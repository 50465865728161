import React, { useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { ShowedProgramContainer } from './styles';
import ClientViewPrograms from 'components/ClientViewPrograms';
import { PROGRAM_COMPLETED } from 'constants/commonData';
import { toggleModal } from 'actions/modal';
import { getAssignedPrograms } from 'redux/client/client.actionCreators';

function AssignedProgram(props) {
  useEffect(() => {
    const { selectedClient } = props;
    if (selectedClient) {
      props.getAssignedPrograms(selectedClient);
    }
  }, []);

  const { assignedPrograms, user } = props;
  const splitDate = user.date_format && user.date_format.split('/');
  const dateFormat = splitDate ? `${splitDate[0].slice(1)}/${splitDate[1].slice(1)}` : 'M/D';

  if (!assignedPrograms.length) {
    return null;
  }

  const today = moment();
  const activeAssigned = _.filter(assignedPrograms, item => item.status !== PROGRAM_COMPLETED);
  let renderedAssigned, isUpcoming;

  if (activeAssigned.length) {
    renderedAssigned = activeAssigned[0];

    _.forEach(activeAssigned, item => {
      if (item.startDate.isBefore(renderedAssigned.startDate)) {
        renderedAssigned = item;
      }
    });

    isUpcoming = renderedAssigned.startDate.isAfter(today);
  }
  return (
    <ShowedProgramContainer>
      {activeAssigned.length ? (
        <div className="program">
          <div className="program__name">
            <span>{isUpcoming ? 'Upcoming:' : ''}</span>&nbsp;
            <Link
              to={`/home/program/${renderedAssigned.program._id}/calendar`}
              className="name"
              data-tip={renderedAssigned.program.title}
              data-for="tootip--active-program"
            >
              {renderedAssigned.program.title}
            </Link>
            <ReactTooltip id="tootip--active-program" className="app-tooltip" place="top" effect="solid" />
            <span>:&nbsp;</span>
          </div>
          <span className="date">
            {renderedAssigned.startDate.format(dateFormat)} - {renderedAssigned.endDate.utc().format(dateFormat)}
          </span>
        </div>
      ) : null}
      <div
        className="view-more"
        data-tip
        data-for="view-more-assigned-programs-tooltip"
        onClick={() => props.toggleModal(true, <ClientViewPrograms />)}
      >
        <div className="dot" />
      </div>
      <ReactTooltip
        id="view-more-assigned-programs-tooltip"
        className="app-tooltip view-more-assigned-programs-tooltip"
        place="top"
        delayShow={500}
        effect="solid"
      >
        <span>Show other assigned programs</span>
      </ReactTooltip>
    </ShowedProgramContainer>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    selectedClient: rootReducer.client.selected,
    assignedPrograms: rootReducer.client.assignedPrograms,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getAssignedPrograms: bindActionCreators(getAssignedPrograms, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedProgram);
