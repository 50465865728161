// Libs
import styled from 'styled-components';

export const IconWrapper = styled.div`
  &.trigger-icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    gap: 4px;
    background: linear-gradient(91.25deg, rgba(255, 76, 0, 0.14) 0.48%, rgba(224, 241, 27, 0.14) 98.93%), #ffffff;
    border-radius: 4px;
  }

  .trigger-icon-label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ff6433;
  }

  .trigger-icon-tooltip.__react_component_tooltip {
    max-width: 228px;
    padding: 15px 15px !important;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    background: #2d2e2d;
    text-align: left;
    border-radius: 5px;

    &.place-top:after {
      border-top-color: #2d2e2d;
      bottom: -8px !important;
      border-top-width: 8px;
    }
  }
`;
