import styled from 'styled-components';
import { MenuTrigger } from 'shared/Icons';

export const DropdownWrapper = styled.div`
  .evf-dropdown__option {
    width: ${({ isDetail }) => isDetail && '111px'};
    padding: 8px 50px 8px 20px;

    .icon {
      margin-right: 10px;

      &.edit {
        width: 16px;
      }
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  border-radius: 6px;
  width: 24px;
  height: 24px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: #7b7e91;
  }
  &.active,
  &:hover {
    .dot,
    .dot::before,
    .dot::after {
      background-color: #5158cf;
    }
  }
`;
