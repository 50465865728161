import React from 'react';
import { connect } from 'react-redux';

function Header({ selectedSegment, all_clients_total }) {
  return `${selectedSegment ? selectedSegment.name : 'All Clients'} (${all_clients_total})`;
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const selectedSegment = rootReducer.segment.list.find(item => item._id === rootReducer.segment.selected);

  return {
    all_clients_total: rootReducer.client.total,
    selectedSegment,
  };
};

export default connect(mapStateToProps, null)(Header);
