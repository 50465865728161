/**
 * @flow
 */

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { getProfile } from 'actions/profile';
import { logout, logoutWithoutRedirect } from 'actions/auth/login';
import { countProducts } from 'redux/product/actions';
import AppComponent from 'components/App/component';
import { getAllRooms, getTotalUnread } from 'redux/inbox-new/actions';
import { explorePayment } from 'redux/permission/actions';
import { getCustomBrandingData } from 'redux/custom-branding/actions';

const mapStateToProps = state => {
  const { user, isGettingProfile, failureToGetProfile, errorGetProfile, rootReducer } = state;

  const autoflowIntervalTraining = get(rootReducer, 'autoflowInterval.training').toJS();

  const clientCalendarHasCopyWeekOrWorkout =
    !isEmpty(get(rootReducer, 'calendar.selectedWeek')) || !isEmpty(get(rootReducer, 'calendar.selectedWorkout'));

  const autoflowExactDateHasCopyWeekOrWorkout =
    !isEmpty(get(rootReducer, 'autoflow.training.selectedWeek')) ||
    !isEmpty(get(rootReducer, 'autoflow.training.selectedWorkouts'));

  const autoflowIntervalHasCopyWeekOrWorkout =
    !isNil(get(autoflowIntervalTraining, 'selectedWeek')) ||
    !isEmpty(get(autoflowIntervalTraining, 'selectedWorkouts'));

  const programLibraryHasCopyWeekOrWorkout =
    !isNil(get(rootReducer, 'program_library.selectedWeek')) ||
    !isEmpty(get(rootReducer, 'program_library.selectedWorkout'));

  const studioProgramHasCopyWeekOrWorkout =
    !isNil(get(rootReducer, 'studioProgram.selectedWeek')) ||
    !isEmpty(get(rootReducer, 'studioProgram.selectedWorkout'));

  const isUploading = get(rootReducer, 'uploadMediaBackground.list', []).length > 0;

  const hasCopyWeekOrWorkout =
    clientCalendarHasCopyWeekOrWorkout ||
    autoflowExactDateHasCopyWeekOrWorkout ||
    autoflowIntervalHasCopyWeekOrWorkout ||
    programLibraryHasCopyWeekOrWorkout ||
    studioProgramHasCopyWeekOrWorkout;

  const isEnableZapier = rootReducer.pricing
    .getIn(['addOnsPricing'])
    .toJS()
    .find(it => it.code === 'zapier');

  return {
    user,
    isGettingProfile,
    failureToGetProfile,
    errorGetProfile,
    selectedClient: rootReducer.client.selected,
    selectedProgram: rootReducer.program_library.selected,
    rootReducer,
    permission: rootReducer.permission,
    productDetail: rootReducer.product.get('workingProduct') ? rootReducer.product.get('workingProduct') : null,
    nameWorkoutCollectionCategory: rootReducer.workoutCollectionCategory.name || '',
    isFormPermission: get(rootReducer, 'permission.form_questionnaire', false),
    isTaskLibraryPermission: get(rootReducer, 'permission.task_library', false),
    loading: rootReducer.calendar.loading,
    coachBioDetails: get(rootReducer, 'coachBioDetails', {}),
    hasCopyWeekOrWorkout,
    onboardingFlowDetail: get(rootReducer, 'onboardingFlow.workingData', null),
    isUploading,
    isEnableZapier,
    generalSettings: rootReducer.generalSettings,
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getProfile: bindActionCreators(getProfile, dispatch),
    logout: bindActionCreators(logout, dispatch),
    logoutWithoutRedirect: bindActionCreators(logoutWithoutRedirect, dispatch),
    getAllRooms: bindActionCreators(getAllRooms, dispatch),
    getTotalUnread: bindActionCreators(getTotalUnread, dispatch),
    explorePayment: bindActionCreators(explorePayment, dispatch),
    countProducts: bindActionCreators(countProducts, dispatch),
    getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent));
