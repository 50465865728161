import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { diff } from 'deep-diff';
import { Prompt, withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import PreviewModal from '../PreviewModal';
import { toggleConfirmModal } from 'actions/modal';
import { axiosInstance } from 'configs/request';
import { ReactComponent as PlayIcon } from 'assets/icons/play_light.svg';
import { ReactComponent as PreviewIcon } from 'assets/icons/eye_grey.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left_new.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import {
  publishForm,
  enableEditForm,
  getDetails,
  getNumberOfResponse,
  updatePartialQuestion,
} from 'redux/form-details/actions';

import * as S from './style';

const CONTENT_TYPES = ['Questions', 'Responses'];

const Header = ({
  push,
  publishForm,
  enableEditForm,
  formDetails,
  toggleConfirmModal,
  getDetails,
  getNumberOfResponse,
  isUploading,
  updatePartialQuestion,
}) => {
  const [contentType, setContentType] = useState(
    window.location.pathname.indexOf('questions') > -1 ? CONTENT_TYPES[0] : CONTENT_TYPES[1],
  );
  const [isPreview, setPreview] = useState(false);
  const [isGettingVersion, setIsGettingVersion] = useState(false);
  const [imBack, setImBack] = useState(false);
  const isEditMode = get(formDetails, 'workingForm.is_edit_mode', false);
  const isPublished = get(formDetails, 'workingForm.status') === 'published';
  const isDraft = get(formDetails, 'workingForm.status') === 'draft';
  const isArchived = get(formDetails, 'workingForm.is_archived', false);
  const isLoading = get(formDetails, 'loading', true);
  const { originalWorkingForm = {}, workingForm = {}, questions = [] } = formDetails;
  const isChange =
    diff(originalWorkingForm, workingForm) || diff(get(originalWorkingForm, 'questions', []), questions) ? true : false;

  useEffect(() => {
    setContentType(window.location.pathname.indexOf('questions') > -1 ? CONTENT_TYPES[0] : CONTENT_TYPES[1]);
  }, [window.location.pathname]);

  const handleClickBack = (e, nextLocation = '/home/forms') => {
    if (!isLoading && !isGettingVersion && !imBack) {
      if (nextLocation === '/home/forms') {
        setImBack(true);
      }
      push(nextLocation);
    }
  };

  const handlePublish = () => {
    setIsGettingVersion(true);
    setTimeout(() => {
      getFormVersion(formDetails.workingForm);
    }, 500);
  };

  const handleEdit = () => {
    if (!isLoading) {
      if (contentType !== CONTENT_TYPES[0]) {
        const formId = get(formDetails, 'workingForm._id');
        if (formId) {
          push(`/home/forms/${formId}/questions`);
          setContentType(CONTENT_TYPES[0]);
        }
      }
      enableEditForm(formDetails.workingForm._id, { enable: true });
    }
  };

  const handleCancelEdit = () => {
    if (!isLoading) {
      if (isChange) {
        toggleConfirmModal(true, <DiscardChangeModal />);
      } else {
        enableEditForm(formDetails.workingForm._id, { enable: false });
      }
    }
  };

  const handleRedirectToTabs = ({ isDraft, onConfirmCallback }) => {
    if (isEmpty(get(formDetails, 'workingQuestion'))) {
      enableEditForm(formDetails.workingForm._id, { enable: false }, onConfirmCallback);
      return;
    }

    const commonParams = {
      _id: formDetails.workingQuestion._id,
      type: formDetails.workingQuestion.type,
      background_image: null,
      background_image_thumbnail: null,
      image_width: null,
      image_height: null,
    };

    if (isDraft) {
      updatePartialQuestion(commonParams, () => {
        setTimeout(() => {
          onConfirmCallback && onConfirmCallback();
        }, 0);
      });
    } else {
      updatePartialQuestion(commonParams);
      enableEditForm(formDetails.workingForm._id, { enable: false }, onConfirmCallback);
    }
  };

  const DiscardChangeModal = ({ onConfirmCallback }) => (
    <ConfirmModal
      title="Cancel Edits Made to Form?"
      content="Would you like to cancel editing the form? Any changes you have made will not be saved."
      onClose={() => {
        setImBack(false);
        setContentType(window.location.pathname.indexOf('questions') > -1 ? CONTENT_TYPES[0] : CONTENT_TYPES[1]);
      }}
      onDeny={() => {
        setImBack(false);
        setContentType(window.location.pathname.indexOf('questions') > -1 ? CONTENT_TYPES[0] : CONTENT_TYPES[1]);
      }}
      onConfirm={() => {
        handleRedirectToTabs({ isDraft, onConfirmCallback });
      }}
      confirmButtonTitle="Cancel Edits"
      cancelButtonTitle="Continue Editing"
      newStyle
      hasCloseIcon
    />
  );

  const handleUnmount = nextLocation => {
    toggleConfirmModal(
      true,
      <DiscardChangeModal onConfirmCallback={() => handleClickBack(null, nextLocation.pathname)} />,
    );

    return false;
  };

  const handlePublishChanges = () => {
    setIsGettingVersion(true);
    setTimeout(() => {
      getFormVersion(formDetails.workingForm);
    }, 500);
  };

  const getFormVersion = form => {
    if (!isGettingVersion && !isLoading) {
      axiosInstance.get(`api/forms/${form._id}/version`).then(response => {
        setIsGettingVersion(false);
        const { data } = response.data;
        if (data.version === form.version) {
          publishForm(form._id);
        } else {
          toggleConfirmModal(
            true,
            <ConfirmModal
              noIcon
              noBorder
              title="Version Conflict"
              content="Can't save this draft. Your teammate has just published a new version of this form. Please refresh the page to load the latest data."
              onConfirm={() => getDetails(form._id, true)}
              hasHoverState
              hideCancelButton
              shouldCloseAfterConfirm
              className="change-question-confirm-popup-container"
            />,
          );
        }
      });
    }
  };

  const totalFormResponses = get(formDetails, 'workingForm.number_of_responses', 0);

  return (
    <>
      <Prompt when={isEditMode || isUploading ? isChange : false} message={handleUnmount} />
      <S.HeaderWrapper>
        <S.LeftHeaderWrapper>
          <div className="back-button-container" onClick={handleClickBack}>
            <LeftArrowIcon />
            <span className="form-library">Back</span>
          </div>
        </S.LeftHeaderWrapper>
        <S.CenterHeaderWrapper>
          {CONTENT_TYPES.map(item => (
            <S.ContentType
              isActive={item === contentType}
              onClick={() => {
                const formId = get(formDetails, 'workingForm._id');
                if (formId) {
                  setContentType(item);
                  push(`/home/forms/${formId}/${item.toLowerCase()}`);
                }
                getNumberOfResponse();
              }}
              className="Tabs"
              name={item}
            >
              {item} {item === CONTENT_TYPES[1] && totalFormResponses ? `(${totalFormResponses})` : null}
            </S.ContentType>
          ))}
        </S.CenterHeaderWrapper>
        <S.RightHeaderWrapper isEditMode={isEditMode}>
          <Button className="preview-button" onClick={() => setPreview(true)}>
            <PreviewIcon />
            Preview
          </Button>
          {isPublished && !isEditMode && (
            <Button className="edit-button" disabled={isArchived || isGettingVersion || isLoading} onClick={handleEdit}>
              <EditIcon />
              Edit
            </Button>
          )}
          {isPublished && isEditMode && (
            <Button className="cancel-edit-button" disabled={isGettingVersion || isLoading} onClick={handleCancelEdit}>
              Cancel Edit
            </Button>
          )}
          {!isPublished && (
            <Button
              purple
              className="publish-button"
              disabled={isGettingVersion || isLoading || isUploading}
              onClick={handlePublish}
            >
              <PlayIcon />
              Publish
            </Button>
          )}
          {isPublished && isEditMode && (
            <Button
              purple
              className="publish-changes-button"
              disabled={isGettingVersion || isLoading || isUploading}
              onClick={handlePublishChanges}
            >
              <SaveIcon />
              Publish Changes
            </Button>
          )}
        </S.RightHeaderWrapper>
        {isPreview && <PreviewModal onClose={() => setPreview(false)} />}
      </S.HeaderWrapper>
    </>
  );
};

const mapState = state => ({
  formDetails: state.rootReducer.formDetails,
});

const mapDispatch = dispatch => {
  return {
    publishForm: bindActionCreators(publishForm, dispatch),
    enableEditForm: bindActionCreators(enableEditForm, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getDetails: bindActionCreators(getDetails, dispatch),
    getNumberOfResponse: bindActionCreators(getNumberOfResponse, dispatch),
    updatePartialQuestion: bindActionCreators(updatePartialQuestion, dispatch),
  };
};

export default withRouter(connect(mapState, mapDispatch)(Header));
