import styled from 'styled-components';
import DefaultBackground from 'assets/icons/default-background.svg';
import { CDN_URL } from 'constants/commonData';

export const CompactInformationItem = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 5px;
`;

export const ProgramStatus = styled(CompactInformationItem)`
  position: relative;
  padding-left: 20px;
  :before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => (props.published ? '#6ACF47' : '#ffc90c')};
    z-index: 1;
  }
`;

export const CompactDataContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${CompactInformationItem} {
    :last-child {
      margin-right: 0;
    }
  }
`;

export const CoverPhotoContainer = styled.div`
  height: 181px;
  width: 100%;
  position: relative;
  background: #cccbc3 url(${props => props.coverImage || DefaultBackground}) no-repeat center center;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const WorkoutCollectionsBaseInformation = styled.div`
  width: 100%;
  /* min-height: 123px; */
  padding: 20px 15px;
  background: #ffffff;
  border: 1px solid #dadfea;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .workout-collections__name {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  .workout-collections__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
    opacity: 0.8;
    /* margin-bottom: 15px; */
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    min-height: 40px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  .workout-collections__type {
    color: #9a9da6;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 4px;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    box-orient: vertical;
    overflow: hidden;
  }
`;

export const CompletedIcon = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${CDN_URL}/images/checkbox_greenwhite.svg);
  background-size: cover;
  margin-right: 5px;
`;

export const ActiveCheck = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #5158cf;
  background-image: url(${CDN_URL}/images/check_studio_active.svg);
  background-repeat: no-repeat;
  padding: 6px 12px 6px 28px;
  background-position: 10px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 345px;
  margin-right: 30px;
  :hover {
    .workout-collections__name {
      color: #5158cf;
    }
  }
  .clientStudio__container {
    color: initial;
    text-decoration: none !important;
    display: block;
  }
`;
