import React, { useEffect } from 'react';
import { Header, Chart, Progress } from './components';
import { Wrapper, ChartContainer } from './style';
import { Mixpanel } from 'utils/mixplanel';

const StepMetric = ({ isDefaultStep = true }) => {
  useEffect(() => {
    Mixpanel.track('view_step_tracking_screen');
  }, []);

  return (
    <Wrapper>
      <ChartContainer>
        <div className="content-container">
          <Header isDefaultStep={isDefaultStep} />
          <Chart />
        </div>
      </ChartContainer>
      {isDefaultStep && <Progress />}
    </Wrapper>
  );
};

export default StepMetric;
