import Request from 'configs/request';
import { METRIC_SETTING_MODULE } from 'constants/commonData';

export const Types = {
  TRAINER_GET_DEFAULT_FEATURE_SETTINGS: 'TRAINER_GET_DEFAULT_FEATURE_SETTINGS',
  TRAINER_SUCCESS_GET_DEFAULT_FEATURE_SETTINGS: 'TRAINER_SUCCESS_GET_DEFAULT_FEATURE_SETTINGS',
  TRAINER_FAILED_GET_DEFAULT_FEATURE_SETTINGS: 'TRAINER_FAILED_GET_DEFAULT_FEATURE_SETTINGS',
  TRAINER_UPDATE_FEATURES_SETTINGS: 'TRAINER_UPDATE_FEATURES_SETTINGS',
  TRAINER_SUCCESS_UPDATE_FEATURES_SETTINGS: 'TRAINER_SUCCESS_UPDATE_FEATURES_SETTINGS',
  TRAINER_FAILED_UPDATE_FEATURES_SETTINGS: 'TRAINER_FAILED_UPDATE_FEATURES_SETTINGS',
  TRAINER_GET_DEFAULT_WORKOUT_SETTINGS: 'TRAINER_GET_DEFAULT_WORKOUT_SETTINGS',
  TRAINER_SUCCESS_GET_DEFAULT_WORKOUT_SETTINGS: 'TRAINER_SUCCESS_GET_DEFAULT_WORKOUT_SETTINGS',
  TRAINER_FAILED_GET_DEFAULT_WORKOUT_SETTINGS: 'TRAINER_FAILED_GET_DEFAULT_WORKOUT_SETTINGS',
  TRAINER_UPDATE_WORKOUT_SETTINGS: 'TRAINER_UPDATE_WORKOUT_SETTINGS',
  TRAINER_SUCCESS_UPDATE_WORKOUT_SETTINGS: 'TRAINER_SUCCESS_UPDATE_WORKOUT_SETTINGS',
  TRAINER_FAILED_UPDATE_WORKOUT_SETTINGS: 'TRAINER_FAILED_UPDATE_WORKOUT_SETTINGS',
  TRAINER_TRANSFER_CLIENT: 'TRAINER_TRANSFER_CLIENT',
  TRAINER_SUCCESS_TRANSFER_CLIENT: 'TRAINER_SUCCESS_TRANSFER_CLIENT',
  TRAINER_FAILED_TRANSFER_CLIENT: 'TRAINER_FAILED_TRANSFER_CLIENT',
};

const getDefaultFeatureSettings = () => {
  return Request.get({ url: '/api/trainer/client_default/feature_preferences' }, true);
};

const getDefaultWorkoutSettings = () => {
  return Request.get({ url: '/api/trainer/client_default/workout_visibility_preferences' }, true);
};

const updateFeaturesSettings = (data) => {
  return Request.put({ url: `/api/trainer/client_default/feature_preferences/updateMany`, data }, true);
};

const updateWorkoutSettings = (data) => {
  return Request.put(
    { url: `/api/trainer/client_default/workout_visibility_preferences/updateMany`, data },
    true
  );
};

const getMetrics = () => {
  return Request.get({ url: `/api/body-metric/get-list-trainer-default-body-metric` }, true);
};

const addMetric = (data) => {
  return Request.post({ url: `/api/body-metric/add-new-metric`, data }, true);
};

const updateMetric = (data) => {
  return Request.put({ url: '/api/body-metric/update-metric', data }, true);
};

const updateMultipleMetric = (data) => {
  return Request.put({ url: '/api/body-metric/update-trainer-default-body-metric-setting', data }, true);
}

const deleteMetric = (data) => {
  return Request.post({ url: '/api/body-metric/remove-metric', data }, true);
};

const transferClient = (data) => {
  return Request.post({ url: `/api/trainer/transfer_lient`, data }, true, null, (error, { dispatch }) =>
    dispatch({ type: Types.TRAINER_FAILED_TRANSFER_CLIENT })
  );
};

export const searchClient = (params) => {
  return Request.get({ url: `/api/trainer/clients_search`, params });
};

export const Actions = {
  getDefaultFeatureSettings,
  getDefaultWorkoutSettings,
  updateFeaturesSettings,
  updateWorkoutSettings,
  getMetrics,
  addMetric,
  updateMetric,
  deleteMetric,
  transferClient,
  updateMultipleMetric,
};
