import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { linkSet, unlinkSet } from 'redux/workout-builder/actions';
import { ENTITY_NAME } from 'database/constants';
import { SECTION_FORMAT_KEY, WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';
import { WorkoutContext } from '../contexts';
import OnboardingTooltip, { Content, Title } from 'components/Onboarding/Tooltip';
import { markWorkoutBuilderGuide, runOnboarding } from 'redux/onboarding/actions';

export const LINK_TYPES = { LINK: 'LINK', UNLINK: 'UNLINK' };

function LinkSet(props) {
  const { type, loadedWorkoutOnboardingData, alreadyCreateSuperset, isNextExercise, runOnboarding } = props;
  const isUnlink = type === LINK_TYPES.UNLINK;
  const workoutCommonData = useContext(WorkoutContext);
  let shouldShowOnboarding;

  useEffect(() => {
    if (shouldShowOnboarding) runOnboarding(WORKOUT_BUILDER_GUIDE_STEPS.CreateSuperset);
  }, [shouldShowOnboarding]);

  const onLink = e => {
    e.preventDefault();
    const data = _.omit(props, p => typeof p === 'function');

    if (isUnlink) {
      props.unlinkSet(data);
    } else {
      if (!alreadyCreateSuperset) {
        props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateSuperset);
      }

      const scrollTop = getCurrentScrollTop();
      props.linkSet(data);
      handleScroll(scrollTop);
    }
  };

  const getCurrentScrollTop = () => {
    const scrollMainRef = document.querySelector('.mainPanel__content');
    return scrollMainRef.scrollTop;
  };

  const handleScroll = scrollTop => {
    setTimeout(() => {
      const scrollMainRef = document.querySelector('.mainPanel__content');
      scrollMainRef.scrollTo(0, scrollTop);
    }, 0);
  };

  const onGuideDone = () => {
    props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateSuperset);
  };

  const tooltipId = `${type}-${props.sectionId}-${props.exerciseId}-${props.linkIndex}`;
  const [sectionId, , sectionFormat] = props.sectionId.split('-');
  const unlinkable = sectionFormat === SECTION_FORMAT_KEY.AMRAP || sectionFormat === SECTION_FORMAT_KEY.TIMED;
  const [linkSectionId, linkExerciseId] = workoutCommonData.firstLinkSupersetId.split('-');
  shouldShowOnboarding =
    loadedWorkoutOnboardingData &&
    !alreadyCreateSuperset &&
    props.alreadyDragAndDropExercise &&
    linkSectionId === sectionId &&
    (linkExerciseId ? props.exerciseId === linkExerciseId : true) &&
    isNextExercise;

  return (
    <Wrapper
      className={classnames('linkSupersetContainer', {
        unlink: !unlinkable && isUnlink,
        unlinkable: unlinkable,
      })}
    >
      {!unlinkable && (
        <div className="link-set-action">
          <button type="button" onClick={onLink} data-tip data-for={tooltipId}>
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 9H4.5C2.291 9 0.5 7.209 0.5 5C0.5 2.791 2.291 1 4.5 1H5.5"
                stroke="#838D9F"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 9H11.5C13.709 9 15.5 7.209 15.5 5C15.5 2.791 13.709 1 11.5 1H10.5"
                stroke="#838D9F"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 5H11.5"
                stroke="#838D9F"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <ReactTooltip className="app-tooltip" effect="solid" place="top" id={tooltipId}>
            <span>{isUnlink ? 'Unlink Superset' : 'Link as Superset'}</span>
          </ReactTooltip>
          {shouldShowOnboarding && (
            <div className="link-set-onboarding">
              <OnboardingTooltip onClose={onGuideDone} place="bottom" align="end" showInModal>
                <Content style={{ width: 280 }}>
                  <Title>Create a Superset</Title>
                  Click the link icon to create a superset between the two exercises. You can link as many exercises as
                  you want.
                </Content>
              </OnboardingTooltip>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const mapState = (state, { linkIndex }) => {
  const {
    rootReducer: {
      onboarding: {
        workoutBuilderGuideSteps: { loaded, create_superset, create_exercise_section },
      },
      workoutBuilder,
      model,
    },
  } = state;
  const selectedWorkout = workoutBuilder.get('selectedWorkout');
  let isNextExercise = false;

  if (selectedWorkout.getIn(['sections', linkIndex + 1])) {
    const nextSectionId = selectedWorkout.getIn(['sections', linkIndex + 1]);
    const nextExerciseId = model.getIn([ENTITY_NAME.Section, nextSectionId, 'exercises', 0]);
    if (nextExerciseId) {
      const nextSupersetId = model.getIn([ENTITY_NAME.Exercise, nextExerciseId, 'supersets', 0]);
      if (nextSupersetId) {
        const nextExerciseInstant = model.getIn([ENTITY_NAME.Superset, nextSupersetId, 'exercise_instance']).toJS();
        isNextExercise = nextExerciseInstant && !_.isEmpty(nextExerciseInstant);
      }
    }
  }

  return {
    loadedWorkoutOnboardingData: loaded,
    alreadyCreateSuperset: create_superset,
    alreadyDragAndDropExercise: create_exercise_section,
    isNextExercise,
  };
};

const mapDispatch = dispatch => {
  return {
    linkSet: bindActionCreators(linkSet, dispatch),
    unlinkSet: bindActionCreators(unlinkSet, dispatch),
    markWorkoutBuilderGuide: bindActionCreators(markWorkoutBuilderGuide, dispatch),
    runOnboarding: bindActionCreators(runOnboarding, dispatch),
  };
};

export default connect(mapState, mapDispatch)(LinkSet);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
  background: inherit;

  .link-set-action {
    display: flex;
    align-items: baseline;

    .link-set-onboarding {
      position: relative;
      margin-left: -4px;
    }
  }

  button {
    position: relative;
    z-index: 2;
    padding: 0 5px;
    outline: none;
    box-shadow: none;
    text-align: center;
    border: none;
    background: inherit;

    :hover {
      cursor: pointer;
      svg {
        path {
          stroke: #5158cf;
        }
      }
    }

    .onboarding-tooltip {
      text-align: left;
      position: absolute;
      right: -3px;
      top: 6px;
    }
  }

  &.unlink {
    padding: 0;

    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: 100%;
      background: #dadfea;
      z-index: 1;
    }
  }
`;
