import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Modal } from 'semantic-ui-react';
import ContentLoader from 'react-content-loader';
import { Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { getUserShortName } from 'utils/commonFunction';
import { axiosInstance } from 'configs/request';
import { DropdownIndicator } from 'shared/Icons';
import Avatar from 'react-avatar';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import './style.scss';

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select_option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  const name = `${data.first_name} ${data.last_name}`;

  return (
    <S.ContactInformation data-pulldown={`pulldown-item ${data.email}`} {...innerProps} className={className}>
      <Avatar name={getUserShortName(data)} size="40" src={data.avatar} color={data.color} />
      <S.SingleLabel className="select__option__label">
        <div className="name">{name}</div>
        <div className="email">{data.email}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const SingleValue = ({ innerProps, data }) => {
  return (
    <S.ContactInformation {...innerProps} className="teammate-single-select__single-value">
      <Avatar name={getUserShortName(data)} size="40" src={data.avatar} />
      <S.SingleLabel className="select__option__label">
        <div className="name">
          {data.first_name} {data.last_name}
        </div>
        <div className="email">{data.email}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 62,
    background: `linear-gradient(180deg, #FFFFFF 0%, #F7F8FC 100%)`,
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    borderColor: '#7470EF',
  }),
  option: (base) => ({
    ...base,
    height: 60,
  }),
};

function ChangeBillingContact(props) {
  const { data } = props;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState(data);

  useEffect(() => {
    axiosInstance
      .get('/api/team/list_admin_owner')
      .then((response) => {
        const { data } = response.data;
        setList(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const onChange = (newData) => {
    if (newData.value !== selected._id) {
      setSelected(newData);
    }
  };

  const onSubmit = () => {
    if (submitting) {
      return false;
    }

    if (selected._id !== data._id) {
      const bodyData = { contact_id: selected._id };
      setSubmitting(true);
      props
        .updateBillingContact(bodyData)
        .then(res => {
          props.onClose();
          onHandleUpdateGeneralSettings(res);
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  const onHandleUpdateGeneralSettings = res => {
    if (res.data) props.updateEmailBilling(res.data);
  };

  const options = useMemo(() => {
    return _.map(list, (item) => ({
      ...item,
      value: item._id,
      key: item._id,
      label: `${item.first_name} ${item.last_name}`,
    }));
  }, [list]);

  const value = useMemo(() => {
    return {
      ...selected,
      key: selected._id,
      value: selected._id,
      label: `${selected.first_name} ${selected.last_name}`,
    };
  }, [selected]);

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      className="modal--billing-contact"
      onClose={props.onClose}
      closeIcon={true}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.ContentWrapper>
          <ModalLayout.Header>
            <div>Billing Contact</div>
          </ModalLayout.Header>
          <ModalLayout.Content>
            {loading ? (
              <ContentLoader height="57" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)">
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            ) : (
              <Select
                options={options}
                onChange={onChange}
                components={{
                  Option: SelectOption,
                  DropdownIndicator,
                  SingleValue,
                  IndicatorSeparator: null,
                }}
                classNamePrefix="select-admin"
                placeholder="Choose a team member"
                className="select-container"
                noOptionsMessage={() => 'No result'}
                value={value}
                styles={customStyles}
                isSearchable={false}
              />
            )}
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button purple disabled={selected._id === data._id} onClick={onSubmit}>
              Update
            </Button>
          </ModalLayout.Actions>
        </S.ContentWrapper>
      </Modal.Content>
    </Modal>
  );
}

export default ChangeBillingContact;
