import React, { useEffect, useRef, useState } from 'react';
import { Accordion } from 'semantic-ui-react';

import * as S from './style';

import { ReactComponent as AccordionIcon } from 'assets/icons/arrow_down_new.svg';

function AdvancedSetting(props) {
  const { children, expand = false } = props;
  const settingsRef = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    scrollToComponent();
  }, [active]);

  const handleAccordionClick = () => {
    setActive(prevActive => !prevActive);
  };

  const scrollToComponent = () => {
    settingsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  return (
    <S.SettingWrapper active={active} expand={expand} ref={settingsRef} ƒ>
      <Accordion className="settings-accordion">
        <Accordion.Title className="advanced-settings-title" active={active} index={0} onClick={handleAccordionClick}>
          <span>Advanced Settings</span>
          <AccordionIcon className="accordion-icon" />
        </Accordion.Title>
        <Accordion.Content active={active}>{children}</Accordion.Content>
      </Accordion>
    </S.SettingWrapper>
  );
}

export default AdvancedSetting;
