import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { MAX_FILE_SIZE, CONVERSION } from 'constants/commonData';
import * as S from './style';
import { SelectFile } from 'shared/FormControl';
import {
  createObjectURL,
  mediaLog,
  revokeObjectURL,
  getPresignedUploadUrlByParams,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import FileUpload from 'shared/FileUpload';
import { VALIDATIONS } from 'components/TaskCreate/GeneralTaskForm/helper';

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
};

class UploadCoverImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.maxSize = MAX_FILE_SIZE * CONVERSION.MB_TO_BYTE;
    this.validateExtentions = ['png', 'jpg', 'jpeg'];
    this.uploadConfigs = null;
  }

  getPresignedURLs = async list => {
    try {
      const { cloudfrontList, getS3presignedURL } = this.props;
      let newSrc = convertS3UrlToCloudFrontUrl(list[0], cloudfrontList);
      if (!newSrc) {
        const response = await getS3presignedURL(list);
        newSrc = response[0];
      }
      this.setState(() => ({
        src: newSrc,
      }));
    } catch {}
  };

  componentDidMount() {
    this._isMounted = true;
    this.getPresignedURLs([this.props.src]);
  }

  componentDidUpdate() {
    const { requestResetValue } = this.props;

    if (requestResetValue) {
      requestResetValue.current = this.onRemoveImage;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }
  }

  onSelectFile = async file => {
    const formData = new FormData();
    formData.append('media', file);
    const { size, name, type } = file;
    const { source = '' } = this.props;

    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: `Send a file via ${source ? source : 'Studio Program'}`,
    });

    const { configs: fileInfo, uploadUrl } = await getPresignedUploadUrlByParams(file, {
      method: 'POST',
      url: '/api/file/gen-presigned-urls-studio-collection',
      data: {
        fileNames: [file.name],
      },
    });

    this.uploadConfigs = {
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    };

    this.setState({ src: createObjectURL(file), isLocalUrl: true, file }, () => {
      this.props.onStartUpload();
    });
  };

  onDropFile = files => {
    if (files.length) {
      const file = files[0];
      if (file.size > MAX_FILE_SIZE * CONVERSION.MB_TO_BYTE) {
        return this.limitSizeError();
      }
      this.onSelectFile(file);
    } else {
      toast.error('File type not supported');
    }
  };

  limitSizeError = () => {
    return toast.error(`File too large. Please upload a smaller file`);
  };

  onUploadSuccess = response => {
    const { source = '' } = this.props;
    if (this._isMounted) {
      const { data, url } = response.config;
      const { name, type } = data;
      this.uploadConfigs = null;
      mediaLog({
        status: 2,
        name: name,
        fileType: type,
        description: `Upload success file via ${source ? source : 'Studio Program'}`,
      });
      this.setState({ file: null }, () => {
        this.props.onUploadSuccess({ bucketData: data, src: this.state.src, url });
      });
    }
  };

  onCancelUpload = () => {
    if (this._isMounted) {
      revokeObjectURL(this.state.src);
      this.uploadConfigs = null;
      this.setState({ ...DEFAULT_STATE }, () => {
        this.props.onEndUpload();
      });
    }
  };

  onRemoveImage = () => {
    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }

    this.setState({ ...DEFAULT_STATE }, () => {
      this.props.onRemove();
    });
  };

  render() {
    const { src, file } = this.state;
    const { disabled = false, maxSize, msgMaxSizeImg } = this.props;

    if (!src && !this.props.src) {
      return (
        <Dropzone onDrop={this.onDropFile} multiple={false} accept={VALIDATIONS.IMAGE.accepts} disabled={disabled}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Wrapper {...getRootProps()} isDragging={isDragActive} className={this.props.className}>
              <S.TooltipIcon data-tip data-for="upload-warning" />
              <ReactTooltip id="upload-warning" effect="solid" place={'top'}>
                Use an image size of at least 640 x 360 pixels for the best resolution and display.
              </ReactTooltip>
              <S.Placeholder isDragging disabled={disabled}>
                <S.Icon />
                <S.Text>
                  Drag and drop an image here or&nbsp;
                  <SelectFile
                    disabled={disabled}
                    trigger={<span>Choose file</span>}
                    onSelect={this.onSelectFile}
                    accept={VALIDATIONS.IMAGE.accepts}
                    validateExtentions={VALIDATIONS.IMAGE.extensions}
                    maxSize={maxSize}
                    fileSizeLimitMessage={msgMaxSizeImg}
                  />
                </S.Text>
              </S.Placeholder>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </S.Wrapper>
          )}
        </Dropzone>
      );
    }

    return (
      <Dropzone onDrop={this.onDropFile} multiple={false} accept={VALIDATIONS.IMAGE.accepts} disabled={disabled}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <S.Wrapper
            {...getRootProps()}
            isDragging={isDragActive}
            src={src || this.props.src}
            className={this.props.className}
          >
            {!file && this.props.hasRemoveIcon && (
              <S.RemoveIcon
                onClick={e => {
                  e.stopPropagation();
                  this.onRemoveImage();
                }}
              />
            )}
            {file ? (
              <FileUpload
                onSuccess={this.onUploadSuccess}
                onError={() => {}}
                onCancel={this.onCancelUpload}
                configs={this.uploadConfigs}
              />
            ) : (
              <S.Placeholder isDragging={isDragActive}>
                <S.TooltipIcon data-tip data-for="upload-warning" />
                <ReactTooltip id="upload-warning" effect="solid" place={'top'}>
                  Use an image size of at least 640 x 360 pixels for the best resolution and display.
                </ReactTooltip>
                <S.Icon />
                <S.Text>
                  Drag and drop your file here or&nbsp;
                  <SelectFile
                    disabled
                    trigger={<span>Choose file</span>}
                    onSelect={this.onSelectFile}
                    accept={VALIDATIONS.IMAGE.accepts}
                    validateExtentions={VALIDATIONS.IMAGE.extensions}
                    maxSize={maxSize}
                    fileSizeLimitMessage={msgMaxSizeImg}
                  />
                </S.Text>
              </S.Placeholder>
            )}
            {!this.props.hideFileInput && !file && <input {...getInputProps()} />}
            {!file && this.props.children}
          </S.Wrapper>
        )}
      </Dropzone>
    );
  }
}

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCoverImage);
