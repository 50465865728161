import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import diff from 'deep-diff';
import { toast } from 'react-toastify';

import { isTeamAdmin, pluralize } from 'utils/commonFunction';
import {
  getExerciseLibrary,
  resetQueryParams,
  changeQueryParams,
  hideMultiExercises,
  addBulkAddTagExercises,
  resetSelectedExercise,
} from 'redux/exercise/exercise.actionCreators';
import { createNewTag, getMostRecentTagsList } from 'redux/tags/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';

import { ToolbarContainer } from 'shared/LibraryLayout';
import { NewSearchInput } from 'shared/SearchInput';
import { Button } from 'shared/FormControl';
import Filter, { DEFAULT_FILTERS } from 'shared/ExerciseFilterPopup';
import { ToolbarLeft, FilterTrigger, ResetFilter, TagsButton, WrapperActions } from '../style';
import ConfirmModal from 'shared/ConfirmModal';
import MenuTags from 'shared/MenuTags';
import ManageTags from 'shared/ManageTags';
import { MODES } from 'components/ExerciseForm/component';

import ExerciseDetailModal from 'components/ExerciseDetailModal';
import CreateNewTags from './CreateNewTags';

import Tag from 'assets/icons/tag.svg';
import { CDN_URL, TAGS_TYPE } from 'constants/commonData';

function View(props) {
  const {
    bodyParts,
    categories,
    currentFilters,
    q,
    selectedExercise,
    isModalOpen,
    exerciseTags,
    getExerciseLibrary,
    countTagsSelected,
    tagsSelected,
    listExercise,
    getMostRecentTagsList,
    user,
    equipments = [],
    modalities = [],
    muscleGroup = [],
    movementPattern = [],
    sort,
    sorter,
  } = props;
  const [open, setOpen] = useState(false);
  const [openCreateNewTags, setOpenCreateNewTags] = useState(false);
  const [shouldGetTags, setShouldGetTags] = useState(true);
  const [openManageTags, setOpenManageTags] = useState(false);
  const { query } = exerciseTags;
  const latestQuerySortRef = useRef({});

  useEffect(() => {
    getExerciseLibrary();

    return () => {
      props.resetQueryParams();
    };
  }, []);

  useEffect(() => {
    if (!isModalOpen && !openCreateNewTags && shouldGetTags) {
      getMostRecentTagsList({
        page: query.page,
        sort: -1,
        type: TAGS_TYPE.EXERCISE,
        text_search: query.textSearch,
      });
    }
  }, [query.page, query.textSearch, isModalOpen, openCreateNewTags]);

  const getParamsSort = search => {
    if (search === '') {
      if (sort !== undefined && sorter !== undefined) {
        return {};
      }

      return {
        sorter: latestQuerySortRef.current.sorter || 'last_interacted',
        sort: latestQuerySortRef.current.sort || -1,
      };
    }

    if (sort !== undefined && sorter !== undefined) {
      latestQuerySortRef.current = { sort, sorter };
    }

    return { sorter: undefined, sort: undefined };
  };

  const searchExercise = (event, { value }) => {
    const search = value.toLowerCase().trim();

    if (search !== q) {
      props.resetSelectedExercise();

      const paramsSort = getParamsSort(search);
      props.changeQueryParams({ page: 1, q: search, ...paramsSort });
    }
  };

  const onNew = () => {
    props.toggleModal(true, <ExerciseDetailModal mode={MODES.CREATE} />);
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      props.resetSelectedExercise();
      props.changeQueryParams({ page: 1 });
    }
  };

  const onApplyFilter = data => {
    props.resetSelectedExercise();
    props.changeQueryParams({ ...data, page: 1 });
  };

  const onResetFilter = () => {
    props.resetSelectedExercise();
    props.changeQueryParams({ ...DEFAULT_FILTERS, page: 1 });
  };

  const onHideExercise = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Hide Exercise"
        content="Would you like to hide this exercise?"
        confirmButtonTitle="Hide"
        onConfirm={doHideExercises}
        headerIcon={`${CDN_URL}/images/eye_hiden_circle.svg`}
        newStyle={true}
      />,
    );
  };

  const doHideExercises = () => {
    props.hideMultiExercises(selectedExercise).then(() => {
      setTimeout(() => {
        toast(
          `${selectedExercise.length} ${pluralize('exercise', selectedExercise.length)} ${
            selectedExercise.length > 1 ? 'have' : 'has'
          } been hidden.`,
        );
      }, 300);
    });
  };

  const hideResetButton = _.isEqual(currentFilters, DEFAULT_FILTERS);
  const searchDebounce = _.debounce(searchExercise, 500);

  const onOpenCreateTags = () => {
    props.toggleModal(
      true,
      <CreateNewTags open={true} onCloseModal={handleCloseModal} onSubmit={props.createNewTag} />,
    );
    setOpenCreateNewTags(true);
  };

  const handleCloseModal = () => {
    props.toggleModal(false);
    setTimeout(() => {
      setOpenCreateNewTags(false);
    }, 100);
  };

  const handleOpenManageTags = () => {
    props.toggleModal(true, <ManageTags type={TAGS_TYPE.EXERCISE} onCloseModal={handleCloseModalManageTag} />);
    setOpenManageTags(true);
  };

  const handleCloseModalManageTag = () => {
    setTimeout(() => {
      setOpenManageTags(false);
    }, 100);
  };

  const handleUpdateGeneralApplyTags = data => {
    const defaultSelected = _.get(data, 'defaultSelected', []);
    const currentSelected = _.get(data, 'tagIds', []);
    const exerciseIds = _.get(data, 'elementsSelectedByTabs', []);
    let newDataTags = {
      exerciseIds: exerciseIds,
      newTagIds: currentSelected,
      removedTagIds: [],
    };
    let newTagIds = [];
    let removedTagIds = [];

    // If default selected have tags selected
    if (defaultSelected.length > 0) {
      if (diff(defaultSelected, currentSelected)) {
        removedTagIds = _.filter(defaultSelected, item => !currentSelected.includes(item));
        newTagIds = _.filter(currentSelected, item => !defaultSelected.includes(item));

        newDataTags = {
          ...newDataTags,
          newTagIds: diff(newTagIds, currentSelected) || diff(newTagIds, defaultSelected) ? newTagIds : [],
          removedTagIds: removedTagIds,
        };
      }
    }

    return newDataTags;
  };

  const handleApplyTags = data => {
    if (data) {
      const newDataTags = handleUpdateGeneralApplyTags(data);
      props.addBulkAddTagExercises && props.addBulkAddTagExercises(newDataTags);
      props.onClearSelectedByTags && props.onClearSelectedByTags();
    }
  };

  const allowEditTag = useMemo(() => {
    const isAdmin = isTeamAdmin(user);

    if (isAdmin) {
      return true;
    }
    const currentSelectedExercises = listExercise.filter(
      it => selectedExercise.includes(it._id) && user._id !== it.author,
    );

    return currentSelectedExercises.length > 0 ? false : true;
  }, [selectedExercise, listExercise]);

  return (
    <>
      <ToolbarContainer>
        <ToolbarLeft>
          <NewSearchInput
            onChange={searchDebounce}
            onClearSearch={() => searchExercise(null, { value: '' })}
            placeholder="Search exercise name"
            onKeyPress={onKeyPress}
          />
          <Filter
            tags={exerciseTags}
            bodyParts={bodyParts}
            categories={categories}
            currentFilters={currentFilters}
            onApply={onApplyFilter}
            menuHeight={585}
            trigger={<FilterTrigger>Filter</FilterTrigger>}
            isExerciseLibrary
            equipments={equipments}
            modalities={modalities}
            muscleGroup={muscleGroup}
            movementPattern={movementPattern}
          />
          <ResetFilter onClick={onResetFilter} hide={hideResetButton}>
            Reset
          </ResetFilter>
          {!!selectedExercise.length && (
            <Button onClick={onHideExercise} className="button--hide-exercise-instance">
              <img src={`${CDN_URL}/images/eye_hiden_grey.svg`} alt="" />
              <span>Hide Exercise</span>
            </Button>
          )}
        </ToolbarLeft>
        <WrapperActions>
          <TagsButton
            title="Tags"
            img={Tag}
            open={open}
            onClick={() => {
              setOpen(true);
              setShouldGetTags(true);
            }}
          />
          <Button purple className="button--add-new-library-instance" onClick={onNew}>
            <img src={`${CDN_URL}/images/plus_white.svg`} alt="" />
            <span>Add New Exercise</span>
          </Button>
        </WrapperActions>
      </ToolbarContainer>
      {open ? (
        <MenuTags
          open={open}
          onClose={() => {
            setOpen(false);
            setShouldGetTags(false);
          }}
          type={TAGS_TYPE.EXERCISE}
          tags={exerciseTags.mostRecentList}
          onClickAddNew={onOpenCreateTags}
          toggleModal={props.toggleModal}
          onClickManage={handleOpenManageTags}
          onSubmit={props.createNewTag}
          countTagsSelected={countTagsSelected}
          elementsSelected={tagsSelected}
          disableCloseMenu={openCreateNewTags || openManageTags}
          onApplyTabs={handleApplyTags}
        />
      ) : null}
    </>
  );
}

const ExerciseLibraryToolbar = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      exercise: {
        body_parts,
        categories,
        listExercise,
        selectedExercise,
        equipments,
        modalities,
        muscleGroup,
        movementPattern,
        query: { q, sort, sorter },
      },
      exerciseTags,
    },
    isModalOpen,
    user,
  } = state;

  const currentFilters = _.pick(state.rootReducer.exercise.query, [
    'body_part',
    'category_type',
    'from',
    'video_only',
    'tags',
    'modalities',
    'muscle_groups',
    'movement_patterns',
    'equipments',
  ]);

  return {
    bodyParts: body_parts,
    categories,
    currentFilters,
    q,
    listExercise,
    selectedExercise,
    exerciseTags,
    isModalOpen,
    user,
    equipments,
    modalities,
    muscleGroup,
    movementPattern,
    sort,
    sorter,
  };
};

const mapDispatch = dispatch => {
  return {
    getExerciseLibrary: bindActionCreators(getExerciseLibrary, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
    hideMultiExercises: bindActionCreators(hideMultiExercises, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    createNewTag: bindActionCreators(createNewTag, dispatch),
    addBulkAddTagExercises: bindActionCreators(addBulkAddTagExercises, dispatch),
    resetSelectedExercise: bindActionCreators(resetSelectedExercise, dispatch),
  };
};

export default connect(mapState, mapDispatch)(ExerciseLibraryToolbar);
