import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import { ANNOUNCEMENT_SCHEDULE_TYPES, CDN_URL } from 'constants/commonData';
import SelectDayCalendar from 'shared/SelectDayCalendar';

const SCHEDULES = [
  { key: 'single', text: 'Single Day', value: ANNOUNCEMENT_SCHEDULE_TYPES.SINGLE },
  { key: 'multiple', text: 'Multiple Days', value: ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE },
];

export default ({ onUpdateSchedule, onUpdateStartDay, onUpdateEndDay, schedule, startDay, endDay, totalWeek }) => {
  return (
    <div className="schedule">
      <Dropdown
        value={schedule}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        options={SCHEDULES}
        onChange={onUpdateSchedule}
        icon={<img src={`${CDN_URL}/images/arrow_down_black.svg`} className="dropdown-icon" alt="" />}
        className="new-ui"
      />
      <SelectDayCalendar value={startDay} totalWeek={totalWeek} onChange={onUpdateStartDay} singleDay />
      {schedule === ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE && (
        <>
          <img src={`${CDN_URL}/images/arrow_long_right.svg`} alt="" />
          <SelectDayCalendar
            value={endDay}
            totalWeek={totalWeek || 6}
            onChange={onUpdateEndDay}
            isValidDay={day => day > startDay}
            startDay={Number(startDay)}
          />
        </>
      )}
    </div>
  );
};
