import React from 'react';
import { get } from 'lodash';

// import { ReactComponent as ForumIcon } from 'assets/icons/forum_asset.svg';
import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import UpgradePath from 'shared/UpgradePath';
import { CDN_URL } from 'constants/commonData';
import { ReactComponent as ForumGrayIcon } from 'assets/icons/forum-gray-icon.svg';

import AssetCard from './AssetCard';

const defaultImage = `${CDN_URL}/images/default_forum_banner.svg`;

export default function ForumAsset({ data, onDeleteAsset, isEdit, cloudfrontList }) {
  const background = get(data, 'asset_data.banner', null);
  return (
    <UpgradePath
      pathName="forum"
      fallback={
        <AssetCard
          icon={<ForumGrayIcon />}
          title={data.asset_data.name}
          asset_type={data.asset_type}
          onDeleteAsset={onDeleteAsset}
          isEdit={isEdit}
          disabled={true}
          disabledText="inactive"
        >
          <span className="badge">Forum</span>
          <span className="info-text">
            {data.asset_data.no_of_members} {pluralize('MEMBER', data.asset_data.no_of_members)}
          </span>
        </AssetCard>
      }
    >
      <AssetCard
        icon={
          data.is_deleted ? (
            <ForumGrayIcon />
          ) : (
            <img
              src={background ? convertS3UrlToCloudFrontUrl(background, cloudfrontList, true) : defaultImage}
              alt="cover"
            />
          )
        }
        title={data.asset_data.name}
        asset_type={data.asset_type}
        onDeleteAsset={onDeleteAsset}
        isEdit={isEdit}
        disabled={data.is_deleted}
        disabledText="deleted"
      >
        <span className="badge">Forum</span>
        <span className="info-text">
          {data.asset_data.no_of_members} {pluralize('MEMBER', data.asset_data.no_of_members)}
        </span>
      </AssetCard>
    </UpgradePath>
  );
}
