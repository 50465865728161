import axios from 'axios';
import { showError } from 'actions/error';
import { toggleModal } from 'actions/modal';
import { showSuccess, hideSuccess } from 'redux/modal/modal.actionCreators';
import { getQueryParamsFromObject } from 'utils/commonFunction';
import _ from 'lodash';
import Request from 'configs/request';
import { logViewExcercise, logSearchExcercise } from 'libs/firebase-analytics';
import { updateAllExercises } from 'redux/workout-builder/actions';

const CancelToken = axios.CancelToken;
const cancelRequests = [];

export const Types = {
  LOAD_MOST_RECENT_EXERCISE_SUCCESS: 'LOAD_MOST_RECENT_EXERCISE_SUCCESS',
  EXERCISE_LIBRARY_UPDATE_SHARING_STATUS: 'EXERCISE_LIBRARY_UPDATE_SHARING_STATUS',
  EXERCISE_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS: 'EXERCISE_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS',
  EXERCISE_LIBRARY_FAILED_UPDATE_SHARING_STATUS: 'EXERCISE_LIBRARY_FAILED_UPDATE_SHARING_STATUS',
  EXERCISE_LIBRARY_SUCCESS_GET_LIST: 'EXERCISE_LIBRARY_SUCCESS_GET_LIST',
  EXERCISE_LIBRARY_FAILED_GET_LIST: 'EXERCISE_LIBRARY_FAILED_GET_LIST',
  EXERCISE_LIBRARY_CHANGE_QUERY_PARAMS: 'EXERCISE_LIBRARY_CHANGE_QUERY_PARAMS',
  EXERCISE_LIBRARY_RESET_QUERY_PARAMS: 'EXERCISE_LIBRARY_RESET_QUERY_PARAMS',
  EXERCISE_LIBRARY_LOADING_DATA: 'EXERCISE_LIBRARY_LOADING_DATA',
  EXERCISE_LIBRARY_UPDATE_SELECTED_EXERCISE: 'EXERCISE_LIBRARY_UPDATE_SELECTED_EXERCISE',
  EXERCISE_LIBRARY_HIDE_EXERCISES_SUCCESS: 'EXERCISE_LIBRARY_HIDE_EXERCISES_SUCCESS',
  EXERCISE_LIBRARY_BULK_ADD_EXERCISE_REQUEST: 'EXERCISE_LIBRARY_BULK_ADD_EXERCISE_REQUEST',
  EXERCISE_LIBRARY_BULK_ADD_EXERCISE_SUCCESS: 'EXERCISE_LIBRARY_BULK_ADD_EXERCISE_SUCCESS',
  EXERCISE_LIBRARY_BULK_ADD_EXERCISE_FAIL: 'EXERCISE_LIBRARY_BULK_ADD_EXERCISE_FAIL',
  UPDATE_TAG_IN_EXERCISES: 'UPDATE_TAG_IN_EXERCISES',
  DELETE_TAG_IN_EXERCISES: 'DELETE_TAG_IN_EXERCISES',
  REQUEST_GET_EXERCISE_EQUIPMENTS: 'REQUEST_GET_EXERCISE_EQUIPMENTS',
  SUCCESS_GET_EXERCISE_EQUIPMENTS: 'SUCCESS_GET_EXERCISE_EQUIPMENTS',
  ERROR_GET_EXERCISE_EQUIPMENTS: 'ERROR_GET_EXERCISE_EQUIPMENTS',
  REQUEST_GET_EXERCISE_MODALITIES: 'REQUEST_GET_EXERCISE_MODALITIES',
  SUCCESS_GET_EXERCISE_MODALITIES: 'SUCCESS_GET_EXERCISE_MODALITIES',
  ERROR_GET_EXERCISE_MODALITIES: 'ERROR_GET_EXERCISE_MODALITIES',
  REQUEST_GET_EXERCISE_MUSCLE_GROUP: 'REQUEST_GET_EXERCISE_MUSCLE_GROUP',
  SUCCESS_GET_EXERCISE_MUSCLE_GROUP: 'SUCCESS_GET_EXERCISE_MUSCLE_GROUP',
  ERROR_GET_EXERCISE_MUSCLE_GROUP: 'ERROR_GET_EXERCISE_MUSCLE_GROUP',
  REQUEST_GET_EXERCISE_MOVEMENT_PATTERN: 'REQUEST_GET_EXERCISE_MOVEMENT_PATTERN',
  SUCCESS_GET_EXERCISE_MOVEMENT_PATTERN: 'SUCCESS_GET_EXERCISE_MOVEMENT_PATTERN',
  ERROR_GET_EXERCISE_MOVEMENT_PATTERN: 'ERROR_GET_EXERCISE_MOVEMENT_PATTERN',
};

export const removeExercise = exerciseId => {
  return Request.delete(
    { url: `/api/exercise/delete/${exerciseId}` },
    false,
    (result, { dispatch, getState }) => {
      const {
        rootReducer: {
          exercise: { selectedExercise },
          workoutBuilder,
        },
      } = getState();
      let allExercises = [];
      try {
        const { allExercises: oldExercises } = workoutBuilder.toJS();
        allExercises = (oldExercises || []).filter(item => item._id !== exerciseId);
      } catch (error) {
        console.error(error);
      }
      dispatch(updateSelectedExercise(selectedExercise.filter(id => id !== exerciseId)));
      dispatch(toggleModal(false));
      dispatch(getExerciseLibrary());
      dispatch(updateAllExercises(allExercises));
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_REMOVE_EXERCISE', error });
    },
  );
};

export const hideExercise = exerciseId => {
  return Request.post(
    { url: `/api/exercise/hide/${exerciseId}` },
    false,
    (result, { dispatch, getState }) => {
      const {
        rootReducer: {
          exercise: { selectedExercise },
        },
      } = getState();
      dispatch(updateSelectedExercise(selectedExercise.filter(id => id !== exerciseId)));
      dispatch(toggleModal(false));
      dispatch(getExerciseLibrary());
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_HIDE_EXERCISE', error });
    },
  );
};

export const selectedExercise = (exerciseId, onSuccess, from) => {
  return dispatch => {
    let url = `/api/exercise/detail/${exerciseId}`;
    if (from) {
      url += `?from=${from}`;
    }
    return dispatch(
      Request.get(
        { url: url },
        true,
        result => {
          const exercise = result.data.data;
          logViewExcercise({ id: exercise._id, title: exercise.title });
          if (typeof onSuccess === 'function') {
            onSuccess(exercise);
          }
        },
        error => {},
      ),
    );
  };
};

export const getListExercises = params => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const currentState = getState();
      queryParams = _.pick(currentState.rootReducer.exercise, ['page', 'per_page', 'search', 'sorter', 'sort']);
    }

    return dispatch(
      Request.get(
        { url: `/api/exercise/list_light?${getQueryParamsFromObject(queryParams)}` },
        true,
        result => {
          dispatch(toggleModal(false));
          dispatch({ type: 'SUCCESS_GET_EXERCISE_LIST', payload: result.data });
        },
        error => {
          dispatch({ type: 'ERROR_GET_LIST_EXERCISE', error: error });
          dispatch(
            showError(
              'Please check your internet connection and try again.',
              'Offline',
              'Ok',
              null,
              'error-network-button',
            ),
          );
        },
      ),
    );
  };
};

export const getAllBodyparts = () => {
  return Request.get(
    { url: '/api/exercise/bodyparts' },
    false,
    (result, { dispatch }) => {
      dispatch({ type: 'SUCCESS_GET_EXERCISE_BODYPARTS', payload: result.data });
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_GET_EXERCISE_BODYPARTS', error: error });
    },
  );
};

export const getAllCategories = () => {
  return Request.get(
    { url: '/api/exercise/category' },
    false,
    (result, { dispatch }) => {
      dispatch({ type: 'SUCCESS_GET_EXERCISE_CATEGORIES', payload: result.data });
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_GET_EXERCISE_CATEGORIES', error: error });
    },
  );
};

export const getAllExerciseFields = () => {
  return Request.get(
    { url: '/api/exercise/fields' },
    false,
    (result, { dispatch }) => dispatch({ type: 'SUCCESS_GET_EXERCISE_FIELDS', payload: result.data }),
    (error, { dispatch }) => dispatch({ type: 'ERROR_GET_EXERCISE_FIELDS', error: error }),
  );
};

export const addExercise = (data, onSuccess) => {
  return Request.post({ url: '/api/exercise/add', data }, false);
};

export const cloneExercise = exerciseId => {
  return Request.post(
    { url: `/api/exercise/clone/${exerciseId}` },
    false,
    (result, { dispatch }) => {
      dispatch(toggleModal(false));

      if (result.data.data) {
        dispatch(showSuccess('Exercise has been duplicated.'));
        setTimeout(() => {
          dispatch(hideSuccess());
          dispatch(getExerciseLibrary());
        }, 1500);
      }

      dispatch(getExerciseLibrary());
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_CLONE_EXERCISE', error }),
  );
};

export const updateExercise = (params, onSuccess) => {
  return Request.put({ url: `/api/exercise/update/${params._id}`, data: params }, false);
};

const loadMostRecentSuccess = list => ({
  type: Types.LOAD_MOST_RECENT_EXERCISE_SUCCESS,
  payload: { list: list.map(ex => ({ ...ex, key: ex._id, label: ex.title, value: ex._id })) },
});

export const getMostRecentExercises = () => {
  return Request.post(
    { url: '/api/exercise/search_filter', data: { page: 1, per_page: 10 } },
    false,
    (result, { dispatch }) => {
      const { data } = result.data;

      if (data) {
        dispatch(loadMostRecentSuccess(data));
      }
    },
  );
};

export const updateExerciseSharingStatus = (data, isOwner) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.EXERCISE_LIBRARY_UPDATE_SHARING_STATUS });

    return dispatch(
      Request.put(
        { url: `/api/exercise/update_sharing/${data._id}`, data: { share: data.share } },
        true,
        () => {
          if (isOwner) {
            dispatch({ type: Types.EXERCISE_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS, payload: { data } });
          } else {
            dispatch(getExerciseLibrary());
          }
        },
        () => dispatch({ type: Types.EXERCISE_LIBRARY_FAILED_UPDATE_SHARING_STATUS }),
      ),
    );
  };
};

export const changeQueryParams = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        exercise: { query },
      },
    } = getState();

    const newData = { ...query, ...data };

    dispatch({
      type: Types.EXERCISE_LIBRARY_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });
    dispatch(getExerciseLibrary(newData));
    logSearchExcercise(newData, 'library');
  };
};

export const resetQueryParams = data => ({ type: Types.EXERCISE_LIBRARY_RESET_QUERY_PARAMS });

export const formatTags = queryParams => {
  let clonedQueryParams = _.cloneDeep(queryParams);
  let shouldFormatTags = !_.isString(_.get(clonedQueryParams, 'tags[0]', ''));
  if (shouldFormatTags) {
    clonedQueryParams.tags = queryParams.tags.map(tag => tag._id);
  }
  return clonedQueryParams;
};

export const getExerciseLibrary = params => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const {
        rootReducer: {
          exercise: { query },
        },
      } = getState();

      queryParams = query;
    }

    if (!!cancelRequests.length) {
      cancelRequests.forEach(
        cancelRequest => typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_EXERCISE_LIBRARY'),
      );
    }

    dispatch({ type: Types.EXERCISE_LIBRARY_LOADING_DATA });

    queryParams = formatTags(queryParams);
    return dispatch(
      Request.post(
        {
          url: `/api/exercise/search_filter_library`,
          data: queryParams,
          cancelToken: new CancelToken(cancelRequest => cancelRequests.push(cancelRequest)),
        },
        true,
        result => {
          const state = getState();
          const exerciseLibraryQuery = state.rootReducer.exercise.query;

          const currentQueryText = exerciseLibraryQuery.q || '';
          const currentInputValue = queryParams.q || '';

          if (currentQueryText.toLowerCase().trim() === currentInputValue.toLowerCase().trim()) {
            cancelRequests.length = 0;
            dispatch({ type: Types.EXERCISE_LIBRARY_SUCCESS_GET_LIST, payload: { data: result.data } });
          }
        },
        error => {
          if ((error || {}).message === 'CANCEL_REQUEST_SEARCH_EXERCISE_LIBRARY') return;
          dispatch({ type: Types.EXERCISE_LIBRARY_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const updateSelectedExercise = exerciseIds => {
  return { type: Types.EXERCISE_LIBRARY_UPDATE_SELECTED_EXERCISE, payload: { data: exerciseIds } };
};

export const resetSelectedExercise = () => {
  return { type: Types.EXERCISE_LIBRARY_HIDE_EXERCISES_SUCCESS };
};

export const hideMultiExercises = exerciseIds => {
  const data = {
    items: exerciseIds,
    hideForTeam: true,
  };
  return Request.post(
    { url: '/api/exercise/hide-exercise', data },
    false,
    (result, { dispatch }) => {
      dispatch(toggleModal(false));
      dispatch(getExerciseLibrary());
      dispatch({ type: Types.EXERCISE_LIBRARY_HIDE_EXERCISES_SUCCESS });
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_HIDE_EXERCISE', error });
    },
  );
};

export const getExerciseDetail = exerciseId => {
  return Request.get({ url: `/api/exercise/detail/${exerciseId}` }, true, response => {
    const { data } = response.data;
    logViewExcercise({ id: data._id, title: data.title });
  });
};

export const addBulkAddTagExercises = data => {
  return (dispatch, getState) => {
    dispatch({ type: Types.EXERCISE_LIBRARY_BULK_ADD_EXERCISE_REQUEST });
    return dispatch(
      Request.post(
        { url: '/api/exercise/bulk-add-tag', data },
        true,
        (result, { dispatch }) => {
          const { data } = result.data;
          if (data) {
            dispatch(getExerciseLibrary());
            dispatch(updateSelectedExercise([]));
            if (data.listInvalidExerciseTitle.length) {
              const message = `You are not authorized to edit these exercises: ${data.listInvalidExerciseTitle.join(
                ', ',
              )}`;
              dispatch(showError(message.length > 165 ? `${message.substring(0, 165)}...` : message));
            }
          }
          dispatch({ type: Types.EXERCISE_LIBRARY_BULK_ADD_EXERCISE_SUCCESS });
        },
        (error, { dispatch }) => {
          dispatch({ type: 'ERROR_HIDE_EXERCISE', error });
        },
      ),
    );
  };
};

export const updateTagInExercises = (id, name) => ({
  type: Types.UPDATE_TAG_IN_EXERCISES,
  payload: {
    id,
    name,
  },
});

export const deleteTagInExercises = id => ({
  type: Types.DELETE_TAG_IN_EXERCISES,
  payload: {
    id,
  },
});

export const getAllExerciseEquipments = () => dispatch => {
  dispatch({ type: Types.REQUEST_GET_EXERCISE_EQUIPMENTS });

  return dispatch(
    Request.get(
      { url: '/api/exercise-equipments' },
      false,
      result => {
        const { data: { total, data } = {} } = result;
        dispatch({ type: Types.SUCCESS_GET_EXERCISE_EQUIPMENTS, payload: data });
      },
      error => {
        dispatch({ type: Types.ERROR_GET_EXERCISE_EQUIPMENTS, error: error });
      },
    ),
  );
};

export const getAllExerciseModalities = () => dispatch => {
  dispatch({ type: Types.REQUEST_GET_EXERCISE_MODALITIES });

  return dispatch(
    Request.get(
      { url: '/api/exercise-modalities' },
      false,
      result => {
        const { data: { total, data } = {} } = result;
        dispatch({ type: Types.SUCCESS_GET_EXERCISE_MODALITIES, payload: data });
      },
      error => {
        dispatch({ type: Types.ERROR_GET_EXERCISE_MODALITIES, error: error });
      },
      false,
    ),
  );
};

export const getAllExerciseMuscleGroup = () => dispatch => {
  dispatch({ type: Types.REQUEST_GET_EXERCISE_MUSCLE_GROUP });

  return dispatch(
    Request.get(
      { url: '/api/exercise-muscle-groups' },
      false,
      result => {
        const { data: { total, data } = {} } = result;
        dispatch({ type: Types.SUCCESS_GET_EXERCISE_MUSCLE_GROUP, payload: data });
      },
      error => {
        dispatch({ type: Types.ERROR_GET_EXERCISE_MUSCLE_GROUP, error: error });
      },
      false,
    ),
  );
};

export const getAllExerciseMovementPattern = () => dispatch => {
  dispatch({ type: Types.REQUEST_GET_EXERCISE_MOVEMENT_PATTERN });

  return dispatch(
    Request.get(
      { url: '/api/exercise-movement-patterns' },
      false,
      result => {
        const { data: { total, data } = {} } = result;
        dispatch({ type: Types.SUCCESS_GET_EXERCISE_MOVEMENT_PATTERN, payload: data });
      },
      error => {
        dispatch({ type: Types.ERROR_GET_EXERCISE_MOVEMENT_PATTERN, error: error });
      },
      false,
    ),
  );
};
