// libs
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import Axios from 'axios';
import moment, { duration } from 'moment';
import ReactTooltip from 'react-tooltip';

// components
import { formatDateChild } from '../NormalMetricProgress';
import ResultPopupSpecialChart from '../SpecialChart/SleepResultPopup';
import ConfirmModalGroup from 'components/MetricGroupLibrary/Parts/ConfirmModal';

// Assets
import { ReactComponent as AppleHealth } from 'assets/icons/ConnectedApp/apple_health.svg';
import { ReactComponent as Fitbit } from 'assets/icons/ConnectedApp/fitbit.svg';
import { ReactComponent as Garmin } from 'assets/icons/ConnectedApp/garmin.svg';
import { ReactComponent as GoogleFit } from 'assets/icons/ConnectedApp/google_fit.svg';
import { ReactComponent as OuraIcon } from 'assets/icons/ConnectedApp/oura_icon.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-select-down-middle.svg';
import { ReactComponent as EditIcon } from 'assets/icons/metric-edit-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete-trash.svg';
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import { TooltipStyle } from 'components/BodyMetricChartNew/components/KeysMetric/style';
import { axiosInstance } from 'configs/request';
import { pluralize } from 'utils/commonFunction';
import { renderDuration } from '../SpecialChart/sleep-helper';
import LoadingIndicator from 'shared/LoadingIndicator';
import { LEGEND_STAGE_ORDER, SLEEP_STATES } from '../../BodyMetricChartNew/components/SpecialChart/Sleep/constants';
import { CDN_URL } from 'constants/commonData';

// style
import * as S from './style';

export const APP_INTEGRATION = {
  apple_health: {
    name: 'apple_health',
    icon: <AppleHealth />,
  },
  fitbit: {
    name: 'fitbit',
    icon: <Fitbit />,
  },
  garmin: {
    name: 'garmin',
    icon: <Garmin />,
  },
  google_fit: {
    name: 'google_fit',
    icon: <GoogleFit />,
  },
  oura: {
    name: 'oura',
    icon: <OuraIcon />,
  },
};

const CancelToken = Axios.CancelToken;
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export function AllEntriesSleepByDay(props) {
  const {
    toggleModal,
    timezone,
    toggleConfirmModal,
    selectedEntry,
    metricTypes,
    selectedMetric,
    dateFormat,
    clientId,
    confirmRemoveEntry,
    updateSleepEntry,
  } = props;

  const [editResult, setEditResult] = useState(null);
  const [allEntries, setAllEntries] = useState([]);
  const [hasSyncedData, setHasSyncedData] = useState(false);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isOuraPermission = process.env.REACT_APP_ENABLE_OURA_INTEGRATION === 'true';

  const axiosToken = useRef(null);

  const handleClose = () => {
    if (editResult) return;
    toggleModal && toggleModal(false);
  };

  const selectedDay = useMemo(() => {
    return formatDateChild(
      moment(`${selectedEntry.end} ${selectedEntry.end_time}`, DATE_TIME_FORMAT).toISOString(),
      timezone,
    );
  }, []);

  const fetchEntryByDate = async (page = 1) => {
    const { start, end } = selectedEntry;
    const params = {
      client: clientId,
      from_date: start,
      to_date: end,
      page,
      per_page: 100,
      // sort: 1,
    };

    try {
      const res = await axiosInstance({
        method: 'GET',
        url: '/api/sleep-metric-entries',
        params,
        cancelToken: new CancelToken(function executor(c) {
          axiosToken.current = c;
        }),
      });
      const { data = [] } = res || { data: [] };

      if (data.length === 0) {
        toggleModal(false);
        return;
      }

      const isHasSyncData = data.some(item => !['trainer', 'client'].includes(item.source));
      let finalData = data;

      function calculateTotalDuration(data) {
        let totalDurationOldData = 0;

        data.forEach(item => {
          item.start = item.start_time ? moment(item.start_time, 'YYYY-MM-DD HH:mm') : null;
          item.end = item.end_time ? moment(item.end_time, 'YYYY-MM-DD HH:mm') : null;
          if (item.is_old_data) {
            totalDurationOldData += item.duration;
          }
        });

        let mergedIntervals = [];
        data.forEach(item => {
          let isMerged = false;
          if (!item.start || !item.end) return;
          for (let i = 0; i < mergedIntervals.length; i++) {
            if (item.start.isBefore(mergedIntervals[i].end) && item.end.isAfter(mergedIntervals[i].start)) {
              mergedIntervals[i].start = moment.min(mergedIntervals[i].start, item.start);
              mergedIntervals[i].end = moment.max(mergedIntervals[i].end, item.end);
              isMerged = true;
              break;
            }
          }
          if (!isMerged) {
            mergedIntervals.push({ start: item.start, end: item.end });
          }
        });

        const totalDuration = mergedIntervals.reduce((acc, interval) => {
          return acc + interval.end.diff(interval.start, 'minutes');
        }, 0);

        return totalDurationOldData + (isNaN(totalDuration) ? 0 : totalDuration);
      }

      if (isHasSyncData) {
        const stageGrouped = data.reduce((obj, item) => {
          if (obj[item.sleep_stage]) {
            obj[item.sleep_stage] = {
              ...obj[item.sleep_stage],
              items: [...obj[item.sleep_stage].items, item],
            };
          } else {
            obj[item.sleep_stage] = {
              sleep_stage: item.sleep_stage,
              items: [item],
            };
          }
          return obj;
        }, {});

        finalData = [];
        LEGEND_STAGE_ORDER.forEach(stage => {
          if (stageGrouped[stage]) {
            const group = stageGrouped[stage];
            finalData.push({
              ...group,
              _id: stage,
              belong_day: group.items[0].belong_day,
              start_time: group.items[0].start_time,
              end_time: group.items[0].end_time,
              duration: calculateTotalDuration(group.items),
            });
          }
        });
      }

      setHasSyncedData(isHasSyncData);
      setAllEntries(finalData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEntryByDate();

    return () => {
      if (axiosToken.current) {
        axiosToken.current();
        axiosToken.current = null;
      }
    };
  }, []);

  const handleCloseAddResultPopup = () => {
    setEditResult(null);
  };

  const handleSaveResultClick = async data => {
    try {
      await updateSleepEntry(data);
      setEditResult(null);
      fetchEntryByDate();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditResultClick = item => () => {
    setEditResult(item);
  };

  const confirmedRemove = async item => {
    try {
      await confirmRemoveEntry(item);
      const isLastEntry = hasSyncedData ? allEntries.flatMap(item => item.items) : allEntries.length === 1;

      if (isLastEntry) {
        handleClose();
      } else {
        setAllEntries(state => {
          if (hasSyncedData) {
            return state.map(entryGrouped => {
              return {
                ...entryGrouped,
                items: entryGrouped.items.filter(entry => entry._id !== item._id),
              };
            });
          }
          return state.filter(entry => entry._id !== item._id);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveResultItem = item => () => {
    toggleConfirmModal(
      true,
      <ConfirmModalGroup
        modalId="delete-result-modal"
        title="Remove Result"
        content={`Are you sure you want to remove this result ?`}
        onConfirm={() => confirmedRemove(item)}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        hasCloseIcon
        toggleConfirmModal={toggleConfirmModal}
      />,
    );
  };

  const handleDetailGroup = _id => () => {
    setExpandedGroup(expandedGroup !== _id ? _id : null);
  };

  const renderItem = (entry, index) => {
    if (hasSyncedData) return renderEntriesItemByWeek(entry, index);

    return renderAllEntries(entry, index);
  };

  const renderTime = item => {
    const { start_time, end_time } = item;
    if (!start_time || !end_time) return '--';

    const startMM = moment(start_time, DATE_TIME_FORMAT);
    const endMM = moment(end_time, DATE_TIME_FORMAT);
    return `${startMM.format('h:mm A')} - ${endMM.format('h:mm A')}`;
  };

  const renderEntriesItemByWeek = (entry, index) => {
    const { _id, end_time, items = [], sleep_stage, duration, belong_day } = entry;
    const formattedDate = formatDateChild(
      moment(end_time || belong_day, end_time ? DATE_TIME_FORMAT : 'YYYY-MM-DD').toISOString(),
      timezone,
    );
    const isExpanded = _id === expandedGroup;

    return (
      <Fragment key={`${_id}-${index}`}>
        <S.FragmentRow>
          <S.EntryItemWrapper key={_id} onClick={handleDetailGroup(_id)} isExpanded={isExpanded}>
            <S.ContentItem width="120px" paddingLeft="23px">
              <span className="date-label">{formattedDate}</span>
            </S.ContentItem>
            <S.ContentItem width="165px" paddingLeft="23px">
              {SLEEP_STATES[sleep_stage]}
            </S.ContentItem>
            <S.ContentItem width="143px" paddingLeft="2px">
              {renderDuration(duration)}
            </S.ContentItem>
            <S.ContentItem width="250px" paddingLeft="3px">
              <span>{hasSyncedData ? pluralize('interval', items.length, true) : renderTime(entry)}</span>
              <ArrowDownIcon className="arrow-down" width={13} />
            </S.ContentItem>
          </S.EntryItemWrapper>
          {items.map(item => {
            const icon = !!item.source ? (APP_INTEGRATION[item.source] || {}).icon : null;
            const isEditing = editResult && editResult._id === item._id;

            // Handle to hide oura
            if (!isOuraPermission && item.source === APP_INTEGRATION.oura.name) return null;

            return (
              <S.ChildItemRows key={item._id} isExpanded={isExpanded} active={isEditing} connectApp={!!icon}>
                <S.EntryChildWrapper>
                  <S.ContentItem width="120px" paddingLeft="23px">
                    <span className="date-label">{formattedDate}</span>
                  </S.ContentItem>
                  <S.ContentItem width="140px" paddingLeft="0">
                    {SLEEP_STATES[item.sleep_stage]}
                    {icon ? icon : null}
                  </S.ContentItem>
                  <S.ContentItem width="143px">{renderDuration(item.duration)}</S.ContentItem>
                  <S.ContentItem width="250px" paddingLeft="3px">
                    <span>{renderTime(item)}</span>
                    {['trainer', 'client'].includes(item.source) && renderActions(item, isEditing)}
                  </S.ContentItem>
                </S.EntryChildWrapper>
              </S.ChildItemRows>
            );
          })}
        </S.FragmentRow>
      </Fragment>
    );
  };

  const renderActions = (entry, isEditing) => {
    const { _id } = entry;
    return (
      <S.EntryAction>
        <ResultPopupSpecialChart
          positionEdit="bottom right"
          newUI
          editData={entry}
          isOpen={isEditing}
          key={`${_id}_${isEditing ? 'open' : 'close'}`}
          timezone={timezone}
          dateFormat={dateFormat}
          metric={selectedMetric}
          metricTypes={metricTypes}
          onClose={handleCloseAddResultPopup}
          onSave={handleSaveResultClick}
          onOpen={handleEditResultClick(entry)}
          className="edit-entry-popup"
          trigger={
            <S.EditWrapper
              onClick={e => e.preventDefault()}
              data-tip
              data-for={`edit-result-on-tooltip-${_id}`}
              active={isEditing}
            >
              <EditIcon className="edit-entry-icon" />
              <ReactTooltip
                className="app-tooltip edit-result-on-tooltip"
                id={`edit-result-on-tooltip-${_id}`}
                effect="solid"
                place={'top'}
              >
                <TooltipStyle>Edit Result</TooltipStyle>
              </ReactTooltip>
            </S.EditWrapper>
          }
        />
        <S.RemoveWrapper onClick={handleRemoveResultItem(entry)} data-tip data-for={`remove-result-on-tooltip-${_id}`}>
          <TrashIcon className="trash-icon" />
          <ReactTooltip
            className="app-tooltip remove-result-on-tooltip"
            id={`remove-result-on-tooltip-${_id}`}
            effect="solid"
            place={'top'}
          >
            <TooltipStyle>Remove Result</TooltipStyle>
          </ReactTooltip>
        </S.RemoveWrapper>
      </S.EntryAction>
    );
  };

  const renderAllEntries = (entry, index) => {
    const { _id, end_time, sleep_stage, duration, belong_day } = entry;
    const isEditing = editResult && editResult._id === _id;
    const formattedDate = formatDateChild(
      moment(end_time || belong_day, end_time ? DATE_TIME_FORMAT : 'YYYY-MM-DD').toISOString(),
      timezone,
    );
    const isExpanded = _id === expandedGroup;

    return (
      <Fragment key={`${_id}-${index}`}>
        <S.FragmentRow>
          <S.EntryItemWrapper
            key={_id}
            active={isEditing}
            onClick={handleDetailGroup(_id)}
            isExpanded={isExpanded}
            isNotGroup={!hasSyncedData}
          >
            <S.ContentItem width="120px" paddingLeft="23px">
              <span className="date-label">{formattedDate}</span>
            </S.ContentItem>
            <S.ContentItem width="165px" paddingLeft="23px">
              {SLEEP_STATES[sleep_stage]}
            </S.ContentItem>
            <S.ContentItem width="143px" paddingLeft="2px">
              {renderDuration(duration)}
            </S.ContentItem>
            <S.ContentItem width="250px" paddingLeft="3px">
              <span>{renderTime(entry)}</span>
              {['trainer', 'client'].includes(entry.source) && renderActions(entry, isEditing)}
            </S.ContentItem>
          </S.EntryItemWrapper>
        </S.FragmentRow>
      </Fragment>
    );
  };

  return (
    <S.CustomModal
      open={true}
      closeOnDimmerClick={false}
      className="all-entries-modal"
      onClose={handleClose}
      width="700px"
    >
      <Modal.Header className="modal-meal-plan-header">
        <S.HeaderWrapper>
          <S.HeaderTitle>{selectedDay} Entries</S.HeaderTitle>
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <S.HeaderTable>
            <S.HeaderItem widthItem="120px" paddingLeft="24px">
              Date
            </S.HeaderItem>
            <S.HeaderItem widthItem="165px" paddingLeft="24px">
              State
            </S.HeaderItem>
            <S.HeaderItem widthItem="143px" paddingLeft="0">
              Duration
            </S.HeaderItem>
            <S.HeaderItem widthItem="250px" paddingLeft="0">
              Sleep Time
            </S.HeaderItem>
          </S.HeaderTable>
          <S.ContentTable hasSyncedData={hasSyncedData}>
            {isLoading ? (
              <div className="loading-wrapper">
                <LoadingIndicator title="" />
              </div>
            ) : (
              allEntries.map(renderItem)
            )}
          </S.ContentTable>
        </S.ContentWrapper>
      </Modal.Content>
    </S.CustomModal>
  );
}
