import React from 'react';
import moment from 'moment';
import * as S from './style';
import CustomBrandingStatusBar from 'shared/CustomBrandingStatusBar';
import CustomBrandingFooter from 'shared/CustomBrandingFooter';
import { CDN_URL } from 'constants/commonData';

export default props => {
  return (
    <S.Wrapper {...props}>
      <S.NotificationIcon />
      <CustomBrandingStatusBar />
      <S.Header>
        <div className="today">Today,&nbsp;{moment(new Date()).format('MMM D')}</div>
        <div className="hello">
          <span>Hello John! 👋</span>
        </div>
      </S.Header>
      <S.Content>
        <div className="let-do-this">Let’s do this</div>
        <S.Workout>
          <S.WorkoutContent>
            <div className="workout__title">TODAY’S WORKOUT</div>
            <div className="workout__name">Night Chest and Shoulder Workout</div>
            <S.StartWorkout>Start Workout</S.StartWorkout>
          </S.WorkoutContent>
        </S.Workout>
        <S.Task>
          <div className="task__header">Tasks (1/2)</div>
          <S.TaskItem>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="active-checkbox"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.478271 6.87264C0.478271 10.4599 3.38378 13.3654 6.97102 13.3654C10.5583 13.3654 13.4638 10.4599 13.4638 6.87264C13.4638 3.28539 10.5583 0.379883 6.97102 0.379883C3.38378 0.379883 0.478271 3.28539 0.478271 6.87264ZM9.21117 4.74626C9.40596 4.55147 9.71436 4.55147 9.90914 4.74626C10.1039 4.94104 10.1039 5.26568 9.92537 5.46046L6.46798 8.99901C6.2732 9.19379 5.9648 9.19379 5.77001 8.99901L4.0332 7.2135C3.83842 7.01872 3.83842 6.69408 4.0332 6.4993C4.22798 6.30452 4.53639 6.30452 4.73117 6.4993L6.11088 7.9277L9.21117 4.74626Z"
                fill="#E6E6E6"
              />
            </svg>
            <S.TaskName>Track these body metrics</S.TaskName>
            <img src={`${CDN_URL}/images/right_arrow_grey.svg`} alt="" />
          </S.TaskItem>
          <S.TaskDivide />
          <S.TaskItem>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.478271 6.87264C0.478271 10.4599 3.38378 13.3654 6.97102 13.3654C10.5583 13.3654 13.4638 10.4599 13.4638 6.87264C13.4638 3.28539 10.5583 0.379883 6.97102 0.379883C3.38378 0.379883 0.478271 3.28539 0.478271 6.87264ZM9.21117 4.74626C9.40596 4.55147 9.71436 4.55147 9.90914 4.74626C10.1039 4.94104 10.1039 5.26568 9.92537 5.46046L6.46798 8.99901C6.2732 9.19379 5.9648 9.19379 5.77001 8.99901L4.0332 7.2135C3.83842 7.01872 3.83842 6.69408 4.0332 6.4993C4.22798 6.30452 4.53639 6.30452 4.73117 6.4993L6.11088 7.9277L9.21117 4.74626Z"
                fill="#E6E6E6"
              />
            </svg>
            <S.TaskName>Take progress picture</S.TaskName>
            <img src={`${CDN_URL}/images/right_arrow_grey.svg`} alt="" />
          </S.TaskItem>
        </S.Task>
      </S.Content>
      <CustomBrandingFooter active={0} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} />
    </S.Wrapper>
  );
};
