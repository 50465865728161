import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { Prompt } from 'react-router';
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import Redirect from 'react-router-dom/Redirect';
import Select from 'react-select';

import * as S from './style';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/file-document.svg';
import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { updatePreferences } from 'redux/studio-program/actions';
import { DropdownIndicator } from 'shared/Icons';
import { isTeamAdmin } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';

const DEFAULT_OPTION_VALUE = 'all_workouts';

const OPTIONS_SHOW_DETAIL_OF_WORKOUTS = {
  all_workouts: {
    label: 'Show all workouts',
    value: 'all_workouts',
  },
  do_not_show: {
    label: 'Do not show details',
    value: 'do_not_show',
  },
  show_week_1: {
    label: 'Show Week 1',
    value: 'show_week_1',
  },
  show_weeks_1_2: {
    label: 'Show Weeks 1 - 2',
    value: 'show_weeks_1_2',
  },
};

function StudioProgramSettings(props) {
  const {
    studioProgramId,
    studioProgramName,
    optionShowDetail,
    toggleConfirmModal,
    pushAction,
    updatePreferences,
    user,
    workingStudio,
  } = props;

  if (!process.env.REACT_APP_STUDIO_PROGRAM_SETTINGS && studioProgramId) {
    return <Redirect to={`/home/studio-programs/${studioProgramId}`} />;
  }

  const author = typeof workingStudio.author_info === 'object' ? workingStudio.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = workingStudio.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

  const [valueDetail, setValueDetail] = useState(OPTIONS_SHOW_DETAIL_OF_WORKOUTS[optionShowDetail]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setValueDetail(OPTIONS_SHOW_DETAIL_OF_WORKOUTS[optionShowDetail]);
    setHasChanges(false);
  }, [optionShowDetail]);

  const onChangeShowDetail = it => {
    if (it.value !== optionShowDetail) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
    setValueDetail(it);
  };

  const handleSave = () => {
    updatePreferences(valueDetail.value);
  };

  const handleConfirmSaveChange = async pathname => {
    handleSave();
    await setHasChanges(false);
    pushAction(pathname);
  };

  const handleCloseAnyway = async pathname => {
    await setHasChanges(false);
    pushAction(pathname);
  };

  const handleBlockedNavigation = nextLocation => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Save changes?"
        content="You have made changes that have not been saved yet. Would you like save your changes before leaving?"
        confirmButtonTitle="Save"
        cancelButtonTitle="Exit without saving"
        className="studio-program-confirm-popup"
        headerIcon={`${CDN_URL}/images/warning_purple_Icon.svg`}
        onConfirm={() => handleConfirmSaveChange(nextLocation.pathname)}
        onDeny={() => handleCloseAnyway(nextLocation.pathname)}
        newStyle
        largeSpacing
        hasHoverState
      />,
    );
    return false;
  };

  return (
    <S.Container>
      <Helmet>
        <title>{studioProgramName} - Studio Program - Everfit</title>
      </Helmet>
      <Prompt when={hasChanges} message={handleBlockedNavigation} />
      <S.Wrapper>
        <S.HeaderTitle>
          <S.Title>Studio Program Settings</S.Title>
          <S.ButtonSave disabled={!hasChanges} onClick={handleSave}>
            <SaveIcon />
            Save
          </S.ButtonSave>
        </S.HeaderTitle>

        <S.Card>
          <div>
            <S.ShowDetailWorkoutTitle>
              Show details of workouts
              <S.FeatureLearnMore
                href="https://help.everfit.io/en/articles/7919390-show-workout-details-for-studio-programs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreIcon />
                <S.FeatureLinkTitle>Learn more</S.FeatureLinkTitle>
              </S.FeatureLearnMore>
            </S.ShowDetailWorkoutTitle>
            <S.ShowDetailWorkoutDesc>
              Choose if clients can see details of each workout in the Studio Program Overview
            </S.ShowDetailWorkoutDesc>
          </div>
          <div>
            <Select
              options={toArray(OPTIONS_SHOW_DETAIL_OF_WORKOUTS)}
              menuPosition="fixed"
              value={valueDetail}
              onChange={onChangeShowDetail}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              isSearchable={false}
              styles={customStyles}
              classNamePrefix="show-detail-of-workouts"
              className="select-show-detail-of-workouts"
              isDisabled={!isCreatorOrOwnerOrAdmin}
            />
          </div>
        </S.Card>
      </S.Wrapper>
    </S.Container>
  );
}

export const customStyles = {
  container: base => ({
    ...base,
    background: '#FFFFFF',
    borderRadius: '5px',
    pointerEvents: 'auto',
  }),
  control: (base, state) => ({
    ...base,
    minHeight: '46px',
    height: '46px',
    minWidth: '323px',
    borderColor: state.isFocused ? '#5158CF' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5158CF',
    },
    cursor: 'pointer',
  }),
  singleValue: base => ({
    ...base,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#323C47',
    marginLeft: '0',
    marginRight: '0',
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: '13px',
  }),
  indicatorsContainer: base => ({
    ...base,
    '> div': {
      paddingRight: '17px !important',
      paddingTop: '9px !important',
    },
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    width: '222px',
    background: '#FFFFFF',
    border: '1px solid #D4D7D9',
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: '10px',
    paddingBottom: '10px',
  }),
  option: base => ({
    ...base,
    backgroundColor: '#ffffff',
    ':hover': {
      backgroundColor: '#F6F5FF',
      fontWeight: '600',
      color: '#5C5BBD',
    },
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: '14px',
    cursor: 'pointer',
    maxHeight: '32px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#000000',
  }),
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      studioProgram: { workingStudio },
    },
  } = state;

  const studioProgramId = get(workingStudio, '_id');
  const optionShowDetail = get(workingStudio, 'preferences.show_detail_of_workouts', DEFAULT_OPTION_VALUE);
  const studioProgramName = get(workingStudio, 'program_name', '');

  return { user: state.user, workingStudio, studioProgramName, optionShowDetail, studioProgramId };
};

const mapDispatchToProps = dispatch => ({
  pushAction: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updatePreferences: bindActionCreators(updatePreferences, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudioProgramSettings);
