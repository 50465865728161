import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const BroadcastModal = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
