import { Types } from './actions';

const INITIAL_STATE = {
  title: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.ON_DEMAND_SETTINGS_UPDATE_TITLE_REQUEST:
      return Object.assign({}, state, {
        title: payload.data,
      });

    case Types.ON_DEMAND_SETTINGS_GET_TITLE_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.ON_DEMAND_SETTINGS_GET_TITLE_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        title: payload.data.title,
      });
    }

    default:
      return state;
  }
};
