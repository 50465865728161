import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { get, debounce } from 'lodash';
import { connect } from 'react-redux';

import TextEditable from 'shared/TextEditable';
import { SECTION_FORMAT, SECTION_FORMAT_KEY } from 'constants/commonData';
import Dropdown, { Option, Trigger } from 'shared/Dropdown/Basic';
import { OptionIcon } from '../../../styles';
import CustomizeForm from './CustomizeForm';
import FormatInput from './FormatInput';
import { SharedTooltip } from 'shared/SharedTooltip';
import { ENTITY_NAME } from 'database/constants';

import * as S from './style';

function SectionHeader(props) {
  const { section, minimize, sectionId, sectionSize, sectionIndex, supersetEntities, exerciseEntities } = props;
  const [showCustomizeForm, setShowCustomizeForm] = useState(false);

  const isHasExerciseNotExisted = useMemo(() => {
    if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
      const items = section.exercise_references || [];
      if (items.length === 0) return false;
      return items.some(item => item.is_existing === false);
    }

    // other sections
    const exerciseIds = section.exercises || [];
    if (exerciseIds.length === 0) return false;

    return exerciseIds.some(exId => {
      const { supersets: supersetIds } = exerciseEntities[exId] || {};
      const isNotExist = (supersetIds || []).some(sId => {
        return get(supersetEntities, `${sId}.exercise_instance.is_existing`, true) === false;
      });
      return isNotExist;
    });
  }, [exerciseEntities, supersetEntities, section]);

  const onBlurTitleInput = event => {
    event.target.scrollLeft = 0;
  };

  const openCustomizeForm = () => {
    setShowCustomizeForm(true);
  };

  const closeCustomizeForm = () => {
    setShowCustomizeForm(false);
  };

  const onUpdate = data => {
    setShowCustomizeForm(false);
    props.onCustomize(data);
  };

  const onMoveUp = () => {
    props.changeOrderSection({
      sectionId,
      fromIndex: sectionIndex,
      toIndex: sectionIndex - 1,
    });
  };

  const onMoveDown = () => {
    props.changeOrderSection({
      sectionId,
      fromIndex: sectionIndex,
      toIndex: sectionIndex + 1,
    });
  };

  const renderTrigger = ({ open }) => (
    <>
      {!open && <SharedTooltip id="section-option-tooltip" />}
      <Trigger data-tip data-for="section-option-tooltip" />
    </>
  );

  return (
    <S.Wrapper className="section__header">
      <div className="section__header__leftSide">
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classnames('toggle', { minimize: minimize })}
          onClick={props.toggleSectionView}
        >
          <path
            d="M5.99922 7.125L1.94922 3.75L2.99922 2.875L5.99922 5.375L8.99922 2.875L10.0492 3.75L5.99922 7.125Z"
            fill="#9DA0C7"
          />
        </svg>
        <TextEditable
          value={section.title}
          onChange={debounce(props.onTitleChange, 500)}
          placeholder="Section Name"
          lineHeight={19}
          className="section__title"
          onBlur={onBlurTitleInput}
          invalid={!section.title}
        />
        <S.SectionFormat className="section__formatName">-&nbsp;&nbsp;{SECTION_FORMAT[section.format]}</S.SectionFormat>
      </div>
      <div className="section__header__rightSide">
        <FormatInput sectionId={props.sectionId} />
        <div className="section__header__actions__group">
          <Dropdown type="light" className="actions" alwaysShow={showCustomizeForm} triggerIcon={renderTrigger}>
            {section.format !== SECTION_FORMAT_KEY.FREESTYLE && (
              <Option key="customize" onClick={openCustomizeForm}>
                <OptionIcon icon="pen_777C8F" hoverIcon="pen_5158CF">
                  Customize Section
                </OptionIcon>
              </Option>
            )}
            {!isHasExerciseNotExisted && !showCustomizeForm && (
              <Option key="duplicate" onClick={props.onDuplicate}>
                <OptionIcon icon="duplicate_777C8F" hoverIcon="duplicate_5158CF">
                  Duplicate
                </OptionIcon>
              </Option>
            )}
            {!isHasExerciseNotExisted && !showCustomizeForm && (
              <Option key="save" onClick={props.onSaveToLibrary}>
                <OptionIcon icon="save_777C8F" hoverIcon="save_5158CF">
                  Save to Library
                </OptionIcon>
              </Option>
            )}
            {!showCustomizeForm && sectionIndex !== 0 ? (
              <Option key="move-up" onClick={onMoveUp}>
                <OptionIcon icon="arrow_up_grey" hoverIcon="arrow_up_grey_hover">
                  Move Up
                </OptionIcon>
              </Option>
            ) : null}
            {!showCustomizeForm && sectionSize > sectionIndex + 1 ? (
              <Option key="move-down" onClick={onMoveDown}>
                <OptionIcon icon="arrow_down_grey" hoverIcon="arrow_down_grey_hover">
                  Move Down
                </OptionIcon>
              </Option>
            ) : null}
            {!showCustomizeForm && (
              <Option key="remove" onClick={props.onRemove}>
                <OptionIcon icon="new_delete" hoverIcon="new_delete_purple">
                  Remove
                </OptionIcon>
              </Option>
            )}
            {showCustomizeForm && (
              <CustomizeForm
                format={section.format}
                type={section.type}
                onUpdate={onUpdate}
                onCancel={closeCustomizeForm}
              />
            )}
          </Dropdown>
        </div>
      </div>
    </S.Wrapper>
  );
}

const mapStateToProps = ({ rootReducer }) => {
  const supersets = rootReducer.model.get(ENTITY_NAME.Superset);
  const exercise = rootReducer.model.get(ENTITY_NAME.Exercise);
  const supersetEntities = supersets && typeof supersets.toJS === 'function' ? supersets.toJS() : {};
  const exerciseEntities = exercise && typeof exercise.toJS === 'function' ? exercise.toJS() : {};

  return { supersetEntities, exerciseEntities };
};

export default connect(mapStateToProps)(SectionHeader);
