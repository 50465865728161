import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export default class ProgramWorkoutDraggable extends React.Component {
  render() { 
    const { currentWorkout, workoutIndex, dragingAssignmentId } = this.props;
    const draggableId = `workout_${currentWorkout._id}`;
    const isDragDisabled = dragingAssignmentId && dragingAssignmentId === currentWorkout._id;
    return (
      <Draggable key={draggableId} draggableId={draggableId} type="workout" index={workoutIndex} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <div
            // className={`${sClass} ${!snapshot.isDragging ? 'exercise-cell' : 'drag'}`}
            className="draggableWorkout"
            style={{ ...provided.draggableProps.style, margin: '0 5px' }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {this.props.children}
          </div>
        )}
      </Draggable>
    )
  }
}