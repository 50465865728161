import React from 'react';
import { connect } from 'react-redux';
import { Button, Image } from 'semantic-ui-react';
// import containers
import CalculateMacrosModal from '../CalculateMacrosModal';
import ManualGoalModal from '../ManualGoalModal';

import { CDN_URL } from 'constants/commonData';

// import actions
import { toggleModal } from 'actions/modal';
import AddModalStyles, { HeaderStyles, ContentStyles } from './styles';

const AddModal = ({ isModalOpen, clientId, fetchReport, dispatch }) => {
  return (
    <AddModalStyles
      size={'tiny'}
      open={isModalOpen}
      onClose={() => dispatch(toggleModal(false))}
      className="client-invite-modals"
      closeOnDimmerClick={false}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
    >
      <HeaderStyles>
        <div className="title">Choose method to set goals</div>
      </HeaderStyles>
      <ContentStyles>
        <div
          className="macros-type-modal"
          onClick={() =>
            dispatch(toggleModal(true, <CalculateMacrosModal clientId={clientId} fetchReport={fetchReport} />))
          }
        >
          <img src={`${CDN_URL}/images/macros-auto.png`} alt="" />
          <div className="text">Auto Calculate</div>
        </div>
        <div className="divine" />
        <div
          className="macros-type-modal"
          onClick={() => dispatch(toggleModal(true, <ManualGoalModal clientId={clientId} fetchReport={fetchReport} />))}
        >
          <img src={`${CDN_URL}/images/macros-manual.png`} alt="" />
          <div className="text">Set Manually</div>
        </div>
      </ContentStyles>
    </AddModalStyles>
  );
};

export default connect(({ isModalOpen }) => ({
  isModalOpen,
}))(AddModal);
