import styled, { css } from 'styled-components';

export const PricingPlanWrapper = styled.div`
  background: #E5E5E5;
  background: linear-gradient(180deg, #F7F8FC 0%, rgba(247, 248, 252, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  .pricingPlan__container {
    overflow: auto;
    width: 100vw;
    height: calc(100vh - 53px);
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #fff;
  }

  .pricingPlan__heading {
    width: 100%;
  }

  .pricingPlan__body {
    width: 1170px;
    padding-bottom: 40px;

    .pricingPlan__bodyHeading {
      width: 100%;

    }

    .pricingPlan__bodyContent {
      width: 100%;
      display: flex;

      .pricingPlan__plans {
        width: 73%;
      }

      .pricingPlan__planSummary {
        width: 27%;
        padding-left: 20px;
        margin-top: 24px;
      }
    }
  }
`;