import styled from 'styled-components';

export const HeadingConfirmPaymentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px 20px 0px;

  .headingConfirm__content {
    &--title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #202353;
      margin-bottom: 5px;
    }

    &--description {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #202353;
    }
  }

  .headingConfirm__backBtn {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    cursor: pointer;

    img {
      margin-right: 8px;
      margin-bottom: -1px;
    }

    &.disable {
      cursor: not-allowed;
      color: rgba(32, 35, 83, 0.6);

      img.arrow-back-icon {
        opacity: 0.6;
      }
    }
  }
`;
