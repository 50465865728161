import { get } from 'lodash';
import React from 'react';
import Avatar from 'react-avatar';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import { AVATAR_SIZE } from '../constants';

import * as S from './style';

const CoachBioItem = ({ coach, handleOpenDetail, cloudfrontList }) => {
  const { role, bio } = coach;

  const renderCoachInfo = () => {
    return (
      <S.CoachBios>
        <Avatar
          name={getUserShortName(coach)}
          size={AVATAR_SIZE}
          src={convertS3UrlToCloudFrontUrl(get(coach, 'avatar', ''), cloudfrontList, true)}
          color={get(coach, 'color', '')}
        />
        <S.CoachBiosDetail>
          <S.CoachBiosName>{get(coach, 'full_name', '')}</S.CoachBiosName>
        </S.CoachBiosDetail>
      </S.CoachBios>
    );
  };

  const renderRole = () => {
    return <S.CoachBiosRole>{role ? role : '--'}</S.CoachBiosRole>;
  };

  const renderBio = () => {
    return <S.Description>{bio || '--'}</S.Description>;
  };

  const renderActions = coach => {
    return <S.Edit onClick={() => handleOpenDetail(coach)}> Edit</S.Edit>;
  };

  return (
    <S.CoachBiosTableBody>
      <S.CoachBiosDetailTableRow>
        <S.CoachBiosCell key="name">{renderCoachInfo()}</S.CoachBiosCell>
        <S.CoachBiosCell key="role">{renderRole()}</S.CoachBiosCell>
        <S.CoachBiosCell key="description">{renderBio()}</S.CoachBiosCell>
        <S.CoachBiosCell key="actions">{renderActions(coach)}</S.CoachBiosCell>
      </S.CoachBiosDetailTableRow>
    </S.CoachBiosTableBody>
  );
};

export default CoachBioItem;
