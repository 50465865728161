import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as S from '../styles';
import AssignClientToAutoflow from 'components/AssignClientToAutoflow';
import { GroupButton } from 'components/ClientGroup/style';
import UpgradePath from 'shared/UpgradePath';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { Button } from 'shared/FormControl';
import { toggleModal } from 'actions/modal';
import AssignProgram from 'components/AssignProgram';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  margin-left: 5px;
`;

class AssignClientButton extends React.PureComponent {
  onAssignClientToProgram = () => {
    const { selectedClients, onAssignClientToSuccess } = this.props;
    this.props.toggleModal(
      true,
      <AssignProgram originClients={selectedClients} onAssignSuccess={onAssignClientToSuccess} from="client_list" />,
    );
  };

  onAssignClientToAutoflow = () => {
    const { selectedClients, onAssignClientToSuccess } = this.props;
    this.props.toggleModal(
      true,
      <AssignClientToAutoflow originClients={selectedClients} onAssignSuccess={onAssignClientToSuccess} />,
    );
  };

  render() {
    const { permission } = this.props;

    if (permission.program && permission.autoflow) {
      return (
        <Wrapper>
          <Dropdown
            triggerIcon={({ open }) => (
              <Button className={classNames('button--group-actions', { open })}>
                <img src={`${CDN_URL}/images/assign_icon.svg`} width={14} alt="" />
                <span>Assign</span>
                <img src={`${CDN_URL}/images/arrow-down.svg`} alt="" />
              </Button>
            )}
            className="dropdown--client-group-actions"
            type="light"
          >
            <Option onClick={this.onAssignClientToAutoflow}>
              <S.ActionOptionContainer className="clientActionTrigger__container">
                <S.ActionOptionIcon>
                  <img src={`${CDN_URL}/images/autoflow_icon_white.svg`} alt="" />
                </S.ActionOptionIcon>
                <div>Assign to Autoflow</div>
              </S.ActionOptionContainer>
            </Option>
            <Option onClick={this.onAssignClientToProgram}>
              <S.ActionOptionContainer className="clientActionTrigger__container">
                <S.ActionOptionIcon>
                  <img src={`${CDN_URL}/images/training_icon.svg`} alt="" />
                </S.ActionOptionIcon>
                <div>Assign to Program</div>
              </S.ActionOptionContainer>
            </Option>
          </Dropdown>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <UpgradePath pathName="autoflow" fallback={<></>}>
          <GroupButton
            title="Assign to Autoflow"
            img={`${CDN_URL}/images/autoflow_icon_white.svg`}
            onClick={this.onAssignClientToAutoflow}
          />
        </UpgradePath>
        <UpgradePath pathName="program" fallback={<></>}>
          <GroupButton
            title="Assign to Program"
            img={`${CDN_URL}/images/training_icon.svg`}
            onClick={this.onAssignClientToProgram}
          />
        </UpgradePath>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    permission: state.rootReducer.permission,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignClientButton);
