// Libs
import React, { useState, useCallback, useRef, useMemo } from 'react';
import Select from 'react-select';
import get from 'lodash/get';

// Constants
import { ASSETS_SHARE_SETTING_TYPE, NO_OWNER_SHARED_OPTION, SHARE_OPTIONS } from '../constants';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_SHARED } from 'constants/commonData';

// Parts
import DefaultDropdownIndicator from './DefaultDropdownIndicator';
import CustomDropdownIndicator from './CustomDropdownIndicator';

// Styles
import * as S from '../styles';

const FieldShare = props => {
  const {
    disabled = false,
    type = ASSETS_SHARE_SETTING_TYPE.DEFAULT,
    usePortal = false,
    shareStatus,
    onChangeShareStatus = () => {},
    ownership,
  } = props;
  if (shareStatus === TEAM_SHARE_NOOWNER) return null;

  const selectStatusRef = useRef();
  const [menuStatusPlacement, setMenuStatusPlacement] = useState(props.menuPlacement || 'auto');

  const [defaultValue, useCustomIcon] = useMemo(() => {
    let statusValue = SHARE_OPTIONS[shareStatus];
    if (shareStatus === TEAM_SHARE_NOOWNER) {
      statusValue = SHARE_OPTIONS[TEAM_SHARE_SHARED];
    }

    const showCustomIcon = type === ASSETS_SHARE_SETTING_TYPE.OWNERSHIP_POPUP;

    return [statusValue, showCustomIcon];
  }, [shareStatus]);

  const menuPortalTarget = useMemo(() => {
    if (usePortal) return document.body;
    return false;
  }, [usePortal]);

  const checkPlacementStatus = useCallback(() => {
    if (typeof selectStatusRef.current.getBoundingClientRect === 'function') {
      const rect = selectStatusRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 100) {
        setMenuStatusPlacement('top');
      } else {
        menuStatusPlacement !== get(props, 'menuStatusPlacement', 'auto') &&
          setMenuStatusPlacement(get(props, 'menuStatusPlacement', 'auto'));
      }
    }
  }, [menuStatusPlacement, selectStatusRef]);

  return (
    <S.Wrapper ref={selectStatusRef}>
      <S.FieldLabel>Share with ORG?</S.FieldLabel>
      <Select
        blurInputOnSelect
        isSearchable={false}
        isMulti={false}
        name="share"
        classNamePrefix="share"
        className="select-share"
        defaultValue={defaultValue}
        options={Object.values(SHARE_OPTIONS)}
        isDisabled={disabled || ownership === NO_OWNER_SHARED_OPTION.value}
        getOptionLabel={option => option.text}
        getOptionValue={option => option.value}
        menuStatusPlacement={menuStatusPlacement}
        menuPortalTarget={menuPortalTarget}
        onChange={onChangeShareStatus}
        onMenuOpen={checkPlacementStatus}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: useCustomIcon ? CustomDropdownIndicator : DefaultDropdownIndicator,
        }}
        styles={S.customSelectStatusStyles}
      />
    </S.Wrapper>
  );
};

export default FieldShare;
