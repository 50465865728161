import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, ErrorMessage } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import StripeBillingInformation, {
  DEFAULT_INFORMATION,
  StripeElementsContainer,
  StripeCardElementOptions,
} from 'components/StripeBillingInformation';
import { validateStripeBillingDetail } from 'utils/validations';
import { togglePaymentInformation } from 'redux/billing/actions';
import ConfirmPayAnInvoiceModal from 'components/BillingSetting/ConfirmPayAnInvoiceModal';

function PaymentInformation(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [billingDetails, setBillingDetails] = useState({ ...DEFAULT_INFORMATION, ...props.billingDetails });
  const [submitting, setSubmitting] = useState(false);
  const [invalidCard, setInvalidCard] = useState(false);
  const [emptyCard, setEmptyCard] = useState(true);
  const [showError, setShowError] = useState(false);

  const onBillingInfoChange = (data) => {
    setBillingDetails({ ...data });
  };

  const onCardChange = (data) => {
    const { empty, error } = data;
    const isInvalid = !!error;
    setInvalidCard(isInvalid);
    setEmptyCard(empty);
  };

  const onPayAnInvoice = (data) => {
    props.toggleModal(true, <ConfirmPayAnInvoiceModal billingInfo={data}  onClose={() => props.toggleModal(false)} />);
  };

  const closePopup = () => {
    props.togglePaymentInformation(false)
    if (props.billPaying) {
      onPayAnInvoice(props.billPaying);
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    const trimmedValues = _.mapValues(billingDetails, (o) => (o + '').trim());

    if (!validateStripeBillingDetail(trimmedValues) || (emptyCard && !props.originalPaymentMethod)) {
      if (!showError) {
        setShowError(true);
      }

      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      console.error('Stripe.js has not loaded yet.');
      return;
    }

    let payload,
      payment_method_id = props.originalPaymentMethod;
    setSubmitting(true);

    if (!emptyCard) {
      const billing_details = {
        name: trimmedValues.name,
        address: _.pick(trimmedValues, ['city', 'line1', 'state', 'country', 'postal_code']),
      };

      payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details,
      });

      payment_method_id = _.get(payload, 'paymentMethod.id');
    }

    if (!payment_method_id) {
      setSubmitting(false);
      return false;
    } else {
      const cardInfo = payload
        ? {
            last4: _.get(payload, 'paymentMethod.card.last4'),
            brand: _.get(payload, 'paymentMethod.card.brand'),
            funding: _.get(payload, 'paymentMethod.card.funding'),
            exp_month: _.get(payload, 'paymentMethod.card.exp_month'),
            exp_year: _.get(payload, 'paymentMethod.card.exp_year'),
          }
        : {};
      const submitData = { ...trimmedValues, payment_method_id, ...cardInfo };
      props
        .updatePaymentInfo(submitData)
        .then(() => {
          closePopup();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <S.Wrapper>
      <S.Container name="update-payment-information">
        <S.CloseIcon data-btn="close-button" onClick={closePopup} />
        <S.Form onSubmit={onSubmit}>
          <ModalLayout.Wrapper>
            <ModalLayout.Header>
              <span>Update Payment Information</span>
            </ModalLayout.Header>
            <ModalLayout.Content>
              <StripeElementsContainer showError={showError && (emptyCard || invalidCard)}>
                <CardElement options={StripeCardElementOptions} onChange={onCardChange} />
                {invalidCard || (emptyCard && showError) ? (
                  <ErrorMessage data-valid="card-number" className="error-message">Your card is invalid</ErrorMessage>
                ) : null}
              </StripeElementsContainer>
              <StripeBillingInformation
                originalData={billingDetails}
                onChange={onBillingInfoChange}
                showError={showError}
              />
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button type="submit" purple>
                Update Card
              </Button>
            </ModalLayout.Actions>
          </ModalLayout.Wrapper>
        </S.Form>
      </S.Container>
    </S.Wrapper>
  );
}

const mapDispatch = (dispatch) => ({
  togglePaymentInformation: bindActionCreators(togglePaymentInformation, dispatch)
})

export default connect(null, mapDispatch)(PaymentInformation);
