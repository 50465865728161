import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import {
  selectedClientIdsWaitList,
  selectedClientIdWaitList,
  undoRequestWaitList,
  resendRequestWaitList,
} from 'redux/waitlist/actions';
import { Checkbox } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import { formatSameYear, formatSameYearWithTime, getUserShortName } from 'utils/commonFunction';
import ViewMessagePopup from 'components/Package/components/CustomPopup/ViewMessagePopup';
import TableEmpty from 'components/Package/components/TableEmpty';
import { getRejectedReasonContent } from 'components/Package/components/RejectReason';
import CustomPopup from 'components/Package/components/CustomPopup';
import { toggleModal } from 'actions/modal';
import {
  checkUndoDurationTime,
  getWaitlistStatus,
  getTimeActionTooltip,
  getTrainerNameApprovedOrRejected,
} from 'components/Package/components/common';
import { renderActionErrorPopup } from 'components/Package/components/CustomPopup/GroupActionPopup';

import { WAITLIST_STATUS } from '../constants';
import * as S from '../../../components/CustomTable.style';

import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';
import { PURCHASE_VALUE } from '../../Settings/constants';

function View({
  list,
  loading,
  toggleModal,
  workingPackage,
  onOpenRejectPopup,
  clientIdsSelected,
  undoRequestWaitList,
  onOpenApprovePopup,
  resendRequestWaitList,
  selectedClientIdWaitList,
  selectedClientIdsWaitList,
}) {
  const hasSelected = !!get(clientIdsSelected, 'length', 0);
  const selectedIds = hasSelected ? clientIdsSelected : [];
  const isWaitlist = get(workingPackage, 'package_purchase_option.type') === PURCHASE_VALUE.WAITLIST;

  const handleSelectClient = (value, event) => {
    event.stopPropagation();

    if (event.target && event.target.checked) {
      selectedClientIdsWaitList([...clientIdsSelected, value.id]);
    } else {
      selectedClientIdsWaitList(filter(clientIdsSelected, clientId => clientId !== value.id));
    }
  };

  const handleRejectRequest = item => {
    const clientId = [item.id];
    onOpenRejectPopup();
    selectedClientIdWaitList(clientId);
  };

  const handleApproveRequest = item => {
    const clientId = [item.id];
    onOpenApprovePopup(clientId.length);
    selectedClientIdWaitList(clientId);
  };

  const handleOpenReasonPopup = item => {
    toggleModal(true, <ViewMessagePopup title="Decline reason" clientInfo={item} onClose={() => toggleModal(false)} />);
  };

  const handleOpenUndoRequest = (disabledAction, item) => {
    if (disabledAction) return;
    toggleModal(
      true,
      <CustomPopup
        title="Are you sure you want to undo?"
        icon={<WarningIcon />}
        buttonText="Confirm"
        buttonClassName="button-confirm"
        onToggle={() => toggleModal(false)}
        onSubmit={toast => handleUndoRequest(toast, item.id)}
        content={`The request status will revert to waiting, and we’ll email the client to let them know.`}
        toastStyle={{
          marginRight: '-40px',
        }}
      />,
    );
  };

  const handleUndoRequest = (toastMessage, id) => {
    if (!!id) {
      undoRequestWaitList(id, {
        callbackSuccess: message => {
          toggleModal(false);
          toastMessage(message);
        },
        callbackFailure: errorCode => {
          renderActionErrorPopup({ errorCode, toggleModal });
        },
      });
    }
  };

  const handleOpenResendRequest = item => {
    toggleModal(
      true,
      <CustomPopup
        title="Resend the offer?"
        icon={<WarningIcon />}
        buttonText="Confirm"
        buttonClassName="button-confirm"
        onToggle={() => toggleModal(false)}
        onSubmit={toast => handleResendRequest(toast, item.id)}
        content={`Are you sure you want to resend the offer? We will send the email & reminders to the client.`}
        toastStyle={{
          marginRight: '-40px',
        }}
      />,
    );
  };

  const handleResendRequest = (toastMessage, id) => {
    if (!!id) {
      resendRequestWaitList(id, {
        callbackSuccess: message => {
          toggleModal(false);
          toastMessage(message);
        },
        callbackFailure: errorCode => {
          renderActionErrorPopup({ errorCode, toggleModal });
        },
      });
    }
  };

  if (!loading && list && !list.length) return <TableEmpty />;
  return (
    <Layout.TableBody>
      {map(list, (item, index) => {
        const uuid = get(item, 'id', index);
        const {
          id,
          client_name,
          client_email,
          status,
          created_at,
          client_info,
          trainer_info,
          reject_reason,
          reject_reason_note = '',
          rejected_at,
          is_marked_as_undo = false,
        } = item || {};
        const requestDate = formatSameYear(created_at);
        const requestDateTooltip = formatSameYearWithTime(created_at);

        const isChecked = selectedIds.includes(id);
        const disabledSelect = status !== WAITLIST_STATUS.WAITING;
        const isShowImpactPerson = [WAITLIST_STATUS.OFFERED, WAITLIST_STATUS.REJECTED_BY_COACH].includes(status);
        const isShowReason = status === WAITLIST_STATUS.REJECTED_BY_CLIENT;
        const isWaiting = status === WAITLIST_STATUS.WAITING;
        const isRejected = status === WAITLIST_STATUS.REJECTED_BY_COACH;
        const isNoResponse = status === WAITLIST_STATUS.NO_RESPONSE;
        const trainerFullName = `${get(trainer_info, 'first_name', '')} ${get(trainer_info, 'last_name', '')}`;

        const disabledUndoAction = is_marked_as_undo || checkUndoDurationTime(rejected_at);
        const isShowUndoAction = isRejected && isWaitlist && !disabledUndoAction;
        const isShowResendAction = isNoResponse && isWaitlist;

        const { statusParse, statusLabel } = getWaitlistStatus(status);
        const timeActionTooltip = getTimeActionTooltip(item);

        return (
          <S.TableRowWrapper height="59px" key={uuid}>
            <S.TableCellWrapper padding="10px 0" key="client">
              <S.CustomTableCellWrapper
                disabledSelect={disabledSelect}
                onClick={e => e.stopPropagation()}
                className={classNames(isChecked ? 'checked' : null)}
              >
                <S.CheckBoxContainer>
                  <div className="checkbox__thumbnail" data-tip data-for={`checkbox-${uuid}`}>
                    <Checkbox
                      disabled={disabledSelect}
                      checked={isChecked}
                      onChange={event => handleSelectClient(item, event)}
                    />
                    {disabledSelect && (
                      <ReactTooltip
                        className="checkbox-tooltip app-tooltip"
                        id={`checkbox-${uuid}`}
                        effect="solid"
                        eventOff="mouseleave scroll mousewheel blur"
                        delayShow={100}
                      >
                        <span>No available actions for this client.</span>
                      </ReactTooltip>
                    )}
                  </div>
                  <S.Thumbnail className="thumbnail">
                    <Avatar
                      title
                      name={getUserShortName(client_info)}
                      size="24"
                      src={get(client_info, 'avatar.s', '')}
                      color={get(client_info, 'color')}
                    />
                  </S.Thumbnail>
                </S.CheckBoxContainer>
                <div className="name-wrapper">
                  <span className="text">{client_name}</span>
                  <span className="email">{client_email}</span>
                </div>
              </S.CustomTableCellWrapper>
            </S.TableCellWrapper>
            <S.TableCellWrapper padding="10px 0" key="request_date">
              <span data-tip data-for={`request-date-${uuid}`} className="text pointer">
                {requestDate}
              </span>
              <ReactTooltip
                className="request-date-tooltip app-tooltip"
                id={`request-date-${uuid}`}
                effect="solid"
                eventOff="mouseleave scroll mousewheel blur"
                delayShow={100}
              >
                <span>{requestDateTooltip}</span>
              </ReactTooltip>
            </S.TableCellWrapper>
            <S.TableCellWrapper padding="10px 0" key="status">
              <div className="action-wrapper">
                <div className="status-wrapper">
                  <div className={classNames('status', statusParse)}>
                    <span
                      data-tip
                      data-for={`time-action-${uuid}`}
                      className={classNames('text', timeActionTooltip ? 'pointer' : '')}
                    >
                      {statusLabel}
                    </span>
                    {!!timeActionTooltip && (
                      <ReactTooltip
                        className="time-action-tooltip app-tooltip"
                        id={`time-action-${uuid}`}
                        effect="solid"
                        eventOff="mouseleave scroll mousewheel blur"
                        delayShow={100}
                        offset={{ top: 8 }}
                      >
                        <span>{timeActionTooltip}</span>
                      </ReactTooltip>
                    )}
                  </div>
                  {isShowImpactPerson && (
                    <div className="avatar-wrapper" data-tip data-for={`impact-person-${uuid}`}>
                      <Avatar
                        title
                        name={getUserShortName(trainer_info)}
                        size="24"
                        src={get(trainer_info, 'avatar', '')}
                        color={get(trainer_info, 'color')}
                      />
                      <ReactTooltip
                        className="impact-person-tooltip app-tooltip"
                        id={`impact-person-${uuid}`}
                        effect="solid"
                        eventOff="mouseleave scroll mousewheel blur"
                        delayShow={100}
                        offset={{ top: 1 }}
                      >
                        <span>{getTrainerNameApprovedOrRejected(statusParse, trainerFullName)}</span>
                      </ReactTooltip>
                    </div>
                  )}
                  {isShowUndoAction && (
                    <button
                      disabled={disabledUndoAction}
                      className="undo-action"
                      onClick={() => handleOpenUndoRequest(disabledUndoAction, item)}
                    >
                      <span>Undo</span>
                    </button>
                  )}
                  {isShowResendAction && (
                    <button className="resend-action" onClick={() => handleOpenResendRequest(item)}>
                      <span>Resend</span>
                    </button>
                  )}
                </div>
                {isWaiting && (
                  <S.ActionsWrapper>
                    <button onClick={() => handleRejectRequest(item)}>
                      <span>Decline</span>
                    </button>
                    <button className="border" onClick={() => handleApproveRequest(item)}>
                      <span>Offer</span>
                    </button>
                  </S.ActionsWrapper>
                )}
                {isShowReason && (
                  <S.ActionsWrapper>
                    <button
                      onClick={() =>
                        handleOpenReasonPopup({
                          ...client_info,
                          email: client_email,
                          message: reject_reason_note || getRejectedReasonContent(reject_reason),
                        })
                      }
                    >
                      <span>View reason</span>
                    </button>
                  </S.ActionsWrapper>
                )}
              </div>
            </S.TableCellWrapper>
          </S.TableRowWrapper>
        );
      })}
    </Layout.TableBody>
  );
}

const TableBody = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      packageDetail,
      waitList: { list, loading, total, clientIdsSelected },
    },
  } = state;
  return {
    list,
    loading,
    total,
    clientIdsSelected,
    workingPackage: packageDetail.get('workingPackage').toJS(),
  };
};

const mapDispatch = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    selectedClientIdsWaitList: bindActionCreators(selectedClientIdsWaitList, dispatch),
    selectedClientIdWaitList: bindActionCreators(selectedClientIdWaitList, dispatch),
    undoRequestWaitList: bindActionCreators(undoRequestWaitList, dispatch),
    resendRequestWaitList: bindActionCreators(resendRequestWaitList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableBody);
