import { TEAM_SHARE, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';

export const ASSETS_SHARE_SETTING_TYPE = {
  DEFAULT: 'default',
  STUDIO_PROGRAM_CREATE_COPY: 'studio_program_create_copy',
  SHARE_SETTING_MODAL: 'share_setting_modal',
  OWNERSHIP_POPUP: 'ownership_popup',
};

export const SHARE_OPTIONS = {
  [TEAM_SHARE_PRIVATE]: {
    text: TEAM_SHARE[TEAM_SHARE_PRIVATE],
    value: TEAM_SHARE_PRIVATE,
  },
  [TEAM_SHARE_SHARED]: {
    text: TEAM_SHARE[TEAM_SHARE_SHARED],
    value: TEAM_SHARE_SHARED,
  },
};

export const NO_OWNER_SHARED_OPTION = {
  text: 'No owner (shared)',
  value: 'no_owner',
};

export const getOwnerOptions = ({ members = [], hasYou = false, userId } = {}) => {
  const options = members.map(it => {
    let addition = '';
    if (hasYou && it._id === userId) {
      addition = ' (You)';
    }
    return {
      text: it.full_name + addition,
      value: it._id,
    };
  });
  options.push(NO_OWNER_SHARED_OPTION);
  return options;
};

export const generateParams = ({ ownership, shareStatus } = {}) => {
  const isNoOwner = ownership === NO_OWNER_SHARED_OPTION.value || shareStatus === TEAM_SHARE_NOOWNER;

  return {
    owner: isNoOwner ? null : ownership,
    share: isNoOwner ? TEAM_SHARE_NOOWNER : shareStatus,
  };
};

export const generateShareStatus = ({ ownership, shareStatus } = {}) => {
  if (ownership === NO_OWNER_SHARED_OPTION.value) {
    return TEAM_SHARE_NOOWNER;
  } else if (ownership !== NO_OWNER_SHARED_OPTION.value && shareStatus === TEAM_SHARE_NOOWNER) {
    return TEAM_SHARE_PRIVATE;
  }
  return shareStatus;
};
