import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CDN_URL, HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';

import ExerciseItem from './ExerciseItem';
import ExerciseReferences from './ExerciseReferences';

import * as S from './style';
// import { convertSecondToTimeString } from 'helpers/time';
import { handleGetExerciseType } from 'helpers/workout';

// const SectionInfo = section => {
//   switch (section.format) {
//     case 'amrap':
//       const round = parseInt(section.round_complete || 0);
//       return (
//         <div className="item">
//           <img src="/images/refresh_gray.svg" alt="" />
//           <span>
//             {round} round{round !== 1 ? 's' : ''}
//           </span>
//         </div>
//       );

//     case 'interval':
//       const interval = _.sumBy(section.exercises, superset => {
//         return _.sumBy(superset.supersets, exercise => exercise.training_sets.length);
//       });
//       return (
//         <div className="item">
//           <img src="/images/interval.svg" alt="" />
//           <span>
//             {interval} interval{interval !== 1 ? 's' : ''}
//           </span>
//         </div>
//       );

//     case 'regular':
//       const exercise = _.sumBy(section.exercises, superset => superset.supersets.length) || 0;
//       return (
//         <div className="item">
//           <img src="/images/exercise.svg" alt="" />
//           <span>
//             {exercise} exercise{exercise !== 1 ? 's' : ''}
//           </span>
//         </div>
//       );

//     case 'timed':
//       const time = convertSecondToTimeString(parseInt(section.time_complete || 0), true);
//       return (
//         <div className="item">
//           <img src="/images/new_clock_grey.svg" alt="" />
//           <span>{time}</span>
//         </div>
//       );

//     default:
//       return null;
//   }
// };

const handleRenderTimeOnTitle = (time, hideTime = false) => {
  return (
    time.text &&
    !hideTime && (
      <div className="time">
        <img src={time.icon} alt="" />
        <span>{time.text}</span>
      </div>
    )
  );
};

const handleRenderTitle = (section, time) => {
  const { format, section_completed: isSectionCompleted } = section || {};
  const isFreestyle = format === SECTION_FORMAT_KEY.FREESTYLE;
  const hideTime = !(isFreestyle && isSectionCompleted) ? true : false;

  return (
    <S.SectionTitle>
      {isFreestyle && !isSectionCompleted ? (
        <div className="missed-title">
          <S.SectionText>{section.title}</S.SectionText>
          <S.Missed>Missed</S.Missed>
          {/* TODO - freestyle_section_completed: true => not show */}
          {handleRenderTimeOnTitle(time)}
        </div>
      ) : isFreestyle ? (
        <div className="title-type-file">
          <div className="type-label">{section.title}</div>
          <div className="section-type">{handleGetExerciseType(section.attachments)}</div>
        </div>
      ) : (
        <div className="title">{section.title}</div>
      )}

      {/* TODO - freestyle_section_completed: false => not show */}
      {handleRenderTimeOnTitle(time, hideTime)}
    </S.SectionTitle>
  );
};

const SectionItem = props => {
  const { FREESTYLE } = SECTION_FORMAT_KEY;
  const { section, isMissedWorkout } = props;
  const { type, exercises } = section;
  const isHiddenSection = type === HIDDEN_SECTION;
  const isSupersetSections = isHiddenSection && exercises[0].supersets.length > 1;
  const time = calculateSectionTime(section);

  return (
    <S.SectionContainer
      key={section._id}
      className={classNames('section', {
        'section--hidden': isHiddenSection && !isSupersetSections,
        'section--supersets': isSupersetSections,
      })}
    >
      <div className="dot" />
      {!isHiddenSection && handleRenderTitle(section, time)}
      <S.SectionExercises
        className={classNames('section--exercises', { 'section--exercises--hidden': isHiddenSection })}
      >
        {section.exercises.map((set, setIndex) => (
          <ExerciseItem set={set} setIndex={setIndex} key={set._id} isMissedWorkout={isMissedWorkout} />
        ))}
        {section.format === FREESTYLE &&
          section.exercise_references.map((set, setIndex) => (
            <ExerciseReferences setIndex={setIndex} set={set} title={set.title} />
          ))}
      </S.SectionExercises>
      <S.Note>{section.note}</S.Note>
    </S.SectionContainer>
  );
};

const mapState = state => {
  const {
    user: { preferences },
    rootReducer: {
      exercise: { fields },
    },
  } = state;

  return { fields, unit: preferences };
};

export default connect(mapState, null)(SectionItem);

const parseTime = seconds => {
  return { minute: parseInt(seconds / 60), second: parseInt(seconds % 60) };
};

const calculateSectionTime = section => {
  const { type, round, time, format } = section;
  let text = '',
    parsedTime,
    totalDuration = Number(time || 0);
  let icon = `${CDN_URL}/images/clock_grey_2.svg`;

  if (type === HIDDEN_SECTION) {
    return { text, icon };
  }

  switch (format) {
    case SECTION_FORMAT_KEY.TIMED:
      icon = `${CDN_URL}/images/round_purple.svg`;
      text = `${round} round${round !== 1 ? 's' : ''}`;
      break;

    case SECTION_FORMAT_KEY.AMRAP:
      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;

    case SECTION_FORMAT_KEY.INTERVAL:
      totalDuration = _.sumBy(section.exercises, set => {
        return _.sumBy(set.supersets, ex => {
          return _.sumBy(ex.training_sets, exSet => {
            const duration = Number(_.get(exSet, 'duration.value', 0));
            const rest = Number(_.get(exSet, 'rest.value', 0));
            return duration + rest;
          });
        });
      });

      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;
    default:
      break;
  }

  return { text, icon };
};
