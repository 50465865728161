import { connect } from 'react-redux';
import BodyMetricUpdateAll from './component';
import { addBodyMetricEntries, addSleepEntry } from 'redux/client/client.actionCreators';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { addHeartRateEntry } from 'redux/heart-rate/action';

const mapStateToProps = state => {
  const {
    isModalOpen,
    groupMetric: { selected = {} },
    rootReducer: {
      client: { bodymetricTypes, workingClientDetail, bodymetricAllSettings, isConnected3rdParty },
    },
  } = state;

  return {
    isModalOpen,
    selectedGroup: selected,
    bodymetricTypes: (bodymetricTypes || []).filter(item => item.selected && !!item.can_update_manual),
    bodymetricAllSettings: bodymetricAllSettings,
    clientId: workingClientDetail && workingClientDetail._id,
    isConnected3rdParty,
  };
};

const actionCreators = {
  addSleepEntry,
  addBodyMetricEntries,
  toggleModal,
  toggleConfirmModal,
  addHeartRateEntry,
};

export default connect(mapStateToProps, actionCreators)(BodyMetricUpdateAll);
