import React from 'react';
import moment from 'moment';
import { FormGroup, FormLabel } from './style';
import Datetime from 'react-datetime';
import SelectProgramDay from 'shared/SelectProgramDay';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';

export default ({ autoflow, startingOn, startingAt, onSelectStartingOn, onSelectStartingAt }) => {
  const renderDatetimeInput = props => {
    let inputClass = 'form-control';

    if (props.className) {
      inputClass += ' ' + props.className;
    }

    return (
      <div {...props} className={inputClass}>
        <img src={`${CDN_URL}/images/new_calendar.svg`} style={{ marginRight: 10 }} alt="" />
        {startingOn.format('MMM DD YYYY')}
      </div>
    );
  };

  if (!autoflow || autoflow.type !== AUTOFLOW_TYPES.INTERVAL) return null;

  return (
    <>
      <FormGroup className="starting-on">
        <FormLabel>Starting On</FormLabel>
        <Datetime
          value={startingOn}
          renderInput={renderDatetimeInput}
          timeFormat={false}
          closeOnSelect={true}
          onChange={onSelectStartingOn}
          isValidDate={current => current.isSameOrAfter(moment(), 'day')}
          renderDay={(props, currentDate) => (
            <td {...props}>
              <div className="rdt__custom-day">
                <div>{currentDate.date()}</div>
              </div>
            </td>
          )}
        />
      </FormGroup>
      <FormGroup className="starting-at">
        <FormLabel>Starting At</FormLabel>
        <SelectProgramDay
          value={startingAt}
          totalWeek={autoflow.interval_weeks ? autoflow.interval_weeks.length : 0}
          onSelect={onSelectStartingAt}
        />
      </FormGroup>
    </>
  );
};
