import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { validateFiles } from 'utils/validations';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import UploadCoverImage from './UploadCoverImage';

import { createObjectURL, revokeObjectURL, readAsArrayBufferAsync, mediaLog } from 'utils/commonFunction';
import { confirmFirstTime, getPreSignedUrl, uploadCoverSuccess } from 'redux/studio-collection/actions';
import { DEFAULT_UPLOAD_SETTINGS } from './constants';

const CoverImageContainer = ({
  isFirstTimeAdd,
  confirmFirstTime,
  getPreSignedUrl,
  onChangeCover,
  cover,
  onConfirmFirstAdd,
  uploadCoverSuccess,
  disabled = false,
}) => {
  const [src, setSrc] = useState(cover);
  const [file, setFile] = useState();
  const [isLocalUrl, setIsLocalUrl] = useState(false);
  const [isFirstAdd, setFirstAdd] = useState(false);
  const [uploadConfigs, setConfigs] = useState({
    DEFAULT_UPLOAD_SETTINGS,
  });
  const [isLoading, setLoading] = useState(false);
  const [uploadingURL, setUploadingURL] = useState(null);

  useEffect(() => {
    setFirstAdd(isFirstTimeAdd);
  }, []);

  const handleSelectedFile = async file => {
    if (src) {
      return;
    }
    const preSignedResp = await getPreSignedUrl(encodeURI(new Date().getTime() + file.name));
    if (!preSignedResp || !preSignedResp.data) {
      return;
    }

    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Resource Collections',
    });

    const fileBuffer = await readAsArrayBufferAsync(file);
    const uploadURL = new URL(preSignedResp.data.data);
    const configs = {
      url: preSignedResp.data.data,
      method: 'put',
      data: fileBuffer,
      headers: { 'Content-Type': file.type },
    };

    handleStartUploadFile();
    setConfigs({ ...DEFAULT_UPLOAD_SETTINGS, ...configs });
    setSrc(createObjectURL(file));
    setIsLocalUrl(true);
    setFile(file);
    setUploadingURL(uploadURL.origin + uploadURL.pathname);
  };

  const handleDrop = files => {
    if (files.length < 1 || files.length > 1) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: DEFAULT_UPLOAD_SETTINGS.validateExtensions,
        maxSize: DEFAULT_UPLOAD_SETTINGS.maxSize * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        handleUploadError(error.type);
      } else {
        handleSelectedFile(file);
      }
    }
  };

  const handleUploadError = type => {
    let message = '';

    if (type === FILE_ERRORS.FILE_TYPE_INVALID) {
      message = 'File type not supported.';
    } else if (type === FILE_ERRORS.FILE_SIZE_LIMIT) {
      message = DEFAULT_UPLOAD_SETTINGS.maxSizeMessage;
    }

    toast.error(message);
  };

  const handleUploadSuccess = ({}) => {
    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Upload success file via Resource Collections',
    });
    setConfigs(null);
    setLoading(false);
    onChangeCover(uploadingURL);
    uploadCoverSuccess();
  };

  const handleCancelUpload = () => {
    revokeObjectURL(src);
    handleEndUpload();
  };

  const handleRemove = () => {
    if (isLocalUrl) {
      revokeObjectURL(src);
    }
    setConfigs(null);
    setSrc(null);
    setFile(null);
    onChangeCover('');
  };

  const handleConfirm = () => {
    confirmFirstTime && confirmFirstTime();
    onConfirmFirstAdd();
    setFirstAdd(true);
  };

  const handleStartUploadFile = () => {
    setLoading(true);
  };

  const handleEndUpload = () => {
    setConfigs(null);
    setSrc(null);
    setFile(null);
    setLoading(false);
  };

  return (
    <UploadCoverImage
      isFirstTime={isFirstAdd}
      onConfirm={handleConfirm}
      onDropFile={handleDrop}
      onSelectFile={handleSelectedFile}
      onRemove={handleRemove}
      onUploadSuccess={handleUploadSuccess}
      onCancelUpload={handleCancelUpload}
      src={src}
      file={file}
      uploadConfigs={uploadConfigs}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    isFirstTimeAdd: user.is_got_it_add_cover_image_studio_collection,
  };
};

const mapDispatch = dispatch => ({
  confirmFirstTime: bindActionCreators(confirmFirstTime, dispatch),
  getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
  uploadCoverSuccess: bindActionCreators(uploadCoverSuccess, dispatch),
});

const CoverImage = connect(mapStateToProps, mapDispatch)(CoverImageContainer);
export default CoverImage;
