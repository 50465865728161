import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

export const ExerciseItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .index {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #979797;
    margin-right: 4px;
  }

  .media-preview {
    flex: 0 0 30px;
  }

  .info {
    background: #ffffff;
    border: 1px solid #dadfea;
    box-shadow: 0px 2px 3px rgba(76, 98, 135, 0.25);
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    position: relative;
    flex: 1 1;
    overflow: hidden;

    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #444444;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
    }

    .exercise-item__sort {
      margin: 0 10px;
      flex: 0 0 15px;

      :hover {
        cursor: grab;

        rect {
          fill: #5158cf;
        }
      }
    }

    :hover {
      border-color: #5158cf;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  .remove {
    display: none;
  }

  :hover {
    .remove {
      display: block;
    }
  }
`;

export const ExerciseMenu = styled.div`
  border: solid 1px #dcdcde;
  background-color: #f7f8fc;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 15px;
  height: 227px;
  max-height: 220px;
  min-height: 140px;
  width: 345px;
`;

export const Header = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #07141f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px 20px 20px;

  img {
    margin-right: 10px;
  }
`;

export const Body = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #444444;
  padding: 0 20px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: transparent;

  button {
    margin: 0;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818da1;
    padding: 6px 34px;
    border: 1px solid #d6dae4;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: #ffffff;

    &.cancel {
      padding: 6px 24px;
      :hover {
        background-color: #f5f7f9;
      }
    }

    &.save {
      background-color: #5158cf;
      border-color: #5158cf;
      color: #fff;
      :hover {
        box-shadow: 0 5px 14px 0 rgba(92, 91, 189, 0.37);
      }
    }
  }
`;

export const Wrapper = styled.div`
  border: 1px solid #dadfea;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;

  .remove-all {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5158cf;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  .search-exercise-container {
    margin-bottom: 10px;
  }
`;

export const CustomTriggerIcon = styled.div`
  margin-left: 7px;
  background: transparent;
  height: 24px;
  width: 24px;
  z-index: -1;
  position: absolute;
  right: 0;
`;

export const CustomPopup = styled(Popup)`
  &.ui.popup {
    border: unset !important;
    box-shadow: unset !important;
    z-index: 2000 !important;
  }
`;
