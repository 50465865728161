import React, { useState, useEffect } from 'react';
import { diff } from 'deep-diff';
import classnames from 'classnames';
import _ from 'lodash';
import * as S from './style';
import { ENTITY_NAME } from 'database/constants';
import { HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';
import Exercise from '../Exercise';
import LinkSet, { LINK_TYPES } from '../LinkSet';
import { SectionContext } from '../contexts';
import Header from './components/Header';
import SectionDroppable from './components/SectionDroppable';
import ConfirmModal from 'shared/ConfirmModal';
import { ErrorMessage } from 'shared/FormControl';
import { pluralize } from 'utils/commonFunction';
import TextEditable from 'shared/TextEditable';
import ExcerciseEmptyDroppable from './components/ExcerciseEmptyDroppable';
import UploadAndDragSection from 'shared/UploadAndDragSection';
import ExerciseList from './components/ExerciseList';

function Section(props) {
  const { FREESTYLE } = SECTION_FORMAT_KEY;
  const {
    sectionId,
    sectionSize,
    linkable,
    sectionIndex,
    draggingItemLeft,
    showError,
    totalExercises,
    onIsChanging,
  } = props;
  let section = props.model.toJS();
  const [sectionOriginal, setSectionOriginal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState(_.pick(section, ['type', 'format', '_id']));
  const [minimize, setMinimize] = useState(false);
  const [exerciseReferences, setExerciseReferences] = useState(section.exercise_references || []);
  const [originalExerciseReferences, setOriginalExerciseReferences] = useState(section.exercise_references || []);
  const [removeAllExerciseState, setRemoveExerciseState] = useState(false);

  const isDragMode = !!draggingItemLeft;
  const isDragExercise = draggingItemLeft && draggingItemLeft.get('dragType') === 'exercise';

  useEffect(() => {
    const newSectionData = _.pick(section, ['type', 'format', '_id']);

    if (!loading) {
      setSectionOriginal(section);
      setLoading(true);
    }

    if (!_.isEqual(sectionData, newSectionData)) {
      setSectionData(newSectionData);
    }

    // Check changing
    onIsChanging(diff(section, sectionOriginal));
  }, [section]);

  const isHiddenSection = section.type === HIDDEN_SECTION;

  const onUpdateInstructions = note => {
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { note });
  };

  const onUpdateAttachments = attachments => {
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { attachments });
  };

  const onUpdatePrefrenceExercise = exercise => {
    let exerciseReferences = _.map(exercise, item => item);
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { exerciseReferences });
  };

  const updateTitle = title => {
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { title });
  };

  const onDuplicate = () => {
    props.duplicateSection(sectionId);
  };

  const onSaveToLibrary = () => {
    props.saveSectionToLibrary(sectionId);
  };

  const onRemove = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete section"
        content="Are you sure that you want to delete this section?"
        onConfirm={() => props.removeSection(sectionId)}
      />,
    );
  };

  const onCustomize = data => {
    props.customizeSection(sectionId, data);
  };

  const onAddExercise = () => {
    props.addExerciseInsideSection({ sectionId });
  };

  const toggleSectionView = () => {
    setMinimize(!minimize);
  };

  const onRemoveAll = () => {
    setRemoveExerciseState(true);
  };

  const handleRenderValidation = () => {
    if (section.format === FREESTYLE) {
      if (showError && !section.note.trim().length) {
        return (
          <S.NoInstructionsErrorContianer>
            <ErrorMessage className="workoutError--noInstruction">Please add instructions</ErrorMessage>
          </S.NoInstructionsErrorContianer>
        );
      }
    } else {
      return !isHiddenSection && showError && !section.exercises.length ? (
        <S.NoExerciseErrorContianer>
          <ErrorMessage className="workoutError--noExercise">Please add at least 1 exercise</ErrorMessage>
        </S.NoExerciseErrorContianer>
      ) : null;
    }
  };

  const handleTotalExerciseMinimize = section => {
    if (section.format === FREESTYLE) {
      if (section.exerciseReferences) {
        return pluralize('exercise', section.exerciseReferences.length, true);
      }
      return pluralize('exercise', section.exercise_references.length, true);
    }
    return pluralize('exercise', totalExercises, true);
  };

  return (
    <S.Wrapper
      className={classnames('sectionWrapper', {
        'sectionWrapper--hidden': section.type === HIDDEN_SECTION,
        'sectionWrapper--linkable': linkable,
        'sectionWrapper--dragmode': isDragMode,
        'sectionWrapper--minimize': minimize,
      })}
    >
      {isDragMode && (
        <SectionDroppable
          dragType={draggingItemLeft.get('dragType')}
          sectionId={sectionId}
          sectionIndex={sectionIndex}
        />
      )}
      <div
        className={classnames({
          'sectionWrapper--freestyle': section.format === FREESTYLE,
        })}
      >
        {!isHiddenSection && (
          <>
            <Header
              section={section}
              onTitleChange={updateTitle}
              onDuplicate={onDuplicate}
              onSaveToLibrary={onSaveToLibrary}
              onRemove={onRemove}
              onCustomize={onCustomize}
              sectionId={sectionId}
              sectionIndex={sectionIndex}
              sectionSize={sectionSize}
              minimize={minimize}
              changeOrderSection={props.changeOrderSection}
              toggleSectionView={toggleSectionView}
            />
            {!section.title && (
              <ErrorMessage className="workoutError--sectionTitleIsEmpty">Section Name cannot be blank</ErrorMessage>
            )}
            {showError && section.format === SECTION_FORMAT_KEY.TIMED && !section.round ? (
              <ErrorMessage className="workoutError--invalidSectionValue">
                Please set number of rounds for the Timed section.
              </ErrorMessage>
            ) : null}
            {showError && section.format === SECTION_FORMAT_KEY.AMRAP && !section.time ? (
              <ErrorMessage className="workoutError--invalidSectionValue">
                Please set a duration for the AMRAP section.
              </ErrorMessage>
            ) : null}
            {section.format === FREESTYLE && (
              <UploadAndDragSection
                onUpdateAttachments={onUpdateAttachments}
                originalAttachments={section.attachments}
              />
            )}
            <S.InstructionContainer
              className={classnames(
                'section_instructionContainer',
                { white: section.format === FREESTYLE },
                { error: section.format === FREESTYLE && showError && !section.note.trim().length },
                { minimizeOfFreestyle: section.format === FREESTYLE && minimize },
              )}
            >
              <TextEditable
                value={section.note}
                onChange={_.debounce(onUpdateInstructions, 100)}
                placeholder="Add instructions"
                lineHeight={20}
                breakLine={true}
              />
            </S.InstructionContainer>
            {handleRenderValidation()}

            {section.format === FREESTYLE && !minimize && (
              <ExerciseList
                exerciseReferences={exerciseReferences}
                originalExerciseReferences={originalExerciseReferences}
                onSave={setExerciseReferences}
                onCancel={onRemoveAll}
                sectionId={section._id}
                onUpdatePrefrenceExercise={onUpdatePrefrenceExercise}
                removeAllExerciseState={removeAllExerciseState}
                setRemoveExerciseState={setRemoveExerciseState}
              />
            )}
          </>
        )}
        <SectionContext.Provider value={{ ...sectionData, sectionIndex }}>
          <S.Content className="section__content">
            {/* {section.format !== FREESTYLE && handleRenderValidation()} */}
            {section.format !== FREESTYLE && (
              <S.ExerciseList className="section__exerciseList">
                {section.exercises.map((exerciseId, index) => (
                  <React.Fragment key={exerciseId}>
                    <Exercise
                      exerciseId={exerciseId}
                      sectionId={sectionId}
                      exerciseIndex={index}
                      isHiddenSection={isHiddenSection}
                      exerciseSize={section.exercises.length}
                    />
                    {!isDragExercise && index < section.exercises.length - 1 && (
                      <LinkSet type={LINK_TYPES.LINK} sectionId={sectionId} exerciseId={exerciseId} linkIndex={index} />
                    )}
                  </React.Fragment>
                ))}
                {!section.exercises.length && (
                  <ExcerciseEmptyDroppable bottom sectionId={sectionId} exerciseIndex={0} />
                )}
              </S.ExerciseList>
            )}
            {!isHiddenSection && (
              <div className="section__actions">
                <div className="section__moreExercises">
                  {minimize ? (
                    <span>
                      Inside:&nbsp;<span className="count">{handleTotalExerciseMinimize(section)}</span>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                {section.format !== FREESTYLE && (
                  <S.AddExerciseButton onClick={onAddExercise}>Add Exercise</S.AddExerciseButton>
                )}
              </div>
            )}
          </S.Content>
        </SectionContext.Provider>
      </div>
      {isDragMode && (
        <SectionDroppable
          dragType={draggingItemLeft.get('dragType')}
          bottom
          sectionId={sectionId}
          sectionIndex={sectionIndex + 1}
        />
      )}
    </S.Wrapper>
  );
}

export default Section;
