import { Radio } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const baseText = css`
  font-family: 'Open Sans';
  line-height: 150%;
  color: #141632;
`;

export const RadioWrapper = styled.div`
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  .ui.radio.checkbox input:checked ~ label:before,
  .ui.radio.checkbox input:focus:checked ~ label:before {
    border-color: #5158cf !important;
  }
  .ui.radio.checkbox input:checked ~ label::after,
  .ui.radio.checkbox input:focus:checked ~ label::after {
    background-color: #5158cf !important;
  }

  .checkbox-item {
    input {
      z-index: 10;
      width: 24px;
      height: 24px;
    }

    .checkbox-icon {
      border-radius: 4px;
    }

    input:checked ~ .checkbox-icon {
      background: #5158cf;
      border-color: #5158cf;
      box-shadow: 0px 0px 1px rgba(58, 57, 76, 0.24);
      background-image: url(${CDN_URL}/images/checked_white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 13px;
    }
  }
`;

export const OptionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 535px;
  min-height: 58px;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #0000001a;
  ${baseText}
  font-weight: 600;
  font-size: 14px;
  gap: 10px;
  cursor: ${({ isScheduledPost }) => (isScheduledPost ? 'not-allowed' : 'pointer')};

  ${({ isScheduledPost }) =>
    isScheduledPost &&
    css`
      ${RadioWrapper} {
        input,
        label {
          cursor: not-allowed;
        }

        .ui.radio.checkbox input:focus ~ label:before,
        .ui.radio.checkbox input:checked ~ label:before,
        .ui.radio.checkbox input:focus:checked ~ label:before {
          border-color: #d4d4d5 !important;
        }

        :hover {
          .ui.radio.checkbox input:focus ~ label:before,
          .ui.radio.checkbox input:checked ~ label:before,
          .ui.radio.checkbox input:focus:checked ~ label:before {
            border-color: #d4d4d5 !important;
          }
        }

        .ui.radio.checkbox input:checked ~ label::after,
        .ui.radio.checkbox input:focus:checked ~ label::after {
          visibility: hidden;
        }

        .ui.checkbox input.hidden + label {
          cursor: not-allowed !important;
        }
      }
    `};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${baseText}
  font-weight: 600;
  font-size: 14px;
  z-index: 1;
  gap: 16px;
`;

export const VotePercent = styled.div`
  position: absolute;
  left: 0;
  width: ${({ percent }) => (percent ? `${percent}%` : '0px')};
  border-radius: 8px;
  height: 100%;
  background-color: ${({ highestPercent }) => (highestPercent ? '#f0efff' : '#F5F5F5')};

  transition: width 0.5s;
`;

export const Title = styled.span`
  overflow-x: hidden;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const AddBy = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;

  span {
    font-weight: 700;
  }
`;

export const RightSection = styled.div`
  display: flex;
  opacity: 0.7;
  z-index: 1;
  font-size: 12px;

  .arrow-right-icon {
    width: 20px;
    height: 20px;

    path {
      stroke: #141414;
    }
  }

  :hover {
    opacity: 1;
    color: #5158cf;
    .arrow-right-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
`;

export const RemoveWrapper = styled.div`
  display: flex;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  z-index: 1;

  top: -4px;
  right: -7px;

  .remove-icon {
    width: 16px;
    height: 16px;

    path {
      fill: #111111;
    }
  }
`;

export const RadioButton = styled(Radio)`
  label {
    ::before {
      border-width: 1px !important;
      width: 24px !important;
      height: 24px !important;
      transition: none !important;
      top: 0 !important;
    }

    ::after {
      width: 14px !important;
      height: 14px !important;
      transform: none !important;
      top: 5px !important;
      left: 5px !important;
      transition: none !important;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${({ isOwner }) =>
    isOwner &&
    css`
      :hover {
        ${RemoveWrapper} {
          visibility: visible;
        }
      }
    `}

  :hover {
    ${OptionWrapper} {
      border-color: ${({ isScheduledPost }) => !isScheduledPost && '#5158cf'}!important;
    }
  }
`;
