import styled from 'styled-components';
import { FormGroup } from 'shared/FormControl';

export const SelectOptionLabel = styled.div`
  flex: 1 1;
  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #111111;
  }
  .email {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8d8c92;
  }
`;

export const SelectOptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 13px;
  border-bottom: 1px solid #eceff1;
  cursor: pointer;

  &.select__option--is-focused {
    background-color: #FAFBFC;
  }

  .sb-avatar {
    margin-right: 12px;
  }

  .sb-avatar__image,
  .sb-avatar__text {
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 6px;
  }
`;

export const Body = styled.div`
  padding: 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;

  .description {
    margin-bottom: 20px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 5px;

    :first-child {
      margin-left: 0;
    }
  }
`;

export const Container = styled.div`
  padding: 20px;

  .single-select-container {
    font-family: Open Sans;

    .single-select__menu {
      border: 1px solid #DCDCDE;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      margin-top: 5px;
    }

    .single-select__control {
      background-color: #fafbfc;
      border: 1px solid #d4d7d9;
      box-shadow: none !important;
      border-radius: 5px;
      min-height: 42px;

      .single-select__value-container {
        padding-left: 13px;
      }

      &.single-select__control--is-focused {
        border-color: #6456c4;
      }

      .single-select__input {
        input {
          height: unset;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 150%;
          color: #323c47;
        }
      }

      .single-select__placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: #323c47;
      }
    }

    .single-select__indicators {
      cursor: pointer;
    }

    .single-select__indicator-separator {
      display: none;
    }

    .single-select__indicator {
      padding-right: 15px;
    }
  }

  ${FormGroup} {
    .error {
      margin-top: 15px;
      color: #ff2e21;
    }
  }
`;
