import React, { createRef } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';

import { sendFeedbackAIWorkoutRequest } from 'utils/hubspot';
import { submitTellMoreUs } from 'components/WorkoutDetailModalAIDemo/actions';

import { CustomConfirmModal } from 'components/WorkoutDetailModal/style';
import { toggleConfirmModal } from 'actions/modal';
import { CDN_URL, KEY_CODE } from 'constants/commonData';
import { TextEditable } from '../style';
import './style.scss';
import { Mixpanel } from 'utils/mixplanel';

export default class TellUsMoreModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSending: false,
      feedbackText: '',
      typedFB: false,
    };
    this.inputRef = createRef();
  }

  componentWillReceiveProps(props) {
    if (props.open) {
      setTimeout(() => {
        this.inputRef.current && this.inputRef.current.focus();
      }, 200);
    } else {
      this.setState({
        isSending: false,
        feedbackText: '',
        typedFB: false,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onEscPressed);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onEscPressed);
  }

  onEscPressed = event => {
    const { open } = this.props;
    if (event.keyCode === KEY_CODE.esc && open) {
      this.handleClose();
      event.stopPropagation();
    }
  };

  onSubmitFeedback = async () => {
    const { feedbackText } = this.state;
    const { nameUser, emailUser, onClose, dispatch, workoutAIPage } = this.props;
    const text = feedbackText.trim() || this.inputRef.current.innerText;

    if (text.length === 0) return;

    Mixpanel.track('submit_tell_us_more_ai_workout_builder', { component: workoutAIPage, feedback: text });

    try {
      this.setState({ isSending: true });

      const params = {
        email: emailUser,
        name: nameUser,
        message: text,
      };
      await sendFeedbackAIWorkoutRequest(params);
      onClose();
      dispatch(submitTellMoreUs({ feedback: text }));
    } catch (error) {
      console.error(error);
    }
    this.setState({ isSending: false });
  };

  onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode === KEY_CODE.esc) {
      this.handleClose();
      event.stopPropagation();
      return;
    }

    if (keyCode > 36 && keyCode < 41) {
      // press arrow key
      event.stopPropagation();
      return;
    }
  };

  onKeyUp = event => {
    this.setState({ typedFB: (event.target.innerText || '').trim().length > 0 });
  };

  onCloseCurrentPopup = () => {
    this.props.onClose();
  };

  handleDenyClosePopup = () => {
    const { dispatch } = this.props;
    dispatch(toggleConfirmModal(false));
  };

  handleClose = () => {
    const { dispatch, onClose } = this.props;
    if (this.inputRef.current.innerText) {
      dispatch(
        toggleConfirmModal(
          true,
          <CustomConfirmModal
            onConfirm={this.onCloseCurrentPopup}
            onDeny={this.handleDenyClosePopup}
            noBorder
            title="Discard Changes?"
            content={`Are you sure you want to go? Changes have not been saved yet.`}
            confirmButtonTitle="Discard changes"
            hasCloseIcon
            headerIcon={`${CDN_URL}/images/discard_changes_icon.svg`}
          />,
        ),
      );
    } else {
      onClose();
    }
  };

  render() {
    const { open } = this.props;
    const { isSending, feedbackText, typedFB } = this.state;

    return (
      <Modal
        id="ai-tell-us-more-modal"
        open={open}
        className="confirm-popup-container"
        closeOnDimmerClick={false}
        onClose={this.handleClose}
      >
        <Modal.Content className="confirm-content">
          <Button onClick={this.handleClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="confirm-content-header">
            <p className="confirm-header-label">Tell us more</p>
          </div>
          <div className="confirm-content-body">
            <div className="confirm-content-label">
              <TextEditable
                dangerouslySetInnerHTML={{ __html: feedbackText }}
                contentEditable
                ref={this.inputRef}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                placeholder="Type in your feedback"
                // onBlur={evt => this.setState({ feedbackText: evt.target.innerText })}
                className="feedback-input"
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions className="confirm-actions">
          <Button className="confirm-no-button" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button className="confirm-yes-button" onClick={this.onSubmitFeedback} disabled={isSending || !typedFB}>
            Submit Feedback
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
