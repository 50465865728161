import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px 15px;
  border: 1px solid #dadfea;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  &:hover {
    border-color: #5559ff;
    box-shadow: 0px 2px 4px #e8eaf3;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const IconWrapper = styled.div`
  margin-right: 15px;
`;

export const DetailWrapper = styled.div``;

export const Title = styled.h5`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  img {
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }
`;

export const Desc = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #202353;
`;
