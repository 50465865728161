import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #A3A3B5;
`;

export const SynonymWrapper = styled.div`
  width: 100%;
  min-width: 369px;
`;

export const List = styled.div``;

export const ButtonAdd = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #5158cf;
  cursor: pointer;
  height: 30px;
  margin-top: 4px;
  width: 78px;

  svg {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
`;
