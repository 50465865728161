import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const RemoveIcon = styled.div`
  width: 22px;
  height: 22px;
  background: transparent url(${CDN_URL}/images/close_grey_purple.svg) no-repeat center center;
  background-size: 100%;
  cursor: pointer;
  display: none;
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TextMessage = styled.div`
  padding: 11px 20px 11px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  background-color: #7470ef;
  border-radius: 20px;
  border-top-right-radius: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  mark {
    background: #fff3c2;
    border-radius: 2px;
    padding: 3px 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }
`;

export const FileMessage = styled.div`
  max-width: 300px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  video {
    max-width: 300px;
    max-height: 200px;
    min-width: 60px;
    display: block;
  }

  .image-with-fallback {
    img {
      max-width: 100%;
      max-height: 200px;
      min-width: 60px;
      width: unset;
      height: unset;
      display: block;
    }
  }
`;

export const MessageContainer = styled.div`
  max-width: calc(100% - 70px);
  position: relative;

  &.disabled {
    :hover {
      cursor: not-allowed;
    }
  }
  .media-player__trigger {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 5px;

  :hover {
    ${RemoveIcon} {
      display: block;
    }
  }

  .sb-avatar.messageItem__avatar {
    margin-left: 10px;
  }
`;
