import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeQueryParams, updateSelectedSection } from 'redux/section-library/actions';

import { ReactComponent as MostRecentIcon } from 'assets/icons/most_recent.svg';
import { ReactComponent as SectionIcon } from 'assets/icons/dumbbell_section.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';

import { Checkbox } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import { CheckBoxContainer, SectionHeaderCell, SortActiveWrapper, SortWrapper } from './style';

const columns = [
  {
    name: 'Section Format',
    icon: <SectionIcon className="column-symbol" />,
    alignCenter: true,
    hideCursor: true,
  },
  {
    key: 'last_used',
    name: 'Most Recent',
    icon: <MostRecentIcon className="column-symbol" />,
    alignCenter: true,
    hideCursor: false,
  },
];

const mostRecentKey = 'last_used';

function TableHeader(props) {
  const { sort, sorter, list, total, selectedSection } = props;
  const ids = _.map(list, '_id');
  const isSelectedAll = selectedSection.length && _.difference(ids, selectedSection).length === 0;

  const renderSorterIndicator = column_name => {
    if (sorter !== column_name) {
      return <ArrowDown className="arrow" />;
    }

    if (sorter === mostRecentKey) {
      return sort === 1 ? <ArrowUp className="arrow" /> : <ArrowDown className="arrow" />;
    }

    return sort === -1 ? <ArrowUp className="arrow" /> : <ArrowDown className="arrow" />;
  };

  const onSort = key => {
    if (!key) return;
    props.changeQueryParams({
      sorter: key,
      sort: key === sorter ? sort * -1 : key === mostRecentKey ? -1 : 1,
      page: 1,
    });
  };

  const selectAllSection = e => {
    if (e.target.checked) {
      props.updateSelectedSection([...new Set([...selectedSection, ...ids])]);
    } else {
      props.updateSelectedSection(selectedSection.filter(id => !ids.includes(id)));
    }
  };

  return (
    <Layout.TableHeader>
      <Layout.TableRow>
        <SectionHeaderCell key="title" className="title">
          <Layout.TableHeaderCellContent>
            <CheckBoxContainer onClick={e => e.stopPropagation()}>
              <Checkbox checked={isSelectedAll} onChange={selectAllSection} />
            </CheckBoxContainer>
            <Layout.CellTitle className="column-symbol">
              <SortWrapper>
                <SortActiveWrapper active={sorter === 'title' && total !== 0} onClick={() => onSort('title')}>
                  <SectionIcon className="column-symbol" />
                  <div className="column-symbol">Sections ({total})</div>
                  {renderSorterIndicator('title')}
                </SortActiveWrapper>
              </SortWrapper>
            </Layout.CellTitle>
          </Layout.TableHeaderCellContent>
        </SectionHeaderCell>
        {_.map(columns, item => {
          return (
            <SectionHeaderCell key={item.key} alignCenter={item.alignCenter} className="title">
              <Layout.TableHeaderCellContent onClick={() => onSort(item.key)}>
                <Layout.CellTitle className="column-symbol">
                  <SortActiveWrapper hideCursor={item.hideCursor} active={sorter === item.key && total !== 0}>
                    {item.icon}
                    <div className="column-symbol">{item.name}</div>
                    {item.key === 'last_used' && renderSorterIndicator(item.key)}
                  </SortActiveWrapper>
                </Layout.CellTitle>
              </Layout.TableHeaderCellContent>
            </SectionHeaderCell>
          );
        })}
        <SectionHeaderCell key="actions" className="actions" />
      </Layout.TableRow>
    </Layout.TableHeader>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      sectionLibrary: {
        query: { sort, sorter },
        list,
        total,
        selectedSection,
      },
    },
  } = state;

  return { sorter, sort, list, total, selectedSection };
};

const mapDispatch = dispatch => {
  return {
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    updateSelectedSection: bindActionCreators(updateSelectedSection, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableHeader);
