import classNames from 'classnames';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import upperCase from 'lodash/upperCase';

import { pluralize } from 'utils/commonFunction';

import * as S from './styles';

const PaymentCounter = ({ currency, counter, onOpenPopup }) => {
  const isCHF = ['CHF', 'SEK'].includes(upperCase(currency));
  const isNOK = upperCase(currency) === 'NOK';
  return (
    <S.CounterWrapper className="profile-part">
      <S.CurrencySymbol
        className={classNames({
          chf: isCHF,
          nok: isNOK,
        })}
      >
        {currency}
      </S.CurrencySymbol>
      <S.CounterTitle>Purchases:</S.CounterTitle>{' '}
      <S.CounterNumber onClick={onOpenPopup} data-tip="View All Purchases" data-for="payment-counter-tooltip">
        {counter} Active {pluralize('Package', counter)}
      </S.CounterNumber>
      <ReactTooltip className="payment-counter" id="payment-counter-tooltip" effect="solid" place={'top'} />
    </S.CounterWrapper>
  );
};

export default PaymentCounter;
