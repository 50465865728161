import React from 'react';
import ChartHeader from './ChartHeader';
import StepTrackingChart from './StepTrackingChart';

function Chart() {
  return (
    <div>
      <ChartHeader />
      <StepTrackingChart />
    </div>
  );
}

export default Chart;
