import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export const ArrowIcon = styled.div`
  height: 32px;
  width: 32px;
  background-image: url(${CDN_URL}/images/arrow_slide_next_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
`;

export const SlideDot = styled.span`
  height: 8px;
  width: 8px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
`;

export const SlideItemWrapper = styled.div`
  position: relative;
  height: 317px;
  display: flex;
  justify-content: center;
  background-color: ${props => (props.noImage ? '#F3F3F3' : '#000000')};
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => (props.noImage ? 'center' : 'contain')};
  padding-left: 1px;

  .media__play-button {
    width: 44px;
    height: 44px;
    background: url(${CDN_URL}/images/play_icon_grey.svg) no-repeat center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SlideContainer = styled.div`
  margin-bottom: 15px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  background-color: #000000;
  height: 317px;

  .slick-dots {
    bottom: 0;

    li {
      width: unset;
      height: 18px;

      button {
        width: 8px;
        height: 8px;
        padding: 0;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid #ffffff;
        box-sizing: border-box;

        ::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: #ffffff;
        }
      }
    }
  }

  .slick-next {
    right: 0px;
    height: 100%;
    width: 52px;
    background-color: transparent;
    background-image: url(${CDN_URL}/images/arrow_slide_next_icon.svg);
    background-position: center;
    background-repeat: no-repeat;

    :hover {
      background-color: #00000010;
      background-image: url(${CDN_URL}/images/arrow_slide_next_icon.svg);
      background-position: center;
      background-repeat: no-repeat;
    }

    ::before {
      content: none;
    }
  }

  .slick-prev {
    z-index: 1;
    left: 0px;
    height: 100%;
    width: 52px;
    background-color: transparent;
    background-image: url(${CDN_URL}/images/arrow_slide_prev_icon.svg);
    background-position: center;
    background-repeat: no-repeat;

    :hover {
      background-color: #00000010;
      background-image: url(${CDN_URL}/images/arrow_slide_prev_icon.svg);
      background-position: center;
      background-repeat: no-repeat;
    }

    ::before {
      content: none;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  padding-top: 0px;

  .exercise-overview__custom-tag {
    border-color: transparent;
    background: #f6f7f9;
    border-radius: 2px;
    color: #20235350;
  }

  .exercise-overview__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #202353;
    margin-top: 8px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    -webkit-box-orient: vertical;
  }
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 569px;
    max-height: 611px;

    .header {
      padding: 0 !important;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0px !important;
      border-radius: 5px !important;
    }
  }
`;

export const CustomTag = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 3px 6px;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  color: #ffffff;
`;
