import React, { useEffect } from 'react';
import get from 'lodash/get';
import { AUTOFLOW_TYPES } from 'constants/commonData';

export default ({ type, exactDateComponents, intervalComponents, children, ...props }) => {
  useEffect(() => {
    if (
      props.isHabitPermission &&
      process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT &&
      !get(props, 'habitUnits', []).length &&
      props.getHabitUnits
    ) {
      props.getHabitUnits();
    }
  }, [props.isHabitPermission]);

  const Component = type === AUTOFLOW_TYPES.INTERVAL ? intervalComponents : exactDateComponents;

  if (Component) {
    return (
      <Component.Layout {...props} cell={Component.Cell}>
        {children}
      </Component.Layout>
    );
  }

  return null;
};
