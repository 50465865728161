import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { likePost } from 'redux/foodJournal/actions';
import Post from 'shared/Post';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const { rootReducer, user, cloudfrontList } = state;
  const { foodJournal } = rootReducer;
  const { allPosts } = foodJournal;
  const data = allPosts.find(item => item._id === id);

  return {
    cloudfrontList,
    data,
    userId: user._id,
  };
};

const mapDispatch = dispatch => ({
  likePost: bindActionCreators(likePost, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(Post);
