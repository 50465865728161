import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import ReactFloater from 'react-floater';
import styled from 'styled-components';
import OnboardingTooltip, { Content, Title } from 'components/Onboarding/Tooltip';
import { markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { connect } from 'react-redux';
import { WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';

const Wrapper = styled.div`
  
  .onboarding-tooltip {
    position: absolute;
    right: -10px;
    top: 3px;
  }

  .onboarding-tooltip__arrow {
    top: 0;
    right: 47px;
    border-bottom: none;
    border-left: none;
}
  }

  .onboarding-tooltip__content {
    visibility: visible;
    right: 50px;
    top: -70px;
    text-transform: none;
  }
`;

function SupersetLink(props) {
  const { workoutBuilderGuideSteps, isFirst, floaterOptions, onClick} = props;
  const isDoneWorkoutGuideStep1 = _.get(workoutBuilderGuideSteps, [WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection]);
  const isDoneWorkoutGuideStep2 = _.get(workoutBuilderGuideSteps, [WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions]);
  const isDoneWorkoutGuideStep3 = _.get(workoutBuilderGuideSteps, [WORKOUT_BUILDER_GUIDE_STEPS.CreateSuperset]);
  const isShowGuide = isFirst && isDoneWorkoutGuideStep1 && isDoneWorkoutGuideStep2 && !isDoneWorkoutGuideStep3;

  return (
      <Wrapper className={props.unlink ? 'divider-exercise' : 'unlink-exercise'}>
        <div className={props.unlink ? 'divider-image-container' : 'link-image-container'}>
          <ReactFloater {...floaterOptions} content={<div>{props.unlink ? 'Unlink superset' : 'Link superset'}</div>}>
            <div
              className="divider-image"
              onClick={onClick}
            />
          </ReactFloater>
          {isShowGuide ? (
            <OnboardingTooltip
              place="left"
              align="end"
              showCloseButton
              customPosition
              onClick={(event) => {
                event.stopPropagation();
              }}
              onClose={() => props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateSuperset)}
            >
              <Content maxWidth="290">
                <Title>Create a Superset</Title>
                Click the link icon to create a a superset between the two exercises. You can link as many exercises as you want.
              </Content>
            </OnboardingTooltip>
          ) : null}
        </div>
      </Wrapper>
  )
}

const mapStateToProps = state => {
  return {
    workoutBuilderGuideSteps: state.rootReducer.onboarding.workoutBuilderGuideSteps
  }
}

const mapDispatchToProps = dispatch => {
  return {
    markWorkoutBuilderGuide: bindActionCreators(markWorkoutBuilderGuide, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupersetLink)