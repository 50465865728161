import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const HeaderIcon = styled.div`
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
  border-radius: 50%;
  margin-right: 10px;
  background: url(${CDN_URL}/images/inbox_line_white.svg) no-repeat center;
  background-color: #5C5BBD;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 15px;
`;

export const Divide = styled.div`
  width: 100%;
  height: 1px;
  background: #F0EFF3;
  margin: 15px 0;
`;

export const Detail = styled.div`
  background: #F3F5F8;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #4E5662;
  padding: 10px;
`;

export const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #323C47;
  margin-bottom: 30px;

  p {
    margin: 0;
    line-height: inherit;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 5px;
    padding: 5px 30px;
  }
`;

export const Wrapper = styled.div`
  padding: 20px;
`;