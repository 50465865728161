// Libs
import React from 'react';
import classNames from 'classnames';

// Parts
import { SWITCH_VIEW_TRIGGER_TYPES } from 'shared/TriggerForms/constants/types';

const Tabs = props => {
  const { currentTab, changeTab } = props;

  return (
    <div className="tabs-wrapper">
      <div className="tabs-content-wrapper">
        <button
          className={classNames('tab-item', { active: currentTab === SWITCH_VIEW_TRIGGER_TYPES.PROGRAM })}
          onClick={changeTab(SWITCH_VIEW_TRIGGER_TYPES.PROGRAM)}
        >
          <img src="/images/program-icon.svg" alt="" />
          <span className="tab-item-label">Program</span>
        </button>
        <button
          className={classNames('tab-item', { active: currentTab === SWITCH_VIEW_TRIGGER_TYPES.FORUM })}
          onClick={changeTab(SWITCH_VIEW_TRIGGER_TYPES.FORUM)}
        >
          <img src="/images/forum-icon.svg" alt="" />
          <span className="tab-item-label">Forum</span>
        </button>
      </div>
    </div>
  );
};

export default Tabs;
