import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Radio, Form, Label, Image } from 'semantic-ui-react';
import diff from 'deep-diff';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import { FeatureSettings } from 'shared/Settings';
import { Toggle } from 'shared/FormControl';
import { SaveButton } from 'shared/Settings/style';
import TimezoneSelect from 'shared/TimezoneSelect';
import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import DateFormatSelect from 'shared/DateFormatSelect';

import { isOnwer, isAdmin } from 'utils/validations';

import './styles.scss';
import ClientMetricSetting from './ClientMetricSetting';
import WorkoutSetting from './WorkoutSetting';
import { PermissionSetting } from 'shared/Settings';
import * as S from './style';
import * as CONSTANTS from './constants';
import { CDN_URL, DATE_FORMATS } from 'constants/commonData';
import SidebarSetting from 'components/SidebarSetting';
class ClientSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editTimezone: null,
      originTimezone: null,
      isTimezoneChanged: false,
      originUnit: null,
      editUnit: null,
      isChangeClientUnit: true,
      isUnitChanged: false,
      dateFormat: this.getDateFormat(),
      isFuturesChanged: false,
      isClientWorkoutChange: false,
    };
  }

  componentDidMount() {
    this.props.getUnitPreferences(this.props.selectedClient._id).then(result => {
      if (result && result.data && result.data.data) {
        const data = result.data.data;
        const unit = {
          distance: data.distance ? data.distance._id : null,
          weight: data.weight ? data.weight._id : null,
          length: data.height ? data.height._id : null,
        };
        this.setState({
          editTimezone: this.props.selectedClient.timezone,
          originTimezone: this.props.selectedClient.timezone,
          originUnit: unit,
          editUnit: _.cloneDeep(unit),
        });
      }
    });
    process.env.REACT_APP_ENABLE_PERMISSION_CONTROL && this.props.getClientListSubCoach(this.props.selectedClient._id);
  }

  getDateFormat() {
    const { selectedClient } = this.props;
    return selectedClient.date_format
      ? { label: selectedClient.date_format, value: selectedClient.date_format }
      : DATE_FORMATS[0];
  }

  getAllUnits = () => {
    const { unit_categories } = this.props;
    const units = [...CONSTANTS.UNITS];

    _.forEach(units, item => {
      const settingObject = unit_categories.find(o => o.name.toLowerCase() === item.dbName) || {
        units: [],
      };

      _.forEach(item.units, unit => {
        unit.data = settingObject.units.find(u => u.unique_code === unit.key) || {};
      });
    });

    return units;
  };

  renderUnitSettings = () => {
    const { editUnit, originUnit } = this.state;

    if (!editUnit) {
      return null;
    }

    const allUnits = this.getAllUnits();
    const isDiffUnit = diff(originUnit, editUnit);

    const handleDiscardChange = nextLocation => {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          noBorder
          title="Discard Changes?"
          content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
          onConfirm={() => {
            this.setState(
              {
                isUnitChanged: false,
              },
              () => {
                this.props.push(nextLocation.pathname);
              },
            );
          }}
          confirmButtonTitle="Discard Changes"
          hasCloseIcon
          hasHoverState
        />,
      );
      return false;
    };

    return (
      <S.Section id={CONSTANTS.SIDEBAR_ID.units}>
        <Prompt when={this.state.isUnitChanged} message={handleDiscardChange} />
        <S.Left>
          <div className="setting__name">
            <Image width={17} height={17} src={`${CDN_URL}/images/unit.svg`} />
            <span>{CONSTANTS.SIDEBAR_SETTING[0].title}</span>
          </div>
        </S.Left>
        <S.Center>
          <S.Units>
            {_.map(allUnits, item => (
              <Form.Group key={item.key}>
                <Label>{item.label}</Label>
                {_.map(item.units, unitItem => (
                  <Form.Field
                    key={unitItem.key}
                    control={Radio}
                    label={unitItem.label}
                    className={unitItem.data._id === editUnit[item.key] ? 'active' : 'isactive'}
                    value={unitItem.data._id}
                    checked={unitItem.data._id === editUnit[item.key]}
                    onChange={(evt, { value }) => {
                      const isDiff = diff(originUnit, { ...editUnit, [item.key]: value });
                      if (!isDiff) {
                        this.setState({
                          editUnit: { ...editUnit, [item.key]: value },
                          isChangeClientUnit: true,
                          isUnitChanged: false,
                        });
                      } else {
                        this.setState({
                          editUnit: { ...editUnit, [item.key]: value },
                          isUnitChanged: true,
                        });
                      }
                    }}
                  />
                ))}
              </Form.Group>
            ))}
          </S.Units>
        </S.Center>
        <S.Right>
          {isDiffUnit && <SaveButton onClick={this.handleSaveClientPreferences.bind(this)}>Save</SaveButton>}
        </S.Right>
        {isDiffUnit && (
          <>
            <S.Left />
            <S.Center>
              <Toggle
                className="unit-client-toggle"
                checked={this.state.isChangeClientUnit}
                onChange={() => {
                  this.setState(state => ({
                    isChangeClientUnit: !state.isChangeClientUnit,
                  }));
                }}
                label="Also update units for Metrics"
              />
            </S.Center>
          </>
        )}
      </S.Section>
    );
  };

  renderTimezoneSettings = () => {
    const { editTimezone, originTimezone, dateFormat } = this.state;
    const { selectedClient } = this.props;
    let isDiffTimezone = editTimezone !== originTimezone;
    let isDiffDateFormat = !_.isEqual(dateFormat.value, selectedClient.date_format || DATE_FORMATS[0].value);

    const handleDiscardChange = nextLocation => {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          noBorder
          title="Discard Changes?"
          content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
          onConfirm={() => {
            this.setState(
              {
                isTimezoneChanged: false,
                dateFormat: this.getDateFormat(),
              },
              () => {
                this.props.push(nextLocation.pathname);
              },
            );
          }}
          confirmButtonTitle="Discard Changes"
          hasCloseIcon
          hasHoverState
        />,
      );
      return false;
    };

    return (
      <S.Section id={CONSTANTS.SIDEBAR_ID.timezone}>
        <Prompt when={this.state.isTimezoneChanged || isDiffDateFormat} message={handleDiscardChange} />
        <S.Left>
          <div className="setting__name">
            <Image width={17} height={17} src={`${CDN_URL}/images/timezone.svg`} />
            <span>{CONSTANTS.SIDEBAR_SETTING[1].title}</span>
          </div>
        </S.Left>
        <S.Center>
          <TimezoneSelect
            onChange={key => {
              this.setState({ editTimezone: key.value, isTimezoneChanged: key.value !== originTimezone });
            }}
            timezone={editTimezone || selectedClient.timezone}
          />
          <DateFormatSelect dateFormat={dateFormat} onChange={this.handleChangeDateFormat} />
        </S.Center>
        <S.Right>
          {(isDiffTimezone || isDiffDateFormat) && (
            <SaveButton onClick={this.handleUpdateTimezoneClick}>Save</SaveButton>
          )}
        </S.Right>
      </S.Section>
    );
  };

  handleChangeDateFormat = value => this.setState({ dateFormat: value });

  handleSaveClientPreferences() {
    const { editUnit } = this.state;
    this.props
      .updateUnitPreferences({
        client: this.props.selectedClient._id,
        weight: editUnit.weight,
        height: editUnit.length,
        distance: editUnit.distance,
        isUpdateUnitsForMetrics: this.state.isChangeClientUnit,
      })
      .then(result => {
        if (result && result.data && result.data.data) {
          const data = result.data.data;
          this.props.updateClientPreference(this.props.selectedClient._id, data);
          this.props.getClientMetrics(this.props.selectedClient._id);
          const unit = {
            distance: data.distance ? data.distance._id : null,
            weight: data.weight ? data.weight._id : null,
            length: data.height ? data.height._id : null,
          };
          this.setState({
            originUnit: unit,
            editUnit: _.cloneDeep(unit),
            isUnitChanged: false,
            isChangeClientUnit: true,
          });
        }
      });
  }

  handleUpdateTimezoneClick = () => {
    this.props
      .updateClientInfo(this.props.selectedClient._id, {
        timezone: this.state.editTimezone,
        date_format: this.state.dateFormat.value,
      })
      .then(result => {
        this.setState({
          isTimezoneChanged: false,
          originTimezone: this.state.editTimezone,
        });
      });
  };

  onSaveFeatureSettings = ({ types }) => {
    const { selectedClient } = this.props;
    const { _id } = selectedClient;
    const bodyData = { client: _id, types: _.map(types, item => ({ ...item, client: _id })) };

    return this.props.updateFeaturePreferences(bodyData);
  };

  onFuturesChanged = value => {
    this.setState({ isFuturesChanged: value });
  };

  onClientWorkoutChange = value => {
    this.setState({ isClientWorkoutChange: value });
  };

  handleScrollTag = idTag => {
    const element = document.getElementById(idTag);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { selectedClient, permission, user, idTrainer } = this.props;
    const { isFuturesChanged, isClientWorkoutChange, isTimezoneChanged, isUnitChanged } = this.state;
    const isAllChangeSetting = [isFuturesChanged, isClientWorkoutChange, isTimezoneChanged, isUnitChanged];

    if (!selectedClient) {
      return null;
    }

    const { feature_preferences } = selectedClient;

    const isTrainerOfClient = user._id === idTrainer;

    return (
      <S.Wrapper>
        <SidebarSetting sidebarSetting={CONSTANTS.SIDEBAR_SETTING} onScrollTag={this.handleScrollTag} />
        <S.Content>
          <div className="accountdetails-container client-settings">
            {this.renderUnitSettings()}
            {this.renderTimezoneSettings()}
            <FeatureSettings
              permission={permission}
              originSettings={feature_preferences}
              onSave={this.onSaveFeatureSettings}
              showMetricSetting={true}
              onClickManageMetric={() => this.props.toggleModal(true, <ClientMetricSetting />)}
              header={
                <S.FeatureSettingGuide>
                  <div className="features__guide__note">
                    <p>{CONSTANTS.FEATURES_TIP}</p>
                    {CONSTANTS.DEVICES.includes(selectedClient.last_used_device) && (
                      <Image
                        src={`${CDN_URL}/images/${
                          selectedClient.last_used_device === 'android' ? 'android_green' : 'apple_grey'
                        }.svg`}
                        data-tip
                        data-for="device-guide-tooltip"
                      />
                    )}
                  </div>
                  {CONSTANTS.DEVICES.includes(selectedClient.last_used_device) && (
                    <ReactTooltip
                      className="app-tooltip device-guide-tootip"
                      id={`device-guide-tooltip`}
                      effect="solid"
                      place={'top'}
                      delayShow={500}
                    >
                      <span>
                        {selectedClient.last_used_device === 'android' ? CONSTANTS.ANDROID_TIP : CONSTANTS.IOS_TIP}
                      </span>
                    </ReactTooltip>
                  )}
                  <a
                    className="features__guide__link"
                    href="http://help.everfit.io/en/articles/2836312-manage-client-features-and-unit-settings"
                    target="_blank"
                  >
                    <Image src={`${CDN_URL}/images/feature_setting_guide.svg`} />
                    <span>How to disable/enable features</span>
                  </a>
                </S.FeatureSettingGuide>
              }
              isAllChangeSetting={isAllChangeSetting}
              onFuturesChanged={this.onFuturesChanged}
            />
            <WorkoutSetting
              isAllChangeSetting={isAllChangeSetting}
              client={selectedClient._id}
              onClientWorkoutChange={this.onClientWorkoutChange}
            />
            {process.env.REACT_APP_ENABLE_PERMISSION_CONTROL &&
              (isOnwer(user) || isAdmin(user) || isTrainerOfClient) && <PermissionSetting />}
          </div>
        </S.Content>
      </S.Wrapper>
    );
  }
}

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      client: {
        workingClientDetail: { trainers = [] },
      },
    },
  } = state;

  return {
    user,
    idTrainer: trainers[0].trainer,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSettings);
