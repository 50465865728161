import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Icon = styled.div`
  margin-right: 10px;
  width: 43px;
  height: 35px;
  background: transparent url(${CDN_URL}/images/drop_image.svg) no-repeat center;
  background-size: contain;
`;

export const Text = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .select-file__trigger {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const Thumbnail = styled.div`
  height: 100%;
  width: 68px;
  flex: 0 0 68px;
  margin-right: 10px;
  background: #fff url(${props => props.thumbnail}) no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #dadfea;
  border-radius: 5px;
`;

export const FileSize = styled.div``;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/cancel.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
`;

export const NameRightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #979797;

  > * {
    margin-left: 10px;
  }
`;

export const NameLeftSide = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #202353;
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FileNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressBar = styled.div`
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #5158cf;
  transition: width 0.25s ease;
  z-index: 1;
`;

export const UploadProgress = styled.div`
  width: 100%;
  height: 5px;
  background-color: #efefef;
  position: relative;
  margin-top: 10px;
`;

export const UploadInformation = styled.div`
  flex: 1 1;
  overflow: hidden;
`;

export const FileInformation = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 18px 14px 15px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px dashed #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  height: 85px;
  width: 100%;
  outline: none;

  ${props =>
    props.isDragging &&
    css`
      border-color: #5158cf;

      ${Icon} {
        background-image: url(${CDN_URL}/images/drop_image_purple.svg);
      }
    `}
`;
