import { toast } from 'react-toastify';
import { debounce, isEmpty, omit, startCase } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Radio } from 'semantic-ui-react';
import { axiosInstance } from 'configs/request';
import { omitEmptyRequestParams } from 'utils/commonFunction';
import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import LoadingIndicator from 'shared/LoadingIndicator';
import GeneralButton from 'shared/GeneralButton';
import { CDN_URL } from 'constants/commonData';
import { ReactComponent as FormItem } from 'assets/icons/form_doc.svg';
import { ReactComponent as EmptyUI } from 'assets/icons/empty_choose_form.svg';
import { ReactComponent as FirstCreateNewIconSrc } from 'assets/icons/first_create_new_form.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/white_plus.svg';
import { ReactComponent as PenOutlined } from 'assets/icons/pen-outlined.svg';
import * as S from './style';
import './styles.scss';

const TABS = [
  {
    key: 'your_form',
    value: 'your forms',
  },
  {
    key: 'all_form',
    value: 'all forms',
  },
];

const DEFAULT_QUERY = {
  page: 1,
  per_page: 20,
  text_search: '',
  fetch_type: 'your_form',
  sort: -1,
  sorter: 'last_updated_at',
  status: 'published',
  total: 0,
  your_form: 0,
  all_form: 0,
};

const ChooseFormPopup = ({ form, push, toggleModal, onClose, onSelectForm }) => {
  const [query, setQuery] = useState(DEFAULT_QUERY);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selected, setSelected] = useState(form);
  const requestResetText = useRef(null);
  const observer = useRef();
  const lastFormElRef = useCallback(
    node => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          // Last element is in view
          setQuery({ ...query, page: query.page + 1 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    getForms();
  }, [query.page, query.fetch_type, query.text_search]);

  const handleClickHeader = header => () => {
    requestResetText && requestResetText.current();
    setQuery({ ...query, ...DEFAULT_QUERY, fetch_type: header });
  };

  const handleSearch = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    setQuery({ ...query, text_search: trimmed, page: 1 });
  };

  const handleClearSearch = () => {
    setQuery({ ...DEFAULT_QUERY, fetch_type: query.fetch_type });
  };

  const getForms = () => {
    if (query.page === 1) {
      setForms([]);
    }
    setLoading(true);
    axiosInstance
      .get('api/forms', { params: omitEmptyRequestParams(omit(query, 'total', 'your_form', 'all_form')) })
      .then(res => {
        const { data } = res.data;
        const newData = query.page === 1 ? data.data : [...forms, ...data.data];
        if (newData.length >= data.total) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setQuery({ ...query, ...omitEmptyRequestParams(omit(data, 'data', 'archived_form')) });
        setForms(newData);
        setLoading(false);
      })
      .catch(err => {
        toast.error('Could not connect to server, please try again later.');
        setLoading(false);
      });
  };

  const handleChooseForm = () => {
    onSelectForm && onSelectForm(selected);
    onClose && onClose();
  };

  const onKeyPress = event => {
    event.persist();
  };

  const handleCreateForm = () => {
    onClose && onClose();
    toggleModal && toggleModal(false);
    push('/home/forms?create_new=true');
  };

  const renderFormItem = (item, index) => {
    return (
      <S.FormItemWrapper
        ref={forms.length === index + 1 ? lastFormElRef : undefined}
        key={item._id}
        selected={selected._id === item._id}
        onClick={() => setSelected(item)}
      >
        <FormItem />
        <S.Desc>
          <S.DescTitle>{item.name}</S.DescTitle>
          <S.Flex>
            <S.DescCounter>
              Questions: <S.TextBold>{item.number_of_questions}</S.TextBold>
            </S.DescCounter>
            {item.has_signature && (
              <>
                <S.DescCounter>
                  <S.Bull>&bull;</S.Bull>
                </S.DescCounter>
                <S.Signature>
                  <PenOutlined /> SIGNATURE
                </S.Signature>
              </>
            )}
            {!!item.author && (
              <S.DescCounter>
                <S.Bull>&bull;</S.Bull>Owner:{' '}
                <S.TextBold>
                  {item.author.first_name} {item.author.last_name}
                </S.TextBold>
              </S.DescCounter>
            )}
          </S.Flex>
          <Radio className="form-item" checked={selected._id === item._id} />
        </S.Desc>
      </S.FormItemWrapper>
    );
  };

  return (
    <S.ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      className="second-modal select-form"
    >
      <Modal.Content>
        <S.ModalHeader>
          <S.ModalTitle>Choose your Form</S.ModalTitle>
          <S.SearchWrapper>
            <NewSearchInput
              onChange={debounce(handleSearch, 300)}
              onClearSearch={debounce(handleClearSearch, 300)}
              placeholder="Search by name"
              onKeyPress={onKeyPress}
              className="search-products"
              requestResetText={requestResetText}
            />
          </S.SearchWrapper>
        </S.ModalHeader>
        <S.TabsContainer>
          {TABS.map(item => (
            <S.HeaderTab key={item.key} isActive={query.fetch_type === item.key} onClick={handleClickHeader(item.key)}>
              {`${startCase(item.value)} (${
                query.text_search && query.fetch_type === item.key ? query.total : query[item.key]
              })`}
            </S.HeaderTab>
          ))}
        </S.TabsContainer>
        <S.ListWrapper>
          {!query.total && !loading ? (
            query.text_search ? (
              <S.Empty>
                <EmptyUI />
                <S.NotFound>No results found</S.NotFound>
              </S.Empty>
            ) : (
              <S.FirstCreateNew>
                <FirstCreateNewIconSrc />
                <S.EmptyDesc>
                  Create your first form to collect customer feedback for your training business.
                </S.EmptyDesc>
                <S.AddNewWrapper>
                  <Button onClick={handleCreateForm}>
                    <span>Create your first form</span>
                  </Button>
                </S.AddNewWrapper>
              </S.FirstCreateNew>
            )
          ) : (
            <S.List>{forms.length ? forms.map(renderFormItem) : null}</S.List>
          )}
          {loading && <LoadingIndicator />}
        </S.ListWrapper>
      </Modal.Content>
      <Modal.Actions>
        {forms.length ? (
          <GeneralButton onClick={handleCreateForm} className="create-button" withImage>
            <S.AddNewIcon>
              <PlusIcon />
            </S.AddNewIcon>
            <span>Create new form</span>
          </GeneralButton>
        ) : (
          <div />
        )}
        <div>
          <Button onClick={onClose}>Cancel</Button>
          <Button purple disabled={isEmpty(selected)} onClick={handleChooseForm}>
            Add
          </Button>
        </div>
      </Modal.Actions>
    </S.ModalWrapper>
  );
};

export default ChooseFormPopup;
