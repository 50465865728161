// import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import IntegrationSetting from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { getZapierKey, resetZapierKey } from 'redux/pricing/actions';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const addOnsPricing = rootReducer.pricing.get('addOnsPricing').toJS() || [];
  const zapierPricing = addOnsPricing.find(item => item.code === 'zapier');
  const zapierBasePricings = get(zapierPricing, 'basePricings', []);
  const isEnableZapier = rootReducer.pricing
    .getIn(['addOnsPricing'])
    .toJS()
    .find(it => it.code === 'zapier');
  return {
    currentZapierQuantity: rootReducer.pricing.getIn(['teamData', 'zapier_quantity']),
    zapierKey: rootReducer.pricing.getIn(['zapierKey', 'api_secret_key']),
    period: rootReducer.pricing.getIn(['selectPackage', 'period']),
    zapierBasePricings,
    teamData: rootReducer.pricing.get('teamData').toJS(),
    isEnableZapier,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getZapierKey: bindActionCreators(getZapierKey, dispatch),
  resetZapierKey: bindActionCreators(resetZapierKey, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSetting);
