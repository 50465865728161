import React, { useCallback, useRef, useState, useEffect, createRef } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import ContentLoader from 'react-content-loader';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';
import size from 'lodash/size';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';

// import { ReactComponent as BackArrowIcon } from 'assets/icons/arrow_left_grey.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_template.svg';
// import { ReactComponent as DownloadedIcon } from 'assets/icons/checked_white.svg';
import { ReactComponent as ModalityIcon } from 'assets/icons/program_modality.svg';
import { ReactComponent as DurationIcon } from 'assets/icons/program_duration.svg';
import { ReactComponent as LevelIcon } from 'assets/icons/program_level.svg';
import { ReactComponent as TagIcon } from 'assets/icons/program_detail_tags.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-gray.svg';
import { ReactComponent as ArrowWeekRightIcon } from 'assets/icons/arrow-right-grey.svg';
import { CDN_URL, HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';

import { Button } from 'shared/FormControl';
import { pluralize } from 'utils/commonFunction';

import * as S from '../style';
import { WORKOUT_ICONS, convertToTitleCase } from '../constants';
import { useMyContext } from '../context/ProgramTemplatesContext';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { getSetDescription } from 'helpers/exercise';

const parseTime = seconds => {
  return { minute: parseInt(seconds / 60), second: parseInt(seconds % 60) };
};

const calculateSectionTime = (section, toggleInstructionModal) => {
  const { type, round, time, format } = section;
  let text = '';
  let parsedTime = 0;
  let icon = null;
  let totalDuration = Number(time || 0);

  if (type === HIDDEN_SECTION) {
    return { text, icon };
  }

  switch (format) {
    case SECTION_FORMAT_KEY.TIMED:
      icon = WORKOUT_ICONS.timed;
      text = `${round} round${round !== 1 ? 's' : ''}`;
      break;

    case SECTION_FORMAT_KEY.AMRAP:
      icon = WORKOUT_ICONS.interval;
      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;

    case SECTION_FORMAT_KEY.INTERVAL:
      icon = WORKOUT_ICONS.interval;
      totalDuration = sumBy(section.exercises, set => {
        return sumBy(set.supersets, ex => {
          return sumBy(ex.training_sets, exSet => {
            const duration = Number(get(exSet, 'duration.value', 0));
            const rest = Number(get(exSet, 'rest.value', 0));
            return duration + rest;
          });
        });
      });

      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;

    case SECTION_FORMAT_KEY.FREESTYLE:
      icon = React.cloneElement(WORKOUT_ICONS.freestyle, {
        onClick: toggleInstructionModal,
      });
      break;
  }

  return { text, icon };
};

const DetailModalContent = () => {
  const {
    toggleDetailModal,
    toggleInstructionModal,
    toggleTagsModal,
    toggleDescriptionModal,
    detailModalItem,
    loading: isLoading = false,
    saveAsProgramLibrary,
    getProgramWeekDetail,
    programWeekDetail,
    cloudfrontList,
    unit,
    fields,
  } = useMyContext();
  const {
    _id,
    title = '',
    description = '',
    modality = '',
    number_of_weeks = 0,
    level = '',
    tags = [],
    workout_sets = [],
    background = '',
    // is_saved = false,
  } = detailModalItem;

  const { downloading = false, loadingWeek = false, days_workout = [] } = programWeekDetail;

  const daysWorkout = orderBy(days_workout, ['day_index'], ['asc']);

  const horizontalScrollRef = useRef();
  const horizontalWeekScrollRef = useRef();
  const descriptionRef = useRef();
  const resizedTagsRef = {};
  const listRef = createRef();
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [hiddenPaddle, setHiddenPaddle] = useState({ left: true, right: true });
  const [hiddenWeekArrow, setHiddenWeekArrow] = useState({ left: true, right: true });
  const [weekDetail, setWeekDetail] = useState({ id: _id, week_id: workout_sets[0] });
  const [downloaded, setDownloadCompleted] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [tagArray, setTagArray] = useState(tags);
  const [showMoreTagBtn, setShowMoreTagBtn] = useState(null);
  const maxLines = 3;

  useEffect(() => {
    const detailsProgram = descriptionRef.current;
    const lineHeight = parseInt(window.getComputedStyle(detailsProgram).lineHeight, 10);
    const maxHeight = detailsProgram.clientHeight;
    const toggleShowMore = () => {
      const contentHeight = lineHeight * maxLines;
      setShowMore(contentHeight >= maxHeight);
    };

    toggleShowMore();
    window.addEventListener('resize', toggleShowMore);

    return () => {
      window.removeEventListener('resize', toggleShowMore);
    };
  }, [maxLines]);

  useEffect(() => {
    getProgramWeekDetail && getProgramWeekDetail(weekDetail);
  }, [weekDetail]);

  const handleWeekClick = weekNumber => {
    setSelectedWeek(weekNumber);
  };

  const handleDownloadTemplate = () => {
    setDownloadCompleted(true);
    saveAsProgramLibrary(_id);
    setTimeout(() => {
      setDownloadCompleted(false);
    }, 5000);
  };

  const renderProgramItem = () => {
    const renderModality =
      modality === 'strength_hypertrophy'
        ? 'strength / hypertrophy'
        : modality === 'hiit'
        ? 'HIIT'
        : convertToTitleCase(modality);

    const renderLevels = () => {
      if (level === 'all_level') return 'All Levels';
      return level;
    };
    return (
      <S.ProgramItemWrapper key={`program-item-${_id}`}>
        <S.ProgramItem>
          <ModalityIcon />
          <S.ProgramItemTitle>Modality</S.ProgramItemTitle>
          <S.DetailProgramItem>{renderModality}</S.DetailProgramItem>
        </S.ProgramItem>
        <S.ProgramItem>
          <LevelIcon /> <S.ProgramItemTitle>Experience Level</S.ProgramItemTitle>
          <S.DetailProgramItem>{renderLevels()}</S.DetailProgramItem>
        </S.ProgramItem>
        <S.ProgramItem>
          <DurationIcon /> <S.ProgramItemTitle>Program Duration</S.ProgramItemTitle>
          <S.DetailProgramItem>
            {number_of_weeks} {pluralize('week', number_of_weeks)}
          </S.DetailProgramItem>
        </S.ProgramItem>
      </S.ProgramItemWrapper>
    );
  };

  useEffect(() => {
    if (!listRef.current) return;
    const TagsWrapperRef = listRef.current.getBoundingClientRect();
    let indexNeedToFind = null;
    Object.values(resizedTagsRef).forEach((item, index) => {
      const tags = { index, bound: item.getBoundingClientRect() };
      const tagsBound = tags.bound;
      if (indexNeedToFind !== null) {
        return;
      }

      if (tagsBound.right >= TagsWrapperRef.right) {
        indexNeedToFind = index;
      } else if (tagsBound.top >= TagsWrapperRef.bottom) {
        indexNeedToFind = index - 1;
      }
    });
    if (indexNeedToFind) {
      const remain = Object.keys(resizedTagsRef).splice(indexNeedToFind).length;
      setTagArray(tagArray.splice(0, indexNeedToFind));
      setShowMoreTagBtn(`+ ${remain} more`);
    }
  }, []);

  const renderTags = () => {
    return (
      <S.TagsWrapper>
        <S.ProgramItem>
          <TagIcon />
          <S.ProgramItemTitle>Tags</S.ProgramItemTitle>
          <div style={{ opacity: 0 }} id="toggleTagsModal" onClick={toggleTagsModal}></div>
        </S.ProgramItem>
        <S.TagsItemWrapper ref={listRef}>
          {tagArray.map((tag, index) => (
            <S.DetailProgramItem
              ref={r => {
                resizedTagsRef['index' + index] = r;
              }}
              key={index}
            >
              {tag}
            </S.DetailProgramItem>
          ))}
          {showMoreTagBtn && (
            <S.DetailProgramItem moreItem onClick={toggleTagsModal}>
              {showMoreTagBtn}
            </S.DetailProgramItem>
          )}
        </S.TagsItemWrapper>
      </S.TagsWrapper>
    );
  };

  const onHorizontalScroll = event => {
    event.persist();
    const target = horizontalScrollRef.current;
    target && onHorizontalChange(target);
    event.stopPropagation();
  };

  const onHorizontalWeelScroll = event => {
    event.persist();
    const target = horizontalWeekScrollRef.current;
    target && onHorizontalWeekChange(target);
    event.stopPropagation();
  };

  const onHorizontalChange = target => {
    const { scrollLeft, scrollWidth, offsetWidth } = target;
    const invisibleSize = scrollWidth - offsetWidth;
    const paddleMargin = 34;

    const endOffset = invisibleSize - paddleMargin;
    if (scrollWidth === offsetWidth || scrollWidth < offsetWidth + paddleMargin) {
      setHiddenPaddle({ left: true, right: true });
    } else {
      setHiddenPaddle({
        left: scrollLeft <= paddleMargin,
        right: scrollLeft >= endOffset,
      });
    }
  };

  const onHorizontalWeekChange = target => {
    const { scrollLeft, scrollWidth, offsetWidth } = target;
    const invisibleSize = scrollWidth - offsetWidth;
    const paddleMargin = 10;

    const endOffset = invisibleSize - paddleMargin;
    if (scrollWidth === offsetWidth || scrollWidth < offsetWidth + paddleMargin) {
      setHiddenWeekArrow({ left: true, right: true });
    } else {
      setHiddenWeekArrow({
        left: scrollLeft <= paddleMargin,
        right: scrollLeft >= endOffset,
      });
    }
  };

  const handleScrollToSideMargin = ({ left = false }) => () => {
    const target = horizontalScrollRef.current;
    if (target) {
      target.scrollTo({
        left: left ? target.scrollLeft - 632 : target.scrollLeft + 632,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollWeekToSideMargin = ({ left = false }) => () => {
    const target = horizontalWeekScrollRef.current;
    if (target) {
      target.scrollTo({
        left: left ? target.scrollLeft - 500 : target.scrollLeft + 500,
        behavior: 'smooth',
      });
    }
  };

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const target = (entries[0] || {}).target;
      target && onHorizontalChange(target);
    }),
  );

  const resizeWeekObserver = useRef(
    new ResizeObserver(entries => {
      const target = (entries[0] || {}).target;
      target && onHorizontalWeekChange(target);
    }),
  );

  const resizedContainerRef = useCallback(container => {
    if (container !== null) {
      resizeObserver.current.observe(container);
      horizontalScrollRef.current = container;
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
      horizontalScrollRef.current = null;
    }
  }, []);

  const resizedWeekContainerRef = useCallback(container => {
    if (container !== null) {
      resizeWeekObserver.current.observe(container);
      horizontalWeekScrollRef.current = container;
    } else {
      if (resizeWeekObserver.current) resizeWeekObserver.current.disconnect();
      horizontalWeekScrollRef.current = null;
    }
  }, []);

  const renderWorkoutIcon = (format, toggleInstructionModal) => {
    // Temporary disabled on this version
    return;
    const icon = WORKOUT_ICONS[format];
    if (format === 'freestyle' && toggleInstructionModal) {
      return React.cloneElement(icon, {
        onClick: toggleInstructionModal,
      });
    }

    return icon || null;
  };

  const renderSupersetDetail = exercises => {
    return (
      <>
        {exercises.supersets.map(item => {
          const exercise = item.exercise || item.exercise_instance;

          if (!exercise) {
            return null;
          }

          const { title, _id } = exercise;
          const { training_sets } = item;
          const summary = getSetDescription(item, unit, fields);

          return (
            <S.ExerciseContentContainer key={_id}>
              <S.ExerciseContent isSuperset>
                <S.ExerciseTitle isSuperset>{title}</S.ExerciseTitle>
                {summary && <S.ExerciseRep>{summary}</S.ExerciseRep>}
              </S.ExerciseContent>
              <S.ExerciseAmount>x{training_sets.length}</S.ExerciseAmount>
            </S.ExerciseContentContainer>
          );
        })}
      </>
    );
  };

  const renderSupersetWorkout = (exercise, index) => {
    const numberOfExercises = size(exercise);

    if (!exercise) {
      return null;
    }

    return (
      <S.SupersetWrapper>
        <div className={classNames('custom-superset', { isOneValue: numberOfExercises === 1 })} key={index}>
          <S.SupersetLabel isSuperset>Superset</S.SupersetLabel>
          {renderSupersetDetail(exercise)}
        </div>
      </S.SupersetWrapper>
    );
  };

  const renderNormalWorkout = (exercises, index) => {
    return (
      <div className="custom-sections" key={index}>
        {exercises.supersets.map(item => {
          const exercise = item.exercise || item.exercise_instance;

          if (!exercise) {
            return null;
          }

          const { title, _id } = exercise;
          const { training_sets } = item;
          const summary = getSetDescription(item, unit, fields);

          return (
            <div className="exercise-wrapper">
              <S.ExerciseContent key={_id}>
                <S.ExerciseTitle>{title}</S.ExerciseTitle>
                {summary && <S.ExerciseRep>{summary}</S.ExerciseRep>}
              </S.ExerciseContent>
              <S.ExerciseAmount>x{training_sets.length}</S.ExerciseAmount>
            </div>
          );
        })}
      </div>
    );
  };

  const renderWorkout = dayWorkout => {
    return dayWorkout.map((workout, workoutIndex) => (
      <S.WorkoutWrapper key={workoutIndex}>
        <S.WorkoutName>{workout.title}</S.WorkoutName>
        {get(workout, 'sections', []).map((section, sectionIndex) => {
          const time = calculateSectionTime(section, toggleInstructionModal);
          return (
            <>
              <S.SectionWrapper key={sectionIndex}>
                <S.SectionName>{section.title}</S.SectionName>
                {(time.text || time.icon) && (
                  <S.SectionNameWrapper>
                    <S.SectionFormat>{time.icon}</S.SectionFormat>
                    <S.SectionType>{time.text}</S.SectionType>
                  </S.SectionNameWrapper>
                )}
                {section.format === SECTION_FORMAT_KEY.FREESTYLE && (
                  <ReactTooltip
                    className="app-tooltip workout-type"
                    id={`workout-${section.type}`}
                    effect="solid"
                    place={'top'}
                  >
                    <div className="workout-type--tooltip">
                      <span>Instruction</span>
                    </div>
                  </ReactTooltip>
                )}
              </S.SectionWrapper>
              <S.ExerciseWrapper
                className="exercises-main-wrapper"
                isSuperset={get(section, 'exercises', []).some(exercise => exercise.supersets.length > 1)}
              >
                {get(section, 'exercises', []).map((exercise, exerciseIndex) => (
                  <S.ExerciseContentWrapper hasOneValue={section.exercises.length === 1} key={exerciseIndex}>
                    {exercise.supersets.length > 1 ? renderSupersetWorkout(exercise) : renderNormalWorkout(exercise)}
                  </S.ExerciseContentWrapper>
                ))}
              </S.ExerciseWrapper>
            </>
          );
        })}
      </S.WorkoutWrapper>
    ));
  };

  const renderProgramWeek = (setId, index) => {
    const weekNumber = index + 1;

    const handleSelectWeek = () => {
      if (selectedWeek !== weekNumber) {
        handleWeekClick(weekNumber);
        setWeekDetail({ ...weekDetail, week_id: setId });
      }
    };

    return (
      <S.ProgramWeek key={setId} isSelected={selectedWeek === weekNumber} onClick={handleSelectWeek}>
        Week {weekNumber}
      </S.ProgramWeek>
    );
  };

  const renderLoadingAbout = (isAbout, idx) => {
    const wrapperClassName = `about-skeleton-wrapper ${isAbout ? 'is-about' : 'is-equipment'}`;
    const viewBox = isAbout ? '0 0 401 200' : '0 0 339 200';
    const width = isAbout ? 401 : 339;

    return (
      <div className={wrapperClassName}>
        <S.LoadingItem key={`loader-item${idx}`}>
          <ContentLoader viewBox={viewBox} width={width} height={200} speed={0}>
            <rect x="0" y="0" rx="16" ry="16" width={width} height="27" />
            <rect x="0" y="43" rx="16" ry="16" width={isAbout ? '196' : '221'} height="27" />
          </ContentLoader>
        </S.LoadingItem>
      </div>
    );
  };

  const renderLoading = (className, key, loaderProps) => (
    <div className={className} key={key}>
      <S.LoadingItem>
        <ContentLoader {...loaderProps}>
          <rect x="0" y="0" rx="8" ry="8" width={loaderProps.width} height={loaderProps.height} />
        </ContentLoader>
      </S.LoadingItem>
    </div>
  );
  // In case need load more weeks
  // const renderLoadingWeek = idx =>
  //   renderLoading('weeks-skeleton-wrapper', `loader-item${idx}`, {
  //     viewBox: '0 0 320 43',
  //     width: 320,
  //     height: 43,
  //     speed: 0,
  //   });

  const renderLoadingWeekProgram = idx =>
    renderLoading('weekday-skeleton-wrapper', `loader-item${idx}`, {
      viewBox: '0 0 100 32',
      width: 100,
      height: 32,
      speed: 0,
    });

  const renderLoadingProgram = idx =>
    renderLoading('program-skeleton-wrapper', `loader-item${idx}`, {
      viewBox: '0 0 300 1000',
      width: 300,
      height: 1000,
      speed: 0,
    });

  const renderWeekdayProgram = (item, index) => {
    if (!item) return null;
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const workouts = get(item, 'day_workout.workouts', []);
    return (
      <S.WeekdayWrapper key={index}>
        <S.WeekdayHeader>
          <>
            <S.WeekdayLabel>{weekdays[item.day_index]}</S.WeekdayLabel>
            {item.day_workout.workouts.length > 1 && (
              <S.TotalWorkout>
                {item.day_workout.workouts.length}
                &nbsp;workouts
              </S.TotalWorkout>
            )}
          </>
        </S.WeekdayHeader>
        {renderWorkout(workouts)}
      </S.WeekdayWrapper>
    );
  };

  const renderLoadingMainProgram = () => {
    const loadingWeekPrograms = Array(8)
      .fill()
      .map((_, idx) => renderLoadingWeekProgram(idx));
    const loadingPrograms = Array(8)
      .fill()
      .map((_, idx) => renderLoadingProgram(idx));

    return (
      <S.WeekdayWrapper>
        <S.SkeletonWeekday>{loadingWeekPrograms}</S.SkeletonWeekday>
        <S.SkeletonProgram>{loadingPrograms}</S.SkeletonProgram>
      </S.WeekdayWrapper>
    );
  };

  const getCloudUrl = url => {
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);
  };

  const convertedThumbnail = getCloudUrl(background) || `${CDN_URL}/images/program_default_detail_header_bg.png`;

  return (
    <S.FullscreenModalContent className="detail">
      <S.DetailModalContainer>
        <S.DetailModalWrapper>
          <Button onClick={toggleDetailModal} className="close-button" type="button">
            <CloseIcon />
          </Button>
          <S.DetailModalHeader className="detail-header" coverImg={convertedThumbnail}>
            {isLoading ? (
              <div className="loading-skeleton-header" />
            ) : (
              <>
                {/* <S.BackButtonWrapper className="back-icon-wrapper" onClick={toggleDetailModal}>
                  <BackArrowIcon />
                  <S.BackButton>Back</S.BackButton>
                </S.BackButtonWrapper> */}
                <S.TitleWrapper className="detail-title-wrapper">
                  <div className="detail-title">{title}</div>
                  <Button
                    className={classNames('download-button', {
                      downloaded: downloaded,
                      downloading: downloading,
                    })}
                    onClick={downloaded ? null : handleDownloadTemplate}
                  >
                    {downloading ? (
                      <Loader active inline="centered" size="tiny" inverted className="download-indicator" />
                    ) : (
                      // :
                      //  downloaded ? (
                      //   <>
                      //     <DownloadedIcon className="downloaded-template" />
                      //     Downloaded Template
                      //   </>
                      // )
                      <>
                        <DownloadIcon />
                        Download Template
                      </>
                    )}
                  </Button>
                </S.TitleWrapper>
              </>
            )}

            <S.DescriptionWrapper className="detail-description-wrapper">
              <S.ContentWrapper>
                {isLoading ? (
                  renderLoadingAbout(true)
                ) : (
                  <>
                    <S.ContentTitle>About Program</S.ContentTitle>
                    <S.DetailsProgram ref={descriptionRef}>{description}</S.DetailsProgram>
                    {showMore && (
                      <S.ShowMoreBtn onClick={toggleDescriptionModal}>
                        Show more <ArrowWeekRightIcon />
                      </S.ShowMoreBtn>
                    )}
                    {renderProgramItem()}
                  </>
                )}
              </S.ContentWrapper>
              <S.ContentWrapper>{isLoading ? renderLoadingAbout(false) : renderTags()}</S.ContentWrapper>
            </S.DescriptionWrapper>
          </S.DetailModalHeader>
          <S.DetailModalBody className="detail-modal-body">
            <S.ProgramOverview>
              <S.ProgramOverviewTitle>Program Overview</S.ProgramOverviewTitle>
              <S.ProgramWeekWrapper>
                <div className="week-slider">
                  <div
                    className={classNames('week-arrow-button-left', {
                      hidden: hiddenWeekArrow.left || workout_sets.length <= 9,
                    })}
                  >
                    <button onClick={handleScrollWeekToSideMargin({ left: true })}>
                      <ArrowWeekRightIcon />
                    </button>
                  </div>
                  <div
                    className={classNames('week-arrow-button-right', {
                      hidden: hiddenWeekArrow.right,
                    })}
                  >
                    <button onClick={handleScrollWeekToSideMargin({ left: false })}>
                      <ArrowWeekRightIcon />
                    </button>
                  </div>
                  <div className="week-scrollable" ref={resizedWeekContainerRef} onScroll={onHorizontalWeelScroll}>
                    <div className="week-wrapper">{workout_sets.map(renderProgramWeek)}</div>
                  </div>
                </div>
              </S.ProgramWeekWrapper>
            </S.ProgramOverview>

            <S.WeekOverviewContainer>
              <div className="workout-slider">
                {!loadingWeek && (
                  <>
                    <div
                      className={classNames('arrow-button-left', {
                        hidden: hiddenPaddle.left,
                      })}
                    >
                      <button onClick={handleScrollToSideMargin({ left: true })}>
                        <ArrowRightIcon />
                      </button>
                    </div>
                    <div
                      className={classNames('arrow-button-right', {
                        hidden: hiddenPaddle.right,
                      })}
                    >
                      <button onClick={handleScrollToSideMargin({ left: false })}>
                        <ArrowRightIcon />
                      </button>
                    </div>
                  </>
                )}
                <div className="workout-scrollable" ref={resizedContainerRef} onScroll={onHorizontalScroll}>
                  {loadingWeek ? renderLoadingMainProgram() : daysWorkout.map(renderWeekdayProgram)}
                </div>
              </div>
            </S.WeekOverviewContainer>
          </S.DetailModalBody>
        </S.DetailModalWrapper>
      </S.DetailModalContainer>
    </S.FullscreenModalContent>
  );
};

export default DetailModalContent;
