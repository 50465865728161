import React from 'react';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Wrapper, StudioProgramStatusWrapper, LastEditBy } from './style';
import UpgradePath from 'shared/UpgradePath';
import { Helmet } from 'react-helmet';

const NAVS = [
  { label: 'Overview', route: '' },
  { label: 'Calendar', route: 'calendar' },
  { label: 'Settings', route: 'settings' },
];

const StudioProgramStatus = ({ isPublished }) => {
  return (
    <StudioProgramStatusWrapper isPublished={isPublished}>
      {isPublished ? 'Published' : 'Draft'}
    </StudioProgramStatusWrapper>
  );
};

const StudioProgramDetailHeader = props => {
  const { workingStudio, location } = props;

  if (!workingStudio) {
    return null;
  }

  const { last_edit_by } = workingStudio;
  const studioProgramName = get(workingStudio, 'program_name', '');

  return (
    <Wrapper>
      <Helmet>
        <title>{studioProgramName} - Studio Program - Everfit</title>
      </Helmet>
      <div className="studio-program-detail__name">
        <span>
          <UpgradePath pathName="studio_program" fallback="On-demand">
            {studioProgramName}
          </UpgradePath>
        </span>
        <StudioProgramStatus isPublished={workingStudio.isPublished} />
        {isObject(last_edit_by) ? (
          <>
            <LastEditBy data-tip data-for="tooltip--latest-edit-by">
              Latest edit by: {last_edit_by.first_name} {last_edit_by.last_name}
            </LastEditBy>
            <ReactTooltip
              className="app-tooltip tooltip--latest-edit-by"
              id="tooltip--latest-edit-by"
              effect="solid"
              place="bottom"
            >
              <div>Changes are automatically saved, but have not been published to clients yet</div>
            </ReactTooltip>
          </>
        ) : null}
      </div>
      <div className="studio-program-detail__nav-menu">
        {map(NAVS, (nav, index) => {
          if (!process.env.REACT_APP_STUDIO_PROGRAM_SETTINGS && isEqual(get(nav, 'route'), 'settings')) {
            return <></>;
          }
          return (
            <NavLink
              key={index}
              className="studio-program-detail__nav-menu__item"
              exact={nav.route === ''}
              to={`/home/studio-programs/${workingStudio._id}${nav.route ? '/' + nav.route : ''}`}
              isActive={() => {
                return (
                  location.pathname ===
                    `/home/studio-programs/${workingStudio._id}${nav.route ? '/' + nav.route : ''}` ||
                  location.pathname === `/home/studio-program/${workingStudio._id}${nav.route ? '/' + nav.route : ''}`
                );
              }}
            >
              {nav.label}
            </NavLink>
          );
        })}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, router } = state;
  const { workingStudio } = rootReducer.studioProgram;

  return {
    workingStudio,
    location: router.location,
  };
};

export default connect(mapStateToProps, null)(StudioProgramDetailHeader);
