// Libs
import React, { useEffect, useState } from 'react';

// Components
import ItemInviteLink from '../ItemInviteLink';

// Assets
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right_darkest.svg';

// Styles
import * as S from './style';

const MAXIMUM_NUMBER_TO_DISPLAY = 5;

const ListInviteLink = ({ list, cloudfrontList, onRemove, onCopy, user }) => {
  const [visibleCount, setVisibleCount] = useState(MAXIMUM_NUMBER_TO_DISPLAY);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      handleViewMore();
    }
  }, [list]);

  const newList = (list || []).slice(0, visibleCount);
  const lengthOfList = list.length;
  const lengthOfNewList = newList.length;
  const remainingCoach = lengthOfList - visibleCount;

  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + remainingCoach);
    setIsExpanded(true);
  };

  const handleShowLess = () => {
    setVisibleCount(MAXIMUM_NUMBER_TO_DISPLAY);
    setIsExpanded(false);
  };

  return (
    <S.Wrapper>
      {(newList || []).map(item => (
        <ItemInviteLink
          key={(item || {})._id}
          item={item}
          cloudfrontList={cloudfrontList}
          onRemove={onRemove}
          onCopy={onCopy}
          user={user}
        />
      ))}
      {visibleCount < lengthOfList && (
        <S.ToggleButton onClick={handleViewMore}>
          <ArrowRightIcon className="custom-icon" />
          View more {remainingCoach === 1 ? 'coach' : 'coaches'} ({remainingCoach})
        </S.ToggleButton>
      )}
      {lengthOfNewList === lengthOfList && lengthOfNewList > MAXIMUM_NUMBER_TO_DISPLAY && (
        <S.ToggleButton onClick={handleShowLess}>
          <ArrowRightIcon className="custom-icon is-show-less" />
          Show less
        </S.ToggleButton>
      )}
    </S.Wrapper>
  );
};

export default ListInviteLink;
