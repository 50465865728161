import React from 'react';
import _ from 'lodash';
import className from 'classnames';
import { ClientProgressWrapper } from './style';
import ProcessBar from './ProcessBar';
import Select from './Select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { State, Dispatch } from 'types/redux';
import { updateSelectPackage } from 'redux/pricing/actions';

function transformOptions(pricings = []) {
  return pricings.map(p => {
    return {
      label: `${p.packageNoClients} clients`,
      key: p.packageNoClients,
      ...p,
    };
  });
}

function ClientProgress(props) {
  const onSelect = value => {
    if (props.isLoading) {
      return;
    }
    const packagePrice = props.selectedPackage.pricings.find(o => o.packageNoClients === value.key);
    props.updateSelectPackage({ packagePrice });
  };

  const noClientsWarning = props.teamData.noClients - props.packagePrice.packageNoClients;

  const options = transformOptions(props.selectedPackage.pricings);

  const optionsSelect = transformOptions(props.selectedPackage.pricings).sort((a, b) => a.key - b.key);

  if (!options || options.length < 2) {
    return null;
  }

  return (
    <ClientProgressWrapper {...props}>
      <div className="clientProgress__selectPlan">
        <span className="clientProgress__selectPlan--text">Choose plan for</span>
        <Select
          options={optionsSelect}
          value={{
            label: `${props.packagePrice.packageNoClients || 0} clients`,
            key: props.packagePrice.packageNoClients || 0,
          }}
          onSelect={onSelect}
        />
      </div>
      <div className="clientProgress__description">
        <span>
          With the <strong>{_.upperFirst(props.tier)} Plan</strong>, grow your business up to{' '}
          {props.selectedPackage.maximumNoClients} clients
        </span>
      </div>
      <ProcessBar
        options={options}
        noClients={props.packagePrice.packageNoClients || 0}
        minClients={props.selectedPackage.minimumNoClients}
        maxClients={props.selectedPackage.maximumNoClients}
        onChange={onSelect}
      />
      <div
        className={className('clientProgress_warningMsg', {
          'clientProgress_warningMsg--active': noClientsWarning > 0,
        })}
      >
        <span>* You are training </span>
        <strong>{props.teamData.noClients} clients</strong>
        <span>
          {' '}
          right now. Please archive {noClientsWarning < 0 ? 0 : noClientsWarning} clients before updating your plan.{' '}
        </span>
        <a href="http://help.everfit.io/en/articles/4166092-how-to-archive-a-client" target="_blank">
          How to archive clients
        </a>
      </div>
    </ClientProgressWrapper>
  );
}

const mapStateToProps = (state: State) => {
  const { rootReducer } = state;
  const tier = rootReducer.pricing.getIn(['selectPackage', 'tier']);
  const packagePrice = rootReducer.pricing.getIn(['selectPackage', 'packagePrice']);
  const selectedPackage = rootReducer.pricing.getIn(['planPackage', tier]);
  return {
    selectedPackage: selectedPackage ? selectedPackage.toJS() : {},
    packagePrice: packagePrice ? packagePrice.toJS() : {},
    tier,
    teamData: rootReducer.pricing.getIn(['teamData']).toJS(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProgress);
