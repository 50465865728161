import Request from 'configs/request';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { hideError, showError } from 'actions/error';
import { toggleModal } from 'actions/modal';
import { initPermission } from 'redux/permission/actions';

export const Types = {
  BROADCAST_MESSAGES_SUCCESS: 'BROADCAST_MESSAGES_SUCCESS',
  BROADCAST_MESSAGES_FAILED: 'BROADCAST_MESSAGES_FAILED',
  BROADCAST_MESSAGES_GET_CLIENTS_REQUEST: 'BROADCAST_MESSAGES_GET_CLIENTS_REQUEST',
  BROADCAST_MESSAGES_GET_CLIENTS_SUCCESS: 'BROADCAST_MESSAGES_GET_CLIENTS_SUCCESS',
  BROADCAST_MESSAGES_GET_CLIENTS_FAILED: 'BROADCAST_MESSAGES_GET_CLIENTS_FAILED',
  BROADCAST_MESSAGES_GET_ALL_CLIENTS_REQUEST: 'BROADCAST_MESSAGES_GET_ALL_CLIENTS_REQUEST',
  BROADCAST_MESSAGES_GET_ALL_CLIENTS_SUCCESS: 'BROADCAST_MESSAGES_GET_ALL_CLIENTS_SUCCESS',
  BROADCAST_MESSAGES_RESET_STATE: 'BROADCAST_MESSAGES_RESET_STATE',
  OPEN_SECOND_TOGGLE_MODAL: 'OPEN_SECOND_TOGGLE_MODAL',
  HIDE_BROADCAST: 'HIDE_BROADCAST',
  BROADCAST_MESSAGES_WORKOUT_SUMMARY_SUCCESS: 'BROADCAST_MESSAGES_WORKOUT_SUMMARY_SUCCESS',
};

export const sendBroadcastMessage = (data, otherCallback) => {
  const submittedTime = new Date();

  const params = {
    ...data,
    source: 'inbox',
  };

  return Request.post(
    { url: 'api/v2/inbox/messages/broadcast', data: params },
    true,
    (result, { dispatch }) => {
      dispatch({ type: Types.BROADCAST_MESSAGES_SUCCESS, payload: submittedTime });
      return result;
    },
    (error, { dispatch }) => {
      const { errorCode, additional } = get(error, 'response.data');
      if (errorCode === '21_1') {
        dispatch(showError(get(error, 'response.data.message'), null, null, null, null, () => backToInbox(dispatch)));
      }
      dispatch({ type: Types.BROADCAST_MESSAGES_FAILED });
      otherCallback && otherCallback(additional);
    },
    false,
  );
};

export const getClientsByParams = params => {
  const { page } = params;
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const oldClientsState = get(rootReducer, 'broadcastMessages.clients', []);

    dispatch({ type: Types.BROADCAST_MESSAGES_GET_CLIENTS_REQUEST });

    return dispatch(
      Request.get(
        { url: 'api/client-management/search', params: { ...params, groups: JSON.stringify(params.groups) } },
        true,
        (result, { dispatch }) => {
          const { data } = result.data;
          const clients = page > 1 ? oldClientsState.concat(get(data, 'list', [])) : get(data, 'list', []);
          dispatch({
            type: Types.BROADCAST_MESSAGES_GET_CLIENTS_SUCCESS,
            payload: { clients: clients, total: get(data, 'total') },
          });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.BROADCAST_MESSAGES_GET_CLIENTS_FAILED });
        },
      ),
    );
  };
};

export const getAllClientsToSelect = (params, callback) => {
  return dispatch => {
    dispatch({ type: Types.BROADCAST_MESSAGES_GET_ALL_CLIENTS_REQUEST });
    return dispatch(
      Request.get(
        { url: 'api/client-management/search', params },
        true,
        (result, { dispatch }) => {
          const { data } = result.data;
          const { list } = data;
          callback && callback(list);
          dispatch({
            type: Types.BROADCAST_MESSAGES_GET_ALL_CLIENTS_SUCCESS,
          });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.BROADCAST_MESSAGES_GET_CLIENTS_FAILED });
        },
      ),
    );
  };
};

export const resetBroadcastMessageState = () => {
  return dispatch => {
    dispatch({ type: Types.BROADCAST_MESSAGES_RESET_STATE });
  };
};

export const openSecondToggle = isOpen => {
  return dispatch => {
    dispatch({ type: Types.OPEN_SECOND_TOGGLE_MODAL, payload: isOpen });
  };
};

export const hideBroadcast = () => {
  return dispatch => {
    dispatch({ type: Types.HIDE_BROADCAST, payload: false });
  };
};

export const backToInbox = dispatch => {
  dispatch(hideError(false));
  dispatch(toggleModal(false));
  dispatch(initPermission());
  dispatch({ type: Types.HIDE_BROADCAST, payload: true });
};

export const getGroupsFilterByParams = (params, callback) => {
  const excludeIds = isEmpty(params.exclude_ids) ? [] : JSON.stringify(params.exclude_ids);
  return dispatch => {
    return dispatch(
      Request.get(
        { url: '/api/group/list', params: { ...params, exclude_ids: excludeIds } },
        true,
        result => {
          callback && callback(result);
        },
        () => {
          callback && callback(null);
        },
      ),
    );
  };
};

export const sendBroadcastMessageWorkoutSummary = (data, otherCallback) => {
  const params = {
    ...data,
    source: 'workout_completion',
  };
  const submittedTime = new Date();
  return Request.post(
    { url: 'api/v2/inbox/messages/broadcast', data: params },
    true,
    (result, { dispatch }) => {
      dispatch({ type: Types.BROADCAST_MESSAGES_WORKOUT_SUMMARY_SUCCESS, payload: submittedTime });
      return result;
    },
    (error, { dispatch }) => {
      const { errorCode, additional } = get(error, 'response.data');
      if (errorCode === '21_1') {
        dispatch(showError(get(error, 'response.data.message'), null, null, null, null, () => backToInbox(dispatch)));
      }
      dispatch({ type: Types.BROADCAST_MESSAGES_FAILED });
      otherCallback && otherCallback(additional);
    },
    false,
  );
};
