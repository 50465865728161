// Libs
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Parts
import GoToIcon from '../GoToIcon';
import DropdownFlows from '../DropdownOnboardingFlows';
import TabBar from './TabBar';
import TabContent from './TabContent';
import { SWITCH_VIEW_TRIGGER_TYPES } from 'shared/TriggerForms/constants/types';
import { getTriggerOptions, isPrivateFlow, getTooltipContent } from 'shared/TriggerForms/units/helper';

// Styles
import * as S from './styles';

const AssignedTriggerBanner = props => {
  const { relatedQuestionOnboardingFlows: onboardingFlows, user, options } = props;
  if (!Array.isArray(onboardingFlows) || !Array.isArray(options)) return null;
  if (onboardingFlows.length === 0) return null;

  const [index, setIndex] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const total = onboardingFlows.length;
  const selectOnboardingFlow = onboardingFlows[index] || {};
  const { _id, name, author, share } = selectOnboardingFlow;

  const { programTriggerOptions, forumTriggerOptions } = useMemo(
    () =>
      getTriggerOptions({
        onboardingFlow: selectOnboardingFlow,
        questionOptions: options,
      }),
    [selectOnboardingFlow, options],
  );

  const isShowTabBar =
    Array.isArray(programTriggerOptions) &&
    programTriggerOptions.length > 0 &&
    Array.isArray(forumTriggerOptions) &&
    forumTriggerOptions.length > 0;

  const initTab = useMemo(() => {
    if (Array.isArray(programTriggerOptions) && programTriggerOptions.length > 0) {
      return SWITCH_VIEW_TRIGGER_TYPES.PROGRAM;
    } else if (Array.isArray(forumTriggerOptions) && forumTriggerOptions.length > 0) {
      return SWITCH_VIEW_TRIGGER_TYPES.FORUM;
    }
    return null;
  }, [programTriggerOptions, forumTriggerOptions]);

  const [tab, setTab] = useState(initTab);

  useEffect(() => {
    initTab !== tab && setTab(initTab);
  }, [initTab]);

  const changeTab = tabType => () => setTab(tabType);

  const changeIndex = idx => () => {
    setIndex(idx);
    setOpenPopup(false);
  };

  const onOpenPopup = () => {
    if (total <= 1) {
      setOpenPopup(false);
      return;
    }
    setOpenPopup(true);
  };

  const onClosePopup = () => {
    setOpenPopup(false);
  };

  const renderFieldDropdown = () => {
    let isDisable = false;
    let tooltipContent;

    if (typeof user === 'object' && typeof author === 'object') {
      isDisable = isPrivateFlow(user, author, share);

      if (isDisable) {
        tooltipContent = getTooltipContent(author);
      }
    }

    return (
      <div className={classNames('onboarding-flow-name-wrapper', { active: openPopup })}>
        <div className="onboarding-flow-name">{name}</div>
        <div
          className={classNames('action-icons', {
            'hide-go-to-icon': total > 1,
            'hide-dropdown-icon': total <= 1,
          })}
        >
          <GoToIcon
            tooltipId={`go-to-icon-tooltip-box-${_id}`}
            href={`/home/onboarding-flow/${_id}`}
            disable={isDisable}
            tooltipContent={tooltipContent}
          />
          <img src="/images/arrow_down_black.svg" alt="" className="arrow-down-icon" />
        </div>
      </div>
    );
  };

  return (
    <S.AssignedTriggerWrapper>
      <div className="title-banner">Automation trigger</div>
      <div className="description-banner">This question is being used for an automation trigger</div>
      <div className="used-in-label">Used In ({total})</div>
      <div className="onboarding-flow-detail-wrapper">
        <DropdownFlows
          trigger={renderFieldDropdown()}
          open={openPopup}
          onboardingFlows={onboardingFlows}
          onOpenPopup={onOpenPopup}
          onClosePopup={onClosePopup}
          onSelectItem={changeIndex}
          user={user}
          selectItem={index}
        />
        {isShowTabBar && <TabBar currentTab={tab} changeTab={changeTab} />}
        <div className="options-wrapper">
          <TabContent options={programTriggerOptions} type={SWITCH_VIEW_TRIGGER_TYPES.PROGRAM} currentTab={tab} />
          <TabContent options={forumTriggerOptions} type={SWITCH_VIEW_TRIGGER_TYPES.FORUM} currentTab={tab} />
        </div>
      </div>
    </S.AssignedTriggerWrapper>
  );
};

export default AssignedTriggerBanner;
