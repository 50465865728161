import React from 'react';

import { CDN_URL } from 'constants/commonData';

import { ReactComponent as ArrowUp } from 'assets/icons/program_arrow_up.svg';

import * as S from '../styles';
import { useMyContext } from '../context/ProgramLibraryContext';

const Banner = () => {
  const { onHideBanner, onOpenExploreTemplate } = useMyContext();

  return (
    <S.BannerWrapper>
      <img src={`${CDN_URL}/images/program-banner.png`} alt="" className="bg-program-banner-left" />
      <div className="explore-container">
        {/* <div className="explore-head-title">Program</div> */}
        <div className="explore-title">Program Templates</div>
        <div className="explore-description">
          Save time by exploring pre-built program templates curated by Everfit.
          <a
            href="https://help.everfit.io/en/articles/8877415-premade-program-templates"
            target="_blank"
            rel="noopener noreferrer"
            className="explore-learn-more"
          >
            <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" />
            <span>Learn more</span>
          </a>
        </div>
      </div>
      <div className="hide-banner-wrapper">
        <button onClick={onHideBanner} className="hide-banner-button">
          <span>Hide Banner</span>
          <ArrowUp className="icon-arrow-up" />
        </button>
      </div>
      <div className="explore-template-wrapper">
        <button onClick={onOpenExploreTemplate} className="explore-template-button">
          <span>Explore Templates</span>
        </button>
      </div>
    </S.BannerWrapper>
  );
};

export default Banner;
