import { useContext, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { WebSocketContext } from 'libs/socket';

const useSocketSubscribeChannel = ({ disable = true, event, channel, room, roomId, callback }) => {
  const { socket } = useContext(WebSocketContext);

  useEffect(() => {
    if (!disable && !isEmpty(socket)) {
      // TODO: join room by id
      socket.emit(event, { room: room, room_id: roomId }, msg => {});
      // TODO: subscribe to channel
      socket.on(channel, handleEvent);
    }

    return () => {
      if (!isEmpty(socket)) {
        // TODO: unsubscribe from channel
        socket.off(channel, handleEvent);
      }
    };
  }, [disable, event, channel, room, roomId]);

  const handleEvent = data => {
    if (!isEmpty(data)) {
      typeof callback === 'function' && callback(data);
    }
  };
};

export default useSocketSubscribeChannel;
