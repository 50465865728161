import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import Component from './component';
import {
  getPostDetail,
  getListComments,
  deletePost,
  updatePostsAfterViewDetail,
  pinPost,
  unPinPost,
  showForumIndicatorByNewComment,
  addCommentWithMedia,
  getListReply,
} from 'redux/forum/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { getNotifications } from 'redux/notification/actions';
import { uploadMediaBackgroundRequest, uploadMediaBackgroundSuccess } from 'redux/upload-background/actions';

const mapState = state => {
  const { user, rootReducer, cloudfrontList } = state;
  return { userId: user._id, user, group: get(rootReducer, 'forum.group', {}), cloudfrontList };
};

const mapDispatchToProps = dispatch => ({
  getListComments: bindActionCreators(getListComments, dispatch),
  pinPost: bindActionCreators(pinPost, dispatch),
  unPinPost: bindActionCreators(unPinPost, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  deletePost: postId => dispatch(deletePost(postId)),
  updatePostsAfterViewDetail: bindActionCreators(updatePostsAfterViewDetail, dispatch),
  getNotifications: bindActionCreators(getNotifications, dispatch),
  getPostDetail: postId => dispatch(getPostDetail(postId)),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  showForumIndicatorByNewComment: bindActionCreators(showForumIndicatorByNewComment, dispatch),
  addCommentWithMedia: bindActionCreators(addCommentWithMedia, dispatch),
  getListReply: bindActionCreators(getListReply, dispatch),
  uploadMediaBackgroundRequest: bindActionCreators(uploadMediaBackgroundRequest, dispatch),
  uploadMediaBackgroundSuccess: bindActionCreators(uploadMediaBackgroundSuccess, dispatch),
});

export default connect(mapState, mapDispatchToProps)(Component);
