import React from 'react';

import ButtonCopyWeek from './ButtonCopyWeek';
import ButtonRemoveWeek from './ButtonRemoveWeek';

import * as S from '../style';

function CalendarActionItem(props) {
  const { currentWeekIndexHover, index, availableCopy, calendarType, handleCurrentWeekHover } = props;
  function onMouseEnter() {
    handleCurrentWeekHover(index);
  }
  if (!availableCopy) {
    return <S.CalendarActionsContainerEmpty calendarType={calendarType || 1} onMouseEnter={onMouseEnter} />;
  }
  return (
    <S.CalendarActionsContainer
      style={{ opacity: Number(currentWeekIndexHover) === Number(index) ? 1 : 0 }}
      calendarType={calendarType || 1}
      onMouseEnter={onMouseEnter}
    >
      <S.CalendarActionsContent>
        <ButtonRemoveWeek {...props} />
        <ButtonCopyWeek {...props} />
      </S.CalendarActionsContent>
    </S.CalendarActionsContainer>
  );
}

export default CalendarActionItem;
