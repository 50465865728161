import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export const InputCopy = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  cursor: default;
`;

export const PublishButton = styled(Button)`
  border-color: transparent;
  background-color: #36b34b;
  color: #fff;
  padding: 6px 10px;
  background-image: url(${CDN_URL}/images/play_icon_white.svg);
  background-position: 20px;
  background-repeat: no-repeat;
  background-size: 12px;
  padding-left: 37px;
  padding-right: 20px;

  ${props =>
    props.isPublished
      ? css`
          background-image: url(${CDN_URL}/images/package_unpublish_icon.svg);
          background-color: #ffffff;
          border: 1px solid #d6dae4;
          color: #818da1;

          :not(:disabled) {
            :hover {
              background-color: #ffcc18;
              background-color: #ffffff;
              border: 1px solid #d6dae4;
              color: #818da1;
            }
          }
        `
      : css`
          :not(:disabled) {
            :hover {
              background-color: #38c350;
            }
          }
        `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 5px;
  }

  .packageActions__saveBtn {
    padding: 6px 30px;
    color: #818da1;

    svg {
      margin-right: 5px;

      path {
        fill: #818da1;
      }
    }
  }

  .packageActions__shareBtn {
    padding: 6px 30px;
    color: #818da1;

    svg {
      margin-right: 5px;
      height: 12px;
      width: 12px;

      path {
        fill: #818da1;
      }
    }
  }

  .packageActions__publishChangeBtn {
    padding: 7px 20px;

    svg {
      margin-right: 5px;

      path {
        fill: #fafbfc;
      }
    }
  }

  .app-tooltip.verify-email-tooltip {
    width: 288px;
    height: 60px;
    border-radius: 5px;
    padding: 10px 24px;
    margin-top: -6px;
    color: #fff;
    text-align: left;
    background-color: #2d2e2d;
    &.type-dark.place-top:after {
      border-top-color: #2d2e2d;
      bottom: -8px;
    }
    > div {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
`;
