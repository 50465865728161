import { toggleModal } from 'actions/modal';
import React, { useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { Button as BtnCommon } from 'shared/FormControl';
import { getUserShortName } from 'utils/commonFunction';
import { isTrainer } from 'utils/validations';
import { clientAddSubCoach } from 'redux/client/client.actionCreators';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const SingleValue = ({ innerProps, data }) => {
  return (
    <S.ContactInformation {...innerProps}>
      <S.SingleLabel className="select__option__label">
        <div className="name">{data.label}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const SelectOption = ({ innerProps, data, isFocused, innerRef }) => {
  let className = 'select__option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  return (
    <S.ContactInformation ref={innerRef} {...innerProps} className={className}>
      <Avatar name={getUserShortName(data)} size="40" src={data.avatar} color={data.color} />
      <S.SingleLabel className="select__option__label">
        <div className="name">
          {data.first_name} {data.last_name}
        </div>
        <div className="email">{data.email}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const AddCoach = ({
  toggleModal,
  team_member,
  clientId,
  clientAddSubCoach,
  idUserCurrent,
  listSubCoach,
  idTrainer,
}) => {
  const [dataSubCoach, setDataSubCoach] = useState([]);

  const options = useMemo(() => {
    const dataFilterTrainer = (team_member || []).filter(item => isTrainer(item));
    const dataFilterClientOfTrainer = (dataFilterTrainer || []).filter(
      item => idUserCurrent !== item._id && item._id !== idTrainer,
    );
    return (dataFilterClientOfTrainer || [])
      .filter(item => {
        const listSubcoach = (listSubCoach || []).map(itemSubcoach => itemSubcoach.trainer._id);
        return !(listSubcoach || []).includes(item._id);
      })
      .map(item => ({
        ...item,
        key: item._id,
        value: item.full_name,
        label: item.full_name,
      }));
  }, [team_member]);

  const handleSelectTeam = data => {
    const dataTransform = data.map(item => {
      return item._id;
    });
    setDataSubCoach(dataTransform);
  };

  const addSubCoach = () => {
    clientAddSubCoach({ clientId, dataSubCoach }, () => {
      toggleModal(false);
    });
  };

  return (
    <S.AddCoach
      open={true}
      closeOnDimmerClick={false}
      onClose={() => toggleModal(false)}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <S.Title>
          <img src={`${CDN_URL}/images/icon-add-coach.svg`} alt="" />
          Grant Access
        </S.Title>
      </Modal.Header>
      <Modal.Content>
        <Select
          options={options}
          components={{
            Option: SelectOption,
            DropdownIndicator: null,
            SingleValue,
            IndicatorSeparator: null,
            ClearIndicator: null,
          }}
          classNamePrefix="select-add-coach-prefix"
          className="select-add-coach"
          placeholder="Add teammates"
          onChange={handleSelectTeam}
          isSearchable
          isMulti
          menuPosition="fixed"
          styles={{
            control: base => ({ ...base, minHeight: 42 }),
          }}
          noOptionsMessage={() => 'No results'}
        />
        <S.ListPermission>
          <S.TitlePermission>Permission</S.TitlePermission>
          <S.ItemPermission>Full permission</S.ItemPermission>
        </S.ListPermission>
        <S.Action>
          <BtnCommon disabled={dataSubCoach.length <= 0} type="button" purple w95 onClick={addSubCoach}>
            Add
          </BtnCommon>
        </S.Action>
      </Modal.Content>
    </S.AddCoach>
  );
};

const mapStateToProps = state => {
  const {
    user: { team_member, _id },
    rootReducer: {
      client: {
        subCoach: { data },
        selected,
        workingClientDetail: { trainers = [] },
      },
    },
  } = state;

  return {
    idUserCurrent: _id,
    clientId: selected,
    isModalOpen: state.isModalOpen,
    team_member,
    listSubCoach: data,
    idTrainer: trainers[0] && trainers[0].trainer,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  clientAddSubCoach: bindActionCreators(clientAddSubCoach, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCoach);
