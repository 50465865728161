// Lib
import React from 'react';

// Shared
import UpgradePathTooltipContent from 'shared/UpgradePath/components/UpgradePathTooltipContent';

// Constants
import { PLANS } from 'constants/commonData';

// Assets
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';

// Style
import * as S from './style';

const TrialAddOn = ({
  title = 'Add on is expired',
  description = '',
  btnContentText = 'Get Custom Meal Plans',
  planWillSelect = {
    tier: PLANS.pro.key,
    addOns: {
      meal_plan: true,
    },
  },
  className,
}) => {
  return (
    <S.GetMealPlanAddOn className={className}>
      {title} <InfoIcon className="icon-info" />
      <UpgradePathTooltipContent
        isShowHeading={false}
        className="features__tooltipUpgradePath custom-upgrade-path-popup"
        btnFullWidth
        description={description}
        btnContentText={btnContentText}
        planWillSelect={planWillSelect}
      />
    </S.GetMealPlanAddOn>
  );
};

export default TrialAddOn;
