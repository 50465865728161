import React from 'react';
import { Modal, Image } from 'semantic-ui-react';
import ReactAvatar from 'react-avatar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserShortName, isTeamAdmin } from 'utils/commonFunction';
import { toggleModal } from 'actions/modal';
import TitleTooltip from 'shared/TitleTooltip';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const ModalWrapper = styled(Modal)`
  border-radius: 8px;
  max-width: 425px;

  .content {
    border-radius: 8px;
    padding: 30px !important;
  }

  .upgradeModal__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__description {
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__adminList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #f3f5f8;
    mix-blend-mode: normal;
    border-radius: 6px;
    padding: 15px;
    margin-top: 30px;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
`;

const AdminWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-width: 0;

  .admin__name {
    flex: 1;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .upgrade-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    overflow: hidden;
  }
`;

const TeamAdmin = admin => {
  const { avatar = '', color = '', _id = '', full_name = '' } = admin;

  return (
    <AdminWrapper>
      <ReactAvatar name={getUserShortName(admin)} size="30" src={avatar} color={color} />
      <div className="admin__name">
        <TitleTooltip className="upgrade-title" tooltipId={`upgrade-title-${_id}`} content={full_name} />
      </div>
    </AdminWrapper>
  );
};

class UpgradeYourPlanModal extends React.Component {
  state = {};

  render() {
    const { user } = this.props;
    const admins = user.team_member.filter(u => isTeamAdmin(u)).splice(0, 4);

    return (
      <ModalWrapper
        size={'tiny'}
        open={true}
        onClose={this.props.onClose}
        closeOnDimmerClick={false}
        closeIcon={
          <button className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </button>
        }
      >
        <Modal.Content>
          <div className="upgradeModal__title">Upgrade Your Plan</div>
          <div className="upgradeModal__description">
            Please reach out to your workspace admin to upgrade the subscription plan.
          </div>
          <div className="upgradeModal__adminList">
            {admins.map(admin => (
              <TeamAdmin key={admin._id} {...admin} />
            ))}
          </div>
        </Modal.Content>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  return {
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeYourPlanModal);
