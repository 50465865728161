import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';
import { normalize } from 'normalizr';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import * as S from './style';
import { ENTITY_NAME } from 'database/constants';
import Header from './components/Header';
import Tempo from './components/Tempo';
import {
  initExerciseInstance,
  initIntervalExerciseFields,
  initFieldsWithORM,
  shouldShowOneRepMaxPercent,
  isUseORM,
  initSetsData,
} from 'helpers/exercise';
import { CDN_URL, SECTION_FORMAT_KEY } from 'constants/commonData';
import { SectionContext } from '../contexts';
import TrainingSet from '../TrainingSet';
import { Checkbox } from 'shared/FormControl';
import AddMultipleSets from 'shared/AddMultipleSets';
import ExerciseFieldsSelectBox from 'components/ExerciseFieldsSelectBox';
import { ErrorMessage } from 'shared/FormControl';
import AlternateExercise from './components/AlternateExercise';
import TextEditable from 'shared/TextEditable';
import { SuperSet } from 'database/schema';

function Superset(props) {
  const {
    model,
    systemFields,
    categories,
    units,
    disabled,
    supersetId,
    exerciseId,
    showError,
    exerciseIndex,
    linkIndex,
    modelExerciseLibrary,
  } = props;
  const superset = model.toJS();

  const [showCustomizeForm, setShowCustomizeForm] = useState(false);
  const [useORM, setUseORM] = useState(isUseORM(_.get(superset, 'exercise_instance.fields', []), systemFields));
  const [showORMOption, setShowORMOption] = useState(
    shouldShowOneRepMaxPercent(_.get(superset, 'exercise_instance.fields', []), systemFields),
  );

  const dataNoteRef = useRef(superset.note || '');

  useEffect(() => {
    setUseORM(isUseORM(_.get(superset, 'exercise_instance.fields', []), systemFields));
    setShowORMOption(shouldShowOneRepMaxPercent(_.get(superset, 'exercise_instance.fields', []), systemFields));
  }, [superset.exercise_instance.fields]);

  const sectionData = useContext(SectionContext);
  const restField = _.find(systemFields, f => f.unique_code === 'rest');
  const isAddingExercise = _.isEmpty(superset.exercise) && _.isEmpty(superset.exercise_instance);

  const fieldsObject = useMemo(() => {
    const result = [];

    _.forEach(superset.exercise_instance.fields, fId => {
      const obj = _.find(systemFields, o => o._id === fId);

      if (obj) {
        result.push(obj);
      }
    });

    if (!_.find(result, f => f.unique_code === 'rest') && restField) {
      result.push(restField);
    }

    return result;
  }, [superset.exercise_instance.fields, systemFields]);

  const missingORMBValue = useMemo(() => {
    if (!useORM || !showError || !superset.training_sets || !superset.training_sets.length) {
      return false;
    }

    const emptyInstance = _.find(superset.training_sets, set => !set.orm || isNaN(parseInt(set.orm.value)));
    return !!emptyInstance;
  }, [useORM, superset.training_sets, showError]);

  const onUpdateNote = note => {
    dataNoteRef.current = note || '';
  };

  const handleBlurNote = () => {
    props.updateEntity &&
      props.updateEntity(ENTITY_NAME.Superset, supersetId, { note: dataNoteRef.current || superset.note || '' });
  };

  const onUpdateTempo = tempo => {
    props.updateEntity(ENTITY_NAME.Superset, supersetId, { tempo });
  };

  const onUpdateEachSide = event => {
    const { checked } = event.target;
    props.updateEntity(ENTITY_NAME.Superset, supersetId, { each_side: checked });
  };

  const updateAlternativeExecises = alternatives => {
    const newSuperset = { ...superset, alternatives };
    const normalized = normalize(newSuperset, SuperSet);
    props.updateEntities(normalized.entities);
  };

  const onSelectExercise = data => {
    if (data) {
      const exercise = _.omit(data, ['key', 'value', 'label']);
      const exerciseInstance = initExerciseInstance(exercise);
      const isIntervalSection = sectionData.format === SECTION_FORMAT_KEY.INTERVAL;

      if (isIntervalSection) {
        exerciseInstance.fields = initIntervalExerciseFields(exerciseInstance, categories, systemFields);
      }

      const showUseOrmPercent = shouldShowOneRepMaxPercent(exerciseInstance.fields || [], systemFields);

      if (useORM && showUseOrmPercent) {
        exerciseInstance.fields = initFieldsWithORM(exerciseInstance.fields, systemFields);
      }

      const initSetParams = {
        oldSets: superset.training_sets,
        newFields: exerciseInstance.fields,
        systemFields,
        units,
      };

      const newSuperset = {
        ...superset,
        exercise,
        exercise_instance: exerciseInstance,
        each_side: false,
        tempo: '0',
        one_rep_max: exercise.one_rep_max,
        training_sets: initSetsData(initSetParams, isIntervalSection),
      };

      // note
      if (_.isEmpty(superset.note)) {
        newSuperset.note = exercise.note || '';
      }

      // alternatives
      if (_.isEmpty(superset.alternatives) && exercise.alternatives) {
        let isExistExercise = false;

        if (modelExerciseLibrary) {
          isExistExercise = exercise.alternatives.every(id => modelExerciseLibrary.hasIn([id]));
        }

        if (isExistExercise) {
          newSuperset.alternatives = exercise.alternatives;
        } else {
          props.getExercisesByIds({
            ids: exercise.alternatives,
            superset: { ...newSuperset },
            isUpdateEntities: true,
          });
        }
      }

      const normalized = normalize(newSuperset, SuperSet);
      props.updateEntities(normalized.entities);
    }
  };

  const onToggleUsePercent = isUseORM => {
    if (!superset.exercise_instance || !superset.training_sets || superset.training_sets.length === 0) {
      return;
    }

    props.toggleUseORM(supersetId, isUseORM);
  };

  const onAddSets = number => {
    if (disabled) {
      return;
    }

    props.addSets(exerciseId, supersetId, number);
  };

  const onAddOneSet = () => {
    if (disabled) {
      return;
    }

    props.addSets(exerciseId, supersetId, 1);
  };

  const onCustomizeFields = () => {
    setShowCustomizeForm(true);
  };

  const onCloseCustomizeForm = () => {
    setShowCustomizeForm(false);
  };

  const onSaveCustomFields = newFields => {
    if (_.isEqual(newFields, _.map(fieldsObject, '_id'))) {
      setShowCustomizeForm(false);
      return;
    }

    const fields = newFields.slice();

    if (!fields.includes(restField._id)) {
      fields.push(restField._id);
    }

    const initSetParams = {
      oldSets: superset.training_sets,
      newFields: fields,
      systemFields,
      units,
    };

    const newSuperset = {
      ...superset,
      training_sets: initSetsData(initSetParams, sectionData.format === SECTION_FORMAT_KEY.INTERVAL),
      exercise_instance: { ...superset.exercise_instance, fields: initSetParams.newFields },
    };
    const normalized = normalize(newSuperset, SuperSet);

    setShowCustomizeForm(false);
    props.updateEntities(normalized.entities);
  };

  const onUpdateExerciseLibrary = data => {};

  return (
    <S.Wrapper className="supersetWrapper">
      <Header
        key={`${supersetId}-header`}
        originalExercise={superset.exercise}
        exerciseInstance={superset.exercise_instance}
        alternatives={superset.alternatives || []}
        onToggleUsePercent={onToggleUsePercent}
        onSelectExercise={onSelectExercise}
        useORM={useORM}
        showORMOption={showORMOption}
        sectionData={sectionData}
        exerciseId={exerciseId}
        supersetId={supersetId}
        onCustomizeFields={onCustomizeFields}
        onUpdateAlternativeExercises={updateAlternativeExecises}
        exerciseIndex={exerciseIndex}
        linkIndex={linkIndex}
      />

      {isAddingExercise ? null : (
        <>
          {showCustomizeForm && (
            <ExerciseFieldsSelectBox
              default_fields={_.map(fieldsObject, '_id')}
              onSave={onSaveCustomFields}
              handleClose={onCloseCustomizeForm}
              intervalSection={sectionData.format === SECTION_FORMAT_KEY.INTERVAL}
              showButtons={true}
              should_show_1rm={showORMOption}
              is_use_1rm={useORM}
              dataFor={supersetId}
            />
          )}
          {superset.alternatives && superset.alternatives.length ? (
            <S.AlternateExerciseContainer className="alternateExercise">
              <label>Alternate exercises</label>
              <div className="list">
                {superset.alternatives.map((exercise, i) => (
                  <AlternateExercise
                    data={exercise}
                    index={i}
                    key={exercise._id}
                    onSaveExercise={props.updateExerciseLibrary}
                  />
                ))}
              </div>
            </S.AlternateExerciseContainer>
          ) : null}
          <S.TrainingSetsContainer className="superset__trainingSets">
            <S.SetTable key="tableHeader" compact="true">
              <thead>
                <tr>
                  <th key="set">Set</th>
                  {fieldsObject.map(field => {
                    const title = field.title.replace(' (short)', '');
                    let unitTitle = '';

                    if (units[field.unique_code]) {
                      unitTitle = '(' + units[field.unique_code].title + ')';
                    }

                    const showTooltipForWeight = field.unique_code === 'weight' && useORM;
                    const tooltipId = `tooltip--${supersetId}-weightField`;

                    return (
                      <th key={`th_${field._id}`}>
                        {showTooltipForWeight ? (
                          <>
                            <span>{`${title} ${unitTitle}`}</span>
                            <img
                              data-tip
                              data-for={tooltipId}
                              src={`${CDN_URL}/images/info_circle.svg`}
                              width={10}
                              height={10}
                              style={{ display: 'inline', marginLeft: 3, verticalAlign: 'middle' }}
                              alt=""
                            />
                            <ReactTooltip id={tooltipId} effect="solid" place="top" className="app-tooltip">
                              <span>Weight assigned based on most recent 1RM estimation</span>
                            </ReactTooltip>
                          </>
                        ) : (
                          <span>{`${title} ${unitTitle}`}</span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </S.SetTable>
            <S.SetTable key="tableBody" className="table--body" compact="true">
              <tbody>
                {_.map(superset.training_sets, (set, setIndex) => {
                  return (
                    <TrainingSet
                      key={set._id}
                      setId={set._id}
                      setIndex={setIndex}
                      fieldsObject={fieldsObject}
                      supersetId={supersetId}
                      exerciseId={exerciseId}
                      useORM={useORM}
                      supersetORMData={superset.one_rep_max}
                      missingORMBValue={missingORMBValue}
                      originalExercise={_.get(superset.exercise, '_id', superset.exercise)}
                    />
                  );
                })}
              </tbody>
            </S.SetTable>
            {missingORMBValue && (
              <ErrorMessage className="workoutError--emptyORM">Please set a %1RM percentage.</ErrorMessage>
            )}
          </S.TrainingSetsContainer>
          <S.ExtendInformationContainer className="superset__extends">
            <div className="leftSide">
              <Checkbox
                title="Each Side"
                checked={superset.each_side}
                disabled={disabled}
                onChange={onUpdateEachSide}
              />
              <Tempo value={superset.tempo} disabled={disabled} onChange={_.debounce(onUpdateTempo, 200)} />
            </div>
            {sectionData.format !== SECTION_FORMAT_KEY.AMRAP && sectionData.format !== SECTION_FORMAT_KEY.TIMED ? (
              <S.AddSetContainer>
                <div className="addOneSet" role="button" onClick={onAddOneSet} disabled={disabled}>
                  Add Set
                </div>
                <AddMultipleSets onSelect={onAddSets} max={8} disabled={disabled} />
              </S.AddSetContainer>
            ) : (
              <div />
            )}
          </S.ExtendInformationContainer>
          <TextEditable
            value={superset.note}
            onChange={_.debounce(onUpdateNote, 150)}
            placeholder="Add note for this exercise"
            lineHeight={20}
            breakLine={true}
            onBlur={handleBlurNote}
          />
        </>
      )}
    </S.Wrapper>
  );
}

export default Superset;
