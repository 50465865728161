// Libs
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { resetOnboardingFilter } from 'redux/onboarding-flow-library/actions';

// Components
import OnboardingFlowLibrary from './component';

const mapStateToProps = state => {
  const {
    rootReducer: {
      onboardingLibrary: { currentTab },
    },
  } = state;

  return { currentTab };
};

const mapDispatchToProps = dispatch => ({
  resetOnboardingFilter: bindActionCreators(resetOnboardingFilter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFlowLibrary);
