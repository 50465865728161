import _ from 'lodash';
import moment from 'moment';
import { timeSince, roundUp } from 'utils/commonFunction';
import { CLIENT_STATUS } from 'constants/commonData';

export const filterClients = (clients, filters) => {
  const list = _.filter(clients, item => {
    let result = true;
    _.forEach(filters, (value, key) => {
      switch (key) {
        case 'groups':
          if (value) {
            _.forEach(value, groupId => {
              if (!_.find(item.groups, g => g === groupId)) {
                result = false;
                return false;
              }
            });
          }

          break;

        case 'ownerShip':
          if (typeof value === 'string') {
            result = item.trainers.some(o => o.trainer === value);
          } else if (_.isArray(value) && value.length) {
            result = item.trainers.some(o => value.includes(o.trainer));
          }

          break;

        case 'client_connection':
          if (!value && parseInt(value) !== 0) {
            result = item.client_connection !== CLIENT_STATUS.archived;
          } else {
            result = item.client_connection === value;
          }

          break;

        default:
          if (value) {
            result = item[key] === value;
          }

          break;
      }

      if (!result) {
        return false;
      }
    });

    return result;
  });
  return list;
};

const parseClientStatus = client_connection => {
  if (client_connection === CLIENT_STATUS.connected) {
    return 'Connected';
  }

  if (client_connection === CLIENT_STATUS.pending) {
    return 'Pending';
  }

  if (client_connection === CLIENT_STATUS.archived) {
    return 'Archived';
  }

  if (client_connection === CLIENT_STATUS.offline) {
    return 'Offline';
  }

  return '--';
};

export const parseClientsData = clients => {
  return _.map(clients, client => {
    client.status = parseClientStatus(client.client_connection);
    client.name = client.full_name;
    client.last_engaged_text = client.last_engaged ? timeSince(new Date(client.last_engaged)) : '--';
    client.last_activity_text = client.last_activity ? timeSince(new Date(client.last_activity)) : '--';

    client.last_assigned_workout_text = '--';
    if (client.last_assigned_workout) {
      const formatDate = moment(client.last_assigned_workout, 'YYYY-MM-DD h:mm:ss a');
      let typeFormatDate = 'MMM D';
      const currentYear = new Date().getFullYear();
      if (Number(formatDate.format('YYYY')) !== Number(currentYear)) {
        typeFormatDate = 'MMM D, YYYY';
      }
      client.last_assigned_workout_text = formatDate.format(typeFormatDate);
    }

    client.trainer_name = client.trainer_full_name;

    return client;
  });
};
