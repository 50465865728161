import styled from 'styled-components';

export const GoalCountdownItemStyled = styled.div`
  &.countdown-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 4px;
    width: 430px;
    min-height: 146px;
    margin: 0 auto;

    .countdown-top {
      min-height: 88px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      position: relative;

      .titles {
        display: flex;
        padding: 10px 5px 2px 12px;
        gap: 8px;

        h3 {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          flex: 1;
        }

        .pinned-ic {
          position: relative;
          top: -1px;
          right: 0;
        }
      }

      :hover {
        cursor: pointer;
      }

      .confetti {
        position: absolute;
        right: 0;
        top: 0;
      }

      .countdown-right-wrapper {
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding: 6px 4px;
      }

      .countdown-right {
        display: flex;
        gap: 4px;
        align-items: center;
        background-color: #fff;
        border-radius: 4px;
        padding: 8px 12px 8px 6px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: #202353;
        width: fit-content;

        img {
          width: 24px;
          height: 24px;
        }

        span.completed {
          display: inline-flex;
          font-size: 24px;
          text-align: center;
          padding: 3px;
        }
      }
    }
    .countdown-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      padding: 16px 12px 12px;

      .dropdown-actions {
        position: relative;
        right: -1px;
        top: -1px;
      }

      p {
        font-size: 12px;
        line-height: 12px;
        color: #a3a3b5;
        font-weight: 600;
        margin-bottom: 0;

        span {
          position: relative;
          color: #202353;
          margin-left: 1px;
        }

        svg {
          position: relative;
          top: 3px;
          margin-left: 9px;
        }
      }
    }
  }
`;
