import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const ImageContainer = styled.div`
  width: 100%;
  height: 295px;
  background: transparent url(${CDN_URL}/images/signup_second_step.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const PlandTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 18px;
`;

export const ContentItem = styled.div`
  margin-bottom: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export const PlanContent = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #191753;
  margin-top: 236px;

  .highlight {
    font-weight: bold;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const LeftSide = styled.div`
  width: 430px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: unset;
    margin-top: 0px;
    padding: 30px 26px;
    position: relative;
    box-shadow: 0px -1px 0px #ebeef1;
    justify-content: left;
  }
`;

export const FormContainer = styled.div`
  box-shadow: -1px 0px 0px #ebeef1;
  min-height: 100%;
  padding: 53px 50px 0;

  .form__header {
    font-weight: bold;
    text-align: center;
    margin: 0 0 28px;

    .welcome {
      color: #5158cf;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .title {
      font-size: 20px;
      line-height: 27px;
      color: #191753;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 30px;
  }
`;

export const RightSide = styled.div`
  width: 420px;
  padding: 20px 0 50px;

  .referral-code-trigger {
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5158cf;
    margin-top: 5px;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .form-group--referral {
    margin-bottom: 0;
  }

  .form__inputs {
    margin-bottom: 20px;
  }

  button.submit-button {
    width: 100%;
    margin: auto;
    text-transform: none;
    padding: 17px 35px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    background: #5158cf;
    border-radius: 10px;

    img {
      width: 13px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: unset;
    padding: 30px 0;

    .form__inputs {
      margin-bottom: 30px;
    }

    button.submit-button {
      width: 100%;
      padding: 17px 35px;
    }
  }
`;

export const Form = styled.form`
  overflow: visible;
  display: flex;
  position: relative;
  min-height: 470px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 8px;
  margin-top: 155px;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  overflow: auto;
  background-color: #f8f8f8;

  .sign-up--second-step-header {
    background-color: #fff;
  }

  @media only screen and (max-width: 768px) {
    .sign-up--second-step-header {
      background-color: transparent;
      box-shadow: none;
      margin-top: 40px;
    }
  }
`;
