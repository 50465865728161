import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import Linkify from 'react-linkify';

import { Checkbox } from 'shared/FormControl';

import * as S from '../styles';
import { find } from 'lodash';

const Option = ({ option, optionAnswers }) => {
  const [alignTop, setAlignTop] = useState(false);
  useEffect(() => {
    const element = document.getElementById(`${option._id}-element`);
    if (element && element.offsetHeight) {
      setAlignTop(element.offsetHeight > 24);
    }
  }, []);
  return (
    <div
      className={classNames('multiple-option-container', { alignTop: alignTop })}
      key={option._id}
      id={`${option._id}-element`}
    >
      <Checkbox
        size={20}
        className="option-radio"
        checked={find(optionAnswers, obj => obj._id === option._id) || false}
      />
      <span className="option-label">{option.label}</span>
    </div>
  );
};

export default function MultipleChoiceResponse({ answer }) {
  const optionAnswers = get(answer, 'answer.option_answers', []);
  const otherAnswer = get(answer, 'answer.other_answer');
  return (
    <S.MultipleChoiceContainer>
      {optionAnswers.map(option => (
        <Option option={option} optionAnswers={optionAnswers} />
      ))}
      {otherAnswer && (
        <S.AnswerContentWrapper>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {otherAnswer}
          </Linkify>
        </S.AnswerContentWrapper>
      )}
    </S.MultipleChoiceContainer>
  );
}
