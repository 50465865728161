import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import InboxContent from './component';

import { push } from 'connected-react-router';
import {
  sendMessage,
  selectClientFromSearchBar,
  turnOnFeature,
  addNewLocalMessages,
  removeLocalMessage,
  removeRoomDraftMessage,
  getRoomMessages,
  adminGetRoomMessages,
  getArchivedRoomMessages,
  getMessagesAtId,
  getMessagesBottomAtId,
  getMessagesAdminRoomAtId,
  getMessagesAdminRoomBottomAtId,
  getMessagesArchivedRoomAtId,
  getMessagesArchivedRoomBottomAtId,
  getLatestMessages,
  unmuteConversation,
  createNewGroup,
  getContactsList,
  enableChatInput,
  resetFirstLoadList,
  getLatestMessagesArchivedRoom,
  resetMuteStatus,
  deleteMessage,
  stopWaitingSystemMessage,
} from 'redux/inbox-new/actions';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { cancelUploadRequest } from 'redux/attachments/actions';
import { toggleConfirmModal } from 'actions/modal';

const mapState = state => {
  const {
    user,
    rootReducer: {
      inboxNew: {
        messages,
        currentRoomId,
        newConversation,
        rooms,
        featureOff,
        profileId,
        transferInformation,
        draft,
        viewTeammate,
        roomInformation,
        archivedMessages,
        endOfList,
        currentTeam,
        loading,
        firstLoadList,
        isDisabled,
        members,
        selectedRoom,
        bottomItem,
        invalidRoom,
        usersInfo,
        query,
        isWaiting,
      },
      permission,
    },
    cloudfrontList,
  } = state;

  const allRooms = query.rooms.length ? uniqBy([...rooms, ...query.rooms], 'id') : rooms;
  const room = currentRoomId ? allRooms.find(item => (item.id || item._id) === currentRoomId) : null;

  return {
    cloudfrontList,
    messages: uniqBy(messages, 'key'),
    currentRoomId,
    newConversation,
    room,
    user,
    featureOff,
    profileId,
    transferInformation,
    draftMessage: draft[profileId] || '',
    viewTeammate,
    roomInformation,
    archivedMessages,
    endOfList,
    currentTeam,
    loading,
    firstLoadList,
    isDisabled,
    members,
    selectedRoom,
    bottomItem,
    invalidRoom,
    usersInfo,
    isWaiting,
    isGroupChatPermission: get(permission, 'group_chat', false),
  };
};

const mapDispatch = dispatch => ({
  sendMessage: bindActionCreators(sendMessage, dispatch),
  selectClientFromSearchBar: bindActionCreators(selectClientFromSearchBar, dispatch),
  turnOnFeature: bindActionCreators(turnOnFeature, dispatch),
  addNewLocalMessages: bindActionCreators(addNewLocalMessages, dispatch),
  removeLocalMessage: bindActionCreators(removeLocalMessage, dispatch),
  removeRoomDraftMessage: bindActionCreators(removeRoomDraftMessage, dispatch),
  getMessages: bindActionCreators(getRoomMessages, dispatch),
  getMessagesBottomAtId: bindActionCreators(getMessagesBottomAtId, dispatch),
  adminGetMessages: bindActionCreators(adminGetRoomMessages, dispatch),
  getArchivedRoomMessages: bindActionCreators(getArchivedRoomMessages, dispatch),
  unmuteConversation: bindActionCreators(unmuteConversation, dispatch),
  createNewGroup: bindActionCreators(createNewGroup, dispatch),
  push: bindActionCreators(push, dispatch),
  getContactsList: bindActionCreators(getContactsList, dispatch),
  enableChatInput: bindActionCreators(enableChatInput, dispatch),
  getMessagesAtId: bindActionCreators(getMessagesAtId, dispatch),
  getMessagesAdminRoomAtId: bindActionCreators(getMessagesAdminRoomAtId, dispatch),
  getMessagesAdminRoomBottomAtId: bindActionCreators(getMessagesAdminRoomBottomAtId, dispatch),
  getMessagesArchivedRoomAtId: bindActionCreators(getMessagesArchivedRoomAtId, dispatch),
  getMessagesArchivedRoomBottomAtId: bindActionCreators(getMessagesArchivedRoomBottomAtId, dispatch),
  getLatestMessages: bindActionCreators(getLatestMessages, dispatch),
  getS3presignedURLFromLocalDatabase: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
  resetFirstLoadList: bindActionCreators(resetFirstLoadList, dispatch),
  getLatestMessagesArchivedRoom: bindActionCreators(getLatestMessagesArchivedRoom, dispatch),
  resetMuteStatus: bindActionCreators(resetMuteStatus, dispatch),
  cancelUploadRequest: bindActionCreators(cancelUploadRequest, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  deleteMessage: bindActionCreators(deleteMessage, dispatch),
  stopWaitingSystemMessage: bindActionCreators(stopWaitingSystemMessage, dispatch),
});

export default connect(mapState, mapDispatch)(InboxContent);
