import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { connect } from 'react-redux';
import _ from 'lodash';

import { CDN_URL, HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';
import { handleGetExerciseType } from 'helpers/workout';

import ExerciseItem from './ExerciseItem';

import * as S from './style';

const SectionItem = props => {
  const { section } = props;

  const { type, exercises } = section;
  const isHiddenSection = type === HIDDEN_SECTION;
  const isSupersetSections = isHiddenSection && !!exercises.length && exercises[0].supersets.length > 1;
  const time = calculateSectionTime(section);

  const currentExercises =
    section.format === SECTION_FORMAT_KEY.FREESTYLE ? section.exercise_references : section.exercises;

  return (
    <S.SectionContainer
      key={section._id}
      className={classNames('section', {
        'section--hidden': isHiddenSection && !isSupersetSections,
        'section--supersets': isSupersetSections,
      })}
    >
      <div className="dot" />
      {!isHiddenSection && (
        <S.SectionTitle className={classNames({ 'none-spacing': currentExercises.length === 0 })}>
          <div className="title">{section.title}</div>
          {time.text && (
            <div className="time">
              <img src={time.icon} />
              <span>{time.text}</span>
            </div>
          )}
          {handleRenderTypeOfSection(section)}
        </S.SectionTitle>
      )}
      <S.SectionExercises
        className={classNames(
          'section--exercises',
          { 'section--exercises--hidden': isHiddenSection },
          { 'none-boder': currentExercises.length === 0 },
        )}
      >
        {currentExercises.map((set, setIndex) => (
          <ExerciseItem set={set} setIndex={setIndex} key={set._id} />
        ))}
      </S.SectionExercises>
    </S.SectionContainer>
  );
};

const handleRenderTypeOfSection = props => {
  const { FREESTYLE } = SECTION_FORMAT_KEY;
  const { format, attachments } = props;

  return (
    format === FREESTYLE && (
      <div className="section-type">
        {handleGetExerciseType(attachments)}
        {handleGetDurationFreestyleSection(attachments)}
      </div>
    )
  );
};

const handleGetDurationFreestyleSection = props => {
  let typeDuration = _.map(props, item => item.duration);

  typeDuration = typeDuration[0];

  const min = moment.duration(typeDuration, 'seconds')._data.minutes;
  const seconds = moment.duration(typeDuration, 'seconds')._data.seconds;

  if (typeDuration) {
    return <div className="section-duration">{`${min}:${seconds}`}</div>;
  }
};

const mapState = state => {
  const {
    user: { preferences },
    rootReducer: {
      exercise: { fields },
    },
  } = state;

  return { fields, unit: preferences };
};

export default connect(mapState, null)(SectionItem);

const parseTime = seconds => {
  return { minute: parseInt(seconds / 60), second: parseInt(seconds % 60) };
};

const calculateSectionTime = section => {
  const { type, round, time, format } = section;
  let text = '',
    parsedTime,
    totalDuration = Number(time || 0);
  let icon = `${CDN_URL}/images/clock_grey_2.svg`;

  if (type === HIDDEN_SECTION) {
    return { text, icon };
  }

  switch (format) {
    case SECTION_FORMAT_KEY.TIMED:
      icon = `${CDN_URL}/images/round_purple.svg`;
      text = `${round} round${round !== 1 ? 's' : ''}`;
      break;

    case SECTION_FORMAT_KEY.AMRAP:
      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;

    case SECTION_FORMAT_KEY.INTERVAL:
      totalDuration = _.sumBy(section.exercises, set => {
        return _.sumBy(set.supersets, ex => {
          return _.sumBy(ex.training_sets, exSet => {
            const duration = Number(_.get(exSet, 'duration.value', 0));
            const rest = Number(_.get(exSet, 'rest.value', 0));
            return duration + rest;
          });
        });
      });

      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;
    default:
      break;
  }

  return { text, icon };
};
