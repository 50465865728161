import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListStudioResource, resetListData } from 'redux/studio-resource/actions';
import * as S from './style';
import StudioResourceHeading from './components/Heading';
import ResourceList from './components/ResourceList';

const StudioResourceList = function(props) {

  useEffect(() => {
    props.getListStudioResource();
  }, []);

  return (
    <S.Wrapper>
      <StudioResourceHeading header />
      <ResourceList />
    </S.Wrapper>
  )
}

const mapDispatch = (dispatch) => ({
  getListStudioResource: bindActionCreators(getListStudioResource, dispatch),
  resetListData: bindActionCreators(resetListData, dispatch),
});

export default connect(null, mapDispatch)(StudioResourceList);
