/**
 * @flow
 */

import type { Element } from 'react';
import { TOGGLE_MODAL, TOGGLE_SECOND_MODAL } from 'actions/modal';
import type { Action } from 'types/redux';

export const isModalOpen = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return action.isOpen;
    default:
      return state;
  }
};

export const isSecondModalOpen = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case TOGGLE_SECOND_MODAL:
      return action.isOpen;
    default:
      return state;
  }
};

export const modal = (state: ?Element<any> = null, action: Action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      if (action.modal) {
        return action.modal;
      }
      return state;
    default:
      return state;
  }
};

export const isConfirmModalOpen = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_CONFIRM_MODAL':
      return action.isOpen;
    default:
      return state;
  }
};

export const secondModal = (state: ?Element<any> = null, action: Action) => {
  switch (action.type) {
    case TOGGLE_SECOND_MODAL:
      if (action.modal) {
        return action.modal;
      }
      return state;
    default:
      return state;
  }
};

export const confirmModal = (state: ?Element<any> = null, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_CONFIRM_MODAL':
      return action.isOpen ? action.modal : null;
    default:
      return state;
  }
};

