import get from 'lodash/get';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import connect from 'react-redux/lib/connect/connect';
import { CDN_URL, PACKAGE_STATUS } from 'constants/commonData';

import * as S from './styles';
import { Button } from 'shared/FormControl';
import { enableEditMode, unpublishPackage, cancelEditMode, publishPackage } from 'redux/package-detail/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import IconButton from 'shared/IconButton';

import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as PreviewIcon } from 'assets/icons/package_preview_icon.svg';
import CopyPackage from './CopyPackage';
import PackagePublishFallbackPopup from 'shared/UpgradePath/components/PackagePublishFallbackPopup';
import { checkCountryUS } from 'utils/commonFunction';

function PackageActions(props) {
  const { onSave, onPublish, status, isEditMode, isSubmitting, workingPackage, isMP, payment } = props;

  const isUS = checkCountryUS(get(payment, 'account.account.country', ''));

  const onEdit = () => {
    props.enableEditMode(workingPackage.get('id'), workingPackage.get('status') === PACKAGE_STATUS.PUBLISH, isMP);
  };

  const onUnpublish = () => {
    props.unpublishPackage(workingPackage.get('id'));
  };

  const onCancelEditMode = () => {
    props.cancelEditMode(workingPackage.get('id'), workingPackage.get('status') === PACKAGE_STATUS.PUBLISH, isMP);
  };

  const handleUnpublish = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Unpublish package"
        content={
          <>
            <div style={{ marginBottom: '20px' }}>
              ‘{workingPackage.get('name')}’ will be unpublished and the link will become inactive.
            </div>
            <div>Any purchases already made by clients will be unaffected. Active purchases will remain active.</div>
          </>
        }
        onConfirm={onUnpublish}
        confirmButtonTitle="I understand"
        cancelButtonTitle="Cancel"
        confirmButtonClass="confirm-unpublish-button"
        headerIcon={`${CDN_URL}/images/studio_program_unpublish_yellow.svg`}
        newStyle
        hasCloseIcon
      />,
    );
    return false;
  };

  const cancelEditWarningModal = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Cancel Edits Made to Package?"
        content="Would you like to cancel editing the package? Any changes you have made will not be saved."
        onConfirm={onCancelEditMode}
        confirmButtonTitle="Cancel Edits"
        headerIcon={`${CDN_URL}/images/cancel_subscription_confirm_icon.svg`}
        cancelButtonTitle="Continue Editing"
        newStyle
        hasCloseIcon
      />,
    );
    return false;
  };

  const handlePreview = () => {
    props.onPreview();
  };

  const handlePublic = () => {
    const { permission, onSetIsConfirmLeavePage, onSaveDraftUpgrade } = props;
    if (permission.payment) onPublish();
    else {
      onSetIsConfirmLeavePage(true);
      props.toggleModal(true, <PackagePublishFallbackPopup onSaveDraftUpgrade={onSaveDraftUpgrade} />);
    }
  };

  return (
    <S.Wrapper>
      {status === PACKAGE_STATUS.PUBLISH ? (
        <>
          <IconButton icon={<PreviewIcon />} tooltip="Preview" tooltipKey="preview_package" onClick={handlePreview} />
          {!isMP ? (
            <CopyPackage isMP={isMP} packageId={workingPackage.get('hash_id') || workingPackage.get('id')} />
          ) : null}
        </>
      ) : (
        <Button disabled={isSubmitting} className="packageActions__saveBtn" onClick={handlePreview}>
          <PreviewIcon />
          Preview
        </Button>
      )}
      {!isEditMode && (
        <Button disabled={isSubmitting} className="packageActions__saveBtn" onClick={onEdit}>
          <EditIcon />
          Edit
        </Button>
      )}
      {status !== PACKAGE_STATUS.PUBLISH && isEditMode && (
        <Button disabled={isSubmitting} className="packageActions__saveBtn" onClick={onSave}>
          <SaveIcon />
          Save
        </Button>
      )}
      {status === PACKAGE_STATUS.PUBLISH && isEditMode && (
        <Button disabled={isSubmitting} className="packageActions__saveBtn" onClick={cancelEditWarningModal}>
          Cancel Edit
        </Button>
      )}
      {status === PACKAGE_STATUS.PUBLISH && isEditMode && (
        <Button disabled={isSubmitting} purple className="packageActions__publishChangeBtn" onClick={onPublish}>
          <SaveIcon />
          Publish Changes
        </Button>
      )}
      {status === PACKAGE_STATUS.PUBLISH && !isEditMode && (
        <S.PublishButton disabled={isSubmitting} isPublished onClick={handleUnpublish}>
          Unpublish
        </S.PublishButton>
      )}
      {status !== PACKAGE_STATUS.PUBLISH && (
        <>
          <S.PublishButton
            disabled={isSubmitting || status === PACKAGE_STATUS.ARCHIVE || (isMP && !isUS)}
            isPublished={false}
            onClick={handlePublic}
            data-tip
            data-for="button-publish-tooltip"
          >
            Publish Package
          </S.PublishButton>
          {isMP && !isUS && (
            <ReactTooltip
              className="app-tooltip"
              id="button-publish-tooltip"
              effect="solid"
              place={'top'}
              delayShow={200}
            >
              <p>Not available outside US</p>
            </ReactTooltip>
          )}
        </>
      )}
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: { permission, packageDetail, setupPayment },
    user,
  } = state;

  return {
    permission,
    workingPackage: packageDetail.get('workingPackage'),
    isEditMode: packageDetail.get('isEditMode'),
    isSubmitting: packageDetail.get('isSubmitting'),
    payment: setupPayment.toJS(),
    user,
  };
};

const actionCreators = {
  enableEditMode,
  unpublishPackage,
  publishPackage,
  cancelEditMode,
  toggleConfirmModal,
  toggleModal,
};

export default connect(mapState, actionCreators)(PackageActions);
