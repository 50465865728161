import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as S from '../style';
import { CLIENT_STATUS } from 'constants/commonData';
import { activeWaitingSegment } from 'redux/segment/actions';
import {
  getWaitingClients,
  sortWaitingClients,
  resetWaitingClientsData,
  toggleNewWaitingClientFlag,
} from 'redux/waiting-activation-clients/actions';

import { ReactComponent as CustomerIcon } from 'assets/icons/person.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/status.svg';
import { ReactComponent as CoachIcon } from 'assets/icons/owner_head.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';

const TableHeader = props => {
  const { sort, sorter, currentSegment, segments } = props;

  const updatedCurrentSegment = useRef(false);

  useEffect(() => {
    props.toggleNewWaitingClientFlag(false);

    return () => {
      props.resetWaitingClientsData();
    };
  }, []);

  useEffect(() => {
    if (segments) {
      const waitingActivationSegment = _.find(segments, item => item.client_connection === CLIENT_STATUS.waiting);

      if (waitingActivationSegment && !updatedCurrentSegment.current) {
        if (currentSegment !== waitingActivationSegment) {
          updatedCurrentSegment.current = true;
          props.activeWaitingSegment(waitingActivationSegment._id);
          props.getWaitingClients({ segmentId: waitingActivationSegment._id });
        } else {
          updatedCurrentSegment.current = true;
        }
      }
    }
  }, [currentSegment, segments]);

  const renderSortIcon = key => {
    if (key === sorter) {
      return sort === 1 ? <ArrowDown /> : <ArrowUp />;
    }

    return <ArrowDown />;
  };

  const handleSort = value => () => {
    props.sortWaitingClients({ sorter: value, sort: value === sorter ? -1 * sort : 1 });
  };

  return (
    <S.TableHeader>
      <S.TableRow>
        <S.TableCellHeader className="customer" isSorting={sorter === 'full_name'} sortable>
          <S.TableCellContent onClick={handleSort('full_name')}>
            <CustomerIcon />
            <span>Customer</span>
            {renderSortIcon('full_name')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader isSorting={sorter === 'createdAt'} sortable>
          <S.TableCellContent onClick={handleSort('createdAt')}>
            <CalendarIcon />
            <span>Sign Up Date</span>
            {renderSortIcon('createdAt')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader>
          <S.TableCellContent>
            <StatusIcon />
            <span>Status</span>
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader isSorting={sorter === 'trainer_full_name'} sortable>
          <S.TableCellContent onClick={handleSort('trainer_full_name')}>
            <CoachIcon />
            <span>Coach</span>
            {renderSortIcon('trainer_full_name')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader className="actions"></S.TableCellHeader>
      </S.TableRow>
    </S.TableHeader>
  );
};

const mapState = state => {
  const {
    rootReducer: { waitingActivationClients, segment },
  } = state;
  return {
    sort: waitingActivationClients.get('sort'),
    sorter: waitingActivationClients.get('sorter'),
    currentSegment: segment.selected,
    segments: segment.list,
  };
};

export default connect(mapState, {
  activeWaitingSegment,
  getWaitingClients,
  sortWaitingClients,
  resetWaitingClientsData,
  toggleNewWaitingClientFlag,
})(TableHeader);
