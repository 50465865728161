import styled, { css } from 'styled-components';

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
  grid-gap: 20px;
  padding-bottom: 10px;
  margin-bottom: 50px;
  position: relative;

  ${props =>
    props.isLoading &&
    css`
      height: 363px;
    `}

  ${props =>
    props.isScroll &&
    css`
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-auto-flow: column;
      overflow-x: auto;
    `}

  ::-webkit-scrollbar {
    height: 5px !important;
  }
`;

export const NoResult = styled.div`
  margin-top: 65px;
  text-align: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  opacity: 0.4;
`;

export const Wrapper = styled.div`
  flex: 1 1;
  overflow: auto;
  padding: 0 30px 50px;

  .resourceList__heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    margin-bottom: 15px;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loading--resources {
    padding-top: unset;
  }
`;
