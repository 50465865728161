import React from 'react';

import { PackageListNavArea, PackageListUpgradePathText, PackageListUpgradePathLink, Label } from './style';
import UpgradePathIcon from 'shared/UpgradePathIcon';

const PackageListNavTitle = props => {
  const { permission, isMP } = props;

  const textComponent = () => {
    return (
      <PackageListUpgradePathLink
        text={
          <>
            <UpgradePathIcon />
            <Label>Upgrade to get Payment</Label>
          </>
        }
      />
    );
  };

  return (
    <PackageListNavArea>
      <span>{isMP ? 'Marketplace ' : ''}Packages List</span>
      {!permission.payment && <PackageListUpgradePathText>{textComponent()}</PackageListUpgradePathText>}
    </PackageListNavArea>
  );
};

export default PackageListNavTitle;
