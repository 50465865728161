import _ from 'lodash';
import { Types } from './client.actionCreators';
import { getAvailableMetrics } from 'utils/commonFunction';
import { Types as TypesGroups } from '../group/actions';

// *** IMPORTANT ****
// Owner ship value:
// '' = You
// null = Any user
// [...] = Only certain users

export const DEFAULT_STATE = {
  page: 1,
  per_page: 20,
  search: '',
  sorter: 'full_name_sort',
  sort: 1,
  total: 0,
  total_except_search: 0,
  all_clients_total: 0,
  total_active_clients: 0,
  list: [],
  selected: '',
  isRequesting: false,
  isShowOwnedClient: false,
  isGettingClients: false,
  newestClients: [],
  workingClientDetail: null,
  assignedPrograms: [],
  bodymetricTypes: [],
  bodymetricAllSettings: [],
  isConnected3rdParty: false,
  subCoach: {
    data: [],
    loading: false,
  },
  isUploading: false,
};

const updateClientSettings = (oldState, payload) => {
  const { workingClientDetail, list } = oldState;
  const { feature_preferences, client } = payload;

  return Object.assign({}, oldState, {
    list: _.map(list, item => ({
      ...item,
      feature_preferences: item._id === client ? feature_preferences : item.feature_preferences,
    })),
    workingClientDetail: workingClientDetail ? { ...workingClientDetail, feature_preferences } : workingClientDetail,
  });
};

const ClientReducer = (state = DEFAULT_STATE, action) => {
  let list_client = state.list.slice();
  let clients, workingClientDetail;
  const { payload } = action;

  switch (action.type) {
    case 'SELECT_CLIENT':
      return Object.assign({}, state, {
        selected: action.payload.client,
      });

    case Types.CLIENT_REQUEST_GET_METRICS: {
      return Object.assign({}, state, { bodymetricTypes: [] });
    }

    case Types.CLIENT_REQUEST_UPDATE_MULTIPLE_METRIC: {
      return Object.assign({}, state, { isRequesting: true });
    }

    case Types.CLIENT_SUCCESS_GET_METRICS:
    case Types.CLIENT_SUCCESS_UPDATE_METRIC:
    case Types.CLIENT_SUCCESS_UPDATE_MULTIPLE_METRIC:
    case Types.CLIENT_SUCCESS_ADD_METRIC:
    case Types.CLIENT_SUCCESS_DELETE_METRIC:
    case Types.CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRIES:
    case Types.CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRY:
    case Types.CLIENT_SUCCESS_UPDATE_BODY_METRIC_ENTRY:
    case Types.CLIENT_SUCCESS_REMOVE_BODY_METRIC_ENTRY:
      return Object.assign({}, state, { bodymetricTypes: getAvailableMetrics(payload.data), isRequesting: false });
    case Types.CLIENT_SUCCESS_GET_METRICS_SETTING_ALL:
      return Object.assign({}, state, { bodymetricAllSettings: payload.data, isRequesting: false });

    case 'IS_GETTING_CLIENTS':
      return Object.assign({}, state, {
        isGettingClients: true,
      });

    case 'SUCCESS_GET_CLIENTS_SIMPLE':
    case 'SUCCESS_GET_CLIENTS_FULL':
      return Object.assign({}, state, {
        page: parseInt(action.payload.page),
        per_page: parseInt(action.payload.per_page),
        search: action.payload.search || '',
        sorter: action.payload.sorter || null,
        sort: action.payload.sort ? parseInt(action.payload.sort) : 1,
        total: parseInt(action.payload.total),
        total_except_search: parseInt(action.payload.total_except_search),
        all_clients_total: parseInt(action.payload.all_clients_total),
        total_active_clients: parseInt(action.payload.total_active_clients),
        list: action.payload.data,
        isGettingClients: false,
      });

    case 'SETTING_CLIENT_FEATURE':
      workingClientDetail = _.cloneDeep(state.workingClientDetail);

      if (workingClientDetail) {
        _.forEach(workingClientDetail.feature_preferences, item => {
          if (item.type === action.payload.type) {
            item.state = action.payload.state;
            return false;
          }
        });
      }

      return Object.assign({}, state, { workingClientDetail });

    case 'SETTING_CLIENT_ALL_FEATURE':
      return updateClientSettings(state, action.payload);

    case Types.GET_CLIENT_DATA:
      return Object.assign({}, state, {
        workingClientDetail: null,
        assignedPrograms: [],
        bodymetricTypes: [],
        isConnected3rdParty: false,
      });

    case Types.UPDATE_CLIENT_PROFILE_SUCCESS:
    case Types.SUCCESS_GET_CLIENT_PROFILE:
      return Object.assign({}, state, {
        workingClientDetail: Object.assign({}, state.workingClientDetail, action.payload.client),
      });
    case Types.UPDATE_CLIENT_CONNECTED_INTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        isConnected3rdParty: payload.isConnected,
      });

    case Types.UPDATE_CLIENT_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isUploading: true,
      });

    case Types.UPDATE_CLIENT_PROFILE_SUCCESS_LOAD_IMAGE:
      return Object.assign({}, state, {
        isUploading: false,
      });

    case Types.RESET_WORKING_CLIENT_DATA:
      return Object.assign({}, state, {
        workingClientDetail: null,
        selected: null,
        assignedPrograms: [],
        bodymetricTypes: [],
      });

    case Types.DELETE_MULTIPLE_CLIENTS:
    case Types.DELETE_CLIENT_SUCCESS:
      return Object.assign({}, state, {
        workingClientDetail: null,
      });

    case Types.UPDATE_CLIENT_AVATAR_SUCCESS:
      return !state.workingClientDetail
        ? state
        : Object.assign({}, state, {
            workingClientDetail: {
              ...state.workingClientDetail,
              avatar: action.avatar,
            },
          });
    case 'UPDATE_CLIENT_FAVORITE':
      return !state.workingClientDetail
        ? state
        : Object.assign({}, state, {
            workingClientDetail: {
              ...state.workingClientDetail,
              favorited: action.payload.isStar,
            },
          });
    case 'UPDATE_REDUX_CLIENT_UNIT':
      return !state.workingClientDetail
        ? state
        : Object.assign({}, state, {
            workingClientDetail: {
              ...state.workingClientDetail,
              preferences: action.payload.unit,
            },
          });
    case 'LOGOUT_USER':
      return Object.assign({}, state, {
        list: [],
        selected: '',
      });
    case 'IS_SHOW_OWNED_CLIENT':
      return Object.assign({}, state, {
        isShowOwnedClient: action.isShowOwnedClient,
      });

    case Types.INVITE_TO_CONNECT_SUCCESS:
      _.forEach(list_client, item => {
        if (item._id === action.data.clientId) {
          item.email = action.data.email;
          item.client_connection = 0;
          return false;
        }
      });

      return Object.assign({}, state, {
        list: list_client,
        workingClientDetail: state.workingClientDetail
          ? { ...state.workingClientDetail, email: action.data.email, client_connection: 0 }
          : state.workingClientDetail,
      });

    case Types.SUCCESS_ARCHIVE_CLIENTS:
      clients = action.payload.clients;

      _.forEach(list_client, item => {
        if (clients.includes(item._id)) {
          item.client_connection = -2;
          item.checked = false;
        }
      });

      return Object.assign({}, state, { list: list_client });

    case Types.SUCCESS_ADD_NEW_CLIENT:
      list_client.push(action.payload.client);

      return Object.assign({}, state, { list: list_client });

    case Types.SEARCH_CLIENTS:
      return Object.assign({}, state, { search: action.payload.search });

    case Types.CLIENT_GET_ASSIGNED_PROGRAMS:
      return Object.assign({}, state, { assignedPrograms: action.payload.programs });

    case Types.CLIENT_REMOVE_ASSIGNED_PROGRAM:
      return Object.assign({}, state, {
        assignedPrograms: _.filter(state.assignedPrograms, item => item._id !== action.payload.assignId),
      });

    case 'UPDATE_CLIENT_INFO':
      if (state.workingClientDetail && state.workingClientDetail._id === action.payload.data._id) {
        return Object.assign({}, state, {
          workingClientDetail: {
            ...state.workingClientDetail,
            timezone: action.payload.data.timezone,
            date_format: action.payload.data.date_format,
          },
        });
      }

      return state;

    case Types.SUCCESS_ARCHIVE_ONE_CLIENT:
      if (state.workingClientDetail && state.workingClientDetail._id === action.payload.clientId) {
        return Object.assign({}, state, {
          workingClientDetail: { ...state.workingClientDetail, is_archived: true },
        });
      }

      return state;

    case Types.SUCCESS_GET_NEWEST_CLIENTS:
      return Object.assign({}, state, { newestClients: action.payload.data });

    case Types.CLIENT_SET_TOTAL_ACTIVE_CLIENTS:
      return Object.assign({}, state, { total_active_clients: action.payload });

    case Types.CLIENT_CONSULTATION_FILE_DELETE_SUCCESS:
      return Object.assign({}, state, {
        workingClientDetail: { ...state.workingClientDetail, consultation_documents: [] },
      });

    case Types.CLIENT_CONSULTATION_FILE_UPLOAD_SUCCESS:
      const consult_doc_arr = _.get(state, 'workingClientDetail.consultation_documents', []);
      const new_consult_doc_arr = _.concat(consult_doc_arr, { document_url: action.payload });
      return Object.assign({}, state, {
        workingClientDetail: { ...state.workingClientDetail, consultation_documents: new_consult_doc_arr },
      });

    // === SUB COACH ===

    // GET LIST
    case Types.GET_SUB_COACH_REQUEST: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: true },
      });
    }
    case Types.GET_SUB_COACH_SUCCESS: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: false, data: payload },
      });
    }
    case Types.GET_SUB_COACH_FAIL: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: false },
      });
    }

    // ADD
    case Types.ADD_SUB_COACH_REQUEST: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: true },
      });
    }
    case Types.ADD_SUB_COACH_SUCCESS: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: false, data: action.payload },
      });
    }
    case Types.ADD_SUB_COACH_FAIL: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: false },
      });
    }

    // DELETE
    case Types.DELETE_SUB_COACH_REQUEST: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: true },
      });
    }
    case Types.DELETE_SUB_COACH_SUCCESS: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: false, data: action.payload },
      });
    }
    case Types.DELETE_SUB_COACH_FAILED: {
      return Object.assign({}, state, {
        subCoach: { ...state.subCoach, loading: false },
      });
    }
    case TypesGroups.SUCCESS_ADD_NEW_GROUP: {
      let newClients = state.list.slice();
      newClients = newClients.map(it => {
        if (payload.clientIds.includes(it._id)) {
          it.groups.push(payload.group._id);
          it.groups = _.uniq(it.groups);
          return it;
        }
        return it;
      });
      return Object.assign({}, state, {
        list: newClients,
      });
    }

    default:
      return state;
  }
};

export default ClientReducer;
