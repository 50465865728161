import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { MODES } from 'components/ExerciseForm/component';
import ExerciseForm from 'components/ExerciseForm';
import ExerciseItem from './ExerciseItem';
import { AddExerciseButton } from './style';
import ExerciseLoader from './LoadingItem';

import { ReactComponent as NoResultFound } from 'assets/icons/workout_builder_no_result_found.svg';

const ExerciseList = ({ displayType, textSearch, listItem, isLoading, isSearching, loadMore, page, onAddExercise }) => {
  const [addingExercise, setAddingExercise] = useState();
  const [element, setElement] = useState(null);
  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const firstEntry = entries[0];

        if (firstEntry.isIntersecting && !isLoading && !isSearching) {
          handleLoadMore(); // 0.5 sec delay
        }
      },
      { threshold: 0.5 },
    ),
  );

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  const handleLoadMore = () => {
    loadMore(page + 1);
  };

  const openAddNewExerciseModal = () => {
    setAddingExercise(true);
  };

  const onCloseExercisePopup = () => {
    setAddingExercise(false);
  };

  const onAddExerciseSuccess = () => {
    setAddingExercise(false);
    onAddExercise();
  };

  return (
    <div className={classnames('workout-left-panel-content-listing', { 'list-view': displayType === 'list' })}>
      {isSearching && (
        <>
          <ExerciseLoader displayType={displayType} />
          <ExerciseLoader displayType={displayType} />
          <ExerciseLoader displayType={displayType} />
          <ExerciseLoader displayType={displayType} />
          <ExerciseLoader displayType={displayType} />
          <ExerciseLoader displayType={displayType} />
        </>
      )}
      {listItem.length === 0 && !isSearching && (
        <div className="no-result-section">
          <NoResultFound />
          <div className="title">No result found</div>
          <AddExerciseButton onClick={openAddNewExerciseModal}>Add Exercise</AddExerciseButton>
        </div>
      )}
      {listItem.length !== 0 && !isSearching && (
        <>
          {listItem.map(exerciseId => (
            <ExerciseItem key={exerciseId} displayType={displayType} exerciseId={exerciseId} />
          ))}
          {isLoading && (
            <>
              <ExerciseLoader displayType={displayType} />
              <ExerciseLoader displayType={displayType} />
            </>
          )}
          <div ref={setElement} className="bottomSentinal"></div>
        </>
      )}
      {addingExercise ? (
        <ExerciseForm
          open={true}
          mode={MODES.CREATE}
          closePopup={onCloseExercisePopup}
          onSubmitSuccess={onAddExerciseSuccess}
          firstTitle={textSearch}
        />
      ) : null}
    </div>
  );
};

ExerciseList.propTypes = {};

export default ExerciseList;
