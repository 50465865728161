// Libs
import React, { PureComponent } from 'react';
import { Area, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Line, ReferenceLine } from 'recharts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import isEmptyLodash from 'lodash/isEmpty';
import get from 'lodash/get';

// Components
import CustomTooltipContent from './CustomTooltipContent';
import CustomLineCursor from './CustomLineCursor';
import CustomGoalDot from './CustomGoal';
import CustomDot from './CustomDot';
import MetricChartCompare from './MetricChartCompare';
import CustomLineBar from './CustomLineBar';
import LoadingIndicator from 'shared/LoadingIndicator';
import CustomizedAxisTick from './CustomizedAxisTick';
import CustomAvgLabel from './CustomAvgLabel';

// Actions
import { filterChartViewMode, toggleComparisonMode, updateLoadingCompareChart } from 'actions/bodyMetric';

// Constants
import { convertUnit } from 'utils/commonFunction';
import {
  CHART_COLORS,
  CHART_LABEL_POSITION,
  CHART_TOOLTIP_POSITION,
  DATE_FORMAT,
  HOUR_LABELS,
  KEYS_METRIC,
  PERIOD_GROUP,
  TIME_UNITS,
  getTimeRangeUnitFromRange,
  initChartDataEmpty,
} from '../constants';
import {
  convertDataToUnit,
  fakeChartDataWithGoal,
  fillChartDataByPeriodTime,
  filledComparePercentage,
  filledPrevNextValues,
  formatWeeklyLabel,
  getDataMax,
  getDataMin,
  getMinMaxValue,
  getNewDataMinMax,
  getSizeOfColumn,
  getTicks,
  getXAxisInterval,
  getXLabels,
  handleShowCursor,
  handleUnitFormat,
  updateTooltipPosition,
} from '../chartHelper';

// Assets
import { ReactComponent as ChartBarIcon } from 'assets/icons/bar-chart-2.svg';
import { ReactComponent as ArrowNextIcon } from 'assets/icons/arrow-circle.svg';
import { ReactComponent as ChartLineIcon } from 'assets/icons/line-chart-2.svg';

// Styles
import * as S from './styles';
import './style.scss';

const HEIGHT_TOOLTIP = 75;
const POSITION_TOOLTIP_BOTTOM = 120;

// chartData: {time, value, goal}[]
class MetricChart extends PureComponent {
  constructor(props) {
    super(props);
    this.areaRef = null;
    this.chartRef = null;
    this.chartWrapperRef = React.createRef();
    this.state = {
      tooltipPos: { x: undefined, y: undefined },
      filledData: [],
      isNoDataButHasGoal: false,
      goalValue: 0,
      barWidth: 20,
      xInterval: 0,
      compareChartData: [],
      isHoveringChart: false,
      isHoveringCompareChart: false,
      chartCompareColor: '',
      chartBarData: {},
      isFirstTime: true,
      showGoalKeyMetric: false,
      showCurrentKeyMetric: false,
      showStartingKeyMetric: false,
      showAverageKeyMetric: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { bodyMetric, isGroupOverview, isOverview, isDisplayDataPoints } = this.props;
    const { metricSettings, isModeCompare } = bodyMetric || {};

    if (get(prevProps, 'bodyMetric.isGettingSetting') !== get(bodyMetric, 'isGettingSetting')) {
      this.setState({ isFirstTime: false });
    }

    if (get(prevProps, 'bodyMetric.metricSettings.key_metrics') !== get(metricSettings, 'key_metrics')) {
      this.getValuesKeyMetric();
    }

    if ((isModeCompare || isDisplayDataPoints) && !isGroupOverview && !isOverview) {
      this.moveEleTooltip();
    }
    if (!isGroupOverview && !isOverview) {
      handleShowCursor();
    }
  }

  componentDidMount() {
    const { toggleComparisonMode, isGroupOverview, isOverview, bodyMetric, isDisplayDataPoints } = this.props;
    const { isModeCompare } = bodyMetric || {};

    typeof toggleComparisonMode === 'function' && toggleComparisonMode(false, {});
    this.getValuesKeyMetric();

    if ((isModeCompare || isDisplayDataPoints) && !isGroupOverview && !isOverview) {
      this.moveEleTooltip();
    }

    if (!isGroupOverview && !isOverview) {
      handleShowCursor();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      chartData,
      rangeTime,
      selectedUnit,
      target,
      isOverview,
      isDoubleChart,
      isGroupOverview,
      chartType = 'line',
      bodyMetric,
      previousItem,
      nextItem,
    } = nextProps;
    const { isModeCompare, metricSettings } = bodyMetric || {};
    const { data_point } = metricSettings || {};

    const isEmpty = chartData.length === 0;
    const isConnectChart = get(previousItem, 'value') && get(nextItem, 'value');
    const isHasConnect = get(previousItem, 'value') || get(nextItem, 'value');
    const isShowOnChartDetail = !(isGroupOverview || isOverview) && !isModeCompare;
    const isChartLine = chartType !== 'bar';
    const isNoDataButHasGoal = isEmpty && !!target && target.value > 0;
    const enableConnectChart = isHasConnect && isShowOnChartDetail;

    const filledData =
      isEmpty && isConnectChart && isShowOnChartDetail
        ? fillChartDataByPeriodTime(initChartDataEmpty(rangeTime.to_date), rangeTime)
        : fillChartDataByPeriodTime(chartData, rangeTime);
    const goalValue = this.convertGoalValueToUnit(nextProps);
    const convertChartData = isNoDataButHasGoal
      ? fakeChartDataWithGoal(goalValue, filledData)
      : convertDataToUnit(selectedUnit, filledData, goalValue);

    const filledComparePercentageData = filledComparePercentage(convertChartData, data_point);

    const filledPrevNextValue =
      ((enableConnectChart && !isEmpty) || (isConnectChart && isShowOnChartDetail && isEmpty)) && isChartLine
        ? filledPrevNextValues(filledComparePercentageData, get(previousItem, 'value'), get(nextItem, 'value'))
        : filledComparePercentageData;

    const barWidth = isChartLine
      ? 20
      : getSizeOfColumn(filledPrevNextValue, isOverview || isGroupOverview, isDoubleChart);
    const xInterval = getXAxisInterval(rangeTime, isGroupOverview, isOverview, isDoubleChart, isChartLine);

    this.setState({ filledData: filledPrevNextValue, goalValue, barWidth, xInterval, chartBarData: {} });
  }

  updateAreaRef = r => {
    this.areaRef = r || this.areaRef;
  };

  updateTooltipBarChart = data => {
    const { showCurrentKeyMetric, showGoalKeyMetric, showStartingKeyMetric, barWidth } = this.state;
    const { bodyMetric, isOverview, isGroupOverview, chartType = 'line' } = this.props;
    const { isModeCompare } = bodyMetric || {};
    const isChartOverview = isGroupOverview || isOverview;

    const tooltipPos = updateTooltipPosition(
      this.chartRef,
      this.chartId,
      data,
      isModeCompare,
      isChartOverview,
      chartType === 'bar' ? barWidth : 0,
    );
    const { x = 0, y = 0 } = tooltipPos || {};

    const shouldShowTooltipAtBottom = !isGroupOverview && !isOverview && this.shouldShowTooltipBottom(chartType, data);

    if (!isModeCompare && !showCurrentKeyMetric && !showGoalKeyMetric && !showStartingKeyMetric) {
      this.setState({ tooltipPos: { x, y: shouldShowTooltipAtBottom ? y + POSITION_TOOLTIP_BOTTOM : y } });
    } else {
      this.setState({ tooltipPos });
    }
  };

  shouldShowTooltipBottom = (chartType, data) => {
    const chartWrapperElement = this.chartWrapperRef && this.chartWrapperRef.current;
    const chartWrapperRect = chartWrapperElement && chartWrapperElement.getBoundingClientRect();
    const tooltipElement =
      chartType === 'line'
        ? document.querySelector('.recharts-tooltip-cursor')
        : document.querySelector('.recharts-tooltip-wrapper');
    const tooltipRect = tooltipElement && tooltipElement.getBoundingClientRect();

    if (tooltipRect && chartWrapperRect && chartType === 'line') {
      const tooltipTop = tooltipRect.top;
      const chartWrapperTop = chartWrapperRect.top + HEIGHT_TOOLTIP;
      if (tooltipTop < chartWrapperTop) {
        return true;
      }
    } else if (tooltipRect && chartWrapperRect && chartType !== 'line') {
      const tooltipRectHeight = tooltipRect.height;
      const chartHeight = !!data ? data.height : 0;
      const chartWrapperHeight = chartWrapperRect.height;
      const tooltipTop = chartHeight + tooltipRectHeight + 20; // 20: This is gap between tooltip and chart
      if (tooltipTop > chartWrapperHeight) {
        return true;
      }
    }
    return false;
  };

  convertGoalValueToUnit = props => {
    const { metricTypes, bodyMetric, selectedUnit, target } = props;
    if (!target || target.value === 0 || target.value === null) return null;

    const type = metricTypes.find(item => item._id === bodyMetric.mid) || {};
    return +convertUnit(target.value, type.unit, selectedUnit).toFixed(2);
  };

  handleTickFormat = value => {
    const { filledData, goalValue, showGoalKeyMetric } = this.state;
    const { selectedUnit, chartData, previousItem, nextItem, isGroupOverview, isOverview, bodyMetric } = this.props;
    const { isModeCompare } = bodyMetric || {};

    const isEmpty = chartData.length === 0;
    const isConnectChart = get(previousItem, 'value') && get(nextItem, 'value');
    const isHasConnect = get(previousItem, 'value') || get(nextItem, 'value');
    const enableConnectChart = isHasConnect && !(isGroupOverview || isOverview) && !isModeCompare;

    const { min, max } = getMinMaxValue(filledData, enableConnectChart);

    const dataMin = getDataMin(min, max, showGoalKeyMetric ? goalValue : 0);
    const dataMax = getDataMax(min, max, showGoalKeyMetric ? goalValue : 0);

    const arrCheckMin = [get(previousItem, 'value'), get(nextItem, 'value')].filter(value => value !== undefined);
    const arrCheckMax = [get(previousItem, 'value'), get(nextItem, 'value')].filter(value => value !== undefined);

    const isSecOrMinUnit = TIME_UNITS.includes(get(selectedUnit, 'unique_code', ''));
    const isConvertValue = isSecOrMinUnit && max >= 60;

    const [newDataMin] = getNewDataMinMax(
      isEmpty && isConnectChart
        ? showGoalKeyMetric && goalValue !== null && goalValue !== 0
          ? Math.min(...[...arrCheckMin, goalValue])
          : Math.min(...arrCheckMin)
        : dataMin,
      isEmpty && isConnectChart
        ? showGoalKeyMetric && goalValue !== null && goalValue !== 0
          ? Math.max(...[...arrCheckMax, goalValue])
          : Math.max(...arrCheckMax)
        : dataMax,
      isSecOrMinUnit,
    );

    return handleUnitFormat(selectedUnit, value, newDataMin, !isConvertValue);
  };

  formatXAxisLabel = label => {
    const {
      rangeTime: { period_group },
    } = this.props;
    const { xInterval, filledData } = this.state;
    const labels = period_group === PERIOD_GROUP.HOURLY ? HOUR_LABELS : getXLabels(filledData, xInterval);

    if (labels.includes(label)) {
      return formatWeeklyLabel(label, period_group);
    }
    return '';
  };

  tooltipActiveCallback = active => {
    if (this.props.chartType === 'line' && this.state.tooltipActive !== active) {
      this.setState({ tooltipActive: active });
    }
  };

  handleHoverChart = ({ status = false, data = {} }) => {
    const { bodyMetric: { isModeCompare } = {} } = this.props;

    this.setState({ isHoveringChart: status, chartBarData: { ...data, isMetricChartBar: !isModeCompare } });
  };

  getValuesKeyMetric = () => {
    const { bodyMetric = {} } = this.props;

    const [
      showCurrentKeyMetric,
      showAverageKeyMetric,
      showGoalKeyMetric,
      showStartingKeyMetric,
    ] = KEYS_METRIC.map(item => get(bodyMetric, 'metricSettings.key_metrics', []).includes(item.key));

    this.setState({
      showCurrentKeyMetric,
      showAverageKeyMetric,
      showGoalKeyMetric,
      showStartingKeyMetric,
    });
  };

  moveEleTooltip = () => {
    const { chartType = 'line' } = this.props;

    const svgEle = document.querySelector('.metric-chart .recharts-surface-default .recharts-surface');
    const svgTooltip = document.querySelector('.metric-chart .recharts-surface-default .recharts-tooltip-cursor');
    const isChartLine = chartType === 'line';

    if (svgEle && svgTooltip && isChartLine) {
      svgEle.insertBefore(svgTooltip, null);
    }
  };

  renderChart = () => {
    const {
      chartData,
      chartType = 'line',
      color = CHART_COLORS[0],
      width = 500,
      height = 400,
      isOverview = false,
      bodyMetric,
      isGroupOverview,
      selectedUnit,
      isLoading,
      rangeTime: { period_group },
      isDisplayDataPoints,
      previousItem,
      nextItem,
    } = this.props;
    const {
      filledData,
      barWidth,
      goalValue,
      tooltipPos,
      xInterval,
      tooltipActive,
      compareChartData,
      chartCompareColor,
      chartBarData,
      isHoveringChart,
      isHoveringCompareChart,
      showGoalKeyMetric,
      showAverageKeyMetric,
    } = this.state;
    const { title = '', unique_code = '' } = selectedUnit || {};
    const { isModeCompare, name = '', unit = '', average, isGettingSetting, _id: bId, mid } = bodyMetric || {};
    const _id = bId || mid;

    const bgColorId = `bgColor${_id}`;
    const isChartLine = chartType === 'line';
    const throttleDelay = period_group === PERIOD_GROUP.MONTHLY ? 300 : 100;
    this.chartId = `metric-chart-tooltip-customized-${_id}`;

    const isEmpty = chartData.length === 0;
    const isConnectChart = get(previousItem, 'value') && get(nextItem, 'value');
    const isHasConnect = get(previousItem, 'value') || get(nextItem, 'value');
    const enableConnectChart = isHasConnect && !(isGroupOverview || isOverview) && !isModeCompare;

    const { min, max } = getMinMaxValue(filledData, enableConnectChart);
    const dataMin = getDataMin(min, max, showGoalKeyMetric ? goalValue : 0);
    const dataMax = getDataMax(min, max, showGoalKeyMetric ? goalValue : 0);

    const isSecOrMinUnit = TIME_UNITS.includes(unique_code);

    const arrCheckMin = [get(previousItem, 'value'), get(nextItem, 'value')].filter(value => value !== undefined);
    const arrCheckMax = [get(previousItem, 'value'), get(nextItem, 'value')].filter(value => value !== undefined);

    const [newDataMin, newDataMax, tickCount] = getNewDataMinMax(
      isEmpty && isConnectChart
        ? showGoalKeyMetric && goalValue !== null && goalValue !== 0
          ? Math.min(...[...arrCheckMin, goalValue])
          : Math.min(...arrCheckMin)
        : dataMin,
      isEmpty && isConnectChart
        ? showGoalKeyMetric && goalValue !== null && goalValue !== 0
          ? Math.max(...[...arrCheckMax, goalValue])
          : Math.max(...arrCheckMax)
        : dataMax,
      isSecOrMinUnit,
    );

    const domain = !isEmpty || (enableConnectChart && isConnectChart) ? [newDataMin, newDataMax] : undefined;

    const ticks =
      !isEmpty || (enableConnectChart && isConnectChart) ? getTicks(newDataMin, newDataMax, tickCount) : undefined;

    return (
      <ResponsiveContainer
        width="100%"
        height={isModeCompare ? 200 : '100%'}
        className={classNames('metric-chart', { 'no-data': isEmpty && !isLoading, 'chart-line': isChartLine })}
      >
        {!isLoading && (
          <ComposedChart
            width={width}
            height={height}
            data={filledData}
            ref={r => (this.chartRef = r)}
            syncId={isModeCompare ? 'sync-compare-chart' : undefined}
            throttleDelay={throttleDelay}
            onMouseOver={() => {
              if (chartData.length !== 1) return;
              if (isChartLine) {
                this.handleHoverChart({ status: true });
              }
            }}
            onMouseOut={() => {
              if (chartData.length !== 1) return;
              if (isChartLine) {
                this.handleHoverChart({ status: false });
              }
            }}
            className="recharts-surface-default"
          >
            <XAxis
              dataKey="time"
              padding={{
                left: isGroupOverview || isOverview ? 5 : isChartLine ? 15 : 10,
                right: 5,
              }}
              tickFormatter={xInterval === 0 ? lab => formatWeeklyLabel(lab, period_group) : this.formatXAxisLabel}
              interval={0}
              dy={5}
              tickLine={false}
              width={10}
              className={isEmpty && !isLoading && `customized-xAxis-${_id}`}
              tick={
                <CustomizedAxisTick
                  filledData={filledData}
                  xInterval={xInterval}
                  period_group={period_group}
                  bodyMetricId={_id}
                />
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ top: 10 }}
              tick={isGroupOverview || isOverview ? { dx: -2 } : {}}
              tickFormatter={this.handleTickFormat}
              label={
                isModeCompare
                  ? {
                      value: `${name} ${title || unit ? `(${title || unit})` : ''}`,
                      position: CHART_LABEL_POSITION.POSITION,
                      offset: CHART_LABEL_POSITION.OFFSET,
                      dy: CHART_LABEL_POSITION.DY,
                    }
                  : undefined
              }
              domain={domain}
              ticks={isModeCompare && isEmpty && !goalValue ? [0] : ticks}
              tickCount={tickCount}
              allowDataOverflow
            />
            <Tooltip
              cursor={
                isChartLine ? (
                  <CustomLineCursor
                    color={color}
                    lineColor={chartCompareColor}
                    isOverview={isOverview || isGroupOverview}
                    areaRef={this.areaRef}
                    updateTooltipBarChart={this.updateTooltipBarChart}
                    isModeCompare={isModeCompare && !isEmptyLodash(compareChartData)}
                    isFillTransparent={isModeCompare ? !isHoveringChart : false}
                    isHoveringChart={isHoveringChart || isHoveringCompareChart}
                    bodyMetricId={_id}
                  />
                ) : (
                  <CustomLineBar
                    color={color}
                    barWidth={barWidth}
                    chartBarData={chartBarData}
                    isFillTransparent={!isHoveringChart}
                    isModeCompare={isModeCompare && !isEmptyLodash(compareChartData)}
                  />
                )
              }
              content={
                <CustomTooltipContent
                  id={this.chartId}
                  chartData={filledData}
                  compareChartData={compareChartData}
                  tooltipActiveCallback={this.tooltipActiveCallback}
                  isModeCompare={isModeCompare && !isEmptyLodash(compareChartData)}
                  isChartLine={isChartLine}
                  chartName={name}
                  chartColor={color}
                  isHoveringChartBar={isHoveringChart}
                  isShowTooltip={isModeCompare ? isHoveringChart && !isHoveringCompareChart : true}
                />
              }
              // animationDurationNumber={100}
              isAnimationActive={false}
              animationEasing="ease-out"
              coordinate={tooltipPos}
              position={tooltipPos}
              offset={isModeCompare ? CHART_TOOLTIP_POSITION.OFFSET : undefined}
            />
            {isChartLine ? (
              <>
                <defs>
                  <linearGradient id={bgColorId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0.0115331" stopColor={color} stopOpacity={1} />
                    <stop offset="0.99" stopColor="white" />
                  </linearGradient>
                </defs>
                <Area
                  shapeRendering={'optimizeQuality'}
                  strokeWidth={2}
                  dataKey={enableConnectChart ? 'tempValue' : 'value'}
                  strokeDasharray={enableConnectChart ? '5 5' : undefined}
                  stroke={color}
                  fill={`url(#${bgColorId})`}
                  ref={this.updateAreaRef}
                  activeDot={false}
                  connectNulls
                  fillOpacity={0.1}
                  isAnimationActive={false}
                  dot={
                    (!tooltipActive && chartData.length === 1) || isDisplayDataPoints
                      ? p => (
                          <CustomDot
                            {...p}
                            color={color}
                            isReduce={isOverview || isGroupOverview}
                            isModeCompare={isModeCompare}
                            bodyMetricId={_id}
                          />
                        )
                      : undefined
                  }
                  onMouseEnter={() => {
                    if (chartData.length === 1) return;
                    this.handleHoverChart({ status: true });
                  }}
                  onMouseLeave={() => {
                    if (chartData.length === 1) return;
                    this.handleHoverChart({ status: false });
                  }}
                />
                {enableConnectChart && (
                  <Line
                    dataKey="value"
                    shapeRendering={'optimizeQuality'}
                    strokeWidth={2}
                    dot={false}
                    activeDot={false}
                    isAnimationActive={false}
                    stroke={color}
                    connectNulls
                  />
                )}
              </>
            ) : (
              <Bar
                dataKey="value"
                fill={color}
                animationDuration={500}
                barSize={barWidth}
                radius={isOverview ? [2, 2, 0, 0] : [3, 3, 0, 0]}
                onMouseOver={data => {
                  this.updateTooltipBarChart(data);
                  this.handleHoverChart({ status: true, data });
                }}
                onMouseOut={() => this.handleHoverChart({ status: false, data: {} })}
                activeBar={false}
              />
            )}
            {showGoalKeyMetric && goalValue !== null && !isGettingSetting && (
              <>
                <ReferenceLine className="custom-goal-line" y={goalValue} stroke="#a3a3b5" label={<CustomGoalDot />} />
                <ReferenceLine y={goalValue * chartData.length === 0 ? 2 : 1} stroke="#a3a3b5" opacity={0} />
                <Line dataKey="goal" opacity={0} />
              </>
            )}
            {showAverageKeyMetric && average !== null && !isGettingSetting && (
              <>
                <ReferenceLine
                  y={average}
                  stroke={color}
                  strokeDasharray="2 7"
                  label={<CustomAvgLabel color={color} />}
                  className="avg-line"
                />
                <ReferenceLine
                  y={average * chartData.length === 0 ? 2 : 1}
                  stroke={color}
                  strokeDasharray="2 7"
                  opacity={0}
                />
                <Line dataKey="average" opacity={0} />
              </>
            )}
          </ComposedChart>
        )}
      </ResponsiveContainer>
    );
  };

  handleChangeRange = isNext => () => {
    const {
      filterChartViewMode,
      filterTime: { from_date, to_date, period_group, timeRangeString },
      isLoading,
      bodyMetric,
    } = this.props;
    if (isLoading || (bodyMetric || {}).isLoadingCompareChart) return;

    const fromDateMoment = moment(from_date, DATE_FORMAT);
    const toDateMoment = moment(to_date, DATE_FORMAT);
    const diff = toDateMoment.diff(fromDateMoment, 'd') + 1;
    const timeUnit = getTimeRangeUnitFromRange(timeRangeString, diff);
    const newFromDate = isNext
      ? fromDateMoment.add(timeUnit.value, timeUnit.timeTitle)
      : fromDateMoment.subtract(timeUnit.value, timeUnit.timeTitle);
    const newToDate = isNext
      ? toDateMoment.add(timeUnit.value, timeUnit.timeTitle)
      : toDateMoment.subtract(timeUnit.value, timeUnit.timeTitle);

    filterChartViewMode(newFromDate.format(DATE_FORMAT), newToDate.format(DATE_FORMAT), period_group, timeRangeString);
  };

  handleGetCompareChartData = data => {
    this.setState({
      compareChartData: data,
    });
  };

  loadingChartCallback = loading => {
    this.props.updateLoadingCompareChart(loading);
  };

  handleHoveringCompareChart = (status, color) => {
    this.setState({ isHoveringCompareChart: status, chartCompareColor: color });
  };

  render() {
    const {
      isLoading,
      chartData,
      color,
      minHeight = 337,
      filterTime,
      isOverview,
      chartType = 'line',
      noDataLabel = 'No data available',
      bodyMetric,
      metricTypes,
      isDisplayDataPoints,
      isGroupOverview,
      previousItem,
      nextItem,
    } = this.props;
    const { filledData, isFirstTime, isHoveringChart, isNoDataButHasGoal } = this.state;
    const { isModeCompare, name = '', isGettingSetting, metricSettings, isLoadingCompareChart } = bodyMetric || {};
    const { data_point = '' } = metricSettings || {};
    const ChartIcon = chartType === 'line' ? ChartLineIcon : ChartBarIcon;
    const disabledNext = moment().diff(moment(filterTime.to_date, this.DATE_FORMAT), 'd') <= 0;
    const isChartLine = chartType === 'line';

    const isConnectChart = get(previousItem, 'value') && get(nextItem, 'value') && isChartLine;

    return (
      <S.ChartContainer>
        <S.Wrapper
          minHeight={isModeCompare ? 200 : minHeight}
          isHideYAxis={(!isConnectChart && !chartData.length) || isNoDataButHasGoal}
          isHideXAxis={isLoading || isGettingSetting}
          isOverview={isOverview}
          isModeCompare={isModeCompare}
          isOverviewAndOverviewGroup={isGroupOverview || isOverview}
          className="chart-wrapper"
          ref={this.chartWrapperRef}
        >
          {(isLoading || (isFirstTime && isGettingSetting)) && (
            <LoadingIndicator title="Loading chart..." className="loading-chart-indicator" />
          )}
          {!isOverview && (
            <>
              <button
                className="chart-arrow-btn chart-prev-btn"
                onClick={this.handleChangeRange(false)}
                disabled={isLoading || isLoadingCompareChart}
              >
                <ArrowNextIcon />
              </button>
              <button
                className="chart-arrow-btn chart-next-btn"
                onClick={this.handleChangeRange(true)}
                disabled={disabledNext || isLoading || isLoadingCompareChart}
              >
                <ArrowNextIcon />
              </button>
            </>
          )}
          {this.renderChart()}
          {isModeCompare && (
            <MetricChartCompare
              metricTypes={metricTypes}
              onGetCompareChartData={this.handleGetCompareChartData}
              onHoveringCompareChart={this.handleHoveringCompareChart}
              colorMetricChart={color}
              nameMetricChart={name}
              isMetricChartLine={isChartLine}
              compareChartData={filledData}
              chartRef={this.chartRef}
              areaRef={this.areaRef}
              isHoveringChart={isHoveringChart}
              isDisplayDataPoints={isDisplayDataPoints}
              dataPoint={data_point}
              loadingChartCallback={this.loadingChartCallback}
            />
          )}
          {!chartData.length && !isLoading && !isConnectChart && !isGettingSetting && (
            <div className="chart-no-data">
              <div className="no-data-wrapper">
                <ChartIcon width={32} />
                <p>{noDataLabel}</p>
              </div>
            </div>
          )}
        </S.Wrapper>
      </S.ChartContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  filterChartViewMode: bindActionCreators(filterChartViewMode, dispatch),
  toggleComparisonMode: bindActionCreators(toggleComparisonMode, dispatch),
  updateLoadingCompareChart: bindActionCreators(updateLoadingCompareChart, dispatch),
});

const mapState = ({ bodyMetric }) => ({
  filterTime: bodyMetric.filterTime,
  previousItem: get(bodyMetric, 'previousItem', {}),
  nextItem: get(bodyMetric, 'nextItem', {}),
});

export default connect(mapState, mapDispatchToProps)(MetricChart);
