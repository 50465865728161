import React, { PureComponent } from 'react';
import { get, debounce, omit, map, uniq, uniqBy } from 'lodash';
import * as S from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchStudioCollectionByClient,
  fetchStudioProgramByClient,
  removeStudioCollectionByClient,
  removeStudioProgramByClient,
  removeWorkoutCollectionsByClient,
  fetchOnDemandWorkoutCollectionsByClient,
} from 'redux/client-studio/actions';
import { getWorkoutCollectionList } from 'redux/workout-collection/actions';
import ClientStudioItem from './ClientStudioItem';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import AddStudioProgramClient from './AddStudioProgramClient';
import AddWorkoutCollectionsClient from './AddWorkoutCollectionsClient';
import ClientCollectionItem from './ClientCollectionItem';
import WorkoutCollectionsItem from './WorkoutCollectionsItem';
import AddCollectionArea from './AddCollectionArea';
import AddProgramArea from './AddProgramArea';
import AddWorkoutCollectionsArea from './AddWorkoutCollectionsArea';
import AddStudioCollectionClient from './AddStudioCollectionClient';
import { axiosInstance } from 'configs/request';
import UpgradePath from 'shared/UpgradePath';
import { LIMIT_RESOURCE_COLLECTION } from 'constants/commonData';

class ClientStudio extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      studioPrograms: [],
      studioCollection: [],
      workoutCollections: [],
      workoutCollectionQuery: {
        page: 1,
        perPage: 20,
      },
    };
  }

  componentDidMount() {
    const { permission, selectedClient, query } = this.props;
    const { workoutCollectionQuery, workoutCollections } = this.state;
    permission.studio_program && this.fetchStudioProgramByClient();
    permission.studio_resource_collection && this.fetchStudioCollectionByClient();
    permission.on_demand_workout &&
      this.fetchOnDemandWorkoutCollectionsByClient(
        this.handleQueryFetchWorkoutCollection({ ...workoutCollectionQuery, id: selectedClient._id }),
      );

    const excludeIds = map(workoutCollections, item => item._id) || [];
    this.props.getWorkoutCollectionList({
      excludeIds: JSON.stringify(excludeIds),
      perPage: query.perPage,
      status: 'publish',
    });
  }

  handleQueryFetchWorkoutCollection = data => {
    const { id, page, perPage } = data;
    return `${id}?page=${page}&perPage=${perPage}&status=publish`;
  };

  fetchStudioProgramByClient = () => {
    const { selectedClient } = this.props;
    this.props.fetchStudioProgramByClient(selectedClient._id).then(response => {
      const { data } = response.data;
      this.setState({ studioPrograms: data });
    });
  };

  fetchStudioCollectionByClient = () => {
    const { selectedClient } = this.props;
    this.props.fetchStudioCollectionByClient(selectedClient._id).then(response => {
      const { data } = response.data;
      this.setState({ studioCollection: data });
    });
  };

  fetchOnDemandWorkoutCollectionsByClient = (param = null) => {
    const { selectedClient } = this.props;
    const { workoutCollectionQuery, workoutCollections } = this.state;
    const query = param
      ? param
      : this.handleQueryFetchWorkoutCollection({ ...workoutCollectionQuery, id: selectedClient._id });

    this.props.fetchOnDemandWorkoutCollectionsByClient(query).then(response => {
      const { data } = response.data;
      if (data) {
        this.setState({
          workoutCollections: [...workoutCollections, ...get(data, 'list', [])],
          workoutCollectionQuery: { ...omit(data, 'list') },
        });
      }
    });
  };

  handleRemoveProgram = studioProgramId => {
    const { selectedClient } = this.props;
    const params = {
      client: selectedClient._id,
      studioProgramId,
    };
    this.props.removeStudioProgramByClient(params).then(response => {
      const { studioPrograms } = this.state;
      this.setState({ studioPrograms: studioPrograms.filter(o => o.studio_program !== studioProgramId) });
    });
  };

  handleRemoveWorkout = workoutCollectionId => {
    const { selectedClient } = this.props;
    const params = {
      clientId: selectedClient._id,
      collectionId: workoutCollectionId,
    };

    this.props.removeWorkoutCollectionsByClient(params).then(response => {
      const { workoutCollections, workoutCollectionQuery } = this.state;
      const data = workoutCollections.filter(o => o._id !== workoutCollectionId) || [];

      this.setState({
        workoutCollections: data,
        workoutCollectionQuery: { ...workoutCollectionQuery, total: data.length },
      });
    });
  };

  handleAddStudioProgramSuccess = () => {
    const { permission } = this.props;
    permission.studio_program && this.fetchStudioProgramByClient();
  };

  handleAddStudioCollectionSuccess = () => {
    this.fetchStudioCollectionByClient();
  };

  goToDetail = studioProgramId => {
    this.props.push(`/home/studio-programs/${studioProgramId}`);
  };

  handleGotoDetailWorkoutCollections = workoutCollectionId => {
    this.props.push(`/home/workout-collections/${workoutCollectionId}`);
  };

  onAddStudioProgramClient = () => {
    const { studioPrograms, checking } = this.state;
    if (checking) {
      return false;
    }
    const params = {
      search: '',
      isPublished: true,
      page: 1,
      per_page: 5,
    };
    this.setState({ checking: true });
    axiosInstance
      .post('/api/studio-program/fetch-by-trainer', params)
      .then(response => {
        const { data } = response.data;
        if (data.length) {
          this.props.toggleModal(
            true,
            <AddStudioProgramClient
              onAddSuccess={this.handleAddStudioProgramSuccess}
              studioPrograms={studioPrograms}
            />,
          );
        } else {
          this.props.push('/home/studio-programs');
        }
      })
      .finally(() => {
        this.setState({ checking: false });
      });
  };

  onAddWorkoutCollectionsClient = limit => {
    const { query, selectedClient, push } = this.props;
    const { workoutCollections, checking, workoutCollectionQuery } = this.state;
    const fetchWorkoutCollections = () => {
      // this.setState({ workoutCollections: [] });
      this.fetchOnDemandWorkoutCollectionsByClient(
        this.handleQueryFetchWorkoutCollection({
          page: 1,
          perPage: 20,
          id: selectedClient._id,
          status: 'publish',
        }),
      );
    };

    if (checking) {
      return false;
    }

    this.setState({ checking: true });
    const excludeIds = map(workoutCollections, item => item._id) || [];

    this.props.getWorkoutCollectionList({
      excludeIds: JSON.stringify(uniq(excludeIds)),
      perPage: query.perPage,
      status: 'publish',
    });

    if (limit > 0 || get(workoutCollectionQuery, 'total') > 0) {
      this.props.toggleModal(
        true,
        <AddWorkoutCollectionsClient
          excludeIds={JSON.stringify(uniq(excludeIds))}
          onAddSuccess={this.handleAddStudioProgramSuccess}
          onFetchOnDemandWorkoutCollectionsByClient={fetchWorkoutCollections}
        />,
      );
      this.setState({ checking: false });
    } else {
      push('/home/workout-collections');
    }
  };

  getSelectedIds = () => {
    const { studioCollection } = this.state;
    const selectedIds = studioCollection.reduce((filtered, option) => {
      filtered.push(option._id);
      return filtered;
    }, []);
    return selectedIds;
  };

  onSelectCollection = () => {
    const { checking, studioCollection } = this.state;
    if (checking) {
      return false;
    }
    const params = {
      search: '',
      isPublished: true,
      page: 1,
      per_page: 5,
    };
    this.setState({ checking: true });
    axiosInstance
      .post('/api/studio-collection/v2/fetch-by-trainer', params)
      .then(response => {
        const { data } = response.data;
        if (data.length) {
          this.props.toggleModal(
            true,
            <AddStudioCollectionClient
              onAddSuccess={this.handleAddStudioCollectionSuccess}
              selectedIds={this.getSelectedIds()}
            />,
          );
        } else {
          this.props.push('/home/studio-collection');
        }
      })
      .finally(() => {
        this.setState({ checking: false });
      });
  };

  handleRemoveStudioCollection = collection => () => {
    const { selectedClient } = this.props;
    const params = {
      client: selectedClient._id,
      collectionId: collection._id,
    };
    this.props.removeStudioCollectionByClient(params).then(response => {
      this.setState(state => ({
        studioCollection: state.studioCollection.filter(it => it._id !== collection._id),
      }));
    });
  };

  renderStudioCollections = () => {
    const { studioCollection } = this.state;
    return (
      <>
        {studioCollection.map(item => (
          <ClientCollectionItem collection={item} onRemove={this.handleRemoveStudioCollection(item)} />
        ))}
      </>
    );
  };

  handleLoadMoreCollectionWorkoutsList = event => {
    const { selectedClient } = this.props;
    const { workoutCollectionQuery, workoutCollections } = this.state;

    const left = event.target.scrollWidth - event.target.scrollLeft <= event.target.clientWidth + 5;

    if (left && workoutCollections.length < workoutCollectionQuery.total) {
      this.fetchOnDemandWorkoutCollectionsByClient(
        this.handleQueryFetchWorkoutCollection({
          page: workoutCollectionQuery.page + 1,
          perPage: workoutCollectionQuery.perPage,
          id: selectedClient._id,
        }),
      );
    }
  };

  onScrollDebounce = debounce(this.handleLoadMoreCollectionWorkoutsList, 300);

  handleScrollWorkoutList = event => {
    event.persist();
    this.onScrollDebounce.call(null, event);
  };

  render() {
    const { studioPrograms, studioCollection, workoutCollections } = this.state;
    const {
      selectedClient,
      permission: { collections_per_client = LIMIT_RESOURCE_COLLECTION },
    } = this.props;
    const workoutsList = get(this, 'props.workouts', []);

    const renderAddNewWorkoutCollection = () => {
      return workoutCollections.length ? (
        <S.AddStudioProgramClientButton
          onClick={() => this.onAddWorkoutCollectionsClient(workoutsList.length)}
          className="add-workout"
        />
      ) : (
        <AddWorkoutCollectionsArea onAddStudioProgram={() => this.onAddWorkoutCollectionsClient(workoutsList.length)} />
      );
    };

    return (
      <S.Wrapper>
        <UpgradePath pathName="studio_resource_collection" fallback={null}>
          <div className="clientStudio__heading">
            Resource Collections
            {studioCollection.length > 0 && (
              <span className="sub-title">Add up to {collections_per_client} collections</span>
            )}
          </div>
          <div className="clientStudio__programList clientStudio__programList--program">
            {studioCollection.length ? (
              <>
                {this.renderStudioCollections()}
                {studioCollection.length < collections_per_client && (
                  <S.AddCollectionButton onClick={this.onSelectCollection} />
                )}
              </>
            ) : (
              <AddCollectionArea onSelectCollection={this.onSelectCollection} />
            )}
          </div>
        </UpgradePath>

        <UpgradePath pathName="on_demand_workout" fallback={null}>
          <div className="clientStudio__heading">Workout Collections</div>
          <div
            className="clientStudio__programList clientStudio__programList--program"
            onScroll={event => this.handleScrollWorkoutList(event)}
          >
            {uniqBy(workoutCollections, '_id').map(workoutCollection => (
              <WorkoutCollectionsItem
                key={workoutCollection._id}
                workoutCollection={workoutCollection}
                onRemove={this.handleRemoveWorkout}
                onGoDetail={this.handleGotoDetailWorkoutCollections}
                selectedClient={selectedClient}
              />
            ))}
            {renderAddNewWorkoutCollection()}
          </div>
        </UpgradePath>

        <UpgradePath pathName="studio_program" fallback={null}>
          <div className="clientStudio__heading">Studio Programs</div>
          <div className="clientStudio__programList clientStudio__programList--program">
            {studioPrograms.map(studioProgram => (
              <ClientStudioItem
                key={studioProgram._id}
                studioProgram={studioProgram}
                onRemove={this.handleRemoveProgram}
                onGoDetail={this.goToDetail}
                selectedClient={selectedClient}
              />
            ))}
            {studioPrograms.length ? (
              <S.AddStudioProgramClientButton onClick={this.onAddStudioProgramClient} />
            ) : (
              <AddProgramArea onAddStudioProgram={this.onAddStudioProgramClient} />
            )}
          </div>
        </UpgradePath>
      </S.Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  selectedClient: state.rootReducer.client.workingClientDetail,
  permission: state.rootReducer.permission,
  workouts: get(state.rootReducer.workoutCollection, 'list', []),
  query: get(state.rootReducer.workoutCollection, 'query', ''),
});

const mapDispatchToprops = dispatch => ({
  fetchStudioProgramByClient: bindActionCreators(fetchStudioProgramByClient, dispatch),
  removeStudioProgramByClient: bindActionCreators(removeStudioProgramByClient, dispatch),
  fetchStudioCollectionByClient: bindActionCreators(fetchStudioCollectionByClient, dispatch),
  removeStudioCollectionByClient: bindActionCreators(removeStudioCollectionByClient, dispatch),
  fetchOnDemandWorkoutCollectionsByClient: bindActionCreators(fetchOnDemandWorkoutCollectionsByClient, dispatch),
  removeWorkoutCollectionsByClient: bindActionCreators(removeWorkoutCollectionsByClient, dispatch),
  getWorkoutCollectionList: bindActionCreators(getWorkoutCollectionList, dispatch),
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToprops)(ClientStudio);
