import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import Avatar from 'react-avatar';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Button } from 'shared/FormControl';
import { ReactComponent as ImageIcon } from 'assets/icons/image-thumbnail-icon.svg';
import { FILE_VALIDATIONS } from 'shared/ChatInput';
import {
  CONVERSION,
  FILE_ERRORS,
  FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF,
  FORMAT_VALIDATE_UPLOAD_IMAGE_NOT_GIF,
} from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl, getPresignedUploadUrl, getUserShortName, mediaLog } from 'utils/commonFunction';
import { validateFiles } from 'utils/validations';
import * as S from './style';

const GROUP_NAME_LIMIT_LENGTH = 80;

const GroupRoomInformation = ({
  members,
  selectedRoom,
  viewTeammate,
  updateGroupInfo,
  uploadGroupAvatar,
  getS3presignedURLFromLocalDatabase,
  getLatestMessages,
  startWaitingSystemMessage,
  isGroupChatPermission,
  cloudfrontList,
}) => {
  const isLocalFile = get(selectedRoom, 'isLocalFile', '');
  const roomIcon = get(selectedRoom, 'room_icon_thumbnail', '') || get(selectedRoom, 'room_icon', '');
  const roomName = get(selectedRoom, 'room_name', '');
  const [name, setName] = useState(roomName);
  const [avatar, setAvatar] = useState();
  const [isChangeName, setIsChangeName] = useState(false);
  const inputRef = useRef();
  const timerRef = useRef();

  useEffect(() => {
    setName(roomName);
  }, [roomName]);

  useEffect(() => {
    let ignore = false;
    if (!isLocalFile) {
      getPresignedURLs({
        list: [roomIcon],
        ignore,
      });
    }
    return () => {
      ignore = true;
    };
  }, [isLocalFile, roomIcon]);

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  const getPresignedURLs = useCallback(async ({ list, ignore }) => {
    const response = await getS3presignedURLFromLocalDatabase(list);
    if (ignore) return;
    setAvatar(response[0]);
  }, []);

  const handleChangeGroupName = value => {
    if (viewTeammate || !isGroupChatPermission) return;
    const trimmedValue = value.trim();

    if (trimmedValue.length <= GROUP_NAME_LIMIT_LENGTH) {
      setName(trimmedValue);
    } else {
      // handle copy paste case out of chars limit
      if (trimmedValue.length === GROUP_NAME_LIMIT_LENGTH + 1) {
        setName(name.trim().slice(0, GROUP_NAME_LIMIT_LENGTH));
      } else {
        setName(trimmedValue.slice(0, GROUP_NAME_LIMIT_LENGTH));
      }
    }
  };

  const handleFocusGroupName = () => {
    setIsChangeName(true);
  };

  const handleBlurGroupName = () => {
    // if (!name) {
    //   setIsChangeName(false);
    // }
  };

  const handleCancelGroupName = () => {
    setName(roomName);
    setIsChangeName(false);
  };

  const handleSaveGroupName = () => {
    setIsChangeName(false);
    updateGroupInfo && updateGroupInfo(selectedRoom.id, { name }).then(afterUpdateGroupInfo);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSaveGroupName();
    }
  };

  const afterUpdateGroupInfo = () => {
    if (selectedRoom.id) {
      getLatestMessages && getLatestMessages();
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        const messagesComponent = document.getElementById(`detail-content-${selectedRoom.id}`);
        if (!isEmpty(messagesComponent)) {
          messagesComponent.scrollTop = messagesComponent.scrollHeight;
        }
      }, 1000);
      startWaitingSystemMessage && startWaitingSystemMessage();
    }
  };

  const triggerInputFile = () => !viewTeammate && isGroupChatPermission && inputRef.current && inputRef.current.click();

  const onSelectPhoto = async e => {
    if (viewTeammate || !isGroupChatPermission) return;
    const files = Array.from(e.target.files);
    if (files[0]) {
      const { size, name, type } = files[0];
      const error = validateFiles({
        files,
        validateExtentions: FORMAT_VALIDATE_UPLOAD_IMAGE_NOT_GIF,
        maxSize: FILE_VALIDATIONS.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = `File type not supported.`;
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = `Please upload an image smaller than ${FILE_VALIDATIONS.MAX_SIZE_IMAGE} MB`;
        }

        toast.error(message);
        return;
      } else {
        mediaLog({
          status: 1,
          name,
          fileSize: size,
          fileType: type,
          description: 'Send a file via Group Image',
        });
        const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-inbox', files[0]);
        if (!uploadUrl || !configs) {
          return;
        }

        setAvatar(configs.url);
        uploadGroupAvatar &&
          uploadGroupAvatar(
            {
              url: uploadUrl,
              headers: { 'Content-Type': type },
              data: files[0],
            },
            () => {
              updateGroupInfo && updateGroupInfo(selectedRoom.id, { icon: configs.url }).then(afterUpdateGroupInfo);
            },
          );

        let reader = new FileReader();
        reader.onload = e => {
          let pendingAvatarImage = avatar;
          setAvatar(pendingAvatarImage);
        };
        reader.readAsDataURL(files[0]);
      }
    }
  };

  const convertS3Url = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);

  return (
    <S.Wrapper data-name="group-info-wrapper">
      <S.GroupImageWrapper
        onClick={triggerInputFile}
        data-name="group-image-wrapper"
        viewTeammate={viewTeammate || !isGroupChatPermission}
      >
        {avatar ? (
          <S.ImageWrapper className="only-one">
            <img
              src={convertS3Url(avatar)}
              onError={event => {
                event.preventDefault();
                event.target.src = avatar;
              }}
              alt=""
            />
          </S.ImageWrapper>
        ) : (
          <>
            {members.slice(0, 3).map((item, index) => (
              <S.ImageWrapper
                key={item._id}
                data-name={`group-image-${index}`}
                className={classnames({ 'only-one': members.length === 1, 'only-two': members.length === 2 })}
              >
                <Avatar name={getUserShortName(item)} size="68" src={convertS3Url(item.avatar)} color={item.color} />
              </S.ImageWrapper>
            ))}
            {get(selectedRoom, 'total_members', 0) > 3 ? (
              <S.OthersImage data-name="group-other-members">
                <span>
                  +{get(selectedRoom, 'total_members', 0) > 102 ? 99 : get(selectedRoom, 'total_members', 0) - 3}
                </span>
              </S.OthersImage>
            ) : null}
          </>
        )}
        <S.UploadImageButton data-name="change-image-btn">
          <ImageIcon />
          <span>Edit</span>
        </S.UploadImageButton>
        <input
          readOnly={viewTeammate || !isGroupChatPermission}
          style={{ visibility: 'hidden' }}
          type="file"
          ref={inputRef}
          onChange={onSelectPhoto}
          accept={FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF}
        />
      </S.GroupImageWrapper>
      <S.GroupNameInput
        placeholder="Name this group"
        lineHeight={22}
        value={name}
        onBlur={handleBlurGroupName}
        onFocus={handleFocusGroupName}
        onChange={handleChangeGroupName}
        onKeyPress={handleKeyPress}
        maxLength={GROUP_NAME_LIMIT_LENGTH}
        notRequired
        readOnly={viewTeammate || !isGroupChatPermission}
      />
      {isChangeName && (
        <S.ActionsWrapper>
          <Button textOnly className="btn btn__cancel" onClick={handleCancelGroupName}>
            Cancel
          </Button>
          <Button textOnly className="btn btn__save" onClick={handleSaveGroupName}>
            Save
          </Button>
        </S.ActionsWrapper>
      )}
    </S.Wrapper>
  );
};

export default GroupRoomInformation;
