// Libs
import React, { useMemo } from 'react';
import reduce from 'lodash/reduce';

// Shared
import { ShareOwnerAvatar } from 'shared/AssetsShareSetting';

// Constants
import { ASSET_TYPES } from 'components/OnboardingFlowDetail/constants';
import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';

// Assets
import DefaultImg from 'assets/images/OnboardingFlow/default_image.png';

// Styles
import * as S from './style';

const STUDIO_DEFAULT_THUMBNAIL = `${CDN_URL}/images/studio_program_default_cover_image.png`;

const countNumberOfResource = collection => {
  return reduce(
    collection.sections,
    (sum, section) => {
      return sum + section.resources.length;
    },
    0,
  );
};

const OnDemandItem = ({ type, onSelect, item, isChecked, isDisabled, cloudfrontList, user }) => {
  const {
    cover_image,
    cover_image_thumbnail,
    background,
    background_thumbnail,
    name,
    program_name,
    number_of_clients,
    number_of_resources,
    number_of_categories,
    number_of_workouts,
    number_of_weeks,
    author_info,
    share,
    _id: onDemandId,
  } = item || {};
  const { _id: userId } = user || {};

  const { assetName, assetBackground, assetTitle1, assetValue1, assetTitle2, assetValue2 } = useMemo(() => {
    switch (type) {
      case ASSET_TYPES.RESOURCE_COLLECTIONS:
        return {
          assetName: name,
          assetBackground: convertS3UrlToCloudFrontUrl(background_thumbnail || background, cloudfrontList),
          assetTitle1: 'Available for',
          assetValue1: `${pluralize('client', number_of_clients, true)}`,
          assetTitle2: 'Resources',
          assetValue2: number_of_resources || countNumberOfResource(item),
        };
      case ASSET_TYPES.WORKOUT_COLLECTIONS:
        return {
          assetName: name,
          assetBackground: convertS3UrlToCloudFrontUrl(background_thumbnail || background, cloudfrontList),
          assetTitle1: 'Workouts:',
          assetValue1: number_of_workouts,
          assetTitle2: 'Categories',
          assetValue2: number_of_categories,
        };
      case ASSET_TYPES.STUDIO_PROGRAMS:
        return {
          assetName: program_name,
          assetBackground:
            convertS3UrlToCloudFrontUrl(cover_image_thumbnail || cover_image, cloudfrontList) ||
            STUDIO_DEFAULT_THUMBNAIL,
          assetTitle1: 'Weeks:',
          assetValue1: number_of_weeks,
          assetTitle2: 'Workouts',
          assetValue2: number_of_workouts,
        };

      default:
        return {
          assetName: '',
          assetBackground: '',
          assetTitle1: '',
          assetValue1: '',
          assetTitle2: '',
          assetValue2: '',
        };
    }
  }, []);

  const isAnotherOwner = share !== TEAM_SHARE_NOOWNER && (author_info || {})._id !== userId;

  const handleSelect = () => {
    if (isDisabled) return;

    onSelect(item, isChecked);
  };

  return (
    <S.Wrapper onClick={handleSelect} isChecked={isChecked} isDisabled={isDisabled}>
      <S.RightSide>
        <S.Background src={assetBackground || DefaultImg} alt={name} />
        <S.InfoWrapper>
          <S.Name>{assetName}</S.Name>
          <S.InfoPart>
            <S.InforTitle>
              {assetTitle1} <S.Bold>{assetValue1}</S.Bold>
            </S.InforTitle>
            <S.SeparateDot />
            <S.InforTitle>
              {assetTitle2}: <S.Bold>{assetValue2}</S.Bold>
            </S.InforTitle>
            {isAnotherOwner && (
              <>
                <S.SeparateDot />
                <S.InforAvatar>
                  <ShareOwnerAvatar
                    user={author_info}
                    tooltipId={`avatar-tooltip-${onDemandId}`}
                    cloudfrontList={cloudfrontList}
                  />
                </S.InforAvatar>
              </>
            )}
          </S.InfoPart>
        </S.InfoWrapper>
      </S.RightSide>
      <S.LeftSide>
        <S.Checkbox className="on-demand-checkbox" isChecked={isChecked} />
      </S.LeftSide>
    </S.Wrapper>
  );
};

export default OnDemandItem;
