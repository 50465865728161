// Libs
import React, { useCallback, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import { ReactComponent as ArrowDownInputIcon } from 'assets/icons/arrow-down-input.svg';
import { ReactComponent as ArrowUpInputIcon } from 'assets/icons/arrow-up-input.svg';
// Style
import * as S from './style';

const GroupInput = props => {
  const {
    input1 = {},
    input2 = {},
    className = '',
    hasError = false,
    autoFocus = true,
    showArrow = false,
    min = 0,
    max = 200000,
    onChange = () => {},
    onFocus: onFocusProp = () => {},
    onBlur: onBlurProp = () => {},
    input2NumberDecimal = 2,
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const wrapperRef = useRef(null);

  const handleBlurWrapper = useCallback(() => {
    setIsFocus(false);
    onBlurProp();
  }, [onBlurProp]);

  const onFocus = () => {
    setIsFocus(true);
    onFocusProp();
  };

  const handleChangeValue = (key, currentInput) => values => {
    const { value = 0 } = values;
    const newInput = {
      ...currentInput,
      value: value > max ? max : value,
    };
    onChange(key, newInput);
  };

  const handleArrowInput = (key, currentInput, isIncrease) => {
    const newInput = {
      ...currentInput,
      value: Number(currentInput.value) + (isIncrease ? 1 : -1),
    };
    onChange(key, newInput);
  };

  const renderArrowInput = (key, currentInput) => {
    const disableDown = currentInput.value <= 0;

    return (
      <div className="arrow-inputs">
        <span onClick={() => handleArrowInput(key, currentInput, true)} className={classNames('arrow-input up')}>
          <ArrowUpInputIcon />
        </span>
        <span
          onClick={() => handleArrowInput(key, currentInput, false)}
          className={classNames('arrow-input down', { disabled: disableDown })}
        >
          <ArrowDownInputIcon />
        </span>
      </div>
    );
  };

  const checkIsAllowed = values => {
    const { floatValue = 0 } = values;
    return floatValue >= min;
  };

  useEffect(() => {
    if (autoFocus) {
      const input = document.getElementById('number-input-1');
      if (input) {
        input.focus();
      }
    }
  }, [autoFocus]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Logic to handle click outside the element
        handleBlurWrapper();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleBlurWrapper]);

  return (
    <S.GroupInputWrapper isFocus={isFocus} onFocus={onFocus} hasError={hasError} className={className} ref={wrapperRef}>
      <S.InputWrap>
        <S.InputItem className="input-item">
          <NumberFormat
            onValueChange={handleChangeValue('input1', input1)}
            value={input1.value}
            id="number-input-1"
            allowNegative={false}
            decimalScale={0}
            autoComplete="off"
            isAllowed={checkIsAllowed}
          />
          {showArrow && renderArrowInput('input1', input1)}
          <span className="input-item-label">{input1.label}</span>
        </S.InputItem>
        <S.InputItem className="input-item">
          <NumberFormat
            onValueChange={handleChangeValue('input2', input2)}
            value={input2.value}
            allowNegative={false}
            decimalSeparator="."
            decimalScale={input2NumberDecimal}
            autoComplete="off"
            isAllowed={checkIsAllowed}
          />
          {showArrow && renderArrowInput('input2', input2)}
          <span className="input-item-label">{input2.label}</span>
        </S.InputItem>
      </S.InputWrap>
    </S.GroupInputWrapper>
  );
};

export default GroupInput;
