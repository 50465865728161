import React, { useMemo, useRef } from 'react';
import * as S from './styles';
import { Button } from 'shared/FormControl';
import { ReactComponent as CopyIcon } from 'assets/icons/payment-package-share-icon.svg';
import { toast } from 'react-toastify';

const basePublicURL = process.env.REACT_APP_PUBLIC_PAGE_URL || 'https://dev-coach.everfit.io';
const baseMPPublicURL = process.env.REACT_APP_MARKETPLACE_PAGE_URL || 'https://marketplace-dev.everfit.io';

export default function CopyPackage(props) {
  const inputRef = useRef();

  const onCopy = () => {
    inputRef.current.focus();
    inputRef.current.select();
    document.execCommand('copy');
    try {
      const successful = document.execCommand('copy');

      if (successful) {
        toast('Link Copied to Clipboard');
      }
    } catch (err) {
      console.error('Oops, unable to copy');
    }
  };

  const packageLink = useMemo(() => {
    return props.isMP ? `${baseMPPublicURL}/product/${props.packageId}` : `${basePublicURL}/package/${props.packageId}`;
  }, [props.packageId]);

  return (
    <>
      <S.InputCopy ref={inputRef} className="inputCopy" value={packageLink} />
      <Button tooltip="Copy link" tooltipKey="copy_package" className="packageActions__shareBtn" onClick={onCopy}>
        <CopyIcon />
        Share
      </Button>
    </>
  );
}
