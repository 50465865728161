import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
`;

export const MealPlanHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.span`
  margin-left: 5px;
  max-width: 440px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const EditButton = styled.button`
  padding: 0;
  margin-left: 15px;
  width: 69px;
  height: 30px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
  }
`;

export const MealPlanStatus = styled.div`
  ${baseText}
  padding: 0 10px;
  margin-left: 15px;
  height: 30px;
  background-color: #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #e1e1e9;
  ${({ isPublished }) =>
    isPublished &&
    css`
      color: #0b7c47;
      background-color: #e8fff4;
      border: 1px solid #65cc9c;
    `}
`;
