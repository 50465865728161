import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import ConfirmModalEl from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled(Modal)`
  &&&.modal {
    width: 650px;
    height: 100%;
    border-radius: 8px;
    height: 800px;

    @media screen and (max-height: 856px) {
      max-height: calc(100% - 28px);
    }
  }
`;

export const Header = styled(Modal.Header)`
  &&&.header {
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 24px 24px;
    border-radius: 8px;

    > h2 {
      flex: 1;
      font-size: 18px;
      font-weight: 700;
      color: #202353;
      margin: 0;
    }

    .survey__search-field {
      display: flex;
      align-items: center;
      gap: 8px;

      .ui.input.search-input > input {
        background: url(${CDN_URL}/images/search_light.svg) no-repeat scroll 11px center !important;
        background-color: #ffffff !important;
        border: 1px solid #d9d9d9 !important;
        height: 42px !important;
        font-size: 13px;
        border-radius: 5px !important;
        &:hover,
        &:focus {
          border-color: #726ee4 !important;
        }
      }
      .search-input {
        border-radius: 5px;
        height: 42px !important;
        width: 231px !important;
      }
      .ui.image.input-addon-icon {
        right: 13px;
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const Content = styled(Modal.Content)`
  &&&.content {
    margin-top: 24px;
    padding: 0px 9px 0px 24px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    max-height: calc(100% - 157px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .survey__description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding-right: 9px;

      > p {
        margin: 0;
        font-size: 13px;
      }
    }

    .survey__list {
      height: 100%;
      overflow: scroll;
      padding-right: 6px;
      scrollbar-width: 8px;

      > .wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 12px;
      }

      ::-webkit-scrollbar {
        width: 5px !important;
      }
    }
  }
`;

export const Actions = styled(Modal.Actions)`
  &&&.actions {
    padding: 12px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    gap: 6px;
  }
`;

export const ModalButton = styled(Button)`
  &&& {
    transition: all 0.3s;
    margin: 0;
    min-width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;

    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    gap: 5px;
    color: #ffffff;
  }
`;

export const PrimaryButton = styled(ModalButton)`
  &&& {
    svg {
      flex-shrink: 0;
    }
    gap: 3px;
    justify-content: unset;
    border: 1px solid rgb(81, 88, 207, 1);
    ${props => ({
      height: props.height || '34px',
      padding: props.padding || '0 35px',
      width: props.width || 'fit-content',
      minWidth: props.minWidth || 'unset',
    })}
    background: rgb(81, 88, 207, 1);

    :hover {
      background: rgb(81, 88, 207, 0.9);
      border-color: rgb(81, 88, 207, 0.9);
    }
  }
`;

export const GhostPrimaryButton = styled(ModalButton)`
  &&& {
    background: none;
    ${props => ({
      height: props.height || '18px',
      minWidth: props.minWidth || '18px',
    })}
    &:hover {
      background: transparent;
      color: rgb(81, 88, 207, 1);
      g {
        transition: all ease 0.25s;
        fill: rgb(81, 88, 207, 1);
      }
    }
  }
`;

export const EmptySurveyWrapper = styled.div`
  &&& {
    width: 100%;
    height: 513px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 40px;
    & .empty__message {
      font-size: 14px;
      color: #9199b8;
    }

    & .empty__list {
      width: 180px;
      height: 133px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 11px;
      gap: 2px;
    }
  }
`;

export const ConfirmModal = styled(ConfirmModalEl)`
  &.ui.modal.confirm-popup-container.new-style {
    width: 467px !important;

    .confirm-content-header {
      padding: 32px 32px 16px 32px !important;

      .confirm-header-label {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .confirm-content-body {
      padding: 0 32px !important;
      min-height: fit-content;

      .confirm-content-label {
        display: block;
        line-height: 20px;

        .survey-name {
          white-space: pre-wrap;
        }
      }
    }

    .confirm-actions {
      padding: 32px !important;

      .ui.button {
        max-height: 30px;
        padding: 5px 16px;
        border-radius: 5px;
        min-width: unset;
        font-weight: 600;
        font-family: 'Open Sans', 'Lato';

        &.confirm-no-button {
          letter-spacing: -0.3px;

          &:hover {
            background: #f5f7f9 !important;
          }
        }

        &.confirm-yes-button {
          background-color: #f9b811;
          box-shadow: none;
          letter-spacing: -0.3px;

          &:hover {
            background: rgba(249, 184, 17, 0.9);
          }
        }
      }
    }
    &.error {
      .confirm-content-header .confirm-header-label {
        svg rect {
          fill: rgba(234, 49, 74, 1);
        }
      }
      .confirm-actions .ui.button {
        &.confirm-yes-button {
          background-color: rgba(234, 49, 74, 1);

          &:hover {
            background: rgba(234, 49, 74, 0.9);
          }
        }
      }
    }
  }
`;

export const SurveyName = styled.h2`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    path {
      fill: #c1c4cc;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: #5158cf;
      }
    }
  }

  .__react_component_tooltip.survey-name-tooltip {
    background: #141414;
    border-radius: 8px;
    padding: 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: 260px;
  }
`;
