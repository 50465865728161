import styled from 'styled-components';

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 120px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;
