import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import classnames from 'classnames';

import { setDragItem, handleDragExercise } from 'redux/workout-builder/actions';

const DragContainerWrapper = styled.div`
  position: absolute;
  height: calc(50% + 2px);
  top: -2px;
  right: 0;
  width: calc(100% - 15px);
  z-index: 2;

  :not(.no-highlight):hover {
    border-top: 1px solid #5c5bbd;
  }

  &.bottom {
    top: unset;
    bottom: -1px;
    :not(.no-highlight):hover {
      border-top: 0px;
      border-bottom: 1px solid #5c5bbd;
    }
  }
`;

const ExerciseDragContainer = props => {
  const draggingItem = props.draggingItem ? props.draggingItem.toJS() : null;
  const { sectionId, index, dragType } = draggingItem;
  const isNoHighlight = dragType === 'exercise' && index === props.index && sectionId === props.sectionId;

  const onMouseUp = e => {
    if (draggingItem && draggingItem.dragType === 'exercise' && !props.disableDrop) {
      e.stopPropagation();
      props.handleDragExercise({
        dropType: 'exercise',
        exerciseId: props.exerciseId,
        sectionId: props.sectionId,
        index: props.index,
      });
    }
  };

  return (
    <DragContainerWrapper
      data-exercise-id={props.exerciseId}
      data-section-id={props.sectionId}
      data-exercise-index={props.index}
      data-arrangement={true}
      onMouseUp={onMouseUp}
      className={classnames({ bottom: props.bottom, 'no-highlight': isNoHighlight })}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    draggingItem: state.rootReducer.workoutBuilder.get('draggingItem'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDragItem: bindActionCreators(setDragItem, dispatch),
    handleDragExercise: bindActionCreators(handleDragExercise, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExerciseDragContainer);
