import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled(Link)`
  background: linear-gradient(180deg, #FAFBFC 0%, #F4F6F8 100%);
  border: 1px solid #EAEDF0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #202353;
  position: relative;
  text-align: left;
  display: flex;
  cursor: pointer;

  .referralIcon {
    width: 55px;
    height: 70px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-60%);
    background-image: url(${CDN_URL}/images/referral_a_coach_icon_active.svg);
  }

  :hover {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(180deg, #FAFBFC 0%, #F4F6F8 100%);
    color: #202353;
    text-decoration: none !important;
  }
`;


export default function ReferralACoach(props) {

  return (
    <Wrapper onClick={props.onClick} to="/home/referral-program">
      <div>Refer a Coach, earn $20</div>
      <div className="referralIcon" />
    </Wrapper>
  )
}