import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  position: relative;
  .filter__dropdown {
    background-color: #f5f5f5;
    cursor: pointer;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    color: #262626;
    &.active,
    :hover {
      background-color: #f4f4fc !important;
    }
    .filter__dropdown__title {
      height: 100%;

      .filter-name {
        width: 38px;
        margin-left: 1px;
      }

      .colons {
        margin-left: 1px;
        margin-right: 4px;
        width: 4px;
      }

      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }
    }
  }

  .disable {
    background-color: #fafafa !important;
    cursor: not-allowed !important;
    user-select: none !important;

    * {
      color: #bfbfbf !important;
    }
  }
`;

export const SearchBar = styled.div`
  .search-input {
    height: 34px;
    width: 298px;
    margin-bottom: 8px;
    input {
      background: url(${CDN_URL}/images/search_white.svg) no-repeat scroll 13px center !important;

      color: #262626;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      ::placeholder {
        color: #d9d9d9;
        opacity: 1;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    svg {
      width: 16px;
      height: 16px;
      right: 16px;
    }
  }
`;

export const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 178px;
  width: 298px;
  padding-right: ${props => (props.hiddenScrollBar ? '0px' : '2px')};
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15) !important;
  }
`;

export const EmptySearch = styled.div`
  padding-top: 86px;
  text-align: center;
  width: 298px;
  height: 178px;

  color: #d9d9d9;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectClientOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 10px;
  cursor: pointer;

  :hover {
    background-color: #f4f4fc;

    .checkbox label {
      &::before {
        border-color: #5158cf !important;
      }
      &::after {
        background-color: #5158cf !important;
      }
    }
  }

  ${props =>
    props.isSelected &&
    css`
      ${AllClientLabel} {
        color: #262626;
      }
      ${ClientName} {
        color: #262626;
      }
    `}
`;

export const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .client-loading {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const AvatarClient = styled.div`
  display: flex;

  .client-icon {
    margin: 4px;
    margin-right: 10px;
  }

  .client-detail-avatar {
    margin-right: 6px;
    flex-shrink: 0;
  }
`;

export const SelectedClient = styled.div`
  display: flex;

  .client-detail-avatar {
    flex-shrink: 0;
    margin-right: 5px;
  }

  .client-detail-name {
    padding-top: 2px;
    color: #262626;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const AllClientLabel = styled.div`
  color: #595959;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px;
  padding-left: 2px;
`;

export const ClientName = styled.div`
  color: #595959;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-top: 6px;
  padding-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 217px;

  &.client-detail-name {
    max-width: 225px;
  }
`;

export const RadioButton = styled.div`
  .ui.radio.checkbox {
    min-height: 16px;
    min-width: 16px;
    line-height: 16px;
    display: block;
    margin-top: -2px;

    label::before {
      border-width: 2px !important;
      width: 16px !important;
      height: 16px !important;
    }
    label::after {
      width: 16px !important;
      height: 16px !important;
    }
    &.checked label::before {
      border-color: #5158cf !important;
    }
    &.checked label::after {
      background-color: #5158cf !important;
    }
  }
`;

export const MenuContainer = styled.div`
  width: 322px;
  max-height: 244px;
  border-radius: 4px;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border: solid 1px #e0e4e5;
  background-color: #fff;
  padding: 12px;
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 999;
  overflow: hidden;
`;
