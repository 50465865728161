import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from 'assets/icons/warning_popup_yellow.svg';
import { connect } from 'react-redux';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import { CDN_URL } from 'constants/commonData';

const ModalWrapper = styled(Modal)`
  width: 467px !important;
  padding: 30px;
  border-radius: 8px !important;

  .content {
      padding 0 !important;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;

  span {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #4a4d56;
  }
`;

const ModalContent = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  ont-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #323c47;

  span {
    font-weight: 600;
    word-break: break-word;
  }

  a {
    margin-left: 3px;
    color: #5c5bbd;
    font-weight: bold;
    opacity: 1;
    letter-spacing: -0.6px;
    white-space: nowrap;

    :hover {
      color: #5c5bbd;
    }

    img {
      margin-bottom: -2px;
      margin-right: 3px;
    }
  }
`;

const ModalActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  background-color: #fff;
  border: 1px solid #d6dae4;
  border-radius: 5px;
  padding: 6px 24px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #818da1;
  cursor: pointer;

  :hover {
    background-color: #f5f7f9;
  }
`;

const WarningPopup = ({ secondProduct, isModalOpen, isSecondModalOpen, toggleModal, toggleSecondModal }) => {
  const handleClose = () => {
    if (isSecondModalOpen) {
      toggleSecondModal(false);
    } else {
      toggleModal(false);
    }
  };

  return (
    <ModalWrapper open={isSecondModalOpen || isModalOpen} onClose={handleClose} closeOnDimmerClick={false}>
      <Modal.Content>
        <ModalHeader>
          <WarningIcon />
          <span>Autoflow conflict</span>
        </ModalHeader>
        <ModalContent>
          The Autoflow from <span>{secondProduct.name}</span> will not be assigned{' '}
          <a
            href="https://help.everfit.io/en/articles/5210131-how-to-add-multiple-autoflows-for-a-client"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" />
            Learn more
          </a>
        </ModalContent>
        <ModalActions>
          <CloseButton onClick={handleClose}>Close</CloseButton>
        </ModalActions>
      </Modal.Content>
    </ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { isModalOpen, isSecondModalOpen } = state;
  return {
    isModalOpen,
    isSecondModalOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningPopup);
