import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #202353;
`;

export const Header = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 3px 0 30px;
  span {
    ${baseText}
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
  }
  button {
    position: relative;
    left: 2px;
    top: 1px;
    ${baseText}
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #5158cf;
    width: 129px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    .custom-plus-icon {
      width: 10px;
      height: 10px;
    }
    svg {
      margin-right: 9px;
      margin-bottom: 2px;
      path {
        stroke: #5158cf;
      }
    }
    &:hover {
      background-color: #f0f1ff;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 7px 0 30px;
`;

export const StatusLabel = styled.div`
  ${baseText}
  color: #7b7e91;
  font-size: 10px;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  &.label-upcoming {
    cursor: pointer;
    line-height: 15px;
    margin-top: 8px;
    margin-bottom: 4px;
    svg {
      margin-left: 6px;
      transform: rotate(180deg);
      width: 7.5px;
      path {
        fill: #7b7e91;
      }
    }
    &:hover {
      color: #5158cf;
      svg path {
        fill: #5158cf;
      }
    }
  }
  ${({ showUpcoming }) =>
    showUpcoming &&
    css`
      &.label-upcoming {
        svg {
          transform: rotate(0deg);
        }
      }
    `}
`;

export const GetMealPlan = styled.div`
  margin-top: 15px;
  border-radius: 10px;
  background-color: #fff8f0;
  padding: 20px 15px;
  margin: 0 24px 0 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .custom-upgrade-path {
    height: 30px;
    display: flex;
    justify-content: center;
    padding: 9px 15px;
    border-radius: 5px;
    font-weight: 700;
    background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
    :hover {
      background: linear-gradient(85.16deg, #fdc830 -18.89%, #f37335 182.76%);
    }
  }
`;

export const GetMealPlanContent = styled.div`
  display: flex;
  gap: 10px;

  svg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  span {
    ${baseText}
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    display: inline-block;
  }
`;

export const Empty = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    ${baseText}
    font-size: 13px;
    line-height: 20px;
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${props =>
    props.noPermissionAddOn &&
    css`
      ${Header} {
        margin: 0 29px 0 30px;
      }
    `}
`;
