import React from 'react';
import { Wrapper } from './style';
import classNames from 'classnames';

export default function FileInput(props) {
  const { icon, hoverIcon, text, onClick, type } = props;

  return (
    <Wrapper className={classNames('add-media-input', type)} onClick={onClick}>
      <div className="image-container">
        <img src={icon} alt="" className="icon" />
        <img src={hoverIcon || icon} alt="" className="icon hover" />
      </div>
      <div className="text">{text}</div>
    </Wrapper>
  );
}
