import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const MissedLabel = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  color: #ffffff;
  padding: 4px 8px;
  background: #fd6666;
  border-radius: 999999px;
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #5158cf;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
`;

export const ExpandIcon = styled.div`
  background-image: url(${CDN_URL}/images/arrow-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

export const SectionWrapper = styled.div``;

export const TrackedSetItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-top: 5px;

  .tracked-set {
    display: flex;
  }

  .tracked-set__no-sequence {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #202353;
    opacity: 0.4;
    margin-right: 5px;
  }

  .tracked-set__value {
    display: flex;
    justify-content: flex-start;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #202353;
  }

  .personal-best {
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .missed-label {
    font-weight: 600;
    font-size: 11px;
    line-height: 100%;
    color: #ffffff;
    padding: 5px 7px;
    background: #fd6666;
    border-radius: 999999px;
  }

  .tracked-set__one-rep-max {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #5f5f5f;
  }

  &.missed-set {
    .tracked-set {
      width: calc(100% - 75px);
    }

    .tracked-set__value {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      color: #fd6666;

      &--des {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .personal-best-info {
    padding: 8px 5px;

    .exercise__title {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      color: #5c5bbd;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 5px;
      border-bottom: 1px solid rgba(118, 118, 118, 0.1);
      min-width: 240px;

      :last-child {
        border-bottom: none;
      }

      .name {
        display: flex;
        justify-content: flex-start;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        color: #5f5f5f;

        img {
          margin-right: 5px !important;
        }
      }

      .value {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #1e0a3c;
      }
    }
  }
`;

export const OneRepMax = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #202353;
`;

export const BaseTrackedInfo = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #2e305e;
  display: flex;
  align-items: center;
  margin-top: 5px;

  img {
    margin-right: 9px;
  }

  span {
    margin-right: 20px;
  }
`;

export const SectionText = styled.div``;
export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-top: 1px solid #eef2fa;

  .title {
    font-weight: bold;
    font-size: 13px;
    line-height: 120%;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1;
  }

  .title-type-file {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .type-label {
    font-weight: bold;
    font-size: 13px;
    line-height: 120%;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .section-type {
    height: 15.6px;
  }

  .missed-title {
    display: flex;
    width: 100%;
    align-items: center;

    ${SectionText} {
      font-weight: bold;
      font-size: 13px;
      line-height: 120%;
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1 1;
    }
  }

  .time {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    color: #8a8cad;
    text-transform: uppercase;
    margin-left: 10px;
    white-space: nowrap;

    img {
      margin-right: 4px;
    }
  }
`;

export const Missed = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  color: #ffffff;
  padding: 5px 7px;
  background: #fd6666;
  border-radius: 999999px;
`;

export const ExerciseTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #202353;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Note = styled(ExerciseTitle)`
  color: #202353;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-left: 20px;
  margin-bottom: 15px;
  white-space: initial;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExerciseSummary = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #5f5f5f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 2px;
  min-height: 10px;
`;

export const NumberOfSets = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  color: #202353;
  margin-left: 10px;
`;

export const ExerciseBaseInfo = styled.div`
  overflow: hidden;
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Exercise = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
`;

export const ExerciseReferences = styled(Exercise)`
  padding: 0;
  margin-bottom: 15px;
`;

export const Exercises = styled.div`
  position: relative;

  .exercise {
    :first-child {
      padding-top: 0;
    }

    :last-child {
      padding-bottom: 0;
    }
  }
`;

export const Supersets = styled.div`
  padding: 10px 0;
  /* border-bottom: 1px solid #EEF2FA; */

  :last-child {
    border: none;
  }

  .superset-label {
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    color: #7b7e91;
    margin-bottom: 5px;
  }

  &.is-supersets {
    padding-top: 0px;

    ${Exercises} {
      ::before {
        content: '';
        width: 6px;
        top: 10px;
        bottom: 24px;
        left: -10px;
        border: 1px dashed #c0c2cb;
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        position: absolute;
      }

      .exercise {
        position: relative;
        :last-child {
          ::before {
            content: '';
            position: absolute;
            left: -14px;
            top: 8px;
            bottom: 0;
            width: 9px;
            z-index: 4;
            background-color: #ffffff;
          }

          ::after {
            content: '';
            z-index: 5;
            width: 6px;
            top: 10px;
            bottom: calc(100% - 20px);
            left: -10px;
            border: 1px dashed #c0c2cb;
            border-right: none;
            border-top: none;
            border-bottom-left-radius: 4px;
            position: absolute;
          }
        }
      }
    }
  }
`;

export const SectionExercises = styled.div`
  padding-left: 20px;

  &.section--exercises--hidden {
    padding-left: 0px;
    padding-top: 10px;
    border-top: 1px solid #eef2fa;
  }
`;

export const SectionContainer = styled.div`
  position: relative;
  /* padding-top: 20px; */
  padding-left: 10px;

  .dot {
    width: 9px;
    height: 9px;
    position: absolute;
    left: -14px;
    background-color: #d8dced;
    border: 1px solid #fafafa;
    border-radius: 50%;
    top: 18px;
    z-index: 2;
    content: '';
  }

  &.section--hidden {
    padding-top: 0;

    .dot {
      /* top: 28px; */
    }
  }

  &.section--supersets {
    .dot {
      /* top: 37px; */
    }
  }

  :first-child {
    ${SectionTitle} {
      border-top: none;
    }
  }
`;

export const Content = styled.div`
  padding-left: 14px;
  position: relative;
  opacity: ${props => (props.loading ? 0 : 1)};

  ::before {
    content: '';
    position: absolute;
    left: 4px;
    border-right: 1px dashed #d8dced;
    top: 0;
    bottom: 0;
  }

  ${SectionContainer} {
    :last-child {
      ::after {
        content: '';
        position: absolute;
        left: -14px;
        top: 28px;
        bottom: 0;
        width: 9px;
        z-index: 3;
        background-color: #ffffff;
      }

      &.section--hidden {
        ::after {
          top: 36px;
        }

        :first-child {
          ::after {
            top: 56px;
          }
        }
      }

      &.section--supersets {
        ::after {
          top: 45px;
        }
      }
    }

    :first-child {
      ::before {
        content: '';
        position: absolute;
        left: -14px;
        top: 0;
        width: 9px;
        height: 18px;
        z-index: 3;
        background-color: #ffffff;
      }

      &.section--hidden {
        padding-top: 20px;

        ::before {
          height: 49px;
        }

        .dot {
          top: 48px;
        }
      }

      &.section--supersets {
        ::before {
          height: 38px;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #edeef1;
  padding: 15px 0px 5px 0px;
  overflow: hidden;

  &:first-child {
    padding-top: 5px;
  }

  .workout-overview__content {
    max-height: 9999px;
    transition: max-height 0.3s ease;
  }

  .workout-overview__expand-icon {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
  }

  &.workout-overview__hide {
    .workout-overview__expand-icon {
      transform: rotate(-90deg);
    }

    .workout-overview__content {
      max-height: 0px;
    }

    .section--exercises.section--exercises--hidden {
      border-top: 0px;
    }
  }
`;
