import styled, { css } from 'styled-components';

export const Header = styled.div`
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
`;

export const Body = styled.div`
  padding: 5px 20px;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column-reverse;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  outline: none !important;

  ${props =>
    props.empty &&
    css`
      ${Body} {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    `}
`;

export const Wrapper = styled.div`
  width: 345px;
  flex: 1;
  background: #ffffff;
  box-shadow: -2px 0px 16px rgba(108, 108, 108, 0.1);
  border-radius: 8px;
  margin-top: 10px;
  max-height: 630px;

  ${props =>
    !props.showing &&
    css`
      display: none;
    `}
`;

export const ListCommentWrapper = styled.div`
  margin-bottom: auto;
`;
