import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13.62px;
  color: #6A778A;
`;

export const UpdateMetric = styled(Modal)`
  &.ui.modal {
    width: 488px;
    border-radius: 8px !important;
    .close-button {
      right: -9px;
      top: -9px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  &.ui.modal .header {
    padding: 0;
  }
  &.ui.modal .content {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    padding: 32px 32px 16px;
  }
  &.ui.modal .actions {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 8px 32px 24px;
    background: transparent;
    border-top: none;
    justify-content: end;
    display: flex;
  }
`;

export const ButtonSave = styled.button`
  ${baseText}
  font-size: 13px;
  line-height: 17.7px;
  width: 100px;
  height: 32px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 5px;
  background: #5e59ff;
  cursor: pointer;
  margin-left: auto;
  :hover {
    opacity: 0.9;
  }
  :disabled {
    opacity: 1;
    cursor: not-allowed;
    background: #bfbfbf;
  }
`;

export const Title = styled.p`
  ${baseText}
  font-size: 18px;
  line-height: 24.51px;
  color: #202353;
  margin-bottom: 16px !important;
`;

export const UnitWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const MetricName = styled.div`
  ${baseText}
  color: #6A778A;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.62px;
  text-transform: uppercase;
`;

export const MetricUnit = styled.span`
  ${baseText}
  color: #000;
  font-weight: 400;
  font-size: 13px;
  line-height: 17.7px;
  width: 38px;
  flex-shrink: 0;
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid #dadfea;
  background: #fafbfc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetricItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  input {
    ${baseText}
    height: 36px;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.7px;
    border: none !important;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
    /* Works for Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Works for Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const MetricInput = styled.div`
  display: flex;
  border: 1px solid #dadfea;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  .progress-photo-weight-input {
    width: 100% !important;
    border-radius: 4px 0 0 4px;
    height: 34px;
    padding: 9px 10px;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Open Sans';
  }
  ${props =>
    props.isError &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const Desc = styled.span`
  ${baseText}
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  margin-top: 8px;
  display: inline-block;
  color: #6a778a;
`;
