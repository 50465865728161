import { Quill } from 'react-quill';

const icons = Quill.import('ui/icons');

export const modules = {
  toolbar: ['bold', 'italic', { list: 'ordered' }, { list: 'bullet' }],
  clipboard: {
    matchVisual: false,
  },
};

export const formats = [
  'align',
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link', ignored
];

export const loadCustomIcons = () => {
  icons['bold'] = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5299 12.3026C18.5639 11.5805 19.2037 10.376 19.0488 9.02952C18.8372 7.18942 17.0825 5.85729 15.1388 5.8573H7.28613V6.87771L8.83672 7.61609C9.19971 7.78893 9.42899 8.14226 9.42899 8.52876V17.4715C9.42899 17.858 9.19971 18.2114 8.83672 18.3842L7.28613 19.1226V20.143H15.6676C17.9217 20.143 19.9323 18.564 20.1267 16.4252C20.2942 14.5826 19.1751 12.9674 17.5299 12.3026ZM12.6433 7.89812H13.7147C14.8982 7.89812 15.8576 8.81178 15.8576 9.93893C15.8576 11.066 14.8982 11.9797 13.7147 11.9797H12.6433V7.89812ZM14.7861 18.1022H12.6433V14.0206H14.7861C15.9696 14.0206 16.929 14.9342 16.929 16.0614C16.929 17.1885 15.9696 18.1022 14.7861 18.1022Z"  fill="currentColor"/>
      </svg>`;

  icons['italic'] = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2853 7.64314V5.85742H10.8567V7.64314H12.2181C12.5441 7.64314 12.7946 7.88375 12.7485 8.1527L11.1305 17.5908C11.0551 18.0307 10.603 18.3574 10.0698 18.3574H8.71387V20.1431H15.1424V18.3574H13.781C13.455 18.3574 13.2046 18.1168 13.2507 17.8479L14.8687 8.40973C14.9441 7.96986 15.3961 7.64314 15.9293 7.64314H17.2853Z"  fill="currentColor"/>
      </svg>`;

  icons['align'][''] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path d="M13 2.5H1V3.5H13V2.5Z" fill="currentColor"/>
      <path d="M8 5.5H1V6.5H8V5.5Z" fill="currentColor"/>
      <path d="M13 8.5H1V9.5H13V8.5Z" fill="currentColor"/>
      <path d="M8 11.5H1V12.5H8V11.5Z" fill="currentColor"/>
      </svg>`;

  icons['align'][
    'center'
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path d="M13 2.5H1V3.5H13V2.5Z" fill="currentColor"/>
      <path d="M11 5.5H3V6.5H11V5.5Z" fill="currentColor"/>
      <path d="M13 8.5H1V9.5H13V8.5Z" fill="currentColor"/>
      <path d="M11 11.5H3V12.5H11V11.5Z" fill="currentColor"/>
      </svg>`;

  // icons['list'][
  //   'ordered'
  // ] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
  //     <path d="M14 2.5H6V3.5H14V2.5Z" fill="currentColor"/>
  //     <path d="M14 6.5H6V7.5H14V6.5Z" fill="currentColor"/>
  //     <path d="M2.30103 6.14014H1.32031V3.45508L1.32983 3.01392L1.3457 2.53149C1.18278 2.69442 1.06958 2.80127 1.0061 2.85205L0.4729 3.28052L0 2.69019L1.49487 1.5H2.30103V6.14014Z" fill="currentColor"/>
  //     <path d="M3.25635 12.2068H0.0126953V11.5244L1.17749 10.3469C1.52238 9.99357 1.74772 9.74919 1.85352 9.61377C1.95931 9.47624 2.03548 9.34928 2.08203 9.23291C2.12858 9.11654 2.15186 8.99593 2.15186 8.87109C2.15186 8.6849 2.10002 8.54631 1.99634 8.45532C1.89478 8.36434 1.7583 8.31885 1.58691 8.31885C1.40706 8.31885 1.2325 8.36011 1.06323 8.44263C0.893962 8.52515 0.717285 8.64258 0.533203 8.79492L0 8.16333C0.228516 7.96867 0.417887 7.83114 0.568115 7.75073C0.718343 7.67033 0.882324 7.60897 1.06006 7.56665C1.23779 7.52222 1.43669 7.5 1.65674 7.5C1.94661 7.5 2.20264 7.5529 2.4248 7.65869C2.64697 7.76449 2.81942 7.9126 2.94214 8.10303C3.06486 8.29346 3.12622 8.51139 3.12622 8.75684C3.12622 8.97054 3.08813 9.17155 3.01196 9.35986C2.93791 9.54606 2.82153 9.73755 2.66284 9.93433C2.50627 10.1311 2.22909 10.4115 1.8313 10.7754L1.23462 11.3372V11.3816H3.25635V12.2068Z" fill="currentColor"/>
  //     <path d="M14 10.5H6V11.5H14V10.5Z" fill="currentColor"/>
  //     </svg>`;

  icons['list'][
    'ordered'
  ] = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_522_1220)">
      <path d="M23.0008 5.85681H11.5723V7.28538H23.0008V5.85681Z"  fill="currentColor"/>
      <path d="M23.0008 11.571H11.5723V12.9996H23.0008V11.571Z"  fill="currentColor"/>
      <path d="M6.28816 11.057H4.88714V7.22119L4.90074 6.59096L4.92341 5.90179C4.69066 6.13453 4.52895 6.28718 4.43827 6.35972L3.67655 6.97182L3.00098 6.12849L5.13651 4.42822H6.28816V11.057Z"  fill="currentColor"/>
      <path d="M7.6529 19.7236H3.01911V18.7488L4.68311 17.0667C5.1758 16.5619 5.49772 16.2128 5.64886 16.0193C5.79999 15.8228 5.90881 15.6415 5.97531 15.4752C6.04181 15.309 6.07506 15.1367 6.07506 14.9583C6.07506 14.6923 6.001 14.4944 5.85289 14.3644C5.7078 14.2344 5.51283 14.1694 5.268 14.1694C5.01107 14.1694 4.7617 14.2284 4.51988 14.3462C4.27806 14.4641 4.02567 14.6319 3.7627 14.8495L3.00098 13.9472C3.32743 13.6692 3.59796 13.4727 3.81257 13.3578C4.02718 13.243 4.26144 13.1553 4.51535 13.0948C4.76925 13.0314 5.05339 12.9996 5.36775 12.9996C5.78185 12.9996 6.1476 13.0752 6.46498 13.2263C6.78237 13.3775 7.02872 13.5891 7.20403 13.8611C7.37935 14.1331 7.46701 14.4445 7.46701 14.7951C7.46701 15.1004 7.4126 15.3876 7.30378 15.6566C7.19799 15.9226 7.03174 16.1961 6.80504 16.4772C6.58136 16.7584 6.18538 17.1589 5.61712 17.6788L4.76472 18.4813V18.5448H7.6529V19.7236Z"  fill="currentColor"/>
      <path d="M23.0008 17.2854H11.5723V18.714H23.0008V17.2854Z"  fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_522_1220">
      <rect width="20" height="20" fill="white" transform="translate(3 3)"/>
      </clipPath>
      </defs>
      </svg>`;

  // icons['list'][
  //   'bullet'
  // ] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
  //       <path d="M2.5 4.5C3.32843 4.5 4 3.82843 4 3C4 2.17157 3.32843 1.5 2.5 1.5C1.67157 1.5 1 2.17157 1 3C1 3.82843 1.67157 4.5 2.5 4.5Z" fill="currentColor"/>
  //       <path d="M13 2.5H6V3.5H13V2.5Z" fill="currentColor"/>
  //       <path d="M2.5 8.5C3.32843 8.5 4 7.82843 4 7C4 6.17157 3.32843 5.5 2.5 5.5C1.67157 5.5 1 6.17157 1 7C1 7.82843 1.67157 8.5 2.5 8.5Z" fill="currentColor"/>
  //       <path d="M13 6.5H6V7.5H13V6.5Z" fill="currentColor"/>
  //       <path d="M2.5 12.5C3.32843 12.5 4 11.8284 4 11C4 10.1716 3.32843 9.5 2.5 9.5C1.67157 9.5 1 10.1716 1 11C1 11.8284 1.67157 12.5 2.5 12.5Z" fill="currentColor"/>
  //       <path d="M13 10.5H6V11.5H13V10.5Z" fill="currentColor"/>
  //     </svg>`;

  icons['list'][
    'bullet'
  ] = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.57254 8.71394C7.75601 8.71394 8.7154 7.75455 8.7154 6.57108C8.7154 5.38761 7.75601 4.42822 6.57254 4.42822C5.38908 4.42822 4.42969 5.38761 4.42969 6.57108C4.42969 7.75455 5.38908 8.71394 6.57254 8.71394Z"  fill="currentColor"/>
      <path d="M21.5723 5.85693H11.5723V7.28551H21.5723V5.85693Z"  fill="currentColor"/>
      <path d="M6.57254 14.4282C7.75601 14.4282 8.7154 13.4688 8.7154 12.2853C8.7154 11.1018 7.75601 10.1425 6.57254 10.1425C5.38908 10.1425 4.42969 11.1018 4.42969 12.2853C4.42969 13.4688 5.38908 14.4282 6.57254 14.4282Z"  fill="currentColor"/>
      <path d="M21.5723 11.5713H11.5723V12.9999H21.5723V11.5713Z"  fill="currentColor"/>
      <path d="M6.57254 20.1425C7.75601 20.1425 8.7154 19.1831 8.7154 17.9997C8.7154 16.8162 7.75601 15.8568 6.57254 15.8568C5.38908 15.8568 4.42969 16.8162 4.42969 17.9997C4.42969 19.1831 5.38908 20.1425 6.57254 20.1425Z"  fill="currentColor"/>
      <path d="M21.5723 17.2852H11.5723V18.7137H21.5723V17.2852Z"  fill="currentColor"/>
      </svg>`;
  return;
};

export const resetIcons = () => {
  icons['align'][
    ''
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=3 x2=15 y1=9 y2=9></line> <line class=ql-stroke x1=3 x2=13 y1=14 y2=14></line> <line class=ql-stroke x1=3 x2=9 y1=4 y2=4></line> </svg>`;
  icons['align'][
    'center'
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=15 x2=3 y1=9 y2=9></line> <line class=ql-stroke x1=15 x2=3 y1=14 y2=14></line> <line class=ql-stroke x1=15 x2=3 y1=4 y2=4></line> </svg>`;
  icons['list'][
    'ordered'
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=7 x2=15 y1=4 y2=4></line> <line class=ql-stroke x1=7 x2=15 y1=9 y2=9></line> <line class=ql-stroke x1=7 x2=15 y1=14 y2=14></line> <line class="ql-stroke ql-thin" x1=2.5 x2=4.5 y1=5.5 y2=5.5></line> <path class=ql-fill d=M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z></path> <path class="ql-stroke ql-thin" d=M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156></path> <path class="ql-stroke ql-thin" d=M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109></path> </svg>`;
  icons['list'][
    'bullet'
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=6 x2=15 y1=4 y2=4></line> <line class=ql-stroke x1=6 x2=15 y1=9 y2=9></line> <line class=ql-stroke x1=6 x2=15 y1=14 y2=14></line> <line class=ql-stroke x1=3 x2=3 y1=4 y2=4></line> <line class=ql-stroke x1=3 x2=3 y1=9 y2=9></line> <line class=ql-stroke x1=3 x2=3 y1=14 y2=14></line> </svg>`;
  return;
};
