import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  .select-client__select-label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-right: 20px;
  }

  .select-client--select-input {
    flex: 1 1;
    max-width: calc(100% - 60px);
  }

  .multi-select-container.select-client--select-input .multi-select__value-container {
    padding: 9px 15px;
    cursor: text;
  }

  .multi-select-container.select-client--select-input
    .multi-select__value-container.multi-select__value-container--has-value {
    padding: 2.5px 10px;
  }

  .multi-select-container .multi-select__control {
    border-color: #d4d7d9;
  }

  .select-client--select-input .multi-select__placeholder {
    font-size: 12px;
    line-height: 12px;
    color: #777777;
    margin-top: 2px;
  }

  .select-client--select-input .multi-select__input {
    color: #323c47;
  }
`;

export const Heading = styled.div`
  margin-bottom: 25px;

  .select-client__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }

  .select-client__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
    margin-top: 5px;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 7px 42px;
  }
`;

export const Wrapper = styled.div`
  .select-client__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
    margin-top: 5px;
  }
`;
