import React from 'react';
import { Map } from 'immutable';
import type { Dispatch } from 'types/redux';
import { Button, Header, Icon, Modal, Image } from 'semantic-ui-react';
import { hideSuccess } from 'redux/modal/modal.actionCreators';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';

type Props = {
  isShowing: boolean,
  dispatch: Dispatch
};

export default class SuccessPopup extends React.Component<Props, State> {
  render() {
    return (
      <Modal open={this.props.isShowing} className="success-popup-container">
        <Modal.Content className="success-content">
          <div className="success-content-header">
          <Image src={`${CDN_URL}/images/checkbox_checked.svg`} className="success-header-image" />
            <p className="success-header-label">
              {this.props.title ? this.props.title : 'Success'}
            </p>
          </div>
          <div className="success-content-body">
            <div className="success-content-label">{this.props.content}</div>
          </div>
        </Modal.Content>
        <Modal.Actions className="success-actions">
          <Button
            className="success-close-button"
            id="success-popup-close-button"
            onClick={() => {
              if (this.props.closeAction) {
                this.props.closeAction()
              }
              this.props.dispatch(hideSuccess());
            }}
          >
            {this.props.acttionButtonTitle || 'Close'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
