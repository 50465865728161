import styled from 'styled-components';

export const CustomTimeOptionWrapper = styled.div`
  .evfSelectBox__menu {
    margin-bottom: 8px !important;
    overflow: hidden !important;
  }
  .evfSelectBox__menu-list {
    padding: 0 !important;
    height: 180px !important;
    ::-webkit-scrollbar {
      width: 6px !important;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 24px !important;
      background: #d9d9d9 !important;
    }
    .evfSelectBox__option {
      padding: 0 16px !important;
      height: 41px;
      display: flex;
      align-items: center;

      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #262626 !important;
      &:hover,
      &.evfSelectBox__option--is-focused,
      &.evfSelectBox__option--is-selected {
        color: #5158cf !important;
        background: #f4f4fc !important;
        font-weight: 600 !important;
      }
    }
  }
  .evfSelectBox__control {
    width: 128px !important;
    min-height: 36px !important;
    background-color: #ffffff !important;
    border: 1px solid #d6dae4 !important;
    padding-right: 5px !important;
    &:hover {
      background-color: #f5f5f5 !important;
    }

    .evfSelectBox__single-value {
      font-family: 'Open Sans';
      font-size: 13px !important;
      font-weight: 400 !important;
      line-height: 18px !important;
      color: #262626 !important;
    }

    .evfSelectBox__value-container {
      padding: 0 8px !important;
    }
    .evfSelectBox__indicators {
      padding-right: 12px !important;
    }
    .evfSelectBox__placeholder {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #747474 !important;
      opacity: 1 !important;
    }
  }

  .evfSelectBox__control--menu-is-open {
    .evfSelectBox__indicators {
      transform: rotate(180deg);
      padding-left: 12px !important;
    }
  }
  .error {
    .evfSelectBox__control {
      border: 1px solid #ea314a !important;
    }
  }
`;
