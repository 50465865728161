import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:disabled {
    background: #f8f8f8;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  span {
    user-select: none;
  }
`;

export const ButtonUpdateWeek = styled.div`
  position: absolute;
  right: 15px;
  width: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  svg {
    width: 11px;
    height: 18px;
  }
  &.increase-week {
    transform: rotate(180deg);
    top: 25px;
  }
  &.decrease-week {
    bottom: 25px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      svg path {
        stroke: #f0f1ff;
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &.decrease-week {
        bottom: 54px;
      }
    `}
`;
