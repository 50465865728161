import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { pluralize } from 'utils/commonFunction';
import ShareOwnerAvatar from 'shared/AssetsShareSetting/components/ShareOwnerAvatar';
import * as S from './style';

const ProgramOption = ({ data, cloudfrontList }) => {
  const no_of_weeks = get(data, 'workout_sets', []).length;
  const totalWorkouts = get(data, 'total_workouts', 0);
  const author = get(data, 'author', {});

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title className="title">{get(data, 'title', '')}</S.Title>
        {get(data, 'active_sync', false) ? <S.BadgeWrapper>Live Sync</S.BadgeWrapper> : null}
      </S.TitleWrapper>
      {!!get(data, '_id', '') && (
        <S.InfoWrapper>
          {no_of_weeks >= 0 && (
            <div className="item-info">
              <S.InfoText>
                {no_of_weeks} {pluralize('WEEK', no_of_weeks)}
              </S.InfoText>
            </div>
          )}
          {totalWorkouts >= 0 && (
            <div className="item-info">
              <S.InfoText>
                {totalWorkouts} {pluralize('WORKOUT', totalWorkouts)}
              </S.InfoText>
            </div>
          )}
          {!isEmpty(author) && (
            <div className="item-info">
              <ShareOwnerAvatar
                user={author}
                key={`avatar-${data._id}-${author._id}`}
                tooltipId={`avatar-tooltip-${data._id}-${author._id}`}
                cloudfrontList={cloudfrontList}
              />
            </div>
          )}
        </S.InfoWrapper>
      )}
    </S.Wrapper>
  );
};

export default React.memo(ProgramOption);
