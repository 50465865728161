import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 619px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
`;

export const WrapperTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  height: 34px;

  .image-button {
    color: #202353;
    font-size: 16px;
  }

  .image-button > div {
    padding-left: 13px;
  }

  .ui.button.general-button {
    background: #ffffff;
    box-shadow: none !important;
    color: #5158cf !important;
    border: 1px solid #5158cf;
    padding: 7px 40px;
    margin: 0px;
    border-radius: 5px;
    font-size: 13px;
    line-height: 18px;
    max-width: 110px;
    height: 100%;

    &:hover,
    :focus {
      background-color: #5158cf !important;
      border-color: #5158cf !important;
      color: #ffffff !important;
    }
  }
`;

export const ButtonBackWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 9px;
  }
`;

export const ButtonContainer = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;

  &:hover {
    opacity: 1;

    .button-title {
      text-decoration: underline;
      color: #5158cf;
    }
    svg path {
      stroke: #5158cf;
    }
  }
`;

export const ButtonTitle = styled.span`
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  max-width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const WrapperContent = styled.div`
  width: 100%;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px 0px #9292d226;
  border-radius: 5px;
  padding: 20px;
`;

export const CoachDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 70px;
  background: #fafbfc;
  border-radius: 4px;
`;

export const Avatar = styled.div``;

export const ToggleShowCoach = styled.div`
  display: flex;
  .toggle-label {
    color: #222;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 20px;
  }
`;

export const CoachDetailInfo = styled.div`
  flex: 1 1;
  padding-left: 15px;
  width: 92%;
`;

export const CoachName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const CoachEmail = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const CoachForm = styled.div`
  color: #323c47;
  font-family: 'Open Sans';
  font-style: normal;

  .formControl {
    margin-bottom: 20px;
  }

  input {
    padding: 9px 0 9px 15px;
    border: 1px solid #dadfea;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    height: 36px;
  }

  input::placeholder {
    line-height: 18px;
    font-weight: 400;
    font-size: 13px;
    color: #323c47;
    opacity: 0.4;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;

  .loading-detail {
    height: 70px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
