import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { push } from 'connected-react-router';

import Component from './component';

import {
  changeQueryParams,
  checkNoFirstRecipe,
  duplicateRecipe,
  getInitTotalRecipe,
  getRecipeBackgroundLibraries,
  getRecipeLibraries,
  getTotalRecipe,
  removeRecipe,
  resetFilters,
  resetCurrentList,
  setHeaderTabs,
  updateRecipeSharingStatus,
  getTotalRecipeInTeam,
} from 'redux/recipes/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, permission },
    user,
    cloudfrontList,
  } = state;

  return {
    user,
    list: get(recipes, 'list', []),
    loading: get(recipes, 'loading', false),
    filters: get(recipes, 'filters', {}),
    allTotal: get(recipes, 'allTotal', 0),
    myRecipeTotal: get(recipes, 'myRecipeTotal', 0),
    totalDraft: get(recipes, 'totalDraft', 0),
    total: get(recipes, 'total', 0),
    initTotal: get(recipes, 'initTotal', null),
    listCoverImage: get(recipes, 'coverBackground.list', []),
    tab: get(recipes, 'tab'),
    maxCalories: get(recipes, 'maxCalories'),
    cloudfrontList,
    permission,
    totalRecipeInTeam: (recipes || {}).totalRecipeInTeam,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  getRecipeLibraries: bindActionCreators(getRecipeLibraries, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeRecipe: bindActionCreators(removeRecipe, dispatch),
  getTotalRecipe: bindActionCreators(getTotalRecipe, dispatch),
  resetFilters: bindActionCreators(resetFilters, dispatch),
  updateRecipeSharingStatus: bindActionCreators(updateRecipeSharingStatus, dispatch),
  getRecipeBackgroundLibraries: bindActionCreators(getRecipeBackgroundLibraries, dispatch),
  duplicateRecipe: bindActionCreators(duplicateRecipe, dispatch),
  checkNoFirstRecipe: bindActionCreators(checkNoFirstRecipe, dispatch),
  getInitTotalRecipe: bindActionCreators(getInitTotalRecipe, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  setHeaderTabs: bindActionCreators(setHeaderTabs, dispatch),
  resetCurrentList: bindActionCreators(resetCurrentList, dispatch),
  getTotalRecipeInTeam: bindActionCreators(getTotalRecipeInTeam, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
