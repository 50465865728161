import _ from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  autoflow: true,
  program: true,
  food_journal: true,
  segment: true,
  digest_email: true,
  team: true,
  branding: true,
  custom_metrics: true,
  assignment_scheduling: 4,
  no_clients: 5,
  mass_copy: true,
  isInited: false,
  studio_program: false,
  studio_resource_collection: true,
  product: false,
  on_demand_workout: true, // TODO: should switch to false when API's permission key is available
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.INIT_PERMISSION_DATA_SUCCESS:
      return Object.assign({}, state, payload.data, { isInited: true });
    default:
      return state;
  }
};
