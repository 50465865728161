import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route } from 'react-router-dom';

import {
  getStudioCollectionDetail,
  resetStudioCollectionDetail,
  resetClientAndUpdateRedux,
} from 'redux/studio-collection/actions';
import CollectionResources from './CollectionResources';
import CollectionClients from './CollectionClients';

class StudioCollectionDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { match } = this.props;
    const studioCollectionId = match.params.collectionId;
    this.props.getStudioCollectionDetail(studioCollectionId);
  }

  componentWillUnmount() {
    this.props.resetStudioCollectionDetail();
    this.props.resetClientAndUpdateRedux();
  }

  render() {
    if (!this.props.workingCollection) {
      return null;
    }

    return (
      <Switch>
        <Route
          exact
          path="/home/studio-collection/:collectionId?"
          render={props => <CollectionResources {...props} />}
        />
        <Route exact path="/home/studio-collection/:collectionId/clients" component={CollectionClients} />
      </Switch>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      studioCollection: { workingCollection },
    },
  } = state;

  return { workingCollection };
};

const mapDispatch = dispatch => ({
  getStudioCollectionDetail: bindActionCreators(getStudioCollectionDetail, dispatch),
  resetStudioCollectionDetail: bindActionCreators(resetStudioCollectionDetail, dispatch),
  resetClientAndUpdateRedux: bindActionCreators(resetClientAndUpdateRedux, dispatch),
});

export default connect(mapState, mapDispatch)(StudioCollectionDetail);
