import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const Loading = styled(Modal)`
  &.ui.modal {
    background: transparent;
    box-shadow: none;
  }
  .ui.segment {
    box-shadow: none;
    margin: 0;
    height: 100%;
    background: transparent;
    border: 0;
    padding: 0;
    border-radius: 0;
  }
  .ui.inverted.dimmer {
    padding: 0;
    height: 0;
    color: #fff;
  }
  .ui.inverted.dimmer .ui.loader {
    color: #fff;
  }
  .ui.inverted.dimmer .ui.loader:after {
    border-color: #fff transparent transparent;
  }
  .ui.inverted.dimmer .ui.loader:before {
    border-color: rgb(145 140 140 / 50%);
  }
`;
