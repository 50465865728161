import styled from 'styled-components';

export const SkeletonWrapper = styled.div`
  height: 285px;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  animation: custom-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes custom-pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }

  .skeleton-thumbnail {
    top: 0;
    height: 168px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    background: #f8f8f8;
  }

  .skeleton-info {
    padding: 10px 15px 21px;
    border: 1px solid #f0f0f2;
    border-top: unset;
    border-radius: 0 0 5px 5px;

    .skeleton-title {
      width: 100%;
      height: 40px;
      background: #f8f8f8;
      border-radius: 5px;
      margin-bottom: 3px;
    }

    .skeleton-unit {
      width: 100%;
      height: 18px;
      background: #f8f8f8;
      border-radius: 5px;
      margin-bottom: 5px;
    }

    .skeleton-owner {
      width: 35%;
      height: 18px;
      background: #f8f8f8;
      border-radius: 5px;
    }
  }
`;
