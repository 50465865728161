// libs
import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import get from 'lodash/get';

// components
import { formatDateChild } from '../NormalMetricProgress';
import ReactTooltip from 'react-tooltip';
import ConfirmModalGroup from 'components/MetricGroupLibrary/Parts/ConfirmModal';
import AddResultPopup from '../AddResultPopup';
import LoadingIndicator from 'shared/LoadingIndicator';

// common
import { roundNumberBodyMetric } from 'utils/commonFunction';

// redux
import { getHeartRateDetailEntriesByDay } from 'redux/heart-rate/action';

// assets
import { ReactComponent as AppleHealth } from 'assets/icons/ConnectedApp/apple_health.svg';
import { ReactComponent as Fitbit } from 'assets/icons/ConnectedApp/fitbit.svg';
import { ReactComponent as Garmin } from 'assets/icons/ConnectedApp/garmin.svg';
import { ReactComponent as GoogleFit } from 'assets/icons/ConnectedApp/google_fit.svg';
import { ReactComponent as OuraIcon } from 'assets/icons/ConnectedApp/oura_icon.svg';
import { ReactComponent as SmartWatchIcon } from 'assets/icons/ConnectedApp/smart_watch.svg';
import { ReactComponent as EditIcon } from 'assets/icons/metric-edit-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete-trash.svg';
import CloseIcon from 'assets/icons/close_bold_circle.svg';

// style
import * as S from './style';
import { TooltipStyle } from 'components/BodyMetricChartNew/components/KeysMetric/style';
import { CDN_URL } from 'constants/commonData';

export const APP_INTEGRATION = {
  apple_health: {
    name: 'apple_health',
    icon: <AppleHealth />,
  },
  fitbit: {
    name: 'fitbit',
    icon: <Fitbit />,
  },
  garmin: {
    name: 'garmin',
    icon: <Garmin />,
  },
  google_fit: {
    name: 'google_fit',
    icon: <GoogleFit />,
  },
  oura: {
    name: 'oura',
    icon: <OuraIcon />,
  },
  client: {
    name: 'client',
    icon: <SmartWatchIcon className="smart-watch-icon" />,
  },
};

const PER_PAGE = 20;

function AllEntriesByDayHeartRate(props) {
  const {
    clientId,
    timezone,
    dateFormat,
    fetchEntry,
    toggleModal,
    metricTypes,
    selectedMetric,
    selectedDate = '',
    toggleConfirmModal,
    updateHeartRateEntry,
    removeHeartRateEntry,
    getHeartRateDetailEntriesByDay,
  } = props;
  const topRef = useRef(null);
  const [editResult, setEditResult] = useState(null);
  const [allEntries, setAllEntries] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const isOuraPermission = process.env.REACT_APP_ENABLE_OURA_INTEGRATION === 'true';

  useEffect(() => {
    getAllEntries();
  }, []);

  const getAllEntries = (page = 1) => {
    const params = {
      client: clientId,
      from_date: selectedDate,
      to_date: selectedDate,
      page: page,
      per_page: PER_PAGE,
      timezone,
      sort: -1,
    };
    setLoading(true);
    getHeartRateDetailEntriesByDay(params)
      .then(res => {
        const { data = [], total = 0 } = res.data;
        setLoading(false);
        if (total === 0) {
          toggleModal(false);
          return;
        }
        const newList = page === 1 ? data : [...allEntries, ...data];
        setAllEntries(newList);
        const endPage = Math.ceil(total / PER_PAGE);
        setIsEnd(page >= endPage);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleScrollEnd = event => {
    const { clientHeight = 0, scrollHeight = 0, scrollTop = 0 } = event.target;
    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    if (bottom && !loading && !isEnd) {
      const currentPage = page + 1;
      getAllEntries(currentPage);
      setPage(currentPage);
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const handleClose = () => {
    if (!editResult) {
      toggleModal && toggleModal(false);
    }
  };

  const formatValueMetric = ({ value, unit: unitItem }) => {
    const { selectedMetric: { metricSettings = {} } = {} } = props;
    const valueRounded = roundNumberBodyMetric(value, 1);
    const unit = (metricSettings || {}).unit || unitItem;
    if (!unit) return valueRounded;
    return `${valueRounded} ${unit.title}`;
  };

  const handleRemoveHeartRateEntry = item => () => {
    removeHeartRateEntry(item._id, clientId).then(() => {
      const newList = allEntries.filter(it => it._id !== item._id);
      setAllEntries(newList);
      if (newList.length === 0) {
        toggleModal(false);
      }
      fetchEntry(selectedMetric, false, true);
    });
  };

  const handleRemoveResultItem = item => () => {
    toggleConfirmModal(
      true,
      <ConfirmModalGroup
        className="delete-result-modal"
        title="Remove Result"
        content="Are you sure you want to remove this result?"
        onConfirm={handleRemoveHeartRateEntry(item)}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        hasCloseIcon
        toggleConfirmModal={toggleConfirmModal}
        isDiscardChange
      />,
    );
  };

  const handleCloseAddResultPopup = () => {
    setEditResult(null);
  };

  const handleSaveResultClick = async (data, entry) => {
    const deviceTz = moment.tz.guess();
    try {
      const payload = { ...data, client: clientId, timezone: deviceTz };
      if (editResult) {
        if (isEmpty(payload.entryId)) {
          payload.entryId = editResult._id;
        }
        if (isEmpty(payload.unit)) {
          payload.unit = editResult.unit;
        }
        await updateHeartRateEntry(payload, editResult._id).then(res => {
          const newMetric = get(res, 'data.data');
          const { _id = '' } = newMetric || {};
          if (_id) {
            setAllEntries([]);
            setPage(1);
            getAllEntries();
            fetchEntry(selectedMetric, false, true);
          }
        });
      }
    } catch (error) {}
    setEditResult(null);
  };

  const handleEditResultClick = item => () => {
    if (!editResult) {
      setEditResult(item);
    }
  };

  const navigateWorkoutResult = assignment_id => () => {
    if (!assignment_id) return;
    const navigateLink = `/home/client/${clientId}/calendar/${assignment_id}/history`;
    window.open(navigateLink, '_blank');
  };

  const renderEntriesItem = entry => {
    const { date, value, unit, _id, agent = '', source, assignment_id = '', sync_from = '' } = entry;

    const isEditing = editResult && editResult._id === _id;
    const isManual = agent === 'manual';
    const formattedDate = formatDateChild(date, timezone);
    const dateISO = DateTime.fromISO(date).toLocal();
    const isSyncFromOtherWS = sync_from !== clientId;

    // Handle to hide oura
    if (!isOuraPermission && source === APP_INTEGRATION.oura.name) return null;

    return (
      <S.EntryItemWrapper
        key={_id}
        active={isEditing}
        className="heart-rate-entries-item"
        onClick={navigateWorkoutResult(isSyncFromOtherWS ? '' : assignment_id)}
      >
        <S.EntryDate>
          <span className="date-label">{formattedDate}</span>
        </S.EntryDate>
        <S.EntryTime isDisableSmartWatch={isSyncFromOtherWS}>
          {dateISO.toFormat('h:mm a')} {!isManual && APP_INTEGRATION[source].icon}
        </S.EntryTime>
        <S.EntryResult>
          <span>{formatValueMetric({ value, unit })}</span>
        </S.EntryResult>
        {isManual && (
          <S.HeartRateEntryAction>
            <S.EditWrapper>
              <AddResultPopup
                positionEdit="bottom right"
                newUI
                editData={entry}
                isOpen={isEditing}
                key={`${_id}_${isEditing ? 'open' : 'close'}`}
                timezone={timezone}
                dateFormat={dateFormat}
                metric={selectedMetric}
                metricTypes={metricTypes}
                onClose={handleCloseAddResultPopup}
                onSave={newData => handleSaveResultClick(newData, entry)}
                onOpen={handleEditResultClick(entry)}
                className="edit-entry-popup"
                trigger={
                  <S.EditWrapper
                    onClick={e => e.preventDefault()}
                    data-tip
                    data-for={`edit-result-on-tooltip-${_id}`}
                    active={isEditing}
                  >
                    <EditIcon className="edit-entry-icon" />
                    <ReactTooltip
                      className="app-tooltip edit-result-on-tooltip"
                      id={`edit-result-on-tooltip-${_id}`}
                      effect="solid"
                      place={'top'}
                    >
                      <TooltipStyle>Edit Result</TooltipStyle>
                    </ReactTooltip>
                  </S.EditWrapper>
                }
              />
            </S.EditWrapper>
            <S.RemoveWrapper
              onClick={handleRemoveResultItem(entry)}
              data-tip
              data-for={`remove-result-on-tooltip-${_id}`}
            >
              <TrashIcon className="trash-icon" />
              <ReactTooltip
                className="app-tooltip remove-result-on-tooltip"
                id={`remove-result-on-tooltip-${_id}`}
                effect="solid"
                place={'top'}
              >
                <TooltipStyle>Remove Result</TooltipStyle>
              </ReactTooltip>
            </S.RemoveWrapper>
          </S.HeartRateEntryAction>
        )}
      </S.EntryItemWrapper>
    );
  };

  return (
    <S.CustomModal open={true} closeOnDimmerClick={false} className="all-entries-modal" onClose={handleClose}>
      <Modal.Header className="modal-meal-plan-header">
        <S.HeaderWrapper>
          <S.HeaderTitle>{formatDateChild(selectedDate, timezone)} Entries</S.HeaderTitle>
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper isHeartRate={true}>
          <S.HeaderTable className="heart-rate-header">
            <S.HeaderDateLabel>Date</S.HeaderDateLabel>
            <S.HeaderTimeLabel>Time</S.HeaderTimeLabel>
            <S.HeaderResultLabel>Results</S.HeaderResultLabel>
          </S.HeaderTable>
          <S.ContentTable
            onScroll={event => {
              event.persist();
              onScrollDebounce.call(null, event);
            }}
          >
            <div ref={topRef} />
            {allEntries.map(renderEntriesItem)}
            {loading && <LoadingIndicator className="entry-by-day-loading" />}
          </S.ContentTable>
        </S.ContentWrapper>
      </Modal.Content>
    </S.CustomModal>
  );
}

const actionCreators = {
  getHeartRateDetailEntriesByDay,
};

export default connect(null, actionCreators)(AllEntriesByDayHeartRate);
