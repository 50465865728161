import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RoomInformation from './component';

import { getIndividualRoomInformation, getFeatureStatus } from 'redux/inbox-new/actions';
import { resetRedirectInboxSegment } from 'redux/segment/actions';

const mapState = state => {
  const {
    cloudfrontList,
    user,
    rootReducer: {
      inboxNew: { profileId, roomInformation, viewTeammate, loadedRooms, rooms, selectedRoom, members, invalidRoom },
      permission,
    },
  } = state;

  return {
    cloudfrontList,
    profileId,
    roomInformation,
    viewTeammate,
    user,
    loaded: loadedRooms,
    rooms,
    selectedRoom,
    members,
    invalidRoom,
    isGroupChatPermission: get(permission, 'group_chat', false),
  };
};

const mapDispatch = dispatch => ({
  getIndividualRoomInformation: bindActionCreators(getIndividualRoomInformation, dispatch),
  getFeatureStatus: bindActionCreators(getFeatureStatus, dispatch),
  resetRedirectInboxSegment: bindActionCreators(resetRedirectInboxSegment, dispatch),
});

export default connect(mapState, mapDispatch)(RoomInformation);
