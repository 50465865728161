import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as Layout from 'shared/LibraryLayout';
import * as Icons from 'shared/LibraryLayout/Icons';
import { changeQueryParams, updateSelectedWorkout } from 'redux/workout_library/workout.actionCreators';
import { Checkbox } from 'shared/FormControl';
import { WorkoutHeader, CheckboxCellContent } from '../style';
import { WorkoutIcon, MostRecentIcon, OwnerIcon } from './icons';
import { ReactComponent as TagsIcon } from 'assets/icons/tag.svg';

const Columns = [
  {
    key: 'tags',
    name: 'Tags',
    hideSort: true,
    alignCenter: false,
    icon: <TagsIcon className="column-symbol" />,
  },
  {
    key: 'exercise',
    name: 'Exercises',
    hideSort: true,
    icon: <WorkoutIcon className="column-symbol" />,
  },
  {
    key: 'last_interacted',
    name: 'Most Recent',
    alignCenter: true,
    icon: <MostRecentIcon className="column-symbol" />,
  },
  {
    key: 'author.full_name',
    name: 'Owner',
    hideSort: true,
    icon: <OwnerIcon className="column-symbol" />,
  },
];

const mostRecentKey = 'last_interacted';

function View(props) {
  const { total, sort, sorter, listWorkout, selectedWorkout } = props;
  const ids = _.map(listWorkout, '_id');
  const isSelectedAll = selectedWorkout.length && _.difference(ids, selectedWorkout).length === 0;

  const onSort = key => {
    props.changeQueryParams({
      sorter: key,
      sort: key === sorter ? sort * -1 : key === mostRecentKey ? -1 : 1,
    });
  };

  const renderSortIcon = key => {
    if (key !== sorter) {
      return <Icons.ArrowDown />;
    }

    let arrow;

    if (sorter === mostRecentKey) {
      arrow = sort === 1 ? 'up' : 'down';
    } else {
      arrow = sort === -1 ? 'up' : 'down';
    }

    return arrow === 'up' ? <Icons.ArrowUp active /> : <Icons.ArrowDown active />;
  };

  const handleSelectAllWorkout = e => {
    let selectedList = [];

    if (e.target.checked) {
      props.updateSelectedWorkout([...selectedWorkout, ...ids]);
      selectedList = [...selectedWorkout, ...ids];
    } else {
      props.updateSelectedWorkout(selectedWorkout.filter(id => !ids.includes(id)));
      selectedList = selectedWorkout.filter(id => !ids.includes(id));
    }

    props.onCountTagBySelected(_.filter(listWorkout, item => selectedList.includes(item._id)));
  };

  return (
    <Layout.TableHeader>
      <Layout.TableRow>
        <WorkoutHeader key="title" className="title">
          <CheckboxCellContent>
            <Layout.TableHeaderCellContent className="checkbox">
              <Checkbox checked={isSelectedAll} onChange={handleSelectAllWorkout} />
            </Layout.TableHeaderCellContent>
            <Layout.TableHeaderCellContent hasSort active={sorter === 'title'} onClick={() => onSort('title')}>
              <WorkoutIcon className="column-symbol" />
              <Layout.CellTitle>Workouts ({total})</Layout.CellTitle>
              {renderSortIcon('title')}
            </Layout.TableHeaderCellContent>
          </CheckboxCellContent>
        </WorkoutHeader>
        {_.map(Columns, item => {
          return (
            <WorkoutHeader key={item.key} alignCenter={item.alignCenter}>
              <Layout.TableHeaderCellContent
                hasSort={!item.hideSort}
                active={sorter === item.key}
                onClick={() => !item.hideSort && onSort(item.key)}
              >
                {item.icon}
                <Layout.CellTitle>{item.name}</Layout.CellTitle>
                {!item.hideSort && renderSortIcon(item.key)}
              </Layout.TableHeaderCellContent>
            </WorkoutHeader>
          );
        })}
        <Layout.TableHeaderCell key="actions" className="actions" />
      </Layout.TableRow>
    </Layout.TableHeader>
  );
}

const TableHeader = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      workoutLibrary: {
        listWorkout,
        selectedWorkout,
        total,
        query: { sort, sorter },
      },
    },
  } = state;

  return { total, sorter, sort, listWorkout, selectedWorkout };
};

const mapDispatch = dispatch => {
  return {
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    updateSelectedWorkout: bindActionCreators(updateSelectedWorkout, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableHeader);
