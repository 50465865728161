import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 204px;
`;

export const SidebarTitle = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 18px;

  font-weight: 700;
  font-size: 16px;
`;

export const SidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 4px 11px 0 10px;
  padding: 6px 14px;

  cursor: pointer;

  color: #202353;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;

  :hover {
    text-decoration: underline;
  }

  &.active {
    border-radius: 5px;
    background: #eaeaff;
  }
`;

export const Content = styled.div`
  border-left: 1px solid #e3eced;
  overflow-x: scroll;
  width: 100%;
`;

export const FeatureSettingGuide = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 40px;

  .features__guide__note {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      max-width: 533px;
      margin-bottom: 0;
    }
  }

  .features__guide__link {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6456c4;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 4px;
    }

    :hover {
      text-decoration: underline;
      color: #6456c4;
    }
  }
`;

export const Left = styled.div`
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #222222;
  padding-right: 10px;

  .setting__name {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    img.ui {
      margin-right: 10px;
      margin-top: 2px;
    }
  }
`;

export const Right = styled.div`
  text-align: right;
`;

export const Center = styled.div`
  .newexercise-dropdown {
    max-width: 385px;

    .exl__indicators {
      display: none;
    }

    .exl__menu {
      z-index: 999;
    }
  }

  .unit-client-toggle {
    margin-top: 30px;
  }
`;

export const Units = styled.div`
  max-width: 650px;
  display: flex;
  justify-content: space-between;

  .fields {
    .ui.label {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #6a778a;
      margin-bottom: 12px;
      padding: 0;
    }

    .field {
      margin-bottom: 13px;

      .ui.checkbox {
        label {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #222222;
        }
      }

      :last-child {
        margin-bottom: 0;
      }

      &.isactive {
        .ui.checkbox {
          label {
            color: #99a1b1;
          }
        }
      }
    }
  }
`;

export const Section = styled.div`
  margin: 0;
  padding: 30px 0;
  border-bottom: 1px solid #e3eced;
  display: grid;
  grid-template-columns: 1fr 55% 1fr;
`;
