import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import * as S from '../styles';

const ProgressBar = ({ progress }) => (
  <S.ProgressBar progress={progress}>
    <div className="filled-bar" />
  </S.ProgressBar>
);

export default function SignatureSummary({ data }) {
  const [titleHeight, setTitleHeight] = useState(28); // default one line
  const hasResponses = get(data, 'summary.no_responses');

  useEffect(() => {
    updateTitleHeight();
    window.addEventListener('resize', updateTitleHeight);
    return () => window.removeEventListener('resize', updateTitleHeight);
  }, []);
  const updateTitleHeight = () => {
    const headerElement = document.getElementById(`${data._id}-header`);
    if (headerElement) {
      setTitleHeight(headerElement.offsetHeight);
    }
  };

  return (
    <S.SingleChoiceSummary titleHeight={titleHeight} id={`${data._id}-scrolling-container`}>
      <div className="option">
        <div className="info">
          <span>Signing Progress</span>
          <div className="option-summary">
            <span className="percentage">{hasResponses ? 100 : 0}%</span>
          </div>
        </div>
        <ProgressBar progress={hasResponses ? 100 : 0} />
      </div>
    </S.SingleChoiceSummary>
  );
}
