import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { MAX_FILE_SIZE, CONVERSION, LIMIT_SIZE_DOCUMENT_UPLOAD_25MB, CDN_URL } from 'constants/commonData';
import * as S from './style';
import { SelectFile } from 'shared/FormControl';
import { createObjectURL, downloadFileFromBlobURI, revokeObjectURL } from 'utils/commonFunction';
import { toast } from 'react-toastify';
import { VALIDATIONS } from '../../helper';
import MediaUploadProgress from 'shared/MediaUploadProgress';

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
};

function detectFileIcon(file, document_type) {
  let type = document_type;

  if (file) {
    const { name, original_name } = file;
    type = _.last((original_name || name || '').split('.'));
  }

  switch (type) {
    case 'docx':
    case 'doc':
      return `${CDN_URL}/images/task_attachment_doc_icon.svg`;
    case 'pdf':
      return `${CDN_URL}/images/task_attachment_pdf_icon.svg`;
    case 'xlsx':
    case 'xls':
    case 'csv':
      return `${CDN_URL}/images/task_attachment_xlsx_icon.svg`;
    default:
      return '';
  }
}

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.maxSize = MAX_FILE_SIZE * CONVERSION.MB_TO_BYTE;
    this.uploadConfigs = null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.file && this.props.file) {
      this.onSelectFile(this.props.file);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }
  }

  onSelectFile = file => {
    if (file.size > VALIDATIONS.DOCUMENT_25MB.maxSize) {
      return toast.error(VALIDATIONS.DOCUMENT_25MB.errorMessage);
    }

    const formData = new FormData();
    formData.append('attachments', file);
    this.uploadConfigs = {
      url: '/api/task/v3/upload',
      method: 'post',
      data: formData,
    };
    const fileIcon = detectFileIcon(file);
    this.setState({ formData, src: createObjectURL(file), isLocalUrl: true, file, fileIcon }, () => {
      this.props.onStartUpload(file);
    });
  };

  onDropFile = files => {
    if (files.length) {
      const file = files[0];
      if (file.size > VALIDATIONS.DOCUMENT_25MB.maxSize) {
        return toast.error(VALIDATIONS.DOCUMENT_25MB.errorMessage);
      }
      this.onSelectFile(file);
    } else {
      toast.error('File type not supported.');
    }
  };

  onUploadSuccess = data => {
    if (this._isMounted) {
      this.uploadConfigs = null;
      const { size } = this.state.file;
      this.setState({ file: null }, () => {
        this.props.onUploadSuccess(data[0]);
      });
    }
  };

  onRemoveImage = () => {
    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }

    this.setState({ ...DEFAULT_STATE }, () => {
      this.props.onRemove();
    });
  };

  handleGetAttachment = async () => {
    const { src } = this.state;
    const { attachmentData } = this.props;
    if (src.includes('blob:')) {
      downloadFileFromBlobURI(src, attachmentData.name);
    } else {
      const key = _.get(this.props, 'attachmentData.key');
      const filename = _.get(this.props, 'attachmentData.name', 'unknown.pdf').replace(/\.[^/.]+$/, '');
      this.props.onGetAttachment(key, filename);
    }
  };

  onErrorUpload = () => {};

  render() {
    const { file } = this.state;
    const fileIcon = detectFileIcon(file);
    const uploadedFileIcon = detectFileIcon(this.props.attachmentData);

    if (!file && !this.props.attachmentData.url) {
      return (
        <Dropzone onDrop={this.onDropFile} multiple={false} accept={VALIDATIONS.DOCUMENT_25MB.accepts}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Wrapper {...getRootProps()} isDragging={isDragActive}>
              <S.Placeholder>
                <S.Icon />
                <S.Text>
                  Drag and drop a <b>.xls .doc .pdf</b> here
                  <br />
                  or&nbsp;
                  <SelectFile
                    trigger={<span>Choose file</span>}
                    onSelect={this.onSelectFile}
                    accept={VALIDATIONS.DOCUMENT_25MB.accepts}
                    maxSize={LIMIT_SIZE_DOCUMENT_UPLOAD_25MB}
                    validateExtentions={VALIDATIONS.DOCUMENT_25MB.extensions}
                    fileSizeLimitMessage={VALIDATIONS.DOCUMENT_25MB.errorMessage}
                  />
                </S.Text>
              </S.Placeholder>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </S.Wrapper>
          )}
        </Dropzone>
      );
    }
    const allowDownload = this.props.shouldAllowDownload;

    return (
      <S.Wrapper hasData>
        {file ? (
          <MediaUploadProgress
            file={file}
            fileIcon={fileIcon}
            apiConfigs={this.uploadConfigs}
            onCancel={this.onRemoveImage}
            onError={this.onErrorUpload}
            onSuccess={this.onUploadSuccess}
          />
        ) : (
          <MediaUploadProgress
            file={{ name: this.props.attachmentData.name, size: this.props.attachmentData.size }}
            fileIcon={uploadedFileIcon}
            onCancel={this.onRemoveImage}
            apiConfigs={this.uploadConfigs}
            viewMode
            allowDownload={allowDownload}
            onDownload={this.handleGetAttachment}
          />
        )}
      </S.Wrapper>
    );
  }
}

export default DocumentUpload;
