import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .howItWorkReferral {
    max-width: 1060px;
    margin: auto;
    padding-top: 58px;
    padding-bottom: 93px;
    padding-right: 30px;
    padding-left: 30px;

    &__heading {
    }

    &__subTitle {
      font-weight: bold;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #5158cf;
      margin-bottom: 2px;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #202353;
      margin-bottom: 30px;
    }

    &__steps {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const ReferralStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .referralStep__line {
    width: calc(100% - 78px);
    height: 1px;
    position: absolute;
    top: 34px;
    left: 50%;
    transform: translateX(39px);
  }

  .referralStep__icon {
    width: 68px;
    height: 68px;
    background: ${props => props.color};
    border-radius: 10px;
    background-image: url(${props => props.icon});
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
  }
  .referralStep__stepName {
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #202353;
    opacity: 0.3;
    margin-bottom: 7px;
  }
  .referralStep__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #202353;
    margin-bottom: 10px;
  }
  .referralStep__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #202353;
    max-width: 298px;
  }
`;

export const StepLine = styled.div`
  border-top: 1px ${props => props.lineType} #d9dde0;
  width: calc(100%);
  height: 1px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: -1px;
    transform: translateY(-60%);
    ${props =>
      props.lineType === 'dashed'
        ? css`
            border-left: 5px solid #d9dde0;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
          `
        : css`
            border: 3px solid #d9dde0;
            border-radius: 50%;
          `}
  }
  :before {
    content: '';
    border: 3px solid #d9dde0;
    position: absolute;
    left: 0;
    transform: translateY(-60%);
    border-radius: 50%;
  }
`;
