import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { pluralize } from 'utils/commonFunction';
import AsyncSelect from 'react-select/lib/Async';
import { StylesConfig } from 'react-select';
import { Button } from 'shared/FormControl';
import SelectClientOption from 'shared/SelectClientOption';
import { searchClients, searchGroupClients } from 'redux/autoflow/client/actions';
import { CLIENT_STATUS } from 'constants/commonData';

import * as S from './style';
import { connect } from 'react-redux';

import { selectStyles } from 'shared/SearchGroupName';

function SelectClients(props) {
  const [clients, setClients] = useState([]);
  let [assignToGroup, setAssignToGroup] = useState([]);

  const searchClient = (inputValue, callback) => {
    const except = map(clients, '_id');
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    props
      .searchGroupClients({ q: search, selected_clients: except })
      .then(response => {
        const { data } = response.data;

        if (data) {
          if (data.length > 0) {
            callback(
              map(data, item => ({
                ...item,
                key: item._id,
                value: item._id,
                label: `${item.first_name} ${item.last_name}`,
                typeName: 'client',
              })),
            );
          } else {
            searchGroupClient(inputValue, callback);
          }
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const searchGroupClient = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    const searchAll = search.toLowerCase() === 'group' ? true : false;

    props
      .searchGroupClients({ txt_search: searchAll ? '' : search })
      .then(response => {
        const { data } = response.data;

        if (data) {
          const { data } = response.data;
          const clients = data.clients.map(o => ({
            ...o,
            typeName: 'client',
            name: `${o.first_name} ${o.last_name}`,
          }));
          const groups = get(data, 'groups', [])
            .filter(item => item && item.total_clients > 0)
            .map(g => ({
              ...g,
              typeName: 'group',
            }));
          const results = orderBy([...clients, ...groups], ['typeName'], ['asc']).filter(
            item =>
              item.typeName === 'group' ||
              ((item.client_connection === CLIENT_STATUS.connected ||
                item.client_connection === CLIENT_STATUS.pending) &&
                !item.is_archived),
          );

          callback(
            map(searchAll ? groups : results, item => ({
              ...item,
              name: item.name,
              key: item._id,
              value: item._id,
              label:
                item.typeName === 'group'
                  ? `${item.name} (${item.total_clients} ${pluralize('client', item.total_clients || 0)})`
                  : item.name,
              total: item.total_clients,
              typeName: item.typeName,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const onAdd = () => {
    props.onSelectClient(clients);
    props.onSetSelectedAssignToGroup(assignToGroup);
  };

  const searchClientDebounce = debounce(searchClient, 200);

  function handleAssignToMember(list) {
    const assignToGroup = [];

    list
      .filter(item => item.typeName === 'group')
      .map(item => {
        props.searchGroupClients({ group: item._id }).then(response => {
          map(response.data.data.clients, item => {
            assignToGroup.push(item);
          });
        });
      });
    setClients(list);
    setAssignToGroup(assignToGroup);
  }

  return (
    <S.Wrapper>
      <S.Heading>
        <span className="select-client__title">Add clients to Autoflow</span>
        <div className="select-client__description">Select from your connected and pending clients</div>
      </S.Heading>
      <S.Content>
        <div className="select-client__select-label">Clients</div>
        <AsyncSelect
          isMulti
          defaultOptions
          cacheOptions
          components={{ Option: SelectClientOption }}
          loadOptions={(inputValue, callback) => searchClientDebounce.call(this, inputValue, callback)}
          onChange={list => handleAssignToMember(list)}
          className={`multi-select-container select-client--select-input`}
          classNamePrefix="multi-select"
          placeholder="Enter user..."
          noOptionsMessage={() => 'No clients found.'}
          styles={selectStyles}
          openMenuOnFocus
          autoFocus
        />
      </S.Content>
      <S.Footer>
        <Button purple onClick={onAdd} disabled={!clients.length}>
          Add
        </Button>
      </S.Footer>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  return {
    autoflow: workingAutoflow ? workingAutoflow._id : '',
  };
};

export default connect(mapStateToProps, { searchClients, searchGroupClients })(SelectClients);
