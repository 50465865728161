// Lib
import React, { useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import round from 'lodash/round';
import ReactTooltip from 'react-tooltip';

// Components
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import AddNewRecipe from '../AddNewRecipe';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import DeleteRecipeModal from '../DeleteRecipeModal';
import ShareModal from './ShareModal';
import SkeletonLoading from '../SkeletonLoading';

// Constants
import { getUserShortName, isTeamAdmin, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { ARRAY_LENGTH_LOADING, RECIPE_STATUS, TYPE_RECIPE } from 'components/Recipes/constants';
import { PER_PAGE } from 'components/RecipeDetail/constants';
import { getMacroNutrients } from 'components/MealPlanDayDetail/constants';

// helper
import { conditionalRoundNutrition } from 'components/RecipeDetail/helper';

// Assets
import { ReactComponent as EmptyBanner } from 'assets/icons/MealPlans/recipes_empty_list.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/MealPlans/action_duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/MealPlans/action_edit.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/MealPlans/action_share.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

import * as S from './style';

const RecipesContentList = props => {
  const {
    list = [],
    push,
    loading,
    filters,
    getRecipeLibraries,
    toggleModal,
    user,
    changeQueryParams,
    toggleConfirmModal,
    removeRecipe,
    getTotalRecipe,
    updateRecipeSharingStatus,
    duplicateRecipe,
    total,
    initTotal,
    resetCurrentList,
    maxCalories,
    cloudfrontList,
    getTotalRecipeInTeam,
    permission,
    totalRecipeInTeam,
  } = props;

  const { hasGetToTal } = initTotal;
  const { _id: userId = '' } = user || {};
  const { no_recipes } = permission || {};

  const exceededNumberRecipes = no_recipes >= 0 && no_recipes <= totalRecipeInTeam;

  const containerRef = useRef();

  useEffect(() => {
    getTotalRecipeInTeam();
    return () => {
      resetCurrentList && resetCurrentList();
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (get(filters, 'page') === 1) {
      container && container.scrollTo({ top: 0 });
    }
  }, [filters]);

  useEffect(() => {
    if (hasGetToTal) {
      getRecipeLibraries && getRecipeLibraries({ ...filters });
      getTotalRecipe && getTotalRecipe({ ...filters, status: [RECIPE_STATUS.PUBLISH] });
    }
  }, [filters, hasGetToTal]);

  const handleRedirectToDetail = id => {
    push(`/home/recipes/${id}`);
  };

  const handleOpenAddRecipe = () => {
    toggleModal && toggleModal(true, <AddNewRecipe user={user} toggleModal={toggleModal} push={push} />);
  };

  const handleDuplicateRecipe = recipe => {
    if (loading) return;

    const { _id = '' } = recipe || {};

    let params = {
      name: `Copied - ${get(recipe, 'name', '').trim()}`,
    };

    if (_id) {
      duplicateRecipe && duplicateRecipe(_id, params);
    }
  };

  const handleEditRecipe = recipe => {
    const { _id = '' } = recipe || {};

    if (_id) {
      push(`/home/recipes/${_id}?edit=true`);
    }
  };

  const handleUpdateShareOwner = ({ id, params }) => {
    updateRecipeSharingStatus &&
      updateRecipeSharingStatus(id, params, () => {
        toggleModal && toggleModal(false);
      });
  };

  const handleShareSetting = (recipe, event) => {
    event.stopPropagation();

    toggleModal &&
      toggleModal(
        true,
        <ShareModal
          isShowCancelButton
          isHideCloseButton
          toggleModal={toggleModal}
          recipe={recipe}
          user={user}
          type={'update'}
          onSubmit={handleUpdateShareOwner}
        />,
      );
  };

  const handleDeleteRecipe = recipe => {
    const { _id = '', status = '' } = recipe || {};

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <DeleteRecipeModal
          onConfirm={() =>
            removeRecipe &&
            removeRecipe(
              _id,
              () => {
                toggleConfirmModal(false);
                toast('Recipe has been removed.');
                getTotalRecipeInTeam && getTotalRecipeInTeam();
                getTotalRecipe && getTotalRecipe({ ...filters, status: [RECIPE_STATUS.PUBLISH] });
              },
              true,
            )
          }
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          noBorder
          content={
            status === RECIPE_STATUS.PUBLISH &&
            'This will remove the recipe for any clients using the recipe and remove the recipe for all clients'
          }
          hasCloseIcon
          isPressEsc
          isCloseOnDimmer={false}
          onClose={() => toggleConfirmModal(false)}
        />,
      );
  };

  const handleLoadMore = event => {
    const scrollHeight = get(event, 'target.scrollHeight', 0);
    const scrollTop = get(event, 'target.scrollTop', 0);
    const clientHeight = get(event, 'target.clientHeight', 0);

    const bottom = scrollHeight - scrollTop <= clientHeight + 10;

    const isEnd = get(filters, 'page') >= Math.ceil(total / PER_PAGE);
    if (bottom && !loading && !isEnd) {
      const nextPage = get(filters, 'page') + 1;
      changeQueryParams && changeQueryParams({ page: nextPage });
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const renderActions = recipe => {
    const canAction =
      (isTeamAdmin(user) ||
        get(recipe, 'author._id', '') === get(user, '_id', '') ||
        get(recipe, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_NOOWNER) &&
      get(recipe, 'type', '') === TYPE_RECIPE.CUSTOM;
    const isSystem = get(recipe, 'type', '') === TYPE_RECIPE.SYSTEM;

    if (exceededNumberRecipes && isSystem) return;

    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => (
          <MenuTrigger className="dropdown-trigger" vertical active={!!open} itemId={get(recipe, '_id', '')} />
        )}
        direction="left"
        isNewSpacing
        isMealPlanAndRecipe={canAction}
      >
        {!exceededNumberRecipes && (
          <Option key="duplicate" onClick={() => handleDuplicateRecipe(recipe)}>
            <S.OptionIcon className="icon">
              <DuplicateIcon className="recipe-duplicate-icon" />
            </S.OptionIcon>
            <span>Duplicate</span>
          </Option>
        )}
        {canAction && (
          <Option key="edit" onClick={() => handleEditRecipe(recipe)}>
            <S.OptionIcon className="icon">
              <EditIcon className="recipe-edit-icon" />
            </S.OptionIcon>
            <span>Edit</span>
          </Option>
        )}
        {canAction && (
          <Option key="share-setting" onClick={event => handleShareSetting(recipe, event)}>
            <S.OptionIcon className="icon">
              <ShareIcon className="recipe-share-icon" />
            </S.OptionIcon>
            <span>Sharing settings</span>
          </Option>
        )}
        {canAction && (
          <Option key="delete" onClick={() => handleDeleteRecipe(recipe)}>
            <S.OptionIcon className="icon">
              <RemoveIcon className="recipe-remove-icon" />
            </S.OptionIcon>
            <span>Remove</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const renderNutritionItem = item => {
    const { label = '', unit = '', value = '', id } = item;
    const isEmptyValue = +value === 0;

    return (
      <S.ItemNutrition key={id}>
        <S.SeparateDot />
        {`${label} ${isEmptyValue ? '—' : conditionalRoundNutrition(value)} ${unit}`.trim()}
      </S.ItemNutrition>
    );
  };

  const renderRecipeNutrition = recipe => {
    const nutrients =
      get(recipe, 'macro_nutrients.length', 0) > 0 ? getMacroNutrients(get(recipe, 'macro_nutrients', [])) : [];

    return <S.RecipeUnit>{nutrients.length > 0 && nutrients.map(renderNutritionItem)}</S.RecipeUnit>;
  };

  const renderRecipeItem = item => {
    const {
      _id,
      cover_image_thumbnail = '',
      cover_image = '',
      intro_instruction,
      name = '',
      macro_nutrients = [],
      share = 0,
      author,
      status = '',
      type,
    } = item;
    const { url } = intro_instruction || {};
    const { _id: authorId = '', avatar = '', first_name = '', last_name = '' } = author || {};

    const isAnotherOwner = share !== TEAM_SHARE_NOOWNER && authorId !== userId;
    const isPublished = status === RECIPE_STATUS.PUBLISH;

    const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
    const coverImageThumbnail = convertS3UrlToCloudFrontUrl(cover_image_thumbnail, cloudfrontList, true);

    const isCustom = type === TYPE_RECIPE.CUSTOM;
    const isSystem = type === TYPE_RECIPE.SYSTEM;

    return (
      <S.RecipeItemWrapper key={_id} onClick={() => handleRedirectToDetail(_id)}>
        <S.RecipeThumbnailWrapper>
          <S.RecipeThumbnail src={[coverImageThumbnail || coverImage]} />
          <S.RecipeFloat>
            {status ? (
              isPublished ? (
                <S.StatusWrapper>
                  <S.StatusDot isPublished />
                  <S.StatusLabel>Published</S.StatusLabel>
                </S.StatusWrapper>
              ) : (
                <S.StatusWrapper>
                  <S.StatusDot />
                  <S.StatusLabel>Draft</S.StatusLabel>
                </S.StatusWrapper>
              )
            ) : null}
            {isCustom && (
              <S.StatusWrapper>
                <S.StatusLabel>Custom</S.StatusLabel>
              </S.StatusWrapper>
            )}
            {url && <PlayIcon className="recipe-play-icon" />}
          </S.RecipeFloat>
        </S.RecipeThumbnailWrapper>
        <S.RecipeInfo>
          <S.RecipeTitle>{name}</S.RecipeTitle>
          <S.InfoWrapper isAnotherOwner={isAnotherOwner && !isSystem}>
            {!isEmpty(macro_nutrients) && renderRecipeNutrition(item)}
            {isAnotherOwner && !isSystem && (
              <>
                <div data-for={`recipe-avatar-${authorId}-tooltip`} data-tip className="recipe-owner-avatar">
                  <Avatar name={getUserShortName(author)} size="20" src={avatar} className="recipe-avatar" />
                </div>
                <ReactTooltip
                  className="recipe-avatar-tooltip"
                  id={`recipe-avatar-${authorId}-tooltip`}
                  effect="solid"
                  place="top"
                >
                  {first_name} {last_name}
                </ReactTooltip>
              </>
            )}
          </S.InfoWrapper>
        </S.RecipeInfo>
        {renderActions(item)}
      </S.RecipeItemWrapper>
    );
  };

  const renderEmptyList = () => {
    const isListEmpty = isEmpty(list);
    const hasTextSearch = get(filters, 'text_search');
    const hasCategory = get(filters, 'category');

    const dietariesValue = get(filters, 'dietaries');
    const parsedDietaries = typeof dietariesValue === 'string' ? JSON.parse(dietariesValue) : dietariesValue;
    const hasDietaries = !isEmpty(parsedDietaries);

    const hasRangeCalories = get(filters, 'from_calories') !== 0 || maxCalories !== get(filters, 'to_calories');

    const emptyListMessage = hasTextSearch
      ? 'No results found.'
      : hasCategory || hasDietaries || hasRangeCalories
      ? 'No recipes.'
      : '';

    return (
      <S.EmptyListWrapper isEmptyList={isListEmpty && !loading}>
        {emptyListMessage ? renderNotFound(emptyListMessage) : renderCreateFirstRecipe()}
      </S.EmptyListWrapper>
    );
  };

  const renderNotFound = (content = '') => {
    return (
      <S.NotFoundWrapper>
        <S.NoResultFound>{content}</S.NoResultFound>
      </S.NotFoundWrapper>
    );
  };

  const renderCreateFirstRecipe = () => {
    return (
      <>
        <EmptyBanner className="empty-banner" />
        <S.NewRecipeButton onClick={handleOpenAddRecipe} purple>
          <PlusIcon className="plus-icon" />
          <span className="label">Create your first recipe</span>
        </S.NewRecipeButton>
      </>
    );
  };

  return (
    <S.RecipesContainer
      className="recipes-container"
      ref={containerRef}
      onScroll={handleScroll}
      isEmptyList={isEmpty(list) && !loading}
    >
      {!loading && isEmpty(list) && hasGetToTal && renderEmptyList()}
      <S.ContentList>
        {get(filters, 'page') === 1 &&
          loading &&
          Array(ARRAY_LENGTH_LOADING)
            .fill(null)
            .map((_, index) => <SkeletonLoading key={index} />)}
        {!isEmpty(list) && !(get(filters, 'page') === 1 && loading) && list.map(renderRecipeItem)}
        {get(filters, 'page', 1) > 1 &&
          loading &&
          Array(ARRAY_LENGTH_LOADING)
            .fill(null)
            .map((_, index) => <SkeletonLoading key={index} />)}
      </S.ContentList>
    </S.RecipesContainer>
  );
};

export default RecipesContentList;
