import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal > :last-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 30px;
  }

  .ui.modal.autoflow-add-clients-modal {
    border-radius: 8px;
    width: 490px !important;
    max-width: 100% !important;
  }

  .ui.modal.autoflow-add-clients-modal > .header {
    font-family: Open Sans;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #000000;
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ui.modal.autoflow-add-clients-modal > .content {
    padding: 0 20px 10px;
  }

  .ui.modal.autoflow-add-clients-modal > .actions {
    background: #fff;
    border: none;
    padding: 20px;
  }

  .multi-select-container.multi-select--autoflow__clients .multi-select__value-container {
    padding: 9px 15px;
  }

  .multi-select-container .multi-select__control {
    border-color: #d4d7d9;
  }

  .multi-select--autoflow__clients .multi-select__placeholder {
    font-size: 12px;
    line-height: 12px;
    color: #777777;
    margin-top: 2px;
  }

  .multi-select--autoflow__clients .multi-select__input {
    color: #323c47;
  }
`;

export const ActionButton = styled(Button)`
  padding: 10px 30px 10px 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
`;
