import styled, { css } from 'styled-components';

const textBase = `
    font-family: 'Open Sans';
    font-weight: 400;
    line-height: 150%;
    color: #202353;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  padding: 32px 31px 32px;
  margin-bottom: 22px;
  border: 1px solid #dadfea;
  border-radius: 12px;

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #202353;
    user-select: none;
  }

  ${({ isOnlyView }) =>
    isOnlyView &&
    css`
      justify-content: flex-start;
      gap: 0;
    `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 11px;

  width: 400px;
  padding: 8px 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Header = styled.div`
  ${textBase}
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  z-index: 1;

  > * {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      position: absolute;
      top: 0;
    }
    &:nth-child(2) {
      left: calc(35px - 8px);
    }
    &:nth-child(3) {
      left: calc((35px - 8px) * 2);
    }
    &:nth-child(4) {
      left: calc((35px - 8px) * 3);
    }
  }
`;

export const AvatarContainer = styled.div`
  border: 1.5px solid #ffffff;
  border-radius: 50%;

  .sb-avatar__text {
    pointer-events: none;
  }

  .__react_component_tooltip.app-tooltip.set-as-default-tooltip {
    border-radius: 4px;
    background-color: #333333;
    padding: 7px 15px;

    &.place-top {
      margin-top: -8px;
      &:after {
        bottom: -9px;
        border-top-color: #333333;
      }
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #fff;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const AvatarRemaining = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #d8d6ff;

  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #020046;
`;

export const Description = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(32, 35, 83, 0.7);
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  height: 50px;
  width: 328px;
`;

export const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  padding: 0 12px 0 16px;
  border: 1px solid #dadfea;
  border-radius: 4px;
  cursor: pointer;

  .arrow-icon {
    rotate: 90deg;
    path {
      fill: #111;
    }
  }

  &:hover {
    border: 1px solid #5158cf;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid #5158cf;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        border: 1px solid #dadfea;
      }
    `}
`;

export const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 12px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  width: 328px;
  padding: 4px 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(38, 38, 38, 0.2);
  cursor: pointer;

  .option {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #202353;
    user-select: none;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    cursor: pointer;

    &.is-active {
      font-weight: 600;
      color: #5158cf;
    }

    &:hover {
      font-weight: 600;
      background-color: #f6f5ff;
      color: #5158cf;
    }
  }
`;
