import React from 'react';
import * as S from './style';
import * as ModalS from '../style';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { declineOptions, DECLINE_REQUEST_TYPE } from '../helper';

const undoDurationTime = process.env.REACT_APP_UNDO_DURATION_TIME;

const DeclineIntroductionSection = ({ type, modalContent, formData, onChangeDeclineOption }) => {
  const { declineDescription = '' } = modalContent || {};
  const { selectedDeclineOption } = formData || {};
  return (
    <S.Wrapper type={type}>
      <S.Header>
        <CancelIcon />
        <ModalS.Title>Decline</ModalS.Title>
      </S.Header>

      <S.Description>{declineDescription}</S.Description>

      {type === DECLINE_REQUEST_TYPE.INTROCALL && (
        <S.DeclineOptionsWrapper>
          <p className="title">DECLINE OPTIONS</p>

          {declineOptions.map(item => (
            <S.DeclineOption
              key={item.value}
              isActive={item.value === selectedDeclineOption}
              onClick={() => onChangeDeclineOption(item.value)}
            >
              <S.DeclineOptionRadio checked={item.value === selectedDeclineOption} label={item.label} />
            </S.DeclineOption>
          ))}
        </S.DeclineOptionsWrapper>
      )}

      <S.UndoTimeInfo>
        <InfoIcon />
        <p>You can undo the action within {undoDurationTime} minutes.</p>
      </S.UndoTimeInfo>
    </S.Wrapper>
  );
};

export default DeclineIntroductionSection;
