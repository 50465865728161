import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { NewSearchInput } from 'shared/SearchInput';
import { CDN_URL } from 'constants/commonData';

export const AddStudioCollectionClientModal = styled(Modal)`

  &.ui.modal {
    width: 650px;

    .header {
      border-radius: 8px;    
      padding-top: 20px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 0px !important;
      color: #202353 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .header__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #202353;
      }
    }

    .content {
      padding: 30px 30px 0px 30px !important;
    }

    .actions {
      background: #FFFFFF;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
      border: none;
      padding-top: 10px;
      padding-bottom: 10px;

      .actions__cancelBtn {
        padding: 7px 25px;
      }

      .actions__createBtn {
        margin-left: 5px;
        padding: 7px 35px;

        :disabled {
          background-color: #C4C4C4
          border-color: #C4C4C4
        }
      }
    }
  }

  .collectionResult__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .collectionResult__selectAll {
    display: flex;
    
    &--text {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #202353;
      opacity: 0.8;
      margin-right: 10px;
    }
  }

  .collectionResult__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #202353;
    margin-bottom: 15px;
  }
`;

export const SearchCollectionInput = styled(NewSearchInput)`
  min-width: 270px;

  input {
    padding-top: 10px;
    padding-bottom: 10px;
    background: url(${CDN_URL}/images/search_grey_purple.svg) no-repeat;
    background-size: auto;
    background-position: 15px center;
    background-color: #f6f7f9;
    border: 1px solid transparent;

    ::placeholder {
      color: #a6acc6;
      font-size: 13px;
      line-height: 18px;
      color: #a6acc6;
    }

    &:focus {
      border: 1px solid #7072dd;
    }
  }
`;

export const CollectionResultContainer = styled.div`
  max-height: 450px;
  min-height: 450px;
  overflow: auto;
  padding-right: 21px;
  margin-right: -21px;

  .collectionResult__noResult {
    margin: auto;
    text-align: center;
    margin-top: 200px;
  }
`;

export const CollectionItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    border: 1px solid #5158cf;
  }

  &.disable-item {
    cursor: not-allowed;
    opacity: 0.4;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid #5158cf;
    `}

  .collectionItem__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    overflow: hidden;

    &--title {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
      white-space: nowrap;
    }

    &--statistic {
      font-size: 12px;
      line-height: 150%;
      color: #202353;
      opacity: 0.5;
    }
  }
`;

export const CheckBoxIconWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  height: 16px;
  width: 16px;
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  align-items: center;

  .checkbox__circle {
    opacity: 0;
    height: 8px;
    width: 8px;
    background: #6261ff;
    border-radius: 99999px;
  }

  ${props =>
    props.active &&
    css`
      border: 2px solid #6261ff;
      box-sizing: border-box;
      .checkbox__circle {
        opacity: 1;
      }
    `}

  &:hover {
    border: 1px solid #6261ff;
  }
`;

export const Dot = styled.span`
  margin: 0px 15px;
  position: relative;

  ::after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    border-radius: 50%;
    background-color: #728096;
    top: 50%;
  }
`;
