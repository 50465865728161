import { Types } from './actions';
import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  list: [],
  loading: true,
  clients: {
    isLoaded: false,
    total: 0,
    list: [],
    page: 1,
    per_page: 30,
  },
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_END:
      return state.merge(fromJS({ ...payload, loading: false }));

    case Types.AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_START:
      return state.set('loading', true);

    case Types.AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_FAILED:
      return state.set('loading', false);

    case Types.AUTOFLOW_EXACT_DATE_UPDATE_LEADERBOARD_SUCCESS:
      return state.setIn(['list', '0'], fromJS(payload));

    case Types.AUTOFLOW_EXACT_DATE_REMOVE_LEADERBOARD_SUCCESS:
      return INITIAL_STATE.set('loading', false).setIn(['clients', 'isLoaded'], true);

    case Types.AUTOFLOW_EXACT_DATE_CREATE_LEADERBOARD_SUCCESS:
      return state.setIn(['list', '0'], fromJS(payload));

    case Types.AUTOFLOW_EXACT_DATE_GET_LEADERBOARD_CLIENT_SUCCESS:
      return state.set('clients', fromJS(payload));

    case Types.AUTOFLOW_EXACT_DATE_LEADERBOARD_UNMOUNT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
