import React from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import * as S from './style';
import * as Icons from './Icons';

import { getUserShortName, formatPhoneNumber, timeSince, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import UpdateOverview from 'components/UpdateOverview';
import GroupRoomInformation from '../GroupRoomInformation';
import ParticipantsList from '../ParticipantsList';
import ClientOverviewNote from 'components/ClientOverviewNote';

class RoomInformation extends React.Component {
  constructor(props) {
    super(props);
    this.updatingFavorite = false;

    this.getDataDebounce = _.debounce(this.getData, 500);
  }

  componentDidMount() {
    this.getDataDebounce();
  }

  componentDidUpdate() {
    this.getDataDebounce();
  }

  getData = () => {
    const { profileId, roomInformation, selectedRoom } = this.props;
    if (
      !_.isEmpty(profileId) &&
      !_.isEqual(profileId, _.get(roomInformation, '_id')) &&
      _.get(selectedRoom, 'type') !== 'group'
    ) {
      this.props.getFeatureStatus(profileId);
      this.props.getIndividualRoomInformation(profileId);
    }
  };

  render() {
    const {
      cloudfrontList,
      profileId,
      roomInformation,
      viewTeammate,
      user,
      loaded,
      rooms,
      startRecord,
      selectedRoom,
      members = [],
      invalidRoom,
    } = this.props;

    if (!invalidRoom && _.get(selectedRoom, 'type') === 'group' && members.length) {
      return (
        <S.Wrapper isGroup>
          <GroupRoomInformation members={members} selectedRoom={selectedRoom} />
          <ParticipantsList members={members} user={user} selectedRoom={selectedRoom} />
        </S.Wrapper>
      );
    }

    if (
      !profileId ||
      !roomInformation ||
      roomInformation._id === user._id ||
      roomInformation._id === viewTeammate ||
      !rooms.length ||
      invalidRoom
    ) {
      return <S.Wrapper />;
    }

    const avt = convertS3UrlToCloudFrontUrl(roomInformation.avatar, cloudfrontList) || roomInformation.avatar;

    return (
      <S.Wrapper>
        <S.Section className="section--base-information">
          <S.ProfileAvatarContainer>
            <Avatar name={getUserShortName(roomInformation)} size="60" src={avt} color={roomInformation.color} />
            <div className="name">
              <Link to={`/home/client/${roomInformation._id}`} onClick={this.props.resetRedirectInboxSegment}>
                {roomInformation.first_name} {roomInformation.last_name}
              </Link>
            </div>
          </S.ProfileAvatarContainer>
          <S.Details>
            <div className="item">
              {Icons.clock}
              <div>{moment.tz(roomInformation.timezone).format('hh:mm A [(GMT]Z[)]')}</div>
            </div>
            {roomInformation.email ? (
              <div className="item">
                {Icons.mail}
                <a href={`mailto:${roomInformation.email}`}>{roomInformation.email}</a>
              </div>
            ) : null}
            {roomInformation.phone ? (
              <div className="item">
                {Icons.phone}
                <a href={`callto:${roomInformation.phone}`}>{formatPhoneNumber(roomInformation.phone)}</a>
              </div>
            ) : null}
            {roomInformation.last_seen ? (
              <div className="item">
                {Icons.eye}
                <div>Last Seen: {timeSince(new Date(roomInformation.last_seen.createdAt))} ago</div>
              </div>
            ) : null}
          </S.Details>
        </S.Section>
        <S.Section>
          <ClientOverviewNote
            key={`client-note_${roomInformation._id}`}
            title="Notes"
            type="note"
            icon=""
            showOnInbox={true}
            selectedClient={roomInformation}
            readOnly={viewTeammate || startRecord}
          />
        </S.Section>
        <S.Section>
          {loaded && <UpdateOverview selectedClient={roomInformation} key={`activity_${roomInformation._id}`} />}
        </S.Section>
      </S.Wrapper>
    );
  }
}

export default RoomInformation;
