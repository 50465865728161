import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
const PAGE_TITLE = [
  {
    pathname: '/home/client',
    title: 'Client Page',
  },
  {
    pathname: '/home/inbox',
    title: 'Inbox Page',
  },
  {
    pathname: '/home/exercise',
    title: 'Exercise Library',
  },
  {
    pathname: '/home/workout',
    title: 'Workout Library',
  },
  {
    pathname: '/home/program',
    title: 'Program Library',
  },
  {
    pathname: '/home/forms',
    title: 'Forms & Questionnaires',
  },
  {
    pathname: '/home/task-library',
    title: 'Task Library',
  },
  {
    pathname: '/home/accountdetails',
    title: 'Account Details',
  },
  {
    pathname: '/home/default-client-settings',
    title: 'Default Client Setting',
  },
  {
    pathname: '/home/changeemail',
    title: 'Change Email',
  },
  {
    pathname: '/home/changepassword',
    title: 'Change Password',
  },
  {
    pathname: '/home/publicprofile',
    title: 'Public Profile',
  },
  {
    pathname: '/home/notifications',
    title: 'Notification',
  },
  {
    pathname: '/home/generalsettings',
    title: 'Team Settings',
  },
  {
    pathname: '/home/teammates',
    title: 'Teammates',
  },
  {
    pathname: '/home/billing',
    title: 'Billing',
  },
  {
    pathname: '/home/billinghistory',
    title: 'Billing History',
  },
  {
    pathname: '/home/autoflow',
    title: 'Autoflow',
  },
  {
    pathname: '/pricing-plan',
    title: 'Pricing Plan',
  },
  {
    pathname: '/home/studio-programs',
    title: 'Studio Program',
  },
  {
    pathname: '/home/studio-resource',
    title: 'Studio Resource',
  },
  {
    pathname: '/home/studio-collection',
    title: 'Studio Collection',
  },
  {
    pathname: '/home/custom-branding',
    title: 'Custom Branding',
  },
  {
    pathname: '/home/referral-program',
    title: 'Referral Program',
  },
];

let actions = {
  identify: id => {
    if (process.env.REACT_APP_MIXPANEL_ID) {
      mixpanel.identify(id);
    }
  },
  alias: id => {
    if (process.env.REACT_APP_MIXPANEL_ID) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (process.env.REACT_APP_MIXPANEL_ID) {
      mixpanel.track(name, props);
    }
  },
  visit: pathname => {
    if (process.env.REACT_APP_MIXPANEL_ID) {
      let page = PAGE_TITLE.find(item => item.pathname === pathname);
      if (page) {
        mixpanel.track(page.title);
      }
    }
  },
  people: {
    set: props => {
      if (process.env.REACT_APP_MIXPANEL_ID) {
        mixpanel.people.set(props);
      }
    },
  },
};

export let Mixpanel = actions;
