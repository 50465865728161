import React from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CDN_URL } from 'constants/commonData';

const FieldTitle = ({ field, units, exerciseId, useORM }) => {
  const title = field.title.replace(' (short)', '');
  let unitTitle = '';

  if (units[field.unique_code]) {
    unitTitle = '(' + units[field.unique_code].title + ')';
  }

  const showTooltipForWeight = field.unique_code === 'weight' && useORM;
  const tooltipId = `tooltip--${exerciseId}-weightField`;

  return (
    <th>
      {showTooltipForWeight ? (
        <>
          <span>{`${title} ${unitTitle}`}</span>
          <img
            data-tip="Weight assigned based on most recent 1RM estimation"
            data-for={tooltipId}
            src={`${CDN_URL}/images/info_circle.svg`}
            width={10}
            height={10}
            alt=""
            style={{ display: 'inline', marginLeft: 3, verticalAlign: 'middle' }}
          />
          <ReactTooltip id={tooltipId} effect="solid" place="top" className="app-tooltip" />
        </>
      ) : (
        <span>{`${title} ${unitTitle}`}</span>
      )}
    </th>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: { client: workingClientDetail },
  } = state;

  return { units: _.get(workingClientDetail || user, 'preferences', []) };
};

export default connect(mapState)(FieldTitle);
