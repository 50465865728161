import React from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

// Store
import ColorPicker from '../ColorPicker';

// Components
import { Checkbox, Toggle } from 'shared/FormControl';
import {
  BAR_CHART_METRIC_TYPES,
  COLOR_OPTIONS,
  DATA_POINT_KEYS,
  DATA_POINT_OPTIONS,
  KEYS_METRIC,
  LINE_CHART,
} from 'components/BodyMetricChartNew/constants';

//Assets
import { ReactComponent as LineChartIcon } from 'assets/icons/line-chart.svg';
import { ReactComponent as BarChartIcon } from 'assets/icons/bar-chart.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';

import * as S from './style';

const NormalMetricSettingModal = props => {
  const {
    bodyMetric,
    customBrandingColor,
    settings,
    handleClose,
    handleSelectChartType,
    handleSelectDataPointOption,
    handleDisplayDataPoints,
    customBrandColor,
    handleSelectColor,
    metricSettings,
    unitOptions,
    handleSelectUnit,
    defaultUnit,
    isCheckedUpdateUnit,
    isChangeUnit,
    handleChangeToggle,
    handleSelectKeyMetric,
    applySetting,
  } = props;
  const isLineChart = settings.type === LINE_CHART;
  const isNotOnlyBarChart = !BAR_CHART_METRIC_TYPES.includes(get(bodyMetric, 'unique_code'));

  return (
    <S.Wrapper open={true} onClose={handleClose} closeOnDimmerClick={false}>
      <S.Title>Chart Settings</S.Title>
      <S.Body>
        {isNotOnlyBarChart && (
          <S.ItemWrapper>
            <S.TitleSettingItem>Chart Type</S.TitleSettingItem>
            <S.ChartTypeSection>
              <S.ChartOption active={isLineChart} onClick={handleSelectChartType('line')}>
                <LineChartIcon className="line-chart-icon" />
                Line
              </S.ChartOption>
              <S.ChartOption active={settings.type === 'bar'} onClick={handleSelectChartType('bar')} right>
                <BarChartIcon className="bar-chart-icon" />
                Bar
              </S.ChartOption>
            </S.ChartTypeSection>
          </S.ItemWrapper>
        )}
        <S.ItemWrapper>
          <S.TitleSettingItem>Data Point</S.TitleSettingItem>
          <S.SectionWrapper className="data-point-wrapper">
            {DATA_POINT_OPTIONS.map(opt => {
              const { value, label } = opt;
              if (opt.value === DATA_POINT_KEYS.all_values && isLineChart && isNotOnlyBarChart) {
                return null;
              }
              return (
                <S.UnitItem key={value} onClick={handleSelectDataPointOption(value)} className="data-point">
                  <S.UnitRadio checked={settings.data_point === value} className="data-point-radio" />
                  {label}
                </S.UnitItem>
              );
            })}
            {isNotOnlyBarChart && isLineChart && (
              <S.KeyMetricItem className="show-data-point">
                <Checkbox
                  title="Show data points on chart graph"
                  checked={settings.display_data_points}
                  className="data-points__checkbox"
                  onChange={handleDisplayDataPoints}
                />
                <S.TooltipWrapper>
                  <S.IconInfo data-tip data-for="display-data-points-key">
                    <InfoIcon />
                  </S.IconInfo>
                  <ReactTooltip
                    id="display-data-points-key"
                    className="display-data-points-tooltip"
                    effect="solid"
                    place="top"
                  >
                    This setting is applied for the Web app only
                  </ReactTooltip>
                </S.TooltipWrapper>
              </S.KeyMetricItem>
            )}
          </S.SectionWrapper>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Chart Color</S.TitleSettingItem>
          <ColorPicker
            color={settings.color || customBrandingColor}
            options={[customBrandColor, ...COLOR_OPTIONS]}
            onSelectColor={handleSelectColor}
            placeholder="Select color..."
          />
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Unit</S.TitleSettingItem>
          <S.SectionWrapper>
            {unitOptions.map(unit => {
              const selectedUnitId =
                get(settings, 'unit._id', '') || get(metricSettings, 'unit._id', '') || get(defaultUnit, '_id', '');
              return (
                <S.UnitItem key={unit._id} onClick={handleSelectUnit(unit)}>
                  <S.UnitRadio checked={selectedUnitId === unit._id} onChange={handleSelectUnit(unit)} />
                  {unit.name} ({unit.title || '-'})
                </S.UnitItem>
              );
            })}
            {isChangeUnit && (
              <S.ToggleWrapper>
                <Toggle
                  className="unit-client-toggle"
                  checked={isCheckedUpdateUnit}
                  onChange={handleChangeToggle}
                  label="Also update units for Metrics"
                />
              </S.ToggleWrapper>
            )}
          </S.SectionWrapper>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Key Metric</S.TitleSettingItem>
          <S.SectionWrapper className="key-metric">
            {KEYS_METRIC.map(keyMetric => {
              const selectedKey = settings.key_metrics.includes(keyMetric.key);
              return (
                <S.KeyMetricItem key={keyMetric.key}>
                  <Checkbox
                    title={keyMetric.title}
                    checked={selectedKey}
                    className="key-metric__checkbox"
                    onChange={handleSelectKeyMetric(keyMetric.key, selectedKey)}
                  />
                </S.KeyMetricItem>
              );
            })}
          </S.SectionWrapper>
        </S.ItemWrapper>
      </S.Body>
      <S.Action>
        <S.CancelButton onClick={handleClose}>Cancel</S.CancelButton>
        <S.ApplyButton onClick={applySetting}>Apply</S.ApplyButton>
      </S.Action>
    </S.Wrapper>
  );
};

export default NormalMetricSettingModal;
