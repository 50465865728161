// Libs
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

// Assets
import { ReactComponent as SubtractionIcon } from 'assets/icons/subtraction-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';

// Style
import * as S from './style';

const NumberInput = props => {
  const {
    label = '',
    value,
    defaultValue,
    onChange = () => {},
    handlePlusSub = () => {},
    onBlur,
    min = 0,
    max = 99,
    allowNegative = false,
    decimalSeparator = '.',
    decimalScale = 0,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const onFocusWrapper = () => {
    setIsFocus(false);
  };

  const isFocusWrapper = () => {
    setIsFocus(true);
  };

  return (
    <S.NumberInputWrapper onFocus={isFocusWrapper} onBlur={onFocusWrapper}>
      {label && <label>{label}</label>}
      <S.NumberInput className="number-input" isFocus={isFocus}>
        <NumberFormat
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          onValueChange={onChange}
          allowNegative={allowNegative}
          decimalSeparator={decimalSeparator}
          decimalScale={decimalScale}
          isAllowed={values => {
            const { floatValue = 0 } = values;
            return floatValue >= min && floatValue <= max;
          }}
        />
        <S.Button
          onClick={() => {
            handlePlusSub(Number(value) + 1);
          }}
          disabled={value >= max}
          className="plus-icon"
        >
          <PlusIcon />
        </S.Button>
        <S.Button
          onClick={() => {
            handlePlusSub(Number(value) - 1);
          }}
          disabled={value < 2}
          className="subtract-icon"
        >
          <SubtractionIcon />
        </S.Button>
      </S.NumberInput>
    </S.NumberInputWrapper>
  );
};

export default NumberInput;
