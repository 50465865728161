import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Wrapper, Header, Content, Actions } from 'shared/Styles/ModalLayout';
import { Button as PrimitiveButton } from 'shared/FormControl';
import ReactTooltip from 'react-tooltip';
import { CDN_URL } from 'constants/commonData';

const Button = ({ className, children, onClick, visible }) => (
  <PrimitiveButton className={className} onClick={onClick} visible={visible}>
    {children}
  </PrimitiveButton>
);

export const SaveButton = styled(Button)`
  flex: 0 0 110px;
  height: 32px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  background: ${props => (props.disabled ? '#c4c4c4' : '#5158cf')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${props => (props.disabled ? '#c4c4c4' : '#5559ff')};
  }
`;

export const WorkoutBackgroundWrapper = styled.div`
  display: flex;
`;

export const DescriptionText = styled.p`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  max-width: 235px;
  color: #202353;
`;

export const DescriptionContainer = styled.div`
  display: inline-block;
  margin-bottom: 40px;
  margin-right: 67px;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const UploadBackgroundContainer = styled.div`
  display: inline-block;
`;

export const UploadBackgroundText = styled.span`
  display: block;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  text-transform: uppercase;

  color: #6a778a;
`;

export const RemoveIcon = styled.div`
  width: 15px;
  height: 15px;
  background: transparent url(${CDN_URL}/images/delete_bg_white.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  display: none;
`;

export const TriggerImage = styled.div`
  width: 130px;
  height: 76px;
  background: transparent url(${props => props.background}) no-repeat center;
  background-size: cover;
  border-radius: 5px;
`;

export const TriggerCustomImage = styled.div`
  width: 130px;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(rgba(19, 19, 19, 0.2), rgba(19, 19, 19, 0.2)),
    url(${props => props.background}) no-repeat center;
  background-size: cover;
  border-radius: 5px;
  :hover {
    background: linear-gradient(rgba(19, 19, 19, 0.5), rgba(19, 19, 19, 0.5)),
      url(${props => props.background}) no-repeat center;
    background-size: cover;
  }
`;

export const TriggerImageName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #202353;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TriggerContainer = styled.div`
  width: 130px;
  height: 76px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    background: linear-gradient(180deg, #ffffff 0%, #edeff4 100%);
    cursor: pointer;
  }
`;

export const BackgroundSelectWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const AddNewText = styled.span`
  margin-top: 3px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;

  text-align: center;

  color: #98a0a8;
`;

export const ManageTextContainer = styled.div`
  text-align: center;
`;

export const ManageText = styled.span`
  font-style: normal;
  font-weight: normal;
  text-align: left;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  color: #ffffff;
  word-wrap: break-word;
  width: 65px;
  height: 28px;
  margin-left: 7px;
`;

export const AddNewTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectFileTrigger = styled.div`
  background: #f7f8fc;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
    border-color: #5158cf;

    svg {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: transparent url(${props => props.background}) no-repeat center;
  background-size: cover;
`;

export const BackgroundBorder = styled.div`
  background: #ffffff;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2px;
  height: 82px;
  width: 134px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 95px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    !props.customFileUpload &&
    css`
      :hover {
        border-color: #5158cf;
      }
    `}
`;

export const ImageNameText = styled.div`
  display: -webkit-box;
  color: white;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SubHeader = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 8px;
  &.everfit-backgrounds-title {
    margin-top: 5px !important;
    margin-bottom: 13px !important;
  }
`;

export const CustomBackgroundsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 0px 20px;
`;

export const Item = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 4px;
  height: 122px;
  position: relative;
  cursor: pointer;

  .select-file__container,
  .select-file__trigger {
    width: 100%;
    height: 100%;
  }

  :hover {
    ${RemoveIcon} {
      display: block;
    }
  }

  ${props =>
    props.disablehover
      ? css`
          :hover {
            border-color: transparent;
          }
        `
      : css`
          :hover {
            border-color: #5158cf;
          }
        `}
  ${props =>
    props.active &&
    css`
      border-color: #5158cf;
    `}

  .evf-file-upload {
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    border-radius: 10px;
    width: unset;
    height: unset;
  }
  ${props =>
    props.selectedDefault &&
    `
  ::before {
    content: 'Default';
    position: absolute;
    top: 12px;
    right: 12px;
    width: 65px;
    height: 19px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  } 
  `}
`;

export const BackgroundList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ContentWrapper = styled(Wrapper)`
  max-height: calc(100vh - 100px);

  ${Header} {
    padding-bottom: 20px;
  }

  ${Content} {
    padding: 0 25px 2px 25px;
  }

  ${Actions} {
    padding-top: 30px;

    button {
      padding: 6px 54px;
    }
  }
`;

export const Tooltip = styled(ReactTooltip)`
  max-width: 320px !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;
`;
