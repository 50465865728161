import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { CDN_URL } from 'constants/commonData';

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${CDN_URL}/images/training_icon.svg) center center no-repeat;
  cursor: pointer;

  :hover {
    background: url(${CDN_URL}/images/training_icon_hover.svg) center center no-repeat;
    border-radius: 6px;
    border: solid 1px #5c58c0;
  }
`;


function ClientTrainingIcon(props) {
  const { user } = props;

  const onClick = (e) => {
    e.stopPropagation();
    props.push(`/home/client/${user._id}/calendar`);

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <Icon onClick={onClick} data-tip data-for={`training-${user._id}`}>
      <ReactTooltip
        delayShow={1000}
        className="app-tooltip"
        id={`training-${user._id}`}
        effect="solid"
        place={'top'}
      >
        <span>Training Calendar</span>
      </ReactTooltip>
    </Icon>
  );
}
const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch)
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientTrainingIcon);