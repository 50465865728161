import React from 'react';
import { TextArea } from 'semantic-ui-react';

import * as S from '../style';

const LongAnswerForm = () => {
  return (
    <S.FormType>
      <S.TypeArea>
        <TextArea readOnly className="welcome-form-textarea" placeholder="Long answer text" />
      </S.TypeArea>
    </S.FormType>
  );
};

export default LongAnswerForm;
