import React, { useEffect, useMemo, useState } from 'react';
import { Button, Image, Radio } from 'semantic-ui-react';
import { CDN_URL, KEY_CODE, PLANS } from 'constants/commonData';
import { isESCPress } from 'utils/commonFunction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { push } from 'connected-react-router';
import { getCurrentUsage, updateSelectPackage, updateZapierPrice } from 'redux/pricing/actions';
import { RootCloseWrapper } from 'react-overlays';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_currentColor.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/check-mark-freestyle-section.svg';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import * as S from './style';
import classNames from 'classnames';

const PERIOD = {
  monthly: 'monthly',
  annually: 'annually',
};

const BASE_MORE_VOLUME = 4000;

const ZapierChangePlanModal = props => {
  const {
    toggleModal,
    toggleConfirmModal,
    period = PERIOD.monthly,
    currentPlan = null,
    push,
    updateSelectPackage,
    addOns,
    zapierBasePricings = [],
    updateZapierPrice,
    addOnsPricing,
    getCurrentUsage,
    scheduledSubscription = null,
    currentZapierUsage,
  } = props;
  const [selectedPeriod, setSelectedPeriod] = useState(period);
  const [hasMorePlan, setHasMorePlan] = useState(false);
  const [selectedMorePlan, setSelectedMorePlan] = useState(null);
  const [hasOpenSecondModal, setHasOpenSecondModal] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const checkScheduledPeriod = get(scheduledSubscription, 'billing_cycle', null) === 1 ? 'monthly' : 'annually';
  const checkCurrentPeriod = get(currentZapierUsage, 'billing_cycle', null) === 'month' ? 'monthly' : 'annually';
  const isScheduledPlan = get(scheduledSubscription, 'zapier_quantity', null);

  const isAnnually = selectedPeriod === PERIOD.annually;
  // const isDowngrade =
  //   (selectedPlan < currentPlan && checkCurrentPeriod === checkScheduledPeriod) ||
  //   (period === 'annually' && selectedPeriod === 'monthly');
  const isDowngradeAndDisabled =
    period === 'annually' && selectedPeriod === 'monthly' && selectedPlan === isScheduledPlan;

  const isDowngrade = useMemo(() => {
    if (period === PERIOD.monthly) {
      if (selectedPeriod === PERIOD.annually) {
        // Always upgrade when period from monthly to annually
        return false;
      }

      if (currentPlan > selectedPlan) {
        return true;
      }
    }

    if (period === PERIOD.annually) {
      if (selectedPeriod === PERIOD.monthly) {
        // Always downgrade when period from annually to monthly
        return true;
      }

      if (currentPlan > selectedPlan) {
        return true;
      }
    }
    return false;
  }, [selectedPeriod, period, selectedPlan, currentPlan]);

  useEffect(() => {
    document.addEventListener('keydown', handlePressEsc);
    return () => {
      document.removeEventListener('keydown', handlePressEsc);
    };
  }, [hasMorePlan, hasOpenSecondModal]);

  useEffect(() => {
    if (!isEmpty(zapierBasePricings) && currentPlan >= BASE_MORE_VOLUME) {
      const plan = zapierBasePricings.find(item => item.packageNoVolumes === currentPlan);
      setSelectedMorePlan(plan);
    }
    getCurrentUsage && getCurrentUsage();
  }, []);

  const handlePressEsc = event => {
    if (event.keyCode === KEY_CODE.esc || isESCPress(event.key)) {
      if (hasMorePlan) {
        setHasMorePlan(false);
        return;
      }

      if (hasOpenSecondModal) {
        toggleConfirmModal(false);
        setHasOpenSecondModal(false);
        return;
      }

      toggleModal(false);
    }
  };

  function filterPricingByPlan(pricings, plan) {
    return pricings
      .map((pricing, index) => ({ ...pricing, index }))
      .filter(pricing => get(pricing, 'packageNoVolumes', 0) === plan);
  }

  const filteredPricing = filterPricingByPlan(zapierBasePricings, selectedPlan);
  const isCurrentPlan = filterPricingByPlan(zapierBasePricings, currentPlan);

  const selectedAddOns = addOnsPricing.map(item => {
    if (get(item, 'code', '') === 'zapier') {
      if (addOns.zapier === true) {
        return {
          ...item,
          basePrice: {
            monthlyPricing: get(filteredPricing, '[0].monthlyPricing', 0),
            anuallyPricing: get(filteredPricing, '[0].anuallyPricing', 0),
          },
        };
      } else {
        return {
          ...item,
          basePrice: {
            monthlyPricing: 0,
            anuallyPricing: 0,
          },
        };
      }
    }
    return item;
  });

  const handleUpgrade = async () => {
    const path = '/home/integration';
    toggleModal(false);
    toggleConfirmModal(false);
    setHasOpenSecondModal(false);

    updateSelectPackage({
      tier: PLANS.studio.key,
      period: isAnnually ? 'annually' : 'monthly',
      addOns: {
        ...addOns,
        zapier: true,
      },
      zapier_quantity: get(filteredPricing, '[0].packageNoVolumes', 0),
    });

    updateZapierPrice({
      addOnsPricing: selectedAddOns,
    });

    push(`/pricing-plan?prev=${path}`, {
      step: 2,
      addOnsPricing: selectedAddOns,
      zapier_quantity: get(filteredPricing, '[0].packageNoVolumes', 0),
      isDowngrade,
      zapierPath: path,
    });
  };

  const DowngradeModalRender = () => {
    return (
      <S.DowngradeWrapper>
        <S.WarningMessage>
          <p>
            Please be aware that the Tier {(get(filteredPricing, '[0].index', 0) + 1).toLocaleString('en-US')} plan has
            a monthly task limit of{' '}
            <span>{`${get(filteredPricing, '[0].packageNoVolumes', 0).toLocaleString('en-US')}`}</span> while your
            existing plan {`(Tier ${(get(isCurrentPlan, '[0].index', 0) + 1).toLocaleString('en-US')})`} has a monthly
            task limit of <span>{`${get(isCurrentPlan, '[0].packageNoVolumes', 0).toLocaleString('en-US')}`}.</span>
          </p>
        </S.WarningMessage>
        <S.DowngradeContent>
          More Zapier tasks will help you:
          <S.FirstOption>
            <CheckedIcon /> Better <span>nurture relationships</span> with your clients through automation
          </S.FirstOption>
          <S.SecondOption>
            <CheckedIcon /> Grants you <span>more free time</span> to focus on other business's aspects
          </S.SecondOption>
          <S.LastSentence>
            Please carefully <span>weigh the potential loss</span> of these automation benefits against your current
            needs and requirements
          </S.LastSentence>
        </S.DowngradeContent>
      </S.DowngradeWrapper>
    );
  };

  const handleChosenPlan = () => {
    if (isDowngrade) {
      setHasOpenSecondModal(true);
      toggleConfirmModal(
        true,
        <S.DowngradeConfirmModal
          title={
            <S.HeaderContent>
              <S.BackButton
                onClick={() => {
                  setHasOpenSecondModal(false);
                  toggleConfirmModal(false);
                }}
              >
                <ArrowUpIcon />
              </S.BackButton>
              <S.HeaderTitle>Have you selected a lower Tier?</S.HeaderTitle>
            </S.HeaderContent>
          }
          content={DowngradeModalRender()}
          onConfirm={handleUpgrade}
          confirmButtonTitle="Continue to downgrade"
          cancelButtonTitle="Cancel"
          noIcon
          noBorder
          hasCloseIcon
        />,
      );
    } else {
      handleUpgrade();
    }
  };

  const handleOpenModal = () => {
    setHasMorePlan(true);
    setTimeout(() => {
      const selectedOption = document.querySelector('.selected-zapier');
      if (selectedOption) {
        selectedOption.scrollIntoView();
      }
    }, 0);
  };

  return (
    <S.ModalWrapper open={true}>
      <S.ModalContainer>
        <Button className="close-button" onClick={() => toggleModal(false)}>
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.ModalHeader>
          <S.LeftHeader>
            <S.HeaderLabel>Choose your Zapier Plan</S.HeaderLabel>
            <S.HeaderDescription>
              Unlock more Zapier tasks, gain more free time, and enhance client relationships with your plan.
            </S.HeaderDescription>
          </S.LeftHeader>
          <S.RightHeader>
            <S.ChoosePlanGroupButton>
              <S.TimeButton
                active={!isAnnually}
                className="monthly"
                onClick={() => {
                  setSelectedPeriod(PERIOD.monthly);
                }}
              >
                Monthly
              </S.TimeButton>
              <S.TimeButton
                active={isAnnually}
                className="annually"
                onClick={() => {
                  setSelectedPeriod(PERIOD.annually);
                }}
              >
                Annually
              </S.TimeButton>
              <S.Discount>
                <S.DiscountCircle />
                <S.DiscountBorder />
              </S.Discount>
              <S.DiscountPercent>Save 16%</S.DiscountPercent>
            </S.ChoosePlanGroupButton>
          </S.RightHeader>
        </S.ModalHeader>
        <S.ModalContent>
          <S.PlanWrapper>
            {zapierBasePricings.map((item, index) => {
              const packageNoVolumes = get(item, 'packageNoVolumes', 0);
              const isSelectedPlan = selectedPlan === packageNoVolumes;

              return (
                index < 3 && (
                  <S.PlanItemContainer
                    key={packageNoVolumes}
                    onClick={() => setSelectedPlan(packageNoVolumes)}
                    checked={isSelectedPlan}
                  >
                    {currentPlan === packageNoVolumes && checkCurrentPeriod === selectedPeriod && (
                      <S.CurrentPlan>Current Plan</S.CurrentPlan>
                    )}
                    {get(scheduledSubscription, 'zapier_quantity', null) === packageNoVolumes &&
                      checkScheduledPeriod === selectedPeriod && <S.ScheduledPlan>Scheduled Plan</S.ScheduledPlan>}
                    <S.TaskPerMonth>
                      <Radio className="radio" checked={isSelectedPlan} />
                      <S.TaskPerMonthText>
                        {new Intl.NumberFormat().format(get(item, 'packageNoVolumes', 0))} tasks&nbsp;
                        <S.SpanText> /month</S.SpanText>
                      </S.TaskPerMonthText>
                    </S.TaskPerMonth>
                    <S.RightContent>
                      <S.Price>
                        {isAnnually ? (
                          <>
                            <S.OldPrice>${new Intl.NumberFormat().format(get(item, 'monthlyPricing'))}</S.OldPrice>$
                            {new Intl.NumberFormat('en-US').format(get(item, 'monthOfAnuallyPricing'))}
                          </>
                        ) : (
                          <>${new Intl.NumberFormat('en-US').format(get(item, 'monthlyPricing'))}</>
                        )}
                        <S.Currency isAnnually={isAnnually}> USD</S.Currency>
                      </S.Price>
                      <S.PerMonth>per month</S.PerMonth>
                    </S.RightContent>
                  </S.PlanItemContainer>
                )
              );
            })}
            {selectedMorePlan ? (
              <S.PlanItemContainer
                key={selectedMorePlan.packageNoVolumes}
                onClick={() => {
                  setHasMorePlan(false);
                  setSelectedPlan(get(selectedMorePlan, 'packageNoVolumes', 0));
                }}
                checked={selectedMorePlan.packageNoVolumes === selectedPlan}
              >
                {currentPlan === selectedMorePlan.packageNoVolumes && checkCurrentPeriod === selectedPeriod && (
                  <S.CurrentPlan>CURRENT PLAN</S.CurrentPlan>
                )}
                {get(scheduledSubscription, 'zapier_quantity', null) === selectedMorePlan.packageNoVolumes &&
                  checkScheduledPeriod === selectedPeriod && <S.ScheduledPlan>Scheduled Plan</S.ScheduledPlan>}
                <S.TaskPerMonth>
                  <Radio className="radio" checked={selectedMorePlan.packageNoVolumes === selectedPlan} />
                  <S.TaskPerMonthText>
                    {new Intl.NumberFormat().format(get(selectedMorePlan, 'packageNoVolumes'))} tasks&nbsp;
                    <S.SpanText> /month</S.SpanText>
                  </S.TaskPerMonthText>

                  <S.GetMorePlan onMouseOver={handleOpenModal} hasMorePlan={!hasMorePlan}>
                    <ArrowUpIcon className="arrow-up-icon" />
                  </S.GetMorePlan>
                </S.TaskPerMonth>
                <S.RightContent>
                  <S.Price>
                    {isAnnually ? (
                      <>
                        <S.OldPrice>
                          ${new Intl.NumberFormat().format(get(selectedMorePlan, 'monthlyPricing'))}
                        </S.OldPrice>
                        ${new Intl.NumberFormat('en-US').format(get(selectedMorePlan, 'monthOfAnuallyPricing'))}
                      </>
                    ) : (
                      <>${new Intl.NumberFormat('en-US').format(get(selectedMorePlan, 'monthlyPricing'))}</>
                    )}
                    <S.Currency isAnnually={isAnnually}> USD </S.Currency>
                  </S.Price>
                  <S.PerMonth>per month</S.PerMonth>
                </S.RightContent>
              </S.PlanItemContainer>
            ) : (
              zapierBasePricings.map((item, index) => {
                const packageNoVolumes = get(item, 'packageNoVolumes', 0);
                const isSelectedPlan = selectedPlan === packageNoVolumes;
                return (
                  index === 3 && (
                    <S.PlanItemContainer
                      key={packageNoVolumes}
                      onClick={() => {
                        setHasMorePlan(false);
                        setSelectedPlan(packageNoVolumes);
                      }}
                      checked={isSelectedPlan}
                    >
                      {currentPlan === packageNoVolumes && checkCurrentPeriod === selectedPeriod && (
                        <S.CurrentPlan>CURRENT PLAN</S.CurrentPlan>
                      )}
                      {get(scheduledSubscription, 'zapier_quantity', null) === packageNoVolumes &&
                        checkScheduledPeriod === selectedPeriod && <S.ScheduledPlan>Scheduled Plan</S.ScheduledPlan>}
                      <S.TaskPerMonth>
                        <Radio className="radio" checked={isSelectedPlan} />
                        <S.TaskPerMonthText>
                          {new Intl.NumberFormat().format(get(item, 'packageNoVolumes'))} tasks&nbsp;
                          <S.SpanText> /month</S.SpanText>
                        </S.TaskPerMonthText>
                        <S.GetMorePlan
                          onMouseOver={() => {
                            setHasMorePlan(true);
                          }}
                          hasMorePlan={!hasMorePlan}
                        >
                          <ArrowUpIcon className="arrow-up-icon" />
                        </S.GetMorePlan>
                      </S.TaskPerMonth>
                      <S.RightContent>
                        <S.Price>
                          {isAnnually ? (
                            <>
                              <S.OldPrice>${new Intl.NumberFormat().format(get(item, 'monthlyPricing'))}</S.OldPrice>$
                              {new Intl.NumberFormat('en-US').format(get(item, 'monthOfAnuallyPricing'))}
                            </>
                          ) : (
                            <>${new Intl.NumberFormat('en-US').format(get(item, 'monthlyPricing'))}</>
                          )}
                          <S.Currency isAnnually={isAnnually}> USD</S.Currency>
                        </S.Price>
                        <S.PerMonth>per month</S.PerMonth>
                      </S.RightContent>
                    </S.PlanItemContainer>
                  )
                );
              })
            )}

            {hasMorePlan && (
              <RootCloseWrapper event="click" onRootClose={() => setHasMorePlan(false)}>
                <S.MorePlan>
                  {zapierBasePricings.map((item, index) => {
                    const packageNoVolumes = get(item, 'packageNoVolumes', 0);
                    const isSelectedPlan = selectedPlan === packageNoVolumes;
                    return (
                      index >= 3 && (
                        <S.MorePlanItemContainer
                          key={packageNoVolumes}
                          checked={isSelectedPlan}
                          onClick={() => {
                            setSelectedPlan(packageNoVolumes);
                            setSelectedMorePlan(item);
                            setHasMorePlan(false);
                          }}
                          className={classNames({
                            'selected-zapier': isSelectedPlan,
                          })}
                        >
                          <S.TaskPerMonth>
                            <Radio className="radio" checked={isSelectedPlan} />
                            <S.TaskPerMonthText>
                              {new Intl.NumberFormat().format(packageNoVolumes)} tasks&nbsp;
                              <S.SpanText>/month</S.SpanText>
                            </S.TaskPerMonthText>
                            {currentPlan === packageNoVolumes && checkCurrentPeriod === selectedPeriod && (
                              <S.CurrentInMorePlan>CURRENT PLAN</S.CurrentInMorePlan>
                            )}
                            {get(scheduledSubscription, 'zapier_quantity', 0) === packageNoVolumes &&
                              checkScheduledPeriod === selectedPeriod && (
                                <S.ScheduledInMorePlan>Scheduled Plan</S.ScheduledInMorePlan>
                              )}
                          </S.TaskPerMonth>
                          <S.RightContent>
                            <S.Price>
                              {isAnnually ? (
                                <>
                                  <S.OldPrice>
                                    ${new Intl.NumberFormat().format(get(item, 'monthlyPricing'))}
                                  </S.OldPrice>
                                  ${new Intl.NumberFormat().format(get(item, 'monthOfAnuallyPricing'))}
                                </>
                              ) : (
                                <> ${new Intl.NumberFormat().format(get(item, 'monthlyPricing'))}</>
                              )}

                              <S.Currency isAnnually={isAnnually}> USD</S.Currency>
                            </S.Price>
                            <S.PerMonth>per month</S.PerMonth>
                          </S.RightContent>
                        </S.MorePlanItemContainer>
                      )
                    );
                  })}
                </S.MorePlan>
              </RootCloseWrapper>
            )}
            <S.GetMore>
              If you need more than <span>20,000 tasks</span> each month, please contact {''}
              <S.ContactLink>enterprise@everfit.io</S.ContactLink> to modify your plan.
            </S.GetMore>
          </S.PlanWrapper>
        </S.ModalContent>
        <S.ModalFooter>
          <S.ConfirmButton
            disabled={
              (selectedPlan === currentPlan && period === selectedPeriod) ||
              (period !== selectedPeriod && !selectedPlan) ||
              isDowngradeAndDisabled
            }
            onClick={handleChosenPlan}
            isDowngrade={isDowngrade && !isDowngradeAndDisabled}
          >
            {isDowngrade ? 'Downgrade' : 'Choose Plan'}
          </S.ConfirmButton>
        </S.ModalFooter>
      </S.ModalContainer>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const addOnsPricing = rootReducer.pricing.get('addOnsPricing').toJS() || [];
  const zapierPricing = addOnsPricing.find(item => item.code === 'zapier');
  const zapierBasePricings = get(zapierPricing, 'basePricings', []);
  const currentUsageJS = rootReducer.pricing.get('zapierCurrentUsage').toJS();

  return {
    user,
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    zapierBasePricings,
    addOns: rootReducer.pricing.getIn(['selectPackage', 'addOns']).toJS(),
    currentPackagePrice: rootReducer.pricing.getIn(['selectPackage', 'packagePrice']).toJS(),
    addOnsPricing,
    isUpdatingPromo: rootReducer.pricing.get('isUpdatingPromo'),
    period: rootReducer.pricing.getIn(['selectPackage', 'period']),
    scheduledSubscription: get(currentUsageJS, 'scheduledSubscription', 0),
    currentZapierUsage: rootReducer.pricing.get('zapierCurrentUsage').toJS(),
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
  updateZapierPrice: bindActionCreators(updateZapierPrice, dispatch),
  getCurrentUsage: bindActionCreators(getCurrentUsage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZapierChangePlanModal);
