export const MESSAGE_VALIDATE = {
  REQUIRED: 'This field cannot be empty',
  INVALID_EMAIL_FORMAT: 'Wrong format email',
  PHONE_INVALID_CHARACTERS: 'Wrong format phone',
  INVALID_EMAIL: 'Invalid email',
  MISSING_REQUIRED_FIELDS: 'Please complete all required fields',
};

export const ADD_ONS_PRICING_CODE = {
  AUTOMATION: 'automation',
  RESOURCE_COLLECTIONS: 'resource_collections',
  PAYMENT_PACKAGE: 'payment_package',
  PREMIUM_CUSTOM_BRAND: 'premiumCustomBrand',
  ZAPIER: 'zapier',
  MEAL_PLAN: 'meal_plan',
};

export const PLAN_PRICING_CODE = {
  STARTER: 'starter',
  PRO: 'pro',
  STUDIO: 'studio',
};
