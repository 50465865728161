import { DateTime } from 'luxon';
import moment from 'moment';

export const formatDate = (data, format = 'YYYY-MM-DD') => {
  return moment(data).format(format);
};

export const getPercent = (numerator, denominator) => {
  return `${Math.round(Number((Number(numerator) / Number(denominator)) * 100 || 0))}%`;
};

export const addPrefix = (value, prefix = '0') => {
  let data = '00';
  if (value) {
    data = (prefix + value).slice(-2);
  }
  return data;
};

export const formatDateWithLuxon = (date, fromFormat = 'yyyy-MM-dd', toFormat = 'kkkk-WW') => {
  return date && DateTime.fromFormat(date, fromFormat).toFormat(toFormat);
};
