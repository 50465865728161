import { TextBase, TextOverflow } from 'components/MetricGroupLibrary/style';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

const activeTrigger = css`
  color: #202353;
  background: #f0f0f2;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100% - 90px);

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }

  .__react_component_tooltip.app-tooltip {
    background-color: #333333;
    border-radius: 4px;
    padding: 8px 16px 8px 16px;

    :after {
      bottom: -8px !important;
    }
  }
  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #333333;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 30px 20px;
  margin-bottom: 30px;
  margin-right: 15px;

  @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1280px) and (max-width: 1599px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1040px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (max-width: 1039px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  cursor: pointer;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  overflow: hidden;

  :hover {
    border: 1px solid #5158cf;
  }
`;

export const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #f0f0f2;
`;

export const GroupLabel = styled.div`
  display: flex;
  width: 80%;
`;

export const Trigger = styled.div`
  display: flex;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  :hover {
    background-color: #e1e1ea;
    .trigger-icon {
      path {
        fill: #5158cf;
      }
    }
  }

  .evf-dropdown__menu {
    margin-top: -5px !important;
  }
  .open {
    background-color: #e1e1ea;
    border-radius: 4px;
    .trigger-icon {
      path {
        fill: #5158cf;
      }
    }
  }

  .custom-dropdown-option {
    display: flex;
    width: 150px;
    padding: 8px 12px 8px 15px;
    align-items: center;
    gap: 15px;
  }
`;

export const GroupName = styled.div`
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  ${TextBase}
  color: #202353;
  font-size: 15px;
  margin-left: 12px;
`;

export const ItemContent = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  background: #fff;
  height: 125px;
`;

export const Metrics = styled.div`
  gap: 5px;
  ${TextBase}
  ${TextOverflow}
  line-height: 175%;
  color: #202353;
  font-weight: 400;
  font-size: 13px;
  word-break: break-word;
  height: 100px;
`;

export const Metric = styled.div`
  display: flex;
  align-items: center;

  ::after {
    padding-left: 3px;
    content: '•';
    color: #a3a3b5;
    display: flex;
    align-items: center;
  }

  :last-child {
    ::after {
      display: none;
    }
  }
`;

export const BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const MetricNumber = styled.div`
  ${TextBase}
  color:  #7b7e91;
  font-size: 10px;
  text-transform: uppercase;
`;

export const AvatarWrapper = styled.div`
  ${TextBase}
  color:  #7b7e91;
  font-size: 10px;
  height: 20px;

  .sb-avatar,
  .sb-avatar * {
    pointer-events: none;
  }
`;

export const Switcher = styled.div`
  width: 265px;
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #dadbea;
  height: 30px;
  overflow: hidden;
`;

export const SelectedSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${TextBase}
  color: #202353;
  font-size: 12px;
  font-weight: 600;
  width: 140px;
  padding: 0 14px;
  white-space: nowrap;
  cursor: pointer;
  ${props =>
    props.active
      ? activeTrigger
      : css`
          :hover {
            color: #5158cf;
            background: #f0f1ff;
          }
        `}

  :last-child {
    border-left: 1px solid #dadbea;
  }
`;

export const OptionIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const EmptyIcon = styled.div`
  display: flex;
  margin-bottom: 21px;
  margin-top: 200px;
`;

export const EmptyLabel = styled.span`
  ${TextBase}
  font-size: 13px;
  color: #202353;
  margin-bottom: 30px;
`;

export const NoSearchResultLabel = styled.span`
  ${TextBase}
  font-size: 13px;
  color: #202353;
  margin-top: 200px;
`;

export const MetricNameWrapper = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-line-clamp: 2;
`;

export const NameSpan = styled.span`
  &.dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: 0 6px;
    border-radius: 50%;
    background-color: #a3a3b5;
    vertical-align: middle;
  }
`;

export const AddMetricButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 10px 15px;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #fff;
  ${TextBase}
  font-size: 13px;
  color: #202353;

  :hover {
    background: #f0f1ff;
    color: #5158cf;
    .plus-icon {
      path {
        fill: #5158cf;
      }
    }
  }

  .plus-icon {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
`;
