import { connect } from 'react-redux';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import {
  getWorkoutCollectionList,
  addNewWorkoutCollection,
  removeCollection,
  duplicateCollection,
  changeQueryParams,
  loadMoreWorkoutCollections,
  editCollectionOnboarding,
  resetLoadMore,
  updateShareStatus,
} from 'redux/workout-collection/actions';
import { hideWorkoutCollectionBanner } from 'redux/general-settings/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

import WorkoutCollections from './component';

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: { workoutCollection, generalSettings, permission },
    user,
  } = state;

  return {
    user: state.user,
    cloudfrontList,
    workouts: _.get(workoutCollection, 'list', []),
    textSearch: workoutCollection.query.textSearch,
    query: workoutCollection.query,
    loading: workoutCollection.loading,
    isPassedFirstTimeAdd: _.get(user, 'is_passed_on_demand_collection_onboarding', false),
    payment: permission.payment,
    isHideBanner: _.get(generalSettings, 'is_passed_workout_collection_banner_onboarding', false),
    onboarding_flow: permission.onboarding_flow,
  };
};

const mapDispatch = dispatch => {
  return {
    getWorkoutCollectionList: bindActionCreators(getWorkoutCollectionList, dispatch),
    addNewWorkoutCollection: bindActionCreators(addNewWorkoutCollection, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    push: bindActionCreators(push, dispatch),
    removeCollection: bindActionCreators(removeCollection, dispatch),
    duplicateCollection: bindActionCreators(duplicateCollection, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    loadMoreWorkoutCollections: bindActionCreators(loadMoreWorkoutCollections, dispatch),
    editCollectionOnboarding: bindActionCreators(editCollectionOnboarding, dispatch),
    resetLoadMore: bindActionCreators(resetLoadMore, dispatch),
    hideWorkoutCollectionBanner: bindActionCreators(hideWorkoutCollectionBanner, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    updateShareStatus: bindActionCreators(updateShareStatus, dispatch),
  };
};

export default connect(mapState, mapDispatch)(WorkoutCollections);
