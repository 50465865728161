import React from 'react';
import _ from 'lodash';

import * as S from './style';

class WeekCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      week: this.getFirstWeek(props.defaultDay),
      days: this.getDaysByWeek(this.getFirstWeek(props.defaultDay), props.defaultDay, false),
      selectedDay: props.defaultDay || 0,
    };
  }

  componentDidMount() {
    this.fetchWorkouts();
  }

  getDaysByWeek = (week, selectedDay, isHide) => {
    const { maxWeeks } = this.props;
    const weekLimit = isHide ? 3 : 4;
    let startWeek = this.getFirstWeek(selectedDay);
    if (isHide) {
      const currentWeek = _.floor(selectedDay / 7);
      startWeek = currentWeek - 1;
      startWeek = maxWeeks >= currentWeek + weekLimit ? startWeek : maxWeeks - weekLimit;
      startWeek = startWeek < 0 ? 0 : startWeek;
    }
    const endWeek = startWeek + weekLimit > maxWeeks ? maxWeeks : startWeek + weekLimit;
    return _.range(startWeek * 7, endWeek * 7);
  };

  getFirstWeek = selectedDay => {
    const currentWeek = _.floor(selectedDay / 7);
    return _.floor(currentWeek / 4) * 4;
  };

  fetchWorkouts = () => {
    const { week } = this.state;
    const { workoutData } = this.props;
    const startWeek = week;
    const endWeek = week + 4;
    this.props.fetchWorkoutsByWeek({ startWeek, endWeek }).then(result => {
      this.props.updateWorkoutSchedule({ ...workoutData, ...result });
      this.handleChangeSelectedDay({ ...workoutData, ...result });
    });
  };

  handleChangeSelectedDay = () => {
    const { selectedDay } = this.state;
    const { workoutData } = this.props;
    const data = _.get(workoutData, [selectedDay], {
      day: selectedDay,
      workouts: [],
    });
    this.props.onSelectDay(data);
  };

  handleNext = () => {
    let { week } = this.state;
    const { maxWeeks } = this.props;
    if (week + 4 >= maxWeeks) {
      return;
    }
    week = week + 4;
    const toWeek = week + 4 > maxWeeks ? maxWeeks : week + 4;
    const days = _.range(week * 7, toWeek * 7);
    this.setState({ week, days }, this.fetchWorkouts);
    this.props.onChangeWeek(week);
  };

  handlePrev = () => {
    let { week } = this.state;
    const { maxWeeks } = this.props;
    if (week < 4) {
      return;
    }
    week = week - 4;
    const toWeek = week + 4 > maxWeeks ? maxWeeks : week + 4;
    const days = _.range(week * 7, toWeek * 7);
    this.setState({ week, days }, this.fetchWorkouts);
    this.props.onChangeWeek(week);
  };

  handleSelectDay = day => {
    let firstWeek = this.getFirstWeek(day);
    let { days, week } = this.state;
    const { maxWeeks } = this.props;
    if (week !== firstWeek) {
      const toWeek = firstWeek + 4 > maxWeeks ? maxWeeks : firstWeek + 4;
      days = _.range(firstWeek * 7, toWeek * 7);
    }
    this.setState({ selectedDay: day, week: firstWeek, days }, () => {
      this.handleChangeSelectedDay();
    });
  };

  getNoDots = day => {
    const { workoutData } = this.props;
    const workouts = _.get(workoutData, [day, 'workouts'], []);
    return workouts.length > 3 ? 3 : workouts.length;
  };

  render() {
    const { week, selectedDay } = this.state;
    let { days } = this.state;
    const { maxWeeks, isHide } = this.props;
    const disabledNext = week + 4 >= maxWeeks;
    const disabledPrev = week < 4;
    const currentWeek = _.floor(selectedDay / 7);

    if (isHide && currentWeek >= week && currentWeek < week + 4) {
      days = this.getDaysByWeek(week, selectedDay, isHide);
    }

    return (
      <S.Wrapper>
        <S.Heading>
          <S.WeekTitle>
            Week {week + 1}&nbsp;-&nbsp;{week + 4}
          </S.WeekTitle>
          <S.Actions>
            <S.ActionButton disabled={disabledPrev} onClick={this.handlePrev} />
            <S.ActionButton disabled={disabledNext} next onClick={this.handleNext} />
          </S.Actions>
        </S.Heading>
        <S.Title>Choose day</S.Title>
        <S.Calendar>
          {days.map(day => (
            <S.DayCalendar active={selectedDay === day} onClick={() => this.handleSelectDay(day)} key={day}>
              {day + 1}
              <S.WorkoutDotContainer>
                {_.range(0, this.getNoDots(day)).map(i => (
                  <S.WorkoutDot key={i} />
                ))}
              </S.WorkoutDotContainer>
            </S.DayCalendar>
          ))}
        </S.Calendar>
      </S.Wrapper>
    );
  }
}

export default WeekCalendar;
