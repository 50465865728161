// Libs
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import Select from 'react-select';
import map from 'lodash/map';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

// Shared
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import CountryModal from 'shared/CountryModal';
import ConfirmIdentityStripe from './ConfirmIdentityStripe';

// Constants
import { checkCountryUS, getUserShortName, getCookie, genTokenFromMarketplace } from 'utils/commonFunction';
import { CURRENCIES, COUNTRIES as DEFAULT_COUNTRIES, GERMANY_SWITZERLAND, C_KEY_TOKEN } from 'constants/commonData';

// Actions
import {
  getOnBoarding,
  getConnectedAccount,
  grantAccess,
  getDashboardLink,
  getVerifySessionLink,
} from 'redux/setup-payment/actions';
import { toggleConfirmModal } from 'actions/modal';

// Assets
import { ReactComponent as Remove } from 'assets/icons/remove_bold.svg';
import Stripe from 'assets/images/stripe_brand.png';
import TrashIcon from 'assets/icons/trash_light.svg';

// Styles
import * as S from './style';

const COUNTRIES = process.env.REACT_APP_ENABLE_PAYMENT_GERMANY_SWITZERLAND
  ? [...DEFAULT_COUNTRIES, ...GERMANY_SWITZERLAND]
  : DEFAULT_COUNTRIES;

const CURRENCY_OPTIONS = map(CURRENCIES, obj => obj);
const queryCurrency = origin => {
  const obj = CURRENCY_OPTIONS.find(item => item.value === origin) || CURRENCY_OPTIONS[0];

  return { ...obj };
};

const queryCountryByCurrency = (origin, country) => {
  const obj = COUNTRIES.find(item => item.currency === origin && item.value === country) || COUNTRIES[3];
  return { ...obj };
};

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select__option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  return (
    <S.ContactInformation {...innerProps} className={className}>
      <Avatar name={getUserShortName(data)} size="40" src={data.avatar} color={data.color} />
      <S.SingleLabel className="select__option__label">
        <div className="name">
          {data.first_name} {data.last_name}
        </div>
        <div className="email">{data.email}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const Team = ({ data, onRemove }) => {
  return (
    <S.ContactInformation className="team">
      <Avatar name={getUserShortName(data)} size="36" src={data.avatar} color={data.color} />
      <S.SingleLabel className="select__option__label">
        <div className="name">{data.full_name}</div>
        <div className="email">{data.email}</div>
      </S.SingleLabel>
      <Remove onClick={onRemove} data-tip="Remove Access" data-for={`${data._id}`} />
      <ReactTooltip className="app-tooltip" id={`${data._id}`} effect="solid" place={'top'} />
    </S.ContactInformation>
  );
};

const SingleValue = ({ innerProps, data }) => {
  return (
    <S.ContactInformation {...innerProps}>
      <S.SingleLabel className="select__option__label">
        <div className="name">{data.label}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const SetupPayment = ({
  user,
  toggleConfirmModal,
  getOnBoarding,
  getConnectedAccount,
  payment,
  grantAccess,
  permission,
  push,
  location,
  getDashboardLink,
  getVerifySessionLink,
  dispatch,
}) => {
  const [currencyValue, setCurrencyValue] = useState({});
  const [country, setCountry] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectingTeam, setSelectingTeam] = useState([]);
  const selectInputRef = useRef();
  const [teams, setTeams] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isInform, setIsInform] = useState(false);

  const isUS = checkCountryUS(get(payment, 'account.account.country', ''));
  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const completed = query.get('success');
    const chargesEnabled = get(payment, 'account.charges_enabled');
    if (completed && chargesEnabled && !isInform) {
      toast('Your Stripe account has connected');
      setIsInform(true);
      push('setup-payment');
    }
  }, [payment]);

  useEffect(() => {
    getConnectedAccount();
  }, [permission]);

  useEffect(() => {
    const grantAccessIds = get(payment, 'account.grant_access');
    const currency = get(payment, 'account.currency');
    const country = get(payment, 'account.account.country', '').toLowerCase();
    setCurrencyValue(queryCurrency(currency));
    setCountry(queryCountryByCurrency(currency, country));
    if (grantAccessIds) {
      setSelectedTeam(user.team_member.filter(item => grantAccessIds.includes(item._id) && item._id !== user._id));
    }
  }, [user, payment]);

  useEffect(() => {
    let selectedTeamIds = selectedTeam.map(team => {
      return team._id;
    });
    setTeams(user.team_member.filter(itemX => !selectedTeamIds.includes(itemX._id) && itemX._id !== user._id));
  }, [selectedTeam]);

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({ token, user, pathname, search, dispatch });
    }
  }, [search, pathname]);

  const options = useMemo(
    () =>
      (teams || []).map(item => ({
        ...item,
        key: item._id,
        value: item._id,
        label: item.full_name,
      })),
    [teams],
  );

  const handleSelectCountry = item => {
    setCountry(item);
  };

  const handleRemoveTeam = id => () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Are you sure?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        hasCloseIcon
        newStyle={true}
        content="Once you remove the teammate, they will not have access to the Payments feature anymore. Would you like to continue?"
        headerIcon={TrashIcon}
        onConfirm={handleConfirmRemove(id)}
      />,
    );
    return;
  };

  const handleConfirmRemove = id => () => {
    const newIds = selectedTeam.filter(item => item._id !== id).map(it => it._id);
    grantAccess({
      grant_access: newIds,
    });
  };

  const handleSelectTeam = item => {
    setSelectingTeam(item);
  };

  const handleSave = () => {
    const selectedTeamIds = [...selectedTeam, ...selectingTeam].map(team => {
      return team._id;
    });
    grantAccess({
      grant_access: selectedTeamIds,
    });
    setSelectedTeam([...selectedTeam, ...selectingTeam]);
    onClear();
  };

  const onClear = () => {
    selectInputRef.current.select.clearValue();
  };

  const handleConnect = async () => {
    const chargesEnabled = get(payment, 'account.charges_enabled');
    if (!chargesEnabled) {
      setIsOpen(true);
      return;
    }
    let stripeURL = 'https://dashboard.stripe.com/dashboard';
    const responsive = await getDashboardLink();
    if (responsive) {
      stripeURL = get(responsive, 'data.data.dashboardUrl');
    }
    window.open(stripeURL);
  };

  const handleCurrencyChange = newValue => {
    grantAccess({
      currency: newValue.value,
    });
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleNextStep = async () => {
    const responsive = await getOnBoarding({
      country: country.value,
    });
    if (responsive) {
      window.location = get(responsive, 'data.data.url');
    }
  };

  const email = get(payment, 'account.account.email');
  const chargesEnabled = get(payment, 'account.charges_enabled');
  const isDisable = chargesEnabled && !email;

  const getMessage = () => {
    if (chargesEnabled) {
      if (email) {
        return email;
      }
      return 'Your account is pending Stripe verification';
    }
    return 'Connect your Stripe account to offer paid packages';
  };

  // TODO - Client Secret Key To open Stripe pop-up
  const clientSecret = get(payment, 'clientSecret');
  const verifyStatus = get(payment, 'account.verify_status');
  const verificationSessions = get(payment, 'account.verification_sessions');

  return (
    <S.Wrapper>
      <S.SectionWrapper>
        <S.CenterContainer>
          <S.Title>Add Payment Gateway</S.Title>
          <S.SectionContainer background={Stripe}>
            <S.DescWrapper>
              <S.SubTitle>Stripe Gateway</S.SubTitle>
              <S.Desc>{getMessage()}</S.Desc>
            </S.DescWrapper>
            <Button disabled={isDisable} onClick={handleConnect}>
              {chargesEnabled ? 'View Account' : 'Connect'}
            </Button>
          </S.SectionContainer>
        </S.CenterContainer>
      </S.SectionWrapper>
      <S.SectionWrapper>
        <S.CenterContainer>
          {/* TODO - Confirm Identity */}
          <ConfirmIdentityStripe
            onIdentityVerification={getVerifySessionLink}
            onGetConnectedAccount={getConnectedAccount}
            clientSecret={clientSecret}
            verifyStatus={verifyStatus}
            chargesEnabled={chargesEnabled}
            isUS={isUS}
            verificationSessions={verificationSessions}
          />
        </S.CenterContainer>
      </S.SectionWrapper>
      {chargesEnabled && country && (
        <S.SectionWrapper>
          <S.CenterContainer>
            <S.Title>Currency</S.Title>
            <S.SectionContainer spaceBetween={true}>
              <S.DescWrapper>
                <S.SubTitle>{country.label}</S.SubTitle>
                <S.Desc>This is the default currency for packages listed by your workplace</S.Desc>
              </S.DescWrapper>
              <Select
                options={CURRENCY_OPTIONS}
                components={{ IndicatorSeparator: null, DropdownIndicator: null }}
                onChange={handleCurrencyChange}
                value={currencyValue}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                isSearchable={false}
                isDisabled={true}
                menuPosition="fixed"
                styles={{
                  control: base => ({ ...base, minHeight: 42, height: 42, minWidth: 125 }),
                }}
              />
            </S.SectionContainer>
          </S.CenterContainer>
        </S.SectionWrapper>
      )}
      <S.SectionWrapper>
        <S.CenterContainer>
          <S.Title>Permission</S.Title>
          <S.SectionTeamContainer>
            <S.SectionPayment>
              <S.DescWrapper className="custom">
                <S.SubTitle>Grant Access</S.SubTitle>
                <S.Desc>Add teammates to manage your packages</S.Desc>
              </S.DescWrapper>
              <Select
                ref={selectInputRef}
                options={options}
                components={{
                  Option: SelectOption,
                  DropdownIndicator: null,
                  SingleValue,
                  IndicatorSeparator: null,
                  ClearIndicator: null,
                }}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                placeholder="Add Teammates"
                onChange={handleSelectTeam}
                isSearchable={true}
                isMulti={true}
                menuPosition="fixed"
                styles={{
                  control: base => ({ ...base, height: 46, width: 548 }),
                }}
              />
            </S.SectionPayment>
            {selectingTeam.length > 0 && (
              <S.SectionPayment>
                <Button onClick={handleSave}>Add</Button>
              </S.SectionPayment>
            )}
            {selectedTeam.length > 0 && (
              <S.Teams>
                {selectedTeam.map(data => (
                  <Team data={data} onRemove={handleRemoveTeam(data._id)} />
                ))}
              </S.Teams>
            )}
          </S.SectionTeamContainer>
        </S.CenterContainer>
      </S.SectionWrapper>
      {isOpen && <CountryModal onNextStep={handleNextStep} onChange={handleSelectCountry} onClose={onClose} />}
    </S.Wrapper>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: { setupPayment, permission },
  } = state;

  return { user, payment: setupPayment.toJS(), permission };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getOnBoarding: bindActionCreators(getOnBoarding, dispatch),
  getConnectedAccount: bindActionCreators(getConnectedAccount, dispatch),
  grantAccess: bindActionCreators(grantAccess, dispatch),
  push: bindActionCreators(push, dispatch),
  getDashboardLink: bindActionCreators(getDashboardLink, dispatch),
  getVerifySessionLink: bindActionCreators(getVerifySessionLink, dispatch),
});

export default connect(mapState, mapDispatchToProps)(SetupPayment);
