import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styled, { css } from 'styled-components';
import { DropdownIndicator } from 'shared/Icons';
import countries from 'constants/countries';
import { COUNTRIES, GERMANY_SWITZERLAND } from 'constants/commonData.js';
import { ReactComponent as CloseCircleIcon } from 'assets/icons/close_bold_circle.svg';

import { toggleModal } from 'actions/modal';
import { requestNewCountry } from 'redux/product/actions';

const ModalWrapper = styled(Modal)`
  border-radius: 4px !important;
  max-width: 400px;

  .content {
    border-radius: 4px;
    padding: 32px !important;
  }
  .close-button {
    right: -12px;
    top: -12px;
  }
`;

const countrySelectorStyle = {
  control: base => ({
    ...base,
    '.request-country-select__menu-list::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '.request-country-select__menu-list::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
};

const CountryDropdown = styled(Select)`
  .request-country-select__menu {
    margin-top: 0px !important;
    padding-right: 4px;
  }

  .request-country-select__control {
    border-radius: 6px;

    &--is-focused,
    &--is-opened {
      border-color: #5e59ff !important;
      box-shadow: none !important;
    }
  }

  .request-country-select__option {
    &--is-focused {
      background-color: rgba(94, 89, 255, 0.08);
    }
    &--is-selected {
      background-color: #5e59ff;
    }
  }

  .request-country-select__menu-list {
    padding-right: 4px;
    ::-webkit-scrollbar {
      width: 4px !important;
    }
  }

  .request-country-select__indicator-separator {
    display: none;
  }
`;

const CountryModalTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;

const CountryModalDesc = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
`;

const CountryModalDropdown = styled.div`
  margin-top: 8px;
`;

const SubmitButton = styled.button`
  margin-top: 30px;
  padding: 14px 16px;
  width: 336px;
  height: 48px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  background: #5e59ff;
  border-radius: 4px;

  ${props =>
    props.disabled &&
    css`
      background: #c4c4c4;
    `}
`;

function NewCountryModal({ handleCloseCountryModal, requestNewCountry }) {
  const openedCountries = [...COUNTRIES, ...GERMANY_SWITZERLAND];
  const filteredCountries = countries.filter(
    country => !openedCountries.map(item => item.value.toUpperCase()).includes(country.value),
  );

  const [country, setCountry] = useState();

  const handleSubmitRequest = () => {
    requestNewCountry(country.label);
    handleCloseCountryModal();
  };
  return (
    <ModalWrapper
      size={'tiny'}
      open={true}
      onClose={handleCloseCountryModal}
      closeOnDimmerClick={false}
      closeIcon={
        <Button className="close-button">
          <CloseCircleIcon />
        </Button>
      }
    >
      <Modal.Content>
        <CountryModalTitle>Request new country</CountryModalTitle>
        <CountryModalDesc>Choose your country</CountryModalDesc>
        <CountryModalDropdown>
          <CountryDropdown
            options={filteredCountries}
            components={{ DropdownIndicator }}
            className="country-select-container"
            classNamePrefix="request-country-select"
            onChange={newCountry => setCountry(newCountry)}
            noOptionsMessage={() => 'No result'}
            value={country}
            menuPosition="fixed"
            styles={countrySelectorStyle}
            placeholder="Select Country"
            blurInputOnSelect={true}
          />
        </CountryModalDropdown>
        <SubmitButton disabled={isEmpty(country)} onClick={handleSubmitRequest}>
          Submit
        </SubmitButton>
      </Modal.Content>
    </ModalWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;

  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    requestNewCountry: bindActionCreators(requestNewCountry, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCountryModal);
