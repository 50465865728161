import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import * as S from './style';
import DocumentUpload from './DocumentUpload';
import UploadCoverImage from 'shared/UploadCoverImage';
import { FormGroup } from 'shared/FormControl';
import { CloseIcon } from '../style';
import { mediaLog, mongoObjectId } from 'utils/commonFunction';
import { LIMIT_SIZE_IMAGE_UPLOAD_MB, showMsgForSize } from 'constants/commonData';

class DocumentAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      title: '',
      attachmentData: {},
      file: null,
    };
    this.uploadingDocument = null;
    this.uploadingCoverImage = null;
  }

  componentDidMount() {
    const { file } = this.props;
    if (file) {
      this.setState({ file });
    } else {
      this.setState({
        attachmentData: _.get(this.props.attachments, 'attachments.0', {}),
        title: this.props.attachments.title,
      });
    }
  }

  onRemoveDocument = () => {
    this.setState({ attachmentData: {}, title: '', isUploading: false, file: null }, () => {
      const removeUploadList = [];

      if (this.uploadingDocument) {
        removeUploadList.push({ uploadId: this.uploadingDocument, status: true });
        this.uploadingDocument = null;
      }

      if (this.uploadingCoverImage) {
        removeUploadList.push({ uploadId: this.uploadingCoverImage, status: true });
        this.uploadingCoverImage = null;
      }

      if (removeUploadList.length) {
        this.props.updateUploadStatus(removeUploadList);
      }

      this.props.onUpdateAttachmentData({ title: '', attachments: [] });
    });
  };

  markCoverImageUploadDone = () => {
    this.props.updateUploadStatus([{ uploadId: this.uploadingCoverImage, status: true }]);
    this.uploadingCoverImage = null;
  };

  markDocumentUploadDone = () => {
    this.props.updateUploadStatus([{ uploadId: this.uploadingDocument, status: true }]);
    this.uploadingDocument = null;
  };

  onRemoveCoverImage = () => {
    this.setState(
      {
        attachmentData: {
          ...this.state.attachmentData,
          thumbnail_url: null,
        },
        isUploading: false,
      },
      () => {
        if (this.uploadingCoverImage) {
          this.markCoverImageUploadDone();
        }

        this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
      },
    );
  };

  onUploadDocumentSuccess = attachmentData => {
    this.setState({ attachmentData, title: attachmentData.name }, () => {
      const { name, mimetype: fileType, size: fileSize, createdAt, updatedAt } = attachmentData;
      mediaLog({
        status: 2,
        name,
        fileSize,
        fileType,
        createdAt,
        updatedAt,
        description: 'Upload success file via create task',
      });
      this.markDocumentUploadDone();
      this.props.onUpdateAttachmentData({ attachments: [attachmentData], title: this.state.title });
    });
  };

  onUploadCoverImageSuccess = ({ src }) => {
    this.setState(
      {
        attachmentData: {
          ...this.state.attachmentData,
          thumbnail_url: src,
        },
      },
      () => {
        this.markCoverImageUploadDone();
        this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
      },
    );
  };

  onStartUploadDocument = file => {
    const newStates = { isUploading: true };

    if (file && file.name) {
      const { name, size: fileSize, type: fileType } = file;
      mediaLog({
        status: 1,
        name,
        fileSize,
        fileType,
        description: 'Send a file via create task',
      });
      newStates.title = file.name;
    }

    this.uploadingDocument = mongoObjectId();
    this.props.updateUploadStatus([{ uploadId: this.uploadingDocument }]);
    this.setState(newStates);
  };

  onStartUploadCoverImage = () => {
    const newStates = { isUploading: true };
    this.uploadingCoverImage = mongoObjectId();
    this.props.updateUploadStatus([{ uploadId: this.uploadingCoverImage }]);
    this.setState(newStates);
  };

  onCancelUploadCoverImage = () => {
    this.setState({ isUploading: false, file: null }, () => {
      this.markCoverImageUploadDone();
    });
  };

  onChangeDocumentName = e => {
    this.setState({ title: e.target.value }, () => {
      this.props.onUpdateAttachmentData({ title: this.state.title });
    });
  };

  render() {
    const { isUploading, attachmentData, title } = this.state;
    return (
      <S.DocumentAttachmentWrapper className={classNames({ isUploaded: attachmentData.url || isUploading })}>
        <CloseIcon className="taskAttachment__closeIcon" onClick={this.props.onClose} />
        {attachmentData.url || isUploading ? (
          <FormGroup>
            <label>Document Name</label>
            <input value={title} placeholder={attachmentData.name} onChange={this.onChangeDocumentName} />
          </FormGroup>
        ) : null}
        <FormGroup className="taskAttachment__documentUpload">
          {attachmentData.url || isUploading ? null : <label>Upload Document </label>}
          <DocumentUpload
            key="document_file"
            onRemove={this.onRemoveDocument}
            onUploadSuccess={this.onUploadDocumentSuccess}
            onStartUpload={this.onStartUploadDocument}
            onGetAttachment={this.props.onGetAttachment}
            attachmentData={attachmentData}
            file={this.state.file}
            taskId={this.props.taskId}
            shouldAllowDownload={this.props.shouldAllowDownload}
          />
        </FormGroup>
        {attachmentData.url || isUploading ? (
          <>
            <S.Label>Cover Image (optional)</S.Label>
            <UploadCoverImage
              key="cover_image"
              className="taskAttachment__uploadCover"
              onRemove={this.onRemoveCoverImage}
              onUploadSuccess={this.onUploadCoverImageSuccess}
              onStartUpload={this.onStartUploadCoverImage}
              onEndUpload={this.onCancelUploadCoverImage}
              src={attachmentData.thumbnail_url}
              uploadKey="media"
              hasRemoveIcon
              hasPresignedUrl
              maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
              fileSizeLimitMessage={showMsgForSize(LIMIT_SIZE_IMAGE_UPLOAD_MB)}
            />
          </>
        ) : null}
      </S.DocumentAttachmentWrapper>
    );
  }
}

export default DocumentAttachment;
