import React from 'react';
import { DropdownIndicator } from 'shared/Icons';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import classNames from 'classnames';
import { getUserShortName } from 'utils/commonFunction';
import * as S from './style';
import './style.scss';

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select_option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  const name = `${data.first_name} ${data.last_name}`;

  return (
    <S.SelectOptionContainer {...innerProps} className={className}>
      <Avatar name={getUserShortName(data)} size="26" src={data.avatar} color={data.color} />
      <S.SelectOptionLabel className="select__option__label">
        <div className="name">{name}</div>
        <div className="email">{data.email}</div>
      </S.SelectOptionLabel>
    </S.SelectOptionContainer>
  );
};

const SingleValue = ({ innerProps, data }) => {
  return (
    <S.SingleValue {...innerProps} className="teammate-single-select__single-value">
      <Avatar name={getUserShortName(data)} size="26" src={data.avatar} />
      <S.SelectOptionLabel className="select__option__label">
        <div className="text">
          {data.first_name} {data.last_name}
        </div>
      </S.SelectOptionLabel>
    </S.SingleValue>
  );
};

function SelectSingleTeamMember(props) {
  const { options, className, placeholder, noOptionsMessage, customComponents, otherProps } = props;

  return (
    <Select
      options={options}
      components={{ Option: SelectOption, DropdownIndicator, SingleValue, ...customComponents }}
      classNamePrefix="teammate-single-select"
      placeholder={placeholder || 'Choose a team member'}
      onChange={props.onChange}
      className={classNames('teammate-single-select-container', className)}
      noOptionsMessage={() => noOptionsMessage || 'No result'}
      {...otherProps}
    />
  );
}

SelectSingleTeamMember.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  noOptionsMessage: PropTypes.string,
  className: PropTypes.string,
};

export default SelectSingleTeamMember;
