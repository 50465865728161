/**
 * @flow
 */

import { connect } from 'react-redux';
import ErrorPopup from './component';

const mapStateToProps = ({ user, isShowingError }) => ({ user, isShowingError });

export default connect(mapStateToProps)(ErrorPopup);
