// Libs
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

// Actions
import { toggleModal } from 'actions/modal';
import { addNewForms, getAllForms, changeQueryParams, resetQueryParams, resetFormTemplates } from 'redux/forms/actions';
import { toggleSideBar } from 'actions/sidebar';
import { hideFormBanner } from 'redux/general-settings/actions';
import { getDefaultMetrics } from 'redux/form-details/actions';

// Shared
import UpgradePath from 'shared/UpgradePath';
import Header from './components/Header';
import Banner from './components/Banner';
import Content from './components/Content';
import Toolbar from './components/Toolbar';
import FormsUpgradePath from './components/FormsUpgradePath';
import AddFormBuilder from './components/AddFormBuilder';

// Constants
import { FORM_VIEW_TYPES } from 'constants/commonData';
import { saveLastLibraryRoute } from 'utils/commonFunction';

import * as S from './style';

const Forms = ({
  user,
  list,
  query,
  toggleModal,
  getAllForms,
  addNewForms,
  isHideFormBanner,
  hideFormBanner,
  changeQueryParams,
  resetQueryParams,
  loading,
  push,
  isFirst,
  location,
  toggleSideBar,
  resetFormTemplates,
  getDefaultMetrics,
  bodyMetrics,
}) => {
  const { sort, sorter, fetch_type } = query;
  const [activeHeader, setActiveHeader] = useState(fetch_type);
  const [activeLayout, setActiveLayout] = useState(FORM_VIEW_TYPES.GRID);
  const { search } = location;

  useEffect(() => {
    if (search) {
      handleOpenAddNewForm();
    }
  }, [search]);

  useEffect(() => {
    getAllForms &&
      getAllForms({
        sort,
        sorter,
        fetch_type,
      });
    toggleSideBar(true);
    saveLastLibraryRoute('/home/forms');
    if (isEmpty(bodyMetrics)) {
      getDefaultMetrics(getDefaultMetrics);
    }
    return () => {
      resetQueryParams && resetQueryParams();
      typeof resetFormTemplates === 'function' && resetFormTemplates();
    };
  }, []);

  const handleChangeActiveHeader = header => {
    setActiveHeader(header);
    changeQueryParams &&
      changeQueryParams({
        fetch_type: header,
        page: 1,
      });
  };

  const handleChangeLayout = layout => {
    setActiveLayout(layout);
  };

  const handleOpenAddNewForm = () => {
    toggleModal(
      true,
      <AddFormBuilder
        user={user}
        toggleModal={toggleModal}
        onAddNewForm={handleAddNewForm}
        onAddFormTemplate={handleAddFormTemplate}
      />,
    );
  };

  const handleAddNewForm = (params, callback) => {
    addNewForms(params);
    callback && callback();
  };

  const handleAddFormTemplate = (form, callback) => {
    typeof addNewForms === 'function' && addNewForms(form);
    typeof callback === 'function' && callback();
  };

  return (
    <S.Wrapper isHideFormBanner={isHideFormBanner}>
      <Banner
        onOpenAddNewForm={handleOpenAddNewForm}
        isHideFormBanner={isHideFormBanner}
        onHideFormBanner={hideFormBanner}
        changeQueryParams={changeQueryParams}
      />
      <Toolbar
        isHideFormBanner={isHideFormBanner}
        onOpenAddNewForm={handleOpenAddNewForm}
        changeQueryParams={changeQueryParams}
      />
      <Header
        activeHeader={activeHeader}
        onChangeHeader={handleChangeActiveHeader}
        activeLayout={activeLayout}
        onChangeLayout={handleChangeLayout}
      />
      <S.Container isHideFormBanner={isHideFormBanner}>
        <Content
          list={list}
          push={push}
          loading={loading}
          query={query}
          changeQueryParams={changeQueryParams}
          isFirst={isFirst}
          isHideFormBanner={isHideFormBanner}
        />
      </S.Container>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { forms, generalSettings, formDetails },
    router,
  } = state;
  return {
    list: get(forms, 'list', []),
    isHideFormBanner: get(generalSettings, 'is_hide_form_banner', false),
    user,
    query: get(forms, 'query', []),
    loading: get(forms, 'loading', false),
    isFirst:
      get(forms, 'query.all_form', 0) + get(forms, 'query.your_form', 0) + get(forms, 'query.archived_form', 0) <= 0,
    location: router.location,
    bodyMetrics: formDetails.bodyMetrics || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    addNewForms: bindActionCreators(addNewForms, dispatch),
    hideFormBanner: bindActionCreators(hideFormBanner, dispatch),
    getAllForms: bindActionCreators(getAllForms, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    resetFormTemplates: bindActionCreators(resetFormTemplates, dispatch),
    getDefaultMetrics: bindActionCreators(getDefaultMetrics, dispatch),
  };
};

const ConnectedForms = connect(mapStateToProps, mapDispatchToProps)(Forms);

export default function Wrapper() {
  return (
    <UpgradePath fallback={<FormsUpgradePath />} pathName="form_questionnaire">
      <ConnectedForms />
    </UpgradePath>
  );
}
