import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &.loader,
  &.loader:after {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  &.loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid #7470ef40;
    border-right: 4px solid #7470ef40;
    border-bottom: 4px solid #7470ef40;
    border-left: 4px solid #7470ef;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default ({ width, size, color = '#7470EF' }) => (
  <Container className="loader" width={width} size={size} color={color}>
    {/* <div className="loaderRing__item"></div>
    <div className="loaderRing__item"></div>
    <div className="loaderRing__item"></div>
    <div className="loaderRing__item"></div> */}
  </Container>
);
