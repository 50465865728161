import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import GroupButtonStyles from './styles';

const GroupButton = ({ data = [], className, tabSelected }) => {
  return (
    <GroupButtonStyles className={className}>
      {data.map((item, index) => (
        <div
          className={classnames('tab', { 'tab-selected': item.text === tabSelected })}
          onClick={item.onClick}
          key={item.text}
        >
          {item.text}
        </div>
      ))}
    </GroupButtonStyles>
  );
};

GroupButton.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default GroupButton;
