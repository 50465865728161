import React from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { Workout } from 'types/model';
import { toggleModal } from 'actions/modal';
import { changeCalendarStartDate } from '../../actions/calendar';
import { showSuccess, hideSuccess } from 'redux/modal/modal.actionCreators';
import { onSubmitted, onSubmitting } from 'actions/submitStatus';
import Request from 'configs/request';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { getOnboardingCheckList } from 'redux/onboarding/actions';
import { ONBOARDING_STEPS } from 'constants/commonData';
import { moveWorkout, moveWorkoutSuccess, updateWorkouts, moveExercice } from 'actions/workout';
import { hideWorkout } from 'redux/calendar/calendar.actionCreators';
import { removeMedialDefault } from 'redux/workout_library/workout.actionCreators';

export const arrangeExerciseSet = data => {
  return (dispatch, getState) => {
    dispatch(
      moveExercice({
        from: data.from,
        fromAssignment: data.assignmentId,
        newIndex: data.newIndex,
        sectionId: data.sectionId,
        to: data.from,
        toAssignment: data.assignmentId,
      }),
    );
    return dispatch(
      Request.put(
        { url: `/api/workout/v2/assignment/${data.assignmentId}/arrange`, data },
        false,
        (response, { dispatch }) => {
          dispatch(updateWorkouts([]));
        },
        (error, { dispatch }) => dispatch({ type: 'ERROR_ARRANGE_EXERCISE_SET', error }),
      ),
    );
  };
};

export const moveExerciseSet = data => {
  return (dispatch, getState) => {
    dispatch(moveExercice(data));
    return dispatch(
      Request.put(
        { url: '/api/workout/v2/assignment/move', data },
        false,
        (response, { dispatch }) => {
          const workouts = [response.data.dbFromAssignment, response.data.dbToAssignment];
          dispatch(updateWorkouts(_.compact(workouts)));
        },
        (error, { dispatch }) => dispatch({ type: 'ERROR_MOVE_EXERCISE_SET', error }),
      ),
    );
  };
};

export const arrangeAssignment = data => {
  return Request.put(
    { url: '/api/workout/v2/assignment/arrange_assignment', data },
    false,
    (response, { dispatch }) => dispatch(changeCalendarStartDate()),
    (error, { dispatch }) => dispatch({ type: 'ERROR_ARRANGE_ASSIGNMENTS', error }),
  );
};

export const moveAssignment = data => {
  return (dispatch, getState) => {
    dispatch(moveWorkout(data));
    return dispatch(
      Request.put(
        { url: '/api/workout/v2/assignment/move_assignment', data },
        false,
        response => {
          dispatch(moveWorkoutSuccess());
        },
        error => {
          dispatch({ type: 'ERROR_MOVE_ASSIGNMENTS', error });
          dispatch(
            moveWorkout({
              fromIndex: data.newIndex,
              newIndex: data.fromIndex,
              newDay: data.oldDay,
              oldDay: data.newDay,
            }),
          );
          dispatch(changeCalendarStartDate());
        },
      ),
    );
  };
};

export const recordAssignment = data => {
  return Request.post(
    { url: `/api/workout/v2/assignment/${data.assignment}/track`, data },
    false,
    (response, { dispatch }) => {
      dispatch(toggleModal(false));
      toast.success('Workout log has been saved.');
      dispatch(changeCalendarStartDate());
    },
  );
};

export const cancelAssignment = data => {
  return Request.put(
    { url: `/api/workout/v2/assignment/${data.assignment}/cancel`, data },
    false,
    (response, { dispatch }) => {
      dispatch(toggleModal(false));
      dispatch(showSuccess('Assignment has been cancelled.'));
      setTimeout(() => {
        dispatch(hideSuccess());
        dispatch(changeCalendarStartDate());
      }, 1500);
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_CANCELLING_ASSIGNMENT', error }),
  );
};

export const addAssignment = (workoutData, otherData) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onboarding: { hideFeature, checkList },
        calendar: { is_hide_workout },
      },
    } = getState();

    const newSection = removeMedialDefault(workoutData.sections);

    const data = {
      title: workoutData.title,
      description: workoutData.description,
      share: workoutData.share,
      background: workoutData.background,
      sections_target: newSection,
      workout_settings: {
        hide_workout: is_hide_workout,
      },
      is_generated_by_ai: workoutData.is_generated_by_ai || false,
      conversion_id: workoutData.is_generated_by_ai ? workoutData.conversion_id || null : null,
      is_edited: workoutData.is_generated_by_ai ? workoutData.is_edited || false : undefined,
      ...otherData,
    };

    return dispatch(
      Request.post({ url: '/api/workout/v2/assignment/add', data }, false, response => {
        dispatch(changeCalendarStartDate());
        is_hide_workout === true && dispatch(hideWorkout(false, _, true));

        if (!hideFeature) {
          const unfinished = _.find(checkList, item => item.type === ONBOARDING_STEPS.ASSIGN_WORKOUT && !item.state);
          dispatch(getOnboardingCheckList(unfinished));
        }
      }),
    );
  };
};

export const getComments = (assignmentId, topic = 'assignment_result') => {
  return Request.get({ url: '/api/comment/list', params: { topic: topic, item: assignmentId } }, true);
};

export const addCommentForAssignment = data => Request.post({ url: '/api/comment/add', data }, true);

export const resetCopyItem = () => ({ type: 'CLIENT_TRAINING_CLEAR_COPY_ASSIGMENT' });

export const updateTrackingData = data => {
  return Request.post({ url: `/api/assignment/update-tracked-workout`, data }, true, (response, { dispatch }) => {
    dispatch(toggleModal(false));
    toast.success('Workout log has been saved.');
    dispatch(changeCalendarStartDate());
  });
};
