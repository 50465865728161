import React from 'react';
import get from 'lodash/get';

import { ImageTextPreview } from '../../MainPanel/style';
import S3Images from 'shared/S3Images';
import MediaPlayer from 'shared/MediaPlayer';

const ImageTextResponse = ({ question }) => {
  const attachmentThumbnailUrl = get(question, 'originalQuestion.background_image_thumbnail');
  const attachmentUrl = get(question, 'originalQuestion.background_image');

  return (
    <>
      {(attachmentThumbnailUrl || attachmentUrl) && (
        <ImageTextPreview isResponse>
          <S3Images src={[attachmentUrl || attachmentThumbnailUrl]} />
          <MediaPlayer url={attachmentUrl || attachmentThumbnailUrl} isVideo={false} />
        </ImageTextPreview>
      )}
    </>
  );
};

export default ImageTextResponse;
