import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  padding: 2px 30px 0;
  display: flex;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  flex: 0 0 auto;
`;

export const RightContainer = styled.div`
  flex: 0 0 138px;
`;

export const HeaderTab = styled.div`
  font-style: normal;
  display: inline-block;
  height: 31px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  opacity: 0.6;
  margin-right: 30px;
  cursor: pointer;
  ${props =>
    props.isActive &&
    css`
      color: #5158cf;
      font-weight: 700;
      opacity: 1;
      box-shadow: inset 0px -2px 0px #5158cf;
    `}
`;

export const LayoutButtonsContainer = styled.div`
  background: #f7f8f9;
  border-radius: 3px;
  .layout-button {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.active {
      background: #5e59ff;
      color: white;
      font-weight: 600;
    }
    svg {
      margin-right: 4px;
    }
  }
  .grid {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 71px;
    height: 30px;
  }
  .list {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 67px;
    height: 30px;
  }
`;
