import React from 'react';
import { Radio } from 'semantic-ui-react';

import * as S from '../style';

const SingleChoiceForm = ({ options }) => {
  return (
    <S.FormType>
      {options.map((option, idx) => (
        <S.TypeRadio key={idx}>
          <S.ContentRadioWrapper>
            <Radio checked={false} readOnly className="welcome-form-radio" />
            <S.Content>{option.label}</S.Content>
          </S.ContentRadioWrapper>
        </S.TypeRadio>
      ))}
    </S.FormType>
  );
};

export default SingleChoiceForm;
