import React, { createRef } from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import { List } from './constants';
import MediaPlayer from 'shared/MediaPlayer';
// import assets
import EverfitDemoImg from 'assets/images/everfit-platform-demo.png';
import ClientGuideImg from 'assets/images/client-app-guide.png';
import { ReactComponent as Copy } from 'assets/icons/hp_copy.svg';
import { SupportIcon, ListContainer, Wrapper, Button, OverlapText } from './styles';
import { CDN_URL, EVENT_CLOSE_TASK_REMINDER_POP_UP } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

class Support extends React.Component {
  state = {
    openList: false,
    isCopied: false,
  };

  timer = createRef();

  onClick = () => {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = 'https://www.youtube.com/watch?v=4m0XN5TPUTI';
    dummy.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.handleDisplayClipboard();
        document.body.removeChild(dummy);
        this.handleResetClipboard();
      }
    } catch (err) {
      console.error('Oops, unable to copy');
    }
  };

  onVideoClick = () => {
    this.setState({ openList: false });
  };

  handleOnClick = () => {
    this.setState({ openList: !this.state.openList });
    window.dispatchEvent(EVENT_CLOSE_TASK_REMINDER_POP_UP);
  };

  handleDisplayClipboard = () => {
    this.setState(state => ({
      isCopied: !state.isCopied,
    }));
  };

  handleOnClose = () => {
    this.setState({ openList: false });
    this.setState(() => ({
      isCopied: false,
    }));
    clearTimeout(this.timer);
  };

  handleResetClipboard = () => {
    this.timer = setTimeout(() => {
      this.handleDisplayClipboard();
    }, 5000);
  };

  render() {
    const { openList, isOpen, isCopied } = this.state;

    return (
      <RootCloseWrapper disabled={!openList} onRootClose={this.handleOnClose}>
        <Wrapper className={openList ? 'open' : ''}>
          <SupportIcon onClick={this.handleOnClick} data-tip data-for="help-center-tooltip">
            <img width={20} src={`${CDN_URL}/images/links.svg`} alt="" />
            <img width={20} className="hover" src={`${CDN_URL}/images/links_purple.svg`} alt="" />
          </SupportIcon>
          <SharedTooltip content="Get started" id="help-center-tooltip" position="bottom" />
          <ListContainer className="help__list">
            <div className="list-item">
              {_.map(List, item => {
                const { key, link, icon, text } = item || {};

                if (!link) return;

                return (
                  <div
                    className="item"
                    key={key}
                    onClick={() => {
                      this.setState({ openList: false });
                      window.open(link);
                    }}
                  >
                    <div className="icon">{icon}</div>
                    <div>{text}</div>
                  </div>
                );
              })}
            </div>
            <div className="guide-section">
              <div className="demo-section">
                <div className="title">Everfit Platform Demo</div>
                <div className="video-section" onClick={this.onVideoClick}>
                  <img src={EverfitDemoImg} alt="" />
                  <MediaPlayer
                    url="https://everfit-web-asset.s3-us-west-1.amazonaws.com/videos/Everfit_Platform_Demo.mp4"
                    type="video"
                  />
                </div>
              </div>
              <div className="divide" />
              <div className="client-guide-section">
                <div className="title">Client App Guide</div>
                <div className="description">Share this video with your clients</div>
                <div className="video-section" onClick={this.onVideoClick}>
                  <img src={ClientGuideImg} alt="" />
                  <MediaPlayer url="https://www.youtube.com/watch?v=4m0XN5TPUTI" type="video" />
                </div>
                <Button onClick={this.onClick} disabled={isCopied}>
                  youtu.be/4m0XN5TPUTI
                  <Copy />
                  <OverlapText>{isCopied ? 'Link copied!' : 'Copy to clipboard'}</OverlapText>
                </Button>
              </div>
            </div>
          </ListContainer>
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

export default Support;
