import React from 'react';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CDN_URL } from 'constants/commonData';
import { onDragSectionLeftPanelEnd } from 'redux/workout-builder/actions';

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px dashed #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 26px 0px;
  margin-bottom: 15px;

  .drag-container__content {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
    max-width: 143px;
    margin-left: 7px;
  }

  ${props =>
    props.isDragMode &&
    css`
      border-color: #5158cf;
    `}

  ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

const DragContainer = props => {
  const onDrop = e => {
    e.preventDefault();
    props.onDragSectionLeftPanelEnd({
      sectionIndex: 0,
    });
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDragleave = e => {
    e.preventDefault();
  };

  return (
    <Wrapper
      isDragMode={!!props.draggingItemLeft}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragleave}
      noMargin={props.noMargin}
    >
      <img
        src={
          props.isHideLeftPanel
            ? `${CDN_URL}/images/add_exercise_section_icon.svg`
            : `${CDN_URL}/images/drag_exercise_icon.svg`
        }
        alt="Drag Exercise"
      />
      <div className="drag-container__content">
        {props.isHideLeftPanel ? 'Add Exercises or Sections to workout' : 'Drag exercises from the left to add'}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    draggingItemLeft: state.rootReducer.workoutBuilder.getIn(['draggingItemLeft']),
  };
};

const mapDispatch = dispatch => ({
  onDragSectionLeftPanelEnd: bindActionCreators(onDragSectionLeftPanelEnd, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(DragContainer);
