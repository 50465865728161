import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import * as S from './style.js';
import InviteMemberForm from '../InviteMemberForm';
import Button from 'shared/FormControl/Button';
import BannerImage from '../BannerImage';
import { ReactComponent as MemberIcon } from 'assets/icons/member_icon.svg';
import ForumNavBar from './ForumNavBar';
import {
  convertS3UrlToCloudFrontUrl,
  getFormatImageUrlWithSML,
  getStatusCodeUrl,
  pluralize,
} from 'utils/commonFunction';

export default function ForumHeader(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [urlBanner, setUrlBanner] = useState();

  const onStartUpload = () => {
    setIsUploading(true);
  };

  const onEndUpload = () => {
    setIsUploading(false);
  };

  const onUploadSuccess = ({ src }) => {
    const { updateGroup } = props;
    updateGroup && updateGroup({ banner: src });
    setIsUploading(false);
  };

  const handleTogglePopup = () => {
    props.toggleModal(true, <InviteMemberForm {...props} />);
  };

  const convertImage = async url => {
    if (!_.get(props.group, 'banner')) return url;

    try {
      const replaceUrl = url.replace('/images/', '/thumbnails-converted/');
      getStatusCodeUrl(replaceUrl).then(res => {
        if (res === 200 && typeof url === 'string' && replaceUrl.includes('/thumbnails-converted/')) {
          setUrlBanner(getFormatImageUrlWithSML(replaceUrl));
          return;
        }
        setUrlBanner(url);
      });
    } catch (error) {}
  };

  const memberTotal = props.members.length || props.group.no_of_members || 0;
  const banner = _.get(props.group, 'banner', ['']);

  useEffect(() => {
    const bannerHasOriginal = typeof banner === 'string' && banner.includes('/original/');
    if (_.get(props.group, 'banner') && bannerHasOriginal) {
      convertImage(banner);
    } else {
      setUrlBanner('');
    }
  }, [banner]);

  return (
    <S.ForumHeader>
      <BannerImage
        notAllowImgGif
        key="banner_image"
        onUploadSuccess={onUploadSuccess}
        onStartUpload={onStartUpload}
        onEndUpload={onEndUpload}
        src={convertS3UrlToCloudFrontUrl(urlBanner || banner, props.cloudfrontList, true)}
        uploadKey="media"
      />
      <S.HeaderBody>
        <S.HeaderContent>
          <S.ForumInfoContainer>
            <h1 className="forum-name">{props.group.name}</h1>
            <div className="members-text">
              <MemberIcon />
              <p>{pluralize('member', memberTotal, true)}</p>
            </div>
          </S.ForumInfoContainer>
          <Button className="invite-btn" purple onClick={handleTogglePopup}>
            + Invite
          </Button>
        </S.HeaderContent>

        <ForumNavBar {...props} />
      </S.HeaderBody>
    </S.ForumHeader>
  );
}
