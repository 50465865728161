import React from 'react';
import _ from 'lodash';
import { Container, Input } from './style';

class InstructionInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isFocused: false };
    this.inputRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const currentValue = this.getValue();
    return !_.isEqual(currentValue, nextProps.value) || nextState.isFocused !== this.state.isFocused;
  }

  getValue = () => {
    let result = [];
    const items = this.inputRef.current.getElementsByTagName('li');

    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        const text = items[i].innerText.trim();

        if (text) {
          result.push(text);
        }
      }
    }

    return result;
  };

  onKeyDown = (event) => {
    switch (event.which) {
      case 13:
        // Enter
        if (event.shiftKey) {
          event.preventDefault();
        } else {
          const selection = document.getSelection();
          const anchorNode = selection.anchorNode;
          let workingNode;

          if (anchorNode.tagName === 'LI') {
            workingNode = anchorNode;
          } else {
            workingNode = anchorNode.parentNode;
          }

          if (selection.toString() || !workingNode.innerText.trim()) {
            event.preventDefault();
          }
        }

        break;
      case 8:
      case 46:
        //DELETE
        if (!event.target.innerText.trim().trimAny('↵')) {
          event.preventDefault();
        }

        break;

      default:
        break;
    }
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  onInput = () => {
    const newValue = this.getValue();

    if (!this.inputRef.current.querySelector('li')) {
      document.execCommand('insertorderedlist');
    }

    this.props.onChange(newValue);
  };

  onPaste = (event) => {
    event.preventDefault();
    let text = '';

    if (event.clipboardData && event.clipboardData.getData) {
      text = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      text = window.clipboardData.getData('Text');
    }

    text = text.replace(/(\r\n+|\n+|\r+)/gm, ' ');

    document.execCommand('insertText', false, text);
    event.persist();
  };

  createMarkup = () => {
    const { value } = this.props;
    const { isFocused } = this.state;

    if (!isFocused && !value.length) {
      return { __html: '<label>Add exercise instructions</label>' };
    }

    if (!value.length) {
      return { __html: '' };
    }

    const ol = document.createElement('ol');

    _.forEach(value, (text) => {
      const li = document.createElement('li');
      li.innerText = text;
      ol.appendChild(li);
    });

    return { __html: ol.outerHTML };
  };

  onContainerClick = () => {
    if (!this.state.isFocused) {
      this.inputRef.current.focus();
      this.setState({ isFocused: true }, () => {
        setTimeout(() => {
          if (!this.inputRef.current.querySelector('li')) {
            document.execCommand('insertorderedlist');
          }
        }, 0);
      });
    }
  };

  render() {
    const { placeholder, value } = this.props;
    const { isFocused } = this.state;

    return (
      <Container onClick={this.onContainerClick} isFocused={isFocused} empty={!value.length}>
        <Input
          contentEditable
          onKeyDown={this.onKeyDown}
          ref={this.inputRef}
          placeholder={placeholder}
          dangerouslySetInnerHTML={this.createMarkup()}
          onPaste={this.onPaste}
          onBlur={this.onBlur}
          onInput={this.onInput}
        />
      </Container>
    );
  }
}

export default InstructionInput;
