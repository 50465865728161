import { PROGRAM_SORT_TYPE, PROGRAM_SORTER_TYPE } from 'constants/commonData';

import { Types, TEMPLATES_MODAL_TYPES } from './actions';
import { DEFAULT_FILTERS } from 'components/ProgramLibraryAndTemplates/constants';
import { DEFAULT_FILTERS_POPUP } from 'components/ProgramTemplatesFullscreenModal/constants';
import omit from 'lodash/omit';

export const DEFAULT_QUERY_PARAMS = {
  search: '',
  page: 1,
  per_page: 20,
  sort: PROGRAM_SORT_TYPE.DESCENDING,
  sorter: PROGRAM_SORTER_TYPE.LAST_INTERACTED,
  ...DEFAULT_FILTERS,
};

export const SORT = {
  ASC: 1,
  DESC: -1,
};

export const INITIAL_STATE = {
  list: [],
  total: 0,
  loading: false,
  query: DEFAULT_QUERY_PARAMS,
  query_templates: {
    page: 1,
    per_page: 100,
    text_search: '',
    sort: SORT.ASC,
    sorter: 'title',
    // is_recommended: true,
    // is_most_popular: false,
    resetFilter: false,
    ...DEFAULT_FILTERS_POPUP,
  },
  templates: {
    openFullscreenModal: false,
    openFilterModal: false,
    openDetailModal: false,
    openInstructionModal: false,
    openTagsModal: false,
    openDescriptionModal: false,
  },
  templateProgram: {
    list: [],
    total: 0,
    loading: false,
  },
  programWeekDetail: { downloading: false, loadingWeek: false },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.PROGRAM_LIBRARY_FILTER_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.PROGRAM_LIBRARY_FILTER_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        list: payload.list,
        total: payload.total,
      });
    }
    case Types.PROGRAM_LIBRARY_FILTER_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.PROGRAM_LIBRARY_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, { query: payload });
    }
    case Types.PROGRAM_LIBRARY_RESET_QUERY_PROGRAM: {
      const { filterOnly = false } = payload;

      if (filterOnly) {
        return Object.assign({}, state, {
          query: {
            ...INITIAL_STATE.query,
            search: state.query.search,
          },
        });
      }

      return Object.assign({}, state, { query: INITIAL_STATE.query });
    }
    case Types.PROGRAM_LIBRARY_REMOVE_ITEM_SUCCESS: {
      const newList = state.list.filter(it => it._id !== payload.id);
      const newTotal = Math.max(state.total - 1, 0);

      return Object.assign({}, state, { list: newList, total: newTotal });
    }
    case Types.PROGRAM_LIBRARY_TOGGLE_MODAL_TEMPLATES: {
      const {
        openFullscreenModal,
        openFilterModal,
        openDetailModal,
        openInstructionModal,
        openTagsModal,
        openDescriptionModal,
      } = state.templates;
      const { query_templates } = state;
      const newState = {};

      switch (payload) {
        case TEMPLATES_MODAL_TYPES.FULLSCREEN: {
          newState.openFullscreenModal = !openFullscreenModal;
          if (newState.openFullscreenModal === false) {
            newState.openFilterModal = false;
            newState.openDetailModal = false;
            newState.openInstructionModal = false;
            newState.query_templates = INITIAL_STATE.query_templates;
          }
          break;
        }
        case TEMPLATES_MODAL_TYPES.FILTER: {
          newState.openFilterModal = !openFilterModal;
          newState.query_templates = {
            ...INITIAL_STATE.query_templates,
            ...query_templates,
          };
          break;
        }
        case TEMPLATES_MODAL_TYPES.DETAIL: {
          newState.openDetailModal = !openDetailModal;
          newState.query_templates = {
            ...INITIAL_STATE.query_templates,
            ...query_templates,
          };
          break;
        }
        case TEMPLATES_MODAL_TYPES.INSTRUCTION: {
          newState.openInstructionModal = !openInstructionModal;
          break;
        }
        case TEMPLATES_MODAL_TYPES.TAGS: {
          newState.openTagsModal = !openTagsModal;
          break;
        }
        case TEMPLATES_MODAL_TYPES.DESCRIPTION: {
          newState.openDescriptionModal = !openDescriptionModal;
          break;
        }
        default:
          break;
      }

      return Object.assign({}, state, {
        templates: {
          ...state.templates,
          ...newState,
        },
        query_templates: {
          ...newState.query_templates,
        },
      });
    }
    case Types.PROGRAM_TEMPLATE_REQUEST: {
      return Object.assign({}, state, {
        templateProgram: {
          ...state.templateProgram,
          loading: true,
        },
      });
    }
    case Types.PROGRAM_TEMPLATE_SUCCESS: {
      return Object.assign({}, state, {
        query_templates: {
          ...state.query_templates,
          resetFilter: false,
        },
        templateProgram: {
          ...state.templateProgram,
          list: payload.data,
          total: payload.total,
          loading: false,
        },
      });
    }
    case Types.PROGRAM_TEMPLATE_FAILED: {
      return Object.assign({}, state, {
        query_templates: { ...INITIAL_STATE.query_templates },
        templateProgram: {
          ...state.templateProgram,
          loading: false,
        },
      });
    }

    case Types.PROGRAM_TEMPLATE_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query_templates: { ...state.query_templates, ...payload } });

    case Types.PROGRAM_TEMPLATE_SAVE_REQUEST:
      return Object.assign({}, state, { programWeekDetail: { ...state.programWeekDetail, downloading: true } });

    case Types.PROGRAM_TEMPLATE_SAVE_SUCCESS:
    case Types.PROGRAM_TEMPLATE_SAVE_FAILED:
      return Object.assign({}, state, {
        programWeekDetail: { ...state.programWeekDetail, downloading: false },
        templateProgram: {
          ...state.templateProgram,
          list: payload.templateProgramList,
        },
      });

    case Types.PROGRAM_TEMPLATE_WEEK_DETAIL_REQUEST:
      return Object.assign({}, state, { programWeekDetail: { loadingWeek: true } });

    case Types.PROGRAM_TEMPLATE_WEEK_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        programWeekDetail: { ...payload.data, loadingWeek: false },
      });

    case Types.PROGRAM_TEMPLATE_WEEK_DETAIL_FAILED:
      return Object.assign({}, state, { loadingWeek: false });

    case Types.PROGRAM_TEMPLATE_RESET_QUERY_PARAMS: {
      const updatedQueryTemplates = omit(INITIAL_STATE.query_templates, 'text_search');
      return Object.assign({}, state, {
        query_templates: { ...state.query_templates, ...updatedQueryTemplates, resetFilter: true },
      });
    }
    case Types.UPDATE_TAG_IN_PROGRAMS: {
      const { id, name } = payload;
      const newList = state.list.map(item => ({
        ...item,
        tags: (item.tags || []).map(tag => (tag._id === id ? { ...tag, name } : tag)),
      }));

      return Object.assign({}, state, {
        list: newList,
      });
    }
    case Types.DELETE_TAG_IN_PROGRAMS: {
      const newList = state.list.map(item => ({
        ...item,
        tags: (item.tags || []).filter(tag => tag._id !== payload.id),
      }));

      return Object.assign({}, state, {
        list: newList,
      });
    }
    case Types.PROGRAM_LIBRARY_CLOSE_MODAL_TEMPLATES: {
      return Object.assign({}, state, {
        templates: INITIAL_STATE.templates,
        query_templates: INITIAL_STATE.query_templates,
        templateProgram: INITIAL_STATE.templateProgram,
        programWeekDetail: INITIAL_STATE.programWeekDetail,
      });
    }

    default:
      return state;
  }
};
