import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Tooltip = styled.div`
  color: #fff;
  border-radius: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  padding: 7px 0 9px;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  width: max-content;
  white-space: nowrap;
  position: absolute;
  background: #231f20;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 7px;
    height: 7px;
    top: calc(100% - 5px);
    background: #231f20;
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const PlayPause = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 15px;
  background: url(${CDN_URL}/images/pause_video.svg) no-repeat;
  background-size: auto;
  background-position: center;
  cursor: pointer;
  opacity: 0.6;
  position: relative;

  ${Tooltip} {
    width: 82px;
    left: -29px;
    bottom: calc(100% + 8px);
  }

  :hover {
    opacity: 1;

    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const VolumnBar = styled.div`
  position: absolute;
  left: -38px;
  bottom: 70px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: auto;
  width: auto;
  padding: 9px 8px;
  opacity: 0;
  visibility: hidden;
  transform: rotate(-90deg);

  input[type='range'] {
    height: 4px;
    width: 83px;
    -webkit-appearance: none;
    background-color: rgba(255, 255, 255, 0.4);
    outline: none;
    cursor: pointer;
    display: block;

    ::-webkit-slider-thumb {
      width: 10px;
      height: 10px;
      -webkit-appearance: none;
      background-color: #fff;
      border-radius: 50%;
    }

    ::-moz-range-track {
      height: 10px;
    }

    ::-moz-range-thumb {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      background: #fff;
      border: none !important;
      border-radius: 50% !important;
    }
  }
`;

export const Mute = styled.div`
  width: 20px;
  height: 26px;
  border-bottom: 2px solid transparent;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center top;
  opacity: 0.6;
`;

export const Volumn = styled.div`
  margin-left: 18px;
  position: relative;
  padding-top: 6px;

  :hover {
    ${Mute} {
      opacity: 1;
      cursor: pointer;
      border-bottom-color: #fff;
    }

    ${VolumnBar} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const ClickToPlaySound = styled.div`
  right: 408px;
  bottom: 378px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 999px;
  padding: 7px 15px 7px 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  color: #fff;

  img {
    margin-right: 5px;
  }

  :hover {
    background-color: #000;
  }
`;

export const SeekBar = styled.input.attrs({
  type: 'range',
})`
  flex: 1 1;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-appearance: none;
  height: 7px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 1px;
  margin-top: 1px;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0;
    height: 15px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw #fff;
  }

  ::-moz-range-track {
    height: 15px;
  }

  ::-moz-range-thumb {
    -webkit-appearance: none;
    width: 0;
    height: 15px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw #fff;
    border: none !important;
    border-radius: 0 !important;
  }
`;

export const Controls = styled.div`
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: visible;
  padding: 10px 10px 8px;
  position: relative;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  overflow: visible;
  visibility: hidden;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 0.3s;
  transition-delay: 0.2s;

  ::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.3;
    z-index: 1;
    height: 152px;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  :hover {
    ${ControlsContainer} {
      &.show {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
    }
  }
`;
