// Libs
import React from 'react';
import get from 'lodash/get';

// Shared
import MediaPlayer from 'shared/MediaPlayer';

// Styles
import * as S from './style';

const StepInstructionDetail = props => {
  const { PrepareInstructions, CookingInstructions, tab } = props;

  return (
    <S.StepInstructionDetailWrapper>
      {(get(tab, 'key', '') === 'preparation' ? PrepareInstructions : CookingInstructions).map((item, index) => {
        const { content, attachments, _id } = item || {};
        const {
          attachment_origin: image = '',
          attachment_thumbnail: thumbnail = '',
          attachment_type: attachmentType = '',
        } = get(attachments, '[0]', {});

        const idx = index + 1;
        return (
          <S.Item key={_id}>
            <S.Content>
              <span>{idx && `${idx}. `}</span>
              {content}
            </S.Content>
            {image && (
              <S.ImageWrapper>
                <S.Image src={[image]} />
                <MediaPlayer url={image} type={attachmentType} thumbnail={thumbnail} />
              </S.ImageWrapper>
            )}
          </S.Item>
        );
      })}
    </S.StepInstructionDetailWrapper>
  );
};

export default StepInstructionDetail;
