import React, { Component } from 'react';
import { Button, Loader, Image } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import { getAssignmentDetail, WORKOUT_TYPE } from 'actions/workout/getWorkouts';
import Calendar from 'components/Calendar';
import DateRangePicker from 'shared/DateRangePicker';
import { CALENDAR_TYPES, CDN_URL, TAGS_TYPE } from 'constants/commonData';
import SelectCalendarType from 'shared/SelectCalendarType';
import { Button as ButtonCustom } from 'shared/FormControl';
import AssignedProgram from './AssignedProgram';
import UpgradePath from 'shared/UpgradePath';
import SaveExactDateCalendarAsProgram from 'shared/SaveExactDateCalendarAsProgram';
import { SAVE_AS_PROGRAM_CALENDAR_TYPE } from 'constants/commonData';
import Loading from 'components/ListCalendar/Loading';
import AlterWeekSelected from './components/AlterWeekSelected';
import AlterWorkoutSelected from './components/AlterWorkoutSelected';

import './styles.scss';
import * as S from './styles';

export default class ListCalendar extends Component {
  state = {
    isDragging: false,
  };

  componentDidMount() {
    const {
      match,
      toggleSideBar,
      detailRoute,
      historyRoute,
      trackingRoute,
      activityRoute,
      dispatch,
      location,
      getMostRecentTagsList,
      view_mode,
    } = this.props;

    toggleSideBar(false);
    const openActivityLog = _.get(location, 'pathname', '').includes('activity');
    const focusDateLocation = _.get(location, 'state.focusDate', '');

    !focusDateLocation && this.props.changeCalendarModeView(openActivityLog ? 'history' : 'assignment', view_mode);
    const { assignmentId, activityId } = match.params;
    getMostRecentTagsList({ type: TAGS_TYPE.EXERCISE, page: 1, perPage: 20, sort: -1 });

    if (assignmentId && !focusDateLocation) {
      if (detailRoute) {
        dispatch(getAssignmentDetail(assignmentId, 'update', WORKOUT_TYPE.DETAIL));
      } else if (historyRoute) {
        dispatch(getAssignmentDetail(assignmentId, 'update', WORKOUT_TYPE.HISTORY));
      } else if (trackingRoute) {
        dispatch(getAssignmentDetail(assignmentId, 'update', WORKOUT_TYPE.LOGWORKOUT));
      }
    }
    if (activityId && activityRoute && !focusDateLocation) {
      const { activityId } = match.params;
      dispatch(getAssignmentDetail(activityId, 'update', WORKOUT_TYPE.LOGACTIVITY));
    }
    document.addEventListener('keydown', this.onPressEscapeKey);
  }

  componentDidUpdate(prevProps) {
    const { match, historyRoute, activityRoute, dispatch, location, view_mode } = this.props;
    const { assignmentId } = match.params;

    if (assignmentId && historyRoute && !prevProps.historyRoute) {
      this.props.dispatch(getAssignmentDetail(assignmentId, 'update', WORKOUT_TYPE.HISTORY));
    } else {
      const {
        params: { clientId },
      } = match;

      if (clientId !== prevProps.match.params.clientId) {
        const today = DateTime.local().startOf('week');
        const newEndDate = today.plus({ days: 7 * 2 });

        this.props.changeCalendarModeView(
          'assignment',
          view_mode,
          {
            client: clientId,
            start_date: today.toFormat('MM-dd-yyyy'),
            end_date: newEndDate.toFormat('MM-dd-yyyy'),
          },
          today,
        );
      }
    }

    if (_.get(prevProps.location, 'pathname', '') !== location.pathname) {
      if (activityRoute && location.pathname.includes('activity')) {
        const activityId = _.get(match, 'params.activityId', '');
        dispatch(getAssignmentDetail(activityId, 'update', WORKOUT_TYPE.LOGACTIVITY));
      }
    }

    this.onHasFocusDate();
  }

  onHasFocusDate = () => {
    const { location, history, changeCalendarStartDate, selectedClient, calendarType } = this.props;
    const focusDateLocation = _.get(location, 'state.focusDate', '');

    if (focusDateLocation) {
      const newStartDate = DateTime.fromFormat(focusDateLocation, 'MM-dd-yyyy').startOf('week');
      const newEndDate = newStartDate.plus({ days: 7 * calendarType });
      changeCalendarStartDate(
        {
          client: selectedClient,
          start_date: newStartDate.toFormat('MM-dd-yyyy'),
          end_date: newEndDate.toFormat('MM-dd-yyyy'),
        },
        newStartDate,
      );

      history.replace({ ...location, state: null });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onPressEscapeKey);
    this.props.handleResetSelectWorkout && this.props.handleResetSelectWorkout();
    this.props.handleResetSelectedWeek && this.props.handleResetSelectedWeek();
  }

  onPressEscapeKey = event => {
    if (event.keyCode === 27 && !document.querySelector('.ui.modals') && this.props.copyingAssignment) {
      this.props.resetCopyItem();
    }
    if (event.keyCode === 27 && !document.querySelector('.ui.modal') && !_.isEmpty(this.props.selectedWorkout)) {
      this.props.handleResetSelectWorkout && this.props.handleResetSelectWorkout();
    }
    if (event.keyCode === 27 && !document.querySelector('.ui.modal') && !_.isEmpty(this.props.selectedWeek)) {
      this.props.handleResetSelectedWeek && this.props.handleResetSelectedWeek();
    }
  };

  onBeforeCapture = event => {
    const [type] = event.draggableId.split('_');
    if (type === 'assignment') {
      this.setState({ isDragging: true });
    }
  };

  render() {
    const { selectedClient, loading } = this.props;

    return (
      <DragDropContext
        onDragEnd={r => {
          this.setState({ isDragging: false });
          this.props.onDragEnd(r, selectedClient);
        }}
        onBeforeCapture={this.onBeforeCapture}
      >
        <div className="calendar-wrapper training-calendar">
          {loading && <Loading />}
          <div className="calendar-container">
            {this.renderNavigationBar()}
            {this.renderCalendar()}
            <AlterWeekSelected {...this.props} />
            <AlterWorkoutSelected {...this.props} />
          </div>
        </div>
      </DragDropContext>
    );
  }

  renderCalendar() {
    const { selectedClient } = this.props;

    if (!selectedClient) {
      return <Loader />;
    }

    return (
      <div className="calendar-main-content">
        <Calendar isDragging={this.state.isDragging} />
      </div>
    );
  }

  renderToday() {
    const { handleTodayClick, selectedClient, calendarType } = this.props;
    return (
      <Button className="today-button" onClick={() => handleTodayClick(selectedClient, calendarType)}>
        TODAY
      </Button>
    );
  }

  renderCalendarViewMode() {
    const { calendarMode, view_mode } = this.props;
    const types = [
      { type: 'assignment', description: 'Assignment' },
      { type: 'history', description: 'History' },
    ];

    const buttons = [];

    for (let i = 0; i < types.length; i++) {
      const c = types[i];
      let style = 'ui positive button month';
      if (c.type !== calendarMode) {
        style += ' disable';
      }
      buttons.push(
        <ButtonCustom
          purple={calendarMode === c.type}
          className={`button-custom ${calendarMode === c.type && 'active'}`}
          onClick={() => this.props.changeCalendarModeView(c.type, view_mode)}
        >
          {c.description}
        </ButtonCustom>,
      );
    }

    return <div className="calendar-mode">{buttons}</div>;
  }

  onSelectCalendarType = value => {
    const { calendarStartDate, selectedClient } = this.props;
    this.props.handleCalendarTypeChange(calendarStartDate, selectedClient, value);
  };

  onSaveAsProgram = data => {
    const { selectedClient, user } = this.props;
    const { startDate, endDate, title, share } = data;

    const bodyData = {
      calendar: SAVE_AS_PROGRAM_CALENDAR_TYPE.CLIENT,
      item_id: selectedClient,
      start_day: startDate.format('MM-DD-YYYY'),
      end_day: endDate.format('MM-DD-YYYY'),
      author: user._id,
      title,
      share,
    };

    return this.props.saveCalendarAsProgram(bodyData);
  };

  renderNavigationBar() {
    const {
      calendarStartDate,
      selectedClient,
      calendarType,
      changeCalendarStartDate,
      isTrainingFeaturing,
      clientName,
    } = this.props;
    return (
      <S.NavBarContainer className="calendar-nav">
        <Helmet>
          <title>{clientName} - Training - Everfit</title>
        </Helmet>
        <div className="calendar-nav__left">
          {this.renderToday()}
          <DateRangePicker
            startDate={calendarStartDate}
            weeks={calendarType}
            onChange={(newStartDate, newEndDate) => {
              changeCalendarStartDate(
                {
                  client: selectedClient,
                  start_date: newStartDate.toFormat('MM-dd-yyyy'),
                  end_date: newEndDate.toFormat('MM-dd-yyyy'),
                },
                newStartDate,
              );
            }}
          />
          {this.renderCalendarViewMode()}
        </div>
        <S.NavBarCenter isTurnOffSetting={!isTrainingFeaturing}>
          <div className="navbarCenter__content">
            <UpgradePath pathName="program" fallback={<></>}>
              <AssignedProgram user={this.props.user} />
            </UpgradePath>
            {!isTrainingFeaturing && (
              <div className="feature_turned_off">
                <Image src={`${CDN_URL}/images/feature_turned_off.svg`} />
                <span>Training has been turned off for this client</span>
              </div>
            )}
          </div>
        </S.NavBarCenter>
        <S.NavBarRightContent>
          <SaveExactDateCalendarAsProgram
            defaultTitle=""
            onSave={this.onSaveAsProgram}
            fetchWorkoutsInRange={this.props.fetchWorkoutsInRange}
          />
          <SelectCalendarType selected={calendarType} options={CALENDAR_TYPES} onSelect={this.onSelectCalendarType} />
        </S.NavBarRightContent>
      </S.NavBarContainer>
    );
  }
}
