import React from 'react';

import * as S from './style';
import DistributionChart from './DistributionChart';
import DistributionTable from './DistributionTable';

const MacroDistribution = props => {
  return (
    <S.Wrapper>
      <S.Heading>Macro Distribution</S.Heading>
      <DistributionChart {...props} />
      <DistributionTable {...props} />
    </S.Wrapper>
  );
};

export default MacroDistribution;
