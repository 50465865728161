import styled, { css } from 'styled-components';

export const Trigger = styled.div`
  display: inline-flex;
  box-shadow: inset 0px -1px 0px #cdcdcd;
  padding: 6px 10px;
  font-weight: 600;
  font-size: 16px;
  color: #7570ee;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  min-width: 70px;

  .text {
    margin-right: 10px;
    min-height: 22px;
    line-height: 22px;
    display: inline;
  }

  :hover {
    box-shadow: inset 0px -1px 0px #000000;
  }
`;

export const Option = styled.div.attrs({
  role: 'option',
})`
  padding: 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  cursor: pointer;
  border-radius: 3px;
  white-space: nowrap;

  :hover {
    background: #7570ee;
    color: #ffffff;
  }
`;

export const Menu = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  overflow: hidden;
  padding: 6px 5px;
  display: none;
  z-index: 1;
  ${props =>
    props.isMobile &&
    css`
      top: 6px;
    `}
`;

export const Wrapper = styled.div.attrs({
  role: 'listbox',
})`
  position: relative;
  display: inline-block;
  margin-left: 5px;
  outline: none;

  &.open {
    ${Trigger} {
      box-shadow: inset 0px -1px 0px #000000;
    }

    ${Menu} {
      display: block;
    }
  }
`;

export const DropdownWrapperContent = styled.div`
  ${props =>
    props.isMobile &&
    css`
      min-height: 620px;
      position: absolute;
      left: 0;
      padding-bottom: 10px;
    `}
`;
