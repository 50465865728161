import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Mixpanel } from 'utils/mixplanel';
import { MultiplePasteMessage } from 'shared/Notifications';
import Request from 'configs/request';
import { toggleModal } from 'actions/modal';
import Announcement from 'components/AutoflowInterval/AutoMessage/Announcement';
import { IN_APP_AUTO_MESSAGE } from 'constants/commonData';

export const Types = {
  AUTOFLOW_INTERVAL_SUCCESS_GET_AUTO_MESSAGES: 'AUTOFLOW_INTERVAL_SUCCESS_GET_AUTO_MESSAGES',
  AUTOFLOW_INTERVAL_COPY_AUTO_MESSAGE: 'AUTOFLOW_INTERVAL_COPY_AUTO_MESSAGE',
  AUTOFLOW_INTERVAL_AUTO_MESSAGE_CLEAR_COPY_ITEM: 'AUTOFLOW_INTERVAL_AUTO_MESSAGE_CLEAR_COPY_ITEM',
  AUTOFLOW_INTERVAL_AUTO_MESSAGE_RESET_DATA: 'AUTOFLOW_INTERVAL_AUTO_MESSAGE_RESET_DATA',
  AUTOFLOW_INTERVAL_MOVE_IN_APP_MESSAGE_TO_THE_PAST: 'AUTOFLOW_INTERVAL_MOVE_IN_APP_MESSAGE_TO_THE_PAST',
  AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_SINGLE_DAY: 'AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_SINGLE_DAY',
  AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_MULTIPLE_DAYS: 'AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_MULTIPLE_DAYS',
  AUTOFLOW_INTERVAL_UPDATE_MESSAGE_DETAIL: 'AUTOFLOW_INTERVAL_UPDATE_MESSAGE_DETAIL',
  AUTOFLOW_INTERVAL_SUCCESS_UPDATE_MESSAGE_DETAIL: 'AUTOFLOW_INTERVAL_SUCCESS_UPDATE_MESSAGE_DETAIL',
  AUTOFLOW_INTERVAL_ARRANGE_MESSAGE_ON_LOCAL: 'AUTOFLOW_INTERVAL_ARRANGE_MESSAGE_ON_LOCAL',
  AUTOFLOW_INTERVAL_MOVE_MESSAGE_ON_LOCAL: 'AUTOFLOW_INTERVAL_MOVE_MESSAGE_ON_LOCAL',
};

const sortDayMessages = messages => {
  let sortedMessages = messages.slice();

  if (sortedMessages.length) {
    const inAppMessages = _.remove(sortedMessages, m => m.type === IN_APP_AUTO_MESSAGE);

    if (inAppMessages.length) {
      inAppMessages.sort((m1, m2) => {
        if (m1.time > m2.time) {
          return 1;
        }

        if (m1.time < m2.time) {
          return -1;
        }

        if (m1.time === m2.time) {
          if (moment(m1.createdAt).isAfter(m2.createdAt)) {
            return 1;
          }
        }

        return 0;
      });
    }

    sortedMessages = [...sortedMessages, ...inAppMessages];
  }

  return sortedMessages;
};

const parseMessageData = weekDataList => {
  const data = {};

  _.forEach(weekDataList, weekData => {
    const { weekIndex, days_message } = weekData;

    _.forEach(days_message, dayData => {
      const {
        day_index,
        day_message: { messages },
      } = dayData;

      data[`${weekIndex}_${day_index}`] = sortDayMessages(messages);
    });
  });

  return data;
};

export const resetIntervalMessageData = () => ({ type: Types.AUTOFLOW_INTERVAL_AUTO_MESSAGE_RESET_DATA });

export const getListIntervalMessages = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        autoflowInterval: { common },
        autoflow: {
          common: { workingAutoflow },
        },
      },
    } = getState();

    const startWeek = common.get('startWeek');

    const params = {
      autoflowId: workingAutoflow._id,
      startWeek,
      endWeek: startWeek + common.get('viewMode') - 1,
    };

    return dispatch(
      Request.get({ url: '/api/autoflow-interval-message/get-calendar-by-weeks', params }, true, response => {
        const data = parseMessageData(response.data.data);
        dispatch({ type: Types.AUTOFLOW_INTERVAL_SUCCESS_GET_AUTO_MESSAGES, payload: { data } });
      }),
    );
  };
};

const updateMessageListOfSingleDay = (data, bodyData) => {
  const dayMessages = { [`${bodyData.weekIndex}_${bodyData.dayIndex}`]: sortDayMessages(data.messages) };
  return {
    type: Types.AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_SINGLE_DAY,
    payload: { data: dayMessages },
  };
};

const updateMessageListOfMultipleDays = weekDaysData => {
  const dayMessages = {};
  _.forEach(weekDaysData, weekDayData => {
    const { day_index, weekIndex } = weekDayData;
    dayMessages[`${weekIndex}_${day_index}`] = sortDayMessages(weekDayData.messages);
  });

  return {
    type: Types.AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_MULTIPLE_DAYS,
    payload: { data: dayMessages },
  };
};

export const addIntervalMessage = (bodyData, extendData = {}) => {
  Mixpanel.track('autoflow_interval_auto_message_screen_add_interval_message');
  return dispatch => {
    const { action, multiPaste } = extendData;

    if (action === 'paste' && !multiPaste) {
      dispatch(resetIntervalMessageCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/autoflow-interval-message/add-new-message', data: bodyData }, true, response => {
        const { data } = response.data;
        dispatch(updateMessageListOfSingleDay(data, bodyData));
      }),
    );
  };
};

export const updateIntervalMessage = bodyData => {
  Mixpanel.track('autoflow_interval_auto_message_screen_update_interval_message');
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_INTERVAL_UPDATE_MESSAGE_DETAIL });
    return dispatch(
      Request.put(
        { url: '/api/autoflow-interval-message/update-message', data: bodyData },
        true,
        (response, { dispatch }) => {
          const responseData = _.get(response, 'data.data', []);
          const sortData = _.reduce(
            _.cloneDeep(responseData),
            (result, value) => {
              const weekIndex = _.get(value, 'weekIndex', null);
              const day_index = _.get(value, 'day_index', null);
              if (!_.isNil(weekIndex) && !_.isNil(day_index)) {
                result[`${weekIndex}_${day_index}`] = sortDayMessages(_.get(value, 'messages', []));
              }
              return result;
            },
            {},
          );
          const payload = { data: sortData };
          dispatch({ type: Types.AUTOFLOW_INTERVAL_SUCCESS_UPDATE_MESSAGE_DETAIL, payload: payload });
        },
      ),
    );
  };
};

export const deleteIntervalMessage = bodyData => {
  Mixpanel.track('autoflow_interval_auto_message_screen_delete_interval_message');
  return Request.post(
    { url: '/api/autoflow-interval-message/remove-message', data: bodyData },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateMessageListOfSingleDay(data, bodyData));
    },
  );
};

export const viewIntervalMessageDetail = (params, otherData) => {
  return Request.get(
    { url: '/api/autoflow-interval-message/get-message-detail', params },
    false,
    (response, { dispatch, getState }) => {
      const { data } = response.data;
      const {
        rootReducer: {
          autoflow: {
            common: {
              workingAutoflow: { interval_weeks },
            },
          },
        },
      } = getState();
      const { weekIndex, dayIndex } = otherData;

      dispatch(
        toggleModal(
          true,
          <Announcement
            dayIndex={dayIndex}
            weekIndex={weekIndex}
            originData={data}
            totalWeek={interval_weeks.length}
          />,
        ),
      );
    },
  );
};

export const resetIntervalMessageCopyItem = () => ({ type: Types.AUTOFLOW_INTERVAL_AUTO_MESSAGE_CLEAR_COPY_ITEM });

export const copyIntervalMessage = data => {
  Mixpanel.track('autoflow_interval_auto_message_screen_copy_interval_message');
  toast(<MultiplePasteMessage title="messages" />);

  return {
    type: Types.AUTOFLOW_INTERVAL_COPY_AUTO_MESSAGE,
    payload: { data },
  };
};

export const pasteIntervalMessage = (bodyData, multiPaste) => {
  Mixpanel.track('autoflow_interval_auto_message_screen_paste_interval_message');
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetIntervalMessageCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/autoflow-interval-message/copy-message', data: bodyData }, true, response => {
        const { data } = response.data;
        dispatch(updateMessageListOfSingleDay(data, bodyData));
      }),
    );
  };
};

const moveIntervalMessageOnLocal = data => ({
  type: Types.AUTOFLOW_INTERVAL_MOVE_MESSAGE_ON_LOCAL,
  payload: { data },
});

export const moveIntervalMessage = bodyData => {
  Mixpanel.track('autoflow_interval_auto_message_screen_move_interval_message');
  return dispatch => {
    dispatch(moveIntervalMessageOnLocal(bodyData));
    return dispatch(
      Request.post(
        { url: `/api/autoflow-interval-message/move-message`, data: _.omit(bodyData, ['oldIndex']) },
        true,
        (response, { dispatch }) => {
          dispatch(updateMessageListOfMultipleDays(response.data.data));
        },
        () => {
          const data = {
            ...bodyData,
            newIndex: bodyData.oldIndex,
            oldIndex: bodyData.newIndex,
            oldWeekIndex: bodyData.newWeekIndex,
            newWeekIndex: bodyData.oldWeekIndex,
            oldDayIndex: bodyData.newDayIndex,
            newDayIndex: bodyData.oldDayIndex,
          };
          dispatch(moveIntervalMessageOnLocal(data));
        },
      ),
    );
  };
};

const arrangeIntervalMessageOnLocal = data => ({
  type: Types.AUTOFLOW_INTERVAL_ARRANGE_MESSAGE_ON_LOCAL,
  payload: { data },
});

export const arrangeIntervalMessage = bodyData => {
  return dispatch => {
    dispatch(arrangeIntervalMessageOnLocal(bodyData));

    return dispatch(
      Request.post(
        { url: `/api/autoflow-interval-message/arrange-message`, data: _.omit(bodyData, ['oldIndex']) },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch(updateMessageListOfSingleDay(data, bodyData));
        },
        () => {
          const data = { ...bodyData, newIndex: bodyData.oldIndex, oldIndex: bodyData.newIndex };
          dispatch(arrangeIntervalMessageOnLocal(data));
        },
      ),
    );
  };
};

export const uploadAttachment = data => Request.post({ url: '/api/autoflow/message/upload_attachment', data }, false);

export const getIntervalMessageDetail = params =>
  Request.get({ url: '/api/autoflow-interval-message/get-message-detail', params }, true);

export const pauseIntervalInAppMessage = params =>
  Request.post(
    { url: '/api/autoflow-interval-message/pause-message', data: params },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateMessageListOfSingleDay(data, params));
    },
  );

export const resumeIntervalInAppMessage = params =>
  Request.post(
    { url: '/api/autoflow-interval-message/resume-message', data: params },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateMessageListOfSingleDay(data, params));
    },
  );

export const getIntervalMessageSent = params =>
  Request.post({ url: '/api/autoflow-interval-message/get-sent-message', data: params }, true);
