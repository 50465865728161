import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getSetDescription } from 'helpers/exercise';

import * as S from './style';

// TODO
// SHOULD handle section attachment icon
// Limit length of title
const ExerciseItem = props => {
  const { unit, fields, set, setIndex } = props;
  const isSuperSets = !!set.supersets && set.supersets.length > 1; // TODO

  return (
    <S.Supersets key={set._id || setIndex} className={classNames('supersets-wrapper', { 'is-supersets': isSuperSets })}>
      {isSuperSets && <div className="superset-label">SUPERSET</div>}
      <S.Exercises className="exericses">
        {set.supersets &&
          _.map(set.supersets, item => {
            const exercise = item.exercise || item.exercise_instance;

            if (!exercise) {
              return null;
            }

            const { title } = exercise;
            const { training_sets } = item;
            const summary = getSetDescription(item, unit, fields);

            return (
              <S.Exercise className="exercise" key={item._id}>
                <S.ExerciseBaseInfo>
                  <S.ExerciseTitle>{title}</S.ExerciseTitle>
                  <S.NumberOfSets>x{training_sets.length}</S.NumberOfSets>
                </S.ExerciseBaseInfo>
                <S.ExerciseSummary>{summary}</S.ExerciseSummary>
              </S.Exercise>
            );
          })}
        {!set.supersets && (
          <S.Exercise className="exercise">
            <S.ExerciseBaseInfo>
              <S.ExerciseTitle>{set.title}</S.ExerciseTitle>
            </S.ExerciseBaseInfo>
          </S.Exercise>
        )}
      </S.Exercises>
    </S.Supersets>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: {
      exercise: { fields },
      client: { workingClientDetail },
    },
  } = state;

  return {
    fields,
    preferences: _.get(workingClientDetail, 'feature_preferences'),
    unit: _.get(workingClientDetail || user, 'preferences', []),
  };
};

export default connect(mapState, null)(ExerciseItem);
