import styled, { css } from 'styled-components';
import { ReactComponent as CheckedIcon } from 'assets/icons/check_circle.svg';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

const baseText = `
  font-weight: 600;
  font-size: 13px;
  line-height: 15.6px;
  color: #FFFFFF;
`;

export const ComparePhotoWrapper = styled.div`
  padding: 4px 0 24px;
  height: calc(100% - 80px);
`;

export const ComparePhoto = styled.div``;

export const ItemPhotoWrapper = styled.div`
  cursor: pointer;
  :hover {
    .compare-mode-remove {
      display: flex;
    }
    .compare-mode-zoom {
      display: flex;
    }
    .compare-mode-tag {
      display: block;
    }
    .compare-mode-edit {
      display: block;
    }
  }
  ${props =>
    props.choiceImg &&
    css`
      cursor: default;
      width: 302px;
      height: 614px;
      padding: 0 48px;
      text-align: center;
      border: 1px solid #dadfea;
      background: #fafbfc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-height: 762px) {
        height: calc(100vh - 156px);
      }
    `}
`;

export const DeleteButton = styled.button`
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  top: 16px;
  right: 16px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  display: none;

  svg {
    width: 16px;
    height: 16px;
  }

  :hover {
    background: #fff;
    cursor: pointer;
    svg g {
      stroke: #5e59ff;
    }
  }
`;

export const TagWrapper = styled.div`
  width: 100px;
  height: 27px;
  position: absolute;
  top: 16px;
  left: 16px;
  display: none;
`;

export const PhotoInfo = styled.div`
  position: relative;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  :hover {
    ${DeleteButton} {
      display: flex;
    }
    ${TagWrapper} {
      pointer-events: none;
      display: block;
      > div {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        div {
          color: #fff;
        }
        div:last-child {
          display: none;
        }
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 132px;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

export const PostedDate = styled.span`
  ${baseText}
  position: absolute;
  bottom: 16px;
  left: 16px;
  letter-spacing: -0.2px;
`;

export const EmptyCompareImg = styled.span`
  ${baseText}
  font-weight: 400;
  line-height: 19.5px;
  opacity: 0.6;
  color: #000;
`;

export const CheckedIconWrapper = styled(CheckedIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
`;

export const S3ImageWithFallbackWrapper = styled(S3ImageWithFallback)`
  display: flex;
`;
