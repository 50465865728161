import React from 'react';
import { SortIcon } from './index';

export const ArrowDown = (props) => {
  return (
    <SortIcon width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.10596 1.1875L5.04346 5.125L8.98096 1.1875"
        stroke="#A1A1A5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SortIcon>
  );
};

export const ArrowUp = (props) => (
  <SortIcon xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
    <path
      d="M8.93756 5.125L5.00006 1.1875L1.06256 5.125"
      stroke="#A1A1A5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SortIcon>
);
