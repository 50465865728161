import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChooseIconsPopup from './component';

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseIconsPopup);
