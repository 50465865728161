import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { CALENDAR_TYPES } from 'constants/commonData';

const Container = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: flex-start;
  align-items: center;

  .item {
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    padding: 3px 15px;
    cursor: pointer;
    border-radius: 12.5px;

    &.selected {
      background-color: #363636;
      color: #fff;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  ${props =>
    props.newUI &&
    css`
      .item {
        font-size: 12px;
        line-height: 12px;
        padding: 8px 16px;
        border-radius: 3px;
      }
    `}
`;

export default props => {
  const { selected, options, onSelect, className, uiOptions, disabled } = props;
  const checkDisabled = typeof disabled === 'function';
  return (
    <Container className={className ? className : ''} {...uiOptions}>
      {_.map(options || CALENDAR_TYPES, item => {
        const itemDisabled = checkDisabled && disabled(item);

        return (
          <div
            key={item.value}
            className={`item${item.value === selected ? ' selected' : ''}${itemDisabled ? ' disabled' : ''}`}
            onClick={() => {
              if (itemDisabled) {
                return false;
              }

              if (selected !== item.value && typeof onSelect === 'function') {
                onSelect(item.value);
              }
            }}
          >
            {item.label}
          </div>
        );
      })}
    </Container>
  );
};
