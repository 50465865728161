import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { CDN_URL } from 'constants/commonData';
import { getFixedPopupStyles } from 'utils/commonFunction';
import { axiosInstance } from 'configs/request';
import OwnerAvatar from 'shared/OwnerAvatar';
import Filter from './Filter';
import { DEFAULT_FILTERS } from 'shared/ExerciseFilterPopup';
import { logSearchExcercise } from 'libs/firebase-analytics';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { getOneRepMax } from 'redux/workout-builder/actions';
import LoadingIndicator from 'shared/LoadingIndicator';
import { ReactComponent as WarningExerciseIcon } from 'assets/icons/warning-exercise.svg';

import * as S from './style';
import { isS3FileURL } from 'utils/validations';

const dimensions = {
  filter: 49,
  createNew: 50,
  results: 30,
  padding: 20,
  maxHeight: 296,
  opHeight: 50,
  noResults: 41,
};
const CREATE_NEW_ITEM = { key: 'create_new' };
const acceptedKeys = [13, 38, 40];
const LIMIT_PERPAPER = 20;

const CancelToken = axios.CancelToken;
const searchRecentCancelRequests = [];

class SearchExercise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      selectingItem: null,
      selected: props.defaultValue || null,
      textSearch: '',
      options: props.defaultOptions || [],
      filters: { ...DEFAULT_FILTERS },
      searching: false,
      searchPage: 1,
      searchEnd: false,
    };

    this.searchDebounce = _.debounce(this.searchExercise, 500);
    this.inputRef = React.createRef();
    this.listRef = React.createRef();
    this.searchBoxRef = React.createRef();
  }

  // TODO - Will remove if no side-effect
  // componentWillReceiveProps(nextProps) {
  //   let { defaultOptions } = nextProps;
  //   defaultOptions = defaultOptions || [];

  //   this.setState({ options: defaultOptions });
  // }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, true);
  }

  componentDidUpdate() {
    if (this.props.defaultValue !== this.state.selected) {
      this.setState({ selected: this.props.defaultValue });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, true);
  }

  onClose = (otherProps, callback) => {
    const { defaultOptions } = this.props;
    this.setState(
      {
        selectingItem: null,
        showMenu: false,
        options: defaultOptions,
        filters: DEFAULT_FILTERS,
        textSearch: '',
        searching: false,
        ...otherProps,
      },
      () => {
        typeof callback === 'function' && callback();
      },
    );
  };

  handleScroll = event => {
    const { showMenu } = this.state;

    if (!showMenu) {
      return false;
    }

    if (event && event.type === 'scroll' && event.target) {
      const target = event.target;

      if (
        target.className === 'async-select--menu-container' ||
        !target.querySelector('.async-select--menu-container')
      ) {
        // Don't close when scroll menu
        return false;
      }
    }

    this.onClose();
  };

  onTextChange = e => {
    const { filters } = this.state;
    const { defaultOptions } = this.props;
    const textSearch = e.target.value;
    const textTrim = textSearch.trim();

    if (textTrim.length) {
      const newState = {
        textSearch,
        selectingItem: null,
        showMenu: true,
        searching: true,
        searchPage: 1,
        options: [],
        searchEnd: false,
      };

      const newFilter = { ...filters, q: textTrim, page: 1 };

      this.setState(newState, () => this.searchDebounce(newFilter, newState));
    } else {
      this.setState({
        textSearch,
        selectingItem: null,
        searching: false,
        showMenu: true,
        searchPage: 1,
        searchEnd: false,
        options: defaultOptions,
      });
    }
  };

  searchExercise = (body, newState) => {
    if (newState.searching && !!searchRecentCancelRequests.length) {
      searchRecentCancelRequests.forEach(
        cancelRequest => typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_EXERCISE_FIELD'),
      );
    }

    logSearchExcercise({ ...body, per_page: LIMIT_PERPAPER });
    let tags = _.get(body, 'tags', []);
    let queryParams = _.cloneDeep(body);
    if (tags.length > 0) {
      queryParams.tags = queryParams.tags.map(tags => tags._id);
    }

    axiosInstance
      .post(
        '/api/exercise/search_filter',
        {
          ...queryParams,
          per_page: LIMIT_PERPAPER,
          excludes: this.props.excludes || [],
        },
        {
          cancelToken: new CancelToken(cancelRequest => searchRecentCancelRequests.push(cancelRequest)),
        },
      )
      .then(response => {
        const { showMenu, options } = this.state;

        if (showMenu) {
          const { data: { data = [] } = {} } = response;

          const currentOptions = newState.options || options || [];
          const newOptions = data.map(itm => ({ ...itm, key: itm._id, label: itm.title, value: itm._id }));

          const currentQueryText = body.q || '';
          const currentInputValue = (this.inputRef.current || {}).value || '';

          if (currentQueryText.toLowerCase().trim() === currentInputValue.toLowerCase().trim()) {
            searchRecentCancelRequests.length = 0;

            this.setState({
              options: [...currentOptions, ...newOptions],
              selectingItem: null,
              searching: false,
              searchEnd: data.length < LIMIT_PERPAPER,
            });
          }
        }
      })
      .catch(error => {
        if ((error || {}).message === 'CANCEL_REQUEST_SEARCH_EXERCISE_FIELD') return;
        const { textSearch, filters, showMenu } = this.state;

        if (showMenu) {
          const q = textSearch.trim();

          if (_.isEqual(body, { ...filters, q, page: body.page })) {
            this.setState({ options: [], searching: false, selectingItem: null });
          }
        }
      });
  };

  onSearchBoxClick = e => {
    if (this.props.disabled) {
      return;
    }

    const { showMenu, textSearch, selected, filters, options } = this.state;
    const { defaultOptions } = this.props;
    const selectedId = (selected || {}).value;

    const selectedOpt = defaultOptions.find(item => item._id === selectedId) || {};
    const isExisted = selectedOpt.is_existing !== false;

    if (!showMenu) {
      if (!isExisted) {
        const selectedLabel = !textSearch.length ? _.get(selected, 'label', '') : '';
        const textTrim = selectedLabel.trim();

        if (textTrim.length) {
          const newState = {
            textSearch: textTrim,
            searching: true,
            searchPage: 1,
            options: [],
            searchEnd: false,
          };

          const newFilter = { ...filters, q: textTrim, page: 1 };

          this.setState(newState, () => this.searchExercise(newFilter, newState));
        }
      }

      const newOptions = !!options.length ? options : defaultOptions || [];

      this.setState(
        {
          showMenu: true,
          selectingItem: null,
          options: newOptions,
        },
        () => {
          this.inputRef && this.inputRef.current.focus();
        },
      );
    }
  };

  onKeyDown = e => {
    const key = e.keyCode;
    const { options, selectingItem, textSearch } = this.state;
    const search = textSearch.trim();

    if (!acceptedKeys.includes(key)) {
      return false;
    }

    e.preventDefault();

    if (key === 13 && selectingItem) {
      // Enter
      return this.handleSelect(
        selectingItem.key === CREATE_NEW_ITEM.key ? { ...selectingItem, textSearch: search } : selectingItem,
      );
    }

    if (key === 40) {
      // key Down
      if (!selectingItem && search.length > 2) {
        return this.setState({ selectingItem: CREATE_NEW_ITEM });
      }

      if (!selectingItem || (selectingItem.key === CREATE_NEW_ITEM.key && options.length)) {
        return this.setState({ selectingItem: options[0] });
      }

      if (options.length) {
        const index = _.findIndex(options, o => o.value === selectingItem.value);

        if (index < options.length - 1) {
          const newIndex = index + 1;

          return this.setState({ selectingItem: options[newIndex] }, () => {
            if (this.listRef.current) {
              const { scrollTop, offsetHeight } = this.listRef.current;
              const firstItemInCurrentView = Math.round(scrollTop / dimensions.opHeight);
              const lastItemInCurrentView = Math.round((scrollTop + offsetHeight) / dimensions.opHeight);

              if (newIndex <= firstItemInCurrentView || newIndex >= lastItemInCurrentView) {
                this.listRef.current.scrollTop = (newIndex + 1) * dimensions.opHeight - offsetHeight;
              }
            }
          });
        }
      }

      return false;
    }

    // key Up
    if (!selectingItem || selectingItem.key === CREATE_NEW_ITEM.key || !options.length) {
      return false;
    }

    const index = _.findIndex(options, o => o.value === selectingItem.value);

    if (index === 0) {
      if (search.length > 2) {
        return this.setState({ selectingItem: CREATE_NEW_ITEM });
      }

      return false;
    }

    const newIndex = index - 1;

    return this.setState({ selectingItem: options[newIndex] }, () => {
      if (this.listRef.current) {
        const { scrollTop, offsetHeight } = this.listRef.current;
        const firstItemInCurrentView = Math.round(scrollTop / dimensions.opHeight);
        const lastItemInCurrentView = Math.round((scrollTop + offsetHeight) / dimensions.opHeight);

        if (newIndex <= firstItemInCurrentView || newIndex >= lastItemInCurrentView) {
          this.listRef.current.scrollTop = (newIndex + 1) * dimensions.opHeight - offsetHeight;
        }
      }
    });
  };

  handleToggleUsePercent = () => {
    const { is_use_1rm, onToggleUsePercent } = this.props;

    if (typeof onToggleUsePercent === 'function') {
      onToggleUsePercent(!is_use_1rm);
      if (!is_use_1rm) {
        this.props.getOneRepMax({ exercise: this.state.selected.value });
      }
    }
  };

  handleSelect = item => {
    const { onlySelect, is_use_1rm } = this.props;
    const { selected } = this.state;
    const itemValue = _.get(item, 'value');
    let newSelectedValue = onlySelect || !itemValue ? null : item;

    const callback = () => {
      if (!item) {
        return false;
      }

      if (typeof this.props.onSelect === 'function') {
        if (item.key === 'create_new') {
          return this.props.onSelect(item);
        }

        if (!selected || selected.value !== item.value) {
          this.props.onSelect(item);
          this.setState({
            searchEnd: false,
            searchPage: 1,
          });
        }
      }

      if (is_use_1rm) {
        this.props.getOneRepMax({ exercise: this.state.selected.value });
      }
    };

    this.onClose({ selected: newSelectedValue }, callback);
  };

  onCreateNewExercise = label => {
    const data = { key: 'create_new', textSearch: label || this.state.textSearch.trim() };
    this.handleSelect(data);
  };

  getMenuStyles = () => {
    const { options, textSearch } = this.state;
    const { disableCreateNew } = this.props;
    const search = textSearch.trim();
    const createNewDimension = disableCreateNew || search.length < 3 ? 0 : dimensions.createNew;
    const width = this.searchBoxRef.current ? this.searchBoxRef.current.offsetWidth : 300;
    const defaultStyles = { maxHeight: dimensions.maxHeight, width };

    if (this.searchBoxRef.current) {
      const rect = this.searchBoxRef.current.getBoundingClientRect();

      const listHeight = options.length
        ? options.length * dimensions.opHeight + dimensions.results
        : dimensions.noResults;
      const realHeight = listHeight + createNewDimension + dimensions.padding;
      const menuHeight = Math.min(realHeight, dimensions.maxHeight);

      const results = {
        styles: { ...defaultStyles, ...getFixedPopupStyles(rect, menuHeight, 0) },
        postion: 'bottom',
      };

      if (results.styles.top < rect.y) {
        results.postion = 'top';
      }

      return results;
    }

    return { styles: { maxHeight: dimensions.maxHeight, width }, postion: 'bottom' };
  };

  renderInput = () => {
    const { textSearch, selected, showMenu } = this.state;
    const { menuIsAutoOpen, placeholder, defaultOptions } = this.props;
    const selectedLabel = !textSearch.length ? _.get(selected, 'label', '') : '';
    const selectedId = (selected || {}).value;
    const selectedOpt = defaultOptions.find(item => item._id === selectedId) || {};
    const isExisted = selectedOpt.is_existing !== false;

    return (
      <div className={classNames('box__left', { 'has-warning': !isExisted })} onClick={this.onSearchBoxClick}>
        <input
          value={textSearch}
          disabled={this.props.disabled}
          onChange={this.onTextChange}
          onKeyDown={this.onKeyDown}
          ref={this.inputRef}
          onFocus={() => {
            if (this.props.disabled) {
              return;
            }

            setTimeout(() => {
              if (!this.state.showMenu) {
                this.setState({ showMenu: true });
              }
            }, 500);
          }}
          type="text"
          autoComplete="new-password"
          autoFocus={!selected && menuIsAutoOpen}
        />
        {!isExisted && !showMenu && (
          <>
            <ReactTooltip
              className="app-tooltip ai-tooltip ex-tooltip"
              id={`ex-${selectedId}-wk-tooltip`}
              effect="solid"
              place="top"
              delayShow={100}
            >
              This exercise is not on library
            </ReactTooltip>
            <WarningExerciseIcon className="waring-icon" data-for={`ex-${selectedId}-wk-tooltip`} data-tip />
          </>
        )}
        <input
          value={selectedLabel}
          type="text"
          autoComplete="new-password"
          className="mirror"
          disabled
          placeholder={textSearch.length ? '' : placeholder || 'Search for your Exercise'}
        />
      </div>
    );
  };

  renderRightContent = () => {
    const { filters, showMenu } = this.state;

    return (
      <div className="box__right">
        {showMenu ? <Filter currentFilters={filters} onApply={this.onApplyFilter} /> : this.renderUsePercent()}
      </div>
    );
  };

  renderAvatar = () => {
    const { selected } = this.state;
    const thumbnail_url = _.get(selected, 'thumbnail_url');
    return thumbnail_url ? (
      <S3ImageWithFallback
        className="box__avatar"
        fluid
        cover
        defaultImage={`${CDN_URL}/images/exercise_grey.svg`}
        src={[thumbnail_url]}
      />
    ) : null;
  };

  renderUsePercent() {
    const { should_show_1rm, is_use_1rm, disabled, defaultOptions } = this.props;
    const { selected } = this.state;
    const selectedId = (selected || {}).value;
    const selectedOpt = defaultOptions.find(item => item._id === selectedId) || {};
    const isExisted = selectedOpt.is_existing !== false;

    return (
      <>
        {!isExisted && (
          <button
            className="create-exercise"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.onCreateNewExercise(selectedOpt.label);
            }}
          >
            Create this exercise
          </button>
        )}
        {should_show_1rm && (
          <button
            className={classNames('use_1rm', { active: is_use_1rm })}
            onClick={e => {
              if (disabled) {
                return;
              }

              e.preventDefault();
              e.stopPropagation();
              this.handleToggleUsePercent();
            }}
          >
            Use %
          </button>
        )}
      </>
    );
  }

  handleScrollBottom = () => {
    const { searchPage, filters, searching, textSearch, searchEnd } = this.state;
    if (this.listRef.current && !searching && !searchEnd) {
      const { scrollTop, clientHeight, scrollHeight } = this.listRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 25) {
        const nextPage = searchPage + 1;

        const newState = {
          searchPage: nextPage,
          searching: true,
        };

        const newFilter = { ...filters, q: textSearch, page: nextPage };

        this.setState(newState, () => this.searchDebounce(newFilter, newState));
      }
    }
  };

  renderExercise = data => {
    const { showNameMultiline } = this.props;
    const { custom, author, preview_300, picture, videoLink, video } = data;
    const thumbnail = preview_300 || (picture || [])[0];
    const hasVideo = !!video || !!videoLink;

    return (
      <S.Exercise
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          this.handleSelect(data);
        }}
        onMouseDown={e => e.preventDefault()}
        isShowNameMultiline={showNameMultiline}
      >
        <S.ExerciseBasicInfo isShowNameMultiline={showNameMultiline}>
          <S.ExerciseThumbnail noThumbnail={!thumbnail} hasVideo={hasVideo}>
            {isS3FileURL(thumbnail) ? (
              <S3ImageWithFallback fluid cover defaultImage={`${CDN_URL}/images/exercise_grey.svg`} src={[thumbnail]} />
            ) : (
              <S.Thumbnail thumbnail={thumbnail} />
            )}
          </S.ExerciseThumbnail>
          {showNameMultiline && (
            <S.ExerciseNameMultiline>
              <span className="name">{data.label}</span>
            </S.ExerciseNameMultiline>
          )}
          {!showNameMultiline && (
            <>
              <S.ExerciseName>{data.label}</S.ExerciseName>
              {custom && <S.ExerciseCustom>Custom</S.ExerciseCustom>}
            </>
          )}
        </S.ExerciseBasicInfo>
        {showNameMultiline && (
          <S.TagCustomLabel>
            <span className="tag-custom">Custom</span>
          </S.TagCustomLabel>
        )}
        {custom && author ? (
          <OwnerAvatar data={author} tooltipId={`search-exercise__owner-tooltip-${data._id}`} />
        ) : null}
      </S.Exercise>
    );
  };

  onApplyFilter = data => {
    const { filters, textSearch } = this.state;

    if (_.isEqual(filters, data)) {
      return null;
    }

    const q = textSearch.trim();

    const newState = { filters: data, searching: true, searchPage: 1, options: [], searchEnd: false };
    const newFilter = { ...data, q, page: 1 };
    this.setState(newState, () => this.searchExercise(newFilter, newState));
  };

  render() {
    const { showMenu, selectingItem, options, textSearch, filters, searching } = this.state;
    const { disableCreateNew, disabled, hideFilter, showAvatar } = this.props;
    const textTrimmed = textSearch.trim();
    const { styles, postion } = this.getMenuStyles();
    const isMostRecent = !textTrimmed && _.isEqual(DEFAULT_FILTERS, filters);
    const noResults = !searching && !options.length;
    const selectingValue = _.get(selectingItem, 'value', '');

    return (
      <RootCloseWrapper event="click" disabled={!showMenu} onRootClose={() => this.onClose()}>
        <S.Wrapper className={classNames('search-exercise-container', { 'input--focus open': showMenu }, postion)}>
          <S.SearchBox ref={this.searchBoxRef} className="box">
            {showAvatar ? this.renderAvatar() : null}
            {this.renderInput()}
            {hideFilter ? null : this.renderRightContent()}
          </S.SearchBox>
          {showMenu ? (
            <S.OptionContainer style={styles} className="menu-wrapper">
              {!disableCreateNew && textTrimmed.length > 2 ? (
                <S.CreateNew
                  onClick={() => this.onCreateNewExercise()}
                  className={selectingItem && selectingItem.key === CREATE_NEW_ITEM.key ? 'selecting' : ''}
                >
                  + Create exercise “{textTrimmed}”
                </S.CreateNew>
              ) : null}
              <S.OptionHeader noResults={noResults}>
                {noResults ? 'No results found' : isMostRecent ? 'Most Recent' : 'Results'}
              </S.OptionHeader>
              <S.ListExercise
                ref={this.listRef}
                className={classNames('async-select--menu-container', { searching: searching })}
                onScroll={e => {
                  this.handleScrollBottom();
                  e.stopPropagation();
                }}
              >
                <ul className="menu">
                  {_.map(_.unionBy(options, '_id'), item => (
                    <li key={item._id} className={classNames('menu__item', { active: item.value === selectingValue })}>
                      {this.renderExercise(item)}
                    </li>
                  ))}
                </ul>
                {searching && <LoadingIndicator title="Loading..." className="loading-exercise" />}
              </S.ListExercise>
            </S.OptionContainer>
          ) : null}
        </S.Wrapper>
      </RootCloseWrapper>
    );
  }
}

SearchExercise.propTypes = {};

const mapDispatchToProps = (dispatch, ownerProps) => {
  return {
    getOneRepMax: bindActionCreators(getOneRepMax, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(SearchExercise);
