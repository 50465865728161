import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import get from 'lodash/get';

import { updateClientGoal } from 'redux/client-countdown/action';
import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

import AddGoalPopup from '../../AddGoalPopup';
import { RemoveConfirmModal } from '../../ConfirmModal/style';
import * as S from './style';

const GoalMoreOption = props => {
  const { updateClientGoal, toggleConfirmModal, clientId, currentGoal } = props;

  const handleEdit = () => {
    toggleConfirmModal(true, <AddGoalPopup isEdit currentGoal={currentGoal} />);
  };

  const handleRemoveGoal = () => {
    updateClientGoal(clientId);
  };

  const handleRemove = () => {
    toggleConfirmModal(
      true,
      <RemoveConfirmModal
        onConfirm={handleRemoveGoal}
        onDeny={() => toggleConfirmModal(false)}
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        confirmButtonTitle="Remove"
        title="Remove goal"
        content="Are you sure you want to remove this goal?"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  return (
    <S.DropdownWrapper className="goal-actions">
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="countdown-options-tooltip">
            <S.TriggerIcon className={classNames('trigger-icon', { active: !!open })} />
            {!open && <SharedTooltip id="countdown-options-tooltip" />}
          </div>
        )}
      >
        <Option key="edit" onClick={handleEdit}>
          <img className="icon edit" src={`${CDN_URL}/images/edit-white.svg`} alt="" />
          <span>Edit</span>
        </Option>
        <Option key="delete" onClick={handleRemove}>
          <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
          <span>Remove</span>
        </Option>
      </DropDown>
    </S.DropdownWrapper>
  );
};

const mapState = ({ rootReducer }) => ({
  clientId: get(rootReducer, 'client.selected'),
  currentGoal: get(rootReducer, 'clientCountdown.currentGoal'),
});

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  updateClientGoal: bindActionCreators(updateClientGoal, dispatch),
});

export default connect(mapState, mapDispatch)(GoalMoreOption);
