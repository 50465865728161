import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import iconSelectedDay from 'assets/icons/icon_selected_date.svg';
import { STATUS_HABIT } from '../../../../constants';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
`;

export const TooltipCustom = styled.div`
  ${baseText}
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  display: block;
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px;
  background: #151619;
  border-radius: 4px;
  width: max-content;
  display: none;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #151619;
  }
`;

export const ProgressCalendar = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
  margin: auto;
  margin-top: 8px;
  .CircularProgressbar {
    .CircularProgressbar-trail {
      ${props => {
        switch (props.status) {
          case STATUS_HABIT.DOING:
            return css`
              stroke: rgba(227, 230, 239, 1);
            `;
          case STATUS_HABIT.DONE:
            return css`
              stroke: #5559ff;
            `;
          default:
            return css`
              stroke: transparent;
            `;
        }
      }}
    }
    .CircularProgressbar-path {
      transition: none;
      ${props => {
        switch (props.status) {
          case STATUS_HABIT.DOING:
            return css`
              stroke: #5559ff;
            `;
          case STATUS_HABIT.DONE:
            return css`
              stroke: #5559ff;
            `;
          default:
            return css`
              stroke: transparent;
            `;
        }
      }}
    }
  }
  ${props =>
    props.daySelected &&
    css`
      &::after {
        content: '';
        background-image: url(${iconSelectedDay});
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        display: block;
        background-position: center 0;
      }
    `};
  &:hover ${TooltipCustom} {
    display: block;
  }
  &:hover {
    ${props =>
      props.daySelected &&
      props.status &&
      css`
        &::after {
          display: none;
        }
      `};
  }
`;

export const AmountDone = styled.div`
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  span {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #ffffff;
    display: inline-block;
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Tooltip = styled(ReactTooltip)`
  ${baseText}
  padding: 10px 15px;
  border-radius: 5px;
  background: #2d2e2d;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  background-color: ${props => (props.status === STATUS_HABIT.DONE ? '#5559ff' : 'transparent')};
  &.rdtOld {
    opacity: 0.3;
  }
  &.rdtNew {
    opacity: 0.3;
  }
  span {
    ${baseText};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: ${props => (props.status === STATUS_HABIT.DONE ? '#fff' : '#212251')};
  }
  &.rdtDay.rdtToday {
    span {
      ${props =>
        props.removeToday &&
        css`
          color: #5e59ff;
        `};
      ${props =>
        props.removeTextToday &&
        css`
          color: #fff;
        `};
    }
  }
`;
