import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Button, Image, Modal } from 'semantic-ui-react';

import { CDN_URL } from 'constants/commonData';

import { Toggle } from 'shared/FormControl';
import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import { hideWorkout } from 'redux/calendar/calendar.actionCreators';

import * as S from './style';

const WorkoutSettingModal = props => {
  const {
    hideWorkout,
    isSecondModalOpen,
    toggleSecondModal,
    toggleConfirmModal,
    noBorder,
    is_hide_workout,
    assignmentId,
    isHideWorkout,
    isCreateNew,
  } = props;

  const initialToggle = isCreateNew ? is_hide_workout : isHideWorkout;
  const [toggle, setToggle] = useState(initialToggle);
  const [isDisabled, setIsDisabled] = useState(true);

  const disabled = useMemo(() => toggle === initialToggle, [toggle, initialToggle]);

  const handleToggleChange = () => {
    setToggle(!toggle);
    setIsDisabled(false);
  };

  const onClose = () => {
    if (!disabled) {
      handleDiscardChange();
    } else {
      toggleSecondModal(false);
    }
  };

  const handleSaveSetting = () => {
    if (isDisabled) {
      return;
    }
    hideWorkout(toggle, assignmentId, isCreateNew);
    toggleSecondModal(false);
  };

  const handleDiscardChange = () => {
    toggleConfirmModal(
      true,
      <S.CustomConfirmModal
        noBorder
        title="Discard Changes?"
        content={`Are you sure you want to go? Changes have not been saved yet.`}
        onConfirm={() => {
          toggleSecondModal(false);
        }}
        confirmButtonTitle="Discard changes"
        hasCloseIcon
        headerIcon={`${CDN_URL}/images/discard_changes_icon.svg`}
      />,
    );
    return false;
  };

  return (
    <S.CustomModal
      open={isSecondModalOpen}
      onClose={onClose}
      closeOnDimmerClick={true}
      className="workout-setting-modal"
    >
      <Modal.Header className="workout-setting-modal-header">
        <S.ContentHeader>
          <S.HeaderTitle>Workout Settings</S.HeaderTitle>
        </S.ContentHeader>
        <Button onClick={onClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      </Modal.Header>
      <Modal.Content className="workout-setting-modal-content">
        <S.WrapperWorkoutSetting>
          <S.ContentWorkoutSetting>Setting up your scheduled workout</S.ContentWorkoutSetting>
          <S.ActionWorkoutSetting>
            <S.ActionTitle>Hide Workout From Client</S.ActionTitle>
            <Toggle checked={toggle} onChange={handleToggleChange} width={41} height={20} />
          </S.ActionWorkoutSetting>
        </S.WrapperWorkoutSetting>
      </Modal.Content>
      <Modal.Actions className={classNames('workout-setting-modal-actions', { 'no-border': noBorder })}>
        <Button className="action-cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button className={classNames('action-save-button', { 'is-disabled': isDisabled })} onClick={handleSaveSetting}>
          Save Setting
        </Button>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      calendar: { is_hide_workout },
    },
    isSecondModalOpen,
  } = state;
  return {
    is_hide_workout,
    isSecondModalOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  hideWorkout: bindActionCreators(hideWorkout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutSettingModal);
