import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import HabitDetail from 'components/HabitDetail';
import HabitsBuilderPopup from 'components/TaskCreate/HabitsBuilderPopup';
import { getHabitDetail, getHabitUnits } from 'redux/habits/actions';

const formatDate = (data, format = 'YYYY-MM-DD') => {
  return moment(data).format(format);
};

const ClientHabitDetail = props => {
  const onClose = () => {
    const {
      match: {
        params: { clientId },
      },
    } = props;
    props.toggleModal(false);
    props.replace(`/home/client/${clientId}/task`);
  };

  useEffect(() => {
    const {
      match: {
        params: { habitId, clientId },
      },
    } = props;

    const params = new URLSearchParams(window.location.search);

    const paramsTracked = params.get('tracked');
    const hasParamsTracked = !_.isEmpty(paramsTracked);
    const selectedDate = hasParamsTracked ? formatDate(paramsTracked.replace(/-/g, '/')) : formatDate();
    const today = formatDate(new Date());
    const checkDayMissed = moment(today).isSameOrAfter(selectedDate);

    const monthCurrent =
      checkDayMissed && hasParamsTracked
        ? formatDate(paramsTracked.replace(/-/g, '/'), 'yyyy-MM')
        : formatDate(new Date(), 'yyyy-MM');

    !props.habitUnits.length && props.getHabitUnits();
    props
      .getHabitDetail({
        habit_id: habitId,
        month: hasParamsTracked ? monthCurrent : props.calendarStartDate.toFormat('yyyy-MM'),
        client: clientId,
      })
      .then(response => {
        const data = _.get(response, 'data.data');
        if (_.get(data, 'has_tracked')) {
          props.toggleModal(true, <HabitDetail prevHabit={data} onClose={onClose} />);
        } else {
          props.toggleModal(true, <HabitsBuilderPopup prevHabit={data} isEdit onClose={onClose} />);
        }
      });
  }, []);

  return null;
};

const mapStateToProps = state => {
  const { calendarStartDate, rootReducer } = state;

  return {
    calendarStartDate,
    habitUnits: _.get(rootReducer, 'habits.habitUnits'),
  };
};

export default connect(mapStateToProps, { toggleModal, replace, getHabitDetail, getHabitUnits })(ClientHabitDetail);
