import { Modal, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: 650px;
    height: 100%;
    max-height: 745px;
    padding: 24px 16px 0px 16px;
    border-radius: 12px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      padding: unset !important;
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    > :last-child {
      padding: unset !important;
      border-radius: 0px !important;
    }
  }
  .header-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0px 8px 4px;
  }
  .title {
    color: #141414;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    height: 27px;
    padding-left: 0.5px;
  }
  .description {
    color: #595959;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    height: 19px;
    margin: 0px 8px;
    padding-left: 0.5px;
  }
  .btn-add-new {
    cursor: pointer;
    user-select: none;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    .label-add-new {
      color: #5158cf;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
    }
    .icon-add-new {
    }
  }
  .exercise-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .search-container {
    height: 44px;
    margin: 16px 8px;
  }
  .search-wrapper {
    width: 602px;
    height: 44px;
    position: relative;
  }
  .search-icon {
    position: absolute;
    top: 14px;
    left: 16px;
    width: 16px;
    height: 16px;
    z-index: 1;
  }
  .search-input {
    width: 602px;
    height: 44px;
    border-radius: 8px;
    background-color: #eeeff2;

    input {
      background: unset;

      color: #141414;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      padding-left: 47.5px;
      padding-right: 50px;
      border-radius: 8px;

      ::placeholder {
        opacity: 1;
        color: #7b7e91;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      :focus {
        background-color: #ffffff !important;
        caret-color: #5158cf;
        border-color: #5158cf;
      }
    }
    svg {
      width: 18px;
      height: 18px;
      right: 16px;
      path {
        fill: #7b7e91;
      }
    }
  }
  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 16px 8px;
  }
  .header-total {
    color: #141414;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    height: 16px;
    text-transform: uppercase;
    padding-top: 1px;
    padding-left: 0.5px;

    &.search-mode {
    }
  }
  .btn-sort {
    cursor: pointer;
    user-select: none;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    .label-sort {
      color: #5158cf;
      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      height: 16px;
      padding-top: 1px;
      padding-right: 0.5px;
    }
    .icon-sort {
    }
  }
  .exercise-scroll-wrapper {
    overflow: hidden auto;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding-right: 16px;

    ::-webkit-scrollbar {
      width: 4px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #d9d9d9 !important;
    }
  }
  .exercise-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: 8px 16px 8px 8px;
    gap: 15px;
    cursor: pointer;

    &.default-exercise-item {
      :hover {
        border-radius: 6px;
        background: #f4f4ff;

        .exercise-btn-remove {
          display: block;
        }
      }
      .exercise-title-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;
      }
      .exercise-custom-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 19px;
        width: 49px;
        background: #f6f7f9;
        border: none;
        box-sizing: border-box;
        border-radius: 2px;
        font-weight: 400;
        font-size: 10px;
        line-height: 100%;
        color: rgba(32, 35, 83, 0.31);
      }
    }
  }
  .exercise-thumbnail {
    width: 90px;
    height: 56px;
    background-color: #eaebef;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .image-with-fallback,
    img {
      border-radius: 4px;
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: cover;
    }
    &.video {
      ::before {
        content: '';
        position: absolute;
        left: 7px;
        bottom: 7px;
        width: 14px;
        height: 14px;
        background: url(${CDN_URL}/images/video_play.svg) no-repeat left bottom;
        background-size: contain;
        z-index: 1;
      }
    }
    .thumbnail {
      background: url(${CDN_URL}/images/exercise_grey.svg) no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
      position: relative;
      background-size: auto;
    }
    .thumbnail-default-image {
      width: 15px;
      height: 15px;
    }
  }
  .exercise-title {
    color: #141414;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.8px;

    // show 3 dots in 3 lines
    flex: 1;
    word-break: break-word;
    box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .exercise-actions {
    min-height: 24px;
    min-width: 24px;
    flex-shrink: 0;
  }
  .exercise-btn-add {
    display: flex;
    padding: 7px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #5158cf;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  .exercise-btn-remove {
    height: 24px;
    width: 24px;
    display: none;

    :hover {
      svg path {
        fill: #e51b33;
      }
    }
  }
  .empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    height: 256px;
    padding: 10px;
    gap: 16px;
    margin-bottom: 16px;
  }
  .empty-icon {
    max-height: 133px;
  }
  .empty-search-text {
    color: #8c8c8c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 1px;
  }
  .indicator-wrapper {
    display: block;
    position: relative;
    margin-top: 0px;
    margin-bottom: 32px;
    min-height: 64px;
    width: 100%;
    .ui.loader {
      z-index: unset !important;
    }
    .ui.loader:before {
      border-color: #fbfbff !important;
      border-width: 4px !important;
    }
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
      border-width: 4px !important;
    }
    span {
      color: #8c8c8c;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }
    &.is-empty {
      margin-top: 144px;
    }
  }
`;

export const AddNewPopup = styled(Popup)`
  &.ui.popup {
    border-radius: 8px !important;
    border: unset !important;
    background: #fff !important;
    box-shadow: inset 1px solid #dadbea, 0px 3px 6px 0px rgba(0, 0, 0, 0.07) !important;
    z-index: 3000 !important;
  }
  &.ui.bottom.popup {
    margin: 8px 0 0 !important;
  }
  .container-popup {
    padding: 10px;
    width: 465px;
    max-height: 424px;
  }
  .search-box-popup {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 39px;
    border-radius: 5px;
    box-shadow: inset 0px 0px 0px 1px #6167cf;
    background: #fff;
    padding: 7px 12px;
    gap: 8px;
  }
  .search-text-field {
    display: flex;
    flex: 1;
    height: 100%;
    input {
      border: unset !important;
      padding: 0px !important;
      outline: none !important;
      width: 100% !important;
      color: #5b5b5b;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      ::placeholder {
        color: #aeaebb;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .clear-actions {
    height: 100%;
    width: 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clear-button {
    width: 16px;
    height: 17px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    background: unset;
    border: unset;
    cursor: pointer;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  .filter-actions {
    height: 100%;
    flex-shrink: 0;
  }

  .header-group-wrapper {
    min-height: 24px;
    margin: 3px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-popup-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-left: 8px;
    min-height: 34px;
  }

  .checkbox-wrapper {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover .checkbox-icon {
      border-color: #7072dd !important;
    }

    .checkbox-thumbnail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;

      .evf-checkbox.exercise-checkbox-all {
        padding-left: unset;
      }

      .checkbox-icon {
        transform: translate(-50%, -50%);
        border-color: #d9d9d9;
      }

      input:checked ~ .checkbox-icon {
        background-color: #7072dd;
        background-size: 10px;
        border-color: #7072dd;
      }
    }
  }

  .header-popup-label {
    color: #141414;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .header-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 9px;

    .selected-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      color: #595959;
    }

    .selected-count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      min-width: 24px;
      min-height: 24px;

      background: #f3f5f8;
      border-radius: 4px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      color: #595959;
      padding: 0px 4px;
    }
  }
  .exercise-scroll-wrapper-popup {
    overflow: hidden auto;
    max-height: 260px;
    gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 2px;
    margin-bottom: 10px;

    ::-webkit-scrollbar {
      width: 3px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #c4c4c4 !important;
    }
  }
  .exercise-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: 8px;
    gap: 10px;
    cursor: pointer;

    &.popup-exercise-item {
      &:hover {
        background-color: #f4f4ff;
        .exercise-title {
          color: #5158cf;
        }
      }

      &:hover,
      &.selected-exercise {
        border-radius: 5px;

        .exercise-thumbnail {
          background-color: unset;

          .thumbnail,
          .thumbnail-default-image {
            display: none;
          }

          &.video {
            ::before {
              display: none;
            }
          }
        }

        .checkbox-thumbnail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;

          .evf-checkbox.exercise-checkbox {
            padding-left: unset;
          }

          .checkbox-icon {
            transform: translate(-50%, -50%);
            border-color: #7072dd;
          }

          input:checked ~ .checkbox-icon {
            background-color: #7072dd;
            background-size: 10px;
          }
        }
      }
      .exercise-title-wrapper {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
      }
      .exercise-custom-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 19px;
        width: 49px;
        background: #f6f7f9;
        border: none;
        box-sizing: border-box;
        border-radius: 2px;
        font-weight: 400;
        font-size: 10px;
        line-height: 100%;
        color: rgba(32, 35, 83, 0.31);
      }
      .exercise-owner-avatar {
        opacity: 1;
      }
    }
  }
  .exercise-thumbnail {
    width: 34px;
    height: 34px;
    background-color: #eaebef;
    flex-shrink: 0;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .image-with-fallback,
    img {
      border-radius: 4px;
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: cover;
    }
    &.video {
      ::before {
        content: '';
        position: absolute;
        left: 3px;
        bottom: 3px;
        width: 12px;
        height: 12px;
        background: url(${CDN_URL}/images/video_play.svg) no-repeat left bottom;
        background-size: contain;
        z-index: 1;
      }
    }
    .thumbnail {
      background: url(${CDN_URL}/images/exercise_grey.svg) no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
      position: relative;
      background-size: auto;
    }
    .thumbnail-default-image {
      width: 15px;
      height: 15px;
    }

    .checkbox-thumbnail {
      display: none;
    }
  }
  .exercise-title {
    color: #141414;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    // show 3 dots in 1 lines
    flex: unset;
    word-break: break-word;
    box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .empty-filter-container {
    text-align: center;
    margin: 49px 0px 62px;
    width: 100%;
  }
  .empty-filter-icon {
    margin: 0 0 10px 1px;
  }
  .empty-filter-text {
    color: #8c8c8c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding: 1px 0 0 2px;
  }
  .popup-indicator-wrapper {
    display: block;
    position: relative;
    margin-top: 16px;
    margin-bottom: 0px;
    min-height: 64px;
    width: 100%;
    .ui.loader {
      z-index: unset !important;
    }
    .ui.loader:before {
      border-color: #fbfbff !important;
      border-width: 4px !important;
    }
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
      border-width: 4px !important;
    }
    span {
      color: #8c8c8c;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 15px 16px 16px;
    margin: 0px -10px -10px;
    border-top: 1px solid rgba(218, 219, 234, 1);

    .btn-clear-all {
      padding: 0;
      width: 55px;
      height: 20px;
      border: unset;
      background: unset;
      color: rgba(81, 88, 207, 1);
      cursor: pointer;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      &:disabled {
        color: #d9d9d9;
        cursor: not-allowed;
      }
    }

    .btn-add {
      padding: 0;
      width: 87px;
      height: 33px;
      border: unset;
      border-radius: 4px;
      background: rgba(81, 88, 207, 1);
      color: #ffffff;
      cursor: pointer;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      &:disabled {
        background: #f5f5f5;
        color: #d9d9d9;
        cursor: not-allowed;
      }
    }
  }
`;
