import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import MediaPlayButton from 'shared/MediaPlayButton';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import ImageWithFallback from 'shared/ImageWithFallback';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const Trigger = props => {
  const { isVideo, url, thumbnail, generatePreview, cloudfrontList, ...otherProps } = props;
  const [src, setSrc] = useState('');

  useEffect(() => {
    const convertUrl = async () => {
      if (generatePreview && !thumbnail && isVideo && url) {
        try {
          let newUrl = convertS3UrlToCloudFrontUrl(url, cloudfrontList);
          if (!newUrl) {
            const response = await props.getS3presignedURLFromLocalDatabase([url]);
            newUrl = response[0];
          }
          setSrc(newUrl);
        } catch (error) {
          console.error(error);
          setSrc(convertS3UrlToCloudFrontUrl(url, cloudfrontList, true));
        }
      }
    };
    convertUrl();
  }, [thumbnail, url, generatePreview]);

  const handleThumbnail = () => {
    if (thumbnail) {
      if (thumbnail.length > 0) return true;
    } else return false;
  };

  if (!generatePreview) {
    return (
      <MediaPlayButton
        isVideo={isVideo}
        thumbnail={handleThumbnail}
        className="media-player__trigger"
        {...otherProps}
      />
    );
  }

  return (
    <Wrapper className="media-player__trigger preview" {...otherProps}>
      {thumbnail ? (
        <S3ImageWithFallback src={[thumbnail]} />
      ) : isVideo ? (
        <div className="media-player__videoContainer">
          <video src={src} type="video/mp4">
            <source src={src} type="video/mp4" />
          </video>
        </div>
      ) : (
        <ImageWithFallback src={[MEDIA_PLACEHOLDER]} />
      )}
      <MediaPlayButton isVideo={isVideo} thumbnail={handleThumbnail} className="media-player__button" />
    </Wrapper>
  );
};

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps)(Trigger);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .media-player__videoContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
  }
`;
