import styled, { css } from 'styled-components';
import { TableHeaderCellContent, TableHeader, TableCell, Actions, TableRow } from 'shared/LibraryLayout';
import { CDN_URL } from 'constants/commonData';

export const Container = styled.div`
  align-items: center !important;
  .packageDetail__heading {
    height: 36px !important;
    margin-bottom: 24px !important;
  }
  button:focus-visible {
    outline: none !important;
  }

  .custom-container {
    padding: 50px 0 0 !important;
  }

  .custom-layout-wrapper {
    padding-bottom: 0 !important;
  }

  .custom-loading {
    background-color: white;
    border-color: transparent;
    border: 1px solid white;
  }
`;

export const TableWrapper = styled.div`
  ::-webkit-scrollbar {
    width: 5px !important;
  }

  overflow: scroll;
  padding: 0 30px;
  margin: 24px -35px 0 -30px;
  height: ${props => (props.isShowPagination ? 'calc(100vh - 293px)' : 'calc(100vh - 259px)')} !important;
`;

export const TableHeaderWrapper = styled(TableHeader)`
  height: 56px !important;
`;

export const TableHeaderCellContentWrapper = styled(TableHeaderCellContent)`
  .column-symbol {
    margin-left: 5px;
    color: ${props => (props.active ? '#5158cf' : '#262626')};
  }

  svg.arrow {
    path {
      fill: none !important;
    }
  }

  span {
    color: ${props => (props.active ? '#5158cf' : '#262626')};
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const LayoutWrapper = styled.div`
  padding: 0;

  .pointer {
    cursor: pointer !important;
  }

  .sortable {
    cursor: pointer !important;
    :hover {
      span,
      .column-symbol {
        color: #5158cf !important;
      }
    }
  }

  tr,
  th {
    cursor: default !important;
  }

  .ui.input.search-input > input {
    background-color: #ffffff !important;
    border: 1px solid #d9d9d9 !important;
    height: 36px !important;
    &:hover,
    &:focus {
      border-color: #726ee4 !important;
    }
  }
  .search-input {
    border-radius: 4px;
    height: 34px !important;
    width: 306px !important;
  }
`;

export const TableCellWrapper = styled(TableCell)`
  ${props => ({ padding: `${props.padding || '12px 0'} !important` })}

  .name-wrapper {
    padding-right: 5px;
    .text {
      color: #262626;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      box-orient: vertical;
    }
    .email {
      color: #8c8c8c !important;
      font-size: 11px !important;
    }
  }

  .status {
    display: flex;
    padding: 0 12px;
    align-items: center;
    height: 30px;
    width: fit-content;
    border-radius: 4px;
    span {
      font-weight: 600;
    }
    &.waiting {
      background: #eaf8ff;
      span {
        color: #00a3ff;
      }
    }
    &.offered {
      background: #e4ffe7;
      span {
        color: #36b24b;
      }
    }
    &.cancelled {
      background: #fffae6;
      span {
        color: #c37f00;
      }
    }
    &.rejected {
      background: #ffebed;
      span {
        color: #ea314a;
      }
    }
    &.purchased {
      background: #f4f4fc;
      span {
        color: #5158cf;
      }
    }
    &.no-response {
      background: #f6f6f6;
      span {
        color: #8c8c8c;
      }
    }
  }
  .message-icon {
    display: inline-block;
    cursor: pointer;
    :hover {
      svg > path {
        &:nth-child(1) {
          fill: #5158cf;
        }
      }
    }
    &.not-pointer {
      cursor: default !important;
    }
  }
  .phone-icon {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    margin-right: 16px;
    .contacted-phone {
      position: absolute;
      opacity: 100;
    }
    .contacted-phone-copy {
      position: absolute;
      opacity: 0;
    }

    :hover {
      .contacted-phone-copy {
        opacity: 100;
      }
      .contacted-phone {
        opacity: 0;
      }
    }
  }
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .avatar-wrapper {
    cursor: pointer;
    img {
      pointer-events: none;
      cursor: default;
    }
  }
  .app-tooltip {
    border-radius: 6px !important;
    min-height: 30px !important;
    padding: 6px 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
    background-color: #141414 !important;
    &.checkbox-tooltip {
      border-radius: 6px !important;
      min-height: 42px !important;
    }
    &.time-action-tooltip,
    &.request-date-tooltip,
    &.impact-person-tooltip,
    &.phone-icon-tooltip {
      border-radius: 8px !important;
      min-height: 52px !important;
    }
    &.__react_component_tooltip.type-dark.place-top:after {
      border-top-color: #141414 !important;
      margin-bottom: 0 !important;
      bottom: -6px !important;
    }
    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #ffffff !important;
    }
  }
  &.column-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .sb-avatar {
    span {
      font-family: 'Open Sans' !important;
      font-size: 11px !important;
      font-weight: 700 !important;
    }
  }
  .undo-action,
  .resend-action {
    color: #5158cf;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0 !important;
    border: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent !important;
    &:disabled {
      color: #dfdfdf;
    }
  }
  .survey-response {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    width: fit-content;
    .survey-response__icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .survey-response__action {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      color: #5158cf;
    }
  }
`;

export const CustomTableCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  &.checked,
  :hover {
    .thumbnail {
      display: none;
    }
    .checkbox__thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
    ${props =>
      props.disabledSelect &&
      css`
        .checkbox-icon {
          border: 1px solid #d9d9d9 !important;
          background-color: #f5f5f5 !important;
          cursor: pointer !important;
        }
      `}
  }
`;

export const ActionsWrapper = styled(Actions)`
  display: flex;
  gap: 16px;
  button {
    margin: 0 !important;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    height: 34px;
    border: 0;
    cursor: pointer;
    span {
      color: #5158cf;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &.border {
      border: 1px solid #5158cf;
      padding: 0 24px;
    }
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .checkbox-tooltip {
    border-radius: 6px !important;
  }

  .checkbox__thumbnail {
    width: 20px;
    height: 20px;
    display: none;
  }

  .checkbox__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .checkbox__thumbnail .checkbox-icon,
  .checkbox-icon {
    width: 20px;
    height: 20px;
    border: 1px solid #b6b6b6;
    border-radius: 2px;
    background-size: 10px;
  }

  ${props =>
    props.isHasSelectedPrev &&
    css`
      .checkbox-icon {
        border: 1px solid rgb(92, 88, 192) !important;
        background-color: rgba(92, 88, 192, 0.2) !important;
      }
      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #5158cf !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    `}
`;

export const Thumbnail = styled.div`
  overflow: hidden;
  background-color: #bfbfbf;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  span {
    font-family: 'Open Sans' !important;
    font-size: 11px !important;
    font-weight: 700 !important;
  }
`;

export const TableSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .action-filter-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const TableRowWrapper = styled(TableRow)`
  ${props => ({ height: props.height || '67px' })}
  :hover {
    background-color: transparent !important;
  }
`;
