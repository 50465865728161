// Lib
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

// Components
import ToolBar from './Parts/Toolbar';
import IngredientTableHeader from './Parts/TableHeader';
import IngredientTableBody from './Parts/TableBody';
import EmptyUI from './Parts/EmptyUI';
import TableFooter from './Parts/TableFooter';
import AddSingleIngredientModal from './Parts/AddSingleIngredientModal';
import AddMultiIngredientModal from './Parts/AddMultiIngredientModal';
import LoadingSkeleton from './Parts/LoadingSkeleton';

// Constants
import { pluralize, saveLastLibraryRoute } from 'utils/commonFunction';
import { PER_PAGE } from './constants';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';

// Style
import * as S from './style';

const IngredientLibrary = props => {
  const {
    list: ingredients,
    total,
    loading,
    filters,
    isLoading,
    toggleModal,
    toggleSideBar,
    getIngredients,
    cloudfrontList,
    editIngredient,
    resetQueryParams,
    changeQueryParams,
    addMultiIngredient,
    ingredientCategory,
    selectedIngredient,
    toggleConfirmModal,
    selectedIngredients,
    addSingleIngredient,
    removeMultiIngredient,
    resetSelectedIngredient,
    userId = '',
  } = props;

  useEffect(() => {
    typeof toggleSideBar === 'function' && toggleSideBar(true);
    typeof saveLastLibraryRoute === 'function' && saveLastLibraryRoute('/home/ingredients');
    typeof getIngredients === 'function' && getIngredients();

    return () => {
      typeof resetQueryParams === 'function' && resetQueryParams();
      typeof resetSelectedIngredient === 'function' && resetSelectedIngredient();
    };
  }, []);

  const handleChangeQuery = query => {
    changeQueryParams(query);
  };

  const onAddNewIngredient = () => {
    toggleModal(
      true,
      <AddSingleIngredientModal
        optionsCategory={ingredientCategory}
        onSubmitForm={addNewIngredient}
        addMultipleIngredient={addMultipleIngredient}
      />,
    );
  };

  const onAddMultiIngredient = () => {
    toggleModal(
      true,
      <AddMultiIngredientModal optionsCategory={ingredientCategory} onSubmitForm={addMultipleIngredient} />,
    );
  };

  const addNewIngredient = data => {
    if (isLoading) return;
    typeof addSingleIngredient === 'function' &&
      addSingleIngredient(data).then(() => {
        toggleModal(false);
        toast('1 ingredient has been added.');
      });
  };

  const addMultipleIngredient = data => {
    if (isLoading) return;
    typeof addMultiIngredient === 'function' &&
      addMultiIngredient(data).then(() => {
        toggleModal(false);
      });
  };

  const confirmRemoveMultipleIngredient = () => {
    const lengthIds = selectedIngredients.length;

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          title={`Remove ${pluralize('Ingredient', lengthIds)}?`}
          content={`Would you like to remove ${lengthIds === 1 ? 'this' : 'these'} ${pluralize(
            'ingredient',
            lengthIds,
          )}?`}
          onConfirm={handleRemoveMultipeIngredient}
          onClose={() => toggleConfirmModal(false)}
          className="custom-confirm-modal-ingredient"
          confirmButtonTitle="Remove"
          isCloseOnDimmer={false}
          noBorder
          hasCloseIcon
          isPressEsc
        />,
      );
  };

  const handleRemoveMultipeIngredient = () => {
    if (isLoading) return;
    typeof removeMultiIngredient === 'function' && removeMultiIngredient(selectedIngredients);
  };

  const handleAddNewIngredient = () => {
    typeof onAddNewIngredient === 'function' && onAddNewIngredient();
  };

  return (
    <S.LayoutWrapper>
      <ToolBar
        onChangeQueryParams={handleChangeQuery}
        onAddNewIngredient={onAddNewIngredient}
        onAddMultiIngredient={onAddMultiIngredient}
        onRemoveMultipleIngredient={confirmRemoveMultipleIngredient}
        selectedIngredients={selectedIngredients}
        isLoading={isLoading}
        filters={filters}
        ingredientCategory={ingredientCategory}
        resetSelectedIngredient={resetSelectedIngredient}
      />

      <S.TableWrapper>
        <S.Table>
          <IngredientTableHeader
            filters={filters}
            changeQueryParams={changeQueryParams}
            total={total}
            ingredients={ingredients}
            selectedIngredient={selectedIngredient}
            selectedIngredients={selectedIngredients}
            userId={userId}
            loading={loading}
          />
          {loading && <LoadingSkeleton />}
          {!loading && total > 0 && (
            <IngredientTableBody
              ingredients={ingredients}
              toggleModal={toggleModal}
              editIngredient={editIngredient}
              cloudfrontList={cloudfrontList}
              toggleConfirmModal={toggleConfirmModal}
              ingredientCategory={ingredientCategory}
              selectedIngredient={selectedIngredient}
              selectedIngredients={selectedIngredients}
              removeMultiIngredient={removeMultiIngredient}
              userId={userId}
            />
          )}
        </S.Table>
        {!loading && total === 0 && (
          <>
            {filters.text_search ? (
              <S.NoResultWrapper>
                <S.NoResult>No results found.</S.NoResult>
                <S.AddNewButton hasSearch={filters.text_search} onClick={onAddNewIngredient}>
                  <PlusIcon className="plus-icon" />
                  <span>Add New Ingredient</span>
                </S.AddNewButton>
              </S.NoResultWrapper>
            ) : (
              <S.ContentWrapper>
                <EmptyUI onClick={handleAddNewIngredient} />
              </S.ContentWrapper>
            )}
          </>
        )}
      </S.TableWrapper>
      {total > PER_PAGE && !loading && <TableFooter className="pagination" />}
    </S.LayoutWrapper>
  );
};

export default IngredientLibrary;
