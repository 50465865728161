import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import { ReactComponent as SeeMoreIcon } from 'assets/icons/choose_workout_see_more_caret_down.svg';
import { getWorkoutSummary } from 'utils/commonFunction';

import * as S from './style';

export default function WorkoutItem({ workout, selectingId, setSelecting, renderExtendedInfo }) {
  const [hasSeeMore, setHasSeeMore] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const { title, _id } = workout;
  const { sections, exercises } = getWorkoutSummary(workout);
  const isSelecting = selectingId === _id;
  const tags = get(workout, 'tags', []);
  useEffect(() => {
    const el = document.getElementById(`tags-container-${_id}`);
    if (el) {
      if (el.offsetHeight > 70) {
        setHasSeeMore(true);
      }
      if (el.offsetHeight > 140) {
        setHasScroll(true);
      }
    }
  }, []);
  const handleSeeMore = e => {
    e.stopPropagation();
    setIsSeeMore(true);
  };
  return (
    <S.Item
      key={_id}
      onClick={() => {
        !isSelecting && setSelecting(workout);
      }}
      className={isSelecting ? 'selecting' : ''}
      hasSeeMore={hasSeeMore}
      hasScroll={hasScroll}
      isSeeMore={isSeeMore}
    >
      <div className="main-content">
        <S.WorkoutBaseInfo>
          <S.WorkoutTitle>{title}</S.WorkoutTitle>
          <S.WorkoutSummary>
            <span>
              {exercises} Exercise{exercises !== 1 ? 's' : ''}
            </span>
            {sections ? (
              <span>
                {` • `}
                {sections} Section{sections !== 1 ? 's' : ''}
              </span>
            ) : null}
          </S.WorkoutSummary>
        </S.WorkoutBaseInfo>
        {renderExtendedInfo(workout)}
      </div>
      <div className="tags-container" id={`tags-container-${_id}`}>
        {tags.map(tag => (
          <S.TagItem key={tag._id}>{tag.name}</S.TagItem>
        ))}
      </div>
      {hasSeeMore && !isSeeMore && (
        <S.SeeMore onClick={handleSeeMore}>
          <SeeMoreIcon />
          See more
        </S.SeeMore>
      )}
    </S.Item>
  );
}
