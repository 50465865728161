import styled, { css } from 'styled-components';
import { NewSearchInput } from 'shared/SearchInput';
import LoadingIndicator from 'shared/LoadingIndicator';
import IconSearch from 'assets/icons/search_icon_grey.svg';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7b7e91;
  margin-bottom: 3px;
  text-transform: uppercase;
`;

export const TriggerWrapper = styled.div`
  ${baseText}
  line-height: 36px;
  height: 36px;
  width: 100%;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  padding: 0 44px 0 14px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
  .text-placeholder {
    ${baseText}
    line-height: 36px;
    color: #a3a3b5;
  }
  &:hover {
    border: 1px solid #5158cf;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 19px;
    path {
      fill: #7b7d91;
    }
  }
  ${({ openDropdown }) =>
    openDropdown &&
    css`
      border: 1px solid #5158cf;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      background: #fffafb;
      border: 1px solid #ea314a !important;
    `}
`;

export const ListWrapper = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 2;
  visibility: hidden;

  ${({ listAmountPer }) =>
    listAmountPer &&
    css`
      width: 151px;
      left: unset;
    `}
`;

export const ListContent = styled.div`
  box-shadow: 0px 2px 8px 0px #26262633;
  padding: 10px 0 0;
  border-radius: 10px;
  background-color: #fff;
`;

export const List = styled.div`
  position: relative;
  height: 204px;
  overflow-y: scroll;
  padding: 0 3px 10px 10px;
  margin-right: 3px;
  .no-result {
    ${baseText}
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 10px;
  }
`;

export const Item = styled.div`
  ${baseText}
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
  }
  ${props =>
    props.active &&
    css`
      color: #5158cf;
      font-weight: 600;
    `}
`;

export const InputSearch = styled(NewSearchInput)`
  height: 36px;
  width: 100%;
  margin-bottom: 5px;
  padding: 0 10px;
  input {
    ${baseText}
    border: 1px solid #dadbea;
    padding: 7px 20px 7px 46px;
    background: url(${IconSearch}) no-repeat;
    background-size: 17px 16px;
    background-position: 15px center;
    background-color: transparent;
    ::placeholder {
      ${baseText}
      color: #a3a3b5;
      opacity: 0.8;
    }

    &:focus {
      border-color: #5158cf;
      background-color: #fff;
    }
  }
  svg path {
    fill: #a3a3b5;
  }
  &:hover {
    input {
      border-color: #5158cf;
    }
  }
`;

export const Loading = styled(LoadingIndicator)`
  padding-top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .ui.loader:before {
    border-color: #ededed !important;
  }
`;
