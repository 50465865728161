import React, { useMemo, memo, useEffect, useCallback, useState } from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';
import classnames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import * as S from './style';

import { convertS3UrlToCloudFrontUrl, getUserShortName, isImage } from 'utils/commonFunction';
import { CDN_URL, MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';
import RoomAction from '../RoomAction';
import { MESSAGE_TYPE, getMessageType, getPreviewIncomingMultipleMediaMessage } from 'components/Inbox/helps';
import { getContent } from '../SystemMessage';
import { ReactComponent as GroupChatIcon } from 'assets/icons/group-chat-icon.svg';

const removeMessageText = 'This message was unsent';
const systemDeletedMessageText = 'This message was deleted by the system.';

function ItemRoom(props) {
  const {
    currentRoomId,
    viewTeammate,
    user,
    room,
    onSelectRoom,
    textSearch,
    getS3presignedURLFromLocalDatabase,
    usersInfo,
    selectedTeammate,
    isGroupChatPermission,
    cloudfrontList,
  } = props;
  const {
    myData,
    parnerData,
    id,
    last_message,
    type,
    mute_until = null,
    unread = 0,
    is_archived,
    mute_status = '',
  } = room;
  const isLocalFile = get(room, 'isLocalFile', '');
  const isGroup = useMemo(() => type === 'group', [type]);
  const checkGroupInfo = useMemo(() => isGroup || viewTeammate || parnerData.error, [type, viewTeammate, parnerData]);
  const parnerAvatar = get(parnerData, 'avatar', '');
  const roomIcon = useMemo(
    () => (checkGroupInfo ? get(room, 'room_icon_thumbnail', '') || get(room, 'room_icon', '') : parnerAvatar),
    [room, checkGroupInfo, parnerAvatar],
  );
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    let ignore = false;
    if (!isLocalFile && !isEmpty(roomIcon) && roomIcon !== 'none' && isImage(roomIcon)) {
      getPresignedURLs({
        list: [roomIcon],
        ignore,
        cloudfrontList,
      });
    }
    return () => {
      ignore = true;
    };
  }, [type, roomIcon, isLocalFile, cloudfrontList]);

  const getPresignedURLs = useCallback(async ({ list, ignore, cloudfrontList }) => {
    if (ignore) return;
    let newAvt = convertS3UrlToCloudFrontUrl(list[0], cloudfrontList);
    if (!newAvt) {
      const response = await getS3presignedURLFromLocalDatabase(list);
      newAvt = response[0];
    }
    setAvatar(newAvt);
  }, []);

  const lastMessage = useMemo(() => {
    return last_message || {};
  }, [last_message]);

  const {
    sender,
    attachment_type,
    attachment,
    content,
    was_deleted,
    was_system_deleted,
    sender_info,
    reply_message,
    system_message_type,
  } = lastMessage;
  const messageType = getMessageType(lastMessage);

  const senderFirstName = useMemo(() => {
    if (isEqual(sender, get(user, '_id'))) return get(user, 'first_name');
    if (isEqual(sender, get(selectedTeammate, '_id'))) return get(selectedTeammate, 'first_name');
    return get(
      usersInfo.find(item => item._id === sender),
      'first_name',
      get(sender_info, 'first_name'),
    );
  }, [sender, usersInfo, user, selectedTeammate]);

  const { name, color, avatarName } = useMemo(() => {
    if (checkGroupInfo) {
      return {
        name: get(room, 'room_name') || get(room, 'room_sub_name'),
        color: get(room, 'room_color', ''),
        avatarName: isGroup
          ? get(room, 'total_members', 0) > 99
            ? '+99'
            : `+${get(room, 'total_members', 0)}`
          : get(room, 'room_name'),
      };
    }

    return {
      name: `${get(parnerData, 'first_name', '')} ${get(parnerData, 'last_name', '')}`,
      color: get(parnerData, 'color', ''),
      avatarName: getUserShortName(parnerData),
    };
  }, [parnerData, type, room]);

  const dateString = useMemo(() => {
    if (last_message) {
      const mDate = moment(viewTeammate ? last_message.time : last_message.timestamp);
      return mDate.isSame(moment(), 'day') ? mDate.format('hh:mm a') : mDate.format(' MMM D');
    }
    return '';
  }, [last_message]);

  const isMedia = useMemo(() => {
    return content === MEDIA_FILE_REJECTED_CONTENT && attachment;
  }, [content, attachment]);

  const mediaType = useMemo(() => {
    return (attachment_type || '').includes('video')
      ? 'video'
      : (attachment_type || '').includes('audio')
      ? 'voice note'
      : (attachment_type || '').includes('gif')
      ? 'GIF file'
      : 'photo';
  }, [attachment_type]);

  const isMultipleMedia = isEqual(messageType, MESSAGE_TYPE.MULTIPLE_MEDIA);
  const isSystemMessage = system_message_type !== undefined;
  const message = useMemo(() => {
    if (isMultipleMedia) {
      if (viewTeammate)
        return getPreviewIncomingMultipleMediaMessage({
          message: lastMessage,
          isOwner: false,
          firstName: senderFirstName,
        });
      if (checkGroupInfo) {
        return getPreviewIncomingMultipleMediaMessage({
          message: lastMessage,
          isOwner: isEqual(sender, get(user, '_id')),
          firstName: senderFirstName,
        });
      }
      return getPreviewIncomingMultipleMediaMessage({
        message: lastMessage,
        isOwner: isEqual(sender, get(myData, 'uid')),
        firstName: get(parnerData, 'first_name'),
      });
    }

    const isReplied = !isEmpty(get(lastMessage, 'reply_message_id'));
    if (viewTeammate && isReplied) {
      const senderName =
        sender === user._id ? senderFirstName : get(sender_info, 'first_name') || get(selectedTeammate, 'first_name');
      if (sender && reply_message.sender && sender !== reply_message.sender)
        return `${senderName} replied to ${get(reply_message, 'sender_info.first_name') || get(room, 'room_name')}`;
      return `${senderName} replied to themself`;
    }

    if (isSystemMessage) {
      const isOwner = sender === user._id;
      return getContent({ message: lastMessage, isOwner, userId: user._id, usersInfo, viewTeammate, textOnly: true });
    }

    if (was_deleted) return removeMessageText;
    if (was_system_deleted) return systemDeletedMessageText;
    if (isMedia) {
      if (viewTeammate) return `${senderFirstName} sent a ${mediaType}`;
      if (checkGroupInfo) return `${sender === user._id ? 'You' : senderFirstName} sent a ${mediaType}`;
      return `${sender === myData.uid ? 'You' : parnerData.first_name} sent a ${mediaType}`;
    }

    return content;
  }, [was_deleted, was_system_deleted, isMedia, content, sender, parnerData, myData, isMultipleMedia, lastMessage]);

  return (
    <S.Item
      key={id}
      id={id}
      className={classnames('inbox-item', {
        'inbox-item--unread': !viewTeammate && !!get(myData, 'unread', unread),
        'inbox-item--active': viewTeammate ? get(room, 'room_id', '') === currentRoomId : room.id === currentRoomId,
      })}
      onClick={() => onSelectRoom(room)}
    >
      <S.ItemLeftContent>
        {!isEmpty(avatar) ? (
          <img
            src={avatar}
            onError={event => {
              event.preventDefault();
              event.target.src = avatar;
            }}
            alt=""
          />
        ) : (
          <Avatar name={avatarName} size="38" src={avatar} color={color} initials={isGroup && (name => name)} />
        )}
        {isGroup ? (
          <S.GroupChatWrapper>
            <GroupChatIcon />
          </S.GroupChatWrapper>
        ) : null}
        <S.ClientInformation>
          <S.ClientName>{name}</S.ClientName>
          <S.LastMessage isMedia={isMedia} isMultipleMedia={isMultipleMedia} isDelete={was_deleted}>
            <span>{message}</span>
          </S.LastMessage>
        </S.ClientInformation>
      </S.ItemLeftContent>
      <S.ItemRightContent>
        <S.DateWrapper>
          {mute_status === 'mute' && mute_until !== null && (mute_until > Date.now() || mute_until < 0) ? (
            <img src={`${CDN_URL}/images/mute_dark.svg`} alt="" />
          ) : null}
          <S.Time>{dateString}</S.Time>
        </S.DateWrapper>
        {!viewTeammate && get(myData, 'unread', unread) ? (
          <S.NoUnread>{get(myData, 'unread', unread)}</S.NoUnread>
        ) : isGroup && !isGroupChatPermission ? null : (
          <RoomAction
            isRoom={true}
            roomId={room.id}
            readOnly={viewTeammate || is_archived}
            isGroup={isGroup}
            isMute={mute_status === 'mute' && mute_until !== null && (mute_until > Date.now() || mute_until < 0)}
            searching={textSearch && get(room, 'room_name')}
          />
        )}
      </S.ItemRightContent>
    </S.Item>
  );
}

export default memo(ItemRoom);
