import styled from 'styled-components';

export default styled.div`
  width: 420px;
  background: #ffffff;
  .forum-no-connected-banner-section {
    border: 1px solid #dadfea;
    box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
    border-radius: 8px;
  }

  .forum-no-connected-banner {
    background: #f2f6ff;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    img {
      width: 186px;
      height: auto;
      padding-top: 12px;
    }

    .text {
      align-self: center;
      margin: 0 20px 0 -40px;
      font-size: 13px;
      line-height: 150%;
    }
  }

  .forum-no-connected-action {
    text-align: center;
    padding: 20px;

    button {
      padding: 11px 20px;
    }
  }

  .hint {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    margin: 20px 0;
    display: flex;

    .text {
      margin-left: 9px;
    }
  }
`;
