import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'semantic-ui-react';
import { push } from 'connected-react-router';

import { isTeamAdmin } from 'utils/commonFunction';

import { SidebarContent } from '../../styles';

const AutomationSidebar = props => {
  const { activeItem, push, isTeamAdmin, permission } = props;

  const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

  const handleClick = event => {
    const { route } = event.currentTarget && event.currentTarget.dataset;
    push(`/home/${route}`);
  };

  return (
    <SidebarContent className="sidebar-content" hideOnMobile>
      <div className="sidebar-divide" />
      <p>Automation</p>
      <List className="menu-list">
        {(hasPermissionMultiple || isTeamAdmin) && (
          <div
            className={classnames('menu-list-item', { active: activeItem === 'onboarding-flow' })}
            data-route="onboarding-flow"
            onClick={handleClick}
          >
            <span>Onboarding Flow</span>
          </div>
        )}
        <div
          className={classnames('menu-list-item', { active: activeItem === 'autoflow' })}
          data-route="autoflow"
          onClick={handleClick}
        >
          <span>Autoflow</span>
        </div>
      </List>
    </SidebarContent>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: { permission },
  } = state;

  return { isTeamAdmin: isTeamAdmin(user), permission };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(AutomationSidebar);
