import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Modal, Image } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

function AssignClientSuccess(props) {
  const { successMessage, errorMessage, errors } = props;

  return (
    <Modal
      open={true}
      closeIcon={null}
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/checkbox_checked.svg`} />
            <span>Summary</span>
          </S.Header>
          <S.Body>
            <div>
              <div style={{ marginBottom: 10 }}>{successMessage}</div>
              {errorMessage && <div>{errorMessage}</div>}
              {errors.length ? (
                <ul style={{ paddingLeft: 30, margin: '5px 0' }}>
                  {_.map(errors, (msg, index) => (
                    <li key={index}>{msg}</li>
                  ))}
                </ul>
              ) : null}
            </div>
          </S.Body>
          <S.Footer>
            <Button green onClick={() => props.toggleModal(false)}>
              OK
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

export default connect(null, { toggleModal })(AssignClientSuccess);
