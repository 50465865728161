import React from 'react';
import map from 'lodash/map';
import Dropzone from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';
import { handleScrollToReply } from 'helpers/replyComment';
import CommentInput from './CommentInput';
import CommentReplyItem from './CommentReplyItem';
import { getReplyMessage } from 'components/CommunityForum/helper';

import * as S from './style';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      replyMessage: {},
    };

    this.inputRef = React.createRef();
    this.bodyRef = React.createRef();
  }

  componentDidMount() {
    const { callBackFunction } = this.props;
    callBackFunction && callBackFunction(this.handleCancelReply);
  }

  handleDrop = files => {
    if (files.length && this.inputRef.current) {
      typeof this.inputRef.current.onDropFiles === 'function' && this.inputRef.current.onDropFiles(files);
    }
  };

  handleReplyMessage = data => () => {
    const {
      userId,
      post: { _id },
    } = this.props;
    if (_id) {
      handleScrollToReply(_id, 500);
    }

    const newData = { ...data, userId };
    const { type, content, owner, authorId, commentId } = getReplyMessage(newData);

    this.setState({
      replyMessage: {
        type,
        content,
        owner,
        authorId,
        commentId,
      },
    });
  };

  handleCancelReply = () => {
    this.setState({
      replyMessage: {},
    });
  };

  render() {
    const { list, getNotifications, id } = this.props;
    const { replyMessage } = this.state;

    return (
      <S.Wrapper>
        <Dropzone onDrop={this.handleDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.Container {...getRootProps()}>
                {!!list.length && (
                  <S.Content ref={this.bodyRef}>
                    <S.Comments>
                      {map(list, item => {
                        const { latestReplyList } = item;
                        return (
                          <CommentReplyItem
                            item={item}
                            handleDeleteComment={this.props.onDelete}
                            uploadSuccess={this.props.onUploadSuccess}
                            cancelUpload={this.props.onCancelUpload}
                            showAttachItem={true}
                            handleReplyMessage={this.handleReplyMessage}
                            replyList={latestReplyList}
                            replyMessage={replyMessage}
                            handleViewMoreReply={this.props.handleViewMoreReply}
                          />
                        );
                      })}
                    </S.Comments>
                  </S.Content>
                )}
                <CommentInput
                  onGetNotifications={getNotifications}
                  ref={this.inputRef}
                  onPost={this.props.onAdd}
                  onSetComments={this.props.onSetComments}
                  {...this.props}
                  autoFocus
                  className={`wrapper-comment-input-${id}`}
                  selectorDetectedInput=".list-comment-forum"
                  isDragActive={isDragActive}
                  placeholder={!isEmpty(replyMessage) && 'Write a reply...'}
                  replyMessage={replyMessage}
                  onCancelReply={this.handleCancelReply}
                />
              </S.Container>
            );
          }}
        </Dropzone>
      </S.Wrapper>
    );
  }
}

export default Component;
