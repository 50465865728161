import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const DayTooltip = styled.div`
  background: #f4f5f9;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #575c77;
  position: absolute;
  transform: translate(50%, -50%);
`;

export const NoData = styled.div`
  color: #000000;
  opacity: 0.3;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  .weekly-report__chart {
    position: relative;

    .weekly-report__goal-tooltip {
      position: absolute;
      padding-left: 20px;
      transform: translateX(100%);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #202353;
      background-repeat: no-repeat;
      background-position: 5px;
      background-color: #ffffff;

      &--average-reps {
        left: -20px;
        background-color: transparent;
      }

      &--step-goal {
        right: 5px;
        background-image: url(${CDN_URL}/images/macros_training_day_bold.svg);
        background-color: transparent;
      }

      .__react_component_tooltip {
        text-align: center;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        min-width: max-content;
        padding: 5px 10px;
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 312px;

  .loading-chart-indicator {
    padding-top: 0;
  }
`;
