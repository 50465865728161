import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import * as Dropdown from './style';

export const Trigger = props => (
  <Dropdown.Trigger {...props} className={classNames('evf-dropdown__trigger', props.className)}>
    <div className="dots" />
  </Dropdown.Trigger>
);

export const Option = props => (
  <Dropdown.Option {...props} className={classNames('evf-dropdown__option', _.get(props, 'className', ''))} />
);

const OLD_SPACING_TOP = 5;
const NEW_SPACING_TOP = 8;

const Component = props => {
  const [open, setOpen] = useState(false);
  const [menuStyles, setMenuStyles] = useState({});
  const [lasItem, setLastItem] = useState(false);
  const triggerRef = useRef();
  const menuRef = useRef();
  const firstOpenRef = useRef(0);
  const {
    children,
    className,
    type,
    direction,
    disabled,
    alwaysShow,
    shouldCheckItem,
    closeDropdownRef,
    extraHeight = 0,
    overlapByOnlyIntercom = false,
    isWaitingClient = false,
    hasOverlap = false,
    hasLongOption = false,
    isNewSpacing = false,
    isMealPlanAndRecipe = false,
    onCloseDropdown,
  } = props;

  useEffect(() => {
    if (closeDropdownRef) {
      closeDropdownRef.current = () => setOpen(false);
    }
  }, []);

  useLayoutEffect(() => {
    if (alwaysShow) {
      generateMenuStyles();
    }
  }, [alwaysShow]);

  const generateMenuStyles = () => {
    if (!triggerRef.current) {
      return console.error('Can not find the trigger');
    }

    if (!menuRef.current) {
      return console.error('Can not find the menu');
    }

    // TODO - default item
    shouldCheckItem && setLastItem(false);

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();
    const { width, height, x, y } = triggerRect;
    const windowWidth = window.innerWidth,
      windowHeight = window.innerHeight;
    let left = x,
      top = y + height + (isNewSpacing ? NEW_SPACING_TOP : OLD_SPACING_TOP);
    let maxHeight = windowHeight - top - 10;
    if (left + menuRect.width > windowWidth || direction === 'left') {
      left = x + width - menuRect.width;
      if (firstOpenRef.current === 1) {
        if (isMealPlanAndRecipe && windowWidth - x < 100) {
          left = left - 41;
        } else if (hasLongOption) {
          left = left - 48;
        }
      } else {
        left = hasLongOption ? left + 32 : left;
      }
    }

    if (
      (top + menuRef.current.scrollHeight > windowHeight - (extraHeight ? extraHeight : 10) &&
        y - 15 > menuRef.current.scrollHeight) ||
      direction === 'top'
    ) {
      top = y - menuRef.current.scrollHeight - 5;
      if (firstOpenRef.current === 1) {
        top = hasLongOption ? top + 20 : top;
      }
      maxHeight = y - 15;
      // TODO - last item
      shouldCheckItem && setLastItem(true);
    }

    // Adjusts the positioning of a menu based on specified parameters.
    // Support for top and left

    const isDirectionLeftTop = direction === 'left top' || direction === 'top left';

    if (left + menuRect.width > windowWidth || isDirectionLeftTop) {
      left = x + width - menuRect.width;
      if (firstOpenRef.current === 1) {
        if (isMealPlanAndRecipe && windowWidth - x < 100) {
          left = left - 41;
        } else if (hasLongOption) {
          left = left - 48;
        }
      } else {
        left = hasLongOption ? left + 32 : left;
      }
    }

    if (
      (top + menuRef.current.scrollHeight > windowHeight - (extraHeight ? extraHeight : 10) &&
        y - 15 > menuRef.current.scrollHeight) ||
      isDirectionLeftTop
    ) {
      top = y - menuRef.current.scrollHeight - 5;
      if (firstOpenRef.current === 1) {
        top = hasLongOption ? top + 20 : top;
      }
      maxHeight = y - 15;
      // TODO - last item
      shouldCheckItem && setLastItem(true);
    }

    if (direction === 'right') {
      left = x + 25;
      top = y + height - 20;
      maxHeight = 100;
    }

    if (
      direction === 'right' &&
      top + menuRef.current.scrollHeight > windowHeight - 16 &&
      y - 15 > menuRef.current.scrollHeight
    ) {
      top = y - menuRef.current.scrollHeight + 23;
    }

    if (extraHeight > 0 && !overlapByOnlyIntercom) {
      const isOverlapX = windowWidth && windowWidth - left < 450;
      const isOverLapY = windowHeight && windowHeight - top < 200;
      if (isOverlapX && isOverLapY) {
        top = windowHeight && windowHeight - 120;
        left = windowWidth && windowWidth - 450;
      }
    }

    if (overlapByOnlyIntercom) {
      const isOverlapX = windowWidth && windowWidth - left < 180;
      const isOverLapY = windowHeight && windowHeight - top < 200;
      if (isOverlapX && isOverLapY) {
        left = windowWidth && windowWidth - 180;
        top = windowHeight && windowHeight - 120;
      }
    }

    if (hasOverlap && isWaitingClient) {
      let newLeft = triggerRect.x + triggerRect.width - menuRect.width;
      let newTop = triggerRect.y + triggerRect.height + 5;
      const newMaxHeight = 'unset';
      const isOverLapY = windowHeight && windowHeight - triggerRect.y <= 80 + menuRect.height + triggerRect.height + 5;
      if (isOverLapY && windowHeight - triggerRect.y < 80) {
        newTop = Math.max(windowHeight - 80 - menuRect.height, 0);
      } else if (isOverLapY && windowHeight - triggerRect.y >= 80) {
        newTop = Math.max(triggerRect.y - 5 - menuRect.height, 0);
      }
      setMenuStyles({ left: newLeft, top: newTop, maxHeight: newMaxHeight });
      return;
    }

    setMenuStyles({ left, top, maxHeight });
  };

  useLayoutEffect(() => {
    if (open) {
      firstOpenRef.current++;
      generateMenuStyles();
    }
  }, [open]);

  const onTriggerClick = event => {
    event.stopPropagation();

    if (disabled || alwaysShow) {
      return;
    }

    setOpen(o => !o);
    onCloseDropdown && open && onCloseDropdown();
  };

  const handleCloseDropdown = () => {
    setOpen(false);
    onCloseDropdown && onCloseDropdown();
  };

  return (
    <RootCloseWrapper disabled={!open || alwaysShow} event="click" onRootClose={handleCloseDropdown}>
      <Dropdown.Wrapper
        className={classNames('evf-dropdown evf-dropdown--basic', { open: open || alwaysShow }, type, className)}
      >
        <div ref={triggerRef} onClick={onTriggerClick} className="evf-dropdown__trigger-container">
          {typeof props.triggerIcon === 'function' ? props.triggerIcon({ open }) : <Trigger />}
        </div>
        <Dropdown.Menu
          className={classNames('evf-dropdown__menu', { last_item: shouldCheckItem && lasItem })}
          ref={menuRef}
          style={menuStyles}
        >
          {React.Children.map(
            children,
            option =>
              option &&
              React.cloneElement(option, {
                onClick: event => {
                  event.stopPropagation();
                  if (typeof option.props.onClick === 'function') {
                    option.props.onClick(event);
                  }
                  setOpen(false);
                },
              }),
          )}
        </Dropdown.Menu>
      </Dropdown.Wrapper>
    </RootCloseWrapper>
  );
};

Component.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']),
  trigger: PropTypes.any,
  className: PropTypes.string,
  direction: PropTypes.string,
};

export default Component;
