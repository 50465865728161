import React from 'react';
import { Loader } from 'semantic-ui-react';
import * as S from './style';

const Indicator = ({ title = 'Loading' }) => {
  return (
    <S.IndicatorWrapper>
      <Loader active inline="centered" className="custom-indicator" size="medium">
        <span>{title}</span>
      </Loader>
    </S.IndicatorWrapper>
  );
};

export default Indicator;
