import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentInformation from './component';
import { updatePaymentInfo } from 'redux/billing/actions';
import { toggleModal } from 'actions/modal';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  updatePaymentInfo: bindActionCreators(updatePaymentInfo, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInformation);
