import styled from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 11px;
  padding: 13px 35px;
  background-color: #ebedf4;
  text-align: center;
  .custom-icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
`;

export const ContentWrapper = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
`;

export const ButtonRequest = styled.button`
  font-weight: 700;
  color: #5559ff;
  border: none;
  background: none;
  outline: none;
  padding: unset;
  margin: unset;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &::selection {
    color: #5559ff;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    color: rgba(85, 89, 255, 0.9);
  }
`;

export const Bold = styled.span`
  display: inline-block;
  font-weight: 700;
`;
