import React from 'react';
import Filter from './ClientFilterPopup';
import { FILTER_STATUS } from '../constants';
import { FilterStyles, UpdatedFilterIcon } from './style';
import isEqual from 'lodash/isEqual';
import { SharedTooltip } from 'shared/SharedTooltip';

const FilterBtn = ({ currentSelectedFilter, onSelectedFilterChange }) => {
  const onSelectedFilterApply = data => {
    onSelectedFilterChange(data);
  };

  const initStateFilter = {
    status: FILTER_STATUS.connected.value,
    owner_ship: 1,
    groups: [],
  };

  return (
    <Filter
      currentSelectedFilter={currentSelectedFilter}
      onSelectedFilterApply={onSelectedFilterApply}
      menuHeight={180}
      trigger={
        <FilterStyles className="broadcastMessage__filter-icon" data-tip data-for="filter-client-tooltip">
          <SharedTooltip id="filter-client-tooltip" content="Filters" />
          {!isEqual(initStateFilter, currentSelectedFilter) && <UpdatedFilterIcon />}
        </FilterStyles>
      }
    />
  );
};

export default FilterBtn;
