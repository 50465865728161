import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  position: relative;
  width: 700px !important;
  max-height: 740px !important;
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .header {
    padding: 16px 24px 0 !important;
    background: #ffffff !important;
  }
  .content {
    background-color: #ffffff !important;
    max-height: 591px !important;
    height: calc(100vh - 300px) !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
  .actions {
    background-color: #ffffff !important;
    height: 77px !important;
    padding: 0 24px !important;
    border-top: 1px solid #f2f2f2 !important;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .ui.loader:after {
    border-color: #5158cf transparent transparent !important;
  }
`;

export const SurveyNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  .survey-name {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #202353;
  }
  .create-at {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #aaacae;
    margin-top: 8px;
  }
`;

export const ButtonAction = styled(Button)`
  transition: all 0.3s;
  min-width: 100px !important;
  height: 45px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-top: -1px;
  border-radius: 4px;
  :hover {
    background-color: rgba(81, 88, 207, 0.9);
  }
`;

export const InformationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  padding: 8px 8px 16px 24px;

  ${props =>
    !props.isScrollTop &&
    css`
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
    `}

  .info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .info-name {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #262626;
    }
    .info-email {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #8c8c8c;
    }
  }
`;

export const ResponseSurveyWrapper = styled.div`
  height: calc(100% - 45px);
  overflow: hidden scroll;
  padding: 0 8px 80px 24px;
  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-indicator {
      padding-top: 0 !important;
    }
  }
  .response-survey {
    padding: 16px 16px 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .response-title {
      font-family: 'Open Sans';
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px;
      letter-spacing: -0.02em;
      color: #202353;
      white-space: pre-wrap;
    }
    .response-content {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #4a4d56;
      white-space: pre-wrap;
    }
  }
`;
