import diff from 'deep-diff';
import { handleScrollToReply } from 'helpers/replyComment';
import cloneDeep from 'lodash/cloneDeep';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import { DateTime } from 'luxon';
import { handleScrollToPhoto } from '../../components/EditProgressPhoto/helper';
import { handleDeleteInPhotoList, handlePhotosInDayDeleted, handlePhotosInDayNotDeleted, Types } from './actions';

const PER_PAGE = 20;

const currentDay = DateTime.now().toFormat('MM-dd-yyyy');

export const INITIAL_STATE_PROGRESS_PHOTO = {
  compareMode: false,
  photoList: [],
  comparePhotoList: [{}, {}],
  filters: {
    no_of_day: PER_PAGE,
    day: currentDay,
    tag: null,
  },
  loading: false,
  nextDay: currentDay,
  firstLoad: false,
  itemPhoto: {
    selectedDate: '',
    selectedPhoto: '',
    selectedThumbnailPhoto: '',
    measurementId: '',
    selectedPhotoListByDate: [],
  },
  photoChangeCompare: {},
  loadingUpload: false,
  listPhotoZoom: [],
};

const formatDataSelectedPhoto = (measurementIdStore, list, selectedDate, itemPhoto, comparePhotoList, compareMode) => {
  let data = {};
  if (measurementIdStore) {
    const dayFilters = list.find(item => get(item, 'day', '') === selectedDate);
    let measurements = get(dayFilters, 'measurements', []);
    const checkListPhoto = get(measurements, 'length', 0);
    if (checkListPhoto <= 0) {
      measurements = get(list, '[0].measurements', []);
    }

    const photoFilters = measurements.find(item => get(item, '_id', '') === measurementIdStore);
    const bodyFatValue = get(photoFilters, 'other_metrics.[0].value', 0);
    const weightValue = get(photoFilters, 'value', 0);
    const weightUnit = get(photoFilters, 'unit', {});
    const tag = get(photoFilters, 'tags', []).find(
      item => get(item, 'image', '') === get(itemPhoto, 'selectedPhoto', ''),
    );

    data = {
      ...data,
      itemPhoto: {
        ...itemPhoto,
        selectedDate: checkListPhoto <= 0 ? get(list, '[0].day', '') : get(itemPhoto, 'selectedDate', ''),
        selectedPhotoListByDate: flatMap(measurements, 'tags'),
        weight: weightValue,
        bodyFat: bodyFatValue,
        tag: get(tag, 'tag', ''),
        weightUnit,
      },
    };
  } else {
    if (selectedDate) {
      const dayFilters = list.find(item => get(item, 'day', '') === selectedDate);
      if (dayFilters) {
        list = [dayFilters];
      }
    }

    const day = get(list, '[0].day', '');
    const { _id = '', value: weightValue } = get(list, '[0].measurements.[0]', {});
    const { image = '', image_thumbnail = '' } = get(list, '[0].measurements.[0].tags.[0]', {});
    const bodyFatValue = get(list, '[0].measurements.[0].other_metrics.[0].value', 0);
    const tag = get(list, '[0].measurements.[0].tags.[0].tag', '');
    const weightUnit = get(list, '[0].measurements.[0].unit', '');

    const selected = {
      selectedDate: day,
      selectedPhoto: image,
      selectedThumbnailPhoto: image_thumbnail,
      selectedPhotoListByDate: flatMap(get(list, '[0].measurements', []), 'tags'),
      measurementId: _id,
      weight: weightValue,
      bodyFat: bodyFatValue,
      tag,
      weightUnit,
    };
    comparePhotoList[0] = selected;
    data = {
      ...data,
      itemPhoto: {
        ...itemPhoto,
        ...selected,
      },
      comparePhotoList: compareMode ? comparePhotoList : [{}, {}],
    };
  }
  return data;
};

const handleChangeTag = (state, tagUpdate, dataItemPhoto) => {
  const { filters = {}, photoList = [], itemPhoto = {} } = { ...state };
  const { tag = '' } = filters;
  const { selectedDate = '', measurementId = '', selectedPhoto = '', selectedPhotoListByDate = [] } = itemPhoto;

  if (!tag) {
    // The case for filters is All
    const result = photoList.map(item => {
      if (get(item, 'day', '') === selectedDate) {
        const measurements = get(item, 'measurements', []).map(measurement => {
          if (get(measurement, '_id', '') === measurementId) {
            const tags = get(measurement, 'tags', []).map(tag => {
              if (get(tag, 'image', '') === selectedPhoto) {
                return { ...tag, tag: tagUpdate };
              }
              return tag;
            });
            return { ...measurement, tags };
          }
          return measurement;
        });
        return {
          ...item,
          measurements,
        };
      }
      return item;
    });

    // Update tag for selectedPhotoListByDate
    const resultSelectedPhotoListByDate = selectedPhotoListByDate.map(item => {
      if (get(item, 'image', '') === selectedPhoto) {
        return { ...item, tag: tagUpdate };
      }
      return item;
    });

    return {
      photoList: result,
      itemPhoto: { ...itemPhoto, tag: tagUpdate, selectedPhotoListByDate: resultSelectedPhotoListByDate },
    };
  } else {
    // The case for filters is Front/ Left/ Right/ Back

    if (tagUpdate === tag) {
      // Change tag that matches filter

      const { day = '', measurements = [] } = dataItemPhoto;
      const measurementsResult = measurements.map(measurement => {
        const measurementId = get(measurement, '_id', '');
        const weight = get(measurement, 'value', null);
        const bodyFat = get(measurement, 'other_metrics.[0].value', null);
        const unit = get(measurement, 'unit', {});
        const tagsListConvert = get(measurement, 'tags', []).map(tag => {
          return { ...tag, measurementId, weight, bodyFat, unit };
        });
        return { ...measurement, tags: tagsListConvert };
      });

      const existDay = photoList.findIndex(item => get(item, 'day', '') === day);

      if (existDay !== -1) {
        // The date of the updated photo matches the listing

        const photoListResult = photoList.map(item => {
          if (get(item, 'day', '') === day) {
            return { ...item, measurements: measurementsResult };
          }
          return item;
        });

        handleScrollToPhoto(day, selectedPhoto);

        return {
          photoList: photoListResult,
          itemPhoto: { ...itemPhoto, selectedPhotoListByDate: flatMap(measurementsResult, 'tags') },
        };
      } else {
        // The date the photo was updated does not match the listing

        photoList.unshift(dataItemPhoto);
        handleScrollToReply(day, 300, {});
        return {
          photoList: orderBy(photoList, ['day'], ['desc']),
          itemPhoto: { ...itemPhoto, selectedPhotoListByDate: flatMap(measurementsResult, 'tags'), tag: tagUpdate },
        };
      }
    } else {
      // Change tag that does not match filter

      const listPhotoByDate = selectedPhotoListByDate.filter(item => get(item, 'image', '') !== selectedPhoto);
      const dataPhotoList = handleDeleteInPhotoList(photoList, selectedDate, measurementId, selectedPhoto);

      if (get(listPhotoByDate, 'length', 0) > 0) {
        // The photos in a day have not been deleted
        const result = handlePhotosInDayNotDeleted(selectedPhotoListByDate, selectedPhoto, dataPhotoList, selectedDate);

        return {
          photoList: result.resultPhotoList,
          itemPhoto: {
            ...itemPhoto,
            selectedPhoto: get(result.nextPhotoAction, 'image', ''),
            selectedThumbnailPhoto: get(result.nextPhotoAction, 'image_thumbnail', ''),
            measurementId: get(result.nextPhotoAction, 'measurementId', ''),
            selectedPhotoListByDate: listPhotoByDate,
            weight: get(result.nextPhotoAction, 'weight', ''),
            bodyFat: get(result.nextPhotoAction, 'bodyFat', ''),
            tag: get(result.nextPhotoAction, 'tag', ''),
            weightUnit: get(result, 'nextPhotoAction.unit', {}),
          },
        };
      } else {
        // All photos in one day are deleted

        const result = handlePhotosInDayDeleted(dataPhotoList, selectedDate);
        const day = get(result, '[0].day', '');

        const { _id = '', value: weightValue, unit = {} } = get(result, '[0].measurements.[0]', {});
        const { image = '', image_thumbnail = '', tag = '' } = get(result, '[0].measurements.[0].tags.[0]', {});
        const bodyFatValue = get(result, '[0].measurements.[0].other_metrics.[0].value', 0);

        // Handle scroll to top
        handleScrollToReply(day, 300, {});

        return {
          photoList: result,
          itemPhoto: {
            ...itemPhoto,
            selectedDate: day,
            selectedPhoto: image,
            selectedThumbnailPhoto: image_thumbnail,
            measurementId: _id,
            selectedPhotoListByDate: flatMap(get(result, '[0].measurements', []), 'tags'),
            weight: weightValue,
            bodyFat: bodyFatValue,
            tag: tag,
            weightUnit: unit,
          },
        };
      }
    }
  }
};

const handleUpdateMetric = (state, payload) => {
  const { data = {}, unit = {}, measurementId, selectedDate, listPhotoByDate } = payload;

  const bodyFatValue = Number(get(data, 'other_metrics.[0].value'));
  const weightValue = Number(get(data, 'value'));

  const { itemPhoto = {}, photoList = [], comparePhotoList = [] } = state;

  const indexPhotoItem = photoList.findIndex(item => get(item, 'day', '') === get(listPhotoByDate, 'day', ''));

  if (indexPhotoItem !== -1) {
    photoList[indexPhotoItem] = listPhotoByDate;
  }

  const {
    selectedPhotoListByDate = [],
    measurementId: measurementIdStore = '',
    weight: weightStore,
    bodyFat: bodyFatStore,
  } = cloneDeep(itemPhoto);

  const photoListResult = photoList.map(photo => {
    if (get(photo, 'day', '') === selectedDate) {
      const measurements = get(photo, 'measurements', []).map(measurement => {
        if (get(measurement, '_id', '') === measurementId) {
          const { other_metrics = [] } = measurement;

          if (get(other_metrics, 'length', '') <= 0) {
            other_metrics[0] = {};
          }

          other_metrics[0].value = bodyFatValue;

          const tags = get(measurement, 'tags', []).map(tag => {
            return { ...tag, weight: weightValue, bodyFat: bodyFatValue, unit };
          });

          return { ...measurement, value: weightValue, other_metrics, unit, tags };
        }
        return measurement;
      });
      return { ...photo, measurements };
    }
    return photo;
  });

  const comparePhotoListResult = comparePhotoList.map(photo => {
    if (get(photo, 'measurementId', '') === measurementId) {
      return { ...photo, weight: weightValue, bodyFat: bodyFatValue, weightUnit: unit };
    }
    return photo;
  });

  const selectedPhotoListByDateResult = selectedPhotoListByDate.map(photo => {
    if (get(photo, 'measurementId', '') === measurementId) {
      return { ...photo, weight: weightValue, bodyFat: bodyFatValue, unit };
    }
    return photo;
  });

  return {
    photoList: photoListResult,
    comparePhotoList: comparePhotoListResult,
    itemPhoto: {
      ...itemPhoto,
      weight: measurementId === measurementIdStore ? weightValue : weightStore,
      bodyFat: measurementId === measurementIdStore ? bodyFatValue : bodyFatStore,
      selectedPhotoListByDate: selectedPhotoListByDateResult,
      weightUnit: unit,
    },
  };
};

export default (state = INITIAL_STATE_PROGRESS_PHOTO, action) => {
  const { type, payload } = action;
  const {
    compareMode,
    comparePhotoList,
    photoList,
    nextDay,
    firstLoad,
    itemPhoto,
    photoChangeCompare,
    listPhotoZoom,
  } = state;
  const {
    selectedDate,
    measurementId: measurementIdStore,
    selectedPhoto = '',
    selectedPhotoListByDate = [],
  } = itemPhoto;
  switch (type) {
    case Types.PROGRESS_PHOTO_CHANGE_MODE: {
      const listPhoto = [...comparePhotoList];
      listPhoto[0] = itemPhoto;
      return Object.assign({}, state, {
        compareMode: !compareMode,
        comparePhotoList: compareMode ? [{}, {}] : listPhoto,
        photoChangeCompare: {},
        listPhotoZoom: [],
      });
    }

    case Types.PROGRESS_PHOTO_SELECTED_PHOTO: {
      const {
        day = '',
        image = '',
        tags = [],
        measurementId = '',
        image_thumbnail = '',
        weight,
        bodyFat,
        tag = '',
        weightUnit = {},
      } = payload;
      return Object.assign({}, state, {
        itemPhoto: {
          ...itemPhoto,
          selectedDate: day,
          selectedPhoto: image,
          selectedPhotoListByDate: tags,
          measurementId,
          selectedThumbnailPhoto: image_thumbnail,
          weight,
          bodyFat,
          tag,
          weightUnit,
        },
      });
    }

    case Types.PROGRESS_PHOTO_RESET_DATA: {
      return Object.assign({}, state, { ...INITIAL_STATE_PROGRESS_PHOTO, comparePhotoList: [{}, {}] });
    }

    case Types.PROGRESS_PHOTO_UPDATE_FILTERS: {
      const { params, resetList } = payload;
      let data = {
        filters: params,
      };
      if (resetList) {
        data = { ...data, photoList: [], nextDay: currentDay };
      }
      return Object.assign({}, state, data);
    }

    case Types.PROGRESS_PHOTO_GET_LIST_REQUEST: {
      if (currentDay === nextDay) {
        return Object.assign({}, state, { loading: true });
      }
      return Object.assign({}, state);
    }

    case Types.PROGRESS_PHOTO_GET_LIST_SUCCESS: {
      const listInit = get(payload, 'list', []).map(item => {
        const measurements = get(item, 'measurements', []).map(measurement => {
          const tags = get(measurement, 'tags', []).map(tag => {
            return { ...tag, unit: get(measurement, 'unit', {}) };
          });
          return { ...measurement, tags };
        });
        return { ...item, measurements };
      });

      const list = listInit;

      // Check duplicate the photo when changing the filter quickly
      const check = diff(photoList, list);
      if (!check && get(list, 'length', 0) > 0)
        return Object.assign({}, state, {
          firstLoad: false,
          loading: false,
        });

      const nextDay = get(payload, 'nextDay', null);
      let data = {};
      const listPhoto = [...comparePhotoList];
      if (firstLoad) {
        data = formatDataSelectedPhoto(measurementIdStore, list, selectedDate, itemPhoto, listPhoto, compareMode);
      } else {
        const currentIndexPhoto = [...selectedPhotoListByDate].find(photo => get(photo, 'image', '') === selectedPhoto);
        const photoByDate = list.find(item => get(item, 'day', '') === selectedDate);
        const listPhotoByDate = flatMap(get(photoByDate, 'measurements', []), 'tags');
        const isExistPhoto = some(listPhotoByDate, currentIndexPhoto);
        let dataItemPhoto = { ...itemPhoto };
        if (get(list, 'length', 0) === PER_PAGE && get([...photoList, ...list], 'length', 0) <= PER_PAGE) {
          const dateLastOfList = DateTime.fromFormat(list[get(list, 'length', 0) - 1].day, 'MM-dd-yyyy');
          const dateSelect = DateTime.fromFormat(selectedDate, 'MM-dd-yyyy');
          if (dateSelect < dateLastOfList) {
            const dayFilters = list.find(item => get(item, 'day', '') === selectedDate);
            if (!dayFilters) {
              const { day = '', measurements = [] } = get(list, '[0]', {});
              const { tags = [], _id = '', value, unit = {}, other_metrics = [] } = get(measurements, '[0]', {});
              const { image = '', image_thumbnail = '', tag = '' } = get(tags, '[0]', {});
              const { value: valueBodyFat } = get(other_metrics, '[0]', {});
              dataItemPhoto = {
                ...dataItemPhoto,
                selectedDate: day,
                selectedPhoto: image,
                selectedThumbnailPhoto: image_thumbnail,
                measurementId: _id,
                selectedPhotoListByDate: flatMap(measurements, 'tags'),
                weight: value,
                bodyFat: valueBodyFat,
                tag,
                weightUnit: unit,
              };
            }
          } else {
            if (isExistPhoto) {
              dataItemPhoto = { ...dataItemPhoto, selectedPhotoListByDate: listPhotoByDate };
            } else {
              dataItemPhoto = { ...dataItemPhoto, selectedPhotoListByDate: [currentIndexPhoto] };
            }
          }
        } else {
          if (isExistPhoto) {
            dataItemPhoto = { ...dataItemPhoto, selectedPhotoListByDate: listPhotoByDate };
          } else {
            dataItemPhoto = { ...dataItemPhoto, selectedPhotoListByDate: [currentIndexPhoto] };
          }
        }
        data = {
          ...data,
          itemPhoto: dataItemPhoto,
        };
      }

      return Object.assign({}, state, {
        firstLoad: false,
        loading: false,
        photoList: [...photoList, ...list],
        nextDay,
        ...data,
      });
    }

    case Types.PROGRESS_PHOTO_GET_LIST_FAIL: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.PROGRESS_PHOTO_V2_UPLOAD_REQUEST:
      return Object.assign({}, state, {
        loadingUpload: true,
      });
    case Types.PROGRESS_PHOTO_V2_UPLOAD_SUCCESS:
    case Types.PROGRESS_PHOTO_V2_UPLOAD_FAILED:
      return Object.assign({}, state, {
        loadingUpload: false,
      });

    case Types.PROGRESS_PHOTO_FIRST_LOAD: {
      return Object.assign({}, state, { firstLoad: true });
    }

    case Types.PROGRESS_PHOTO_SELECTED_PHOTO_COMPARE_MODE: {
      const listPhoto = [...comparePhotoList];
      const {
        day = '',
        image = '',
        tags = [],
        measurementId = '',
        image_thumbnail = '',
        weight,
        bodyFat,
        tag = '',
        weightUnit = {},
      } = payload;
      const data = {
        selectedDate: day,
        selectedPhoto: image,
        selectedPhotoListByDate: tags,
        measurementId,
        selectedThumbnailPhoto: image_thumbnail,
        weight,
        bodyFat,
        tag,
        weightUnit,
      };
      if (!isEmpty(photoChangeCompare)) {
        const index = listPhoto.findIndex(
          item => get(item, 'selectedPhoto', '') === get(photoChangeCompare, 'selectedPhoto', ''),
        );
        listPhoto[index] = data;
      } else {
        if (!isEmpty(get(listPhoto, '[0]', {})) && image !== get(listPhoto, '[0].selectedPhoto', '')) {
          listPhoto[1] = data;
        }
        if (!isEmpty(get(listPhoto, '[1]', {})) && image !== get(listPhoto, '[1].selectedPhoto', '')) {
          listPhoto[0] = data;
        }
      }

      const listPhotoZoomResult = [...listPhotoZoom].filter(item => get(item, 'image', '') !== image);

      return Object.assign({}, state, {
        comparePhotoList: listPhoto,
        photoChangeCompare: !isEmpty(listPhoto[0]) && !isEmpty(listPhoto[1]) ? data : {},
        listPhotoZoom: listPhotoZoomResult,
      });
    }

    case Types.PROGRESS_PHOTO_CHANGE_PHOTO_COMPARE_MODE: {
      if (get(photoChangeCompare, 'selectedPhoto', '') === get(payload, 'selectedPhoto', '')) {
        return Object.assign({}, state, { photoChangeCompare: {} });
      } else {
        return Object.assign({}, state, { photoChangeCompare: payload });
      }
    }

    case Types.PROGRESS_PHOTO_DELETE_PHOTO_SUCCESS: {
      return Object.assign({}, state, payload);
    }

    case Types.PROGRESS_PHOTO_UPDATE_TAG_SUCCESS: {
      const { tag = '', dataItemPhoto = {} } = action;
      const result = handleChangeTag(state, tag, dataItemPhoto);
      return Object.assign({}, state, result);
    }

    case Types.PROGRESS_PHOTO_DRAG_DROP: {
      return Object.assign({}, state, { comparePhotoList: payload });
    }

    case Types.PROGRESS_PHOTO_REMOVE_PHOTO_COMPARE: {
      const listPhoto = [...comparePhotoList];

      const data = listPhoto.find(item => get(item, 'selectedPhoto', '') !== payload);

      return Object.assign({}, state, { comparePhotoList: [data, {}], photoChangeCompare: {} });
    }

    case Types.PROGRESS_PHOTO_UPDATE_INITIAL_FILTER: {
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          day: DateTime.now().setZone(payload).toFormat('MM-dd-yyyy'),
        },
      });
    }

    case Types.PROGRESS_PHOTO_UPDATE_METRIC_SUCCESS: {
      const { payload } = action;
      const result = handleUpdateMetric(cloneDeep(state), payload);
      return Object.assign({}, state, result);
    }

    case Types.PROGRESS_PHOTO_UPDATE_PHOTO_ZOOM: {
      const { selectedPhoto, value } = action.payload;

      const updatedListPhoto = [...state.listPhotoZoom].map(item => {
        if (get(item, 'image', '') === selectedPhoto) {
          return { ...item, scale: value };
        }
        return item;
      });

      const checkExist = updatedListPhoto.findIndex(item => get(item, 'image', '') === selectedPhoto);
      if (checkExist === -1) {
        updatedListPhoto.push({ image: selectedPhoto, scale: value });
      }

      return Object.assign({}, state, { listPhotoZoom: updatedListPhoto });
    }

    case Types.PROGRESS_PHOTO_OPEN_ZOOM_SETTING: {
      const { isOpenZoomSettingItem, selectedPhoto } = action.payload;
      const comparePhotoListUpdate = [...state.comparePhotoList].map(item => {
        if (get(item, 'selectedPhoto', '') === selectedPhoto) {
          return { ...item, isOpenZoomSettingItem };
        }
        return item;
      });

      return Object.assign({}, state, {
        comparePhotoList: comparePhotoListUpdate,
      });
    }

    case Types.PROGRESS_PHOTO_RESET_CHANGE_PHOTO_COMPARE_MODE: {
      return Object.assign({}, state, {
        photoChangeCompare: {},
      });
    }

    default:
      return state;
  }
};
