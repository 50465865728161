import React from 'react';
import * as S from './style';
import CustomBrandingStatusBar from 'shared/CustomBrandingStatusBar';
import CustomBrandingFooter from 'shared/CustomBrandingFooter';
import foodJournal from 'assets/images/food-journal.png';
import foodJournalPasta from 'assets/images/food-journal-pasta.png';
import foodJournalDefault from 'assets/images/food-journal-default.png';
import { ReactComponent as TickIcon } from 'assets/icons/mobile-preview-tick.svg';

export default props => {
  const { primaryColor, secondaryColor } = props;
  return (
    <S.Wrapper {...props}>
      <CustomBrandingStatusBar />

      <S.Header>
        <div className="hello">
          <span>Coaching</span>
        </div>
      </S.Header>
      <S.Content>
        <div className="let-do-this">
          <span className="bold">Training</span> (this week)
        </div>

        <S.Calendar>
          <S.CalenarRow>
            <div className="item">M</div>
            <div className="item">T</div>
            <div className="item">W</div>
            <div className="item">T</div>
            <div className="item">F</div>
            <div className="item active">S</div>
            <div className="item">S</div>
          </S.CalenarRow>

          <S.CalenarRow>
            <div className="item num">20</div>
            <div className="item num missed">21</div>
            <div className="item num">22</div>
            <div className="item num missed">23</div>
            <div className="item num checked">
              <TickIcon />
            </div>
            <div className="item num active">25</div>
            <div className="item num">26</div>
          </S.CalenarRow>

          <S.TaskDivide />
          <div className="task__footer">You missed 2 workout so far</div>
        </S.Calendar>

        <S.Task>
          <div className="task__header">
            <span className="bold">Tasks</span> (last 7 days)
          </div>
          <S.TaskItem>
            <S.TaskName>You completed 2 out of 7</S.TaskName>
            <svg width="42" height="5" viewBox="0 0 42 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.607422" y="0.60791" width="40.9021" height="4.096" rx="2.048" fill="#ECECF1" />
              <rect x="0.607422" y="0.60791" width="12.4121" height="4.096" rx="2.048" fill={primaryColor} />
            </svg>
          </S.TaskItem>
        </S.Task>

        <S.MenuFood>
          <div className="task__header">Food Journal</div>
          <div className="task__slider">
            <div className="item">
              <span className="days bold">Today</span>
              <div>
                <img src={foodJournal} />
                <div className="number">
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.0800781"
                      y="0.864014"
                      width="12.8"
                      height="12.8"
                      rx="6.28364"
                      fill={primaryColor}
                      stroke="white"
                      stroke-width="1.024"
                    />
                  </svg>
                  <span className="text">2</span>
                </div>
              </div>
            </div>
            <div className="item">
              <span className="days">Thu</span>
              <div>
                <img src={foodJournalPasta} />
                <div className="number">
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.0800781"
                      y="0.864014"
                      width="12.8"
                      height="12.8"
                      rx="6.28364"
                      fill={primaryColor}
                      stroke="white"
                      stroke-width="1.024"
                    />
                  </svg>
                  <span className="text">1</span>
                </div>
              </div>
            </div>

            <div className="item">
              <span className="days">Wed</span>
              <div>
                <img src={foodJournalDefault} />
              </div>
            </div>

            <div className="item">
              <span className="days">Tue</span>
              <div>
                <img src={foodJournalPasta} />
              </div>
            </div>
          </div>
        </S.MenuFood>
      </S.Content>

      <CustomBrandingFooter active={1} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} />
    </S.Wrapper>
  );
};
