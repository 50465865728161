// Libs
import React, { useMemo } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';

// Utils
import { mongoObjectId } from 'utils/commonFunction';

// Components
import StepInstructionItem from '../StepInstructionItem';

// Helper
import { extractUrlPresigned } from 'components/RecipeDetail/helper';

import * as S from './style';

const StepInstruction = props => {
  const {
    PrepareInstructions,
    CookingInstructions,
    handleUpdateStepInstruction = () => {},
    tab,
    setUploadLoading,
    isLoading,
    isSubmitted,
    isSubmittedPublish,
  } = props;

  const filterCookingInstructions = CookingInstructions.filter(item => {
    const { content, attachments } = item || {};
    const check = content || !isEmpty(attachments);
    return check;
  });

  const filterPreparationInstructions = PrepareInstructions.filter(item => {
    const { content, attachments } = item || {};
    const check = content || !isEmpty(attachments);
    return check;
  });

  const steps = useMemo(() => {
    if (get(get(tab, 'key', '') === 'preparation' ? PrepareInstructions : CookingInstructions, 'length', 0) > 0) {
      return get(tab, 'key', '') === 'preparation' ? PrepareInstructions : CookingInstructions;
    }
    return [{ _id: mongoObjectId(), content: '', attachments: [] }];
  }, [PrepareInstructions, CookingInstructions, get(tab, 'key', '')]);
  const handleAddStep = () => {
    handleUpdateStepInstruction([...steps, { _id: mongoObjectId(), content: '', attachments: [] }]);
  };

  const handleChangeContent = (value, id) => {
    const result = steps.map(item => {
      if (get(item, '_id', '') === id) {
        return { ...item, content: value };
      }
      return item;
    });
    handleUpdateStepInstruction(result);
  };

  const handleRemoveStep = id => {
    const result = steps.filter(item => get(item, '_id', '') !== id);
    handleUpdateStepInstruction(result);
  };

  const handleUploadImgSuccess = (dimension, fileInfo, uploadConfigs, id) => {
    const { width, height } = dimension;
    const { mimetype } = fileInfo;
    const { url } = uploadConfigs;

    const result = steps.map(item => {
      if (get(item, '_id', '') === id) {
        return {
          ...item,
          attachments: [
            {
              attachment: extractUrlPresigned(url),
              attachment_origin: extractUrlPresigned(url),
              attachment_width: width,
              attachment_height: height,
              attachment_type: mimetype,
            },
          ],
        };
      }
      return item;
    });
    handleUpdateStepInstruction(result);
  };

  const handleRemoveUploadImg = id => {
    const result = steps.map(item => {
      if (get(item, '_id', '') === id) {
        return {
          ...item,
          attachments: [],
        };
      }
      return item;
    });
    handleUpdateStepInstruction(result);
  };

  return (
    <S.StepInstructionWrapper>
      <S.List>
        {steps.map((item, index) => {
          const { content, attachments, _id } = item;
          const image = get(attachments, '[0].attachment_origin', '');
          const idx = index + 1;

          const checkValid = !isEmpty(attachments) && !content;

          const isCookingStepValid =
            isEmpty(filterCookingInstructions) &&
            isEmpty(filterPreparationInstructions) &&
            ['cooking', 'preparation'].includes(get(tab, 'key', '')) &&
            idx === 1 &&
            isSubmittedPublish;

          const isCookingStepValidEmpty = checkValid && (isSubmitted || isSubmittedPublish);

          const placeholder =
            get(tab, 'key', '') === 'cooking' ? 'Add cooking instruction' : 'Add preparation instruction';

          const errorMsgEmpty =
            (tab && tab.key) === 'cooking' ? 'Please add cooking instruction.' : 'Please add preparation instruction.';

          return (
            <StepInstructionItem
              lengthStep={get(steps, 'length', 0)}
              index={idx}
              key={_id}
              id={_id}
              image={image}
              content={content}
              handleChangeContent={debounce(handleChangeContent, 300)}
              handleRemoveStep={handleRemoveStep}
              handleUploadImgSuccess={(dimension, fileInfo, uploadConfigs) =>
                handleUploadImgSuccess(dimension, fileInfo, uploadConfigs, _id)
              }
              handleRemoveUploadImg={() => handleRemoveUploadImg(_id)}
              setUploadLoading={setUploadLoading}
              isLoading={isLoading}
              hasError={isCookingStepValidEmpty || isCookingStepValid}
              isCookingStepValid={isCookingStepValid}
              isCookingStepValidEmpty={isCookingStepValidEmpty}
              placeholder={placeholder}
              errorMsgEmpty={errorMsgEmpty}
            />
          );
        })}
      </S.List>
      <S.ButtonAdd onClick={handleAddStep}>
        <PlusIcon />
        Add step
      </S.ButtonAdd>
    </S.StepInstructionWrapper>
  );
};

export default StepInstruction;
