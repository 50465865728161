import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export default styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  cursor: pointer;

  ${props =>
    props.isVideo &&
    css`
      background: rgba(0, 0, 0, 0.5) url(${CDN_URL}/images/play_icon_white.svg) no-repeat center;
      background-size: auto;
    `}

  ${props =>
    props.thumbnail &&
    css`
      width: 283px;
      height: 159px;
    `}
`;
