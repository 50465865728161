/**
 * @flow
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import WorkoutLibrary from "components/WorkoutLibrary";

type Props = {
  dispatch: Dispatch,
};

export default class WorkoutLibraryContainer extends React.Component<Props> {
  state = {
    openingPopup: "",
  };

  render() {
    return (
      <Switch>
        <Route exact path="/home/workout/" render={(props) => <WorkoutLibrary {...props} />} />
        <Route exact path="/home/workout/:workoutId?/detail" render={(props) => <WorkoutLibrary {...props} mode="update_workout" />} />
        <Route exact path="/home/workout/:workoutId?/clone" render={(props) => <WorkoutLibrary {...props} mode="clone_workout" />} />
      </Switch>
    );
  }
}
