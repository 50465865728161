import moment from 'moment';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import { toast } from 'react-toastify';

import Request from 'configs/request';
import {
  PACKAGE_ANALYTICS_INVOICE_SORT_BY,
  PACKAGE_ANALYTICS_VIEW_TYPE,
  PACKAGE_ANALYTICS_SORT_BY,
  PURCHASE_STATUS,
  RESUME_TYPE,
  PACKAGE_PRICING_TYPES,
} from 'constants/commonData';
import { omitEmptyRequestParams } from 'utils/commonFunction';

export const Types = {
  PACKAGE_ANALYTICS_CHANGE_QUERY_PARAMS: 'PACKAGE_ANALYTICS_CHANGE_QUERY_PARAMS',
  PACKAGE_ANALYTICS_GET_PURCHASE_LIST: 'PACKAGE_ANALYTICS_GET_PURCHASE_LIST',
  PACKAGE_ANALYTICS_GET_PURCHASE_LIST_SUCCESSFULLY: 'PACKAGE_ANALYTICS_GET_PURCHASE_LIST_SUCCESSFULLY',
  PACKAGE_ANALYTICS_GET_PURCHASE_LIST_ERROR: 'PACKAGE_ANALYTICS_GET_PURCHASE_LIST_ERROR',
  PACKAGE_ANALYTICS_GET_INVOICE_LIST: 'PACKAGE_ANALYTICS_GET_INVOICE_LIST',
  PACKAGE_ANALYTICS_GET_INVOICE_LIST_SUCCESSFULLY: 'PACKAGE_ANALYTICS_GET_INVOICE_LIST_SUCCESSFULLY',
  PACKAGE_ANALYTICS_GET_INVOICE_LIST_ERROR: 'PACKAGE_ANALYTICS_GET_INVOICE_LIST_ERROR',
  PACKAGE_ANALYTICS_HIDE_BANNER: 'PACKAGE_ANALYTICS_HIDE_BANNER',
  PACKAGE_ANALYTICS_RESET_DATA: 'PACKAGE_ANALYTICS_RESET_DATA',
  PACKAGE_ANALYTICS_COUNT_PURCHASES: 'PACKAGE_ANALYTICS_COUNT_PURCHASES',
  PAYMENT_CANCEL_SUBSCRIPTION_SUCCESSFULLY: 'PAYMENT_CANCEL_SUBSCRIPTION_SUCCESSFULLY',
  PACKAGE_ANALYTICS_GET_STATISTICS_REQUEST: 'PACKAGE_ANALYTICS_GET_STATISTICS_REQUEST',
  PACKAGE_ANALYTICS_GET_STATISTICS_SUCCESSFULLY: 'PACKAGE_ANALYTICS_GET_STATISTICS_SUCCESSFULLY',
  PACKAGE_ANALYTICS_GET_PURCHASES_OF_CLIENT_SUCCESSFULLY: 'PACKAGE_ANALYTICS_GET_PURCHASES_OF_CLIENT_SUCCESSFULLY',
  PACKAGE_ANALYTICS_GET_INVOICES_PACKAGE_CLIENT_SUCCESSFULLY:
    'PACKAGE_ANALYTICS_GET_INVOICES_PACKAGE_CLIENT_SUCCESSFULLY',
  PACKAGE_ANALYTICS_REFUND_INVOICE_REQUEST: 'PACKAGE_ANALYTICS_REFUND_INVOICE_REQUEST',
  PACKAGE_ANALYTICS_REFUND_INVOICE_SUCCESSFULLY: 'PACKAGE_ANALYTICS_REFUND_INVOICE_SUCCESSFULLY',
  PACKAGE_ANALYTICS_REFUND_INVOICE_FAIL: 'PACKAGE_ANALYTICS_REFUND_INVOICE_FAIL',
  PACKAGE_ANALYTICS_PAUSE_RECURRING_REQUEST: 'PACKAGE_ANALYTICS_PAUSE_RECURRING_REQUEST',
  PACKAGE_ANALYTICS_PAUSE_RECURRING_SUCCESSFULLY: 'PACKAGE_ANALYTICS_PAUSE_RECURRING_SUCCESSFULLY',
  PACKAGE_ANALYTICS_PAUSE_RECURRING_FAIL: 'PACKAGE_ANALYTICS_PAUSE_RECURRING_FAIL',
  PACKAGE_ANALYTICS_RESUME_RECURRING_REQUEST: 'PACKAGE_ANALYTICS_RESUME_RECURRING_REQUEST',
  PACKAGE_ANALYTICS_RESUME_RECURRING_SUCCESSFULLY: 'PACKAGE_ANALYTICS_RESUME_RECURRING_SUCCESSFULLY',
  PACKAGE_ANALYTICS_RESUME_RECURRING_FAIL: 'PACKAGE_ANALYTICS_RESUME_RECURRING_FAIL',
};

const DATE_FORMAT = 'MMMM D, YYYY';

const getFullNameClient = item => {
  return get(item, 'customer.full_name') || get(item, 'client.full_name');
};

export const getPurchaseList = (params, isMP) => {
  const platforms = isMP ? ['marketplace'] : ['everfit'];
  return Request.get(
    { url: '/api/payment/purchases/list/by-package', params: omitEmptyRequestParams({ ...params, platforms }) },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({
        type: Types.PACKAGE_ANALYTICS_GET_PURCHASE_LIST_SUCCESSFULLY,
        payload: { list: data.data, total: data.total },
      });
    },
  );
};

export const getInvoiceList = (params, isMP) => {
  const platforms = isMP ? ['marketplace'] : ['everfit'];
  return Request.get(
    { url: '/api/payment/invoices', params: omitEmptyRequestParams({ ...params, platforms }) },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({
        type: params.isInvoiceOfPackage
          ? Types.PACKAGE_ANALYTICS_GET_INVOICES_PACKAGE_CLIENT_SUCCESSFULLY
          : Types.PACKAGE_ANALYTICS_GET_INVOICE_LIST_SUCCESSFULLY,
        payload: { list: data.list_invoices.data, total: data.list_invoices.total, total_paid: data.total_paid },
      });
    },
  );
};

const getQueryParams = (type, sort) => {
  if (type === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE && !!sort) {
    return PACKAGE_ANALYTICS_SORT_BY[sort.toUpperCase()] ? sort : '';
  }
  return !!sort && PACKAGE_ANALYTICS_INVOICE_SORT_BY[sort.toUpperCase()] ? sort : '';
};

export const changeQueryParams = (params, viewType, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageAnalytics },
    } = getState();

    const currentViewType = viewType || packageAnalytics.get('viewType');
    const currentPackage = packageAnalytics.get('query').toJS().packageId || params.packageId;
    const query = omitEmptyRequestParams({
      ...packageAnalytics.get('query').toJS(),
      ...params,
      sort: getQueryParams(currentViewType, get(params, 'sort', '')),
    });
    if (!currentPackage) {
      return;
    }

    dispatch({ type: Types.PACKAGE_ANALYTICS_CHANGE_QUERY_PARAMS, payload: { query, viewType: currentViewType } });
    if (currentViewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE) {
      dispatch(getPurchaseList(query, isMP));
    } else {
      const status = params.status === 'all_invoices' || query.status === 'all_invoices' ? 'status' : '';
      dispatch(
        getInvoiceList(
          {
            package_id: currentPackage,
            ...omit(query, ['packageId', status]),
          },
          isMP,
        ),
      );
    }
  };
};

export const resetPackageAnalyticsData = () => ({ type: Types.PACKAGE_ANALYTICS_RESET_DATA });

export const countPackages = (params, isMP) => {
  return dispatch => {
    if (isNil(params.id)) return;
    return dispatch(
      Request.get({ url: `/api/payment/packages/${params.id}/count-statistic` }, true, (response, { dispatch }) => {
        const { data } = response.data;
        dispatch({ type: Types.PACKAGE_ANALYTICS_COUNT_PURCHASES, payload: data });
      }),
    );
  };
};

export const getPurchaseDetail = (params, isMP) => {
  return Request.get({ url: `/api/payment/purchases/${params.id}/get-by-trainer` }, true);
};

export const cancelSubscription = (data, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageAnalytics },
    } = getState();
    const currentViewType = packageAnalytics.get('viewType');
    const query = packageAnalytics.get('query').toJS();
    const purchases = packageAnalytics.get('purchases').toJS();
    const idPurchase = get(data, 'id', '');
    const detailPurchase = purchases.find(purchase => get(purchase, 'id') === idPurchase);
    const status = detailPurchase && get(detailPurchase, 'status', '').toLowerCase();
    const pricingType = detailPurchase && get(detailPurchase, 'price.type', '');
    const fullNameCustomer = detailPurchase && get(detailPurchase, 'customer.full_name', '');

    dispatch(
      Request.post({ url: `/api/payment/purchases/${data.id}/cancel`, data }, true, (response, { dispatch }) => {
        if (status === PURCHASE_STATUS.TRIAL) {
          toast(`${fullNameCustomer}’s trial has been marked as Cancelled`);
        } else if (status === PURCHASE_STATUS.ACTIVE) {
          if (pricingType === get(PACKAGE_PRICING_TYPES, 'one_time.value', '')) {
            toast(`${fullNameCustomer}’s purchase has been marked as Cancelled`);
          } else if (pricingType === get(PACKAGE_PRICING_TYPES, 'recurring.value', '')) {
            toast(`${fullNameCustomer}’s subscription has been marked as Cancelled`);
          }
        }
        currentViewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE && dispatch(getPurchaseList(query, isMP));
        dispatch(countPackages({ id: query.packageId }, isMP));
      }),
    );
  };
};

export const getPurchasesByClient = params => {
  return Request.get({ url: `/api/payment/purchases/list/of-client`, params }, true);
};

export const getPackageStatistics = (params, isMP) => {
  const platforms = isMP ? ['marketplace'] : ['everfit'];
  return Request.get(
    { url: '/api/payment/packages-statistics', params: { ...params, platforms } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({
        type: Types.PACKAGE_ANALYTICS_GET_STATISTICS_SUCCESSFULLY,
        payload: data,
      });
    },
  );
};

export const resetChartData = () => ({ type: Types.PACKAGE_ANALYTICS_GET_STATISTICS_REQUEST });

export const getPurchasesOfClient = (params, platforms) => {
  return Request.get(
    { url: '/api/payment/purchases/list/of-client', params: { ...params, platforms } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({
        type: Types.PACKAGE_ANALYTICS_GET_PURCHASES_OF_CLIENT_SUCCESSFULLY,
        payload: data,
      });
    },
  );
};

export const undoCancel = (params, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageAnalytics },
    } = getState();
    const query = packageAnalytics.get('query').toJS();
    const currentViewType = packageAnalytics.get('viewType');
    dispatch(
      Request.post({ url: `/api/payment/purchases/${params.id}/undo-cancel` }, true, (response, { dispatch }) => {
        dispatch(getPurchaseDetail(params, isMP));
        currentViewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE && dispatch(getPurchaseList(query, isMP));
      }),
    );
  };
};

export const cancelInvoice = (data, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageAnalytics },
    } = getState();
    const query = packageAnalytics.get('query').toJS();
    const currentPackage = packageAnalytics.get('query').toJS().packageId;
    const status = query.status === 'all_invoices' ? 'status' : '';
    dispatch(
      Request.post({ url: `/api/payment/invoices/${data.id}/cancel` }, true, (response, { dispatch }) => {
        dispatch(
          getInvoiceList(
            {
              package_id: currentPackage,
              ...omit(query, ['packageId', status]),
            },
            isMP,
          ),
        );
        dispatch(countPackages({ id: currentPackage }, isMP));
      }),
    );
  };
};

export const retryCharge = (data, isMP) => {
  if (data.isTrial) {
    return Request.post({ url: `/api/payment/purchases/${data.id}/retry-trial?invoice=${data.invoice}` }, true);
  }

  return Request.post({ url: `/api/payment/purchases/${data.id}/retry?invoice=${data.invoice}` }, true);
};

export const refundInvoice = (data, onSuccess, onFailed) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_ANALYTICS_REFUND_INVOICE_REQUEST });

    dispatch(
      Request.post(
        { url: `/api/payment/refund`, data: data },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          onSuccess && onSuccess();
          dispatch({ type: Types.PACKAGE_ANALYTICS_REFUND_INVOICE_SUCCESSFULLY, payload: data });
        },
        err => {
          onFailed && onFailed(err);
          dispatch({ type: Types.PACKAGE_ANALYTICS_REFUND_INVOICE_FAIL });
        },
        false,
      ),
    );
  };
};

export const pauseSubscription = (item, pauseDate, callback, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_ANALYTICS_PAUSE_RECURRING_REQUEST });

    const data = {
      resume_at: moment(pauseDate),
    };

    dispatch(
      Request.post(
        { url: `/api/payment/purchases/${item.id}/pause`, data: data },
        true,
        (response, { dispatch }) => {
          dispatch({
            type: Types.PACKAGE_ANALYTICS_PAUSE_RECURRING_SUCCESSFULLY,
            payload: { data: { ...item, status: PURCHASE_STATUS.PAUSED.toUpperCase(), resume_day: moment(pauseDate) } },
          });
          toast(
            `${getFullNameClient(item)}'s Subscription has been paused until ${moment(pauseDate).format(DATE_FORMAT)}`,
          );
          callback && callback();
        },
        err => {
          dispatch({ type: Types.PACKAGE_ANALYTICS_PAUSE_RECURRING_FAIL });
        },
      ),
    );
  };
};

export const resumeSubscription = (item, type, resumeDate, callback, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_ANALYTICS_RESUME_RECURRING_REQUEST });

    const data = {
      resume_at: resumeDate,
      type: type,
    };
    const currentStatus = type === RESUME_TYPE.IMMEDIATELY ? PURCHASE_STATUS.ACTIVE : PURCHASE_STATUS.PAUSED;
    const currentResume = type === RESUME_TYPE.IMMEDIATELY ? null : moment(resumeDate);

    dispatch(
      Request.post(
        { url: `/api/payment/purchases/${item.id}/resume`, data: data },
        true,
        (response, { dispatch }) => {
          dispatch({
            type: Types.PACKAGE_ANALYTICS_RESUME_RECURRING_SUCCESSFULLY,
            payload: { data: { ...item, status: currentStatus.toUpperCase(), resume_day: currentResume } },
          });
          if (type === RESUME_TYPE.IMMEDIATELY) {
            toast(`${getFullNameClient(item)}'s Subscription has been resumed and charged`);
          } else {
            toast(
              `${getFullNameClient(item)}'s Subscription will be resumed on ${moment(resumeDate).format(DATE_FORMAT)}`,
            );
          }
          callback && callback();
          dispatch(changeQueryParams({}, undefined, isMP));
        },
        err => {
          dispatch({ type: Types.PACKAGE_ANALYTICS_RESUME_RECURRING_FAIL });
        },
      ),
    );
  };
};
