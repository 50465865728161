import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { PaymentMethodWrapper, CardInfoWrapper, Card, PaymentMethodContainer, ExpiredLabel } from './style';
import { CARD_BRAND, CDN_URL } from 'constants/commonData';

class PaymentMethod extends React.Component {
  state = {};

  checkCardExpired = paymentInfo => {
    if (!paymentInfo || !paymentInfo.payment_method_id) {
      return false;
    }
    const expiredDate = moment()
      .month(paymentInfo.exp_month - 1) // Months are zero indexed, so January is month 0.
      .year(paymentInfo.exp_year)
      .endOf('month');
    return moment().isAfter(expiredDate);
  };

  render() {
    const { billing, paymentInfo, toggleChangeCard, isChangeCard } = this.props;
    const cardBrand = CARD_BRAND[paymentInfo.brand] || {};
    const isExpiredCard = this.checkCardExpired(paymentInfo);

    if (!billing.isInited) {
      return <div />;
    }

    return (
      <PaymentMethodWrapper className={this.props.className}>
        <PaymentMethodContainer>
          <div class="paymentMethod__heading">
            {isChangeCard && (
              <img src={`${CDN_URL}/images/arrow_back_purple.svg`} onClick={() => toggleChangeCard(false)} alt="" />
            )}
          </div>
          <div>
            {paymentInfo.payment_method_id ? (
              <CardInfoWrapper logo={cardBrand.logo}>
                <Card>
                  <div>
                    <span className="card-name">{cardBrand.name}</span> ending in&nbsp;
                    <strong className="sBold">{paymentInfo.last4}</strong>
                    {isExpiredCard && <ExpiredLabel>Expired</ExpiredLabel>}
                  </div>
                  <div className="expires">
                    Expires: {`0${paymentInfo.exp_month}`.slice(-2)}/{paymentInfo.exp_year}
                  </div>
                </Card>
              </CardInfoWrapper>
            ) : null}
            <button className="paymentMethod__changeMethodBtn" onClick={() => toggleChangeCard(true)}>
              <span>+ Change Payment Method</span>
            </button>
          </div>
        </PaymentMethodContainer>
      </PaymentMethodWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    paymentInfo: rootReducer.billing.paymentInfo,
    billing: rootReducer.billing,
    is_payment_success: rootReducer.payment.is_payment_success,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
