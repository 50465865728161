import React, { useState, useEffect, useRef } from 'react';
import Select from './Select';
import _ from 'lodash';
import Options from './options';
import { isMobile } from 'react-device-detect';
import { addResponsiveMetaTag } from 'utils/event-api';
import NotACoachModal from './NotACoachModal';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const ANSWER_LENGTH = 3;

const Questionnaire = props => {
  const { isSkipDirectTraffic, logout } = props;
  const [answers, setAnswers] = useState([null]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isGettingQuestion, setIsGettingQuestion] = useState(false);
  const timeoutIdRef = useRef(null);

  const handleOpenModalNotACoach = () => {
    setOpenModal(true);

    const params = { not_a_coach: true, is_click_next: false };
    _.forEach(answers.slice(1), (item, index) => {
      params[`q_${index === 2 ? 4 : index + 1}`] = item.label;
    });

    setSubmitting(true);
    props.submitQuestionnaire(params);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateFillOptionBefore = () => {
    setIsGettingQuestion(true);
    props
      .getProfileTempOnboardingQuestionnaire()
      .then(res => {
        const data = _.get(res, 'data.data', {});
        const questionsKeys = ['q_1', 'q_2', 'q_4'];
        const filledData = questionsKeys.reduce((acc, curr, index) => {
          const foundItem = Options[`q${index === 2 ? index + 2 : index + 1}`].find(
            findItem => findItem.label === data[curr],
          );
          if (!foundItem) return acc;
          acc.push({
            label: data[curr] || '',
            key: foundItem.key || '',
            selectedLabel: (foundItem || {}).selectedLabel || '',
          });

          return acc;
        }, []);

        setAnswers([...answers, ...filledData]);
      })
      .finally(() => {
        setIsGettingQuestion(false);
      });
  };

  useEffect(() => {
    props.getClientsFullData(false, { page: 1, search: '' });
    handleUpdateFillOptionBefore();
    if (isMobile) {
      addResponsiveMetaTag();
    }
  }, []);

  useEffect(() => {
    const isIOSDevice = /iPad|iPhone/.test(navigator.userAgent);
    const actionString = isIOSDevice ? 'pagehide' : 'beforeunload';

    const handleLogout = () => {
      logout();
    };

    if (openModal) {
      window.addEventListener(actionString, handleLogout);
    }

    return () => {
      window.removeEventListener(actionString, handleLogout);
    };
  }, [openModal]);

  const onSelectAnswer = (value, index) => {
    const newAnswers = answers.slice();
    newAnswers[index] = value;
    setAnswers(newAnswers);
    const params = {
      is_click_next: false,
    };
    _.forEach(newAnswers.slice(1), (item, index) => {
      params[`q_${index === 2 ? 4 : index + 1}`] = item.label;
    });

    const submitTempQuestion = () => props.submitQuestionnaire(params);

    if (window.Cypress) {
      timeoutIdRef.current = setTimeout(submitTempQuestion, 2000);
    } else {
      submitTempQuestion();
    }
  };

  const onSubmit = () => {
    clearTimeout(timeoutIdRef.current);
    if (isSubmitting || answers.length <= ANSWER_LENGTH || !!_.find(answers.slice(1), a => !a)) {
      return false;
    }

    const params = {
      skip_direct_traffic: isSkipDirectTraffic,
      is_click_next: true,
    };

    _.forEach(answers.slice(1), (item, index) => {
      params[`q_${index === 2 ? 4 : index + 1}`] = item.label;
    });

    setSubmitting(true);
    props
      .submitQuestionnaire(params)
      .then(() => {
        setSubmitting(false);
        props.onFinished();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const renderFirstQuestion = () => {
    return (
      <S.QuestionContainer className="questionaire">
        <span>I am a</span>
        <Select
          options={Options.q1}
          value={answers[1]}
          onSelect={option => onSelectAnswer(option, 1)}
          autoOpen={!answers[1] && !isGettingQuestion}
        />
        <span>.</span>
      </S.QuestionContainer>
    );
  };

  const renderSecondQuestion = () => {
    if (!answers[1]) {
      return null;
    }

    const key = answers[2] ? 'q_2' : answers[1].key;

    return (
      <S.QuestionContainer className="questionaire">
        <span>I work at</span>
        <Select
          options={Options.q2}
          value={answers[2]}
          onSelect={option => onSelectAnswer(option, 2)}
          autoOpen={!answers[2] && !isGettingQuestion}
        />
        <span>.</span>
      </S.QuestionContainer>
    );
  };

  const missingData = answers.length <= ANSWER_LENGTH || !!_.find(answers.slice(1), a => !a);

  const renderThirdQuestion = () => {
    if (!answers[2]) {
      return null;
    }

    const key = 'q_4';

    return (
      <S.QuestionContainer className="questionaire">
        <span>I have</span>
        <Select
          options={Options.q4}
          value={answers[3]}
          onSelect={option => onSelectAnswer(option, 3)}
          autoOpen={!answers[3] && !isGettingQuestion}
          key={key}
        />
        <span>.</span>
      </S.QuestionContainer>
    );
  };

  return (
    <S.Wrapper mobile={isMobile}>
      <S.Header>
        <img src={`${CDN_URL}/images/everfit_new_logo.svg`} width="171" alt="" />
        <div className="everfit-preload-images">
          <img src={`${CDN_URL}/images/onboarding_list_bg.svg`} alt="" />
          <img src={`${CDN_URL}/images/guide_arrow_1.svg`} alt="" />
          <img src={`${CDN_URL}/images/guide_arrow_2.svg`} alt="" />
        </div>
      </S.Header>
      <S.Container>
        <S.Section className="illustration">
          <div className="img-container">
            <img src={`${CDN_URL}/images/questionnaire_4x.png`} alt="" />
          </div>
          <div className="desctiprion">
            <h1>We’d like to get to know you</h1>
            <h3>Based on your answers, we will suggest features that work best for you and your team.</h3>
          </div>
        </S.Section>
        <S.Section className="questions">
          <h1 className="hi-coach-label">
            Hi Coach,{' '}
            <span role="img" aria-label="hand">
              👋
            </span>
          </h1>
          <h1 className="sub-title">Help us set up your Everfit account</h1>
          <S.Questions className="questions">
            {renderFirstQuestion()}
            {renderSecondQuestion()}
            {renderThirdQuestion()}
            <NotACoachModal onCloseModal={handleCloseModal} openModal={openModal} />
          </S.Questions>
          <div className="actions">
            <button type="button" onClick={onSubmit} disabled={missingData}>
              Next
            </button>
            {isMobile && <S.NotACoachSection onClick={handleOpenModalNotACoach}>Not a Coach?</S.NotACoachSection>}
          </div>
        </S.Section>
      </S.Container>
      {!isMobile && <S.NotACoachSection onClick={handleOpenModalNotACoach}>Not a Coach?</S.NotACoachSection>}
    </S.Wrapper>
  );
};

export default Questionnaire;
