// Libs
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const BlockActionModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 467px;
    height: fit-content;
    max-height: 745px;
    padding: 30px;
    border-radius: 8px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      display: grid;
      grid-template-columns: 25px 1fr;
      align-items: center;
      justify-items: flex-start;
      gap: 10px;
      padding: 0px;

      img.icon-header {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        object-fit: contain;
      }
    }

    .description {
      margin: 15px 0px;
    }

    .content {
      padding: 0px 2px 0px 0px;
      margin: 0px 0px 30px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
    }

    .actions {
      border-top: none;
      background: none;
      text-align: unset;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-end;
    }

    > :last-child {
      padding: 0px !important;
      border-radius: 0px !important;
    }

    .close-button {
      right: -8px;
      top: -8px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .title-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .description-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #323c47;
    }

    .submit-button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 5px;
      border: unset;
      padding: 4px 29px;
      border: 1px solid transparent;
      min-width: 141px;

      &.secondary {
        background: #ffc445;
        border-color: #ffc445;
        color: #ffffff;

        &:hover {
          background: rgba(255, 196, 69, 0.9);
          border-color: rgba(255, 196, 69, 0.9);
        }
      }
    }

    .wrapper-onboarding-flows {
      padding: 0px 4px 0px 12px;
      margin-right: 2px;
      width: 100%;
      max-height: 172px;
      background: #f3f5f8;
      border-radius: 5px;
    }

    .scrollable-onboarding-flows {
      overflow: auto;
      padding: 12px 4px 12px 0px;
      width: 100%;
      height: 100%;

      &::-webkit-scrollbar-track {
        margin-top: 12px;
        margin-bottom: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e6e6e6 !important;
      }
      &::-webkit-scrollbar {
        width: 4px !important;
      }
    }

    .onboarding-flow-item {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #4e5662;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: #5e59ff;
        text-decoration: unset !important;
      }
    }

    .isDisableNavigate {
      cursor: not-allowed;
      :hover {
        color: #4e5662;
      }
    }

    .go-to-icon-tooltip {
      width: 228px;
      padding: 15px;
      border-radius: 5px;
    }
  }
`;
