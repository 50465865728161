import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

import * as S from './style';

const ChangeTimeButton = ({ title, disabled, onClick }) => {
  return (
    <S.Wrapper>
      <div className="cal-arrow-btn prev-day" onClick={() => onClick(-1)}>
        <ArrowIcon />
      </div>
      <span>{title}</span>
      <div className={classNames('cal-arrow-btn next-day', { disabled })} onClick={() => onClick(1)}>
        <ArrowIcon />
      </div>
    </S.Wrapper>
  );
};

export default ChangeTimeButton;
