import styled from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal {
    &.confirm-popup-container {
      border-radius: 8px !important;
      width: 467px !important;
    }

    .confirm-content-header {
      padding: 30px 30px 0;
      > img {
        width: 25px;
        height: 25px;
      }
      .confirm-header-label {
        ${baseText}
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
      .confirm-content-label {
        ${baseText}
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #323c47;
      }
    }
    .ui.button.confirm-no-button {
      ${baseText}
      color: #818DA1;
      font-size: 13px;
      line-height: 18px;
      min-width: 102px;
      &:hover {
        background-color: #f5f7f9 !important;
      }
    }
    .ui.button.confirm-yes-button {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      box-shadow: none;
      background-color: rgba(234, 49, 74, 1);
      color: #fff;
      height: 30px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 111px;
      &:hover {
        box-shadow: none;
        background-color: rgba(234, 49, 74, 0.9);
      }
    }
  }
`;
