import React from 'react';
import { ReactComponent as CheckedIcon } from 'assets/icons/check_circle.svg';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { mongoObjectId } from 'utils/commonFunction';
import { selectedPhoto as handleSelectedPhoto, selectedPhotoCompareMode } from 'redux/progress-photo/actions';
import { formatDate, getThumbnailProgressPhoto } from '../../../helper';
import * as S from './style';

const ItemPhoto = ({
  list = [],
  day = '',
  weight,
  bodyFat,
  compareMode,
  selectedPhoto = '',
  selectedDate = '',
  handleSelectedPhoto,
  comparePhotoList = [],
  selectedPhotoCompareMode,
  photoChangeCompare,
}) => {
  const selectedTags = flatMap(list, 'tags');
  const renderUnit = (weight, bodyFat) => {
    if (weight && bodyFat) {
      return `${weight} - ${bodyFat}`;
    }
    if (weight) {
      return weight;
    }
    return bodyFat;
  };

  return (
    <S.ItemPhotoWrapper active={selectedDate === day} compareMode={compareMode} id={day} data-list="list-photo-date">
      <S.ItemPhoto>
        <S.Info>
          <S.Date>{formatDate(day)}</S.Date>
          <S.Unit>{renderUnit(weight, bodyFat)}</S.Unit>
        </S.Info>
        <S.List id={`photo-by-date-${day}`}>
          {list &&
            list.length > 0 &&
            list.map(item => {
              const bodyFat = get(item, 'other_metrics.[0].value', null);
              const weight = get(item, 'value', null);
              return get(item, 'tags', []).map(tag => {
                const { image = '', tag: tagPhoto = '', image_thumbnail = '' } = tag;
                const isActive =
                  compareMode &&
                  (get(comparePhotoList, '[0].selectedPhoto', '') === image ||
                    get(comparePhotoList, '[1].selectedPhoto', '') === image);

                const disabledItem =
                  isActive && !isEmpty(photoChangeCompare) && get(photoChangeCompare, 'selectedPhoto', '') !== image;

                const disableSelected =
                  compareMode &&
                  ((!isEmpty(get(comparePhotoList, '[0]', {})) &&
                    !isEmpty(get(comparePhotoList, '[1]', {})) &&
                    isEmpty(photoChangeCompare)) ||
                    (!isEmpty(photoChangeCompare) && isActive));

                return (
                  <S.ItemWrapper key={item._id || mongoObjectId()}>
                    <S.Item
                      disabledItem={disabledItem}
                      active={compareMode ? isActive : selectedPhoto === image}
                      id={image}
                      data-tip
                      data-for={`photo-item-tooltip-${image}`}
                      data-item="item-photo"
                      onClick={() => {
                        if (disableSelected) return;
                        const data = {
                          day,
                          image,
                          tags: selectedTags,
                          measurementId: get(item, '_id', ''),
                          image_thumbnail,
                          weight,
                          bodyFat,
                          tag: tagPhoto,
                          weightUnit: get(item, 'unit', {}),
                        };
                        if (compareMode) {
                          selectedPhotoCompareMode && selectedPhotoCompareMode(data);
                        } else {
                          handleSelectedPhoto && handleSelectedPhoto(data);
                        }
                      }}
                    >
                      {!window.Cypress ? (
                        <S.S3ImageWithFallbackWrapper src={getThumbnailProgressPhoto([image_thumbnail, image])} />
                      ) : (
                        <S.MockupImg></S.MockupImg>
                      )}
                      {isActive && <CheckedIcon />}
                    </S.Item>
                    {tagPhoto && (
                      <ReactTooltip
                        isOpen={true}
                        id={`photo-item-tooltip-${image}`}
                        effect="solid"
                        place={'top'}
                        className="photo-item-tooltip"
                      >
                        {tagPhoto || 'No tag'}
                      </ReactTooltip>
                    )}
                  </S.ItemWrapper>
                );
              });
            })}
        </S.List>
      </S.ItemPhoto>
    </S.ItemPhotoWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: {
        compareMode,
        itemPhoto: { selectedDate, selectedPhoto },
        comparePhotoList,
        photoChangeCompare,
      },
    },
  } = state;
  return { compareMode, selectedPhoto, selectedDate, comparePhotoList, photoChangeCompare };
};

const mapDispatchToProps = dispatch => ({
  handleSelectedPhoto: bindActionCreators(handleSelectedPhoto, dispatch),
  selectedPhotoCompareMode: bindActionCreators(selectedPhotoCompareMode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemPhoto);
