import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import classNames from 'classnames';
import * as S from './style';
import UploadCoverImage from 'shared/UploadCoverImage';
import { ErrorMessage, FormGroup } from 'shared/FormControl';
import { CloseIcon } from '../style';
import { validateVimeoUrl, getLinkType, validateLink, autoAddHttps, checkLinkFormat } from 'utils/validations';
import MediaModal from 'shared/MediaModal/component';
import { MEDIA_TYPES } from '../helper';
import { mongoObjectId, formatShortLink, generateVimeoAPIUrl } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import { LIMIT_SIZE_IMAGE_UPLOAD_MB, MEDIA_PLACEHOLDER, showMsgForSize } from 'constants/commonData';

class LinkAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      attachmentData: { type: MEDIA_TYPES.LINK },
    };

    this.linkInput = React.createRef();
    this.uploadingCoverImage = null;
    this.getMetaDataDebounce = _.debounce(this.getLinkMetaData, 300);
  }

  componentDidMount() {
    const attachmentData = _.get(this.props.attachments, 'attachments.0');

    if (attachmentData) {
      this.setState({ attachmentData });
    } else {
      if (this.linkInput.current) {
        this.linkInput.current.focus();
      }
    }
  }

  markUploadDone = () => {
    this.props.updateUploadStatus([{ uploadId: this.uploadingCoverImage, status: true }]);
    this.uploadingCoverImage = null;
  };

  onRemoveCoverImage = () => {
    this.setState(
      {
        attachmentData: {
          ...this.state.attachmentData,
          thumbnail_url: null,
        },
        isUploading: false,
      },
      () => {
        if (this.uploadingCoverImage) {
          this.markUploadDone();
        }

        this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
      },
    );
  };

  onUploadCoverImageSuccess = ({ src }) => {
    this.setState(
      {
        attachmentData: {
          ...this.state.attachmentData,
          thumbnail_url: src,
        },
      },
      () => {
        this.markUploadDone();
        this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
      },
    );
  };

  onStartUpload = () => {
    this.uploadingCoverImage = mongoObjectId();
    this.props.updateUploadStatus([{ uploadId: this.uploadingCoverImage }]);
    this.setState({ isUploading: true });
  };

  onEndUpload = () => {
    this.setState({ isUploading: false });
    this.markUploadDone();
  };

  onChangeLinkName = e => {
    this.setState(
      {
        attachmentData: {
          ...this.state.attachmentData,
          name: e.target.value,
        },
      },
      () => {
        this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
      },
    );
  };

  getLinkMetaData = url => {
    getPageMetaDataFromURL(url).then(response => {
      const {
        data: {
          data: { image, title },
        },
      } = response;

      const linkURL = _.get(this.state.attachmentData, 'url', '');

      this.setState(
        {
          attachmentData: {
            ...this.state.attachmentData,
            thumbnail_url: image,
            name: title,
            url: formatShortLink(linkURL),
          },
        },
        () => {
          this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
        },
      );
    });
  };

  onChangeLink = event => {
    let value = event.target.value || '';
    const trimmedValue = autoAddHttps(value.trim());
    const link_type = getLinkType(trimmedValue);
    this.setState(
      {
        link_type,
        attachmentData: {
          ...this.state.attachmentData,
          url: value.trim(),
        },
      },
      () => {
        if (!value && this.uploadingCoverImage) {
          this.markUploadDone();
        }

        this.props.onUpdateAttachmentData({
          attachments: this.state.attachmentData.url ? [this.state.attachmentData] : [],
        });
      },
    );
    if (trimmedValue) {
      if (validateVimeoUrl(trimmedValue)) {
        axios.get(generateVimeoAPIUrl(trimmedValue)).then(response => {
          const data = response.data || {};
          this.setState(
            {
              attachmentData: {
                ...this.state.attachmentData,
                thumbnail_url: data.thumbnail_url || MEDIA_PLACEHOLDER,
                name: data.title,
              },
            },
            () => {
              this.props.onUpdateAttachmentData({ attachments: [this.state.attachmentData] });
            },
          );
        });
      } else {
        if (validateLink(trimmedValue) || checkLinkFormat(trimmedValue)) {
          this.getMetaDataDebounce(trimmedValue);
        }
      }
    }
  };

  render() {
    const { attachmentData, link_type } = this.state;
    return (
      <S.LinkAttachmentWrapper>
        <CloseIcon onClick={this.props.onClose} />
        <FormGroup
          className={classNames('form-group--link', {
            error: attachmentData.url && !validateLink(attachmentData.url) && !checkLinkFormat(attachmentData.url),
            hasUrl: !!attachmentData.url,
          })}
          largeMargin
        >
          <label>Link</label>
          <input
            value={attachmentData.url}
            placeholder="http://example.com"
            onChange={this.onChangeLink}
            ref={this.linkInput}
          />
          {attachmentData.url && !validateLink(attachmentData.url) && !checkLinkFormat(attachmentData.url) ? (
            <ErrorMessage className="error-message">Please add valid link</ErrorMessage>
          ) : null}
        </FormGroup>
        {attachmentData.url ? (
          <FormGroup largeMargin>
            <label>Link Title</label>
            <input value={attachmentData.name} placeholder="Optional" onChange={this.onChangeLinkName} />
          </FormGroup>
        ) : null}
        {attachmentData.url ? (
          <>
            <S.Label>Cover Image (optional)</S.Label>
            <UploadCoverImage
              key="cover_image"
              className="taskAttachment__uploadCover"
              onRemove={this.onRemoveCoverImage}
              onUploadSuccess={this.onUploadCoverImageSuccess}
              onStartUpload={this.onStartUpload}
              onEndUpload={this.onEndUpload}
              src={attachmentData.thumbnail_url}
              uploadKey="media"
              hasRemoveIcon
              hideFileInput={['youtube', 'vimeo'].includes(link_type)}
              maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
              fileSizeLimitMessage={showMsgForSize(LIMIT_SIZE_IMAGE_UPLOAD_MB)}
            >
              {['youtube', 'vimeo'].includes(link_type) && (
                <MediaModal video={autoAddHttps(attachmentData.url)} validVideo={true} />
              )}
            </UploadCoverImage>
          </>
        ) : null}
      </S.LinkAttachmentWrapper>
    );
  }
}

export default LinkAttachment;
