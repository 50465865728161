/**
 * @flow
 */

import React from 'react';
import { Button, Form, Label } from 'semantic-ui-react';
import { push } from 'connected-react-router';

import GeneralButton from 'shared/GeneralButton';
import './styles.scss';
import VerifyPasswordTooltip from 'components/Register/VerifyPasswordTooltip';
import { checkPasswordValidation } from 'utils/validations';
import classNames from 'classnames';

export default class ChangePassword extends React.Component {
  state = {
    isPasswordInputFocused: false,
    showVerifyPasswordError: false,
  };

  handleSubmit(event) {
    event.preventDefault();

    this.handleChangePassword();
  }

  checkNewPassword = password => {
    const validationResult = checkPasswordValidation(password || '');
    return Object.values(validationResult).every(item => !!item);
  };

  handleCheckPasswordInputStatus = status => () => {
    const { isPasswordInputFocused, newPassword } = this.state;
    if (isPasswordInputFocused && !status) {
      this.setState({
        showVerifyPasswordError: newPassword && !this.checkNewPassword(newPassword),
      });
    } else {
      this.setState({
        showVerifyPasswordError: false,
      });
    }

    this.setState({
      isPasswordInputFocused: status,
    });
  };

  render() {
    const { isPasswordInputFocused, newPassword, confirmPassword, showVerifyPasswordError } = this.state;
    const isInvalidNewPassword = !this.checkNewPassword(newPassword);

    return (
      <div className="accountdetails-container">
        <div className="changeemail">
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group widths="equal">
              <Form.Field>
                <Label className="setting-label">CURRENT PASSWORD</Label>
                <input
                  className={'form-text'}
                  type="password"
                  name="password"
                  onChange={evt => {
                    this.setState({ password: evt.target.value });
                  }}
                  value={this.state.password}
                  placeholder={'Enter your current password'}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field className="new-password__input">
                <Label className="setting-label">NEW PASSWORD</Label>
                <input
                  className={classNames('form-text', { isInvalidNewPassword: showVerifyPasswordError })}
                  type="password"
                  name="password"
                  onChange={evt => {
                    let error = '';
                    if (confirmPassword && confirmPassword !== evt.target.value) {
                      error = 'Passwords do not match';
                    }
                    this.setState({ newPassword: evt.target.value, error: error });
                  }}
                  value={newPassword}
                  placeholder={'Enter new password'}
                  onBlur={this.handleCheckPasswordInputStatus(false)}
                  onFocus={this.handleCheckPasswordInputStatus(true)}
                />
                {isPasswordInputFocused && (
                  <div className="verify-password-wrapper">
                    <VerifyPasswordTooltip passwordValue={newPassword || ''} />
                  </div>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field className="confirm-password__input">
                <Label className="setting-label">CONFIRM NEW PASSWORD</Label>
                <input
                  className={'form-text '}
                  type="password"
                  name="password"
                  onChange={evt => {
                    let error = '';
                    if (this.state.newPassword !== evt.target.value) {
                      error = 'Passwords do not match';
                    }
                    this.setState({ confirmPassword: evt.target.value, error: error });
                  }}
                  value={this.state.confirmPassword}
                  placeholder={'Confirm your new password'}
                />
              </Form.Field>
            </Form.Group>
            {this.state.error && (
              <Form.Group>
                <Form.Field>
                  <Label className="error-lable">{this.state.error}</Label>
                </Form.Field>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Field>
                <Button
                  className="setting-cancel-button"
                  onClick={() => {
                    this.props.dispatch(push('/home/accountdetails'));
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <GeneralButton
                  className="setting-save-button"
                  disabled={
                    !this.state.newPassword ||
                    !this.state.confirmPassword ||
                    !this.state.password ||
                    this.state.newPassword !== this.state.confirmPassword ||
                    isInvalidNewPassword
                  }
                  type="submit"
                >
                  Save
                </GeneralButton>
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }

  handleChangePassword() {
    if (this.state.newPassword.lenght < 8) {
      this.setState({ error: 'Password must be at least 8 characters long' });
      return;
    }
    this.props.changePassword({
      old_password: this.state.password,
      password: this.state.newPassword,
    });
  }
}
