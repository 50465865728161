import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import classNames from 'classnames';

import { ErrorMessage, FormGroup } from 'shared/FormControl';
import { validateVimeoUrl, getLinkType, validateLink, autoAddHttps } from 'utils/validations';
import { formatShortLink, generateVimeoAPIUrl } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import MediaPlayer from 'shared/MediaPlayer';

import { MEDIA_TYPES } from './helper';
import { ReactComponent as LinkIcon } from 'assets/icons/freestyle-section-link-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/freestyle-section-close-icon.svg';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import * as S from './style';

class LinkAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      attachmentData: { type: MEDIA_TYPES.LINK },
    };

    this.linkInput = React.createRef();
    this.uploadingCoverImage = null;
    this.getMetaDataDebounce = _.debounce(this.getLinkMetaData, 300);
  }

  componentDidMount() {
    const attachmentData = _.get(this.props, 'attachment');

    if (attachmentData) {
      const link_type = getLinkType(attachmentData.url);
      this.setState({ attachmentData, link_type });
    } else {
      if (this.linkInput.current) {
        this.linkInput.current.focus();
      }
    }
  }

  markUploadDone = () => {
    this.props.updateUploadStatus([{ uploadId: this.uploadingCoverImage, status: true }]);
    this.uploadingCoverImage = null;
  };

  getLinkMetaData = url => {
    this.props.onStartFetchingMeta();
    const linkType = getLinkType(url);

    getPageMetaDataFromURL(url).then(response => {
      const {
        data: {
          data: { image, title },
        },
      } = response;

      const { hostname } = new URL(url);
      const linkURL = _.get(this.state.attachmentData, 'url', '');

      this.setState(
        {
          attachmentData: {
            ...this.state.attachmentData,
            thumbnail_url: image,
            name: ['youtube', 'vimeo'].includes(linkType) ? title : hostname,
            url: ['youtube'].includes(linkType) ? formatShortLink(linkURL) : linkURL,
          },
        },
        () => {
          this.props.onUpdateAttachmentData([this.state.attachmentData]);
          this.props.onEndFetchingMeta();
        },
      );
    });
  };

  onChangeLink = event => {
    let value = event.target.value || '';
    let emptyUrl = value === '';

    const trimmedValue = autoAddHttps(value.trim());
    const link_type = getLinkType(trimmedValue);
    this.setState(
      {
        link_type,
        attachmentData: {
          ...this.state.attachmentData,
          url: value.trim(),
          thumbnail_url: emptyUrl ? null : this.state.attachmentData.thumbnail_url,
        },
      },
      () => {
        if (!value && this.uploadingCoverImage) {
          this.markUploadDone();
        }

        this.props.onUpdateAttachmentData(this.state.attachmentData.url ? [this.state.attachmentData] : null);
      },
    );
    if (trimmedValue) {
      if (validateVimeoUrl(trimmedValue)) {
        axios.get(generateVimeoAPIUrl(trimmedValue)).then(response => {
          const data = response.data || {};
          this.setState(
            {
              attachmentData: {
                ...this.state.attachmentData,
                thumbnail_url: data.thumbnail_url || MEDIA_PLACEHOLDER,
                name: data.title,
              },
            },
            () => {
              this.props.onUpdateAttachmentData([this.state.attachmentData]);
            },
          );
        });
      } else {
        if (validateLink(trimmedValue)) {
          this.getMetaDataDebounce(trimmedValue);
        }
      }
    }
  };

  render() {
    const { attachmentData, link_type } = this.state;
    const shouldPlay = ['youtube', 'vimeo'].includes(link_type);
    return (
      <S.LinkAttachmentWrapper>
        <FormGroup
          className={classNames('form-group--link', {
            error: attachmentData.url && !validateLink(attachmentData.url),
            hasUrl: !!attachmentData.url,
          })}
          largeMargin
        >
          <div className="container">
            {['youtube', 'vimeo', 'link'].includes(link_type) &&
            (attachmentData.thumbnail_url || attachmentData.url) ? (
              <S.MediaLinkPreview
                background={attachmentData.thumbnail_url || MEDIA_PLACEHOLDER}
                shouldRenderPlayIcon={shouldPlay}
              >
                {shouldPlay && (
                  <MediaPlayer url={attachmentData.url} type="video" thumbnail={attachmentData.thumbnail_url} />
                )}
              </S.MediaLinkPreview>
            ) : (
              <LinkIcon className="link-icon-placeholder" />
            )}
            <input
              className="link-input"
              value={attachmentData.url}
              placeholder="Youtube / Vimeo Link"
              onChange={this.onChangeLink}
              ref={this.linkInput}
            />
            <CloseIcon onClick={this.props.onClose} className="close-icon" />
          </div>
          {attachmentData.url && !validateLink(attachmentData.url) ? (
            <ErrorMessage className="error-message">Please add valid link</ErrorMessage>
          ) : null}
        </FormGroup>
      </S.LinkAttachmentWrapper>
    );
  }
}

export default LinkAttachment;
