// libs
import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

// components
import DragItemRecipe from './DragItemRecipe';

const SortableRecipe = props => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DragItemRecipe
      ref={setNodeRef}
      style={style}
      isSortableDragging={isDragging}
      attributes={attributes}
      listeners={listeners}
      isOver={isOver}
      {...props}
    />
  );
};

export default SortableRecipe;
