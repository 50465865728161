import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { Modal } from 'semantic-ui-react';

import { axiosInstance } from 'configs/request';
import { BILLING_CYCLE, CDN_URL } from 'constants/commonData';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as Icons from 'shared/LibraryLayout/Icons';

import { WebSocketContext } from 'libs/socket';
import { SOCKET_EVENTS } from 'libs/socket/constants';
import LoadingIndicator from 'shared/LoadingIndicator';
import ConfirmPayAnInvoiceModal from '../ConfirmPayAnInvoiceModal';
import { ReactComponent as PayIcon } from 'assets/icons/billing_pay_now_icon.svg';

import * as S from './style';
import './style.scss';

function FullBillingHistory(props) {
  const { automation_meter_addons_overdue_invoice, zapier_meter_addons_overdue_invoice, invoice_over_due } = props;
  const [list, setList] = useState([]);
  const [downloadingList, setDownloadingList] = useState([]);
  const [sort, setSort] = useState({ key: 'created', order: -1 });
  const { socket } = useContext(WebSocketContext);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get('/api/pricing/get-team-billing-history')
      .then(response => {
        const { data } = response.data;

        if (data) {
          const { historyListData } = data;
          setList(historyListData || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSort = key => {
    let order = key === sort.key ? -1 * sort.order : -1;
    setSort({ order, key });
  };

  const onPayAnInvoice = data => {
    props.toggleSecondModal(
      true,
      <ConfirmPayAnInvoiceModal
        billingInfo={data}
        onClose={() => props.toggleSecondModal(false)}
        isFullBillingHistory
      />,
    );
  };

  const onSocketDownloadBilling = itemId => {
    const newDownloadingIds = _.filter(downloadingList, id => id !== itemId);
    setDownloadingList(newDownloadingIds);
    socket.removeAllListeners(SOCKET_EVENTS.DownloadPdfCustomInvoice);
    socket.on(SOCKET_EVENTS.DownloadPdfCustomInvoice, data => {
      if (data && data.url) {
        socket.removeAllListeners(SOCKET_EVENTS.DownloadPdfCustomInvoice);
        window.open(data.url, '_blank');
      }
    });
  };

  const onDownloadBilling = item => {
    const idsDownloading = [...downloadingList, item.id];
    setDownloadingList(_.uniq(idsDownloading));
    if (!downloadingList.includes(_.get(item, 'id', null))) {
      axiosInstance
        .get(`/api/pricing/billing/${item.id}/download`)
        .then(() => {
          onSocketDownloadBilling(item.id);
        })
        .catch(() => {
          const newDownloadingIds = _.filter(downloadingList, id => id !== item.id);
          setDownloadingList(newDownloadingIds);
        });
    }
  };

  const orderedList = _.orderBy(list, sort.key, sort.order === 1 ? 'asc' : 'desc');

  const unpaidInvoice = [
    automation_meter_addons_overdue_invoice,
    zapier_meter_addons_overdue_invoice,
    invoice_over_due,
  ];

  const hasPayNow = automation_meter_addons_overdue_invoice || zapier_meter_addons_overdue_invoice || invoice_over_due;

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      className="modal--billing-full-history"
      onClose={props.onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.ContentWrapper>
          <ModalLayout.Header>
            <div>Billing History</div>
          </ModalLayout.Header>
          <ModalLayout.Content>
            {isLoading && <LoadingIndicator className="loading-history" />}
            {!isLoading && (
              <S.TableContainer>
                <table>
                  <thead>
                    <tr>
                      <th onClick={() => onSort('created')}>
                        <span>Date</span>
                        {sort.key === 'created' ? (
                          sort.order === 1 ? (
                            <Icons.ArrowUp active />
                          ) : (
                            <Icons.ArrowDown active />
                          )
                        ) : (
                          <Icons.ArrowDown />
                        )}
                      </th>
                      <th>Billing Period</th>
                      <th>Amount</th>
                      {hasPayNow && <th></th>}
                      <th onClick={() => onSort('status')}>
                        <span>Status</span>
                        {sort.key === 'status' ? (
                          sort.order === 1 ? (
                            <Icons.ArrowUp active />
                          ) : (
                            <Icons.ArrowDown active />
                          )
                        ) : (
                          <Icons.ArrowDown />
                        )}
                      </th>
                      <th>Invoice</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {_.map(orderedList, (item, index) => (
                      <tr key={item.created}>
                        <td>{moment.unix(item.created).format('MMM D, YYYY')}</td>
                        <td>{BILLING_CYCLE[item.interval] ? BILLING_CYCLE[item.interval].name : '--'}</td>
                        <td className="amount">
                          {(Number(item.amount_due) || 0).toUSDFormat()}{' '}
                          {item.status !== 'paid' && unpaidInvoice.includes(item.id) ? (
                            <S.PayButton onClick={() => onPayAnInvoice(item)}>
                              <PayIcon />
                              Pay Now
                            </S.PayButton>
                          ) : null}
                        </td>
                        {item.status && hasPayNow && <td></td>}
                        <td>
                          <div className={classnames('status', item.status)}>
                            {item.status === 'paid' ? 'Paid' : 'Pending'}
                          </div>
                        </td>
                        <td>{item.number}</td>
                        <td>
                          {!!item.invoice_pdf &&
                            (downloadingList.includes(_.get(item, 'id', null)) ? (
                              <S.DownloadingIcon>
                                <div className="lds-ring"></div>
                              </S.DownloadingIcon>
                            ) : (
                              <S.DownloadIconBilling
                                data-btn={`download-invoice ${index}`}
                                onClick={e => {
                                  e.preventDefault();
                                  onDownloadBilling(item);
                                }}
                              />
                            ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </S.TableContainer>
            )}
          </ModalLayout.Content>
        </S.ContentWrapper>
      </Modal.Content>
    </Modal>
  );
}

export default FullBillingHistory;
