// Libs
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';

// Constants
import { convertFtToFtAndInch, convertMinToFromHourMin, roundNumberBodyMetric } from 'utils/commonFunction';
import { DATA_POINT_KEYS, METRIC_CHANGED_STATUS, PERIOD_GROUP } from '../constants';
import {
  calculateComparedChartInfo,
  calculateComparedSharedPrevItem,
  compareItemToPrevious,
  convertSecToSecMin,
  getAverageValueContent,
  getLastValueContent,
  getTimeLabel,
  getTotalOfAllValuesContent,
} from '../chartHelper';

// Components
import TagChanged from './TagChanged';

// Styles
import * as S from './styles';

const containsMinAndHourAndSec = str => {
  return str.includes('min') || str.includes('h') || str.includes('sec');
};

const convertToHTML = str => {
  const [value1, unit1, value2, unit2] = str.split(' ');
  return (
    <>
      {value1} <S.UnitStyle>{unit1}</S.UnitStyle> {value2} <S.UnitStyle>{unit2}</S.UnitStyle>
    </>
  );
};

const CustomTooltipContent = ({
  payload: tooltipPayload,
  chartData = [],
  compareChartData = [],
  active,
  tooltipActiveCallback = () => {},
  isModeCompare = false,
  isChartLine = false,
  chartName,
  chartColor,
  id,
  isShowTooltip = true,
  isMetricChartCompare = false,
  isExerciseMetric = false,
  isHoveringChartBar = false,
  hideBottomTag = false,
  isSpecialChart = false,
}) => {
  const { payload: itemData = {} } = tooltipPayload[0] || {};

  const arrDataPointsKey = [DATA_POINT_KEYS.latest_value, DATA_POINT_KEYS.average_value, DATA_POINT_KEYS.all_values];

  const getDataPointContent = (dataPoint, periodGroup) => {
    switch (dataPoint) {
      case DATA_POINT_KEYS.latest_value:
        return getLastValueContent(periodGroup);
      case DATA_POINT_KEYS.average_value:
        return getAverageValueContent(periodGroup);
      case DATA_POINT_KEYS.all_values:
        return getTotalOfAllValuesContent(periodGroup);

      default:
        return getLastValueContent(periodGroup);
    }
  };

  // don't render tooltip without label or value is undefined
  if ((!!itemData && itemData.value === undefined) || !isShowTooltip) {
    tooltipActiveCallback(false);
    return null;
  }

  const label = useMemo(() => {
    if (!itemData.date) {
      return itemData.timeLabel || '';
    }
    return getTimeLabel(itemData);
  }, [itemData]);

  const comparedPrevItem = useMemo(() => compareItemToPrevious(itemData, chartData), [itemData]);

  const comparedPrevItemIndex = useMemo(() => chartData.findIndex(item => item._id === itemData._id), [itemData]);

  const comparedSharedPrevItem = useMemo(
    () => calculateComparedSharedPrevItem(comparedPrevItemIndex, compareChartData),
    [comparedPrevItemIndex],
  );

  const comparedChartInfo = useMemo(() => calculateComparedChartInfo(compareChartData, comparedPrevItemIndex), [
    comparedPrevItemIndex,
  ]);

  const itemChartDataPoint = get(itemData, 'dataPoint');
  const itemCompareChartDataPoint = get(comparedChartInfo, 'dataPoint');

  const itemChartDataPeriod = get(itemData, 'period_group');
  const itemCompareChartDataPeriod = get(comparedChartInfo, 'period_group');

  useEffect(() => {
    tooltipActiveCallback(active);
  }, [active]);

  const unit = (itemData && itemData.unit && itemData.unit.title) || '';
  const isFtUnit = unit === 'ft';
  const ftInch = isFtUnit ? convertFtToFtAndInch((itemData && itemData.value) || 0) : {};

  const renderValue = (value, unit) => {
    const valueRounded = round(value, 1);

    const { min = null, max = null } = itemData;
    if (min && max) {
      return min === max ? min : `${min} - ${max}`;
    }

    if (unit !== 'min' && unit !== 'sec') {
      return valueRounded;
    }

    const valRounded = roundNumberBodyMetric(value, 1);
    const isMinUnit = unit === 'min';
    const isSecUnit = unit === 'sec';

    return <>{isMinUnit ? renderMin(valRounded) : isSecUnit ? renderSec(valRounded) : ''}</>;
  };

  const renderMin = value => {
    const { min, hour } = convertMinToFromHourMin(value);

    return (
      <>
        {hour > 0 ? (
          <S.UnitValue>
            {hour} <S.UnitStyle>h </S.UnitStyle>
          </S.UnitValue>
        ) : (
          ''
        )}
        {min > 0 ? (
          <S.UnitValue>
            {Math.round(min)} <S.UnitStyle>min</S.UnitStyle>
          </S.UnitValue>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderSec = value => {
    const { sec, min } = convertSecToSecMin(value);

    return (
      <>
        {min > 0 ? (
          <S.UnitValue>
            {Math.round(min)} <S.UnitStyle>min </S.UnitStyle>
          </S.UnitValue>
        ) : (
          ''
        )}
        {sec > 0 ? (
          <S.UnitValue>
            {Math.round(sec)} <S.UnitStyle>sec</S.UnitStyle>
          </S.UnitValue>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderFTUnitValue = value => {
    return (
      <>
        {value.ft > 0 ? (
          <>
            <S.UnitValue>
              {value.ft}
              <S.UnitStyle> ft</S.UnitStyle>
            </S.UnitValue>
          </>
        ) : (
          ''
        )}
        {value.inch > 0 ? (
          <>
            <S.UnitValue>
              {value.inch}
              <S.UnitStyle> in</S.UnitStyle>
            </S.UnitValue>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderMetricChart = () => {
    return (
      <S.TooltipCompareWrapper>
        <S.ChartTypes>
          <S.ChartType
            style={{
              backgroundColor: chartColor,
            }}
            isChartLine={isChartLine}
          />
        </S.ChartTypes>
        <S.ChartInfo>
          <S.ChartName>{chartName}</S.ChartName>
          <p className="tooltip-value">
            {isFtUnit ? (
              renderFTUnitValue(ftInch)
            ) : (
              <S.UnitValue>
                {!!itemData && renderValue(itemData.value, unit)}{' '}
                <S.UnitStyle>{isFtUnit || unit === 'min' || unit === 'sec' ? '' : unit}</S.UnitStyle>
              </S.UnitValue>
            )}
            {arrDataPointsKey.includes(itemChartDataPoint) && (
              <S.AverageLabel>{getDataPointContent(itemChartDataPoint, itemChartDataPeriod)}</S.AverageLabel>
            )}
          </p>
          {!!comparedPrevItem && (
            <div className="value-change">
              <TagChanged info={comparedPrevItem} />
              <span className="from-time">{comparedPrevItem.date}</span>
            </div>
          )}
        </S.ChartInfo>
      </S.TooltipCompareWrapper>
    );
  };

  const renderMetricChartCompare = (isFtUnitCompare, ftInchCompare, unitCompare) => {
    return (
      <S.TooltipCompareWrapper>
        <S.ChartTypes>
          <S.ChartType
            style={{
              backgroundColor: get(comparedChartInfo, 'chartColor'),
            }}
            isChartLine={isChartLine}
          />
        </S.ChartTypes>
        <S.ChartInfo>
          <S.ChartName>{get(comparedChartInfo, 'chartName')}</S.ChartName>
          {get(comparedChartInfo, 'value') ? (
            <>
              <p className="tooltip-value">
                {isFtUnitCompare ? (
                  renderFTUnitValue(ftInchCompare)
                ) : (
                  <S.UnitValue>
                    {renderValue(comparedChartInfo.value, unitCompare)}{' '}
                    <S.UnitStyle>
                      {isFtUnitCompare || unitCompare === 'min' || unitCompare === 'sec' ? '' : unitCompare}
                    </S.UnitStyle>
                  </S.UnitValue>
                )}
                {arrDataPointsKey.includes(itemCompareChartDataPoint) && (
                  <S.AverageLabel>
                    {getDataPointContent(itemCompareChartDataPoint, itemCompareChartDataPeriod)}
                  </S.AverageLabel>
                )}
              </p>
              {!!comparedSharedPrevItem && (
                <div className="value-change">
                  <TagChanged info={comparedSharedPrevItem} />
                  <span className="from-time">{comparedSharedPrevItem.date}</span>
                </div>
              )}
            </>
          ) : (
            <S.ChartNoData>No data available</S.ChartNoData>
          )}
        </S.ChartInfo>
      </S.TooltipCompareWrapper>
    );
  };

  const renderTooltipCompare = () => {
    const { unit, value } = comparedChartInfo || {};
    const unitCompare = (unit && unit.title) || '';
    const isFtUnitCompare = unitCompare === 'ft';
    const ftInchCompare = isFtUnitCompare ? convertFtToFtAndInch(value || 0) : {};

    return (
      <>
        {isMetricChartCompare ? (
          <>
            {!isEmpty(comparedChartInfo) && renderMetricChartCompare(isFtUnitCompare, ftInchCompare, unitCompare)}
            {renderMetricChart()}
          </>
        ) : (
          <>
            {renderMetricChart()}
            {!isEmpty(comparedChartInfo) && renderMetricChartCompare(isFtUnitCompare, ftInchCompare, unitCompare)}
          </>
        )}
      </>
    );
  };

  return (
    <S.TooltipWrapper
      id={id || 'metric-chart-tooltip-customized'}
      className={classNames({
        'has-change': get(comparedPrevItem, 'status') !== METRIC_CHANGED_STATUS.NO,
      })}
      isHoveringChartBar={isHoveringChartBar}
      isSpecialChart={isSpecialChart}
      style={{ opacity: isChartLine || isHoveringChartBar ? 1 : 0 }}
      isModeCompare={isModeCompare}
      isExerciseMetric={isExerciseMetric}
    >
      <S.RangeTimeLabel>
        {label}
        {/* <S.TimeRange> {timeRange}</S.TimeRange> */}
      </S.RangeTimeLabel>
      {isModeCompare ? (
        renderTooltipCompare()
      ) : (
        <>
          <p className="tooltip-value">
            {isFtUnit ? (
              <>
                {ftInch.ft > 0 ? (
                  <>
                    <S.UnitValue>
                      {ftInch.ft}
                      <S.UnitStyle> ft</S.UnitStyle>
                    </S.UnitValue>
                  </>
                ) : (
                  ''
                )}
                {ftInch.inch > 0 ? (
                  <>
                    <S.UnitValue>
                      {ftInch.inch}
                      <S.UnitStyle> in</S.UnitStyle>
                    </S.UnitValue>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <S.UnitValue>
                {isExerciseMetric ? (
                  <>
                    {containsMinAndHourAndSec(unit) ? (
                      convertToHTML(unit)
                    ) : (
                      <>
                        {get(itemData, 'label', '')} <S.UnitStyle>{isFtUnit ? '' : unit}</S.UnitStyle>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {renderValue(get(itemData, 'value'), unit) || ''}{' '}
                    <S.UnitStyle>{isFtUnit || unit === 'min' || unit === 'sec' ? '' : unit}</S.UnitStyle>
                  </>
                )}
              </S.UnitValue>
            )}{' '}
            {arrDataPointsKey.includes(itemChartDataPoint) && (
              <S.AverageLabel>{getDataPointContent(itemChartDataPoint, itemChartDataPeriod)}</S.AverageLabel>
            )}
          </p>
          {!!comparedPrevItem && !isExerciseMetric && !hideBottomTag && (
            <div className="value-change">
              <TagChanged info={comparedPrevItem} />
              <span className="from-time">{comparedPrevItem.date}</span>
            </div>
          )}
        </>
      )}
    </S.TooltipWrapper>
  );
};

export default CustomTooltipContent;
