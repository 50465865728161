import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import { Button } from 'shared/FormControl';
import { turnOnEditMode } from 'redux/studio-program/actions';
import { CDN_URL } from 'constants/commonData';

function ConfirmEditModal(props) {
  const onConfirm = () => {
    props.turnOnEditMode(props.programId);
    props.onClose();
  };

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      className="modal--studio-confirm-actions"
      onClose={props.onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <Wrapper>
          <ModalLayout.Header>
            <ModalLayout.HeaderIcon
              backgroundColor="#5c5bbd"
              icon={`${CDN_URL}/images/edit_underline_white_stroke.svg`}
            />
            <span>Edit Program</span>
          </ModalLayout.Header>
          <ModalLayout.Content>
            <div>
              You can edit freely. Changes made to the program will not go live until you choose to Publish Changes.
            </div>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button purple onClick={onConfirm}>
              Okay
            </Button>
          </ModalLayout.Actions>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
}

export default connect(null, { turnOnEditMode })(ConfirmEditModal);

const HeaderIcon = styled.div`
  width: 25px;
  height: 25px;
  background: #5c5bbd url(${CDN_URL}/images/edit_underline_white_stroke.svg) no-repeat center center;
  background-size: auto;
  border-radius: 50%;
  margin-right: 10px;
`;

const Wrapper = styled(ModalLayout.Wrapper)`
  ${ModalLayout.Header} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 15px;
  }
`;
