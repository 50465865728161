/**
 * @flow
 */

export const POPUP = 'POPUP';

export const TOGGLE_POPUP = 'TOGGLE_POPUP';

export const togglePopup = (popup: string) => {
  return {
    type: TOGGLE_POPUP,
    popup,
  };
};
