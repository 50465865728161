import React, { useState, useEffect, useRef, Fragment } from 'react';
import * as Layout from 'shared/LibraryLayout';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import classNames from 'classnames';

import SearchInput from 'shared/SearchInput';
import { Button } from 'shared/FormControl';
import { timeSince } from 'utils/commonFunction';
import OwnerAvatar from 'shared/OwnerAvatar';
import WorkoutTags from 'components/WorkoutDetailModal/components/MainPanel/components/WorkoutTags';

import { CDN_URL } from 'constants/commonData';

import { ReactComponent as TagsFilterIcon } from 'assets/icons/workout-tag-darker-gray.svg';
import { ReactComponent as StarsAIIcon } from 'assets/icons/stars-2.svg';

import WorkoutPreview from './Preview';
import * as S from './style';
import './style.scss';
import WorkoutItem from './WorkoutItem';

const optionHeight = 70;

const WorkoutLoader = props => (
  <Fragment>
    {_.range(0, 5).map(i => (
      <S.LoadingItem key={`workoutloader${i}`}>
        <ContentLoader viewBox="0 0 360 60" width={360} height={60} speed={2} {...props}>
          <rect x="15" y="16" rx="2" ry="2" width="190" height="14" />
          <rect x="15" y="37" rx="2" ry="2" width="120" height="9" />
        </ContentLoader>
      </S.LoadingItem>
    ))}
  </Fragment>
);

function ChooseWorkoutModal(props) {
  const { workouts, title, displayOwner, displayLastUsed, disableSelectButton, total, workoutTags = null } = props;
  const [selecting, setSelecting] = useState();
  const [textSearch, setTextSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const listRef = useRef();

  useEffect(() => {
    const existing = workouts.some(item => _.get(item, '_id') === _.get(selecting, '_id'));
    if ((_.isEmpty(selecting) || !existing) && workouts.length) {
      setSelecting(workouts[0]);
    }

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selecting, workouts]);

  useEffect(() => {
    if (workouts.length === 0 && !props.isLoading) {
      setSelecting({});
    }
  }, [workouts]);

  const onClose = () => {
    typeof props.onClose === 'function' && props.onClose();
  };

  const onSearchChange = (event, { value }) => {
    const valuedTrimmed = value.trim();

    setTextSearch(value);
    if (workoutTags !== null) {
      props.onSearch({ q: valuedTrimmed, tags: selectedTags });
    } else {
      props.onSearch(valuedTrimmed);
    }
  };

  const onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode !== 38 && keyCode !== 40) {
      return false;
    }

    if (!workouts.length) {
      return false;
    }

    if (!selecting) {
      return setSelecting(workouts[0]);
    }

    const index = _.findIndex(workouts, item => item._id === selecting._id);

    if (index === -1) {
      return setSelecting(workouts[0]);
    }

    let newIndex = index;

    if (keyCode === 40) {
      // arrow down
      if (index === workouts.length - 1) {
        return false;
      }

      newIndex++;
    } else {
      // arrow up
      if (index === 0) {
        return false;
      }

      newIndex--;
    }

    setSelecting(workouts[newIndex]);

    if (listRef.current) {
      const { scrollTop, offsetHeight, scrollHeight } = listRef.current;

      if (scrollHeight > offsetHeight) {
        const firstItemInCurrentView = Math.round(scrollTop / optionHeight);
        const lastItemInCurrentView = Math.round((scrollTop + offsetHeight) / optionHeight);

        if (newIndex <= firstItemInCurrentView || newIndex >= lastItemInCurrentView) {
          listRef.current.scrollTop = (newIndex + 1) * optionHeight - offsetHeight;
        }
      }
    }
  };

  const handleScroll = event => {
    if (listRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current;
      const bottom = scrollHeight - (scrollTop + clientHeight);
      if (bottom < 300 && props.loadMore) {
        props.loadMore();
      }
    }
  };

  const renderExtendedInfo = workout => {
    if (!displayOwner && !displayLastUsed) {
      return null;
    }

    const { last_interacted, author } = workout;

    return (
      <S.WorkoutExtendedInfo>
        {displayOwner && author ? <OwnerAvatar data={author} tooltipId={`workout-author__${workout._id}`} /> : null}
        {displayLastUsed && last_interacted ? (
          <div className="last-used">{timeSince(new Date(last_interacted))}</div>
        ) : null}
      </S.WorkoutExtendedInfo>
    );
  };

  const createNewTag = () => {};

  const handleApplyTags = filters => {
    setSelectedTags(filters.tags);
    props.onSearch({ q: textSearch.trim(), tags: filters.tags });
  };

  const renderWorkoutList = () => {
    const selectingId = _.get(selecting, '_id');
    const displayBackButton = typeof props.onBack === 'function';
    const displayCreateNew = typeof props.onCreateNew === 'function';
    const displayAIBtn = typeof props.onEverfitAIClick === 'function';

    return (
      <S.WorkoutListWrapper className={classNames({ 'with-ai-btn': displayAIBtn })}>
        <S.Header>
          <div className="title">
            {displayBackButton && (
              <img src={`${CDN_URL}/images/back_purple.svg`} onClick={props.onBack} alt="" className="back" />
            )}
            <span>{title}</span>
          </div>
          {(displayAIBtn || displayCreateNew) && (
            <div className="actions">
              {displayAIBtn && (
                <Button className="everfit-ai-btn" onClick={props.onEverfitAIClick}>
                  <StarsAIIcon /> Everfit AI
                </Button>
              )}
              {displayCreateNew && (
                <Button className="create-new" onClick={props.onCreateNew}>
                  {!displayAIBtn && <img src={`${CDN_URL}/images/plus_purple_2.svg`} alt="" />}
                  <span>Create New</span>
                </Button>
              )}
            </div>
          )}
        </S.Header>

        {!workoutTags ? (
          <SearchInput
            placeholder="Search workout"
            onAddOnIconClick={() => onSearchChange(null, { value: '' })}
            autoFocus={true}
            value={textSearch}
            onChange={onSearchChange}
          />
        ) : (
          <S.Toolbar>
            <SearchInput
              placeholder="Search workout"
              onAddOnIconClick={() => onSearchChange(null, { value: '' })}
              autoFocus={true}
              value={textSearch}
              onChange={onSearchChange}
            />
            <WorkoutTags
              showClearAll
              counterHasBackground
              createNewTag={createNewTag}
              tags={workoutTags}
              currentFilters={{ tags: selectedTags }}
              onApply={handleApplyTags}
              trigger={
                <S.TagsFilterTrigger>
                  <TagsFilterIcon />
                  <div className="tags-filter-btn-title">Tags</div>
                </S.TagsFilterTrigger>
              }
            />
          </S.Toolbar>
        )}
        <S.ListLabel>
          {textSearch.length ? 'Results' : props.listLabel || 'Most Recent'} ({total || workouts.length})
        </S.ListLabel>

        <S.ListContainer ref={listRef} onScroll={_.debounce(handleScroll, 200)}>
          <S.List loading={props.searching}>
            <div className="workoutList">
              {_.map(workouts, workout => {
                return (
                  <WorkoutItem
                    workout={workout}
                    selectingId={selectingId}
                    setSelecting={setSelecting}
                    renderExtendedInfo={renderExtendedInfo}
                  />
                );
              })}
              {props.isLoading ? <WorkoutLoader /> : null}
            </div>
            <div className="workoutList__loader">
              <WorkoutLoader />
            </div>
          </S.List>

          <div className="notdata">
            {workouts.length === 0 && !props.isLoading && <Layout.NoResultText>No data</Layout.NoResultText>}
          </div>
        </S.ListContainer>
      </S.WorkoutListWrapper>
    );
  };

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      className="modal--choose-workout"
      onClose={onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Content>
            {renderWorkoutList()}
            <WorkoutPreview workout={selecting} displayOwner={displayOwner} />
          </S.Content>
          <S.Actions>
            <div />
            <Button
              purple
              className="save"
              disabled={!!disableSelectButton || !selecting || _.isEmpty(selecting, true)}
              onClick={() => {
                if (typeof props.onSelect === 'function' && selecting) {
                  props.onSelect(selecting);
                }
              }}
            >
              Select
            </Button>
          </S.Actions>
        </S.Wrapper>
      </Modal.Content>
    </Modal>
  );
}

export default ChooseWorkoutModal;
