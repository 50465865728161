/**
 * Reuseable function on chart
 */

import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { convertUnit, formatMinValueToHM, mongoObjectId, roundNumberBodyMetric } from 'utils/commonFunction';
import {
  BOUNDARY_VALUES,
  DATE_FORMAT,
  UNDEFINED_OR_NULL_VALUE,
  METRIC_CHANGED_STATUS,
  PERIOD_GROUP,
  formatDate,
  DESIRED_HOUR,
  SPECIAL_METRICS_CODE,
} from './constants';

export const updateTooltipPosition = (
  chartRef,
  tooltipId,
  data,
  isModeCompare,
  isOverviewChart = false,
  barWidth = 0,
  isAllowOverlap = false,
) => {
  if (!chartRef) return;

  if (isModeCompare) {
    return { y: data.y + 2 };
  }

  const tooltip = document.getElementById(tooltipId);
  const { width: tooltipW, height } = tooltip ? tooltip.getBoundingClientRect() : { width: 0, height: 0 };

  const y = data.y - height - 10;

  const centerTooltip = 2;
  const posXOverlap = 95;
  const barWidthCenter = barWidth !== 0 ? barWidth / 2 : 0;
  const overviewAllowOverlap = isOverviewChart && isAllowOverlap;

  let x =
    data.x -
    tooltipW / centerTooltip +
    (overviewAllowOverlap ? barWidthCenter : data.x <= posXOverlap ? (!isOverviewChart ? 20 : 60) : barWidthCenter);

  if (x + tooltipW > chartRef.props.width) {
    x -= x + tooltipW - chartRef.props.width;
  }

  return { x, y };
};

export const convertDataToUnit = (unit, chartData, goalValue, decimal = 1) => {
  return chartData.map(item => ({
    ...item,
    goal: goalValue,
    value: item.value !== undefined ? convertUnit(item.value, item.unit, unit) : undefined,
    unit: unit && unit._id ? unit : item.unit,
  }));
};

export const getMinMaxValue = (data, enableConnectChart) => {
  let min = 0,
    max = 0;

  if (isEmpty(data)) return { min, max };

  const values = data
    .map(item => (enableConnectChart ? item.value || item.tempValue : item.value))
    .filter(value => value !== undefined);
  min = Math.min(...values);
  max = Math.max(...values);
  return { min, max };
};

export const getMinMaxHeartRateValue = data => {
  let min = 0,
    max = 0;

  if (isEmpty(data)) return { min, max };

  const maxValues = data
    .map(item => {
      return Math.max(...item.value);
    })
    .filter(value => !isNaN(value) && value !== undefined);

  max = Math.max(...maxValues);

  const minValues = data
    .map(item => {
      return Math.min(...item.value);
    })
    .filter(value => !isNaN(value) && value !== undefined && value > 0);

  min = Math.min(...minValues);
  min = min === max ? min : min;
  return { min, max };
};

export const getDataMin = (min, max, goalValue = 0) => {
  let result = 0;

  if (BOUNDARY_VALUES.includes(min) || BOUNDARY_VALUES.includes(max)) return 0;

  if (goalValue !== null && goalValue !== 0 && goalValue < min) {
    result = goalValue;
  } else {
    result = min;
  }

  return result <= 0 ? 0 : result;
};

export const getDataMax = (min, max, goalValue = 0) => {
  let result = 0;

  if (BOUNDARY_VALUES.includes(min) || BOUNDARY_VALUES.includes(max)) return 0;

  if (goalValue > max) {
    result = goalValue;
  } else {
    result = max;
  }

  return result <= 0 ? 0 : result;
};

export const fakeChartDataWithGoal = (goal, filledData) => {
  const goalData = new Array(7).fill({ goal: goal * 2 });

  if (filledData && filledData.length > 0) {
    const mergedData = filledData.map((item, index) => ({
      ...item,
      ...(goalData[index] || {}),
    }));
    return mergedData;
  }

  return goalData;
};

export const getSizeOfColumn = (finalData, isOverview, isDoubleChart) => {
  const len = finalData.length;
  if (isOverview) {
    if (len < 15) return isDoubleChart ? 10 : 20;
    if (len < 32) return isDoubleChart ? 4 : 10;
    if (len < 63) return isDoubleChart ? 2 : 4;
    return isDoubleChart ? 1 : 2;
  }

  if (len < 21) return 20;
  if (len < 31) return 10;
  if (len < 60) return 6;
  return 4;
};

export const getDurationTime = (rangeTime, unit = 'd') => {
  const { from_date, to_date } = rangeTime || {};
  return moment(to_date, DATE_FORMAT).diff(moment(from_date, DATE_FORMAT), unit) + 1;
};

export const getXAxisInterval = (rangeTime, isGroupOverview = false, isOverview, isDoubleChart, isChartLine) => {
  if (!rangeTime) return 0;

  if (isGroupOverview || isOverview) {
    const weeks = getDurationTime(rangeTime, 'week');
    const month = getDurationTime(rangeTime, 'month');

    if (rangeTime.period_group === PERIOD_GROUP.WEEKLY) {
      if (month === 6) {
        return isDoubleChart ? 10 : 5;
      }
      return isDoubleChart ? 4 : 2;
    }

    // remaining for daily
    if (month >= 2) {
      return isDoubleChart ? 20 : 10;
    }

    if (weeks === 4) {
      return isDoubleChart ? 10 : 5;
    }

    if (weeks === 2) {
      return isDoubleChart ? 4 : 2;
    }

    return isDoubleChart ? 2 : 0;
  }

  switch (rangeTime.period_group) {
    case PERIOD_GROUP.HOURLY:
      return 4;
    case PERIOD_GROUP.DAILY:
      const days = getDurationTime(rangeTime, 'd');
      if (days <= 7) return 0;

      const weeks = getDurationTime(rangeTime, 'week');
      if (weeks <= 2) return 2;

      const months = getDurationTime(rangeTime, 'month');
      if (months <= 1) return 4;
      if (months <= 2) return 8;
      return 12;
    case PERIOD_GROUP.WEEKLY:
      const durationMonths = getDurationTime(rangeTime, 'month');
      if (durationMonths <= 1) return 0;
      if (durationMonths <= 2) return 2;
      if (durationMonths <= 3) return 2;
      if (durationMonths <= 4) return 3;
      if (durationMonths <= 8) return 5;
      return 8;
    default:
      return 0;
  }
};

export const getWeekLabel = (startMM, endMM, formatByYear = false) => {
  const startSameYear = startMM.isSame(new Date(), 'year');
  const endSameYear = endMM.isSame(new Date(), 'year');

  if (formatByYear) {
    if (startMM.isSame(endMM, 'month')) {
      return `${startMM.format('MMM')} ${startMM.format('D')} - ${endMM.format(endSameYear ? 'D' : 'D, YYYY')}`;
    }
    if (startMM.isSame(endMM, 'year')) {
      return `${startMM.format('MMM D')} - ${endMM.format(endSameYear ? 'MMM D' : 'MMM D, YYYY')}`;
    }
    return `${startMM.format(startSameYear ? 'MMM D' : 'MMM D, YYYY')} - ${endMM.format(
      endSameYear ? 'MMM D' : 'MMM D, YYYY',
    )}`;
  }

  if (startMM.isSame(endMM, 'month')) {
    return `${startMM.format('MMM')} ${startMM.format('D')} - ${endMM.format('D')}`;
  }

  return `${startMM.format('MMM')} ${startMM.format('D')} - ${endMM.format('MMM')} ${endMM.format('D')}`;
};

export const getWeeksInRange = rangeTime => {
  const weeks = [];
  const startDateMM = moment(rangeTime.from_date, DATE_FORMAT);
  const startDateMMWeek = moment(startDateMM).startOf('week');
  const endDateMM = moment(rangeTime.to_date, DATE_FORMAT);

  while (startDateMMWeek.isSameOrBefore(endDateMM)) {
    const endClone = startDateMMWeek.clone().add(6, 'days').endOf('week');
    weeks.push({
      start: startDateMMWeek.format(DATE_FORMAT),
      end: endClone.format(DATE_FORMAT),
      group_name: `${startDateMMWeek.year()}-${startDateMMWeek.weeks() > 9 ? '' : '0'}${startDateMMWeek.weeks()}`,
      label: getWeekLabel(startDateMMWeek, endClone),
    });
    startDateMMWeek.add(1, 'week');
  }
  return weeks;
};

export const fillChartDataByHours = (chartData, rangeTime, uniqueCode) => {
  const { from_date, period_group } = rangeTime || {};
  const chartItemCloned = cloneDeep(chartData[0]);
  const fromDateMoment = moment(from_date, DATE_FORMAT);
  const items = [];

  for (let i = 0; i < 24; i++) {
    const day = fromDateMoment.clone().add(i, 'h');

    let chartItem;
    switch (uniqueCode) {
      case SPECIAL_METRICS_CODE.HEART_RATE:
        chartItem = chartData.find(item => {
          const timeString = get(item, 'group_name', '').replace(':', 'T') + ':00:00';
          return day.format('hA') === moment(timeString).format('hA');
        });
        if (chartItem) {
          const timeString = get(chartItem, 'group_name', '').replace(':', 'T') + ':00:00';
          chartItem.time = moment(timeString).format('hA');
          chartItem.timeLabel = moment(timeString).format('h A');
          chartItem.value = [chartItem.min, chartItem.max];
          chartItem.period_group = period_group;
        }
        break;

      default:
        chartItem = chartData.find(item => {
          return day.format('hA') === moment(item.date).format('hA');
        });
        if (chartItem) {
          chartItem.time = moment(chartItem.date).format('hA');
        }
        break;
    }

    items.push(
      chartItem || {
        ...chartItemCloned,
        _id: mongoObjectId(),
        time: day.format('hA'),
        value: undefined,
      },
    );
  }
  return items;
};

export const fillChartDataByDays = (chartData, rangeTime, uniqueCode) => {
  const { from_date, period_group } = rangeTime || {};
  const days = getDurationTime(rangeTime);
  const fromDateMoment = moment(from_date, DATE_FORMAT);
  const chartItemCloned = cloneDeep(chartData[0]);
  const items = [];

  for (let i = 0; i < days; i++) {
    let chartItem;
    const day = fromDateMoment.clone().add(i, 'd');
    switch (uniqueCode) {
      case SPECIAL_METRICS_CODE.HEART_RATE:
        chartItem = chartData.find(item => {
          return day.format('MM-DD-YYYY') === moment(get(item, 'group_name')).format('MM-DD-YYYY');
        });

        if (chartItem) {
          const isSameYear = moment(chartItem.group_name).isSame(new Date(), 'year');
          chartItem.time = moment(chartItem.group_name).format('MMM D');
          chartItem.timeLabel = moment(chartItem.group_name).format(isSameYear ? 'MMM D' : 'MMM D, YYYY');
          chartItem.value = [chartItem.min, chartItem.max];
          chartItem.period_group = period_group;
        }
        break;

      default:
        chartItem = chartData.find(item => day.format('MM-DD-YYYY') === item.day);
        if (chartItem) {
          chartItem.time = moment(chartItem.day, 'MM-DD-YYYY').format('MMM D');
        }

        break;
    }

    items.push(
      chartItem || {
        ...chartItemCloned,
        _id: mongoObjectId(),
        time: day.format('MMM D'),
        value: undefined,
      },
    );
  }
  return items;
};

export const fillChartDataByWeeks = (chartData, rangeTime, uniqueCode) => {
  const { period_group = '' } = rangeTime;
  const items = [];
  const weeks = getWeeksInRange(rangeTime);
  const chartItemCloned = cloneDeep(chartData[0]);
  for (let week of weeks) {
    let chartItem;
    switch (uniqueCode) {
      case SPECIAL_METRICS_CODE.HEART_RATE:
        chartItem = chartData.find(item => week.group_name === item.group_name);
        if (chartItem) {
          const startMM = moment(chartItem.start, DATE_FORMAT);
          const endMM = moment(chartItem.end, DATE_FORMAT);
          chartItem.time = getWeekLabel(startMM, endMM);
          chartItem.timeLabel = getWeekLabel(startMM, endMM, true);
          chartItem.value = [chartItem.min, chartItem.max];
          chartItem.period_group = period_group;
        }
        break;

      default:
        chartItem = chartData.find(item => week.group_name === item.group_name);
        if (chartItem) {
          const startMM = moment(chartItem.start, DATE_FORMAT);
          const endMM = moment(chartItem.end, DATE_FORMAT);
          chartItem.time = getWeekLabel(startMM, endMM);
        }
        break;
    }

    items.push(
      chartItem || {
        ...chartItemCloned,
        ...week,
        _id: mongoObjectId(),
        time: week.label,
        value: undefined,
      },
    );
  }
  return items;
};

export const fillChartDataByMonths = (chartData, rangeTime, uniqueCode) => {
  const { from_date, to_date, period_group } = rangeTime || {};
  const items = [];
  const chartItemCloned = cloneDeep(chartData[0]);
  const startDateMM = moment(from_date, DATE_FORMAT);
  const startDateMMMonth = moment(startDateMM).startOf('month');
  const endDateMM = moment(to_date, DATE_FORMAT);

  while (startDateMMMonth.isSameOrBefore(endDateMM)) {
    let chartItem;
    switch (uniqueCode) {
      case SPECIAL_METRICS_CODE.HEART_RATE:
        chartItem = chartData.find(item => startDateMMMonth.format('YYYY-MM') === item.group_name);
        if (chartItem) {
          const isSameYear = moment(chartItem.group_name).isSame(new Date(), 'year');
          chartItem.time = moment(chartItem.start, DATE_FORMAT).format('MMM');
          chartItem.timeLabel = moment(chartItem.start, DATE_FORMAT).format(isSameYear ? 'MMM' : 'MMM YYYY');
          chartItem.value = [chartItem.min, chartItem.max];
          chartItem.period_group = period_group;
        }
        break;

      default:
        chartItem = chartData.find(item => startDateMMMonth.format('YYYY-MM') === item.group_name);
        if (chartItem) {
          chartItem.time = moment(chartItem.start, DATE_FORMAT).format('MMM');
        }
        break;
    }

    items.push(
      chartItem || {
        ...chartItemCloned,
        _id: mongoObjectId(),
        time: startDateMMMonth.format('MMM'),
        value: undefined,
      },
    );
    startDateMMMonth.add(1, 'month');
  }

  return items;
};

export const fillChartDataByPeriodTime = (chartData, rangeTime, unique_code = '') => {
  switch (rangeTime.period_group) {
    case PERIOD_GROUP.HOURLY:
      return fillChartDataByHours(chartData, rangeTime, unique_code);
    case PERIOD_GROUP.DAILY:
      return fillChartDataByDays(chartData, rangeTime, unique_code);
    case PERIOD_GROUP.WEEKLY:
      return fillChartDataByWeeks(chartData, rangeTime, unique_code);
    default:
      return fillChartDataByMonths(chartData, rangeTime, unique_code);
  }
};

export const getXLabels = (filledData, interval) => {
  const labels = [];

  const nextItems = (filledData || []).filter(item => item.isAddNextDay);
  const temp = nextItems.length > 0 ? filledData.length - nextItems.length - 1 : filledData.length - 1;

  for (let i = temp; i >= 0; i -= interval) {
    if (filledData[i]) {
      labels.push(filledData[i].time);
    }
  }

  return labels;
};

export const formatWeeklyLabel = (label, period_group) => {
  if (period_group === PERIOD_GROUP.WEEKLY) {
    const [finalLabel] = (label || '').split(' - ');
    return finalLabel || '';
  }
  return label;
};

export const getTimeLabel = (item, isBottom = false) => {
  const { start, end, date, period_group, previousDate, previousStart, previousEnd } = item;
  const startMoment = moment(start);
  const endMoment = moment(end);
  const dateMoment = moment(date);
  const previousStartMoment = previousStart ? moment(previousStart) : startMoment;
  const previousEndMoment = previousEnd ? moment(previousEnd) : endMoment;
  const previousDateMoment = previousDate ? moment(previousDate) : dateMoment;
  const isSameMonth = startMoment.isSame(endMoment, 'M');
  const isSameYear = startMoment.isSame(endMoment, 'year');
  const today = moment(new Date());
  const isSameYearWithToday = dateMoment.isSame(today, 'year');

  switch (period_group) {
    case PERIOD_GROUP.HOURLY:
      if (isBottom) {
        return formatDate(previousDateMoment, isSameYearWithToday, 'MMM D, h A', 'MMM D, h A');
      }
      return formatDate(previousDateMoment, isSameYearWithToday, 'h A', 'h A');
    case PERIOD_GROUP.WEEKLY:
      return (
        formatDate(previousStartMoment, isSameYearWithToday, 'MMM D', isSameYear ? 'MMM D' : 'MMM D, YYYY') +
        ' - ' +
        formatDate(
          previousEndMoment,
          isSameYearWithToday,
          isSameMonth ? 'D' : 'MMM D',
          isSameMonth ? 'D, YYYY' : 'MMM D, YYYY',
        )
      );
    case PERIOD_GROUP.MONTHLY:
      return formatDate(dateMoment, isSameYearWithToday, 'MMM', 'MMM YYYY');
    default:
      // PERIOD_GROUP.DAILY
      return formatDate(dateMoment, isSameYearWithToday, 'MMM D', 'MMM D, YYYY');
  }
};

const calculateMetricChange = (chartData, itemIndex) => {
  const chartItem = chartData[itemIndex];

  if (!chartItem) return undefined;

  const {
    comparePercentage: prevItem,
    value: curItemValue = 0,
    date: curDate,
    time,
    start,
    end,
    day,
    previousEntry,
    period_group,
  } = chartItem;

  const { time: prevTime, previousDate, previousValue } = prevItem || {};
  const { start: previousEntryStart, end: previousEntryEnd, date: previousEntryDate } = previousEntry || {};

  if (isEmpty(prevItem)) return undefined;

  if (prevTime !== time) {
    const newPrevItem =
      !isEmpty(chartData) && chartData[0].value !== undefined
        ? omit(prevItem, ['previousValue', 'previousStart', 'previousEnd'])
        : prevItem;

    return calculateChange(newPrevItem, curItemValue, curDate);
  }

  if (prevTime === time) {
    const hasPreviousEntry = !isEmpty(chartItem.previousEntry) && !['hourly', 'daily'].includes(period_group);
    const isSameStartDate = hasPreviousEntry && moment(previousEntryStart).isSame(moment(start), 'day');
    const isSameEndDate = hasPreviousEntry && moment(previousEntryEnd).isSame(moment(end), 'day');
    const isSameDate = hasPreviousEntry && moment(previousEntryDate).isSame(moment(day), 'day');

    if ((isSameStartDate && isSameEndDate) || isSameDate || previousEntry === null) {
      return undefined;
    }

    const newPrevItem = { ...prevItem, date: previousDate, value: previousValue };

    return calculateChange(newPrevItem, curItemValue, curDate);
  }

  return undefined;
};

const calculateChange = (prevItem, curItemValue, curDate) => {
  const diff = curItemValue - prevItem.value;
  const percent = (Math.abs(diff) / prevItem.value) * 100;
  const status = diff > 0 ? METRIC_CHANGED_STATUS.INCREASE : METRIC_CHANGED_STATUS.DECREASE;
  const isSameDay = moment(curDate).isSame(prevItem.date, 'day');
  const prevLabel = getTimeLabel(prevItem, !isSameDay);

  return {
    status: diff !== 0 ? status : METRIC_CHANGED_STATUS.NO,
    percent: percent !== Infinity && Number(percent.toFixed(1)),
    date: `from ${prevLabel}`,
  };
};

const getPreviousItem = chartData => {
  if (isEmpty(chartData)) return {};

  const data = chartData[0];
  const { previousEntry, previousValue = '', previousDay = '' } = data || {};

  if (chartData.length > 0 && chartData[0].value !== undefined) {
    const { date, start, end } = previousEntry || {};

    return {
      ...data,
      previousDate: date ? date : null,
      previousValue: previousValue ? previousValue : null,
      previousStart: start ? start : null,
      previousEnd: end ? end : null,
    };
  }

  if (data && !!previousValue && !!previousDay && !isEmpty(previousEntry)) {
    return {
      value: previousValue,
      start: previousEntry.start || data.start,
      end: previousEntry.end || data.end,
      date: previousDay,
      period_group: data.period_group,
      previousDate: data.period_group === 'hourly' ? previousEntry.date : '',
    };
  }

  return findFirstValue(chartData);
};

const findFirstValue = arr => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].value !== undefined) {
      return arr[i];
    }
  }
  return {};
};

export const compareItemToPrevious = (itemData, chartData) => {
  if (isEmpty(itemData)) return undefined;

  const itemIndex = chartData.findIndex(item => item._id === itemData._id);

  if (itemIndex !== -1 && !isEmpty(chartData[itemIndex])) {
    return calculateMetricChange(chartData, itemIndex);
  }

  return undefined;
};

export const fillChartDataBySettings = (chartData = [], color, name) => {
  return isEmpty(chartData) ? [] : chartData.map(item => ({ ...item, chartColor: color, chartName: name }));
};

export const calculateComparedSharedPrevItem = (index, data) => {
  if (index !== -1 && !isEmpty(data[index])) {
    return calculateMetricChange(data, index);
  }

  return undefined;
};

export const calculateComparedChartInfo = (data, index) => {
  return !isEmpty(data) && index !== -1 ? data[index] : undefined;
};

export const filledComparePercentage = (data, dataPoint) => {
  return data.map(item => {
    if (dataPoint) {
      return {
        ...item,
        dataPoint,
        comparePercentage: getPreviousItem(data),
      };
    }

    return {
      ...item,
      comparePercentage: getPreviousItem(data),
    };
  });
};

// const getDigitCount = value => {
//   if (value === 0) {
//     return 1;
//   }
//   return Math.floor(Math.log10(Math.abs(value))) + 1;
// };

// const getFormatStep = (roughStep, allowDecimals) => {
//   if (roughStep <= 0) {
//     return 0;
//   }

//   const digitCount = getDigitCount(roughStep);
//   const digitCountValue = Math.pow(10, digitCount);
//   const stepRatio = roughStep / digitCountValue;
//   const stepRatioScale = digitCount !== 1 ? 0.05 : 0.1;
//   const amendStepRatio = Math.ceil(stepRatio / stepRatioScale) * stepRatioScale;
//   const formatStep = amendStepRatio * digitCountValue;

//   return allowDecimals ? formatStep : Math.ceil(formatStep);
// };

// const rangeStep = (min, max, step) => {
//   const result = [];
//   let num = min;
//   while (num < max) {
//     result.push(num);
//     num += step;
//   }
//   return result;
// };

// This function, getTickCount, does not use.
// export const getTickCount = (min, max, tickCount, allowDecimals) => {
//   const count = Math.max(tickCount, 2);
//   const roughStep = (max - min) / (count - 1);
//   const step = getFormatStep(roughStep, allowDecimals);
//   const newMax = max - 0.99 * step;
//   const values = [...rangeStep(min, newMax, step), max];
//   const newTickCount = isEmpty(values) || values.length <= 1 ? tickCount : values.length;

//   return newTickCount;
// };

const findStepTemp = (gapStep, arrStep) => arrStep.findIndex(step => gapStep <= step);

const findNearestMultiple = (value, step) => {
  const quotient = value / step;
  return Math.floor(quotient) * step;
};

export const getNewDataMinMax = (minValue, maxValue, isTimeUnit, ratio = 0.55, isShortToolTip = false) => {
  const gapValue = maxValue - minValue;
  const gapStep = gapValue * ratio;
  let step;

  if (!isTimeUnit && !isShortToolTip && gapStep <= 5) {
    const arrStepNonTimeUnit = [0.5, 1, 2, 3, 4, 5];
    let index = findStepTemp(gapStep, arrStepNonTimeUnit);
    step = index !== -1 ? arrStepNonTimeUnit[index] : Math.ceil(gapStep / 10) * 10;
  } else if (isTimeUnit) {
    const arrStepTimeUnit = [1, 5, 10, 20, 30];
    let index = findStepTemp(gapStep, arrStepTimeUnit);
    step = index !== -1 ? arrStepTimeUnit[index] : Math.ceil(gapStep / 60) * 60;
  } else if (isShortToolTip) {
    const arrStepUnit = [1, 2, 3, 4, 5];
    let index = findStepTemp(gapStep, arrStepUnit);
    step = index !== -1 ? arrStepUnit[index] : Math.ceil(gapStep / 10) * 10;
  } else {
    step = Math.ceil(gapStep / 10) * 10;
  }

  let chartMinValue = findNearestMultiple(minValue, step);

  if (minValue - chartMinValue <= step / 3) {
    chartMinValue -= step;
  }

  chartMinValue = Math.max(0, chartMinValue);

  let tickCountTemp = 3;
  const chartHeightTemp = 305;
  const markerHeight = isShortToolTip ? 80 : 110; // Height tooltip + spacing between bar and tooltip
  const extraTopOffset = 50; // Spacing from top chart to header key metrics
  const contentChartHeight = chartHeightTemp + extraTopOffset;

  while (tickCountTemp < 6) {
    const tempChartMax = chartMinValue + step * (tickCountTemp - 1);
    const heightPerValue = chartHeightTemp / (tempChartMax - chartMinValue);
    const maxValueHeight = heightPerValue * (maxValue - chartMinValue);

    if (maxValueHeight + markerHeight < contentChartHeight) {
      break;
    }

    tickCountTemp++;
  }

  const chartMaxValue = chartMinValue + step * (tickCountTemp - 1);

  return [chartMinValue, chartMaxValue, tickCountTemp];
};

export const getTicks = (minValue, maxValue, tickCount) => {
  let result = [];

  const step = (maxValue - minValue) / (tickCount - 1);

  for (let i = minValue; i <= maxValue; i += step) {
    result.push(i);
  }

  return result;
};

export const roundAndTrim = value => {
  const rounded = round(value, 2);
  let roundedStr = rounded.toString();

  roundedStr = roundedStr.replace(/(\.\d*?[1-9])0+$/, '$1');
  roundedStr = roundedStr.replace(/\.$/, '');

  return parseFloat(roundedStr);
};

export const convertUnitTime = value => {
  return ((value / 60) * 2) / 2;
};

const formatOutput = (value, unit, newDataMin, isConvertValue) => {
  if (value === 0 || value === newDataMin) {
    return '';
  }

  switch (unit) {
    case 'ft':
      return `${roundAndTrim(value)} ${unit}`;
    case 'min':
      return formatMinValueToHM(value, true, isConvertValue);
    case 'sec':
      return formatSecValueToSM(value, true, isConvertValue);
    default:
      return value === 0 || value === newDataMin ? '' : value;
  }
};

export const handleUnitFormat = (selectedUnit, value, newDataMin, isConvertValue) => {
  if (isEmpty(selectedUnit)) {
    return value === 0 || value === newDataMin ? '' : value;
  }

  return formatOutput(value, get(selectedUnit, 'unique_code', ''), newDataMin, isConvertValue);
};

export const convertSecToSecMin = value => {
  if (value < 60) return { min: 0, sec: value };

  const min = Math.floor(value / 60);
  const sec = Math.floor(value - min * 60);
  return { min, sec };
};

export const formatSecValueToSM = (value, isNewFormat = false, isConvertValue = false) => {
  const valueRounded = roundNumberBodyMetric(value, 1);
  const strSec = isNewFormat ? `${valueRounded} s` : `${valueRounded} sec`;

  if (valueRounded < 60 || isConvertValue) return strSec;

  const { min, sec } = convertSecToSecMin(valueRounded);
  const strSecAndMin = isNewFormat
    ? `${min}${sec > 0 ? 'm' : ' m'}${sec > 0 ? ` ${sec}s` : ''}`
    : `${min} min${sec > 0 ? ` ${sec} sec` : ''}`;

  return strSecAndMin;
};

export const handleShowCursor = (isExercise = false) => {
  const elementString = isExercise ? '.exercise-metric-chart-wrapper' : '.metric-chart';
  const tooltipWrapper = document.querySelector('.recharts-tooltip-wrapper');
  const svgEle = document.querySelector(`${elementString} .recharts-surface`);

  if (tooltipWrapper && svgEle) {
    const tooltipPositionTop = get(tooltipWrapper, 'style.top', '') !== '0px';
    const tooltipPositionLeft = get(tooltipWrapper, 'style.left', '') !== '0px';
    const tooltipVisible =
      get(tooltipWrapper, 'style.visibility', '') === 'visible' || (tooltipPositionTop && tooltipPositionLeft);
    svgEle.style.cursor = tooltipVisible ? 'pointer' : 'default';
  }
};

export const filledPrevNextValues = (data, prevValue, nextValue) => {
  if (isEmpty(data)) return;

  let newData = [...data];
  const isMore45 = newData.length >= 45;
  const isMore60 = newData.length >= 62;
  const shouldAddMore = isMore60 || isMore45;
  const item = { value: undefined, time: null };
  const firstItem = newData[0];
  const lastItem = newData[newData.length - 1];

  if (
    !UNDEFINED_OR_NULL_VALUE.includes(prevValue) &&
    !UNDEFINED_OR_NULL_VALUE.includes(firstItem.value || shouldAddMore)
  ) {
    newData.unshift({ ...item, isAddPrevDay: true });
    isMore45 && newData.unshift({ ...item, isAddPrevDay: true });
    isMore60 && newData.unshift({ ...item, isAddPrevDay: true });
  }

  if (
    !UNDEFINED_OR_NULL_VALUE.includes(nextValue) &&
    (!UNDEFINED_OR_NULL_VALUE.includes(lastItem.value) || shouldAddMore)
  ) {
    newData.push({ ...item, isAddNextDay: true });
    isMore45 && newData.push({ ...item, isAddNextDay: true });
    isMore60 && newData.push({ ...item, isAddNextDay: true });
  }

  newData = newData.map(item => ({
    ...item,
    tempValue: item.value,
  }));

  if (!UNDEFINED_OR_NULL_VALUE.includes(prevValue) && UNDEFINED_OR_NULL_VALUE.includes(newData[0].tempValue)) {
    newData[0].tempValue = prevValue;
  }

  if (
    !UNDEFINED_OR_NULL_VALUE.includes(nextValue) &&
    UNDEFINED_OR_NULL_VALUE.includes(newData[newData.length - 1].tempValue)
  ) {
    newData[newData.length - 1].tempValue = nextValue;
  }

  return newData;
};

export const getLastValueContent = periodGroup => {
  switch (periodGroup) {
    case PERIOD_GROUP.HOURLY:
    case PERIOD_GROUP.DAILY:
      return '';
    case PERIOD_GROUP.WEEKLY:
    case PERIOD_GROUP.MONTHLY:
      return '• Average';

    default:
      return '';
  }
};

export const getAverageValueContent = periodGroup => {
  switch (periodGroup) {
    case PERIOD_GROUP.HOURLY:
      return '';
    case PERIOD_GROUP.DAILY:
      return '';
    case PERIOD_GROUP.WEEKLY:
    case PERIOD_GROUP.MONTHLY:
      return '• Average';

    default:
      return '';
  }
};

export const getTotalOfAllValuesContent = periodGroup => {
  switch (periodGroup) {
    case PERIOD_GROUP.HOURLY:
    case PERIOD_GROUP.DAILY:
      return '';
    case PERIOD_GROUP.WEEKLY:
    case PERIOD_GROUP.MONTHLY:
      return '• Average';

    default:
      return '';
  }
};

export const handleHourlyTickFormat = (value, isViewTime = false) => {
  let newValue = Math.floor(+value);

  if (isViewTime) {
    if (value < 12) {
      const tick = value + ' PM';
      return tick === '0 PM' ? '12 PM' : value < 0 ? value + 12 + ' AM' : tick;
    }
    newValue -= 12;
    let isPM = false;
    if (newValue >= 12) {
      isPM = true;
      newValue -= 12;
    }
    const tick = newValue + (isPM ? ' PM' : ' AM');
    return tick === '0 PM' ? '12 PM' : tick === '0 AM' ? '12 AM' : tick;
  }

  return value + 'H';
};
