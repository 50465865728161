import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import * as S from './style';

const ARROW_ALIGN = ['start', 'center', 'end']; //can improve by pass the number: something like left: 20px
const PLACES = ['top', 'right', 'bottom', 'left'];

const isSafariBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    return true;
  }

  return false;
};

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  width: ${props => `${props.maxWidth || 300}px`};
  cursor: default;

  b {
    font-weight: 600;
  }
`;

export default function Tooltip(props) {
  const { children, anchorProps, showInModal } = props;
  const [position, setPosition] = useState({});

  const contentRef = useRef();
  const anchorRef = useRef();
  const aura = useRef();
  const place = PLACES.includes(props.place) ? props.place : 'top';
  const arrowAlign = ARROW_ALIGN.includes(props.align) ? props.align : 'center';
  const offset = Number(props.offset || 0) || 15;
  const isSafari = isSafariBrowser();

  const getPosition = () => {
    let result = { place };

    if (contentRef.current) {
      if (anchorRef.current) {
        const { offsetWidth, offsetHeight } = contentRef.current;
        const triggerRect = anchorRef.current.getBoundingClientRect();
        const { x, y, width, height } = triggerRect;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const position = {};

        switch (place) {
          case 'top':
          case 'bottom':
            position.top = place === 'top' ? -1 * (offset + offsetHeight) : height + offset;

            if (arrowAlign === 'center') {
              position.left = -1 * ((offsetWidth - width) / 2);
            } else if (arrowAlign === 'start') {
              position.left = -1 * offset;
            } else {
              position.left = -1 * (offsetWidth - width - offset);
            }

            if (x + position.left + offsetWidth > windowWidth) {
              position.left = -1 * (offsetWidth - width - offset);
            }

            break;

          case 'right':
          case 'left':
            position.left = place === 'right' ? width + offset : -1 * (offset + offsetWidth);

            if (arrowAlign === 'center') {
              position.top = -1 * ((offsetHeight - height) / 2);
            } else if (arrowAlign === 'start') {
              position.top = -30;
            } else {
              position.top = -1 * (offsetHeight - height - 30);
            }

            break;

          default:
            break;
        }

        result = { position, place };
      }
    }

    return result;
  };

  useLayoutEffect(() => {
    setPosition(getPosition());
    setTimeout(() => {
      if (isSafari && showInModal && aura.current.style) {
        aura.current.style.webkitAnimationPlayState = 'running';
      }
    }, 100);

    return () => {
      const navbar = document.querySelector('.app-navbar');
      if (navbar) {
        navbar.style.zIndex = 999;
      }
    };
  }, []);

  let styles = {},
    placeClass = position.place;

  if (position.position && !props.customPosition) {
    styles = { ...position.position, visibility: 'visible' };
    const appNavbar = document.querySelector('.app-navbar');
    if (appNavbar) {
      appNavbar.style.zIndex = 1;
    }
  }

  return (
    <S.Wrapper {...props} className={classNames('onboarding-tooltip', placeClass)} offset={offset}>
      <S.Anchor {...anchorProps} hideAnchor={props.hideAnchor} ref={anchorRef} classNames="onboarding-tooltip__anchor">
        <S.Aura ref={aura} style={{ animationPlayState: isSafari && showInModal ? 'paused' : 'running' }} />
      </S.Anchor>
      <S.Arrow className="onboarding-tooltip__arrow" />
      <S.Popup className="onboarding-tooltip__content" ref={contentRef} style={styles}>
        {children}
        {typeof props.onClose === 'function' ? (
          <S.Close className="onboarding-tooltip__close-button">
            <a onClick={props.onClose}>Got it</a>
          </S.Close>
        ) : null}
      </S.Popup>
    </S.Wrapper>
  );
}
