import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;

  img {
    margin-right: 6px;
  }
`;

export const Divide = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0eff3;
  margin: 15px 0;
`;

export const Note = styled.div`
  background: #f3f5f8;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #4e5662;

  ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    li {
      margin-bottom: 6px;
    }
  }
`;

export const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  padding: 15px 0 30px;

  b {
    color: #000;
    font-weight: 600;
  }

  .connect-forum-type {
    margin: 10px 0;

    .radio {
      margin: 5px 0;
    }

    label {
      font-weight: normal !important;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 5px;
  }

  button {
    padding: 5px 30px;
  }

  .action-btn {
    background: #5158cf;
    border-radius: 5px;
    color: #fff;
  }
`;

export const Container = styled.div`
  padding: 30px;
  border-radius: 5px;
`;

export const ModalStyle = styled(Modal)`
  width: 482px !important;

  .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }
`;
