import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { Button } from 'shared/FormControl';
import { getListStudioCollection, loadMoreStudioCollection } from 'redux/studio-collection/actions';
import CollectionItem from 'components/StudioCollectionList/components/CollectionList/CollectionItem';
import LoadingIndicator from 'shared/LoadingIndicator';

class CollectionArea extends React.Component {
  constructor(props) {
    super(props);
    this.handleScrollDebounce = _.debounce(this.handleScrollDebounce, 300);
    this.listRef = React.createRef();
    this.state = {
      isFetched: false,
    };
  }

  componentDidMount() {
    this.props.getListStudioCollection().finally(() => {
      this.setState({ isFetched: true });
    });
  }

  handleScroll = event => {
    event.persist();
    this.handleScrollDebounce.call(null, event);
  };

  handleScrollDebounce = event => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    const { list, total, loadMore } = this.props;
    const bottom = scrollWidth - (scrollLeft + clientWidth);
    if (list.length < total && bottom < 300) {
      loadMore();
    }
  };

  goToDetail = collection => {
    this.props.push(`/home/studio-collection/${collection._id}`);
  };

  render() {
    const { isFetched } = this.state;
    const { list, user } = this.props;

    if (!isFetched) {
      return (
        <S.Wrapper>
          <div className="collectionArea__heading">Collections</div>
          <S.LoadingWrapper>
            <LoadingIndicator
              size="medium"
              title="Loading resource collections"
              className="loading--resource-collections"
            />
          </S.LoadingWrapper>
        </S.Wrapper>
      );
    }
    return (
      <S.Wrapper>
        <div className="collectionArea__heading">Collections</div>
        {list.length ? (
          <S.List onScroll={this.handleScroll}>
            {list.map((item, index) => (
              <CollectionItem
                id={`collection-${index}`}
                key={item._id}
                collection={item}
                onGoDetail={this.goToDetail}
                user={user}
              />
            ))}
            <S.AddCollectionButton onClick={this.props.onCreateNewCollection}>
              <span>Create New Collection</span>
            </S.AddCollectionButton>
          </S.List>
        ) : (
          <div className="collectionArea__content">
            <div className="collectionArea__image">
              <img alt="collection_area_cover_img" src={`${CDN_URL}/images/collection_area_cover_img.png`} />
            </div>
            <div className="collectionArea__info">
              <div className="collectionArea__info--title">Create a Resource Collection</div>
              <div className="collectionArea__info--description">
                Group resources together and choose which clients to give access to the collection.
              </div>
              <div className="collectionArea__actions">
                <Button className="collectionArea__actions--button" onClick={this.props.onCreateNewCollection}>
                  Create
                </Button>
                <Link to="/home/studio-collection" className="collectionArea__actions--goToCollection">
                  <span>Go to collection</span>
                  <img src={`${CDN_URL}/images/arrow_right_blue.svg`} alt="arrow_right_blue" />
                </Link>
              </div>
            </div>
          </div>
        )}
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      studioCollection: { list, total, isSearching },
    },
    sideBarVisible,
    user,
  } = state;
  return { list, total, sideBarVisible, isSearching, user };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  loadMore: bindActionCreators(loadMoreStudioCollection, dispatch),
  getListStudioCollection: bindActionCreators(getListStudioCollection, dispatch),
});

export default connect(mapState, mapDispatch)(CollectionArea);
