import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { ReactComponent as GroupOverviewIcon } from 'assets/icons/group-tag-overview.svg';

import * as S from './styles';

const GroupOverview = props => {
  const { groupsOfClient } = props;

  if (!groupsOfClient.length) {
    return null;
  }

  const handleGoToFilterGroup = groupId => {
    window.open(`/home/client?groupId=${groupId}`, '_blank');
  };

  return (
    <S.ProfilePart className="profile-part">
      <S.IconContainer>
        <GroupOverviewIcon />
      </S.IconContainer>
      <S.ContentContainer>
        <span>Group:&nbsp;</span>
        {groupsOfClient.map((item, index) => {
          return (
            <S.GroupOpenPopup key={get(item, '_id', '')} onClick={() => handleGoToFilterGroup(get(item, '_id', ''))}>
              {get(item, 'name')}
              {index < groupsOfClient.length - 1 && `, `}
            </S.GroupOpenPopup>
          );
        })}
      </S.ContentContainer>
    </S.ProfilePart>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      client: {
        workingClientDetail: { groups = [] },
      },
      group: { list = [] },
    },
  } = state;

  const groupsOfClient = list.filter(item => groups.some(it => it === get(item, '_id', '')));

  return {
    groupsOfClient,
  };
};

export default connect(mapStateToProps, null)(GroupOverview);
