import { Types } from './action';

export const INITIAL_STATE = {
  isGettingChartData: false,
  gettingEntriesByDay: false,
  entries: [],
  chartData: [],
  value_range: {},
  next_date: null,
  previous_date: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CLIENT_REQUEST_GET_HEART_CHART_DATA:
      return Object.assign({}, state, {
        isGettingChartData: true,
      });

    case Types.CLIENT_REQUEST_GET_HEART_ENTRIES_BY_DAY:
      return Object.assign({}, state, {
        gettingEntriesByDay: true,
      });

    case Types.CLIENT_FAILED_GET_HEART_CHART_DATA:
      return Object.assign({}, state, {
        isGettingChartData: false,
      });

    case Types.CLIENT_FAILED_GET_HEART_ENTRIES_BY_DAY:
      return Object.assign({}, state, {
        gettingEntriesByDay: false,
      });

    case Types.CLIENT_SUCCESS_GET_HEART_ENTRIES_BY_DAY:
      const { data: entries = [], previous_date = '', next_date = '' } = payload || {};

      return Object.assign({}, state, {
        entries: entries,
        next_date: next_date,
        previous_date: previous_date,
        isGettingChartData: false,
      });

    case Types.CLIENT_SUCCESS_GET_HEART_CHART_DATA:
      const { data = [], value_range = {} } = payload || {};

      return Object.assign({}, state, {
        chartData: data,
        isGettingChartData: false,
        value_range: value_range,
      });

    default:
      return state;
  }
};
