import styled from 'styled-components';
import CalculateMacrosModalStyles, {
  HeaderStyles as HeaderCalculateMacrosModalStyles,
  ContentStyles as ContentCalculateMacrosModalStyles,
  ActionsStyles as ActionsCalculateMacrosModalStyles,
} from '../CalculateMacrosModal/styles';

export const HeaderStyles = styled(HeaderCalculateMacrosModalStyles)``;

export const ContentStyles = styled(ContentCalculateMacrosModalStyles)`
  padding-top: 0 !important;
`;

export const ActionsStyles = styled(ActionsCalculateMacrosModalStyles)``;

export const GramFormStyles = styled.div`
  margin-top: 15px;
`;

export const PercentFormStyles = styled.div`
  margin-top: 15px;
`;

export default styled(CalculateMacrosModalStyles)`
  .goal-types-select {
    width: 216px;
    height: 34px;
    margin: auto;
    background: #f6f6f8;
    border-radius: 3px;
    padding: 4px;
    display: flex;
    margin-bottom: 15px;

    .goal-type {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #202353;
      cursor: pointer;

      &.selected {
        background: #202353;
        border-radius: 3px;
        color: #ffffff;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;

    label {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #6a778a;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;

      &.protein-dot {
        background-color: #57b5ea;
      }
      &.carbs-dot {
        background-color: #65cc9c;
      }
      &.fat-dot {
        background-color: #ffbe49;
      }
    }
  }

  .total-cal {
    height: 84px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #f6f6f8;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;

    &.percent-form {
      height: auto;
      padding-bottom: 9px;
    }

    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      margin-bottom: 5px;
    }

    .result {
      font-size: 32px;
      line-height: 100%;
      color: #5158cf;

      span {
        font-weight: bold;
      }

      input {
        text-align: center;

        &.error {
          border-color: #ea314a;
        }
      }
    }
  }

  .note {
    font-size: 13px;
    line-height: 21px;
    color: #7b7e91;
    margin-bottom: 15px;
  }

  input {
    &.error {
      border-color: #ea314a;
    }
  }
`;
