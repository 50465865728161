import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import ContentLoader from 'react-content-loader';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// import { ReactComponent as SearchIcon } from 'assets/icons/search_icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/program_filter.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/program_arrow_right.svg';
import { ReactComponent as ArrowDownFillIcon } from 'assets/icons/program_arrow_down_fill.svg';
import { ReactComponent as DefaultCover } from 'assets/icons/program_workout_plan_default.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/program_download_icon.svg';
import { ReactComponent as EmptyUI } from 'assets/icons/program_empty.svg';

import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import * as S from '../style';
import { useMyContext } from '../context/ProgramTemplatesContext';
import { Checkbox } from 'shared/FormControl';

import {
  DEFAULT_FILTERS_POPUP,
  FILTER_CATEGORIES,
  SORT_OPTIONS,
  // SORT_SWITCH_OPTIONS,
  // TIME_SWITCH_OPTIONS,
  shortNumberToFloor,
  EXPERIENCE_OPTIONS,
  DURATION_OPTIONS,
  LOADING_DATA,
  convertToTitleCase,
  FILTER_TYPE_KEYS,
} from '../constants';
import { CDN_URL } from 'constants/commonData';

import { isEqual } from 'lodash';
import { NewSearchInput } from 'shared/SearchInput';

const FullscreenModalContent = () => {
  const {
    toggleFullscreenModal,
    toggleFilterModal,
    toggleDetailModal,
    showSortPopup,
    setShowSortPopup,
    showExperiencePopup,
    setShowExperiencePopup,
    showDurationPopup,
    setShowDurationPopup,
    getProgramTemplate,
    templateProgram,
    loading: isLoading = false,
    cloudfrontList,
    setDetailModalItem,
    changeQueryParams,
    queryTemplates,
    currentFilters = DEFAULT_FILTERS_POPUP,
  } = useMyContext();

  const { list, total } = templateProgram;
  const {
    sort = 1,
    sorter = 'title',
    number_of_weeks = [],
    levels = '',
    resetFilter,
    modalities = 'all',
  } = queryTemplates;
  const horizontalScrollRef = useRef();
  const searchBoxRef = useRef();
  const [hiddenPaddle, setHiddenPaddle] = useState({ left: true, right: true });
  const [isSearching, setSearching] = useState(false);
  // const [selectedSwitchSort, setSelectedSwitchSort] = useState(SORT_SWITCH_OPTIONS[0]);
  // const [selectedSwitchTime, setSelectedSwitchTime] = useState(TIME_SWITCH_OPTIONS[0]);
  const [showStickHeader, setShowStickHeader] = useState(false);
  const isEmptyTemplate = useMemo(() => isEmpty(list), [list]);
  const [filters, setFilters] = useState(DEFAULT_FILTERS_POPUP);

  useEffect(() => {
    getProgramTemplate && getProgramTemplate({ sort });
  }, []);

  const counterFilters = useMemo(() => {
    if (typeof currentFilters !== 'object') return 0;
    let counter = 0;
    for (const [key, value] of Object.entries(currentFilters)) {
      switch (key) {
        case FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK:
        case FILTER_TYPE_KEYS.MODALITIES:
        case FILTER_TYPE_KEYS.PROGRAM_LENGTH:
          if (Array.isArray(value) && value.length > 0 && value !== null && value[0] !== 'all') counter += value.length;
          break;
        case FILTER_TYPE_KEYS.LEVELS:
          if ((typeof value === 'string' && value !== '') || (Array.isArray(value.levels) && value.levels.length > 0)) {
            counter += 1;
          }
          break;
        default:
          break;
      }
    }
    return counter;
  }, [currentFilters]);

  useEffect(() => {
    if (!isEqual(currentFilters, filters)) {
      setFilters(currentFilters);
    }
  }, [currentFilters]);

  const handleSelectCategory = option => () => {
    const selectedCategory = option.key;
    changeQueryParams({ modalities: [selectedCategory] });
  };

  const handleOpenSortPopup = () => {
    setShowSortPopup(true);
  };

  const handleCloseSortPopup = () => {
    setShowSortPopup(false);
  };

  const handleOpenExperiencePopup = () => {
    setShowExperiencePopup(true);
    setShowDurationPopup(false);
  };

  const handleCloseExperiencePopup = () => {
    setShowExperiencePopup(false);
  };

  const handleOpenDurationPopup = () => {
    setShowDurationPopup(true);
  };

  const handleCloseDurationPopup = () => {
    setShowDurationPopup(false);
  };

  const handleScrollToSideMargin = ({ left = false }) => () => {
    const target = horizontalScrollRef.current;
    if (target) {
      target.scrollTo({
        left: left ? 0 : target.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  // const handleSelectedSwitchSort = key => () => {
  //   setSelectedSwitchSort(key);
  // };

  // const handleSelectedSwitchTime = key => () => {
  //   setSelectedSwitchTime(key);
  // };

  const handleSelectSort = it => () => {
    const sortValue = it.key === 'title' ? 1 : -1;
    changeQueryParams({ sort: sortValue, sorter: it.key });
    handleCloseSortPopup();
  };

  const handleSelectExperience = it => () => {
    changeQueryParams({ sort, levels: it.key });
    handleCloseExperiencePopup();
  };

  const handleSelectCheckbox = key => {
    const result = number_of_weeks.includes(key)
      ? number_of_weeks.filter(itemKey => itemKey !== key)
      : [...number_of_weeks, key];
    changeQueryParams({ number_of_weeks: result });
    return result;
  };

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const target = (entries[0] || {}).target;
      target && onHorizontalChange(target);
    }),
  );

  const resizedContainerRef = useCallback(container => {
    if (container !== null) {
      resizeObserver.current.observe(container);
      horizontalScrollRef.current = container;
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
      horizontalScrollRef.current = null;
    }
  }, []);

  const onHorizontalScroll = event => {
    event.persist();
    const target = horizontalScrollRef.current;
    target && onHorizontalChange(target);
    event.stopPropagation();
  };

  const onHorizontalChange = target => {
    const { scrollLeft, scrollWidth, offsetWidth } = target;
    const invisibleSize = scrollWidth - offsetWidth;
    const paddleMargin = 34;
    const endOffset = invisibleSize - paddleMargin;

    if (scrollWidth === offsetWidth || scrollWidth < offsetWidth + paddleMargin) {
      setHiddenPaddle({ left: true, right: true });
    } else {
      setHiddenPaddle({
        left: scrollLeft <= paddleMargin,
        right: scrollLeft >= endOffset,
      });
    }
  };

  const onVerticalScroll = event => {
    event.persist();
    const { scrollTop } = event.target;
    if (scrollTop > 250 && !showStickHeader) {
      setShowStickHeader(true);
    } else if ((showStickHeader && scrollTop === 0) || (showStickHeader && scrollTop <= 180)) {
      setShowStickHeader(false);
    }
  };

  const handleOpenDetailTemplate = item => () => {
    toggleDetailModal(item);
    setDetailModalItem(item);
  };

  const handleCloseFullscreen = () => {
    handleCloseExperiencePopup();
    handleCloseDurationPopup();
    handleCloseSortPopup();
    toggleFullscreenModal();
  };

  const renderCategoryItem = it => {
    const { key, icon, label } = it;
    let selected = false;

    if (key === 'all' && modalities.length <= 0) {
      selected = true;
    } else if (key === 'all' && modalities.length > 1) {
      selected = false;
    } else {
      selected = modalities.includes(key);
    }

    return (
      <button key={key} className={classNames('category-item', { selected })} onClick={handleSelectCategory(it)}>
        <div className="icon">{icon}</div>
        <div className="label">{label}</div>
      </button>
    );
  };

  // const renderSortSwitchItem = option => {
  //   const { key, label } = option;
  //   const selected = selectedSwitchSort.key === key;

  //   return (
  //     <button
  //       key={key}
  //       className={classNames('switch-item label', { selected })}
  //       onClick={handleSelectedSwitchSort(option)}
  //     >
  //       {label}
  //     </button>
  //   );
  // };

  // const renderTimeSwitchItem = option => {
  //   const { key, label } = option;
  //   const selected = selectedSwitchTime.key === key;

  //   return (
  //     <button
  //       key={key}
  //       className={classNames('switch-item label', { selected })}
  //       onClick={handleSelectedSwitchTime(option)}
  //     >
  //       {label}
  //     </button>
  //   );
  // };

  const renderSortOption = option => {
    const { key, label } = option;
    const selected = sorter === key;

    return (
      <button key={key} className={classNames('sort-option', { selected })} onClick={handleSelectSort(option)}>
        {label}
      </button>
    );
  };

  const renderSortLabel = option => {
    const { key, label } = option;
    const isSelected = sorter === key;

    return isSelected && <span key={key}>{label}</span>;
  };

  const renderExperienceOption = option => {
    const { key, label } = option;
    const isSelected = levels.includes(key);

    return (
      <button key={key} className={classNames('sort-option', { isSelected })} onClick={handleSelectExperience(option)}>
        {label}
      </button>
    );
  };

  const renderDurationOption = option => {
    const { key, label } = option;
    const isSelected = number_of_weeks.includes(key);

    const handleClickCheckbox = event => {
      event.stopPropagation();
      handleSelectCheckbox(key);
    };

    return (
      <button key={key} className="sort-option" onClick={handleClickCheckbox}>
        {label}
        <Checkbox
          key={key}
          className="duration-checkbox"
          checked={isSelected}
          onChange={handleClickCheckbox}
          size={16}
        />
      </button>
    );
  };

  const getCloudUrl = url => {
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);
  };

  const renderTemplateItem = item => {
    const {
      _id,
      total_used = 0,
      title = '',
      description = '',
      background_thumbnail = '',
      modality = '',
      number_of_weeks = 0,
      level = '',
    } = item;
    const total = shortNumberToFloor(total_used);
    const convertedThumbnail = getCloudUrl(background_thumbnail || '');

    const renderModality =
      modality === 'strength_hypertrophy'
        ? 'strength / hypertrophy'
        : modality === 'hiit'
        ? 'HIIT'
        : convertToTitleCase(modality);

    const renderLevels = () => {
      if (level === 'all_level') return 'All Levels';
      return level;
    };

    const renderTagItem = () => (
      <div className="tags">
        <div className="template-tag-item">{renderModality}</div>
        <div className="template-tag-item">{renderLevels()}</div>
        <div className="template-tag-item">
          {number_of_weeks} {pluralize('Week', number_of_weeks)}
        </div>
      </div>
    );

    return (
      <div className="template-item" key={`template-item-${_id}`} onClick={handleOpenDetailTemplate(item)}>
        <div className="thumbnail-header">
          <div className="thumbnail">
            {convertedThumbnail ? <img src={convertedThumbnail} alt="" /> : <DefaultCover />}
          </div>
          {renderTagItem()}
        </div>
        <div className="template-content">
          <div className="title-wrapper">
            <div className="title">{title}</div>
            <div className="total-download">
              <DownloadIcon />
              <span>{total}</span>
            </div>
          </div>
          <div className="description">{description}</div>
        </div>
      </div>
    );
  };

  const renderLoaderItem = (item, idx) => {
    const { speed = 0, bgColor = '#EEE', bgOpacity = 0.2 } = item;

    return (
      <S.LoadingItem key={`loader-item${idx}`}>
        <ContentLoader
          viewBox="0 0 316 294"
          width={316}
          height={294}
          speed={speed}
          backgroundColor={bgColor}
          backgroundOpacity={bgOpacity}
        >
          <rect x="0" y="0" rx="8" ry="8" width="316" height="200" />
          <rect x="0" y="212" rx="8" ry="8" width="168" height="24" />
          <rect x="0" y="240" rx="8" ry="8" width="316" height="42" />
        </ContentLoader>
      </S.LoadingItem>
    );
  };

  const renderEmptyTemplate = () => (
    <div className="empty-program-wrapper">
      <div className="empty-icon-wrapper">
        <EmptyUI />
        No results found.
      </div>
    </div>
  );

  const renderLoadingTemplate = () => (
    <div className="loading-wrapper">{Array(20).fill(LOADING_DATA).map(renderLoaderItem)}</div>
  );

  const getLabelForKey = key => {
    const durationOption = DURATION_OPTIONS.find(option => option.key === key);
    return durationOption && durationOption.label;
  };

  const renderSelectedDurations = () => {
    const selectedLabels = number_of_weeks.map(getLabelForKey);

    return <>{selectedLabels.length > 0 ? selectedLabels.join(', ') : 'Select a duration'}</>;
  };

  const renderSelectedExperiences = () => {
    if (levels === 'all_level') return 'All Levels';
    return <span>{levels.length > 0 ? levels : 'Select a level'}</span>;
  };

  const handleClearDurationFilter = () => {
    changeQueryParams({ number_of_weeks: [] });
  };

  const handleClearExperienceFilter = () => {
    changeQueryParams({ levels: '' });
  };

  const handleClearSearch = () => {
    setSearching(false);
    changeQueryParams({ sort, page: 1, text_search: '' });
  };

  const handleSearch = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    setSearching(!!trimmed);
    changeQueryParams({ sort, page: 1, text_search: trimmed });
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 });
    }
  };

  const searchDebounce = debounce(handleSearch, 300);
  const checkOpenedFilter = showDurationPopup || showExperiencePopup || showSortPopup;

  return (
    <S.FullscreenModalContent openedFilter={checkOpenedFilter} className="fullscreen" onScroll={onVerticalScroll}>
      <img
        src={`${CDN_URL}/images/program_fullscreen_header_bg.png`}
        alt=""
        className={classNames('background-header', { sticky: showStickHeader })}
      />
      <button
        className={classNames('close-icon-button', {
          sticky: showStickHeader,
        })}
        onClick={handleCloseFullscreen}
      >
        <CloseIcon />
      </button>
      <div className="header-title-wrapper">
        <div className="title">Everfit Program Templates</div>
        <div className="description">Ready-to-use program templates for your convenience</div>
      </div>
      <div
        className={classNames('filter-search-wrapper', {
          default: !showStickHeader,
          sticky: showStickHeader,
        })}
      >
        <S.ExperienceDurationWrapper className="experience-duration-wrapper">
          <S.MainFilterPopup
            trigger={
              <button
                className={classNames('filter-experience', {
                  show: showExperiencePopup,
                })}
              >
                <span>
                  <div className="label">Experience</div>
                  <div className="value">{renderSelectedExperiences()}</div>
                </span>
              </button>
            }
            basic={true}
            flowing={true}
            hoverable={true}
            on="click"
            position="bottom left"
            open={showExperiencePopup}
            onOpen={handleOpenExperiencePopup}
          >
            <RootCloseWrapper event="click" onRootClose={handleCloseExperiencePopup} disabled={!showExperiencePopup}>
              <>
                <div className="sort-popup-title">Experience Level</div>
                <div className="sort-popup">{EXPERIENCE_OPTIONS.map(renderExperienceOption)}</div>
                <div className="clear-filter" onClick={handleClearExperienceFilter}>
                  Clear
                </div>
              </>
            </RootCloseWrapper>
          </S.MainFilterPopup>
          <S.MainFilterPopup
            trigger={
              <button
                className={classNames('filter-duration', {
                  show: showDurationPopup && !showExperiencePopup,
                })}
              >
                <span>
                  <div className="label">Duration</div>
                  <div className="value">{renderSelectedDurations()}</div>
                </span>
              </button>
            }
            basic={true}
            flowing={true}
            on="click"
            position="bottom right"
            open={showDurationPopup && !showExperiencePopup}
            onOpen={handleOpenDurationPopup}
            onClose={handleCloseDurationPopup}
          >
            <>
              <div className="sort-popup-title">Program Duration</div>
              <div className="sort-popup">{DURATION_OPTIONS.map(renderDurationOption)}</div>
              <div className="clear-filter" onClick={handleClearDurationFilter}>
                Clear
              </div>
            </>
          </S.MainFilterPopup>
        </S.ExperienceDurationWrapper>
        <div className="filter-wrapper">
          <button className="filter-button" data-tip data-for="template-filter-button" onClick={toggleFilterModal}>
            <FilterIcon className="icon" />
          </button>
          {counterFilters > 0 && (
            <S.CounterFilters>
              <span>{counterFilters}</span>
            </S.CounterFilters>
          )}
          {!showStickHeader && (
            <ReactTooltip
              className="app-tooltip filter-button-template"
              id={`template-filter-button`}
              effect="solid"
              place="top"
            >
              <span>More Filters</span>
            </ReactTooltip>
          )}
        </div>
        <div className="search-wrapper">
          <NewSearchInput
            onChange={searchDebounce}
            onClearSearch={handleClearSearch}
            placeholder="Search by keywords or name..."
            onKeyPress={onKeyPress}
          />
          {/* <button className="search-icon">
            <SearchIcon />
          </button> */}
        </div>
      </div>
      <div className="overlay-wrapper">
        {process.env.REACT_APP_ENABLE_PROGRAM_TEMPLATES_CATEGORY && (
          <div className={classNames('category-filter-wrapper', { sticky: showStickHeader })}>
            <div className="category-wrapper">
              <div
                className={classNames('arrow-button-left', {
                  hidden: hiddenPaddle.left,
                })}
              >
                <button onClick={handleScrollToSideMargin({ left: true })}>
                  <ArrowRightIcon />
                </button>
              </div>
              <div
                className={classNames('arrow-button-right', {
                  hidden: hiddenPaddle.right,
                })}
              >
                <button onClick={handleScrollToSideMargin({ left: false })}>
                  <ArrowRightIcon />
                </button>
              </div>
              <div className="category-scrollable" ref={resizedContainerRef} onScroll={onHorizontalScroll}>
                {FILTER_CATEGORIES.map(renderCategoryItem)}
              </div>
            </div>
          </div>
        )}

        <div className="content-scrollable">
          <div
            className={classNames('recommended-most-popular-time-sort-wrapper', {
              enabled: process.env.REACT_APP_ENABLE_PROGRAM_TEMPLATES_CATEGORY,
            })}
          >
            <div className="recommended-most-popular-wrapper">
              {isLoading ? null : (
                <>
                  {isSearching || counterFilters > 0 || resetFilter
                    ? `${pluralize('Result', total)} (${total})`
                    : `${pluralize('All Program', total)} (${total})`}
                </>
              )}
            </div>

            <div className="time-sort-wrapper">
              {/* {selectedSwitchSort.key === 'most_popular' && (
              <div className="time-wrapper">{TIME_SWITCH_OPTIONS.map(renderTimeSwitchItem)}</div>
            )} */}

              <div className="sort-wrapper">
                <S.SortPopup
                  trigger={
                    <button
                      className={classNames('sort-trigger', {
                        show: showSortPopup,
                      })}
                    >
                      <span>
                        <strong>Sort:</strong>&nbsp;{SORT_OPTIONS.map(renderSortLabel)}
                      </span>
                      <ArrowDownFillIcon className="icon" />
                    </button>
                  }
                  basic={true}
                  flowing={true}
                  hoverable={true}
                  on="click"
                  position="bottom right"
                  open={showSortPopup}
                  onOpen={handleOpenSortPopup}
                >
                  <RootCloseWrapper event="click" onRootClose={handleCloseSortPopup} disabled={!showSortPopup}>
                    <div className="sort-popup">{SORT_OPTIONS.map(renderSortOption)}</div>
                  </RootCloseWrapper>
                </S.SortPopup>
              </div>
            </div>
          </div>
          <div className="templates-scrollable">{!isEmptyTemplate && !isLoading && list.map(renderTemplateItem)}</div>
          {isEmptyTemplate && !isLoading && renderEmptyTemplate()}
          {isLoading && renderLoadingTemplate()}
          <div className="footer">
            <img src={`${CDN_URL}/images/footer_logo_everfit.svg`} alt="" className="logo" />
          </div>
        </div>
        <S.BgOverlay show={showExperiencePopup || showDurationPopup} sticky={showStickHeader} />
      </div>
    </S.FullscreenModalContent>
  );
};

export default FullscreenModalContent;
