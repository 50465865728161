import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CurrentPlan from './component';
import { toggleModal } from 'actions/modal';
import { togglePaymentInformation } from 'redux/billing/actions';
import get from 'lodash/get';

const mapState = state => {
  const {
    rootReducer,
    rootReducer: {
      billing: {
        pricingPlan,
        upcommingInvoice,
        pricingPlan: { billingCycle },
        scheduledSubscription,
        invoiceOverDue,
        clientSecret,
      },
    },
  } = state;

  const addOnsPricing = rootReducer.pricing.get('addOnsPricing').toJS() || [];
  const zapierPricing = addOnsPricing.find(item => item.code === 'zapier');
  const zapierBasePricings = get(zapierPricing, 'basePricings', []);

  return {
    pricingPlan,
    upcommingInvoice,
    billingCycle,
    scheduledSubscription,
    invoiceOverDue,
    clientSecret,
    addOnsPricing: rootReducer.pricing.get('addOnsPricing'),
    paymentOverdue: rootReducer.pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    zapierOverdue: rootReducer.pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    addOnsOverdue: rootReducer.pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierBasePricings,
    permission: rootReducer.permission,
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  togglePaymentInformation: bindActionCreators(togglePaymentInformation, dispatch),
});

export default connect(mapState, mapDispatch)(CurrentPlan);
