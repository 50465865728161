import React from 'react';
import { components } from 'react-select';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down.svg';

const DefaultDropdownIndicator = args => (
  <components.DropdownIndicator {...args}>
    <ArrowDownIcon
      style={{
        color: '#000000',
        width: 14,
        height: 14,
        marginRight: 7,
      }}
    />
  </components.DropdownIndicator>
);

export default DefaultDropdownIndicator;
