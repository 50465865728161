import { Button } from 'shared/FormControl';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 31px 32px 48px;
  text-align: center;

  .hand {
    font-weight: normal;
    font-size: 72px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 24px;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #000000;
    margin: 0 0 10px;
  }

  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 21.79px;
    text-align: center;
    color: #000000;
    margin: 0 auto 24px;
    max-width: 416px;
  }

  .links {
    a {
      display: inline-block;
      margin: 0 5px 6px;
    }
  }

  .create-account-container {
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid #0000001a;
    margin-top: 45px;

    .create-account__label {
      position: absolute;
      width: 219px;
      height: 20px;
      left: 100px;
      top: -14px;
      background-color: #fff;
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      color: #101010;
    }

    .create-account__button {
      margin-top: 33px;
    }
  }
`;

export const SignupButton = styled(Button)`
  padding: 18px 32px;
  border-radius: 4px;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;

  span {
    margin-right: 9px;
  }
`;
