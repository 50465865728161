import _ from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  checkList: [],
  hideFeature: false,
  maximize: false,
  running: null,
  showTooltip: false,
  showOnboardingGuide: false,
  workoutBuilderGuideSteps: {
    create_exercise_section: true,
    custom_options: true,
    create_superset: true,
    loaded: false,
  }
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.MAXIMIZE_ONBOARDING_CHECK_LIST:
      return Object.assign({}, state, { maximize: true });

    case Types.MINIMIZE_ONBOARDING_CHECK_LIST:
      return Object.assign({}, state, { maximize: false });

    case Types.HIDE_ONBOARDING_FEATURE:
      return Object.assign({}, state, { hideFeature: true });

    case Types.SUCCESS_GET_ONBOARDING_CHECKLIST:
      return Object.assign({}, state, { checkList: payload.data });

    case Types.HIDE_ONBOARDING_TOOLTIP:
      return Object.assign({}, state, { showTooltip: false });

    case Types.SHOW_ONBOARDING_TOOLTIP:
      return Object.assign({}, state, { showTooltip: true });

    case Types.RUNNING_ONBOARDING_STEP:
      return Object.assign({}, state, { running: payload.data, showTooltip: !!payload.data });

    case Types.SHOW_ONBOARDING_USER_GUIDE:
      return Object.assign({}, state, { showOnboardingGuide: true });

    case Types.HIDE_ONBOARDING_USER_GUIDE:
      return Object.assign({}, state, { showOnboardingGuide: false });

    case Types.SUCCESS_GET_ONBOARDING_WORKOUT_BUILDER_GUIDE:
      return Object.assign({}, state, { workoutBuilderGuideSteps: { ...payload.data, loaded: true } });

    case Types.MARK_WORKOUT_BUILDER_GUIDE: {

      return Object.assign({}, state, {
        workoutBuilderGuideSteps: {
          ...state.workoutBuilderGuideSteps,
          [payload.data.type]: true
        }
      });
    }

    default:
      return state;
  }
};
