import styled from 'styled-components';

export default styled.div`
  input {
    background: #ffffff;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    text-align: center;
    width: 67px;
    padding: 3px;
    font-size: inherit;
    line-height: inherit;

    &.error {
      border: 1px solid #ff9791 !important;
    }
  }
`;
