import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const SearchStyles = styled.div``;

export const FilterStyles = styled.div`
  padding: 5px;
  background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #a6acc6;
  cursor: pointer;
  width: 28px;
  height: 28px;

  :hover {
    background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon.svg);
    background-color: #eaebff;
    border-radius: 3px;
  }
`;

export const ThumbnailStyle = styled.div`
  width: 100%;
  height: 97px;
  overflow: hidden;
  background-blend-mode: multiply;
  background-color: #eaebef;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  /* TODO: Revert error drap & drog */
  /* .image-with-fallback {
    img {
      pointer-events: none;
    }
  } */

  ${props =>
    !props.thumbnail &&
    css`
      background-size: auto;
      background: url(${CDN_URL}/images/exercise_grey.svg) no-repeat;
      background-position: center;
      background-color: #eaebef;
    `}

  ${props =>
    props.isVideo &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: 4px;
        bottom: 4px;
        width: 15px;
        height: 15px;
        background: url(${CDN_URL}/images/video_play.svg) no-repeat left bottom;
        background-size: contain;
        z-index: 1;
      }
    `}
`;

export const SearchContainer = styled.div`
  display: flex;
  background: #f6f7f9;
  border-radius: 5px;
  margin: 0 15px 20px;
  height: 40px;

  .search-input {
    flex: 1;

    input {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 43px;
      background-color: #f6f7f9;
      border: none;
      font-weight: 400;
      font-family: 'Open Sans';
      line-height: 16px;
    }
    input::placeholder {
      font-size: 13px;
    }
  }

  .exercise-filter {
    margin-right: 0;
    margin: auto;
    margin-right: 7px;

    &.open {
      .workout-builder__filter-icon {
        background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon_hover.svg);
        background-color: #eaebff;
        border-radius: 3px;
      }
    }
  }
`;

export const LoadingItemStyles = styled.div`
  border: 1px solid #dadfea;
  border-radius: 2px;
  margin: 0 7.5px 15px;
`;

export default styled.div`
  padding: 30px 15px 0px;
  height: 100%;
  box-shadow: -9px 3px 12px -8px rgba(30, 46, 103, 0.1) inset;

  .left-pannel__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow-x: visible;

    .listItem__wrapper {
      flex: 1 1;
      overflow: auto;
      padding: 0 0 0 15px;
      position: relative;

      .listItem__container {
        width: 320px;
      }

      ::-webkit-scrollbar {
        width: 5px !important;
      }
    }

    .left-pannel__tabs {
      z-index: 10;
      background-color: #fff;
      margin-bottom: 20px;
      padding: 0 15px;
    }
  }

  .search-input input {
    background-image: url(${CDN_URL}/images/search_grey_purple.svg);
  }

  .workout-left-panel-header-actions {
    display: flex;
    margin-bottom: 15px;
    background: #f6f7f9;
    border-radius: 5px;

    .search-input {
      flex: 1;

      input {
        background-color: #f6f7f9;
        border: none;
      }
    }

    .exercise-filter {
      margin-right: 7px;
    }
  }

  .workout-left-panel-content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      font-weight: 600;
      font-size: 11px;
      line-height: 120%;
      text-transform: uppercase;
      color: #202353;
    }

    .icons {
      display: flex;

      svg {
        margin-left: 10px;
        cursor: pointer;
        fill: #a6acc6;

        &.selected {
          * {
            fill: #5158cf;
          }
        }
      }
    }
  }
  .workout-left-panel-content-listing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    &.sectionList {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }

    .exercise-item {
      width: 152px;
      height: 149px;
      display: flex;
      flex-direction: column;
      border: 1px solid #ebedf2;
      border-radius: 5px;
      overflow: hidden;
      cursor: grab;

      :hover {
        border: 1px solid #5158cf;
      }
    }

    .section-item {
      width: 100%;
      padding: 15px;
      margin: 0 7.5px 15px;
      background: #f7f8fc;
      border: 1px solid #e1e5ec;
      box-sizing: border-box;
      border-radius: 8px;

      .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #202353;
        margin-bottom: 5px;
      }

      .sub-title {
        display: flex;

        .type {
          padding: 2px 10px;
          background: #deebff;
          border-radius: 2px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #5088e2;
          text-transform: capitalize;
        }
      }
    }

    .no-result-section {
      position: absolute;
      top: 30px;
      bottom: 100px;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        text-align: center;
        color: #a6a7ba;
        margin-bottom: 15px;
        font-weight: normal;
        font-size: 13px;
        line-height: 130%;
        color: #a6a7ba;
      }
    }

    &.list-view {
      grid-template-columns: 1fr;
      grid-gap: 12px;

      .exercise-item {
        width: 100%;
        height: 66px;
        flex-direction: row;
        padding: 5px;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;

        .exerciseThumbnail {
          height: 100%;
          width: 90px;
          border-radius: 5px;
          flex: 0 0 90px;
          margin-right: 15px;
        }

        .drag-icon {
          flex: 0 0 9px;
          margin-left: 10px;
          align-self: center;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }

    .bottomSentinal {
      height: 5px;
    }
  }

  .onboarding-tooltip {
    position: absolute;
    right: 43px;
    top: 218px;
    z-index: 100000;
  }
`;
