import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Mixpanel } from 'utils/mixplanel';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { Modal } from 'semantic-ui-react';
import { Button, FormGroup } from 'shared/FormControl';
import { TEAM_SHARE_PRIVATE, SHARE_OPTIONS, CDN_URL } from 'constants/commonData';
import ShareOption from 'shared/ShareOption';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';

export default ({ title, share, onSave, trigger, header, controlled, ...props }) => {
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({
    title: title || '',
    share: SHARE_OPTIONS.find(o => o.value === share || TEAM_SHARE_PRIVATE) || SHARE_OPTIONS[0],
  });

  useEffect(() => {
    if (!open) {
      setState({
        title: title || '',
        share: SHARE_OPTIONS.find(o => o.value === share || TEAM_SHARE_PRIVATE) || SHARE_OPTIONS[0],
      });
      setIsSubmitting(false);
      setShowError(false);
    }
  }, [open]);

  const onUpdateTitle = event => {
    const { value } = event.target;
    setState(prev => ({ ...prev, title: value }));

    if (showError) {
      setShowError(false);
    }
  };

  const onUpdateSharing = data => {
    setState(prev => ({ ...prev, share: data }));
  };

  const onOpen = () => {
    !controlled && setOpen(true);
  };

  const onClose = () => {
    controlled ? props.onClose() : setOpen(false);
  };

  const onSubmit = event => {
    event.preventDefault();

    Mixpanel.track('autoflow_interval_training_calendar_screen_save_as_program');
    if (isSubmitting) {
      return false;
    }

    if (!state.title.trim()) {
      setShowError(true);
      setState(prev => ({ ...prev, title: state.title.trim() }));
      return toast.error('Please enter program name');
    }

    setIsSubmitting(true);

    if (typeof onSave === 'function') {
      try {
        onSave({ title: state.title, share: state.share.value })
          .then(() => {
            onClose();
            toast(<div>Program is saving to your library, It will be ready in a few minutes.</div>);
          })
          .catch(() => {
            setIsSubmitting(false);
          });
      } catch (err) {
        console.error('onSave', err);

        onClose();
      }
    }
  };

  return (
    <ModalWrapper
      open={controlled ? true : open}
      closeOnDimmerClick={false}
      className="modal--save-as-Program"
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      onOpen={onOpen}
      onClose={onClose}
      trigger={
        controlled
          ? null
          : trigger || (
              <ToggleButton className="button--modalToggleButton">
                <SaveIcon />
                <span>Save as Program</span>
              </ToggleButton>
            )
      }
    >
      <Modal.Content>
        <form onSubmit={onSubmit}>
          <ModalLayout.Wrapper>
            <ModalLayout.Header>{header || 'Save as Program to your Library'}</ModalLayout.Header>
            <ModalLayout.Content>
              <FormGroup className={showError && !state.title.trim() ? 'error' : ''}>
                <label>Program Name</label>
                <input type="text" value={state.title} onChange={onUpdateTitle} placeholder="Your program name" />
              </FormGroup>
              <FormGroup>
                <label>Shared with Workspace?</label>
                <ShareOption value={state.share} onChange={onUpdateSharing} fixedPosition />
              </FormGroup>
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button onClick={onClose} type="button">
                Cancel
              </Button>
              <Button type="submit" purple disabled={isSubmitting} disabled={!state.title.trim()}>
                Save
              </Button>
            </ModalLayout.Actions>
          </ModalLayout.Wrapper>
        </form>
      </Modal.Content>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 500px;

    > .content {
      padding: 0;
      border-radius: 8px !important;
    }
  }

  ${FormGroup} {
    :last-child {
      margin-bottom: 0;
    }
  }

  button[type='submit'] {
    width: 120px;
  }
`;

const ToggleButton = styled(Button)`
  padding: 5px 29px;

  :hover {
    background-color: #f5f7f9;
  }
`;
