import S3Images from 'shared/S3Images';
import styled, { css } from 'styled-components';

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

const textBase = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const MainPanelHeaderWrapper = styled.div`
  width: 100%;
  padding: 20px 30px 0;
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const HeaderInput = styled.input`
  outline: unset;
  padding: 0 10px;
  line-height: 40px !important;
  ${textBase}
  font-size: 21px;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  min-width: 265px;
  &:focus,
  &:hover {
    border: 1px solid #5158cf;
    background: #fff;
  }
`;

export const HeaderButton = styled.div`
  height: 42px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0 10px;
  min-width: 265px;
  width: fit-content;
  .truncate {
    ${textBase}
    font-size: 21px;
    font-weight: 600;
    line-height: 40px;
    ${limitText}
  }
  &:hover {
    border-color: #5158cf;
  }
`;

export const TotalNutrition = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalNutritionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${textBase}
  font-size: 12px;
  font-weight: 600;
  padding: 10px 7px;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #a3a3b5;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

export const NutritionLabelItem = styled.div`
  margin-right: 5px;
`;

export const NutritionValueItem = styled.div`
  margin-right: 3px;
  ${props =>
    props.isEmpty &&
    css`
      color: #a3a3b5;
    `}
`;

export const MainPanelContentWrapper = styled.div`
  height: 100%;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  padding: 20px 30px 20px 40px;
  margin-right: 6px;
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4 !important;
  }
`;

export const ContentEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 200px 0;
`;

export const ContentEmptyTitle = styled.div`
  ${textBase}
  color: #7b7e91;
  font-size: 15px;
  font-weight: 400;
`;

export const MainPanelItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  padding: 10px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  ${({ isDisable }) =>
    isDisable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  .close-icon {
    position: absolute;
    display: none;
    height: 18px;
    width: 18px;
    right: -7px;
    top: -8px;
    cursor: pointer;
  }
  &:hover {
    border: 1px solid #5158cf;
    .close-icon {
      display: block;
    }
    .evf-dropdown.custom-dropdown-actions {
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    ${props =>
      props.isFromClient &&
      css`
        flex-direction: column;
        ${ItemImage} {
          &.image-with-fallback {
            width: 100%;
          }
        }
      `}
  }

  ${({ sideBarVisible, isFromClient }) =>
    !isFromClient && !sideBarVisible
      ? css`
          ${ItemImage} {
            &.image-with-fallback {
              width: 150px;
              height: 100px;
            }
          }
        `
      : !isFromClient &&
        css`
          ${ItemImage} {
            &.image-with-fallback {
              @media screen and (max-width: 1749px) {
                width: 120px;
                height: 80px;
              }
              @media screen and (min-width: 1750px) {
                width: 150px;
                height: 100px;
              }
            }
          }
        `}
  .evf-dropdown.custom-dropdown-actions {
    display: none;
    position: absolute;
    top: 12px;
    right: 9px;
    .evf-dropdown__trigger-container {
      .dropdown-trigger {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        .dot {
          width: 4px;
          height: 4px;
          background-color: #7b7e91;
          &::before {
            width: 4px;
            height: 4px;
            left: -6px;
            background-color: #7b7e91;
          }
          &::after {
            left: 6px;
            width: 4px;
            height: 4px;
            background-color: #7b7e91;
          }
        }

        &:hover {
          background-color: #f0f1ff;
          .dot {
            background-color: #5158cf;
            &::before {
              background-color: #5158cf;
            }
            &::after {
              background-color: #5158cf;
            }
          }
        }
        .__react_component_tooltip {
          &::after {
            bottom: -9px;
            border-top-color: #333;
          }
          &.place-top {
            margin-top: -13px;
          }
          &.app-tooltip {
            ${textBase}
            border-radius: 5px;
            font-weight: 400;
            font-size: 12px;
            color: #fff;
            line-height: 18px;
            width: 107px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #333;
          }
        }
      }
    }
    .evf-dropdown__menu {
      width: 150px;
      .evf-dropdown__option {
        ${textBase}
        font-weight:600;
        color: #fff;
        line-height: 20px;
        font-size: 13px;
        height: 36px;
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
        }
      }
    }
  }
  .evf-dropdown.custom-dropdown-actions.open .evf-dropdown__trigger-container .dropdown-trigger {
    background-color: #f0f1ff;
    .dot {
      background-color: #5158cf;
      &::before {
        background-color: #5158cf;
      }
      &::after {
        background-color: #5158cf;
      }
    }
  }
`;

export const ItemImage = styled(S3Images)`
  user-select: none;
  &.image-with-fallback {
    flex-shrink: 0;
    width: 120px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 4px;
`;

export const ItemTitle = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  user-select: none;
  ${limitText}
  -webkit-line-clamp: 2;
  line-clamp: 2;
  padding-right: 50px;
`;

export const ListNutrition = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemNutrition = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${textBase}
  color: #7B7E91;
  font-size: 12px;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #7b7e91;
  }
  &:last-child {
    padding-right: unset;
    &::after {
      content: none;
    }
  }
  &:first-child {
    padding-left: unset;
  }
  ${({ hasMoreOption }) =>
    hasMoreOption &&
    css`
      padding-left: 8px;
      &::after {
        right: -3px;
      }
    `}
`;

export const Label = styled.div`
  margin-right: 2px;
`;

export const Value = styled.div`
  margin-right: 2px;
  ${({ hasMoreOption }) =>
    hasMoreOption &&
    css`
      margin-right: 3px;
    `}
`;

export const ItemDesc = styled.div`
  ${textBase}
  color: #7b7e91;
  font-size: 11px;
  font-weight: 400;
  ${limitText}
`;

export const ListDietary = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 7px;
  margin-top: 7px;
`;

export const DietarySelectItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 5px 7px 5px 5px;
  height: 26px;
  border-radius: 100px;
  background-color: #f8f8f8;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    ${textBase}
    font-size: 10px;
    font-weight: 400;
  }
`;

export const MainPanelSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);
  animation: custom-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes custom-pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  .skeleton-thumbnail {
    flex-shrink: 0;
    width: 150px;
    height: 100px;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  .skeleton-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .skeleton-name {
    width: 70%;
    height: 20px;
    background: #f8f8f8;
    border-radius: 5px;
  }
  .skeleton-macro-nutrient {
    width: 60%;
    height: 18px;
    background: #f8f8f8;
    border-radius: 5px;
  }
  .skeleton-description {
    width: 90%;
    height: 16px;
    background: #f8f8f8;
    border-radius: 5px;
  }
  .skeleton-dietary-list {
    display: flex;
    gap: 5px 7px;
  }
  .skeleton-dietary {
    width: 80px;
    height: 26px;
    background: #f8f8f8;
    border-radius: 100px;
  }
`;

export const LoggedMeal = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
  display: flex;
  .__react_component_tooltip.show {
    padding: 8px 16px;
    border-radius: 4px;
    width: 128px;

    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
  .__react_component_tooltip.type-dark.place-top:after {
    border-top-width: 8px;
    border-top-color: #2d2e2d;
  }
  .__react_component_tooltip.place-top {
    margin-top: -14px;
  }
  .__react_component_tooltip.place-top::after {
    margin-bottom: -1px !important;
  }
`;
