import React from 'react';
import classnames from 'classnames';
import { round, isNaN } from 'lodash';
// import components
import { FormGroup } from 'shared/FormControl';
import { GramFormStyles } from './styles';

const GramForm = ({ totalCalo, setTotalCalo, setNutri, nutri, errors, setErrors }) => {
  return (
    <GramFormStyles>
      <FormGroup>
        <label className="title">
          <span className="dot protein-dot"></span> Protein (g)
        </label>
        <input
          type="number"
          className={classnames({ error: errors.protein })}
          value={nutri.protein}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-|e/.test(keyValue)) event.preventDefault();
          }}
          onChange={e => {
            const number = e.target.value || 0;
            let { carbs = 0, fat = 0 } = nutri;
            if (isNaN(carbs)) carbs = 0;
            if (isNaN(fat)) fat = 0;
            setErrors({ ...errors, protein: false });
            setTotalCalo(round(4 * parseFloat(number) + 4 * carbs + 9 * fat, 2));
            setNutri({ ...nutri, protein: e.target.value ? parseFloat(number) : '' });
          }}
        />
      </FormGroup>
      <FormGroup>
        <label className="title">
          <span className="dot carbs-dot"></span> Carbs (G)
        </label>
        <input
          type="number"
          value={nutri.carbs}
          className={classnames({ error: errors.carbs })}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-|e/.test(keyValue)) event.preventDefault();
          }}
          onChange={e => {
            const number = e.target.value || 0;
            let { protein = 0, fat = 0 } = nutri;
            if (isNaN(protein)) protein = 0;
            if (isNaN(fat)) fat = 0;
            setErrors({ ...errors, carbs: false });
            setTotalCalo(round(4 * protein + 4 * parseFloat(number) + 9 * fat, 2));
            setNutri({ ...nutri, carbs: e.target.value ? parseFloat(number) : '' });
          }}
        />
      </FormGroup>
      <FormGroup>
        <label className="title">
          <span className="dot fat-dot"></span> Fat (G)
        </label>
        <input
          type="number"
          value={nutri.fat}
          className={classnames({ error: errors.fat })}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-/.test(keyValue)) event.preventDefault();
          }}
          onChange={e => {
            const number = e.target.value || 0;
            let { protein = 0, carbs = 0 } = nutri;
            if (isNaN(protein)) protein = 0;
            if (isNaN(carbs)) carbs = 0;
            setErrors({ ...errors, fat: false });
            setTotalCalo(round(4 * protein + 4 * carbs + 9 * parseFloat(number), 2));
            setNutri({ ...nutri, fat: e.target.value ? parseFloat(number) : '' });
          }}
        />
      </FormGroup>
      <div className="total-cal">
        <div className="title">Total Calories Goal</div>
        <div className="result">
          <span>{round(totalCalo, 2) || '-'}</span> Cal
        </div>
      </div>
    </GramFormStyles>
  );
};

export default GramForm;
