import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  Status,
  AutoflowNameContainer,
  Name,
  CountClients,
  CountItem,
  TypeColumnContent,
  TagType,
  WithSetting,
} from './style';
import { AUTOFLOW_STATUS, AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import { timeSince } from 'utils/commonFunction';
import { ReactComponent as SettingIcon } from 'assets/icons/setting.svg';

export default [
  {
    key: 'name',
    fieldData: 'name',
    label: 'Autoflow',
    sortKey: 'name',
    icon: `${CDN_URL}/images/autoflow_fill_grey.svg`,
    mutate: function (data, index) {
      const tooltipId = `autoflow-status-tooltip__${data._id}`;
      return (
        <AutoflowNameContainer>
          <Name>
            <Status
              className={data.status === AUTOFLOW_STATUS.ACTIVATED ? 'active' : 'inactive'}
              data-tip
              data-for={tooltipId}
            />
            <Link
              className="autoflow__name"
              to={`/home/autoflow/${data._id}/training/calendar`}
              data-intercom-target={`Autoflow list - Autoflow ${index}`}
            >
              {data.name}
            </Link>
            <ReactTooltip className="app-tooltip autoflow-list-tooltip" id={tooltipId} effect="solid" place="top">
              <span>{data.status === AUTOFLOW_STATUS.ACTIVATED ? 'Active' : 'Inactive'}</span>
            </ReactTooltip>
          </Name>
        </AutoflowNameContainer>
      );
    },
  },
  {
    key: 'type',
    fieldData: 'type',
    label: 'Type',
    mutate: function (data) {
      const { clients_count, type, isSettingTurnedOff, _id } = data;

      return (
        <TypeColumnContent>
          <TagType>By {type === AUTOFLOW_TYPES.INTERVAL ? 'Day sequence' : 'Exact date'}</TagType>
          {!isSettingTurnedOff && (
            <>
              <WithSetting data-tip="Autoflow with Settings" data-for={`tooltip--autoflow-with-setting-${_id}`}>
                <SettingIcon />
              </WithSetting>
              <ReactTooltip
                className="app-tooltip"
                id={`tooltip--autoflow-with-setting-${_id}`}
                effect="solid"
                place="top"
              />
            </>
          )}
          {!!clients_count && (
            <CountClients>
              {!!clients_count.active && (
                <CountItem className="active">
                  <div className="num">{clients_count.active}</div>
                  <div>Active</div>
                </CountItem>
              )}
              {!!clients_count.paused && (
                <CountItem className="paused">
                  <div className="num">{clients_count.paused}</div>
                  <div>Paused</div>
                </CountItem>
              )}
              {!!clients_count.waiting_to_start && (
                <CountItem className="waiting">
                  <div className="num">{clients_count.waiting_to_start}</div>
                  <div>Waiting</div>
                </CountItem>
              )}
            </CountClients>
          )}
        </TypeColumnContent>
      );
    },
  },
  {
    key: 'clients',
    fieldData: 'num_clients',
    label: 'Clients',
    mutate: function (data) {
      const clients_count = data.clients_count || {};
      return (
        <CountItem>
          <div className="num">{clients_count.total}</div>
          <div>Total</div>
        </CountItem>
      );
    },
  },
  {
    key: 'last_used',
    fieldData: 'last_used',
    label: 'Most Recent',
    sortKey: 'last_used',
    defaultSort: -1,
    mutate: function (data) {
      return data.last_used ? timeSince(new Date(data.last_used)) : '--';
    },
  },
  {
    key: 'author',
    fieldData: 'author',
    label: 'Owner',
    // sortKey: 'author_name',
    headerStyles: { textAlign: 'left' },
    bodyStyles: { textAlign: 'left' },
    mutate: function (data) {
      const { author } = data;

      if (!author) return '--';

      return (
        <div>
          {author.first_name} {author.last_name}
        </div>
      );
    },
  },
  {
    key: 'action',
    fieldData: 'action',
    label: 'Actions',
    hideLabel: true,
  },
];
