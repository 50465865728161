import styled from 'styled-components';

export const EmailWrapper = styled.div`
  display: flex;
`;

export const EmailGoogleIcon = styled.div`
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #d6dae4;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const WrapperNotification = styled.div`
  display: flex;
  column-gap: 6px;
  margin: 5px 0 10px;
`;

export const Notification = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
`;
