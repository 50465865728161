import React from 'react';
import _ from 'lodash';
import Avatar from 'react-avatar';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { convertS3UrlToCloudFrontUrl, getUserShortName, pluralize } from 'utils/commonFunction';
import { onDragLeftPanelStart } from 'redux/workout-builder/actions';

import { SectionItemWrapper } from './style';
import { SECTION_FORMAT_KEY } from 'constants/commonData';
import { handleGetExerciseType } from 'helpers/workout';

export function getFormatName(format) {
  switch (format) {
    case 'amrap':
      return 'AMRAP';
    default:
      return _.upperFirst(format);
  }
}

export function getFormatDesc(section) {
  switch (section.format) {
    case 'interval':
      const totalDuration = _.sumBy(section.exercises, exercise => {
        return _.sumBy(exercise.supersets, superset => {
          const eachEide = superset.each_side ? 2 : 1;
          return _.sumBy(superset.training_sets, set => {
            const duration = parseInt(_.get(set, 'duration.value', 0)) || 0;
            const rest = parseInt(_.get(set, 'rest.value', 0)) || 0;
            return (duration + rest) * eachEide;
          });
        });
      });
      const min = Math.floor(totalDuration / 60);
      const seconds = totalDuration % 60;

      if (min === 0) {
        return `${seconds ? `0${seconds}`.slice(-2) : ''} ${pluralize('second', seconds, '')}`;
      }

      return `${min}${seconds ? ':' + `0${seconds}`.slice(-2) : ''} ${pluralize('minute', min, '')}`;
    case 'amrap':
      return pluralize('minute', section.time / 60, true);
    case 'timed':
      return pluralize('round', section.round, true);
    case 'regular':
      return pluralize('exercise', section.exercises.length, true);
    default:
      return '';
  }
}

export function getExerciseText(section) {
  return _.reduce(
    section.exercises,
    (names, exercise) => {
      return names.concat(_.map(exercise.supersets, 'exercise_instance.title'));
    },
    [],
  ).join(' / ');
}

function handleDot(section) {
  const { FREESTYLE } = SECTION_FORMAT_KEY;

  if (section.format === FREESTYLE) {
    return section.attachments.length > 0 && <div className="sub-title__dot" />;
  }
  return <div className="sub-title__dot" />;
}

function SectionItem(props) {
  const { section, cloudfrontList } = props;

  const onDragStart = e => {
    props.onDragLeftPanelStart({
      dragType: 'section',
      section,
    });
  };
  const onDragEnd = e => {
    props.onDragLeftPanelStart(null);
  };
  return (
    <SectionItemWrapper draggable onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div className="title">
        <div>{section.title}</div>
      </div>
      <div className="sub-title">
        <div className="sub-title__format">{getFormatName(section.format)}</div>
        {handleDot(section)}
        <div className="sub-title__no-exercises">{getFormatDesc(section)}</div>
        {handleGetExerciseType(section.attachments)}
      </div>
      <div className="exercises-desc">{getExerciseText(section)}</div>
      {section.user && (
        <Avatar
          name={getUserShortName(section.user)}
          className="section-item__author"
          size="20"
          src={convertS3UrlToCloudFrontUrl(section.user.avatar, cloudfrontList, true)}
        />
      )}
    </SectionItemWrapper>
  );
}

const mapState = ({ cloudfrontList }) => ({
  cloudfrontList,
});

const mapDispatch = dispatch => ({
  onDragLeftPanelStart: bindActionCreators(onDragLeftPanelStart, dispatch),
});

export default connect(mapState, mapDispatch)(SectionItem);
