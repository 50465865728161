import React from 'react';
import * as S from './style';
import TableHeader from './components/TableHeader';
import TableContent from './components/TableContent';
import Footer from './components/Footer';
 
export default () => {
  return (
    <S.Wrapper>
      <S.TableContainer>
        <S.Table>
          <TableHeader />
          <TableContent />
        </S.Table>
      </S.TableContainer>
    </S.Wrapper>
  );
};
