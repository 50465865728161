// Libs
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

// Component
import Component from './component';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import {
  getIngredients,
  resetQueryParams,
  changeQueryParams,
  addSingleIngredient,
  addMultiIngredient,
  selectedIngredient,
  removeMultiIngredient,
  editIngredient,
  resetSelectedIngredient,
} from 'redux/ingredient-library/actions';
import { getListCategoryIngredient } from 'redux/recipes/actions';

const mapStateToProps = state => {
  const {
    rootReducer: { ingredientLibrary, recipes },
    user: { _id: userId = '' },
    cloudfrontList,
  } = state;

  const {
    list = [],
    loading = false,
    total = 0,
    filters = {},
    isLoading = false,
    selectedIngredients = [],
  } = ingredientLibrary;
  const { ingredient } = recipes || {};
  const { category: ingredientCategory = [] } = ingredient || {};

  return {
    list,
    loading,
    total,
    filters,
    cloudfrontList,
    ingredientCategory,
    isLoading,
    selectedIngredients,
    userId,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getIngredients: bindActionCreators(getIngredients, dispatch),
  editIngredient: bindActionCreators(editIngredient, dispatch),
  resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  selectedIngredient: bindActionCreators(selectedIngredient, dispatch),
  addMultiIngredient: bindActionCreators(addMultiIngredient, dispatch),
  addSingleIngredient: bindActionCreators(addSingleIngredient, dispatch),
  removeMultiIngredient: bindActionCreators(removeMultiIngredient, dispatch),
  getListCategoryIngredient: bindActionCreators(getListCategoryIngredient, dispatch),
  resetSelectedIngredient: bindActionCreators(resetSelectedIngredient, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
