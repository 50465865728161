import { CLIENT_CATEGORY } from 'constants/commonData';

export const readCSVFile = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = e => {
      let list = [],
        error = '';
      const { result } = e.target;
      const rows = result.split('\n');

      for (let i = 1; i < rows.length; i++) {
        let cells = rows[i].split(',');
        const first_name = cells[0] ? cells[0].replace(/\r?\n|\r/g, '') : '';
        const last_name = cells[1] ? cells[1].replace(/\r?\n|\r/g, '') : '';
        const email = cells[2] ? cells[2].replace(/\r?\n|\r/g, '') : '';
        const client_type = cells[3] ? cells[3].replace(/\r?\n|\r/g, '') : CLIENT_CATEGORY.ONLINE;
        const send_mail = !!cells[4] && cells[4].replace(/\r?\n|\r/g, '').toLowerCase() === 'yes';

        if (!first_name && !last_name && !email) {
          continue;
        }

        if (!first_name || !last_name) {
          error = 'First name, last name are required.';
          list = [];
          break;
        }

        const client = {
          client_info: { first_name, last_name, email, client_type },
          send_mail
        };
        list.push(client);
      }

      resolve({ list, error });
    };

    reader.onerror = reject;
    reader.readAsText(file);
  });
};
