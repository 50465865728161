import React, { useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Checkbox } from 'shared/FormControl';

import { ReactComponent as CheckedDefaultIcon } from 'assets/icons/checked_default.svg';

import * as S from './styles';

function ItemCheckbox(props) {
  const { option, value, disabled, onChange } = props;
  const key = useMemo(() => get(option, 'key'), [option]);

  const customLabelHtml = { __html: option.label };
  if (option.defaultChecked) {
    return (
      <S.CheckedDefault>
        <S.CheckedIcon>
          <CheckedDefaultIcon />
        </S.CheckedIcon>
        <S.LabelDefaultCheckbox dangerouslySetInnerHTML={customLabelHtml} />
      </S.CheckedDefault>
    );
  }

  if (!isEmpty(key)) {
    return (
      <Checkbox
        checked={value[key]}
        disabled={disabled}
        onChange={() => onChange(option)}
        size={16}
        className="card-checkbox"
        title={option.isCustomLabel ? <div dangerouslySetInnerHTML={customLabelHtml} /> : option.label}
      />
    );
  }
  return <></>;
}

export default ItemCheckbox;
