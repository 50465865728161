import React from 'react';
import PropTypes from 'prop-types';
import useEscKeyPress from 'hooks/useEscKeyPress';

import * as S from './style';

const ConfirmModalGroup = props => {
  const {
    modalId = undefined,
    noBorder,
    onConfirm,
    title,
    content,
    headerIcon,
    confirmButtonTitle,
    hasCloseIcon,
    className,
    toggleConfirmModal,
    isDiscardChange = false,
    noIcon,
    cancelButtonTitle,
  } = props;

  useEscKeyPress(() => {
    if (toggleConfirmModal) {
      return toggleConfirmModal(false);
    }
  });

  return (
    <S.CustomConfirmModal
      modalId={modalId}
      className={`delete-recipe-modal ${className || ''}`}
      title={title || 'Remove Metric Group from Library?'}
      content={content}
      onConfirm={onConfirm}
      noBorder={noBorder}
      noIcon={noIcon}
      headerIcon={headerIcon}
      confirmButtonTitle={confirmButtonTitle || 'Remove'}
      hasCloseIcon={hasCloseIcon}
      isDiscardChange={isDiscardChange}
      cancelButtonTitle={cancelButtonTitle}
    />
  );
};

ConfirmModalGroup.propTypes = {
  noBorder: PropTypes.bool,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  headerIcon: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default ConfirmModalGroup;
