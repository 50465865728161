import styled, { css } from 'styled-components';
import { NewSearchInput } from 'shared/SearchInput';
import IconSearch from 'assets/icons/search_icon_grey.svg';

const baseText = `
    font-family: 'Open Sans';
    font-style: normal;
    color: #202353;
    line-height: 150%;
    font-weight: 600;
`;

export const ComparisonButtonWrapper = styled.div`
  position: relative;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .btn-close-icon {
    width: 10px;
    height: 10px;
    path {
      fill: #7b7e91;
    }
  }
`;

export const ButtonTitle = styled.div`
  ${baseText}
  text-align: center;
  font-size: 12px;
`;

export const TriggerWrapper = styled.div`
  height: 30px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background-color: #fff;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
  &:hover {
    background-color: #f0f1ff;
    .btn-comparison-icon path {
      stroke: #5158cf;
    }
    ${ButtonContent} {
      .btn-close-icon path {
        fill: #5158cf;
      }
    }
    ${ButtonTitle} {
      color: #5158cf;
    }
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      padding: 0 8px 0 10px;
      white-space: nowrap;
    `}
  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: #f0f1ff;
      .btn-comparison-icon path {
        stroke: #5158cf;
      }
      ${ButtonContent} {
        .btn-close-icon path {
          fill: #5158cf;
        }
      }
    `}
  .__react_component_tooltip.app-tooltip.comparison-metric {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 400;
    background-color: #333;
    line-height: 18px;
  }
  .__react_component_tooltip.place-bottom {
    margin-top: 13px;
  }
  .app-tooltip.place-bottom:after {
    border-bottom-color: #333 !important;
  }
`;

export const ListWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 2;
`;

export const ListContent = styled.div`
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  padding: 10px 10px 0;
  border-radius: 10px;
  min-width: 319px;
  background-color: #fff;
`;

export const InputSearch = styled(NewSearchInput)`
  height: 40px;
  width: 100%;
  input {
    ${baseText}
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    border: 1px solid #dadbea;
    padding: 7px 10px 7px 45px;
    background: url(${IconSearch}) no-repeat;
    background-size: 17px 16px;
    background-position: 15px center;
    background-color: #f8f8f8;
    ::placeholder {
      ${baseText}
      color: #a3a3b5;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      opacity: 0.8;
    }

    &:focus {
      border-color: #5158cf;
      background-color: #fff;
    }
  }
  svg {
    right: 14px;

    path {
      fill: #a3a3b5;
    }
  }
  &:hover {
    input {
      border-color: #5158cf;
    }
  }
`;

export const ListHint = styled.div`
  padding: 15px 0 5px 10px;
  ${baseText}
  color: #7b7e91;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: start;
  span {
    display: block;
  }
  .desc-hint {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-transform: none;
    margin-top: 3px;
  }
`;

export const List = styled.div`
  position: relative;
  max-height: 330px;
  overflow-y: scroll;
  padding-bottom: 10px;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      max-height: none;
      height: 330px;
    `}
  .no-result {
    ${baseText}
    display: block;
    height: fit-content;
    text-align: center;
    margin-left: 3px;
    position: absolute;
    font-weight: 400;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #f0f0f2 !important;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 10px;
  }
`;

export const Item = styled.div`
  ${baseText}
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
  text-align: start;
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
  }
  ${props =>
    props.active &&
    css`
      color: #5158cf;
      font-weight: 600;
    `}
`;
