import React from 'react';
import { Helmet } from 'react-helmet';

import SearchClients from './SearchClients';
import SelectedClients from './SelectedClients';

import * as S from './style';

const Clients = ({ workingCollection }) => {
  if (!workingCollection._id) {
    return null;
  }
  return (
    <S.Wrapper>
      <Helmet>
        <title>{workingCollection.name} - Workout Collection - Everfit</title>
      </Helmet>
      <div className="addingClient__heading">Assign to</div>
      <p>Choose clients to assign to this Collection.</p>
      <div className="addingClient__body">
        <div className="addingClient__search">
          <SearchClients />
        </div>
        <div className="addingClient__selectedClient">
          <SelectedClients />
        </div>
      </div>
    </S.Wrapper>
  );
};

export default Clients;
