import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import FormItemInput from 'shared/FormItemInput';

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;

export const CreateNewPackageModal = styled(Modal)`
  &.ui.modal {
    width: 540px;
  }

  .header {
    color: #202353 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    border-radius: 8px;
  }

  .content {
    padding: 30px !important;
  }

  .formControl {
    margin-bottom: 30px;
  }

  .submit {
    padding: 7px 29px !important;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Form = styled.form``;

export const Input = styled(FormItemInput)``;
