import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactAvatar from 'react-avatar';
import { getUserShortName, isTeamAdmin } from 'utils/commonFunction';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483100;
  top: 0;
  left: 0;

  .overdueAnnuallyTeamMember {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    background: #fff;
    border-radius: 5px;
    z-index: 2147483100;
    padding: 30px;

    &__content {
      margin-bottom: 30px;
      width: 360px;

      &--title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 30px */

        text-align: center;

        color: #000000;

        margin-bottom: 10px;
      }

      &--description {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        text-align: center;

        color: #000000;
      }
    }

    &__closeButton {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
    }

    &__owner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: #F3F5F8;
      mix-blend-mode: normal;
      border-radius: 6px;
      padding: 15px;
      width: 100%;
    }
  }
`;

const AdminWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .admin__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    padding-left: 10px;
  }
`;

const TeamAdmin = (admin) => {
  return (
    <AdminWrapper>
      <ReactAvatar name={getUserShortName(admin)} size="30" src={admin.avatar} color={admin.color}/>
      <div className="admin__name">
        {admin.full_name}
      </div>
    </AdminWrapper>
  )
}

function OverdueAnnuallyTeamMember({user, location, ...props}) {
  const admins = user.team_member.filter(u => isTeamAdmin(u)).splice(0, 4);

  const goToHome = () => {
    props.push('/home/client');
  };

  return (
    <Wrapper >
      <div className="overdueAnnuallyTeamMember">
        <img onClick={goToHome} className="overdueAnnuallyTeamMember__closeButton" src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        <div className="overdueAnnuallyTeamMember__content">
          <div className="overdueAnnuallyTeamMember__content--title">
          Your payment is overdue
          </div>
          <div className="overdueAnnuallyTeamMember__content--description">
            <span> Please reach out to your workspace admin to continue using the Automation add-on.</span>
          </div>
        </div>
        <div className="overdueAnnuallyTeamMember__owner">
          {admins.map(admin => <TeamAdmin key={admin._id} {...admin} />)}
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state: State) => {
  const {
    user
  } = state;

  return {
    user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OverdueAnnuallyTeamMember)
);