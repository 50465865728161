import styled from 'styled-components';
import Heart from 'assets/icons/red_heart.png';

export const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a6a3b0;
`;

export const SideBar = styled.div`
  width: 60px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(92, 88, 192);
  padding: 22px 10px;

  > * {
    margin-bottom: 44px;
  }
`;

export const VideoContainer = styled.div`
  height: 363px;

  video {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const Button = styled.button`
  background: #cdcdcd;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  padding: 8px 72px;
  border: none;
  outline: none;

  :hover {
    background: #7470ef;
  }
`;

export const Information = styled.div`
  padding: 30px 30px 40px;
  text-align: center;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    margin: 0 0 7px;
  }

  h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #333333;
    opacity: 0.8;
    margin: 0 0 36px;
  }
`;

export const Content = styled.div`
  border-radius: 8px;
  background-color: #fff;
  width: 642px;
`;

export const ContentWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const HeartWrapper = styled.div`
  display: inline-block;
  height: 24px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Heart});
  margin: 0 6px;
`;
