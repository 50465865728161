import React, { useState } from 'react';
import { debounce, get, isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeQueryParams } from 'redux/tasks-library/actions';
import SelectTaskType from 'shared/SelectTaskType';
import SelectOwner from 'shared/SelectOwner';

import * as S from './style';

const DEFAULT_FILTERS = { types: [], owners: [] };

function TaskFilter(props) {
  const { changeQueryParams, onTrackingFilter } = props;
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const onSelectTaskType = (item, checked) => {
    if (checked) {
      setFilters(prevFilters => {
        const params = {
          ...prevFilters,
          types: [...prevFilters.types, item._id],
        };
        handleFilter(params);
        return params;
      });
    } else {
      setFilters(prevFilters => {
        const newList = prevFilters.types.slice();
        newList.splice(
          newList.findIndex(i => i === item._id),
          1,
        );
        const params = { ...prevFilters, types: newList };
        handleFilter(params);
        return params;
      });
    }
  };

  const handleSelectOwners = debounce(owners => {
    setFilters(prevFilters => {
      const params = {
        ...prevFilters,
        owners: owners,
      };
      handleFilter(params);
      return params;
    });
  }, 300);

  const handleFilter = queryParams => {
    const ownersId = queryParams ? get(queryParams, 'owners', []).map(owner => owner._id) : [];
    const newFilter = { ...queryParams, owners: ownersId };
    changeQueryParams({ ...newFilter, page: 1 });
    onTrackingFilter(!(isEmpty(get(queryParams, 'owners', [])) && isEmpty(get(queryParams, 'types', []))));
  };

  const handleClearFilter = debounce(() => {
    setFilters(DEFAULT_FILTERS);
    onTrackingFilter(false);
    changeQueryParams({ ...DEFAULT_FILTERS, page: 1 });
  }, 300);

  return (
    <S.Wrapper>
      <S.HeaderFilter>
        <div className="popup-title">Filter by: </div>
        <S.ClearSelection onClick={() => handleClearFilter()}>Clear All</S.ClearSelection>
      </S.HeaderFilter>
      <S.TypeWrapper>
        <SelectTaskType types={get(filters, 'types', [])} onSelectTaskType={onSelectTaskType} />
        <SelectOwner
          owners={get(filters, 'owners', [])}
          onSelectowners={handleSelectOwners}
          menuWidth={345}
          placeholder="Enter the users"
        />
      </S.TypeWrapper>
    </S.Wrapper>
  );
}

const mapDispatchToProps = dispatch => ({
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
});

export default connect(null, mapDispatchToProps)(TaskFilter);
