import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button as CloseButton, Image } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import { Mixpanel } from 'utils/mixplanel';
import ConfirmModal from './ConfirmModal';
// import actions
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { saveMacroNote } from 'redux/macros/actions';

import { CDN_URL } from 'constants/commonData';

// import assets
import macroTitleimage from 'assets/images/macro-note-title-icon.png';
import { AddNoteModalStyles, HeaderModalStyles, ContentModalStyles, ActionsModalStyles } from './styles';

const AddNoteModal = ({ noteData = '', clientId, setMacroNote, closeModal, dispatch }) => {
  const [note, setNote] = useState(noteData);

  const onChangeNote = e => {
    setNote(e.target.value || '');
  };

  const onClose = () => {
    if (note !== noteData) dispatch(toggleConfirmModal(true, <ConfirmModal onConfirm={closeModal} />));
    else closeModal();
  };

  const onSaveNote = () => {
    Mixpanel.track('macro-note-add-new');
    dispatch(
      saveMacroNote({
        note: note.trim(),
        client: clientId,
      }),
    ).then(response => {
      setMacroNote(get(response, 'data.data.note'));
      closeModal();
    });
  };

  return (
    <AddNoteModalStyles
      size={'tiny'}
      open={true}
      onClose={onClose}
      className="client-macro-note"
      closeOnDimmerClick={false}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
    >
      <HeaderModalStyles>
        <img src={macroTitleimage} className="title-image" alt="" />
        <div className="title">Macros Note</div>
      </HeaderModalStyles>
      <ContentModalStyles>
        <div className="description">Add a note to explain the macros goal for your clients</div>
        <textarea placeholder="Add Macros Note" defaultValue={note} onChange={onChangeNote} />
      </ContentModalStyles>
      <ActionsModalStyles>
        <Button onClick={onClose}>Cancel</Button>
        <Button purple onClick={onSaveNote}>
          Update
        </Button>
      </ActionsModalStyles>
    </AddNoteModalStyles>
  );
};

export default connect(null, dispatch => ({
  closeModal: () => {
    dispatch(toggleModal(false));
  },
  dispatch,
}))(AddNoteModal);
