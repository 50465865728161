import get from 'lodash/get';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Types } from './actions';

const INITIAL_MESSAGES = [
  {
    content:
      'Hi {FIRST_NAME}, welcome to the {WORKSPACE_NAME} team! I am so excited to have you onboard and I am eager to help you get moving towards your health and fitness goals!',
  },
  {
    content:
      'Make sure you watch the onboarding video so you’re familiar with all the great features at your disposal and ready to hit the ground running. Let’s get to it!',
  },
];

const INITIAL_STATE = {
  list: [],
  workingData: null,
  isEditMode: false,
  isSaveDraft: true,
  isSubmitting: false,
  isLoadingOnboarding: false,
  query: {
    page: 1,
    per_page: 20,
    text_search: '',
    total: 0,
    isEnd: false,
    loading: false,
  },
  onboarding_form: null,
  onboarding_message: {
    messages: INITIAL_MESSAGES,
  },
  status: 'draft',
  formTemplates: [],
  questionsTemplates: [],
  metricSettings: [],
  activeAssets: {
    form: true,
    message: true,
    assignments: true,
    autoflow: true,
    on_demand: true,
  },
  isLoadingSetting: false,
  workingSetting: null,
  isLoadingAction: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const { workingSetting } = state;

  switch (type) {
    case Types.ONBOARDING_FLOW_SELECTED_FORM:
      const { form } = payload;

      return Object.assign({}, state, {
        onboarding_form: form,
      });
    case Types.ONBOARDING_FLOW_SAVE_DRAFT_REQUEST:
      return Object.assign({}, state, {
        isSubmitting: true,
      });
    case Types.ONBOARDING_FLOW_SAVE_DRAFT_SUCCESS:
      const { data: draftData } = payload || {};

      return Object.assign({}, state, {
        workingData: draftData,
        isSubmitting: false,
        isSaveDraft: (payload || {}).multiple_onboarding_flow ? (draftData || {}).is_show_save_draft : isNil(draftData),
      });
    case Types.ONBOARDING_FLOW_SAVE_DRAFT_FAILED:
      return Object.assign({}, state, {
        isSubmitting: false,
      });
    case Types.ONBOARDING_FLOW_PUBLISH_REQUEST:
      return Object.assign({}, state, {
        isSubmitting: true,
      });
    case Types.ONBOARDING_FLOW_PUBLISH_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false,
        workingData: { ...state.workingData, status: 'publish' },
      });
    case Types.ONBOARDING_FLOW_PUBLISH_FAILED:
      return Object.assign({}, state, {
        isSubmitting: false,
      });
    case Types.ONBOARDING_FLOW_UNPUBLISH_REQUEST:
      return Object.assign({}, state, {
        isSubmitting: true,
      });
    case Types.ONBOARDING_FLOW_UNPUBLISH_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false,
        workingData: { ...state.workingData, status: 'draft' },
      });
    case Types.ONBOARDING_FLOW_UNPUBLISH_FAILED:
      return Object.assign({}, state, {
        isSubmitting: false,
      });
    case Types.ONBOARDING_FLOW_EDIT_SUCCESS:
      return Object.assign({}, state, {
        isEditMode: true,
      });
    case Types.ONBOARDING_FLOW_SAVE_CHANGES_SUCCESS:
      return Object.assign({}, state, {
        isEditMode: false,
        workingData: payload,
      });
    case Types.ONBOARDING_FLOW_CANCEL_CHANGES_SUCCESS:
      return Object.assign({}, state, {
        isEditMode: false,
      });
    case Types.ONBOARDING_FLOW_GET_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isLoadingOnboarding: true,
      });
    case Types.ONBOARDING_FLOW_GET_DETAIL_SUCCESS:
      const { data: detailData } = payload;

      return Object.assign({}, state, {
        workingData: detailData,
        isSaveDraft: (payload || {}).multiple_onboarding_flow
          ? (detailData || {}).is_show_save_draft
          : isNil(detailData),
        isLoadingOnboarding: false,
      });
    case Types.ONBOARDING_FLOW_GET_DETAIL_FAILED:
      return Object.assign({}, state, {
        isLoadingOnboarding: false,
      });
    case Types.ONBOARDING_FLOW_GET_FORM_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        formTemplates: payload,
      });
    case Types.ONBOARDING_FLOW_GET_FORM_TEMPLATES_DETAIL_SUCCESS:
      const isFirstTime = isEmpty(state.questionsTemplates);
      const isExisting = !isFirstTime && state.questionsTemplates.some(item => item._id === payload._id);

      return Object.assign({}, state, {
        questionsTemplates: isFirstTime
          ? [payload]
          : isExisting
          ? state.questionsTemplates
          : [...state.questionsTemplates, payload],
      });
    case Types.ONBOARDING_FLOW_REMOVE_FORM:
      const { formId, isCustomForm } = payload;

      let updatedForms = [];
      if (isCustomForm) {
        updatedForms = get(state, 'onboarding_form', []).filter(item => get(item, 'custom_form._id') !== formId);
      } else {
        updatedForms = get(state, 'onboarding_form', []).filter(item => get(item, 'form._id') !== formId);
      }

      return Object.assign({}, state, {
        onboarding_form: updatedForms,
      });
    case Types.ONBOARDING_FLOW_GET_DEFAULT_METRICS:
      return Object.assign({}, state, {
        metricSettings: payload,
      });
    case Types.ONBOARDING_FLOW_TOGGLE_ASSET_STATUS:
      return Object.assign({}, state, {
        activeAssets: payload,
      });
    case Types.ONBOARDING_FLOW_RESET_FORM_TEMPLATES:
      return Object.assign({}, state, {
        formTemplates: [],
      });

    case Types.ONBOARDING_FLOW_RESET_WORKING_DATA:
      return Object.assign({}, state, {
        workingData: null,
      });

    case Types.ONBOARDING_FLOW_RESET_WORKING_SETTING:
      return Object.assign({}, state, {
        workingSetting: null,
      });

    case Types.ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isLoadingSetting: true,
      });

    case Types.ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_SUCCESS:
      const { data } = payload || {};

      return Object.assign({}, state, {
        isLoadingSetting: false,
        workingSetting: data,
      });

    case Types.ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_FAILED:
      return Object.assign({}, state, {
        isLoadingSetting: false,
      });

    case Types.ONBOARDING_FLOW_SET_AS_DEFAULT_REQUEST:
    case Types.ONBOARDING_FLOW_UPDATE_INFO_REQUEST:
    case Types.ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_REQUEST:
    case Types.ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_REQUEST:
    case Types.ONBOARDING_FLOW_ADD_PACKAGES_REQUEST:
    case Types.ONBOARDING_FLOW_REMOVE_PACKAGE_REQUEST:
      return Object.assign({}, state, {
        isLoadingAction: true,
      });

    case Types.ONBOARDING_FLOW_SET_AS_DEFAULT_FAILED:
    case Types.ONBOARDING_FLOW_UPDATE_INFO_SUCCESS:
    case Types.ONBOARDING_FLOW_UPDATE_INFO_FAILED:
    case Types.ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_FAILED:
    case Types.ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_FAILED:
    case Types.ONBOARDING_FLOW_ADD_PACKAGES_FAILED:
    case Types.ONBOARDING_FLOW_REMOVE_PACKAGE_FAILED:
      return Object.assign({}, state, {
        isLoadingAction: false,
      });

    case Types.ONBOARDING_FLOW_SET_AS_DEFAULT_SUCCESS:
      const { success, default_type } = payload || {};
      const isWorkspaceDefault = get(state, 'workingData.is_default_team');

      return Object.assign({}, state, {
        workingData:
          isWorkspaceDefault && default_type === 'none'
            ? { ...state.workingData, is_default_team: !isWorkspaceDefault }
            : default_type === 'workspace_default'
            ? { ...state.workingData, is_default_team: success }
            : state.workingData,
        isLoadingAction: false,
      });

    case Types.ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_SUCCESS:
      const { trainer_invite_codes } = payload;

      return Object.assign({}, state, {
        workingSetting: {
          ...workingSetting,
          invite_codes: uniq([...workingSetting.invite_codes, ...trainer_invite_codes.invite_codes]),
          ...omit(trainer_invite_codes, ['invite_codes']),
        },
        isLoadingAction: false,
      });

    case Types.ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_SUCCESS:
      const { trainerId } = payload;

      return Object.assign({}, state, {
        workingSetting: {
          ...workingSetting,
          invite_codes: workingSetting.invite_codes.filter(it => get(it, 'trainer._id', '') !== trainerId),
        },
        isLoadingAction: false,
      });

    case Types.ONBOARDING_FLOW_ADD_PACKAGES_SUCCESS:
      const { data_packages } = payload;

      return Object.assign({}, state, {
        workingSetting: {
          ...workingSetting,
          packages: uniq([...workingSetting.packages, ...data_packages.packages]),
          ...omit(data_packages, ['packages']),
        },
        isLoadingAction: false,
      });

    case Types.ONBOARDING_FLOW_REMOVE_PACKAGE_SUCCESS:
      const { packageId } = payload;

      return Object.assign({}, state, {
        workingSetting: {
          ...workingSetting,
          packages: workingSetting.packages.filter(({ id }) => id !== packageId),
        },
        isLoadingAction: false,
      });

    default:
      return state;
  }
};
