import styled from 'styled-components';

export const DateTimeInputWrapper = styled.div`
  width: 100%;

  &.input-error {
    .inputs-wrapper {
      border: 1px solid #ea314a;
    }
  }

  .inputs-wrapper {
    border: 1px solid #e1e1ea;
    border-radius: 5px;
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.active,
    &:hover,
    &:focus-within {
      border: 1px solid #5158cf;
    }
  }

  .time-input-container {
    height: 34px !important;
    input.time-cleave {
      border: none;
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding-left: 13px;
    }

    .time-ap {
      height: 36px !important;
      line-height: 36px !important;
      width: 32px !important;
      right: -2px !important;

      &.time-am {
        right: 30px !important;
      }

      &.selected {
        font-weight: 600;
      }

      &.time-pm {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }
  }

  .date-input-container {
    min-width: 98px;
    justify-content: flex-start !important;
    position: relative;

    .rdt .date-input-field {
      border: none !important;
      height: 34px;
      font-size: 13px;
      line-height: 19.5px;
      font-weight: 400;
      width: 97px;
      color: #202353;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 20px;
      top: 8px;
      width: 1px;
      background-color: #e1e1ea;
    }

    .rdtPicker table tbody tr:first-child td .content {
      margin-top: 9px !important;
    }

    &.open-picker {
      .rdtDays {
        padding-top: 2px;
      }

      .date-input-field {
        background-color: transparent !important;
      }

      .rdtPicker {
        position: fixed;
      }
    }

    .rdtPicker {
      right: -145px !important;
    }
  }

  .error-msg {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
`;
