import React, { useState, useEffect } from 'react';
import { isNumber } from 'lodash';
import CaloInputStyles from './styles';

const CaloInput = ({ value, className, ...props }) => {
  const [text, setText] = useState(value || undefined);
  useEffect(() => {
    if (isNumber(value)) setText(`${value}`);
  }, [value]);

  const onInput = e => {
    setText(e.target.value);
  };
  return (
    <CaloInputStyles>
      <input
        className={`everfit-calo-input ${className}`}
        value={text}
        placeholder="-"
        onInput={onInput}
        onKeyPress={event => {
          const keyCode = event.keyCode || event.which;
          const keyValue = String.fromCharCode(keyCode);
          if (/\+|-/.test(keyValue)) event.preventDefault();
        }}
        style={{ width: text ? (text.length + 1) * 20 : '100%' }}
        {...props}
      />
      <label className="everfit-calo-label">Cal</label>
    </CaloInputStyles>
  );
};

export default CaloInput;
