import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Modal, Button, Image, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { RootCloseWrapper } from 'react-overlays';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { getMostRecentTagsList } from 'redux/tags/actions';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';

import { NewSearchInput } from 'shared/SearchInput';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL, TAGS_TYPE } from 'constants/commonData';
import ExerciseOverview from '../ExerciseOverview';
import ExerciseFilterPopup from '../ExerciseFilterPopup';
import ItemExercise from './ItemExercise';
import {
  DEFAULT_GENERAL_EXERCISE,
  DEFAULT_RECENT_EXERCISE,
  DEBOUNCE_TIME,
  cancelAllRequest,
  fetchGeneralExercise,
  fetchRecentExercise,
  addExerciseToGeneralList,
  removeExerciseFromGeneralList,
  clearSearchGeneralCancelTokens,
  clearSearchRecentCancelTokens,
} from './helps';
import { pluralize } from 'utils/commonFunction';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as AddCircle } from 'assets/icons/add_circle.svg';
import { ReactComponent as EmptyIcon } from 'assets/icons/empty_swap_out_exercise.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search_swap_out_exercise.svg';
import { ReactComponent as NotFoundIcon } from 'assets/icons/not_found_swap_out_exercise.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey_16.svg';

import * as S from './style';

function ExerciseListModal(props) {
  const {
    toggleSecondModal,
    toggleConfirmModal,
    closeModal,
    getMostRecentTagsList,
    user,
    exerciseLibrary = {},
    exerciseTags = {},
    cloudfrontList,
    getS3presignedURLFromLocalDatabase,
  } = props;

  const searchBoxPopupRef = useRef();
  const searchBoxGeneralRef = useRef();
  const lastScrollTopRef = useRef(0);
  const lastScrollTopPopupRef = useRef(0);
  const clearIconRecentRef = useRef();

  const [generalExercise, setGeneralExercise] = useState(DEFAULT_GENERAL_EXERCISE);
  const [recentExercise, setRecentExercise] = useState(DEFAULT_RECENT_EXERCISE);
  const [isLoading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const { isEmptyGeneralExercise, isEmptyRecentExercise } = useMemo(() => {
    const isEmptyGeneralExercise = generalExercise.list.length === 0;
    const isEmptyRecentExercise = recentExercise.list.length === 0;

    return { isEmptyGeneralExercise, isEmptyRecentExercise };
  }, [generalExercise, recentExercise, selectedIds]);

  useEffect(() => {
    exerciseTags.mostRecentList.length === 0 &&
      typeof getMostRecentTagsList === 'function' &&
      getMostRecentTagsList({
        page: 1,
        type: TAGS_TYPE.EXERCISE,
      });

    searchBoxGeneralRef.current = generalExercise.q;
    generalExercise.list.length === 0 && fetchGeneralExerciseDebounce(generalExercise);
  }, []);

  const fetchGeneralExerciseDebounce = debounce(
    params =>
      fetchGeneralExercise({
        data: params,
        callbackInit: () => setGeneralExercise(it => ({ ...it, ...params, loading: true })),
        callbackSuccess: response => {
          const { data = [], total = 0 } = response.data.data || {};

          const currentQueryText = params.q || '';
          const currentInputValue = searchBoxGeneralRef.current || '';

          if (currentQueryText.toLowerCase().trim() === currentInputValue.toLowerCase().trim()) {
            clearSearchGeneralCancelTokens();
            setGeneralExercise(it => ({
              ...it,
              list: it.page === 1 ? data : [...it.list, ...data],
              total,
              isEnd: total <= it.page * it.per_page,
            }));
          }
        },
        callbackFailure: error => console.error(error),
        callbackFinally: () => setGeneralExercise(it => ({ ...it, loading: false })),
      }),
    DEBOUNCE_TIME,
  );

  const fetchRecentExerciseDebounce = debounce(
    params =>
      fetchRecentExercise({
        data: params,
        callbackInit: () => setRecentExercise(it => ({ ...it, ...params, loading: true })),
        callbackSuccess: response => {
          const { data = [], total = 0, all_exercise_ids = [] } = response.data.data || {};

          const currentQueryText = params.q || '';
          const currentInputValue = (searchBoxPopupRef.current || {}).value || '';

          if (currentQueryText.toLowerCase().trim() === currentInputValue.toLowerCase().trim()) {
            clearSearchRecentCancelTokens();
            setRecentExercise(it => ({
              ...it,
              list: it.page === 1 ? data : [...it.list, ...data],
              total,
              isEnd: total <= it.page * it.per_page,
              all_exercise_ids,
            }));
          }
        },
        callbackFailure: error => console.error(error),
        callbackFinally: () => setRecentExercise(it => ({ ...it, loading: false })),
      }),
    DEBOUNCE_TIME,
  );

  const getMoreGeneralExercise = () => {
    const newState = { ...generalExercise, page: generalExercise.page + 1 };
    fetchGeneralExerciseDebounce(newState);
  };

  const getMoreRecentExercise = () => {
    const newState = { ...recentExercise, page: recentExercise.page + 1 };
    fetchRecentExerciseDebounce(newState);
  };

  const onBodyScrollAndLoadMore = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const isEnd = generalExercise.list.length > 0 && generalExercise.list.length === generalExercise.total;
    if (!isEnd && !generalExercise.loading && scrollTop > lastScrollTopRef.current) {
      const bottom = scrollHeight - (scrollTop + clientHeight);
      if (bottom < 150) {
        getMoreGeneralExercise();
      }
    }
    lastScrollTopRef.current = scrollTop;
  };

  const onScrollDebounce = debounce(onBodyScrollAndLoadMore, 0);

  const onBodyPopupScrollAndLoadMore = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const isEnd = recentExercise.list.length > 0 && recentExercise.list.length === recentExercise.total;
    if (!isEnd && !recentExercise.loading && scrollTop > lastScrollTopPopupRef.current) {
      const bottom = scrollHeight - (scrollTop + clientHeight);
      if (bottom < 150) {
        getMoreRecentExercise();
      }
    }
    lastScrollTopPopupRef.current = scrollTop;
  };

  const onScrollPopupDebounce = debounce(onBodyPopupScrollAndLoadMore, 0);

  const handleCloseModal = () => {
    if (recentExercise.showPopup || recentExercise.showFilter) return;
    typeof closeModal === 'function' && closeModal();
    cancelAllRequest();

    lastScrollTopRef.current = 0;
    lastScrollTopPopupRef.current = 0;
  };

  const toggleSortType = () => {
    const newState = {
      ...generalExercise,
      page: 1,
      list: [],
      sortAlphabetical: !generalExercise.sortAlphabetical,
      isEnd: false,
    };
    fetchGeneralExerciseDebounce(newState);
  };

  const handleOpenPreviewExercise = item => {
    item &&
      typeof toggleSecondModal === 'function' &&
      toggleSecondModal(true, <ExerciseOverview exercise={item} onClose={handleClosePreview} />);
  };

  const handleClosePreview = () => typeof toggleSecondModal === 'function' && toggleSecondModal(false);

  const handleSelectExercise = (isChecked = false) => exercise => {
    if (!exercise || isLoading) return;

    if (!isChecked) {
      setSelectedIds(prevSelect => [...prevSelect, exercise._id]);
    } else {
      setSelectedIds(prevFilters => {
        const newList = prevFilters.filter(id => id !== exercise._id);
        return newList;
      });
    }
  };

  const handleClearAllExercises = () => {
    if (isLoading) return;
    setSelectedIds([]);
  };

  const handleAddExercises = () => {
    if (!selectedIds.length) return;

    addExerciseToGeneralList({
      data: {
        loading: isLoading,
        exerciseIds: selectedIds,
      },
      callbackInit: () => setLoading(true),
      callbackSuccess: response => {
        const {
          data: { data },
        } = response;
        if (!isEmpty(data)) {
          let newGeneralExercises = data;

          const currentSearch = (generalExercise.q || '').trim().toLocaleLowerCase();
          if (!!currentSearch) {
            newGeneralExercises = data.filter(it => {
              const exerciseTitle = (it.exercise_name || '').trim().toLocaleLowerCase();
              return exerciseTitle.includes(currentSearch);
            });
          }

          const list = [...newGeneralExercises, ...generalExercise.list];
          const total = generalExercise.total + newGeneralExercises.length;
          const newStateGeneral = { ...generalExercise, list, total };
          setGeneralExercise(newStateGeneral);

          const count = data.length;
          const word = pluralize('exercise', count);
          toast(`${count} ${word} have been added to the list.`);
        }
      },
      callbackFailure: error => console.error(error),
      callbackFinally: () => {
        setLoading(false);
        handleClosePopup();
      },
    });
  };

  const handleRemoveExercise = (exercise = {}) => {
    removeExerciseFromGeneralList({
      data: {
        loading: isLoading,
        exerciseId: exercise._id,
      },
      callbackInit: () => setLoading(true),
      callbackSuccess: response => {
        const {
          data: { data: { success = false } = {} },
        } = response;
        if (success) {
          const list = (generalExercise.list || []).filter(it => it._id !== exercise._id);
          const total = Math.max(generalExercise.total - 1, 0);
          const newStateGeneral = { ...generalExercise, list, total };
          setGeneralExercise(newStateGeneral);
          toast('Exercise has been removed from the list.');
        }
      },
      callbackFailure: error => console.error(error),
      callbackFinally: () => setLoading(false),
    });
  };

  const handleConfirmRemoveExercise = item => {
    if (isLoading) return;
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Remove exercise"
          content="Are you sure you want to remove this exercise from the General Exercise List?"
          confirmButtonTitle="Remove"
          onConfirm={handleRemoveExercise.bind(this, { ...item })}
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          newStyle
          hasCloseIcon
          hasHoverState
          largeSpacing
        />,
      );
  };

  const handleSearch = (_, { value = '' }) => {
    searchBoxGeneralRef.current = value;
    const newState = { ...generalExercise, q: value, page: 1, list: [], total: 0, isEnd: false };
    fetchGeneralExerciseDebounce(newState);
  };

  const searchDebounce = debounce(handleSearch, 0);

  const handleClearSearch = () => {
    searchBoxGeneralRef.current = '';
    const newState = { ...generalExercise, q: '', page: 1, list: [], total: 0, isEnd: false };
    fetchGeneralExerciseDebounce(newState);
  };

  const handleOpenPopup = () => {
    if (recentExercise.showPopup) return;
    const newState = { ...DEFAULT_RECENT_EXERCISE, showPopup: true };
    fetchRecentExerciseDebounce(newState);
  };

  const handleClosePopup = () => {
    if (recentExercise.showFilter) return;
    setRecentExercise(DEFAULT_RECENT_EXERCISE);
    setSelectedIds([]);
  };

  const onApplyFilter = filter => {
    const newState = { ...recentExercise, page: 1, list: [], total: 0, filter };
    fetchRecentExerciseDebounce(newState);
  };

  const toggleFilter = showFilter => {
    setRecentExercise(it => ({ ...it, showFilter }));
  };

  const handleSearchPopup = () => {
    if (!searchBoxPopupRef.current) return;
    const newState = {
      ...recentExercise,
      q: searchBoxPopupRef.current.value || '',
      page: 1,
      list: [],
      total: 0,
      isEnd: false,
    };
    fetchRecentExerciseDebounce(newState);
  };

  const searchDebouncePopup = debounce(handleSearchPopup, DEBOUNCE_TIME);

  const handleSearchRecent = event => {
    searchDebouncePopup(event);

    if (clearIconRecentRef.current && searchBoxPopupRef.current) {
      if (searchBoxPopupRef.current.value) {
        clearIconRecentRef.current.classList.remove('hidden');
      } else {
        clearIconRecentRef.current.classList.add('hidden');
      }
    }
  };

  const handleClearSearchRecent = () => {
    if (!searchBoxPopupRef.current) return;
    searchBoxPopupRef.current.value = '';

    if (clearIconRecentRef.current) {
      clearIconRecentRef.current.classList.add('hidden');
    }

    const newState = {
      ...recentExercise,
      q: '',
      page: 1,
      list: [],
      total: 0,
      isEnd: false,
    };
    fetchRecentExerciseDebounce(newState);
  };

  const renderItemDefault = (it, idx) => (
    <ItemExercise
      key={`exercise-item-${it._id || idx}`}
      item={it}
      onClick={handleOpenPreviewExercise}
      onRemove={handleConfirmRemoveExercise}
      isDefaultExerciseItem
      cloudfrontList={cloudfrontList}
      getS3presignedURLFromLocalDatabase={getS3presignedURLFromLocalDatabase}
    />
  );

  const renderItemPopup = (it, idx) => {
    const isChecked = selectedIds.includes(it._id);

    return (
      <ItemExercise
        key={`exercise-item-popup-${it._id || idx}`}
        item={it}
        user={user}
        onClick={handleSelectExercise(isChecked)}
        isPopupExerciseItem
        cloudfrontList={cloudfrontList}
        isChecked={isChecked}
      />
    );
  };

  return (
    <S.CustomModal
      open
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <div className="header-title-container">
          <div className="title">General Exercise List</div>
          <S.AddNewPopup
            trigger={
              <div className="btn-add-new">
                <AddCircle className="icon-add-new" />
                <span className="label-add-new">Add Exercise</span>
              </div>
            }
            basic={true}
            flowing={true}
            hoverable={true}
            on="click"
            position="bottom right"
            open={recentExercise.showPopup}
            onOpen={handleOpenPopup}
            onClose={() => {}}
          >
            <RootCloseWrapper event="click" onRootClose={handleClosePopup} disabled={!recentExercise.showPopup}>
              <div className="container-popup">
                <div className="search-box-popup">
                  <div className="search-text-field">
                    <input
                      placeholder="Search Exercise"
                      onChange={handleSearchRecent}
                      autoFocus
                      ref={searchBoxPopupRef}
                    />
                  </div>
                  <div className="clear-actions">
                    <button className="clear-button hidden" onClick={handleClearSearchRecent} ref={clearIconRecentRef}>
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="filter-actions">
                    <ExerciseFilterPopup
                      currentFilters={recentExercise.filter}
                      onApply={onApplyFilter}
                      bodyParts={exerciseLibrary.body_parts || []}
                      categories={exerciseLibrary.categories || []}
                      equipments={exerciseLibrary.equipments || []}
                      modalities={exerciseLibrary.modalities || []}
                      muscleGroup={exerciseLibrary.muscleGroup || []}
                      movementPattern={exerciseLibrary.movementPattern || []}
                      tags={exerciseTags}
                      tagType={TAGS_TYPE.EXERCISE}
                      user={user}
                      useMinHeight={true}
                      menuMinHeight={455}
                      menuWidth={434}
                      toggleFilter={toggleFilter}
                    />
                  </div>
                </div>

                <div className="header-group-wrapper">
                  <div className="header-popup-content">
                    <div className="header-popup-label">
                      {isEmpty(recentExercise.q) ? `EXERCISES` : 'Results'}&nbsp;({recentExercise.total})
                    </div>
                  </div>
                  {!!selectedIds.length && (
                    <div className="header-selected">
                      <div className="selected-label">Selected</div>
                      <div className="selected-count">{selectedIds.length}</div>
                    </div>
                  )}
                </div>

                <div
                  className="exercise-scroll-wrapper-popup"
                  onScroll={event => onScrollPopupDebounce.call(this, { ...event })}
                >
                  {!isEmptyRecentExercise && recentExercise.list.map(renderItemPopup)}
                  {isEmptyRecentExercise && recentExercise.isEnd && !recentExercise.loading && (
                    <div className="empty-filter-container">
                      <div className="empty-filter-icon">
                        <NotFoundIcon />
                      </div>
                      <div className="empty-filter-text">No results found.</div>
                    </div>
                  )}
                  {!recentExercise.isEnd && (
                    <div className="popup-indicator-wrapper">
                      <Loader active inline="centered" className="popup-custom-indicator" size="medium">
                        <span>Loading...</span>
                      </Loader>
                    </div>
                  )}
                </div>
                <div className="action-buttons">
                  <button
                    className="btn-clear-all"
                    disabled={!selectedIds.length || isLoading}
                    onClick={handleClearAllExercises}
                  >
                    Clear all
                  </button>
                  <button className="btn-add" disabled={!selectedIds.length || isLoading} onClick={handleAddExercises}>
                    Add
                  </button>
                </div>
              </div>
            </RootCloseWrapper>
          </S.AddNewPopup>
        </div>
        <div className="description">This general exercise list is the same for every client.</div>
      </Modal.Header>
      <Modal.Content>
        <div className="search-container">
          <div className="search-wrapper">
            <SearchIcon className="search-icon" />
            <NewSearchInput
              placeholder="Search by keyword or name"
              onChange={searchDebounce}
              onClearSearch={handleClearSearch}
              autoFocus
            />
          </div>
        </div>
        <div className="exercise-container">
          <div className="content-header">
            <div
              className={classNames('header-total', {
                'search-mode': !isEmpty(generalExercise.q),
              })}
            >
              {isEmpty(generalExercise.q) ? 'EXERCISES' : 'RESULTS'}&nbsp;({generalExercise.total})
            </div>
            <div className="btn-sort" onClick={toggleSortType}>
              <span className="label-sort">{generalExercise.sortAlphabetical ? 'Alphabetical' : 'Most recent'}</span>
              <ArrowDown className="icon-sort" />
            </div>
          </div>
          <div className="exercise-scroll-wrapper" onScroll={event => onScrollDebounce.call(this, { ...event })}>
            {!isEmptyGeneralExercise && generalExercise.list.map(renderItemDefault)}
            {isEmptyGeneralExercise && generalExercise.isEnd && !generalExercise.loading && (
              <div className="empty-container">
                <div className="empty-icon">
                  <EmptyIcon />
                </div>
                <div className="empty-search-text">No results found.</div>
              </div>
            )}
            {!generalExercise.isEnd && (
              <div
                className={classNames('indicator-wrapper', {
                  'is-empty': isEmptyGeneralExercise,
                })}
              >
                <Loader active inline="centered" className="custom-indicator" size="medium">
                  <span>Loading...</span>
                </Loader>
              </div>
            )}
          </div>
        </div>
      </Modal.Content>
    </S.CustomModal>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  exerciseLibrary: state.rootReducer.exercise,
  exerciseTags: state.rootReducer.exerciseTags,
  cloudfrontList: state.cloudfrontList,
});

const mapDispatchToProps = {
  getMostRecentTagsList,
  getS3presignedURLFromLocalDatabase,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseListModal);
