import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
`;

export const Container = styled.div`
  width: 100%;
`;
export const SliderContainer = styled.div`
  width: 100%;
`;

export const RangeSlider = styled.div`
  height: 5px;
  position: relative;
  background-color: #f0f0f2;
  border-radius: 2px;
`;

export const RangeSelected = styled.div`
  height: 100%;
  left: 10px;
  right: 10px;
  position: absolute;
  border-radius: 5px;
  background-color: #5158cf;
`;

export const RangeInput = styled.div`
  position: relative;

  input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 2px 8px rgba(38, 38, 38, 0.2);
    background-color: #fff;
    pointer-events: auto;
    -webkit-appearance: none;
    cursor: grabbing;
  }

  input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -6px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    outline: none;
  }
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  padding: 0px 5px;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 23px;
`;

export const RangeLine = styled.div`
  width: 15px;
  border-bottom: 1px solid #e1e1ea;
`;

export const NumberInput = styled.input`
  ${baseText}
  display: flex;
  width: 176px;
  height: 36px;
  padding: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #fff;

  :focus {
    border: 1px solid #5158cf;
  }

  :focus-visible {
    outline: none;
  }

  :hover {
    border: 1px solid #5158cf;
    background-color: #f8f8f8;
    cursor: text;
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;
