import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import { toggleConfirmModal } from 'actions/modal';
import { countToActive } from 'redux/autoflow/actions';
import { NavLink } from 'react-router-dom';
import { resetLoadPostsQuery, resetChangeTab } from 'redux/forum/actions';
import { SharedTooltip } from 'shared/SharedTooltip';

import { isTeamAdmin } from 'utils/commonFunction';
import { ReactComponent as NotificationIcon } from 'assets/icons/bell_icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings_gear_icon.svg';
import { ReactComponent as LeaveGroupIcon } from 'assets/icons/leave_group_icon.svg';
import { NAVS } from 'components/CommunityForum/constants';
import ManageNotifications from './components/ManageNotifications';
import LeaveGroup from '../../LeaveGroup';
import { ASSET_TYPE } from 'components/Product/constants';
import { checkAssetAssignedToProduct } from 'utils/commonFunction';

import * as S from './style';

const Navbar = props => {
  const {
    location,
    toggleModal,
    push,
    connectedLeaderboard,
    group,
    user,
    payment,
    schedulePosts,
    scheduleTab,
    resetChangeTab,
    resetLoadPostsQuery,
    onboarding_flow,
  } = props;
  const forumId = props.match.params.id || props.match.params.forumId;
  const prefix = `/home/forums/${forumId}`;
  const { pathname } = location;
  const route = (pathname || '').trimAnyRight('/').split(prefix).pop();
  const [assigned, setAssigned] = useState(false);
  const [assignedOnboardingFlow, setAssignedOnboardingFlow] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (payment) {
      checkAssetAssignedToProduct(ASSET_TYPE.FORUM, forumId).then(res => setAssigned(res));
    }
    if (onboarding_flow) {
      checkAssetAssignedToProduct(ASSET_TYPE.FORUM, forumId, true).then(res => setAssignedOnboardingFlow(res));
    }
  }, [forumId]);

  const handleCloseAction = () => {
    toggleModal(false);
  };

  const manageNotifications = () => {
    toggleModal(true, <ManageNotifications onCloseAction={handleCloseAction} groupId={forumId} />);
  };

  const navigateToAdvancedSettings = () => {
    if (!forumId) return;
    push(`/home/forums/${forumId}/settings`);
  };

  const handleLeaveGroup = () => {
    const { toggleConfirmModal } = props;
    toggleConfirmModal &&
      toggleConfirmModal(true, <LeaveGroup assigned={assigned} assignedOnboardingFlow={assignedOnboardingFlow} />);
  };

  const customTrigger = (
    <div data-tip data-for="header-options-tooltip" className="header-options-tooltip">
      <SharedTooltip id="header-options-tooltip" />
    </div>
  );

  const renderActionDropdown = () => {
    return (
      <>
        <S.Dropdown button icon="ellipsis horizontal" onOpen={() => {}} trigger={customTrigger}>
          <S.Dropdown.Menu className="dropdown-menu more-actions">
            <S.Dropdown.Item onClick={manageNotifications}>
              <S.DropItem>
                <NotificationIcon />
                <span>Manage Notifications</span>
              </S.DropItem>
            </S.Dropdown.Item>
            <S.Dropdown.Item onClick={navigateToAdvancedSettings}>
              <S.DropItem>
                <SettingsIcon />
                <span>Advanced Settings</span>
              </S.DropItem>
            </S.Dropdown.Item>
            <S.Breaker />
            <S.Dropdown.Item onClick={handleLeaveGroup}>
              <S.DropItem leaveGroup>
                <LeaveGroupIcon />
                <span>Leave Group</span>
              </S.DropItem>
            </S.Dropdown.Item>
          </S.Dropdown.Menu>
        </S.Dropdown>
      </>
    );
  };

  const handleLoadPosts = () => {
    resetLoadPostsQuery();
  };

  return (
    <S.NavMenu>
      <div className="client-menu">
        {_.map(NAVS, (nav, index) => {
          if (nav.route === '/leaderboard' && _.isEmpty(connectedLeaderboard)) {
            return null;
          }
          if (nav.route === '/upcoming' && (schedulePosts <= 0 || schedulePosts === undefined)) {
            return null;
          }
          const handleClickTab = e => {
            resetChangeTab();
            const checkActiveTab = !nav.route ? route === nav.route : route.includes(nav.route);
            const isActive = (!nav.route ? route === nav.route : route.includes(nav.route)) && !disabled;

            if ((nav.route === '/discussion' || nav.route === '/upcoming') && checkActiveTab) {
              if (!isActive) {
                handleLoadPosts();
                setDisabled(true);
              }
            } else {
              return null;
            }
          };
          return (
            <NavLink
              key={index}
              className="client-menu-item"
              exact={nav.route === ''}
              to={`${prefix}${nav.route}`}
              isActive={() => (!nav.route ? route === nav.route : route.includes(nav.route))}
              onClick={handleClickTab}
              data-nav-index={index}
            >
              {nav.label === 'Scheduled Posts' ? (
                <span>
                  {nav.label} {`(${schedulePosts})`}
                </span>
              ) : (
                <span>{nav.label}</span>
              )}
            </NavLink>
          );
        })}
        {renderActionDropdown()}
      </div>
    </S.NavMenu>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { router, isModalOpen, user } = state;

  return {
    location: router.location,
    isModalOpen,
    connectedLeaderboard: state.rootReducer.forum.connectedLeaderboard,
    user,
    schedulePosts: state.rootReducer.forum.totalScheduledPost,
    scheduleTab: state.rootReducer.forum.query.tab,
    group: state.rootReducer.forum.group,
    payment: state.rootReducer.permission.payment,
    onboarding_flow: state.rootReducer.permission.onboarding_flow,
  };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  countToActive: bindActionCreators(countToActive, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  resetLoadPostsQuery: bindActionCreators(resetLoadPostsQuery, dispatch),
  resetChangeTab: bindActionCreators(resetChangeTab, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(Navbar);
