import Request from 'configs/request';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { CLASSNAME_TOAST } from 'constants/commonData';
import { updatePackageSurveyFlag } from 'redux/package-detail/actions';

export const Types = {
  SURVEY_CONTACT_FETCH_SURVEY_LIST_SUCCESS: 'SURVEY_CONTACT_FETCH_SURVEY_LIST_SUCCESS',
  SURVEY_CONTACT_FETCH_SURVEY_LIST_FAIL: 'SURVEY_CONTACT_FETCH_SURVEY_LIST_FAIL',
  SURVEY_CONTACT_FETCH_SURVEY_LIST_LOADING: 'SURVEY_CONTACT_FETCH_SURVEY_LIST_LOADING',
  SURVEY_CONTACT_RESET_SURVEY_LIST: 'SURVEY_CONTACT_RESET_SURVEY_LIST',
  SURVEY_CONTACT_FETCH_SURVEY_QUESTION_LIBRARIES_SUCCESS: 'SURVEY_CONTACT_FETCH_SURVEY_QUESTION_LIBRARIES_SUCCESS',
  SURVEY_CONTACT_CREATE_NEW_SURVEY_SUCCESS: 'SURVEY_CONTACT_CREATE_NEW_SURVEY_SUCCESS',
  SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_SUCCESS: 'SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_SUCCESS',
  SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_FAIL: 'SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_FAIL',
  SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_LOADING: 'SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_LOADING',
  SURVEY_CONTACT_FETCH_SURVEY_DETAILS_SUCCESS: 'SURVEY_CONTACT_FETCH_SURVEY_DETAILS_SUCCESS',
  SURVEY_CONTACT_FETCH_SURVEY_DETAILS_FAIL: 'SURVEY_CONTACT_FETCH_SURVEY_DETAILS_FAIL',
  SURVEY_CONTACT_FETCH_SURVEY_DETAILS_LOADING: 'SURVEY_CONTACT_FETCH_SURVEY_DETAILS_LOADING',
  SURVEY_CONTACT_ARCHIVE_SURVEY_SUCCESS: 'SURVEY_CONTACT_ARCHIVE_SURVEY_SUCCESS',
  SURVEY_CONTACT_ARCHIVE_SURVEY_FAIL: 'SURVEY_CONTACT_ARCHIVE_SURVEY_FAIL',
  SURVEY_CONTACT_ARCHIVE_SURVEY_LOADING: 'SURVEY_CONTACT_ARCHIVE_SURVEY_LOADING',
  SURVEY_CONTACT_UNARCHIVE_SURVEY_SUCCESS: 'SURVEY_CONTACT_UNARCHIVE_SURVEY_SUCCESS',
  SURVEY_CONTACT_UNARCHIVE_SURVEY_FAIL: 'SURVEY_CONTACT_UNARCHIVE_SURVEY_FAIL',
  SURVEY_CONTACT_UNARCHIVE_SURVEY_LOADING: 'SURVEY_CONTACT_UNARCHIVE_SURVEY_LOADING',
  SURVEY_CONTACT_DELETE_SURVEY_SUCCESS: 'SURVEY_CONTACT_DELETE_SURVEY_SUCCESS',
  SURVEY_CONTACT_DELETE_SURVEY_FAIL: 'SURVEY_CONTACT_DELETE_SURVEY_FAIL',
  SURVEY_CONTACT_DELETE_SURVEY_LOADING: 'SURVEY_CONTACT_DELETE_SURVEY_LOADING',
  SURVEY_CONTACT_RESET_SURVEY_DETAILS: 'SURVEY_CONTACT_RESET_SURVEY_DETAILS',
  SURVEY_CONTACT_SET_SAVED_SURVEY: 'SURVEY_CONTACT_SET_SAVED_SURVEY',
  SURVEY_CONTACT_RESET_SAVED_SURVEY: 'SURVEY_CONTACT_RESET_SAVED_SURVEY',
};

export const resetSurveyList = () => {
  return dispatch => {
    dispatch({ type: Types.SURVEY_CONTACT_RESET_SURVEY_LIST });
  };
};

export const resetSurveyDetails = () => {
  return dispatch => {
    dispatch({ type: Types.SURVEY_CONTACT_RESET_SURVEY_DETAILS });
  };
};

export const getSurveyList = filterParams => {
  return dispatch => {
    dispatch({ type: Types.SURVEY_CONTACT_FETCH_SURVEY_LIST_LOADING });
    return dispatch(
      Request.get(
        { url: '/api/payment/surveys', params: filterParams },
        true,
        (response, { dispatch }) => {
          const { page = 0, q } = filterParams || {};
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.SURVEY_CONTACT_FETCH_SURVEY_LIST_SUCCESS,
              payload: { data, filters: filterParams },
            });
          }
          if (page === 1) {
            dispatch(getSurveyStatistic({ q }));
          }
        },
        () => {
          dispatch({ type: Types.SURVEY_CONTACT_FETCH_SURVEY_LIST_FAIL });
        },
      ),
    );
  };
};

export const getSurveyQuestionLibraries = () => {
  return dispatch => {
    return dispatch(
      Request.get(
        { url: '/api/payment/survey-question-libraries', params: { order: 'ASC' } },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.SURVEY_CONTACT_FETCH_SURVEY_QUESTION_LIBRARIES_SUCCESS,
              payload: { data },
            });
          }
        },
      ),
    );
  };
};

export const createNewSurvey = data => {
  return dispatch =>
    dispatch(
      Request.post(
        { url: '/api/payment/surveys', data },
        true,
        null,
        err => {
          throw err;
        },
        false,
      ),
    );
};

export const updateSurvey = (id, data) => {
  return dispatch =>
    dispatch(
      Request.patch(
        { url: `/api/payment/surveys/${id}`, data },
        true,
        null,
        err => {
          throw err;
        },
        false,
      ),
    );
};

export const getSurveyStatistic = params => {
  return dispatch => {
    dispatch({
      type: Types.SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_LOADING,
    });
    return dispatch(
      Request.get(
        { url: '/api/payment/surveys/statistic', params },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (!params) {
            const hasSurveyList = !!get(data, 'active', 0);
            dispatch(updatePackageSurveyFlag({ has_survey_list: hasSurveyList }));
          }
          dispatch({
            type: Types.SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_SUCCESS,
            payload: { data },
          });
        },
        () => {
          dispatch({ type: Types.SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_FAIL });
        },
      ),
    );
  };
};

export const getSurveyDetails = id => {
  return dispatch => {
    dispatch({
      type: Types.SURVEY_CONTACT_FETCH_SURVEY_DETAILS_LOADING,
    });
    return dispatch(
      Request.get(
        { url: `/api/payment/surveys/${id}` },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.SURVEY_CONTACT_FETCH_SURVEY_DETAILS_SUCCESS,
              payload: { data },
            });
          }
        },
        () => {
          dispatch({
            type: Types.SURVEY_CONTACT_FETCH_SURVEY_DETAILS_FAIL,
          });
        },
      ),
    );
  };
};

export const archiveSurvey = (payload, successCallback, errorCallback) => {
  const { id, filters } = payload || {};
  if (!id) return;
  return dispatch => {
    dispatch({
      type: Types.SURVEY_CONTACT_ARCHIVE_SURVEY_LOADING,
    });
    dispatch(
      Request.post(
        { url: `/api/payment/surveys/${id}/archive` },
        true,
        () => {
          toast('You have archived a survey successfully.', { className: CLASSNAME_TOAST.SURVEY_MANAGEMENT });
          dispatch(getSurveyStatistic({ q: filters.q }));
          dispatch({ type: Types.SURVEY_CONTACT_ARCHIVE_SURVEY_SUCCESS, payload: { id } });
          successCallback && successCallback(id);
        },
        err => {
          const errorMessage = get(err, 'response.data.message');
          const statusCode = get(err, 'response.data.statusCode');
          if (statusCode === 400 && errorMessage === 'Can not archive the applied survey.') {
            errorCallback && errorCallback();
          }
          dispatch({ type: Types.SURVEY_CONTACT_ARCHIVE_SURVEY_FAIL });
        },
        false,
      ),
    );
  };
};

export const unarchiveSurvey = payload => {
  const { id, filters } = payload || {};
  return dispatch => {
    dispatch({
      type: Types.SURVEY_CONTACT_UNARCHIVE_SURVEY_LOADING,
    });
    dispatch(
      Request.post(
        { url: `/api/payment/surveys/${id}/unarchive` },
        true,
        () => {
          toast('You have unarchived a survey successfully.', { className: CLASSNAME_TOAST.SURVEY_MANAGEMENT });
          dispatch(getSurveyStatistic({ q: filters.q }));
          dispatch({ type: Types.SURVEY_CONTACT_UNARCHIVE_SURVEY_SUCCESS, payload: { id } });
        },
        () => {
          dispatch({ type: Types.SURVEY_CONTACT_UNARCHIVE_SURVEY_FAIL });
        },
      ),
    );
  };
};

export const deleteSurvey = payload => {
  const { id, filters } = payload || {};
  return dispatch => {
    dispatch({
      type: Types.SURVEY_CONTACT_DELETE_SURVEY_LOADING,
    });
    dispatch(
      Request.delete(
        { url: `/api/payment/surveys/${id}` },
        true,
        () => {
          toast('You have deleted an archived survey successfully.', { className: CLASSNAME_TOAST.SURVEY_MANAGEMENT });
          dispatch(getSurveyStatistic({ q: filters.q }));
          dispatch({ type: Types.SURVEY_CONTACT_DELETE_SURVEY_SUCCESS, payload: { id } });
        },
        () => {
          dispatch({ type: Types.SURVEY_CONTACT_DELETE_SURVEY_FAIL });
        },
      ),
    );
  };
};

export const setSavedSurvey = survey => {
  return dispatch => {
    dispatch({ type: Types.SURVEY_CONTACT_SET_SAVED_SURVEY, payload: { survey } });
  };
};

export const resetSavedSurvey = () => {
  return dispatch => {
    dispatch({ type: Types.SURVEY_CONTACT_RESET_SAVED_SURVEY });
  };
};
