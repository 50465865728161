import React from 'react';
import styled from 'styled-components';
import ComplianceFilter from '../ComplianceFilter';
import HeaderItem from './HeaderItem';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-top: 20px solid #fff;

  .compliance-filter {
    position: absolute;
    left: 50%;
    top: -20px;
    transform: translateX(-50%);
    z-index: 2;
  }
`;

const ComplianceHeader = props => {
  return (
    <Wrapper>
      <ComplianceFilter className="compliance-filter" />
      <HeaderItem
        column={{
          key: 'percent_completed_workout',
          fieldData: 'percent_completed_workout',
          label: 'Training',
          sortKey: 'percent_completed_workout',
        }}
      />
      <HeaderItem
        column={{
          key: 'percent_completed_task',
          fieldData: 'percent_completed_task',
          label: 'Task',
          sortKey: 'percent_completed_task',
        }}
      />
    </Wrapper>
  );
};

export default ComplianceHeader;
