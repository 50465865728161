import React from 'react';
import classnames from 'classnames';
import { isNumber, round } from 'lodash';
import classNames from 'classnames';
// import components
import { FormGroup } from 'shared/FormControl';
import CaloInput from '../CaloInput';
import { PercentFormStyles } from './styles';

const PercentForm = ({ totalCalo, setTotalCalo, setNutri, nutri, errors, setErrors }) => {
  return (
    <PercentFormStyles>
      <div className="total-cal percent-form">
        <div className="title">Total Calories Goal</div>
        <div className="result">
          <CaloInput
            type="number"
            className={classnames({ error: errors.totalCalo })}
            value={totalCalo}
            onChange={e => {
              setTotalCalo(parseFloat(e.target.value));
            }}
          />
        </div>
      </div>
      <FormGroup>
        <label className="title">
          <span className="dot protein-dot"></span> Protein (%)
        </label>
        <input
          type="number"
          placeholder="Eg: 20%"
          className={classNames({ error: errors && errors.protein })}
          value={nutri.protein}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-/.test(keyValue)) event.preventDefault();
          }}
          onChange={e => {
            setNutri({ ...nutri, protein: parseFloat(e.target.value) });
          }}
          onBlur={e => {
            const { carbs, fat } = nutri;
            const number = e.target.value ? parseFloat(e.target.value) : 0;
            if (isNumber(carbs) && !fat) {
              const fat = round(100 - carbs - number, 2);
              if (fat < 0) {
                return setErrors({
                  carbs: false,
                  fat: false,
                  protein: true,
                });
              }
              setErrors({
                carbs: false,
                fat: false,
                protein: false,
              });
              return setNutri({ ...nutri, protein: number, fat });
            }
            if (isNumber(fat) && !carbs) {
              const carbs = round(100 - fat - number, 2);
              if (carbs < 0) {
                return setErrors({
                  carbs: false,
                  fat: false,
                  protein: true,
                });
              }
              setErrors({
                carbs: false,
                fat: false,
                protein: false,
              });
              return setNutri({ ...nutri, protein: number, carbs });
            }
            if (!fat && !carbs && number > 100) {
              return setErrors({
                carbs: false,
                fat: false,
                protein: true,
              });
            }
            if (fat + carbs + number !== 100)
              return setErrors({
                carbs: false,
                fat: false,
                protein: true,
              });
            setErrors({
              carbs: false,
              fat: false,
              protein: false,
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <label className="title">
          <span className="dot carbs-dot"></span> Carbs (%)
        </label>
        <input
          type="number"
          placeholder="Eg: 20%"
          className={classNames({ error: errors && errors.carbs })}
          value={nutri.carbs}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-/.test(keyValue)) event.preventDefault();
          }}
          onChange={e => {
            setNutri({ ...nutri, carbs: parseFloat(e.target.value) });
          }}
          onBlur={e => {
            const { protein, fat } = nutri;
            const number = e.target.value ? parseFloat(e.target.value) : 0;
            if (isNumber(protein) && !fat) {
              const fat = round(100 - protein - number, 2);
              if (fat < 0) {
                return setErrors({
                  fat: false,
                  protein: false,
                  carbs: true,
                });
              }
              setErrors({
                fat: false,
                protein: false,
                carbs: false,
              });
              return setNutri({ ...nutri, carbs: number, fat });
            }
            if (isNumber(fat) && !protein) {
              const protein = round(100 - fat - number, 2);
              if (protein < 0) {
                return setErrors({
                  fat: false,
                  protein: false,
                  carbs: true,
                });
              }
              setErrors({
                fat: false,
                protein: false,
                carbs: false,
              });
              return setNutri({ ...nutri, carbs: number, protein });
            }
            if (!fat && !protein && number > 100) {
              return setErrors({
                fat: false,
                protein: false,
                carbs: true,
              });
            }
            if (fat + protein + number !== 100)
              return setErrors({
                fat: false,
                protein: false,
                carbs: true,
              });
            setErrors({
              fat: false,
              protein: false,
              carbs: false,
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <label className="title">
          <span className="dot fat-dot"></span> Fat (%)
        </label>
        <input
          type="number"
          placeholder="Eg: 20%"
          className={classNames({ error: errors && errors.fat })}
          value={nutri.fat}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-/.test(keyValue)) event.preventDefault();
          }}
          onChange={e => {
            setNutri({ ...nutri, fat: parseFloat(e.target.value) });
          }}
          onBlur={e => {
            const { protein, carbs } = nutri;
            const number = e.target.value ? parseFloat(e.target.value) : 0;
            if (isNumber(protein) && !carbs) {
              const carbs = round(100 - protein - number, 2);
              if (carbs < 0) {
                return setErrors({
                  protein: false,
                  carbs: false,
                  fat: true,
                });
              }
              setErrors({
                protein: false,
                carbs: false,
                fat: false,
              });
              return setNutri({ ...nutri, fat: number, carbs });
            }
            if (isNumber(carbs) && !protein) {
              const protein = round(100 - carbs - number, 2);
              if (protein < 0) {
                return setErrors({
                  protein: false,
                  carbs: false,
                  fat: true,
                });
              }
              setErrors({
                protein: false,
                carbs: false,
                fat: false,
              });
              return setNutri({ ...nutri, fat: number, protein });
            }
            if (!carbs && !protein && number > 100) {
              return setErrors({
                protein: false,
                carbs: false,
                fat: true,
              });
            }
            if (carbs + protein + number !== 100)
              return setErrors({
                protein: false,
                carbs: false,
                fat: true,
              });
            setErrors({
              protein: false,
              carbs: false,
              fat: false,
            });
          }}
        />
      </FormGroup>
      <div className="note">Note: macronutrients must sum to 100%</div>
    </PercentFormStyles>
  );
};

export default PercentForm;
