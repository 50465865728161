import styled, { css } from 'styled-components';
import { Wrapper } from 'shared/Styles/ModalLayout';
import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import { Button } from 'shared/FormControl';

export const Tip = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  opacity: 0.3;
  text-align: right;
  padding: 5px 35px;
`;

export const Messages = styled.div`
  background-color: #fff;
  padding: 20px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const InAppMessageWrapper = styled(Wrapper)`
  width: 669px;
  height: calc(100vh - 48px);
  max-height: 675px;

  .inAppMessageForm__header {
    padding-bottom: 15px;
    display: block;
    position: relative;

    .inAppMessageForm__header__title {
      margin-bottom: 2px;
    }

    .inAppMessageForm__header__description {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #323c47;
    }

    .paused {
      padding: 7px 25px;
      position: absolute;
      right: 20px;
      top: 30px;
      background: #ffc42b;
      border-radius: 3px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
  }

  .inAppMessageForm__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${Messages} {
      flex: 1 1;
    }
  }

  .inAppMessageForm__actions {
    padding: 10px 30px;
    justify-content: space-between;

    .remaining {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #848484;
    }
  }

  ${props =>
    props.sent &&
    css`
      .inAppMessageForm__actions {
        padding-left: 15px;
        padding-right: 15px;

        .allMessageSent {
          width: 100%;
          background: #f8f8f8;
          border-radius: 3px;
          padding: 8px;
          text-align: center;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #1f2023;
          cursor: not-allowed;

          span {
            opacity: 0.4;
          }
        }
      }
    `}

  .message-paused-alert {
    background: #f6f6f6;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #1f2023;
    padding: 12px;
    margin: 0 15px;

    span {
      opacity: 0.4;
    }
  }
`;

export const IntervalMessageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  margin-bottom: 4px;

  button {
    width: 120px;
  }

  ${props =>
    props.editting &&
    css`
      justify-content: space-between;
      margin-bottom: 0;

      button {
        width: unset;
      }
    `}
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal.modal--in-app-message {
    border-radius: 8px;
    max-width: 100%;
    width: unset;

    > .content {
      padding: 0 !important;
      border-radius: 8px !important;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.with-client-list {
      ${InAppMessageWrapper} {
        box-shadow: -1px 0px 20px rgba(30, 46, 103, 0.1);
      }
    }
  }
`;

export const SubmitButton = styled(Button)`
  width: 112px;
  height: 35px;

  &:not(:disabled):hover {
    background-color: rgba(81, 88, 207, 0.9);
  }

  ${props =>
    props.isRemove &&
    css`
      background-color: #ff5e5e;

      &:not(:disabled):hover {
        background-color: rgba(255, 94, 94, 0.9);
      }
    `}
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
