import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export const PurchasedButton = styled.div`
  background: #f2f4f8;
  border-radius: 3px;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  color: #202353;
  padding: 12px 25px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    path {
      stroke: #202353;
    }
  }
`;

export const AutomationWrapper = styled.div`
  padding: 20px 30px 20px 30px;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  position: relative;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${props =>
    props.active &&
    css`
      border: 1px solid #6261ff;
      box-shadow: 0 0 0 1px #6261ff;

      .automation__label {
        border: 1px solid #6261ff !important;
        border-bottom: unset !important;
      }
    `}

  .automation__left {
    padding-top: 10px;
    display: flex;

    .automation__icon {
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .automationContent {
      margin-left: 28px;

      &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 100%;
        color: #202353;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        a {
          color: #202353;
        }

        &--addOn {
          font-weight: 600;
          font-size: 11px;
          line-height: 100%;
          color: #202353;
          padding: 4px 14px;
          background: #e4e8ef;
          border-radius: 3px;
          margin-left: 10px;
          text-transform: uppercase;
        }
      }

      &__featureTitle {
        font-size: 13px;
        line-height: 140%;
        color: #202353;
        margin-bottom: 10px;
      }

      &__featureItem {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #202353;
        margin: 0;
        padding-left: 20px;

        li {
          margin-bottom: 6px;
        }
      }
    }
  }

  .automation__purchased {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    align-items: center;
  }

  .automation__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    align-items: flex-end;

    .automation__checkbox {
      display: flex;
      align-items: center;

      &--text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
        text-align: right;
        color: #202353;
        margin-right: 10px;
      }

      input:checked ~ .checkbox-icon {
        background-image: url(${CDN_URL}/images/check_icon.svg);
      }

      .checkbox-icon {
        height: 20px;
        width: 20px;
      }
    }

    .automation__pricing {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--cost {
        margin-bottom: 8px;
      }

      &--from {
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        text-align: right;
        color: #202353;
      }

      &--symbol {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        color: #202353;
        vertical-align: top;
      }

      &--value {
        font-weight: bold;
        font-size: 34px;
        line-height: 100%;
        color: #202353;
        margin-right: 7px;
      }

      &--unit {
        font-weight: normal;
        font-size: 13px;
        line-height: 100%;
        color: #202353;
      }

      &--clients {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 100%;
        color: #202353;
      }

      &--zapier {
        font-size: 15px;
        font-weight: 700;
        line-height: 20.43px;
        margin-right: 4px;
      }

      &--tier {
        font-size: 11px;
        font-weight: 700;
        line-height: 14.98px;
        color: #202353;
        opacity: 0.3;
      }
      &--cost-discount {
        width: max-content;
      }
      &--value-discount {
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        color: #31a26d;
      }
      &--unit-discount {
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 700;
        line-height: 13px;
        color: #31a26d;
      }
      &--desc-discount {
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
        line-height: 13px;
        margin-left: 12px;
        color: #31a26d;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 2px;
          background: #202353;
          opacity: 0.4;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          left: -6px;
        }
      }
      &--btn-trial {
        cursor: pointer;
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #5c6881;
        border: 1px solid #e8e8e8;
        background: #ffffffe5;
        border-radius: 5px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        margin-top: 22px;
        &:hover {
          background: #f0f1ff;
          color: #5158cf;
        }
      }

      &--discount {
        align-items: end;
        padding-left: 60px;
        .automation__pricing--value {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background: #202353;
            top: calc(50% + 2px);
            transform: translateY(-50%);
          }
        }
      }
    }

    .automation__dropdown {
      display: flex;
      align-items: center;
      margin-top: 31px;
      margin-bottom: 9px;

      .dropdown--zapier-picker {
        min-width: 273px;
        max-width: 400px;
      }

      .dropdown--zapier-picker .common-select-container__control {
        max-height: 40px;
        height: 40px !important;
        box-shadow: none !important;
        cursor: pointer;
        border-color: #cccccc;

        &:hover,
        &:focus,
        &--menu-is-open {
          border-color: #5e59ff;
        }

        .common-select-container__indicators i {
          color: #a1a1a5;
          margin: 1px 3px 0 0;
        }
      }

      .dropdown--zapier-picker .common-select-container__menu {
        width: 441px;
        height: 364px;
        margin-bottom: 3px;
        right: 0;
      }

      .dropdown--zapier-picker .common-select-container__dropdown-indicator {
        margin-right: 8px;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .dropdown--zapier-picker .common-select-container__option--is-disabled {
        cursor: not-allowed;
      }

      .dropdown--zapier-picker .common-select-container__option {
        font-size: 13px;
        line-height: 18px;
        padding: 7px 15px 8px 7px;
      }

      .dropdown--zapier-picker .common-select-container__value-container {
        padding-left: 15px;
        padding-right: 0;
      }

      .dropdown--zapier-picker .common-select-container__menu-list {
        min-height: 364px;
        padding-top: 0;
        margin-right: 4px;

        .zapier_options {
          padding: 9px 8px 7px 16px;

          .checkbox__circle {
            height: 10px !important;
            width: 9px !important;
          }

          &:hover,
          &--is-focused {
            border-radius: 8px;
            background: linear-gradient(90deg, #f5f5ff 15.97%, rgba(245, 245, 255, 0) 74.96%);
          }
        }
      }
    }

    .automation__howCalculate {
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;
      text-align: right;
      text-decoration-line: underline;
      color: #202353;
      cursor: pointer;
      opacity: 0.6;
    }
  }

  .__react_component_tooltip.app-tooltip-white {
    font-weight: normal;
    border: 1px solid #dfe4e5;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #000000;
    background-color: #ffffff;
    max-width: 260px;
  }

  ${({ isShowBetaLabel }) =>
    isShowBetaLabel &&
    css`
      padding-top: 70px;
    `}

  .automation__label {
    position: absolute;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
    padding: 15px 20px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #dadfea;
    border-bottom: unset;
    background: #fffbeb;
    display: flex;
    align-items: center;
    gap: 8px;

    .automation__label--content {
      color: #393e41;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      &.is-bold {
        font-weight: 700;
      }
    }
  }
`;

export const HowToCalculateModalWrapper = styled(Modal)`
  background: #ffffff;
  border: 1px solid #d4d4d5;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 590px;

  .pricingAuto__content {
    border-radius: 8px !important;
    padding: 32px 30px !important;
  }

  .pricingAuto__heading {
    margin-bottom: 20px;

    &--text {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #202353;
    }

    &--tag {
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;
      color: #202353;
      padding: 8px 10px;
      margin-left: 10px;
      background: #e4e8ef;
      border-radius: 3px;
    }
  }

  .pricingAuto__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    margin-bottom: 30px;
  }

  .pricingAuto__table {
    .pricingAuto__pricingHeader {
      display: grid;
      grid-template-columns: 1fr 0.6fr;
      padding: 10px 15px;
      background: #6261ff;
      border-radius: 5px;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 11px;
      line-height: 15px;
      color: #efefef;
    }
  }

  .pricingAuto__footer {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
`;

export const PricingRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  padding: 15px;
  border-bottom: 1px solid #eeeeee;

  font-weight: normal;
  font-size: 13px;
  line-height: 100%;

  color: #202353;

  :last-child {
    border-bottom: none;
  }
`;

export const SpecialLaunchPricingContainer = styled.div`
  background: #ffc44515;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 35px;
  width: max-content;
  color: #f2af1f;

  img {
    margin-right: 5px;
  }
`;

export const OptionsWrapper = styled.div`
  padding: 8px 0px 0px 9px;
`;

export const CustomZapierOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  :before {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid #f6f6f6;
    bottom: -12px;
    position: absolute;
  }
`;

export const ZapierTask = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #202353;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  p {
    font-size: 11px;
    font-weight: 700;
    opacity: 0.3;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
`;
const sharedZapierStyles = `
  color: #202353;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
`;

export const ZapierPrice = styled.div`
  ${sharedZapierStyles}
  display: flex;
  font-weight: 400;
  flex-direction: column;

  p {
    font-weight: 700;
  }
`;

export const ZapierPriceUnit = styled.div`
  display: flex;
  justify-content: end;
`;

export const ZapierPeriodLabel = styled.span`
  color: #505373;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
`;
export const ZapierOriginalPrice = styled.span`
  ${sharedZapierStyles}
  color: #505373;
  font-size: 12px;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const ZapierSingleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ZapierSingleTitle = styled.span`
  ${sharedZapierStyles}
  font-size: 11px;
  font-weight: 700;
  opacity: 0.3;
  text-transform: uppercase;
  margin-right: 10px;
`;

export const SelectedZapier = styled.span`
  ${sharedZapierStyles}
  font-weight: 700;
  white-space: nowrap;
`;

export const ZapierPeriod = styled.span`
  ${sharedZapierStyles}
  font-weight: 400;
`;

export const ScheduledPlan = styled.div`
  display: inline-block;
  margin-left: 12px;
  height: 20px;
  padding: 4px 8px;
  color: #f37335;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  border-radius: 20px;
  background: #fef0e6;
  text-transform: uppercase;
`;

export const CurrentPlan = styled.div`
  display: inline-block;
  margin-left: 12px;
  height: 20px;
  padding: 4px 8px;
  color: #202353;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  border-radius: 20px;
  background: #e7e6fe;
  text-transform: uppercase;
`;
