import styled from 'styled-components';

const btnHeight = '36px';
const popupTop = '44px';

export const Wrapper = styled.div`
  position: relative;
  .filter__dropdown {
    background-color: #f5f5f5;
    cursor: pointer;
    height: ${btnHeight};
    padding: 8px 12px;
    font-size: 14px;
    color: #262626;
    &.active,
    :hover {
      background-color: #f4f4fc !important;
    }
    .filter__dropdown__title {
      .filter-name {
        width: 44px;
      }

      .colons {
        margin-left: 1px;
        margin-right: 4px;
        width: 4px;
      }

      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }
    }
  }
`;

export const MenuContainer = styled.div`
  width: 307px;
  height: 96px;
  padding-top: 13px;
  padding-left: 23px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #e0e4e5;
  background: #fff;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  top: ${popupTop};
  z-index: 999;
  .form-group label {
    margin-bottom: 5px;
    color: #96a4b4;
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .ui.dropdown.visible svg path {
    stroke: #5158cf;
  }

  .ui.dropdown {
    height: 32px;
    width: 259px;
    padding-left: 9px;
    padding-right: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: solid 1px #e0e4e5;
    > .text {
      color: #343c46;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    svg path {
      stroke: #6f7075;
    }
    .menu {
      width: 259px;
      top: calc(100% + 4px);
      left: -1px;
      border-radius: 4px;
      border: 1px solid #d9d9d9 !important;
      box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
      .item {
        :first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        :last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        color: #595959;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 8px 13px !important;
        &.active {
          font-weight: 600;
        }
        :hover,
        &.active {
          color: #5158cf;
          background-color: #f4f4fc;
        }
      }
    }
    &:hover,
    &.visible.active {
      svg path {
        stroke: #5158cf;
      }
    }
  }
`;
