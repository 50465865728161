import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectProgram from 'components/SelectProgramModal/component';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { all_items, preview_selected_item } = rootReducer.program_library;
  return {
    all_items,
    previewItem: preview_selected_item,
  };
};

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProgram);
