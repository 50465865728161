import React from 'react';

import { Input, ButtonContainer, Container } from './style';
import { getPlainText } from './helper';

class CommentInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  };

  onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode > 36 && keyCode < 41) {
      // press arrow key
      event.stopPropagation();
    }
  };

  onSubmit = () => {
    const { onPost } = this.props;
    const value = getPlainText(this.inputRef.current.innerHTML);
    const trimValue = value.trimAny('↵').trim();
    if (trimValue) {
      onPost(trimValue, () => {
        this.inputRef.current.innerText = '';
      });
    }
  };

  onPaste = event => {
    event.preventDefault();
    let text = '';

    if (event.clipboardData && event.clipboardData.getData) {
      text = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      text = window.clipboardData.getData('Text');
    }

    // Normalize the line breaks to ensure consistent rendering, regardless of the source or platform.
    text = text.replace(/\r\n/g, '\n');

    document.execCommand('insertText', false, text);
    event.persist();
  };

  render() {
    const { placeholder } = this.props;

    return (
      <Container className="comments__container">
        <Input
          className="comments__input"
          contentEditable
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.onKeyDown}
          ref={this.inputRef}
          placeholder={placeholder || 'Add a comment...'}
          onPaste={this.onPaste}
        />
        <ButtonContainer className="comments__buttons">
          <button className="button button--post" onClick={this.onSubmit} type="button">
            Post
          </button>
        </ButtonContainer>
      </Container>
    );
  }
}

export default CommentInput;
