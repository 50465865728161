import React from 'react';
import { connect } from 'react-redux';
import WarningYellow from 'assets/icons/archive_yellow.svg';

import * as S from './style';

const ArchiveClientWithProduct = ({ onConfirm, dispatch }) => {
  // Confirm archive client
  const handleConfirmArchive = () => {
    onConfirm && onConfirm(true);
  };

  // Confirm keep client
  const handleConfirmKeepClient = () => {
    onConfirm && onConfirm(false);
  };

  return (
    <S.ConfirmWrapper
      isConfirmModalOpen={true}
      title="Archive Client"
      className="cancel-with-product"
      content={
        <S.Wrapper>
          <S.Message>
            The Sequence is being removed. Would you like to archive the client as well, or keep the client active?
          </S.Message>
          <S.Message>When a client is archived, they will lose access to training features.</S.Message>
        </S.Wrapper>
      }
      onConfirm={handleConfirmArchive}
      confirmButtonTitle="Archive Client"
      cancelButtonTitle="Keep Client Active"
      newStyle
      hasCloseIcon
      headerIcon={WarningYellow}
      onDeny={handleConfirmKeepClient}
      dispatch={dispatch}
    />
  );
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};
export default connect(null, mapDispatchToProps)(ArchiveClientWithProduct);
