import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

const createCSS = numOfLines => {
  let styles = '';

  for (let i = 2; i <= numOfLines; i++) {
    let ratio = (i - 1) / (numOfLines - 1);
    styles += `
      &.line${i} {
         top: calc(${ratio * 100}% - ${ratio * 10}px);
       }
     `;
  }

  return styles;
};

export const Container = styled.div`
  position: relative;
  padding: 5px 0;

  .program-add-week__line {
    height: 10px;
    background-color: transparent;
    position: absolute;
    left: 4px;
    top: 0;
    right: 4px;
    border-radius: 999999px;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    transition: background-color 0.2s ease;

    ${props => createCSS(props.numOfLines)}

    .program-add-week__icon {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 50%;
      top: -11px;
      transform: translateX(-50%);
      background: #5c5bbd url(${CDN_URL}/images/plus_white.svg) no-repeat center;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.2s ease;
      border-radius: 3px;
    }

    :last-child {
      bottom: 0;
      top: unset;
      .program-add-week__icon {
        top: unset;
        bottom: 0;
      }
    }

    &.line1 {
      .program-add-week__icon {
        top: 0;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          left: -8px;
          top: -8px;
          z-index: -1;
          width: 40px;
          height: 40px;
        }
      }
    }

    &.no-show {
      display: none;
    }

    :hover {
      background-color: #5c5bbd;
      .program-add-week__icon {
        opacity: 1;
      }
    }
  }

  .calendar-column__body {
    height: 100%;
  }

  .calendar-cell {
    padding: 5px 4px;
  }

  ${props =>
    props.isCopingWeek &&
    css`
      .add-new {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
      }
    `}
`;

export default {
  calendar: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },

  column: {
    display: 'flex',
    marginTop: 0,
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },

  weekday: {
    fontSize: 10,
    color: 'grey',
    marginLeft: 8,
  },
};

export const PasteButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding-bottom: 4px;
  top: 10px;
  left: 4px;
`;

export const PasteButtonContainer = styled.div`
  height: 100%;
  width: calc(100% - 8px);
  background: rgba(108, 115, 154, 0.1);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => (props.calendarType === 4 ? '8px' : '12px')};
  position: relative;
  z-index: 10;
  opacity: 0;

  &:hover {
    opacity: 1 !important;
  }
`;

export const PasteButtonContainerEmpty = styled.div`
  height: 100%;
  width: calc(100% - 8px);
  background-color: transparent;
  margin-bottom: ${props => (props.calendarType === 4 ? '8px' : '12px')};
  position: relative;
  z-index: -1;
  opacity: 0;
`;

export const PasteButton = styled.div`
  width: 247px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  border-radius: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #ffffff;
  cursor: pointer;
`;
