import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button } from 'shared/FormControl';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { initPricingData } from 'redux/pricing/actions';
import { initPermission } from 'redux/permission/actions';
import CheckIcon from './CheckIcon';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fcfcfc;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2147483100;
  top: 0;
  left: 0;

  .paymentSuccess__logo {
    margin-bottom: 120px;
    margin-top: 60px;

    img {
      height: 38px;
    }
  }

  .paymentSuccess {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    padding: 55px;
    border: 1px solid #ebedf4;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
    border-radius: 10px;

    &__heading {
      margin-bottom: 30px;
    }

    &__content {
      margin-bottom: 40px;
      width: 360px;
      text-align: center;
      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 104%;
        text-align: center;

        color: #202353;
      }
    }

    &__logout {
      button {
        padding: 8px 45px;
      }
    }
  }
  .counter {
    font-size: 13px;
    line-height: 104%;
    text-align: center;

    color: #999999;
    margin-top: 30px;
  }
`;

function PopupPaymentSuccess({ location, ...props }) {
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(sec => sec - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const goHome = () => {
    props.push('/home/billing');

    setTimeout(() => {
      props.initPermission();
    }, 1000);
    props.initPricingData();
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };

  if (seconds === 0) {
    goHome();
  }

  return (
    <Wrapper>
      <div className="paymentSuccess__logo">
        <img src={`${CDN_URL}/images/logo.png`} alt="" />
      </div>
      <div className="paymentSuccess">
        <div className="paymentSuccess__heading">
          <CheckIcon />
        </div>
        <div className="paymentSuccess__content">
          <span>Your payment is successful!</span>
        </div>
        <div className="paymentSuccess__logout">
          <Button purple onClick={goHome}>
            Return home
          </Button>
        </div>
      </div>
      <div className="counter">Returning to Everfit in {seconds < 0 ? 0 : seconds} seconds...</div>
    </Wrapper>
  );
}

const mapStateToProps = (state: State) => {
  const { user } = state;

  return {
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
    initPermission: bindActionCreators(initPermission, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupPaymentSuccess);
