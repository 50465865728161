import styled from 'styled-components';

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 20px 30px;
  padding-bottom: 30px;
`;

export const NoResult = styled.div`
  margin-top: 65px;
  text-align: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  opacity: 0.4;
`;

export const Wrapper = styled.div`
  flex: 1 1;
  overflow: auto;
  padding: 0 30px 0;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
