import styled, { css } from 'styled-components';

import ButtonIcon from 'shared/ButtonIcon';

export const WorkoutsHeader = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  .search-input {
    width: 265px;
    height: 30px;

    input {
      background-color: #fff;
      border: 1px solid #e0e1e4;

      ::placeholder {
        color: #777777;
      }

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #7072dd;
      }
    }
  }

  .buttonClassName {
    button {
      margin-right: 0;

      &:hover,
      &:focus,
      &:active {
        background: #5559ff !important;
      }
    }
  }
`;

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  .on-demand-filters {
    top: 35px;
  }
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  .filter-options {
    .ui.button {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 12px;

      :hover {
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
    }
  }
`;

export const CounterFilters = styled.div`
  right: -6px;
  top: -10px;
  width: 22px;
  height: 22px;
  line-height: 17px;
  border: 3px solid rgb(255, 255, 255);
  position: absolute;
  background: #e54d2e;
  border-radius: 50%;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
`;
