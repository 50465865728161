import { get, pickBy, identity, omit } from 'lodash';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import Request from 'configs/request';
import { ASSET_TYPE, AUTOFLOW_STATUS } from 'components/Product/constants';
import { Mixpanel } from 'utils/mixplanel';
import { WORKOUT_COLLECTION_STATUS } from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl, replaceImageToThumbnailConvertedURL } from 'utils/commonFunction';

export const Types = {
  PRODUCT_INIT: 'PRODUCT_INIT',
  PRODUCT_GET_LIST_REQUEST: 'PRODUCT_GET_LIST_REQUEST',
  PRODUCT_GET_LIST_SUCCESS: 'PRODUCT_GET_LIST_SUCCESS',
  PRODUCT_GET_LIST_FAIL: 'PRODUCT_GET_LIST_FAIL',
  PRODUCT_GET_LIST_MORE_REQUEST: 'PRODUCT_GET_LIST_MORE_REQUEST',
  PRODUCT_GET_LIST_MORE_SUCCESS: 'PRODUCT_GET_LIST_MORE_SUCCESS',
  PRODUCT_GET_LIST_MORE_FAIL: 'PRODUCT_GET_LIST_MORE_FAIL',
  PRODUCT_ADD_NEW_REQUEST: 'PRODUCT_ADD_NEW_REQUEST',
  PRODUCT_ADD_NEW_SUCCESS: 'PRODUCT_ADD_NEW_SUCCESS',
  PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUEST',
  PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_HIDE_BANNER_REQUEST: 'PRODUCT_HIDE_BANNER_REQUEST',
  PRODUCT_HIDE_BANNER_SUCCESS: 'PRODUCT_HIDE_BANNER_SUCCESS',
  PRODUCT_DUPLICATE_REQUEST: 'PRODUCT_DUPLICATE_REQUEST',
  PRODUCT_DUPLICATE_SUCCESS: 'PRODUCT_DUPLICATE_SUCCESS',
  PRODUCT_ARCHIVE_REQUEST: 'PRODUCT_ARCHIVE_REQUEST',
  PRODUCT_ARCHIVE_SUCCESS: 'PRODUCT_ARCHIVE_SUCCESS',
  PRODUCT_UNARCHIVE_REQUEST: 'PRODUCT_UNARCHIVE_REQUEST',
  PRODUCT_UNARCHIVE_SUCCESS: 'PRODUCT_UNARCHIVE_SUCCESS',
  PRODUCT_DELETE_REQUEST: 'PRODUCT_DELETE_REQUEST',
  PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
  PRODUCT_UPDATE_REDUX: 'PRODUCT_UPDATE_REDUX',
  PRODUCT_COUNT_REQUEST: 'PRODUCT_COUNT_REQUEST',
  PRODUCT_COUNT_SUCCESS: 'PRODUCT_COUNT_SUCCESS',
  PRODUCT_DETAIL_ADD_NEW: 'PACKAGE_DETAIL_ADD_NEW',
  PRODUCT_CREATE_DRAFT_REQUEST: 'PRODUCT_CREATE_DRAFT_REQUEST',
  PRODUCT_CREATE_DRAFT_SUCCESS: 'PRODUCT_CREATE_DRAFT_SUCCESS',
  PRODUCT_CREATE_DRAFT_END_SUBMIT: 'PRODUCT_CREATE_DRAFT_END_SUBMIT',
  PRODUCT_DETAIL_SUBMIT_REQUEST: 'PRODUCT_DETAIL_SUBMIT_REQUEST',
  PRODUCT_DETAIL_SUBMIT_SUCCESS: 'PRODUCT_DETAIL_SUBMIT_SUCCESS',
  PRODUCT_DETAIL_GET_DATA_REQUEST: 'PRODUCT_DETAIL_GET_DATA_REQUEST',
  PRODUCT_DETAIL_GET_DATA_SUCCESS: 'PRODUCT_DETAIL_GET_DATA_SUCCESS',
  PRODUCT_EDIT_GET_DATA_REQUEST: 'PRODUCT_EDIT_GET_DATA_REQUEST',
  PRODUCT_EDIT_GET_DATA_SUCCESS: 'PRODUCT_EDIT_GET_DATA_SUCCESS',
  FETCH_PRODUCT_DETAIL: 'FETCH_PRODUCT_DETAIL',
  PRODUCT_PUBLISH_REQUEST: 'PRODUCT_PUBLISH_REQUEST',
  PRODUCT_PUBLISH_SUCCESS: 'PRODUCT_PUBLISH_SUCCESS',
  PRODUCT_UPDATE_QUERY_REDUX: 'PRODUCT_UPDATE_QUERY_REDUX',
  PRODUCT_RESET_DUPLICATE_REDUX: 'PRODUCT_RESET_DUPLICATE_REDUX',
  PRODUCT_UPLOAD_IMAGE_REQUEST: 'PRODUCT_UPLOAD_IMAGE_REQUEST',
  PRODUCT_UPLOAD_IMAGE_SUCCESS: 'PRODUCT_UPLOAD_IMAGE_SUCCESS',
  REQUEST_NEW_COUNTRY_SUCCESS: 'REQUEST_NEW_COUNTRY_SUCCESS',
  REQUEST_NEW_COUNTRY_FAILED: 'REQUEST_NEW_COUNTRY_FAILED',
  GET_NEW_COUNTRY_STATUS_SUCCESS: 'GET_NEW_COUNTRY_STATUS_SUCCESS',
  GET_NEW_COUNTRY_STATUS_FAILED: 'GET_NEW_COUNTRY_STATUS_FAILED,',
  GET_NEW_COUNTRY_STATUS: 'GET_NEW_COUNTRY_STATUS',
};

export const initProduct = () => {
  // TODO
  return dispatch => {
    dispatch({ type: Types.PRODUCT_INIT });
  };
};

const formatParams = params => {
  let assets = get(params, 'assets', []);
  assets.forEach((element, index) => {
    const assetData = element.asset_data;
    assets[index].asset_data = omit(assetData, ['clients', 'sections', 'author']);
  });
  params.assets = assets;
  return params;
};

export const updateProduct = data => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_UPDATE_REQUEST });
    Mixpanel.track('Product_update');
    return dispatch(
      Request.patch(
        { url: `api/payment/products/${data.id}`, data: formatParams(data) },
        true,
        (response, { dispatch }) => {
          const { data: responseData } = response.data;
          dispatch({ type: Types.PRODUCT_UPDATE_SUCCESS, payload: responseData });
          toast('Sequence has been saved');
        },
      ),
    );
  };
};

export const getListProduct = () => {
  return (dispatch, getState) => {
    let queryParams;
    const {
      rootReducer: { product },
      cloudfrontList,
    } = getState();

    queryParams = product.get('query').toJS();
    dispatch({ type: Types.PRODUCT_GET_LIST_REQUEST });

    return dispatch(
      Request.get(
        {
          url: 'api/payment/products',
          params: pickBy(queryParams, identity),
        },
        true,
        response => {
          const { data, total } = response.data.data;
          const list = data.map(item => {
            const imgS3 = convertS3UrlToCloudFrontUrl(item.cover_image, cloudfrontList, true);
            return {
              ...item,
              banner: replaceImageToThumbnailConvertedURL(imgS3),
              banner_s3: imgS3,
            };
          });
          dispatch({ type: Types.PRODUCT_GET_LIST_SUCCESS, payload: { data: list, total } });
        },
        error => {
          dispatch({ type: Types.PRODUCT_GET_LIST_FAIL, error: error });
        },
      ),
    );
  };
};

export const getPublishedProducts = query => {
  const params = {
    status: 'publish',
    ...query,
  };

  return Request.get({ url: `api/payment/products`, params: pickBy(params, identity) });
};

export const loadMoreProduct = () => {
  return (dispatch, getState) => {
    let queryParams;
    const {
      rootReducer: { product },
    } = getState();

    queryParams = product.get('query').toJS();
    queryParams.page = queryParams.page + 1;

    dispatch({ type: Types.PRODUCT_GET_LIST_MORE_REQUEST });

    return dispatch(
      Request.get(
        { url: 'api/payment/products', params: pickBy(queryParams, identity) },
        true,
        response => {
          const { data, page } = response.data.data;
          dispatch({ type: Types.PRODUCT_GET_LIST_MORE_SUCCESS, payload: { data, page: queryParams.page } });
        },
        error => {
          dispatch({ type: Types.PRODUCT_GET_LIST_MORE_FAIL, error: error });
        },
      ),
    );
  };
};

export const deleteProduct = id => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_DELETE_REQUEST });
    Mixpanel.track('Product_delete');
    return dispatch(
      Request.delete({ url: `api/payment/products/${id}` }, true, (response, { dispatch }) => {
        dispatch({ type: Types.PRODUCT_DELETE_SUCCESS, payload: id });
        toast('Sequence has been deleted');
      }),
    );
  };
};

export const archiveProduct = (params, isDetail = true) => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_ARCHIVE_REQUEST });
    Mixpanel.track('Product_archive');
    return dispatch(
      Request.patch(
        {
          url: `api/payment/products/${params.id}/archive`,
        },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.PRODUCT_ARCHIVE_SUCCESS, payload: { id: params.id, isDetail } });

          toast(
            `Sequence ‘${params.name}‘ has been archived. It will become inactive in all Packages, and the sequence will not get assigned to new purchases.`,
          );
        },
      ),
    );
  };
};

export const unArchiveProduct = params => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_UNARCHIVE_REQUEST });
    Mixpanel.track('Product_unarchive');
    return dispatch(
      Request.patch({ url: `api/payment/products/${params.id}/unarchive` }, true, (response, { dispatch }) => {
        dispatch({ type: Types.PRODUCT_UNARCHIVE_SUCCESS, payload: params });

        toast(
          `Sequence ‘${params.name}‘ has been unarchived. New purchases of Packages including the Sequence will resume auto-assignment.`,
        );
      }),
    );
  };
};

export const duplicateProduct = params => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_DUPLICATE_REQUEST, payload: params });
    Mixpanel.track('Product_duplicate');
    return dispatch(
      Request.post(
        {
          url: `api/payment/products/${params.id}/duplicate`,
          data: {
            name: `${params.name.substring(0, 83)} (Copy)`,
          },
        },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.PRODUCT_DUPLICATE_SUCCESS, payload: { data } });
          dispatch(push(`/home/sequences/${data.hash_id}`));
        },
      ),
    );
  };
};

export const publishProduct = params => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_PUBLISH_REQUEST });
    Mixpanel.track('Product_publish');
    return dispatch(
      Request.post(
        { url: `api/payment/products/${params.id}/publish`, data: formatParams(params) },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data.name) {
            toast(`Sequence ‘${data.name}‘ has been published. You can add it to Packages now.`);
          }
          dispatch({ type: Types.PRODUCT_PUBLISH_SUCCESS, payload: { data } });
        },
      ),
    );
  };
};

export const hideProductBanner = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.PRODUCT_HIDE_BANNER_REQUEST });

    return dispatch(
      Request.put({ url: 'api/product/hide-banner' }, true, (response, { dispatch }) => {
        dispatch({ type: Types.PRODUCT_HIDE_BANNER_SUCCESS });
      }),
    );
  };
};

export const searchAsset = (assetType, params) => {
  const query = {
    page: 1,
    per_page: 20,
    sorter: 'last_used',
    ...params,
  };
  switch (assetType) {
    case ASSET_TYPE.AUTOFLOW:
      return Request.get({
        url: `/api/autoflow/v2/list`,
        params: { ...query, status: AUTOFLOW_STATUS.AUTOFLOW_STATUS_ACTIVATED },
      });

    case ASSET_TYPE.RESOURCE:
      return Request.post({ url: `/api/studio-collection/v2/fetch-by-trainer`, data: query });

    case ASSET_TYPE.STUDIO:
      return Request.post({ url: `/api/studio-program/fetch-by-trainer`, data: { ...query, isPublished: true } });

    case ASSET_TYPE.FORUM:
      return Request.get({ url: `/api/forum/get-list-group`, params: query });

    case ASSET_TYPE.PROGRAM:
      return Request.get({ url: `/api/program/library/v2/all`, params: query });

    case ASSET_TYPE.WORKOUT_COLLECTION:
      return Request.get({
        url: `/api/on-demand-workout-collections`,
        params: { ...query, textSearch: query.search, status: WORKOUT_COLLECTION_STATUS.PUBLISH },
      });

    default:
      break;
  }
};

export const countProducts = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.PRODUCT_COUNT_REQUEST });
    return dispatch(
      Request.get({ url: 'api/payment/count-products' }, true, (response, { dispatch }) => {
        const { data } = response.data;
        dispatch({ type: Types.PRODUCT_COUNT_SUCCESS, payload: data });
      }),
    );
  };
};

export const getProductDetail = (id, enableEdit = false) => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_DETAIL_GET_DATA_REQUEST });
    return dispatch(
      Request.get({ url: '/api/payment/products/' + id + '/detail' }, true, (response, { dispatch }) => {
        let { data } = response.data;

        if (data) {
          data = { ...data, is_edit_mode: enableEdit };
          dispatch({
            type: Types.PRODUCT_DETAIL_GET_DATA_SUCCESS,
            payload: { data },
          });
        }
      }),
    );
  };
};

export const getProductEdit = id => {
  // TODO
  return Request.post({ url: '/api/payment/products/' + id + '/start-edit-mode' }, true, (response, { dispatch }) => {
    const { data } = response.data;

    if (data) {
      dispatch({
        type: Types.PRODUCT_EDIT_GET_DATA_SUCCESS,
        payload: { data },
      });
    }
  });
};

export const addNewProduct = data => {
  // TODO
  return dispatch => {
    dispatch({ type: Types.PRODUCT_DETAIL_ADD_NEW, payload: data });

    if (get(window.location, 'search', '').includes('skip')) {
      dispatch(push(`/home/sequences/create/${window.location.search}`));
    } else {
      dispatch(push('/home/sequences/create'));
    }
  };
};

export const fetchProduct = data => {
  // TODO
  return dispatch => {
    dispatch({ type: Types.FETCH_PRODUCT_DETAIL, payload: data });
  };
};

export const saveProductDraft = data => {
  const params = pickBy(data, identity);
  return dispatch => {
    dispatch({ type: Types.PRODUCT_CREATE_DRAFT_REQUEST });
    Mixpanel.track('Product_create_new');
    return dispatch(
      Request.post(
        { url: '/api/payment/products/draft', data: formatParams(params) },
        true,
        (response, { dispatch }) => {
          const { data: responseData } = response.data;
          dispatch({
            type: Types.PRODUCT_CREATE_DRAFT_SUCCESS,
            payload: { data: responseData },
          });
          toast('Sequence has been saved', { autoClose: 1000 });
          dispatch(push(`/home/sequences/${responseData.hash_id}`));
        },
        () => {
          dispatch({ type: Types.PRODUCT_CREATE_DRAFT_END_SUBMIT });
        },
      ),
    );
  };
};

export const getPreSignedUrl = data => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_DETAIL_SUBMIT_REQUEST });
    return dispatch(
      Request.post({ url: '/api/payment/products/upload/get-pre-signed-url', data }, true, (response, { dispatch }) => {
        dispatch({ type: Types.PRODUCT_DETAIL_SUBMIT_SUCCESS });
      }),
    );
  };
};

export const startUploadCoverImage = () => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_UPLOAD_IMAGE_REQUEST });
  };
};

export const endUploadCoverImage = () => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_UPLOAD_IMAGE_SUCCESS });
  };
};

export const updateQuery = params => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      rootReducer: { product },
    } = currentState;
    const query = {
      ...product.toJS().query,
      ...params,
    };
    dispatch({ type: Types.PRODUCT_UPDATE_QUERY_REDUX, payload: { query } });
  };
};

export const resetDuplicate = () => {
  return dispatch => {
    dispatch({ type: Types.PRODUCT_RESET_DUPLICATE_REDUX });
  };
};

export const requestNewCountry = country => {
  if (!country) return;
  return dispatch => {
    const params = { name: country };
    dispatch({ type: Types.GET_NEW_COUNTRY_STATUS });
    return dispatch(
      Request.post(
        { url: `/api/request-payment-country/add-new-request`, data: params },
        false,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.REQUEST_NEW_COUNTRY_SUCCESS,
            });
            toast('Your request has been successfully submitted.');
          }
        },
        error => {
          dispatch({ type: Types.REQUEST_NEW_COUNTRY_FAILED, error: error });
        },
      ),
    );
  };
};

export const getRequestCountryStatus = () => {
  return dispatch => {
    dispatch({ type: Types.GET_NEW_COUNTRY_STATUS });
    return dispatch(
      Request.get(
        {
          url: '/api/request-payment-country/get-by-user',
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.GET_NEW_COUNTRY_STATUS_SUCCESS, payload: { data } });
        },
        error => {
          dispatch({ type: Types.GET_NEW_COUNTRY_STATUS_FAILED, error: error });
        },
      ),
    );
  };
};
