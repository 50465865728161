import styled, { css } from 'styled-components';

export const Details = styled.div`
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    svg {
      margin-right: 13px;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ProfileAvatarContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;

  .sb-avatar {
    margin: 0 auto 8px;
  }

  .name {
    font-size: 16px;
    color: #273d52;
    font-weight: bold;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: inherit;

      :hover {
        text-decoration: none !important;
        color: #6456c4 !important;
      }
    }
  }
`;

export const Section = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  width: 100%;

  :last-child {
    border-bottom: none;
  }

  &.section--base-information {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #3b4859;
    position: relative;
    padding: 20px 15px;

    a,
    a:hover {
      color: inherit;
    }
  }
`;

export const Wrapper = styled.div`
  border-left: 1px solid #e8e8e8;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 0 0 320px;

  ${props =>
    props.isGroup &&
    css`
      overflow: hidden;
    `}

  ${Section} {
    .eve-panel {
      box-shadow: none;
      border: none;
      margin: 0;

      > * {
        border: none;
      }

      .panel-header {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 15px 15px 5px;

        .panel-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #273d52;
        }
      }

      .advanced-note-item {
        border-color: #e8e8e8;

        .advanced-note-content {
          .indicator {
            color: #6456c4;
          }

          .note-content {
            line-height: 18px;
            color: #000000;
          }
        }

        > .advanced-note-date {
          padding-top: 5px;
          line-height: 14px;
          color: #000;
          opacity: 0.6;
        }
      }

      &.note-panel.advanced > .panel-footer {
        max-height: 180px;
      }

      &.update-panel {
        .message {
          line-height: 18px;
          color: #000000;
          width: calc(100% - 39px);
        }

        .time {
          line-height: 18px;
          color: #000;
          opacity: 0.6;
        }

        ul li::before {
          color: #6456c4;
        }
      }
    }
  }
`;
