import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  height: 30px;
  outline: none;
  padding: 0 4px;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  user-select: none;

  .__react_component_tooltip.app-tooltip.rearrange-tooltip {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #333;

    &.place-top:after {
      bottom: -10px;
    }
    &.type-dark.place-top:after {
      border-top-color: #333;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #fff;
    }
  }

  &:hover {
    background-color: #f0f1ff;
    .rearrange-icon g:first-child path {
      stroke: #5158cf;
    }
  }

  ${({ isRearrangeMode }) =>
    isRearrangeMode &&
    css`
      padding: 0 14px;
      border: 1px solid #5158cf;
      background-color: #f0f1ff;
      .close-icon path {
        fill: #5158cf;
      }
      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #202353;
      }
    `}
`;
