import { axiosInstance } from 'configs/request';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as S from './styles';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const showsPeriod = ['quarterly', 'monthly', 'annually'];

function CustomEnterprisePricing(props) {
  const [planData, setPlanData] = useState(null);

  useEffect(() => {
    axiosInstance.get('/api/pricing/get-enterprise-custom-plan').then(async ({ data }) => {
      setPlanData(data.data);
    });
  }, []);

  const billing_period = useMemo(() => {
    const period = planData && planData.billingPeriod;
    return showsPeriod.includes(period) ? `Billed ${_.upperFirst(period)}` : null;
  }, [planData]);

  const closePopup = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('prev')) {
      props.push(params.get('prev'));
    } else {
      props.push('/home/client');
    }
  };

  if (!planData) {
    return (
      <S.Wrapper>
        <S.Header>
          <img alt="remove_icon" src={`${CDN_URL}/images/remove_icon.svg`} onClick={closePopup} />
        </S.Header>
        <S.Container></S.Container>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Header>
        <img alt="remove_icon" src={`${CDN_URL}/images/remove_icon.svg`} onClick={closePopup} />
      </S.Header>
      <S.Container>
        <S.PricingContent>
          <S.Heading>Hello, {props.user.full_name}</S.Heading>
          <S.Description>
            You are currently on a <b>Custom Plan</b>. If you need to make any changes, please contact{' '}
            <S.EmailContact>success@everfit.io</S.EmailContact> to modify your plan.
          </S.Description>
          <S.PriceBox>
            <div>
              <S.PlanNameContainer>
                <S.PlanLabel>EnterPrise</S.PlanLabel>
                <S.PlanName>{`${_.upperFirst(planData.teamSubscription.tier || props.tier)} Plan`}</S.PlanName>
              </S.PlanNameContainer>
              <S.PlanDescription>
                All-in-one coaching platform for studios and gyms looking to offer a range of both hands-off and
                high-touch training.
              </S.PlanDescription>
            </div>
            <S.RightContent>
              <S.PriceContainer>
                <S.PricePrefix>{billing_period}</S.PricePrefix>
                <S.PriceCurrency>$</S.PriceCurrency>
                <S.PriceAmount>{Number(planData.basePrice).toSeperateFormat()}</S.PriceAmount>
                <S.PriceUnit>
                  /{planData.basePeriodMonths !== 1 ? planData.basePeriodMonths : ''}{' '}
                  {pluralize('month', planData.basePeriodMonths, false)}
                </S.PriceUnit>
              </S.PriceContainer>
              <S.ClientContainer>
                <S.ClientAmount>
                  Clients{' '}
                  <b>
                    {planData.activeClientCount} / {planData.totalClient}
                  </b>
                </S.ClientAmount>
                <S.ClientProgress value={planData.activeClientCount} max={planData.totalClient} />
              </S.ClientContainer>
            </S.RightContent>
          </S.PriceBox>
          {planData.addOns.autoflow ? (
            <>
              <S.Divider>
                <S.Dot opacity={0.4} />
                <S.Dot opacity={0.6} />
                <S.Dot opacity={0.8} />
                <S.Dot opacity={1} />
              </S.Divider>
              <S.PriceBox>
                <div>
                  <S.PlanNameContainer>
                    <S.PlanLabel addOn>Add-On</S.PlanLabel>
                    <S.PlanName>Automation</S.PlanName>
                  </S.PlanNameContainer>
                  <S.PlanDescription>Spend less time managing, and more time training clients.</S.PlanDescription>
                </div>
                <S.RightContent>
                  <S.PriceContainer>
                    <S.PricePrefix>Current Usage</S.PricePrefix>
                    <S.PriceCurrency>$</S.PriceCurrency>
                    <S.PriceAmount>{Number(planData.addOns.autoflow.basePrice).toSeperateFormat()}</S.PriceAmount>
                    <S.PriceUnit>
                      /{planData.basePeriodMonths !== 1 ? planData.basePeriodMonths : ''}{' '}
                      {pluralize('month', planData.addOns.autoflow.basePeriodMonths, false)}
                    </S.PriceUnit>
                  </S.PriceContainer>
                  <S.ClientContainer addOn>
                    <S.ClientAmount>
                      Clients{' '}
                      <b>
                        {planData.activeAutoflowClientCount} / {planData.addOns.autoflow.totalClient}
                      </b>
                    </S.ClientAmount>
                    <S.ClientProgress
                      value={planData.activeAutoflowClientCount}
                      max={planData.addOns.autoflow.totalClient}
                    />
                  </S.ClientContainer>
                </S.RightContent>
              </S.PriceBox>
            </>
          ) : null}
          <S.ReturnLink>
            <span onClick={closePopup}>Return to Everfit</span>
          </S.ReturnLink>
        </S.PricingContent>
      </S.Container>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const { user, rootReducer } = state;

  return {
    user,
    tier: rootReducer.pricing.getIn(['selectPackage', 'tier']),
  };
};

export default connect(mapStateToProps, { push })(CustomEnterprisePricing);
