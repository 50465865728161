// Lib
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Assets
import { ReactComponent as AddCircleIcon } from 'assets/icons/MealPlans/add_circle_solid.svg';

// Components
import ViewRecipeDetail from 'components/RecipeDetail/ViewRecipeDetail';

import * as S from './style';

const ContentItem = props => {
  const { displayType, meal, recipe, updateMealPlanByDay, selected, listMealGroup, toggleModal } = props;

  const isAdded = useMemo(() => {
    const mealRecipes = get(meal, 'recipes', []);
    const recipeId = get(recipe, '_id');

    return mealRecipes.some(item => get(item, 'recipe._id') === recipeId);
  }, [meal, recipe]);

  const handleAddRecipe = e => {
    e.stopPropagation();

    if (isAdded) {
      return;
    }

    if (!isEmpty(recipe)) {
      const result = listMealGroup.map(item => {
        if (get(item, '_id') === selected) {
          return { ...item, recipes: [...item.recipes, { recipe: recipe }] };
        }
        return item;
      });

      updateMealPlanByDay && updateMealPlanByDay({ meal_group: result }, true);
    }
  };

  const handleOpenDetailRecipe = () => {
    const recipeId = get(recipe, '_id', '');
    toggleModal && toggleModal(true, <ViewRecipeDetail recipeId={recipeId} onClose={() => toggleModal(false)} />);
  };

  return (
    <S.ContentItemWrapper onClick={handleOpenDetailRecipe} className={displayType}>
      <S.ContentItemThumbnail src={[recipe.cover_image_thumbnail, recipe.cover_image]} />
      <S.ContentItemInfo>
        <S.ContentItemTitle>{recipe.name || ''}</S.ContentItemTitle>
      </S.ContentItemInfo>
      <AddCircleIcon
        onClick={handleAddRecipe}
        data-tip
        data-for={`recipe-${get(recipe, '_id')}`}
        className={classNames('add-circle-icon', { disabled: isAdded || !meal })}
      />
      {meal && (
        <ReactTooltip
          id={`recipe-${get(recipe, '_id')}`}
          className="app-tooltip recipe-item-tooltip"
          effect="solid"
          place="top"
          delayShow={300}
        >
          <p>{`${isAdded ? 'Added' : 'Add'} to ${get(meal, 'name', '')}`}</p>
        </ReactTooltip>
      )}
    </S.ContentItemWrapper>
  );
};

export default ContentItem;
