import React from 'react';
import { get } from 'lodash';

import { ReactComponent as WorkoutCollectionIcon } from 'assets/icons/workout-collection-asset.svg';
import { ReactComponent as WorkoutCollectionGrayIcon } from 'assets//icons/workout-collection-asset-gray.svg';
import UpgradePath from 'shared/UpgradePath';
import { WORKOUT_COLLECTION_STATUS } from 'constants/commonData';

import AssetCard from './AssetCard';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

export default function WorkoutCollectionAsset({ data, onDeleteAsset, isEdit, cloudfrontList }) {
  const background = get(data, 'asset_data.background', null);
  const disabled = {
    isDisabled: data.is_deleted || data.asset_data.status === WORKOUT_COLLECTION_STATUS.DRAFT,
    disabledText: data.is_deleted ? 'deleted' : 'draft',
  };
  return (
    <UpgradePath
      pathName="on_demand_workout"
      fallback={
        <AssetCard
          icon={<WorkoutCollectionGrayIcon />}
          title={data.asset_data.name}
          asset_type={data.asset_type}
          onDeleteAsset={onDeleteAsset}
          isEdit={isEdit}
          disabled={true}
          disabledText="inactive"
        >
          <span className="badge">Collection</span>
        </AssetCard>
      }
    >
      <AssetCard
        icon={
          disabled.isDisabled ? (
            <WorkoutCollectionGrayIcon />
          ) : background ? (
            <img src={convertS3UrlToCloudFrontUrl(background, cloudfrontList, true)} alt="cover" />
          ) : (
            <WorkoutCollectionIcon className="resource" />
          )
        }
        title={data.asset_data.name}
        asset_type={data.asset_type}
        onDeleteAsset={onDeleteAsset}
        isEdit={isEdit}
        disabled={disabled.isDisabled}
        disabledText={disabled.disabledText}
      >
        <span className="badge">Workout Collection</span>
      </AssetCard>
    </UpgradePath>
  );
}
