import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import { Helmet } from 'react-helmet';

import * as S from '../style';
import { useMyContext } from '../context/ProgramTemplatesContext';
import FullscreenModalContent from './FullscreenModalContent';
import { KEY_CODE } from 'constants/commonData';
import { isESCPress } from 'utils/commonFunction';

const FullscreenModal = () => {
  const {
    openFullscreenModal,
    openFilterModal,
    openDetailModal,
    openInstructionModal,
    openTagsModal,
    toggleFullscreenModal,
    showSortPopup,
    showExperiencePopup,
    showDurationPopup,
  } = useMyContext();

  const disabledClose =
    !openFullscreenModal ||
    openFilterModal ||
    openDetailModal ||
    showExperiencePopup ||
    showDurationPopup ||
    openInstructionModal ||
    openTagsModal ||
    showSortPopup;

  const handleKeyDown = event => {
    if (event.keyCode === KEY_CODE.esc && isESCPress(event.key)) {
      toggleFullscreenModal();
    }
  };

  return (
    <S.FullscreenModalWrapper
      className={classNames('fullscreen', {
        open: openFullscreenModal,
      })}
    >
      <RootCloseWrapper event="click" disabled={disabledClose} onRootClose={handleKeyDown}>
        <S.FullscreenModalContainer
          className={classNames('fullscreen', {
            open: openFullscreenModal,
          })}
        >
          {openFullscreenModal && (
            <>
              <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              </Helmet>
              <FullscreenModalContent />
            </>
          )}
        </S.FullscreenModalContainer>
      </RootCloseWrapper>
    </S.FullscreenModalWrapper>
  );
};

export default FullscreenModal;
