import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Modal } from 'semantic-ui-react';
import { pluralize } from 'utils/commonFunction';

import { CDN_URL } from 'constants/commonData';

import './styles.scss';

class ArchiveClientModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onClose = () => this.setState({ open: false });

  onOpen = () => this.setState({ open: true });

  render() {
    const {
      clients = [],
      archiveClients,
      onSucess,
      trigger,
      showError,
      hideError,
      getAllSegments,
      getClientsFullData,
    } = this.props;
    const { open } = this.state;

    const clientsNotSubCoach = clients.filter(item => !item.is_indirect_client);

    if (!clientsNotSubCoach.length) {
      return null;
    }

    return (
      <Modal
        closeOnDimmerClick={false}
        open={open}
        onClose={this.onClose}
        className="archive-clients-modal"
        trigger={trigger}
        onOpen={this.onOpen}
      >
        <Modal.Header>
          <Button onClick={this.onClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="title">
            <Image width={15} src={`${CDN_URL}/images/archive_pink.svg`} />
            <span>Archive {clientsNotSubCoach.length > 1 ? 'these clients' : 'this client'}?</span>
          </div>
        </Modal.Header>
        <Modal.Content>
          <h3>
            {pluralize('client', clientsNotSubCoach.length || 0, true)} will be archived. When a client is archived, all
            assignments will be removed from today onwards.
          </h3>
          <p>
            Also please make sure you let {clientsNotSubCoach.length > 1 ? 'these clients' : 'this client'} know they
            will have limited access to the mobile app.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="confirm-no-button" onClick={this.onClose}>
            Cancel
          </Button>
          <Button
            className="confirm-yes-button archived"
            onClick={() => {
              archiveClients(_.map(clientsNotSubCoach, c => c._id))
                .then(repsonse => {
                  this.setState({ open: false });
                  toast(`You‘ve archived ${pluralize('client', clientsNotSubCoach.length || 0, true)}.`, {
                    className: 'archived-success-message',
                  });

                  if (typeof onSucess === 'function') {
                    onSucess(clientsNotSubCoach);
                  }
                })
                .catch(error => {
                  showError(_.get(error, 'message'), null, null, null, null, () => {
                    this.onClose();
                    getAllSegments();
                    getClientsFullData(true, { page: 1 });
                    hideError(false);
                  });
                });
            }}
          >
            Archive {pluralize('client', clientsNotSubCoach.length || 0)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ArchiveClientModal.propTypes = {
  clients: PropTypes.array,
  archiveClients: PropTypes.func,
};

export default ArchiveClientModal;
