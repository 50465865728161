import React, { useMemo } from 'react';
import { components } from 'react-select';
import Avatar from 'react-avatar';
import get from 'lodash/get';
import styled from 'styled-components';
import SearchGroupAva from 'assets/icons/search-group.svg';

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  position: relative;

  img {
    max-width: unset;
  }

  .photo-client-avatar {
    flex-shrink: 0;
  }
`;

export const ClientFullName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #263d52;
`;

export const GroupLabel = styled.div`
  display: inline;
  font-weight: 700;
`;

function Option(props) {
  const avatar = useMemo(() => {
    return get(props, 'data.avatar');
  }, [props]);
  const color = useMemo(() => {
    return get(props, 'data.color');
  }, [props]);
  const full_name = useMemo(() => {
    return get(props, 'data.label');
  }, [props]);

  return (
    <components.Option {...props}>
      <OptionWrapper>
        <Avatar
          className="photo-client-avatar"
          size="26"
          src={props.data.typeName === 'group' ? SearchGroupAva : avatar}
          color={color}
          name={full_name}
        />
        <ClientFullName>
          {props.data.typeName === 'group' && <GroupLabel>Group: </GroupLabel>}
          {props.children}
        </ClientFullName>
      </OptionWrapper>
    </components.Option>
  );
}

export default Option;
