// Lib
import React from 'react';

// Constants

// Assets
import { ReactComponent as EmptyBanner } from 'assets/icons/Ingredients/illustration.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';

// Style
import * as S from './style';

const EmptyUI = ({ onClick = () => {} }) => {
  return (
    <S.EmptyUIWrapper>
      <EmptyBanner className="empty-banner" />
      <S.AddNewButton onClick={onClick}>
        <PlusIcon className="plus-icon" />
        <span>Create your first ingredient</span>
      </S.AddNewButton>
    </S.EmptyUIWrapper>
  );
};

export default EmptyUI;
