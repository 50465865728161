import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InviteMemberForm from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { addMember, getListUser, getGroupDetail } from 'redux/forum/actions';

const mapStateToProps = state => {
  const { user } = state;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const forumId = ownProps.match.params.forumId || ownProps.match.params.id;

  return {
    dispatch,
    getListUser: () => dispatch(getListUser(forumId)),
    getGroupDetail: () => dispatch(getGroupDetail(forumId)),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    addMember: (forumId, members) => dispatch(addMember(forumId, members)),
    toggleConfirmModal: (isOpen, modal) => dispatch(toggleConfirmModal(isOpen, modal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteMemberForm);
