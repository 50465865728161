import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const RemoveIcon = styled.div`
  width: 22px;
  height: 22px;
  background: transparent url(${CDN_URL}/images/close_grey_purple.svg) no-repeat center center;
  background-size: 100%;
  cursor: pointer;
  display: none;
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TextMessage = styled.div`
  padding: 11px 17px 11px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  background-color: #7470ef;
  border-radius: 16px;
  border-top-right-radius: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  mark {
    background: #fff3c2;
    border-radius: 2px;
    padding: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    display: inline-block;
    margin-top: 1px;
  }
`;

export const FileMessage = styled.div`
  max-width: 300px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  video {
    max-width: 300px;
    max-height: 200px;
    min-width: 60px;
    display: block;
  }

  .image-with-fallback {
    img {
      max-width: 100%;
      max-height: 200px;
      min-width: 60px;
      width: unset;
      height: unset;
      display: block;
    }
  }
`;

export const MessageContainer = styled.div`
  max-width: calc(100% - 328px);
  position: relative;

  &.disabled {
    :hover {
      cursor: not-allowed;
    }
  }
  &.preview {
    max-width: 100%;
    :hover {
      cursor: text;
    }

    ${TextMessage} {
      padding: 6px;
      color: #fff;
      text-align: left;
      font-family: Open Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 16px;
      border-top-right-radius: 0px;
      overflow-wrap: anywhere;

      mark {
        font-size: 11px;
      }
    }

    ${FileMessage} {
      max-width: 100%;

      video,
      .image-with-fallback > img {
        max-width: 100%;
      }
    }
  }
  .media-player__trigger {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 5px;

  :hover {
    ${RemoveIcon} {
      display: block;
    }
  }

  .sb-avatar.messageItem__avatar {
    margin-left: 10px;
    width: 24px !important;
    height: auto !important;

    .sb-avatar__text.messageItem__avatar,
    .sb-avatar__image.messageItem__avatar {
      width: 24px !important;
      height: 24px !important;
    }
  }

  &.preview {
    .sb-avatar.messageItem__avatar {
      margin-left: 6px;
      min-width: 15px;
      width: 16px !important;
      height: 16px !important;

      .sb-avatar__text.messageItem__avatar,
      .sb-avatar__image.messageItem__avatar {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
`;
