// Libs
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

// Actions
import { axiosInstance } from 'configs/request';
import { logout } from 'actions/auth/login';
import { getProfile } from 'actions/profile';
import { toggleConfirmModal } from 'actions/modal';

// Utils
import { useCountdown } from 'hooks/useCountdown';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

// Components
import { CHEAT_TIME_COUNT_DOWN, TIME_COUNT_DOWN } from 'components/VerifyEmail';
import { NAVBAR_HEIGHT_ALERT } from '../PaymentOverdueWhiteLabelAlert';
import VerifyEmailExistingModal from 'shared/VerifyEmailExistingModal';

// Assets
import { ReactComponent as CircleYellowAlert } from 'assets/icons/VerifyEmail/circle-yellow-alert.svg';

// Styles
import * as S from './style';

const VerifyEmailAlert = ({ user, targetDate, logout, getProfile, toggleConfirmModal }) => {
  const { is_verified_email, force_send_verification_email_at, _id, trainerId } = user || {};

  const timeCountDown = process.env.REACT_APP_CHEAT_TIME_COUNT_DOWN_VERIFY ? CHEAT_TIME_COUNT_DOWN : TIME_COUNT_DOWN;

  const { countdown, setCountdown } = useCountdown();

  const [currentTime, setCurrentTime] = useState(moment());
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const headerWrapperRef = useRef(null);

  const endDate = moment(targetDate).add(30, 'days');
  const currentDate = moment();
  const remainingHours = endDate.diff(currentDate, 'hours');
  const remainingDays = Math.ceil(remainingHours / 24);

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    const dateNeedVerify = moment(force_send_verification_email_at).add(30, 'days');
    const isBeforeCurrentDate = moment(dateNeedVerify).isBefore(currentTime);

    if (isBeforeCurrentDate && !is_verified_email) {
      setIsShow(true);
      clearInterval(intervalRef.current);
    }
  }, [currentTime, targetDate]);

  useEffect(() => {
    if (trainerId === _id) {
      getProfile({ isUpdated: true });
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      const container = headerWrapperRef.current;
      const appMainContent = document.querySelector('.app-content-custom.verification-email');

      if (!container || !appMainContent || isShow) {
        const appMainContentCustom = document.querySelector('.app-content-custom');
        appMainContentCustom.style.paddingTop = 0;
        return;
      }

      const { height } = container.getBoundingClientRect();

      if (NAVBAR_HEIGHT_ALERT.includes(Math.ceil(height))) {
        appMainContent.style.paddingTop = `${height}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isShow]);

  const handleResend = isClickResend => {
    resendEmailVerification(isClickResend);
  };

  const resendEmailVerification = (isClickResend = false) => {
    axiosInstance
      .post('/api/authentication/trainer/send-email-verification', { is_click_resend: isClickResend })
      .then(response => {
        const { success, cooldown_resend: coolDownTime } = get(response, 'data.data', {});

        if (success || coolDownTime !== 0) {
          if (success) {
            toast('A verification email has been sent to you. Follow instructions to verify your account.', {
              className: 'wider higher',
            });
          }
          setCountdown(Math.ceil((coolDownTime || timeCountDown) / 1000));
        }
      })
      .catch(error => {});
  };

  if (is_verified_email && !force_send_verification_email_at) {
    return null;
  }

  if (isShow && !is_verified_email) {
    return (
      <VerifyEmailExistingModal
        isLogged={!isEmpty(user)}
        firstName={(user || {}).first_name}
        onClose={() => {
          if (loading) return;
          setLoading(true);
          getProfile({ isUpdated: true })
            .then(response => {
              const { user } = get(response, 'data');
              const { is_verified_email } = user || {};
              if (is_verified_email) {
                setIsShow(false);
                toast('Your email has been verified.');
              } else {
                toggleConfirmModal(true, <ConfirmVerifyModal onConfirm={() => logout()} />);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      />
    );
  }

  return (
    <S.Wrapper ref={headerWrapperRef}>
      <CircleYellowAlert className="custom-icon" />
      <S.ContentWrapper>
        Please verify your email address for security purposes, some features will be restricted after{' '}
        <span className="content-bold">{pluralize('day', remainingDays <= 1 ? 1 : remainingDays, true)}.</span> Click{' '}
        <S.ButtonRequest onClick={() => handleResend(true)} disabled={!!countdown}>
          here
          {!!countdown && <> ({countdown}s)</>}
        </S.ButtonRequest>{' '}
        to resend a verification email.
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { user } = state;

  return {
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: bindActionCreators(logout, dispatch),
    getProfile: bindActionCreators(getProfile, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailAlert);

const ConfirmVerifyModal = ({ onConfirm }) => {
  return (
    <S.CustomConfirmModal
      headerIcon={`${CDN_URL}/images/active_wating_client_full_confirm.svg`}
      title={`Email Verification Required`}
      content={`Please verify your email address to maintain access to the platform. Closing this pop-up without completing verification will log you out. Would you like to continue?`}
      onConfirm={onConfirm}
      confirmButtonTitle="Continue"
      noBorder
      className="verification-email-confirm-modal"
    />
  );
};
