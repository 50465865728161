import React, { memo } from 'react';
import classNames from 'classnames';
import FormItemInput from 'shared/FormItemInput';
import BannerImage from './BannerImage';
import * as S from './styles';
import DescriptionFormItem from './DescriptionFormItem';
import NameFormItem from './NameFormItem';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const CustomBannerImage = memo(({ onRemove, onUploadSuccess, onStartUpload, onEndUpload, src, className }) => {
  return (
    <BannerImage
      key="banner_image"
      onRemove={onRemove}
      onUploadSuccess={onUploadSuccess}
      onStartUpload={onStartUpload}
      onEndUpload={onEndUpload}
      src={src}
      uploadKey="media"
      className={className}
    />
  );
});

class PackageForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const originPackage = this.props.originPackage.toJS();
    this.state = {
      ...originPackage,
      cover_image: convertS3UrlToCloudFrontUrl(originPackage.cover_image, this.props.cloudfrontList, true),
    };
  }

  onRemove = () => {
    this.setState({ cover_image: null });
    this.props.onChangeForm({ cover_image: null });
  };

  onUploadSuccess = ({ src }) => {
    this.setState({ cover_image: src, isUploading: false });
    this.props.onChangeForm({ cover_image: src });
  };

  onStartUpload = () => {
    this.setState({ isUploading: true });
  };

  onEndUpload = () => {
    this.setState({ isUploading: false });
  };

  handleChangeName = name => {
    this.setState({ name });
    this.props.onChangeForm({ name });
  };

  handleChangeHeadline = e => {
    this.setState({ headline: e.target.value });
    this.props.onChangeForm({ headline: e.target.value });
  };

  handleChangeDescription = description => {
    this.setState({ description });
    this.props.onChangeForm({ description });
  };

  render() {
    const { cover_image, name, headline, description } = this.state;
    const { isSubmitted, isSave } = this.props;

    return (
      <S.Wrapper>
        <CustomBannerImage
          onRemove={this.onRemove}
          onUploadSuccess={this.onUploadSuccess}
          onStartUpload={this.onStartUpload}
          onEndUpload={this.onEndUpload}
          src={cover_image}
          className={classNames({ error: !!isSubmitted && !cover_image && !isSave })}
        />
        <S.FormInfo>
          <NameFormItem name={name} onChange={this.handleChangeName} />
          <FormItemInput
            label="Headline"
            placeholder="Package headline goes here"
            maxLength={255}
            inputProps={{ maxLength: 255 }}
            isSubmitted={isSubmitted}
            value={headline}
            onChange={this.handleChangeHeadline}
          />
          <DescriptionFormItem
            isSubmitted={isSubmitted && !isSave}
            value={description}
            onChange={this.handleChangeDescription}
          />
        </S.FormInfo>
      </S.Wrapper>
    );
  }
}

export default PackageForm;
