import React from 'react';
import _ from 'lodash';
import { Dropdown, Icon } from 'semantic-ui-react';

export default ({ type, value, onChange, options, name, className, noColon }) => {
  const valueObj = _.find(options, item => item.value === value) || options[0];
  return (
    <Dropdown
      icon={null}
      className="filter__dropdown"
      key={type}
      trigger={
        <div className={`filter__dropdown__title${className ? ' ' + className : ''}`}>
          <span>{noColon ? name : `${name}:`}&nbsp;</span>
          <span className="selected">{valueObj.label}</span>
          <Icon className="chevron down" />
        </div>
      }
    >
      <Dropdown.Menu>
        {options.map(item => {
          let itemClass = value === item.value ? 'active' : '';
          return (
            <Dropdown.Item
              onClick={() => {
                if (item.value !== valueObj.value) {
                  onChange(type, item.value);
                }
              }}
              className={itemClass}
            >
              <div>{item.label}</div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
