import styled, { css } from 'styled-components';
import DefaultBackground from 'assets/images/form-welcome-screen-default.png';
import RemoveOptionGray from 'assets/icons/form-builder-option-remove-gray.svg';
import RemoveOptionDark from 'assets/icons/form-builder-option-remove-dark.svg';
import ImageAddDragging from 'assets/icons/image-add-dragging.svg';
import ImageAddGray from 'assets/icons/image-add-gray.svg';
import { Button } from 'shared/FormControl';

export const Wrapper = styled.div`
  background: #f7f7f7;
  text-align: center;
`;

export const LabelButton = styled(Button)`
  background: ${props => props.buttonBackground} !important;
`;

export const MainPanelWrapper = styled.div`
  background-color: #f6f6f6;
  padding: 16px 24px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px !important;
  }
  ${props =>
    props.isPublished &&
    !props.allowEdit &&
    css`
      .text-editable__container--active {
        ::after {
          background-color: transparent !important;
        }
      }
      .evf-checkbox-circle.option-radio,
      .single-option-container .text-editable__container,
      .multiple-option-container .text-editable__container {
        cursor: default !important;
      }
      .single-option-container,
      .multiple-option-container {
        cursor: default !important;
        .remove-option-container.disabled {
          cursor: default !important;
        }
      }
    `}
`;

export const QuestionWrapper = styled.div`
  ${'' /* TODO: should remove margin when implement scroll snapping */}
  margin: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: calc(100% - 20px);
  position: relative;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${props =>
    props.isSafari &&
    css`
      height: calc(100vh - 175px);
    `}
  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const WelcomeScreenContainer = styled.div`
  width: 375px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${props => props.background || DefaultBackground});
  background-size: cover;
  background-position: center;
  .welcome-screen-content {
    max-width: 335px;
    max-height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .welcome-screen-title {
      width: 100%;
      margin-bottom: 8px;
      cursor: text;
      .text-editable__container {
        padding: 0px;
        background-color: unset;
        :hover {
          background-color: unset;
        }
        &--active {
          border-color: transparent;
        }
        .text-editable__input {
          color: #ffffff;
          font-weight: 600;
          font-size: 28px;
          letter-spacing: -0.02em;
          ${'' /* placeholder styles */}
          ::before {
            color: #ffffff;
            opacity: 0.6;
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
          }
        }
      }
    }
    .welcome-screen-description {
      width: 100%;
      overflow-y: scroll;
      margin-bottom: 24px;
      cursor: text;
      .text-editable__container {
        padding: 0px;
        background-color: unset;
        :hover {
          background-color: unset;
        }
        &--active {
          border-color: transparent;
        }
        .text-editable__input {
          color: #ffffff;
          font-weight: 400;
          font-size: 15px;
          ${'' /* placeholder styles */}
          ::before {
            color: #ffffff;
            opacity: 0.8;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
          }
        }
      }
    }
  }
  .welcome-screen-btn {
    cursor: default;
    padding: 18px 52.5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: #7470ef;
    border-radius: 100px;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  height: unset !important;
  .remove-option-container {
    transition: visibility 0.1s linear, opacity 0.1s linear;
    ${props => !props.isOptionFocused && 'visibility: hidden; opacity: 0;'}
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .text-editable__wrapper.option-title {
    min-width: 110px;
    max-width: 508px;
    width: auto;
    margin: 0px;
    .text-editable__container {
      padding: 4px 4px;
      transition: background-color 0.1s linear;
      .text-editable__input {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #202353;
      }
    }
  }
`;

export const OptionRemove = styled.div`
  margin-left: 10px;
  background-image: url(${RemoveOptionGray});
  background-size: cover;
  width: 20px;
  height: 20px;
  :hover {
    background-image: url(${RemoveOptionDark});
  }
`;

export const SingleChoiceContainer = styled.div`
  display: flex;
  > div {
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: #202353;
    margin-bottom: 4px;
  }
  .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: rgba(32, 35, 83, 0.6);
    margin-bottom: 22px;
  }

  .single-option-container {
    :not(:last-child) {
      margin-bottom: 7px;
    }
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #202353;
    .option-radio {
      width: 100%;
      > div:first-child {
        cursor: default;
        :hover {
          border: 1px solid #dadfea;
        }
      }
      :hover {
        .remove-option-container {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .option-title.other-option .text-editable__container--active {
      ::after {
        background-color: transparent;
      }
    }
    &.alignTop {
      .option-radio {
        align-items: flex-start;
        > div:first-child {
          transform: translateY(6px);
        }
      }
    }
    .text-editable__container {
      cursor: text;
      &--active {
        border: 1px solid transparent;
        position: relative;
        ::after {
          content: '';
          height: 1px;
          width: 100%;
          background-color: #5e59ff;
          position: absolute;
          top: 100%;
          left: 4px;
        }
        :hover {
          background-color: #ffffff !important;
        }
      }
      &--invalid {
        background-color: initial;
        :hover {
          background-color: #f1f1f3;
        }
        border-color: transparent;
      }
    }
  }
  .add-option-btn {
    width: fit-content;
    margin-top: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #3c39a3;
    :hover {
      color: #5e59ff;
    }
    cursor: pointer;
  }
  .option-radio {
    > div:first-child {
      flex: 0 0 20px;
      height: 20px;
      width: 20px;
    }
    > span {
      transform: translate(4px, -1px);
    }
  }
`;

export const MultipleChoiceContainer = styled.div`
  display: flex;
  > div {
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: #202353;
    margin-bottom: 4px;
  }
  .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: rgba(32, 35, 83, 0.6);
    margin-bottom: 22px;
  }
  .option-title.other-option .text-editable__container--active {
    ::after {
      background-color: transparent;
    }
  }
  .multiple-option-container {
    :not(:last-child) {
      margin-bottom: 3px;
    }
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #202353;
    &.alignTop {
      align-items: flex-start;
      .checkbox-icon {
        top: 7px;
      }
    }
    :hover {
      .remove-option-container {
        visibility: visible;
        opacity: 1;
      }
    }
    .checkbox-icon {
      top: -8px;
      left: -6px;
      border-radius: 4px;
      cursor: default;
    }
    .option-title.other-option .text-editable__container--active {
      ::after {
        background-color: transparent;
      }
    }
    .multiple-choice-option-name {
      margin-left: 8px;
    }
    .text-editable__container {
      cursor: text;
      &--active {
        border: 1px solid transparent;
        position: relative;
        ::after {
          content: '';
          height: 1px;
          width: 100%;
          background-color: #5e59ff;
          position: absolute;
          top: 100%;
          left: 4px;
        }
        :hover {
          background-color: #ffffff !important;
        }
      }
      &--invalid {
        background-color: initial;
        :hover {
          background-color: #f1f1f3;
        }
        border-color: transparent;
      }
    }
  }
  .add-option-btn {
    width: fit-content;
    margin-top: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #3c39a3;
    :hover {
      color: #5e59ff;
    }
    cursor: pointer;
  }
  .option-radio {
    > div:first-child {
      flex: 0 0 20px;
      height: 20px;
      width: 20px;
    }
    > span {
      transform: translate(4px, -1px);
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 56px 10px;
  @media only screen and (min-width: 1400px) {
    padding: 56px 100px;
  }
  .title {
    margin: 6px 0;
    transform: translateX(-4px);
    .text-editable__container {
      padding: 0;
      padding-left: 4px;
      border: none;
      &--active {
        position: relative;
        ::after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #5e59ff;
          position: absolute;
          top: 100%;
          transform: translateY(4px);
        }
      }
    }
    .text-editable__input {
      font-weight: 600;
      font-size: 20px;
      color: #202353;
      ${props =>
        props.isRequire &&
        css`
          position: relative;
          :after {
            content: '*';
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #ee2525;
            margin-left: 4px;
            position: absolute;
          }
        `}
    }
  }

  .description {
    transform: translateX(-4px);
    .text-editable__container {
      padding: 0;
      padding-left: 4px;
      border: none;
      border-bottom: 1px solid transparent;
      margin-top: 2px;
      transition: background-color 0.1s linear;
      &--active {
        position: relative;
        ::after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #5e59ff;
          position: absolute;
          top: 100%;
          transform: translateY(6px);
        }
      }
      .text-editable__input {
        cursor: text;
        font-weight: 400;
        font-size: 15px;
        color: rgba(32, 35, 83, 0.6);

        ::before {
          opacity: 0.6;
        }
      }
    }
  }
  .option-container {
    margin: 16px 0;
    display: grid;
    grid-template-columns: repeat(${props => props.range || 5}, auto);
  }
  .option {
    background: #fff;
    border: 1px solid #dadfea;
    border-radius: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000;
    height: 48px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    user-select: none;
    :last-child {
      margin: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  user-select: none;
  svg {
    margin-right: 4px;
  }
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3683f7;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #202353;
  }
`;

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(32, 35, 83, 0.5);
  margin-top: 22px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(32, 35, 83, 0.5);
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 20px;
  position: absolute;
  top: 32px;
  right: 32px;
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    user-select: none;
    :hover {
      path {
        stroke: #5e59ff;
      }
    }
  }
  .__react_component_tooltip.question-tooltip {
    background: #262627;
    border-radius: 4px;
    padding: 6px 8px;
  }
`;

export const SignatureContainer = styled.div`
  height: 160px;
  background: #fff;
  border: 1px dashed #dedede;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: rgba(32, 35, 83, 0.5);
  margin-top: 16px;
  user-select: none;
`;

export const ImageTextIcon = styled.div`
  margin-bottom: 19px;
  width: 25px;
  height: 26px;
  background: transparent url(${ImageAddGray}) no-repeat center;
  background-size: contain;
`;

export const ImageTextPlaceholder = styled.div`
  position: relative;
  margin-top: 16px;
  width: 100%;
  height: 355px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 1px dashed #d1d1d1;
  background: #fbfbfb;
  cursor: pointer;
  ${props =>
    props.isDragging &&
    css`
      border: 1px dashed #5158cf;
      background: #fff;
      ${ImageTextIcon} {
        background-image: url(${ImageAddDragging});
      }
    `}
  &.not-allowed {
    cursor: default;
  }
`;

export const ImageTextContent = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #6a778a;
  text-align: center;
  .custom-image-text {
    color: #5158cf;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ImageTextPreview = styled.div`
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-top: 16px;
  position: relative;
  img {
    height: auto;
    width: auto;
    object-fit: contain;
    aspect-ratio: auto;
  }
  .media-player__trigger {
    width: 100%;
    height: 100%;
  }
  ${props =>
    props.isScaleWidth &&
    css`
      img {
        width: 572px;
        height: unset;
        aspect-ratio: auto;
      }
    `}
  ${props =>
    props.isScaleHeight &&
    css`
      img {
        height: 355px;
        width: unset;
        aspect-ratio: auto;
      }
    `}
  ${props =>
    props.isResponse &&
    css`
      max-height: 200px;
      max-width: 200px;
      width: unset;
      height: unset;
      .image-with-fallback img {
        max-height: 200px;
        max-width: 200px;
        object-fit: contain;
        aspect-ratio: auto;
      }
    `}
`;

export const ImageTextItem = styled.div`
  position: relative;
  height: auto;
  display: flex;
  .evf-file-upload {
    border-radius: unset;
    img {
      width: auto;
      height: auto;
      object-fit: contain;
    }
  }
  ${props =>
    props.isScaleWidth &&
    css`
      img {
        width: 572px;
        height: unset;
        aspect-ratio: auto;
      }
    `}
  ${props =>
    props.isScaleHeight &&
    css`
      img {
        height: 355px;
        width: unset;
        aspect-ratio: auto;
      }
    `}
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
    path {
      fill: #a6acc6;
    }
    :hover {
      path {
        fill: #5158cf;
      }
    }
  }
  .tooltip-image-text {
    width: 270px;
    height: 70px;
    padding: 15px !important;
    :after {
      bottom: -7px !important;
    }
  }
`;

export const TooltipContent = styled.div`
  color: #fff;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

export const ReadOnlyWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: baseline;
  margin-top: -8px;
  .hyphen {
    color: rgba(32, 35, 83, 0.5);
  }
  .dynamic-unit {
    width: 94px;
    height: 22px;
    text-align: end;
  }
`;

export const MetricAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
`;

export const MainPanelTriggerIconWrapper = styled.div`
  position: absolute;
  top: 31px;
  left: 32px;
`;
