import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const SectionContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 6px;
  padding: 5px 7px;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), inset 3px 0px 0px #cdd1db;
  border-radius: 3px;
  height: 70px;
  margin-bottom: 4px;

  :last-child {
    margin-bottom: 0;
  }

  .section-overview {
    width: 100%;
    height: 100%;

    .section-overview__header {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
    }

    .section-overview__footer {
      font-size: 10px;
      line-height: 14px;
      color: #000000;
    }
  }

  &.workout__section--hidden {
    border: 1px solid #dfdfdf;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    height: auto;
    padding: 0;

    .exercise-preview {
      min-height: 46px;
      padding: 6px 7px;

      .exercise-cell-set {
        flex: 1 auto;
        overflow: hidden;
        align-items: flex-start;
        padding-left: 10px;

        .exercise-preview__name {
          img {
            margin-left: 3px;
          }
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #000000;
          margin-bottom: 2px;
          width: 100%;
        }

        .exercise-preview__description {
          width: 100%;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 14px;
          color: #95a6b7;
        }
      }
    }
  }
`;

export const WorkoutHeader = styled.div`
  padding: 5px 2px 4px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ui.checkbox {
    label:before {
      width: 14px;
      height: 14px;
      border: 1px solid #d4d8da;
      border-radius: 2px;
    }
    label:hover::before {
      border-color: #5158cf !important;
    }
    input:focus ~ label:before {
      border-color: #5158cf !important;
    }
    input:checked ~ label:before {
      background: #5158cf !important;
    }
    input:checked ~ label:after {
      content: ' ';
      background-image: url(${CDN_URL}/images/checked_white.svg);
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      background-position: center;
      background-size: 70%;
    }
  }

  .workout__title {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #5a57cb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    flex: 1 1;

    :hover {
      cursor: pointer;
    }
  }

  .evf-dropdown {
    .evf-dropdown__option {
      white-space: nowrap;
      img {
        margin-right: 12px;
      }
    }

    .evf-dropdown__trigger {
      .dots {
        ::before {
          left: -4px;
        }

        ::after {
          left: 4px;
        }
      }
    }
  }
`;

export const WorkoutSectionsContainer = styled.div`
  padding: 0 2px;
`;

export const ViewMoreItem = styled.div`
  color: #676767;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  :hover {
    color: #5a57cb;
    cursor: pointer;
  }
`;

export const WorkoutFooter = styled.div`
  padding: 5px 3px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .calendar-plus-icon {
    margin-bottom: 0;
  }
`;

export const WorkoutContainer = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-sizing: border-box;
  border-radius: 6px;
  ${props =>
    props.checked &&
    css`
      border-color: #5a57cb;
    `}

  .workout__header--checkbox {
    visibility: ${props => (props.hasSelectedWorkout ? 'visible' : 'hidden')};
    margin-right: 8px;
    padding-left: 12px;
    .checkbox-icon {
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background-size: 70%;
      background-position: 2px 2px;
    }
  }

  &:has(.checked) {
    .workout__header--checkbox {
      visibility: visible;
    }
  }

  :hover {
    border-color: #5a57cb;
    .workout__header--checkbox {
      visibility: visible;
    }
  }

  .checkbox-icon:hover {
    border-color: #5a57cb;
  }
`;

export const WorkoutWrapper = styled.div`
  margin-bottom: 5px;

  :last-child {
    margin-bottom: 0;
  }

  &.disableDraggale {
    cursor: not-allowed;

    .workout__section {
      cursor: not-allowed;
    }
  }
`;
