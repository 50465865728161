import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { convertS3UrlToCloudFrontUrl, getStatusCodeUrl, getUserShortName } from 'utils/commonFunction';

const AvatarWrapper = props => {
  const { user, cloudfrontList, classNames, size = 26, styles, imageLocal = '' } = props;
  const avatarSrc = (user || {}).avatar;

  const avtUrlS3 = imageLocal || convertS3UrlToCloudFrontUrl(avatarSrc, cloudfrontList, true);
  const avtOrigin = avtUrlS3 ? avtUrlS3.replace('thumbnails-converted', 'images') : '';
  const [src, setSrc] = useState(avtOrigin);

  const checkStatus = async avtUrl => {
    try {
      const status = await getStatusCodeUrl(avtUrl);
      if (status === 200) {
        setSrc(avtUrl);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // check valid or not
    // if not valid
    // setSrc(avtUrlOrigin)
    if (avtUrlS3) {
      checkStatus(avtUrlS3);
    }
  }, [avtUrlS3]);

  return (
    <Avatar
      src={src}
      size={size}
      style={styles}
      color={user.color}
      className={classNames}
      name={getUserShortName(user)}
    />
  );
};

const mapStateToProps = state => {
  const { cloudfrontList = [] } = state;
  return {
    cloudfrontList,
  };
};
export default connect(mapStateToProps, null)(AvatarWrapper);
