import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';

import Component from './component';
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import {
  cancelChanges,
  editOnboardingFlow,
  getFormTemplates,
  publishOnboardingFlow,
  saveChanges,
  saveOnboardingFlowDraft,
  unpublishOnboardingFlow,
  getOnboardingFlowDetail,
  handleSelectedForm,
  getFormTemplatesDetail,
  removeForm,
  getDefaultMetrics,
  toggleStatus,
  resetFormTemplates,
  resetWorkingData,
  resetWorkingSetting,
} from 'redux/onboarding-flow/actions';
import { toggleSideBar } from 'actions/sidebar';
import { hideUpgradePathOnboarding } from 'redux/general-settings/actions';

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      onboardingFlow: {
        onboarding_form,
        onboarding_message,
        isEditMode,
        isSaveDraft,
        isSubmitting,
        status,
        workingData,
        formTemplates,
        questionsTemplates,
        activeAssets,
      },
      generalSettings,
      permission,
      pricing,
    },
    sideBarVisible,
    loadingBar,
    isConfirmModalOpen,
    cloudfrontList,
  } = state;
  const enabledAddons = pricing.getIn(['teamData']).toJS();
  const hasPermissionUseTrigger = get(permission, 'autoflow') || get(enabledAddons, 'automation');

  return {
    onboardingForm: onboarding_form,
    onboardingMessage: onboarding_message,
    isEditMode,
    isSaveDraft,
    isSubmitting,
    status,
    user,
    permission,
    workingData,
    formTemplates,
    questionsTemplates,
    activeAssets,
    sideBarVisible,
    isLoading: loadingBar.default,
    isConfirmModalOpen,
    cloudfrontList,
    isHideUpgradePath: (generalSettings || {}).is_show_upgrade_path_onboarding_flow,
    teamData: pricing.get('teamData').toJS(),
    hasPermissionUseTrigger,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getFormTemplates: bindActionCreators(getFormTemplates, dispatch),
  saveOnboardingFlowDraft: bindActionCreators(saveOnboardingFlowDraft, dispatch),
  publishOnboardingFlow: bindActionCreators(publishOnboardingFlow, dispatch),
  unpublishOnboardingFlow: bindActionCreators(unpublishOnboardingFlow, dispatch),
  editOnboardingFlow: bindActionCreators(editOnboardingFlow, dispatch),
  cancelChanges: bindActionCreators(cancelChanges, dispatch),
  saveChanges: bindActionCreators(saveChanges, dispatch),
  getOnboardingFlowDetail: bindActionCreators(getOnboardingFlowDetail, dispatch),
  handleSelectedForm: bindActionCreators(handleSelectedForm, dispatch),
  getFormTemplatesDetail: bindActionCreators(getFormTemplatesDetail, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  push: bindActionCreators(push, dispatch),
  removeForm: bindActionCreators(removeForm, dispatch),
  getDefaultMetrics: bindActionCreators(getDefaultMetrics, dispatch),
  toggleStatus: bindActionCreators(toggleStatus, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  resetFormTemplates: bindActionCreators(resetFormTemplates, dispatch),
  hideUpgradePathOnboarding: bindActionCreators(hideUpgradePathOnboarding, dispatch),
  resetWorkingData: bindActionCreators(resetWorkingData, dispatch),
  resetWorkingSetting: bindActionCreators(resetWorkingSetting, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
