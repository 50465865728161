import React from 'react';
import { connect } from 'react-redux';
import StepTrackingWeeklyChart from '../StepTrackingWeeklyChart';
import StepTrackingMonthlyChart from '../StepTrackingMonthlyChart';
import { STEP_VIEW_MODES } from 'constants/commonData';
import LoadingIndicator from 'shared/LoadingIndicator';

import * as S from './style';

function StepTrackingChart({ chartData, currentMode, dailyGoal, fromDate, toDate, loadingChartData }) {
  if (loadingChartData) {
    return (
      <S.LoadingWrapper>
        <LoadingIndicator title="Loading chart..." className="loading-chart-indicator" />
      </S.LoadingWrapper>
    );
  }
  if (currentMode === STEP_VIEW_MODES.month.value) {
    return <StepTrackingMonthlyChart chartData={chartData} dailyGoal={dailyGoal} fromDate={fromDate} toDate={toDate} />;
  }

  return (
    <StepTrackingWeeklyChart
      chartData={chartData}
      dailyGoal={dailyGoal}
      fromDate={fromDate}
      toDate={toDate}
      currentMode={currentMode}
    />
  );
}

const mapState = state => {
  const {
    bodyMetric,
    rootReducer: {
      clientStep: { currentMode, chartData, dailyGoal, fromDate, toDate, loadingChartData },
    },
  } = state;
  return {
    currentMode,
    chartData,
    dailyGoal,
    fromDate,
    toDate,
    manualStepData: bodyMetric.chartData,
    loadingChartData,
  };
};

export default connect(mapState, null)(StepTrackingChart);
