import React from 'react';
import styled from 'styled-components';
import { getPeriodName } from 'utils/commonFunction';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;

  .proration-cost--label {
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 4px;
  }

  .proration-cost--desctiption {
    font-size: 11px;
    line-height: 140%;
    color: #202353;
    opacity: 0.5;
  }

  .proration-cost__price {
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-align: right;
    color: #202353;
  }
`;

function ProrationCost(props) {
  const { label, description, cost, minus } = props;
  if (cost <= 0) {
    return null;
  }

  return (
    <Wrapper className="proration-cost">
      <div>
        <span className="proration-cost--label">{label}</span>
        <div className="proration-cost--desctiption">{description}</div>
      </div>
      <div>
        <span className="proration-cost__price">
          {minus ? '-' : ''}&nbsp;{Number(cost).toUSDFormat(false)}
        </span>
      </div>
    </Wrapper>
  );
}

export default ProrationCost;
