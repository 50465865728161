import styled from 'styled-components';

export const ActionOptionIcon = styled.div`
  flex: 0 0 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ActionOptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 7px 15px;
`;

export const GroupActions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .button--group-actions {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 100%;
    padding: 7px 15px;
    min-height: 30px;
    margin-right: 5px;

    :hover,
    &.open {
      background: #FAFBFC;
    }

    img {
      margin: 0;
    }

    span {
      margin: 0 13px 0 10px;
    }
  }

  .dropdown--client-group-actions {
    .evf-dropdown__option {
      padding: 0;
    }

    .evf-dropdown__menu {
      width: 200px;
      padding: 10px 0;
    }
  }
`;

export const ClientTableWrapper = styled.div`
  ::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
  }
  ::-webkit-scrollbar-track {
    margin-left: ${props => `${props.nameWidth || 0}px`}
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0) !important;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.25) !important;
    }
  }
`
