import React, { useState } from 'react';
import { HIDDEN_SECTION, SECTION_FORMAT, SECTION_FORMAT_KEY } from 'constants/commonData';
import Exercise from './Exercise';
import classnames from 'classnames';
import * as S from '../style';
import { ReactComponent as LinkIcon } from 'assets/icons/link_set.svg';
import FreestyleAttachment from '../../../components/SectionDetail/components/FreestyleAttachment';
import ListExercises from 'shared/ListExercises';
import { ReactComponent as CollapseUp } from 'assets/icons/collapse_up.svg';
import { get } from 'lodash';

function handleRenderAttachments(section) {
  if (section.attachments) {
    if (section.attachments.length > 0)
      return (
        <div style={{ marginTop: '15px', padding: '0px 20px' }}>
          <FreestyleAttachment originalAttachments={section.attachments} viewOnly noBottomMargin />
        </div>
      );
  }
}

function handleRenderExerciseReferences(section) {
  const [openExercise, SetOpenExercise] = useState(false);
  const exerciseReferences = section.exercise_references || [];
  const originalExerciseReferences = section.exercise_references || [];

  if (section.exercise_references) {
    if (section.exercise_references.length > 0) {
      return (
        <>
          <div
            className="exercise-list-header-log-workout"
            style={{ marginTop: '0px' }}
            onClick={() => SetOpenExercise(!openExercise)}
          >
            <p className="exercise-list-label">
              <span className="highlight">List of Exercises</span> (for reference)
            </p>
            <div
              className={classnames({
                collapse__list__exercise: !openExercise,
                expand__list__exercise: openExercise,
              })}
            >
              <CollapseUp />
            </div>
          </div>
          {openExercise && (
            <ListExercises
              hideSearch={true}
              disabledEdit={true}
              exerciseReferences={exerciseReferences}
              originalExerciseReferences={originalExerciseReferences}
            />
          )}
        </>
      );
    }
  }
}

export default ({ section, nextSectionType }) => {
  const linkable = section.type === HIDDEN_SECTION && nextSectionType && nextSectionType === HIDDEN_SECTION;
  const isExercise = section.type === HIDDEN_SECTION;
  const numOfRefEx = get(section, 'exercise_references', []);
  const sectionNote = get(section, 'note', '');

  return (
    <React.Fragment key={section._id}>
      <S.Section key={section._id} isExercise={isExercise} linkable={linkable}>
        <S.SectionHeader>
          <S.SectionHeaderLeft>
            <div className="name">{section.title}</div>&nbsp;&nbsp;
            {!isExercise && <span className="sectionFormat">-&nbsp;&nbsp;{SECTION_FORMAT[section.format]}</span>}
          </S.SectionHeaderLeft>
        </S.SectionHeader>
        <S.SectionContent>
          {handleRenderAttachments(section)}
          {sectionNote.length > 0 && (
            <S.Description hasPadding style={{ wordBreak: 'break-word' }}>
              {section.note}
            </S.Description>
          )}
          <S.SectionExercises shouldHide={section.format === SECTION_FORMAT_KEY.FREESTYLE && numOfRefEx.length === 0}>
            {section.exercises.map((exercise, exerciseIndex) => {
              return (
                <Exercise
                  key={exercise._id}
                  exerise={exercise}
                  sectionFormat={section.format}
                  exerciseIndex={exerciseIndex}
                />
              );
            })}
            {handleRenderExerciseReferences(section)}
          </S.SectionExercises>
        </S.SectionContent>
      </S.Section>
      {linkable ? (
        <S.LinkSet>
          <LinkIcon />
        </S.LinkSet>
      ) : null}
    </React.Fragment>
  );
};
