import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

import Button from 'shared/FormControl/Button';

export const GiphyContainer = styled.div`
  width: 600px;
  border-radius: 12px !important;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.2);
`;

export const ScrollGrid = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: overlay;
  height: 337px;
  max-width: calc(100% - 4px * 2);
  margin: 0 auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px !important;
    height: unset !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(196, 196, 196, 0.5) !important;
    border-radius: 25.47px !important;
  }

  .loader {
    margin-bottom: 30px;

    * {
      display: none !important;
    }
  }

  .giphy-not-found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #7b7e91;
  }

  .giphy-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;

export const ContentGrid = styled.div`
  max-width: calc(100% - 12px * 2);
  margin: 0 auto;
`;

export const SearchBarContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 16px;
`;

export const SearchControl = styled.div`
  display: grid;
  grid-template-columns: 16px auto 20px;
  box-sizing: border-box;
  height: 52px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  width: 100%;
  align-items: center;
  gap: 9px 12px;
  padding: 0 16px;

  ${props =>
    props.isFocus &&
    css`
      border-color: #5e59ff !important;
    `}

  > input {
    box-sizing: border-box;
    outline: 0;
    border: none;
    width: 100%;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    &::placeholder {
      color: rgba(123, 126, 145, 0.6);
    }
  }

  .giphy-clear-icon {
    cursor: pointer;
  }
`;

export const InboxTrigger = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  width: 26px;
  height: 26px;
  background: #f5f5f6;
  border-radius: 4px;
  padding: 4px;

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
          svg:hover {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            background: #e6e6fd;
            path {
              fill: #7370e7 !important;
            }
          }
        `}

  ${props =>
    props.open &&
    css`
      background: #e6e6fd;
      path {
        fill: #7370e7 !important;
      }
    `}
`;

export const ForumPostTrigger = styled(Button)`
  display: flex;
  height: 38px;
  width: 38px;
  min-width: unset !important;
  left: 0px;
  top: 0px;
  padding: 10px 0;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10000px;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0;
  }

  :hover {
    background-color: #f6f5fe;
    border: 1px solid #5258c8;
    box-shadow: 0px 2px 2px #0000001a !important;
  }

  ${props =>
    props.open &&
    css`
      background-color: #f6f5fe;
      border: 1px solid #5258c8;
      box-shadow: 0px 2px 2px #0000001a !important;
    `}

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
    background-color: #f9f9f9;
    box-shadow: none !important;
    color: #b0b0be;
    border: none !important;

    svg {
      path {
        fill-opacity: 0.4;
        stroke: none;
      }
    }
  }
`;

export const ForumCommentTrigger = styled.div`
  cursor: pointer;
  height: 27px;
`;

export const GiphyPopup = styled(Popup)`
  &.ui.popup {
    background-color: transparent;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    z-index: 3000 !important;
  }

  &.ui.top.popup {
    margin: 0 0 16px !important;
  }

  &.ui.bottom.popup {
    margin: 16px 0 0 !important;
  }

  &::before {
    content: '';
    display: none;
  }

  ${props =>
    props.size &&
    props.size === 'small' &&
    css`
      ${GiphyContainer} {
        width: 528px !important;
      }

      &.ui.top.popup {
        margin: 0 0 6px !important;
      }

      &.ui.bottom.popup {
        margin: 6px 0 0 !important;
      }
    `}
`;

export const GifLoader = styled.div`
  --width: 16px;
  --height: 16px;
  --spinner-dots: 8;
  --width-dots: 2px;
  --height-dots: 5px;

  .lds-spinner {
    display: inline-block;
    position: relative;
    width: var(--width);
    height: var(--height);
  }
  .lds-spinner div {
    transform-origin: calc(var(--width) / 2) calc(var(--height) / 2);
    animation: lds-spinner calc(var(--spinner-dots) * 0.1s) linear infinite;
  }
  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0px;
    left: calc(var(--width) / 2 - 1px);
    width: var(--width-dots);
    height: var(--height-dots);
    border-radius: calc(var(--width) / 2);
    background: #9c9c9c;
  }
  .lds-spinner div:nth-child(1) {
    --nth-child: 1;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(2) {
    --nth-child: 2;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(3) {
    --nth-child: 3;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(4) {
    --nth-child: 4;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(5) {
    --nth-child: 5;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(6) {
    --nth-child: 6;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(7) {
    --nth-child: 7;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  .lds-spinner div:nth-child(8) {
    --nth-child: 8;
    transform: rotate(calc(360deg / var(--spinner-dots) * (var(--nth-child) - 1)));
    animation-delay: calc((var(--spinner-dots) - var(--nth-child)) * -0.1s);
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

// Watermark
export const Watermark = styled.div`
  position: relative;
  display: block;
  ${props =>
    props.maxSize &&
    css`
      width: 100%;
      height: 100%;
    `}
  ${props =>
    props.bgLoaderTransparent &&
    css`
      ${PlaceholderLoader} {
        background-color: transparent !important;
      }
    `}
  img {
    display: block;
    object-fit: cover;
  }
  .giphy-watermark {
    position: absolute;
    bottom: 8px;
    left: 8px;
    display: block;
    width: unset;
    height: unset;
    min-width: 49px;
    object-fit: contain;
  }
`;

export const PlaceholderLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f6f6f6;
  border-radius: 4px;
  width: 100%;
  min-width: 283px;
  height: 159px;
  ${props =>
    props.repliedSize &&
    css`
      width: 148px;
      height: 148px;
      min-width: unset;
    `}

  ${props =>
    props.isReplyComment &&
    css`
      min-width: 242px;
    `}

  ${props =>
    props.isInbox &&
    css`
      width: 310px;
      height: 310px;
      min-width: unset;
    `}

  ${props =>
    props.loaderMaxSize &&
    css`
      min-width: unset;
      width: 100%;
      height: 100%;
    `}

  ${props =>
    props.ratioComment &&
    css`
      min-width: unset;
      height: ${props => (props.isReplyComment ? '230px' : '260px')};
      width: ${props => (props.isReplyComment ? '230px' : '260px')};
    `}

  ${props =>
    props.ratioCommentForum &&
    css`
      min-width: unset;
      height: ${props => (props.isReplyComment ? '260px' : '295px')};
      width: ${props => (props.isReplyComment ? '260px' : '295px')};
    `}

    

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #d9d9d9 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
