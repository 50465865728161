import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { convertToRaw } from 'draft-js';
import emojiRegex from 'emoji-regex';

import { mongoObjectId } from 'utils/commonFunction';

const TEMP_ID = mongoObjectId();

export const getRole = data => {
  switch (data.role) {
    case 3:
      return 'Forum owner';
    case 1:
      return 'Coach';
    default:
      return 'Admin';
  }
};

export const formatMentions = (mentions = [], hasEveryone = false) => {
  let members = mentions.map(it => {
    return {
      ...it,
      name: `${it.first_name} ${it.last_name}`,
      id: it._id,
    };
  });

  !hasEveryone &&
    members.unshift({
      name: 'everyone',
      id: TEMP_ID,
      _id: TEMP_ID,
    });

  return members;
};

const removeEmojis = (string = '') => {
  const regexCustom = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
  const regex = emojiRegex();
  return string.replace(regexCustom, ' ') || string.replace(regex, ' ');
};

const getEntityRanges = (data = {}) => {
  if (data) {
    const content = removeEmojis(data.caption || data.content || '');
    const taggedPeople = get(data, 'tagged_people', []);
    const offsetOfTag = content.indexOf(`@everyone`);
    let entityRanges = [];
    if (!!taggedPeople) {
      taggedPeople.forEach(it => {
        if (!isEmpty(it)) {
          const length = get(it, 'name.length', 0);
          const substr = `@${get(it, 'name', '')}`;
          const indices = [];
          let index = content.indexOf(substr);
          while (index !== -1) {
            indices.push(index);
            index = content.indexOf(substr, index + 1);
          }
          indices.forEach((indice, idxIndice) => {
            entityRanges.push({ offset: indice, length: length + 1, key: idxIndice });
          });
        }
      });
    }
    if (offsetOfTag >= 0) {
      entityRanges = [...entityRanges, { offset: offsetOfTag, length: 9, key: data.tagged_people.length }];
    }
    return entityRanges;
  }
  return [];
};

const formatEntityMap = data => {
  if (data) {
    const content = get(data, 'caption') || get(data, 'content') || '';
    const offsetOfTag = content.indexOf(`@everyone`);
    let dataFormat = [];
    if (!!data.tagged_people) {
      dataFormat = data.tagged_people.map(it => {
        return {
          type: 'mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              ...it,
            },
          },
        };
      });
    }

    if (offsetOfTag >= 0) {
      dataFormat = [
        ...dataFormat,
        {
          type: 'mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              name: 'everyone',
              _id: TEMP_ID,
              id: TEMP_ID, // Create temple id
            },
          },
        },
      ];
    }

    return dataFormat;
  }
  return [];
};

export const initData = data => {
  const content = get(data, 'caption') || get(data, 'content') || '';
  const entityRanges = uniqWith(getEntityRanges(data), isEqual);
  const entityRangesConvert = (entityRanges || []).map((item, index) => ({ ...item, key: index }));

  const results = JSON.stringify({
    blocks: [
      {
        key: mongoObjectId(),
        text: content || '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: entityRangesConvert,
        data: {},
      },
    ],
    entityMap: Object.assign({}, formatEntityMap(data)),
  });

  return results;
};

export const suggestionsFilter = (searchValue, suggestions, maxRow = 10) => {
  let value = searchValue.toLowerCase();
  let filteredSuggestions = suggestions.filter(suggestion => {
    return !value || suggestion.name.toLowerCase().indexOf(value) > -1;
  });
  let length = filteredSuggestions.length < maxRow ? filteredSuggestions.length : maxRow;
  return filteredSuggestions.slice(0, length);
};

export const getTaggedPeople = data => {
  const content = data.getCurrentContent();
  const rawData = convertToRaw(content);
  const { entityMap } = rawData;
  const taggedFromEntityMap = Object.values(entityMap);
  return taggedFromEntityMap.map(it => it.data.mention && it.data.mention._id);
};

export const isTaggedEvery = mentions => {
  return mentions.includes(TEMP_ID);
};

export const getReplyMessage = data => {
  const { authorId, comment, name, userId } = data;
  const { attachment_type } = comment;
  const isOwner = userId === authorId;
  const commentId = get(comment, '_id', '');

  let type = 'text';
  let content = comment.content;
  let owner = 'yourself';

  if (!isOwner) owner = name;

  switch (true) {
    case attachment_type && attachment_type.startsWith('image'):
      content = '';
      type = 'Image';
      if (attachment_type.includes('gif')) type = 'GIF file';
      break;
    case attachment_type && attachment_type.startsWith('video'):
      content = '';
      type = 'Video';
      break;
    default:
      break;
  }

  return { type, content, owner, authorId, commentId };
};
