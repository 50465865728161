import React from 'react';
import ReactAvatar from 'react-avatar';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import * as S from './style';

export default function SearchClientItem({ client: item, onSelect, cloudfrontList }) {
  const avt = convertS3UrlToCloudFrontUrl(item.avatar, cloudfrontList, true);

  return (
    <S.SearchClientItemWrapper onClick={() => onSelect(item)}>
      <div className="searchClientItem__avatar">
        {item.type === 'group' ? (
          <ReactAvatar
            name={getUserShortName(item)}
            className="client-detail-avatar"
            size="34"
            src={avt}
            color={item.color}
          />
        ) : (
          <ReactAvatar name={item.name} className="client-detail-avatar" size="34" src={avt} color={item.color} />
        )}
      </div>
      <div className="searchClientItem__info">
        <div className="searchClientItem__info--name">
          {item.type === 'group' && <span className="searchClientItem__info--prefixGroup">Group: </span>}
          {item.name}
        </div>
        <div className="searchClientItem__info--email">
          {item.type === 'group' ? `${item.clients} clients` : item.email}
        </div>
      </div>
    </S.SearchClientItemWrapper>
  );
}
