import React from 'react';
import { ReactComponent as AllIcon } from 'assets/icons/MealPlans/RecipeCategories/all_categories.svg';
import { ReactComponent as BreakfastIcon } from 'assets/icons/MealPlans/RecipeCategories/breakfast.svg';
import { ReactComponent as LunchIcon } from 'assets/icons/MealPlans/RecipeCategories/lunch.svg';
import { ReactComponent as DinnerIcon } from 'assets/icons/MealPlans/RecipeCategories/dinner.svg';
import { ReactComponent as SnackIcon } from 'assets/icons/MealPlans/RecipeCategories/snack.svg';
import { ReactComponent as SoupIcon } from 'assets/icons/MealPlans/RecipeCategories/soup.svg';
import { ReactComponent as SaladIcon } from 'assets/icons/MealPlans/RecipeCategories/salad.svg';
import { ReactComponent as OthersIcon } from 'assets/icons/MealPlans/RecipeCategories/others.svg';
import { RECIPE_CATEGORY } from 'components/RecipeDetail/constants';

export const CATEGORIES = [
  {
    label: 'All',
    icon: <AllIcon className="category-icon" />,
    value: '',
  },
  {
    label: 'Breakfast',
    icon: <BreakfastIcon className="category-icon" />,
    value: RECIPE_CATEGORY.BREAKFAST,
  },
  {
    label: 'Lunch',
    icon: <LunchIcon className="category-icon" />,
    value: RECIPE_CATEGORY.LUNCH,
  },
  {
    label: 'Dinner',
    icon: <DinnerIcon className="category-icon" />,
    value: RECIPE_CATEGORY.DINNER,
  },
  {
    label: 'Snack',
    icon: <SnackIcon className="category-icon" />,
    value: RECIPE_CATEGORY.SNACK,
  },
  {
    label: 'Soup',
    icon: <SoupIcon className="category-icon" />,
    value: RECIPE_CATEGORY.SOUP,
  },
  {
    label: 'Salad/Bowl',
    icon: <SaladIcon className="category-icon" />,
    value: RECIPE_CATEGORY.SALAD_BOWL,
  },
  {
    label: 'Others',
    icon: <OthersIcon className="category-icon" />,
    value: RECIPE_CATEGORY.OTHERS,
  },
];

export const RECIPE_BUILDER_UPLOAD_URL = '/api/file/gen-presigned-urls-meal-plan';

export const DIMENSIONS_AT_LEAST_WIDTH = 335;

export const ARRAY_LENGTH_LOADING = 12;

export const MIN_CALORIES = 0;

export const MAX_CALORIES = 50;

export const SORT_BY = {
  A_TO_Z: 'name_lowercase',
  MOST_RECENT: 'updated_at',
};

export const RECIPE_FROM = [
  {
    label: 'Everfit',
    key: 'system',
  },
  {
    label: 'Custom Recipes',
    key: 'custom',
  },
];

export const RECIPE_FILTER_STATUS = [
  {
    label: 'Published',
    key: 'publish',
  },
  {
    label: 'Draft',
    key: 'draft',
  },
];

export const RECIPE_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
};

export const RECIPE_HEADER_TABS = {
  ALL_RECIPE: 'all_recipe',
  YOUR_RECIPE: 'your_recipe',
};

export const TYPE_RECIPE = {
  SYSTEM: 'system',
  CUSTOM: 'custom',
};
