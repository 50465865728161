import React from 'react';
import PopupModal from './PopupModal';

const AddNewForm = ({
  open,
  itemEditing,
  titlePopup,
  description,
  titleInput,
  placeholderInput,
  submitName,
  onSetOpen,
  onSubmit,
  maxLength,
}) => {
  return (
    <PopupModal
      open={open}
      itemEditing={itemEditing}
      titlePopup={titlePopup}
      description={description}
      titleInput={titleInput}
      placeholderInput={placeholderInput}
      submitName={submitName}
      onSetOpen={onSetOpen}
      onSubmit={onSubmit}
      maxLength={maxLength}
    />
  );
};

export default AddNewForm;
