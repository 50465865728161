import styled, { css } from 'styled-components';
import { SubmitButton } from 'layouts/Login/style';

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 35px;
  flex-flow: row wrap;

  .evf-checkbox {
    padding-left: 25px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-right: 20px;

    .checkbox-icon {
      border: 1px solid #e3e6ef;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 5px;
      background-size: 11px;
    }
  }

  > * {
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  ${SubmitButton} {
    margin-bottom: 15px;
  }
`;

export const JoinTeamTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-family: 'Open Sans';
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
`;

export const JoinTeamName = styled.div`
  font-family: 'Open Sans';
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
`;

export const JoinTeamWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 2px;
`;
