import React from 'react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

import { Checkbox } from 'shared/FormControl';
import ActionTrigger from 'shared/Dropdown/Trigger';
import { AUTOFLOW_CLIENT_STATUS, CDN_URL } from 'constants/commonData';
import { getUserShortName } from 'utils/commonFunction';
import { isTeamAdmin } from 'utils/commonFunction';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import StatusColumn from './components/StatusColumn';
import ProgressColumn from './components/ProgressColumn';
import StartDateColumn from './components/StartDateColumn';
import EndDateColumn from './components/EndDateColumn';
import ComplianceHeader from './components/ComplianceHeader';
import ComplianceColumn from './components/ComplianceColumn';
// import svg
import { ReactComponent as DateIcon } from 'assets/icons/date_white.svg';
import { SharedTooltip } from 'shared/SharedTooltip';

export default [
  {
    key: 'name',
    fieldData: 'name',
    label: 'Name',
    sortKey: 'name',
    mutate: function (data) {
      const { client } = data;
      const { user } = this.props;
      const trainer = get(client, 'trainer', {});
      // check client is subcoach (start)
      const subTrainers = get(client, 'sub_trainers', []);
      const isSubTrainer = findIndex(subTrainers, function (o) {
        return o.trainer._id === user._id;
      });
      // check client is subcoach (end)
      const isChecked = this.props.selectedClients.some(item => item._id === client._id);

      return (
        <div className="autoflow-client__profile">
          <div className="autoflow-client__profile__avatar">
            {(data.canEdit && !data.is_archived) || !data.is_completed ? (
              <Checkbox
                checked={isChecked}
                className="select-checkbox"
                onChange={event => {
                  this.onSelectClient(
                    { ...client, status: data.status, started_on: data.started_on },
                    event.target.checked,
                  );
                }}
              />
            ) : null}
            <Avatar name={getUserShortName(client)} size="29" src={client.avatar} color={client.color} />
          </div>
          {!isTeamAdmin(user) && trainer._id !== user._id && isSubTrainer === -1 ? ( // isSubTrainer is check subcoach
            <div className="autoflow-client__profile__name disabled">
              <span>{client.full_name}</span>
            </div>
          ) : (
            <Link className="autoflow-client__profile__name" to={`/home/client/${client._id}`}>
              <span>{client.full_name}</span>
            </Link>
          )}
          {data.is_completed ? (
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-tip
                data-for={`tooltip--completed-marker--${data._id}`}
                className="completed-marker"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                  fill="#716DE0"
                />
              </svg>
            </>
          ) : null}
        </div>
      );
    },
  },
  {
    key: 'status',
    fieldData: 'status',
    label: 'Status',
    sortKey: 'status',
    mutate: function (data) {
      const { status } = data;
      return <StatusColumn status={status} />;
    },
  },
  {
    key: 'compliance',
    fieldData: 'compliance',
    label: 'Training',
    customHeader: props => <ComplianceHeader {...props} />,
    sortKey: 'compliance',
    mutate: function (data) {
      return <ComplianceColumn item={data} />;
    },
  },
  // {
  //   key: 'percent_completed_workout',
  //   fieldData: 'percent_completed_workout',
  //   label: 'Training',
  //   sortKey: 'percent_completed_workout',
  //   mutate: function (data) {
  //     const { percent_completed_workout, status } = data;
  //     return <ComplianceColumn status={status} progress={percent_completed_workout || 0} color="#45A5FF" />;
  //   },
  // },
  // {
  //   key: 'percent_completed_task',
  //   fieldData: 'percent_completed_task',
  //   label: 'Task',
  //   sortKey: 'percent_completed_task',
  //   mutate: function (data) {
  //     const { percent_completed_workout, status } = data;
  //     return <ComplianceColumn status={status} progress={percent_completed_workout || 0} color="#BC7CF8" />;
  //   },
  // },
  {
    key: 'blank',
    fieldData: 'blank',
    label: <div>&nbsp;</div>,
    mutate: function (data) {
      return <div />;
    },
  },
  {
    key: 'completed_day',
    fieldData: 'completed_day',
    label: 'Progress',
    sortKey: 'completed_day',
    mutate: function (data) {
      const { completed_day, weeks, status } = data;
      return <ProgressColumn weeks={weeks} completed_day={completed_day || 0} status={status} />;
    },
  },
  {
    key: 'started_date',
    fieldData: 'started_date',
    label: 'Start Date',
    sortKey: 'started_date',
    mutate: function (data) {
      return <StartDateColumn item={data} />;
    },
  },
  {
    key: 'end_date',
    fieldData: 'end_date',
    label: 'End Date',
    sortKey: 'end_date',
    mutate: function (data) {
      return <EndDateColumn item={data} />;
    },
  },
  {
    key: 'action',
    fieldData: 'action',
    label: 'Actions',
    hideLabel: true,
    mutate: function (data) {
      const { client } = data;
      const { user } = this.props;
      const trainer = get(client, 'trainer', {});
      // check client is subcoach (start)
      const subTrainers = get(client, 'sub_trainers', []);
      const isSubTrainer = findIndex(subTrainers, function (o) {
        return o.trainer._id === user._id;
      });
      // check client is subcoach (start)
      const canAction = isTeamAdmin(user) || trainer._id === user._id || isSubTrainer !== -1; // isSubTrainer is check subcoach

      if (!client || !canAction) return null;

      const interactable = !data.is_archived && !data.is_completed;

      const renderTrigger = ({ open }) => (
        <div data-tip data-for="client-autoflow-table-tooltip">
          <ActionTrigger />
          {!open && <SharedTooltip id="client-autoflow-table-tooltip" />}
        </div>
      );

      return (
        <DropDown triggerIcon={renderTrigger}>
          {interactable && data.status === AUTOFLOW_CLIENT_STATUS.PAUSE ? (
            <Option onClick={() => this.onResumeClient(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/play_white.svg`} />
                Resume
              </div>
            </Option>
          ) : null}
          {interactable && data.status === AUTOFLOW_CLIENT_STATUS.ACTIVE ? (
            <Option onClick={() => this.onPauseClient(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/pause_white.svg`} width={14} />
                Pause
              </div>
            </Option>
          ) : null}
          {interactable &&
          (data.status === AUTOFLOW_CLIENT_STATUS.ACTIVE || data.status === AUTOFLOW_CLIENT_STATUS.PAUSE) ? (
            <Option onClick={() => this.onMaskAsComplete(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/mask_as_complete_white.svg`} width={14} />
                Mark as Complete
              </div>
            </Option>
          ) : null}
          {interactable && data.status === AUTOFLOW_CLIENT_STATUS.WAITING_TO_START ? (
            <Option onClick={() => this.onStartTodayClient(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/play_white.svg`} />
                Start Today
              </div>
            </Option>
          ) : null}
          {interactable && data.status === AUTOFLOW_CLIENT_STATUS.WAITING_TO_START ? (
            <Option onClick={() => this.onChangeStartDateClient(data)}>
              <div className="item__content">
                <DateIcon />
                Change Start Date
              </div>
            </Option>
          ) : null}
          <Option onClick={() => this.onDeleteClient(data)}>
            <div className="item__content">
              <Image src={`${CDN_URL}/images/delete.svg`} width={14} />
              Remove
            </div>
          </Option>
        </DropDown>
      );
    },
  },
];
