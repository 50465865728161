import styled, { css } from 'styled-components';
import ImageWithFallback from 'shared/ImageWithFallback';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

export const Video = styled.video`
  max-width: ${props => (props.isReplyComment ? '230px' : '260px')};
  max-height: ${props => (props.isReplyComment ? '230px' : '260px')};
  display: block;
`;

export const VideoHolder = styled.div`
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;

  ${props =>
    props.defaultThumb &&
    css`
      min-width: 100px;
    `}
`;

export const Author = styled.span`
  font-weight: bold;
`;

export const Message = styled.span``;

export const Content = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 5px;
  white-space: pre-wrap;
  color: #000;
  word-break: break-word;
`;

export const CommentDate = styled.span`
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;

  :hover {
    text-decoration: underline;
  }
`;

export const CommentAttachItemWrapper = styled.span`
  display: flex;
`;

export const CommentAttachItem = styled.span`
  display: inline-block;
  max-width: 175px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const OtherInfo = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #979797;
  .media-player__trigger {
    width: 100% !important;
    height: 100% !important;
    overflow-y: scroll;
  }
`;

export const MediaContainer = styled.div`
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 5px;

  .s3-video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: ${props => (props.isReplyComment ? '230px' : '260px')};
    max-width: ${props => (props.isReplyComment ? '230px' : '260px')};
  }

  img.thumbnail {
    max-height: ${props => (props.isReplyComment ? '230px' : '260px')};
    max-width: ${props => (props.isReplyComment ? '230px' : '260px')};
    width: 100%;
    height: 100%;
  }

  .media-player__trigger {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
`;

export const MediaBox = styled.div`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin: 11px 0 5px;
`;

export const Container = styled.div`
  width: calc(100% - 30px);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 19px;

  ${props =>
    props.isAutoScroll &&
    css`
      margin-bottom: auto;
      padding-bottom: 19px;
      :first-child {
        height: -moz-available;
        height: -webkit-fill-available;
        height: fill-available;
      }
    `};

  .comment__avatar {
    margin-right: 10px;
    .comment__avatar {
      border-radius: 50%;
      object-fit: cover;

      span {
        font-weight: 600;
      }
    }
  }
`;

export const CommentInfo = styled.div`
  display: inline-flex;
`;

export const CommentInfoWrapper = styled.div`
  display: flex;
`;

export const ReplyWrapper = styled.div`
  ${props =>
    props.loadingReply &&
    css`
      :hover {
        cursor: not-allowed;
      }

      ${ReplyButton} {
        pointer-events: none;
      }
    `}
`;

export const ReplyButton = styled.div`
  user-select: none;

  :hover {
    color: #5158cf;
    cursor: pointer;
  }
`;

export const Seperate = styled.div`
  margin-left: 4px;
  margin-right: 12px;
`;

export const ImageWithFallbackWrapper = styled(ImageWithFallback)`
  max-height: ${props => (props.isReplyComment ? '230px' : '260px')};
  max-width: ${props => (props.isReplyComment ? '230px' : '260px')};
`;

export const S3ImageWithFallbackWrapper = styled(S3ImageWithFallback)`
  img {
    max-height: ${props => (props.isReplyComment ? '230px' : '260px')};
    max-width: ${props => (props.isReplyComment ? '230px' : '260px')};
  }
`;
