import { get } from 'lodash';
import Request from 'configs/request';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { showError, hideError } from 'actions/error';
import { FORM_STATUS, QUESTION_TYPES } from 'constants/commonData';
import { changeQueryParams } from 'redux/forms/actions';
import { FORM_FETCH_TYPES } from 'constants/commonData';
import { getAvailableMetrics } from 'utils/commonFunction';

export const Types = {
  GET_FORM_DETAIL_REQUEST: 'GET_FORM_DETAIL_REQUEST',
  GET_FORM_DETAIL_SUCCESS: 'GET_FORM_DETAIL_SUCCESS',
  GET_FORM_DETAIL_FAILED: 'GET_FORM_DETAIL_FAILED',
  UPDATE_WORKING_FORM_REQUEST: 'UPDATE_WORKING_FORM_REQUEST',
  UPDATE_WORKING_FORM_SUCCESS: 'UPDATE_WORKING_FORM_SUCCESS',
  UPDATE_WORKING_FORM_FAILED: 'UPDATE_WORKING_FORM_FAILED',
  ADD_QUESTION_REQUEST: 'ADD_QUESTION_REQUEST',
  ADD_QUESTION_SUCCESS: 'ADD_QUESTION_SUCCESS',
  ADD_QUESTION_FAILED: 'ADD_QUESTION_FAILED',
  REMOVE_QUESTION_REQUEST: 'REMOVE_QUESTION_REQUEST',
  REMOVE_QUESTION_SUCCESS: 'REMOVE_QUESTION_SUCCESS',
  REMOVE_QUESTION_FAILED: 'REMOVE_QUESTION_FAILED',
  SELECTED_QUESTION_REQUEST: 'SELECTED_QUESTION_REQUEST',
  SELECTED_QUESTION_SUCCESS: 'SELECTED_QUESTION_SUCCESS',
  SELECTED_QUESTION_FAILED: 'SELECTED_QUESTION_FAILED',
  DUPLICATE_QUESTION_REQUEST: 'DUPLICATE_QUESTION_REQUEST',
  DUPLICATE_QUESTION_SUCCESS: 'DUPLICATE_QUESTION_SUCCESS',
  DUPLICATE_QUESTION_FAILED: 'DUPLICATE_QUESTION_FAILED',
  REARRANGE_QUESTIONS_REQUEST: 'REARRANGE_QUESTIONS_REQUEST',
  REARRANGE_QUESTIONS_SUCCESS: 'REARRANGE_QUESTIONS_SUCCESS',
  REARRANGE_QUESTIONS_FAILED: 'REARRANGE_QUESTIONS_FAILED',
  UPDATE_PARTIAL_QUESTION_REQUEST: 'UPDATE_PARTIAL_QUESTION_REQUEST',
  UPDATE_PARTIAL_QUESTION_SUCCESS: 'UPDATE_PARTIAL_QUESTION_SUCCESS',
  UPDATE_PARTIAL_QUESTION_FAILED: 'UPDATE_PARTIAL_QUESTION_FAILED',
  GET_QUESTION_TYPES_REQUEST: 'GET_QUESTION_TYPES_REQUEST',
  GET_QUESTION_TYPES_SUCCESS: 'GET_QUESTION_TYPES_SUCCESS',
  GET_QUESTION_TYPES_FAILED: 'GET_QUESTION_TYPES_FAILED',
  PUBLISH_FORM_REQUEST: 'PUBLISH_FORM_REQUEST',
  PUBLISH_FORM_SUCCESS: 'PUBLISH_FORM_SUCCESS',
  PUBLISH_FORM_FAILED: 'PUBLISH_FORM_FAILED',
  ENABLE_EDIT_FORM_REQUEST: 'ENABLE_EDIT_FORM_REQUEST',
  ENABLE_EDIT_FORM_SUCCESS: 'ENABLE_EDIT_FORM_SUCCESS',
  ENABLE_EDIT_FORM_FAILED: 'ENABLE_EDIT_FORM_FAILED',
  RESET_FORM_DETAIL: 'RESET_FORM_DETAIL',
  GET_BACKGROUND_LIBRARY_REQUEST: 'GET_BACKGROUND_LIBRARY_REQUEST',
  GET_BACKGROUND_LIBRARY_SUCCESS: 'GET_BACKGROUND_LIBRARY_SUCCESS',
  REMOVE_IMAGE_REQUEST: 'REMOVE_IMAGE_REQUEST',
  REMOVE_IMAGE_SUCCESS: 'REMOVE_IMAGE_SUCCESS',
  SAVE_BACKGROUND_LIBRARY_SUCCESS: 'SAVE_BACKGROUND_LIBRARY_SUCCESS',
  SET_TOTAL_RESPONSES: 'SET_TOTAL_RESPONSES',
  DUPLICATE_FORM_QUESTIONS: 'DUPLICATE_FORM_QUESTIONS',
  UPDATE_NUMBER_OF_RESPONSIVE: 'UPDATE_NUMBER_OF_RESPONSIVE',
  IS_UPLOADING_IMAGE: 'IS_UPLOADING_IMAGE',
  GET_DEFAULT_METRICS: 'GET_DEFAULT_METRICS',
  FROM_DETAIL_UPDATE_LIST_QUESTION_CONFIRMED: 'FROM_DETAIL_UPDATE_LIST_QUESTION_CONFIRMED',
};

export const updateWorkingForm = (data, formId, callback) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_PARTIAL_QUESTION_REQUEST });
    return dispatch(
      Request.patch(
        { url: `api/forms/${formId}`, data: data },
        true,
        response => {
          dispatch({ type: Types.UPDATE_WORKING_FORM_SUCCESS, payload: { data, formId } });
          callback && callback();
        },
        err => {
          dispatch({ type: Types.UPDATE_WORKING_FORM_FAILED });
        },
      ),
    );
  };
};

export const getDetails = (formID, isKeepCurrentState = false, callback) => {
  return (dispatch, getState) => {
    const draftForms = get(getState(), 'rootReducer.formDetails.draftForms', []);
    const existingForm = draftForms.find(
      item =>
        get(item, '_id', '') === formID &&
        (item.status === FORM_STATUS.DRAFT || (item.status === FORM_STATUS.PUBLISHED && item.is_edit_mode)) &&
        !isKeepCurrentState,
    );
    dispatch({ type: Types.GET_FORM_DETAIL_REQUEST });

    if (existingForm) {
      return dispatch({
        type: Types.GET_FORM_DETAIL_SUCCESS,
        payload: {
          data: { ...existingForm, is_edit_mode: existingForm.is_edit_mode && existingForm.has_edit_permission },
          isKeepCurrentState,
        },
      });
    } else {
      return dispatch(
        Request.get({ url: `api/forms/${formID}/draft` }, true, response => {
          const { data } = response.data;

          if (data) {
            dispatch({
              type: Types.GET_FORM_DETAIL_SUCCESS,
              payload: {
                data: { ...data, is_edit_mode: data.is_edit_mode && data.has_edit_permission },
                isKeepCurrentState,
              },
            });
          }
          callback && callback();
        }),
      );
    }
  };
};

export const addQuestion = (data, callback) => {
  return dispatch => {
    dispatch({ type: Types.ADD_QUESTION_REQUEST });

    callback && callback();
    return dispatch({ type: Types.ADD_QUESTION_SUCCESS, payload: data });
    // return dispatch(
    //   Request.post({ url: 'api/form-questions', data: params }, true, response => {
    //     const { data } = response.data;
    //     dispatch({ type: Types.ADD_QUESTION_SUCCESS, payload: data });
    //     callback && callback();
    //   }),
    // );
  };
};

export const removeQuestion = (id, callback) => {
  return dispatch => {
    dispatch({ type: Types.REMOVE_QUESTION_REQUEST });
    callback && callback();
    return dispatch({ type: Types.REMOVE_QUESTION_SUCCESS, payload: id });
    // return dispatch(
    //   Request.delete({ url: `api/form-questions/${id}` }, true, response => {
    //     if (get(response, 'data.data.success', false)) {
    //       dispatch({ type: Types.REMOVE_QUESTION_SUCCESS, payload: id });
    //     }
    //     callback && callback();
    //   }),
    // );
  };
};

export const selectedQuestion = (data, callback) => {
  return (dispatch, getState) => {
    const questionId = get(getState(), 'rootReducer.formDetails.workingQuestion._id', '');
    if (questionId === data._id) return;
    callback && callback();
    return dispatch({ type: Types.SELECTED_QUESTION_SUCCESS, payload: data });
  };
};

export const duplicateQuestion = (data, callback) => {
  return dispatch => {
    dispatch({ type: Types.DUPLICATE_QUESTION_REQUEST });
    callback && callback();
    return dispatch({
      type: Types.DUPLICATE_QUESTION_SUCCESS,
      payload: data,
    });
    // return dispatch(
    //   Request.post({ url: `api/form-questions/${id}/duplicate` }, true, response => {
    //     const { data } = response.data;
    //     dispatch({
    //       type: Types.DUPLICATE_QUESTION_SUCCESS,
    //       payload: { id, data },
    //     });
    //     callback && callback();
    //   }),
    // );
  };
};

export const rearrangeQuestions = (data, callback) => {
  return (dispatch, getState) => {
    // const {
    //   rootReducer: {
    //     formDetails: { questions },
    //   },
    // } = getState();
    // const hasWelcome = questions.find(x => x.type === QUESTION_TYPES.WELCOME_SCREEN);
    dispatch({ type: Types.REARRANGE_QUESTIONS_REQUEST });
    callback && callback();
    return dispatch({ type: Types.REARRANGE_QUESTIONS_SUCCESS, payload: data });
    // return dispatch(
    //   Request.patch(
    //     {
    //       url: `api/form-questions/${data.id}/rearrange`,
    //       data: {
    //         index: !hasWelcome ? data.destination + 1 : data.destination,
    //       },
    //     },
    //     true,
    //     response => {
    //       if (get(response, 'data.data.success', false)) {
    //         dispatch({ type: Types.REARRANGE_QUESTIONS_SUCCESS, payload: data });
    //       }
    //       callback && callback();
    //     },
    //   ),
    // );
  };
};

export const updatePartialQuestion = (data, callback, callAPI = true) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_PARTIAL_QUESTION_REQUEST });
    callback && callback();
    // if (callAPI) {
    //   return dispatch(
    //     Request.patch({ url: `api/form-questions/${data._id}`, data }, true, response => {
    //       if (get(response, 'data.data.success', false)) {
    //         dispatch({ type: Types.UPDATE_PARTIAL_QUESTION_SUCCESS, payload: data });
    //       }
    //       callback && callback();
    //     }),
    //   );
    // } else {
    return dispatch({ type: Types.UPDATE_PARTIAL_QUESTION_SUCCESS, payload: data });
    // }
  };
};

export const getQuestionTypes = callback => {
  return dispatch => {
    dispatch({ type: Types.GET_QUESTION_TYPES_REQUEST });

    return dispatch(
      Request.get({ url: `api/form-questions/types` }, true, response => {
        const { data } = response.data;

        if (data) {
          dispatch({ type: Types.GET_QUESTION_TYPES_SUCCESS, payload: data });
        }
        callback && callback();
      }),
    );
  };
};

export const publishForm = formId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        formDetails: { questions = [] },
      },
    } = getState();
    if (
      questions.filter(o => ![QUESTION_TYPES.WELCOME_SCREEN, QUESTION_TYPES.IMAGE_TEXT].includes(o.type)).length > 0
    ) {
      const newQuestions = questions.map(question => {
        const { type = '', unit: { _id } = {} } = question || {};

        if ([QUESTION_TYPES.METRIC].includes(type)) {
          return { ...question, unit: _id };
        }

        return question;
      });

      dispatch({ type: Types.PUBLISH_FORM_REQUEST });
      return dispatch(
        Request.post(
          { url: `api/forms/${formId}/publish-full`, data: { questions: newQuestions } },
          true,
          response => {
            const { data } = response.data;
            if (data.success) {
              dispatch(getDetails(formId, true));
              toast('Form has been published. You can attach the form to a Task or a Resource.');
            }
          },
          err => {
            dispatch({ type: Types.PUBLISH_FORM_FAILED });
          },
        ),
      );
    } else {
      toast.error('Please add at least 1 question before publishing the form');
    }
  };
};

export const enableEditForm = (formId, data, callback) => {
  const { enable } = data;
  return dispatch => {
    dispatch({ type: Types.ENABLE_EDIT_FORM_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: `api/forms/${formId}/edit-mode`,
          data,
        },
        true,
        response => {
          const { data } = response.data;
          if (data.success) {
            if (!enable) {
              dispatch({ type: Types.ENABLE_EDIT_FORM_SUCCESS, payload: formId });
            }
            dispatch(getDetails(formId, enable, callback));
          }
        },
        err => {
          if (get(err, 'response.status') === 400) {
            dispatch(getDetails(formId, true));
          } else {
            dispatch(showError(get(err, 'response.data.message'), null, null, null, null, () => backToForms(dispatch)));
            dispatch({ type: Types.ENABLE_EDIT_FORM_FAILED });
          }
        },
        false,
      ),
    );
  };
};

export const resetFormDetail = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_FORM_DETAIL });
  };
};

export const getBackgroundLibrary = callback => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        formDetails: { workingForm },
      },
    } = getState();
    dispatch({ type: Types.GET_BACKGROUND_LIBRARY_REQUEST });
    const params = {
      item: workingForm._id,
      topic: 'form',
    };

    return dispatch(
      Request.get({ url: `api/background-libraries`, params: params }, true, response => {
        const { data } = response.data;

        if (data) {
          dispatch({ type: Types.GET_BACKGROUND_LIBRARY_SUCCESS, payload: { data } });
        }
        callback && callback();
      }),
    );
  };
};

export const getNumberOfResponse = callback => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        formDetails: { workingForm },
      },
    } = getState();
    if (!workingForm._id) return;
    return dispatch(
      Request.get({ url: `/api/forms/${workingForm._id}/number-of-response` }, true, response => {
        const { data } = response.data;
        if (data || data === 0) {
          dispatch({ type: Types.UPDATE_NUMBER_OF_RESPONSIVE, payload: { data, id: workingForm._id } });
        }
        callback && callback();
      }),
    );
  };
};

export const saveBackgroundToLibrary = (data, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        formDetails: { workingForm },
      },
    } = getState();
    // dispatch({ type: Types.SAVE_BACKGROUND_LIBRARY_REQUEST });
    const body = {
      item: workingForm._id,
      topic: 'form',
      background: data,
    };

    return dispatch(
      Request.post({ url: `api/background-libraries`, data: body }, true, response => {
        const { data } = response.data;

        if (data) {
          dispatch({ type: Types.SAVE_BACKGROUND_LIBRARY_SUCCESS, payload: { data } });
          callback && callback(data);
          // dispatch(getBackgroundLibrary());
        }
        // callback && callback();
      }),
    );
  };
};

export const removeBackgroundLibraryImage = (imageId, callback) => {
  return dispatch => {
    dispatch({ type: Types.REMOVE_IMAGE_REQUEST });
    return dispatch(
      Request.delete({ url: `api/background-libraries/${imageId}` }, true, response => {
        if (get(response, 'data.data.success', false)) {
          callback && callback();
          dispatch(
            getBackgroundLibrary(() => {
              dispatch({ type: Types.REMOVE_IMAGE_SUCCESS });
            }),
          );
        }
      }),
    );
  };
};

export const duplicateFormQuestion = data => {
  return dispatch => {
    dispatch({ type: Types.DUPLICATE_FORM_QUESTIONS, payload: data });
  };
};

export const backToForms = dispatch => {
  dispatch(hideError(false));
  dispatch(
    changeQueryParams({
      page: 1,
      fetch_type: FORM_FETCH_TYPES.YOUR_FORM,
    }),
  );
  dispatch(push('/home/forms'));
};

export const getIsUploading = data => {
  return dispatch => {
    dispatch({ type: Types.IS_UPLOADING_IMAGE, payload: data });
  };
};

export const getDefaultMetrics = () => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/body-metric/get-list-trainer-default-body-metric` }, true, (response, { dispatch }) => {
        const { data } = get(response, 'data', {});
        dispatch({
          type: Types.GET_DEFAULT_METRICS,
          payload: {
            data: (getAvailableMetrics(data) || []).filter(({ unique_code = '' }) => unique_code !== 'steps'),
          },
        });
      }),
    );
  };
};

export const updateListQuestionConfirmed = data => {
  return dispatch => {
    dispatch({ type: Types.FROM_DETAIL_UPDATE_LIST_QUESTION_CONFIRMED, payload: data });
  };
};
