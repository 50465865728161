import { Button } from 'shared/FormControl';
import S3Images from 'shared/S3Images';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
`;

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const RecipesContainer = styled.div`
  height: calc(100% - 175px);
  padding: 0 30px 30px;
  padding-right: 12px;
  margin-right: 12px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }
  ${({ isEmptyList }) =>
    isEmptyList &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const OrderBy = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const SortLabel = styled.div`
  color: #7b7e91;
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
`;

const activePopup = css`
  background: #f0f1ff;
  color: #5158cf;
  border-radius: 5px;

  .arrow-down-icon {
    path {
      stroke: #5158cf;
    }
  }
`;

export const SortTrigger = styled.div`
  max-height: 30px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #202353;

  :hover {
    ${activePopup}
  }

  ${props => props.active && activePopup}

  .arrow-down-icon {
    transform: rotate(180deg);
    width: 12px;
    height: 12px;
    margin-left: 6px;
    margin-top: 3px;
  }
`;

export const SortPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 151px;
  position: absolute;
  top: 38px;
  right: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  font-size: 13px;
  font-weight: 400;
`;

export const SortItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${props => (props.active ? '#5158cf' : '#7b7e91')};
  font-weight: ${props => (props.active ? '600' : '400')};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  :hover {
    color: #5158cf;
    font-weight: ${props => (props.active ? '600' : '400')};
    border-radius: 5px;
    background: #f0f1ff;
  }

  .checked-icon {
    margin-top: 4px;
    margin-right: 4px;
    path {
      fill: #5158cf;
    }
  }

  .sort-item-label {
    white-space: nowrap;
  }
`;

export const ContentList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 30px 25px;

  @media only screen and (min-width: 1880px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1641px) and (max-width: 1879px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1280px) and (max-width: 1640px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1040px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (max-width: 1039px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const RecipeItemWrapper = styled.div`
  position: relative;
  min-height: 268px;
  border-radius: 5px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  :hover {
    .evf-dropdown.custom-dropdown-actions {
      visibility: visible;
      opacity: 1;
    }
  }

  .evf-dropdown.custom-dropdown-actions.open {
    visibility: visible;
    opacity: 1;
    .dropdown-trigger {
      background-color: #f0f1ff !important;
    }
  }

  .evf-dropdown.custom-dropdown-actions {
    position: absolute;
    right: 15px;
    top: 10px;
    visibility: hidden;
    opacity: 0;

    .evf-dropdown__trigger-container {
      .dropdown-trigger {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: #fff;

        .dot {
          transform: unset;
        }

        .dot,
        .dot::after,
        .dot::before {
          background-color: #202353;
        }

        :hover {
          background-color: #f0f1ff;

          .dot,
          .dot::after,
          .dot::before {
            background-color: #5158cf;
          }
        }

        .__react_component_tooltip.app-tooltip.show {
          background: #333;
          padding: 8px 10px;
          border-radius: 5px;
          ${baseText}
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          width: 95px;
          height: 34px;
        }

        .__react_component_tooltip.type-dark.place-top:after {
          border-top-color: #333;
          margin-bottom: -2px !important;
        }

        .__react_component_tooltip.place-top {
          margin-top: -11px;
        }
      }
    }

    .evf-dropdown__menu {
      padding: 4px 0;

      .evf-dropdown__option {
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        user-select: none;

        .icon {
          display: flex;
          justify-content: center;
        }

        span {
          color: #f7f8f9;
          font-family: 'Open Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
`;

export const OptionIcon = styled.div``;

export const RecipeThumbnailWrapper = styled.div`
  position: relative;
`;

export const RecipeFloat = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 7px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  align-items: center;
  .recipe-play-icon {
    width: 20px;
    height: 20px;
    pointer-events: auto;
  }
`;

export const RecipeThumbnail = styled(S3Images)`
  height: 168px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const RecipeInfo = styled.div`
  padding: 15px 15px 20px 15px;
  .__react_component_tooltip.recipe-avatar-tooltip {
    border-radius: 5px;
    background: #333;
    padding: 8px 16px;
    ${baseText}
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    &.type-dark.place-top:after {
      border-top-color: #333;
      border-top-style: solid;
      border-top-width: 6px;
    }
    &.place-top {
      margin-top: -6px;
    }
  }
`;

export const RecipeTitle = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 40px;
  margin-bottom: 5px;
`;

export const InfoWrapper = styled.div`
  position: relative;
  padding-right: unset;
  .recipe-owner-avatar {
    position: absolute;
    right: 0;
    top: -2px;
    .recipe-avatar.sb-avatar__text {
      pointer-events: none;
    }
    img {
      pointer-events: none;
    }
  }
  ${props =>
    props.isAnotherOwner &&
    css`
      padding-right: 24px;
    `}
`;

export const RecipeUnit = styled.div`
  ${baseText}
  color: #202353;
  font-size: 12px;
  line-height: 18px;

  display: flex;
  flex-wrap: wrap;
  gap: 5px 0;
`;

export const SeparateDot = styled.span`
  width: 2px;
  height: 2px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #7b7e91;
`;

export const ItemNutrition = styled.div`
  max-width: fit-content;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  &:first-child {
    ${SeparateDot} {
      display: none;
    }
  }
`;

export const RecipeOwner = styled.div`
  color: #7b7e91;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 7px;
`;

export const BoldGrayText = styled.span`
  font-weight: 600;
  color: #7b7e91;
`;

export const EmptyListWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  ${({ isEmptyList }) =>
    isEmptyList &&
    css`
      padding: 0;
    `}
`;

export const NotFoundWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoResultFound = styled.span`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const Notice = styled.span`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const NewRecipeButton = styled(Button)`
  padding: 10px 20px 10px 15px;
  margin-top: 23px;
  margin-left: 8px;
  min-width: 208px;
  max-height: 36px;
  .plus-icon {
    width: 13px;
    height: 13px;
    fill: #fff;
    margin-top: -2px;
    margin-right: 4px;
  }
  .label {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 1px;
    margin-left: 5px;
  }
  :hover {
    opacity: 0.9;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 24px;
  border-radius: 5px;
  padding: 0 10px 0 11px;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const StatusDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${props => (props.isPublished ? '#6acf47' : '#f0f0f2')};
`;

export const StatusLabel = styled.div`
  ${baseText}
  color: #fff;
  font-size: 10px;
  font-weight: 600;
`;
