import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  padding: 22px 43px 19px;
  display: grid;
  grid-template-columns: 65% 35%;
  border-bottom: 1px solid #eeeeee;
`;

export const HeaderItem = styled.div`
  color: #6a778a;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
`;
