import React, { Component } from 'react';
import get from 'lodash/get';
import clone from 'lodash/clone';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { convertFromRaw, convertToRaw, EditorState, ContentState, Modifier } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import positionSuggestions from 'draft-js-mention-plugin/lib/utils/positionSuggestions';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import Avatar from 'react-avatar';

import {
  getRole,
  formatMentions,
  initData,
  suggestionsFilter,
  getTaggedPeople,
  isTaggedEvery,
} from 'components/CommunityForum/helper';
import { mongoObjectId } from 'utils/commonFunction';

import 'draft-js-mention-plugin/lib/plugin.css';
import * as S from './styles';

const EntryComponent = props => {
  const { mention } = props;
  return (
    <div {...props} className="mention-item">
      <div className="item">
        <Avatar
          className="client-detail-avatar"
          size="24"
          textSizeRatio={1.5}
          name={mention.name}
          src={mention.avatar}
          color={mention.color}
        />
        <div className="name">{mention.name}</div>
        {mention.role > 0 && <div className="role">{getRole(mention)}</div>}
      </div>
    </div>
  );
};

const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });

export default class MentionEditor extends Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true,
      positionSuggestions: this._positionSuggestions,
    });
  }

  state = {
    editorState: this.props.initEditor
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(initData(this.props.initEditor))))
      : EditorState.createEmpty(),
    suggestions: formatMentions(this.props.members),
  };

  componentDidMount() {
    const { callBackFunction, autoFocus } = this.props;

    callBackFunction && callBackFunction(this.resetFunction);
    if (autoFocus) {
      setTimeout(this.editor.focus, 0);
    }
  }

  componentDidUpdate(prevProps) {
    const owner = 'yourself';
    const { replyMessage } = this.props;
    const { replyMessage: prevReplyMessage } = prevProps;

    if (!isUndefined(replyMessage) && replyMessage !== prevReplyMessage) {
      setTimeout(this.editor && this.editor.focus, 0);

      if (
        (!isEmpty(replyMessage) && get(replyMessage, 'owner', '') !== owner) ||
        (!isEmpty(prevReplyMessage) &&
          get(prevReplyMessage, 'owner', '') !== owner &&
          !isEmpty(replyMessage) &&
          get(replyMessage, 'owner', '') === owner)
      ) {
        this.setState({
          editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(initData(this.getMentionReply(replyMessage)))),
          ),
        });
      }
    }
  }

  getMentionReply(replyMessage) {
    const owner = get(replyMessage, 'owner', '');
    const authorId = get(replyMessage, 'authorId', '');

    if (owner === 'yourself') return;

    return {
      content: !isEmpty(replyMessage) && `@${owner} `,
      tagged_people: [
        {
          _id: authorId,
          name: owner,
        },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const rawData = convertToRaw(this.state.editorState.getCurrentContent());
    const { blocks } = rawData;
    const value = blocks
      .map(block => (!block.text.trim() && '\n') || block.text)
      .join('\n')
      .trim();

    const newContent = get(nextProps, 'initEditor.caption', '') || get(nextProps, 'initEditor.content', '');

    if (newContent.trim() !== value && nextProps.readOnly) {
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(initData(nextProps.initEditor)))),
      });
    }
  }

  onChange = editorState => {
    if (this.pressEnter) return;

    this.setState({
      editorState: editorState,
    });

    const { onChange } = this.props;
    onChange && onChange(editorState);
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      const { onSubmit } = this.props;
      this.pressEnter = true;
      onSubmit && onSubmit();
    } else {
      this.pressEnter = false;
    }
  };

  onSearchChange = ({ value, trigger }) => {
    const { members } = this.props;
    const editor = clone(this.state.editorState);
    const taggedIds = getTaggedPeople(editor);
    const ableAddMembers = members.filter(it => !taggedIds.includes(it._id));
    this.setState({
      suggestions: suggestionsFilter(
        value,
        formatMentions(ableAddMembers, this.hasEveryone()),
        ableAddMembers.length + 1,
      ),
    });
  };

  resetFunction = () => {
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState });
    setTimeout(() => {
      this.editor && this.focus();
    }, 500);
    this.pressEnter = false;
  };

  hasEveryone = () => {
    const editor = clone(this.state.editorState);
    const taggedIds = getTaggedPeople(editor);
    return isTaggedEvery(taggedIds);
  };

  onAddMention = data => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  // This method to debug
  renderContentAsRawJs = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const raw = convertToRaw(contentState);

    return JSON.stringify(raw, null, 2);
  };

  _positionSuggestions = ({ decoratorRect, popover, state, props }) => {
    const popoverPosition = (decoratorRect.x || decoratorRect.left) + popover.offsetWidth;
    const { left, top, ...restProps } = positionSuggestions({
      decoratorRect,
      popover,
      state,
      props,
    });
    let adjustedLeft = null;
    if (popoverPosition > window.innerWidth) {
      adjustedLeft = `${parseFloat(left) - (popoverPosition % window.innerWidth)}px`;
    }

    return {
      left: adjustedLeft || left,
      top: `-${popover.offsetHeight}px`,
      ...restProps,
    };
  };

  handleBeforeInput = (chars, editorState) => {
    if (chars === '. ') {
      const currentSelection = editorState.getSelection();
      this.setState({
        editorState: EditorState.set(editorState, {
          currentContent: Modifier.replaceText(editorState.getCurrentContent(), currentSelection, ' '),
        }),
      });
      return 'handled';
    }
    return 'not-handled';
  };

  handlePastedText = (text, __, editorState) => {
    const { onChange } = this.props;

    try {
      const formatText = text.trim().trimAny('↵');
      const contentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        ContentState.createFromText(formatText).getBlockMap(),
      );
      const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
      this.setState({ editorState: newEditorState });

      if (typeof onChange === 'function') {
        onChange(newEditorState);
      }

      return 'handled';
    } catch (error) {}

    return 'not-handled';
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, linkifyPlugin];
    const { placeholder, customClassName = '', readOnly } = this.props;

    return (
      <S.Container onClick={this.focus} className={customClassName} readOnly={readOnly}>
        <S.MentionEditorWrapper>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            editorKey={mongoObjectId()}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            spellCheck={true}
            placeholder={placeholder}
            readOnly={readOnly}
            keyBindingFn={this.handleKeyPress}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePastedText}
          />
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            onAddMention={this.onAddMention}
            entryComponent={EntryComponent}
            open={true}
          />
        </S.MentionEditorWrapper>
      </S.Container>
    );
  }
}
