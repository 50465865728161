import React from 'react';
import classnames from 'classnames';
import { Button, Modal, Image } from 'semantic-ui-react';
import { toggleConfirmModal } from 'actions/modal';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';

export default class ConfirmModal extends React.Component {
  renderIcon() {
    let { noIcon, className } = this.props;
    if (noIcon) {
      return null;
    }
    return (
      <Image
        src={this.props.headerIcon ? this.props.headerIcon : `${CDN_URL}/images/close_circle.svg`}
        className={`confirm-header-image ${className && className}`}
      />
    );
  }

  onClickConfirmButton = () => {
    const { shouldCloseAfterConfirm = true, dispatch, onConfirm } = this.props;
    if (shouldCloseAfterConfirm) {
      dispatch(toggleConfirmModal(false));
    }
    onConfirm();
  };

  renderYesButton() {
    let { disableConfirmButton, components } = this.props;

    if (disableConfirmButton) {
      return null;
    }

    if (components && components.ConfirmButton) {
      return React.cloneElement(components.ConfirmButton, { onClick: this.onClickConfirmButton });
    }

    return (
      <Button
        className={this.props.confirmButtonClass ? this.props.confirmButtonClass : 'confirm-yes-button'}
        onClick={this.onClickConfirmButton}
      >
        {this.props.confirmButtonTitle || 'OK'}
      </Button>
    );
  }

  onClickCancelButton = () => {
    if (!this.props.isNotCloseConfirmModalWhenCancelled) {
      this.props.dispatch(toggleConfirmModal(false));
    }

    if (typeof this.props.onDeny === 'function') {
      this.props.onDeny();
    }
  };

  renderNoButton() {
    let { hideCancelButton, cancelButtonTitle, components } = this.props;

    if (hideCancelButton) {
      return null;
    }

    if (components && components.CancelButton) {
      return React.cloneElement(components.CancelButton, { onClick: this.onClickConfirmButton });
    }

    return (
      <Button className="confirm-no-button" onClick={this.onClickCancelButton}>
        {cancelButtonTitle || 'Cancel'}
      </Button>
    );
  }

  renderSingleActionButton() {
    let { enableSingleActionButton } = this.props;
    if (!enableSingleActionButton) {
      return null;
    }
    return (
      <Button
        className="confirm-single-button"
        onClick={() => {
          this.props.dispatch(toggleConfirmModal(false));
          this.props.onAction();
        }}
      >
        {this.props.actionButton || 'Close'}
      </Button>
    );
  }

  onClose = () => {
    this.props.dispatch(toggleConfirmModal(false));
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { className, newStyle, largeSpacing, hasCloseIcon, hasHoverState, modalId } = this.props;

    return (
      <Modal
        id={modalId}
        open={this.props.isConfirmModalOpen}
        className={classnames('confirm-popup-container', className, {
          'new-style': newStyle,
          largeSpacing: largeSpacing,
          'has-hover-state': hasHoverState,
        })}
        closeOnDimmerClick={this.props.isCloseOnDimmer}
        onClose={this.props.isPressEsc && this.onClose}
      >
        <Modal.Content className="confirm-content">
          {hasCloseIcon ? (
            <Button onClick={this.onClose} className="close-button">
              <Image src={`${CDN_URL}/images/close_circle.svg`} />
            </Button>
          ) : null}
          <div className="confirm-content-header">
            {this.renderIcon()}

            <p className="confirm-header-label">{this.props.title || 'Discard Changes'}</p>
          </div>
          <div className="confirm-content-body">
            <div className="confirm-content-label">
              {this.props.content || 'Are you sure you want to go? Changes have not been saved yet.'}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions className={`confirm-actions ${this.props.noBorder ? 'no-border' : ''} `}>
          {this.renderNoButton()}
          {this.renderYesButton()}
          {this.renderSingleActionButton()}
        </Modal.Actions>
      </Modal>
    );
  }
}
