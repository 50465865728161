export const columns = [
  {
    key: 'name_lowercase',
    title: 'Onboarding Flows',
    sortable: true,
    sortKey: 'name_lowercase',
    icon: null,
  },
  {
    key: 'total_invite_codes',
    title: 'Invite Links Created',
    sortable: false,
    sortKey: 'total_invite_codes',
    icon: null,
  },
  {
    key: 'total_packages',
    title: 'Package Attached',
    sortable: false,
    sortKey: 'total_packages',
    icon: null,
  },
  {
    key: 'updated_at',
    title: 'Last Updated',
    sortable: true,
    sortKey: 'updated_at',
    icon: null,
  },
  {
    key: 'actions',
    title: '',
    sortable: false,
    sortKey: null,
    icon: null,
  },
];

export const defaultOnboardingFlowColumns = [
  {
    key: 'coach_name',
    title: 'Coach name',
    sortable: true,
    sortKey: 'coach_name',
    icon: null,
  },
  {
    key: 'onboarding_flow',
    title: 'Onboarding Flow',
    sortable: false,
    sortKey: null,
    icon: null,
  },
];
