import React from 'react';

import { ReactComponent as AllIcon } from 'assets/icons/program_all.svg';
import { ReactComponent as StrengthTrainingIcon } from 'assets/icons/program_strength_training.svg';
import { ReactComponent as CardiovascularIcon } from 'assets/icons/program_cardiovascular.svg';
import { ReactComponent as HIITIcon } from 'assets/icons/program_hiit.svg';
import { ReactComponent as SpeedPowerIcon } from 'assets/icons/program_speed_power.svg';
import { ReactComponent as WeightLossIcon } from 'assets/icons/program_weight_loss.svg';
import { ReactComponent as MindBodyIcon } from 'assets/icons/program_mind_body.svg';
import { ReactComponent as CrossfitIcon } from 'assets/icons/program_crossfit.svg';
import { ReactComponent as RecoveryTrainingIcon } from 'assets/icons/program_recovery_training.svg';
import { ReactComponent as WeekendWarriorIcon } from 'assets/icons/program_weekend_warrior.svg';
import { ReactComponent as YouthAdolescentIcon } from 'assets/icons/program_youth_adolescent.svg';
import { ReactComponent as TimedIcon } from 'assets/icons/program_timed.svg';
import { ReactComponent as FreestyleIcon } from 'assets/icons/program_freestyle.svg';
import { ReactComponent as IntervalIcon } from 'assets/icons/program_interval.svg';

export const FILTER_CATEGORIES = [
  {
    key: 'all',
    label: 'All',
    icon: <AllIcon />,
  },
  {
    key: 'strength_hypertrophy',
    label: 'Strength / Hypertrophy',
    icon: <StrengthTrainingIcon />,
  },
  {
    key: 'cardiovascular_training',
    label: 'Cardiovascular Training',
    icon: <CardiovascularIcon />,
  },
  {
    key: 'hiit',
    label: 'HIIT',
    icon: <HIITIcon />,
  },
  // {
  //   key: 'speed_power',
  //   label: 'Speed / Power',
  //   icon: <SpeedPowerIcon />,
  // },
  // {
  //   key: 'weight_loss',
  //   label: 'Weight Loss',
  //   icon: <WeightLossIcon />,
  // },
  // {
  //   key: 'mind_body',
  //   label: 'Mind-Body',
  //   icon: <MindBodyIcon />,
  // },
  // {
  //   key: 'crossfit',
  //   label: 'Crossfit',
  //   icon: <CrossfitIcon />,
  // },
  {
    key: 'recovery_training',
    label: 'Recovery Training',
    icon: <RecoveryTrainingIcon />,
  },
  // {
  //   key: 'weekend_warrior',
  //   label: 'Weekend Warrior',
  //   icon: <WeekendWarriorIcon />,
  // },
  // {
  //   key: 'youth_adolescent',
  //   label: 'Youth/Adolescent',
  //   icon: <YouthAdolescentIcon />,
  // },
];

// export const SORT_SWITCH_OPTIONS = [
//   {
//     key: 'all',
//     label: 'All',
//   },
//   {
//     key: 'most_popular',
//     label: 'Most popular',
//   },
// ];

// export const TIME_SWITCH_OPTIONS = [
//   {
//     key: 'week',
//     label: 'Week',
//   },
//   {
//     key: 'month',
//     label: 'Month',
//   },
//   {
//     key: 'all_time',
//     label: 'All time',
//   },
// ];

export const SORT_OPTIONS = [
  {
    key: 'title',
    label: 'Alphabetical',
  },
  // {
  //   key: 'released_at',
  //   label: 'Release date',
  // },
  {
    key: 'popularity',
    label: 'Popularity',
  },
];

export const EXPERIENCE_OPTIONS = [
  {
    key: 'all_level',
    label: 'All Levels',
  },
  {
    key: 'beginner',
    label: 'Beginner',
  },
  {
    key: 'intermediate',
    label: 'Intermediate',
  },
  {
    key: 'advanced',
    label: 'Advanced',
  },
];

export const DURATION_OPTIONS = [
  {
    key: '1-2',
    label: '1-2 weeks',
  },
  {
    key: '3-4',
    label: '3-4 weeks',
  },
  {
    key: '5-6',
    label: '5-6 weeks',
  },
  {
    key: '7-8',
    label: '7-8 weeks',
  },
  {
    key: '9',
    label: '9+ weeks',
  },
];

const getRandomNumber = () => Math.random() * 100000;

export const formatNumberWithShortcut = number => {
  if (number === null || number === undefined || number === '') {
    return '';
  }

  const shortcuts = ['', 'k', 'M', 'B', 'T', 'Q', 'Qn', 'S', 'Sp'];
  let index = 0;
  let num = parseFloat(number);

  if (isNaN(num)) {
    return '';
  }

  while (num >= 1000) {
    num /= 1000;
    index++;
  }

  const formattedNumber = Number.isInteger(num) ? num : num.toFixed(0);

  return `${formattedNumber}${shortcuts[index]}`;
};

const formatShorter = (num, divide, unit) => {
  const newNum = (num / divide).toFixed(7);
  const val = newNum.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
  return Number(val) + unit;
};

export const shortNumberToFloor = num => {
  if (num < 1000) return num;

  if (num < 1000000) {
    return formatShorter(num, 1000, 'K');
  }

  return formatShorter(num, 1000000, 'M');
};

export const WORKOUT_ICONS = {
  timed: <TimedIcon />,
  interval: <IntervalIcon />,
  freestyle: <FreestyleIcon data-tip data-for={`workout-freestyle`} className="custom-program-freestyle" />,
};

export const FILTER_TYPE_KEYS = {
  MODALITIES: 'modalities',
  LEVELS: 'levels',
  WORKOUT_DAYS_PER_WEEK: 'workout_days_per_weeks',
  DURATIONS: 'durations',
  PROGRAM_LENGTH: 'number_of_weeks',
};

export const FILTER_TYPES = [
  { key: FILTER_TYPE_KEYS.MODALITIES, label: 'Modality' },
  { key: FILTER_TYPE_KEYS.LEVELS, label: 'Experience Level' },
  { key: FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK, label: 'Workout Days Per Week' },
  // { key: FILTER_TYPE_KEYS.DURATIONS, label: 'Duration' },
  { key: FILTER_TYPE_KEYS.PROGRAM_LENGTH, label: 'Program Duration' },
];

export const DEFAULT_FILTERS_POPUP = {
  [FILTER_TYPE_KEYS.MODALITIES]: [],
  [FILTER_TYPE_KEYS.LEVELS]: '',
  [FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK]: [],
  [FILTER_TYPE_KEYS.DURATIONS]: [],
  [FILTER_TYPE_KEYS.PROGRAM_LENGTH]: [],
};

export const DEFAULT_GROUP_OPTIONS = {
  [FILTER_TYPE_KEYS.MODALITIES]: true,
  [FILTER_TYPE_KEYS.LEVELS]: true,
  [FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK]: true,
  [FILTER_TYPE_KEYS.DURATIONS]: true,
  [FILTER_TYPE_KEYS.PROGRAM_LENGTH]: true,
};

export const DEFAULT_MODALITIES_VALUES = [
  {
    key: 'strength_hypertrophy',
    label: 'Strength / Hypertrophy',
  },
  {
    key: 'cardiovascular_training',
    label: 'Cardiovascular Training',
    desc: 'Running, Swimming, Cycling',
  },
  {
    key: 'hiit',
    label: 'HIIT',
    desc: 'Circuits, Crossfit WOD’s, Group Fitness, Bootcamps',
  },
  // {
  //   key: 'speed_power',
  //   label: 'Speed / Power',
  //   desc: 'Sprints, Jumping, Plyometrics',
  // },
  {
    key: 'recovery_training',
    label: 'Recovery Training',
    desc: 'Stretching, Self Myofascial Release, Corrective Exercises, PRI',
  },
  // {
  //   key: 'mind_body',
  //   label: 'Mind-Body',
  //   desc: 'Yoga, Pilates, Tai Chi, Meditation, Breathwork',
  // },
  // {
  //   key: 'combination',
  //   label: 'Combination',
  //   desc: 'Programs with more than one modality',
  // },
];

export const DEFAULT_DURATIONS = [
  {
    key: '0-20',
    label: '0 - 20 min',
  },
  {
    key: '20-40',
    label: '20 - 40 min',
  },
  {
    key: '40-60',
    label: '40 - 60 min',
  },
  {
    key: '60',
    label: '60+ min',
  },
];

export const DEFAULT_LEVELS = [
  {
    key: 'all_level',
    label: 'All Levels',
  },
  {
    key: 'beginner',
    label: 'Beginner',
  },
  {
    key: 'intermediate',
    label: 'Intermediate',
  },
  {
    key: 'advanced',
    label: 'Advanced',
  },
];

export const DEFAULT_WORKOUT_DAYS_PER_WEEK = [
  {
    key: 1,
    label: '1',
  },
  {
    key: 2,
    label: '2',
  },
  {
    key: 3,
    label: '3',
  },
  {
    key: 4,
    label: '4',
  },
  {
    key: 5,
    label: '5',
  },
  {
    key: 6,
    label: '6',
  },
  {
    key: 7,
    label: '7',
  },
];

export const DEFAULT_PROGRAM_LENGTH = [
  {
    key: '1-2',
    label: '1-2 weeks',
  },
  {
    key: '3-4',
    label: '3-4 weeks',
  },
  {
    key: '5-6',
    label: '5-6 weeks',
  },
  {
    key: '7-8',
    label: '7-8 weeks',
  },
  {
    key: '9',
    label: '9+ weeks',
  },
];

export const LOADING_DATA = { speed: 0, bgColor: '#f5f6f7', bgOpacity: 1 };

export const convertToTitleCase = str => str.split('_').join(' ');
