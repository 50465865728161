import React, { useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Checkbox, Toggle } from 'shared/FormControl';
import { TRIAL_PERIOD } from '../../constants';

import { DropdownIndicator } from 'shared/Icons';
import { ReactComponent as SettingIcon } from 'assets/icons/setting-trial-period.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';

import * as S from './styles';

const INIT_TIME = TRIAL_PERIOD[1]; // Default 7 days
const formatOption = number => {
  return TRIAL_PERIOD.find(it => it.value === number) || INIT_TIME;
};

const TrialSetting = props => {
  const { onChangeTrial, onChangeToggle, currentData, isOnetime } = props;
  const { trial_period, enable_trial, is_archive_on_trial_cancellation = true, allow_trial_once = true } = currentData;

  const initArchiveClient = () => {
    if (!enable_trial) return false;
    return is_archive_on_trial_cancellation;
  };

  const initTrialOnce = () => {
    if (!enable_trial) return false;
    return allow_trial_once;
  };

  const [openedSetting, setOpenedSetting] = useState(enable_trial);
  const [checkedArchiveClient, setCheckedArchiveClient] = useState(initArchiveClient);
  const [checkedTrialOnce, setCheckedTrialOnce] = useState(initTrialOnce);
  const [selectedTime, setSelectedTime] = useState(formatOption(trial_period));

  const handleSelectTime = newTime => {
    setSelectedTime(newTime);
    onChangeTrial({ time: newTime.value, isArchiveClient: checkedArchiveClient, isTrialOnce: checkedTrialOnce });
  };

  const handleOpened = () => {
    const status = !openedSetting;
    setOpenedSetting(status);
    onChangeToggle(status);
    setCheckedArchiveClient(status);
    setCheckedTrialOnce(status);
    onChangeTrial({ time: trial_period || INIT_TIME.value, isArchiveClient: status, isTrialOnce: status });

    if (status) {
      setSelectedTime(formatOption(trial_period));
    }
  };

  const handleCheckArchive = () => {
    const status = !checkedArchiveClient;
    setCheckedArchiveClient(status);
    onChangeTrial({ time: selectedTime.value, isArchiveClient: status, isTrialOnce: checkedTrialOnce });
  };

  const handleCheckedTrialOnce = () => {
    const status = !checkedTrialOnce;
    setCheckedTrialOnce(status);
    onChangeTrial({ time: selectedTime.value, isTrialOnce: status, isArchiveClient: checkedArchiveClient });
  };

  return (
    <S.Wrapper isOnetime={isOnetime}>
      <S.Label>
        <SettingIcon /> <S.LabelText>Advanced Settings</S.LabelText>
      </S.Label>
      <S.Content isOpen={openedSetting}>
        <Toggle
          className="toggle-trial"
          disabled={false}
          checked={openedSetting}
          onChange={handleOpened}
          width={36}
          height={20}
          label={
            <div className="text-content">
              <span>Allow Trial Period</span>
              <TooltipIcon data-tip data-for="tooltip--trial-setting" />
              <ReactTooltip className="app-tooltip" id="tooltip--trial-setting" effect="solid">
                Payment will be charged at the end of the trial. If payment fails, subscription and sequences will be
                automatically cancelled.
              </ReactTooltip>
            </div>
          }
        />
        <S.SelectWrapper>
          {openedSetting && (
            <Select
              options={TRIAL_PERIOD}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              onChange={handleSelectTime}
              classNamePrefix="evfSelectBox"
              className="evfSelectBoxContainer"
              value={selectedTime}
              isSearchable={false}
              menuPosition="fixed"
              styles={{
                control: base => ({ ...base, minHeight: 40, height: 40, width: 108 }),
              }}
            />
          )}
        </S.SelectWrapper>
      </S.Content>
      {openedSetting && (
        <S.TrialSettingContent>
          <S.ArchiveClient>
            <Checkbox
              onChange={handleCheckArchive}
              className="archive-client-checkbox"
              checked={checkedArchiveClient}
            />
            <S.ArchiveClientDesc onClick={handleCheckArchive}>
              Archive client when cancelling a package within the trial period
            </S.ArchiveClientDesc>
          </S.ArchiveClient>
          <S.ArchiveClient>
            <Checkbox
              onChange={handleCheckedTrialOnce}
              className="archive-client-checkbox"
              checked={checkedTrialOnce}
            />
            <S.ArchiveClientDesc onClick={handleCheckedTrialOnce}>
              Allow client to sign up for a trial <S.BoldText>once</S.BoldText>
            </S.ArchiveClientDesc>
          </S.ArchiveClient>
        </S.TrialSettingContent>
      )}
    </S.Wrapper>
  );
};

export default TrialSetting;
