// Libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

// Actions
import { getOnboardingFlowSettings } from 'redux/onboarding-flow/actions';

// Components
import AssetSelect from '../AssetSelect';
import InfoTooltipIcon from 'shared/InfoTooltipIcon';
import InviteLinkOverview from './InviteLinkOverview';
import SetAsDefaultOverview from './SetAsDefaultOverview';
import AttachPackageOverview from './AttachPackageOverview';

// Styles
import * as S from './style';

const Settings = ({ user, onboardingFlowId, getOnboardingFlowSettings, listTrainer, permission, disabled }) => {
  const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

  const [settingValue, setSettingValue] = useState({ _id: 0, label: 'All new clients' });

  useEffect(() => {
    if (onboardingFlowId && typeof getOnboardingFlowSettings == 'function' && hasPermissionMultiple) {
      getOnboardingFlowSettings(onboardingFlowId);
    }
  }, [onboardingFlowId]);

  const isEnabledSetAsDefault = listTrainer.some(({ _id }) => _id === (user || {})._id);

  return (
    <S.Wrapper isPermissionAutoflow={hasPermissionMultiple}>
      {!hasPermissionMultiple && (
        <S.Content>
          <S.Card disabled={disabled}>
            <S.Container>
              <S.CardTitleWrapper>
                <S.CardTitle>Assign to Clients</S.CardTitle>
                {!disabled && (
                  <InfoTooltipIcon
                    tooltipId="assign-client-setting"
                    size="16px"
                    maxWidthContent="259px"
                    message="Select the type of invitations to assign to clients to the onboarding flow."
                    className="assign-client-tooltip"
                  />
                )}
              </S.CardTitleWrapper>
              {disabled ? (
                <S.SettingValue>{settingValue.label}</S.SettingValue>
              ) : (
                <AssetSelect disabled={true} value={settingValue} classNamePrefix="off" onSelect={() => {}} />
              )}
            </S.Container>
          </S.Card>
        </S.Content>
      )}
      {hasPermissionMultiple && (
        <>
          <SetAsDefaultOverview isEnabled={isEnabledSetAsDefault} />
          <InviteLinkOverview isEnabled={isEnabledSetAsDefault} />
          <AttachPackageOverview isEnabled={isEnabledSetAsDefault} />
        </>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      onboardingFlow: { workingSetting, isLoadingSetting },
      permission,
    },
  } = state;

  return {
    user,
    listTrainer: get(workingSetting, 'trainer_default_settings', []),
    workingSetting,
    isLoadingSetting,
    permission,
  };
};

const mapDispatchToProps = dispatch => ({
  getOnboardingFlowSettings: bindActionCreators(getOnboardingFlowSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
