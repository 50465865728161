import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExerciseLibrary from 'components/ExerciseLibrary';
import { MODES } from 'components/ExerciseForm/component';

export default () => (
  <Switch>
    <Route exact path="/home/exercise/" render={(props) => <ExerciseLibrary {...props} />} />
    <Route
      exact
      path="/home/exercise/:exerciseId?/"
      render={(props) => <ExerciseLibrary {...props} mode={MODES.UPDATE} />}
    />
    <Route
      exact
      path="/home/exercise/:exerciseId?/clone"
      render={(props) => <ExerciseLibrary {...props} mode={MODES.CLONE} />}
    />
  </Switch>
);
