import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Image, Loader, Modal } from 'semantic-ui-react';

import useScrollTop from 'hooks/useScrollTop';
import { exportOnboardingResponsesPdf } from 'redux/form-documents/actions';
import { ReactComponent as IconPrint } from 'assets/icons/print_pdf.svg';
import { RESPONSE_TYPES } from './components/constants';
import { CDN_URL } from 'constants/commonData';
import {
  DateTimeResponse,
  LongAnswerResponse,
  MetricResponse,
  MultiChoiceResponse,
  SignatureResponse,
  SingleChoiceResponse,
} from './components';

import * as S from './style';

const ResponsesFormModal = props => {
  const { onClose, exportOnboardingResponsesPdf, loadingPrint, data, selectedClient, date_format, timezone } = props;
  const { title = '', onboarding_form_submitted_at = '', questions = [] } = data;
  const [scrollTop, scrollProps] = useScrollTop();
  let numberOfQuestion = 0;
  let count = 0;

  const handlePrintPdf = () => {
    exportOnboardingResponsesPdf && exportOnboardingResponsesPdf(get(data, '_id', ''));
  };

  const renderLoadingPrint = () => {
    return (
      <S.LoadingWrapper>
        <Loader active inline="centered" className="custom-loading-print" size="tiny" />
      </S.LoadingWrapper>
    );
  };

  const renderTitle = (title, numberOfQuestion) => {
    return (
      <S.TitleQuestion>
        {numberOfQuestion}.&nbsp;{title}
      </S.TitleQuestion>
    );
  };

  const renderAnswerByType = answer => {
    switch (answer.type) {
      case RESPONSE_TYPES.DATE_TIME:
        return <DateTimeResponse answer={answer} date_format={date_format} />;
      case RESPONSE_TYPES.METRIC:
        return <MetricResponse answer={answer} />;
      case RESPONSE_TYPES.SINGLE_CHOICE:
        return <SingleChoiceResponse answer={answer} />;
      case RESPONSE_TYPES.MULTIPLE_CHOICE:
        return <MultiChoiceResponse answer={answer} />;
      case RESPONSE_TYPES.LONG_ANSWER:
        return <LongAnswerResponse answer={answer} />;
      case RESPONSE_TYPES.SIGNATURE:
        return <SignatureResponse answer={answer} />;
      default:
        return null;
    }
  };

  const handleCheckCurrentYear = date => {
    if (!date) {
      return '';
    }
    const currentYear = moment().year();

    return moment(date).year() === currentYear
      ? moment(date).tz(timezone).format('MMM DD - h:mm A')
      : moment(date).tz(timezone).format('MMM DD, YYYY - h:mm A');
  };

  return (
    <S.CustomModal
      open={true}
      className={classNames('responses-form-modal', { scroll: scrollTop > 0 })}
      onClose={onClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Button onClick={onClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} alt="close icon" />
        </Button>
        <S.HeaderWrapper className="header-wrapper">
          <S.HeaderTitle>{title}</S.HeaderTitle>
          <S.HeaderRight className="header-right">
            <S.InforWrapper>
              <S.ClientName>{get(selectedClient, 'full_name', '')}</S.ClientName>
              <S.Datetime>{handleCheckCurrentYear(onboarding_form_submitted_at)}</S.Datetime>
            </S.InforWrapper>
            <S.ButtonPrintPdf onClick={handlePrintPdf} disabled={loadingPrint} isLoading={loadingPrint}>
              {loadingPrint ? renderLoadingPrint() : <IconPrint />}
            </S.ButtonPrintPdf>
          </S.HeaderRight>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentContainer {...scrollProps} isScrollTop={scrollTop > 0}>
          {questions &&
            questions.map(question => {
              const { title = '' } = question;
              if (get(question, 'is_answered')) {
                count++;
                numberOfQuestion = count;
              } else {
                return null;
              }
              return (
                <S.QuestionFormWrapper>
                  {renderTitle(title, numberOfQuestion)}
                  {renderAnswerByType(question)}
                </S.QuestionFormWrapper>
              );
            })}
        </S.ContentContainer>
      </Modal.Content>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      formDocuments: { loadingPrint },
      client: { workingClientDetail },
    },
  } = state;
  return {
    loadingPrint,
    selectedClient: workingClientDetail,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    exportOnboardingResponsesPdf: bindActionCreators(exportOnboardingResponsesPdf, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesFormModal);
