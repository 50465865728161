import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';
import { ErrorMessage, FormGroup } from 'shared/FormControl';

const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6A778A;
  margin-bottom: 5px;

  .label__required {
    color: #EA314A;
  }
`;

const FormControl = styled(FormGroup)`

  &.error {
    input:hover:not(:disabled), input:focus {
      border-color: #EA314A;
    }
  }
`

export default class FormItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInvalidMsg = () => {
    const { rules = [], required, value } = this.props;
    let msg = '';
    if (required && _.isEmpty(value)) {
      return 'This field cannot be empty';
    }
    _.forEach(rules, rule => {
      if (rule.regex && !rule.regex.test(value)) {
        msg = rule.message;
        return false;
      }
      if (!rule.valid) {
        msg = rule.message;
        return false;
      }
    });
    return msg;
  }

  render() {
    const { label, value, onChange, required, placeholder, isSubmited, attribute } = this.props;
    const errorMsg = this.getInvalidMsg();
    return (
      <FormControl data-form-control={attribute} className={classNames({'error': isSubmited && !!errorMsg})}>
        <Label data-label={attribute}>
          {label}
          {required && isSubmited && _.isEmpty(value) ? (
            <span>&nbsp;(<span className="label__required">*</span>)</span>
          ) : null}
        </Label>
        <input
          data-input={attribute}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        {!!isSubmited && <div style={{marginTop: '5px'}}>
          <ErrorMessage data-message-error={attribute}>{errorMsg}</ErrorMessage>
        </div>}
      </FormControl>
    )
  }
}