import { fromJS } from 'immutable';
import { Types } from './actions';

const INITIAL_STATE = {
  isSearching: true,
  workingStudio: null,
  list: [],
  total: 0,
  query: {
    page: 1,
    per_page: 32,
    search: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.STUDIO_RESOURCES_LOADING_DATA:
      return Object.assign({}, state, { isSearching: true });

    case Types.STUDIO_RESOURCES_FAILED_GET_LIST:
      return Object.assign({}, state, { isSearching: false });

    case Types.STUDIO_RESOURCES_SUCCESS_GET_LIST:
      return Object.assign({}, state, {
        isSearching: false,
        total: payload.total,
        list: payload.data,
        query: {
          ...state.query,
          page: 1,
        },
      });

    case Types.STUDIO_RESOURCES_SUCCESS_LOAD_MORE_LIST:
      return Object.assign({}, state, {
        isSearching: false,
        total: payload.total,
        list: [...state.list, ...payload.data],
        query: {
          ...state.query,
          page: payload.page,
        },
      });

    case Types.STUDIO_RESOURCES_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...payload.data } });

    case Types.STUDIO_RESOURCES_LIST_RESET_DATA:
      return Object.assign({}, state, { isSearching: false, query: INITIAL_STATE.query, list: [], total: 0 });

    case Types.STUDIO_RESOURCE_SUCCESS_DELETE_RESOURCE:
      return Object.assign({}, state, {
        list: state.list.filter(o => o._id !== payload.data.resourceId),
        total: state.total - 1,
      });

    case Types.STUDIO_RESOURCE_SUCCESS_GET_DETAIL:
      return Object.assign({}, state, {
        workingStudio: payload.data,
      });

    case Types.STUDIO_RESOURCE_SUCCESS_UPDATE:
      return Object.assign({}, state, {
        workingStudio: {
          ...state.workingStudio,
          ...payload.data,
        },
      });

    case Types.STUDIO_RESOURCE_UPDATE_REDUX: {
      return Object.assign({}, state, {
        list: state.list.map(item => {
          if (item._id === payload.data._id) {
            item = fromJS(payload.data);
          }
          return item;
        }),
      });
    }

    case Types.STUDIO_RESOURCE_RESET_DETAIL_DATA:
      return Object.assign({}, state, {
        workingStudio: null,
      });

    case Types.STUDIO_RESOURCE_CHANGE_SHARE_STATUS: {
      return Object.assign({}, state, {
        list: payload || state.list,
      });
    }

    default:
      return state;
  }
};
