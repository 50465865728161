import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    -webkit-transform:scale(1);
  }
  20% {
    -webkit-transform:scale(1.75);
    opacity: 0.4;
  }
  40% { 
    -webkit-transform:scale(2.5);
  }
  60% { 
    -webkit-transform:scale(3.75);
    opacity: 0.2;
  }
  80% { 
    -webkit-transform:scale(5);
    opacity: 0
  }
  100% {
    opacity: 0;
  }
`;

const getPrefixPositionStyles = prefixPosition => {
  switch (prefixPosition) {
    case 'bottom':
      return css`
        top: -6px;
        border-top: 1px solid #dbddeb;
        border-left: 1px solid #dbddeb;
      `;
    case 'top':
      return css`
        bottom: -3px;
        border-bottom: 1px solid #dbddeb;
        border-right: 1px solid #dbddeb;
      `;
    default:
      return css`
        top: -9px;
        border-top: 1px solid #dbddeb;
        border-left: 1px solid #dbddeb;
      `;
  }
};

const getSuffixPositionStyles = suffixPosition => {
  switch (suffixPosition) {
    case 'start':
      return css`
        left: 35%;
      `;
    case 'end':
      return css`
        right: 26%;
      `;
    default:
      return css`
        left: 50%;
      `;
  }
};

export const TooltipBody = styled.div`
  position: relative;
  width: 317px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 15px;
  padding: 15px 15px 18px;
  border: 1px solid #dbddeb;
  border-radius: 5px;
  background-color: #fff;

  &::before {
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    transform-origin: 0% 0%;
    transform: rotate(45deg);
    background: #fff;

    ${({ prefixPosition }) => getPrefixPositionStyles(prefixPosition)}
    ${({ suffixPosition }) => getSuffixPositionStyles(suffixPosition)}
  }
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #000;
`;

export const TooltipContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #000;

  .bold {
    font-weight: 700;
  }

  .italic {
    font-style: italic;
  }
`;

export const TooltipFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Button = styled.button`
  margin-top: -1px;
  outline: none;
  border: none;
  padding: unset;
  background-color: transparent;
  cursor: pointer;
`;

export const FormattedMessage = styled.span`
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  color: #7470ef;
`;

export const Anchor = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ff8a00;
`;

export const Aura = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff8a00;
  left: 0;
  top: 0;
  animation: ${pulse} 1s infinite cubic-bezier(0.4, 0, 1, 1);
`;
