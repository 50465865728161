import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import ImportIngredients from './component';
import { toggleConfirmModal, toggleSecondModal, toggleModal } from 'actions/modal';
import { addIngredientByCSV, getIngredients } from 'redux/ingredient-library/actions';
import { getListOtherNutrient } from 'redux/recipes/actions';

const mapStateToProps = state => {
  const {
    isModalOpen,
    user,
    cloudfrontList,
    rootReducer: { permission, recipes, ingredientLibrary },
  } = state;

  return {
    isModalOpen,
    user,
    cloudfrontList,
    permission,
    categoryIngredient: get(recipes, 'ingredient.category', []),
    unitIngredient: get(ingredientLibrary, 'listUnitIngredient', {}),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  addIngredientByCSV: bindActionCreators(addIngredientByCSV, dispatch),
  getIngredients: bindActionCreators(getIngredients, dispatch),
  getListOtherNutrient: bindActionCreators(getListOtherNutrient, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportIngredients);
