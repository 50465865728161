import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getSetItemDescription } from 'helpers/exercise';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import ReactTooltip from 'react-tooltip';

const SetItem = props => {
  const { itm, setFields, exercise, hasOneRepMax, index, isMissedWorkout } = props;
  const hasPersonalBest = itm.records && itm.records.length > 0;
  const isMissed = isMissedWorkout || !itm.is_completed;
  let oneRepMax = '';

  if (hasOneRepMax) {
    const weight = parseFloat(_.get(itm, 'weight.value', 0));
    const reps = parseFloat(_.get(itm, 'reps.value', 0));
    if (reps === 1) {
      oneRepMax = Math.round(Number(weight));
    } else {
      oneRepMax = Math.round(Number(weight) * (1 + Number(reps) / 30));
    }
  }

  const itemDescription = getSetItemDescription(itm, props.unit, false, setFields, props.fields);

  return (
    <S.TrackedSetItem key={itm._id} className={classNames({ 'personal-cup': hasPersonalBest, 'missed-set': isMissed })}>
      <div className="tracked-set">
        <div className="tracked-set__no-sequence">{`${_.padStart(index + 1, 2, 0)}.`}</div>
        <div className="tracked-set__value">
          <div className="tracked-set__value--des">{itemDescription}</div>
          {itm.is_completed && hasPersonalBest ? (
            <React.Fragment>
              <div className="personal-best" data-tip data-for={itm._id}>
                {itm.records.map((item, index) => (
                  <img key={index} src={`${CDN_URL}/images/pbsmall.svg`} alt="pbsmall" />
                ))}
              </div>
              <ReactTooltip className="app-tooltip-white" id={itm._id} effect="solid" place={'top'} delayShow={100}>
                <div className="personal-best-info">
                  <div className="exercise__title">{_.get(exercise, 'title')}</div>
                  {_.map(itm.records, (record, index) => (
                    <div key={index} className="item">
                      <div className="name">
                        <img key={index} src={`${CDN_URL}/images/pbsmall.svg`} alt="pbsmall" />
                        <div>{record.type === '1rm' ? '1RM' : `Max ${record.type}`}</div>
                      </div>
                      <div className="value">
                        {record.value || 0} {record.type === 'reps' ? 'reps' : _.get(record, 'set_unit.title')}
                      </div>
                    </div>
                  ))}
                </div>
              </ReactTooltip>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {isMissed ? (
        <div className="missed-label">Missed</div>
      ) : (
        <div className="tracked-set__one-rep-max">{oneRepMax}</div>
      )}
    </S.TrackedSetItem>
  );
};

const ExerciseItem = props => {
  const { fields, set, setIndex } = props;
  const isSuperSets = set.supersets.length > 1;
  const repsField = fields.find(item => item.unique_code === 'reps');
  const weightField = fields.find(item => item.unique_code === 'weight');

  return (
    <S.Supersets key={set._id || setIndex} className={classNames('supersets-wrapper', { 'is-supersets': isSuperSets })}>
      {isSuperSets && <div className="superset-label">SUPERSET</div>}
      <S.Exercises className="exericses">
        {_.map(set.supersets, item => {
          const exercise = item.exercise || item.exercise_instance;
          const { exercise_instance } = item;
          const exTitle = _.get(item, 'exercise_instance.title', '');
          let hasOneRepMax = false;

          if (exercise_instance && repsField && weightField) {
            hasOneRepMax =
              _.get(item, 'exercise.category_type_name') === 'Strength' &&
              exercise_instance.fields.includes(repsField._id) &&
              exercise_instance.fields.includes(weightField._id);
          }
          if (!exercise) {
            return null;
          }

          return (
            <S.Exercise className="exercise" key={item._id}>
              <S.ExerciseBaseInfo>
                <S.ExerciseTitle>{exTitle}</S.ExerciseTitle>
                <S.OneRepMax>{hasOneRepMax && '1RM'}</S.OneRepMax>
              </S.ExerciseBaseInfo>
              <S.ExerciseSummary>
                {_.map(item.training_sets, (exSet, index) => (
                  <SetItem
                    key={exSet._id}
                    index={index}
                    itm={exSet}
                    setFields={item.exercise_instance.fields}
                    exercise={item.exercise}
                    hasOneRepMax={hasOneRepMax}
                    unit={props.unit}
                    fields={props.fields}
                    isMissedWorkout={props.isMissedWorkout}
                  />
                ))}
              </S.ExerciseSummary>
            </S.Exercise>
          );
        })}
      </S.Exercises>
    </S.Supersets>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: {
      exercise: { fields },
      client: { workingClientDetail },
    },
  } = state;

  return {
    fields,
    preferences: _.get(workingClientDetail, 'feature_preferences'),
    unit: _.get(workingClientDetail || user, 'preferences', []),
  };
};

export default connect(mapState, null)(ExerciseItem);
