import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';

import ConfirmModal from 'shared/ConfirmModal/component';
import { CheckboxCircle } from 'shared/FormControl';
import { CDN_URL, PACKAGE_PRICING_TYPES, PURCHASE_STATUS } from 'constants/commonData';
import { getTrialLeftDays, pluralize } from 'utils/commonFunction';

import * as S from './styles';

const CancelSubscriptionPopup = props => {
  const { dispatch, isConfirmModalOpen, onConfirm, item, packageDetail } = props;

  const [cancelType, setCancelType] = useState('immediately');

  const isOneTimePurchase = useMemo(() => {
    return get(packageDetail, 'price.type') === PACKAGE_PRICING_TYPES.one_time.value;
  }, [packageDetail]);

  const isTrialPackage = useMemo(() => {
    return get(item, 'status', '').toLowerCase() === PURCHASE_STATUS.TRIAL;
  }, [item]);

  const handleOnChange = value => {
    setCancelType(value);
  };

  const getNextInvoice = purchase => {
    if (purchase.next_invoice) return purchase.next_invoice;
    return renderNextInvoice(purchase);
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      const cancel_at = cancelType === 'immediately' || isOneTimePurchase ? moment().format() : getNextInvoice(item);
      onConfirm({
        id: item.id,
        cancel_at,
      });
    }
  };

  const clientFullName = useMemo(() => {
    return get(item, 'customer.full_name') || get(item, 'client.full_name');
  }, [item]);

  const titlePopup = isTrialPackage
    ? `Cancel ${clientFullName}’s Trial?`
    : isOneTimePurchase
    ? 'Mark Purchase as Cancelled?'
    : `Cancel ${clientFullName}’s Subscription?`;

  const messagePopup = isOneTimePurchase
    ? 'This will mark the purchase as Cancelled. But since the purchase is already final, this will have no effect on your client. Your client will not be notified of the change.'
    : 'Your client will receive an email notice for the upcoming change. Another email will be sent on the date of cancellation.';

  const renderNextInvoice = purchase => {
    const today = moment(new Date());
    const every = get(purchase, 'price.every', 0);
    const type = get(purchase, 'price.recurring_interval_type', 'week');

    const periodEnd = purchase.current_period_end ? moment.unix(purchase.current_period_end) : today;
    const nextInvoice = today <= periodEnd ? periodEnd : moment(today).add(every, type);

    return nextInvoice;
  };

  return (
    <ConfirmModal
      isConfirmModalOpen={isConfirmModalOpen}
      className={isTrialPackage && 'cancel-trial-modal'}
      title={titlePopup}
      content={
        <S.Wrapper isTrialPackage={isTrialPackage}>
          <div className={`cancel__description ${isOneTimePurchase && 'isOneTime'}`}>
            {isTrialPackage ? (
              <S.TrialText>
                <p>
                  There are <b>{pluralize('day', getTrialLeftDays(get(item, 'ended_trial_at', '')), true)} left</b> on
                  the trial. This will mark the trial as Cancelled immediately.
                </p>
                Your client will receive an email notice for the upcoming change.
              </S.TrialText>
            ) : (
              messagePopup
            )}
          </div>
          {!isOneTimePurchase && !isTrialPackage && (
            <>
              <CheckboxCircle
                label="Cancel Immediately"
                value="immediately"
                checked={cancelType === 'immediately'}
                onChange={handleOnChange}
              />
              <CheckboxCircle
                label={
                  <span>
                    Cancel at the end of current billing period{' '}
                    <b>
                      {item.next_invoice
                        ? moment(item.next_invoice).format('MMMM DD, YYYY')
                        : renderNextInvoice(item).format('MMMM DD, YYYY')}
                    </b>
                  </span>
                }
                value="end_period"
                checked={cancelType === 'end_period'}
                onChange={handleOnChange}
              />
            </>
          )}
        </S.Wrapper>
      }
      onConfirm={handleConfirm}
      confirmButtonTitle="Yes, I’m sure"
      cancelButtonTitle="Nevermind"
      headerIcon={`${CDN_URL}/images/cancel_subscription_confirm_icon.svg`}
      newStyle
      hasCloseIcon
      dispatch={dispatch}
    />
  );
};

const mapStateToProps = state => {
  const { isConfirmModalOpen, confirmModal } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionPopup);
