import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

import Button from 'shared/FormControl/Button';
import Toggle from 'shared/FormControl/Toggle';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const LeftPanel = styled.div`
  width: 254px;
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #dde0e5;
`;

export const FilterOptions = styled.div`
  width: 206px;
  height: 42px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 1px;
  :hover {
    border-radius: 4px;
    background: #f4f4fc;
  }
  ${props =>
    props.checked &&
    css`
      border-radius: 4px;
      background: #f4f4fc;
    `}
`;

export const FilterType = styled.div`
  ${baseText}
  color: #8c8c8c;
  font-size: 14px;
  ${props =>
    props.checked &&
    css`
      color: #5e59ff;
    `}
`;

export const FilterTotal = styled.div`
  ${baseText}
  color: #8c8c8c;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  ${props =>
    props.checked &&
    css`
      color: #5e59ff;
    `}
`;

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1;
`;

export const MainPanelFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  min-height: 60px;
  padding: 12px 24px;
  box-shadow: inset 0px -1px 0px #dde0e5;
  width: 100%;
`;

export const DividerSpace = styled.div`
  display: flex;
  flex: 1;
  margin-left: -8px;
`;

export const FilterIconWrapper = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonReset = styled.button`
  cursor: pointer;
  position: relative;
  border: unset !important;
  background: transparent !important;
  outline: none !important;
  padding: unset !important;
  gap: 4px;

  display: flex;
  flex-direction: row;
  color: #595959;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 7px;

  .icon {
    display: block;
  }

  .icon.hover {
    display: none;
  }

  &:hover {
    color: #5158cf;

    .icon:not(.hover) {
      display: none;
    }

    .icon.hover {
      display: block;
    }
  }
`;

export const MainPanelHeader = styled.div`
  display: flex;
  width: 100%;
  min-width: 480px;
  max-width: 760px;
  height: 56px;
  padding: 12px 24px;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  border-bottom: 1px solid #dde0e5;

  ${props =>
    props.noBorder &&
    css`
      border-bottom: none;
    `}

  ${props =>
    props.center &&
    css`
      @media screen and (min-width: 1440px) {
        margin: 0 auto;
      }
    `};
`;

export const MainPanelTitle = styled.div`
  ${baseText}
  color: #595959;
  font-size: 16px;
  text-transform: uppercase;
  min-width: 54px;
`;

export const MarkAsRead = styled.div`
  ${baseText}
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  :hover {
    color: #5158cf;
    background-color: transparent;
  }
`;

export const MainPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: calc(100% - 2px);
  padding-left: 8px; // offset the width of the right scrollbar.
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c2c2c2 !important;
  }
`;

export const ContentScrollWrapper = styled.div`
  width: 100%;
  ${props =>
    props.limitWidth &&
    css`
      min-width: 760px;
      max-width: 760px;
    `};

  ${props =>
    props.center &&
    css`
      @media screen and (min-width: 1440px) {
        margin: 0 auto;
      }
    `};
`;

export const HeaderToggleWrapper = styled('label')`
  cursor: pointer;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const WorkoutCompletionButton = styled.div`
  margin-right: 9px;
`;

export const ToggleLabel = styled.span`
  color: #262626;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  user-select: none;
`;

export const ToggleButton = styled(Toggle)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  input {
    ~ label {
      ::before {
        background-color: #e0e1e4 !important;
      }
    }

    :checked,
    :checked:focus {
      ~ label {
        ::before {
          background-color: #36ba7b !important;
        }
      }
    }
  }
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  margin-top: ${props => (props.isEmpty ? '144px' : '32px')};
  margin-bottom: 32px;
  .ui.loader {
    z-index: unset !important;
  }
  .ui.loader:before {
    border-color: #fbfbff;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    color: #8c8c8c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 144px auto 0px;
  gap: 16px;
`;

export const EmptyText = styled.div`
  color: #8c8c8c;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const BottomIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
`;

export const BottomText = styled.div`
  color: #8c8c8c;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const GoToTopButton = styled(Button)`
  color: #5158cf;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

export const Item = styled.a`
  padding: 16px 8px 16px 24px;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px #ebedf4;
  overflow: hidden;

  &.unread {
    background: #fbfbff;
    box-shadow: 0px -1px 0px 0px #dde0e5 inset;
    border-left: 4px solid #5158cf;
    padding-left: 24px;
  }

  &.align-items-center {
    align-items: center;
  }

  &:hover {
    background: #fafafa;
    text-decoration: none !important;

    .action {
      visibility: visible;
    }
  }

  .avatar-notification-center {
    flex-shrink: 0;
  }

  .message {
    color: #262626;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    flex: 0;
    word-break: break-word;
    box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    b {
      font-weight: 600;
    }
  }

  .content {
    margin-left: 8px;
  }

  .time {
    color: #8c8c8c;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .action {
    visibility: hidden;
    flex-shrink: 0;
  }

  .time-box {
  }

  .action-box {
    z-index: 100;
  }

  .payment-amount {
    color: #595959;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 8px;

    &.in-sales {
      margin-top: 6px;
    }
  }

  .confetti {
    position: absolute;
    top: -190px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  .trigger-icon {
    width: 36px;
    height: 36px;
    min-width: 36px;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

export const ItemActionPopup = styled(Popup)`
  &.ui.popup {
    background-color: transparent;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  &.ui.top.popup {
    margin: 0 0 10.5px !important;
  }

  &.ui.bottom.popup {
    margin: 10.5px 0 0 !important;
  }

  &.ui.top.right.popup {
    margin: 0 -12px 10.5px 0 !important;
  }

  &.ui.bottom.right.popup {
    margin: 10.5px -12px 0 0 !important;
  }

  &::before {
    content: '';
    width: unset !important;
    height: unset !important;
    background: unset !important;
    background-color: unset !important;
    box-shadow: unset !important;
    transform: unset !important;
    margin-left: unset !important;
    right: 18px !important;
    top: -10px !important;
    z-index: unset !important;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #262626;

    ${props =>
      props.position === 'top right' &&
      css`
        top: unset !important;
        bottom: -10px !important;
        border-color: #262626 transparent transparent;
      `}
  }
`;

export const ItemActionOption = styled.div`
  ${props =>
    props.disabled &&
    css`
      display: none;
    `};
`;

export const ItemActionContent = styled.div`
  width: 131px;
  border-radius: 6px;
  background-color: #262626;
  box-shadow: unset;
`;

export const ItemActionItem = styled.div`
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #262626;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #4e4d4d;
  }
`;

export const ItemActionLabel = styled.div`
  color: #ffffff;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const EmojiHabit = styled.div`
  display: inline-block;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${props => props.color || '#FCF5DE'};
  position: relative;

  img {
    filter: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const ScrollTopButton = styled.div`
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  position: fixed;
  right: 20px;
  bottom: 96px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  z-index: 1000;
  background-color: #5158cf;

  &.show {
    opacity: 1;
    visibility: visible;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 28px;
  background: #dadbea;
  margin-left: 1px;
`;

export const Header = styled.div`
  color: #262626;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px !important;
  margin-top: 5px;
  margin-left: -6px;
`;
