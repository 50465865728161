// Libs
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import truncate from 'lodash/truncate';

// Parts
import { findAssetNamesDeleted } from 'shared/TriggerForms/units/helper';

// Styles
import * as S from './styles';

const WarningIssueIcon = props => {
  const { wrapperClassNames, tooltipId, triggerValue, type } = props;
  const names = useMemo(() => findAssetNamesDeleted(triggerValue, type), [triggerValue, type]);

  if (names.length < 1) return null;

  const stringNames = names.join(', ');
  const truncatedName = truncate(stringNames, { length: 87 });

  return (
    <S.IconWrapper className={wrapperClassNames} data-for={tooltipId} data-tip>
      <img src="/images/warning-delete-icon.svg" alt="" />
      <ReactTooltip id={tooltipId} className="warning-icon-program-tooltip" place="top" effect="solid">
        <span className="tooltip-text">
          <span className="text-highlight">{truncatedName}</span> {names.length === 1 ? 'has' : 'have'} been deleted.
        </span>
      </ReactTooltip>
    </S.IconWrapper>
  );
};

export default WarningIssueIcon;
