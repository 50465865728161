import React from 'react';

import * as S from './style';

const SkeletonLoading = () => {
  return (
    <S.SkeletonWrapper>
      <div className="skeleton-thumbnail"></div>
      <div className="skeleton-info">
        <div className="skeleton-title"></div>
        <div className="skeleton-unit"></div>
        <div className="skeleton-owner"></div>
      </div>
    </S.SkeletonWrapper>
  );
};

export default SkeletonLoading;
