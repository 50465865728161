import React from 'react';
import get from 'lodash/get';

import * as S from '../styles';

const SignatureResponse = ({ answer, getCloudUrl }) => {
  const signatureThumbnailUrl = get(answer, 'answer.signature_thumbnail_url');
  const signatureUrl = get(answer, 'answer.signature_url');

  return (
    <>
      <S.SignatureResponse>
        <img src={getCloudUrl(signatureThumbnailUrl || signatureUrl)} alt="" />
      </S.SignatureResponse>
      {get(answer, 'answer.full_name') ? <S.FullNameText>{get(answer, 'answer.full_name')}</S.FullNameText> : null}
    </>
  );
};

export default SignatureResponse;
