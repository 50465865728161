import React from 'react';
import Dropzone from 'react-dropzone';
import { AddMediaPlaceholder, CloseIcon, TextPlaceholder } from './style';
import { FormGroup, Button } from 'shared/FormControl';
import { VALIDATIONS } from '../helper';

export default ({ onDrop, onSelectFile, onClose, onAddLink }) => (
  <FormGroup>
    <label>Upload media file</label>
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, isDragActive }) => {
        return (
          <AddMediaPlaceholder {...getRootProps()} isDragging={isDragActive}>
            <TextPlaceholder>
              <div>Drag and drop your media here,</div>
              <div>
                <Button className="choose-file" textOnly>
                  <span>Choose a file</span>
                  <input
                    type="file"
                    accept={`${VALIDATIONS.IMAGE_25MB.accepts}, ${VALIDATIONS.VIDEO_300MB.accepts}`}
                    onChange={onSelectFile}
                    className="select-file__input"
                    multiple={false}
                  />
                </Button>
                <span>, or&nbsp;</span>
                <Button className="choose-file" textOnly onClick={onAddLink}>
                  Add video link
                </Button>
              </div>
            </TextPlaceholder>
            <CloseIcon onClick={onClose} />
          </AddMediaPlaceholder>
        );
      }}
    </Dropzone>
  </FormGroup>
);
