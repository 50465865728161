import _ from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  list: [],
  page: 1,
  per_page: 20,
  total: 0,
  unread: 0,
  isGetting: true,
  selectedItem: null,
  loading: false,
  clients: {
    list: [],
    page: 1,
    per_page: 20,
    text_search: '',
    total: 0,
    loading: false,
  },
  summaries: {
    all: 0,
    forum: 0,
    training: 0,
    task_habit: 0,
    nutrition: 0,
    progress_photo: 0,
    payment: 0,
    admin: 0,
  },
  dashboard: {
    list: [],
    page: 1,
    per_page: 20,
    total: 0,
    filters: {},
    loading: false,
  },
  popup: {
    list: [],
    page: 1,
    per_page: 20,
    total: 0,
    filters: {},
    loading: false,
  },
};

const getListSuccess = (oldState, payload) => {
  const { list } = oldState;
  const { data, page, total } = payload;
  const newList = _.unionBy(list, data, '_id');
  const ordered = _.orderBy(newList, 'createdAt', ['desc']);
  return Object.assign({}, oldState, {
    list: ordered,
    page: parseInt(page || 1),
    total,
    selectedItem: null,
    loading: false,
  });
};

const markAsRead = (oldList, payload) => {
  const newList = _.clone(oldList);

  if (payload && payload.id) {
    _.forEach(newList, item => {
      if (item._id === payload.id) {
        item.checked = true;
        return false;
      }
    });
  } else {
    _.forEach(newList, item => {
      item.checked = true;
    });
  }

  return newList;
};

const markAsUnread = (oldList, payload) => {
  const newList = _.clone(oldList);

  if (payload && payload.id) {
    _.forEach(newList, item => {
      if (item._id === payload.id) {
        item.checked = false;
        return false;
      }
    });
  } else {
    _.forEach(newList, item => {
      item.checked = false;
    });
  }

  return newList;
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.NOTIFICATION_GET_LIST:
      return Object.assign({}, state, { isGetting: true, loading: true });

    case Types.NOTIFICATION_FAILED_GET_LIST:
      return Object.assign({}, state, { isGetting: false, loading: false });

    case Types.NOTIFICATION_SUCCESS_GET_LIST:
      return getListSuccess(state, payload);

    case Types.NOTIFICATION_MARK_AS_READ:
    case Types.NOTIFICATION_MARK_ALL_AS_READ: {
      const newList = markAsRead(state.list, payload);
      const newDashboardList = markAsRead(state.dashboard.list, payload);
      const newPopupList = markAsRead(state.popup.list, payload);
      return Object.assign({}, state, {
        list: newList,
        isGetting: false,
        dashboard: { ...state.dashboard, list: newDashboardList, loading: false },
        popup: { ...state.popup, list: newPopupList, loading: false },
      });
    }

    case Types.NOTIFICATION_CATEGORY_MARK_ALL_AS_READ: {
      const newDashboardList = markAsRead(state.dashboard.list, payload);
      return Object.assign({}, state, {
        dashboard: { ...state.dashboard, list: newDashboardList, loading: false },
      });
    }

    case Types.NOTIFICATION_MARK_AS_UNREAD: {
      const newList = markAsUnread(state.list, payload);
      const newDashboardList = markAsUnread(state.dashboard.list, payload);
      const newPopupList = markAsUnread(state.popup.list, payload);
      return Object.assign({}, state, {
        list: newList,
        isGetting: false,
        dashboard: { ...state.dashboard, list: newDashboardList, loading: false },
        popup: { ...state.popup, list: newPopupList, loading: false },
      });
    }

    case Types.NOTIFICATION_COUNT_UNREAD:
      return Object.assign({}, state, { unread: payload.data });

    case Types.NOTIFICATION_RESET_DATA:
      return INITIAL_STATE;

    case Types.NOTIFICATION_SUCCESS_CLEAR_NEW:
      return Object.assign({}, state, { unread: 0 });

    case Types.NOTIFICATION_HAS_NEW_ONE_SOCKET: {
      return Object.assign({}, state, { unread: state.unread + 1 });
    }

    case Types.NOTIFICATION_CLEAR_LIST:
      return Object.assign({}, state, { page: INITIAL_STATE.page, list: INITIAL_STATE.list });

    case Types.NOTIFICATION_SELECTED:
      return Object.assign({}, state, { selectedItem: payload });

    case Types.NOTIFICATION_FETCH_CLIENTS_REQUEST: {
      return Object.assign({}, state, {
        clients: {
          ...state.clients,
          loading: true,
          list: payload.page === 1 ? [] : state.clients.list,
        },
      });
    }
    case Types.NOTIFICATION_FETCH_CLIENTS_SUCCESS: {
      return Object.assign({}, state, {
        clients: {
          ...state.clients,
          page: payload.page,
          text_search: payload.text_search,
          list: payload.page === 1 ? payload.list : [...state.clients.list, ...payload.list],
          total: payload.total,
          loading: false,
        },
      });
    }
    case Types.NOTIFICATION_FETCH_CLIENTS_FAILED: {
      return Object.assign({}, state, { clients: { ...state.clients, loading: false } });
    }
    case Types.NOTIFICATION_SUMMARIES_SUCCESS: {
      return Object.assign({}, state, { summaries: { ...state.summaries, ...payload } });
    }
    case Types.NOTIFICATION_FILTER_REQUEST: {
      return Object.assign({}, state, {
        dashboard: {
          ...state.dashboard,
          loading: true,
          list: payload.page === 1 ? [] : state.dashboard.list,
        },
      });
    }
    case Types.NOTIFICATION_FILTER_SUCCESS: {
      return Object.assign({}, state, {
        dashboard: {
          ...state.dashboard,
          loading: false,
          list: payload.page === 1 ? payload.list : [...state.dashboard.list, ...payload.list],
          total: payload.total,
          page: payload.page,
          per_page: payload.per_page,
          filters: payload.filters,
        },
      });
    }
    case Types.NOTIFICATION_FILTER_FAILED: {
      return Object.assign({}, state, { dashboard: { ...state.dashboard, loading: false } });
    }
    case Types.NOTIFICATION_POPUP_FILTER_REQUEST: {
      return Object.assign({}, state, {
        popup: {
          ...state.popup,
          loading: true,
          list: payload.page === 1 ? [] : state.popup.list,
        },
      });
    }
    case Types.NOTIFICATION_POPUP_FILTER_SUCCESS: {
      return Object.assign({}, state, {
        popup: {
          ...state.popup,
          loading: false,
          list: payload.page === 1 ? payload.list : [...state.popup.list, ...payload.list],
          total: payload.total,
          page: payload.page,
          per_page: payload.per_page,
          filters: payload.filters,
        },
      });
    }
    case Types.NOTIFICATION_POPUP_FILTER_FAILED: {
      return Object.assign({}, state, { popup: { ...state.popup, loading: false } });
    }
    case Types.NOTIFICATION_DECREASE_CATEGORY_SUMMARIES: {
      const { category = '', amount = 1, reduceToZero = false } = payload;
      if (reduceToZero && category === 'all') {
        return Object.assign({}, state, { summaries: INITIAL_STATE.summaries });
      }
      let newCategorySummary = {};
      if (!_.isEmpty(category) && _.keys(state.summaries).includes(category) && category !== 'all') {
        newCategorySummary = {
          [category]: Math.max(state.summaries[category] - amount, 0),
        };
      }
      return Object.assign({}, state, {
        summaries: {
          ...state.summaries,
          all: Math.max(state.summaries.all - amount, 0),
          ...newCategorySummary,
        },
      });
    }
    case Types.NOTIFICATION_INCREASE_CATEGORY_SUMMARIES: {
      const { category = '', amount = 1 } = payload;
      let newCategorySummary = {};
      if (!_.isEmpty(category) && _.keys(state.summaries).includes(category) && category !== 'all') {
        newCategorySummary = {
          [category]: Math.max(state.summaries[category] + amount, 0),
        };
      }
      return Object.assign({}, state, {
        summaries: {
          ...state.summaries,
          all: Math.max(state.summaries.all + amount, 0),
          ...newCategorySummary,
        },
      });
    }
    default:
      return state;
  }
};
