import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import * as S from './style';

function SidebarSetting(props) {
  const { onScrollTag, sidebarSetting } = props;
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    setActiveItem(sidebarSetting[0].id);
  }, []);

  const handleScrollTag = idTag => {
    setActiveItem(idTag);
    onScrollTag(idTag);
  };

  return (
    <S.SideBar>
      <S.SidebarTitle>Settings</S.SidebarTitle>
      {map(sidebarSetting, item => (
        <S.SidebarItem
          className={classNames('status', { active: item.id === activeItem })}
          key={item.id}
          onClick={() => handleScrollTag(item.id)}
        >
          {item.title}
        </S.SidebarItem>
      ))}
    </S.SideBar>
  );
}

export default SidebarSetting;
