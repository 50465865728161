// Libs
import React from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { pluralize } from 'utils/commonFunction.js';

// Parts
import { BLOCK_ACTION_TYPES, CONTENT_BLOCK_ACTION_TYPES } from 'shared/TriggerForms/constants/types';
import { getTooltipContent, isPrivateFlow } from 'shared/TriggerForms/units/helper';

// Styles
import * as S from './styles';

const BlockActionModal = props => {
  const { type, closeModal, relatedQuestionOnboardingFlows, relatedFormOnboardingFlows, user = {} } = props;

  if (!Object.keys(CONTENT_BLOCK_ACTION_TYPES).includes(type)) return null;
  if (!Array.isArray(relatedQuestionOnboardingFlows) && !Array.isArray(relatedFormOnboardingFlows)) return null;

  const { icon, title, submitLabelButton, submitButtonTheme } = CONTENT_BLOCK_ACTION_TYPES[type];

  let onboardingFlows = [];
  if (type === BLOCK_ACTION_TYPES.CHANGE_TYPE_QUESTION) {
    onboardingFlows = relatedQuestionOnboardingFlows;
  } else if (type === BLOCK_ACTION_TYPES.ARCHIVE_FORM) {
    onboardingFlows = relatedFormOnboardingFlows;
  }

  const description = () => {
    const lengthOnboardingFlow = onboardingFlows.length;
    switch (type) {
      case BLOCK_ACTION_TYPES.CHANGE_TYPE_QUESTION:
        return `This question is being used as trigger. You cannot change question type at the moment, please remove trigger from the following onboarding ${pluralize(
          'flow',
          lengthOnboardingFlow,
        )} first:`;
      case BLOCK_ACTION_TYPES.ARCHIVE_FORM:
        return `This form is being used as trigger. You can not archive at the moment, please remove trigger from the following onboarding ${pluralize(
          'flow',
          lengthOnboardingFlow,
        )} first:`;
      default:
        return '';
    }
  };

  const handleCloseModal = () => {
    typeof closeModal === 'function' && closeModal();
  };

  const renderItem = ({ _id, name, share, author }, idx) => {
    const isDisable = isPrivateFlow(user, author, share);
    const tooltipContent = getTooltipContent(author);
    return (
      <React.Fragment key={`onboarding-form-${_id}-${idx}`}>
        <a
          href={!isDisable && `/home/onboarding-flow/${_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames('onboarding-flow-item', {
            isDisableNavigate: isDisable,
          })}
        >
          <span data-for={`tooltip-${_id}-${idx}`} data-tip>
            {name}
          </span>
        </a>

        {isDisable && (
          <ReactTooltip id={`tooltip-${_id}-${idx}`} className="go-to-icon-tooltip" place="top" effect="solid">
            <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
          </ReactTooltip>
        )}
      </React.Fragment>
    );
  };

  return (
    <S.BlockActionModalWrapper
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src="/images/close_circle.svg" />
        </Button>
      }
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {icon && <img src={icon} alt="" className="icon-header" />}
        <div className="title-modal">{title}</div>
      </Modal.Header>
      <Modal.Description>
        <div
          className="description-modal"
          dangerouslySetInnerHTML={{
            __html: description(),
          }}
        />
      </Modal.Description>
      <Modal.Content>
        <div className="wrapper-onboarding-flows">
          <div className="scrollable-onboarding-flows">{onboardingFlows.map(renderItem)}</div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <button type="submit" className={classNames('submit-button', submitButtonTheme)} onClick={handleCloseModal}>
          {submitLabelButton}
        </button>
      </Modal.Actions>
    </S.BlockActionModalWrapper>
  );
};

export default BlockActionModal;
