import React, { useReducer } from 'react';
import map from 'lodash/map';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import { CONTACT_MESSAGE_STATUS } from 'components/Package/PackageDetail/ContactList/constants';

import { getContactMessageStatus } from '../common';
import { StatusFilterWrapper } from './styles';

const MessageFilter = ({ options, value, onChange }) => {
  const [isShowDropdown, toggleDropdown] = useReducer(isShow => !isShow, false);

  const handleSelectStatus = value => {
    onChange(value);
    toggleDropdown();
  };

  return (
    <RootCloseWrapper onRootClose={toggleDropdown} event="click" disabled={!isShowDropdown}>
      <StatusFilterWrapper>
        <div className="button-wrapper">
          <div className="status-button" onClick={toggleDropdown}>
            {value !== CONTACT_MESSAGE_STATUS.ALL ? (
              <span className="bold">{getContactMessageStatus(value).label}</span>
            ) : (
              <>
                <span>Client message:</span>
                <span className="bold">All</span>
              </>
            )}
          </div>
        </div>
        {isShowDropdown && (
          <div className="status-dropdown">
            {map(options, item => {
              return (
                <label
                  className={classNames('status-item', item.value === value ? 'active' : '')}
                  key={item.value}
                  onClick={() => handleSelectStatus(item.value)}
                >
                  <span>{item.name}</span>
                </label>
              );
            })}
          </div>
        )}
      </StatusFilterWrapper>
    </RootCloseWrapper>
  );
};

export default MessageFilter;
