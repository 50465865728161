import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  padding: 12px;
  gap: 2px;
  border-radius: 4px;
  cursor: text;
  border: ${({ hasError }) => (hasError ? '1px solid #EA314A !important' : '1px solid #d9d9d9')};
  max-height: 548px;
  overflow: auto;

  &:has(textarea:focus) {
    border: 1px solid #5158cf;
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    resize: none;
    border: none;
    outline: none;
    min-height: 19px;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    color: #202353;
  }
`;

export const CharacterCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.98px;
  margin-top: 2px;
  user-select: none;
  color: #979797;

  .is-error {
    color: #ea314a;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #ea314a;
  margin-top: 6px;
`;
