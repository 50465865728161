import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { showError } from 'actions/error';
import warning from 'assets/icons/warning_red_light.svg';
import * as S from './style';

const PaymentLimitAccess = ({ showError, message, background }) => {
  useEffect(() => {
    showError(
      `${message || 'You are not authorized to access this page. Please contact your admin to gain access.'}`,
      'Error',
      'Go back',
      warning,
      'go-back',
      backToHome,
    );
  }, []);

  const backToHome = () => {
    window.location.href = '/';
  };

  return <S.Container background={background} />;
};

export default connect(null, { showError })(PaymentLimitAccess);
