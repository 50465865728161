import styled, { css } from 'styled-components';

export const ListContainer = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: overlay;
  position: relative;
  padding-top: 20px;
  display: flex;

  .clients__table-container {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: overlay;
  }

  table {
    border-spacing: 0 0;
    width: 100%;
    tr {
      th {
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6a778a;
        text-transform: uppercase;
        height: 0;
        position: initial;

        .table__header {
          position: absolute;
          top: 0;
          &.send-email-column {
            width: 100px;
            text-align: center;
          }
        }

        :nth-child(4) {
          width: 250px;
        }

        :last-child {
          min-width: 100px;
        }

        :first-child {
          min-width: 40px;
        }
      }

      td:last-child {
        text-align: center;
      }
    }

    tbody {
      tr {
        td {
          padding-bottom: 20px;
          padding-right: 13px;

          :first-child {
            font-size: 13px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            width: 40px;
            text-align: center;
            padding-right: 0;

            img {
              display: none;
              cursor: pointer;
              margin: auto;
              width: 18px;
            }
          }

          :last-child {
            padding-right: 0;
          }
        }

        :last-child {
          td {
            padding-bottom: 0;
            :last-child {
              text-align: left;
            }
          }

          .icon {
            cursor: pointer;
          }
        }

        :hover {
          td:first-child {
            span {
              display: none;
            }
            img {
              display: block;
            }
          }
        }
      }
    }

    .ui.input {
      width: 100%;
    }

    .ui.dropdown {
      padding: 11.5px 15px !important;
    }
  }
`;

export const InputWrapper = styled.div`
  ${props =>
    props.isAdminOrOwner &&
    css`
      max-width: ${props => (props.column === 'first_name' || props.column === 'last_name' ? '110px' : 'unset')};
    `}
`;

export const AssignContent = styled.div`
  min-width: 158px;
`;

export const CategoryContent = styled.div`
  min-width: 158px;
`;

export const LeftActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .onboarding-flow {
    display: flex;
    align-items: center;
    gap: 16px;

    .onboarding-flow-label {
      .custom-form-label {
        display: flex;
        gap: 6px;

        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        color: #6a778a;

        .custom-hint-icon {
          &:hover {
            cursor: pointer;
            path {
              fill: #5c5bbd;
            }
          }
        }
      }

      .__react_component_tooltip.app-tooltip.onboarding-flow-tooltip {
        min-width: 228px;
        padding: 15px;
        border-radius: 5px;
        background-color: #151619;
        text-align: left;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
      .__react_component_tooltip.type-dark.onboarding-flow-tooltip.place-top:after {
        bottom: -7px;
        border-top-color: #151619;
      }
      .__react_component_tooltip.onboarding-flow-tooltip.place-top {
        margin-top: -7px;
      }
    }

    .select-onboarding-flow {
      min-width: 304px;
    }

    .onboarding-flow__menu {
      min-width: 498px;
    }
  }
`;
