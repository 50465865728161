import styled, { css } from 'styled-components';
import S3Images from 'shared/S3Images';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
`;

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 1px 16px 0px #0000000f;
  background-color: #fff;
  position: relative;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    border: 1px solid #5158cf;
  }
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      &:hover {
        cursor: default;
        border: 1px solid transparent;
      }
    `}
`;

export const Thumbnail = styled(S3Images)`
  width: 100%;
  height: 168px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      height: 100%;
    `}
`;

export const Content = styled.div`
  padding: 10px 15px 0;
`;

export const Name = styled.div`
  height: 40px;
  ${baseText}
  ${limitText}
  -webkit-line-clamp: 2;
  line-clamp: 2;
  margin-bottom: 5px;
`;

export const NutritionInfo = styled.div`
  display: flex;
  ${baseText}
  color: #7b7e91;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
  ${limitText}
  -webkit-line-clamp: 1;
  line-clamp: 1;
`;

export const SeparateDot = styled.span`
  width: 2px;
  height: 2px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #7b7e91;
`;

export const NutritionItem = styled.div`
  max-width: fit-content;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  &:last-child {
    ${SeparateDot} {
      display: none;
    }
  }
`;
