import styled from 'styled-components';

export const GoalItemOverviewStyled = styled.div`
  border-radius: 4px;
  background-color: #edf7fe;
  color: #202353;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .goal-type {
    display: flex;
    justify-content: flex-start;
    padding: 0 4px 4px 0;
    background-color: #fff;
    width: fit-content;
    border-radius: 0 0 4px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      height: 28px;
      min-width: 106px;
      width: fit-content;
      border-radius: 4px;
      background-color: #edf7fe;
      text-transform: capitalize;
    }
  }
  .goal-text {
    padding: 9px 12px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-height: 48px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
