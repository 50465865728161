import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Image, Modal } from 'semantic-ui-react';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

import OwnerFilter from 'shared/OwnerFilter';
import { CLIENT_IN_VIEW, HEIGHT_OF_TAB_COMPLETED, PER_PAGE, TAB_MISSED } from './constants';
import { Calendar, Header, TableHeader, MissedTab, CompletedTab } from './parts';
import {
  getAllMissedWorkoutSummaries,
  getWorkoutSummaries,
  resetWorkoutSummaryState,
} from 'redux/workout-completion/actions';
import { bindActionCreators } from 'redux';
import { pluralize } from 'utils/commonFunction';

import { CDN_URL } from 'constants/commonData';

import { toggleModal, toggleSecondModal } from 'actions/modal';
import BroadcastMessage from 'shared/BroadcastMessage';
import { resetBroadcastMessageState } from 'redux/broadcast-messages/actions';
import UpgradeStudioPlanModal from './parts/StudioUpgradeModal';

import * as S from './style';

const initParams = {
  page: 1,
  per_page: PER_PAGE,
  owner_ship: 1,
  category: 'missed',
};

const WorkoutCompletionModal = props => {
  const {
    tier,
    user,
    onClose,
    toggleModal,
    toggleSecondModal,
    getWorkoutSummaries,
    hasBroadcastPermission,
    resetWorkoutSummaryState,
    workoutCompletionSummaries,
    resetBroadcastMessageState,
    getAllMissedWorkoutSummaries,
  } = props;
  const isLoading = get(workoutCompletionSummaries, 'loading', false);
  const timezone = get(user, 'timezone', 'UTC');

  const isYesterday = moment.tz(timezone).subtract(1, 'day');
  const [startDate, setStartDate] = useState(isYesterday);
  const [ownerFilter, setOwnerFilter] = useState(1);
  const [tab, setTab] = useState(TAB_MISSED);

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [isScrollEnd, setScrollEnd] = useState(false);
  const [isOpenSecondModal, setIsOpenSecondModal] = useState(false);

  const tableBodyRef = useRef();

  useEffect(() => {
    const resetScrollTop = () => {
      if (tableBodyRef.current) {
        tableBodyRef.current.scrollTop = 0;
      }
    };

    resetScrollTop();

    return resetScrollTop;
  }, [tab]);

  const resetSelected = () => {
    setSelected([]);
  };

  useEffect(() => {
    const activeTab = tab === TAB_MISSED ? 'missed' : 'completed';
    getWorkoutSummaries({
      ...initParams,
      day: startDate.format('YYYY-MM-DD'),
      owner_ship: ownerFilter,
      category: activeTab,
    });
    setPage(1);

    resetSelected();
    setScrollEnd(false);

    return () => {
      resetSelected();
      setScrollEnd(false);
    };
  }, [startDate, tab, ownerFilter]);

  useEffect(() => {
    return () => {
      resetWorkoutSummaryState();
    };
  }, []);

  const handleScrollToTop = () => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollEnd = event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    const showBackToTop = scrollHeight - scrollTop <= clientHeight + 120;
    !isLoading && setScrollEnd(showBackToTop);

    const total =
      tab === TAB_MISSED
        ? get(workoutCompletionSummaries, 'totalMissed', 0)
        : get(workoutCompletionSummaries, 'totalCompleted', 0);

    const isLastPage = page >= Math.ceil(total / PER_PAGE);

    if (bottom && !isLoading && !isLastPage) {
      const newPage = page + 1;
      setPage(newPage);
      const activeTab = tab === TAB_MISSED ? 'missed' : 'completed';
      getWorkoutSummaries({
        ...initParams,
        day: startDate.format('YYYY-MM-DD'),
        owner_ship: ownerFilter,
        category: activeTab,
        page: newPage,
      });
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const isLastPage =
    page >=
    Math.ceil(
      get(workoutCompletionSummaries, `${tab === TAB_MISSED ? 'totalMissed' : 'totalCompleted'}`, 0) / PER_PAGE,
    );

  const handleCloseBroadcastModal = () => {
    resetBroadcastMessageState();
    toggleModal && toggleModal(false);
  };

  const handleOpenBroadcastModal = selectedClientIds => {
    getAllMissedWorkoutSummaries(
      {
        day: startDate.format('YYYY-MM-DD'),
        owner_ship: ownerFilter,
      },
      res => {
        const missedWorkoutClients = res.map(item => get(item, 'client'));
        toggleModal &&
          toggleModal(
            true,
            <BroadcastMessage
              onClose={handleCloseBroadcastModal}
              selectedClientProps={selectedClientIds}
              isFromWorkoutSummary={true}
              missedWorkoutClients={missedWorkoutClients}
            />,
          );
      },
    );
  };

  const handleCloseAllPopup = () => {
    setIsOpenSecondModal(false);
    toggleSecondModal && toggleSecondModal(false);
    toggleModal && toggleModal(false);
  };

  const handleCloseUpgradeModal = () => {
    setIsOpenSecondModal(false);
    toggleSecondModal && toggleSecondModal(false);
  };

  const handleOpenUpgradeModal = () => {
    const dimmer = document.querySelector('.ui.dimmer');
    if (dimmer) {
      dimmer.style.transition = 'none';
    }

    setIsOpenSecondModal(true);
    toggleSecondModal &&
      toggleSecondModal(
        true,
        <UpgradeStudioPlanModal onClose={handleCloseUpgradeModal} onCloseAll={handleCloseAllPopup} />,
      );
  };

  const clients = useMemo(() => get(workoutCompletionSummaries, 'list', []), [tab, workoutCompletionSummaries]);

  const isHasScroll = useMemo(() => {
    if (!isEmpty(selected) && clients.length === CLIENT_IN_VIEW) {
      return true;
    }

    if (tableBodyRef.current) {
      return tableBodyRef.current.scrollHeight > tableBodyRef.current.clientHeight + 76;
    }

    return false;
  }, [selected]);

  const handleSetStartDate = date => {
    setStartDate(date);
    resetSelected();
  };

  const handleSetOwnerFilter = ownerShip => {
    setOwnerFilter(ownerShip);
    resetSelected();
  };

  return (
    <S.CustomModal
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      className={`workout-completion-modal ${isOpenSecondModal ? 'second-modal' : ''}`}
    >
      <Modal.Header className="workout-completion-modal__header">
        <S.HeaderWrapper>
          <S.HeaderTitle>Workout Analytics</S.HeaderTitle>
          <S.HeaderRight>
            <Calendar
              isYesterday={isYesterday}
              startDate={startDate}
              setStartDate={handleSetStartDate}
              timezone={timezone}
            />
            <S.FilterOwner>
              <OwnerFilter
                open={open}
                setOpen={setOpen}
                filter={ownerFilter}
                setFilter={handleSetOwnerFilter}
                user={user}
              />
            </S.FilterOwner>
          </S.HeaderRight>
        </S.HeaderWrapper>
        <Button onClick={onClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} alt="close" />
        </Button>
      </Modal.Header>
      <Modal.Content className="workout-completion-modal__content">
        <Header
          totalMissed={get(workoutCompletionSummaries, 'totalMissed', 0)}
          totalCompleted={get(workoutCompletionSummaries, 'totalCompleted', 0)}
          tab={tab}
          setTab={setTab}
          selectedClients={selected}
          clients={clients}
          ownerFilter={ownerFilter}
          startDate={startDate.format('YYYY-MM-DD')}
          page={page}
          isLoading={isLoading}
          hasBroadcastPermission={hasBroadcastPermission}
        />
        {tab === TAB_MISSED && (
          <TableHeader
            selected={selected}
            setSelected={setSelected}
            ownerFilter={ownerFilter}
            startDate={startDate.format('YYYY-MM-DD')}
            getAllClient={getAllMissedWorkoutSummaries}
            totalMissed={get(workoutCompletionSummaries, 'totalMissed', 0)}
            clients={clients}
          />
        )}
        <S.TableBodyWrapper
          ref={tableBodyRef}
          height={tab !== TAB_MISSED ? HEIGHT_OF_TAB_COMPLETED : null}
          hasSelected={!isEmpty(selected) && isHasScroll}
          onScroll={event => {
            event.persist();
            onScrollDebounce.call(null, event);
          }}
        >
          <S.TableBody>
            {tab === TAB_MISSED ? (
              <MissedTab
                data={clients}
                selected={selected}
                setSelected={setSelected}
                onScrollToTop={handleScrollToTop}
                isLoading={isLoading}
                workoutCompletionSummaries={workoutCompletionSummaries}
                totalMissed={get(workoutCompletionSummaries, 'totalMissed', 0)}
                hasLoadMore={isLoading && page > 1}
                isEndPage={isLastPage && isScrollEnd}
                startDate={startDate}
                page={page}
              />
            ) : (
              <CompletedTab
                data={clients}
                onScrollToTop={handleScrollToTop}
                isLoading={isLoading}
                workoutCompletionSummaries={workoutCompletionSummaries}
                totalCompleted={get(workoutCompletionSummaries, 'totalCompleted', 0)}
                hasLoadMore={isLoading && page > 1}
                isEndPage={isLastPage && isScrollEnd}
                page={page}
              />
            )}
          </S.TableBody>
        </S.TableBodyWrapper>
        {tab === TAB_MISSED && hasBroadcastPermission && !isEmpty(selected) && (
          <S.BroadcastSelectedWrapper>
            <S.BroadcastSelectedButton purple onClick={() => handleOpenBroadcastModal(selected)}>
              Send message to {pluralize('client', selected.length, true)}
            </S.BroadcastSelectedButton>
          </S.BroadcastSelectedWrapper>
        )}
        {!hasBroadcastPermission && isString(tier) && (
          <S.BroadcastSelectedWrapper>
            <S.BroadcastSelectedButton upgradePath onClick={handleOpenUpgradeModal}>
              Get Broadcast Messages
            </S.BroadcastSelectedButton>
          </S.BroadcastSelectedWrapper>
        )}
      </Modal.Content>
    </S.CustomModal>
  );
};

const mapState = state => {
  const { user, rootReducer } = state;
  const { workoutCompletionSummaries } = rootReducer;
  const teamData = rootReducer.pricing.get('teamData').toJS();
  const hasBroadcastPermission = get(rootReducer, 'permission.broadcast_message', false);
  const tier = teamData && get(teamData, 'tier');

  return { user, workoutCompletionSummaries, tier, hasBroadcastPermission };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  getWorkoutSummaries: bindActionCreators(getWorkoutSummaries, dispatch),
  resetWorkoutSummaryState: bindActionCreators(resetWorkoutSummaryState, dispatch),
  resetBroadcastMessageState: bindActionCreators(resetBroadcastMessageState, dispatch),
  getAllMissedWorkoutSummaries: bindActionCreators(getAllMissedWorkoutSummaries, dispatch),
});

export default connect(mapState, mapDispatch)(WorkoutCompletionModal);
