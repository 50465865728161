import ConfirmModal from 'shared/ConfirmModal';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #202353;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.multiple-popup-red,
  &.ui.modal.confirm-popup-container.multiple-popup-purple,
  &.ui.modal.confirm-popup-container.multiple-popup-yellow {
    width: ${({ width }) => width || '475px'} !important;
    .close-button {
      right: -8px;
      top: -8px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
      .confirm-header-label {
        ${baseText}
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
      }
    }
    .confirm-content-body {
      .confirm-content-label {
        ${baseText}
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .confirm-actions {
      .button {
        border-radius: 5px;
        ${baseText}
        padding: 4px 24px;
        font-weight: 600;
        margin-left: 8px !important;
      }
      .confirm-no-button {
        min-width: unset;
        width: 102px;
        border: 1px solid #d6dae4 !important;
        color: #818da1 !important;
      }
    }
  }

  &.ui.modal.confirm-popup-container.multiple-popup-red.discard-change {
    .confirm-content-header {
      padding: 33px 30px 0 !important;
    }
    .confirm-header-label {
      font-size: 21px !important;
    }

    .confirm-content-body {
      padding-top: 28px !important;
    }

    .confirm-yes-button {
      padding: 5px 25px !important;
    }

    .confirm-no-button {
      min-width: unset !important;
      width: 89px !important;
      padding: 5px 25px !important;
    }
  }

  &.ui.modal.confirm-popup-container.extra-width {
    width: 475px !important;
  }

  &.ui.modal.confirm-popup-container.extra-large-width {
    width: 485px !important;
  }

  &.ui.modal.confirm-popup-container.multiple-popup-red {
    .confirm-content-body {
      padding: 25px 30px 25px;
    }
    .confirm-actions {
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        padding: 5px 30px;
        color: #fff;
        background-color: #ea314a !important;
        margin-left: 6px !important;
        &:hover {
          background-color: rgba(234, 49, 74, 0.9) !important;
        }
      }
    }
    &.remove-popup {
      .confirm-content-body {
        padding: 15px 30px 30px;
      }
    }
  }

  &.ui.modal.confirm-popup-container.multiple-popup-purple {
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      .button {
        font-size: 13px;
        line-height: 20px;
        margin-left: 5px !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        padding: 4px 29px;
        color: #fff;
        background-color: #5158cf !important;
        &:hover {
          background-color: rgba(81, 88, 207, 0.9) !important;
        }
      }
    }
  }

  &.ui.modal.confirm-popup-container.multiple-popup-yellow {
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      .button {
        font-size: 13px;
        line-height: 20px;
        margin-left: 5px !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        padding: 4px 29px;
        color: #fff;
        background-color: #ffc445 !important;
        &:hover {
          background-color: rgba(255, 196, 69, 0.9) !important;
        }
      }
    }
  }
`;
