import React from 'react';
import classNames from 'classnames';
import Logo from 'assets/images/app-icon-onscreen.png';
import { APP_ICON_STATUS, NEW_APP_ICON_FLOW } from '../../constants';
import * as S from './style';

function handleGetLogo(props) {
  const { CANCELLED } = APP_ICON_STATUS;
  const { appIcon, appIconReview, appIconUrl, appIconUploading, getCloudUrl } = props;

  if (appIconUploading) {
    return appIconUploading.src || appIconUploading.defaultLogo;
  }
  if (appIconReview) {
    // return appIconReview.status === CANCELLED ? (appIconUrl ? appIconUrl : Logo) : appIconReview.app_icon_url;
    return appIconReview.status === CANCELLED ? Logo : getCloudUrl(appIconReview.app_icon_url);
  }
  if (appIconUrl) return getCloudUrl(appIconUrl);
  if (appIcon) return getCloudUrl(appIcon.src);
  return Logo;
}

const AppIconScreen = props => {
  const { currentAppIconDefault, appIconKeySystemUrl, appIconKeySystem, appIconFlowType } = props;
  const { url } = currentAppIconDefault || {};

  const logoUp = handleGetLogo(props);
  const active = logoUp ? (Logo === logoUp ? true : false) : true;

  const isNewFlow = appIconFlowType === NEW_APP_ICON_FLOW;

  return (
    <S.Wrapper {...props}>
      <S.AppIconItemArea>
        {[...Array(10)].map((x, i) => (
          <S.AppIconItem key={i} isAndroid={props.isAndroid}></S.AppIconItem>
        ))}
        <S.AppIconItem
          isAndroid={props.isAndroid}
          style={{
            background: `url(${
              isNewFlow ? (appIconKeySystem ? url || appIconKeySystemUrl : url || Logo) : logoUp || Logo
            })`,
            border: '0.5px solid #dadfea',
          }}
          className={classNames({ active })}
        >
          <p className="text">Everfit</p>
        </S.AppIconItem>
      </S.AppIconItemArea>
      <S.ScreenName>
        {props.isAndroid ? (
          <div className="android-title-container">
            <div className="title">Android</div>
            <div className="subtitle">On some devices</div>
          </div>
        ) : (
          <div className="title">iOS</div>
        )}
      </S.ScreenName>
    </S.Wrapper>
  );
};

export default AppIconScreen;
