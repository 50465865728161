import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  &.multi-select__option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 13px;
    border-bottom: 1px solid #eceff1;
    cursor: pointer;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .client-avatar {
      max-width: none !important;
      margin-right: 12px;
      margin-left: 0;
    }
  }

  &.multi-select__option.multi-select__option--is-focused {
    background-color: #edecfc;
  }

  &.multi-select-container .multi-select__control {
    overflow-y: scroll;
  }

  .multi-select__option__label {
    z-index: 100 !important;
    max-width: 280px;

    .name {
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111111;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .email,
    .totalMember {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8d8c92;

      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .roleLable {
      display: block;
      position: absolute;
      top: 50%;
      padding: 5px 15px;
      max-width: 70px;
      text-align: center;
      font-size: 10px;
      color: #323c47;
      margin-bottom: 30px;
      background: #eeedf2;
      border: 1px solid #d4d7d9;
      border-radius: 10000px;
      align-items: center;
      transform: translateY(-50%);
    }

    &.rightSide {
      display: flex;
      height: 100%;
      max-width: 90px;
      position: relative;
      background: yellow;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;
