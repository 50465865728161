import Request from 'configs/request';
import { getRefreshToken, saveToken } from 'utils/commonFunction';
import get from 'lodash/get';

export const Types = {
  INIT_PERMISSION_DATA: 'INIT_PERMISSION_DATA',
  INIT_PERMISSION_DATA_FAILD: 'INIT_PERMISSION_DATA_FAILD',
  INIT_PERMISSION_DATA_SUCCESS: 'INIT_PERMISSION_DATA_SUCCESS',
  UPDATE_PERMISSION: 'UPDATE_PERMISSION',
  UPDATE_EXPLORE_PAYMENT_FAIL: 'UPDATE_EXPLORE_PAYMENT_FAIL',
  UPDATE_EXPLORE_PAYMENT_SUCCESS: 'UPDATE_EXPLORE_PAYMENT_SUCCESS',
};

function transfromPricingData(data) {
  return {
    ...data,
    unlimitedScheduling: data.assignment_scheduling < 0,
  };
}

export const updatePermission = data => {
  return { type: Types.INIT_PERMISSION_DATA_SUCCESS, payload: { data: transfromPricingData(data) } };
};

export const explorePayment = () => {
  // TODO: should call api to update
  return Request.put(
    { url: '/api/team/explore-payment/active' },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (data) {
        dispatch({ type: Types.UPDATE_EXPLORE_PAYMENT_SUCCESS });
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.UPDATE_EXPLORE_PAYMENT_FAIL });
    },
  );
};

export const refreshToken = () => {
  return Request.get(
    { url: `/api/auth/refresh_token?token=${getRefreshToken()}` },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (data) {
        saveToken(data, localStorage.getItem('access-token'));
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.INIT_PERMISSION_DATA_FAILD });
    },
  );
};

export const initPermission = () => {
  return Request.get(
    { url: '/api/permission/get-permission' },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (data) {
        dispatch(updatePermission(data));
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.INIT_PERMISSION_DATA_FAILD });
    },
  );
};

export const startTrialMealPlan = () => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: `/api/permission/start-trial-meal-plan`,
        },
        true,
        (response, { dispatch }) => {
          if (get(response, 'data.data.success', false)) {
            dispatch(initPermission());
          }
        },
      ),
    );
  };
};
