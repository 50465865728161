import styled from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';

export const ConfirmWrapper = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container.new-style.cancel-with-product .ui.button {
    &.button.confirm-yes-button {
      background: #ffc445;
      width: 147px;
      padding: 5px 0px;
      font-family: 'Open Sans';
    }
    &.confirm-no-button {
      padding: 5px 0px !important;
      width: 173px;
      font-family: 'Open Sans';
    }
  }
`;

export const Wrapper = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #202353;

  .cancel__description {
    padding-bottom: 15px;
    &.isOneTime {
      padding-bottom: 0;
    }
  }

  .evf-checkbox-circle {
    padding-bottom: 10px;
  }
`;

export const Message = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin-bottom: 24px;
`;
