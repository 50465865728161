import styled, { css } from 'styled-components';

export const Symbol = styled.div`
  width: 22px;
  height: 18px;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
  opacity: 0.4;
  background-color: transparent;
  text-align: right;
  position: absolute;
  ${props =>
    props.isCustom &&
    css`
      left: 15px;
    `}
`;

export const ErrorMessage = styled.div`
  width: 22px;
  height: 15px;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ea314a;
  width: 175px;
  text-align: right;
  position: absolute;
  padding-right: 11px;
`;

export const InputContainer = styled.div`
  position: relative;

  input {
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #dadfea;
    box-sizing: border-box;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: none;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #323c47;
    padding: 11px 164px 11px 29px;
    border-left-color: transparent;
    z-index: 1;
    position: relative;

    :focus {
      border-color: #5158cf;
    }
  }

  ${props =>
    props.invalidValue &&
    css`
      input {
        border-color: #ea314a;
        :focus {
          border-color: #ea314a;
        }
      }
    `}
  ${props =>
    props.isSpecial &&
    css`
      input {
        padding-left: 45px;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-color: #dadfea;
      ~ div {
        z-index: 2;
      }
      &.evfSelectBox__control--is-disabled {
        color: #323c47;
      }
    }
    &.evfSelectBox--is-disabled .evfSelectBox__indicators,
    &.evfSelectBox--is-disabled .evfSelectBox__value-container {
      opacity: 1;
    }
  }
`;
