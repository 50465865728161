// Lib
import React from 'react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import omit from 'lodash/omit';

// Constants
import {
  MAX_NOTE_CHARACTER_LIMIT,
  calculateMacroNutrients,
  getMacroNutrients,
  SORT_BY,
} from 'components/MealPlanDayDetail/constants';
import { LIST_DAY_OF_WEEK } from 'components/MealPlanDetail/constants';
import { RECIPE_STATUS } from 'components/Recipes/constants';

// Shared
import AddNote from 'components/MealPlanDetail/Parts/AddNote';
import NutritionOverviewWrapper from 'components/MealPlanDetail/Parts/NutritionOverview';
import { Button, Toggle } from 'shared/FormControl';
import NoteModal from 'components/MealPlanDetail/Parts/NoteModal';

// Assets
import { ReactComponent as BackIcon } from 'assets/icons/arrow_back.svg';

import * as S from './style';

const DayDetailHeader = props => {
  const {
    history,
    match,
    detailMealPlanByDay,
    detailMealPlanByWeek,
    toggleModal,
    toggleConfirmModal,
    updateMealPlanByDay,
    updateMealPlanDayOfWeek,
    changeModeEditByDay,
    listMealGroup,
    isChange,
    showError,
    getRecipeLibraries,
    filters,
    loadingByDay,
  } = props;

  const { note = '' } = detailMealPlanByDay || {};
  const { week_index = 0 } = detailMealPlanByWeek || {};

  const mealPlanId = get(match, 'params.mealPlanId', '');
  const weekId = get(match, 'params.weekId', '');
  const dayIndex = get(match, 'params.dayIndex', '');
  const params = new URLSearchParams(get(window, 'location.search'));
  const isEdit = params.get('mode');

  const handleGoBack = () => {
    history.push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}`, {
      dayIndexState: dayIndex,
    });
  };

  const handleUpdateNote = (data, type) => {
    if (data.length > MAX_NOTE_CHARACTER_LIMIT) {
      showError('Note length must be less than or equal to 10000 characters long');
      return;
    }

    if (mealPlanId && weekId && dayIndex) {
      updateMealPlanByDay && updateMealPlanByDay({ note: data }, true);
      toggleModal && toggleModal(false);
      switch (type) {
        case 'delete':
          toast('Note deleted.');
          break;
        case 'update':
          toast('Successfully updated.');
          break;
        case 'add':
          toast('Note added.');
          break;
        default:
          break;
      }
    }
  };

  const handleOpenNote = () => {
    const weekIndex = week_index + 1;
    const dayOfWeek = LIST_DAY_OF_WEEK[dayIndex].dayOfWeek;
    const numberOfDay = +dayIndex + 1 + week_index * 7;

    toggleModal &&
      toggleModal(
        true,
        <NoteModal
          title={`Week ${weekIndex} - ${dayOfWeek} (Day ${numberOfDay})'s Note`}
          content={note}
          toggleModal={toggleModal}
          handleUpdateNote={handleUpdateNote}
          toggleConfirmModal={toggleConfirmModal}
        />,
      );
  };

  const handleShowDietary = () => {
    updateMealPlanByDay &&
      updateMealPlanByDay({ show_dietary_info: !get(detailMealPlanByDay, 'show_dietary_info', false) }, true);
  };

  const handleSave = () => {
    if (mealPlanId && weekId && dayIndex) {
      const mealConvert = get(detailMealPlanByDay, 'meal_group', []).map(meal => {
        const { isCreateNew = false, ...restMeal } = meal || {};
        const mealOmitKeys = isCreateNew ? ['_id', 'isCreateNew'] : ['isCreateNew'];
        const mealOmit = omit(restMeal, mealOmitKeys);

        const recipesConvert = get(mealOmit, 'recipes', []).map(({ _id, recipe }) => {
          const { _id: recipeItemId } = recipe || {};

          return { _id, recipe: recipeItemId };
        });

        return { ...mealOmit, recipes: recipesConvert };
      });

      const paramsDetailMealPlanByDay = { ...detailMealPlanByDay, meal_group: mealConvert };

      updateMealPlanDayOfWeek &&
        updateMealPlanDayOfWeek(
          mealPlanId,
          weekId,
          dayIndex,
          omit(paramsDetailMealPlanByDay, ['selected', 'headerTitle', 'isChange']),
        ).then(res => {
          let message = '';
          const dayOfWeek = LIST_DAY_OF_WEEK[dayIndex].dayOfWeek;
          const weekIndex = week_index + 1;

          changeModeEditByDay && changeModeEditByDay(true);
          if (!isEdit) {
            message = `${dayOfWeek}, Week ${weekIndex} has been saved.`;
            history.push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}/days/${dayIndex}?mode=edit`);
          } else {
            message = `Successfully updated.`;
          }

          toast(message);

          if (get(res, 'data.data.error_recipes.length', 0) > 0) {
            typeof getRecipeLibraries === 'function' &&
              getRecipeLibraries({
                ...filters,
                status: JSON.stringify([RECIPE_STATUS.PUBLISH]),
                sorter: SORT_BY.MOST_RECENT,
                sort: -1,
              });
          }
        });
    }
  };

  const macroNutrients = calculateMacroNutrients(listMealGroup);
  const listNutrients = getMacroNutrients(macroNutrients);

  return (
    <S.HeaderWrapper>
      <S.HeaderLeft>
        <S.BackWrapper onClick={handleGoBack}>
          <BackIcon />
          Back
        </S.BackWrapper>
        <S.Separate />
        <AddNote
          content="Leave a note for this day"
          hasNote={get(detailMealPlanByDay, 'note', '')}
          onClick={handleOpenNote}
        />
        <S.Separate />
        <NutritionOverviewWrapper label="Daily Nutrition Total" list={listNutrients} />
      </S.HeaderLeft>
      <S.HeaderRight>
        <Toggle
          className="toggle-dietary-info"
          disabled={false}
          checked={get(detailMealPlanByDay, 'show_dietary_info', false)}
          onChange={handleShowDietary}
          width={28}
          height={16}
          label={<span className="label-dietary-info">Show dietary info</span>}
        />
        <Button className="save-button-day-detail" onClick={handleSave} purple disabled={!isChange || loadingByDay}>
          Save
        </Button>
      </S.HeaderRight>
    </S.HeaderWrapper>
  );
};

export default DayDetailHeader;
