import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .product-list-tabs .ui.text.menu {
    margin: 0px;
    margin-bottom: 35px;
  }
  .product-list-tabs .ui.text.menu .item {
    margin-right: 30px;
    padding: 0px;
    text-decoration: none !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    opacity: 0.6;
    padding: 12px 0px;
    &.active {
      color: #5158cf;
      opacity: 1;
      box-shadow: inset 0px -2px 0px #5158cf;
      font-weight: bold;
    }
  }
`;

export const HeaderArea = styled.div`
  position: relative;
  .search-input {
    width: 265px;
    height: 36px;
    input {
      background-color: #ffffff;
    }
  }
`;

export const TabRight = styled.div`
  display: flex;
  position: absolute;
  height: 43px;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;

  .buttonClassName {
    margin-right: 0;
  }
`;
