import React from 'react';

import { pluralize } from 'utils/commonFunction';
import { getFormProperties } from 'components/OnboardingFlowDetail/constants/helper';

import { ReactComponent as TriggerFormIcon } from 'assets/icons/onboarding-trigger-form.svg';

import * as S from './style';

const TagFormInfo = props => {
  const { triggerQuestions, total_single_choice_questions } = getFormProperties(props.form, {
    isFilterTriggerQuestions: true,
  });
  const numberOfTriggerQuestion = total_single_choice_questions || triggerQuestions.length || 0;

  return (
    <S.FormInfoContent>
      <div className="tag-wrapper">
        <TriggerFormIcon />
        {pluralize('trigger question', numberOfTriggerQuestion, true)}
      </div>
    </S.FormInfoContent>
  );
};

export default TagFormInfo;
