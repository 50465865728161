import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import { getProgressPhotoHistory, deleteProgressPhoto } from 'actions/client';
import NoteProgressPhoto from './component';
import { toggleModal } from 'actions/modal';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import {
  changeMode,
  getProgressPhotoOverviewList,
  updateProgressPhotoFilters,
  selectedPhoto,
  firstLoad,
} from 'redux/progress-photo/actions';

const mapStateToProps = state => {
  const { user, rootReducer, sideBarVisible, router } = state;
  const selectedClient = rootReducer.client.workingClientDetail;
  return {
    timezone: user.timezone,
    selectedClient,
    sideBarVisible,
    date_format: user.date_format,
    router,
  };
};

const actionCreators = {
  getProgressPhotoHistory,
  deleteProgressPhoto,
  toggleModal,
  getS3presignedURLFromLocalDatabase,
  changeMode,
  getProgressPhotoOverviewList,
  updateProgressPhotoFilters,
  selectedPhoto,
  firstLoad,
  replaceRouter: replace,
};

export default connect(mapStateToProps, actionCreators)(NoteProgressPhoto);
