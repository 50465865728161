import axios from 'axios';

const CancelToken = axios.CancelToken;
const cancelRequests = [];

export const clearCancelTokens = () => {
  cancelRequests.length = 0;
};

export const saveCancelTokens = new CancelToken(cancelRequest => {
  cancelRequests.push(cancelRequest);
});

export const cancelLastRequest = () => {
  if (Array.isArray(cancelRequests) && cancelRequests.length > 0) {
    cancelRequests.forEach(
      cancelRequest => typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_FETCH_FORM_DETAIL'),
    );
  }
};
