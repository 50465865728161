import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { get } from 'lodash';
import { CDN_URL } from 'constants/commonData';
import { Button } from 'shared/FormControl';

import * as S from './style';

const MainForumScreen = props => {
  if (props.forumList.length === 0) {
    return (
      <S.Wrapper>
        <S.Container>
          <S.Content>
            <img
              src={`${CDN_URL}/images/forum_first_screen.svg`}
              alt="community-forums"
              style={{ width: '377.79px' }}
            />
            <div className="text">
              <div className="title">Community Forums</div>
              <div>Start a forum to engage your clients and easily share content with each other.</div>
            </div>
            <Link to="/home/forums/create-forum">
              <Button borderPurple className="button--create-new">
                <svg width="12" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 4.99902V0.499023H4.5V4.99902H0V6.99902H4.5V11.499H6.5V6.99902H11V4.99902H6.5Z"
                    fill="#5158cf"
                  />
                </svg>
                <span>Create First Forum</span>
              </Button>
            </Link>
          </S.Content>
        </S.Container>
      </S.Wrapper>
    );
  } else {
    const firstForumId = get(props, 'forumList[0]._id');
    return <Redirect to={`/home/forums/${firstForumId}/discussion`} />;
  }
};

const mapStateToProps = state => {
  return {
    forumList: state.rootReducer.forum.list,
  };
};

export default connect(mapStateToProps)(MainForumScreen);
