// libs
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Modal, Image, Button } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';

// shared
import TeammateDropdown, { TYPE } from 'shared/TeammateDropdown';
import OnboardingFlowSelect from 'shared/OnboardingFlowSelect';

// components

// utils
import { readCSVFile } from './helper';
import { isTeamAdmin } from 'utils/commonFunction';

// assets
import { ReactComponent as HintIcon } from 'assets/icons/hint.svg';

// styles
import {
  FistStepContainer,
  UploadFile,
  SecondStepContainer,
  FooterLeftContainer,
  AssignWrapper,
  AssignLabel,
  AssignContent,
} from './style';
import './style.scss';

const STEPS = [1, 2, 3];
const VALID_CLIENT_CATEGORY = ['In-Person', 'Online', 'Hybrid'];

class ImportClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isSubmitting: false,
      file: null,
      error: false,
      step: 1,
      assignTo: props.user,
      assignOnboarding: null,
    };
    this.inputFile = React.createRef();
  }

  onClose = () => {
    const { toggleModal } = this.props;
    return toggleModal(false);
  };

  submitClients = () => {
    const { permission } = this.props;
    const { list, isSubmitting, error, assignTo, assignOnboarding } = this.state;

    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;
    const onboarding_flow_id = hasPermissionMultiple ? _.get(assignOnboarding, '_id') : null;
    const onboarding_flow_type =
      hasPermissionMultiple && onboarding_flow_id === 'not_using' ? onboarding_flow_id : undefined;

    if (isSubmitting || error) {
      return false;
    }

    const { submitMultipleClients, getAllSegments, getClientsFullData } = this.props;

    this.setState({ isSubmitting: true });
    submitMultipleClients({
      data: list,
      autoClose: false,
      fromCSV: true,
      assignTo,
      onboarding_flow_id,
      onboarding_flow_type,
    })
      .then(response => {
        const { success } = response.data.data;
        this.setState({ isSubmitting: false, step: 3 });
        if (success) {
          getAllSegments && getAllSegments();
          getClientsFullData && getClientsFullData();
        }
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  onSelectFile = async event => {
    const { target } = event;

    if (!target || !target.files || !target.files.length) {
      return false;
    } else {
      const file = target.files[0];
      try {
        const data = await readCSVFile(file);
        const { list, error } = data;
        const importError =
          !!_.find(list, item => !VALID_CLIENT_CATEGORY.includes(item.client_info.client_type)) || error;

        this.setState({ list, error: importError, file });
      } catch (e) {
        console.log(e);
      }
    }
  };

  selectStep = selectedStep => {
    const { step, list } = this.state;

    if (step === 3 || selectedStep === 3 || (selectedStep === 2 && !list.length)) {
      return false;
    }

    this.setState({ step: selectedStep });
  };

  renderFirstStep = () => {
    const { step, file } = this.state;

    if (step !== 1) {
      return null;
    }

    return (
      <FistStepContainer>
        <div className="form-group">
          <label>Upload your CSV</label>
          <UploadFile className="upload-container">
            <div className="name">{file ? file.name : ''}</div>
            <div className="browse" onClick={() => this.inputFile.current.click()}>
              Browse
            </div>
            <input type="file" accept=".csv" ref={this.inputFile} onChange={this.onSelectFile} />
          </UploadFile>
          <div className="tip">
            Please download the{' '}
            <a href="/download/Clients.csv" download>
              <span>CSV template here</span> <Image src="/images/download.svg" />
            </a>
            . Then add your client info and upload.
          </div>
        </div>
        <div>
          In the next step, you will get to review the list of uploaded clients before saving to our database. Entries
          with duplicate or existing email accounts will be removed. And client invitation emails will only be sent to
          clients you specify.
        </div>
      </FistStepContainer>
    );
  };

  renderSecondStep = () => {
    const { step, list } = this.state;

    if (step !== 2) {
      return null;
    }

    return (
      <SecondStepContainer>
        <div className="clients__table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="table__header">First Name</div>
                </th>
                <th>
                  <div className="table__header">Last Name</div>
                </th>
                <th>
                  <div className="table__header">Email</div>
                </th>
                <th>
                  <div className="table__header">Category</div>
                </th>
                <th>
                  <div className="table__header send-email-column">Send Invite</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(list, (item, index) => (
                <tr key={index}>
                  {_.map(item.client_info, (value, key) => (
                    <td
                      key={key}
                      className={classNames(key, {
                        missing:
                          (key === 'email' && item.send_mail && !value) ||
                          (key === 'client_type' && !VALID_CLIENT_CATEGORY.includes(value)),
                      })}
                    >
                      <div>{value}</div>
                    </td>
                  ))}
                  <td className="send_email">{item.send_mail && <Image src="/images/check_mark_purple.svg" />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SecondStepContainer>
    );
  };

  renderThirdStep = () => {
    const { step } = this.state;

    if (step !== 3) {
      return null;
    }

    return (
      <div className="import__step-3">
        <h3>Your request is being processed!</h3>
        <p>We will notify you via email when the upload is complete.</p>
      </div>
    );
  };

  renderActionButtons = () => {
    const { step, list, isSubmitting, error } = this.state;
    const { toggleModal } = this.props;

    return (
      <div>
        {step === 1 && (
          <Button
            onClick={() => (list.length ? this.setState({ step: step + 1 }) : null)}
            disabled={!list.length}
            className={'purple'}
          >
            Next
          </Button>
        )}

        {step === 2 ? (
          <React.Fragment>
            <Button className="back" onClick={() => (isSubmitting ? null : this.setState({ step: 1 }))}>
              Back
            </Button>
            <Button onClick={this.submitClients} className={'purple'} disabled={!!error}>
              Add Clients
            </Button>
          </React.Fragment>
        ) : null}

        {step === 3 && (
          <Button onClick={() => toggleModal(false)} className={'purple'}>
            Finish
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { isModalOpen, user, cloudfrontList, toggleConfirmModal, toggleSecondModal, permission } = this.props;
    const { step, error, list, assignOnboarding } = this.state;
    const title = step === 2 ? 'Review Info' : step === 3 ? 'Confirmation' : 'Import File';

    const isAdminOrOwner = isTeamAdmin(user);
    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

    return (
      <Modal
        open={isModalOpen}
        onClose={this.onClose}
        className="multiple-client-modal multiple-client-modal--import"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Button onClick={this.onClose} className="close-button">
            <Image src="/images/close_circle.svg" />
          </Button>
          <div className="modal__header">
            <h3>Add Clients</h3>
            <h2>{title}</h2>
          </div>
          <div className="progress">
            {_.map(STEPS, item => (
              <div key={item} className={step >= item ? 'step completed' : 'step'}>
                {item !== 1 && <div className="bar" />}
                <div className="circle" onClick={() => this.selectStep(item)}>
                  {item}
                </div>
              </div>
            ))}
          </div>
          <div className="steps" />
        </Modal.Header>
        <Modal.Content>
          {this.renderFirstStep()}
          {this.renderSecondStep()}
          {this.renderThirdStep()}
        </Modal.Content>
        <Modal.Actions>
          <FooterLeftContainer>
            {step === 2 && (
              <>
                <div className="total-clients">
                  <div className="number">{list.length > 9 ? list.length : `0${list.length}`}</div>
                  {list.length > 1 ? 'clients' : 'client'}
                </div>
                {isAdminOrOwner && (
                  <AssignWrapper>
                    <AssignLabel>Assign To</AssignLabel>
                    <AssignContent>
                      <TeammateDropdown
                        type={TYPE.IMPORT}
                        menuPlacement="top"
                        assignTo={this.state.assignTo}
                        onChange={it => this.setState({ assignTo: it })}
                      />
                    </AssignContent>
                  </AssignWrapper>
                )}
                {hasPermissionMultiple && (
                  <div className="onboarding-flow">
                    <div className="onboarding-flow-label">
                      <div className="custom-import-csv">
                        ONBOARDING FLOW
                        <div className="custom-label">
                          (OPTIONAL){' '}
                          <HintIcon className="custom-hint-icon" data-tip data-for="tooltip-onboarding-flow" />
                        </div>
                      </div>
                      <ReactTooltip
                        id="tooltip-onboarding-flow"
                        effect="solid"
                        place="top"
                        className="app-tooltip onboarding-flow-tooltip"
                      >
                        <span>
                          With the Automation add-on, you can select different Onboarding Flows to assign to this
                          client.
                        </span>
                      </ReactTooltip>
                    </div>
                    <OnboardingFlowSelect
                      type={TYPE.IMPORT}
                      value={assignOnboarding}
                      onChange={it => this.setState({ assignOnboarding: it })}
                      cloudfrontList={cloudfrontList}
                      toggleConfirmModal={toggleConfirmModal}
                      toggleSecondModal={toggleSecondModal}
                    />
                  </div>
                )}
              </>
            )}
            {error ? (
              <div className={classNames('error', { hasMultipleOnb: hasPermissionMultiple })}>
                Please check the imported data
              </div>
            ) : null}
          </FooterLeftContainer>
          {this.renderActionButtons()}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ImportClients;
