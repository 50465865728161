import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router';
import { push } from 'connected-react-router';
import { diff } from 'deep-diff';

import { updateOndemandTitle, getOnDemandSettingTitle } from 'redux/on-demand-settings/actions';
import { toggleConfirmModal } from 'actions/modal';
import useStateCallback from 'hooks/useStateCallback';
import OnDemandSettingInput from 'components/StudioOnDemandSettings/OnDemandSettingInput';
import WarningIcon from 'assets/icons/warning_red_light.svg';
import * as S from './style';

function StudioOnDemandSettings({
  updateOndemandTitle,
  title,
  toggleConfirmModal,
  getOnDemandSettingTitle,
  loading,
  push,
}) {
  const [isChanged, setIsChanged] = useStateCallback(false);
  const [focused, setFocused] = useState(false);
  const [updatedTitle, setUpdateTitle] = useState(null);
  const [isOpenSave, setIsOpenSave] = useState(false);

  useEffect(() => {
    setUpdateTitle(title);
  }, [title]);

  useEffect(() => {
    getOnDemandSettingTitle && getOnDemandSettingTitle();
  }, []);

  useEffect(() => {
    if (!diff(updatedTitle, title)) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [updatedTitle, title]);

  const handleTitleChange = (title, action) => {
    setUpdateTitle(title);
    if (action === 'typing') {
      setIsOpenSave(true);
    }
  };
  const truncateTitle = (title, limit) => {
    if (title && title.length > limit) {
      return title.substring(0, limit);
    } else if (title && title.length >= 1) {
      return title;
    } else {
      return 'On-demand';
    }
  };

  const renderTitle = () => {
    if (!loading) {
      if (isChanged) {
        return updatedTitle ? truncateTitle(updatedTitle, 20) : 'On-demand';
      } else {
        return truncateTitle(title, 20);
      }
    }
  };

  const iconTitle = () => {
    if (!loading) {
      if (isChanged) {
        return updatedTitle ? truncateTitle(updatedTitle, 10) : 'On-demand';
      } else {
        return truncateTitle(title, 10);
      }
    }
  };

  const saveTitle = () => {
    updateOndemandTitle(updatedTitle);
    setIsChanged(false);
    setIsOpenSave(false);
  };

  const handleDiscardChange = nextLocation => {
    toggleConfirmModal(
      true,
      <S.DiscardModal
        title={'Discard Changes'}
        content={`Are you sure to discard all the changes?`}
        onConfirm={() => {
          setIsChanged(false, () => push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard"
        cancelButtonTitle="Cancel"
        headerIcon={WarningIcon}
        noBorder
        hasCloseIcon
      />,
    );
    return false;
  };

  const checkFocus = data => {
    setFocused(data);
  };

  return (
    <>
      <S.Wrapper>
        <Prompt when={isChanged} message={handleDiscardChange} />
        <S.LeftPanel>
          <S.MainTitleWrapper>
            <S.MainTitle>General Settings</S.MainTitle>
            {isOpenSave && (
              <S.SaveButton onClick={saveTitle} purple>
                Save
              </S.SaveButton>
            )}
          </S.MainTitleWrapper>
          <S.SubTitle>Title</S.SubTitle>
          <OnDemandSettingInput
            name={title}
            onConfirmChange={(title, action) => handleTitleChange(title, action)}
            checkFocus={checkFocus}
          />
        </S.LeftPanel>
        <S.RightPanel>
          <S.MobileWrapper isFocused={focused}>
            <S.MobileOnFocused isFocused={focused} />
            <S.MobilePreviewContainer>
              <S.MobilePreviewHeader>{renderTitle()}</S.MobilePreviewHeader>
              {focused ? (
                <S.CircleWrapper>
                  <S.OnDemandFocused />
                  <S.CircleBackground />
                  <S.OnDemandIcon className="on-demand-settings__focused" />
                  <S.OnDemandTitle className="on-demand-settings__focused--title">{iconTitle()}</S.OnDemandTitle>
                </S.CircleWrapper>
              ) : (
                <S.OnDemandWrapper>
                  <S.OnDemandIcon />
                  <S.OnDemandTitle>{iconTitle()}</S.OnDemandTitle>
                </S.OnDemandWrapper>
              )}
            </S.MobilePreviewContainer>
          </S.MobileWrapper>
        </S.RightPanel>
      </S.Wrapper>
    </>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: {
      onDemandSettings: { title, loading },
    },
  } = state;

  return {
    title,
    loading,
  };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  updateOndemandTitle: bindActionCreators(updateOndemandTitle, dispatch),
  getOnDemandSettingTitle: () => dispatch(getOnDemandSettingTitle()),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(StudioOnDemandSettings);
