import React from 'react';
import * as S from './style';
import { ReactComponent as TodayIcon } from 'assets/icons/app_navbar_icon_today.svg';
import { ReactComponent as CoachingIcon } from 'assets/icons/app_navbar_icon_coaching.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/app_navbar_icon_inbox.svg';
import { ReactComponent as YouIcon } from 'assets/icons/app_navbar_icon_you.svg';

import { THEMES } from 'components/CustomBranding/constants';

const DEFAULT_STATE = {
  splashLogo: { name: '', src: '' },
  workoutBackground: {},
  theme: { ...THEMES[0] },
};

const data = [
  { title: 'Today', component: <TodayIcon /> },
  { title: 'Coaching', component: <CoachingIcon /> },
  { title: 'Inbox', component: <InboxIcon /> },
  { title: 'You', component: <YouIcon /> },
];

export default props => {
  const { active, primaryColor, secondaryColor } = props;
  let theme;
  if (primaryColor && secondaryColor) {
    theme = { primaryColor, secondaryColor };
  } else {
    theme = DEFAULT_STATE.theme;
  }

  return (
    <S.Footer {...theme}>
      {data.map((item, key) => (
        <div className={`item ${active === key && 'active'} ${item.title === 'Today' && 'use-fill'}`} key={key}>
          {item.component}
          <div>{item.title}</div>
        </div>
      ))}
    </S.Footer>
  );
};
