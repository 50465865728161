import React from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';

import BodyMetricChartOverviewNew from 'components/BodyMetricChartOverviewNew';
import StepChartOverviewDraggable from './StepChartOverviewNew';

import { STEP_UNIQUE_CODE } from 'constants/commonData';

const DRAG_TYPE = 'metric-overview';

function MetricOverviewDraggable({ item, timeRange, onClick, units, isDoubleChart, onDrop, isRequesting }) {
  const isStepChart = [item.unique_code, item.unit.unique_code].includes(STEP_UNIQUE_CODE);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAG_TYPE,
      item: item,
      collect: monitor => ({ isDragging: !!monitor.isDragging() }),
    }),
    [item],
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAG_TYPE,
      drop: itemDragged => {
        onDrop({ target: item, source: itemDragged });
      },
      collect: monitor => ({ isOver: !!monitor.isOver() }),
    }),
    [item],
  );

  return (
    <div className={classNames('drag-container', { 'drag-over': isOver && !isDragging })} ref={drop}>
      <div
        className={classNames('training-overview-period-new', { dragging: isDragging })}
        onClick={onClick}
        ref={isRequesting ? undefined : drag}
      >
        {isStepChart ? (
          <StepChartOverviewDraggable
            isDefaultStep={item.unique_code === STEP_UNIQUE_CODE}
            uniqueCode={item.unique_code}
            metricId={(item || {})._id}
            name={item.name}
            timeRange={timeRange}
          />
        ) : (
          <BodyMetricChartOverviewNew metric={item} units={units} isDoubleChart={isDoubleChart} timeRange={timeRange} />
        )}
      </div>
    </div>
  );
}
export default MetricOverviewDraggable;
