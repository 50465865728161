import ConfirmModal from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const NavigatorBarRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const NavigatorBarLeftContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 25px;

  .weeks {
    margin-right: 0;
  }

  .dropdown--addWeek {
    margin-left: 25px;
  }
`;

export const NavigatorBarCenter = styled.div`
  padding: 0 25px;
  flex: 1 1;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  height: 100%;
`;

export const NavigatorBarContainer = styled.div`
  padding: 7px 30px 7px 30px;
  margin-bottom: 10px;
  box-shadow: inset 0px -1px 0px #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WeekIndicatorContainer = styled.div`
  position: absolute;
  left: 7px;
  top: 2px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .week__title {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    opacity: 0.2;
    text-transform: uppercase;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    cursor: default;
  }

  .delete-icon {
    margin-bottom: 10px;
    cursor: pointer;
    &.deleting {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        background-image: url(${CDN_URL}/images/new_delete.svg);
      }
    }
  }

  ${props =>
    props.onlyTitle &&
    css`
      padding-top: 21px;
    `}
`;

export const AddWeekIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 50%;
  transform: translateX(-50%);
  top: -11px;
  background: #5c5bbd url(${CDN_URL}/images/plus_white.svg) no-repeat center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 3px;
  z-index: 1;
  &.adding {
    cursor: not-allowed;
  }
`;

export const AddWeekContainer = styled.div`
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 30px;
  right: 30px;
  top: 100%;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  transition: background-color 0.2s ease;
  z-index: 99;
  &.adding:hover {
    cursor: not-allowed;
    opacity: 0.5;
  }

  :hover {
    background-color: #5c5bbd;

    ${AddWeekIcon} {
      opacity: 1;
    }
  }

  &.lastLine {
    ${AddWeekIcon} {
      top: unset;
      bottom: 0;
    }
  }

  &.firstLine {
    top: -10px;

    ${AddWeekIcon} {
      top: 0;
    }
  }
`;

export const CopyWrapper = styled.div`
  position: absolute;
  height: 100%;
  left: 1px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: none;
  flex-direction: column;
  gap: 17px;
  padding-right: 2px;
  border-radius: 2px;

  :hover {
    background-color: #e7e8ff;
    border-right: none !important;
  }

  .__react_component_tooltip.place-right {
    margin-left: 13px;
  }
  .__react_component_tooltip.place-right:after {
    left: -5px !important;
  }

  #copy-week-on-tooltip,
  #remove-week-on-tooltip {
    padding: 5px 15px !important;
    border-radius: 5px !important;
    background: #2d2e2d !important;

    div {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }
  }
`;

export const IconWrapper = styled.div`
  svg:hover {
    cursor: pointer;
    path {
      stroke: #5158cf;
    }
  }
`;

export const PasteWeek = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 60px);
  background-color: rgba(108, 115, 154, 0.1);
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: none;
  border-radius: 2px;
`;

export const PasteButton = styled.div`
  width: 247px;
  height: 34px;
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  border-radius: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
`;

export const GridRow = styled.div`
  padding: 0 30px 0 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  &:hover {
    ${CopyWrapper} {
      display: flex;
      border-right: 1px solid #202353;
    }
    ${PasteWeek} {
      display: flex;
    }

    .week__title.hide-week__title {
      display: none;
    }
  }

  ${props =>
    props.isCopingWeek &&
    css`
      .autoflowInterval__assignMenu {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
      }
    `}
`;

export const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${GridRow} {
    height: ${props => `calc((100% - ${(props.numRows - 1) * 10}px) / ${props.numRows})`};
  }
`;

export const CalendarContainer = styled.div`
  height: calc(100% - 55px);
  padding-bottom: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CancelChangeButton = styled.button`
  outline: none;
  box-shadow: none;
  border: 1px solid #d6dae4;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #818da1;
  padding: 5px 29px 5px 44px;
  background: #fff url(${CDN_URL}/images/close_818DA1.svg) no-repeat;
  background-position: 29px center;
  background-size: 12px 12px;

  :hover {
    cursor: pointer;
  }
`;

export const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
  padding-right: 22px;
  position: relative;

  :after {
    content: '';
    position: absolute;
    width: 2px;
    height: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #e8e8e8;
  }

  > * {
    margin-right: 5px;

    :last-child {
      margin: 0;
    }
  }

  button {
    &.button--turn-on-edit-mode {
      padding: 5px 29px 5px 46px;
      background-image: url(${CDN_URL}/images/edit_white.svg);
      background-repeat: no-repeat;
      background-position: 29px center;
      background-size: 12px 12px;
    }

    &.button--publish-disabled {
      background-color: #c4c4c4;
    }
  }
`;

export const AlertBottomWrapper = styled.div`
  max-height: 61px;
  height: 61px;
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding-left: 25px;
  padding-right: 34.5px;
  z-index: 1;

  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    padding-top: 1px;
  }

  .cancel-copy {
    cursor: pointer;
    margin-left: 15px;
  }

  .cancel-copy-week {
    cursor: pointer;
    margin-left: 11px;
  }
`;

export const SelectedWeekText = styled.div`
  margin-left: -3px;
`;

export const SelectedNumberWorkoutWrapper = styled.div`
  font-weight: 600;
`;

export const SelectedWorkoutNumber = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 43px;
  height: 21px;
  background: #303030;
  border-radius: 999px;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 16px;
  color: white !important;
  margin-right: 3px;
`;

export const SelectedWorkoutText = styled.div`
  margin-left: 2px;
`;

export const SelectedWorkoutAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-right: 52px;

  .tooltip--remove,
  .tooltip--copy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
`;

export const IconActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
`;

export const TextActions = styled.span`
  color: #728096;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const BottomActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    ${IconActions} {
      svg {
        path {
          fill: #5e59ff;
        }
      }
    }
    ${TextActions} {
      color: #5e59ff;
    }
  }
`;

export const BottomCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
  }
`;

export const EscText = styled.strong``;

export const TextCancel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CancelBold = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const RemovePopup = styled(ConfirmModal)`
  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;

export const RemoveAllWorkoutInWeekPopup = styled(ConfirmModal)`
  border-radius: 8px !important;

  .confirm-popup-container.remove-workout-in-week-popup .confirm-content-header {
    padding: 30px 30px 15px !important;
  }

  .confirm-header-image {
    width: 25px !important;
    height: 25px !important;
  }

  .ui.button.confirm-no-button {
    width: 102px !important;
    min-width: 102px !important;
  }

  .ui.button.confirm-yes-button {
    width: 111px !important;
    min-width: 111px !important;
  }

  .ui.button {
    font-family: 'Open Sans' !important;
  }

  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;
