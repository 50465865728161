import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';

export const ClientListContainer = styled.div`
  padding: 30px 20px;

  > div {
    display: block;
    height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .viewClient__noClients {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--text {
      color: #b5b5b5;
      font-size: 13px;
      margin-top: 10px;
    }
  }

  .item {
    float: left;
    width: calc(100% / 3);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #263d52;
    padding: 0 10px 24px;
    cursor: pointer;
    overflow: hidden;

    .info {
      overflow: hidden;
      color: #263d52;
    }

    .clientAvatar__avatar.sb-avatar {
      margin: 0;
      flex: 0 0 36px;
    }

    .name {
      margin-bottom: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .date {
      font-size: 11px;
      color: #5a636c;
      font-weight: normal;
    }

    .clientAvatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;
      height: 36px;
      width: 36px;
      &__checkbox {
        display: none;
        width: 36px;
        .checkbox-icon {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__avatar {
        border-radius: 42.5px;
        font-family: 'Open Sans' !important;
        font-size: 20px !important;
        object-fit: cover;
        margin: 0;
      }

      &--checked {
        .clientAvatar__checkbox {
          display: block;
        }

        .clientAvatar__avatar {
          display: none !important;
        }
      }
    }

    :hover {
      color: #494699;
      .date,
      .name {
        color: #494699;
      }
      ${props =>
        props.isCompeleted
          ? ''
          : css`
              .clientAvatar {
                &__checkbox {
                  display: block;
                }

                &__avatar {
                  display: none !important;
                }
              }
            `}
    }
  }
`;

export const Tabs = styled.div`
  border-bottom: 1px solid #dcdcde;
  padding: 0 30px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .tab {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    cursor: pointer;
    position: relative;

    .name {
      position: relative;
      margin-right: 3px;
    }

    &.active,
    &:hover {
      color: #494699;

      .name::after {
        display: block;
      }
    }

    &.active {
      font-weight: bold;
      ::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #494699;
      }
    }
  }
`;

export const AssignMoreClient = styled(Button)`
  background: #ffffff;
  border: 1px solid #d4d7d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06) !important;
  border-radius: 5px;
  padding: 7px 14px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #777777;
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div``;
