import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const textBase = `
    color: #202353;
    font-family: 'Open sans';
    font-style: normal;
    line-height: 150%;
`;

const lineClamp1 = `
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`;

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-meal-plan-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 656px;
  }
  &.ui.modal.evf-meal-plan-modal > .header,
  &.ui.modal.evf-meal-plan-modal > .content,
  &.ui.modal.evf-meal-plan-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  .close-button {
    position: absolute;
    display: flex;
    top: -8.5px;
    right: -8.5px;
    img {
      flex-shrink: 0;
    }
  }
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
  ${lineClamp1}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding: 15px 30px;
  background-color: #ffffff;
`;

export const Title = styled.div`
  ${textBase}
  color: #7B7E91;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const UploadWrapper = styled.div`
  width: 242px;
  height: 161px;
`;

export const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  ${textBase}
  color: #7B7E91;
  font-size: 12px;
  font-weight: 400;
  user-select: none;
  span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-upload-content {
    color: #5158cf;
    font-weight: 600;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const Hint = styled.div`
  ${textBase}
  color: #7B7E91;
  font-size: 11px;
  font-weight: 400;
  margin-top: 7px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  .formControl {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    > div {
      line-height: 15px;
    }
    > div:first-child {
      color: #7b7e91;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 3px;
      width: 100%;
      .label__maxLength {
        ${textBase}
        color: #7B7E91;
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        opacity: 1;
      }
    }
    input,
    textarea {
      ${textBase};
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      padding: 10px;
      border: 1px solid #e1e1ea;
      border-radius: 5px;
      ::placeholder {
        color: #a3a3b5;
      }
    }
    textarea {
      height: 140px;
      margin-top: 2px;
      ::placeholder {
        font-size: 13px;
      }
    }
    input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
      background: #ffffff;
    }
  }
  .field {
    user-select: none;
  }
  .field.client_type_field {
    display: flex;
  }
  .ui.dropdown.ownership-dropdown {
    padding: 10px 34px 10px 15px;
    .dropdown.icon::before {
      right: 9px;
    }
    .text,
    &.visible > .text:not(.default) {
      font-weight: 400;
    }
  }
  .ui.selection.active.dropdown {
    border-radius: 5px !important;
  }
  .ui.dropdown.ownership-dropdown:hover {
    background-color: #fff !important;
    border: 1px solid #5158cf !important;
  }
`;

export const Footer = styled.div`
  padding: 15px 30px;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 11px 45px 9px;
  border: none;
  border-radius: 5px;
  ${textBase}
  background: #5158CF;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
  min-width: unset;
  cursor: pointer;
  user-select: none;

  :disabled {
    cursor: not-allowed;
    background: #eaeef1;
    color: #7b7e91;
  }
`;
