import React from 'react';
import ReactTooltip from 'react-tooltip';
import { DateTime } from 'luxon';

import { ReactComponent as TrashIcon } from 'assets/icons/trash-calendar.svg';
import { checkSameMonth } from 'utils/commonFunction';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

function ButtonRemoveWeek(props) {
  const { calendarStartDate, index, toggleConfirmModal, handleRemoveWeek } = props;
  const onDeleteWeek = () => {
    const day = DateTime.fromISO(calendarStartDate.toISODate());
    const deleteFromDate = day.plus({ days: 7 * index });
    const deleteToDate = day.plus({ days: 7 * index + 6 });
    const isSameMonth = checkSameMonth(deleteFromDate, deleteToDate);
    const deleteFromDateFormat = deleteFromDate.toFormat('LLL d');
    const deleteToDateTitleFormat = isSameMonth ? deleteToDate.toFormat('d') : deleteToDate.toFormat('LLL d');
    const deleteToDateContentFormat = deleteToDate.toFormat('LLL d');
    const startDateRemove = deleteFromDate.toFormat('MM-dd-yyyy');
    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove workouts from ${deleteFromDateFormat} - ${deleteToDateTitleFormat}`}
        content={`All workouts from ${deleteFromDateFormat} to ${deleteToDateContentFormat} will be deleted. Would you like to continue?`}
        onConfirm={() =>
          handleRemoveWeek && handleRemoveWeek({ startDateRemove, deleteFromDateFormat, deleteToDateContentFormat })
        }
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
        className="remove-week-popup"
      />,
    );
  };
  return (
    <>
      <TrashIcon data-tip data-for={`tooltip-trash-${index}`} onClick={onDeleteWeek} />
      <ReactTooltip className="app-tooltip" id={`tooltip-trash-${index}`} effect="solid" place="right">
        Remove Week
      </ReactTooltip>
    </>
  );
}

export default ButtonRemoveWeek;
