import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #202353;
`;

export const Calendar = styled.div`
  ${baseText}
  border: 1px solid #f7f7f7;
  border-radius: 12px;
  text-align: center;
  height: 407px;
  .calender-habit {
    height: 100%;
    .rdtPicker {
      position: relative;
      width: 365px;
      height: 100%;
      padding: 26px 0;
      margin: auto;
      box-shadow: none;
      border: none;
      table {
        height: 100%;
      }
      .rdtDays {
        height: 100%;
      }
      th {
        border-bottom: none;
      }
      td {
        &.rdtActive {
          background-color: transparent;
          color: unset;
          text-shadow: none;
        }
      }
      thead {
        .dow {
          ${baseText}
          text-transform: uppercase;
        }
        .rdtSwitch {
          pointer-events: none;
          ${baseText}
          line-height: 23px;
        }
        .rdtPrev {
          color: #c5c5d1;
          span {
            display: none;
          }
          &::after {
            content: url(${CDN_URL}/images/icon-calendar-change-month.svg);
            position: absolute;
            top: 32px;
            left: 7px;
          }
          &.disabledBtnNext {
            pointer-events: none;
            opacity: 0.3;
          }
        }
        .rdtNext {
          color: #c5c5d1;
          span {
            display: none;
          }
          &::after {
            content: url(${CDN_URL}/images/icon-calendar-change-month.svg);
            display: inline-block;
            transform: rotate(180deg);
            position: absolute;
            top: 30px;
            right: 6px;
          }
          &.disabledBtnNext {
            pointer-events: none;
            opacity: 0.3;
          }
        }
        tr:first-child {
          th {
            padding-bottom: 20px;
          }
        }
        tr:last-child {
          th {
            border-bottom: 1px solid #f7f7f7;
            height: 40px;
          }
        }
        th:hover {
          background: transparent;
        }
      }
      tfoot {
        display: none;
      }
      tbody {
        td {
          padding: 0;
          &.tdNotAllowed {
            cursor: not-allowed;
          }
          &:hover {
            background: transparent;
          }
        }
        .weekHide {
          display: none;
        }
        .rdtToday::before {
          content: none;
        }
        .rdtActive {
          color: unset;
        }
      }
      .rdtDays {
        tbody {
          ${props =>
            props.selectWeek &&
            css`
              tr {
                position: relative;
                td {
                  position: relative;
                }
                td::after {
                  content: '';
                  background: transparent;
                  width: calc(100% + 5px);
                  height: 40px;
                  position: absolute;
                  z-index: -2;
                  top: calc(50% + 4px);
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                td:first-child::after {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
                td:last-child::after {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
                &:hover td::after {
                  background: #f2f4f9;
                }
              }
              tr:has(.selectWeek) {
                td::after {
                  content: '';
                  background: #f2f4f9;
                  width: calc(100% + 5px);
                  height: 40px;
                  position: absolute;
                  z-index: -2;
                  top: calc(50% + 4px);
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            `};
        }
      }
    }
  }
`;
