import axios from 'axios';
import _ from 'lodash';
import { axiosInstanceWithoutRefreshToken, axiosInstanceWithoutToken, axiosInstance } from 'configs/request';
import { isMobile } from 'react-device-detect';
// import { datadogRum } from '@datadog/browser-rum';
import { generateS3DataToPresigned, generateVimeoAPIUrl } from './commonFunction';
import { isS3FileURL, validateVimeoUrl } from './validations';
import { POST_THUMB_DEFAULT } from 'constants/commonData';

const CancelToken = axios.CancelToken;
const cancelRequests = [];

export const removeUploadedFileFromServer = bucketData => {
  const data = _.pick(bucketData, ['attachment_id', 'bucket', 'folder', 'file_extension']);
  return axiosInstanceWithoutRefreshToken.post('/api/media/remove', { data });
};

export const logRequestError = (title, error, email = null) => {
  const data = {
    title,
    detail: error,
    device: isMobile ? 'mobile-web' : 'web',
    os: navigator.userAgent,
  };
  // datadogRum.addError(data);
  if (email) {
    data.email = email;
  }
  return axiosInstanceWithoutToken.post('/api/issue/self-report', data);
};

export const removeOldTokenSavedOnLocal = () => {
  const oldTokenStr = localStorage.getItem('last_user_token');

  if (oldTokenStr) {
    try {
      const tokens = JSON.parse(oldTokenStr);
      const token = tokens ? tokens['access-token'] : '';

      if (token) {
        axiosInstanceWithoutToken.post('/api/auth/logout', null, { headers: { 'x-access-token': token } });
      }
    } catch (e) {}
  }
};

export const generateS3PresignedURL = async (list, expireSeconds = 7200) => {
  try {
    const parsedData = list.map(url => ({ isS3: isS3FileURL(url), url }));
    const s3URLs = _.filter(parsedData, o => !!o.isS3).map(o => o.url);

    if (!s3URLs.length) {
      return list;
    }

    const listRequestURLs = _.map(s3URLs, url => generateS3DataToPresigned(url, expireSeconds));
    const response = await axiosInstance.post('/api/file/gen-presigned-urls', { data: listRequestURLs });
    const presignedURLs = response.data.data;

    let counter = 0;

    _.forEach(parsedData, o => {
      if (o.isS3) {
        o.url = presignedURLs[counter++];
      }
    });

    return _.map(parsedData, 'url');
  } catch (err) {
    return list;
  }
};

export const cancelRequest = () => {
  cancelRequests.forEach(c => typeof c === 'function' && c()); // Cancel each request
  cancelRequests.length = 0; // Reset the cancelRequests array
};

const getVimeoDataFromUrl = async url => {
  try {
    const vimeoUlr = generateVimeoAPIUrl(url);
    const res = await axios.get(vimeoUlr, {
      cancelToken: new CancelToken(c => cancelRequests.push(c)),
    });
    const data = _.get(res, 'data') || {};

    return new Promise(resolve =>
      resolve({
        data: {
          data: {
            author: data.author_name || '',
            description: data.description || '',
            domain: 'Vimeo',
            image: data.thumbnail_url || POST_THUMB_DEFAULT,
            keyword: '',
            publisher: '',
            title: data.title || '',
            url,
            isThumbDefault: !!data.thumbnail_url ? false : true,
          },
        },
      }),
    );
  } catch (error) {
    return new Promise((res, rej) => rej(error));
  }
};

export const getPageMetaDataFromURL = url => {
  if (validateVimeoUrl(url)) {
    return getVimeoDataFromUrl(url);
  }

  const params = { url };
  return axiosInstance.post('/api/general/get-metadata', params, {
    cancelToken: new CancelToken(c => cancelRequests.push(c)),
  });
};

export const cancelPreviousRequest = (isCancel = false, requests = []) => {
  const cancelRequests = requests.slice(requests.length - 1);
  if (isCancel && !!cancelRequests.length) {
    _.forEach(cancelRequests, cancelRequest => typeof cancelRequest === 'function' && cancelRequest('cancel token'));
  }
};
