import _ from 'lodash';
import { Types } from './actions';
import { THEMES, CUSTOM_ONBOARDING_VIDEO_TYPE } from 'components/CustomBranding/constants';

const INITIAL_STATE = {
  workoutBackgrounds: [],
  selectedSplashLogo: { src: '', name: '' },
  selectedSplashBackground: { src: '', name: '' },
  selectedWorkoutBackground: { src: '', name: '', _id: '' },
  selectedTodayScreenLogo: null,
  selectedAppIconKey: null,
  splash_screen_welcome_text_color: '#1e0a3c',
  emailBanner: { src: '', name: '' },
  originalTheme: { ...THEMES[0] },
  loading: true,
  currentTab: 0,
  customOnboardingVideoType: CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT,
  customOnboardingYoutube: null,
  customOnboardingUpload: null,
  customOnboardingDraft: null,
  appIconDefault: null,
  appIconKeySystem: '',
  isLoading: false,
};

const deleteWorkoutBackground = (oldState, id) => {
  const { workoutBackgrounds, selectedWorkoutBackground } = oldState;
  const newData = _.filter(workoutBackgrounds, item => item._id !== id);
  const selectedBackground = selectedWorkoutBackground._id === id ? newData[0] : selectedWorkoutBackground;

  return Object.assign({}, oldState, {
    selectedWorkoutBackground: selectedBackground,
    workoutBackgrounds: newData,
  });
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CUSTOM_BRANDING_GET_DATA:
      return Object.assign({}, state, { loading: true });

    case Types.CUSTOM_BRANDING_ERROR_GET_DATA:
      return Object.assign({}, state, { loading: false });

    case Types.CUSTOM_BRANDING_SUCCESS_GET_DATA:
      const splash_color = _.get(payload, 'data.splash_screen_welcome_text_color') || '#1e0a3c';
      return Object.assign({}, state, {
        ...payload.data,
        splash_screen_welcome_text_color: splash_color,
        loading: false,
      });

    case Types.CUSTOM_BRANDING_SUCCESS_UPDATE_PARTIAL_DATA:
      return Object.assign({}, state, { ...payload.data });

    case Types.CUSTOM_BRANDING_ADD_WORKOUT_BACKGROUND:
      return Object.assign({}, state, { workoutBackgrounds: [...state.workoutBackgrounds, payload.data] });

    case Types.CUSTOM_BRANDING_SUCCESS_DELETE_WORKOUT_BACKGROUND:
      return deleteWorkoutBackground(state, payload.data);

    case Types.CUSTOM_BRANDING_CHANGE_ACTIVE_TAB_REDUX:
      return Object.assign({}, state, { currentTab: payload.index });

    case Types.CUSTOM_BRANDING_CHANGE_WELCOME_TEXT_COLOR:
      return Object.assign({}, state, { splash_screen_welcome_text_color: payload.data });

    case Types.CUSTOM_BRANDING_PREVIEW_DRAFT:
      return Object.assign({}, state, { customOnboardingDraft: payload.data });

    case Types.CUSTOM_BRANDING_SET_DEFAULT_APP_ICON:
      return Object.assign({}, state, { appIconDefault: payload.item });

    case Types.CUSTOM_BRANDING_RESET_DEFAULT_APP_ICON:
      return Object.assign({}, state, { appIconDefault: null });

    case Types.CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_REQUEST:
      return Object.assign({}, state, { isLoading: true });

    case Types.CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_SUCCESS:
      const { dataSystem } = payload;

      return Object.assign({}, state, {
        isLoading: false,
        appIconDefault: dataSystem,
        appIconKeySystem: (dataSystem || {}).key,
        appIconKeySystemUrl: (dataSystem || {}).url,
      });

    case Types.CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_FAILED:
      return Object.assign({}, state, { isLoading: false });

    default:
      return state;
  }
};
