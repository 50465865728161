import styled from 'styled-components';

export default styled.div`
  width: 420px;

  .banner {
    border-radius: 8px;
    position: relative;
    height: 165px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    .info {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 99px;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.8;
      padding: 29px 25px 23px;
      color: #ffffff;
      border-radius: 8px;

      .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      .last-activities {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 18px;

        .label {
          font-weight: bold;
        }
      }
    }

    .action-btn {
      position: absolute;
      right: 10px;
      top: 10px;

      .actions-trigger {
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.2);

        &:hover {
          background: #ffffff;
          .dot,
          .dot::after,
          .dot::before {
            background: #2d2e2d;
          }
        }

        .dot,
        .dot::after,
        .dot::before {
          background: #ffffff;
        }
      }

      .item__content {
        display: flex;

        img {
          width: auto;
          height: auto;
          object-fit: none;
          border-radius: 0;
          margin-right: 11px;
        }
      }
    }
  }

  .hint {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    margin: 20px 0;
    display: flex;

    .text {
      margin-left: 9px;
    }
  }
`;
