/**
 * @flow
 */
import _ from 'lodash';
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';
import Item from 'components/StudioProgram/List/Content/Item';

export type User = {
  _id: string,
  first_name: ?string,
  last_name: ?string,
  email: ?string,
  is_trainer: ?boolean,
  sex: ?string,
  verified: ?boolean,
  trainers: ?Array<User>,
};

export type ValuedUnit = {
  value: number,
  unit: string,
  set_unit: any,
};

export class Category {
  _id: string;
  description: string;
  kind: number;
  title: string;

  categoryFromJSON(json: any): Category {
    if (json) {
      this._id = json._id;
      this.description = json.description;
      this.kind = json.kind;
      this.title = json.title;
    }

    return this;
  }
}

export class Exercise {
  _id: string;
  body_part: string;
  author: Object;
  author_name: string;
  share: Number;
  category_type: string;
  category_type_name: string;
  instructions: Array<string>;
  fields: Array<string>;
  picture: Array<string>;
  title: string;
  link: string;
  video: string;
  videoLink: string;
  preview_50: string;
  preview_300: string;
  thumbnail_url: string;

  exerciseSetFromJSON(json: any): Exercise {
    this._id = json._id || '';
    this.body_part = json.body_part;
    this.picture = json.picture;
    this.picture = json.picture;
    this.category_type = json.category_type;
    this.fields = json.fields;
    this.instructions = json.instructions;
    this.title = json.title;
    this.link = json.link;
    this.video = json.video;
    this.author = json.author;
    this.share = json.share;
    this.videoLink = json.videoLink;
    this.preview_50 = json.preview_50;
    this.preview_300 = json.preview_300;
    this.thumbnail_url = json.thumbnail_url;
    this.custom = json.custom;
    return this;
  }

  constructor(ex, authorId, title) {
    if (!ex) {
      this.instructions = [];
      this.picture = [];
      this.fields = [];
      this.share = TEAM_SHARE_PRIVATE;
      this.author = {
        _id: authorId,
      };

      if (title) {
        this.title = title;
      }
    } else {
      this._id = ex._id || '';
      this.body_part = ex.body_part;
      this.picture = ex.picture;
      this.picture = ex.picture;
      this.category_type = ex.category_type;
      this.category_type_name = ex.category_type_name;
      this.fields = ex.fields;
      this.instructions = ex.instructions;
      this.title = ex.title;
      this.link = ex.link;
      this.author = ex.author;
      this.author_name = ex.author_name;
      this.share = ex.share || TEAM_SHARE_PRIVATE;
      this.video = ex.video;
      this.videoLink = ex.videoLink;
      this.preview_50 = ex.preview_50;
      this.preview_300 = ex.preview_300;
      this.thumbnail_url = ex.thumbnail_url;
      this.custom = ex.custom;
    }
  }
}

export class Set {
  _id: string;
  reps: ?number;
  distance: ?ValuedUnit;
  duration: ?number;
  weight: ?ValuedUnit;
  rest: number;
  is_completed: boolean;

  constructor() {
    this.reps = 0;
    this.duration = 0;
    this.distance = {
      value: 0,
      unit: 'mi',
    };
    this.weight = {
      value: 0,
      unit: 'lb',
    };
    this.rest = 0;
    this.is_completed = false;
  }

  setFromJSON(json: any): Set {
    if (json) {
      this._id = json._id;
      this.reps = json.reps;
      this.distance = json.distance;
      this.duration = json.duration;
      this.weight = json.weight;
      this.rest = json.rest;
      this.is_completed = json.is_completed || false;
    }

    return this;
  }

  isNewSet() {
    if (this.reps || this.distance || this.duration || this.weight || this.rest) {
      return false;
    }
    return true;
  }

  toJSON() {
    return {
      reps: this.reps,
      distance: this.distance,
      duration: this.duration,
      weight: this.weight,
      rest: this.rest,
      is_completed: this.is_completed,
    };
  }
}

export class ExerciseSet {
  exercise: string | Exercise;
  exercise_instance: string | Exercise;
  sets: Array<Set>;
  note: string;
  tempo: string;
  each_side: boolean;
  training_sets: Array<Set>;
  one_rep_max: Any;

  constructor(ex: ?ExerciseSet) {
    if (!ex) {
      this.sets = [];
    } else {
      this.exercise = ex.exercise;
      this.exercise_instance = ex.exercise_instance;
      this.each_side = ex.each_side;
      this.tempo = ex.tempo;
      this.sets = ex.sets;
      this.training_sets = ex.training_sets;
      this.note = ex.note;
      this.alternatives = ex.alternatives;
      this.one_rep_max = ex.one_rep_max;
    }
  }

  setExercise(exercise: string): ExerciseSet {
    this.exercise = exercise;
    return this;
  }

  setNote(note: string): ExerciseSet {
    this.note = note;
    return this;
  }

  isValid(): boolean {
    return (
      this.training_sets.length > 0 &&
      this.exercise_instance !== undefined &&
      (typeof this.exercise_instance === 'string' ? this.exercise_instance.length > 0 : true)
    );
  }
}

export class Section {
  _id: string;
  exercises: Array<Superset>;
  format: string;
  note: string;
  type: string;
  title: string;

  constructor(ex: ?Section) {
    if (!ex) {
      this.exercises = [];
    } else {
      this._id = ex._id;
      this.exercises = ex.exercises;
      this.format = ex.format;
      this.type = ex.type;
      this.note = ex.note;
      this.title = ex.title || '';
    }
  }
}

export type Superset = {
  _id: ?string,
  supersets: Array<ExerciseSet>,
};

export class Workout {
  _id: string;
  author: object;
  date: Date;
  description: string;
  timezone: string;
  // exerciseSet: Array<ExerciseSet>;
  // exerciseSets: Array<Array<ExerciseSet>>;
  training_sets: Array<Superset>;
  sections: Array<Section>;
  title: string;
  records: Array<>;
  day: string;
  last_used: Date;
  last_updated: Date;
  updated_at: Date;
  start_date: Date;
  end_date: Date;
  start_time: string;
  end_time: string;
  status: Number;
  share: Number;
  completed_note: string;
  last_interacted: Date;
  background: string;
  is_high_five: boolean;
  high_fives: Array<>;
  workout_settings: Object;

  constructor(w: ?Workout, authorId: ?string) {
    if (!w) {
      this.training_sets = [];
      this.sections = [];
      this.author = {
        _id: authorId,
      };
      this.share = TEAM_SHARE_PRIVATE;
    } else {
      this._id = w._id;
      this.author = w.author;
      this.share = w.share || TEAM_SHARE_PRIVATE;
      this.date = w.date;
      this.day = w.day;
      this.description = w.description;
      this.timezone = w.timezone;
      this.training_sets = w.training_sets;
      this.sections = w.sections;
      this.c_sections = w.c_sections;
      this.rating = w.rating;
      this.title = w.title;
      this.records = w.records;
      this.last_used = w.last_used;
      this.last_updated = w.last_updated;
      this.updated_at = w.updated_at;
      this.start_date = w.start_date;
      this.end_date = w.end_date;
      this.status = w.status;
      this.completed_note = w.completed_note;
      this.isTrainerTracked = w.isTrainerTracked;
      this.last_interacted = w.last_interacted;
      this.duration = w.duration;
      this.trackedAgent = w.trackedAgent;
      this.background = w.background;
      this.is_high_five = w.is_high_five;
      this.high_fives = w.high_fives;
      this.workout_settings = w.workout_settings;
    }
  }

  convertSectionExerciseInstance(sections, sections_target) {
    let newSections = sections.slice();
    _.forEach(newSections, (section, indexSection) => {
      _.forEach(section.exercises, (exercise, indexExercise) => {
        if (exercise.supersets) {
          _.forEach(exercise.supersets, (exSet, indexExSet) => {
            const systemExercise = _.omit(exSet.exercise, [
              'createdAt',
              'updatedAt',
              '__v',
              'last_used',
              'category',
              'fields',
            ]);
            exSet.exercise_instance = exSet.exercise_instance
              ? Object.assign({}, exSet.exercise_instance, systemExercise)
              : exSet.exercise_instance;

            if (sections_target) {
              exSet.note = _.get(
                sections_target[indexSection],
                `exercises[${indexExercise}].supersets[${indexExSet}].note`,
              );
              exSet.each_side = _.get(
                sections_target[indexSection],
                `exercises[${indexExercise}].supersets[${indexExSet}].each_side`,
              );
              const targetExerciseSet = _.get(
                sections_target[indexSection],
                `exercises[${indexExercise}].supersets[${indexExSet}]`,
              );

              if (targetExerciseSet) {
                exSet.training_sets = exSet.training_sets.map((item, index) => {
                  return { ...item, targetTrainingSet: _.get(targetExerciseSet, `training_sets[${index}]`) };
                });
              }
            }
          });
        }
      });
    });

    return newSections;
  }

  parseFromAssignment(assignment): Workout {
    this._id = assignment._id;
    this.title = assignment.title;
    this.records = assignment.records;
    this.day = assignment.day;
    this.date = assignment.date;
    this.author = assignment.author;
    this.share = assignment.share || TEAM_SHARE_PRIVATE;
    this.description = assignment.description || '';
    this.training_sets = assignment.workout_target;
    this.sections = this.convertSectionExerciseInstance(assignment.sections_target);
    this.c_sections = this.convertSectionExerciseInstance(assignment.sections_complete, assignment.sections_target);
    this.rating = assignment.rating;
    this.status = assignment.status;
    this.start_date = assignment.start_date;
    this.end_date = assignment.end_date;
    this.completed_note = assignment.completed_note;
    this.total_comment = assignment.total_comment;
    this.isTrainerTracked = assignment.isTrainerTracked;
    this.last_interacted = assignment.last_interacted;
    this.originalProgram = assignment.originalProgram;
    this.originalAutoflow = assignment.originalAutoflow;
    this.assignedProgram = assignment.assignedProgram;
    this.duration = assignment.duration;
    this.trackedAgent = assignment.trackedAgent;
    this.background = assignment.background;
    this.is_high_five = assignment.is_high_five;
    this.high_fives = assignment.high_fives;
    this.workout_settings = assignment.workout_settings;
    return this;
  }

  parseFromWorkout(workout): Workout {
    this._id = workout._id;
    this.title = workout.title;
    this.records = workout.records;
    this.day = workout.day;
    this.author = workout.author;
    this.share = workout.share || TEAM_SHARE_PRIVATE;
    this.description = workout.description || '';
    this.training_sets = workout.exercises;
    this.last_used = workout.last_used;
    this.last_updated = workout.last_updated;
    this.sections = this.convertSectionExerciseInstance(workout.sections);

    this.updated_at = workout.updatedAt;
    this.completed_note = workout.completed_note;
    this.start_date = workout.start_date;
    this.end_date = workout.end_date;
    this.status = workout.status;
    this.last_interacted = workout.last_interacted;
    this.background = workout.background;
    this.tags = workout.tags;
    return this;
  }

  setDescription(desc: string): Workout {
    this.description = desc;
    return this;
  }

  setTitle(title: string): Workout {
    this.title = title;
    return this;
  }

  setBackground(background: string): Workout {
    this.background = background;
    return this;
  }

  addSectionSet(exSet: Section): Workout {
    this.sections.push(exSet);
    return this;
  }

  deleteSectionSet(setIndex): Workout {
    this.sections.splice(setIndex, 1);
    return this;
  }

  addExerciseSet(exSet: ExerciseSet, sId): Workout {
    this.training_sets.push({ supersets: [exSet] });
    this.sections = this.sections.map(item => {
      if (item._id === sId) {
        item.exercises.push({ supersets: [exSet] });
      }
      return item;
    });
    return this;
  }

  deleteExerciseSet(setIndex, exIndex): Workout {
    let exSet = this.training_sets[setIndex];
    exSet.supersets.splice(exIndex, 1);
    if (exSet.supersets.length == 0) {
      this.training_sets.splice(setIndex, 1);
    }
    return this;
  }
}

export class ClientInvite {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  type: string;

  setFirstName(fname: string): ClientInvite {
    this.first_name = fname;
    return this;
  }

  setLastName(lName: string): ClientInvite {
    this.last_name = lName;
    return this;
  }

  setEmail(email: string): ClientInvite {
    this.email = email;
    return this;
  }

  setType(type: string): ClientInvite {
    this.type = type;
    return this;
  }
}
