import React, { useState } from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';

import { getUserShortName, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

import * as S from '../styles';

function ShareOwnerAvatar(props) {
  const {
    user,
    className = '',
    delayShow = 0,
    tooltipId,
    tooltipClassName = 'share-owner-avatar-tooltip',
    size = 20,
    cloudfrontList = [],
  } = props;
  if (isEmpty(user) || isEmpty(tooltipId)) return <></>;
  const name = getUserShortName(user);
  const fullName = user.full_name || `${user.first_name || ''} ${user.last_name || ''}`.trim();
  const avatar = convertS3UrlToCloudFrontUrl(user.avatar || '', cloudfrontList, true);
  const color = user.color || '';

  const [isDisable, setDisable] = useState(false);

  const handleMouseOver = e => {
    const { scrollWidth, clientWidth } = e.currentTarget;
    if (clientWidth <= scrollWidth) {
      isDisable && setDisable(false);
    } else {
      !isDisable && setDisable(true);
    }
  };

  const handleMouseOut = () => {
    setDisable(false);
  };

  return (
    <S.ShareOwnerAvatarWrapper data-for={tooltipId} data-tip onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Avatar name={name} className={className} size={size} src={avatar} color={color} />
      <ReactTooltip
        className={tooltipClassName}
        id={tooltipId}
        effect="solid"
        place="top"
        disable={isDisable}
        delayShow={delayShow}
        eventOff="mouseleave scroll mousewheel blur"
      >
        <span>{fullName}</span>
      </ReactTooltip>
    </S.ShareOwnerAvatarWrapper>
  );
}

export default React.memo(ShareOwnerAvatar);
