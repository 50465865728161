import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'shared/FormControl';
import SelectSingleTeamMember from 'shared/SelectSingleTeamMember';
import { axiosInstance } from 'configs/request';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import './style.scss';

function RemoveTeamMember(props) {
  const { user, removedMember } = props;

  const [memberToReassignAsset, setMemberToReassignAsset] = useState();
  const [memberToReassignClient, setMemberToReassignClient] = useState();
  const [submitting, setSubmitting] = useState(false);
  const name = `${removedMember.first_name} ${removedMember.last_name}`;

  const teamMembers = _.filter(user.team_member || [], item => item._id !== removedMember._id);
  const options = _.map(teamMembers, item => ({
    ...item,
    key: item._id,
    value: item._id,
    label: `${item.first_name} ${item.last_name}`,
  }));

  const onSelectMemberToReassignAsset = data => {
    setMemberToReassignAsset(data);
  };

  const onSelectMemberToReassignClient = data => {
    setMemberToReassignClient(data);
  };

  const onRemove = () => {
    if (submitting || !memberToReassignAsset || !memberToReassignClient) {
      return false;
    }

    setSubmitting(true);
    props
      .removeTeamMember({
        removeId: removedMember._id,
        transferClientId: memberToReassignClient._id,
        transferAssetId: memberToReassignAsset._id,
      })
      .then(() => {
        toast(
          <S.Message>
            You've removed {name} from the team. It might take several minutes for the assets and clients to be
            transferred
          </S.Message>,
        );
        props.onClose();

        if (typeof props.onSuccess === 'function') {
          props.onSuccess(removedMember);
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal open={true} onClose={props.onClose} className="modal--remove-team-member" closeOnDimmerClick={false}>
      <Modal.Content>
        <S.Wrapper>
          <S.Header>
            <S.HeaderIcon icon={`${CDN_URL}/images/new_delete_red.svg`} />
            <div>Remove Team Member</div>
          </S.Header>
          <S.Content>
            <p className="title">
              You are about to remove {name} from Everfit.{' '}
              <span className="hightlight">What should happen to {removedMember.first_name}’s assets and clients?</span>
            </p>
            <S.FormGroup>
              <label>
                Reassign exercises, workouts, programs, segments, autoflows, forums, forms, habits, on-demand assets,
                and meal plans & recipes to
              </label>
              <SelectSingleTeamMember options={options} onChange={onSelectMemberToReassignAsset} />
            </S.FormGroup>
            <S.FormGroup>
              <label>Reassign clients to</label>
              <SelectSingleTeamMember options={options} onChange={onSelectMemberToReassignClient} />
            </S.FormGroup>
          </S.Content>
          <S.Actions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              delete
              disabled={!memberToReassignAsset || !memberToReassignClient}
              className="button--remove-team-member"
              onClick={onRemove}
            >
              Remove
            </Button>
          </S.Actions>
        </S.Wrapper>
      </Modal.Content>
    </Modal>
  );
}

RemoveTeamMember.propTypes = {};

export default RemoveTeamMember;
