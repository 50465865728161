import styled from 'styled-components';
import Button from '../Button';

export const Trigger = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #000;
  background: #F3F3F3;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  padding: 12px 10px 11px;
  cursor: pointer;
  user-select: none;

  :hover {
    border-color: #5C5BBD;
  }
`;

export const Label = styled.div`
  margin-bottom: 7px;
`;

export const Input = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #000000;
  height: 36px;
  width: 52px;
  text-align: center;
  background: #FAFBFC;
  border: 1px solid #D4D7D9;
  border-radius: 5px;
  font-family: 'Open Sans';
  outline: none;

  :focus {
    border-color: #5C5BBD;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;

  .ui.dropdown {
    min-width: 85px !important;
    color: #000;

    > .text {
      color: #000 !important;
      font-weight: normal !important;
    }
  }
`;

export const Content = styled.div`
  padding: 15px;

  ${Button} {
    width: 100%;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: calc(100% + 7px);
  left: -100%;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 3px;
  display: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;

  ::before {
    content: '';
    /* box-shadow: -5px -6px 12px rgba(0,0,0,0.1); */
    background-color: #fff;
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
    transform: translateX(-50%) rotate(45deg);
    left: 50%;
    top: -3px;
  }

  &.invalid-time {
    input {
      background: #FFE7E7;
      border: 1px solid #C04D4D;
      border-radius: 5px;
    }

    .ui.dropdown.meridiem-dropdown {
      background: #FFE7E7;
      border: 1px solid #C04D4D;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;

  &.open {
    ${Trigger} {
      border-color: #5C5BBD;
    }

    ${Popup} {
      display: block;
    }
  }
`;