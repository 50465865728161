import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Note from 'components/Note';
import { NoteTypes } from 'components/Note/component';
import ClientOverviewNote from 'components/ClientOverviewNote';
import NoteProgressPhoto from 'components/NoteProgressPhoto';
import HabitOverview from 'components/HabitOverview';
import './styles.scss';

const isGoalCountDownFeature = true;

const NoteOverview = ({ selectedClient, isHabitPermission }) => {
  const headerRef = useRef();
  const isProgressPhotoFeaturing = selectedClient.feature_preferences.find(item => item.type === 'progress_photo')
    .state;
  const [isWrappedHeader, setIsWrapperHeader] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current) {
        const flexContainer = document.querySelector('.goals-overview .panel-header');
        if (flexContainer) {
          const isWrapped = flexContainer.clientHeight;
          const minHeight = 47;
          setIsWrapperHeader(isWrapped > minHeight);
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
      updateHeight();
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, [headerRef.current]);

  return (
    <div className="note-overview-container" ref={headerRef}>
      {isGoalCountDownFeature && (
        <Note
          key={NoteTypes.goal_countdown}
          type={NoteTypes.goal_countdown}
          icon=""
          className={classNames('goals-overview', { isWrapped: isWrappedHeader })}
          title={
            <div className="title-header-note">
              <span className="main-title-goal">Goal & Countdown</span>
              <span className="small-title show"> (Shared with client)</span>
            </div>
          }
        />
      )}
      {isHabitPermission && <HabitOverview />}
      <ClientOverviewNote
        isPrivate={true}
        showOnInbox={false}
        key="note"
        title="Notes"
        type="note"
        icon=""
        selectedClient={selectedClient}
      />
      <ClientOverviewNote
        isPrivate={true}
        showOnInbox={false}
        key="limitation"
        title="Limitations/Injuries"
        type="limitation"
        icon=""
        selectedClient={selectedClient}
      />
      {/* <Note
        key={NoteTypes.goal}
        type={NoteTypes.goal}
        icon=""
        className="goals-overview"
        title={
          <div>
            Goals <span className="small-title show">(shared with client)</span>
          </div>
        }
      /> */}
      {isProgressPhotoFeaturing && <NoteProgressPhoto key="progress_photo" type="progress_photo" icon="" />}
    </div>
  );
};

export default NoteOverview;
