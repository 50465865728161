import React, { useEffect, useState } from 'react';
import { Mixpanel } from 'utils/mixplanel';
import { connect } from 'react-redux';
import diff from 'deep-diff';
import { get, cloneDeep } from 'lodash';

import Upload from '../../Upload';

import { CDN_URL } from 'constants/commonData';

import { ReactComponent as UploadTooltipIcon } from 'assets/icons/splash-screen-upload-tooltip.svg';

import * as S from './style';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const DEFAULT_STATE = {
  todayScreenLogo: { name: '', src: null },
};

const TodayScreen = props => {
  const {
    setTodayScreenLogo,
    todayScreenLogo,
    selectedTodayScreenLogo,
    discard,
    activeIndex,
    onSetCurrentIndex,
    onSetScreenActive,
    onCheckSaving,
    onChangeDiscard,
    isSubmitLogo,
    onSetIsSubmitLogo,
    originalTodayLogoSize,
    cloudfrontList,
  } = props;
  const [todayScreenImage, setTodayScreenImage] = useState(DEFAULT_STATE.todayScreenLogo);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const originalTodayScreenImage = cloneDeep(selectedTodayScreenLogo);

  useEffect(() => {
    const { name, src } = DEFAULT_STATE.todayScreenLogo;

    if (todayScreenLogo) {
      if (isSubmitLogo) {
        onSetIsSubmitLogo(false);
        return;
      }
      setTodayScreenImage({
        ...todayScreenImage,
        src: get(todayScreenLogo, 'src', false) || src,
        name: get(todayScreenLogo, 'name', false) || name,
      });
    }
  }, [todayScreenLogo]);

  useEffect(() => {
    // Checking change
    if (diff(originalTodayScreenImage, todayScreenLogo)) {
      setIsChanged(true);
      onCheckSaving(true);
    } else {
      setIsChanged(false);
      onCheckSaving(false);
    }
  }, [todayScreenImage]);

  useEffect(() => {
    const { name, src } = DEFAULT_STATE.todayScreenLogo;

    if (discard) {
      onChangeDiscard(false);
      onSetCurrentIndex(activeIndex);
      onSetScreenActive(activeIndex);
      onCheckSaving(false);

      // Back Original Today Screen Logo
      setTodayScreenImage({
        ...todayScreenImage,
        src: get(originalTodayScreenImage, 'src', false) || originalTodayScreenImage || src,
        name: get(originalTodayScreenImage, 'name', false) || name,
      });
      setTodayScreenLogo(originalTodayScreenImage || null);
    }
  }, [discard]);

  const onUploadLogoSuccess = value => {
    // TODO - Upload Logo
    setTodayScreenImage({ ...todayScreenImage, ...value });
    setTodayScreenLogo(value);
    setIsLoading(false);
  };

  const onRemoveLogo = () => {
    // TODO - Remove Logo
    setTodayScreenImage(DEFAULT_STATE.todayScreenLogo);
    setTodayScreenLogo(DEFAULT_STATE.todayScreenLogo);
  };

  const renderSaveButton = () => {
    if (isLoading) {
      return <S.SaveButton className="save disable">Save</S.SaveButton>;
    }

    return (
      <S.SaveButton className={`save${isChanged ? '' : ' disable'}`} onClick={handleSubmit}>
        Save
      </S.SaveButton>
    );
  };

  const handleSubmit = () => {
    if (!isChanged) return;

    const {
      theme,
      splashLogo,
      splashBackground,
      originalWelcomeTextColor,
      appIcon,
      updateCustomBrandingPremium,
    } = props;

    const data = {
      primary_color: theme.primaryColor,
      secondary_color: theme.secondaryColor,
      splash_logo: splashLogo.bucketData ? splashLogo.bucketData.url : splashLogo.src,
      splash_logo_name: splashLogo.name,
      splash_background: splashBackground.bucketData ? splashBackground.bucketData.url : splashBackground.src,
      splash_background_name: splashBackground.name,
      app_icon_key: appIcon,
      splash_screen_welcome_text_color: originalWelcomeTextColor,
      today_screen_logo: todayScreenImage.bucketData ? todayScreenImage.bucketData.url : todayScreenImage.src,
      today_screen_file_name: todayScreenImage.bucketData
        ? todayScreenImage.bucketData.original_name
        : todayScreenImage.name,
    };

    const updateData = {
      ...data,
      selectedTodayScreenLogo: {
        src: todayScreenImage.bucketData ? todayScreenImage.bucketData.url : todayScreenImage.src,
        name: todayScreenImage.bucketData ? todayScreenImage.bucketData.original_name : todayScreenImage.name,
      },
    };

    updateCustomBrandingPremium(data, updateData);
    Mixpanel.track('set_today_screen_icon');
    setIsChanged(false);
    onCheckSaving(false);
    onSetIsSubmitLogo(true);
  };

  const getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList) || url;

  const onUploading = value => {
    setIsLoading(value);
  };

  return (
    <S.TodayScreenWrapper>
      <S.LeftArea>
        <S.DescriptionContainer>
          <S.DescriptionText>Add your branding to the Today Screen by uploading a business logo.</S.DescriptionText>
          <S.LinkText>
            <S.Link
              href="https://help.everfit.io/en/articles/6339236-advanced-custom-branding-display-your-logo-icon-on-the-client-app-s-today-screen"
              target="_blank"
            >
              <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" />
              <S.LinkContentText>Today Screen Logo Guide</S.LinkContentText>
            </S.Link>
          </S.LinkText>
        </S.DescriptionContainer>

        <S.UploadLogoContainer>
          <S.DragBoxItem withLeftMargin withMarginTopSmallScreen>
            <label>Add your Logo</label>
            <S.DragBoxElement
              src={getCloudUrl(get(todayScreenImage, 'src', false))}
              horizontalSide={get(originalTodayLogoSize, 'horizontalSide', false)}
              verticalSide={get(originalTodayLogoSize, 'verticalSide', false)}
            >
              <Upload
                key={todayScreenImage.src || ''}
                data={todayScreenImage}
                cloudfrontList={cloudfrontList}
                verifyResolution={{
                  width: 100,
                  height: 100,
                  message: 'Your app icon must be at least 100 x 100 px',
                  messageSize: 'File too large. Please upload a smaller file.',
                }}
                onCancelUpload={onRemoveLogo}
                onUploadSuccess={onUploadLogoSuccess}
                onUploading={onUploading}
                maxSize={25}
                source="Today Screen Icon"
              />
            </S.DragBoxElement>
          </S.DragBoxItem>

          <S.ValidationDescriptionArea>
            <S.ValidationDescription>
              <UploadTooltipIcon />
              <S.ValidationText>
                <span className="highlight">Minimum 100 x 100 px</span> Png or jpg files. Png files with a transparent
                background will show up better.
              </S.ValidationText>
            </S.ValidationDescription>
          </S.ValidationDescriptionArea>
        </S.UploadLogoContainer>
      </S.LeftArea>

      <S.RightArea>{renderSaveButton()}</S.RightArea>
    </S.TodayScreenWrapper>
  );
};

const mapStateToProps = state => ({
  cloudfrontList: state.cloudfrontList,
  originalWelcomeTextColor: state.rootReducer.customBranding.splash_screen_welcome_text_color,
});

export default connect(mapStateToProps)(TodayScreen);
