import styled, { keyframes, css } from 'styled-components';

const pulse = keyframes`
  0% {
    -webkit-transform:scale(1);
  }

  20% {
    -webkit-transform:scale(3.6);
    opacity: 0.1;
  }

  40% { 
    -webkit-transform:scale(6);
  }

  60% { 
    -webkit-transform:scale(8.7);
    opacity: 0.05;
  }

  85% { 
    -webkit-transform:scale(10);
    opacity: 0
  }

  100% {
    opacity: 0;
  }
`;

export const Aura = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f47435;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${pulse} 1.25s infinite cubic-bezier(0.4, 0, 1, 1);
`;

export const Anchor = styled.div`
  width: 7px;
  height: 7px;
  background-color: #f47435;
  border-radius: 50%;
  position: relative;

  ${props =>
    props.hideAnchor &&
    css`
      display: none;
    `}
`;

export const Close = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  text-align: right;
  color: #7470ef;
  text-align: right;
  margin-top: 15px;

  a {
    color: #7470ef !important;
    cursor: pointer;
  }
`;

export const Arrow = styled.div`
  border: 1px solid #e7e7e7;
  transform: rotate(45deg);
  position: absolute;
  z-index: 1000;
  width: 7px;
  height: 7px;
  background-color: #fff;
`;

export const Popup = styled.div`
  padding: 15px;
  position: absolute;
  z-index: 999;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  visibility: hidden;
  width: auto;
`;

export const Wrapper = styled.div`
  &.top {
    ${Arrow} {
      border-left: none;
      border-top: none;
      top: ${props => `${-1 * (props.offset + 4)}px`};
      left: 0px;
    }
  }

  &.bottom {
    ${Arrow} {
      border-right: none;
      border-bottom: none;
      top: ${props => `${props.offset + 4}px`};
      left: 0px;
    }
  }

  &.right {
    ${Arrow} {
      border-top: none;
      border-right: none;
      left: ${props => `${props.offset + 4}px`};
      top: 0px;
    }

    &.left {
      ${Arrow} {
        border-bottom: none;
        border-left: none;
        left: ${props => `${-1 * (props.offset + 4)}px`};
        top: 0px;
      }
    }
  }
`;
