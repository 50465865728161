import styled, { css } from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  ${props => ({
    margin: props.margin || '0px',
  })}
`;

export const Tab = styled.button`
  outline: none;
  padding: 8px 4px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  position: relative;

  ${props =>
    props.isActive
      ? css`
          color: #5158cf;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: #5158cf;
            top: 100%;
            left: 0;
          }
        `
      : css`
          color: #8c8c8c;
        `}

  &:hover {
    cursor: pointer;
  }
`;
