import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import diff from 'deep-diff';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import { Prompt } from 'react-router';
import { push } from 'connected-react-router';

import { Actions } from 'redux/trainer/actions';
import { WorkoutSettings, FeatureSettings } from 'shared/Settings';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import MetricSetting from 'shared/MetricSetting';
import { getAvailableMetrics } from 'utils/commonFunction';
import { CDN_URL, METRIC_SETTING_MODULE } from 'constants/commonData';
import { updateUserPreferences } from 'actions/profile';
import { fetchLeaderboardRankingTypes } from 'redux/leaderboard/actions';
import ConfirmModal from 'shared/ConfirmModal';
import useStateCallback from 'hooks/useStateCallback';

import UnitsSetting from './UnitsSetting';
import { Wrapper, Note, FeatureHeader, Header } from './style';
import SidebarSetting from 'components/SidebarSetting';
import { SIDEBAR_ID } from 'components/ClientSettings/constants';
import * as Sidebar from 'components/ClientSettings/style';

const SIDEBAR_SETTING = [
  {
    title: 'Units',
    id: SIDEBAR_ID.units,
  },
  {
    title: 'Features',
    id: SIDEBAR_ID.features,
  },
  {
    title: 'Workout Settings',
    id: SIDEBAR_ID.workout,
  },
];

const FeatureSettingHeader = () => (
  <FeatureHeader>
    <p>
      Create a custom experience for every client by disabling or enabling certain features. When a feature is disabled,
      it will not appear on your client's mobile app
    </p>
    <a
      className="guide"
      href="http://help.everfit.io/en/articles/2836312-manage-client-features-and-unit-settings"
      target="_blank"
    >
      <Image src={`${CDN_URL}/images/feature_setting_guide.svg`} />
      <span>How to disable/enable features</span>
    </a>
  </FeatureHeader>
);

function DefaultSettings(props) {
  const {
    unitCategories,
    permission,
    location: { state },
    history,
  } = props;

  const [featurePreferences, setFeature] = useState([]);
  const [workoutPreferences, setWorkout] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [settingMetrics, setSettingMetrics] = useState(false);
  const [originUnit, setOriginUnit] = useState(null);
  const [editUnit, setEditUnit] = useState(null);
  const [weight, setWeigth] = useState(null);
  const [distance, setDistance] = useState(null);
  const [length, setLength] = useState(null);
  const [isChanged, setChanged] = useStateCallback(false);
  const [isSync, setSync] = useState(true);
  const [isFuturesChanged, setIsFuturesChanged] = useState(false);
  const [isClientWorkoutChange, setIsClientWorkoutChange] = useState(false);
  const [isLoadingGetMetric, setIsLoadingGetMetric] = useState(false);
  const isAllChangeSetting = useMemo(() => {
    return [isChanged, isFuturesChanged, isClientWorkoutChange];
  }, [isChanged, isFuturesChanged, isClientWorkoutChange]);

  useEffect(() => {
    props.getDefaultFeatureSettings().then(response => {
      const { data } = response.data;
      setFeature(data);
    });

    props.getDefaultWorkoutSettings().then(response => {
      const { data } = response.data;
      setWorkout(data);
    });
    setIsLoadingGetMetric(true);
    props
      .getMetrics()
      .then(response => {
        const { data } = response.data;
        setIsLoadingGetMetric(false);
        setMetrics(getAvailableMetrics(data));
      })
      .catch(() => {
        setIsLoadingGetMetric(false);
      });
  }, []);

  useEffect(() => {
    setChanged(!!diff(originUnit, editUnit));
  }, [editUnit]);

  useEffect(() => {
    if ((state || {}).isOpenSetting) {
      setTimeout(() => {
        setSettingMetrics(true);
      }, 500);
    }
  }, [state]);

  const handleCloseSettingMetric = () => {
    setSettingMetrics(false);
    history.replace({
      pathname: '/home/default-client-settings',
      state: { isOpenSetting: false },
    });
  };

  useEffect(() => {
    let weight = {};
    let distance = {};
    let length = {};
    let weightUnit = props.unitCategories.find(item => item.name.toLowerCase() === 'mass');
    let distanceUnit = props.unitCategories.find(item => item.name.toLowerCase() === 'distance');
    let lenghtUnit = props.unitCategories.find(item => item.name.toLowerCase() === 'length');
    weight.kg = weightUnit ? weightUnit.units.find(item => item.unique_code === 'kg') : {};
    weight.lb = weightUnit ? weightUnit.units.find(item => item.unique_code === 'lb') : {};
    distance.km = distanceUnit ? distanceUnit.units.find(item => item.unique_code === 'km') : {};
    distance.mi = distanceUnit ? distanceUnit.units.find(item => item.unique_code === 'mi') : {};
    length.cm = lenghtUnit ? lenghtUnit.units.find(item => item.unique_code === 'cm') : {};
    length.in = lenghtUnit ? lenghtUnit.units.find(item => item.unique_code === 'in') : {};
    setWeigth(weight);
    setDistance(distance);
    setLength(length);
  }, [props.unitCategories]);

  useEffect(() => {
    const unit = {
      distance: _.get(props, 'trainer_unit.distance._id', ''),
      weight: _.get(props, 'trainer_unit.weight._id', ''),
      length: _.get(props, 'trainer_unit.height._id', ''),
    };
    setOriginUnit(unit);
    setEditUnit(_.cloneDeep(unit));
  }, []);

  const newMetricFromResponseData = response => {
    const { data } = response.data;
    setMetrics(getAvailableMetrics(data));
  };

  const onUpdateOneMetric = metricData => {
    return props.updateMetric({ ...metricData, module: METRIC_SETTING_MODULE.DEFAULT_SETTING }).then(response => {
      newMetricFromResponseData(response);
      return response;
    });
  };

  const onAddMetric = metricData => {
    return props.addMetric({ ...metricData, module: METRIC_SETTING_MODULE.DEFAULT_SETTING }).then(response => {
      newMetricFromResponseData(response);
      return response;
    });
  };

  const onDeleteMetric = metricData => {
    return props
      .deleteMetric({ unique_code: metricData.unique_code, module: METRIC_SETTING_MODULE.DEFAULT_SETTING })
      .then(response => {
        newMetricFromResponseData(response);
        return response;
      });
  };

  const onSaveMetricSettings = items => {
    return props.updateMultipleMetric({ items, module: METRIC_SETTING_MODULE.DEFAULT_SETTING }).then(response => {
      newMetricFromResponseData(response);
      return response;
    });
  };

  const onSaveFeatureSettings = bodyData => {
    return props.updateFeaturesSettings(bodyData).then(response => {
      const { data } = response.data;
      setFeature(data);
      return response;
    });
  };

  const onSaveWorkouSetting = bodyData => {
    return props.updateWorkoutSettings(bodyData).then(response => {
      const { data } = response.data;
      setWorkout(data);
      return response;
    });
  };

  const handleOnchange = item => {
    setEditUnit(item);
  };
  const handleOnSync = () => {
    setSync(!isSync);
  };

  const handleOnSave = () => {
    props
      .updateUserPreferences({
        weight: editUnit.weight,
        height: editUnit.length,
        distance: editUnit.distance,
        isUpdateUnitsForMetrics: isSync,
      })
      .then(() => {
        setIsLoadingGetMetric(true);
        props
          .getMetrics()
          .then(response => {
            const { data } = response.data;
            setMetrics(getAvailableMetrics(data));
            setOriginUnit(editUnit);
            setIsLoadingGetMetric(false);
          })
          .catch(() => {
            setIsLoadingGetMetric(false);
          });
        props.fetchLeaderboardRankingTypes();
        setChanged(false);
      });
  };

  const handleDiscardChange = nextLocation => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          setChanged(false, () => props.push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
        hasHoverState
      />,
    );
    return false;
  };

  const onFuturesChanged = value => setIsFuturesChanged(value);
  const onClientWorkoutChange = value => setIsClientWorkoutChange(value);

  const handleScrollTag = idTag => {
    const element = document.getElementById(idTag);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Sidebar.Wrapper>
      <SidebarSetting sidebarSetting={SIDEBAR_SETTING} onScrollTag={handleScrollTag} />
      <Sidebar.Content>
        <Wrapper id={SIDEBAR_ID.units}>
          <Header>
            <Note>
              Choose the default selection of settings to apply when adding a new client. You can still customize the
              settings for each client individually from a client’s settings page.
            </Note>
          </Header>
          <Prompt when={isChanged} message={handleDiscardChange} />
          <UnitsSetting
            weight={weight}
            editUnit={editUnit}
            distance={distance}
            length={length}
            onChange={handleOnchange}
            isChanged={isChanged}
            onSave={handleOnSave}
            onChangeSync={handleOnSync}
          />
          <div className="divider" />
          <FeatureSettings
            originSettings={featurePreferences}
            permission={permission}
            onSave={onSaveFeatureSettings}
            showMetricSetting={true}
            onClickManageMetric={() => setSettingMetrics(true)}
            header={<FeatureSettingHeader />}
            isAllChangeSetting={isAllChangeSetting}
            onFuturesChanged={onFuturesChanged}
          />
          <WorkoutSettings
            originSettings={workoutPreferences}
            onSave={onSaveWorkouSetting}
            isAllChangeSetting={isAllChangeSetting}
            onClientWorkoutChange={onClientWorkoutChange}
          />
          {settingMetrics && (
            <MetricSetting
              subTitle="Customize the list of metrics you want all newly created clients to have available to track."
              originMetrics={metrics}
              unitCategories={unitCategories}
              onSave={onSaveMetricSettings}
              onUpdate={onUpdateOneMetric}
              onAdd={onAddMetric}
              onDelete={onDeleteMetric}
              onClose={handleCloseSettingMetric}
              isLoadingGetMetric={isLoadingGetMetric}
            />
          )}
        </Wrapper>
      </Sidebar.Content>
    </Sidebar.Wrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: { unit, permission },
    user,
  } = state;
  const trainer_unit = user.preferences;
  return { unitCategories: unit.unit_categories, permission, trainer_unit };
};

export default connect(mapState, {
  ...Actions,
  toggleModal,
  toggleConfirmModal,
  updateUserPreferences,
  push,
  fetchLeaderboardRankingTypes,
})(DefaultSettings);
