import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';

import { Button } from 'shared/FormControl';
import UpgradePath from 'shared/UpgradePath';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left_grey.svg';
import { ReactComponent as TitleIcon } from 'assets/icons/forum-advanced-settings-title-icon.svg';
import { ReactComponent as RedRemoveIcon } from 'assets/icons/remove_forum_member_red.svg';
import { ReactComponent as DiscussionDropdownIndicator } from 'assets/icons/forum-discussion-dropdown-indicator.svg';
import TrashIcon from 'assets/icons/red_trash_circle.svg';
import { getGroupDetail, removeGroup } from 'redux/forum/actions';
import { toggleConfirmModal } from 'actions/modal';
import { push } from 'connected-react-router';
import { getConnectedLeaderboard } from 'redux/forum/actions';
import { ASSET_TYPE } from 'components/Product/constants';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { checkAssetAssignedToProduct } from 'utils/commonFunction';

import ConnectAutoflow from './ConnectAutoflow';
import SetupGroupSettings from './SetupGroupSettings';
import * as S from './styles';

import { TypingModal, TYING_TYPES } from 'shared/TriggerForms';

function AdvancedSettings({
  history,
  location,
  group,
  getGroupDetail,
  toggleConfirmModal,
  removeGroup,
  push,
  forumList,
  getConnectedLeaderboard,
  connectedLeaderboard,
  userId,
  user,
  match,
  payment,
  onboarding_flow,
}) {
  const isOwner = userId === group.author;

  useEffect(() => {
    if (isEmpty(group)) {
      getGroupDetail(groupId);
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(group) && isEmpty(connectedLeaderboard)) {
      getConnectedLeaderboard(group._id);
    }
  }, [group]);
  const handleGoBack = () => {
    push(`/home/forums/${groupId}/discussion`);
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  /**
   * Priority is as below:
   * 1. Warning pop up of package
   * 2. Warning pop up of trigger
   * Enable if User writes word “Confirm” (ignore sensitive case).
   * On-click this option should remove the program (no need any other pop up)
   * 3. Warning pop up of assignment for Onboarding Flow.
   */
  const handleDeleteForum = async () => {
    if (payment) {
      const isAssignedToProduct = await checkAssetAssignedToProduct(ASSET_TYPE.FORUM, groupId);
      if (isAssignedToProduct) {
        return toggleConfirmModal(
          true,
          <DeleteAssetConfirmModal assetType={ASSET_TYPE.FORUM} onConfirm={handleConfirmDeleteForum} />,
        );
      }
    }

    if (onboarding_flow) {
      const {
        assigned: isAssignedToOnboardingFlow,
        assigned_trigger_form: isTriggerApplied,
      } = await checkAssetAssignedToProduct(ASSET_TYPE.FORUM, groupId, true, true);

      if (isTriggerApplied && typeof toggleConfirmModal === 'function') {
        return toggleConfirmModal(
          true,
          <TypingModal
            isCloseAfterSubmit
            type={TYING_TYPES.REMOVE_FORUM}
            closeModal={closeConfirmModal}
            submitAction={onDeleteForum}
          />,
        );
      }

      if (isAssignedToOnboardingFlow) {
        return toggleConfirmModal(
          true,
          <DeleteAssetConfirmModal
            isOnboardingFlow
            title="Forum is assigned to an Onboarding Flow"
            content="This Forum is in at least 1 onboarding flow. If the Forum is removed, it will be removed from all onboarding flows as well."
            assetType={ASSET_TYPE.FORUM}
            onConfirm={handleConfirmDeleteForum}
          />,
        );
      }
    }

    handleConfirmDeleteForum();
  };

  const handleConfirmDeleteForum = () => {
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        noBorder
        headerIcon={TrashIcon}
        title="Delete this forum?"
        content={`If you delete this forum, everyone will lose access and all posts will be cleared out.`}
        cancelButtonTitle="Keep my forum"
        onConfirm={onDeleteForum}
        confirmButtonTitle="Delete Permanently"
        hasCloseIcon
      />,
    );
  };

  const onDeleteForum = async () => {
    await removeGroup(groupId);
    const newForumList = forumList.filter(forum => forum._id !== groupId);
    if (newForumList.length > 0) {
      push(`/home/forums/${newForumList[0]._id}/discussion`);
    } else {
      push('/home/forums');
    }
  };

  const groupId = location.pathname.slice(
    location.pathname.indexOf('/forums/') + '/forums/'.length,
    location.pathname.indexOf('/settings'),
  );

  const renderManageDiscussion = () => {
    const settings = [
      {
        label: 'Who can post?',
        description: 'Define who can add new posts to the forum',
      },
      {
        label: 'Who can comment?',
        description: 'Define who can comment on posts',
      },
      {
        label: 'Who can edit?',
        description: 'Define who can edit posts',
      },
    ];
    const options = [
      { value: 'Only me', key: 0, label: 'Only me' },
      { value: 'coaches', key: 1, label: 'Coaches Only' },
      { value: 'anyone', key: 2, label: 'Anyone' },
    ];
    return (
      <S.SettingContainer isManageDiscussion>
        <div className="title-container">
          <TitleIcon />
          Manage Discussion
        </div>
        {settings.map((setting, idx) => (
          <div key={idx}>
            <div className="discussion-settings-container">
              <div>
                <S.DiscussionSettingLabel>{setting.label}</S.DiscussionSettingLabel>
                <S.DiscussionSettingDescription>{setting.description}</S.DiscussionSettingDescription>
              </div>
              <Select
                className="manage-discusstion-select"
                options={options}
                placeholder="Choose Autoflow"
                components={{ DiscussionDropdownIndicator, IndicatorSeparator: null }}
                classNamePrefix="single-select"
                isSearchable={false}
              />
            </div>
            {idx !== settings.length - 1 && <div className="breaker" />}
          </div>
        ))}
      </S.SettingContainer>
    );
  };

  return (
    <S.Wrapper>
      <Button className="go-back-btn" textOnly onClick={handleGoBack} type="button">
        <LeftArrowIcon />
        <span> Back</span>
      </Button>
      <SetupGroupSettings useStateCallback={useStateCallback} location={location} />
      <UpgradePath pathName="autoflow" fallback={<></>}>
        <ConnectAutoflow
          groupId={groupId}
          getConnectedLeaderboard={getConnectedLeaderboard}
          getGroupDetail={getGroupDetail}
          useStateCallback={useStateCallback}
        />
      </UpgradePath>
      {isOwner && (
        <div className="delete-btn-container">
          <Button borderRed onClick={handleDeleteForum}>
            <RedRemoveIcon />
            Delete Forum
          </Button>
        </div>
      )}
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    group: state.rootReducer.forum.group,
    forumList: state.rootReducer.forum.list,
    connectedLeaderboard: state.rootReducer.forum.connectedLeaderboard,
    userId: state.user._id,
    payment: state.rootReducer.permission.payment,
    onboarding_flow: state.rootReducer.permission.onboarding_flow,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGroupDetail: bindActionCreators(getGroupDetail, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    removeGroup: bindActionCreators(removeGroup, dispatch),
    push: bindActionCreators(push, dispatch),
    getConnectedLeaderboard: bindActionCreators(getConnectedLeaderboard, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings));

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}
