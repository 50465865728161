import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { pauseAutoflow } from 'redux/autoflow/actions';
import { pauseAutoflow as pauseAutoflowInterval } from 'redux/autoflowInterval/actions';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import * as S from './style';

function PauseModal(props) {
  const { autoflowId, clientStatistic } = props;

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/pause.svg`} />
            <span>Pause Autoflow</span>
          </S.Header>
          <S.Body>
            <div>
              When Autoflow is paused, all <b>{clientStatistic.active} Active</b> client
              {clientStatistic.active === 1 ? '' : 's'} will be paused. This Autoflow’s workouts, tasks, and
              auto-messages from today onwards will be removed for clients. Would you like to continue?
            </div>
            <S.Divide />
            <S.Note>
              <b>{clientStatistic.paused} Paused</b> client{clientStatistic.paused === 1 ? '' : 's'} will not be
              affected. Nothing from this Autoflow is currently synced to their calendars.
            </S.Note>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              yellow
              onClick={() => {
                props.pauseAutoflow(autoflowId);
                props.toggleModal(false);
              }}
            >
              Pause
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = (state, props) => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  let clientStatistic = rootReducer.autoflow.common.clientStatistic;
  if (props.type === AUTOFLOW_TYPES.INTERVAL) {
    clientStatistic = rootReducer.autoflowInterval.common.get('clientStatistic');
  }

  return {
    autoflowId: workingAutoflow && workingAutoflow._id,
    clientStatistic,
  };
};

const mapDispatch = (dispatch, props) => {
  if (props.type === AUTOFLOW_TYPES.INTERVAL) {
    return {
      pauseAutoflow: bindActionCreators(pauseAutoflowInterval, dispatch),
      toggleModal: bindActionCreators(toggleModal, dispatch),
    };
  }
  return {
    pauseAutoflow: bindActionCreators(pauseAutoflow, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapState, mapDispatch)(PauseModal);
