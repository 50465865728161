import styled from 'styled-components';

export const CoachEditor = styled.div`
  .DraftEditor-root {
    height: 280px;
    overflow: hidden;
  }

  .DraftEditor-editorContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
    padding: 15px;
    border: 1px solid #dadfea;
    border-radius: 4px;
    box-sizing: inherit;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      &-thumb {
        border-radius: 4px !important;
      }
    }

    &:hover,
    :focus-within {
      border-color: #5c5bbd !important;
    }
  }

  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #323c47 !important;
    opacity: 0.4;
    top: 15px;
    left: 15px;
  }

  .public-DraftEditor-content {
    display: block;
    max-height: calc(280px - 30px);
  }
`;

export const CoachLabel = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 5px;
`;
