import React, { useState, useMemo, useEffect, useRef } from 'react';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import remove from 'lodash/remove';
import map from 'lodash/map';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChatInput from './ChatBar';
import MessageItem from './MessageItem';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleConfirmModal } from 'actions/modal';
import { MESSAGE_TYPES } from 'components/InAppMessage/constants';
import { openSecondToggle } from 'redux/broadcast-messages/actions';
import {
  convertMsToTime,
  getUserShortName,
  isESCPress,
  limitText,
  mediaLog,
  pluralize,
  revokeObjectURL,
  padTo2Digits,
} from 'utils/commonFunction';
import { getAttachmentIDFromUrl } from 'helpers/replyComment';
import { KEY_CODE } from 'constants/commonData';
import ConfirmHeaderIcon from 'assets/icons/circle_header.svg';
import TimerModal from 'components/Inbox/BroadcastMessage/WarningTimeModal';
import { ReactComponent as RemoveIcon } from 'assets/icons/close_gray_out.svg';

import * as S from './style';

const INIT_HEADER_HEIGHT = 33;
const BroadcastMessageRightSide = props => {
  const {
    user,
    selectedClients,
    onGetMessages,
    toggleConfirmModal,
    openSecondToggle,
    onChangeSelectedClients,
    onClose,
  } = props;
  const selectedBoxRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [uploadingItems, setUploadingItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasTextInInput, setHasTextInInput] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(INIT_HEADER_HEIGHT);
  // Removed message ids
  const removeIds = [];
  const userInfo = useMemo(() => ({ src: user.avatar, name: getUserShortName(user), color: user.color }), [user]);

  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector('.bottom-selected-clients.enable-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedClients]);

  const onEscPressed = event => {
    if (event.keyCode === KEY_CODE.esc && isESCPress(event.key)) {
      openSecondToggle(false);
      toggleConfirmModal(false);
    }
  };

  const onSendMessage = message => {
    if (messages.length < 3) {
      const newMessages = m => [...m, message];
      setMessages(newMessages);

      if (message.type === MESSAGE_TYPES.FILE) {
        setUploadingItems([...uploadingItems, message._id]);
      }

      onGetMessages(newMessages);
      autoScrollMessages();
    }
  };

  const autoScrollMessages = () => {
    setTimeout(() => {
      const element = document.querySelector('.message-wrapper');
      if (element) {
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  const onRemoveMessage = index => {
    const newMessages = messages.slice();
    const removeMessages = newMessages.splice(index, 1);
    removeIds.push(messages[index]._id);

    if (removeMessages[0].objectURL) {
      revokeObjectURL(removeMessages[0].objectURL);
      if (!isEmpty(uploadingItems) && uploadingItems.includes(removeMessages[0]._id)) {
        setUploadingItems(uploadingItems.filter(id => id !== removeMessages[0]._id));
      }
    }
    setMessages(newMessages);
    onGetMessages(newMessages);
  };

  const onCancelUpload = ({ mId }) => {
    const index = findIndex(messages, m => m._id === mId);

    if (index !== -1) {
      onRemoveMessage(index);
      const newUploadingItems = uploadingItems.slice();
      remove(newUploadingItems, id => id === mId);

      setUploadingItems(newUploadingItems);
      onRemoveMessage(index);
    }
  };

  const handleBroadcastMessage = () => {
    if (isSubmitting) {
      return false;
    }

    const data = {
      client_ids: map(selectedClients, client => client._id),
      messages: map(messages, m => omit(m, ['_id', 'objectURL', 'type'])),
    };

    const continueSubmit = () => {
      setIsSubmitting(true);

      props.onSubmit(
        data,
        () => {
          openSecondToggle(false);
          setIsSubmitting(false);
        },
        data => {
          if (isEmpty(data)) return;
          // get lastTime
          let submittedTime = new Date();
          const totalSeconds = 120 - data.wait_time; // 120 second
          const minute = parseInt(Math.floor(totalSeconds / 60), 10);
          const second = parseInt(totalSeconds % 60, 10);
          const timeToSubtract = new Date(0, 0, 0, 0, minute, second - 1);
          submittedTime.setMinutes(submittedTime.getMinutes() - timeToSubtract.getMinutes());
          submittedTime.setSeconds(submittedTime.getSeconds() - timeToSubtract.getSeconds());
          // get timeStringProp
          const [minuteString, secondString] = data.time.split(':');
          const timeString = `00:${padTo2Digits(minuteString)}:${padTo2Digits(secondString)}`;

          toggleConfirmModal(
            true,
            <TimerModal countDownTime={120000} lastTime={submittedTime} timeStringProp={timeString} />,
          );
          setIsSubmitting(false);
        },
      );
    };

    const checkTimeBeforeSubmit = () => {
      const { submittedTime } = props;
      const twoMinutesToMilliseconds = 120000;
      const currentTime = new Date();
      const isValidTime = currentTime - submittedTime >= twoMinutesToMilliseconds;
      const timeString = convertMsToTime(twoMinutesToMilliseconds - (currentTime - submittedTime));

      if (isValidTime) {
        continueSubmit();
      } else {
        openSecondToggle(true);
        toggleConfirmModal(
          true,
          <TimerModal countDownTime={twoMinutesToMilliseconds} lastTime={submittedTime} timeStringProp={timeString} />,
        );
      }
    };

    openSecondToggle(true);
    if (hasTextInInput) {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Are you done?"
          content="You have typed a message that has not been added to the list of broadcast messages yet. Would you like to add the message first, or continue to save & exit?"
          onConfirm={() => checkTimeBeforeSubmit()}
          confirmButtonTitle="Save & exit"
          cancelButtonTitle="Cancel"
          newStyle={true}
          className="has-text-input-broadcast-message-confirm-popup"
        />,
      );
    } else {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={`Broadcast to ${pluralize('client', selectedClients.length, true)}`}
          content={`You have selected ${pluralize(
            'client',
            selectedClients.length,
            true,
          )} to broadcast the messages to. Would you like to send the messages now?`}
          onConfirm={() => checkTimeBeforeSubmit()}
          confirmButtonTitle="Send now"
          cancelButtonTitle="Cancel"
          newStyle={true}
          headerIcon={ConfirmHeaderIcon}
          className="broadcast-message-confirm-popup"
        />,
      );
    }
  };

  const disabledInput = messages.length >= 3;

  const isRemovedFromLocal = mId => {
    return removeIds.includes(mId);
  };

  const onUploadSuccess = ({ mId, data }) => {
    const newMessages = messages.slice();

    // Nothing if message is removed
    if (!isRemovedFromLocal(mId)) {
      const index = findIndex(messages, m => m._id === mId);
      if (messages[index]) {
        const {
          attachment: name,
          attachment_type: fileType,
          uploadConfigs: { url = '' },
        } = messages[index];
        mediaLog({
          status: 2,
          name,
          fileType,
          description: 'Upload success file via BroadcastMessage',
        });
        newMessages.splice(index, 1, {
          ...omit(messages[index], 'uploadConfigs'),
          attachment: url,
          attachment_id: getAttachmentIDFromUrl(url),
          content: '{{file}}',
        });
      }
      setMessages(newMessages);
    }

    const newUploadingItems = uploadingItems.slice();
    remove(newUploadingItems, id => id === mId);

    setUploadingItems(newUploadingItems);
  };

  const handleRemoveSelectedClient = client => {
    const newList = selectedClients.filter(c => c._id !== client._id);
    onChangeSelectedClients(newList);
    const element = document.querySelector('.enable-scroll');
    if (element) {
      element.classList.remove('enable-scroll');
    }
  };

  const getHeight = () => {
    if (selectedBoxRef.current) {
      setHeaderHeight(selectedBoxRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    getHeight();
  }, [selectedClients]);

  return (
    <S.Wrapper>
      <S.SendToWrapper ref={selectedBoxRef} className="header-wrapper">
        <S.SendToLabel>Send To ({selectedClients.length})</S.SendToLabel>
        <S.SelectedClientWrapper>
          {selectedClients.map(client => {
            return (
              <S.SelectedClientItem key={client._id}>
                <S.ClientName>{limitText(client.full_name, 29)}</S.ClientName>
                <RemoveIcon className="remove-selected-icon" onClick={() => handleRemoveSelectedClient(client)} />
              </S.SelectedClientItem>
            );
          })}
          <div className="bottom-selected-clients" />
        </S.SelectedClientWrapper>
      </S.SendToWrapper>
      <S.MessageWrapper headerHeight={headerHeight}>
        <S.Messages className="message-wrapper">
          {messages.map(message => {
            return (
              <MessageItem
                key={message._id}
                message={message}
                avatarData={userInfo}
                onRemoveMessage={onRemoveMessage}
                onUploadSuccess={onUploadSuccess}
                onCancelUpload={onCancelUpload}
                index={messages.indexOf(message)}
              />
            );
          })}
          {!isEmpty(messages) && <S.Tip>Each message will be sent 5s apart from each other</S.Tip>}
        </S.Messages>
      </S.MessageWrapper>
      <S.ChatActionWrapper>
        <ChatInput
          onSubmit={onSendMessage}
          setHasTextInInput={setHasTextInInput}
          placeholder="Type a message here"
          disabled={disabledInput}
          className="chat-input"
        />
        <S.BottomActionWrapper>
          <S.MessagesRemaining disabled={disabledInput}>
            Messages remaining: {3 - messages.length}/3
          </S.MessagesRemaining>
          <Button
            className="broadcast-button"
            disabled={isEmpty(messages) || selectedClients.length === 0 || !isEmpty(uploadingItems)}
            onClick={() => handleBroadcastMessage()}
            purple
          >
            Broadcast now
          </Button>
        </S.BottomActionWrapper>
      </S.ChatActionWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { broadcastMessages },
  } = state;

  const { broadcastSubmittedTime } = broadcastMessages;

  return {
    user,
    submittedTime: broadcastSubmittedTime,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  openSecondToggle: bindActionCreators(openSecondToggle, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastMessageRightSide);
