import Request from 'configs/request';
import { omit } from 'lodash';

export const Types = {
  GET_COACH_BIO_REQUEST: 'GET_COACH_BIO_REQUEST',
  GET_COACH_BIO_SUCCESS: 'GET_COACH_BIO_SUCCESS',
  GET_COACH_BIO_FAIL: 'GET_COACH_BIO_FAIL',
  COACH_BIO_CHANGE_QUERY_PARAMS: 'COACH_BIO_CHANGE_QUERY_PARAMS',
};

export const getCoachBios = (params, isLoadmore = false) => {
  return (dispatch, getState) => {
    let queryParams;
    const {
      rootReducer: {
        coachBios: { query, list },
      },
    } = getState();

    if (params) {
      queryParams = params;
    } else {
      queryParams = query;
    }

    const requestParams = queryParams.q === '' ? omit(queryParams, 'q') : queryParams;
    const oldList = list;

    dispatch({ type: Types.GET_COACH_BIO_REQUEST });

    return dispatch(
      Request.get(
        { url: 'api/payment/coach-bios', params: requestParams },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          if (isLoadmore) {
            const newList = oldList.concat(data.list);
            return dispatch({ type: Types.GET_COACH_BIO_SUCCESS, payload: { ...data, list: newList, queryParams } });
          }

          return dispatch({ type: Types.GET_COACH_BIO_SUCCESS, payload: { ...data, queryParams } });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.GET_COACH_BIO_FAIL, error });
        },
      ),
    );
  };
};

export const changeQueryParams = (params, isLoadmore = false) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        coachBios: { query },
      },
    } = getState();
    const newData = { ...query, ...params };

    dispatch({
      type: Types.COACH_BIO_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });

    dispatch(getCoachBios(newData, isLoadmore));
  };
};
