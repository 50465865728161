// Libs
import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

// Shared
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

// Commons
import { PLANS } from 'constants/commonData';

// Assets
import UpgradeTransparentIcon from 'assets/icons/upgrade-icon-transparent.svg';

// Styles
import * as S from './styles';

const UpgradePathIconButton = props => {
  const {
    wrapperClassNames,
    tooltipOffsetRight,
    tooltipId,
    tooltipContent = 'Upgrade automation add-ons to use the trigger',
  } = props;

  return (
    <S.UpgradePathIconButton
      className={classNames('upgrade-path-icon-button', wrapperClassNames, {
        'offset-right': tooltipOffsetRight,
      })}
      data-for={tooltipId}
      data-tip
    >
      <UpgradePathButton
        isHasChildren={true}
        planWillSelect={{
          tier: PLANS.pro.key,
          addOns: {
            automation: true,
          },
        }}
      >
        <img src={UpgradeTransparentIcon} alt="" />
        <ReactTooltip
          id={tooltipId}
          className="upgrade-path-icon-tooltip"
          place="top"
          effect="solid"
          offset={{ left: tooltipOffsetRight ? 93 : 0 }}
        >
          {tooltipContent}
        </ReactTooltip>
      </UpgradePathButton>
    </S.UpgradePathIconButton>
  );
};

export default UpgradePathIconButton;
