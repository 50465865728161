import React from 'react';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import truncate from 'lodash/truncate';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isInteger from 'lodash/isInteger';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { push } from 'connected-react-router';

import { database } from 'database/firebase';
import Request, { axiosInstance } from 'configs/request';
import { toggleModal } from 'actions/modal';
import { MultiplePasteMessage } from 'shared/Notifications';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { CLASSNAME_TOAST, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { convertWorkoutUnits } from 'helpers/workout';
import { mongoObjectId, pluralize } from 'utils/commonFunction';

export const Types = {
  STUDIO_PROGRAMS_CHANGE_QUERY_PARAMS: 'STUDIO_PROGRAMS_CHANGE_QUERY_PARAMS',
  STUDIO_PROGRAMS_LOADING_DATA: 'STUDIO_PROGRAMS_LOADING_DATA',
  STUDIO_PROGRAMS_SUCCESS_GET_LIST: 'STUDIO_PROGRAMS_SUCCESS_GET_LIST',
  STUDIO_PROGRAMS_FAILED_GET_LIST: 'STUDIO_PROGRAMS_FAILED_GET_LIST',
  STUDIO_PROGRAMS_SUCCESS_LOAD_MORE_LIST: 'STUDIO_PROGRAMS_SUCCESS_LOAD_MORE_LIST',
  STUDIO_PROGRAMS_SUCCESS_HIDE_BANNER: 'STUDIO_PROGRAMS_SUCCESS_HIDE_BANNER',
  STUDIO_PROGRAM_SUCCESS_HIDE_GUIDE: 'STUDIO_PROGRAM_SUCCESS_HIDE_GUIDE',
  STUDIO_PROGRAMS_LIST_RESET_DATA: 'STUDIO_PROGRAMS_LIST_RESET_DATA',
  STUDIO_PROGRAM_SUCCESS_GET_DETAIL: 'STUDIO_PROGRAM_SUCCESS_GET_DETAIL',
  STUDIO_PROGRAM_GET_DETAIL: 'STUDIO_PROGRAM_GET_DETAIL',
  STUDIO_PROGRAM_SUCCESS_UPDATE: 'STUDIO_PROGRAM_SUCCESS_UPDATE',
  STUDIO_PROGRAM_SUCCESS_DELETE_PROGRAM: 'STUDIO_PROGRAM_SUCCESS_DELETE_PROGRAM',
  STUDIO_PROGRAM_RESET_DETAIL_DATA: 'STUDIO_PROGRAM_RESET_DETAIL_DATA',
  STUDIO_PROGRAM_UNDEFINED: 'STUDIO_PROGRAM_UNDEFINED',
  STUDIO_PROGRAM_SUCCESS_GET_WORKOUTS: 'STUDIO_PROGRAM_SUCCESS_GET_WORKOUTS',
  STUDIO_PROGRAM_REQUEST_GET_WORKOUTS: 'STUDIO_PROGRAM_REQUEST_GET_WORKOUTS',
  STUDIO_PROGRAM_GET_WORKOUTS_FAILED: 'STUDIO_PROGRAM_GET_WORKOUTS_FAILED',
  STUDIO_PROGRAM_CHANGE_CALENDAR_VIEW_MODE: 'STUDIO_PROGRAM_CHANGE_CALENDAR_VIEW_MODE',
  STUDIO_PROGRAM_GO_TO_WEEK: 'STUDIO_PROGRAM_GO_TO_WEEK',
  STUDIO_PROGRAM_SUCCESS_GET_AVAILABLE_CLIENTS: 'STUDIO_PROGRAM_SUCCESS_GET_AVAILABLE_CLIENTS',
  STUDIO_PROGRAM_ADD_CLIENT_TO_REDUX: 'STUDIO_PROGRAM_ADD_CLIENT_TO_REDUX',
  STUDIO_PROGRAM_REMOVE_CLIENT_TO_REDUX: 'STUDIO_PROGRAM_REMOVE_CLIENT_TO_REDUX',
  STUDIO_PROGRAM_SUCCESS_SAVE_CLIENTS: 'STUDIO_PROGRAM_SUCCESS_SAVE_CLIENTS',
  STUDIO_PROGRAM_SUCCESS_ADD_WORKOUT: 'STUDIO_PROGRAM_SUCCESS_ADD_WORKOUT',
  STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_SINGLE_DAY: 'STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_SINGLE_DAY',
  STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS: 'STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS',
  STUDIO_PROGRAM_COPY_WORKOUT: 'STUDIO_PROGRAM_COPY_WORKOUT',
  STUDIO_PROGRAM_RESET_COPY_ITEM: 'STUDIO_PROGRAM_RESET_COPY_ITEM',
  STUDIO_PROGRAM_MOVE_WORKOUT_ON_LOCAL: 'STUDIO_PROGRAM_MOVE_WORKOUT_ON_LOCAL',
  STUDIO_PROGRAM_ARRANGE_WORKOUT_ON_LOCAL: 'STUDIO_PROGRAM_ARRANGE_WORKOUT_ON_LOCAL',
  STUDIO_PROGRAM_SUCCESS_ENABLE_EDIT_MODE: 'STUDIO_PROGRAM_SUCCESS_ENABLE_EDIT_MODE',
  STUDIO_PROGRAM_SUCCESS_CANCEL_CHANGES: 'STUDIO_PROGRAM_SUCCESS_CANCEL_CHANGES',
  STUDIO_PROGRAM_SUCCESS_PUBLISH_CHANGES: 'STUDIO_PROGRAM_SUCCESS_PUBLISH_CHANGES',
  STUDIO_PROGRAM_UPDATE_LAST_EDIT_BY: 'STUDIO_PROGRAM_UPDATE_LAST_EDIT_BY',
  STUDIO_PROGRAM_SUCCESS_REMOVE_WEEK: 'STUDIO_PROGRAM_SUCCESS_REMOVE_WEEK',
  STUDIO_PROGRAM_SUCCESS_ADD_WEEK: 'STUDIO_PROGRAM_SUCCESS_ADD_WEEK',
  STUDIO_PROGRAM_MOVE_EXERCISE: 'STUDIO_PROGRAM_MOVE_EXERCISE',
  STUDIO_PROGRAM_COPY_WEEK: 'STUDIO_PROGRAM_COPY_WEEK',
  STUDIO_PROGRAM_RESET_WEEK: 'STUDIO_PROGRAM_RESET_WEEK',
  STUDIO_PROGRAM_PASTE_WEEK_REQUEST: 'STUDIO_PROGRAM_PASTE_WEEK_REQUEST',
  STUDIO_PROGRAM_PASTE_WEEK_SUCCESS: 'STUDIO_PROGRAM_PASTE_WEEK_SUCCESS',
  STUDIO_PROGRAM_PASTE_WEEK_FAILED: 'STUDIO_PROGRAM_PASTE_WEEK_FAILED',
  STUDIO_PROGRAM_REMOVE_WEEK_REQUEST: 'STUDIO_PROGRAM_REMOVE_WEEK_REQUEST',
  STUDIO_PROGRAM_REMOVE_WEEK_SUCCESS: 'STUDIO_PROGRAM_REMOVE_WEEK_SUCCESS',
  STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_REQUEST: 'STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_REQUEST',
  STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_SUCCESS: 'STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_SUCCESS',
  STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_FAILED: 'STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_FAILED',
  STUDIO_PROGRAM_SELECT_MULTIPLE_WORKOUT: 'STUDIO_PROGRAM_SELECT_MULTIPLE_WORKOUT',
  STUDIO_PROGRAM_RESET_MULTIPLE_WORKOUT: 'STUDIO_PROGRAM_RESET_MULTIPLE_WORKOUT',
  STUDIO_PROGRAM_COPY_MULTIPLE_WORKOUT: 'STUDIO_PROGRAM_COPY_MULTIPLE_WORKOUT',
  STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_REQUEST: 'STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_REQUEST',
  STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_SUCCESS: 'STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_SUCCESS',
  STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_FAILED: 'STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_FAILED',
  STUDIO_PROGRAM_SOCKET_WORKOUT_ADDED: 'STUDIO_PROGRAM_SOCKET_WORKOUT_ADDED',
  STUDIO_PROGRAM_SOCKET_WORKOUT_DELETED: 'STUDIO_PROGRAM_SOCKET_WORKOUT_DELETED',
  STUDIO_PROGRAM_CHANGE_SHARE_STATUS: 'STUDIO_PROGRAM_CHANGE_SHARE_STATUS',
};

const parseWorkoutDataByDay = weekDataList => {
  const workoutsData = {};

  forEach(weekDataList, weekData => {
    const { weekIndex, days_workout } = weekData;

    forEach(days_workout, dayData => {
      const {
        day_index,
        day_workout: { workouts },
      } = dayData;

      workoutsData[`${weekIndex}_${day_index}`] = workouts;
    });
  });

  return workoutsData;
};

export const changeStudioProgramQueryParams = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioProgram: { query },
      },
    } = getState();

    const newData = { ...query, ...data };

    dispatch({
      type: Types.STUDIO_PROGRAMS_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });
    dispatch(getListStudioProgram(newData, true));
  };
};

export const getListStudioProgram = (params, isLoading = false) => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const {
        rootReducer: {
          studioProgram: { query },
        },
      } = getState();

      queryParams = query;
    }

    if (!isLoading) {
      dispatch({ type: Types.STUDIO_PROGRAMS_LOADING_DATA });
    }

    return dispatch(
      Request.post(
        { url: `/api/studio-program/fetch-by-trainer`, data: queryParams },
        true,
        response => {
          const { data, total } = response.data;
          dispatch({ type: Types.STUDIO_PROGRAMS_SUCCESS_GET_LIST, payload: { data, total } });
        },
        error => {
          dispatch({ type: Types.STUDIO_PROGRAMS_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const loadMoreStudioProgram = (isLoading = false) => {
  return (dispatch, getState) => {
    let queryParams;

    const {
      rootReducer: {
        studioProgram: { query },
      },
    } = getState();

    queryParams = query;
    queryParams.page = queryParams.page + 1;

    if (isLoading) {
      dispatch({ type: Types.STUDIO_PROGRAMS_LOADING_DATA });
    }

    return dispatch(
      Request.post(
        { url: '/api/studio-program/fetch-by-trainer', data: queryParams },
        true,
        response => {
          const { data, total, page } = response.data;
          dispatch({ type: Types.STUDIO_PROGRAMS_SUCCESS_LOAD_MORE_LIST, payload: { data, total, page } });
        },
        error => {
          dispatch({ type: Types.STUDIO_PROGRAMS_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const hideStudioProgramBanner = () => {
  return Request.post({ url: '/api/studio-program/hide-banner' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.STUDIO_PROGRAMS_SUCCESS_HIDE_BANNER });
  });
};

export const hideGuideHowItWork = () => {
  return Request.post({ url: '/api/studio-program/hide-guide-how-it-work' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_HIDE_GUIDE });
  });
};

export const resetListData = () => ({ type: Types.STUDIO_PROGRAMS_LIST_RESET_DATA });

export const getStudioProgramDetail = studioProgramId => {
  return dispatch => {
    dispatch({ type: Types.STUDIO_PROGRAM_GET_DETAIL, payload: { data: studioProgramId } });
    return dispatch(
      Request.post(
        { url: '/api/studio-program/get-detail', data: { studioProgramId } },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_GET_DETAIL, payload: { data } });
        },
      ),
    );
  };
};

export const getAvailableClients = studioProgramId => {
  return Request.post(
    { url: '/api/studio-program/get-clients-available', data: { studioProgramId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_GET_AVAILABLE_CLIENTS, payload: { data } });
    },
  );
};

export const addClientToRedux = clients => {
  return { type: Types.STUDIO_PROGRAM_ADD_CLIENT_TO_REDUX, payload: { data: clients } };
};

export const removeClientFromRedux = client => {
  return { type: Types.STUDIO_PROGRAM_REMOVE_CLIENT_TO_REDUX, payload: { data: client } };
};

export const saveClientsAvailable = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioProgram: { availableClients, workingStudio },
      },
    } = getState();

    const studioProgramId = get(workingStudio, '_id');
    const clients = map(availableClients, '_id');
    return dispatch(
      Request.post(
        {
          url: '/api/studio-program/add-client',
          data: { studioProgramId, clients },
        },
        false,
        response => {
          dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_SAVE_CLIENTS });
        },
        error => {
          dispatch(getAvailableClients(studioProgramId));
        },
      ),
    );
  };
};

export const getResultAddClients = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioProgram: { availableClients, workingStudio },
      },
    } = getState();

    const studioProgramId = get(workingStudio, '_id');
    const clients = map(availableClients, '_id');
    return dispatch(
      Request.post(
        {
          url: '/api/studio-program/get-result-add-clients',
          data: { studioProgramId, clients },
        },
        false,
      ),
    );
  };
};

export const updateStudioProgram = (id, params) => {
  return Request.post(
    { url: '/api/studio-program/update', data: { id, ...params } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_GET_DETAIL, payload: { data } });
    },
  );
};

export const duplicateStudioProgram = program => {
  const body = {
    programId: program._id,
    program_name: `Copy of ${program.program_name}`,
  };
  if (body.program_name.length > 30) {
    body.program_name = truncate(body.program_name, { length: 30 });
  }
  return Request.post({ url: '/api/studio-program/clone', data: body }, false, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch(push(`/home/studio-programs/${data._id}`));
  });
};

export const removeStudioProgram = program => {
  const body = {
    programId: program._id,
  };
  return Request.post({ url: '/api/studio-program/remove-program', data: body }, false, (response, { dispatch }) => {
    dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_DELETE_PROGRAM, payload: { data: body } });
  });
};

export const copyToProgramLibrary = data => {
  return Request.post({ url: '/api/studio-program/copy-to-library', data }, false);
};

export const publishStudioProgram = programId => {
  return Request.post(
    { url: '/api/studio-program/publish-program', data: { programId } },
    false,
    (response, { dispatch }) => {
      dispatch({
        type: Types.STUDIO_PROGRAM_SUCCESS_UPDATE,
        payload: { data: { isPublished: true, isSyncing: true } },
      });
    },
  );
};

export const unpublishStudioProgram = programId => {
  return Request.post(
    { url: '/api/studio-program/unpublish-program', data: { programId } },
    false,
    (response, { dispatch }) => {
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_UPDATE, payload: { data: { isPublished: false } } });
    },
  );
};

export const addFromProgramLibrary = params => {
  return Request.post({ url: '/api/v2/studio-program/add-from-library', data: params }, false);
};

export const resetStudioProgramDetail = () => ({ type: Types.STUDIO_PROGRAM_RESET_DETAIL_DATA });

export const getListWorkouts = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioProgram: { startWeek, calendarViewMode, workingStudio, studioProgramId },
      },
      user,
    } = getState();

    const programId = studioProgramId || get(workingStudio, '_id');
    const endWeek = startWeek + calendarViewMode - 1;
    const units = user.preferences;

    if (!programId) {
      return;
    }
    dispatch(showLoading());
    dispatch({
      type: Types.STUDIO_PROGRAM_REQUEST_GET_WORKOUTS,
    });

    return dispatch(
      Request.get(
        {
          url: '/api/studio-program/get-program-calendar-by-weeks',
          params: { programId, startWeek, endWeek },
        },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          let workoutsData = parseWorkoutDataByDay(data);
          forEach(workoutsData, workoutDay => {
            forEach(workoutDay, (workout, key) => {
              const convertedWorkout = convertWorkoutUnits(workout, units);
              workoutDay[key] = convertedWorkout;
            });
          });
          dispatch({
            type: Types.STUDIO_PROGRAM_SUCCESS_GET_WORKOUTS,
            payload: { data: workoutsData, startWeek, endWeek, programId },
          });
          dispatch(hideLoading());
        },
        (error, { dispatch }) => {
          dispatch({
            type: Types.STUDIO_PROGRAM_GET_WORKOUTS_FAILED,
          });
          dispatch(hideLoading());
        },
      ),
    );
  };
};

export const changeCalendarViewMode = data => {
  return dispatch => {
    dispatch({
      type: Types.STUDIO_PROGRAM_CHANGE_CALENDAR_VIEW_MODE,
      payload: { data },
    });
    dispatch(getListWorkouts());
  };
};

export const goToWeek = data => {
  return dispatch => {
    dispatch({ type: Types.STUDIO_PROGRAM_GO_TO_WEEK, payload: { data } });
    dispatch(getListWorkouts());
  };
};

const updateWorkoutOfMultipleDays = (weekDaysData, programId) => {
  const workoutsByDay = {};
  forEach(weekDaysData, weekDayData => {
    const { day_index, weekIndex } = weekDayData;
    workoutsByDay[`${weekIndex}_${day_index}`] = weekDayData.workouts;
  });

  return {
    type: Types.STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS,
    payload: { data: workoutsByDay, programId },
  };
};

const updateWorkoutListOfSingleDay = (data, bodyData, isDelete) => {
  const dayWorkoutss = { [`${bodyData.weekIndex}_${data.day_index}`]: data.workouts };
  return {
    type: Types.STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_SINGLE_DAY,
    payload: { data: dayWorkoutss, programId: bodyData.programId, workoutId: get(bodyData, 'workoutId', ''), isDelete },
  };
};

export const addWorkoutToStudioProgram = (bodyData, continueShowingThePopup) => {
  return Request.post(
    { url: '/api/studio-program/add-new-workout', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData));
    },
  );
};

export const assignWorkoutToStudioProgram = bodyData => {
  return Request.post(
    { url: '/api/studio-program/assign-new-workout', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData));
    },
  );
};

export const copyStudioProgramWorkout = workout => {
  toast(<MultiplePasteMessage />);
  return {
    type: Types.STUDIO_PROGRAM_COPY_WORKOUT,
    payload: { data: workout },
  };
};

export const updateStudioProgramWorkout = (bodyData, continueShowingThePopup) => {
  return Request.put(
    { url: '/api/studio-program/update-workout-detail', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData));
    },
  );
};

export const saveStudioProgramWorkoutToLibrary = bodyData => {
  return Request.post({ url: '/api/studio-program/save-to-library', data: bodyData }, false, () => {
    toast.success('Workout saved to library!', { autoClose: 3000 });
  });
};

export const deleteStudioProgramWorkout = bodyData => {
  return Request.post(
    { url: '/api/studio-program/remove-workout', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData, true));
    },
  );
};

export const getStudioProgramWorkoutDetail = (params, view, otherData) => {
  return Request.get({ url: '/api/studio-program/get-workout-detail', params }, false, (response, { dispatch }) => {
    if (view) {
      const { data } = response.data;
      dispatch(viewStudioProgramWorkoutDetail(data, otherData));
    }
  });
};

export const viewStudioProgramWorkoutDetail = (workoutData, otherData) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { studioProgram },
    } = getState();
    const { weekIndex, dayIndex, programId, disabled } = otherData;
    const { number_of_weeks } = studioProgram.workingStudio;

    dispatch(
      toggleModal(
        true,
        <WorkoutDetailModal
          key="update-studio-program-workout"
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          workingWorkout={{ ...workoutData, day: Number(weekIndex) * 7 + Number(dayIndex) }}
          fetchWorkoutsByWeek={fetchStudioProgramWorkoutsByWeek}
          maxWeeks={number_of_weeks}
          readOnly={!!disabled}
          pdfType="studio_program_workout"
          onSave={newWorkout => {
            const bodyData = {
              weekIndex,
              dayIndex,
              programId,
              title: newWorkout.title,
              description: newWorkout.description,
              sections: newWorkout.sections,
              workoutId: newWorkout._id,
              background: newWorkout.background,
            };
            return dispatch(updateStudioProgramWorkout(bodyData)).then(response => {
              const { data } = response.data;
              return find(data.workouts, w => w._id === bodyData.workoutId);
            });
          }}
          saveToLibrary={workout => dispatch(saveStudioProgramWorkoutToLibrary({ workoutId: workout._id, programId }))}
          onDelete={workout => {
            dispatch(
              deleteStudioProgramWorkout({
                programId,
                weekIndex,
                dayIndex,
                workoutId: workout._id,
              }),
            );
          }}
        />,
      ),
    );
  };
};

export const resetStudioProgramCopyItem = () => ({ type: Types.STUDIO_PROGRAM_RESET_COPY_ITEM });

export const pasteWorkoutToDay = (bodyData, multiPaste) => {
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetStudioProgramCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/studio-program/copy-workout', data: bodyData }, true, response => {
        const { data } = response.data;
        dispatch(updateWorkoutListOfSingleDay(data, bodyData));
      }),
    );
  };
};

const moveStudioProgramWorkoutOnLocal = data => ({
  type: Types.STUDIO_PROGRAM_MOVE_WORKOUT_ON_LOCAL,
  payload: { data },
});

const arrangeWorkoutsInDayOnLocal = data => ({
  type: Types.STUDIO_PROGRAM_ARRANGE_WORKOUT_ON_LOCAL,
  payload: { data },
});

export const arrangeStudioProgramWorkoutInDay = (bodyData, oldWorkoutIndex) => {
  return dispatch => {
    dispatch(arrangeWorkoutsInDayOnLocal(bodyData));

    return dispatch(
      Request.post({ url: '/api/studio-program/arrange-workout', data: bodyData }, false, (response, { dispatch }) => {
        dispatch(updateWorkoutListOfSingleDay(response.data.data, bodyData));
      }),
    );
  };
};

export const arrangeSectionInsideStudipProgramWorkout = bodyData => {
  return dispatch => {
    dispatch({
      type: Types.STUDIO_PROGRAM_MOVE_EXERCISE,
      payload: {
        data: {
          workoutId: bodyData.workoutId,
          newIndex: bodyData.newIndex,
          oldIndex: bodyData.oldIndex,
          oldWeekIndex: bodyData.weekIndex,
          oldDayIndex: bodyData.dayIndex,
          newWeekIndex: bodyData.weekIndex,
          newDayIndex: bodyData.dayIndex,
          newWorkoutId: bodyData.workoutId,
        },
      },
    });
    return dispatch(
      Request.post(
        { url: '/api/studio-program/arrange-section', data: bodyData },
        false,
        (response, { dispatch }) => {
          dispatch(updateWorkoutListOfSingleDay(response.data.data, bodyData));
        },
        () =>
          dispatch({
            type: Types.STUDIO_PROGRAM_MOVE_EXERCISE,
            payload: {
              data: {
                workoutId: bodyData.workoutId,
                newIndex: bodyData.oldIndex,
                oldIndex: bodyData.newIndex,
                oldWeekIndex: bodyData.weekIndex,
                oldDayIndex: bodyData.dayIndex,
                newWeekIndex: bodyData.weekIndex,
                newDayIndex: bodyData.dayIndex,
                newWorkoutId: bodyData.workoutId,
              },
            },
          }),
      ),
    );
  };
};

export const moveStudioProgramWorkout = (bodyData, oldWorkoutIndex) => {
  return dispatch => {
    dispatch(moveStudioProgramWorkoutOnLocal(bodyData));
    return dispatch(
      Request.post(
        { url: '/api/studio-program/move-workout', data: bodyData },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch(updateWorkoutOfMultipleDays(data, bodyData.programId));
        },
        (error, { dispatch }) => {
          const data = {
            ...bodyData,
            oldWeekIndex: bodyData.newWeekIndex,
            oldDayIndex: bodyData.newDayIndex,
            newWeekIndex: bodyData.oldWeekIndex,
            newDayIndex: bodyData.oldDayIndex,
            newIndex: oldWorkoutIndex,
          };

          dispatch(moveStudioProgramWorkoutOnLocal(data));
        },
      ),
    );
  };
};

export const moveStudioProgramSection = bodyData => {
  return dispatch => {
    dispatch({ type: Types.STUDIO_PROGRAM_MOVE_EXERCISE, payload: { data: bodyData } });
    return dispatch(
      Request.post(
        { url: '/api/studio-program/move-section', data: bodyData },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch(updateWorkoutOfMultipleDays(data, bodyData.programId));
        },
        () =>
          dispatch({
            type: Types.STUDIO_PROGRAM_MOVE_EXERCISE,
            payload: {
              data: {
                workoutId: bodyData.newWorkoutId,
                newIndex: bodyData.oldIndex,
                oldIndex: bodyData.newIndex,
                oldWeekIndex: bodyData.newWeekIndex,
                oldDayIndex: bodyData.newDayIndex,
                newWeekIndex: bodyData.oldWeekIndex,
                newDayIndex: bodyData.oldDayIndex,
                newWorkoutId: bodyData.workoutId,
              },
            },
          }),
      ),
    );
  };
};

export const turnOnEditMode = programId => {
  return Request.post(
    { url: '/api/studio-program/start-edit-mode', data: { programId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_ENABLE_EDIT_MODE, payload: { data, programId } });
      dispatch(getListWorkouts());
    },
  );
};

export const cancelStudioProgramChanges = ({ programId }) => {
  return Request.post(
    { url: '/api/studio-program/cancel-changes', data: { programId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_CANCEL_CHANGES, payload: { data, programId } });
      dispatch(getListWorkouts());
    },
  );
};

export const publishStudioProgramChanges = ({ programId }) => {
  return Request.post(
    { url: '/api/studio-program/publish-changes', data: { programId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_PUBLISH_CHANGES, payload: { data, programId } });
      dispatch(getListWorkouts());
    },
  );
};

export const listenToStudioProgramLastEditBy = programId => {
  return dispatch => {
    database
      .ref('studio_program')
      .child(programId)
      .child('last_edit_by')
      .on('value', snapshot => {
        let data = snapshot.val();

        if (!data) {
          return;
        }

        dispatch({ type: Types.STUDIO_PROGRAM_UPDATE_LAST_EDIT_BY, payload: { data, programId } });
      });
  };
};

export const socketListenToStudioProgramLastEditBy = (data, programId) => {
  return dispatch => {
    dispatch({ type: Types.STUDIO_PROGRAM_UPDATE_LAST_EDIT_BY, payload: { data, programId } });
  };
};

export const addSectionToStudioProgramWorkout = data => {
  return Request.post({ url: '/api/studio-program/add-workout-section', data }, true, (response, { dispatch }) => {
    dispatch(updateWorkoutListOfSingleDay(response.data.data, data));
  });
};

export const updateStudioProgramSectionFromCalendar = bodyData => {
  return Request.put(
    { url: '/api/studio-program/update-workout-detail', data: bodyData },
    false,
    (response, { dispatch }) => {
      dispatch(updateWorkoutListOfSingleDay(response.data.data, bodyData));
    },
  );
};

export const removeStudioProgramWeek = data => {
  const weekIndex = get(data, 'weekIndex', 0);

  return Request.post({ url: '/api/studio-program/remove-week', data }, false, (response, { dispatch }) => {
    const {
      data: { data },
    } = response;
    if (data) {
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_REMOVE_WEEK, payload: { data: data, weekIndex } });
      dispatch(getListWorkouts());
      toast(`Week ${weekIndex} has been removed.`, {
        className: CLASSNAME_TOAST.TRAINING_CALENDAR,
      });
    }
  });
};

export const addStudioProgramWeek = data => {
  const weekIndex = get(data, 'weekIndex', null);
  return Request.post({ url: '/api/studio-program/add-new-week', data }, false, (response, { dispatch }) => {
    const {
      data: { data },
    } = response;
    if (data) {
      dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_ADD_WEEK, payload: { data: data, weekIndex } });
      dispatch(getListWorkouts());
    }
  });
};

export const fetchStudioProgramWorkoutsByWeek = data => {
  return (dispatch, getState) => {
    const state = getState();
    const { workingStudio } = state.rootReducer.studioProgram;
    const params = { ...data, programId: workingStudio._id };

    return axiosInstance.get('/api/studio-program/get-program-calendar-by-weeks', { params }).then(response => {
      const workoutData = {};
      forEach(response.data.data, week => {
        forEach(week.days_workout, dayData => {
          const dayIndex = week.weekIndex * 7 + dayData.day_index;
          workoutData[dayIndex] = {
            day: dayIndex,
            workouts: get(dayData, 'day_workout.workouts', []),
          };
        });
      });
      return workoutData;
    });
  };
};

export const assignProgramToStudioProgram = data => {
  return dispatch => {
    dispatch(
      Request.post({ url: '/api/studio-program/assign-program', data }, true, (response, { dispatch }) => {
        // dispatch({ type: Types.STUDIO_PROGRAM_SUCCESS_ADD_WEEK, payload: { data: response.data.data } });
        // dispatch(getListWorkouts());
      }),
    );
  };
};

export const handleSelectWeek = weekId => {
  const TEMP_ID = mongoObjectId();
  return dispatch => {
    dispatch({ type: Types.STUDIO_PROGRAM_COPY_WEEK, payload: { weekId, weekCopyId: TEMP_ID } });
    toast(`Week ${weekId + 1} Copied. Click on week to paste workouts.`, {
      className: CLASSNAME_TOAST.TRAINING_CALENDAR,
    });
  };
};

export const handleResetWeek = () => {
  return { type: Types.STUDIO_PROGRAM_RESET_WEEK };
};

export const handlePasteWeek = ({ weekId, multiPaste }) => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id', '');
    const selectedWeek = get(state, 'rootReducer.studioProgram.selectedWeek', 0);
    const weekCopyId = get(state, 'rootReducer.studioProgram.weekCopyId');

    if (isEmpty(studioProgramId) || !isInteger(weekId)) {
      return;
    }

    dispatch({ type: Types.STUDIO_PROGRAM_PASTE_WEEK_REQUEST, payload: { multiPaste } });

    return dispatch(
      Request.post(
        {
          url: '/api/studio-program/copy-week-workouts',
          data: {
            program_id: studioProgramId,
            copy_week: selectedWeek,
            paste_week: weekId,
            copy_id: weekCopyId,
          },
        },
        false,
        response => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.STUDIO_PROGRAM_PASTE_WEEK_SUCCESS });
          }
        },
        error => dispatch({ type: Types.STUDIO_PROGRAM_PASTE_WEEK_FAILED, error }),
      ),
    );
  };
};

export const handleRemoveWeek = weekIndex => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id', '');
    const weekNumber = weekIndex + 1;

    if (isEmpty(studioProgramId) || !isInteger(weekIndex)) {
      return;
    }

    dispatch({
      type: Types.STUDIO_PROGRAM_REMOVE_WEEK_REQUEST,
    });

    return dispatch(
      Request.post(
        {
          url: '/api/studio-program/remove-week-workouts',
          data: {
            program_id: studioProgramId,
            week_index: weekIndex,
          },
        },
        false,
        response => {
          const removedIds = get(response, 'data.data.workout_ids', []);
          if (removedIds) {
            dispatch({
              type: Types.STUDIO_PROGRAM_REMOVE_WEEK_SUCCESS,
            });
            const workoutsDeleted = removedIds.length;
            const unit = pluralize('Workout', workoutsDeleted);
            const verbs = workoutsDeleted !== 1 ? 'have' : 'has';
            toast(`All ${unit} from Week ${weekNumber} ${verbs} been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
      ),
    );
  };
};

export const handleSelectWorkout = ({ workoutIds }) => {
  return { type: Types.STUDIO_PROGRAM_SELECT_MULTIPLE_WORKOUT, payload: { workoutIds } };
};

export const handleResetWorkout = () => {
  return { type: Types.STUDIO_PROGRAM_RESET_MULTIPLE_WORKOUT };
};

export const handleCopyWorkout = workouts => {
  return dispatch => {
    dispatch({
      type: Types.STUDIO_PROGRAM_COPY_MULTIPLE_WORKOUT,
      payload: { workouts },
    });
    toast(`${workouts.length} ${pluralize('Workout', workouts.length)} copied. Click on date to paste workouts.`, {
      className: CLASSNAME_TOAST.TRAINING_CALENDAR,
    });
  };
};

export const handleRemoveMultipleWorkout = selectedWorkout => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id', '');

    if (isEmpty(studioProgramId) || isEmpty(selectedWorkout)) {
      return;
    }

    if (isEmpty(selectedWorkout)) {
      dispatch(handleResetWorkout());
      toast(`0 Workouts have been removed.`, { className: CLASSNAME_TOAST.TRAINING_CALENDAR });
      return;
    }

    dispatch({
      type: Types.STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_REQUEST,
    });

    return dispatch(
      Request.post(
        {
          url: `/api/studio-program/bulk-remove-workouts`,
          data: {
            program_id: studioProgramId,
            workout_ids: selectedWorkout,
          },
        },
        true,
        (response, { dispatch }) => {
          const removedIds = get(response, 'data.data.workout_ids', []);
          if (removedIds) {
            dispatch({
              type: Types.STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_SUCCESS,
            });
            const workoutsDeleted = removedIds.length;
            const unit = pluralize('Workout', workoutsDeleted);
            const verbs = workoutsDeleted !== 1 ? 'have' : 'has';
            toast(`${workoutsDeleted} ${unit} ${verbs} been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => {
          dispatch({ type: Types.STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_FAILED });
        },
      ),
    );
  };
};

export const handlePasteMultipleWorkout = ({ dayIndex, weekIndex, multiPaste = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id');
    const selectedWorkout = get(state, 'rootReducer.studioProgram.selectedWorkout');

    if (isEmpty(studioProgramId) || isEmpty(selectedWorkout) || !isInteger(dayIndex)) return;

    dispatch({
      type: Types.STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_REQUEST,
      payload: { multiPaste },
    });
    return dispatch(
      Request.post(
        {
          url: `/api/studio-program/bulk-copy-workouts`,
          data: {
            program_id: studioProgramId,
            workout_ids: selectedWorkout,
            day_index: dayIndex,
            week_index: weekIndex,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_SUCCESS });
          }
        },
        error => {
          dispatch({ type: Types.STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_FAILED, error });
        },
      ),
    );
  };
};

// Socket update workout after copy week/workout
export const socketStudioProgramWorkoutAdded = data => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id');

    const weekIndex = get(data, 'week_index');
    const dayIndex = get(data, 'day_index');
    const workout = get(data, 'workout');

    if (isEqual(studioProgramId, get(data, 'program_id'))) {
      return dispatch({
        type: Types.STUDIO_PROGRAM_SOCKET_WORKOUT_ADDED,
        payload: {
          studioProgramId,
          workout,
          weekIndex,
          dayIndex,
        },
      });
    }
    return;
  };
};

export const socketStudioProgramWorkoutDeleted = data => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id');

    const workoutIds = get(data, 'workout_ids');

    if (isEqual(studioProgramId, get(data, 'program_id'))) {
      return dispatch({
        type: Types.STUDIO_PROGRAM_SOCKET_WORKOUT_DELETED,
        payload: {
          studioProgramId,
          workoutIds,
        },
      });
    }
    return;
  };
};

export function assignProgramToStudioProgramSuccess(dispatch, data) {
  dispatch(getListWorkouts());
}

export const updatePreferences = data => {
  return (dispatch, getState) => {
    const state = getState();
    const studioProgramId = get(state, 'rootReducer.studioProgram.workingStudio._id');
    const studioProgramName = get(state, 'rootReducer.studioProgram.workingStudio.program_name', '');
    if (isEmpty(studioProgramId)) return;
    const params = {
      show_detail_of_workouts: data,
    };

    return dispatch(
      Request.patch({ url: `/api/studio-program/${studioProgramId}/preferences`, params }, false, response => {
        const success = get(response, 'data.success', false);
        if (success) {
          if (get(state, 'rootReducer.studioProgram.workingStudio._id')) {
            dispatch({
              type: Types.STUDIO_PROGRAM_SUCCESS_UPDATE,
              payload: { data: { preferences: params } },
            });
          }
          toast(`${studioProgramName}'s Setting has been updated`);
        }
      }),
    );
  };
};

export const updateShareStatus = ({ params = {}, studioProgramId } = {}) => (dispatch, getState) => {
  if ((!params.owner && params.share !== TEAM_SHARE_NOOWNER) || !studioProgramId) return;
  const {
    user: { _id: userId, team_member = [] },
    rootReducer: {
      studioProgram: { list },
    },
  } = getState();

  return dispatch(
    Request.patch(
      {
        url: `/api/studio-program/${studioProgramId}/share`,
        data: params,
      },
      true,
      response => {
        if (((response.data || {}).data || {}).success) {
          let newList = [];
          if (params.owner === userId || params.share !== TEAM_SHARE_PRIVATE) {
            newList = [...list].map(it => {
              if (it._id === studioProgramId) {
                const author_info = team_member.find(it => it._id === params.owner);
                return { ...it, share: params.share, author: params.owner, author_info };
              }
              return it;
            });
          } else {
            newList = [...list].filter(it => it._id !== studioProgramId);
          }

          dispatch({
            type: Types.STUDIO_PROGRAM_CHANGE_SHARE_STATUS,
            payload: newList,
          });
        }
      },
    ),
  );
};
