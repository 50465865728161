import get from 'lodash/get';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPackageAnalyticsData, changeQueryParams } from 'redux/package-analytics/actions';
import { C_KEY_TOKEN, PACKAGE_STATUS } from 'constants/commonData';
import PackageLayout from '../PackageLayout';
import List from './components/List';
import TabBar from './components/TabBar';
import AnalyticsChart from './components/AnalyticsChart/';
import CopyPackage from '../Overview/PackageActions/CopyPackage';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { getCookie, genTokenFromMarketplace } from 'utils/commonFunction';

const Analytics = props => {
  const isMP = get(props, 'match.url', '').includes('marketplace');
  const currentId = useMemo(() => {
    return props.workingPackage.toJS().id;
  }, [props.workingPackage.toJS().id]);

  const isPublished = props.workingPackage.toJS().status === PACKAGE_STATUS.PUBLISH && !isMP;
  const { location, user, dispatch } = props;

  useEffect(() => {
    return () => {
      props.resetPackageAnalyticsData();
      props.toggleModal(false);
      props.toggleConfirmModal(false);
    };
  }, []);

  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (isMP && token && search.includes('redirect-platform')) {
      const replacePathname = search.includes('&') && pathname + search.replace('redirect-platform&', '');
      genTokenFromMarketplace({ token, user, pathname, search, dispatch, replacePathname });
    }
  }, [pathname, search]);

  return (
    <PackageLayout
      isMP={isMP}
      rightContent={
        isPublished ? <CopyPackage isMP={isMP} packageId={props.workingPackage.get('hash_id') || currentId} /> : null
      }
    >
      <AnalyticsChart isMP={isMP} packageId={currentId} />
      {currentId && <TabBar isMP={isMP} packageId={currentId} workingPackage={props.workingPackage} />}
      <List isMP={isMP} />
    </PackageLayout>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageDetail },
    router: { location },
    user,
  } = state;

  return { workingPackage: packageDetail.get('workingPackage'), location, user };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetPackageAnalyticsData: bindActionCreators(resetPackageAnalyticsData, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(Analytics);
