export const MODE = {
  CREATE_TEMPLATE: 'create_template',
  UPDATE_TEMPLATE: 'update_template',
  CLONE_TEMPLATE: 'clone_template',
};

export const formatCreateProgramParams = program => ({
  title: program.title || '',
  description: program.description || '',
  week_count: program.week_count || 1,
  tags: program.tags || [],
  author: program.author ? program.author._id : null,
  share: program.share,
  modality: program.modality || null,
  level: program.level || null,
  background: program.background || '',
});

export const formatUpdateProgramParams = program => ({
  _id: program._id,
  title: program.title || '',
  description: program.description || '',
  tags: program.tags || [],
  author: program.author ? program.author._id : null,
  share: program.share,
  modality: program.modality || null,
  level: program.level || null,
  background: program.background || '',
});

export const formatCloneProgramParams = program => ({
  _id: program._id,
  title: program.title || '',
  description: program.description || '',
  tags: program.tags || [],
  author: program.author ? program.author._id : null,
  share: program.share,
  modality: program.modality || null,
  level: program.level || null,
  background: program.background || '',
});
