import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import { Image } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { Wrapper, Trigger, Item, Menu } from './style';

const MAX_MENU_HEIGHT = 300;

const FIELDS_SHOW_DISPLAY = ['rpm', 'watts']

class SelectFieldDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.trigger = React.createRef();
  }

  renderMenu = () => {
    if (!this.trigger.current || !this.state.open) {
      return null;
    }

    const { hiddenFields } = this.props;

    const { onSelect, fields, selectedFields } = this.props;
    const viewportHeight = window.innerHeight;
    const { height, x, y } = this.trigger.current.getBoundingClientRect();
    const styles = { left: x };
    const total = fields.length;
    const bottomSpacing = viewportHeight - (y + height + 2);
    const topSpacing = y - 2;

    if (bottomSpacing >= MAX_MENU_HEIGHT || bottomSpacing >= topSpacing) {
      styles.top = y + height + 2;
      styles.maxHeight = bottomSpacing - 10 > MAX_MENU_HEIGHT ? 300 : bottomSpacing - 10;
    } else {
      styles.bottom = viewportHeight - topSpacing;
      styles.maxHeight = topSpacing - 10;
    }

    return (
      <Menu style={styles} className="select-field-dropdown__menu">
        <div className="select-field-dropdown__list">
          {_.map(fields, item =>
            hiddenFields && hiddenFields.includes(item.unique_code) ? null : (
              <Item
                key={item._id}
                className={classNames('select-field-dropdown__item', {
                  selected: selectedFields.includes(item._id),
                })}
                onClick={() => {
                  this.setState({ open: false });
                  onSelect(item);
                }}
              >
                {FIELDS_SHOW_DISPLAY.includes(item.unique_code) && item.display ? `${item.title} (${item.display})` : item.title}
              </Item>
            ),
          )}
        </div>
      </Menu>
    );
  };

  render() {
    const { open } = this.state;
    const { className } = this.props;
    let wrapperClass = '';

    if (className) {
      wrapperClass += ' ' + className;
    }

    return (
      <RootCloseWrapper disabled={!open} event="click" onRootClose={() => this.setState({ open: false })}>
        <Wrapper className={wrapperClass} className="select-field-dropdown">
          <Trigger
            onClick={() => this.setState({ open: !open })}
            ref={this.trigger}
            className="select-field-dropdown__trigger"
          >
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3V0H3L3 3H0V5H3L3 8H5V5H8V3H5Z" fill="#6D7987" />
            </svg>
          </Trigger>
          {this.renderMenu()}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { fields } = rootReducer.exercise;

  return { fields };
};

export default connect(mapStateToProps, null)(SelectFieldDropdown);
