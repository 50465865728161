import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Heading = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 15px;
`;

export const BillingItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #000000;
  margin-bottom: 15px;
`;

export const BillingDetailContainer = styled.div`
  border-bottom: 1px solid #d5d9e9;
  padding-bottom: 15px;
`;

export const TotalDueItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #000000;

  > :last-child {
    font-size: 18px;
  }
`;

export const TotalDueContainer = styled.div`
  padding-top: 30px;
  flex: 1 1;
`;

export const PromoCostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  .promoCost__code {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #0085ff;
    display: flex;
    align-items: center;

    .promoCost__removeIcon {
      cursor: pointer;
      margin-left: 5px;
      width: 12px;
      height: 12px;
      background-image: url(${CDN_URL}/images/c_remove.svg);
      background-color: #ffffff;

      :hover {
        background-image: url(${CDN_URL}/images/c_remove_purple.svg);
      }
    }
  }

  .promoCost__value {
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-align: right;
    color: #202353;
  }
`;

export const PayButton = styled.button`
  background: linear-gradient(91.48deg, #05b4ff 7.12%, #0085ff 90.44%);
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  padding: 8px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;

  :hover {
    background: linear-gradient(97.89deg, #05b4ff 7.12%, #00c2ff 90.44%);
  }

  &:disabled {
    background: #ffffff;
    color: #afb4cf;
    :hover {
      background: #ffffff;
    }
  }
`;

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #f9f9fb;
  border: 1px solid #d5d9e9;
  border-radius: 5px;
  padding: 30px 20px 25px 20px;
  min-height: 370px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
