import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import { generateAWSImageFallback } from 'utils/commonFunction';

const Image = styled.img`
  max-width: 100%;
  max-height: 200px;
  display: block;
  min-width: 60px;
  min-height: 60px;

  ${props =>
    props.noSizeLimit &&
    css`
      min-width: unset;
      min-height: unset;
      max-width: unset;
      max-height: unset;
    `}

  ${props =>
    props.fluid &&
    css`
      width: 100%;
      height: 100%;
    `}

  ${props =>
    props.cover &&
    css`
      object-fit: cover;
    `}
`;

class ImageWithFallback extends React.PureComponent {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = { images: generateAWSImageFallback(props.src || []) };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.src, this.props.src)) {
      this.setState({ images: generateAWSImageFallback(this.props.src) });
    }
  }

  render() {
    const { images } = this.state;
    const { src, className, ...otherProps } = this.props;

    return (
      <Image
        {...otherProps}
        className={classnames('image-with-fallback', className)}
        src={images[0]}
        onError={event => {
          event.preventDefault();
          event.persist();

          event.target.onerror = null;
          if (this.currentIndex === images.length) {
            return false;
          }

          if (this.currentIndex < images.length - 1) {
            this.currentIndex += 1;
            event.target.src = images[this.currentIndex];
          } else {
            this.currentIndex += 1;
            event.target.src = MEDIA_PLACEHOLDER;
          }
        }}
      />
    );
  }
}

ImageWithFallback.propTypes = {
  src: PropTypes.array.isRequired,
};

export default ImageWithFallback;
