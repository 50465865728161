import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Avatar from 'react-avatar';

import { getUserShortName } from 'utils/commonFunction';
import ConfirmModal from 'shared/ConfirmModal/component';
import * as S from './style';

const getRole = data => {
  return data.role === 1 ? 'Coach' : 'Admin';
};

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select_option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  const name = `${data.first_name} ${data.last_name}`;

  return (
    <S.SelectOptionContainer {...innerProps} className={className}>
      <S.ProfileInfo>
        <Avatar name={getUserShortName(data)} size="26" src={data.avatar} color={data.color} />
        <S.SelectOptionLabel className="select__option__label">
          <div className="name">{name}</div>
          <div className="email">{data.email}</div>
        </S.SelectOptionLabel>
      </S.ProfileInfo>
      <S.RoleName>{getRole(data)}</S.RoleName>
    </S.SelectOptionContainer>
  );
};

const SingleValue = ({ innerProps, data }) => {
  return (
    <S.ContactInformation {...innerProps}>
      <S.SingleLabel className="select__option__label">
        <Avatar name={getUserShortName(data)} size="36" src={data.avatar} color={data.color} />
        <div className="name">{data.label}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const TransferMember = ({ isConfirmModalOpen, dispatch, onConfirm, teammates }) => {
  const [selectedTeammate, setSelectedTeam] = useState(null);

  const options = useMemo(
    () =>
      (teammates || []).map(item => ({
        ...item,
        key: item._id,
        value: item._id,
        label: `${item.first_name} ${item.last_name}`,
      })),
    [teammates],
  );

  const handleSelectedTeammate = value => {
    setSelectedTeam(value);
  };

  const handleConfirmTransfer = () => {
    selectedTeammate && onConfirm && onConfirm(selectedTeammate);
  };

  return (
    <ConfirmModal
      title="Transfer Ownership"
      isConfirmModalOpen={isConfirmModalOpen}
      className={`transfer-ownership ${!selectedTeammate ? 'empty' : 'selected'}`}
      content={
        <S.TransferTeamWrapper>
          <S.TransferMessageConfirm>
            Select one of your teammates in the forum to become the owner.
          </S.TransferMessageConfirm>
          <S.TransferLabel>Assign Forum To</S.TransferLabel>
          <Select
            options={options}
            components={{
              Option: SelectOption,
              SingleValue,
              IndicatorSeparator: null,
              ClearIndicator: null,
            }}
            classNamePrefix="evfSelectBox"
            className="evfSelectBoxContainer"
            placeholder="Choose a team member"
            onChange={handleSelectedTeammate}
            isSearchable={false}
            isMulti={false}
            menuPosition="fixed"
            styles={{
              control: base => ({ ...base, height: 45, width: 548 }),
            }}
          />
        </S.TransferTeamWrapper>
      }
      onConfirm={handleConfirmTransfer}
      confirmButtonTitle="Transfer and leave"
      cancelButtonTitle="Cancel"
      newStyle
      noIcon
      dispatch={dispatch}
      hasCloseIcon
    />
  );
};

const mapStateToProps = state => {
  const { isConfirmModalOpen, confirmModal } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferMember);
