import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #f0f0f2;
  .thumbnail {
    width: 100%;
    height: 145px;
    background-color: #f0f0f2;
  }
  .content {
    padding: 14px;
  }
  .long-name {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 5px;
    background-color: #f0f0f2;
    height: 14px;
  }
  .short-name {
    border-radius: 3px;
    background-color: #f0f0f2;
    margin-bottom: 7px;
    height: 14px;
    width: 40%;
  }
  ${({ isRecipe }) =>
    isRecipe &&
    css`
      .thumbnail {
        height: 130px;
      }
      .content {
        padding: 12px 14px 14px;
        height: 103px;
      }
      .long-name {
        border-radius: 3px;
        margin-bottom: 5px;
        height: 10px;
      }
      .short-name {
        margin-bottom: 29px;
        height: 10px;
        width: 60%;
      }
    `}
`;
