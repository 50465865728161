import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FormControl, Label } from './style';
import { onlyAllowNumber } from 'utils/event-api';

export default class FormItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInvalidMsg = () => {
    const { rules = [], required, value } = this.props;
    let msg = '';
    if (required && _.isEmpty(value)) {
      return 'This field is required.';
    }
    _.forEach(rules, rule => {
      if (rule.regex && !rule.regex.test(value)) {
        msg = rule.message;
        return false;
      }
      if (!rule.valid) {
        msg = rule.message;
        return false;
      }
    });
    return msg;
  };

  onFocus = e => {
    this.setState({ isFocused: true });
  };

  onBlur = e => {
    this.setState({ isFocused: false });
  };

  renderInput = (type, value, onChange, placeholder, inputProps) => {
    const { isFocused } = this.state;

    switch (type) {
      case 'textarea':
        return (
          <textarea
            {...inputProps}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={value}
            onChange={onChange}
            placeholder={!isFocused ? placeholder : undefined}
          />
        );
      case 'number':
        return (
          <input
            {...inputProps}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            type="number"
            value={value}
            onChange={onChange}
            placeholder={!isFocused ? placeholder : undefined}
            onKeyPress={onlyAllowNumber}
          />
        );
      default:
        return (
          <input
            {...inputProps}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={value}
            onChange={onChange}
            placeholder={!isFocused ? placeholder : undefined}
          />
        );
    }
  };

  render() {
    const { label, type, value, onChange, placeholder, isSubmited, inputProps, maxLength, addOnsButton } = this.props;
    const errorMsg = this.getInvalidMsg();
    const { isFocused } = this.state;
    return (
      <FormControl
        addOnsButton={(!!value || isFocused) && addOnsButton}
        className={classNames({ error: isSubmited && !!errorMsg })}
      >
        <Label>
          {label}
          {maxLength ? (
            <span className="label__maxLength">
              {value.length} / {maxLength}
            </span>
          ) : null}
        </Label>
        <div className="formItem__input">
          {this.renderInput(type, value, onChange, placeholder, inputProps)}
          <div className="formItem__input--addOns">{!!value || isFocused ? addOnsButton : null}</div>
        </div>
      </FormControl>
    );
  }
}
