import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const RecipeNameWrapper = styled.div`
  .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    border: 1px solid #5158cf;
    background-color: #fff !important;
  }
  .text-editable__container {
    max-height: 116px;
    overflow-y: scroll;
    padding: 0 10px;
    pointer-events: none;
    ::-webkit-scrollbar {
      width: 16px !important;
    }
    ::-webkit-scrollbar-thumb {
      min-height: 30px !important;
      background: #e1e1ea !important;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box !important;
      border-radius: 9999px;
    }
    .text-editable__input {
      ${baseText}
      font-weight: 600;
      font-size: 25px;
      line-height: 40px;
      color: #202353;
      word-break: break-word;
      :empty::before {
        ${baseText}
        font-weight: 600;
        font-size: 25px;
        line-height: 40px;
        color: #a3a3b5;
        opacity: 1;
      }
    }
    :not(.text-editable__container--active) {
      .text-editable__input {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  ${props =>
    props.isEditMode &&
    css`
      .text-editable__container {
        pointer-events: auto;
      }
    `}
`;

export const ErrorMessage = styled.span`
  ${baseText}
  margin-top: 2px;
  color: #ea314a;
  display: inline-block;
`;

export const MaxLength = styled.span`
  ${baseText}
  color: #7B7E91;
  padding-left: 10px;
`;
