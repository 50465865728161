import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 69px;
  height: 30px;
  padding: 0 5px 0 9px;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  user-select: none;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #202353;

  .arrow-icon {
    width: 16px;
    height: 16px;
    path {
      fill: #8789a8;
    }
  }

  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    .arrow-icon path {
      fill: #5158cf;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #f0f1ff;
      color: #5158cf;
      .arrow-icon path {
        fill: #5158cf;
      }
    `}
`;

export const ViewModePopup = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  width: 110px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;

  span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #202353;
    user-select: none;
  }

  .check-icon {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #f0f1ff;
    span {
      color: #5158cf;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      span {
        font-weight: 600;
        color: #5158cf;
      }
    `}
`;
