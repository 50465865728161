import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import {
  getClientList,
  changeAutoflowClientQueryParam,
  selectClients,
  deleteClient,
  resetData,
  deleteClients,
} from 'redux/autoflow/client/actions';
import { toggleModal } from 'actions/modal';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { list, query, selectedClients, firstLoad } = rootReducer.autoflow.client;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    list,
    selectedClients,
    sort: query.sort,
    sorter: query.sorter,
    firstLoad,
    autoflowStatus: workingAutoflow && workingAutoflow.status,
    autoflowName: workingAutoflow && workingAutoflow.name,
    user
  }
 }

 const mapDispatchToProps = dispatch => ({
   getClientList: bindActionCreators(getClientList, dispatch),
   changeAutoflowClientQueryParam: bindActionCreators(changeAutoflowClientQueryParam, dispatch),
   selectClients: bindActionCreators(selectClients, dispatch),
   deleteClient: bindActionCreators(deleteClient, dispatch),
   deleteClients: bindActionCreators(deleteClients, dispatch),
   resetData: bindActionCreators(resetData, dispatch),
   toggleModal: bindActionCreators(toggleModal, dispatch),
 })

 export default connect(mapStateToProps, mapDispatchToProps)(Component);
 