import React from 'react';
import { referralAmount } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

export const ReferralStep = props => {
  return (
    <S.ReferralStepWrapper icon={props.icon} color={props.color}>
      {props.stepLine && (
        <div className="referralStep__line">
          <S.StepLine lineType={props.stepLine} />
        </div>
      )}
      <div className="referralStep__icon" />
      <div className="referralStep__stepName">{props.stepName}</div>
      <div className="referralStep__title">{props.title}</div>
      <div className="referralStep__description">{props.description}</div>
    </S.ReferralStepWrapper>
  );
};

export default class HowItWorkReferral extends React.Component {
  render() {
    return (
      <S.Wrapper>
        <div className="howItWorkReferral">
          <div className="howItWorkReferral__heading">
            <div className="howItWorkReferral__subTitle">How it works?</div>
            <div className="howItWorkReferral__title">Complete 2 easy steps to earn referral credit</div>
          </div>
          <div className="howItWorkReferral__steps">
            <ReferralStep
              icon={`${CDN_URL}/images/referral_step1_icon.svg`}
              color="#ECF6FF"
              stepLine="dashed"
              stepName="Step 1"
              title="Referral signs up for Everfit"
              description="Your friend signs up for a new workspace on Everfit using your referral link or referral code."
            />
            <ReferralStep
              icon={`${CDN_URL}/images/referral_step2_icon.svg`}
              color="#FFF5DD"
              stepLine="solid"
              stepName="Step 2"
              title="Referral pays subscription"
              description="Your referral pays for a monthly or annual subscription plan."
            />
            <ReferralStep
              icon={`${CDN_URL}/images/referral_step3_icon.svg`}
              color="#EDFFED"
              stepName="Hooray!"
              title={`You get $${referralAmount()}! They get $${referralAmount()}!`}
              description="We’ll notify you when the credit has been added to your account."
            />
          </div>
        </div>
      </S.Wrapper>
    );
  }
}
