import { Popup, Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const AddIndividualPopup = styled(Popup)`
  &.ui.popup {
    background-color: transparent;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
  }
  &.ui.top.popup {
    margin: 0 0 8px !important;
  }
  &.ui.bottom.popup {
    margin: 8px 0 0 !important;
  }
  &.ui.top.right.popup {
    margin: 0 0x 8px 0 !important;
  }
  &.ui.bottom.right.popup {
    margin: 8px 0px 0 0 !important;
  }
  &::before {
    content: '';
    display: none;
  }
`;
export const PopupIndividualContent = styled.div`
  width: 150px;
  padding: 8px 0px;
  border-radius: 5px;
  background-color: #2d2e2d;
  box-shadow: unset;
`;
export const PopupIndividualItem = styled.button`
  width: 100%;
  border: none;
  padding: 9px 16px;
  background-color: #2d2e2d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: #535353;
  }

  &:disabled {
    cursor: not-allowed;
  }

  .icon.remove {
    margin-right: 2px;
  }

  .label {
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const GroupBodyMetricWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  height: 100%;
  margin-right: 9px;
  padding-right: 10px;
  padding-left: 25px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #e1e1ea !important;
  }
  &::-webkit-scrollbar-track {
    margin-bottom: 13px;
  }
  .placeholder {
    position: absolute;
    border-radius: 5px;
    border: 1px dashed #5158cf;
    background: #fff;
  }
`;

export const EmptySearchUI = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  height: 100%;
  margin-right: 9px;
  padding-right: 10px;
  padding-left: 25px;
`;

export const EmptyResults = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GroupResult = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MetricResult = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ResultLabel = styled.p`
  color: #7b7e91;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 15px */
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const GroupBodyMetricItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  gap: 8px;
  width: 100%;
  max-width: 386px;
  max-height: 333px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #fff;
  margin-bottom: 13px;

  .header-group {
    position: relative;
    .drag-icon {
      position: absolute;
      display: none;
      left: 6px;
      top: 14px;
      width: 14px;

      path {
        fill: #a3a3b5;
      }
    }
  }

  &.dragging,
  &:hover {
    .header-group .drag-icon {
      display: block;
    }
  }

  &:hover,
  &.selected {
    border-color: #5158cf;
    .header-group {
      cursor: pointer;
      .title-group,
      .title-length {
        color: #5158cf;
      }
      .arrow path {
        fill: #5158cf;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.selected {
    .header-group {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 1px solid #f0f0f2;
    }
  }
  &.show-content {
    .content-group {
      display: flex;
    }
    .empty-content-group {
      display: block;
    }
    .arrow {
      transform: rotate(90deg) !important;
      path {
        fill: #5158cf;
      }
    }
  }
  .header-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding: 0px 15px;
    border-radius: 5px;
    .left-side {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 6px;
      padding-left: 2px;
      .arrow {
        transition: all 0.3s;
        flex-shrink: 0;
      }
    }
    .right-side {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
    .title-group {
      color: #202353;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 170px;
    }
    .title-length {
      color: #202353;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .title-group-wrapper {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: fit-content;
    }
  }
`;

export const ContentGroupWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ContentHeaderGroup = styled.div`
  display: grid;
  grid-template-columns: 155px 105px 105px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  color: #7b7e91;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  height: 25px;
  padding-left: 27px;
  flex-shrink: 0;
`;

export const ContentBodyGroup = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  width: 100% !important;
  padding-left: 8px;
  padding-right: 8px;

  &.show-scrollbar {
    padding-right: 0;
    width: calc(100% - 2px) !important;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #e1e1ea !important;
  }
  &::-webkit-scrollbar-track {
    margin-bottom: 10px;
  }

  .placeholder {
    position: absolute;
    border-radius: 5px;
    border: 1px dashed #5158cf;
    background: #fff;
    width: 366px !important;
  }
`;

export const IconTrigger = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  &:hover,
  &.selected {
    background: #f0f1ff;
    .dot,
    .dot::before,
    .dot::after {
      background-color: #5158cf;
    }
  }

  .button-more-metric-to-group .app-tooltip {
    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: #333;
    padding: 8px 16px;
    margin-top: -16px !important;
    width: unset !important;
    height: unset !important;
    text-transform: capitalize !important;

    ::after {
      border-top-color: #333 !important;
      border-top-width: 8px !important;
      bottom: -9px !important;
    }
  }
`;

export const IndividualAddButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  &:hover,
  &.selected {
    background: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }

  .button-add-metric-to-group {
    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: #333;
    padding: 8px 16px;
    margin-top: -16px !important;

    ::after {
      border-top-color: #333 !important;
      border-top-width: 8px !important;
      bottom: -9px !important;
    }
  }
`;

export const ItemBodyMetricWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20px 155px 90px 105px;
  justify-items: stretch;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 10px 0;
  flex-shrink: 0;
  border-radius: 5px;
  margin-bottom: 4px;

  &:hover .close-icon-wrapper {
    display: flex;
  }
  &:hover,
  &.selected {
    background: #f0f1ff;
  }

  &.loading {
    cursor: not-allowed !important;
  }

  &.dragging {
    width: 368px !important;
    border-radius: 5px;
    background: #ffffff !important;
    box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  }

  .empty {
    color: #a3a3b5 !important;
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }

  &:hover {
    .drag-icon {
      display: block;
    }
  }

  .drag-icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .drag-icon {
    display: none;
    width: 14px;

    path {
      fill: #a3a3b5;
    }
  }

  .close-icon-wrapper {
    width: 20px;
    height: 20px;
    display: none;
    position: absolute;
    right: 5px;
    justify-content: center;
    align-items: center;
    padding: 3px;
    cursor: pointer;

    &:hover {
      border-radius: 4px;
      background: #ffd8dd;

      svg.close-icon path {
        stroke: #ea314a;
      }
    }

    svg.close-icon path {
      stroke: #8789a8;
    }

    .app-tooltip.place-top.remove-metric-group {
      width: 168px;
      height: 34px;
      line-height: 18px;
      padding: 9px;
    }
    .app-tooltip.place-top::after {
      border-top-width: 8px !important;
      bottom: -8px;
    }
  }

  .name {
    color: #202353;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  .value {
    color: #202353;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 93px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .last-update {
    color: #7b7e91;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
    margin-right: 26px;
  }
`;

export const EmptyMetric = styled.div`
  color: #7b7e91;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
`;

export const CustomModalChooseGroup = styled(Modal)`
  &.ui.modal {
    width: 562px;
    height: auto;
    max-height: 640px;
    padding: 0px;
    border-radius: 10px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      border-radius: 10px !important;
      padding: 20px 30px 15px !important;
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      border-radius: 10px !important;
      padding: 0px 9px 0px 30px !important;
    }

    > :last-child {
      background: #fff !important;
      border-top: 1px solid #f0f0f2 !important;
      padding: 15px 30px !important;
    }
  }
`;

export const ModalHeaderTitle = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalHeaderDescription = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  text-wrap: pretty;
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  width: 87px;
  height: 30px;
  border-radius: 5px;
  background: #5158cf;
  border: none;
  color: #fff;

  &:hover {
    background: rgb(81 88 207 / 90%);
  }
`;

export const ModalGroupChooseWrapper = styled.div`
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  padding-right: 15px;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #e1e1ea !important;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 20px;
  }
`;

export const ModalGroupChoose = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 144px 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
`;

export const ModalGroupChooseLabel = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #f8f8f8;
  padding: 8px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 36px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const customChooseGroup = {
  container: baseStyle => ({
    ...baseStyle,
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5158CF' : '#E1E1EA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5158CF',
    },
    minHeight: '36px',
    maxHeight: '36px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FFFFFF',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 10px',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    top: 'calc(50% - 3px)',
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
  }),
  placeholder: baseStyle => ({
    ...baseStyle,
    top: 'calc(50% - 3px)',
    color: '#A3A3B5',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '10px',
    marginBottom: '10px',
    boxShadow: '0px 2px 8px 0px rgba(38, 38, 38, 0.20)',
    border: '1px solid #DCDCDE',
    background: '#FFFFFF',
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '300px',
    overflowX: 'hidden',
    padding: '10px 3px 10px 10px',
    marginRight: '3px',
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px !important',
      background: '#E1E1EA !important',
    },
    '::-webkit-scrollbar-track': {
      marginTop: '10px',
      marginBottom: '10px',
    },
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused ? '#F0F1FF' : '#ffffff',
    ':hover': {
      backgroundColor: '#F0F1FF',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isFocused || state.isSelected ? '#5158CF' : '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : '400',
    lineHeight: 'normal',
    padding: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: '5px',
    position: 'relation',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px',

    '.option-label': {
      backgroundColor: state.isFocused ? '#F0F1FF' : '#ffffff',
      ':hover': {
        backgroundColor: '#F0F1FF',
      },
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#202353',
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: state.isSelected ? '600' : '400',
      lineHeight: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      borderRadius: '5px',
    },
  }),
};

export const CustomModalAddMetric = styled(Modal)`
  &.ui.modal {
    width: 562px;
    height: 100%;
    max-height: 560px;
    padding: 0px;
    border-radius: 10px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      border-radius: 10px !important;
      padding: 20px 30px 30px !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      border-radius: 10px !important;
      padding: 0px 9px 0px 30px !important;
    }

    > :last-child {
      background: #fff !important;
      border-top: 1px solid #f0f0f2 !important;
      padding: 15px 30px !important;
    }
  }

  .search-input {
    width: 224px;
    height: 30px;

    input {
      padding-right: 27px;
      background-position: 15px 7px;

      ::placeholder {
        padding-left: 0px;
      }
    }

    svg {
      right: 14px !important;
      path {
        fill: #a3a3b5;
      }
    }
  }
`;

export const ModalAddMetricHeaderTitle = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalAddMetricWrapper = styled.div`
  overflow: auto;
  position: relative;
  padding-right: 15px;

  &.not-found {
    padding-right: 21px;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #e1e1ea !important;
  }

  &::-webkit-scrollbar-track {
    margin-top: 30px;
  }

  .col-title {
    color: #7b7e91;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .col-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #202353;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    line-height: normal;

    &.name {
      max-width: 190px;
      font-weight: 600;
    }

    &.type {
      font-weight: 400;
    }

    &.unit {
      font-weight: 400;
    }
  }

  .metrics-column-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
  .metrics-column-wrapper.checkbox-wrapper {
    cursor: pointer;
  }

  .metrics-checkbox-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    margin-right: 19px;

    .evf-checkbox.metrics-checkbox-header {
      padding-left: unset;
    }

    .checkbox-icon {
      transform: translate(-50%, -50%);
      border-radius: 4px;
    }

    input:checked ~ .checkbox-icon {
      background-color: #5158cf;
      border-color: #5158cf;
      background-size: 12px;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    table-layout: fixed;
    position: relative;

    thead {
      tr {
        height: 35px;

        th {
          position: sticky;
          top: 0;
          z-index: 1;
          padding: 0px;
          padding-bottom: 5px;
        }

        .col-header {
          height: 30px;
          background: #f8f8f8;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        .metrics {
          width: 100%;

          .col-header {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        .type {
          width: 150px;
        }

        .unit {
          width: 83px;

          .col-header {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        position: relative;
        height: 52px;

        &:hover,
        &.selected {
          background: #fbfbff;

          input:not(:checked) ~ .checkbox-icon {
            background: #f0f1ff;
          }
        }

        &:last-child {
          td {
            border-bottom: unset;
          }
        }

        td {
          padding: 0px;
          border-bottom: 1px solid #f0f0f2;
        }
      }
    }
  }
`;

export const ModalAddMetricFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonAdd = styled.button`
  cursor: pointer;
  width: 83px;
  height: 30px;
  border-radius: 5px;
  background: #5158cf;
  border: none;
  color: #fff;

  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &:hover {
    background: rgb(81 88 207 / 90%);
  }

  &:disabled {
    cursor: not-allowed;
    color: #7b7e91;
    background: #eaeef1;
  }
`;

export const ModalAddMetricEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 312px;
  margin-right: 22px;

  .empty-label {
    color: #202353;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &.no-available {
    height: 420px;
  }
`;

export const MetricSelected = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  flex: 1;

  .count {
    display: flex;
    padding: 0px 15px;
    align-items: center;
    gap: 10px;

    border-radius: 13px;
    background: #202353;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .label {
    color: #202353;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;
