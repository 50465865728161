// Libs
import React, { useMemo } from 'react';

// Constants
import { TRIAL_MODE } from 'constants/commonData';
import { ASSET_TYPE } from 'components/Product/constants';
import { ONBOARDING_FLOW_STATUS } from 'components/OnboardingFlowDetail/constants';
import { CDN_URL } from 'constants/commonData';

// Components
import UpgradePathPopup from 'components/OnboardingFlowDetail/components/UpgradePathPopup';

// Styles
import { StatusLabel } from '../OnboardingFlowHeaderDetail/style';
import * as S from './style';

const OnboardingFlowHeader = ({ isPermission, isHideUpgradePath, permission, teamData, status }) => {
  const isOndemandAddOn = permission['studio_resource_collection'] || permission['on_demand_workout'];
  const isStudioProgram = permission[ASSET_TYPE.STUDIO];
  const isAutomationAddOn = permission[ASSET_TYPE.AUTOFLOW];

  const isTrialMode = (teamData || {}).trialMode === TRIAL_MODE.Active;

  const isFullPermission = isOndemandAddOn && isStudioProgram && isAutomationAddOn && !isTrialMode;

  const isPublished = useMemo(() => status === ONBOARDING_FLOW_STATUS.PUBLISH, [status]);

  const isHasMultiple = permission.multiple_onboarding_flow;

  return (
    <S.Wrapper>
      <S.TitleWrapper isPermission={isPermission}>
        <span>Onboarding Flow</span>
        {!isHasMultiple && isPermission && (
          <StatusLabel isPublished={isPublished} isHasMultiple={!isHasMultiple}>
            {isPublished ? 'Published' : 'Draft'}
          </StatusLabel>
        )}
      </S.TitleWrapper>
      {isPermission && (
        <S.DescriptionWrapper>
          <S.Description>
            Automate your client onboarding effortlessly by seamlessly assigning onboarding forms, auto messages,
            training assignments, and Autoflow
            <S.Action isHideUpgradePath={isHideUpgradePath && !isFullPermission}>
              <S.LearnMore
                href="https://help.everfit.io/en/collections/9970350-onboarding-flow-basics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.LearnMoreIcon src={`${CDN_URL}/images/learn_more_icon.svg`} alt="learn more onboarding flow" />
                <S.LearnMoreText>Learn more</S.LearnMoreText>
              </S.LearnMore>
              {isHideUpgradePath && !isFullPermission && (
                <>
                  <S.Dot />
                  <UpgradePathPopup permission={permission} isTrialMode={isTrialMode} />
                </>
              )}
            </S.Action>
          </S.Description>
        </S.DescriptionWrapper>
      )}
    </S.Wrapper>
  );
};

export default OnboardingFlowHeader;
