import { combineReducers } from 'redux';
import { fromJS } from 'immutable';
import { Types } from './actions';
import { CALENDAR_TYPES } from 'constants/commonData';
import trainingReducer from './training/reducer';
import autoMessageReducer from './auto-message/reducer';
import clientReducer from './client/reducer';
import { Types as AutoflowTypes } from 'redux/autoflow/actions';
import taskReducer from './task/reducer';
import habitReducer from './habit/reducer';

const DEFAULT_CALENDAR_VIEW = CALENDAR_TYPES[1];

const INITITAL_STATE = fromJS({
  viewMode: DEFAULT_CALENDAR_VIEW.value,
  startWeek: 0,
  clientStatistic: {},
});

const common = (state = INITITAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case Types.AUTOFLOW_INTERVAL_CHANGE_VIEW_MODE:
      return state.set('viewMode', payload.mode);

    case Types.AUTOFLOW_INTERVAL_CHANGE_START_DATE:
      return Object.assign({}, state, { startWeek: payload.startDate });

    case Types.AUTOFLOW_INTERVAL_GO_TO_WEEK:
      return state.set('startWeek', payload.week);

    case Types.AUTOFLOW_INTERVAL_UPDATE_WEEK_VIEW:
      return state.mergeDeep(payload.data);

    case Types.AUTOFLOW_INTERVAL_SUCCESS_COUNT_CLIENTS:
      return state.set('clientStatistic', payload.data);

    case AutoflowTypes.RESET_AUTOFLOW_DETAIL_DATA:
      return INITITAL_STATE;

    default:
      return state;
  }
};

const autoflowInterval = combineReducers({
  common,
  training: trainingReducer,
  task: taskReducer,
  autoMessage: autoMessageReducer,
  client: clientReducer,
  habit: habitReducer,
});

export default autoflowInterval;
