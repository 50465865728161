import React from 'react';
import { connect } from 'react-redux';
import _, { get } from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// import actions
import { toggleModal } from 'actions/modal';
import { copyTask, pasteTask, deleteTask, getDetail } from 'redux/autoflowInterval/task/actions';
import { saveToLibrary } from 'redux/task/task.actionCreators';
// import components
import TaskForm from './TaskAutoflowIntervalAddNewContainer';
import ReactTooltip from 'react-tooltip';
import { TaskContainer } from './styles';
import * as CellLayout from '../Calendar/CellLayout';
import { CalendarAssignWorkoutIcon, CloseIcon } from 'shared/Icons';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import HabitDraggable from 'components/TaskCalendarCell/components/HabitDraggable';

const ACTIONS = [
  { key: 'copy', label: 'Copy', icon: 'duplicate', action: 'onCopy' },
  { key: 'delete', label: 'Delete', icon: 'delete', action: 'onDelete' },
];

const TASK_CONTAINER_HEIGHT = 57;
const HEADER_HEIGHT = 34;
const FOOTER_HEIGHT = 22;

class CalendarCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarCellHeight: 0,
      openingPopup: null,
      showAll: false,
      maximumRenderedItem: 0,
      isConfirmRemove: false,
      currentTask: {},
    };

    this.calendarCell = null;
    this.wrapperRef = React.createRef();
    this.resizeObserver = new ResizeObserver(this.handleResizeCallBack);
  }

  componentDidMount() {
    if (this.wrapperRef.current) {
      this.resizeObserver.observe(this.wrapperRef.current);
    }
  }

  componentDidUpdate() {
    if (this.wrapperRef.current) {
      this.resizeObserver.observe(this.wrapperRef.current);
    }
  }

  compoentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  handleResizeCallBack = entries => {
    if (entries[0]) {
      const {
        contentRect: { height },
      } = entries[0];

      if (!height) {
        return;
      }

      const containerHeight = height - FOOTER_HEIGHT - HEADER_HEIGHT;
      const maximumRenderedItem = parseInt(containerHeight / TASK_CONTAINER_HEIGHT);

      if (this.state.maximumRenderedItem !== maximumRenderedItem) {
        this.setState({ maximumRenderedItem });
      }
    }
  };

  handlePaste = event => {
    const multiPaste = event.shiftKey;
    const { copying, pasteTask, weekIndex, dayIndex, workingAutoflow } = this.props;

    if (copying) {
      const params = {
        taskId: copying._id,
        autoflowId: workingAutoflow._id,
        weekIndex,
        dayIndex,
      };

      pasteTask(params, multiPaste);
    }
  };

  toggleRemoveConfirmModal = () => {
    this.setState(state => ({
      isConfirmRemove: !state.isConfirmRemove,
    }));
  };

  handleConfirmRemoveTaskHasRepeat = isOnly => {
    const { currentTask } = this.state;
    const { workingAutoflow, dayIndex, weekIndex, deleteTask } = this.props;
    const params = {
      taskId: currentTask._id,
      autoflowId: workingAutoflow._id,
      dayIndex,
      weekIndex,
      is_only_this_task: isOnly,
      repeatId: currentTask.repeat_task_setting_id._id,
    };
    deleteTask(params);
    this.toggleRemoveConfirmModal();
  };

  onDelete = event => {
    const { taskId } = event.currentTarget.dataset;
    const { workingAutoflow, dayIndex, weekIndex, tasks } = this.props;
    const task = _.find(tasks, t => t._id === taskId);
    if (task.repeat_task_setting_id) {
      this.setState({
        currentTask: task,
      });
      this.toggleRemoveConfirmModal();
    } else {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Delete task"
          content="Are you sure that you want to delete this task?"
          onConfirm={() => {
            const params = {
              taskId: task._id,
              autoflowId: workingAutoflow._id,
              dayIndex,
              weekIndex,
            };
            this.props.deleteTask(params);
          }}
        />,
      );
    }
  };

  onCopy = event => {
    const { taskId } = event.currentTarget.dataset;
    const task = _.find(this.props.tasks, t => t._id === taskId);

    if (task) {
      this.props.copyTask(task);
    }
  };

  viewTaskDetail = event => {
    const { weekIndex, dayIndex, workingAutoflow, getDetail } = this.props;
    const { taskId } = event.currentTarget.dataset;
    getDetail({ taskId: taskId, autoflowId: workingAutoflow._id }).then(res => {
      const { data } = res.data;
      this.props.toggleModal(
        true,
        <TaskForm
          date={parseInt(weekIndex) * 7 + parseInt(dayIndex)}
          task={data}
          mode="edit"
          autoflowType={AUTOFLOW_TYPES.INTERVAL}
          {...this.props}
        />,
      );
    });
  };

  onAddNewTask = () => {
    const { weekIndex, dayIndex } = this.props;

    this.props.toggleModal(
      true,
      <TaskForm
        date={parseInt(weekIndex) * 7 + parseInt(dayIndex)}
        mode="create"
        autoflowType={AUTOFLOW_TYPES.INTERVAL}
        {...this.props}
      />,
    );
  };

  onSaveToLibrary = taskId => () => {
    this.props.saveToLibrary({ taskId, taskSchema: 'autoflow-interval-task' });
  };

  hideViewAllMode = () => {
    this.setState({ showAll: false });
  };

  viewAllTasks = () => {
    this.setState({ showAll: true });
  };

  onPopupToggle = popup => {
    //should open popup
    this.setState({ openingPopup: popup });
  };

  renderTask = index => {
    const { bodymetricTypes, weekIndex, dayIndex, tasks, isMoving } = this.props;
    const task = tasks[index];
    let draggableId = `${task._id};${weekIndex};${dayIndex}`;

    const taskIdString = `task-title-${task._id}`;
    let metricsName = task.schedule_reminder ? task.reminder_time : '';

    if (task.type === 'body_metric') {
      let listMetricsData = task.metrics.reduce((listMetrics, metricId) => {
        const metricData = bodymetricTypes.find(item => item._id === metricId && item.selected);
        if (metricData) {
          listMetrics.push(metricData.name);
        }
        return listMetrics;
      }, []);

      let metricText = listMetricsData.join(', ');

      if (task.schedule_reminder && metricText) metricsName += ' | ';

      metricsName += metricText;
    }

    if (task.repeat_task_setting_id) {
      draggableId += `${draggableId};isRepeating`;
    }

    return (
      <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isMoving}>
        {provided => (
          <TaskContainer
            className="task__container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            data-task-id={task._id}
            onClick={this.viewTaskDetail}
          >
            <img src={`${CDN_URL}/images/new_task_icon_${task.type}.svg`} width={22} className="task__icon" alt="" />
            <div className="task__title" data-tip data-for={taskIdString}>
              <div className="title">{task.title}</div>
              <div className="description">{metricsName}</div>
            </div>
            <Dropdown className="intervalTask__actions">
              <Option onClick={this.onSaveToLibrary(task._id)} data-task-id={task._id}>
                <img src={`${CDN_URL}/images/save.svg`} width={14} style={{ marginRight: 12 }} alt="" />
                <span>Save to library</span>
              </Option>
              {ACTIONS.map(item => (
                <Option key={item.key} onClick={this[item.action]} data-task-id={task._id}>
                  <img src={`${CDN_URL}/images/${item.icon}.svg`} width={14} style={{ marginRight: 12 }} alt="" />
                  <span>{item.label}</span>
                </Option>
              ))}
            </Dropdown>
            <ReactTooltip className="app-tooltip" id={taskIdString} effect="solid" place={'top'}>
              <span>{task.title ? task.title : ''}</span>
            </ReactTooltip>
          </TaskContainer>
        )}
      </Draggable>
    );
  };

  // logic render habits
  renderHabits = () => {
    const { dayIndex, tasks, habits } = this.props;
    const { showAll, openingPopup, maximumRenderedItem } = this.state;
    const habitViews = [];
    const directionPopup = dayIndex === 6 ? 'right bottom' : 'left bottom';
    for (let idx = 0; idx < (habits || []).length && (showAll || idx < maximumRenderedItem - tasks.length); idx++) {
      if (_.isEmpty(habits[idx])) {
        continue;
      }
      habitViews.push(
        <HabitDraggable
          key={`habit-${habits[idx]._id}-${idx + tasks.length}-${dayIndex}`}
          habit={habits[idx]}
          directionPopup={directionPopup}
          day={dayIndex}
          openingPopup={openingPopup}
          onPopupToggle={this.onPopupToggle}
          isDragging={this.props.draggingWorkout}
          autoflow={AUTOFLOW_TYPES.INTERVAL}
        />,
      );
    }

    return habitViews;
  };

  render() {
    const { showAll, maximumRenderedItem, isConfirmRemove } = this.state;
    const { cellId, copying, weekIndex, dayIndex, tasks, habits } = this.props;
    const remainTasks = tasks.length + habits.length - maximumRenderedItem;

    if (!cellId) {
      return <CellLayout.CellWrapper />;
    }

    return (
      <>
        <Droppable droppableId={cellId} key={`dropzone_${cellId}`}>
          {provided => (
            <CellLayout.CellWrapper
              data-cell-id={cellId}
              ref={ref => {
                provided.innerRef(ref);
                this.wrapperRef.current = ref;
              }}
              copying={copying}
              showAll={showAll}
              empty={!tasks.length && !habits.length}
              className="intervalCell__wrapper"
            >
              <CellLayout.CellContainer className="intervalCell__container">
                <CellLayout.CellHeader className="intervalCell__header">
                  <div className="day">Day {Number(weekIndex) * 7 + Number(dayIndex) + 1}</div>
                  <CalendarAssignWorkoutIcon className="addItemButton" onClick={this.onAddNewTask} />
                  {showAll ? <CloseIcon onClick={this.hideViewAllMode} /> : null}
                </CellLayout.CellHeader>
                <CellLayout.CellContent className="intervalCell__content">
                  <CellLayout.CellContentContainer className="intervalCell__contentContainer">
                    {_.range(showAll ? tasks.length : Math.min(tasks.length, maximumRenderedItem)).map(this.renderTask)}
                    {this.renderHabits()}
                    {showAll ? null : (
                      <CellLayout.CellFooter>
                        {remainTasks > 0 ? (
                          <CellLayout.ViewMoreItem onClick={this.viewAllTasks}>
                            {pluralize(`+ ${remainTasks} more task`, remainTasks)}
                          </CellLayout.ViewMoreItem>
                        ) : null}
                        <CellLayout.PasteButton onClick={this.handlePaste}>Paste</CellLayout.PasteButton>
                      </CellLayout.CellFooter>
                    )}
                  </CellLayout.CellContentContainer>
                </CellLayout.CellContent>
              </CellLayout.CellContainer>
              {provided.placeholder}
            </CellLayout.CellWrapper>
          )}
        </Droppable>
        {isConfirmRemove && (
          <RepeatTaskConfirm
            title="Remove a Repeating Task"
            isRemove={true}
            isTask={true}
            type={CONFIRM_TYPES.TASK}
            message="You are removing a repeating task. Do you want to remove this and all upcoming occurences of this task, or only the selected task?"
            onClose={this.toggleRemoveConfirmModal}
            onConfirm={this.handleConfirmRemoveTaskHasRepeat}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { autoflow } = state.rootReducer;
  const {
    settings: { metrics },
  } = autoflow;
  const { tasks, copying, habits, isMoving } = state.rootReducer.autoflowInterval.task;
  const { dayIndex, weekIndex } = ownProps;
  const dayData = get(tasks, `[${weekIndex}][${dayIndex}]`, []);
  const dayHabitData = get(habits, `[${weekIndex}][${dayIndex}]`, []);

  return {
    bodymetricTypes: metrics,
    tasks: dayData,
    habits: dayHabitData,
    workingAutoflow: autoflow.common.workingAutoflow,
    copying,
    isFormPermission: get(state, 'rootReducer.permission.form_questionnaire', false),
    isHabitPermission: get(state, 'rootReducer.permission.habit', false),
    repeatTaskPermission: get(state, 'rootReducer.permission.repeat_task', false),
    repeatTaskAutoFlowPermission: get(state, 'rootReducer.permission.repeat_task_autoflow', false),
    isMoving,
  };
};

export default connect(mapStateToProps, {
  toggleModal,
  copyTask,
  pasteTask,
  deleteTask,
  toggleConfirmModal,
  saveToLibrary,
  getDetail,
})(CalendarCell);
