import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import ContentLoader from 'react-content-loader';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { convertS3UrlToCloudFrontUrl, convertHEICUrlToJPEGUrl, removeAllParamsFromURL } from 'utils/commonFunction';

class S3ImageWithFallback extends React.PureComponent {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = { images: [] };
  }

  componentDidMount() {
    this.__isMounted = true;
    this.getPresignedURLs(this.props.src);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.src, this.props.src)) {
      this.getPresignedURLs(this.props.src);
    }
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  getPresignedURLs = async list => {
    try {
      const { cloudfrontList, getS3presignedURLFromLocalDatabase } = this.props;
      const response = [];
      const images = list.map(it => removeAllParamsFromURL(it));

      for await (const item of images) {
        const itemCloudUrl = convertS3UrlToCloudFrontUrl(item, cloudfrontList);
        if (itemCloudUrl) {
          response.push(itemCloudUrl);
        } else {
          const [s3PresignedUrl] = await getS3presignedURLFromLocalDatabase([item]);
          response.push(s3PresignedUrl || item);
        }
      }

      // HEIC
      const covertResults = await convertHEICUrlToJPEGUrl(response);

      if (this.__isMounted) {
        this.setState({ images: covertResults });
      }
    } catch {
      // good luck
    }
  };

  onLoadError = event => {
    const { images } = this.state;
    const { defaultImage } = this.props;
    event.preventDefault();

    event.target.onerror = null;
    if (this.currentIndex === images.length) {
      return false;
    }

    if (this.currentIndex < images.length - 1) {
      this.currentIndex += 1;
      event.target.src = images[this.currentIndex];
    } else {
      this.currentIndex += 1;
      event.target.src = defaultImage || MEDIA_PLACEHOLDER;
    }
  };

  render() {
    const { images } = this.state;
    const { src, className, showLoader, onLoad, draggable = 'auto', thumbnail, ...otherProps } = this.props;

    return (
      <Container {...otherProps} className={classnames('image-with-fallback', className)}>
        {images.length ? (
          <img
            src={images[0] || images[1]}
            onError={this.onLoadError}
            className={thumbnail ? 'thumbnail' : ''}
            alt=""
            onLoad={onLoad}
            draggable={draggable}
          />
        ) : showLoader ? (
          <ContentLoader height="100%" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)">
            <rect x="0" y="0" width="100%" height="100%" />
          </ContentLoader>
        ) : null}
      </Container>
    );
  }
}

S3ImageWithFallback.propTypes = {
  src: PropTypes.array.isRequired,
  cloudfrontList: PropTypes.array.isRequired,
  defaultImage: PropTypes.string,
  showLoader: PropTypes.bool,
  onLoad: PropTypes.func,
};

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps, { getS3presignedURLFromLocalDatabase })(S3ImageWithFallback);

const Container = styled.div`
  text-align: center;

  &.media-placeholder {
    background: #eff0f8;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .thumbnail {
    width: 283px;
    height: 159px;
  }

  ${props =>
    props.fluid &&
    css`
      width: 100%;
      height: 100%;
    `}

  ${props =>
    props.cover &&
    css`
      img {
        object-fit: cover;
      }
    `}

  ${props =>
    props.noSizeLimit &&
    css`
      min-width: unset;
      min-height: unset;
      max-width: unset;
      max-height: unset;
    `}

  ${props =>
    props.comment &&
    css`
      img {
        max-width: 100%;
        max-height: 200px;
        display: block;
        min-width: 60px;
        min-height: 60px;
      }
    `}
`;
