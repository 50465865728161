import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import moment from 'moment';
import React from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { ReactComponent as MarketplaceIcon } from 'assets/icons/marketplace-icon.svg';

import { Button } from 'shared/FormControl';
import { formatMoney, pluralize } from 'utils/commonFunction';
import { CDN_URL, CURRENCIES, PACKAGE_PRICING_TYPES, TEXT_CURRENCIES } from 'constants/commonData';
import * as S from './styles';

const headers = ['Package', '', 'Purchase Date', 'Type', 'Status'];

const trimPackageName = str => {
  return str.length > 40 ? `${str.slice(0, 37)}...` : str;
};

const getPurchaseType = price => {
  if (price.type === PACKAGE_PRICING_TYPES.one_time.value) {
    return 'One time';
  } else {
    return `Every ${pluralize(get(price, 'recurring_interval_type'), get(price, 'every'), true)} ${
      price.end_after ? `for ${pluralize('invoice', get(price, 'end_after'), true)}` : ''
    }`;
  }
};

const handleOpenDetail = item => () => {
  const packageId = get(item, '_package.hash_id') || get(item, '_package.id');
  const { id: purchaseId = '', platform = '' } = item || {};
  const path = platform === 'marketplace' ? 'marketplace' : 'packages';

  if (packageId && purchaseId) {
    window.location = `/home/${path}/${packageId}/analytics?preview=${purchaseId}`;
  }
};

const PackagesPopup = ({ onClose, purchases, currency, client }) => {
  return (
    <S.PopupWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_circle" />
        </CloseButton>
      }
    >
      <Modal.Header>{get(client, 'first_name')}'s Purchases</Modal.Header>
      <Modal.Content>
        <S.TableWrapper>
          <S.Table>
            <S.TableHeader>
              <S.TableRow>
                {headers.map(item => (
                  <S.TableCellHeader key={`header-${item}`}>{item}</S.TableCellHeader>
                ))}
              </S.TableRow>
            </S.TableHeader>
            <S.TableBody>
              {purchases.map(it => {
                const currency = get(it, 'currency', 'usd');
                const symbol = TEXT_CURRENCIES.includes(currency)
                  ? `${get(CURRENCIES, `${currency}.symbol`)} `
                  : get(CURRENCIES, `${currency}.symbol`);

                return (
                  <S.TableRow onClick={handleOpenDetail(it)} key={`purchase-id-${it.id}`}>
                    <S.TableCell>
                      <S.TableCellPackageName className="padding">
                        <Avatar round="5px" size="40" src={get(it, '_package.cover_image')} className="cover-image" />
                        <S.PackageNameDetail>{trimPackageName(get(it, 'package_name'))}</S.PackageNameDetail>
                      </S.TableCellPackageName>
                    </S.TableCell>
                    <S.TableCell>
                      {get(it, 'platform') === 'marketplace' ? (
                        <>
                          <S.TableCellPlatform data-tip="Marketplace Platform" data-for="marketplace-icon-tooltip">
                            <MarketplaceIcon />
                          </S.TableCellPlatform>
                          <ReactTooltip
                            className="marketplace-icon"
                            id="marketplace-icon-tooltip"
                            effect="solid"
                            place="top"
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </S.TableCell>
                    <S.TableCell>
                      <S.TableCellPurchase className="padding">
                        {moment(get(it, 'created_at')).format('MMM DD, YYYY')}
                      </S.TableCellPurchase>
                    </S.TableCell>
                    <S.TableCell>
                      <S.TableCellType className="padding">
                        <S.PackageTypeDetail>
                          <S.Price>{formatMoney(get(it, 'price.amount'), symbol)}</S.Price>
                          <span>{getPurchaseType(get(it, 'price'))}</span>
                        </S.PackageTypeDetail>
                      </S.TableCellType>
                    </S.TableCell>
                    <S.TableCell>
                      <S.TableCellStatus className="padding">
                        <S.Status status={lowerCase(it.status)}>{it.status}</S.Status>
                      </S.TableCellStatus>
                    </S.TableCell>
                  </S.TableRow>
                );
              })}
            </S.TableBody>
          </S.Table>
        </S.TableWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button purple onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </S.PopupWrapper>
  );
};

export default PackagesPopup;
