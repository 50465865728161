import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { pluralize } from 'utils/commonFunction';
import { ReactComponent as InfoConfirmIcon } from 'assets/icons/info_icon_confirm.svg';

function AddClientConfirm(props) {
  const { results } = props;

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <InfoConfirmIcon />
            <span>Clients list changed</span>
          </S.Header>
          <S.Body>
            {/* <div>
              Lorem ipsum dolor sit amet, elit nunc suspendisse pellentesque metus, aliquam lacus. Fusce urna nunc vitae
              metus
            </div> */}
            <S.Note className="note">
              <ul>
                <li>
                  <b>{pluralize('client', results.addingClients || 0, true)}</b> will be added to the list
                </li>
                <li>
                  <b>{pluralize('client', results.removingClients || 0, true)}</b> will be removed from the list
                </li>
              </ul>
            </S.Note>
            <div>Would you like to continue?</div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              purple
              onClick={() => {
                props.onConfirm();
                props.toggleModal(false);
              }}
            >
              Confirm
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  return {};
};

const mapDispatch = (dispatch, props) => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapState, mapDispatch)(AddClientConfirm);
