import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const Heading = styled.div`
  display: flex;
  padding: 20px;
  background: url(${CDN_URL}/images/workout_builder_overview_title_icon.svg);
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: 23px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  background-color: #f6f7fb;
`;

export const TrackedIcon = styled.div`
  background-image: url(${CDN_URL}/images/tracked_calendar.svg);
  background-position: 10px;
  background-repeat: no-repeat;
  background-color: #f6f7f9;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 9px 5px 33px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #a6acc6;

  ${props =>
    props.isTracked &&
    css`
      background-color: #5158cf10;
      background-image: url(${CDN_URL}/images/tracked_calendar_hover.svg);
      color: #5158cf;
    `};

  :hover {
    background-color: #5158cf10;
    background-image: url(${CDN_URL}/images/tracked_calendar_hover.svg);
    color: #5158cf;
  }
`;

export const DayHeading = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #959daf;
  background-color: #ffffff;
  padding: 20px 20px;
  position: relative;

  span {
    text-transform: uppercase;
  }

  ${TrackedIcon} {
    position: absolute;
    right: 20px;
    top: 15px;
  }
`;
