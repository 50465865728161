// Libs
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

// Actions
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { updateMetricData } from 'actions/bodyMetric';
import { editMetricGroup, addMultipleMetricToGroup } from 'actions/groupMetric';

// Assets
import { ReactComponent as PlusGreyIcon } from 'assets/icons/plus_grey.svg';

// Components
import BodyMetricUpdateAll from 'components/BodyMetricUpdateAll';
import DropdownOption from 'components/BodyMetricChartNew/components/DropdownOption';
import { TIME_OPTIONS_METRIC_OVERVIEW } from 'components/BodyMetricChartNew/constants';
import AddMetricsModal from 'components/GroupBodyMetrics/components/AddMetricsModal';
import LoadingIndicator from 'shared/LoadingIndicator';
import GroupMetricOverviewItem from './GroupMetricOverviewItem';

// Utils
import { isTeamAdmin } from 'utils/commonFunction';

// Styles
import './style.scss';
import * as S from './style';

const LIMIT_LENGTH = 30;

function GroupMetricOverview({
  client,
  push,
  toggleModal,
  preferences,
  units,
  selectedGroupMetric,
  updateMetricData,
  metrics,
  addMultipleMetricToGroup,
  editMetricGroup,
  toggleConfirmModal,
  loading,
  isCreateNew,
  paymentOverdue,
  addOnsOverdue,
  zapierOverdue,
  paymentWhiteLabelOverdue,
  user,
}) {
  const { is_verified_email, force_send_verification_email_at } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  const titleRef = useRef();
  const [selectedRange, setSelectedRange] = useState(TIME_OPTIONS_METRIC_OVERVIEW.last_4_week.value);
  const [isFocused, setFocused] = useState(false);
  const [groupTitle, setGroupTitle] = useState('');
  const { _id: groupId, metrics: metricIds = [], title: groupName = '', is_default = false } =
    selectedGroupMetric || {};

  const timeOptions = useMemo(() => Object.values(TIME_OPTIONS_METRIC_OVERVIEW), []);

  const isMetricEnable = useMemo(() => {
    return get(
      preferences.find(item => item.type === 'body_metric'),
      'state',
      false,
    );
  }, [preferences]);

  useEffect(() => {
    if (titleRef.current && isCreateNew) {
      titleRef.current.focus();
      setGroupTitle('');
    }
  }, [groupId, isCreateNew]);

  useEffect(() => {
    setGroupTitle(groupName);
  }, [groupName]);

  useEffect(() => {
    const container = document.querySelector('.group-metrics-overview-charts');
    if (!container) return;
    const handleScrollEnd = () => {
      const items = document.querySelectorAll('.my-custom-dot');
      for (const item of items) {
        item.style.display = 'unset';
      }
    };
    container.addEventListener('scrollend', handleScrollEnd);
    return () => {
      container.removeEventListener('scrollend', handleScrollEnd);
    };
  }, []);

  if (!isMetricEnable || !selectedGroupMetric) {
    return null;
  }

  const updateResultsSuccessCallback = () => {
    // trick to refetch data for chart
    const currentRange = selectedRange;
    setSelectedRange(() => '');
    setTimeout(() => {
      setSelectedRange(() => currentRange);
    }, 10);
  };

  const handleUpdateAll = () => {
    const metricsOfGroup = metricIds.map(id => metrics[id]);
    toggleModal(
      true,
      <BodyMetricUpdateAll metricsOfGroup={metricsOfGroup} updateSuccessCallback={updateResultsSuccessCallback} />,
    );
  };

  const getUnitObject = item => {
    if (item.unit) {
      return item.unit;
    } else if (item.category && item.category.default_unit) {
      return item.category.default_unit;
    }
    return {};
  };

  const handleClickOnChart = mId => () => {
    const metricItem = metrics[mId];
    const unitObject = getUnitObject(metricItem);
    const params = {
      unique_code: metricItem.unique_code || '',
      mid: metricItem._id || '',
      name: metricItem.name,
      unit: unitObject.title,
      unitId: unitObject._id,
    };
    updateMetricData(params);
    push(
      `/home/client/${client}/metrics?unique_code=${encodeURIComponent(
        metricItem.unique_code,
      )}&time_range=${selectedRange}`,
    );
  };

  const [firstMetricId, remainingMetricIds] = useMemo(() => {
    const listLength = (metricIds || []).length;

    if (listLength < 4 && listLength % 2 === 1) {
      const [first, ...rest] = metricIds;
      return [first, rest];
    }
    return [null, metricIds || []];
  }, [metricIds]);

  const changeGroupName = event => {
    if (is_default) return;
    if (event && event.target) {
      const value = event.target.value;

      if (value.length <= LIMIT_LENGTH) {
        setGroupTitle(value);
      }
    }
  };

  const onBlur = () => {
    setFocused(false);
    if ((groupTitle && groupTitle === groupName) || is_default) return;
    !groupTitle && setGroupTitle(groupName || 'New Group');
    editMetricGroup({
      groupId: selectedGroupMetric._id,
      title: groupTitle,
    });
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      setFocused(false);
      if (groupTitle === groupName || is_default) return;
      !groupTitle && setGroupTitle(groupName);
      editMetricGroup({
        groupId: selectedGroupMetric._id,
        title: groupTitle,
      });
    }
  };

  const onFocus = () => {
    if (is_default) return;
    setFocused(true);
  };

  const renderChart = (metricId, isDoubleChart) => {
    return (
      <GroupMetricOverviewItem
        key={metricId}
        item={metrics[metricId]}
        units={units}
        isDraggable={false}
        isDoubleChart={isDoubleChart}
        onClick={handleClickOnChart(metricId)}
        timeRange={selectedRange}
      />
    );
  };

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handleAddNewMetric = () => {
    toggleModal(
      true,
      <AddMetricsModal
        onClose={handleCloseModal}
        group={selectedGroupMetric}
        addMetricsToGroup={addMultipleMetricToGroup}
        metricsNormalized={metrics}
        clientId={client || ''}
        toggleConfirmModal={toggleConfirmModal}
      />,
    );
  };

  const renderAddNewMetricsBox = () => {
    return (
      <S.AddNewMetricBox onClick={handleAddNewMetric}>
        <PlusGreyIcon className="icon-plus" />
        <S.AddMetricLabel>Add Metrics</S.AddMetricLabel>
      </S.AddNewMetricBox>
    );
  };

  return (
    <div className="group-metrics-overview">
      <div className="group-metrics-overview__header">
        <div className="group-metrics-overview__header-title">
          {loading ? (
            <LoadingIndicator title="Loading chart..." className="group-body-metric-loading-chart" />
          ) : (
            <S.Wrapper id="group-metrics-overview-header-title" hasError={groupName.length > LIMIT_LENGTH}>
              <S.HeaderInput
                ref={titleRef}
                onClick={onFocus}
                onChange={changeGroupName}
                onBlur={onBlur}
                onKeyDown={onKeyPress}
                value={groupTitle}
                placeholder="Name your metric group"
                disabled={is_default}
                maxLength={LIMIT_LENGTH}
              />
              {isFocused && groupTitle.length >= LIMIT_LENGTH - 5 && (
                <div className={classNames('nameInput__warning', { 'full-limit': groupTitle.length === LIMIT_LENGTH })}>
                  <span className="nameInput__warning--text">
                    {groupTitle.length}/{LIMIT_LENGTH}
                  </span>
                </div>
              )}
            </S.Wrapper>
          )}
        </div>
        {!isEmpty(metricIds) && !loading && (
          <div className="group-metrics-overview__header-actions">
            <DropdownOption
              selected={selectedRange}
              onSelectFilter={setSelectedRange}
              options={timeOptions}
              className="time-filter"
            />
            <button className="update-results-btn" onClick={handleUpdateAll}>
              Update Results
            </button>
          </div>
        )}
      </div>
      {!loading && (
        <div
          className={classNames('group-metrics-overview-charts', {
            'reduce-height': isWhiteLabelOverdue || isShowOverdue || isVerifyEmail,
          })}
        >
          {isEmpty(metricIds) && !is_default ? (
            renderAddNewMetricsBox()
          ) : (
            <>
              {!!firstMetricId && renderChart(firstMetricId, false)}
              {metricIds.length === 2 ? (
                <>
                  {renderChart(remainingMetricIds[0], false)}
                  <div style={{ height: 16 }} />
                  {renderChart(remainingMetricIds[1], false)}
                </>
              ) : (
                <div className={classNames('list-chart', { 'has-first': !!firstMetricId })}>
                  {remainingMetricIds.map(id => renderChart(id, true))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  const {
    user,
    groupMetric,
    rootReducer: {
      client: { workingClientDetail },
      pricing,
    },
  } = state;

  return {
    user,
    metrics: groupMetric.metrics,
    loading: groupMetric.loading,
    isCreateNew: groupMetric.isCreateNew,
    selectedGroupMetric: groupMetric.selected,
    client: get(workingClientDetail, '_id'),
    preferences: get(workingClientDetail, 'feature_preferences'),
    units: get(workingClientDetail || user, 'preferences', []),
    paymentOverdue: pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = {
  push,
  toggleModal,
  updateMetricData,
  addMultipleMetricToGroup,
  editMetricGroup,
  toggleConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupMetricOverview);
