import React from 'react';
import { connect } from 'react-redux';
import { saveCalendarAsProgram } from 'redux/program_library/program_library.actionCreators';
import SaveExactDateCalendarAsProgram from 'shared/SaveExactDateCalendarAsProgram';
import styled from 'styled-components';
import { SAVE_AS_PROGRAM_CALENDAR_TYPE } from 'constants/commonData';
import { fetchAutoflowWorkoutsInRange } from 'redux/autoflow/training/actions';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const mapState = state => {
  const {
    user,
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
    },
  } = state;

  return { user, autoflow: workingAutoflow };
};

export default connect(mapState, { saveCalendarAsProgram, fetchAutoflowWorkoutsInRange })(
  ({ saveCalendarAsProgram, autoflow, user, fetchAutoflowWorkoutsInRange }) => {
    const onSave = data => {
      const { startDate, endDate, title, share } = data;

      const bodyData = {
        calendar: SAVE_AS_PROGRAM_CALENDAR_TYPE.AUTOFLOW,
        item_id: autoflow._id,
        start_day: startDate.format('MM-DD-YYYY'),
        end_day: endDate.format('MM-DD-YYYY'),
        author: user._id,
        title,
        share,
      };

      return saveCalendarAsProgram(bodyData);
    };

    return (
      <Container>
        <SaveExactDateCalendarAsProgram
          defaultTitle={`${autoflow.name} Copy`}
          onSave={onSave}
          fetchWorkoutsInRange={fetchAutoflowWorkoutsInRange}
        />
      </Container>
    );
  },
);
