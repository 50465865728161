import React, { createRef } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import classNames from 'classnames';

import { CustomConfirmModal } from 'components/WorkoutDetailModal/style';
import { toggleConfirmModal } from 'actions/modal';
import { sendFeedback, sendFeedbackForExample } from 'components/WorkoutDetailModalAIDemo/actions';
import { CDN_URL, KEY_CODE } from 'constants/commonData';
import { Mixpanel } from 'utils/mixplanel';

import Rating from './Rating';
import { TextEditable } from '../style';
import './style.scss';

const TabTexts = [
  'Different exercise chosen',
  'Section type is not correct',
  'Tracking fields are not correct',
  'Exercise was not created',
  'Taking too long',
  'Other',
];

export default class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSending: false,
      feedbackTag: [],
      feedbackText: '',
      typedFB: false,
      number_of_rating: null,
    };
    this.inputRef = createRef();
  }

  componentWillReceiveProps(props) {
    if (props.open) {
      setTimeout(() => {
        this.inputRef.current && this.inputRef.current.focus();
      }, 200);
    } else {
      this.setState({
        isSending: false,
        feedbackTag: [],
        feedbackText: '',
        typedFB: false,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onEscPressed, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onEscPressed, true);
  }

  onEscPressed = event => {
    const { open } = this.props;
    if (event.keyCode === KEY_CODE.esc && open) {
      this.handleClose();
      event.stopPropagation();
    }
  };

  onSubmitFeedback = async () => {
    const { dispatch, respondId, reaction = '', onClose, conversation = {}, sessionId, workoutAIPage } = this.props;
    const { feedbackText, feedbackTag, number_of_rating } = this.state;
    const text = feedbackText.trim() || this.inputRef.current.innerText;
    if (text.length === 0 && feedbackTag.length === 0) return;

    Mixpanel.track('submit_feedback_ai_workout_builder', {
      component: workoutAIPage,
      // tags: feedbackTag,
      // feedback: text,
      rating: number_of_rating,
    });

    try {
      this.setState({ isSending: true });
      const bodyRequest = { reaction, detail: text, rating: feedbackTag, number_of_rating };
      if (respondId) {
        await dispatch(sendFeedback(respondId, bodyRequest));
      } else {
        const { user_input, json_output } = conversation || {};
        await dispatch(
          sendFeedbackForExample({
            ...bodyRequest,
            user_input: user_input || '',
            json_output: JSON.stringify(json_output || {}),
            session_id: sessionId,
          }),
        );
      }
      onClose({ reaction, detail: text, rating: feedbackTag });
      this.setState({ number_of_rating: null });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isSending: false });
    }
  };

  onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode > 36 && keyCode < 41) {
      // press arrow key
      event.stopPropagation();
      return;
    }
  };

  onKeyUp = event => {
    this.setState({ typedFB: (event.target.innerText || '').trim().length > 0 });
  };

  onCloseCurrentPopup = () => {
    const { reaction, onClose } = this.props;
    this.setState({ number_of_rating: null });
    onClose({ reaction });
  };

  handleDenyClosePopup = () => {
    const { dispatch } = this.props;
    dispatch(toggleConfirmModal(false));
  };

  handleClose = () => {
    const { dispatch, onClose, reaction } = this.props;
    const { feedbackTag, number_of_rating } = this.state;
    if (this.inputRef.current.innerText || feedbackTag.length > 0 || number_of_rating !== null) {
      dispatch(
        toggleConfirmModal(
          true,
          <CustomConfirmModal
            onConfirm={this.onCloseCurrentPopup}
            onDeny={this.handleDenyClosePopup}
            noBorder
            title="Discard Changes?"
            content={`Are you sure you want to go? Changes have not been saved yet.`}
            confirmButtonTitle="Discard changes"
            hasCloseIcon
            headerIcon={`${CDN_URL}/images/discard_changes_icon.svg`}
          />,
        ),
      );
    } else {
      this.setState({ number_of_rating: null });
      onClose({ reaction });
    }
  };

  handleChangeRating = numb => {
    this.setState(s => ({ ...s, number_of_rating: s.number_of_rating === numb ? numb - 1 || null : numb }));
  };

  render() {
    const { open } = this.props;
    const { feedbackTag, isSending, feedbackText, typedFB, number_of_rating } = this.state;
    const isDisabled = isSending || (feedbackTag.length === 0 && !typedFB && !number_of_rating);

    return (
      <Modal
        id="ai-feedback-modal"
        open={open}
        className="confirm-popup-container"
        closeOnDimmerClick={false}
        onClose={this.handleClose}
      >
        <Modal.Content className="confirm-content">
          <Button onClick={this.handleClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="confirm-content-header">
            <p className="confirm-header-label">Provide feedback</p>
          </div>
          <div className="confirm-content-body">
            <div className="confirm-content-label">
              <div className="feedback-tags">
                {TabTexts.map(item => {
                  return (
                    <div
                      key={item}
                      className={classNames('tag-item', { active: feedbackTag.includes(item) })}
                      onClick={() => {
                        this.setState({
                          feedbackTag: feedbackTag.includes(item)
                            ? feedbackTag.filter(i => i !== item)
                            : [...feedbackTag, item],
                        });
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
              <TextEditable
                dangerouslySetInnerHTML={{ __html: feedbackText }}
                contentEditable
                ref={this.inputRef}
                onKeyUp={this.onKeyUp}
                onKeyDown={this.onKeyDown}
                placeholder="What was the issue with the response?"
                // onBlur={evt => this.setState({ feedbackText: evt.target.innerText })}
                className="feedback-input"
              />
              <div className="rating-content-wrapper">
                <p>How accurate was the workout created?</p>
                <Rating rating={number_of_rating} onChange={this.handleChangeRating} />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions className="confirm-actions">
          <Button className="confirm-no-button" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button className="confirm-yes-button" onClick={this.onSubmitFeedback} disabled={isDisabled}>
            Submit Feedback
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
