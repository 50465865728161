import { toast } from 'react-toastify';
import _ from 'lodash';
import Request from 'configs/request';
import { push } from 'connected-react-router';
import { getClientsFullData, getNewestClients } from 'redux/client/client.actionCreators';
import { CLIENT_CATEGORY, ONBOARDING_STEPS } from 'constants/commonData';

export const Types = {
  SUCCESS_GET_ONBOARDING_CHECKLIST: 'SUCCESS_GET_ONBOARDING_CHECKIST',
  FAILED_GET_ONBOARDING_CHECKLIST: 'FAILED_GET_ONBOARDING_CHECKIST',
  MAXIMIZE_ONBOARDING_CHECK_LIST: 'MAXIMIZE_ONBOARDING_CHECK_LIST',
  MINIMIZE_ONBOARDING_CHECK_LIST: 'MINIMIZE_ONBOARDING_CHECK_LIST',
  HIDE_ONBOARDING_FEATURE: 'HIDE_ONBOARDING_FEATURE',
  SUCCESS_ADD_ONBOARDING_CLIENT: 'SUCCESS_ADD_ONBOARDING_CLIENT',
  FALIED_ADD_ONBOARDING_CLIENT: 'FALIED_ADD_ONBOARDING_CLIENT',
  RUNNING_ONBOARDING_STEP: 'RUNNING_ONBOARDING_STEP',
  HIDE_ONBOARDING_TOOLTIP: 'HIDE_ONBOARDING_TOOLTIP',
  SHOW_ONBOARDING_TOOLTIP: 'SHOW_ONBOARDING_TOOLTIP',
  SHOW_ONBOARDING_USER_GUIDE: 'SHOW_ONBOARDING_USER_GUIDE',
  HIDE_ONBOARDING_USER_GUIDE: 'HIDE_ONBOARDING_USER_GUIDE',
  MARK_WORKOUT_BUILDER_GUIDE: 'MARK_WORKOUT_BUILDER_GUIDE',
  SUCCESS_GET_ONBOARDING_WORKOUT_BUILDER_GUIDE: 'SUCCESS_GET_ONBOARDING_WORKOUT_BUILDER_GUIDE',
};

export const maximizeCheckList = () => ({ type: Types.MAXIMIZE_ONBOARDING_CHECK_LIST });

export const minimizeCheckList = () => ({ type: Types.MINIMIZE_ONBOARDING_CHECK_LIST });

export const hideFeature = () => ({ type: Types.HIDE_ONBOARDING_FEATURE });

export const runOnboarding = step => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const runningStep = _.get(rootReducer, `onboarding.running`);
    if (runningStep) {
      dispatch(markWorkoutBuilderGuide(runningStep));
    }
    dispatch({ type: Types.RUNNING_ONBOARDING_STEP, payload: { data: step } });
  };
};

export const hideTooltip = () => ({ type: Types.HIDE_ONBOARDING_TOOLTIP });

export const showTooltip = () => ({ type: Types.SHOW_ONBOARDING_TOOLTIP });

export const showOnboardingUserGuide = () => ({ type: Types.SHOW_ONBOARDING_USER_GUIDE });

export const hideOnboardingUserGuide = () => ({ type: Types.HIDE_ONBOARDING_USER_GUIDE });

export const getOnboardingCheckList = openChecklist => {
  return Request.get(
    { url: '/api/profile/onboarding_preferences?platform=web' },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (data) {
        dispatch({ type: Types.SUCCESS_GET_ONBOARDING_CHECKLIST, payload: { data } });
      }

      if (openChecklist) {
        dispatch(maximizeCheckList());
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.FAILED_GET_ONBOARDING_CHECKLIST });
    },
  );
};

export const addYourselfAsClient = () => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        onboarding: { checkList },
      },
    } = getState();
    const { first_name, last_name, email } = user;
    const client_info = { first_name, last_name, email, client_type: CLIENT_CATEGORY.ONLINE };

    dispatch(push('/home/client'));

    return dispatch(
      Request.post(
        { url: '/api/trainer/invite', data: { client_info, send_mail: true } },
        true,
        () => {
          toast(`An invitation has been sent to ${email}`, { className: 'wider' });
          dispatch({ type: Types.SUCCESS_ADD_ONBOARDING_CLIENT });
          dispatch(getClientsFullData(true));
          // dispatch(getNewestClients());

          const unfinished = _.find(checkList, item => item.type === ONBOARDING_STEPS.ADD_CLIENT && !item.state);

          if (unfinished) {
            dispatch(getOnboardingCheckList());
          }
        },
        () => {
          dispatch({ type: Types.FALIED_ADD_ONBOARDING_CLIENT });
        },
      ),
    );
  };
};

export const dismiss = () => {
  return Request.put({ url: '/api/profile/onboarding_preferences/display', data: { visibility: false } }, true);
};

export const updateStepOnboarding = (type) => {
  return Request.put({ url: '/api/profile/onboarding_preferences/update', data: { type } }, false,  (response, { dispatch }) => {
    dispatch(getOnboardingCheckList());
  },);
};

export const getOnboardingWorkoutBuiler = openChecklist => {
  return Request.get(
    { url: '/api/onboarding/get-workout-builder-guide' },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (data) {
        dispatch({ type: Types.SUCCESS_GET_ONBOARDING_WORKOUT_BUILDER_GUIDE, payload: { data } });
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.FAILED_GET_ONBOARDING_CHECKLIST });
    },
  );
};

export const markWorkoutBuilderGuide = type => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const stepState = _.get(rootReducer, `onboarding.workoutBuilderGuideSteps.${type}`);
    if (!stepState) {
      dispatch({ type: Types.MARK_WORKOUT_BUILDER_GUIDE, payload: { data: { type } } });
      return dispatch(
        Request.post({ url: '/api/onboarding/update-workout-builder-guide', data: { step: type } }, false),
      );
    }
  };
};
