import Select from 'react-select';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #6a778a;
`;

export const BlockSelect = styled.div`
  width: 100%;
  height: 100%;
`;

export const Label = styled.p`
  ${baseText}
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const SelectList = styled(Select)`
  height: 36px;
  .select-section-lib-prefix__value-container {
    padding-left: 15px;
  }
  .select-section-lib-prefix__indicators {
    padding-right: 13.5px;
  }
  .elect-section-lib-prefix__value-container {
    padding-left: 15px;
  }
  .select-section-lib-prefix__control {
    cursor: pointer;
    box-shadow: none;
    min-height: 100%;
    background: #fafbfc;
    border: 1px solid #dadfea;
    &:hover {
      border: 1px solid #5158cf !important;
    }
  }
  .select-section-lib-prefix__control--is-focused {
    border: 1px solid #5158cf !important;
  }
  .select-section-lib-prefix__menu {
    margin-top: 4px;
    background: #ffffff;
    border: 1px solid #dadfea;
    box-shadow: none;
    z-index: 5;
  }
`;

export const SingleValue = styled.div`
  ${baseText}
  color: #323c47;
`;

export const ItemSection = styled.div`
  ${baseText}
  font-weight: 400;
  color: #202353;
  padding: 8px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.is-selected {
    font-weight: 600;
    color: #5158cf;
  }
  &:hover {
    background: #f6f5ff;
    font-weight: 600;
    color: #5158cf;
  }
  svg:hover path {
    fill: #716ae7;
  }
  .app-tooltip {
    max-width: 312px !important;
    text-align: left !important;
    padding: 15px !important;
    margin-top: -5px !important;
    &::after {
      bottom: -6px !important;
    }
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }
  }
`;

export const MenuListContainer = styled.div``;

export const MenuListContent = styled.div`
  padding: 8px 0;
`;

export const MenuListFooter = styled.div`
  padding: 8px 0;
  border-top: 1px solid #e5e4e5;
  span {
    ${baseText}
    font-weight: 400;
    color: #202353;
    padding: 8px 14px;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 34px;
    &:hover {
      color: #5158cf;
      font-weight: 600;
    }
  }
`;
