import React from 'react';
import ContentLoader from 'react-content-loader';

import { SectionItemWrapper } from './style';

export default function (props) {
  return (
    <SectionItemWrapper>
      <ContentLoader height="94" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)">
        <rect x="0" y="0" rx="2" ry="2" width="70%" height="10" />
        <circle cx="95%" cy="10" r="10" />
        <rect x="0" y="24" rx="2" ry="2" width="60" height="19" />
        <circle cx="75" cy="34" r="1.5" />
        <rect x="90" y="30" rx="2" ry="2" width="60" height="9" />
        <rect x="0" y="65" rx="2" ry="2" width="90%" height="8" />
        <rect x="0" y="80" rx="2" ry="2" width="50%" height="8" />
      </ContentLoader>
    </SectionItemWrapper>
  );
}
