import React, { Component } from 'react';
import { Button, Input, Loader, Image } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import './styles.scss';
import TaskCalendar from 'components/TaskCalendar';
import DateRangePicker from 'shared/DateRangePicker';
import { CALENDAR_TYPES, CDN_URL } from 'constants/commonData';
import SelectCalendarType from 'shared/SelectCalendarType';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import { Helmet } from 'react-helmet';

export default class TaskListCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirm: false,
      movingItem: null,
      isDragging: false,
    };
  }

  componentDidMount() {
    this.props.toggleSideBar(false);
    const { handleInitData } = this.props;
    handleInitData();
    document.addEventListener('keydown', this.onPressEscapeKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onPressEscapeKey);
  }

  onPressEscapeKey = event => {
    if (event.keyCode === 27 && !document.querySelector('.ui.modals') && this.props.copyingTask) {
      this.props.resetCopyItem();
    }
  };

  handleConfirmMove = () => {
    const { selectedClient, onDragEnd, day_tasks } = this.props;
    const { movingItem } = this.state;
    onDragEnd(movingItem, selectedClient, day_tasks);
    this.toggleMoveRepeat();
  };

  onMoveItem = item => {
    this.setState({
      isDragging: false,
    });

    const { destination, source } = item;
    const { selectedClient, onDragEnd, repeatTaskPermission, day_tasks } = this.props;
    // Check task repeating or normal
    const isRepeating = item.draggableId.indexOf('isRepeating') >= 0 && repeatTaskPermission;
    if (isRepeating && destination.droppableId !== source.droppableId) {
      this.toggleMoveRepeat();
      this.setState(() => ({
        movingItem: item,
      }));
    } else {
      onDragEnd(item, selectedClient, day_tasks);
    }
  };

  toggleMoveRepeat = () => {
    this.setState(state => ({
      isConfirm: !state.isConfirm,
    }));
  };

  handleDragStart = () => {
    this.setState({
      isDragging: true,
    });
  };

  render() {
    const { isConfirm } = this.state;
    const { clientName } = this.props;
    // the searchbar and searchresult is for v2.0
    return (
      <>
        <Helmet>
          <title>{clientName} - Tasks - Everfit</title>
        </Helmet>
        <DragDropContext onDragEnd={this.onMoveItem} onDragStart={this.handleDragStart}>
          <div className="calendar-wrapper">
            <div className="calendar-container">
              {this.renderNavigationBar()}
              {this.renderCalendar()}
            </div>
          </div>
          {this.props.children}
        </DragDropContext>
        {isConfirm && (
          <RepeatTaskConfirm
            type={CONFIRM_TYPES.MOVE}
            title="Move a Repeating Task"
            message={`You are moving a task that is in a repeating sequence. \n\n This change will detach the task from the original sequence.`}
            onClose={this.toggleMoveRepeat}
            onConfirm={this.handleConfirmMove}
          />
        )}
      </>
    );
  }

  handleShowCalendarClick = () => {
    this.setState({ isShowCalendar: true });
  };

  renderCalendar() {
    const { selectedClient } = this.props;
    if (!selectedClient) {
      return <Loader />;
    }

    return (
      <div className="calendar-main-content task-calendar">
        <TaskCalendar isDragging={this.state.isDragging} />
      </div>
    );
  }

  renderToday() {
    const { handleTodayClick, selectedClient, taskCalendarType } = this.props;
    return (
      <Button className="today-button" onClick={() => handleTodayClick(selectedClient, taskCalendarType)}>
        TODAY
      </Button>
    );
  }

  renderNavigationBar() {
    const {
      isTaskFeaturing,
      taskCalendarStartDate,
      selectedClient,
      taskCalendarType,
      changeTaskCalendarStartDate,
    } = this.props;

    return (
      <div className="calendar-nav calendar--task">
        <div className="calendar-nav__left">
          {this.renderToday()}
          <DateRangePicker
            startDate={taskCalendarStartDate}
            weeks={taskCalendarType}
            onChange={(newStartDate, newEndDate) => {
              changeTaskCalendarStartDate(
                {
                  client: selectedClient,
                  start_date: newStartDate.toFormat('MM-dd-yyyy'),
                  end_date: newEndDate.toFormat('MM-dd-yyyy'),
                },
                newStartDate,
              );
            }}
          />
        </div>
        {!isTaskFeaturing && (
          <div className="feature_turned_off">
            <Image src={`${CDN_URL}/images/feature_turned_off.svg`} />
            <span>Tasks has been turned off for client</span>
          </div>
        )}
        {this.rendertaskCalendarTypes()}
      </div>
    );
  }

  rendertaskCalendarTypes() {
    const { handleTaskCalendarTypeChange, taskCalendarStartDate, selectedClient, taskCalendarType } = this.props;

    return (
      <SelectCalendarType
        selected={taskCalendarType}
        options={CALENDAR_TYPES}
        onSelect={value => handleTaskCalendarTypeChange(taskCalendarStartDate, selectedClient, value)}
      />
    );
  }

  renderSearchBar() {
    return <Input loading={false} icon={'search'} iconPosition={'left'} placeholder={'Search...'} />;
  }
}
