import styled from 'styled-components';

export const ActionsContainer = styled.div`
  width: 110px;
  padding: 15px 0;

  .actions__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 5px 0 5px 15px;

    img {
      width: 14px;
      margin-right: 12px !important;
    }

    :hover {
      background-color: #696969;
    }
  }
`;

export const TaskContainer = styled.div`
  padding: 8px;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 5px;

  .task__icon {
    img {
      width: 22px;
    }
    flex: 0 0 22px;
  }

  .task__title {
    flex: 1 1;
  }

  :last-child {
    margin-bottom: 0;
  }

  .task__title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    overflow: hidden;
    padding-left: 8px;

    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 150%;
    }

    .description {
      font-size: 10px;
      font-weight: 400;
      color: #a0a8b1;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  i.icon.more-actions-icon {
    margin: 0 0 0 auto !important;
  }
`;
