import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  background: #fff;
  font-weight: 400;
  font-style: normal;
  color: rgba(0,0,0,.87);
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  .close-button {
    position: absolute;
    right: -11px;
    top: -11px;
  }

  .fixed-popup__body {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  ::before {
    content: '';
    box-shadow: -1px -1px 0 0 rgba(34,36,38,.12);
    background-color: #fff;
    position: fixed;
    width: 10px;
    height: 10px;
    z-index: -1;
  }

  &.right {
    ::before {
      left: ${props => `${props.triggerDOMRect.x + props.triggerDOMRect.width + 6}px`};
      top: ${props => `${props.triggerDOMRect.y + props.triggerDOMRect.height / 2 - 5}px`};
      transform: rotate(-45deg);
    }
  }

  &.left {
    ::before {
      left: ${props => `${props.triggerDOMRect.x - 16}px`};
      top: ${props => `${props.triggerDOMRect.y + props.triggerDOMRect.height / 2 - 5}px`};
      transform: rotate(135deg);
    }
  }

  &.top {
    ::before {
      left: ${props => `${props.triggerDOMRect.x + props.triggerDOMRect.width / 2 - 5}px`};
      top: ${props => `${props.triggerDOMRect.y - 16}px`};
      transform: rotate(-135deg);
    }
  }

  &.bottom {
    ::before {
      left: ${props => `${props.triggerDOMRect.x + props.triggerDOMRect.width / 2 - 5}px`};
      top: ${props => `${props.triggerDOMRect.y + props.triggerDOMRect.height + 6}px`};
      transform: rotate(45deg);
    }
  }
`;