import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';
import * as S from './style';
import { convertS3UrlToCloudFrontUrl, getWorkoutSummary } from 'utils/commonFunction';
import { getSetDescription } from 'helpers/exercise';
import { getCustomBrandingData } from 'redux/custom-branding/actions';
import { bindActionCreators } from 'redux';
import { CDN_URL, HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';
import { axiosInstance } from 'configs/request';
import { ReactComponent as AttachmentIcon } from 'assets/icons/mobile-preview-attachment-icon.svg';

const WorkoutLoader = props => (
  <ContentLoader viewBox="0 0 286 130" height={130} speed={2} backgroundColor={'#F1F1F1'} {...props}>
    <rect x="0" y="83" rx="2" ry="2" width="144" height="18" />
    <rect x="0" y="115" rx="2" ry="2" width="217" height="14" />
  </ContentLoader>
);

class Preview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadingQueue = [];
  }

  componentDidMount() {
    if (_.get(this.props, 'workout._id')) this.initWorkoutData();
    this.props.getCustomBrandingData();
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'workout._id') !== _.get(this.props, 'workout._id')) {
      if (this.state.loading) {
        this.loadingQueue.push(_.get(this.props, 'workout._id'));
      } else {
        this.initWorkoutData();
      }
    }
  }

  initWorkoutData = () => {
    const { workout } = this.props;
    if (_.isEmpty(workout, true)) {
      this.setState({
        workout: [],
      });
    } else {
      this.setState({ loading: true });
      this.loadingQueue = [];
      axiosInstance
        .get(`/api/workout/v2/detail/${workout._id}`)
        .then(response => {
          const { data } = response.data;
          if (this.loadingQueue.length) {
            this.loadingQueue = [];
            return this.initWorkoutData();
          }
          this.setState({
            workout: data,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  renderSection = section => {
    const { unit, fields, cloudfrontList } = this.props;
    const { type, exercises } = section;
    const isHiddenSection = type === HIDDEN_SECTION;
    const isSupersetSections = isHiddenSection && exercises[0].supersets.length > 1;
    const time = calculateSectionTime(section);
    const isFreestyleSection = section.format === SECTION_FORMAT_KEY.FREESTYLE;

    return (
      <S.SectionContainer
        key={section._id}
        className={classNames('section', {
          'section--hidden': isHiddenSection && !isSupersetSections,
          'section--supersets': isSupersetSections,
        })}
      >
        <div className="dot" />
        {!isHiddenSection && (
          <S.SectionTitle>
            <div className="title">{section.title}</div>
            {time.text && (
              <div className="time">
                <img src={time.icon} alt="" />
                <span>{time.text}</span>
              </div>
            )}
            {isFreestyleSection && (
              <div className="attachment">
                <AttachmentIcon />
              </div>
            )}
          </S.SectionTitle>
        )}
        <S.SectionExercises className="section--exercises">
          {_.map(exercises, (set, setIndex) => {
            const isSuperSets = set.supersets.length > 1;

            return (
              <S.Supersets
                key={set._id || setIndex}
                className={classNames('supersets-wrapper', { 'is-supersets': isSuperSets })}
              >
                {isSuperSets && <div className="superset-label">SUPERSET</div>}
                <S.Exercises className="exericses">
                  {_.map(set.supersets, item => {
                    const exercise = item.exercise || item.exercise_instance;

                    if (!exercise) {
                      return null;
                    }

                    const { title, preview_300, picture } = exercise;
                    const { training_sets } = item;
                    const thumbnail = preview_300 || (picture || [])[0];
                    const summary = getSetDescription(item, unit, fields);

                    return (
                      <S.Exercise className="exercise" key={item._id}>
                        <S.ExerciseThumbnail thumb={convertS3UrlToCloudFrontUrl(thumbnail, cloudfrontList, true)} />
                        <S.ExerciseBaseInfo>
                          <S.ExerciseTitle>{title}</S.ExerciseTitle>
                          <S.ExerciseSummary>{summary}</S.ExerciseSummary>
                        </S.ExerciseBaseInfo>
                        <S.NumberOfSets>x{training_sets.length}</S.NumberOfSets>
                      </S.Exercise>
                    );
                  })}
                </S.Exercises>
              </S.Supersets>
            );
          })}
        </S.SectionExercises>
      </S.SectionContainer>
    );
  };

  render() {
    const { workout, loading } = this.state;

    if (!workout) {
      return (
        <S.Wrapper>
          <S.Container>
            <img src={`${CDN_URL}/images/workout_preview_bg.jpg`} className="ear" style={{ display: 'none' }} alt="" />
            <S.Footer />
          </S.Container>
        </S.Wrapper>
      );
    }
    const { cloudfrontList } = this.props;
    const { description, title } = workout;
    const background = _.get(workout, 'background', this.props.selectedWorkoutBackground.src);
    const tier = _.get(this.props, 'tier', 'starter');
    const premiumCustomBrand = _.get(this.props, 'premiumCustomBrand', false);
    const { sections, exercises } = getWorkoutSummary(workout);

    return (
      <S.Wrapper>
        <S.Container>
          <img src={`${CDN_URL}/images/iphone_header.svg`} className="phone-header" alt="" />
          <img src={`${CDN_URL}/images/workout_preview_bg.jpg`} style={{ display: 'none' }} alt="" />
          <S.Content>
            <S.Detail loading={loading}>
              <S.Header
                className="workoutPreview__contentHeader"
                background={
                  premiumCustomBrand && background && tier !== 'starter'
                    ? convertS3UrlToCloudFrontUrl(background, cloudfrontList, true)
                    : `${CDN_URL}/images/workout_preview_bg.jpg`
                }
              >
                <S.Title>{title}</S.Title>
                <S.Summary>
                  <span>
                    {exercises} Exercise{exercises !== 1 ? 's' : ''}
                  </span>
                  {sections ? (
                    <span>
                      {` • `}
                      {sections} Section{sections !== 1 ? 's' : ''}
                    </span>
                  ) : null}
                </S.Summary>
              </S.Header>
              <S.Header className="workoutPreview__loader" loading>
                <WorkoutLoader />
              </S.Header>
              <div style={{ height: '134px' }} />
              <S.Body loading={loading}>
                {description && (
                  <S.Description loading={loading}>
                    <div className="label">Description</div>
                    <div className="text">{description}</div>
                  </S.Description>
                )}
                <S.Sections loading={loading}>{_.map(workout.sections, this.renderSection)}</S.Sections>
              </S.Body>
            </S.Detail>
          </S.Content>
          <S.Footer />
        </S.Container>
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: {
      exercise: { fields },
      customBranding: { selectedWorkoutBackground },
      permission: { premiumCustomBrand },
      client: { workingClientDetail },
    },
  } = state;

  return {
    fields,
    cloudfrontList,
    unit: _.get(workingClientDetail || user, 'preferences', []),
    selectedWorkoutBackground,
    tier: state.rootReducer.pricing.getIn(['teamData', 'tier']),
    premiumCustomBrand,
  };
};

const mapDispatch = dispatch => ({
  getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
});

export default connect(mapState, mapDispatch)(Preview);

const parseTime = seconds => {
  return { minute: parseInt(seconds / 60), second: parseInt(seconds % 60) };
};

const calculateSectionTime = section => {
  const { type, round, time, format } = section;
  let text = '',
    parsedTime,
    totalDuration = Number(time || 0);
  let icon = `${CDN_URL}/images/clock_purple_2.svg`;

  if (type === HIDDEN_SECTION) {
    return { text, icon };
  }

  switch (format) {
    case SECTION_FORMAT_KEY.TIMED:
      icon = `${CDN_URL}/images/round_purple.svg`;
      text = `${round} round${round !== 1 ? 's' : ''}`;
      break;

    case SECTION_FORMAT_KEY.AMRAP:
      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;

    case SECTION_FORMAT_KEY.INTERVAL:
      totalDuration = _.sumBy(section.exercises, set => {
        return _.sumBy(set.supersets, ex => {
          return _.sumBy(ex.training_sets, exSet => {
            const duration = Number(_.get(exSet, 'duration.value', 0));
            const rest = Number(_.get(exSet, 'rest.value', 0));
            return duration + rest;
          });
        });
      });

      parsedTime = parseTime(totalDuration);
      text = `${parsedTime.minute}`;

      if (parsedTime.second) {
        text += `:${`0${parsedTime.second}`.slice(-2)}`;
      }

      text += ` min`;

      break;
  }

  return { text, icon };
};
