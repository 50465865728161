import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const SectionFormat = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #c0c4ca;
  vertical-align: bottom;
`;

export const InstructionContainer = styled.div`
  padding: 0px 20px 20px;

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 140%;
    text-transform: uppercase;
    color: #a6a7b9;
    padding-left: 10px;
    padding-bottom: 5px;
    display: inline-block;
  }

  .text-editable__container {
    padding-top: 8px;
    padding-bottom: 8px;

    .text-editable__input {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #202353;

      :empty::before {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #a3a8bc;
        opacity: 1;
      }
    }

    :not(.text-editable__container--active) {
      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        box-orient: vertical;
        overflow: hidden;
      }
    }

    :hover:not(.text-editable__container--active) {
      background-color: #e9ecf7;
    }
  }
  &.error .text-editable__container {
    border: 1px solid #ea314a;
    background-color: #fef4f6;
    &:hover {
      background-color: #fef4f6 !important;
    }
  }
  &.minimizeOfFreestyle {
    width: calc(100% - 30px);
    margin: auto;
    padding: 0;
    background-color: white;
    box-shadow: 0px 4px 8px #f1f2f6;
    border-radius: 3px;

    :hover {
      .text-editable__container {
        border: 1px solid #5158cf;
        background-color: white;
      }
    }

    .text-editable__container {
      padding: 7px 15px;

      .text-editable__input {
        line-height: 150%;
      }

      :hover:not(.text-editable__container--active) {
        background-color: white;
      }
    }
    &.error .text-editable__container {
      border: 1px solid #ea314a;
      background-color: #fef4f6;
    }

    &.minimizeOfFreestyle {
      margin-bottom: 5px;
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: transparent;
      box-shadow: none;

      .text-editable__container {
        padding: 4px;

        &:hover {
          padding: 4px 9px;
          background-color: #f1f1f3;
        }

        &.text-editable__container--active {
          padding: 7px 15px;

          &:hover {
            padding: 7px 15px;
            background-color: white;
          }
        }
      }
    }
  }
`;

export const NoExerciseErrorContianer = styled.div`
  background-color: #f7f8fc;

  .workoutError--noExercise {
    margin: 0;
  }
`;

export const NoInstructionsErrorContianer = styled.div`
  .workoutError--noInstruction {
    margin-bottom: 10px;
    margin-top: 8px;
  }
`;

export const ExerciseList = styled.div`
  position: relative;
`;

export const Content = styled.div`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 20px 0 30px;
`;

export const Wrapper = styled.div`
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .evf-dropdown.actions {
    margin-left: 10px;

    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border: 1px solid #dadfea;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 24px;
      height: 24px;

      .dots {
        background-color: #758094;
      }

      :hover {
        background-color: #eaebff;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #eaebff;
      }
    }
  }

  &.sectionWrapper {
    &--hidden {
      background-color: #f6f7fb;

      ${ExerciseList} {
        padding: 0;
      }

      ${Content} {
        background-color: #f6f7fb;
        border-radius: 5px;
      }
    }

    &--linkable {
      margin-bottom: 0;
    }

    &--dragmode {
      // margin-bottom: 0px;
    }

    &--minimize {
      ${ExerciseList} {
        display: none;
      }

      .section_instructionContainer {
        padding-bottom: 4px;
      }
    }

    :not(.sectionWrapper--hidden) {
      border-radius: 5px;
      background-color: #ffffff;
    }

    & .sectionWrapper--freestyle {
      border-radius: 5px;
    }
  }

  .section__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .workoutError--invalidSectionValue {
    margin-top: 8px;
  }

  .workoutError--sectionTitleIsEmpty {
    margin-top: 8px;
    padding: 0 20px;
  }

  .section__moreExercises {
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #202353;

    .count {
      font-weight: normal;
    }
  }
`;

export const SemiBoldText = styled.span`
  font-weight: 600;
`;
export const NormalText = styled.span`
  font-weight: 400;
`;

export const ListExercise = styled.div`
  margin-top: 25px;
`;

export const AddListExercistButton = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;

  ${SemiBoldText} {
    margin-left: 6px;
  }

  &:hover {
    color: #5158cf;

    svg {
      > path:first-child {
        fill: #5158cf;
      }
    }
  }
`;

export const Text = styled.div`
  color: #758094;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;

  &:hover {
    color: #5158cf;
  }

  &.black {
    color: #202353;

    &:hover {
      color: #202353;
    }
  }

  ${props =>
    props.cursor &&
    css`
      cursor: pointer;
    `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BlockSelectSection = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const WrapperBlockSelectSection = styled.div`
  padding: 0 20px 30px 30px;
`;

export const BlockSelectSectionItem = styled.div`
  flex-shrink: 0;
  flex: ${props => (props.isFormatFreeStyle ? '0 1 175px' : '1 1 0')};
`;

export const SectionFormatAmrap = styled.div`
  width: 100%;
  flex: 1 1 0;
  .invalid {
    background: #f5eff3;
    border: 1px solid #db3d33;
  }
  input.invalid:focus,
  input.invalid:hover {
    border-color: #db3d33;
  }
`;

export const SectionFormatTimed = styled.div`
  width: 100%;
  flex: 1 1 0;
  .invalid {
    background: #f5eff3;
    border: 1px solid #db3d33;
  }
  input.invalid:focus,
  input.invalid:hover {
    border-color: #db3d33;
  }
`;

export const SectionFormatInterval = styled.div`
  width: 100%;
  flex: 1 1 0;
  .app-tooltip {
    max-width: 228px !important;
    margin-top: 0 !important;
    text-align: left !important;
    padding: 15px !important;
    margin-top: -4px !important;
    &::after {
      bottom: -6px !important;
    }
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }
  }
  .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
  }
  .inputContainer {
    width: 100%;
    height: 36px;
    background: #fafbfc;
    opacity: 0.5;
    border: 1px solid #dadfea;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #323c47;
    }
    &.readOnly {
      cursor: not-allowed;
    }
  }
`;

export const AddButton = styled(Button)`
  padding: 11px;
  background: #eaebff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5158cf;
  border-color: transparent;
  width: 100%;
  margin-top: 15px;

  .add-button__icon {
    width: 20px;
    height: 20px;
    background-image: url(${CDN_URL}/images/plus_purple_new.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
  }

  :hover {
    background: #5158cf;
    color: #ffffff;

    .add-button__icon {
      background-image: url(${CDN_URL}/images/plus_white_new.svg);
    }
  }
`;

export const ActionFixedContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  min-height: 38px;
  padding: 10px 35px;
  background-image: url(${CDN_URL}/images/arrow_up_purple.svg),
    linear-gradient(180deg, rgba(112, 114, 221, 0) 0%, #7072dd2b 100%);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  .btn-actions {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease;
    margin-top: 0;
  }

  :hover {
    background-color: #ffffff;
    background-image: none;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    opacity: 1;

    .btn-actions {
      max-height: 100px;
    }
  }
`;

export const WrapUploadAndDragSection = styled.div`
  > div > div {
    margin: 0;
    width: 100%;
  }
`;

export const WrapListExercises = styled.div`
  .alt-exs__exercise-menu {
    height: 100%;
  }
`;

export const AddOnLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
`;

export const WrapperSectionFormatFreeStyle = styled.div`
  padding: 0 20px 0 30px;
`;
