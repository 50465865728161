// Lib
import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

// Shared
import SingleChoiceResponse from './SingleChoiceResponse';
import MultipleChoiceResponse from './MultipleChoiceResponse';
import ShortAnswerResponse from './ShortAnswerResponse';
import LongAnswerResponse from './LongAnswerResponse';
import LinearScaleResponse from './LinearScaleResponse';
import ImageTextResponse from './ImageTextResponse';
import SignatureResponse from './SignatureResponse';
import DateAnswerResponse from './DateAnswerResponse';
import MetricAnswerResponse from './MetricAnswerResponse';
import TextEditable from 'shared/TextEditable';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';

// Assets
import { ReactComponent as MetricAnalyticIcon } from 'assets/icons/FormQuestionnaires/metric_analytic_icon.svg';

// Styles
import * as S from '../styles';

const renderAnswerByType = (answer, getCloudUrl, dateFormat) => {
  switch (answer.originalQuestion.type) {
    case QUESTION_TYPES.SINGLE_CHOICE:
      return <SingleChoiceResponse answer={answer} />;
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return <MultipleChoiceResponse answer={answer} />;
    case QUESTION_TYPES.SHORT_ANSWER:
      return <ShortAnswerResponse answer={answer} />;
    case QUESTION_TYPES.LONG_ANSWER:
      return <LongAnswerResponse answer={answer} />;
    case QUESTION_TYPES.LINEAR_SCALE:
      return <LinearScaleResponse answer={answer} />;
    case QUESTION_TYPES.SIGNATURE:
      return <SignatureResponse answer={answer} getCloudUrl={getCloudUrl} />;
    case QUESTION_TYPES.DATE_TIME:
      return <DateAnswerResponse answer={answer} dateFormat={dateFormat} />;
    case QUESTION_TYPES.METRIC:
      return <MetricAnswerResponse answer={answer} />;

    default:
      return null;
  }
};

export default function FormResponse({ workingAnswerDate, getCloudUrl, dateFormat, clientId = '', push }) {
  const originalFormQuestions = get(workingAnswerDate, 'form.questions', []);
  const originalQuestionAnswers = get(workingAnswerDate, 'question_answers', []);
  const questions = originalFormQuestions
    .filter(obj => obj.type !== QUESTION_TYPES.WELCOME_SCREEN)
    .map(question => {
      return {
        answer: find(originalQuestionAnswers, o => o.question_original_id === question.question_original_id),
        originalQuestion: question,
      };
    });
  let numberIndex = 0;

  const onOpenClientMetricDetail = question => {
    if (!clientId) return;

    typeof push === 'function' &&
      push(`/home/client/${clientId}/metrics?unique_code=${get(question, 'originalQuestion.body_metric_unique_code')}`);
  };

  return (
    <S.FormRespondContainer>
      {questions.map(question => {
        const questionType = get(question, 'originalQuestion.type');
        const isImageText = questionType === QUESTION_TYPES.IMAGE_TEXT;
        numberIndex = questionType !== QUESTION_TYPES.IMAGE_TEXT ? numberIndex + 1 : numberIndex;

        return (
          <S.AnswerContainer key={question.originalQuestion._id}>
            <div className={classNames('header', { empty: question.answer || isImageText ? false : true })}>
              <div className="title">
                {questionType !== QUESTION_TYPES.IMAGE_TEXT && `${numberIndex}. `}
                {question.originalQuestion.title}
              </div>
              {[QUESTION_TYPES.SIGNATURE, QUESTION_TYPES.IMAGE_TEXT].includes(questionType) &&
              get(question, 'originalQuestion.description') ? (
                <TextEditable
                  value={get(question, 'originalQuestion.description')}
                  lineHeight={22.5}
                  breakLine
                  readOnly
                />
              ) : null}
            </div>
            {questionType === QUESTION_TYPES.IMAGE_TEXT ? (
              <ImageTextResponse question={question} />
            ) : question.answer ? (
              renderAnswerByType(question, getCloudUrl, dateFormat)
            ) : (
              <S.NotAnswered>This question hasn't been answered</S.NotAnswered>
            )}
            {questionType === QUESTION_TYPES.METRIC && (
              <>
                <S.MetricAnalyticIconWrapper
                  data-for={`responses-metric-${get(question, 'originalQuestion._id')}`}
                  data-tip
                  onClick={() => onOpenClientMetricDetail(question)}
                >
                  <MetricAnalyticIcon className="metric-detail-icon" />
                </S.MetricAnalyticIconWrapper>
                <ReactTooltip
                  className="app-tooltip responses-metric-tooltip"
                  id={`responses-metric-${get(question, 'originalQuestion._id')}`}
                  effect="solid"
                  place="top"
                >
                  <span>View client metric</span>
                </ReactTooltip>
              </>
            )}
          </S.AnswerContainer>
        );
      })}
    </S.FormRespondContainer>
  );
}
