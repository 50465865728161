// Libs
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { CDN_URL, PLANS } from 'constants/commonData';

// Actions
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { addPackages, removePackage } from 'redux/onboarding-flow/actions';

// Components
import ButtonCreate from '../ButtonCreate';
import ListModal from '../ListModal';
import ListPackage from './ListPackage';
import UpgradePathIcon from 'shared/UpgradePathIcon';

// Constants
import { SETTINGS_TYPE_MODAL } from '../constants';

// Styles
import * as S from './style';

const AttachPackageOverview = ({
  user,
  push,
  isEnabled,
  permission,
  addPackages,
  toggleModal,
  listPackages,
  removePackage,
  cloudfrontList,
  isLoadingAction,
  onboardingFlowId,
  isLoadingSetting,
  toggleConfirmModal,
  isEnabledWorkspace,
}) => {
  const isEmptySelectedList = listPackages.length === 0;

  const handleRedirectToSettings = (hash_id, platforms) => {
    const isMarketplace = platforms.includes('marketplace');
    const path = `/home/${isMarketplace ? 'marketplace' : 'packages'}/${hash_id}/settings`;
    push(path);
  };

  const handleRedirectToOverview = (hash_id, platforms) => {
    const isMarketplace = platforms.includes('marketplace');
    const path = `/home/${isMarketplace ? 'marketplace' : 'packages'}/${hash_id}`;
    push(path);
  };

  const handleRemovePackage = id => {
    typeof removePackage === 'function' && removePackage(onboardingFlowId, id);
  };

  const handleSubmit = selectedList => {
    if (isLoadingAction) return;

    const selectedListIds = (selectedList || []).map(({ id }) => id);

    const bodyData = {
      packages: selectedListIds,
    };

    typeof addPackages === 'function' && addPackages(onboardingFlowId, bodyData);
  };

  const handleAddPackages = () => {
    toggleModal(
      true,
      <ListModal
        user={user}
        title="Packages"
        cloudfrontList={cloudfrontList}
        type={SETTINGS_TYPE_MODAL.PACKAGES}
        onClose={() => toggleModal(false)}
        toggleConfirmModal={toggleConfirmModal}
        onSubmit={handleSubmit}
        currentSelectedList={listPackages}
        permission={permission}
      />,
    );
  };

  return (
    <S.Wrapper isDisabled={isEnabled || isEnabledWorkspace || isLoadingSetting} isEmpty={isEmptySelectedList}>
      <S.LeftSide className="left-side">
        <S.Header>Attach to Packages</S.Header>
        <S.Description>
          Links this onboarding flow to the specific packages and assign its assets to clients when they activate the
          packages.
        </S.Description>
        <S.LearnMore
          href="https://help.everfit.io/en/articles/9651985-how-to-add-an-onboarding-flow-to-a-package"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.LearnMoreIcon src={`${CDN_URL}/images/learn_more_icon.svg`} alt="learn more onboarding flow" />
          <S.LearnMoreText>Learn how to add an onboarding flow to a package</S.LearnMoreText>
        </S.LearnMore>
      </S.LeftSide>
      <S.RightSide className="right-side">
        {permission.payment && (
          <>
            {listPackages.length > 0 && (
              <ListPackage
                list={listPackages}
                cloudfrontList={cloudfrontList}
                onRedirectToSettings={handleRedirectToSettings}
                onRedirectToOverview={handleRedirectToOverview}
                onRemovePackage={handleRemovePackage}
                isLoadingAction={isLoadingAction}
              />
            )}
            <ButtonCreate title="Add Packages" onClick={handleAddPackages} disabled={false} />
          </>
        )}
        {!permission.payment && (
          <S.ButtonUpgradePath
            text={
              <>
                <UpgradePathIcon />
                <span className="upgrade-path-text">Upgrade to get this feature</span>
              </>
            }
            planWillSelect={{
              tier: PLANS.pro.key,
              addOns: {
                payment_package: true,
              },
            }}
          />
        )}
      </S.RightSide>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: {
      permission,
      onboardingFlow: { isLoadingSetting, isLoadingAction, workingSetting, workingData },
    },
  } = state;

  return {
    user,
    permission,
    cloudfrontList,
    isLoadingAction,
    isLoadingSetting,
    listPackages: get(workingSetting, 'packages', []),
    onboardingFlowId: get(workingSetting, 'onboarding_flow', ''),
    isEnabledWorkspace: get(workingData, 'is_default_team', false),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addPackages: bindActionCreators(addPackages, dispatch),
  push: bindActionCreators(push, dispatch),
  removePackage: bindActionCreators(removePackage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachPackageOverview);
