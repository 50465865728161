import React from 'react';
import classNames from 'classnames';

import { WarningIssueIcon, UpgradePathIconButton } from 'shared/TriggerForms';

import TagWrapper from '../TagWrapper';

import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as TriggerFormIcon } from 'assets/icons/onboarding-trigger-form-grey.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/consultation-remove.svg';

import * as S from './style';

const BasedOnTrigger = props => {
  const { disabled = false, onOpen, value, onRemove, isHasPermissionUseTrigger, type } = props;
  const { form, custom_form, question_data, trigger_options, is_form_deleted, is_deleted } = value || {};
  const { title } = question_data || {};

  const isTriggerUnavailable = is_form_deleted === true || is_deleted === true;
  const countAssignedOptions = trigger_options.filter(it => !!it.forum || !!it.program_library).length || 0;
  const additionData = !isTriggerUnavailable && countAssignedOptions > 0 ? ` (${countAssignedOptions})` : '';

  const handleEditTrigger = () => {
    typeof onOpen === 'function' && onOpen();
  };

  const handleRemoveTrigger = () => {
    typeof onRemove === 'function' && onRemove();
  };

  return (
    <S.BasedOnTriggerWrapper
      className={classNames({ 'is-unavailable': isTriggerUnavailable })}
      isNoPermission={!isHasPermissionUseTrigger}
    >
      {!isHasPermissionUseTrigger && (
        <UpgradePathIconButton
          wrapperClassNames="warning-icon-wrapper"
          tooltipId="test"
          tooltipContent="Upgrade to use trigger"
        />
      )}
      {isHasPermissionUseTrigger && !isTriggerUnavailable && (
        <WarningIssueIcon
          wrapperClassNames="warning-icon-wrapper"
          tooltipId={`warning-issue-icon-tooltip-${form || custom_form}-${type}`}
          triggerValue={value}
          type={type}
        />
      )}
      <TagWrapper
        isDeleted={isTriggerUnavailable}
        icon={<TriggerFormIcon />}
        content={`Based on trigger${additionData}`}
      />
      {isTriggerUnavailable && <TagWrapper isDeleted={isTriggerUnavailable} content="Deleted" />}
      <div className="question-label">{title || ''}</div>

      {isHasPermissionUseTrigger && !disabled && (
        <button className="edit-button" onClick={handleEditTrigger}>
          <EditIcon className="onboarding-flow-edit-icon-trigger" />
          <span className="button-label">Edit</span>
        </button>
      )}
      {!isHasPermissionUseTrigger && !disabled && (
        <S.RemoveButtonWrapper className="remove-button-wrapper">
          <button className="remove-button" onClick={handleRemoveTrigger}>
            <TrashIcon className="onboarding-flow-trash-icon-trigger" />
            <span className="button-label">Remove</span>
          </button>
        </S.RemoveButtonWrapper>
      )}
    </S.BasedOnTriggerWrapper>
  );
};

export default BasedOnTrigger;
