import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import uniqBy from 'lodash/uniqBy';

import { Types } from './actions';
import { CALENDAR_TYPES } from 'constants/commonData';

const INITIAL_STATE = {
  isSearching: true,
  workingStudio: null,
  studioProgramId: null,
  availableClients: [],
  originClients: [],
  list: [],
  total: 0,
  query: {
    page: 1,
    per_page: 15,
    search: '',
  },
  calendarViewMode: 2,
  startWeek: 0,
  workoutsByDay: {},
  copying: null,
  selectedWeek: null,
  selectedWorkout: [],
  isCopySelectedWorkout: false,
  weekCopyId: null,
  loadingPaste: false,
  isGetWorkoutsLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.STUDIO_PROGRAMS_LOADING_DATA:
      return Object.assign({}, state, { isSearching: true });

    case Types.STUDIO_PROGRAMS_FAILED_GET_LIST:
      return Object.assign({}, state, { isSearching: false });

    case Types.STUDIO_PROGRAMS_SUCCESS_GET_LIST:
      return Object.assign({}, state, {
        isSearching: false,
        total: payload.total,
        list: payload.data,
        query: {
          ...state.query,
          page: 1,
        },
      });

    case Types.STUDIO_PROGRAMS_SUCCESS_LOAD_MORE_LIST:
      return Object.assign({}, state, {
        isSearching: false,
        total: payload.total,
        list: [...state.list, ...payload.data],
        query: {
          ...state.query,
          page: payload.page,
        },
      });

    case Types.STUDIO_PROGRAMS_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...payload.data } });

    case Types.STUDIO_PROGRAMS_LIST_RESET_DATA:
      return Object.assign({}, state, { isSearching: false, query: INITIAL_STATE.query, list: [], total: 0 });

    case Types.STUDIO_PROGRAM_SUCCESS_DELETE_PROGRAM:
      return Object.assign({}, state, {
        list: state.list.filter(o => o._id !== payload.data.programId),
        total: state.total - 1,
      });

    case Types.STUDIO_PROGRAM_SUCCESS_GET_DETAIL:
      return Object.assign({}, state, {
        workingStudio: payload.data,
        ...updateWeekView(payload.data, state.startWeek, state.calendarViewMode),
      });

    case Types.STUDIO_PROGRAM_GET_DETAIL:
      return Object.assign({}, state, {
        studioProgramId: payload.data,
      });

    case Types.STUDIO_PROGRAM_SUCCESS_UPDATE:
      return Object.assign({}, state, {
        workingStudio: {
          ...state.workingStudio,
          ...payload.data,
        },
      });

    case Types.STUDIO_PROGRAM_SUCCESS_GET_AVAILABLE_CLIENTS:
      return Object.assign({}, state, { availableClients: payload.data, originClients: payload.data });

    case Types.STUDIO_PROGRAM_SUCCESS_SAVE_CLIENTS:
      return Object.assign({}, state, { originClients: state.availableClients });

    case Types.STUDIO_PROGRAM_ADD_CLIENT_TO_REDUX:
      return Object.assign({}, state, {
        availableClients: uniqBy([...payload.data, ...state.availableClients], '_id'),
      });

    case Types.STUDIO_PROGRAM_REMOVE_CLIENT_TO_REDUX:
      return Object.assign({}, state, {
        availableClients: state.availableClients.filter(item => item._id !== payload.data._id),
      });

    case Types.STUDIO_PROGRAM_RESET_DETAIL_DATA:
      return Object.assign({}, state, {
        workingStudio: null,
        calendarViewMode: 2,
        startWeek: 0,
        workoutsByDay: {},
      });

    case Types.STUDIO_PROGRAM_SUCCESS_GET_WORKOUTS:
      if (
        state.workingStudio &&
        state.workingStudio._id === payload.programId &&
        payload.startWeek === state.startWeek &&
        payload.endWeek === state.calendarViewMode + state.startWeek - 1
      ) {
        return Object.assign({}, state, {
          workoutsByDay: { ...state.workoutsByDay, ...payload.data },
          isGetWorkoutsLoading: false,
        });
      } else {
        return state;
      }

    case Types.STUDIO_PROGRAM_REQUEST_GET_WORKOUTS:
      return Object.assign({}, state, { isGetWorkoutsLoading: true });

    case Types.STUDIO_PROGRAM_GET_WORKOUTS_FAILED:
      return Object.assign({}, state, { isGetWorkoutsLoading: false });

    case Types.STUDIO_PROGRAM_CHANGE_CALENDAR_VIEW_MODE:
      return Object.assign({}, state, {
        calendarViewMode: payload.data,
        startWeek:
          payload.data === 1
            ? state.startWeek
            : Math.min(state.workingStudio.studio_program_weeks.length - payload.data, state.startWeek),
      });

    case Types.STUDIO_PROGRAM_GO_TO_WEEK:
      return Object.assign({}, state, { startWeek: payload.data });

    case Types.STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_SINGLE_DAY:
    case Types.STUDIO_PROGRAM_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS:
      const { workoutId = '', isDelete = false } = payload;
      const cloneSelectedWorkout = get(state, 'selectedWorkout', []);
      const isIncludes = isDelete && cloneSelectedWorkout.includes(workoutId);
      const newSelectedWorkout = cloneSelectedWorkout.filter(it => it !== workoutId);

      if (state.workingStudio && state.workingStudio._id === payload.programId) {
        return Object.assign({}, state, {
          workoutsByDay: { ...state.workoutsByDay, ...payload.data },
          selectedWorkout: isIncludes ? newSelectedWorkout : state.selectedWorkout,
        });
      } else {
        return state;
      }

    case Types.STUDIO_PROGRAM_COPY_WORKOUT:
      return Object.assign({}, state, { copying: payload.data });

    case Types.STUDIO_PROGRAM_RESET_COPY_ITEM:
      return Object.assign({}, state, { copying: null });

    case Types.STUDIO_PROGRAM_MOVE_WORKOUT_ON_LOCAL:
      return moveLocalWorkouts(state, payload.data);

    case Types.STUDIO_PROGRAM_ARRANGE_WORKOUT_ON_LOCAL:
      return arrangeLocalWorkouts(state, payload.data);

    case Types.STUDIO_PROGRAM_SUCCESS_ENABLE_EDIT_MODE:
    case Types.STUDIO_PROGRAM_SUCCESS_CANCEL_CHANGES:
      if (get(state, 'workingStudio._id') === payload.programId) {
        return Object.assign({}, state, {
          workingStudio: { ...state.workingStudio, ...payload.data, last_edit_by: null },
          workoutsByDay: {},
        });
      } else {
        return state;
      }

    case Types.STUDIO_PROGRAM_SUCCESS_PUBLISH_CHANGES: {
      if (get(state, 'workingStudio._id') === payload.programId) {
        return Object.assign({}, state, {
          workingStudio: { ...state.workingStudio, ...payload.data, last_edit_by: null, isSyncing: true },
          workoutsByDay: {},
        });
      } else {
        return state;
      }
    }

    case Types.STUDIO_PROGRAM_UPDATE_LAST_EDIT_BY:
      if (get(state, 'workingStudio._id') === payload.programId) {
        return Object.assign({}, state, {
          workingStudio: { ...state.workingStudio, last_edit_by: payload.data },
        });
      } else {
        return state;
      }

    case Types.STUDIO_PROGRAM_SUCCESS_ADD_WEEK:
      const workoutsByDay = { ...state.workoutsByDay };
      const newWorkoutsByDay = {};

      const weekIndex = payload.weekIndex;

      for (const [key, value] of Object.entries(workoutsByDay)) {
        const weekIndexWorkout = parseInt(key.charAt(0));
        if (weekIndex > weekIndexWorkout) {
          newWorkoutsByDay[key] = value;
        }

        if (weekIndex <= weekIndexWorkout) {
          const weekIndex_DayIndex = key.replace(`${key.charAt(0)}`, `${parseInt(key.charAt(0)) + 1}`);
          newWorkoutsByDay[weekIndex_DayIndex] = [...value];
        }
      }

      return Object.assign({}, state, {
        workingStudio: {
          ...state.workingStudio,
          ...payload.data,
        },
        ...updateWeekView(payload.data, state.startWeek, state.calendarViewMode),
        workoutsByDay: { ...newWorkoutsByDay },
      });

    case Types.STUDIO_PROGRAM_SUCCESS_REMOVE_WEEK:
      const workouts = { ...state.workoutsByDay };
      const newWorkouts = {};

      const removedWeekIndex = payload.weekIndex;

      for (const [key, value] of Object.entries(workouts)) {
        const weekIndexWorkout = parseInt(key.charAt(0));
        if (removedWeekIndex > weekIndexWorkout) {
          newWorkouts[key] = value;
        }

        if (removedWeekIndex <= weekIndexWorkout) {
          const weekIndex_DayIndex = key.replace(`${key.charAt(0)}`, `${parseInt(key.charAt(0)) - 1}`);
          newWorkouts[weekIndex_DayIndex] = [...value];
        }
      }

      return Object.assign({}, state, {
        workingStudio: {
          ...state.workingStudio,
          ...payload.data,
        },
        ...updateWeekView(payload.data, state.startWeek, state.calendarViewMode),
        workoutsByDay: { ...newWorkouts },
      });

    case Types.STUDIO_PROGRAM_MOVE_EXERCISE:
      return handleMoveExercise(state, payload.data);

    case Types.STUDIO_PROGRAM_COPY_WEEK: {
      const { weekId, weekCopyId } = payload;
      return Object.assign({}, state, { selectedWeek: weekId, weekCopyId: weekCopyId });
    }

    case Types.STUDIO_PROGRAM_PASTE_WEEK_REQUEST: {
      if (action.payload.multiPaste) return state;
      return Object.assign({}, state, { selectedWeek: null });
    }

    case Types.STUDIO_PROGRAM_RESET_WEEK: {
      return Object.assign({}, state, { selectedWeek: null, weekCopyId: null });
    }

    case Types.STUDIO_PROGRAM_SELECT_MULTIPLE_WORKOUT: {
      return Object.assign({}, state, { selectedWorkout: payload.workoutIds });
    }

    case Types.STUDIO_PROGRAM_RESET_MULTIPLE_WORKOUT: {
      return Object.assign({}, state, { selectedWorkout: [], isCopySelectedWorkout: false });
    }

    case Types.STUDIO_PROGRAM_COPY_MULTIPLE_WORKOUT: {
      return Object.assign({}, state, { isCopySelectedWorkout: isEmpty(payload.workouts) ? false : true });
    }

    case Types.STUDIO_PROGRAM_PASTE_MULTIPLE_WORKOUT_REQUEST: {
      if (payload.multiPaste) return state;
      return Object.assign({}, state, {
        selectedWorkout: [],
        isCopySelectedWorkout: false,
      });
    }

    case Types.STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_REQUEST: {
      return Object.assign({}, state, { isCopySelectedWorkout: false });
    }

    case Types.STUDIO_PROGRAM_SOCKET_WORKOUT_ADDED: {
      const { weekIndex, dayIndex, workout } = payload;
      const newWorkoutsByDay = cloneDeep(get(state, 'workoutsByDay', {}));

      if (isNumber(weekIndex) && isNumber(dayIndex) && workout) {
        if (!newWorkoutsByDay[`${weekIndex}_${dayIndex}`]) {
          newWorkoutsByDay[`${weekIndex}_${dayIndex}`] = [];
        }
        newWorkoutsByDay[`${weekIndex}_${dayIndex}`] = [...newWorkoutsByDay[`${weekIndex}_${dayIndex}`], workout];
      }

      return Object.assign({}, state, {
        workoutsByDay: { ...newWorkoutsByDay },
      });
    }

    case Types.STUDIO_PROGRAM_REMOVE_WEEK_SUCCESS: {
      return Object.assign({}, state, {
        selectedWeek: null,
      });
    }

    case Types.STUDIO_PROGRAM_REMOVE_WORKOUT_OF_WEEK_SUCCESS: {
      return Object.assign({}, state, {
        selectedWorkout: [],
      });
    }

    case Types.STUDIO_PROGRAM_SOCKET_WORKOUT_DELETED: {
      const { workoutIds } = payload;

      return Object.assign({}, state, {
        isCopySelectedWorkout: false,
        workoutsByDay: { ...removeWorkoutByIds(workoutIds, get(state, 'workoutsByDay', {})) },
      });
    }
    case Types.STUDIO_PROGRAM_CHANGE_SHARE_STATUS: {
      return Object.assign({}, state, {
        list: payload || state.list,
      });
    }

    default:
      return state;
  }
};

const removeWorkoutByIds = (removedIds, workoutsByDay) => {
  const newWorkoutsByDay = cloneDeep(workoutsByDay);

  Object.entries(newWorkoutsByDay).forEach(([key, value], index) => {
    if (typeof value !== 'undefined' && value.some(item => removedIds.includes(get(item, '_id', '')))) {
      newWorkoutsByDay[key] = value.filter(item => !removedIds.includes(get(item, '_id', '')));
    }
  });

  return newWorkoutsByDay;
};

const updateWeekView = (programData, currentStartWeek, currentViewMode) => {
  let newStartWeek = currentStartWeek;
  let newViewMode = currentViewMode;
  const totalWeek = programData.studio_program_weeks.length;

  if (totalWeek <= 1) {
    newViewMode = 1;
  } else {
    if (currentViewMode > 1 && totalWeek < currentViewMode) {
      const findIdx = findIndex(CALENDAR_TYPES, item => item.value === currentViewMode);
      newViewMode = findIdx > 0 ? CALENDAR_TYPES[findIdx - 1].value : 1;
    }
  }

  if (newStartWeek >= totalWeek - newViewMode) {
    newStartWeek = totalWeek - newViewMode;
  }

  return { startWeek: newStartWeek, calendarViewMode: newViewMode };
};

const moveLocalWorkouts = (oldState, data) => {
  const { workoutId, newIndex, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex } = data;
  const newWorkoutByDays = cloneDeep(oldState.workoutsByDay);
  const oldDayId = `${oldWeekIndex}_${oldDayIndex}`;
  const newDayId = `${newWeekIndex}_${newDayIndex}`;

  if (!newWorkoutByDays[oldDayId] || !newWorkoutByDays[oldDayId].length) {
    return oldState;
  }

  const oldIndex = findIndex(newWorkoutByDays[oldDayId] || [], w => w._id === workoutId);
  const workoutData = newWorkoutByDays[oldDayId][oldIndex];

  if (!workoutData) {
    return oldState;
  }

  if (!newWorkoutByDays[newDayId]) {
    newWorkoutByDays[newDayId] = [workoutData];
  } else {
    newWorkoutByDays[newDayId].splice(newIndex, 1, workoutData, ...newWorkoutByDays[newDayId].splice(newIndex));
  }

  newWorkoutByDays[oldDayId].splice(oldIndex, 1);

  return Object.assign({}, oldState, { workoutsByDay: newWorkoutByDays });
};

const arrangeLocalWorkouts = (oldState, data) => {
  const { workoutId, newIndex, weekIndex, dayIndex } = data;
  const newWorkoutByDays = cloneDeep(oldState.workoutsByDay);
  const dayId = `${weekIndex}_${dayIndex}`;

  if (!newWorkoutByDays[dayId] || !newWorkoutByDays[dayId].length) {
    return oldState;
  }

  const oldIndex = findIndex(newWorkoutByDays[dayId], w => w._id === workoutId);
  const workoutData = newWorkoutByDays[dayId][oldIndex];

  if (!workoutData) {
    return oldState;
  }

  newWorkoutByDays[dayId].splice(oldIndex, 1);
  newWorkoutByDays[dayId].splice(newIndex, 0, workoutData);

  return Object.assign({}, oldState, { workoutsByDay: newWorkoutByDays });
};

const handleMoveExercise = (oldState, data) => {
  const { workoutId, newIndex, oldIndex, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex, newWorkoutId } = data;
  const newWorkoutByDays = cloneDeep(oldState.workoutsByDay);

  const fromDayId = `${oldWeekIndex}_${oldDayIndex}`;
  const toDayId = `${newWeekIndex}_${newDayIndex}`;

  const fromWorkoutIndex = newWorkoutByDays[fromDayId].findIndex(w => w._id === workoutId);
  const fromWorkout = newWorkoutByDays[fromDayId][fromWorkoutIndex];
  const movingExercise = fromWorkout.sections[oldIndex];

  // remove old exercise
  newWorkoutByDays[fromDayId][fromWorkoutIndex].sections.splice(oldIndex, 1);
  if (!newWorkoutByDays[fromDayId][fromWorkoutIndex].sections.length) {
    newWorkoutByDays[fromDayId].splice(fromWorkoutIndex, 1);
  }

  const toWorkoutIndex = (newWorkoutByDays[toDayId] || []).findIndex(w => w._id === newWorkoutId);

  // update new exercise
  if (toWorkoutIndex !== -1) {
    newWorkoutByDays[toDayId][toWorkoutIndex].sections.splice(newIndex, 0, movingExercise);
  }

  return Object.assign({}, oldState, { workoutsByDay: newWorkoutByDays });
};
