import React, { useState, useMemo } from 'react';
import get from 'lodash/get';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Radio } from 'semantic-ui-react';
import CurrencyInput from './CurrencyInput';
import BillCustomEvery from './BillCustomEvery';
import { END_PERIOD, PERIOD_OPTIONS } from '../constants';
import { DropdownIndicator } from 'shared/Icons';
import { formatMoney, pluralize } from 'utils/commonFunction';
import { CURRENCIES, TEXT_CURRENCIES } from 'constants/commonData';
import TrialSetting from './TrialSettings';
import * as S from './styles';

const RecurringPayment = props => {
  const {
    data,
    onChange,
    showError,
    permission,
    onChangeType,
    onChangeEvery,
    everyErrorField,
    amountErrorField,
  } = props;

  const isEnableTrialPeriod = get(permission, 'trial_package', false);
  const [endPeriod, setEndPeriod] = useState(data.end_after ? END_PERIOD.HAS_LIMIT : END_PERIOD.FOREVER);
  const endAfterValue = useMemo(() => PERIOD_OPTIONS.find(item => item.value === data.end_after) || null, [
    data.end_after,
  ]);
  const currency = get(data, 'currency', 'usd');
  const symbol = TEXT_CURRENCIES.includes(currency)
    ? `${get(CURRENCIES, `${currency}.symbol`)} `
    : get(CURRENCIES, `${currency}.symbol`);

  const totalAmount = useMemo(() => {
    if (data.end_after && data.amount) {
      return data.end_after * data.amount;
    }

    return 0;
  }, [data.amount, data.end_after]);

  const over = useMemo(() => {
    if (data.end_after && data.every) {
      return data.end_after * data.every;
    }

    return 0;
  }, [data.every, data.end_after]);

  const handleChangeEndPeriod = (event, { value }) => {
    if (value === endPeriod) {
      return;
    }

    setEndPeriod(value);

    if (value === END_PERIOD.FOREVER) {
      onChangeType({ end_after: 0 });
    } else {
      onChangeType({ end_after: 2 });
    }
  };

  const handleEndAfter = newValue => {
    onChangeType({ end_after: newValue.value });
  };

  const handleChangEveryType = newValue => {
    onChangeType(newValue);
  };

  const handleSettingToggle = status => {
    onChangeType({ enable_trial: status });
  };

  const handleChangeTrialPeriod = params => {
    const { time, isArchiveClient, isTrialOnce } = params;
    onChange({ trial_period: time, is_archive_on_trial_cancellation: isArchiveClient, allow_trial_once: isTrialOnce });
  };

  return (
    <S.Wrapper>
      <S.Header>Recurring Subscription</S.Header>
      <S.Description>
        This package will set up a recurring charge that repeats for a set number of times or renews forever.
        Registration requires email and credit card details.
      </S.Description>
      <S.FormGroup>
        <label>Set Price</label>
        <CurrencyInput data={data} onChange={onChange} showError={showError} errorField={amountErrorField} />
      </S.FormGroup>
      <S.FormGroup>
        <label>Bill customer every</label>
        <BillCustomEvery
          data={data}
          onChange={onChangeEvery}
          showError={showError}
          onChangeEveryType={handleChangEveryType}
          errorField={everyErrorField}
        />
      </S.FormGroup>
      <S.EndPeriodContainer>
        <S.RadioContainer>
          <Radio
            label="Renew Forever"
            name="period"
            value={END_PERIOD.FOREVER}
            checked={endPeriod === END_PERIOD.FOREVER}
            onChange={handleChangeEndPeriod}
          />
        </S.RadioContainer>
        <S.RadioContainer>
          <Radio
            label="End after"
            name="period"
            value={END_PERIOD.HAS_LIMIT}
            checked={endPeriod === END_PERIOD.HAS_LIMIT}
            onChange={handleChangeEndPeriod}
          />
          {!!data.end_after && (
            <>
              <S.InvoiceRepeatContainer>
                <Select
                  options={PERIOD_OPTIONS}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  onChange={handleEndAfter}
                  classNamePrefix="evfSelectBox"
                  className="evfSelectBoxContainer"
                  value={endAfterValue}
                  isSearchable={false}
                  menuPosition="fixed"
                  styles={{
                    control: base => ({ ...base, minHeight: 40, height: 40, minWidth: 108 }),
                  }}
                />
                <span>{pluralize('Invoice', data.end_after, false)}</span>
              </S.InvoiceRepeatContainer>
              <S.TotalAmountContainer>
                <div>
                  <span>Total:&nbsp;</span>
                  {!!totalAmount && <span className="value">{formatMoney(totalAmount, symbol)}</span>}
                </div>
                <div>Over {pluralize(data.recurring_interval_type, over, true)}</div>
              </S.TotalAmountContainer>
            </>
          )}
        </S.RadioContainer>
      </S.EndPeriodContainer>
      {isEnableTrialPeriod && (
        <S.FormGroup>
          <TrialSetting
            onChangeTrial={handleChangeTrialPeriod}
            onChangeToggle={handleSettingToggle}
            currentData={data}
            isOnetime={false}
          />
        </S.FormGroup>
      )}
    </S.Wrapper>
  );
};

const mapState = state => {
  const {
    rootReducer: { permission },
  } = state;

  return {
    permission,
  };
};

export default connect(mapState, null)(RecurringPayment);
