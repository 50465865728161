import styled, { css } from 'styled-components';
import { FormGroup as FG } from 'shared/FormControl';

export const FormGroup = styled(FG)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .label {
    flex: 0 0 57px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;

    .announcement-tooltip {
      padding: 15px;
      width: 226px;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-transform: none;
    }

    img {
      margin-left: 8px;
    }
  }

  .controls {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > * {
      margin-right: 5px;
    }

    .selectDayCalendar {
      .selectDayCalendar__trigger {
        background: #fff;
        border-color: transparent;
        padding: 8px 14px;

        :hover {
          border-color: #5158cf;
        }
      }

      &.open {
        .selectDayCalendar__trigger {
          background: #fff;
          border-color: #5158cf;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  background-color: #f5f5f5;
  padding: 15px 30px;
  width: 100%;
  z-index: 2;

  ${props =>
    props.sent &&
    css`
      padding-top: 18px;
      padding-bottom: 18px;
    `}

  .message__title {
    width: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: none;
    padding: 8px 15px;
    outline: none;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000000;

    ::placeholder {
      color: #b4b4b4;
    }

    :focus {
      border-color: #5c5bbd;
    }
  }

  ${FormGroup} {
    margin-bottom: 0;

    :first-child {
      margin-bottom: 10px;
    }
  }

  .__react_component_tooltip.app-tooltip-white {
    padding: 15px;
    width: 226px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    text-transform: none;
  }

  .custom-date-input {
    background-color: #fff;
    border-color: transparent;
    padding: 8px 15px;
    line-height: 18px;

    span {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  .rdt.new-ui {
    margin-right: 5px;
  }

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      background-color: #fff;
      border-color: transparent;

      :hover,
      &--is-focused {
        border-color: transparent;
      }
    }

    .evfSelectBox__menu {
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    .evfSelectBox__value-container {
      input {
        width: 1px;
      }

      .evfSelectBox__single-value {
        color: #000;
        font-weight: normal;
      }
    }
  }

  .inAppMessage__schedule__sent {
    font-size: 14px;

    span {
      font-weight: 600;
    }
  }

  &.inAppMessageForm__schedule {
    .disable-editting {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
    }
  }
`;
