import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import CalendarOverview from './CalendarOverview';
import WorkoutListOverview from './WorkoutListOverview';

import * as S from './style';
import { axiosInstance } from 'configs/request';

const WorkoutOverview = props => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [isExpandCalendar, setIsExpandCalendar] = useState(false);
  const [isTracked, setIsTracked] = useState(false);

  const onSelectDay = data => {
    setSelectedDay(data);
    if (isTracked) {
      getTrackedWorkout(data);
    }
  };

  const onExpandCalendar = isExpand => {
    setIsExpandCalendar(isExpand);
  };

  const toggleTracked = () => {
    setIsTracked(!isTracked);
    if (!isTracked) {
      getTrackedWorkout(selectedDay);
    }
  };

  const getTrackedWorkout = data => {
    const promises = data.workouts.map(w => getWorkoutDetail(w));

    Promise.all(promises).then(results => {
      setSelectedDay({ ...data, workouts: results });
    });
  };

  const getWorkoutDetail = workout => {
    if (workout.status !== 2) {
      return workout;
    }
    return axiosInstance.get(`/api/workout/v2/assignment/detail/${workout._id}`).then(response => {
      const { data } = response.data;
      return _.merge(workout, data);
    });
  };

  return (
    <S.Wrapper>
      <S.Heading>Workout Schedule</S.Heading>
      <CalendarOverview
        onSelectDay={onSelectDay}
        onExpand={onExpandCalendar}
        fetchWorkoutsInRange={props.fetchWorkoutsInRange}
        fetchWorkoutsByWeek={props.fetchWorkoutsByWeek}
        isWeekCalendar={props.isWeekCalendar}
        maxWeeks={props.maxWeeks}
        defaultDay={props.day}
      />
      {selectedDay ? (
        <S.DayHeading>
          <span>{props.isWeekCalendar ? `Day ${selectedDay.day + 1}` : moment(selectedDay.day).format('ll')}</span>
          {props.hasTrackedWorkout ? (
            <S.TrackedIcon isTracked={isTracked} onClick={toggleTracked}>
              Workout History
            </S.TrackedIcon>
          ) : null}
        </S.DayHeading>
      ) : null}
      <WorkoutListOverview
        isExpandCalendar={isExpandCalendar}
        isWeekCalendar={props.isWeekCalendar}
        selectedDay={selectedDay}
        isTracked={isTracked}
      />
    </S.Wrapper>
  );
};

export default WorkoutOverview;
