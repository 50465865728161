import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import PopUpForm from '../CreatePostForm/components/PopUpForm';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { ReactComponent as CloseIcon } from 'assets/icons/close_thin.svg';
import * as S from './style';

export default function component(props) {
  const { push, toggleModal, permission, hideScheduleBanner, group, bannerStatus, enabledAddons } = props;
  const [isHideBanner, setHideBanner] = useState(false);

  useEffect(() => {
    setHideBanner(bannerStatus);
  }, [bannerStatus]);

  const hideBanner = () => {
    setHideBanner(true);
    const groupID = get(group, '_id', '');
    hideScheduleBanner && hideScheduleBanner({ group_id: groupID });
  };

  const handleTogglePopup = () => {
    toggleModal(true, <PopUpForm {...props} isSchedulePost={true} firstCreate />);
  };

  const handleConnectAutoflow = () => {
    push(`/home/autoflow`, { index: 1 });
  };

  if (!permission.autoflow && !enabledAddons.automation) {
    return (
      <S.SchedulePostWrapper>
        <div className="schedule-post-content">
          <S.SchedulePostImage createPost={!!permission.autoflow} />
          <S.Title>Schedule a Post</S.Title>
          <S.SubTitle>Create scheduled posts to engage your community</S.SubTitle>
          <S.ConnectToAutoflowBtn textOnly onClick={handleConnectAutoflow}>
            <UpgradePathButton
              data-btn="Get Autoflow"
              text="Get Autoflow"
              className="button--upgrade-path"
              isPreventDefault={true}
            />
          </S.ConnectToAutoflowBtn>
        </div>
      </S.SchedulePostWrapper>
    );
  }

  return !isHideBanner ? (
    <S.SchedulePostWrapper>
      <div className="schedule-post-content">
        <S.SchedulePostImage createPost={!!permission.autoflow} />
        <CloseIcon className="button--close-banner" data-btn-close="close_banner" onClick={hideBanner} />
        <S.Title>Schedule a Post</S.Title>
        <S.SubTitle>Create scheduled posts to engage your community</S.SubTitle>
        <S.ConnectToAutoflowBtn data-btn="create-schedule-post" textOnly onClick={handleTogglePopup}>
          Create your post
        </S.ConnectToAutoflowBtn>
      </div>
    </S.SchedulePostWrapper>
  ) : (
    <></>
  );
}
