import React from 'react';
import Body from './Body';
import Footer from './Footer';
import { ListWrapper } from './style';

const PurchaseList = ({ isMP }) => {
  return (
    <ListWrapper>
      <Body isMP={isMP} />
      <Footer isMP={isMP} />
    </ListWrapper>
  );
};

export default PurchaseList;
