import styled from 'styled-components';

export const ActionPasteWeek = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 60px);
  background: rgba(108, 115, 154, 0.1);
  z-index: 1;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  display: none;
  user-select: none;
`;

export const PasteButton = styled.div`
  width: 247px;
  height: 34px;
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  border-radius: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #ffffff;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`;
