import styled, { css } from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';

export const PauseWrapper = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 482px !important;
    &.new-style {
      .confirm-content-header {
        padding: 30px 30px 13px 30px;
        > img {
          margin-right: 3px;
        }
      }
      .confirm-content-body {
        padding: 0 30px;
      }
      .confirm-actions {
        padding: 30px;
        .ui.button {
          padding: 5px 21px;
          font-weight: 600;
          text-align: center;
          font-size: 13px;
          letter-spacing: 0.35px;
        }
        .confirm-no-button {
          padding: 5px 20px !important;
        }
        .confirm-yes-button {
          background-color: #ffc445;
          box-shadow: none;
        }
      }
      &.resume-option {
        .confirm-actions .confirm-yes-button {
          background-color: #49ca8d;
        }
      }
    }
  }
`;
export const Wrapper = styled.div`
  .evf-checkbox-circle {
    padding-bottom: 10px;
    position: relative;
    > div {
      margin-right: 6px;
    }
    &.first {
      max-width: 190px;
    }
    &.first.pause-ref {
      max-width: 300px;
    }
    &.seconds {
      max-width: 300px;
    }
    &.seconds.pause-ref {
      max-width: 280px;
    }
  }
  .resume-tooltip {
    display: inline-block;
    width: 20px;
    height: 14px;
    padding-top: 1px;
    svg {
      max-width: 100%;
      max-height: 100%;
      g {
        opacity: 1;
      }
      path {
        fill: #9a9da6;
      }
    }
  }
  .__react_component_tooltip.place-top {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: -2px;
    margin-left: 3px;
    &:after {
      margin-left: -9px;
    }
  }
`;

export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px !important;
  color: #202353;
  margin-bottom: 16px !important;
`;

export const Option = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  &.has-tooltip {
    display: flex;
    .resume-tooltip {
      padding-left: 4px;
    }
  }
  .custom-calendar {
    display: inline-block;
    margin-left: 8px;
    &.resume {
      .rdtPicker {
        box-shadow: -6px 4px 16px rgb(0 0 0 / 10%);
      }
    }
    .rdtPicker {
      margin-top: 10px;
      width: 340px;
      border: 1px solid #eceef4;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 5px;
      padding-bottom: 12px;
      tbody tr:last-child {
        .rdtDay {
          &:after {
            padding-bottom: 0;
          }
          &:hover {
            background-color: initial;
            color: #5a636c;
            &:before {
              top: 1px;
            }
          }
        }
        .rdtDay.rdtActive:before {
          top: 1px;
        }
      }
      tbody:before {
        content: '';
        display: block;
        height: 15px;
      }
      th {
        border-bottom: none;
      }
      thead .dow {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        color: #202353;
      }
      .rdtDay {
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        color: #202353;
        width: 33px;
        height: 33px;
        position: relative;
        &:after {
          content: '';
          display: block;
          padding-bottom: 24px;
        }
        &:hover {
          background-color: initial;
          color: #5a636c;
          &:before {
            z-index: -1;
            width: 30px;
            height: 30px;
            background: #eee;
            content: ' ';
            display: inline-block;
            position: absolute;
            top: -8px;
            left: 4px;
            border-radius: 50%;
          }
        }
        &.rdtActive {
          font-weight: 700;
          color: rgb(255, 255, 255);
          background-color: initial;
          position: relative;
          &:before {
            z-index: -1;
            width: 30px;
            height: 30px;
            background-color: rgb(81, 88, 207);
            content: ' ';
            display: inline-block;
            position: absolute;
            top: -8px;
            left: 4px;
            border-radius: 50%;
          }
        }
        &.rdtToday {
          color: #5158cf;
          &.rdtDay.rdtActive {
            color: rgb(255, 255, 255);
          }
          &:before {
            content: none;
          }
          &.rdtActive::before {
            z-index: -1;
            width: 30px;
            height: 30px;
            background-color: rgb(81, 88, 207);
            content: ' ';
            display: inline-block;
            position: absolute;
            top: -8px;
            left: 4px;
            border-radius: 50%;
            border-left: 0;
            border-bottom: 0;
          }
        }
        &.rdtDisabled {
          opacity: 0.5;
          &.rdtToday {
            opacity: 1;
          }
        }
      }
    }
    .rdtSwitch {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 23px 0;
      color: #202353;
      &:hover {
        background-color: initial;
        color: #7470ef;
      }
    }
    .rdtPrev {
      padding-left: 6px;
      text-align: left;
    }
    .rdtNext {
      text-align: right;
      padding-right: 6px;
    }
    .rdtPrev,
    .rdtNext {
      color: #3d3d66;
      opacity: 0.3;
      font-size: 40px;
      font-weight: 100;
      padding-top: 18px;
      padding-bottom: 22px;
      &:hover {
        background-color: initial;
        color: #7470ef;
        opacity: 1;
      }
    }
  }
  .custom-date-input {
    width: 174px;
    height: 32px;
    background: #fafbfc;
    border: 1px solid #d4d7d9;
    border-radius: 5px;
    color: #000000;
    line-height: 18px;
    padding-left: 15px;
    &:hover {
      border: 1px solid #5158cf;
    }
  }
`;

export const SubLabel = styled.span`
  opacity: 0.5;
  font-weight: 400;
  padding-left: 1px;
`;

export const MoreOptionsWrapper = styled.div`
  width: 215px;
  height: 375px;
  position: absolute;
  right: -353px;
  top: 42px;
  background-color: #fff;
  box-shadow: 16px 4px 16px rgb(0 0 0 / 10%);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 17px;
  border: 1px solid #eceef4;
`;

export const MoreOption = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  padding: 8px 8px 8px 33px;
  margin-bottom: 4px;
  &:hover {
    color: #5158cf;
    background: #f6f5ff;
  }
  ${props =>
    props.active &&
    css`
      color: #5158cf;
      background: #f6f5ff;
    `}
`;
