import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .studio__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #202353;
    transform: translateY(-9px);
  }
`;
