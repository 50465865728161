import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const ExtendActions = styled.div`
  position: absolute;
  right: 35px;
  top: 24px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .__react_component_tooltip.app-tooltip-white {
    min-width: fit-content;
  }

  .ownershipWrapper {
    .action-menu__toggle-button {
      margin-right: 0;
      width: auto;

      .teammate-icon {
        width: 28px;
        height: 28px;
        border-radius: 5px;
      }
    }

    .ownership {
      right: -12px;
    }
  }

  .dropdown-wrapper {
    margin-left: 10px;
  }
  .tags-wrapper {
    margin-right: 10px;

    .exercise-filter {
      margin-right: 0;
    }
  }

  .evf-dropdown {
    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 28px;
      height: 28px;
      border-radius: 5px;

      .dots {
        background-color: #838d9f;
        width: 4px;
        height: 4px;

        :before {
          width: 4px;
          height: 4px;
          left: -6px;
        }

        :after {
          width: 4px;
          height: 4px;
          left: 6px;
        }
      }

      :hover {
        background-color: #ebedf3;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #ebedf3;

        .dots {
          background-color: #838d9f;
        }
      }
    }
  }
`;

export const Header = styled.div`
  position: relative;
  padding: 26px 145px 18px 25px;
  flex: 0;

  .text-editable__container {
    :not(.text-editable__container--active) {
      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        box-orient: vertical;
        overflow: hidden;
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1 1;
  overflow: auto;
  scroll-behavior: smooth;
  margin-right: 10px;
  padding-bottom: 20px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const DescriptionContainer = styled.div`
  width: 424px;
`;

export const DescriptionAndBackgroundContainer = styled.div`
  padding: 0 25px 30px 25px;
  display: flex;
  justify-content: space-between;

  label {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #202353;
    opacity: 0.4;
    margin-bottom: 4px;
    padding: 0 10px;
    display: block;
  }

  .text-editable__wrapper {
    .text-editable__container {
      padding-top: 4px;
      padding-bottom: 4px;

      .text-editable__input {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #202353;

        :empty::before {
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: #aeaebb;
          opacity: 1;
        }
      }

      :not(.text-editable__container--active) {
        .text-editable__input {
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          box-orient: vertical;
          overflow: hidden;
        }
      }

      &.text-editable__container--active {
        .text-editable__input {
          min-height: 40px;
        }
      }
    }
  }
`;

export const SectionListContainer = styled.div`
  padding: 0 25px 0px 35px;
`;

export const ActionFixedContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 38px;
  padding: 10px 35px;
  background-image: url(${CDN_URL}/images/arrow_up_purple.svg),
    linear-gradient(180deg, rgba(112, 114, 221, 0) 0%, #7072dd2b 100%);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  .btn-actions {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease;
  }

  :hover {
    background-color: #ffffff;
    background-image: none;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    opacity: 1;

    .btn-actions {
      max-height: 100px;
    }
  }
`;

export const AILoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  z-index: 3;

  .bg-blue {
    width: 112px;
    height: 92px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .blue-svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .indicator {
      padding-top: 0;

      .ui.small.loader {
        width: 31px;
        height: 31px;
      }

      .ui.loader::after,
      .ui.loader::before {
        width: 31px;
        height: 31px;
        margin: 0 0 0 -15px;
      }
      .ui.loader::before {
        border-color: rgba(81, 88, 207, 0.18);
      }
      .ui.loader:after {
        border-color: #5158cf transparent transparent;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .workoutError--noExercise {
    background: rgba(234, 49, 74, 0.05);
    border-radius: 4px;
    display: block;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  input.invalid {
    border-color: #ea314a;
    background-color: #fef4f6;
  }

  .mainPanel__sections {
    padding-bottom: 30px;
  }

  &.scrolling {
    ${Header} {
      padding-top: 7px;
      padding-bottom: 7px;

      .text-editable__input {
        font-size: 14px;
        line-height: 19px;
      }

      :before {
        content: '';
        position: absolute;
        left: 35px;
        right: 37px;
        bottom: 0;
        height: 1px;
        background-color: #dadfea;
      }
    }

    ${ExtendActions} {
      top: 11px;
    }

    ${Content} {
      padding-bottom: 20px;
    }
  }
`;
