import _ from 'lodash';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { Types } from './actions';
import { calculateMacroNutrients, getMacroNutrients } from 'components/MealPlanDayDetail/constants';
import { CLIENT_MEAL_PLAN_VIEW_MODE } from 'components/ClientMealPlan/constants';

const initState = {
  mealPlanByDay: {},
  loading: false,
  macro_nutrients: [],
  currentMealPlan: null,
  upcomingMealPlan: null,
  assignedMealPlanClients: [],
  assignedMealPlanClientsByRange: [],
  manualMealClients: [],
  listStatusMealPlanFormat: {
    loading: false,
    data: {},
  },
  loadingAction: false,
  viewMode: CLIENT_MEAL_PLAN_VIEW_MODE[0],
  isRearrangeMode: false,
  weekMealClients: [],
  weekNote: '',
  isLoadingWeek: false,
  rangeWeek: [],
};

const calculateNutrients = mealPlanByDay => {
  const list = get(mealPlanByDay, 'meal_group', []).map(item => ({
    ...item,
    recipes: (item.recipes || []).filter(({ is_disable, is_logged }) => !(is_disable && !is_logged)),
  }));

  if (list.length === 0) {
    return [];
  }
  const macroNutrients = calculateMacroNutrients(list);
  const listNutrients = getMacroNutrients(macroNutrients);

  return listNutrients.map(({ type = '', value = '' }) => ({ type, value }));
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_REQUEST:
      return Object.assign({}, state, { loading: true });

    case Types.CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_SUCCESS:
      return Object.assign({}, state, {
        mealPlanByDay: payload,
        loading: false,
        macro_nutrients: calculateNutrients(payload),
      });

    case Types.CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_ERROR:
      return Object.assign({}, state, { loading: false });

    case Types.CLIENT_MEAL_PLAN_UPDATE_NOTE_REQUEST:
    case Types.CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case Types.CLIENT_MEAL_PLAN_UPDATE_NOTE_SUCCESS:
      const { note } = payload;

      return Object.assign({}, state, {
        mealPlanByDay: { ...state.mealPlanByDay, note: note },
        loading: false,
      });

    case Types.CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_SUCCESS:
      const { weekNote } = payload;

      return Object.assign({}, state, {
        weekNote: weekNote,
        loading: false,
      });

    case Types.CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });

    case Types.CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_SUCCESS: {
      const { data } = payload || {};
      const { assigned_meal_plan_clients = [], assigned_meal_plan_clients_by_range = [], manual_meal_clients = [] } =
        data || {};
      return Object.assign({}, state, {
        assignedMealPlanClients: assigned_meal_plan_clients,
        assignedMealPlanClientsByRange: assigned_meal_plan_clients_by_range,
        manualMealClients: manual_meal_clients,
      });
    }

    case Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_SUCCESS: {
      const { data } = payload || {};
      return Object.assign({}, state, {
        mealPlanByDay: data,
      });
    }

    case Types.CLIENT_MEAL_PLAN_CALENDAR_RESET_CLIENT_TAB: {
      const data = omit(initState, ['listStatusMealPlanFormat']);
      return Object.assign({}, state, data);
    }

    case Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_DAY:
      const { data } = payload;
      return Object.assign({}, state, {
        mealPlanByDay: data,
        loading: false,
        macro_nutrients: calculateNutrients(data),
      });

    case Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_WEEK:
      const { dataDayOfWeek } = payload || {};
      const newWeekMealClients = [...state.weekMealClients, dataDayOfWeek];
      const weekMealClientsGroupByDay = _(newWeekMealClients)
        .groupBy('day')
        .map(items => _.merge({}, ...items))
        .value();

      return Object.assign({}, state, {
        weekMealClients: weekMealClientsGroupByDay,
        loading: false,
      });

    case Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_REQUEST: {
      const listStatusMealPlanFormat = { ...state.listStatusMealPlanFormat, loading: true };
      return Object.assign({}, state, {
        listStatusMealPlanFormat,
      });
    }

    case Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_SUCCESS: {
      const { data } = payload || {};
      const listStatusMealPlanFormat = { ...state.listStatusMealPlanFormat, data, loading: false };
      return Object.assign({}, state, {
        listStatusMealPlanFormat,
      });
    }

    case Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_FAILED: {
      const listStatusMealPlanFormat = { ...state.listStatusMealPlanFormat, loading: false };
      return Object.assign({}, state, {
        listStatusMealPlanFormat,
      });
    }

    case Types.CLIENT_MEAL_PLAN_UPDATE_LIST_STATUS_MEAL_PLAN_FORMAT: {
      const { data } = payload;
      const listStatusMealPlanFormat = {
        ...state.listStatusMealPlanFormat,
        data: { ...state.listStatusMealPlanFormat.data, ...omit(data, ['client']) },
        loading: false,
      };
      return Object.assign({}, state, {
        listStatusMealPlanFormat,
      });
    }

    case Types.CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_REQUEST:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_REQUEST:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_REQUEST:
      return Object.assign({}, state, { loadingAction: true });

    case Types.CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_FAILED:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_FAILED:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_FAILED:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_SUCCESS:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_SUCCESS:
    case Types.CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_SUCCESS:
      return Object.assign({}, state, { loadingAction: false });

    case Types.CLIENT_MEAL_PLAN_CHANGE_VIEW_MODE:
      const { item } = payload || {};
      const isChangeWeekToDayView = item.key === 'day' && state.viewMode.key === 'week';

      return Object.assign({}, state, {
        viewMode: item,
        isRearrangeMode: isChangeWeekToDayView ? false : state.isRearrangeMode,
      });

    case Types.CLIENT_MEAL_PLAN_TOGGLE_REARRANGE_MODE:
      return Object.assign({}, state, { isRearrangeMode: !payload.status });

    case Types.CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_REQUEST:
    case Types.CLIENT_MEAL_PLAN_REARRANGE_REQUEST:
      return Object.assign({}, state, { isLoadingWeek: true });

    case Types.CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_SUCCESS:
      return Object.assign({}, state, {
        weekMealClients: payload.mealClients,
        weekNote: payload.note,
        isLoadingWeek: false,
      });

    case Types.CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_FAILED:
    case Types.CLIENT_MEAL_PLAN_REARRANGE_FAILED:
      return Object.assign({}, state, { isLoadingWeek: false });

    case Types.CLIENT_MEAL_PLAN_REARRANGE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingWeek: false,
      });

    case Types.CLIENT_MEAL_PLAN_SET_CURRENT_RANGE_WEEK:
      const { rangeWeek } = payload;
      return Object.assign({}, state, {
        rangeWeek,
      });

    default:
      return state;
  }
};
