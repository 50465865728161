import React, { useMemo, useRef, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Radio } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

import { NewSearchInput } from 'shared/SearchInput';
import { ReactComponent as ForumAllIcon } from 'assets/icons/forum-all.svg';
import LoadingIndicator from 'shared/LoadingIndicator';

import * as S from './style';

const ForumFilter = props => {
  const { filterForum, setFilterForum, forums, loading = false } = props;

  const [open, setOpen] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const inputRef = useRef();

  const selectedForumIds = useMemo(() => get(filterForum, '_id'), [filterForum]);
  const filterForums = useMemo(() => {
    const search = textSearch.trim().toLowerCase();
    return filter(forums, it => it.name.toLowerCase().includes(search) && selectedForumIds !== it._id);
  }, [forums, textSearch, selectedForumIds]);
  const hiddenScrollBar = useMemo(() => (isEmpty(textSearch) ? filterForums.length <= 4 : filterForums.length <= 3), [
    filterForums,
    textSearch,
  ]);

  const handleChangeInput = event => {
    event.preventDefault();
    setTextSearch(get(event, 'target.value', ''));
  };

  const handleClose = () => {
    setOpen(false);
    setTextSearch('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleItemClick = forumId => {
    setFilterForum(forumId);
    handleClose();
  };

  const renderBanner = ({ forum, isSelected = null, isForumSelected = false, short = false }) => {
    const isBannerBackground = get(forum, 'banner_thumbnail');
    const forumName = get(forum, 'name');
    const isItemSelected = !isNull(isSelected);

    return (
      <S.ItemForumContent>
        <S.ImageBanner
          isBackground={isBannerBackground}
          className={classNames('forum-banner default-bg', {
            'is-selected': isItemSelected,
          })}
        />
        <span className={classNames('forum-name', { 'is-selected': isItemSelected || isForumSelected, short: short })}>
          {forumName}
        </span>
      </S.ItemForumContent>
    );
  };

  const renderItem = forum => {
    const forumId = get(forum, '_id');
    const isForumSelected = filterForum === forumId;

    return (
      <S.SelectForumOption key={forumId} onClick={() => handleItemClick(forumId)}>
        {renderBanner({ forum, isForumSelected })}
        <S.RadioButton>
          <Radio checked={isForumSelected} />
        </S.RadioButton>
      </S.SelectForumOption>
    );
  };

  return (
    <RootCloseWrapper onRootClose={handleClose} event="click" disabled={!open}>
      <S.Wrapper>
        <div
          className={classNames('filter__dropdown', { active: open, 'is-selected': filterForum })}
          onClick={() => setOpen(!open)}
        >
          <div className="filter__dropdown__title">
            {filterForum ? (
              renderBanner({
                forum: find(forums, item => item._id === filterForum),
                isSelected: filterForum,
                short: true,
              })
            ) : (
              <>
                <span className="filter-name">Community Forum</span>
                <span className="colons">:</span>
                <span className="selected">All</span>
              </>
            )}
          </div>
        </div>
        {open && (
          <S.MenuContainer>
            <S.SearchBar>
              <NewSearchInput
                placeholder="Search by name"
                ref={inputRef}
                onChange={handleChangeInput}
                onClearSearch={handleChangeInput}
                autoFocus
              />
            </S.SearchBar>
            <S.ForumWrapper hiddenScrollBar={hiddenScrollBar}>
              {!isEmpty(filterForums) && isEmpty(textSearch) && (
                <S.SelectForumOption onClick={() => handleItemClick(null)}>
                  <S.ItemForumContent>
                    <ForumAllIcon className="forum-icon" />
                    <span className={classNames('forum-name all-forum', { 'is-selected': isNull(filterForum) })}>
                      All community forums
                    </span>
                  </S.ItemForumContent>
                  <S.RadioButton>
                    <Radio checked={isNull(filterForum)} />
                  </S.RadioButton>
                </S.SelectForumOption>
              )}
              {!isEmpty(filterForums) && map(filterForums, renderItem)}
              {isEmpty(filterForums) && !loading && (
                <S.EmptyResultFound>{!!textSearch ? 'No results found.' : 'No forums found.'}</S.EmptyResultFound>
              )}
              {loading && (
                <S.LoadingBox>
                  <LoadingIndicator className="forum-loading" />
                </S.LoadingBox>
              )}
            </S.ForumWrapper>
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

export default ForumFilter;
