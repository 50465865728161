import React, { useEffect, useState } from 'react';
import { Button as CloseButton } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { concat, get, find } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from 'shared/FormControl';
import { ReactComponent as CloseCircleIcon } from 'assets/icons/close_circle.svg';
import DefaultBackground from 'assets/images/form-welcome-screen-default.png';
import { getPresignedUploadUrl, createObjectURL, revokeObjectURL, mediaLog } from 'utils/commonFunction';
import { ReactComponent as UploadIcon } from 'assets/icons/upload_banner_icon.svg';
import { ReactComponent as EditBannerIcon } from 'assets/icons/edit_banner_icon.svg';
import { ReactComponent as InfoGrayIcon } from 'assets/icons/info_gray_in.svg';
import FileUpload from 'shared/FileUpload';
import { validateFiles } from 'utils/validations';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import {
  saveBackgroundToLibrary,
  removeBackgroundLibraryImage,
  updatePartialQuestion,
} from 'redux/form-details/actions';

import * as S from './style';

const FORM_BUILDER_UPLOAD_URL = '/api/file/gen-presigned-urls-form-questionnaires';

const VALID_EXTENSIONS = ['png', 'jpeg', 'jpg'];

const getSelectedImage = (selectedBackground, imageLibrary) => {
  if (!selectedBackground) return null;
  const list = get(imageLibrary, 'list', []);
  return find(list, o => o.background === selectedBackground) || null;
};

function ImageLibraryModal(props) {
  const {
    toggleModal,
    getBackgroundLibrary,
    saveBackgroundToLibrary,
    imageLibrary,
    removeBackgroundLibraryImage,
    updatePartialQuestion,
    workingQuestion,
    onUploadImageChange,
    toggleConfirmRemoveImageModal,
  } = props;
  const [file, setFile] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);
  const selectedImageUrl = get(props, 'workingQuestion.background_image', null);
  const [attachmentUrl, setAttachmentUrl] = useState(selectedImageUrl);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const originalAttachment = selectedImageUrl;
  const [selectedImage, setSelectedImage] = useState(getSelectedImage(selectedImageUrl, imageLibrary));
  const notChanged = get(selectedImage, 'background', null) === originalAttachment;
  useEffect(() => {
    getBackgroundLibrary();
  }, []);
  useEffect(() => {
    setSelectedImage(getSelectedImage(selectedImageUrl, imageLibrary));
  }, [selectedImageUrl]);
  const defaultImage = { _id: 'default', isDefault: true, background: DefaultBackground };
  const handleClose = (forceClose = false) => () => {
    if (notChanged || forceClose) {
      toggleModal(false);
    } else {
      // toggleConfirm
      toggleConfirmRemoveImageModal(() => toggleModal(false));
    }
  };
  const handleSelectFile = async file => {
    if (file) {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
    }
    setFile(file);
    if (localUrl) {
      revokeObjectURL(localUrl);
    }
    setLocalUrl(createObjectURL(file));
    const { configs: fileInfo, uploadUrl } = await getPresignedUploadUrl(FORM_BUILDER_UPLOAD_URL, file);

    // props.startUploadCoverImage();
    setIsUploading(true);
    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });
    setAttachmentUrl(fileInfo.url);
  };
  const handleDropFile = files => {
    if (files.length === 0) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: VALID_EXTENSIONS,
        maxSize: 25 * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = 'File type not supported.';
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = 'Image size limit is 25MB.';
        }

        toast.error(message);
      } else {
        handleSelectFile(file);
      }
    }
  };
  const handleImageUrl = () => {
    return localUrl || null;
  };
  const handleSuccess = async () => {
    if (file) {
      const { size, name, type } = file;
      await mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success a file to S3',
      });
      setFile(null);
    }
    saveBackgroundToLibrary(attachmentUrl, data => {
      setSelectedImage(data);
    });
    setUploadConfigs(null);
    setIsUploading(false);
    // props.endUploadCoverImage();
  };

  const handleCancel = () => {
    setFile(null);
    setLocalUrl(null);
    setUploadConfigs(null);
    setAttachmentUrl(originalAttachment);
    setIsUploading(false);
    // props.endUploadCoverImage();
  };

  const handleRemove = image => e => {
    e.stopPropagation();
    setFile(null);
    setLocalUrl(null);
    setAttachmentUrl(null);
    setUploadConfigs(null);
    setSelectedImage(null);
    if (image.background === selectedImageUrl) {
      // set background to default and remove background from library
      removeBackgroundLibraryImage(image._id, () => {
        updatePartialQuestion &&
          updatePartialQuestion({
            _id: workingQuestion._id,
            type: workingQuestion.type,
            background_image: null,
            background_image_thumbnail: null,
          });
      });
    } else {
      removeBackgroundLibraryImage(image._id);
    }
  };

  const handleSelectImage = image => () => {
    if (image.background === get(selectedImage, 'background') || (image.isDefault && selectedImage === null)) {
      return;
    }
    if (image.isDefault) {
      setSelectedImage(null);
    } else {
      setSelectedImage(image);
    }
  };

  const handleSave = () => {
    if (selectedImage === null) {
      updatePartialQuestion &&
        updatePartialQuestion(
          {
            _id: workingQuestion._id,
            type: workingQuestion.type,
            background_image: null,
            background_image_thumbnail: null,
          },
          handleClose(true),
        );
    } else {
      onUploadImageChange(
        { url: selectedImage.background, thumbnail_url: selectedImage.background_thumbnail },
        handleClose(true),
      );
    }
  };

  const images = get(imageLibrary, 'list', []);

  return (
    <S.Modal
      open={true}
      closeOnDimmerClick={false}
      onClose={handleClose(true)}
      className="form-builder-image-library"
      closeIcon={
        <CloseButton className="close-button">
          <CloseCircleIcon />
        </CloseButton>
      }
    >
      <S.Modal.Header>
        <div>Choose background</div>
      </S.Modal.Header>
      <div className="tabs-container">
        <div className="tab">Upload</div>
      </div>
      <S.Modal.Content>
        <div className="images-library">
          {concat(defaultImage, images).map(image => (
            <div className="image-container" onClick={handleSelectImage(image)}>
              <S.Image
                background={image.background}
                selected={
                  image.isDefault && selectedImage === null
                    ? true
                    : image.background === get(selectedImage, 'background')
                }
              />
              {!image.isDefault && !isUploading && <S.RemoveIcon onClick={handleRemove(image)} />}
            </div>
          ))}
          {images.length < 1 && (
            <Dropzone onDrop={handleDropFile} multiple={false}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <S.BannerWrapper src={handleImageUrl()} {...getRootProps()} isDragging={isDragActive}>
                  {attachmentUrl && !isUploading && <S.RemoveIcon onClick={handleRemove} />}
                  {/* {(!attachmentUrl || props.isSubmitting) && <UploadIcon />} */}
                  {uploadConfigs ? (
                    <FileUpload
                      onSuccess={handleSuccess}
                      onError={() => {}}
                      onCancel={handleCancel}
                      configs={uploadConfigs}
                    />
                  ) : (
                    <S.SelectBanner
                      trigger={
                        <S.UploadContainer>
                          {!file && !isUploading && !attachmentUrl && (
                            <>
                              <S.UploadIcon />
                              <div className="upload-content">
                                Drag and drop an image here or <span>Choose file</span>
                              </div>
                            </>
                          )}
                        </S.UploadContainer>
                      }
                      isDragging={isDragActive}
                      onSelect={handleSelectFile}
                      accept="image/png, image/jpeg, image/jpg, image/*'"
                      validateExtentions={VALID_EXTENSIONS}
                      maxSize={25}
                      fileSizeLimitMessage={'Image size limit is 25MB.'}
                    />
                  )}
                </S.BannerWrapper>
              )}
            </Dropzone>
          )}
        </div>
      </S.Modal.Content>
      <S.Modal.Actions className="library-action">
        <div className="left-info">
          <InfoGrayIcon />
          <p className="info-text">
            Png or jpg file. Width-to-height ratio should be <b>3:5</b> and width should be at least <b>750px</b> for
            best resolution.
          </p>
        </div>
        <Button className="save-btn" purple disabled={imageLibrary.loading || notChanged} onClick={handleSave}>
          Save
        </Button>
      </S.Modal.Actions>
    </S.Modal>
  );
}

// File size should be less than 25mb and only png, pdf formats are supported.

const mapState = state => {
  return {
    imageLibrary: state.rootReducer.formDetails.imageLibrary,
    workingQuestion: state.rootReducer.formDetails.workingQuestion,
  };
};

const mapDispatch = dispatch => {
  return {
    saveBackgroundToLibrary: bindActionCreators(saveBackgroundToLibrary, dispatch),
    removeBackgroundLibraryImage: bindActionCreators(removeBackgroundLibraryImage, dispatch),
    updatePartialQuestion: bindActionCreators(updatePartialQuestion, dispatch),
  };
};

export default connect(mapState, mapDispatch)(ImageLibraryModal);
