import React from 'react';
import _ from 'lodash';
import { ProgressBarWrapper, ProgressSlider, ProgressDot, ProgressQuantity } from './style';

export default function ProcessBar(props) {
  const { maxClients = 100, options, noClients } = props;
  const dots = _.initial(options);
  const sliderValue = options.findIndex(o => o.key === noClients);

  const onChange = (e) => {
    props.onChange(options[e.target.value]);
  };

  return (
    <ProgressBarWrapper dotsLength={dots.length}>
      <ProgressSlider type="range" min="0" max={dots.length} value={sliderValue} step={1} onChange={onChange} className="slider"/>
      {dots.map((option, index) => (
        <ProgressDot isFirst={index === 0} isLast={index === dots.length - 1} isOver={option.key >= noClients} />
      ))}
      {dots.map((option, index) => (
        <ProgressQuantity isOver={option.key >= noClients} >
          <span className="dotValue">{option.key}</span>
          {(dots.length - 1) === index ? (<span className="dotValue--lastItem">{maxClients}</span>) : null}
        </ProgressQuantity>
      ))}
    </ProgressBarWrapper>
  )
}