import styled, { css } from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

export const NoAccessPayment = styled.div`
  display: flex;
  width: 100vw;
  background: #fafbfc;
  padding-top: 30px;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Item = styled.div``;

export const DefaultFilterImage = styled.img`
  width: 204px;
`;

export const DefaultImage = styled.img`
  width: 631px;
  margin-left: 73px;
`;

export const NoAccessBox = styled.div`
  display: flex;
  width: 631px;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-left: 73px;
`;

export const NoAccessTitle = styled.div`
  color: #000;
  font-size: 26px;
  line-height: 39px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;

export const NoAccessDescription = styled.div`
  display: block;
  color: #000;
  width: 444px;
  margin: auto;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const SUpgradePathButton = styled(UpgradePathButton)`
  padding: 13.5px 27px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
