import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import { Modal } from 'semantic-ui-react';
import { useStripe } from '@stripe/react-stripe-js';

import { WebSocketContext } from 'libs/socket';
import PaymentInfo from './PaymentInfo';
import PaymentMethod from './PaymentMethod';
import ProcessingPaymentPopup from 'components/PricingPlan/ConfirmPayment/ProcessingPaymentPopup';
import PopupPaymentSuccess from 'components/PricingPlan/ConfirmPayment/PopupPaymentSuccess';
import { SOCKET_EVENTS } from 'libs/socket/constants';
import { CDN_URL } from 'constants/commonData';
import { axiosInstance } from 'configs/request';
import UpdatePaymentConfirm from './UpdatePaymentConfirm';

const checkCardExpired = paymentInfo => {
  if (!paymentInfo || !paymentInfo.payment_method_id) {
    return false;
  }

  const expiredDate = moment()
    .month(paymentInfo.exp_month - 1) // Months are zero indexed, so January is month 0.
    .year(paymentInfo.exp_year)
    .endOf('month');

  return moment().isAfter(expiredDate);
};

const ConfirmPayAnInvoiceModal = props => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const { socket } = useContext(WebSocketContext);

  const toggleChangeCard = () => {
    if (props.isFullBillingHistory) {
      props.toggleModal(false);
      props.toggleSecondModal(false);
      props.togglePaymentInformation(true, props.billingInfo);
    } else {
      props.onClose();
      props.togglePaymentInformation(true, props.billingInfo);
    }
  };

  useEffect(() => {
    socket.on(SOCKET_EVENTS.PaymentAuthenUpdate, data => {
      if (data) {
        setIsSuccess(true);
      }
    });
  }, []);

  const handlePay = async e => {
    e.preventDefault();
    const { billingInfo } = props;
    const body = {
      paymentIntentId: billingInfo.payment_intent,
      invoiceId: billingInfo.id,
    };
    setIsProcessing(true);
    setError('');
    axiosInstance
      .post('/api/pricing/pay-an-invoice', body)
      .then(async ({ data }) => {
        if (data && data.success) {
          setIsProcessing(false);
          setIsSuccess(true);
        } else {
          const { client_secret } = data;
          const cardAction = await stripe.confirmCardPayment(client_secret);
          if (cardAction && cardAction.error) {
            const errMsg = _.get(cardAction, 'error.message');
            setIsProcessing(false);
            setError(errMsg);
          }
        }
      })
      .catch(err => {
        const errorMessage = _.get(err, 'response.data.message');
        setError(errorMessage);
        setIsProcessing(false);
      });
  };

  const onClose = () => {
    props.onClose();
    props.toggleConfirmModal(true, <UpdatePaymentConfirm />);
  };

  const onCloseSuccessPopup = () => {
    props.onClose();
    props.toggleModal(false);
    props.toggleSecondModal(false);
    props.getBillingOverviewData();
    props.initPricingData();
  };

  const isExpiredCard = checkCardExpired(props.paymentInfo);

  return (
    <S.ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        {!!error && <S.ErrorContainer>{error}</S.ErrorContainer>}
        <ModalLayout.Content>
          <S.ContentWrapper>
            <S.PaymentMethodContainer>
              <div className="payment-method__heading">Confirm Payment</div>
              <PaymentMethod toggleChangeCard={toggleChangeCard} />
            </S.PaymentMethodContainer>
            <S.PaymentInfoContainer>
              <PaymentInfo billingInfo={props.billingInfo} onPay={handlePay} isExpiredCard={isExpiredCard} />
            </S.PaymentInfoContainer>
          </S.ContentWrapper>
          {isProcessing && <ProcessingPaymentPopup />}
          {isSuccess && <PopupPaymentSuccess onClose={onCloseSuccessPopup} />}
        </ModalLayout.Content>
      </Modal.Content>
    </S.ModalWrapper>
  );
};

export default ConfirmPayAnInvoiceModal;
