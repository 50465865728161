import React, { useState } from 'react';
import classNames from 'classnames';

// components
import { Button } from 'shared/FormControl';
import CheckBox from 'shared/CheckBox';

// assets
import headerImg from 'assets/images/ai/beta-agreement.png';
import { ReactComponent as LetStartBg } from 'assets/icons/ai/let-start-bg.svg';
import * as S from './style';

const TERMS = [
  'You understand that this feature is still in development and that Everfit reserves the right to make changes to how it looks.',
  'You shall complete a Feedback Form to share your experience with AI Workout Builder Beta.',
];

const AIWelcome = ({ onLetStartClick = () => {}, updateFlagBetaAgreement, onSubmitSuccess }) => {
  const [showSubmitScreen, setShowSubmitScreen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);

  const handleNext = async () => {
    if (!showSubmitScreen) {
      setShowSubmitScreen(true);
      return;
    }

    if (!isAgreed) {
      setIsError(true);
      return;
    }

    try {
      await updateFlagBetaAgreement();
      setShowCongrats(true);
      onSubmitSuccess();
    } catch (error) {}
  };

  const renderFistContent = () => {
    return (
      <div>
        <p>
          We’re entering a bold new chapter to revolutionize fitness coaching! The future of fitness lies in the perfect
          blend of technology and human connection. Everfit streamlines tasks and delivers instant, data-driven
          insights, allowing coaches to focus on transforming lives.
        </p>
        <p>
          Our mission is to empower coaches with AI-driven tools that unlock new possibilities and bring advanced
          fitness intelligence to millions. This is just the beginning. We are committed to making Everfit the best and
          only coaching platform you will ever need to help turn ambitions into reality! Join us on this journey as we
          redefine the future of fitness coaching, one supercharged intelligent solution at a time.
        </p>
      </div>
    );
  };

  const renderSubmitContent = () => {
    return (
      <div>
        <p>By entering your email, you agree to join the Beta Program for AI Workout Builder and agree that:</p>
        <ol>
          {TERMS.map((text, tIndex) => (
            <li key={tIndex}>
              <p>{text}</p>
            </li>
          ))}
        </ol>
        <CheckBox
          title={
            <>
              By ticking, you are confirming that you have read, understood and agree to{' '}
              <a href="https://everfit.io/tos" target="_blank" rel="noopener noreferrer">
                Everfit terms and conditions
              </a>
              .
            </>
          }
          checked={isAgreed}
          onChange={() => setIsAgreed(!isAgreed)}
          className={classNames('beta-cb', { error: isError })}
        />
      </div>
    );
  };

  const renderCongrats = () => {
    return (
      <div>
        <p>Now you can try our new workout builder with AI integration</p>
        <div className="let-start-btn" onClick={onLetStartClick}>
          <LetStartBg />
          <span>Let’s start!</span>
        </div>
      </div>
    );
  };

  return (
    <S.Wrapper>
      <img src={headerImg} alt="beta agreement" className="beta-img" />
      <div
        className={classNames('beta-content', {
          'submit-beta-content': showSubmitScreen,
          'congrats-content': showCongrats,
        })}
      >
        <h2>
          {showCongrats ? 'Congratulations, you are in!' : 'Welcome To Everfit’s AI Workout Builder Beta Program!'}
        </h2>
        {showCongrats ? renderCongrats() : showSubmitScreen ? renderSubmitContent() : renderFistContent()}
        {!showCongrats && (
          <div className="beta-footer">
            <Button purple onClick={handleNext} className={classNames('next-btn', { 'submit-btn': showSubmitScreen })}>
              {showSubmitScreen ? 'Submit' : 'Next'}
            </Button>
          </div>
        )}
      </div>
    </S.Wrapper>
  );
};

export default AIWelcome;
