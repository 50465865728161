// Lib
import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

// Constants
import { getMacroNutrients } from 'components/MealPlanDayDetail/constants';
import { conditionalRoundNutrition, handleEmptyValue } from 'components/RecipeDetail/helper';

// Shared
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// Utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';
import { ReactComponent as ReplaceIcon } from 'assets/icons/MealPlans/action_replace.svg';
import { ReactComponent as LoggedMeal } from 'assets/icons/MealPlans/logged_meal.svg';

import * as S from './style';

const RecipeItems = props => {
  const {
    recipe,
    isShowDietary,
    isShowRemoveIcon = false,
    onRemoveRecipe,
    isFromClient = false,
    sideBarVisible,
    showDesc = true,
    hasMoreOption = false,
    handleReplace,
    handleRemove,
    isLogged = false,
    mealClientCompleted = false,
    cloudfrontList,
    listDietary = [],
    isDisable = false,
  } = props;
  const macroNutrients = recipe.macro_nutrients || [];
  const nutrients = macroNutrients.length > 0 ? getMacroNutrients(macroNutrients) : [];
  const dietaries = recipe.dietaries || [];

  const closeDropdownRef = useRef();

  const renderMoreOption = () => {
    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => (
          <MenuTrigger className="dropdown-trigger" vertical active={!!open} itemId={recipe && recipe._id} />
        )}
        direction="left"
        isNewSpacing
        closeDropdownRef={closeDropdownRef}
      >
        <Option key="replace" onClick={handleReplace}>
          <div className="icon">
            <ReplaceIcon />
          </div>
          <span>Replace</span>
        </Option>
        <Option key="delete" onClick={handleRemove}>
          <div className="icon">
            <RemoveIcon />
          </div>
          <span>Remove</span>
        </Option>
      </DropDown>
    );
  };

  const handleMouseleave = () => {
    if (hasMoreOption) {
      closeDropdownRef.current && closeDropdownRef.current();
    }
  };

  return (
    <S.MainPanelItemWrapper
      isFromClient={isFromClient}
      sideBarVisible={sideBarVisible}
      onMouseLeave={handleMouseleave}
      isDisable={isDisable}
    >
      {isShowRemoveIcon && <CloseIcon onClick={event => onRemoveRecipe(event, recipe)} className="close-icon" />}
      <S.ItemImage src={[recipe.cover_image_thumbnail, recipe.cover_image]} />
      <S.ItemInfo>
        <S.ItemTitle className="recipe-name">{recipe.name}</S.ItemTitle>
        <S.ListNutrition>
          {nutrients.length > 0
            ? nutrients.map(item => {
                const { label, unit, value, id } = item;
                return (
                  <S.ItemNutrition key={id} hasMoreOption={hasMoreOption}>
                    {label && <S.Label>{label}</S.Label>}
                    <S.Value hasMoreOption={hasMoreOption}>
                      {handleEmptyValue(conditionalRoundNutrition(value))}
                    </S.Value>
                    {unit}
                  </S.ItemNutrition>
                );
              })
            : null}
        </S.ListNutrition>
        {showDesc && <S.ItemDesc>{recipe.description || ''}</S.ItemDesc>}
        {isShowDietary && (
          <S.ListDietary>
            {dietaries.map(item => {
              const { value = '', name = '', icon_hover = '' } =
                listDietary.find(dietary => dietary.value === item) || {};

              const iconConvert = convertS3UrlToCloudFrontUrl(icon_hover, cloudfrontList, true);

              return (
                <S.DietarySelectItem key={value}>
                  <img src={iconConvert} alt={name} />
                  <span>{name}</span>
                </S.DietarySelectItem>
              );
            })}
          </S.ListDietary>
        )}
      </S.ItemInfo>
      {hasMoreOption && !isLogged && !mealClientCompleted ? renderMoreOption() : null}
      {isLogged && (
        <S.LoggedMeal data-tip data-for={`logged-meal-tooltip-${recipe._id}`}>
          <LoggedMeal />
          <ReactTooltip id={`logged-meal-tooltip-${recipe._id}`} effect="solid" place="top">
            Client has logged macros for meal
          </ReactTooltip>
        </S.LoggedMeal>
      )}
    </S.MainPanelItemWrapper>
  );
};

export default RecipeItems;
