import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const ReactQuillWrapper = styled(ReactQuill)`
  overflow: hidden;

  .ql-toolbar.ql-snow {
    background: linear-gradient(90.68deg, #f7f7f7 27.26%, rgba(247, 247, 247, 0) 50.59%);
    border: none;
    border-radius: 8px;
    padding: 8px;

    .ql-formats {
      padding-right: 10px;
      margin-right: 10px;

      .ql-stroke {
        stroke: #6a778a;
      }

      button {
        user-select: auto !important;
        height: 26px !important;
        padding: 0px !important;
        width: 26px !important;
        margin-right: 12px;
        border-radius: 4px;

        color: #323c47;
        &.ql-active {
          color: #5158cf;
          background: #e3e3ff;

          .ql-stroke {
            stroke: #5158cf;
          }
        }
        &:hover {
          color: #5158cf;
          .ql-stroke {
            stroke: #5158cf;
          }
          .ql-fill {
            fill: #5158cf;
          }
        }
        &.ql-active .ql-fill {
          fill: #5158cf;
        }
        .ql-fill {
          fill: #6a778a;
          &:hover {
            fill: #5158cf;
          }
        }
      }
    }
  }

  .ql-editor.ql-blank {
    &::before {
      left: 1px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 175%;
      color: rgba(50, 60, 71, 0.3);
    }
  }

  .ql-container.ql-snow {
    border: none;
    overflow-y: scroll;

    .ql-editor {
      word-break: break-word;
      padding: 16px 16px 16px 0px;

      * {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 175%;
        color: #323c47;
      }

      p {
        margin-bottom: 0;
      }

      strong,
      strong em {
        font-weight: 700 !important;
      }

      em {
        font-style: italic !important;
      }

      ul,
      ol {
        padding: revert;
        margin-top: 0px;
        margin-bottom: 0px;

        li {
          padding-left: revert;

          ::before {
            content: '';
          }
        }
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }
    }
  }
`;
