import get from 'lodash/get';
import moment from 'moment';
import { formatSameYearWithTime, pluralize } from 'utils/commonFunction';

export const GENERAL_STATUS_WAITLIST = {
  WAITING: 'waiting',
  OFFERED: 'offered',
  PURCHASED: 'purchased',
  REJECTED_BY_COACH: 'rejected_by_coach',
  REJECTED_BY_CLIENT: 'rejected_by_client',
  REJECTED_BY_COACH_WITHOUT_CONSULTATION: 'rejected_by_coach_without_consultation',
  REJECTED_BY_COACH_AFTER_CONSULTATION: 'rejected_by_coach_after_consultation',
  CANCELLED_BY_CLIENT: 'cancelled_by_client',
  NO_RESPONSE: 'no_response',
};

export const checkUndoDurationTime = date => {
  const rejectedTime = moment(date).add(process.env.REACT_APP_UNDO_DURATION_TIME, 'm').toISOString();
  const currentTime = moment().toISOString();
  return moment(rejectedTime).isSameOrBefore(currentTime);
};

export const getWaitlistStatus = status => {
  let result = {
    statusParse: 'no-response',
    statusLabel: 'No response',
  };
  switch (status) {
    case GENERAL_STATUS_WAITLIST.WAITING:
      result = {
        statusParse: 'waiting',
        statusLabel: 'Waiting',
      };
      break;
    case GENERAL_STATUS_WAITLIST.PURCHASED:
      result = {
        statusParse: 'purchased',
        statusLabel: 'Purchased',
      };
      break;
    case GENERAL_STATUS_WAITLIST.OFFERED:
      result = {
        statusParse: 'offered',
        statusLabel: 'Offered',
      };
      break;
    case GENERAL_STATUS_WAITLIST.CANCELLED_BY_CLIENT:
      result = {
        statusParse: 'cancelled',
        statusLabel: 'Cancelled',
      };
      break;
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_CLIENT:
      result = {
        statusParse: 'cancelled',
        statusLabel: 'Declined by client',
      };
      break;
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH:
      result = {
        statusParse: 'rejected',
        statusLabel: 'Declined by coach',
      };
      break;
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH_AFTER_CONSULTATION:
      result = {
        statusParse: 'rejected',
        statusLabel: 'Declined after consultation',
      };
      break;
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH_WITHOUT_CONSULTATION:
      result = {
        statusParse: 'rejected',
        statusLabel: 'Declined without consultation',
      };
      break;
    default:
      break;
  }
  return result;
};

export const getContactMessageStatus = status => {
  let label = 'Show all';
  switch (status) {
    case false:
      label = 'Unread only';
      break;
    case true:
      label = 'Read only';
      break;
    default:
      break;
  }
  return {
    label,
    value: status,
  };
};

export const getClientWord = count => pluralize('client', count);
export const getPronounsWord = count => (count > 1 ? 'these' : 'this');

export const getParamsFromUrl = search => {
  if (!search) {
    return [];
  }
  let params = search.split('&');
  params = params.map(item => item.substring(item.indexOf('=') + 1, item.length));

  return params;
};

export const getTimeActionTooltip = data => {
  const status = get(data, 'status', '');
  const isHideTooltip = [GENERAL_STATUS_WAITLIST.WAITING, GENERAL_STATUS_WAITLIST.NO_RESPONSE].includes(status);
  if (isHideTooltip) {
    return '';
  }
  let time, label;
  switch (status) {
    case GENERAL_STATUS_WAITLIST.PURCHASED:
      time = get(data, 'purchased_at');
      label = 'Purchased on';
      break;
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_CLIENT:
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH:
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH_AFTER_CONSULTATION:
    case GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH_WITHOUT_CONSULTATION:
      time = get(data, 'rejected_at');
      label = 'Declined on';
      break;
    case GENERAL_STATUS_WAITLIST.CANCELLED_BY_CLIENT:
      time = get(data, 'cancelled_at');
      label = 'Cancelled on';
      break;
    case GENERAL_STATUS_WAITLIST.OFFERED:
      time = get(data, 'approved_at');
      label = 'Offered on';
      break;
    default:
      break;
  }

  if (time) {
    return `${label} ${formatSameYearWithTime(time)}`;
  }
  return '';
};

export const getTrainerNameApprovedOrRejected = (status, name) => {
  let labelAction = 'Offered';

  if (status === 'rejected') {
    labelAction = 'Declined';
  }

  return `${labelAction} by ${name}`;
};
