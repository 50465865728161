import styled, { keyframes, css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

import background from 'assets/images/upload-cover-bg.png';
import backgroundPreview from 'assets/images/resource-preview.png';

const pulse = keyframes`
  0% {
    -webkit-transform:scale(1); 
  }

  20% {
    -webkit-transform:scale(3.6);
    opacity: 0.1;
  }

  40% { 
    -webkit-transform:scale(6);
  }

  60% { 
    -webkit-transform:scale(8.7);
    opacity: 0.05;
  }

  85% { 
    -webkit-transform:scale(10);
    opacity: 0
  }

  100% {
    opacity: 0;
  }
`;

export const Aura = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f47435;
  position: absolute;
  right: -2px;
  top: -3px;
  animation: ${pulse} 1.25s infinite cubic-bezier(0.4, 0, 1, 1);
`;

export const FirstTimeWrapper = styled.div`
  width: 73px;
  height: 87px;
  z-index: 10002;
  background-color: #ffff;
  border-radius: 4px;
  padding: 4px;
  position: relative;
`;

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2px;
  border: 1px solid #dadfea;
  border-radius: 3px;
`;

export const PlaceholderBg = styled.div`
  background-image: url(${background});
  background-color: #ffff;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  background-color: #f1f3f6;
  border-radius: 3px;
`;

export const PlaceholderMessage = styled.div`
  width: 315px;
  padding: 15px 6px 15px 15px;
  height: 440px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  right: -330px;
  top: -15px;
  position: absolute;
  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-width: 1px 0px 0px 1px;
    border-top-style: solid;
    border-left-style: solid;
    border-image: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: initial;
    border-bottom-color: initial;
    transform: rotate(-45deg);
    position: absolute;
    right: 312px;
    top: 15px;
    background: #ffffff;
  }
`;

export const PlaceholderTitle = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
`;

export const PlaceholderDesc = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
`;

export const PlaceholderPreview = styled.div`
  background-image: url(${backgroundPreview});
  background-color: #ffff;
  background-repeat: no-repeat;
  background-position: center;
  height: 263.77px;
  width: 100%;
  background-size: contain;
`;

export const PlaceholderConfirm = styled.button`
  color: #7470ef;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const PlaceholderActions = styled.div`
  text-align: right;
  padding-top: 15px;
`;

export const UploadWrapperContainer = styled.div`
  width: 63px;
  height: 78px;
  padding: 3px;
  border: 1px solid #dadfea;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      * {
        cursor: not-allowed !important;
      }
    `}
`;

export const UploadWrapper = styled.div`
  background: #f1f3f6;
  .uploadCover {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    background: #f1f3f6;
    border: 1px dashed transparent;
    ${props =>
      props.isDragging &&
      css`
        border-color: #5158cf;
        &.available {
          border-color: transparent;
        }
      `}
  }
`;

export const DropWrapper = styled.div`
  width: 52px;
  height: 68px;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
      pointer-events: none;
      background-size: cover;
    `}
`;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 2;
`;
