import React from 'react';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { SharedTooltip } from 'shared/SharedTooltip';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_grey.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_grey.svg';
import { ReactComponent as Remove } from 'assets/icons/trash_gray.svg';
import { ReactComponent as Edit } from 'assets/icons/edit_gray.svg';

import * as S from './style';

const SectionOptions = ({ onSwap, isFirstSection, isLastSection, currentIndex, onRemove, onEdit }) => {
  const handleMoveUp = () => {
    !isFirstSection && onSwap(currentIndex, currentIndex - 1);
  };

  const handleMoveDown = () => {
    !isLastSection && onSwap(currentIndex, currentIndex + 1);
  };

  const handleRemove = () => {
    onRemove(currentIndex);
  };

  const handleEdit = () => {
    onEdit();
  };

  return (
    <S.DropdownWrapper>
      <DropDown
        className="evf-dropdown evf-dropdown--basic"
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="resource-detail-options-tooltip" className="resource-detail-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="resource-detail-options-tooltip" />
          </div>
        )}
      >
        <Option key="move-up" onClick={handleMoveUp} disabled={isFirstSection}>
          <ArrowUpIcon />
          <span>Move Up</span>
        </Option>
        <Option key="move-down" onClick={handleMoveDown} disabled={isLastSection}>
          <ArrowDownIcon />
          <span>Move Down</span>
        </Option>
        <Option key="edit" onClick={handleEdit}>
          <Edit />
          <span>Edit Description</span>
        </Option>
        <Option key="remove" onClick={handleRemove}>
          <Remove />
          <span>Remove</span>
        </Option>
      </DropDown>
    </S.DropdownWrapper>
  );
};

export default SectionOptions;
