import { fromJS } from 'immutable';
import _ from 'lodash';
import { Types } from './actions';
import { AUTOFLOW_CLIENT_STATUS } from 'constants/commonData';

const INITITAL_STATE = fromJS({
  list: [],
  total: 0,
  selectedClients: [],
  firstLoad: true,
  complianceView: '7d',
  query: {
    search: '',
    page: 1,
    per_page: 50,
    sort: -1,
    sorter: 'last_activity',
    status: AUTOFLOW_CLIENT_STATUS.ACTIVE,
  },
});

export default (state = INITITAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case Types.AUTOFLOW_INTERVAL_CLIENT_CHANGE_QUERY_PARAM:
      return state.mergeIn(['query'], payload.newQuery);

    case Types.AUTOFLOW_INTERVAL_CLIENT_CHANGE_COMPLIANCE_VIEW:
      return state.mergeIn(['complianceView'], payload.complianceView);

    case Types.AUTOFLOW_INTERVAL_CLIENT_GET_LIST_SUCCESS:
      return state.merge({
        list: payload.list,
        firstLoad: false,
        total: isNaN(parseInt(payload.total)) ? 0 : payload.total,
      });

    case Types.AUTOFLOW_INTERVAL_CLIENT_RESET:
      return INITITAL_STATE;

    case Types.AUTOFLOW_INTERVAL_CLIENT_SELECT_CLIENTS:
      return state.merge({ selectedClients: payload.data });

    case Types.AUTOFLOW_INTERVAL_CLIENT_SUCCESS_REMOVE_CLIENTS:
      return state.merge({ selectedClients: [] });

    case Types.AUTOFLOW_INTERVAL_CLIENT_SUCCESS_REMOVE_ONE_CLIENT:
      return state.merge({
        selectedClients: _.filter(state.selectedClients, item => item._id !== payload.client),
      });

    case Types.AUTOFLOW_INTERVAL_SUCCESS_ACTIVATE_CLIENTS:
    case Types.AUTOFLOW_INTERVAL_SUCCESS_PAUSE_CLIENTS:
    case Types.AUTOFLOW_INTERVAL_SUCCESS_MARK_CLIENTS_AS_COMPLETE:
    case Types.AUTOFLOW_INTERVAL_SUCCESS_START_TODAY:
    case Types.AUTOFLOW_INTERVAL_SUCCESS_CHANGE_START_DATE:
      if (payload.multiple) {
        return state.merge({ selectedClients: [] });
      } else {
        return state.merge({
          selectedClients: _.filter(state.selectedClients, item => item._id !== payload.data.clients[0]),
        });
      }

    default:
      return state;
  }
};
