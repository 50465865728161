import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

function InactiveAutoflowHasClientStartInPastWarning(props) {
  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/alert_warning.svg`} />
            <span>Update start dates</span>
          </S.Header>
          <S.Body>
            <div>
              Some clients have start dates in the past. Please update the clients before proceeding to activate the
              Autoflow
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>OK</Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

export default connect(null, { toggleModal })(InactiveAutoflowHasClientStartInPastWarning);
