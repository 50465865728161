import styled, { css } from 'styled-components';
import { DietarySelectItem } from 'components/RecipeDetail/DietaryInfo/style';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { CDN_URL } from 'constants/commonData';

const textBase = `
  color: #202353;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
`;

export const Trigger = styled.div`
  width: 42px;
  height: 24px;
  background-color: #e4e5e7;
  border-radius: 3px;
  background-image: url(${CDN_URL}/images/filter.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
  z-index: 999;
  :hover {
    background-color: #e7e6fe;
    background-image: url(${CDN_URL}/images/filter_purple.svg);
  }
`;

export const TriggerContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

export const PopupTitleHeader = styled.div`
  ${textBase}
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
`;

export const ClearAllFilter = styled.div`
  ${textBase}
  color: #5158cf;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SelectDietary = styled.div`
  padding: 0;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const CheckboxLabel = styled.span`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
`;

export const Checkbox = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #dadbea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: #fff;
  svg {
    width: 8px;
    height: 8px;
  }
`;

export const SelectRangeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 22px;
  margin-bottom: 10px;
`;

export const Body = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 378px;
  margin-right: 5px;
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }
`;

export const FilterTitle = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
`;

export const FilterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 6px 10px 20px;
  &:first-child {
    ${FilterTitle} {
      margin-bottom: 5px;
    }
  }
`;

export const Footer = styled.div`
  text-align: right;
  padding: 14px 20px 15px 15px;
  border-top: 1px solid #f0f0f2;
  .update-button {
    height: 30px;
    min-width: 73px;
    width: 73px;
    font-size: 12px;
    &:hover {
      opacity: 0.9;
    }
  }
`;

export const Popup = styled.div`
  width: 437px;
  height: 487px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  display: none;
  position: absolute;
  top: 117px;
  right: 33px;
  z-index: 2;
`;

export const Wrapper = styled.div`
  margin-right: 10px;
  user-select: none;
  &.open {
    ${Trigger} {
      background-color: #e7e6fe;
      background-image: url(${CDN_URL}/images/filter_purple.svg);
    }
    ${TriggerContainer} {
      ::after {
        display: block;
      }
    }
    ${Popup} {
      display: flex;
    }
  }
`;

const activeTrigger = `
background: rgb(240, 241, 255);
  .filter-icon {
    path {
      fill: #5158cf;
    }
  }
`;

export const FilterButton = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .__react_component_tooltip {
    ${textBase}
    font-weight: 400;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 10px;
    border-radius: 4px;
  }

  :hover {
    ${activeTrigger}
  }
  ${props =>
    props.active &&
    css`
      ${activeTrigger}
    `}
`;

export const FilterNumber = styled.div`
  width: 16px;
  height: 14px;
  position: absolute;
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  right: -9px;
  top: -9px;
  border-radius: 3px;
  box-shadow: 0 0 0 3px #fff;
  background: #ffbe49;
  ${textBase}
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
`;

export const IconCategoryOriginal = styled(S3ImageWithFallback)`
  display: flex;
`;

export const IconCategoryHover = styled(S3ImageWithFallback)`
  display: none;
`;

export const DietarySelectItemWrapper = styled(DietarySelectItem)`
  gap: 5px;
  padding: 0 12px;
  &:hover {
    ${IconCategoryOriginal} {
      display: none;
    }
    ${IconCategoryHover} {
      display: flex;
    }
  }
`;
