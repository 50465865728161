import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import InfoIcon from 'assets/icons/information.svg';

export const Tip = styled.div`
  border-radius: 3px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding: 9px 10px 9px 35px;
  background: #e6f1ff url(${InfoIcon}) no-repeat 11px 11px;
  margin-top: 10px;
  flex: 0 0 100%;
`;

export const SelectRankingGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
  margin-bottom: 20px;

  .form-item {
    margin-bottom: 0;

    &.select-ranking-type {
      width: 325px;
    }
    &.select-order-by {
      width: 275px;
    }
  }
`;

export const LeaderboardModal = styled(Modal)`
  width: 675px !important;

  > .content {
    border-radius: 8px !important;
    padding: 0 !important;
  }

  .modalLayout__header {
    padding-bottom: 25px;
  }

  .modalLayout__content {
    overflow: visible;

    .inputsContainer {
      margin-bottom: 10px;
    }
  }

  .modalLayout__actions {
    padding: 10px 30px;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  }

  .form-item {
    input,
    textarea {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      padding: 11px 14px;
      border: 1px solid #dadfea;
      box-sizing: border-box;
      border-radius: 4px;
      height: unset;

      ::placeholder {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #969fb5;
      }
    }

    textarea {
      outline: none !important;
      box-shadow: none;
      width: 100%;
      resize: vertical;
      min-height: 63px;
      padding: 14px;
    }
  }

  .evfSelectBoxContainer {
    &.ranking {
      .evfSelectBox__control {
        border-radius: 5px;

        .evfSelectBox__value-container {
          padding-left: 14px;
        }

        .evfSelectBox__single-value {
          color: #202353;
          font-weight: normal;
        }
      }
    }

    &.evfSelectBox--is-disabled {
      .evfSelectBox__value-container {
        opacity: 1;
      }
    }
  }
`;
