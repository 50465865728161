import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import Avatar from 'react-avatar';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import SelectClientOption from 'shared/SelectClientOption';
import { DropdownIndicator } from 'shared/Icons';
import { searchAutoflow } from 'redux/autoflow/actions';
import { searchClient } from 'redux/trainer/actions';
import { AUTOFLOW_STATUS, AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import { timeSince, getUserShortName } from 'utils/commonFunction';
import { assignClientsFromMainList, assignClientsSuccess } from 'redux/autoflow/client/actions';
import { assignClientsToIntervalAutoflowFromClientList } from 'redux/autoflowInterval/client/actions';
import * as S from './style';
import './style.scss';
import { toggleModal } from 'actions/modal';
import AutoflowIntervalOptions from './AutoflowIntervalOptions';

const AutoflowSelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select_option';
  const author = data.author || {};

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  const name = `${data.first_name} ${data.last_name}`;

  return (
    <S.AutoflowOptionContainer {...innerProps} className={className}>
      <S.AutoflowOptionNameContainer>
        <div>{data.name}</div>
        <div
          className={classNames('status', {
            active: data.status === AUTOFLOW_STATUS.ACTIVATED,
          })}
        >
          {data.status === AUTOFLOW_STATUS.ACTIVATED ? 'Active' : 'Inactive'}
        </div>
      </S.AutoflowOptionNameContainer>
      <S.AutoflowOptionOwnerContainer>
        {!!data.last_used && <div className="last-used">{timeSince(new Date(data.last_used))}</div>}
        <Avatar name={getUserShortName(author)} className="avatar" size="32" src={author.avatar} color={author.color} />
      </S.AutoflowOptionOwnerContainer>
    </S.AutoflowOptionContainer>
  );
};

function AssignToAutoflow(props) {
  const { originClients } = props;
  const [clients, setClients] = useState(originClients || []);
  const [autoflow, setAutoflow] = useState(null);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startingOn, setStartingOn] = useState(moment());
  const [startingAt, setStartingAt] = useState(0);

  const onModalOpen = () => {
    setClients(originClients || []);
    setAutoflow(null);
    setError(false);
    setIsSubmitting(false);
  };

  const loadClientOptions = (inputValue, callback) => {
    const except = _.map(clients, '_id');
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    props
      .searchClient({ q: search, except: except.join(), total: 20 })
      .then(response => {
        const { data } = response.data;

        if (data) {
          callback(
            _.map(data, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label: item.full_name ? item.full_name : `${item.first_name} ${item.last_name}`,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const loadAutoflowOptions = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    props
      .searchAutoflow({ search, page: 1, per_page: 20, sorter: 'last_used' })
      .then(response => {
        const { data } = response.data;

        if (data) {
          callback(
            _.map(data, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label: item.name,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(() => {
        callback([]);
      });
  };

  const loadClientOptionsDebounce = _.debounce(loadClientOptions, 200);
  const loadAutoflowOptionsDebounce = _.debounce(loadAutoflowOptions, 200);

  const onSubmit = () => {
    if (isSubmitting) {
      return false;
    }

    if (!clients.length || !autoflow) {
      return setError(true);
    }

    const data = {
      clients: _.map(clients, '_id'),
    };

    setIsSubmitting(true);

    if (autoflow.type === AUTOFLOW_TYPES.INTERVAL) {
      data.autoflowId = autoflow._id;
      data.started_date = startingOn.format('MM-DD-YYYY');
      data.started_on = startingAt + 1;
      props
        .assignClientsToIntervalAutoflowFromClientList(data)
        .then(response => {
          const firstClientName = _.get(clients, [0, 'full_name']);
          const { data } = response.data;
          const popupData = {
            data: {
              list_success: data.filter(o => o.success),
              list_error: data.filter(o => !o.success),
            },
          };

          props.assignClientsSuccess(popupData, firstClientName);

          if (typeof props.onAssignSuccess === 'function') {
            props.onAssignSuccess(response);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      data.autoflow = autoflow._id;
      data.first_client_name = clients[0].full_name;
      props
        .assignClientsFromMainList(data, 'bulk_add')
        .then(response => {
          setIsSubmitting(false);
          setError(false);
          props.toggleModal(false);

          props.assignClientsSuccess(response, data.first_client_name);

          if (typeof props.onAssignSuccess === 'function') {
            props.onAssignSuccess(response);
          }
        })
        .catch(() => setIsSubmitting(false));
    }
  };

  const onSelectAutoflow = value => {
    setAutoflow(value);
  };

  const onSelectStartingOn = value => {
    setStartingOn(value);
  };

  const onSelectStartingAt = value => {
    setStartingAt(value);
  };

  return (
    <Modal
      open={true}
      onClose={() => props.toggleModal(false)}
      closeOnDimmerClick={false}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
      onOpen={onModalOpen}
      className="assign-client-to-autoflow-modal"
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <div className="title">Assign to Autoflow</div>
            <div className="subtitle">Only your connected and pending clients will be added</div>
          </S.Header>
          <S.Body>
            <S.FormGroup>
              <S.FormLabel>Add clients</S.FormLabel>
              <S.Control>
                <AsyncSelect
                  isMulti
                  defaultOptions
                  cacheOptions
                  components={{ Option: SelectClientOption }}
                  loadOptions={(inputValue, callback) => loadClientOptionsDebounce.call(this, inputValue, callback)}
                  onChange={list => setClients(list)}
                  className="multi-select-container"
                  classNamePrefix="multi-select"
                  placeholder="Enter client..."
                  noOptionsMessage={() => 'No clients found.'}
                  defaultValue={_.map(clients, item => ({
                    ...item,
                    key: item._id,
                    value: item._id,
                    label: item.full_name ? item.full_name : `${item.first_name} ${item.last_name}`,
                  }))}
                />
                {error && !clients.length ? <S.Error>Please chooose clients</S.Error> : null}
              </S.Control>
            </S.FormGroup>
            <S.FormGroup>
              <S.FormLabel>Choose Autoflow</S.FormLabel>
              <S.Control>
                <AsyncSelect
                  defaultOptions
                  cacheOptions
                  loadOptions={(inputValue, callback) => loadAutoflowOptionsDebounce.call(this, inputValue, callback)}
                  components={{
                    DropdownIndicator,
                    Option: AutoflowSelectOption,
                  }}
                  classNamePrefix="single-select"
                  placeholder="Choose your Autoflow"
                  onChange={onSelectAutoflow}
                  className="single-select-container select--autoflow"
                  noOptionsMessage={() => 'No result'}
                  inputId="assign-to-autoflow__select-autoflow-input"
                />
                {error && !autoflow ? <S.Error>Please chooose autoflow</S.Error> : null}
              </S.Control>
            </S.FormGroup>
            <AutoflowIntervalOptions
              autoflow={autoflow}
              startingOn={startingOn}
              startingAt={startingAt}
              onSelectStartingOn={onSelectStartingOn}
              onSelectStartingAt={onSelectStartingAt}
            />
          </S.Body>
          <S.Footer>
            <Button purple onClick={onSubmit} type="button">
              Assign
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const actionCreators = {
  searchClient,
  searchAutoflow,
  assignClientsFromMainList,
  toggleModal,
  assignClientsToIntervalAutoflowFromClientList,
  assignClientsSuccess,
};

export default connect(null, actionCreators)(AssignToAutoflow);
