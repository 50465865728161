import { listWarningMessage } from 'constants/commonData';

export const onlyInsertPlainTextCustom = event => {
  event.preventDefault();
  let text = '';

  if (event.clipboardData && event.clipboardData.getData) {
    text = event.clipboardData.getData('text/plain');
  } else if (window.clipboardData && window.clipboardData.getData) {
    text = window.clipboardData.getData('Text');
  }

  // Check string same format "{...}"
  const textTrim = text.trim();
  const firstChar = textTrim.charAt(0) === '{';
  const lastChar = textTrim.charAt(textTrim.length - 1) === '}';
  const textExist = listWarningMessage.includes(textTrim.toUpperCase());

  if (firstChar && lastChar && textExist) {
    let sel, range;
    if (window.getSelection && (sel = window.getSelection()).rangeCount) {
      range = sel.getRangeAt(0);
      range.collapse(true);
      let span = document.createElement('span');
      span.className = 'autofill';
      span.appendChild(document.createTextNode(text));
      range.insertNode(span);
      // Move the caret immediately after the inserted span
      range.setStartAfter(span);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  } else {
    document.execCommand('insertText', false, text);
  }

  event.persist();
};

export const onlyInsertPlainText = event => {
  event.preventDefault();
  let text = '';

  if (event.clipboardData && event.clipboardData.getData) {
    text = event.clipboardData.getData('text/plain');
  } else if (window.clipboardData && window.clipboardData.getData) {
    text = window.clipboardData.getData('Text');
  }

  document.execCommand('insertHTML', false, text);
  event.persist();
};

export const onlyAllowNumber = event => {
  if (event.which === 45 || event.which === 43 || event.which === 101) {
    event.preventDefault();
  }
};

export const addResponsiveMetaTag = () => {
  if (document) {
    let meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
};

export const shouldUseVirtualViewport = () => {
  if (document) {
    let meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=auto';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
};

export const showTheMobileReminder = () => {
  localStorage.setItem('mobileReminder', 'show');
};

export const hideTheMobileReminder = () => {
  localStorage.setItem('mobileReminder', 'hide');
};
