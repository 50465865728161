import styled from 'styled-components';

export const GoalListStyled = styled.div`
  &.goal-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-bottom-right-radius: 8px;

    .goal-heading {
      display: flex;
      height: 58px;
      justify-content: space-between;
      align-items: center;
      padding: 16px 25px;

      h2 {
        font-size: 19px;
        line-height: 28.5px;
        font-weight: 600;
        color: #141414;
        margin-bottom: 0;
      }
    }
    .goal-items {
      padding: 14px 13px 0 25px;
      margin-right: 6px;
      height: 100%;
      background-color: #f8f8f8;
      overflow: scroll;
      width: 666px;

      &::-webkit-scrollbar {
        width: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #00000026 !important;
      }

      &.empty-list {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 24px;

        p {
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          font-weight: 600;
          color: #a3a3b5;
          margin: 3px 0 0;
          padding: 0;
        }

        .add-goal-btn {
          position: relative;
          left: -1px;
          top: 0px;
          padding: 4px 16px;
          height: 36px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: center;
          color: #5158cf;
          border: 1px solid #5158cf;
          background-color: #ffffff;
          border-radius: 4px;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
`;
