import React, { useEffect, useState } from 'react';
import Banner from './WorkoutInfo/Banner';
import * as S from './WorkoutInfo/style';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const LIMIT_LENGTH = 30;

export default function Header({
  name: originalName,
  onUpdateName,
  background,
  shouldShowBackground,
  getPreSignedUrl,
  onUpdateBackground,
  customBrandingBackground,
  errors,
  showErrors,
  onNameFocus,
  onStartUploading,
  onEndUploading,
  user = {},
  author = {},
  shareStatus = {},
  cloudfrontList,
  handleChangeOwnershipStatus,
  isCreatorOrOwnerOrAdmin = false,
}) {
  const [name, setName] = useState(originalName);
  const titleRef = React.createRef();

  useEffect(() => {
    // should only run when name or description
    //  is updated from the parent component (select workout with empty name/description case)
    if (originalName !== name) {
      setName(originalName);
    }
  }, [originalName]);

  const handleChangeName = value => {
    let val = value ? value.trim() : '';

    if (val.length <= LIMIT_LENGTH) {
      setName(val);
      onUpdateName && onUpdateName(val);
      // oChangeNewData('title', value.trim());
    } else {
      val = val.slice(0, LIMIT_LENGTH);
      setName(val);
      onUpdateName && onUpdateName(val);
      // oChangeNewData('title', value.slice(0, LIMIT_LENGTH).trim());
    }
  };

  return (
    <S.Header>
      <S.TextEditableArea>
        <S.NameField
          ref={titleRef}
          readOnly={!isCreatorOrOwnerOrAdmin}
          className="workout-collection-title"
          value={name}
          onChange={handleChangeName}
          autoFocus={true}
          placeholder="Name your Workout"
          lineHeight={27}
          maxLength={LIMIT_LENGTH}
          invalid={showErrors && errors.includes('name')}
          onFocus={onNameFocus}
        />
        <S.CountCharacter>{`${name.length}/${LIMIT_LENGTH}`}</S.CountCharacter>
      </S.TextEditableArea>
      <S.HeaderRightWrapper>
        {shouldShowBackground && (
          <S.BackgroundContainer>
            {(background || customBrandingBackground.src) && (
              <Banner
                isSubmitting={false}
                isEdit={isCreatorOrOwnerOrAdmin}
                coverImage={convertS3UrlToCloudFrontUrl(
                  background ? background : customBrandingBackground.src,
                  cloudfrontList,
                  true,
                )}
                getPreSignedUrl={getPreSignedUrl}
                onUploadImageChange={onUpdateBackground}
                startUploadCoverImage={onStartUploading}
                endUploadCoverImage={onEndUploading}
              />
            )}
          </S.BackgroundContainer>
        )}
        {isCreatorOrOwnerOrAdmin && (
          <OwnershipPopupStudio
            user={user}
            owner={author}
            shareStatus={shareStatus}
            saveChangeOnClose={handleChangeOwnershipStatus}
            updateImmediately={true}
          />
        )}
      </S.HeaderRightWrapper>
    </S.Header>
  );
}
