import get from 'lodash/get';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import * as S from './style';

const PaginationDot = ({ selectedPhotoListByDate = [], selectedPhoto }) => {
  const indexSelected = useMemo(
    () => selectedPhotoListByDate.findIndex(item => get(item, 'image', '') === selectedPhoto),
    [selectedPhotoListByDate, selectedPhoto],
  );
  const length = useMemo(() => get(selectedPhotoListByDate, 'length', 0), [selectedPhotoListByDate]);

  const NUMBER_LIMIT_LEFT = 2;
  const NUMBER_LIMIT_RIGHT = length - 3;
  const NUMBER_LIMIT_DOT = 5;

  const calculatePaginationDot = index => {
    let between = false;
    let small = false;

    if (length >= NUMBER_LIMIT_DOT) {
      if (indexSelected <= NUMBER_LIMIT_LEFT) {
        if (index === NUMBER_LIMIT_LEFT + 1) {
          between = true;
        }
        if (index === NUMBER_LIMIT_LEFT + 2) {
          small = true;
        }
      } else if (indexSelected >= NUMBER_LIMIT_RIGHT) {
        if (index === NUMBER_LIMIT_RIGHT - 1) {
          between = true;
        }
        if (index === NUMBER_LIMIT_RIGHT - 2) {
          small = true;
        }
      } else {
        if (index === indexSelected - 1 || index === indexSelected + 1) {
          between = true;
        }
        if (index === indexSelected - 2 || index === indexSelected + 2) {
          small = true;
        }
      }
    } else if (length === NUMBER_LIMIT_DOT - 1) {
      if (indexSelected <= NUMBER_LIMIT_LEFT) {
        if (index === NUMBER_LIMIT_LEFT + 1) {
          between = true;
        }
      } else {
        if (index === 0) {
          between = true;
        }
      }
    }

    return { between, small };
  };

  let valueTranslateX = 0;

  if (length >= 5) {
    if (indexSelected >= 3 && indexSelected <= NUMBER_LIMIT_RIGHT) {
      valueTranslateX = -((indexSelected - 2) * 18);
    }

    if (indexSelected > NUMBER_LIMIT_RIGHT) {
      valueTranslateX = -((NUMBER_LIMIT_RIGHT - 2) * 18);
    }
  }

  return (
    <S.DotWrapper>
      <S.DotMain valueTranslateX={valueTranslateX} center={length < 5}>
        {selectedPhotoListByDate.map((item, index) => {
          const result = calculatePaginationDot(index);
          const active = get(item, 'image', '') === selectedPhoto;
          return (
            <S.DotItem key={get(item, 'image', '')} between={result.between} small={result.small} active={active} />
          );
        })}
      </S.DotMain>
    </S.DotWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: {
        itemPhoto: { selectedPhoto, selectedPhotoListByDate },
      },
    },
  } = state;

  return {
    selectedPhotoListByDate,
    selectedPhoto,
  };
};

export default connect(mapStateToProps, null)(PaginationDot);
