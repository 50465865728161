// Libs
import React, { useMemo } from 'react';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import isObject from 'lodash/isObject';

// Actions
import { toggleModal } from 'actions/modal';
import { isTeamAdmin } from 'utils/commonFunction';
import { TEAM_SHARE_NOOWNER } from 'constants/commonData';

// Components
import MealPlanModal from 'components/MealPlans/parts/MealPlanModal';

// Constants
import { MEAL_PLAN_STATUS } from 'components/MealPlanDetail/constants';

import * as S from './style';

const MealPlanHeader = ({ name, title, loadingByDay, toggleModal, status, user, share, author }) => {
  const isAdmin = isTeamAdmin(user);
  const userId = get(user, '_id', '');
  const isAuthor = isObject(author) ? author._id === userId : author === userId;

  const handleEdit = () => {
    toggleModal && toggleModal(true, <MealPlanModal toggleModal={toggleModal} editMode={true} />);
  };

  const renderStatusLabel = useMemo(() => {
    switch (status) {
      case MEAL_PLAN_STATUS.PUBLISH:
        return <S.MealPlanStatus isPublished>Published</S.MealPlanStatus>;
      case MEAL_PLAN_STATUS.DRAFT:
        return <S.MealPlanStatus>Draft</S.MealPlanStatus>;
      default:
        return <></>;
    }
  }, [status]);

  return (
    <>
      <Helmet>
        <title>
          {title || name ? `${title || name} - Meal Plan Templates -  Everfit` : 'Meal Plan Templates -  Everfit'}
        </title>
      </Helmet>
      {loadingByDay ? null : (
        <S.MealPlanHeaderWrapper>
          <S.Name>{title || name || ''}</S.Name>
          {!title && renderStatusLabel}
          {!title && (isAdmin || share === TEAM_SHARE_NOOWNER || isAuthor) && (
            <>
              <S.EditButton onClick={handleEdit}>Edit Info</S.EditButton>
            </>
          )}
        </S.MealPlanHeaderWrapper>
      )}
    </>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: { mealPlans },
  } = state;

  const name = get(mealPlans, 'detailMealPlan.name', '');
  const title = get(mealPlans, 'detailMealPlanByDay.headerTitle', '');
  const share = get(mealPlans, 'detailMealPlan.share');
  const author = get(mealPlans, 'detailMealPlan.author');
  const loadingByDay = get(mealPlans, 'loadingByDay', false);
  const status = get(mealPlans, 'detailMealPlan.status', '');

  return { name, title, loadingByDay, status, user, share, author };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default withRouter(connect(mapState, mapDispatchToProps)(MealPlanHeader));
