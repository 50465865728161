import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';
import { ENTITY_NAME } from 'database/constants';
import { Exercise } from 'database/schema';
import { DraggingIcon } from '../style';

import * as S from './style';
import ExerciseItem from '../ExerciseItem';
import { bindActionCreators } from 'redux';
import { handleDragExercise, handleDragSection, setDragItem } from 'redux/workout-builder/actions';
import SectionDropContainer from './SectionDropContainer';

const SectionIcon = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.01984 3.43476L1.6865 0.101429C1.4805 -0.0279044 1.22184 -0.0339043 1.00984 0.083429C0.797837 0.201429 0.666504 0.424096 0.666504 0.666762L0.666504 7.33343C0.666504 7.5761 0.797837 7.79876 1.00984 7.91676C1.11117 7.9721 1.22184 8.0001 1.33317 8.0001C1.45584 8.0001 1.5785 7.9661 1.6865 7.89876L7.01984 4.56543C7.2145 4.44343 7.33317 4.2301 7.33317 4.0001C7.33317 3.7701 7.2145 3.55676 7.01984 3.43476Z"
        fill="#5158CF"
      />
    </svg>
  );
};

class SectionItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      noneDrag: false,
    };
    this.isFocused = false;
    this.expandTimeout = undefined;
    this.wrapperRef = React.createRef();
    this.isMouseDown = false;
  }

  componentDidMount() {
    const { FREESTYLE } = SECTION_FORMAT_KEY;
    const section = this.props.section.toJS();

    if (section.format === FREESTYLE) this.setState({ collapse: false });
  }

  onMouseDown = e => {
    this.isMouseDown = true;
    this.timeoutSetDrag = setTimeout(() => this.doSetDrag(), 300);
  };

  doSetDrag = () => {
    if (this.isMouseDown) {
      this.props.setDragItem({
        dragType: 'section',
        sectionId: this.props.sectionId,
        index: this.props.index,
      });
    }
  };

  onMouseUp = e => {
    if (this.isMouseDown) {
      this.isMouseDown = false;
      this.setState({ noneDrag: false });
      clearTimeout(this.timeoutSetDrag);
    }

    const { FREESTYLE } = SECTION_FORMAT_KEY;
    const { sectionId } = this.props;
    const draggingItem = this.props.draggingItem ? this.props.draggingItem.toJS() : null;
    const section = this.props.section.toJS();

    if (section.format !== FREESTYLE) {
      if (draggingItem) {
        e.stopPropagation();
        if (draggingItem.dragType === 'exercise') {
          this.props.handleDragExercise({
            dropType: 'exercise',
            sectionId: sectionId,
            index: 0,
          });
          this.setState({ collapse: true });
        }
        if (draggingItem.dragType === 'section') {
          this.props.handleDragSection({
            dropType: 'section',
            sectionId: sectionId,
            index: this.props.index,
          });
        }
      }
    }
  };

  handleCollapse = (e, section) => {
    const { collapse } = this.state;

    e.stopPropagation();

    this.setState({ collapse: !collapse });
  };

  onMouseOver = e => {
    const { FREESTYLE } = SECTION_FORMAT_KEY;
    const draggingItem = this.props.draggingItem ? this.props.draggingItem.toJS() : null;
    const isExercise = this.props.section.type === HIDDEN_SECTION;
    const isCanExpandByHover =
      draggingItem &&
      draggingItem.dragType === 'exercise' &&
      draggingItem.sectionId !== this.props.sectionId &&
      !isExercise;
    if (isCanExpandByHover && this.wrapperRef.current) {
      this.wrapperRef.current.style.borderColor = '#5c5bbd';
    }
    this.isFocused = true;
    if (isCanExpandByHover && this.wrapperRef.current && e.target.className.indexOf(FREESTYLE) > -1) {
      this.setState({ noneDrag: true });
      return;
    }

    this.expandTimeout = setTimeout(this.expandByFocus, 1000);
  };

  expandByFocus = () => {
    if (this.isFocused && !!this.props.draggingItem) {
      this.setState({ collapse: true });
    }
  };

  onMouseLeave = () => {
    this.isFocused = false;
    clearTimeout(this.expandTimeout);

    if (this.wrapperRef.current) {
      this.wrapperRef.current.style = {};
    }
  };

  render() {
    const { FREESTYLE } = SECTION_FORMAT_KEY;
    const section = this.props.section.toJS();
    const { type, exercises, title } = section;
    const { collapse, noneDrag } = this.state;
    const isExercise = type === HIDDEN_SECTION;
    const draggingItem = this.props.draggingItem ? this.props.draggingItem.toJS() : null;
    const isDragging =
      draggingItem && draggingItem.dragType === 'section' && draggingItem.sectionId === this.props.sectionId;
    const isDragMode = !!draggingItem;
    const isCanExpandByHover =
      draggingItem &&
      draggingItem.dragType === 'exercise' &&
      draggingItem.sectionId !== this.props.sectionId &&
      !isExercise;
    return (
      <S.Wrapper
        isExercise={isExercise}
        collapse={collapse}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        isDragMode={isDragMode}
        isDragging={isDragging}
        className="arrangement__section"
        ref={this.wrapperRef}
        noneDrag={noneDrag}
      >
        {isExercise ? (
          <ExerciseItem exerciseId={exercises[0]} sectionId={this.props.sectionId} index={0} disableDrop />
        ) : (
          <>
            <div
              className={`sectionItem section-${section._id}-${section.format}`}
              onClick={e => this.handleCollapse(e, section)}
              onMouseOver={e => this.onMouseOver(e)}
              onMouseLeave={this.onMouseLeave}
            >
              <div className={`sectionItem__icon section-${section._id}-${section.format}`}>
                <SectionIcon />
              </div>
              <div className={`sectionItem__title section-${section._id}-${section.format}`}>
                {title}
                {section.format !== FREESTYLE && ` (${this.props.totalExercises})`}
              </div>
            </div>
            <div className="sectionItem__exercises">
              {exercises &&
                exercises.map((exercise, index) => (
                  <ExerciseItem
                    key={index}
                    exerciseId={exercise}
                    sectionId={this.props.sectionId}
                    index={index}
                    format={section.format}
                  />
                ))}
            </div>
          </>
        )}
        <DraggingIcon />
        {!!draggingItem && !isDragging && (
          <>
            <SectionDropContainer {...this.props} isCanExpand={isCanExpandByHover} />
            <SectionDropContainer
              {...this.props}
              index={this.props.index + 1}
              bottom
              isCanExpand={isCanExpandByHover}
            />
          </>
        )}
      </S.Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const section = state.rootReducer.model.getIn([ENTITY_NAME.Section, ownProps.sectionId]);
  let totalExercises = 0;

  if (section.get('exercises')) {
    const exercises = denormalize(section.get('exercises'), [Exercise], state.rootReducer.model).toJS();
    totalExercises = _.sumBy(exercises, e => e.supersets.length || 0);
  } else {
    const exerciseReferences = section.get('exercise_references');
    totalExercises = _.sumBy(exerciseReferences, e => e.length || 0);
  }

  return {
    section,
    totalExercises,
    draggingItem: state.rootReducer.workoutBuilder.get('draggingItem'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDragItem: bindActionCreators(setDragItem, dispatch),
    handleDragExercise: bindActionCreators(handleDragExercise, dispatch),
    handleDragSection: bindActionCreators(handleDragSection, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionItem);
