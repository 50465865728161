import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Button } from 'shared/FormControl';
import { getUserShortName, revokeObjectURL, revokeMultipleObjectURL, mediaLog } from 'utils/commonFunction';
import { CDN_URL, IN_APP_AUTO_MESSAGE } from 'constants/commonData';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import ConfirmModal from 'shared/ConfirmModal';
import { IN_APP_MESSAGE_STATUS } from 'constants/commonData';

import * as S from './style';
import ChatBar from 'components/InAppMessage/ChatBar';
import MessageItem from 'components/InAppMessage/MessageItem';
import { MESSAGE_TYPES, TimeOptions } from 'components/InAppMessage/constants';
import Schedule from './components/Schedule';
import Actions from './components/Footer';
import ClientList from './components/ClientList';
import { toast } from 'react-toastify';
import { getAttachmentIDFromUrl } from 'helpers/replyComment';

const DEFAULT_TIME = { label: '08:00 AM', value: '08:00' };

function InAppMessage(props) {
  const { user, source, totalWeek } = props;
  // Removed message ids
  const removeIds = [];

  // memoize
  const userInfo = useMemo(() => ({ src: user.avatar, name: getUserShortName(user), color: user.color }), [user]);
  const originalData = useMemo(() => {
    const weekIndex = _.get(source, 'weekIndex', 0);
    const dayIndex = _.get(source, 'dayIndex', 0);

    return {
      subject: _.get(source, 'subject', ''),
      messages: _.get(source, 'messages', []),
      time: _.get(source, 'time', DEFAULT_TIME.value),
      day: Number(weekIndex) * 7 + Number(dayIndex),
    };
  }, [source, props.day]);
  const isEditting = useMemo(() => !!source._id, [source]);

  // states
  const [day, setDay] = useState(originalData.day);
  const [messages, setMessages] = useState(originalData.messages);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [time, setTime] = useState({
    label: _.get(
      _.find(TimeOptions, o => o.value === originalData.time),
      'label',
      '-',
    ),
    value: originalData.time,
  });
  const [subject, setTitle] = useState(originalData.subject);
  const [showError, setShowError] = useState(false);
  const [uploadingItems, setUploadingItems] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(source.status);
  const [editable, setEditable] = useState(false);
  const [hasTextInInput, setHasTextInInput] = useState(false);

  //effects
  useEffect(() => {
    return () => {
      revokeMultipleObjectURL(_.map(uploadingItems, 'objectURL'));
    };
  }, []);

  const showClientList = useMemo(
    () =>
      currentStatus === IN_APP_MESSAGE_STATUS.ACTIVE || (currentStatus === IN_APP_MESSAGE_STATUS.PAUSE && !editable),
    [editable, currentStatus],
  );

  const onClose = (showConfirmPopup = true) => {
    const data = {
      oldValue: originalData,
      newValue: { subject, messages, time: time.value, day },
    };
    props.onClose(showConfirmPopup ? data : null);
  };

  const onSubmit = () => {
    if (isSubmitting) {
      return false;
    }

    if (!subject.trim() || !messages.length) {
      return setShowError(true);
    }

    const data = {
      subject: subject.trim(),
      messages: _.map(messages, m => _.omit(m, ['_id', 'objectURL'])),
      day,
      time: time.value,
      type: IN_APP_AUTO_MESSAGE,
    };

    const continueSubmit = () => {
      setIsSubmitting(true);
      Promise.resolve(props.onSubmit(data))
        .then(() => onClose(false))
        .catch(() => setIsSubmitting(false));
    };

    if (hasTextInInput) {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Are you done?"
          content="You have typed a message that has not been added to the list of scheduled messages yet. Would you like to add the message first, or continue to save and exit?"
          onConfirm={continueSubmit}
          confirmButtonTitle="Save & exit"
          cancelButtonTitle="Cancel"
          newStyle={true}
        />,
      );
    } else {
      continueSubmit();
    }
  };

  const onSelectTime = data => {
    setTime(data);
  };

  const onDayChange = data => {
    setDay(data);
  };

  const onSendMessage = message => {
    autoScrollMessages();
    if (messages.length < 3) {
      setMessages(m => [...m, message]);

      if (message.type === MESSAGE_TYPES.FILE) {
        setUploadingItems([...uploadingItems, message._id]);
      }
    }
  };

  const autoScrollMessages = () => {
    setTimeout(() => {
      const element = document.querySelector('.inAppMessageForm__messages');
      if (element) {
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  const onRemoveMessage = index => {
    const newMessages = messages.slice();
    const removeMessages = newMessages.splice(index, 1);
    removeIds.push(messages[index]._id);

    if (removeMessages[0].objectURL) {
      revokeObjectURL(removeMessages[0].objectURL);
      if (!_.isEmpty(uploadingItems) && uploadingItems.includes(removeMessages[0]._id)) {
        setUploadingItems(uploadingItems.filter(id => id !== removeMessages[0]._id));
      }
    }

    setMessages(newMessages);
  };

  const onUpdateTitle = event => {
    setTitle(event.target.value);
  };

  const isRemovedFromLocal = mId => {
    return removeIds.includes(mId);
  };

  const onUploadSuccess = ({ mId, data }) => {
    const newMessages = messages.slice();

    // Nothing if message is removed
    if (!isRemovedFromLocal(mId)) {
      const index = _.findIndex(messages, m => m._id === mId);

      if (messages[index]) {
        const {
          attachment: name,
          attachment_type: fileType,
          uploadConfigs: { url = '' },
        } = messages[index];
        mediaLog({
          status: 2,
          name,
          fileType,
          description: 'Upload success file via InAppMessage',
        });
        newMessages.splice(index, 1, {
          ..._.omit(messages[index], 'uploadConfigs'),
          attachment: url,
          attachment_id: getAttachmentIDFromUrl(url),
        });
      }
      setMessages(newMessages);
    }

    const newUploadingItems = uploadingItems.slice();
    _.remove(newUploadingItems, id => id === mId);

    setUploadingItems(newUploadingItems);
  };

  const onCancelUpload = ({ mId }) => {
    const index = _.findIndex(messages, m => m._id === mId);

    if (index !== -1) {
      onRemoveMessage(index);
      const newUploadingItems = uploadingItems.slice();
      _.remove(newUploadingItems, id => id === mId);

      setUploadingItems(newUploadingItems);
      onRemoveMessage(index);
    }
  };

  const onPause = () => {
    props.pauseMessage().then(() => {
      setCurrentStatus(IN_APP_MESSAGE_STATUS.PAUSE);
    });
  };

  const onResume = () => {
    props.resumeMessage().then(() => {
      setCurrentStatus(IN_APP_MESSAGE_STATUS.ACTIVE);
      toast('The message has been resumed');
    });
  };

  const onPauseAndEdit = () => {
    props.pauseMessage().then(() => {
      setCurrentStatus(IN_APP_MESSAGE_STATUS.PAUSE);
      setEditable(true);
    });
  };

  const onEdit = () => {
    setEditable(true);
  };

  return (
    <S.ModalWrapper
      open={true}
      onClose={onClose}
      className={classnames('modal--in-app-message', { 'with-client-list': showClientList })}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.InAppMessageWrapper className="inAppMessageForm">
          <ModalLayout.Header className="inAppMessageForm__header">
            <div className="inAppMessageForm__header__title">Schedule an In-app Message</div>
            <div className="inAppMessageForm__header__description">
              These in-app messages will be sent based on the{' '}
              <span style={{ fontWeight: 600 }}>Client’s timezone.</span>
            </div>
            {currentStatus === IN_APP_MESSAGE_STATUS.PAUSE && !editable ? <div className="paused">Paused</div> : null}
          </ModalLayout.Header>
          <ModalLayout.Content className="inAppMessageForm__content">
            <Schedule
              subject={subject}
              day={day}
              time={time}
              showError={showError}
              onUpdateTitle={onUpdateTitle}
              onSelectTime={onSelectTime}
              onChangeScheduleDay={onDayChange}
              totalWeek={totalWeek}
              currentStatus={currentStatus}
              disabled={isEditting && !editable}
            />
            <S.Messages className="inAppMessageForm__messages">
              {_.map(messages, (m, index) => (
                <MessageItem
                  key={m._id}
                  message={m}
                  avatarData={userInfo}
                  onRemoveMessage={onRemoveMessage}
                  onUploadSuccess={onUploadSuccess}
                  onCancelUpload={onCancelUpload}
                  index={index}
                  disabled={
                    currentStatus === IN_APP_MESSAGE_STATUS.ACTIVE ||
                    (currentStatus === IN_APP_MESSAGE_STATUS.PAUSE && !editable)
                  }
                />
              ))}
              {!!messages.length && <S.Tip>Each message will be sent 5s apart from each other</S.Tip>}
            </S.Messages>
            {!isEditting || (currentStatus === IN_APP_MESSAGE_STATUS.PAUSE && editable) ? (
              <ChatBar
                onSubmit={onSendMessage}
                placeholder="Type a message here"
                disabled={messages.length > 2}
                setHasTextInInput={setHasTextInInput}
              />
            ) : (
              <div className="message-paused-alert">
                <span>
                  {currentStatus === IN_APP_MESSAGE_STATUS.ACTIVE
                    ? 'Messages have been scheduled.'
                    : 'Messages have been paused.'}
                </span>
              </div>
            )}
          </ModalLayout.Content>
          <ModalLayout.Actions className="inAppMessageForm__actions">
            {!isEditting ? (
              <>
                <div className="remaining">Messages remaining: {3 - messages.length}/3</div>
                <Button disabled={!messages.length || uploadingItems.length} onClick={onSubmit} purple>
                  Schedule
                </Button>
              </>
            ) : (
              <Actions
                currentStatus={currentStatus}
                editable={editable}
                disableSubmitting={!messages.length || uploadingItems.length}
                totalMessages={messages.length}
                onPause={onPause}
                onResume={onResume}
                onPauseAndEdit={onPauseAndEdit}
                onScheduleAndResume={onSubmit}
                onEdit={onEdit}
              />
            )}
          </ModalLayout.Actions>
        </S.InAppMessageWrapper>
        {showClientList && <ClientList message={source} />}
      </Modal.Content>
    </S.ModalWrapper>
  );
}

InAppMessage.propTypes = {
  source: PropTypes.object.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default InAppMessage;
