import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { mongoObjectId } from 'utils/commonFunction';
import * as S from './style';

const filterList = [
  {
    _id: mongoObjectId(),
    text: 'All',
    value: null,
  },
  {
    _id: mongoObjectId(),
    text: 'Front',
    value: 'front',
  },
  {
    _id: mongoObjectId(),
    text: 'Back',
    value: 'back',
  },
  {
    _id: mongoObjectId(),
    text: 'Left',
    value: 'left',
  },
  {
    _id: mongoObjectId(),
    text: 'Right',
    value: 'right',
  },
];
const Filter = ({ handleFilter, loading, filters }) => {
  const filter = filterList.find(item => get(item, 'value', null) === get(filters, 'tag', null));

  return (
    <S.Filter>
      {filterList.map(item => {
        const selected = item.value === filter.value;
        return (
          <button
            disabled={selected}
            key={item._id}
            onClick={() => {
              if (!loading) {
                handleFilter(item.value);
              }
            }}
            className={selected ? 'selected' : ''}
          >
            {item.text}
          </button>
        );
      })}
    </S.Filter>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: { loading, filters },
    },
  } = state;
  return { loading, filters: filters };
};

export default connect(mapStateToProps, null)(Filter);
