import styled, { css } from 'styled-components';
import S3Images from 'shared/S3Images';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
`;

export const Thumbnail = styled(S3Images)`
  display: flex;
  img {
    width: 100%;
    height: 146px;
    object-fit: cover;
    ${({ isRecipe }) =>
      isRecipe &&
      css`
        height: 130px;
      `}
  }
`;

export const Content = styled.div`
  ${baseText}
  padding: 15px;
  border: 1px solid #f0f0f2;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${({ isRecipe }) =>
    isRecipe &&
    css`
      font-size: 12px;
      line-height: 18px;
      min-height: 103px;
      padding: 12px 14px 13px;
      border: 1px solid transparent;

      span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    `}
`;

export const RecipeName = styled.div`
  height: 54px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const RecipeUnit = styled.div`
  ${baseText}
  color: #7B7E91;
  font-size: 11px;
  font-weight: 400;
  line-height: 150%;

  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
`;

export const SeparateDot = styled.span`
  width: 2px;
  height: 2px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #7b7e91;
`;

export const ItemNutrition = styled.div`
  max-width: fit-content;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  &:first-child {
    ${SeparateDot} {
      display: none;
    }
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  .week {
    ${baseText}
    font-size: 10px;
    line-height: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 0 10px;
    position: absolute;
    bottom: 10px;
    left: 15px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(1.5px);
  }
  .view-detail {
    width: 24px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
    &:hover {
      background-color: #f0f1ff;
      svg path {
        fill: #5158cf;
      }
    }
  }
  .checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 7px;
    border: 1px solid #e1e1ea;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
  }
  .__react_component_tooltip.client-view-recipe {
    border-radius: 4px;
    background: #333;
    padding: 8px 16px;
    &.place-top:after {
      border-top-color: #333;
      bottom: -9px;
    }
    &.place-top {
      margin-top: -15px;
    }
    div {
      ${baseText}
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      .view-detail {
        cursor: not-allowed;
      }
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: ${({ isRecipe }) => (isRecipe ? '1px solid #f0f0f2' : '1px solid transparent')};
  cursor: pointer;
  &:hover {
    border: 1px solid #5158cf;
    ${Thumbnail} {
      padding: 5px 5px 0;
      img {
        height: 141px;
        ${({ isRecipe }) =>
          isRecipe &&
          css`
            height: 125px;
          `}
      }
    }
    ${Content} {
      border-color: transparent;
    }
    ${ThumbnailWrapper} {
      .view-detail {
        display: flex;
      }
      .checkbox {
        background: #f0f1ff;
        border-width: 2px;
      }
    }
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid #5158cf;
      ${Thumbnail} {
        padding: 5px 5px 0;
        img {
          height: 141px;
          ${({ isRecipe }) =>
            isRecipe &&
            css`
              height: 125px;
            `}
        }
      }
      ${Content} {
        border-color: transparent;
      }
      ${ThumbnailWrapper} {
        .checkbox {
          background: #5158cf !important;
          border-width: 1px !important;
          svg {
            display: block;
          }
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `}
`;
