import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import InboxRooms from './component';

import {
  openChat,
  toggleModal,
  createNewConversation,
  changeProfileId,
  searchAllRooms,
  resetQueryParams,
  getLoadMoreRooms,
  searchRooms,
  resetSearchRoom,
  addSelectedRoomToList,
} from 'redux/inbox-new/actions';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';

const mapState = state => {
  const {
    cloudfrontList,
    user,
    rootReducer: {
      inboxNew: {
        rooms,
        currentRoomId,
        newConversation,
        openArchivedMessage,
        viewTeammate,
        roomInformation,
        loadedRooms,
        isFirstLoad,
        usersInfo,
        query,
        loadingRooms,
        loading,
        selectedTeammate,
      },
      permission,
    },
  } = state;

  const totalUnreadConversation = rooms.filter(item => get(item, 'myData.unread', 0) > 0 || get(item, 'unread', 0) > 0)
    .length;

  return {
    cloudfrontList,
    user,
    rooms,
    totalUnreadConversation,
    currentRoomId,
    newConversation,
    openArchivedMessage,
    viewTeammate,
    loadedRooms,
    roomInformation,
    isFirstLoad,
    usersInfo,
    query,
    loadingRooms,
    loading,
    selectedTeammate,
    isGroupChatPermission: get(permission, 'group_chat', false),
  };
};

const mapDispatch = dispatch => ({
  openChat: bindActionCreators(openChat, dispatch),
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  changeProfileId: bindActionCreators(changeProfileId, dispatch),
  createNewConversation: bindActionCreators(createNewConversation, dispatch),
  getS3presignedURLFromLocalDatabase: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
  searchAllRooms: bindActionCreators(searchAllRooms, dispatch),
  resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
  getLoadMoreRooms: bindActionCreators(getLoadMoreRooms, dispatch),
  searchRooms: bindActionCreators(searchRooms, dispatch),
  resetSearchRoom: bindActionCreators(resetSearchRoom, dispatch),
  addSelectedRoomToList: bindActionCreators(addSelectedRoomToList, dispatch),
});

export default connect(mapState, mapDispatch)(InboxRooms);
