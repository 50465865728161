import React, { useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Route, Switch, withRouter } from 'react-router';

import { push } from 'connected-react-router';

import { getAllLabels } from 'redux/category-labels/actions';
import {
  getAllOnDemandWorkouts,
  removeWorkoutFromList,
  addNewOnDemandWorkouts,
  getOnDemandWorkoutDetail,
  changeQueryParams,
  updateShareStatus,
} from 'redux/on-demand-workouts/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { getCustomBrandingData } from 'redux/custom-branding/actions';
import { ON_DEMAND_WORKOUT_MODAL_MODE } from 'constants/commonData';

import OnDemandWorkoutModal from './OnDemandWorkoutModal';
import OnDemandWorkoutsHeader from './OnDemandWorkoutsHeader';
import OnDemandWorkoutList from './OnDemandWorkoutList';

import * as S from './style';

function Container(props) {
  return (
    <Switch>
      <Route
        exact
        path="/home/on-demand-workouts/"
        render={routeProps => <OnDemandWorkouts {...props} {...routeProps} />}
      />
      <Route
        exact
        path="/home/on-demand-workouts/:workoutId/"
        render={routeProps => <OnDemandWorkouts edit {...props} {...routeProps} />}
      />
      <Route
        exact
        path="/home/on-demand-workouts/:workoutId/duplicate"
        render={routeProps => <OnDemandWorkouts duplicate {...props} {...routeProps} />}
      />
    </Switch>
  );
}

function OnDemandWorkouts({
  getAllLabels,
  categoryLabels,
  workouts,
  getAllOnDemandWorkouts,
  removeWorkoutFromList,
  toggleConfirmModal,
  addNewOnDemandWorkouts,
  getCustomBrandingData,
  customBrandingBackground,
  toggleModal,
  edit,
  duplicate,
  match,
  getOnDemandWorkoutDetail,
  push,
  location,
  changeQueryParams,
  query,
  loading,
  total,
  groupLabels,
  user,
  updateShareStatus,
  cloudfrontList,
}) {
  useEffect(() => {
    getAllLabels && getAllLabels();
    getAllOnDemandWorkouts && getAllOnDemandWorkouts(...Array(1), true);
    getCustomBrandingData && getCustomBrandingData();
  }, []);

  useEffect(() => {
    async function renderModal(workoutId, mode) {
      const res = await getOnDemandWorkoutDetail(workoutId);
      if (!res) return;
      const { data } = res;
      const workout = data.data;
      const handleClose = () => {
        toggleModal(false);
        push(`/home/on-demand-workouts`);
      };
      toggleModal(
        true,
        <OnDemandWorkoutModal
          type={workout.type}
          mode={mode}
          onClose={handleClose}
          workout={workout}
          addNewOnDemandWorkouts={addNewOnDemandWorkouts}
        />,
      );
    }
    if (edit) {
      const workoutId = get(match, 'params.workoutId', '');
      if (!workoutId.includes('create_new')) renderModal(workoutId, ON_DEMAND_WORKOUT_MODAL_MODE.EDIT);
    }
    if (duplicate) {
      const workoutId = get(match, 'params.workoutId', '');
      renderModal(workoutId, ON_DEMAND_WORKOUT_MODAL_MODE.DUPLICATE);
    }
  }, [match.params.workoutId]);

  return (
    <S.Wrapper>
      <OnDemandWorkoutsHeader
        categoryLabels={categoryLabels}
        addNewOnDemandWorkouts={addNewOnDemandWorkouts}
        location={location}
        push={push}
        changeQueryParams={changeQueryParams}
        query={query}
        groupLabels={groupLabels}
      />
      <OnDemandWorkoutList
        toggleModal={toggleModal}
        categoryLabels={categoryLabels}
        addNewOnDemandWorkouts={addNewOnDemandWorkouts}
        loading={loading}
        query={query}
        cloudfrontList={cloudfrontList}
        push={push}
        data={workouts}
        removeWorkout={removeWorkoutFromList}
        customBrandingBackground={customBrandingBackground}
        total={total}
        changeQueryParams={changeQueryParams}
        toggleConfirmModal={toggleConfirmModal}
        user={user}
        updateShareStatus={updateShareStatus}
      />
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: { categoryLabels, onDemandWorkouts },
    router: { location },
    cloudfrontList,
  } = state;

  let groupLabels = {
    level: {},
    duration: {},
    others: [],
  };

  get(categoryLabels, 'list', []).forEach(item => {
    if (item.unique_code === 'level' || item.unique_code === 'duration') {
      groupLabels[item.unique_code] = item;
    } else {
      let data = get(groupLabels, 'others', []);
      groupLabels.others = [...data, item];
    }
  });

  return {
    user: state.user,
    categoryLabels: get(categoryLabels, 'list', []),
    workouts: get(onDemandWorkouts, 'list', []),
    customBrandingBackground: state.rootReducer.customBranding.selectedWorkoutBackground,
    location,
    groupLabels,
    query: onDemandWorkouts.query,
    cloudfrontList,
    total: onDemandWorkouts.total,
    loading: onDemandWorkouts.loading,
  };
};

const mapDispatch = dispatch => {
  return {
    getAllLabels: bindActionCreators(getAllLabels, dispatch),
    getAllOnDemandWorkouts: bindActionCreators(getAllOnDemandWorkouts, dispatch),
    addNewOnDemandWorkouts: bindActionCreators(addNewOnDemandWorkouts, dispatch),
    removeWorkoutFromList: bindActionCreators(removeWorkoutFromList, dispatch),
    getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getOnDemandWorkoutDetail: bindActionCreators(getOnDemandWorkoutDetail, dispatch),
    push: bindActionCreators(push, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    updateShareStatus: bindActionCreators(updateShareStatus, dispatch),
  };
};

export default withRouter(connect(mapState, mapDispatch)(Container));
