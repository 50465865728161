import crypto from 'crypto';

export const bootIntercom = user => {
  const intercom_secret_key = process.env.REACT_APP_INTERCOM_KEY || '';
  const hash = crypto.createHmac('sha256', intercom_secret_key).update(user._id).digest('hex');
  const userData = {
    user_id: user._id,
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    user_hash: hash,
  };

  window.intercomSettings = {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    api_base: `https://${process.env.REACT_APP_INTERCOM_APP_ID}.intercom-messenger.com`,
  };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };

      l();

      // if (w.attachEvent) {
      //   w.attachEvent('onload', l);
      // } else {
      //   w.addEventListener('load', l, false);
      // }
    }
  })();

  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    ...userData,
  });
};
