import { connect } from 'react-redux';
import PollPost from './component';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { addPollOption, removePollOption, unvotePollOption, votePollOption } from 'redux/forum/actions';

const mapStateToProps = state => {
  const {
    rootReducer: { forum },
    user,
    cloudfrontList,
  } = state;
  const { members = [], group = {} } = forum || {};
  return { members, user, cloudfrontList, group };
};

const mapDispatchToProps = {
  toggleConfirmModal,
  toggleModal,
  addPollOption,
  removePollOption,
  votePollOption,
  unvotePollOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(PollPost);
