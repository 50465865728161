export const TABLE_COLUMNS = [
  {
    key: 'categories_name',
    name: 'Category',
  },
  {
    key: 'unit_name',
    name: 'Unit',
  },
  {
    key: 'last_interacted',
    name: 'Most recent',
    alignCenter: true,
  },
  // TODO: Remove from ver 3.31.16
  // {
  //   key: 'type',
  //   name: 'Custom',
  //   alignCenter: true,
  // },
];

export const MOST_RECENT_KEY = 'last_interacted';

export const PER_PAGE = 20;

export const ENUM_TYPE_INGREDIENT = {
  SYSTEM: 'system',
  CUSTOM: 'custom',
};
