import React from 'react';
import { CheckBoxIconWrapper, ProgramItemWrapper } from './style'
import { pluralize } from 'utils/commonFunction';


const CheckBoxIcon = ({ active }) => {
  return (
    <CheckBoxIconWrapper active={active}>
      <div className="checkbox__circle" />
    </CheckBoxIconWrapper>
  )
};

export default function ({ program, active, onSelect }) {
  const onClick = () => {
    onSelect(program)
  }
  return (
    <ProgramItemWrapper active={active} onClick={onClick}>
      <div className="programItem__avatar" />
      <div className="programItem__info">
        <div>
          <div className="programItem__info--title">
            {program.title}
          </div>
          <div className="programItem__info--description">
            {program.description}
          </div>
        </div>
        <div className="programItem__info--statistic">
          {program.workout_sets.length} {pluralize('Week', program.workout_sets.length)}&nbsp;-&nbsp;
          {program.total_workouts || 0} {pluralize('Workout', (program.total_workouts || 0))}
        </div>
      </div>
      <CheckBoxIcon active={active} />
    </ProgramItemWrapper>
  )
}