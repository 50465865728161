import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import classnames from 'classnames';
import { setDragItem, handleDragExercise, handleDragSection } from 'redux/workout-builder/actions';

const DragContainerWrapper = styled.div`
  position: absolute;
  height: ${props => (props.isCanExpand ? '40%' : 'calc(50% + 5px)')};
  top: -4.5px;
  right: 0;
  width: 100%;
  z-index: 1;

  :hover {
    border-top: 1px solid #5c5bbd;
  }

  &.bottom {
    top: unset;
    bottom: -3.5px;
    :hover {
      border-top: 0px;
      border-bottom: 1px solid #5c5bbd;
    }
  }
`;

const SectionDropContainer = props => {
  const draggingItem = props.draggingItem ? props.draggingItem.toJS() : null;

  const onMouseUp = e => {
    if (draggingItem) {
      e.stopPropagation();
      if (draggingItem.dragType === 'exercise') {
        props.handleDragExercise({
          dropType: 'section',
          sectionId: props.sectionId,
          index: props.index,
        });
      } else {
        props.handleDragSection({
          dropType: 'section',
          sectionId: props.sectionId,
          index: props.index,
        });
      }
    }
  };

  return (
    <DragContainerWrapper
      data-section-id={props.sectionId}
      data-section-index={props.index}
      data-arrangement={true}
      onMouseUp={onMouseUp}
      className={classnames({ bottom: props.bottom })}
      isCanExpand={props.isCanExpand}
    ></DragContainerWrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    draggingItem: state.rootReducer.workoutBuilder.get('draggingItem'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDragItem: bindActionCreators(setDragItem, dispatch),
    handleDragExercise: bindActionCreators(handleDragExercise, dispatch),
    handleDragSection: bindActionCreators(handleDragSection, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SectionDropContainer);
