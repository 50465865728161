// Libs
import React from 'react';

// Helper
import { conditionalRoundNutrition, getNutrientValue, handleEmptyValue, sortCaloriesToTop } from '../helper';

// Constants
import { listMacronutrients } from '../constants';

// Styles
import * as S from './style';

const Header = props => {
  const { MacroNutrients } = props;

  return (
    <S.NutritionOverviewWrapper>
      {sortCaloriesToTop([...listMacronutrients]).map(item => {
        const { label, unit, type } = item;

        const value = getNutrientValue(MacroNutrients, type);

        return (
          <S.NutritionItem key={type}>
            <S.Unit>
              {handleEmptyValue(conditionalRoundNutrition(value))}
              <span> {unit}</span>
            </S.Unit>
            <S.Name>{label}</S.Name>
          </S.NutritionItem>
        );
      })}
    </S.NutritionOverviewWrapper>
  );
};

export default Header;
