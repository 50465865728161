import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const DropdownOnboardingFlowsWrapper = styled(Popup)`
  &.ui.popup {
    border-radius: 8px !important;
    border: unset !important;
    background: #fff !important;
    box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.2) !important;
    z-index: 3000 !important;

    &.top {
      margin: 0 0 5px !important;
    }

    &.bottom {
      margin: 5px 0 0 !important;
    }
  }

  .popup-content-wrapper {
    width: 315px;
    height: 100%;
    background: #ffffff;
    border-radius: 3px;

    &.scrollable {
      padding: 0 4px 0 0;
    }
  }

  .popup-content-scrollbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 260px;
    overflow: auto;
    padding: 8px 0;

    &::-webkit-scrollbar-track {
      margin-top: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9 !important;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
    }
  }
`;

export const SelectItemWrapper = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  gap: 16px;
  border: none;
  background: transparent;
  width: 100%;

  :not(:last-child) {
    box-shadow: inset 0px -1px 0px #eeeff2;
  }

  &.scrollable {
    padding: 20px 8px 20px 16px;
  }

  &:hover {
    background: #f6f5ff;

    .label {
      color: #5158cf;
    }
  }

  &.active .label {
    color: #5158cf;
    font-weight: 600;
  }

  .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
  }
`;
