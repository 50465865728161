import styled from 'styled-components';

export const CountdownEmptyStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: -16px;

  .description {
    color: #a3a3b5;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    text-align: center;
    margin-top: 2px;
  }

  .banner {
    margin-left: -7px;
  }

  button.create-btn {
    display: flex;
    background-color: transparent;
    gap: 14px;
    align-items: center;
    padding: 8px 16px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #e1e1ea;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-top: 8px;
    width: fit-content;
    border-color: #5158cf;
    color: #5158cf;

    &:hover {
      background-color: #f0f1ff;
      cursor: pointer;
      border-color: #5158cf;
    }
  }
`;
