// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { conditionalRoundNutrition, handleEmptyValue } from 'components/RecipeDetail/helper';

// Assets
import { ReactComponent as MacroIcon } from 'assets/icons/MealPlans/nutrition_chart_icon.svg';

import * as S from './style';

const NutritionOverviewWrapper = props => {
  const { label, list = [] } = props;

  return (
    <S.Wrapper>
      <MacroIcon className="macro-icon" />
      <S.LabelNutrition>{label || 'Weekly Nutrition Averages'}:</S.LabelNutrition>
      <S.List>
        {list.map(item => {
          const { id, label, unit, value } = item;
          return (
            <S.Item key={id}>
              {label && <S.Label>{label}</S.Label>}
              <S.Value isEmpty={isNaN(value)}>{handleEmptyValue(conditionalRoundNutrition(value))}</S.Value>
              {unit}
            </S.Item>
          );
        })}
      </S.List>
    </S.Wrapper>
  );
};

NutritionOverviewWrapper.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
};

export default NutritionOverviewWrapper;
