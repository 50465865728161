import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import round from 'lodash/round';

import { convertFtToFtAndInch, convertMinToFromHourMin, roundNumberBodyMetric } from 'utils/commonFunction';
import { convertUnit } from 'utils/commonFunction';
import MetricChart from 'components/BodyMetricChartNew/components/MetricChart';
import TagChanged from 'components/BodyMetricChartNew/components/TagChanged';
import { convertSecToSecMin, getDurationTime } from 'components/BodyMetricChartNew/chartHelper';
import {
  BAR_CHART_METRIC_TYPES,
  DATA_POINT_KEYS,
  DEFAULT_COLOR,
  KEYS_METRIC_ARRAY,
  METRIC_CHANGED_STATUS,
  SPECIAL_METRICS_CODE,
  getDateFromRange,
} from 'components/BodyMetricChartNew/constants';
import HeartRate from 'components/BodyMetricChartNew/components/SpecialChart/HeartRate';
import SleepChart from 'components/BodyMetricChartNew/components/SpecialChart/Sleep';

import { ReactComponent as DragIcon } from 'assets/icons/drag-icon-2.svg';
import './styles.scss';

export default class BodyMetricChartOverviewNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      isLoading: true,
      target: {},
      dataChanged: null,
      settings: {},
      period_group: 'daily',
    };
  }

  componentDidMount() {
    this.props.updateSleepView({ viewBy: 'duration', isShowStages: false });
    setTimeout(() => {
      this.getChartData();
    }, 500);
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.timeRange !== this.props.timeRange) {
      this.setState({ chartData: [], isLoading: true });
      this.getChartData(nextProp.timeRange);
    }
  }

  getTarget = async () => {
    const { clientId, metric, getBodyMetricTargetOverview } = this.props;
    const { target } = this.state;
    try {
      const response = isEmpty(target)
        ? await getBodyMetricTargetOverview({
            client: clientId,
            unique_code: metric.unique_code,
          })
        : { data: { data: target } };
      const { data: { data } = { data: {} } } = response || {};
      const { ending, starting } = data || {};

      let dataChanged = null;
      if (ending && starting) {
        const diff = ending.value - starting.value;
        const percent = (diff / starting.value) * 100;

        const status = diff > 0 ? METRIC_CHANGED_STATUS.INCREASE : METRIC_CHANGED_STATUS.DECREASE;
        dataChanged = {
          status: diff === 0 ? METRIC_CHANGED_STATUS.NO : status,
          percent: percent === 0 ? 0 : Math.abs(Number(percent).toFixed(1)),
        };
      }

      return { target: data, dataChanged };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  getSettings = async () => {
    const { settings: currentSettings } = this.state;
    if (!isEmpty(currentSettings)) return currentSettings;

    const { metric, getMetricSettingsOverview } = this.props;
    try {
      const response = await getMetricSettingsOverview(metric.unique_code);
      const { data: { data } = { data: {} } } = response || {};
      return (
        data || {
          type: 'line',
          color: null,
          unit: !!metric && !!metric.unit && metric.unit,
          key_metrics: KEYS_METRIC_ARRAY,
        }
      );
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  getPeriodGroup = (rangeFinal, settings, isSpecialBarChart) => {
    const isChartLine = isEmpty(settings.type) || settings.type === 'line';
    const durationMonth = getDurationTime(rangeFinal, 'months');

    if (durationMonth >= 2 && (isSpecialBarChart || !isChartLine)) {
      return 'weekly';
    }

    if (durationMonth >= 6 && isChartLine) {
      return 'weekly';
    }

    return 'daily';
  };

  getChartData = async range => {
    const {
      clientId,
      metric,
      getChartDataLatestByDay,
      timeRange,
      isGroupOverview,
      getHeartRateChartDataOverview,
      getSleepChartDataLatestByDay,
    } = this.props;
    try {
      const target = await this.getTarget();
      const settings = isGroupOverview && !isEmpty(metric.setting) ? metric.setting : await this.getSettings();
      const rangeFinal = range || timeRange;
      const { fromDate, toDate } = getDateFromRange(rangeFinal);
      const isHearRateChart = SPECIAL_METRICS_CODE.HEART_RATE === (metric || {}).unique_code;
      const isSpecialBarChart = BAR_CHART_METRIC_TYPES.includes(get(metric, 'unique_code')) || isHearRateChart;
      const period_group = this.getPeriodGroup({ from_date: fromDate, to_date: toDate }, settings, isSpecialBarChart);
      const timezone = moment.tz.guess();
      const sleepType = metric.unique_code === SPECIAL_METRICS_CODE.SLEEP;

      if (sleepType) {
        const { data } =
          (await getSleepChartDataLatestByDay({
            client: clientId,
            metric_code: metric.unique_code,
            period_group,
            from_date: fromDate,
            to_date: toDate,
            timezone,
            data_point: (settings || {}).data_point || DATA_POINT_KEYS.latest_value,
          })) || {};
        const chartData = get(data, 'data.details', []);
        const sleepDuration = get(data, 'data.summary_data.duration', '');
        this.setState({ chartData, ...target, sleepDuration, settings, period_group });
      } else {
        const { data: chartDataRespond = {} } = isHearRateChart
          ? (await getHeartRateChartDataOverview({
              client: clientId,
              period_group,
              from_date: fromDate,
              to_date: toDate,
              timezone,
            })) || {}
          : (await getChartDataLatestByDay({
              client: clientId,
              metric_code: metric.unique_code,
              period_group,
              from_date: fromDate,
              to_date: toDate,
              timezone,
              data_point: (settings || {}).data_point || DATA_POINT_KEYS.latest_value,
            })) || {};

        const goal = target.data ? target.data.value : 0;
        const {
          data = [],
          previous_day = '',
          previous_value = '',
          previous_entry = {},
          value_range = {},
        } = chartDataRespond;
        const chartData = isHearRateChart
          ? data
          : data
              .filter(item => !!item && !isNaN(parseInt(item.value)))
              .map(item => ({
                ...item,
                goal,
                period_group,
                value: Number(item.value),
                time: moment(item.date).tz(timezone).format('MMM DD'),
                previousDay: previous_day,
                previousValue: previous_value,
                previousEntry: previous_entry,
                dataPoint: (settings || {}).data_point || DATA_POINT_KEYS.latest_value,
              }));

        this.setState({ chartData, value_range, ...target, settings, period_group });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(s => ({
        ...s,
        isLoading: false,
      }));
    }
  };

  handleConvertValue = data => {
    const { metric, bodymetricTypes } = this.props;
    const { settings } = this.state;
    const shouldConvert = bodymetricTypes.find(it => it.unique_code === metric.unique_code);
    const value = get(data, 'value', 0);
    return value && shouldConvert
      ? convertUnit(
          value,
          get(data, 'unit_data', null) || (metric || {}).unit,
          (settings || {}).unit || (shouldConvert || {}).unit,
        )
      : value;
  };

  handleConvertUnitLabel = () => {
    const { settings } = this.state;
    if (settings.unit) {
      return settings.unit.title || '';
    }
    const { metric, bodymetricTypes } = this.props;
    const shouldConvert = bodymetricTypes.find(it => it.unique_code === metric.unique_code) || { unit: { title: '' } };
    return shouldConvert.unit.title;
  };

  renderValueUnit = (value, unit, shouldRound = true) => {
    return (
      <>
        {shouldRound ? Math.round(value) : value} <span className="unit">{unit}</span>
      </>
    );
  };

  renderFtAndInch = ({ ft, inch }) => {
    return (
      <>
        {ft > 0 && this.renderValueUnit(ft, 'ft ', false)}
        {inch > 0 && this.renderValueUnit(inch, 'in', false)}
      </>
    );
  };

  renderMin = value => {
    const { hour, min } = convertMinToFromHourMin(value);
    return (
      <>
        {hour > 0 && this.renderValueUnit(hour, ' h ')}
        {min > 0 && this.renderValueUnit(min, ' min')}
      </>
    );
  };

  renderSec = value => {
    const { sec, min } = convertSecToSecMin(value);

    return (
      <>
        {min > 0 && this.renderValueUnit(min, 'min ')}
        {sec > 0 && this.renderValueUnit(sec, 'sec')}
      </>
    );
  };

  renderMinMax = range => {
    const { min = 0, max = 0 } = range;
    if (!min && !max) return '—';

    return min === max ? min : `${min} - ${max}`;
  };

  renderChart = () => {
    const { chartData, isLoading, settings = {}, period_group } = this.state;
    const {
      metric,
      bodymetricTypes,
      isDoubleChart,
      timeRange,
      isGroupOverview = false,
      customBrandingColor,
    } = this.props;
    const { fromDate, toDate } = getDateFromRange(timeRange);

    switch (metric.unique_code) {
      case SPECIAL_METRICS_CODE.SLEEP:
        return (
          <SleepChart
            isGroupOverview={isGroupOverview}
            metricTypes={bodymetricTypes}
            bodyMetric={metric}
            rangeTime={{
              from_date: fromDate,
              to_date: toDate,
              period_group,
            }}
            selectedUnit={settings.unit}
            chartData={chartData}
            isLoading={isLoading}
            isOverview
            height={isGroupOverview ? 209 : 134}
            minHeight={isGroupOverview ? 209 : 134}
            isDoubleChart={isDoubleChart}
          />
        );

      case SPECIAL_METRICS_CODE.HEART_RATE:
        return (
          <HeartRate
            chartData={chartData}
            metricTypes={bodymetricTypes}
            bodyMetric={metric}
            rangeTime={{
              from_date: fromDate,
              to_date: toDate,
              period_group,
            }}
            filterTime={{
              from_date: fromDate,
              to_date: toDate,
              period_group,
            }}
            isGroupOverview={isGroupOverview}
            height={isGroupOverview ? 209 : 134}
            minHeight={isGroupOverview ? 209 : 134}
            width={410}
            isDoubleChart={isDoubleChart}
            isLoading={isLoading}
            selectedUnit={settings.unit}
            isOverview
          />
        );

      default:
        return (
          <MetricChart
            metricTypes={bodymetricTypes}
            chartData={chartData}
            bodyMetric={metric}
            rangeTime={{
              from_date: fromDate,
              to_date: toDate,
              period_group,
            }}
            isGroupOverview={isGroupOverview}
            height={isGroupOverview ? 209 : 134}
            minHeight={isGroupOverview ? 209 : 134}
            width={410}
            chartType={BAR_CHART_METRIC_TYPES.includes(get(metric, 'unique_code')) ? 'bar' : settings.type || 'line'}
            isDoubleChart={isDoubleChart}
            color={settings.color || customBrandingColor || DEFAULT_COLOR}
            isLoading={isLoading}
            selectedUnit={settings.unit}
            isOverview
            isDisplayDataPoints={settings.display_data_points}
          />
        );
    }
  };

  renderValueSleepType = () => {
    const { sleepDuration } = this.state;
    const { min, hour } = convertMinToFromHourMin(round(sleepDuration || 0));

    return (
      <>
        {!sleepDuration ? (
          this.renderValueUnit('—', ' min', false)
        ) : (
          <>
            {hour > 0 && this.renderValueUnit(hour, ' h ')}
            {min > 0 && this.renderValueUnit(min, ' min')}
          </>
        )}
      </>
    );
  };

  renderMetricValue = () => {
    const { target, value_range } = this.state;
    const { metric } = this.props;
    const value = target.ending ? roundNumberBodyMetric(this.handleConvertValue(target.ending)) : '';
    const unit = this.handleConvertUnitLabel();
    const isFtUnit = unit === 'ft';
    const isMinUnit = unit === 'min';
    const isSecUnit = unit === 'sec';
    const currFtInch = isFtUnit ? convertFtToFtAndInch(value || 0) : {};

    if (metric.unique_code === SPECIAL_METRICS_CODE.HEART_RATE) {
      return (
        <>
          {this.renderMinMax(value_range)}
          <span className="unit"> {unit}</span>
        </>
      );
    }

    if (metric.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      return this.renderValueSleepType();
    }

    return (
      <>
        {value ? (
          isFtUnit || isMinUnit || isSecUnit ? (
            isFtUnit ? (
              this.renderFtAndInch(currFtInch)
            ) : isMinUnit ? (
              this.renderMin(value)
            ) : (
              this.renderSec(value)
            )
          ) : (
            value
          )
        ) : (
          <>— {isFtUnit && <span className="unit">ft</span>}</>
        )}
        {!isFtUnit && !isMinUnit && !isSecUnit && <span className="unit"> {unit}</span>}
      </>
    );
  };

  renderHeadValue = () => {
    const { target, dataChanged } = this.state;
    const { metric } = this.props;
    const isNotShowDataChange = get(target, 'starting.day', '') === get(target, 'ending.day', '');
    const sleepType = metric.unique_code === SPECIAL_METRICS_CODE.SLEEP;
    const heartRateType = metric.unique_code === SPECIAL_METRICS_CODE.HEART_RATE;

    return (
      <>
        {this.renderMetricValue()}
        {!!dataChanged && !isNotShowDataChange && !sleepType && !heartRateType && (
          <div className="tag-changed-wrapper">
            <TagChanged info={dataChanged} className="tag-change" />
            <div className="compare-tooltip">Compared to Starting value</div>
          </div>
        )}
      </>
    );
  };

  render() {
    const { isLoading, target, sleepDuration, value_range } = this.state;
    const { metric, isDraggable = true } = this.props;
    const value = target.ending ? roundNumberBodyMetric(this.handleConvertValue(target.ending)) : '';
    const isEmptyVal = !value && !sleepDuration && isEmpty(value_range);

    return (
      <div className="eve-panel body-metric-chart-panel-new overview">
        <div className="panel-header">
          <div className="panel-title">{metric.name}</div>
          {isDraggable && <DragIcon className="move-icon" />}
          <div className={classNames('current-value', { 'empty-value': isEmptyVal })}>
            {!isLoading && this.renderHeadValue()}
          </div>
        </div>
        <div className="panel-body">{this.renderChart()}</div>
      </div>
    );
  }
}
