import TextareaAutosize from 'react-autosize-textarea';
import styled, { css } from 'styled-components';

const baseText = css`
  font-family: 'Open Sans';
  line-height: 150%;
  color: #141632;
`;

export const PollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 567px;
  background: #ffffff;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;

  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
`;

export const PollOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const AddMoreOption = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 535px;
  height: 52px;
  padding: 15px;
  border-radius: 8px;
  border: ${({ disableAdd }) => (disableAdd ? '1px solid transparent' : '1px solid #0000001a')};
  ${baseText}
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  gap: 8px;
  cursor: pointer;
  position: relative;
  opacity: ${({ disableAdd }) => (disableAdd ? '0.4' : '1')};

  .plus-icon {
    width: 18px;
    height: 18px;
    min-height: 18px;
  }

  :hover {
    cursor: ${({ disableAdd }) => (disableAdd ? 'not-allowed' : 'pointer')};
    border: ${({ disableAdd }) => (disableAdd ? '1px solid transparent' : '1px solid #5158cf')};
  }

  &.btn-add-more {
    opacity: 0.4;
    border: none;
    &:hover {
      opacity: 1;
      border: 1px solid #5158cf;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  .title-icon {
    min-width: 28px;
  }
`;

export const Title = styled.span`
  display: inline;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  ${baseText}
  line-height: 28px;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ShowMore = styled.span`
  font-size: 18px;
  font-weight: 700;
  ${baseText}
  color: #A6A7B0;
  cursor: pointer;
`;

export const AddMoreOptionInput = styled(TextareaAutosize)`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #141632;
  min-height: 53px;
  padding: 15px !important;
  border-radius: 8px;

  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
  resize: none;
  ::placeholder {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #141632;
    opacity: 0.5;
  }
`;

export const InputContainer = styled.div`
  flex: 1 1;
  border-radius: 8px;
  background: #fff;
  margin: 0px;
  display: flex;
  align-items: center;
  min-height: 53px;
  position: relative;

  border: ${({ errorInput }) => (errorInput ? '1px solid #EA314A' : '1px solid #d6dae4')};

  &:focus-within,
  :hover {
    border: ${({ errorInput }) => (errorInput ? '1px solid #EA314A' : '1px solid #5158cf')};
  }
`;

export const LimitTitle = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 400;
  color: ${({ isFull }) => (isFull ? '#ea314a' : '#7b7e91')};
  margin-left: 10px;
  margin-top: 4px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValidateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  ${baseText}
  color: #EA314A;
  font-size: 12px;
  margin-top: 4px;
  min-width: 10px;
`;

export const RemoveWrapper = styled.div`
  display: flex;
  cursor: pointer;
  position: absolute;
  z-index: 2;

  top: -4px;
  right: -7px;

  .remove-icon {
    width: 16px;
    height: 16px;

    path {
      fill: #111111;
    }
  }
`;
