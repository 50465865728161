import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import WorkoutCollectionBannerSrc from 'assets/images/workout_collections_banner.svg';

export const BannerImage = styled.div`
  position: absolute;
  right: 30px;
  bottom: 0;
  width: 391px;
  height: 240px;
  background: url(${WorkoutCollectionBannerSrc}) no-repeat;
  background-size: container;
`;

export const BannerContainer = styled.div`
  position: relative;
  width: calc(100% - 80px);
  min-height: 226.7px;
  margin: auto;
  margin-bottom: 20px;
  padding: 30px 50px;
  background-color: #f0f8ff;
  border-radius: 8px;

  .buttons__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button {
      :first-child {
        margin-right: 10px;
      }
    }

    .buttonClassName {
      button {
        background-color: #4b90f1 !important;
        padding: 10px 15px;
        width: 184px;
        height: 40px;
        color: #fff;

        svg {
          margin-right: 10px;

          path {
            fill: #fff;
          }
        }
      }
    }
  }

  button {
    &.button--hide-banner {
      border: none;
      background-color: transparent;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #7e7e7e;
      border-radius: 5px;
      transform: translateX(-50%);

      :hover {
        background-color: #bdbab0;
      }

      > * {
        :first-child {
          margin-right: 10px;
        }
      }
    }

    &.button--search-box-trigger {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #50abff;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
      min-width: unset;

      svg {
        path {
          stroke: #50abff;
        }
      }

      :hover {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #50abff;

        svg {
          path {
            stroke: #50abff;
          }
        }
      }
    }
  }

  .search-input {
    width: 272px;
    height: 40px;
    border-radius: 5px;

    input {
      background-color: #ffffff;
      border-color: #fff;
      background-image: url(${CDN_URL}/images/search_black.svg);
      background-position: 11px center;
    }
  }

  .button-hidden-container {
    position: absolute;
    text-align: center;
    bottom: 10.67px;
    left: 50%;
    z-index: 2;
  }
`;

export const BannerTextContent = styled.div`
  max-width: 465px;
  font-weight: normal;
  font-size: 13px;
  line-height: 19.5px;
  color: #1c1919;
  position: relative;
  z-index: 2;

  .collections__banner__label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .collections__banner__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 2px;
  }

  .collections__banner__description {
    margin-bottom: 30px;
    span {
      opacity: 0.8;
    }
  }

  .collections__banner__learnMoreLink {
    margin-top: 10px;
    margin-left: 17px;
    opacity: 1 !important;

    a {
      color: #6456c4;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    img {
      margin-bottom: -3px;
    }
  }
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  .filter-options {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      border: none !important;
      :hover {
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
          0px 0px 4px 0px rgba(0, 0, 0, 0.06);
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
      margin-right: unset;
    }
  }
  .filter-options-header {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      border: 1px solid #d4d7d9 !important;
      margin-right: unset;

      :hover {
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
          0px 0px 4px 0px rgba(0, 0, 0, 0.06);
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
    }
  }
`;

export const CounterFilters = styled.div`
  right: -11px;
  top: -11px;
  width: 22px;
  height: 22px;
  border: 3px solid #fff;
  position: absolute;
  background: #ffbe49;
  border-radius: 6px;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
`;
