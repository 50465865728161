import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import union from 'lodash/union';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';

import { updateSleepView } from 'actions/bodyMetric';

import { LEGEND_STAGE_ORDER, SLEEP_COLOR, SLEEP_STAGE_KEYS, SLEEP_STATES, SLEEP_VIEW } from './constants';
import { PERIOD_GROUP } from 'components/BodyMetricChartNew/constants';
import { convertDataSleepChart, filterDataSleepChart } from './helpers';

import AskToConnectButton from 'components/BodyMetricProgressNew/AskToConnectButton';

import { ReactComponent as AskToConnectIconHideOura } from 'assets/icons/ask-to-connect-circles.svg';
import { ReactComponent as AskToConnectIconHasOura } from 'assets/icons/ask-to-connect-circles-has-oura.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checked_default.svg';

import { LegendWrapper, SleepSwitchWrapper } from './SleepStyle';
import { checkIsOnlyAsleepInBed } from './hourly-helper';

const SleepSwitch = ({
  isLoading,
  sleepSettings,
  chartData,
  dispatch,
  isHourlyChart,
  disabledSleepTime,
  isUsedDevice,
  rangeTime,
}) => {
  const [isHoverSwitch, setIsHoverSwitch] = useState(false);
  const { isShowStages, viewBy } = sleepSettings;
  const isDurationView = viewBy === SLEEP_VIEW.DURATION;
  const isHourlyTimeStage = isHourlyChart && !isDurationView && isShowStages;

  const isOuraPermission = process.env.REACT_APP_ENABLE_OURA_INTEGRATION === 'true';
  const AskToConnectIcon = isOuraPermission ? AskToConnectIconHasOura : AskToConnectIconHideOura;

  const legends = useMemo(() => {
    const chartDataSleepSorted = convertDataSleepChart(chartData, rangeTime);
    const chartDataSleepFilter = filterDataSleepChart(chartDataSleepSorted, sleepSettings);

    let allStage = [];

    if (isDurationView) {
      allStage = chartDataSleepFilter.flatMap(item => item.stage_details.flatMap(s => [s.sleep_stage]));
    } else {
      const result = chartDataSleepFilter.map(item => {
        const { grouped_sleep_in_day } = item || {};
        return { ...item, grouped_sleep_in_day: flatMap(grouped_sleep_in_day) };
      });
      allStage = result.flatMap(item => item.grouped_sleep_in_day.flatMap(s => [s.sleep_stage]));
      if (isHourlyChart && isShowStages) {
        const hasStage = allStage.every(s => ![SLEEP_STAGE_KEYS.in_bed, SLEEP_STAGE_KEYS.asleep].includes(s));
        if (hasStage) {
          allStage = allStage.filter(s => s !== SLEEP_STAGE_KEYS.in_bed);
        }
      }
    }

    const allStageUnion = union(allStage);

    return LEGEND_STAGE_ORDER.filter(key => allStageUnion.includes(key));
  }, [chartData, isShowStages, isDurationView, isHourlyChart]);

  useEffect(() => {
    if (!isLoading) {
      const hasSyncedData = chartData
        .flatMap(item => item.grouped_sleep_in_day)
        .some(item => !['client', 'trainer'].includes(item.source));

      dispatch(
        updateSleepView({
          ...sleepSettings,
          hasAsleepStageInRange: hasSyncedData,
          isShowStages: hasSyncedData ? sleepSettings.isShowStages : false,
        }),
      );
    }
  }, [chartData, isLoading]);

  const handleChangeViewBy = nextView => () => {
    if (nextView === SLEEP_VIEW.TIME && disabledSleepTime) return;

    if (sleepSettings.viewBy !== nextView) {
      dispatch(updateSleepView({ ...sleepSettings, viewBy: nextView }));
    }
  };

  const handleSleepStages = () => {
    if (sleepSettings.connectedApp || sleepSettings.hasAsleepStageInRange) {
      dispatch(updateSleepView({ ...sleepSettings, isShowStages: !sleepSettings.isShowStages }));
    }
  };

  const onMouseEnterSwitch = e => {
    e.stopPropagation();
    setIsHoverSwitch(true);
  };

  const onMouseLeaveSwitch = () => {
    setIsHoverSwitch(false);
  };

  const renderSwitchItem = (key, label, className) => (
    <div
      className={classNames('switch-wrapper-item', className, {
        active: sleepSettings.viewBy === key,
        disabled: key === SLEEP_VIEW.TIME && disabledSleepTime,
      })}
      onClick={handleChangeViewBy(key)}
    >
      <span>{label}</span>
    </div>
  );

  const renderState = (key, index) => {
    const isAsleepManual =
      key === SLEEP_STAGE_KEYS.asleep &&
      isHourlyTimeStage &&
      sleepSettings.hasAsleepStageInRange &&
      !checkIsOnlyAsleepInBed(chartData);

    return (
      <div key={key} style={{ left: index }} className="legend-item">
        <div
          className={classNames('legend-item__box', { 'sleep-special': isAsleepManual })}
          style={{ backgroundColor: isAsleepManual ? undefined : SLEEP_COLOR[key] }}
        />
        <div className="legend-item__label">{isAsleepManual ? SLEEP_STATES.asleep : SLEEP_STATES[key]}</div>
      </div>
    );
  };

  return (
    <>
      {legends.length > 0 && <LegendWrapper hourlyView={isHourlyChart}>{legends.map(renderState)}</LegendWrapper>}
      <SleepSwitchWrapper className="sleep-switch-wrapper">
        <div className="switch-wrapper" onMouseEnter={onMouseEnterSwitch} onMouseLeave={onMouseLeaveSwitch}>
          {renderSwitchItem(SLEEP_VIEW.DURATION, 'Sleep Duration', 'sleep-duration')}
          {renderSwitchItem(SLEEP_VIEW.TIME, 'Sleep Time', 'sleep-time')}
        </div>
        <div
          className={classNames('switch-stages', {
            disabled: !sleepSettings.hasAsleepStageInRange && !sleepSettings.connectedApp,
          })}
          onClick={handleSleepStages}
        >
          <div
            className={classNames('switch-box', {
              active: sleepSettings.isShowStages,
            })}
          >
            {sleepSettings.isShowStages && <CheckIcon stroke="#fff" />}
          </div>
          <span>Show Sleep Stages</span>

          <div
            className={classNames('ask-connect-tooltip', {
              hidden:
                !isUsedDevice || sleepSettings.connectedApp || isHoverSwitch || sleepSettings.hasAsleepStageInRange,
            })}
          >
            <div className="connect-content">
              <div className="text">
                {'Ask the client to connect with a third-party app '}
                <span />
                {'to sync data'}
              </div>
              <AskToConnectIcon />
            </div>
            <AskToConnectButton className="ask-connect-btn" showIcon={false} label="Ask to connect" />
          </div>
        </div>
      </SleepSwitchWrapper>
    </>
  );
};

const mapState = ({ bodyMetric, rootReducer }) => {
  const { last_used_device = false } = get(rootReducer, 'client.workingClientDetail', {}) || {};
  const { sleepSettings, filterTime } = bodyMetric || {};
  const disabledSleepTime = [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY].includes(filterTime.period_group);

  return {
    sleepSettings,
    disabledSleepTime,
    isUsedDevice: !!last_used_device,
  };
};

export default connect(mapState)(SleepSwitch);
