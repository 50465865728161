// Libs
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

// Components
import CategorySelect from '../../CategorySelect';

const CategorySelectWrapper = props => {
  const { ingredientCategory, loading } = props;
  return <CategorySelect {...props} options={ingredientCategory} loading={loading} />;
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes },
  } = state;

  return {
    ingredientCategory: get(recipes, 'ingredient.category', []),
    loading: get(recipes, 'ingredient.loadingIngredientCategory', false),
  };
};

export default connect(mapStateToProps, null)(CategorySelectWrapper);
