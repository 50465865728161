import { hideError, showError } from 'actions/error';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import Request from 'configs/request';
import { push } from 'connected-react-router';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';

export const Types = {
  PROGRESS_PHOTO_V2_UPLOAD_REQUEST: 'PROGRESS_PHOTO_V2_UPLOAD_REQUEST',
  PROGRESS_PHOTO_V2_UPLOAD_SUCCESS: 'PROGRESS_PHOTO_V2_UPLOAD_SUCCESS',
  PROGRESS_PHOTO_V2_UPLOAD_FAILED: 'PROGRESS_PHOTO_V2_UPLOAD_FAILED',
  PROGRESS_PHOTO_CHANGE_MODE: 'PROGRESS_PHOTO_CHANGE_MODE',
  PROGRESS_PHOTO_SELECTED_PHOTO: 'PROGRESS_PHOTO_SELECTED_PHOTO',
  PROGRESS_PHOTO_RESET_DATA: 'PROGRESS_PHOTO_RESET_DATA',
  PROGRESS_PHOTO_UPDATE_FILTERS: 'PROGRESS_PHOTO_UPDATE_FILTERS',
  PROGRESS_PHOTO_GET_LIST_REQUEST: 'PROGRESS_PHOTO_GET_LIST_REQUEST',
  PROGRESS_PHOTO_GET_LIST_SUCCESS: 'PROGRESS_PHOTO_GET_LIST_SUCCESS',
  PROGRESS_PHOTO_GET_LIST_FAIL: 'PROGRESS_PHOTO_GET_LIST_FAIL',
  PROGRESS_PHOTO_FIRST_LOAD: 'PROGRESS_PHOTO_FIRST_LOAD',
  PROGRESS_PHOTO_SELECTED_PHOTO_COMPARE_MODE: 'PROGRESS_PHOTO_SELECTED_PHOTO_COMPARE_MODE',
  PROGRESS_PHOTO_CHANGE_PHOTO_COMPARE_MODE: 'PROGRESS_PHOTO_CHANGE_PHOTO_COMPARE_MODE',
  PROGRESS_PHOTO_DRAG_DROP: 'PROGRESS_PHOTO_DRAG_DROP',
  PROGRESS_PHOTO_REMOVE_PHOTO_COMPARE: 'PROGRESS_PHOTO_REMOVE_PHOTO_COMPARE',
  PROGRESS_PHOTO_UPDATE_TAG_REQUEST: 'PROGRESS_PHOTO_UPDATE_TAG_REQUEST',
  PROGRESS_PHOTO_UPDATE_TAG_SUCCESS: 'PROGRESS_PHOTO_UPDATE_TAG_SUCCESS',
  PROGRESS_PHOTO_UPDATE_TAG_FAILED: 'PROGRESS_PHOTO_UPDATE_TAG_FAILED',
  PROGRESS_PHOTO_DELETE_PHOTO_SUCCESS: 'PROGRESS_PHOTO_DELETE_PHOTO_SUCCESS',
  PROGRESS_PHOTO_UPDATE_INITIAL_FILTER: 'PROGRESS_PHOTO_UPDATE_INITIAL_FILTER',
  PROGRESS_PHOTO_UPDATE_METRIC_SUCCESS: 'PROGRESS_PHOTO_UPDATE_METRIC_SUCCESS',
  PROGRESS_PHOTO_UPDATE_PHOTO_ZOOM: 'PROGRESS_PHOTO_UPDATE_PHOTO_ZOOM',
  PROGRESS_PHOTO_OPEN_ZOOM_SETTING: 'PROGRESS_PHOTO_OPEN_ZOOM_SETTING',
  PROGRESS_PHOTO_RESET_CHANGE_PHOTO_COMPARE_MODE: 'PROGRESS_PHOTO_RESET_CHANGE_PHOTO_COMPARE_MODE',
};

export const changeMode = () => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_CHANGE_MODE });
  };
};

export const selectedPhoto = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_SELECTED_PHOTO, payload: data });
  };
};

export const selectedPhotoCompareMode = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_SELECTED_PHOTO_COMPARE_MODE, payload: data });
  };
};

export const resetDataProgressPhoto = () => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_RESET_DATA });
  };
};

export const handleUploadProgress = (data, callback) => {
  let newData = { ...data };

  if (get(data, 'value') === 0) {
    newData = omit(newData, 'value');
  }

  if (get(data, 'other_metrics[0].value') === 0) {
    newData.other_metrics = [];
  }

  return (dispatch, getState) => {
    dispatch({ type: Types.PROGRESS_PHOTO_V2_UPLOAD_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/v2/measurements`, data: { ...newData } },
        true,
        (response, { dispatch }) => {
          if (response) {
            dispatch({
              type: Types.PROGRESS_PHOTO_V2_UPLOAD_SUCCESS,
            });
            callback && callback();
          }
        },
        (error, { dispatch }) => {
          const status = get(error, 'response.status', 0);
          if (status === 403) {
            dispatch(
              showError(
                'Please check with your workspace admin for permission to use this feature',
                null,
                null,
                null,
                null,
                () => backToOverview(dispatch),
              ),
            );
          }
          dispatch({ type: Types.PROGRESS_PHOTO_V2_UPLOAD_FAILED, error });
        },
      ),
    );
  };
};

export const getProgressPhotoOverviewList = params => {
  return Request.get({ url: '/api/v2/measurements/previews', params });
};

export const updateProgressPhotoFilters = (resetList = false, data = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const idClient = get(state, 'rootReducer.client.workingClientDetail._id', '');
    const filters = get(state, 'rootReducer.progressPhoto.filters', {});
    const nextDay = get(state, 'rootReducer.progressPhoto.nextDay', {});
    dispatch({
      type: Types.PROGRESS_PHOTO_UPDATE_FILTERS,
      payload: {
        params: { ...filters, client: idClient, day: nextDay, ...data },
        resetList,
      },
    });
  };
};

export const getProgressPhotoList = (params, callback, accessDispatch = true) => {
  return (dispatch, getState) => {
    const state = getState();
    const nextDay = get(state, 'rootReducer.progressPhoto.nextDay', {});
    if (!nextDay && accessDispatch) return;
    if (accessDispatch) {
      dispatch({ type: Types.PROGRESS_PHOTO_GET_LIST_REQUEST });
    }
    return dispatch(
      Request.get(
        {
          url: '/api/v2/measurements',
          params,
        },
        false,
        response => {
          if (accessDispatch) {
            const list = get(response, 'data.data.day_data', []);
            const listConvert = list.map(date => {
              const measurementsResult = get(date, 'measurements', []).map(measurement => {
                const measurementId = get(measurement, '_id', '');
                const weight = get(measurement, 'value', null);
                const bodyFat = get(measurement, 'other_metrics.[0].value', null);
                const tagsListConvert = get(measurement, 'tags', []).map(tag => {
                  return { ...tag, measurementId, weight, bodyFat };
                });
                return { ...measurement, tags: tagsListConvert };
              });
              return { ...date, measurements: measurementsResult };
            });
            const nextDay = get(response, 'data.data.next_day', null);
            dispatch({
              type: Types.PROGRESS_PHOTO_GET_LIST_SUCCESS,
              payload: {
                list: listConvert,
                nextDay,
              },
            });
            callback && callback(list);
          }
        },
        () => {
          if (accessDispatch) {
            dispatch({ type: Types.PROGRESS_PHOTO_GET_LIST_FAIL });
          }
        },
      ),
    );
  };
};

export const firstLoad = () => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_FIRST_LOAD });
  };
};

export const updateTag = (tag, callback) => {
  return (dispatch, getState) => {
    const state = getState();

    const photoList = get(state, 'rootReducer.progressPhoto.photoList', []);
    const itemPhoto = get(state, 'rootReducer.progressPhoto.itemPhoto', {});
    const filters = get(state, 'rootReducer.progressPhoto.filters', {});
    const idClient = get(state, 'rootReducer.client.workingClientDetail._id', '');

    const { tag: currentTab = '' } = filters;

    const { measurementId = '', selectedPhoto = '', selectedDate = '' } = itemPhoto;

    const data = {
      tag,
      image: selectedPhoto,
    };

    const dataPhotoList = handleDeleteInPhotoList(photoList, selectedDate, measurementId, selectedPhoto);
    const resultPhotoList = handlePhotosInDayDeleted(dataPhotoList, selectedDate);

    return dispatch(
      Request.post(
        { url: `/api/v2/measurements/${measurementId}/edit-tag`, data },
        true,
        async (response, { dispatch }) => {
          if (response) {
            let dataPayload = {
              type: Types.PROGRESS_PHOTO_UPDATE_TAG_SUCCESS,
              tag,
            };

            if (currentTab && currentTab === tag) {
              const resultItemPhoto = await dispatch(
                getProgressPhotoList(
                  { no_of_day: 1, day: selectedDate, client: idClient, tag: currentTab },
                  null,
                  false,
                ),
              );
              dataPayload.dataItemPhoto = get(resultItemPhoto, 'data.data.day_data[0]', {});
            }

            dispatch(dataPayload);
            if (get(resultPhotoList, 'length', 0) <= 0 && currentTab !== tag) {
              callback && callback();
            }
          }
        },
        (error, { dispatch }) => {
          handleActionAfterError(get(error, 'response.data.message'), dispatch, state);
        },
      ),
    );
  };
};

export const deletePhoto = callback => {
  return (dispatch, getState) => {
    const state = getState();

    const photoList = get(state, 'rootReducer.progressPhoto.photoList', []);
    const itemPhoto = get(state, 'rootReducer.progressPhoto.itemPhoto', {});
    const { measurementId = '', selectedPhoto = '', selectedPhotoListByDate, selectedDate } = itemPhoto;

    const data = {
      image: selectedPhoto,
    };

    const existingSelectedPhotoListByDate = [...selectedPhotoListByDate];
    const dataPhotoList = handleDeleteInPhotoList(photoList, selectedDate, measurementId, selectedPhoto);

    const resultSelectedPhotoListByDate = existingSelectedPhotoListByDate.filter(
      item => get(item, 'image', '') !== selectedPhoto,
    );

    let dataState = {};

    if (get(resultSelectedPhotoListByDate, 'length', null) > 0) {
      // Case: The photos in a day have not been deleted
      const result = handlePhotosInDayNotDeleted(
        existingSelectedPhotoListByDate,
        selectedPhoto,
        dataPhotoList,
        selectedDate,
      );

      dataState = {
        photoList: result.resultPhotoList,
        itemPhoto: {
          ...itemPhoto,
          selectedPhoto: get(result.nextPhotoAction, 'image', ''),
          selectedThumbnailPhoto: get(result.nextPhotoAction, 'image_thumbnail', ''),
          measurementId: get(result.nextPhotoAction, 'measurementId', ''),
          selectedPhotoListByDate: resultSelectedPhotoListByDate,
          weight: get(result.nextPhotoAction, 'weight', ''),
          bodyFat: get(result.nextPhotoAction, 'bodyFat', ''),
          tag: get(result.nextPhotoAction, 'tag', ''),
          weightUnit: get(result, 'nextPhotoAction.unit', {}),
        },
      };
    } else {
      // Case: all photos in one day are deleted
      const result = handlePhotosInDayDeleted(dataPhotoList, selectedDate);

      dataState = {
        photoList: result,
        itemPhoto: {
          ...itemPhoto,
          selectedDate: get(result, '[0].day', ''),
          selectedPhoto: get(result, '[0].measurements.[0].tags.[0].image', ''),
          selectedThumbnailPhoto: get(result, '[0].measurements.[0].tags.[0].image_thumbnail', ''),
          measurementId: get(result, '[0].measurements.[0]._id', ''),
          selectedPhotoListByDate: flatMap(get(result, '[0].measurements', []), 'tags'),
          weight: get(result, '[0].measurements.[0].value', ''),
          bodyFat: get(result, '[0].measurements.[0].other_metrics.[0].value', ''),
          tag: get(result, '[0].measurements.[0].tags.[0].tag', ''),
          weightUnit: get(result, '[0].measurements.[0].unit', {}),
        },
      };
    }

    return dispatch(
      Request.post(
        { url: `/api/v2/measurements/${measurementId}/remove-image`, data },
        true,
        (response, { dispatch }) => {
          if (response) {
            dispatch({
              type: Types.PROGRESS_PHOTO_DELETE_PHOTO_SUCCESS,
              payload: dataState,
            });
            callback && callback(get(dataState, 'photoList', []));
          }
        },
        (error, { dispatch }) => {
          handleActionAfterError(get(error, 'response.data.message'), dispatch, state);
        },
      ),
    );
  };
};

export const changePhotoCompareMode = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_CHANGE_PHOTO_COMPARE_MODE, payload: data });
  };
};

export const dragDropPhoto = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_DRAG_DROP, payload: data });
  };
};

export const removePhotoCompare = photo => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_REMOVE_PHOTO_COMPARE, payload: photo });
  };
};

export const backToOverview = dispatch => {
  dispatch(hideError(false));
  dispatch(toggleModal(false));
  dispatch(push('/home/client'));
};

// Case: The photos in a day have not been deleted
export const handlePhotosInDayNotDeleted = (
  existingSelectedPhotoListByDate,
  selectedPhoto,
  dataPhotoList,
  selectedDate,
) => {
  const indexPhotoDeleted = existingSelectedPhotoListByDate.findIndex(item => get(item, 'image', '') === selectedPhoto);

  let nextPhotoAction = {};

  switch (indexPhotoDeleted) {
    case 0:
      nextPhotoAction = existingSelectedPhotoListByDate[1];
      break;
    case get(existingSelectedPhotoListByDate, 'length', 0) - 1:
      nextPhotoAction = existingSelectedPhotoListByDate[indexPhotoDeleted - 1];
      break;

    default:
      nextPhotoAction = existingSelectedPhotoListByDate[indexPhotoDeleted + 1];
      break;
  }

  const resultPhotoList = dataPhotoList.map(photoDate => {
    if (get(photoDate, 'day', '') === selectedDate) {
      const measurements = get(photoDate, 'measurements', []).filter(
        measurement => get(measurement, 'tags.length', []) !== 0,
      );
      return { ...photoDate, measurements };
    }
    return photoDate;
  });

  return {
    nextPhotoAction,
    resultPhotoList,
  };
};

// Case: all photos in one day are deleted
export const handlePhotosInDayDeleted = (dataPhotoList, selectedDate) => {
  const resultPhotoList = dataPhotoList.map(photoDate => {
    if (get(photoDate, 'day', '') === selectedDate) {
      const measurements = get(photoDate, 'measurements', []).filter(
        measurement => get(measurement, 'tags.length', []) !== 0,
      );
      return { ...photoDate, measurements };
    }
    return photoDate;
  });

  const resultPhotoListConvert = resultPhotoList.filter(item => get(item, 'measurements.length', [] !== 0));
  return resultPhotoListConvert;
};

export const handleDeleteInPhotoList = (photoList, selectedDate, measurementIdStore, selectedPhoto) => {
  const existingPhotoList = [...photoList];
  const dataPhotoList = existingPhotoList.map(photoDate => {
    if (get(photoDate, 'day', '') === selectedDate) {
      const measurements = get(photoDate, 'measurements', []).map(measurement => {
        if (get(measurement, '_id', '') === measurementIdStore) {
          const tags = get(measurement, 'tags', []).filter(tag => get(tag, 'image', '') !== selectedPhoto);
          return { ...measurement, tags };
        }
        return measurement;
      });
      return { ...photoDate, measurements };
    }
    return photoDate;
  });
  return dataPhotoList;
};

export const updateInitialProgressPhotoFilters = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_UPDATE_INITIAL_FILTER, payload: data });
  };
};

export const updateMetric = params => {
  const { measurementId, data, selectedDate } = params;

  let newData = { ...data };

  if (get(data, 'value') === 0) {
    newData = omit(newData, 'value');
  }

  if (get(data, 'other_metrics[0].value') === 0) {
    newData.other_metrics = [];
  }

  return (dispatch, getState) => {
    const state = getState();
    const bodyMetricTypes = get(state, 'rootReducer.client.bodymetricTypes', []);
    const filters = get(state, 'rootReducer.progressPhoto.filters', {});

    const typeWeight = find(bodyMetricTypes, it => get(it, 'unique_code', '') === 'weight');

    const unit = get(typeWeight, 'unit', {});

    return dispatch(
      Request.patch(
        { url: `/api/v2/measurements/${measurementId}`, data: { ...newData, is_update_metric: true } },
        true,
        async (response, { dispatch }) => {
          if (response) {
            let dataPayload = {
              type: Types.PROGRESS_PHOTO_UPDATE_METRIC_SUCCESS,
              payload: { data, unit: pick(unit, ['_id', 'ratio']), measurementId, selectedDate },
            };

            const resultItemPhoto = await dispatch(
              getProgressPhotoList({ ...filters, no_of_day: 1, day: selectedDate }, null, false),
            );

            const resultItemPhotoConvert = get(resultItemPhoto, 'data.data.day_data', []).map(date => {
              const measurementsResult = get(date, 'measurements', []).map(measurement => {
                const measurementId = get(measurement, '_id', '');
                const weight = get(measurement, 'value', null);
                const bodyFat = get(measurement, 'other_metrics.[0].value', null);
                const tagsListConvert = get(measurement, 'tags', []).map(tag => {
                  return { ...tag, measurementId, weight, bodyFat, unit: get(measurement, 'unit', {}) };
                });
                return { ...measurement, tags: tagsListConvert };
              });
              return { ...date, measurements: measurementsResult };
            });

            dataPayload.payload.listPhotoByDate = get(resultItemPhotoConvert, '[0]', {});
            dispatch(dataPayload);
          }
        },
        (error, { dispatch }) => {
          handleActionAfterError(get(error, 'response.data.message'), dispatch, state, true);
        },
      ),
    );
  };
};

export const updatePhotoZoom = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_UPDATE_PHOTO_ZOOM, payload: data });
  };
};

export const openZoomSetting = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_OPEN_ZOOM_SETTING, payload: data });
  };
};

export const resetChangePhotoCompareMode = () => {
  return dispatch => {
    dispatch({ type: Types.PROGRESS_PHOTO_RESET_CHANGE_PHOTO_COMPARE_MODE });
  };
};

export const handleActionAfterError = (message, dispatch, state, isUpdateMetric = false) => {
  dispatch(
    showError(message, null, null, null, null, () => {
      const idClient = get(state, 'rootReducer.client.workingClientDetail._id', '');
      const timezone = get(state, 'rootReducer.client.workingClientDetail.timezone', '');
      const compareMode = get(state, 'rootReducer.progressPhoto.compareMode', false);

      if (isUpdateMetric) {
        dispatch(toggleSecondModal(false));
      }
      dispatch(hideError(false));
      dispatch(firstLoad());
      dispatch(
        updateProgressPhotoFilters(true, {
          no_of_day: 20,
          day: timezone
            ? DateTime.now().setZone(timezone).toFormat('MM-dd-yyyy')
            : DateTime.now().toFormat('MM-dd-yyyy'),
          client: idClient,
          tag: null,
        }),
      );

      if (compareMode) {
        dispatch(changeMode());
      }
    }),
  );
};
