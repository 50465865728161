import { handleScrollToReply } from 'helpers/replyComment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProgressPhotoList, updateProgressPhotoFilters } from 'redux/progress-photo/actions';
import LoadingIndicator from 'shared/LoadingIndicator';
import { roundNumberBodyMetric } from 'utils/commonFunction';
import { BODY_METRIC_TYPES, PER_PAGE_LOAD_MORE, PER_PAGE } from '../../constants';
import { handleConvertUnit, handleScrollToPhoto } from '../../helper';
import Action from './Action';
import Filter from './Filter';
import ItemPhoto from './ItemPhoto';
import * as S from './style';

const LeftPanel = ({
  selectedDate = '',
  selectedPhoto = '',
  filters = {},
  getProgressPhotoList,
  loading,
  updateProgressPhotoFilters,
  photoList,
  titleWeight,
  handleAddPhoto,
  nextDay,
  onClose,
  bodymetricTypes,
  timezone,
}) => {
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);

  useEffect(() => {
    handleScrollToReply(selectedDate, 300, {});
    handleScrollToPhoto(selectedDate, selectedPhoto);
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(filters) && nextDay) {
      setLoadingLoadMore(true);
      getProgressPhotoList &&
        getProgressPhotoList(filters, photoList => {
          if (get(photoList, 'length', 0) <= 0 && !filters.tag) {
            handleAddPhoto && handleAddPhoto();
            onClose && onClose();
          }
        }).finally(() => {
          setLoadingLoadMore(false);
        });
    }
  }, [filters]);

  const handleFilter = tag => {
    updateProgressPhotoFilters &&
      updateProgressPhotoFilters(true, {
        day: timezone ? DateTime.now().setZone(timezone).toFormat('MM-dd-yyyy') : DateTime.now().toFormat('MM-dd-yyyy'),
        tag,
        no_of_day: PER_PAGE,
      });
  };

  const handleScrollList = () => {
    const listPhoto = document.getElementById('list-progress-photo');
    if (listPhoto && !loadingLoadMore && nextDay) {
      const tolerancePercentage = 5;
      const tolerance = (tolerancePercentage / 100) * listPhoto.clientHeight;
      const isAtBottom = listPhoto.scrollTop + listPhoto.clientHeight + tolerance >= listPhoto.scrollHeight;

      if (isAtBottom) {
        updateProgressPhotoFilters && updateProgressPhotoFilters(false, { no_of_day: PER_PAGE_LOAD_MORE });
      }
    }
  };

  const getLatestUpdateItem = array => {
    let maxTimestamp = null;
    let itemWithMaxTimestamp = null;

    for (const item of array) {
      const timestamp = Date.parse(item.value_updated_at);

      if (maxTimestamp === null || timestamp > maxTimestamp) {
        maxTimestamp = timestamp;
        itemWithMaxTimestamp = item;
      }
    }
    return itemWithMaxTimestamp;
  };

  return (
    <S.LeftPanel data-progress-photo="left-panel">
      <Action handleAddPhoto={handleAddPhoto} />
      <Filter handleFilter={handleFilter} />
      <S.List id="list-progress-photo" onScroll={debounce(handleScrollList, 300)}>
        {loading ? (
          <S.LoadingIndicatorWrapper title="Loading..." />
        ) : photoList && photoList.length > 0 ? (
          photoList.map(item => {
            const { measurements = [], day = '' } = item;

            const itemWithMaxTimestamp = getLatestUpdateItem(measurements);

            const weight = get(itemWithMaxTimestamp, `value`, null);
            const weightUnit = get(itemWithMaxTimestamp, `unit`, {});
            const weightConvert = handleConvertUnit(weight, weightUnit, bodymetricTypes);
            const bodyFat = get(itemWithMaxTimestamp, `other_metrics.[0].value`, null);
            return (
              <ItemPhoto
                list={measurements}
                key={day}
                day={day}
                weight={weight ? `${roundNumberBodyMetric(weightConvert)} ${titleWeight}` : null}
                bodyFat={bodyFat ? `${roundNumberBodyMetric(bodyFat)}%` : null}
              />
            );
          })
        ) : (
          <S.Empty>No results found</S.Empty>
        )}
        {loadingLoadMore && !loading && get(photoList, 'length', 0) !== 0 && (
          <LoadingIndicator title="Loading..." className="loading-photo" />
        )}
      </S.List>
    </S.LeftPanel>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: {
        itemPhoto: { selectedDate, selectedPhoto },
        filters,
        loading,
        photoList,
        nextDay,
      },
      client: {
        bodymetricTypes = [],
        workingClientDetail: { timezone },
      },
    },
  } = state;
  const weight = bodymetricTypes.find(item => item.unique_code === BODY_METRIC_TYPES.WEIGHT);
  const titleWeight = get(weight, 'unit.title', '');
  return {
    selectedDate,
    selectedPhoto,
    filters,
    loading,
    photoList,
    titleWeight,
    nextDay,
    bodymetricTypes,
    timezone,
  };
};

const mapDispatchToProps = dispatch => ({
  getProgressPhotoList: bindActionCreators(getProgressPhotoList, dispatch),
  updateProgressPhotoFilters: bindActionCreators(updateProgressPhotoFilters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
