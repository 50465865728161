import React, { useEffect } from 'react';
import { ReactComponent as GoalIcon } from 'assets/icons/goal-line.svg';
import isEmpty from 'lodash/isEmpty';

const CustomGoalDot = ({ viewBox, isSleep = false, className = '' }) => {
  const { x, y, width } = viewBox || { x: 0, y: 0, width: 0 };

  useEffect(() => {
    const goalLineElm = document.querySelectorAll('.custom-goal-line .recharts-reference-line-line');

    if (!isEmpty(goalLineElm)) {
      goalLineElm.forEach(element => {
        element.setAttribute('x1', x + 15);
        element.setAttribute('x2', width + x - 5);
      });
    }
  }, []);

  let xGoal = x - 5;
  let yGoal = y - 10;

  if (isSleep) {
    xGoal -= 2;
    yGoal -= 10;
  }

  return <GoalIcon x={xGoal} y={yGoal} fill="#fff" className={className} />;
};

export default CustomGoalDot;
