import React, { useState } from 'react';
import Avatar from 'react-avatar';
import get from 'lodash/get';
import map from 'lodash/map';
import { Button, Image, Modal } from 'semantic-ui-react';
import { getUserShortName } from 'utils/commonFunction';
import { connect } from 'react-redux';

import * as S from './styles';
import moment from 'moment';
import LoadingIndicator from 'shared/LoadingIndicator';
import { CDN_URL } from 'constants/commonData';

const ResponseSurveyPopup = ({ onClose, data, surveySubmissionLoading, surveySubmission }) => {
  const fullName = get(data, 'first_name', '') + ' ' + get(data, 'last_name', '');
  const email = get(data, 'email', '');
  const createdAt = get(surveySubmission, 'created_at', '');
  const surveyName = get(surveySubmission, 'survey.survey_name', '');
  const surveyList = get(surveySubmission, 'question_answers', []);
  const [isScrollTop, setIsScrollTop] = useState(true);

  const handleScroll = event => {
    const contentWrapper = event && event.target;
    if (contentWrapper) {
      const isAtTop = event.target.scrollTop === 0;
      if (isScrollTop !== isAtTop) {
        setIsScrollTop(isAtTop);
      }
    }
  };

  return (
    <S.ModalWrapper open={true} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>
        <Button className="close-button" onClick={onClose}>
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        {!surveySubmissionLoading && (
          <S.SurveyNameWrapper>
            <span className="survey-name">{surveyName}</span>
            <span className="create-at">{`Submitted: ${moment(createdAt).format('HH:mm MMM D, YYYY')}`}</span>
          </S.SurveyNameWrapper>
        )}
      </Modal.Header>
      <Modal.Content>
        {!surveySubmissionLoading && (
          <S.InformationWrapper isScrollTop={isScrollTop}>
            <Avatar
              name={getUserShortName(data)}
              size="35"
              src={get(data, 'avatar.s', '')}
              color={get(data, 'color')}
            />
            <div className="info">
              <span className="info-name">{fullName}</span>
              <span className="info-email">{email}</span>
            </div>
          </S.InformationWrapper>
        )}
        <S.ResponseSurveyWrapper onScroll={handleScroll}>
          {surveySubmissionLoading ? (
            <div className="loading-wrapper">
              <LoadingIndicator className="loading-indicator" title="Loading..." size="medium" />
            </div>
          ) : (
            map(surveyList, (item, index) => {
              const question = get(item, 'question', '');
              const answer = get(item, 'text_answer', '');
              return (
                <div className="response-survey" key={`${question}-${answer}`}>
                  <span className="response-title">{`${index + 1}. ${question.trim()}`}</span>
                  <span className="response-content">{answer.trim()}</span>
                </div>
              );
            })
          )}
        </S.ResponseSurveyWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ButtonAction onClick={onClose} purple>
          OK
        </S.ButtonAction>
      </Modal.Actions>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      contactList: { surveySubmission, surveySubmissionLoading },
    },
  } = state;
  return {
    surveySubmissionLoading,
    surveySubmission,
  };
};

export default connect(mapStateToProps)(ResponseSurveyPopup);
