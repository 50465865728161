import React from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import * as S from './style';
import {
  getProgramLibrary
} from 'redux/program_library/program_library.actionCreators';
import { addStudioProgramsToClient } from 'redux/client-studio/actions';
import { Button } from 'shared/FormControl';
import StudioProgramItem from './StudioProgramItem';
import { axiosInstance } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

const PER_PAGE = 100;
class AddStudioProgramClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      textSearch: '',
      selectedProgram: {}
    }
    this.loadingRef = React.createRef();
    this.handleSearch = _.debounce(this.handleSearch, 300);
  }

  componentDidMount() {
    this.handleSearch();
    this.initIntersectionObserver();
  }

  componentDidUpdate() {
    if (!this.observer) {
      this.initIntersectionObserver();
    }
  }

  initIntersectionObserver = () => {
    if (this.loadingRef.current) {
      var options = {
        root: null, // Page as root
        rootMargin: '0px',
        threshold: 1.0
      };
      // Create an observer
      this.observer = new IntersectionObserver(
        this.handleObserver, //callback
        options
      );
      this.observer.observe(this.loadingRef.current);
    }
  }

  handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    const { isLoading, hasMore } = this.state;
    if (this.state.prevY > y && !isLoading && hasMore) {
      this.loadMore();
    }
    this.setState({ prevY: y });
  }

  loadMore = () => {
    const { textSearch, page } = this.state;
    this.handleSearch(textSearch, page + 1);
  }

  onChangeSearch = (e) => {
    this.setState({ textSearch: e.target.value, searchResults: [], selectedProgram: {}, isSelectedAll: false });
    this.handleSearch(e.target.value);
  }

  handleSearch = (textSearch, nextPage = 1) => {
    const { studioPrograms } = this.props;
    const params = {
      search: textSearch || '',
      isPublished: true,
      except: _.map(studioPrograms, 'studio_program'),
      page: nextPage,
      per_page: PER_PAGE
    }
    this.setState({ isLoading: true });
    axiosInstance.post('/api/studio-program/fetch-by-trainer', params).then(response => {
      const { data, page } = response.data;
      const { searchResults } = this.state;
      this.setState({ searchResults: [...searchResults,...data], page, hasMore: PER_PAGE <= data.length });
    }).finally(() => {
      this.setState({ isLoading: false });
    })
  }

  onSelectProgram = (program) => {
    const { selectedProgram, searchResults } = this.state;
    selectedProgram[program._id] = !selectedProgram[program._id];
    this.setState({
      selectedProgram: { ...selectedProgram },
      isSelectedAll: this.isSelectedAll(searchResults, selectedProgram)
    });
  }

  handleSelectAll = (e) => {
    const { searchResults } = this.state;
    if (e.target.checked) {
      const selectedProgram = _.reduce(searchResults, (obj, item) => {
        obj[item._id] = true;
        return obj;
      }, {})
      this.setState({ selectedProgram, isSelectedAll: e.target.checked });
    } else {
      this.setState({ selectedProgram: {}, isSelectedAll: e.target.checked });
    }
  }

  isSelectedAll = (searchResults, selectedProgram) => {
    const selected = _.keys(selectedProgram).filter(id => !!selectedProgram[id]);
    return selected.length === searchResults.length;
  }

  onClearSearch = () => {
    this.setState({
      textSearch: '',
      searchResults: []
    })
  }

  handleClose = () => {
    this.props.toggleModal(false);
  }

  onSubmit = () => {
    this.setState({ isSubmitting: true });
    const { selectedProgram } = this.state;
    const { selectedClient } = this.props;
    const ids = _.keys(selectedProgram).filter(id => !!selectedProgram[id]);
    this.props.addStudioProgramsToClient({ ids, client: selectedClient._id }).then(response => {
      this.handleClose();
      this.props.onAddSuccess();
    })
    .finally(() => {
      this.setState({ isSubmitting: true });
    });
  }

  render() {
    const { textSearch, searchResults, selectedProgram, isSubmitting, isLoading, isSelectedAll } = this.state;
    const hasProgramSelect = _.keys(selectedProgram).filter(id => !!selectedProgram[id]).length;
    return (
      <S.AddStudioProgramClientModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <div className="header__title">
            Add a Studio Program
          </div>
          <S.SearchProgramInput
            value={textSearch}
            onChange={this.onChangeSearch}
            onClearSearch={this.onClearSearch}
            placeholder="Search program name"
          />
        </Modal.Header>
        <Modal.Content>
          <div className="programResult__heading">
            <div className="programResult__title">
              All Programs ({searchResults.length})
            </div>
            <div className="programResult__selectAll">
              <span className="programResult__selectAll--text">Select All</span>
              <S.CheckboxProgram checked={isSelectedAll} onChange={this.handleSelectAll} />
            </div>
          </div>
          <S.ProgramResultContainer>
            {!searchResults.length && (<div className="programResult__noResult">
              {isLoading ? 'Searching...' : 'No results found'}
            </div>)}
            {searchResults.map(item => (
              <StudioProgramItem
                key={item._id}
                program={item}
                onSelect={this.onSelectProgram}
                active={selectedProgram[item._id]}
              />
            ))}
            <div ref={this.loadingRef} />
          </S.ProgramResultContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button className="actions__cancelBtn" onClick={this.handleClose}>Cancel</Button>
          <Button
            className="actions__createBtn"
            disabled={!hasProgramSelect || isSubmitting}
            purple
            onClick={this.onSubmit}
          >
            Add
          </Button>
        </Modal.Actions>
      </S.AddStudioProgramClientModal>
    );    
  }
}

const mapStateToProps = state => {
  return {
    programs: state.rootReducer.program_library.all_items || [],
    selectedClient: state.rootReducer.client.workingClientDetail,
  };
}

const actionCreators = { toggleModal, push, getProgramLibrary, addStudioProgramsToClient };

export default connect(mapStateToProps, actionCreators)(AddStudioProgramClient);
