import _ from 'lodash';
import Request from 'configs/request';
import { Mixpanel } from 'utils/mixplanel';

export const Types = {
  PERSONAL_REMINDER_GET_LIST: 'PERSONAL_REMINDER_GET_LIST',
  PERSONAL_REMINDER_GET_LIST_SUCCESS: 'PERSONAL_REMINDER_GET_LIST_SUCCESS',
  PERSONAL_REMINDER_GET_COMPLETED_LIST: 'PERSONAL_REMINDER_GET_COMPLETED_LIST',
  PERSONAL_REMINDER_GET_COMPLETED_LIST_SUCCESS: 'PERSONAL_REMINDER_GET_COMPLETED_LIST_SUCCESS',
  PERSONAL_REMINDER_GET_FOLLOWING_LIST: 'PERSONAL_REMINDER_GET_FOLLOWING_LIST',
  PERSONAL_REMINDER_GET_FOLLOWING_LIST_SUCCESS: 'PERSONAL_REMINDER_GET_FOLLOWING_LIST_SUCCESS',
  PERSONAL_REMINDER_MARK_AS_COMPLETED: 'PERSONAL_REMINDER_MARK_AS_COMPLETED',
  PERSONAL_REMINDER_ADD_TASK: 'PERSONAL_REMINDER_ADD_TASK',
  PERSONAL_REMINDER_ADD_TASK_SUCCESS: 'PERSONAL_REMINDER_ADD_TASK_SUCCESS',
  PERSONAL_REMINDER_UPDATE_DATA: 'PERSONAL_REMINDER_UPDATE_DATA',
  PERSONAL_REMINDER_UPDATE_DATA_SUCCESS: 'PERSONAL_REMINDER_UPDATE_DATA_SUCCESS',

  PERSONAL_REMINDER_GET_TASK: 'PERSONAL_REMINDER_GET_TASK',
  PERSONAL_REMINDER_GET_TASK_SUCCESS: 'PERSONAL_REMINDER_GET_TASK_SUCCESS',

  PERSONAL_REMINDER_ARRANGE_TASK_LOCAL: 'PERSONAL_REMINDER_ARRANGE_TASK_LOCAL',
  PERSONAL_REMINDER_ARRANGE_TASK_: 'PERSONAL_REMINDER_ARRANGE_TASK',
  PERSONAL_REMINDER_ARRANGE_TASK_SUCCESS: 'PERSONAL_REMINDER_ARRANGE_TASK_SUCCESS',
  PERSONAL_REMINDER_ARRANGE_TASK_FAIL: 'PERSONAL_REMINDER_ARRANGE_TASK_FAIL',

  PERSONAL_REMINDER_REMOVE_TASK: 'PERSONAL_REMINDER_REMOVE_TASK',
  PERSONAL_REMINDER_REMOVE_TASK_LOCAL: 'PERSONAL_REMINDER_REMOVE_TASK_LOCAL',
  PERSONAL_REMINDER_REMOVE_TASK_SUCCESS: 'PERSONAL_REMINDER_REMOVE_TASK_SUCCESS',
};

export const getTask = taskId => {
  return Request.post(
    { url: '/api/personal-task-reminder/get-task-detail', data: { taskId: taskId } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.PERSONAL_REMINDER_GET_TASK_SUCCESS, payload: { data } });
    },
  );
};

export const addTask = data => {
  Mixpanel.track('coach_to_do_list_feature_add_task');
  if (data.schedule_notification) {
    Mixpanel.track('coach_to_do_list_feature_enable_notification_reminder');
  }
  return Request.post(
    { url: '/api/personal-task-reminder/add-task', data: { data } },
    false,
    (response, { dispatch }) => {
      dispatch({ type: Types.PERSONAL_REMINDER_ADD_TASK_SUCCESS, payload: response.data });
    },
  );
};

export const fetchCompletedTask = () => {
  return Request.get({ url: '/api/personal-task-reminder/fetch-completed-task' }, true, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch({ type: Types.PERSONAL_REMINDER_GET_COMPLETED_LIST_SUCCESS, payload: { data } });
  });
};
export const arrangeTask = data => {
  return dispatch => {
    dispatch({ type: Types.PERSONAL_REMINDER_ARRANGE_TASK_LOCAL, payload: data });
    return dispatch(
      Request.put(
        { url: '/api/personal-task-reminder-list/arrange-task', data: { data } },
        false,
        (response, { dispatch }) => {
          dispatch({ type: Types.PERSONAL_REMINDER_ARRANGE_TASK_SUCCESS, payload: null });
        },
        () => {
          dispatch({ type: Types.PERSONAL_REMINDER_ARRANGE_TASK_FAIL, payload: data });
        },
      ),
    );
  };
};
export const removeTask = taskId => {
  return dispatch => {
    dispatch({ type: Types.PERSONAL_REMINDER_REMOVE_TASK_LOCAL, payload: { taskId } });
    return dispatch(
      Request.put(
        { url: '/api/personal-task-reminder/remove-task', data: { taskId: taskId } },
        false,
        (response, { dispatch }) => {
          dispatch({ type: Types.PERSONAL_REMINDER_REMOVE_TASK_SUCCESS, payload: null });
        },
      ),
    );
  };
};

export const markAsRead = taskId => {
  return Request.put({ url: `/api/personal-task-reminder/mark-as-read`, data: { taskId: taskId } });
};

export const checkDueTask = () => Request.get({ url: `/api/personal-task-reminder-list/check-due-task` });

export const searchTrainers = data => Request.post({ url: `/api/team/trainer-search`, data });

export const fetchFollowingTask = () => {
  return Request.get(
    { url: '/api/personal-task-reminder-list/fetch-following-task' },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.PERSONAL_REMINDER_GET_FOLLOWING_LIST_SUCCESS, payload: { data } });
    },
  );
};

export const fetch = () => {
  return Request.get({ url: '/api/personal-task-reminder-list/fetch' }, true, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch({ type: Types.PERSONAL_REMINDER_GET_LIST_SUCCESS, payload: { data } });
  });
};

export const updateTask = data => {
  return Request.put(
    { url: '/api/personal-task-reminder/update-task', data: { data } },
    true,
    (response, { dispatch }) => {
      dispatch({ type: Types.PERSONAL_REMINDER_UPDATE_DATA_SUCCESS, payload: null });
    },
  );
};

export const markAsCompleted = taskId => {
  Mixpanel.track('coach_to_do_list_feature_mark-as-completed');
  return Request.put(
    { url: '/api/personal-task-reminder/mark-as-completed', data: { taskId: taskId } },
    false,
    (response, { dispatch }) => {
      dispatch({ type: Types.PERSONAL_REMINDER_MARK_AS_COMPLETED, payload: null });
    },
  );
};

export const unmarkAsCompleted = taskId => {
  return Request.put(
    { url: '/api/personal-task-reminder/unmark-as-completed', data: { taskId: taskId } },
    false,
    (response, { dispatch }) => {
      dispatch({ type: Types.PERSONAL_REMINDER_MARK_AS_COMPLETED, payload: null });
    },
  );
};
