import React from 'react';
import { GoalCountdownOverviewWrapper } from './styles.js';
import { ReactComponent as DoneIcon } from 'assets/icons/check_circle.svg';
import ConfettiImg from 'assets/images/confett.png';
import { BACKGROUND_DEFAULT, BACKGROUND_LIST } from '../../constants.js';
import useDaysRemaining from 'hooks/useDaysRemaining.js';

const CountdownOverviewItem = ({ countdown, onSelect }) => {
  const { title, date_utc, icon, color } = countdown;
  const timeRemaining = useDaysRemaining(date_utc, true);
  const selectedColor = BACKGROUND_LIST.find(({ background }) => color === background) || BACKGROUND_DEFAULT;

  return (
    <GoalCountdownOverviewWrapper onClick={() => onSelect(countdown)} bg={selectedColor}>
      {!timeRemaining && <img className="confetti" src={ConfettiImg} alt="confetti" />}
      <div className="title-wrapper">
        <div className="countdown-icon">{timeRemaining ? icon : <DoneIcon className="completed-icon" />}</div>
        <div className="countdown-name">
          <span>{title}</span>
        </div>
      </div>
      {timeRemaining && (
        <div className="time-counting-wrapper">
          <div className="time">{timeRemaining.time}</div>
          <div className="label">{timeRemaining.label}</div>
        </div>
      )}
    </GoalCountdownOverviewWrapper>
  );
};

export default CountdownOverviewItem;
