import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmPayAnInvoiceModal from './component';
import { getBillingOverviewData, togglePaymentInformation } from 'redux/billing/actions';
import { initPricingData } from 'redux/pricing/actions';
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    paymentInfo: rootReducer.billing.paymentInfo,
    is_payment_success: rootReducer.payment.is_payment_success,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    togglePaymentInformation: bindActionCreators(togglePaymentInformation, dispatch),
    getBillingOverviewData: bindActionCreators(getBillingOverviewData, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPayAnInvoiceModal);
