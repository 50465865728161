import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import * as ReactDOM from 'react-dom';
import { DateTime as DateTimeLuxon } from 'luxon';

import { TimeOptions } from 'components/InAppMessage/constants';
import { DateInput } from 'shared/FormControl';
import DropdownOptions from 'shared/DropdownOptions';
import PopUpForm from '../CreatePostForm/components/PopUpForm';
import { CDN_URL, KEY_CODE } from 'constants/commonData';
import { formatDateSafari, isESCPress } from 'utils/commonFunction';

import * as S from './style';

export default function SchedulePicker(props) {
  const {
    toggleModal,
    toggleSecondModal,
    isShowReschedule,
    user,
    scheduledDate,
    scheduledHour,
    saveScheduleTemp,
    formDataSchedule,
    rescheduleTime,
    data,
    firstCreate,
  } = props;

  const today = moment().tz(user.timezone);
  const currentHour = today.hour();
  const currentMinute = today.minutes();
  const tomorrow = moment().add(1, 'days');
  const isBetween1145To12 = currentHour === 23 && currentMinute >= 46 && currentMinute <= 59;
  const isUnscheduled = get(data, 'status', '') === 'UNSCHEDULED';

  const initHourSchedule = () => {
    const scheduleHour = moment(get(formDataSchedule, 'schedule_time')).tz(user.timezone).format('HH:mm');
    const parseTime = TimeOptions.find(time => time.value === scheduleHour);

    if (isUnscheduled || !parseTime) {
      if (isBetween1145To12) {
        // If the status is UNSCHEDULED or the current time is between 11:45 PM and 12:00 AM,
        // choose the next 12:00AM option
        return TimeOptions.find(option => option.value[0]);
      } else {
        return nearestHourOption();
      }
    }
    return parseTime;
  };

  const initHourAfterTimeover = () => {
    const scheduledHourValue = moment(scheduledHour.value, 'h:mm A');
    const isScheduledHourHigher = scheduledHourValue.isSameOrBefore(today);

    if (isScheduledHourHigher) {
      return nearestHourOption();
    }
    return scheduledHour;
  };

  const initDateSchedule = () => {
    const date = get(formDataSchedule, 'schedule_time', '');

    if (get(data, 'status', '') === 'UNSCHEDULED' || !date) {
      if (isBetween1145To12) {
        // If the status is UNSCHEDULED or the current time is between 11:45 PM and 12:00 AM,
        // choose the next day option
        return today.add(1, 'day');
      } else {
        return moment().tz(user.timezone);
      }
    }
    return moment(date).tz(user.timezone);
  };

  function parseOptionValue(optionValue) {
    const [hour, minute] = optionValue.split(':').map(part => parseInt(part));
    return { hour, minute };
  }

  // find the nearest valid hour option
  function nearestHourOption() {
    let hourOptions = TimeOptions;
    for (const option of hourOptions) {
      const { hour, minute } = parseOptionValue(option.value);
      if (hour > currentHour || (hour === currentHour && minute >= currentMinute)) {
        hourOptions = option;
        break;
      }
    }
    return hourOptions;
  }

  const escFunction = event => {
    if (event.keyCode === KEY_CODE.esc || isESCPress(event.key)) {
      handleCloseModal && handleCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, []);

  const [startDate, setStartDate] = useState(scheduledDate ? scheduledDate : initDateSchedule);
  const [startHour, setStartHour] = useState(scheduledHour ? initHourAfterTimeover : initHourSchedule);

  // filter out the invalid hours and add the isDisabled property to the object
  const checkToday = startDate.isSameOrAfter(tomorrow, 'day');
  const memoizedValidOptions = useMemo(() => {
    return TimeOptions.map(option => {
      const { hour, minute } = parseOptionValue(option.value);

      if (checkToday || hour > currentHour || (hour === currentHour && minute >= currentMinute)) {
        return option;
      }

      return { ...option, isDisabled: true };
    });
  }, [TimeOptions, checkToday, currentHour, currentMinute]);

  const handleCancel = () => {
    handleCloseModal();
    toggleModal(true, <PopUpForm {...props} />);
    rescheduleTime && toggleModal(false);
  };

  const handleRemove = () => {
    saveScheduleTemp({ ...formDataSchedule, schedule_time: '', status: 'PUBLISHED' });
    handleCloseModal();
    toggleModal(
      true,
      <PopUpForm
        {...props}
        isSchedulePost={true}
        scheduledDate={false}
        scheduledHour={false}
        rescheduleTime={false}
        isShowReschedule={false}
      />,
    );
  };

  const handleCloseModal = () => {
    toggleSecondModal(false);
  };

  const isTimeInThePast = () => {
    const joinDate = `${startDate.format(moment.HTML5_FMT.DATE)} ${get(startHour, 'value')}`;
    const currentTime = moment().tz(user.timezone).format('YYYY-MM-DD HH:mm');
    return currentTime >= joinDate;
  };

  const handleSaveScheduled = async () => {
    const {
      post,
      editPost,
      match: {
        params: { forumId, id },
      },
    } = props;

    let groupID = post ? post.group : false;
    groupID = get(groupID, '_id') || id || forumId || '';
    const hour = startHour.value;
    const [hours, mins] = hour.split(':');
    const date = moment(startDate).set('hour', hours).set('minute', mins).set('second', 0).utc(0);

    if (isTimeInThePast()) {
      toast.error(`You can’t schedule a post in the past`);
      return;
    }

    saveScheduleTemp({ ...formDataSchedule, schedule_time: date, status: 'IN_SCHEDULE' });

    const pickedSelectedDataSchedule = pick(formDataSchedule, [
      'status',
      'schedule_time',
      'caption',
      'tag_all',
      'tagged_people',
      'attachments',
      'preview',
    ]);

    const selectedDataSchedule = omitBy(pickedSelectedDataSchedule, isEmpty());
    handleCloseModal();

    const bodyRequest = {
      ...selectedDataSchedule,
      schedule_time: date,
      groupId: groupID,
      postId: formDataSchedule._id,
      status: 'IN_SCHEDULE',
    };

    if (typeof bodyRequest.caption === 'string' && bodyRequest.caption.trim().length === 0) {
      delete bodyRequest.caption;
    }

    rescheduleTime
      ? await editPost(bodyRequest)
      : toggleModal(
          true,
          <PopUpForm {...props} isSchedulePost={true} scheduledDate={startDate} scheduledHour={startHour} />,
        );
  };

  const isValidDate = current => {
    let today = moment().tz(user.timezone).startOf('day');
    const nextMonth = moment().add(30, 'days').startOf('day');
    const currentDate = moment(current).startOf('day');

    if (isBetween1145To12 || (isBetween1145To12 && isUnscheduled)) {
      // Choose the next day by adding one day to the current date and replace it on today
      return currentDate.isBetween(tomorrow, nextMonth, 'day', '[]');
    }
    return currentDate.isBetween(today, nextMonth, 'day', '[]');
  };

  const isRenderRemove =
    (isShowReschedule && formDataSchedule.schedule_time) || rescheduleTime || (scheduledDate && scheduledHour);

  const handleScrollToOption = () => {
    setTimeout(() => {
      const selectedOption = document.querySelector('.common-select-container__option--is-selected');
      if (selectedOption) {
        selectedOption.scrollIntoView();
      }
    }, 0);
  };
  const calendarRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      handleRemoveWeek();
    }, 0);
  }, []);

  // // Detect remove days (By DOM)
  const handleRemoveWeek = () => {
    if (!calendarRef.current) return;
    const btnNext = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead tr .rdtNext');
    const btnPrev = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead tr .rdtPrev');
    // Get month from calendar
    const currentMonth = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead tr .rdtSwitch');
    const formatDateToLuxon = DateTimeLuxon.fromFormat(currentMonth.textContent, 'MMMM yyyy');
    const currentMonthFormatted = moment(formatDateToLuxon.toFormat('yyyy-MM-dd'));

    const thisMonth = moment().startOf('month').tz(user.timezone);
    const nextMonth = moment().startOf('month').tz(user.timezone).add(1, 'month');

    // Compare current month with system
    if (currentMonthFormatted.isSame(formatDateSafari(thisMonth))) {
      btnPrev.classList.add('disabledBtnPrev');
    } else {
      btnPrev.classList.remove('disabledBtnPrev');
    }

    if (currentMonthFormatted.isSameOrAfter(formatDateSafari(nextMonth))) {
      btnNext.classList.add('disabledBtnNext');
    } else {
      btnNext.classList.remove('disabledBtnNext');
    }
  };

  // Detect reset DOM
  const handleRemoveWeekChange = () => {
    setTimeout(() => {
      handleRemoveWeek();
    }, 0);
  };

  const renderDay = (props, currentDate) => {
    // Adds 0 to the days in the days view
    return (
      <td {...props}>
        <div>{currentDate.date() < 10 ? '0' + currentDate.date() : currentDate.date()}</div>
      </td>
    );
  };

  const isRescheduleTitle = (rescheduleTime || isShowReschedule) && !firstCreate;

  return (
    <S.ScheduleModal size={'tiny'} open={true} closeOnDimmerClick={false}>
      <S.ScheduleModalContainer>
        <S.ScheduleMainWrapper>
          <Modal.Header className="modal__header">
            <button className="close-button" onClick={handleCancel}>
              <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
            </button>
          </Modal.Header>
          <Modal.Content>
            <S.SchedulePickerWrapper>
              <S.ScheduleLabel>{isRescheduleTitle ? 'Reschedule Post' : 'Schedule a Post'}</S.ScheduleLabel>
              <S.ScheduleTitle>Select a date and time in the future for your post to be published.</S.ScheduleTitle>
              <S.ScheduleSubTitle>
                {isRescheduleTitle
                  ? `Scheduled based on timezone ${moment.tz(user.timezone).format('([GMT]Z)')}`
                  : 'This will be scheduled in your current timezone.'}
              </S.ScheduleSubTitle>
              <S.DateTimeContainer>
                <S.DateTimeWrapper>
                  <Datetime
                    value={startDate}
                    renderInput={props => {
                      return <DateInput {...props} text={startDate.format('MMM D, YYYY')} />;
                    }}
                    timeFormat={false}
                    onChange={newDate => {
                      setStartDate(newDate);
                    }}
                    closeOnSelect={true}
                    className="new-ui date-picker"
                    ref={calendarRef}
                    isValidDate={isValidDate}
                    onNavigateBack={handleRemoveWeekChange}
                    onNavigateForward={handleRemoveWeekChange}
                    onViewModeChange={handleRemoveWeekChange}
                    renderDay={renderDay}
                  />
                </S.DateTimeWrapper>
                <S.HourPickerWrapper>
                  <DropdownOptions
                    menuPlacement="top"
                    width="99px"
                    height="36px"
                    options={memoizedValidOptions}
                    selectedValue={scheduledHour ? initHourAfterTimeover().value : initHourSchedule().value}
                    onSelect={newHour => setStartHour(newHour)}
                    isForceUpdate={true}
                    className="dropdown--hour-picker"
                    onScrollBottom={handleScrollToOption}
                    blurInputOnSelect={true}
                  />
                </S.HourPickerWrapper>
              </S.DateTimeContainer>
            </S.SchedulePickerWrapper>
            <div className="schedule-post-button">
              {isRenderRemove && (
                <S.RemoveButton data-btn-delete="remove-datetime" onClick={handleRemove}>
                  Remove
                </S.RemoveButton>
              )}
              <S.CancelButton data-btn-cancel="cancel-datetime-picker" onClick={handleCancel}>
                Cancel
              </S.CancelButton>
              <S.SaveButton data-btn-save="save-datetime" purple onClick={handleSaveScheduled}>
                Save
              </S.SaveButton>
            </div>
          </Modal.Content>
        </S.ScheduleMainWrapper>
      </S.ScheduleModalContainer>
    </S.ScheduleModal>
  );
}
