import React from 'react';
import { Loader } from 'semantic-ui-react';

import * as S from '../style';
import { useMyContext } from '../context/NotificationContext';

const LoadingIndicator = () => {
  const { openTooltip, isEmptyNotification } = useMyContext();

  return (
    <S.IndicatorWrapper openTooltip={openTooltip} isEmpty={isEmptyNotification}>
      <Loader active inline="centered" className="custom-indicator" size="medium">
        <span>Loading...</span>
      </Loader>
    </S.IndicatorWrapper>
  );
};

export default LoadingIndicator;
