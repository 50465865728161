// Libs
import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { Dropdown } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import { Prompt } from 'react-router';
import classNames from 'classnames';

// Action
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import {
  changeModeRearrange,
  publishMealPlan,
  unpublishMealPlan,
  startEditMealPlan,
  cancelEditMealPlan,
  publishChangeMealPlan,
  getAssignedList,
} from 'redux/meal-plans/actions';

// Shared
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// Constants
import { CALENDAR_LIMIT_WEEKS, CDN_URL } from 'constants/commonData';
import { ASSIGNED_STATUS, MEAL_PLAN_STATUS, STATUS_ADD_WEEK } from 'components/MealPlanDetail/constants';

// Parts
import AddWeekButton from '../AddWeekButton';
import AssignMealPlan from '../AssignMealPlan';
import AssignedClientList from '../AssignedClientList';
import InfoModal from './InfoModal';
import ContentPublishChangeRender from './ContentPublishChangeRender';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down.svg';
import { ReactComponent as AssignIcon } from 'assets/icons/assign_icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/MealPlans/calendar.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/MealPlans/group.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_cicre.svg';
import { ReactComponent as PublishIcon } from 'assets/icons/MealPlans/action_publish.svg';
import { ReactComponent as UnpublishIcon } from 'assets/icons/MealPlans/action_unpublish.svg';
import { ReactComponent as PublishChangesIcon } from 'assets/icons/MealPlans/publish_changes.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_square.svg';
import WarningIcon from 'assets/icons/warning_red_light.svg';

// Styles
import * as S from './style';

const Menu = props => {
  const {
    toggleModal,
    toggleSecondModal,
    weekIndex,
    handleChangeWeek,
    handleAddWeek,
    onRemoveWeek,
    totalClientActive,
    changeModeRearrange,
    rearrangeMode,
    publishMealPlan,
    unpublishMealPlan,
    startEditMealPlan,
    cancelEditMealPlan,
    publishChangeMealPlan,
    mealPlanId,
    toggleConfirmModal,
    detailMealPlan,
    isSyncing,
    getAssignedList,
    disabledAction,
    history,
    onChangeModeRearrange,
    numberOfWeeks,
  } = props;
  const [openWeek, setOpenWeek] = useState(false);

  const currentWeek = weekIndex + 1;

  const { status = '', number_of_weeks: totalWeek = 0, is_edit_mode: isEdit = false, weeks: weeksList = [] } =
    detailMealPlan || {};

  const isDraft = status === MEAL_PLAN_STATUS.DRAFT;
  const isPublish = status === MEAL_PLAN_STATUS.PUBLISH;

  const params = new URLSearchParams(get(window, 'location.search'));
  const isEditMode = params.get('isEdit');

  useEffect(() => {
    if (isEditMode && !isEmpty(detailMealPlan) && !isEdit && isPublish) {
      handleStartEdit();

      history.replace({ pathname: `/home/meal-plans/${mealPlanId}/weeks/${weeksList[weekIndex]}` });
    }
  }, [detailMealPlan]);

  const weeks = useMemo(() => {
    const result = Array.from({ length: totalWeek }, (_, index) => ({
      key: index,
      text: (index + 1).toString(),
      value: index,
    }));

    return result;
  }, [totalWeek]);

  const handleOpenAssign = () => {
    toggleModal && toggleModal(true, <AssignMealPlan toggleModal={toggleModal} />);
  };

  const handleOpenAssignedClient = () => {
    toggleSecondModal && toggleSecondModal(true, <AssignedClientList toggleSecondModal={toggleSecondModal} />);
  };

  const handlePrevWeek = () => {
    if (currentWeek === 1) return;
    handleChangeWeek(weekIndex - 1);
  };

  const handleNextWeek = () => {
    if (currentWeek === totalWeek) return;
    handleChangeWeek(currentWeek);
  };

  const handleRemoveWeek = () => {
    onRemoveWeek(weekIndex);
  };

  const renderDropdown = () => {
    return (
      <Dropdown
        options={weeks}
        value={weekIndex}
        icon="chevron down"
        className="week-option"
        onChange={(_, { value }) => {
          handleJumpToWeek(value);
        }}
      />
    );
  };

  const handleJumpToWeek = weekCurrent => {
    handleChangeWeek(weekCurrent);
    if (weekCurrent !== weekIndex) {
      setOpenWeek(false);
    }
  };

  const jumpToWeek = () => {
    return (
      <S.JumpWeek>
        <S.JumpWeekTitle>Go to week</S.JumpWeekTitle>
        <S.JumpWeekContent>
          <S.WeekOption>
            {renderDropdown()}
            of {totalWeek}
          </S.WeekOption>
        </S.JumpWeekContent>
      </S.JumpWeek>
    );
  };

  const handleOpenJumpToWeek = state => {
    setOpenWeek(state);
  };

  const handleChangeModeRearrange = () => {
    if (!rearrangeMode) {
      onChangeModeRearrange();
      toast('You’re in rearrange mode.');
    }
    changeModeRearrange();
  };

  const handlePublish = () => {
    handlePublishMealPlan();
    // TODO: Remove this ver
    // return toggleConfirmModal(
    //   true,
    //   <InfoModal
    //     title="Publish Meal Plan"
    //     content="Ready - Set - Go! The Meal Plan will be published live to all clients that the Meal Plan has been made available for."
    //     onConfirm={handlePublishMealPlan}
    //     confirmButtonTitle="Publish"
    //     noBorder
    //     hasCloseIcon
    //     isPressEsc
    //     noIcon
    //     isCloseOnDimmer={false}
    //   />,
    // );
  };

  const handlePublishMealPlan = () => {
    publishMealPlan(mealPlanId, () => {
      toggleConfirmModal(false);
      toast('Meal Plan has been published. You can start assigning clients to it now.');
    });
  };

  const handleUnpublish = () => {
    return toggleConfirmModal(
      true,
      <InfoModal
        title="Unpublish Meal Plan"
        content="This will remove all the clients from the Meal Plan (Waiting to Start and Active). All the changes client have modified will not be saved."
        onConfirm={handleUnpublishMealPlan}
        confirmButtonTitle="Unpublish"
        headerIcon={`${CDN_URL}/images/recipe_unpublish.svg`}
        className="unpublish-meal-plan"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleUnpublishMealPlan = () => {
    toggleConfirmModal(false);
    unpublishMealPlan(mealPlanId, () => {
      const params = {
        meal_plan_id: mealPlanId,
        status: ASSIGNED_STATUS.ACTIVE,
      };

      getAssignedList && getAssignedList(params);

      toast('The meal plan has been unpublished and remove for all clients.');
    });
  };

  const handleStartEdit = () => {
    return toggleConfirmModal(
      true,
      <InfoModal
        title="Edit Meal Plan"
        content="You can edit freely. Changes made to the meal plan will not go live until you choose to Publish Changes."
        onConfirm={handleEditMealPlan}
        confirmButtonTitle="Okay"
        className="edit-meal-plan"
        noBorder
        hasCloseIcon
        isPressEsc
        noIcon
        hideCancelButton
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleEditMealPlan = () => {
    toggleConfirmModal(false);
    startEditMealPlan(mealPlanId);
  };

  const handleCancelEdit = nextLocation => {
    if (nextLocation) {
      const { pathname } = nextLocation;
      const isInMealPlanDetail = pathname.includes('/home/meal-plans') && pathname.includes('/weeks/');

      if (isInMealPlanDetail) {
        return true;
      }
    }

    toggleConfirmModal(
      true,
      <InfoModal
        title="Cancel and Discard Changes"
        content="This edited version of the Meal Plan will be discarded. The Meal Plan will reset to its current live version. Would you like to continue?"
        onConfirm={() => {
          handleCancelEditMealPlan(() => {
            if (nextLocation) {
              props.push(nextLocation.pathname);
            }
          });
        }}
        confirmButtonTitle="Discard Changes"
        headerIcon={WarningIcon}
        className="cancel-meal-plan"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
    if (nextLocation) {
      return false;
    }
  };

  const handleCancelEditMealPlan = callback => {
    cancelEditMealPlan(mealPlanId, callback);
  };

  const handlePublishChange = () => {
    toggleModal(
      true,
      <ContentPublishChangeRender toggleModal={toggleModal} onPublish={handlePublishChangeEditMealPlan} />,
    );
  };

  const handlePublishChangeEditMealPlan = status => {
    publishChangeMealPlan(mealPlanId, { override_client_changes: !status }, () => {
      toast('Changes have been published. Clients using the meal plan will see the update in minutes.', {
        className: 'custom--meal-plan-publish-change',
      });
      toggleModal(false);
    });
  };

  const renderAction = () => {
    return (
      <S.MenuRight>
        <S.AssignedClientWrapper onClick={handleOpenAssignedClient}>
          <GroupIcon className="meal-plan-group" />
          <span>{totalClientActive}</span>
          <span>active</span>
          <S.DotActive />
        </S.AssignedClientWrapper>
        <S.AssignButton onClick={handleOpenAssign} disabled={isDraft} data-tip data-for="assign-meal-plan-tooltip">
          <AssignIcon className="assign-icon" />
          Assign Meal Plan
        </S.AssignButton>
        {isDraft && (
          <ReactTooltip
            className="assign-meal-plan-tooltip"
            id="assign-meal-plan-tooltip"
            effect="solid"
            place="top"
            delayShow={300}
          >
            Please publish the meal plan before assigning to the clients.
          </ReactTooltip>
        )}

        {!(!isEdit && isPublish) && (
          <S.Rearrange rearrangeMode={rearrangeMode} onClick={handleChangeModeRearrange} disabled={disabledAction}>
            {rearrangeMode && <CloseIcon />}
            Rearrange
          </S.Rearrange>
        )}
        {renderActionStatus()}
      </S.MenuRight>
    );
  };

  const renderActionStatus = () => {
    return (
      <>
        {isPublish && !isEdit && (
          <S.ActionStatus onClick={handleStartEdit} className="btn-edit" disabled={isSyncing || disabledAction}>
            Edit
          </S.ActionStatus>
        )}
        {isDraft && (
          <S.ActionStatus className="btn-publish" onClick={handlePublish} disabled={isSyncing || disabledAction}>
            <PublishIcon />
            Publish
          </S.ActionStatus>
        )}
        {isPublish && !isEdit && (
          <>
            <S.ActionStatus
              className="btn-unpublish"
              onClick={handleUnpublish}
              disabled={isSyncing || disabledAction}
              data-tip
              data-for="unpublish-meal-plan-tooltip"
            >
              <UnpublishIcon />
              Unpublish
            </S.ActionStatus>
            {isSyncing && (
              <ReactTooltip
                className="unpublish-meal-plan-tooltip"
                id="unpublish-meal-plan-tooltip"
                effect="solid"
                place="top"
                delayShow={300}
              >
                The Meal Plan syncing is still in progress. Please wait a few minutes before unpublishing the program
              </ReactTooltip>
            )}
          </>
        )}
        {isPublish && isEdit && (
          <>
            <S.ActionStatus className="btn-cancel-edit" disabled={disabledAction} onClick={() => handleCancelEdit()}>
              Cancel Edit
            </S.ActionStatus>
            <S.ActionStatus className="btn-publish-changes" disabled={disabledAction} onClick={handlePublishChange}>
              <PublishChangesIcon />
              Publish Changes
            </S.ActionStatus>
          </>
        )}
      </>
    );
  };

  const renderAddWeek = () => {
    return (
      <S.AddWeekButton>
        <DropDown
          className={classNames('custom-drop-down-actions custom-drop-down-add-week', {
            disabled: disabledAction,
          })}
          triggerIcon={() => <AddWeekButton disabled={totalWeek === CALENDAR_LIMIT_WEEKS || disabledAction} />}
          direction="left"
        >
          <Option
            key="add-before"
            onClick={() => {
              handleAddWeek(currentWeek - 1, STATUS_ADD_WEEK.BEFORE);
            }}
          >
            <PlusIcon />
            <span>Add before “Week {currentWeek}”</span>
          </Option>
          <Option
            key="add-after"
            onClick={() => {
              handleAddWeek(currentWeek, STATUS_ADD_WEEK.AFTER);
            }}
          >
            <PlusIcon />
            <span>Add after “Week {currentWeek}”</span>
          </Option>
          {numberOfWeeks !== currentWeek ? (
            <Option
              key="add-last"
              onClick={() => {
                handleAddWeek(numberOfWeeks, STATUS_ADD_WEEK.AFTER);
              }}
            >
              <PlusIcon />
              <span>Add after “Week {numberOfWeeks}”</span>
            </Option>
          ) : null}
        </DropDown>
      </S.AddWeekButton>
    );
  };

  return (
    <S.HeaderWrapper>
      <Prompt when={isPublish && isEdit} message={nextLocation => handleCancelEdit(nextLocation)} />
      <S.AddWeekWrapper>
        <RootCloseWrapper event="click" disabled={!openWeek} onRootClose={() => handleOpenJumpToWeek(false)}>
          <S.WeekWrapper>
            <S.PrevIcon disabled={currentWeek === 1} onClick={handlePrevWeek}>
              <ArrowIcon />
            </S.PrevIcon>
            <S.WeekRange onClick={() => handleOpenJumpToWeek(!openWeek)}>
              <CalendarIcon className="calendar-icon" />
              <span>
                Week {currentWeek} of {totalWeek}
              </span>
            </S.WeekRange>
            <S.NextIcon disabled={currentWeek === totalWeek} onClick={handleNextWeek}>
              <ArrowIcon />
            </S.NextIcon>
            {openWeek && jumpToWeek()}
          </S.WeekWrapper>
        </RootCloseWrapper>
        {!isPublish && (
          <>
            {renderAddWeek()}
            <DropDown
              className={classNames('custom-drop-down-actions', { disabled: disabledAction })}
              triggerIcon={() => <MenuTrigger className="drop-down-trigger" />}
            >
              <Option key="delete" onClick={handleRemoveWeek}>
                <div className="icon">
                  <img src={`${CDN_URL}/images/delete.svg`} alt="" />
                </div>
                <span>Remove Week {currentWeek}</span>
              </Option>
            </DropDown>
          </>
        )}
      </S.AddWeekWrapper>
      {renderAction()}
    </S.HeaderWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { mealPlans },
  } = state;

  const totalClientActive = get(mealPlans, 'assignedList.total');
  const rearrangeMode = get(mealPlans, 'rearrangeMode', false);
  const detailMealPlan = get(mealPlans, 'detailMealPlan', {});

  return { totalClientActive, rearrangeMode, detailMealPlan };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  changeModeRearrange: bindActionCreators(changeModeRearrange, dispatch),
  publishMealPlan: bindActionCreators(publishMealPlan, dispatch),
  unpublishMealPlan: bindActionCreators(unpublishMealPlan, dispatch),
  startEditMealPlan: bindActionCreators(startEditMealPlan, dispatch),
  cancelEditMealPlan: bindActionCreators(cancelEditMealPlan, dispatch),
  publishChangeMealPlan: bindActionCreators(publishChangeMealPlan, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getAssignedList: bindActionCreators(getAssignedList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
