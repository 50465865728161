import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { database } from 'database/firebase';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  getStudioProgramDetail,
  resetStudioProgramDetail,
  listenToStudioProgramLastEditBy,
} from 'redux/studio-program/actions';
import { getMostRecentTagsList } from 'redux/tags/actions';
import StudioProgramOverview from './StudioProgramOverview';
import StudioProgramCalendar from './Calendar';
import StudioProgramSettings from './Settings';
import { TAGS_TYPE } from 'constants/commonData';

class StudioProgramDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const studioProgramId = get(this.props, 'match.params.studioId');
    if (isEmpty(studioProgramId)) return;
    this.props.getStudioProgramDetail(studioProgramId);
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.EXERCISE, page: 1, perPage: 20, sort: -1 });
    // this.props.listenToStudioProgramLastEditBy(studioProgramId); // TODO
  }

  componentWillUnmount() {
    this.props.resetStudioProgramDetail();
    const studioProgramId = this.props.match.params.studioId;
    database.ref('updates').child(studioProgramId).off();
  }

  render() {
    const studioProgramId = get(this.props, 'workingStudio._id');
    if (isEmpty(studioProgramId)) return <></>;

    return (
      <Switch>
        <Route exact path="/home/studio-program/:studioId?" render={props => <StudioProgramOverview {...props} />} />
        <Route exact path="/home/studio-program/:studioId/calendar" component={StudioProgramCalendar} />
        <Route exact path="/home/studio-program/:studioId/settings" component={StudioProgramSettings} />
        <Route exact path="/home/studio-programs/:studioId?" render={props => <StudioProgramOverview {...props} />} />
        <Route exact path="/home/studio-programs/:studioId/calendar" component={StudioProgramCalendar} />
        <Route exact path="/home/studio-programs/:studioId/settings" component={StudioProgramSettings} />
      </Switch>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      studioProgram: { workingStudio },
    },
  } = state;

  return { workingStudio };
};

const mapDispatch = dispatch => ({
  getStudioProgramDetail: bindActionCreators(getStudioProgramDetail, dispatch),
  resetStudioProgramDetail: bindActionCreators(resetStudioProgramDetail, dispatch),
  listenToStudioProgramLastEditBy: bindActionCreators(listenToStudioProgramLastEditBy, dispatch),
  getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
});

export default connect(mapState, mapDispatch)(StudioProgramDetail);
