import { combineReducers } from 'redux';
import cmsUserReducer from './cms/user/user.reducer';
import cmsInvitationReducer from './cms/invitation/invitation.reducer';
import exerciseReducer from './exercise/exercise.reducer';
import calendarReducer from './calendar/calendar.reducer';
import clientReducer from './client/client.reducer';
import unitReducer from './unit/unit.reducer';
import modalReducer from './modal/modal.reducer';
import taskReducer from './task/task.reducer';
import programLibraryReducer from './program_library/program_library.reducer';
import groupReducer from './group/reducer';
import segmentReducer from './segment/reducer';
import autoflowReducer from './autoflow/reducer';
import foodJournal from './foodJournal/reducer';
import notification from './notification/reducer';
import systemWorkout from './systemWorkout/reducer';
import onboarding from './onboarding/reducer';
import taskReminderReducer from './personal-task-reminder/reducer';
import workoutLibrary from './workout_library/workout.reducer';
import permission from './permission/reducer';
import payment from './payment/reducer';
import customBranding from './custom-branding/reducer';
import pricing from './pricing/reducer';
import billing from './billing/reducer';
import inboxNew from './inbox-new/reducer';
import studioProgram from './studio-program/reducer';
import studioResource from './studio-resource/reducer';
import studioCollection from './studio-collection/reducer';
import workoutBuilder from './workout-builder/reducer';
import model from './model/reducer';
import macros from './macros/reducer';
import autoflowInterval from './autoflowInterval/reducer';
import auth from './auth/reducer';
import leaderboard from './leaderboard/reducer';
import forum from './forum/reducer';
import taskLibrary from './task-library/reducer';
import clientStep from './client-step/reducer';
import waitingActivationClients from './waiting-activation-clients/reducer';
import packageList from './package-list/reducer';
import packageDetail from './package-detail/reducer';
import packageAnalytics from './package-analytics/reducer';
import packageSettings from './package-settings/reducer';
import setupPayment from './setup-payment/reducer';
import activity from './activity/reducer';
import product from './product/reducer';
import onDemandWorkouts from './on-demand-workouts/reducer';
import onDemandSettings from './on-demand-settings/reducer';
import workoutCollection from './workout-collection/reducer';
import workoutCollectionCategory from './workout-collection-category/reducer';
import workoutLabels from './workout-labels/reducer';
import categoryLabels from './category-labels/reducer';
import generalSettings from './general-settings/reducer';
import exerciseTags from './tags/exercise-tags/reducer';
import workoutTags from './tags/workout-tags/reducer';
import programTags from './tags/program-tags/reducer';
import forms from './forms/reducers';
import formDetails from './form-details/reducers';
import formResponses from './form-responses/reducers';
import sectionLibrary from './section-library/reducer';
import tasksLibrary from './tasks-library/reducers';
import coachBios from './coach-bios/reducers';
import coachBioDetails from './coach-bios-detail/reducers';
import formDocuments from './form-documents/reducers';
import habits from './habits/reducers';
import onboardingFlow from './onboarding-flow/reducer';
import progressPhoto from './progress-photo/reducers';
import broadcastMessages from './broadcast-messages/reducers';
import uploadMediaBackground from './upload-background/reducers';
import workoutCompletionSummaries from './workout-completion/reducers';
import mealPlans from './meal-plans/reducers';
import recipes from './recipes/reducers';
import clientMealPlan from './client-meal-plan/reducer';
import waitList from './waitlist/reducers';
import contactList from './contact-list/reducers';
import metricGroupLibrary from './metric-group-library/reducers';
import programLibraryAndTemplates from './program-library-and-templates/reducers';
import surveyContact from './survey-contact/reducers';
import ingredientLibrary from './ingredient-library/reducers';
import clientCountdown from './client-countdown/reducer';
import onboardingLibrary from './onboarding-flow-library/reducer';
import hearRateMetric from './heart-rate/reducer';

const rootReducer = combineReducers({
  exercise: exerciseReducer,
  calendar: calendarReducer,
  modal: modalReducer,
  client: clientReducer,
  clientCountdown,
  unit: unitReducer,
  workoutLibrary,
  program_library: programLibraryReducer,
  task: taskReducer,
  group: groupReducer,
  segment: segmentReducer,
  autoflow: autoflowReducer,
  cms: combineReducers({
    user: cmsUserReducer,
    invitation: cmsInvitationReducer,
  }),
  taskReminder: taskReminderReducer,
  foodJournal,
  notification,
  systemWorkout,
  onboarding,
  permission,
  payment,
  customBranding,
  pricing,
  billing,
  inboxNew,
  studioProgram,
  studioResource,
  studioCollection,
  workoutBuilder,
  model,
  macros,
  autoflowInterval,
  auth,
  leaderboard,
  forum,
  taskLibrary,
  clientStep,
  waitingActivationClients,
  packageList,
  packageDetail,
  packageAnalytics,
  packageSettings,
  setupPayment,
  activity,
  product,
  onDemandWorkouts,
  onDemandSettings,
  workoutCollection,
  workoutCollectionCategory,
  categoryLabels,
  workoutLabels,
  generalSettings,
  exerciseTags,
  workoutTags,
  programTags,
  forms,
  formDetails,
  formResponses,
  sectionLibrary,
  tasksLibrary,
  coachBios,
  coachBioDetails,
  formDocuments,
  habits,
  onboardingFlow,
  progressPhoto,
  broadcastMessages,
  uploadMediaBackground,
  workoutCompletionSummaries,
  mealPlans,
  recipes,
  clientMealPlan,
  metricGroupLibrary,
  programLibraryAndTemplates,
  waitList,
  contactList,
  surveyContact,
  ingredientLibrary,
  onboardingLibrary,
  hearRateMetric,
});

export default rootReducer;
