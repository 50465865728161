import styled, { css } from 'styled-components';
import CustomBrandingStatusBar from 'shared/CustomBrandingStatusBar';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewMore = styled.span`
  color: #7470ef;
  font-weight: 700;
  font-size: 9.33635px;
  line-height: 13px;
  text-align: right;
  letter-spacing: -0.02em;
  cursor: pointer;
`;

export const ViewName = styled.span`
  font-weight: 700;
  font-size: 13.3376px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #1e0a3c;
  white-space: nowrap;
  max-width: 70%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MobilePreviewContainer = styled.div`
  padding: 37px 22px 10px;
  position: relative;
  height: 538px;
  width: 273px;
  flex: 0 0 273px;
  overflow: hidden;
  background: #f7f8fc url(${CDN_URL}/images/iphone_layout_dark.svg) no-repeat;
  background-position: top left;
  background-size: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 24px;
    bottom: 6px;
    right: 7px;
    background: #485362;
    border-radius: 50%;
    transform: rotateZ(20deg);
  }

  .mobilePreview__heading {
    font-weight: bold;
    font-size: 19.6981px;
    line-height: 21px;
    letter-spacing: -0.351752px;
    color: #1e0a3c;
    word-break: break-word;
  }
  .mobilePreview__sections {
    max-height: 404px;
    padding-top: 21px;
    padding-right: 10px;
    margin-right: -10px;
    overflow: hidden;
    overflow-y: auto;
    border-bottom-right-radius: 21px;
    border-bottom-left-radius: 6px;

    &.overLimit {
      max-height: 383px;
    }
  }
  .mobilePreview__menu {
    display: flex;
    position: relative;
    margin-top: 4px;
    border-bottom: 1px solid #e0e1e4;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      width: 10.5px;
      height: 1px;
      background: #e0e1e4;
      bottom: -1px;
      left: -10.5px;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 10.5px;
      height: 1px;
      background: #e0e1e4;
      bottom: -1px;
      right: -10.5px;
      z-index: 1;
    }

    .item {
      font-weight: 700;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #9a9da6;
      padding: 6px 0;
      margin-right: 21px;

      &.active {
        position: relative;
        color: #5e59ff;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          bottom: -1px;
          color: #5e59ff;
          border-bottom: 1.3px solid #5e59ff;
        }
      }
    }
  }

  @media screen and (max-height: 780px) {
    transform: scale(0.85);
  }
`;

export const SectionMobilePreview = styled.div`
  margin-bottom: 21px;

  &:last-child {
    margin-bottom: 0;

    ${PreviewLargeCardWrapper} {
    }
  }
`;

export const ResourceItemWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 5.3px;

  .previewResource {
    &__avatar {
      position: relative;
      width: 70.7px;
      height: 70.7px;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-position: center;

      .overlay {
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        background: linear-gradient(transparent, #00000080);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &__infoArea {
      display: flex;
      width: calc(100% - 70.7px);
      height: 70.7px;
      padding-left: 8px;
      flex-direction: column;
      justify-content: center;
    }

    &__labelAndDuration {
      font-weight: 600;
      font-size: 7.3357px;
      line-height: 11px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #9a9da6;
      margin-top: 2.6px;
    }

    &__info {
      color: #151619;
      font-weight: 700;
      font-size: 9.33635px;
      line-height: 13px;
      letter-spacing: -0.02em;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      overflow: hidden;
      width: calc(100% - 24px);
      white-space: pre-line;
      overflow-wrap: break-word;
    }

    &__playIcon {
      position: absolute;
      left: 8px;
      bottom: 8px;
    }
  }

  ${props =>
    props.coverImage
      ? css`
          .previewResource__avatar {
            background-image: url(${props.coverImage});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `
      : css`
          .previewResource__avatar {
            background-image: url(${CDN_URL}/images/drop_image.svg);
            background-color: #f3f6f9;
            background-size: 28.12px;

            &.youtube {
              background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
            }

            &.vimeo {
              background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
            }

            &.instagram {
              background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
            }

            &.facebook {
              background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
            }

            &.twitter {
              background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
            }

            &.spotify {
              background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
            }

            &.link {
              background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
              background-size: 12px;
            }
            &.document {
              background-image: url(${process.env
                .REACT_APP_CDN_URL}/images/studio_resource_document_mobile_icon_light.svg);
            }
          }
        `}
`;

export const PreviewTitle = styled.h4`
  font-weight: bold;
  font-size: 12.6631px;
  line-height: 17px;
  letter-spacing: -0.140701px;
  color: #1e0a3c;
  margin-bottom: 10.67px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PreviewLargeCardWrapper = styled.div`
  display: flex;
  margin-bottom: 22px;
`;

export const Card = styled.div`
  position: relative;
  min-width: 185px;
  max-width: 185px;
  border-radius: 5.3px;
  height: 104px;
  margin-right: 5.3px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);

  .previewResource__playIcon {
    position: absolute;
    left: 8px;
    top: 23px;
  }

  &.full-width {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const LargeCardName = styled.p`
  position: relative;
  font-weight: 700;
  font-size: 9.33635px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: #ffffff;
  overflow-wrap: break-word;
  max-width: 169px;
  white-space: pre-line;
  word-break: break-all;
  max-height: 40px;
  padding: 0 8px;
  margin-bottom: 2px !important;
  text-overflow: ellipsis;
  max-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  z-index: 1;
`;

export const LabelAndDuration = styled.div`
  position: relative;
  padding: 0 8px;
  font-weight: 600;
  font-size: 7.3357px;
  line-height: 11px;
  letter-spacing: 0.04em;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  z-index: 1;
`;

export const NarrowCard = styled(Card)`
  min-width: 109px;
  max-width: 109px;
  height: 145px;
  box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);

  ${LargeCardName} {
    -webkit-line-clamp: 3;
    max-height: 39px;
  }
`;

export const CardPhoto = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-end;

  .overlay {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    bottom: 0;
    background: linear-gradient(transparent, #00000080);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .previewResource__playIcon {
    position: absolute;
    left: 8px;
    top: 64px;
  }

  ${props =>
    props.coverImage
      ? css`
          background-image: url(${props.coverImage});
        `
      : css`
          background-image: url(${CDN_URL}/images/drop_image.svg);
          background-color: #f3f6f9;
          background-size: 28.12px;

          &.youtube {
            background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
          }

          &.vimeo {
            background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
          }

          &.link {
            background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
          }
        `}
`;

export const ResourceType = styled.div`
  padding-left: 15px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  svg path {
    fill: #000;
  }
  ${props =>
    props.coverImage &&
    css`
      color: #fff;
      svg path {
        fill: #fff;
      }
    `}
`;

export const SquareCard = styled.div`
  position: relative;
  min-width: 109px;
  max-width: 109px;
  border-radius: 7.03504px;
  margin-right: 7px;

  ${CardPhoto} {
    height: 109px;
    border-radius: 7.03504px;
    margin-bottom: 5px;
    box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);

    .previewResource__playIcon {
      position: absolute;
      left: 5px;
      bottom: 0;
      top: initial;
    }
  }

  ${LabelAndDuration} {
    color: #9a9da6;
    font-weight: 600;
    font-size: 7.3357px;
    line-height: 11px;
    padding: 0;
    margin: 2.67px;
    margin-bottom: 0;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
`;

export const SquareName = styled.p`
  display: -webkit-box;
  font-weight: 700;
  font-size: 9.33635px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: #151619;
  margin-bottom: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const MobilePreviewTop = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 14px;
  margin-bottom: 15px;

  .backButton {
    position: absolute;
    left: 8px;

    svg {
      position: absolute;
      top: 1px;
      width: 6px;
      transform: rotateZ(180deg);

      g {
        stroke: #1e0a3c;
      }
    }
  }

  .searchButton {
    position: absolute;
    right: 21px;
    svg {
      position: absolute;
      top: 0;
      width: 16px;

      path {
        stroke: #212121;
      }
    }
  }
`;

export const StatusBar = styled(CustomBrandingStatusBar)`
  position: absolute;
  top: 11px;
  width: calc(100% - 42px);

  .status-bar__left-content {
    font-size: 10px;
    padding-left: 17px;
  }
`;
