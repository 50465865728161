import styled, { css } from 'styled-components';
import { Wrapper } from 'shared/Styles/ModalLayout';
import { CDN_URL } from 'constants/commonData';

export const Tip = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  opacity: 0.3;
  text-align: right;
  padding: 5px 35px;
`;

export const Messages = styled.div`
  background-color: #fff;
  padding: 20px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  background: rgba(234, 49, 74, 0.1);
  font-size: normal;
  font-weight: normal;
  line-height: 15px;
  color: #ea314a;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 18px;

  .label {
    font-weight: bold;
  }

  .icon {
    flex: 0 0 16px;
    height: 16px;
    margin-right: 10px;
    border-radius: 50%;
    background: transparent url(${CDN_URL}/images/alert_warning.svg) no-repeat center center;
    background-size: 100%;
  }
`;

export const InAppMessageWrapper = styled(Wrapper)`
  &.inAppMessageForm {
    height: 675px;
  }
  .inAppMessageForm__header {
    padding-bottom: 15px;
    display: block;

    .inAppMessageForm__header__title {
      margin-bottom: 2px;
    }

    .inAppMessageForm__header__description {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #323c47;
    }
  }

  .inAppMessageForm__content {
    padding: 0;
    height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${Messages} {
      flex: 1 1;
    }
  }

  .inAppMessageForm__actions {
    padding: 10px 30px;
    justify-content: space-between;

    .remaining {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #848484;
    }
  }

  ${props =>
    props.sent &&
    css`
      .inAppMessageForm__actions {
        padding-left: 15px;
        padding-right: 15px;

        .allMessageSent {
          width: 100%;
          background: #f8f8f8;
          border-radius: 3px;
          padding: 8px;
          text-align: center;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #1f2023;
          cursor: not-allowed;

          span {
            opacity: 0.4;
          }
        }
      }
    `}

  .message-paused-alert {
    background: #f6f6f6;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #1f2023;
    padding: 12px;
    margin: 0 15px;

    span {
      opacity: 0.4;
    }
  }
`;
