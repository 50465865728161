import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Input = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  min-height: 18px;
  color: #1f2023;
  max-height: 106px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none !important;
  flex: 1 1 auto;
  z-index: 2;
  user-select: text;
  word-break: break-word;

  p {
    display: block;
    line-height: 18px;
    margin: 0;
    min-height: 18px;
    word-break: break-word;
  }

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }

  span.autofill {
    background: #fff3c2;
    border-radius: 2px;
    padding: 3px 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    display: inline-block;
  }

  img {
    vertical-align: middle;
  }

  &[contenteditable='false']:hover {
    cursor: not-allowed;
  }
`;

export const SubmitButton = styled.button`
  width: 26px;
  height: 26px;
  flex: 0 0 26px;
  background: transparent url(${CDN_URL}/images/send_message.svg) no-repeat center center;
  background-size: auto;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: 0;
  padding: 0;
  margin: 3px 0 3px 10px;

  :disabled {
    background-image: url(${CDN_URL}/images/send_message_disabled.svg);
    cursor: not-allowed;
  }
`;

export const InputContainer = styled.div`
  margin: 0;
  flex: 1 1;
  padding: 5px 10px;
  background: #f8f8f8;
  border-radius: 3px;
  position: relative;
  min-height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .inAppMessage__chatBar__input__placeholder {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #1f2023;
    opacity: 0.4;
    position: absolute;
    left: 10px;
    top: 8px;
    z-index: 1;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 1;

  > * {
    margin-right: 5px;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const AutofillTrigger = styled.div`
  display: flex;

  &.active {
    svg {
      rect {
        fill: #e6e6ff;
      }
      path {
        fill: #726ee4;
      }
    }
  }

  ${props =>
    props.disabled
      ? css`
          :hover {
            cursor: not-allowed;
          }
        `
      : css`
          :hover {
            cursor: pointer;
            svg {
              rect {
                fill: #d9dfe3;
              }
            }
          }
        `}
`;

export const AutofillItem = styled.div`
  padding: 10px;
  box-shadow: 0px 1px 0px #dcdcde;

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 5px;
  }

  .description {
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #000000;
    opacity: 0.5;
  }

  :hover,
  &.selecting {
    background: #f4f4ff;
    cursor: pointer;
  }
`;

export const AutofillPopup = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcde;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow: hidden;
  width: 310px;
  position: fixed;
  z-index: 2;

  ${AutofillItem} {
    :last-child {
      box-shadow: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 11px 30px 11px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;

  .__react_component_tooltip {
    &.app-tooltip {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      padding: 10px 20px;
    }
    &.autoflow-exactdate-tooltip {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      padding: 12px 15px 10px;
      width: 180px;
    }
  }
`;
