import { map } from 'lodash';
import { pluralize } from 'utils/commonFunction';

export const validateInput = (str, limit) => {
  if (!str || str.length > limit) {
    return false;
  }
  return true;
};

export const handleCloseDropdown = event => {
  if (event.target.className.includes('evf-dropdown__menu')) return;

  map(document.getElementsByClassName('evf-dropdown--basic'), item => {
    if (item.className.includes('open')) {
      map(item.children, child => {
        if (child.className.includes('evf-dropdown__trigger-container')) child.click();
      });
    }
  });
};

export const handleCheckWeek = value => {
  return pluralize('WEEK', value, true);
};
