// libs
import React from 'react';
import { components } from 'react-select';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

// utils
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

const CustomSingleValue = props => {
  const { cloudfrontList } = get(props, 'selectProps', {});
  const { author, is_none_option, is_default_team, is_my_default } = get(props, 'selectProps.value', {});
  const { _id, full_name, first_name, last_name, avatar, color } = author || {};

  const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();

  return (
    <components.SingleValue {...props}>
      <div className="wrapper-title">
        <div className="name">{props.children}</div>
        {(is_default_team || is_my_default) && (
          <div className="name-tag">{is_default_team ? 'Workspace Default' : is_my_default ? 'Your Default' : ''}</div>
        )}
      </div>
      {!is_none_option && (
        <>
          <div data-tip data-for={`coach-${_id}`}>
            <Avatar
              name={getUserShortName(author)}
              className="avatar"
              size={20}
              color={color}
              src={convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
            />
          </div>
          {!props.isDisabled && (
            <ReactTooltip className="app-tooltip set-as-default-tooltip" id={`coach-${_id}`} effect="solid" place="top">
              <p>{fullName}</p>
            </ReactTooltip>
          )}
        </>
      )}
    </components.SingleValue>
  );
};

export default CustomSingleValue;
