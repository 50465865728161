import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 600px;
  height: 58px;
  background: #262626;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 12px 24px;
  position: fixed;
  top: ${props => (props.showDownloadBanner ? '10%' : '24px')};
  left: calc(50% - 290px);
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #fff;
    min-width: 382px;
    height: 24px;

    b {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  &.ui.button {
    margin: 0;
    padding: 8px 23px;
    width: 158px;
    height: 34px;
    background: #f5f5f5;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #262626;

    :hover {
      background: rgba(228, 228, 228, 0.9);
    }
  }
`;
