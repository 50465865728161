import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  min-height: 218px;
  max-height: 420px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f8f8f8;
  cursor: pointer;

  .plus-icon {
    width: 20px;
    height: 20px;
    path {
      fill: #202353;
    }
  }

  .label {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #202353;
  }

  &:hover {
    background-color: #f0f1ff;
    .plus-icon path {
      fill: #5158cf;
    }
    .label {
      color: #5158cf;
    }
  }
`;
