/**
 * Wating: Finish design
 */
import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { Item, ItemImageArea, ItemImage, Header, Title, ItemSubText } from '../../style';
import {
  SFallbackWrapper,
  SDescription,
  SMenuItem,
  SMenuItemActive,
  SItemContent,
  SItemTitle,
  SItemText,
  TabsMenu,
} from './style';
import UpgradePathCollenctions from 'assets/images/upgrade-path-studio-collections.png';
import UpgradePathPrograms from 'assets/images/upgrade-path-studio-programs.png';
import UpgradePathOnDemandWorkout from 'assets/images/on_demand_workout.png';
import UpgradePathButton from '../UpgradePathButton';
import { PLANS } from 'constants/commonData';

const tabsKey = {
  RESOURCE_COLLECTION: 'studio_resource_collection',
  STUDIO_PROGRAM: 'studio_program',
  ON_DEMAND_WORKOUT: 'on_demand_workout',
};
const data = [
  {
    name: 'studio_resource_collection',
    headerTitle: 'Low-touch member Solutions',
    imageSrc: UpgradePathCollenctions,
    title: 'Resource Collections',
    tabsName: 'Resource Collections',
    description: 'Provide members quick access to important links, recipes, pdfs, and videos. All in one place.',
    subDescription: 'Available as Add-on or included in Studio Plan',
    buttonName: 'Get Resource Collections',
    buttonProps: {
      planWillSelect: {
        tier: PLANS.pro.key,
        addOns: {
          resource_collections: true,
        },
      },
    },
  },
  {
    name: 'on_demand_workout',
    headerTitle: 'Low-touch member Solutions',
    imageSrc: UpgradePathOnDemandWorkout,
    tabsName: 'Workout Collections',
    title: 'Workout Collections',
    description: 'Provide on-demand Netflix-style training content portal for your clients to browse on their own',
    subDescription: 'Included in On-demand Collections Add-on',
    buttonName: 'Get Workout Collections',
    buttonProps: {
      planWillSelect: {
        tier: PLANS.pro.key,
        addOns: {
          resource_collections: true,
        },
      },
    },
  },
  {
    name: 'studio_program',
    headerTitle: 'Low-touch member Solutions',
    imageSrc: UpgradePathPrograms,
    tabsName: 'Studio Programs',
    title: 'DIY Programs',
    description: 'Offer libraries of Netflix-style training programs for members to browse and train on their own.',
    subDescription: 'Included in Studio Plan',
    buttonName: 'Get Studio Programs',
    buttonProps: {
      planWillSelect: {
        tier: PLANS.studio.key,
        addOns: {
          resource_collections: false,
        },
      },
    },
  },
];

function handleGetElement(props) {
  let disable = [];
  const studioResourceCollection = props.permission.studio_resource_collection;
  const studioProgram = props.permission.studio_program;
  const onDemandWorkout = props.permission.on_demand_workout;

  if (studioResourceCollection) disable.push('studio_resource_collection');
  if (studioProgram) disable.push('studio_program');
  if (onDemandWorkout) disable.push('on_demand_workout');
  if (disable.length === 0) return data;
  else {
    const value = data.filter(item => !disable.includes(item.name));
    return value;
  }
}

export default function StudioFallBack(props) {
  const [active, setActive] = useState(0);
  const dataList = handleGetElement(props);

  useEffect(() => {
    const list = dataList || [];
    const names = map(list, item => item.name);

    map(names, (item, index) => item === props.pathName && setActive(index));
  }, [props.pathName]);

  return (
    <SFallbackWrapper>
      <Helmet>
        <title>{dataList[active].tabsName}</title>
      </Helmet>
      <Header>
        {dataList
          .filter((item, key) => active === key)
          .map((item, key) => (
            <SDescription key={key}>{item.headerTitle}</SDescription>
          ))}
        <Title>Deliver On-Demand Content to your clients</Title>
      </Header>

      {/* <UpgradePathButton text="Show me the Magic" /> */}
      <TabsMenu>
        {dataList.map((item, key) =>
          key === active ? (
            <SMenuItemActive key={key} onClick={() => setActive(key)}>
              {item.tabsName}
            </SMenuItemActive>
          ) : (
            <SMenuItem key={key} onClick={() => setActive(key)}>
              {item.tabsName}
            </SMenuItem>
          ),
        )}
      </TabsMenu>

      {dataList
        .filter((item, key) => key === active)
        .map((item, key) => (
          <Item key={key}>
            <ItemImageArea>
              <ItemImage src={item.imageSrc}></ItemImage>
            </ItemImageArea>

            <SItemContent className={classNames({ 'big-spacing': tabsKey.STUDIO_PROGRAM === item.name })}>
              <SItemTitle>{item.title}</SItemTitle>
              <SItemText>{item.description}</SItemText>
              <UpgradePathButton {...item.buttonProps} text={item.buttonName} />
              <ItemSubText>{item.subDescription}</ItemSubText>
            </SItemContent>
          </Item>
        ))}
    </SFallbackWrapper>
  );
}
