import { toast } from 'react-toastify';
import Request from 'configs/request';

export const Types = {
  ON_DEMAND_SETTINGS_UPDATE_TITLE_REQUEST: 'ON_DEMAND_SETTINGS_UPDATE_TITLE_REQUEST',
  ON_DEMAND_SETTINGS_UPDATE_TITLE_SUCCESS: 'ON_DEMAND_SETTINGS_UPDATE_TITLE_SUCCESS',
  ON_DEMAND_SETTINGS_UPDATE_TITLE_FAILED: 'ON_DEMAND_SETTINGS_UPDATE_TITLE_FAILED',
  ON_DEMAND_SETTINGS_GET_TITLE_REQUEST: 'ON_DEMAND_SETTINGS_GET_TITLE_REQUEST',
  ON_DEMAND_SETTINGS_GET_TITLE_SUCCESS: 'ON_DEMAND_SETTINGS_GET_TITLE_SUCCESS',
  ON_DEMAND_SETTINGS_GET_TITLE_FAILED: 'ON_DEMAND_SETTINGS_GET_TITLE_FAILED',
};

export const updateOndemandTitle = data => {
  if (data === undefined) return;

  return dispatch => {
    dispatch({
      type: Types.ON_DEMAND_SETTINGS_UPDATE_TITLE_REQUEST,
      payload: { data },
    });
    const params = { title: data };
    return dispatch(
      Request.post(
        { url: '/api/v1/on-demand-settings', data: params },
        false,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.ON_DEMAND_SETTINGS_UPDATE_TITLE_SUCCESS,
            });
            toast('The title has been updated.');
          }
        },
        error => {
          dispatch({ type: Types.ON_DEMAND_SETTINGS_UPDATE_TITLE_FAILED, error: error });
        },
      ),
    );
  };
};

export const getOnDemandSettingTitle = () => {
  return dispatch => {
    dispatch({ type: Types.ON_DEMAND_SETTINGS_GET_TITLE_REQUEST });

    return dispatch(
      Request.get({ url: `/api/v1/on-demand-settings` }, true, (response, { dispatch }) => {
        dispatch({ type: Types.ON_DEMAND_SETTINGS_GET_TITLE_SUCCESS, payload: { data: response.data.data } });
      }),
      error => {
        dispatch({ type: Types.ON_DEMAND_SETTINGS_GET_TITLE_FAILED, error: error });
      },
    );
  };
};
