import React from 'react';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { TRIAL_MODE } from 'constants/commonData';
import UpgradePath from 'shared/UpgradePath';

const WarningText = styled.span`
  background: #fff6de;
  border-radius: 3px;
  padding: 5px 15px;
  font-size: 13px;
  line-height: 120%;
  color: #65462a;
  font-weight: normal;
  margin-left: 10px;
`;

const Name = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202353;
`;

const StudioProgramListHeader = ({ total, teamData }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Helmet>
        <title>Studio Programs - Everfit</title>
      </Helmet>
      <UpgradePath pathName="studio_program" fallback="On-demand">
        <Name>Studio Programs</Name>
      </UpgradePath>
      {teamData.trialMode === TRIAL_MODE.Active ? (
        <WarningText>Try out Studio Programs during your Trial!&nbsp;&nbsp;&nbsp;🎉</WarningText>
      ) : null}
    </div>
  );
};

const mapStateToPropss = state => {
  const { rootReducer } = state;

  return {
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

export default connect(mapStateToPropss, null)(StudioProgramListHeader);
