import _ from 'lodash';
import { fromJS } from 'immutable';
import { Types } from './action';

const INITIAL_STATE = fromJS({
    task: {},
    list: [],
    followingList: [],
    completedList: [],
    query: {
        page: 1,
        limit: 10,
        query: {},
    },
});

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case Types.PERSONAL_REMINDER_ARRANGE_TASK_LOCAL:
            const { destinationIndex, sourceIndex, listType } = payload
            if (listType === 'yourList') {
                const localList = Array.from(state.toJS().list)
                localList.splice(sourceIndex, 1)
                localList.splice(destinationIndex, 0, state.toJS().list[sourceIndex])
                return state.set('list', localList)
            } else {
                const localFollowingList = Array.from(state.toJS().followingList)
                localFollowingList.splice(sourceIndex, 1)
                localFollowingList.splice(destinationIndex, 0, state.toJS().followingList[sourceIndex])
                return state.set('followingList', localFollowingList)
            }

        case Types.PERSONAL_REMINDER_ARRANGE_TASK_FAIL:
            const newList = Array.from(state.toJS().list)
            newList.splice(destinationIndex, 1)
            newList.splice(sourceIndex, 0, state.toJS().list[destinationIndex])
            return state.set('list', newList)


        case Types.PERSONAL_REMINDER_REMOVE_TASK_LOCAL:
            const { taskId } = payload
            let removedList = Array.from(state.toJS().list)
            if (removedList.some(task => task._id == taskId)) {
                removedList = removedList.filter(task => task._id !== taskId)
                return state.set('list', removedList);
            } else {
                removedList = Array.from(state.toJS().followingList)
                if (removedList.some(task => task._id == taskId)) {
                    removedList = removedList.filter(task => task._id !== taskId)
                    return state.set('followingList', removedList);
                } else {
                    removedList = Array.from(state.toJS().completedList)
                    removedList = removedList.filter(task => task._id !== taskId)
                    return state.set('completedList', removedList);
                }
            }
        case Types.PERSONAL_REMINDER_ADD_TASK_SUCCESS: {
            const { data } = payload.data;
            
            let newList;
            if (!_.isEmpty(data.assignees)) {
                if (data.assignees.some(trainer => trainer == data.owner)) {
                    newList = Array.from(state.toJS().list);
                    newList.unshift(data)
                    return state.set('list', newList);
                } else {
                    return state
                }
            }
            newList = Array.from(state.toJS().list);
            newList.unshift(data)
            return state.set('list', newList);
        }

        case Types.PERSONAL_REMINDER_GET_LIST_SUCCESS:
            return state.set('list', fromJS(payload.data))


        case Types.PERSONAL_REMINDER_GET_FOLLOWING_LIST_SUCCESS:
            return state.set('followingList', fromJS(payload.data))

        case Types.PERSONAL_REMINDER_GET_TASK_SUCCESS:
            return state.set('task', fromJS(payload.data))


        case Types.PERSONAL_REMINDER_GET_COMPLETED_LIST_SUCCESS:
            return state.set('completedList', fromJS(payload.data))
        case Types.PERSONAL_REMINDER_UPDATE_DATA_SUCCESS:
        case Types.PERSONAL_REMINDER_REMOVE_TASK_SUCCESS:

        case Types.PERSONAL_REMINDER_MARK_AS_COMPLETED:
        case Types.PERSONAL_REMINDER_ARRANGE_TASK_SUCCESS:
        default:
            return state
    }

}