import React from 'react';

const Confetti = props => {
  const { url, width = '100%', height = '100%' } = props;
  if (!url) return;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<dotlottie-player src="${url}" background="transparent" speed="1" style="width: ${width}; height: ${height}" direction="1" mode="normal" loop autoplay></dotlottie-player>`,
      }}
      className="confetti"
    />
  );
};

export default Confetti;
