import { toast } from 'react-toastify';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Request from 'configs/request';
import { pluralize } from 'utils/commonFunction';
import { CLASSNAME_TOAST } from 'constants/commonData';
import { mongoObjectId } from 'utils/commonFunction';
import { changeCalendarStartDate } from 'actions/calendar';
import { updateHideWorkout } from 'redux/workout-builder/actions';

export const Types = {
  GET_PDF_REQUEST: 'GET_PDF_REQUEST',
  GET_PDF_FINISH: 'GET_PDF_FINISH',
  CLIENT_CALENDAR_TRAINING_SELECTED_WEEK: 'CLIENT_CALENDAR_TRAINING_SELECTED_WEEK',
  CLIENT_CALENDAR_TRAINING_COPY_WEEK_REQUEST: 'CLIENT_CALENDAR_TRAINING_COPY_WEEK_REQUEST',
  CLIENT_CALENDAR_TRAINING_COPY_WEEK_SUCCESS: 'CLIENT_CALENDAR_TRAINING_COPY_WEEK_SUCCESS',
  CLIENT_CALENDAR_TRAINING_COPY_WEEK_FAILED: 'CLIENT_CALENDAR_TRAINING_COPY_WEEK_FAILED',
  CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WEEK: 'CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WEEK',
  CLIENT_CALENDAR_TRAINING_SELECTED_WORKOUT: 'CLIENT_CALENDAR_TRAINING_SELECTED_WORKOUT',
  CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WORKOUT: 'CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WORKOUT',
  CLIENT_CALENDAR_TRAINING_COPY_WORKOUT: 'CLIENT_CALENDAR_TRAINING_COPY_WORKOUT',
  CLIENT_CALENDAR_TRAINING_HIDE_WORKOUT: 'CLIENT_CALENDAR_TRAINING_HIDE_WORKOUT',
  CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST: 'CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST',
  CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_SUCCESS: 'CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_SUCCESS',
  CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_FAILED: 'CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_FAILED',
  CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST: 'CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST',
  CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS: 'CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS',
  CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED: 'CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED',
  CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_REQUEST: 'CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_REQUEST',
  CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_SUCCESS: 'CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_SUCCESS',
  CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_FAILED: 'CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_FAILED',
  CLIENT_CALENDAR_TRAINING_NEW_ASSIGNMENT: 'CLIENT_CALENDAR_TRAINING_NEW_ASSIGNMENT',
  CLIENT_CALENDAR_TRAINING_GET_ID_SINGLE_WORKOUT: 'CLIENT_CALENDAR_TRAINING_GET_ID_SINGLE_WORKOUT',
};

export const changeCalendarMode = mode => {
  return {
    type: 'CHANGE_CALENDAR_VIEW_TYPE',
    payload: { mode },
  };
};

export const getPdfRequest = () => {
  return {
    type: Types.GET_PDF_REQUEST,
  };
};

export const getPdfFinish = () => {
  return {
    type: Types.GET_PDF_FINISH,
  };
};

// Select a week to copy or delete
export const handleSelectedWeek = selectedWeek => {
  const TEMP_ID = mongoObjectId();
  return {
    type: Types.CLIENT_CALENDAR_TRAINING_SELECTED_WEEK,
    payload: {
      selectedWeek,
      weekCopyId: TEMP_ID,
    },
  };
};

// Paste a week
export const handlePasteWeek = ({ startDatePaste, multiPaste }) => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = get(state, 'rootReducer.client.selected');
    const fromDate = get(state, 'rootReducer.calendar.selectedWeek.from');
    const weekCopyId = get(state, 'rootReducer.calendar.weekCopyId');
    if (isEmpty(clientId) || isEmpty(fromDate) || isEmpty(startDatePaste)) return;
    dispatch({
      type: Types.CLIENT_CALENDAR_TRAINING_COPY_WEEK_REQUEST,
      payload: { multiPaste },
    });
    return dispatch(
      Request.post(
        {
          url: '/api/assignment/copy-week',
          data: {
            clientId,
            fromDate: fromDate.toFormat('MM-dd-yyyy'),
            toDate: startDatePaste,
            copy_id: weekCopyId,
          },
        },
        false,
        response => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_COPY_WEEK_SUCCESS });
          }
        },
        error => dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_COPY_WEEK_FAILED, error }),
      ),
    );
  };
};

// Reset selected week
export const handleResetSelectedWeek = () => {
  return { type: Types.CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WEEK };
};

// Select workouts
export const handleSelectWorkout = ({ workoutIds, excludeWorkoutIds }) => {
  return { type: Types.CLIENT_CALENDAR_TRAINING_SELECTED_WORKOUT, payload: { workoutIds, excludeWorkoutIds } };
};

//Reset select workouts
export const handleResetSelectWorkout = () => {
  return { type: Types.CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WORKOUT };
};

export const handleCopyWorkout = () => {
  return { type: Types.CLIENT_CALENDAR_TRAINING_COPY_WORKOUT };
};

export const handlePasteMultipleWorkout = ({ toDate, multiPaste = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = get(state, 'rootReducer.client.selected');
    const selectedWorkout = get(state, 'rootReducer.calendar.selectedWorkout');
    if (isEmpty(clientId) || isEmpty(selectedWorkout) || isEmpty(toDate)) return;
    dispatch({
      type: Types.CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST,
      payload: { multiPaste },
    });
    return dispatch(
      Request.post(
        {
          url: `/api/assignment/bulk-copy`,
          data: {
            clientId,
            assignmentIds: selectedWorkout,
            toDate,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_SUCCESS });
          }
        },
        error => {
          dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_FAILED, error });
        },
      ),
    );
  };
};

export const handleRemoveWorkouts = () => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = get(state, 'rootReducer.client.selected');
    const selectedWorkout = get(state, 'rootReducer.calendar.selectedWorkout');
    const excludeWorkout = get(state, 'rootReducer.calendar.excludeWorkout');
    const removeWorkoutIds = filter(selectedWorkout, id => !includes(excludeWorkout, id));
    if (isEmpty(clientId) || isEmpty(selectedWorkout)) return;
    if (isEmpty(removeWorkoutIds)) {
      dispatch(handleResetSelectWorkout());
      toast(`0 Workouts have been removed.`, { className: CLASSNAME_TOAST.TRAINING_CALENDAR });
      return;
    }
    dispatch({
      type: Types.CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: `/api/assignment/bulk-remove`,
          data: {
            clientId: clientId,
            assignmentIds: removeWorkoutIds,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          const workoutDeleted = get(response, 'data.data.removedIds', []);
          if (success) {
            dispatch({
              type: Types.CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS,
              payload: workoutDeleted,
            });
            const unit = pluralize('Workout', workoutDeleted.length);
            const verbs = workoutDeleted.length !== 1 ? 'have' : 'has';
            toast(`${workoutDeleted.length} ${unit} ${verbs} been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => {
          dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED, error });
        },
      ),
    );
  };
};

export const handleRemoveWeek = ({ startDateRemove, deleteFromDateFormat, deleteToDateContentFormat }) => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = get(state, 'rootReducer.client.selected');
    if (isEmpty(clientId) || isEmpty(startDateRemove)) return;
    dispatch({
      type: Types.CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: '/api/assignment/remove-week',
          data: {
            clientId,
            fromDate: startDateRemove,
          },
        },
        false,
        response => {
          const removedIds = get(response, 'data.data.removedIds', false);
          const invalidIds = get(response, 'data.data.invalidIds', []);
          if (removedIds) {
            dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_SUCCESS, payload: removedIds });
            const workoutDeleted = removedIds.length;
            const unit = pluralize('Workout', workoutDeleted);
            const verbs = workoutDeleted !== 1 ? 'have' : 'has';
            const isInvalidDeleted = invalidIds.length === 0;
            toast(
              `${
                isInvalidDeleted ? 'All' : workoutDeleted
              } ${unit} from ${deleteFromDateFormat} to ${deleteToDateContentFormat} ${
                isInvalidDeleted ? 'have' : verbs
              } been removed.`,
              { className: CLASSNAME_TOAST.TRAINING_CALENDAR },
            );
          }
        },
        error => dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_FAILED, error }),
      ),
    );
  };
};

export const socketNewAssignment = data => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = get(state, 'rootReducer.client.selected');
    if (isEqual(clientId, get(data, 'client'))) {
      return dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_NEW_ASSIGNMENT, payload: data });
    }
    return;
  };
};

export const getIdRemoveSingleWorkout = id => {
  return { type: Types.CLIENT_CALENDAR_TRAINING_GET_ID_SINGLE_WORKOUT, payload: id };
};

export const hideWorkout = (status, assignmentId, isCreateNew) => {
  return dispatch => {
    if (isCreateNew) {
      return dispatch({ type: Types.CLIENT_CALENDAR_TRAINING_HIDE_WORKOUT, payload: status });
    } else {
      return dispatch(
        Request.patch(
          {
            url: `/api/assignment/${assignmentId}/settings`,
            data: {
              hide_workout: status,
            },
          },
          false,
          response => {
            const { data } = response.data;

            dispatch(changeCalendarStartDate());
            dispatch(updateHideWorkout(data));
            toast(`Workout has been ${status ? '' : 'un'}hidden.`, { className: CLASSNAME_TOAST.TRAINING_CALENDAR });
          },
          error => {},
        ),
      );
    }
  };
};

export const handleHideWorkouts = () => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = get(state, 'rootReducer.client.selected');
    const selectedWorkout = get(state, 'rootReducer.calendar.selectedWorkout');
    const excludeWorkout = get(state, 'rootReducer.calendar.excludeWorkout');
    const removeWorkoutIds = filter(selectedWorkout, id => !includes(excludeWorkout, id));
    if (isEmpty(clientId) || isEmpty(selectedWorkout)) return;
    if (isEmpty(removeWorkoutIds)) {
      dispatch(handleResetSelectWorkout());
      toast(`0 Workouts have been hidden.`, { className: CLASSNAME_TOAST.TRAINING_CALENDAR });
      return;
    }

    return dispatch(
      Request.post(
        {
          url: `/api/assignment/bulk-hide`,
          data: {
            client_id: clientId,
            assignment_ids: removeWorkoutIds,
          },
        },
        true,
        (response, { dispatch }) => {
          const { number_of_hide_workout } = response.data.data;

          const unit = pluralize('Workout', number_of_hide_workout);
          const verbs = number_of_hide_workout !== 1 ? 'have' : 'has';
          toast(`${number_of_hide_workout} ${unit} ${verbs} been hidden.`, {
            className: CLASSNAME_TOAST.TRAINING_CALENDAR,
          });
          dispatch(handleResetSelectWorkout());
          dispatch(changeCalendarStartDate());
        },
        error => {},
      ),
    );
  };
};
