import styled from 'styled-components';
import { Button, Modal } from 'semantic-ui-react';

export const ResumeAction = styled(Button)`
  width: 109px;
  height: 32px;
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    &.autoflow-action-resume-modal {
      width: 520px;
      padding: 30px 33px 35px;
      border-radius: 8px;
    }
    .content {
      padding: 0;
    }
    ${ResumeAction} {
      background: #5158cf !important;
      border-radius: 5px !important;
      background: #5158cf;
      border-radius: 5px;
      font-weight: 600;
      font-size: 13px;
      color: #fff;
    }
  }

  .evfSelectBoxContainer {
    .evfSelectBox__control .evfSelectBox__single-value {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

export const Wrapper = styled.div``;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
`;

export const Desc = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 22px;
  padding-right: 20px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #6a778a;
  text-transform: uppercase;
`;

export const DayPicker = styled.div`
  width: 152px;
  height: 42px;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover,
  &.opened {
    border: 1px solid #5158cf;
  }
  &.warning {
    border: 1px solid #ff1b1b;
  }
  font-size: 13px;
  line-height: 18px;
  i.icon {
    color: #95a6b7;
    &.disabled {
      cursor: not-allowed;
    }
  }
`;

export const Calendar = styled.div`
  width: 339px;
  height: 295px;
  border: 1px solid #eceef4;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 47px;
  left: -26px;
  background-color: #fff;
  padding: 20px 27px 30px;
  &:before {
    content: '';
    width: 9px;
    height: 9px;
    border: 1px solid #eceef4;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);
    position: absolute;
    left: 32px;
    top: -5px;
    background: #fff;
  }
`;

export const CalendarTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #202353;
  line-height: 22px;
  margin-bottom: 21px;
  text-align: center;
`;

export const CalendarActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Weeks = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
`;

export const SelectedDay = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  align-items: center;
`;

export const DaysOfWeeks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
`;

export const Day = styled.div`
  width: 37px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #202353;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-bottom: 8px;
  &:hover,
  &.selected {
    border: 1px solid #5158cf;
  }
`;

export const Footer = styled.div`
  text-align: right;
  margin-top: 30px;
`;
