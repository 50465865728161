import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get, round, isEmpty } from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
// import containers
import ManualGoalModal from '../ManualGoalModal';
import Note from '../Note';
// import actions
import { toggleModal } from 'actions/modal';
// import icons
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as GoalBanner } from 'assets/icons/goal-banner.svg';
import { DropdownIndicator } from 'shared/Icons';
import DailyCaloStyles from './styles';
import DailyCaloriesChart from './DailyCaloriesChart';

const viewOptions = [
  { label: 'All', value: 'all' },
  { label: 'Protein', value: 'protein' },
  { label: 'Carbs', value: 'carbs' },
  { label: 'Fat', value: 'fat' },
];

const getValue = (object, key) => {
  if (get(object, ['value', key])) {
    return round(get(object, ['value', key]));
  } else {
    return '-';
  }
};

const DailyCalo = ({
  trainingGoal,
  restGoal,
  reportData,
  clientId,
  dispatch,
  weekCalendar,
  fetchReport,
  turnOff,
  viewSize,
}) => {
  const [view, setview] = useState(viewOptions[0]);
  const { averageGoal, averageMeal } = reportData;

  const isHighlight = categoryKey => {
    const dailyAvg = get(averageMeal, ['value', categoryKey]);
    const goalAvg = get(averageGoal, ['value', categoryKey]);
    return dailyAvg && goalAvg && dailyAvg / goalAvg > 1.1;
  };

  const handleEditGoal = () => {
    if (turnOff) {
      return;
    }
    dispatch(
      toggleModal(
        true,
        <ManualGoalModal
          clientId={clientId}
          typeDefault={get(trainingGoal, 'value.type')}
          data={trainingGoal ? { ...trainingGoal.value, totalCalo: trainingGoal.value.calories } : null}
          restDayData={
            restGoal
              ? {
                  ...restGoal.value,
                  totalCalo: restGoal.value.calories,
                  type: restGoal.rest_days,
                  rest_days: restGoal.rest_days,
                }
              : null
          }
          fetchReport={fetchReport}
        />,
      ),
    );
  };
  return (
    <DailyCaloStyles>
      <div className="chart-section">
        <div className="chart-title">
          <div>Daily Calories (Kcal)</div>
          <Select
            options={viewOptions}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            menuPosition="fixed"
            onChange={setview}
            classNamePrefix="evfSelectBox"
            className="evfSelectBoxContainer"
            value={view}
            isSearchable={false}
            styles={{
              control: base => ({
                ...base,
                minWidth: 120,
                minHeight: 28,
                borderColor: '#D6DAE4',
                backgroundColor: '#ffffff',
              }),
            }}
          />
        </div>
        <DailyCaloriesChart
          reportData={reportData}
          weekCalendar={weekCalendar}
          viewType={view.value}
          viewSize={viewSize}
        />
      </div>
      <div className="detail-section">
        <div className="detail-title">
          <div className="title">
            <span>Calories Goal</span>
            <div className="edit-btn" onClick={handleEditGoal}>
              <EditIcon />
              <div>Edit</div>
            </div>
          </div>
          <Note clientId={clientId} />
        </div>
        {!isEmpty(restGoal) && <div className="detail-subtitle">Training day and Rest day goals</div>}
        {isEmpty(restGoal) && get(averageGoal, 'value.isAvgTrainingGoal') && (
          <div className="detail-subtitle">Weekly averages</div>
        )}
        <div className="detail-table">
          <div className="row row-header">
            <div className="col col-name" />
            <div className="col col-daily">Daily avg</div>
            <div className="col col-goal">
              {restGoal || get(averageGoal, 'value.isAvgTrainingGoal') ? 'Avg Goal' : 'Goal'}
            </div>
          </div>
          <div className="row">
            <div className="col col-name">Protein</div>
            <div className={classnames('col col-daily', { 'col-daily__highlight': isHighlight('protein_gram') })}>
              {getValue(averageMeal, 'protein_gram')}
            </div>
            <div className="col col-goal">{getValue(averageGoal, 'protein_gram')}</div>
          </div>
          <div className="row">
            <div className="col col-name">Carbs</div>
            <div className={classnames('col col-daily', { 'col-daily__highlight': isHighlight('carbs_gram') })}>
              {getValue(averageMeal, 'carbs_gram')}
            </div>
            <div className="col col-goal">{getValue(averageGoal, 'carbs_gram')}</div>
          </div>
          <div className="row">
            <div className="col col-name">Fat</div>
            <div className={classnames('col col-daily', { 'col-daily__highlight': isHighlight('fat_gram') })}>
              {getValue(averageMeal, 'fat_gram')}
            </div>
            <div className="col col-goal">{getValue(averageGoal, 'fat_gram')}</div>
          </div>
          <div className="row row-calo">
            <div className="col col-name">Calories</div>
            <div className="col col-daily">{getValue(averageMeal, 'calories')}</div>
            <div className="col col-goal">{getValue(averageGoal, 'calories')}</div>
          </div>
        </div>
        <GoalBanner className="goal-banner" />
      </div>
    </DailyCaloStyles>
  );
};

export default connect()(DailyCalo);
