import React from 'react';
import { get } from 'lodash';
import { TASK_TYPES } from 'constants/commonData';
import CardThumbnail from './CardThumbnail';

import * as S from './style';

function PreviewSquares(props) {
  const { section } = props;
  return (
    <S.SectionMobilePreview>
      <S.PreviewTitle>
        <S.ViewName>{section.section_name}</S.ViewName>
        <S.ViewMore>View more</S.ViewMore>
      </S.PreviewTitle>
      <S.PreviewLargeCardWrapper>
        {section.resources.map(resource => {
          if (resource.source_type === TASK_TYPES.FORM && get(resource, 'form.is_archived', false)) {
            return null;
          }
          return (
            <S.SquareCard key={resource._id}>
              <CardThumbnail resource={resource} showPhoto={true} />
              <S.SquareName>{resource.name}</S.SquareName>
            </S.SquareCard>
          );
        })}
      </S.PreviewLargeCardWrapper>
    </S.SectionMobilePreview>
  );
}

export default PreviewSquares;
