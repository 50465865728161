import React from 'react';
import get from 'lodash/get';

import * as S from '../style';

const MultiChoiceResponse = ({ answer }) => {
  return (
    <S.MultiWrapper>
      {get(answer, 'multi_choice_answers', []).map(item => {
        const isOther = get(item, 'is_other', false);
        const otherAnswer = get(answer, 'other_answer', '').trim() || 'Other';

        return (
          <>
            <S.QuestionAnswer isMulti>{get(item, 'label', '')}</S.QuestionAnswer>
            <S.OtherAnswer>{isOther ? otherAnswer : ''}</S.OtherAnswer>
          </>
        );
      })}
    </S.MultiWrapper>
  );
};

export default MultiChoiceResponse;
