import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'shared/FormControl';
import { CLIENT_STATUS } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';
import * as S from './style';
import './style.scss';

function BulkSendInvitation(props) {
  const { clients } = props;
  const [open, setOpen] = useState(false);
  const [isSumitting, setSubmitting] = useState(false);

  if (!clients) {
    return null;
  }

  const array = clients.slice();
  const connectedClients = _.remove(array, (item) => item.client_connection === CLIENT_STATUS.connected);
  const missingEmailClients = _.remove(array, (item) => !item.email);
  const pendingClients = _.remove(array, (item) => item.client_connection === CLIENT_STATUS.pending);
  const offlineClients = _.remove(array, (item) => item.client_connection === CLIENT_STATUS.offline);
  const totalProcessedClients = pendingClients.length + offlineClients.length;
  const totalUnprocessedClients = connectedClients.length + missingEmailClients.length;

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  const onSendInvites = () => {
    if (isSumitting) {
      return false;
    }

    setSubmitting(true);

    props
      .bulkSendInvitation({ clients: [..._.map(pendingClients, '_id'), ..._.map(offlineClients, '_id')] })
      .then(() => {
        toast(
          `Invitations have been sent successfully to ${pluralize('client', totalProcessedClients, true)}`
        );
        onClose();

        if (typeof props.onSuccess === 'function') {
          props.onSuccess();
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      className="modal--bulk-send-invitation"
      trigger={props.trigger}
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Header>
            <S.HeaderIcon />
            <div>Send Invites</div>
          </S.Header>
          <S.Content>
            <p>
              Email invitations will be sent to&nbsp;
              {totalProcessedClients ? (
                <>
                  {!!pendingClients.length && (
                    <span>
                      <b>{pendingClients.length} Pending</b> 
                    </span>
                  )}
                  {pendingClients.length && offlineClients.length ? ' and ' : null}
                  {!!offlineClients.length && (
                    <span>
                      <b>{offlineClients.length} Offline</b>
                    </span>
                  )}
                </>
              ) : (
                '0'
              )}&nbsp;
              {pluralize('client', totalProcessedClients)}.
            </p>
            {!!totalUnprocessedClients && <br />}
            {!!totalUnprocessedClients && (
              <p>
                {pluralize('client', totalUnprocessedClients, true)} will not be sent invites:&nbsp;
                {!!missingEmailClients.length && (
                  <span>
                    {missingEmailClients.length} {missingEmailClients.length === 1 ? 'is' : 'are'} missing the
                    email field
                  </span>
                )}
                {!!connectedClients.length && (
                  <span>
                    {missingEmailClients.length ? ', and ' : ''}
                    {connectedClients.length} {connectedClients.length === 1 ? 'is' : 'are'} already
                    Connected.
                  </span>
                )}
              </p>
            )}
            {!!totalProcessedClients && (
              <>
                <S.Divide />
                <S.Detail>
                  {!!pendingClients.length && (
                    <p>
                      <b>{pluralize('Pending client', pendingClients.length, true)} </b>will be resent
                      invitations.
                    </p>
                  )}
                  {!!offlineClients.length && (
                    <p>
                      <b>{pluralize('Offline client', offlineClients.length, true)}</b> will be sent new
                      invitations.
                    </p>
                  )}
                </S.Detail>
              </>
            )}
          </S.Content>
          <S.Actions>
            <Button onClick={onClose}>Cancel</Button>
            <Button purple onClick={onSendInvites}>
              Send Invites
            </Button>
          </S.Actions>
        </S.Wrapper>
      </Modal.Content>
    </Modal>
  );
}

export default BulkSendInvitation;
