import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import { hideError } from 'actions/error';
import { getTaskDetail } from 'redux/task/task.actionCreators';
import TaskCreate from 'components/TaskCreate';

function ClientTaskDetail(props) {
  const onCloseForm = () => {
    const {
      match: {
        params: { clientId },
      },
    } = props;
    props.toggleModal(false);
    props.replace(`/home/client/${clientId}/task`);
  };

  useEffect(() => {
    const {
      match: {
        params: { taskId, clientId },
      },
    } = props;

    props
      .getTaskDetail(taskId)
      .then(response => {
        const { data } = response.data;
        props.toggleModal(true, <TaskCreate task={data} onCloseForm={onCloseForm} />);
      })
      .catch(err => {
        props.replace(`/home/client/${clientId}/task`);
        //TODO: should improve UX
        if (err.errorCode === '16_3') {
          props.hideError();
          window.location.reload();
        }
      });
  }, []);

  return null;
}

export default connect(null, { toggleModal, getTaskDetail, replace, hideError })(ClientTaskDetail);
