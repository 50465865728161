// Lib
import React, { useRef } from 'react';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames';

import { sortCaloriesToTop } from 'components/RecipeDetail/helper';
import { listMacronutrients } from 'components/RecipeDetail/constants';

// Assets
import { ReactComponent as InfoIcon } from 'assets/icons/Ingredients/info_icon.svg';

// Style
import * as S from './style';

const NutrientInfo = ({ macroNutrients = [], otherNutrients = [], listOtherNutrients = [] }) => {
  const nutrientWrapperRef = useRef(null);
  const nutrientListRef = useRef(null);

  const onMouseEnter = () => {
    const elementNutrientWrapper = nutrientWrapperRef.current;
    const elementNutrientList = nutrientListRef.current;
    if (elementNutrientWrapper) {
      const rect = elementNutrientWrapper.getBoundingClientRect();
      const { x, y, width, height } = rect;
      if (elementNutrientList) {
        const rect = elementNutrientList.getBoundingClientRect();
        const { width: widthListNutrient } = rect;
        const isDropdownVisible = window.innerHeight < rect.bottom;
        elementNutrientList.style.position = 'fixed';
        elementNutrientList.style.left = `${x}px`;
        elementNutrientList.style.top = `${y + height}px`;
        if (!isDropdownVisible) {
          elementNutrientList.style.transform = `translateX(calc(${width}px - ${widthListNutrient}px))`;
        } else {
          elementNutrientList.style.transform = `translate(calc(${width}px - ${widthListNutrient}px), calc(-100% - ${height}px))`;
          elementNutrientList.style.paddingTop = `0px`;
          elementNutrientList.style.paddingBottom = `8px`;
        }
      }
    }
  };

  const onMouseLeave = () => {
    const elementNutrientList = nutrientListRef.current;
    if (elementNutrientList) {
      elementNutrientList.style.transform = 'none';
      elementNutrientList.style.paddingTop = `8px`;
      elementNutrientList.style.paddingBottom = `0px`;
    }
  };

  return (
    <S.Wrapper ref={nutrientWrapperRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="icon-info">
        <InfoIcon />
      </div>
      <div className="list-nutrient-wrapper" ref={nutrientListRef}>
        <div className="nutrient-content">
          <h4>Nutrition info</h4>
          <div className="list-nutrient">
            {sortCaloriesToTop([...listMacronutrients]).map(item => {
              const { label, unit, type } = item;
              const { value } = macroNutrients.find(item => (item || {}).type === type);

              return (
                <div className={classNames('item-nutrient', { 'item-nutrient-invalid': isNaN(value) })} key={type}>
                  <span className="name-nutrient">{label}</span>
                  <span className="value-nutrient">
                    {isNumber(value) && value >= 0 ? value : '—'}
                    <span className="unit-nutrient"> {unit}</span>
                  </span>
                </div>
              );
            })}
            {(listOtherNutrients || []).map(item => {
              const { name, unit, key } = item;
              const { value } = otherNutrients.find(item => (item || {}).type === key) || {};

              return (
                <div className={classNames('item-nutrient', { 'item-nutrient-invalid': isNaN(value) })} key={key}>
                  <span className="name-nutrient">{name}</span>
                  <span className="value-nutrient">
                    {isNumber(value) && value >= 0 ? value : '—'}
                    <span className="unit-nutrient"> {unit}</span>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </S.Wrapper>
  );
};

export default NutrientInfo;
