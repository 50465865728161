import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

const WORKOUT_SPACE_LEFT_BORDER = 13;
const WORKOUT_MAX_OFFSET = 160;
const WORKOUT_MIN_OFFSET = 87;

export default props => {
  const { program, elementId, isMonday, isWorkout } = props;
  const [offset, setOffset] = useState();
  const arrowOffset = useMemo(() => {
    if (isMonday && isWorkout) return 11;
    return;
  }, [isMonday, isWorkout]);

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      if (isMonday) {
        const width = get(entries, '[0].contentRect.width');
        let offsetRight = width / 2;
        let spaceToLeftBorder = 0;
        let maxOffset = 0;
        let minOffset = 0;
        if (isWorkout) {
          spaceToLeftBorder = WORKOUT_SPACE_LEFT_BORDER;
          maxOffset = WORKOUT_MAX_OFFSET;
          minOffset = WORKOUT_MIN_OFFSET;
        }
        offsetRight -= spaceToLeftBorder;
        // check case first time render
        const classList = get(entries, '[0].target.classList');
        if (!includes(classList, 'app-tooltip-white')) {
          offsetRight -= 26; // reduce size when class tooltip not exist
        }
        offsetRight = Math.min(offsetRight, maxOffset);
        offsetRight = Math.max(offsetRight, minOffset);
        setOffset({
          right: offsetRight,
        });
      }
    }),
  );

  const resizedContainerRef = useCallback(container => {
    const tooltipRef = get(container, 'tooltipRef');
    if (!isEmpty(tooltipRef)) {
      resizeObserver.current.observe(tooltipRef);
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    }
  }, []);

  if (!program) {
    return null;
  }

  const name = get(program, 'title', '');
  const isSynced = get(program, 'active_sync');

  return (
    <Container data-tip data-for={`${elementId}-assigned-program`} arrowOffset={arrowOffset}>
      <div className={classNames('assigment-in-program', { 'is-synced': isSynced })} />
      <ReactTooltip
        className="app-tooltip-white assignment-in-program-tooltip"
        id={`${elementId}-assigned-program`}
        show={true}
        effect="solid"
        place="top"
        offset={offset}
        ref={resizedContainerRef}
      >
        <AssignedProgramTooltipStyles>
          <div>
            <span>Program:</span> <span className="title">{name}</span>
          </div>
          <div>{isSynced && <span className="live-sync">Live Sync</span>}</div>
        </AssignedProgramTooltipStyles>
      </ReactTooltip>
    </Container>
  );
};

const AssignedProgramTooltipStyles = styled.div`
  display: flex;
  justify-content: space-between;

  .title {
    word-break: break-word;
  }
`;

const Container = styled.div`
  ${props =>
    props.arrowOffset &&
    css`
      .__react_component_tooltip.app-tooltip-white {
        max-width: 298px;
      }

      .__react_component_tooltip.app-tooltip-white.place-top:before,
      .__react_component_tooltip.app-tooltip-white.place-top:after {
        left: ${props.arrowOffset}px;
      }
    `}
`;
