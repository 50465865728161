import React from 'react';
import styled from 'styled-components';

const ItemFeatureApp = props => {
  const { screenshot, title, description } = props;
  return (
    <ItemFeatureAppWrapper>
      <img src={screenshot} alt="" className="screenshot" />
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </ItemFeatureAppWrapper>
  );
};

export default ItemFeatureApp;

const ItemFeatureAppWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 293px;
  width: 100%;
  overflow: hidden;
  margin-left: 16px;

  .screenshot {
    width: 293px;
    height: 232px;
    margin-bottom: 24px;
    object-fit: contain;
    display: block;
  }

  .title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 8px;
  }

  .description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1e0a3c;
  }
`;
