import axios from 'axios';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import React, { useEffect, useRef, useState } from 'react';
import { map, filter, findIndex, isEmpty, forEach, get } from 'lodash';

import {
  mediaLog,
  revokeObjectURL,
  createObjectURL,
  revokeMultipleObjectURL,
  getMultiplePresignedUploadUrlsByParams,
  convertHeicToJpeg,
} from 'utils/commonFunction';
import ProgressRing from 'shared/ProgressRing';
import { validateFiles } from 'utils/validations';
import ProgressPhotoTag from 'shared/ProgressPhotoTag';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import { removeUploadedFileFromServer } from 'utils/commonRequest';
import CloseIcon from 'assets/icons/close_progress_photo_image.svg';
import { DEFAULT_STATE, PROGRESS_PHOTO_DEFAULT_SETTINGS } from './constants';

import * as S from './style';
import * as Styles from 'shared/FileUpload/style';

const ProgressPhotoUploadMultiple = props => {
  const { onErrorMessage, getListImages, isUploadingImages } = props;

  const [detectRender, setDetectRender] = useState(true);
  const [uploadPhotoProgress, setUploadPhotoProgress] = useState({});
  const prevPhotoData = useRef({ ...DEFAULT_STATE.photoData });
  const listPhotoUpload = useRef(null);
  const uploadTasks = {};
  const idsUploadingRef = useRef([]);

  useEffect(() => {
    return () => {
      let listObjectURL = [];
      const localPhotoUrls = map(
        filter(prevPhotoData.current.list, item => !!item && item.isLocalFile),
        item => item.src,
      );
      listObjectURL = listObjectURL.concat(localPhotoUrls);
      if (listObjectURL.length) {
        revokeMultipleObjectURL(listObjectURL);
      }
      forEach(uploadTasks, task => {
        if (typeof task === 'function') {
          task();
        }
      });
    };
  }, []);

  useEffect(() => {
    getListImages && getListImages(prevPhotoData.current && prevPhotoData.current.list);
  }, [prevPhotoData.current]);

  const updatePhotoData = (data, addingPhotos = []) => {
    prevPhotoData.current = { ...prevPhotoData.current, ...data };
    if (addingPhotos.length) {
      uploadFilesToServer(addingPhotos);
    }
  };

  const uploadFilesToServer = async files => {
    if (files.length) {
      const uploadArr = await getMultiplePresignedUploadUrlsByParams(
        files.map(({ file }) => file),
        {
          method: 'POST',
          url: 'api/file/gen-presigned-urls-progress-photo',
          data: { files: files.map(({ file }) => ({ name: file.name, mimetype: file.type })) },
        },
      );
      uploadArr.forEach((file, index) => {
        const { uploadUrl, configs } = file;
        const { _id } = files[index];
        axios({
          method: 'PUT',
          url: uploadUrl,
          headers: { 'Content-Type': configs.mimetype },
          data: files[index].file,
          onUploadProgress: progressData => {
            const { loaded, total } = progressData;
            const { list } = prevPhotoData.current;
            const newProgress = Math.floor((loaded / total) * 100);
            const itemIndex = findIndex(list, obj => obj._id === _id);

            if (itemIndex !== -1) {
              setUploadPhotoProgress(prevState => ({ ...prevState, [_id]: newProgress }));
            }
          },
          cancelToken: new axios.CancelToken(function executor(c) {
            uploadTasks[_id] = c;
          }),
        })
          .then(res => {
            const { _id } = files[index];
            const { original_name, mimetype } = configs;
            mediaLog({
              status: 2,
              name: original_name,
              fileType: mimetype,
              description: 'Upload success file via Progress Photo',
            });
            onUploadPhotoSuccess({ _id, configs });
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              onRemoveSinglePhoto({ data: { ...error, message: 'Canceled' }, _id });
            } else {
              delete uploadTasks[_id];
            }
          });
      });
    }
  };

  const selectMultiplePhotos = (originalFiles, isDropped) => {
    onErrorMessage('');
    let files = originalFiles;
    const { list } = prevPhotoData.current;
    if (isDropped) {
      const checkedFiles = map(originalFiles, file => {
        const error = validateFiles({
          files: [file],
          validateExtentions: PROGRESS_PHOTO_DEFAULT_SETTINGS.IMAGE_VALIDATE_EXTENTION,
          maxSize: PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_SIZE * CONVERSION.MB_TO_BYTE,
        });
        if (error) {
          handleShowErrorMessage(error);
        }
        return { file, error };
      });
      files = filter(checkedFiles, item => !item.error).map(item => item.file);
    }
    if (!files.length) {
      return;
    }
    if (originalFiles.length + list.length <= PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_LENGTH_PHOTOS) {
      const parsedPhotos = map(files, (file, index) => {
        const { size, name, type } = file;
        mediaLog({
          status: 1,
          name,
          fileSize: size,
          fileType: type,
          description: 'Send a file via Progress Photo',
        });
        return {
          src: createObjectURL(file),
          isLocalFile: true,
          file: file,
          _id: `adding_multiple_${new Date().getTime()}_${index}`,
        };
      });
      const photoIds = parsedPhotos.map(photo => photo._id);
      idsUploadingRef.current = [...idsUploadingRef.current, ...photoIds];
      isUploadingImages(idsUploadingRef.current);
      const addingPhotos = parsedPhotos.slice(0, PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_LENGTH_PHOTOS - list.length);
      updatePhotoData(
        {
          list: [...list, ...addingPhotos],
        },
        addingPhotos,
      );
      setTimeout(() => {
        if (listPhotoUpload && listPhotoUpload.current) {
          listPhotoUpload.current.scrollLeft = listPhotoUpload.current.scrollWidth;
        }
      }, 300);
    } else {
      onErrorMessage(PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_PHOTO_NUMBER_MESSAGE);
    }
  };

  const onRemoveSinglePhoto = ({ _id }) => {
    const list = prevPhotoData.current.list.slice();
    const index = findIndex(list, item => item._id === _id);
    if (index !== -1) {
      const data = list[index];
      if (data && data.isLocalFile) {
        revokeObjectURL(data.src);
        if (data.bucketData) {
          removeUploadedFileFromServer(data.bucketData);
        }
      }
      list.splice(index, 1);
      updatePhotoData({ list });
      if (prevPhotoData.current) {
        prevPhotoData.current.list = list;
        setDetectRender(!detectRender);
      }
    }
  };

  const updateUploadingImages = photoId => {
    const newArray = filter(idsUploadingRef.current, item => item !== photoId);
    idsUploadingRef.current = newArray;
    isUploadingImages(newArray);
  };

  const onUploadPhotoSuccess = async ({ configs: data, _id }) => {
    if (_id) {
      const index = findIndex(prevPhotoData.current && prevPhotoData.current.list, item => item._id === _id);
      delete uploadTasks[_id];
      if (index !== -1) {
        const list = prevPhotoData.current && prevPhotoData.current.list.slice();
        list[index] = { ...list[index], file: null, bucketData: data, image: data.url, tag: '' };
        updatePhotoData({ list });
        updateUploadingImages(_id);
      } else {
        removeUploadedFileFromServer(data);
      }
    }
  };

  const onCancelUploadPhoto = photoId => {
    updateUploadingImages(photoId);
    if (uploadTasks[photoId] && typeof uploadTasks[photoId] === 'function') {
      uploadTasks[photoId]();
    } else {
      onRemoveSinglePhoto({ _id: photoId });
    }
  };

  // support adding type 'image/heic' to file when uploading on device Windows
  const addTypeHeicToFiles = files => {
    if (files.length) {
      files = files.map(file => {
        const hasNameHeic = get(file, 'name', '').split('.').pop().toLowerCase().includes('heic');
        const isEmptyType = isEmpty(file.type);
        if (hasNameHeic && isEmptyType) {
          const newFile = new File([file], file.name, { type: 'image/heic' });
          return newFile;
        } else {
          return file;
        }
      });
    }
    return files;
  };

  const onDrop = async acceptedFiles => {
    const newAcceptedFiles = addTypeHeicToFiles(acceptedFiles);
    let imagesWithoutHEIC = filter(newAcceptedFiles, file => !get(file, 'type', '').includes('image/heic')) || [];
    const isTypeHeic = filter(newAcceptedFiles, file => get(file, 'type', '').includes('image/heic'));
    if (isTypeHeic.length) {
      const arr = await convertHeicToJpeg(isTypeHeic);
      imagesWithoutHEIC.push(...arr);
    }
    if (newAcceptedFiles.length) {
      selectMultiplePhotos(imagesWithoutHEIC, true);
    }
  };

  const handleShowErrorMessage = error => {
    let message = '';
    if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
      message = PROGRESS_PHOTO_DEFAULT_SETTINGS.FILE_TYPE_SUPPORTED_MESSAGE;
    } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
      message = PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_SIZE_MESSAGE;
    }
    toast.error(message);
  };

  const renderTag = id => {
    return (
      <S.TagWrapper>
        <ProgressPhotoTag id={id} onChooseTag={onChooseTag} />
      </S.TagWrapper>
    );
  };

  const onChooseTag = ({ value, id }) => {
    const { list } = prevPhotoData.current;
    const newList = forEach(list, item => {
      if (item._id === id) {
        item.tag = value;
      }
    });
    setDetectRender(!detectRender);
    updatePhotoData({ list: newList });
  };

  return (
    <>
      {isEmpty(prevPhotoData.current.list) ? (
        <Dropzone onDrop={onDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.ProgressPhotoZone {...getRootProps()} isDragging={isDragActive}>
                <S.UploadIcon />
                <S.UploadContent>
                  Drag & Drop your images here
                  <br />
                  or&nbsp;<span className="custom-upload-content">Choose Files</span>
                </S.UploadContent>
                <input {...getInputProps()} type="file" accept={PROGRESS_PHOTO_DEFAULT_SETTINGS.ACCEPTED_IMAGE} />
              </S.ProgressPhotoZone>
            );
          }}
        </Dropzone>
      ) : (
        <S.MediasWrapper ref={listPhotoUpload}>
          <S.MediaList>
            {prevPhotoData.current.list &&
              prevPhotoData.current.list.map((photo, idx) => {
                const id = get(photo, '_id', '');
                const isEndUploading = uploadPhotoProgress[id] === 100;
                return (
                  <S.MediaItem key={idx} isCypress={window.Cypress}>
                    {!window.Cypress && (
                      <S3ImageWithFallback
                        className="progress-photo-medias"
                        src={[get(photo, 'src', '')]}
                        draggable={false}
                      />
                    )}
                    {isEndUploading ? (
                      <S.CloseIcon onClick={() => onRemoveSinglePhoto({ _id: id })} src={CloseIcon} width={15} alt="" />
                    ) : (
                      <Styles.Wrapper>
                        <Styles.Container>
                          <ProgressRing radius={22} progressBarWidth={3} progress={uploadPhotoProgress[id] || 0} />
                          <Styles.CancelButton
                            onClick={() => onCancelUploadPhoto(id)}
                            className="file-upload-cancel-button"
                          />
                        </Styles.Container>
                      </Styles.Wrapper>
                    )}
                    {isEndUploading && renderTag(id)}
                  </S.MediaItem>
                );
              })}
            {prevPhotoData.current.list &&
              prevPhotoData.current.list.length !== PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_LENGTH_PHOTOS && (
                <Dropzone onDrop={onDrop} multiple={true}>
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <S.MediaUpload {...getRootProps()} isDragging={isDragActive}>
                        <S.UploadIcon />
                        <S.UploadContent>
                          Drag & Drop your
                          <br />
                          images here
                          <br />
                          or&nbsp;<span className="custom-upload-content">Choose Files</span>
                        </S.UploadContent>
                        <input
                          {...getInputProps()}
                          type="file"
                          accept={PROGRESS_PHOTO_DEFAULT_SETTINGS.ACCEPTED_IMAGE}
                        />
                      </S.MediaUpload>
                    );
                  }}
                </Dropzone>
              )}
          </S.MediaList>
        </S.MediasWrapper>
      )}
    </>
  );
};

export default ProgressPhotoUploadMultiple;
