import React, { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { RootCloseWrapper } from 'react-overlays';

import * as S from './style';
import { MenuTrigger } from 'shared/Icons';
import { ReactComponent as TrashIcon } from 'assets/icons/delete_message_trash_icon.svg';
import ConfirmModal from 'shared/ConfirmModal';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

const MessageAction = ({ messageKey, currentRoomId, disabled = false, toggleConfirmModal, deleteMessage }) => {
  if (isEmpty(messageKey) && isEmpty(currentRoomId)) return <></>;
  const [open, setOpen] = useState(false);

  const openPopup = useCallback(() => {
    setOpen(true);
    const actionBox = document.getElementById(`action-${messageKey}`);
    if (!isEmpty(actionBox)) {
      actionBox.style.visibility = 'visible';
      window.addEventListener('scroll', scrollEvent, true);
    }
  }, [open, messageKey]);

  const closePopup = useCallback(() => {
    setOpen(false);
    const actionBox = document.getElementById(`action-${messageKey}`);
    if (!isEmpty(actionBox)) {
      actionBox.style.visibility = null;
      window.removeEventListener('scroll', scrollEvent, true);
    }
  }, [open, messageKey]);

  const scrollEvent = useCallback(() => {
    closePopup();
  }, []);

  const handleConfirmDelete = () => {
    setOpen(false);
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          title="Delete Message"
          content="This message will be unsent for everyone in the chat. Others may have already seen it."
          cancelButtonTitle="Cancel"
          confirmButtonTitle="Remove"
          onConfirm={() => typeof deleteMessage === 'function' && deleteMessage({ messageKey, currentRoomId })}
          noBorder
          className="delete-message-confirm-popup"
        />,
      );
  };

  return (
    <S.MessageActionPopup
      trigger={
        <S.MoreOption disabled={disabled} data-for="more-option-message-item" data-tip>
          <MenuTrigger vertical size={22} active={open} />
          <SharedTooltip id="more-option-message-item" />
        </S.MoreOption>
      }
      flowing={true}
      hoverable={true}
      on="click"
      position="top center"
      open={open}
      onOpen={openPopup}
      onClose={closePopup}
      className="message-action-popup"
      keepInViewPort={true}
    >
      <RootCloseWrapper>
        <S.MessageActionContent>
          <S.MessageActionItem onClick={handleConfirmDelete}>
            <TrashIcon />
            <S.Label>Delete</S.Label>
          </S.MessageActionItem>
        </S.MessageActionContent>
      </RootCloseWrapper>
    </S.MessageActionPopup>
  );
};

export default MessageAction;
