import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import * as S from './style';
import { cameraIcon } from 'constants/images';
import {
  CDN_URL,
  CONVERSION,
  FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO as DEFAULT_FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO,
  FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO as DEFAULT_FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO,
} from 'constants/commonData';
import VideoDuration from 'shared/VideoDuration';
import { getDuration, getMediaType, getPresignedUploadUrl, mediaLog } from 'utils/commonFunction';
import { recordAudio } from 'utils/recordAudio';
import { ReactComponent as VoiceIcon } from 'assets/icons/voice-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play_audio.svg';
import { ReactComponent as StopIcon } from 'assets/icons/stop_audio.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/pause_audio.svg';
import { InboxTrigger, GiphyPopup } from 'shared/Giphy';
import { ReactComponent as ReplyIcon } from 'assets/icons/reply-icon-black.svg';
import { ReactComponent as Close } from 'assets/icons/close-reply.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-purple.svg';
import { SharedTooltip } from 'shared/SharedTooltip';
import { getPlainText } from './helper';

export const FILE_VALIDATIONS = {
  ACCEPTED: 'image/png, image/jpg, image/jpeg, image/gif, .avi, .flv, .mp4, .mov, .3gp, .mp3, .gif',
  EXTENTIONS: ['png', 'jpg', 'jpeg', 'avi', 'flv', 'mp4', 'mov', '3gp', 'mp3', 'gif'],
  MAX_SIZE_VIDEO: 150,
  MAX_SIZE_IMAGE: 25,
};
const FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO = [...DEFAULT_FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO];
const FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO = DEFAULT_FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO.concat(', image/gif');

const STATUS_RECORDING = {
  PLAY: 'play',
  PAUSE: 'pause',
  STOP: 'stop',
};

export const TYPES = { TEXT: 'text', FILE: 'file' };

class ChatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasText: false,
      selectedFiles: [],
      statusRecording: STATUS_RECORDING.PLAY,
      progressVoice: 0,
      playedSeconds: 0,
      offline: false,
      havePermissions: false,
      spamming: false,
    };
    this.recorder = null;
    this.inputRef = React.createRef();
    this.maxSize = FILE_VALIDATIONS.MAX_SIZE * CONVERSION.MB_TO_BYTE;
    this.timeout = null;
    this.playing = false;
    this.cancelling = false;
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ hasText: true }, () => {
        this.inputRef.current.innerHTML = this.props.defaultValue;

        if (this.props.autoFocus) {
          this.focus();
        }
      });
    } else {
      if (this.props.autoFocus) {
        this.focus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { statusRecording, progressVoice, playedSeconds, offline, havePermissions } = this.state;
    const { startRecord, showRecord } = this.props;
    if (havePermissions && !offline && showRecord && startRecord && statusRecording === STATUS_RECORDING.PLAY) {
      this.handleGetDuration(progressVoice);
    }

    if (showRecord && statusRecording === STATUS_RECORDING.PAUSE) {
      this.handleGetPlayedSeconds(playedSeconds, progressVoice);
    }

    window.addEventListener('online', () => this.setState({ offline: false }));
    window.addEventListener('offline', () => {
      this.timeout && clearTimeout(this.timeout);
      this.setState({ offline: true });
    });

    if (
      this.props.isInbox &&
      this.props.autoFocus === true &&
      this.props.disabled === false &&
      prevProps.disabled !== this.props.disabled
    ) {
      this.focus();
    }
  }

  componentWillUnmount() {
    this.props.showRecord && this.handleEndRecording();
  }

  handleGetDuration = duration => {
    if (duration > 59) {
      this.handleStopRecording();
    } else {
      const { statusRecording } = this.state;
      this.timeout = setTimeout(() => {
        if (this.recorder) {
          const recordTime = Math.floor(this.recorder.getRecordTime());
          if (statusRecording === STATUS_RECORDING.PLAY) {
            this.setState({
              progressVoice: recordTime,
            });
          }
        } else {
          this.setState({
            progressVoice: 0,
          });
        }
      }, 100);
    }
  };

  handleGetPlayedSeconds = (seconds, duration) => {
    if (seconds <= duration) {
      this.playingTimeout = setTimeout(() => {
        this.setState({
          playedSeconds: seconds + 1,
        });
      }, 1000);
    } else {
      this.setState({
        playedSeconds: 0,
        statusRecording: STATUS_RECORDING.STOP,
      });
    }
  };

  focus = () => {
    const nodes = _.get(this.inputRef, 'current.childNodes', []);
    const lastNode = nodes[nodes.length - 1];

    if (lastNode) {
      const sel = window.getSelection();
      const range = document.createRange();
      let offset = 0;
      if (lastNode.childNodes.length > 0) {
        offset = lastNode.childNodes.length;
      } else {
        offset = lastNode.textContent.length;
      }
      range.setStart(lastNode, offset);
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      this.inputRef.current && this.inputRef.current.focus();
    }
  };

  onKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  };

  onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode > 36 && keyCode < 41) {
      // press arrow key
      event.stopPropagation();
    }
  };

  onSubmit = () => {
    const { onPost, disabled, startRecord, showRecord, sendingVoiceMessage, notCacheMessage } = this.props;

    if (disabled || sendingVoiceMessage || this.state.spamming) {
      return false;
    }

    const { selectedFiles, hasText } = this.state;
    this.avoidSpam();

    if (hasText) {
      const value = getPlainText(this.inputRef.current.innerHTML);
      const trimValue = value.trimAny('↵').trim();
      if (trimValue) {
        onPost({ type: TYPES.TEXT, data: trimValue }, () => {
          if (!notCacheMessage) {
            if (this.inputRef.current) {
              this.inputRef.current.innerText = '';
            }
            this.setState({ hasText: false });
          }
        });
      }
    } else {
      if (selectedFiles.length) {
        setTimeout(() => {
          onPost({ type: TYPES.FILE, data: selectedFiles });
          showRecord && this.handleEndRecording();
        }, 200);
      }
      if (startRecord) {
        setTimeout(() => {
          this.handleStopRecording({ type: TYPES.FILE }, this.handleEndRecording, this.props.onSendRecord);
        }, 200);
      }
    }

    if (notCacheMessage) {
      if (this.inputRef.current) {
        this.inputRef.current.innerText = '';
      }
      this.setState({ selectedFiles: [], hasText: false });
    } else {
      this.setState({ selectedFiles: [] });
    }
  };

  avoidSpam = () => {
    this.setState({
      spamming: true,
    });
    setTimeout(() => {
      this.setState({
        spamming: false,
      });
    }, 500);
  };

  scrollDownAfterPasteInput = () => {
    setTimeout(() => {
      const { inputRef } = this;
      if (inputRef.current) {
        const { scrollHeight } = inputRef.current;
        inputRef.current.scroll({ top: scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  onPaste = event => {
    event.preventDefault();
    let text = '';

    if (event.clipboardData && event.clipboardData.getData) {
      text = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      text = window.clipboardData.getData('Text');
    }

    // Normalize the line breaks to ensure consistent rendering, regardless of the source or platform.
    text = text.replace(/\r\n/g, '\n');

    document.execCommand('insertText', false, text);
    this.scrollDownAfterPasteInput();
    event.persist();
  };

  onInput = event => {
    const { hasText } = this.state;
    const text = event.target.innerText;

    if (!_.trim(text)) {
      this.setState({ hasText: false });
    } else {
      if (!hasText) {
        this.setState({ hasText: true });
      }
    }
  };

  handleMultipleFileSelect = async (files, data, callback) => {
    const { isInbox = false } = this.props;
    const { selectedFiles } = this.state;
    const { maxSizeVideo } = this.props;

    if (files.length > 5 || selectedFiles.length + files.length > 5) {
      return toast.error('Maximum of 5 files per upload');
    }

    let error = '',
      newFiles = [];

    const getDimensionImg = async (index, src, upUrl) => {
      new Promise(res => {
        const image = document.createElement('img');
        image.src = src;
        image.onload = event => {
          const { naturalWidth, naturalHeight } = event.target;
          newFiles[index].dimensions = { width: naturalWidth, height: naturalHeight };
          if (upUrl) {
            newFiles[index].uploadUrl = upUrl;
          }
          res('done');
        };
      });
    };

    const getDimensionVideo = async (index, src, upUrl) => {
      new Promise(res => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function (event) {
          newFiles[index].dimensions = { width: video.videoWidth, height: video.videoHeight };
          if (upUrl) {
            newFiles[index].uploadUrl = upUrl;
          }
          res('done');
        };
        video.src = src;
      });
    };

    for (let i = 0; i < files.length; i++) {
      let file = files[i] || {};

      // if (this.props.showRecord) {
      //   file.name = 'new_voice_message.mp3';
      // }

      const { size = 0, name = '', type = '', duration = '' } = file;

      mediaLog({
        status: 1,
        name,
        description: 'Send a file via ChatInput',
        fileSize: size,
        fileType: type,
      });

      if (
        (type && !duration && !FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.includes(name.split('.').pop().toLowerCase())) ||
        _.isEmpty(type)
      ) {
        error = `Please use supported file types (${FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.join(', ')})`;
        break;
      }

      if (
        type &&
        type.includes('video') &&
        size > (maxSizeVideo || FILE_VALIDATIONS.MAX_SIZE_VIDEO) * CONVERSION.MB_TO_BYTE
      ) {
        error = `Please resize and upload a video smaller than ${maxSizeVideo || FILE_VALIDATIONS.MAX_SIZE_VIDEO} MB`;
        break;
      }

      if (type && type.includes('image') && size > FILE_VALIDATIONS.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE) {
        error = `Please upload an image smaller than ${FILE_VALIDATIONS.MAX_SIZE_IMAGE} MB`;
        break;
      }

      let src = null;

      if (type.includes('audio')) {
        src = new MediaSource();
        src.srcObject = file;
        newFiles.push({ file, objectURL: src });
      } else {
        src = window.URL.createObjectURL(file);
        newFiles.push({ file, objectURL: src });
      }

      if (type.includes('audio')) {
        newFiles[i].objectURL = src;
      }

      if (!isInbox) {
        getPresignedUploadUrl('/api/file/gen-presigned-urls-comment', file).then(async res => {
          const { uploadUrl } = res;

          if (type.includes('video')) {
            await getDimensionVideo(i, src, uploadUrl);
          }
          if (type.includes('image')) {
            await getDimensionImg(i, src, uploadUrl);
          }
        });
      } else {
        if (type.includes('video')) {
          getDimensionVideo(i, src);
        }
        if (type.includes('image')) {
          getDimensionImg(i, src);
        }
      }
    }

    if (error) {
      return toast.error(error);
    }

    this.setState({ selectedFiles: selectedFiles.concat([...newFiles]) });
    if (callback) {
      this.props.onSelect &&
        this.props.onSelect(
          selectedFiles.concat([...newFiles]),
          {
            ...data,
            data: selectedFiles.concat([...newFiles]),
          },
          callback,
        );
    } else {
      this.props.onSelect && this.props.onSelect(selectedFiles.concat([...newFiles]));
    }
  };

  handleSingleFileSelect = file => {
    const { size, name, type } = file;

    if (!FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.includes(name.split('.').pop())) {
      return toast.error(`Please use supported file types (${FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.join(', ')})`);
    }

    if (type.includes('video') && size > FILE_VALIDATIONS.MAX_SIZE_VIDEO * CONVERSION.MB_TO_BYTE) {
      return toast.error(`Please resize and upload a video smaller than ${FILE_VALIDATIONS.MAX_SIZE_VIDEO} MB`);
    }

    if (type.includes('image') && size > FILE_VALIDATIONS.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE) {
      return toast.error(`Please upload an image smaller than ${FILE_VALIDATIONS.MAX_SIZE_IMAGE} MB`);
    }

    this.setState({ selectedFiles: [file] });
  };

  onSelectFiles = event => {
    const { files } = event.target;

    if (files.length) {
      this.handleMultipleFileSelect(files);
    }

    event.target.value = '';
  };

  onDeleteSelectedFile = index => {
    const { onRemove } = this.props;
    const newFiles = this.state.selectedFiles.slice();
    newFiles.splice(index, 1);
    onRemove && onRemove(index);
    this.setState({ selectedFiles: newFiles });
  };

  onDropFiles = files => {
    this.handleMultipleFileSelect(files);
  };

  checkPermissions = () => {
    const { disabled, limitedComment, sendingVoiceMessage } = this.props;
    const { hasText, selectedFiles } = this.state;
    const disableRecordVoice =
      disabled || hasText || limitedComment || sendingVoiceMessage || selectedFiles.length || this.cancelling;

    if (disableRecordVoice) return;
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    permissions
      .then(stream => {
        this.setState({
          havePermissions: true,
        });
        stream.getTracks()[0].stop();
        this.handleStartRecording();
      })
      .catch(err => {
        this.setState({
          havePermissions: false,
        });
      });
  };

  handleStartRecording = async () => {
    this.setState({
      progressVoice: 0,
      playedSeconds: 0,
    });
    this.props.onPause && this.props.onPause();
    this.props.onToggleRecord && this.props.onToggleRecord();
    this.props.onStartRecord && this.props.onStartRecord();
    this.recorder = await recordAudio();
    this.recorder && this.recorder.start();
  };

  handleEndRecording = () => {
    this.setState({
      statusRecording: STATUS_RECORDING.PLAY,
      selectedFiles: [],
      progressVoice: 0,
    });
    this.props.onToggleRecord && this.props.onToggleRecord();
    this.props.onStopRecord && this.props.onStopRecord();
    this.recorder && this.recorder.stop();
    this.timeout && clearTimeout(this.timeout);
    this.playingTimeout && clearTimeout(this.playingTimeout);
    this.cancelling = true;
    setTimeout(() => {
      this.cancelling = false;
    }, 500);
  };

  handleStopRecording = (data, callback, submit) => {
    const { progressVoice } = this.state;
    if (progressVoice <= 0) return;

    this.setState({
      statusRecording: STATUS_RECORDING.STOP,
    });

    this.props.onStopRecord && this.props.onStopRecord();
    this.timeout && clearTimeout(this.timeout);
    if (this.recorder) {
      this.recorder.stop();
      submit && submit();
      let intervalRetry = setInterval(async () => {
        let audioBlob = await this.recorder.getBlob();
        if (audioBlob) {
          audioBlob.duration = Math.floor(progressVoice * 1000);
          audioBlob.name = 'new_voice_message.mp3';
          this.handleMultipleFileSelect([audioBlob], data, callback);
          clearInterval(intervalRetry);
        }
      }, 300);
      // setTimeout(async () => {
      //   let audioBlob = await this.recorder.getBlob();
      //   if (!audioBlob) {
      //     audioBlob = await this.recorder.getBlob();
      //   }
      //   if (audioBlob) {
      //     audioBlob.duration = Math.floor(progressVoice * 1000);
      //     this.handleMultipleFileSelect([audioBlob], data, callback);
      //   }
      // }, 300);
    }
  };

  handlePlayRecording = () => {
    this.playing = true;
    setTimeout(() => {
      this.playing = false;
    }, 1000);
    this.setState({
      statusRecording: STATUS_RECORDING.PAUSE,
    });
    if (this.recorder) {
      if (this.state.playedSeconds && this.state.playedSeconds < this.state.progressVoice) {
        this.recorder.resume();
      } else {
        this.setState(
          {
            playedSeconds: 0,
          },
          () => {
            this.recorder.play();
          },
        );
      }
    }
    this.playingTimeout && clearTimeout(this.playingTimeout);
  };

  handlePauseRecording = () => {
    if (this.state.progressVoice - this.state.playedSeconds <= 1) return;

    if (!this.playing) {
      this.setState({
        statusRecording: STATUS_RECORDING.STOP,
      });
      this.playingTimeout && clearTimeout(this.playingTimeout);
      this.recorder && this.recorder.pause();
    }
  };

  renderFileSelect = () => {
    const { disabled, limitedComment, sendingVoiceMessage } = this.props;
    const { hasText } = this.state;
    const disableSelectFile = disabled || hasText || limitedComment || sendingVoiceMessage;

    return (
      <S.FileInputContainer
        className={classNames('select-files-input', { disabled: disableSelectFile })}
        data-tip
        data-for="chat-input-has-text-tooltip"
        data-input="select-files"
      >
        {cameraIcon}
        {hasText ? (
          <ReactTooltip
            className="app-tooltip"
            id="chat-input-has-text-tooltip"
            effect="solid"
            place="top"
            delayShow={500}
          >
            <p>Please send message first before adding attachment</p>
          </ReactTooltip>
        ) : (
          <SharedTooltip content="Attach a file" position="top" id="chat-input-has-text-tooltip" />
        )}
        {limitedComment && (
          <ReactTooltip
            className="app-tooltip"
            id="chat-input-has-text-tooltip"
            effect="solid"
            place="top"
            delayShow={500}
          >
            <p>You have reached a maximum of 100 comments</p>
          </ReactTooltip>
        )}
        <input
          type="file"
          accept={FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO}
          disabled={disableSelectFile}
          multiple={true}
          onChange={this.onSelectFiles}
        />
      </S.FileInputContainer>
    );
  };

  renderSelectedFiles = () => {
    const { selectedFiles } = this.state;

    if (!selectedFiles.length) {
      return null;
    }

    return (
      <S.MediaContainer className="selected-files">
        {_.map(selectedFiles, (item, index) => {
          const { file, objectURL } = item;
          const fileType = getMediaType(file);

          return (
            <S.MediaItem data-index={index} className="item" key={objectURL}>
              {fileType.includes('video') ? (
                <>
                  <video
                    className="media"
                    onLoadedMetadata={event => {
                      if (event.target && event.target.duration) {
                        const { duration, nextElementSibling } = event.target;

                        if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                          const time = getDuration(duration);
                          const timeEl = nextElementSibling.querySelector('.time');

                          if (timeEl) {
                            timeEl.innerText = time;
                          }
                        }
                      }
                      event.persist();
                    }}
                  >
                    <source src={objectURL} type={fileType} />
                    <source src={objectURL} type="video/mp4" />
                  </video>
                  <VideoDuration />
                </>
              ) : (
                <img className="media" src={objectURL} alt="" />
              )}
              <img
                src={`${CDN_URL}/images/close_circle_grey.svg`}
                onClick={() => this.onDeleteSelectedFile(index)}
                className="remove-icon"
                data-btn="close-media"
                alt=""
              />
            </S.MediaItem>
          );
        })}
      </S.MediaContainer>
    );
  };

  render() {
    const {
      placeholder,
      disabled,
      className,
      disableMedia,
      limitedComment,
      defaultValue,
      startRecord,
      user,
      showRecord,
      sendingVoiceMessage,
      isInbox,
      replyMessage,
      onCancelReply,
      scrolling,
      onGoToBottom,
      isReplyInbox = false,
    } = this.props;
    const { selectedFiles, statusRecording, progressVoice, playedSeconds, offline, hasText } = this.state;
    const recordTimes = isInbox && parseInt(localStorage.getItem(`${user._id}`));
    const disableRecordVoice = disabled || hasText || limitedComment || sendingVoiceMessage || selectedFiles.length;
    const disableGif = disabled || limitedComment || sendingVoiceMessage;

    const durationPlayed = getDuration(
      statusRecording === STATUS_RECORDING.PAUSE || (statusRecording === STATUS_RECORDING.STOP && playedSeconds)
        ? playedSeconds < progressVoice
          ? playedSeconds
          : progressVoice
        : progressVoice,
    );
    const isReplyText = _.get(replyMessage, 'type', '') === 'text';
    const isAttachedMedia = !_.isEmpty(selectedFiles) && !_.get(selectedFiles[0], 'file.type', '').includes('audio');
    const isShowNote = isInbox && startRecord && recordTimes < 3;
    const owner = _.get(replyMessage, 'owner', '');
    const ownerElement = isReplyInbox && owner === 'yourself' ? owner : <b>{owner}</b>;

    return (
      <S.Wrapper className={classNames('evf-chat-input', className)}>
        {!_.isEmpty(replyMessage) && (
          <S.ReplyContainer>
            <S.ReplyBy>
              <ReplyIcon />
              <S.ReplyMessageText>Replying to {ownerElement}</S.ReplyMessageText>
            </S.ReplyBy>
            {isReplyText ? (
              <S.ReplyMessageText isBlur>{_.get(replyMessage, 'content', '')}</S.ReplyMessageText>
            ) : (
              <S.ReplyMessageText isBold>{_.get(replyMessage, 'type', '')}</S.ReplyMessageText>
            )}
            <S.CloseReply onClick={onCancelReply}>
              <Close className="close-icon" />
            </S.CloseReply>
          </S.ReplyContainer>
        )}
        {!showRecord ? this.renderSelectedFiles() : null}
        <S.ControlsContainer className="controls">
          {showRecord ? (
            <S.AudioWrapper>
              <CloseIcon
                width={22}
                height={22}
                onClick={this.handleEndRecording}
                data-tip
                data-for="audio-icon-tooltip"
              />

              <S.AudioContainer
                progress={startRecord ? (progressVoice / 60) * 100 : (playedSeconds / progressVoice) * 100}
                recording={startRecord}
                playing={statusRecording === STATUS_RECORDING.PAUSE}
                pause={statusRecording === STATUS_RECORDING.STOP}
                offline={offline}
                className="AudioContainer"
                duration={progressVoice}
              >
                {(() => {
                  switch (statusRecording) {
                    case STATUS_RECORDING.PLAY:
                      if (startRecord) {
                        return (
                          <S.AudioControl stop preventStop={progressVoice <= 1} onClick={this.handleStopRecording}>
                            <StopIcon />
                          </S.AudioControl>
                        );
                      } else {
                        return (
                          <S.AudioControl onClick={this.handlePauseRecording}>
                            <PauseIcon width={8} height={8} className="pause" />
                          </S.AudioControl>
                        );
                      }
                    case STATUS_RECORDING.PAUSE:
                      return (
                        <S.AudioControl onClick={this.handlePauseRecording}>
                          <PauseIcon width={8} height={8} className="pause" />
                        </S.AudioControl>
                      );
                    case STATUS_RECORDING.STOP:
                      return (
                        <S.AudioControl onClick={this.handlePlayRecording}>
                          <PlayIcon width={4.82} height={6.43} className="play" />
                        </S.AudioControl>
                      );
                    default:
                      return null;
                  }
                })()}
                <S.AudioDuration>{durationPlayed}</S.AudioDuration>
              </S.AudioContainer>
            </S.AudioWrapper>
          ) : (
            <>
              {!disableMedia ? this.renderFileSelect() : null}
              {isInbox && (
                <S.VoiceIconContainer>
                  <VoiceIcon
                    className={classNames('icon', { disabled: disableRecordVoice })}
                    onClick={this.checkPermissions}
                    data-tip
                    data-for="chat-voice-has-text-tooltip"
                  />
                </S.VoiceIconContainer>
              )}
              {hasText ? (
                <ReactTooltip
                  className="app-tooltip"
                  id="chat-voice-has-text-tooltip"
                  effect="solid"
                  place="top"
                  delayShow={500}
                >
                  <p>Please send message first before adding voice note</p>
                </ReactTooltip>
              ) : (
                <SharedTooltip content="Send a voice clip" position="top" id="chat-voice-has-text-tooltip" />
              )}
              {limitedComment && (
                <ReactTooltip
                  className="app-tooltip"
                  id="chat-voice-has-text-tooltip"
                  effect="solid"
                  place="top"
                  delayShow={500}
                >
                  <p>You have reached a maximum of 100 comments</p>
                </ReactTooltip>
              )}
              {this.props.giphyEnable && (
                <GiphyPopup trigger={InboxTrigger} disabled={disableGif} onGifClick={this.props.onGifClick} />
              )}
              <S.InputContainer data-input="input-text">
                <S.Input
                  contentEditable
                  onKeyPress={this.onKeyPress}
                  onKeyDown={this.onKeyDown}
                  ref={this.inputRef}
                  placeholder={placeholder || ''}
                  onPaste={this.onPaste}
                  disabled={disabled || selectedFiles.length || limitedComment}
                  onInput={this.onInput}
                  className="input"
                  defaultValue={defaultValue}
                />
              </S.InputContainer>
            </>
          )}

          <S.SubmitButton
            onClick={this.onSubmit}
            className={classNames('post', {
              disabled: limitedComment || (startRecord && progressVoice < 1) || sendingVoiceMessage,
            })}
            data-tip
            data-for="chat-submit-button-tooltip"
            data-btn="post"
          >
            {limitedComment && (
              <ReactTooltip
                className="app-tooltip"
                id="chat-submit-button-tooltip"
                effect="solid"
                place="top"
                delayShow={500}
              >
                <p>You have reached a maximum of 100 comments</p>
              </ReactTooltip>
            )}
          </S.SubmitButton>
        </S.ControlsContainer>
        {isShowNote ? <S.VoiceMessageText>Note: Voice messages are deleted after 30 days</S.VoiceMessageText> : null}
        {scrolling && (
          <S.FloatingButton
            isShowNote={isShowNote}
            hasReply={!_.isEmpty(replyMessage)}
            isAttachedMedia={isAttachedMedia}
            onClick={() => {
              onGoToBottom && onGoToBottom();
            }}
          >
            <ArrowDown />
          </S.FloatingButton>
        )}
      </S.Wrapper>
    );
  }
}

ChatInput.propTypes = {
  onPost: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disableMedia: PropTypes.bool,
};

export default ChatInput;
