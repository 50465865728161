import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  line-height: 150%;
  font-style: normal;
  color: #202353;
  text-align: left;
`;

export const Container = styled.div`
  .__react_component_tooltip.app-tooltip.item-tooltip {
    width: 228px;
    border-radius: 5px;
    padding: 15px;
    text-align: left;
    background-color: #151619;
    &.place-top {
      margin-top: -8px;
      &:after {
        bottom: -7px;
      }
    }
    p {
      ${textBase}
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #fff;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 24px;

  width: 100%;
  min-width: 574px;
  height: 86px;
  padding: 16px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid #5158cf;
  }

  ${props =>
    props.isChecked &&
    css`
      border: 1px solid #5158cf;
      background-color: #f8f8fe;
    `}

  ${props =>
    props.isDisabled &&
    css`
      background-color: #fff;
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        border: 1px solid transparent;
      }
    `}
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  .custom-avatar {
    width: 54px;
    height: 54px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Name = styled.div`
  ${textBase}
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  user-select: none;
`;

export const InfoPart = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const InforTitle = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  user-select: none;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Checkbox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border: 1.6px solid #d7d7d7;
  border-radius: 6px;

  &:hover {
    border: 1.6px solid #5158cf;
  }

  ${props =>
    props.isChecked &&
    css`
      background-color: #5258cf;
      border: none;
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        display: block
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(${CDN_URL}/images/checked_white.svg);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
      }
    `}

  ${props =>
    props.isAssigned &&
    css`
      &:hover {
        border: 1.6px solid #d7d7d7;
      }
    `}
`;

export const BannerWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100px;
  height: 54px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .market-place-icon {
    position: absolute;
    left: 4px;
    bottom: 4px;
  }

  .__react_component_tooltip.app-tooltip.market-place-tooltip {
    padding: 15px;
    max-width: 192px;
    background-color: #151619;
    text-align: left;
    margin-top: -7px;
    p {
      color: #fff;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &.market-place-tooltip.place-top:after {
      bottom: -7px;
      border-top-color: #151619;
    }
  }
`;
