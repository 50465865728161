import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Heading = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #5158cf;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const ExpandIcon = styled.div`
  background-image: url(${CDN_URL}/images/arrow-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

export const SectionWrapper = styled.div``;

export const SectionTitle = styled.div`
  display: flex;
  margin-bottom: 2px;
  padding-bottom: 3px;
  padding-top: 15px;
  border-top: 1px solid #eef2fa;
  justify-content: space-between;
  align-items: center;

  .title {
    max-width: 172px;
    font-weight: bold;
    font-size: 13px;
    line-height: 120%;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1;
  }

  .time,
  .section-type,
  .section-duration {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    color: #8a8cad;
    text-transform: uppercase;
    margin-left: 10px;
    white-space: nowrap;

    img {
      margin-right: 4px;
    }
  }

  &.none-spacing {
    padding-bottom: 0;
    padding-top: 0;
    min-height: 46.59px;
  }
`;

export const ExerciseTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #202353;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ExerciseSummary = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #5f5f5f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 2px;
  min-height: 10px;
`;

export const NumberOfSets = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  color: #202353;
  margin-left: 10px;
`;

export const ExerciseBaseInfo = styled.div`
  overflow: hidden;
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Exercise = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
`;

export const Exercises = styled.div`
  position: relative;

  .exercise {
    :first-child {
      padding-top: 0;
    }

    :last-child {
      padding-bottom: 0;
    }
  }
`;

export const Supersets = styled.div`
  padding: 10px 0;
  /* border-bottom: 1px solid #EEF2FA; */

  :last-child {
    border: none;
  }

  .superset-label {
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    color: #7b7e91;
    margin-bottom: 5px;
  }

  &.is-supersets {
    padding-top: 0px;

    ${Exercises} {
      ::before {
        content: '';
        width: 6px;
        top: 10px;
        bottom: 24px;
        left: -10px;
        border: 1px dashed #c0c2cb;
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        position: absolute;
      }
    }
  }
`;

export const SectionExercises = styled.div`
  padding-left: 20px;

  &.section--exercises--hidden {
    padding-left: 0px;
    padding-top: 10px;
    border-top: 1px solid #eef2fa;
  }

  &.none-boder {
    border-top: none;
  }
`;

export const SectionContainer = styled.div`
  position: relative;
  /* padding-top: 20px; */
  padding-left: 10px;

  .dot {
    width: 9px;
    height: 9px;
    position: absolute;
    left: -14px;
    background-color: #d8dced;
    border: 1px solid #fafafa;
    border-radius: 50%;
    top: 18px;
    z-index: 2;
    content: '';
  }

  &.section--hidden {
    padding-top: 0;

    .dot {
      /* top: 28px; */
    }
  }

  &.section--supersets {
    .dot {
      /* top: 37px; */
    }
  }

  :first-child {
    ${SectionTitle} {
      border-top: none;
    }
  }
`;

export const Content = styled.div`
  padding-left: 14px;
  position: relative;
  opacity: ${props => (props.loading ? 0 : 1)};

  ::before {
    content: '';
    position: absolute;
    left: 4px;
    border-right: 1px dashed #d8dced;
    top: 0;
    bottom: 0;
  }

  ${SectionContainer} {
    :last-child {
      ::after {
        content: '';
        position: absolute;
        left: -14px;
        top: 28px;
        bottom: 0;
        width: 9px;
        z-index: 3;
        background-color: #ffffff;
      }

      &.section--hidden {
        ::after {
          top: 36px;
        }

        :first-child {
          ::after {
            top: 35px;
          }
        }

        .dot {
          top: 25px;
        }
      }

      &.section--supersets {
        ::after {
          top: 26px;
        }
        .dot {
          top: 18px;
        }
      }

      .dot {
        top: 19px;
      }
    }

    :first-child {
      ::before {
        content: '';
        position: absolute;
        left: -14px;
        top: 0;
        width: 9px;
        height: 18px;
        z-index: 3;
        background-color: #ffffff;
      }

      &.section--hidden {
        ::after {
          top: 35px;
        }

        ::before {
          height: 23px;
        }

        .dot {
          top: 24px;
        }
      }

      &.section--supersets {
        ::before {
          height: 19px;
        }
      }

      .section--exercises--hidden {
        border-top-color: transparent;
      }
    }
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #edeef1;
  padding: 15px 0px 5px 0px;
  overflow: hidden;

  &:first-child {
    padding-top: 5px;
  }

  .workout-overview__content {
    max-height: 9999px;
    transition: max-height 0.3s ease;
  }

  .workout-overview__expand-icon {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
  }

  &.workout-overview__hide {
    .workout-overview__expand-icon {
      transform: rotate(-90deg);
    }

    .workout-overview__content {
      max-height: 0px;
    }
  }
`;
