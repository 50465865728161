import React from 'react';
import { connect } from 'react-redux';
import Pagination from 'shared/Pagination';
import { changeQueryParams } from 'redux/package-analytics/actions';

const Footer = ({ page, pageSize, changeQueryParams, total, isMP }) => {
  const handleClickPrev = newPage => {
    changeQueryParams({ page: newPage }, undefined, isMP);
  };

  const handleClickNext = newPage => {
    changeQueryParams({ page: newPage }, undefined, isMP);
  };

  return (
    <Pagination page={page} perPage={pageSize} totalPage={total} onPrev={handleClickPrev} onNext={handleClickNext} />
  );
};

const mapStateToProps = state => {
  const { packageAnalytics } = state.rootReducer;

  return {
    page: packageAnalytics.getIn(['query', 'page']),
    pageSize: packageAnalytics.getIn(['query', 'pageSize']),
    total: packageAnalytics.get('total'),
  };
};

const actions = { changeQueryParams };

export default connect(mapStateToProps, actions)(Footer);
