// Libs
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';

// Components
import MacroNutrient from 'components/RecipeDetail/MacroNutrient';
import UnitInput from 'components/RecipeDetail/UnitInput';

// constants
import { NUTRITION_TAB } from 'components/RecipeDetail/constants';

// Assets
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_bold.svg';

// Style
import * as S from './style';

const tabs = [
  {
    key: NUTRITION_TAB.MACRO_NUTRIENTS,
    label: 'Macronutrients',
  },
  {
    key: NUTRITION_TAB.OTHER_NUTRIENTS,
    label: 'Other Nutrients',
  },
];

const NutritionInfo = ({
  OtherNutrients = [],
  listOtherNutrients = [],
  isViewMode = false,
  onChangeNutrients = () => {},
  MacroNutrients = [],
  addFromMultiple = false,
  dataAmountPer = {},
  handleChangeAmountPer = () => {},
}) => {
  const [showNutritionInfo, setShowNutritionInfo] = useState(false);
  const [tab, setTab] = useState(tabs[0]);
  const [hideOtherNutrient, setHideOtherNutrient] = useState(true);

  const isMacro = tab.key === NUTRITION_TAB.MACRO_NUTRIENTS;

  useEffect(() => {
    if (addFromMultiple) setShowNutritionInfo(true);
  }, [addFromMultiple]);

  const handleToggleNutritionInfo = () => {
    setShowNutritionInfo(!showNutritionInfo);
  };

  const handleChangeNutrients = (e, type) => {
    onChangeNutrients(e, type, tab);
  };

  const renderNutritionInfo = () => {
    return (
      <S.NutritionInfo>
        <div className="tab-header">
          <div className="tab-header-left">
            {tabs.map(item => {
              const { key, label } = item;
              return (
                <div
                  className={classNames('tab-header-item', { active: key === tab.key })}
                  onClick={() => handleChangeTab(item)}
                  key={key}
                >
                  {label}
                </div>
              );
            })}
          </div>
          <div className={classNames('tab-header-right', { 'is-view-mode': isViewMode })}>
            <span className={classNames('text-amount-per', { 'is-view-mode': isViewMode })}>
              Amount per{isViewMode ? ':' : ''}
            </span>
            {isViewMode ? (
              <>
                {!isEmpty(get(dataAmountPer, 'servingSize')) ? (
                  <span className="value-amount-per">{`${get(dataAmountPer, 'servingSize.value')} ${get(
                    dataAmountPer,
                    'servingSize.unit_display_name',
                  )}`}</span>
                ) : (
                  ''
                )}
              </>
            ) : (
              <S.ListAmountPerWrapper
                disabled={isEmpty(get(dataAmountPer, 'value', []))}
                data-tip
                data-for="tooltip-amount-per-disabled"
              >
                {isEmpty(get(dataAmountPer, 'value', [])) && (
                  <ReactTooltip id="tooltip-amount-per-disabled" effect="solid" place="top">
                    Please choose ingredient category first
                  </ReactTooltip>
                )}
                <S.ListAmountPer
                  label=""
                  options={get(dataAmountPer, 'list', [])}
                  values={get(dataAmountPer, 'value', [])}
                  isMulti
                  showInputSearch={false}
                  listAmountPer={true}
                  onChangeCategory={handleChangeAmountPer}
                />
              </S.ListAmountPerWrapper>
            )}
          </div>
        </div>
        <div className="tab-content">
          {isMacro ? (
            <div className="macro-nutrient">
              <MacroNutrient
                MacroNutrients={MacroNutrients}
                onChange={handleChangeNutrients}
                showMacroSection={false}
                disabledInput={isViewMode}
              />
            </div>
          ) : (
            renderOtherNutrient()
          )}
        </div>
      </S.NutritionInfo>
    );
  };

  const renderOtherNutrient = () => {
    const renderNutrientList = nutrients => {
      return nutrients.map(item => {
        const { name, key, unit } = item;
        const { value } = OtherNutrients.find(nutrient => nutrient.type === key) || {};
        return (
          <div className="item-other-nutrient" key={key}>
            <UnitInput
              label={name}
              unit={unit}
              defaultValue={value}
              onChange={e => handleChangeNutrients(e, key)}
              disabled={isViewMode}
            />
          </div>
        );
      });
    };
    const baseNutrient = listOtherNutrients.filter(item => item.type === 'other' && item.is_pin_on_top);
    const otherNutrient = listOtherNutrients.filter(item => item.type === 'other' && !item.is_pin_on_top);
    return (
      <div className="other-nutrient">
        <div className="nutrient-list">{renderNutrientList(baseNutrient)}</div>
        <div
          className={classNames('toggle-other-nutrient', { 'is-hide': hideOtherNutrient })}
          onClick={handleToggleOtherNutrient}
        >
          More Nutrients <ArrowDownIcon className="toggle-icon" />
        </div>
        <div className="nutrient-list">{!hideOtherNutrient && renderNutrientList(otherNutrient)}</div>
      </div>
    );
  };

  const handleToggleOtherNutrient = () => {
    setHideOtherNutrient(!hideOtherNutrient);
  };

  const handleChangeTab = data => {
    setTab(data);
  };

  return (
    <S.Wrapper>
      {!addFromMultiple && (
        <div
          className={classNames('toggle-nutrition-info', { 'is-show': showNutritionInfo })}
          onClick={handleToggleNutritionInfo}
        >
          {!showNutritionInfo ? 'View' : ''} Nutrition Info <ArrowDownIcon className="toggle-nutrition-info-icon" />
        </div>
      )}
      {showNutritionInfo && renderNutritionInfo()}
    </S.Wrapper>
  );
};

export default NutritionInfo;
