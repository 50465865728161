import packageJson from '../../package.json';

const INITIAL_STATE = {
  version: packageJson.version,
};

const UPDATE_NEW_WEB_VERSION = 'UPDATE_NEW_WEB_VERSION';

export const updateNewWebVersionSuccess = version => {
  return dispatch => {
    return dispatch({ type: UPDATE_NEW_WEB_VERSION, version });
  };
};

const home = (state = INITIAL_STATE, action) => {
  const { type, version } = action;

  switch (type) {
    case UPDATE_NEW_WEB_VERSION:
      return Object.assign({}, state, { version });
    default:
      return state;
  }
};

export default home;
