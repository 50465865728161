import get from 'lodash/get';
import map from 'lodash/map';
import round from 'lodash/round';
import forEach from 'lodash/forEach';
import lowerCase from 'lodash/lowerCase';
import React, { useState } from 'react';
import moment from 'moment';
import IconButton from 'shared/IconButton';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { ReactComponent as RetryIcon } from 'assets/icons/retry_light.svg';
import { ReactComponent as RefundIcon } from 'assets/icons/refund-icon.svg';

import RetryChargePopup from './../../List/InvoiceTable/RetryChargePopup';

import {
  CURRENCIES,
  INVOICE_STATUS,
  INVOICE_REFUND_STATUS,
  REFUND_REASONS,
  TEXT_CURRENCIES,
  CANCEL_REASON,
} from 'constants/commonData';
import { formatMoney, formatSameYear } from 'utils/commonFunction';

import * as S from './styles';

export default function InvoiceTable(props) {
  const {
    invoices,
    account,
    purchases,
    currentPackage,
    selectedPackage,
    retriedCallback,
    onToggleRefundModal,
    is_allow_refund,
    changeQueryParams,
    countPackages,
    isMP,
  } = props;
  const [retryInvoice, setRetryInvoice] = useState();
  const currency = get(account, 'currency', 'usd');
  const symbol = TEXT_CURRENCIES.includes(currency)
    ? `${get(CURRENCIES, `${currency}.symbol`)} `
    : get(CURRENCIES, `${currency}.symbol`);
  const path = isMP ? 'marketplace' : 'packages';

  const getPaymentType = item => {
    if (get(item, 'price.type') === 'recurring') {
      const started = formatSameYear(moment.unix(get(item, 'period_start')));
      const end = formatSameYear(moment.unix(get(item, 'period_end')));
      return `${started} - ${end}`;
    }
    return '-';
  };

  // Checking selected package and current view package
  const isCurrentPackage = item => {
    return get(currentPackage, 'id') === parseInt(get(item, 'package.id'));
  };
  const handleOpenLink = item => () => {
    if (!isCurrentPackage(item)) {
      let packageId = '';
      if (selectedPackage.id) {
        // Case selected a package on dropdown
        packageId = get(selectedPackage, '_package.hash_id') || get(selectedPackage, '_package.id');
      } else {
        // Case All packages
        const packageOfItem = purchases.find(ele => ele.id === parseInt(item.order_id));
        packageId = get(packageOfItem, '_package.hash_id') || get(packageOfItem, '_package.id');
      }
      window.location = window.location.origin + `/home/${path}/${packageId}/analytics`;
    }
  };

  const handleCloseRetry = () => {
    refreshInvoices();
    setRetryInvoice(null);
  };

  const refreshInvoices = () => {
    setTimeout(() => {
      changeQueryParams(
        {
          sort: 'payment_due',
        },
        undefined,
        isMP,
      );
      countPackages({ id: get(retryInvoice, 'package.id') }, isMP);
    }, 2000);
  };

  const handleRetry = item => event => {
    event.stopPropagation();
    setRetryInvoice(item);
  };

  const renderTrigger = ({ open }) => <MenuTrigger vertical active={!!open} />;

  const handleToggleRefundModal = invoice => () => {
    onToggleRefundModal(invoice);
  };

  const renderDropdown = (invoice, status) => {
    const isRender = is_allow_refund && status === INVOICE_STATUS.PAID;

    return (
      <td className="dropdown">
        {isRender && (
          <DropDown triggerIcon={renderTrigger} direction="left">
            <Option key="active" data-item={invoice.id} onClick={handleToggleRefundModal(invoice)}>
              <div className="items">
                <RefundIcon />
                <span>Refund</span>
              </div>
            </Option>
          </DropDown>
        )}
      </td>
    );
  };

  const renderStatus = invoice => {
    let status = invoice.status;
    if (invoice.status === INVOICE_REFUND_STATUS.PENDING) status = 'processing';
    if (invoice.status === INVOICE_REFUND_STATUS.SUCCESSED) status = 'refunded';

    let statusElement = <S.StatusContent status={lowerCase(invoice.status)}>{status}</S.StatusContent>;

    const renderRefundCost = value => {
      return formatMoney(round(value, 2), symbol);
    };

    if (invoice.status === INVOICE_REFUND_STATUS.SUCCESSED) {
      const handleGetReason = () => {
        let value = '';

        forEach(REFUND_REASONS, item => {
          if (item.key === get(invoice, 'refund.reason', '')) value = get(item, 'label', '');
        });

        return value;
      };

      const refundMount = round(get(invoice, 'refund.amount', 0) / 100, 2);
      const content = (
        <S.TooltipContent>
          <S.RefundCost>Refund: {renderRefundCost(refundMount)}</S.RefundCost>
          <S.RefundReason>
            <span className="highlight">Reason:</span> {handleGetReason()}
          </S.RefundReason>
          <S.RefundDetails>
            <span className="highlight">Details:</span> {get(invoice, 'refund.note', '')}
          </S.RefundDetails>
        </S.TooltipContent>
      );

      statusElement = (
        <S.Tooltip
          content={content}
          inverted
          position="center top"
          trigger={<S.StatusContent status={lowerCase(invoice.status)}>{status}</S.StatusContent>}
        />
      );
    }

    return statusElement;
  };

  const renderAmount = invoice => {
    const total = get(invoice, 'total', 0);
    const total_refund = get(invoice, 'total_refund', 0);
    let amountElement = <td className="amount">{formatMoney(round(total / 100, 2), symbol)}</td>;

    if (invoice.status === INVOICE_REFUND_STATUS.PENDING || invoice.status === INVOICE_REFUND_STATUS.SUCCESSED) {
      amountElement = (
        <td className="amount">
          <span className="cost">{formatMoney(round(total_refund / 100, 2), symbol)}</span>

          <span className="cost-by-refunded">{formatMoney(round((total - total_refund) / 100, 2), symbol)}</span>
        </td>
      );
    }

    return amountElement;
  };

  const renderList = item => {
    const list =
      process.env.REACT_APP_ENABLE_PAYMENT_REFUND && get(item, 'refund', null)
        ? [
            {
              ...item,
              status: get(item, 'refund.status', ''),
              amount: get(item, 'refund.amount', 0),
            },
          ]
        : [item];

    return (
      <tbody>
        {list.map(
          invoice =>
            invoice && (
              <tr key={invoice.id} onClick={handleOpenLink(invoice)} className={!isCurrentPackage(invoice) && 'cursor'}>
                <td className="limit">{get(invoice, 'package.name')}</td>
                <td>{invoice.invoice_number || '-'}</td>
                <td>{formatSameYear(invoice.payment_due)}</td>
                <td>{getPaymentType(invoice)}</td>
                <td>
                  <S.StatusWrapper>
                    {renderStatus(invoice)}
                    {(invoice.status === INVOICE_STATUS.OVERDUE ||
                      (process.env.REACT_APP_ENABLE_RECHARGE_TRIAL_INVOICE &&
                        invoice.status === INVOICE_STATUS.FAILED &&
                        get(selectedPackage, 'cancel_reason') === CANCEL_REASON.TRIAL_ENDED)) && (
                      <IconButton
                        icon={<RetryIcon />}
                        tooltip="Recharge Invoice"
                        tooltipKey={`retry-client-tooltip-${invoice.id}`}
                        onClick={handleRetry(invoice)}
                      />
                    )}
                  </S.StatusWrapper>
                </td>
                {renderAmount(invoice)}
                {process.env.REACT_APP_ENABLE_PAYMENT_REFUND && renderDropdown(invoice, invoice.status)}
              </tr>
            ),
        )}
      </tbody>
    );
  };

  return (
    <S.InvoiceTableWrapper>
      <table>
        <thead>
          <tr>
            <th>
              <div>Package</div>
            </th>
            <th>
              <div>Invoice #</div>
            </th>
            <th>
              <div>Payment Due</div>
            </th>
            <th>
              <div>Billing Cycle</div>
            </th>
            <th>
              <div>Status</div>
            </th>
            <th className="amount">
              <div>Amount</div>
            </th>
            {process.env.REACT_APP_ENABLE_PAYMENT_REFUND && (
              <th className="minHeight">
                <div></div>
              </th>
            )}
          </tr>
        </thead>
        {map(invoices, item => renderList(item))}
      </table>
      {retryInvoice && (
        <RetryChargePopup
          isMP={isMP}
          onClose={handleCloseRetry}
          invoice={retryInvoice}
          completedCallback={retriedCallback}
        />
      )}
    </S.InvoiceTableWrapper>
  );
}
