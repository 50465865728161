// Lib
import React, { useEffect } from 'react';

// Shared
import RecipesHeader from './Parts/Header';
import RecipesContentList from './Parts/ContentList';

// Constants
import { saveLastLibraryRoute } from 'utils/commonFunction';

import * as S from './style';

const Recipes = props => {
  const { resetFilters, toggleSideBar, toggleModal } = props;

  useEffect(() => {
    return () => {
      resetFilters && resetFilters();
      toggleModal(false);
    };
  }, []);

  useEffect(() => {
    toggleSideBar && toggleSideBar(true);
    saveLastLibraryRoute('/home/recipes');
  }, []);

  return (
    <S.RecipesWrapper>
      <RecipesHeader {...props} />
      <RecipesContentList {...props} />
    </S.RecipesWrapper>
  );
};

export default Recipes;
