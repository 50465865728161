import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { ENTITY_NAME } from 'database/constants';
import { setDragItem, handleDragExercise } from 'redux/workout-builder/actions';

import SupersetItem from '../SupersetItem';
import DropContainer from './DropContainer';

import * as S from './style';

class ExerciseItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.isMouseDown = false;
  }

  onMouseDown = e => {
    e.stopPropagation();
    this.isMouseDown = true;
    this.timeoutSetDrag = setTimeout(() => this.doSetDrag(), 300);
  };

  doSetDrag = () => {
    if (this.isMouseDown) {
      this.props.setDragItem({
        dragType: 'exercise',
        exerciseId: this.props.exerciseId,
        sectionId: this.props.sectionId,
        index: this.props.index,
      });
    }
  };

  onMouseUp = e => {
    e.preventDefault();
    this.isMouseDown = false;
    clearTimeout(this.timeoutSetDrag);
  };

  render() {
    const exercise = this.props.exercise.toJS();
    const { supersets } = exercise;
    const draggingItem = this.props.draggingItem ? this.props.draggingItem.toJS() : null;
    const isDragging = draggingItem && draggingItem.dragType === 'exercise' && exercise._id === draggingItem.exerciseId;
    const isDragMode = !!draggingItem;

    return (
      <S.Wrapper
        className="arrangement__exercise"
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        isDragMode={isDragMode}
        isDragging={isDragging}
      >
        {supersets.map(superset => (
          <SupersetItem key={superset} supersetId={superset} />
        ))}
        <S.ExerciseDraggingIcon />
        {!!draggingItem && draggingItem.dragType === 'exercise' && !this.props.disableDrop && (
          <>
            <DropContainer {...this.props} />
            <DropContainer {...this.props} index={this.props.index + 1} bottom />
          </>
        )}
      </S.Wrapper>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    exercise: state.rootReducer.model.getIn([ENTITY_NAME.Exercise, ownProps.exerciseId]),
    draggingItem: state.rootReducer.workoutBuilder.get('draggingItem'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDragItem: bindActionCreators(setDragItem, dispatch),
    handleDragExercise: bindActionCreators(handleDragExercise, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExerciseItem);
