import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const SupportIcon = styled.div`
  cursor: pointer;

  img {
    display: block;
    width: auto;
    max-width: 100%;

    &.hover {
      display: none;
    }
  }

  &:hover {
    img {
      display: none;

      &.hover {
        display: block;
      }
    }
  }
`;

export const ListContainer = styled.div`
  display: none;
  padding: 12px 0 10px 0;
  min-width: 246px;
  border: 1px solid #dadaea;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(37, 40, 48, 0.1);
  border-radius: 5px;
  background: #fff;
  position: absolute;
  right: -30px;
  top: calc(100% + 12px);
  z-index: 999 !important;

  @media screen and (height <= 830px) {
    max-height: 650px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media screen and (height <= 755px) {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media screen and (height <= 655px) {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  :before {
    content: '';
    position: absolute;
    top: -4px;
    right: 37px;
    border: solid 1px #dadaea;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #fff;
  }

  .item {
    padding: 7.5px 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: Open Sans;

    svg {
      color: #979797;
    }

    :hover {
      background-color: #f0f0ff;
      color: #5158cf;

      svg {
        color: #5158cf;
      }
    }

    .icon {
      min-width: 24px;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .list-item {
    box-shadow: inset 0px -1px 0px #dadaea;
    padding-bottom: 12px;
    background-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #fff;

    :hover {
      background-color: #fff;
    }
  }

  .guide-section {
    padding: 7px 20px 2px;
    text-align: center;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #333333;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #333333;
    }

    .video-section {
      cursor: pointer;
      position: relative;
      width: 139px;
      height: 78px;
      border-radius: 4px;

      &:before {
        content: '';
        position: absolute;
        left: 0px;
        bottom: 0;
        right: 0;
        top: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        background: url(${CDN_URL}/images/video_play_fill.svg) left bottom / contain no-repeat;
        z-index: 1;
      }

      * {
        border-radius: 4px;
      }

      .media-player__trigger {
        background: transparent;
        width: 100%;
        height: 100%;
      }
    }

    .demo-section {
      margin: 13px 0px;

      .video-section {
        margin: 10px auto 7px;
      }
    }

    .client-guide-section {
      margin: 13px 0 7px;

      .description {
        margin: 5px 0 14px 0;
      }
      .video-section {
        margin: 10px auto 11px;
      }
    }
  }

  .divide {
    width: 100%;
    height: 1px;
    background-color: #dadaea;
    margin: 20px 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  &.open {
    ${ListContainer} {
      display: block;
    }

    ${SupportIcon} {
      img {
        display: none;

        &.hover {
          display: block;
        }
      }
    }
  }
`;

export const OverlapText = styled.span`
  color: #5158cf;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 36px;
  padding: 7px 7px 7px 11px;
`;

export const Button = styled.button`
  width: 100%;
  outline: none !important;
  box-shadow: none;
  border: 1px solid #5c5bbd;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  color: #91939a;
  padding: 7px 7px 7px 11px;
  background-color: #fafbfc;
  border: 1px solid #dcdcde;
  font-size: 12px;
  color: #91939a;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  position: relative;
  svg {
    margin-left: 25px;
  }

  :hover {
    color: transparent;
    background-color: #fff;
    border-color: #5158cf;
    cursor: pointer;
    ${OverlapText} {
      display: inline-block;
    }
    svg {
      display: none;
    }
  }
  ${props =>
    props.disabled &&
    css`
      color: transparent;
      background-color: #fff;
      border-color: #5158cf;
      svg {
        display: none;
      }
      ${OverlapText} {
        display: inline-block;
      }
    `}
`;
