// Libs
import React from 'react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

// Utils
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

// Shared
import { Checkbox } from 'shared/FormControl';

// Constants
import { ASSIGNED_STATUS } from 'components/MealPlanDetail/constants';

// Styles
import * as S from './style';

const Item = ({ data, selectedClient, cloudfrontList, tab, handleSelectClient }) => {
  const { client, day, end_day, week_count, _id } = data;
  const { full_name, color, avatar, _id: idClient = '', is_archived = false } = client || {};

  const checked = selectedClient.includes(_id);

  const avt = convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true);

  const diffWeekIn = item => {
    const { client, day, mark_completed_at, end_day } = item || {};
    const { timezone } = client || {};

    const completedAt = mark_completed_at
      ? moment.tz(mark_completed_at, timezone)
      : moment.tz(end_day, timezone).add({ day: 1, second: -1 });

    const currentMoment = completedAt.isBefore(new Date()) ? completedAt : moment.tz(new Date(), timezone);

    const itemMoment = moment.tz(day, timezone);

    const weeksDifference = Math.max(currentMoment.diff(itemMoment, 'weeks', true), 0);

    return Math.ceil(weeksDifference);
  };

  const formatDate = date => {
    const currentDate = moment();
    const formattedDate = moment(date).format('MMM DD');
    const isInCurrentYear = moment(date).isSame(currentDate, 'year');
    return isInCurrentYear ? formattedDate : moment(date).format('MMM DD, YYYY');
  };

  const filedByItem = [
    {
      key: 'clients',
      label: (
        <S.Info>
          <div className="client-avatar-container">
            <Checkbox
              checked={checked}
              className={`select-client-checkbox ${checked ? 'disabled-hover-checkbox' : ''}`}
              onChange={e => handleSelectClient(e, _id)}
            />
            {!checked && (
              <div className="client-avatar-wrapper">
                <Avatar
                  name={getUserShortName(client)}
                  className="clientAvatar__avatar"
                  size="35"
                  src={avt}
                  color={color}
                />
              </div>
            )}
          </div>
          <Link className="app-link" to={`/home/client/${idClient}/meal-plan`}>
            <span className="client-name">{full_name}</span>
          </Link>
        </S.Info>
      ),
    },
    {
      key: 'progress',
      label:
        tab.key !== ASSIGNED_STATUS.UPCOMING ? (
          <>
            Week <span className="current-week">{diffWeekIn(data)}</span> of {week_count}
          </>
        ) : (
          '--'
        ),
    },
    {
      key: 'start_date',
      label: formatDate(day),
    },
    {
      key: 'end_date',
      label: formatDate(end_day),
    },
  ];

  return (
    <>
      <S.TableRow isDisabled={is_archived} data-for={`assigned-meal-plan-${idClient}`} data-tip>
        {filedByItem.map(item => {
          const { key, label } = item;

          return (
            <S.TableCell key={key} styleHeader={key}>
              {label}
            </S.TableCell>
          );
        })}
      </S.TableRow>
      {idClient && is_archived ? (
        <ReactTooltip id={`assigned-meal-plan-${idClient}`} className="app-tooltip assigned-meal-plan">
          <div>Archived Client</div>
        </ReactTooltip>
      ) : null}
    </>
  );
};

export default Item;
