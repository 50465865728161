import styled, { css } from 'styled-components';

export default styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #728096;

  :before {
    content: '';
    width: ${props => props.width || '20px'};
    height: ${props => props.height || '20px'};
    margin-right: 6px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }

  ${(props) =>
      props.icon &&
      css`
        :before {
          background-image: url(${props.icon});
        }
      `}
    :hover {
    color: #726ee4;
    ${(props) =>
      props.iconHover &&
      css`
        :before {
          background-image: url(${props.iconHover});
        }
      `}
  }
`;
