import styled, { css } from 'styled-components';

export const PlaceholderLoader = styled.span`
  width: 32px;
  height: 32px;
  border: 3px solid rgba(255, 255, 255, 0.4);
  border-bottom-color: rgba(255, 255, 255, 1);
  border-left-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  ${props =>
    props.bgBlack &&
    css`
      border: 3px solid rgba(0, 0, 0, 0.4);
      border-bottom-color: rgba(0, 0, 0, 1);
      border-left-color: rgba(0, 0, 0, 1);
    `}
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
