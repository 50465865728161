import styled, { css } from 'styled-components';
import { FormGroup as FG, ErrorMessage } from 'shared/FormControl';

export const ServerErrorMessage = styled.div`
  background: #fff5f5;
  border-radius: 5px;
  padding: 14px 30px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #ff5959;
  margin-bottom: 30px;

  ${props =>
    props.isGoogleAuth &&
    css`
      color: #d94f3d;
      margin-bottom: 0 !important;
      margin-top: 8px !important;
    `}
`;

export const FormHeader = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #191753;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;
  }
`;

export const FormFooter = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;

  .highlight {
    color: #5158cf;
    font-weight: 600;
  }
`;

export const Controls = styled.div`
  width: 100%;
  box-shadow: inset 0px -1px 0px #e3e6ef;
  position: relative;
`;

export const FormGroup = styled(FG)`
  margin-bottom: 20px;

  ${ErrorMessage} {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #ff5959;
    margin-top: 8px;
  }

  ${Controls} {
    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #5158cf;
      text-transform: none;
      transition: opacity 0.3s ease-in-out;
      margin-bottom: 0;

      &.hide {
        opacity: 0;
      }
    }

    input {
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #191753;
      background-color: transparent;
      height: auto;
      padding: 5px 0;

      ::placeholder {
        color: #aab0ba;
      }
    }
  }

  &.error {
    ${Controls} {
      background: #fff5f5;
      box-shadow: inset 0px -1px 0px #ff5959;

      label {
        color: #ff5959;
      }
    }
  }
`;

export const SubmitButton = styled.button.attrs(props => ({ type: props.type || 'submit' }))`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #5158cf;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;

  img {
    margin-left: 10px;
    width: 15px;
  }

  :disabled {
    cursor: not-allowed;
    background-color: #bfc6d0;
  }
`;

export const FormContainer = styled.form`
  width: 444px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 8px;
  padding: 36px 45px 45px;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #191753;

  a {
    color: #5158cf;
    font-weight: 600;

    :hover {
      color: #5158cf;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;
