import { fromJS } from 'immutable';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  forumDetail: null,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTOFLOW_FORUM_CONNECT_SUCCESS: {
      return state.set('forumDetail', fromJS(payload));
    }

    default:
      return state;
  }
};
