import React from 'react';
import { Toggle } from 'shared/FormControl';

import * as S from '../SurveyPurchaseOption/styles';

const InboxOption = ({ value, disabled, onChange }) => {
  return (
    <S.SwitchPurchaseOptionWrapper>
      {disabled ? (
        <S.PurchasePreviewValue>{`Messaging ${
          value ? 'available' : 'unavailable'
        } with this package`}</S.PurchasePreviewValue>
      ) : (
        <Toggle
          disabled={disabled}
          checked={value}
          onChange={onChange}
          width={28}
          height={16}
          label={<span>Enable messaging for this package?</span>}
        />
      )}
    </S.SwitchPurchaseOptionWrapper>
  );
};

export default InboxOption;
