export const REJECTED_REASON = {
  NOT_ENOUGH_BUDGET: 'not_enough_budget',
  SCHEDULE_CONFLICT: 'schedule_conflict',
  BUSY: 'busy',
  EQUIPMENT_ISSUE: 'equipment_issue',
  HEALTH_CONSIDERATION: 'health_consideration',
  NOT_MATCH_EXPECTATION: 'not_match_expectation',
  CHANGE_GOAL: 'change_goal',
  CHANGE_MIND: 'change_mind',
  OTHERS: 'others',
};

export const getRejectedReasonContent = reason => {
  let content = 'Others';
  switch (reason) {
    case REJECTED_REASON.NOT_ENOUGH_BUDGET:
      content = 'Won’t work for my budget';
      break;
    case REJECTED_REASON.SCHEDULE_CONFLICT:
      content = 'Specific schedule conflicts';
      break;
    case REJECTED_REASON.BUSY:
      content = 'Too busy';
      break;
    case REJECTED_REASON.EQUIPMENT_ISSUE:
      content = 'Don’t have access to the required equipment/facilities';
      break;
    case REJECTED_REASON.HEALTH_CONSIDERATION:
      content = 'Health or medical considerations';
      break;
    case REJECTED_REASON.NOT_MATCH_EXPECTATION:
      content = 'Package specifics didn’t match my expectations';
      break;
    case REJECTED_REASON.CHANGE_GOAL:
      content = 'Changed my fitness/workout goals';
      break;
    case REJECTED_REASON.CHANGE_MIND:
      content = 'Changed my mind';
      break;
    default:
      break;
  }
  return content;
};
