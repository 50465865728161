import _ from 'lodash';
import { Types } from './workout.actionCreators';

export const DEFAULT_STATE = {
  total: 0,
  listWorkout: [],
  selectedWorkout: [],
  loading: false,
  query: {
    page: 1,
    per_page: 20,
    q: '',
    sorter: 'last_interacted',
    sort: -1,
    search_tags: [],
  },
  allExercise: [],
};

const WorkoutReducer = (state = DEFAULT_STATE, action) => {
  const { payload } = action;
  let newList = [];

  switch (action.type) {
    case Types.WORKOUT_LIBRARY_SUCCESS_GET_LIST:
      const { data } = payload;
      return Object.assign({}, state, { total: parseInt(data.total), listWorkout: data.data, loading: false });

    case Types.WORKOUT_LIBRARY_FAILED_GET_LIST:
      return Object.assign({}, state, { loading: false });

    case Types.WORKOUT_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS:
      return Object.assign({}, state, {
        listWorkout: _.map(state.listWorkout, item =>
          item._id === payload.data._id ? { ...item, share: payload.data.share } : item,
        ),
      });

    case Types.WORKOUT_LIBRARY_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...payload.data } });

    case Types.WORKOUT_LIBRARY_RESET_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...DEFAULT_STATE.query } });

    case Types.WORKOUT_LIBRARY_LOADING_DATA:
      return Object.assign({}, state, { loading: true });

    case Types.WORKOUT_LIBRARY_UPDATE_SELECTED_WORKOUT:
      return Object.assign({}, state, { selectedWorkout: payload.data });

    case Types.UPDATE_TAG_IN_WORKOUTS:
      const { id, name } = payload;
      newList = state.listWorkout.map(item => ({
        ...item,
        tags: (item.tags || []).map(tag => (tag._id === id ? { ...tag, name } : tag)),
      }));
      return Object.assign({}, state, {
        listWorkout: newList,
      });

    case Types.DELETE_TAG_IN_WORKOUTS:
      newList = state.listWorkout.map(item => ({
        ...item,
        tags: (item.tags || []).filter(tag => tag._id !== payload.id),
      }));
      return Object.assign({}, state, {
        listWorkout: newList,
      });

    case Types.WORKOUT_LIBRARY_RESET_SELECTED_WORKOUTS_SUCCESS: {
      return Object.assign({}, state, { selectedWorkout: [] });
    }

    case Types.FETCH_ALL_EXERCISES_SUCCESS: {
      return Object.assign({}, state, { allExercise: payload });
    }

    default:
      return state;
  }
};

export default WorkoutReducer;
