import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { toggleModal } from 'actions/modal';
import ChooseWorkoutModal from 'components/ChooseWorkoutModal';
import { axiosInstance } from 'configs/request';
import {
  addWorkoutToAutoflowInterval,
  assignWorkoutToAutoflowInterval,
  deleteAutoflowIntervalWorkout,
  fetchAutoflowIntervalWorkoutsByWeek,
  saveAutoflowIntervalWorkoutToLibrary,
  updateAutoflowIntervalWorkout,
  viewAutoflowIntervalWorkoutDetail,
} from 'redux/autoflowInterval/training/actions';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import CreateWorkoutTemplateAIDemo from 'components/CreateWorkoutTemplateAIDemo';

import { TAGS_TYPE, WORKOUT_AI_BUILDER_PAGES, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { getMostRecentTagsList } from 'redux/tags/actions';
import { omitEmptyRequestParams } from 'utils/commonFunction';

const DEFAULT_PARAMS = { page: 1, per_page: 10 };

class AssignWorkoutModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 0,
      workouts: [],
      isCreateNew: false,
      isSubmitting: false,
    };
    this.search = '';
    this.tags = [];
    this.searchDebounce = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.searchWorkout();
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.WORKOUT });
  }

  searchWorkout = searchParams => {
    const q = _.get(searchParams, 'q');
    const tags = _.get(searchParams, 'tags', []);
    const params = omitEmptyRequestParams({
      ...DEFAULT_PARAMS,
      q,
      search_tags: _.join(
        tags.map(tag => tag._id),
        ',',
      ),
    });
    this.setState({ loading: true, searching: true });

    axiosInstance
      .get('/api/workout/v2/list', { params })
      .then(response => {
        const { data, total } = response.data;
        this.setState({
          workouts: data,
          total,
          page: params.page,
          hasMore: data.length < response.data.total,
          loading: false,
        });
      })
      .catch(() => {});
  };

  onSearch = params => {
    this.search = params.q;
    this.tags = params.tags;
    this.searchWorkout(params);
  };

  loadMore = () => {
    const { hasMore, page, isLoading } = this.state;
    const params = omitEmptyRequestParams({
      page: page + 1,
      per_page: DEFAULT_PARAMS.per_page,
      q: this.search,
      search_tags: _.join(
        this.tags.map(tag => tag._id),
        ',',
      ),
    });

    if (hasMore && !isLoading) {
      this.setState({ loading: true });
      axiosInstance
        .get('/api/workout/v2/list', { params })
        .then(response => {
          const { data } = response.data;
          const newWorkouts = _.unionBy(this.state.workouts, data, '_id');
          this.setState({
            workouts: newWorkouts,
            total: response.data.total,
            page: params.page,
            hasMore: newWorkouts.length < response.data.total,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onSelect = workout => {
    this.setState({ isSubmitting: true });
    const { autoflowId, weekIndex, dayIndex } = this.props;
    const bodyData = { workoutId: workout._id, autoflowId, weekIndex, dayIndex };
    this.props
      .assignWorkoutToAutoflowInterval(bodyData)
      .then(() => this.props.toggleModal(false))
      .catch(() => this.setState({ isSubmitting: false }));
  };

  onClose = () => {
    this.props.toggleModal(false);
  };

  onCreateNew = () => {
    this.setState({ isCreateNew: true });
  };

  onSavedWithoutClose = workout => {
    const { weekIndex, dayIndex, autoflowId } = this.props;
    const requestParams = { autoflowId, workoutId: workout._id };
    const otherData = { weekIndex, dayIndex, autoflowId };
    this.props.viewAutoflowIntervalWorkoutDetail(requestParams, otherData);
  };

  onSaveTheNewWorkout = async workoutData => {
    const { weekIndex, dayIndex, autoflowId } = this.props;
    const bodyData = {
      weekIndex,
      dayIndex,
      autoflowId,
      title: workoutData.title,
      background: workoutData.background,
      description: workoutData.description,
      sections: workoutData.sections,
      is_generated_by_ai: workoutData.is_generated_by_ai || false,
      conversion_id: workoutData.is_generated_by_ai ? workoutData.conversion_id || null : null,
      is_edited: workoutData.is_generated_by_ai ? workoutData.is_edited || false : undefined,
    };

    try {
      const response = await this.props.addWorkoutToAutoflowInterval(bodyData);
      const { data, created } = response.data;
      const createdWorkoutData = _.find(data.workouts, w => w._id === created);

      return createdWorkoutData;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  handleUpdateAssignment = workout => {
    const { weekIndex, dayIndex, autoflowId, updateAutoflowIntervalWorkout } = this.props;
    const { _id, title, description, sections, background } = workout || {};

    const bodyData = {
      weekIndex,
      dayIndex,
      autoflowId,
      title: title,
      description: description,
      sections: sections,
      workoutId: _id,
      background: background,
    };

    return updateAutoflowIntervalWorkout(bodyData).then(response => {
      const { data } = (response || {}).data;
      return _.find((data || {}).workouts, ({ _id }) => _id === (bodyData || {}).workoutId);
    });
  };

  handleSaveToLibrary = workout => {
    const { autoflowId, saveAutoflowIntervalWorkoutToLibrary } = this.props;
    const { _id } = workout || {};
    const params = { workoutId: _id, autoflowId };
    saveAutoflowIntervalWorkoutToLibrary(params);
  };

  handleDeleteAssignment = workout => {
    const { weekIndex, dayIndex, autoflowId, deleteAutoflowIntervalWorkout } = this.props;
    const { _id } = workout || {};
    const params = { autoflowId, weekIndex, dayIndex, workoutId: _id };
    deleteAutoflowIntervalWorkout(params);
  };

  handleEverfitAIClick = () => {
    const { toggleModal } = this.props;
    if (toggleModal) {
      toggleModal(
        true,
        <CreateWorkoutTemplateAIDemo
          onAssignment={this.onSaveTheNewWorkout}
          onUpdateAssignment={this.handleUpdateAssignment}
          onSaveToLibrary={this.handleSaveToLibrary}
          onAssignmentDelete={this.handleDeleteAssignment}
          pdfType="autoflow_workout"
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          onSavedWithoutClose={this.onSavedWithoutClose}
          workoutAIPage={WORKOUT_AI_BUILDER_PAGES.autoflow_interval}
        />,
      );
    }
  };

  render() {
    const { isCreateNew, hasMore, loading, total, isSubmitting } = this.state;
    const { weekIndex, dayIndex, totalWeek, workoutTags, enableAIworkout } = this.props;

    if (isCreateNew) {
      return (
        <WorkoutDetailModal
          key="create-new-autoflow-interval-workout"
          maxWeeks={totalWeek}
          onSave={this.onSaveTheNewWorkout}
          onSavedWithoutClose={this.onSavedWithoutClose}
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          fetchWorkoutsByWeek={fetchAutoflowIntervalWorkoutsByWeek}
          date={Number(weekIndex) * 7 + Number(dayIndex)}
          pdfType="autoflow_workout"
        />
      );
    }

    return (
      <ChooseWorkoutModal
        workoutTags={workoutTags}
        workouts={this.state.workouts}
        onClose={this.onClose}
        onSearch={params => {
          this.search = params.q;
          this.tags = params.tags;
          this.searchDebounce(params);
        }}
        loadMore={this.loadMore}
        isLoading={loading}
        total={total}
        hasMore={hasMore}
        onSelect={this.onSelect}
        onCreateNew={this.onCreateNew}
        title="Find a Workout"
        displayOwner
        displayLastUsed
        disableSelectButton={isSubmitting}
        onEverfitAIClick={enableAIworkout ? this.handleEverfitAIClick : undefined}
      />
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
      permission,
      workoutTags,
    },
  } = state;
  const enableAIAllWorkoutBuilder = process.env.REACT_APP_AI_ENABLE_ALL_WORKOUT_BUILDER || false;
  const enableAIworkout = enableAIAllWorkoutBuilder && _.get(permission, 'workout_builder_ai', false);
  const totalWeek = workingAutoflow.interval_weeks.length;

  return {
    totalWeek,
    workoutTags,
    enableAIworkout,
  };
};

const actionCreators = {
  addWorkoutToAutoflowInterval,
  updateAutoflowIntervalWorkout,
  saveAutoflowIntervalWorkoutToLibrary,
  deleteAutoflowIntervalWorkout,
  assignWorkoutToAutoflowInterval,
  toggleModal,
  push,
  viewAutoflowIntervalWorkoutDetail,
  getMostRecentTagsList,
};

export default connect(mapState, actionCreators)(AssignWorkoutModal);
