import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Image } from 'semantic-ui-react';
import _ from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import SelectClients from './SelectClients';
import * as S from './style';
import ChooseStartDate from './ChooseStartDate';
import { bulkInviteClients } from 'redux/autoflowInterval/client/actions';
import { assignClientsSuccess } from 'redux/autoflow/client/actions';
import { CDN_URL } from 'constants/commonData';

const getFullName = member => {
  return `${member.first_name} ${member.last_name}`;
};

function Component(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState([]);
  let [selectedAssignToGroup, setSelectedAssignToGroup] = useState([]);

  const onClose = () => {
    setIsSubmitting(false);
    setOpen(false);
    setStep(1);
    setSelectedClient([]);
  };

  const onSelectClient = clients => {
    setSelectedClient(clients);
    setStep(2);
  };

  const onConfirm = confirmData => {
    if (selectedAssignToGroup.length > 0) {
      _.map(selectedClient, item => {
        if (item.typeName !== 'group') selectedAssignToGroup.push(item);
      });
    }

    Mixpanel.track('autoflow_interval_client_listing_screen_add_client');
    const params = {
      ...confirmData,
      clients:
        selectedAssignToGroup.length > 0
          ? [...new Set(_.map(selectedAssignToGroup, '_id'))]
          : _.map(selectedClient, '_id'),
      autoflowId: props.autoflow,
    };
    setIsSubmitting(true);

    props
      .bulkInviteClients(params)
      .then(response => {
        onClose();
        const firstClientName = _.get(selectedClient, [0, 'full_name']) || getFullName(_.get(selectedClient, [0], ''));
        const { data } = response.data;
        const popupData = {
          data: {
            list_success: data.filter(o => o.success),
            list_error: data.filter(o => !o.success),
          },
        };
        props.assignClientsSuccess(popupData, firstClientName);
        handleClearAddList();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClearAddList = () => {
    setSelectedClient([]);
    setSelectedAssignToGroup([]);
  };

  return (
    <S.ModalWrapper
      open={open}
      className="autoflow-add-clients-modal"
      closeOnDimmerClick={false}
      closeIcon={
        <Button onClick={() => {}} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <S.ActionButton className="autoflow-interval__add-clients-trigger">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M7 8.16667C8.93025 8.16667 10.5 6.59692 10.5 4.66667V3.5C10.5 1.56975 8.93025 0 7 0C5.06975 0 3.5 1.56975 3.5 3.5V4.66667C3.5 6.59692 5.06975 8.16667 7 8.16667Z"
              fill="#A1A1A5"
            />
            <path
              d="M12.25 11.6668C10.8389 11.6668 9.66233 10.6647 9.39225 9.3335H4.08333C1.83225 9.3335 0 11.1652 0 13.4168V14.0002H14V13.4168C14 12.6818 13.8022 11.9935 13.4604 11.3967C13.0912 11.5665 12.6834 11.6668 12.25 11.6668Z"
              fill="#A1A1A5"
            />
            <path
              d="M12.8333 7H11.6667V8.16667H10.5V9.33333H11.6667V10.5H12.8333V9.33333H14V8.16667H12.8333V7Z"
              fill="#A1A1A5"
            />
          </svg>
          <span>+ Clients</span>
        </S.ActionButton>
      }
    >
      {step === 1 && (
        <SelectClients onSelectClient={onSelectClient} onSetSelectedAssignToGroup={setSelectedAssignToGroup} />
      )}
      {step === 2 && <ChooseStartDate onConfirm={onConfirm} isSubmitting={isSubmitting} />}
    </S.ModalWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  return {
    autoflow: workingAutoflow ? workingAutoflow._id : '',
  };
};

export default connect(mapStateToProps, { bulkInviteClients, assignClientsSuccess })(Component);
