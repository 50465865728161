import React from 'react';
import CircleProgress from 'shared/CircleProgress';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .progress-percent {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #202353;
    margin-left: 5px;
  }

  .no-data {
    height: 1px;
    width: 20px;
    background: #202353;
    opacity: 0.2;
  }
`;

const ColumnItem = props => {
  const { progress, progressPercent, progressCircle, color, status } = props;
  return (
    <Wrapper>
      {status !== 0 && (
        <>
          <CircleProgress radius={26} stroke={18} progress={progressCircle} color={color} />
          <div className="compliance-progress progress-percent">{progressPercent}</div>
          <div className="compliance-progress progress-number">{progress}</div>
        </>
      )}
      {status === 0 && <div className="no-data" />}
    </Wrapper>
  );
};

export default ColumnItem;
