import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
// import _, { get } from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
// import Select from 'react-select';
import classNames from 'classnames';

import { SECTION_CUSTOM_FILTER, TAGS_TYPE } from 'constants/commonData';
import { Button } from 'shared/FormControl';
import { getFixedPopupStyles } from 'utils/commonFunction';
import { Checkbox } from 'shared/FormControl';
import { Toggle } from 'shared/FormControl';
import TagsFilter from 'shared/TagsFilter';
// import SelectOwnerOption from 'shared/SelectOwnerOption';
import { getFormatName } from 'components/WorkoutDetailModal/components/LeftPanel/SectionsTab/SectionItem';
import SelectTaskType from 'shared/SelectTaskType';
import SelectOwner from 'shared/SelectOwner';

import * as S from './style';

export const DEFAULT_FILTERS = {
  body_part: [],
  category_type: [],
  from: [false, false],
  video_only: false,
  tags: [],
  modalities: [],
  muscle_groups: [],
  movement_patterns: [],
  equipments: [],
};

export const DEFAULT_CUSTOM_FILTERS = { types: [], owners: [], formats: [] };

const DEFAULT_OPENED_SECTION = {
  body_part: true,
  category_type: true,
  from: true,
  video_only: true,
  tags: true,
  owners: true,
  section_types: true,
  task_type: true,
  owner: true,
  equipments: true,
};

export default function ExerciseFilter(props) {
  const {
    bodyParts,
    categories,
    currentFilters,
    tags,
    hideTag = false,
    title,
    hideDefaultFilter = false,
    hideCustomFilter = true,
    onCustomFilter,
    currentCustomFilters,
    isShowCustomFilter = false,
    tagType = null,
    tagMenuPortalTarget = null,
    tagsStyles = {},
    useMinHeight = false,
    classNameButtonUpdate = '',
    hideExerciseSourceFilter = false,
    toggleFilter,
    equipments = [],
    modalities = [],
    muscleGroup = [],
    movementPattern = [],
  } = props;

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const [customFilters, setCustomFilters] = useState({ ...DEFAULT_CUSTOM_FILTERS });
  const [openedSections, setOpenedSections] = useState({ ...DEFAULT_OPENED_SECTION });
  const [isTagsMenuOpen, setIsTagsMenuOpen] = useState(false);
  const triggerRef = useRef();
  const menuRef = useRef();
  const menuWidth = props.menuWidth || 435;
  const menuHeight = useMinHeight ? props.menuMinHeight : props.menuHeight || 455;
  const [showingFull, setShowingFull] = useState(false); // Open nested dropdown
  const hideExerciseSource = hideExerciseSourceFilter || hideDefaultFilter;

  useEffect(() => {
    if (!open) {
      setFilters(currentFilters || DEFAULT_FILTERS);
      setCustomFilters(currentCustomFilters || DEFAULT_CUSTOM_FILTERS);
    }
    typeof toggleFilter === 'function' && toggleFilter(open);
  }, [open, currentFilters, currentCustomFilters]);

  const getMenuStyles = () => {
    if (open && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const styles = getFixedPopupStyles(triggerRect, menuHeight, 7);
      let left = triggerRect.x - menuWidth / 2 + triggerRect.width / 2;

      if (isShowCustomFilter) {
        left = triggerRect.x;
      }

      if (left + menuWidth > window.innerWidth) {
        left = window.innerWidth - menuWidth - 10;
      }

      return {
        styles: { ...styles, left },
        className: styles.top < triggerRect.y ? 'top' : 'bottom',
      };
    }

    return { styles: { display: 'none' } };
  };

  const onPrimaryFocusItemClick = item => {
    const currentIndex = filters.body_part.findIndex(i => i === item);

    if (currentIndex === -1) {
      setFilters({ ...filters, body_part: [...filters.body_part, item] });
    } else {
      const newList = filters.body_part.slice();
      newList.splice(currentIndex, 1);
      setFilters({ ...filters, body_part: newList });
    }
  };

  const onFilterItemClick = (key, itemId) => () => {
    if (!key || !filters.hasOwnProperty(key)) return;

    const currentIndex = filters[key].findIndex(id => id === itemId);

    if (currentIndex === -1) {
      setFilters({ ...filters, [key]: [...filters[key], itemId] });
    } else {
      const newList = filters[key].slice();
      newList.splice(currentIndex, 1);
      setFilters({ ...filters, [key]: newList });
    }
  };

  const onSelectCategory = (item, checked) => {
    if (checked) {
      setFilters(prevFilters => ({
        ...prevFilters,
        category_type: [...prevFilters.category_type, item._id],
      }));
    } else {
      setFilters(prevFilters => {
        const newList = prevFilters.category_type.slice();
        newList.splice(
          newList.findIndex(i => i === item._id),
          1,
        );
        return { ...prevFilters, category_type: newList };
      });
    }
  };

  const onSelectTaskType = (item, checked) => {
    if (checked) {
      setCustomFilters(prevFilters => ({
        ...prevFilters,
        types: [...prevFilters.types, item._id],
      }));
    } else {
      setCustomFilters(prevFilters => {
        const newList = prevFilters.types.slice();
        newList.splice(
          newList.findIndex(i => i === item._id),
          1,
        );
        return { ...prevFilters, types: newList };
      });
    }
  };

  // const handleSelectOwners = owners => {
  //   setShowingFull(true);
  //   setCustomFilters(prevFilters => ({
  //     ...prevFilters,
  //     owners: owners,
  //   }));
  // };

  const onUpdate = () => {
    typeof props.onApply === 'function' && props.onApply(filters);
    isShowCustomFilter && onCustomFilter && onCustomFilter(customFilters);
    setOpen(false);
  };

  const onClear = () => {
    if (!_.isEqual(filters, DEFAULT_FILTERS)) {
      setFilters({ ...DEFAULT_FILTERS });
      typeof props.onApply === 'function' && props.onApply({ ...DEFAULT_FILTERS });
    }
    if (isShowCustomFilter && !_.isEqual(customFilters, DEFAULT_CUSTOM_FILTERS)) {
      setCustomFilters({ ...DEFAULT_CUSTOM_FILTERS });
      onCustomFilter && onCustomFilter({ ...DEFAULT_CUSTOM_FILTERS });
    }
  };

  const renderExerciseSource = () => {
    const { from } = openedSections;

    return (
      <S.Section expand={from} className="from">
        <S.SectionHeader onClick={() => setOpenedSections({ ...openedSections, from: !from })}>
          <S.SectionTitle>Exercises from</S.SectionTitle>
          <S.SectionToggleIcon />
        </S.SectionHeader>
        {from && (
          <S.SectionBody>
            <Checkbox
              key={'system-exercise'}
              title="Everfit"
              checked={filters.from[0]}
              onChange={event => {
                setFilters({ ...filters, from: [event.target.checked, filters.from[1]] });
              }}
              size={15}
            />
            <Checkbox
              key={'custom-exercise'}
              title="Custom Exercises"
              checked={filters.from[1]}
              onChange={event => {
                setFilters({ ...filters, from: [filters.from[0], event.target.checked] });
              }}
              size={15}
            />
          </S.SectionBody>
        )}
      </S.Section>
    );
  };

  const renderVideoExercise = () => {
    const { video_only } = openedSections;

    return (
      <S.Section expand={video_only} className="video_only">
        <S.SectionBody>
          <Toggle
            width={32}
            height={18}
            checked={filters.video_only}
            onChange={() => {
              setFilters({ ...filters, video_only: !filters.video_only });
            }}
            label="Exercises with video"
            reverse
            fullWidth
          />
        </S.SectionBody>
      </S.Section>
    );
  };

  const renderCategories = () => {
    return (
      <S.Section expand={openedSections.category_type} className="category">
        <S.SectionHeader
          onClick={() => setOpenedSections({ ...openedSections, category_type: !openedSections.category_type })}
        >
          <S.SectionTitle>Category</S.SectionTitle>
          <S.SectionToggleIcon />
        </S.SectionHeader>
        {openedSections.category_type && (
          <S.SectionBody>
            {_.map(categories, item => (
              <div className="checkbox-container" key={item._id}>
                <Checkbox
                  key={item._id}
                  title={item.title}
                  checked={filters.category_type.includes(item._id)}
                  onChange={event => onSelectCategory(item, event.target.checked)}
                  size={15}
                />
              </div>
            ))}
          </S.SectionBody>
        )}
      </S.Section>
    );
  };

  // Customize Filter for suitable with sections library
  const onSelectSectionType = (item, checked) => {
    if (checked) {
      setCustomFilters(prevFilters => ({
        ...prevFilters,
        formats: [...prevFilters.formats, item._id],
      }));
    } else {
      setCustomFilters(prevFilters => {
        const newList = prevFilters.formats.slice();
        newList.splice(
          newList.findIndex(i => i === item._id),
          1,
        );
        return { ...prevFilters, formats: newList };
      });
    }
  };

  const renderSectionType = () => {
    const sectionTypes = _.keys(SECTION_CUSTOM_FILTER).map(value => ({
      _id: value,
      title: getFormatName(value),
    }));

    return (
      <S.Section expand={openedSections.section_types} className="category">
        <S.SectionHeader
          onClick={() => setOpenedSections({ ...openedSections, section_types: !openedSections.section_types })}
        >
          <S.SectionTitle>Section Format</S.SectionTitle>
          <S.SectionToggleIcon />
        </S.SectionHeader>
        {openedSections.section_types && (
          <S.SectionBody>
            {_.map(sectionTypes, item => (
              <div className="checkbox-container" key={item._id}>
                <Checkbox
                  key={item._id}
                  title={item.title}
                  checked={(customFilters.formats || []).includes(item._id)}
                  onChange={event => onSelectSectionType(item, event.target.checked)}
                  size={15}
                />
              </div>
            ))}
          </S.SectionBody>
        )}
      </S.Section>
    );
  };

  const handleSelectOwners = owners => {
    setOpen(true);
    setShowingFull(true);
    setCustomFilters(prevFilters => ({
      ...prevFilters,
      owners: owners,
    }));
  };

  // const renderOwner = () => {
  //   const { user } = props;

  //   const teamMembers = user.team_member || [];
  //   const options = _.map(teamMembers, item => {
  //     return Object.assign({}, item, {
  //       key: item._id,
  //       value: item._id,
  //       label: `${item.first_name} ${item.last_name}`,
  //     });
  //   });

  //   return (
  //     <S.Section expand={openedSections.owners} className="tags" id="tags-section">
  //       <S.SectionHeader onClick={() => setOpenedSections({ ...openedSections, owners: !openedSections.owners })}>
  //         <S.SectionTitle>Owner</S.SectionTitle>
  //         <S.SectionToggleIcon />
  //       </S.SectionHeader>
  //       {openedSections.owners && (
  //         <S.SectionBody>
  //           <S.SelectOwner>
  //             <Select
  //               isMulti
  //               options={options}
  //               components={{ Option: SelectOwnerOption }}
  //               onChange={owners => handleSelectOwners(owners)}
  //               value={get(customFilters, 'owners')}
  //               classNamePrefix="multi-select"
  //               placeholder="Enter the users"
  //               className="multi-select-container"
  //               noOptionsMessage={() => 'No results'}
  //               menuPortalTarget={document.body}
  //               onMenuOpen={() => setShowingFull(true)}
  //               onMenuClose={() => setShowingFull(false)}
  //             />
  //           </S.SelectOwner>
  //         </S.SectionBody>
  //       )}
  //     </S.Section>
  //   );
  // };

  const handleShowingFull = isShow => {
    setShowingFull(isShow);
  };

  const handleSelectTags = values => {
    setFilters({ ...filters, tags: values });
  };

  const handleTagMenuOpen = () => {
    setIsTagsMenuOpen(true);
    setShowingFull(true); // Set open nested dropdown
  };

  const handleTagMenuClose = () => {
    setIsTagsMenuOpen(false);
    // Keep open parent dropdown
    setTimeout(() => {
      setShowingFull(false);
    }, 300);
  };

  const renderTags = () => {
    return (
      <S.Section expand={openedSections.tags} className="tags" id="tags-section">
        <S.SectionHeader onClick={() => setOpenedSections({ ...openedSections, tags: !openedSections.tags })}>
          <S.SectionTitle>Tags</S.SectionTitle>
          <S.SectionToggleIcon />
        </S.SectionHeader>
        {openedSections.tags && (
          <S.SectionBody>
            <TagsFilter
              tagsStyles={tagsStyles}
              menuPortalTarget={tagMenuPortalTarget}
              onMenuOpen={handleTagMenuOpen}
              onMenuClose={handleTagMenuClose}
              tags={tags}
              selectedTags={filters.tags}
              type={tagType ? tagType : TAGS_TYPE.EXERCISE}
              sorter="most_recent"
              onChange={handleSelectTags}
            />
          </S.SectionBody>
        )}
      </S.Section>
    );
  };

  // const renderPrimaryFocus = () => {
  //   return (
  //     <S.Section expand={openedSections.body_part} className="primary-focus">
  //       <S.SectionHeader onClick={() => setOpenedSections({ ...openedSections, body_part: !openedSections.body_part })}>
  //         <S.SectionTitle>Primary Focus</S.SectionTitle>
  //         <S.SectionToggleIcon />
  //       </S.SectionHeader>
  //       {openedSections.body_part && (
  //         <S.SectionBody>
  //           {_.map(bodyParts, (item, index) => (
  //             <S.PrimaryFocusItem
  //               key={index}
  //               className={filters.body_part.includes(item) ? 'selected' : ''}
  //               onClick={() => onPrimaryFocusItemClick(item)}
  //             >
  //               {item}
  //             </S.PrimaryFocusItem>
  //           ))}
  //         </S.SectionBody>
  //       )}
  //     </S.Section>
  //   );
  // };

  const renderPrimaryFocusVersion2 = () => {
    return (
      <S.Section expand={openedSections.body_part} className="primary-focus">
        <S.SectionHeader onClick={() => setOpenedSections({ ...openedSections, body_part: !openedSections.body_part })}>
          <S.SectionTitle>Primary Focus</S.SectionTitle>
          <S.SectionToggleIcon />
        </S.SectionHeader>
        {openedSections.body_part && (
          <>
            <S.PrimaryFocusChildLabel>MODALITY</S.PrimaryFocusChildLabel>
            <S.SectionBody className="padding-none">
              {_.map(modalities, (item, index) => (
                <S.PrimaryFocusItem
                  key={index}
                  className={filters.modalities.includes(item._id) ? 'selected' : ''}
                  onClick={onFilterItemClick('modalities', item._id)}
                >
                  {item.title}
                </S.PrimaryFocusItem>
              ))}
            </S.SectionBody>
            <S.PrimaryFocusChildLabel>MUSCLE GROUP</S.PrimaryFocusChildLabel>
            <S.SectionBody className="padding-none">
              {_.map(muscleGroup, (item, index) => (
                <S.PrimaryFocusItem
                  key={index}
                  className={filters.muscle_groups.includes(item._id) ? 'selected' : ''}
                  onClick={onFilterItemClick('muscle_groups', item._id)}
                >
                  {item.title}
                </S.PrimaryFocusItem>
              ))}
            </S.SectionBody>
            <S.PrimaryFocusChildLabel>MOVEMENT PATTERN</S.PrimaryFocusChildLabel>
            <S.SectionBody className="padding-none">
              {_.map(movementPattern, (item, index) => (
                <S.PrimaryFocusItem
                  key={index}
                  className={filters.movement_patterns.includes(item._id) ? 'selected' : ''}
                  onClick={onFilterItemClick('movement_patterns', item._id)}
                >
                  {item.title}
                </S.PrimaryFocusItem>
              ))}
            </S.SectionBody>
          </>
        )}
      </S.Section>
    );
  };

  const renderExerciseEquipment = () => {
    return (
      <S.Section expand={openedSections.equipments} className="exercise-equipment">
        <S.SectionHeader
          onClick={() => setOpenedSections({ ...openedSections, equipments: !openedSections.equipments })}
        >
          <S.SectionTitle>Equipment</S.SectionTitle>
          <S.SectionToggleIcon />
        </S.SectionHeader>
        {openedSections.equipments && (
          <S.SectionBody>
            {_.map(equipments, item => (
              <S.PrimaryFocusItem
                key={item._id}
                className={filters.equipments.includes(item._id) ? 'selected' : ''}
                onClick={onFilterItemClick('equipments', item._id)}
              >
                {item.title}
              </S.PrimaryFocusItem>
            ))}
          </S.SectionBody>
        )}
      </S.Section>
    );
  };

  const { styles, className } = getMenuStyles();
  let count = 0;

  _.forEach(filters, type => {
    count += typeof type === 'object' ? _.sumBy(type, i => !!i) : type ? 1 : 0;
  });

  _.forEach(customFilters, type => {
    count += typeof type === 'object' ? _.sumBy(type, i => !!i) : type ? 1 : 0;
  });

  const handleCloseFilterPopup = () => {
    if (showingFull) {
      return;
    }
    setOpen(false);
  };

  return (
    <RootCloseWrapper
      event="click"
      onRootClose={handleCloseFilterPopup}
      disabled={tagMenuPortalTarget !== null ? !open || isTagsMenuOpen : !open}
    >
      <S.Wrapper className={classNames('exercise-filter', className, { open: open })}>
        {count > 0 && (
          <S.Count className="exercise-filter__count">
            <span>{count}</span>
          </S.Count>
        )}
        <S.TriggerContainer
          ref={triggerRef}
          className="exercise-filter__trigger"
          onClick={() => {
            setOpen(prev => !prev);
          }}
        >
          {props.trigger || <S.Trigger />}
        </S.TriggerContainer>
        <S.Popup
          ref={menuRef}
          style={styles}
          menuHeight={menuHeight}
          menuWidth={menuWidth}
          className="exercise-filter__menu"
        >
          <S.Header>
            <div className="popup-title">{title || 'Exercise Filters'}</div>
            <S.ClearSelection onClick={onClear}>Clear All</S.ClearSelection>
          </S.Header>
          <S.Body id="filter-popup-body">
            {!hideExerciseSource && renderExerciseSource()}
            {!hideDefaultFilter && renderVideoExercise()}
            {!hideCustomFilter && <SelectTaskType types={customFilters.types} onSelectTaskType={onSelectTaskType} />}
            {!hideTag && tags && renderTags()}
            {isShowCustomFilter && hideCustomFilter && renderSectionType()}
            {(isShowCustomFilter || !hideCustomFilter) && (
              <SelectOwner
                owners={customFilters.owners}
                selectedOwners={customFilters.owners}
                onSelectowners={handleSelectOwners}
                onShowDropdown={handleShowingFull}
              />
            )}
            {!hideDefaultFilter && renderCategories()}
            {/* {!hideDefaultFilter && renderPrimaryFocus()} */}
            {/* {(isShowCustomFilter || !hideCustomFilter) && renderOwner()} */}
            {!hideDefaultFilter && renderPrimaryFocusVersion2()}
            {!hideDefaultFilter && renderExerciseEquipment()}
          </S.Body>
          <S.Footer className="footer">
            <Button purple onClick={onUpdate} className={classNameButtonUpdate}>
              Update
            </Button>
          </S.Footer>
        </S.Popup>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}
