import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-app-icon-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 576px;
  }
  &.ui.modal.evf-app-icon-modal > .header,
  &.ui.modal.evf-app-icon-modal > .content,
  &.ui.modal.evf-app-icon-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  background: #fff;
  padding: 32px 32px 16px;
  border-radius: 8px 8px 0 0;
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #000;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-height: 470px;
  height: 100%;
  background: #fff;
  padding: 0 8px 0 24px;
`;

export const ContentContainer = styled.div`
  height: 100%;
  min-height: 470px;
  max-height: 470px;
  overflow: auto;
  padding: 8px 24px 16px 8px;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebebeb !important;
  }

  .loading--custom-branding {
    padding-top: 200px;
    &.loading-more {
      top: auto;
      transform: none;
      padding-top: 14px;
    }
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const ContentBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 116px;
  height: 116px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border: 1px solid #5158cf;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #5158cf;
      box-shadow: 0px 0px 0px 4px rgba(81, 88, 207, 0.1);
    `}

  img.item-image {
    width: 68px;
    height: 68px;
    object-fit: cover;
  }

  div.item-label {
    position: absolute;
    top: 4px;
    right: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 7px;
    padding: 0 12px;
    background-color: rgba(0, 0, 0, 0.32);
    text-transform: capitalize;

    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    color: #fff;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.05);
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 0 35px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #5158cf;
  cursor: pointer;

  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #fff;

  &:disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
  }
`;
