import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import round from 'lodash/round';
import min from 'lodash/min';
import max from 'lodash/max';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { LEGEND_STAGE_ORDER, SLEEP_STAGE_KEYS, SLEEP_VIEW, TIME_RANGE_DATE } from './constants';
import { mongoObjectId } from 'utils/commonFunction';
import { PERIOD_GROUP, DATE_FORMAT } from 'components/BodyMetricChartNew/constants';
import { getDurationTime, getWeekLabel, getWeeksInRange } from 'components/BodyMetricChartNew/chartHelper';
import { convertMinToHour } from './hourly-helper';

export const DATE_TIME_SLEEP = 'YYYY-MM-DD HH:mm';

export const getOffsetColor = (total, index) => {
  if (index === 0) return '0%';
  if (total === 2) return '50%';
  if (total === 3) {
    if (index === 1) return '10%';
    if (index === 2) return '90%';
  }
  // remaining total = 4
  if (index === 1) return '7%';
  if (index === 2) return '90%';
  if (index === 3) return '97%';
};

export const getRadius = (currIndex, length, sleepView, isShowStages) => {
  if (sleepView === SLEEP_VIEW.TIME && length === 1) {
    return [3, 3, 3, 3];
  }
  switch (currIndex) {
    case 0:
      return [3, 3, 0, 0];
    case length - 1:
      return sleepView === SLEEP_VIEW.DURATION ? [0, 0, 0, 0] : [0, 0, 3, 3];
    default:
      return [0, 0, 0, 0];
  }
};

export const getTimeGoalFromDate = time => {
  // time: HH:mm
  const hmMM = moment(time, 'HH:mm');
  const h = hmMM.get('h');
  const m = hmMM.get('m');
  const hm = +Number(h + m / 60).toFixed(2);
  return hm > 12 ? hm - 12 : hm + 12;
};

export const findMinMaxValueForDataSleep = (data = [], goalValue, isSleepDuration) => {
  const allValues = !isSleepDuration
    ? data.flatMap(item => item.rangeValues).filter(value => value !== 0)
    : data.flatMap(item => item.rangeValues);
  const valuesToConsider = [...allValues, goalValue].filter(value => value !== null);
  const minValue = Math.min(...valuesToConsider);
  const maxValue = Math.max(...valuesToConsider);
  return { min: minValue, max: maxValue };
};

export const statusSleepSetting = (sleepSettings = {}, isOverview = false) => {
  const { connectedApp = false, viewBy = '', isShowStages = false } = sleepSettings;

  const duration = viewBy === SLEEP_VIEW.DURATION;

  return {
    sleepDurationNoConnect: duration && !connectedApp,
    sleepDurationConnectedStage: duration && connectedApp && isShowStages,
    sleepDurationConnectedNoStage: duration && connectedApp && !isShowStages,
    sleepTimeNoConnect: !duration && !connectedApp,
    sleepTimeConnectedStage: !duration && connectedApp && isShowStages,
    sleepTimeConnectedNoStage: !duration && connectedApp && !isShowStages,
  };
};

export const convertSleepCharData = (data = [], sleepSettings, isOverview = false) => {
  const { viewBy = '' } = sleepSettings;
  const durationType = viewBy === SLEEP_VIEW.DURATION;
  const timeType = viewBy === SLEEP_VIEW.TIME;

  const result = data.map(item => {
    const { isHideItem = false } = item || {};

    const { stage_details = [], grouped_sleep_in_day = [] } = item || {};

    if (isHideItem) {
      const itemMore = {
        listRange: [],
        rangeValues: [],
        isMultipleRange: false,
      };
      return { ...item, ...itemMore };
    } else {
      // Sleep duration
      if (durationType) {
        const itemMore = !isEmpty(stage_details)
          ? convertDataSleepDuration(stage_details)
          : {
              dataType: [],
              rangeValues: [],
            };

        return { ...item, ...itemMore };
      }

      // Sleep time
      if (timeType) {
        const itemMore = !isEmpty(grouped_sleep_in_day)
          ? convertDataSleepTime(grouped_sleep_in_day)
          : {
              listRange: [],
              rangeValues: [],
              isMultipleRange: false,
            };
        return { ...item, ...itemMore };
      }

      return item;
    }
  });

  return result;
};

// Sleep duration

const convertDataSleepDuration = (data = []) => {
  const dataTypeConvert = data.map(item => {
    const { sleep_stage, duration } = item;
    return {
      type: sleep_stage,
      value: duration / 60,
    };
  });

  const sortedSleepData = sortByOrder(dataTypeConvert, 'type', LEGEND_STAGE_ORDER);

  return {
    dataType: sortedSleepData || [],
    rangeValues: [0, getTotalFromNestedValues(dataTypeConvert)],
  };
};

const getTotalFromNestedValues = data => {
  const hasInBed = data.some(item => item.type === SLEEP_STAGE_KEYS.in_bed);
  const itemInBed = data.find(item => item.type === SLEEP_STAGE_KEYS.in_bed);
  return hasInBed ? itemInBed.value : data.reduce((total, item) => total + item.value, 0);
};

// Sleep time

const convertDataSleepTime = (data = []) => {
  const dataListRange = getDataListRange(data);

  return {
    isMultipleRange: true,
    rangeValues: getMinMaxRangeValues(dataListRange),
    listRange: dataListRange || [],
  };
};

const getDataListRange = data => {
  const result = data.map(item => {
    const { lowestStartTime, highestEndTime, isSameDay } = getBoundaryTimes(item, 'start_time', 'end_time');

    const hasInBed = item.some(item => item.sleep_stage === SLEEP_STAGE_KEYS.in_bed);

    const getDataType = item.map(item => {
      const { sleep_stage, start_time, end_time, duration } = item;

      const isSameDay = moment(start_time, 'YYYY-MM-DD HH:mm').isSame(moment(end_time, 'YYYY-MM-DD HH:mm'), 'hour');

      return {
        type: sleep_stage,
        value: !hasInBed
          ? duration / 60
          : [
              getValueFromTimeFormat(formatDateTime(end_time), isSameDay),
              getValueFromTimeFormat(formatDateTime(start_time), false, true),
            ],
      };
    });

    const getDataRangValues = [
      getValueFromTimeFormat(highestEndTime, isSameDay),
      getValueFromTimeFormat(lowestStartTime, false, true),
    ];

    return {
      startDate: lowestStartTime,
      endDate: highestEndTime,
      isManual: hasInBed,
      dataType: getDataType,
      rangeValues: getDataRangValues,
    };
  });
  return result;
};

const getBoundaryTimes = (data, startTimeKey, endTimeKey) => {
  const startTimes = data.map(item => moment(item[startTimeKey]));
  const endTimes = data.map(item => moment(item[endTimeKey]));

  const lowestStartTime = moment.min(startTimes);
  const highestEndTime = moment.max(endTimes);

  return {
    lowestStartTime: lowestStartTime.format('h:mm A'),
    highestEndTime: highestEndTime.format('h:mm A'),
    isSameDay: lowestStartTime.isSame(highestEndTime, 'hour'),
  };
};

const getValueFromTimeFormat = (timeFormat, isSameDay = false, isStartTime = false) => {
  function parseTime(time) {
    let momentTime = moment(time, 'hh:mm A');
    return {
      hour: momentTime.format('h A'),
      minutes: momentTime.minutes(),
    };
  }

  const { hour, minutes } = parseTime(timeFormat);
  const result = isStartTime
    ? TIME_RANGE_DATE.findLast(item => item.label === hour)
    : isSameDay
    ? TIME_RANGE_DATE.findLast(item => item.label === hour)
    : TIME_RANGE_DATE.find(item => item.label === hour);
  return result.value - minutes / 60;
};

// Format time from "YYYY-MM-DD HH:mm" to "h:mm A"
const formatDateTime = dateTimeString => {
  let momentDateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm');
  let formattedDateTime = momentDateTime.format('h:mm A');

  return formattedDateTime;
};

const getMinMaxRangeValues = data => {
  const flatRangeValues = flatMap(data, 'rangeValues');
  const minValue = min(flatRangeValues);
  const maxValue = max(flatRangeValues);
  return [minValue, maxValue];
};

// Convert data sleep chart
export const convertDataSleepChart = (data, filterTime) => {
  const result = data.map(item => {
    const { end, group_name } = item;
    const date = moment(end, 'YYYY-MM-DD').toISOString();
    return {
      ...item,
      date,
      day: moment(end, 'YYYY-MM-DD').format('MM-DD-YYYY'),
      period_group: filterTime.period_group,
      time: group_name || date,
      _id: mongoObjectId(),
    };
  });
  return result;
};

export const filterDataSleepChart = (data, sleepSettings) => {
  const { isShowStages = false } = sleepSettings;

  const result = data.map(item => {
    const { stage_details = [], grouped_sleep_in_day = [] } = item;

    const dataStageDetails = (stage_details || []).filter(({ duration }) => {
      const roundedDuration = round(duration);
      return roundedDuration >= 1;
    });

    const dataGroupedSleepInDay = grouped_sleep_in_day.map(item => {
      return item.filter(({ duration }) => {
        const roundedDuration = round(duration);
        return roundedDuration >= 1;
      });
    });

    let stageDetails = [...dataStageDetails];
    let groupedSleepInDay = [...dataGroupedSleepInDay];

    switch (true) {
      case !isShowStages:
        stageDetails = calculateSleepDurationConnectedNoStage(dataStageDetails);
        groupedSleepInDay = calculateSleepTimeConnectedNoStage(dataGroupedSleepInDay);
        break;

      case isShowStages:
        stageDetails = calculateSleepDurationConnectedStage(dataStageDetails);
        groupedSleepInDay = calculateSleepTimeConnectedStage(dataGroupedSleepInDay);
        break;

      default:
        break;
    }

    return {
      ...item,
      stage_details: stageDetails,
      grouped_sleep_in_day: (groupedSleepInDay || []).filter(item => item.length > 0),
    };
  });
  return result;
};

export const fillChartDataByHours = (chartData, rangeTime) => {
  const { from_date } = rangeTime || {};
  const chartItemCloned = cloneDeep(chartData[0]);
  const fromDateMoment = moment(from_date, DATE_FORMAT);
  const items = [];

  for (let i = 0; i < 24; i++) {
    const day = fromDateMoment.clone().add(i, 'h');
    const chartItem = chartData.find(item => day.format('hA') === moment(item.date).format('hA'));
    if (chartItem) {
      chartItem.time = moment(chartItem.date).format('hA');
    }
    items.push(
      chartItem || {
        ...chartItemCloned,
        time: day.format('hA'),
        isHideItem: true,
        stage_details: [],
        grouped_sleep_in_day: [],
      },
    );
  }
  return items;
};

export const fillChartDataByDays = (chartData, rangeTime) => {
  const { from_date } = rangeTime || {};
  const days = getDurationTime(rangeTime);
  const fromDateMoment = moment(from_date, DATE_FORMAT);
  const chartItemCloned = cloneDeep(chartData[0]);
  const items = [];

  for (let i = 0; i < days; i++) {
    const day = fromDateMoment.clone().add(i, 'd');
    const chartItem = chartData.find(item => day.format('MM-DD-YYYY') === item.day);
    if (chartItem) {
      chartItem.time = moment(chartItem.day, 'MM-DD-YYYY').format('MMM D');
    }
    items.push(
      chartItem || {
        ...chartItemCloned,
        time: day.format('MMM D'),
        isHideItem: true,
        stage_details: [],
        grouped_sleep_in_day: [],
      },
    );
  }
  return items;
};

export const fillChartDataByWeeks = (chartData, rangeTime) => {
  const items = [];
  const weeks = getWeeksInRange(rangeTime);
  const chartItemCloned = cloneDeep(chartData[0]);

  for (let week of weeks) {
    const chartItem = chartData.find(item => week.group_name === item.group_name);
    if (chartItem) {
      const startMM = moment(chartItem.start, DATE_FORMAT);
      const endMM = moment(chartItem.end, DATE_FORMAT);
      chartItem.time = getWeekLabel(startMM, endMM);
    }
    items.push(
      chartItem || {
        ...chartItemCloned,
        ...week,
        time: week.label,
        isHideItem: true,
        stage_details: [],
        grouped_sleep_in_day: [],
      },
    );
  }
  return items;
};

export const fillChartDataByMonths = (chartData, rangeTime) => {
  const { from_date, to_date } = rangeTime || {};
  const items = [];
  const chartItemCloned = cloneDeep(chartData[0]);
  const startDateMM = moment(from_date, DATE_FORMAT);
  const startDateMMMonth = moment(startDateMM).startOf('month');
  const endDateMM = moment(to_date, DATE_FORMAT);

  while (startDateMMMonth.isSameOrBefore(endDateMM)) {
    const chartItem = chartData.find(item => startDateMMMonth.format('YYYY-MM') === item.group_name);
    if (chartItem) {
      chartItem.time = moment(chartItem.start, DATE_FORMAT).format('MMM');
    }
    items.push(
      chartItem || {
        ...chartItemCloned,
        time: startDateMMMonth.format('MMM'),
        isHideItem: true,
        stage_details: [],
        grouped_sleep_in_day: [],
      },
    );
    startDateMMMonth.add(1, 'month');
  }

  return items;
};

export const fillChartDataByPeriodTime = (chartData, rangeTime) => {
  switch (rangeTime.period_group) {
    case PERIOD_GROUP.HOURLY:
      return fillChartDataByHours(chartData, rangeTime);
    case PERIOD_GROUP.DAILY:
      return fillChartDataByDays(chartData, rangeTime);
    case PERIOD_GROUP.WEEKLY:
      return fillChartDataByWeeks(chartData, rangeTime);
    default:
      return fillChartDataByMonths(chartData, rangeTime);
  }
};

// Calculate total in_bed and asleep times based on input array of sleep stages and their durations.

const calculateSleepDurationConnectedNoStage = data => {
  const hasStage = data.some(({ sleep_stage }) => sleep_stage !== SLEEP_STAGE_KEYS.asleep);

  if (hasStage) {
    const sleepStages = {
      awake: 0,
      core: 0,
      rem: 0,
      deep: 0,
      asleep: 0,
      in_bed: 0,
    };
    data.forEach(stage => {
      if (stage.sleep_stage in sleepStages) {
        sleepStages[stage.sleep_stage] += stage.duration;
      }
    });
    const asleep = sleepStages.core + sleepStages.rem + sleepStages.deep + sleepStages.asleep;
    const in_bed = sleepStages.awake + sleepStages.in_bed + asleep;
    const result = [
      { sleep_stage: 'in_bed', duration: in_bed },
      { sleep_stage: 'asleep', duration: asleep },
    ];
    return result.filter(({ duration }) => duration > 0);
  } else {
    return data;
  }
};

const calculateSleepDurationConnectedStage = data => {
  const isAllInBed = data.every(({ sleep_stage }) => sleep_stage === SLEEP_STAGE_KEYS.in_bed);
  const onlyHasInBedAndAsleep = data.every(
    item => item.sleep_stage === SLEEP_STAGE_KEYS.in_bed || item.sleep_stage === SLEEP_STAGE_KEYS.asleep,
  );

  return data.filter(
    ({ sleep_stage }) => isAllInBed || onlyHasInBedAndAsleep || sleep_stage !== SLEEP_STAGE_KEYS.in_bed,
  );
};

const calculateSleepTimeConnectedNoStage = data => {
  function convertSleepData(input) {
    const allInBed = input.every(stage => stage.sleep_stage === SLEEP_STAGE_KEYS.in_bed);
    const allAsleep = input.every(stage => stage.sleep_stage === SLEEP_STAGE_KEYS.asleep);

    const output = [];
    let inBed = {
      sleep_stage: 'in_bed',
      duration: 0,
      start_time: input[0].start_time,
      end_time: input[input.length - 1].end_time,
    };

    let asSleepSegments = [];
    let currentSegment = null;
    const listExceptInbed = input.filter(({ sleep_stage }) => sleep_stage !== 'in_bed');
    for (let i = 0; i < listExceptInbed.length; i++) {
      let stage = listExceptInbed[i];

      // Add duration to the total in_bed duration
      inBed.duration += stage.duration;

      // Check for sleep stages other than 'awake'
      if (stage.sleep_stage !== 'awake') {
        if (!currentSegment) {
          currentSegment = {
            sleep_stage: 'asleep',
            duration: 0,
            start_time: stage.start_time,
            end_time: stage.end_time,
          };
        }
        currentSegment.duration += stage.duration;
        currentSegment.end_time = stage.end_time;
      } else {
        if (currentSegment) {
          asSleepSegments.push(currentSegment);
          currentSegment = null;
        }
      }
    }

    // Push the last segment if it exists
    if (currentSegment) {
      asSleepSegments.push(currentSegment);
    }

    // Add the in_bed data to the output
    if (!allAsleep) {
      output.push(inBed);
    }

    // Add the as_sleep segments to the output
    if (!allInBed) {
      output.push(...asSleepSegments);
    }
    return output;
  }

  return data.map(item => {
    return convertSleepData(item);
  });
};

const calculateSleepTimeConnectedStage = data => {
  return data.map(arr => {
    const onlyHasInBedAndAsleep = arr.every(
      item => item.sleep_stage === SLEEP_STAGE_KEYS.in_bed || item.sleep_stage === SLEEP_STAGE_KEYS.asleep,
    );

    if (onlyHasInBedAndAsleep) {
      return arr;
    }

    return arr.filter(({ sleep_stage }) => sleep_stage !== SLEEP_STAGE_KEYS.in_bed);
  });
};

export const getStartEndSleeps = (groupSleeps, isHourlyTimeStage = false) => {
  const allStage = (groupSleeps || []).flatMap(items => (Array.isArray(items) ? items : [items]));
  let startMM = null;
  let endMM = null;

  allStage.forEach(({ start_time, end_time, sleep_stage }) => {
    if (isHourlyTimeStage && sleep_stage === SLEEP_STAGE_KEYS.in_bed) return;
    const newStart = moment(start_time, DATE_TIME_SLEEP);
    const newEnd = moment(end_time, DATE_TIME_SLEEP);

    if (startMM === null) {
      startMM = newStart;
    } else if (startMM.unix() > newStart.unix()) {
      startMM = newStart;
    }

    if (endMM === null) {
      endMM = newEnd;
    } else if (newEnd.unix() > endMM.unix()) {
      endMM = newEnd;
    }
  });
  return { startMM, endMM };
};

const getHourFromTime = time => {
  const [h, m] = time.split(':').map(Number);
  return Number(Number(h + m / 60).toFixed(2));
};

export const getRangeOfSleep = (startMM, endMM, selectedDate) => {
  if (!startMM || !endMM) return [];

  const isStartSameSelectedDate = startMM.format('YYYY-MM-DD') === selectedDate;
  const hourStart = getHourFromTime(startMM.format('HH:mm'));
  const duration = +endMM.diff(startMM, 'm');
  const hourEnd = hourStart + convertMinToHour(duration);

  return [hourStart, hourEnd].map(i => {
    if (isStartSameSelectedDate) return i + 12;
    if (i > 12) return i - 12;
    return i;
  });
};

export const getValueDurationTimeSleep = (durationValue, timeValue, viewBy) => {
  if (viewBy === SLEEP_VIEW.DURATION) {
    if (durationValue) {
      return +durationValue / 60;
    }
  }
  if (viewBy === SLEEP_VIEW.TIME) {
    function convertTimeLabel(time) {
      const momentTime = moment(time, 'HH:mm');
      const hour = momentTime.format('h');
      const minute = momentTime.format('mm');
      const ampm = momentTime.format('A');
      return { hour, minute, ampm };
    }
    if (timeValue) {
      const { hour, minute, ampm } = convertTimeLabel(timeValue);
      const timeText = `${hour} ${ampm}`;
      const itemRange = TIME_RANGE_DATE.find(item => item.label === timeText) || {};
      const minValue = Number(minute) / 60;
      const hourValue = itemRange.value || 0;
      let result = hourValue - minValue;
      return result;
    }
  }
  return null;
};

export const sortByOrder = (array, property, order) => {
  return array.sort((a, b) => {
    return order.indexOf(a[property]) - order.indexOf(b[property]);
  });
};

// Find domain for sleep chart
const getDivisibleValue = value => {
  const divisors = [7, 6, 5, 4];
  for (let i = 0; i < divisors.length; i++) {
    const divisor = divisors[i];
    const result = value / divisor;
    if (Number.isInteger(result)) {
      if (value > 10) {
        if (result >= 3) {
          return result;
        }
      } else {
        if (result >= 2) {
          return result;
        }
      }
    }
  }
  return 0;
};

const generateSequence = (gap, max) => {
  const sequence = [];
  for (let i = 0; i <= max; i += gap) {
    sequence.push(i);
  }
  return sequence;
};

export const findValueInRange = (minValue, maxValue) => {
  const min = Math.floor(minValue);
  const max = Math.ceil(maxValue);
  const value = max - min;
  if (value <= 4) {
    return { gap: 1, max: value, ticks: generateSequence(1, value) };
  }
  if (value <= 6) {
    return { gap: 2, max: 6, ticks: [0, 2, 4, 6] };
  }
  const result = getDivisibleValue(value);
  if (result > 0) {
    return { gap: result, max, ticks: generateSequence(result, max) };
  } else {
    return findValueInRange(min, max + 1);
  }
};
