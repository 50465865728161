import styled from 'styled-components';

export const GoalCountdownOverviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ bg }) => `${bg.background}`};
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .confetti {
    position: absolute;
    right: 0;
    top: 0;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;

    .countdown-icon {
      font-size: 24px;

      .completed-icon {
        path {
          :first-child {
            fill: ${({ bg }) => bg.border};
          }
        }
      }
    }

    .countdown-name {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      display: flex;
      align-items: center;
      margin-right: 12px;
      word-break: break-all;

      color: #202353;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .time-counting-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 0px 12px;

    width: 50px;
    height: 40px;

    background: ${({ bg }) => `${bg.secondBackground}`};
    border-radius: 8px;

    .time {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #202353;
    }
    .label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 150%;
      color: #7b7e91;
    }
  }
`;
