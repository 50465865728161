import React, { useState, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import upperCase from 'lodash/upperCase';
import * as S from './styles';
import Select from 'react-select';
import { CURRENCIES } from 'constants/commonData';
import { validateNumberOnly } from 'utils/validations';
import NumberFormat from 'react-number-format';

const CURRENCY_OPTIONS = map(CURRENCIES, obj => obj);

const initDefaultCurrency = origin => {
  const obj = CURRENCY_OPTIONS.find(item => item.value === origin) || CURRENCY_OPTIONS[0];

  return { ...obj };
};

export default ({ data, showError, onChange, errorField }) => {
  const [currencyValue, setCurrencyValue] = useState(initDefaultCurrency(data.currency));
  const [amountValue, setAmountValue] = useState(isNaN(parseInt(data.amount)) ? '' : data.amount);

  const isText = useMemo(() => {
    return ['CHF', 'SEK', 'NOK', 'DH'].includes(upperCase(get(currencyValue, 'symbol', '')));
  }, [currencyValue]);

  const isNOK = useMemo(() => {
    return upperCase(get(currencyValue, 'symbol', '')) === 'NOK';
  }, [currencyValue]);

  const errorMessage = useMemo(() => {
    const minPrice = isNOK ? 3 : 1;
    if (!!showError && errorField) {
      const number = toNumber(amountValue);

      return !number || number < minPrice
        ? `Minimum value is ${currencyValue.symbol}${isText ? ' ' : ''}${minPrice}`
        : number > 5000
        ? `Maximum value is ${currencyValue.symbol}${isText ? ' ' : ''}5,000`
        : '';
    }

    return '';
  }, [showError, amountValue, errorField, isText, isNOK]);

  const handleCurrencyChange = newValue => {
    setCurrencyValue(newValue);
    onChange({ currency: newValue.value, amount: amountValue });
  };

  const handleAmountChange = useCallback(
    values => {
      const { value } = values;
      setAmountValue(value);
      onChange({ currency: currencyValue.value, amount: value });
    },
    [amountValue],
  );

  return (
    <S.Wrapper>
      <Select
        options={[currencyValue]}
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        onChange={handleCurrencyChange}
        classNamePrefix="evfSelectBox"
        className="evfSelectBoxContainer"
        value={currencyValue}
        isSearchable={false}
        menuPosition="fixed"
        isDisabled={true}
        styles={{
          control: base => ({ ...base, minHeight: 42, height: 42, minWidth: 125 }),
        }}
      />
      <S.InputContainer invalidValue={!!errorMessage} isSpecial={isText}>
        <S.Symbol isCustom={isText}>{currencyValue.symbol}</S.Symbol>
        <NumberFormat
          thousandSeparator={true}
          value={amountValue}
          onKeyPress={validateNumberOnly}
          inputmode="numeric"
          decimalScale={2}
          onValueChange={handleAmountChange}
          isNumericString={true}
        />
        {showError && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      </S.InputContainer>
    </S.Wrapper>
  );
};
