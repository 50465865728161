import { Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const BodyMetricsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 25px;

  .right-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }

  .label {
    color: #202353;
    font-family: 'Open Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    cursor: default;
  }

  .search-input {
    width: 259px;
    height: 30px;

    input {
      padding-right: 30px;

      ::placeholder {
        padding-left: 2px;
      }
    }

    svg {
      path {
        fill: #a3a3b5;
      }
    }
  }
`;

export const SearchIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 30px;
  border-radius: 5px;
  background: #f0f0f2;
  border: none;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    path {
      fill: #202353;
    }
  }

  &:hover {
    background: #f0f1ff;

    svg {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const AddNewMetricPopup = styled(Popup)`
  &.ui.popup {
    background-color: transparent;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  &.ui.top.popup {
    margin: 0 0 8px !important;
  }

  &.ui.bottom.popup {
    margin: 8px 0 0 !important;
  }

  &.ui.top.right.popup {
    margin: 0 0x 8px 0 !important;
  }

  &.ui.bottom.right.popup {
    margin: 8px 0px 0 0 !important;
  }

  &::before {
    content: '';
    display: none;
  }
`;

export const PopupAddNewContent = styled.div`
  width: 141px;
  padding: 8px 0px;
  border-radius: 5px;
  background-color: #2d2e2d;
  box-shadow: unset;

  .add-new-metric-label {
    position: relative;

    ${({ hasPermission }) =>
      !hasPermission &&
      css`
        cursor: not-allowed;
      `}

    .features__tooltipUpgradePath {
      visibility: hidden;
      position: absolute;
      left: 294px;
      bottom: 25px;
      z-index: 4;
      opacity: 1;
      transition: opacity 0.35s linear;
    }

    :hover {
      .features__tooltipUpgradePath {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.75s ease, left 0.25s linear;

        .tooltip_box:before {
          top: 20px;
          left: -4px;
          right: unset;
          bottom: unset;
          transform: rotate(135deg);
        }

        .tooltip_box::after {
          position: absolute;
          content: '';
          top: 0;
          left: -14px;
          width: 20px;
          height: 150px;
          background-color: transparent;
        }
      }
    }
  }
`;

export const PopupAddNewItem = styled.button`
  width: 100%;
  border: none;
  padding: 9px 16px;
  background-color: #2d2e2d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    background-color: #535353;
  }
`;

export const PopupAddNewLabel = styled.div`
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const AddNewMetricTrigger = styled.button`
  border: none;
  border-radius: 5px;
  background: #f0f0f2;
  display: flex;
  width: 120px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 15px;

  .label {
    color: #202353;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    width: 53px;
  }

  .icon-plus,
  .icon-down {
    width: 16px;
    height: 16px;
  }

  &.active,
  &:hover {
    background: #f0f1ff;

    .label {
      color: #5158cf;
    }

    .icon-plus {
      path {
        fill: #5158cf;
      }
    }

    .icon-down {
      path {
        fill: #5158cf;
      }
    }
  }
`;
