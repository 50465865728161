import styled from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';

export const CustomModal = styled(ConfirmModal)`
  &.ui.modal.remove-trigger-modal {
    width: 467px !important;
    min-height: 260px !important;

    > :first-child {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    > :last-child {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      padding: 1px 30px 30px 0 !important;
    }

    .close-button {
      top: -8px;
      right: -8px;

      img {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .confirm-content-header {
      padding: 30px 30px 0 30px;
    }

    .confirm-header-image {
      width: 25px !important;
      height: 25px !important;
    }

    .confirm-header-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .confirm-content-body {
      padding: 15px 30px 29px 30px !important;
    }

    .confirm-content-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #323c47;
    }

    .confirm-no-button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #818da1 !important;

      min-width: unset !important;
      width: 102px !important;
      height: 30px !important;
      margin-left: unset !important;
      padding: unset !important;
      border-radius: 5px !important;
    }

    .confirm-yes-button {
      box-shadow: unset !important;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #ffffff !important;

      width: 111px !important;
      height: 30px !important;
      margin-left: 5px !important;
      padding: unset !important;
      border: unset !important;
      border-radius: 5px !important;
    }
  }
`;
