import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

import DropDown from 'shared/Dropdown/Basic';

import * as S from './style';
import { FormatLayout } from '../../constants';

const TriggerIcon = ({ format }) => {
  return (
    <S.LayoutOptions>
      <S.ViewType className={format}></S.ViewType>
      <S.LayoutTitle>Format</S.LayoutTitle>
      <Icon name="chevron down" size="mini" />
    </S.LayoutOptions>
  );
};

const SettingLayout = ({ format, onChangeFormat }) => {
  const [isShow, setShowDropdown] = useState(false);
  const [formatLayout, setFormat] = useState(format);

  const cancelUpdate = () => {
    setShowDropdown(false);
  };

  const handleSelectedType = format => () => {
    setShowDropdown(true);
    setFormat(format);
  };

  const handleUpdate = () => {
    setShowDropdown(false);
    onChangeFormat && onChangeFormat(formatLayout);
  };

  return (
    <S.DropdownWrapper>
      <DropDown
        className="evf-dropdown evf-dropdown--basic"
        direction="left"
        alwaysShow={isShow}
        triggerIcon={({ open }) => <TriggerIcon className="trigger-icon" active={!!open} format={format} />}
      >
        <S.Arrow />
        <S.OptionsWrapper>
          <S.Title>Choose card design</S.Title>
          <S.TypesWrapper>
            <div onClick={handleSelectedType(FormatLayout.LAGER)}>
              <S.Type className={FormatLayout.LAGER} isActive={FormatLayout.LAGER === formatLayout} />
              <S.TypeName>Large Cards</S.TypeName>
            </div>
            <div onClick={handleSelectedType(FormatLayout.SQUARE)}>
              <S.Type className={FormatLayout.SQUARE} isActive={FormatLayout.SQUARE === formatLayout} />
              <S.TypeName>Squares</S.TypeName>
            </div>
            <div onClick={handleSelectedType(FormatLayout.NARROW)}>
              <S.Type className={FormatLayout.NARROW} isActive={FormatLayout.NARROW === formatLayout} />
              <S.TypeName>Narrow cards</S.TypeName>
            </div>
            <div onClick={handleSelectedType(FormatLayout.LIST)}>
              <S.Type className={FormatLayout.LIST} isActive={FormatLayout.LIST === formatLayout} />
              <S.TypeName>List</S.TypeName>
            </div>
          </S.TypesWrapper>
          <S.ActionsWrapper>
            <Button onClick={cancelUpdate}>Cancel</Button>
            <Button purple onClick={handleUpdate}>
              Update
            </Button>
          </S.ActionsWrapper>
        </S.OptionsWrapper>
      </DropDown>
    </S.DropdownWrapper>
  );
};

export default SettingLayout;
