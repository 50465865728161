import { analytics } from 'database/firebase';
import { ANALYTICS_EVENTS } from './constants';

export const setUserProperties = user => {
  analytics.setUserProperties({
    id: user._id,
    full_name: user.full_name,
    email: user.email,
  });
};

export const logViewExcercise = params => {
  analytics.logEvent(ANALYTICS_EVENTS.ViewExercise, params);
};
export const logSignUp = params => {
  analytics.logEvent(ANALYTICS_EVENTS.SignUp, params);
};
export const logSignUpFacebook = params => {
  analytics.logEvent(ANALYTICS_EVENTS.SignUpFacebook, params);
};
export const logSignIn = params => {
  analytics.logEvent(ANALYTICS_EVENTS.Login, params);
};

export const logSearchExcercise = query => {
  analytics.logEvent(ANALYTICS_EVENTS.SearchExercise, query);
};
