import React from 'react';
import Select from 'react-select';
import Avatar from 'react-avatar';
import { Icon } from 'semantic-ui-react';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

import * as S from './style';

const CustomGroupHeading = ({ selectProps, children, owner, loaded, cloudfrontList }) => {
  // Click into head
  const handleClickHead = item => () => {
    selectProps.onChange(item);
    selectProps.onMenuClose();
  };

  return (
    <S.GroupWrapper {...selectProps}>
      <S.GroupHeader onClick={handleClickHead(owner)}>
        <Avatar
          name={children}
          size="37"
          src={convertS3UrlToCloudFrontUrl(selectProps.options[0].avatar, cloudfrontList, true)}
          color={owner.color}
        />
        <S.TeamName>
          {children} <span>(you)</span>
        </S.TeamName>
      </S.GroupHeader>
      <S.GroupTitle>
        <span>Your Team</span>
      </S.GroupTitle>
    </S.GroupWrapper>
  );
};

const SingleItem = ({ innerProps, data }) => {
  return (
    <S.ValueItem {...innerProps}>
      <S.ValueSelected>{data.isMe ? 'Your Inbox' : data.label}</S.ValueSelected>
    </S.ValueItem>
  );
};

const Option = ({ innerProps, data, cloudfrontList }) => {
  return (
    <S.SingleItemWrapper {...innerProps}>
      <Avatar
        name={getUserShortName(data)}
        size="37"
        src={convertS3UrlToCloudFrontUrl(data.avatar, cloudfrontList, true)}
        color={data.color}
      />
      <S.TeamName>
        {data.label} {data.isRemoved && <S.RemovedLabel>(removed)</S.RemovedLabel>}
      </S.TeamName>
    </S.SingleItemWrapper>
  );
};

const Teammates = ({ options, onSelect, selected, owner, loaded, cloudfrontList }) => {
  return (
    <S.TeamWrapper>
      <Select
        options={options}
        components={{
          GroupHeading: innerProps => (
            <CustomGroupHeading {...innerProps} owner={owner} cloudfrontList={cloudfrontList} />
          ),
          Option: p => <Option {...p} cloudfrontList={cloudfrontList} />,
          IndicatorSeparator: null,
          ClearIndicator: null,
          SingleValue: SingleItem,
          DropdownIndicator: () => <Icon className="dropdown" />,
        }}
        value={selected}
        onChange={onSelect}
        classNamePrefix="evfSelectBox"
        className="evfSelectBoxContainer"
        isSearchable={false}
        isDisabled={!loaded}
      />
    </S.TeamWrapper>
  );
};

export default Teammates;
