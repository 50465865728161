import React, { Component } from 'react';
import { Mixpanel } from 'utils/mixplanel';
import ColorPreview from './ColorPreview';
import * as S from './style';
import { THEMES } from './constants';
import _ from 'lodash';

export default class ColorThemes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryColor: _.get(props, 'originalTheme.primaryColor', THEMES[0].primaryColor).toUpperCase(),
      secondaryColor: _.get(props, 'originalTheme.secondaryColor', THEMES[0].secondaryColor).toUpperCase(),
      theme: { ..._.get(props, 'originalTheme', THEMES[0]) },
      isCustomTheme: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCancelThemeColor(nextProps);
    const primaryColorOrigin = _.get(nextProps, 'originalTheme.primaryColor');
    const secondaryColorOrigin = _.get(nextProps, 'originalTheme.secondaryColor');
    const {
      primaryColor,
      secondaryColor,
      theme: { preset },
    } = this.state;
    if ((primaryColor !== primaryColorOrigin || secondaryColorOrigin !== secondaryColor) && !preset) {
      this.setState({
        primaryColor: primaryColorOrigin,
        secondaryColor: secondaryColorOrigin,
      });
    }
  }

  componentDidMount() {
    this.setState({
      isCustomTheme: checkIfCustomTheme(this.state.theme, THEMES),
    });
  }

  handleCancelThemeColor = data => {
    const {
      discard,
      getCustomBrandingData,
      onChangeDiscard,
      activeIndex,
      setCurrentIndex,
      setScreenActive,
      setTheme,
    } = data;

    if (discard) {
      getCustomBrandingData().finally(() => {
        this.setState({
          primaryColor: _.get(data, 'originalTheme.primaryColor', THEMES[0].primaryColor),
          secondaryColor: _.get(data, 'originalTheme.secondaryColor', THEMES[0].secondaryColor),
          theme: { ..._.get(data, 'originalTheme', THEMES[0]) },
          isCustomTheme: false,
        });

        this.props.onCheckSaving(false);
        onChangeDiscard(false);
        setTheme({ ..._.get(data, 'originalTheme', THEMES[0]) });
        setCurrentIndex(activeIndex);
        setScreenActive(activeIndex);
      });
    }
  };

  handleOnPrimaryColorChange = (color, isInput) => {
    const { theme } = this.state;
    const newValue = { ...theme, primaryColor: handleFormatInput(color) };

    this.setState({
      primaryColor: color,
      theme: newValue,
      isCustomTheme: checkIfCustomTheme(newValue, THEMES),
    });
    if (isInput) {
      this.props.setTheme(newValue);
    }

    this.props.onCheckSaving(true);
  };

  handleOnSecondaryColorChange = (color, isInput) => {
    const { theme } = this.state;
    const newValue = { ...theme, secondaryColor: handleFormatInput(color) };
    this.setState({
      secondaryColor: color,
      theme: newValue,
      isCustomTheme: checkIfCustomTheme(newValue, THEMES),
    });
    if (isInput) {
      this.props.setTheme(newValue);
    }

    this.props.onCheckSaving(true);
  };

  handleSyncColorOnClose = () => {
    this.props.setTheme(this.state.theme);
  };

  componentDidUpdate(prevProps) {
    const { loading, originalTheme } = this.props;

    if (!loading && prevProps.loading) {
      this.setState({
        theme: { ...originalTheme },
      });
    }
  }

  handleSelectTheme = event => {
    const { themeId } = event.currentTarget.dataset;
    const selectedTheme = THEMES.find(item => item._id === themeId);

    if (selectedTheme) {
      const { theme } = this.state;

      if (
        selectedTheme.primaryColor.toLowerCase() !== theme.primaryColor.toLowerCase() ||
        selectedTheme.secondaryColor.toLowerCase() !== theme.secondaryColor.toLowerCase()
      ) {
        this.setState({
          theme: { ...selectedTheme },
          primaryColor: selectedTheme.primaryColor.toUpperCase(),
          secondaryColor: selectedTheme.secondaryColor.toUpperCase(),
          isCustomTheme: false,
        });

        this.props.setTheme(selectedTheme);
      }
    }
  };

  handleSave = () => {
    const { onCheckSaving } = this.props;

    if (this.isSaving) {
      return false;
    }

    const { theme } = this.state;
    const data = {
      primary_color: theme.primaryColor,
      secondary_color: theme.secondaryColor,
    };
    const localData = {
      originalTheme: { ...theme },
    };

    this.isSaving = true;

    this.props.updateCustomBranding(data, localData).finally(() => {
      Mixpanel.track('advance_custom_brand_set_custom_color');
      this.isSaving = false;
      onCheckSaving(false);
    });
  };

  renderSaveButton = () => {
    const { loading, originalTheme } = this.props;
    const { theme } = this.state;
    let hexColorRegExp = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i; // valid when string is valid hex color code with 3 or 6 values
    const primary_valid = hexColorRegExp.test(theme.primaryColor); // check if primary color input is valid
    const secondary_valid = hexColorRegExp.test(theme.secondaryColor); // check if secondary color input is valid

    if (
      !loading &&
      (theme.primaryColor.toLowerCase() !== originalTheme.primaryColor.toLowerCase() ||
        theme.secondaryColor.toLowerCase() !== originalTheme.secondaryColor.toLowerCase())
    ) {
      this.props.onCheckSaving(true);
      if (!primary_valid || !secondary_valid) {
        // disable save button if primary and secondary color code is not valid
        return <S.SaveButton disabled>Save</S.SaveButton>;
      } else {
        return <S.SaveButton onClick={this.handleSave}>Save</S.SaveButton>;
      }
    } else {
      this.props.onCheckSaving(false);
      return <S.SaveButton disabled>Save</S.SaveButton>;
    }
  };

  render() {
    const { primaryColor, secondaryColor, theme, isCustomTheme } = this.state;
    const { sideBarVisible } = this.props;

    return (
      <S.ColorThemesContainer>
        <S.ThemeSelectContainer>
          <div>
            <S.ColorTitle>Primary Color</S.ColorTitle>
            <ColorPreview
              suffix="primary"
              color={primaryColor}
              onChange={this.handleOnPrimaryColorChange}
              onSyncColor={this.handleSyncColorOnClose}
            />
          </div>
          <div>
            <S.ColorTitle>Secondary Color</S.ColorTitle>
            <ColorPreview
              suffix="secondary"
              color={secondaryColor}
              onChange={this.handleOnSecondaryColorChange}
              onSyncColor={this.handleSyncColorOnClose}
            />
          </div>
          <CustomThemeBall primaryColor={primaryColor} secondaryColor={secondaryColor} isCustomTheme={isCustomTheme} />
          <S.ColorBallsContainer>
            <S.ColorBallsHeader>Preset color themes</S.ColorBallsHeader>
            <S.ColorBallsSliderWrapper sideBarVisible={sideBarVisible}>
              <S.ColorBallsSliderContainer>
                {_.map(THEMES, (item, index) => (
                  <ThemeBall key={item._id} item={item} index={index} theme={theme} onClick={this.handleSelectTheme} />
                ))}
              </S.ColorBallsSliderContainer>
            </S.ColorBallsSliderWrapper>
          </S.ColorBallsContainer>
        </S.ThemeSelectContainer>
        {this.renderSaveButton()}
      </S.ColorThemesContainer>
    );
  }
}

const ThemeBall = ({ item, index, theme, onClick }) => {
  return (
    <S.ThemeItem
      index={index}
      active={
        item.primaryColor.toLowerCase() === theme.primaryColor.toLowerCase() &&
        item.secondaryColor.toLowerCase() === theme.secondaryColor.toLowerCase()
      }
      onClick={onClick}
      data-theme-id={item._id}
    >
      <S.ThemeContent {...item} />
    </S.ThemeItem>
  );
};

const CustomThemeBall = ({ primaryColor, secondaryColor, isCustomTheme }) => {
  return (
    <S.ThemeItem
      className={isCustomTheme ? 'active' : ''}
      index={0}
      active={isCustomTheme}
      data-theme-id={'custom'}
      customTheme={true}
    >
      <S.ThemeContent
        primaryColor={primaryColor.includes('#') ? primaryColor : `#${primaryColor}`}
        secondaryColor={secondaryColor.includes('#') ? secondaryColor : `#${secondaryColor}`}
      />
    </S.ThemeItem>
  );
};

const handleFormatInput = color => {
  if (color.includes('#')) {
    return color;
  } else {
    return `#${color}`;
  }
};

const checkIfCustomTheme = (theme, defaultThemes) => {
  let isCustomTheme = true;
  let primaryColor = theme.primaryColor.toLowerCase();
  let secondaryColor = theme.secondaryColor.toLowerCase();
  defaultThemes.forEach(el => {
    if (
      el.primaryColor.toLowerCase() === handleFormatInput(primaryColor) &&
      el.secondaryColor.toLowerCase() === handleFormatInput(secondaryColor)
    ) {
      isCustomTheme = false;
    }
  });
  return isCustomTheme;
};
