import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { toggleModal } from 'actions/modal';
import ChooseWorkoutModal from 'components/ChooseWorkoutModal';
import { axiosInstance } from 'configs/request';
import {
  addWorkoutToStudioProgram,
  assignWorkoutToStudioProgram,
  deleteStudioProgramWorkout,
  fetchStudioProgramWorkoutsByWeek,
  getStudioProgramWorkoutDetail,
  saveStudioProgramWorkoutToLibrary,
  updateStudioProgramWorkout,
} from 'redux/studio-program/actions';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import CreateWorkoutTemplateAIDemo from 'components/CreateWorkoutTemplateAIDemo';
import { TAGS_TYPE, WORKOUT_AI_BUILDER_PAGES, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { getMostRecentTagsList } from 'redux/tags/actions';
import { omitEmptyRequestParams } from 'utils/commonFunction';

const DEFAULT_PARAMS = { page: 1, per_page: 10 };

class AssignWorkoutModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 0,
      workouts: [],
      isCreateNew: false,
      isSubmitting: false,
    };
    this.search = '';
    this.tags = [];
    this.searchDebounce = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.searchWorkout();
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.WORKOUT });
  }

  searchWorkout = searchParams => {
    const q = _.get(searchParams, 'q');
    const tags = _.get(searchParams, 'tags', []);
    const params = omitEmptyRequestParams({
      ...DEFAULT_PARAMS,
      q,
      search_tags: _.join(
        tags.map(tag => tag._id),
        ',',
      ),
    });
    this.setState({ loading: true, searching: true });

    axiosInstance
      .get('/api/workout/v2/list', { params })
      .then(response => {
        const { data, total } = response.data;
        this.setState({
          workouts: data,
          total,
          page: params.page,
          hasMore: data.length < response.data.total,
          loading: false,
        });
      })
      .catch(() => {});
  };

  onSearch = params => {
    this.search = params.q;
    this.tags = params.tags;
    this.searchWorkout(params);
  };

  loadMore = () => {
    const { hasMore, page, isLoading } = this.state;
    const params = omitEmptyRequestParams({
      page: page + 1,
      per_page: DEFAULT_PARAMS.per_page,
      q: this.search,
      search_tags: _.join(
        this.tags.map(tag => tag._id),
        ',',
      ),
    });

    if (hasMore && !isLoading) {
      this.setState({ loading: true });
      axiosInstance
        .get('/api/workout/v2/list', { params })
        .then(response => {
          const { data } = response.data;
          const newWorkouts = _.unionBy(this.state.workouts, data, '_id');
          this.setState({
            workouts: newWorkouts,
            total: response.data.total,
            page: params.page,
            hasMore: newWorkouts.length < response.data.total,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onSelect = workout => {
    this.setState({ isSubmitting: true });
    const { programId, weekIndex, dayIndex } = this.props;
    const bodyData = { workoutId: workout._id, programId, weekIndex, dayIndex };
    this.props
      .assignWorkoutToStudioProgram(bodyData)
      .then(() => this.props.toggleModal(false))
      .catch(() => this.setState({ isSubmitting: false }));
  };

  onClose = () => {
    this.props.toggleModal(false);
  };

  onCreateNew = () => {
    this.setState({ isCreateNew: true });
  };

  onSavedWithoutClose = workout => {
    const { weekIndex, dayIndex, programId } = this.props;
    const requestParams = { programId, workoutId: workout._id };
    const otherData = { weekIndex, dayIndex, programId };
    this.props.getStudioProgramWorkoutDetail(requestParams, true, otherData);
  };

  onSaveTheNewWorkout = workoutData => {
    const { weekIndex, dayIndex, programId } = this.props;
    const bodyData = {
      weekIndex,
      dayIndex,
      programId: programId,
      background: workoutData.background,
      title: workoutData.title,
      description: workoutData.description,
      sections: workoutData.sections,
      is_generated_by_ai: workoutData.is_generated_by_ai || false,
      conversion_id: workoutData.is_generated_by_ai ? workoutData.conversion_id || null : null,
      is_edited: workoutData.is_generated_by_ai ? workoutData.is_edited || false : undefined,
    };
    return this.props.addWorkoutToStudioProgram(bodyData).then(response => {
      const { data, created } = response.data;
      const createdWorkoutData = _.find(data.workouts, w => w._id === created);
      return createdWorkoutData;
    });
  };

  handleUpdateAssignment = workout => {
    const { weekIndex, dayIndex, programId, updateStudioProgramWorkout } = this.props;
    const { _id, title, description, sections, background } = workout || {};

    const bodyData = {
      weekIndex,
      dayIndex,
      programId,
      title: title,
      description: description,
      sections: sections,
      workoutId: _id,
      background: background,
    };

    return updateStudioProgramWorkout(bodyData).then(response => {
      const { data } = response.data;
      return _.find(data.workouts, w => w._id === bodyData.workoutId);
    });
  };

  handleSaveToLibrary = workout => {
    const { programId, saveStudioProgramWorkoutToLibrary } = this.props || {};
    const { _id } = workout || {};

    saveStudioProgramWorkoutToLibrary({ workoutId: _id, programId });
  };

  handleDeleteAssignment = workoutId => {
    const { weekIndex, dayIndex, programId, deleteStudioProgramWorkout } = this.props || {};

    deleteStudioProgramWorkout({ programId, weekIndex, dayIndex, workoutId });
  };

  handleEverfitAIClick = () => {
    const { toggleModal } = this.props;
    if (toggleModal) {
      toggleModal(
        true,
        <CreateWorkoutTemplateAIDemo
          onAssignment={this.onSaveTheNewWorkout}
          onUpdateAssignment={this.handleUpdateAssignment}
          onSaveToLibrary={this.handleSaveToLibrary}
          onAssignmentDelete={this.handleDeleteAssignment}
          pdfType="studio_program_workout"
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          onSavedWithoutClose={this.onSavedWithoutClose}
          workoutAIPage={WORKOUT_AI_BUILDER_PAGES.studio_program}
        />,
      );
    }
  };

  render() {
    const { isCreateNew, hasMore, loading, total, isSubmitting } = this.state;
    const { weekIndex, dayIndex, totalWeek, workoutTags, enableAIworkout } = this.props;

    if (isCreateNew) {
      return (
        <WorkoutDetailModal
          key="create-new-studio-program-workout"
          maxWeeks={totalWeek}
          onSave={this.onSaveTheNewWorkout}
          onSavedWithoutClose={this.onSavedWithoutClose}
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          fetchWorkoutsByWeek={fetchStudioProgramWorkoutsByWeek}
          date={Number(weekIndex) * 7 + Number(dayIndex)}
          pdfType="studio_program_workout"
        />
      );
    }

    return (
      <ChooseWorkoutModal
        workoutTags={workoutTags}
        workouts={this.state.workouts}
        onClose={this.onClose}
        onSearch={params => {
          this.search = params.q;
          this.tags = params.tags;
          this.searchDebounce(params);
        }}
        loadMore={this.loadMore}
        isLoading={loading}
        total={total}
        hasMore={hasMore}
        onSelect={this.onSelect}
        onCreateNew={this.onCreateNew}
        title="Find a Workout"
        displayOwner
        displayLastUsed
        disableSelectButton={isSubmitting}
        onEverfitAIClick={enableAIworkout ? this.handleEverfitAIClick : undefined}
      />
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      studioProgram: { workingStudio },
      workoutTags,
      permission,
    },
  } = state;
  const enableAIAllWorkoutBuilder = process.env.REACT_APP_AI_ENABLE_ALL_WORKOUT_BUILDER || false;
  const enableAIworkout = enableAIAllWorkoutBuilder && _.get(permission, 'workout_builder_ai', false);
  const totalWeek = workingStudio.studio_program_weeks.length;
  return { totalWeek, workoutTags, enableAIworkout };
};

const mapDispatch = dispatch => {
  return {
    addWorkoutToStudioProgram: bindActionCreators(addWorkoutToStudioProgram, dispatch),
    updateStudioProgramWorkout: bindActionCreators(updateStudioProgramWorkout, dispatch),
    saveStudioProgramWorkoutToLibrary: bindActionCreators(saveStudioProgramWorkoutToLibrary, dispatch),
    deleteStudioProgramWorkout: bindActionCreators(deleteStudioProgramWorkout, dispatch),
    assignWorkoutToStudioProgram: bindActionCreators(assignWorkoutToStudioProgram, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    push: bindActionCreators(push, dispatch),
    getStudioProgramWorkoutDetail: bindActionCreators(getStudioProgramWorkoutDetail, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(AssignWorkoutModal);
