import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  width: 355px;
  height: 110px;
  padding: 24px 13px;
  display: flex;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  canvas {
    max-width: 182px;
    max-height: 40px;
  }
`;

export const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &:first-child {
    width: 44%;
  }
  &:last-child {
    width: 56%;
    padding-left: 4px;
  }
  .__react_component_tooltip.app-tooltip {
    width: 228px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    padding: 15px 15px;
    text-align: left;
  }
`;

export const TextBoldWrapper = styled.span`
  margin-bottom: 15px;
`;

export const TextBold = styled.span`
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  color: #202353;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  &.reset {
    padding-left: 6px;
  }
  .total-amount {
    padding-left: 24px;
  }
  .total-amount.fr {
    padding-left: 70px;
  }
  .total-amount.nok {
    padding-left: 80px;
  }
  .total-amount.dh {
    padding-left: 54px;
  }
`;

export const Desc = styled.span`
  font-size: 11px;
  line-height: 100%;
  text-transform: unset;
  color: #202353;
  opacity: 0.5;
  padding-left: 6px;
  strong {
    text-transform: uppercase;
  }
`;

export const Currency = styled.span`
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  color: #202353;
  position: absolute;
  top: 5px;
  left: 5px;
`;

export const Wrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 50px;
  display: flex;
`;
