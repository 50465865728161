import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 505px;
    padding: 30px;

    .confirm-footer {
      padding: 30px 0 0;

      button {
        padding: 7px 42px;
      }
    }
  }
`;

export const ModalTitle = styled.p`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin: 0;
`;

export const ModalDesc = styled.p`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin: 0;
  padding-top: 5px;
  letter-spacing: -0.35px;
`;

export const ParticipantsList = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 1px;
  padding-top: 25px;

  .select-participants {
    border-radius: 4px;
    padding: 0 3px 0 10px;
    width: 364px;
    min-height: 69px;

    .isc__input {
      font-size: 12px;

      input {
        line-height: 18px;
      }
    }

    .isc__control {
      min-height: 53px;
      align-items: flex-start;

      .isc__value-container {
        padding: 10px 7px 0 0;
        margin-top: unset;
        /* max-height: 94px; */

        input {
          font-size: 12px;
          line-height: 18px;
        }

        .isc__multi-value__label {
          color: #444;
          font-size: 12px;
          line-height: 18px;
          padding-bottom: 2px;
          padding-top: 2px;

          strong {
            font-weight: 700;
          }
          span {
            font-weight: 400;
          }
        }
      }
    }

    .isc__menu {
      width: 364px;
      left: 0;
      padding: 0;
      padding-right: 5px;
      margin-top: 1px;

      .isc__menu-list::-webkit-scrollbar {
        width: 4px !important;
      }

      .multi-select__option {
        border-bottom: 1px solid #dcdcde;
        padding: 10px 0 8px 14px;

        :hover {
          background: #fafbfc;
        }

        .multi-select__option__label {
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 300px;
            white-space: nowrap;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .email,
          .totalMember {
            line-height: 16px;
            margin-top: -2px;
          }
        }
      }
    }
  }
`;

export const ParticipantsListLabel = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  width: 70px;
  padding-top: 7px;
`;

export const ModalButton = styled(Button)`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border: none;
  color: #fff;
  background-color: #5c5bbd;

  &:hover {
    background-color: #5659f7;
  }

  &:disabled {
    background-color: #bfbfbf;

    &:hover {
      background-color: #bfbfbf;
    }
  }
`;
