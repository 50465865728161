import React from 'react';
import { GlobalSearchClientsWrapper, GlobalSearchIcon } from './style';
import ClientAutocomplete from './components/ClientAutocomplete';
import { EVENT_CLOSE_TASK_REMINDER_POP_UP } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

export default class GlobalSearchClients extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keypress', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keypress', this.handleKeyPress);
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.onCloseSearch();
    }
  };

  handleKeyPress = event => {
    const ignoreTypes = ['INPUT', 'TEXTAREA'];
    if (ignoreTypes.indexOf(event.target.tagName) !== -1 || event.target.isContentEditable) {
      return;
    }
    if (!this.state.isSearching && event.code === 'KeyJ') {
      setTimeout(() => {
        this.onSearch();
      }, 0);
    }
  };

  onSearch = () => {
    window.dispatchEvent(EVENT_CLOSE_TASK_REMINDER_POP_UP);
    this.setState({ isSearching: true });
  };

  onCloseSearch = () => {
    this.setState({ isSearching: false });
  };

  handleSelectClient = client => {
    this.setState({ isSearching: false });
    this.props.push(`/home/client/${client._id}`);
  };

  render() {
    const { isSearching } = this.state;
    return (
      <GlobalSearchClientsWrapper ref={this.wrapperRef} data-tip data-for="search-clients-tooltip">
        {isSearching && (
          <ClientAutocomplete visible onSelectClient={this.handleSelectClient} onCloseSearch={this.onCloseSearch} />
        )}
        <GlobalSearchIcon onClick={this.onSearch} />
        <SharedTooltip content="Search clients" id="search-clients-tooltip" position="bottom" />
      </GlobalSearchClientsWrapper>
    );
  }
}
