import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { get, isObject } from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import { AddLeaderboard, ChooseTemplate, LeaderboardDetail, FormModal } from 'components/Leaderboard';
import ForumEmpty from 'components/ForumSection/components/Empty';
import NoConnected from 'components/ForumSection/components/NoConnected';
import Connected from 'components/ForumSection/components/Connected';
import { autoflowConnectForum, autoflowCheckInviteClient } from 'redux/autoflow/forum/actions';
import { Actions } from 'redux/trainer/actions';

import {
  autoflowFetchLeaderboards,
  autoflowCreateLeaderboard,
  autoflowUpdateLeaderboard,
  autoflowRemoveLeaderboard,
  autoflowGetLeaderboardClients,
  autoflowLeaderboardUnmount,
} from 'redux/autoflow/leaderboard/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import * as S from './style';
import { Helmet } from 'react-helmet';
import { convertUnit, roundUp } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

export function AutoflowLeaderboard(props) {
  const [numberClients, setNumberClients] = useState({});
  const { workingAutoflow, leaderboard, forumDetail } = props;
  const leaderboardData = leaderboard.toJS();
  const rankingTypes = props.rankingTypes.toJS();
  const [metricSetting, setMetricSetting] = useState([]);

  useEffect(() => {
    props.autoflowFetchLeaderboards(workingAutoflow._id);
    autoflowCheckInviteClient(workingAutoflow._id).then(response => setNumberClients(get(response, 'data.data')));

    return () => {
      props.autoflowLeaderboardUnmount();
    };
  }, []);

  useEffect(() => {
    props.getMetrics &&
      props.getMetrics().then(res => {
        if (res.data) {
          setMetricSetting(res.data.data);
        }
      });
  }, []);

  const __className = useMemo(() => {
    if (leaderboardData.loading) {
      return '';
    }

    if (leaderboardData.list[0]) {
      if (forumDetail) {
        return 'hasLeaderboardHasForum';
      }

      return 'hasLeaderboardNoForum';
    } else {
      if (forumDetail) {
        return 'noLeaderboardHasForum';
      }

      return 'empty';
    }
  }, [forumDetail, leaderboardData]);

  const onSaveLeaderboardData = body => {
    return props.autoflowCreateLeaderboard({ ...body, autoflow: workingAutoflow._id });
  };

  const onAddNewLeaderboard = () => {
    Mixpanel.track('autoflow_leaderboard_screen_add_new_leaderboard');
    props.toggleModal(true, <ChooseTemplate onClose={() => props.toggleModal(false)} onSave={onSaveLeaderboardData} />);
  };

  const doUpdateLeaderboard = newValue => {
    Mixpanel.track('autoflow_leaderboard_screen_edit_leaderboard');
    return props.autoflowUpdateLeaderboard({
      ...newValue,
      autoflow: workingAutoflow._id,
      leaderboardId: leaderboardData.list[0]._id,
    });
  };

  const onEditLeaderboard = () => {
    props.toggleModal(
      true,
      <FormModal
        onClose={() => props.toggleModal(false)}
        onSave={doUpdateLeaderboard}
        workingItem={leaderboardData.list[0]}
      />,
    );
  };

  const doRemoveLeaderboard = () => {
    props.autoflowRemoveLeaderboard({ leaderboardId: leaderboardData.list[0]._id });
  };

  const onClickRemove = () => {
    Mixpanel.track('autoflow_leaderboard_screen_remove_leaderboard');
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Leaderboard"
        content="Not the right leaderboard? All data for the leaderboard will be lost when the leaderboard is removed."
        onConfirm={doRemoveLeaderboard}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  const onLoadClients = requestData => {
    return props.autoflowGetLeaderboardClients({ ...requestData, leaderboardId: leaderboardData.list[0]._id });
  };

  const leaderboardClients = useMemo(() => {
    const { user, leaderboard } = props;

    const leaderboardData = leaderboard.toJS();

    const firstLeaderboard = leaderboardData.list[0];
    const defaultSetting = get(firstLeaderboard, 'unit_id');

    const metricSettingData = metricSetting.find(it => it.unique_code === 'weight');
    const setting =
      metricSettingData && firstLeaderboard && firstLeaderboard.ranking_type === 2
        ? metricSettingData.unit
        : get(user, 'preferences')['weight'];
    let { clients } = leaderboardData;
    const { list = [] } = clients;
    const convertData = list.map(it => {
      it.score = roundUp(
        defaultSetting && isObject(defaultSetting) && setting
          ? roundUp(convertUnit(it.score, defaultSetting, setting), 2)
          : it.score,
        2,
      );
      it.setting = setting;
      return it;
    });

    clients.list = convertData;
    return clients;
  }, [leaderboard]);

  return (
    <S.Wrapper className={__className}>
      <Helmet>
        <title>{workingAutoflow.name} - Leaderboard - Everfit</title>
      </Helmet>
      {forumDetail ? (
        <Connected
          forumDetail={forumDetail}
          autoflowConnectForum={props.autoflowConnectForum}
          autoflowId={workingAutoflow._id}
          numberClients={numberClients}
        />
      ) : leaderboardData.loading ? null : leaderboardData.list.length ? (
        <NoConnected
          autoflowConnectForum={props.autoflowConnectForum}
          autoflowId={workingAutoflow._id}
          numberClients={numberClients}
        />
      ) : (
        <ForumEmpty
          autoflowConnectForum={props.autoflowConnectForum}
          autoflowId={workingAutoflow._id}
          numberClients={numberClients}
        />
      )}
      {leaderboardData.loading ? null : leaderboardData.list.length ? (
        <LeaderboardDetail
          leaderboard={leaderboardData.list[0]}
          clients={leaderboardClients}
          rankingTypes={rankingTypes || []}
          onEdit={onEditLeaderboard}
          onRemove={onClickRemove}
          onLoadClients={onLoadClients}
        />
      ) : (
        <AddLeaderboard onAddNew={onAddNewLeaderboard} connected={!!forumDetail} />
      )}
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    user,
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
        leaderboard,
        forum,
      },
    },
  } = state;
  return {
    user,
    leaderboard,
    workingAutoflow,
    rankingTypes: state.rootReducer.leaderboard.get('rankingTypes'),
    forumDetail: forum.get('forumDetail'),
  };
};

const actions = {
  autoflowFetchLeaderboards,
  autoflowCreateLeaderboard,
  autoflowConnectForum,
  autoflowUpdateLeaderboard,
  autoflowRemoveLeaderboard,
  autoflowGetLeaderboardClients,
  autoflowLeaderboardUnmount,
  toggleConfirmModal,
  toggleModal,
  autoflowCheckInviteClient,
  getMetrics: Actions.getMetrics,
};

export default connect(mapState, actions)(AutoflowLeaderboard);
