import React from 'react';
import Avatar from 'react-avatar';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
import { convertToRaw } from 'draft-js';

import {
  getDuration,
  getMediaType,
  getUserShortName,
  mediaLog,
  getPresignedUploadUrlByParams,
  getExtension,
} from 'utils/commonFunction';
import VideoDuration from 'shared/VideoDuration';
import { FILE_VALIDATION, TYPES } from 'components/CommunityForum/constants';
import { ReactComponent as CameraIcon } from 'assets/icons/camera_grey_outline.svg';
import {
  CDN_URL,
  CONVERSION,
  FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO,
  FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO,
} from 'constants/commonData';
import MentionEditor from 'components/CommunityForum/components/MentionEditor';
import { GiphyPopup, ForumCommentTrigger } from 'shared/Giphy';
import DropZonePlaceholder from 'shared/DropZonePlaceholder';
import { ReactComponent as ReplyIcon } from 'assets/icons/reply-icon-black.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';

import * as S from './style';

class CommentInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.callbackFunction = null;
    this.state = {
      selectedFiles: [],
      hasText: false,
      comment: {},
      taggedPeople: [],
      tagAll: false,
      hasContent: '',
    };
    this.onlyCaption = this.props.onlyCaption === true || this.props.onlyCaption === undefined;
    this.className = `.${this.props.className} .DraftEditor-editorContainer`;
    this.observer = new MutationObserver(this.handleCheckTextChange);
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ hasText: true }, () => {
        if (this.props.autoFocus) {
          this.handleCommentFocus();
        }
      });
    } else {
      if (this.props.autoFocus) {
        this.handleCommentFocus();
      }
    }

    this.handleDetectedChangeDom();
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleCheckTextChange = () => {
    const targetNode = document.querySelector(this.className);
    const dataContent = targetNode && targetNode.textContent && targetNode.textContent.trim();

    this.setState({
      hasContent: dataContent,
      // comment: dataContent,
    });
  };

  handleCommentFocus = () => {};

  handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  };

  onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode > 36 && keyCode < 41) {
      // press arrow key
      event.stopPropagation();
    }
  };

  onSubmit = () => {
    const { onPost, replyMessage } = this.props;
    const { selectedFiles, hasText, comment, taggedPeople, tagAll, hasContent } = this.state;
    const tagged = taggedPeople.map(it => pick(it, ['_id', 'name']));

    if ((hasText && comment) || hasContent) {
      const value = comment;
      const trimValue = value.trimAny('↵').trim();
      if (trimValue) {
        onPost({ type: TYPES.TEXT, data: value.trim(), taggedPeople: tagged, tagAll }, replyMessage, () => {
          setTimeout(() => {
            this.callbackFunction && this.callbackFunction();
          }, 100);
          this.setState({
            comment: '',
            hasText: false,
          });
        });
        this.props.onGetNotifications();
      }
    } else {
      if (selectedFiles.length) {
        onPost({ type: TYPES.FILE, data: selectedFiles }, replyMessage);
        this.props.onGetNotifications();
        setTimeout(() => {
          this.callbackFunction && this.callbackFunction();
        }, 100);
        this.setState({ selectedFiles: [] });
      }
    }
  };

  onPaste = event => {
    event.preventDefault();
    let text = '';

    if (event.clipboardData && event.clipboardData.getData) {
      text = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      text = window.clipboardData.getData('Text');
    }

    document.execCommand('insertText', false, text);
    event.persist();
  };

  handleMultipleFileSelect = async event => {
    const { files } = event.target;

    const { selectedFiles } = this.state;
    const { onUpdateConfigsUpload } = this.props;

    let newFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const isFLV = getExtension(get(file, 'name', '')) === 'flv';
      let mimetype = '';
      if (isFLV) mimetype = 'video/x-flv';
      const { size, name, type = mimetype } = file;

      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file via forum comment',
      });

      const { uploadUrl } = await getPresignedUploadUrlByParams(file, {
        method: 'POST',
        url: '/api/file/gen-presigned-urls-comment',
        data: {
          files: [
            {
              name,
              mimetype: type,
              // metadata: await getMetadataMediaFile(file), TODO - Will open later
            },
          ],
        },
      });

      onUpdateConfigsUpload &&
        onUpdateConfigsUpload({
          method: 'PUT',
          url: uploadUrl,
          headers: { 'Content-Type': type },
          data: file,
        });

      if (
        (type && !FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.includes(name.split('.').pop().toLowerCase())) ||
        isEmpty(type)
      ) {
        toast.error(`Please use supported file types (${FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.join(', ')})`);
        return;
      }

      if (type && type.includes('video') && size > FILE_VALIDATION.MAX_SIZE_VIDEO_FORUM * CONVERSION.MB_TO_BYTE) {
        toast.error(`Please resize and upload a video smaller than ${FILE_VALIDATION.MAX_SIZE_VIDEO_FORUM} MB`);
        return;
      }

      if (type && type.includes('image') && size > FILE_VALIDATION.MAX_SIZE_IMAGE_FORUM * CONVERSION.MB_TO_BYTE) {
        toast.error(`Please upload an image smaller than ${FILE_VALIDATION.MAX_SIZE_IMAGE_FORUM} MB`);
        return;
      }

      const src = window.URL.createObjectURL(file);
      newFiles.push({ file, objectURL: src });

      if (type.includes('video')) {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function (event) {
          newFiles[i].dimensions = { width: video.videoWidth, height: video.videoHeight };
        };
        video.src = src;
      } else {
        const image = document.createElement('img');
        image.src = src;
        image.onload = event => {
          const { naturalWidth, naturalHeight } = event.target;
          newFiles[i].dimensions = { width: naturalWidth, height: naturalHeight };
        };
      }
    }

    this.setState({ selectedFiles: selectedFiles.concat([...newFiles]) });
  };

  handleDetectedChangeDom = () => {
    let options = {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true,
    };

    setTimeout(() => {
      const targetNode = document.querySelector(this.props.selectorDetectedInput);
      if (targetNode) {
        this.observer.observe(targetNode, options);
      }
    }, 500);
  };

  onDeleteSelectedFile = index => {
    const newFiles = this.state.selectedFiles.slice();
    newFiles.splice(index, 1);
    this.setState({ selectedFiles: newFiles });
  };

  onGifClick = gif => {
    const { replyMessage } = this.props;
    const url = get(gif, 'images.original.url');
    const height = get(gif, 'images.original.height');
    const width = get(gif, 'images.original.width');
    const id = get(gif, 'id');
    if (!isEmpty(url) && !isEmpty(id)) {
      this.props.onPost(
        {
          type: 'image/gif',
          data: {
            attachment: url,
            attachment_id: id,
            attachment_type: 'image/gif',
            attachment_source: 'giphy',
            attachment_height: height,
            attachment_width: width,
          },
        },
        replyMessage,
      );
    }
  };

  renderFileSelect = () => {
    const { hasText, hasContent } = this.state;
    const { isScrolling = false } = this.props;
    if (!hasText && !hasContent) {
      return (
        <>
          <GiphyPopup
            trigger={ForumCommentTrigger}
            position={!this.onlyCaption ? 'top right' : 'top center'}
            onGifClick={this.onGifClick}
            isScrolling={isScrolling}
          />
          <S.FileInputContainer
            className={classNames('select-files-input')}
            data-tip
            data-for="chat-input-has-text-tooltip"
          >
            <S.CameraButton>
              <CameraIcon />
            </S.CameraButton>

            <input
              type="file"
              accept={FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO}
              multiple={false}
              onChange={this.handleMultipleFileSelect}
            />
          </S.FileInputContainer>
        </>
      );
    } else {
      return <S.SubmitButton onClick={this.onSubmit} data-tip data-for="chat-submit-button-tooltip"></S.SubmitButton>;
    }
  };

  renderSelectedFiles = () => {
    const { selectedFiles } = this.state;

    if (!selectedFiles.length) {
      return null;
    }

    return (
      <S.MediaContainer className="selected-files">
        {map(selectedFiles, (item, index) => {
          const { file, objectURL } = item;
          const fileType = getMediaType(file);

          return (
            <S.MediaItem className="item" key={objectURL}>
              {fileType.includes('video') ? (
                <>
                  <video
                    className="media"
                    onLoadedMetadata={event => {
                      if (event.target && event.target.duration) {
                        const { duration, nextElementSibling } = event.target;

                        if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                          const time = getDuration(duration);
                          const timeEl = nextElementSibling.querySelector('.time');

                          if (timeEl) {
                            timeEl.innerText = time;
                          }
                        }
                      }
                      event.persist();
                    }}
                  >
                    <source src={objectURL} type={fileType} />
                    <source src={objectURL} type="video/mp4" />
                  </video>
                  <VideoDuration />
                </>
              ) : (
                <img className="media" src={objectURL} alt="" />
              )}
              <img
                src={`${CDN_URL}/images/close_circle_grey.svg`}
                onClick={() => this.onDeleteSelectedFile(index)}
                className="remove-icon"
                alt=""
              />
            </S.MediaItem>
          );
        })}
      </S.MediaContainer>
    );
  };

  onInput = event => {
    const { hasText } = this.state;
    const text = event.target.innerText;

    if (!text) {
      this.setState({ hasText: false });
    } else {
      if (!hasText) {
        this.setState({ hasText: true });
      }
    }
  };

  onChangeInput = data => {
    const { hasText } = this.state;
    const valueEditor = String(data.getCurrentContent().getPlainText());

    const entityMap = convertToRaw(data.getCurrentContent()).entityMap;
    const taggedFromEntityMap = Object.values(entityMap);
    const taggedPeople = taggedFromEntityMap.map(it => it.data.mention);
    const isAll = taggedFromEntityMap.find(it => get(it, 'data.mention.name', '') === 'everyone');

    if (!valueEditor) {
      this.setState({ hasText: false });
    } else {
      if (!hasText) {
        this.setState({ hasText: true });
      }
    }
    this.setState({
      comment: valueEditor,
      taggedPeople: taggedPeople,
      tagAll: !!isAll,
    });
  };
  onDropFiles = async files => {
    const { selectedFiles } = this.state;
    const { onUpdateConfigsUpload } = this.props;
    if (selectedFiles.length) return;

    let newFiles = [];

    for (let i = 0; i < files.length; i++) {
      if (i > 0) break;
      const file = files[i];
      const isFLV = getExtension(get(file, 'name', '')) === 'flv';
      let mimetype = '';
      if (isFLV) mimetype = 'video/x-flv';
      const { size, name, type = mimetype } = file;

      if (!FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.includes(name.split('.').pop().toLowerCase())) {
        toast.error(`Please use supported file types (${FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO.join(', ')})`);
        return;
      }

      if (type && type.includes('video') && size > FILE_VALIDATION.MAX_SIZE_VIDEO_FORUM * CONVERSION.MB_TO_BYTE) {
        toast.error(`Please resize and upload a video smaller than ${FILE_VALIDATION.MAX_SIZE_VIDEO_FORUM} MB`);
        return;
      }

      if (type && type.includes('image') && size > FILE_VALIDATION.MAX_SIZE_IMAGE_FORUM * CONVERSION.MB_TO_BYTE) {
        toast.error(`Please upload an image smaller than ${FILE_VALIDATION.MAX_SIZE_IMAGE_FORUM} MB`);
        return;
      }

      const src = window.URL.createObjectURL(file);
      const { uploadUrl } = await getPresignedUploadUrlByParams(file, {
        method: 'POST',
        url: '/api/file/gen-presigned-urls-comment',
        data: {
          files: [
            {
              name,
              mimetype: type,
              // metadata: await getMetadataMediaFile(file), TODO - Will open later
            },
          ],
        },
      });
      onUpdateConfigsUpload &&
        onUpdateConfigsUpload({
          method: 'PUT',
          url: uploadUrl,
          headers: { 'Content-Type': type },
          data: file,
        });

      newFiles.push({ file, objectURL: src });

      if (type.includes('video')) {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function (event) {
          newFiles[i].dimensions = { width: video.videoWidth, height: video.videoHeight };
        };
        video.src = src;
      } else {
        const image = document.createElement('img');
        image.src = src;
        image.onload = event => {
          const { naturalWidth, naturalHeight } = event.target;
          newFiles[i].dimensions = { width: naturalWidth, height: naturalHeight };
        };
      }
    }

    this.setState({ selectedFiles: selectedFiles.concat([...newFiles]) });
  };

  handleCallBackFunction = callback => {
    this.callbackFunction = callback;
  };

  render() {
    const { placeholder, members, autoFocus, className, isDragActive, replyMessage, onCancelReply } = this.props;
    const isReplyText = get(replyMessage, 'type', '') === 'text';

    return (
      <S.Container className="comments__container">
        {!isEmpty(replyMessage) && (
          <>
            <S.ReplyWrapper>
              <S.ReplyContainer>
                <S.ReplyBy>
                  <ReplyIcon />
                  <S.ReplyMessageText>
                    Replying to <S.TextBold>{get(replyMessage, 'owner', '')}</S.TextBold>
                  </S.ReplyMessageText>
                </S.ReplyBy>
                {isReplyText ? (
                  <S.ReplyMessageText isBlur>{get(replyMessage, 'content', '')}</S.ReplyMessageText>
                ) : (
                  <S.ReplyMessageText>
                    <S.TextBold>{get(replyMessage, 'type', '')}</S.TextBold>
                  </S.ReplyMessageText>
                )}
              </S.ReplyContainer>
              <S.CloseReply onClick={onCancelReply}>
                <Close />
              </S.CloseReply>
            </S.ReplyWrapper>
          </>
        )}
        <S.ContentWrapper>
          <Avatar
            size="32"
            name={getUserShortName(this.props.user)}
            src={this.props.user.avatar}
            color={this.props.user.color}
          />
          <S.InputContainer className="detail_post_comment--input">
            {this.state.selectedFiles.length ? (
              <>
                {this.renderSelectedFiles()}
                <S.SubmitButton onClick={this.onSubmit} />
              </>
            ) : (
              <>
                <MentionEditor
                  members={members}
                  placeholder={placeholder || 'Write something...'}
                  className="comment-with-mention"
                  onChange={this.onChangeInput}
                  callBackFunction={this.handleCallBackFunction}
                  autoFocus={autoFocus}
                  onSubmit={this.onSubmit}
                  customClassName={className}
                  replyMessage={replyMessage}
                />
                {this.renderFileSelect()}
              </>
            )}
          </S.InputContainer>
          {!this.state.selectedFiles.length && <DropZonePlaceholder show={isDragActive} />}
        </S.ContentWrapper>
      </S.Container>
    );
  }
}

export default CommentInput;
