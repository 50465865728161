import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

import Button from 'shared/FormControl/Button';
import Toggle from 'shared/FormControl/Toggle';
import { CDN_URL } from 'constants/commonData';

export const NotificationSidebarWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: unset;
  right: 0;
  z-index: 1001;
  ${props =>
    props.open &&
    css`
      top: 0;
    `}
`;

export const NotificationSidebar = styled.div`
  width: 560px;
  height: 100vh;
  background: #fff;
  right: -560px;
  top: 0;
  position: fixed;
  transition: right 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  ${props =>
    props.open &&
    css`
      right: 0;
    `}
`;

export const NotificationSidebarContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Trigger = styled.div`
  position: relative;
  cursor: pointer;

  .icon {
    display: block;
    width: 16px;
    height: 20px;
  }

  .icon.hover {
    display: none;
  }

  &:hover {
    background-image: url(${CDN_URL}/images/notification_purple.svg);

    .icon:not(.hover) {
      display: none;
    }

    .icon.hover {
      display: block;
    }
  }

  .counter {
    position: absolute;
    top: -7px;
    left: 14px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ff6f64;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 18px;
    color: #fff;

    &.large {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }

    &.small {
      top: -5px;
      width: 15px;
      height: 15px;
      line-height: 15px;
    }
  }

  .__react_component_tooltip.place-bottom:after {
    right: 24px !important;
    left: unset !important;
  }
`;

// Header
export const HeaderTitleWrapper = styled.div`
  padding: 16px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const CloseIconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;

  .close-icon {
    width: 19px;
    height: 19px;
    padding-left: 1px;
  }
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 33px;
`;

export const TitlePopup = styled.div`
  color: #262626;
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 600;
`;

export const ButtonNewTab = styled.a`
  cursor: pointer;
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  margin-left: 8px;
  margin-top: 2px;
  background-color: unset;
  border: unset;
  text-decoration: unset;
  color: unset;

  .icon {
    display: block;
  }

  .icon.hover {
    display: none;
  }

  &:hover {
    .icon:not(.hover) {
      display: none;
    }

    .icon.hover {
      display: block;
    }
  }
`;

export const WorkoutCompletionButton = styled(Button)`
  display: flex;
  padding: unset !important;
  justify-content: center;
  align-items: center;
  min-width: 234px;
  min-height: 33px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const OrderTitle = styled.div`
  color: #595959;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  line-height: 32px;
`;

export const HeaderFilterWrapper = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);

  ${props =>
    props.noShadow &&
    css`
      box-shadow: unset !important;
    `}
`;

export const HeaderOrderWrapper = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);

  ${props =>
    props.noShadow &&
    css`
      box-shadow: unset !important;
    `}
`;

export const GroupButtonRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const MarkAllReadButton = styled(Button)`
  color: #262626;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const Divider = styled.div`
  width: 2px;
  height: 24px;
  background-color: #e8e8e8;
`;

export const HeaderToggleWrapper = styled.label`
  cursor: pointer;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ToggleLabel = styled.span`
  color: #262626;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  user-select: none;
`;

export const ToggleButton = styled(Toggle)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  input {
    ~ label {
      ::before {
        background-color: #e0e1e4 !important;
      }
    }

    :checked,
    :checked:focus {
      ~ label {
        ::before {
          background-color: #36ba7b !important;
        }
      }
    }
  }
`;

// Content
export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 134px auto 0px;
`;

export const EmptyText = styled.div`
  color: #8c8c8c;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
`;

export const Item = styled.a`
  padding: 16px 14px 16px 24px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px #ebedf4;
  overflow: hidden;

  &.unread {
    background-color: #fbfbff;
    box-shadow: 0px -1px 0px 0px #dde0e5 inset;
    border-left: 4px solid #5158cf;
    padding-left: 24px;
  }

  &.align-items-center {
    align-items: center;
  }

  &:hover {
    background-color: #fafafa;
    text-decoration: none !important;

    .action {
      visibility: visible;
    }
  }

  .avatar-notification-popup {
    flex-shrink: 0;
  }

  .message {
    color: #262626;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    flex: 1 1;
    word-break: break-word;
    box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    b {
      font-weight: 600;
    }
  }

  .content {
    margin-left: 8px;
  }

  .time {
    color: #8c8c8c;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 8px;
  }

  .action {
    visibility: hidden;
    flex-shrink: 0;
    z-index: 100;
  }

  .payment-amount {
    color: #595959;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 6px;
  }

  .confetti {
    position: absolute;
    top: -137px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  .trigger-icon {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

export const ItemActionPopup = styled(Popup)`
  &.ui.popup {
    background-color: transparent;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  &.ui.top.popup {
    margin: 0 0 10.5px !important;
  }

  &.ui.bottom.popup {
    margin: 10.5px 0 0 !important;
  }

  &.ui.top.right.popup {
    margin: 0 -12px 10.5px 0 !important;
  }

  &.ui.bottom.right.popup {
    margin: 10.5px -12px 0 0 !important;
  }

  &::before {
    content: '';
    width: unset !important;
    height: unset !important;
    background: unset !important;
    background-color: unset !important;
    box-shadow: unset !important;
    transform: unset !important;
    margin-left: unset !important;
    right: 18px !important;
    top: -10px !important;
    z-index: unset !important;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #262626;

    ${props =>
      props.position === 'top right' &&
      css`
        top: unset !important;
        bottom: -10px !important;
        border-color: #262626 transparent transparent;
      `}
  }
`;

export const ItemActionOption = styled.div`
  ${props =>
    props.disabled &&
    css`
      display: none;
    `};
`;

export const ItemActionContent = styled.div`
  width: 131px;
  border-radius: 6px;
  background-color: #262626;
  box-shadow: unset;
`;

export const ItemActionItem = styled.div`
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #262626;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #4e4d4d;
  }
`;

export const ItemActionLabel = styled.div`
  color: #ffffff;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: ${props => (props.openTooltip ? '84px' : 'unset')};
  margin-right: 2px;
  padding-right: 2px;
  &::-webkit-scrollbar {
    width: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c2c2c2 !important;
  }
  &::-webkit-scrollbar-track {
    margin-top: 2px;
  }
`;

export const ContentBodyWrapper = styled.div``;

export const EmojiHabit = styled.div`
  display: inline-block;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.color || '#FCF5DE'};
  position: relative;

  img {
    filter: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  margin-top: ${props => (props.isEmpty ? '134px' : '32px')};
  margin-bottom: ${props => (!props.openTooltip ? '32px' : 'unset')};
  .ui.loader {
    z-index: unset !important;
  }
  .ui.loader:before {
    border-color: #fbfbff;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    color: #8c8c8c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: ${props => (!props.openTooltip ? '32px' : 'unset')};
`;

export const BottomIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
`;

export const BottomText = styled.div`
  color: #8c8c8c;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const GoToTopButton = styled(Button)`
  color: #5158cf;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

// Footer
export const FooterTooltipWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px 24px 16px 24px;
`;

export const FooterTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 502px;
  height: 44px;
  padding: 0px 23px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export const FooterTooltipContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 6px;
`;

export const FooterTooltipLabel = styled.div`
  color: #262626;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const FooterTooltipCloseButton = styled.div`
  display: flex;
  cursor: pointer;
`;

export const ButtonCtrl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${props => (props.isWindows ? '35px' : '41px')};
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 2px 0px 0px #d4d7d9;

  color: #8c8c8c;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
`;
