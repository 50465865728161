import styled, { css } from 'styled-components';

const baseText = css`
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
  font-size: 12px;
  font-weight: 600;
`;

export const TooltipWrapper = styled.div`
  .__react_component_tooltip.app-tooltip.custom-tooltip,
  .__react_component_tooltip.app-tooltip-white.custom-tooltip {
    width: ${({ width }) => (width ? `${width}px` : 'auto')} !important;
    padding: 8px 12px !important;
    ${baseText}
    white-space:  ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
  }

  .__react_component_tooltip.app-tooltip-white.custom-tooltip {
    min-width: fit-content;
  }

  .__react_component_tooltip.app-tooltip.custom-tooltip.place-top:after,
  .__react_component_tooltip.app-tooltip-white.custom-tooltip.place-top:after {
    bottom: -8px !important;
  }
`;
