import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7B7E91;
`;

export const FilterCategoryWrapper = styled.div`
  position: relative;
`;

export const List = styled.div`
  overflow: auto;
  white-space: nowrap;
  text-overflow: unset;
  ::-webkit-scrollbar {
    height: 0 !important;
  }
`;

export const Item = styled.div`
  ${baseText}
  display: inline-block;
  padding: 5px 15px;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  ${props =>
    props.active &&
    css`
      border: 1px solid #5158cf;
      color: rgb(32, 35, 83);
      background-color: rgb(240, 241, 255);
    `}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 32px;
  pointer-events: none;
  &.left {
    left: -2px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) -16.72%, #ffffff 53.16%, #ffffff 100%);
  }
  &.right {
    right: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) -16.72%, #ffffff 53.16%, #ffffff 100%);
  }
`;

export const Button = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg path {
    fill: #a3a3b5;
  }
  &:hover {
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }
  &.left {
    left: 0;
    svg {
      transform: rotate(-90deg);
    }
  }
  &.right {
    right: 0;
    svg {
      transform: rotate(90deg);
    }
  }
`;
