import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import { ReactComponent as DragIcon } from 'assets/icons/drag-icon.svg';

const LoadingWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ebedf2;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
`;

const ListLoader = props => {
  return (
    <LoadingWrapper className="exercise-item">
      <ContentLoader height="56" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)">
        <rect x="0" y="0" rx="7" ry="7" width="90" height="100%" />
        <rect x="110" y="20" rx="2" ry="2" width="138" height="8" />
        <rect x="110" y="35" rx="2" ry="2" width="60" height="8" />
      </ContentLoader>
      <DragIcon className="drag-icon" />
    </LoadingWrapper>
  );
};

const CardLoader = props => {
  return (
    <div className="exercise-item">
      <ContentLoader height="149" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)">
        <rect x="0" y="0" rx="0" ry="5" width="100%" height="97" />
        <rect x="10" y="115" rx="2" ry="2" width="88%" height="8" />
        <rect x="10" y="128" rx="2" ry="2" width="50%" height="8" />
      </ContentLoader>
    </div>
  );
};

export default function (props) {
  return props.displayType === 'list' ? <ListLoader /> : <CardLoader />;
}
