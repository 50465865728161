import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMessageDetail, addMessage, updateMessage } from 'redux/autoflow/autoMessage/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import InAppMessage from 'components/InAppMessage';
import { push } from 'connected-react-router';
import { CDN_URL } from 'constants/commonData';
import * as S from './styles';
function Wrapper(props) {
  const { match, autoflow, user, originData, extendData } = props;
  const [data, setData] = useState(null);
  const messageId = _.get(match, 'params.messageId');

  useEffect(() => {
    if (messageId) {
      props.getMessageDetail({ id: messageId, autoflow }).then(response => {
        const { data } = response.data;
        setData(data);
      });
    } else {
      setData(originData || {});
    }
  }, []);

  const onSubmit = message => {
    const body = { ...message, autoflow };

    if (messageId || props.isEditMode) {
      body.messageId = messageId || data._id;
      return props.updateMessage(body, extendData);
    }

    return props.addMessage(body, extendData);
  };

  const closePopup = () => {
    if (typeof props.onClose === 'function') {
      return props.onClose();
    }

    if (messageId) {
      props.push(`/home/autoflow/${autoflow}/auto-message`);
    }

    props.toggleModal(false);
  };

  const onClose = data => {
    if (data && !_.isEqual(data.oldValue, data.newValue)) {
      props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={closePopup}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      closePopup();
    }
  };

  return data ? (
    <InAppMessage
      originalData={data}
      onSubmit={onSubmit}
      user={user}
      onClose={onClose}
      day={data.day || props.day}
      toggleConfirmModal={props.toggleConfirmModal}
    />
  ) : null;
}

const mapState = state => {
  const { user, rootReducer, isModalOpen } = state;
  const {
    common: { workingAutoflow },
  } = rootReducer.autoflow;
  const { _id, avatar, first_name, last_name, color } = user;

  return {
    autoflow: workingAutoflow ? workingAutoflow._id : '',
    user: { _id, avatar, first_name, last_name, color },
    isModalOpen,
  };
};

const mapDispatch = dispatch => ({
  getMessageDetail: bindActionCreators(getMessageDetail, dispatch),
  updateMessage: bindActionCreators(updateMessage, dispatch),
  addMessage: bindActionCreators(addMessage, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(mapState, mapDispatch)(Wrapper);
