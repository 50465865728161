import React from 'react';
import { connect } from 'react-redux';
// import illustrators
import NoConnectedIllus from 'assets/images/illustrator-forum-no-connect.png';
import { ReactComponent as ConnectIcon } from 'assets/icons/connect-forum.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/hint.svg';
// import components
import ConnectForumModal from '../ConnectForumModal';
import { Button } from 'shared/FormControl';
// import actions
import { toggleModal } from 'actions/modal';
import NoConnectedStyles from './style';

const NoConnected = ({ autoflowConnectForum, autoflowId, numberClients, dispatch }) => {
  const openModal = () => {
    dispatch(
      toggleModal(
        true,
        <ConnectForumModal
          onClose={() => dispatch(toggleModal(false))}
          autoflowConnectForum={autoflowConnectForum}
          autoflowId={autoflowId}
          numberClients={numberClients}
        />,
      ),
    );
  };
  return (
    <NoConnectedStyles>
      <div className="forum-no-connected-banner-section">
        <div className="forum-no-connected-banner">
          <img src={NoConnectedIllus} alt="" />
          <div className="text">Connect and showcase the leaderboard in one of your community forums</div>
        </div>
        <div className="forum-no-connected-action">
          <Button purple onClick={openModal}>
            <ConnectIcon />
            <span>Connect Forum</span>
          </Button>
        </div>
      </div>
      <div className="hint">
        <div className="tooltip">
          <TooltipIcon />
        </div>
        <div className="text">
          Invite clients from the Autoflow to the connected Forum and encourage community engagement.
        </div>
      </div>
    </NoConnectedStyles>
  );
};

export default connect()(NoConnected);
