import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { bootDataDogSessionReplay } from './datadog-session-replay';
import packageJson from '../../../package.json';

const DataDogRumTracking = ({ user, isGettingProfile, isEnableDataDogRum, isEnableLogRequest }) => {
  const [isInitiatedDataRum, setIsInitiatedDataRum] = useState(false);

  useEffect(() => {
    if (isGettingProfile || !user || isInitiatedDataRum) return;

    const appEnableDataDogRum = process.env.REACT_APP_ENABLE_DATADOG_RUM === 'true';

    if (appEnableDataDogRum && isEnableDataDogRum) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
        clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'everfit-web-app',
        version: packageJson.version,
        env: process.env.REACT_APP_DATADOG_RUM_ENV,
        allowedTracingOrigins: [/https:\/\/[api].*\.everfit\.io/], // Only trading request to apis of everfit

        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        useSecureSessionCookie: true,
        beforeSend: (event, context) => {
          // collect a RUM resource's response headers
          if (event.type === 'resource' && event.resource.type === 'fetch') {
            event.context = { ...event.context, responseHeaders: context.response.headers };
          }
        },
      });

      datadogRum.setUser({
        id: user._id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });

      if (process.env.REACT_APP_ENABLE_DATADOG_SESSION_REPLAY === 'true') {
        bootDataDogSessionReplay();
      }
      setIsInitiatedDataRum(true);
    }

    if (appEnableDataDogRum && isEnableLogRequest) {
      datadogLogs.init({
        applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
        clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: `everfit-web-app-${process.env.REACT_APP_DATADOG_RUM_ENV}`,
        env: process.env.REACT_APP_DATADOG_RUM_ENV,
        allowedTracingOrigins: [/https:\/\/[api].*\.everfit\.io/], // Only trading request to apis of everfit

        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        version: packageJson.version,
        sampleRate: 100,
        trackInteractions: true,
        useSecureSessionCookie: true,
        forwardErrorsToLogs: false,
      });
      window.isEnableLogRequest = true;
      setIsInitiatedDataRum(true);
    }
  }, [isGettingProfile, user, isInitiatedDataRum, isEnableDataDogRum, isEnableLogRequest]);

  return null;
};

const mapStateToProps = state => {
  const { user, isGettingProfile, rootReducer } = state;
  const { generalSettings: { enable_datadog_rum = false, enable_logs_request = false } = {} } = rootReducer;

  return {
    user,
    isGettingProfile,
    isEnableLogRequest: enable_logs_request,
    isEnableDataDogRum: enable_datadog_rum,
  };
};

export default connect(mapStateToProps)(DataDogRumTracking);
