import React from 'react';

import { PLANS } from 'constants/commonData';
import UpgradeYourPlanModal from 'shared/UpgradePath/components/UpgradeYourPlanModal';

export const goToBilling = (
  isAdmin,
  pathName,
  isTrialOrStarter,
  defaultAddOns,
  invoiceOverDue,
  handleCloseUpgradeModal,
  togglePaymentInformation,
  notSendPrev,
  push,
  updateSelectPackage,
  currentPackage,
  toggleModal,
  paymentAddOn,
  planWillSelect,
  teamData,
  getLowestPackagePrice,
  planPricing,
  isNotUsePathName,
  props,
) => {
  if (invoiceOverDue && pathName.includes('billing')) {
    handleCloseUpgradeModal();
    togglePaymentInformation(true);
  } else {
    let addOns = null;

    /* Handle Check Is Admin */
    handleCheckIsAdmin(
      isAdmin,
      notSendPrev,
      pathName,
      isTrialOrStarter,
      updateSelectPackage,
      currentPackage,
      toggleModal,
      handleCloseUpgradeModal,
      push,
    );

    if (props.onClick) {
      props.onClick();
    }

    /* Handle Select Plan  */
    if (planWillSelect) {
      addOns = handlePlanWillSelect(
        teamData,
        planWillSelect,
        currentPackage,
        isTrialOrStarter,
        defaultAddOns,
        getLowestPackagePrice,
        planPricing,
      );
      updateSelectPackage(addOns);
    } else if (isTrialOrStarter) {
      /* Handle isTrial Or Starter  */
      addOns = handleIsTrialOrStarter(currentPackage);
      updateSelectPackage({ addOns: addOns });
    }

    if (isNotUsePathName) {
      addOns = handleCheckNotUsePathName(isTrialOrStarter, defaultAddOns, currentPackage, planWillSelect);
      return updateSelectPackage({ addOns: addOns });
    } else {
      /* Handle Check Autoflow  */
      if (pathName.includes('autoflow')) {
        addOns = handleCheckAutoflow(isTrialOrStarter, defaultAddOns, currentPackage);
        return updateSelectPackage({ addOns: addOns });
      }
      /* Handle Check Payment  */
      if (pathName.includes('packages') || pathName.includes('payment-activities') || pathName.includes('sequences')) {
        addOns = handleCheckPayment(paymentAddOn, isTrialOrStarter, defaultAddOns, currentPackage);
        return updateSelectPackage({ addOns: addOns });
      }
    }
  }
};

const handleCheckIsAdmin = (
  isAdmin,
  notSendPrev,
  pathName,
  isTrialOrStarter,
  updateSelectPackage,
  currentPackage,
  toggleModal,
  handleCloseUpgradeModal,
  push,
) => {
  if (isAdmin) {
    const path = notSendPrev ? '/pricing-plan' : `/pricing-plan?prev=${pathName}`;
    push(path);

    if (notSendPrev && isTrialOrStarter) {
      updateSelectPackage({
        addOns: {
          ...currentPackage.get('addOns').toJS(),
          automation: false,
        },
      });
    }
  } else {
    toggleModal(true, <UpgradeYourPlanModal onClose={handleCloseUpgradeModal} />);
  }
};

const handleCheckNotUsePathName = (isTrialOrStarter, defaultAddOns, currentPackage, planWillSelect) => {
  return {
    ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
    ...planWillSelect.addOns,
  };
};

const handleCheckAutoflow = (isTrialOrStarter, defaultAddOns, currentPackage) => {
  return {
    ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
    automation: true,
  };
};

const handleCheckPayment = (paymentAddOn, isTrialOrStarter, defaultAddOns, currentPackage) => {
  const isPayment = paymentAddOn.toJS().find(it => it.code === 'payment_package') ? true : false;
  return {
    ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
    payment_package: isPayment,
  };
};

const handlePlanWillSelect = (
  teamData,
  planWillSelect,
  currentPackage,
  isTrialOrStarter,
  defaultAddOns,
  getLowestPackagePrice,
  planPricing,
) => {
  if (teamData.get('tier') !== PLANS.starter.key && planWillSelect.tier !== PLANS.studio.key) {
    planWillSelect.tier = teamData.get('tier');
  }

  const selectPackage = currentPackage.mergeDeep(planWillSelect).toJS();
  selectPackage.addOns = {
    ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
    ...planWillSelect.addOns,
  };

  selectPackage.packagePrice = getLowestPackagePrice(
    planPricing.toJS(),
    selectPackage.tier,
    teamData.get('noClients'),
    teamData.get('packageNoClients'),
  );

  return selectPackage;
};

const handleIsTrialOrStarter = currentPackage => {
  return {
    ...currentPackage.get('addOns').toJS(),
    automation: false,
  };
};
