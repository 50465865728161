import styled, { css } from 'styled-components';

const baseText = `
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  font-family: 'Open Sans';
  font-style: normal;
  user-select: none;
`;

export const ListTag = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  border-radius: 4px;
  background-color: #fff;
  ${props =>
    props.transparent &&
    css`
      color: #000;
    `}
`;

export const ItemTag = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  :hover {
    background-color: #edecfc;
  }
`;

export const TagTitle = styled.div`
  ${baseText}
`;

export const TagIcon = styled.div`
  width: 20px;
  height: 20px;
  ${props =>
    props.transparent &&
    css`
      svg g path {
        fill: #fff;
      }
    `}
`;

export const CloseIcon = styled.div`
  width: 20px;
  height: 20px;
  :hover {
    svg path {
      fill: #5e59ff !important;
    }
  }

  ${props =>
    props.transparent &&
    css`
      svg g path {
        fill: #fff;
      }
    `}
`;

export const TagLabel = styled.div`
  ${baseText}
  ${props =>
    props.transparent &&
    css`
      color: #fff;
    `}
`;

export const TagName = styled.div`
  ${baseText}
  text-transform: capitalize;
  :hover {
    color: #5e59ff !important;
  }
  ${props =>
    props.transparent &&
    css`
      color: #fff;
    `}
`;

export const ButtonAddTag = styled.div`
  width: 100%;
  height: 28px;
  padding: 4px 12px 4px 8px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  :hover {
    ${TagIcon} {
      svg path {
        fill: #5e59ff;
      }
    }
    ${TagLabel} {
      color: #5e59ff;
    }
  }
  ${props =>
    props.transparent &&
    css`
      background: rgba(0, 0, 0, 0.2);
      :hover {
        background: #fff;
      }
    `}
`;

export const ButtonEditTag = styled(ButtonAddTag)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  ${props =>
    props.transparent &&
    css`
      :hover {
        ${CloseIcon} {
          svg g path {
            fill: #000;
          }
        }
        ${TagName} {
          color: #000;
        }
      }
    `}
`;
