import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const DayTooltip = styled.div`
  background: #f4f5f9;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #575c77;
  position: absolute;
  transform: translate(50%, -50%);
`;

export const NoData = styled.div`
  color: #000000;
  opacity: 0.3;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  .slds-p-top--medium {
    position: absolute;
    top: 0;
    left: 0;
    width: 518px;
    z-index: -1;
  }
  .quiz-chartTip {
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 50;
    max-width: 250px;
  }

  .quiz-graph {
    padding: 0 10px;
    height: 328px;
    width: 100%;
    overflow: visible;
  }

  .quiz-graph .x-labels {
    text-anchor: middle;
  }

  .quiz-graph .y-labels {
    text-anchor: end;
  }

  .quiz-graph .quiz-graph-grid {
    stroke: #ccc;
    stroke-dasharray: 0;
    stroke-width: 1;
  }

  .label-title {
    text-anchor: middle;
    text-transform: uppercase;
    font-size: 12px;
    fill: gray;
  }

  .quiz-graph-dot,
  .quiz-graph-start-dot {
    fill: rgba(0, 112, 210, 1);
    stroke-width: 2;
    stroke: white;
  }

  .weekly-report__chart {
    position: relative;

    .weekly-report__goal-tooltip {
      position: absolute;
      right: 20px;
      padding-left: 20px;
      transform: translateX(100%);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #202353;
      background-repeat: no-repeat;
      background-position: 5px;
      background-color: #ffffff;

      cursor: pointer;
      ${props =>
        props.isOneWeek &&
        css`
          right: 40px;
        `}

      &--rest-day {
        background-image: url(${CDN_URL}/images/macros_rest_day_bold.svg);
        background-color: transparent;
      }

      &--training-day {
        background-image: url(${CDN_URL}/images/macros_training_day_bold.svg);
        background-color: transparent;
      }

      .__react_component_tooltip {
        text-align: center;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        min-width: max-content;
        padding: 5px 10px;
      }
    }
  }
`;

export const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  .legend-item {
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: #202353;
    padding-left: 15px;
    margin-left: 30px;
    position: relative;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #ffbe49;
      top: 50%;
      transform: translateY(-50%);
    }

    &--protein {
      ::before {
        background-color: #57b5ea;
      }
    }
    &--carbs {
      ::before {
        background-color: #65cc9c;
      }
    }
    &--fat {
      ::before {
        background-color: #ffbe49;
      }
    }
  }
`;
