import { cloneDeep, get, isEmpty, pick } from 'lodash';
import { Types } from './actions';
import { FORM_FETCH_TYPES, FORM_VIEW_TYPES, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';

export const SORTER = {
  NAME: 'name',
  LAST_UPDATED: 'last_updated_at',
};

export const SORT = {
  ASC: 1,
  DESC: -1,
};

export const INITIAL_STATE_FORMS = {
  list: [],
  loading: false,
  viewType: FORM_VIEW_TYPES.LIST,
  query: {
    page: 1,
    per_page: 20,
    text_search: '',
    fetch_type: FORM_FETCH_TYPES.YOUR_FORM,
    sort: SORT.DESC,
    sorter: SORTER.LAST_UPDATED,
    isEnd: false,
    all_form: 0,
    your_form: 0,
    archived_form: 0,
  },
  formTemplates: [],
  isLoadingTemplate: false,
};

export default (state = INITIAL_STATE_FORMS, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_FORMS_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.GET_FORMS_SUCCESS: {
      const { data, total, your_form, all_form, archived_form } = payload;
      return Object.assign({}, state, {
        loading: false,
        list: data,
        query: { ...state.query, total, your_form, all_form, archived_form },
      });
    }
    case Types.GET_FORMS_FAIL: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.GET_PUBLISHED_FORMS_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.GET_PUBLISHED_FORMS_SUCCESS: {
      const { data, page, total, your_form, all_form } = payload;
      const newList = page === 1 ? data : [...state.list, ...data];

      return Object.assign({}, state, {
        loading: false,
        list: newList,
        query: { ...state.query, page, total, your_form, all_form, isEnd: newList.length >= total },
      });
    }
    case Types.GET_PUBLISHED_FORMS_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.ADD_NEW_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.ADD_NEW_FORM_SUCCESS: {
      const newList = payload.isOwner ? [payload.data, ...cloneDeep(state.list)] : cloneDeep(state.list);
      return Object.assign({}, state, { list: newList, loading: false });
    }
    case Types.ADD_NEW_FORM_FAIL: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.EDIT_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.EDIT_FORM_SUCCESS: {
      let newList = cloneDeep(state.list);
      const editForm = newList.find(item => item._id === payload.data._id);
      const teamMember = get(payload, 'currentUser.team_member', []);
      const newAuthor = pick(
        teamMember.find(item => item._id === payload.data.owner),
        ['_id', 'first_name', 'last_name', 'avatar'],
      );
      newList = newList.filter(item => item._id !== payload.data._id);

      if (
        payload.currentUser._id === payload.data.owner ||
        (payload.data.share !== TEAM_SHARE_PRIVATE && state.query.fetch_type === FORM_FETCH_TYPES.ALL_FORM)
      ) {
        newList = [{ ...editForm, ...payload.data, author: isEmpty(newAuthor) ? null : newAuthor }, ...newList];
      }
      return Object.assign({}, state, { list: newList, loading: false });
    }
    case Types.EDIT_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.DUPLICATE_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.DUPLICATE_FORM_SUCCESS: {
      let newList = cloneDeep(state.list);
      const isArchived = newList.some(item => item._id === payload.prevId && item.is_archived);
      if (
        !isArchived &&
        (payload.isOwner ||
          (state.query.fetch_type === FORM_FETCH_TYPES.ALL_FORM && payload.data.share === TEAM_SHARE_SHARED))
      ) {
        newList = [payload.data, ...newList];
      }
      return Object.assign({}, state, { list: newList, loading: false });
    }
    case Types.DUPLICATE_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.DELETE_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.DELETE_FORM_SUCCESS: {
      const newList = state.list.filter(item => item._id !== payload);
      const newQuery = { ...state.query, total: state.query.total - 1 };
      return Object.assign({}, state, { list: newList, loading: false, query: newQuery });
    }
    case Types.DELETE_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.FORM_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...state.query, ...payload } });
    case Types.FORM_RESET_QUERY_PARAMS:
      const { isResetFull } = payload;
      if (isResetFull) {
        return Object.assign({}, state, { query: INITIAL_STATE_FORMS.query });
      }
      const { fetch_type } = state.query;
      return Object.assign({}, state, {
        query: {
          ...INITIAL_STATE_FORMS.query,
          fetch_type,
        },
      });
    case Types.ARCHIVE_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.ARCHIVE_FORM_SUCCESS: {
      let newList = cloneDeep(state.list);
      const query = cloneDeep(state.query);
      query.total = query.total > 0 ? query.total - 1 : query.total;
      newList = newList.filter(item => item._id !== payload.id);
      return Object.assign({}, state, { list: newList, loading: false, query });
    }
    case Types.ARCHIVE_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.GET_FORM_TEMPLATES_REQUEST: {
      return Object.assign({}, state, { isLoadingTemplate: true });
    }
    case Types.GET_FORM_TEMPLATES_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, { formTemplates: data.reverse(), isLoadingTemplate: false });
    }
    case Types.GET_FORM_TEMPLATES_FAILED: {
      return Object.assign({}, state, { isLoadingTemplate: false });
    }
    case Types.RESET_FORM_TEMPLATES: {
      return Object.assign({}, state, { formTemplates: [] });
    }
    default:
      return state;
  }
};
