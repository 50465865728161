// libraries
import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import isEmpty from 'lodash/isEmpty';

// store

// components

// constants

// assets
import { ReactComponent as TrailingIcon } from 'assets/icons/MealPlans/trailing_icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete-trash.svg';

import * as S from './style';

function SelectedMetrics(props) {
  const { selectedMetrics = [], onRemoveGroup, onOrderMetrics, isDisable } = props;
  const [placeholderProps, setPlaceholderProps] = useState({});
  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  const getClientY = (draggedDOM, array, index) => {
    const result =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      array.slice(0, index).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    return result;
  };

  const getDraggedDom = draggableId => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    return document.querySelector(domQuery);
  };

  const onDragEnd = useCallback(
    result => {
      const { destination, source } = result;
      if (!destination || !source) return;

      const sourceIndex = source.index;
      const destIndex = destination.index;
      const newMetrics = [...selectedMetrics];
      const fromMetric = newMetrics[sourceIndex];
      newMetrics.splice(sourceIndex, 1);
      newMetrics.splice(destIndex, 0, fromMetric);
      onOrderMetrics(newMetrics);
    },
    [selectedMetrics],
  );

  const handleDragStart = event => {
    const draggedDOM = getDraggedDom(event && event.draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event && event.source && event.source.index;

    const clientY = getClientY(draggedDOM, [...draggedDOM.parentNode.children], sourceIndex);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragUpdate = event => {
    if (!event.destination) return;

    const draggedDOM = getDraggedDom(event && event.draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event && event.destination && event.destination.index;
    const sourceIndex = event && event.source && event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY = getClientY(draggedDOM, updatedArray, destinationIndex);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const renderMetric = (metric, index) => {
    const id = get(metric, '_id');
    const type = get(metric, 'category.name');
    const unit = get(metric, 'unit.title');

    const handleRemoveGroup = () => {
      onRemoveGroup(metric);
    };
    const tooltipRemoveId = `remove-metric-tooltip-${id}`;

    return (
      <Draggable key={id} draggableId={id} index={index} isDragDisabled={isDisable}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <S.SelectedMetricItemWrapper isDragging={snapshot.isDragging} disabled={isDisable}>
              <S.SelectedMetricName>
                <S.TrailingIconWrapper>
                  <TrailingIcon className="trailing-item" />
                </S.TrailingIconWrapper>
                <span>{metric.name}</span>
              </S.SelectedMetricName>
              <S.SelectedMetricType>{type}</S.SelectedMetricType>
              <S.SelectedMetricUnit>{unit || '--'}</S.SelectedMetricUnit>

              {!isDisable && (
                <S.RemoveGroupIcon data-for={tooltipRemoveId} data-tip onClick={handleRemoveGroup}>
                  <TrashIcon className="remove-group-icon" />
                  <ReactTooltip className="app-tooltip" id={tooltipRemoveId} effect="solid" place="top">
                    <span>Remove Metric</span>
                  </ReactTooltip>
                </S.RemoveGroupIcon>
              )}
            </S.SelectedMetricItemWrapper>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <S.Wrapper>
      <S.SelectedMetricWrapper>
        <S.HeaderSelectedTable>
          <S.HeaderSelectMetricLabel>Metrics in group</S.HeaderSelectMetricLabel>
          <S.HeaderSelectedTypeLabel>Type</S.HeaderSelectedTypeLabel>
          <S.HeaderUnitLabel>Unit</S.HeaderUnitLabel>
        </S.HeaderSelectedTable>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
          <Droppable droppableId="droppable" type="droppableGroup">
            {(provided, snapshot) => (
              <S.ContentTable {...provided.droppableProps} ref={provided.innerRef}>
                {selectedMetrics.map(renderMetric)}
                {provided.placeholder}
                {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                  <S.Placeholder
                    style={{
                      top: placeholderProps.clientY + 2.5,
                      left: placeholderProps.clientX,
                      height: placeholderProps.clientHeight - 5,
                      width: placeholderProps.clientWidth - 21,
                    }}
                  />
                )}
              </S.ContentTable>
            )}
          </Droppable>
        </DragDropContext>
      </S.SelectedMetricWrapper>
    </S.Wrapper>
  );
}

export default SelectedMetrics;
