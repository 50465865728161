import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import { toggleModal } from 'actions/modal';
import { hideTeammateBanner } from 'redux/teammate/actions';

class TeammateHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { showBanner, header, banner } = this.props;
    const isMobile = window.innerWidth <= 1220;
    const isShowBanner = showBanner && !isMobile;
    if ((isShowBanner && header) || (!isShowBanner && banner)) {
      return null;
    }

    return (
      <S.Wrapper className="teammates__header" showBanner={showBanner && !isMobile}>
        {showBanner && !isMobile ? (
          <S.BannerContainer>
            <S.BannerTextContent>
              <div className="teammates__banner__label">Team</div>
              <div className="teammates__banner__title">Collaborate with your Dream Team</div>
              <div className="teammates__banner__description">
                <span>
                  Invite unlimited teammates and collaborate on your library of exercises, workouts, and programs.
                </span>
                <a href="http://help.everfit.io/en/collections/1789337-team-features" target="_blank">
                  <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" /> Learn more
                </a>
              </div>
            </S.BannerTextContent>
            <div className="buttons__container">
              <Button purple onClick={this.props.onCreateNew} className="button--create-new">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 4.99902V0.499023H4.5V4.99902H0V6.99902H4.5V11.499H6.5V6.99902H11V4.99902H6.5Z"
                    fill="black"
                  />
                </svg>
                <span>Add Teammate</span>
              </Button>
            </div>
            <div className="button-hidden-container">
              <Button className="button--hide-banner" onClick={this.props.hideTeammateBanner}>
                <span>Hide Banner</span>
                <img src={`${CDN_URL}/images/arrow_up_7e7e7e.svg`} alt="" />
              </Button>
            </div>
            <S.BackgroundBanner />
          </S.BannerContainer>
        ) : (
          <S.DefaultUIContainer>
            {/* <NewSearchInput
              onChange={this.searchDebounce}
              onClearSearch={() => this.onSearch(null, { value: '' })}
              placeholder="Search by keyword or name"
              onKeyPress={this.onKeyPress}
            /> */}
            <div></div>
            <Button purple onClick={this.props.onCreateNew}>
              <img src={`${CDN_URL}/images/plus_white.svg`} alt="" />
              <span>Add Teammate</span>
            </Button>
          </S.DefaultUIContainer>
        )}
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    user: { is_hide_banner_teammate },
  } = state;
  return { showBanner: !is_hide_banner_teammate };
};

const mapDispatch = dispatch => ({
  hideTeammateBanner: bindActionCreators(hideTeammateBanner, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(TeammateHeading);
