import styled, { css } from 'styled-components';

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .rootOptions {
    width: 100%;
    height: 100%;
    .common-select-container__menu {
      margin-top: 1px;
      background: transparent;
      box-shadow: none;
      padding-bottom: 15px;
    }
    .common-select-container__menu-list {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    }

    .common-select-container__control {
      height: 100%;
      background: #fafbfc;
      padding: 0 5px;
      box-shadow: none;
      color: #202353;
      cursor: pointer;
      &.common-select-container__control--menu-is-open,
      &.common-select-container__control--is-focused {
        border-color: #5158cf;
        box-shadow: none;
      }

      ${props =>
        props.required &&
        css`
          border-color: rgb(238, 37, 37);
        `}
    }
    .common-select-container__option {
      &.common-select-container__option--is-selected {
        color: #5158cf;
        background: transparent;
        font-weight: 600;
        &.common-select-container__option--is-focused {
          background: transparent;
        }
      }
      &.common-select-container__option--is-focused {
        cursor: pointer;
        background: #f4f3ff;
        font-weight: 600;
        color: #5158cf;
      }
    }
  }
  ${props =>
    props.maxHeightOfList &&
    css`
      .common-select-container__menu-list {
        max-height: ${`${props.maxHeightOfList}`};
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${`${props.width}`};
    `}
  ${props =>
    props.height &&
    css`
      height: ${`${props.height}`};
    `}
`;

export const SelectedWrapper = styled.div`
  display: flex;
`;

export const SelectedLabel = styled.span`
  font-weight: normal;
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 18px;
  color: #000000;
`;
