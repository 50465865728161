import styled, { css } from 'styled-components';
import Background from 'assets/images/log-activity-background.png';
import { Image } from 'semantic-ui-react';

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  white-space: pre-line;
  word-break: break-word;

  ${props =>
    props.hasPadding &&
    css`
      padding: 20px 20px 10px;
    `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  padding: 20px;
  background-color: #fff;

  .history__edit-icon {
    color: #5c5bbd;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  .workout-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const BaseInfoContainer = styled.div`
  padding: 15px 0px 8px;

  .duration {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #5f5f5f;
    margin-bottom: 20px;
  }

  .workout__name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 4px;
    margin-top: 8px;
    text-align: center;
    word-break: break-word;
    &.with__video__margin {
      margin-top: 15px;
    }
  }

  .workout__tracked {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #5c5bbd;
    margin-bottom: 30px;
  }

  .workout__summarize {
    background: #ffffff;
    border: 1px solid #dde2ed;
    border-radius: 5px;

    &.video__workout {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      margin-top: 8px;
      .client__rating__header {
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        color: #6a778a;
        margin-bottom: 6px;
        text-transform: uppercase;
      }
      .client__rating__detail {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        color: #69bd5b;
      }
    }

    .completion {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 0;
      ${'' /* border-bottom: 1px solid #dde2ed; */}

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1;
        padding: 0 30px;

        &.center {
          justify-content: center;
          border-right: none !important;
        }

        :first-child {
          border-right: 1px solid #dde2ed;
        }

        img {
          margin-right: 20px !important;
        }

        .value {
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 100%;
          color: #000000;

          span {
            font-size: 28px;
          }

          .unit {
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #202353;
            opacity: 0.7;
          }
          .unit-display {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            color: #202353;
          }
        }
      }
    }

    .duration {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #273d52;
      padding: 15px 0;
      border-bottom: 1px solid #dde2ed;
    }

    .rating {
      background: #fafbfc;
      border-radius: 8px;
      padding: 20px 0;
      text-align: center;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        color: #6a778a;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      .level {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 100%;
        color: #69bd5b;
      }
    }
  }
`;

export const SectionHeader = styled.div`
  margin-left: 17px;
  margin-bottom: 15px;
  border-bottom: 1px solid #efefef;

  &.note-border {
    border-bottom: none;
  }

  .section__title {
    ${props => props.isFreestyle && `display: flex; justify-content: space-between;`}
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #1e0a3c;
    margin-bottom: 5px;
    position: relative;
    word-break: break-all;

    .section-completed-icon {
      transform: translate(10px, 1px);
    }

    ::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      background-color: #d5d5d5;
      border-radius: 50%;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .section__description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #1e0a3c;
    margin-bottom: 15px;

    .item {
      display: inline-block;
      margin-right: 20px;

      :last-child {
        margin-right: 0;
      }

      img {
        margin-right: 6px !important;
      }

      > * {
        display: inline-block;
        vertical-align: middle;
      }

      .note {
        color: #333333;
        font-size: 13px;
        line-height: 150%;
        margin-bottom: 11px;
      }

      .note.freestyle-section {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }

      .view-more {
        display: inline-block;
        color: #5158cf;
        font-weight: 600;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }

      .freestyle-section-info {
        display: flex;
        .freestyle-info-item {
          display: flex;
          align-items: center;
          margin-right: 22px;
          svg {
            margin-right: 6px;
          }
        }
      }
      .breaker {
        height: 1px;
        width: 100%;
        background: #efefef;
        margin: 15px 0px 15px;
      }
    }
  }
`;

export const SetItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .set__value {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    width: 210px;
    cursor: pointer;
  }

  .highlighted {
    color: #fd6666;
  }

  .personal-best {
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
`;

export const SetContainer = styled.div`
  .sets__container {
    margin-left: 10px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 15px;
    position: relative;
  }

  .set-history__item {
    margin-bottom: 15px;
  }

  .item__title {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #5158cf;
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 6px;
    background: #fff;

    .exercise-name,
    .view-note {
      color: #5158cf;

      :hover {
        color: #5559ff;
      }
    }

    .view-note {
      svg {
        path {
          fill: currentColor;
        }
      }
    }

    :hover {
      background: #f5f7fe;
      border-radius: 3px;

      .one_rep_max {
        display: none;
      }
      .icons_wrapper {
        .view-note,
        .exHistory {
          opacity: 1;
        }
      }

      .exHistory__trigger {
        background-color: #f5f7fe;
      }
    }

    .icons_wrapper {
      display: flex;
      padding: 0 0 0 6px;
      background-color: inherit;
      min-width: 80px;
      justify-content: flex-end;
      align-items: center;

      .view-note,
      .exHistory {
        opacity: 0;
      }

      .exHistory.exHistory--open {
        opacity: 1;
      }
    }

    img.ui.image,
    .ui.image {
      margin: 0 8px;
    }
    .media-preview--open {
      .__react_component_tooltip {
        visibility: hidden;
      }
    }

    .__react_component_tooltip {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      padding: 8px 15px;
    }

    .one_rep_max {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      text-align: right;
      color: #1e0a3c;
      position: absolute;
      right: 0;
    }

    .exHistory--open {
      .exHistory__trigger {
        .__react_component_tooltip.app-tooltip {
          display: none;
        }
      }
    }

    .exHistory__trigger {
      background-color: #fff;
      border: none;
      width: unset;
      height: unset;
      padding-left: 18px;
      padding-right: 6px;
    }

    .exHistory--open .exHistory__trigger,
    .exHistory__trigger:hover {
      svg {
        path {
          stroke: #5559ff;
        }
      }
    }
  }

  .item__body {
    padding-left: 30px;

    ol {
      margin: 0;
      padding: 0;
      counter-reset: item;
      list-style-type: decimal-leading-zero;

      li {
        padding: 5px 0;
      }
    }
    .one-max {
      min-width: 57px;
      text-align: right;
    }
  }

  &.set-history--super-set {
    .sets__container {
      ::before {
        content: '';
        width: 11px;
        top: 8px;
        bottom: calc(100% / 2 - 10px);
        left: -17px;
        position: absolute;
        border: 1px dashed #c1c1c1;
        border-right: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  .app-tooltip-white {
    border: 1px solid #dfe4e5 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
  }

  .__react_component_tooltip.app-tooltip-white.app-tooltip-white-small {
    min-width: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    box-shadow: none !important;
    border: 1px solid #dfe4e5;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
  }

  .personal-best-info {
    padding: 8px 5px;

    .exercise__title {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      color: #5c5bbd;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 5px;
      border-bottom: 1px solid rgba(118, 118, 118, 0.1);
      min-width: 240px;

      :last-child {
        border-bottom: none;
      }

      .name {
        display: flex;
        justify-content: flex-start;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        color: #5f5f5f;

        img {
          margin-right: 5px !important;
        }
      }

      .value {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #1e0a3c;
      }
    }
  }
`;

export const SectionBody = styled.div`
  .super-set {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #7b7e91;
    margin-bottom: 2px;
    padding-left: 17px;
  }

  ${SetContainer} {
    :last-child {
      .sets__container {
        border-color: transparent;
        margin-bottom: 0;
      }
    }
  }
`;

export const SectionContainer = styled.div`
  padding: 20px 20px 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #5f5f5f;
  background: #ffffff;
  border: 1px solid #dde2ed;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const Details = styled.div`
  padding: 20px 30px;
  border: 1px solid #dde2ed;
  border-radius: 5px;

  .value {
    display: flex;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: #1e0a3c;
    svg {
      margin-right: 6px;
    }
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0px;
    }
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .view-workout {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #5c5bbd;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

export const ResultContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  width: 550px;
  height: auto;
`;

export const MainInfoContainer = styled.div`
  ${'' /* height: calc(100% - 70px); */}
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
  max-height: calc(100vh - 110px);
  background-color: #f7f8fc;
  position: relative;
`;

export const Assigned = styled.div`
  width: 180px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;

export const EachSideLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #adb1cb;
  transform: translateX(-25px);
`;

export const ContainerWithHeaderBackground = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-color: white;
  height: 100%;
  padding: 25px;
  border-radius: 5px;
`;

export const HeaderText = styled.div`
  margin: 20px 0px;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
`;

export const ActivityEmojiContainer = styled.div`
  width: 112px;
  height: 112px;
  background-color: white;
  border-radius: 50%;
  margin: auto;
  padding: 9px;
`;

export const ActivityEmoji = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 52px;
  ${'' /* font-size controls the emoji size */}
  text-align: center;
`;

export const NotesContainer = styled.div`
  .note-header {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #202353;
    opacity: 0.4;
    text-transform: uppercase;
    margin-top: 30px;
  }
  .note-description-container {
    margin-top: 7px;
  }
  .note-description {
    margin-top: 7px;
    .text-editable__input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #5f5f5f;
    }
    .text-editable__container {
      padding: 0px;
    }
  }
`;

export const VideoThumbnailContainer = styled.div`
  height: 120px;
  width: 204.45333862304688px;
  border-radius: 8px;
  margin: auto;
  background-size: cover;
  background-image: url('${props => props.thumbnail}');
`;
