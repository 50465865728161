/**
 * @flow
 */

import {
  GET_PROFILE,
  FAILED_GET_PROFILE,
  ERROR_GET_PROFILE,
} from 'actions/profile';

import type { Action } from 'types/redux';

export const isGettingProfile = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case GET_PROFILE:
      return true;
    default:
      return false;
  }
};

export const failureToGetProfile = (state: any = null, action: Action) => {
  switch (action.type) {
    case FAILED_GET_PROFILE:
      return action.error;
    default:
      return null;
  }
};

export const errorGetProfile = (state: any = null, action: Action) => {
  switch (action.type) {
    case ERROR_GET_PROFILE:
      return action.error;
    default:
      return null;
  }
};
