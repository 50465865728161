import styled from 'styled-components';

export const GlobalSearchResultsWrapper = styled.div`
  background: #FFFFFF;;
  border-radius: 5px;
  border: 1px solid #D4D7D9;
  margin-top: -2px;
  z-index: 1;

  .globalSearchResults__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  .globalSearchResults__mostRecents {
    font-weight: 600;
    font-size: 11px;
    line-height: 120%;
    text-transform: uppercase;
    color: #202353;
  }

  .globalSearchResults__hotkey {
    font-size: 12px;
    line-height: 120%;
    color: #202353;
    display: flex;
    align-items: center;

    &--text {
      /* cursor: pointer; */
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .globalSearchResults__body {
    height: 220px;
    overflow: auto;

    &--noResults {
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      opacity: 0.4;
      text-align: center;
      margin-top: 100px;
    }
  }
`;

export const SearchItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px 10px 15px;
  background: #FFFFFF;
  border-bottom: 1px solid #F0F0F0;
  cursor: pointer;

  &:hover {
    background: #F8F8FF;

    .searchItem__info--name {
      color: #5C5BBD;
      font-weight: 600;
    }
  }

  .searchItem__leftContent {
    display: flex;
  }

  .searchItem__avatar {
    margin-right: 10px;
  }

  .searchItem__info {
    
    &--name {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #333333
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 215px;
    }

    &--email {
      font-size: 12px;
      line-height: 16px;
      color: #878787;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 215px;
    }
  }

  .searchItem__rightContent {
    display: flex;
  }
`;