import get from 'lodash/get';
import { ERROR_CODE_GET_MEAL_PLAN, ERROR_CODE_UPDATE_MEAL_PLAN, Types } from './actions';
import { LIST_DAY_OF_WEEK, MEAL_PLAN_STATUS, PER_PAGE, STATUS_ADD_WEEK } from 'components/MealPlanDetail/constants';
import { ERROR_CODE_FORBIDDEN, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { MEAL_PLAN_HEADER_TABS } from 'components/MealPlans/constants';

export const INITIAL_STATE_MEAL_PLANS = {
  list: [],
  detailMealPlan: {},
  loading: false,
  loadingCreate: false,
  total: 0,
  totalAll: 0,
  totalMyPlans: 0,
  totalDraft: 0,
  filters: {
    page: 1,
    per_page: PER_PAGE,
    sort: -1,
    sorter: 'updated_at',
    only_my_meal_plans: false,
    text_search: '',
    status: [MEAL_PLAN_STATUS.PUBLISH],
  },
  detailMealPlanByWeek: {},
  detailMealPlanByDay: {
    note: '',
    show_dietary_info: false,
    meal_group: [],
    selected: '',
    headerTitle: '',
    isChange: false,
  },
  loadingByDay: false,
  editModeByDay: false,
  assignedList: [],
  rearrangeMode: false,
  tab: MEAL_PLAN_HEADER_TABS.ALL_PLAN,
};

export default (state = INITIAL_STATE_MEAL_PLANS, action) => {
  const { type, payload } = action;
  const { detailMealPlanByDay, rearrangeMode, detailMealPlan, detailMealPlanByWeek } = { ...state };

  switch (type) {
    case Types.MEAL_PLAN_ADD_REQUEST: {
      return Object.assign({}, state, {
        loadingCreate: true,
      });
    }

    case Types.MEAL_PLAN_GET_LIST_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.MEAL_PLAN_ADD_SUCCESS: {
      const { list } = state;

      return Object.assign({}, state, {
        loadingCreate: false,
        list: [payload, ...list],
      });
    }

    case Types.MEAL_PLAN_ADD_FAILED: {
      return Object.assign({}, state, {
        loadingCreate: false,
      });
    }

    case Types.MEAL_PLAN_GET_LIST_SUCCESS: {
      const { resList, total, page } = payload;
      const { list = [] } = state;

      return Object.assign({}, state, {
        loading: false,
        list: page > 1 ? [...list, ...resList] : resList,
        total: total,
      });
    }

    case Types.MEAL_PLAN_GET_TOTAL_LIST: {
      const { all, only_my_meal_plan, isDraft, only_draft } = payload;
      const totalKey = isDraft ? 'totalDraft' : 'totalMyPlans';

      return Object.assign({}, state, {
        totalAll: all,
        [totalKey]: only_my_meal_plan,
        loading: false,
        totalDraft: only_draft,
      });
    }

    case Types.MEAL_PLAN_GET_LIST_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.MEAL_PLANS_CHANGE_QUERY_PARAMS: {
      const filters = { ...state.filters, ...payload };
      return Object.assign({}, state, {
        filters: filters,
      });
    }
    case Types.MEAL_PLAN_SELECT_MEAL_DAY_DETAIL: {
      const { id } = payload;

      return Object.assign({}, state, {
        detailMealPlanByDay: { ...detailMealPlanByDay, selected: id },
      });
    }

    case Types.MEAL_PLAN_ADD_MEAL_DAY_DETAIL: {
      const { data, status } = payload;

      return Object.assign({}, state, {
        detailMealPlanByDay: {
          ...detailMealPlanByDay,
          meal_group: [...detailMealPlanByDay.meal_group, data],
          isChange: status,
        },
      });
    }

    case Types.MEAL_PLAN_UPDATE_DATA_DAY_DETAIL: {
      const { data, status } = payload;

      return Object.assign({}, state, {
        detailMealPlanByDay: { ...detailMealPlanByDay, ...data, isChange: status },
      });
    }

    case Types.MEAL_PLANS_REMOVE_MEAL_PLAN: {
      const { list } = state;

      const removedList = list.filter(item => get(item, '_id', '') !== payload);
      return Object.assign({}, state, {
        list: removedList,
      });
    }
    case Types.MEAL_PLANS_SHARE_MEAL_PLAN: {
      const { list } = state;
      const { mealPlanId, data, user } = payload;
      const sharePrivate = get(data, 'share') === TEAM_SHARE_PRIVATE;
      const otherOwner = get(user, '_id', '') !== get(data, 'author', '');

      const newList = otherOwner && sharePrivate ? list.filter(item => get(item, '_id', '') !== mealPlanId) : list;
      return Object.assign({}, state, {
        list: newList,
      });
    }

    case Types.MEAL_PLAN_GET_DETAIL_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, {
        detailMealPlan: data,
        detailMealPlanByDay: { ...detailMealPlanByDay, isChange: false },
      });
    }

    case Types.MEAL_PLAN_GET_DETAIL_BY_WEEK_SUCCESS: {
      const { data } = payload;

      const weeks = get(state, 'detailMealPlan.weeks', []);
      const weekId = get(data, '_id', '');

      const week_index = weeks.findIndex(item => item === weekId);

      const result = { ...data, week_index };

      return Object.assign({}, state, {
        detailMealPlanByWeek: result,
      });
    }

    case Types.MEAL_PLAN_UPDATE_BY_WEEK_SUCCESS: {
      const { data } = payload;
      const detailMealPlanByWeek = get(state, 'detailMealPlanByWeek', {});
      return Object.assign({}, state, {
        detailMealPlanByWeek: { ...detailMealPlanByWeek, ...data, selected: get(data, '') },
      });
    }

    case Types.MEAL_PLAN_RESET_DETAIL: {
      return Object.assign({}, state, {
        detailMealPlan: {},
        rearrangeMode: false,
      });
    }

    case Types.MEAL_PLAN_START_SUBMIT: {
      return Object.assign({}, state, { rearrangeMode: false });
    }

    case Types.MEAL_PLAN_RESET_DETAIL_BY_WEEK: {
      return Object.assign({}, state, {
        detailMealPlanByWeek: {},
      });
    }

    case Types.MEAL_PLAN_RESET_DETAIL_BY_DAY: {
      return Object.assign({}, state, {
        detailMealPlanByDay: INITIAL_STATE_MEAL_PLANS.detailMealPlanByDay,
      });
    }

    case Types.MEAL_PLAN_GET_DAY_OF_WEEK_REQUEST: {
      return Object.assign({}, state, {
        loadingByDay: true,
      });
    }

    case Types.MEAL_PLAN_GET_DAY_OF_WEEK_SUCCESS: {
      const { data } = payload;
      const dayOfWeek = LIST_DAY_OF_WEEK[get(data, 'day_index', 0)].dayOfWeek;
      const weekIndex = get(data, 'week_index', 0) + 1;

      const detailMealPlanByDay = get(state, 'detailMealPlanByDay', {});
      return Object.assign({}, state, {
        detailMealPlanByDay: {
          ...detailMealPlanByDay,
          ...data,
          selected: get(data, 'meal_group[0]._id', ''),
          headerTitle: `${dayOfWeek}, Week ${weekIndex}`,
        },
        loadingByDay: false,
      });
    }

    case Types.MEAL_PLAN_UPDATE_DAY_OF_WEEK_REQUEST: {
      return Object.assign({}, state, {
        loadingByDay: true,
      });
    }

    case Types.MEAL_PLAN_UPDATE_DAY_OF_WEEK_SUCCESS: {
      const { data } = payload;
      const { detailMealPlanByDay } = state;

      return Object.assign({}, state, {
        detailMealPlanByDay: {
          ...detailMealPlanByDay,
          ...(data && data.meal_plan_day),
          isChange: false,
        },
        loadingByDay: false,
      });
    }

    case Types.MEAL_PLAN_UPDATE_DAY_OF_WEEK_FAILED: {
      const { errorCode } = payload;

      return Object.assign({}, state, {
        detailMealPlanByDay: {
          ...detailMealPlanByDay,
          isChange: !(
            errorCode === ERROR_CODE_GET_MEAL_PLAN ||
            errorCode === ERROR_CODE_UPDATE_MEAL_PLAN ||
            errorCode === ERROR_CODE_FORBIDDEN
          ),
        },
        loadingByDay: false,
      });
    }

    case Types.MEAL_PLAN_GET_DAY_OF_WEEK_FAILED: {
      return Object.assign({}, state, {
        loadingByDay: false,
      });
    }

    case Types.MEAL_PLAN_CHANGE_MODE_EDIT_BY_DAY: {
      const { status } = payload;

      return Object.assign({}, state, {
        editModeByDay: status,
      });
    }

    case Types.MEAL_PLAN_ADD_WEEK_SUCCESS: {
      const { weekIndex, type, data = {} } = payload;
      const { detailMealPlan } = { ...state };

      const weeks = get(detailMealPlan, 'weeks', []);
      weeks.splice(weekIndex, 0, get(data, 'week._id', ''));

      let dataState = {
        detailMealPlan: { ...detailMealPlan, number_of_weeks: get(data, 'number_of_weeks', 0), weeks },
      };

      if (type === STATUS_ADD_WEEK.BEFORE) {
        dataState = { ...dataState, detailMealPlanByWeek: { ...detailMealPlanByWeek, week_index: weekIndex + 1 } };
      }

      return Object.assign({}, state, dataState);
    }

    case Types.MEAL_PLAN_REMOVE_WEEK_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, { detailMealPlan: data });
    }

    case Types.MEAL_PLAN_UPDATE_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, {
        detailMealPlan: data,
      });
    }

    case Types.MEAL_PLAN_GET_ASSIGNED_LIST_SUCCESS: {
      const { data } = payload;

      return Object.assign({}, state, {
        assignedList: data,
      });
    }

    case Types.MEAL_PLAN_RESET_FILTERS: {
      return Object.assign({}, state, {
        filters: INITIAL_STATE_MEAL_PLANS.filters,
      });
    }

    case Types.MEAL_PLAN_CHANGE_MODE_REARRANGE: {
      return Object.assign({}, state, {
        rearrangeMode: !rearrangeMode,
      });
    }

    case Types.MEAL_PLAN_UPDATE_DETAIL: {
      const { data } = payload;
      return Object.assign({}, state, {
        detailMealPlan: { ...detailMealPlan, ...data },
      });
    }

    case Types.MEAL_PLAN_UPDATE_DETAIL_BY_WEEK: {
      const { data } = payload;
      return Object.assign({}, state, {
        detailMealPlanByWeek: { ...detailMealPlanByWeek, ...data },
      });
    }

    case Types.MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_SUCCESS: {
      const { data } = payload;
      const { days = [] } = detailMealPlanByWeek;

      const updatedDays = (days || []).map(day => {
        const { day_index } = day || {};
        const updatedDay = data.find(dayR => dayR && dayR.day_index === day_index);

        if (day_index === (updatedDay && updatedDay.day_index)) {
          return { ...day, day_meal: updatedDay };
        }
        return day;
      });

      return Object.assign({}, state, {
        detailMealPlanByWeek: { ...detailMealPlanByWeek, days: updatedDays },
      });
    }

    case Types.MEAL_PLAN_SET_HEADER_TABS: {
      const { tab } = payload;

      return Object.assign({}, state, {
        tab: tab,
      });
    }

    case Types.MEAL_PLAN_SET_CURRENT_LIST: {
      return Object.assign({}, state, {
        list: [],
        total: 0,
        totalAll: 0,
        // TODO: Keep totalMyPlans & totalDraft
        // totalMyPlans: 0,
        // totalDraft: 0,
      });
    }

    default:
      return state;
  }
};
