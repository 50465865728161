import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #202353;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 35px;
`;

export const MealCategory = styled.div``;

export const Label = styled.div`
  ${baseText}
  height: 23px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .icon-wrapper {
    margin-left: 3px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    svg {
      width: 10px;
      height: 10px;
      path {
        stroke: #5158cf;
      }
    }
    &:hover {
      background-color: #f0f1ff;
    }
  }
  .__react_component_tooltip {
    ${baseText}
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    padding: 0 16px;
    border-radius: 4px !important;
    height: 34px !important;
    display: flex;
    align-items: center;
    &.place-top {
      margin-top: -8px !important;
    }
  }
`;

export const Empty = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  border-radius: 5px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    path {
      stroke: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    svg path {
      stroke: #5158cf;
    }
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      &:hover {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: #f8f8f8;
        color: #202353;
        svg path {
          stroke: #202353;
        }
      }
    `}
`;

export const ListRecipe = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RecipeItemWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  ${({ isDisable }) =>
    isDisable &&
    css`
      cursor: default !important;
    `}
  .image-with-fallback {
    height: 100px;
    width: 150px;
  }
  .recipe-name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 50px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin: 120px 0;
  span {
    ${baseText}
    color: #7b7e91;
  }
  img {
    width: 186px;
    height: 158px;
  }
`;

export const MacroInfoWrapper = styled.div`
  margin: 0 9px;
`;
