import styled from 'styled-components';
import { MenuTrigger } from 'shared/Icons';

export const DropdownWrapper = styled.div`
  svg path {
    fill: #777c8f;
  }

  .evf-dropdown__menu {
    border-radius: 4px;
    border: 1px solid #dadfea;
    width: 175px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  }
  .evf-dropdown__option {
    color: #202353;
    font-size: 13px;
    line-height: 18px;
    &:hover {
      background-color: #eaebff;
      cursor: pointer;
    }
    span {
      padding-left: 10px;
    }
  }
  .disable {
    pointer-events: none;
    span {
      color: #202353;
      opacity: 0.2;
    }
  }

  .resource-detail-options-tooltip {
    margin-left: 10px;
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: transparent;
  border-radius: 6px;
  width: 24px;
  height: 24px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: #728096;
  }

  &:hover {
    .dot,
    .dot::before,
    .dot::after {
      background-color: #000000;
    }
  }
`;
