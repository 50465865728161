// Libs
import React, { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import { ReactComponent as ArrowDownInputIcon } from 'assets/icons/arrow-down-input.svg';
import { ReactComponent as ArrowUpInputIcon } from 'assets/icons/arrow-up-input.svg';
import { METRIC_VALUE_LIMIT } from 'constants/commonData';
// Style
import * as S from './style';

const FitInchUnit = props => {
  const {
    hasError = false,
    onChange = () => {},
    fitValue,
    inchValue,
    onFocus: onFocusProp = () => {},
    onBlur: onBlurProp = () => {},
    className = '',
    autoFocus = true,
    showArrow = false,
    min = 0,
    max = 200000,
    idMetricType,
    isMulti = false,
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const [idMetricTypeSelected, setIdMetricTypeSelected] = useState('');

  const fitRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Logic to handle click outside the element
        if (!isMulti || idMetricTypeSelected === idMetricType) {
          onBlur();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [idMetricTypeSelected, idMetricType]);

  const onBlur = () => {
    setIsFocus(false);
    onBlurProp();
  };

  const onFocus = () => {
    setIsFocus(true);
    onFocusProp();
    setIdMetricTypeSelected(idMetricType);
  };

  const handleChangeFtValue = values => {
    const { value = 0 } = values;
    if (value > METRIC_VALUE_LIMIT) {
      onChange('ft', METRIC_VALUE_LIMIT);
      return;
    }
    onChange('ft', value);
  };

  const onChangeInchValue = values => {
    const { value = 0 } = values;
    if (value > METRIC_VALUE_LIMIT) {
      onChange('inch', METRIC_VALUE_LIMIT);
      return;
    }

    onChange('inch', value || '');
  };

  const handleClickInput = event => {
    event.stopPropagation();
  };

  const onlyAllowNumber = event => {
    const disallowedKeyCodes = [45, 43, 101, 44, 46, 188, 190];
    if (disallowedKeyCodes.includes(event.which)) {
      event.preventDefault();
    }
  };

  const handleArrowInput = (unit, isIncrease) => {
    const isFt = unit === 'ft';
    const currentVal = isFt ? fitValue : inchValue;
    let nextVal = Number(currentVal) + (isIncrease ? 1 : -1);
    if (isFt && nextVal > 8) {
      nextVal = 8;
    }
    if (!isFt && nextVal > 11) {
      nextVal = 11;
    }
    onChange(unit, nextVal > 0 ? nextVal : '');
  };

  const renderArrowInput = unit => {
    const isFt = unit === 'ft';
    const disableUp = isFt ? fitValue >= 8 : inchValue >= 11;
    const disableDown = isFt ? fitValue <= 0 : inchValue <= 0;

    return (
      <div className="arrow-inputs">
        <span
          onClick={() => handleArrowInput(unit, true)}
          className={classNames('arrow-input up', { disabled: disableUp })}
        >
          <ArrowUpInputIcon />
        </span>
        <span
          onClick={() => handleArrowInput(unit, false)}
          className={classNames('arrow-input down', { disabled: disableDown })}
        >
          <ArrowDownInputIcon />
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (fitRef.current && autoFocus) {
      fitRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <S.FitInchInputWrapper
      isFocus={isFocus}
      onFocus={onFocus}
      hasError={hasError}
      className={className}
      ref={wrapperRef}
    >
      <S.FitInchInput>
        <S.InputItem className="input-item">
          <NumberFormat
            onValueChange={handleChangeFtValue}
            value={fitValue}
            allowNegative={false}
            decimalScale={0}
            isAllowed={values => {
              const { floatValue = 0 } = values;
              return floatValue >= min;
            }}
          />
          {showArrow && renderArrowInput('ft')}
          <span>ft</span>
        </S.InputItem>
        <S.InputItem className="input-item">
          <NumberFormat
            onValueChange={onChangeInchValue}
            value={inchValue}
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
            isAllowed={values => {
              const { floatValue = 0 } = values;
              return floatValue >= min;
            }}
          />
          {showArrow && renderArrowInput('inch')}
          <span>in</span>
        </S.InputItem>
      </S.FitInchInput>
    </S.FitInchInputWrapper>
  );
};

export default FitInchUnit;
