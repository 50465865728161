import styled, { css } from 'styled-components';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: #151619;
`;

export const ItemPhoto = styled.div`
  border: 1px solid transparent;
  padding: 14px 0;
  height: 135px;
  border-radius: 4px;
  background-color: #fff;
`;

export const ItemPhotoWrapper = styled.div`
  margin-bottom: 12px;
  border-radius: 6px;
  margin-bottom: 4px;
  border: 2px solid transparent;

  ${props =>
    !props.compareMode &&
    css`
      &:hover {
        border: 2px solid rgba(206, 204, 254, 0.4);
        ${ItemPhoto} {
          border: 1px solid #5e59ff;
        }
      }
      ${props =>
        props.active &&
        css`
          border: 2px solid rgba(206, 204, 254, 0.4);
          ${ItemPhoto} {
            border: 1px solid #5e59ff;
          }
        `}
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 15px;
  ${baseText}
`;

export const Date = styled.span`
  letter-spacing: -0.02em;
`;

export const Unit = styled.span`
  font-size: 10px;
  line-height: 13.62px;
  color: #6a778a;
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 2px;
  padding: 0 15px 4px;

  ::-webkit-scrollbar {
    height: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    border-radius: 99px !important;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0 !important;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 99px !important;
  }
`;

export const Item = styled.div`
  width: 69px;
  height: 82px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid transparent;
  padding: 1px;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;

  svg {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }

  img {
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid #e5e5e5;
  }

  &:hover {
    border: 1px solid #5e59ff;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid #5e59ff;
    `}

  ${props =>
    props.disabledItem &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}
`;

export const ItemWrapper = styled.div`
  .photo-item-tooltip {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    padding: 4px 8px;
    border-radius: 5px;

    ::first-letter {
      text-transform: capitalize;
    }

    &.place-top {
      margin-top: -2px !important;
    }

    &.show {
      background-color: #151619;
    }

    &.type-dark.place-top:after {
      border-top-color: #151619;
      border-top-width: 7px !important;
      bottom: -5px;
    }
  }
`;

export const S3ImageWithFallbackWrapper = styled(S3ImageWithFallback)`
  height: 100%;
`;

export const MockupImg = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
`;
