import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #202353;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : '762px')};
    height: ${({ customHeight }) => (customHeight ? `${customHeight}px` : '740px')};
    max-height: 100%;
  }
  &.ui.modal > :first-child {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 25px 0 0;
    .title {
      ${baseText}
      margin: 0 30px 15px;
    }
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 1px solid #f0f0f2;
    height: 60px;
    background-color: #fff;
    padding: 0 33px;
    display: flex;
    align-items: center;
    justify-content: end;
    &.left-action {
      justify-content: space-between;
      gap: 10px;
    }
  }
  &.ui.modal > .content {
    height: 100%;
    border-radius: 15px;
    padding: 0;
  }
`;

export const FilterCategory = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 45px;
  box-shadow: 0px 3px 12px 0px #0000000d;
`;

export const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  height: 100%;
  padding-top: 6px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    ${baseText}
    font-weight: 600;
    font-size: 11px;
    line-height: 17px;
    color: #7b7e91;
  }
  &.breakfast {
    width: 52px;
  }
  &.lunch {
    width: 33px;
  }
  &.dinner,
  &.others {
    width: 37px;
  }
  &.snack {
    width: 32px;
  }
  &.soup {
    width: 27px;
  }
  &.salad-bowl {
    width: 65px;
  }
  &:hover {
    color: #202353;
    border-bottom: 2px solid #5158cf;
    span {
      color: ${props => (props.active ? '#5158cf' : '#202353')};
    }
  }
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid #5158cf;
      .category-icon path {
        fill: #5158cf;
      }
      span {
        color: #5158cf;
      }
      &:hover {
        span {
          color: #5158cf;
        }
      }
    `}
`;
