import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';

import * as S from '../style';

import { useMyContext } from '../context/ProgramTemplatesContext';
import InstructionModalContent from './InstructionModalContent';

const InstructionModal = () => {
  const { openFullscreenModal, openDetailModal, toggleInstructionModal, openInstructionModal } = useMyContext();
  const opened = openFullscreenModal && openDetailModal && openInstructionModal;

  return (
    <S.FullscreenModalWrapper
      className={classNames('instruction', {
        open: opened,
      })}
    >
      <RootCloseWrapper event="click" disabled={!openInstructionModal} onRootClose={toggleInstructionModal}>
        <S.FullscreenModalContainer
          className={classNames('instruction', {
            open: opened,
          })}
        >
          {openFullscreenModal && openDetailModal && openInstructionModal && <InstructionModalContent />}
        </S.FullscreenModalContainer>
      </RootCloseWrapper>
    </S.FullscreenModalWrapper>
  );
};

export default InstructionModal;
