import styled from 'styled-components';
import NoResults from 'assets/icons/form-responses-no-results.png';

export const Wrapper = styled.div`
  background: #ffffff;
  border-right: 1px solid #eeeeee;
  padding: 24px 0px;
  .loading-indicator {
    padding-top: 20px;
  }
`;

export const ClientContainer = styled.div`
  padding: 8px 12px;
  margin: 8px 0px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background: rgba(94, 89, 255, 0.1);
    .last-answered-at {
      color: #5e59ff;
    }
  }
  .client-avatar {
    flex: 0 0 40px;
    margin-right: 8px;
  }
  .client-name {
    -webkit-font-smoothing: auto;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #202353;
    flex: 1 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .last-answered-at {
    -webkit-font-smoothing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: rgba(32, 35, 83, 0.6);
    flex: 0 0 45px;
  }
  &.active {
    .last-answered-at {
      color: #5e59ff;
    }
    background: rgba(94, 89, 255, 0.1);
  }
  ${'' /* :not(:last-child) {
    position: relative;
    ::after {
      content: '';
      height: 1px;
      opacity: 0.1;
      width: 100%;
      background-color: #767676;
      position: absolute;
      bottom: -8px;
      left: 0px;
    }
  } */}
`;

export const Breaker = styled.div`
  height: 1px;
  width: 100%;
  background-color: #767676;
  opacity: 0.1;
`;

export const SearchContainer = styled.div`
  padding: 0px 11px 0px 8px;
  height: 52px;
  .search-input.client-list-search {
    height: 100%;
    width: 100%;
    input {
      background-color: #f7f8fc;
      border-radius: 8px;
      border: none;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      border: 1px solid #f7f8fc;
      :focus {
        background-color: #ffffff;
        border: 1px solid #5e59ff;
      }
      ::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: rgba(123, 126, 145, 0.6);
      }
    }
  }
`;

export const EmptyContainer = styled.div`
  margin-top: 50%;
  .img-container {
    width: fit-content;
    margin: auto;
  }
  .text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #9a9da6;
  }
  ${'' /* background-image: url(${NoResults});
  background-repeat: no-repeat;
  width: 105px;
  height: 119.01px; */}
`;

export const ClientsList = styled.div`
  padding: 0px 8px;
  overflow-y: scroll;
  height: calc(100vh - 209px);
`;
