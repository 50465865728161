import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { toggleConfirmModal } from 'actions/modal';

import { CDN_URL } from 'constants/commonData';

import { pluralize } from 'utils/commonFunction';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import ConfirmModal from 'shared/ConfirmModal';
import { SharedTooltip } from 'shared/SharedTooltip';
import * as S from './style';

const countNumberOfResource = collection => {
  return _.reduce(
    collection.sections,
    (sum, section) => {
      return sum + section.resources.length;
    },
    0,
  );
};

const RenderDropdownItem = ({ onRemoveClick, className, toggleConfirmModal, collection }) => {
  const renderOption = () => {
    const content = <p>Would you like to remove access of this resource collection for this client?</p>;

    return (
      <Option
        key="delete"
        onClick={e => {
          toggleConfirmModal(
            true,
            <S.ConfirmModal
              headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
              newStyle
              confirmButtonTitle="Remove"
              title="Remove"
              content={content}
              onConfirm={onRemoveClick}
            />,
          );
        }}
        triggerIcon={({ open }) => (
          <div data-tip data-for="client-resource-collection-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="client-resource-collection-item-options-tooltip" />
          </div>
        )}
      >
        <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
        <span>Remove</span>
      </Option>
    );
  };

  return (
    <S.DropdownWrapper className={className}>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="client-resource-collection-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="client-resource-collection-item-options-tooltip" />
          </div>
        )}
      >
        {renderOption()}
      </DropDown>
    </S.DropdownWrapper>
  );
};

function ClientCollectionItem(props) {
  const { collection, onRemove, className, toggleConfirmModal } = props;

  if (!collection) {
    return null;
  }

  const goDetail = () => {
    props.push('/home/studio-collection/' + collection._id);
  };

  const onRemoveClick = () => {
    onRemove();
  };

  return (
    <S.Wrapper onClick={() => goDetail()}>
      <div className="collectionHeading">
        <div className="collectionHeading__icon" />
        <div className="collectionHeading__name">{collection.name}</div>
        <RenderDropdownItem
          onRemoveClick={onRemoveClick}
          className={className}
          toggleConfirmModal={toggleConfirmModal}
          collection={collection}
        />
      </div>
      <div className="collectionInfo">
        <div className="collectionInfo__item">
          <span>Available for </span>
          <b>
            {collection.number_of_clients} {pluralize('client', collection.number_of_clients)}
          </b>
        </div>
        <div className="collectionInfo__item">
          <span>{pluralize('Resource', countNumberOfResource(collection))}:&nbsp;</span>
          <b>{countNumberOfResource(collection)}</b>
        </div>
      </div>
    </S.Wrapper>
  );
}

const mapDispatchToprops = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(null, mapDispatchToprops)(ClientCollectionItem);
