import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { RootCloseWrapper } from 'react-overlays';

import { formatSameYear } from 'utils/commonFunction';
import { DateInput } from 'shared/FormControl';
import CalendarIcon from 'assets/icons/calendar-icon.svg';

import * as S from '../style';

const Calendar = props => {
  const { isYesterday, startDate, setStartDate, timezone } = props;
  const [open, setOpen] = useState(false);

  const renderDateTimeInput = props => {
    return (
      <DateInput
        {...props}
        onClick={() => {
          setOpen(!open);
        }}
        text={
          isYesterday.format('MMM DD, YYYY') === startDate.format('MMM DD, YYYY')
            ? 'Yesterday'
            : formatSameYear(startDate)
        }
        isIcon={CalendarIcon}
        className="workout-completion-calender"
      />
    );
  };

  const handleValidateDate = currentDate =>
    currentDate.startOf('day').isSameOrBefore(moment.tz(timezone).subtract(1, 'day'), 'day');

  const onChangeDate = date => {
    setOpen(false);
    setStartDate(date);
  };

  return (
    <RootCloseWrapper
      event="click"
      onRootClose={() => {
        setOpen(false);
      }}
    >
      <S.DatePicker>
        <Datetime
          open={open}
          onFocus={() => {
            setOpen(true);
          }}
          onBlur={() => {
            setOpen(false);
          }}
          value={startDate}
          timeFormat={false}
          strictParsing={false}
          onChange={onChangeDate}
          className="new-ui"
          isValidDate={handleValidateDate}
          renderInput={renderDateTimeInput}
        />
      </S.DatePicker>
    </RootCloseWrapper>
  );
};

Calendar.propTypes = {
  isYesterday: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
};

export default Calendar;
