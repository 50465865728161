import _ from 'lodash';
import { Types } from './exercise.actionCreators';
import { Types as WorkoutBuilderTypes } from '../workout-builder/actions';
import { DEFAULT_FILTERS } from 'shared/ExerciseFilterPopup';

export const DEFAULT_STATE = {
  total: 0,
  listExercise: [],
  selectedExercise: [],
  body_parts: [],
  categories: [],
  fields: [],
  mostRecents: [],
  equipments: [],
  modalities: [],
  muscleGroup: [],
  movementPattern: [],
  loading: false,
  query: {
    page: 1,
    per_page: 50,
    q: '',
    sorter: 'last_interacted',
    sort: -1,
    ...DEFAULT_FILTERS,
  },
};

const ExerciseReducer = (state = DEFAULT_STATE, action) => {
  const { payload } = action;
  let newList = [];

  switch (action.type) {
    case Types.EXERCISE_LIBRARY_SUCCESS_GET_LIST:
      const { data } = payload;
      return Object.assign({}, state, { total: parseInt(data.total), listExercise: data.data, loading: false });

    case Types.EXERCISE_LIBRARY_FAILED_GET_LIST:
      return Object.assign({}, state, { loading: false });

    case 'SUCCESS_GET_EXERCISE_BODYPARTS':
      return Object.assign({}, state, {
        body_parts: action.payload.bodyparts,
      });

    case 'SUCCESS_GET_EXERCISE_CATEGORIES':
      return Object.assign({}, state, {
        categories: action.payload.data,
      });

    case 'SUCCESS_GET_EXERCISE_FIELDS':
      return Object.assign({}, state, {
        fields: action.payload.data,
      });

    case Types.LOAD_MOST_RECENT_EXERCISE_SUCCESS:
      return Object.assign({}, state, { mostRecents: action.payload.list });

    case WorkoutBuilderTypes.WORKOUT_BUILDER_ADD_EXERCISES_FROM_AI_TO_RECENT:
      return Object.assign({}, state, { mostRecents: state.mostRecents.concat(action.payload.exercises) });

    case Types.EXERCISE_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS:
      return Object.assign({}, state, {
        listExercise: _.map(state.listExercise, item =>
          item._id === payload.data._id ? { ...item, share: payload.data.share } : item,
        ),
      });

    case Types.EXERCISE_LIBRARY_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...payload.data } });

    case Types.EXERCISE_LIBRARY_RESET_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...DEFAULT_STATE.query } });

    case Types.EXERCISE_LIBRARY_LOADING_DATA:
      return Object.assign({}, state, { loading: true });

    case Types.EXERCISE_LIBRARY_UPDATE_SELECTED_EXERCISE:
      return Object.assign({}, state, { selectedExercise: payload.data });

    case Types.EXERCISE_LIBRARY_HIDE_EXERCISES_SUCCESS:
      return Object.assign({}, state, { selectedExercise: [] });

    case Types.UPDATE_TAG_IN_EXERCISES:
      const { id, name } = payload;
      newList = state.listExercise.map(item => ({
        ...item,
        tags: (item.tags || []).map(tag => (tag._id === id ? { ...tag, name } : tag)),
      }));
      return Object.assign({}, state, {
        listExercise: newList,
      });

    case Types.DELETE_TAG_IN_EXERCISES:
      newList = state.listExercise.map(item => ({
        ...item,
        tags: (item.tags || []).filter(tag => tag._id !== payload.id),
      }));
      return Object.assign({}, state, {
        listExercise: newList,
      });

    case Types.SUCCESS_GET_EXERCISE_EQUIPMENTS:
      return Object.assign({}, state, {
        equipments: payload.data,
      });

    case Types.SUCCESS_GET_EXERCISE_MODALITIES:
      return Object.assign({}, state, {
        modalities: payload.data,
      });

    case Types.SUCCESS_GET_EXERCISE_MUSCLE_GROUP:
      return Object.assign({}, state, {
        muscleGroup: payload.data,
      });

    case Types.SUCCESS_GET_EXERCISE_MOVEMENT_PATTERN:
      return Object.assign({}, state, {
        movementPattern: payload.data,
      });

    default:
      return state;
  }
};

export default ExerciseReducer;
