import styled from 'styled-components';

export const FooterLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  > * {
    :last-child {
      margin-right: 0;
    }
  }

  .total-clients {
    display: flex;
    align-items: center;

    font-size: 10px;
    line-height: 14px;
    color: #303030;
    font-weight: 600;

    .number {
      font-size: 12px;
      line-height: 16px;
      padding: 2px 14px;
      border-radius: 999px;
      background-color: #303030;
      min-width: 43px;
      margin-right: 3px;
      color: #fff;
      display: inline-block;
      text-align: center;
    }
  }

  .onboarding-flow {
    display: flex;
    align-items: center;
    gap: 8px;

    .onboarding-flow-label {
      .custom-import-csv {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        color: #6a778a;

        .custom-label {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .custom-hint-icon {
          &:hover {
            cursor: pointer;
            path {
              fill: #5c5bbd;
            }
          }
        }
      }

      .__react_component_tooltip.app-tooltip.onboarding-flow-tooltip {
        min-width: 158px;
        padding: 15px;
        border-radius: 5px;
        background-color: #151619;
        text-align: left;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
      .__react_component_tooltip.type-dark.onboarding-flow-tooltip.place-top:after {
        bottom: -7px;
        border-top-color: #151619;
      }
      .__react_component_tooltip.onboarding-flow-tooltip.place-top {
        margin-top: -7px;
      }
    }

    .select-onboarding-flow {
      min-width: 264px;
    }

    .onboarding-flow__menu {
      min-width: 498px;
    }
  }

  .hasMultipleOnb {
    position: absolute;
    position: absolute;
    left: 28px;
    bottom: 13px;
  }
`;

export const UploadFile = styled.div`
  position: relative;
  border-radius: 5px;
  border: solid 1px #d4d7d9;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 13px;
  width: 100%;

  .name {
    flex: 1 1;
    padding: 0 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: normal;
    color: #323c47;
  }

  .browse {
    background-color: #eceeef;
    text-align: center;
    font-weight: 600;
    color: #777777;
    padding: 12px 37px;
    z-index: 2;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none !important;
    outline: none !important;
    z-index: 1;
    text-indent: -9999px;
    height: 100%;
    width: 100%;
    color: transparent;
  }
`;

export const FistStepContainer = styled.div`
  width: 530px;
  margin: 0 auto;
  padding-top: 20px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #323c47;

  .form-group {
    line-height: normal;
    margin-bottom: 30px;

    label {
      font-size: 10px;
      font-weight: 600;
      color: #6a778a;
      margin-bottom: 6px;
    }

    .tip {
      font-size: 13px;
      color: #999999;
      a {
        color: #5c5bbd;
        font-weight: 600;
      }

      img.ui {
        display: inline-block;
      }
    }

    ${UploadFile} {
      margin-bottom: 9px;
    }
  }
`;

export const SecondStepContainer = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 25px;
  display: flex;

  .clients__table-container {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
    border-top: 1px solid #e8e8e7;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      :nth-child(even) {
        background-color: #fafbfc;
      }

      :last-child {
        td {
          border: none;
        }
      }

      th {
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6a778a;
        text-transform: uppercase;
        height: 0;
        position: initial;
        padding: 0;

        .table__header {
          position: absolute;
          top: 0;
          padding: 0 15px;
          &.send-email-column {
            width: 13%;
            text-align: center;
          }
        }

        :nth-child(3) {
          width: 24%;
        }
        :nth-child(4) {
          width: 18%;
        }
        :last-child {
          width: 15%;
        }
      }

      td {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 14px 15px;
        border-bottom: 1px solid #e8e8e7;

        &.email {
          font-weight: normal;
        }

        &.send_email {
          text-align: center;
          img {
            margin: auto;
          }
        }

        &.missing {
          background-color: #fff6f6;
          box-shadow: inset 0px 0px 0px 1px #e0b4b4;
        }
      }
    }
  }
`;

export const AssignWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 222px;
`;

export const AssignLabel = styled.div`
  color: #6a778a;
  font-family: Open Sans;
  font-size: 10px;
  line-height: 150%;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const AssignContent = styled.div`
  min-width: 158px;
`;
