import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import S3Images from 'shared/S3Images';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';

import * as S from './style';

const ThumbnailOverlay = props => {
  const { key, src, desc, tooltipContent, onOpen, isEditAble = true } = props;

  const handleOpen = () => {
    if (!isEditAble) {
      return;
    }

    onOpen && onOpen();
  };

  return (
    <S.ThumbnailWrapper onClick={handleOpen} isEditAble={!isEditAble}>
      <S3Images src={[src]} />
      <S.Overlay>
        <S.Desc>{desc || 'Click to change image'}</S.Desc>
      </S.Overlay>
      <S.IconInfo
        onClick={e => {
          e.preventDefault();
        }}
        data-tip
        data-for={key || 'thumbnail-info-key'}
        data-iscapture="true"
        isEditAble={isEditAble}
      >
        <InfoIcon />
      </S.IconInfo>
      <ReactTooltip
        id={key || 'thumbnail-info-key'}
        className="thumbnail-info"
        effect="solid"
        place="top"
        delayShow={100}
      >
        {tooltipContent || 'Image size should have width of at least 335px'}
      </ReactTooltip>
    </S.ThumbnailWrapper>
  );
};

ThumbnailOverlay.propTypes = {
  key: PropTypes.string,
  src: PropTypes.string,
  desc: PropTypes.string,
  tooltipContent: PropTypes.string,
  onOpen: PropTypes.func,
};

export default ThumbnailOverlay;
