import React from 'react';
import { ReactComponent as SelectIcon } from 'assets/icons/arrow_down_outline.svg';
import { ReactComponent as IconInfo } from 'assets/icons/icon-info.svg';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import * as S from './style';

const SelectSectionOption = ({ label = '', options = [], hasFooter, hasInfor, onChange, ...props }) => {
  const SelectOption = ({ innerProps, data, isSelected, innerRef }) => {
    let className = '';
    if (isSelected) {
      className += 'is-selected';
    }
    return (
      <S.ItemSection ref={innerRef} {...innerProps} className={className}>
        {data.value}
        {hasInfor && (
          <>
            <IconInfo data-tip data-for={`tooltip-section-format-${data.value}`} />
            <ReactTooltip
              id={`tooltip-section-format-${data.value}`}
              effect="solid"
              place="top"
              className="app-tooltip"
            >
              {data.desc || ''}
            </ReactTooltip>
          </>
        )}
      </S.ItemSection>
    );
  };

  const SingleValue = ({ innerProps, data }) => {
    return <S.SingleValue {...innerProps}>{data.value}</S.SingleValue>;
  };

  const MenuListCustom = props => {
    return (
      <S.MenuListContainer>
        <S.MenuListContent>{props.children}</S.MenuListContent>
        {hasFooter && <S.MenuListFooter>{hasFooter}</S.MenuListFooter>}
      </S.MenuListContainer>
    );
  };

  return (
    <S.BlockSelect>
      {label && <S.Label>{label}</S.Label>}
      <S.SelectList
        options={options}
        components={{
          Option: SelectOption,
          DropdownIndicator: () => {
            return <SelectIcon />;
          },
          SingleValue,
          MenuList: MenuListCustom,
          IndicatorSeparator: null,
          ClearIndicator: null,
        }}
        onChange={onChange}
        {...props}
      />
    </S.BlockSelect>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSectionOption);
