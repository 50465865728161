// Libs
import React, { useMemo, useState } from 'react';
import { Rectangle } from 'recharts';
import uniqBy from 'lodash/uniqBy';

// Constants
import { SLEEP_COLOR, SIZES, SLEEP_VIEW, SLEEP_STAGE_KEYS } from './constants';

// Helpers
import { getRadius } from './helpers';

const SIZE_VERTICAL = {
  SPACING_TOP: 10,
  SPACING_REMAINING_TOOLTIP: 73,
  GAP_STAGES: 7,
  HEIGHT_STAGES: 18,
  POSITION_SPACING_LEFT: 10,
  WIDTH_TOOLTIP_HAS_STAGE: 170,
};

// Calculates the position of a tooltip relative to a Recharts X-axis element.
const calculateTooltipPosition = (lengthOfType, top) => {
  const X_AXIS_SELECTOR = '.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis';
  const rechartsXAxis = document.querySelector(X_AXIS_SELECTOR);

  const { top: topRechartsXAxis } = rechartsXAxis && rechartsXAxis.getBoundingClientRect();

  const heightTooltip =
    lengthOfType * SIZE_VERTICAL.HEIGHT_STAGES +
    (lengthOfType > 0 ? lengthOfType - 1 : 0) * SIZE_VERTICAL.GAP_STAGES +
    SIZE_VERTICAL.SPACING_REMAINING_TOOLTIP;

  const checkTooltipOutsideBottom = heightTooltip > topRechartsXAxis - top;

  const newPositionTooltipBottom = checkTooltipOutsideBottom
    ? top - (heightTooltip - (topRechartsXAxis - top) + 10)
    : top;
  return newPositionTooltipBottom;
};

const CustomVerticalBarShape = props => {
  const {
    x,
    y,
    width,
    height,
    payload,
    setTooltipPosition,
    viewBy,
    isShowStages,
    connectedApp = false,
    isOverview = false,
  } = props;

  const durationType = viewBy === SLEEP_VIEW.DURATION;

  const { dataType = [], rangeValues = [], isMultipleRange = false, listRange = [] } = payload;
  const heightHover = height + SIZES.OFFSET;

  const [isHovered, setIsHovered] = useState(false);
  const [positionBarShapeHover, setPositionBarShapeHover] = useState({ height: height, y: y });

  const total = useMemo(() => {
    if (rangeValues.length === 2) {
      return Math.abs(rangeValues[0] - rangeValues[1]);
    }
    return 0;
  }, [rangeValues]);

  const renderBar = (entry, currIndex) => {
    const { type, value } = entry || {};

    let yBar = dataType.reduce((tt, entry, itemIndex) => {
      if (itemIndex < currIndex) {
        return tt + (entry.value / total) * height;
      }
      return tt;
    }, y);
    let heightBar = (value / total) * height;
    let radius = getRadius(currIndex, dataType.length, viewBy, isShowStages, connectedApp);

    const hasInBed = dataType.some(item => item.type === SLEEP_STAGE_KEYS.in_bed);
    if (hasInBed) {
      const { value } = entry || {};
      const convertValueToArr = [0, value];
      const step = height / (rangeValues[1] - rangeValues[0]);
      if (convertValueToArr.length > 0) {
        yBar = y + (rangeValues[1] - convertValueToArr[1]) * step;
        heightBar = (convertValueToArr[1] - convertValueToArr[0]) * step;
        radius = [3, 3, 0, 0];
      }
    }

    return (
      <Rectangle
        key={type + '-' + currIndex}
        x={x}
        y={yBar}
        width={width}
        height={heightBar}
        fill={SLEEP_COLOR[entry.type]}
        radius={radius}
      />
    );
  };

  const handleMouseEnter = event => {
    const wrapperRect = event.currentTarget.getBoundingClientRect();
    const { top, left, width: widthCurrentTarget } = wrapperRect;

    const lengthOfTypeUniq = uniqBy(dataType, 'type').length;
    const newPositionTooltipBottom = calculateTooltipPosition(lengthOfTypeUniq, top);

    setIsHovered(true);

    let DataTooltipPosition = {};

    if (isOverview) {
      DataTooltipPosition = {
        top: top - SIZE_VERTICAL.SPACING_TOP,
        left: left + widthCurrentTarget / 2,
        payload,
      };
    } else {
      DataTooltipPosition = {
        top: newPositionTooltipBottom,
        left: left,
        payload,
        positionVertical: SIZE_VERTICAL.POSITION_SPACING_LEFT,
        widthCurrentTarget: widthCurrentTarget,
      };
    }

    setTooltipPosition(DataTooltipPosition);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTooltipPosition(undefined);
  };

  // Start: Multiple range
  const renderListRange = (itemRange, rangeIndex) => {
    const { dataType = [] } = itemRange || {};
    if (dataType.length > 0) {
      return (
        <svg
          fill="none"
          onMouseEnter={event => handleMouseEnterRangeTime(event, itemRange)}
          onMouseLeave={handleMouseLeaveRangeTime}
          key={'range-' + rangeIndex}
        >
          {dataType.map((entry, currIndex) => renderBarMulti(itemRange, entry, currIndex, rangeIndex))}
        </svg>
      );
    }
    return null;
  };

  const handleMouseEnterRangeTime = (event, itemRange) => {
    const { rangeValues: itemRangeValues = [], dataType: itemDataType = [] } = itemRange;

    // setPositionBarShapeHover
    const step = height / (rangeValues[1] - rangeValues[0]);
    const valueY = y - SIZES.OFFSET_HOVER + Math.abs(itemRangeValues[1] - rangeValues[1]) * step;
    const valueHeight = SIZES.OFFSET + Math.abs(itemRangeValues[1] - itemRangeValues[0]) * step;
    setPositionBarShapeHover(prevState => ({ ...prevState, y: valueY, height: valueHeight }));

    // setIsHovered
    const wrapperRect = event.currentTarget.getBoundingClientRect();
    const { top, left, width: widthCurrentTarget } = wrapperRect;

    const lengthOfTypeUniq = uniqBy(itemDataType, 'type').length;
    const newPositionTooltipBottom = calculateTooltipPosition(lengthOfTypeUniq, top);

    const DataTooltipPosition = {
      top: newPositionTooltipBottom,
      left: left,
      payload,
      dataRangeTime: itemRange,
      positionVertical: SIZE_VERTICAL.POSITION_SPACING_LEFT,
      widthCurrentTarget: widthCurrentTarget,
    };

    setIsHovered(true);
    setTooltipPosition(DataTooltipPosition);
  };

  const handleMouseLeaveRangeTime = () => {
    setIsHovered(false);
    setTooltipPosition(undefined);
  };

  const renderBarMulti = (itemRange, entry, currIndex, rangeIndex) => {
    const { type, value } = entry || {};
    const { dataType = [], isManual = false, rangeValues: rangeValuesItem = [] } = itemRange;
    const step = height / (rangeValues[1] - rangeValues[0]);

    let yBar = dataType.reduce((tt, entry, itemIndex) => {
      if (itemIndex < currIndex) {
        return tt + (entry.value / total) * height;
      }
      return tt;
    }, y);

    if (!isManual) {
      yBar = yBar + (rangeValues[1] - rangeValuesItem[1]) * step;
    }

    let heightBar = (value / total) * height;
    let radius = getRadius(currIndex, dataType.length, viewBy, isShowStages, connectedApp);

    if (isManual) {
      const { value = [] } = entry || {};
      const step = height / (rangeValues[1] - rangeValues[0]);

      if (value.length > 0) {
        yBar = y + (rangeValues[1] - value[1]) * step;
        heightBar = (value[1] - value[0]) * step;
      }
      radius = [3, 3, 3, 3];
    }

    return (
      <Rectangle
        key={[type, currIndex, rangeIndex].join('-')}
        x={x}
        y={yBar}
        width={width}
        height={heightBar}
        fill={SLEEP_COLOR[entry.type]}
        radius={radius}
      />
    );
  };

  const renderMultiRange = () => {
    const { height, y } = positionBarShapeHover;
    return (
      <>
        {isHovered && (
          <Rectangle
            x={x - SIZES.OFFSET_HOVER}
            y={y}
            width={width + SIZES.OFFSET}
            height={height}
            fill={SLEEP_COLOR.hover}
            radius={[6, 6, 6, 6]}
            className="bar-shape-custom-hover"
          />
        )}
        {listRange.map(renderListRange)}
      </>
    );
  };

  if (isMultipleRange && rangeValues.length > 0 && !listRange.length > 0) return '';

  // End: Multiple range

  if (!isMultipleRange && rangeValues.length > 0 && !dataType.length > 0) return '';

  return (
    <>
      {isMultipleRange ? (
        renderMultiRange()
      ) : (
        <svg fill="none" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {isHovered && (
            <Rectangle
              x={x - SIZES.OFFSET_HOVER}
              y={y - SIZES.OFFSET_HOVER}
              width={width + SIZES.OFFSET}
              height={heightHover}
              fill={SLEEP_COLOR.hover}
              radius={durationType ? [6, 6, 0, 0] : [6, 6, 6, 6]}
              className="bar-shape-custom-hover"
            />
          )}
          {dataType.map(renderBar)}
        </svg>
      )}
    </>
  );
};

export default CustomVerticalBarShape;
