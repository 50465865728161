// Lib
import React, { useState } from 'react';
import { Button as CloseButton } from 'semantic-ui-react';

// Shared
import TextEditable from 'shared/TextEditable';

// Assets
import WarningIcon from 'assets/icons/warning_red_light.svg';

import { CDN_URL } from 'constants/commonData';

// Styles
import * as S from './style';

const NoteModal = ({
  toggleModal,
  title = 'Note',
  content = '',
  handleUpdateNote = () => {},
  toggleConfirmModal,
  isPublish = false,
  isEditMealPlan = false,
  disabledAction,
}) => {
  const [value, setValue] = useState(content);
  const [isChange, setIsChange] = useState(false);
  const disabled = (isPublish && !isEditMealPlan) || disabledAction;

  const handleClose = () => {
    if (content !== value) {
      toggleConfirmModal &&
        toggleConfirmModal(
          true,
          <S.CustomConfirmModal
            noBorder
            title="Discard Changes?"
            content="Are you sure you want to go? Changes have not been saved yet."
            onConfirm={() => {
              toggleModal && toggleModal(false);
            }}
            confirmButtonTitle="Discard Changes"
            hasCloseIcon
            headerIcon={WarningIcon}
          />,
        );
    } else {
      toggleModal && toggleModal(false);
    }
  };

  const handleChangeNote = data => {
    setValue(data);

    if (data || (content && !data)) {
      setIsChange(true);
    } else {
      setIsChange(false);
    }
  };

  const handleDeleteNote = () => {
    handleUpdateNote('', 'delete');
  };

  const handleAddNote = () => {
    handleUpdateNote(value, content ? 'update' : 'add');
  };

  return (
    <S.Wrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close" />
        </CloseButton>
      }
    >
      <S.Header>{title}</S.Header>
      <S.Content>
        <TextEditable
          autoFocus
          onChange={handleChangeNote}
          value={value}
          placeholder="Add a note"
          breakLine={true}
          readOnly={disabled}
        />
      </S.Content>
      <S.Actions hasContent={content}>
        {content && (
          <S.Delete disabled={disabled} onClick={handleDeleteNote}>
            Delete
          </S.Delete>
        )}
        <S.Update onClick={handleAddNote} disabled={!isChange || disabled}>
          {content ? 'Update' : 'Add Note'}
        </S.Update>
      </S.Actions>
    </S.Wrapper>
  );
};

export default NoteModal;
