import React, { useState } from 'react';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { Toggle } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import SettingLayout from './SettingLayout';
import { SharedTooltip } from 'shared/SharedTooltip';

import { ReactComponent as DefaultCategoryBackground } from 'assets/icons/drop_image.svg';
import { ReactComponent as HoverBackground } from 'assets/icons/hamburger_icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_thin.svg';
import { ReactComponent as DragDropIcon } from 'assets/icons/workout-drag-drop-icon.svg';
import { ReactComponent as UpArrowIcon } from 'assets/icons/move-workout-up.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/move-workout-down.svg';

import { CATEGORIES_STATUS, INIT_SECTION } from '../constants';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { connect } from 'react-redux';

function Category({
  category: originalCategory,
  toggleCategory,
  handleCategoryClick,
  toggleConfirmModal,
  onRemoveCategory,
  onDuplicateCategory,
  onMoveCategory,
  isFirst = false,
  isLast = false,
  index = 0,
  updateSettingFormat,
  disabled,
  cloudfrontList,
}) {
  const [category, setCategory] = useState(originalCategory);
  const [active, setActive] = useState(CATEGORIES_STATUS[originalCategory.status]);
  const [format, setFormat] = useState(get(category, 'format', INIT_SECTION.format));

  const handleRenderCategoryBackground = value => {
    if (value.background_thumbnail || value.background) {
      const bg = convertS3UrlToCloudFrontUrl(value.background_thumbnail || value.background, cloudfrontList, true);
      return <img src={bg} className="category-background" alt={value.name} />;
    }
    return (
      <span className="default-background">
        <DefaultCategoryBackground />
      </span>
    );
  };

  const handleCountWorkout = value => {
    const label = pluralize('Workout', value);
    const val = String(value).concat(' ', label);

    return val;
  };

  const handleToggleCategory = (categoryId, categoryStatus) => {
    toggleCategory(categoryId, categoryStatus);
    setActive(!active);
    if (category.status === 'active') {
      setCategory({ ...category, status: 'inactive' });
    } else {
      setCategory({ ...category, status: 'active' });
    }
  };

  const handleRemove = () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Remove'}
        content={
          <>
            Are you sure you want to remove <b>{category.name}</b>?
          </>
        }
        onConfirm={() => onRemoveCategory()}
        onDeny={() => {}}
        newStyle
        largeSpacing
        hasHoverState
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
      />,
    );
  };

  const handleMoveItem = (destinationIndex, originalIndex) => () => {
    onMoveCategory && onMoveCategory(category._id, destinationIndex, originalIndex);
  };

  const handleDuplicate = () => {
    onDuplicateCategory(category._id, `Copy of ${category.name}`);
  };

  // TODO - Handle Change Format
  const handleChangeFormat = format => {
    setFormat(format);
    updateSettingFormat && updateSettingFormat(format, category._id);
  };

  return (
    <div className="category-item">
      <div className="category-arrange">
        <DragDropIcon className="drag-drop-icon" />
      </div>
      <div className="category-left" onClick={handleCategoryClick}>
        <div className="category-background-wrapper">
          {handleRenderCategoryBackground(category)}
          <div className="hover-background">
            <HoverBackground />
          </div>
        </div>
        <div className="category-info">
          <div className="category-name">{category.name}</div>
          <div className="category-no-of-workouts">{handleCountWorkout(category.number_of_workouts || 0)}</div>
        </div>
      </div>
      <div className="category-right">
        <div className="category-status-container">
          {!disabled && (
            <>
              <Toggle
                className="unit-client-toggle"
                checked={active}
                onChange={e => {
                  e.stopPropagation();
                  handleToggleCategory(category._id, category.status);
                }}
                data-tip={category.status}
                data-for={`tooltip-${category._id}`}
              />
              <ReactTooltip id={`tooltip-${category._id}`} effect="solid" place={'top'} delayShow={500} />
            </>
          )}
        </div>
        {!disabled && <SettingLayout format={format} onChangeFormat={handleChangeFormat} />}

        {!disabled && (
          <DropDown
            className="category-actions-dropdown"
            triggerIcon={({ open }) => (
              <div data-tip data-for="workout-collection-category-tooltip">
                <S.TriggerIcon className="trigger-icon" active={!!open} />
                <SharedTooltip id="workout-collection-category-tooltip" />
              </div>
            )}
            direction="left"
            shouldCheckItem={true}
          >
            <div className="dropdown-area">
              <Option onClick={handleCategoryClick}>
                <EditIcon />
                <span>Edit</span>
              </Option>
              <Option onClick={handleDuplicate}>
                <DuplicateIcon />
                <span>Duplicate</span>
              </Option>
              {!isFirst && (
                <Option onClick={handleMoveItem(index - 1, index)}>
                  <UpArrowIcon className="icon" />
                  <span>Move up</span>
                </Option>
              )}
              {!isLast && (
                <Option onClick={handleMoveItem(index + 1, index)}>
                  <DownArrowIcon className="icon" />
                  <span>Move down</span>
                </Option>
              )}
              <Option onClick={handleRemove}>
                <DeleteIcon />
                <span>Remove</span>
              </Option>
            </div>
            <div className="dropdown-arrow" />
          </DropDown>
        )}
      </div>
    </div>
  );
}

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(Category);
