import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Modal, Image, Button } from 'semantic-ui-react';

import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { addNewCountdown, getClientCountdownList, getCountdownDetail } from 'redux/client-countdown/action';
import { CDN_URL } from 'constants/commonData';

import { NoteTypes } from 'components/Note/component';
import CountdownList from 'components/Note/Countdown/components/CountdownList';
import { CountdownViewModes, GoalTypes } from 'components/Note/Countdown/constants';
import GoalList from 'components/Note/Countdown/components/GoalList';

// assets
import { ReactComponent as FlagIcon } from 'assets/icons/flag-filled.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-5h.svg';
import { GoalCountdownPopupStyled } from './styles';
import { CustomConfirmModal } from '../ConfirmModal/style';

const GoalCountdownPopup = props => {
  const {
    isCreateNew,
    toggleModal,
    activeTab = NoteTypes.goal,
    countdownList = [],
    templateList,
    addNewCountdown,
    countdownDetail,
    getCountdownDetail,
    currentGoal,
    toggleConfirmModal,
    getClientCountdownList,
    selectedClientId,
  } = props;
  const [currentTab, setCurrentTab] = useState(activeTab);
  const [isChangedTab, setIsChangedTab] = useState(false);
  const [countdownViewMode, setCountdownViewMode] = useState(CountdownViewModes.ViewList);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setCountdownViewMode(
      isCreateNew && !isChangedTab
        ? CountdownViewModes.ViewCreateNew
        : countdownDetail
        ? CountdownViewModes.ViewDetails
        : CountdownViewModes.ViewList,
    );
  }, [isChangedTab]);

  useEffect(() => {
    if (!currentGoal && countdownList.length > 0) {
      setCurrentTab(NoteTypes.goal_countdown);
    }
    return () => {
      getClientCountdownList({ page: 1, client: selectedClientId }, true);
    };
  }, []);

  const handleShowDiscardChange = callback => {
    toggleConfirmModal(
      true,
      <CustomConfirmModal
        onConfirm={() => callback()}
        onDeny={() => toggleConfirmModal(false)}
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        confirmButtonTitle="Discard Changes"
        title="Discard Changes?"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleClose = () => {
    if (currentTab === NoteTypes.goal_countdown && isChanged) {
      handleShowDiscardChange(() => toggleModal(false));
      return;
    }
    toggleModal(false);
  };

  const handleChangeTab = tab => () => {
    setIsChangedTab(true);
    if (!isChangedTab) {
      setCountdownViewMode(CountdownViewModes.ViewList);
    }
    if (currentTab === NoteTypes.goal_countdown && isChanged) {
      handleShowDiscardChange(() => setCurrentTab(tab));
      return;
    }
    setCurrentTab(tab);
  };

  const renderTab = (tab, label, icon) => {
    return (
      <div className={classNames('tab', { active: tab === currentTab })} onClick={handleChangeTab(tab)}>
        {icon} {label}
      </div>
    );
  };

  const handleChangeFormCreate = isChanged => {
    setIsChanged(isChanged);
  };

  const handleChangeViewMode = viewMode => {
    setIsChangedTab(true);
    setTimeout(() => {
      setCountdownViewMode(viewMode);
    }, 0);
  };

  const renderContentTab = () => {
    if (currentTab === NoteTypes.goal) {
      return (
        <GoalList
          currentGoal={currentGoal}
          toggleConfirmModal={toggleConfirmModal}
          isCreateNew={isCreateNew && !isChangedTab}
        />
      );
    }

    return (
      <CountdownList
        viewMode={countdownViewMode}
        countdownList={countdownList}
        templateList={templateList}
        addNewCountdown={addNewCountdown}
        toggleModal={toggleModal}
        toggleConfirmModal={toggleConfirmModal}
        countdownDetail={countdownDetail}
        getCountdownDetail={getCountdownDetail}
        getClientCountdownList={getClientCountdownList}
        onChangeFormCreate={handleChangeFormCreate}
        onChangeViewMode={handleChangeViewMode}
      />
    );
  };

  return (
    <GoalCountdownPopupStyled
      open={true}
      onClose={handleClose}
      className="goal-countdown-popup"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Button onClick={handleClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <div className="countdown-wrapper">
          <div className="tabs">
            <h2>Goals</h2>
            {renderTab(NoteTypes.goal, 'Goal', <FlagIcon />)}
            {renderTab(NoteTypes.goal_countdown, 'Countdown', <ClockIcon />)}
          </div>
          <div className="tab-content">{renderContentTab()}</div>
        </div>
      </Modal.Content>
    </GoalCountdownPopupStyled>
  );
};

const mapState = ({ rootReducer }) => {
  const { client, clientCountdown } = rootReducer;
  const { selected: selectedClientId } = client || {};
  const { isRequestCountdown, countdownList, templateList, currentGoal } = clientCountdown || {};
  return {
    selectedClientId,
    isRequestCountdown,
    countdownList,
    templateList,
    currentGoal,
  };
};

const mapDispatch = {
  toggleModal,
  toggleConfirmModal,
  addNewCountdown,
  getCountdownDetail,
  getClientCountdownList,
};

export default connect(mapState, mapDispatch)(GoalCountdownPopup);
