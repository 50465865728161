import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { updateOneFeaturePreferences } from 'actions/client';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { fetchListTaskLibrary } from 'redux/task-library/actions';
import TaskAddNew from './component';

const mapStateToProps = state => {
  const { rootReducer, isModalOpen } = state;
  const { workingClientDetail } = rootReducer.client;
  const { listTaskLibrary } = rootReducer.taskLibrary;

  return {
    clientId: _.get(workingClientDetail, '_id', ''),
    isModalOpen,
    enableBodyMetric: workingClientDetail
      ? workingClientDetail.feature_preferences.find(item => item.type === 'body_metric').state
      : true,
    enableProgressPhoto: workingClientDetail
      ? workingClientDetail.feature_preferences.find(item => item.type === 'progress_photo').state
      : true,
    listTaskLibrary,
    isFormPermission: _.get(rootReducer, 'permission.form_questionnaire', false),
    isHabitPermission: _.get(rootReducer, 'permission.habit', false),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOneFeaturePreferences: bindActionCreators(updateOneFeaturePreferences, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    fetchListTaskLibrary: bindActionCreators(fetchListTaskLibrary, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskAddNew));
