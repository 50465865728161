import get from 'lodash/get';
import { toast } from 'react-toastify';
import { toggleModal } from 'actions/modal';
import Request from 'configs/request';
import { push, replace } from 'connected-react-router';
import { ERROR_CODE_PACKAGE_SETTING, PACKAGE_STATUS } from 'constants/commonData';
import { updateCounterPackages } from 'redux/package-list/actions';
import { hideError, showError } from 'actions/error';
import { getSurveyStatistic, resetSavedSurvey, setSavedSurvey } from 'redux/survey-contact/actions';

export const Types = {
  PACKAGE_DETAIL_GET_DATA: 'PACKAGE_DETAIL_GET_DATA',
  PACKAGE_DETAIL_ADD_NEW: 'PACKAGE_DETAIL_ADD_NEW',
  PACKAGE_DETAIL_TOGGLE_EDIT_MODE: 'PACKAGE_DETAIL_TOGGLE_EDIT_MODE',
  PACKAGE_DETAIL_END_SUBMIT: 'PACKAGE_DETAIL_END_SUBMIT',
  PACKAGE_DETAIL_STARTING_SUBMIT: 'PACKAGE_DETAIL_STARTING_SUBMIT',
  PACKAGE_DETAIL_RESET: 'PACKAGE_DETAIL_RESET',
  PACKAGE_SETTINGS_UPDATE_REQUEST: 'PACKAGE_SETTINGS_UPDATE_REQUEST',
  PACKAGE_SETTINGS_UPDATE_SUCCESS: 'PACKAGE_SETTINGS_UPDATE_SUCCESS',
  PACKAGE_SETTINGS_UPDATE_FAILED: 'PACKAGE_SETTINGS_UPDATE_FAILED',
  PACKAGE_DETAIL_UPDATE_TOTAL_OFFER: 'PACKAGE_DETAIL_UPDATE_TOTAL_OFFER',
  PACKAGE_DETAIL_UPDATE_SURVEY_FLAG: 'PACKAGE_DETAIL_UPDATE_SURVEY_FLAG',
};

export const addNewPackage = (data, isMP = false) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_ADD_NEW, payload: { data } });
    dispatch(push(`/home/${isMP ? 'marketplace' : 'packages'}/create`));
    dispatch(toggleModal(false));
  };
};

export const getPackage = (id, isMP) => {
  return Request.get(
    { url: '/api/payment/packages/' + id + '/detail' },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (data) {
        dispatch({
          type: Types.PACKAGE_DETAIL_GET_DATA,
          payload: { data },
        });
        if (isMP) {
          const survey = get(data, 'package_purchase_option.survey', {});
          const hasSurvey = !!get(survey, 'id', '');

          dispatch(getSurveyStatistic());
          if (hasSurvey) {
            dispatch(setSavedSurvey(survey));
          } else {
            dispatch(resetSavedSurvey());
          }
        }
      }
    },
    (error, { dispatch }) => {
      const responseData = get(error, 'response.data');
      if (responseData.statusCode === 403) return;
      dispatch(
        showError(responseData.message, null, null, null, null, () => {
          dispatch(hideError(false));
          if (responseData.errorCode === 'PACKAGE_NOT_FOUND') {
            dispatch(replace(`/home/${isMP ? 'marketplace' : 'packages'}`));
          }
        }),
      );
    },
    false,
  );
};

export const savePackageDraft = (data, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    const platforms = isMP ? ['marketplace'] : ['everfit'];
    dispatch(
      Request.post(
        { url: '/api/payment/packages/draft', data: { ...data, platforms } },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data) {
            dispatch(push(`/home/${isMP ? 'marketplace' : 'packages'}/` + data.hash_id));
            dispatch({
              type: Types.PACKAGE_DETAIL_GET_DATA,
              payload: { data },
            });
            dispatch(updateCounterPackages());
          }
          toast('Package has been saved');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const savePackageDraftGoToUpgrade = (data, pathName, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    const platforms = isMP ? ['marketplace'] : ['everfit'];
    dispatch(
      Request.post(
        { url: '/api/payment/packages/draft', data: { ...data, platforms } },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data) {
            if (pathName) dispatch(push(pathName));
            dispatch({
              type: Types.PACKAGE_DETAIL_GET_DATA,
              payload: { data },
            });
            dispatch(updateCounterPackages());
          }
          toast('Package has been saved');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const createPackage = (data, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    const platforms = isMP ? ['marketplace'] : ['everfit'];
    dispatch(
      Request.post(
        { url: '/api/payment/packages', data: { ...data, platforms } },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data) {
            dispatch(push(`/home/${isMP ? 'marketplace' : 'packages'}/` + data.hash_id));
            dispatch({
              type: Types.PACKAGE_DETAIL_GET_DATA,
              payload: { data },
            });
          }
          toast('Package has been published');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const updatePackage = (id, data, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    dispatch(
      Request.patch(
        {
          url: '/api/payment/packages/' + id,
          data: {
            ...data,
            is_edit_mode: false,
          },
        },
        true,
        (response, { dispatch }) => {
          const { data: dataResponsive } = response.data;
          dispatch({
            type: Types.PACKAGE_DETAIL_GET_DATA,
            payload: { data: dataResponsive },
          });
          toast('Package has been saved');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const updatePackageGoToUpgrade = (id, data, pathName, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    dispatch(
      Request.patch(
        { url: '/api/payment/packages/' + id, data },
        true,
        (response, { dispatch }) => {
          if (data) {
            if (pathName) dispatch(push(pathName));
          }
          dispatch({
            type: Types.PACKAGE_DETAIL_GET_DATA,
            payload: { data },
          });
          toast('Package has been saved');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const unpublishPackage = id => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    dispatch(
      Request.post(
        { url: '/api/payment/packages/' + id + '/unpublish' },
        true,
        (response, { dispatch, getState }) => {
          const {
            rootReducer: { packageDetail },
          } = getState();
          const workingPackage = packageDetail.get('workingPackage').toJS();
          workingPackage.status = PACKAGE_STATUS.DRAFT;
          dispatch({ type: Types.PACKAGE_DETAIL_GET_DATA, payload: { data: workingPackage } });
          toast('Package is unpublished');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const publishPackage = (id, data, isMP) => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    return dispatch(
      Request.patch(
        { url: '/api/payment/packages/' + id + '/publish', data },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.PACKAGE_DETAIL_GET_DATA, payload: { data } });
          toast('Package has been published');
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const enableEditMode = (id, shouldSendRequest, isMP) => {
  toast(
    shouldSendRequest
      ? 'You are editing the package. Changes will go live when you publish.'
      : 'You’re in editing mode',
  );
  return dispatch => {
    if (!shouldSendRequest) {
      return dispatch({ type: Types.PACKAGE_DETAIL_TOGGLE_EDIT_MODE, payload: { data: true } });
    }
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    dispatch(
      Request.post(
        { url: '/api/payment/packages/' + id + '/start-edit-mode' },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.PACKAGE_DETAIL_TOGGLE_EDIT_MODE, payload: { data: true } });
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const cancelEditMode = (id, shouldSendRequest, isMP) => {
  return dispatch => {
    if (!shouldSendRequest) {
      return dispatch({ type: Types.PACKAGE_DETAIL_TOGGLE_EDIT_MODE, payload: { data: false } });
    }
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    dispatch(
      Request.post(
        { url: '/api/payment/packages/' + id + '/end-edit-mode' },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.PACKAGE_DETAIL_TOGGLE_EDIT_MODE, payload: { data: false } });
          dispatch(getPackage(id, isMP));
        },
        () => {
          dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
        },
      ),
    );
  };
};

export const getPreSignedUrl = keyFile => {
  return dispatch => {
    dispatch({ type: Types.PACKAGE_DETAIL_STARTING_SUBMIT });
    const data = { keyFile };
    return dispatch(
      Request.post({ url: '/api/payment/upload/get-pre-signed-url', data }, true, (response, { dispatch }) => {
        dispatch({ type: Types.PACKAGE_DETAIL_END_SUBMIT });
      }),
    );
  };
};

export const resetPackage = () => ({ type: Types.PACKAGE_DETAIL_RESET });

export const resendActivationEmail = _id => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/payment/purchases/${_id}/resend-activation-email` },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;

          if (data) toast('Activation email has been sent.');
        },
        () => {},
      ),
    );
  };
};

export const updatePackageSettings = (id, settings, data, isMP, callbackCustomError) => {
  return dispatch => {
    const package_purchase_option = get(settings, 'package_purchase_option', null);
    if (isMP && get(package_purchase_option, 'is_turn_on_survey') && !get(package_purchase_option, 'survey_id')) return;
    dispatch({ type: Types.PACKAGE_SETTINGS_UPDATE_REQUEST });
    let groupSettings = {};
    if (process.env.REACT_APP_ENABLE_PACKAGE_GROUP_SETTINGS) {
      groupSettings = { groups: get(settings, 'groups', []) };
    }
    dispatch(
      Request.patch(
        {
          url: `/api/payment/packages/${id}/settings`,
          data: {
            limit_purchase: settings.limit_purchase,
            invoice_display_name: settings.invoice_display_name,
            is_allow_client_self_cancellation: settings.is_allow_client_self_cancellation,
            is_unassign_asset_self_cancellation: settings.is_unassign_asset_self_cancellation,
            ...groupSettings,
            is_archive_coach_manual_cancel: get(settings, 'is_archive_coach_manual_cancel', false),
            is_archive_subscription_ended: get(settings, 'is_archive_subscription_ended', false),
            is_archive_client_self_cancel: get(settings, 'is_archive_client_self_cancel', false),
            is_archive_on_trial_cancellation: get(settings, 'is_archive_on_trial_cancellation', false),
            is_trial_end_reminder: get(settings, 'is_trial_end_reminder', false),
            is_reactive_client: get(settings, 'is_reactive_client', false),
            overdue_reminder_after_3_day: get(settings, 'overdue_reminder_after_3_day', false),
            overdue_reminder_after_5_day: get(settings, 'overdue_reminder_after_5_day', false),
            allow_trial_once: get(settings, 'allow_trial_once', false),
            ...(!!package_purchase_option && {
              package_purchase_option,
            }),
            onboarding_flow_id:
              get(settings, 'onboarding_flow_id') === 'not_using' ? null : get(settings, 'onboarding_flow_id'),
          },
        },
        true,
        (response, { dispatch }) => {
          const { data: dataResponsive } = response.data;
          dispatch({
            type: Types.PACKAGE_SETTINGS_UPDATE_SUCCESS,
            payload: { ...data, ...dataResponsive },
          });
          if (isMP) {
            dispatch(resetSavedSurvey());
          }
          toast('Package settings have been saved.');
        },
        error => {
          const errorCode = get(error, 'response.data.errorCode', '');
          const errorMessage = get(error, 'response.data.message', '');
          const additionalData = get(error, 'response.data.additionalData', null);
          const isShowCustomPopup = Object.values(ERROR_CODE_PACKAGE_SETTING).includes(errorCode);
          dispatch({ type: Types.PACKAGE_SETTINGS_UPDATE_FAILED, payload: { additionalData } });
          if (isMP) {
            if (isShowCustomPopup && callbackCustomError) {
              callbackCustomError({
                ...additionalData,
                errorCode,
                errorMessage,
              });
              return;
            }
            dispatch(
              showError(errorMessage, null, null, null, null, () => {
                dispatch(hideError(false));
              }),
            );
          }
        },
        // if MP not show error, show custom error
        !isMP,
      ),
    );
  };
};

export const updateTotalOfferPackageSetting = (totalOffered = 0) => {
  return { type: Types.PACKAGE_DETAIL_UPDATE_TOTAL_OFFER, payload: { totalOffered } };
};

export const updatePackageSurveyFlag = data => {
  return { type: Types.PACKAGE_DETAIL_UPDATE_SURVEY_FLAG, payload: { data } };
};
