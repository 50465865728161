import React from 'react';
import { Helmet } from 'react-helmet';
import { Image, Icon } from 'semantic-ui-react';
import _, { get } from 'lodash';
import Avatar from 'react-avatar';
import ReactDOM from 'react-dom';
import { Container, SegmentItem, Name } from './styles';
import SegmentModal from 'components/SegmentModal';
import { DEFAULT_CLIENT_FILTERS, CLIENT_STATUS, WAITING_SEGMENT_NAME, CDN_URL } from 'constants/commonData';
import {
  convertSegmentToFilters,
  removeFristLastSlash,
  getUserShortName,
  isTeamAdmin,
  isOwnerOrAdminOrSubTrainer,
} from 'utils/commonFunction';
import UpgradePath from 'shared/UpgradePath';
import { SharedTooltip } from 'shared/SharedTooltip';
import { TooltipPortal } from './TooltipPortal';

const DEFAULT_SEGMENTS = [
  'Connected',
  'Pending',
  'Offline',
  'Need Attention',
  WAITING_SEGMENT_NAME,
  'Need Programming',
  'Your Entire Team',
];

class SegmentSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  componentDidUpdate() {
    const { router, workingClientDetail, segments, selectedSegment, user, permission } = this.props;
    const currentPath = router.location.pathname;
    const clientId = currentPath.slice(13);
    const archivedSegment = segments.find(item => item.is_default && item.name === 'Archived');
    const isCurrentClientArchived = workingClientDetail && get(workingClientDetail, 'is_archived', false);
    const isArchivedSegmentValid = archivedSegment && archivedSegment._id !== selectedSegment;

    if (clientId && isCurrentClientArchived && isArchivedSegmentValid) {
      const isPermitDenied = !permission.segment && !archivedSegment.is_default;
      const filters = convertSegmentToFilters(archivedSegment, user);
      this.props.selectSegment(archivedSegment._id, filters, isPermitDenied);

      if (!archivedSegment.is_visible) {
        this.setState({
          showMore: true,
        });
      }
    }
  }

  checkCurrentPathname = () => {
    const { router, push } = this.props;
    const currentPath = removeFristLastSlash(router.location.pathname);

    if (currentPath !== 'home/client') {
      push('/home/client');
    }
  };

  handleClickSegment = item => () => {
    const { selectedSegment, user, permission } = this.props;

    if (item.client_connection === CLIENT_STATUS.waiting) {
      // Waiting Activation Clients
      if (selectedSegment !== item._id) {
        if (user.has_waiting_client) {
          this.props.markSeenWaitingList();
        }

        this.props.activeWaitingSegment(item._id);
        this.props.push('/home/client/waiting-activation');
      }

      return;
    }

    if (selectedSegment !== item._id) {
      const isPermitDenied = !permission.segment && !item.is_default;
      const filters = convertSegmentToFilters(item, user);
      this.props.selectSegment(item._id, filters, isPermitDenied);
    }

    this.checkCurrentPathname();
  };

  renderItems = (list, showAvatar) => {
    const { hideSegment, activeSegment, selectedSegment, permission, hasNewWaitingClients } = this.props;

    return _.map(list, item => {
      const trainer = item.trainer || {};
      const icon = item.is_visible ? 'deactive' : 'active';
      return (
        <SegmentItem
          key={item._id}
          className={`menu-list-item${selectedSegment === item._id ? ' active' : ''}`}
          onClick={this.handleClickSegment(item)}
          showRedDot={item.client_connection === CLIENT_STATUS.waiting && hasNewWaitingClients}
        >
          <div className="left-content">
            <Name>{item.name}</Name>
          </div>
          <div className="right-content">
            {
              <React.Fragment>
                {showAvatar && (
                  <Avatar
                    name={getUserShortName(trainer)}
                    size="16"
                    src={trainer.avatar}
                    className={`segment-avatar ${permission.segment ? 'show-eye-icon' : ''}`}
                    key={item._id}
                    color={item.color}
                  />
                )}
                <UpgradePath pathName="segment" fallback={<></>}>
                  <div
                    className="segment__active-icon"
                    onClick={event => {
                      event.stopPropagation();
                      item.is_visible ? hideSegment(item._id) : activeSegment(item._id);
                    }}
                  >
                    <Image src={`${CDN_URL}/images/eye_${icon}_light_purple.svg`} />
                    <Image className="hover" src={`${CDN_URL}/images/eye_${icon}_white.svg`} />
                  </div>
                </UpgradePath>
              </React.Fragment>
            }
            <span className="count">{item.num_clients}</span>
          </div>
        </SegmentItem>
      );
    });
  };

  renderActiveSegments = () => {
    const { segments, selectedSegment, totalClients, selectSegment, user, permission } = this.props;
    const isOwnerOrAdmin = user => {
      const hasTeammates = get(user, 'team_member', []).filter(item => item._id !== user._id);
      if ((isTeamAdmin(user) || user.teams[0].role === 0) && hasTeammates.length) {
        return true;
      }
      return false;
    };
    let visibleSegments = _.filter(
      segments,
      item => (item.is_visible && permission.segment) || (!permission.segment && item.is_default),
    );
    let ownedSegments = _.remove(visibleSegments, item => item.trainer._id === user._id);
    const archivedSegment = _.remove(ownedSegments, item => item.is_default && item.name === 'Archived');
    const sharedSegments = visibleSegments.slice();
    let defaultSegments = [];
    _.forEach(DEFAULT_SEGMENTS, item => {
      const obj = ownedSegments.find(s => s.name === item && s.is_default);

      if (obj) {
        if (obj.name === 'Your Entire Team') {
          if (isOwnerOrAdminOrSubTrainer(user)) {
            defaultSegments.push(obj);
          }
        } else {
          // default case
          defaultSegments.push(obj);
        }
      }
    });

    ownedSegments = defaultSegments.concat(
      _.filter(ownedSegments, item => !item.is_default && item.name !== 'Your Entire Team'),
    );

    return (
      <React.Fragment>
        <div className="segments segments__visible">
          {!!sharedSegments.length && (
            <div className="segments__header">
              <span>Mine</span>
            </div>
          )}
          <div className="menu-list">
            <div
              className={`menu-list-item${!selectedSegment ? ' active' : ''}`}
              onClick={() => {
                selectedSegment &&
                  selectSegment(null, Object.assign({}, DEFAULT_CLIENT_FILTERS, { ownerShip: user._id }));
                this.checkCurrentPathname();
              }}
            >
              <span>All Clients</span>
              <span>{totalClients}</span>
            </div>
            {this.renderItems(ownedSegments, false)}
            {this.renderItems(archivedSegment, false)}
          </div>
        </div>
        {!!sharedSegments.length && (
          <div className="segments segments__visible">
            <div className="segments__header">
              <span>Other</span>
            </div>
            <div className="menu-list">{this.renderItems(sharedSegments, true)}</div>
          </div>
        )}
      </React.Fragment>
    );
  };

  TooltipPortal = ({ children }) => {
    return ReactDOM.createPortal(
      children,
      document.getElementById('root'), // Place render the tooltip
    );
  };

  renderDeactivateSegments = () => {
    const { segments, toggleModal, permission } = this.props;
    const { showMore } = this.state;
    const renderedSegments = segments.filter(item => !item.is_visible || (!permission.segment && !item.is_default));

    return (
      <React.Fragment>
        {showMore && (
          <div className="segments segments__hidden">
            <div className="divide" />
            <div className="menu-list">{this.renderItems(renderedSegments, true)}</div>
          </div>
        )}
        <div
          className="segment__show-more"
          onClick={() => this.setState(prevState => ({ showMore: !prevState.showMore }))}
        >
          <div>
            <span>{showMore ? 'Hide' : 'Show more'}</span>
            <Icon name={`chevron ${showMore ? 'up' : 'down'}`} />
          </div>

          <UpgradePath pathName="segment" fallback={<></>}>
            <div className="manage-segments-wrapper">
              <Image
                src={`${CDN_URL}/images/setting_grey.svg`}
                width={14}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleModal(true, <SegmentModal onlyManage={true} />);
                }}
                data-tip
                data-for="manage-segments-tooltip"
              />
              <TooltipPortal>
                <SharedTooltip content="Manage Segments" id="manage-segments-tooltip" noWrap />
              </TooltipPortal>
            </div>
          </UpgradePath>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { segments, selectedSegment } = this.props;

    if (!segments || !segments.length) {
      return null;
    }

    const segmentName = _.get(
      _.find(segments, item => item._id === selectedSegment),
      'name',
      'All Clients',
    );

    return (
      <Container className="segment-container">
        <Helmet>
          <title>{segmentName} - Everfit</title>
        </Helmet>
        {this.renderActiveSegments()}
        {this.renderDeactivateSegments()}
      </Container>
    );
  }
}

export default SegmentSidebar;
