import styled, { css } from 'styled-components';

export const PasteButton = styled.button`
  display: none;
  height: 22px;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: center;
  padding: 0;
  width: 100%;
  border: none;
  color: rgb(255, 255, 255);
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const ViewMoreItem = styled.div`
  color: #676767;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  :hover {
    color: #5158cf;
    cursor: pointer;
  }
`;

export const CellHeader = styled.div`
  min-height: 34px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .day {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #6a7988;
  }
`;

export const CellContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 7px;
`;

export const CellContent = styled.div`
  flex: 1;
  overflow-y: hidden;
  border-radius: 6px;

  &.itvAutoflowCalCell__content--empty {
    ${CellContentContainer} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const CellFooter = styled.div`
  height: 22px;
  width: 100%;

  ${ViewMoreItem} {
    vertical-align: middle;
    font-size: 11px;
    font-weight: 600;
    color: #5a636c;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;

    :hover {
      color: #5158cf;
    }
  }
`;

export const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const CellWrapper = styled.div`
  height: 100%;
  background: #f3f5f8;
  border-radius: 6px;
  width: calc((100% - 48px) / 7);
  border: 1px solid transparent;

  ${props =>
    props.copying &&
    css`
      :hover {
        ${CellFooter} {
          ${ViewMoreItem} {
            display: none;
          }

          ${PasteButton} {
            display: block;
          }
        }
      }
    `}

  .addItemButton {
    opacity: 0;
  }

  :hover {
    .addItemButton {
      opacity: 1;
    }
  }

  ${props =>
    props.showAll &&
    css`
      background-color: #fff;
      border: 1px solid rgb(114, 110, 228);

      ${CellContentContainer} {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      ${CellHeader} {
        border-bottom: 1px solid #e5eced;
      }
    `}

  ${props =>
    props.empty &&
    css`
      ${CellContentContainer} {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
`;
