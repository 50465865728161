import styled from 'styled-components';
import { Wrapper, Header, Content, Actions } from 'shared/Styles/ModalLayout';
import { Modal } from 'semantic-ui-react';

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
`;

export const Mark = styled(Description)`
  font-weight: bold;
  margin-top: 20px;
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 466px;
  }

  > .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding-bottom: 16px;
  }
`;
