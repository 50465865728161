import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import * as S from './style';
import { HeaderWithLogo } from 'layouts/PageHeader';
import { PLANS, REFERAL_CODE_KEY, AFFREF_CODE_KEY, CDN_URL } from 'constants/commonData';
import * as FormStyles from 'layouts/Login/style';
import { ErrorMessage } from 'shared/FormControl';
import { RegisterInput, RegisterFormGroup } from 'components/Register/style';
import { axiosInstance, axiosInstanceWithoutToken } from 'configs/request';
import { removeOldTokenSavedOnLocal } from 'utils/commonRequest';
import { getCookie, upperFirstCharacter } from 'utils/commonFunction';

class CompleteRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planObject: PLANS.studio,
      referralCode: props.data ? props.data.getIn(['user', 'signupData', 'referral_code']) : '',
      referredBy: '',
      referralObj: {},
      showError: false,
      showReferralCode: !!props.data && !!props.data.getIn(['user', 'signupData', 'referral_code']),
      serverError: '',
      showReferalCodeError: false,
      first_name: props.data ? props.data.getIn(['user', 'first_name']) || '' : '',
      last_name: props.data ? props.data.getIn(['user', 'last_name']) || '' : '',
      company: '',
    };
    this.isSubmitting = false;
  }

  componentDidMount() {
    const { referralCode } = this.state;
    if (referralCode) {
      this.verifyReferralCode(referralCode);
    }
  }

  getReferralLinkCode = () => {
    const { referralCode } = this.state;

    let affCode = '';
    const paramsString = _.get(this.props, 'location.search', '');

    if (paramsString) {
      const params = new URLSearchParams(paramsString);
      affCode = params.get('affref');
    }

    const referalCodeKey = getCookie(REFERAL_CODE_KEY);
    const affrefCodeKey = getCookie(AFFREF_CODE_KEY);

    let referralLinkCode = null;

    if (referralCode) {
      if (referalCodeKey === referralCode) {
        referralLinkCode = affCode ? affCode : affrefCodeKey;
      }
    }

    return referralLinkCode;
  };

  onSubmit = async event => {
    event.preventDefault();
    const { first_name, last_name, referralCode, referredBy, referralObj, company } = this.state;
    const { data } = this.props;

    if (this.isSubmitting) {
      return false;
    }

    if (!first_name || !last_name) {
      this.setState({ showError: true });
      return false;
    }

    if (referralCode) {
      const isVerifiedCode = await this.verifyReferralCode(referralCode);

      if (!isVerifiedCode) {
        return false;
      }
    }

    const referralLinkCode = this.getReferralLinkCode();

    this.isSubmitting = true;
    this.setState({ serverError: '' });
    const submitData = {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      referral_code: referralCode,
      referredBy,
      referralObj,
      email: data.getIn(['user', 'email']),
      company: company.trim(),
      referral_link_code: referralLinkCode,
    };

    this.props.completeTrainerRegistration(submitData, data.get('token')).catch(error => {
      const serverError = _.get(
        error,
        'response.data.message',
        'Undefined error. Please try again later or contact your administrator.',
      );
      this.setState({ serverError });
      this.isSubmitting = false;
    });
  };

  onUpdateField = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onBlurField = () => {
    const { first_name, last_name } = this.state;
    const capitalizeFirstName = upperFirstCharacter(first_name);
    const capitalizeLastName = upperFirstCharacter(last_name);

    this.setState({ first_name: capitalizeFirstName, last_name: capitalizeLastName });
  };

  onReferralCodeChange = event => {
    const { value } = event.target;
    this.setState({ referralCode: value, showReferalCodeError: false });
  };

  resetReferralCode = () => {
    this.setState({ referralCode: '', showReferalCodeError: false });
  };

  onReferralCodeBlur = () => {
    const { referralCode } = this.state;

    if (referralCode) {
      this.verifyReferralCode(referralCode);
    }
  };

  verifyReferralCode = referral_code => {
    return new Promise(resolve => {
      axiosInstance
        .post('/api/authentication/verify-referral-code', { referral_code })
        .then(response => {
          const referredBy = _.get(response, 'data.referredBy');
          const referralObj = _.get(response, 'data.referralObj', {});
          this.setState({ showReferalCodeError: false, referredBy, referralObj }, () => {
            resolve(true);
          });
        })
        .catch(() => {
          this.setState({ showReferalCodeError: true });
          resolve(false);
        });
    });
  };

  render() {
    const {
      planObject,
      first_name,
      last_name,
      referralCode,
      showReferralCode,
      showError,
      serverError,
      showReferalCodeError,
      company,
    } = this.state;

    const { data } = this.props;

    if (!data || !data.toJS()) {
      return <Redirect to="/login" />;
    }

    return (
      <S.Wrapper>
        <HeaderWithLogo className="sign-up--second-step-header" />
        <S.MainContent>
          <S.Form onSubmit={this.onSubmit}>
            <S.LeftSide>
              <S.ImageContainer />
              <S.PlanContent>
                <S.PlandTitle>
                  Start your <span className="highlight">30-day trial</span>
                </S.PlandTitle>
                <div>
                  <S.ContentItem>
                    {checkmark}
                    <span>
                      Access to the <span className="highlight">{planObject.name} Plan</span>
                    </span>
                  </S.ContentItem>
                  <S.ContentItem>
                    {checkmark}
                    <span>
                      <span className="highlight">Automation</span> add-on included
                    </span>
                  </S.ContentItem>
                  <S.ContentItem>
                    {checkmark}
                    <span>
                      Train up to <span className="highlight">50</span> clients
                    </span>
                  </S.ContentItem>
                  <S.ContentItem>
                    {checkmark}
                    <span>No credit card required</span>
                  </S.ContentItem>
                </div>
              </S.PlanContent>
            </S.LeftSide>
            <S.RightSide>
              <S.FormContainer>
                <div className="form__header">
                  <div className="welcome">Welcome to Everfit</div>
                  <div className="title">Just a few more details</div>
                </div>
                {serverError && <FormStyles.ServerErrorMessage>{serverError}</FormStyles.ServerErrorMessage>}
                <div className="form__inputs">
                  <RegisterFormGroup className={classnames({ error: showError && !first_name.trim() })}>
                    <RegisterInput
                      defaultValue={first_name}
                      value={first_name}
                      onChange={this.onUpdateField}
                      onBlur={this.onBlurField}
                      name="first_name"
                      placeholder="First Name"
                      maxLength={30}
                    />
                    <ErrorMessage>First name is required</ErrorMessage>
                  </RegisterFormGroup>
                  <RegisterFormGroup
                    className={classnames('form-group--last-name', { error: showError && !last_name.trim() })}
                  >
                    <RegisterInput
                      defaultValue={last_name}
                      value={last_name}
                      onChange={this.onUpdateField}
                      onBlur={this.onBlurField}
                      placeholder="Last Name"
                      name="last_name"
                      maxLength={30}
                    />
                    <ErrorMessage>Last name is required</ErrorMessage>
                  </RegisterFormGroup>
                  {process.env.REACT_APP_ENABLE_COMPANY_NAME && (
                    <RegisterFormGroup>
                      <RegisterInput
                        defaultValue={company}
                        onChange={this.onUpdateField}
                        value={company}
                        placeholder="Company (Optional)"
                        name="company"
                      />
                    </RegisterFormGroup>
                  )}
                  {showReferralCode ? (
                    <RegisterFormGroup
                      className={classnames('form-group--referral', { error: showReferalCodeError && !!referralCode })}
                    >
                      <div className="referral-form-group">
                        <RegisterInput
                          className="referral-code-input"
                          value={referralCode}
                          onChange={this.onReferralCodeChange}
                          placeholder="Add Referral Code"
                          autoFocus={!referralCode}
                          onBlur={this.onReferralCodeBlur}
                        />
                        {!!referralCode && <div onClick={this.resetReferralCode} className="referral-reset-input" />}
                      </div>
                      <ErrorMessage>Referral Code not found</ErrorMessage>
                    </RegisterFormGroup>
                  ) : (
                    <div className="referral-code-trigger" onClick={() => this.setState({ showReferralCode: true })}>
                      Referral Code
                    </div>
                  )}
                </div>
                <FormStyles.SubmitButton type="button" onClick={this.onSubmit} className="submit-button">
                  <span>Start Training</span>
                  <img src={`${CDN_URL}/images/arrow.svg`} alt="" />
                </FormStyles.SubmitButton>
              </S.FormContainer>
            </S.RightSide>
          </S.Form>
        </S.MainContent>
      </S.Wrapper>
    );
  }
}

export default CompleteRegistration;

const checkmark = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 3.6L15.2 2C8.3 4 4.8 8.4 4.8 8.4L1.6 6L0 7.6L4.8 14C8.5 7.1 16 3.6 16 3.6Z" fill="#48C98C" />
  </svg>
);
