import React from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import SystemWorkoutModal from './SystemWorkoutModal';
import * as S from './style';
import './style.scss';
import { CDN_URL, WORKOUT_BUILDER_TYPES } from 'constants/commonData';

function CreateWorkout(props) {
  const onSave = async workout => {
    try {
      const response = await props.addWorkout(workout);
      return response.data.data;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  const onSavedWithoutClose = newWorkout => {
    props.openWorkoutDetail(newWorkout._id);
    props.push(`/home/workout/${newWorkout._id}/detail`);
  };

  return (
    <Modal
      open={true}
      className="modal--create-workout-template"
      closeOnDimmerClick={true}
      onClose={() => props.toggleModal(false)}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <S.Title>Create Workout</S.Title>
        <S.SubTitle>Please select how you would like to start</S.SubTitle>
      </Modal.Header>
      <Modal.Content>
        <S.OptionContainer>
          <S.Option
            onClick={() => {
              props.toggleModal(true, <SystemWorkoutModal />);
            }}
          >
            <S.OptionIcon src={`${CDN_URL}/images/from_template.svg`} />
            <S.OptionIcon src={`${CDN_URL}/images/from_template_purple.svg`} className="hover" />
            <S.OptionLabel>Create from Template</S.OptionLabel>
            <div>Choose from our list of workout templates</div>
          </S.Option>
          <S.Option
            onClick={() => {
              props.toggleModal(
                true,
                <WorkoutDetailModal
                  onSave={onSave}
                  onSavedWithoutClose={onSavedWithoutClose}
                  type={WORKOUT_BUILDER_TYPES.TEMPLATE}
                  key="create-workout-template"
                  pdfType="workout"
                />,
              );
            }}
          >
            <S.OptionIcon src={`${CDN_URL}/images/new_workout.svg`} />
            <S.OptionIcon src={`${CDN_URL}/images/new_workout_purple.svg`} className="hover" />
            <S.OptionLabel>New Workout</S.OptionLabel>
            <div>Create your own</div>
          </S.Option>
        </S.OptionContainer>
      </Modal.Content>
    </Modal>
  );
}

export default CreateWorkout;
