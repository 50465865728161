import React from 'react';
import find from 'lodash/find';

import * as S from './styles';
import PurchaseDropdown from '../CustomDropdown';

const DropdownPurchaseOption = ({ options = [], value = '', disabled = false, onChange }) => {
  const findOption = find(options, item => item.value === value) || options[0];

  const handleChange = value => {
    onChange(value);
  };

  if (disabled) {
    return (
      <S.DropdownPurchaseOptionWrapper>
        <S.PurchasePreviewValue>{findOption.label || ''}</S.PurchasePreviewValue>
      </S.DropdownPurchaseOptionWrapper>
    );
  }
  return <PurchaseDropdown options={options} value={value} onChange={handleChange} />;
};

export default DropdownPurchaseOption;
