import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import BackToTop from './BackToTop';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { toggleModal } from 'actions/modal';
import { push } from 'connected-react-router';
import { Checkbox } from 'shared/FormControl';
import LoadingIndicator from 'shared/LoadingIndicator';

import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';

import { convertS3UrlToCloudFrontUrl, encryptData, getUserShortName, pluralize } from 'utils/commonFunction';
import { CLIENT_IN_VIEW } from '../constants';

import { ReactComponent as InboxIcon } from 'assets/icons/inbox-icon.svg';
import { ReactComponent as EmptyBanner } from 'assets/icons/workout_empty_client.svg';

import * as S from '../style';

const MissedTab = props => {
  const {
    data = [],
    selected,
    setSelected,
    onScrollToTop,
    isEndPage = false,
    isLoading,
    push,
    toggleModal,
    hasLoadMore = false,
    startDate,
    page,
    cloudfrontList,
  } = props;
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (page === 1 && !isLoading) {
      setClients(data);
    }
    if (page > 1 && !isLoading) {
      const newList = [...clients, ...data];
      const filteredDuplicate = uniqBy(newList, 'client._id');

      setClients(filteredDuplicate);
    }
  }, [page, isLoading]);

  const handleSelected = (event, client) => {
    event.stopPropagation();
    const isChecked = selected.some(it => get(it, '_id') === get(client, '_id'));

    if (isChecked) {
      setSelected(selected.filter(it => get(it, '_id', '') !== get(client, '_id', '')));
    } else {
      setSelected([...selected, client]);
    }
  };

  const handleRedirectToInbox = client => {
    toggleModal && toggleModal(false);
    push(`/home/inbox?profileId=${get(client, '_id', '')}&ufn=${encryptData(get(client, 'full_name', ''))}`);
  };

  const handleRedirectToClientCalendar = clientId => {
    toggleModal && toggleModal(false);
    push({
      pathname: `/home/client/${clientId}/calendar`,
      state: { focusDate: startDate.format('MM-DD-YYYY') },
    });
  };

  if (isLoading && !hasLoadMore) {
    return (
      <S.EmptyContainer>
        <LoadingIndicator className="wc-loading-indicator" />
      </S.EmptyContainer>
    );
  }

  return (
    <div className="missed-tab-wrapper">
      {!isLoading && isEmpty(clients) && (
        <S.EmptyContainer>
          <S.EmptyBanner>
            <EmptyBanner />
          </S.EmptyBanner>
          <S.EmptyText>It was great, no one missed their workout!</S.EmptyText>
        </S.EmptyContainer>
      )}
      {clients.map(item => {
        const isChecked = selected.some(it => get(it, '_id') === get(item, 'client._id'));
        const makeKey = `missed-${get(item, 'client._id', '')}-${get(item, '_id', '')}`;
        const avt = get(item, 'client.avatar', '');

        return (
          <S.TableBodyContainer key={makeKey}>
            <tr>
              <S.TableBodyCell>
                <S.TableCellContent>
                  <S.CheckboxClientContainer>
                    <S.CheckBoxContainer className={isChecked ? 'checked' : null}>
                      <S.CheckboxHeader className="checkbox-header">
                        <Checkbox checked={isChecked} onChange={event => handleSelected(event, get(item, 'client'))} />
                      </S.CheckboxHeader>
                      <S.AvatarContainer className="avatar-container">
                        <Avatar
                          src={convertS3UrlToCloudFrontUrl(avt, cloudfrontList, true)}
                          size="24"
                          name={getUserShortName(get(item, 'client', ''))}
                          alt=""
                          className="workout-completed-avatar"
                        />
                      </S.AvatarContainer>
                    </S.CheckBoxContainer>
                    <S.TableBodyContent
                      className="workout-missed-name"
                      onClick={e => {
                        handleSelected(e, get(item, 'client'));
                      }}
                    >
                      {get(item, 'client.full_name', '')}
                    </S.TableBodyContent>
                  </S.CheckboxClientContainer>
                </S.TableCellContent>
              </S.TableBodyCell>
              <S.TableBodyCell>
                <S.TableCellContent
                  isWorkout
                  paddingLeft={16}
                  paddingTop={8}
                  paddingBottom={8}
                  data-tip
                  data-for={`workout-completion-${makeKey}`}
                >
                  <S.TableBodyContent
                    onClick={() => handleRedirectToClientCalendar(get(item, 'client._id'))}
                    className="workout-missed-name"
                  >
                    {pluralize('workout', get(item, 'total_missed_workouts', 0), true)}
                  </S.TableBodyContent>
                </S.TableCellContent>
                {get(item, 'missed_workouts', []) && get(item, 'total_missed_workouts', 0) !== 0 && (
                  <ReactTooltip
                    className="app-tooltip"
                    id={`workout-completion-${makeKey}`}
                    effect="solid"
                    place={'bottom'}
                    delayShow={300}
                  >
                    <ul>
                      {get(item, 'missed_workouts', []) && get(item, 'total_missed_workouts', 0) > 5
                        ? get(item, 'missed_workouts', [])
                            .slice(0, 4)
                            .map(item => <li key={get(item, '_id')}>{truncate(get(item, 'title', ''), 26)}</li>)
                        : get(item, 'missed_workouts', []).map(item => (
                            <li key={get(item, '_id')}>{truncate(get(item, 'title', ''), 26)}</li>
                          ))}
                      {get(item, 'missed_workouts', []) && get(item, 'total_missed_workouts', 0) > 5 && (
                        <li key={get(item, '_id')}>{`+${
                          get(item, 'missed_workouts', []).slice(4).length
                        } missed ${pluralize('workout', get(item, 'missed_workouts', []).slice(4).length)}`}</li>
                      )}
                    </ul>
                  </ReactTooltip>
                )}
              </S.TableBodyCell>
              <S.TableBodyCell>
                <S.TableCellContent isIcon>
                  <S.TableBodyIcon
                    onClick={() => {
                      handleRedirectToInbox(get(item, 'client'));
                    }}
                    data-tip
                    data-for={`inbox-${makeKey}`}
                  >
                    <InboxIcon className="inbox-icon" />
                    {get(item, 'has_unread_message', false) && <S.InboxUnread />}
                  </S.TableBodyIcon>
                  <ReactTooltip className="inbox-tooltip" id={`inbox-${makeKey}`} effect="solid" place={'bottom'}>
                    Open inbox
                  </ReactTooltip>
                </S.TableCellContent>
              </S.TableBodyCell>
            </tr>
          </S.TableBodyContainer>
        );
      })}
      {hasLoadMore && <LoadingIndicator className="wc-loading-indicator" />}
      {isEndPage && !isLoading && !isEmpty(clients) && clients.length > CLIENT_IN_VIEW && (
        <BackToTop onScrollToTop={onScrollToTop} />
      )}
    </div>
  );
};

MissedTab.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  onScrollToTop: PropTypes.func,
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatchToProps)(MissedTab);
