import React, { useEffect, useRef } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import { DateTime as DateTimeLuxon } from 'luxon';
import * as ReactDOM from 'react-dom';

import { Container, Arrow, CalendarIcon } from './styles';
import { formatDateSafari } from 'utils/commonFunction';

const DatePicker = ({
  value = moment.tz(),
  clientTimezone,
  format,
  onChange,
  className = '',
  isOpen = undefined,
  onToggleCalendar,
  isShowArrowBtn = true,
  maxDate = undefined,
  closeOnClickOutside = true,
  renderCustomInput,
  hideNextMonth = false,
  allowDetectElmOutTop = false,
}) => {
  const onArrowClick = factor => {
    onChange(moment.tz(value.format(), clientTimezone).add(factor, 'days'));
  };

  const calendarRef = useRef(null);

  const renderDay = ({ className: innerClassName, onClick, ...restInnerProps }, currentDate) => {
    let finalClassName = innerClassName;
    let isDisabled = false;

    if (maxDate && currentDate > moment(maxDate)) {
      finalClassName += ' disabled';
      isDisabled = true;
    }

    if (value.isSame(currentDate, 'day') && !currentDate.isSame(moment(), 'day')) {
      finalClassName += ' isSelected';
    }

    return (
      <td {...restInnerProps} onClick={isDisabled ? undefined : onClick} className={finalClassName}>
        <div className="content">
          <div>{currentDate.date()}</div>
        </div>
      </td>
    );
  };

  const renderDateInput = () => {
    if (format) return value.format(format);

    let newFormat = 'MMM DD, YYYY';
    if (value.isSame(new moment.tz(clientTimezone), 'year')) newFormat = 'MMM DD';

    return moment.tz(value, clientTimezone).format(newFormat);
  };

  const onSelectDate = date => {
    onChange(moment.tz(date.format(), clientTimezone));
  };

  // // Detect remove days (By DOM)
  const handleHideNextMonthView = () => {
    if (!calendarRef.current) return;
    const btnNext = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead tr .rdtNext');

    // Get month from calendar
    const currentMonth = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead tr .rdtSwitch');

    const formatDateToLuxon = DateTimeLuxon.fromFormat(currentMonth.textContent, 'MMMM yyyy');
    const currentMonthFormatted = moment(formatDateToLuxon.toFormat('yyyy-MM-dd'));

    const thisMonth = moment().startOf('month').tz(clientTimezone);
    const parentBtnNext = btnNext.parentNode;
    if (currentMonthFormatted.isSameOrAfter(formatDateSafari(thisMonth))) {
      btnNext.classList.add('disabledBtnNext');
      !!parentBtnNext && parentBtnNext.classList.add('headerHasDisableNext');
    } else {
      btnNext.classList.remove('disabledBtnNext');
      !!parentBtnNext && parentBtnNext.classList.remove('headerHasDisableNext');
    }
  };

  const updateSelectGroupStyles = () => {
    const parentElm = ReactDOM.findDOMNode(calendarRef.current);
    if (parentElm.classList.contains('rdtOpen')) {
      const rdtPickerElm = parentElm.querySelector('.rdtPicker');
      if (rdtPickerElm) {
        const rect = rdtPickerElm.getBoundingClientRect();
        if (rect.top < 0) {
          rdtPickerElm.style.top = '40px';
        }
      }
    }
  };
  useEffect(() => {
    if (isOpen && allowDetectElmOutTop) {
      updateSelectGroupStyles();
    }
  }, [isOpen]);

  useEffect(() => {
    onChangeMonth();
  }, []);

  // Detect reset DOM
  const onChangeMonth = () => {
    if (hideNextMonth) {
      setTimeout(() => {
        handleHideNextMonthView();
      }, 0);
    }
  };

  return (
    <Container>
      {isShowArrowBtn && <Arrow left onClick={() => onArrowClick(-1)} />}
      <Datetime
        ref={calendarRef}
        value={value}
        displayTimeZone={clientTimezone}
        renderInput={({ onClick, ...restProps }) => {
          return (
            <div {...restProps} onClick={onToggleCalendar || onClick}>
              {renderCustomInput || (
                <>
                  <CalendarIcon />
                  <span style={{ marginLeft: '10px' }}>{renderDateInput()}</span>
                </>
              )}
            </div>
          );
        }}
        className={className}
        timeFormat={false}
        closeOnSelect={true}
        onChange={onSelectDate}
        renderDay={renderDay}
        open={isOpen}
        closeOnClickOutside={closeOnClickOutside}
        onNavigateBack={onChangeMonth}
        onNavigateForward={onChangeMonth}
        onViewModeChange={onChangeMonth}
      />
      {isShowArrowBtn && <Arrow right onClick={() => onArrowClick(1)} />}
    </Container>
  );
};

export default DatePicker;
