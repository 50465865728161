import React from 'react';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';
import LoadingIndicator from 'shared/LoadingIndicator';

const HeartRateProgress = props => {
  const { progressList, isLoading, isViewByWeek, renderEntry, resultListRef } = props;

  return (
    <div className="progress__body">
      <Table
        singleLine
        className={classNames('progress-table-head normal-metric-head', {
          'normal-metric-head-all': !isViewByWeek,
        })}
      >
        <Table.Header className="progress-table__head">
          <Table.Row>
            <Table.HeaderCell className={isViewByWeek ? 'w-56per' : 'w-112'}>DATE</Table.HeaderCell>
            {!isViewByWeek && (
              <Table.HeaderCell
                className={classNames('head-time', { 'head-time-week': isViewByWeek })}
              ></Table.HeaderCell>
            )}
            <Table.HeaderCell className="result">{isViewByWeek ? 'Weekly range' : 'RANGE'}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div className="result-list" forwardref={resultListRef}>
        <Table singleLine className="progress-table__content normal-metric-content">
          <Table.Body>
            {progressList.map(renderEntry)}
            {isLoading && (
              <Table.Row className="row-item">
                <Table.Cell colSpan={isViewByWeek ? 3 : 4} textAlign="center" verticalAlign="middle">
                  <LoadingIndicator title="" className="progress-load-more" />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default HeartRateProgress;
