import _ from 'lodash';
import { Types } from './actions';
import { CALENDAR_TYPES } from 'constants/commonData';

const INITIAL_STATE = {
  listMyFitnessPal: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.MY_FITNESS_PAL_STARTED_SYNCING:
    case Types.MY_FITNESS_PAL_SYNCED:
      return {
        ...state,
        listMyFitnessPal: {
          ...state.listMyFitnessPal,
          ...payload.data,
        },
      };
    default:
      return state;
  }
};
