import Request from 'configs/request';

export const Types = {
  LEADERBOARD_FETCH_GLOBAL_DATA_SUCCESS: 'LEADERBOARD_FETCH_GLOBAL_DATA_SUCCESS',
  LEADERBOARD_FETCH_RANKING_TYPES_SUCCESS: 'LEADERBOARD_FETCH_RANKING_TYPES_SUCCESS',
};

export const fetchLeaderboardGlobalData = () => {
  return Request.get({ url: '/api/leaderboard/get-template' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.LEADERBOARD_FETCH_GLOBAL_DATA_SUCCESS, payload: response.data.data });
  });
};

export const fetchLeaderboardRankingTypes = () => {
  return Request.get({ url: '/api/leaderboard/get-ranking-types' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.LEADERBOARD_FETCH_RANKING_TYPES_SUCCESS, payload: response.data.data });
  });
};
