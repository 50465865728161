import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .metric-name {
    cursor: default;
  }
  .integration-container {
    background: #f3f7fb;
    border-radius: 5px;
    padding: 5px 7px 5px 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon-area {
      position: relative;
      display: flex;
      width: 35px;
      height: 31px;
      margin-right: 7px;
      align-items: flex-end;
      filter: drop-shadow(0px 1.70455px 1.70455px #e7eef5);
    }
    .icon {
      width: 16px;
    }
    .device-icon {
      position: absolute;
      top: 7px;
      left: 2px;
      filter: drop-shadow(0px 1.70455px 1.70455px #e7eef5);
    }
    .fitbit-icon {
      position: absolute;
      left: 14px;
      filter: drop-shadow(0px 1.70455px 1.70455px #e7eef5);
    }
    .garmin-icon {
      position: absolute;
      top: 0;
      left: 15px;
      width: 19px;
      filter: drop-shadow(0px 1.70455px 1.70455px #e7eef5);
    }
    .name {
      font-weight: 600;
      font-size: 12px;
      line-height: 14.4px;
      color: #838fa4;
      margin-right: 5px;
    }
    .ask-to-connect {
      font-weight: 600;
      font-size: 12px;
      line-height: 14.4px;
      color: #5158cf;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const ChartContainer = styled.div`
  padding: 28px 30px 38px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f2;
  /* box-shadow: inset 0px -1px 0px #ebedf4, inset 0px 1px 0px #ebedf4; */
`;

export const Wrapper = styled.div`
  width: 100%;
  .step-progress-container {
    background: #fafbfc;
    padding: 40px 15px 30px;
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #202353;
    }
  }
  .content-container {
    max-width: 730px;
    margin: auto;
  }
`;
