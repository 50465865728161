import React, { useState, useEffect } from 'react';
import { Modal } from 'semantic-ui-react';

import { CDN_URL } from 'constants/commonData';

import { Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { ModalWrapper, ModalTitle, ModalSubTitle, NoteArea } from './style';

const NoteModalComponent = ({ onClose, value, title }) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(value)
  }, [value]);

  return <ModalWrapper
    open={true}
    closeOnDimmerClick={false}
    onClose={onClose}
    closeIcon={
      <button className="close-button">
        <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
      </button>
    }
  >
    <Modal.Content>
      <ModalLayout.Wrapper>
        <ModalSubTitle>Note for</ModalSubTitle>
        <ModalTitle>{title}</ModalTitle>
        <ModalLayout.Content className="wrapper-content">
          <NoteArea placeholder="Add note" disabled>{note}</NoteArea>
        </ModalLayout.Content>
        <ModalLayout.Actions className="wrapper-footer">
          <Button purple onClick={onClose} primary >
            Okay
          </Button>
        </ModalLayout.Actions>
      </ModalLayout.Wrapper>
    </Modal.Content>
  </ModalWrapper>
}

export default NoteModalComponent;