import React from 'react';
import * as S from './style';

import Step1 from 'assets/images/package_step_1.svg';
import Step2 from 'assets/images/package_step_2.svg';
import Step3 from 'assets/images/package_step_3.svg';

const Blank = () => {
  return (
    <S.BlankContainer>
      <S.StepContainer>
        <S.StepImage src={Step1} />
        <S.StepTitle>Step 1</S.StepTitle>
        <S.StepDesc>Setup payment portal</S.StepDesc>
      </S.StepContainer>
      <S.NextStep />
      <S.StepContainer>
        <S.StepImage src={Step2} />
        <S.StepTitle>Step 2</S.StepTitle>
        <S.StepDesc>Create a package</S.StepDesc>
      </S.StepContainer>
      <S.NextStep />
      <S.StepContainer>
        <S.StepImage src={Step3} />
        <S.StepTitle>HOORAY!</S.StepTitle>
        <S.StepDesc>Get paid!</S.StepDesc>
      </S.StepContainer>
    </S.BlankContainer>
  );
};

export default Blank;
