import React from 'react';
import moment from 'moment';

// assets
// import { ReactComponent as AIThinkingIcon } from 'assets/icons/ai-thinking.svg';
import AIIconPng from 'assets/images/ai-msg-icon.png';
import StarRotate from 'assets/gifs/ai_star_rotate.gif';
import { ReactComponent as AIBeta } from 'assets/icons/ai-beta.svg';

import * as S from './style';

const SendingMsg = () => {
  return (
    <S.ConversationItem>
      <div className="ai-header">
        <span className="avt">
          <img src={AIIconPng} alt="" className="ai-msg-icon" />
        </span>
        <h6>Everfit AI</h6>
        <AIBeta className="ai-beta" />
        <span className="timestamp">{moment().format('hh:mm A')}</span>
      </div>

      <div className="message">
        <div className="ai-thinking">
          <img src={StarRotate} className="loading-rotate" alt="" />
          <span className="ai-thinking-text">Cooking some magic</span>
        </div>
      </div>
    </S.ConversationItem>
  );
};

export default SendingMsg;
