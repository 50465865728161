import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { initPricingData, confirmPayment } from 'redux/pricing/actions';
import { selectSegment } from 'redux/segment/actions';
import { initPermission } from 'redux/permission/actions';
import { updatePaymentStatus } from 'redux/payment/actions';

import DowngradeQuestionnaireCommponent from './DowngradeQuestionnaireComponent';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  return {
    user,
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
    initPermission: bindActionCreators(initPermission, dispatch),
    confirmPayment: bindActionCreators(confirmPayment, dispatch),
    selectSegment: bindActionCreators(selectSegment, dispatch),
    updatePaymentStatus: bindActionCreators(updatePaymentStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DowngradeQuestionnaireCommponent);
