export const Types = {
  FORM_SUBMITTING: 'FORM_SUBMITTING',
  FORM_SUBMITED: 'FORM_SUBMITED',
  RESET_SUBMIT_STATUS: 'RESET_SUBMIT_STATUS',
}

export const onSubmitting = key => {
  return {
    type: Types.FORM_SUBMITTING,
    key
  }
}

export const onSubmitted = (key, success) => {
  return {
    type: Types.FORM_SUBMITED,
    key,
    success
  }
}

export const onResetStatus = key => {
  return {
    type: Types.RESET_SUBMIT_STATUS,
    key
  }
}