import React from 'react';
import get from 'lodash/get';
import { autoflowTypeParse } from 'utils/commonFunction';
import { ReactComponent as AutoflowSetting } from 'assets/icons/asset-autoflow-setting-icon.svg';

import * as S from './style';

const AutoflowOption = ({ data }) => {
  const type = get(data, 'type', '');
  const isSettingTurnedOff = get(data, 'isSettingTurnedOff', false);
  return (
    <S.Wrapper gap={6}>
      <S.Title className="title">{get(data, 'label', '')}</S.Title>
      <S.AutoflowWrapper>
        {type ? <S.AutoflowLabel type={type}>{autoflowTypeParse(type)}</S.AutoflowLabel> : null}
        {!isSettingTurnedOff ? <AutoflowSetting /> : null}
      </S.AutoflowWrapper>
    </S.Wrapper>
  );
};

export default AutoflowOption;
