import React from 'react';

import * as S from 'shared/PreviewLink/styles';
import { ReactComponent as CloseMetaIcon } from 'assets/icons/close_thin.svg';

function PreviewArticleLink(props) {
  const {
    attachmentMeta = {},
    closePreview = false,
    onTimeline = false,
    isScheduledPost = false,
    onPostDetail = false,
  } = props;
  const { title = '', description = '', image = '', publisher = '', url = '', domain = '' } = attachmentMeta;
  if (!title && !description && !image && !publisher && !domain) return null;
  const handleOpenLink = () => {
    if ((url && onTimeline) || (url && onPostDetail)) {
      window.open(url, '_blank');
    }
  };

  return (
    <S.MetadataWrapper onTimeline={onTimeline} onPostDetail={onPostDetail}>
      <S.MetaContent
        onTimeline={onTimeline}
        onPostDetail={onPostDetail}
        onClick={handleOpenLink}
        isScheduledPost={isScheduledPost}
      >
        {image && <img alt="" src={image} />}
        <S.TextWrapper
          onTimeline={onTimeline}
          onPostDetail={onPostDetail}
          hasImage={image}
          isScheduledPost={isScheduledPost}
        >
          <S.TextURL>{domain || publisher}</S.TextURL>
          <S.TextTitle>{domain || title}</S.TextTitle>
          <S.TextDesc>{description}</S.TextDesc>
        </S.TextWrapper>
        {!onTimeline && !onPostDetail && (
          <S.CloseMetaData onClick={closePreview}>
            <CloseMetaIcon />
          </S.CloseMetaData>
        )}
      </S.MetaContent>
    </S.MetadataWrapper>
  );
}

export default PreviewArticleLink;
