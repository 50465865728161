import React from 'react';
import classNames from 'classnames';
import { Button, Form, Label, Image, Radio, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import { push } from 'connected-react-router';
import Datetime from 'react-datetime';
import Avatar from 'react-avatar';
import diff from 'deep-diff';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Prompt } from 'react-router';

import ConfirmModal from 'shared/ConfirmModal';
import GeneralButton from 'shared/GeneralButton';
import TimezoneSelect from 'shared/TimezoneSelect';
import DateFormatSelect from 'shared/DateFormatSelect';
import { CDN_URL, CONVERSION, DATE_FORMATS } from 'constants/commonData';
import { FILE_VALIDATIONS } from 'shared/ChatInput';
import {
  getPresignedUploadUrl,
  getUserShortName,
  mediaLog,
  disableFutureDateTime,
  convertS3UrlToCloudFrontUrl,
  upperFirstCharacter,
} from 'utils/commonFunction';
import { ReactComponent as GoogleIcon } from 'assets/icons/google-icon.svg';
import { ReactComponent as ExclamtionMark } from 'assets/icons/exclamtion-mark.svg';
import { VALIDATIONS } from 'components/TaskCreate/GeneralTaskForm/helper';

import './styles.scss';
import * as S from './styles';

const DEFAULT_BASE_INFO_STATUS = { error: false, isSubmitting: false };

export default class AccountDetails extends React.Component {
  state = {
    user: this.getDefaultUser(),
    updateBaseInfo: DEFAULT_BASE_INFO_STATUS,
    dateFormat: this.getDateFormat(),
    timezone: this.getDefaultUser().timezone,
    autoAdjustTimezone: this.getDefaultUser().auto_adjust_timezome,
  };

  getDefaultUser() {
    return this.props.user ? _.cloneDeep(this.props.user) : {};
  }

  getDateFormat() {
    return this.getDefaultUser().date_format
      ? { label: this.getDefaultUser().date_format, value: this.getDefaultUser().date_format }
      : DATE_FORMATS[0];
  }

  componentDidMount() {
    this.props.toggleSideBar(true);
    this.props.getProfile({ isUpdated: true }).then(() => {
      this.setState({
        user: this.getDefaultUser(),
        dateFormat: this.getDateFormat(),
        timezone: this.getDefaultUser().timezone,
        autoAdjustTimezone: this.getDefaultUser().auto_adjust_timezome,
      });
    });
  }

  componentWillUnmount() {
    this.props.getProfile({ isUpdated: true }).then(() => {
      this.setState({
        user: this.getDefaultUser(),
        dateFormat: this.getDateFormat(),
        timezone: this.getDefaultUser().timezone,
        autoAdjustTimezone: this.getDefaultUser().auto_adjust_timezome,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (diff(this.props.user, prevProps.user)) {
      this.setState({
        user: this.props.user ? _.cloneDeep(this.props.user) : {},
      });
    }
  }

  triggerInputFile = () => this.fileInput.click();

  onSelectPhoto = async e => {
    const maxImageSize = FILE_VALIDATIONS.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE;
    const files = Array.from(e.target.files);
    if (files[0]) {
      const { size, name, type } = files[0];
      if (!VALIDATIONS.IMAGE.accepts.includes(type)) {
        return toast.error('File type not supported');
      }
      if (size > maxImageSize) {
        return toast.error(`Please upload an image smaller than ${FILE_VALIDATIONS.MAX_SIZE_IMAGE} MB`);
      }

      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file via User Profile',
      });
      const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-avatar', files[0]);
      if (!uploadUrl || !configs) {
        return;
      }

      let user = this.state.user;
      user.avatar = configs.url;
      this.setState({
        user,
        uploadConfig: {
          url: uploadUrl,
          headers: { 'Content-Type': type },
          data: files[0],
        },
      });
      let reader = new FileReader();
      reader.onload = e => {
        let pendingAvatarImage = this.state.pendingAvatarImage;
        pendingAvatarImage = e.target.result;
        this.setState(prevState => ({
          pendingAvatarImage,
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  renderPendingEmail() {
    const { user } = this.state;
    const { pending_email, is_verified_email, email, force_send_verification_email_at, change_email_requested_at } =
      user || {};
    const formattedDate = moment(force_send_verification_email_at).add(30, 'days').format('Do MMM YYYY');

    const dateChangeEmail = moment(change_email_requested_at);
    const dateForceVerificationEmail = moment(force_send_verification_email_at);
    const isBefore = dateChangeEmail.isBefore(dateForceVerificationEmail);

    if (pending_email && is_verified_email && !force_send_verification_email_at) {
      return (
        <div className="pending-email-sent-container">
          <div className="pending-email-sent-icon">
            <img src={`${CDN_URL}/images/pending_email_send_info.svg`} alt="pending_email_send_info" />
          </div>
          <div className="pending-email-sent-text">
            We have sent a verification email to: <span className="pending-email-highlight">{pending_email}</span>.
            Please check your email and follow the link to complete the change.
          </div>
        </div>
      );
    }

    if (!is_verified_email && force_send_verification_email_at) {
      return (
        <div className="pending-email-sent-container">
          <div className="pending-email-sent-icon">
            <img src={`${CDN_URL}/images/pending_email_send_info.svg`} alt="pending_email_send_info" />
          </div>
          <div className="pending-email-sent-text">
            A verification email has been sent to{' '}
            <span className="pending-email-highlight">{isBefore ? email : pending_email || email}</span>. Please review
            your email and click on the provided link to finalize the process. If your account is not verified by{' '}
            <span className="pending-email-highlight">{formattedDate}</span>, there will be limited access to certain
            features.
          </div>
        </div>
      );
    }

    return null;
  }

  handleDiscardChange = nextLocation => {
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          const defaultUser = this.getDefaultUser();
          this.setState(
            {
              user: defaultUser,
              dateFormat: this.getDateFormat(),
              timezone: defaultUser.timezone,
              autoAdjustTimezone: defaultUser.auto_adjust_timezome,
            },
            () => {
              this.props.push(nextLocation.pathname);
            },
          );
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
        hasHoverState
      />,
    );
    return false;
  };

  handleScrollDown = () => {
    setTimeout(() => {
      let div = document.getElementsByClassName('accountdetails-container')[0];
      if (div) {
        div.scrollTop = div.scrollHeight - div.clientHeight;
      }
    }, 0);
  };

  onChangeName = event => {
    const { user } = this.state;
    const { value = '', name = '' } = event.target;
    this.setState({ user: { ...user, [name]: value } });
  };

  onBlurInputName = () => {
    const { user } = this.state;
    const capitalizeFirstName = upperFirstCharacter((user || {}).first_name);
    const capitalizeLastName = upperFirstCharacter((user || {}).last_name);
    const newUser = { ...user, first_name: capitalizeFirstName, last_name: capitalizeLastName };
    this.setState({ user: newUser });
  };

  render() {
    const { cloudfrontList } = this.props;
    const {
      user,
      updateBaseInfo,
      dateFormat,
      timezone,
      autoAdjustTimezone,
      pendingAvatarImage,
      pendingAvatarData,
    } = this.state;
    const { dob } = user;
    let avatar = convertS3UrlToCloudFrontUrl(user.avatar, cloudfrontList, true);

    if (pendingAvatarImage) {
      avatar = pendingAvatarImage;
    }

    let isDiffInfo =
      pendingAvatarData ||
      diff(
        _.omit(this.props.user, ['timezone', 'auto_adjust_timezome', 'date_format']),
        _.omit(user, ['timezone', 'auto_adjust_timezome', 'date_format']),
      );

    let isDiffTimezone = timezone !== user.timezone || autoAdjustTimezone !== user.auto_adjust_timezome;
    let isDiffDateFormat = !_.isEqual(dateFormat.value, user.date_format || DATE_FORMATS[0].value);
    const isGoogleAuth = _.get(user, 'is_sign_up_via_google', false);
    const dobTimezone = moment.utc(dob);

    return (
      <div className="accountdetails-container">
        <Prompt when={isDiffInfo || isDiffTimezone || isDiffDateFormat} message={this.handleDiscardChange} />
        <div className="basic_info account-details">
          <div className="one">
            <div>
              <Image width={17} height={17} src={`${CDN_URL}/images/basic_info.svg`} />
              <span>Basic Info</span>
            </div>
            <div className="avatar-container">
              <Avatar
                name={getUserShortName(user)}
                className="client-detail-avatar"
                size="100"
                src={avatar}
                color={user.color}
              />
              <div className="avatar-camera-container" onClick={() => this.triggerInputFile()}>
                <input
                  style={{ visibility: 'hidden' }}
                  type="file"
                  ref={fileInput => (this.fileInput = fileInput)}
                  onChange={this.onSelectPhoto}
                  accept={VALIDATIONS.IMAGE.accepts}
                />
                <Image src={`${CDN_URL}/images/camera_grey.svg`} className="avatar-camera" />
              </div>
            </div>
          </div>
          <div className="three">
            <Form>
              <Form.Group widths="equal" className="trainer-name__form-control">
                <Form.Field>
                  <Label className="setting-label">FIRST NAME</Label>
                  <input
                    className={classNames('form-text', { error: updateBaseInfo.error && !user.first_name })}
                    onChange={this.onChangeName}
                    onBlur={this.onBlurInputName}
                    value={user.first_name}
                    name="first_name"
                    placeholder={
                      updateBaseInfo.error && !user.first_name ? 'First name can not be empty' : 'First name'
                    }
                    maxLength={30}
                  />
                </Form.Field>
                <Form.Field>
                  <Label className="setting-label">LAST NAME</Label>
                  <input
                    className={classNames('form-text', { error: updateBaseInfo.error && !user.last_name })}
                    onChange={this.onChangeName}
                    onBlur={this.onBlurInputName}
                    value={user.last_name}
                    name="last_name"
                    placeholder={updateBaseInfo.error && !user.last_name ? 'Last name can not be empty' : 'Last name'}
                    maxLength={30}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Label className="setting-label">BIRTHDATE</Label>
                  {
                    <Datetime
                      value={dobTimezone}
                      isValidDate={disableFutureDateTime}
                      renderInput={props => {
                        return (
                          <div>
                            <div className="ui icon input birthday-field">
                              <input
                                {...props}
                                placeholder={'Birthdate'}
                                className={'form-text'}
                                type="text"
                                value={dob ? dobTimezone.format('MMM Do YYYY') : ''}
                              />
                              <i aria-hidden="true" className="calendar check outline icon" />
                            </div>
                          </div>
                        );
                      }}
                      timeFormat={false}
                      onChange={momentData => {
                        if (moment.isMoment(momentData)) {
                          let user = this.state.user;
                          user.dob = momentData.toISOString();
                          this.setState(p => ({
                            user,
                          }));
                        }
                      }}
                      onOpen={momentData => {
                        if (moment.isMoment(momentData)) {
                          let user = this.state.user;
                          user.dob = momentData.toISOString();
                          this.setState(p => ({
                            user,
                          }));
                        }
                      }}
                    />
                  }
                </Form.Field>
                <Form.Field>
                  <Label className="setting-label">PHONE</Label>
                  <input
                    className={'form-text'}
                    onChange={evt => {
                      let user = this.state.user;
                      user.phone = evt.target.value;
                      this.setState(p => ({
                        user,
                      }));
                    }}
                    value={user.phone}
                    placeholder={'Phone'}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group style={{ marginLeft: '0' }}>
                <Form.Field
                  control={Radio}
                  label="Male"
                  className={'general ' + (user.sex === '0' ? 'active' : 'isactive')}
                  value="0"
                  checked={user.sex === '0'}
                  onChange={(evt, { value }) => {
                    let user = this.state.user;
                    user.sex = value;
                    this.setState(p => ({
                      user,
                    }));
                  }}
                />
                <Form.Field
                  control={Radio}
                  className={'general ' + (user.sex === '1' ? 'active' : 'isactive')}
                  label="Female"
                  value="1"
                  checked={user.sex === '1'}
                  onChange={(evt, { value }) => {
                    let user = this.state.user;
                    user.sex = value;
                    this.setState(p => ({
                      user,
                    }));
                  }}
                />
              </Form.Group>
            </Form>
          </div>
          <div className="save_area one">
            {isDiffInfo && (
              <div>
                <GeneralButton onClick={this.handleSaveUserInfo.bind(this)}>Save</GeneralButton>
              </div>
            )}
          </div>
        </div>
        <div className="divider email" />
        <div className="basic_info email">
          <div className="one"></div>
          <div className="three">
            <Form
              onKeyDown={event => {
                event.key === 'Enter' && event.preventDefault();
              }}
            >
              <Form.Group>
                <Form.Field className="custom-field">
                  <Label className="setting-label">EMAIL</Label>
                  {isGoogleAuth ? (
                    <S.EmailWrapper>
                      <S.EmailGoogleIcon>
                        <GoogleIcon />
                      </S.EmailGoogleIcon>
                      <input className={'form-text google'} value={user.email} placeholder={'Email'} readOnly />
                    </S.EmailWrapper>
                  ) : (
                    <input className={'form-text'} value={user.email} placeholder={'Email'} readOnly />
                  )}
                </Form.Field>
              </Form.Group>
              {this.renderPendingEmail()}
              <Form.Group>
                {isGoogleAuth ? (
                  <Form.Field className="custom-field">
                    <S.WrapperNotification>
                      <ExclamtionMark />
                      <S.Notification>
                        You're logged in with Google. Use your Google Account, if you would like to change your
                        password.
                      </S.Notification>
                    </S.WrapperNotification>
                  </Form.Field>
                ) : (
                  <>
                    <Form.Field>
                      <Button
                        className="setting-button"
                        onClick={() => {
                          this.props.dispatch(push('/home/changeemail'));
                        }}
                      >
                        Change Email
                      </Button>
                    </Form.Field>
                    <Form.Field>
                      <Button
                        className="setting-button"
                        onClick={() => {
                          this.props.dispatch(push('/home/changepassword'));
                        }}
                      >
                        Change Password
                      </Button>
                    </Form.Field>
                  </>
                )}
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="divider" />
        <div className="basic_info timezone">
          <div className="one">
            <Image width={17} height={17} src={`${CDN_URL}/images/timezone.svg`} />
            <span>Timezone</span>
          </div>
          <div className="three">
            <TimezoneSelect isDisabled={autoAdjustTimezone} onChange={this.handleChangeTimezone} timezone={timezone} />
            <Checkbox
              toggle
              label="Auto adjust timezone based on location"
              checked={autoAdjustTimezone}
              style={{ zIndex: 0 }}
              className="trainer-timezone everfit-toggle"
              onChange={this.handleChangeAutoAdjustTimezone}
            />
            <DateFormatSelect
              fullWidth={false}
              dateFormat={dateFormat}
              onChange={this.handleChangeDateFormat}
              onScrollDown={this.handleScrollDown}
            />
          </div>
          <div className="save_area one">
            {(isDiffTimezone || isDiffDateFormat) && (
              <div>
                <GeneralButton onClick={this.handleUpdateTimezoneSettings}>Save</GeneralButton>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  handleChangeDateFormat = value => this.setState({ dateFormat: value });

  handleChangeTimezone = key => this.setState({ timezone: key.value });

  handleChangeAutoAdjustTimezone = () => {
    this.setState(p => ({
      timezone: !p.autoAdjustTimezone ? moment.tz.guess() : p.timezone,
      autoAdjustTimezone: !p.autoAdjustTimezone,
    }));
  };

  handleSaveUserInfo() {
    const { user, updateBaseInfo, uploadConfig } = this.state;
    const { first_name, last_name } = user;

    if (updateBaseInfo.isSubmitting) {
      return false;
    }

    const trimmedFirstName = first_name.trim();
    const trimmedLastName = last_name.trim();
    const newUser = {
      ...user,
      first_name: trimmedFirstName,
      last_name: trimmedLastName,
    };

    if (!trimmedFirstName || !trimmedLastName) {
      return this.setState({ updateBaseInfo: { ...updateBaseInfo, error: true }, user: newUser });
    }

    this.setState({ updateBaseInfo: { ...updateBaseInfo, isSubmitting: true }, user: newUser });
    this.props.saveOwnerInfo(newUser, uploadConfig, () =>
      this.setState({ updateBaseInfo: DEFAULT_BASE_INFO_STATUS, pendingAvatarData: null, uploadConfig: null }),
    );
  }

  handleUpdateTimezoneSettings = () => {
    const { user, dateFormat, timezone, autoAdjustTimezone } = this.state;
    const params = {
      date_format: dateFormat.value,
      timezone,
      auto_adjust_timezome: autoAdjustTimezone,
    };
    this.props.updateTimezoneSettings(params).then(() => {
      this.setState({
        user: { ...user, ...params },
      });
    });
  };
}
