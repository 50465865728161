import styled, { css } from 'styled-components';
import { FormGroup } from 'shared/FormControl';

export const FormHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MessageInput = styled.div`
  outline: none !important;
  background: #ffffff;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  min-height: 88px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #000000;
  padding: 15px;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }

  :empty::before {
    content: 'Add your message';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    opacity: 0.2;
  }
`;

export const Attachment = styled.div`
  width: 170px;
  height: 96px;
  border-radius: 2px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  .remove {
    position: absolute !important;
    top: -7px;
    right: -7px;
    z-index: 2;
    cursor: pointer;
  }

  .video-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 2px;
    .media-player__trigger {
      width: 100% !important;
      height: 100% !important;
    }
  }

  video,
  img.image-with-fallback {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${props =>
    props.background &&
    css`
      background-image: url(${props => props.background});
    `}

  ${props =>
    props.video &&
    css`
      .shadow {
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 12px;
        color: #ffffff;
        z-index: 2;

        img.ui {
          margin-right: 3px;
        }

        .duration {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    `}
`;

export const Media = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const MediaContainer = styled.div`
  position: relative;
  /* margin-top: 10px; */
  min-height: 100px;

  .file-in-process {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #ff2e21;
    width: 100%;
  }
`;

export const FormContent = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 8px 20px;
  position: relative;
  outline: none !important;

  .drop-zone__input-container {
    top: 0;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 555px;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;

  ${FormGroup} {
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .count {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 11px;
        color: #000000;
        opacity: 0.3;
      }
    }

    input {
      border: 1px solid #d4d7d9;
    }
  }

  ${props =>
    props.hasError &&
    css`
      ${FormGroup} {
        &.error {
          input {
            background: #fff7f7;
            border: 1px solid #ff9791;

            ::placeholder {
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              color: #ff2e21;
              opacity: 1;
            }
          }
        }

        ${MessageInput} {
          :empty {
            background: #fff7f7;
            border: 1px solid #ff9791;

            ::before {
              content: 'This field cannot be blank';
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              color: #ff2e21;
              opacity: 1;
            }
          }
        }

        .quill:has(.ql-blank) {
          background: #fff7f7;

          .ql-editor.ql-blank::before {
            content: 'This field cannot be blank' !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 11px !important;
            color: #ff2e21 !important;
            opacity: 1 !important;
          }
        }
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  height: 570px;
`;

export const Footer = styled.div`
  padding: 20px;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-right: 5px;
    }

    .app-tooltip-white {
      width: 158px;
      padding: 14px 15px;
      border: 1px solid #dfe4e5;
      box-sizing: border-box;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
      color: #000000;
      text-transform: none;
      min-width: unset;
    }

    .announcement-tooltip {
      width: 158px;
      padding: 14px 15px;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
      text-transform: none;
      min-width: unset;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .schedule {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .schedule__end-date--invalid {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        text-align: right;
        color: #ff2e21;
        position: absolute;
        right: 0;
        top: -23px;
      }

      > * {
        margin-right: 7px;
      }

      .ui.dropdown.new-ui {
        margin-right: 15px;
        line-height: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-color: #d4d7d9;
        color: #000;
        min-width: 140px;
        border-radius: 5px !important;

        .menu {
          top: unset;
          bottom: calc(100% + 6px);
        }
      }

      .custom-date-input {
        font-size: 13px;
        line-height: 16px;
        padding: 12px 15px;
        border-color: #d4d7d9;
        background-color: #fafbfc;

        &.invalid {
          background: #fff7f7;
          border: 1px solid #ff9791;
        }
      }
    }

    button {
      width: 140px;
      height: 42px;
      line-height: 16px;
      font-size: 13px;
    }
  }

  .rdt {
    .rdtPicker {
      margin-top: 0;
      bottom: calc(100% + 7px);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Wrapper = styled.div``;

export const AttachmentLinkWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background: #f4f4f5;

  .remove {
    position: absolute !important;
    top: -7px;
    right: -7px;
    z-index: 2;
    cursor: pointer;
  }

  .video-container {
    width: 242px;
    height: 136px;
    border-radius: 5px;
    overflow: hidden;
    background: #f4f4f4;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .invalid-link {
    background: rgba(234, 49, 74, 0.05);
    border-radius: 4px;
    font-size: 13px;
    line-height: 18px;
    color: #ff1b1b;
    padding: 10px 15px;
    margin-top: 10px;
  }
`;
