// Lib
import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import uniqBy from 'lodash/uniqBy';
import { toast } from 'react-toastify';
import { DragDropContext } from 'react-beautiful-dnd';

// Constants
import { ASSIGNED_STATUS, LIST_DAY_OF_WEEK, MEAL_PLAN_STATUS, STATUS_ADD_WEEK } from './constants';
import { getMacroNutrients, calculateMacroNutrients } from 'components/MealPlanDayDetail/constants';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';

// Parts
import AddNote from './Parts/AddNote';
import DayMeal from './Parts/DayMeal';
import Menu from './Parts/Menu';
import NoteModal from './Parts/NoteModal';
import NutritionOverview from './Parts/NutritionOverview';
import RemoveWeek from './Parts/RemoveWeek';
import LoadingIndicator from 'shared/LoadingIndicator';

// Common function
import { calculateTypeMap } from './commonFunction';

// Utils
import { isTeamAdmin } from 'utils/commonFunction';

// Styles
import * as S from './style';

const queryAttr = 'data-rbd-drag-handle-draggable-id';

const combineSameDay = (days, result) => {
  const { combine, draggableId } = result || {};
  const { draggableId: combineDraggableId, droppableId: combineDroppableId } = combine || {};

  const resultDays = days.map(itemDay => {
    const { day_index, day_meal: dayMeal = [] } = itemDay || {};
    const { meal_group: mealGroup = [] } = dayMeal || [];

    if (day_index === +combineDroppableId) {
      const mealCombine = mealGroup.find(item => item && item._id === draggableId);
      const recipesCombine = mealCombine.recipes;
      const removeMealCombine = mealGroup.filter(item => {
        return item && item._id !== draggableId;
      });

      const resultMeal = removeMealCombine.map(item => {
        if (item && item._id === combineDraggableId) {
          return { ...item, recipes: uniqBy([...item.recipes, ...recipesCombine], 'recipe._id') };
        }
        return item;
      });

      return { ...itemDay, day_meal: { ...dayMeal, meal_group: resultMeal } };
    }

    return itemDay;
  });
  return resultDays;
};

const combineDifferentDay = (days, result) => {
  const { combine, source } = result || {};

  const { draggableId: combineDraggableId, droppableId: combineDroppableId } = combine || {};
  const { droppableId: sourceDroppableId, index: sourceIndex } = source || {};

  const combineDay = days.find(itemDay => itemDay && itemDay.day_index === +sourceDroppableId);
  const mealCombine = get(combineDay, 'day_meal.meal_group', [])[sourceIndex];
  const recipesCombine = mealCombine.recipes;

  const resultDays = days.map(itemDay => {
    const { day_index, day_meal: dayMeal = [] } = itemDay || {};
    const { meal_group: mealGroup = [] } = dayMeal || [];
    const reorderedItems = Array.from(mealGroup);

    if (day_index === +sourceDroppableId) {
      reorderedItems.splice(sourceIndex, 1);
      return { ...itemDay, day_meal: { ...dayMeal, meal_group: reorderedItems } };
    }

    if (day_index === +combineDroppableId) {
      const resultMeal = reorderedItems.map(item => {
        if (item && item._id === combineDraggableId) {
          return { ...item, recipes: uniqBy([...item.recipes, ...recipesCombine], 'recipe._id') };
        }
        return item;
      });

      return { ...itemDay, day_meal: { ...dayMeal, meal_group: resultMeal } };
    }

    return itemDay;
  });

  return resultDays;
};

const notCombineSameDay = (days, result) => {
  const { destination, source } = result || {};
  const { index: destinationIndex } = destination || {};
  const { droppableId: sourceDroppableId, index: sourceIndex } = source || {};

  const resultDays = days.map(itemDay => {
    const { day_index, day_meal: dayMeal = [] } = itemDay || {};
    const { meal_group: mealGroup = [] } = dayMeal || [];

    if (day_index === +sourceDroppableId) {
      const reorderedItems = Array.from(mealGroup);
      const [removed] = reorderedItems.splice(sourceIndex, 1);
      reorderedItems.splice(destinationIndex, 0, removed);
      return { ...itemDay, day_meal: { ...dayMeal, meal_group: reorderedItems } };
    }

    return itemDay;
  });

  return resultDays;
};

const convertMealGroup = mealGroup => {
  return mealGroup.map(itemMeal => ({
    ...itemMeal,
    recipes: get(itemMeal, 'recipes', []).map(itemRecipe => ({
      _id: get(itemRecipe, '_id', ''),
      recipe: get(itemRecipe, 'recipe._id', ''),
    })),
  }));
};

const getDataSameDay = (days, result = {}) => {
  const { source } = result;
  const { droppableId: sourceDroppableId } = source || {};

  const sourceDay = days.find(item => item && item.day_index === +sourceDroppableId) || {};

  const data = {
    days: [
      {
        day_index: get(sourceDay, 'day_meal.day_index', ''),
        note: get(sourceDay, 'day_meal.note', ''),
        show_dietary_info: get(sourceDay, 'day_meal.show_dietary_info', false),
        meal_group: convertMealGroup(get(sourceDay, 'day_meal.meal_group', [])),
      },
    ],
  };

  return data;
};

const getDataDifferentDay = (days, result = {}, isCombine = false) => {
  const { combine, destination, source } = result;
  const { droppableId: combineDroppableId } = combine || {};
  const { droppableId: destinationDroppableId } = destination || {};
  const { droppableId: sourceDroppableId } = source || {};

  const sourceDay = days.find(item => item && item.day_index === +sourceDroppableId) || {};
  const destinationDay =
    days.find(item => item && item.day_index === (isCombine ? +combineDroppableId : +destinationDroppableId)) || {};

  const data = {
    days: [
      {
        day_index: get(sourceDay, 'day_meal.day_index', ''),
        note: get(sourceDay, 'day_meal.note', ''),
        show_dietary_info: get(sourceDay, 'day_meal.show_dietary_info', false),
        meal_group: convertMealGroup(get(sourceDay, 'day_meal.meal_group', [])),
      },
      {
        day_index: get(destinationDay, 'day_meal.day_index', ''),
        note: get(destinationDay, 'day_meal.note', ''),
        show_dietary_info: get(destinationDay, 'day_meal.show_dietary_info', false),
        meal_group: convertMealGroup(get(destinationDay, 'day_meal.meal_group', [])),
      },
    ],
  };

  return data;
};

const notCombineDifferentDay = (days, result) => {
  const { destination, source } = result || {};
  const { droppableId: destinationDroppableId, index: destinationIndex } = destination || {};
  const { droppableId: sourceDroppableId, index: sourceIndex } = source || {};

  const sourceDay = days.find(itemDay => itemDay && itemDay.day_index === +sourceDroppableId);
  const sourceItem = get(sourceDay, 'day_meal.meal_group', [])[sourceIndex];

  const destinationDay = days.find(itemDay => itemDay && itemDay.day_index === +destinationDroppableId);

  let resultDays = days.map(itemDay => {
    const { day_index, day_meal: dayMeal = [] } = itemDay || {};
    const { meal_group: mealGroup = [] } = dayMeal || [];
    const reorderedItems = Array.from(mealGroup);

    if (day_index === +sourceDroppableId) {
      reorderedItems.splice(sourceIndex, 1);
      return { ...itemDay, day_meal: { ...dayMeal, meal_group: reorderedItems } };
    }

    if (day_index === +destinationDroppableId) {
      reorderedItems.splice(destinationIndex, 0, sourceItem);
      return { ...itemDay, day_meal: { ...dayMeal, meal_group: reorderedItems } };
    }

    return itemDay;
  });

  // Empty
  if (!isObject(destinationDay)) {
    const dataDay = {
      day_index: +destinationDroppableId,
      day_meal: {
        day_index: +destinationDroppableId,
        note: '',
        show_dietary_info: false,
        meal_group: [sourceItem],
      },
    };
    resultDays = [...resultDays, dataDay];
  }

  return resultDays;
};

const checkRearrangeEmpty = (days = []) => {
  const check = days.some(item => item && item.day_index === '') || false;
  return check;
};

const MealPlanDetail = props => {
  const {
    history,
    detailMealPlan = {},
    detailMealPlanByWeek,
    toggleModal,
    getDetailMealPlan,
    getDetailMealPlanByWeek,
    updateMealPlanByWeek,
    resetDetailMealPlan,
    resetDetailMealPlanByWeek,
    toggleConfirmModal,
    changeModeEditByDay,
    addWeekInMealPlan,
    removeWeekInMealPlan,
    getAssignedList,
    toggleSecondModal,
    updateDetailMealPlanByWeek,
    rearrangeMode,
    updateDataDaysByWeek,
    currentUser,
  } = props;
  const [loadingChangeWeek, setLoadingChangeWeek] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState({});

  const wrapperRef = useRef();

  const mealPlanId = get(props, 'match.params.mealPlanId', '');
  const weekId = get(props, 'match.params.weekId', '');

  const dayIndexState = get(props, 'location.state.dayIndexState', '');

  const { days = [], isDragging } = detailMealPlanByWeek;

  const {
    status = false,
    is_edit_mode: isEditMealPlan = false,
    is_syncing: isSyncing = false,
    author = {},
    share,
    number_of_weeks: numberOfWeeks,
  } = detailMealPlan || {};

  const isPublish = status === MEAL_PLAN_STATUS.PUBLISH;

  const indexCurrentWeek = get(detailMealPlanByWeek, 'week_index', 0);

  const disabledAction = useMemo(() => {
    const { _id: userId } = currentUser || {};
    const { _id: authorId } = author || {};

    const isCreator = userId === (authorId || author);
    const isOwnerOrAdmin = isTeamAdmin(currentUser);
    const isNoOwner = share === TEAM_SHARE_NOOWNER;

    return !(isCreator || isOwnerOrAdmin || isNoOwner);
  }, [currentUser, detailMealPlan]);

  useEffect(() => {
    getDetailMealPlan && getDetailMealPlan(mealPlanId, weekId);
    return () => {
      resetDetailMealPlan && resetDetailMealPlan();
      resetDetailMealPlanByWeek && resetDetailMealPlanByWeek();
      toggleModal(false);
      toggleSecondModal(false);
    };
  }, []);

  useEffect(() => {
    if (mealPlanId) {
      let params = {
        meal_plan_id: mealPlanId,
        status: ASSIGNED_STATUS.ACTIVE,
      };

      getAssignedList && getAssignedList(params);
    }
  }, []);

  useEffect(() => {
    // Handle scrolling to the previous day when returning from the meal plan detail.
    const targetElement = document.getElementById(`wrapper-day-index-${dayIndexState}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        block: 'start',
      });
      setTimeout(() => {
        history.replace();
      }, 3000);
    }

    return () => {
      window.onbeforeunload = function (event) {
        if (dayIndexState) {
          history.replace();
        }
      };
    };
  }, [days]);

  const getDraggedDom = draggableId => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    return document.querySelector(domQuery);
  };

  const getClientY = (draggedDOM, array, index) => {
    const result =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft) +
      array.slice(0, index).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginRight = parseFloat(style.marginRight) + 15;
        return total + 195 + marginRight;
      }, 0);

    return result;
  };

  const handleScrollOnTop = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const resultListDayOfWeek = useMemo(() => {
    const result = LIST_DAY_OF_WEEK.map(day => {
      const matchingCondition = days.find(item => get(item, 'day_index', '') === LIST_DAY_OF_WEEK.indexOf(day));
      if (matchingCondition) {
        return {
          ...day,
          data: matchingCondition,
        };
      }
      return day;
    });

    return result;
  }, [days]);

  const listNutrition = useMemo(() => {
    const dataDays = days.map(item => calculateMacroNutrients(get(item, 'day_meal.meal_group', [])));

    const checkExistRecipe = days.filter(item => {
      const allRecipesEmpty = get(item, 'day_meal.meal_group', []).every(
        group => get(group, 'recipes.length', 0) === 0,
      );
      return !allRecipesEmpty;
    });

    const typeMapResult = calculateTypeMap(dataDays, checkExistRecipe);
    const listNutrients = getMacroNutrients(typeMapResult);

    return listNutrients;
  }, [days]);

  const handleUpdateNote = (data, type) => {
    if (mealPlanId && weekId) {
      updateMealPlanByWeek(mealPlanId, weekId, { note: data }).then(() => {
        toggleModal && toggleModal(false);
        switch (type) {
          case 'delete':
            toast('Note deleted.');
            break;
          case 'update':
            toast('Successfully updated.');
            break;
          case 'add':
            toast('Note added.');
            break;
          default:
            break;
        }
      });
    }
  };

  const handleOpenNote = () => {
    toggleModal &&
      toggleModal(
        true,
        <NoteModal
          title={`Week ${get(detailMealPlanByWeek, 'week_index', 0) + 1} Note`}
          content={get(detailMealPlanByWeek, 'note', '')}
          toggleModal={toggleModal}
          handleUpdateNote={handleUpdateNote}
          toggleConfirmModal={toggleConfirmModal}
          isPublish={isPublish}
          isEditMealPlan={isEditMealPlan}
          disabledAction={disabledAction}
        />,
      );
  };

  const handleAddMeal = (type, dayIndex) => {
    const isEdit = type === 'edit';
    const modeEdit = isEdit ? '?mode=edit' : '';
    changeModeEditByDay && changeModeEditByDay(isEdit);

    if (mealPlanId && weekId) {
      history.push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}/days/${dayIndex}${modeEdit}`);
    }
  };

  const handleChangeWeek = weekIndex => {
    setLoadingChangeWeek(true);
    const weeks = get(detailMealPlan, 'weeks', []);
    const weekId = weeks[weekIndex];
    history.push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}`);
    getDetailMealPlanByWeek &&
      getDetailMealPlanByWeek(mealPlanId, weekId).finally(() => {
        setLoadingChangeWeek(false);
      });
    handleScrollOnTop();
  };

  const handleAddWeek = (weekIndex, type) => {
    addWeekInMealPlan &&
      addWeekInMealPlan(mealPlanId, { week_index: weekIndex }, type, () => {
        toast(`Week ${weekIndex + 1} added.`);
      });
  };

  const handleRemoveWeek = weekIndex => {
    const currentWeek = weekIndex + 1;

    if (get(detailMealPlan, 'number_of_weeks', 0) <= 1) {
      return toggleConfirmModal(
        true,
        <S.ConfirmModalCustom
          title="Error"
          content="Meal plan should have at least 1 week"
          hideCancelButton
          confirmButtonTitle="Close"
          confirmButtonClass="btn-purple"
          noBorder
          noIcon
          onConfirm={() => toggleConfirmModal(false)}
        />,
      );
    }

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <RemoveWeek
          confirmButtonTitle="Remove"
          title={`Remove “Week ${currentWeek}”?`}
          onConfirm={() => removeWeek(weekIndex)}
          noBorder
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          shouldCloseAfterConfirm={false}
        />,
      );
  };

  const removeWeek = weekIndex => {
    const currentWeek = weekIndex + 1;

    removeWeekInMealPlan &&
      removeWeekInMealPlan(mealPlanId, weekId, weekIndex, () => {
        const weeks = get(detailMealPlan, 'weeks', []);

        let weekId = '';

        if (get(weeks, 'length', 0) === weekIndex) {
          weekId = weeks[weekIndex - 1];
        } else {
          weekId = weeks[weekIndex];
        }

        if (weekId) {
          toggleConfirmModal(false);
          toast(`Week ${currentWeek} has been removed.`);
          history.push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}`);
          getDetailMealPlanByWeek && getDetailMealPlanByWeek(mealPlanId, weekId);
        }
      }).catch(() => {
        toggleConfirmModal(false);
      });
  };

  const handleBeforeDragStart = useCallback(result => {
    if (isObject(result)) {
      const { draggableId } = result || {};
      const mealItemDraggableById = document.querySelectorAll(`.meal-item-by-${draggableId}`);
      mealItemDraggableById.forEach(function (element) {
        element.style.border = 'none';
      });
    }
  }, []);

  const handleDragStart = event => {
    updateDetailMealPlanByWeek({ isDragging: true });

    // Handle show border item dragging
    const draggedDOM = getDraggedDom(event && event.draggableId);
    if (!draggedDOM) {
      return;
    }
    const sourceIndex = event && event.source && event.source.index;
    const clientX = getClientY(draggedDOM, [...draggedDOM.parentNode.children], sourceIndex) + 7.5;
    setPlaceholderProps({
      clientHeight: 122,
      clientWidth: 195,
      clientX,
      clientY: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop),
    });
  };

  const handleDragUpdate = useCallback(
    result => {
      if (isObject(result)) {
        const { combine, destination, source, draggableId } = result || {};
        const { draggableId: combineDraggableId, droppableId: combineDroppableId } = combine || {};
        const { droppableId: destinationDroppableId } = destination || {};
        const { droppableId: sourceDroppableId } = source || {};

        // Handle show label merge
        const mealItemLabelMerge = document.querySelectorAll('.meal-item-label-merge');
        mealItemLabelMerge.forEach(function (element) {
          element.classList.remove('show-label');
        });
        const mealItemLabelMergeDraggableById = document.querySelectorAll(
          `.meal-item-label-merge-${combineDraggableId}`,
        );
        if (isObject(combine)) {
          mealItemLabelMergeDraggableById.forEach(function (element) {
            element.classList.add('show-label');
          });
        }

        // Meal
        const mealItemWrapper = document.querySelectorAll('.meal-item-wrapper');
        mealItemWrapper.forEach(function (element) {
          element.style.border = '1px dashed #e1e1ea';
        });

        const mealItemDraggableById = document.querySelectorAll(`.meal-item-by-${draggableId}`);
        mealItemDraggableById.forEach(function (element) {
          element.style.border = 'none';
        });

        const mealItemById = document.querySelectorAll(`.meal-item-by-${combineDraggableId}`);
        mealItemById.forEach(function (element) {
          element.style.border = '1px dashed #5158CF';
        });

        // Day
        const dayIndexWrapper = document.querySelectorAll('.day-index-wrapper');
        dayIndexWrapper.forEach(function (element) {
          element.style.setProperty('--color', 'transparent');
        });

        if (isObject(combine)) {
          if (combineDroppableId !== sourceDroppableId) {
            const dayIndexById = document.querySelectorAll(`.day-index-by-${combineDroppableId}`);
            dayIndexById.forEach(function (element) {
              element.style.setProperty('--color', '#5158CF');
            });
          }
        } else {
          if (destinationDroppableId !== sourceDroppableId) {
            const dayIndexById = document.querySelectorAll(`.day-index-by-${destinationDroppableId}`);
            dayIndexById.forEach(function (element) {
              element.style.setProperty('--color', '#5158CF');
            });
          }
        }

        if (destinationDroppableId !== sourceDroppableId) {
          const mealEmptyListWrapper = document.querySelectorAll('.meal-empty-list-wrapper');
          const mealEmptyListByDayIndex = document.querySelectorAll(`.meal-empty-list-by-${destinationDroppableId}`);

          mealEmptyListWrapper.forEach(function (element) {
            element.style.borderColor = '#e1e1ea';
          });

          mealEmptyListByDayIndex.forEach(function (element) {
            element.style.borderColor = 'transparent';
          });
        }
      }

      // Handle show border item dragging
      if (!result.destination) {
        return;
      }
      const draggedDOM = getDraggedDom(result && result.draggableId);
      if (!draggedDOM) {
        return;
      }
      const destinationIndex = result && result.destination && result.destination.index;
      const sourceIndex = result && result.source && result.source.index;
      const childrenArray = [...draggedDOM.parentNode.children];
      const movedItem = childrenArray[sourceIndex];
      childrenArray.splice(sourceIndex, 1);
      const updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
      ];
      const clientX = getClientY(draggedDOM, updatedArray, destinationIndex) + 7.5;
      setPlaceholderProps({
        clientHeight: 122,
        clientWidth: 195,
        clientX,
        clientY: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop),
      });
    },
    [days],
  );

  const handleDragEnd = useCallback(
    result => {
      setPlaceholderProps({});

      if (isObject(result)) {
        let data = {};
        let resultDays = [];

        const { combine, destination, source, draggableId } = result || {};

        const { droppableId: combineDroppableId } = combine || {};
        const { droppableId: destinationDroppableId } = destination || {};
        const { droppableId: sourceDroppableId } = source || {};

        // Handle show label merge: Reset tooltip
        const mealItemLabelMerge = document.querySelectorAll('.meal-item-label-merge');
        mealItemLabelMerge.forEach(function (element) {
          element.classList.remove('show-label');
        });

        const mealItemById = document.querySelectorAll(`.meal-item-wrapper`);
        mealItemById.forEach(function (element) {
          element.style.border = '1px dashed #e1e1ea';
        });

        const mealItemDraggableById = document.querySelectorAll(`.meal-item-by-${draggableId}`);
        mealItemDraggableById.forEach(function (element) {
          element.style.border = '1px dashed #e1e1ea';
        });

        const dayIndexById = document.querySelectorAll(`.day-index-wrapper`);
        dayIndexById.forEach(function (element) {
          element.style.setProperty('--color', 'transparent');
        });

        const mealEmptyListWrapper = document.querySelectorAll('.meal-empty-list-wrapper');

        mealEmptyListWrapper.forEach(function (element) {
          element.style.borderColor = '#e1e1ea';
        });

        if (isObject(combine)) {
          // Combine
          if (sourceDroppableId === combineDroppableId) {
            // Same day
            resultDays = combineSameDay(days, result);
            data = getDataSameDay(resultDays, result);
          } else {
            // Different day
            resultDays = combineDifferentDay(days, result);
            data = getDataDifferentDay(resultDays, result, true);
          }
        } else {
          // Not combine
          if (sourceDroppableId === destinationDroppableId) {
            // Same day
            resultDays = notCombineSameDay(days, result);
            data = getDataSameDay(resultDays, result);
          } else {
            // Different day
            resultDays = notCombineDifferentDay(days, result);
            data = getDataDifferentDay(resultDays, result);
          }
        }

        const checkEmpty = checkRearrangeEmpty(get(data, 'days', []));
        if (!checkEmpty) {
          updateDataDaysByWeek(mealPlanId, weekId, data);
          updateDetailMealPlanByWeek({ days: resultDays });
        }
      }
      updateDetailMealPlanByWeek({ isDragging: false });
    },
    [days],
  );

  const handleChangeModeRearrange = () => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollTop = 0;
    }
  };

  if (isEmpty(detailMealPlan) || isEmpty(detailMealPlanByWeek))
    return (
      <S.MealPlanDetailWrapper>
        <S.LoadingContainer>
          <LoadingIndicator className="loading-indicator" />
        </S.LoadingContainer>
      </S.MealPlanDetailWrapper>
    );

  return (
    <S.MealPlanDetailWrapper>
      <Menu
        weekIndex={indexCurrentWeek}
        numberOfWeeks={numberOfWeeks}
        handleChangeWeek={handleChangeWeek}
        handleAddWeek={handleAddWeek}
        onRemoveWeek={handleRemoveWeek}
        mealPlanId={mealPlanId}
        isSyncing={isSyncing}
        history={history}
        disabledAction={disabledAction}
        onChangeModeRearrange={handleChangeModeRearrange}
      />
      {loadingChangeWeek ? (
        <S.LoadingWrapper />
      ) : (
        <S.Content ref={wrapperRef}>
          <S.WeekNutrition rearrangeMode={rearrangeMode}>
            <S.AddNoteWrapper>
              <AddNote
                isPublish={isPublish}
                content={`Leave a note for Week ${indexCurrentWeek + 1}`}
                hasNote={get(detailMealPlanByWeek, 'note', '')}
                onClick={handleOpenNote}
                isEditMealPlan={isEditMealPlan}
              />
            </S.AddNoteWrapper>
            <S.Divider />
            <NutritionOverview list={listNutrition} />
          </S.WeekNutrition>
          <S.WeekWrapper>
            <DragDropContext
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragUpdate={handleDragUpdate}
              onBeforeDragStart={handleBeforeDragStart}
            >
              {resultListDayOfWeek.map(item => {
                const { dayOfWeek, data, dayIndex } = item;
                return (
                  <S.DayWrapper
                    key={dayIndex}
                    rearrangeMode={rearrangeMode}
                    className={`day-index-wrapper day-index-by-${dayIndex}`}
                    id={`wrapper-day-index-${dayIndex}`}
                  >
                    <DayMeal
                      dayOfWeek={dayOfWeek}
                      dayIndex={dayIndex}
                      data={data}
                      onAddMeal={type => handleAddMeal(type, dayIndex)}
                      weekIndex={indexCurrentWeek}
                      isPublish={isPublish}
                      isEditMealPlan={isEditMealPlan}
                      rearrangeMode={rearrangeMode}
                      isDragging={isDragging}
                      placeholderProps={placeholderProps}
                      toggleModal={toggleModal}
                      disabledAction={disabledAction}
                      mealPlanId={mealPlanId}
                    />
                  </S.DayWrapper>
                );
              })}
            </DragDropContext>
          </S.WeekWrapper>
        </S.Content>
      )}
    </S.MealPlanDetailWrapper>
  );
};

export default MealPlanDetail;
