import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
  color: #202353;
`;

export const ContentRightContainer = styled.div`
  flex: 1;
  margin: 0 auto;

  @media screen and (max-width: 1023px) {
    max-width: 459px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    max-width: 568px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    max-width: 615px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 742px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const GenerateAI = styled.div`
  position: relative;
  .wrapper-button {
    border-radius: 5px 0 5px 5px;
    background: linear-gradient(180deg, rgba(169, 87, 255, 0.5) 0%, rgba(90, 83, 255, 0.5) 100%);
    height: 30px;
    padding: 1px;
    overflow: hidden;
    opacity: 0.5;
  }
  button {
    ${baseText}
    line-height: 18px;
    font-size: 12px;
    font-weight: 600;
    color: #202353;
    padding: 0 14px 0 12px;
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    height: 100%;
    border-radius: 4px 0 4px 4px;
    cursor: not-allowed;
  }
  .generate-ai-icon {
    margin-right: 8px;
  }
  .upcoming-tab-icon {
    position: absolute;
    top: -17px;
    right: -4px;
  }
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HeaderRightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GetMealPlanAddOn = styled.button`
  ${baseText}
  line-height: 20px;
  color: #a36b06;
  font-weight: 600;
  font-size: 13px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ffbe49;
  display: flex;
  align-items: center;
  padding: 9px;
  gap: 7px;
  background: #fffaf0;
  margin-left: 16px;
  position: relative;
  cursor: pointer;
  .icon-info {
    width: 16px;
    height: 16px;
    path {
      fill: #ffbe49;
    }
  }
  .features__tooltipUpgradePath.custom-upgrade-path-popup {
    display: none;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    padding-top: 17px;
    padding-bottom: 0;
    .tooltip_box {
      width: 281px;
      -webkit-filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      -webkit-filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      box-shadow: unset;
      border: unset;
    }
    .tooltip_box:before {
      content: '';
      cursor: default;
      background-color: inherit;
      width: 16px;
      height: 16px;
      border: unset;
      top: -4px;
      bottom: unset;
      left: 50%;
      -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
    }
    .tooltip__description {
      font-family: 'Open Sans';
      margin-top: 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      width: 100%;
    }
    .tooltip__upgradePathBtn {
      border-radius: 5px;
      padding: 6px 30px;
      span {
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  :hover {
    .features__tooltipUpgradePath.custom-upgrade-path-popup {
      display: block;
    }
  }
`;

export const TrialAddOnWrapper = styled.div`
  margin-left: 16px;
`;
