import React, { useState } from 'react';
import { Button } from 'shared/FormControl';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import AutoTextArea from './AutoTextArea';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const EditModal = ({ section, onSave, onClose }) => {
  const [text, setText] = useState(section.description);

  const handleOnChange = value => {
    setText(value);
  };

  const handleSave = () => {
    onSave && onSave(text);
  };

  return (
    <S.EditDescModal
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <div className="header__title">Edit section description</div>
      </Modal.Header>
      <Modal.Content>
        <S.DescWrapper>
          <S.SectionName>{section.section_name}</S.SectionName>
          <S.DescContent>
            <AutoTextArea value={section.description} onChange={handleOnChange} />
          </S.DescContent>
          <S.SubPlaceholder />
        </S.DescWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button className="actions__cancel" onClick={onClose}>
          Cancel
        </Button>
        <Button className="actions__createBtn" purple onClick={handleSave}>
          Save
        </Button>
      </Modal.Actions>
    </S.EditDescModal>
  );
};

export default EditModal;
