import React, { useState, useCallback, useRef, useMemo } from 'react';
import Select, { components } from 'react-select';
import get from 'lodash/get';
import find from 'lodash/find';
import { Modal } from 'semantic-ui-react';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down.svg';

import * as S from '../styles';
import { Button } from 'shared/FormControl';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';
import {
  NO_OWNER_SHARED_OPTION,
  ASSETS_SHARE_SETTING_TYPE,
  SHARE_OPTIONS,
  getOwnerOptions,
  generateShareStatus,
} from '../constants';
import { isTeamAdmin } from 'utils/commonFunction';

const ShareSettingModal = props => {
  const { user = {}, owner = {}, disabled = false, usePortal = false, closeModal, onSave } = props;
  const selectOwnerRef = useRef();
  const selectStatusRef = useRef();
  const [ownership, setOwnership] = useState(
    props.shareStatus === TEAM_SHARE_NOOWNER ? NO_OWNER_SHARED_OPTION.value : owner._id,
  );
  const [shareStatus, setShareStatus] = useState(props.shareStatus || TEAM_SHARE_PRIVATE);
  const [loading, setLoading] = useState(false);
  const [menuOwnerPlacement, setMenuOwnerPlacement] = useState(() => get(props, 'menuPlacement', 'auto'), [props]);
  const [menuStatusPlacement, setMenuStatusPlacement] = useState(() => get(props, 'menuPlacement', 'auto'), [props]);

  const menuOwnerPortalTarget = useMemo(() => {
    if (usePortal) return document.body;
    return false;
  }, [usePortal]);

  const menuStatusPortalTarget = useMemo(() => {
    if (usePortal) return document.body;
    return false;
  }, [usePortal]);

  const [defaultOwnerValue, defaultStatusValue, ownershipOptions] = useMemo(() => {
    let ownerValue;
    if (shareStatus === TEAM_SHARE_NOOWNER || owner === null || ownership === NO_OWNER_SHARED_OPTION.value) {
      ownerValue = NO_OWNER_SHARED_OPTION;
    } else {
      const ownershipData = find(user.team_member, item => item && item._id === ownership);
      if (ownershipData) {
        let fullName =
          ownershipData.full_name || `${ownershipData.first_name || ''} ${ownershipData.last_name || ''}`.trim();
        if (ownershipData._id === user._id) {
          fullName += ' (You)';
        }
        ownerValue = {
          text: fullName,
          value: ownershipData._id,
        };
      }
    }

    let statusValue = SHARE_OPTIONS[shareStatus];
    if (shareStatus === TEAM_SHARE_NOOWNER) {
      statusValue = SHARE_OPTIONS[TEAM_SHARE_SHARED];
    }

    const isOwnerOrAdmin = isTeamAdmin(user);
    const members = isOwnerOrAdmin ? user.team_member || [] : [user];
    const ownerOptions = getOwnerOptions({
      members,
      hasYou: true,
      userId: user._id,
    });

    return [ownerValue, statusValue, ownerOptions];
  }, [shareStatus]);

  const checkPlacementOwner = useCallback(() => {
    if (selectOwnerRef.current && typeof selectOwnerRef.current.getBoundingClientRect === 'function') {
      const rect = selectOwnerRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 100) {
        setMenuOwnerPlacement('top');
      } else {
        menuOwnerPlacement !== get(props, 'menuPlacement', 'auto') &&
          setMenuOwnerPlacement(get(props, 'menuPlacement', 'auto'));
      }
    }
  }, [menuOwnerPlacement, selectOwnerRef]);

  const handleChangeOwnership = (option = {}) => {
    const ownershipValue = option.value || owner._id;
    setOwnership(ownershipValue);
    setShareStatus(it => generateShareStatus({ ownership: ownershipValue, shareStatus: it }));
  };

  const checkPlacementStatus = useCallback(() => {
    if (selectStatusRef.current && typeof selectStatusRef.current.getBoundingClientRect === 'function') {
      const rect = selectStatusRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 100) {
        setMenuStatusPlacement('top');
      } else {
        menuStatusPlacement !== get(props, 'menuPlacement', 'auto') &&
          setMenuStatusPlacement(get(props, 'menuPlacement', 'auto'));
      }
    }
  }, [menuStatusPlacement, selectStatusRef]);

  const handleChangeShareStatus = (option = {}) => {
    setShareStatus(option.value || TEAM_SHARE_PRIVATE);
  };

  const handleCancel = e => {
    e.preventDefault();
    typeof closeModal === 'function' && closeModal();
  };

  const handleSave = e => {
    e.preventDefault();
    setLoading(true);
    typeof onSave === 'function' && onSave({ ownership, shareStatus });
  };

  return (
    <S.ShareSettingModalWrapper open className="share-setting-modal">
      <Modal.Header>
        <S.ModalTitle>Sharing settings</S.ModalTitle>
      </Modal.Header>
      <Modal.Content>
        <S.ColumnWrapper type={ASSETS_SHARE_SETTING_TYPE.SHARE_SETTING_MODAL}>
          <S.FieldWrapper ref={selectOwnerRef}>
            <S.FieldLabel>Owner</S.FieldLabel>
            <Select
              blurInputOnSelect
              isSearchable={false}
              isMulti={false}
              name="owner"
              classNamePrefix="owner"
              className="select-owner"
              defaultValue={defaultOwnerValue}
              options={ownershipOptions}
              isDisabled={disabled || loading}
              getOptionLabel={option => option.text}
              getOptionValue={option => option.value}
              menuPlacement={menuOwnerPlacement}
              menuPortalTarget={menuOwnerPortalTarget}
              onChange={handleChangeOwnership}
              onMenuOpen={checkPlacementOwner}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: DefaultDropdownIndicator,
              }}
              styles={S.customSelectOwnerStyles}
            />
          </S.FieldWrapper>
          {shareStatus !== TEAM_SHARE_NOOWNER && (
            <S.FieldWrapper ref={selectStatusRef}>
              <S.FieldLabel>Share with ORG?</S.FieldLabel>
              <Select
                blurInputOnSelect
                isSearchable={false}
                isMulti={false}
                name="share"
                classNamePrefix="share"
                className="select-share"
                defaultValue={defaultStatusValue}
                options={Object.values(SHARE_OPTIONS)}
                isDisabled={disabled || loading || ownership === NO_OWNER_SHARED_OPTION.value}
                getOptionLabel={option => option.text}
                getOptionValue={option => option.value}
                menuPlacement={menuStatusPlacement}
                menuPortalTarget={menuStatusPortalTarget}
                onChange={handleChangeShareStatus}
                onMenuOpen={checkPlacementStatus}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DefaultDropdownIndicator,
                }}
                styles={S.customSelectStatusStyles}
              />
            </S.FieldWrapper>
          )}
        </S.ColumnWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button className="cancel-button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button className="save-button" purple onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </Modal.Actions>
    </S.ShareSettingModalWrapper>
  );
};

export default ShareSettingModal;

const DefaultDropdownIndicator = args => (
  <components.DropdownIndicator {...args}>
    <ArrowDownIcon
      style={{
        color: '#000000',
        width: 14,
        height: 14,
        marginRight: 7,
      }}
    />
  </components.DropdownIndicator>
);
