import _ from 'lodash';
import { Types } from 'actions/submitStatus';
import { DEFAULT_SUBMIT_STATUS } from 'constants/commonData';

// -1 : not submitted yet
// 0 : submitting,
// 1: submited

const FORM_NAME = {
  EXERCISE_DETAIL: 'EXERCISE_DETAIL',
  WORKOUT_DETAIL: 'WORKOUT_DETAIL',
  EXECISE_DETAIL_MODAL: 'EXECISE_DETAIL_MODAL',
  PROGRAM_DETAIL: 'PROGRAM_DETAIL',
  INVITE_CLIENT: 'INVITE_CLIENT',
  TRAINER_UNITS: 'TRAINER_UNITS',
  SELECT_PROGRAM: 'SELECT_PROPGRAM',
}

const INITIAL_STATE = {};
_.forEach(FORM_NAME, (value, key) => {
  INITIAL_STATE[key] = DEFAULT_SUBMIT_STATUS
})

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case Types.FORM_SUBMITTING:
      return FORM_NAME[action.key]
        ? Object.assign({}, state, { [action.key]: { success: true, status: 0} })
        : state;
    
    case Types.FORM_SUBMITED:
        return FORM_NAME[action.key]
        ? Object.assign({}, state, { [action.key]: { success: !!action.success, status: 1} })
        : state;

    case Types.RESET_SUBMIT_STATUS:
      return FORM_NAME[action.key]
        ? Object.assign({}, state, { [action.key]: DEFAULT_SUBMIT_STATUS })
        : state;
    
    default:
      return state
  }
}