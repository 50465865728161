import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCustomBranding } from 'redux/custom-branding/actions';
import SettingMobileApp from './component';
import { get } from 'lodash';

const defaultBackground = {
  _id: '1',
  src: 'https://everfit-web-asset.s3-us-west-1.amazonaws.com/images/workout_1.png',
  name: 'Purple (Default)',
  default: true,
};

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: {
      customBranding: { loading, selectedWorkoutBackground, selectedSplashLogo, selectedAppIconKey, originalTheme },
    },
  } = state;

  return {
    loading,
    cloudfrontList,
    selectedWorkoutBackground,
    selectedSplashLogo,
    selectedAppIconKey,
    originalTheme,
    tier: state.rootReducer.pricing.getIn(['teamData', 'tier']),
    defaultBackground: get(state, 'rootReducer.customBranding.workoutBackgrounds[0]', defaultBackground),
  };
};

const mapDispatch = dispatch => ({
  updateCustomBranding: bindActionCreators(updateCustomBranding, dispatch),
});

export default connect(mapState, mapDispatch)(SettingMobileApp);
