import styled from 'styled-components';

export const SaveButton = styled.button`
  outline: none !important;
  box-shadow: none;
  border: 1px solid #5c5bbd;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #5c5bbd;
  padding: 9px 25px 8px;
  background-color: #ffffff;

  :hover {
    color: #fff;
    background-color: #5c5bbd;
    cursor: pointer;
  }
`;

export const UnitWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ToggleWrapper = styled.div`
  margin-top: 30px;
`;
