import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { listTags } from './constants';
import { ReactComponent as AddTagIcon } from 'assets/icons/add_tag_icon.svg';
import { ReactComponent as CloseTagIcon } from 'assets/icons/close_tag_icon.svg';

import * as S from './style';

const ProgressPhotoTag = props => {
  const { onChooseTag, id, transparent, value = '' } = props;

  const [isClickOutside, setIsClickOutside] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tag, setTag] = useState(value);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsClickOutside(true);
      } else {
        setIsClickOutside(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setTag(value);
  }, [value]);

  useEffect(() => {
    if (isClickOutside) {
      setIsOpen(false);
    }
  }, [isClickOutside]);

  const handleOpenPopup = value => {
    setIsOpen(value);
  };

  const handleChooseTag = value => {
    setTag(value);
    onChooseTag && onChooseTag({ value, id });
    setIsOpen(false);
  };

  const handleRemoveTag = () => {
    setTag(null);
    setIsOpen(false);
    onChooseTag && onChooseTag({ value: null, id });
  };

  return (
    <>
      {isOpen && (
        <S.ListTag ref={wrapperRef} transparent={transparent}>
          {listTags.map(item => (
            <S.ItemTag key={item.id} onClick={() => handleChooseTag(item.value)}>
              <S.TagTitle transparent={transparent}>{item.title}</S.TagTitle>
            </S.ItemTag>
          ))}
        </S.ListTag>
      )}
      {!isOpen && (
        <>
          {tag ? (
            <S.ButtonEditTag transparent={tag.length >= 1 && transparent}>
              <S.TagName onClick={() => handleOpenPopup(true)} transparent={tag.length >= 1 && transparent}>
                {tag}
              </S.TagName>
              <S.CloseIcon onClick={handleRemoveTag} transparent={tag.length > 1 && transparent}>
                <CloseTagIcon />
              </S.CloseIcon>
            </S.ButtonEditTag>
          ) : (
            <S.ButtonAddTag onClick={() => handleOpenPopup(true)} transparent={transparent}>
              <S.TagIcon transparent={transparent}>
                <AddTagIcon />
              </S.TagIcon>
              <S.TagLabel transparent={transparent}>Add tag</S.TagLabel>
            </S.ButtonAddTag>
          )}
        </>
      )}
    </>
  );
};

ProgressPhotoTag.propTypes = {
  onChooseTag: PropTypes.func,
  id: PropTypes.string,
  transparent: PropTypes.bool,
  value: PropTypes.string,
};

export default ProgressPhotoTag;
