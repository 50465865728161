import React from 'react';
import { get } from 'lodash';

import ResourceMobilePreviewItem from './ResourceMobilePreviewItem';
import { MAX_ITEM } from './constants';

import * as S from './style';

function MobilePreviewCollection(props) {
  const { section } = props;

  return (
    <S.SectionMobilePreview>
      <S.PreviewTitle>
        <S.ViewName>{section.name}</S.ViewName>
        <S.ViewMore>See more</S.ViewMore>
      </S.PreviewTitle>
      {get(section, 'workouts', [])
        .filter((item, index) => index < MAX_ITEM)
        .map((resource, index) => (
          <>{index < 5 ? <ResourceMobilePreviewItem key={resource._id} resource={resource} /> : null}</>
        ))}
    </S.SectionMobilePreview>
  );
}

export default MobilePreviewCollection;
