import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { DateTime as DateTimeUtil } from 'luxon';

import Datetime from 'shared/DatePicker';
import TimeInput, { getTimeFromInput } from 'components/BodyMetricProgressNew/AddResultPopup/TimeInput';
import { DateTimeInputWrapper } from './DateTimeInputStyle';
import { ErrorMessage } from 'shared/FormControl';

const TIME_FORMAT = 'hh : mm';
export const DATETIME_ERROR_KEYS = {
  START_TIME_UNFILLED: 'START_TIME_UNFILLED',
  END_TIME_UNFILLED: 'END_TIME_UNFILLED',
  START_TIME_GREATER_END_TIME: 'START_TIME_GREATER_END_TIME',
  OVER_24_HOURS: 'OVER_24_HOURS',
  OVER_CURRENT_TIME: 'OVER_CURRENT_TIME',
  INVALID_TIME_FORMAT: 'INVALID_TIME_FORMAT',
};
const SIZES = {
  HEIGHT_CALENDAR: 320,
  TOP_BUFFER: 39,
  LEFT_PADDING: 118,
  BOTTOM_BUFFER: 13,
};

export default function DateTimeInput({
  inputId = 'datetime',
  date = moment(),
  isAM = false,
  errorMsg = null,
  onError = () => {},
  onBlur = () => {},
  onChangeValue,
}) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isFocusedTime, setIsFocusedTime] = useState(false);
  const [data, setData] = useState({
    date: date,
    time: '',
    isAM: isAM,
  });

  const onChangeDate = newDate => {
    const newData = { ...data, date: newDate };
    setData(newData);
    handleBlur(newData);
    onToggleCalendar();
  };

  const onToggleCalendar = () => {
    setShowDatePicker(s => {
      if (!s) {
        const [key, id] = inputId.split('_');
        const elmKey = key === 'start' ? 'end' : 'start';
        const elm = document.querySelector(`#${elmKey}_${id} .date-input-container.open-picker`);
        if (elm) {
          document.querySelector(`#${elmKey}_${id} .date-input-field`).click();
        }
      }
      return !s;
    });
  };

  const onChangeTime = newTime => {
    const { isAMTime, ...rest } = newTime;
    const isChangeAM = isAMTime !== undefined;
    const newData = { ...data, ...rest, isAM: isChangeAM ? isAMTime : data.isAM };
    setData(newData);
    if (isChangeAM || rest.time.length >= 6) {
      handleBlur(newData);
    }
  };

  const onFocusTime = () => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
    setIsFocusedTime(true);
  };

  const handleBlur = newData => {
    let msg = null;
    const finalData = newData || data;

    if (!isFocusedTime) return;

    if (finalData.time) {
      const { hour, minute } = getTimeFromInput(finalData.time || '', true);
      const timeStr = `${hour} : ${minute}`;
      const newTime = DateTimeUtil.fromFormat(timeStr, TIME_FORMAT);

      if (newTime.invalid) {
        msg = DATETIME_ERROR_KEYS.END_TIME_UNFILLED;
      }

      if (!finalData.time.includes(':')) {
        msg = DATETIME_ERROR_KEYS.INVALID_TIME_FORMAT;
      }
    } else {
      msg = DATETIME_ERROR_KEYS.END_TIME_UNFILLED;
    }

    let datetimeMM = null;

    if (!msg) {
      const { hour, minute } = getTimeFromInput(finalData.time, true);
      const timeFormat = `h:mm ${finalData.isAM ? 'AM' : 'PM'}`;
      const finalHour = +hour < 12 && !finalData.isAM ? +hour + 12 : +hour;
      const dateString =
        finalData.date.format('MMM DD, YYYY') + ` ${finalHour}:${minute} ${finalData.isAM ? 'AM' : 'PM'}`;

      datetimeMM = moment(dateString, `MMM DD, YYYY ${timeFormat}`);
    }

    onChangeValue(inputId, datetimeMM);
    msg ? onError(inputId, msg) : onBlur(inputId);
  };

  useEffect(() => {
    setTimeout(() => {
      const wrapper = document.querySelector(`#${inputId} .inputs-wrapper`);
      const rdtPicker = document.querySelector(`#${inputId} .rdtPicker`);

      if (wrapper && rdtPicker) {
        const { left, top } = wrapper.getBoundingClientRect();
        let finalTop = top + SIZES.TOP_BUFFER;
        if (finalTop + SIZES.HEIGHT_CALENDAR > window.innerHeight) {
          finalTop = top - SIZES.HEIGHT_CALENDAR - SIZES.BOTTOM_BUFFER;
          if (finalTop < 0) {
            finalTop = 0;
          }
        }
        rdtPicker.style.top = finalTop + 'px';
        rdtPicker.style.left = left - SIZES.LEFT_PADDING + 'px';
      }
    }, 1000);
  }, [inputId, errorMsg]);

  const renderDatePicker = () => {
    return (
      <div className={classNames('date-input-container', { 'open-picker': showDatePicker })}>
        <Datetime
          value={data.date}
          isShowArrowBtn={false}
          renderCustomInput={<div className="date-input-field">{data.date.format('MMM D, YYYY')}</div>}
          maxDate={new Date()}
          timeFormat={false}
          onChange={onChangeDate}
          isOpen={showDatePicker}
          onToggleCalendar={onToggleCalendar}
        />
      </div>
    );
  };

  return (
    <DateTimeInputWrapper
      id={inputId}
      className={classNames({ 'input-error': !!errorMsg })}
      onBlur={() => handleBlur()}
    >
      <div className={classNames('inputs-wrapper', { active: showDatePicker })}>
        {renderDatePicker()}
        <TimeInput
          className="time-input-container"
          value={data.time}
          isAM={data.isAM}
          onChange={onChangeTime}
          onFocus={onFocusTime}
        />
      </div>
      {!!errorMsg && <ErrorMessage className="error-msg">{errorMsg}</ErrorMessage>}
    </DateTimeInputWrapper>
  );
}
