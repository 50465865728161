import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

import MobileFrame from 'assets/images/mobile-frame.png';
import SubMobileFrame from 'assets/images/loading-mobile-frame.png';

export const Wrapper = styled.div`
  background: #fafbfc;
  border: 1px solid #ebedf4;
  border-radius: 8px;
  padding: 16px 10px;
  margin-bottom: 20px;

  .collectionSection__heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    &.collectionSection__new {
      margin-bottom: 0;
    }
    i.icon {
      color: #c4c4c4;
      font-size: 1.25em;
      padding: 3px 10px;
    }
  }

  .collectionSection__list {
    position: relative;
  }

  .collectionSection__addResourseBtn {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #5158cf;
    margin-top: 20px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      color: #c4c4c4;
    }
  }
  .ui.accordion .title:not(.ui) {
    padding: 0;
  }
  .ui.accordion:not(.styled) .title ~ .content:not(.ui):last-child {
    padding: 0.5rem 5px;
  }
`;

export const AddResource = styled.div`
  background: #ffffff;
  border: 1px dashed #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #98a0a8;
  text-align: center;
  cursor: pointer;
  padding: 70px 0px 20px 0px;
  background-image: url(${CDN_URL}/images/add_resource_icon.svg);
  background-repeat: no-repeat;
  background-position: center top 20px;

  :hover {
    color: #5c5bbd;
    border: 1px dashed #5158cf;
    box-sizing: border-box;
    border-radius: 5px;
    background-image: url(${CDN_URL}/images/add_resource_hover_icon.svg);
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: #98a0a8 !important;
      border: 1px dashed #dadfea !important;
      background-image: url(${CDN_URL}/images/add_resource_icon.svg) !important;
    `}
`;

export const DraggablePlacehoder = styled.div`
  position: absolute;
  background: rgb(250, 251, 252);
  border: 1px dashed rgb(81, 88, 207);
  box-sizing: border-box;
  border-radius: 5px;
`;

export const SectionActions = styled.div`
  display: flex;
  align-items: center;
`;
export const SectionInfoWrapper = styled.div`
  display: flex;
  &.newSectionInput {
    width: 100%;
  }
`;

// LayoutSetting
export const SettingsWrapper = styled.div`
  display: flex;
`;

export const MoreActionsWrapper = styled.div``;

export const EditDescModal = styled(Modal)`
  &.ui.modal {
    width: 334px;
    border-radius: 8px;
    .header {
      border-radius: 8px;
      padding-top: 20px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 0px !important;
      color: #202353 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;

      .header__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #202353;

        &--description {
          margin-top: 5px;
          font-weight: normal;
          font-size: 13px;
          line-height: 150%;
          color: #323c47;
        }
      }
    }
    > .content {
      padding-bottom: 0;
      background: transparent;
    }
    > .actions {
      border-top: 0;
      background-color: #fff;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);

      button {
        width: 109px;
        margin-left: 10px;
        height: 34px;
        font-weight: 600;
        font-size: 13px;
      }
    }
    > :last-child {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
`;

export const DescWrapper = styled.div`
  width: 273.48px;
  height: 305px;
  background-image: url(${MobileFrame});
  padding: 50px 24px;
  margin: auto;
  position: relative;
  overflow: hidden;
`;

export const SectionName = styled.h4`
  font-weight: bold;
  font-size: 19.6981px;
  line-height: 24px;
  letter-spacing: -0.351752px;
  color: #1e0a3c;
`;

export const PlaceholderDesc = styled.span`
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 13px;
  line-height: 150%;
  color: #a3a8bc;
  z-index: 0;
  pointer-events: none;
`;

export const SubPlaceholder = styled.div`
  width: 222.58px;
  height: 119.6px;
  position: absolute;
  bottom: -42px;
  left: 26px;
  background-image: url(${SubMobileFrame});
`;

export const DescContent = styled.div`
  textarea {
    min-height: 32px;
    width: 100%;
    max-height: 120px;
    border: 1px solid transparent;
    padding: 5px;
    font-size: 13px;
    line-height: 18px;
    border-radius: 3px;
    color: #202353;
    background-color: #fafbfc;
    resize: none;

    &:focus {
      ::-webkit-scrollbar {
        width: 0px !important;
      }
    }
    &:focus {
      border-color: #5c5bbd;
      outline: 0;
    }
  }
`;
