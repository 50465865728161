import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onDragExerciseLeftPanelEnd } from 'redux/workout-builder/actions';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  .droppable-container {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 15px;
    margin-top: -2px;
    max-height: 0px;
    overflow: hidden;

    span {
      font-weight: normal;
      font-size: 13px;
      line-height: 120%;
      color: #5158cf;
      margin-left: 10px;
    }
  }

  &.exercise-droppable__hover {
    .droppable-container {
      max-height: 200px;
      border: 1px dashed #5158cf;
    }
  }
`;

export const DroppableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 11;
`;

function ExcerciseEmptyDroppable(props) {
  const [hover, setHover] = useState(false);

  const onDragEnter = e => {
    setHover(true);
  };

  const onDrop = e => {
    e.preventDefault();
    props.onDragExerciseLeftPanelEnd({
      sectionId: props.sectionId,
      exerciseIndex: 0,
      sectionIndex: props.sectionIndex,
    });
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDragleave = e => {
    e.preventDefault();
    setHover(false);
  };

  return (
    <Wrapper
      bottom={props.bottom}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragleave}
      onDragEnter={onDragEnter}
      className={classnames({ 'exercise-droppable__hover': hover })}
    >
      <div className="droppable-container">
        <img src={`${CDN_URL}/images/section_droppable_icon.svg`} alt="section_droppble_icon" />
        <span>Drop your exercise here</span>
      </div>
      <DroppableContainer />
    </Wrapper>
  );
}

const mapDispatch = dispatch => ({
  onDragExerciseLeftPanelEnd: bindActionCreators(onDragExerciseLeftPanelEnd, dispatch),
});

export default connect(null, mapDispatch)(ExcerciseEmptyDroppable);
