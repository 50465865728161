import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import * as S from '../style';
import { formatDateSafari } from 'utils/commonFunction';

function ButtonPasteWeek(props) {
  const { weekIndex, permission, selectedWeek, startDate, handlePasteMultipleWorkoutToWeek } = props;
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V2 || !get(permission, 'copy_week')) return <></>;
  if (isEmpty(selectedWeek)) return <></>;
  const debouncePasteWeek = useCallback(debounce(handlePasteMultipleWorkoutToWeek, 100), []);
  function onPasteWeek(event) {
    const multiPaste = event.shiftKey;
    const day = DateTime.fromISO(new Date(formatDateSafari(startDate)).toISOString());
    const startDatePaste = day.plus({ days: 7 * weekIndex }).toFormat('MM-dd-yyyy');
    debouncePasteWeek({ startDatePaste, multiPaste });
  }
  return (
    <S.PasteWeek>
      <S.PasteButton onClick={onPasteWeek}>Paste Week</S.PasteButton>
    </S.PasteWeek>
  );
}

export default ButtonPasteWeek;
