import styled from 'styled-components';

export const ResetButtonWrapper = styled.div`
  user-select: none;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  line-height: 14px;
  padding: 0 6px;
  cursor: pointer;

  .bold {
    font-weight: 600;
  }
  &:hover {
    background-color: #f4f4fc;
    transition: all 0.3s;
  }

  background-color: transparent;
  color: #595959;
  path {
    fill: #595959;
  }
`;
