import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import { Modal, Image, Button, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { FormGroup, Button as ActionButton } from 'shared/FormControl';
import _ from 'lodash';
import ConfirmModal from 'shared/ConfirmModal';
import { Container, Message } from './style';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { addAutoflow, updateAutoflow, duplicateAutoflow } from 'redux/autoflow/actions';
import { CDN_URL, SHARE_OPTIONS, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { isTeamAdmin, getUserShortName } from 'utils/commonFunction';
import './style.scss';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { checkAssetAssignedToProduct } from '../../../utils/commonFunction';
import { ASSET_TYPE } from '../../Product/constants';

function Form(props) {
  const { action, originData, user, onboarding_flow } = props;
  const [name, setName] = useState(originData ? originData.name : '');
  const [type] = useState(_.get(originData, 'type') || 'exact_date');
  const [share, setShare] = useState(originData ? originData.share : TEAM_SHARE_PRIVATE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const isEdit = !!_.get(originData, '_id');
  const author = !isEdit || action === 'duplicate' ? user : _.get(originData, 'author', {});
  const authorName = author.first_name + ' ' + author.last_name;
  const header = action === 'duplicate' ? 'Duplicate Autoflow' : isEdit ? 'Edit Autoflow' : 'Create New Autoflow';

  const onClose = () => {
    const { toggleModal, toggleConfirmModal } = props;
    let isChanged = false;

    if (isEdit) {
      if (name !== originData.name || share !== originData.share) {
        isChanged = true;
      }
    } else {
      if (name || share !== TEAM_SHARE_PRIVATE) {
        isChanged = true;
      }
    }

    if (isChanged) {
      return toggleConfirmModal(true, <ConfirmModal onConfirm={() => toggleModal(false)} />);
    } else {
      toggleModal(false);
    }
  };

  const onSubmit = event => {
    event && event.preventDefault();

    if (isSubmitting) {
      return false;
    }

    if (!name || !name.trim()) {
      return setError(true);
    }

    const data = { name: name.trim(), type, share, author: author._id };

    setIsSubmitting(true);
    const promises = [];

    if (isEdit) {
      if (action === 'duplicate') {
        props.duplicateAutoflow(originData._id, data, originData.isSettingTurnedOff);

        return props.toggleModal(false);
      } else {
        promises.push(Promise.resolve(props.updateAutoflow(originData._id, data)));
      }
    } else {
      promises.push(Promise.resolve(props.addAutoflow(data)));
    }

    Promise.all(promises)
      .then(() => {
        setIsSubmitting(false);
        props.toggleModal(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const checkAssetAssignedToOnboardingFlow = async () => {
    if (originData.share === TEAM_SHARE_PRIVATE) return false;
    const { toggleConfirmModal } = props;
    const isAssigned = onboarding_flow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.AUTOFLOW, originData._id, true)
      : false;

    if (isAssigned) {
      toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isOnboardingFlow
          title="Autoflow is assigned to an Onboarding Flow"
          content="This Autoflow is in at least 1 onboarding flow. If you change its permission to “Private”, it will be inactivated in all onboarding flows as well."
          assetType={ASSET_TYPE.AUTOFLOW}
          onConfirm={() => {
            toggleConfirmModal(false);
            onSubmit();
          }}
        />,
      );
    }
    return isAssigned;
  };

  return (
    <Modal open={props.isModalOpen} closeOnDimmerClick={false} className="autoflow-modal" onClose={() => onClose()}>
      <Modal.Header>
        <Button onClick={onClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <div className="title">{header}</div>
      </Modal.Header>
      <Modal.Content>
        <Container
          onSubmit={event => {
            event.preventDefault();
            onSubmit();
          }}
        >
          <FormGroup>
            <label>Autoflow Name</label>
            <input value={name} placeholder="Enter name..." onChange={event => setName(event.target.value)} autoFocus />
            {error ? <div className="error">Name is required</div> : null}
          </FormGroup>
          <FormGroup>
            <label>Autoflow Type</label>
            <input value={type === 'interval' ? 'By Day Sequence' : 'Exact Date'} disabled />
          </FormGroup>
          <FormGroup className="share">
            <label>Shared with org?</label>
            <Dropdown
              value={share}
              selection
              className="sharing-dropdown"
              options={SHARE_OPTIONS}
              onChange={(event, { value }) => setShare(value)}
              icon={<Image src={`${CDN_URL}/images/arrow_down_black.svg`} className="dropdown-icon" />}
            />
          </FormGroup>
          <FormGroup className="owner">
            <label>Owner</label>
            <div className="owner__info">
              <Avatar name={getUserShortName(author)} size="29" src={author.avatar} />
              <div>
                {authorName}
                {author._id === user._id ? <span className="you">&nbsp;(You)</span> : null}
              </div>
            </div>
          </FormGroup>
          {!isEdit || action === 'duplicate' ? (
            <Message>All announcements will be sent from the owner of this Autoflow.</Message>
          ) : null}
          <div className="buttons">
            <ActionButton disabled={!isTeamAdmin(user) && user._id !== author._id} purple>
              {isEdit ? (isSubmitting ? 'Saving' : 'Save') : isSubmitting ? 'Creating' : 'Create'}
            </ActionButton>
          </div>
        </Container>
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
  user: state.user,
  onboarding_flow: state.rootReducer.permission.onboarding_flow,
});

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  addAutoflow: bindActionCreators(addAutoflow, dispatch),
  updateAutoflow: bindActionCreators(updateAutoflow, dispatch),
  duplicateAutoflow: bindActionCreators(duplicateAutoflow, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
