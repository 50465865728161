export const Types = {
  TAGS_PROGRAM_GET_LIST_REQUEST: 'TAGS_PROGRAM_GET_LIST_REQUEST',
  TAGS_PROGRAM_GET_LIST_SUCCESS: 'TAGS_PROGRAM_GET_LIST_SUCCESS',
  TAGS_PROGRAM_GET_LIST_FAILED: 'TAGS_PROGRAM_GET_LIST_FAILED',
  TAGS_PROGRAM_GET_MOST_RECENT_REQUEST: 'TAGS_PROGRAM_GET_MOST_RECENT_REQUEST',
  TAGS_PROGRAM_GET_MOST_RECENT_SUCCESS: 'TAGS_PROGRAM_GET_MOST_RECENT_SUCCESS',
  TAGS_PROGRAM_GET_MOST_RECENT_FAILED: 'TAGS_PROGRAM_GET_MOST_RECENT_FAILED',
  TAGS_PROGRAM_GET_MOST_USED_REQUEST: 'TAGS_PROGRAM_GET_MOST_USED_REQUEST',
  TAGS_PROGRAM_GET_MOST_USED_SUCCESS: 'TAGS_PROGRAM_GET_MOST_USED_SUCCESS',
  TAGS_PROGRAM_GET_MOST_USED_FAILED: 'TAGS_PROGRAM_GET_MOST_USED_FAILED',
  TAGS_PROGRAM_CREATE_NEW_REQUEST: 'TAGS_PROGRAM_CREATE_NEW_REQUEST',
  TAGS_PROGRAM_CREATE_NEW_SUCCESS: 'TAGS_PROGRAM_CREATE_NEW_SUCCESS',
  TAGS_PROGRAM_CREATE_NEW_FAILED: 'TAGS_PROGRAM_CREATE_NEW_FAILED',
  TAGS_PROGRAM_EDIT_REQUEST: 'TAGS_PROGRAM_EDIT_REQUEST',
  TAGS_PROGRAM_EDIT_SUCCESS: 'TAGS_PROGRAM_EDIT_SUCCESS',
  TAGS_PROGRAM_EDIT_FAILED: 'TAGS_PROGRAM_EDIT_FAILED',
  TAGS_PROGRAM_DELETE_REQUEST: 'TAGS_PROGRAM_DELETE_REQUEST',
  TAGS_PROGRAM_DELETE_SUCCESS: 'TAGS_PROGRAM_DELETE_SUCCESS',
  TAGS_PROGRAM_DELETE_FAILED: 'TAGS_PROGRAM_DELETE_FAILED',
  TAGS_PROGRAM_CHANGE_QUERY_PARAMS: 'TAGS_PROGRAM_CHANGE_QUERY_PARAMS',
  TAGS_PROGRAM_RESET_QUERY_PARAMS: 'TAGS_PROGRAM_RESET_QUERY_PARAMS',
};
