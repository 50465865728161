import React, { useReducer } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { Checkbox } from 'shared/FormControl';
import { RootCloseWrapper } from 'react-overlays';

import { StatusFilterWrapper, CustomCheckBoxContainer } from './styles';
import { getWaitlistStatus } from '../common';

const StatusFilter = ({ type, options, value = [], onChange }) => {
  const [isShowDropdown, toggleDropdown] = useReducer(isShow => !isShow, false);

  const handleSelectStatus = (status, event) => {
    event.stopPropagation();

    const existed = value.includes(status);
    if (existed) {
      onChange(filter(value, item => item !== status));
    } else {
      onChange([...value, status]);
    }
  };

  const handleSelectAllStatus = event => {
    event.stopPropagation();

    const allStatus = map(options, item => item.value);

    if (value.length === options.length) {
      onChange([]);
    } else {
      onChange(allStatus);
    }
  };

  const renderStatusSelected = () => {
    if (value && value.length === 1) {
      return <span className="bold">{getWaitlistStatus(value.toString()).statusLabel}</span>;
    }
    if (value && value.length > 1 && value.length < options.length) {
      return <span className="bold">{`${value.length} Statuses`}</span>;
    }
    return (
      <>
        <span>Status:</span>
        <span className="bold">All</span>
      </>
    );
  };

  return (
    <RootCloseWrapper onRootClose={toggleDropdown} event="click" disabled={!isShowDropdown}>
      <StatusFilterWrapper type={type}>
        <div className="button-wrapper">
          <div className="status-button" onClick={toggleDropdown}>
            {renderStatusSelected()}
          </div>
        </div>
        {isShowDropdown && (
          <div className="status-dropdown">
            <label htmlFor="status-all" className="status-item" key="all">
              <span>All statuses</span>
              <CustomCheckBoxContainer>
                <Checkbox id="status-all" checked={value.length === options.length} onChange={handleSelectAllStatus} />
              </CustomCheckBoxContainer>
            </label>
            {map(options, item => {
              const isChecked = value.includes(item.value);
              return (
                <label htmlFor={`status-${item.value}`} className="status-item" key={item.value}>
                  <span>{item.name}</span>
                  <CustomCheckBoxContainer>
                    <Checkbox
                      id={`status-${item.value}`}
                      value={item.value}
                      checked={isChecked}
                      onChange={event => handleSelectStatus(item.value, event)}
                    />
                  </CustomCheckBoxContainer>
                </label>
              );
            })}
          </div>
        )}
      </StatusFilterWrapper>
    </RootCloseWrapper>
  );
};

export default StatusFilter;
