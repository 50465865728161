// Libs
import React, { useMemo, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { diff } from 'deep-diff';

// Shared
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';

// Constants
import { MAX_ONBOARDING_NAME_LENGTH, TYPE_OF_MODAL } from 'components/OnboardingFlowLibrary/constants';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

// Styles
import * as S from './style';

const CreateModal = ({
  onClose,
  onSubmit,
  user,
  isAddLoading,
  type = TYPE_OF_MODAL.CREATE,
  currentName,
  currentParamShare,
  isLoadingAction,
}) => {
  const [name, setName] = useState(currentName || '');
  const [paramShare, setParamShare] = useState(
    currentParamShare || {
      author: (user || {})._id,
      share: TEAM_SHARE_PRIVATE,
    },
  );

  const isChangedData = useMemo(() => {
    return !isEqual(currentName, name) || diff(currentParamShare, paramShare);
  }, [currentName, name, currentParamShare, paramShare]);

  const handleChangeValue = e => {
    const newName = e.target.value;

    if (newName.length <= MAX_ONBOARDING_NAME_LENGTH) {
      setName(newName);
    }
  };

  const handleChangeOwnershipStatus = ({ author, share }) => {
    if (author === 'no_owner') {
      author = null;
    } else if (typeof author === 'object') {
      author = (author || {})._id;
    }
    setParamShare({ author, share });
  };

  const onClick = () => {
    if (isAddLoading || isLoadingAction) return;

    const data = {
      name: name.trim(),
      author: paramShare.share === TEAM_SHARE_NOOWNER ? null : paramShare.author,
      share: paramShare.share,
    };

    typeof onSubmit === 'function' && onSubmit(data);
  };

  return (
    <S.CustomModal open onClose={onClose} closeOnDimmerClick={false} className="evf-add-onboarding-flow-modal">
      <Modal.Header className="modal-recipe-header">
        <S.HeaderWrapper>
          <S.TitleWrapper>
            <S.HeaderTitle>
              {type === TYPE_OF_MODAL.UPDATE ? 'Edit Onboarding Flow Info' : 'Create New Onboarding Flow'}
            </S.HeaderTitle>
            <OwnershipPopupStudio
              size={28}
              user={user}
              owner={type === TYPE_OF_MODAL.UPDATE ? { _id: paramShare.author } : user}
              shareStatus={paramShare.share}
              saveChangeOnClose={handleChangeOwnershipStatus}
            />
          </S.TitleWrapper>
          <S.Description>
            Set up an Onboarding Flow to automatically welcome and onboard your new clients upon first login.{' '}
          </S.Description>
          <CloseButton className="close-button" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <S.FormInput
            label="Onboarding flow name"
            placeholder="Name your onboarding flow"
            required
            isHideErrorMessage
            maxLength={90}
            inputProps={{ autoFocus: true, maxLength: 90 }}
            isSubmitted={isAddLoading}
            value={name}
            onChange={e => handleChangeValue(e)}
          />
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.Footer>
          <S.Button disabled={isAddLoading || !isChangedData || !name.trim() || isLoadingAction} onClick={onClick}>
            {type === TYPE_OF_MODAL.UPDATE ? TYPE_OF_MODAL.UPDATE : TYPE_OF_MODAL.CREATE}
          </S.Button>
        </S.Footer>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      onboardingLibrary: { isAddLoading },
      onboardingFlow: { isLoadingAction },
    },
  } = state;

  return { user, isAddLoading, isLoadingAction };
};

export default connect(mapStateToProps, null)(CreateModal);
