import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleModal, toggleConfirmModal } from 'actions/modal';
import AssignOnboardingMessage from './component';

const mapStateToProps = state => {
  const { user } = state;

  return {
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignOnboardingMessage);
