import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';
import { ReactComponent as LeaderboardCup } from 'assets/icons/leaderboard.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import ConnectedForumBG from 'assets/icons/add_leaderboard_connected_forum_bg.svg';

export default ({ onAddNew, connected }) => (
  <Wrapper connected={connected} className="addNewLeaderboard">
    {connected ? null : <LeaderboardCup />}
    <div className="title">Leaderboard</div>
    <div className="subtitle">Create a competitive leaderboard to motivate members of this Autoflow</div>
    <Button transparent onClick={onAddNew}>
      <PlusIcon />
      Add New Leaderboard
    </Button>
  </Wrapper>
);

const Wrapper = styled.div`
  text-align: center;
  width: 296px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 30px 25px;

  svg {
    margin: 0 auto 20px;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #202353;
    margin-bottom: 5px;
  }

  .subtitle {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    margin-bottom: 30px;
  }

  ${Button} {
    color: #5158cf;
    border: 1px solid #5158cf;
    padding: 7px 19px;

    svg {
      margin-right: 10px;
    }

    :hover {
      background-color: #5158cf;
      color: #ffffff;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  ${props =>
    props.connected &&
    css`
      padding: 45px 25px 45px 165px;
      width: 420px;
      text-align: left;
      background: #fff url(${ConnectedForumBG}) no-repeat left top;
      background-size: auto 100%;

      .subtitle {
        margin-bottom: 20px;
      }

      ${Button} {
        color: #fff;
        background-color: #5158cf;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    `}
`;
