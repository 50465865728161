import React, { useMemo } from 'react';
import { debounce, get } from 'lodash';

import { ReactComponent as PlusIcon } from 'assets/icons/add_grey.svg';
import { Checkbox } from 'shared/FormControl';
import * as S from './style';
import { checkingValidUrl, convertS3UrlToCloudFrontUrl, timeSince } from 'utils/commonFunction';
import LoadingIndicator from 'shared/LoadingIndicator';

const MAX_LENGTH = 45;

const getLabel = item => {
  if (item) {
    const label = get(item, 'level.item.name', '');
    const currentLabel = label === 'All' ? 'All levels' : label;
    return `${get(item, 'duration.item.name', '')} - ${currentLabel}`;
  }
  return '';
};

const getLabels = item => {
  if (item) {
    return get(item, 'labels').map((it, idx) => (
      <S.Label key={`item-labels-${(it.item || {})._id || idx}`}>{get(it, 'item.name', '')}</S.Label>
    ));
  }
  return '';
};

export function cutString(str) {
  return str.length > MAX_LENGTH ? str.substring(0, MAX_LENGTH) + '... ' : str;
}

const WorkoutItem = ({ item, onSelect, isSelected, customBrandingBackground, cloudfrontList }) => {
  const handleOnClickItem = event => {
    event.preventDefault();
    onSelect && onSelect(item);
  };

  const thumbnail = useMemo(() => {
    if (item.background_thumbnail && checkingValidUrl(item.background_thumbnail)) {
      return item.background_thumbnail;
    } else {
      return item.background ? item.background : customBrandingBackground.src ? customBrandingBackground.src : '';
    }
  }, [item, customBrandingBackground]);

  return (
    <S.WorkoutWrapper onClick={handleOnClickItem} selected={isSelected}>
      <S.Thumbnail thumbnail={convertS3UrlToCloudFrontUrl(thumbnail, cloudfrontList, true)}>
        {item.type === 'video' && <S.VideoIcon />}
      </S.Thumbnail>
      <S.DescWrapper>
        <S.Desc>
          <S.HeaderWrapper>
            <S.DescTitle>{cutString(item.title || '')}</S.DescTitle>
            <S.LevelLabel>{getLabel(item)}</S.LevelLabel>
          </S.HeaderWrapper>
          <S.CheckboxWrapper>
            <S.MostRecentLabel>
              {item.last_activity_at ? timeSince(new Date(item.last_activity_at)) : '1s'}
            </S.MostRecentLabel>
            <Checkbox checked={isSelected} onChange={handleOnClickItem} className="select-checkbox" disabled={false} />
          </S.CheckboxWrapper>
        </S.Desc>
        <S.ListLabel>{getLabels(item)}</S.ListLabel>
      </S.DescWrapper>
    </S.WorkoutWrapper>
  );
};

export default function WorkoutList({
  data = [],
  addedIds = [],
  selectedIds = [],
  onChangeItem,
  customBrandingBackground,
  onCreateNewWorkout,
  onLoadMore,
  availableWorkouts,
  loading = true,
  cloudfrontList,
}) {
  const workouts = useMemo(() => {
    return data.filter(it => !addedIds.includes(it._id));
  }, [data, addedIds]);

  const handleSelectedItem = item => {
    onChangeItem && onChangeItem(item);
  };

  const isCheckedItem = item => {
    return selectedIds.includes(item._id);
  };

  const handleScrollEnd = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && onLoadMore) {
      onLoadMore();
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const handleRenderList = () => {
    if (addedIds.length > 0 && workouts.length <= 0) {
      return (
        <S.WorkoutEmptyListArea>
          <S.NoMoreLabel>No results found</S.NoMoreLabel>
        </S.WorkoutEmptyListArea>
      );
    }
    if (workouts.length <= 0 && !availableWorkouts) {
      return (
        <S.WorkoutEmptyListArea>
          <S.WorkoutButton onClick={onCreateNewWorkout}>
            <PlusIcon />
            Create your first On-Demand Workout
          </S.WorkoutButton>
        </S.WorkoutEmptyListArea>
      );
    }
    return (
      <S.WorkoutListArea
        onScroll={event => {
          event.persist();
          onScrollDebounce.call(null, event);
        }}
      >
        {workouts.map(it => (
          <WorkoutItem
            item={it}
            key={it._id}
            cloudfrontList={cloudfrontList}
            onSelect={handleSelectedItem}
            isSelected={isCheckedItem(it)}
            customBrandingBackground={customBrandingBackground}
          />
        ))}
        {loading && <LoadingIndicator className="load-more" />}
      </S.WorkoutListArea>
    );
  };

  return (
    <>
      <S.WorkoutListHeader>
        <S.TotalWorkout>
          <span>{`All Workouts (${availableWorkouts})`}</span>
          <S.SelectedCounterLabel>
            {selectedIds.length > 0 ? `${selectedIds.length} selected` : ''}
          </S.SelectedCounterLabel>
        </S.TotalWorkout>
        {handleRenderList()}
      </S.WorkoutListHeader>
    </>
  );
}
