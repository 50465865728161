import _ from 'lodash';
import { fromJS } from 'immutable';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  planPricing: [],
  addOnsPricing: [],
  planPackage: {},
  teamData: {},
  referralPromotionCode: null,
  selectPackage: {
    tier: 'pro',
    period: 'monthly',
    addOns: {
      automation: true,
    },
    packagePrice: {},
    prorationPlanCost: null,
    zapier_quantity: 0,
  },
  canDowngradeToStarter: false,
  shouldSelectPlan: false,
  isUpdatingPromo: false,
  zapierKey: null,
  zapierUsageHistory: {},
  zapierUsageLoading: false,
  zapierCurrentUsage: {},
  loadingPricing: false,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.UPDATING_PROMO:
      return state.merge({ isUpdatingPromo: true });
    case Types.UPDATING_PROMO_SUCCESS:
      return state.merge({ isUpdatingPromo: false });
    case Types.INIT_PRICING_DATA_SUCCESS:
      return state.mergeDeep(fromJS({ ...payload.data, isInited: true, loadingPricing: false }));
    case Types.GET_REFERRAL_PROMOTION_CODE_SUCCESS:
      return state.merge({ referralPromotionCode: payload.data });
    case Types.GET_ZAPIER_KEY_SUCCESS:
      return state.merge({ zapierKey: payload.data });
    case Types.UPDATE_SELECT_PACKAGE: {
      const clonedState = { ...state.toJS() };
      const add_ons = _.get(payload, 'data.promoCode.metadata.add_ons');

      if (add_ons) {
        return state.mergeIn(['selectPackage'], fromJS(payload.data), {
          addOns: {
            ...clonedState.selectPackage.addOns,
            [add_ons]: true,
          },
        });
      } else {
        return state.mergeIn(['selectPackage'], fromJS(payload.data));
      }
    }
    case Types.UPDATE_ZAPIER_PRICING: {
      const updatedAddOns = _.get(payload, 'data.addOnsPricing', {});
      return state.mergeIn(['addOnsPricing'], fromJS(updatedAddOns));
    }

    case Types.GET_ZAPIER_USAGE_HISTORY_REQUEST: {
      return state.mergeIn(['zapierUsageLoading'], true);
    }

    case Types.GET_ZAPIER_USAGE_HISTORY_SUCCESS: {
      return state.merge({ zapierUsageHistory: fromJS(payload), zapierUsageLoading: false });
    }

    case Types.GET_ZAPIER_USAGE_HISTORY_FAILED: {
      return state.mergeIn(['zapierUsageLoading'], false);
    }

    case Types.MARK_SHOUlD_SELECT_PLAN:
      return state.merge({ shouldSelectPlan: false, canDowngradeToStarter: true });

    case Types.RESET_PRICING_FLAG:
      return state.set('canDowngradeToStarter', false);

    case Types.RESET_ZAPIER_QUANTITY:
      return state.setIn(['selectPackage', 'zapier_quantity'], 0);

    case Types.GET_ZAPIER_CURRENT_USAGE_SUCCESS:
      return state.merge({ zapierCurrentUsage: payload.data });

    case Types.RESET_ZAPIER_SECRET_KEY_SUCCESS:
      return state.merge({ zapierKey: payload.data });

    case Types.INIT_PRICING_DATA_REQUEST:
      return state.set('loadingPricing', true);

    case Types.INIT_PRICING_DATA_FAILED:
      return state.set('loadingPricing', false);

    default:
      return state;
  }
};
