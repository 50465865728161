import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const PriceNote = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 140%;
  text-align: center;
  color: #202353;
  opacity: 0.4;
  margin-top: 15px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  overflow: hidden;

  .planSummary__heading {
    background: #f9f9fb;
    border-bottom: 1px solid #dadfea;
    padding: 30px 25px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      color: #202353;
    }
  }

  .planSummary__body {
    padding-right: 25px;
    padding-left: 25px;
    min-height: 200px;

    .planSummary__selectedPlan {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;

      :first-child {
        border-bottom: 1px dashed #e6e9f3;
      }

      .planSummary__planName {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 100%;
        color: #6261ff;

        &--automation {
          color: #202353;
        }
      }

      &--name {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 100%;
        color: #6261ff;
      }

      &--client {
        font-size: 13px;
        line-height: 100%;
        color: #6261ff;
        color: #202353;
      }

      &--priceWrapper {
        text-align: right;
      }

      &--price {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 100%;
        text-align: right;
        color: #202353;
      }

      &--period {
        font-size: 13px;
        line-height: 100%;
        text-align: right;
        color: #202353;
      }

      &--starter {
        color: #202353;
      }

      &--pro {
        color: #6261ff;
      }

      &--studio {
        color: #28ae66;
      }

      &--right {
        min-width: 102px;
        text-align: right;

        &.isOneTime {
          min-width: 60px;
        }
      }
    }

    .planSummary__creditToAccount {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;
      border-bottom: 1px dashed #e6e9f3;

      &--leftContent {
        display: flex;
      }

      &--tootipIcon {
        margin-left: 10px;
        background-color: #d7dbe3;
        color: #fff;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        font-weight: 900;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--name {
        font-size: 13px;
        line-height: 100%;
        color: #202353;
      }

      &--price {
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        text-align: right;
        color: #48c98c;
      }

      .__react_component_tooltip.plan-summary-tootip {
        font-weight: normal;
        border: 1px solid #dfe4e5;
        box-sizing: border-box;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        max-width: 260px;
        font-size: 14px;
        text-align: left !important;
      }
    }

    .planSummary__promoMsg {
      padding-top: 20px;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #202353;
    }

    .planSummary__waringMsg {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #202353;
      padding-top: 20px;

      a {
        font-weight: bold;
        color: #6261ff;
      }
    }
  }

  .planSummary__footer {
    text-align: center;
    padding: 25px;
  }
`;

export const DowngradeConfirmModal = styled(Modal)`
  &.ui.modal.confirm-popup-container {
    max-width: 440px !important;
    background-color: #ffffff !important;
    border-radius: 5px !important;
  }

  .close-button {
    top: -12px !important;
    right: -12px !important;
  }

  .close-button img {
    width: 24px !important;
    height: 24px !important;
  }

  &.ui.modal.confirm-popup-container .confirm-actions {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding: 25px 20px 20px 20px !important;
  }

  .confirm-content-header {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    padding-bottom: 15px !important;
  }

  .confirm-content-header > img {
    width: 25px !important;
    height: 25px !important;
    border: none !important;
    margin-right: 6px !important;
  }
  .confirm-content-header > p {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }

  .confirm-content-body .confirm-content-label {
    font-family: 'Open Sans' !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px !important;
  }

  .confirm-content-label__second {
    margin-top: 20px;
  }

  .confirm-content-body__feature {
    background: #f3f5f8;
    border-radius: 5px;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    > div {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4e5662;
    }
  }

  button.ui.button.confirm-downgrade-button {
    background: #fba703;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    padding: 9px 30px !important;
    margin: unset !important;
    border: none !important;
    max-height: 38px;
  }
`;
