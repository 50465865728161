import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 528px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f7f7fa;

  .custom-settings-icon {
    width: 20px;
    height: 20px;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: #dce0ea;
    `}
`;

export const BannerWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 116px;
  height: 68px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .market-place-icon {
    position: absolute;
    left: 4px;
    bottom: 4px;
  }

  .__react_component_tooltip.app-tooltip.market-place-tooltip {
    padding: 15px;
    max-width: 192px;
    background-color: #151619;
    text-align: left;
    margin-top: -7px;
    p {
      color: #fff;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &.market-place-tooltip.place-top:after {
      bottom: -7px;
      border-top-color: #151619;
    }
  }
`;

export const PackageInfoWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PackageName = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #202353;
  cursor: pointer;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const PackageInfo = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #202353;
  min-height: 16px;
`;

export const PackageStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  padding: 0 4px;
  border-radius: 2px;
  background-color: #c6cbd9;

  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: #64667f;
  text-transform: uppercase;
`;

export const SettingsButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    .custom-settings-icon path {
      fill: #5c5bbd;
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      &:hover {
        .custom-settings-icon path {
          fill: #728096;
        }
      }
    `}
`;

export const Actions = styled.div`
  .__react_component_tooltip.app-tooltip.settings-tooltip {
    width: 228px;
    border-radius: 5px;
    background-color: #161519;
    padding: 15px;

    &.place-top {
      margin-top: -4px;

      &::after {
        border-top-color: #151619;
        bottom: -7px;
      }
    }

    div.settings-tooltip-wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        margin-bottom: unset;
        text-align: left;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #ffffff;

        &.title {
          font-weight: 700;
        }
      }
    }
  }
`;
