// Libs
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import Axios from 'axios';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

// ACtions
import { axiosInstance } from 'configs/request';

// Shared
import { NewSearchInput } from 'shared/SearchInput';
import LoadingIndicator from 'shared/LoadingIndicator';

// Components
import Item from './Item';
import ConfirmModal from '../ConfirmModal';

// Constants
import { ONBOARDING_SETTINGS_POPUP_GET_LIST, SETTINGS_TYPE_MODAL } from '../constants';
import { isAdmin, isOnwer } from 'utils/validations';
import { CDN_URL, USER_ROLE } from 'constants/commonData';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as EmptyCoachesIcon } from 'assets/icons/OnboardingFlow/coaches_empty.svg';
import { ReactComponent as EmptySearchIcon } from 'assets/icons/OnboardingFlow/on_demand_search_empty.svg';

// Styles
import * as S from './style';

const CancelToken = Axios.CancelToken;
const cancelRequests = [];

const ListModal = ({
  user,
  type,
  title,
  onClose,
  onSubmit,
  permission,
  cloudfrontList,
  toggleConfirmModal,
  currentSelectedList,
}) => {
  const { method, url, query } = ONBOARDING_SETTINGS_POPUP_GET_LIST[type];

  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isEnabled, setIsEnabled] = useState(false);

  const searchBoxAssetsRef = useRef();

  const isEmptyList = list.length === 0;
  const isEmptySelectedList = selectedList.length === 0;
  const selectedLength = currentSelectedList.length + selectedList.length;
  const currentRole =
    isOnwer(user) || isAdmin(user) ? [USER_ROLE.OWNER, USER_ROLE.ADMIN, USER_ROLE.TRAINER] : [USER_ROLE.TRAINER];
  const hasMP = get(permission, 'marketplace_payment', false);

  useEffect(() => {
    fetchData({ inputValue: '' });
  }, []);

  useEffect(() => {
    setIsEnabled(!isEmptySelectedList);
  }, [selectedList]);

  const fetchData = ({ inputValue, isNextPage = false, searchText }) => {
    if (!url || !method) return;

    const isFetching = isLoading || isLoadingMore;

    const isCoachesModal = type === SETTINGS_TYPE_MODAL.COACHES;
    const isPackagesModal = type === SETTINGS_TYPE_MODAL.PACKAGES;

    if (isFetching && !!cancelRequests.length) {
      cancelRequests.forEach(
        cancelRequest =>
          typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_ONBOARDING_SETTINGS_POPUP'),
      );
    }

    !isNextPage && setIsLoading(true);
    isNextPage && setIsLoadingMore(true);

    let queryParams = {
      ...query,
      // Get list teammate by roles
      roles: isCoachesModal ? currentRole : undefined,
      // Get list packages by platforms
      platforms: isPackagesModal ? (hasMP ? ['everfit', 'marketplace'] : ['everfit']) : undefined,
    };

    const key = 'params';
    const searchCoaches = isCoachesModal ? searchText || inputValue : undefined;
    const searchPackages = isPackagesModal ? searchText || inputValue : undefined;
    const listIds = (list || []).map(({ _id = '', id = '' }) => _id || id);

    if (searchCoaches || searchPackages) {
      queryParams = {
        ...queryParams,
        search: searchCoaches,
        q: searchPackages || undefined,
      };
    }

    if (isNextPage) {
      queryParams = {
        ...queryParams,
        page: nextPage,
      };
    } else {
      setNextPage(1);
    }

    axiosInstance({
      method,
      url,
      [key]: queryParams,
      cancelToken: new CancelToken(cancelRequest => cancelRequests.push(cancelRequest)),
    })
      .then(response => {
        const currentQueryText = queryParams.q || queryParams.search || '';
        const currentInputValue = searchBoxAssetsRef.current || '';

        if (currentQueryText.toLowerCase().trim() !== currentInputValue.toLowerCase().trim()) return;

        cancelRequests.length = 0;

        const { data, total } = (response || {}).data;

        const searchData = isPackagesModal
          ? ((data || {}).data || []).filter(o => !listIds.includes(o.id))
          : (data || []).filter(o => !listIds.includes(o._id));
        const newData = isNextPage ? [...list, ...searchData] : isPackagesModal ? (data || {}).data : data;
        const newTotal = isPackagesModal ? (data || {}).total : total;
        const newPage = queryParams.page + 1;

        setList(newData);
        setTotal(newTotal);
        setNextPage(newPage);

        !isNextPage && setIsLoading(false);
        isNextPage && setIsLoadingMore(false);
      })
      .catch(error => {
        if ((error || {}).message === 'CANCEL_REQUEST_SEARCH_ONBOARDING_SETTINGS_POPUP') return;

        !isNextPage && setIsLoading(false);
        isNextPage && setIsLoadingMore(false);
      });
  };

  const fetchDataDebounce = debounce(fetchData, 300);

  const handleSearch = (__, { value }) => {
    searchBoxAssetsRef.current = value;

    const trimmedText = value.toLowerCase().trim();

    if (trimmedText !== textSearch) {
      setTextSearch(trimmedText);
      fetchDataDebounce({ inputValue: trimmedText });
    }
  };

  const searchDebounce = debounce(handleSearch, 300);

  const handleClearSearch = () => {
    searchBoxAssetsRef.current = '';

    setTextSearch('');
    fetchData({ inputValue: '' });
  };

  const handleLoadMore = event => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    const bottom = scrollWidth - (scrollLeft + clientWidth);

    if (list.length < total && bottom < 200 && !isLoading && !isLoadingMore) {
      fetchData({ isNextPage: true, searchText: textSearch });
    }
  };

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const getTotalContentModal = () => {
    switch (type) {
      case SETTINGS_TYPE_MODAL.COACHES:
        return 'All Coaches';
      case SETTINGS_TYPE_MODAL.PACKAGES:
        return 'All packages';

      default:
        return '';
    }
  };

  const handleSelect = (item, isSelected) => {
    const { _id = '', id = '' } = item || {};
    const itemId = _id || id;

    if (isSelected) {
      const newList = selectedList.filter(({ _id, id }) => (_id || id) !== itemId);
      setSelectedList(uniq(newList));
      return;
    }

    setSelectedList(uniq([...selectedList, item]));
  };

  const handleSubmit = () => {
    if (!isEnabled || isLoading) return;

    const newSelectedList = currentSelectedList.length > 0 ? [...currentSelectedList, ...selectedList] : selectedList;

    onSubmit(newSelectedList);
    onClose();
  };

  const handleClose = () => {
    if (!isEmptySelectedList) {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Discard Changes?"
          content="Are you sure you want to go? Changes have not been saved yet."
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          confirmBtnTitle="Discard Changes"
          className="multiple-popup-red discard-change"
          onConfirm={onClose}
          onClose={() => toggleConfirmModal(false)}
          width={'467px'}
        />,
      );
    } else {
      onClose();
    }
  };

  const handleConfirmSubmit = () => {
    if (!isEmptySelectedList) {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={
            SETTINGS_TYPE_MODAL.COACHES === type ? 'Add Coaches to Onboarding Flow' : 'Add Packages to Onboarding Flow'
          }
          content={
            SETTINGS_TYPE_MODAL.COACHES === type
              ? 'Each added coach will receive a unique link to share with their clients. Clients who sign up using the link will gain access to all the onboarding flow assets. Would like to continue?'
              : 'Clients who sign up through these packages will receive all the onboarding flow assets. Would you like to continue?'
          }
          headerIcon={`${CDN_URL}/images/archive_light_package.svg`}
          confirmBtnTitle="Yes"
          className="multiple-popup-purple"
          onConfirm={handleSubmit}
          onClose={() => toggleConfirmModal(false)}
        />,
      );
    }
  };

  const renderLoadingIndicator = (className = '') => {
    return <LoadingIndicator title="Loading" size="small" className={`loading--on-demand-asset ${className}`} />;
  };

  const renderEmptySearch = () => {
    return (
      <S.ContentBodyEmpty>
        <EmptySearchIcon className="empty-search-icon" />
        <span>No results found.</span>
      </S.ContentBodyEmpty>
    );
  };

  const renderEmptyList = () => {
    return (
      <S.ContentWrapperEmpty>
        <EmptyCoachesIcon className="empty-icon" />
        <span>No {title.toLowerCase()} found.</span>
      </S.ContentWrapperEmpty>
    );
  };

  const renderItem = item => {
    const { _id, id, onboarding_flow_id } = item || {};
    const itemId = _id || id;

    const isSelected = (selectedList || []).some(({ _id, id }) => (_id || id) === itemId);
    const isDisabled = (currentSelectedList || []).some(({ trainer, id }) => ((trainer || {})._id || id) === itemId);
    const isAssigned = typeof onboarding_flow_id === 'string' && !isDisabled;

    return (
      <Item
        key={itemId}
        type={type}
        item={item}
        onSelect={handleSelect}
        isChecked={isSelected}
        isDisabled={isDisabled}
        isAssigned={isAssigned}
        cloudfrontList={cloudfrontList}
        user={user}
      />
    );
  };

  const renderList = () => {
    const isSearchLoading = textSearch && isLoading;
    const isSearchEmptyList = textSearch && isEmptyList;

    return (
      <S.ContentBody isSearchLoading={isSearchLoading} isSearchEmptyList={isSearchEmptyList}>
        {isSearchLoading
          ? renderLoadingIndicator()
          : isSearchEmptyList
          ? renderEmptySearch()
          : (list || []).map(renderItem)}
      </S.ContentBody>
    );
  };

  return (
    <S.CustomModal open={true} onClose={handleClose} closeOnDimmerClick={false} className="evf-resources-asset-modal">
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderLeftSide>
            <S.HeaderTitle>{title}</S.HeaderTitle>
          </S.HeaderLeftSide>
          <S.HeaderRightSide>
            <NewSearchInput
              autoFocus={false}
              focusAfterClear
              placeholder="Search by name"
              onChange={searchDebounce}
              onClearSearch={handleClearSearch}
            />
          </S.HeaderRightSide>
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper isEmptyOrLoading={!textSearch && (isEmptyList || isLoading)}>
          {!(!textSearch && (isEmptyList || isLoading)) && (
            <>
              <S.ContentHeader>
                <S.TotalContent>{`${getTotalContentModal()} (${total})`}</S.TotalContent>
                {selectedLength > 0 && <S.SelectedWrapper>{selectedLength} selected</S.SelectedWrapper>}
              </S.ContentHeader>
            </>
          )}
          <S.ContentContainer onScroll={handleScroll} isEmptyOrLoading={!textSearch && (isEmptyList || isLoading)}>
            {!textSearch && isLoading
              ? renderLoadingIndicator()
              : !textSearch && isEmptyList
              ? renderEmptyList()
              : renderList()}
            {isLoadingMore ? renderLoadingIndicator('loading-more') : null}
          </S.ContentContainer>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ActionsWrapper>
          <S.Button className="btn btn__cancel" onClick={handleClose}>
            Cancel
          </S.Button>
          <S.Button className="btn btn__save" onClick={handleConfirmSubmit} disabled={!isEnabled || isLoading}>
            Add
          </S.Button>
        </S.ActionsWrapper>
      </Modal.Actions>
    </S.CustomModal>
  );
};

export default ListModal;
