import React, { memo } from 'react';
import { isWindows } from 'react-device-detect';

import * as S from '../style';
import { ReactComponent as IconClose } from 'assets/icons/close_notification_tooltip.svg';
import { useMyContext } from '../context/NotificationContext';

const FooterTooltip = () => {
  const { openTooltip, closeTooltip } = useMyContext();

  if (!openTooltip) return <></>;

  return (
    <S.FooterTooltipWrapper>
      <S.FooterTooltipContainer>
        <S.FooterTooltipContent>
          <S.FooterTooltipLabel>Press</S.FooterTooltipLabel>
          <S.ButtonCtrl isWindows={isWindows}>{isWindows ? 'Ctrl' : 'Cmd'}</S.ButtonCtrl>
          <S.FooterTooltipLabel>and click notification to open in new tab</S.FooterTooltipLabel>
        </S.FooterTooltipContent>
        <S.FooterTooltipCloseButton onClick={closeTooltip}>
          <IconClose />
        </S.FooterTooltipCloseButton>
      </S.FooterTooltipContainer>
    </S.FooterTooltipWrapper>
  );
};

export default memo(FooterTooltip);
