// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import moment from 'moment';

// Actions
import { toggleModal } from 'actions/modal';

// Components
import { NewSearchInput } from 'shared/SearchInput';
import FilterPopup from './FilterPopup';
import AddNewRecipe from './AddNewRecipe';
import { FilterNumber } from './FilterPopup/style';
import ListSorter from './ContentList/listSorter';
import UpgradePathTooltipContent from 'shared/UpgradePath/components/UpgradePathTooltipContent';

// Constants
import { CATEGORIES, MAX_CALORIES, MIN_CALORIES, RECIPE_HEADER_TABS, RECIPE_STATUS, SORT_BY } from '../constants';
import { PLANS, TRIAL_MODE } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';
import { ADD_ONS_PRICING_CODE } from 'components/PricingPlan/constants';

// Helper
import { checkPastDateForTrial } from 'components/PricingPlan/helper';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/MealPlans/meal_plan_filter_icon.svg';

// Styles
import * as S from './style';

const RecipesHeader = props => {
  const {
    user,
    toggleModal,
    changeQueryParams,
    push,
    myRecipeTotal = 0,
    totalDraft = 0,
    totalRecipeInTeam,
    getInitTotalRecipe,
    initTotal,
    tab,
    setHeaderTabs,
    permission,
    trialMode,
    addOnMealPlan,
    loadingPricing,
  } = props;
  const { max_calories, hasGetToTal } = initTotal;
  const { no_recipes } = permission || {};

  const [activeCategory, setActiveCategory] = useState(CATEGORIES[0].value);
  const [appliedFilter, setAppliedFilter] = useState();
  const [filterNumber, setFilterNumber] = useState();
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY.MOST_RECENT);
  const [isShowDraft, setIsShowDraft] = useState(false);
  const [isHeaderResize, setIsHeaderResize] = useState(false);

  const exceededNumberRecipes = no_recipes >= 0 && no_recipes <= totalRecipeInTeam;

  const isTrial = useMemo(() => {
    const { beta_date } = addOnMealPlan || {};
    const timezone = moment.tz.guess();
    const isPastDate = checkPastDateForTrial(beta_date, timezone);
    const isShowBetaLabel = beta_date && !isPastDate;
    return trialMode === TRIAL_MODE.Active || isShowBetaLabel;
  }, [addOnMealPlan, trialMode]);

  useEffect(() => {
    const targetElement = document.querySelector('.recipes-container');

    const handleScroll = () => {
      setIsHeaderResize(targetElement && targetElement.scrollTop > 0);
    };

    if (targetElement) {
      targetElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (hasGetToTal) {
      const initFilterState = {
        dietaries: [],
        caloriesRange: {
          from: MIN_CALORIES,
          to: max_calories > MAX_CALORIES ? max_calories : MAX_CALORIES,
        },
        recipeFrom: [],
        recipeStatus: [],
      };

      setAppliedFilter(initFilterState);
    }
  }, [max_calories, hasGetToTal]);

  useEffect(() => {
    getInitTotalRecipe();
  }, []);

  useEffect(() => {
    if (RECIPE_HEADER_TABS.ALL_RECIPE !== tab) {
      typeof changeQueryParams === 'function' &&
        changeQueryParams({ only_my_recipes: !(RECIPE_HEADER_TABS.ALL_RECIPE === tab), page: 1 });
    }
  }, []);

  const handleOpenAddRecipe = () => {
    if (exceededNumberRecipes) return;

    toggleModal(true, <AddNewRecipe user={user} toggleModal={toggleModal} push={push} />);
  };

  const renderTrigger = open => {
    return (
      <S.FilterButton active={open}>
        <FilterIcon className="filter-icon" />
        <span className="filter-label">Filter</span>
        {filterNumber > 0 && <FilterNumber className="filter-number">{filterNumber}</FilterNumber>}
      </S.FilterButton>
    );
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams && changeQueryParams({ text_search: value, only_my_recipes: false, page: 1 });
  };

  const handleClearSearch = () => {
    handleSearch(null, { value: '' });
  };

  const handleSelectedFilterApply = selectedFilter => {
    const dietaryFilter = get(selectedFilter, 'dietaries', []).map(item => item.value);
    const recipeFrom = JSON.stringify(get(selectedFilter, 'recipeFrom', []));
    // const recipeStatus = JSON.stringify(get(selectedFilter, 'recipeStatus', []));
    const dietaryFilterJson = JSON.stringify(dietaryFilter);

    const newParams = {
      recipe_from: recipeFrom,
      // status: recipeStatus,
      dietaries: dietaryFilterJson,
      from_calories: get(selectedFilter, 'caloriesRange.from', 0),
      to_calories: get(selectedFilter, 'caloriesRange.to', 0),
      page: 1,
    };

    setAppliedFilter(selectedFilter);
    changeQueryParams && changeQueryParams(newParams);
  };

  const handleFilterCategory = category => {
    if (activeCategory === category) return;

    setActiveCategory(category);
    changeQueryParams &&
      changeQueryParams({
        category: category,
        page: 1,
        status: JSON.stringify([isShowDraft ? RECIPE_STATUS.DRAFT : RECIPE_STATUS.PUBLISH]),
      });
  };

  const handleChangeNumberFilter = number => {
    setFilterNumber(number);
  };

  const handleSelectSort = value => {
    if (sortBy === value) {
      setShowSortPopup(false);
      return;
    }

    const sort = SORT_BY.MOST_RECENT === value ? -1 : 1;

    setSortBy(value);
    setShowSortPopup(false);

    changeQueryParams &&
      changeQueryParams({
        sorter: value,
        sort: sort,
        page: 1,
        status: JSON.stringify([isShowDraft ? RECIPE_STATUS.DRAFT : RECIPE_STATUS.PUBLISH]),
      });
  };

  const handleSelectShowByShare = value => {
    if (tab === value && !isShowDraft) return;

    setIsShowDraft(false);

    typeof setHeaderTabs === 'function' && setHeaderTabs(value);

    changeQueryParams &&
      changeQueryParams({
        only_my_recipes: !(RECIPE_HEADER_TABS.ALL_RECIPE === value),
        page: 1,
        status: JSON.stringify([RECIPE_STATUS.PUBLISH]),
      });
  };

  const handleShowDraft = status => {
    if (isShowDraft) return;

    setIsShowDraft(!status);

    typeof changeQueryParams === 'function' &&
      changeQueryParams({
        status: JSON.stringify([status ? RECIPE_STATUS.PUBLISH : RECIPE_STATUS.DRAFT]),
        only_my_recipes: status ? tab !== RECIPE_HEADER_TABS.ALL_RECIPE : status,
        page: 1,
      });
  };

  return (
    <S.RecipesHeaderContainer isHeaderResize={isHeaderResize}>
      <S.RecipesHeader>
        <S.RecipeFilterWrapper>
          <NewSearchInput
            placeholder="Search recipe name"
            className="search-input-recipes"
            onChange={debounce(handleSearch, 300)}
            onClearSearch={handleClearSearch}
          />
          <FilterPopup
            menuHeight={180}
            trigger={renderTrigger}
            onSelectedFilterApply={handleSelectedFilterApply}
            onChangeNumberFilter={handleChangeNumberFilter}
            appliedFilter={appliedFilter}
            changeQueryParams={changeQueryParams}
            isShowRecipeStatus
            maxCalories={max_calories}
          />
        </S.RecipeFilterWrapper>
        <S.NewRecipeButton onClick={handleOpenAddRecipe} purple>
          <PlusIcon className="plus-icon" />
          <span className="label">Create New Recipe</span>
          {exceededNumberRecipes && !loadingPricing && (
            <UpgradePathTooltipContent
              className="features__tooltipUpgradePath custom-upgrade-path-popup"
              id="feat_custom-metrics"
              btnFullWidth
              heading="Create unlimited recipes"
              description={`${isTrial ? 'The trial' : 'The current plan'} is limited to ${pluralize(
                'recipe',
                no_recipes,
                true,
              )}. Upgrade now to continue building your recipe library.`}
              btnContentText="Get Meal Plans ADD-ON"
              planWillSelect={{
                tier: PLANS.pro.key,
                addOns: {
                  meal_plan: true,
                },
              }}
            />
          )}
        </S.NewRecipeButton>
      </S.RecipesHeader>
      <S.RecipeCategories>
        {CATEGORIES.map(item => {
          return (
            <S.RecipeCategory
              key={get(item, 'value', '')}
              active={activeCategory === get(item, 'value', '')}
              onClick={() => handleFilterCategory(get(item, 'value', ''))}
            >
              <S.IconWrapper>{item.icon}</S.IconWrapper>
              <span className="recipe-category-label">{get(item, 'label', '')}</span>
            </S.RecipeCategory>
          );
        })}
      </S.RecipeCategories>
      <S.HeaderList>
        <S.RecipesShowByOwner>
          <S.AllRecipes
            active={RECIPE_HEADER_TABS.ALL_RECIPE === tab && !isShowDraft}
            onClick={() => handleSelectShowByShare(RECIPE_HEADER_TABS.ALL_RECIPE)}
          >
            All Published Recipes
          </S.AllRecipes>
          <S.YourRecipes
            active={RECIPE_HEADER_TABS.YOUR_RECIPE === tab && !isShowDraft}
            onClick={() => handleSelectShowByShare(RECIPE_HEADER_TABS.YOUR_RECIPE)}
          >
            Your Published Recipes ({myRecipeTotal})
          </S.YourRecipes>
        </S.RecipesShowByOwner>
        <S.ActionsRight>
          <S.ButtonShowDraft isShowDraft={isShowDraft} onClick={() => handleShowDraft(isShowDraft)}>
            <S.ButtonContent>Drafts ({totalDraft})</S.ButtonContent>
          </S.ButtonShowDraft>
          <ListSorter
            sortBy={sortBy}
            showSortPopup={showSortPopup}
            setShowSortPopup={setShowSortPopup}
            handleSelectSort={handleSelectSort}
          />
        </S.ActionsRight>
      </S.HeaderList>
    </S.RecipesHeaderContainer>
  );
};

const mapStateToProps = state => {
  const { user, rootReducer } = state;

  return {
    user,
    trialMode: rootReducer.pricing.getIn(['teamData', 'trialMode']),
    addOnMealPlan: rootReducer.pricing
      .get('addOnsPricing')
      .toJS()
      .find(it => it.code === ADD_ONS_PRICING_CODE.MEAL_PLAN),
    loadingPricing: rootReducer.pricing.get('loadingPricing'),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipesHeader);
