import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { changeClientListQueryParams, selectClientResponse } from 'redux/form-responses/actions';
import SearchIcon from 'assets/icons/search_grey_thin.svg';
import NoResults from 'assets/icons/form-responses-no-results.png';
import { NewSearchInput } from 'shared/SearchInput';
import LoadingIndicator from 'shared/LoadingIndicator';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

import * as S from './styles';

const formatYear2Digits = dateFormat => {
  if (dateFormat.indexOf('YYYY') >= 0) {
    return dateFormat.replace('YYYY', 'YY');
  }
  return dateFormat;
};

const Client = ({
  data,
  index,
  isLast,
  selectClientResponse,
  isSelected,
  timezone,
  dateFormat,
  resetHistoryState,
  cloudfrontList,
}) => {
  const clientId = get(data, 'client');
  const color = get(data, 'client_color');
  const clientName = get(data, 'client_full_name', '');

  const handleClick = data => () => {
    resetHistoryState();
    window.history.replaceState({}, '', `/home/forms/${data.form}/responses`);
    selectClientResponse(data);
  };

  return (
    <>
      <S.ClientContainer
        onClick={handleClick(data)}
        className={classNames({ active: isSelected })}
        data-id={`client-${index}`}
        id={clientId}
      >
        <Avatar
          name={clientName}
          className="client-avatar"
          size="40"
          src={convertS3UrlToCloudFrontUrl(data.client_avatar, cloudfrontList, true)}
          color={color}
        />
        <div className="client-name">{clientName}</div>
        <div className="last-answered-at">
          {moment(data.last_answered_at)
            .tz(timezone)
            .format(formatYear2Digits(dateFormat) || 'MM/DD/YY')}
        </div>
      </S.ClientContainer>
      {!isLast && <S.Breaker />}
    </>
  );
};

function ClientsList({
  clientsList,
  selectClientResponse,
  workingClient,
  loading,
  changeClientListQueryParams,
  isEnd,
  query,
  formId,
  timezone,
  dateFormat,
  clientId,
  resetHistoryState,
  cloudfrontList,
}) {
  const id = get(workingClient, 'client');
  const [workingClientId, setWorkingClientId] = useState(clientId);
  useEffect(() => {
    id && setWorkingClientId(id);

    // auto focus client selected into view
    const selectedClient = document.getElementById(id);
    if (selectedClient) {
      selectedClient.scrollIntoView({ block: 'nearest' });
    }
  }, [id]);

  const workingFormId = formId || get(workingClient, 'form');

  const handleSearchChange = (event, { value }) => {
    changeClientListQueryParams(workingFormId, { text_search: value, page: 1 });
  };
  const handleClearSearch = () => {
    changeClientListQueryParams(workingFormId, { text_search: '', page: 1 });
  };

  const handleScrollEnd = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && !isEnd && !loading) {
      changeClientListQueryParams(workingFormId, { page: query.page + 1 });
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);
  return (
    <S.Wrapper>
      <S.SearchContainer>
        <NewSearchInput
          placeholder="Search by name"
          className="client-list-search"
          onChange={debounce(handleSearchChange, 300)}
          onClearSearch={handleClearSearch}
          searchIconPath={SearchIcon}
        />
      </S.SearchContainer>
      <S.ClientsList
        onScroll={event => {
          event.persist();
          onScrollDebounce.call(null, event);
        }}
      >
        {clientsList.map((client, index) => (
          <Client
            isSelected={client.client === workingClientId}
            timezone={timezone}
            dateFormat={dateFormat}
            data={client}
            index={index}
            isLast={index === clientsList.length - 1}
            key={client._id}
            selectClientResponse={selectClientResponse}
            resetHistoryState={resetHistoryState}
            cloudfrontList={cloudfrontList}
          />
        ))}
        {loading && <LoadingIndicator className="loading-indicator" />}
      </S.ClientsList>
      {!loading && clientsList.length === 0 && query.text_search !== '' && (
        <S.EmptyContainer>
          <div className="img-container">
            <img src={NoResults} alt="" />
          </div>
          <div className="text">No results found.</div>
        </S.EmptyContainer>
      )}
    </S.Wrapper>
  );
}

const mapState = state => {
  const { formResponses } = state.rootReducer;
  return {
    cloudfrontList: state.cloudfrontList,
    timezone: state.user.timezone,
    dateFormat: state.user.date_format,
    clientsList: formResponses.clientsList,
    isEnd: formResponses.isEnd,
    workingClient: formResponses.workingClient,
    loading: formResponses.loading,
    query: formResponses.clientsQuery,
  };
};

const mapDispatch = dispatch => {
  return {
    selectClientResponse: bindActionCreators(selectClientResponse, dispatch),
    changeClientListQueryParams: bindActionCreators(changeClientListQueryParams, dispatch),
  };
};

export default connect(mapState, mapDispatch)(ClientsList);
