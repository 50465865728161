const beforeLabel = () => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    content: '"Group: "',
    display: 'block',
    fontWeight: 700,
    marginLeft: 10,
  },
});

export const selectStyles: StylesConfig = {
  multiValue: (styles, res) =>
    res.data.typeName === 'group' && {
      ...styles,
      ...beforeLabel(),
    },
};
