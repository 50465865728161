import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import * as S from './style';
import { loadMoreStudioResource } from 'redux/studio-resource/actions';
import StudioResourceHeading from '../Heading';
import ResourceItem from './ResourceItem';
import CollectionArea from './CollectionArea';
import AddCollectionFormPopup from 'components/StudioCollectionList/components/AddCollectionFormPopup';
import { toggleModal } from 'actions/modal';
import AddResourceFormPopup from '../AddResourceFormPopup';
import LoadingIndicator from 'shared/LoadingIndicator';

class ResourceList extends React.Component {
  constructor(props) {
    super(props);
    this.handleScrollDebounce = _.debounce(this.handleScrollDebounce, 300);
    this.listRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const { clientWidth } = this.listRef.current;
    this.setState({ clientWidth });
  }

  handleScroll = event => {
    event.persist();
    this.handleScrollDebounce.call(null, event);
  };

  handleScrollDebounce = event => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    const { list, total, loadMore, isSearching } = this.props;
    const bottom = scrollWidth - (scrollLeft + clientWidth);
    if (list.length < total && bottom < 200 && !isSearching) {
      loadMore();
    }
  };

  getMaxColumn = () => {
    if (this.listRef.current) {
      const { sideBarVisible } = this.props;
      const { clientWidth } = this.state;
      const sideBarWidth = sideBarVisible ? 0 : 230;
      const maxColumn = _.floor((clientWidth + sideBarWidth) / 270);
      return maxColumn;
    }

    return 4;
  };

  onCreateNewCollection = () => {
    this.props.toggleModal(true, <AddCollectionFormPopup />);
  };

  onGoDetail = resource => {
    this.props.toggleModal(
      true,
      <AddResourceFormPopup source_type={resource.source_type} defaultResource={resource} />,
    );
  };

  render() {
    const { list, isSearching, query } = this.props;
    const maxColumn = this.getMaxColumn();
    const isScroll = list.length > maxColumn * 4;

    return (
      <S.Wrapper>
        <StudioResourceHeading banner />
        <div className="resourceList__heading">
          <div>Resources</div>
        </div>
        {!isSearching && !list.length ? (
          <S.NoResult>{query.search ? 'No Resources' : 'Add your first resource'}</S.NoResult>
        ) : null}
        <S.List
          maxColumn={maxColumn}
          isScroll={isScroll}
          ref={this.listRef}
          onScroll={this.handleScroll}
          isLoading={isSearching}
        >
          {isSearching ? (
            <S.LoadingWrapper>
              <LoadingIndicator size="medium" title="Loading resources" className="loading--resources" />
            </S.LoadingWrapper>
          ) : (
            <>
              {list.map((item, index) => (
                <ResourceItem id={`resource-${index}`} key={item._id} resource={item} onGoDetail={this.onGoDetail} />
              ))}
            </>
          )}
        </S.List>
        <CollectionArea onCreateNewCollection={this.onCreateNewCollection} />
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      studioResource: { list, total, isSearching, query },
    },
    sideBarVisible,
  } = state;
  return { list, total, isSearching, sideBarVisible, query };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  loadMore: bindActionCreators(loadMoreStudioResource, dispatch),
});

export default connect(mapState, mapDispatch)(ResourceList);
