import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLabelAndDuration } from './commonFunction';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';

import * as S from './style';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

function ResourceMobilePreviewItem(props) {
  const { resource, cloudfrontList } = props;

  return (
    <S.ResourceItemWrapper coverImage={convertS3UrlToCloudFrontUrl(resource.background, cloudfrontList, true)}>
      <div className={`previewResource__avatar`}>
        <div className="overlay"></div>
      </div>
      <div className="previewResource__infoArea">
        <div className="previewResource__info">{resource.title}</div>
        <div className="previewResource__labelAndDuration">{getLabelAndDuration(resource)}</div>
      </div>

      {resource.type.includes('video') && (
        <div className="previewResource__playIcon">
          <PlayIcon />
        </div>
      )}
    </S.ResourceItemWrapper>
  );
}

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapState, mapDispatchToProps)(ResourceMobilePreviewItem);
