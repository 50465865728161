import styled, { css } from 'styled-components';

export const Status = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c4c4c4;
  margin-right: 15px;
  flex: 0 0 10px;

  &.active {
    background-color: #2fd787;
  }
`;

export const Name = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CountItem = styled.div`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  color: #979797;
  text-align: center;
  display: inline-block;

  .num {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 5px;
    color: #2a394c;
    text-align: center;
  }

  &.paused {
    .num {
      color: #ffa31d;
    }
  }

  &.active {
    .num {
      color: #1eae6a;
    }
  }

  &.waiting {
    .num {
      color: #c4c4c4;
    }
  }
`;

export const CountClients = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1;

  ${CountItem} {
    margin-left: 26px;
    text-align: right;

    ::before {
      content: '';
      position: absolute;
      bottom: 3px;
      left: -13px;
      z-index: 1;
      background-color: #979797;
      border-radius: 50%;
      width: 3px;
      height: 3px;
    }

    :first-child {
      margin-left: 0;
      ::before {
        display: none;
      }
    }
  }
`;

export const AutoflowNameContainer = styled.div`
  .autoflow__name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2a394c;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;

    :hover {
      color: #494699;
    }
  }
`;

export const Container = styled.div`
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  margin-right: -15px;

  .table-container {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      margin-top: 50px;
    }
  }

  ${props =>
    props.isSetMinHeight &&
    css`
      .table-container {
        min-height: calc(100vh - 220px);
      }
    `}

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    font-size: 13px;
    letter-spacing: normal;
    color: #2a394c;
    width: 100%;
    position: relative;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;

    .autoflow-list-tooltip {
      min-width: 80px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
    }

    tr {
      th {
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6a778a;
        height: 0;
        position: sticky;
        text-align: left;
        top: 0;
        background-color: #fff;
        z-index: 2;
        padding: 0;

        &.name {
          width: 330px;
        }

        &.type {
          width: 400px;
        }

        .table__header__content {
          padding: 20px 15px;
          width: 100%;
          border-bottom: 2px solid #f6f5f8;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 15px;
          color: #6a778a;
          white-space: nowrap;

          .column-icon {
            margin-right: 5px;
          }

          &.hidden {
            color: transparent;
            pointer-events: none;
            user-select: none;
          }

          i.icon {
            margin: 0;
            margin-left: 7px;
            font-size: 9px;
            line-height: 11px;

            &.active {
              color: #716ae7;
            }
          }

          .header {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        &.action {
          width: 100px;
        }
      }

      td {
        text-align: left;
        padding: 14px 15px;
        border-bottom: 1px solid #e8e8e7;

        &.action {
          text-align: right;

          .icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .sharing-status .__react_component_tooltip.app-tooltip-white {
            min-width: fit-content;
          }
          .more-options-wrapper {
            margin-left: 18px;
          }
          .ui.dropdown {
            .menu {
              background: #2d2e2d;
              top: calc(100% + 4px);

              .item {
                color: #fff;

                :hover {
                  background: #696969;
                }
              }

              .item__content {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #fff;

                img {
                  margin-right: 5px !important;
                }
              }
            }

            &.upward {
              .menu {
                top: unset;
                bottom: calc(100% + 4px);
              }
            }

            &.active.visible {
              .actions-trigger {
                background-color: #5c5bbd;

                .dot,
                .dot::after,
                .dot::before {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        :hover {
          background-color: #fafbfc;
        }
      }
      .clients .num {
        padding-top: 4px;
        margin-bottom: 4px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0;
`;

export const TypeColumnContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;

  .__react_component_tooltip.app-tooltip {
    padding: 7px 13px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
  }
`;

export const TagType = styled.div`
  padding: 4px 8px;
  background: #f1f1f1;
  border-radius: 3px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #818da1;
  white-space: nowrap;
`;

export const WithSetting = styled.div`
  background: #f1f1f1;
  border-radius: 50%;
  margin-left: 4px;
  flex: 0 0 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
