/**
 * @flow
 */

import React from 'react';
import './styles.scss';
import { Image } from 'semantic-ui-react';

export default class ImageButton extends React.Component<Props, State> {
  handleClick(e) {
    e.stopPropagation();
    const { onClick } = this.props;

    if (onClick && typeof onClick === 'function') {
      onClick(e);
    }
  }

  handleOutsideClick(e) {
    const { onClick, isPreventOutsideClick } = this.props;

    if (!isPreventOutsideClick && onClick && typeof onClick === 'function') {
      onClick(e);
    }
  }

  render() {
    const { title, boldTitle, image, imageOnRight } = this.props;
    return (
      <div
        style={this.props.style}
        onClick={this.handleOutsideClick.bind(this)}
        className={`${this.props.className} image-button`}
      >
        {!imageOnRight && <Image src={image} onClick={this.handleClick.bind(this)} />}
        <div onClick={this.handleClick.bind(this)}>
          {title}
          {boldTitle && <b> {boldTitle}</b>}
        </div>
        {imageOnRight && <Image src={image} onClick={this.handleClick.bind(this)} />}
      </div>
    );
  }
}
