import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import FormItemInput from 'shared/FormItemInput';

export const Button = styled(ButtonIcon)`
  ${props => props.disabled && `cursor: not-allowed;`}
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }

  &.buttonClassName {
    button {
      margin-right: 0;
      &:hover,
      &:focus,
      &:active {
        background: #5559ff;
      }

      :disabled {
        background: #c4c4c4 !important;
      }
    }
  }

  ${props =>
    props.disabledOwner &&
    css`
      cursor: not-allowed !important;
    `}
`;

export const CreateAddNewModal = styled(Modal)`
  &.ui.modal {
    width: 452px;
  }

  .header {
    color: #202353 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    border-radius: 8px;
  }

  .content {
    padding: 25px 15px 0px 30px !important;
  }

  .formControl {
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .submit {
    padding: 7px 29px !important;

    &:hover,
    &:focus,
    &:active {
      background: #5559ff !important;
    }
  }
  .load-more {
    padding-top: 26px;
    padding-bottom: 1rem;
    .ui.small.loader:before {
      border-color: rgb(142 142 142 / 15%);
    }
    .ui.small.loader:after {
      border-color: #5158cf transparent transparent;
    }
  }
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-top: 4px;
  margin-bottom: 0;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  ${props =>
    props.hasLabel &&
    css`
      justify-content: space-between;
    `}
`;

export const CreateAddNewDialog = styled(CreateAddNewModal)`
  &.ui.modal {
    width: 650px;
  }

  .cancel {
    width: 112px;
    height: 40px;
    color: #151619;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-right: 12px;
  }

  .submit {
    width: 112px;
    height: 40px;
    background-color: #5e59ff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
  }
  .submit:disabled {
    pointer-events: none;
    background-color: #c4c4c4;
  }

  .search-input {
    width: 265px;
    height: 30px;
    input {
      background-color: #fff;
      border: 1px solid #e0e1e4;

      ::placeholder {
        color: #777777;
      }

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #7072dd;
      }
    }
  }

  ${Description} {
    margin-top: 5px;
    color: #323c47;
  }

  ${Footer} {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 6px;

  .label__required {
    color: #ea314a;
  }
`;

export const Form = styled.form``;

export const Input = styled(FormItemInput)``;

export const SActions = styled(Modal.Actions)`
  padding: 10px 30px !important;
  background: #ffffff !important;
  border-top: none !important;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
`;

export const SHeader = styled(Modal.Header)`
  padding: 20px 15px 30px 30px !important;
`;

export const FooterLabelWrapper = styled.div`
  display: flex;
  justify-items: center;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`;

export const FooterCreateNewWorkoutLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7b82a4;
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-top: 20px;
  .filter-options {
    margin-left: 5px;
    .ui.button {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export const SearchContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
`;

export const CounterFilters = styled.div`
  right: -6px;
  top: -10px;
  width: 22px;
  height: 22px;
  line-height: 17px;
  border: 3px solid rgb(255, 255, 255);
  position: absolute;
  background: #e54d2e;
  border-radius: 50%;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
`;
