import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import diff from 'deep-diff';
import classnames from 'classnames';
import { Image, List } from 'semantic-ui-react';
import { logout } from 'actions/auth/login';
import { push } from 'connected-react-router';
import Sidebar from 'react-sidebar';
import { detachCurrentUser } from 'redux/inbox-new/actions';

import { onCutStringEllipsis } from 'helpers/string';

import styles, {
  SidebarItemContainer,
  SubMenuContainer,
  MainAppContainer,
  LeftColumnContainer,
  SidebarContent,
  MobileSidebarTrigger,
  SubMenuHeader,
  CopyLinkContainer,
} from './styles';
import './styles.scss';
import Avatar from 'react-avatar';
import Support from 'components/Support';
import 'url-search-params-polyfill';
import SegmentSidebar from 'components/SegmentSidebar';
// import AppNotification from 'components/AppNotification';
import AppNotificationSidebar from 'components/AppNotificationSidebar';
import {
  getUserShortName,
  getLastLibraryRoute,
  getLastAutomationRoute,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import Onboarding from 'components/Onboarding';
import DownloadCoachAppButton from './DownloadCoachApp';
import { isOnwer } from 'utils/validations';
import UpgradePath from 'shared/UpgradePath';
import TeammatesMemberFallback from 'shared/UpgradePath/components/TeammatesMemberFallback';
import UpgradeButton from './UpgradeButton';
import PaymentOverdueWarning from './components/PaymentOverdueWarning';
import GlobalSearchClients from '../../../components/GlobalSearchClients';
import StudioSidebar from './components/StudioSidebar';
import TaskReminder from '../../../components/TaskReminder';
import SidebarForum from './components/SidebarForum';
import DownloadAppBanner from 'shared/DownloadAppBanner';
import { hideTheMobileReminder } from 'utils/event-api';

import SidebarItem from './components/SidebarItem';
import { SidebarContext } from 'database/contexts';
import Routes from './routes';
import { ReactComponent as ReferralIcon } from 'assets/icons/route_referal.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/route_teammate.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/route_setting.svg';
import { ReactComponent as BillingIcon } from 'assets/icons/route_billing.svg';
import { ReactComponent as CustomBrandingIcon } from 'assets/icons/route_paint.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/right_arrow_grey.svg';
import { ReactComponent as IntegrationIcon } from 'assets/icons/integration_icon.svg';

import { ReactComponent as Bumble } from 'assets/icons/m_bumble.svg';
import MobileSidebar from './components/MobileSidebar';
import { isMobileSidebarActive } from './components/MobileSidebar/helper';
import { ReactComponent as CloseIcon } from 'assets/icons/close_current_color.svg';
import { ReactComponent as HumburgerIcon } from 'assets/icons/humburger.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back_current_color.svg';
import { SIDEBAR_BACK_TO_PARENT_EVENT, WAITING_SEGMENT_NAME, CDN_URL } from 'constants/commonData';
import CopyToClipboard from 'shared/CopyToClipboard';
import WaitingPopup from 'components/WaitingActivationClients/WaitingPopup';
import PaymentSidebar from './components/PaymentSidebar';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/learn-about-package.svg';
import AutomationSidebar from './components/AutomationSidebar';
import { isOwnerOrAdminOrSubTrainer } from '../../../utils/commonFunction';
import VerifyEmailAlert from './components/VerifyEmailAlert';
import PaymentOverdueWhiteLabelAlert from './components/PaymentOverdueWhiteLabelAlert';

const INVITE_LINK = process.env.REACT_APP_INVITE_LINK;

const CLIENT_DEFAULT_TOOLTIP = {
  title: 'All Clients',
  route: '/home/client',
};

const DEFAULT_SEGMENTS = [
  'Connected',
  'Pending',
  'Offline',
  'Need Attention',
  WAITING_SEGMENT_NAME,
  'Need Programming',
  'Your Entire Team',
];
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverSupport: false,
      isMenuExpand: true,
      mobileZoomRatio: isMobile ? this.calculateRatio() : 1,
      showDownloadBanner: isMobile && localStorage.getItem('mobileReminder') === 'show',
      shouldShowMobileSidebarPanel: false,
      currentSelectedForum: {},
      forumList: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentSelectedForum, location } = nextProps;
    let { forumList } = this.state;
    const { pathname } = location;

    if (
      pathname.indexOf('post-item') > -1 ||
      pathname.indexOf('discussion') > -1 ||
      pathname.indexOf('settings') > -1 ||
      pathname.indexOf('members') > -1 ||
      pathname.indexOf('leaderboard') > -1 ||
      pathname.indexOf('upcoming') > -1
    ) {
      if (!_.isEqual(this.state.currentSelectedForum, currentSelectedForum)) {
        forumList.map(item => item._id === currentSelectedForum._id && (item.banner = currentSelectedForum.banner));
        this.setState({ forumList });
      }
      this.setState({ currentSelectedForum });
    } else {
      this.setState({ currentSelectedForum: {} });
    }

    this.setState({ forumList: nextProps.forumList });
  }

  componentDidMount() {
    this.props.checkDueTask();
    this.props.getOnboardingCheckList();
    this.props.getListGroup();
    this._isMounted = true;
    window.addEventListener('resize', this.updateZoomRatio);

    const { activeItem, isTeamAdmin } = this.props;

    if (isMobileSidebarActive(activeItem, isTeamAdmin) && !this.state.shouldShowMobileSidebarPanel) {
      this.setState({ shouldShowMobileSidebarPanel: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { activeItem, isTeamAdmin, cloudfrontList, getCloudfrontList } = this.props;
    const isActive = isMobileSidebarActive(activeItem, isTeamAdmin);

    if (
      (!isActive && prevProps.activeItem !== activeItem) ||
      (!this.props.sideBarVisible && prevProps.sideBarVisible && this.state.shouldShowMobileSidebarPanel)
    ) {
      this.setState({ shouldShowMobileSidebarPanel: false });
    } else if (isActive && this.props.sideBarVisible && !this.state.shouldShowMobileSidebarPanel) {
      this.setState({ shouldShowMobileSidebarPanel: true });
    }

    // refresh cloudfront if expired have 5s remaining
    if (cloudfrontList.length) {
      const [firstCloudFront] = cloudfrontList;
      const now = new Date();
      const cloudExpiredTime = new Date(firstCloudFront.expired_time);
      const remaining = (cloudExpiredTime - now) / 1000;
      if (remaining < 5) {
        getCloudfrontList(true);
      }
    }

    // const postsChange =
    //   _.get(this.props, 'posts', []).length &&
    //   _.get(prevProps, 'posts', []).length !== _.get(this.props, 'posts', []).length;

    // if (postsChange) {
    //   this.props.getListGroup().then(res => {
    //     const { data } = res;
    //     const sortedList = data.data.slice().sort((a, b) => b.date - a.date);
    //     this.setState({ forumList: sortedList });
    //   });
    // }
    // // TODO
    // if (diff(this.props.forumList, this.state.forumList)) {
    //   this.props.getListGroup();
    // }

    // Update state forum list
    const diffCurrentSelectedForum = diff(prevProps.currentSelectedForum, this.props.currentSelectedForum);
    if (diffCurrentSelectedForum && !_.isEmpty(this.props.currentSelectedForum)) {
      this.props.getListGroup().then(res => {
        const forumList = _.get(res, 'data.data', []);
        const sortedList = forumList.sort((a, b) => b.date - a.date);
        this.setState({ forumList: sortedList });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateZoomRatio);
  }

  calculateRatio = () => {
    try {
      return 980 / window.screen.width;
    } catch {
      return 1;
    }
  };

  updateZoomRatio = () => {
    if (isMobile && this._isMounted) {
      this.setState({ mobileZoomRatio: this.calculateRatio() });
    }
  };

  onCloseDownloadBanner = () => {
    window.removeEventListener('resize', this.updateZoomRatio);
    hideTheMobileReminder();
    this.setState({ mobileZoomRatio: 1, showDownloadBanner: false });
  };

  handleClickMobileSidebarTrigger = () => {
    this.setState(
      prev => ({ shouldShowMobileSidebarPanel: !prev.shouldShowMobileSidebarPanel }),
      () => {
        const { shouldShowMobileSidebarPanel } = this.state;

        if (shouldShowMobileSidebarPanel) {
          if (!this.props.sideBarVisible) {
            this.props.toggleSideBar(true);
          }
        } else {
          if (this.props.sideBarVisible) {
            this.props.toggleSideBar(false);
          }
        }
      },
    );
  };

  onBackToParentMenu = () => {
    window.dispatchEvent(new CustomEvent(SIDEBAR_BACK_TO_PARENT_EVENT, { bubbles: true }));
  };

  render() {
    const {
      activeItem,
      sideBarVisible,
      higherHeader,
      paymentOverdue,
      user,
      isTeamAdmin,
      permission,
      pageTitle,
      isPaymentAddOn,
      total,
      totalProduct,
      mediumNavbar,
      zapierOverdue,
      addOnsOverdue,
      totalMP,
      paymentWhiteLabelOverdue,
    } = this.props;
    const { mobileZoomRatio, showDownloadBanner, shouldShowMobileSidebarPanel } = this.state;
    const { is_verified_email, force_send_verification_email_at } = user || {};

    const isInboxView = this.props.path.startsWith('/home/inbox');
    const isStudioCollection = this.props.path.startsWith('/home/studio-collection');
    const isPricingPlan = activeItem === 'pricing-plan' ? true : false;
    const mobileSidebarActive = isMobileSidebarActive(activeItem, isTeamAdmin);
    let classMain = styles.mainContainer;

    if (higherHeader) {
      classMain = Object.assign({}, classMain, styles.contentWidthHigherNavbar);
    }

    if (mediumNavbar) {
      classMain = Object.assign({}, classMain, styles.contentWidthMediumNavbar);
    }

    const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin;
    const paymentOverdueHeight = isOverdue ? 40 : 0;

    let topSpacing = 0;

    if (showDownloadBanner) {
      topSpacing = 59 * mobileZoomRatio;
    }

    const isShowWhiteLabelOverdue = paymentWhiteLabelOverdue;
    const isShowOverdue = !isShowWhiteLabelOverdue && isOverdue;
    const isVerifyEmail =
      !isShowWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

    return (
      <div
        style={styles.rootContainer}
        className={!sideBarVisible || activeItem === 'inbox' ? 'app-sidebar-collapse' : 'app-sidebar-expand'}
      >
        <Helmet>
          <title>{pageTitle ? `${pageTitle} - Everfit` : 'Everfit'}</title>
        </Helmet>
        {showDownloadBanner && <DownloadAppBanner ratio={mobileZoomRatio} onClose={this.onCloseDownloadBanner} />}
        {!isPricingPlan && this.renderLeftColumn(topSpacing)}
        <UpgradePath pathName="team" isMember fallback={<TeammatesMemberFallback />}>
          <Sidebar
            sidebar={
              <React.Fragment>
                {(shouldShowMobileSidebarPanel || mobileSidebarActive) && (
                  <MobileSidebar
                    activeItem={activeItem}
                    permission={permission}
                    isTeamAdmin={isTeamAdmin}
                    user={user}
                    isPaymentAddOn={isPaymentAddOn}
                    total={total}
                    totalMP={totalMP}
                    totalProduct={totalProduct}
                  />
                )}
                {this.renderSidebarContent()}
              </React.Fragment>
            }
            sidebarClassName="app-sidebar"
            contentClassName={classnames('app-content-custom', {
              overdue: isShowOverdue,
              'white-label-overdue': isShowWhiteLabelOverdue,
              'verification-email': isVerifyEmail,
            })}
            rootClassName={classnames({ overlay: isStudioCollection })}
            styles={{
              sidebar: {
                backgroundColor: '#ffffff',
                transition: 'transform .1s ease-out',
                WebkitTransition: '-webkit-transform .1s ease-out',
                top: `${topSpacing}px`,
                transformStyle: 'preserve-3d',
                zIndex: 3,
              },
              root: {
                left: '60px',
                top: `${topSpacing}px`,
                backgroundColor: !sideBarVisible || isInboxView ? '#5C58C0' : 'transparent',
              },
              content: {
                backgroundColor: '#ffffff',
                overflowY: 'hidden',
                transition: 'left .1s ease-out, right .1s ease-out',
              },
            }}
            docked={sideBarVisible}
          >
            {isShowWhiteLabelOverdue && <PaymentOverdueWhiteLabelAlert />}
            {isShowOverdue && <PaymentOverdueWarning />}
            {isVerifyEmail && <VerifyEmailAlert targetDate={force_send_verification_email_at} />}
            {this.renderHeader(isInboxView)}
            <MainAppContainer
              style={classMain}
              paymentOverdueHeight={paymentOverdueHeight}
              isInboxView={isInboxView}
              className="app-main-content"
            >
              {isOnwer(user) ? (
                this.props.children
              ) : (
                <UpgradePath pathName="team" fallback={<TeammatesMemberFallback />}>
                  {this.props.children}
                </UpgradePath>
              )}
            </MainAppContainer>
          </Sidebar>
        </UpgradePath>
        <Onboarding />
        {this.renderModal()}
        {this.renderSecondModal()}
        {this.renderConfirmModal()}
        {this.renderSuccessModal()}
        {this.renderErrorModal()}
        <WaitingPopup />
      </div>
    );
  }

  renderSidebarContent() {
    const { forumList } = this.state;
    const { activeItem, parrent, isTeamAdmin, permission, user, isEnableZapier, generalSettings } = this.props;
    const inviteLink = `${INVITE_LINK}/${user.referral_code}`;
    const copyLabel = `${INVITE_LINK}/${user.referral_code}`;

    const isAutoFlowOrOnboarding = activeItem.startsWith('onboarding-flow') || activeItem.startsWith('autoflow');
    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;
    const hasPermissionAutoflowOrTeamAdmin = hasPermissionMultiple || isTeamAdmin;

    if (activeItem === 'client') {
      return (
        <SidebarContent className="sidebar-content">
          <div className="sidebar-divide" />
          <SubMenuHeader>Clients</SubMenuHeader>
          <SegmentSidebar />
          <CopyLinkContainer>
            <div className="title">Your invite link</div>
            <CopyToClipboard value={inviteLink} copyLabel={copyLabel} />
          </CopyLinkContainer>
        </SidebarContent>
      );
    } else if (activeItem.indexOf('library') > -1) {
      return (
        <SidebarContent className="sidebar-content">
          <div className="sidebar-divide" />
          <SubMenuHeader>Library</SubMenuHeader>
          <List className="menu-list">
            <div
              className={classnames('menu-list-item', { active: activeItem === 'exercise_library' })}
              onClick={() => {
                this.props.dispatch(push('/home/exercise'));
              }}
            >
              <span>Exercises</span>
            </div>
            <div
              className={classnames('menu-list-item', { active: activeItem === 'workout_library' })}
              onClick={() => {
                this.props.dispatch(push('/home/workout'));
              }}
            >
              <span>Workouts</span>
            </div>
            {process.env.REACT_APP_ENABLE_SECTION_LIBRARY || permission.training_section_library ? (
              <SubMenuContainer>
                <div
                  className={classnames('menu-list-item', { active: activeItem === 'section_library' })}
                  onClick={() => {
                    this.props.dispatch(push('/home/sections'));
                  }}
                >
                  <span>Sections</span>
                </div>
              </SubMenuContainer>
            ) : null}

            <div
              className={classnames('menu-list-item', { active: activeItem === 'program_library' })}
              onClick={() => {
                this.props.dispatch(push('/home/program'));
              }}
            >
              <span>Programs</span>
            </div>

            {this.props.isTaskLibraryPermission && process.env.REACT_APP_ENABLE_TASKS_LIBRARY ? (
              <div
                className={classnames('menu-list-item', { active: activeItem === 'task_library' })}
                onClick={() => {
                  this.props.dispatch(push('/home/task-library'));
                }}
              >
                <span>Tasks</span>
              </div>
            ) : null}

            {(this.props.isFormPermission && process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES) ||
            process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH ? (
              <div
                className={classnames('menu-list-item', { active: activeItem === 'forms_library' })}
                onClick={() => {
                  this.props.dispatch(push('/home/forms'));
                }}
              >
                <span>Forms & Questionnaires</span>
              </div>
            ) : null}
            <div
              className={classnames('menu-list-item', { active: activeItem === 'meal_plans_library' })}
              onClick={() => {
                this.props.dispatch(push('/home/meal-plans'));
              }}
            >
              <span>Meal Plan Templates</span>
            </div>
            <SubMenuContainer>
              <div
                className={classnames('menu-list-item', { active: activeItem === 'recipes_library' })}
                onClick={() => {
                  this.props.dispatch(push('/home/recipes'));
                }}
              >
                <span>Recipes</span>
              </div>
            </SubMenuContainer>
            <SubMenuContainer>
              <div
                className={classnames('menu-list-item', { active: activeItem === 'ingredient_library' })}
                onClick={() => {
                  this.props.dispatch(push('/home/ingredients'));
                }}
              >
                <span>Ingredients</span>
              </div>
            </SubMenuContainer>
            <div
              className={classnames('menu-list-item', { active: activeItem === 'metric_groups_library' })}
              onClick={() => {
                this.props.dispatch(push('/home/metric-group'));
              }}
            >
              <span>Metric Groups</span>
            </div>
          </List>
          <div className="spacer" />
          {((this.props.isFormPermission && process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES) ||
            process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) &&
            activeItem === 'forms_library' &&
            this.props.isHideFormBanner && (
              <a
                className="guide-links"
                href="https://help.everfit.io/en/articles/6633667-how-to-create-forms-questionnaires"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreIcon />
                <p>Learn more about Forms</p>
              </a>
            )}
          {_.get(permission, 'meal_plan') &&
            ['meal_plans_library', 'recipes_library', 'ingredient_library'].includes(activeItem) &&
            _.get(generalSettings, 'is_hide_meal_plan_banner') && (
              <a
                className="guide-links"
                href="https://help.everfit.io/en/collections/7747089-meal-plans-and-recipes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreIcon />
                <p>Learn more about Meal Plans</p>
              </a>
            )}
        </SidebarContent>
      );
    } else if (activeItem.indexOf('setting') > -1) {
      return (
        <SidebarContent className="sidebar-content settings" fixedOnMobile={isTeamAdmin}>
          <SubMenuHeader>
            {isTeamAdmin && <BackIcon className="back" onClick={this.onBackToParentMenu} />}
            <span>Settings</span>
          </SubMenuHeader>
          <List className="menu-list">
            <div
              className={'menu-list-item parrent'}
              onClick={() => {
                if (isTeamAdmin) {
                  if (parrent !== 'your_account') {
                    this.props.dispatch(push('/home/accountdetails'));
                    this.setState({ isMenuExpand: true });
                  } else {
                    this.setState({ isMenuExpand: !this.state.isMenuExpand });
                  }
                }
              }}
            >
              <span>Your Account</span>
              {this.state.isMenuExpand && parrent === 'your_account' ? (
                <Image width={17} height={6} style={{ left: 5 }} src={`${CDN_URL}/images/menu_expand.svg`} />
              ) : (
                <Image width={6} height={17} src={`${CDN_URL}/images/menu_collapse.svg`} />
              )}
            </div>
            {this.state.isMenuExpand && parrent === 'your_account' && (
              <SubMenuContainer>
                <div
                  className={classnames('menu-list-item', {
                    active: activeItem === 'account_details_setting',
                  })}
                  onClick={() => {
                    this.props.dispatch(push('/home/accountdetails'));
                  }}
                >
                  <span>Account Details</span>
                </div>
                <div
                  className={classnames('menu-list-item', { active: activeItem === 'notifications_setting' })}
                  onClick={() => {
                    this.props.dispatch(push('/home/notifications'));
                  }}
                >
                  <span>Notifications</span>
                </div>
                <div
                  className={classnames('menu-list-item', {
                    active: activeItem === 'default-client-settings',
                  })}
                  onClick={() => {
                    this.props.dispatch(push('/home/default-client-settings'));
                  }}
                >
                  <span>Default Settings</span>
                </div>
              </SubMenuContainer>
            )}
            {isTeamAdmin && (
              <>
                <div
                  className={'menu-list-item parrent'}
                  onClick={() => {
                    if (parrent !== 'work_space') {
                      this.props.dispatch(push('/home/generalsettings'));
                      this.setState({ isMenuExpand: true });
                    } else {
                      this.setState({ isMenuExpand: !this.state.isMenuExpand });
                    }
                  }}
                >
                  <span>Workspace</span>
                  {this.state.isMenuExpand && parrent === 'work_space' ? (
                    <Image width={17} height={6} style={{ left: 5 }} src={`${CDN_URL}/images/menu_expand.svg`} />
                  ) : (
                    <Image width={6} height={17} src={`${CDN_URL}/images/menu_collapse.svg`} />
                  )}
                </div>
                {this.state.isMenuExpand && parrent === 'work_space' && (
                  <SubMenuContainer>
                    <div
                      className={classnames('menu-list-item', { active: activeItem === 'general_settings' })}
                      onClick={() => {
                        this.props.dispatch(push('/home/generalsettings'));
                      }}
                    >
                      <span>Team Settings</span>
                    </div>
                    <div
                      className={classnames('menu-list-item', {
                        active: activeItem === 'teammates_settings',
                      })}
                      onClick={() => {
                        this.props.dispatch(push('/home/teammates'));
                      }}
                    >
                      <span>Teammates</span>
                    </div>
                    <div
                      className={classnames('menu-list-item', {
                        active: activeItem === 'custom_branding_settings',
                      })}
                      onClick={() => {
                        this.props.dispatch(push('/home/custom-branding'));
                      }}
                    >
                      <span>Custom Branding</span>
                    </div>
                  </SubMenuContainer>
                )}
                {/* Should be disabled when finished to avoid flickering */}
                {isEnableZapier && (
                  <div
                    className={classnames('menu-list-item parrent', {
                      active: activeItem === 'integration_setting',
                    })}
                    onClick={() => {
                      this.props.dispatch(push('/home/integration'));
                    }}
                  >
                    <span>Integration</span>
                  </div>
                )}

                <div
                  className={classnames('menu-list-item parrent', {
                    active: activeItem === 'billing_setting',
                  })}
                  onClick={() => {
                    this.props.dispatch(push('/home/billing'));
                  }}
                >
                  <span>Billing</span>
                </div>
              </>
            )}
          </List>
        </SidebarContent>
      );
    } else if (activeItem.indexOf('studio') > -1) {
      return permission.studio_program || permission.studio_resource_collection || permission.on_demand_workout ? (
        <StudioSidebar activeItem={activeItem} permission={permission} />
      ) : null;
    } else if (hasPermissionAutoflowOrTeamAdmin && isAutoFlowOrOnboarding) {
      return <AutomationSidebar activeItem={activeItem} />;
    } else if (activeItem === 'forums') {
      const currentSelectedForumId = _.get(this.state, 'currentSelectedForum._id', null);

      return (
        <SidebarForum
          {...this.props}
          forumList={forumList}
          onCutStringEllipsis={onCutStringEllipsis}
          currentSelectedForumId={currentSelectedForumId}
        />
      );
    } else if (
      activeItem.startsWith('package') ||
      activeItem.startsWith('marketplace') ||
      activeItem.startsWith('setup') ||
      activeItem.startsWith('payment-activities') ||
      activeItem.startsWith('sequences') ||
      activeItem.startsWith('coach-bios')
    ) {
      return <PaymentSidebar activeItem={activeItem} />;
    }
  }

  switchNavbar = (higherSize, highestSize, mediumNavbar) => {
    if (higherSize) return styles.topBarHigher;
    if (highestSize) return styles.topBarHighest;
    if (mediumNavbar) return styles.topBarMedium;
    return styles.topBar;
  };

  renderHeader = isInboxPage => {
    const {
      toggleSideBar,
      sideBarVisible,
      showHeaderBorder,
      hideToggleSidebar,
      higherHeader,
      highestNavbar,
      is_hide_banner_product,
      location,
      permission,
      mediumNavbar,
      isHideProgramLibraryBanner,
    } = this.props;

    const isHideBanner = is_hide_banner_product ? is_hide_banner_product : false;
    const checkPermission = permission.payment;
    const formsDetailPageRegexp = /\/home\/forms\/([A-Za-z0-9])\w+\/questions/;
    const isFormDetailPage = _.get(location.pathname.match(formsDetailPageRegexp), '[0]', null);
    const programRegex = /^\/home\/program\/?$/;
    const pathname = _.get(location, 'pathname');
    const isProgramBanner = isHideProgramLibraryBanner && programRegex.test(pathname);
    return (
      <div
        style={this.switchNavbar(higherHeader, highestNavbar, mediumNavbar)}
        className={classnames('app-navbar', {
          'show-border': showHeaderBorder,
          'inbox-view': isInboxPage,
          'client-detailed': location.pathname.includes('/home/client/'),
        })}
      >
        <div
          className={classnames('app-navbar__left', {
            'product-document': isHideBanner && checkPermission && location.pathname === '/home/sequences',
            'form-detail': isFormDetailPage === null ? false : true,
            'program-library': isProgramBanner,
          })}
        >
          {!hideToggleSidebar && (
            <Image
              src={this.props.sideBarVisible ? `${CDN_URL}/images/collapse.svg` : `${CDN_URL}/images/expand.svg`}
              size="26"
              onClick={() => toggleSideBar(!sideBarVisible)}
              className="toggle-side-bar-icon"
            />
          )}
          <div
            className={classnames('app-navbar__left__main-content', {
              'product-document': isHideBanner && checkPermission && location.pathname === '/home/sequences',
            })}
          >
            {this.props.title}
          </div>
        </div>
        <div className="app-navbar__right">
          <UpgradeButton />
          <GlobalSearchClients />
          <DownloadCoachAppButton toggleModal={this.props.toggleModal} onboarding={this.props.onboarding} />
          <TaskReminder />
          <Support />
          {/* <AppNotification /> */}
          <AppNotificationSidebar />
        </div>
      </div>
    );
  };

  getPathName() {
    let pathName = this.props.location.pathname.replace(/\/$/, '');
    pathName = pathName.replace(`/home/client/${this.props.selectedClient ? this.props.selectedClient._id : ''}`, '');
    pathName = pathName.replace('/', '');
    return pathName;
  }

  renderLeftColumn = topSpacing => {
    const {
      sideBarVisible,
      activeItem,
      permission,
      isTeamAdmin,
      user,
      isPaymentAddOn,
      isFormPermission,
      isTaskLibraryPermission,
      segment,
      selectSegment,
      markSeenWaitingList,
      activeWaitingSegment,
      router,
    } = this.props;

    const { shouldShowMobileSidebarPanel } = this.state;
    const sidebarStyles = sideBarVisible ? { ...styles.leftCol } : { ...styles.leftColShadow };
    const mobileSidebarActive = isMobileSidebarActive(activeItem, isTeamAdmin);

    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

    /* =========================================
      Only Client groups
    */
    let visibleSegments = _.filter(
      _.get(segment, 'list', []),
      item => (item.is_visible && permission.segment) || (!permission.segment && item.is_default),
    );
    let ownedSegments = _.remove(visibleSegments, item => item.trainer._id === user._id);
    const archivedSegment = _.remove(ownedSegments, item => item.is_default && item.name === 'Archived');
    // const sharedSegments = visibleSegments.slice(); // TODO
    let defaultSegments = [];
    _.forEach(DEFAULT_SEGMENTS, item => {
      const obj = ownedSegments.find(s => s.name === item && s.is_default);

      if (obj) {
        if (obj.name === 'Your Entire Team') {
          if (isOwnerOrAdminOrSubTrainer(user)) {
            defaultSegments.push(obj);
          }
        } else {
          // default case
          defaultSegments.push(obj);
        }
      }
    });

    ownedSegments = defaultSegments.concat(
      _.filter(ownedSegments, item => !item.is_default && item.name !== 'Your Entire Team'),
    );

    const clientsTooltips =
      [...ownedSegments, ...archivedSegment].map(it => {
        return {
          title: it.name,
          route: '/home/client',
          segment: it,
        };
      }) || [];

    // =========================================

    if (topSpacing) {
      sidebarStyles.top = `${topSpacing}px`;
    }

    return (
      <SidebarContext.Provider value={{ activeItem, permission, isTeamAdmin }}>
        <LeftColumnContainer style={sidebarStyles} className={'app-left-sidebar'}>
          <div className="sidebar-divide" />
          <div style={styles.leftColLogo}>
            <Image
              style={styles.leftColLogoImage}
              onClick={() => {
                this.props.dispatch(push('/home/client'));
              }}
              src={`${CDN_URL}/images/logo.svg`}
              width="26"
              height="40"
            />
          </div>
          <div>
            {Routes.map(item => {
              switch (item.name) {
                case 'library':
                  return (
                    <SidebarItem
                      key={item.name}
                      {...item}
                      route={getLastLibraryRoute() || '/home/workout'}
                      isFormPermission={isFormPermission}
                      isTaskLibraryPermission={isTaskLibraryPermission}
                    />
                  );

                case 'inbox':
                  return (
                    <SidebarItem key={item.name} {...item}>
                      {this.renderTotalUnread()}
                    </SidebarItem>
                  );

                case 'studio':
                  return (
                    <SidebarItem permission={permission} key={item.name} {...item} route={'/home/studio-collection'} />
                  );

                case 'package': {
                  if (isOnwer(user) || permission.explore_payment || permission.payment) {
                    return <SidebarItem permission={permission} isOwner={isOnwer(user)} key={item.name} {...item} />;
                  }
                  return null;
                }

                case 'automation': {
                  if (hasPermissionMultiple || isTeamAdmin) {
                    return (
                      <SidebarItem
                        permission={permission}
                        isTeamAdmin={isTeamAdmin}
                        key={item.name}
                        {...item}
                        route={getLastAutomationRoute() || '/home/onboarding-flow'}
                      />
                    );
                  }

                  // Remove onboarding flow
                  const itemUpdate = {
                    ...item,
                    group: {
                      ...item.group,
                      route: '/home/autoflow',
                    },
                    subItems: [item.subItems[1]],
                  };

                  return (
                    <SidebarItem
                      permission={permission}
                      isTeamAdmin={isTeamAdmin}
                      key={item.name}
                      {...itemUpdate}
                      route={'/home/autoflow'}
                    />
                  );
                }

                case 'client':
                  return (
                    <SidebarItem
                      key={item.name}
                      {...item}
                      tooltips={[CLIENT_DEFAULT_TOOLTIP, ...clientsTooltips]}
                      onChangeSegment={selectSegment}
                      selectedSegment={_.get(segment, 'selected', null)}
                      user={user}
                      permission={permission}
                      router={router}
                      markSeenWaitingList={markSeenWaitingList}
                      activeWaitingSegment={activeWaitingSegment}
                    />
                  );

                default:
                  return <SidebarItem key={item.name} {...item} />;
              }
            })}
            <MobileSidebarTrigger
              className={classnames({ active: mobileSidebarActive, open: shouldShowMobileSidebarPanel })}
              onClick={this.handleClickMobileSidebarTrigger}
            >
              {shouldShowMobileSidebarPanel && !mobileSidebarActive ? <CloseIcon /> : <HumburgerIcon />}
            </MobileSidebarTrigger>
          </div>
          {this.renderAvatar()}
        </LeftColumnContainer>
      </SidebarContext.Provider>
    );
  };

  renderTotalUnread() {
    const { totalUnread } = this.props;

    if (totalUnread && totalUnread > 0) {
      return (
        <div className="total-unread-div">{totalUnread && <span className="total-unread">{totalUnread}</span>}</div>
      );
    }

    return null;
  }

  handleAvatarClick = () => {
    this.props.dispatch(push('/home/accountdetails'));
  };

  handleCustomBrandClick = () => {
    let pathName = this.props.location.pathname.replace(/\/$/, '');
    const nextPath = '/home/custom-branding';
    if (pathName.indexOf('advanced-custom-branding') >= 0) {
      window.location = nextPath;
    } else {
      this.props.dispatch(push(nextPath));
    }
  };

  renderAvatar() {
    const { isTeamAdmin, user, isHideReferralCoach, isEnableZapier, permission, cloudfrontList } = this.props;
    const isShowNotification = _.get(permission, 'notification_center', false);

    if (!user) {
      return null;
    }

    const userAvatar = convertS3UrlToCloudFrontUrl(user.avatar, cloudfrontList) || user.avatar;

    return (
      <div style={styles.leftColAvatar} className="left-col-avatar">
        {!isHideReferralCoach && (
          <SidebarItem
            name="referral-program"
            icon={<ReferralIcon />}
            route="/home/referral-program"
            title="Referral Program"
            hideOnMobile
            group={{
              title: 'Referral Program',
              icon: <ReferralIcon />,
              route: '/home/referral-program',
            }}
          />
        )}
        {isTeamAdmin && (
          <SidebarItem
            name="teammates_settings"
            icon={<TeamIcon />}
            route="/home/teammates"
            title="Team"
            hideOnMobile
            group={{
              title: 'Teammates',
              icon: <TeamIcon />,
              route: '/home/teammates',
            }}
          />
        )}
        <SidebarItemContainer className="current-user">
          <Avatar
            style={styles.leftColAvatarImage}
            className="client-detail-avatar"
            size="34"
            name={getUserShortName(user)}
            src={userAvatar}
            color={user.color}
            onClick={this.handleAvatarClick}
          />
          <div className={classnames('menu-item-dropdown-content setting', { 'not-admin': !isTeamAdmin })}>
            <ul className="list-dropdown">
              <li className="list-dropdown-item name">
                <div>{`${user.first_name} ${user.last_name}`}</div>
              </li>
              <li className="list-dropdown-item divider">
                <div />
              </li>
              {isTeamAdmin ? (
                <li
                  className="list-dropdown-item"
                  onClick={() => {
                    this.props.dispatch(push('/home/generalsettings'));
                  }}
                >
                  <div className="work-space-title">
                    <div className="text">
                      {user.teams && user.teams.length > 0 ? user.teams[0].team.name : 'Personal Workspace'}
                    </div>
                    <ArrowRightIcon />
                  </div>
                </li>
              ) : (
                <li className="list-dropdown-item name">
                  <div className="work-space-title">
                    <div className="text">
                      {user.teams && user.teams.length > 0 ? user.teams[0].team.name : 'Personal Workspace'}
                    </div>
                  </div>
                </li>
              )}
              <li className="list-dropdown-item divider">
                <div />
              </li>
              {isShowNotification && (
                <li
                  className="list-dropdown-item"
                  onClick={() => {
                    this.props.dispatch(push('/home/notification-center'));
                  }}
                >
                  <div>
                    <Bumble className="icon" />
                    Notification Center
                  </div>
                </li>
              )}

              <li
                className="list-dropdown-item"
                onClick={() => {
                  this.props.dispatch(push('/home/accountdetails'));
                }}
              >
                <div>
                  <SettingIcon className="icon" />
                  Settings
                </div>
              </li>
              {isTeamAdmin && (
                <>
                  <li className="list-dropdown-item" onClick={this.handleCustomBrandClick}>
                    <div>
                      <CustomBrandingIcon className="icon" />
                      Custom Branding
                    </div>
                  </li>
                  {isEnableZapier && (
                    <li
                      className="list-dropdown-item integration-item"
                      onClick={() => {
                        this.props.dispatch(push('/home/integration'));
                      }}
                    >
                      <div>
                        <IntegrationIcon className="icon integration-icon" />
                        Integration
                      </div>
                    </li>
                  )}
                  <li
                    className="list-dropdown-item"
                    onClick={() => {
                      this.props.dispatch(push('/home/billing'));
                    }}
                  >
                    <div>
                      <BillingIcon className="icon" />
                      Billing
                    </div>
                  </li>
                </>
              )}
              <li className="list-dropdown-item divider full-width">
                <div />
              </li>
              <li
                className="list-dropdown-item"
                onClick={() => {
                  this.props.dispatch(detachCurrentUser());
                  this.props.dispatch(logout());
                }}
              >
                <div>Logout</div>
              </li>
            </ul>
          </div>
        </SidebarItemContainer>
      </div>
    );
  }

  renderModal() {
    return this.props.isModalOpen ? this.props.modal : null;
  }

  renderSecondModal() {
    return this.props.isSecondModalOpen ? this.props.secondModal : null;
  }

  renderConfirmModal() {
    return this.props.isConfirmModalOpen ? this.props.confirmModal : null;
  }

  renderErrorModal() {
    return this.props.isShowingError ? this.props.errorPopup : null;
  }

  renderSuccessModal() {
    return this.props.isShowingSuccess ? this.props.successPopup : null;
  }
}
