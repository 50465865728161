import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import * as S from './style';
import { FormGroup, ErrorMessage } from 'shared/FormControl';
import { VALIDATIONS, MEDIA_TYPES, DEFAULT_MEDIA_LINK_DATA } from '../helper';
import MediaUploadProgress from 'shared/MediaUploadProgress';
import {
  createObjectURL,
  checkYoutubeThumbnailUrl,
  mediaLog,
  revokeObjectURL,
  formatShortLink,
  generateVimeoAPIUrl,
} from 'utils/commonFunction';
import DragDropUI from './DragDropUI';
import { validateVimeoUrl, validateYouTubeUrl } from 'utils/validations';
import { ReactComponent as RemoveIcon } from 'assets/icons/close_thin.svg';
import { CloseIcon } from '../style';
import MediaPlayer from 'shared/MediaPlayer';
import { VALIDATIONS as VALIDATIONS_AUDIO } from 'shared/UploadAndDragSection/LinkAttachment/helper';
import { isMp3File, isVideoFile } from 'utils/validations';
import { getPageMetaDataFromURL, cancelRequest } from 'utils/commonRequest';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';

class AddMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingFile: null,
      attachment: null,
      isAddingLink: false,
    };
    this.supportedExtensions = [...VALIDATIONS.VIDEO_300MB.extensions, ...VALIDATIONS.IMAGE_25MB.extensions];
    this.localUrl = null;
    this.uploadConfigs = {
      url: '/api/task/v3/upload',
      method: 'post',
    };
    this.getYoutubeLinkDataDebounce = _.debounce(this.getYoutubeLinkData, 300);
  }

  componentDidMount() {
    const { attachments, file } = this.props;

    const processAttachments = async () => {
      if (file) {
        this.updateUploadingFile(file);
      } else if (attachments && attachments.attachments) {
        this.setState({ attachment: attachments.attachments[0] });
        const url = _.get(attachments, 'attachments[0].url', '');
        if (url) {
          const trimmedValue = url.trim();
          const youtubeId = validateYouTubeUrl(trimmedValue);
          const thumbnailUrl = await checkYoutubeThumbnailUrl(youtubeId);
          if (youtubeId) {
            this.updateAttachment({
              thumbnail_url: thumbnailUrl,
            });
            this.getYoutubeLinkDataDebounce(trimmedValue);
          }
        }
      }
    };

    processAttachments();
  }

  componentWillUnmount() {
    if (this.localUrl) {
      revokeObjectURL(this.localUrl);
    }
  }

  onDropfile = files => {
    if (files.length > 1) {
      return toast.error('Please upload one file at a time');
    }

    this.handleSelectedFile(files[0]);
  };

  onSelectFile = event => {
    const { files } = event.target;
    if (files.length) {
      this.handleSelectedFile(files[0]);

      event.target.value = '';
    }
  };

  handleSelectedFile = file => {
    const { name, size, type } = file;
    const fileExtension = name.split('.').pop().toLowerCase();
    const { name: fileName, size: fileSize, type: fileType } = file;
    mediaLog({
      status: 1,
      name: fileName,
      description: 'Send a file via create task',
      fileSize,
      fileType,
    });

    if (!this.supportedExtensions.includes(fileExtension)) {
      return toast.error('File type not supported.');
    } else {
      if (isMp3File(type)) {
        if (size > VALIDATIONS_AUDIO.AUDIO.maxSize) {
          return toast.error(VALIDATIONS_AUDIO.AUDIO.errorMessage);
        }
      } else {
        if (VALIDATIONS.VIDEO_300MB.extensions.includes(fileExtension)) {
          if (size > VALIDATIONS.VIDEO_300MB.maxSize) {
            return toast.error(VALIDATIONS.VIDEO_300MB.errorMessage);
          }
        } else if (size > VALIDATIONS.IMAGE_25MB.maxSize) {
          return toast.error(VALIDATIONS.IMAGE_25MB.errorMessage);
        }
      }
    }

    this.updateUploadingFile(file);
  };

  updateUploadingFile = file => {
    const formData = new FormData();
    formData.append('attachments', file);
    this.localUrl = createObjectURL(file);
    this.uploadConfigs.data = formData;
    this.setState({ uploadingFile: file }, () => {
      this.props.updateUploadStatus([{ uploadId: this.localUrl }]);
    });
  };

  markUploadDone = () => {
    this.props.updateUploadStatus([{ uploadId: this.localUrl, status: true }]);
  };

  onUploadSuccess = list => {
    const attachment = list[0];
    const { name, mimetype: fileType, size: fileSize, createdAt, updatedAt } = attachment;
    mediaLog({
      name,
      fileSize,
      fileType,
      createdAt,
      updatedAt,
      status: 2,
      description: 'Upload success file via create task',
    });
    this.markUploadDone();
    this.setState({ attachment, uploadingFile: null }, () => {
      this.props.onUpdateAttachmentData({ attachments: [attachment] });
    });
  };

  onRemoveUploadedAttachment = () => {
    cancelRequest();
    if (this.localUrl) {
      revokeObjectURL(this.localUrl);
    }

    this.setState({ attachment: null }, () => {
      this.props.onUpdateAttachmentData({ title: '', attachments: [] });
    });
  };

  onCancelUploadingFile = () => {
    if (this.localUrl) {
      this.markUploadDone();
      revokeObjectURL(this.localUrl);
    }

    this.setState({ attachment: null, uploadingFile: null });
  };

  onUpdateAttachmentTitle = event => {
    const { value } = event.target;
    this.props.onUpdateAttachmentData({ title: value });
  };

  onAddLink = () => {
    this.setState({ attachment: DEFAULT_MEDIA_LINK_DATA });
  };

  updateAttachment = data => {
    this.setState({ attachment: { ...this.state.attachment, ...data } }, () => {
      this.props.onUpdateAttachmentData({ attachments: [this.state.attachment] });
    });
  };

  getYoutubeLinkData = url => {
    getPageMetaDataFromURL(url).then(response => {
      const {
        data: {
          data: { title },
        },
      } = response;

      this.updateAttachment({ name: title });
    });
  };

  onChangeLink = async event => {
    let value = event.target.value || '';
    const trimmedValue = value.trim();

    if (trimmedValue) {
      if (validateVimeoUrl(trimmedValue)) {
        this.updateAttachment({
          name: '',
          type: MEDIA_TYPES.LINK,
          url: trimmedValue,
          thumbnail_url: '',
          invalidLink: false,
        });
        axios
          .get(generateVimeoAPIUrl(trimmedValue))
          .then(response => {
            const data = response.data || {};
            this.updateAttachment({
              name: data.title,
              type: MEDIA_TYPES.LINK,
              url: trimmedValue,
              thumbnail_url: data.thumbnail_url || MEDIA_PLACEHOLDER,
              invalidLink: false,
            });
          })
          .catch(() => {
            this.updateAttachment({
              ...DEFAULT_MEDIA_LINK_DATA,
              url: trimmedValue,
              invalidLink: true,
            });
          });
      } else {
        const youtubeId = validateYouTubeUrl(trimmedValue);
        const thumbnailUrl = await checkYoutubeThumbnailUrl(youtubeId);
        if (youtubeId) {
          this.updateAttachment({
            name: '',
            url: formatShortLink(trimmedValue),
            thumbnail_url: thumbnailUrl,
            invalidLink: false,
          });
          this.getYoutubeLinkDataDebounce(trimmedValue);
        } else {
          this.updateAttachment({
            ...DEFAULT_MEDIA_LINK_DATA,
            url: trimmedValue,
            invalidLink: true,
          });
        }
      }
    } else {
      this.updateAttachment(DEFAULT_MEDIA_LINK_DATA);
    }
  };

  render() {
    const { uploadingFile, attachment } = this.state;
    const { attachments } = this.props;

    return (
      <S.AddMediaContainer className="task__add-media">
        {uploadingFile || attachment ? (
          <S.MediaDetailContainer>
            <CloseIcon className="taskAttachment__closeIcon" onClick={this.props.onClose} />
            <FormGroup>
              <label>Media Files</label>
              <input
                placeholder="Title (Optional)"
                defaultValue={attachments ? attachments.title : ''}
                onChange={this.onUpdateAttachmentTitle}
              />
            </FormGroup>
            <S.AttachmentItem>
              {uploadingFile ? (
                <MediaUploadProgress
                  file={uploadingFile}
                  apiConfigs={this.uploadConfigs}
                  onCancel={this.onCancelUploadingFile}
                  onSuccess={this.onUploadSuccess}
                  preview={
                    <S.AttachmentPreview
                      isVideo={isVideoFile(uploadingFile.type)}
                      isAudio={isMp3File(uploadingFile.type)}
                    >
                      <MediaPlayer
                        url={this.localUrl}
                        type={uploadingFile.type}
                        thumbnail={uploadingFile.type.startsWith('image') ? this.localUrl : null}
                        generatePreview
                      />
                    </S.AttachmentPreview>
                  }
                />
              ) : attachment.type === MEDIA_TYPES.LINK ? (
                <S.MediaLinkContainer>
                  <S.MediaLinkPreview
                    background={attachment.thumbnail_url}
                    className={classnames({
                      youtube: !!attachment.url && !attachment.invalidLink && !validateVimeoUrl(attachment.url),
                      vimeo: !!attachment.url && !attachment.invalidLink && validateVimeoUrl(attachment.url),
                    })}
                  >
                    {!!attachment.url && !attachment.invalidLink ? (
                      <MediaPlayer url={attachment.url} type="video" thumbnail={attachment.thumbnail_url} />
                    ) : null}
                  </S.MediaLinkPreview>
                  <div style={{ flex: '1 1', overflow: 'hidden' }}>
                    <FormGroup className={classnames('form-group--link', { error: attachment.invalidLink })}>
                      <input
                        placeholder="Youtube / Vimeo Link"
                        onChange={this.onChangeLink}
                        defaultValue={attachment.url}
                        autoFocus={true}
                      />
                      <RemoveIcon className="remove-link" onClick={this.onRemoveUploadedAttachment} />
                    </FormGroup>
                    {attachment.invalidLink && (
                      <ErrorMessage className="invalid-link">Please add a Youtube or Vimeo link</ErrorMessage>
                    )}
                    {attachment.name && <div className="video-title">{attachment.name}</div>}
                  </div>
                </S.MediaLinkContainer>
              ) : (
                <MediaUploadProgress
                  file={attachment}
                  onCancel={this.onRemoveUploadedAttachment}
                  viewMode
                  preview={
                    isMp3File(attachment.mimetype || attachment.type) ? (
                      <S.AttachmentPreview isAudio>
                        <MediaPlayer
                          url={this.localUrl || attachment.url}
                          type={attachment.mimetype || attachment.type}
                          thumbnail={attachment.thumbnail_url}
                          generatePreview
                        />
                      </S.AttachmentPreview>
                    ) : isVideoFile(attachment.mimetype || attachment.type) ? (
                      <S.AttachmentPreview isVideo>
                        <MediaPlayer
                          url={this.localUrl || attachment.url}
                          type={attachment.mimetype || attachment.type}
                          thumbnail={attachment.thumbnail_url}
                          generatePreview
                        />
                      </S.AttachmentPreview>
                    ) : (
                      <S.AttachmentPreview>
                        <MediaPlayer
                          url={this.localUrl || attachment.url}
                          type={attachment.mimetype || attachment.type}
                          thumbnail={this.localUrl || attachment.thumbnail_url || attachment.url}
                          generatePreview
                        />
                      </S.AttachmentPreview>
                    )
                  }
                />
              )}
            </S.AttachmentItem>
          </S.MediaDetailContainer>
        ) : (
          <DragDropUI
            onDrop={this.onDropfile}
            onSelectFile={this.onSelectFile}
            onClose={this.props.onClose}
            onAddLink={this.onAddLink}
          />
        )}
      </S.AddMediaContainer>
    );
  }
}

export default AddMedia;
