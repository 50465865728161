import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import { Preview } from '../style';
import { Phone, fadeIn } from '../style';

export const EmailBanner = styled.div`
  background: #fafafa;
  border: 1px dashed #dedede;
  box-sizing: border-box;
  height: 54px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14.0008px;
  line-height: 19px;
  text-align: center;
  color: #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;

  ${props =>
    props.background &&
    css`
      height: auto;
      border: none;
      background-color: transparent;
      min-height: unset;
    `}

  img {
    max-height: 108px;
    width: 100%;
    object-fit: cover;
  }
`;

export const Avatar = styled.img.attrs({
  src: `${CDN_URL}/images/custom_branding_client_avatar.png`,
})`
  width: 33px;
  height: 33px;
  margin-bottom: 13px;
`;

export const EmailBody = styled.div`
  height: 270px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #9f9f9f;
  padding: 22px;
  position: relative;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  border-radius: 1.17495px;

  :after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: 100px;
    background: linear-gradient(180deg, rgba(247, 248, 252, 0) 0%, #f7f8fc 69.81%);
    z-index: 1;
  }
`;

export const PhoneEmailBanner = styled(EmailBanner)`
  height: 43px;
  margin-top: 28px;
  border: 1px dashed #dedede;
  min-height: 43px;

  ${props =>
    props.background &&
    css`
      border: none;
      height: auto;
      background-color: transparent;
      min-height: unset;
    `}

  img {
    max-height: 86px;
  }
`;

export const PhoneEmailContentPlaceholder = styled.div`
  border: 1px solid #f6f6f8;
  box-sizing: border-box;
  border-radius: 3px;
  height: 28px;
`;

export const PhoneEmailBody = styled(EmailBody)`
  padding: 13px;
  height: auto;
  border: none;

  .text {
    max-width: 140px;
    margin: auto;
    margin-bottom: 20px;
  }

  :after {
    display: none;
  }
`;

export const PhoneContainer = styled(Phone)`
  width: 218px;
  min-height: 220px;
  max-height: 250px;
  padding: 7px 8px 7px 7px;
  position: absolute;
  right: -135px;
  top: 110px;
  z-index: 1;

  :after {
    z-index: 3;
  }
`;

export const EmailContainer = styled.div`
  width: calc(100% - 175px);
  flex: 0 0 calc(100% - 175px);
  max-width: 370px;
  margin-right: 135px;
  overflow: visible;
  position: relative;
`;

export const PreviewContainer = styled(Preview)`
  padding: 34px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: top;
`;
