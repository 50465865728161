import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { Radio } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';

import { Button, Checkbox } from 'shared/FormControl';

import * as S from './style';
import { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';
import { CDN_URL } from 'constants/commonData';

const initiation = (labels = []) => {
  let options = {};
  if (labels.length) {
    labels.forEach(item => {
      options[item.unique_code] = [];
    });
  }

  return options;
};

const initFilterStatus = (labels = []) => {
  let options = {};
  labels.forEach(item => {
    options[item.unique_code] = true;
  });

  return options;
};

const OnDemandFilters = ({
  onUpdateFilters,
  groupLabels,
  currentFilters = {},
  onCloseFilters,
  categoryLabels = [],
  className = '',
  currentFilterOwner = OWNER_TYPE.ALL,
  onUpdateFilterOwner,
}) => {
  const [owner, setOwner] = useState(currentFilterOwner);
  const [selected, setSelected] = useState(initiation(categoryLabels));
  const ref = useRef();

  const [groupOptions, setGroupOptions] = useState(initFilterStatus(categoryLabels));
  const [expandedOwner, setExpandedOwner] = useState(true);

  useEffect(() => {
    setSelected(currentFilters);
  }, [currentFilters]);

  useEffect(() => {
    setGroupOptions(initFilterStatus(categoryLabels));
  }, [categoryLabels]);

  const handleExpand = key => () => {
    if (key === 'owner') {
      setExpandedOwner(!expandedOwner);
    }
    setGroupOptions({
      ...groupOptions,
      [key]: !groupOptions[key],
    });
  };

  const handleOnChange = (key, id) => () => {
    const newSelected = get(selected, key, []).includes(id)
      ? get(selected, key, []).filter(it => it !== id)
      : [...get(selected, key, []), id];

    setSelected({
      ...selected,
      [key]: newSelected,
    });
  };

  const handleClearAll = () => {
    setSelected(initiation(categoryLabels));
    setOwner(OWNER_TYPE.ALL);
  };

  const handleUpdateFilterOptions = () => {
    onUpdateFilterOwner && onUpdateFilterOwner(owner);
    onUpdateFilters && onUpdateFilters(selected, owner);
  };

  const levels = useMemo(() => {
    return get(groupLabels, 'level.items', []);
  }, [groupLabels]);
  const durations = useMemo(() => {
    return get(groupLabels, 'duration.items', []);
  }, [groupLabels]);

  const handleSelectOwner = type => {
    setOwner(type);
  };

  return (
    <RootCloseWrapper event="click" onRootClose={onCloseFilters}>
      <S.PopupWrapper ref={ref} className={className}>
        <S.PopupHeader>
          <S.PopupHeaderTitle>Filters</S.PopupHeaderTitle>
          <S.ClearLabel onClick={handleClearAll}>Clear All</S.ClearLabel>
        </S.PopupHeader>
        <S.Popup>
          <S.PopupGroup>
            <S.PopupGroupTitle onClick={handleExpand('owner')}>
              Owner
              <img
                src={`${CDN_URL}/images/arrow_down_black.svg`}
                className={classNames({ opened: expandedOwner })}
                alt=""
              />
            </S.PopupGroupTitle>
            <S.PopupGroupContent className={classNames('owner', { expanded: expandedOwner })}>
              <Radio
                checked={owner === OWNER_TYPE.ALL}
                label="All"
                onClick={handleSelectOwner.bind(this, OWNER_TYPE.ALL)}
              />
              <Radio
                checked={owner === OWNER_TYPE.YOU}
                label="You"
                onClick={handleSelectOwner.bind(this, OWNER_TYPE.YOU)}
              />
            </S.PopupGroupContent>
          </S.PopupGroup>
          <S.PopupGroup>
            <S.PopupGroupTitle onClick={handleExpand('level')}>
              Level
              <img
                src={`${CDN_URL}/images/arrow_down_black.svg`}
                className={classNames({ opened: groupOptions['level'] })}
                alt=""
              />
            </S.PopupGroupTitle>
            <S.PopupGroupContent className={classNames({ expanded: groupOptions['level'] })}>
              {levels.map(it => (
                <Checkbox
                  key={it._id}
                  title={it.name}
                  className="system-labels"
                  checked={get(selected, 'level', []).includes(it._id)}
                  onChange={handleOnChange('level', it._id)}
                  size={15}
                />
              ))}
            </S.PopupGroupContent>
          </S.PopupGroup>
          <S.PopupGroup>
            <S.PopupGroupTitle onClick={handleExpand('duration')}>
              Duration
              <img
                src={`${CDN_URL}/images/arrow_down_black.svg`}
                className={classNames({ opened: groupOptions['duration'] })}
                alt=""
              />
            </S.PopupGroupTitle>
            <S.PopupGroupContent className={classNames('duration', { expanded: groupOptions['duration'] })}>
              {durations.map(it => (
                <Checkbox
                  key={it._id}
                  title={it.name}
                  className="system-labels"
                  checked={get(selected, 'duration', []).includes(it._id)}
                  onChange={handleOnChange('duration', it._id)}
                  size={15}
                />
              ))}
            </S.PopupGroupContent>
          </S.PopupGroup>
          {categoryLabels.map(it => {
            if (['level', 'duration'].includes(it['unique_code']) || !get(it, 'items').length) {
              return null;
            }
            return (
              <S.PopupGroup key={`item-category-${it._id}`}>
                <S.PopupGroupTitle onClick={handleExpand(it['unique_code'])}>
                  {it.name}
                  <img
                    src={`${CDN_URL}/images/arrow_down_black.svg`}
                    className={classNames({ opened: groupOptions[it['unique_code']] })}
                    alt=""
                  />
                </S.PopupGroupTitle>
                <S.PopupGroupContent className={classNames('other', { expanded: groupOptions[it['unique_code']] })}>
                  {get(it, 'items').map(item => {
                    return (
                      <S.LabelChip
                        className={classNames({ selected: get(selected, it['unique_code'], []).includes(item._id) })}
                        key={item._id}
                        onClick={handleOnChange(it['unique_code'], item._id, it._id)}
                      >
                        {item.name}
                      </S.LabelChip>
                    );
                  })}
                </S.PopupGroupContent>
              </S.PopupGroup>
            );
          })}

          <S.PopupFooter>
            <Button onClick={handleUpdateFilterOptions}>Update</Button>
          </S.PopupFooter>
        </S.Popup>
      </S.PopupWrapper>
    </RootCloseWrapper>
  );
};

export default OnDemandFilters;
