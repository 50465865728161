import React from 'react';
import styles from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const TooltipWrapper = styles(ReactTooltip)`
  &.__react_component_tooltip {

    &.average-tooltip {
      background-color: #FFFFFF;
      border-radius: 3px;
      padding: 15px;
      color: #202353;
      min-width: unset;
      width: unset;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    }

    .average-tooltip {
      &__heading {
        display: flex;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 11px;
        line-height: 100%;
        text-transform: uppercase;
        color: #202353;
        white-space: nowrap;
      }

      &__value {
        strong {
          font-size: 16px;
          line-height: 100%;
          font-weight: 600;
          color: #202353;
        }

        span {
          font-weight: 600;
          font-size: 13px;
          line-height: 100%;
          color: #8F91A9;
          margin-left: 5px;
        }

      }
    }
  }
`;

function GoalStepTooltip({ steps, ...props }) {
  return (
    <TooltipWrapper className="average-tooltip app-tooltip-white" {...props}>
      <div className="average-tooltip__heading">
        <img src={`${CDN_URL}/images/macros_training_day_bold.svg`} width="10" style={{ marginRight: 5 }} alt="" />
        <strong>Steps Goal</strong>
      </div>
      <div className="average-tooltip__value">
        <strong>{Number(steps).toSeperateFormat()}</strong>
        <span>{pluralize('step', steps)}</span>
      </div>
    </TooltipWrapper>
  );
}

export default GoalStepTooltip;
