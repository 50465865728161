import React from 'react';
import countBy from 'lodash/countBy';
import forEach from 'lodash/forEach';

import { ReactComponent as WorkoutIcon } from 'assets/icons/program_workout.svg';
import { ReactComponent as LiveSyncIcon } from 'assets/icons/program_live_sync.svg';
import { ReactComponent as TagsIcon } from 'assets/icons/program_tags.svg';
import { ReactComponent as WeekIcon } from 'assets/icons/program_week.svg';
import { ReactComponent as MostRecentIcon } from 'assets/icons/program_most_recent.svg';

import {
  PROGRAM_SORTER_TYPE,
  TEAM_SHARE,
  TEAM_SHARE_NOOWNER,
  TEAM_SHARE_PRIVATE,
  TEAM_SHARE_SHARED,
} from 'constants/commonData';

export const ASSETS_SHARE_SETTING_TYPE = {
  DEFAULT: 'default',
  SHARE_SETTING_MODAL: 'share_setting_modal',
};

export const SHARE_OPTIONS = {
  [TEAM_SHARE_PRIVATE]: {
    text: TEAM_SHARE[TEAM_SHARE_PRIVATE],
    value: TEAM_SHARE_PRIVATE,
  },
  [TEAM_SHARE_SHARED]: {
    text: TEAM_SHARE[TEAM_SHARE_SHARED],
    value: TEAM_SHARE_SHARED,
  },
};

export const NO_OWNER_SHARED_OPTION = {
  text: 'No owner (shared)',
  value: 'no_owner',
};

export const LIBRARY_COLUMNS = [
  {
    id: PROGRAM_SORTER_TYPE.TITLE,
    label: 'Program',
    hasSort: true,
    alignCenter: false,
    icon: <WorkoutIcon className="column-symbol" />,
  },
  {
    id: 'tags',
    label: 'Tags',
    hasSort: false,
    alignCenter: false,
    icon: <TagsIcon className="column-symbol" />,
  },
  {
    id: 'live_sync',
    label: 'Live Sync',
    hasSort: false,
    alignCenter: true,
    icon: <LiveSyncIcon className="column-symbol" />,
  },
  {
    id: PROGRAM_SORTER_TYPE.WORKOUT_SETS,
    label: 'Weeks',
    hasSort: true,
    alignCenter: true,
    icon: <WeekIcon className="column-symbol" />,
  },
  {
    id: PROGRAM_SORTER_TYPE.LAST_INTERACTED,
    label: 'Most Recent',
    hasSort: true,
    alignCenter: true,
    icon: <MostRecentIcon className="column-symbol" />,
  },
  {
    id: 'more_actions',
    isEmpty: true,
  },
];

export const FILTER_TYPE_KEYS = {
  TAGS: 'tags',
  MODALITY: 'modalities',
  EXPERIENCE_LEVEL: 'levels',
  WORKOUT_DAYS_PER_WEEK: 'workout_days_per_weeks',
  // DURATION: 'duration',
  PROGRAM_LENGTH: 'number_of_weeks',
};

export const DEFAULT_FILTERS = {
  [FILTER_TYPE_KEYS.TAGS]: [],
  [FILTER_TYPE_KEYS.MODALITY]: [],
  [FILTER_TYPE_KEYS.EXPERIENCE_LEVEL]: null,
  [FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK]: [],
  // duration: [],
  [FILTER_TYPE_KEYS.PROGRAM_LENGTH]: [],
};

export const DEFAULT_GROUP_OPTIONS = {
  [FILTER_TYPE_KEYS.TAGS]: true,
  [FILTER_TYPE_KEYS.MODALITY]: true,
  [FILTER_TYPE_KEYS.EXPERIENCE_LEVEL]: true,
  [FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK]: true,
  // duration: true,
  [FILTER_TYPE_KEYS.PROGRAM_LENGTH]: true,
};

export const FILTER_TYPES = [
  { key: FILTER_TYPE_KEYS.TAGS, label: 'Tags', className: 'tags' },
  { key: FILTER_TYPE_KEYS.MODALITY, label: 'Modality', className: 'modality' },
  { key: FILTER_TYPE_KEYS.EXPERIENCE_LEVEL, label: 'Experience Level', className: 'experience_level' },
  { key: FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK, label: 'Workout Days Per Week', className: 'workout_days_per_week' },
  // { key: FILTER_TYPE_KEYS.DURATION, label: 'Duration', className: 'duration' },
  { key: FILTER_TYPE_KEYS.PROGRAM_LENGTH, label: 'Program Duration', className: 'program_length' },
];

export const DEFAULT_MODALITY_VALUES = [
  {
    key: 'strength_hypertrophy',
    label: 'Strength / Hypertrophy',
  },
  {
    key: 'cardiovascular_training',
    label: 'Cardiovascular Training',
    tooltip: 'Running, Swimming, Cycling',
  },
  {
    key: 'hiit',
    label: 'HIIT',
    tooltip: 'Circuits, Crossfit WOD’s, Group Fitness, Bootcamps',
  },
  {
    key: 'speed_power',
    label: 'Speed / Power',
    tooltip: 'Sprints, Jumping, Plyometrics',
  },
  {
    key: 'recovery_training',
    label: 'Recovery Training',
    tooltip: 'Stretching, Self Myofascial Release, Corrective Exercises, PRI',
  },
  {
    key: 'mind_body',
    label: 'Mind-Body',
    tooltip: 'Yoga, Pilates, Tai Chi, Meditation, Breathwork',
  },
  {
    key: 'combination',
    label: 'Combination',
    tooltip: 'Programs with more than one modality',
  },
];

// export const DEFAULT_DURATIONS = [
//   {
//     key: '0_20_min',
//     label: '0 - 20 min',
//   },
//   {
//     key: '20_40_min',
//     label: '20 - 40 min',
//   },
//   {
//     key: '40_60_min',
//     label: '40 - 60 min',
//   },
//   {
//     key: '60_min_plus',
//     label: '60+ min',
//   },
// ];

export const DEFAULT_EXPERIENCE_LEVEL = [
  {
    key: 'all_level',
    label: 'All Levels',
  },
  {
    key: 'beginner',
    label: 'Beginner',
  },
  {
    key: 'intermediate',
    label: 'Intermediate',
  },
  {
    key: 'advanced',
    label: 'Advanced',
  },
];

export const DEFAULT_WORKOUT_DAYS_PER_WEEK = [
  {
    key: '1',
    label: '1',
  },
  {
    key: '2',
    label: '2',
  },
  {
    key: '3',
    label: '3',
  },
  {
    key: '4',
    label: '4',
  },
  {
    key: '5',
    label: '5',
  },
  {
    key: '6',
    label: '6',
  },
  {
    key: '7',
    label: '7',
  },
];

export const DEFAULT_PROGRAM_LENGTH = [
  {
    key: '1-2',
    label: '1-2 weeks',
  },
  {
    key: '3-4',
    label: '3-4 weeks',
  },
  {
    key: '5-6',
    label: '5-6 weeks',
  },
  {
    key: '7-8',
    label: '7-8 weeks',
  },
  {
    key: '9',
    label: '9+ weeks',
  },
];

export const getOwnerOptions = ({ members = [], hasYou = false, userId } = {}) => {
  const options = members.map(it => {
    let addition = '';
    if (hasYou && it._id === userId) {
      addition = ' (You)';
    }
    return {
      text: it.full_name + addition,
      value: it._id,
    };
  });
  options.push(NO_OWNER_SHARED_OPTION);
  return options;
};

export const generateShareStatus = ({ ownership, shareStatus } = {}) => {
  if (ownership === NO_OWNER_SHARED_OPTION.value) {
    return TEAM_SHARE_NOOWNER;
  } else if (ownership !== NO_OWNER_SHARED_OPTION.value && shareStatus === TEAM_SHARE_NOOWNER) {
    return TEAM_SHARE_PRIVATE;
  }
  return shareStatus;
};

export const getCountTagsSelected = ({ programsSelected, allCurrentProgram }) => {
  let allCurrentTags = [];
  let allTagsProgramList = [];

  forEach(programsSelected, item => {
    allCurrentTags = allCurrentTags.concat(item.tags || []);
  });

  forEach(allCurrentProgram, item => {
    allTagsProgramList = allTagsProgramList.concat(item.tags || []);
  });

  const countCurrentProgram = countBy(allCurrentTags, '_id');
  const countAllProgramList = countBy(allTagsProgramList, '_id');
  let result = [];

  forEach(countCurrentProgram, (total, tagsId) => {
    result[tagsId] = {
      total,
      state: countAllProgramList[tagsId] === allTagsProgramList[tagsId] || total === programsSelected.length ? 2 : 1,
    };
    result[tagsId].originState = result[tagsId].state;
  });

  return result;
};
