import React from 'react';
import get from 'lodash/get';

import BodyMetricChartOverviewNew from 'components/BodyMetricChartOverviewNew';
import StepChartOverviewNew from 'components/TrainingOverview/MetricOverview/StepChartOverviewNew';
import { STEP_UNIQUE_CODE } from 'constants/commonData';

function GroupMetricOverviewItem({ item, timeRange, onClick, units, isDoubleChart, isDraggable = true }) {
  const unique_code = get(item, 'unit.unique_code', '');
  const isStepChart = [item.unique_code, unique_code].includes(STEP_UNIQUE_CODE);

  return (
    <div className="training-overview-period-new group-metric-charts" onClick={onClick}>
      {isStepChart ? (
        <StepChartOverviewNew
          isDefaultStep={item.unique_code === STEP_UNIQUE_CODE}
          uniqueCode={item.unique_code}
          metricId={(item || {})._id}
          name={item.name}
          timeRange={timeRange}
          isDraggable={isDraggable}
          isGroupOverview
        />
      ) : (
        <BodyMetricChartOverviewNew
          metric={item}
          units={units}
          isDoubleChart={isDoubleChart}
          timeRange={timeRange}
          isDraggable={isDraggable}
          isGroupOverview
        />
      )}
    </div>
  );
}
export default GroupMetricOverviewItem;
