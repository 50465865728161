import React, { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Modal } from 'semantic-ui-react';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL, PLANS } from 'constants/commonData';
import * as S from './style';
import './style.scss';

function UpcomingBill(props) {
  const { data, plan, billingCycle, useAddOns, pricingPlan, scheduledSubscription, permission } = props;

  const isZapier = _.get(permission, 'zapier', false);
  const isZapierMeter = _.get(permission, 'zapier_on_demand_charge', false);
  const isZapierEnable = isZapier && isZapierMeter;

  if (!data) {
    return null;
  }

  const isStarterPlan = plan.key === PLANS.starter.key;
  const { period_end, period_start, total, next_payment_attempt } = data;
  const planFee = _.get(props.data, 'plan', {});
  const addOnsMetter = _.get(props.data, 'addOnsMetter', {});
  const addOnsBase = _.get(props.data, 'addOnsBase', {});
  const paymentAddOnsBase = _.get(props.data, 'paymentAddOnsBase', {});
  const resourceAddOnsBase = _.get(props.data, 'resourceCollectionsAddOnsBase', {});
  const mealPlanAddOnsBase = _.get(data, 'mealPlanAddOnsBase', {});

  const scheduleBillingCycle = _.get(scheduledSubscription, 'billing_cycle', null) || billingCycle;
  const cheatedPeriod = scheduleBillingCycle === 2 ? 'week' : 'day';
  const productionPeriod = scheduleBillingCycle === 2 ? 'year' : 'month';
  const period = process.env.REACT_APP_ZAPIER_DEV ? cheatedPeriod : productionPeriod;

  const zapierAddOnMetter = _.get(data, 'zapierAddOnsMetter', null);
  const zapierAddOnsBase = _.get(data, 'zapierAddOnsBase', null);

  const zapierAddOns = !!_.get(pricingPlan, 'addOns.zapier', false);
  const isBillForAddOns = (useAddOns || zapierAddOns) && billingCycle === 2 && !planFee.amount;

  const dates = {
    nextCharge: next_payment_attempt,
    periodStart: isBillForAddOns ? period_start : next_payment_attempt,
    periodEnd: isBillForAddOns
      ? period_end
      : next_payment_attempt
      ? moment.unix(next_payment_attempt).add(1, period).unix()
      : null,
    addOnsFrom: isBillForAddOns
      ? period_start
      : next_payment_attempt
      ? moment.unix(next_payment_attempt).subtract(1, period).unix()
      : null,
  };

  const start = dates.periodStart ? moment.unix(dates.periodStart) : null;
  const end = dates.periodEnd ? moment.unix(dates.periodEnd) : null;
  const isSameYear = start && end ? start.isSame(end, 'year') : true;
  const addOnsFrom = dates.addOnsFrom ? moment.unix(dates.addOnsFrom) : null;
  const nextCharge = dates.nextCharge ? moment.unix(dates.nextCharge) : null;
  const renderUpToZapier = useMemo(() => {
    const volumes = _.get(zapierAddOnMetter, 'volumes', 0);
    let zapierUpTo = _.get(zapierAddOnMetter, 'upTo', 0);

    while (volumes > zapierUpTo) {
      zapierUpTo += 1000;
    }

    return zapierUpTo.toLocaleString('en-US');
  }, [zapierAddOnMetter]);

  const renderContent = () => {
    if (isStarterPlan) {
      return (
        <S.NoBill>
          You are on the <span className="highlight">Starter</span> Plan. There are no upcoming bills.
        </S.NoBill>
      );
    }

    return (
      <S.ContentContainer>
        <S.DetailContainer>
          {!isBillForAddOns ? (
            <S.Section>
              <S.SectionHeader>Your plan</S.SectionHeader>
              <S.SectionContent>
                <div className="data">
                  <div>
                    <span className="highlight">{plan.name} Plan</span> -&nbsp;
                    {pluralize('client', planFee.clients || 0, true)}
                  </div>
                  <div>{(Number(planFee.amount) || 0).toUSDFormat()}</div>
                </div>
                {useAddOns ? (
                  <div className="data">
                    <div>
                      <span className="highlight">Automation</span> - Add-on (
                      {pluralize('client', addOnsBase.clients || 0, true)})
                    </div>
                    <div>{(Number(addOnsBase.amount) || 0).toUSDFormat()}</div>
                  </div>
                ) : null}
                {paymentAddOnsBase.amount ? (
                  <div className="data">
                    <div>
                      <span className="highlight">Payment & Packages</span> - Add-on
                    </div>
                    <div>{(Number(paymentAddOnsBase.amount) || 0).toUSDFormat()}</div>
                  </div>
                ) : null}
                {resourceAddOnsBase.amount ? (
                  <div className="data">
                    <div>
                      <span className="highlight">On-demand Collections</span> - Add-on
                    </div>
                    <div>{(Number(resourceAddOnsBase.amount) || 0).toUSDFormat()}</div>
                  </div>
                ) : null}
                {_.get(zapierAddOnsBase, 'amount') ? (
                  <div className="data">
                    <div>
                      <span className="highlight">Zapier</span> - API Plugin (
                      {_.get(zapierAddOnsBase, 'volumes', 0).toLocaleString('en-US')} tasks)
                    </div>
                    <div>{Number(_.get(zapierAddOnsBase, 'amount', 0)).toUSDFormat()}</div>
                  </div>
                ) : null}
                {_.get(mealPlanAddOnsBase, 'amount') ? (
                  <div className="data">
                    <div>
                      <span className="highlight">Custom Meal Plan</span> - Add-on
                    </div>
                    <div>{(Number(mealPlanAddOnsBase.amount) || 0).toUSDFormat()}</div>
                  </div>
                ) : null}
              </S.SectionContent>
            </S.Section>
          ) : null}
          {(useAddOns || isZapierEnable) && (
            <S.Section>
              <S.SectionHeader>Current Usage Activity</S.SectionHeader>
              <S.SectionContent>
                {useAddOns && (
                  <>
                    <div className="data usage-label">
                      <div>
                        <span className="highlight">Automation</span> - Up to&nbsp;
                        {pluralize('client', addOnsMetter.upTo || 0, true)}
                      </div>
                      <div>
                        {Number(addOnsMetter.amount) > 0 ? (Number(addOnsMetter.amount) || 0).toUSDFormat() : null}
                      </div>
                    </div>
                    <div className="data current-usage">
                      <div>
                        <span className="highlight">Current Usage:</span>&nbsp;
                        <span>
                          {pluralize('active Autoflow client', addOnsMetter.clients || 0, true)} from{' '}
                          {addOnsFrom ? addOnsFrom.format('MMM D') : '--'} until now
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {isZapierEnable && (
                  <>
                    <div className="data usage-label">
                      <div>
                        <span className="highlight">Zapier Integration</span> - Up to {renderUpToZapier} tasks
                      </div>
                      <div>
                        {Number(zapierAddOnMetter.amount) > 0
                          ? Number(_.get(zapierAddOnMetter, 'amount', 0)).toUSDFormat()
                          : null}
                      </div>
                    </div>
                    <div className="data current-usage">
                      <div>
                        <span className="highlight">Current Usage:</span>&nbsp;
                        <span>
                          {_.get(zapierAddOnMetter, 'volumes', 0).toLocaleString('en-US')} tasks from{' '}
                          {addOnsFrom ? addOnsFrom.format('MMM D') : '--'} until now
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </S.SectionContent>
            </S.Section>
          )}
        </S.DetailContainer>
        <S.SummaryContainer>
          <div className="total">
            <div>
              Total due on&nbsp;
              <span className="highlight">{nextCharge ? nextCharge.format('MMM D, YYYY') : '--'}</span>
            </div>
            <div className="amount">{(Number(total) || 0).toUSDFormat()}</div>
          </div>
          <div className="note">
            Based on your current configuration, you will be automatically charged {(Number(total) || 0).toUSDFormat()}
          </div>
        </S.SummaryContainer>
      </S.ContentContainer>
    );
  };

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      className="modal--upcoming-billing"
      onClose={props.onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.ContentWrapper starterPlan={isStarterPlan}>
          <ModalLayout.Header>
            <div>
              <div className="title">Upcoming Bill</div>
              {!isStarterPlan ? (
                <div className="sub-title">
                  <span>Service Period:&nbsp;</span>
                  <span className="sBold">
                    {start ? start.format(isSameYear ? 'MMM D' : 'MMM D, YYYY') : '--'} -{' '}
                    {end ? end.format('MMM D, YYYY') : '--'}
                  </span>
                </div>
              ) : null}
            </div>
            <UpgradePathButton
              text={isStarterPlan ? 'Upgrade' : 'View Plan'}
              className="button--view-plan"
              onClick={props.onClose}
            />
          </ModalLayout.Header>
          <ModalLayout.Content>{renderContent()}</ModalLayout.Content>
        </S.ContentWrapper>
      </Modal.Content>
    </Modal>
  );
}

export default UpcomingBill;
