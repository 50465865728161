import React from 'react';
import _ from 'lodash';
import { SortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc';

import { isS3FileURL } from 'utils/validations';
import { replaceImageURL } from 'utils/commonFunction';
import { RemoveIcon } from 'shared/Icons';
import MediaPreview from 'shared/MediaPreview';

import { CDN_URL } from 'constants/commonData';

import * as S from './styles';

const DragHandle = SortableHandle(() => (
  <div className="exercise-item__sort">
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="15" height="2" fill="#DCDCDE" />
      <rect y="4" width="15" height="2" fill="#DCDCDE" />
      <rect y="8" width="15" height="2" fill="#DCDCDE" />
    </svg>
  </div>
));

const SortableItem = SortableElement(({ data, itemIndex, onRemove }) => {
  const { preview_300, video, videoLink, picture } = data;
  const url = (picture || [])[0];
  const thumbnail = preview_300 || (isS3FileURL(url) ? replaceImageURL(url) : url);
  const isVideo = !!video || !!videoLink;

  const onClickRemove = () => {
    onRemove(itemIndex);
  };

  return (
    <S.ExerciseItem className="alt-exs__exercise-item" key={data._id}>
      <div className="index">{itemIndex + 1}.</div>
      <div className="info">
        <MediaPreview
          isVideo={isVideo}
          thumbnail={thumbnail}
          src={video || videoLink || (picture || [])[0]}
          defaultThumbnail={`${CDN_URL}/images/exercise_grey.svg`}
          size={{ width: 30, height: 30 }}
          indicatorSize={{ width: 10, height: 10 }}
        />
        <div className="title">{data.title}</div>
        <DragHandle />
      </div>
      <RemoveIcon onClick={onClickRemove} className="remove" size={15} />
    </S.ExerciseItem>
  );
});

const SortableList = SortableContainer(({ items, onClickRemoveIcon }) => (
  <S.ExerciseMenu className="alt-exs__exercise-menu" onScroll={e => e.stopPropagation()}>
    {_.map(items, (item, index) => (
      <SortableItem key={`item-${item._id}`} itemIndex={index} index={index} data={item} onRemove={onClickRemoveIcon} />
    ))}
  </S.ExerciseMenu>
));

export default SortableList;
