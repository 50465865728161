import styled from 'styled-components';

export const customStyleSingle = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#ffffff',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '42px',
    maxHeight: '42px',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FFFFFF',
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    cursor: 'pointer',
  }),
  input: (baseStyle, state) => ({
    ...baseStyle,
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '282px',
    marginTop: '4px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '280px',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingRight: '4px',
    marginRight: '4px',
    '::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#F5F5FA' : '#ffffff',
    cursor: 'pointer',
    maxHeight: '63px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    padding: '12px',
    '.member-icon': {
      width: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexShrink: 0,
    },
    '.member-role': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#505373',
    },
    '.member-content': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    '.member-name': {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#505373',
      textOverflow: 'ellipsis',
      '.you': {
        fontWeight: 400,
      },
    },
    ':active': {
      backgroundColor: '#F5F5FA',
    },
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: 'rgba(50, 60, 71, 0.3)',
    userSelect: 'none',
    marginLeft: 'unset',
    marginRight: 'unset',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '12px',
    gap: '8px',
    cursor: 'text',
    WebkitMaskImage: 'linear-gradient(90deg, #000 90%, transparent)',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  loadingMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  singleValue: (baseStyle, state) => ({
    ...baseStyle,
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textOverflow: 'unset',
  }),
};

export const customStyleMultiple = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#ffffff',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '38px',
    maxHeight: '38px',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FAFBFC',
  }),
  input: (baseStyle, state) => ({
    ...baseStyle,
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    cursor: 'pointer',
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    minWidth: '522px',
    maxHeight: '282px',
    marginTop: '4px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
    '.teammate-loading': {
      color: '#505373',
      padding: '8px 12px',
      textAlign: 'center',
      boxSizing: 'border-box',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      userSelect: 'none',
      minHeight: '65px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '280px',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingRight: '4px',
    marginRight: '4px',
    '::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
    left: baseStyle.left + baseStyle.width - 522,
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#F5F5FA' : '#ffffff',
    cursor: 'pointer',
    maxHeight: '63px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    padding: '12px',
    '.member-icon': {
      width: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexShrink: 0,
    },
    '.member-role': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#505373',
    },
    '.member-content': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    '.member-name': {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#505373',
      textOverflow: 'ellipsis',
      '.you': {
        fontWeight: 400,
      },
    },
    ':active': {
      backgroundColor: '#F5F5FA',
    },
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: 'rgba(50, 60, 71, 0.3)',
    userSelect: 'none',
    marginLeft: 'unset',
    marginRight: 'unset',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '12px',
    gap: '8px',
    cursor: 'text',
    WebkitMaskImage: 'linear-gradient(90deg, #000 60%, transparent)',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  loadingMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  singleValue: (baseStyle, state) => ({
    ...baseStyle,
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textOverflow: 'unset',
  }),
};

export const customStyleImport = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#ffffff',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '42px',
    maxHeight: '42px',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FFFFFF',
    cursor: 'pointer',
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    cursor: 'pointer',
  }),
  input: (baseStyle, state) => ({
    ...baseStyle,
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    minWidth: '522px',
    maxHeight: '282px',
    marginTop: '4px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '280px',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingRight: '4px',
    marginRight: '4px',
    '::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#F5F5FA' : '#ffffff',
    cursor: 'pointer',
    maxHeight: '63px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    padding: '12px',
    '.member-icon': {
      width: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexShrink: 0,
    },
    '.member-role': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#505373',
    },
    '.member-content': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    '.member-name': {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
      color: state.isFocused || state.isSelected ? '#5158CF' : '#505373',
      textOverflow: 'ellipsis',
      '.you': {
        fontWeight: 400,
      },
    },
    ':active': {
      backgroundColor: '#F5F5FA',
    },
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: 'rgba(50, 60, 71, 0.3)',
    userSelect: 'none',
    marginLeft: 'unset',
    marginRight: 'unset',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '12px',
    gap: '8px',
    cursor: 'text',
    WebkitMaskImage: 'linear-gradient(90deg, #000 60%, transparent)',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  loadingMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  singleValue: (baseStyle, state) => ({
    ...baseStyle,
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textOverflow: 'unset',
  }),
};

export const TeammateDropdownWrapper = styled.div`
  .teammate-loading {
    color: #505373;
    padding: 8px 12px;
    text-align: center;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    user-select: none;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .teammate__dropdown-indicator :hover {
    cursor: pointer;
  }
`;
