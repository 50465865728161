import React from 'react';
import { Image } from 'semantic-ui-react';
import withResponsive from 'hoc/withResponsive';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';

function Container(props) {
  const handleOpenNewTab = () => {
    const path = `https://everfit.io${window.location.search}`;
    window.open(path);
  };
  return (
    <div className="login-container">
      <div className="left-banner--mobile">
        <Image src={`${CDN_URL}/images/logo.png`} />
      </div>
      <div className="left-banner">
        <Image src={`${CDN_URL}/images/left-banner.jpg`} />
        <div className="portfolio">
          <div onClick={handleOpenNewTab}>
            <Image src={`${CDN_URL}/images/logo.svg`} width="35" height="54" />
            <Image src={`${CDN_URL}/images/everfit_wordmark.png`} width="88" height="22" />
          </div>
          <span>
            Creating and managing your clients is easy as 1-2-3. Easily invite the clients you want to connect through
            the platform and start training.
          </span>
        </div>
      </div>
      <div className="right-content">
        {props.children}
        <Image src={`${CDN_URL}/images/welcome.svg`} style={{ display: 'none' }} />
        <Image src={`${CDN_URL}/images/logo_neon.png`} style={{ display: 'none' }} />
        <Image src={`${CDN_URL}/images/client_purple.svg`} style={{ display: 'none' }} />
        <Image src={`${CDN_URL}/images/workout_builder_purple.svg`} style={{ display: 'none' }} />
        <Image src={`${CDN_URL}/images/workout_libary_purple.svg`} style={{ display: 'none' }} />
        <Image src={`${CDN_URL}/images/devide_reminder.jpg`} style={{ display: 'none' }} />
      </div>
    </div>
  );
}

export default withResponsive(Container);
