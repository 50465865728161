// Libs
import React from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import classNames from 'classnames';

// Shared
import { Checkbox } from 'shared/FormControl';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// Components
import AddSingleIngredientModal from '../AddSingleIngredientModal';

// Helpers
import { convertS3UrlToCloudFrontUrl, timeSince, pluralize } from 'utils/commonFunction';

// Shared
import { SharedTooltip } from 'shared/SharedTooltip';
import OwnerAvatar from 'shared/OwnerAvatar';

import { CDN_URL } from 'constants/commonData';

// Assets
import defaultImg from 'assets/icons/Ingredients/default_ingredient_image.png';
import { ReactComponent as EditIcon } from 'assets/icons/MealPlans/action_edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';

// Styles
import * as S from './style';

const IngredientTableBody = props => {
  const {
    toggleModal,
    ingredients,
    cloudfrontList,
    editIngredient,
    selectedIngredient,
    ingredientCategory,
    toggleConfirmModal,
    selectedIngredients,
    removeMultiIngredient,
    userId = '',
  } = props;

  const renderCustom = ingredient => {
    const { type, _id } = ingredient;

    const tooltipId = `tooltip--ingredient__${_id}`;
    let text = 'Custom Ingredient',
      icon = (
        <img
          className="type-ingredient"
          src={`${CDN_URL}/images/check_icon.png`}
          width="19"
          data-tip
          data-for={tooltipId}
          alt=""
        />
      );

    if (type !== 'custom') {
      text = 'System Ingredient';
      icon = (
        <img
          className="type-ingredient"
          src={`${CDN_URL}/images/lib_icon_everfit.svg`}
          data-tip
          data-for={tooltipId}
          alt=""
        />
      );
    }

    return (
      <>
        {icon}
        <ReactTooltip className="app-tooltip" place="top" id={tooltipId} effect="solid">
          <span>{text}</span>
        </ReactTooltip>
      </>
    );
  };

  const handleEditIngredient = (data, ingredientId) => {
    editIngredient(data, ingredientId).then(() => {
      toggleModal(false);
      toast('Ingredient has been saved.');
    });
  };

  const onOpenIngredientModal = ingredient => () => {
    const { _id = '', type = '', categories = [], author = {}, default_unit = {} } = ingredient || {};
    const {
      _id: idDefaultUnit = '',
      unit_group = '',
      unit_type = '',
      name: nameDefaultUnit = '',
      formula: formulaDefaultUnit = '',
    } = default_unit || {};

    const initData = {
      name: ingredient.name,
      categories: ingredient.categories.map(({ _id }) => _id) || [],
      default_unit: idDefaultUnit,
    };

    const initLabelViewMode = {
      categoryLabel: renderCategory(categories),
      unitLabel: `${nameDefaultUnit}${formulaDefaultUnit && unit_group !== 'other' ? ` (${formulaDefaultUnit})` : ''}`,
    };

    const isCustom = type === 'custom';
    const isOwnerIngredient = userId === (author || {})._id;

    const isEditMode = isCustom && isOwnerIngredient;

    let unitType = '';
    const unitGroup = unit_group;
    if (unitGroup === 'other') {
      unitType = unitGroup;
    } else {
      unitType = unit_type;
    }

    toggleModal(
      true,
      <AddSingleIngredientModal
        isViewMode={!isEditMode}
        isEditMode={isEditMode}
        optionsCategory={ingredientCategory}
        onSubmitForm={data => handleEditIngredient(data, _id)}
        initLabelViewMode={initLabelViewMode}
        unitType={pluralize(unitType)}
        ingredientId={_id}
      />,
    );
  };

  const removeIngredient = id => () => {
    removeMultiIngredient([id]);
  };

  const handleRemoveIngredient = ingredient => () => {
    const { _id } = ingredient;
    toggleConfirmModal(
      true,
      <S.RemoveIngredientModal
        className="remove-ingredient"
        onConfirm={removeIngredient(_id)}
        title="Remove Ingredient?"
        toggleConfirmModal={toggleConfirmModal}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        hasCloseIcon
        content="Would you like to remove this ingredient?"
      />,
    );
  };

  const renderActions = ingredient => {
    const { type = '', author = {} } = ingredient || {};
    const isOwnerIngredient = userId === (author || {})._id;
    return (
      <DropDown
        className={classNames('custom-dropdown-actions', { hidden: !(type === 'custom' && isOwnerIngredient) })}
        triggerIcon={({ open }) => (
          <div data-tip data-for="ingredient-item-options-tooltip">
            <MenuTrigger className="dropdown-trigger" vertical active={!!open} size={24} />
            <SharedTooltip id="ingredient-item-options-tooltip" />
          </div>
        )}
      >
        <Option key="edit" onClick={onOpenIngredientModal(ingredient)}>
          <div className="icon">
            <EditIcon className="ingredient-edit-icon" />
          </div>
          <span>Edit</span>
        </Option>
        <Option key="delete" onClick={handleRemoveIngredient(ingredient)}>
          <div className="icon">
            <RemoveIcon className="ingredient-remove-icon" />
          </div>
          <span>Remove</span>
        </Option>
      </DropDown>
    );
  };

  const handleSelectIngredient = (ingredient, e) => {
    e.stopPropagation();
    const { _id } = ingredient || {};

    if (e.target && e.target.checked) {
      typeof selectedIngredient === 'function' && selectedIngredient([...selectedIngredients, _id]);
    } else {
      typeof selectedIngredient === 'function' && selectedIngredient(filter(selectedIngredients, id => id !== _id));
    }
  };

  const renderCategory = (categories = []) => {
    if (categories.length > 0) {
      const categoryNames = categories.map(({ name = '' }) => name).join('<span class="dot"></span>');
      return <S.Category dangerouslySetInnerHTML={{ __html: categoryNames }}></S.Category>;
    }
    return '--';
  };

  return (
    <S.TableBody>
      {map(ingredients, ingredient => {
        const {
          _id = '',
          name = '',
          categories = [],
          updated_at = '',
          type = '',
          image_thumbnail = '',
          author = {},
          default_unit = {},
        } = ingredient || {};

        const { name: nameDefaultUnit = '', formula: formulaDefaultUnit = '', unit_group = '' } = default_unit || {};

        const defaultUnitName = `${nameDefaultUnit}${
          formulaDefaultUnit && unit_group !== 'other' ? ` (${formulaDefaultUnit})` : ''
        }`;

        const thumbnail = convertS3UrlToCloudFrontUrl(image_thumbnail, cloudfrontList, true);
        const isChecked = selectedIngredients.includes(_id);
        const isTypeCustom = type === 'custom';
        const isOwnerIngredient = userId === (author || {})._id;

        return (
          <S.TableRow key={_id} onClick={onOpenIngredientModal(ingredient)}>
            <S.TableCell key="checkbox" className="name">
              <S.CheckboxContainer
                allowActive={!(isTypeCustom && isOwnerIngredient)}
                onClick={e => e.stopPropagation()}
                className={isChecked ? 'checked' : null}
              >
                <div className="checkbox__thumbnail">
                  <Checkbox
                    className="checkbox__tasks"
                    checked={isChecked}
                    onChange={e => handleSelectIngredient(ingredient, e)}
                  />
                </div>
                <S.ThumbnailContainer className="thumbnail-container thumbnail-task">
                  <S.Thumbnail src={thumbnail || defaultImg} alt={name} />
                </S.ThumbnailContainer>
              </S.CheckboxContainer>
            </S.TableCell>
            <S.TableCell key="name">
              <div className="custom-name">{name}</div>
            </S.TableCell>
            <S.TableCell key="category">{renderCategory(categories)}</S.TableCell>
            <S.TableCell key="unit">{defaultUnitName}</S.TableCell>
            <S.TableCell key="most-recent" className="custom-align-center" alignCenter>
              {updated_at && type === 'custom' ? timeSince(new Date(updated_at)) : '--'}
            </S.TableCell>
            {/* TODO: Remove from ver 3.31.16 */}
            {/* <S.TableCell key="type" className="custom-align-center" alignCenter>
              {renderCustom(ingredient)}
            </S.TableCell> */}
            <S.TableCell key="actions" className="action">
              <S.ActionContentWrapper>
                {!isOwnerIngredient && isTypeCustom && (
                  <OwnerAvatar data={author} tooltipId={`tooltip--owner-${_id}`} size={25} className="owner-avatar" />
                )}
                {renderActions(ingredient)}
              </S.ActionContentWrapper>
            </S.TableCell>
          </S.TableRow>
        );
      })}
    </S.TableBody>
  );
};

export default IngredientTableBody;
