import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { encryptData } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${CDN_URL}/images/inbox_icon.svg) center center no-repeat;
  cursor: pointer;

  :hover {
    background: url(${CDN_URL}/images/inbox_icon_hover.svg) center center no-repeat;
    border-radius: 6px;
    border: solid 1px #5c58c0;
  }
`;

function ClientInboxIcon(props) {
  const { user, dispatch } = props;
  const isInboxFeaturing = user.client_connection === 1 && user.inbox_preferences && user.inbox_preferences.state;

  if (!isInboxFeaturing) {
    return <div style={{ minWidth: '30px' }} />;
  }

  const onClick = e => {
    e.stopPropagation();
    dispatch(push(`/home/inbox?profileId=${user._id}&ufn=${encryptData(user.first_name + user.last_name)}`));

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <Icon isInboxFeaturing={isInboxFeaturing} onClick={onClick} data-tip data-for={`inbox-${user._id}`}>
      <ReactTooltip delayShow={1000} className="app-tooltip" id={`inbox-${user._id}`} effect="solid" place={'top'}>
        <span>Message Client</span>
      </ReactTooltip>
    </Icon>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ClientInboxIcon);
