import get from 'lodash/get';
import React, { useContext, useMemo } from 'react';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { NewLabel, SidebarItemContainer } from '../styles';
import { SidebarContext } from 'database/contexts';
import { CLIENT_STATUS, DEFAULT_CLIENT_FILTERS } from 'constants/commonData';

import { ReactComponent as NewLabelIcon } from 'assets/icons/new_label.svg';
import { convertSegmentToFilters, removeFristLastSlash } from 'utils/commonFunction';

const SidebarItem = ({
  className,
  name,
  route,
  subItems,
  dispatch,
  icon,
  isActive,
  children,
  title,
  isOwner,
  permission,
  isTeamAdmin,
  tooltips = [],
  group,
  user,
  selectedSegment,
  onChangeSegment,
  markSeenWaitingList,
  activeWaitingSegment,
  router,
  ...otherProps
}) => {
  const { activeItem } = useContext(SidebarContext);
  const active = typeof isActive === 'function' ? isActive(activeItem) : activeItem === name;
  const subItemsData = useMemo(() => subItems || [{ route, title }], [subItems]);
  const hasMP = get(permission, 'marketplace_payment', false);

  const handleClickSubItem = e => {
    const { route } = e.currentTarget.dataset;
    e.stopPropagation();
    dispatch(push(route));
  };

  /* =========================
  Only use for Client group
  */
  const handleClickSegment = item => {
    if (!item) {
      onChangeSegment(null, Object.assign({}, DEFAULT_CLIENT_FILTERS, { ownerShip: user._id }));
    } else {
      if (item.client_connection === CLIENT_STATUS.waiting) {
        // Waiting Activation Clients
        if (selectedSegment !== item._id) {
          if (user.has_waiting_client) {
            markSeenWaitingList && markSeenWaitingList();
          }

          activeWaitingSegment && activeWaitingSegment(item._id);

          dispatch(push('/home/client/waiting-activation'));
        }

        return;
      }

      if (selectedSegment !== item._id) {
        const isPermitDenied = !permission.segment && !item.is_default;
        const filters = convertSegmentToFilters(item, user);
        onChangeSegment && onChangeSegment(item._id, filters, isPermitDenied);
      }
    }

    checkCurrentPathname();
  };

  const checkCurrentPathname = () => {
    const currentPath = removeFristLastSlash(router.location.pathname);
    if (currentPath !== 'home/client') {
      dispatch(push('/home/client'));
    }
  };
  // =========================

  return (
    <SidebarItemContainer className={classnames('sidebar__item', name, className, { active: active })} {...otherProps}>
      <Link
        to={route}
        onClick={e => {
          if (active && activeItem === 'inbox') {
            e.preventDefault();
          }
        }}
      >
        {icon}
        {name === process.env.REACT_APP_NEW_FEATURE_LABEL && (
          <NewLabel>
            <NewLabelIcon />
          </NewLabel>
        )}
      </Link>
      <div className="menu-item-dropdown-content">
        <ul className="list-dropdown ">
          {/* Group name */}
          {group && (
            <li
              key={group.route}
              className={classnames('list-dropdown-item isHeader', {
                isOnlyGroup: subItemsData.length <= 1 && !tooltips.length ? true : false,
              })}
              onClick={event => {
                event.stopPropagation();
              }}
              data-route={group.route}
            >
              {group.icon && group.icon}
              <div>{group.title}</div>
            </li>
          )}
          {/* Default list */}
          {subItemsData.length > 1 &&
            subItemsData.map((item, index) => {
              if (!hasMP && item.title === 'Marketplace') {
                return null;
              }
              if (
                item.title === 'Forms & Questionnaires' &&
                (!otherProps.isFormPermission || !process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES) &&
                !process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH
              ) {
                return null;
              } else if (
                item.title === 'Tasks' &&
                (!otherProps.isTaskLibraryPermission || !process.env.REACT_APP_ENABLE_TASKS_LIBRARY)
              ) {
                return null;
              } else if (item.title === 'Setup Payment' && !isOwner) {
                return null;
              } else if (
                ['Payment Activity', 'Setup Payment', 'Coach Bios'].includes(item.title) &&
                !permission.payment &&
                !permission.explore_payment
              ) {
                return null;
              } else if (item.title === 'Settings' && !permission.studio_resource_collection) {
                return null;
              }

              return (
                <li
                  key={item.route + '-' + index}
                  className="list-dropdown-item"
                  onClick={handleClickSubItem}
                  data-route={item.route}
                >
                  <div>{item.title}</div>
                </li>
              );
            })}
          {/* Custom list */}
          {tooltips &&
            tooltips.map((item, index) => (
              <li
                key={index}
                className="list-dropdown-item"
                onClick={() => handleClickSegment(item.segment)}
                data-action={item.id}
              >
                <div>{item.title}</div>
              </li>
            ))}
        </ul>
      </div>
      {children}
    </SidebarItemContainer>
  );
};

export default connect()(SidebarItem);
