import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { diff } from 'deep-diff';

import { QUESTION_TYPES } from 'constants/commonData';
import {
  ASSET_TYPES,
  TRIGGER_FORMS_PICK_PROTOTYPE,
  PROGRAM_TRIGGER_OPTIONS_PICK_PROTOTYPE,
  PROGRAM_PICK_PROTOTYPE,
  FORUM_TRIGGER_OPTIONS_PICK_PROTOTYPE,
  FORUM_PICK_PROTOTYPE,
  INIT_STATE_TRIGGER_OPTIONS,
  EMPTY_OPTION_TRIGGER,
} from './index';

export const getTriggerQuestions = (questions, isCustomForm = false, selected_questions = []) =>
  questions.reduce((accumulator, currentValue, currentIndex) => {
    const hasTrigger = currentValue.type === QUESTION_TYPES.SINGLE_CHOICE;

    if (
      (isCustomForm && hasTrigger) ||
      (!isCustomForm && hasTrigger && selected_questions.includes(currentValue._id))
    ) {
      return [...accumulator, { ...currentValue, index: currentIndex }];
    }

    return accumulator;
  }, []);

export const getFormProperties = (data, { checkHasTrigger = false, isFilterTriggerQuestions = false } = {}) => {
  const { form, custom_form, selected_questions } = data || {};
  const currentForm = form || custom_form || {};
  const { title, name, has_trigger = false, hasTrigger = false } = currentForm;
  const questions = currentForm.questions || [];
  const formTitle = title || name;
  const isCustomForm = !!custom_form;

  // check has trigger questions
  let isHasTrigger = has_trigger || hasTrigger || false;
  if (checkHasTrigger && !isHasTrigger) {
    isHasTrigger = questions.some(question => (question || {}).type === QUESTION_TYPES.SINGLE_CHOICE);
  }

  // get trigger questions
  let triggerQuestions = [];
  if (isFilterTriggerQuestions) {
    triggerQuestions = getTriggerQuestions(questions, isCustomForm, selected_questions);
  }

  return { ...currentForm, selected_questions, formTitle, isCustomForm, hasTrigger: isHasTrigger, triggerQuestions };
};

export const handleTriggerScroll = (selector, callback) => {
  const documentDom = document.querySelector(selector);
  if (documentDom) {
    documentDom.addEventListener('scroll', event => {
      typeof callback === 'function' && callback();
    });
  }
  return () => {
    if (documentDom) {
      documentDom.removeEventListener('scroll', event => {});
    }
  };
};

const formatProgramTriggerOption = it => {
  const option = pick(it, PROGRAM_TRIGGER_OPTIONS_PICK_PROTOTYPE);

  if (option.program_library_data) {
    option.program_library_data = pick(option.program_library_data, PROGRAM_PICK_PROTOTYPE);
  }

  return option;
};

const formatForumTriggerOption = it => {
  const option = pick(it, FORUM_TRIGGER_OPTIONS_PICK_PROTOTYPE);

  if (option.forum_data) {
    option.forum_data = pick(option.forum_data, FORUM_PICK_PROTOTYPE);
  }

  return option;
};

export const formatParamsTrigger = (triggers, type) => {
  if (!Array.isArray(triggers)) return [];
  const results = [];

  triggers.forEach(trigger => {
    if (!trigger) return;

    const params = pick(trigger, TRIGGER_FORMS_PICK_PROTOTYPE);

    // Force remove value null
    if (!params.form) {
      delete params.form;
    }

    if (!params.custom_form) {
      delete params.custom_form;
    }

    // Save question title
    if (typeof get(trigger, 'question_data.title') === 'string') {
      params.title = trigger.question_data.title;
    }

    if (Array.isArray(params.trigger_options)) {
      const options = params.trigger_options.reduce((accumulator, currentValue) => {
        if (type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS && !!currentValue.program_library) {
          return [...accumulator, formatProgramTriggerOption(currentValue)];
        }

        if (type === ASSET_TYPES.FORUM_TRIGGER_FORMS && !!currentValue.forum) {
          return [...accumulator, formatForumTriggerOption(currentValue)];
        }

        return accumulator;
      }, []);

      params.trigger_options = options;
    }

    results.push(params);
  });

  return results;
};

export const formatExtendDataTriggerForms = ({ triggerForms, forms }) => {
  const results = cloneDeep(triggerForms).map(triggerForm => {
    // form_data
    const formData = forms.find(it => {
      const { _id } = getFormProperties(it);

      return _id === triggerForm.form || _id === triggerForm.custom_form;
    });

    if (!formData) {
      return {
        ...triggerForm,
        is_form_deleted: true,
        form_data: {
          form: {
            title: '',
            total_single_choice_questions: triggerForm.number_option_answers,
          },
        },
        question_data: {
          title: triggerForm.title || '',
        },
      };
    }

    // question_data and questions
    const { is_deleted, is_resolved, title } = triggerForm;
    const { triggerQuestions } = getFormProperties(formData, {
      isFilterTriggerQuestions: true,
    });
    let initQuestion = triggerQuestions.find(
      it => it.question_original_id === triggerForm.question || it._id === triggerForm.question,
    );

    // trigger_options
    let initOptions = [];
    if (initQuestion) {
      // extend data for question
      if (!initQuestion.is_deleted && is_deleted) initQuestion.is_deleted = is_deleted;
      if (!initQuestion.is_resolved && is_resolved) initQuestion.is_resolved = is_resolved;
      if (!initQuestion.title && title) initQuestion.title = title;

      // map data for option
      initOptions = initQuestion.options || initQuestion.option_answers || [];
      initOptions = initOptions.map(it => {
        let initTriggerOption = triggerForm.trigger_options.find(option => it._id === option.question_option);

        if (!initTriggerOption) {
          initTriggerOption = {
            program_library_data: EMPTY_OPTION_TRIGGER,
            forum_data: EMPTY_OPTION_TRIGGER,
          };
        }

        if (!initTriggerOption.is_deleted && is_deleted) initTriggerOption.is_deleted = is_deleted;

        return {
          ...it,
          ...INIT_STATE_TRIGGER_OPTIONS,
          ...initTriggerOption,
          question_option: it._id,
        };
      });
    } else {
      initQuestion = {
        _id: triggerForm.question,
        title: triggerForm.title,
        is_deleted: true,
      };
    }

    const dataTriggerOptions = convertDataTriggerOptions(
      initOptions || [],
      get(triggerForms, '[0].trigger_options', []) || [],
    );

    return {
      ...triggerForm,
      form_data: triggerForm.form ? formData : null,
      custom_form_data: triggerForm.custom_form ? formData : null,
      question_data: initQuestion,
      questions: triggerQuestions,
      trigger_options: dataTriggerOptions || [],
    };
  });

  return results;
};

const convertDataTriggerOptions = (originalData = [], triggerData = []) => {
  const originalDataIds = originalData.map(item => (item || {})._id);

  const filteredTriggerData = triggerData
    .map(item => ({ ...item, _id: (item || {}).question_option }))
    .filter(item => !originalDataIds.includes((item || {})._id));

  const updatedOriginalData = originalData.map(item => {
    const triggerItem = triggerData.find(trigger => (trigger || {}).question_option === (item || {})._id);
    if (!isEmpty(triggerItem)) {
      return { ...item, is_deleted: (triggerItem || {}).is_deleted };
    }
    return item;
  });
  return [...filteredTriggerData, ...updatedOriginalData];
};

const KEY_COMPARE_TRIGGER = ['form', 'custom_form', 'question', 'trigger_options'];
const KEY_COMPARE_OPTION_TRIGGER = ['program_library', 'forum', 'question_option'];

const mapPickValue = it => {
  const trigger = pick(it, KEY_COMPARE_TRIGGER);

  trigger.trigger_options = trigger.trigger_options.reduce((accumulator, currentValue) => {
    const content = pick(currentValue, KEY_COMPARE_OPTION_TRIGGER);

    if (!content.program_library) {
      delete content.program_library;
    }

    if (!content.forum) {
      delete content.forum;
    }

    if (!!content.program_library || !!content.forum) {
      return [...accumulator, content];
    }

    return accumulator;
  }, []);

  return trigger;
};

export const checkIsTriggerChange = ({ keySource, source, keyState, state }) => {
  try {
    const programTrigger = get(source, keySource, []);
    const stateProgramTrigger = get(state, keyState, []);

    const sourcePick = programTrigger.map(mapPickValue);
    const statePick = stateProgramTrigger.map(mapPickValue);

    const diffValue = diff(sourcePick, statePick);

    return !!diffValue;
  } catch (error) {
    return false;
  }
};

export const formatParamsResolveIssues = (triggers, type) => {
  if (!Array.isArray(triggers)) return [];
  const results = [];

  triggers.forEach(trigger => {
    if (!trigger) return;

    const { trigger_options } = trigger;

    trigger_options.forEach(it => {
      const { is_rename_resolved } = it;

      const isResolvedQuestionOption = is_rename_resolved === true;

      if (isResolvedQuestionOption) {
        results.push({
          question_option: it.question_option,
        });
      }
    });
  });

  return results;
};

export const formatTriggerAfterToggleStatusQuestion = ({
  triggerForms,
  forms,
  questions,
  isCustomForm,
  selectedQuestions,
  isDeleted,
}) => {
  try {
    const newTriggerState = formatExtendDataTriggerForms({
      triggerForms,
      forms,
    });

    return {
      ...newTriggerState[0],
      is_deleted: isDeleted,
      questions: getTriggerQuestions(questions, isCustomForm, selectedQuestions),
      question_data: {
        ...triggerForms[0].question_data,
        is_deleted: isDeleted,
      },
      trigger_options: triggerForms[0].trigger_options.map(option => ({
        ...option,
        is_deleted: isDeleted,
      })),
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};
