import { Modal, Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { ASSETS_SHARE_SETTING_TYPE } from './constants';

export const Wrapper = styled.div`
  position: relative;
  display: block;
`;

export const FieldLabel = styled.div`
  color: #6a778a;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const FieldTextBox = styled.div`
  height: 42px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  border-radius: 4px;
  border: 1px solid #dcdcde;
  background: #fafbfc;
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  b {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
  }
`;

export const customSelectOwnerStyles = {
  container: baseStyle => ({
    ...baseStyle,
    background: '#FAFBFC',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '42px',
    maxHeight: '42px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FAFBFC',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 12px',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '8px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    overflowX: 'hidden',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#edecfc' : '#ffffff',
    ':hover': {
      backgroundColor: '#edecfc',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isFocused || state.isSelected ? '#5158CF' : '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : '400',
    lineHeight: '18px',
    padding: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

export const customSelectStatusStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#FAFBFC',
    borderRadius: '4px',
    pointerEvents: 'auto',
    opacity: state.isDisabled ? 0.4 : 1,
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '42px',
    maxHeight: '42px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FAFBFC',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 12px',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '8px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#edecfc' : '#ffffff',
    ':hover': {
      backgroundColor: '#edecfc',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isFocused || state.isSelected ? '#5158CF' : '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : '400',
    lineHeight: '18px',
    padding: '12px',
  }),
};

const getFormatColumnCell = type => {
  switch (type) {
    case ASSETS_SHARE_SETTING_TYPE.SHARE_SETTING_MODAL:
      return css`
        gap: 10px;

        ${Wrapper} {
          width: 100%;
        }

        ${FieldTextBox} {
          height: 36px;
          padding-left: 14px;
        }

        .owner__control,
        .share__control {
          min-height: 36px;
          max-height: 36px;
        }

        .share__single-value {
          margin-top: 0px;
        }

        .share__dropdown-indicator {
          padding-right: 8px;
        }
      `;
    case ASSETS_SHARE_SETTING_TYPE.OWNERSHIP_POPUP:
      return css`
        gap: 20px;

        ${Wrapper} {
          width: 100%;
        }

        ${FieldLabel} {
          margin-bottom: 3px;
        }

        ${FieldTextBox} {
          height: 40px;
          padding: 0px 13px;
          border-radius: 8px;
          border: 1px solid #dcdcde;
          background: #fafbfc;
          color: #333;
          font-weight: 400;
          b {
            font-weight: 400;
          }
        }

        .select-share {
          border-radius: 8px;
        }

        .owner__control,
        .share__control {
          border-radius: 8px;
          min-height: 40px;
          max-height: 40px;
        }

        .share__value-container {
          padding: 0px 11px;
        }

        .owner__single-value,
        .share__single-value {
          color: #333;
          font-weight: 400;
        }

        .share__dropdown-indicator {
        }
      `;
    case 'default':
    default:
      return css`
        gap: 20px;

        ${Wrapper} {
          width: 100%;
        }

        ${FieldTextBox} {
          height: 42px;
        }

        .owner__control,
        .share__control {
          min-height: 42px;
          max-height: 42px;
        }
      `;
  }
};

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${props => props.type && getFormatColumnCell(props.type)};
`;

const getFormatRowCell = type => {
  switch (type) {
    case ASSETS_SHARE_SETTING_TYPE.STUDIO_PROGRAM_CREATE_COPY:
    case ASSETS_SHARE_SETTING_TYPE.DEFAULT:
    default:
      return css`
        gap: 20px;

        ${Wrapper} {
          width: 100%;
        }

        ${FieldTextBox} {
          height: 42px;
        }

        .share__control {
          min-height: 42px;
          max-height: 42px;
        }
      `;
  }
};

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${props => props.type && getFormatRowCell(props.type)};
`;

export const ShareSettingModalWrapper = styled(Modal)`
  border-radius: 8px !important;
  border: 1px solid #d4d4d5 !important;
  background: #fff !important;
  width: 563px !important;
  padding: 23px !important;

  > .header,
  .content,
  .actions {
    padding: 0 !important;
    background: #fff !important;
  }

  > .content {
    margin-top: 21px !important;
    margin-bottom: 45px !important;
  }

  > .actions {
    border-top: unset !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-right: -1px !important;
  }

  .cancel-button {
    color: #323c47 !important;
    text-align: center !important;
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;

    display: flex !important;
    width: 104px !important;
    height: 44px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;

    border-radius: 5px !important;
    border: 1px solid #d6dae4 !important;
    background: #fff !important;

    :hover {
      background-color: #f5f7f9 !important;
    }
  }

  .save-button {
    color: #fff !important;
    text-align: center !important;
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;

    display: flex !important;
    width: 102px !important;
    height: 44px !important;
    align-items: center !important;
    gap: 10px !important;

    border-radius: 5px !important;
    border: 1px solid #5158cf !important;
    background: #5158cf !important;

    :hover {
      background: rgba(81, 88, 207, 0.9) !important;
    }
    :disabled {
      border: 1px solid #c4c4c4 !important;
      background: #c4c4c4 !important;
    }
  }
`;

export const ModalTitle = styled.div`
  color: #000;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const IconStatusContainer = styled.div`
  display: inline-block;
  cursor: pointer;

  .__react_component_tooltip.app-tooltip {
    width: 85px;
    margin-left: -8px;

    &.place-top {
      margin-top: -2.5px;
    }

    ::after {
      right: -4px;
      border-top-color: #2d2e2d !important;
    }

    &.is-place {
      width: 59px;
      height: 36px;
      padding: 8px;
      padding-top: 9px;
      margin-left: 0px;
      border-radius: 5px;
      background-color: #2d2e2d;
    }

    .tooltip-content {
      color: #fff;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  > * {
    vertical-align: middle;
  }

  ${props =>
    props.share &&
    css`
      svg {
        circle {
          fill: #f0f1ff;
        }

        path {
          fill: #5158cf;
        }
      }
    `}
`;

export const CustomOwnershipPopup = styled(Popup)`
  &.ui.popup {
    background-color: #fff;
    border: 1px solid #d4d4d5 !important;
    border-radius: 8px !important;
    box-shadow: unset !important;
    z-index: 2000 !important;
  }
  &.ui.bottom.right.popup {
    margin-top: 18px;
    margin-right: -30px;
  }
  &.ui.bottom.popup:before {
    background: #fff;
    width: 15px;
    height: 15px;
    margin-right: ${props => (props.customSize ? '22px' : '24px')};
    border-top-left-radius: 1.5px;
    border-width: 0.25px;
    top: -7.5px !important;
    box-shadow: -1px -1px 0 0 #d4d4d5 !important;
  }
`;

export const CustomOwnershipPopupContent = styled.div`
  width: 312px;
  padding: 21px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }
  .header-icon {
    width: 11px;
    height: 11px;
  }
  .title {
    color: #000;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const TriggerIconPopup = styled.div`
  cursor: pointer;
  display: flex;
  width: ${props => (props.size ? `${props.size}px` : '32px')};
  height: ${props => (props.size ? `${props.size}px` : '32px')};
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .icon {
    display: block;
    width: 16px;
    height: 20px;
  }
  .icon.hover {
    display: none;
    path {
      fill: #5158cf;
    }
  }
  &:hover {
    border-radius: 5px;
    border: 1px solid #5158cf;
    background: #fff;
    .icon:not(.hover) {
      display: none;
    }
    .icon.hover {
      display: block;
    }
  }
  ${props =>
    props.open &&
    css`
      border-radius: 5px;
      border: 1px solid #5158cf;
      background: #fff;
      .icon:not(.hover) {
        display: none;
      }
      .icon.hover {
        display: block;
      }
    `}
  .__react_component_tooltip.app-tooltip-white {
    min-width: 165px !important;
    border: 1px solid #dadfea;
    padding: 8px 15px;
    text-align: center;
    &.custom-arrow.place-top:before {
      border-top: 11px solid #dadfea !important;
      bottom: -8px;
      scale: 0.4;
    }
    .tooltip-content {
      color: #202353;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .__react_component_tooltip.place-top {
    margin-top: -8px;
  }
`;

export const ShareOwnerAvatarWrapper = styled.div`
  line-height: 0px;

  .sb-avatar,
  .sb-avatar * {
    pointer-events: none;
  }

  .__react_component_tooltip.share-owner-avatar-tooltip {
    border-radius: 4px;
    background: #333;
    padding: 8px 16px;
    margin-top: -13px;
    max-width: 394px;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-break: break-word;
    white-space: pre-wrap;

    ::after {
      background-color: #333;
      width: 11px;
      height: 11px;
      border-radius: 2px;
      transform: rotate(45deg);
      border: none !important;
      bottom: -7px;
      margin-left: -6px;
    }
  }
`;

export const PopupBody = styled.div`
  max-height: 442px;
  overflow-y: scroll;
`;

export const PopupWrapper = styled.div`
  width: 380px;
  height: 213px;
  z-index: 999999;
  border-radius: 8px;
  border: 1px solid #d4d7d9;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  position: absolute;
  left: -1px;
  top: calc(100% + 8px);
  padding-bottom: 53px;
`;

export const PopupHeader = styled.div`
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PopupHeaderTitle = styled.span`
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ClearLabel = styled.span`
  color: #5c5bbd;
  text-align: right;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PopupGroup = styled.div`
  padding: 12px 15px;
`;

export const PopupGroupTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  display: flex;
  justify-content: space-between;
`;

export const PopupGroupContent = styled.div`
  margin-top: 23px;
  &.owner {
    display: inline-flex;
    gap: 20px;
    .radio label {
      color: #111;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
      padding-left: 21px !important;
      &::before {
        border-width: 1px;
      }
      :hover::before {
        border-color: #5158cf;
      }
    }
  }
`;

export const PopupFooter = styled.div`
  text-align: right;
  padding: 10px 15px;
  border-top: 1px solid #d4d7d9;
  position: absolute;
  bottom: 0;
  width: 100%;
  button {
    background: #5e59ff;
    color: #fff;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 95px;
    height: 32px;
    padding: 7px 23px 7px 25px;
    margin-right: unset !important;
    border: none;
    &:hover {
      background: rgba(94, 89, 255, 0.9);
      color: #fff;
    }
    &.disable {
      cursor: not-allowed;
      border-radius: 5px;
      background: #c4c4c4;
    }
  }
`;
