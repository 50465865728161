// Libs
import React, { useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_bold_circle.svg';

// Styles
import * as S from './styles';

// Constants
import { LIMIT_CHARACTER_SYNONYM } from 'constants/commonData';

const SynonymsItem = props => {
  const { index, content, handleChangeContent, lengthSynonym, handleRemoveSynonym, placeholder } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = value => {
    if (value.length > LIMIT_CHARACTER_SYNONYM) {
      value = value.substring(0, LIMIT_CHARACTER_SYNONYM);
    }

    handleChangeContent(value, index);
  };

  const handleRemoveItemClick = () => handleRemoveSynonym(index);

  return (
    <S.Item onClick={handleOpen} open={open}>
      {lengthSynonym > 1 && <RemoveIcon className="icon-remove" onClick={handleRemoveItemClick} />}
      <RootCloseWrapper onRootClose={handleClose}>
        <S.Content>
          <S.TextEditorWrapper
            value={content}
            onChange={e => handleInputChange(e.target.value)}
            placeholder={placeholder}
            contentEditable
            // breakLine={true}
            maxLength={LIMIT_CHARACTER_SYNONYM}
            lineHeight={18}
          />
          <p>{content}</p>
        </S.Content>
      </RootCloseWrapper>
    </S.Item>
  );
};

export default SynonymsItem;
