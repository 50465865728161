import styled from 'styled-components';
import { Wrapper } from 'shared/Styles/ModalLayout';
import { CDN_URL } from 'constants/commonData';

export const WarningContainer = styled.div`
  background: #fff4d9;
  border-radius: 5px;
  padding: 9px 15px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #b56a26;
  margin: 10px 15px 0;
`;

export const Pin = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
  background: transparent no-repeat center center;
  background-size: auto;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  height: 270px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-metric-indicator {
    padding-top: 0;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const TableContainer = styled.div`
  max-height: 270px;
  overflow-y: auto;
  padding-right: 20px;
  padding-left: 30px;

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    position: relative;
    width: 100%;

    tr {
      td,
      th {
        padding: 7px;
        text-align: left;

        :first-child {
          padding-left: 0;
        }

        :last-child {
          padding-right: 0;
          width: 30px;
        }

        :nth-child(2) {
          width: 100px;
        }

        :nth-child(3) {
          min-width: 120px;
          text-align: center;
          vertical-align: middle;
        }
      }

      th {
        padding-top: 0;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #6a778a;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;

        .header__pin-to-overview {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          img {
            margin-left: 5px;
          }
        }

        :nth-child(2) {
          text-align: left;
          padding-left: 18px;
        }

        .__react_component_tooltip.metric-pin-tooltip {
          min-width: unset;
          text-transform: none;
          width: 180px;
          font-weight: normal;
          padding: 15px;
        }
      }

      &.selected {
        .evf-checkbox {
          font-weight: 600;
        }
      }
    }
  }

  .evf-checkbox {
    padding-left: 26px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
  }

  .evf-dropdown {
    .evf-dropdown__trigger {
      width: 23px;
      height: 23px;
      border-radius: 3px;

      .dots {
        background-color: #c4c4c4 !important;
      }

      :hover {
        background-color: #f6f7f9;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #f6f7f9;
      }
    }

    .evf-dropdown__option {
      padding: 9px 20px;
      min-width: 150px;

      img {
        margin-right: 10px;
      }
    }
  }
`;

export const ListLabel = styled.div`
  width: 100%;
  background: #fafafa;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #000000;
  padding: 10px 30px;
  margin-bottom: 15px;
`;

export const MetricListContainer = styled.div``;

export const MetricSettingsWrapper = styled(Wrapper)`
  .metric-settings__header {
    padding: 30px 0 20px;
    display: block;
  }

  .metric-settings__content {
    padding: 0 5px 0 0;
  }

  .metric-settings__actions {
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    padding: 10px 30px;
    justify-content: space-between;

    button {
      margin: 0;

      &.button--add-new-metric {
        border: none;
        box-shadow: none;
        color: #758094;
        padding: 0;
      }
    }

    .features__toggle {
      position: relative;

      .features__tooltipUpgradePath {
        visibility: hidden;
        position: absolute;
        z-index: 4;
        opacity: 0;
        transition: opacity 0.35s linear;
      }

      &:hover {
        .features__tooltipUpgradePath {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.75s ease, left 0.25s linear;
        }
      }
    }
  }

  .unit-select-box {
    &.unit-select--is-disabled {
      pointer-events: auto;
    }

    .unit-select__control {
      box-shadow: none;
      border-color: transparent;
      border-radius: 3px;
      cursor: pointer;

      :hover,
      &--menu-is-open {
        border-color: #dadfea;
      }

      &--is-disabled {
        background-color: transparent;
        cursor: not-allowed;
        border: none !important;
      }

      .unit-select__value-container {
        padding: 2px 10px;
        overflow: visible;
        height: 100%;
      }
    }

    .unit-select__single-value {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: #263d52;
      margin: 0;
    }

    .unit-select__option {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      background-color: #fff;

      b {
        font-weight: 600;
      }

      :hover,
      &--is-selected {
        background-color: #f6f5ff;
        color: #5c5bbd;
      }
    }
  }

  .onboarding-tooltip {
    position: absolute;
    right: 30px;
    bottom: 350px;
  }
`;

export const ExtendInfo = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  opacity: 0.5;
  margin-left: 7px;
`;
