import React from 'react';
import * as S from './style';

function ProcessingPaymentPopup({ location, ...props }) {
  return (
    <S.Wrapper >
      <div className="processingPopup">
        <div className="processingPopup__heading">
          <S.ProcessingIcon />
        </div>
        <div className="processingPopup__content">
          <div className="processingPopup__content--title">
            Payment in progress
          </div>
          <div className="processingPopup__content--description">
            We are processing your order.
            <br/>
            Please don’t refresh or close the browser..
          </div>
        </div>
      </div>
    </S.Wrapper>
  );
};

export default ProcessingPaymentPopup;
