/**
 * @flow
 */

import React from 'react';
import { Image, Button } from 'semantic-ui-react';
import type { User } from 'types/model';
import type { Dispatch } from 'types/redux';
import Pagination from 'shared/Pagination';
// import styles from './styles';
import './styles.scss';
import _ from 'lodash';
import { CDN_URL } from 'constants/commonData';

type Props = {
  dispatch: Dispatch,
};

type State = {};

const data = [
  {
    date: '12 Nov 2019',
    billingPeriod: 'Monthly',
    amount: '$30.00',
    invoice: '676-402-5967',
  },
  {
    date: '13 Nov 2019',
    billingPeriod: 'Monthly',
    amount: '$10.00',
    invoice: '676-402-5967',
  },
  {
    date: '14 Nov 2019',
    billingPeriod: 'Monthly',
    amount: '$50.00',
    invoice: '676-402-5967',
  },
  {
    date: '16 Nov 2019',
    billingPeriod: 'Monthly',
    amount: '$40.00',
    invoice: '676-402-5967',
  },
];

export default class BillingHistory extends React.Component<Props> {
  state = {};

  render() {
    const { items, filtered_items, page, per_page } = this.state;
    return (
      <div className="billinghistory-container">
        <div className="billing-sorter-bar">
          <div>Date</div>
          <div>Billing period</div>
          <div>Amount</div>
          <div>Invoice</div>
          <div></div>
        </div>
        <div className="billing-list-container">
          <div className="billing-list-outer">
            <ul className="billing-scroll-list">
              {data &&
                data.map(item => {
                  return (
                    <li className="billing-item">
                      <div className="billing-item-content">
                        <div>
                          <span>{item.date}</span>
                        </div>
                        <div>
                          <span>{item.billingPeriod}</span>
                        </div>
                        <div>
                          <span>{item.amount}</span>
                        </div>
                        <div>
                          <span>{item.invoice}</span>
                        </div>
                        <div>
                          <span>
                            <Image src={`${CDN_URL}/images/billing_export.svg`} width="26" height="26" />
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="billing-pagintion-bar">
          <div className="pagination-shift-right"></div>
          {items && (
            <div className="library-pagination">
              <Pagination
                page={page}
                perPage={per_page}
                totalPage={filtered_items.length}
                onPrev={() => {}}
                onNext={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
