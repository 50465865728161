import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import isEmpty from 'lodash/isEmpty';
import { KEY_CODE } from 'constants/commonData';
import { isESCPress } from 'utils/commonFunction';
import * as S from '../style';

import { useMyContext } from '../context/ProgramTemplatesContext';
import DetailModalContent from './DetailModalContent';

const DetailModal = () => {
  const {
    openFullscreenModal,
    openDetailModal,
    toggleDetailModal,
    openInstructionModal,
    openTagsModal,
    openDescriptionModal,
    detailModalItem,
  } = useMyContext();

  const opened = openFullscreenModal && openDetailModal && !isEmpty(detailModalItem);

  const handleKeyDown = event => {
    if (event.keyCode === KEY_CODE.esc && isESCPress(event.key)) {
      toggleDetailModal();
    }
  };

  return (
    <S.FullscreenModalWrapper
      className={classNames('detail', {
        open: openFullscreenModal && openDetailModal,
      })}
    >
      <RootCloseWrapper
        event="click"
        disabled={!openDetailModal || openInstructionModal || openTagsModal || openDescriptionModal}
        onRootClose={handleKeyDown}
      >
        <S.FullscreenModalContainer
          className={classNames('detail', {
            open: opened,
          })}
        >
          {openDetailModal && detailModalItem && <DetailModalContent />}
        </S.FullscreenModalContainer>
      </RootCloseWrapper>
    </S.FullscreenModalWrapper>
  );
};

export default DetailModal;
