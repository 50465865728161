import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

// Components
import FileUpload from 'shared/FileUpload';

// Constants
import {
  convertHeicToJpeg,
  createObjectURL,
  getImageDimensionFromUrl,
  getPresignedUploadUrl,
  mediaLog,
  revokeObjectURL,
} from 'utils/commonFunction';
import { validateFiles } from 'utils/validations';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import { PROGRESS_PHOTO_DEFAULT_SETTINGS } from 'components/AddProgressPhoto/constants';
import { DIMENSIONS_AT_LEAST_WIDTH, RECIPE_BUILDER_UPLOAD_URL } from 'components/Recipes/constants';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_circle.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/MealPlans/upload_banner.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';

// Helper
import { extractUrlPresigned } from 'components/RecipeDetail/helper';

import * as S from './style';

const CoverImageContainer = props => {
  const {
    localUrl,
    setLocalUrl,
    isLoading,
    setIsLoading,
    uploadCoverSuccess,
    contentUpload,
    onSelectFile,
    hideUpload = false,
    noBorder,
    isDragActive,
    mealPlanSrc,
    onRemoveFile,
    checkExistImg,
  } = props;

  const [file, setFile] = useState(null);
  const [uploadConfigs, setUploadConfigs] = useState(null);

  const addTypeHeicToFiles = files => {
    if (files.length) {
      files = files.map(file => {
        const hasNameHeic = get(file, 'name', '').split('.').pop().toLowerCase().includes('heic');
        const isEmptyType = isEmpty(file.type);
        if (hasNameHeic && isEmptyType) {
          const newFile = new File([file], file.name, { type: 'image/heic' });
          return newFile;
        } else {
          return file;
        }
      });
    }
    return files;
  };

  const handleDropFile = async files => {
    if (files.length === 0) {
      return toast.error('Please upload one file at a time');
    }

    let errorMaxWidth = false;
    const newFiles = addTypeHeicToFiles(files);
    let imagesWithoutHEIC = filter(newFiles, file => !get(file, 'type', '').includes('image/heic')) || [];
    const isTypeHeic = filter(newFiles, file => get(file, 'type', '').includes('image/heic'));

    if (isTypeHeic.length) {
      const arr = await convertHeicToJpeg(isTypeHeic);
      imagesWithoutHEIC.push(...arr);
    }

    if (imagesWithoutHEIC.length) {
      const file = imagesWithoutHEIC[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: PROGRESS_PHOTO_DEFAULT_SETTINGS.IMAGE_VALIDATE_EXTENTION,
        maxSize: PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_SIZE * CONVERSION.MB_TO_BYTE,
      });

      if (!error) {
        const { width: originalWidth = null, height: originalHeight = null } = await getImageDimensionFromUrl(
          createObjectURL(file),
        );
        if (originalWidth < DIMENSIONS_AT_LEAST_WIDTH) {
          errorMaxWidth = true;
        }
      }

      if (error || errorMaxWidth) {
        let message = '';
        if (get(error, 'type') === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = PROGRESS_PHOTO_DEFAULT_SETTINGS.FILE_TYPE_SUPPORTED_MESSAGE;
        } else if (get(error, 'type') === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = `${PROGRESS_PHOTO_DEFAULT_SETTINGS.MAX_SIZE_MESSAGE}.`;
        } else if (errorMaxWidth) {
          message = `Image width should be at least ${DIMENSIONS_AT_LEAST_WIDTH}px for best resolution.`;
        }
        toast.error(message);
      } else {
        handleSelectFile(file);
        onSelectFile && onSelectFile(file);
      }
    }
  };

  const handleSelectFile = async file => {
    if (file) {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
    }
    if (localUrl) {
      revokeObjectURL(localUrl);
    }

    setIsLoading(true);
    setLocalUrl(createObjectURL(file));

    // Get presigned url
    const { uploadUrl } = await getPresignedUploadUrl(RECIPE_BUILDER_UPLOAD_URL, file);

    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });
    setFile(file);
  };

  const handleUploadSuccess = async response => {
    if (file) {
      const { size, name, type } = file;
      await mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success file via recipe',
      });
      setFile(null);
    }
    setIsLoading(false);
    setUploadConfigs(null);
    setLocalUrl(null);

    const fileInfo = extractUrlPresigned(get(response, 'config.url', ''));

    uploadCoverSuccess && uploadCoverSuccess({ fileInfo: fileInfo });
  };

  const handleCancelUpload = () => {
    revokeObjectURL(localUrl);
    setIsLoading(false);
    setFile(null);
    setLocalUrl(null);
    setUploadConfigs(null);
    uploadCoverSuccess && uploadCoverSuccess({ fileInfo: null });
  };

  const handleUploadError = () => {
    setIsLoading(false);
  };

  const handleRemoveFile = e => {
    e.stopPropagation();
    if (localUrl) {
      revokeObjectURL(localUrl);
    }
    setUploadConfigs(null);
    setLocalUrl(null);
    setFile(null);
    onRemoveFile();
  };

  const renderUpload = () => {
    if (file && !hideUpload) {
      return (
        <S.UploadContent background={localUrl}>
          <FileUpload
            onSuccess={handleUploadSuccess}
            onError={handleUploadError}
            onCancel={handleCancelUpload}
            configs={uploadConfigs}
          />
        </S.UploadContent>
      );
    } else {
      return (
        <S.UploadContent>
          <span>
            <UploadIcon />
          </span>
          {isDragActive ? (
            <span className="text-dragging">Drop Image here</span>
          ) : (
            <span className="text-upload-image">
              Drag & Drop an image here
              <br />
              or&nbsp;
              <span className="custom-upload-content">Upload Image</span>
            </span>
          )}
          {!isDragActive && (
            <>
              <S.IconInfo
                onClick={e => {
                  e.preventDefault();
                }}
                data-tip
                data-for={'thumbnail-info-key'}
                data-iscapture="true"
              >
                <InfoIcon />
              </S.IconInfo>
              <ReactTooltip
                id={'thumbnail-info-key'}
                className="thumbnail-info"
                effect="solid"
                place="top"
                delayShow={100}
              >
                {'Image size should have width of at least 335px'}
              </ReactTooltip>
            </>
          )}
        </S.UploadContent>
      );
    }
  };

  return (
    <S.UploadWrapper>
      <Dropzone onDrop={handleDropFile} multiple={false}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <S.UploadContainer
            {...getRootProps()}
            isDragging={isDragActive}
            isContentUpload={(!!contentUpload && isLoading) || localUrl}
            src={mealPlanSrc || (!hideUpload && localUrl)}
            noBorder={noBorder}
          >
            {contentUpload ? (
              <S.UploadContent isShowOverlay={checkExistImg || (!hideUpload && localUrl)}>
                {contentUpload}
                <S.IconInfo
                  onClick={e => {
                    e.preventDefault();
                  }}
                  data-tip
                  data-for={'thumbnail-info-key'}
                  data-iscapture="true"
                >
                  <InfoIcon />
                </S.IconInfo>
                <ReactTooltip
                  id={'thumbnail-info-key'}
                  className="thumbnail-info"
                  effect="solid"
                  place="top"
                  delayShow={100}
                >
                  {'Image size should have width of at least 335px'}
                </ReactTooltip>
                {!!contentUpload && (localUrl || checkExistImg) && !isLoading && (
                  <S.Overlay>
                    <S.Desc>Click to change image</S.Desc>
                  </S.Overlay>
                )}
              </S.UploadContent>
            ) : (
              renderUpload()
            )}
            {!isLoading && (
              <input {...getInputProps()} type="file" accept={PROGRESS_PHOTO_DEFAULT_SETTINGS.ACCEPTED_IMAGE} />
            )}
            {!!contentUpload && (localUrl || checkExistImg) && !isLoading && (
              <RemoveIcon className="close-icon" onClick={handleRemoveFile} />
            )}
            {file && !!contentUpload && (
              <FileUpload
                onSuccess={handleUploadSuccess}
                onError={() => {}}
                onCancel={handleCancelUpload}
                configs={uploadConfigs}
              />
            )}
          </S.UploadContainer>
        )}
      </Dropzone>
    </S.UploadWrapper>
  );
};

export default CoverImageContainer;
