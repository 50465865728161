import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const EditProgressPhoto = styled(Modal)`
  &.ui.modal {
    width: auto;
    height: 722px;
    max-height: 722px;
    @media (max-height: 762px) {
      max-height: calc(100vh - 40px);
    }
  }
  &.ui.modal > :last-child {
    border-radius: 8px !important;
    overflow: hidden;
  }
  .close-button {
    right: -12px;
    top: -12px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &.ui.modal > .content {
    height: 100%;
    display: flex;
    padding: 0;
  }
`;
