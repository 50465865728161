import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &.empty {
    padding-top: 160px;

    > div {
      :first-child {
        margin-right: 15px;
      }
    }
  }

  &.hasLeaderboardHasForum {
    .leaderboardDetail,
    .addNewLeaderboard {
      margin-left: 35px;
    }
  }

  &.hasLeaderboardNoForum {
    .leaderboardDetail,
    .addNewLeaderboard {
      margin-left: 35px;
    }
  }

  &.noLeaderboardHasForum {
    .leaderboardDetail,
    .addNewLeaderboard {
      margin-left: 35px;
    }
  }
`;
