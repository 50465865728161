import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  outline: 1px solid #eceef7;
  border-radius: 10px;
  margin-bottom: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .leaderboard-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #1e0a3c;
  }
  .body {
    padding: 29px 20px 20px;
  }
  .rank-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .client-info-container {
    display: flex;
    align-items: center;
  }
  .client-name {
    margin-left: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1e0a3c;
  }
  .client-score {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #1e0a3c;
    svg {
      margin-left: 8px;
    }
  }
`;
