import styled from 'styled-components';

export const NoData = styled.div`
  color: #000000;
  opacity: 0.3;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dde2ed;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  min-height: 218px;
  font-family: 'Open Sans';

  .header {
    position: relative;
  }

  .chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 137px;
    position: relative;
  }

  .average {
    text-align: right;
    margin-bottom: 30px;
  }

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #273d52;
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 150%;
  }

  .value {
    min-height: 23px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.5px;
    color: #202353;
  }

  .description {
    font-weight: 600;
    font-size: 13px;
    color: #202353;
    opacity: 0.4;
  }

  &.step-chart-overview {
    .header:hover {
      border-bottom: 1px solid #f0f0f2;
      background-color: #f8f8f8;
    }

    .header-value {
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: start;
      gap: 10px;
      margin-top: 10px !important;

      .description {
        color: #7b7e91;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 19.5px */
      }

      .value {
        color: #273d52;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }

      .value .dash {
        color: #a3a3b5;
      }
    }
    .move-icon {
      display: none;
      position: absolute;
      right: 16px;
      top: 15px;
    }

    &:hover {
      & {
        border: 1px solid #5158cf;
      }

      .header {
        .move-icon {
          display: block;
        }
      }
    }
  }

  .loading-chart-indicator {
    position: absolute;
    padding-top: 0;

    .ui.small.text.loader {
      padding-top: 29px;
    }

    span {
      font-size: 13px !important;
      color: #8f99a3;
      font-weight: normal;
    }
  }

  .chart-no-data {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    color: #8f99a3;
    width: 100%;
    position: absolute !important;
    left: 0;
    top: 57px;
    svg path {
      stroke: #7b7e91;
    }

    p {
      margin-top: 10px;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
    }
  }
`;
