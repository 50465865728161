import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/FormControl';

import * as S from './style';

const SingleBanner = ({
  onSetIsHideBanner = null,
  articleIcon = '',
  arrowIcon = '',
  articleLink = '',
  bannerImageSrc = '',
  buttonContent = null,
  bannerLabel = '',
  bannerTitle = '',
  bannerDescription = '',
  customClasses = '',
}) => {
  return (
    <S.BannerContainer className={customClasses}>
      <S.BannerTextContent>
        <div className="single__banner__label">{bannerLabel}</div>
        <div className="single__banner__title">{bannerTitle}</div>
        <div className="single__banner__description">
          <span>{bannerDescription}</span>
          {bannerDescription && (
            <span className="single__banner__learnMoreLink">
              <a href={articleLink} target="_blank">
                <img src={articleIcon} />Learn more
              </a>
            </span>
          )}
        </div>
      </S.BannerTextContent>
      <div className="buttons__container">{buttonContent && buttonContent}</div>
      <div className="button-hidden-container">
        <Button className="button--hide-banner" onClick={onSetIsHideBanner && onSetIsHideBanner}>
          <span>Hide Banner</span>
          <img src={arrowIcon} />
        </Button>
      </div>

      <S.BannerImage bannerImageSrc={bannerImageSrc} />
    </S.BannerContainer>
  );
};

PropTypes.SingleBanner = {
  articleIcon: PropTypes.string,
  arrowIcon: PropTypes.string,
  articleLink: PropTypes.string,
  bannerImageSrc: PropTypes.string,
  bannerLabel: PropTypes.string,
  bannerTitle: PropTypes.string,
  bannerDescription: PropTypes.string,
  customClasses: PropTypes.customClasses,
};

export default SingleBanner;
