import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { get, find, isEmpty } from 'lodash';
import _ from 'lodash';
import moment from 'moment';
// import containers
import AddIntroModal from './AddIntroModal';
import DailyCalo from './DailyCalo';
import DailyReport from './DailyReport';
import MyFitnessPal from './MyFitnessPal';
// import components
import { Button } from 'shared/FormControl';
import BasicFilter from 'components/ClientFilters/BasicFilter';
import DateRangePicker from 'shared/DateRangePicker/NewDateRange';
import DatePicker from 'shared/DatePicker';
import MacrosStyles, { NoDataStyles, MyfitnesspalWrapper, MyfitnesspalMessage } from './styles';
import { ReactComponent as MyFitnessPalIcon } from 'assets/icons/myfitnesspal.svg';
import { ReactComponent as MFP_Cronometer_Icon } from 'assets/icons/myfitnesspal-cronometer.svg';
// import actions
import { toggleModal } from 'actions/modal';
import { getGoal, getReportMacrosDaily, getReportMacrosWeekly } from 'actions/marcos';
import { getMyfitnesspalStatus } from 'redux/macros/actions';
import MacroDistribution from './MacroDistribution';
import TotalsByDay from './TotalsByDay';
import { FeatureTurnedOff } from 'components/FoodJournal/style';
import ManualGoalModal from './ManualGoalModal';
import { CDN_URL } from 'constants/commonData';

const listViewBy = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'daily', label: 'Daily' },
  { value: 'last7', label: 'Last 7 days' },
  { value: 'last14', label: 'Last 14 days' },
];

const MFP_SYNCING_TYPES = { daily: 'day', weekly: 'week' };

const getTracked = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('tracked') ? moment(params.get('tracked').replace(/-/g, '/')).format('YYYY-MM-DD') : new Date();
};

const Macros = props => {
  const { syncingStatus } = props;

  const clientTimezone = get(props.workingClientDetail, 'timezone');
  const tracked = getTracked();

  const [viewBy, setViewBy] = useState(undefined);
  const [trainingGoal, setTrainingGoal] = useState(null);
  const [restGoal, setRestGoal] = useState(null);
  const [weekCalendar, setWeekCalendar] = useState({
    startDate: moment(tracked).tz(clientTimezone).startOf('week'),
    endDate: moment(tracked).tz(clientTimezone).endOf('week'),
  });
  const [reportData, setReportData] = useState({});
  const [dayCalendar, setDayCalendar] = useState(moment(tracked).tz(clientTimezone));
  const [myfitnessPalLastSynced, setMyfitnessPalLastSynced] = useState(undefined);
  const [MFPStatus, setMFPStatus] = useState({});

  const macrosSetting = find(props.workingClientDetail.feature_preferences, item => item.type === 'macro');

  const turnOff = !macrosSetting || !macrosSetting.state;

  const isUpdate = get(props, 'location.state.isUpdate', '');

  //ref
  const prevSyncStatus = useRef(syncingStatus);

  useEffect(() => {
    if (!turnOff && isUpdate) {
      props.dispatch(
        toggleModal(
          true,
          <ManualGoalModal
            data={isEmpty(trainingGoal) ? {} : { ...trainingGoal.value, totalCalo: trainingGoal.value.calories }}
            restDayData={
              isEmpty(restGoal)
                ? {}
                : {
                    ...restGoal.value,
                    totalCalo: restGoal.value.calories,
                    type: restGoal.rest_days,
                    rest_days: restGoal.rest_days,
                  }
            }
            clientId={props.match.params.clientId}
            fetchReport={fetchReport}
            isUpdate={isUpdate}
            history={props.history}
          />,
        ),
      );
    }

    return () => {
      window.onbeforeunload = function () {
        if (isUpdate) {
          props.history.replace();
        }
      };
    };
  }, [trainingGoal, restGoal]);

  useEffect(() => {
    props
      .dispatch(getMyfitnesspalStatus(props.match.params.clientId))
      .then(data => setMFPStatus(get(data, 'data.data')));
  }, []);

  useEffect(() => {
    if (
      syncingStatus.status === 'synced' &&
      !_.isEqual(syncingStatus, prevSyncStatus.current) &&
      syncingStatus.type === MFP_SYNCING_TYPES[viewBy]
    ) {
      fetchDataInRange();
    }

    prevSyncStatus.current = syncingStatus;
  }, [syncingStatus, viewBy]);

  useEffect(() => {
    fetchReport();
  }, [dayCalendar, weekCalendar]);

  useEffect(() => {
    if (viewBy === 'last7') {
      const endDate = moment(moment().format()).tz(clientTimezone);
      const startDate = moment(moment().format()).tz(clientTimezone).subtract(6, 'days');

      setWeekCalendar({
        startDate,
        endDate,
      });
    }
    if (viewBy === 'last14') {
      const endDate = moment(moment().format()).tz(clientTimezone);
      const startDate = moment(moment().format()).tz(clientTimezone).subtract(13, 'days');
      setWeekCalendar({
        startDate,
        endDate,
      });
    }
    if (viewBy === 'daily') {
      if (weekCalendar.startDate.isSame(moment.tz(clientTimezone), 'week')) {
        setDayCalendar(moment.tz(clientTimezone));
      } else {
        setDayCalendar(weekCalendar.startDate.startOf('week'));
      }
    }
    if (viewBy === 'weekly') {
      const startDate = moment.tz(dayCalendar.format(), clientTimezone).startOf('week');
      const endDate = moment.tz(dayCalendar.format(), clientTimezone).endOf('week');

      setWeekCalendar({
        startDate,
        endDate,
      });
    }
  }, [viewBy]);

  const fetchDataInRange = () => {
    if (viewBy === 'daily') {
      const params = {
        client: props.match.params.clientId,
        day: dayCalendar.format('MM-DD-YYYY'),
      };
      props.getReportMacrosDaily(params).then(response => {
        setMyfitnessPalLastSynced(get(response, 'data.data.lastSync'));
        setReportData(response.data.data);
      });
    } else {
      const params = {
        client: props.match.params.clientId,
        startDay: weekCalendar.startDate.format('MM-DD-YYYY'),
        endDay: weekCalendar.endDate.format('MM-DD-YYYY'),
      };
      props.getReportMacrosWeekly(params).then(response => {
        setMyfitnessPalLastSynced(get(response, 'data.data.lastSync'));
        setReportData(response.data.data);
      });
    }
  };

  const fetchReport = () => {
    props
      .getGoal(props.match.params.clientId)
      .then(data => {
        if (data && get(data, 'data.data[0]')) {
          const listGoal = get(data, 'data.data');
          setTrainingGoal(listGoal.find(goal => goal && goal.type === 'training_day'));
          setRestGoal(listGoal.find(goal => goal && goal.type === 'rest_day'));
        }
      })
      .finally(() => {
        if (!viewBy) setViewBy(listViewBy[0].value);
      });
    fetchDataInRange();
  };

  const handleViewModeChange = (type, val) => {
    if (val !== viewBy) {
      setViewBy(val);
      setReportData({});
    }
  };

  return (
    <MacrosStyles>
      <div>
        {turnOff ? (
          <FeatureTurnedOff className="macros__turn-off">
            <img alt="feature_turned_off" src={`${CDN_URL}/images/feature_turned_off.svg`} />
            <span>Macro has been turned off for this client</span>
          </FeatureTurnedOff>
        ) : null}
        {viewBy && (
          <div className="macros-header">
            <div className="title">Macros Report</div>
            {!trainingGoal && (
              <MyfitnesspalWrapper>
                {process.env.REACT_APP_ENABLE_CRONOMETER ? <MFP_Cronometer_Icon /> : <MyFitnessPalIcon />}
                <MyfitnesspalMessage>
                  Your client can integrate MyFitnessPal
                  {process.env.REACT_APP_ENABLE_CRONOMETER ? ' / Cronometer' : ''} to pull in daily macros data
                </MyfitnesspalMessage>
              </MyfitnesspalWrapper>
            )}
            {trainingGoal && (
              <div className="filter">
                <BasicFilter
                  type="macros_view-by"
                  value={viewBy}
                  name="View by"
                  options={listViewBy}
                  noColon
                  onChange={handleViewModeChange}
                />

                {['weekly', 'last7', 'last14'].includes(viewBy) && (
                  <DateRangePicker
                    className="date-range-picker"
                    startDate={weekCalendar.startDate}
                    weeks={viewBy === 'last14' ? 2 : 1}
                    onChange={(newStartDate, newEndDate) => {
                      if (['last7', 'last14'].includes(viewBy)) {
                        return;
                      }
                      setWeekCalendar({
                        startDate: newStartDate,
                        endDate: newEndDate,
                      });
                    }}
                  />
                )}
                {viewBy === 'daily' && (
                  <DatePicker
                    value={dayCalendar}
                    clientTimezone={clientTimezone}
                    onChange={date => {
                      setDayCalendar(date);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {viewBy && (
          <MyFitnessPal
            clientId={props.match.params.clientId}
            date={viewBy === 'daily' ? dayCalendar : weekCalendar.startDate}
            viewBy={viewBy}
            myfitnessPalLastSynced={myfitnessPalLastSynced}
            setMyfitnessPalLastSynced={setMyfitnessPalLastSynced}
            MFPStatus={MFPStatus}
          />
        )}
        {trainingGoal && ['weekly', 'last7', 'last14'].includes(viewBy) && (
          <>
            <DailyCalo
              trainingGoal={trainingGoal}
              restGoal={restGoal}
              reportData={reportData}
              weekCalendar={weekCalendar}
              clientId={props.match.params.clientId}
              fetchReport={fetchReport}
              turnOff={turnOff}
              viewSize={viewBy}
            />
            <div className="macros-content__bottom">
              <MacroDistribution
                trainingGoal={trainingGoal}
                reportData={reportData}
                weekCalendar={weekCalendar}
                clientId={props.match.params.clientId}
              />
              <TotalsByDay
                trainingGoal={trainingGoal}
                reportData={reportData}
                weekCalendar={weekCalendar}
                clientId={props.match.params.clientId}
                clientTimezone={clientTimezone}
                viewSize={viewBy}
              />
            </div>
          </>
        )}
        {trainingGoal && viewBy === 'daily' && (
          <DailyReport
            clientId={props.match.params.clientId}
            day={dayCalendar.format('MM-DD-YYYY')}
            reportData={reportData}
          />
        )}
        {!trainingGoal && viewBy && (
          <NoDataStyles>
            <img src={`${CDN_URL}/images/empty_food_journal.svg`} alt="" />
            <p>
              Macros tracking for this client
              <br />
              hasn’t been set up yet!
            </p>
            <Button
              purple
              disabled={turnOff}
              onClick={() =>
                props.dispatch(
                  toggleModal(true, <AddIntroModal clientId={props.match.params.clientId} fetchReport={fetchReport} />),
                )
              }
            >
              Set goal
            </Button>
          </NoDataStyles>
        )}
      </div>
    </MacrosStyles>
  );
};

const mapStateToProps = state => {
  const { client } = state.rootReducer;
  const { workingClientDetail } = client;
  return {
    workingClientDetail,
    syncingStatus: state.rootReducer.macros.listMyFitnessPal[workingClientDetail._id] || {},
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGoal: id => {
      return dispatch(getGoal(id));
    },
    getReportMacrosDaily: params => {
      return dispatch(getReportMacrosDaily(params));
    },
    getReportMacrosWeekly: params => {
      return dispatch(getReportMacrosWeekly(params));
    },
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Macros);
