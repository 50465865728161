import React from 'react';
import isEmpty from 'lodash/isEmpty';

const CustomLineBarSpecialChart = ({
  color,
  barWidth,
  chartBarData,
  isFillTransparent = false,
  isOverview = false,
}) => {
  if (isEmpty(chartBarData)) return null;
  const verticalPadding = isOverview ? 4 : 6;

  const { height: heightBar, y: yPosition, x, min, max } = chartBarData;
  const isOneValueRange = min === max;

  const lineBarHeight = isOverview ? 4 : 6;
  const lineBarWidth = isOverview ? 4 : 6;

  let finalViewBoxWidth = barWidth + lineBarWidth;
  let finalViewBoxHeight = isOneValueRange ? lineBarHeight * 2 : Math.abs(heightBar) + lineBarHeight * 2;
  let finalXPosition = x - lineBarWidth / 2;
  let finalYPosition = yPosition - verticalPadding;

  const rectProps = {
    width: finalViewBoxWidth,
    height: finalViewBoxHeight,
    fill: color,
    fillOpacity: isFillTransparent ? 0 : 0.2,
    rx: 6,
  };

  return (
    <svg
      width={finalViewBoxWidth}
      height={finalViewBoxHeight}
      x={finalXPosition}
      y={finalYPosition}
      viewBox={`0 0 ${finalViewBoxWidth} ${finalViewBoxHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect {...rectProps} />
    </svg>
  );
};

export default CustomLineBarSpecialChart;
