import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  max-width: 60%;
  word-wrap: break-word;
  white-space: break-spaces;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #728096;

  svg {
    margin-right: 6px;
    margin-bottom: -2px;
  }
`;

export const RoomName = styled.span`
  font-weight: 600;
`;
