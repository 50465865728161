import _, { omit, cloneDeep } from 'lodash';
import Request from 'configs/request';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { updateWorkoutCollectionOnboarding } from 'actions/profile';
import {
  INITIAL_WORKING_COLLECTION,
  ON_DEMAND_CATEGORY_STATUS,
  TEAM_SHARE_NOOWNER,
  TEAM_SHARE_PRIVATE,
  WORKOUT_COLLECTION_STATUS,
} from 'constants/commonData';
import { omitEmptyRequestParams } from 'utils/commonFunction';
import { Mixpanel } from 'utils/mixplanel';

export const Types = {
  WORKOUT_COLLECTION_GET_LIST_REQUEST: 'WORKOUT_COLLECTION_GET_LIST_REQUEST',
  WORKOUT_COLLECTION_GET_LIST_SUCCESS: 'WORKOUT_COLLECTION_GET_LIST_SUCCESS',
  WORKOUT_COLLECTION_GET_DETAIL_REQUEST: 'WORKOUT_COLLECTION_GET_DETAIL_REQUEST',
  WORKOUT_COLLECTION_GET_DETAIL_SUCCESS: 'WORKOUT_COLLECTION_GET_DETAIL_SUCCESS',
  WORKOUT_COLLECTION_UPDATE_DETAIL_REQUEST: 'WORKOUT_COLLECTION_UPDATE_DETAIL_REQUEST',
  WORKOUT_COLLECTION_UPDATE_DETAIL_SUCCESS: 'WORKOUT_COLLECTION_UPDATE_DETAIL_SUCCESS',
  WORKOUT_COLLECTION_GET_LIST_FAIL: 'WORKOUT_COLLECTION_GET_LIST_FAIL',
  CREATE_WORKOUT_COLLECTION_REQUEST: 'CREATE_WORKOUT_COLLECTION_REQUEST',
  CREATE_WORKOUT_COLLECTION_SUCCESS: 'CREATE_WORKOUT_COLLECTION_SUCCESS',
  CREATE_WORKOUT_COLLECTION_FAIL: 'CREATE_WORKOUT_COLLECTION_FAIL',
  WORKOUT_COLLECTION_DETAIL_SUBMIT_REQUEST: 'WORKOUT_COLLECTION_DETAIL_SUBMIT_REQUEST',
  WORKOUT_COLLECTION_DETAIL_SUBMIT_SUCCESS: 'WORKOUT_COLLECTION_DETAIL_SUBMIT_SUCCESS',
  WORKOUT_COLLECTION_GET_CLIENTS_REQUEST: 'WORKOUT_COLLECTION_GET_CLIENTS_REQUEST',
  WORKOUT_COLLECTION_GET_CLIENTS_SUCCESS: 'WORKOUT_COLLECTION_GET_CLIENTS_SUCCESS',
  WORKOUT_COLLECTION_GET_CLIENTS_FAIL: 'WORKOUT_COLLECTION_GET_CLIENTS_FAIL',
  WORKOUT_COLLECTION_ADD_CLIENT_TO_REDUX: 'WORKOUT_COLLECTION_ADD_CLIENT_TO_REDUX',
  WORKOUT_COLLECTION_REMOVE_CLIENT_TO_REDUX: 'WORKOUT_COLLECTION_REMOVE_CLIENT_TO_REDUX',
  WORKOUT_COLLECTION_ADD_CLIENT_SUCCESS: 'WORKOUT_COLLECTION_ADD_CLIENT_SUCCESS',
  CREATE_CATEGORIES_REQUEST: 'CREATE_CATEGORIES_REQUEST',
  CREATE_CATEGORIES_SUCCESS: 'CREATE_CATEGORIES_SUCCESS',
  CREATE_CATEGORIES_FAIL: 'CREATE_CATEGORIES_FAIL',
  ACTIVATE_CATEGORY_REQUEST: 'ACTIVATE_CATEGORY_REQUEST',
  ACTIVATE_CATEGORY_SUCCESS: 'ACTIVATE_CATEGORY_SUCCESS',
  DEACTIVATE_CATEGORY_REQUEST: 'DEACTIVATE_CATEGORY_REQUEST',
  DEACTIVATE_CATEGORY_SUCCESS: 'DEACTIVATE_CATEGORY_SUCCESS',
  RESET_WORKOUT_COLLECTION_DETAIL: 'RESET_WORKOUT_COLLECTION_DETAIL',
  PUBLISH_WORKOUT_COLLECTION_REQUEST: 'PUBLISH_WORKOUT_COLLECTION_REQUEST',
  PUBLISH_WORKOUT_COLLECTION_SUCCESS: 'PUBLISH_WORKOUT_COLLECTION_SUCCESS',
  UNPUBLISH_WORKOUT_COLLECTION_REQUEST: 'UNPUBLISH_WORKOUT_COLLECTION_REQUEST',
  UNPUBLISH_WORKOUT_COLLECTION_SUCCESS: 'UNPUBLISH_WORKOUT_COLLECTION_SUCCESS',
  WORKOUT_COLLECTION_REMOVE_CATEGORY_REQUEST: 'WORKOUT_COLLECTION_REMOVE_CATEGORY_REQUEST',
  WORKOUT_COLLECTION_REMOVE_CATEGORY_SUCCESS: 'WORKOUT_COLLECTION_REMOVE_CATEGORY_SUCCESS',
  WORKOUT_COLLECTION_REMOVE_REQUEST: 'WORKOUT_COLLECTION_REMOVE_REQUEST',
  WORKOUT_COLLECTION_REMOVE_SUCCESS: 'WORKOUT_COLLECTION_REMOVE_SUCCESS',
  WORKOUT_COLLECTION_DUPLICATE_REQUEST: 'WORKOUT_COLLECTION_DUPLICATE_REQUEST',
  WORKOUT_COLLECTION_DUPLICATE_SUCCESS: 'WORKOUT_COLLECTION_DUPLICATE_SUCCESS',
  WORKOUT_COLLECTION_CHANGE_QUERY_PARAMS: 'WORKOUT_COLLECTION_CHANGE_QUERY_PARAMS',
  WORKOUT_COLLECTION_DUPLICATE_CATEGORY_REQUEST: 'WORKOUT_COLLECTION_DUPLICATE_CATEGORY_REQUEST',
  WORKOUT_COLLECTION_DUPLICATE_CATEGORY_SUCCESS: 'WORKOUT_COLLECTION_DUPLICATE_CATEGORY_SUCCESS',
  WORKOUT_COLLECTION_GET_MORE_REQUEST: 'WORKOUT_COLLECTION_GET_MORE_REQUEST',
  WORKOUT_COLLECTION_GET_MORE_SUCCESS: 'WORKOUT_COLLECTION_GET_MORE_SUCCESS',
  WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_REQUEST: 'WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_REQUEST',
  WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_SUCCESS: 'WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_SUCCESS',
  WORKOUT_COLLECTION_CHANGE_ONBOARDING_REQUEST: 'WORKOUT_COLLECTION_CHANGE_ONBOARDING_REQUEST',
  WORKOUT_COLLECTION_CHANGE_ONBOARDING_SUCCESS: 'WORKOUT_COLLECTION_CHANGE_ONBOARDING_SUCCESS',
  WORKOUT_COLLECTION_CHANGE_CATEGORY_FORMAT_REDUX: 'WORKOUT_COLLECTION_CHANGE_CATEGORY_FORMAT_REDUX',
  WORKOUT_COLLECTION_RESET_LOAD_MORE_REDUX: 'WORKOUT_COLLECTION_RESET_LOAD_MORE_REDUX',
  WORKOUT_COLLECTION_CHANGE_SHARE_STATUS: 'WORKOUT_COLLECTION_CHANGE_SHARE_STATUS',
  WORKOUT_COLLECTION_GET_ASSIGNED_CLIENTS: 'WORKOUT_COLLECTION_GET_ASSIGNED_CLIENTS',
};

export const updateFormatCategoryRedux = (format, categoryId) => {
  return { type: Types.WORKOUT_COLLECTION_CHANGE_CATEGORY_FORMAT_REDUX, payload: { format, categoryId } };
};

export const addClientToRedux = clients => {
  return { type: Types.WORKOUT_COLLECTION_ADD_CLIENT_TO_REDUX, payload: { data: clients } };
};

export const removeClientFromRedux = client => {
  return { type: Types.WORKOUT_COLLECTION_REMOVE_CLIENT_TO_REDUX, payload: { data: client } };
};

export const getWorkoutCollectionDetail = id => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_COLLECTION_GET_DETAIL_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/on-demand-workout-collections/${id}`,
        },
        true,
        response => {
          const { data } = response.data;

          if (!data) {
            return dispatch(push(`/home/workout-collections`));
          }

          dispatch({
            type: Types.WORKOUT_COLLECTION_GET_DETAIL_SUCCESS,
            payload: data ? { data: data } : { data: INITIAL_WORKING_COLLECTION },
          });
        },
      ),
    );
  };
};

export const updateWorkoutCollectionDetail = (updateFields, collectionId) => {
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch({ type: Types.WORKOUT_COLLECTION_UPDATE_DETAIL_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: `/api/on-demand-workout-collections/${collectionId}`,
          data: updateFields,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({
            type: Types.WORKOUT_COLLECTION_UPDATE_DETAIL_SUCCESS,
            payload: data ? { data: data } : { data: INITIAL_WORKING_COLLECTION },
          });
          if (user._id !== data.author && data.share === TEAM_SHARE_PRIVATE) {
            dispatch(push('/home/workout-collections'));
          }
          toast('This collection has been updated successfully.', { autoClose: 3000 });
        },
      ),
    );
  };
};

export const getWorkoutCollectionList = (params, isLoading = false) => {
  return dispatch => {
    if (isLoading) {
      dispatch({ type: Types.WORKOUT_COLLECTION_GET_LIST_REQUEST });
    }

    return dispatch(
      Request.get(
        { url: `/api/on-demand-workout-collections`, params: omitEmptyRequestParams(params) },
        true,
        res => {
          const list = _.get(res, 'data.data', []);
          dispatch({ type: Types.WORKOUT_COLLECTION_GET_LIST_SUCCESS, payload: list });
        },
        error => {
          dispatch({ type: Types.WORKOUT_COLLECTION_GET_LIST_FAIL, error });
        },
      ),
    );
  };
};

export const addNewWorkoutCollection = data => {
  return (dispatch, getState) => {
    const {
      user: { _id: userId },
    } = getState();

    dispatch({ type: Types.CREATE_WORKOUT_COLLECTION_REQUEST });
    Mixpanel.track('on_demand_add_new_workout_collection');

    return dispatch(
      Request.post(
        { url: `/api/on-demand-workout-collections`, data },
        true,
        res => {
          const { data: { data = {} } = {} } = res;
          const hasPermission = data.author === userId || data.share !== TEAM_SHARE_PRIVATE;
          if (hasPermission) {
            dispatch({ type: Types.CREATE_WORKOUT_COLLECTION_SUCCESS, payload: [data] });
            dispatch(push(`/home/workout-collections/${data._id}`));
          } else {
            dispatch({ type: Types.CREATE_WORKOUT_COLLECTION_SUCCESS, payload: [] });
          }
        },
        error => {
          dispatch({ type: Types.CREATE_WORKOUT_COLLECTION_FAIL, error });
        },
      ),
    );
  };
};

export const addNewCategory = data => {
  return dispatch => {
    dispatch({ type: Types.CREATE_CATEGORIES_REQUEST });
    Mixpanel.track('on_demand_add_new_category');

    return dispatch(
      Request.post(
        { url: `/api/on-demand-workout-categories`, data },
        true,
        res => {
          const list = _.get(res, 'data.data', []);

          dispatch({ type: Types.CREATE_CATEGORIES_SUCCESS, payload: [list] });
          dispatch(push(`/home/workout-collection-category/${list._id}`));
        },
        error => {
          dispatch({ type: Types.CREATE_CATEGORIES_FAIL, error });
        },
      ),
    );
  };
};

export const getPreSignedUrl = data => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_DETAIL_SUBMIT_REQUEST });
    return dispatch(
      //TODO: replace presigned url when available

      Request.post({ url: '/api/file/gen-presigned-urls-on-demand-workout', data }, true, (response, { dispatch }) => {
        dispatch({ type: Types.WORKOUT_COLLECTION_DETAIL_SUBMIT_SUCCESS });
      }),
    );
  };
};

export const getWorkoutCollectionClients = collectionId => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_GET_CLIENTS_REQUEST });
    return dispatch(
      Request.get(
        { url: `/api/on-demand-workout-collections/${collectionId}/clients` },
        true,
        res => {
          const list = _.get(res, 'data.data', []);
          dispatch({ type: Types.WORKOUT_COLLECTION_GET_CLIENTS_SUCCESS, payload: list });
        },
        error => {
          dispatch({ type: Types.WORKOUT_COLLECTION_GET_CLIENTS_FAIL, error });
        },
      ),
    );
  };
};

export const saveClientsAvailable = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        workoutCollection: { availableClients, workingCollection },
      },
    } = getState();

    const collectionId = _.get(workingCollection, '_id');
    const clients = _.map(availableClients, '_id');
    if (collectionId) {
      return dispatch(
        Request.post(
          {
            url: `/api/on-demand-workout-collections/${collectionId}/clients/add`,
            data: { clients },
          },
          false,
          response => {
            dispatch({ type: Types.WORKOUT_COLLECTION_ADD_CLIENT_SUCCESS });
          },
          error => {
            dispatch(getWorkoutCollectionClients(collectionId));
          },
        ),
      );
    }
  };
};

export const toggleCategory = (categoryId, status) => {
  return dispatch => {
    const action = status === ON_DEMAND_CATEGORY_STATUS.ACTIVE ? 'deactive' : 'active';
    const isActive = status === ON_DEMAND_CATEGORY_STATUS.ACTIVE;
    if (isActive) {
      dispatch({ type: Types.DEACTIVATE_CATEGORY_REQUEST });
    } else {
      dispatch({ type: Types.ACTIVATE_CATEGORY_REQUEST });
    }
    return dispatch(
      Request.put(
        { url: `/api/on-demand-workout-categories/${categoryId}/${action}` },
        true,
        res => {
          const { data } = res.data;
          if (isActive) {
            dispatch({ type: Types.DEACTIVATE_CATEGORY_SUCCESS, payload: { data } });
            toast('The Category has been deactivated.');
          } else {
            dispatch({ type: Types.ACTIVATE_CATEGORY_SUCCESS, payload: { data } });
            toast('The Category has been activated.');
          }
        },
        error => {
          // dispatch({ type: Types.WORKOUT_COLLECTION_GET_CLIENTS_FAIL, error });
        },
      ),
    );
  };
};

export const resetWorkoutCollectionDetail = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_WORKOUT_COLLECTION_DETAIL });
  };
};

export const toggleCollection = (workoutCollectionId, status) => {
  return dispatch => {
    const action = status === WORKOUT_COLLECTION_STATUS.PUBLISH ? 'unpublish' : 'publish';
    const isPublished = status === WORKOUT_COLLECTION_STATUS.PUBLISH;
    if (isPublished) {
      dispatch({ type: Types.UNPUBLISH_WORKOUT_COLLECTION_REQUEST });
    } else {
      dispatch({ type: Types.PUBLISH_WORKOUT_COLLECTION_REQUEST });
    }
    return dispatch(
      Request.put(
        { url: `/api/on-demand-workout-collections/${workoutCollectionId}/${action}` },
        true,
        res => {
          const { data } = res.data;
          if (isPublished) {
            dispatch({ type: Types.UNPUBLISH_WORKOUT_COLLECTION_SUCCESS, payload: { data } });
            toast('The Collection has been unpublished.');
          } else {
            dispatch({ type: Types.UNPUBLISH_WORKOUT_COLLECTION_SUCCESS, payload: { data } });
            toast('The Collection has been published.');
          }
        },
        error => {
          // dispatch({ type: Types.WORKOUT_COLLECTION_GET_CLIENTS_FAIL, error });
        },
      ),
    );
  };
};
export const removeCategory = categoryId => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_REMOVE_CATEGORY_REQUEST });
    Mixpanel.track('on_demand_remove_workout_category');

    return dispatch(
      Request.delete(
        { url: `/api/on-demand-workout-categories/${categoryId}` },
        true,
        res => {
          const { data } = res.data;
          dispatch({ type: Types.WORKOUT_COLLECTION_REMOVE_CATEGORY_SUCCESS, payload: { data: categoryId } });
        },
        error => {},
      ),
    );
  };
};

export const duplicateCategory = (categoryId, categoryName) => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_DUPLICATE_CATEGORY_REQUEST });
    return dispatch(
      Request.post(
        {
          url: `/api/on-demand-workout-categories/${categoryId}/duplicate`,
          data: {
            name: categoryName,
          },
        },
        true,
        res => {
          const { data } = res.data;
          dispatch({ type: Types.WORKOUT_COLLECTION_DUPLICATE_CATEGORY_SUCCESS, payload: { data: data } });
          dispatch(push(`/home/workout-collection-category/${data._id}`));
        },
        error => {},
      ),
    );
  };
};

export const removeCollection = collectionId => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_REMOVE_REQUEST });
    Mixpanel.track('on_demand_remove_workout_collection');

    return dispatch(
      Request.delete(
        { url: `/api/on-demand-workout-collections/${collectionId}` },
        true,
        res => {
          // const list = _.get(res, 'data.data', []);
          const { data } = res.data;
          dispatch({ type: Types.WORKOUT_COLLECTION_REMOVE_SUCCESS, payload: { data: collectionId } });
        },
        error => {},
      ),
    );
  };
};

export const duplicateCollection = (collectionId, collectionName) => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_DUPLICATE_REQUEST });
    return dispatch(
      Request.post(
        {
          url: `/api/on-demand-workout-collections/${collectionId}/duplicate`,
          data: {
            name: collectionName,
          },
        },
        true,
        res => {
          const { data } = res.data;
          dispatch({ type: Types.WORKOUT_COLLECTION_DUPLICATE_SUCCESS, payload: { data: data[0] } });
          dispatch(push(`/home/workout-collections/${data[0]._id}`));
        },
        error => {},
      ),
    );
  };
};

export const changeQueryParams = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: { workoutCollection },
    } = getState();

    const query = { ...workoutCollection.query, ...params };
    dispatch({ type: Types.WORKOUT_COLLECTION_CHANGE_QUERY_PARAMS, payload: query });
    dispatch(getWorkoutCollectionList(query));
  };
};

export const loadMoreWorkoutCollections = (isLoading = false) => {
  return (dispatch, getState) => {
    if (isLoading) {
      dispatch({ type: Types.WORKOUT_COLLECTION_GET_MORE_REQUEST });
    }
    const {
      rootReducer: {
        workoutCollection: { query },
      },
    } = getState();
    let queryParams = omit(cloneDeep(query), ['isEnd', 'status', 'total']);
    queryParams.page = queryParams.page + 1;
    return dispatch(
      Request.get(
        { url: 'api/on-demand-workout-collections', params: queryParams },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.WORKOUT_COLLECTION_GET_MORE_SUCCESS, payload: { data } });
        },
        error => {},
      ),
    );
  };
};

export const rearrangeCategories = (categoryId, destinationIndex, originalIndex, isDragging = false) => {
  return dispatch => {
    dispatch({ type: Types.WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/on-demand-workout-categories//${categoryId}/rearrange`,
          data: {
            newIndex: destinationIndex,
          },
        },
        true,
        response => {
          dispatch({
            type: Types.WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_SUCCESS,
            payload: {
              categoryId,
              destinationIndex,
              originalIndex,
              isDragging,
            },
          });
          toast('This collection has been updated successfully.', { autoClose: 3000 });
        },
      ),
    );
  };
};

export const editCollectionOnboarding = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_COLLECTION_CHANGE_ONBOARDING_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/on-demand-workout-collections/edit-collection-onboarding`,
        },
        true,
        response => {
          if (_.get(response, 'data.success', false)) {
            dispatch({
              type: Types.WORKOUT_COLLECTION_CHANGE_ONBOARDING_SUCCESS,
            });
            dispatch(updateWorkoutCollectionOnboarding());
          }
        },
      ),
    );
  };
};

export const resetLoadMore = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_COLLECTION_RESET_LOAD_MORE_REDUX });
  };
};

export const updateShareStatus = ({ params = {}, collectionId } = {}) => (dispatch, getState) => {
  if ((!params.owner && params.share !== TEAM_SHARE_NOOWNER) || !collectionId) return;
  const {
    user: { _id: userId, team_member = [] },
    rootReducer: {
      workoutCollection: { list },
    },
  } = getState();

  return dispatch(
    Request.patch(
      {
        url: `/api/on-demand-workout-collections/${collectionId}/share`,
        data: params,
      },
      true,
      response => {
        if (((response.data || {}).data || {}).success) {
          let newList = [];
          if (params.owner === userId || params.share !== TEAM_SHARE_PRIVATE) {
            newList = [...list].map(it => {
              if (it._id === collectionId) {
                const author_info = team_member.find(it => it._id === params.owner);
                return { ...it, share: params.share, author: params.owner, author_info };
              }
              return it;
            });
          } else {
            newList = [...list].filter(it => it._id !== collectionId);
          }

          dispatch({
            type: Types.WORKOUT_COLLECTION_CHANGE_SHARE_STATUS,
            payload: newList,
          });
        }
      },
    ),
  );
};

export const getAssignedClients = collectionId => {
  return Request.get(
    { url: `/api/on-demand-workout-collections/${collectionId}/clients`, params: { collectionId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.WORKOUT_COLLECTION_GET_ASSIGNED_CLIENTS, payload: { data } });
    },
  );
};
