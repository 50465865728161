// Libs
import styled from 'styled-components';

export const IconWrapper = styled.div`
  .warning-icon-program-tooltip.__react_component_tooltip {
    width: 228px;
    padding: 15px 15px !important;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    background: #2d2e2d;
    text-align: left;
    border-radius: 5px;

    &.place-top:after {
      border-top-color: #2d2e2d;
      bottom: -8px !important;
      border-top-width: 8px;
    }

    .text-highlight {
      font-weight: 600;
    }
  }
`;
