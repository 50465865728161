/**
 * @flow
 */
import styled from 'styled-components';
import WarningIcon from 'assets/icons/warning.svg';

export const ChooseStartDateLabel = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 13px;
  text-align: left;
`;

export const WarningContainer = styled.div`
  border-radius: 4px;
  padding: 4px 10px 4px 36px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ff1b1b;
  display: flex;
  background: #fef4f6 url(${WarningIcon}) no-repeat 10px 9px;
  min-height: 44px;
`;

export const SelectStartDayContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SelectDayInputValue = styled.div`
  width: 180px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 15px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  background: #fafbfc;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  .select-program-day {
    text-align: left;
    width: auto;
    margin-right: 10px;

    .text {
      border: none;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
    }
  }

  button {
    height: 44px;
    width: 120px;
    flex: 0 0 120px;
    margin-left: 44px;
  }
`;

export const ProgramEndingOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .arrow { 
    margin: 0 15px;
    color: #000;
  }
`;

export default {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Open Sans',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'visible',
    justifyContent: 'space-between',
  },

  workoutListContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    marginTop: 5,
    borderRadius: '6px',
    boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.2)',
  },

  previewContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    marginTop: '5px',
    border: '1px solid #DFE4E5',
    boxShadow: 'none',
  },

  hoverColor: '#90ee9080',
};
