import React from 'react';
import styled from 'styled-components';
import { CalendarAssignWorkoutIcon } from 'shared/Icons';

const Container = styled.div`
  position: relative;

  .autoflowInterval__assignMenu {
    &__menu {
      top: 10px;
      right: 0;
      padding-top: 10px;
      display: none;
      position: absolute;
      z-index: 10;
    }
    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      background: #2d2e2d;
      border-radius: 8px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    }

    &__item {
      color: #fff;
      padding: 13px 20px;
      text-decoration: none;
      display: block;
      text-align: left;
      cursor: pointer;
      width: 130px;
      font-size: 1rem;
      text-transform: none;
      font-weight: 400;

      :hover {
        background: #696969;
      }
    }
  }

  :hover {
    .autoflowInterval__assignMenu__menu {
      display: block;
    }
  }
`;

export default ({ onAssignProgram, onAssignWorkout }) => (
  <Container className="autoflowInterval__assignMenu">
    <CalendarAssignWorkoutIcon className="addItemButton" onClick={onAssignWorkout} />
    <div className="autoflowInterval__assignMenu__menu">
      <ul className="autoflowInterval__assignMenu__list">
        <li className="autoflowInterval__assignMenu__item" onClick={onAssignWorkout}>
          <div>Add workout</div>
        </li>
        <li className="autoflowInterval__assignMenu__item" onClick={onAssignProgram}>
          <div>Add program</div>
        </li>
      </ul>
    </div>
  </Container>
);
