import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Icon } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import Select from 'react-select';
import { Wrapper, MenuContainer } from './styles';
import { changeClientFilter } from 'redux/segment/actions';
import SelectClientOption from 'shared/SelectClientOption';

// *** IMPORTANT ****
// Owner ship value:
// '' = You
// null = All
// [...] = certain trainers

class OwnerShipFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  renderSelectOwner = () => {
    const { changeClientFilter, user, filters } = this.props;

    if (!_.isArray(filters.ownerShip)) {
      return null;
    }

    const teamMembers = user.team_member || [];
    const options = _.map(teamMembers, item => {
      return Object.assign({}, item, {
        key: item._id,
        value: item._id,
        label: `${item.first_name} ${item.last_name}`,
      });
    });
    let defaultValue = [];

    if (_.isArray(filters.ownerShip)) {
      _.forEach(filters.ownerShip, id => {
        const obj = _.find(options, op => op.value === id);

        if (obj) {
          defaultValue.push(obj);
        }
      });
    }

    if (!defaultValue.length) {
      const currentUser = _.find(options, item => item._id === user._id);

      if (currentUser) {
        defaultValue.push(currentUser);
      }
    }

    return (
      <Select
        isMulti
        options={options}
        components={{ Option: SelectClientOption }}
        classNamePrefix="multi-select"
        placeholder="Enter user..."
        onChange={owners => changeClientFilter('ownerShip', _.map(owners, '_id'))}
        defaultValue={defaultValue}
        className="multi-select-container"
        noOptionsMessage={() => 'No results'}
      />
    );
  };

  render() {
    const { open } = this.state;
    const { changeClientFilter, user, filters } = this.props;
    const ownerShip = filters.ownerShip;
    const title = _.isArray(ownerShip) ? 'Certain trainers' : ownerShip === null ? 'All' : 'You';

    return (
      <RootCloseWrapper
        onRootClose={() => {
          if (_.isArray(filters.ownerShip) && !filters.ownerShip.length) {
            changeClientFilter('ownerShip', null);
          }

          this.setState({ open: false });
        }}
        event="click"
        disabled={!open}
      >
        <Wrapper>
          <div className={`filter__dropdown${open ? ' active' : ''}`} onClick={() => this.setState({ open: !open })}>
            <div className="filter__dropdown__title">
              <span>Owner:&nbsp;</span>
              <span className="selected">{title}</span>
              <Icon className="chevron down" />
            </div>
          </div>
          {open && (
            <MenuContainer>
              <div className="form-group">
                <label>Owner</label>
                <Dropdown
                  text={
                    <React.Fragment>
                      <span>{title}</span>
                      <Icon className="chevron down" />
                    </React.Fragment>
                  }
                  icon={null}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ open: false });
                        changeClientFilter('ownerShip', user._id);
                      }}
                      className={typeof ownerShip === 'string' ? 'active' : ''}
                    >
                      You
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ open: false });
                        changeClientFilter('ownerShip', null);
                      }}
                      className={ownerShip === null ? 'active' : ''}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        changeClientFilter('ownerShip', [user._id]);
                      }}
                      className={_.isArray(ownerShip) && ownerShip.length ? 'active' : ''}
                    >
                      Certain trainers...
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {this.renderSelectOwner()}
            </MenuContainer>
          )}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  return {
    filters: rootReducer.segment.filters,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  changeClientFilter: bindActionCreators(changeClientFilter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerShipFilter);
