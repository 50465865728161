import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as Layout from 'shared/LibraryLayout';
import * as Icons from 'shared/LibraryLayout/Icons';
import { CheckBoxContainer } from '../style';
import { changeQueryParams, updateSelectedExercise } from 'redux/exercise/exercise.actionCreators';
import { Checkbox } from 'shared/FormControl';

const Columns = [
  { key: 'tags', name: 'Tags', hasSort: false },
  { key: 'modality', name: 'Modality', hasSort: true, keySorter: 'modality' },
  { key: 'muscle_groups', name: 'Muscle Group', hasSort: true, keySorter: 'muscle_group' },
  { key: 'movement_patterns', name: 'Movement Pattern', hasSort: true, keySorter: 'movement_pattern' },
  { key: 'category_type_name', name: 'Category', hasSort: true },
  {
    key: 'last_interacted',
    name: 'Most Recent',
    alignCenter: true,
    hasSort: true,
  },
  { key: 'author', name: 'Custom', alignCenter: true, hasSort: true },
];

const mostRecentKey = 'last_interacted';

function View(props) {
  const { total, sort, sorter, selectedExercise, listExercise } = props;
  const ids = _.map(listExercise, '_id');
  const isSelectedAll = selectedExercise.length && _.difference(ids, selectedExercise).length === 0;
  const isHasSelectedPrev = selectedExercise.length > 0;

  const onSort = item => () => {
    const { hasSort } = item;
    const key = item.keySorter || item.key;

    if (key === 'tags' || !hasSort) return;
    props.changeQueryParams({
      sorter: key,
      sort: key === sorter ? sort * -1 : key === mostRecentKey ? -1 : 1,
      page: 1,
    });
  };

  const onSortTitle = () => {
    const key = 'title';

    props.changeQueryParams({
      sorter: key,
      sort: key === sorter ? sort * -1 : key === mostRecentKey ? -1 : 1,
      page: 1,
    });
  };

  const renderSortIcon = key => {
    if (key === 'tags') return null;
    if (key !== sorter) {
      return <Icons.ArrowDown />;
    }

    let arrow;

    if (sorter === mostRecentKey) {
      arrow = sort === 1 ? 'up' : 'down';
    } else {
      arrow = sort === -1 ? 'up' : 'down';
    }

    return arrow === 'up' ? <Icons.ArrowUp active /> : <Icons.ArrowDown active />;
  };

  const selectAllExercise = e => {
    let selectedList = [];

    if (e.target.checked) {
      props.updateSelectedExercise([...selectedExercise, ...ids]);
      selectedList = [...selectedExercise, ...ids];
    } else {
      props.updateSelectedExercise(selectedExercise.filter(id => !ids.includes(id)));
      selectedList = selectedExercise.filter(id => !ids.includes(id));
    }

    props.onCountTagBySelected(_.filter(listExercise, item => selectedList.includes(item._id)));
  };

  return (
    <Layout.TableHeader>
      <Layout.TableRow>
        <Layout.TableHeaderCell key="title" className="title header-fixed" isActive={sorter === 'title'}>
          <Layout.TableHeaderCellContent hasSort>
            <CheckBoxContainer isHasSelectedPrev={isHasSelectedPrev} onClick={e => e.stopPropagation()}>
              <Checkbox checked={isSelectedAll} onChange={selectAllExercise} />
            </CheckBoxContainer>
            <Layout.CellTitle onClick={onSortTitle}>Exercises ({total})</Layout.CellTitle>
            {renderSortIcon('title')}
          </Layout.TableHeaderCellContent>
        </Layout.TableHeaderCell>
        {_.map(Columns, item => {
          const keySorter = item.keySorter || item.key;

          return (
            <Layout.TableHeaderCell key={item.key} alignCenter={item.alignCenter} isActive={sorter === keySorter}>
              <Layout.TableHeaderCellContent hasSort={item.hasSort} onClick={onSort(item)}>
                <Layout.CellTitle>{item.name}</Layout.CellTitle>
                {item.hasSort && renderSortIcon(keySorter)}
              </Layout.TableHeaderCellContent>
            </Layout.TableHeaderCell>
          );
        })}
        <Layout.TableHeaderCell key="actions" className="actions" style={{ width: '135px' }} />
      </Layout.TableRow>
    </Layout.TableHeader>
  );
}

const TableHeader = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      exercise: {
        listExercise,
        selectedExercise,
        total,
        query: { sort, sorter },
      },
    },
  } = state;

  return { total, sorter, sort, listExercise, selectedExercise };
};

const mapDispatch = dispatch => {
  return {
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    updateSelectedExercise: bindActionCreators(updateSelectedExercise, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableHeader);
