import React, { useState, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import {
  FormGroup,
  Container,
  ButtonGroup,
  CalendarWrapper,
  InputTask,
  Trigger,
  InputBody,
  InputContainer,
  InputHeader,
} from '../styles';
import { Button } from 'shared/FormControl';
import moment from 'moment';
import { ErrorMessage } from 'shared/FormControl';
import TIME_OPTIONS from 'constants/time-options';
import ReactTooltip from 'react-tooltip';
import Datetime from 'react-datetime';
import { Reminder } from './Reminder';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import _ from 'lodash';
import { CleanEmptyStringFromObject } from 'utils/commonFunction';
import TextEditable from 'shared/TextEditable';
import { COLOR_OPTIONS } from '../constants';
import { ReactComponent as ColorIcon } from 'assets/icons/color-tag.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UserIcon } from 'assets/icons/assign_to_icon.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock_task_icon.svg';

import AsyncSelect from 'react-select/lib/Async';
import SelectClientOption from 'shared/SelectClientOption';
const DEFAULT_REMINDER_TIME = '08:00 AM';

const CreatedByBlock = ({ user, task }) => {
  let name;
  if (user._id === task.owner._id) {
    name = 'You';
  } else {
    name = task.owner.full_name;
  }
  return (
    <FormGroup className="created_by">
      Task created by <b>{name}</b>
    </FormGroup>
  );
};

const DateBlock = ({ date }) => {
  const selectedDate = moment(date, 'MM DD YYYY');
  const selectedYear = selectedDate.year();
  const currentYear = moment().year();
  if (selectedYear == currentYear) {
    return selectedDate.format('MMM DD');
  } else {
    return selectedDate.format('MMM DD YYYY');
  }
};

export const AddNewTask = props => {
  const titleRef = React.createRef();
  const prevTask = props.task || {};
  const prevDates = prevTask.date || {
    date: '',
    time: '',
  };
  const prevDate = prevDates.date || null;
  const prevTime = prevDates.time || '';
  const prevTitle = prevTask.title || '';
  const prevDetails = prevTask.details || '';
  const prevColor = prevTask.color || '';
  const prevAssignees = prevTask.assignees ? prevTask.assignees.slice() : [];
  const [menuStyles, setMenuStyles] = useState({});
  const [disabled, setButtonDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [title, setTitle] = useState(prevTitle || '');
  const [starting, setStartingDate] = useState(prevDate || null);
  const [details, setDetails] = useState(prevDetails || '');
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(prevColor || '');
  const [reminderTime, setReminderTime] = useState(prevTime || '');
  const [toggle, setToggle] = useState(prevTime ? true : false);
  const [assignees, setAssingees] = useState(prevAssignees || []);

  useEffect(() => {
    if (!props.update && titleRef.current) {
      titleRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (props.update) {
      if (checkDifference()) {
        setIsChange(false);
      } else {
        setIsChange(true);
      }
    }
  }, [title, starting, details, color, reminderTime, assignees]);
  const handleTitleChange = e => {
    setTitle(e);
  };
  const handleRemoveColor = e => {
    e.stopPropagation();
    setColor('');
  };
  const handleRemoveDate = () => {
    setStartingDate(null);
    setReminderTime('');
    setToggle(false);
  };

  const handleTimeValue = value => {
    const currentTime = moment();
    if (starting && currentTime.isSame(starting, 'date') && value === DEFAULT_REMINDER_TIME) {
      const time = currentTime.format('HH:mm');
      const newTime = TIME_OPTIONS.filter(item => item.value > time);

      return newTime[0].label;
    }
    return value;
  };

  const hanldeUpdateStartDate = newDate => {
    const correctDateFormat = newDate.format('MM DD YYYY');

    if (correctDateFormat !== starting) {
      setToggle(false);
      setReminderTime('');
    }

    setStartingDate(correctDateFormat);
  };
  const handleToggle = () => {
    if (toggle) {
      setReminderTime(null);
      setToggle(false);
    } else {
      setReminderTime(handleTimeValue(DEFAULT_REMINDER_TIME));
      setToggle(true);
    }
  };
  const handleChangeReminderTime = data => {
    const time = handleTimeValue(data.label);
    setReminderTime(time);
  };
  const handleClosePopUp = () => {
    setOpen(false);
    if (!reminderTime) {
      setToggle(false);
    }
  };
  const trigger = e => {
    const triggerRect = e.target.getBoundingClientRect();
    const { height, y } = triggerRect;
    const windowHeight = window.innerHeight;
    const styles = {};
    //ANCHOR 400 is the height of the InputContainer
    if (400 >= windowHeight - y - height) {
      styles.right = '368px';
      styles.top = '-110px';
    } else {
      styles.left = '23px';
      styles.top = '37px';
    }
    setMenuStyles(styles);
    if (!open) setOpen(true);
    else handleClosePopUp();
  };

  const handleDetailsChange = e => {
    setDetails(e);
  };
  const handleColorChange = (e, value) => {
    e.preventDefault();
    setColor(value);
  };
  const correctColor = color => {
    return COLOR_OPTIONS.filter(e => e.color === color)[0].colorId;
  };
  const validate = body => {
    const newTitle = body.title.trim();
    if (!newTitle) {
      setShowError(true);
      return false;
    }
    setShowError(false);
    return true;
  };
  const onSubmit = async e => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    const body = {
      title: title,
      details: details,
      date: {
        date: starting,
        time: reminderTime,
      },
      assignees: assignees,
      schedule_notification: toggle,
      color: color,
    };
    if (validate(body)) {
      if (!!props.update && props.task._id) {
        const update = {
          update: body,
          taskId: props.task._id,
        };
        setButtonDisabled(true);
        try {
          await props.updateTask(update);
        } catch (err) {}
      } else {
        const newBody = CleanEmptyStringFromObject(body);
        setButtonDisabled(true);
        try {
          await props.addTask(newBody);
        } catch (err) {}
      }
      try {
        await props.handleBacktoList();
      } catch (err) {}
    }
  };
  const checkDifference = () => {
    return (
      prevTitle === title &&
      prevDates.time === reminderTime &&
      prevDates.date === starting &&
      prevColor === color &&
      prevDetails === details &&
      _.isEqual(prevAssignees, assignees)
    );
  };

  const searchTeammates = (inputValue, callback) => {
    let except;
    except = _.map(assignees, '_id');
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    props
      .searchTrainers({ q: search, selected_trainers: except })
      .then(response => {
        const { data } = response.data;
        if (data) {
          const newlist = [
            ...assignees.map(trainer => {
              return {
                ...trainer,
                key: trainer._id,
                value: trainer._id,
                label: `${trainer.first_name} ${trainer.last_name}`,
              };
            }),
            ...data,
          ];
          callback(
            _.map(newlist, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label: `${item.first_name} ${item.last_name}`,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };
  const searchTeammatesDebounce = _.debounce(searchTeammates, 100);

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <FormGroup>
          <TextEditable
            ref={titleRef}
            autoFocus={true}
            value={title}
            onChange={handleTitleChange}
            className={'input_title'}
            placeholder="Name your Task"
            lineHeight={27}
            type="text"
            invalid={!title && showError}
          />
          {!title && showError && <ErrorMessage className="error_message">Please add a Task title</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <label className="task_label"> Task Detail</label>
          <TextEditable
            value={details}
            onChange={handleDetailsChange}
            className={'input_details'}
            placeholder="Add detail"
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <RootCloseWrapper disabled={!open} onRootClose={handleClosePopUp}>
            <CalendarWrapper className={open ? 'open' : ''}>
              <Trigger>
                <InputTask className={open ? 'open clock' : 'clock'}>
                  <div onClick={trigger} className="left_content">
                    <div className="image">
                      <ClockIcon className={starting ? 'clock_icon has_value' : 'clock_icon'} height={20} width={20} />
                    </div>
                    {starting ? (
                      <div className="date_time">
                        <DateBlock date={starting} />
                        {reminderTime && ` - ${reminderTime}`}
                      </div>
                    ) : (
                      <div className="due_time"> Due date </div>
                    )}
                  </div>
                  {starting && <CloseIcon onClick={handleRemoveDate} className="margin-right" />}
                </InputTask>
              </Trigger>
              <InputContainer style={menuStyles}>
                <InputHeader>
                  <Datetime
                    value={moment(starting, 'MM DD YYYY')}
                    renderInput={() => null}
                    timeFormat={false}
                    strictParsing={false}
                    open={true}
                    onChange={hanldeUpdateStartDate}
                    className="new-ui"
                    isValidDate={currentDate => currentDate.isSameOrAfter(moment(), 'day')}
                  />
                </InputHeader>
                <InputBody>
                  <Reminder
                    starting={starting}
                    scheduleReminder={toggle}
                    reminderTime={reminderTime}
                    handleChangeReminderTime={handleChangeReminderTime}
                    handleToggleScheduleReminder={handleToggle}
                  />
                </InputBody>
              </InputContainer>
            </CalendarWrapper>
          </RootCloseWrapper>
        </FormGroup>
        <FormGroup>
          <InputTask>
            <Dropdown
              triggerIcon={() => (
                <div className="space-between">
                  {color ? (
                    <>
                      <div className="input_color">
                        <div className="selected_color_block" style={{ background: correctColor(color) }}></div>
                        <div className="input_text"> {color} </div>
                      </div>
                      <CloseIcon onClick={handleRemoveColor} className="margin-right" />
                    </>
                  ) : (
                    <div className="no_color">
                      <ColorIcon />
                      <div className="input_text"> Color Tag </div>
                    </div>
                  )}
                </div>
              )}
            >
              {_.map(COLOR_OPTIONS, item => (
                <Option
                  key={item.key}
                  style={{ background: item.colorId }}
                  onClick={e => handleColorChange(e, item.color)}
                ></Option>
              ))}
            </Dropdown>
          </InputTask>
        </FormGroup>
        <FormGroup className="assign_to">
          <UserIcon
            height={21}
            width={21}
            className="user_icon"
            className={!_.isEmpty(assignees) ? 'user_icon has_value' : 'user_icon'}
          />
          <AsyncSelect
            isMulti
            defaultOptions
            cacheOptions
            styles={{
              control: base => ({ ...base, minHeight: 38 }),
              valueContainer: base => ({
                ...base,
                minHeight: 38,
                cursor: 'pointer',
              }),
              input: base => ({
                ...base,
                minHeight: 38,
                margin: 0,
                padding: 0,
              }),
            }}
            defaultValue={
              !_.isEmpty(assignees) &&
              assignees.map(trainer => {
                return {
                  ...trainer,
                  key: trainer._id,
                  value: trainer._id,
                  label: `${trainer.first_name} ${trainer.last_name}`,
                };
              })
            }
            components={{
              Option: SelectClientOption,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            loadOptions={(inputValue, callback) => searchTeammatesDebounce.call(this, inputValue, callback)}
            onChange={list => setAssingees(list)}
            className="multi-select-container"
            classNamePrefix="multi-select"
            placeholder="Assign to"
            noOptionsMessage={() => 'No trainers found.'}
          />
        </FormGroup>
        {props.update && <CreatedByBlock user={props.user} task={prevTask} />}
        <ButtonGroup className="form_button">
          {props.update ? (
            <>
              {' '}
              {isChange && (
                <Button disabled={disabled} purple>
                  {' '}
                  Update task{' '}
                </Button>
              )}
            </>
          ) : (
            <Button disabled={disabled} purple>
              {' '}
              Add New{' '}
            </Button>
          )}
        </ButtonGroup>
      </form>
    </Container>
  );
};
