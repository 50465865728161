import React, { useState, useEffect } from 'react';
import * as S from './style';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Mixpanel } from 'utils/mixplanel';
import ForumBackgroundImg from 'assets/images/community.png';
import ExploreIcon from 'assets/icons/explore_leaderboard.svg';
import ExploreIconSmall from 'assets/icons/explore_leaderboard_small.svg';
import { axiosInstance } from 'configs/request';

const ForumTeaser = ({ permission }) => {
  const [autoflow, setAutoflow] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get('/api/autoflow/v2/list', { page: 1, per_page: 100, sorter: 'last_used' })
      .then(response => {
        const { data } = response.data;

        if (data.length) {
          const exploreAutoflow = _.find(data, item => !!item.is_demo) || data[0];
          setAutoflow(exploreAutoflow._id);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Adding Mixpanel
  useEffect(() => {
    Mixpanel.track('view_community_page');
  }, []);

  const handleClickUpgradeButton = () => {
    window.location = `/pricing-plan`;
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.Top>
          <S.TopTitle>Community Forums</S.TopTitle>
          <S.TopText>
            Build a digital community and drive engagement with community forums and leaderboard for your clients
          </S.TopText>

          <S.ButtonArea>
            <S.UpgradeButton onClick={handleClickUpgradeButton}>Get Forum FEATURE</S.UpgradeButton>
          </S.ButtonArea>
        </S.Top>
        <S.Content>
          <div className="content">
            <S.ForumBackground src={ForumBackgroundImg} />
            {!loading && (
              <Link
                className="explore"
                to={permission && autoflow ? `/home/autoflow/${autoflow}/leaderboard` : '/home/autoflow'}
              >
                <picture>
                  <source media="(min-width: 1220px)" srcset={ExploreIcon} />
                  <source media="(max-width: 1220px)" srcset={ExploreIconSmall} />
                  <img src={ExploreIcon} alt="Explore" />
                </picture>
              </Link>
            )}
          </div>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};

export default connect(state => ({ permission: _.get(state.rootReducer, 'permission.leaderboard') }))(ForumTeaser);
