import Request from 'configs/request';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { toast } from 'react-toastify';

export const Types = {
  CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_REQUEST: 'CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_REQUEST',
  CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_SUCCESS: 'CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_SUCCESS',
  CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_ERROR: 'CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_ERROR',
  CLIENT_MEAL_PLAN_UPDATE_NOTE_REQUEST: 'CLIENT_MEAL_PLAN_UPDATE_NOTE_REQUEST',
  CLIENT_MEAL_PLAN_UPDATE_NOTE_SUCCESS: 'CLIENT_MEAL_PLAN_UPDATE_NOTE_SUCCESS',
  CLIENT_MEAL_PLAN_UPDATE_NOTE_FAILED: 'CLIENT_MEAL_PLAN_UPDATE_NOTE_FAILED',
  CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_REQUEST: 'CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_REQUEST',
  CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_SUCCESS: 'CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_SUCCESS',
  CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_FAILED: 'CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_FAILED',
  CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_REQUEST: 'CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_REQUEST',
  CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_SUCCESS: 'CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_SUCCESS',
  CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_FAILED: 'CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_FAILED',
  CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_REQUEST: 'CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_REQUEST',
  CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_SUCCESS: 'CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_SUCCESS',
  CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_FAILED: 'CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_FAILED',
  CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_REQUEST: 'CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_REQUEST',
  CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_SUCCESS: 'CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_SUCCESS',
  CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_FAILED: 'CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_FAILED',
  CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_REQUEST: 'CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_REQUEST',
  CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_SUCCESS: 'CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_SUCCESS',
  CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_FAILED: 'CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_FAILED',
  CLIENT_MEAL_PLAN_CALENDAR_RESET_CLIENT_TAB: 'CLIENT_MEAL_PLAN_CALENDAR_RESET_CLIENT_TAB',
  CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_DAY: 'CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_DAY',
  CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_WEEK: 'CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_WEEK',
  CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_REQUEST: 'CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_REQUEST',
  CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_SUCCESS: 'CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_SUCCESS',
  CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_FAILED: 'CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_FAILED',
  CLIENT_MEAL_PLAN_RESET_LIST_STATUS_MEAL_PLAN_FORMAT: 'CLIENT_MEAL_PLAN_RESET_LIST_STATUS_MEAL_PLAN_FORMAT',
  CLIENT_MEAL_PLAN_UPDATE_LIST_STATUS_MEAL_PLAN_FORMAT: 'CLIENT_MEAL_PLAN_UPDATE_LIST_STATUS_MEAL_PLAN_FORMAT',
  CLIENT_MEAL_PLAN_CHANGE_VIEW_MODE: 'CLIENT_MEAL_PLAN_CHANGE_VIEW_MODE',
  CLIENT_MEAL_PLAN_TOGGLE_REARRANGE_MODE: 'CLIENT_MEAL_PLAN_TOGGLE_REARRANGE_MODE',
  CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_REQUEST: 'CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_REQUEST',
  CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_SUCCESS: 'CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_SUCCESS',
  CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_FAILED: 'CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_FAILED',
  CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_REQUEST: 'CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_REQUEST',
  CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_SUCCESS: 'CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_SUCCESS',
  CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_FAILED: 'CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_FAILED',
  CLIENT_MEAL_PLAN_REARRANGE_REQUEST: 'CLIENT_MEAL_PLAN_REARRANGE_REQUEST',
  CLIENT_MEAL_PLAN_REARRANGE_SUCCESS: 'CLIENT_MEAL_PLAN_REARRANGE_SUCCESS',
  CLIENT_MEAL_PLAN_REARRANGE_FAILED: 'CLIENT_MEAL_PLAN_REARRANGE_FAILED',
  CLIENT_MEAL_PLAN_SET_CURRENT_RANGE_WEEK: 'CLIENT_MEAL_PLAN_SET_CURRENT_RANGE_WEEK',
  CLIENT_MEAL_PLAN_SET_CURRENT_SELECTED_DAY: 'CLIENT_MEAL_PLAN_SET_CURRENT_SELECTED_DAY',
};

export const getClientMealPlanByDay = (clientId, selectedDay) => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_REQUEST });

    return dispatch(
      Request.get(
        { url: `/api/meal-clients/get-by-day?client=${clientId}&day=${selectedDay}` },
        true,
        result => {
          const mealPlan = get(result, 'data.data');
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_SUCCESS, payload: mealPlan });
        },
        error => dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_FETCH_BY_DAY_ERROR, error }),
      ),
    );
  };
};

export const getClientMealPlanByMonth = params => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/meal-plan-clients/overview`,
          params: params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data');
          if (isObject(data)) {
            dispatch({
              type: Types.CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_SUCCESS,
              payload: { data },
            });
          }
        },
        () => {
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_GET_DATA_OVERVIEW_FAILED });
        },
      ),
    );
  };
};

export const getListMealPlan = params => {
  return Request.get(
    {
      url: '/api/meal-plans',
      params,
    },
    true,
  );
};

export const getListRecipe = params => {
  return Request.get(
    {
      url: '/api/recipe-libraries',
      params,
    },
    true,
  );
};

export const updateClientMealPlanNote = data => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_UPDATE_NOTE_REQUEST });

    return dispatch(
      Request.patch(
        { url: `/api/meal-clients`, data },
        true,
        response => {
          const note = get(response, 'data.data.note', '');

          dispatch({ type: Types.CLIENT_MEAL_PLAN_UPDATE_NOTE_SUCCESS, payload: { note } });
        },
        error => dispatch({ type: Types.CLIENT_MEAL_PLAN_UPDATE_NOTE_FAILED }),
      ),
    );
  };
};

export const updateClientMealPlanWeekNote = data => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/meal-client-week-notes`, data },
        true,
        response => {
          const weekNote = get(response, 'data.data.note', '');

          dispatch({ type: Types.CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_SUCCESS, payload: { weekNote } });
        },
        error => dispatch({ type: Types.CLIENT_MEAL_PLAN_UPDATE_WEEK_NOTE_FAILED }),
      ),
    );
  };
};

export const updateMealCategoryClient = (data, callback) => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: '/api/meal-clients',
          data: data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (isObject(data)) {
            dispatch(getClientMealPlanByDay(data && data.client, data && data.day));
            // TODO: Update later
            // dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_SUCCESS, payload: { data } });
            callback && callback();
          }
        },
        () => {
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_MEAL_CATEGORY_FAILED });
        },
      ),
    );
  };
};

export const addRecipeTrainer = (data, callback) => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_REQUEST });
    return dispatch(
      Request.post(
        {
          url: '/api/meal-clients/recipes/trainer',
          data: data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (isObject(data)) {
            dispatch(getClientMealPlanByDay(data && data.client, data && data.day));
            // TODO: Update later
            callback && callback();
          }
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_SUCCESS });
        },
        error => {
          const { status } = get(error, 'response', {});
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_ADD_RECIPE_TRAINER_FAILED });

          if (status === 400) {
            callback && callback();
          }
        },
      ),
    );
  };
};

export const swapRecipeTrainer = (id, data, callback) => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/meal-clients/${id}/swap-recipe/trainer`,
          data: data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (isObject(data)) {
            dispatch(getClientMealPlanByDay(data && data.client, data && data.day));
            // TODO: Update later
            callback && callback();
          }
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_SUCCESS });
        },
        error => {
          const { status } = get(error, 'response', {});
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_SWAP_RECIPE_CLIENT_FAILED });

          if (status === 400) {
            callback && callback();
          }
        },
      ),
    );
  };
};

export const removeRecipeTrainer = (id, params, callback) => {
  return (dispatch, getState) => {
    const currentState = getState();
    const loadingAction = get(currentState, 'rootReducer.clientMealPlan.loadingAction', false);
    if (loadingAction) return;
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_REQUEST });
    return dispatch(
      Request.delete(
        {
          url: `/api/meal-clients/${id}/remove-recipe/trainer`,
          params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (isObject(data)) {
            dispatch(getClientMealPlanByDay(data && data.client, data && data.day));
            // TODO: Update later
            callback && callback();
          }
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_SUCCESS });
        },
        () => {
          dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_REMOVE_RECIPE_TRAINER_FAILED });
        },
      ),
    );
  };
};

export const resetClientMealPlan = () => {
  return dispatch => dispatch({ type: Types.CLIENT_MEAL_PLAN_CALENDAR_RESET_CLIENT_TAB });
};

export const updateClientMealPlanByDay = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        clientMealPlan: { mealPlanByDay, rangeWeek },
      },
    } = getState();

    if (get(data, 'day', '') === get(mealPlanByDay, 'day', '')) {
      dispatch({
        type: Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_DAY,
        payload: { data },
      });
    }
    if ((rangeWeek || []).includes(get(data, 'day'))) {
      dispatch({
        type: Types.CLIENT_MEAL_PLAN_CALENDAR_UPDATE_DATA_BY_WEEK,
        payload: { dataDayOfWeek: data },
      });
    }
  };
};

export const getStatusMealPlanFormat = params => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_REQUEST });
    return dispatch(
      Request.get(
        { url: '/api/meal-plan-preference/choosing-meal-plan-format', params },
        true,
        res => {
          const data = get(res, 'data.data', {});
          if (isObject(data)) {
            dispatch({ type: Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_SUCCESS, payload: { data } });
          }
        },
        error => dispatch({ type: Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_FAILED, error }),
      ),
    );
  };
};

export const updateListStatusMealPlanFormat = data => {
  return dispatch => {
    dispatch({
      type: Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_REQUEST,
    });
    return dispatch(
      Request.put(
        {
          url: '/api/meal-plan-preference/choosing-meal-plan-format',
          data: data,
        },
        true,
        response => {
          if (get(response, 'data.data.success')) {
            dispatch({
              type: Types.CLIENT_MEAL_PLAN_UPDATE_LIST_STATUS_MEAL_PLAN_FORMAT,
              payload: { data },
            });
          }
        },
        () => {
          dispatch({ type: Types.CLIENT_MEAL_PLAN_GET_LIST_MEAL_PLAN_FORMAT_FAILED });
        },
      ),
    );
  };
};

export const resetStatusMealPlanFormat = () => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_RESET_LIST_STATUS_MEAL_PLAN_FORMAT });
  };
};

export const changeClientMealPlanViewMode = item => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_CHANGE_VIEW_MODE, payload: { item } });
  };
};

export const toggleRearrangeMode = status => {
  return dispatch => {
    if (!status) {
      toast("You're in rearrange mode.");
    }

    dispatch({ type: Types.CLIENT_MEAL_PLAN_TOGGLE_REARRANGE_MODE, payload: { status } });
  };
};

export const getClientMealPlanByWeek = (clientId, day) => {
  return dispatch => {
    dispatch({
      type: Types.CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_REQUEST,
    });
    return dispatch(
      Request.get(
        {
          url: '/api/meal-clients/get-by-week',
          params: { day, client: clientId },
        },
        true,
        response => {
          const { meal_clients, note } = get(response, 'data.data', {});
          dispatch({
            type: Types.CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_SUCCESS,
            payload: { mealClients: meal_clients, note },
          });
        },
        () => {
          dispatch({ type: Types.CLIENT_MEAL_PLAN_GET_LIST_WEEKLY_VIEW_FAILED });
        },
      ),
    );
  };
};

export const reArrangeMealClientRecipe = (data, recipeId) => {
  return dispatch => {
    dispatch({
      type: Types.CLIENT_MEAL_PLAN_REARRANGE_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: `/api/meal-clients/recipes/${recipeId}/rearrange`,
          data,
        },
        true,
        response => {
          const { data } = get(response, 'data', {});
          if (!!data) {
            dispatch({
              type: Types.CLIENT_MEAL_PLAN_REARRANGE_SUCCESS,
            });
          }
        },
        () => {
          dispatch({ type: Types.CLIENT_MEAL_PLAN_REARRANGE_FAILED });
        },
      ),
    );
  };
};

export const setCurrentRangeWeek = rangeWeek => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_MEAL_PLAN_SET_CURRENT_RANGE_WEEK, payload: { rangeWeek } });
  };
};
