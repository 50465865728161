import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { NewSearchInput } from 'shared/SearchInput';
import { Checkbox } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export const AddStudioProgramClientModal = styled(Modal)`

  &.ui.modal {
    width: 650px;

    .header {
      border-radius: 8px;    
      padding-top: 20px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 0px !important;
      color: #202353 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .header__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #202353;
      }
    }

    .content {
      padding: 30px 30px 0px 30px !important;
    }

    .actions {
      background: #FFFFFF;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
      border: none;
      padding-top: 10px;
      padding-bottom: 10px;

      .actions__cancelBtn {
        padding: 7px 25px;
      }

      .actions__createBtn {
        margin-left: 5px;
        padding: 7px 35px;

        :disabled {
          background-color: #C4C4C4
          border-color: #C4C4C4
        }
      }
    }
  }

  .programResult__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .programResult__selectAll {
    display: flex;
    
    &--text {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #202353;
      opacity: 0.8;
      margin-right: 10px;
    }
  }

  .programResult__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #202353;
    margin-bottom: 15px;
  }
`;

export const SearchProgramInput = styled(NewSearchInput)`
  min-width: 270px;

  input {
    padding-top: 10px;
    padding-bottom: 10px;
    background: url(${CDN_URL}/images/search_grey_purple.svg) no-repeat;
    background-size: auto;
    background-position: 15px center;
    background-color: #f6f7f9;
    border: 1px solid transparent;

    ::placeholder {
      color: #a6acc6;
      font-size: 13px;
      line-height: 18px;
    }

    &:focus {
      border: 1px solid #7072dd;
    }
  }
`;

export const ProgramResultContainer = styled.div`
  max-height: 450px;
  min-height: 450px;
  overflow: auto;
  padding-right: 21px;
  margin-right: -21px;

  .programResult__noResult {
    margin: auto;
    text-align: center;
    margin-top: 200px;
  }
`;

export const ProgramItemWrapper = styled.div`
  height: 127px;
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    border: 1px solid #5158cf;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid #5158cf;
    `}

  .programItem__avatar {
    width: 200px;
    height: 100%;
    background: url(${props => props.coverImage || `${CDN_URL}/images/studio_program_default_cover_image.png`})
      no-repeat center center;
    background-size: cover;
  }

  .programItem__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;

    &--title {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 350px;
      white-space: nowrap;
    }

    &--description {
      font-size: 12px;
      line-height: 150%;
      color: #202353;
      opacity: 0.8;

      overflow: hidden;
      text-overflow: ellipsis;
      width: 350px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &--statistic {
      font-weight: 600;
      font-size: 11px;
      line-height: 150%;
      color: #202353;
    }
  }
`;

export const CheckBoxIconWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 3px;
`;

export const CheckboxProgram = styled(Checkbox)`
  input {
    :checked ~ .checkbox-icon {
      background-color: #7072dd;
      border-color: #7072dd;
      background-image: url(${CDN_URL}/images/check_studio_active.svg);
    }
  }

  .checkbox-icon {
    border: 1px solid #dadfea;
    border-radius: 3px;
    height: 16px;
    width: 16px;

    :hover {
      border: 1px solid #7072dd;
    }
  }
`;
