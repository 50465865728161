// Libs
import React from 'react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import moment from 'moment';

// Components
import AddNote from 'components/MealPlanDetail/Parts/AddNote';
import NoteModal from 'components/MealPlanDetail/Parts/NoteModal';
import LoadingNoteSkeleton from './LoadingNoteSkeleton';

// Constants
import { getWeekTitle } from 'components/ClientMealPlan/helper';

// Styles
import * as S from './style';

const ClientMealPlanNote = ({
  toggleModal,
  toggleConfirmModal,
  selectedClient,
  selectedDay,
  note,
  updateClientMealPlanNote,
  loading,
  assignedMealPlanClientsByRange,
  mealPlanByDay,
  viewMode,
  clientTimezone,
  updateClientMealPlanWeekNote,
}) => {
  const { meal_client_completed = false } = mealPlanByDay || {};

  const getContentToast = type => {
    switch (type) {
      case 'delete':
        toast('Note deleted.');
        break;
      case 'update':
        toast('Successfully updated.');
        break;
      case 'add':
        toast('Note added.');
        break;
      default:
        break;
    }
  };

  const handleUpdateNote = (data, type) => {
    if (loading) return;

    const itemWithCurrentDate = (assignedMealPlanClientsByRange || []).find(item => {
      const { day, end_day } = item || {};
      const startDate = moment(day);
      const endDate = moment(end_day);
      return selectedDay.isBetween(startDate, endDate, null, '[]');
    });

    if (viewMode.key === 'day') {
      const params = {
        client: get(selectedClient, '_id'),
        day: selectedDay.format('YYYY-MM-DD'),
        note: data,
        meal_plan: get(itemWithCurrentDate, '_id', undefined),
      };

      typeof updateClientMealPlanNote === 'function' &&
        updateClientMealPlanNote(params).then(() => {
          toggleModal && toggleModal(false);
          getContentToast(type);
        });
    } else if (viewMode.key === 'week') {
      const params = {
        client: get(selectedClient, '_id'),
        date: selectedDay.format('YYYY-MM-DD'),
        note: data,
      };

      typeof updateClientMealPlanWeekNote === 'function' &&
        updateClientMealPlanWeekNote(params).then(() => {
          toggleModal && toggleModal(false);
          getContentToast(type);
        });
    }
  };

  const handleOpenNote = () => {
    const today = moment();
    const dayTitle = selectedDay.format(today.isSame(selectedDay, 'year') ? 'MMM D' : 'MMM D, YYYY');
    const weekTitle = getWeekTitle(selectedDay, clientTimezone);
    const titleNote = viewMode.key === 'day' ? dayTitle : weekTitle;

    toggleModal &&
      toggleModal(
        true,
        <NoteModal
          title={`${titleNote}'s Note`}
          content={note}
          toggleModal={toggleModal}
          handleUpdateNote={handleUpdateNote}
          toggleConfirmModal={toggleConfirmModal}
          disabledAction={meal_client_completed}
        />,
      );
  };

  if (loading) {
    return <LoadingNoteSkeleton />;
  }

  return (
    <>
      <S.NoteWrapper hasNote={!!note}>
        <AddNote
          content={`Leave a note for this ${viewMode.key === 'day' ? 'day' : 'week'}`}
          hasNote={!!note}
          onClick={handleOpenNote}
          note={note}
          hasShowContent
        />
      </S.NoteWrapper>
    </>
  );
};

export default ClientMealPlanNote;
