import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  position: relative;
  width: auto;
  .filter__dropdown {
    background-color: #f5f5f5;
    cursor: pointer;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    color: #262626;
    &.is-selected {
      padding: 6px 12px;
    }
    &.active,
    :hover {
      background-color: #f4f4fc !important;
    }
    .filter__dropdown__title {
      white-space: nowrap;
      .filter-name {
        width: 123px;
        margin-left: 1px;
      }

      .colons {
        margin-left: 1px;
        margin-right: 4px;
        width: 4px;
      }

      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }

      .is-selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }
    }
  }
`;

export const MenuContainer = styled.div`
  width: 330px;
  max-height: 244px;
  border-radius: 4px;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border: solid 1px #e4e6e8;
  background-color: #fff;
  padding: 11px 12px;
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 999;
  .form-group label {
    margin-bottom: 5px;
    color: #96a4b4;
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
  }
  > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const SearchBar = styled.div`
  .search-input {
    height: 34px;
    width: 306px;
    input {
      background: url(${CDN_URL}/images/search_white.svg) no-repeat scroll 13px center !important;
      padding-left: 41px;

      color: #262626;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      ::placeholder {
        color: #bfbfbf;
        opacity: 1;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    svg {
      width: 16px;
      height: 16px;
      right: 16px;
    }
  }
`;

export const ForumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 178px;
  width: 306px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-y: auto;
  padding-right: ${props => (props.hiddenScrollBar ? '0px' : '2px')};

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15) !important;
  }
`;

export const SelectForumOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 7px 6px 10px;
  cursor: pointer;

  :hover {
    background-color: #f4f4fc;

    .checkbox label {
      &::before {
        border-color: #5158cf !important;
      }
      &::after {
        background-color: #5158cf !important;
      }
    }
  }
`;

export const ItemForumContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .forum-icon {
    margin: 4px;
  }
  .forum-name {
    color: #595959;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 219px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 1px;
    &.all-forum {
    }
    &.is-selected {
      color: #262626;
    }
    &.short {
      max-width: 177px;
    }
  }
`;

const getBackgroundImage = isBackground => {
  return isBackground || `${CDN_URL}/images/default_forum_banner.svg`;
};

export const ImageBanner = styled.div`
  &.forum-banner {
    border-radius: 2px;
    width: 32px;
    height: 32px;
  }
  &.default-bg {
    background-image: url(${props => getBackgroundImage(props.isBackground)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &.is-selected {
    width: 24px;
    height: 24px;
  }
`;

export const RadioButton = styled.div`
  .ui.radio.checkbox {
    min-height: 16px;
    min-width: 16px;
    line-height: 16px;
    display: block;
    margin-top: -2px;
    margin-right: -1px;

    label::before {
      border-width: 2px !important;
      width: 16px !important;
      height: 16px !important;
    }
    label::after {
      width: 16px !important;
      height: 16px !important;
    }
    &.checked label::before {
      border-color: #5158cf !important;
    }
    &.checked label::after {
      background-color: #5158cf !important;
    }
  }
`;

export const EmptyResultFound = styled.div`
  padding-top: 86px;
  padding-left: 92px;
  width: 306px;
  height: 178px;

  color: #bfbfbf;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .forum-loading {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
