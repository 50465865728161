import React, { useRef } from 'react';
import { ReactComponent as SurveyIcon } from 'assets/icons/survey_item_icon.svg';
import get from 'lodash/get';
import { MenuTrigger } from 'shared/Icons';
import DropDown, { Option } from 'shared/Dropdown/Basic';

import { SurveyItemWrapper, SurveyInformationWrapper, SurveyIconWrapper, SurveyInfo, Dot } from './SurveyItem.styles';
import { pluralize } from 'utils/commonFunction';
import { ReactComponent as PreviewIcon } from 'assets/icons/survey_action_preview.svg';
import { ReactComponent as EditIcon } from 'assets/icons/survey_action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/survey_action_duplicate.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/survey_action_archive.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/survey_action_trash.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/survey_action_unarchive.svg';
import { SURVEY_ACTIONS } from '..';
import useSurveyPermission from '../../../hooks/useSurveyPermission';

const SurveyItem = ({ survey, onAction, permission, user }) => {
  const isArchived = get(survey, 'is_archive', false);
  const totalPackageApplied = get(survey, 'total_package_applied', 0);
  const totalQuestions = get(survey, 'questions.length', 0);
  const surveyAuthorId = get(survey, 'author', '');
  const { hasPermission } = useSurveyPermission(surveyAuthorId, user, permission);
  const closeDropdownRef = useRef();
  const authorName = get(survey, 'author_name', null);

  const handleAction = (action, event) => {
    event.stopPropagation();
    closeDropdownRef.current && closeDropdownRef.current();
    if (isArchived && action === SURVEY_ACTIONS.PREVIEW) return;

    onAction(action, survey);
  };

  const renderTrigger = ({ open }) => <MenuTrigger vertical active={!!open} />;

  const renderActiveActions = () => {
    return (
      <>
        {hasPermission && (
          <Option key="edit" onClick={event => handleAction(SURVEY_ACTIONS.EDIT, event)} data-item={survey.id}>
            <EditIcon />
            <span>Edit</span>
          </Option>
        )}
        <Option key="duplicate" onClick={event => handleAction(SURVEY_ACTIONS.DUPLICATE, event)} data-item={survey.id}>
          <DuplicateIcon />
          <span>Duplicate</span>
        </Option>
        <Option key="preview" onClick={event => handleAction(SURVEY_ACTIONS.PREVIEW, event)} data-item={survey.id}>
          <PreviewIcon />
          <span>Preview</span>
        </Option>
        {!totalPackageApplied && (
          <Option key="archive" onClick={event => handleAction(SURVEY_ACTIONS.ARCHIVE, event)} data-item={survey.id}>
            <ArchiveIcon />
            <span>Archive</span>
          </Option>
        )}
      </>
    );
  };

  const renderInactiveActions = () => {
    return (
      <>
        <Option key="unarchive" onClick={event => handleAction(SURVEY_ACTIONS.UNARCHIVE, event)} data-item={survey.id}>
          <HistoryIcon />
          <span>Unarchive</span>
        </Option>
        <Option key="delete" onClick={event => handleAction(SURVEY_ACTIONS.DELETE, event)} data-item={survey.id}>
          <DeleteIcon className="white-color" />
          <span>Delete</span>
        </Option>
      </>
    );
  };

  return (
    <SurveyItemWrapper
      className={!isArchived ? 'survey__active' : ''}
      onClick={event => handleAction(SURVEY_ACTIONS.PREVIEW, event)}
    >
      <SurveyInformationWrapper>
        <SurveyIconWrapper className="icon_box">
          <SurveyIcon width="36px" height="36px" className="icon icon_default" />
        </SurveyIconWrapper>
        <SurveyInfo>
          <h6>{get(survey, 'survey_name', '')}</h6>
          <div className="survey__info-common">
            <p>
              <b>{totalQuestions}</b> {pluralize('Question', totalQuestions)}
            </p>
            <Dot />
            <p>
              Applied: <b>{totalPackageApplied}</b> {pluralize('package', totalPackageApplied)}
            </p>
            {!!authorName && (
              <>
                <Dot />
                <p className="survey__info-author">{authorName}</p>
              </>
            )}
          </div>
        </SurveyInfo>
      </SurveyInformationWrapper>
      <DropDown
        className="survey__actions"
        direction="left"
        triggerIcon={renderTrigger}
        closeDropdownRef={closeDropdownRef}
      >
        {isArchived ? renderInactiveActions() : renderActiveActions()}
      </DropDown>
    </SurveyItemWrapper>
  );
};

export default SurveyItem;
