import styled, { css } from 'styled-components';
import { TableCell, TableHeaderCell } from 'shared/LibraryLayout';
import { Wrapper } from 'shared/LibraryLayout';
import ReactTooltip from 'react-tooltip';
import AddButton from 'shared/AddButton';
import { CDN_URL } from 'constants/commonData';

export const WorkoutLibraryWrapper = styled(Wrapper)`
  padding: 0 20px 0 30px;

  .popup-title {
    -webkit-font-smoothing: auto;
  }
  .footer {
    button {
      width: 90px;
    }
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 55px;
  width: 55px;
`;

export const WorkoutCell = styled(TableCell)`
  text-align: left;

  &.tags {
    min-width: 74px;
    text-align: inherit;
  }

  &.most-recent {
    min-width: 135px;
  }

  ${props =>
    props.alignCenter &&
    css`
      text-align: center;
    `}

  .tags-container {
    display: flex;
    flex-flow: row wrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .tag-name {
    cursor: pointer;
    margin-right: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5c58c0;

    :hover {
      text-decoration: underline;
    }

    ::after {
      content: ',';
    }

    :last-child::after {
      content: '';
    }
  }
`;

export const WorkoutNameCell = styled(TableCell)`
  display: flex;
  text-align: left;
  padding: 20px 15px 17px;

  .evf-checkbox {
    height: 15px;
    width: 15px;
    padding-left: 0;
    padding-right: 0;
    margin-right: 15px;

    .checkbox-icon {
      height: 15px;
      width: 15px;
      margin-top: 3px;
      background-size: calc(15px / 2);
    }

    input:checked ~ .checkbox-icon {
      border: none;
      background-color: #7072dd !important;
      background-image: url(${CDN_URL}/images/checked_white.svg);
    }
  }

  .workoutContent {
    width: 100%;
  }

  .workoutName__nameContent {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    .workoutName__name {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #0d1419;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }

    .workoutName__numberOfExercises {
      font-size: 12px;
      font-weight: 600;

      color: #726ee4;
      background: #edecfc;

      border-radius: 12px;
      padding: 3px 15px;
      margin-left: 10px;
    }
  }

  .workoutName__description {
    font-size: 13px;
    line-height: 18px;
    color: #0d1419;
    opacity: 0.8;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }

  .workoutName__info {
    float: left;
    font-size: 12px;
    color: #99a1b1;
    line-height: 16px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin-top: 7px;

    .section-count-exercise {
      border-radius: 4px;
      background-color: #dfeafb;
      font-size: 10px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 14px;
      letter-spacing: normal;
      text-align: left;
      color: #5088e2;
      padding: 2px 6px;
      white-space: pre-line;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: none;
      margin-bottom: 3px;
    }

    .workoutName__exerciseName {
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #0d1419;
      opacity: 0.5;
    }
    .app-tooltip {
      text-align: left;
    }
  }
`;

export const FilterTrigger = styled.div`
  padding: 6px 25px;
  padding-left: 50px;
  background: url(${CDN_URL}/images/new_filter.svg) no-repeat 25px 6px;
  background-size: auto;
  background-color: #fff;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777;
  cursor: pointer;

  :hover {
    background-color: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  }
`;

export const ResetFilter = styled.div`
  display: inline-block;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  background: transparent url(${CDN_URL}/images/reset_new.svg) no-repeat 0 center;
  background-size: auto;

  :hover {
    cursor: pointer;
    background-image: url(${CDN_URL}/images/reset_new_purple.svg);
    color: #5158cf;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const ToolbarLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 5px;
  }

  .exercise-filter {
    margin-right: 5px;

    &.open {
      ${FilterTrigger} {
        background-color: #f8f8f8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      }
    }

    .exercise-filter__count {
      right: -10px;
      top: -11px;
      border-radius: 6px;
      width: 22px;
      height: 21px;
      line-height: 17px;
      border: 3px solid #ffffff;
    }
  }
`;

export const CheckboxCellContent = styled.div`
  display: flex;
  align-items: center;
`;

export const WorkoutHeader = styled(TableHeaderCell)`
  width: 135px;
  // text-align: left;

  &:nth-child(1) {
    width: auto !important;
  }
  &:nth-child(2) {
    width: 8.7vw !important;
  }
  &:nth-child(3) {
    width: 6.2vw !important;
  }
  &:nth-child(4) {
    width: 16vw !important;
  }

  &.title {
    width: unset;
    min-width: 400px;
  }

  &.center-align {
    display: flex;
    align-items: center;
    box-shadow: none;
  }

  &.actions {
    width: 95px;
  }

  .checkbox {
    width: 15px;
    margin-right: 15px;

    .evf-checkbox {
      height: 15px;
      width: 15px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 15px;

      .checkbox-icon {
        height: 15px;
        width: 15px;
        background-size: calc(15px / 2);
      }

      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    }
  }
`;

export const RowItemPopup = styled.ol`
  padding-left: 15px;
  margin: 0;

  li {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const SReactTooltip = styled(ReactTooltip)`
  width: 266px;
  padding: 15px !important;
`;

export const TooltipTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
`;

export const TagsButton = styled(AddButton)`
  float: left !important;
  padding: 6px 20px;
  border-color: #d4d7d9 !important;
  border-radius: 5px !important;

  .shared-add-button-wrapper {
    margin: 0;
  }

  .shared-add-button-title {
    font-size: 12px;
    line-height: 12px;
    color: #777;
    font-weight: 600 !important;
    letter-spacing: 0;
    padding-left: 10px;
  }

  &:hover,
  &:active {
    background-color: #f8f8f8 !important;
    cursor: pointer;
  }

  ${props =>
    props.open &&
    css`
      background-color: #f8f8f8 !important;
    `}
`;

export const WrapperActions = styled.div`
  display: flex;
`;
