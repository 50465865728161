import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Arrow, BasicSlideContainer, Item, PlaceholderLoader } from './style';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import ReactPlayer from 'react-player';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import { convertHEICUrlToJPEGUrl, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

function BasicSlider(props) {
  const { className, options, items, width, height, viewingName, cloudfrontList } = props;
  const [showing, setShowing] = useState(props.startAt || 0);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const imageRef = useRef();
  const containerRef = useRef();

  const next = () => {
    if (list && list.length > 1) {
      setIsLoading(true);
      setShowing(showing === items.length - 1 ? 0 : showing + 1);
    }
  };
  const previous = () => {
    if (list && list.length > 1) {
      setIsLoading(true);
      setShowing(showing === 0 ? items.length - 1 : showing - 1);
    }
  };

  useEffect(() => {
    getPresigned();
  }, []);

  useEffect(() => {
    const handler = event => {
      const { keyCode } = event;
      const targetNode = document.querySelector('.detail_post_comment--input :focus-within');

      if (!targetNode) {
        if (keyCode === 39) {
          return next();
        }

        if (keyCode === 37) {
          return previous();
        }
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [showing, list]);

  const getPresigned = useCallback(async () => {
    const originItems = _.cloneDeep(items);
    const listURLs = _.map(originItems, 'src');
    const newData = [];

    for (let i = 0; i < listURLs.length; i++) {
      let cloudItem = convertS3UrlToCloudFrontUrl(listURLs[i], cloudfrontList);
      if (!cloudItem) {
        [cloudItem] = await props.getS3presignedURLFromLocalDatabase([listURLs[i]]);
      }

      // HEIC
      const covertResults = await convertHEICUrlToJPEGUrl([cloudItem]);

      newData.push({
        src: covertResults[0],
        type: originItems[i].type,
        isAttachmentGiphy: !!_.get(originItems[i], 'isAttachmentGiphy'),
      });
    }
    setList(newData);
  }, []);

  const onLoad = event => {
    const { target } = event;

    if (target) {
      const { naturalWidth, naturalHeight } = target;
      const maxWidth = width;
      const maxHeight = containerRef.current.clientHeight;
      let imgWidth = 0,
        imgHeight = 0;

      if (naturalHeight >= maxHeight) {
        const tmpWidth = (maxHeight / naturalHeight) * naturalWidth;
        if (tmpWidth > maxWidth) {
          imgWidth = maxWidth;
          imgHeight = (maxWidth / naturalWidth) * naturalHeight;
        } else {
          imgWidth = tmpWidth;
          imgHeight = maxHeight;
        }
      } else {
        if (naturalWidth <= maxWidth) {
          imgWidth = naturalWidth;
          imgHeight = naturalHeight;
        } else {
          imgWidth = maxWidth;
          imgHeight = (maxWidth / naturalWidth) * naturalHeight;
        }
      }

      const currentContainerWidth = containerRef.current.clientWidth;

      if (!currentContainerWidth) {
        containerRef.current.style.width = `${imgWidth > maxHeight ? width : maxHeight}px`;
      } else {
        if (imgWidth > currentContainerWidth) {
          containerRef.current.style.width = maxWidth + 'px';
        }
      }

      imageRef.current.style.width = imgWidth + 'px';
      imageRef.current.style.height = imgHeight + 'px';
    }
    setIsLoading(false);
  };

  const Loading = () => {
    return (
      <PlaceholderLoader>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </PlaceholderLoader>
    );
  };

  return (
    <BasicSlideContainer
      {...options}
      className={`slide slide--basic ${className ? className : ''}`}
      height={height}
      width={width}
      onClick={next}
      ref={containerRef}
    >
      {list && list.length > 0 && (
        <Item className="slide__item">
          {viewingName !== 'forum' ? (
            <>
              {isLoading && <Loading />}
              <img
                ref={imageRef}
                src={list[showing].src}
                onLoad={event => {
                  event.persist();
                  onLoad(event);
                }}
                alt=""
              />
            </>
          ) : (
            <>
              {!list[showing].isAttachmentGiphy && list[showing].type === 'image' && (
                <>
                  {isLoading && <Loading />}
                  <img
                    ref={imageRef}
                    src={list[showing].src}
                    onLoad={event => {
                      event.persist();
                      onLoad(event);
                    }}
                    alt=""
                  />
                </>
              )}
              {list[showing].isAttachmentGiphy && (
                <GiphyWatermark maxSize={false} bgLoaderTransparent={true}>
                  <div>
                    <img
                      ref={imageRef}
                      src={list[showing].src}
                      onLoad={event => {
                        event.persist();
                        onLoad(event);
                      }}
                      alt=""
                    />
                  </div>
                </GiphyWatermark>
              )}
              {list[showing].type === 'video' && (
                <ReactPlayer
                  width="100%"
                  height="100%"
                  playing
                  url={list[showing].src}
                  controls={true}
                  onLoad={event => {
                    event.persist();
                    onLoad(event);
                  }}
                  onClick={e => e.stopPropagation()}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              )}
            </>
          )}
        </Item>
      )}
      {list && list.length > 1 && (
        <>
          <Arrow
            className={`slide__arrow prev ${list && list[showing] && list[showing].type === 'video' ? 'is-video' : ''}`}
            onClick={event => {
              event.stopPropagation();
              previous();
            }}
          >
            <i />
          </Arrow>
          <Arrow
            className={`slide__arrow next ${list && list[showing] && list[showing].type === 'video' ? 'is-video' : ''}`}
          >
            <i />
          </Arrow>
        </>
      )}
    </BasicSlideContainer>
  );
}

BasicSlider.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  items: PropTypes.array.isRequired,
  startAt: PropTypes.number,
  viewingName: PropTypes.string,
  options: PropTypes.shape({
    style: PropTypes.object,
  }),
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState, { getS3presignedURLFromLocalDatabase })(BasicSlider);
