import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';

import {
  WorkoutWrapper,
  WorkoutContainer,
  WorkoutHeader,
  WorkoutSectionsContainer,
  WorkoutFooter,
  SectionContainer,
  ViewMoreItem,
} from './style';
import { maximumRenderedExercises } from 'helpers/workout';
import SectionOverview from 'components/SectionOverview';
import { Workout, ExerciseSet } from 'types/model';
import ExerciseDetail from 'components/ExerciseDetail';
import { counterBehindExercises, pluralize } from 'utils/commonFunction';
import Dropdown, { Option, Trigger } from 'shared/Dropdown/Basic';
import { deleteExerciseSet, updateSetNumberInSection } from 'helpers/workout';
import { CDN_URL, SECTION_FORMAT_KEY } from 'constants/commonData';
import ConfirmModal from 'shared/ConfirmModal';
import { connect } from 'react-redux';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  resetSelectedWeek,
  resetSelectedWorkouts,
  resetAutoflowIntervalCopyItem,
} from 'redux/autoflowInterval/training/actions';
import { Checkbox } from 'shared/FormControl';
import { Icon } from 'semantic-ui-react';
import { SharedTooltip } from 'shared/SharedTooltip';

export const DRAGGALE_TYPE = {
  WORKOUT: 'workout',
  SECTION: 'section',
};

const LIMIT_SELECTED_WORKOUTS = 20;
class WorkoutDraggable extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  getMaximumRenderedExercises = () => {
    const { cellContentHeight, currentWorkout, totalWorkouts } = this.props;
    const workoutHeaderHeight = 24;
    const workoutFooterHeight = 22;
    const workoutCompressedHeight = 52;
    let factor = 2; // border

    factor += workoutHeaderHeight + workoutFooterHeight;
    factor += (totalWorkouts - 1) * workoutCompressedHeight;

    const containerHeight = cellContentHeight - factor;
    return maximumRenderedExercises(currentWorkout, containerHeight, 74, 52);
  };

  onSaveExercise = data => {
    const { currentWorkout } = this.props;
    const { exercise, sectionIndex, setIndex, exIndex } = data;
    const newWorkout = _.cloneDeep(currentWorkout);
    let section = newWorkout.sections[sectionIndex];

    if (!section) {
      return console.error('Cannot find section with index = ' + sectionIndex);
    }

    if (section.exercises[setIndex]) {
      section.exercises[setIndex].supersets[exIndex] = exercise;
    }

    updateSetNumberInSection(newWorkout.sections[sectionIndex], setIndex, exIndex);
    this.props.onUpdateSection(newWorkout);
  };

  onDeleteExercise = (sectionIndex, setIndex, exIndex) => {
    const { currentWorkout } = this.props;
    const copyWorkout = _.cloneDeep(currentWorkout);
    deleteExerciseSet(copyWorkout, sectionIndex, setIndex, exIndex);
    this.props.onUpdateSection(copyWorkout);
  };

  renderSuperset = (section, totalRendered, sectionIndex) => {
    const { dayIndex, weekIndex, currentWorkout, cellId, disabled } = this.props;
    const views = [];
    const set = section.exercises ? section.exercises[0] : { supersets: [] };

    set.supersets.map((exSet, exerciseIndex) => {
      if (exerciseIndex < totalRendered) {
        const currentSet = _.cloneDeep(exSet);
        const popupId = `${cellId}_${currentWorkout._id}_${section._id}_${exerciseIndex}`;
        views.push(
          <div key={exerciseIndex}>
            <ExerciseDetail
              exerciseSet={new ExerciseSet(currentSet)}
              assignment={new Workout(currentWorkout)}
              pId={popupId}
              exIndex={exerciseIndex}
              day={dayIndex}
              week={weekIndex}
              setIndex={0}
              sectionIndex={sectionIndex}
              mode="program_library_workout_popup"
              onClone={exercise => this.props.onAddSection({ assignment: currentWorkout, exercise })}
              onSave={data => this.onSaveExercise({ ...data, sectionIndex, exIndex: exerciseIndex, setIndex: 0 })}
              onDelete={() => this.onDeleteExercise(sectionIndex, 0, exerciseIndex)}
              disabled={disabled}
            />
            {exerciseIndex < totalRendered - 1 ? (
              <div style={{ backgroundColor: 'gainsboro', height: 1, margin: '0 8px' }} />
            ) : null}
          </div>,
        );
      }
    });

    return views;
  };

  renderSections = maxSectionsRendered => {
    const { currentWorkout, onlyShowOverview, isShowAllWorkouts, draggingWorkout, cellId, disabled } = this.props;
    const { sections } = currentWorkout;
    const views = [];
    const isRenderSections = !draggingWorkout && (!onlyShowOverview || isShowAllWorkouts);

    if (isRenderSections) {
      let totalRendered = 0;

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        let numOfExercises = _.sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
        if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
          numOfExercises = section.exercise_references.length || 1;
        }
        const rendered = Math.min(numOfExercises, maxSectionsRendered - totalRendered);

        if (!rendered) {
          break;
        } else {
          totalRendered += rendered;
          const sectionDraggableId = `section_${cellId}_${currentWorkout._id}_${section._id}`;
          views.push(
            <Draggable
              key={sectionDraggableId}
              draggableId={sectionDraggableId}
              index={i}
              type={DRAGGALE_TYPE.SECTION}
              isDragDisabled={disabled}
            >
              {(provided, snapshot) => (
                <SectionContainer
                  className={classnames('workout__section', {
                    'workout__section--hidden': section.type === 'hidden',
                    'workout__section--dragging': snapshot.isDragging,
                  })}
                  style={{ ...provided.draggableProps.style }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {section.type === 'hidden' ? (
                    this.renderSuperset(section, rendered, i)
                  ) : (
                    <SectionOverview
                      key={section._id}
                      section={section}
                      onClick={() => this.props.viewWorkoutDetail(currentWorkout, section._id)}
                    />
                  )}
                </SectionContainer>
              )}
            </Draggable>,
          );
        }
      }
    }

    return <WorkoutSectionsContainer className="workout__sections">{views}</WorkoutSectionsContainer>;
  };

  saveWorkoutToLibrary = () => {
    this.props.saveWorkoutToLibrary(this.props.currentWorkout);
  };

  copyCurrentWorkout = () => {
    const { copyWorkout, currentWorkout, selectedWorkouts, selectedWeek, copyingWorkouts } = this.props;
    !_.isEmpty(selectedWeek) && resetSelectedWeek();
    (!_.isEmpty(selectedWorkouts) || copyingWorkouts) && resetSelectedWorkouts();

    copyWorkout && copyWorkout(currentWorkout);
  };

  handleSelectedWorkout = currentWorkout => {
    const {
      onSelectWorkout,
      selectedWorkouts,
      selectedWeek,
      resetSelectedWeek,
      toggleConfirmModal,
      resetAutoflowIntervalCopyItem,
      copying,
    } = this.props;

    const currentWorkoutId = _.get(currentWorkout, '_id', '');

    const isChecked = selectedWorkouts.includes(currentWorkoutId);

    const lengthWorkout = selectedWorkouts.length;
    if (!_.isEmpty(selectedWeek)) {
      resetSelectedWeek && resetSelectedWeek();
    }

    if (copying) {
      resetAutoflowIntervalCopyItem && resetAutoflowIntervalCopyItem();
    }

    if (lengthWorkout >= LIMIT_SELECTED_WORKOUTS && !isChecked) {
      toggleConfirmModal &&
        toggleConfirmModal(
          true,
          <ConfirmModal
            headerIcon={`${CDN_URL}/images/warning_purple.svg`}
            title={`Maximum of ${lengthWorkout} workouts selected`}
            content={`You can select up to ${lengthWorkout} workouts at a time. Please complete your action and then select more.`}
            onConfirm={() => {}}
            onDeny={() => {}}
            confirmButtonTitle="Okay"
            hideCancelButton
            noBorder={true}
            hasCloseIcon={true}
            className="calendar--max-select"
          />,
        );
    } else {
      onSelectWorkout(currentWorkoutId);
    }
  };

  deleteCurrentWorkout = () => {
    this.props.deleteWorkout(this.props.currentWorkout);
  };

  moveWorkoutUp = () => {
    const { currentWorkout, workoutIndex } = this.props;
    this.props.arrangeWorkoutInDay(currentWorkout, workoutIndex - 1);
  };

  moveWorkoutDown = () => {
    const { currentWorkout, workoutIndex } = this.props;
    this.props.arrangeWorkoutInDay(currentWorkout, workoutIndex + 1);
  };

  renderTriggerIcon = ({ open }) => {
    const { currentWorkout } = this.props;
    return (
      <>
        <Trigger data-tip data-for={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />
        {!open && <SharedTooltip id={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />}
      </>
    );
  };

  renderActions = () => {
    const { workoutIndex, totalWorkouts, disabled } = this.props;

    if (disabled) {
      return (
        <Dropdown triggerIcon={this.renderTriggerIcon}>
          <Option onClick={this.saveWorkoutToLibrary} key="save">
            <img src={`${CDN_URL}/images/save.svg`} alt="" />
            <span>Save to library</span>
          </Option>
        </Dropdown>
      );
    }

    return (
      <Dropdown triggerIcon={this.renderTriggerIcon}>
        {totalWorkouts > 1 && workoutIndex > 0 ? (
          <Option onClick={this.moveWorkoutUp} key="move-up">
            <img src={`${CDN_URL}/images/workout_up.svg`} alt="" />
            <span>Move up</span>
          </Option>
        ) : null}
        {totalWorkouts > 1 && workoutIndex < totalWorkouts - 1 ? (
          <Option onClick={this.moveWorkoutDown} key="move-down">
            <img src={`${CDN_URL}/images/workout_down.svg`} alt="" />
            <span>Move down</span>
          </Option>
        ) : null}
        <Option onClick={this.saveWorkoutToLibrary} key="save">
          <img src={`${CDN_URL}/images/save.svg`} alt="" />
          <span>Save to library</span>
        </Option>
        <Option onClick={this.copyCurrentWorkout} key="copy">
          <img src={`${CDN_URL}/images/duplicate.svg`} alt="" />
          <span>Copy</span>
        </Option>
        <Option onClick={this.deleteCurrentWorkout} key="delete">
          <img src={`${CDN_URL}/images/delete.svg`} alt="" />
          <span>Delete</span>
        </Option>
      </Dropdown>
    );
  };

  render() {
    const {
      currentWorkout,
      selectedWorkouts,
      workoutIndex,
      cellId,
      isShowAllWorkouts,
      onlyShowOverview,
      disabled,
      permission,
    } = this.props;
    const dndId = `${cellId}_${currentWorkout._id}`;
    const workoutDraggableId = `workout_${dndId}`;
    const maxSectionsRendered = isShowAllWorkouts ? 1000 : onlyShowOverview ? 0 : this.getMaximumRenderedExercises();

    const remainExercises = counterBehindExercises(currentWorkout, maxSectionsRendered);
    const isChecked = selectedWorkouts.includes(_.get(currentWorkout, '_id', ''));

    return (
      <Draggable
        key={workoutDraggableId}
        draggableId={workoutDraggableId}
        type={DRAGGALE_TYPE.WORKOUT}
        index={workoutIndex}
        isDragDisabled={disabled}
      >
        {provided => (
          <WorkoutWrapper
            className={classnames('workoutCompactViewWrapper workout-draggable-container', {
              disableDraggale: !!disabled,
            })}
            style={{ ...provided.draggableProps.style, margin: '0 5px' }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Droppable droppableId={dndId} type={DRAGGALE_TYPE.SECTION} isDropDisabled={disabled}>
              {(provided, snapshot) => {
                const dragOver = snapshot.isDraggingOver ? { background: '#f3f5f8' } : {};

                return (
                  <WorkoutContainer
                    ref={provided.innerRef}
                    className="workoutCompactViewContainer workout-droppage-container"
                    style={{ ...dragOver }}
                    checked={isChecked}
                    hasSelectedWorkout={!_.isEmpty(selectedWorkouts)}
                  >
                    <WorkoutHeader className="workout__header">
                      <div
                        className="workout__title"
                        onClick={() => this.props.viewWorkoutDetail(currentWorkout)}
                        data-tip
                        data-for={`tooltip--${dndId}`}
                      >
                        {currentWorkout.title}
                      </div>
                      {process.env.REACT_APP_COPY_WEEK_ENABLE_V2 && _.get(permission, 'copy_week') && (
                        <Checkbox
                          className="workout__header--checkbox"
                          checked={isChecked}
                          onChange={event => {
                            event.stopPropagation();
                            this.handleSelectedWorkout(currentWorkout, event.target.checked);
                          }}
                        />
                      )}
                      <ReactTooltip className="app-tooltip" id={`tooltip--${dndId}`} effect="solid" place={'top'}>
                        <span>{currentWorkout.title || ''}</span>
                      </ReactTooltip>
                      {this.renderActions()}
                    </WorkoutHeader>
                    {this.renderSections(maxSectionsRendered)}
                    <WorkoutFooter className="workout__footer">
                      {remainExercises > 0 ? (
                        <ViewMoreItem className="workout__view-more-exercises" onClick={this.props.viewAllData}>
                          {pluralize(`+ ${remainExercises} more exercise`, remainExercises)}
                        </ViewMoreItem>
                      ) : (
                        <div />
                      )}
                      {!disabled ? (
                        <ExerciseDetail
                          mode={'new_popup'}
                          assignment={currentWorkout}
                          pId={`${cellId}_${currentWorkout._id}_new-exercise`}
                          onSave={this.props.onAddSection}
                        />
                      ) : null}
                    </WorkoutFooter>
                    {provided.placeholder}
                  </WorkoutContainer>
                );
              }}
            </Droppable>
          </WorkoutWrapper>
        )}
      </Draggable>
    );
  }
}

const mapState = (state, ownProps) => {
  const {
    rootReducer: {
      autoflowInterval: { training },
      permission,
    },
  } = state;

  const { selectedWorkouts = [], selectedWeek = [], copying, copyingWorkouts } = training.toJS();
  return {
    selectedWorkouts,
    selectedWeek,
    copying,
    copyingWorkouts,
    permission,
  };
};

const actionCreators = {
  toggleModal,
  toggleConfirmModal,
  resetSelectedWeek,
  resetSelectedWorkouts,
  resetAutoflowIntervalCopyItem,
};

export default connect(mapState, actionCreators)(WorkoutDraggable);
