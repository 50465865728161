import styled from 'styled-components';

export const ActionButton = styled.button`
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: #5c5bbd;
  font-family: 'Open Sans';
  margin-top: 10px;

  outline: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  border: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const PanelHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
`;

export const PanelContent = styled.div`
  padding: 0 30px;
  flex: 1 1;
`;

export const PanelItem = styled.div``;

export const PanelFooter = styled.div`
  padding: 0 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Panel = styled.div`
  min-height: 228px;
  background: #ffffff;
  border: 1px solid #ebedf4;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  color: #202353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const Wrapper = styled.div`
  padding: 36px 30px;
  width: 100%;
  overflow: auto;

  .highlight {
    color: #5c5bbd;
  }

  .sBold {
    font-weight: 600;
  }
`;
