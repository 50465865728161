import React from 'react';
import { get } from 'lodash';

import { ReactComponent as StudioGrayIcon } from 'assets/icons/studio-program-gray-icon.svg';
import UpgradePath from 'shared/UpgradePath';

import AssetCard from './AssetCard';
import { handleCheckWeek } from '../../../helper';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const defaultImage = `${CDN_URL}/images/studio_program_default_cover_image.png`;

export default function StudioAsset({ data, onDeleteAsset, isEdit, cloudfrontList }) {
  const background = get(data, 'asset_data.cover_image', null);
  const disabled = {
    isDisabled: data.is_deleted || !data.asset_data.isPublished,
    disabledText: data.is_deleted ? 'deleted' : 'draft',
  };

  return (
    <UpgradePath
      pathName="studio_program"
      fallback={
        <AssetCard
          icon={<StudioGrayIcon />}
          title={data.asset_data.program_name}
          asset_type={data.asset_type}
          onDeleteAsset={onDeleteAsset}
          isEdit={isEdit}
          disabled={true}
          disabledText={'inactive'}
        >
          <span className="badge">Studio Program</span>
          <span className="info-text">{handleCheckWeek(data.asset_data.number_of_weeks)}</span>
        </AssetCard>
      }
    >
      <AssetCard
        icon={
          disabled.isDisabled ? (
            <StudioGrayIcon />
          ) : (
            <img
              src={background ? convertS3UrlToCloudFrontUrl(background, cloudfrontList, true) : defaultImage}
              alt="cover"
            />
          )
        }
        title={data.asset_data.program_name}
        asset_type={data.asset_type}
        onDeleteAsset={onDeleteAsset}
        isEdit={isEdit}
        disabled={disabled.isDisabled}
        disabledText={disabled.disabledText}
      >
        <span className="badge">Studio Program</span>
        <span className="info-text">{handleCheckWeek(data.asset_data.number_of_weeks)}</span>
      </AssetCard>
    </UpgradePath>
  );
}
