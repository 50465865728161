import { Types } from './actions';
import _ from 'lodash';

const INITITAL_STATE = {
  tasks: [],
  copying: null,
  isMoving: false,
};

export default (state = INITITAL_STATE, action) => {
  const { payload, type } = action;
  const { tasks } = state;
  let newTasks = tasks.slice(),
    index;

  switch (type) {
    case Types.AUTOFLOW_SUCCESS_GET_TASKS:
      return Object.assign({}, state, { tasks: payload.data });

    case Types.AUTOFLOW_TASK_RESET_DATA:
      return INITITAL_STATE;

    case Types.AUTOFLOW_SUCCESS_ASSIGN_TASK:
      index = _.findIndex(newTasks, item => item.day === payload.data.day);

      if (index !== -1) {
        newTasks[index].tasks = payload.data.tasks;
      } else {
        newTasks.push(payload.data);
      }

      return Object.assign({}, state, { tasks: newTasks });

    case Types.AUTOFLOW_MOVE_TASK:
      return handleMoveTask(payload.data, state);

    case Types.AUTOFLOW_REQUEST_MOVE_TASK:
      return Object.assign({}, state, { isMoving: true });

    case Types.AUTOFLOW_SUCCESS_MOVE_TASK:
      _.forEach(payload.data, item => {
        const findIndex = _.findIndex(newTasks, d => d.day === item.day);

        if (findIndex !== -1) {
          newTasks[findIndex] = { ...newTasks[findIndex], tasks: item.tasks, _id: item._id };
        } else {
          newTasks.push(item);
        }
      });

      return Object.assign({}, state, { tasks: newTasks, isMoving: false });

    case Types.AUTOFLOW_FAILED_MOVE_TASK:
      return Object.assign({}, state, { isMoving: false });

    case Types.AUTOFLOW_SUCCESS_ARRANGE_TASK:
      index = _.findIndex(newTasks, item => item.day === payload.data.day);

      if (index !== -1) {
        newTasks[index].tasks = payload.data.tasks;
      }

      return Object.assign({}, state, { tasks: newTasks });

    case Types.AUTOFLOW_COPY_TASK:
      return Object.assign({}, state, { copying: payload.data });

    case Types.AUTOFLOW_TASK_CLEAR_COPY_ITEM:
      return Object.assign({}, state, { copying: null });

    case Types.AUTOFLOW_SUCCESS_DELETE_TASK:
      return Object.assign({}, state, { tasks: newTasks });

    case Types.AUTOFLOW_CREATED_TASK_SUCCESS: {
      const { autoflow, day } = payload.data;
      const tasks = _.map(newTasks, dayOfWeek => {
        if (dayOfWeek.autoflow === autoflow && dayOfWeek.day === day) {
          return {
            ...dayOfWeek,
            tasks: _.uniqBy([...dayOfWeek.tasks, payload.data], '_id'),
          };
        }
        return dayOfWeek;
      });
      return Object.assign({}, state, { tasks });
    }

    case Types.AUTOFLOW_REMOVED_TASK_SUCCESS: {
      const { data } = payload;
      const ids = _.map(data, it => it._id);
      const tasks = _.map(newTasks, dayOfWeek => {
        return {
          ...dayOfWeek,
          tasks: dayOfWeek.tasks.filter(item => !_.includes(ids, item._id)),
        };
      });
      return Object.assign({}, state, { tasks });
    }

    case Types.AUTOFLOW_SUCCESS_REMOVE_SETTING_REPEAT: {
      const {
        data: { repeatId },
      } = payload;
      const results = _.map(newTasks, day => {
        const { tasks } = day;
        if (tasks) {
          day.tasks = tasks.filter(
            item =>
              !item.repeat_task_setting_id ||
              (item.repeat_task_setting_id && item.repeat_task_setting_id._id !== repeatId),
          );
        }
        return day;
      });

      return Object.assign({}, state, { tasks: results });
    }

    case Types.AUTOFLOW_TASK_SUCCESS_ARRANGE_TASK:
      index = _.findIndex(newTasks, item => item.day === payload.day);

      if (index !== -1) {
        newTasks[index] = payload.data;
      }

      return Object.assign({}, state, { tasks: newTasks });

    default:
      return state;
  }
};

const handleMoveTask = (params, oldState) => {
  const tasks = oldState.tasks;
  const { newIndex, autoflow, newDay, taskId, dayTaskId } = params;
  const fromDayTaskIndex = _.findIndex(oldState.tasks, o => o._id === dayTaskId);
  const toDayTaskIndex = _.findIndex(oldState.tasks, o => o.day === newDay);
  const fromIndex = tasks[fromDayTaskIndex].tasks.findIndex(t => t._id === taskId);
  const taskMoving = tasks[fromDayTaskIndex].tasks.find(t => t._id === taskId);
  taskMoving.day = newDay;
  tasks[fromDayTaskIndex].tasks.splice(fromIndex, 1);

  if (toDayTaskIndex !== -1) {
    tasks[toDayTaskIndex].tasks.splice(newIndex, 0, taskMoving);
  } else {
    tasks.push({
      autoflow: autoflow,
      day: newDay,
      tasks: [taskMoving],
      _id: taskMoving._id,
    });
  }
  return Object.assign({}, oldState, { tasks });
};
