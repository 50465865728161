import React from 'react';

import classNames from 'classnames';
import SectionMobilePreview from './SectionMobilePreview';
import PreviewLargeCard from './PreviewLargeCard';
import { FormatLayout } from '../../constants';
import PreviewSquares from './PreviewSquares';
import PreviewNarrowCard from './PreviewNarrowCard';

import { ReactComponent as BackIcon } from 'assets/icons/right_arrow_grey.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search_icon.svg';

import * as S from './style';

const MAX_LIMIT = 20;

function MobilePreviewCollection({ name = '', workingCollection = [] }) {
  const RenderView = () => {
    return workingCollection.map((section, index) => {
      switch (section.format) {
        case FormatLayout.LAGER:
          return <PreviewLargeCard section={section} />;
        case FormatLayout.SQUARE:
          return <PreviewSquares section={section} />;
        case FormatLayout.NARROW:
          return <PreviewNarrowCard section={section} />;
        default:
          return <SectionMobilePreview section={section} />;
      }
    });
  };

  return (
    <S.Wrapper>
      <S.MobilePreviewContainer>
        <S.StatusBar />
        <S.MobilePreviewTop>
          <div className="backButton">
            <BackIcon />
          </div>
          <div className="searchButton">
            <SearchIcon />
          </div>
        </S.MobilePreviewTop>
        <div className={'mobilePreview__heading'}>{name}</div>
        <div className="mobilePreview__menu">
          <span className="item active">For you</span>
          <span className="item">Categories</span>
          <span className="item">About</span>
        </div>
        <div className={classNames('mobilePreview__sections', { overLimit: name.length > MAX_LIMIT })}>
          <RenderView />
        </div>
      </S.MobilePreviewContainer>
    </S.Wrapper>
  );
}

export default MobilePreviewCollection;
