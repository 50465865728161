import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Modal, Button, Icon, Image, Input } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import GeneralButton from 'shared/GeneralButton';
import { NewSearchInput } from 'shared/SearchInput';
import ContentEditable from 'shared/ContentEditable';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as Plus } from 'assets/icons/new_plus_grey_sm.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_bold_circle.svg';
import './style.scss';
import { CDN_URL, TAGS_TYPE } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';

const LIMIT_NAME = 19;

function ManageTags({
  user,
  type,
  isModalOpen,
  exerciseTags,
  workoutTags,
  programTags,
  toggleModal,
  toggleConfirmModal,
  changeQueryParams,
  resetQueryParams,
  getTagsList,
  createNewTag,
  editTag,
  deleteTag,
  updateTagInExercises,
  deleteTagInExercises,
  updateTagInWorkouts,
  deleteTagInWorkouts,
  updateTagInPrograms,
  deleteTagInPrograms,
  onCloseModal,
}) {
  const [newTag, setNewTag] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef();
  const observer = useRef();
  const requestResetValue = useRef();
  const { title, loading, query } = useMemo(() => {
    switch (type) {
      case TAGS_TYPE.EXERCISE:
        return { title: 'Manage Exercise Tags', loading: exerciseTags.loading, query: exerciseTags.query };

      case TAGS_TYPE.WORKOUT:
        return { title: 'Manage Workout Tags', loading: workoutTags.loading, query: workoutTags.query };

      case TAGS_TYPE.PROGRAM:
        return { title: 'Manage Program Tags', loading: programTags.loading, query: programTags.query };

      default:
        return { title: '', loading: false, query: { isEnd: true } };
    }
  }, [exerciseTags, workoutTags, programTags]);
  const lastExerciseElRef = useCallback(
    node => {
      if (isLoading || query.isEnd) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !query.isEnd) {
          // Last element is in view
          changeQueryParams({
            type,
            page: query.page + 1,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, query.isEnd],
  );

  useEffect(() => {
    resetQueryParams(type);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getTagsList({
      page: query.page,
      sort: query.sort,
      text_search: query.textSearch,
      type,
    }).then(() => setIsLoading(false));
  }, [query.page, query.sort, query.textSearch]);

  const onClose = () => {
    toggleModal(false);
    resetQueryParams(type);

    typeof onCloseModal === 'function' && onCloseModal();
  };

  const onChangeTagName = (tag, value) => {
    const name = value.trim();

    if (!name) {
      return toast.error('Please input tag name');
    }

    editTag(tag._id, { name, type }, () => requestResetValue && requestResetValue.current()).then(() => {
      switch (type) {
        case TAGS_TYPE.EXERCISE:
          updateTagInExercises(tag._id, name);
          break;
        case TAGS_TYPE.WORKOUT:
          updateTagInWorkouts(tag._id, name);
          break;
        case TAGS_TYPE.PROGRAM: {
          updateTagInPrograms(tag._id, name);
          break;
        }

        default:
          break;
      }
    });
  };

  const onDeleteTag = tag => {
    let total;
    let text;

    switch (type) {
      case TAGS_TYPE.EXERCISE: {
        total = tag.total_exercises;
        text = 'exercise';
        break;
      }
      case TAGS_TYPE.WORKOUT: {
        total = tag.total_workouts;
        text = 'workout';
        break;
      }
      case TAGS_TYPE.PROGRAM: {
        total = tag.total_programs || 0;
        text = 'program';
        break;
      }
      default:
        break;
    }

    let contentConfirm = `Are you sure you want to delete the tag ‘${
      tag.name
    }’ ? This tag will be removed from ${total} ${pluralize(text, total)}.
    `;

    if (type === TAGS_TYPE.PROGRAM) {
      contentConfirm = `Are you sure you want to delete the tag "${tag.name}"? This tag will be removed from any program that it's currently in.`;
    }

    toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Delete Tags?'}
        content={contentConfirm}
        headerIcon={`${CDN_URL}/images/trash_bg_red.svg`}
        noBorder
        hasCloseIcon
        hasHoverState
        className="confirm-delete-tag"
        onConfirm={() => {
          deleteTag(tag._id, type).then(() => {
            switch (type) {
              case TAGS_TYPE.EXERCISE:
                deleteTagInExercises(tag._id);
                break;
              case TAGS_TYPE.WORKOUT:
                deleteTagInWorkouts(tag._id);
                break;
              case TAGS_TYPE.PROGRAM: {
                deleteTagInPrograms(tag._id);
                break;
              }

              default:
                break;
            }
          });
        }}
        onDeny={() => toggleConfirmModal(false)}
        confirmButtonTitle="Delete"
        cancelButtonTitle="Cancel"
      />,
    );
  };

  const onSortTags = () => {
    changeQueryParams({ type, page: 1, sort: query.sort * -1 });
  };

  const onSearchTags = (event, { value }) => {
    changeQueryParams({ type, page: 1, textSearch: value });
  };

  const renderList = () => {
    let list = [];
    switch (type) {
      case TAGS_TYPE.EXERCISE:
        list = exerciseTags.list;
        break;
      case TAGS_TYPE.WORKOUT:
        list = workoutTags.list;
        break;
      case TAGS_TYPE.PROGRAM: {
        list = programTags.list;
        break;
      }

      default:
        break;
    }

    return (
      <div className="tags-container">
        <div className="tags-header">
          <div className="name-column" onClick={onSortTags}>
            <span>Tag Name</span>
            <Icon name={`chevron ${query.sort < 0 ? 'down' : 'up'}`} />
          </div>
          <span>Action</span>
        </div>
        <div className="tags-body">
          {!loading && list.length < 1 ? (
            <div className="no-results-text">No results found</div>
          ) : (
            _.map(list, (item, index) => {
              const disableDelete = !item.is_possible_to_delete;
              const editable = user._id === item.author;
              if (list.length === index + 1) {
                return (
                  <div className="tags-item" key={item._id} ref={lastExerciseElRef}>
                    <ContentEditable
                      required={true}
                      value={item.name}
                      onChange={e => onChangeTagName(item, e.value)}
                      maxLength={LIMIT_NAME}
                      editable={editable}
                      requestResetValue={requestResetValue}
                    />
                    <div
                      className={classNames('delete-tag', disableDelete && 'disabled', loading && 'loading')}
                      data-tip
                      data-for={`tag-disable-delete-${item._id}`}
                      onClick={() => {
                        if (!disableDelete && !loading) {
                          onDeleteTag(item);
                        }
                      }}
                    >
                      Delete
                    </div>
                    {disableDelete && (
                      <ReactTooltip
                        id={`tag-disable-delete-${item._id}`}
                        className="app-tooltip delete-tag-tooltip"
                        effect="solid"
                        place="top"
                        delayShow="200"
                      >
                        <span>This tag is being used by other, you can not delete this.</span>
                      </ReactTooltip>
                    )}
                  </div>
                );
              }
              return (
                <div className="tags-item" key={item._id}>
                  <ContentEditable
                    required={true}
                    value={item.name}
                    onChange={e => onChangeTagName(item, e.value)}
                    maxLength={LIMIT_NAME}
                    editable={editable}
                    requestResetValue={requestResetValue}
                  />
                  <div
                    className={classNames('delete-tag', disableDelete && 'disabled', loading && 'loading')}
                    data-tip
                    data-for={`tag-disable-delete-${item._id}`}
                    onClick={() => {
                      if (!disableDelete && !loading) {
                        onDeleteTag(item);
                      }
                    }}
                  >
                    Delete
                  </div>
                  {disableDelete && (
                    <ReactTooltip
                      id={`tag-disable-delete-${item._id}`}
                      className="app-tooltip delete-tag-tooltip"
                      effect="solid"
                      place="top"
                      delayShow="200"
                    >
                      <span>This tag is being used by other, you can not delete this.</span>
                    </ReactTooltip>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  const handleCreateNewTag = event => {
    event.preventDefault();

    if (!newTag) {
      return null;
    }

    if (!newTag.name || !newTag.name.trim()) {
      setError(true);
      return toast.error('Please input tag name.');
    }

    createNewTag({
      type,
      name: newTag.name,
    }).then(() => setNewTag(null));
  };

  const handleReset = () => {
    setError(false);
    setNewTag(null);
  };

  const handleChange = (evt, data) => {
    setError(false);
    setNewTag({
      ...newTag,
      name: data.value,
    });
  };

  const renderAddNewTag = () => {
    if (!newTag) {
      return <div className="overlay-manage-tags" />;
    }

    return (
      <form className="create-new-tag" onSubmit={handleCreateNewTag}>
        <div className="input-tag">
          <label>Tag name</label>
          <Input
            maxLength={20}
            ref={inputRef}
            value={newTag.name}
            placeholder="Enter Tag Name"
            onChange={handleChange}
            error={error}
            autoFocus={true}
          />
        </div>
        <Button type="submit" className="submit">
          Create
        </Button>
        <Button onClick={handleReset} className="close-button" type="button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      </form>
    );
  };

  return (
    <Modal open={isModalOpen} className="manage-tag-modal" closeOnDimmerClick={false} onClose={onClose}>
      <Modal.Header>
        <div className="title">
          <span>{title}</span>
          <NewSearchInput
            placeholder="Search"
            onChange={_.debounce(onSearchTags, 300)}
            onClearSearch={() => resetQueryParams(type)}
            defaultValue={query.textSearch}
          />
        </div>
        <Button onClick={onClose} className="close-button" type="button">
          <CloseIcon />
        </Button>
      </Modal.Header>
      <Modal.Content>
        {renderList()}
        {renderAddNewTag()}
      </Modal.Content>
      <Modal.Actions>
        <GeneralButton
          onClick={() =>
            setNewTag({}, () => {
              setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }, 0);
            })
          }
          className="create-button"
          withImage
        >
          <Plus />
          <span>Create New Tag</span>
        </GeneralButton>
        <GeneralButton className="done-button" onClick={onClose} disabled={!!newTag}>
          Done
        </GeneralButton>
      </Modal.Actions>
    </Modal>
  );
}

export default ManageTags;
