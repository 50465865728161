import get from 'lodash/get';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { getPackage, resetPackage } from 'redux/package-detail/actions';
import { getConnectedAccount } from 'redux/setup-payment/actions';
import { countProducts } from 'redux/product/actions';
import PackageOverview from './Overview';
import PackageAnalytics from './Analytics';
import PackageSettings from './Settings';
import WaitList from './WaitList';
import ContactList from './ContactList';

function PackageDetail(props) {
  const {
    match: { params },
    isMP,
    location,
  } = props;
  const path = isMP ? 'marketplace' : 'packages';
  const search = get(location, 'search', '');
  useEffect(() => {
    // get package data
    if (params.packageId && params.packageId !== 'create' && !search.includes('redirect-platform')) {
      props.getPackage(params.packageId, isMP);
    }
    props.getConnectedAccount();
    props.countProducts();
    return () => {
      props.resetPackage();
    };
  }, [search]);

  const data = useMemo(() => (props.workingPackage ? props.workingPackage.toJS() : null), [props.workingPackage]);

  if (!data) {
    return 'No data';
  }

  return (
    <Switch>
      <Route exact path={`/home/${path}/:packageId?`} component={PackageOverview} />
      <Route exact path={`/home/${path}/:packageId/analytics`} component={PackageAnalytics} />
      <Route exact path={`/home/${path}/:packageId/waitlist`} component={WaitList} />
      <Route exact path={`/home/${path}/:packageId/intro-call`} component={ContactList} />
      <Route exact path={`/home/${path}/:packageId/settings`} component={PackageSettings} />
    </Switch>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageDetail },
    router: { location },
  } = state;

  return { workingPackage: packageDetail.get('workingPackage'), location };
};

const actionCreators = { getPackage, resetPackage, getConnectedAccount, countProducts };

export default connect(mapState, actionCreators)(PackageDetail);
