import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { OptionIcon } from '../../../styles';
import { ENTITY_NAME } from 'database/constants';
import { SECTION_FORMAT_KEY } from 'constants/commonData';

function MoveToSection(props) {
  const section = props.section.toJS();
  return (
    <OptionIcon
      shouldHide={section.format === SECTION_FORMAT_KEY.FREESTYLE}
      icon="arrow_right_bold_black"
      hoverIcon="arrow_right_bold_purple"
    >
      Move to {section.title}
    </OptionIcon>
  );
}

const mapState = (state, ownProps) => {
  const {
    rootReducer: { model },
  } = state;

  const { sectionId } = ownProps;
  const section = model.getIn([ENTITY_NAME.Section, sectionId]);

  return { section };
};

export default connect(mapState, null)(MoveToSection);
