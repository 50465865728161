import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getClientsList, resetFormResponses } from 'redux/form-responses/actions';
import { ReactComponent as EmptyContent } from 'assets/icons/empty_responses.svg';

import ClientsList from '../ClientsList';
import ResponsesMainPanel from '../ResponsesMainPanel';
import * as S from './styles';

const VIEW_MODES = {
  INDIVIDUAL: 'individual',
  SUMMARY: 'summary',
};

const getParamsFromUrl = search => {
  if (!search) {
    return [];
  }
  let params = search.split('&');
  params = params.map(item => item.substring(item.indexOf('=') + 1, item.length));

  return params;
};

function Responses({ match, history, getClientsList, resetFormResponses, numberOfResponses }) {
  const id = get(match, 'params.id', '');
  const [viewMode, setViewMode] = useState(VIEW_MODES.INDIVIDUAL);
  const params = getParamsFromUrl(get(history, 'location.search', ''));
  const clientId = params[0];
  const submittedId = params[1];

  useEffect(() => {
    if (id) {
      getClientsList(
        id,
        {
          clientId,
          submittedId,
        },
        !params.length,
      );
    }
    return () => {
      resetFormResponses();
    };
  }, [id]);

  const handleChangeViewMode = mode => {
    setViewMode(mode);
  };

  const handleResetHistoryState = () => {
    submittedId && history.replace();
  };

  if (!numberOfResponses) {
    return (
      <S.EmptyResponses>
        <EmptyContent />
        <S.EmptyText>There are no responses yet</S.EmptyText>
      </S.EmptyResponses>
    );
  }
  return (
    <S.ResponsesContainer isSummary={viewMode === VIEW_MODES.SUMMARY}>
      {viewMode === VIEW_MODES.INDIVIDUAL && (
        <ClientsList formId={id} clientId={clientId} resetHistoryState={handleResetHistoryState} />
      )}
      <ResponsesMainPanel
        match={match}
        onChangeViewMode={handleChangeViewMode}
        viewMode={viewMode}
        clientId={clientId}
        submittedId={submittedId}
        resetHistoryState={handleResetHistoryState}
      />
    </S.ResponsesContainer>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      formResponses: { loading },
      formDetails: { workingForm },
    },
  } = state;

  return {
    loading: loading,
    numberOfResponses: get(workingForm, 'number_of_responses', 0),
  };
};

const mapDispatch = dispatch => ({
  getClientsList: bindActionCreators(getClientsList, dispatch),
  resetFormResponses: bindActionCreators(resetFormResponses, dispatch),
});

export default withRouter(connect(mapState, mapDispatch)(Responses));
