// Libs
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

// Components
import UnitSelect from '../../UnitSelect';

const UnitSelectWrapper = props => {
  const { listUnitIngredient, loading } = props;
  return <UnitSelect {...props} data={listUnitIngredient} loading={loading} />;
};

const mapStateToProps = state => {
  const {
    rootReducer: { ingredientLibrary },
  } = state;

  return {
    loading: get(ingredientLibrary, 'loadingListUnitIngredient', false),
    listUnitIngredient: get(ingredientLibrary, 'listUnitIngredient', {}),
  };
};

export default connect(mapStateToProps, null)(UnitSelectWrapper);
