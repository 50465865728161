import React from 'react';
import * as S from './style';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export default function AddWorkoutCollectionsArea(props) {
  return (
    <S.Wrapper>
      <div className="workoutCollectionsArea__content">
        <div className="workoutCollectionsArea__image">
          <img src={`${CDN_URL}/images/add_workout_collections_banner.svg`} alt="" />
        </div>
        <div className="workoutCollectionsArea__info">
          <div className="workoutCollectionsArea__info--title">Assign a Workout Collection</div>
          <div className="workoutCollectionsArea__info--description">
            Offer on-demand workouts that your clients can browse and start anytime
          </div>
          <Button className="workoutCollectionsArea__info--button" onClick={props.onAddStudioProgram}>
            Choose Collection
          </Button>
        </div>
      </div>
    </S.Wrapper>
  );
}
