import styled, { css } from 'styled-components';
import { Dropdown as DropdownElement } from 'semantic-ui-react';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fafbfc;
  overflow: auto;
  scroll-behavior: smooth;
  padding: 0;
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px 220px;
  background: #f0f2f5;
  overflow: none;
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 1 1 !important;
  margin: 0;
`;

export const ScrollTop = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  position: fixed;
  right: 88px;
  bottom: 30px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  z-index: 1000;
  background: #ffffff;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: #6456c4 !important;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const ListContainer = styled.div`
  .leaderboardDetail {
    width: 522px !important;
    background: transparent;
  }
  .clientList {
    background: #ffffff;
    min-height: 300px;
  }
  .info__name {
    width: 100%;
  }
`;

export const Header = styled.div`
  box-shadow: 0px 1px 0px 0px #e9ecf2;
  padding: 30px 30px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input {
    height: 40px !important;
    width: 320px !important;
    left: 0px;
    top: 0px;
    border-radius: 5px;
  }
`;

export const Content = styled.div`
  padding: 30px;
`;

export const Title = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Subtitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;

  span {
    opacity: 0.5;
  }
`;

export const MemberNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 13px;
  height: 34px;
  margin-left: 10px;
  align-self: center;
  color: #202353;

  .member-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 7px;
  }
  .create-at {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    opacity: 50%;
  }
`;

export const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
  flex: 1;
  position: relative;
`;

export const Dropdown = styled(DropdownElement)`
  background: #f4f6f8 !important;
  border-radius: 5px !important;
  height: 38px;
  width: 59px;
  padding: 5px 10px !important;

  .icon {
    position: absolute;
    top: 13px;
    right: 14px !important;
  }
`;

export const DropItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202353;
  padding: 17px 20.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: auto;

  & > span {
    margin-left: 10px;
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    svg > path {
      stroke: #5158cf;
    }
  }

  ${props =>
    props.remove &&
    css`
      color: #ea314a !important;
      svg > path {
        stroke: #ea314a !important;
      }
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;

  Button {
    min-width: 59px !important;
    height: 38px;
    background: #f4f6f8 !important;
  }

  & > * {
    margin-left: 8px !important;
  }
`;

export const Divider = styled.div`
  height: 1px;
  flex: 0.9;
  border-radius: 0px;
  opacity: 0.6;
  background: #dadfea;
  margin: 30px;
`;

export const NoData = styled.div`
  /* min-height: 500px; */
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin-bottom: 12px;
  }

  .title {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
  }

  .subtitle {
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const ResultsText = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: #202353;
  margin-bottom: 30px;
`;
