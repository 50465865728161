import _ from 'lodash';
import { push } from 'connected-react-router';

import Request from 'configs/request';
import { resetLoadPostsQuery, checkPostsIndicatorAndGotoPostDetail } from 'redux/forum/actions';
import { getClientsList } from 'redux/form-responses/actions';
import {
  EVENT_OPEN_TASK_REMINDER_POP_UP,
  TOPICS,
  NOTIFICATION_SUB_ACTIONS,
  PAYMENT_NOTIFICATIONS_TYPES,
  CLIENT_OWNER_SHIP,
  NOTIFICATION_ACTIONS,
} from 'constants/commonData';
import {
  convertFiltersToNotificationParams,
  getAccessToken,
  getCreatedAtString,
  getAdditionalInfoDayString,
} from 'utils/commonFunction';

export const Types = {
  NOTIFICATION_GET_LIST: 'NOTIFICATION_GET_LIST',
  NOTIFICATION_SUCCESS_GET_LIST: 'NOTIFICATION_SUCCESS_GET_LIST',
  NOTIFICATION_FAILED_GET_LIST: 'NOTIFICATION_FAILED_GET_LIST',
  NOTIFICATION_MARK_AS_READ: 'NOTIFICATION_MARK_AS_READ',
  NOTIFICATION_MARK_AS_UNREAD: 'NOTIFICATION_MARK_AS_UNREAD',
  NOTIFICATION_MARK_ALL_AS_READ: 'NOTIFICATION_MARK_ALL_AS_READ',
  NOTIFICATION_COUNT_UNREAD: 'NOTIFICATION_COUNT_UNREAD',
  NOTIFICATION_RESET_DATA: 'NOTIFICATION_RESET_DATA',
  NOTIFICATION_CLEAR_NEW: 'NOTIFICATION_CLEAR_NEW',
  NOTIFICATION_SUCCESS_CLEAR_NEW: 'NOTIFICATION_SUCCESS_CLEAR_NEW',
  NOTIFICATION_FAILED_CLEAR_NEW: 'NOTIFICATION_FAILED_CLEAR_NEW',
  NOTIFICATION_CLEAR_LIST: 'NOTIFICATION_CLEAR_LIST',
  NOTIFICATION_HAS_NEW_ONE_SOCKET: 'NOTIFICATION_HAS_NEW_ONE_SOCKET',
  NOTIFICATION_SELECTED: 'NOTIFICATION_SELECTED',
  NOTIFICATION_FETCH_CLIENTS_REQUEST: 'NOTIFICATION_FETCH_CLIENTS_REQUEST',
  NOTIFICATION_FETCH_CLIENTS_SUCCESS: 'NOTIFICATION_FETCH_CLIENTS_SUCCESS',
  NOTIFICATION_FETCH_CLIENTS_FAILED: 'NOTIFICATION_FETCH_CLIENTS_FAILED',
  NOTIFICATION_SUMMARIES_REQUEST: 'NOTIFICATION_SUMMARIES_REQUEST',
  NOTIFICATION_SUMMARIES_SUCCESS: 'NOTIFICATION_SUMMARIES_SUCCESS',
  NOTIFICATION_SUMMARIES_FAILED: 'NOTIFICATION_SUMMARIES_FAILED',
  NOTIFICATION_FILTER_REQUEST: 'NOTIFICATION_FILTER_REQUEST',
  NOTIFICATION_FILTER_SUCCESS: 'NOTIFICATION_FILTER_SUCCESS',
  NOTIFICATION_FILTER_FAILED: 'NOTIFICATION_FILTER_FAILED',
  NOTIFICATION_POPUP_FILTER_REQUEST: 'NOTIFICATION_POPUP_FILTER_REQUEST',
  NOTIFICATION_POPUP_FILTER_SUCCESS: 'NOTIFICATION_POPUP_FILTER_SUCCESS',
  NOTIFICATION_POPUP_FILTER_FAILED: 'NOTIFICATION_POPUP_FILTER_FAILED',
  NOTIFICATION_CATEGORY_MARK_ALL_AS_READ: 'NOTIFICATION_CATEGORY_MARK_ALL_AS_READ',
  NOTIFICATION_DECREASE_CATEGORY_SUMMARIES: 'NOTIFICATION_DECREASE_CATEGORY_SUMMARIES',
  NOTIFICATION_INCREASE_CATEGORY_SUMMARIES: 'NOTIFICATION_INCREASE_CATEGORY_SUMMARIES',
};

export const markAsRead = id => ({ type: Types.NOTIFICATION_MARK_AS_READ, payload: { id } });
export const markAsUnread = id => ({ type: Types.NOTIFICATION_MARK_AS_UNREAD, payload: { id } });
export const resetData = () => ({ type: Types.NOTIFICATION_RESET_DATA });
export const decreaseCategorySummaries = ({ category = '', amount = 1, reduceToZero = false } = {}) => ({
  type: Types.NOTIFICATION_DECREASE_CATEGORY_SUMMARIES,
  payload: { category, amount, reduceToZero },
});
export const increaseCategorySummaries = ({ category = '', amount = 1 } = {}) => ({
  type: Types.NOTIFICATION_INCREASE_CATEGORY_SUMMARIES,
  payload: { category, amount },
});

export const getNotifications = params => {
  return dispatch => {
    dispatch({ type: Types.NOTIFICATION_GET_LIST });
    return dispatch(
      Request.get(
        { url: '/api/notification/list', params },
        true,
        response => {
          const { data, page, total } = response.data;
          dispatch({ type: Types.NOTIFICATION_SUCCESS_GET_LIST, payload: { page, total, data } });
        },
        () => dispatch({ type: Types.NOTIFICATION_FAILED_GET_LIST }),
      ),
    );
  };
};

// Mark all as read
export const readNotifications = data => (dispatch, getState) => {
  return dispatch(
    Request.post({ url: '/api/notification/open', data }, false, (response, { dispatch }) => {
      if (!_.get(data, 'id')) {
        dispatch({ type: Types.NOTIFICATION_MARK_ALL_AS_READ });
        const isPermission = _.get(getState(), 'rootReducer.permission.notification_center', false);
        if (isPermission) {
          dispatch(
            decreaseCategorySummaries({
              category: 'all',
              amount: 0,
              reduceToZero: true,
            }),
          );
        }
      }
    }),
  );
};

export const readCategoryNotifications = category => (dispatch, getState) => {
  return dispatch(
    Request.post(
      {
        url: '/api/v3/notifications/mark-category-as-read',
        data: {
          category,
        },
      },
      false,
      (response, { dispatch }) => {
        if (_.get(response, 'data.data.success', false)) {
          dispatch({ type: Types.NOTIFICATION_CATEGORY_MARK_ALL_AS_READ });
          const isPermission = _.get(getState(), 'rootReducer.permission.notification_center', false);
          if (isPermission) {
            dispatch(
              decreaseCategorySummaries({
                category,
                amount: _.get(response, 'data.data.notifications', 0),
              }),
            );
          }
        }
      },
      error => {},
      false,
    ),
  );
};

export const countUnread = () => {
  return Request.get({ url: '/api/notification/countUnread' }, false, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch({ type: Types.NOTIFICATION_COUNT_UNREAD, payload: { data } });
  });
};

export const clearNewNotification = () => {
  return dispatch => {
    dispatch({ type: Types.NOTIFICATION_CLEAR_NEW });

    return dispatch(
      Request.post(
        { url: '/api/notification/read' },
        false,
        () => dispatch({ type: Types.NOTIFICATION_SUCCESS_CLEAR_NEW }),
        () => dispatch({ type: Types.NOTIFICATION_FAILED_CLEAR_NEW }),
      ),
    );
  };
};

export const clearList = () => ({ type: Types.NOTIFICATION_CLEAR_LIST });

export const newNotification = () => ({ type: Types.NOTIFICATION_HAS_NEW_ONE_SOCKET });

export const navigateToPersonalTask = data => {
  return { type: Types.NOTIFICATION_SELECTED, payload: data };
};

/**
 * Open item notification
 *
 * @param {Object} data - the data parameter
 * @param {Event} event - the event parameter
 * @param {Function} dispatch - the dispatch function
 * @param {Function} getState - the getState function
 * @return {undefined} no return value
 */
export const openItemNotification = (data, event) => async (dispatch, getState) => {
  const openNewTab = event.shiftKey || event.metaKey || event.ctrlKey;

  const isPaymentNotification =
    _.values(PAYMENT_NOTIFICATIONS_TYPES).includes(_.get(data, 'type')) ||
    _.values(PAYMENT_NOTIFICATIONS_TYPES).includes(_.get(data, 'action_sub_type'));
  const isPaymentNotificationSummary = _.get(data, 'action_target') === PAYMENT_NOTIFICATIONS_TYPES.PAYMENT_SUMMARY;

  const isFormQuestionnaireNotification = _.get(data, 'type') === 'form_questionnaire';
  const isRecap = data.action_type === NOTIFICATION_ACTIONS.RECAP && data.category === 'admin';

  const isHabitPermission = _.get(getState(), 'rootReducer.permission.habit', false);
  const isMultipleOnboardingPermission = _.get(getState(), 'rootReducer.permission.multiple_onboarding_flow', false);

  let url;
  await dispatch(markAsReadItemNotification(data));
  if (
    data.action_type === NOTIFICATION_ACTIONS.MEAL_PLAN_CLIENT &&
    data.action_sub_type === NOTIFICATION_SUB_ACTIONS.CLIENT_SWAPPED_RECIPE &&
    data.action_target === NOTIFICATION_ACTIONS.MEAL_PLAN_CLIENT
  ) {
    const clientId = _.get(data, 'relate_user._id');
    const trackedDate = getAdditionalInfoDayString(data);
    if (clientId) {
      url = `/home/client/${clientId}/meal-plan?tracked=${trackedDate}`;
      // improve late for case same path
      if (window.location.pathname.includes(`/home/client/${clientId}/meal-plan`)) {
        if (openNewTab) {
          window.open(url, '_blank');
        } else {
          // Force reload page
          window.open(url, '_self');
        }
        return;
      }
    }
  } else if (
    data.action_type === NOTIFICATION_ACTIONS.MACRO &&
    data.action_sub_type === NOTIFICATION_SUB_ACTIONS.CLIENT_LOGGED_MEAL &&
    data.action_target === NOTIFICATION_ACTIONS.MACRO
  ) {
    const clientId = _.get(data, 'relate_user._id');
    const trackedDate = getAdditionalInfoDayString(data);
    if (clientId) {
      url = `/home/client/${clientId}/macros?tracked=${trackedDate}`;
      // improve late for case same path
      if (window.location.pathname.includes(`/home/client/${clientId}/macros`)) {
        if (openNewTab) {
          window.open(url, '_blank');
        } else {
          // Force reload page
          window.open(url, '_self');
        }
        return;
      }
    }
  } else if (
    data.action_type === NOTIFICATION_ACTIONS.PROGRESS_PHOTO &&
    data.action_target === NOTIFICATION_ACTIONS.PROGRESS_PHOTO
  ) {
    const clientId = _.get(data, 'relate_user._id');
    url = `/home/client/${clientId}?show=progress_photo`;
  } else if (
    data.action_type === NOTIFICATION_ACTIONS.GOAL &&
    data.action_sub_type === NOTIFICATION_SUB_ACTIONS.UPDATE &&
    !data.action_target
  ) {
    const clientId = _.get(data, 'relate_user._id');
    url = `/home/client/${clientId}/macros`;
  } else if (
    data.action_type === NOTIFICATION_ACTIONS.BODY_METRIC &&
    data.action_sub_type === NOTIFICATION_SUB_ACTIONS.UPDATE &&
    data.action_target === NOTIFICATION_ACTIONS.BODY_METRIC
  ) {
    const clientId = _.get(data, 'relate_user._id');
    url = `/home/client/${clientId}/metrics`;
  } else if (data.action_type === NOTIFICATION_ACTIONS.FORM_QUESTIONNAIRES) {
    const formId = _.get(data, 'additional_info._id');
    const clientId = _.get(data, 'relate_user._id');
    const idClientInfo = _.get(data, 'additional_info.client._id');
    const submittedId = _.get(data, 'item');
    await dispatch(
      getClientsList(formId, {
        clientId,
        submittedId,
      }),
    );
    url = `/home/forms/${formId}/responses?client=${
      idClientInfo ? idClientInfo : clientId
    }&submitted=${submittedId}&hasComment=true`;
  } else if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.NEW_WAITING_CLIENT) {
    url = '/home/client/waiting-activation';
  } else if (data.action_type === NOTIFICATION_ACTIONS.LEADER_BOARD && data.secondary_item) {
    url = `/home/autoflow/${data.secondary_item}/leaderboard`;
  } else if (data.action_type === NOTIFICATION_ACTIONS.REFERRAL) {
    url = '/home/referral-program';
  } else if (data.action_type === NOTIFICATION_ACTIONS.AUTOFLOW) {
    url = '/home/autoflow';
    if (data.item) {
      url += `/${data.item}/${data.item_topic}`;
    }
  } else if (data.action_type === NOTIFICATION_ACTIONS.ONBOARDING_FLOW && isMultipleOnboardingPermission) {
    url = '/home/onboarding-flow';
    if (data.item) {
      url += `/${data.item}`;
    }
    if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.TRAINER_INVITE_CODE_ADDED) {
      url += `?view_mode=settings`;
    }
  } else if (data.action_type === NOTIFICATION_ACTIONS.FORUM) {
    if (data.sub_item_topic === 'forum_post') {
      dispatch(resetLoadPostsQuery());
      // Mark Read Post Detail And Go To Detail
      await dispatch(
        checkPostsIndicatorAndGotoPostDetail({ postId: data.sub_item }, data.item, data.sub_item, openNewTab),
      );
      return;
    } else {
      dispatch(resetLoadPostsQuery());
      url = `/home/forums/${data.item}/discussion`;
    }
  } else if (data.action_type === NOTIFICATION_ACTIONS.LOG_ACTIVITY) {
    const idRelateUser = _.get(data, 'relate_user._id', '');
    const idAuthor = _.get(data, 'additional_info.author', '');
    if (idRelateUser || idAuthor) {
      url = `/home/client/${idAuthor || idRelateUser}/calendar/${data.item}/activity`;
    }
  } else if (data.action_type === NOTIFICATION_ACTIONS.CLIENT_ASSIGNED) {
    const clientId = _.get(data, 'additional_info.client._id');
    url = `/home/client`;
    if (clientId) {
      url = `/home/client/${clientId}`;
    }
  } else if (data.relate_user) {
    if (data.action_type !== TOPICS.PERSONAL_TASK) {
      const idRelateUser = _.get(data, 'relate_user._id', '');
      const idClient = _.get(data, 'additional_info.client._id', '');
      url = `/home/client/${idClient ? idClient : idRelateUser}`;
    }

    if (data.item) {
      switch (data.item_topic) {
        case TOPICS.FOOD_JOURNAL:
          url += `/food-journal/detail/${data.item}`;
          break;
        case TOPICS.ASSIGNMENT:
          url += `/calendar/${data.item}/history`;
          break;
        case TOPICS.TASK:
          url += `/task/${data.item}?`;
          if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.COMMENT) {
            url += `comment=show&`;
          }
          url = url.replace(/\?$|&$/, '');
          break;
        case TOPICS.TEAM_BODY_METRIC:
          const metricCode = _.get(data, 'additional_info.metric_code');
          url += metricCode ? `/metrics?unique_code=${encodeURIComponent(metricCode)}` : `/metrics`;
          break;
        case TOPICS.PERSONAL_TASK:
          window.dispatchEvent(EVENT_OPEN_TASK_REMINDER_POP_UP);
          dispatch(navigateToPersonalTask(data));
          break;
        case TOPICS.HABIT:
          if (isHabitPermission) {
            url += `/habit/${data.item}?`;
            if (
              data.action_sub_type === NOTIFICATION_SUB_ACTIONS.ON_STREAK ||
              data.action_sub_type === NOTIFICATION_SUB_ACTIONS.HABIT_COMPLETE_TRAINER
            ) {
              const trackedDate = getCreatedAtString(data);
              url += `tracked=${trackedDate}&`;
            }
            if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.COMMENT) {
              url += `comment=show&`;
            }
            url = url.replace(/\?$|&$/, '');
          } else {
            return;
          }
          break;
        default:
          break;
      }
    }
  }

  const packageId = _.get(data, 'additional_info.package.hash_id') || _.get(data, 'additional_info.package.id');
  const purchaseId = _.get(data, 'additional_info.purchase.id') || _.get(data, 'additional_info.purchase_id');
  const formId = _.get(data, 'additional_info.form');
  const clientId = _.get(data, 'relate_user._id');
  const submittedId = _.get(data, 'additional_info.formAnswer');

  if (isPaymentNotification && packageId && purchaseId) {
    const url = `/home/packages/${packageId}/analytics?preview=${purchaseId}`;
    if (openNewTab) {
      window.open(url, '_blank');
    } else {
      // Force reload page
      window.open(url, '_self');
    }
    return;
  }

  if (isPaymentNotificationSummary) {
    const url = `/home/packages`;
    if (openNewTab) {
      window.open(url, '_blank');
    } else {
      dispatch(push(url));
    }
    return;
  }

  if (isFormQuestionnaireNotification && formId && clientId && submittedId) {
    const url = `/home/forms/${formId}/responses?client=${clientId}&submitted=${submittedId}`;
    if (openNewTab) {
      window.open(url, '_blank');
    } else {
      dispatch(push(url));
    }
    return;
  }

  if (isRecap) {
    const token = getAccessToken();
    const domain = process.env.REACT_APP_RECAP_DOMAIN;
    if (domain) {
      const url = domain + `?token=${token}`;
      window.open(url, '_blank');
    }
    return;
  }

  if (
    data.action_type === NOTIFICATION_ACTIONS.ONBOARDING_FLOW &&
    data.item &&
    data.action_sub_type !== NOTIFICATION_SUB_ACTIONS.TRAINER_INVITE_CODE_ADDED
  ) {
    const url = `/home/onboarding-flow/${data.item}`;
    if (openNewTab) {
      window.open(url, '_blank');
    } else {
      dispatch(push(url));
    }
    return;
  }

  if (!url) return;

  if (openNewTab) {
    window.open(url, '_blank');
  } else {
    !isFormQuestionnaireNotification && dispatch(push(url));
  }
};

/**
 * Marks an item notification as read.
 *
 * @param {Object} data - The notification data.
 * @param {function} dispatch - The dispatch function.
 */
export const markAsReadItemNotification = data => (dispatch, getState) => {
  if (!data.checked) {
    return dispatch(
      Request.patch(
        { url: `/api/v3/notifications/${data._id}/mark-as-read` },
        true,
        response => {
          if (_.get(response, 'data.data.success', false)) {
            dispatch(markAsRead(data._id));
            const isPermission = _.get(getState(), 'rootReducer.permission.notification_center', false);
            if (isPermission) {
              dispatch(decreaseCategorySummaries({ category: _.get(data, 'category') }));
            }
          }
        },
        error => {},
        true,
      ),
    );
  }
  return;
};

/**
 * Marks an item notification as unread.
 *
 * @param {Object} data - The notification data.
 * @param {function} dispatch - The dispatch function.
 */
export const markAsUnreadItemNotification = data => (dispatch, getState) => {
  if (data.checked) {
    return dispatch(
      Request.patch(
        { url: `/api/v3/notifications/${data._id}/mark-as-unread` },
        true,
        response => {
          if (_.get(response, 'data.data.success', false)) {
            dispatch(markAsUnread(data._id));
            const isPermission = _.get(getState(), 'rootReducer.permission.notification_center', false);
            if (isPermission) {
              dispatch(increaseCategorySummaries({ category: _.get(data, 'category') }));
            }
          }
        },
        error => {},
        true,
      ),
    );
  }
  return;
};

export const getNotificationClientsByParams = ({ page = 1, text_search = '' } = {}) => (dispatch, getState) => {
  const per_page = _.get(getState(), 'rootReducer.notification.clients.per_page', 20);
  const params = {
    page,
    per_page,
    owner_ship: CLIENT_OWNER_SHIP.ALL,
    text_search,
  };

  dispatch({ type: Types.NOTIFICATION_FETCH_CLIENTS_REQUEST, payload: params });
  return dispatch(
    Request.get(
      { url: 'api/client-management/search', params },
      false,
      result => {
        const list = _.get(result, 'data.data.list', []);
        const total = _.get(result, 'data.data.total', 0);
        dispatch({
          type: Types.NOTIFICATION_FETCH_CLIENTS_SUCCESS,
          payload: { page, text_search, list, total },
        });
      },
      error => {
        dispatch({
          type: Types.NOTIFICATION_FETCH_CLIENTS_FAILED,
          payload: error,
        });
      },
    ),
  );
};

/**
 * Retrieves the summaries of notifications.
 *
 * @param {function} dispatch - The dispatch function from Redux.
 * @param {function} getState - The getState function from Redux.
 * @return {Promise} A promise that resolves with the notification summaries.
 */
export const getNotificationSummaries = () => dispatch => {
  dispatch({ type: Types.NOTIFICATION_SUMMARIES_REQUEST });

  return dispatch(
    Request.get(
      {
        url: '/api/v3/notifications/summaries',
      },
      false,
      result => {
        dispatch({
          type: Types.NOTIFICATION_SUMMARIES_SUCCESS,
          payload: _.get(result, 'data.data', {}),
        });
      },
      error => {
        dispatch({
          type: Types.NOTIFICATION_SUMMARIES_FAILED,
          payload: error,
        });
      },
    ),
  );
};

/**
 * Retrieves notifications with optional filtering and pagination.
 *
 * @param {Object} options - An object containing optional parameters.
 * @param {number} options.page - The page number to retrieve (default: 1).
 * @param {Object} options.filters - An object containing filters to apply (default: {}).
 * @param {function} dispatch - The dispatch function from redux.
 * @param {function} getState - The getState function from redux.
 * @return {Promise} A promise that resolves to the filtered notifications.
 */
export const getNotificationWithFilter = ({ page = 1, filters = {} } = {}) => (dispatch, getState) => {
  const notifications = _.get(getState(), 'rootReducer.notification.dashboard', {});
  let queryParams = {};
  if (!_.isEmpty(filters)) {
    queryParams = convertFiltersToNotificationParams(filters);
  } else {
    queryParams = _.get(notifications, 'filters', {});
  }
  const params = {
    ..._.pick(notifications, ['page', 'per_page']),
    page,
    ...queryParams,
  };

  dispatch({ type: Types.NOTIFICATION_FILTER_REQUEST, payload: { page, filters: queryParams } });
  return dispatch(
    Request.get(
      { url: '/api/v3/notifications', params },
      false,
      result => {
        const list = _.get(result, 'data.data', []);
        const total = _.get(result, 'data.total', 0);
        const currentPage = _.get(result, 'data.page', 1);
        const currentPerPage = _.get(result, 'data.per_page', 1);
        dispatch({
          type: Types.NOTIFICATION_FILTER_SUCCESS,
          payload: { list, total, page: currentPage, per_page: currentPerPage, filters: queryParams },
        });
      },
      error => {
        dispatch({
          type: Types.NOTIFICATION_FILTER_FAILED,
          payload: error,
        });
      },
      false,
    ),
  );
};

/**
 * Retrieves notifications popup sidebar with optional filtering and pagination.
 *
 * @param {Object} options - An object containing optional parameters.
 * @param {number} options.page - The page number to retrieve (default: 1).
 * @param {Object} options.filters - An object containing filters to apply (default: {}).
 * @param {function} dispatch - The dispatch function from redux.
 * @param {function} getState - The getState function from redux.
 * @return {Promise} A promise that resolves to the filtered notifications.
 */
export const getNotificationPopupWithFilter = ({ page = 1, filters = {} } = {}) => (dispatch, getState) => {
  const notifications = _.get(getState(), 'rootReducer.notification.popup', {});
  let queryParams = {};
  if (!_.isEmpty(filters)) {
    queryParams = convertFiltersToNotificationParams(filters);
  } else {
    queryParams = _.get(notifications, 'filters', {});
  }
  const params = {
    ..._.pick(notifications, ['page', 'per_page']),
    page,
    ...queryParams,
  };

  dispatch({ type: Types.NOTIFICATION_POPUP_FILTER_REQUEST, payload: { page, filters: queryParams } });
  return dispatch(
    Request.get(
      { url: '/api/v3/notifications', params },
      true,
      result => {
        const list = _.get(result, 'data.data', []);
        const total = _.get(result, 'data.total', 0);
        const currentPage = _.get(result, 'data.page', 1);
        const currentPerPage = _.get(result, 'data.per_page', 1);
        dispatch({
          type: Types.NOTIFICATION_POPUP_FILTER_SUCCESS,
          payload: { list, total, page: currentPage, per_page: currentPerPage, filters: queryParams },
        });
      },
      error => {
        dispatch({
          type: Types.NOTIFICATION_POPUP_FILTER_FAILED,
          payload: error,
        });
      },
      false,
    ),
  );
};
