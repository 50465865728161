// Libs
import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

// Actions
import { axiosInstance } from 'configs/request';

// Constants
import { TITLE_HEIGHT_ONE_LINE } from 'components/FormDetails/constants';
import { roundNumberBodyMetric } from 'utils/commonFunction';

// Assets
import * as S from '../styles';

export default function MetricAnswerSummary({ data }) {
  const { _id, question_original_id: originalQuestionId = '' } = data || {};

  const [answers, setAnswers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [titleHeight, setTitleHeight] = useState(TITLE_HEIGHT_ONE_LINE);

  const total = useRef(0);

  useEffect(() => {
    if (!loading && originalQuestionId !== 'null') {
      getAnswers();
    }
  }, [page]);

  useEffect(() => {
    updateTitleHeight();
    window.addEventListener('resize', updateTitleHeight);
    return () => window.removeEventListener('resize', updateTitleHeight);
  }, []);

  const getAnswers = () => {
    setLoading(true);
    axiosInstance({
      url: `api/form-questions/${originalQuestionId}/text-answers`,
      method: 'GET',
      params: {
        page,
        per_page: 20,
        sort: -1,
      },
    })
      .then(res => {
        const { data: { data = {} } = {} } = res || {};
        total.current = data.total;
        const newAnswers = [...answers, ...data.data];
        if (newAnswers.length === data.total) {
          setIsEnd(true);
        }
        setAnswers(newAnswers);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScrollEnd = event => {
    if (event.target) {
      const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
      if (bottom && !isEnd && !loading) {
        setPage(page + 1);
      }
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const updateTitleHeight = () => {
    const headerElement = document.getElementById(`${_id}-header`);
    if (headerElement) {
      setTitleHeight(headerElement.offsetHeight);
    }
  };

  const renderMetricValue = answer => {
    const { metric_answer, additional_data } = answer || {};
    const { value, unit } = metric_answer || {};

    if (!isEmpty(additional_data)) {
      const { metric_ft: ft = '', metric_in: inch = '' } = additional_data || {};
      const ftStr = ft !== 0 ? `${roundNumberBodyMetric(ft)} ft` : '';
      const inchStr = inch !== 0 ? `${roundNumberBodyMetric(inch)} in` : '';

      return ft === 0 && inch !== 0 ? `${inchStr}` : `${ftStr} ${inchStr}`;
    }

    return `${roundNumberBodyMetric(value)} ${(unit || {}).title}`;
  };

  return (
    <S.DateAnswerSummary
      onScroll={event => {
        event.persist();
        onScrollDebounce.call(null, event);
      }}
      titleHeight={titleHeight}
    >
      {answers.map(answer => {
        return (
          <S.DateAnswerItem key={_id} readOnly>
            {renderMetricValue(answer) || ''}
          </S.DateAnswerItem>
        );
      })}
    </S.DateAnswerSummary>
  );
}
