// Lib
import React, { useEffect, useRef, useState } from 'react';

// Shared
import TextEditable from 'shared/TextEditable';

// Constants
import { setCaretToEnd, useOnClickOutside } from 'utils/commonFunction';
import { KEY, KEY_CODE } from 'constants/commonData';
import { SEE_MORE_HEIGHT } from '../constants';

// Parts
import DescriptionModal from './DescriptionModal';

// Assets
import { ReactComponent as PrimeIcon } from 'assets/icons/MealPlans/prime_icon.svg';

import * as S from './style';

const DescriptionEditable = props => {
  const { isEditMode, description = '', onChange, toggleModal } = props;

  const [hasViewMode, setHasViewMode] = useState(false);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    const inputElement = document.querySelector('.desc-recipe .text-editable__input');
    if (inputElement) {
      inputElement.textContent = inputElement.textContent.trim();
    }
  }, [isEditMode]);

  useOnClickOutside(textareaRef, e => {
    if (isEditing) {
      handleClose(e);
    }
  });

  useEffect(() => {
    const textareaEl = textareaRef && textareaRef.current;
    const textEditableInput = textareaEl && textareaEl.querySelector('.text-editable__input');

    if (textEditableInput) {
      setIsOverflowed(textEditableInput.scrollHeight > SEE_MORE_HEIGHT);
    }
  }, [isEditMode, onChange]);

  const handleSeeMore = e => {
    e.stopPropagation();
    const textareaEl = textareaRef && textareaRef.current;
    const element = textareaEl && textareaEl.querySelector('.text-editable__input');
    const elementContainer = textareaEl.querySelector('.text-editable__container');

    if (isEditMode && element && elementContainer) {
      setIsEditing(true);
      setHasViewMode(true);

      element.focus();
      setCaretToEnd(element);
      requestAnimationFrame(() => {
        elementContainer.scrollTop = elementContainer.scrollHeight;
      });
    }

    if (!isEditMode) {
      handleOpenDesc();
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === KEY_CODE.tab || e.key === KEY.TAB) {
      handleClose(e);
    }
  };

  const handleClose = e => {
    e.stopPropagation();
    setHasViewMode(false);
    setIsEditing(false);

    if (!isEditMode) {
      toggleModal(false);
    }
  };

  const handleOpenDesc = () => {
    typeof toggleModal === 'function' &&
      toggleModal(true, <DescriptionModal description={description} onClose={handleClose} />);
  };

  return (
    <>
      <S.RecipeDesc isEditMode={isEditMode} hasViewMode={hasViewMode} hasDescription={description}>
        <div className="blank"></div>
        {!isEditMode && description && (
          <span className="desc-prime">
            <PrimeIcon className="desc-prime-icon" />
          </span>
        )}
        {(isEditMode || description) && (
          <div onClick={e => isEditMode && !isEditing && handleSeeMore(e)} ref={textareaRef}>
            <TextEditable
              value={description || ''}
              placeholder="Add Description"
              breakLine={true}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              className="desc-recipe"
            />
          </div>
        )}
      </S.RecipeDesc>
      {isOverflowed && !hasViewMode ? <S.SeeMore onClick={e => handleSeeMore(e)}>See more</S.SeeMore> : null}
    </>
  );
};

export default DescriptionEditable;
