// libs
import React, { useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';

// constants
import { CLIENT_MEAL_PLAN_VIEW_MODE } from 'components/ClientMealPlan/constants';

// assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down_normal.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_blue.svg';

// styles
import * as S from './style';

const ChangeModeButton = ({ viewMode, onChangeViewMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectedMode = item => {
    onChangeViewMode(item);
  };

  return (
    <S.Wrapper onClick={toggleButton} isActive={isOpen}>
      {viewMode.label} <ArrowIcon className="arrow-icon" />
      {isOpen && (
        <RootCloseWrapper event="click" onRootClose={() => setIsOpen(false)} disabled={!isOpen}>
          <S.ViewModePopup>
            {CLIENT_MEAL_PLAN_VIEW_MODE.map(item => {
              const { key, label } = item || {};
              const isSelected = viewMode.key === key;
              return (
                <S.Option key={key} isSelected={isSelected} onClick={() => handleSelectedMode(item)}>
                  <span>{label}</span>
                  {isSelected && <CheckIcon className="check-icon" />}
                </S.Option>
              );
            })}
          </S.ViewModePopup>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

export default ChangeModeButton;
