import styled from 'styled-components';

export const TableEmptyWrapper = styled.div`
  margin-top: 158px;
  span {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #8c8c8c;
    position: absolute;
    width: 100%;
    text-align: center;
  }
`;
