import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: 500px;
    height: 100%;
    max-height: 500px;
    padding: 24px 8px 0px 24px;
    border-radius: 8px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      padding: unset !important;
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    > :last-child {
      padding: unset !important;
      border-radius: 0px !important;
    }

    .close-button img {
      width: 20px;
      height: 20px;
    }
  }

  .header-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 8px;
  }

  .title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #202353;

    word-break: break-word;
    white-space: pre-wrap;
  }

  .description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: rgba(32, 35, 83, 0.7);

    margin-bottom: 24px;
  }

  .member-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .loading-indicator {
      padding-top: 160px;
      .ui.loader:after {
        border-color: #5158cf transparent transparent !important;
      }
    }
  }

  .member-scroll-wrapper {
    overflow: hidden auto;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding-right: 12px;
    padding-bottom: 24px;

    ::-webkit-scrollbar {
      width: 4px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #d9d9d9 !important;
    }
    .loading-wrapper {
      width: 100%;
      .loading-indicator {
        padding-top: 12px;
        .ui.loader:after {
          border-color: #5158cf transparent transparent !important;
        }
      }
    }
  }

  .member-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: 0px;
    gap: 16px;
    min-height: 40px;
    cursor: default;

    .sb-avatar,
    .sb-avatar * {
      pointer-events: none;
    }

    .member-name {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;

      color: #202353;

      word-break: break-word;
      white-space: pre-wrap;
    }
  }
`;
