import React from 'react';
import OverdueAnnuallyOnwer from './OverdueAnnuallyOnwer';
import { connect } from 'react-redux';
import OverdueAnnuallyTeamMember from './OverdueAnnuallyTeamMember';
import { isTeamAdmin } from 'utils/commonFunction';

function OverdueAnnually(props) {
  const pathname = props.location.pathname;

  if (!pathname.startsWith('/home/autoflow')) {
    return null;
  }

  return isTeamAdmin(props.user) ? (
    <OverdueAnnuallyOnwer />
  ) : (
    <OverdueAnnuallyTeamMember />
  );
}

const mapStateToProps = (state) => {
  const {
    user,
    router,
    rootReducer
  } = state;

  return {
    user,
    location: router.location,
    teamData: rootReducer.pricing.get('teamData').toJS()
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(OverdueAnnually);