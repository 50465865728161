import React, { useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
// import moment from 'moment';
import Helmet from 'react-helmet';

import { Button } from 'shared/FormControl';
import AddPackagePopup from '../AddPackagePopup';
import { NewSearchInput } from 'shared/SearchInput';

import { toggleModal } from 'actions/modal';
import { changeQueryParams } from 'redux/package-list/actions';
import * as TabBar from 'components/Package/components/TabBar.style';
import { PACKAGE_LIST_TAB_ITEMS } from 'components/Package/constants';
// import { FILTER_DAYS, FILTER_DAYS_OPTIONS } from 'constants/commonData';

import { TabBarSection, FilterSection } from './style';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

function TabsHeader(props) {
  const { hideBanner, isMP, changeQueryParams, toggleModal, routeName, loading } = props;
  // const [filter, setFilter] = useState(FILTER_DAYS_OPTIONS.find(item => item.value === 'all'));
  const [inputSearch, setInputSearch] = useState('');
  const [currentRouteName, setCurrentRouteName] = useState(routeName);

  const { counter, query } = useMemo(() => {
    const newCounter = isMP ? props.counterMP : props.counter;
    const newQuery = isMP ? props.queryMP : props.query;
    return {
      counter: newCounter ? newCounter.toJS() : {},
      query: newQuery ? newQuery.toJS() : {},
    };
  }, [props.counter, isMP, props.counterMP, props.query, props.queryMP]);

  const publishCounter = counter.publish && counter.publish > 0 ? counter.publish : 0;
  const draftCounter = counter.draft && counter.draft > 0 ? counter.draft : 0;
  const allPackagesCounter = publishCounter + draftCounter > 0 ? publishCounter + draftCounter : 0;

  const fetchPackages = () => {
    const params = { ...query, q: '' };
    changeQueryParams(params, isMP);
  };

  // this effect for the first time render
  useEffect(() => {
    setTimeout(() => {
      !loading && fetchPackages();
    }, 100);

    return () => {
      handleClearSearch();
    };
  }, []);

  // this effect when changed route but same page
  // need to refetch data by new route
  useEffect(() => {
    const packagesRoutes = ['/home/packages', '/home/marketplace'];
    if (currentRouteName !== routeName && packagesRoutes.includes(routeName)) {
      fetchPackages();
      setCurrentRouteName(routeName);
    }
  }, [routeName, currentRouteName]);

  // clear search when change tab
  useEffect(() => {
    setInputSearch('');
  }, [isMP]);

  const handleCreatePackage = () => {
    toggleModal(true, <AddPackagePopup isMP={isMP} />);
  };

  const handleSelectTab = event => {
    const { status } = event.currentTarget.dataset;
    if ((query.status || '') !== status) {
      changeQueryParams({ status, page: 1 }, isMP);
    }
  };

  // const handleSelectView = item => {
  //   let created_at_end = moment();
  //   let created_at_start = created_at_end;
  //   switch (item.value) {
  //     case FILTER_DAYS.this_month:
  //       created_at_start = moment().subtract(moment().date() - 1, 'days');
  //       break;
  //     case FILTER_DAYS.months_2:
  //       created_at_start = moment().subtract(2, 'months');
  //       break;
  //     case FILTER_DAYS.days_30:
  //       created_at_start = moment().subtract(30, 'days');
  //       break;
  //     case FILTER_DAYS.days_14:
  //       created_at_start = moment().subtract(14, 'days');
  //       break;
  //     case FILTER_DAYS.days_7:
  //       created_at_start = moment().subtract(7, 'days');
  //       break;
  //     default:
  //       created_at_start = '';
  //       created_at_end = '';
  //       break;
  //   }
  //   setFilter(item);
  //   changeQueryParams(
  //     {
  //       created_at_start: created_at_start ? created_at_start.toISOString() : '',
  //       created_at_end: created_at_end ? created_at_end.toISOString() : '',
  //       page: 1,
  //     },
  //     isMP,
  //   );
  // };

  const handleDebounceSearch = useCallback(
    debounce(value => {
      changeQueryParams({ q: value, page: 1 }, isMP);
    }, 300),
    [isMP],
  );

  const handleSearch = (event, { value }) => {
    setInputSearch(value);
    handleDebounceSearch(value);
  };

  const handleClearSearch = () => {
    setInputSearch('');
    changeQueryParams({ q: '', page: 1 }, isMP);
  };

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 }, isMP);
    }
  };

  const handleCountTotal = item => {
    // TODO
    return item.key.includes('all')
      ? ` (${allPackagesCounter}) `
      : item.showTotal
      ? ` (${counter[item.value] || 0})`
      : null;
  };

  const renderPageTitle = () => {
    return `${isMP ? 'Marketplace ' : ''}Packages List - Everfit`;
  };

  return (
    <>
      <Helmet>
        <title>{renderPageTitle()}</title>
      </Helmet>
      <TabBarSection>
        <TabBar.TabBarContainer>
          {PACKAGE_LIST_TAB_ITEMS.map(item => (
            <TabBar.TabBarItem
              key={item.key}
              onClick={handleSelectTab}
              data-status={item.value}
              active={item.value === (query.status || '')}
            >
              {item.label}
              {handleCountTotal(item)}
            </TabBar.TabBarItem>
          ))}
        </TabBar.TabBarContainer>
        {hideBanner || isMP ? (
          <Button purple onClick={handleCreatePackage}>
            <PlusIcon />
            <span>Create Package</span>
          </Button>
        ) : null}
      </TabBarSection>
      {counter.all > 0 && (
        <FilterSection>
          <NewSearchInput
            value={inputSearch}
            onChange={handleSearch}
            onClearSearch={handleClearSearch}
            placeholder="Search by keyword or name"
            onKeyPress={handleKeyPress}
          />
          {/* TODO - SHOW on V2 */}
          {/* <FilterByDaysWrapper>
            <DropdownLabel>View</DropdownLabel>
            <Select
              options={FILTER_DAYS_OPTIONS}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              onChange={handleSelectView}
              classNamePrefix="evfSelectBox"
              className="evfSelectBoxContainer"
              value={filter}
              isSearchable={false}
              styles={{
                control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 74 }),
              }}
            />
          </FilterByDaysWrapper> */}
        </FilterSection>
      )}
    </>
  );
}

const mapDispatch = {
  toggleModal,
  changeQueryParams,
};

const mapState = state => {
  const {
    rootReducer: { packageList },
    user,
    router,
  } = state;

  return {
    counter: packageList.get('counter'),
    counterMP: packageList.get('counterMP'),
    query: packageList.get('query'),
    queryMP: packageList.get('queryMP'),
    hideBanner: user.is_hide_banner_package,
    routeName: router.location.pathname,
    loading: packageList.get('loadingPackages'),
  };
};

export default connect(mapState, mapDispatch)(TabsHeader);
