import React from 'react';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import ConfirmModal from 'shared/ConfirmModal';
import AddResultPopup from './AddResultPopup';

import { convertUnit, roundNumberBodyMetric } from 'utils/commonFunction';
import { ReactComponent as PlusIcon } from 'assets/icons/metric-add-icon-circle-purple.svg';
import { ReactComponent as EditIcon } from 'assets/icons/metric-edit-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete-trash.svg';
import './styles.scss';
import { TooltipStyle } from 'components/BodyMetricChartNew/components/KeysMetric/style';

export default class ManualStepProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddResultPopup: false,
      editResult: null,
    };
    this.onScrollDebounce = debounce(this.handleScrollEnd, 100);
  }

  handleEditResultClick = item => () => {
    const { editResult } = this.state;

    if (!editResult) {
      this.setState({ showAddResultPopup: false, editResult: item }, this.updateZIndexOfAppBar(true));
    }
  };

  handleRemoveResultItem = itemId => () => {
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Remove Result'}
        content={'Are you sure that you want to remove this result?'}
        onConfirm={() => this.onConfirmRemoveResult(itemId)}
      />,
    );
  };

  onConfirmRemoveResult = async metricEntryId => {
    const { handleRefreshBodyMetric, clientId, removeBodyMetricEntry, refreshManualStepChart } = this.props;
    try {
      await removeBodyMetricEntry({ clientId, entryId: metricEntryId });
      handleRefreshBodyMetric();
      refreshManualStepChart();
    } catch (error) {}
  };

  handleSaveResultClick = async data => {
    const { editResult } = this.state;
    const {
      addBodyMetricEntry,
      updateBodyMetricEntry,
      handleRefreshBodyMetric,
      refreshManualStepChart,
      clientId,
      timezone,
    } = this.props;

    try {
      const payload = { ...data, client: clientId, timezone };
      if (editResult) {
        payload.entryId = editResult._id;
        payload.unit = editResult.unit._id;
        await updateBodyMetricEntry(payload);
      } else {
        await addBodyMetricEntry(payload);
      }
      handleRefreshBodyMetric();
      refreshManualStepChart();
    } catch (error) {}
    this.handleCloseAddResultPopup();
  };

  handleScrollEnd = event => {
    const { loading, total, dataMetric, onLoadMore } = this.props;
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
    if (bottom && !loading && dataMetric.length < total) {
      onLoadMore();
    }
  };

  handleConvert = data => {
    const { metricTypes = [] } = this.props;
    const metricUnit = metricTypes.find(it => it.unique_code === data.metric_code);
    return data.value && metricUnit
      ? roundNumberBodyMetric(convertUnit(data.value, data.unit, metricUnit.unit))
      : data.value;
  };

  handleConvertResults = results => {
    const newResults = orderBy(results, e => e.date || '', 'desc');
    return newResults.map(item => {
      item.value = this.handleConvert(item);
      return item;
    });
  };

  handleScrollResult = event => {
    event.persist();
    this.onScrollDebounce.call(null, event);
  };

  updateZIndexOfAppBar = isLower => () => {
    const [appBar] = document.getElementsByClassName('app-navbar');
    const [mainContent] = document.getElementsByClassName('app-main-content');
    if (appBar) {
      appBar.style.zIndex = isLower ? 1 : 999;
    }
    if (mainContent) {
      mainContent.style.zIndex = isLower ? 2 : 1;
    }
  };

  handleAddResult = () => {
    this.setState({ showAddResultPopup: true, editResult: null }, this.updateZIndexOfAppBar(true));
  };

  handleCloseAddResultPopup = () => {
    this.setState({ showAddResultPopup: false, editResult: null }, this.updateZIndexOfAppBar(false));
  };

  formatTimeMetric = ({ value }) => {
    const { selectedMetric } = this.props;
    const { unit } = selectedMetric;
    const valueRounded = roundNumberBodyMetric(value);

    if (unit !== 'min') return `${valueRounded} ${unit}`;

    if (valueRounded < 60) return `${valueRounded} ${unit}`;

    const hour = Math.floor(valueRounded / 60);
    const min = valueRounded - hour * 60;
    return `${hour}:${min < 10 ? `0${min}` : min} hr`;
  };

  // renderEditPopup = () => {
  //   const { editResult } = this.state;
  //   if (editResult) {
  //     const valElm = document.getElementById(`result-id-${editResult._id}`);
  //     if (valElm) {
  //       const { dateFormat, timezone, selectedMetric, metricTypes } = this.props;
  //       const { x, y } = valElm.getBoundingClientRect();
  //       return (
  //         <AddResultPopup
  //           isOpen
  //           key={editResult._id}
  //           editData={editResult}
  //           style={{ left: x, top: y - 220, position: 'fixed', bottom: 'inherit' }}
  //           timezone={timezone}
  //           dateFormat={dateFormat}
  //           metric={selectedMetric}
  //           metricTypes={metricTypes}
  //           onClose={this.handleCloseAddResultPopup}
  //           onSave={this.handleSaveResultClick}
  //           isManualStep={true}
  //         />
  //       );
  //     }
  //     return null;
  //   }
  //   return null;
  // };

  renderEntryItem = item => {
    const { editResult } = this.state;
    const { dateFormat, timezone, selectedMetric, metricTypes } = this.props;
    const isEditing = editResult && editResult._id === item._id;
    const date = DateTime.fromISO(item.date).toLocal();
    const currentDateLocal = DateTime.local().setZone(timezone);
    let dateText = date.toFormat('MMM dd');

    if (date.toFormat('yyyy') !== currentDateLocal.toFormat('yyyy')) {
      dateText = date.toFormat('MMM dd, yyyy');
    } else if (date.toFormat('MM-dd-yyyy') === currentDateLocal.toFormat('MM-dd-yyyy')) {
      dateText = 'Today';
    }

    return (
      <Table.Row key={item._id} className={classNames('row-item', { editing: isEditing })}>
        <Table.Cell className="date">
          <div>{dateText}</div>
        </Table.Cell>
        <Table.Cell className="time">{date.toFormat('hh:mm a')}</Table.Cell>
        <Table.Cell className="value" id={`result-id-${item._id}`}>
          <div>{this.formatTimeMetric(item)}</div>
        </Table.Cell>
        <Table.Cell>
          <div className="actions progress-item">
            {/* <button
              onClick={this.handleEditResultClick(item)}
              className={classNames('btn-icon btn-edit', { active: editResult })}
              data-tip
              data-for={`edit-result-on-tooltip-${item._id}`}
            >
              <EditIcon />
              <ReactTooltip
                className="app-tooltip edit-result-on-tooltip"
                id={`edit-result-on-tooltip-${item._id}`}
                effect="solid"
                place={'top'}
              >
                <TooltipStyle>Edit Result</TooltipStyle>
              </ReactTooltip>
            </button> */}
            <AddResultPopup
              newUI
              isOpen={isEditing}
              key={`${item._id}_${isEditing ? 'open' : 'close'}`}
              editData={item}
              isManualStep={true}
              // style={{ left: x, top: y - 220, position: 'fixed', bottom: 'inherit' }}
              timezone={timezone}
              dateFormat={dateFormat}
              metric={selectedMetric}
              metricTypes={metricTypes}
              onClose={this.handleCloseAddResultPopup}
              onSave={this.handleSaveResultClick}
              onOpen={this.handleEditResultClick(item)}
              trigger={
                <button
                  onClick={e => e.preventDefault()}
                  className={classNames('btn-icon btn-edit', { active: editResult })}
                  data-tip
                  data-for={`edit-result-on-tooltip-${item._id}`}
                >
                  <EditIcon />
                  <ReactTooltip
                    className="app-tooltip edit-result-on-tooltip"
                    id={`edit-result-on-tooltip-${item._id}`}
                    effect="solid"
                    place={'top'}
                  >
                    <TooltipStyle>Edit Result</TooltipStyle>
                  </ReactTooltip>
                </button>
              }
            />
            <button
              onClick={this.handleRemoveResultItem(item._id)}
              className="btn-icon btn-trash"
              data-tip
              data-for={`remove-result-on-tooltip-${item._id}`}
            >
              <TrashIcon />
              <ReactTooltip
                className="app-tooltip remove-result-on-tooltip"
                id={`remove-result-on-tooltip-${item._id}`}
                effect="solid"
                place={'top'}
              >
                <TooltipStyle>Remove Result</TooltipStyle>
              </ReactTooltip>
            </button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    const { showAddResultPopup } = this.state;
    const { dataMetric = [], dateFormat, timezone, selectedMetric, metricTypes, loading } = this.props;
    const newResults = this.handleConvertResults(cloneDeep(dataMetric));

    return (
      <div className="progress-wrapper">
        <div className="progress-content">
          <div className="progress__header">
            <div className="progress__header-title">Progress</div>
            {/* <div className="progress__header-add-result-btn" onClick={this.handleAddResult}>
              <PlusIcon />
              Add Result
            </div>
            {showAddResultPopup && (
              <AddResultPopup
                isOpen
                key="add_new"
                timezone={timezone}
                dateFormat={dateFormat}
                metric={selectedMetric}
                metricTypes={metricTypes}
                onClose={this.handleCloseAddResultPopup}
                onSave={this.handleSaveResultClick}
                isManualStep={true}
              />
            )} */}
            <AddResultPopup
              newUI
              isOpen={showAddResultPopup}
              key={`add_new_${showAddResultPopup ? 'open' : 'close'}`}
              timezone={timezone}
              dateFormat={dateFormat}
              metric={selectedMetric}
              metricTypes={metricTypes}
              onClose={this.handleCloseAddResultPopup}
              onSave={this.handleSaveResultClick}
              onOpen={this.handleAddResult}
              isManualStep={true}
              trigger={
                <div
                  className={classNames('progress__header-add-result-btn', { active: showAddResultPopup })}
                  onClick={e => e.preventDefault()}
                >
                  <PlusIcon />
                  Add Result
                </div>
              }
            />
          </div>
          {newResults.length > 0 ? (
            <div className="progress__body">
              <Table singleLine className="progress-table-head normal-metric-head normal-metric-head-all">
                <Table.Header className="progress-table__head">
                  <Table.Row>
                    <Table.HeaderCell>DATE</Table.HeaderCell>
                    <Table.HeaderCell className="head-time">TIME</Table.HeaderCell>
                    <Table.HeaderCell className="result">RESULTS</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </Table>
              <div className="result-list" onScroll={this.handleScrollResult}>
                <Table singleLine className="progress-table__content normal-metric-content">
                  <Table.Body>{newResults.map(this.renderEntryItem)}</Table.Body>
                </Table>
              </div>
              {/* {this.renderEditPopup()} */}
            </div>
          ) : (
            <>{!loading && <p className="no-result">No entries yet</p>}</>
          )}
        </div>
      </div>
    );
  }
}
