import React from 'react';
import { debounce, get } from 'lodash';
import { SOCKET_EVENTS, TASK_REMINDER_NOTIFICATION_TOAST_OPTIONS } from './constants';
import { refreshToken, updatePermission } from 'redux/permission/actions';
import {
  getRepeatTasksSuccess,
  changeTaskCalendarStartDate,
  removeTasksRepeatingSuccess,
} from 'redux/task/task.actionCreators';
import { updatePaymentStatus } from 'redux/payment/actions';
import { syncMyFitnessPal, finishSyncMyFitnessPal } from 'redux/macros/actions';
import { showForumIndicator, showForumIndicatorByNewComment } from 'redux/forum/actions';
import { isAutoflowTrainingCalendarPage } from 'utils/validations';
import {
  getAutoflowIntervalListWorkouts,
  socketAutoflowIntervalWorkoutAdded,
} from 'redux/autoflowInterval/training/actions';
import NotificationMessage from 'components/TaskReminder/component/NotificationMessage';
import { toast } from 'react-toastify';
import { markAsRead } from 'redux/personal-task-reminder/action';
import {
  createdTaskAutoflowExactDateSuccess,
  removedTaskAutoflowExactDateSuccess,
  getListTasks,
} from 'redux/autoflow/task/actions';
import {
  createdTaskAutoflowIntervalSuccess,
  getListTasks as getListTasksInterval,
  removedTaskAutoflowIntervalSuccess,
} from 'redux/autoflowInterval/task/actions';
import {
  toggleNewWaitingClientFlag,
  getWaitingClients,
  markSeenWaitingList,
} from 'redux/waiting-activation-clients/actions';
import { getAllSegments } from 'redux/segment/actions';
import { newNotification } from 'redux/notification/actions';
import {
  socketListenToStudioProgramLastEditBy,
  socketStudioProgramWorkoutAdded,
  socketStudioProgramWorkoutDeleted,
  assignProgramToStudioProgramSuccess,
} from 'redux/studio-program/actions';
import { updateNewWebVersionSuccess } from 'reducers/home';
import { socketHabitUpdated } from 'redux/task/task.actionCreators';
import { socketNewAssignment } from 'redux/calendar/calendar.actionCreators';
import { socketAutoflowTrainingWorkoutAdded } from 'redux/autoflow/training/copy-remove-week-workout.actions';
import { socketProgramWorkoutAdded } from 'redux/program_library/program_library.actionCreators';
import { newFormAnswerComment } from 'redux/form-responses/actions';
import { getWorkoutSummaries } from 'redux/workout-completion/actions';
import { getDetailMealPlan } from 'redux/meal-plans/actions';
import { updateClientMealPlanByDay } from 'redux/client-meal-plan/actions';
import { getUserIdVerifySuccess } from 'actions/auth/login';

function assignProgramToAutoflowIntervalDaySuccess(dispatch, data) {
  if (isAutoflowTrainingCalendarPage(data.autoflow)) {
    dispatch(getAutoflowIntervalListWorkouts());
  }
}

const __assignProgramToAutoflowIntervalDaySuccess = debounce(assignProgramToAutoflowIntervalDaySuccess, 1500);

const __getListWorkoutsStudioProgram = debounce(assignProgramToStudioProgramSuccess, 1000);

export default (socket, dispatch) => {
  socket.on(SOCKET_EVENTS.TeamPlanUpdate, data => {
    dispatch(updatePermission(data));
    dispatch(refreshToken(data));
  });
  socket.on(SOCKET_EVENTS.MyFitnessPalStartedSyncing, data => {
    dispatch(
      syncMyFitnessPal({
        [data.clientId]: { ...data, status: 'syncing' },
      }),
    );
  });
  socket.on(SOCKET_EVENTS.CronometerStartedSyncing, data => {
    dispatch(
      syncMyFitnessPal({
        [data.clientId]: { ...data, status: 'syncing' },
      }),
    );
  });
  socket.on(SOCKET_EVENTS.MyFitnessPalSynced, data => {
    dispatch(
      finishSyncMyFitnessPal({
        [data.clientId]: { ...data, status: 'synced' },
      }),
    );
  });
  socket.on(SOCKET_EVENTS.CronometerSynced, data => {
    dispatch(
      finishSyncMyFitnessPal({
        [data.clientId]: { ...data, status: 'synced' },
      }),
    );
  });
  socket.on(SOCKET_EVENTS.CreatedRepeatTasksSuccess, data => {
    dispatch(getRepeatTasksSuccess(data));
  });
  socket.on(SOCKET_EVENTS.CreatedRepeatTaskDone, () => {
    dispatch(changeTaskCalendarStartDate());
  });

  socket.on(SOCKET_EVENTS.RemovedRepeatTaskSuccess, data => {
    dispatch(removeTasksRepeatingSuccess(data));
  });

  socket.on(SOCKET_EVENTS.CreatedRepeatTasksAutoflowExactDateSuccess, data => {
    dispatch(createdTaskAutoflowExactDateSuccess(data));
  });

  socket.on(SOCKET_EVENTS.CreatedRepeatTasksAutoflowExactDateDone, () => {
    dispatch(getListTasks());
  });

  socket.on(SOCKET_EVENTS.RemovedRepeatTasksAutoflowExactDateDone, data => {
    dispatch(removedTaskAutoflowExactDateSuccess(data));
  });

  socket.on(SOCKET_EVENTS.CreatedRepeatTasksAutoflowIntervalSuccess, data => {
    dispatch(createdTaskAutoflowIntervalSuccess(data));
  });

  socket.on(SOCKET_EVENTS.RemovedRepeatTasksAutoflowIntervalSuccess, data => {
    dispatch(removedTaskAutoflowIntervalSuccess(data));
  });

  socket.on(SOCKET_EVENTS.CreatedRepeatTasksAutoflowIntervalDone, () => {
    dispatch(getListTasksInterval());
  });

  socket.on(SOCKET_EVENTS.AutoflowIntervalAssignProgramToDay, data => {
    __assignProgramToAutoflowIntervalDaySuccess(dispatch, data);
  });
  socket.on(SOCKET_EVENTS.PersonalTaskNotification, data => {
    toast(<NotificationMessage task={data} />, TASK_REMINDER_NOTIFICATION_TOAST_OPTIONS);
    dispatch(markAsRead(data._id));
  });

  socket.on(SOCKET_EVENTS.NewNotification, data => {
    dispatch(newNotification());
  });

  socket.on(SOCKET_EVENTS.NewWaitingActivateClient, data => {
    dispatch(getAllSegments());

    if (window.location.pathname.includes('/home/client/waiting-activation')) {
      dispatch(getWaitingClients());
      dispatch(markSeenWaitingList());
    } else {
      dispatch(toggleNewWaitingClientFlag(true));
    }
  });
  socket.on(SOCKET_EVENTS.NotifyForumIndicator, data => {
    dispatch(showForumIndicator(data));
    dispatch(showForumIndicatorByNewComment(data));
  });
  socket.on(SOCKET_EVENTS.UpdateLastEditStudioChange, data => {
    if (data.programId) {
      dispatch(socketListenToStudioProgramLastEditBy(data, data.programId));
    }
  });
  socket.on(SOCKET_EVENTS.AssignedProgramToStudioProgram, data => {
    __getListWorkoutsStudioProgram(dispatch, data);
  });
  socket.on(SOCKET_EVENTS.NewWebVersionReleased, data => {
    dispatch(updateNewWebVersionSuccess(data.liveVersion));
  });
  socket.on(SOCKET_EVENTS.HabitUpdated, data => {
    dispatch(socketHabitUpdated(data));
  });
  socket.on(SOCKET_EVENTS.NewAssignment, data => {
    dispatch(socketNewAssignment(data));
  });
  socket.on(SOCKET_EVENTS.AutoflowTrainingSocketWorkoutAdded, data => {
    dispatch(socketAutoflowTrainingWorkoutAdded(data));
  });
  socket.on(SOCKET_EVENTS.AutoflowIntervalSocketWorkoutAdded, data => {
    dispatch(socketAutoflowIntervalWorkoutAdded(data));
  });
  socket.on(SOCKET_EVENTS.ProgramWorkoutSocketWorkoutAdded, data => {
    dispatch(socketProgramWorkoutAdded(data));
  });
  socket.on(SOCKET_EVENTS.StudioProgramSocketWorkoutAdded, data => {
    dispatch(socketStudioProgramWorkoutAdded(data));
  });
  socket.on(SOCKET_EVENTS.StudioProgramSocketWorkoutDeleted, data => {
    dispatch(socketStudioProgramWorkoutDeleted(data));
  });
  socket.on(SOCKET_EVENTS.NewComment, data => {
    if (data.topic === 'form_answer') {
      dispatch(newFormAnswerComment(data));
    }
  });
  socket.on(SOCKET_EVENTS.WorkoutSummaryInitialed, data => {
    dispatch(getWorkoutSummaries(data, true));
  });
  socket.on(SOCKET_EVENTS.MealPlanIobFinished, data => {
    const mealPlanId = get(data, 'meal_plan._id', '');
    const weekId = get(data, 'meal_plan.weeks.[0]', '');
    dispatch(getDetailMealPlan(mealPlanId, weekId, true));
  });
  socket.on(SOCKET_EVENTS.MealClientUpdated, data => {
    const dataMealClient = get(data, 'meal_client', {});
    dispatch(updateClientMealPlanByDay(dataMealClient));
  });
  socket.on(SOCKET_EVENTS.TrainerEmailVerified, data => {
    dispatch(getUserIdVerifySuccess(get(data, 'trainer_id')));
  });
};
