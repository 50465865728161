import styled, { css } from 'styled-components';
import { ellipsis } from 'polished';

export const PostAuthor = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0px;
  left: 0px;
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;

  .author__avatar {
    margin-right: 15px;
    .author__avatar {
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .author__name {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #000000;

    .date {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      cursor: pointer;
      display: inline-block;

      svg {
        margin-right: 8px;
      }

      > * {
        vertical-align: middle;
      }
    }
  }
`;

export const PostCaption = styled.div`
  ${({ showMore }) => showMore && ellipsis(undefined, 4)}
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  white-space: pre-line;
  word-break: break-word;
`;

export const PostInfo = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const Liked = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;

  .name {
    font-weight: bold;
  }
`;

export const Comments = styled.div`
  margin-bottom: auto;
  padding: 5px 5px 5px 0;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Content = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: overlay;
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - ${props => (props.initHeightCommentList ? `${props.initHeightCommentList}px` : '0')});
  overflow-y: scroll;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  font-family: 'Open Sans';
  color: #000;
  width: 100%;
  .interactions {
    padding: 5px 0;
    border-top: 1px solid #dde2ed;
    border-bottom: 1px solid #dde2ed;
    margin-bottom: 15px;
  }

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: ${props => (props.initTopHeader ? `-${props.initTopHeader}px` : '0')};
  left: 0px;
  z-index: 999;
  background-color: #fff;
`;

export const ShowMoreText = styled.a`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #828282;
  margin-top: 15px;
  display: inline-block;
  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1 1 0%;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 0 15px;
`;

export const HighFiveMessage = styled.div`
  margin-bottom: 19px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  span {
    font-weight: 700;
  }
  .others-name:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .__react_component_tooltip.high-five-tooltip {
    background: #2d2e2d;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
    p:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  .__react_component_tooltip.type-dark.place-bottom:after {
    border-bottom-width: 3px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    top: -3px;
    left: 53%;
    margin-left: -8px;
  }
`;

export const HighFiveWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  right: 15px;
  bottom: calc(100% + 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
`;

export const AvatarContainer = styled.div`
  display: block;
  position: relative;
  width: ${props => (props.twoAvatars ? '35px' : '')};
  margin-right: 5px;
  height: 20px;
  > * {
    &:nth-child(2) {
      img,
      .sb-avatar__text {
        position: absolute;
        left: 15px;
        top: 0;
        display: inline-block;
        box-shadow: 0 0 0 3px white;
      }
    }
  }
`;
