// Libs
import React from 'react';
import classnames from 'classnames';
import Avatar from 'react-avatar';

// Shared
import FileUpload from 'shared/FileUpload';
import MediaPlayer from 'shared/MediaPlayer';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import S3Video from 'shared/S3Video';
import { SharedTooltip } from 'shared/SharedTooltip';

// Constants
import { MESSAGE_TYPES } from 'components/InAppMessage/constants';
import { parseTextAutoMessages } from 'utils/commonFunction';

// Styles
import * as S from './style';

function MessageItem(props) {
  const { message, avatarData, disabled, onRemoveMessage, sent, index } = props;
  const { uploadConfigs, attachment, attachment_thumbnail, attachment_type, type, objectURL } = message;
  const src = objectURL || attachment;
  const isVideo = attachment_type && attachment_type.startsWith('video');
  const thumbnail = type === MESSAGE_TYPES.FILE ? attachment_thumbnail || (!isVideo ? src : null) : null;

  const onUploadSuccess = response => {
    const { data } = response;
    props.onUploadSuccess({ mId: message._id, data });
  };

  const onCancelUpload = () => {
    props.onCancelUpload({ mId: message._id });
  };

  const renderText = text => {
    const parsedData = parseTextAutoMessages(text);

    return (
      <div>
        {parsedData.map((item, index) => {
          return item.highlight ? <mark key={index}>{item.content}</mark> : item.content;
        })}
      </div>
    );
  };

  return (
    <S.Wrapper className="messageItem">
      <S.MessageContainer className={classnames('messageItem__container', { disabled: disabled })}>
        {message.type === MESSAGE_TYPES.FILE ? (
          <S.FileMessage>
            {thumbnail ? (
              <S3ImageWithFallback src={[thumbnail]} />
            ) : isVideo ? (
              <S3Video videoLink={src} videoType={attachment_type} />
            ) : null}
            {uploadConfigs ? (
              <FileUpload
                onSuccess={onUploadSuccess}
                onError={() => {}}
                onCancel={onCancelUpload}
                configs={message.uploadConfigs}
              />
            ) : (
              <MediaPlayer url={src} type={attachment_type} thumbnail={thumbnail} />
            )}
          </S.FileMessage>
        ) : (
          <S.TextMessage className="messageItem__messageContent messageItem__messageContent--text">
            {renderText(message.content)}
          </S.TextMessage>
        )}
        {!sent && !disabled ? (
          <>
            <S.RemoveIcon onClick={() => onRemoveMessage(index)} data-for="remove-message-item-tooltip" data-tip />
            <SharedTooltip id="remove-message-item-tooltip" content="Remove" />
          </>
        ) : null}
      </S.MessageContainer>
      <Avatar className="messageItem__avatar" size="24" {...avatarData} />
    </S.Wrapper>
  );
}

export default MessageItem;
