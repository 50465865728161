import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import * as S from './styles';
import Select from 'react-select';
import { DropdownIndicator } from 'shared/Icons';
import { PACKAGE_RECURRING_TYPES } from 'constants/commonData';
import { validatePositiveIntegerNumberOnly } from 'utils/validations';

export const OPTIONS = _.map(PACKAGE_RECURRING_TYPES, obj => obj);

const initDefaultCurrency = origin => {
  const obj = OPTIONS.find(item => item.value === origin) || OPTIONS[0];

  return { ...obj };
};

export default ({ data, showError, onChange, onChangeEveryType, errorField }) => {
  const [type, setType] = useState(initDefaultCurrency(data.recurring_interval_type));
  const [value, setValue] = useState(data.every || '');

  const errorMessage = useMemo(() => {
    if (!showError || !errorField) {
      return '';
    }

    const number = _.toNumber(value);

    if (!number) {
      return 'Minimum value is 1';
    }

    if (number > 12) {
      return 'Maximum value is 12';
    }

    return '';
  }, [showError, value, errorField]);

  const handleTypeChange = newValue => {
    setType(newValue);
    onChangeEveryType({ recurring_interval_type: newValue.value, every: _.toNumber(value) });
  };

  const handleValueChange = useCallback(
    event => {
      setValue(event.value);
      onChange({ recurring_interval_type: type.value, every: parseInt(event.value) });
    },
    [value, type],
  );

  return (
    <S.Wrapper>
      <S.Container invalidValue={errorMessage}>
        <S.Input
          value={value}
          decimalScale={0}
          onKeyPress={validatePositiveIntegerNumberOnly}
          inputmode="numeric"
          onValueChange={handleValueChange}
          isNumericString={true}
        />
        <Select
          options={OPTIONS}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          onChange={handleTypeChange}
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          value={type}
          isSearchable={false}
          menuPosition="fixed"
          styles={{
            control: base => ({ ...base, minHeight: 42, height: 42, minWidth: 141 }),
          }}
        />
      </S.Container>
      {!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.Wrapper>
  );
};
