import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { validateEmail } from 'utils/validations';
import { InviteInputWrapper, EmailInput, CongratReferralToast } from './style';
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import { Button } from 'shared/FormControl';
import { axiosInstance } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

class InviteInput extends React.Component {
  constructor () {
    super();
    this.state = { tags: [], isSubmitting: false };
  }

  handleChange = (tags) => {
    this.setState({ tags, showError: false });
  }

  onChangeInput = () => {
    if (this.state.showError) {
      this.setState({ showError: false })
    }
  }

  renderTag = (props) => {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, className, ...other} = props;
    const isValid = validateEmail(tag);
    return (
      <span key={key} {...other} className={classNames(className, { 'invalid': !isValid })}>
        {getTagDisplayValue(tag)}
        {!disabled &&
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        }
      </span>
    )
  }

  onSubmit = (props) => {
    const { tags } = this.state;
    const validEmails = tags.filter(tag => validateEmail(tag));
    if (!validEmails.length) {
      this.setState({ showError: true });
    } else {
      this.setState({ isSubmitting: true });
      axiosInstance.post('/api/referral/send-invitation', { emails: validEmails }).then((res) => {
        this.setState({ tags: [], isSubmitting: false });
        toast((
          <CongratReferralToast>
            <img src={`${CDN_URL}/images/congrat_referral.png`} alt="" />
            <span>'Invite sent! We’ll notify you when you earn credits!'</span>
          </CongratReferralToast>
        ), { position: 'top-center'});
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
    }
  }

  render () {
    const { showError, isSubmitting } = this.state;
    const REGEX = /[^,;]/i;
    return (
      <InviteInputWrapper showError={showError}>
        <EmailInput
          value={this.state.tags}
          disabled={isSubmitting}
          addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
          onlyUnique
          addOnPaste
          addOnBlur
          validationRegex={REGEX}
          renderTag={this.renderTag}
          inputProps={{
            placeholder: 'Enter your friend’s emails',
            onChange: this.onChangeInput
          }}
          pasteSplit={data => {
            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
          }}
          onChange={this.handleChange}
        />
        <Button disabled={isSubmitting} onClick={this.onSubmit} purple className="inviteInput__sendBtn">
          {isSubmitting ? 'Sending' : 'Send'}
        </Button>
      </InviteInputWrapper>
    )
  }
}

export default InviteInput;
