// libs
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button as CloseButton } from 'semantic-ui-react';
import classNames from 'classnames';

// Shared
import { Button } from 'shared/FormControl';
import RenderOwnShip from 'components/Forms/components/AddNewForm/RenderOwnShip';

// Constatns
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import * as S from './styles';

const ModalShareOwner = props => {
  const {
    isHideCloseButton = false,
    isShowCancelButton = false,
    elementModal = null,
    toggleModal,
    onSubmit,
    type,
    user,
    workingItem,
    headerTitle,
    disabled = false,
    isOnboardingFlow = false,
  } = props;
  const [ownerValue, setOwnerValue] = useState({});
  const [shareValue, setShareValue] = useState({ share: TEAM_SHARE_PRIVATE });

  useEffect(() => {
    setOwnerValue({
      owner: user._id,
    });
  }, [user]);

  useEffect(() => {
    if (workingItem) {
      const key = isOnboardingFlow ? 'author' : 'owner';
      setOwnerValue({
        owner: get(workingItem, `${key}._id`, '') || get(workingItem, key, ''),
      });
      setShareValue({
        share: workingItem.share,
      });
    }
  }, [workingItem, type]);

  const onSetShareValue = value => {
    setShareValue(value);
  };

  const onSetOwnerValue = value => {
    setOwnerValue(value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    let params = {
      [isOnboardingFlow ? 'onboardingFlowId' : 'taskLibraryId']: get(workingItem, '_id', null),
      owner: get(ownerValue, 'owner', ''),
      ...shareValue,
    };

    if (onSubmit) {
      onSubmit && onSubmit(params);
    }
  };

  const onClose = () => {
    toggleModal && toggleModal(false);
  };

  const Modal = elementModal ? elementModal : S.ModalShare;

  return (
    <Modal
      open={true}
      closeIcon={
        !isHideCloseButton && (
          <CloseButton className="close-button" onClick={onClose}>
            <img src={CloseIcon} alt="button-close" />
          </CloseButton>
        )
      }
      className="tasks-share-owner"
    >
      <Modal.Header>{headerTitle || 'Sharing settings'}</Modal.Header>
      <Modal.Content>
        <S.Form onSubmit={onSubmit}>
          <RenderOwnShip
            ownerValue={ownerValue}
            shareValue={shareValue}
            onSetShareValue={onSetShareValue}
            onSetOwnerValue={onSetOwnerValue}
            userId={get(user, '_id', '')}
            type={type}
            hasAvatar
            hasSpacing
            hasYou
          />
        </S.Form>
        <S.Footer>
          {isShowCancelButton && (
            <Button className="cancel" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button className={classNames('submit')} onClick={event => handleSubmit(event)} purple disabled={disabled}>
            Save
          </Button>
        </S.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ModalShareOwner;
