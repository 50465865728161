import styled, { css } from 'styled-components';

export const Input = styled.div`
  padding: 0;
  outline: none;
  box-shadow: none;
  height: 100%;
  overflow: auto;

  ol {
    padding: 0;
    border: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    list-style: none;
    counter-reset: instruction-item;

    li {
      margin-bottom: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #202353;
      outline: none;
      margin-bottom: 15px;
      counter-increment: instruction-item;
      padding-left: 23px;
      position: relative;
      display: block;

      &:first-child:has(br) {
        :after {
          font-style: normal;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          color: #000;
          opacity: 0.3;
          content: 'Add first step for the exercise';
          position: absolute;
          top: 3px;
          left: 0;
          padding-left: 23px;
        }
      }

      :before {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #728096;
        opacity: 0.56;
        content: counter(instruction-item) '.';
        position: absolute;
        top: 3px;
        left: 0;
        text-align: right;
        width: 20px;
      }
    }
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #969fb5;
    margin: 0;
  }
`;

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 8px 14px 14px;
  cursor: text;
  overflow: hidden;
  height: 275px;

  ${props =>
    props.isFocused &&
    css`
      border-color: #5158cf;
    `}

  ${props =>
    !props.isFocused &&
    props.empty &&
    css`
      height: auto;
      padding-top: 9px;
      padding-bottom: 9px;
    `}
`;
