import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 50px 0;
  width: 969px;
  margin: 0 auto;

  .packageActions__shareBtn {
    padding: 6px 30px;
    color: #818da1;

    svg {
      margin-right: 5px;
      height: 12px;
      width: 12px;

      path {
        fill: #818da1;
      }
    }
  }

  .packageDetail__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .packageDetail__nav-menu {
    margin-top: 0;

    .packageDetail__nav-menu__item {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding-bottom: 9px;
      color: #858585;
      position: relative;
      margin: 0 18px;
      text-decoration: none !important;

      :first-child {
        margin-left: 0;
      }

      ::after {
        content: '';
        position: absolute;
        display: none;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #5158cf;
        left: 0;
      }

      &.active {
        color: #5158cf;

        ::after {
          display: block;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  padding-bottom: 20px;
  ${props => props.background && `background: ${props.background}`}
`;

export const HeaderNote = styled.div`
  background: #ebedf4;
  padding: 13px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
`;

export const WarningContent = styled.span`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ResendRequest = styled.span`
  color: #5559ff;
  cursor: pointer;
  &:hover {
    color: #5559ff;
  }
  ${props =>
    props.isResent &&
    css`
      color: #858585;
      cursor: not-allowed;
      &:hover {
        color: #858585;
      }
    `}
`;
