import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Layout from '../Calendar';
import {
  getListIntervalMessages,
  arrangeIntervalMessage,
  moveIntervalMessage,
  resetIntervalMessageCopyItem,
  resetIntervalMessageData,
} from 'redux/autoflowInterval/auto-message/actions';

const TrainingCalendar = ({
  copying,
  autoflowId,
  getListIntervalMessages,
  moveIntervalMessage,
  arrangeIntervalMessage,
  resetIntervalMessageCopyItem,
  resetIntervalMessageData,
  ...props
}) => {
  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    if (!source || !destination || !draggableId || _.isEqual(source, destination)) {
      return;
    }

    const [oldWeekIndex, oldDayIndex, messageId] = draggableId.split(';');
    const [newWeekIndex, newDayIndex] = destination.droppableId.split('_');
    const newIndex = destination.index;
    const oldIndex = source.index;

    if (source.droppableId === destination.droppableId) {
      const bodyData = { dayIndex: oldDayIndex, weekIndex: oldWeekIndex, autoflowId, oldIndex, newIndex, messageId };
      arrangeIntervalMessage(bodyData);
    } else {
      const bodyData = {
        newWeekIndex,
        newDayIndex,
        oldWeekIndex,
        oldDayIndex,
        oldIndex,
        newIndex,
        autoflowId,
        messageId,
      };
      moveIntervalMessage(bodyData);
    }
  };

  return (
    <Layout
      {...props}
      copying={copying}
      onDragEnd={onDragEnd}
      onDidMount={getListIntervalMessages}
      resetCopyItem={resetIntervalMessageCopyItem}
      onStartWeekChange={getListIntervalMessages}
      onViewModeChange={getListIntervalMessages}
      onUpdateWeeks={getListIntervalMessages}
      onUnmount={resetIntervalMessageData}
    />
  );
};

const mapState = state => {
  const {
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
      autoflowInterval: { autoMessage },
    },
  } = state;

  return { copying: autoMessage.get('copying'), autoflowId: _.get(workingAutoflow, '_id') };
};

const actionCreators = {
  getListIntervalMessages,
  moveIntervalMessage,
  arrangeIntervalMessage,
  resetIntervalMessageCopyItem,
  resetIntervalMessageData,
};

export default connect(mapState, actionCreators)(TrainingCalendar);
