import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  padding-top: 23px;
  cursor: pointer;
  color: #758094;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .text-content {
    margin-left: 2px;
  }

  .toggle-trial {
    label {
      margin-bottom: 0;
    }
  }

  .toggle-input {
    margin-right: 15px;
    margin-left: 1px;
    font-size: 12px;

    input {
      ~ label {
        ::before {
          background-color: #5158cf;
        }
      }
    }

    input:checked ~ label::before,
    input:checked:focus ~ label::before {
      background-color: #5158cf;
    }

    :not(.checked) {
      color: #728096;

      input {
        ~ label {
          ::before {
            background-color: #e4e5e7;
          }
        }
      }
    }

    .text-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-transform: none;
      margin-bottom: 0;
      padding-top: 2px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202253;

      svg {
        width: 12px;
        height: 12px;
        margin-left: 5px;

        :not(:hover) {
          path {
            fill: #a6acc6;
          }
        }

        :hover {
          path {
            fill: #5158cf;
          }
        }
      }
    }
  }

  .__react_component_tooltip.app-tooltip {
    background-color: rgba(45, 46, 45, 1);
    padding: 15px;
    font-size: 13px;
    max-width: 275px;
    height: 110px;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-align: left;
  }

  .toggle-trial {
    margin-top: 26px !important;
  }

  .app-tooltip svg:not(:hover) path {
    fill: #5c5bbd !important;
  }
`;

export const Wrapper = styled.div`
  width: 479px;
  height: 136px;
  border-top: 1px solid #f2f2f2;
  margin-top: 23px;
  margin-bottom: 0;
  .evfSelectBoxContainer .evfSelectBox__menu {
    margin-top: 4px;
    height: 136px !important;
    border-bottom: none;
  }

  .evfSelectBox__menu-list {
    padding: 0 !important;
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .evfSelectBoxContainer .evfSelectBox__option.evfSelectBox__option--is-selected {
    color: #5e59ff;
  }

  .evfSelectBoxContainer .evfSelectBox__option.evfSelectBox__option--is-focused {
    color: #5e59ff;
  }

  .evfSelectBoxContainer .evfSelectBox__control.evfSelectBox__control--menu-is-open {
    border: 1px solid #6456c4 !important;
  }
`;

export const Notice = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #858196;

  margin-top: 10px;
`;

export const LabelText = styled.div`
  margin: 0 8px 0 5px;
  padding-top: 2px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #758094;
`;

export const SelectWrapper = styled.div`
  position: relative;
  .evfSelectBoxContainer {
    position: absolute;
    top: -6px;
    left: 2px;
  }
`;

export const ArchiveClient = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .archive-client-checkbox {
    margin-bottom: 0;
    .checkbox-icon {
      width: 16px;
      height: 16px;
      background-size: 10px;
      border-radius: 4px;
    }
  }
`;

export const ArchiveClientDesc = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-left: 7px;
  color: #202353;
  :hover {
    cursor: pointer;
  }
`;

export const TrialSettingContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;
