import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/asset-user-count.svg';
import { get, reduce } from 'lodash';

const countNumberOfResource = collection => {
  return reduce(
    collection.sections,
    (sum, section) => {
      return sum + section.resources.length;
    },
    0,
  );
};

export default function ResourceCollectionOption({ data }) {
  const number_of_clients = get(data, 'number_of_clients', 0);
  return (
    <div className="asset-content">
      <div className="resource-info-container">
        <div className="title">{data.name}</div>
        <div className="clients-count-badge">
          <UserIcon />
          {number_of_clients}
        </div>
      </div>
      <div className="asset-info">Resources: {countNumberOfResource(data)}</div>
    </div>
  );
}
