import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import * as S from './style';
import { pluralize } from 'utils/commonFunction';
import CollectionDropdownOptions from '../CollectionDropdownOptions';
import { ShareOwnerAvatar } from 'shared/AssetsShareSetting';
import { TEAM_SHARE_SHARED } from 'constants/commonData';

const countNumberOfResource = collection => {
  return _.reduce(
    collection.sections,
    (sum, section) => {
      return sum + section.resources.length;
    },
    0,
  );
};

export default function CollectionItem(props) {
  const { collection, cloudfrontList, user } = props;
  const author = typeof collection.author_info === 'object' ? collection.author_info : null;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isShare = collection.share === TEAM_SHARE_SHARED;

  return (
    <S.Wrapper onClick={() => props.onGoDetail(collection)}>
      <div className="collectionHeading">
        <div className="collectionHeading__icon" />
        <div className="collectionHeading__name">{collection.name}</div>
        <CollectionDropdownOptions studioCollection={collection} className="collectionInfo__options" />
      </div>
      <div className={classNames('collectionInfo', { 'share-owner-avatar': true })}>
        <div>
          <div className="collectionInfo__item">
            <span>Available for </span>
            <b>
              {collection.number_of_clients} {pluralize('client', collection.number_of_clients)}
            </b>
          </div>
          <div className="collectionInfo__item">
            <span>{pluralize('Resource', countNumberOfResource(collection))}:&nbsp;</span>
            <b>{countNumberOfResource(collection)}</b>
          </div>
        </div>
        {author && !isCreator && isShare && (
          <ShareOwnerAvatar
            user={author}
            tooltipId={`avatar-tooltip-${collection._id}`}
            cloudfrontList={cloudfrontList}
          />
        )}
      </div>
    </S.Wrapper>
  );
}
