import React, { useEffect, useState } from 'react';

import UpgradePathOnboardingFlowImg from 'assets/images/onboarding_flow_upgrade_path_banner.png';
import { PLANS } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';

import * as S from '../style';

export default function OnboardingFlowFallback(props) {
  const { user, paymentOverdue, zapierOverdue, addOnsOverdue, paymentWhiteLabelOverdue } = props || {};
  const { is_verified_email, force_send_verification_email_at } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 50);
    // reset index state
    window.history.replaceState({}, '', '/home/onboarding-flow');
  }, []);

  const banner = {
    imageSrc: UpgradePathOnboardingFlowImg,
    title: 'Streamlined Onboarding Experience',
    description: `Automate the training program for new clients by smoothly integrating the onboarding assets and assignments.`,
    imageOrder: 'onboarding-flow-bg',
  };

  const planWillSelect = { tier: PLANS.pro.key };

  if (!loaded) {
    return <></>;
  }
  return (
    <S.OnboardingFlowFallbackWrapper>
      <S.Container>
        <S.SectionOnboardingFlow>
          <S.OnboardingFlowUpgradeContainer>
            <div>
              <S.BodyAutoflow>
                <S.OnboardingFlowUpgradePathBackground
                  className={banner.imageOrder}
                  isHasAlertHeader={isWhiteLabelOverdue || isShowOverdue || isVerifyEmail}
                >
                  <img src={banner.imageSrc} alt="" />
                </S.OnboardingFlowUpgradePathBackground>
                <S.TitleOnboardingFlow className="shift-up">{banner.title}</S.TitleOnboardingFlow>
                <S.DescriptionOnboardingFlow className="shift-up">{banner.description}</S.DescriptionOnboardingFlow>
                {banner.learnMore && banner.learnMoreURL && (
                  <S.LearnMore
                    dangerouslySetInnerHTML={{ __html: banner.learnMore }}
                    className="shift-up"
                    href={banner.learnMoreURL}
                    target="_blank"
                  ></S.LearnMore>
                )}
                <S.UpgradePathAutoflowButton
                  className="upgrade-path-button"
                  text="Get Onboarding Flow"
                  planWillSelect={planWillSelect}
                  isOnboardingFlow={true}
                />
              </S.BodyAutoflow>
            </div>
          </S.OnboardingFlowUpgradeContainer>
        </S.SectionOnboardingFlow>
      </S.Container>
    </S.OnboardingFlowFallbackWrapper>
  );
}
