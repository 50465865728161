import React from 'react';
import ReactTooltip from 'react-tooltip';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { ReactComponent as CopyIcon } from 'assets/icons/copy-calendar.svg';
import { checkSameMonth } from 'utils/commonFunction';
import { CLASSNAME_TOAST } from 'constants/commonData';

function ButtonCopyWeek(props) {
  const { index, calendarStartDate, handleSelectedWeek } = props;
  function handleCopyWeek() {
    const day = DateTime.fromISO(calendarStartDate.toISODate());
    const copyFromDate = day.plus({ days: 7 * index });
    const copyToDate = day.plus({ days: 7 * index + 6 });
    const isSameMonth = checkSameMonth(copyFromDate, copyToDate);
    const copyFromDateFormat = copyFromDate.toFormat('LLL d');
    const copyToDateFormat = copyToDate.toFormat(isSameMonth ? 'd' : 'LLL d');
    handleSelectedWeek &&
      handleSelectedWeek({
        from: copyFromDate,
        to: copyToDate,
      });
    toast(`Week of ${copyFromDateFormat} - ${copyToDateFormat} copied. Click on week to paste workouts.`, {
      className: CLASSNAME_TOAST.TRAINING_CALENDAR,
    });
  }
  return (
    <>
      <CopyIcon data-tip data-for={`tooltip-copy-${index}`} onClick={handleCopyWeek} />
      <ReactTooltip className="app-tooltip" id={`tooltip-copy-${index}`} effect="solid" place="right">
        Copy Week
      </ReactTooltip>
    </>
  );
}

export default ButtonCopyWeek;
