import React, { useEffect, useMemo, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as TeammateBlack } from 'assets/icons/teammate_black.svg';
import { ReactComponent as TeammateGrey } from 'assets/icons/teammate_grey.svg';
import { ReactComponent as TeammatePurple } from 'assets/icons/teammate_purple.svg';

import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER } from 'constants/commonData';

import * as S from '../styles';
import { ASSETS_SHARE_SETTING_TYPE, NO_OWNER_SHARED_OPTION, generateShareStatus } from '../constants';
import ColumnOwnership from './ColumnOwnership';

const OwnershipPopupStudio = props => {
  const {
    position = 'bottom right',
    size = 32,
    owner = {},
    shareStatus,
    saveChangeOnClose,
    updateImmediately = false,
  } = props;

  const [open, setOpen] = useState(false);
  const [ownership, setOwnership] = useState();
  const [share, setShareStatus] = useState(shareStatus);

  const defaultOwnership = useMemo(() => {
    if (shareStatus === TEAM_SHARE_NOOWNER || owner === null) {
      return NO_OWNER_SHARED_OPTION.value;
    }
    return owner._id;
  }, []);

  useEffect(() => {
    setOwnership(defaultOwnership);
  }, []);

  const togglePopup = () => setOpen(it => !it);

  const handleOpenPopup = () => setOpen(true);

  const handleSave = data => {
    if (data && typeof saveChangeOnClose === 'function') {
      saveChangeOnClose(data);
    }
  };

  const handleClosePopup = () => {
    setOpen(false);

    const isChange = ownership !== defaultOwnership || share !== shareStatus;
    if (isChange) handleSave({ author: ownership, share });
  };

  const handleChangeOwnership = (option = {}) => {
    const ownershipValue = option.value || owner._id;
    const shareValue = generateShareStatus({ ownership: ownershipValue, shareStatus: share });
    setOwnership(ownershipValue);
    setShareStatus(shareValue);

    if (updateImmediately) handleSave({ author: ownershipValue, share: shareValue });
  };

  const handleChangeShareStatus = (option = {}) => {
    const shareValue = option.value || TEAM_SHARE_PRIVATE;
    setShareStatus(shareValue);

    if (updateImmediately) handleSave({ author: ownership, share: shareValue });
  };

  return (
    <S.CustomOwnershipPopup
      customSize={size !== 32}
      trigger={
        <S.TriggerIconPopup onClick={togglePopup} open={open} data-tip data-for="ownership-sharing-tooltip" size={size}>
          <TeammateGrey className="icon" />
          <TeammatePurple className="icon hover" />
          <ReactTooltip
            className="app-tooltip-white custom-arrow"
            id="ownership-sharing-tooltip"
            effect="solid"
            place="top"
          >
            <span className="tooltip-content">Ownership &amp; Sharing</span>
          </ReactTooltip>
        </S.TriggerIconPopup>
      }
      flowing={true}
      hoverable={true}
      on="click"
      position={position}
      open={open}
      onOpen={handleOpenPopup}
      className="ownership-popup"
    >
      <RootCloseWrapper onRootClose={handleClosePopup} event="click" disabled={!open}>
        <S.CustomOwnershipPopupContent>
          <div className="header">
            <TeammateBlack className="header-icon" />
            <span className="title">Ownership</span>
          </div>
          <ColumnOwnership
            {...props}
            type={ASSETS_SHARE_SETTING_TYPE.OWNERSHIP_POPUP}
            ownership={ownership}
            shareStatus={share}
            onChangeOwnership={handleChangeOwnership}
            onChangeShareStatus={handleChangeShareStatus}
          />
        </S.CustomOwnershipPopupContent>
      </RootCloseWrapper>
    </S.CustomOwnershipPopup>
  );
};

export default OwnershipPopupStudio;
