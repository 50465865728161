import styled from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';

export const ConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container.large-width {
    width: 520px !important;
  }

  &.ui.modal.confirm-popup-container.extra-large-width {
    width: 565px !important;
  }

  .confirm-content-header {
    padding-top: 30px !important;
  }
`;
