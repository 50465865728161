import styled from 'styled-components';

const baseText = `
  font-family: Open Sans;
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  color: #202353;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .item {
    height: 425px;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    img {
      width: 450px;
    }
    .content {
      max-width: 360px;
    }
    .title {
      ${baseText}
      margin-bottom: 10px;
    }
    .description {
      ${baseText}
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      color: #7b7e91;
      margin-bottom: 30px;
    }
    .get-plan-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 5px;
      height: 36px;
      padding: 0 50px;
      background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
      &:hover {
        background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
      }
      span {
        ${baseText}
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        color: #fff;
        text-transform: uppercase;
      }
    }
    .trial-btn {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;

      outline: none;
      margin-top: 10px;
      border: 1px solid #e1e1ea;
      background: #ffffff;
      border-radius: 5px;
      height: 36px;
      padding: 0 44.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: #f0f1ff;
        color: #5158cf;
      }
    }
    &.item-ingredient {
      flex-direction: column;
      gap: 0;
      img {
        width: 860px;
      }
      .content {
        max-width: 100%;
        text-align: center;
        margin-bottom: 40px;
      }
      .description {
        margin-bottom: 0;
      }
      .get-plan-btn {
        margin-top: 40px;
      }
    }
  }
  .slick-slider {
    .slick-dots {
      bottom: -30px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      li {
        button::before {
          content: none;
        }
        button {
          padding: 0;
          width: 10px;
          height: 10px;
        }
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #e1e1ea;
        &.slick-active {
          width: 10px;
          height: 10px;
          background: #5158cf;
        }
      }
    }
    .slick-arrow {
      width: 42px;
      height: 42px;
      box-shadow: 0px 2px 30px 0px #acacac33;
      border: 1px solid #e1e1ea;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      z-index: 1;
      top: calc(50% + -3px);
      cursor: pointer;
      &::before {
        content: none;
      }
      &.slick-prev {
        left: 62px;
        transform: rotate(-90deg) translateX(50%);
        svg {
          margin-bottom: 2px;
        }
      }
      &.slick-next {
        right: 63px;
        transform: rotate(90deg) translateX(-50%);
        svg {
          margin-bottom: 2px;
        }
      }
      &:hover {
        background: #f0f1ff;
      }
    }
  }
`;
