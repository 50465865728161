import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { Input, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';

class SearchInput extends React.Component<Props, State> {
  render() {
    const { value, onChange, placeholder, onAddOnIconClick, autoFocus, alwayShowDelete } = this.props;

    return (
      <Input
        {...this.props}
        className="search-input"
        autoFocus={autoFocus}
        fluid
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        icon={
          value || alwayShowDelete ? (
            <Image
              onClick={onAddOnIconClick}
              className="input-addon-icon"
              src={`${CDN_URL}/images/close_circle_grey.svg`}
              width="16"
              height="16"
            />
          ) : null
        }
      />
    );
  }
}

SearchInput.propTypes = {
  alwayShowDelete: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onAddOnIconClick: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default SearchInput;

const OtherInput = styled.input`
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 31px;
  background: url(${props => (props.searchIconPath ? props.searchIconPath : `${CDN_URL}/images/search_light.svg`)})
    no-repeat;
  background-size: auto;
  background-position: 15px center;
  background-color: #f8f8f8;
  border: 1px solid #f0f0f0;
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  font-family: Open Sans;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;

  :focus {
    background-color: #fff;
    border-color: #7072dd;
  }

  ::placeholder {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #777777;
    opacity: 0.5;
  }
`;

const ClearIcon = styled.svg`
  cursor: pointer;
  display: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: auto;

  input {
    width: 100%;
    height: 100%;
  }
`;

export const NewSearchInput = props => {
  const { alwayShowDelete, hideClear = false, searchIconPath, requestResetText } = props;
  const input = useRef();
  const clearIcon = useRef();

  useEffect(() => {
    if (requestResetText) {
      requestResetText.current = handleResetSearchText;
    }
  }, []);

  const handleResetSearchText = () => {
    clearIcon.current.style.display = 'none';
    input.current.value = '';
  };

  return (
    <Container className={classNames('search-input', props.className)}>
      <OtherInput
        {...omit(props, ['onClearSearch'])}
        className=""
        searchIconPath={searchIconPath}
        ref={input}
        defaultValue={props.value === undefined ? props.defaultValue || '' : undefined}
        onChange={event => {
          const { value } = event.target;
          typeof props.onChange === 'function' && props.onChange(event, { value });

          if (!hideClear) {
            if (props.value === undefined) {
              if (value) {
                clearIcon.current.style.display = 'block';
              } else {
                clearIcon.current.style.display = 'none';
              }
            }
          }
        }}
      />
      {!hideClear && (
        <ClearIcon
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={clearIcon}
          show={!!props.value || alwayShowDelete}
          onClick={event => {
            if (props.value === undefined) {
              clearIcon.current.style.display = 'none';
              input.current.value = '';
            }

            if (props.focusAfterClear && input.current) {
              typeof input.current.focus === 'function' && input.current.focus();
            }

            typeof props.onClearSearch === 'function' && props.onClearSearch(event);
          }}
        >
          <g clipPath="url(#clip0)">
            <path
              d="M6.5 0C5.21442 0 3.95772 0.381218 2.8888 1.09545C1.81988 1.80968 0.986756 2.82484 0.494786 4.01256C0.00281636 5.20028 -0.125905 6.50721 0.124899 7.76809C0.375703 9.02896 0.994768 10.1872 1.90381 11.0962C2.81285 12.0052 3.97104 12.6243 5.23192 12.8751C6.49279 13.1259 7.79973 12.9972 8.98745 12.5052C10.1752 12.0132 11.1903 11.1801 11.9046 10.1112C12.6188 9.04229 13 7.78558 13 6.5C12.995 4.77763 12.3086 3.12724 11.0907 1.90933C9.87277 0.691427 8.22237 0.00500173 6.5 0V0ZM9.18125 8.41533L8.41534 9.18125L6.5 7.26592L4.58467 9.18125L3.81875 8.41533L5.73409 6.5L3.81875 4.58467L4.58467 3.81875L6.5 5.73408L8.41534 3.81875L9.18125 4.58467L7.26592 6.5L9.18125 8.41533Z"
              fill="#D6D6D6"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="13" height="13" fill="white" />
            </clipPath>
          </defs>
        </ClearIcon>
      )}
    </Container>
  );
};

NewSearchInput.propTypes = {
  alwayShowDelete: PropTypes.bool,
  hideClear: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onAddOnIconClick: PropTypes.func,
  autoFocus: PropTypes.bool,
  classNames: PropTypes.string,
  defaultValue: PropTypes.string,
  searchIconPath: PropTypes.string,
  requestResetText: PropTypes.any,
};
