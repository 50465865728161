/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import OwnershipPopup from './component';

const mapStateToProps = state => {
  const { user } = state;
  return {
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OwnershipPopup));
