import React, { useEffect, useMemo, useRef } from 'react';
import { components } from 'react-select';
import get from 'lodash/get';

function CustomControl(props) {
  const scrollWrapperRef = useRef();
  const selectedValueLength = useMemo(() => props.getValue().length, [props]);

  useEffect(() => {
    if (scrollWrapperRef.current && selectedValueLength > 0) {
      const childrenHeight = get(scrollWrapperRef, 'current.children[0].offsetHeight', 0);
      scrollWrapperRef.current.scrollTo(0, childrenHeight);
    }
  }, [selectedValueLength]);

  return (
    <components.Control {...props}>
      <div className="scroll-container">
        <div className="scroll-wrapper" ref={scrollWrapperRef}>
          {props.children}
        </div>
      </div>
    </components.Control>
  );
}

export default CustomControl;
