import React, { useState, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { Wrapper, Trigger, TaskButton, MenuContainer, Header, Body, ButtonContainer, Item } from './styles';
import { img, Dropdown, Icon } from 'semantic-ui-react';
import { TaskList } from './component/TaskList';
import _ from 'lodash';

import { AddNewTask } from './component/AddNewTask';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left-black.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/task_bin.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task_reminder.svg';
import { LIST_OPTIONS } from './constants';
import { Button } from 'shared/FormControl';
import ReactTooltip from 'react-tooltip';
import { Mixpanel } from 'utils/mixplanel';
import { CLOSE_TASK_REMINDER_POP_UP, OPEN_TASK_REMINDER_POP_UP } from 'constants/commonData';

import { DragDropContext } from 'react-beautiful-dnd';
import { SharedTooltip } from 'shared/SharedTooltip';

const ModeView = ({
  doUnmarkAsCompleted,
  user,
  searchTrainers,
  followingList,
  doMarkAsCompleted,
  updateTask,
  addTask,
  completedList,
  list,
  task,
  setTaskId,
  mode,
  currentList,
  toCompletedList,
  handleBacktoList,
  toTaskDetail,
}) => {
  switch (mode) {
    case 0:
    default:
      return (
        <TaskList
          doUnmarkAsCompleted={doUnmarkAsCompleted}
          followingList={followingList}
          doMarkAsCompleted={doMarkAsCompleted}
          completedList={completedList}
          list={list}
          toCompletedList={toCompletedList}
          setTaskId={setTaskId}
          toTaskDetail={toTaskDetail}
          currentList={currentList}
        />
      );
    case 1:
      return (
        <AddNewTask
          searchTrainers={searchTrainers}
          addTask={addTask}
          update={false}
          handleBacktoList={handleBacktoList}
        />
      );
    case 2:
      return (
        <AddNewTask
          user={user}
          currentList={currentList}
          searchTrainers={searchTrainers}
          updateTask={updateTask}
          update={true}
          handleBacktoList={handleBacktoList}
          task={task}
        />
      );
  }
};

export default function TaskReminder(props) {
  const { taskReminder, notification } = props;
  const [task, setTask] = useState(null);
  const [mode, setViewMode] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentList, setCurrentList] = useState(0);
  const handleClosePopUp = () => setIsOpen(false);
  const handleOpenPopUp = () => setIsOpen(true);

  const trigger = () => {
    const firstVisit = sessionStorage.getItem('firstVisit');
    if (!firstVisit) {
      Mixpanel.track('coach_to_do_list_feature_click_on_feature');
      sessionStorage.setItem('firstVisit', 'true');
    }
    if (!isOpen) {
      setCurrentList(0);
      setViewMode(0);
      setIsOpen(true);
      doFetchList(currentList);
    } else {
      handleClosePopUp();
    }
  };
  useEffect(() => {
    fetchAllTask();
  }, []);

  useEffect(() => {
    doFetchList();
  }, [currentList]);

  useEffect(() => {
    window.addEventListener(CLOSE_TASK_REMINDER_POP_UP, handleClosePopUp);
    return () => {
      window.removeEventListener(CLOSE_TASK_REMINDER_POP_UP, handleClosePopUp);
    };
  }, []);

  useEffect(() => {
    window.addEventListener(OPEN_TASK_REMINDER_POP_UP, handleOpenPopUp);
    if (notification.selectedItem) {
      showListTaskNotification();
    }
    return () => {
      window.removeEventListener(OPEN_TASK_REMINDER_POP_UP, handleOpenPopUp);
    };
  }, [notification.selectedItem]);

  const showListTaskNotification = () => {
    const existTask = [..._.get(taskReminder, 'list', []), ..._.get(taskReminder, 'completedList.list', [])].find(
      item => item._id === _.get(notification, 'selectedItem.item', ''),
    );
    const isCompleted = _.get(existTask, 'is_completed', false);

    if (isCompleted) {
      toCompletedList();
    } else {
      toYourList();
    }
  };

  const setTaskId = value => {
    setTask(value);
  };
  const onDragEnd = async result => {
    const { destination, source, draggableId } = result;
    if (!destination || !source) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const params = {
      taskId: draggableId,
      destinationIndex: destination.index,
      sourceIndex: source.index,
      listType: source.droppableId,
    };
    if (currentList === 2) {
      return;
    }
    try {
      await props.arrangeTask(params);
      await doFetchList(currentList);
    } catch (err) {}
  };
  const doMarkAsCompleted = async taskId => {
    try {
      await props.markAsCompleted(taskId);
    } catch (err) {}
    doFetchList();
  };
  const doUnmarkAsCompleted = async taskId => {
    try {
      await props.unmarkAsCompleted(taskId);
    } catch (err) {}
    doFetchList();
  };
  const toTaskDetail = async taskId => {
    try {
      await props.getTask(taskId);
    } catch (err) {}
    setViewMode(2);
  };
  const fetchAllTask = () => {
    props.fetch();
    props.fetchCompletedTask();
    props.fetchFollowingTask();
  };
  const toCompletedList = async e => {
    // try {
    //   await doFetchList(2)
    // } catch (err) { }
    setCurrentList(2);
  };
  const toYourList = async e => {
    // try {
    //   await doFetchList(0)
    // } catch (err) { }
    setCurrentList(0);
  };
  const doFetchList = (value = currentList) => {
    // eslint-disable-next-line default-case
    switch (value) {
      case 0:
        return props.fetch();

      case 1:
        return props.fetchFollowingTask();

      case 2:
        return props.fetchCompletedTask();
    }
  };
  const handleListChange = (e, item) => {
    e.preventDefault();
    // doFetchList(item)
    setCurrentList(item);
  };
  const handleDueTaskRedDot = () => {
    if (_.isEmpty(taskReminder.list)) return 'red-dot';
    else if (taskReminder.list.some(task => !!task.is_due)) return 'red-dot active';
    return 'red-dot';
  };
  const handleDeleteTask = async e => {
    try {
      await props.removeTask(task);
    } catch (err) {}
    handleBacktoList();
  };
  const handleCreateTask = e => {
    setViewMode(1);
  };
  const handleBacktoList = e => {
    doFetchList(currentList);
    setViewMode(0);
  };

  const renderPopupContainer = () => {
    if (!isOpen) return;

    return (
      <MenuContainer mode={mode} className={!mode && 'list'}>
        <Header>
          {mode === 0 && (
            <>
              <Dropdown
                icon={null}
                className="task_dropdown"
                inline
                label={'ga'}
                trigger={
                  <div>
                    <span className="task_dropdown_text"> {LIST_OPTIONS[currentList].text}&nbsp;</span>
                    &nbsp;
                    <Icon className="chevron down" />
                  </div>
                }
              >
                <Dropdown.Menu>
                  {LIST_OPTIONS.map((item, idx) => {
                    return (
                      <Dropdown.Item
                        disabled={item.deactivated}
                        className={item.value === currentList ? 'active' : ''}
                        onClick={e => handleListChange(e, item.value)}
                        key={idx}
                      >
                        <div>{item.text}</div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              {!currentList && (
                <TaskButton onClick={handleCreateTask}>
                  <div className="add_task">
                    <span>+</span> &nbsp; Add new Task{' '}
                  </div>
                </TaskButton>
              )}
            </>
          )}
          {mode !== 0 && (
            <a href="#">
              <BackIcon height={30} width={40} className="back-icon" onClick={handleBacktoList} />
            </a>
          )}
          {mode === 2 && (
            <TaskButton onClick={handleDeleteTask}>
              <div data-tip={`tooltip--delete`} data-for={`tooltip--delete`}>
                <TrashIcon className="bin" />
              </div>
              <ReactTooltip
                delayShow={300}
                id={`tooltip--delete`}
                className="app-tooltip-delete"
                effect="solid"
                place="top"
              >
                <div>Remove Task</div>
              </ReactTooltip>
            </TaskButton>
          )}
        </Header>
        <Body className={!!mode ? 'notlist' : 'list'}>
          <DragDropContext onDragEnd={onDragEnd}>
            <ModeView
              user={props.user}
              doUnmarkAsCompleted={doUnmarkAsCompleted}
              doMarkAsCompleted={doMarkAsCompleted}
              addTask={props.addTask}
              updateTask={props.updateTask}
              completedList={taskReminder.completedList}
              followingList={taskReminder.followingList}
              list={taskReminder.list}
              toCompletedList={toCompletedList}
              task={props.taskReminder.task}
              setTaskId={setTaskId}
              toTaskDetail={toTaskDetail}
              searchTrainers={props.searchTrainers}
              mode={mode}
              currentList={currentList}
              handleBacktoList={handleBacktoList}
            />
          </DragDropContext>
        </Body>
        {mode === 0 && (
          <ButtonContainer>
            {!currentList ? (
              <Button onClick={toCompletedList} purple>
                {' '}
                View Completed Tasks
              </Button>
            ) : (
              <Button onClick={toYourList} purple>
                {' '}
                Current Tasks
              </Button>
            )}
          </ButtonContainer>
        )}
      </MenuContainer>
    );
  };

  return (
    <RootCloseWrapper disabled={!isOpen} shouldCloseOnOverlayClick={false} onRootClose={handleClosePopUp}>
      <Wrapper className={isOpen ? 'open app-notification' : 'app-notification'}>
        <Trigger className="open-task-trigger" onClick={trigger} data-tip data-for="open-task-trigger-tooltip">
          <span className={handleDueTaskRedDot()}></span>
          <TaskIcon className={isOpen ? 'open open-task-icon' : 'open-task-icon'} />
          <SharedTooltip content="Your tasks" id="open-task-trigger-tooltip" position="bottom" />
        </Trigger>

        {renderPopupContainer()}
      </Wrapper>
    </RootCloseWrapper>
  );
}
