import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';

import { toggleModal } from 'actions/modal';
import * as S from './style';
import FormItem from './FormItem';
import { Button } from 'shared/FormControl';
import UploadCoverImage from './UploadCoverImage';
import { removeUploadedFileFromServer } from 'utils/commonRequest';
import { axiosInstance } from 'configs/request';
import { CALENDAR_LIMIT_WEEKS, CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { generateParams, generateShareStatus } from 'shared/AssetsShareSetting/constants';

class CreateNewStudioProgram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      number_of_weeks: 1,
      program_name: '',
      description: '',
      author: props.user._id,
      share: TEAM_SHARE_PRIVATE,
    };
  }

  handleChangeField = (field, e) => {
    this.setState({ [field]: e.target.value });
  };

  componentDidMount() {
    window.addEventListener('popstate', this.handleBackButtonToggleModal);
  }

  handleBackButtonToggleModal = () => {
    if (this.props.isModalOpen) {
      this.handleClose();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButtonToggleModal, false);
  }

  validateForm = () => {
    const { program_name, number_of_weeks } = this.state;

    if (!program_name.trim()) {
      return false;
    }

    if (parseInt(number_of_weeks, 10) < 1 || parseInt(number_of_weeks, 10) > CALENDAR_LIMIT_WEEKS) {
      return false;
    }
    return true;
  };

  handleClose = () => {
    this.props.toggleModal(false);
    this.setState({
      number_of_weeks: 1,
      program_name: '',
      description: '',
      isSubmited: false,
    });
  };

  onSubmit = () => {
    this.setState({ isSubmited: true });
    if (this.validateForm()) {
      const { user } = this.props;
      const { program_name, number_of_weeks, description, bucketData, url, author, share } = this.state;
      const attachmentUrl = !isEmpty(url) ? url.substring(0, url.indexOf('?X-Amz-Algorithm')) : '';

      const paramsShare = generateParams({
        ownership: author,
        shareStatus: share,
      });

      const params = {
        program_name,
        number_of_weeks,
        description,
        cover_image: get(bucketData, 'location', attachmentUrl),
        owner: paramsShare.owner,
        share: paramsShare.share,
      };
      this.setState({ loading: true });
      axiosInstance
        .post('/api/studio-program/add', params)
        .then(response => {
          this.handleClose();
          const { data } = response.data;
          const hasPermission = user._id === data.author || data.share !== TEAM_SHARE_PRIVATE;
          if (hasPermission) {
            this.props.push(`/home/studio-programs/${data._id}`);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  onRemove = () => {
    const { bucketData } = this.state;
    this.setState({}, () => {
      if (bucketData) {
        removeUploadedFileFromServer(bucketData);
      }
    });
  };

  onUploadSuccess = ({ src, bucketData, url }) => {
    this.setState({ src, bucketData, isUploading: false, url });
  };

  onStartUpload = () => {
    this.setState({ isUploading: true });
  };

  onEndUpload = () => {
    this.setState({ isUploading: false });
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.setState({ author, share });
  };

  render() {
    const { user } = this.props;
    const { isUploading, loading, share } = this.state;
    const isDisabledSubmit = isUploading || loading;
    return (
      <S.CreateNewStudioProgramModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <div className="header-title">Create New Studio Program</div>
          <OwnershipPopupStudio
            size={28}
            user={user}
            owner={user}
            shareStatus={share}
            saveChangeOnClose={this.handleChangeOwnershipStatus}
            updateImmediately={true}
          />
        </Modal.Header>
        <Modal.Content>
          <form className="addStudioProgram__form">
            <div className="addStudioProgram__left">
              <S.Label>Cover Image</S.Label>
              <UploadCoverImage
                key="cover_image"
                onRemove={this.onRemove}
                onUploadSuccess={this.onUploadSuccess}
                onStartUpload={this.onStartUpload}
                onEndUpload={this.onEndUpload}
                data={this.state}
                uploadKey="media"
              />
            </div>
            <div className="addStudioProgram__right">
              <FormItem
                label="Program Name"
                placeholder="Add Program Name"
                required
                maxLength={30}
                inputProps={{ maxLength: 30, autoFocus: true }}
                isSubmited={this.state.isSubmited}
                value={this.state.program_name}
                onChange={e => this.handleChangeField('program_name', e)}
              />
              <FormItem
                label="Number Of Weeks"
                placeholder=""
                type="number"
                inputProps={{ min: 1 }}
                isSubmited={this.state.isSubmited}
                value={this.state.number_of_weeks}
                onChange={e => this.handleChangeField('number_of_weeks', e)}
              />
              <FormItem
                label="Description"
                placeholder="Add program description"
                type="textarea"
                inputProps={{ rows: 4 }}
                isSubmited={this.state.isSubmited}
                value={this.state.description}
                onChange={e => this.handleChangeField('description', e)}
              />
            </div>
          </form>
          <div className="addStudioProgram__footer">
            <Button disabled={isDisabledSubmit} onClick={this.onSubmit} purple>
              Create
            </Button>
          </div>
        </Modal.Content>
      </S.CreateNewStudioProgramModal>
    );
  }
}

const actionCreators = { toggleModal, push };
const mapState = state => {
  return {
    user: state.user,
    isModalOpen: state.isModalOpen,
  };
};

export default connect(mapState, actionCreators)(CreateNewStudioProgram);
