import styled from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
`;

export const LeftContentMobile = styled.div`
  display: none;
  width: 100%;
  .logo-icon {
    width: 146px;
    height: 24px;
  }
`;

export const LeftContent = styled.div`
  width: 42%;
  .logo-icon {
    width: 146px;
    height: 24px;
  }
  > img {
    position: absolute;
    height: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fafbfc;
`;

export const Blur = styled.div`
  position: absolute;
  height: 217px;
  bottom: 0;
  opacity: 0.8;
  width: 42%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`;

export const PortfolioWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  margin: 0px 40px;
  max-width: 35%;
`;

export const Portfolio = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

export const Information = styled.div`
  ${textBase}
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 48px;
  max-width: 520px;
  .verify-confirm-icon {
    width: 88px;
    height: 88px;
  }
`;

export const VerifyConfirmInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
`;

export const VerifyConfirmTitle = styled.div`
  ${textBase}
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #141414;
`;

export const VerifyConfirmDesc = styled.span`
  ${textBase}
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #141414;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const VerifyConfirmActions = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 4px;
`;

export const ActionContent = styled.div`
  ${textBase}
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
`;

export const ActionCountDown = styled.div`
  ${textBase}
  font-size: 16px;
  line-height: 24px;
  color: #141414;
`;

export const ActionResend = styled.button`
  ${textBase}
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #5158cf;
  border: none;
  background: none;
  outline: none;
  padding: unset;
  margin: unset;
  &:hover {
    cursor: pointer;
    color: #5559ff;
    text-decoration: underline;
  }
  &::selection {
    color: #5559ff;
  }
  &:disabled {
    text-decoration: none;
    cursor: not-allowed;
    color: rgba(85, 89, 255, 0.9);
  }
`;

export const VerifyBackButton = styled.div`
  ${textBase}
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #5158cf;
  text-align: left;
`;

export const VerifyBackLoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  margin-top: 17px;

  .verify-back-icon {
    width: 24px;
    height: 24px;
    path {
      fill: #5158cf;
    }
  }

  &:hover {
    cursor: pointer;
    ${VerifyBackButton} {
      text-decoration: underline;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;

  @media only screen and (max-width: 980px) {
    display: block;
    padding: 32px 0 20px;
    overflow: auto;
    overflow-x: hidden;
    height: 100vh;
    background-color: #fafbfc;
    position: relative;
    body::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    ${LeftContent} {
      display: none;
    }
    ${LeftContentMobile} {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
    }
    ${RightContent} {
      width: 100%;
      height: 100%;
      background: #fafbfc;
      padding: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    ${ContentWrapper} {
      row-gap: 24px;
    }
    ${VerifyConfirmInfo} {
      row-gap: 16px;
    }
    ${VerifyConfirmTitle} {
      font-size: 24px;
      line-height: 33px;
    }
    ${VerifyConfirmDesc},
    ${ActionContent},
    ${ActionCountDown},
    ${ActionResend} {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media only screen and (min-width: 981px) {
    ${LeftContent} {
      display: flex;
    }
    ${RightContent} {
      width: 58%;
    }
  }
`;
