import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 20px;
`;

export const UploadVideoButton = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #6a778a;
  cursor: pointer;

  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e4e5e7 url(${CDN_URL}/images/upload_video.svg) no-repeat center;
    margin-right: 10px;
  }
`;

export const VideoLink = styled.input.attrs({
  type: 'text',
})`
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding: 9px 10px 9px 32px;
  background: #ffffff url(${CDN_URL}/images/link_icon_light.svg) no-repeat;
  background-position: 11px center;
  box-shadow: none;
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 10px;

  ::placeholder {
    opacity: 0.4;
  }

  :focus {
    border-color: #726ee4;
  }
`;

export const Preview = styled.div`
  width: 100%;
  height: 174px;
  position: relative;
  border-radius: 5px;

  .media__play-button {
    border-radius: 5px;
  }

  ${props =>
    props.thumbnail &&
    css`
      background: url(${props => props.thumbnail}) no-repeat center;
      background-size: cover;
    `}

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PreviewContainer = styled.div`
  width: 100%;
`;

export const VideoContainer = styled.div``;

export const UploadPhotoArea = styled.div`
  padding: 16px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
  padding-left: 75px;
  background: #fff url(${CDN_URL}/images/drop_image.svg) no-repeat 30px center;
  background-size: 34px auto;
  min-height: 70px;
  border: 1px dashed #dadfea;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;

  .choose-file {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const PhotoUploadTrigger = styled.div`
  width: 100%;
  height: 100%;
  background: #fff url(${CDN_URL}/images/image_upload_bg.svg) no-repeat center;
  background-size: auto;
  border-radius: 5px;
  border: 1px dashed #dadfea;
  cursor: pointer;
`;

export const RemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid #f6f7fb;
  border-radius: 50%;
  background: #f6f7fb url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: -8px;
  top: -8px;
`;

export const PhotoItem = styled.div`
  width: 72px;
  height: 72px;
  flex: 0 0 72px;
  position: relative;
  border-radius: 5px;

  ${props =>
    props.thumbnail &&
    css`
      background: transparent url(${props => props.thumbnail}) no-repeat center;
      background-size: cover;
    `}

  .select-file__container {
    display: block;
    width: 100%;
    height: 100%;

    .select-file__trigger {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .file-upload__cancel-button {
    width: 18px;
    height: 18px;
    background-size: 8px auto;
  }
`;

export const PhotoItemContainer = styled.div`
  /* order: ${props => props.order}; */
  margin-right: 10px;

  :last-child {
    margin-right: 0;
  }
`;

export const ListPhoto = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AcceptNote = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #868c9a;
  opacity: 0.9;
`;

export const Error = styled.div`
  background: rgba(234, 49, 74, 0.05);
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  color: #ff1b1b;
  padding: 10px 15px;
  margin-top: 10px;
`;

export const SectionTitle = styled.h3`
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #777c8f;
`;

export const Section = styled.section`
  margin-bottom: 15px;

  .select-video-input {
    margin-bottom: 10px;
  }
`;

export const Content = styled.div``;

export const Container = styled.div`
  padding: 15px;
  outline: none;
  box-shadow: none;
`;

export const Wrapper = styled.div`
  padding: 15px;
  background-color: #f6f7fb;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-transform: none;
`;
