import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleConfirmModal } from 'actions/modal';
import WorkoutBackground from './component';
import { addWorkoutBackground, deleteWorkoutBackground } from 'redux/custom-branding/actions';

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: {
      customBranding: { workoutBackgrounds },
    },
  } = state;
  return { workoutBackgrounds, cloudfrontList };
};

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addWorkoutBackground: bindActionCreators(addWorkoutBackground, dispatch),
  deleteWorkoutBackground: bindActionCreators(deleteWorkoutBackground, dispatch),
});

export default connect(mapState, mapDispatch)(WorkoutBackground);
