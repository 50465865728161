import styled, { css } from 'styled-components';
import { DraggingIcon } from '../style';

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ebedf2;
  box-shadow: 0px 2px 3px rgba(176, 188, 207, 0.25);
  border-radius: 3px;
  margin-bottom: 5px;
  position: relative;
  user-select: none;
  cursor: grab;

  .sectionItem {
    padding: 10px 16.6px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 10px;
      transition: transform 0.3s ease;
    }

    &__title {
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      color: #000000;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 15px;
    }

    &__exercises {
      padding-left: 19px;
      padding-right: 15px;
      max-height: 0px;
      visibility: hidden;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out, visibility 0.2s ease-in-out, padding-top 0.2s ease-in-out,
        padding-bottom 0.2s ease-in-out;
    }
  }

  ${props =>
    props.collapse &&
    css`
      .sectionItem__exercises {
        visibility: visible;
        max-height: 9999px;
        padding-top: 3px;
        padding-bottom: 3px;
      }
      .sectionItem__icon {
        transform: rotate(90deg);
      }
    `}

  ${DraggingIcon} {
    visibility: hidden;
    position: absolute;
    right: 15px;
    top: 16px;
  }

  ${props =>
    !props.isExercise &&
    css`
      padding-bottom: 10px;
    `}

  ${props =>
    props.isDragging
      ? css`
          border: 1px solid #5c5bbd;
          box-sizing: border-box;
          cursor: move;

          ${DraggingIcon} {
            visibility: visible;
          }
        `
      : props.isDragMode
      ? css`
          cursor: grabbing;
        `
      : !props.isExercise &&
        css`
          padding-bottom: 10px;
          :hover {
            border: 1px solid #5c5bbd;
            box-sizing: border-box;

            ${DraggingIcon} {
              visibility: visible;
            }
          }
        `}
  ${props =>
    props.isDragMode &&
    props.noneDrag &&
    css`
      cursor: no-drop;
    `}
`;
