import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { chartOptions } from './constants';
import { DEFAULT_COLOR } from 'components/BodyMetricChartNew/constants';
import { calculateYAxisRange, convertUnit as convertUnitFunc, pluralize } from 'utils/commonFunction';
import { formatDuration } from 'helpers/time';

import ChartHeader from 'components/BodyMetricChartNew/components/ChartHeader';
import MetricChartExercise from 'components/BodyMetricChartNew/components/MetricChartExercise';

import './styles.scss';

const DURATION_PARSE_DATA = {
  min: 60,
  hour: 3600,
};

export default class ExerciseHistoryChart extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
    this.state = {
      timeRange: '2m',
      dataExercise: [],
      isLoading: false,
      chartData: [],
    };
  }

  componentDidMount() {
    this.getExerciseHistoryEntriesByTime();
  }

  componentDidUpdate(prevProps) {
    if (this.props.exercise !== prevProps.exercise || this.props.filterTime !== prevProps.filterTime) {
      this.getExerciseHistoryEntriesByTime();
    }
  }

  componentWillUnmount() {
    this.props.changeGettingChartData(false);
  }

  handleConvert = (data, uniqueCode) => {
    const { systemUnits } = this.props;
    const shouldConvert = systemUnits[uniqueCode];
    return data.value && shouldConvert ? convertUnitFunc(data.value, data.set_unit, shouldConvert) : data.value;
  };

  getExerciseHistoryEntriesByTime = () => {
    const { filterTime, updateLoadingExerciseMetric } = this.props;
    const { period_group: frequency, from_date, to_date } = filterTime || {};
    updateLoadingExerciseMetric(true);
    this.setState({ isLoading: true });
    this.props
      .getExerciseHistoryEntriesByTime({
        client: this.props.clientId,
        exercise: this.props.exercise.exercise._id,
        frequency,
        start_date: moment(new Date(from_date)).format('MM-DD-yyyy'),
        end_date: moment(new Date(to_date)).format('MM-DD-yyyy'),
      })
      .then(response => {
        if (response && response.data && response.data.data) {
          const { data } = response.data;

          const endDate = moment(to_date);
          const startDate = moment(from_date);

          const list = [];

          _.forEach(data, item => {
            item.dateMoment = moment(_.get(item, 'link.end_date', item.date));
          });

          const validData = data.filter(item => {
            return item.dateMoment.isSameOrBefore(endDate, 'day') && item.dateMoment.isSameOrAfter(startDate, 'day');
          });

          for (let i = moment(startDate); i.isSameOrBefore(endDate); i.add(1, 'day')) {
            const existItem = _.find(validData, item => item.dateMoment.isSame(i, 'day'));

            list.push(existItem || { dateMoment: moment(i) });
          }

          const chartData = this.getChartData(data, list);

          this.setState({
            chartData,
            dataExercise: list,
            isLoading: false,
          });
          updateLoadingExerciseMetric(false);
        } else {
          this.setState({ isLoading: false });
          updateLoadingExerciseMetric(false);
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
        updateLoadingExerciseMetric(false);
      });
  };

  getChartData = (data, list) => {
    const { systemUnits } = this.props;
    const { filterTime } = this.props;

    const chartData = (data || []).map(item => {
      const { chart_data, group_name, date } = item || {};
      const { value, type: unitType, set_unit } = chart_data || {};

      const isDuration = unitType === 'duration';

      const chartRange = this.getMinMaxValue(list);
      let unit_title = '';
      const convertUnit = isDuration
        ? parseInt(Number(chartRange.max) / DURATION_PARSE_DATA.hour)
          ? DURATION_PARSE_DATA.hour
          : DURATION_PARSE_DATA.min
        : 1;

      if (systemUnits[unitType]) {
        unit_title = systemUnits[unitType].title;
      } else if (unitType === 'reps') {
        unit_title = pluralize('rep', +value);
      }

      let tooltipItemValue = isDuration ? Number(value) : Number(value) * convertUnit;
      let tooltipValue = '';

      if (isDuration) {
        tooltipValue = formatDuration(tooltipItemValue);
      } else {
        tooltipValue = _.round(convertUnitFunc(tooltipItemValue, set_unit, systemUnits[unitType]), 2);
      }

      const dataTooltip = tooltipValue + ' ' + unit_title;

      const dataTooltipConvert = dataTooltip.split(' ').filter(item => item != '');

      return {
        ...item,
        unit: {
          title: isDuration ? dataTooltip.trim() : dataTooltipConvert[1],
        },
        value: +tooltipValue,
        label: isDuration ? '' : dataTooltipConvert[0],
        time: group_name || date,
        period_group: filterTime.period_group,
      };
    });
    return chartData;
  };

  getMinMaxValue = data => {
    const { dataExercise } = this.state;
    const existChartData = _.filter(data ? data : dataExercise, item => !!item.chart_data);
    const sorted = _.orderBy(existChartData, ['chart_data.value'], 'desc');

    if (sorted.length) {
      return {
        min: sorted[sorted.length - 1].chart_data.value,
        max: sorted[0].chart_data.value,
        unitType: sorted[0].chart_data.type,
      };
    }

    return null;
  };

  render() {
    const { systemUnits, selectedClient, date_format, exercise, customBrandingColor, filterTime } = this.props;

    const { exercise_name = '' } = exercise || {};

    if (!selectedClient) {
      return null;
    }

    const { dataExercise, timeRange, isLoading, chartData } = this.state;

    const dateFormat = date_format ? date_format.slice(0, 5) : 'MM/DD';
    let item,
      labels = [],
      labelsTooltip = [],
      dataSets = [];

    const today = moment();
    const chartRange = this.getMinMaxValue();
    const unitType = chartRange && chartRange.unitType;
    const convertUnit =
      unitType === 'duration'
        ? parseInt(Number(chartRange.max) / DURATION_PARSE_DATA.hour)
          ? DURATION_PARSE_DATA.hour
          : DURATION_PARSE_DATA.min
        : 1;

    for (let i = 0; i < dataExercise.length; i++) {
      item = dataExercise[i];

      if (item.chart_data) {
        if (item.dateMoment.isSame(today, 'day')) {
          labels.push('Today');
          labelsTooltip.push('Today, ');
        } else {
          labels.push(item.dateMoment.format(dateFormat));
          labelsTooltip.push(item.dateMoment.format('MMM DD, '));
        }
        dataSets.push(
          item.chart_data.value < 0 ? null : Number(this.handleConvert(item.chart_data, item.chart_data.type)),
        );
      } else {
        labels.push(item.dateMoment.format(dateFormat));
        labelsTooltip.push(item.dateMoment.format('MMM DD, '));
        dataSets.push(null);
      }
    }

    let unit_title = '';

    if (systemUnits[unitType]) {
      const setting = systemUnits[unitType];
      unit_title = setting ? setting.title : systemUnits[unitType].title;
    } else if (unitType === 'reps') {
      unit_title = ' reps';
    }

    const options = chartOptions(labelsTooltip, unitType, timeRange, unit_title, convertUnit);

    if (chartRange) {
      const { yAxes } = options.scales;
      let yConfigs = yAxes[0];
      chartRange.max = Number(chartRange.max) / convertUnit;
      chartRange.min = Number(chartRange.min) / convertUnit;
      yConfigs.ticks = { ...yConfigs.ticks, ...calculateYAxisRange(chartRange.min, chartRange.max) };
      options.scales.yAxes[0] = yConfigs;
    }

    return (
      <div className="eve-panel body-metric-chart-panel exercise-metric-chart-panel">
        <ChartHeader
          bodyMetric={{ filterTime: filterTime, name: exercise_name }}
          onFilterRangeTime={this.handleFilterRangeTime}
          hideFilterToday
          isLoadingMetric={isLoading}
        />
        <div className="exercise-metric-chart-wrapper">
          <MetricChartExercise
            rangeTime={filterTime}
            chartData={chartData}
            color={customBrandingColor || DEFAULT_COLOR}
            isLoading={isLoading}
            chartType="line"
          />
        </div>
      </div>
    );
  }
}
