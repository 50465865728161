import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Layout from '../Calendar';
import {
  getAutoflowIntervalListWorkouts,
  resetAutoflowIntervalCopyItem,
  arrangeAutoflowIntervalWorkoutInDay,
  arrangeSectionInsideAutoflowIntervalWorkout,
  moveAutoflowIntervalSection,
  moveAutoflowIntervalWorkout,
  resetAutoflowIntervalTrainingData,
} from 'redux/autoflowInterval/training/actions';
import { DRAGGALE_TYPE } from 'shared/WorkoutCalendarCompactView';
import NavBar from './NavBar';

const TrainingCalendar = ({
  copying,
  autoflowId,
  getAutoflowIntervalListWorkouts,
  resetAutoflowIntervalCopyItem,
  resetAutoflowIntervalTrainingData,
  ...props
}) => {
  const onDragEnd = result => {
    const { type, draggableId, source, destination } = result;

    if (!destination || !source || !draggableId) {
      return;
    }

    if (_.isEqual(source, destination)) {
      return;
    }

    const newIndex = destination.index;
    const oldIndex = source.index;

    if (type === DRAGGALE_TYPE.WORKOUT) {
      const [, oldWeekIndex, oldDayIndex] = source.droppableId.split('_');
      const [, newWeekIndex, newDayIndex] = destination.droppableId.split('_');
      const [, , , workoutId] = draggableId.split('_');
      let bodyData = { autoflowId, workoutId, newIndex };

      if (source.droppableId === destination.droppableId) {
        bodyData = { ...bodyData, weekIndex: oldWeekIndex, dayIndex: oldDayIndex };
        props.arrangeAutoflowIntervalWorkoutInDay(bodyData, oldIndex);
      } else {
        bodyData = { ...bodyData, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex };
        props.moveAutoflowIntervalWorkout(bodyData, oldIndex);
      }
    } else if (type === DRAGGALE_TYPE.SECTION) {
      const [oldWeekIndex, oldDayIndex, workoutId] = source.droppableId.split('_');
      const [newWeekIndex, newDayIndex, newWorkoutId] = destination.droppableId.split('_');
      const [, , , , sectionId] = draggableId.split('_');
      let bodyData = { autoflowId, workoutId, newIndex, sectionId };

      if (source.droppableId === destination.droppableId) {
        bodyData = { ...bodyData, weekIndex: oldWeekIndex, dayIndex: oldDayIndex, oldIndex };
        props.arrangeSectionInsideAutoflowIntervalWorkout(bodyData, oldIndex);
      } else {
        bodyData = { ...bodyData, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex, newWorkoutId, oldIndex };
        props.moveAutoflowIntervalSection(bodyData, oldIndex);
      }
    }
  };

  return (
    <Layout
      {...props}
      components={{ NavBar: NavBar }}
      copying={copying}
      onDidMount={getAutoflowIntervalListWorkouts}
      resetCopyItem={resetAutoflowIntervalCopyItem}
      onDragEnd={onDragEnd}
      onStartWeekChange={getAutoflowIntervalListWorkouts}
      onViewModeChange={getAutoflowIntervalListWorkouts}
      onUpdateWeeks={getAutoflowIntervalListWorkouts}
      onUnmount={resetAutoflowIntervalTrainingData}
      isTrainingTab={true}
    />
  );
};

const mapState = state => {
  const {
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
      autoflowInterval: { training },
    },
  } = state;

  return { copying: training.get('copying'), autoflowId: _.get(workingAutoflow, '_id') };
};

const actionCreators = {
  getAutoflowIntervalListWorkouts,
  resetAutoflowIntervalCopyItem,
  arrangeAutoflowIntervalWorkoutInDay,
  arrangeSectionInsideAutoflowIntervalWorkout,
  moveAutoflowIntervalSection,
  moveAutoflowIntervalWorkout,
  resetAutoflowIntervalTrainingData,
};

export default connect(mapState, actionCreators)(TrainingCalendar);
