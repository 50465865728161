import { roundNumberBodyMetric } from 'utils/commonFunction';

export const calculateTypeMap = (dataDays, days = []) => {
  const typeMap = {};

  // Calculate sum for each type
  for (const dataArray of dataDays) {
    for (const item of dataArray) {
      const { type, value } = item || {};
      typeMap[type] = typeMap[type] || { type, value: 0 };
      typeMap[type].value += value;
    }
  }

  // Calculate average and round for each type
  for (const type in typeMap) {
    const { value } = typeMap[type];
    typeMap[type].value = roundNumberBodyMetric(value / days.length || 0);
  }

  return Object.values(typeMap);
};
