import React, { useRef, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import { CDN_URL, CLIENT_STATUS } from 'constants/commonData';
import * as S from './style';
import './style.scss';

function InviteClientSuccess(props) {
  const { clientData } = props;
  const [copied, setCopied] = useState();
  const copyInput = useRef();

  if (!clientData) {
    return null;
  }

  const { client_connection } = clientData;

  const onCopyLink = () => {
    copyInput.current.focus();
    copyInput.current.select();

    try {
      const successful = document.execCommand('copy');

      if (successful) {
        setCopied(true);
      }
    } catch (err) {
      console.error('Oops, unable to copy');
    }
  };

  const onMouseLeave = () => {
    if (copied) {
      setCopied(false);
    }
  };

  const onAssignWorkout = () => {
    props.push(`/home/client/${clientData._id}/calendar`);
    props.onClose();
  };

  const openDashBoard = () => {
    props.push(`/home/client/${clientData._id}`);
    props.onClose();
  };

  const renderUIForOfflineCLient = () => {
    return (
      <S.Wrapper>
        <div className="icon">
          <img src={`${CDN_URL}/images/hi_icon.svg`} alt="" />
        </div>
        <h1 className="title">New client: {clientData.first_name}</h1>
        <h3 className="subtitle">
          You’ve added an offline client. You can always send the client an invitation to connect on Everfit later.
        </h3>
        <Button purple className="button--open-dashboard" onClick={openDashBoard}>
          <span>Open client dashboard</span>
        </Button>
      </S.Wrapper>
    );
  };

  const renderUIForPendingCLient = () => {
    return (
      <S.Wrapper>
        <div className="icon">
          <img src={`${CDN_URL}/images/congratulation_icon.svg`} alt="" />
        </div>
        <h1 className="title">Invitation sent to {clientData.first_name}!</h1>
        <h3 className="subtitle">
          Your client will be receiving an invite email shortly, but you can also copy the invite link below.
        </h3>
        <S.CopyArea onMouseLeave={onMouseLeave}>
          <S.CopyInput className="link" value={clientData.invitation_link} ref={copyInput} />
          <img src={`${CDN_URL}/images/copy_purple.svg`} className="copy-icon" alt="" />
          <S.CopyButton onClick={onCopyLink}>
            <span>{copied ? 'Invite link copied!' : 'Copy to clipboard'}</span>
          </S.CopyButton>
        </S.CopyArea>
        <Button purple className="button--assign-first-workout" onClick={onAssignWorkout}>
          <span>Assign first workout</span>
          <img src={`${CDN_URL}/images/arrow.svg`} alt="" width="14" />
        </Button>
      </S.Wrapper>
    );
  };

  return (
    <Modal
      open={true}
      className="modal--invite-client-success"
      onClose={props.onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Content>
        {client_connection === CLIENT_STATUS.pending ? renderUIForPendingCLient() : renderUIForOfflineCLient()}
      </Modal.Content>
    </Modal>
  );
}

export default InviteClientSuccess;
