import { Section, SectionBody, SectionTitle, SelectOwner } from 'shared/ExerciseFilterPopup/style';
import SelectTaskType from 'shared/SelectTaskType';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TypeWrapper = styled.div`
  ${Section} {
    padding: 25px 32px;

    &:last-child {
      border-bottom: none;
    }

    ${SectionTitle} {
      color: #727272;
    }

    ${SectionBody} {
      padding-top: 12px;

      .checkbox-container {
        margin: 4px -4px 4px 0px;
      }

      .evf-checkbox {
        color: black;
        padding-left: 24px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        white-space: nowrap;
      }

      ${SelectOwner} {
        width: 228px;
        padding-top: 5px;

        .multi-select__menu .multi-select__menu-list {
          /* width: 345px !important; */
        }

        .multi-select-container .multi-select__value-container {
          padding: 3px 13px !important;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
        }

        .multi-select-container .multi-select__multi-value {
          align-items: center;
          border-radius: 12px;
          background: #efeeff;
          height: 20px;
        }

        .multi-select-container .multi-select__multi-value__label {
          color: #3c39a3;
        }

        .multi-select-container .multi-select__control.multi-select__control--is-focused {
          border: 1px solid #7773ff !important;
        }

        .multi-select-container .multi-select__multi-value__remove > svg {
          fill: black;
        }

        &.multi-select__option.multi-select__option--is-focused {
          background: #f4f4ff;
        }

        .multi-select__menu-notice--no-options {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: #202353;
        }

        .multi-select__menu:has(.multi-select__menu-notice--no-options) {
          width: 100% !important;
        }
      }
    }
  }
`;

export const HeaderFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0 32px;

  .popup-title {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ClearSelection = styled.button`
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5c5bbd;
  padding: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const SelectType = styled(SelectTaskType)`
  padding: 25px 32px !important;
`;
