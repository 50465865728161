import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import * as S from './style';
import Post from '../Post';
import { toggleModal } from 'actions/modal';
import PostDetail from '../PostDetail';
import Leaderboard from '../Leaderboard';
import SchedulePost from '../SchedulePost';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_black.svg';
import { ReactComponent as EmptyScheduledSvg } from 'assets/images/empty-scheduled-posts.svg';
import { ReactComponent as DisabledScheduled } from 'assets/images/disabled-scheduled-post.svg';
import { resetDisableLoadMoreSchedulePosts } from 'redux/forum/actions';

function UpcomingFeed(props) {
  const {
    schedulePosts,
    match,
    push,
    toggleModal,
    getListSchedulePost,
    permission,
    loading,
    enabledAddons,
    firstLoadSchedulePosts,
    schedulePostsLoading,
    resetDisableLoadMoreSchedulePosts,
  } = props;
  const forumId = get(match, 'params.id') || get(match, 'params.forumId') || '';
  const [switchTab, setSwitchTab] = useState('all');
  const [openingDropdown, setOpeningDropdown] = useState(null);
  const isDisabledPermission = !permission.autoflow && !enabledAddons.automation;

  function handleTabClick(tabName) {
    if (isDisabledPermission || loading) return;
    if (tabName === switchTab) return;

    setSwitchTab(tabName);
    resetDisableLoadMoreSchedulePosts();
    getListSchedulePost({ page: 1, tab: tabName });
  }

  useEffect(() => {
    if (!loading && (isEmpty(schedulePosts) || schedulePosts === undefined) && !switchTab) {
      push(`/home/forums/${forumId}/discussion`);
    }
  }, [schedulePosts]);

  const filterScheduledPosts = useMemo(() => {
    return orderBy(
      schedulePosts.filter(it => !it.isPin),
      ['schedule_time'],
      ['asc'],
    );
  }, [schedulePosts]);

  const loadingIndicator = () => {
    return schedulePostsLoading ? (
      <S.IndicatorWrapper>
        <Loader active inline="centered" className="custom-indicator">
          <span>Loading scheduled posts</span>
        </Loader>
      </S.IndicatorWrapper>
    ) : (
      <S.IndicatorPlaceHolder />
    );
  };

  const renderScheduledPost = () => {
    return (
      <>
        {filterScheduledPosts.length === 0 && !firstLoadSchedulePosts ? (
          <S.EmptyFeed>
            <EmptyScheduledSvg />
            <S.EmptyTitle>No posts yet</S.EmptyTitle>
            <S.EmptySubTitle>
              All posts that you schedule will <br /> appear here
            </S.EmptySubTitle>
          </S.EmptyFeed>
        ) : (
          <S.Wrapper className="list-scheduled-post" disabled={isDisabledPermission}>
            {filterScheduledPosts.map(item => {
              return (
                <Post
                  {...props}
                  key={item._id}
                  id={item._id}
                  data={item}
                  scheduleTime={item.schedule_time}
                  enabledAddons={enabledAddons}
                  isScheduledPost={true}
                  root={`/home/forums/${forumId}/post-detail/${item._id}`}
                  onMediaClick={({ mediaIndex }, mediaType, multiple) => {
                    if (mediaType !== 'video' || multiple) {
                      push(`/home/forums/${forumId}/post-detail/${item._id}`);
                      toggleModal(true, <PostDetail mediaIndex={mediaIndex} {...props} postId={item._id} />);
                    }
                  }}
                  openingDropdown={openingDropdown}
                  onToggleDropdown={setOpeningDropdown}
                />
              );
            })}
            {loadingIndicator()}
          </S.Wrapper>
        )}
      </>
    );
  };

  const handleConnectAutoflow = () => {
    push(`/home/autoflow`, { index: 1 });
  };

  return (
    <>
      <S.DiscussionTimeline>
        <S.PostFeed disabled={isDisabledPermission}>
          <S.HeaderTabWrapper>
            <S.AllScheduled
              data-btn-tab="all-posts"
              enableTab={switchTab === 'all'}
              disabled={isDisabledPermission}
              onClick={() => handleTabClick('all')}
            >
              All posts
            </S.AllScheduled>
            <S.MyPosts
              data-btn-tab="my-posts"
              enableTab={switchTab === 'my_post'}
              disabled={isDisabledPermission}
              onClick={() => handleTabClick('my_post')}
            >
              My posts
            </S.MyPosts>
          </S.HeaderTabWrapper>
          {isDisabledPermission ? (
            !schedulePostsLoading ? (
              <S.MainPostWrapper>
                {renderScheduledPost()}
                <S.DisabledWrapper>
                  <DisabledScheduled className="stack-top" />
                  <S.DisabledTitle>Schedule Forum Posts</S.DisabledTitle>
                  <S.DisabledSubTitle>
                    Plan out your posts for the month and schedule ahead. Keep your <br />
                    clients engaged, while giving you ultimate flexibility.
                  </S.DisabledSubTitle>
                  <S.ConnectToAutoflowBtn textOnly onClick={handleConnectAutoflow}>
                    <UpgradePathButton
                      data-btn="Get This Feature"
                      text="Get This Feature"
                      className="button--upgrade-path"
                      isPreventDefault={true}
                    />
                  </S.ConnectToAutoflowBtn>
                </S.DisabledWrapper>
              </S.MainPostWrapper>
            ) : (
              loadingIndicator()
            )
          ) : (
            renderScheduledPost()
          )}
        </S.PostFeed>
        <S.SideBar>
          <Leaderboard permission={permission} />
          <SchedulePost permission={permission} />
        </S.SideBar>
      </S.DiscussionTimeline>
      <S.ScrollTop>
        <ArrowUpIcon />
      </S.ScrollTop>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetDisableLoadMoreSchedulePosts: bindActionCreators(resetDisableLoadMoreSchedulePosts, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(UpcomingFeed);
