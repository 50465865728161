import React, { useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import { SetOneRepMaxModalWrapper } from './style';
import { Modal } from 'semantic-ui-react';
import { Button, ErrorMessage, FormGroup } from 'shared/FormControl';
import { axiosInstance } from 'configs/request';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import SearchExercise from 'shared/SearchExercise';
import classNames from 'classnames';
import { convertUnit, roundUp } from 'utils/commonFunction';
import { showError } from 'actions/error';
import { CDN_URL } from 'constants/commonData';

function SetOneRepMaxModal(props) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(0);
  const [selectedExercise, setSelectedExercise] = useState(props.exercise);

  useEffect(() => {
    const params = {
      client: props.client,
      exercise: _.get(selectedExercise, '_id'),
    };
    if (!params.exercise || !params.client) {
      return;
    }
    setLoading(true);
    axiosInstance
      .get('/api/exercise/get-one-rep-max', { params })
      .then(({ data }) => {
        try {
          Mixpanel.track('set_1rm_manually');
        } catch {}

        if (data.data) {
          const value =
            data.data.value && props.weightSetting
              ? convertUnit(data.data.value, data.data.set_unit, props.weightSetting)
              : data.data.value;
          setValue(roundUp(value, 2) || 0);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedExercise]);

  const onSubmit = e => {
    e.preventDefault();
    if (_.isNaN(parseFloat(value)) || loading) {
      return;
    }
    const body = {
      client: props.client,
      exercise: _.get(selectedExercise, '_id'),
      value,
      unit: props.weightUnit,
    };
    setLoading(true);
    axiosInstance
      .post('/api/exercise/set-one-rep-max', body)
      .then(() => {
        toast(`1RM for ${selectedExercise.title} has been updated.`);
        onClose();
      })
      .catch(err => {
        const errorMessage = _.get(err, 'response.data.message');
        props.showError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    props.toggleModal(false);
  };

  const onChangeValue = e => {
    setValue(e.target.value);
  };

  const onSelectExercise = data => {
    setSelectedExercise(data);
  };

  const defaultValue = useMemo(() => {
    const id = _.get(selectedExercise, '_id', '');
    if (!id) return null;
    return {
      key: id,
      value: id,
      label: _.get(selectedExercise, 'title'),
      thumbnail_url: selectedExercise.preview_300 || (selectedExercise.picture || [])[0],
    };
  }, [selectedExercise]);

  const isNotStrength = useMemo(() => {
    return selectedExercise && selectedExercise.category_type_name !== 'Strength';
  }, [selectedExercise]);

  return (
    <SetOneRepMaxModalWrapper
      size={'tiny'}
      open={props.isModalOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      width={405}
    >
      <Modal.Header>
        <div>Manually Set 1RM</div>
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={onSubmit}>
          <FormGroup className={classNames({ error: isNotStrength })}>
            <label>Choose Exercise</label>
            <SearchExercise
              defaultOptions={props.mostRecents}
              defaultValue={defaultValue}
              onSelect={onSelectExercise}
              disableCreateNew={true}
              placeholder="Search exercises"
              hideFilter={true}
              showAvatar
            />
            <ErrorMessage>Choose a Strength exercise in order to set 1RM</ErrorMessage>
          </FormGroup>
          <FormGroup>
            <label>Value</label>
            <div className="orm__inputValue">
              <input onChange={onChangeValue} type="number" min="0" value={value} step="0.01" disabled={loading} />
              <span className="orm__inputValue--addon">{props.unitTitle}</span>
            </div>
          </FormGroup>
          <div className="orm__footer">
            <Button
              disabled={_.isNaN(parseFloat(value)) || value < 0 || loading || !selectedExercise || isNotStrength}
              purple
            >
              Set
            </Button>
          </div>
        </form>
      </Modal.Content>
    </SetOneRepMaxModalWrapper>
  );
}

const mapStateToProps = state => {
  const weightSetting = _.get(state, 'rootReducer.client.workingClientDetail.preferences.weight', '');
  const onlyStrength = _.get(state, 'rootReducer.exercise.mostRecents', []).filter(
    item => item.category_type_name === 'Strength',
  );

  return {
    isModalOpen: state.isModalOpen,
    weightUnit: _.get(state, 'rootReducer.client.workingClientDetail.preferences.weight._id', ''),
    unitTitle: _.get(weightSetting, 'title', ''),
    client: _.get(state, 'rootReducer.client.workingClientDetail._id', ''),
    weightSetting,
    mostRecents: _.map(onlyStrength, item =>
      Object.assign({}, item, {
        key: item._id,
        label: item.title,
        value: item._id,
      }),
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    showError: bindActionCreators(showError, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetOneRepMaxModal);
