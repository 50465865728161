import styled from 'styled-components';
import Top1Icon from 'assets/icons/forum-leaderboard-first-place.svg';
import Top2Icon from 'assets/icons/forum-leaderboard-second-place.svg';
import Top3Icon from 'assets/icons/forum-leaderboard-third-place.svg';

export const PartialInfo = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 6px 8px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 5px;
  display: inline-block;

  svg {
    margin-right: 10px;
    vertical-align: bottom;
  }
`;

export const PartialDurationInfo = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 6px 8px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 5px;
  display: flex;
  flex-direction: row;

  .divider {
    width: 1px;
    background: #6c66e1;
    margin: 3px 5px;
    height: 11px;
  }

  svg {
    margin-right: 10px;
    vertical-align: bottom;
  }
`;

export const MarkLive = styled.span`
  width: 60px;
  height: 21px;
  padding: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
  text-align: center;
  margin-left: 4px;
  background: #4cc28c;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
`;

export const TopUserItem = styled.div`
  flex: 0 0 85px;
  margin-right: 6px;
  text-align: center;

  :last-child {
    margin-right: 0;
  }

  .userInfo {
    min-height: 72px;
  }

  .sb-avatar {
    margin-bottom: 5px;
  }

  .userName {
    font-weight: bold;
    font-size: 13px;
    line-height: 100%;
    color: #ffffff;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    max-height: 26px;
    margin-bottom: 3px;
    word-break: break-word;
  }

  .score {
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .rankingPosition {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left bottom;

    &.top1 {
      height: 105px;
      width: 80px;
      margin: auto;
      background-image: url(${Top1Icon});
    }

    &.top2 {
      height: 95px;
      width: 80px;
      margin: auto;
      background-image: url(${Top2Icon});
    }

    &.top3 {
      height: 85px;
      width: 80px;
      margin: auto;
      background-image: url(${Top3Icon});
    }
  }
`;

export const BaseInforContainer = styled.div`
  background: radial-gradient(100% 100% at 50% 100%, #8b81ff 0%, #2a2989 100%);
  padding: 20px 15px 0;
  color: #fff;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .evf-dropdown {
    position: absolute;
    top: 10px;
    right: 10px;

    .evf-dropdown__trigger {
      width: 25px;
      height: 25px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px;

      .dots {
        background-color: #fff;
      }
    }

    .evf-dropdown__menu {
      width: 178px;

      .evf-dropdown__option {
        img {
          width: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  .info {
    &__ending {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      margin-bottom: 3px;

      &.upcoming,
      &.completed {
        font-weight: 600;
      }
    }

    &__name {
      font-weight: bold;
      font-size: 19px;
      line-height: 33px;
      margin-bottom: 23px;
      width: 300px;
      word-break: break-word;
    }
    &__other {
      display: flex;
      flex-direction: row;
    }
  }

  .topUser {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .empty {
    padding-bottom: 77px;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    opacity: 0.6;

    svg {
      margin-bottom: 16px;
    }

    &.upcoming {
      padding-bottom: 60px;
    }
  }
`;

export const ClientItem = styled.div`
  padding: 12px 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  color: #202353;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: inset 0px -1px 0px #f1f5fd;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1;
  text-overflow: ellipsis;
  position: relative;

  :last-child {
    box-shadow: none;
  }

  .index {
    flex: 0 0 27px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #7b7e91;
  }

  .sb-avatar {
    flex: 0 0 42px;
  }

  .clientName {
    font-weight: 600;
    margin: 0 10px;
    flex: 1 1;
    white-space: break-spaces;
  }

  .bottomSentinal {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
`;

export const ClientContainer = styled.div`
  padding: 13px 25px 0;
  border: 1px solid #dadfea;
  border-top: none;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 100px;
  height: calc(100% - 407px);
  overflow: auto;

  &.noList {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-data {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #b6b6b6;
  }
`;

export const Wrapper = styled.div`
  width: 303px;
  background: #fff;
  height: 100%;
  cursor: pointer;
`;
