import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  .icon-info {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .list-nutrient-wrapper {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 295px;
    height: 308px;
    z-index: 2;
    padding-top: 8px;
  }
  .nutrient-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 16px 0px #26262633;
    height: 100%;
    transform: none;
    padding: 20px 0 20px 20px;
    h4 {
      margin-bottom: 5px;
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      color: #7b7e91;
      text-transform: uppercase;
    }
    .list-nutrient {
      margin-right: 5px;
      padding-right: 9px;
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 10px;
      ::-webkit-scrollbar {
        width: 6px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #e1e1ea !important;
      }
      ::-webkit-scrollbar-track {
        margin-bottom: 10px;
      }
    }
    .item-nutrient {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        border-bottom: none;
      }
      .name-nutrient {
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        color: #202353;
        text-transform: capitalize;
      }
      .value-nutrient {
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 600;
        line-height: 19.5px;
      }
      .unit-nutrient {
        font-size: 11px;
        line-height: 16.5px;
      }
      &.item-nutrient-invalid {
        background-color: #fff3f3;
      }
    }
  }
  &:hover {
    .list-nutrient-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }
`;
