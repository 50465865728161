/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import Ownership from './component';

const mapStateToProps = (state: State) => {
  const { user, cloudfrontList } = state;
  let isTeamAdmin = false;
  if (!user.teams || user.teams.length === 0 || user.teams[0].role === 0 || user.teams[0].role === 2) {
    isTeamAdmin = true;
  }
  return {
    user,
    isTeamAdmin,
    cloudfrontList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Ownership)
);
