import styled from 'styled-components';

export const FormInfo = styled.div`
  padding: 8px 20px;
`;

export const Wrapper = styled.div`
  flex: 1;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  max-height: 754px;
  height: fit-content;
`;
