import { createContext, useContext } from 'react';

const defaultValue = {
  openPopup: false,
  closePopup: () => {},
  openTooltip: false,
  closeTooltip: () => {},
  onlyUnread: false,
  toggleOnlyUnread: () => {},
  isToday: false,
  isOlder: false,
  isEmptyNotification: false,
  hasUnread: false,
  list: [],
  listToday: [],
  listOlder: [],
  timezone: undefined,
  openItemNotification: () => {},
  readNotifications: () => {},
  backToTop: () => {},
  loading: false,
  getMoreNotifications: () => {},
  isEnd: false,
  markAsReadItemNotification: () => {},
  markAsUnreadItemNotification: () => {},
  disableCloseSidebar: false,
  setDisableCloseSidebar: () => {},
  actionPopupId: undefined,
  setActionPopupId: () => {},
  toggleModal: () => {},
  isFirstPage: false,
  permission: false,
};

const MyContext = createContext(defaultValue);

export default MyContext;

export const useMyContext = () => useContext(MyContext);
