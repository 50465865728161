import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  width: fit-content;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 20px;
  padding: 0 21px 0 9px;
  border-radius: 8px;
  background: linear-gradient(90deg, #ff6347 0%, #ff8c00 51%, #ffd700 100%);

  .custom-close-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -9px;
    right: -9px;
    cursor: pointer;
  }

  ${props =>
    props.hasOnboardingGuide &&
    css`
      right: 372px;
      left: unset;
      transform: unset;
    `}
`;

export const Container = styled.div`
  display: flex;
  gap: 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32.5px;
  padding: 15px 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

export const Title = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
`;

export const Description = styled.div`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12.5px;
  min-width: 350px;

  .custom-check-icon {
    width: 10px;
    height: 10px;
  }
`;

export const ActionItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const ActionUpgradePath = styled(UpgradePathButton)`
  padding: unset;
  background: unset;

  span {
    font-family: 'Open Sans';
    text-transform: capitalize;
    font-weight: 600;
    white-space: nowrap;
  }
  &:hover {
    background: unset;
    span {
      text-decoration: underline;
    }
  }
`;

export const ButtonUpgradePath = styled(UpgradePathButton)`
  padding: 8px 12px;
  background: #ffffff;
  border-radius: 6px;
  span {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    color: #202353;
    white-space: nowrap;
  }
  &:hover {
    background: #ffffff;
  }
`;

export const ButtonUpgradePathIcon = styled(UpgradePathButton)`
  padding: unset;
  background: unset;
  border-radius: unset;
  display: flex;
  align-items: flex-end;
  &:hover {
    background: unset;
  }
`;
