// Libs
import React, { useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton } from 'semantic-ui-react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

// Actions
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';

// Component
import InputName from '../InputName';
import CategorySelect from '../CategorySelect';
import UnitSelect from '../UnitSelect';
import DiscardChangeModal from 'components/Recipes/Parts/DiscardChange';
import AddSingleIngredientModal from '../AddSingleIngredientModal';
import ImportIngredients from 'components/ImportIngredients';

// Utils
import { mongoObjectId } from 'utils/commonFunction';

// Helpers
import { convertUnitName } from 'components/IngredientLibrary/helpers';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/close_icon_cicre.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as CsvIcon } from 'assets/icons/csv-icon.svg';

import { CDN_URL } from 'constants/commonData';

// Style
import * as S from './style';

const dataItemInit = {
  name: '',
  categories: [],
  default_unit: '',
  // nutrition_info: {},
};

const AddMultiIngredientModal = ({
  toggleModal,
  toggleSecondModal,
  isSecondModal = false,
  errorMsg = 'Please complete all required fields',
  onSubmitForm = () => {},
  optionsCategory = [],
  toggleConfirmModal,
  isLoading,
  listUnitIngredient = {},
}) => {
  const listIngredientRef = useRef(null);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState([{ ...dataItemInit, _id: mongoObjectId() }]);
  const [isScroll, setIsScroll] = useState(false);

  const convertNameListUnit = convertUnitName(listUnitIngredient);

  const hasError = useMemo(() => {
    const checkEmpty = data.some(item => {
      const { name, categories, default_unit } = item || {};
      return !(name || '').trim() || isEmpty(categories) || isEmpty(default_unit);
    });
    if (checkEmpty) return true;
    setIsSubmitted(false);
    return false;
  }, [data]);

  const handleCloseModal = () => {
    const toggleFunction = isSecondModal ? toggleSecondModal : toggleModal;
    if (typeof toggleFunction === 'function') {
      toggleFunction(false);
    }
  };

  const onSubmit = () => {
    setIsSubmitted(true);
    if (hasError) return;
    onSubmitForm(data);
  };

  const handleAddMore = () => {
    setData(prevState => [...prevState, { ...dataItemInit, _id: mongoObjectId() }]);
  };

  const handleRemove = indexToRemove => {
    setData(prevState => {
      const updatedData = prevState.filter((_, index) => index !== indexToRemove);
      return updatedData;
    });
  };

  const handleUpdateField = (property, value, indexUpdate) => {
    setData(prevState => {
      const result = prevState.map((item, index) => {
        if (index === indexUpdate) {
          return { ...item, [property]: property === 'categories' ? [value] : value };
        }
        return item;
      });
      return result;
    });
  };

  const onScroll = () => {
    if (listIngredientRef.current) {
      setIsScroll(listIngredientRef.current.scrollTop > 0);
    }
  };

  const handleDiscardChange = () => {
    const isDiscardChange = data.some(item => {
      const { name, categories, default_unit } = item || {};
      return name || !isEmpty(categories) || !isEmpty(default_unit);
    });

    if (isDiscardChange) {
      toggleConfirmModal(true, <DiscardChangeModal onConfirm={handleCloseModal} />);
    } else {
      handleCloseModal();
    }
  };

  const handleAddNutrition = (dataNutrition, idIngredient) => {
    const result = data.map(item => {
      if (item._id === idIngredient) {
        return { ...item, nutrition_info: dataNutrition };
      }
      return item;
    });
    setData(result);
    toggleSecondModal(false);
  };

  const handleOpenNutritionInfo = (idIngredient, nutritionInfo) => {
    toggleSecondModal(
      true,
      <AddSingleIngredientModal
        // nutritionInfo={nutritionInfo}
        isSecondModal
        isEditMode
        addFromMultiple
        onSubmitForm={data => handleAddNutrition(data, idIngredient)}
      />,
    );
  };

  const getNutritionInfo = (nutritionInfo = {}) => {
    const { dataAmountPer = {}, macroNutrients = [] } = nutritionInfo;
    const { value = '-', unit = {} } = dataAmountPer;
    const { formula = '' } = unit;

    const getNutrientValue = type => {
      const nutrient = macroNutrients.find(item => item.type === type);
      return nutrient && nutrient.value ? nutrient.value : '-';
    };

    const protein = getNutrientValue('protein');
    const carbs = getNutrientValue('carbs');
    const fat = getNutrientValue('fat');
    const calories = getNutrientValue('calories');

    return `${value} ${formula} (${calories} cal, P ${protein} g, C ${carbs} g, F ${fat} g)`;
  };

  const handleOpenImportCsv = () => {
    toggleSecondModal(true, <ImportIngredients />);
  };

  return (
    <S.Wrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={handleDiscardChange}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <S.Header>
        <div className="left-header">
          <span className="sub-title">Add Ingredients</span>
          <h3 className="title">Add Multiple Ingredients</h3>
        </div>
        <div className="right-header">
          <button className="btn-add-csv" onClick={handleOpenImportCsv}>
            <CsvIcon /> Add ingredients by CSV.file
          </button>
        </div>
      </S.Header>
      <S.Content>
        <S.TableContainer ref={listIngredientRef} onScroll={onScroll}>
          <div className={classNames('table-header', { 'is-scroll': isScroll })}>
            <div className="table-cell">Ingredient</div>
            <div className="table-cell">Category</div>
            <div className="table-cell">Default unit</div>
            {/* <div className="table-cell">Nutrition info</div> */}
          </div>
          {data.map((item, index) => {
            const { _id, name, categories = [], default_unit = '', nutrition_info = {} } = item || {};
            const nutritionInfoEmpty = isEmpty(nutrition_info);

            return (
              <div className="item" key={_id}>
                <div className={classNames('text-number', { 'allow-remove': data.length > 1 })}>
                  <span>{index + 1}</span>
                  <DeleteIcon onClick={() => handleRemove(index)} />
                </div>
                <div className="content">
                  <div className="form-item">
                    <InputName
                      onChange={value => handleUpdateField('name', value, index)}
                      hasError={isSubmitted && !(name || '').trim()}
                      isMulti
                      value={name}
                    />
                  </div>
                  <div className="form-item">
                    <CategorySelect
                      label=""
                      options={optionsCategory}
                      values={categories}
                      onChangeCategory={value => handleUpdateField('categories', value, index)}
                      hasError={isSubmitted && isEmpty(categories)}
                      isMulti
                    />
                  </div>
                  <div className="form-item">
                    <UnitSelect
                      label=""
                      data={convertNameListUnit}
                      value={default_unit}
                      onChangeUnit={value => handleUpdateField('default_unit', value, index)}
                      hasError={isSubmitted && isEmpty(default_unit)}
                      isMulti
                    />
                  </div>
                  {/* <div className="form-item">
                    <div
                      onClick={() => handleOpenNutritionInfo(_id, nutrition_info)}
                      className={classNames('nutrition-info', { 'is-empty': nutritionInfoEmpty })}
                    >
                      {nutritionInfoEmpty ? <AddIcon /> : <span>{getNutritionInfo(nutrition_info)}</span>}
                    </div>
                  </div> */}
                </div>
              </div>
            );
          })}
          <div className="item-add">
            <div className="text-number">{data.length + 1}</div>
            <button className="button-add-more" onClick={handleAddMore} disabled={isLoading}>
              <PlusIcon />
            </button>
          </div>
        </S.TableContainer>
      </S.Content>
      <S.Action>
        <div className="left-action">{isSubmitted && hasError && <div className="error-msg">{errorMsg}</div>}</div>
        <div className="right-action">
          <button className="action-btn" onClick={handleDiscardChange} disabled={isLoading}>
            Cancel
          </button>
          <button className={classNames('action-btn', { 'add-btn': true })} onClick={onSubmit} disabled={isLoading}>
            Add
          </button>
        </div>
      </S.Action>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { ingredientLibrary },
  } = state;

  const { isLoading = false, listUnitIngredient = {} } = ingredientLibrary || {};

  return { isLoading, listUnitIngredient };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMultiIngredientModal);
