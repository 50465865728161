import React from 'react';
import ReactTooltip from 'react-tooltip';
import { DateTime } from 'luxon';

import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as RemoveWeek } from 'assets/icons/remove_week.svg';
import { checkSameMonth, formatDateSafari } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

function ButtonRemoveWeek(props) {
  const { weekIndex, startDate, toggleConfirmModal, handleRemoveWeek } = props;
  function onDeleteWeek() {
    const day = DateTime.fromISO(new Date(formatDateSafari(startDate)).toISOString());
    const deleteFromDate = day.plus({ days: 7 * weekIndex });
    const deleteToDate = day.plus({ days: 7 * weekIndex + 6 });
    const isSameMonth = checkSameMonth(deleteFromDate, deleteToDate);
    const deleteFromDateFormat = deleteFromDate.toFormat('LLL d');
    const deleteToDateTitleFormat = isSameMonth ? deleteToDate.toFormat('d') : deleteToDate.toFormat('LLL d');
    const deleteToDateContentFormat = deleteToDate.toFormat('LLL d');
    const startDateRemove = deleteFromDate.toFormat('MM-dd-yyyy');
    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove workouts from ${deleteFromDateFormat} - ${deleteToDateTitleFormat}`}
        content={`All workouts from ${deleteFromDateFormat} to ${deleteToDateContentFormat} will be deleted. Would you like to continue?`}
        onConfirm={() =>
          handleRemoveWeek && handleRemoveWeek({ startDateRemove, deleteFromDateFormat, deleteToDateContentFormat })
        }
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
        className="remove-week-popup"
      />,
    );
  }
  return (
    <>
      <RemoveWeek data-tip data-for="remove-week-on-tooltip" onClick={onDeleteWeek} />
      <ReactTooltip id="remove-week-on-tooltip" effect="solid" place={'right'}>
        <div>Remove Week</div>
      </ReactTooltip>
    </>
  );
}

export default ButtonRemoveWeek;
