import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEqual, sortBy, map } from 'lodash';

// Store
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { updateMetricSettings, changeMetricSettingsRequest, getBodyMetricTargetOverview } from 'actions/bodyMetric';
import { updateMultipleClientMetric } from 'redux/client/client.actionCreators';

import { SPECIAL_METRICS_CODE, DATA_POINT_KEYS, LINE_CHART } from 'components/BodyMetricChartNew/constants';
import { CDN_URL } from 'constants/commonData';

//Assets
import NormalMetricSettingModal from './NormalMetricSetting';
import SleepSetting from './SleepMetricSetting';
import * as S from './style';
import HeartRateSetting from './HeartRateSetting';

const SettingWrapper = props => {
  const {
    toggleModal,
    changeMetricSettingsRequest,
    updateMetricSettings,
    metricTypes,
    bodyMetric,
    toggleConfirmModal,
    clientId,
    updateMultipleClientMetric,
    customBrandingColor,
    getBodyMetricTargetOverview,
  } = props;
  const { metricSettings = {}, unique_code } = bodyMetric;
  const [settings, setSettings] = useState({
    type: LINE_CHART,
    color: customBrandingColor,
    unit: null,
    key_metrics: [],
    data_point: DATA_POINT_KEYS.latest_value,
    display_data_points: false,
  });
  const [selectedSettings, setSelectedSettings] = useState({});
  const [isCheckedUpdateUnit, setIsCheckedUpdateUnit] = useState(true);
  const [isChangeUnit, setIsChangeUnit] = useState(false);

  const handleChangeToggle = () => {
    setIsCheckedUpdateUnit(!isCheckedUpdateUnit);
  };

  const { unitOptions, unit: defaultUnit } = useMemo(() => {
    const metricType = metricTypes.find(item => item.unique_code === unique_code) || {};
    const { category: { units = [] } = {}, unit } = metricType;
    return { unitOptions: units, unit };
  }, [metricTypes, unique_code]);

  useEffect(() => {
    setSettings({ ...metricSettings, unit: metricSettings.unit || defaultUnit });
    setSelectedSettings({ unit: metricSettings.unit || defaultUnit });
  }, [metricSettings]);

  const handleDiscardChange = () => {
    toggleConfirmModal(
      true,
      <S.CustomConfirmModal
        noBorder
        title="Discard Changes?"
        content={`Are you sure you want to go? Changes have not been saved yet.`}
        onConfirm={handleConfirmDiscardChange}
        confirmButtonTitle="Discard changes"
        hasCloseIcon
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
      />,
    );
  };

  const handleConfirmDiscardChange = () => {
    toggleModal(false);
    toggleConfirmModal(false);
  };

  const handleClose = () => {
    const hasNotChange =
      metricSettings.color === settings.color &&
      get(metricSettings, 'unit._id', null) === get(settings, 'unit._id', null) &&
      metricSettings.type === settings.type &&
      isEqual(sortBy(metricSettings.key_metrics), sortBy(settings.key_metrics)) &&
      metricSettings.display_data_points === settings.display_data_points &&
      metricSettings.data_point === (settings || {}).data_point;

    hasNotChange ? toggleModal(false) : handleDiscardChange();
  };

  const handleSelectChartType = type => () => {
    if (type === settings.type) return;
    const dataPointSetting = { data_point: settings.data_point };
    if (type === LINE_CHART && settings.data_point === DATA_POINT_KEYS.all_values) {
      dataPointSetting.data_point = DATA_POINT_KEYS.latest_value;
    }
    setSettings({
      ...settings,
      type: type,
      ...dataPointSetting,
    });
    setSelectedSettings({
      ...selectedSettings,
      type: type,
      ...dataPointSetting,
    });
  };

  const applySetting = async () => {
    toggleModal(false);
    changeMetricSettingsRequest();
    await updateMetricSettings(clientId, selectedSettings, bodyMetric.unique_code);
    const newData = map(metricTypes, item => {
      const { pinned, selected, unit, unique_code, last_pin_at } = item;
      if (unique_code === get(bodyMetric, 'unique_code', '')) {
        return {
          pinned,
          selected,
          unique_code,
          unit: isCheckedUpdateUnit && isChangeUnit ? get(selectedSettings, 'unit._id', '') : unit._id,
          last_pin_at,
        };
      }

      return {
        pinned,
        selected,
        unique_code,
        unit: unit._id,
        last_pin_at,
      };
    });

    await updateMultipleClientMetric({ items: newData, clientId });
    await getBodyMetricTargetOverview({
      client: clientId,
      unique_code: (bodyMetric || {}).unique_code,
    });
  };

  const handleSelectUnit = newUnit => () => {
    const selectedUnit = newUnit || defaultUnit;
    const isChangedUnit = (newUnit || {}).unique_code !== get(metricSettings, 'unit.unique_code', '');
    setIsChangeUnit(isChangedUnit);

    setSettings({ ...settings, unit: selectedUnit });
    setSelectedSettings({ ...selectedSettings, unit: selectedUnit });
  };

  const handleSelectColor = color => {
    const isCustomBrand = color === customBrandingColor;
    setSettings({ ...settings, color: isCustomBrand ? null : color });
    setSelectedSettings({ ...selectedSettings, color: isCustomBrand ? null : color });
  };

  const handleSelectKeyMetric = (key, isSelected = false) => () => {
    const currentKeys = settings.key_metrics || [];
    const keysSetting = isSelected ? currentKeys.filter(item => item !== key) : [...currentKeys, key];
    setSettings({ ...settings, key_metrics: keysSetting });
    setSelectedSettings({ ...selectedSettings, key_metrics: keysSetting });
  };

  const handleDisplayDataPoints = () => {
    setSettings({ ...settings, display_data_points: !settings.display_data_points });
    setSelectedSettings({ ...selectedSettings, display_data_points: !settings.display_data_points });
  };

  const customBrandColor = { label: 'Your brand color', color: customBrandingColor };

  const handleSelectDataPointOption = value => () => {
    setSettings({ ...settings, data_point: value });
    setSelectedSettings({ ...selectedSettings, data_point: value });
  };

  let SettingModal = NormalMetricSettingModal;
  if (bodyMetric.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
    SettingModal = SleepSetting;
  }

  if (bodyMetric.unique_code === SPECIAL_METRICS_CODE.HEART_RATE) {
    SettingModal = HeartRateSetting;
  }

  return (
    <SettingModal
      {...props}
      settings={settings}
      handleClose={handleClose}
      handleSelectChartType={handleSelectChartType}
      handleSelectDataPointOption={handleSelectDataPointOption}
      handleDisplayDataPoints={handleDisplayDataPoints}
      customBrandColor={customBrandColor}
      handleSelectColor={handleSelectColor}
      metricSettings={metricSettings}
      unitOptions={unitOptions}
      handleSelectUnit={handleSelectUnit}
      defaultUnit={defaultUnit}
      isCheckedUpdateUnit={isCheckedUpdateUnit}
      isChangeUnit={isChangeUnit}
      handleChangeToggle={handleChangeToggle}
      handleSelectKeyMetric={handleSelectKeyMetric}
      applySetting={applySetting}
    />
  );
};

const mapStateToProps = state => {
  const {
    user,
    bodyMetric,
    rootReducer: { customBranding, client },
  } = state;
  const { bodymetricTypes, selected } = client;

  return {
    user,
    bodyMetric,
    metricTypes: bodymetricTypes,
    clientId: selected,
    customBrandingColor: get(customBranding, 'originalTheme.primaryColor', ''),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeMetricSettingsRequest: bindActionCreators(changeMetricSettingsRequest, dispatch),
    updateMetricSettings: bindActionCreators(updateMetricSettings, dispatch),
    updateMultipleClientMetric: bindActionCreators(updateMultipleClientMetric, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getBodyMetricTargetOverview: bindActionCreators(getBodyMetricTargetOverview, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingWrapper);
