import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { NewSearchInput } from 'shared/SearchInput';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import * as S from './style';

function Toolbar({ onOpenAddNewForm, isHideFormBanner, changeQueryParams }) {
  const [isHideBanner, setIsHideBanner] = useState(false);

  useEffect(() => {
    setIsHideBanner(isHideFormBanner);
  }, [isHideFormBanner]);

  const handleSearch = (event, { value }) => {
    changeQueryParams({
      page: 1,
      text_search: value,
    });
  };

  const handleClearSearch = () => {
    changeQueryParams({
      page: 1,
      text_search: '',
    });
  };

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 });
    }
  };

  return (
    isHideBanner && (
      <S.ToolbarWrapper>
        <NewSearchInput
          placeholder="Search by keyword or name"
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          onKeyPress={handleKeyPress}
        />
        <S.Button text="Create New Form" icon={<PlusIcon />} onClick={onOpenAddNewForm} data-btn="create-form" />
      </S.ToolbarWrapper>
    )
  );
}

export default Toolbar;
