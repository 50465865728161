import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import isEmpty from 'lodash/isEmpty';

import ItemBodyMetric from './ItemBodyMetric';
import * as S from '../style';
import { useMyContext } from '../context/GroupBodyMetricsContext';
import classNames from 'classnames';

const OrganizeContentGroup = props => {
  const { metrics = [], isEmptyMetric = false, groupId, clientId, textSearch, isDefaultGroup, prefixId } = props;
  const { metricsNormalized, placeholderMetricProps } = useMyContext();

  if (isEmptyMetric) {
    return <S.EmptyMetric className="empty-content-group">No metrics yet</S.EmptyMetric>;
  }

  const renderItemBodyMetric = (metricId, idx) => (
    <ItemBodyMetric
      key={`${groupId}_${metricId}`}
      item={metricsNormalized[metricId]}
      index={idx}
      groupId={groupId}
      clientId={clientId}
      textSearch={!!textSearch}
      isDefaultGroup={isDefaultGroup}
      prefixId={prefixId}
    />
  );

  return (
    <S.ContentGroupWrapper className="content-group">
      <S.ContentHeaderGroup>
        <div>name</div>
        <div>value</div>
        <div>last update</div>
      </S.ContentHeaderGroup>
      <Droppable droppableId={`droppableMetric_${groupId}`} type="METRIC">
        {(provided, snapshot) => (
          <S.ContentBodyGroup
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classNames({
              'show-scrollbar': metrics.length > 6,
            })}
          >
            {metrics.map(renderItemBodyMetric)}
            {provided.placeholder}
            {!isEmpty(placeholderMetricProps) && snapshot.isDraggingOver && (
              <div
                className="placeholder"
                style={{
                  top: placeholderMetricProps.clientY,
                  left: placeholderMetricProps.clientX,
                  height: placeholderMetricProps.clientHeight,
                  width: placeholderMetricProps.clientWidth - 23,
                }}
              />
            )}
          </S.ContentBodyGroup>
        )}
      </Droppable>
    </S.ContentGroupWrapper>
  );
};

export default OrganizeContentGroup;
