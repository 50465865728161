import React from 'react';
import _ from 'lodash';
import { Image, Icon } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { MenuContainer, Wrapper, Item, Trigger } from './styles';
import { Checkbox } from 'shared/FormControl';
import { SharedTooltip } from 'shared/SharedTooltip';

class TableColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { columns, onSelect } = this.props;
    const { open } = this.state;

    return (
      <RootCloseWrapper onRootClose={() => this.setState({ open: false })} event="click" disabled={!open}>
        <Wrapper>
          <Trigger
            onClick={() => this.setState({ open: !open })}
            className={open ? 'open' : ''}
            data-tip
            data-for="data-column"
          >
            <div className="icon column" />
            <Icon name="chevron down" />
            <SharedTooltip content="Data Column" position="bottom" id="data-column" />
          </Trigger>
          {open && (
            <MenuContainer className="columns__menu">
              {_.map(columns, item =>
                item.hideOnList ? null : (
                  <Item key={item.key} className={item.alwaysShow ? 'disabled' : ''}>
                    <Checkbox
                      checked={item.checked}
                      className="columns__checkbox"
                      onChange={event => {
                        onSelect(item, event.target.checked);
                      }}
                      disabled={!!item.alwaysShow}
                      title={
                        <div className="column__title">
                          {item.icon && (
                            <div className="column__icon">
                              <Image src={item.icon} />
                            </div>
                          )}
                          <div>{item.columnName || item.label}</div>
                        </div>
                      }
                    />
                  </Item>
                ),
              )}
            </MenuContainer>
          )}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

export default TableColumns;
