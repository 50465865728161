import React from 'react';
import { ReactComponent as ClientIcon } from 'assets/icons/route_client.svg';
import { ReactComponent as LibraryIcon } from 'assets/icons/route_library.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/route_inbox.svg';
import { ReactComponent as AutoflowIcon } from 'assets/icons/route_autoflow.svg';
import { ReactComponent as StudioIcon } from 'assets/icons/route_studio.svg';
import { ReactComponent as ForumIcon } from 'assets/icons/route_forum.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/route_payment.svg';

export default [
  {
    route: '/home/client',
    name: 'client',
    icon: <ClientIcon />,
    title: 'Clients',
    group: {
      title: 'Clients',
      icon: <ClientIcon />,
      route: '/home/client',
    },
  },
  {
    route: '/home/workout',
    name: 'library',
    subItems: [{ route: '/home/client', title: 'Clients' }],
    isLibrary: true,
    subItems: [
      { route: '/home/exercise', title: 'Exercises', isSub: true },
      { route: '/home/workout', title: 'Workouts', isSub: true },
      { route: '/home/program', title: 'Programs', isSub: true },
      { route: '/home/task-library', title: 'Tasks', isSub: true },
      { route: '/home/forms', title: 'Forms & Questionnaires', isSub: true },
      { route: '/home/meal-plans', title: 'Meal Plan Templates', isSub: true },
      { route: '/home/metric-group', title: 'Metric Groups', isSub: true },
    ],
    icon: <LibraryIcon />,
    isActive: activeItem => activeItem.includes('library'),
    title: 'Clients',
    group: {
      title: 'Library',
      icon: <LibraryIcon />,
      route: '/home/workout',
    },
  },
  {
    route: '/home/inbox',
    name: 'inbox',
    icon: <InboxIcon />,
    title: 'Inbox',
    group: {
      title: 'Inbox',
      icon: <InboxIcon />,
      route: '/home/inbox',
    },
  },
  {
    route: '/home/onboarding-flow',
    name: 'automation',
    icon: <AutoflowIcon />,
    title: 'Automation',
    hideOnMobile: true,
    group: {
      title: 'Automation',
      icon: <AutoflowIcon />,
      route: '/home/onboarding-flow',
    },
    subItems: [
      { route: '/home/onboarding-flow', title: 'Onboarding Flow' },
      { route: '/home/autoflow', title: 'Autoflow' },
    ],
    isActive: activeItem => activeItem.startsWith('onboarding-flow') || activeItem.startsWith('autoflow'),
  },
  {
    route: '/home/studio-programs',
    name: 'studio',
    icon: <StudioIcon />,
    isActive: activeItem => activeItem.includes('studio'),
    title: 'On-demand',
    hideOnMobile: true,
    group: {
      title: 'On-demand',
      icon: <StudioIcon />,
      route: '/home/studio-programs',
    },
    subItems: [
      { route: '/home/studio-collection', title: 'Resource Collections' },
      { route: '/home/workout-collections', title: 'Workout Collections' },
      { route: '/home/studio-programs', title: 'Studio Programs' },
      { route: '/home/on-demand-settings', title: 'Settings' },
    ],
  },
  {
    route: '/home/forums',
    name: 'forums',
    icon: <ForumIcon />,
    title: 'Community Forums',
    hideOnMobile: true,
    group: {
      title: 'Community Forums',
      icon: <ForumIcon />,
      route: '/home/forums',
    },
  },
  {
    route: '/home/packages',
    name: 'package',
    icon: <PaymentIcon />,
    isActive: activeItem =>
      activeItem.startsWith('package') ||
      activeItem.startsWith('marketplace') ||
      activeItem.startsWith('setup') ||
      activeItem.startsWith('payment-activities') ||
      activeItem.startsWith('sequences') ||
      activeItem.startsWith('coach-bios'),
    title: 'Payment',
    hideOnMobile: true,
    group: {
      title: 'Payment & Packages',
      icon: <PaymentIcon />,
      route: '/home/packages',
    },
    subItems: [
      { route: '/home/packages', title: 'Packages' },
      { route: '/home/marketplace', title: 'Marketplace' },
      { route: '/home/payment-activities', title: 'Payment Activity' },
      { route: '/home/setup-payment', title: 'Setup Payment' },
      { route: '/home/coach-bios', title: 'Coach Bios' },
    ],
  },
];
