// Libs
import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

// Constants
import { ON_DEMAND_PLANS } from 'components/OnboardingFlowDetail/constants';
import { ASSET_TYPE } from 'components/Product/constants';
import { TRIAL_MODE } from 'constants/commonData';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check_mark.svg';
import { ReactComponent as UpgradePathAutoflowAndOnDemandIcon } from 'assets/icons/OnboardingFlow/upgrade_path_autoflow_and_on_demand.svg';
import { ReactComponent as UpgradePathOnDemandIcon } from 'assets/icons/OnboardingFlow/upgrade_path_on_demand.svg';
import { ReactComponent as UpgradePathAutoflowAndStudioProgramsIcon } from 'assets/icons/OnboardingFlow/upgrade_path_autoflow_and_studio_programs.svg';
import { ReactComponent as UpgradePathStudioProgramsIcon } from 'assets/icons/OnboardingFlow/upgrade_path_studio_programs.svg';
import { ReactComponent as UpgradePathAutoflowIcon } from 'assets/icons/OnboardingFlow/upgrade_path_autoflow.svg';
import { ReactComponent as UpgradePathTrialModeIcon } from 'assets/icons/OnboardingFlow/upgrade_path_trial_mode.svg';

// Styles
import * as S from './style';

const UpgradePathFloat = props => {
  const { permission, onClose, teamData } = props || {};

  const hasOnboardingGuide = document.querySelector('.onboarding-guide');

  const isOndemandAddOn = permission['studio_resource_collection'] || permission['on_demand_workout'];
  const isStudioProgram = permission[ASSET_TYPE.STUDIO];
  const isAutomationAddOn = permission[ASSET_TYPE.AUTOFLOW];
  const isTrialMode = (teamData || {}).trialMode === TRIAL_MODE.Active;

  const isFullPermission = isOndemandAddOn && isStudioProgram && isAutomationAddOn && !isTrialMode;

  if (isFullPermission) return <></>;

  const { banner: Banner, title, description, actions, buttonAction } = useMemo(() => {
    const actionsMap = {
      onDemand: [
        { contentTitle: 'Resource Collection', targetPlan: ON_DEMAND_PLANS.onDemand },
        { contentTitle: 'Workout Collection', targetPlan: ON_DEMAND_PLANS.onDemand },
        { contentTitle: 'Studio Program', targetPlan: ON_DEMAND_PLANS.studio },
      ],
      studioAndAutomation: [
        { contentTitle: 'Studio Program', targetPlan: ON_DEMAND_PLANS.studio },
        { contentTitle: 'Autoflow', targetPlan: ON_DEMAND_PLANS.automation },
        { contentTitle: 'Multiple Onboarding Flows', targetPlan: ON_DEMAND_PLANS.automation },
      ],
      automation: [
        { contentTitle: 'Autoflow', targetPlan: ON_DEMAND_PLANS.automation },
        { contentTitle: 'Multiple Onboarding Flows', targetPlan: ON_DEMAND_PLANS.automation },
      ],
    };

    const defaultState = {
      banner: null,
      title: '',
      description: '',
      actions: [],
      buttonAction: {},
    };

    // Case: Trial mode
    if (isTrialMode) {
      return {
        banner: UpgradePathTrialModeIcon,
        title: 'Add Automation features',
        description: 'Create and assign multiple onboarding flows for specific coaches and clients.',
        buttonAction: { contentTitle: 'Get This Feature', targetPlan: ON_DEMAND_PLANS.proAndAutomation },
      };
    }

    // Case: Pro plan && dont have on-demand add on && dont have automation add on
    if (!isOndemandAddOn && !isStudioProgram && !isAutomationAddOn) {
      return {
        banner: UpgradePathAutoflowAndOnDemandIcon,
        title: 'Add On-Demand and Automation features',
        actions: [...actionsMap.onDemand, ...actionsMap.automation],
        buttonAction: { contentTitle: 'Get These Features', targetPlan: ON_DEMAND_PLANS.studioAndAutomation },
      };
    }

    // Case: Pro plan && have automation add on && dont have on-demand add on
    if (isAutomationAddOn && !isOndemandAddOn && !isStudioProgram) {
      return {
        banner: UpgradePathOnDemandIcon,
        title: 'Add On-Demand to onboarding flow',
        actions: [...actionsMap.onDemand],
        buttonAction: { contentTitle: 'Get These Features', targetPlan: ON_DEMAND_PLANS.studio },
      };
    }

    // Case: Pro plan && have on-demand add on && dont have automation add on
    if (isOndemandAddOn && !isAutomationAddOn && !isStudioProgram) {
      return {
        banner: UpgradePathAutoflowAndStudioProgramsIcon,
        title: 'Add Studio Program and Automation features',
        actions: [...actionsMap.studioAndAutomation],
        buttonAction: { contentTitle: 'Get These Features', targetPlan: ON_DEMAND_PLANS.studioAndAutomation },
      };
    }

    // Case: Pro plan && have on-demand add on && have automation add on
    if (isAutomationAddOn && isOndemandAddOn && !isStudioProgram) {
      return {
        banner: UpgradePathStudioProgramsIcon,
        title: 'Add Studio Program to onboarding flow',
        description: 'Offer libraries of Netflix-style training programs for members.',
        buttonAction: { contentTitle: 'Get This Feature', targetPlan: ON_DEMAND_PLANS.studio },
      };
    }

    // Case: Studio plan && dont have automation add on
    if (isOndemandAddOn && isStudioProgram && !isAutomationAddOn) {
      return {
        banner: UpgradePathAutoflowIcon,
        title: 'Add Automation features',
        actions: [...actionsMap.automation],
        buttonAction: { contentTitle: 'Get This Feature', targetPlan: ON_DEMAND_PLANS.studioAndAutomation },
      };
    }

    return defaultState;
  }, [permission]);

  const renderActionsUpgradePath = (item, index) => {
    const { contentTitle, targetPlan } = item || {};

    return (
      <S.ActionItem key={index}>
        <CheckMarkIcon className="custom-check-icon" />
        <S.ActionUpgradePath text={contentTitle} planWillSelect={targetPlan} />
      </S.ActionItem>
    );
  };

  return (
    <S.Wrapper hasOnboardingGuide={hasOnboardingGuide}>
      <CloseIcon onClick={onClose} className="custom-close-icon" />
      <S.Container>
        <S.ButtonUpgradePathIcon isHasChildren planWillSelect={(buttonAction || {}).targetPlan}>
          <Banner />
        </S.ButtonUpgradePathIcon>
        <S.ContentWrapper>
          <S.ContentContainer>
            <S.Title>{title}</S.Title>
            {isEmpty(actions) ? (
              <S.Description>{description}</S.Description>
            ) : (
              <S.ActionsWrapper>{actions.map(renderActionsUpgradePath)}</S.ActionsWrapper>
            )}
          </S.ContentContainer>
          <S.ButtonUpgradePath
            text={(buttonAction || {}).contentTitle}
            planWillSelect={(buttonAction || {}).targetPlan}
          />
        </S.ContentWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default UpgradePathFloat;
