import React from 'react';
import get from 'lodash/get';
import { Toggle } from 'shared/FormControl';
import { ReactComponent as SurveyIcon } from 'assets/icons/survey_item_icon.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/primary_settings.svg';
import * as S from './styles';
import { pluralize } from 'utils/commonFunction';
import SurveySelect from './CustomSelect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSurveyList } from 'redux/survey-contact/actions';
import { toggleModal } from 'actions/modal';
import SurveyModal from '../SurveyModal';
import SurveyPreviewDetailModal from '../SurveyPreviewDetailModal';
import { updatePackageSurveyFlag } from 'redux/package-detail/actions';

const SurveyPurchaseOption = ({
  value = false,
  onChange,
  disabled = false,
  toggleModal,
  updatePackageSurveyFlag,
  workingPackage,
  errorMessage,
  onChangeSurvey,
}) => {
  const packageData = workingPackage ? workingPackage.toJS() : null;
  const packagePurchaseOption = get(packageData, 'package_purchase_option', null);
  const survey = get(packagePurchaseOption, 'survey');

  const labelPreview = value
    ? 'Clients will be prompted to fill out a survey with their request'
    : 'Clients will be provided an optional message field only';
  const handleChangeSurvey = newData => {
    if (!newData) return;

    const newSurveyId = get(newData, 'id', '');
    const oldSurveyId = get(packagePurchaseOption, 'survey.survey_id', '');

    const package_purchase_option = {
      ...packagePurchaseOption,
      survey_id: get(newData, 'id'),
      survey: {
        ...packagePurchaseOption.survey,
        survey_name: get(newData, 'survey_name'),
        questions: get(newData, 'questions'),
      },
    };
    if (onChangeSurvey) {
      onChangeSurvey(newSurveyId !== oldSurveyId);
    }
    updatePackageSurveyFlag({ has_survey_list: true, package_purchase_option });
  };

  const handleChange = event => {
    const checked = get(event, 'target.checked', false);
    onChange(checked);
  };

  const handleManageSurvey = () => {
    if (!toggleModal) return;
    toggleModal(true, <SurveyModal onClose={() => {}} onChangeSurvey={onChangeSurvey} />);
  };

  if (disabled) {
    const surveyName = get(survey, 'survey_name');
    const authorName = get(survey, 'author_name', '');
    const totalQuestions = get(survey, 'questions.length', 0);
    return (
      <S.SwitchPurchaseOptionWrapper>
        <S.PurchasePreviewValue>{labelPreview}</S.PurchasePreviewValue>
        {survey && value ? (
          <S.SurveyViewModeItem>
            <SurveyIcon />
            <div className="survey__info">
              <h6>{surveyName}</h6>
              <S.SurveyInformationOptionWrapper>
                <span className="survey__info-questions">
                  <b>{totalQuestions}</b> {pluralize('Question', totalQuestions)}
                </span>
                <div className="dot" />
                <span className="author-name">{authorName}</span>
              </S.SurveyInformationOptionWrapper>
            </div>
            <span
              className="survey__action"
              onClick={() => toggleModal(true, <SurveyPreviewDetailModal survey={survey} onClose={() => {}} />)}
            >
              View
            </span>
          </S.SurveyViewModeItem>
        ) : null}
      </S.SwitchPurchaseOptionWrapper>
    );
  }

  return (
    <S.SwitchPurchaseOptionWrapper>
      <S.SurveyEditModeActions>
        <Toggle
          disabled={disabled}
          checked={value}
          onChange={event => {
            handleChange(event);
            handleChangeSurvey('');
          }}
          width={28}
          height={16}
          label={<span>Ask clients to fill out a survey</span>}
        />
        {!!value && (
          <div className="manage-action" onClick={handleManageSurvey}>
            <SettingIcon />
            <span>Manage Surveys</span>
          </div>
        )}
      </S.SurveyEditModeActions>
      {!!value && (
        <div>
          <SurveySelect
            key="survey-select"
            name="survey"
            value={get(packagePurchaseOption, 'survey_id', '')}
            onChange={newSurvey => {
              handleChangeSurvey(newSurvey);
            }}
            placeholderInput={{
              text: 'Select your survey',
              mask: {
                surveyName: get(survey, 'survey_name', ''),
                authorName: get(survey, 'author_name', ''),
                questions: get(survey, 'questions', 0),
              },
            }}
            error={!!errorMessage}
            onChangeSurvey={onChangeSurvey}
          />
          {!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        </div>
      )}
    </S.SwitchPurchaseOptionWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { surveyContact, packageDetail },
  } = state;

  return {
    workingPackage: packageDetail.get('workingPackage'),
    surveyList: get(surveyContact, 'surveyList', { total: 0, data: [] }),
    loading: get(surveyContact, 'loading', false),
  };
};

const mapDispatchToProps = dispatch => ({
  getSurveyList: bindActionCreators(getSurveyList, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updatePackageSurveyFlag: bindActionCreators(updatePackageSurveyFlag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPurchaseOption);
