import styled from 'styled-components';
import * as ModalS from '../style';

export const Wrapper = styled.div`
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0px 20px 0px #1e2e671a;

  ${ModalS.Title} {
    padding: 24px 24px 17px;
    background: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    &.large-title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: #151619;
      padding: 25px 24px 15px;
      letter-spacing: -0.02em;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  padding: 0px;
`;

export const PreviewEmailWrapper = styled.div`
  width: 532px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  border-radius: 4px;

  .text {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    margin-bottom: 0px;
    color: #202353;
  }

  .bold-text {
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
  }
`;

export const DeclineReasonWrapper = styled.div`
  flex-shrink: 0;
  width: 402px;
  box-shadow: -1px 0px 15px 0px #1e2e670d;
`;

export const DeclineReasonContent = styled.div`
  margin-top: -1px;
  padding: 0px 23px;
`;

export const EmailTemplate = styled.div`
  background: #e9ecef;
  padding: 24px 32px 23px 32px;
  height: fit-content;
  max-height: 548px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Inter', 'Open Sans';

  .preview-email {
    width: 468px;
  }

  .preview-banner {
    height: 108px;
    background-color: #f5f5f5;
  }

  .preview-content {
    background: #f5f5f5;
    padding: 0 24px 33px;

    &_information {
      padding: 32px 32px 31px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: -28px;
      position: relative;
      z-index: 1;

      b {
        white-space: pre-wrap;
        word-break: break-word;
      }

      h6 {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        color: #141414;
        margin-bottom: 16px;
        margin-top: 0px;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #141414;
        margin-bottom: 8px;
        white-space: pre-wrap;
        word-break: break-word;
        &:last-child {
          margin-bottom: 0px;
        }
      }

      .decline-reason {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #141414;
        margin-bottom: 10px;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        p {
          min-height: 18px;
          margin-bottom: 0px;
        }
      }
    }

    &_footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 18px;
      margin-top: 24px;

      .footer-social {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .footer-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 9px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        color: #141414;
        opacity: 0.4;
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
