import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import { LeaderboardModal, Tip, SelectRankingGroup } from './style';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import FormItem from 'shared/FormItem';
import Duration from '../Duration';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleConfirmModal } from 'actions/modal';
import { initialValues } from './helper';
import { CDN_URL } from 'constants/commonData';

function LeaderboardForm({ workingItem, onClose, types, template, toggleConfirmModal, onSave }) {
  const rankingTypes = useMemo(() => types.toJS(), [types]);

  const originalValues = useMemo(() => {
    return initialValues(workingItem, template, rankingTypes);
  }, [workingItem, template, rankingTypes]);

  const [type, setType] = useState(originalValues.type);
  const [orderBy, setOrderBy] = useState(originalValues.orderBy);
  const [title, setTitle] = useState(originalValues.title);
  const [description, setDescription] = useState(originalValues.description);
  const [orderList, setOrderList] = useState(originalValues.orderList);
  const [duration, setDuration] = useState({ ...originalValues.duration });
  const [highlightEndTime, setHighlightEndTime] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (type) {
      setOrderList(type.orderBy);
      setOrderBy(type.orderBy[0]);
      setTitle(type.title || type.label);
      setDescription(type.placeholder);
    }
  }, [type]);

  const onSubmit = async event => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (!title.trim() || !duration.endDate || !duration.endTime) {
      return setShowError(true);
    }

    setIsSubmitting(true);

    const requestBody = {
      description: description.trim(),
      name: title.trim(),
      ranking_type: type.value,
      order_by: orderBy.value,
      unit: _.get(type, 'unitInfo.unique_code', _.get(type, 'unit.value', '')),
      unit_id: _.get(type, 'unitInfo._id', null),
      start_date: moment(
        `${duration.startDate.format('MM-DD-YYYY')} ${duration.startTime.value}`,
        'MM-DD-YYYY HH:mm',
      ).format(),
      end_date: moment(
        `${duration.endDate.format('MM-DD-YYYY')} ${duration.endTime.value}`,
        'MM-DD-YYYY HH:mm',
      ).format(),
    };

    try {
      await onSave(requestBody);
      onClose();

      if (workingItem) {
        toast('The leaderboard has been updated, this action might take several minutes');
      } else {
        toast('Leaderboard was created successfully');
      }
    } catch {
      setIsSubmitting(false);
    }
  };

  const onChangeTitle = event => {
    const { value } = event.target;
    setTitle(value);
  };

  const onChangeDescription = event => {
    const { value } = event.target;
    setDescription(value);
  };

  const onChangeType = value => {
    setType(value);
  };

  const onChangeOrderBy = value => {
    setOrderBy(value);
  };

  const onChangeDuration = (name, value) => {
    setDuration(prev => ({ ...prev, [name]: value }));
    setHighlightEndTime(false);
  };

  const checkingBeforeClose = () => {
    const newData = { title, description, type, orderBy };
    const oldData = _.omit(originalValues, ['duration', 'orderList']);

    if (
      !_.isEqual(newData, oldData) ||
      !duration.startDate.isSame(originalValues.duration.startDate, 'day') ||
      (duration.endDate && !duration.endDate.isSame(originalValues.duration.endDate, 'day')) ||
      !_.isEqual(duration.startTime, originalValues.duration.startTime) ||
      !_.isEqual(duration.endTime, originalValues.duration.endTime)
    ) {
      toggleConfirmModal(true, <ConfirmModal onConfirm={onClose} />);
    } else {
      onClose();
    }
  };

  return (
    <LeaderboardModal
      className="modal--leaderboard-form"
      open={true}
      onClose={checkingBeforeClose}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <LeaderboardModal.Content>
        <form onSubmit={onSubmit}>
          <ModalLayout.Wrapper>
            <ModalLayout.Header className="modalLayout__header">
              {workingItem ? 'Edit Leaderboard' : 'Create Leaderboard'}
            </ModalLayout.Header>
            <ModalLayout.Content className="modalLayout__content">
              <div className="inputsContainer">
                <SelectRankingGroup>
                  <FormItem
                    inputType="select"
                    title="Leaderboard ranking by"
                    isDisabled={!!workingItem}
                    components={!!workingItem ? { DropdownIndicator: null } : null}
                    options={rankingTypes}
                    getOptionLabel={option =>
                      `${option.label} (${_.get(option, 'unitInfo.unique_code', _.get(option, 'unit.value', ''))})`
                    }
                    getOptionValue={option => option.value}
                    placeholder="-"
                    onChange={onChangeType}
                    className="select-ranking-type"
                    inputClass="ranking type"
                    value={type}
                    isSearchable={false}
                    menuPosition="fixed"
                    styles={{
                      control: base => ({ ...base, minHeight: 42, height: 42 }),
                      menu: base => ({ ...base, marginTop: 2, marginBottom: 5 }),
                    }}
                  />
                  <FormItem
                    inputType="select"
                    components={!!workingItem || !orderList.length < 2 ? { DropdownIndicator: null } : null}
                    title="ordered by"
                    isDisabled={!!workingItem || !orderList.length < 2}
                    options={orderList}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    placeholder="-"
                    onChange={onChangeOrderBy}
                    inputClass="ranking orderBy"
                    className="select-order-by"
                    value={orderBy}
                    isSearchable={false}
                    menuPosition="fixed"
                    styles={{
                      control: base => ({ ...base, minHeight: 42, height: 42 }),
                      menu: base => ({ ...base, marginTop: 2, marginBottom: 5 }),
                    }}
                  />
                  {type.description ? <Tip>{type.description}</Tip> : null}
                </SelectRankingGroup>
                <FormItem
                  title={
                    <>
                      <span>Title</span>
                      <span style={{ opacity: 0.3, fontSize: 11, color: '#202353' }}>{title.length} / 25</span>
                    </>
                  }
                  maxLength="25"
                  type="text"
                  placeholder="Leaderboard Title"
                  value={title}
                  onChange={onChangeTitle}
                  autoFocus={true}
                  error={showError && !title.trim() ? 'Please enter leaderboard title' : ''}
                />
                <FormItem
                  title={
                    <>
                      <span>Description</span>
                      <span style={{ opacity: 0.3, fontSize: 11, color: '#202353' }}>{description.length} / 60</span>
                    </>
                  }
                  type="text"
                  placeholder="Leaderboard Description"
                  value={description}
                  onChange={onChangeDescription}
                  inputType="textarea"
                  maxLength="60"
                />
              </div>
              <Duration
                onChange={onChangeDuration}
                duration={duration}
                highlightEndTime={highlightEndTime}
                shouldShowError={showError}
                isEditing={!!workingItem}
              />
            </ModalLayout.Content>
            <ModalLayout.Actions className="modalLayout__actions">
              <Button type="button" onClick={checkingBeforeClose}>
                Cancel
              </Button>
              <Button type="submit" purple>
                {!!workingItem ? 'Update' : 'Create'}
              </Button>
            </ModalLayout.Actions>
          </ModalLayout.Wrapper>
        </form>
      </LeaderboardModal.Content>
    </LeaderboardModal>
  );
}

const mapState = state => {
  const { leaderboard } = state.rootReducer;

  return { types: leaderboard.get('rankingTypes') };
};

const actions = { toggleConfirmModal };

export default connect(mapState, actions)(LeaderboardForm);
