import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { LEADERBOARD_STATUS } from 'constants/commonData';

const InfoEnding = ({ status, startDate, endDate, onCompleted }) => {
  const [duration, setDuration] = useState(moment.duration(moment(endDate).diff(moment())));

  const end = useRef(moment(endDate));
  const intervalId = useRef();
  const firstInterval = useRef();

  useEffect(() => {
    end.current = moment(endDate);
  }, [endDate]);

  useEffect(() => {
    let count;

    const updateDuration = callback => {
      const diff = end.current.diff(moment());

      if (diff <= 0) {
        onCompleted && onCompleted();
        if (intervalId.current) clearInterval(intervalId.current);
      } else {
        setDuration(moment.duration(diff));
        if (callback) callback();
      }
    };

    const timerMinute = () => {
      const diff = end.current.diff(moment());
      if (diff <= 60000) {
        timerSecond();
      } else {
        if (intervalId.current) clearInterval(intervalId.current);

        intervalId.current = setInterval(() => {
          updateDuration(() => {
            const diff = end.current.diff(moment());

            if (diff <= 60000) {
              timerSecond();
            }
          });
        }, 60 * 1000);
      }
    };

    const timerSecond = () => {
      if (intervalId.current) clearInterval(intervalId.current);

      intervalId.current = setInterval(() => {
        updateDuration();
      }, 1000);
    };

    if (status === LEADERBOARD_STATUS.LIVE) {
      updateDuration();
      const diff = end.current.diff(moment());

      if (diff <= 60000) {
        timerSecond();
      } else {
        count = (60 - moment().get('seconds')) * 1000;

        firstInterval.current = setTimeout(() => {
          if (intervalId.current) {
            clearInterval(firstInterval.current);
          }

          updateDuration(() => {
            timerMinute();
          });
        }, count);
      }
    }
  }, [endDate, status]);

  if (status === LEADERBOARD_STATUS.UPCOMING) return 'Upcoming';
  if (status === LEADERBOARD_STATUS.COMPLETED) return 'Completed';
  if (duration.get('days') >= 7 || duration.months() > 0)
    return `Ending on ${
      !moment(endDate).isSame(moment(startDate), 'year')
        ? `${moment(endDate).format('MMM D, YYYY')}`
        : `${moment(endDate).format('MMM D')}`
    }`;
  if (duration.get('days') >= 2) return `Ending in ${duration.get('days') + 1}d`;
  if (duration.get('days') >= 1) return `Ending in ${duration.get('days')}d:${duration.get('hours')}h`;
  if (duration.get('hours') >= 1) return `Ending in ${duration.get('hours')}h:${duration.get('minutes')}m`;
  if (duration.get('minutes') >= 1) return `Ending in ${duration.get('minutes')}m`;
  if (duration.get('minutes') < 1) return `Ending in ${duration.get('seconds')}s`;

  return '';
};

export default InfoEnding;
