import { get } from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';
import { PRODUCT_STATUS } from '../constants';
import { ProductListNavArea, Title, Name, Status } from './style';

const handleRenderStatus = status => {
  switch (status) {
    case PRODUCT_STATUS.PUBLISH:
      return <Status status={status}>{`${status}ed`}</Status>;
    case PRODUCT_STATUS.ARCHIVE:
      return <Status status={status}>{`${status}d`}</Status>;
    case PRODUCT_STATUS.NEW:
      return;
    default:
      return <Status status={status}>{status}</Status>;
  }
};

const ProductNameStatus = props => {
  const productDetail = props.productDetail.toJS();
  const productName = get(productDetail, 'name');

  return (
    <>
      {productName && (
        <Helmet>
          <title>{productDetail.name} - Sequences - Everfit</title>
        </Helmet>
      )}
      <ProductListNavArea>
        <Title>
          <Name>{productDetail.name}</Name>
          {productDetail.status && handleRenderStatus(productDetail.status)}
        </Title>
      </ProductListNavArea>
    </>
  );
};

export default ProductNameStatus;
