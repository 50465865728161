import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { DateTime } from 'luxon';

import {
  getListWorkouts,
  changeViewMode,
  changeStartDate,
  viewWorkoutDetail,
  arrangeExerciseInsideWorkout,
  moveExerciseOutsideWorkout,
  moveWorkout,
  resetData,
  resetCopyItem,
} from 'redux/autoflow/training/actions';
import {
  handleResetSelectedMultipleWorkout,
  handleDeleteMultipleWorkout,
  handleStartCopyMultipleWorkout,
  handleSelectWeekToCopy,
  handlePasteMultipleWorkoutToWeek,
  handleRemoveWeek,
  handleResetSelectedWeek,
} from 'redux/autoflow/training/copy-remove-week-workout.actions';
import CalendarLayout from './Layout';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { autoflow, permission } = rootReducer;
  const { copying, selectedWeek, selectedWorkouts, viewDetailStatus, isLoadingWorkouts } = autoflow.training;
  const { viewMode, startDate, startWeek } = autoflow.common;
  const { features } = autoflow.settings;
  const trainingSetting = features.find(item => item.type === 'training');
  const featureTurnOff = trainingSetting ? !trainingSetting.state : false;

  return {
    viewMode,
    startDate,
    copying,
    featureTurnOff,
    selectedWeek,
    selectedWorkouts,
    viewDetailStatus,
    startWeek,
    permission,
    isLoadingWorkouts,
    assignments: get(autoflow, 'training.assignments', []),
  };
};

const handleDragEndWorkout = (dispatch, result, autoflow) => {
  const { source, destination } = result;
  const newIndex = destination.index;
  const fromIndex = source.index;
  const oldDay = source.droppableId.split('_')[1];
  const timezone = DateTime.local().zoneName;
  const newDay = destination.droppableId.split('_')[1];
  const assignmentId = result.draggableId.split('_')[1];
  if (oldDay === newDay && fromIndex === newIndex) {
    return;
  }
  const params = {
    oldDay,
    fromIndex,
    newIndex,
    timezone,
    newDay,
    assignmentId,
    autoflow,
  };
  dispatch(moveWorkout(params));
};

const onDragEnd = (dispatch, result, autoflow) => {
  const { source, destination } = result;

  if (!autoflow || !source || !destination) {
    return;
  }

  if (result.type !== 'exercise') {
    return handleDragEndWorkout(dispatch, result, autoflow);
  }

  const [newDay, workoutDestinationId] = destination.droppableId.split(';');
  const [oldDay, fromWorkout] = source.droppableId.split(';');
  const sectionId = result.draggableId.split(';')[2];

  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return;
  }

  if (destination && source.droppableId !== destination.droppableId) {
    const newIndex = parseInt(destination.index);
    const oldIndex = parseInt(source.index);
    const params = {
      id: fromWorkout,
      workoutDestinationId,
      sectionId,
      newIndex,
      oldIndex,
      oldDay,
      newDay,
      autoflow,
    };
    dispatch(moveExerciseOutsideWorkout(params));
  } else {
    const newIndex = parseInt(destination.index);
    const oldIndex = parseInt(source.index);
    const params = {
      sectionId,
      newIndex,
      oldIndex,
      id: fromWorkout,
      autoflow,
      oldDay,
      newDay,
    };

    dispatch(arrangeExerciseInsideWorkout(params));
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDidMount: match => {
    const { workoutId } = match.params;
    dispatch(getListWorkouts());

    if (workoutId) {
      dispatch(viewWorkoutDetail(workoutId));
    }
  },
  onDragEnd: result => onDragEnd(dispatch, result, ownProps.match.params.autoflowId),
  onWillUnmount: bindActionCreators(resetData, dispatch),
  changeViewMode: bindActionCreators(changeViewMode, dispatch),
  changeStartDate: bindActionCreators(changeStartDate, dispatch),
  resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  handleStartCopyMultipleWorkout: bindActionCreators(handleStartCopyMultipleWorkout, dispatch),
  handleDeleteMultipleWorkout: bindActionCreators(handleDeleteMultipleWorkout, dispatch),
  handleResetSelectedMultipleWorkout: bindActionCreators(handleResetSelectedMultipleWorkout, dispatch),
  handleSelectWeekToCopy: bindActionCreators(handleSelectWeekToCopy, dispatch),
  handlePasteMultipleWorkoutToWeek: bindActionCreators(handlePasteMultipleWorkoutToWeek, dispatch),
  handleRemoveWeek: bindActionCreators(handleRemoveWeek, dispatch),
  handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLayout);
