import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 22px 30px 27px;
`;

export const LeftActions = styled.div`
  .onboarding-library-search {
    width: 265px;
    height: 36px;
    input {
      background: url(${CDN_URL}/images/search_light.svg) no-repeat;
      padding-left: 40px;
      background-size: auto;
      background-position: 15px center;
      background-color: #f8f8f8;
      color: #2a394c;
      &:hover,
      &:focus {
        background-color: #fff;
        border: 1px solid #5559ff;
      }
    }
    input::placeholder {
      font-size: 13px !important;
      line-height: 18px !important;
      color: #bababa !important;
    }
    svg {
      right: 9.5px;
      g {
        path {
          fill: #a3a3b5 !important;
        }
      }
    }
  }
`;

export const RightActions = styled.div``;
