import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Mixpanel } from 'utils/mixplanel';
// import containers
import AddNoteModal from './AddNoteModal';
// import actions
import { toggleModal } from 'actions/modal';
import { getMacroNote } from 'redux/macros/actions';
// import assets
import { ReactComponent as NoteIcon } from 'assets/icons/note.svg';
import NoteStyles from './styles';

const Note = ({ clientId, dispatch }) => {
  const [macroNote, setMacroNote] = useState('');

  useEffect(() => {
    dispatch(getMacroNote(clientId)).then(response => {
      setMacroNote(get(response, 'data.data.note'));
    });
  }, []);

  const openAddNoteModal = () => {
    Mixpanel.track('macro-note-open-popup');
    dispatch(toggleModal(true, <AddNoteModal clientId={clientId} noteData={macroNote} setMacroNote={setMacroNote} />));
  };

  return (
    <NoteStyles>
      <NoteIcon
        className={`note-icon ${macroNote && 'active'}`}
        data-tip
        data-for="macro-note"
        onClick={openAddNoteModal}
        clientId={clientId}
      />
      <ReactTooltip className="app-tooltip" id="macro-note" effect="solid" place={'top'} delayShow={200}>
        <p>Add Macros Note</p>
      </ReactTooltip>
    </NoteStyles>
  );
};

export default connect(null)(Note);
