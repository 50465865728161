import styled, { css } from 'styled-components';

export const Header = styled.div`
  padding: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000;
`;

export const Body = styled.div`
  padding: 5px 20px;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column-reverse;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  height: auto;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  outline: none !important;

  ${props =>
    props.empty &&
    css`
      ${Body} {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    `}
`;

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: -2px 0px 16px rgba(108, 108, 108, 0.1), inset 1px 0px 0px #dde2ed;
  width: 360px;
`;

export const HighFiveMessage = styled.div`
  padding-bottom: 10px;
  margin: -8px 30px 8px 20px;
  border-bottom: 0.5px solid #e3e3e5;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #1e0a3c;

  span {
    font-weight: 700;
  }

  .others-name:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .__react_component_tooltip.high-five-tooltip {
    background: #2d2e2d;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;

    p:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .__react_component_tooltip.type-dark.place-bottom:after {
    border-bottom-width: 3px;
  }

  .__react_component_tooltip.place-bottom:after {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    top: -3px;
    left: 53%;
    margin-left: -8px;
  }
`;

export const HighFiveWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: #fffcfc;
  box-shadow: 0px 0px 8.57143px rgba(71, 71, 71, 0.15);
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: calc(100% + 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const ListCommentWrapper = styled.div`
  margin-bottom: auto;
`;
