import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { DropdownIndicator } from 'shared/Icons';

const CustomSelect = styled(Select)`
  border-radius: 5px;
  outline: none;
  min-width: 140px;
  font-size: 13px;

  .single-select__control {
    border: none;
    background: #f2f3f5;
    min-height: 34px;

    :hover,
    &--is-focused,
    &--menu-is-open {
      border-color: #5158cf;
      box-shadow: 0 0 0 1px #5158cf;
    }
  }

  .single-select__placeholder {
    color: #000000;
  }

  .single-select__indicator-separator {
    display: none;
  }

  .single-select__option {
    background-color: #ffffff;
    cursor: pointer;

    :hover,
    &--is-selected {
      color: #5158cf;
      font-weight: 600;
    }
  }
`;

const options = [
  { value: 'all_level', label: 'All Levels' },
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'advanced', label: 'Advanced' },
];

export default function ProgramLevelSelect(props) {
  const { disabled = false } = props;

  const getValue = value => {
    return options.find(o => value && o.value === value);
  };

  return (
    <CustomSelect
      components={{ DropdownIndicator }}
      classNamePrefix="single-select"
      className="single-select-container"
      placeholder="Program Level"
      onChange={props.onChange}
      options={options}
      value={getValue(props.value)}
      isDisabled={disabled}
    />
  );
}
