import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';

import * as S from './style';

const CreateAnAccountModal = ({ onClose, onSubmit }) => {
  return (
    <S.CustomModal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <img src="/images/close_circle.svg" alt="" />
        </CloseButton>
      }
      onClose={onClose}
      closeOnDimmerClick={false}
      className="custom-modal-create-account"
    >
      <Modal.Content>
        <S.Container>
          <div className="header">
            <img src="/images/warning_purple.svg" alt="" className="header-icon" />
            <span className="header-title">Create an account</span>
          </div>
          <div className="content">
            This account is already linked to an Everfit platform. Do you want to use the same login for this platform,
            too?
          </div>
          <div className="actions">
            <button className="cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="create" onClick={onSubmit}>
              Create account
            </button>
          </div>
        </S.Container>
      </Modal.Content>
    </S.CustomModal>
  );
};

export default CreateAnAccountModal;
