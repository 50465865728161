import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
`;

export const CustomTag = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 3px 6px;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  color: #ffffff;
`;

export const ExerciseItemWrapper = styled.div`
  position: relative;

  .exercise-item__title {
    flex: 1 1;

    &--text {
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      color: #202353;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      -webkit-box-orient: vertical;

      :hover {
        color: #5158cf;
      }
    }
  }

  &.list {
    .exercise-item__title {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      align-items: flex-start;

      &--text {
        &.custom {
          -webkit-line-clamp: 1;
        }
      }

      ${CustomTag} {
        margin-top: 5px;
        background: #f6f7f9;
        border-radius: 2px;
        color: #20235350;
      }
    }
  }

  &.card {
    .exercise-item__title {
      flex: 1 1;
      padding: 10px;
    }

    ${CustomTag} {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
`;

export const ExerciseListWrapper = styled.div``;

export const AddExerciseButton = styled(Button)`
  padding: 6px 20px 6px 35px;
  background-image: url(${CDN_URL}/images/plus_short_thin_purple.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5158cf;
  border-color: #5158cf;

  :hover {
    background-color: #5158cf;
    color: #fff;
    background-image: url(${CDN_URL}/images/plus_short_thin_white.svg);
  }
`;
