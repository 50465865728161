import React from 'react';
import { ASSET_TYPE } from 'components/Product/constants';
import AutoflowAsset from './AutoflowAsset';
import ResourceAsset from './ResourceAsset';
import StudioAsset from './StudioAsset';
import ForumAsset from './ForumAsset';
import ProgramAsset from './ProgramAsset';
import WorkoutCollectionAsset from './WorkoutCollectionAsset';

export default function SelectedAsset({ data, onDeleteAsset, isEdit, cloudfrontList }) {
  const handleDeleteAsset = () => {
    onDeleteAsset(data);
  };
  switch (data.asset_type) {
    case ASSET_TYPE.AUTOFLOW:
      return <AutoflowAsset data={data} onDeleteAsset={handleDeleteAsset} isEdit={isEdit} />;

    case ASSET_TYPE.RESOURCE:
      return (
        <ResourceAsset data={data} onDeleteAsset={handleDeleteAsset} isEdit={isEdit} cloudfrontList={cloudfrontList} />
      );

    case ASSET_TYPE.STUDIO:
      return (
        <StudioAsset data={data} onDeleteAsset={handleDeleteAsset} isEdit={isEdit} cloudfrontList={cloudfrontList} />
      );

    case ASSET_TYPE.FORUM:
      return (
        <ForumAsset data={data} onDeleteAsset={handleDeleteAsset} isEdit={isEdit} cloudfrontList={cloudfrontList} />
      );

    case ASSET_TYPE.PROGRAM:
      return <ProgramAsset data={data} onDeleteAsset={handleDeleteAsset} isEdit={isEdit} />;

    case ASSET_TYPE.WORKOUT_COLLECTION:
      return (
        <WorkoutCollectionAsset
          data={data}
          onDeleteAsset={handleDeleteAsset}
          isEdit={isEdit}
          cloudfrontList={cloudfrontList}
        />
      );

    default:
      return null;
  }
}
