import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './style';

const ProgressRing = props => {
  const { progressBarWidth, progress, progressBarColor, progressBarBackground } = props;
  const radius = props.radius || 26;
  const stroke = progressBarWidth || 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Container className="progress-ring">
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={progressBarBackground || 'rgba(255, 255, 255, 0.4)'}
          fill="transparent"
          strokeWidth={stroke}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={progressBarColor || 'white'}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </Container>
  );
};

ProgressRing.propTypes = {
  radius: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  progressBarWidth: PropTypes.number,
  progressBarColor: PropTypes.string,
  progressBarBackground: PropTypes.string
};

export default ProgressRing;
