import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import moment from 'moment';

import { Divide, Wrapper } from './style';
import { getPeriodName, arrayToString } from 'utils/commonFunction';
import TermPolicy from 'components/PricingPlan/PlanSummary/TermPolicy';
import PromomotionCost from 'components/PricingPlan/ConfirmPayment/PaymentSummary/PromomotionCost';
import Subtotal from './SubTotal';
import ProrationCost from 'components/PricingPlan/ConfirmPayment/PaymentSummary/ProrationCost';
import {
  calculatePaymentObject,
  getAddOnsCoupon,
  getPriceByPeriod,
  getPromoOnlyZapier,
} from 'components/PricingPlan/helper';

function ZapierPaymentSummary(props) {
  const { selectPackage: initSelectPackage, addOnsPricing, paymentInfo, teamData, downgrade, permission } = props;
  const {
    zapier_quantity: currentZapierQuantity = 0,
    period: teamPeriod = '',
    expiredDate: teamExpiredDate = '',
    addOns: teamAddOns = [],
    packageNoClients: teamPackageNoClients = false,
  } = teamData;

  // Force logic hide promo code not available for add ons zapier
  const selectPackage = useMemo(() => {
    const result = { ...initSelectPackage };

    const metadataPromoCode = _.get(initSelectPackage, 'promoCode.metadata');

    if (_.isEmpty(metadataPromoCode) || _.get(metadataPromoCode, 'add_ons') === 'zapier') {
      result.promoCode = initSelectPackage.promoCode;
    } else {
      delete result.promoCode;
    }

    return result;
  }, [initSelectPackage]);

  const {
    addOns: selectedAddOns = [],
    period: selectPeriod = '',
    zapier_quantity: selectedZapierQuantity = 0,
    packagePrice: selectPackagePrice = {},
  } = selectPackage;

  const price = getPriceByPeriod(selectPeriod, selectPackagePrice);
  const isAnnually = selectPeriod === 'annually' && teamPeriod === 'annually';
  const isMonthly = selectPeriod === 'monthly' && teamPeriod === 'monthly';
  const isDowngradeZapier = selectedZapierQuantity < currentZapierQuantity;
  const isZapierMeter = _.get(permission, 'zapier_on_demand_charge', false);

  const countAddedAddOns = useMemo(() => {
    let count = 0;
    const addOnsBefore = teamAddOns;
    const addOnsSelected = selectedAddOns;
    for (const key in teamAddOns) {
      if (addOnsBefore.hasOwnProperty(key) && addOnsSelected.hasOwnProperty(key)) {
        if (addOnsBefore[key] === false && addOnsSelected[key] === true) {
          count++;
        }
      }
    }
    return count;
  }, [teamAddOns, selectPackage]);

  const isDowngradeZapierKeepBasePlan =
    selectedZapierQuantity < currentZapierQuantity &&
    isMonthly &&
    !isAnnually &&
    _.isEqual(selectedAddOns, teamAddOns) &&
    _.get(selectPackage, 'packagePrice.packageNoClients', false) === teamPackageNoClients;

  const isBothZapAndBase = selectPackage.will_schedule && selectPackage.will_zapier_schedule;

  const isDowngradeAnnually = () => {
    if (isBothZapAndBase) return false; // Return false if isBothZapAndBase is true.
    const isDowngrade =
      (selectPackage.will_schedule ||
        (isDowngradeZapier &&
          countAddedAddOns === 0 &&
          selectPackagePrice.packageNoClients === teamPackageNoClients)) &&
      isAnnually &&
      !isMonthly;

    return isDowngrade;
  };

  const newTotalDueIsGreater =
    !selectPackage.will_schedule &&
    !selectPackage.will_zapier_schedule &&
    selectedZapierQuantity > currentZapierQuantity &&
    selectPackagePrice.packageNoClients <= teamPackageNoClients &&
    countAddedAddOns === 0 &&
    isAnnually;

  const removeZapierOnAnnually =
    teamAddOns.zapier && !_.get(selectedAddOns, 'zapier', false) && isAnnually && countAddedAddOns === 0;

  const isZapierChangedOnAnnualToMonthly =
    selectedZapierQuantity !== currentZapierQuantity && selectPeriod === 'monthly' && teamPeriod === 'annually';

  let expiredDate;
  const isScheduled =
    ((downgrade && teamPeriod === 'annually') ||
      isDowngradeZapierKeepBasePlan ||
      isDowngradeAnnually() ||
      removeZapierOnAnnually ||
      isZapierChangedOnAnnualToMonthly) &&
    !newTotalDueIsGreater;

  if (isScheduled) {
    expiredDate = moment(teamExpiredDate).format('MMM D, YYYY');
  }

  const recurringAddOnsPricing = useMemo(() => addOnsPricing.filter(o => !o.isOneTime && o.code === 'zapier'), [
    addOnsPricing,
  ]);

  const paymentObject = calculatePaymentObject(
    selectPackage,
    recurringAddOnsPricing,
    paymentInfo,
    teamData,
    downgrade,
    isScheduled,
  );

  const addOns = getAddOnsCoupon(selectPackage, recurringAddOnsPricing);

  const zapierBasePricings = _.get(recurringAddOnsPricing[0], 'basePricings', []);

  const filteredPricing = useMemo(() => {
    const filterPricingByPlan = (pricings, plan) => {
      return pricings
        .map((pricing, index) => ({ ...pricing, index }))
        .filter(pricing => pricing.packageNoVolumes === plan);
    };
    return filterPricingByPlan(zapierBasePricings, currentZapierQuantity);
  }, [zapierBasePricings, currentZapierQuantity]);

  const zapierCurrentPrice =
    teamPeriod === 'monthly'
      ? _.get(filteredPricing, '[0].monthlyPricing', 0)
      : _.get(filteredPricing, '[0].anuallyPricing', 0);

  const renderTags = useCallback(
    tags => {
      const updatedTags = arrayToString(tags);
      return updatedTags === 'ADD-ON'
        ? 'Add-on'
        : updatedTags === 'API PLUGIN'
        ? `${_.get(selectPackage, 'zapier_quantity', false).toLocaleString('en-US')} tasks`
        : updatedTags;
    },
    [selectPackage],
  );

  // Pricing calculation
  const subtotalPrice = recurringAddOnsPricing
    .map(addOn => Math.max(getPriceByPeriod(selectPeriod, addOn.basePrice) - zapierCurrentPrice, 0))
    .reduce((acc, price) => acc + price, 0);

  // Check if there's a promo code and apply it to the final price
  let finalPrice = subtotalPrice;
  const promoDiscount = getPromoOnlyZapier(selectPackage.promoCode, subtotalPrice);

  if (promoDiscount > 0) {
    finalPrice -= promoDiscount;
  }
  // if (paymentObject.payFromBalance > 0) {
  //   finalPrice -= paymentObject.payFromBalance;
  // }

  // Force logic calculate payFormBalance for zapier upgrade
  let zapierPayFromBalance = 0;
  if (paymentObject.creditBalance > 0) {
    if (subtotalPrice - promoDiscount > paymentObject.creditBalance) {
      zapierPayFromBalance = paymentObject.creditBalance;
    } else {
      zapierPayFromBalance = subtotalPrice - promoDiscount;
    }
  }

  if (zapierPayFromBalance > 0) {
    finalPrice -= zapierPayFromBalance;
  }

  finalPrice = Math.max(finalPrice, 0);

  return (
    <div className={props.className}>
      <Wrapper>
        <div className="paymentSummary__heading">
          <span>Payment Summary</span>
        </div>
        <div className="paymentSummary__body">
          <div className="paymentSummary__selectedPlan">
            <div>
              <div className="paymentSummary__selectedPlan--current">Current</div>
              <span className="paymentSummary__selectedPlan--oldName">Zapier</span>
              {price > 0 && (
                <span className="paymentSummary__selectedPlan--oldClient">
                  {' '}
                  - {_.get(teamData, 'zapier_quantity', 0).toLocaleString('en-US')} tasks
                </span>
              )}
            </div>
            <div>
              <div className="paymentSummary__selectedPlan--oldPrice">
                {zapierCurrentPrice > 0 ? Number(zapierCurrentPrice).toUSDFormat(false) : 'Free'}
              </div>
              {price > 0 && <div className="paymentSummary__selectedPlan--oldPeriod">/{getPeriodName(teamPeriod)}</div>}
            </div>
          </div>
          {recurringAddOnsPricing.map(
            addOn =>
              addOns[addOn.code] &&
              price > 0 && (
                <div className="paymentSummary__selectedPlan">
                  <div className={classNames({ paymentSummary__oneTimeDes: addOn.isOneTime })}>
                    <div className="paymentSummary__selectedPlan--newPlan">New plan</div>
                    <span className="paymentSummary__planName paymentSummary__planName--automation">{addOn.title}</span>
                    <span className="paymentSummary__selectedPlan--client">
                      &nbsp;-&nbsp;{renderTags(_.get(addOn, 'tags', []))}
                    </span>
                    <div className="paymentSummary__selectedPlan--addOnsDes">
                      Additional monthly cost based on usage
                    </div>
                  </div>
                  <div className={classNames({ paymentSummary__oneTimePrice: addOn.isOneTime })}>
                    <div className="paymentSummary__selectedPlan--price">
                      {getPriceByPeriod(selectPackage.period, addOn.basePrice).toUSDFormat(false)}
                    </div>
                    {addOn.isOneTime ? (
                      <div className="paymentSummary__selectedPlan--period">One time</div>
                    ) : (
                      <div className="paymentSummary__selectedPlan--period">/{getPeriodName(selectPackage.period)}</div>
                    )}
                  </div>
                </div>
              ),
          )}
        </div>
        <Subtotal subTotal={subtotalPrice} period={selectPackage.period} />
        <div className="paymentSummary__totalSumary">
          {/* In-case showing prorated & credit of plan, enable code line below */}
          {/* {paymentObject.proratedSubtotal !== paymentObject.unusedCost && (
            <ProrationCost
              cost={paymentObject.proratedSubtotal}
              label="Prorated subtotal"
              description="Until end of billing cycle"
            />
          )} */}
          {!!promoDiscount && <PromomotionCost promoAmount={promoDiscount} onlyZapier />}
          {/* {paymentObject.proratedSubtotal !== paymentObject.unusedCost && (
            <ProrationCost
              cost={paymentObject.unusedCost}
              label="Credit for current plan"
              description="Until end of billing cycle"
              minus={true}
            />
          )} */}
          {!!paymentObject.oneTimeCost && (!!paymentObject.proratedSubtotal || !!paymentObject.unusedCost) && (
            <Divide />
          )}
          {zapierPayFromBalance > 0 && subtotalPrice > 0 && (
            <div className="paymentSummary__subTotal">
              <div className="paymentSummary__subTotal--left-content">
                <span className="paymentSummary__subTotal--name">Credit balance</span>
                <div
                  className="paymentSummary__creditToAccount--tootipIcon"
                  data-tip
                  data-for="paymentSummary__payFromBalance"
                >
                  i
                </div>
                <ReactTooltip
                  className="app-tooltip payment-summary-tootip"
                  id="paymentSummary__payFromBalance"
                  place="top"
                  effect="solid"
                >
                  The amount will be deducted from your Everfit credit balance.
                </ReactTooltip>
              </div>
              <div>
                <div className="paymentSummary__subTotal--price paymentSummary__subTotal--credit">
                  -&nbsp;{Number(zapierPayFromBalance).toUSDFormat(false)}
                </div>
              </div>
            </div>
          )}
          {/* {paymentObject.creditToAccount > 0 && (
            <div className="paymentSummary__creditToAccount">
              <div className="paymentSummary__creditToAccount--leftContent">
                <span className="paymentSummary__creditToAccount--name">Credit to account</span>
                <div
                  className="paymentSummary__creditToAccount--tootipIcon"
                  data-tip
                  data-for="paymentSummary__creditToAccount"
                >
                  i
                </div>
                <ReactTooltip
                  className="app-tooltip payment-summary-tootip"
                  id="paymentSummary__creditToAccount"
                  place="top"
                  effect="solid"
                >
                  The remaining value will be added to your Everfit credit balance.
                </ReactTooltip>
              </div>
              <div>
                <strong className="paymentSummary__creditToAccount--price">
                  +&nbsp;{Number(paymentObject.creditToAccount).toUSDFormat(false)}
                </strong>
              </div>
            </div>
          )} */}
        </div>

        <div className="paymentSummary__totalDue">
          <div className="paymentSummary__totalDue--name">
            {expiredDate ? (
              <>
                <div className="paymentSummary__totalDue--text">Total due</div>
                <div className="paymentSummary__totalDue--day">on {expiredDate}</div>
              </>
            ) : (
              <div className="paymentSummary__totalDue--today">Total due today</div>
            )}
          </div>
          <div className="paymentSummary__totalDue--price">{Number(finalPrice).toUSDFormat(false)}</div>
        </div>
        <div className="paymentSummary__footer">
          <button onClick={props.onConfirm} disabled={props.invalidCard} className="paymentSummary__confirmBtn">
            Confirm Payment
          </button>
          <h5>Subscription renews automatically</h5>
        </div>
      </Wrapper>
      <TermPolicy />
    </div>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    paymentInfo: rootReducer.billing.paymentInfo,
    permission: rootReducer.permission,
  };
};

const actionCreators = {
  push,
};

export default connect(mapState, actionCreators)(ZapierPaymentSummary);
