import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoflowList from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { push } from 'connected-react-router';
import {
  getAutoflowList,
  changeAutoflowQueryParam,
  deleteAutoflow,
  changeWorkingAutoflow,
  resetAutoflowListQueryData,
} from 'redux/autoflow/actions';
import { toggleSideBar } from 'actions/sidebar';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { list, query } = rootReducer.autoflow.common;
  return {
    list,
    sort: query.sort,
    sorter: query.sorter,
    user,
    teamData: rootReducer.pricing.get('teamData').toJS(),
    payment: rootReducer.permission.payment,
    onboarding_flow: rootReducer.permission.onboarding_flow,
  };
};

const mapDispatchToProps = dispatch => ({
  getAutoflowList: bindActionCreators(getAutoflowList, dispatch),
  changeAutoflowQueryParam: bindActionCreators(changeAutoflowQueryParam, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  deleteAutoflow: bindActionCreators(deleteAutoflow, dispatch),
  changeWorkingAutoflow: bindActionCreators(changeWorkingAutoflow, dispatch),
  resetAutoflowListQueryData: bindActionCreators(resetAutoflowListQueryData, dispatch),
  push: bindActionCreators(push, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoflowList);
