import styled from 'styled-components';

export const DraggablePlaceholder = styled.div`
  position: absolute;
  padding: 4px;

  div {
    height: 100%;
    background: #ededff;
    border: 1px dashed #5a57cb;
    box-sizing: border-box;
    border-radius: 2px;
  }
`;

export const AssignedProgramTooltipStyles = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExerciseListHeader = styled.div`
  height: 14px;
  width: calc(100% - 8px);
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
  .show {
    visibility: visible;
    opacity: 1;
  }
  .calendar-client--checkbox-workout {
    height: 14px;
    width: 14px;
    padding-left: 18px;
    margin-left: 4px;
    display: block;
    & > span {
      width: 14px;
      height: 14px;
    }
    input:checked ~ .checkbox-icon {
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      background-position: 2px 2px;
      background-size: 70%;
      top: 7px;
    }
    &:hover > span.checkbox-icon {
      border: 1px solid #5158cf;
    }
  }
`;

export const HiddenEye = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 4px;

  svg g path {
    fill: #979797;
  }
`;
