import Request from 'configs/request';
import get from 'lodash/get';
import { toast } from 'react-toastify';

export const Types = {
  ONBOARDING_FLOW_SELECTED_FORM: 'ONBOARDING_FLOW_SELECTED_FORM',
  ONBOARDING_FLOW_SAVE_DRAFT_REQUEST: 'ONBOARDING_FLOW_SAVE_DRAFT_REQUEST',
  ONBOARDING_FLOW_SAVE_DRAFT_SUCCESS: 'ONBOARDING_FLOW_SAVE_DRAFT_SUCCESS',
  ONBOARDING_FLOW_SAVE_DRAFT_FAILED: 'ONBOARDING_FLOW_SAVE_DRAFT_FAILED',
  ONBOARDING_FLOW_PUBLISH_REQUEST: 'ONBOARDING_FLOW_PUBLISH_REQUEST',
  ONBOARDING_FLOW_PUBLISH_SUCCESS: 'ONBOARDING_FLOW_PUBLISH_SUCCESS',
  ONBOARDING_FLOW_PUBLISH_FAILED: 'ONBOARDING_FLOW_PUBLISH_FAILED',
  ONBOARDING_FLOW_UNPUBLISH_REQUEST: 'ONBOARDING_FLOW_UNPUBLISH_REQUEST',
  ONBOARDING_FLOW_UNPUBLISH_SUCCESS: 'ONBOARDING_FLOW_UNPUBLISH_SUCCESS',
  ONBOARDING_FLOW_UNPUBLISH_FAILED: 'ONBOARDING_FLOW_UNPUBLISH_FAILED',
  ONBOARDING_FLOW_EDIT_REQUEST: 'ONBOARDING_FLOW_EDIT_REQUEST',
  ONBOARDING_FLOW_EDIT_SUCCESS: 'ONBOARDING_FLOW_EDIT_SUCCESS',
  ONBOARDING_FLOW_EDIT_FAILED: 'ONBOARDING_FLOW_EDIT_FAILED',
  ONBOARDING_FLOW_SAVE_CHANGES_REQUEST: 'ONBOARDING_FLOW_SAVE_CHANGE_REQUEST',
  ONBOARDING_FLOW_SAVE_CHANGES_SUCCESS: 'ONBOARDING_FLOW_SAVE_CHANGES_SUCCESS',
  ONBOARDING_FLOW_SAVE_CHANGES_FAILED: 'ONBOARDING_FLOW_SAVE_CHANGES_FAILED',
  ONBOARDING_FLOW_CANCEL_CHANGES_REQUEST: 'ONBOARDING_FLOW_CANCEL_CHANGES_REQUEST',
  ONBOARDING_FLOW_CANCEL_CHANGES_SUCCESS: 'ONBOARDING_FLOW_CANCEL_CHANGES_SUCCESS',
  ONBOARDING_FLOW_CANCEL_CHANGES_FAILED: 'ONBOARDING_FLOW_CANCEL_CHANGES_FAILED',
  ONBOARDING_FLOW_GET_DETAIL_REQUEST: 'ONBOARDING_FLOW_GET_DETAIL_REQUEST',
  ONBOARDING_FLOW_GET_DETAIL_SUCCESS: 'ONBOARDING_FLOW_GET_DETAIL_SUCCESS',
  ONBOARDING_FLOW_GET_DETAIL_FAILED: 'ONBOARDING_FLOW_GET_DETAIL_FAILED',
  ONBOARDING_FLOW_GET_FORM_TEMPLATES_SUCCESS: 'ONBOARDING_FLOW_GET_FORM_TEMPLATES_SUCCESS',
  ONBOARDING_FLOW_GET_FORM_TEMPLATES_DETAIL_SUCCESS: 'ONBOARDING_FLOW_GET_FORM_TEMPLATES_DETAIL_SUCCESS',
  ONBOARDING_FLOW_REMOVE_FORM: 'ONBOARDING_FLOW_REMOVE_FORM',
  ONBOARDING_FLOW_GET_DEFAULT_METRICS: 'ONBOARDING_FLOW_GET_DEFAULT_METRICS',
  ONBOARDING_FLOW_TOGGLE_ASSET_STATUS: 'ONBOARDING_FLOW_TOGGLE_ASSET_STATUS',
  ONBOARDING_FLOW_RESET_FORM_TEMPLATES: 'ONBOARDING_FLOW_RESET_FORM_TEMPLATES',
  ONBOARDING_FLOW_REMOVE_COACH: 'ONBOARDING_FLOW_REMOVE_COACH',
  ONBOARDING_FLOW_RESET_WORKING_DATA: 'ONBOARDING_FLOW_RESET_WORKING_DATA',
  ONBOARDING_FLOW_RESET_WORKING_SETTING: 'ONBOARDING_FLOW_RESET_WORKING_SETTING',
  ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_REQUEST: 'ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_REQUEST',
  ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_SUCCESS: 'ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_SUCCESS',
  ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_FAILED: 'ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_FAILED',
  ONBOARDING_FLOW_SET_AS_DEFAULT_REQUEST: 'ONBOARDING_FLOW_SET_AS_DEFAULT_REQUEST',
  ONBOARDING_FLOW_SET_AS_DEFAULT_SUCCESS: 'ONBOARDING_FLOW_SET_AS_DEFAULT_SUCCESS',
  ONBOARDING_FLOW_SET_AS_DEFAULT_FAILED: 'ONBOARDING_FLOW_SET_AS_DEFAULT_FAILED',
  ONBOARDING_FLOW_UPDATE_INFO_REQUEST: 'ONBOARDING_FLOW_UPDATE_INFO_REQUEST',
  ONBOARDING_FLOW_UPDATE_INFO_SUCCESS: 'ONBOARDING_FLOW_UPDATE_INFO_SUCCESS',
  ONBOARDING_FLOW_UPDATE_INFO_FAILED: 'ONBOARDING_FLOW_UPDATE_INFO_FAILED',
  ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_REQUEST: 'ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_REQUEST',
  ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_SUCCESS: 'ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_SUCCESS',
  ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_FAILED: 'ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_FAILED',
  ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_REQUEST: 'ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_REQUEST',
  ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_SUCCESS: 'ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_SUCCESS',
  ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_FAILED: 'ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_FAILED',
  ONBOARDING_FLOW_ADD_PACKAGES_REQUEST: 'ONBOARDING_FLOW_ADD_PACKAGES_REQUEST',
  ONBOARDING_FLOW_ADD_PACKAGES_SUCCESS: 'ONBOARDING_FLOW_ADD_PACKAGES_SUCCESS',
  ONBOARDING_FLOW_ADD_PACKAGES_FAILED: 'ONBOARDING_FLOW_ADD_PACKAGES_FAILED',
  ONBOARDING_FLOW_REMOVE_PACKAGE_REQUEST: 'ONBOARDING_FLOW_REMOVE_PACKAGE_REQUEST',
  ONBOARDING_FLOW_REMOVE_PACKAGE_SUCCESS: 'ONBOARDING_FLOW_REMOVE_PACKAGE_SUCCESS',
  ONBOARDING_FLOW_REMOVE_PACKAGE_FAILED: 'ONBOARDING_FLOW_REMOVE_PACKAGE_FAILED',
};

export const handleSelectedForm = (form, isEditForm, isCustom) => ({
  type: Types.ONBOARDING_FLOW_SELECTED_FORM,
  payload: { form, isEditForm, isCustom },
});

export const getFormTemplates = () => {
  return Request.get({ url: '/api/onboarding-flows/form-templates' }, true, (res, { dispatch }) => {
    const { data = [] } = res.data;
    dispatch({ type: Types.ONBOARDING_FLOW_GET_FORM_TEMPLATES_SUCCESS, payload: data });
  });
};

export const resetFormTemplates = () => {
  return dispatch => dispatch({ type: Types.ONBOARDING_FLOW_RESET_FORM_TEMPLATES });
};

export const resetWorkingData = () => {
  return dispatch => dispatch({ type: Types.ONBOARDING_FLOW_RESET_WORKING_DATA });
};

export const resetWorkingSetting = () => {
  return dispatch => dispatch({ type: Types.ONBOARDING_FLOW_RESET_WORKING_SETTING });
};

export const getFormTemplatesDetail = formId => {
  return Request.get({ url: `/api/onboarding-flows/form-templates/${formId}` }, true, (res, { dispatch }) => {
    const { data = {} } = res.data;
    dispatch({ type: Types.ONBOARDING_FLOW_GET_FORM_TEMPLATES_DETAIL_SUCCESS, payload: data });
  });
};

export const saveOnboardingFlowDraft = data => (dispatch, getState) => {
  const {
    rootReducer: { permission },
  } = getState();
  const { multiple_onboarding_flow } = permission || {};

  dispatch({ type: Types.ONBOARDING_FLOW_SAVE_DRAFT_REQUEST });

  return dispatch(
    Request.post(
      { url: '/api/onboarding-flows/save-draft', data },
      true,
      (res, { dispatch }) => {
        const { data } = res.data;
        toast('Onboarding flow has been saved', {
          className: 'custom--onboarding-flow',
        });
        dispatch({
          type: Types.ONBOARDING_FLOW_SAVE_DRAFT_SUCCESS,
          payload: { data, multiple_onboarding_flow },
        });
      },
      () => {
        dispatch({ type: Types.ONBOARDING_FLOW_SAVE_DRAFT_FAILED });
      },
    ),
  );
};

export const publishOnboardingFlow = id => (dispatch, getState) => {
  if (!id) return;
  dispatch({ type: Types.ONBOARDING_FLOW_PUBLISH_REQUEST });
  return dispatch(
    Request.post(
      { url: `/api/onboarding-flows/${id}/publish` },
      true,
      res => {
        if (get(res, 'data.success')) {
          toast('Onboarding Flow has been published', {
            className: 'custom--onboarding-flow',
          });
          dispatch({ type: Types.ONBOARDING_FLOW_PUBLISH_SUCCESS });
        }
      },
      () => {
        dispatch({ type: Types.ONBOARDING_FLOW_PUBLISH_FAILED });
      },
    ),
  );
};

export const unpublishOnboardingFlow = id => (dispatch, getState) => {
  if (!id) return;
  dispatch({ type: Types.ONBOARDING_FLOW_UNPUBLISH_REQUEST });
  return dispatch(
    Request.post(
      { url: `/api/onboarding-flows/${id}/unpublish` },
      true,
      res => {
        if (get(res, 'data.success')) {
          dispatch({ type: Types.ONBOARDING_FLOW_UNPUBLISH_SUCCESS });
        }
      },
      () => {
        dispatch({ type: Types.ONBOARDING_FLOW_UNPUBLISH_FAILED });
      },
    ),
  );
};

export const editOnboardingFlow = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.ONBOARDING_FLOW_EDIT_SUCCESS });
  };
};

export const cancelChanges = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.ONBOARDING_FLOW_CANCEL_CHANGES_SUCCESS });
  };
};

export const saveChanges = (id, data, isSaveDraft) => (dispatch, getState) => {
  dispatch({ type: Types.ONBOARDING_FLOW_SAVE_CHANGES_REQUEST });
  if (!id) return;
  return dispatch(
    Request.put(
      { url: `/api/onboarding-flows/${id}`, data },
      true,
      (res, { dispatch }) => {
        const { data } = res.data;
        if (isSaveDraft) {
          toast('Onboarding flow has been saved');
        } else {
          toast('Onboarding Flow has been updated', {
            className: 'custom--onboarding-flow',
          });
        }
        dispatch({ type: Types.ONBOARDING_FLOW_SAVE_CHANGES_SUCCESS, payload: data });
      },
      () => {
        dispatch({ type: Types.ONBOARDING_FLOW_SAVE_CHANGES_FAILED });
      },
    ),
  );
};

export const getOnboardingFlowDetail = id => (dispatch, getState) => {
  const {
    rootReducer: { permission },
  } = getState();
  const { multiple_onboarding_flow } = permission || {};

  const urlEndpoint = multiple_onboarding_flow ? `/api/onboarding-flows/${id}` : '/api/onboarding-flows/detail';

  dispatch({ type: Types.ONBOARDING_FLOW_GET_DETAIL_REQUEST });

  return dispatch(
    Request.get(
      { url: urlEndpoint },
      true,
      (res, { dispatch }) => {
        const { data } = res.data;
        dispatch({
          type: Types.ONBOARDING_FLOW_GET_DETAIL_SUCCESS,
          payload: { data, multiple_onboarding_flow },
        });
      },
      () => {
        dispatch({ type: Types.ONBOARDING_FLOW_GET_DETAIL_FAILED });
      },
    ),
  );
};

export const removeForm = (formId, isCustomForm = false) => ({
  type: Types.ONBOARDING_FLOW_REMOVE_FORM,
  payload: { formId, isCustomForm },
});

export const getDefaultMetrics = () => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/body-metric/get-list-trainer-default-body-metric` }, true, (response, { dispatch }) => {
        const { data } = get(response, 'data', {});
        dispatch({ type: Types.ONBOARDING_FLOW_GET_DEFAULT_METRICS, payload: data });
      }),
    );
  };
};

export const toggleStatus = data => ({
  type: Types.ONBOARDING_FLOW_TOGGLE_ASSET_STATUS,
  payload: data,
});

export const getOnboardingFlowSettings = id => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/onboarding-flow-settings/${id}`,
        },
        false,
        (response, { dispatch }) => {
          const { data = {} } = get(response, 'data', {});

          dispatch({ type: Types.ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_SUCCESS, payload: { data } });
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_FETCH_DETAIL_SETTINGS_FAILED });
        },
      ),
    );
  };
};

export const setAsDefaultOnboardingFlow = (id, default_type) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_SET_AS_DEFAULT_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flow-settings/${id}/set-as-default`,
          data: { default_type },
        },
        true,
        (response, { dispatch }) => {
          const { success } = get(response, 'data.data', {});
          dispatch({ type: Types.ONBOARDING_FLOW_SET_AS_DEFAULT_SUCCESS, payload: { success, default_type } });
          dispatch(getOnboardingFlowSettings(id));
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_SET_AS_DEFAULT_FAILED });
        },
      ),
    );
  };
};

export const updateOnboardingFlowInfo = (id, data, callback, shouldRedirect = false) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_UPDATE_INFO_REQUEST });

    return dispatch(
      Request.patch(
        {
          url: `/api/onboarding-flows/${id}`,
          data,
        },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.ONBOARDING_FLOW_UPDATE_INFO_SUCCESS });
          if (!shouldRedirect) {
            dispatch(getOnboardingFlowDetail(id));
            dispatch(getOnboardingFlowSettings(id));
          }
          typeof callback === 'function' && callback();
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_UPDATE_INFO_FAILED });
        },
      ),
    );
  };
};

export const addTrainerInviteCodes = (id, data) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flow-settings/${id}/trainer-invite-codes`,
          data,
        },
        true,
        (response, { dispatch }) => {
          const { data } = get(response, 'data', {});
          dispatch({
            type: Types.ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_SUCCESS,
            payload: { trainer_invite_codes: data },
          });
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_ADD_TRAINER_INVITE_CODES_FAILED });
        },
      ),
    );
  };
};

export const deleteTrainerInviteCodes = (id, trainerId, callback) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_REQUEST });

    return dispatch(
      Request.delete(
        {
          url: `/api/onboarding-flow-settings/${id}/trainer-invite-codes/${trainerId}`,
        },
        true,
        (response, { dispatch }) => {
          const { success } = get(response, 'data.data', {});

          if (success) {
            dispatch({
              type: Types.ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_SUCCESS,
              payload: { trainerId },
            });
            typeof callback === 'function' && callback();
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_DELETE_TRAINER_INVITE_CODES_FAILED });
        },
      ),
    );
  };
};

export const addPackages = (id, data) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_ADD_PACKAGES_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flow-settings/${id}/packages`,
          data,
        },
        true,
        (response, { dispatch }) => {
          const { data } = get(response, 'data', {});
          dispatch({
            type: Types.ONBOARDING_FLOW_ADD_PACKAGES_SUCCESS,
            payload: { data_packages: data },
          });
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_ADD_PACKAGES_FAILED });
        },
      ),
    );
  };
};

export const removePackage = (onboardingFlowId, packageId) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_REMOVE_PACKAGE_REQUEST });

    return dispatch(
      Request.delete(
        {
          url: `/api/onboarding-flow-settings/${onboardingFlowId}/packages/${packageId}`,
        },
        true,
        (response, { dispatch }) => {
          const { success } = get(response, 'data.data', {});
          if (success) {
            dispatch({
              type: Types.ONBOARDING_FLOW_REMOVE_PACKAGE_SUCCESS,
              payload: { packageId },
            });
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_REMOVE_PACKAGE_FAILED });
        },
      ),
    );
  };
};
