import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Icon = styled.div`
  margin-bottom: 10px;
  width: 86px;
  height: 51px;
  background: transparent url(${CDN_URL}/images/task_document_upload_icon.svg) no-repeat center;
  background-size: contain;
`;

export const Text = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #6a778a;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;

  .select-file__trigger {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  outline: none;
  position: relative;
  background: #fafbfc;
  border: 1px dashed #d1d1d1;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 148px;
  display: flex;
  align-items: center;

  .media-upload-progress {
    padding: 16px 15px 16px 20px;
    background: #fff;
    border: 1px solid #ebedf4;
    box-shadow: 0px 4px 8px #f1f2f6;
    border-radius: 5px;

    .icon {
      height: 30px;
      width: 30px;
    }

    .upload__placeholder {
      padding: 0 20px;
    }
  }

  ${props =>
    props.hasData
      ? css`
          background: none;
          border: none;
          height: auto;
          min-height: unset;
        `
      : css`
          :hover {
            border-color: #5158cf;

            ${Icon} {
              background-image: url(${CDN_URL}/images/task_document_upload_icon_dragging.svg);
            }
          }
        `}

  ${props =>
    props.isDragging &&
    css`
      border-color: #5158cf;

      ${Icon} {
        background-image: url(${CDN_URL}/images/task_document_upload_icon_dragging.svg);
      }
    `}
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
