import React, { useCallback, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { customStyleSingle, CategoryDropdownWrapper } from './style';
import { CLIENT_CATEGORY } from 'constants/commonData';

const CATEGORIES = [
  { key: 'online', text: CLIENT_CATEGORY.ONLINE, value: CLIENT_CATEGORY.ONLINE },
  { key: 'in_person', text: CLIENT_CATEGORY.IN_PERSON, value: CLIENT_CATEGORY.IN_PERSON },
  { key: 'Hybrid', text: CLIENT_CATEGORY.HYBRID, value: CLIENT_CATEGORY.HYBRID },
];

const CategoryClientDropdown = props => {
  const { name, onChange, disabled, value } = props;
  const selectRef = useRef();

  const placeholder = useMemo(() => get(props, 'placeholder', 'Choose Coach'), [props]);
  const noOptionsMessage = useMemo(() => get(props, 'noOptionsMessage', 'No results found'), [props]);

  const menuPortalTarget = useMemo(() => {
    return document.body;
  }, []);
  const [menuPlacement, setMenuPlacement] = useState(() => get(props, 'menuPlacement', 'auto'), [props]);
  const initValue = value && CATEGORIES.find(it => it.value === value);
  const [valueCategory, setValueCategory] = useState(initValue || CATEGORIES[0]);

  const handleChange = useCallback(option => {
    setValueCategory(option);
    onChange && onChange(option);
  }, []);

  const checkPlacement = useCallback(() => {
    if (typeof selectRef.current.getBoundingClientRect === 'function') {
      const rect = selectRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 114) {
        setMenuPlacement('top');
      } else {
        menuPlacement !== get(props, 'menuPlacement', 'auto') && setMenuPlacement(get(props, 'menuPlacement', 'auto'));
      }
    }
  }, [menuPlacement, selectRef]);

  const onMenuOpen = useCallback(() => {
    checkPlacement();
  }, []);

  const onMenuClose = useCallback(() => {}, []);

  return (
    <CategoryDropdownWrapper ref={selectRef}>
      <Select
        blurInputOnSelect
        isMulti={false}
        isLoading={false}
        isSearchable={false}
        onChange={handleChange}
        name={name}
        value={valueCategory}
        isClearable={false}
        options={CATEGORIES}
        placeholder={placeholder}
        isDisabled={disabled}
        styles={customStyleSingle}
        classNamePrefix="category"
        className="select-category"
        noOptionsMessage={() => noOptionsMessage}
        getOptionLabel={option => get(option, 'text')}
        getOptionValue={option => get(option, 'value')}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </CategoryDropdownWrapper>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryClientDropdown);
