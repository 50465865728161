import styled from 'styled-components';

export const Container = styled.div`
  svg {
    display: block;
  }

  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
`;