import styled from 'styled-components';
import { MenuTrigger } from 'shared/Icons';

export const DropdownWrapper = styled.div`

  .evf-dropdown__option {
    padding: 8px 50px 8px 20px;

    .icon {
      margin-right: 10px;

      &.edit {
        width: 16px;
      }
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: #00000010;
  border-radius: 6px;
  width: 24px;
  height: 24px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: #FFFFFF;
  }

  &:hover {
    background: #FFFFFF;

    .dot, .dot::before, .dot::after {
      background-color: #000000;
    }
  }
  
`;
