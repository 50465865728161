import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import { SetOneRepMaxModalWrapper } from './style';
import { Modal } from 'semantic-ui-react';
import { Button, FormGroup } from 'shared/FormControl';
import { axiosInstance } from 'configs/request';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import { convertUnit, roundUp } from 'utils/commonFunction';
import { showError } from 'actions/error';
import { CDN_URL } from 'constants/commonData';

function SetOneRepMaxModal(props) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    const params = {
      client: props.client,
      exercise: props.exerciseId,
    };
    setLoading(true);
    axiosInstance
      .get('/api/exercise/get-one-rep-max', { params })
      .then(({ data }) => {
        try {
          Mixpanel.track('set_1rm_manually');
        } catch {}

        if (data.data) {
          const { setting } = props;
          const defaultSetting = _.get(data, 'data.set_unit', null);
          const value =
            defaultSetting && setting
              ? convertUnit(data.data.value, defaultSetting, _.get(props, 'setting'))
              : data.data.value;
          setValue(roundUp(value || 0, 2));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    if (_.isNaN(parseFloat(value)) || loading) {
      return;
    }
    const body = {
      client: props.client,
      exercise: props.exerciseId,
      value,
      unit: props.weightUnit,
    };
    setLoading(true);
    axiosInstance
      .post('/api/exercise/set-one-rep-max', body)
      .then(() => {
        toast(`1RM for ${props.exercise.exercise_name} has been updated.`);
        onClose();
      })
      .catch(err => {
        const errorMessage = _.get(err, 'response.data.message');
        props.showError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    props.toggleModal(false);
  };

  const onChangeValue = e => {
    setValue(e.target.value);
  };

  return (
    <SetOneRepMaxModalWrapper
      size={'tiny'}
      open={props.isModalOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      width={405}
    >
      <Modal.Header>
        <div>Manually Set 1RM</div>
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={onSubmit}>
          <FormGroup>
            <label>Value</label>
            <div className="orm__inputValue">
              <input onChange={onChangeValue} type="number" min="0" value={value} step="0.01" />
              <span className="orm__inputValue--addon">{props.unitTitle}</span>
            </div>
          </FormGroup>
          <div className="orm__footer">
            <Button disabled={_.isNaN(parseFloat(value)) || value < 0 || loading} purple>
              Update
            </Button>
          </div>
        </form>
      </Modal.Content>
    </SetOneRepMaxModalWrapper>
  );
}

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
  weightUnit: _.get(state, 'rootReducer.client.workingClientDetail.preferences.weight._id', ''),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    showError: bindActionCreators(showError, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetOneRepMaxModal);
