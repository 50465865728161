import { push } from 'connected-react-router';
import _ from 'lodash';
import { toggleModal } from 'actions/modal';
import { toast } from 'react-toastify';
import { getWorkoutLibrary } from 'redux/workout_library/workout.actionCreators';
import { changeCalendarStartDate } from '../../actions/calendar';
import { onSubmitting, onSubmitted } from 'actions/submitStatus';
import Request from 'configs/request';
import { getOnboardingCheckList } from 'redux/onboarding/actions';
import { ONBOARDING_STEPS } from 'constants/commonData';

export const updateWorkout = (params, notCloseTheWorkoutPopup) => {
  return dispatch => {
    dispatch(onSubmitting('WORKOUT_DETAIL'));

    return dispatch(
      Request.put(
        { url: `/api/workout/v2/update/${params.workoutId}`, data: params },
        false,
        result => {
          if (!notCloseTheWorkoutPopup) {
            dispatch(toggleModal(false));
            dispatch(push('/home/workout'));
          }

          dispatch(onSubmitted('WORKOUT_DETAIL', true));
          dispatch(getWorkoutLibrary());
        },
        error => {
          dispatch(onSubmitted('WORKOUT_DETAIL', false));
          dispatch({ type: 'ERROR_UPDATING_WORKOUT', error });
        },
      ),
    );
  };
};

export const assignWorkout = params => {
  return (dispatch, getState) => {
    dispatch(onSubmitting('WORKOUT_DETAIL'));

    return dispatch(
      Request.post(
        { url: '/api/workout/v2/assign', data: params },
        false,
        () => {
          const {
            rootReducer: {
              onboarding: { hideFeature, checkList },
            },
          } = getState();

          if (!hideFeature) {
            const unfinished = _.find(checkList, item => item.type === ONBOARDING_STEPS.ASSIGN_WORKOUT && !item.state);

            dispatch(getOnboardingCheckList(!!unfinished));
          }

          dispatch(onSubmitted('WORKOUT_DETAIL', true));
          dispatch(toggleModal(false));
          dispatch(changeCalendarStartDate());
        },
        error => {
          dispatch(onSubmitted('WORKOUT_DETAIL', false));
          dispatch({ type: 'ERROR_ASSIGN_WORKOUT', error });
        },
      ),
    );
  };
};

export const addExerciseSetToAssignment = params => {
  return dispatch => {
    dispatch(onSubmitting('EXERCISE_DETAIL'));

    return dispatch(
      Request.put(
        { url: `/api/workout/v2/assignment/${params.assignment}/addSet`, data: params },
        false,
        result => {
          dispatch(onSubmitted('EXERCISE_DETAIL', true));
          dispatch(toggleModal(false));
          dispatch(changeCalendarStartDate());
        },
        error => {
          dispatch(onSubmitted('EXERCISE_DETAIL', false));
          dispatch({ type: 'ERROR_ADD_EXERCISE_TO_ASSIGNMENT', error });
        },
      ),
    );
  };
};

export const saveAssignmentToLibrary = params => {
  return Request.post(
    { url: `/api/workout/v2/assignment/${params.assignment}/saveToLibrary` },
    false,
    (result, { dispatch }) => {
      toast.success('Workout saved to library!', { autoClose: 3000 });
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_SAVE_ASSIGNMENT_TO_LIBRARY', error });
    },
  );
};

export const updateExerciseLibFromCalendar = data => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { user, rootReducer } = currentState;
    const { exercise, client } = rootReducer;
    const { categories, fields } = exercise;
    const unit = client.workingClientDetail ? client.workingClientDetail.preferences : user.preferences;

    dispatch({
      type: 'UPDATE_EXERCISE_LIB_FROM_CALENDAR',
      payload: { data: { ...data, categories, fields, unit } },
    });
  };
};
