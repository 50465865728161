import React from 'react';

const CustomMultiValueLabel = props => {
  const { data, innerProps } = props || {};
  const { label = '', typeName = '' } = data || {};

  const isGroup = typeName === 'group';

  return <div {...innerProps}>{isGroup ? `Group: ${label}` : label}</div>;
};

export default CustomMultiValueLabel;
