// Libs
import React, { useMemo } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// Constants
import { MACRO_NUTRIENTS, listMacronutrients } from 'components/RecipeDetail/constants';
import { calculateNutritionChartValues, getNutrientValue } from 'components/RecipeDetail/helper';
import { checkShowPaddingAngle, getMarcoValue } from 'components/ClientMealPlan/helper';
import { roundNumberBodyMetric } from 'utils/commonFunction';

// Styles
import * as S from './style';

const MarcoView = ({ marcoData, macroNutrients, isMarco }) => {
  const { calories = '' } = marcoData || {};

  const gerColorsProgressBar = type => {
    switch (type) {
      case MACRO_NUTRIENTS.PROTEIN:
        return '#57b5ea';

      case MACRO_NUTRIENTS.CARBS:
        return '#65cc9c';

      case MACRO_NUTRIENTS.FAT:
        return '#ffbe49';

      default:
        return '#a3a3b5';
    }
  };

  const renderProgressBar = (percentage = 0, type = '') => {
    return (
      <S.ProgressBarWrapper>
        <S.ProgressBar percentage={percentage > 100 ? 100 : percentage} bgColor={gerColorsProgressBar(type)} />
      </S.ProgressBarWrapper>
    );
  };

  const renderProgressItem = (item, index) => {
    const { label = '', unit = '', type = '' } = item || {};

    const value = getNutrientValue(macroNutrients, type);
    const isEmptyValue = +value === 0 || isEmpty(macroNutrients);

    const marcoValue = getMarcoValue(marcoData, type);
    const isExceeding = +value > +marcoValue;

    const percentage = round(roundNumberBodyMetric((+value / +marcoValue || 0) * 100));

    return (
      <S.ProgressItem key={index}>
        <S.ProgressValues>
          <S.NutritionValue isExceeding={isExceeding} isEmptyValue={isEmptyValue}>
            {isEmptyValue ? '—' : round(value)}
          </S.NutritionValue>
          <S.MarcoValue>&nbsp;/{round(marcoValue)}</S.MarcoValue>
          <S.ProgressUnit>&nbsp;{unit}</S.ProgressUnit>
        </S.ProgressValues>
        {renderProgressBar(percentage, type)}
        <S.ProgressMetric>{label}</S.ProgressMetric>
      </S.ProgressItem>
    );
  };

  const renderMarcoChart = () => {
    const valueNutrition = useMemo(() => calculateNutritionChartValues(macroNutrients), [macroNutrients]);

    const valueCalories = get(valueNutrition, 'calories.value');
    const percentProtein = get(valueNutrition, 'protein.percent');
    const percentCarbs = get(valueNutrition, 'carbs.percent');
    const percentFat = get(valueNutrition, 'fat.percent');
    const isExceeding = +valueCalories > +calories;
    const blankChart = !percentProtein && !percentCarbs && !percentFat;
    const isHasPaddingAgle = checkShowPaddingAngle(percentProtein, percentCarbs, percentFat);

    const isEmptyValue = +valueCalories === 0 || isEmpty(macroNutrients);

    const data = blankChart
      ? [{ label: 'blank', unit: '', value: 100, color: '#a3a3b5' }]
      : [
          { label: 'protein', unit: 'g', value: percentProtein, color: '#57b5ea' },
          { label: 'carbs', unit: 'g', value: percentCarbs, color: '#65cc9c' },
          { label: 'fat', unit: 'g', value: percentFat, color: '#ffbe49' },
        ];

    return (
      <S.MarcoChartWrapper>
        <S.MarcoChartValues>
          <S.MarcoChartValue>
            <S.NutritionValue isEmptyValue={isEmptyValue} isExceeding={isExceeding}>
              {isEmptyValue ? '—' : round(valueCalories)}
            </S.NutritionValue>
            <S.MarcoValue>&nbsp;/{round(calories)}</S.MarcoValue>
          </S.MarcoChartValue>
          <S.MarcoChartMetric>cal</S.MarcoChartMetric>
        </S.MarcoChartValues>
        <PieChart width={88} height={88}>
          <Pie
            data={data}
            dataKey="value"
            innerRadius={39}
            outerRadius={44}
            paddingAngle={isHasPaddingAgle ? 4 : 0}
            cornerRadius={isHasPaddingAgle ? 1 : 0}
            startAngle={90}
            endAngle={-360}
            stroke={isHasPaddingAgle ? undefined : 'none'}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </S.MarcoChartWrapper>
    );
  };

  return (
    <S.MacroWrapper>
      {renderMarcoChart()}
      <S.ProgressWrapper>
        {[...listMacronutrients].filter(item => item.unit !== 'cal').map(renderProgressItem)}
      </S.ProgressWrapper>
    </S.MacroWrapper>
  );
};

export default MarcoView;
