import React, { useMemo } from 'react';
import classNames from 'classnames';
import { LIMIT_OPTION_CHARACTER, SHOW_OPTION_CHARACTER } from './constants';
import OptionInput from './OptionInput';

import { ReactComponent as RemoveIcon } from 'assets/icons/close_circle_grey_3.svg';

import * as S from './style';

const ItemOption = props => {
  const { placeholder = '', onRemoveOption, data, onOptionChange, disableEditPoll, isLastItem, userId } = props;
  const { _id, isDuplicate = false, value = '', owner } = data || {};
  const otherOption = !!owner && userId !== (owner || {})._id;

  const { isDisable, isShowCharacter, isShowWarning } = useMemo(() => {
    const isDisable = (value || '').length >= LIMIT_OPTION_CHARACTER;
    const isShowCharacter = (value || '').length >= SHOW_OPTION_CHARACTER;
    const isShowWarning = isDuplicate || isShowCharacter;

    return { isDisable, isShowCharacter, isShowWarning };
  }, [value, isDuplicate]);

  const handleRemoveOption = () => {
    typeof onRemoveOption === 'function' && onRemoveOption(_id);
  };

  const handleChangeValue = (value = '') => {
    const newOption = { ...data, value };
    typeof onOptionChange === 'function' && onOptionChange(newOption);
  };

  return (
    <S.ItemOptionContainer data-for="disable-edit-poll" data-tip>
      <S.ItemOptionWrapper disableEditPoll={disableEditPoll} otherOption={otherOption}>
        <OptionInput
          placeholder={placeholder}
          initEditor={data}
          onInputChange={handleChangeValue}
          isDisable={isDisable}
          disableEditPoll={disableEditPoll || otherOption}
          className={classNames({ 'warning-border': isShowWarning })}
        />

        {!isLastItem && (
          <button className="remove-button" onClick={handleRemoveOption}>
            <RemoveIcon />
          </button>
        )}
      </S.ItemOptionWrapper>

      {isShowWarning && (
        <S.ItemOptionWrapper>
          <div className="label-warning">
            {isDuplicate && (
              <S.WarningOptionLabel className="warning-duplicate">Already have this option</S.WarningOptionLabel>
            )}
            {isShowCharacter && (
              <S.WarningOptionLabel
                className={classNames('count-character', {
                  'is-disable': isDisable,
                })}
              >
                {`${value.length}/${LIMIT_OPTION_CHARACTER}`}
              </S.WarningOptionLabel>
            )}
          </div>
        </S.ItemOptionWrapper>
      )}
    </S.ItemOptionContainer>
  );
};

export default ItemOption;
