import React from 'react';
import * as S from './style';
import { ReactComponent as FormIcon } from 'assets/icons/form_table_icon.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import { SORTER, SORT } from 'redux/forms/reducers';

const TableHeader = ({ list, onSort, query }) => {
  const handleSort = sorter => () => {
    if (query.sorter === sorter) {
      if (query.sort === SORT.ASC) {
        onSort({ sorter: sorter, sort: SORT.DESC });
      } else {
        onSort({ sorter: sorter, sort: SORT.ASC });
      }
    } else {
      if (sorter === SORTER.NAME) {
        onSort({ sorter: sorter, sort: SORT.ASC });
      } else {
        onSort({ sorter: sorter, sort: SORT.DESC });
      }
    }
  };

  return (
    <S.TableHeaderWrapper>
      <S.HeaderItem isActive={query.sorter === SORTER.NAME}>
        <S.HeaderItemContent hasSort onClick={handleSort(SORTER.NAME)} data-id="forms-column">
          <FormIcon className="form-icon" />
          <span>Forms {`(${query.total || 0})`}</span>
          {query.sort === SORT.ASC && query.sorter === SORTER.NAME ? <ArrowDown /> : <ArrowUp />}
        </S.HeaderItemContent>
      </S.HeaderItem>
      <S.HeaderItem isCenter={true}>
        <S.HeaderItemContent>Questions</S.HeaderItemContent>
      </S.HeaderItem>
      <S.HeaderItem isCenter={true}>
        <S.HeaderItemContent>Responses</S.HeaderItemContent>
      </S.HeaderItem>
      <S.HeaderItem isCenter={true} isActive={query.sorter === SORTER.LAST_UPDATED}>
        <S.HeaderItemContent hasSort onClick={handleSort(SORTER.LAST_UPDATED)} data-id="last-updated-column">
          <span>Last Updated</span>
          {query.sort === SORT.ASC && query.sorter === SORTER.LAST_UPDATED ? <ArrowUp /> : <ArrowDown />}
        </S.HeaderItemContent>
      </S.HeaderItem>
      <S.HeaderItem>
        <S.HeaderItemContent></S.HeaderItemContent>
      </S.HeaderItem>
    </S.TableHeaderWrapper>
  );
};

export default TableHeader;
