import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Arrow = styled.div`
  position: absolute;
  top: 0;
  bottom: 80px;
  width: 50%;
  opacity: 0.4;
  background-color: transparent;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  i {
    background-repeat: no-repeat;
    background-size: auto;
    height: 32px;
    width: 32px;
    display: inline-block;
  }

  &.prev {
    padding-left: 15px;
    left: 0;
    text-align: left;

    i {
      background-image: url(${CDN_URL}/images/slide_arrow_left.svg);
      background-position: left center;
    }
  }

  &.next {
    padding-right: 15px;
    right: 0;
    text-align: right;
    justify-content: end;
    i {
      background-image: url(${CDN_URL}/images/slide_arrow_right.svg);
      background-position: right center;
    }
  }
  &.slide__arrow:hover {
    opacity: 1;
  }
  &.is-video {
    width: 50px;
    height: calc(100% - 80px);
    i {
      margin-top: 80px;
    }
  }
`;

export const Item = styled.div`
  width: auto;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 0px;
    height: 0px;
    display: block;
  }
`;

export const BasicSlideContainer = styled.div`
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  background-color: #000;
  position: relative;
  cursor: pointer;
  max-height: 100%;

  ${Arrow} {
    display: none;
  }

  :hover {
    ${Arrow} {
      display: flex;
    }
  }
`;

export const PlaceholderLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #000;
  border-radius: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #d9d9d9 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
