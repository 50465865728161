import styled, { css } from 'styled-components';
import { SelectFile } from 'shared/FormControl';

import TextEditable from 'shared/TextEditable';
import { CDN_URL } from 'constants/commonData';

export const WorkoutInfoArea = styled.div``;

export const NameField = styled(TextEditable)`
  &.workout-collection-title {
    width: 100%;
  }

  .text-editable__input {
    &:empty::before {
      opacity: 0.3;
      font-weight: 600;
    }
  }

  // .text-editable__container--active,
  // .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
  //   padding: 7px 9px !important;
  // }

  .text-editable__container {
    padding: 4px 9px;
  }
`;

export const DescriptionField = styled(TextEditable)`
  &.workout-collection-title {
    margin-bottom: 14px;
  }

  .text-editable__container {
    padding-top: 0;
    padding-bottom: 0;
    transform: translateX(-9px);

    &:not(.text-editable__container--active) {
      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .text-editable__input {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;

    &:empty::before {
      color: #aeaebb;
      opacity: 1;
      font-weight: 400;
    }
  }

  .text-editable__container--active {
    .text-editable__input {
      min-height: 40px;
      max-height: 108px;
      padding-top: 7px;
      padding-bottom: 7px;
      overflow-y: auto;
    }
  }

  // .text-editable__container--active,
  // .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
  //   padding: 7px 9px !important;
  // }

  // .text-editable__container {
  //   padding: 7px 0;
  // }
`;

export const Label = styled.div`
  color: #6a778a;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 7px;
  text-transform: uppercase;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 15px;
`;

export const BackgroundContainer = styled.div`
  width: 77px;
  height: 50px;
  background: #fafbfc;
  border: 0.971658px solid #ccd1db;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #dadfea;
    box-sizing: border-box;
    border-radius: 5px;
  }
  cursor: pointer;
`;

export const BannerWrapper = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #e2e5ec;
  display: flex;
  border-radius: 4px;
  height: 100%;
  ${props =>
    props.isDragging &&
    css`
      outline: 1px dashed #5158cf;
    `}
  align-items: center;
  justify-content: center;
  position: relative;
  .hidden {
    visibility: hidden;
  }
  .show-on-hover {
    visibility: hidden;
  }
  :hover {
    .show-on-hover {
      visibility: visible;
    }
  }
`;

export const SelectBanner = styled(SelectFile)`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

export const Icon = styled.div`
  width: 33px;
  height: 35px;
  //   background: transparent url(${CDN_URL}/images/drop_image.svg) no-repeat center;
  background-image: url(${CDN_URL}/images/drop_image_transparent.svg);
  background-size: contain;
  z-index: 1;
`;

export const EditButton = styled.div`
  background-color: #00000030;
  height: 100%;
  width: 69px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 8px 15px;
  font-family: Open Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #818da1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  //   svg {
  //     margin-right: 10px;
  //     path {
  //       fill: #818da1;
  //     }
  //   }

  //   :hover {
  //     color: #5158cf;
  //     svg {
  //       margin-right: 10px;
  //       path {
  //         fill: #5158cf;
  //       }
  //     }
  //   }
`;

export const CountCharacter = styled.span`
  display: none;
  position: absolute;
  top: 50%;
  right: 9px;
  color: #0000004d;
  font-weight: 400;
  font-size: 13px;
  transform: translateY(-50%);
`;

export const TextEditableArea = styled.div`
  position: relative;
  width: 450px;
  height: 37px;
  margin-bottom: 26px;
  transform: translateX(-11px);

  .text-editable__input {
    width: calc(100% - 51px);
    overflow-wrap: normal;
    overflow-y: hidden;
    overflow-x: visible;
    white-space: nowrap;
    text-overflow: initial;

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &:focus-within,
  &:focus,
  &:hover {
    background: #f1f1f3;
    border-radius: 5px;

    ${CountCharacter} {
      display: initial;
    }
  }
`;
