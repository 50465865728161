import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.progress-no-data {
    display: inline-block;
  }

  .progress__weeks {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 10px;
  }

  .progress__days {
    display: flex;
    align-items: center;
  }

  .progress__no-days {
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #77849d;
    margin-right: 15px;
  }

  .progress__progress-days {
    margin-right: 5px;
  }

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 60px;
    height: 6px;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #e9effb;
    border-radius: 99px;
  }

  progress[value]::-webkit-progress-value {
    background-color: #3bc384;
    border-radius: 99px;
  }

  .progress__percent-days {
    font-size: 10px;
    line-height: 100%;
    /* identical to box height, or 10px */

    display: flex;
    align-items: center;

    color: #77849d;
  }

  .no-data {
    height: 1px;
    width: 20px;
    background: #202353;
    opacity: 0.2;
  }
`;

const ProgressColumn = props => {
  const { completed_day, weeks, status } = props;
  const completed_week = _.ceil(completed_day / 7);
  const completed_percent = _.round((completed_day * 100) / (weeks * 7), 0);
  return status !== 0 ? (
    <Wrapper>
      <div className="progress__weeks">
        Week {completed_week} of {weeks}
      </div>
      <div className="progress__days">
        <div className="progress__no-days">Day {completed_day}</div>
        <progress className="progress__progress-days" value={completed_percent} max="100" />
        <div className="progress__percent-days">{completed_percent}%</div>
      </div>
    </Wrapper>
  ) : (
    <Wrapper className="progress-no-data">
      <div className="no-data" />
    </Wrapper>
  );
};

export default ProgressColumn;
