import React from 'react';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

import { ReactComponent as CopyWeek } from 'assets/icons/duplicate_week.svg';
import { checkSameMonth } from 'utils/commonFunction';
import { CLASSNAME_TOAST } from 'constants/commonData';
import { formatDateSafari } from 'utils/commonFunction';

function ButtonCopyWeek(props) {
  const { weekIndex, startDate, handleSelectWeekToCopy } = props;
  function onCopyWeek() {
    const day = DateTime.fromISO(new Date(formatDateSafari(startDate)).toISOString());
    const copyFromDate = day.plus({ days: 7 * weekIndex });
    const copyToDate = day.plus({ days: 7 * weekIndex + 6 });
    const isSameMonth = checkSameMonth(copyFromDate, copyToDate);
    const copyFromDateFormat = copyFromDate.toFormat('LLL d');
    const copyToDateFormat = copyToDate.toFormat(isSameMonth ? 'd' : 'LLL d');
    handleSelectWeekToCopy &&
      handleSelectWeekToCopy({
        from: copyFromDate,
        to: copyToDate,
      });
    toast(`Week of ${copyFromDateFormat} - ${copyToDateFormat} copied. Click on week to paste workouts.`, {
      className: CLASSNAME_TOAST.TRAINING_CALENDAR,
    });
  }
  return (
    <>
      <CopyWeek onClick={onCopyWeek} data-tip data-for="copy-week-on-tooltip" />
      <ReactTooltip id="copy-week-on-tooltip" effect="solid" place={'right'}>
        <div>Copy Week</div>
      </ReactTooltip>
    </>
  );
}

export default ButtonCopyWeek;
