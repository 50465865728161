import styled, { css } from 'styled-components';

export const DotWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 104px;
  max-width: 90px;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
`;

export const DotMain = styled.div`
  position: relative;
  align-items: flex-end;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  transform: translateX(${props => props.valueTranslateX || 0}px);

  ${props =>
    props.center &&
    css`
      margin: 0 auto;
    `}
`;

export const DotItem = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 4px;
  display: inline-block;
  transition: 0.5s;
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ececec;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: 0.5s;
    ${props =>
      props.active &&
      css`
        background: #5c5bbd;
      `}

    ${props =>
      props.between &&
      css`
        width: 7px;
        height: 7px;
      `}

    ${props =>
      props.small &&
      css`
        width: 4px;
        height: 4px;
      `}
  }
`;
