import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import HabitsBuilderPopup from './component';
import { toggleModal } from 'actions/modal';
import { addHabit, updateHabit, removeHabit, saveHabitToLibrary, endHabit } from 'redux/habits/actions';
import { toggleConfirmModal } from 'actions/modal';
import {
  addAutoflowIntervalHabit,
  removeAutoflowIntervalHabit,
  updateAutoflowIntervalHabit,
  saveAutoflowIntervalHabitToLibrary,
} from 'redux/autoflowInterval/habit/actions';
import {
  addAutoflowHabit,
  removeAutoflowHabit,
  updateAutoflowHabit,
  saveAutoflowHabitToLibrary,
} from 'redux/autoflow/habit/actions';

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    clientId: get(rootReducer, 'client.selected'),
    habitUnits: get(rootReducer, 'habits.habitUnits'),
    clientTimezone: get(state.rootReducer.client.workingClientDetail, 'timezone'),
    totalWeeks: get(rootReducer, 'autoflow.common.workingAutoflow.interval_weeks.length'),
    autoflowId: get(rootReducer, 'autoflow.common.workingAutoflow._id'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    addHabit: bindActionCreators(addHabit, dispatch),
    updateHabit: bindActionCreators(updateHabit, dispatch),
    replace: bindActionCreators(replace, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    removeCurrentHabit: bindActionCreators(removeHabit, dispatch),
    saveHabitToLibrary: bindActionCreators(saveHabitToLibrary, dispatch),
    endCurrentHabit: bindActionCreators(endHabit, dispatch),
    addAutoflowIntervalHabit: bindActionCreators(addAutoflowIntervalHabit, dispatch),
    removeAutoflowIntervalHabit: bindActionCreators(removeAutoflowIntervalHabit, dispatch),
    updateAutoflowIntervalHabit: bindActionCreators(updateAutoflowIntervalHabit, dispatch),
    saveAutoflowIntervalHabitToLibrary: bindActionCreators(saveAutoflowIntervalHabitToLibrary, dispatch),
    addAutoflowHabit: bindActionCreators(addAutoflowHabit, dispatch),
    removeAutoflowHabit: bindActionCreators(removeAutoflowHabit, dispatch),
    updateAutoflowHabit: bindActionCreators(updateAutoflowHabit, dispatch),
    saveAutoflowHabitToLibrary: bindActionCreators(saveAutoflowHabitToLibrary, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HabitsBuilderPopup);
