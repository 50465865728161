import get from 'lodash/get';
import React from 'react';
import { components } from 'react-select';
import { CustomLoadingMessage } from '../styles';

const CustomNoOptionsMessage = props => {
  const isLoading = get(props, 'isLoading', false);
  return (
    <components.NoOptionsMessage {...props} customLoading={isLoading}>
      {isLoading ? (
        <CustomLoadingMessage className="loading-message">Loading...</CustomLoadingMessage>
      ) : (
        <p>No surveys found</p>
      )}
    </components.NoOptionsMessage>
  );
};

export default CustomNoOptionsMessage;
