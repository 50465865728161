import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { WEB_FEATURES } from './helps';
import ItemFeatureWeb from './ItemFeatureWeb';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  variableWidth: true,
  speed: 500, // 500ms ~ 0.5s
  autoplaySpeed: 20000, // 20000ms ~ 20s
  pauseOnHover: true,
  customPaging: i => <Dots key={`dot_${i}`} className="custom-dot-item" />,
};

const CarouselFeatureWeb = () => {
  return (
    <CarouselFeatureWebWrapper {...settings}>
      {WEB_FEATURES && WEB_FEATURES.map((it, index) => <ItemFeatureWeb {...it} key={`web_feature_${index}`} />)}
    </CarouselFeatureWebWrapper>
  );
};

export default CarouselFeatureWeb;

const CarouselFeatureWebWrapper = styled(Slider)`
  .slick-list {
    padding-top: 48px;
    padding-bottom: 40px;
  }

  .slick-dots {
    position: relative;
    bottom: unset;
    height: 10px;
    font-size: 0px;

    .slick-active .custom-dot-item {
      background: #5e59ff;
      opacity: 1;
    }

    li {
      width: initial;
      height: initial;
      margin: 0 4px;
    }
  }
`;

const Dots = styled.div`
  width: 10px;
  height: 10px;
  padding: 0;
  background: #4a3f36;
  opacity: 0.2;
  border-radius: 50%;
  border: none;

  &:before {
    content: none;
  }
`;
