import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import find from 'lodash/find';
import get from 'lodash/get';
import { Image } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';

// components
import RightPanel from './components/RightPanel';
import ClientMealPlanCalendar from './components/Calendar';
import MealPlanList from './components/MealPlanList';
import MealPlanFormat, { BUTTON_MEAL_PLAN_FORMAT_TYPE } from './components/MealPlanFormat';

// constants
import { DAY_FORMAT } from 'constants/time-format';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as CollapseIcon } from 'assets/icons/MealPlans/calendar-collapse.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/MealPlans/calendar-expand.svg';

// Styles
import * as S from './style';

const getTracked = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('tracked') ? moment(params.get('tracked').replace(/-/g, '/')).format('YYYY-MM-DD') : new Date();
};

const ClientMealPlan = props => {
  const {
    permission,
    toggleModal,
    selectedClient,
    getClientMealPlanByDay,
    getClientMealPlanByWeek,
    getClientMealPlanByMonth,
    clientTimezone,
    coachTimezone,
    cloudfrontList,
    assignedMealPlanClients,
    assignedMealPlanClientsByRange,
    manualMealClients,
    resetClientMealPlan,
    permissionAddOn,
    mealPlanScheduling,
    generalSettings,
    updateStatusMealPlanFormat,
    listStatusMealPlanFormat,
    updateListStatusMealPlanFormat,
    viewMode,
  } = props;
  const tracked = getTracked();

  const [selectedDay, setSelectedDay] = useState(moment(tracked).tz(clientTimezone));
  const [currentMonth, setCurrentMonth] = useState(moment(tracked).tz(clientTimezone));
  const [hideLeftPanel, setHideLeftPanel] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const SelectStructuredMealPlan = useMemo(() => {
    return get(listStatusMealPlanFormat, 'data.select_structured_meal_plan', false);
  }, [listStatusMealPlanFormat]);

  const { width: windowWidth } = useWindowDimensions();
  const mealPlanSetting = find(selectedClient.feature_preferences, item => item.type === 'meal_plan');

  const turnOff = !mealPlanSetting || !mealPlanSetting.state || !permission;

  const fetchClientMealPlan = day => {
    getClientMealPlanByDay(selectedClient._id, day);
  };

  const fetchClientMealPlanByWeek = day => {
    getClientMealPlanByWeek(selectedClient._id, day);
  };

  useEffect(() => {
    return () => {
      typeof resetClientMealPlan === 'function' && resetClientMealPlan();
    };
  }, []);

  useEffect(() => {
    if (permission && viewMode.key === 'day') {
      fetchClientMealPlan(moment(selectedDay).format(DAY_FORMAT.YYYY_MM_DD));
    } else if (permission && viewMode.key === 'week') {
      fetchClientMealPlanByWeek(moment(selectedDay).format(DAY_FORMAT.YYYY_MM_DD));
    }
  }, [selectedDay, viewMode]);

  const renderEmptyView = () => (
    <S.EmptyMealByDay>
      <Image src={`${CDN_URL}/images/empty_food_journal.svg`} />
      <S.EmptyTitle>No Meals Scheduled.</S.EmptyTitle>
    </S.EmptyMealByDay>
  );

  const renderButtonExpand = (className = '', onClick) => {
    if (className && typeof onClick === 'function' && isOpen) return;

    return (
      <S.ButtonExpand
        data-tip
        data-for="button-expand-tooltip"
        onClick={() => {
          if (typeof onClick === 'function' && className) onClick();
          setHideLeftPanel(false);
        }}
        className={className}
      >
        <ExpandIcon />
        <ReactTooltip id="button-expand-tooltip" effect="solid" place="top">
          Expand
        </ReactTooltip>
      </S.ButtonExpand>
    );
  };

  const renderButtonCollapse = () => {
    if (hideLeftPanel) return renderButtonExpand();

    return (
      <S.ButtonCollapse
        data-tip
        data-for="button-collapse-tooltip"
        onClick={() => {
          setHideLeftPanel(true);
        }}
        className="button-collapse"
      >
        <CollapseIcon />
        <ReactTooltip id="button-collapse-tooltip" effect="solid" place="top">
          Collapse
        </ReactTooltip>
      </S.ButtonCollapse>
    );
  };

  const onChangeStatus = data => {
    const { id, type } = data;

    if (type === BUTTON_MEAL_PLAN_FORMAT_TYPE.NOTIFY_ME) {
      updateStatusMealPlanFormat({ [id]: true }).then(() => {
        toast(
          <>
            Thanks for your interest. We will notify you through <br /> notifications and email when this feature
            available.
          </>,
        );
      });
    } else {
      updateListStatusMealPlanFormat({ select_structured_meal_plan: true, client: selectedClient._id });
    }
  };

  const renderContentLeftPanel = () => {
    return (
      <>
        <S.CalendarWrapper>
          <ClientMealPlanCalendar
            selectedClient={selectedClient}
            selectedDay={selectedDay}
            onChangeDay={setSelectedDay}
            getClientMealPlanByMonth={getClientMealPlanByMonth}
            clientTimezone={clientTimezone}
            coachTimezone={coachTimezone}
            assignedMealPlanClientsByRange={assignedMealPlanClientsByRange}
            manualMealClients={manualMealClients}
            setCurrentMonth={setCurrentMonth}
            mealPlanScheduling={mealPlanScheduling}
            isOpen={isOpen}
          />
        </S.CalendarWrapper>
        <MealPlanList
          cloudfrontList={cloudfrontList}
          toggleModal={toggleModal}
          selectedDay={selectedDay}
          assignedMealPlanClients={assignedMealPlanClients}
          assignedMealPlanClientsByRange={assignedMealPlanClientsByRange}
          onChangeDay={setSelectedDay}
          clientTimezone={clientTimezone}
          currentMonth={currentMonth}
          permissionAddOn={permissionAddOn}
        />
      </>
    );
  };

  const handleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  if (get(listStatusMealPlanFormat, 'loading', false)) {
    return null;
  }

  if (!SelectStructuredMealPlan && !turnOff) {
    const {
      notify_flexible_meal_plan = false,
      notify_on_demand_recipe_books = false,
      loading = false,
    } = generalSettings;

    return (
      <S.MealPlanFormatWrapper>
        <MealPlanFormat
          statusButtonFormat={{
            select_structured_meal_plan: SelectStructuredMealPlan,
            notify_flexible_meal_plan: notify_flexible_meal_plan,
            notify_on_demand_recipe_books: notify_on_demand_recipe_books,
          }}
          onChangeStatus={onChangeStatus}
          loading={loading || get(listStatusMealPlanFormat, 'loading', false)}
        />
      </S.MealPlanFormatWrapper>
    );
  }

  return (
    <S.Wrapper className="client-meal-plans" turnedOff={turnOff}>
      {turnOff ? (
        <>
          <S.FeatureTurnedOff>
            <Image src={`${CDN_URL}/images/feature_turned_off.svg`} />
            <span>Meal Plan has been turned off for this client</span>
          </S.FeatureTurnedOff>
          {renderEmptyView()}
        </>
      ) : (
        <S.ContentWrapper>
          {windowWidth >= 1280 ? (
            <>
              <S.LeftPanel hideLeftPanel={hideLeftPanel} noPermissionAddOn={!permissionAddOn} className="left-panel">
                {renderContentLeftPanel()}
              </S.LeftPanel>
              {renderButtonCollapse()}
            </>
          ) : (
            <>
              {renderButtonExpand('button-expand-xl', handleOpen)}
              {createPortal(
                <S.LeftPanelWrapper open={isOpen} onClick={closePopup}>
                  <S.LeftPanel
                    hideLeftPanel={hideLeftPanel}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    noPermissionAddOn={!permissionAddOn}
                    className="left-panel-xl"
                  >
                    {renderContentLeftPanel()}
                  </S.LeftPanel>
                </S.LeftPanelWrapper>,
                document.body,
              )}
            </>
          )}
          <S.RightPanelWrapper>
            <RightPanel
              selectedClient={selectedClient}
              selectedDay={selectedDay}
              onChangeDay={setSelectedDay}
              permissionAddOn={permissionAddOn}
              clientTimezone={clientTimezone}
            />
          </S.RightPanelWrapper>
        </S.ContentWrapper>
      )}
    </S.Wrapper>
  );
};

const mapState = ({ rootReducer }) => ({
  mealPlanScheduling: rootReducer.permission.meal_plan_scheduling,
});

export default connect(mapState)(ClientMealPlan);
