import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';

export const DiscussionTimeline = styled.div`
  width: 100%;
  padding: 20px 220px;
  background: #f0f2f5;
  overflow: none;
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const PostFeed = styled.div`
  height: 100%;
  background: #f0f2f5;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  position: relative;
`;

export const MainPostWrapper = styled.div`
  position: relative;
`;

export const HeaderTabWrapper = styled.div`
  padding: 4px;
  width: 598px;
  height: 40px;
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #83868e;
`;

export const AllScheduled = styled.div`
  padding: 4px 8px;
  gap: 8px;
  width: 295px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  background: #f6f6f6;
  color: #83868e;
  cursor: pointer;

  ${props =>
    props.enableTab &&
    css`
      color: #fff;
      background: #5158cf;
      box-shadow: 0px 1px 4px rgba(38, 38, 38, 0.2);
    `}
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const MyPosts = styled.div`
  padding: 4px 8px;
  gap: 8px;
  width: 295px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  color: #83868e;
  cursor: pointer;

  ${props =>
    props.enableTab &&
    css`
      color: #fff;
      background: #5158cf;
      box-shadow: 0px 1px 4px rgba(38, 38, 38, 0.2);
    `}
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const SideBar = styled.div`
  height: 100%;
  background: #f0f2f5;
  & > * {
    margin-bottom: 20px;
  }
  position: sticky;
  top: 80px;
`;

export const EmptyFeed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 289px;
  margin-top: 102px;
  color: #202353;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  font-style: normal;
  font-family: Open Sans;
  align-self: center;

  .subtitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
`;

export const EmptyTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 5px;
  color: #1e0a3c;
`;

export const EmptySubTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #5f5f5f;
`;

export const DisabledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #202353;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  font-style: normal;
  align-self: center;
  position: absolute;
  top: 218px;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    top: -170px;
    pointer-events: none;
    background-image: linear-gradient(360deg, #f0f2f5 51.11%, rgba(240, 242, 245, 0) 96.89%);
    width: 101%;
    height: 100%;
  }

  .stack-top {
    z-index: 9;
  }
`;

export const DisabledTitle = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  text-align: center;
  z-index: 9;
  margin-top: -30px;
`;
export const DisabledSubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  z-index: 9;
  margin-top: 20px;
`;

export const ConnectToAutoflowBtn = styled(Button)`
  color: #5158cf;
  z-index: 9;
  margin-top: 20px;

  button {
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 38px;
    span {
      text-transform: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

export const ScrollTop = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  position: fixed;
  right: 88px;
  bottom: 30px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  z-index: 1000;
  background: #ffffff;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: #6456c4 !important;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  margin-top: 10px;

  ${props =>
    props.disabled &&
    css`
      background: #f0f2f5;
      pointer-events: none;
      position: relative;
      height: 500px;
      overflow: hidden;
    `}

  .post {
    margin-bottom: 20px;
    width: 598px;
    border-radius: 8px;
    padding: 0px !important;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Section = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #202353;
  opacity: 0.7;
  width: 100%;
  padding: 10px 20px;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 20px;
  height: 84px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;

export const IndicatorPlaceHolder = styled.div`
  height: 84px;
  display: block;
`;
