import React from 'react';
import { NoOptionsMessageStyle } from './style';

const NoOptionsMessage = props => {
  return (
    <NoOptionsMessageStyle>
      <div className="title">No forums available</div>
      <div
        className="description"
        style={{
          marginTop: 7,
          fontWeight: 'normal',
          fontSize: 13,
          lineHeight: '150%',
          textAlign: 'center',
          color: '#202353',
          opacity: '0.6',
        }}
      >
        Create your first community forum using the Everfit Coach mobile app
      </div>
    </NoOptionsMessageStyle>
  );
};

export default NoOptionsMessage;
