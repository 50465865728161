import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import Select from 'react-select';
import * as S from './style';
import countries from 'constants/countries';
import USStates from 'constants/us-states';
import { DropdownIndicator } from 'shared/Icons';
import { validateOnlyNumber } from 'utils/validations';

export const DEFAULT_INFORMATION = {
  name: '',
  line1: '',
  apt_unit_suite: '',
  country: 'US',
  city: '',
  state: '',
  postal_code: '',
};

class StripeBillingInformation extends React.Component {
  constructor(props) {
    super(props);
    const orignalState = _.get(props, 'originalData.state', '').toLowerCase();
    let stateObject = orignalState
      ? _.find(USStates, (item) => item.label.toLowerCase() === orignalState || item.value.toLowerCase() === orignalState)
      : null;

    this.state = {
      data: { ...DEFAULT_INFORMATION, ...props.originalData, state: _.get(props, 'originalData.state', '') },
      countryObject: _.find(
        countries,
        (item) => item.value === (_.get(props, 'originalData.country') || 'US')
      ),
      stateObject,
    };

    this.customStyles = {
      control: (base) => ({
        ...base,
        height: 46,
        minHeight: 46,
      }),
      menu: (base) => ({
        ...base,
        margin: 0,
      }),
      option: (base) => ({
        ...base,
        padding: 10,
      }),
    };
  }

  componentDidMount() {
    const countryObject = _.find(countries, item => item.value === (_.get(this.props, 'originalData.country') || 'US'));
    this.onSelectCountry(countryObject);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.showError !== this.props.showError || !_.isEqual(nextState.data, this.state.data)) {
      return true;
    }

    if (_.isEqual(nextProps.originalData, nextState.data)) {
      return false;
    }

    return true;
  }

  updateData = (newData) => {
    this.setState({ ...newData }, () => {
      this.props.onChange(this.state.data);
    });
  };

  onSelectCountry = (countryObject) => {
    const { data } = this.state;

    if (data.country !== countryObject.value) {
      this.updateData({
        data: { ...data, country: countryObject.value, city: '', state: '', postal_code: '' },
        countryObject,
        stateObject: null,
      });
    }
  };

  onSelectState = (stateObject) => {
    const { data } = this.state;

    if (data.state !== stateObject.label) {
      this.updateData({ data: { ...data, state: stateObject.label }, stateObject });
    }
  };

  onInputChange = (event) => {
    const { value, name } = event.target;
    const { data } = this.state;
    this.updateData({ data: { ...data, [name]: value } });
  };

  onZipCodeChange = (event) => {
    const { value } = event.target;

    if (validateOnlyNumber(value)) {
      const { data } = this.state;
      this.updateData({ data: { ...data, postal_code: value } });
    }
  };

  render() {
    const { showError } = this.props;
    const { countryObject, stateObject } = this.state;
    const { name, line1, country, apt_unit_suite, city, state, postal_code } = this.state.data;

    return (
      <S.Wrapper className="stripe-billing-information">
        <S.FormGroup className={classnames('form-group--full-name', { error: showError && !name.trim() })}>
          <input
            defaultValue={name}
            type="text"
            onChange={this.onInputChange}
            placeholder="Full Name"
            name="name"
          />
        </S.FormGroup>
        <S.FormGroup className={classnames('form-group--line1', { error: showError && !line1.trim() })}>
          <input
            defaultValue={line1}
            type="text"
            onChange={this.onInputChange}
            placeholder="Address"
            name="line1"
          />
        </S.FormGroup>
        <S.FormGroup className="form-group--apt_unit_suite">
          <input
            defaultValue={apt_unit_suite}
            type="text"
            onChange={this.onInputChange}
            placeholder="Apt, Unit, Suite, etc. (optional)"
            name="apt_unit_suite"
          />
        </S.FormGroup>
        <S.FormGroup data-billing-countries="countries">
          <Select
            options={countries}
            components={{ DropdownIndicator }}
            classNamePrefix="billing-select-national billing-select"
            className="billing-select-container-national billing-select-container"
            onChange={this.onSelectCountry}
            noOptionsMessage={() => 'No result'}
            value={countryObject}
            menuPosition="fixed"
            styles={this.customStyles}
          />
        </S.FormGroup>
        {country === 'US' ? (
          <>
            <S.USFormContainer>
              <S.FormGroup className={classnames('form-group--city', { error: showError && !city.trim() })}>
                <input
                  defaultValue={city}
                  type="text"
                  onChange={this.onInputChange}
                  placeholder="City"
                  name="city"
                />
              </S.FormGroup>
              <S.FormGroup
                data-billing-states="states"
                className={classnames('form-group--state', { error: showError && !state })}
              >
                <Select
                  options={USStates}
                  components={{ DropdownIndicator }}
                  classNamePrefix="billing-select-state billing-select"
                  className="billing-select-container-state billing-select-container"
                  onChange={this.onSelectState}
                  noOptionsMessage={() => 'No result'}
                  value={stateObject}
                  menuPosition="fixed"
                  styles={this.customStyles}
                  placeholder="State"
                />
              </S.FormGroup>
              <S.FormGroup
                className={classnames('form-group--postal-code', { error: showError && !postal_code.trim() })}
              >
                <input
                  value={postal_code}
                  type="text"
                  onChange={this.onZipCodeChange}
                  placeholder="Zip code"
                  name="postal_code"
                />
              </S.FormGroup>
            </S.USFormContainer>
          </>
        ) : (
          <>
            {' '}
            <S.FormGroup className={classnames('form-group--city', { error: showError && !city.trim() })}>
              <input
                defaultValue={city}
                type="text"
                onChange={this.onInputChange}
                placeholder="City / Town / Village"
                name="city"
              />
            </S.FormGroup>
            <S.Grid>
              <S.FormGroup className="form-group--province">
                <input
                  defaultValue={state}
                  type="text"
                  onChange={this.onInputChange}
                  placeholder="Province / Region"
                  name="state"
                />
              </S.FormGroup>
              <S.FormGroup
                className={classnames('form-group--postal-code', { error: showError && !postal_code.trim() })}
              >
                <input
                  defaultValue={postal_code}
                  type="text"
                  onChange={this.onInputChange}
                  placeholder="Postal code"
                  name="postal_code"
                />
              </S.FormGroup>
            </S.Grid>
          </>
        )}
      </S.Wrapper>
    );
  }
}

export default StripeBillingInformation;

export const StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    padding: '8px 14px',
    base: {
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: 'normal',
      color: '#202353',
      fontFamily: 'Open Sans, sans-serif',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.2)',
      },
    },
    invalid: {
      color: '#EA314A',
    },
  },
};

export const StripeElementsContainer = styled.div`
  margin-bottom: 20px;

  .error-message {
    margin-top: 10px;
  }

  .StripeElement {
    background: #ffffff;
    border: 1px solid #dadfea;
    border-radius: 3px;
    padding: 13px 14px;

    &--focus {
      border-color: #5158cf;
    }

    &--invalid {
      background-color: rgba(234, 49, 74, 0.05);
      border-color: #ee2525;
    }

    ${props => props.showError && css`
      background-color: rgba(234, 49, 74, 0.05);
      border-color: #ee2525;
    `}

    :not(.StripeElement--invalid) {
      :hover {
        border-color: #5158cf;
      }
    }
  }
`;
