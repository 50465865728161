import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { copy, paste, resetCopyItem, deleteMessage, arrangeMessage } from 'redux/autoflow/autoMessage/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';
import Cell from './component';

const mapState = (state, ownProps) => {
  const { rootReducer, user } = state;
  const {
    autoflow: {
      common: { viewMode },
      autoMessage: { messages, copying },
    },
  } = rootReducer;
  const day = ownProps.day.format('MM-DD-YYYY');
  const dayData = _.find(messages, item => item.day === day);

  return {
    copying,
    messages: dayData ? dayData.messages : [],
    dayId: _.get(dayData, '_id'),
    user,
    compactView: viewMode > 2,
  };
};

const actionCreators = {
  copy,
  paste,
  resetCopyItem,
  deleteMessage,
  toggleConfirmModal,
  toggleModal,
  toggleSideBar,
  push,
  arrangeMessage,
};

export default withRouter(connect(mapState, actionCreators)(Cell));
