import styled from 'styled-components';

export const Wrapper = styled.div`
  .featureBanner {
    margin-bottom: 30px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const Link = styled.a`
  display: flex;
  margin-right: 30px;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: #5c5bbd;
  }
`;

export const LinkText = styled.span`
  color: #5c5bbd;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 1;
  margin-left: 4px;
`;
