import React, { useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import round from 'lodash/round';

// Components
import SleepGoalPopup from '../AddGoalPopup/SleepGoal';

// Constants
import { KEYS_METRIC, PERIOD_GROUP } from 'components/BodyMetricChartNew/constants';
import { convertMinToFromHourMin } from 'utils/commonFunction';

import { updateSleepGoal } from 'actions/bodyMetric';

// Assets
import { ReactComponent as GoalIcon } from 'assets/icons/goal-line.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-edit-goal.svg';
import { SLEEP_VIEW } from '../SpecialChart/Sleep/constants';
import * as S from './style';

const SleepKeysMetric = props => {
  const { bodyMetric, clientId, updateSleepGoalAction } = props;
  const {
    metricSettings,
    isGettingTarget,
    isGettingChartData,
    sleepSettings,
    filterTime,
    keyMetricData,
    target,
  } = bodyMetric;
  const { key_metrics = [] } = metricSettings;
  const isDurationView = sleepSettings.viewBy === SLEEP_VIEW.DURATION;
  const isHourlyView = filterTime.period_group === PERIOD_GROUP.HOURLY;
  const [isShowGoalPopup, setIsShowGoalPopup] = useState(false);

  const getTimeMoment = (value, isOnlyTime) => {
    // value: YYYY-MM-DD HH:mm | HH:mm | null
    if (!value) return null;

    if (isOnlyTime) {
      return moment(value, 'HH:mm');
    }
    return moment(value, 'YYYY-MM-DD HH:mm');
  };

  const keyValues = useMemo(() => {
    const data = get(target, 'data') || {};
    const avgTime = getTimeMoment(keyMetricData.sleep_time, true);
    const startTime = getTimeMoment(keyMetricData.starting_time);

    const durationGoal = data.value;
    const timeGoal = getTimeMoment(data.sleep_time_goal, true);

    return { avgTime, timeGoal, startTime, durationGoal, isEditedGoal: !!durationGoal };
  }, [keyMetricData, target]);

  const [, , showGoalKeyMetric, showStartingKeyMetric] = useMemo(() => {
    return KEYS_METRIC.map(item => key_metrics.includes(item.key));
  }, [key_metrics]);

  const toggleGoalPopup = () => {
    setIsShowGoalPopup(s => !s);
  };

  const handleSavingGoal = async payload => {
    updateSleepGoalAction({
      ...payload,
      client: clientId,
      unique_code: bodyMetric.unique_code,
      unit: bodyMetric.unitId,
    });
    setIsShowGoalPopup(false);
  };

  const formatNumber = (number = 0) => {
    const num = Math.abs(number);
    return Number.isInteger(num) ? Math.abs(num) : Math.abs(num).toFixed(1);
  };

  const renderMin = value => {
    const { min, hour } = convertMinToFromHourMin(value);
    if (!min && !hour) {
      return (
        <>
          <span className="empty-line">—</span>
          <S.Unit className="min-unit"> min</S.Unit>
        </>
      );
    }

    return (
      <>
        {hour > 0 && (
          <>
            {hour} <S.Unit className="min-unit">h </S.Unit>
          </>
        )}
        {min > 0 && (
          <>
            {Math.round(min)} <S.Unit className="sec-unit">min</S.Unit>
          </>
        )}
      </>
    );
  };

  if (isGettingTarget || isGettingChartData) {
    return null;
  }

  return (
    <S.Wrapper className="sleep-keys">
      <S.CurrentWrapper isEmpty={!keyMetricData.duration} className="average-wrapper">
        <S.KeyLabel color="#1FCCBE">
          {isDurationView
            ? isHourlyView
              ? 'Total duration'
              : 'Daily Average Duration'
            : isHourlyView
            ? 'Sleep time'
            : 'DAILY Average SLEEP TIME'}
        </S.KeyLabel>
        {!isGettingChartData && (
          <>
            <S.Number current className="sleep-avg-num">
              {isDurationView ? (
                <>{renderMin(round(formatNumber(keyMetricData.duration || 0)))}</>
              ) : (
                <>
                  {keyValues.avgTime ? keyValues.avgTime.format('h:mm ') : <span className="empty-line">—</span>}
                  <S.Unit className="min-unit">{keyValues.avgTime ? keyValues.avgTime.format(' A') : ''}</S.Unit>
                </>
              )}
            </S.Number>
          </>
        )}
      </S.CurrentWrapper>
      {showGoalKeyMetric && (
        <S.GoalWrapper>
          <S.KeyLabel className="sleep-goal">
            <GoalIcon className="goal-icon" />
            {isDurationView ? 'Duration Goal' : 'Sleep time goal'}
          </S.KeyLabel>
          {!isGettingTarget && (
            <S.StatWrapper>
              <S.Number data-tip data-for="goal-metric-on-tooltip" className="sleep-goal-num">
                {isDurationView ? (
                  <>{renderMin(keyValues.durationGoal)}</>
                ) : (
                  <>
                    {keyValues.timeGoal ? keyValues.timeGoal.format('h:mm ') : <span className="empty-line">—</span>}
                    <S.Unit className="min-unit">{keyValues.timeGoal ? keyValues.timeGoal.format(' A') : ''}</S.Unit>
                  </>
                )}
              </S.Number>
              <S.IconWrapper data-tip data-for="add-goal-on-tooltip" className="sleep-edit-goal">
                <EditIcon className="pencil" onClick={toggleGoalPopup} />
                <ReactTooltip className="app-tooltip" id="add-goal-on-tooltip" effect="solid" place={'top'}>
                  <S.TooltipStyle>{keyValues.isEditedGoal ? 'Edit' : 'Add'} Goal</S.TooltipStyle>
                </ReactTooltip>
              </S.IconWrapper>
            </S.StatWrapper>
          )}
          {isShowGoalPopup && (
            <SleepGoalPopup
              currentGoal={get(target, 'data') || {}}
              isEdit={keyValues.isEditedGoal}
              onClose={toggleGoalPopup}
              onSave={handleSavingGoal}
            />
          )}
        </S.GoalWrapper>
      )}
      {showStartingKeyMetric && (
        <S.StartingWrapper data-tip data-for="starting-metric-on-tooltip">
          <S.KeyLabel>Starting</S.KeyLabel>
          {!isGettingTarget && (
            <>
              {isDurationView ? (
                <S.Number>{renderMin(formatNumber(keyMetricData.starting_duration))}</S.Number>
              ) : (
                <S.Number>
                  {keyValues.startTime ? (
                    <>
                      {keyValues.startTime.format('h:mm ')}
                      <S.Unit className="min-unit">{keyValues.startTime.format('A')}</S.Unit>
                    </>
                  ) : (
                    <>
                      <span className="empty-line">—</span>
                    </>
                  )}
                </S.Number>
              )}
            </>
          )}
        </S.StartingWrapper>
      )}
    </S.Wrapper>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateSleepGoalAction: bindActionCreators(updateSleepGoal, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(SleepKeysMetric);
