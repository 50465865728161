import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

import * as S from './style';

export default props => {
  const { todayScreenLogo, isSubmitLogo, originalTodayLogoSize, cloudfrontList } = props;
  const [logo, setLogo] = useState(null);

  const logoUrl = () => {
    return todayScreenLogo ? convertS3UrlToCloudFrontUrl(todayScreenLogo.src, cloudfrontList, true) : null;
  };

  useEffect(() => {
    if (isSubmitLogo) return;
    setLogo(todayScreenLogo);
  }, [todayScreenLogo]);

  const renderLogo = () => {
    return (
      <S.Logo>
        {get(logo, 'src', false) ? (
          <S.TodayScreenLogo
            src={logoUrl()}
            horizontalSide={get(originalTodayLogoSize, 'horizontalSide', false)}
            verticalSide={get(originalTodayLogoSize, 'verticalSide', false)}
          />
        ) : (
          <S.DefaultLogo>Your Logo</S.DefaultLogo>
        )}
      </S.Logo>
    );
  };

  return (
    <S.Wrapper {...props}>
      {renderLogo()}
      <S.NotificationIcon />
      <S.Header>
        <div className="today">Today,&nbsp;{moment(new Date()).format('MMM D')}</div>
        <div className="hello">
          <span>Hello John! 👋</span>
        </div>
      </S.Header>
      <S.Content>
        <div className="let-do-this">Let’s do this</div>
        <S.Workout>
          <S.WorkoutContent>
            <div className="workout__title">TODAY’S WORKOUT</div>
            <div className="workout__name">Chest and Shoulder Workout</div>
            <S.StartWorkout>Start Workout</S.StartWorkout>
          </S.WorkoutContent>
        </S.Workout>
        <S.Task>
          <div className="task__header">Tasks (1/2)</div>
        </S.Task>
      </S.Content>
    </S.Wrapper>
  );
};
