import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { CDN_URL } from 'constants/commonData';

import './style.scss';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { Button } from 'shared/FormControl';

function ConfirmCancelChangesModal(props) {
  return (
    <Modal open={true} closeOnDimmerClick={false} className="modal--studio-confirm-actions" onClose={props.onClose}>
      <Modal.Content>
        <Wrapper>
          <ModalLayout.Header>
            <ModalLayout.HeaderIcon backgroundColor="#EA314A" icon={`${CDN_URL}/images/close_white.svg`} />
            <span>Cancel and Discard Changes</span>
          </ModalLayout.Header>
          <ModalLayout.Content>
            <div>
              This edited version of the Studio program will be discarded. The program calendar will reset to the live
              version of the program. Would you like to continue?
            </div>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              delete
              onClick={() => {
                props.onConfirm();
                props.onClose();
              }}
            >
              Discard Changes
            </Button>
          </ModalLayout.Actions>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
}

export default ConfirmCancelChangesModal;

const Wrapper = styled(ModalLayout.Wrapper)`
  ${ModalLayout.Header} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 15px;
  }
`;
