// Libs
import React from 'react';

// Shared
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

// Assets
import { ReactComponent as CheckIcon } from 'assets/icons/checked_white.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_cicre.svg';
import defaultImg from 'assets/icons/Ingredients/default_ingredient_image.png';

import * as S from './style';

const DietaryInfoItem = props => {
  const {
    item = {},
    handleChooseDietary = () => {},
    handleRemoveDietary = () => {},
    selected = false,
    className,
    hasCheckbox = true,
    hasClose = false,
    hasThumbnail = true,
    isShowLabelCustom = false,
  } = props;
  const { name = '', src = '' } = item;

  return (
    <S.DietaryInfoItemWrapper selected={selected} onClick={() => handleChooseDietary(item)} className={className}>
      {hasCheckbox && (
        <S.Checkbox>
          <CheckIcon />
        </S.Checkbox>
      )}
      {hasThumbnail ? (
        <S.Thumbnail className="avatar">
          <S3ImageWithFallback src={[src, defaultImg]} />
        </S.Thumbnail>
      ) : (
        <></>
      )}
      <S.LabelSelect className="label" isShowLabelCustom={isShowLabelCustom}>
        <span className="name">{name}</span> {isShowLabelCustom && <span className="label-custom">Custom</span>}
      </S.LabelSelect>
      {hasClose && (
        <S.CloseIcon onClick={() => handleRemoveDietary(item)}>
          <CloseIcon />
        </S.CloseIcon>
      )}
    </S.DietaryInfoItemWrapper>
  );
};

export default DietaryInfoItem;
