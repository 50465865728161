import { filter, find, get, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
  changeQueryParams,
  removeTask,
  updateSelectedTask,
  updateTaskSharingStatus,
} from 'redux/tasks-library/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { Checkbox } from 'shared/FormControl';
import { MenuTrigger } from 'shared/Icons';
import OwnerAvatar from 'shared/OwnerAvatar';
import SharingStatus from 'shared/SharingStatus';
import * as Layout from 'shared/LibraryLayout';
import ConfirmModal from 'shared/ConfirmModal';
import { isTeamAdmin, timeSince } from 'utils/commonFunction';
import { CDN_URL, TASK_TYPES, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';
import { MESSAGE, toastMessage } from '../constants';
import ModalShareOwner from '../ModalShareOwner';
import { ReactComponent as BodyMetricIcon } from 'assets/icons/task_option_body_metric.svg';
import { ReactComponent as FormIcon } from 'assets/icons/task_option_form.svg';
import { ReactComponent as GeneralIcon } from 'assets/icons/task_option_general.svg';
import { ReactComponent as ProgressPhotoIcon } from 'assets/icons/task_option_progress_photo.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';

import * as S from '../styles';

function Component(props) {
  const {
    list,
    user,
    toggleConfirmModal,
    removeTask,
    toggleModal,
    updateTaskSharingStatus,
    selectedTasks,
    updateSelectedTask,
    push,
  } = props;
  const hasTeam = !!user.team_member && user.team_member.length > 1;

  const handleDuplicate = task => {
    const taskId = get(task, '_id', '');
    push && push(`/home/task-library/${taskId}/duplicate`);
  };

  const handleDeleteSingleTask = task => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Remove Task"
          content="Are you sure that you want to delete this task?"
          onConfirm={() =>
            removeTask &&
            removeTask(get(task, '_id', ''), () => {
              toggleModal(false);
              toastMessage(MESSAGE.DELETE);
            })
          }
        />,
      );
  };

  const handleUpdateShareOwner = values => {
    updateTaskSharingStatus &&
      updateTaskSharingStatus(values, () => {
        toggleModal && toggleModal(false);
      });
  };

  const handleOpenFormPopup = (task, event) => {
    event.stopPropagation();

    toggleModal &&
      toggleModal(
        true,
        <ModalShareOwner
          isShowCancelButton={true}
          isHideCloseButton={true}
          toggleModal={toggleModal}
          workingItem={task}
          user={user}
          type={'update'}
          onSubmit={handleUpdateShareOwner}
        />,
      );
  };

  const renderActions = task => {
    const { owner } = task;
    const canDelete =
      isTeamAdmin(user) ||
      (get(owner, '_id', '') || owner) === get(user, '_id', '') ||
      get(task, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_NOOWNER;

    return (
      <DropDown triggerIcon={({ open }) => <MenuTrigger vertical active={!!open} itemId={get(task, '_id', '')} />}>
        <Option key="edit" onClick={() => handleOpenEditTask(task)}>
          <div className="icon">
            <img src={`${CDN_URL}/images/edit-white.svg`} alt="" />
          </div>
          <span>Edit</span>
        </Option>
        <Option key="duplicate" onClick={() => handleDuplicate(task)}>
          <div className="icon">
            <img src={`${CDN_URL}/images/clone.svg`} alt="" />
          </div>
          <span>Duplicate</span>
        </Option>
        <Option key="update-share" onClick={event => handleOpenFormPopup(task, event)}>
          <S.OptionIcon className="icon">
            <ShareIcon className="share-icon" />
          </S.OptionIcon>
          <span>Sharing settings</span>
        </Option>
        {canDelete && (
          <Option key="delete" onClick={() => handleDeleteSingleTask(task)}>
            <div className="icon">
              <img src={`${CDN_URL}/images/delete.svg`} alt="" />
            </div>
            <span>Delete</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const renderThumbnail = type => {
    switch (type) {
      case TASK_TYPES.GENERAL:
        return <GeneralIcon className="thumb-general"></GeneralIcon>;
      case TASK_TYPES.PROGRESS_PHOTO:
        return <ProgressPhotoIcon className="thumb-progress-photo"></ProgressPhotoIcon>;
      case TASK_TYPES.BODY_METRIC:
        return <BodyMetricIcon className="thumb-body-metric"></BodyMetricIcon>;
      case TASK_TYPES.FORM:
        return <FormIcon className="thumb-form"></FormIcon>;

      default:
        return null;
    }
  };

  const handleSelectTask = (task, event) => {
    event.stopPropagation();

    if (event.target && event.target.checked) {
      updateSelectedTask && updateSelectedTask([...selectedTasks, task._id]);
    } else {
      updateSelectedTask && updateSelectedTask(filter(selectedTasks, id => id !== task._id));
    }
  };

  const handleOpenEditTask = task => {
    const taskId = get(task, '_id', '');
    push && push(`/home/task-library/${taskId}`);
  };

  return (
    <Layout.TableBody>
      {map(list, task => {
        const { title, last_activity_at, type, owner } = task;
        const author =
          task.share !== TEAM_SHARE_NOOWNER && hasTeam
            ? find(user.team_member, item => item._id === (get(owner, '_id', '') || owner))
            : null;
        const share = task.share === TEAM_SHARE_SHARED || task.share === TEAM_SHARE_NOOWNER;
        const isChecked = selectedTasks.includes(get(task, '_id', ''));

        return (
          <Layout.TableRow key={get(task, '_id', '')} onClick={() => handleOpenEditTask(task)}>
            <S.TaskTableCell key="name" className="name">
              <S.TasksContainer>
                <S.CheckBoxContainer onClick={e => e.stopPropagation()} className={isChecked ? 'checked' : null}>
                  <div className="checkbox__thumbnail">
                    <Checkbox
                      className="checkbox__tasks"
                      checked={isChecked}
                      onChange={event => handleSelectTask(task, event)}
                    />
                  </div>
                  <S.ThumbnailContainer className="thumbnail-container thumbnail-task">
                    <S.Thumbnail>{renderThumbnail(type)}</S.Thumbnail>
                  </S.ThumbnailContainer>
                </S.CheckBoxContainer>
                <div className="text">{title}</div>
              </S.TasksContainer>
            </S.TaskTableCell>
            <Layout.TableCell key="most-recent" className="tasks-most-recent" alignCenter>
              <S.TaskBodyMostRecent>
                {last_activity_at ? timeSince(new Date(last_activity_at)) : '--'}
              </S.TaskBodyMostRecent>
            </Layout.TableCell>
            <Layout.TableCell key="actions">
              <Layout.Actions>
                {hasTeam ? (
                  <>
                    {author ? (
                      <S.TasksOwnerAvatar>
                        <OwnerAvatar
                          data={author}
                          tooltipId={`tooltip--owner-${get(task, '_id', '')}`}
                          size={25}
                          className="owner-avatar"
                        />
                      </S.TasksOwnerAvatar>
                    ) : null}
                    <SharingStatus
                      place="top"
                      share={share}
                      itemId={get(task, '_id', '')}
                      onClick={event => handleOpenFormPopup(task, event)}
                    />
                  </>
                ) : null}
                {renderActions(task)}
              </Layout.Actions>
            </Layout.TableCell>
          </Layout.TableRow>
        );
      })}
    </Layout.TableBody>
  );
}

const TableBody = React.memo(Component);

const mapState = state => {
  const {
    user,
    rootReducer: {
      tasksLibrary: { list, selectedTasks },
    },
  } = state;

  return { list, selectedTasks, user };
};

const mapDispatch = dispatch => {
  return {
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    removeTask: bindActionCreators(removeTask, dispatch),
    updateTaskSharingStatus: bindActionCreators(updateTaskSharingStatus, dispatch),
    updateSelectedTask: bindActionCreators(updateSelectedTask, dispatch),
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableBody);
