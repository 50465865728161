import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Image } from 'semantic-ui-react';
import * as S from './style';
import ChooseDate from './ChooseDate';
import Summary from './Summary';
import { toggleModal } from 'actions/modal';
import { changeStartDate } from 'redux/autoflowInterval/client/actions';
import { CDN_URL } from 'constants/commonData';

function Component(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const { clients, autoflowId, changeStartDate } = props;

  const onClose = () => {
    setIsSubmitting(false);
    props.toggleModal(false);
  };

  const onChooseStartDate = startDate => {
    setIsSubmitting(true);
    setStartDate(startDate);
    changeStartDate({
      autoflowId,
      clients: clients.map(client => client._id),
      ...startDate,
    }).then(() => {
      setStep(2);
      setIsSubmitting(false);
    });
  };

  return (
    <S.ModalWrapper
      open={true}
      className="autoflow-add-clients-modal"
      closeOnDimmerClick={false}
      closeIcon={
        <Button onClick={() => {}} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={onClose}
      trigger={
        <S.ActionButton className="autoflow-interval__add-clients-trigger">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M7 8.16667C8.93025 8.16667 10.5 6.59692 10.5 4.66667V3.5C10.5 1.56975 8.93025 0 7 0C5.06975 0 3.5 1.56975 3.5 3.5V4.66667C3.5 6.59692 5.06975 8.16667 7 8.16667Z"
              fill="#A1A1A5"
            />
            <path
              d="M12.25 11.6668C10.8389 11.6668 9.66233 10.6647 9.39225 9.3335H4.08333C1.83225 9.3335 0 11.1652 0 13.4168V14.0002H14V13.4168C14 12.6818 13.8022 11.9935 13.4604 11.3967C13.0912 11.5665 12.6834 11.6668 12.25 11.6668Z"
              fill="#A1A1A5"
            />
            <path
              d="M12.8333 7H11.6667V8.16667H10.5V9.33333H11.6667V10.5H12.8333V9.33333H14V8.16667H12.8333V7Z"
              fill="#A1A1A5"
            />
          </svg>
          <span>+ Clients</span>
        </S.ActionButton>
      }
    >
      {step === 1 && <ChooseDate onChooseStartDate={onChooseStartDate} isSubmitting={isSubmitting} />}
      {step === 2 && <Summary clients={clients} startDate={startDate} />}
    </S.ModalWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  return {
    autoflowId: workingAutoflow ? workingAutoflow._id : '',
  };
};

export default connect(mapStateToProps, { toggleModal, changeStartDate })(Component);
