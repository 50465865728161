import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open sans';
  color: #202353;
  font-style: normal;
  line-height: 150%;
`;

const widthHeader = {
  clients: {
    width: '277px',
  },
  progress: {
    width: '114px',
  },
  start_date: {
    width: '96px',
  },
  end_date: {
    width: '89px',
  },
};

export const Info = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  .client-avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    flex-shrink: 0;
  }
  .select-client-checkbox {
    display: none;
    .checkbox-icon {
      width: 20px;
      height: 20px;
      margin-top: -1px;
      margin-left: 1px;
      border-radius: 4px;
    }
    input:checked ~ .checkbox-icon {
      background-size: 14px;
    }
  }
  .disabled-hover-checkbox {
    display: block;
  }
  .client-avatar-wrapper {
    border: 1px solid #fcfcfc;
    border-radius: 50%;
  }
  .client-name {
    ${textBase}
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    &:hover {
      color: rgb(73, 70, 153);
    }
  }
`;

export const TableRow = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f2;
  padding: 8px 0;
  height: 56px;
  display: flex;
  align-items: center;
  &:hover {
    ${Info} {
      .client-avatar-wrapper {
        display: none;
      }
      .disabled-hover-avatar {
        display: block;
      }
      .select-client-checkbox {
        display: flex;
      }
    }
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      position: relative;
      z-index: 21;
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: transparent;
        z-index: 22;
      }
      &:hover {
        cursor: pointer;
        ${Info} {
          .client-avatar-wrapper {
            display: block;
          }
          .disabled-hover-avatar {
            display: none;
          }
          .select-client-checkbox {
            display: none;
          }
        }
      }
    `}
`;

export const TableCell = styled.div`
  ${textBase}
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  width: ${props => widthHeader[props.styleHeader].width || 'auto'};
  padding: 0;

  .current-week {
    font-weight: 600;
  }

  :first-child {
    padding-left: 5px;
  }

  ${({ isHeader }) =>
    isHeader &&
    css`
      ${textBase}
      font-weight: 600;
      font-size: 11px;
      line-height: 17px;
      color: #7b7e91;
    `}
`;
