// Libs
import React, { useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import Countdown from 'react-countdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { axiosInstanceWithoutToken } from 'configs/request';
import { getProfile } from 'actions/profile';
import { logoutWithoutRedirect } from 'actions/auth/login';

// Components
import { useCountdown } from 'hooks/useCountdown';
import { CHEAT_TIME_COUNT_DOWN, TIME_COUNT_DOWN } from 'components/VerifyEmail';

// Assets
import { ReactComponent as LogoBlackIcon } from 'assets/icons/VerifyEmail/logo_black.svg';
import { ReactComponent as VerifySuccess } from 'assets/icons/VerifyEmail/verify_success.svg';
import { ReactComponent as VerifyExpired } from 'assets/icons/VerifyEmail/verify_expired.svg';

// Styles
import * as S from './style';

const ERROR_CODE = {
  EXPIRED_TIME_24_HOURS: '14_16',
  EXPIRED_TIME_OLD_EMAIL: '14_2',
  ALREADY_VERIFY: '14_17',
};

const TIME_VERIFIED = 5000;

const VerifyAccount = props => {
  const { history, getProfile, user, logoutWithoutRedirect } = props || {};
  const timeCountDown = process.env.REACT_APP_CHEAT_TIME_COUNT_DOWN_VERIFY ? CHEAT_TIME_COUNT_DOWN : TIME_COUNT_DOWN;

  const { countdown, setCountdown } = useCountdown();

  const [statusCode, setStatusCode] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);

  const emailRef = useRef('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      setIsLoading(true);

      axiosInstanceWithoutToken
        .post('/api/authentication/trainer/verify-email-by-token', { token })
        .then(response => {
          const { success, email = '' } = get(response, 'data.data', {});
          if (success) {
            setStatusCode('');
            emailRef.current = email;
          }
        })
        .catch(error => {
          setStatusCode(get(error, 'response.data.errorCode'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      history.replace(`/login`);
    }
  }, []);

  const resendEmailVerification = (isClickResend = false) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      axiosInstanceWithoutToken
        .post('/api/authentication/trainer/send-email-verification-by-token', { token, is_click_resend: isClickResend })
        .then(response => {
          const { success, cooldown_resend: coolDownTime } = get(response, 'data.data', {});
          if (success || coolDownTime !== 0) {
            setCountdown(Math.ceil((coolDownTime || timeCountDown) / 1000));
          }
        })
        .catch(error => {});
    }
  };

  const handleResendEmail = isClickResend => {
    resendEmailVerification(isClickResend);
  };

  const handleRedirectToLogin = () => {
    setIsRedirect(current => !current);
    const email = emailRef.current;
    const path = email ? `/login?email=${encodeURIComponent(email)}` : '/login';
    const { force_send_verification_email_at, force_verify_email } = user || {};
    if (!force_send_verification_email_at && force_verify_email) {
      logoutWithoutRedirect();
    }
    history.replace(path);
  };

  const renderDescVerified = () => {
    return (
      <S.VerifiedDesc>
        You'll be redirected to sign in page shortly (
        <Countdown
          date={Date.now() + TIME_VERIFIED}
          intervalDelay={0}
          precision={3}
          renderer={({ seconds }) => <span>{seconds}s</span>}
          onComplete={handleRedirectToLogin}
          zeroPadTime={2}
        />
        ) or{' '}
        <span className="desc-link" onClick={handleRedirectToLogin}>
          click here
        </span>{' '}
        to proceed.
      </S.VerifiedDesc>
    );
  };

  const renderContentButtonResend = () => {
    return <span>{!!countdown ? <>Email resent ({countdown}s)</> : 'Click here to resend email'}</span>;
  };

  const { icon, title, description, isSuccess, contentButton, onClick, disabled, isHideActions } = useMemo(() => {
    switch (statusCode) {
      case ERROR_CODE.EXPIRED_TIME_24_HOURS:
        return {
          icon: <VerifyExpired className="verify-icon verify-expired-icon" />,
          title: 'The verification link has expired',
          description: (
            <S.VerifiedDesc>
              The link has expired because it's been more than <S.Bold>24 hours</S.Bold> since we <br />
              sent the link to you. Please click below to request another link.
            </S.VerifiedDesc>
          ),
          contentButton: renderContentButtonResend(),
          onClick: () => handleResendEmail(true),
          disabled: !!countdown,
        };
      case ERROR_CODE.EXPIRED_TIME_OLD_EMAIL:
        return {
          icon: <VerifyExpired className="verify-icon verify-expired-icon" />,
          title: 'The verification link has expired',
          description: null,
          contentButton: renderContentButtonResend(),
          onClick: () => handleResendEmail(true),
          disabled: !!countdown,
        };
      case ERROR_CODE.ALREADY_VERIFY:
        return {
          icon: <VerifySuccess className="verify-icon verify-success-icon" />,
          title: 'Your email has been verified!',
          description: renderDescVerified(),
          disabled: isRedirect,
          isHideActions: true,
        };
      default:
        return {
          icon: <VerifySuccess className="verify-icon verify-success-icon" />,
          title: 'Verification has been successfully completed',
          description: renderDescVerified(),
          isSuccess: true,
          disabled: isRedirect,
          isHideActions: true,
        };
    }
  }, [statusCode, countdown, isRedirect]);

  return (
    <S.Container>
      {!isLoading && (
        <>
          <S.Logo>
            <LogoBlackIcon className="logo-icon" />
          </S.Logo>
          <S.ContentWrapper>
            {icon}
            <S.VerifiedConfirmInfo isSuccess={isSuccess}>
              <S.VerifiedTitle>{title}</S.VerifiedTitle>
              {description}
              {!isHideActions && (
                <S.VerifiedActions>
                  <S.VerifiedButton onClick={onClick} disabled={disabled}>
                    {contentButton}
                  </S.VerifiedButton>
                </S.VerifiedActions>
              )}
            </S.VerifiedConfirmInfo>
          </S.ContentWrapper>
        </>
      )}
    </S.Container>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  getProfile: bindActionCreators(getProfile, dispatch),
  logoutWithoutRedirect: bindActionCreators(logoutWithoutRedirect, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
