import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import SkeletonDetail from 'assets/icons/MealPlans/Ingredient/skeleton_detail.png';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 685px;
    max-height: calc(100vh - 100px);
    border-radius: 10px !important;
    @media screen and (max-height: 820px) {
      max-height: 100%;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 0 8.5px 30px 20px;
  max-height: calc(100vh - 160px);
  overflow-y: scroll;
  margin-right: 15.5px;
  @media screen and (max-height: 820px) {
    height: calc(100% - 60px);
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 30px;
    margin-top: 85px;
  }
  .error-msg {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #ea314a;
    display: inline-block;
    margin-top: 15px;
    padding-left: 10px;
  }
`;

export const Action = styled.div`
  height: 60px;
  padding: 0 30px 0 20px !important;
  border-top: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-action {
    .add-multi-btn {
      ${baseText}
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 0px 15px 0px 30px;
      position: relative;
      height: 30px;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
      svg {
        position: absolute;
        left: 9px;
        width: 16px;
        height: 16px;
        path {
          fill: #7b7e91;
        }
      }
      &:hover {
        background: #f0f1ff;
        color: #5158cf;
        svg path {
          fill: #5158cf;
        }
      }
    }
  }
  .right-action {
    display: flex;
    gap: 10px;
    .action-btn {
      ${baseText}
      color: #202353;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 0px 14px;
      height: 30px;
      border: none;
      background: transparent;
      border: 1px solid #e1e1ea;
      cursor: pointer;
      &.add-btn {
        width: 93px;
        max-width: 93px;
        color: #fff;
        border: 1px solid transparent;
        background: rgba(81, 88, 207, 1);
      }
      &:hover {
        &.add-btn {
          color: #fff;
          border: 1px solid transparent;
          background: rgba(81, 88, 207, 0.9);
        }
      }
      &.close-btn {
        width: 102px;
        max-width: 102px;
        color: #fff;
        border: 1px solid transparent;
        background: rgba(81, 88, 207, 1);
      }
      &:hover {
        &.close-btn {
          color: #fff;
          border: 1px solid transparent;
          background: rgba(81, 88, 207, 0.9);
        }
      }
    }
    &.is-view-mode {
      margin-left: auto;
    }
  }
  .left-action,
  .right-action {
    .action-btn,
    .add-multi-btn {
      &:disabled {
        cursor: not-allowed;
        opacity: 0.9;
      }
    }
  }
`;

export const InputNameWrapper = styled.div`
  padding-bottom: 15px;
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 31px;
  z-index: 1;
`;

export const OptionWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 10px;
  justify-content: space-between;
`;
export const CategorySelect = styled.div`
  width: 355px;
  flex-shrink: 0;
`;

export const UnitSelect = styled.div`
  width: 100%;
`;

export const ViewDetail = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .label {
    ${baseText}
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    display: inline-block;
    margin-bottom: 3px;
    text-transform: uppercase;
  }
  .content {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
    border: 1px solid #e1e1ea;
    border-radius: 5px;
    padding: 7px 14px;
    background: #f8f8f8;
    flex: 1;
  }
`;

export const InfoIngredient = styled.div`
  padding: 30px 10px 10px;
  .title {
    ${baseText}
    font-size: 19px;
    line-height: 29px;
    color: #202353;
    display: flex;
    align-items: center;
    height: 36px;
  }
  .name {
    ${baseText}
    color: #7b7e91;
    font-size: 15px;
    line-height: 23px;
    margin-top: 3px;
  }
`;

export const LoadingWrapper = styled.div`
  background-image: url(${SkeletonDetail});
  background-repeat: no-repeat;
  background-position: center;
  height: 274px;
`;
