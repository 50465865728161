import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import './style.scss';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { Button } from 'shared/FormControl';

function ConfirmPublishChangesModal(props) {
  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      className="modal--studio-confirm-actions"
      onClose={props.onClose}
    >
      <Modal.Content>
        <Wrapper>
          <ModalLayout.Header>
            <span>Publish Changes</span>
          </ModalLayout.Header>
          <ModalLayout.Content>
            <div>
              All the updates in this edited version will be published live to clients using this Studio
              program.
            </div>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              purple
              onClick={() => {
                props.onConfirm();
                props.onClose();
              }}
            >
              Publish
            </Button>
          </ModalLayout.Actions>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
}

export default ConfirmPublishChangesModal;

const Wrapper = styled(ModalLayout.Wrapper)`
  ${ModalLayout.Header} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 15px;
  }
`;
