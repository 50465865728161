
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483100;
  top: 0;
  left: 0;

  .processingPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    z-index: 2147483100;
    padding: 45px;

    &__heading {
      margin-bottom: 30px;
    }

    &__content {
      margin-bottom: 20px;
      width: 360px;

      &--title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000

        margin-bottom: 15px;
      }

      &--description {
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        color: #323C47;
      }
    }
  }
`;

export const ProcessingIcon = styled.div`
  width: 4rem;
  height: 4rem;
  border: 3px solid #48C98C30;
  border-top-color: #48C98C;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  @keyframes rotation{
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }
`;