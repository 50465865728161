import React, { useLayoutEffect, useRef } from 'react';
import { isSafari } from 'react-device-detect';

import * as S from './style';

const OnboardingTourTooltip = props => {
  const { continuous, index, step, primaryProps, tooltipProps } = props || {};
  const { placement } = step || {};

  useLayoutEffect(() => {
    setTimeout(() => {
      if (isSafari && auraRef.current.style) {
        auraRef.current.style.webkitAnimationPlayState = 'running';
      }
    }, 100);
  }, []);

  const anchorRef = useRef();
  const auraRef = useRef();

  /**
   * prefixPosition determines the direction of the arrow
   * suffixPosition determines the horizontal position of the arrow
   */
  const [prefixPosition, suffixPosition] = (placement || '').split('-');

  const getWidthByIndex = index => {
    switch (index) {
      case 0:
        return { width: '324px' };
      case 2:
        return { width: '327px' };
      case 5:
        return { width: '329px' };
      default:
        return {};
    }
  };

  const getPositionBottomByIndex = index => {
    switch (index) {
      case 3:
        return '-23px';
      case 4:
        return '-22px';
      case 5:
        return '-24px';
      default:
        return '-19px';
    }
  };

  const styleTooltipBody = {
    position: 'relative',
    ...(suffixPosition === 'start' ? { left: '-5px' } : suffixPosition === 'end' ? { right: '-5px' } : {}),
    ...getWidthByIndex(index),
  };

  const styleAnchor = {
    ...(prefixPosition === 'top'
      ? { bottom: getPositionBottomByIndex(index) }
      : prefixPosition === 'bottom'
      ? { top: '-23px' }
      : {}),
    ...(suffixPosition === 'start' ? { left: `calc(35% - 2.5px)` } : suffixPosition === 'end' ? { left: '71%' } : {}),
  };

  const styleAura = {
    animationPlayState: isSafari ? 'paused' : 'running',
  };

  return (
    <S.TooltipBody
      {...tooltipProps}
      style={styleTooltipBody}
      prefixPosition={prefixPosition}
      suffixPosition={suffixPosition}
    >
      <S.Anchor ref={anchorRef} classNames="onboarding-tooltip__anchor" style={styleAnchor}>
        <S.Aura ref={auraRef} classNames="onboarding-tooltip__aura" style={styleAura} />
      </S.Anchor>
      {step.title && <S.TooltipTitle>{step.title}</S.TooltipTitle>}
      <S.TooltipContent>{step.content}</S.TooltipContent>
      <S.TooltipFooter>
        {continuous && (
          <S.Button {...primaryProps} title="">
            <S.FormattedMessage id="next">Got it</S.FormattedMessage>
          </S.Button>
        )}
      </S.TooltipFooter>
    </S.TooltipBody>
  );
};

export default OnboardingTourTooltip;
