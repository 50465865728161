import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { PLANS, TRIAL_MODE } from 'constants/commonData';
import { ReactComponent as UpgradeIcon } from 'assets/icons/upgrade-white-icon.svg';
import { ReactComponent as BroadcastIcon } from 'assets/icons/upgrade-broadcast-icon.svg';

import { InboxUpgradeButton, InboxUpgradeButtonWrapper } from './styles';

const GroupChatUpgradeButton = ({ isPermissionGroupChat, isPermissionBroadCast }) => {
  if (isPermissionGroupChat || isPermissionBroadCast) {
    return <></>;
  }
  return (
    <InboxUpgradeButtonWrapper data-tip data-for="upgrade-group-tooltip">
      <InboxUpgradeButton
        text={
          <>
            <UpgradeIcon className="upgrade-icon" />
            <BroadcastIcon className="broadcast-icon" />
          </>
        }
        planWillSelect={{
          tier: PLANS.studio.key,
          addOns: {
            resource_collections: false,
          },
        }}
      />
      <ReactTooltip className="app-tooltip group-chat-tooltip" id="upgrade-group-tooltip" effect="solid" place="bottom">
        <p>Upgrade to Studio Plan to use Broadcast Messages and Group Chat</p>
      </ReactTooltip>
    </InboxUpgradeButtonWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { pricing, permission },
  } = state;

  const isPermissionGroupChat =
    get(pricing.toJS(), 'teamData.tier', '') === PLANS.studio.key ||
    get(pricing.toJS(), 'teamData.trialMode', '') !== TRIAL_MODE.Expired;
  return {
    isPermissionGroupChat,
    isPermissionBroadCast: get(permission, 'broadcast_message', false),
  };
};

export default connect(mapStateToProps, null)(GroupChatUpgradeButton);
