import React, { useMemo } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { DropdownIndicator } from 'shared/Icons';
import _ from 'lodash';
import TIME_OPTIONS from 'constants/time-options';
import { ReminderContainer } from './style';
import { Toggle } from 'shared/FormControl';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';

const Options = TIME_OPTIONS.filter(item => item.value > '03:45');

export default ({
  scheduleReminder,
  reminderTime,
  onChangeReminderTime,
  onToggleScheduleReminder,
  isAddTaskFromLibrary,
  className,
  disabled = false,
  habit = false,
}) => {
  const value = useMemo(() => {
    return Options.find(item => item.label === reminderTime);
  }, [reminderTime]);

  return (
    <ReminderContainer className={className} isAddTaskFromLibrary={isAddTaskFromLibrary}>
      <Toggle
        disabled={disabled}
        checked={scheduleReminder}
        onChange={onToggleScheduleReminder}
        width={36}
        height={20}
        label={
          <div className="text-content">
            <span>Set Reminder</span>
            <TooltipIcon data-tip data-for="tooltip--task-reminder" />
            <ReactTooltip className="app-tooltip" id="tooltip--task-reminder" effect="solid">
              <p>A notification will be sent based on the client’s timezone</p>
            </ReactTooltip>
          </div>
        }
      />
      {scheduleReminder && (
        <Select
          options={Options}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          placeholder="-"
          onChange={onChangeReminderTime}
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          value={value}
          isSearchable={!habit}
          styles={{
            control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 110 }),
            menu: base => ({ ...base, marginTop: 2, marginBottom: 5, minWidth: 110 }),
          }}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.label}
          menuPlacement="top"
          maxMenuHeight="250"
          menuPosition="fixed"
        />
      )}
    </ReminderContainer>
  );
};
