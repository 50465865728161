import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { toggleConfirmModal } from 'actions/modal';
import {
  getAllClientsToSelect,
  getClientsByParams,
  getGroupsFilterByParams,
  openSecondToggle,
  sendBroadcastMessage,
  sendBroadcastMessageWorkoutSummary,
} from 'redux/broadcast-messages/actions';
import { push } from 'connected-react-router';

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  openSecondToggle: bindActionCreators(openSecondToggle, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getClientsByParams: bindActionCreators(getClientsByParams, dispatch),
  sendBroadcastMessage: bindActionCreators(sendBroadcastMessage, dispatch),
  getAllClientsToSelect: bindActionCreators(getAllClientsToSelect, dispatch),
  getGroupsFilterByParams: bindActionCreators(getGroupsFilterByParams, dispatch),
  sendBroadcastMessageWorkoutSummary: bindActionCreators(sendBroadcastMessageWorkoutSummary, dispatch),
});

const mapStateToProps = state => {
  const { user } = state;

  const { broadcastMessages } = state.rootReducer;
  const { isOpenSecondModal } = broadcastMessages;
  const {
    total = 0,
    clients = [],
    clientsLoading = false,
    isAllClientsLoading = false,
    broadcastSubmittedTime = '',
    broadcastWorkoutSummarySubmittedTime = '',
  } = broadcastMessages;

  return {
    user,
    total: total,
    clients: clients,
    clientsLoading: clientsLoading,
    isOpenSecondModal: isOpenSecondModal,
    submittedTime: broadcastSubmittedTime,
    isAllClientsLoading: isAllClientsLoading,
    broadcastWorkoutSummarySubmittedTime: broadcastWorkoutSummarySubmittedTime,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
