import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as DragIcon } from 'assets/icons/MealPlans/trailing_icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_gray_out.svg';

import { toggleConfirmModal, toggleModal } from 'actions/modal';
import {
  removeMetricInGroup,
  addMultipleMetricToGroup,
  selectGroupHasMetricBySearch,
  getGroupMetrics,
} from 'actions/groupMetric';
import { resetBodyMetricTarget } from 'actions/bodyMetric';

import ConfirmRemoveMetric from './ConfirmRemoveMetric';
import { formatValueUnit, formatDate, getStyleLockedDraggable, formatHeartRateValueUnit } from '../constant';
import { useMyContext } from '../context/GroupBodyMetricsContext';
import { convertMinToFromHourMin } from 'utils/commonFunction';
import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';

import * as S from '../style';

const ItemBodyMetric = props => {
  const {
    item = {},
    index,
    groupId,
    toggleConfirmModal,
    removeMetricInGroup,
    toggleModal,
    textSearch,
    isDefaultGroup = false,
    selectGroupHasMetricBySearch,
    groupIdBySearchMetric,
    resetBodyMetricTarget,
    getGroupMetrics,
    clientId = '',
  } = props;
  const { _id, name = '', unique_code, recent, recent_heart_rate } = item;
  const { date: heartRateDate = '', min: minHeartRate = 0, max: maxHeartRate = 0, unit: heartRateUnit = {} } =
    recent_heart_rate || {};
  const isSleep = unique_code === SPECIAL_METRICS_CODE.SLEEP;
  const isHeartRate = unique_code === SPECIAL_METRICS_CODE.HEART_RATE;
  const { date: recentDate, last_day, duration, value = 0, unit = {} } = recent || {};
  const { selectedMetricId, handleSelectBodyMetric, isLoadingMetric } = useMyContext();
  const deviceTimezone = moment.tz.guess();

  const isSelected = _id === selectedMetricId && (!textSearch || groupIdBySearchMetric === groupId);

  const handleSelectMetric = () => {
    if (item._id === selectedMetricId || isLoadingMetric) {
      return;
    }
    resetBodyMetricTarget();
    handleSelectBodyMetric(item);
    selectGroupHasMetricBySearch(groupId);
    getGroupMetrics(clientId);
  };

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const toggleConfirm = event => {
    event.stopPropagation();

    toggleConfirmModal(
      true,
      <ConfirmRemoveMetric onConfirm={handleRemoveMetricOutGroup} onClose={handleCloseModal} metricName={name} />,
    );
  };

  const handleRemoveMetricOutGroup = () => {
    removeMetricInGroup({
      groupId,
      metricId: _id,
    });
  };

  const renderValue = () => {
    if (!isEmpty(recent_heart_rate)) {
      return formatHeartRateValueUnit({ minHeartRate, maxHeartRate, heartRateUnit });
    }

    if (unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      if (!duration) return '—';

      const { hour, min } = convertMinToFromHourMin(duration);
      let label = '';
      const roundedMin = Math.round(min);
      if (min) label = `${roundedMin} m`;
      if (hour) label = `${hour} h ${label}`;
      return label;
    }

    return formatValueUnit({ value, unit });
  };

  const isEmptyValue = () => {
    if (isSleep) return !duration;
    if (isHeartRate) return !minHeartRate && !maxHeartRate;
    return !value;
  };

  return (
    <Draggable draggableId={`metric_${_id}_${groupId}`} index={index}>
      {(provided, snapshot) => (
        <S.ItemBodyMetricWrapper
          id={_id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames({
            selected: isSelected,
            dragging: snapshot.isDragging,
            loading: isLoadingMetric,
          })}
          onClick={handleSelectMetric}
          style={getStyleLockedDraggable(provided.draggableProps.style)}
        >
          <div className="drag-icon-wrapper">{!textSearch && <DragIcon className="drag-icon" />}</div>
          <div className="name">{name}</div>
          <div className={classNames('value', { empty: isEmptyValue() })}>{renderValue()}</div>
          <div className={classNames('last-update', { empty: !recentDate })}>
            {formatDate({ time: heartRateDate || last_day || recentDate, timezone: deviceTimezone })}
          </div>
          {!isDefaultGroup && (
            <div className="close-icon-wrapper" onClick={toggleConfirm} data-tip data-for={_id}>
              <CloseIcon className="close-icon" />
              <ReactTooltip
                className="app-tooltip remove-metric-group"
                id={_id}
                effect="solid"
                place="top"
                delayShow={300}
              >
                <p>Remove from this group</p>
              </ReactTooltip>
            </div>
          )}
        </S.ItemBodyMetricWrapper>
      )}
    </Draggable>
  );
};

const mapDispatchToProps = {
  removeMetricInGroup,
  addMultipleMetricToGroup,
  selectGroupHasMetricBySearch,
  toggleConfirmModal,
  toggleModal,
  resetBodyMetricTarget,
  getGroupMetrics,
};
const mapStateToProps = state => {
  const {
    groupMetric: { groupIdBySearchMetric = '' },
  } = state;

  return {
    groupIdBySearchMetric: groupIdBySearchMetric,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemBodyMetric);
