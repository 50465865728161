import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import { Radio, Icon } from 'semantic-ui-react';
import { Wrapper, Container, Trigger, Content, FormGroup, Footer } from './style';
import { RootCloseWrapper } from 'react-overlays';
import { changeClientFilter } from 'redux/segment/actions';

const Types = [
  { key: 'more', value: 1, label: 'More than' },
  { key: 'exactly', value: 2, label: 'Exactly' },
  { key: 'less', value: 3, label: 'Less than' },
];

function LastAssignedFilter({ changeClientFilter, filters }) {
  const [days, setDays] = useState(isNaN(parseInt(filters.last_assigned_workout)) ? '' : filters.last_assigned_workout);
  const [type, setType] = useState(filters.last_assigned_workout_type || 3);
  const [open, setOpen] = useState(false);

  const getTitle = () => {
    if (isNaN(parseInt(filters.last_assigned_workout))) {
      return '';
    }

    const obj = _.find(Types, item => item.value === (filters.last_assigned_workout_type || 1)) || { label: '' };
    const text = `${obj.label.toLowerCase()} ${filters.last_assigned_workout} ${
      filters.last_assigned_workout > 1 ? 'days' : 'day'
    }`;
    return text;
  };

  const title = getTitle();

  return (
    <RootCloseWrapper disabled={!open} event="click" onRootClose={() => setOpen(false)}>
      <Wrapper>
        <Trigger
          className={`filter__dropdown${open ? ' active' : ''}`}
          onClick={() => {
            if (open) {
              setOpen(!open);
            } else {
              setDays(filters.last_assigned_workout);
              setType(filters.last_assigned_workout_type);
              setOpen(true);
            }
          }}
        >
          <div className="filter__dropdown__title" data-tip data-for="last-assigned-filter-tooltip">
            <span>Last Assigned Workout&nbsp;</span>
            <span className="selected">{title}</span>
            <Icon className="chevron down" />
          </div>
        </Trigger>
        {open && (
          <Container>
            <Content>
              {_.map(Types, item => (
                <FormGroup key={item.key}>
                  <Radio
                    label={item.label}
                    name="type"
                    value={item.value}
                    checked={item.value === type}
                    onChange={(e, { value }) => setType(value)}
                  />
                  {item.value === type && (
                    <div className="input__container">
                      <input
                        value={days}
                        type="number"
                        step="1"
                        min="0"
                        onChange={event => setDays(event.target.value)}
                        autoFocus={true}
                      />
                      <span>days away</span>
                    </div>
                  )}
                </FormGroup>
              ))}
            </Content>
            <Footer>
              <button
                onClick={() => {
                  if (isNaN(parseInt(filters.last_assigned_workout))) {
                    setOpen(false);
                  } else {
                    changeClientFilter('last_assigned_workout', { days: '', type: 3 });
                    setOpen(false);
                  }
                }}
              >
                {isNaN(parseInt(filters.last_assigned_workout)) ? 'Cancel' : 'Clear'}
              </button>
              <button
                className="save"
                onClick={() => {
                  const parseDays = parseInt(days);
                  console.log(parseDays);
                  changeClientFilter('last_assigned_workout', { days: isNaN(parseDays) ? '' : parseDays, type });
                  setOpen(false);
                }}
              >
                Save
              </button>
            </Footer>
          </Container>
        )}
        {title && (
          <ReactTooltip
            className="app-tooltip"
            id={`last-assigned-filter-tooltip`}
            effect="solid"
            place={'top'}
            delayShow={500}
          >
            <span>{title}</span>
          </ReactTooltip>
        )}
      </Wrapper>
    </RootCloseWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    filters: rootReducer.segment.filters,
  };
};

const mapDispatchToProps = dispatch => ({
  changeClientFilter: bindActionCreators(changeClientFilter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastAssignedFilter);
