import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'shared/Pagination';
import { changeAutoflowClientQueryParam, getClientList } from 'redux/autoflow/client/actions';

const Toolbar = ({ page, per_page, total, changeAutoflowClientQueryParam, getClientList }) => (
  <Pagination
    page={page}
    perPage={per_page}
    totalPage={total}
    onPrev={() => {
      if (page > 1) {
        changeAutoflowClientQueryParam({ page: page - 1 });
        getClientList();
      }
    }}
    onNext={() => {
      if (page * per_page < total) {
        changeAutoflowClientQueryParam({ page: page + 1 });
        getClientList();
      }
    }}
  />
);

Toolbar.displayName = 'AutoflowListToolbar';

const mapStateToProps = (state) => {
  const { rootReducer } = state;
  const { client } = rootReducer.autoflow;
  const { query, total } = client;
  const { page, per_page } = query;

  return { page, total, per_page };
};

const mapDispatchToProps = (dispatch) => ({
  changeAutoflowClientQueryParam: bindActionCreators(changeAutoflowClientQueryParam, dispatch),
  getClientList: bindActionCreators(getClientList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
