// Libs
import React from 'react';
import { Image } from 'semantic-ui-react';

// Hoc
import withResponsive from 'hoc/withResponsive';

// Assets
import { ReactComponent as LogoIcon } from 'assets/icons/VerifyEmail/logo.svg';
import { ReactComponent as LogoBlackIcon } from 'assets/icons/VerifyEmail/logo_black.svg';

// Styles
import * as S from './style';

const LayoutVerifyEmail = ({ children }) => {
  const handleOpenNewTab = () => {
    const path = `https://everfit.io${window.location.search}`;
    window.open(path);
  };

  return (
    <S.Container>
      <S.LeftContentMobile>
        <LogoBlackIcon className="logo-icon" />
      </S.LeftContentMobile>
      <S.LeftContent>
        <Image src="/images/left-banner.jpg" />
        <S.Blur />
        <S.PortfolioWrapper>
          <S.Portfolio onClick={handleOpenNewTab}>
            <LogoIcon className="logo-icon" />
          </S.Portfolio>
          <S.Information>
            Creating and managing your clients is easy as 1-2-3. Easily invite the clients you want to connect through
            the platform and start training.
          </S.Information>
        </S.PortfolioWrapper>
      </S.LeftContent>
      <S.RightContent>{children}</S.RightContent>
    </S.Container>
  );
};

export default withResponsive(LayoutVerifyEmail);
