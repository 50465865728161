import React from 'react';
import _ from 'lodash';

import * as S from './style';

const caloriesTypes = [
  { value: 'protein', color: '#57B5EA', percentKey: 'protein_percent' },
  { value: 'carbs', color: '#65CC9C', percentKey: 'carbs_percent' },
  { value: 'fat', color: '#FFBE49', percentKey: 'fat_percent' },
];

const DistributionTable = ({ reportData }) => {
  const renderFatPercent = nutriData => {
    if (nutriData.value !== 'fat') {
      const nutriPercent = _.round(_.get(reportData.goals, [0, 'value', nutriData.percentKey]), 0);
      return nutriPercent ? `${nutriPercent}%` : '-';
    }
    const fatPercent = _.round(_.get(reportData.goals, [0, 'value', 'fat_percent']));
    if (!fatPercent) return '-';
    const proteinPercent = _.round(_.get(reportData.goals, [0, 'value', 'protein_percent']), 0) || 0;
    const carbsPercent = _.round(_.get(reportData.goals, [0, 'value', 'carbs_percent']), 0) || 0;
    const newFatPercent = 100 - proteinPercent - carbsPercent;
    return newFatPercent ? `${newFatPercent}%` : '-';
  };
  return (
    <S.TableWrapper>
      <thead>
        <th className="distribution-table__header">Macro</th>
        <th className="distribution-table__header">Actual</th>
        <th className="distribution-table__header">Goal</th>
      </thead>
      <tbody>
        {caloriesTypes.map(marco => (
          <tr>
            <td>
              <S.MacroItem className={marco.value}>{_.upperFirst(marco.value)}</S.MacroItem>
            </td>
            <td className="actual">
              {_.round(_.get(reportData.averageMeal, ['value', marco.percentKey]), 0)
                ? `${_.round(_.get(reportData.averageMeal, ['value', marco.percentKey]), 0)}%`
                : '-'}
            </td>
            <td className="distribution-table__td">{renderFatPercent(marco)}</td>
          </tr>
        ))}
      </tbody>
    </S.TableWrapper>
  );
};

export default DistributionTable;
