import styled, { css } from 'styled-components';
import Select from 'react-select';

export const DayOfWeeksWrapper = styled.div`
  .list-rest-day {
    border: 1px solid #DADFEA;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    background: #FFFFFF;
    border-radius: 5px;
    .rest-day-spec {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 1px;
      font-size: 10px;
      color: rgba(50, 60, 71, 0.62);
      cursor: pointer;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked + .rest-day-spec {
      background: rgb(231, 230, 255);
      border-radius: 3px;
      color: rgb(113, 102, 234);
    }
  }
`