import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #202353;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 405px;
    border-radius: 10px;
  }
`;

export const Header = styled.div`
  ${baseText}
  padding: 20px 20px 14px;
`;

export const Content = styled.div`
  border-bottom: 1px solid #f0f0f2;
  border-top: 1px solid #f0f0f2;
  padding: 20px;

  .text-editable__container {
    height: 212px !important;
    padding: 15px;
    overflow: hidden;

    .text-editable__input {
      ${baseText}
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #202353;

      :empty::before {
        ${baseText}
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #a3a3b5;
        opacity: 1;
      }
    }

    &:hover {
      overflow-y: scroll;
      padding: 15px 1px 15px 15px;
    }

    ::-webkit-scrollbar {
      width: 14px !important;
    }

    ::-webkit-scrollbar-thumb {
      background: #e1e1ea !important;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box !important;
      border-radius: 9999px;
    }
  }

  .text-editable__container:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    background-color: #f8f8f8;
    cursor: text;
  }
`;

export const Actions = styled.div`
  padding: 14px 20px 15px 15px !important;
  display: flex;
  align-items: center;
  justify-content: end;
  ${props =>
    props.hasContent &&
    css`
      justify-content: space-between;
    `}
`;

export const Delete = styled.button`
  ${baseText}
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  width: 48px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 5px;
  background: transparent;
  color: #202353;
  border: none;
  &:hover {
    background: #ffd8dd;
    color: #ea314a;
  }

  ${props =>
    props.disabled &&
    css`  
    opacity: 0.5;
    &:hover {
      background: transparent;
      color: #202353;
      cursor: not-allowed;
    `}
  }
`;

export const Update = styled.button`
  ${baseText}
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  height: 30px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: rgba(81, 88, 207, 1);
  color: #fff;
  border: none;
  &:hover {
    background: rgba(81, 88, 207, 0.9);
  }
  ${props =>
    props.disabled &&
    css`
      background: #eaeef1 !important;
      color: #7b7e91;
      cursor: not-allowed;
    `}
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        ${baseText}
        font-size: 21px;
        line-height: 32px;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      ${baseText}
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      padding: 25px 30px 25px;
    }
    .confirm-actions {
      display: flex;
      align-items: center;
      justify-content: end;
      > button {
        ${baseText}
        font-size: 12px;
        line-height: 18px;
        height: 30px;
      }
      .confirm-no-button {
        min-width: unset;
        width: 89px;
        border-radius: 5px;
        border: 1px solid #e1e1ea;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f7f9 transparent;
        color: #777 !important;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        box-shadow: none;
        width: 146px;
        border-radius: 5px;
        margin-left: 8px !important;
        border: none;
        color: #fff;
        background-color: #ea314a;
        display: flex;
        align-items: center;
        justify-content: center;
        :hover {
          background-color: rgba(234, 49, 74, 0.9);
        }
      }
    }
  }
`;
