import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #202353;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 410px;
    border-radius: 10px !important;
  }
  .banner-start-trial {
    padding: 0;
    width: 100%;
    height: 249px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    margin-top: 28px;
    text-align: center;
    padding: 0 40px !important;
    .title {
      ${baseText}
      margin-bottom: 10px;
      padding: 0 20px;
    }
    .desc {
      ${baseText}
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #7b7e91;
    }
  }
  .actions {
    background-color: #fff !important;
    border-top: none !important;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    padding: 0 20px 20px 20px !important;
    button {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      flex: 1;
      height: 36px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e1e1ea;
      background: #fff;
      cursor: pointer;
      &.confirm {
        color: #ffffff;
        border: none;
        background: rgba(81, 88, 207, 1);
      }
      &:hover {
        background: #f0f1ff;
        color: #5158cf;
        &.confirm {
          background: rgba(81, 88, 207, 0.9);
          color: #fff;
        }
      }
    }
  }
`;
