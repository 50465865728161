import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeQueryParams, resetQueryParams } from 'redux/tags/actions';
import MenuTags from './component';

const mapStateToProps = state => {
  const {
    rootReducer: { exerciseTags, workoutTags, programTags },
    isModalOpen,
  } = state;
  return {
    exerciseTags,
    workoutTags,
    programTags,
    isModalOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuTags);
