import styled from 'styled-components';

export const Trigger = styled.div`
  cursor: pointer;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;

  .ui.radio.checkbox{
    margin-bottom: 5px;
    font-family: 'Open Sans' !important;

    label {
      padding-left: 25px;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: normal;
    }
  }

  input {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: #000;
    padding: 7px 10px;
    width: 180px;
    outline: none !important;
    border-radius: 5px;
    border: solid 1px #d4d7d9;
    margin-right: 10px;

    :focus {
      border: solid 1px #5c5bbd;
    }
  }

  .input__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
  }
`;

export const Content = styled.div`
  padding: 20px 15px 5px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const Footer = styled.div`
  background-color: #fafbfc;
  padding: 12px 15px;
  text-align: right;
  border-top: 1px solid #dfe4e5;

  button {
    width: 110px;
    padding: 7px;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: #818da1;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #d6dae4;
    background-color: #fff;
    margin-left: 5px;

    &.save {
      box-shadow: 0 5px 14px 0 rgba(92, 91, 189, 0.37);
      background-color: #5c5bbd;
      color: #fff;
    }
  }
`

export const Container = styled.div`
  width: 310px;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #dfe4e5;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  z-index: 999;
`;


export const Wrapper = styled.div`
  position: relative;
`;