import React from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';
import classNames from 'classnames';
import TaskCreate from 'components/TaskCreate';
import TaskHabit from 'components/TaskHabit';
import TaskLibrary from 'components/TaskLibrary';
import ConfirmModal from 'shared/ConfirmModal';
import UpgradePath from 'shared/UpgradePath';
import ImageGotoBilling from 'layouts/home/Home/components/ImageGotoBilling';
import { CDN_URL, PLANS } from 'constants/commonData';
import { ReactComponent as TaskForm } from 'assets/icons/task_option_form.svg';
import { ReactComponent as TaskHabitIcon } from 'assets/icons/task_option_habit.svg';
import './styles.scss';

export default class TaskAddNew extends React.Component {
  componentDidMount() {
    this.props.fetchListTaskLibrary();
  }

  renderBodyMetric = () => {
    const { clientId, enableBodyMetric, isAddTaskFromLibrary, owner, bodyMetricsFromLibrary } = this.props;

    return (
      <div
        className="item"
        onClick={() => {
          this.handleCloseAction();
          if (enableBodyMetric) {
            this.props.toggleModal(
              true,
              <TaskCreate
                owner={owner}
                bodyMetricsFromLibrary={bodyMetricsFromLibrary}
                isAddTaskFromLibrary={isAddTaskFromLibrary}
                date={this.props.date}
                type="body_metric"
              />,
            );
          } else {
            this.props.toggleConfirmModal(
              true,
              <ConfirmModal
                title={'Confirm'}
                content={'Body Metrics has been turned off for the client. Do you want to turn on the feature?'}
                confirmButtonClass="general-button"
                onConfirm={() =>
                  this.props
                    .updateOneFeaturePreferences({ client: clientId, type: 'body_metric', state: true })
                    .then(() => {
                      this.props.toggleModal(true, <TaskCreate date={this.props.date} type="body_metric" />);
                    })
                }
                onDeny={() => {}}
                headerIcon={`${CDN_URL}/images/checkbox_greenwhite.svg`}
              />,
            );
          }
        }}
      >
        <Image src={`${CDN_URL}/images/task_option_body_metric.svg`} width={60} height={60} />
        <p>Body Metrics</p>
      </div>
    );
  };

  renderProgressPhoto = () => {
    const { clientId, enableProgressPhoto, date, isAddTaskFromLibrary, owner, bodyMetricsFromLibrary } = this.props;

    return (
      <div
        className="item"
        onClick={() => {
          this.handleCloseAction();
          if (enableProgressPhoto) {
            this.props.toggleModal(
              true,
              <TaskCreate
                owner={owner}
                bodyMetricsFromLibrary={bodyMetricsFromLibrary}
                isAddTaskFromLibrary={isAddTaskFromLibrary}
                date={date}
                type="progress_photo"
              />,
            );
          } else {
            this.props.toggleConfirmModal(
              true,
              <ConfirmModal
                title={'Confirm'}
                content={'Progress Photo has been turned off for the client. Do you want to turn on the feature?'}
                confirmButtonClass="general-button"
                onConfirm={() =>
                  this.props
                    .updateOneFeaturePreferences({ client: clientId, type: 'progress_photo', state: true })
                    .then(() => {
                      this.props.toggleModal(true, <TaskCreate date={date} type="progress_photo" />);
                    })
                }
                onDeny={() => {}}
                headerIcon={`${CDN_URL}/images/checkbox_greenwhite.svg`}
              />,
            );
          }
        }}
      >
        <Image src={`${CDN_URL}/images/task_option_progress_photo.svg`} width={60} height={60} />
        <p>Progress Photo</p>
      </div>
    );
  };

  openTaskLibraryModal = e => {
    e.preventDefault();
    this.props.toggleModal(true, <TaskLibrary date={this.props.date} from="client" />);
  };

  render() {
    const {
      toggleModal,
      date,
      isModalOpen,
      isFormPermission,
      isAddTaskFromLibrary,
      owner,
      bodyMetricsFromLibrary,
      isHabitPermission,
      listTaskLibrary,
      autoflowType,
    } = this.props;

    return (
      <Modal
        size={'tiny'}
        open={isModalOpen}
        onClose={this.handleCloseAction}
        className={classNames(
          'addnew-task',
          {
            expand:
              !!isFormPermission ||
              process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH ||
              !!isHabitPermission,
          },
          {
            habit:
              (!!isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) &&
              (!!isHabitPermission || process.env.REACT_APP_ENABLE_HABIT_UPGRADE_PATH) &&
              !isAddTaskFromLibrary,
          },
        )}
      >
        <Modal.Header>
          <Button onClick={this.handleCloseAction} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="form-header task-modal-header">
            <div>Create a task</div>
            {!isAddTaskFromLibrary && (
              <a href="#" onClick={this.openTaskLibraryModal}>
                Choose from Library
              </a>
            )}
          </div>
        </Modal.Header>
        <Modal.Content className="content-addnew-task">
          <div
            className={classNames('wapper-actions', {
              addOn: !!isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH,
            })}
          >
            <div
              onClick={() => {
                this.handleCloseAction();
                toggleModal(
                  true,
                  <TaskCreate
                    owner={owner}
                    bodyMetricsFromLibrary={bodyMetricsFromLibrary}
                    isAddTaskFromLibrary={isAddTaskFromLibrary}
                    date={date}
                    type="general"
                  />,
                );
              }}
              className="item"
            >
              <Image src={`${CDN_URL}/images/task_option_general.svg`} width={60} height={60} />
              <p>General</p>
            </div>
            {this.renderProgressPhoto()}
            {this.renderBodyMetric()}
            {(isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) && (
              <UpgradePath
                fallback={
                  <div className="item with-upgrade-path">
                    <ImageGotoBilling
                      imageTitle={'Upgrade your subscription to use Forms & Questionnaires'}
                      maxWidthContent={373}
                      planWillSelect={{ tier: PLANS.pro.key }}
                      notSendPrev={false}
                      isNotUsePathName={true}
                      onClick={() => {
                        this.handleCloseAction();
                      }}
                    />
                    <TaskForm />
                    <p>Form</p>
                  </div>
                }
                pathName="form_questionnaire"
              >
                <div
                  onClick={() => {
                    this.handleCloseAction();
                    toggleModal(
                      true,
                      <TaskCreate
                        owner={owner}
                        bodyMetricsFromLibrary={bodyMetricsFromLibrary}
                        isAddTaskFromLibrary={isAddTaskFromLibrary}
                        date={date}
                        type="form"
                      />,
                    );
                  }}
                  className="item"
                >
                  <TaskForm />
                  <p>Form</p>
                </div>
              </UpgradePath>
            )}
            {(isHabitPermission || process.env.REACT_APP_ENABLE_HABIT_UPGRADE_PATH) && !isAddTaskFromLibrary && (
              <UpgradePath
                fallback={
                  <div className="item with-upgrade-path">
                    <ImageGotoBilling
                      imageTitle={'Upgrade your subscription to use Habit'}
                      maxWidthContent={373}
                      planWillSelect={{ tier: PLANS.pro.key }}
                      notSendPrev={false}
                      isNotUsePathName={true}
                      onClick={() => {
                        this.handleCloseAction();
                      }}
                    />
                    <TaskHabitIcon />
                    <p>Habit</p>
                  </div>
                }
                pathName="habit"
              >
                <div
                  onClick={() => {
                    this.handleCloseAction();
                    toggleModal(true, <TaskHabit date={date} type="habit" autoflowType={autoflowType} />);
                  }}
                  className="item"
                >
                  <TaskHabitIcon />
                  <p>Habit</p>
                </div>
              </UpgradePath>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  handleCloseAction = () => {
    this.props.toggleModal(false);
  };
}
