import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { debounce, get } from 'lodash';
import { NewSearchInput } from 'shared/SearchInput';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import * as S from './style';
import { toggleModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import DialogAddNew from '../DialogAddNew';
import ButtonIcon from 'shared/ButtonIcon';
import OnDemandFilters from 'shared/OnDemandFilters';
import { ReactComponent as FilterIcon } from 'assets/icons/new_filter.svg';
import { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';

function OnDemandWorkoutsHeader({
  toggleModal,
  categoryLabels,
  addNewOnDemandWorkouts,
  location,
  changeQueryParams,
  query,
  groupLabels,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterOwner, setOwner] = useState(OWNER_TYPE.ALL);

  const [textSearch, setSearchText] = useState('');

  useEffect(() => {
    if (get(location, 'pathname', '').includes('create_new')) handleClick();
    return () => {
      handleClearSearch();
    };
  }, []);

  const handleClose = () => {
    toggleModal(false);
  };

  const handleUpdateFilters = (options, owner = OWNER_TYPE.ALL) => {
    setFilters(options);
    let others = [];
    Object.keys(options).forEach(key => {
      const item = {};
      const workingLabel = categoryLabels.find(it => it.unique_code === key);
      if (!['level', 'duration'].includes(workingLabel.unique_code)) {
        item.label = get(workingLabel, '_id', '');
        item.values = options[key];
        if (!!get(item, 'values', []).length) {
          others.push(item);
        }
      }
    });

    changeQueryParams({
      textSearch: textSearch,
      page: 1,
      level: options['level'],
      duration: options['duration'],
      labels: others,
      only_my_workout_library: owner === OWNER_TYPE.YOU,
    });
    handleShowFilters();
  };

  const handleClick = () => {
    toggleModal(
      true,
      <DialogAddNew
        onClose={handleClose}
        toggleModal={toggleModal}
        categoryLabels={categoryLabels}
        addNewOnDemandWorkouts={addNewOnDemandWorkouts}
      />,
    );
  };

  const handleSearch = (event, { value }) => {
    setSearchText(value);
    changeQueryParams({
      textSearch: value,
      page: 1,
      level: filters['level'],
      duration: filters['duration'],
      labels: filters['others'],
      only_my_workout_library: filterOwner === OWNER_TYPE.YOU,
    });
  };

  const handleClearSearch = () => {
    setSearchText('');
    handleSearch(null, { value: '' });
  };

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 });
    }
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const counterFilters = useMemo(() => {
    let counter = 0;
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'others') {
        counter = value.reduce(
          (accumulator, currentValue = {}) => (accumulator += (currentValue.values || []).length),
          counter,
        );
      } else {
        counter += value.length;
      }
    }
    if (filterOwner !== OWNER_TYPE.ALL) {
      counter += 1;
    }
    return counter;
  }, [filters, filterOwner]);

  return (
    <S.WorkoutsHeader>
      <S.SearchWrapper>
        <NewSearchInput
          placeholder="Search by keyword or name"
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          onKeyPress={handleKeyPress}
          defaultValue={query.textSearch}
        />
        <S.FilterButtonWrapper>
          <ButtonIcon
            text="Filter"
            width={106}
            height={30}
            className="filter-options"
            icon={<FilterIcon />}
            color="rgb(119, 119, 119)"
            textHoverColor="rgb(119, 119, 119)"
            bgHoverColor="rgb(248, 248, 248)"
            onClick={handleShowFilters}
            isShowFilter={showFilters}
          />
          {counterFilters > 0 && (
            <S.CounterFilters>
              <span>{counterFilters}</span>
            </S.CounterFilters>
          )}
        </S.FilterButtonWrapper>
        {showFilters && (
          <OnDemandFilters
            onUpdateFilters={handleUpdateFilters}
            groupLabels={groupLabels}
            currentFilters={filters}
            onCloseFilters={handleShowFilters}
            className="on-demand-filters"
            categoryLabels={categoryLabels}
            currentFilterOwner={filterOwner}
            onUpdateFilterOwner={setOwner}
          />
        )}
      </S.SearchWrapper>
      <S.Button
        text="Add New Workout"
        height={30}
        width={167}
        icon={<PlusIcon />}
        className="buttonClassName"
        onClick={handleClick}
      />
    </S.WorkoutsHeader>
  );
}

const mapState = state => ({
  isModalOpen: state.isModalOpen,
});
const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(OnDemandWorkoutsHeader);
