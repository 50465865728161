import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

import { CDN_URL } from 'constants/commonData';

import { RESOURCE_TYPES } from '.';

export const CreateNewStudioResourceModal = styled(Modal)`
  &.ui.modal {
    width: ${props => (props.type === RESOURCE_TYPES.FORM ? 480 : 440)}px;
  }

  .ui.modal > .header:not(.ui) {
    font-weight: 600;
  }

  .header {
    border-radius: 8px;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    color: #202353 !important;
    font-weight: 600 !important;

    .addStudioResource__backIcon {
      margin-right: 15px;
      cursor: pointer;
    }
  }

  .content {
    padding: 30px !important;
  }

  .addStudioResource {
    &__formWrapper {
      margin-bottom: 18px;
    }

    &__coverImageInput {
      max-width: 215px;
      margin-bottom: 30px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      .addStudioResource__addAnother {
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: #202353;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      button {
        padding-right: 40px;
        padding-left: 40px;
        :not(:disabled) {
          :hover {
            background-color: #5559ff;
          }
        }
      }
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;

export const ResourceTypeIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  top: 0;
  left: 30px;
  transform: translateY(-50%);

  &.youtube {
    background-color: #ffe7e7;
    background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
  }

  &.vimeo {
    background-color: #e8fbff;
    background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
  }

  &.instagram {
    background-color: #ffe2ec;
    background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
  }

  &.facebook {
    background-color: #e0f0ff;
    background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
  }

  &.twitter {
    background-color: #e8f6ff;
    background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
  }

  &.spotify {
    background-color: #e7ffef;
    background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
  }

  &.link {
    background-color: #f7f8fc;
    background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
  }

  &.document {
    background-color: #e8f4ff;
    background-image: url(${CDN_URL}/images/studio_resource_document_icon.svg);
  }

  &.form {
    background-color: #e8f4ff;
    background-image: url(${CDN_URL}/images/studio_resource_form_icon.svg);
  }
`;

export const OpenLink = styled.div`
  width: 42px;
  height: 100%;
  cursor: pointer;
  background-image: url(${CDN_URL}/images/open_link_icon.svg);
  background-repeat: no-repeat;
  background-position: center;
`;

export const ShareWrapper = styled.div`
  margin-bottom: 30px;
`;

export const OwnershipWrapper = styled.div`
  position: absolute;
  right: 28px;
  top: 30px;
`;
