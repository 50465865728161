import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

export const InboxAction = styled.div`
  .inbox-action {
    ${props =>
      props.readOnly &&
      css`
        opacity: 0.5;
        pointer-events: none;
        .evf-dropdown__menu {
          display: none;
        }
      `}
    .evf-dropdown__menu {
      min-width: 198px;
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: inherit;
    }
    &.room-item {
      .evf-dropdown__menu {
        min-width: 168px;
        left: 213px !important;
        background: #2d2e2d;
        border-radius: 8px;
      }
      .evf-dropdown__menu:after {
        content: '';
        position: absolute;
        top: -8px;
        right: 25px;
        border: 5px solid #fffdfd00;
        border-right-color: #2d2e2d;
        transform: rotate(90deg);

        ${props =>
          props.searching &&
          css`
            right: 22px;
          `}

        ${props =>
          props.isMute &&
          css`
            right: 38px;
          `}

          ${props =>
          props.isMute &&
          props.searching &&
          css`
            right: 35px;
          `}
      }
      .last_item.evf-dropdown__menu:after {
        top: 74px;
        transform: rotateZ(270deg);

        ${props =>
          props.isGroup &&
          css`
            top: 102px;
          `}
      }
    }

    .action {
      padding-right: 10px;
    }
    .action-name {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 460px;
    padding: 30px;
  }
  > .content {
    padding: 0 !important;
  }
  .confirm-content {
    padding: 0;
  }
  .confirm-footer {
    padding: 30px 0 0;
    button {
      padding: 5px 30px;
    }
  }
`;
export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin: 0;
  padding-bottom: 15px;
  display: flex;
  svg {
    margin-right: 10px;
  }
`;

export const ModalDesc = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #323c47;
`;

export const ModalButton = styled(Button)`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  min-width: auto;
`;

export const MuteConversationModal = styled(Modal)`
  &.ui.modal {
    border-radius: 5px;
    max-width: 480px;
    padding: 20px;

    .rootOptions {
      .common-select-container__control {
        min-height: 36px;
        padding-right: 12px;
        border-color: #dcdcde;

        span {
          font-weight: 600;
          color: #323c47;
        }

        &:hover,
        &--is-focused,
        &--menu-is-open {
          border-color: #dcdcde;
        }
      }

      .common-select-container__menu {
        padding: 0;
        margin-top: 5px;
        margin-bottom: 0;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      }

      .common-select-container__menu-list {
        padding: 0;
      }

      .common-select-container__option {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #202353;
        padding: 12px;

        &--is-selected {
          color: #5e5bca;
          font-weight: 700;
        }

        &--is-focused {
          color: #202353;
          font-weight: 400;
          background: rgba(94, 89, 255, 0.08);
        }

        &--is-focused.common-select-container__option--is-selected {
          color: #5e5bca;
          font-weight: 700;
        }
      }
    }

    .confirm-footer {
      padding: 30px 0 0;
      button {
        padding: 7px 49px 7px 54px;
        background-color: #5c5bbd;
        line-height: 18px;
        border: none;

        &:hover {
          background-color: #5659f7;
        }
      }
    }
  }
`;

export const MuteConversationModalDesc = styled.p`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  color: #6a778a;
  margin: 0;
  padding: 12px 0;
`;
