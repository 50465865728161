import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Cleave from 'cleave.js/react';
import styled from 'styled-components';

export default props => {
  const { value, disabled } = props;

  const [state, setState] = useState((!value || value === '0' ? '' : value).toUpperCase());
  const delimiter = '-';

  useEffect(() => {
    if ((!value || value === '0' ? '' : value).toUpperCase() !== state) {
      setState(value.toUpperCase());
    }
  }, [value]);

  const onChange = e => {
    if (disabled) {
      return;
    }

    const value = e.target.value;
    const splited = value.split(delimiter);
    _.forEach(splited, (v, index) => {
      if (v === ' ') {
        splited[index] = '0';
      }
    });
    const str = splited.join(delimiter).toUpperCase();
    setState(str);
    props.onChange(str);
  };

  let placeholder = 'X-X-X-X';

  if (state.length < 7) {
    if (state) {
      let tempoArray = state.split('-');

      for (let i = 0; i < 4; i++) {
        let value = tempoArray[i];

        if (!value) {
          tempoArray[i] = 'X';
        }
      }

      placeholder = tempoArray.join(delimiter);
    }
  } else {
    placeholder = '';
  }

  return (
    <TempoContainer className={classnames('superset__tempoContainer', { empty: !state.length })}>
      <Cleave
        value={state}
        className="tempo__input"
        options={{
          blocks: [1, 1, 1, 1],
          numericOnly: false,
          delimiter,
        }}
        onChange={onChange}
        disabled={disabled}
        placeholder="Tempo"
      />
      <input type="text" className="superset__tempoContainer__placeholder" value={placeholder} disabled />
    </TempoContainer>
  );
};

const TempoContainer = styled.div`
  position: relative;

  input {
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    outline: none;
    box-shadow: none;
    padding: 5px 12px;
    border-radius: 5px;
  }

  .tempo__input {
    border: 1px solid transparent;
    width: 86px;
    color: #202353;
    z-index: 2;
    background-color: transparent;
    position: relative;
    letter-spacing: 0.22em;

    ::placeholder {
      letter-spacing: normal;
      color: rgba(32, 35, 83, 0.4);
      font-weight: normal;
      font-size: 11px;
    }

    :hover,
    :focus {
      border-color: #5158cf;
      background-color: transparent;

      ~ .superset__tempoContainer__placeholder {
        background-color: #fff;
      }
    }

    :focus {
      ::placeholder {
        color: transparent;
      }

      ~ .superset__tempoContainer__placeholder {
        color: rgba(32, 35, 83, 0.4);
      }
    }
  }

  .superset__tempoContainer__placeholder {
    position: absolute;
    top: -1px;
    left: 1px;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: none;
    color: rgba(32, 35, 83, 0.4);
    background-color: #f6f7f9;
    letter-spacing: 0.22em;
  }

  &.empty {
    .tempo__input {
      :not(:focus) {
        text-align: center;
      }
    }

    .superset__tempoContainer__placeholder {
      color: transparent;
    }
  }
`;
