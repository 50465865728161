import styled from 'styled-components';

export const CustomToastMessage = styled.p`
  white-space: pre-wrap;
  margin-right: ${props => props.marginRight || '-80px'};
  color: #fff;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${props => props.style}
`;
