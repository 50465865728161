import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { remove } from 'lodash';
import { IconContainer, ContentContainer, ProfilePart } from './styles';
import { ReactComponent as AutoflowIcon } from 'assets/icons/autoflow.svg';

export default ({ autoflows }) => {
  if (!autoflows.length) {
    return null;
  }

  const sortedList = useMemo(() => {
    const list = autoflows.slice();
    const withSettings = remove(list, item => item && !item.isSettingTurnedOff);
    return [...withSettings, ...list];
  }, [autoflows]);

  return (
    <ProfilePart className="profile-part">
      <IconContainer>
        <AutoflowIcon />
      </IconContainer>
      <ContentContainer>
        <span>Autoflow:&nbsp;</span>
        {sortedList.map((item, index) => {
          if (!item || !item._id) return;
          return (
            <Link to={`/home/autoflow/${item._id}/training/calendar`}>
              <span>
                {item.name}
                {!item.isSettingTurnedOff && <span>&nbsp;(&#9733;)</span>}
              </span>
              {index < autoflows.length - 1 && `, `}
            </Link>
          );
        })}
      </ContentContainer>
    </ProfilePart>
  );
};
