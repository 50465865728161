import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMetricData, resetBodyMetricData, resetFilterTime } from 'actions/bodyMetric';
import { getExerciseHistoryList, updateOneFeaturePreferences } from 'actions/client';
import { getBodyMetricEntryHistory, getClientIntegration, getClientMetrics } from 'redux/client/client.actionCreators';
import ClientMetric from './component';
import { ONBOARDING_STEPS } from 'constants/commonData';
import { runOnboarding } from 'redux/onboarding/actions';
import { changeStepMetricSelected } from 'redux/client-step/actions';
import { addNewMetricGroup, getGroupMetrics, searchGroupMetricsByName } from 'actions/groupMetric';
import { toggleModal } from 'actions/modal';
import { push } from 'connected-react-router';

const mapStateToProps = state => {
  const { user, rootReducer, bodyMetric, groupMetric } = state;
  const {
    onboarding: { running, showTooltip },
    clientStep: { manualStepSelected, manualStepProgress },
    permission,
    exercise: { body_parts, modalities, muscleGroup, movementPattern },
  } = rootReducer;
  const { workingClientDetail } = rootReducer.client;
  const isBodyMetricFeaturing =
    workingClientDetail && workingClientDetail.feature_preferences
      ? workingClientDetail.feature_preferences.find(item => item.type === 'body_metric').state
      : null;

  return {
    user,
    selectedMetric: bodyMetric,
    isBodyMetricFeaturing,
    selectedClient: workingClientDetail,
    bodymetricTypes: (rootReducer.client.bodymetricTypes || []).filter(item => item.selected),
    body_parts,
    modalities,
    muscleGroup,
    movementPattern,
    showGuide: running === ONBOARDING_STEPS.CUSTOMIZE_METRIC && showTooltip,
    permission,
    manualStepSelected,
    manualStepProgress,
    groupMetric,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    updateOneFeaturePreferences: data => {
      dispatch(updateOneFeaturePreferences(data));
    },
    getClientIntegration: client => dispatch(getClientIntegration(client)),
    changeStepMetricSelected: metric => dispatch(changeStepMetricSelected(metric)),
    getBodyMetricEntryHistory: data => dispatch(getBodyMetricEntryHistory(data)),
    getExerciseHistoryList: data => dispatch(getExerciseHistoryList(data)),
    updateMetricData: bindActionCreators(updateMetricData, dispatch),
    resetBodyMetricData: bindActionCreators(resetBodyMetricData, dispatch),
    runOnboarding: bindActionCreators(runOnboarding, dispatch),
    getClientMetrics: bindActionCreators(getClientMetrics, dispatch),
    getGroupMetrics: bindActionCreators(getGroupMetrics, dispatch),
    addNewMetricGroup: bindActionCreators(addNewMetricGroup, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    push: bindActionCreators(push, dispatch),
    searchGroupMetricsByName: bindActionCreators(searchGroupMetricsByName, dispatch),
    resetFilterTime: bindActionCreators(resetFilterTime, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientMetric);
