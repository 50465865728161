// Libs
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

// Shared
import TextEditable from 'shared/TextEditable';

// Components
import RemoveWeek from 'components/MealPlanDetail/Parts/RemoveWeek';

import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_bold_circle.svg';

// Style
import * as S from './style';

const AddAsText = ({
  isEditMode,
  handleChangeIngredientText = () => {},
  hasError = false,
  value = '',
  handleClearTextIngredient = () => {},
  toggleConfirmModal,
  description = 'Ingredients entered as text will not be included in the shopping list feature on the Everfit app.',
}) => {
  const textEditableRef = useRef(null);

  const allowRemoveText = isEditMode && value;

  useEffect(() => {
    if (isEditMode) {
      textEditableRef.current && textEditableRef.current.focus();
    }
  }, []);

  const handleClear = () => {
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(
        true,
        <RemoveWeek
          confirmButtonTitle="Remove"
          title="Remove Ingredient Text"
          content="Are you sure that you want to remove this ingredient text?"
          onConfirm={handleConfirmClear}
          noBorder
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          shouldCloseAfterConfirm={false}
        />,
      );
  };

  const handleConfirmClear = () => {
    handleClearTextIngredient();
    handleChangeIngredientText('');
    toggleConfirmModal(false);
  };

  return (
    <S.Wrapper hasError={hasError} isViewMode={!isEditMode} allowRemoveText={allowRemoveText}>
      <div className={classNames('text-editable-wrapper', { 'read-only': !isEditMode })}>
        <TextEditable
          ref={textEditableRef}
          placeholder="Add ingredients, e.g. \A 1/2 Lemon \A 1 Banana"
          breakLine
          onChange={handleChangeIngredientText}
          value={value}
          readOnly={!isEditMode}
        />

        {allowRemoveText && <RemoveIcon className="close-icon" onClick={handleClear} />}
      </div>
      {isEditMode && (
        <div className="action-wrapper">
          <span className="desc-text">{description}</span>
        </div>
      )}
    </S.Wrapper>
  );
};

export default AddAsText;
