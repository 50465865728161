import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  border-top: 1px solid #f4f4f4;
  display: flex;
  justify-content: flex-start;
  margin-right: 15px;
  margin-left: 15px;

  button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    flex: 1 1 50%;
    background-color: #fff;
    cursor: pointer;
    padding: 9px 9px;

    svg {
      margin-right: 5px;
    }

    :hover {
      background-color: #f4f4f4;
    }

    &.like {
      &.active {
        color: #494699;
        svg {
          path {
            fill: #5158cf;
          }
        }
      }
    }
  }
`;
