import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Modal, Button, Image } from 'semantic-ui-react';
import _ from 'lodash';
import Slider from 'shared/Slider';
import { getPostDetail } from 'redux/foodJournal/actions';

import { CDN_URL } from 'constants/commonData';

import { Container, CommentsContainer } from './style';
import './style.scss';
import Comments from './Comments';

function PostDetail(props) {
  const { clientId, data, match, location, cloudfrontList } = props;
  const onlyCaption = !data || match.params.pId !== data._id || !data.pictures || !data.pictures.length;

  useEffect(() => {
    const { pId } = match.params;
    props.getPostDetail(pId).catch(() => {
      props.push(`/home/client/${clientId}/food-journal`);
    });
  }, []);

  const renderSlider = () => {
    if (onlyCaption) {
      return null;
    }

    const items = _.map(data.pictures || [], item => ({ src: item, title: item, description: item }));
    const parsed = queryString.parse(location.search);
    const index = parsed ? parseInt(parsed.startAt) : 0;

    return <Slider width={691} height={528} items={items} startAt={isNaN(index) ? 0 : index} />;
  };

  return (
    <Modal
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      className={`fj-detail-modal${onlyCaption ? ' only-caption' : ''}`}
      onClose={() => props.push(`/home/client/${clientId}/food-journal`)}
    >
      <Modal.Content>
        <Container>
          {renderSlider()}
          <CommentsContainer>{!!data && <Comments post={data} />}</CommentsContainer>
        </Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { foodJournal, client } = rootReducer;
  const { workingClientDetail } = client;
  const { viewing } = foodJournal;

  return {
    data: viewing,
    clientId: workingClientDetail ? workingClientDetail._id : null,
  };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  getPostDetail: bindActionCreators(getPostDetail, dispatch),
});

export default connect(mapState, mapDispatch)(PostDetail);
