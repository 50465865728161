import React from 'react';
import _ from 'lodash';
import { SortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Wrapper, Header, Body, Footer, ExerciseItem, ExerciseMenu, CustomTriggerIcon, CustomPopup } from './style';
import SearchExercise from 'shared/SearchExercise';
import { Button } from 'shared/FormControl';
import { RemoveIcon } from 'shared/Icons';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';
import { toast } from 'react-toastify';
import { CDN_URL } from 'constants/commonData';

const DragHandle = SortableHandle(() => (
  <div className="exercise-item__sort">
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="15" height="2" fill="#DCDCDE" />
      <rect y="4" width="15" height="2" fill="#DCDCDE" />
      <rect y="8" width="15" height="2" fill="#DCDCDE" />
    </svg>
  </div>
));

const SortableItem = SortableElement(({ data, itemIndex, onRemove, onUpdateExerciseLibrary, onEditExercise }) => {
  const onClickRemove = () => {
    onRemove(itemIndex);
  };

  const onSaveExercise = newData => {
    onUpdateExerciseLibrary({ oldExerciseId: data._id, newExercise: newData.exercise });
  };

  return (
    <ExerciseItem className="alt-exs__exercise-item" key={data._id}>
      <div className="index">{itemIndex + 1}.</div>
      <div className="info">
        <ExerciseMediaPreview
          size={{ width: 30, height: 30 }}
          indicatorSize={{ width: 10, height: 10 }}
          exercise={data}
          onSaveExercise={onSaveExercise}
          onEditExercise={onEditExercise}
        />
        <div className="title">{data.title}</div>
        <DragHandle />
      </div>
      <RemoveIcon onClick={onClickRemove} className="remove" size={15} />
    </ExerciseItem>
  );
});

const SortableList = SortableContainer(({ items, onClickRemoveIcon, onUpdateExerciseLibrary, onEditExercise }) => (
  <ExerciseMenu className="alt-exs__exercise-menu" onScroll={e => e.stopPropagation()}>
    {_.map(items, (item, index) => (
      <SortableItem
        key={`item-${item._id}`}
        itemIndex={index}
        index={index}
        data={item}
        onRemove={onClickRemoveIcon}
        onUpdateExerciseLibrary={onUpdateExerciseLibrary}
        onEditExercise={onEditExercise}
      />
    ))}
  </ExerciseMenu>
));

class AlternativeExercises extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: props.exercises || [],
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.containerRef.current) {
      this.executeScroll();
    }
  }

  executeScroll = () => this.containerRef.current.scrollIntoView();

  onSelectExercise = data => {
    const { exercises } = this.state;
    const { originalExercise, onSelectTempAltExercises } = this.props;

    if (data._id === originalExercise) {
      return toast.error('Cannot add same alternative exercise with the original exercise');
    }

    if (_.find(exercises, item => item._id === data._id)) {
      return false;
    }

    let newExercises = exercises.slice();
    onSelectTempAltExercises && onSelectTempAltExercises(newExercises);
    newExercises.push(data);

    this.setState({ exercises: newExercises });
  };

  removeExercise = index => {
    const { onSelectTempAltExercises } = this.props;
    let newList = this.state.exercises.slice();
    newList.splice(index, 1);
    onSelectTempAltExercises && onSelectTempAltExercises(newList);
    this.setState({ exercises: newList });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let exercises = this.state.exercises.slice();
    const removed = exercises.splice(oldIndex, 1);
    exercises.splice(newIndex, 0, removed[0]);
    this.setState({ exercises });
  };

  onUpdateExerciseLibrary = data => {
    const { oldExerciseId, newExercise } = data;

    if (this.state.exercises.length) {
      const exercises = this.state.exercises.slice();

      _.forEach(exercises, (ex, index) => {
        if (ex._id === oldExerciseId) {
          exercises[index] = { ...newExercise };
        }
      });

      this.setState({ exercises });
    }

    if (typeof this.props.onUpdateExerciseLibrary === 'function') {
      this.props.onUpdateExerciseLibrary(data);
    }
  };

  render() {
    const { exercises } = this.state;
    const { mostRecents, onCancel, onSave, newUIPopup = false } = this.props;

    const content = (
      <Wrapper className="alt-exs" ref={this.containerRef}>
        <Header className="alt-exs__header">
          <img src={`${CDN_URL}/images/split.svg`} width={12} alt="" />
          <div>Edit Alternate Exercises</div>
        </Header>
        <Body className="alt-exs__body">
          <SearchExercise
            defaultOptions={mostRecents}
            defaultValue={null}
            onSelect={this.onSelectExercise}
            onlySelect={true}
            disableCreateNew={true}
            menuIsAutoOpen={!exercises.length}
            excludes={_.map(exercises, '_id')}
          />
          <SortableList
            helperClass="sortableHelper"
            items={exercises}
            onSortEnd={this.onSortEnd}
            onClickRemoveIcon={this.removeExercise}
            onUpdateExerciseLibrary={this.onUpdateExerciseLibrary}
            useDragHandle
            onEditExercise={this.props.onEditExercise}
          />
        </Body>
        <Footer className="alt-exs__footer">
          <div className="remove-all" onClick={() => this.setState({ exercises: [] })}>
            Remove All
          </div>
          <div className="actions">
            <Button onClick={onCancel} className="cancel">
              Cancel
            </Button>
            <Button onClick={() => onSave(exercises)} className="save">
              Save
            </Button>
          </div>
        </Footer>
      </Wrapper>
    );

    if (newUIPopup) {
      return (
        <CustomPopup trigger={<CustomTriggerIcon />} open position="bottom right" keepInViewPort={true} basic={true}>
          {content}
        </CustomPopup>
      );
    }

    return content;
  }
}

export default AlternativeExercises;
