import styled, { css } from 'styled-components';

export const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  text-align: center;
  padding: 20px;
`;

export const WeekInfo = styled.div`
  margin-bottom: 25px;
  padding: 0 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    :hover {
      cursor: pointer;
    }
  }
`;

export const Day = styled.div`
  width: 33px;
  height: 33px;
  text-align: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 33px;
  text-align: center;
  color: #202353;
  border-radius: 3px;
  background: transparent;
  border: 1px solid transparent;
`;

export const DayContainer = styled.div`
  border: 4px solid transparent;
  border-top: 0;
  border-bottom: none;
  height: 33px;

  &.day {
    &--disabled {
      border-left-color: #fff;

      ${Day} {
        color: #a7a7b6;
        font-weight: normal;

        :hover {
          cursor: not-allowed;
          border-color: transparent;
        }
      }
    }

    &--active {
      ${Day} {
        background-color: #5158cf;
        color: #fff;
        font-weight: 600;
      }
    }

    :hover:not(.day--disabled) {
      ${Day} {
        cursor: pointer;
        border-color: #5158cf;
        background-color: #fff !important;
        color: #202353;
      }
    }

    &--inRange {
      background-color: #f4f4f4;

      &.day--active:not(.day--disabled) {
        ${Day} {
          background-color: transparent;
          color: #202353;
        }
      }
    }
  }
`;

export const DayGrid = styled.div`
  padding: 0 26px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 8px;

  ${props =>
    props.checkInRange &&
    css`
      ${DayContainer} {
        :hover:not(.day--disabled),
        &.day--active:not(.day--disabled) {
          border-left-color: #f4f4f4;
        }
      }
    `}
`;

export const Content = styled.div`
  padding-bottom: 30px;
  min-height: 230px;
`;

export const Footer = styled.div`
  padding: 10px 26px;
  text-align: right;
  border-top: 1px solid #eceef4;

  button {
    width: unset !important;
    min-width: unset !important;
    height: unset !important;
    margin-left: 10px;

    &.save {
      padding: 4px 34px;
    }
  }
`;

export const PopupContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eceef4;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: fixed;
  display: none;
  min-width: 340px;
  z-index: 9999;
`;

export const Trigger = styled.div`
  background: #fafbfc;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  padding: 11px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 130px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  color: #000000;

  :hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  &.open {
    ${PopupContainer} {
      display: block;
    }
  }
`;
