import React, { useMemo } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import * as S from './style';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { duplicateStudioCollection, removeStudioCollection, updateShareStatus } from 'redux/studio-collection/actions';
import { checkAssetAssignedToProduct } from 'utils/commonFunction';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { ASSET_TYPE } from 'components/Product/constants';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';
import { ShareSettingModal } from 'shared/AssetsShareSetting';
import { isTeamAdmin } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

function CollectionDropdownOptions(props) {
  const { studioCollection = {}, payment, updateShareStatus, user = {}, toggleModal, onboarding_flow } = props;
  const studioCollectionId = studioCollection._id;
  const author = typeof studioCollection.author_info === 'object' ? studioCollection.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = studioCollection.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

  const onRemoveClick = async e => {
    const isAssignedToProduct = payment
      ? await checkAssetAssignedToProduct(ASSET_TYPE.RESOURCE, studioCollection._id)
      : false;
    const isAssignedOnboarding = onboarding_flow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.RESOURCE_COLLECTION, studioCollection._id, true)
      : false;

    if (payment && isAssignedToProduct) {
      props.toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          assetType={ASSET_TYPE.RESOURCE}
          onConfirm={() => {
            props.toggleConfirmModal(
              true,
              <ConfirmModal
                headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
                newStyle
                confirmButtonTitle="Remove"
                title="Remove Collection?"
                content="This will remove the collection from your workspace, but you can still use the resources in other collections. Would you like to continue?"
                onConfirm={() => props.removeStudioCollection(studioCollection)}
              />,
            );
          }}
        />,
      );
    } else if (onboarding_flow && isAssignedOnboarding) {
      props.toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isExtraLarge
          isCloseAfterConfirm
          title="Resource Collection is assigned to an Onboarding Flow"
          content="This resource collection is in at least 1 onboarding flow. If the resource collection is removed, it will be removed from all onboarding flows as well."
          assetType={ASSET_TYPE.STUDIO}
          onConfirm={() => props.removeStudioCollection(studioCollection)}
        />,
      );
    } else {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          newStyle
          confirmButtonTitle="Remove"
          title="Remove Collection?"
          content="This will remove the collection from your workspace, but you can still use the resources in other collections. Would you like to continue?"
          onConfirm={() => props.removeStudioCollection(studioCollection)}
        />,
      );
    }
  };

  const onEdit = () => {
    props.push(`/home/studio-collection/${props.studioCollection._id}`);
  };

  const onDuplicate = () => {
    props.duplicateStudioCollection(studioCollection);
  };

  const handleSaveShare = ({ params } = {}) => {
    typeof toggleModal === 'function' && toggleModal(false);
    if (!params || !studioCollectionId) return;
    typeof updateShareStatus === 'function' && updateShareStatus({ params, studioCollectionId });
  };

  const onShare = () => {
    props.toggleModal(
      true,
      <ShareSettingModal
        user={user}
        owner={author}
        shareStatus={studioCollection.share}
        closeModal={onCloseModal}
        onSave={handleSaveShare}
      />,
    );
  };

  const onCloseModal = () => {
    props.toggleModal(false);
  };

  return (
    <S.DropdownWrapper className={props.className}>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="resource-collection-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="resource-collection-item-options-tooltip" />
          </div>
        )}
      >
        {isCreatorOrOwnerOrAdmin && (
          <Option key="edit" onClick={onEdit}>
            <img className="icon edit" src={`${CDN_URL}/images/edit-white.svg`} alt="" />
            <span className="text">Edit</span>
          </Option>
        )}
        <Option key="clone" onClick={onDuplicate}>
          <img className="icon clone" src={`${CDN_URL}/images/clone.svg`} alt="" />
          <span className="text">Duplicate</span>
        </Option>
        {isCreatorOrOwnerOrAdmin && (
          <Option key="share" onClick={onShare}>
            <ShareIcon className="icon share" />
            <span className="text">Sharing settings</span>
          </Option>
        )}
        {isCreatorOrOwnerOrAdmin && (
          <Option key="delete" onClick={onRemoveClick}>
            <img className="icon remove" src={`${CDN_URL}/images/delete.svg`} alt="" />
            <span className="text">Remove</span>
          </Option>
        )}
      </DropDown>
    </S.DropdownWrapper>
  );
}

const mapStateToProps = state => {
  const { user, rootReducer } = state || {};
  const { permission } = rootReducer || {};
  const { payment, onboarding_flow } = permission || {};

  return { user, payment, onboarding_flow };
};

const mapDispatchToProps = {
  push,
  toggleConfirmModal,
  toggleModal,
  duplicateStudioCollection,
  removeStudioCollection,
  updateShareStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDropdownOptions);
