import React from 'react';
import styled, { css } from 'styled-components';
import { components } from 'react-select';
import SharingStatus from './SharingStatus';
import MenuTrigger from './MenuTrigger';
import { CDN_URL } from 'constants/commonData';

export default styled.div`
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;

  ${props =>
    props.name === 'delete' &&
    css`
      width: ${props => `${props.width || 11}px`};
      height: ${props => `${props.height || 11}px`};
      background-image: url(${CDN_URL}/images/new_delete.svg);

      :hover {
        background-image: url(${CDN_URL}/images/new_delete_hover.svg);
      }
    `}

  ${props =>
    props.name === 'add' &&
    css`
      width: ${props => `${props.width || 42}px`};
      height: ${props => `${props.height || 42}px`};
      background-image: url(${CDN_URL}/images/plus_purple.svg);

      :hover {
        background-image: url(${CDN_URL}/images/plus_purple_hover.svg);
      }
    `}
`;

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <img src={`${CDN_URL}/images/arrow_down_black.svg`} alt="" />
  </components.DropdownIndicator>
);

const MediaRemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid #f6f7fb;
  border-radius: 50%;
  background: #f6f7fb url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: -8px;
  top: -8px;
`;

export const CloseIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f6f7fb url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  z-index: 100;
  right: -6px;
  top: -6px;

  :hover {
    cursor: pointer;
  }
`;

export const CalendarAssignWorkoutIcon = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  background: transparent url(${CDN_URL}/images/plus_circle_fill_none.svg) no-repeat;
  background-size: contain;
  background-position: center;

  :hover {
    cursor: pointer;
    background-image: url(${CDN_URL}/images/plus_circle_white.svg);
  }
`;

const RemoveIcon = styled.div`
  width: ${props => `${props.size || 12}px`};
  height: ${props => `${props.size || 12}px`};
  border-radius: 50%;
  background: #020202 url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: ${props => `-${props.size / 2}px`};
  top: ${props => `-${props.size / 2}px`};

  :hover {
    cursor: pointer;
  }
`;

export { SharingStatus, DropdownIndicator, MenuTrigger, MediaRemoveIcon, RemoveIcon };
