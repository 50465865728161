import React from 'react';
import { debounce } from 'lodash';
import { NewSearchInput } from 'shared/SearchInput';
import { requestLogInfo } from 'utils/commonFunction';

const SearchInput = ({ defaultValue, onChange, placeholder }) => {
  const searchExercise = (event, { value }) => {
    requestLogInfo('Search exercises:', { search_key: value });
    onChange(value.toLowerCase().trim());
  };

  const searchDebounce = debounce(searchExercise, 500);
  return (
    <NewSearchInput
      defaultValue={defaultValue}
      onChange={searchDebounce}
      onClearSearch={() => searchExercise(null, { value: '' })}
      placeholder={placeholder || 'Search for your Exercise'}
    />
  );
};

export default SearchInput;
