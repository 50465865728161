import styled, { css } from 'styled-components';
import * as SUpload from '../../Upload/style';
import { FormGroup as FG, Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';
import TransparentBackground from 'assets/icons/welcome-text-transparent.svg';

export const FormGroup = styled(FG)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;

  &.clientMobileApp {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1291px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Tip = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #868c9a;
  padding-left: 20px;
  background: transparent url(${CDN_URL}/images/new_info.svg) no-repeat;
  background-size: 14px 14px;
  background-position: 0 2px;
  margin-top: 10px;

  .hightlight {
    font-weight: 600;
  }
`;

export const DragboxItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    transform: translate(2px, 2px);
  }

  ${props => props.withLeftMargin && `margin-left: 50px;`}
  ${props =>
    props.withMarginTopSmallScreen &&
    `
    @media screen and (max-width: 1291px) {
      margin-left: 0px !important;
      margin-top: 30px;
    }
  `}
`;

export const DragboxElement = styled.div`
  display: flex;

  ${SUpload.Wrapper} {
    width: 282px;
  }

  ${SUpload.Icon} {
    margin-right: 10px;
  }

  ${SUpload.Text} {
    width: calc(100% - 120px);
  }

  ${SUpload.Thumbnail} {
    background-size: cover;
  }
`;

export const Note = styled.div`
  display: table;
  width: 282px;
  height: inherit;
  margin-left: 15px;
  padding: 0 !important;

  ${Tip} {
    display: table-cell;
    height: inherit;
    margin: 0;
    vertical-align: middle;
    background-position: left calc(50% - 8px);
  }
`;

export const SaveButton = styled(Button)`
  background-color: #5c5bbd;
  color: #fff;
  box-sizing: border-box;
  border-radius: 5px;

  &.save {
    min-width: 110px;
  }

  :hover {
    background-color: #5559ff;
  }

  &.disable {
    background-color: #c4c4c4;
    color: #fff;
    pointer-events: none;
  }
`;

export const ThemeContent = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.value === '#ffffff'
      ? `
    border: 1px solid #DADFEA; 
    background-color: ${props.value}
    `
      : props.value === 'none'
      ? `
    background: url(${TransparentBackground});
    background-size: contain;
    `
      : `
    background-color: ${props.value}
    `}
`;

export const ThemeItem = styled.div`
  margin-left: ${props => (props.index === 0 ? '0px' : '12px')};
  background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
  border: 1px solid #dadfea;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${props =>
    props.customTheme &&
    `
  height: 44px;
  margin-top: 23px;
  margin-left: 20px;
  visibility: hidden !important;
  &.active {
    visibility: visible !important;
  }
  `}

  :hover {
    border-color: #7470ef;
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
      border-color: #7470ef;
    `}

  ${props =>
    props.addNew &&
    css`
      width: 72px;
      height: 72px;

      .add-new-theme-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        display: none;
      }

      :hover {
        border-color: transparent;

        .add-new-theme-hover {
          display: block;
        }
      }
    `}

  .__react_component_tooltip.app-tooltip-white {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #323c47 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-color: #e8e8e8;
    position: absolute !important;
    top: unset !important;
    left: 50% !important;
    bottom: 60px !important;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;

export const WelcomeColorPickerContainer = styled.div`
  margin-left: 54px;
  ${props =>
    props.hiddenWhenSmallScreen &&
    `
  @media screen and (max-width: 1291px) {
    display: none;
  }`}
  ${props =>
    props.hiddenWhenLargeScreen &&
    `@media screen and (min-width: 1292px) {
    display: none;
  }`}
  label {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1291px) {
    ${props => !props.withLeftMargin && 'margin-left: 0px !important;'}
    ${props => (props.noMarginTop ? 'margin-top: 0px;' : 'margin-top: 30px;')}
  }
`;
