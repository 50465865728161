import { ACCEPT_IMAGES, ACCEPT_VIDEOS, ACCEPT_MPEG4_VIDEOS, ACCEPTED_DOCUMENTS } from 'constants/commonData';

export const ATTACHMENT_TYPES = {
  MEDIA: 1,
  DOCUMENT: 2,
  LINK: 3,
};

export const VALIDATIONS = {
  VIDEO: {
    maxSize: 50000000,
    extensions: ['avi', 'flv', 'mp4', 'mov', '3gp', 'm4v', 'm4a', 'mp3'],
    accepts: `${ACCEPT_VIDEOS}, ${ACCEPT_MPEG4_VIDEOS}, .mp3`,
    errorMessage: 'Video size limit is 50MB. Please compress your video first or attach a video link instead.',
    type: ATTACHMENT_TYPES.MEDIA,
  },
  IMAGE: {
    maxSize: 50000000,
    extensions: ['png', 'jpeg', 'jpg'],
    accepts: ACCEPT_IMAGES,
    errorMessage: 'Image size limit is 50MB.',
    type: ATTACHMENT_TYPES.MEDIA,
  },
  DOCUMENT: {
    maxSize: 50000000,
    extensions: ['xls', 'doc', 'pdf', 'xlsx', 'docx'],
    accepts: ACCEPTED_DOCUMENTS,
    errorMessage: 'File size limit is 50MB. You can use Dropbox or Google Drive and attach the file link instead.',
    type: ATTACHMENT_TYPES.DOCUMENT,
  },
  DOCUMENT_25MB: {
    maxSize: 25000000,
    extensions: ['xls', 'doc', 'pdf', 'xlsx', 'docx'],
    accepts: ACCEPTED_DOCUMENTS,
    errorMessage: 'File size limit is 25MB. You can use Dropbox or Google Drive and attach the file link instead.',
    type: ATTACHMENT_TYPES.DOCUMENT,
  },
  VIDEO_300MB: {
    maxSize: 300000000,
    extensions: ['avi', 'flv', 'mp4', 'mov', '3gp', 'm4v', 'm4a', 'mp3'],
    accepts: `${ACCEPT_VIDEOS}, ${ACCEPT_MPEG4_VIDEOS}, .mp3`,
    errorMessage: 'Video size limit is 300MB. Please compress your video first or attach a video link instead.',
    type: ATTACHMENT_TYPES.MEDIA,
  },
  IMAGE_25MB: {
    maxSize: 25000000,
    extensions: ['png', 'jpeg', 'jpg'],
    accepts: ACCEPT_IMAGES,
    errorMessage: 'Image size limit is 25MB.',
    type: ATTACHMENT_TYPES.MEDIA,
  },
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  LINK: 'link',
};

export const DEFAULT_MEDIA_LINK_DATA = {
  name: '',
  type: MEDIA_TYPES.LINK,
  url: '',
  thumbnail_url: '',
  invalidLink: false,
};
