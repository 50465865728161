import React from 'react';
import { connect } from 'react-redux';
import { Radio } from 'semantic-ui-react';
// import icons
import { ReactComponent as RestDayIcon } from 'assets/icons/add-rest-day.svg';
import AddrestDaySectionStyles from './styles';

const listDay = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const AddrestDaySection = ({ restDayType, setRestDayType, listRestDay, setListRestDay, children }) => {
  const onSelectRestDay = e => {
    if (e.target.checked) setListRestDay([...listRestDay, e.target.name]);
    else setListRestDay(listRestDay.filter(day => day != e.target.name));
  };

  const onCancel = () => {
    setRestDayType(undefined);
  };

  return (
    <AddrestDaySectionStyles>
      <div className="add-rest-day-section">
        <div className="add-rest-day-title">
          <div
            className={'add-rest-day-btn'}
            onClick={() => {
              if (restDayType) setRestDayType(undefined);
              else setRestDayType('no_workout');
            }}
          >
            <RestDayIcon />
            <span>Add Rest day macros</span>
          </div>
          {restDayType && (
            <div className="cancel-btn" onClick={onCancel}>
              Cancel
            </div>
          )}
        </div>
        {restDayType && (
          <div className="rest-day-section">
            <div className="select-rest-day-type">
              <div className="rest-day-option">
                <Radio
                  label="Days with no workout"
                  name="restDayType"
                  value="no_workout"
                  checked={restDayType === 'no_workout'}
                  onChange={() => setRestDayType('no_workout')}
                />
              </div>
              <div className="rest-day-option">
                <Radio
                  label="Specific days of the week"
                  name="restDayType"
                  value="specific_day"
                  checked={restDayType !== 'no_workout'}
                  onChange={() => setRestDayType('specific_day')}
                />
                {restDayType !== 'no_workout' && (
                  <div className="list-rest-day">
                    {listDay.map((day, index) => (
                      <div key={`${day}-${index}`}>
                        <input
                          type="checkbox"
                          name={index + 1}
                          value={index + 1}
                          defaultChecked={listRestDay.indexOf(`${index + 1}`) !== -1}
                          id={`rest-day-${index + 1}`}
                          onChange={onSelectRestDay}
                        />
                        <label className="rest-day-spec" htmlFor={`rest-day-${index + 1}`}>
                          {day}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {children}
          </div>
        )}
      </div>
    </AddrestDaySectionStyles>
  );
};

AddrestDaySection.propTypes = {};

export default connect()(AddrestDaySection);
