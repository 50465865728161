import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Header, Wrapper, Actions, Content } from 'shared/Styles/ModalLayout';

export const ModalWrapper = styled(Modal)`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;

  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 614px;
  }

  > .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 35px;
    font-size: 20px;
    line-height: 27px;

    &.scrolling {
      border-bottom: 1px solid #dadfea;
      font-size: 18px;
      line-height: 25px;
    }
  }

  ${Wrapper} {
    max-height: calc(100vh - 110px);
  }

  ${Actions} {
    padding: 10px 30px;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    z-index: 2;
  }

  ${Content} {
    padding: 10px 35px 30px;
    position: relative;

    .checkScrollItem {
      position: absolute;
      top: 100px;
    }
  }
`;

export const LinkSet = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px;
  margin: 8px 0 4px;

  svg {
    z-index: 2;
  }

  ${props =>
    props.linked &&
    css`
      padding: 0;

      :before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        background: rgb(218, 223, 234);
        z-index: 1;
      }
    `}
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  white-space: pre-line;
  word-break: break-all;

  ${props =>
    props.hasPadding &&
    css`
      padding: 20px 20px 10px;
    `}
`;

export const SupersetTitle = styled.div`
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  padding: 0 10px;
`;

export const SupersetHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SupersetExtendInfo = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #202353;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div:nth-child(even) {
    border-left: 1px solid #dadfea;
    padding-left: 10px;
    margin-left: 10px;

    span {
      :nth-child(1) {
        font-weight: normal;
      }
    }
  }
`;

export const TrainingSetContainer = styled.div`
  margin-bottom: 20px;

  table {
    border-collapse: initial;
    border-spacing: 0;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #202353;
    width: 100%;
    table-layout: fixed;

    th {
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
      color: #6a778a;
      padding: 5px 10px;

      .__react_component_tooltip.app-tooltip {
        width: 200px;
      }
    }

    tbody {
      tr {
        :not(:last-child) {
          td {
            border-bottom: 1px solid #dadfea;
          }
        }

        :first-child {
          td {
            :first-child {
              border-top-left-radius: 5px;
            }

            :last-child {
              border-top-right-radius: 5px;
            }
          }
        }

        :last-child {
          td {
            :first-child {
              border-bottom-left-radius: 5px;
            }

            :last-child {
              border-bottom-right-radius: 5px;
            }
          }
        }
      }

      td {
        padding: 10px;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #202353;
      }
    }

    &.table--body {
      border: 1px solid #dadfea;
      border-radius: 5px;
    }
  }
`;

export const SupersetContainer = styled.div`
  padding: 20px;

  > {
    div {
      margin-bottom: 20px;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  ${SupersetHeader} {
    margin-bottom: 15px;
  }
`;

export const ExerciseSupersets = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 3px rgba(76, 98, 135, 0.25);
  border-radius: 8px;
`;

export const SectionExercises = styled.div`
  padding: 15px 20px 20px;
  position: relative;
  ${props => {
    if (props.shouldHide) {
      return 'display: none;';
    }
  }}
`;

export const SectionHeaderLeft = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const SectionHeader = styled.div`
  background: linear-gradient(90.16deg, #2a307c 0.08%, #23233f 99.94%);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding: 13px 20px 8px;

  .sectionFormat {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #c0c4ca;
  }

  .name {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SectionContent = styled.div``;

export const Section = styled.div`
  background: #f7f8fc;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;

  ${props =>
    props.isExercise &&
    css`
      background: #fff;

      ${SectionHeader} {
        display: none;
      }

      ${SectionExercises} {
        padding: 0;
      }
    `}

  ${props =>
    props.linkable &&
    css`
      margin-bottom: 0;
    `}
`;

export const SectionList = styled.div`
  padding: 0;
`;

export const WorkoutDesctiptionContainer = styled.div`
  margin-bottom: 37px;

  label {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #202353;
    opacity: 0.4;
    margin-bottom: 7px;
    text-transform: uppercase;
  }
`;

export const UseORM = styled.div`
  border-radius: 3px;
  padding: 3px 10px 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #5158cf;
  background: rgba(81, 88, 207, 0.1);
`;

export const SetType = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;

  &.warm_up {
    color: #a7aeb7;
    font-weight: 600;
  }

  &.drop_set {
    color: #4881f9;
    font-weight: 600;
  }

  &.failure {
    color: #fd6666;
    font-weight: 600;
  }
`;
