// Libs
import React, { useEffect, useState } from 'react';

// Components
import ItemPackage from '../ItemPackage';

// Assets
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right_darkest.svg';

// Styles
import * as S from './style';

const MAXIMUM_NUMBER_TO_DISPLAY = 5;

const ListPackage = ({
  list,
  onRemove,
  cloudfrontList,
  onRedirectToSettings,
  onRedirectToOverview,
  onRemovePackage,
  isLoadingAction,
}) => {
  const [visibleCount, setVisibleCount] = useState(MAXIMUM_NUMBER_TO_DISPLAY);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      handleViewMore();
    }
  }, [list]);

  const newList = (list || []).slice(0, visibleCount);
  const lengthOfList = list.length;
  const lengthOfNewList = newList.length;
  const remainingPackage = lengthOfList - visibleCount;

  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + remainingPackage);
    setIsExpanded(true);
  };

  const handleShowLess = () => {
    setVisibleCount(MAXIMUM_NUMBER_TO_DISPLAY);
    setIsExpanded(false);
  };

  return (
    <S.Wrapper>
      {(newList || []).map(item => {
        const { hash_id, id } = item || {};
        return (
          <ItemPackage
            key={hash_id || id}
            item={item}
            cloudfrontList={cloudfrontList}
            onRemove={onRemove}
            onRedirectToSettings={onRedirectToSettings}
            onRedirectToOverview={onRedirectToOverview}
            onRemovePackage={onRemovePackage}
            isLoadingAction={isLoadingAction}
          />
        );
      })}
      {visibleCount < lengthOfList && (
        <S.ToggleButton onClick={handleViewMore}>
          <ArrowRightIcon className="custom-icon" />
          View more {remainingPackage === 1 ? 'package' : 'packages'} ({remainingPackage})
        </S.ToggleButton>
      )}
      {lengthOfNewList === lengthOfList && lengthOfNewList > MAXIMUM_NUMBER_TO_DISPLAY && (
        <S.ToggleButton onClick={handleShowLess}>
          <ArrowRightIcon className="custom-icon is-show-less" />
          Show less
        </S.ToggleButton>
      )}
    </S.Wrapper>
  );
};

export default ListPackage;
