import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 0;
    width: 100vw;
    min-height: 100vh;
    margin: -1rem !important;
    > .header {
      background: #1f2122;
      border-radius: 0 !important;
      position: sticky;
      top: -14px;
      z-index: 9;
      padding: 10px 0;
    }
    > :last-child {
      padding-top: 0;
    }
  }
  > .content {
    padding: 0 !important;
  }
  .confirm-content {
    padding: 0;
  }
  .confirm-footer {
    padding: 30px 0 0;
  }
`;

export const Container = styled.div`
  max-width: 1210px;
  margin: auto;
  position: relative;
  margin-bottom: 65px;
  ${props =>
    props.isMobile &&
    css`
      max-width: 375px;
      height: 812px;
      border-radius: 5px;
    `}
`;

export const HeaderContainer = styled(Container)`
  align-items: center;
  position: relative;
  display: flex;
  margin-bottom: 0;
  > svg {
    max-width: 16px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
`;
export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin: 0;
  padding-bottom: 15px;
  display: flex;

  svg {
    margin-right: 10px;
  }
`;

export const HeaderMessage = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #fff;
  margin-bottom: 0;
  width: 575px;
`;

export const HeaderPreviewDevicesWrapper = styled.div`
  display: flex;
`;
export const HeaderPreviewDevice = styled.div`
  width: 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  ${props =>
    props.active &&
    css`
      background: #fff;
    `}
`;

export const ModalDesc = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  white-space: pre-line;
`;

export const ModalButton = styled(Button)`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  min-width: auto;
  &.rest-btn {
    padding-left: 0;
    padding-right: 15px;
    border: 0;
    &:hover {
      background-color: transparent;
    }
  }
  &.all-btn {
    background-color: transparent;
    border: 1px solid #d6dae4;
    color: #818da1;
  }
  &.update-btn {
    background-color: #5559ff;
  }
`;

export const PreviewDetail = styled.div`
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  position: relative;
  ${props =>
    props.isMobile &&
    css`
      padding: 0;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 80px;
    `}
  ::-webkit-scrollbar {
    width: 0px !important; //for horizontal scrollbar
  }
`;

export const PreviewBanner = styled.div`
  height: 365px;
  background-size: cover;
  background-position: center;
  background-color: rgb(234, 236, 242);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
    `}
  ${props =>
    props.isMobile &&
    css`
      flex-shrink: 0;
      height: 192px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    `}
`;

export const PreviewInfoWrapper = styled.div`
  display: grid;
  margin-top: 38px;
  grid-template-columns: 66% 34%;
  ${props =>
    props.isMobile &&
    css`
      display: block;
      margin-top: 0;
      min-height: calc(100% - 214px);
    `}
`;

export const PreviewFormWrapper = styled.div`
  .desc {
    border-top: 1px solid #dbdbdb;
    ${props =>
      props.isMobile &&
      css`
        padding-bottom: 24px !important;
        border-top: none;
        border-bottom: none;
        box-shadow: none;
      `};

    .ql-editor {
      h1,
      h2,
      li {
        font-family: 'Public Sans';
      }
    }
  }

  ${props =>
    props.isMobile &&
    css`
      min-height: calc(100vh - 273px);
      &:last-child {
        display: block;
      }
    `}

  &:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 22px;
    ${props =>
      props.isMobile &&
      css`
        display: block;
      `}
  }
`;

export const PreviewNested = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Group = styled.div`
  padding-bottom: 30px;
  max-width: 670px;
  &.desc {
    ${props =>
      props.isMobile &&
      css`
        padding-bottom: 20px;
      `}
  }

  .ql-editor {
    padding: 40px 0 0 0;
    font-family: 'Public Sans';
    color: #1f1d1d;
    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      padding-bottom: 25px;
    }

    h2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      padding-bottom: 10px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
    }

    ul {
      list-style: disc;
      padding-left: 10px;
    }

    ol {
      list-style: auto;
      padding-left: 1.2em;
    }

    ol,
    ul {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      padding-top: 26px;
      li {
        padding-bottom: 15px;
      }

      li:not(.ql-direction-rtl) {
        padding-left: 0;
        letter-spacing: -0.3px;
        &::before {
          margin-right: 13px;
        }
      }
    }

    ${props =>
      props.isMobile &&
      css`
        p,
        ol,
        ul {
          font-size: 16px;
        }
      `}
  }

  ${props =>
    props.isMobile &&
    css`
      padding-left: 22px;
      padding-right: 22px;
      border-bottom: 1px solid transparent;
      box-shadow: 0px 2px 4px rgba(90, 90, 90, 0.04);
    `}

  ${props =>
    props.isMP &&
    css`
      padding-left: 0px;
      padding-right: 0px;
    `}

  &:last-child {
    border-bottom: none;
    ${props =>
      props.isMobile &&
      css`
        border-bottom: none;
        box-shadow: none;
        padding-bottom: 0;
      `}
  }
`;

export const PreviewTitle = styled.h2`
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.16px;
  margin-bottom: 11px;
  color: #141414;
  font-family: 'Sequel Sans';
  padding-top: 20px;
  font-weight: 500;
  ${props =>
    props.isMobile &&
    css`
      font-size: 20px;
      padding-bottom: 5px;
    `}
`;

export const PreviewHeading = styled.p`
  font-size: 18px;
  line-height: 150%;
  color: #101010;
  font-family: 'Public Sans';
  ${props =>
    props.isMobile &&
    css`
      font-size: 16px;
    `}
`;

export const PreviewCoachBio = styled.div`
  font-size: 16px;
  color: #1f1d1d;
  font-family: 'Public Sans';
  padding-top: 20px;
  padding-bottom: 36px;
  border-top: 1px solid #dbdbdb;

  ${props =>
    props.isMobile &&
    css`
      border-top: 1px solid #dbdbdb;
      padding-top: 20px;
      padding-bottom: 24px;
    `}
`;

export const BioContent = styled.div`
  max-width: 670px;
  padding-top: 15px;
  overflow-wrap: break-word;
  white-space: pre-line;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  font-family: 'Public Sans';
  color: #353535;

  ${props =>
    props.isMobile &&
    css`
      font-size: 16px;
    `}

  ${props =>
    !props.isReadMore &&
    css`
      display: block;
      max-height: calc(24px * 3 + 15px);
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (display: -webkit-box) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    `}
`;

export const ShowLess = styled.div`
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  color: #0084ff;
  cursor: pointer;
  font-size: ${props => (props.isMobile ? '16px' : '14px')};
  line-height: 150%;
  margin-top: 8px;
`;

export const CoachHeader = styled.div`
  display: flex;
  font-weight: 700;
`;

export const CoachRole = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  opacity: 0.4;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const AccountWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props =>
    props.isMobile &&
    css`
      max-width: 80%;
    `}
`;

export const CoachName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CoachTitle = styled.div`
  font-weight: 425;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  font-family: 'Sequel Sans';
  ${props =>
    props.isMobile &&
    css`
      margin-bottom: 8px;
    `}
`;

export const Avatar = styled.div`
  padding-right: 8px;
  ${props =>
    props.isMobile &&
    css`
      padding-right: 2px;
    `}
`;

export const FormContainer = styled.div`
  border: 1px solid #ddd;
  box-shadow: 0 20px 16px -10px hsl(0deg 0% 63% / 15%);
  border-radius: 8px;
  padding: 33px 20px 30px 20px;
  width: 360px;
`;

export const PreviewPricing = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PreviewPaymentType = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #101010;
  font-family: 'Public Sans';
  padding-top: 3px;
`;

export const PaymentTime = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding-top: 7px;
  color: #000000;
  font-family: 'Public Sans';
`;

export const PreviewPricingDetail = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
`;

export const RepeatTimes = styled.div`
  color: #000000;
  font-family: 'Public Sans';
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
`;

export const CurrencyContainer = styled.span`
  display: flex;
`;
export const Currency = styled.span`
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  font-family: 'Sequel Sans';
`;

export const CurrencyName = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding-top: 2px;
  padding-right: 4px;
`;

export const Pricing = styled(Currency)`
  font-weight: 700;
  font-family: 'Sequel Sans';
  color: #101010;
`;

export const FakeInput = styled.div`
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px 20px;
  font-size: 10px;
  line-height: 100%;
  color: #515152;
  opacity: 0.6;
  text-transform: uppercase;
  margin-top: 8px;
  width: 100%;
  font-family: 'Sequel Sans';
  font-weight: 500;
`;

export const CardForm = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-size: 10px;
  line-height: 100%;
  color: #515152;
  opacity: 0.6;
  text-transform: uppercase;
  margin-top: 22px;
  width: 100%;
  font-weight: 500;
  font-family: 'Sequel Sans';
`;

export const CardNumber = styled.div`
  border-bottom: 1px solid #dbdbdb;
  padding: 25px 20px;
  align-items: center;
  display: flex;
  svg {
    margin-right: 5px;
  }
`;

export const CardExpired = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  ${CardNumber} {
    border-bottom: 0;
    &:first-child {
      border-right: 1px solid #dbdbdb;
    }
  }
`;

export const PaymentButton = styled.div`
  background: #0085ff;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  margin-top: 30px;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'Public Sans';

  ${props =>
    props.isTrial &&
    css`
      padding: 18px 16px;
    `}
`;

export const FormFooter = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(34, 34, 44, 0.6);
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
  width: 100%;
  line-height: 20px;
  font-family: 'Public Sans';

  ${props =>
    props.isWhiteLabelAccount &&
    css`
      margin-bottom: 40px;
    `}
`;

export const RedirectLink = styled.a`
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto auto;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isWhiteLabelAccount &&
    css`
      margin: unset;
    `}
`;

export const Copyright = styled.p`
  margin-bottom: 0;
  color: #22222c;
  opacity: 0.5;
  padding-right: 2px;
  font-weight: 600;
  font-size: 10px;
  margin-right: 3px;
`;

export const EverfitFooter = styled.div`
  height: 84px;
  width: calc(100% + ${props => (props.desktop ? '40px' : '0px')});
  background: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0px, ${props => (props.desktop ? '0px' : '0')});
  margin: ${props => (props.desktop ? '-20px' : '0')};
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
    color: #8f95ab;
    letter-spacing: 0.5px;
  }
  svg {
    position: relative;
    top: 5px;
  }
`;

export const EverfitFooterText = styled.span`
  padding: 0 10px;
`;

export const FooterMobile = styled.div`
  border-top: 1px solid #e9e9e9;
  box-shadow: 0px -7px 12px rgb(90 90 90 / 4%);
  position: absolute;
  bottom: 0;
  width: 375px;
  padding: ${props => (props.desktop ? '12px 22px' : '0')};
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const FooterMobileItem = styled.div`
  display: inherit;
  width: 100%;
  padding: ${props => (props.desktop ? '' : '15px 22px')};
  justify-content: space-between;
`;

export const FooterCurrencyContainer = styled.div`
  display: flex;
`;
export const FooterPricing = styled(Pricing)`
  font-size: 24px;
`;

export const FooterCurrency = styled(Currency)`
  font-size: 24px;
  padding-right: 2px;
`;

export const FooterCurrencyName = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-top: 2px;
  padding-right: 2px;
`;

export const FooterPaymentButton = styled(PaymentButton)`
  width: 134px;
  height: 48px;
  margin-top: 0;
  justify-content: center;

  ${props =>
    props.isTrial &&
    css`
      width: 155px;
    `}
`;

export const FooterRepeat = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #0e0e0e;
  margin-top: 5px;
`;

export const TextBold = styled.strong`
  font-weight: 600;
  opacity: 0.4;
`;

export const TrialDesc = styled.div`
  margin-top: 16px;
  text-align: center;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #1f1d1d;
  opacity: 0.5;
`;

export const NewPreviewFormWrapper = styled.div`
  padding: 32px 0 0 0;
  ${props =>
    props.flex &&
    css`
      flex: 1;
    `}
  ${props =>
    props.isMobile &&
    css`
      flex: 1;
      padding: 32px 16px;
    `}
  ${props =>
    props.isTurnedOnWaitlistOrIntroCall &&
    css`
      padding: 0;
    `}
`;

export const NewPreviewTitle = styled.h2`
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.16px;
  color: #141414;
  font-family: 'Sequel Sans';
  padding: 16px 0;
  margin-bottom: 0;
  font-weight: 500;
  ${props =>
    props.isMobile &&
    css`
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0px;
    `}
`;

export const NewPreviewHeading = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #141414;
  font-family: 'Public Sans';
  font-weight: 500;
  padding-bottom: 40px;
  border-bottom: 1px solid #f2f2f2;
  ${props =>
    props.isMobile &&
    css`
      padding-bottom: 24px;
    `}
`;

export const NewGroupPreviewContent = styled.p`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${props =>
    props.isMobile &&
    css`
      padding: 24px 0;
    `}
`;

export const NewPreviewContentTitle = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: #141414;
  font-family: 'Sequel Sans';
  font-weight: 500;
  margin-bottom: 12px;
  ${props =>
    props.isMobile &&
    css`
      font-size: 18px;
      line-height: 26px;
    `}
`;

export const NewPreviewContentSubTitle = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: #141414;
  font-family: 'Sequel Sans';
  font-weight: 500;
  margin-bottom: 12px;
  ${props =>
    props.isMobile &&
    css`
      font-size: 16px;
    `}
`;

export const NewPreviewContentDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #141414;
  font-family: 'Public Sans';
  font-weight: 500;
  margin-bottom: 12px;
`;

export const NewPreviewContentList = styled.ul`
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NewPreviewContentItem = styled.li`
  font-size: 16px;
  line-height: 24px;
  color: #141414;
  font-family: 'Sequel Sans';
  font-weight: 500;
`;

export const NewFooterMobileItem = styled.div`
  width: 100%;
  padding: 16px;
`;

export const NewFooterCurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .align-right {
    text-align: right;
    .currency {
      display: flex;
    }
  }
`;
export const NewFooterPricing = styled(Pricing)`
  color: #141414;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -1.28px;
  font-family: 'Sequel Sans';
`;

export const NewFooterCurrency = styled(Currency)`
  color: #141414;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: 'Sequel Sans';
`;

export const NewFooterCurrencyName = styled.sub`
  color: #141414;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Sequel Sans';
  text-transform: uppercase;
`;

export const NewFooterTotalLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #141414;
  font-family: 'Sequel Sans';
`;

export const NewFooterPaymentButton = styled(PaymentButton)`
  width: 100%;
  height: 56px;
  font-size: 16px;
  background-color: #0038ff;
  color: #fff;
  font-family: 'Sequel Sans';
  font-weight: 700;
  line-height: 24px;
  margin-top: 8px;
  justify-content: center;
  gap: 12px;
`;

export const NewFooterRepeat = styled.p`
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Sequel Sans';
`;

export const NewPreviewDetail = styled.div`
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  position: relative;
  ${props =>
    props.isMobile &&
    css`
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      overflow-y: auto;
      padding-bottom: ${props => (props.previewDetailHeight ? `${props.previewDetailHeight}px` : '136px')};
6    `}
  ::-webkit-scrollbar {
    width: 0px !important; //for horizontal scrollbar
  }
`;

export const NewPreviewInfoWrapper = styled.div`
  display: flex;
  gap: 80px;
  margin: 40px 0;
  ${props =>
    props.isMobile &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0;
      margin: 0;
    `}
`;

export const NewPreviewBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const NewPreviewBreadcrumbLink = styled.span`
  color: #8c8c8c !important;
  font-family: 'Sequel Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  &:hover {
    text-decoration: none !important;
  }
`;

export const NewPreviewPricing = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NewPaymentTitle = styled.span`
  font-family: 'Sequel Sans';
  color: #141414;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const NewCurrencyName = styled.span`
  color: #141414;
  font-family: 'Sequel Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const NewCurrency = styled.span`
  color: #141414;
  font-family: 'Sequel Sans';
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
`;

export const NewPricing = styled(NewCurrency)`
  font-weight: 700;
  letter-spacing: -1.28px;
`;

export const NewPaymentTime = styled.span`
  color: #8c8c8c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Public Sans';
`;

export const NewCurrencyContainer = styled.span`
  display: flex;
  justify-content: end;
`;

export const NewCardForm = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 100%;
`;

export const NewCardInput = styled.div`
  height: 64px;
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
  padding: 0 20px;
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Sequel Sans';
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #e5e5e5;
    `}
  ${props =>
    props.borderRight &&
    css`
      border-right: 1px solid #e5e5e5;
    `};
`;

export const NewCardExpired = styled.div`
  display: flex;
  &:last-child {
    border-bottom: none !important;
  }
`;

export const NewPaymentButton = styled.div`
  background: #0038ff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  font-family: 'Sequel Sans';
  line-height: 24px;
  height: 56px;
`;

export const NewTrialDesc = styled.div`
  color: #8c8c8c;
  text-align: center;
  font-family: 'Public Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
`;

export const NewFormContainer = styled.div`
  display: flex;
  width: 400px;
  padding: 32px;
  flex-direction: column;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
    0px 0px 4px 0px rgba(0, 0, 0, 0.06);
`;
