// Libs
import classNames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNumberOfResponse, getDetails } from 'redux/form-details/actions';
import { push } from 'connected-react-router';

// Actions
import {
  exportResponsesPdf,
  getClientAnswerDetails,
  getClientsList,
  getFormSummary,
} from 'redux/form-responses/actions';
import { toggleModal } from 'actions/modal';
import { showError, hideError } from 'actions/error';

// Components
import Individual from './Individual';
import Summary from './Summary';

// Utils
import { isTeamAdmin } from 'utils/commonFunction';

// Styles
import * as S from './styles';

const VIEW_MODES = {
  INDIVIDUAL: 'individual',
  SUMMARY: 'summary',
};

function ResponsesMainPanel({
  cloudfrontList,
  workingClient,
  getClientAnswerDetails,
  getFormSummary,
  match,
  summary,
  viewMode,
  onChangeViewMode,
  clientId,
  submittedId,
  getNumberOfResponse,
  getClientsList,
  toggleModal,
  push,
  getDetails,
  isAuthorizedClient,
  exportResponsesPdf,
  loadingPrint,
  showError,
  resetHistoryState,
  hideError,
  user,
  paymentOverdue,
  zapierOverdue,
  addOnsOverdue,
  paymentWhiteLabelOverdue,
}) {
  const { timezone, date_format: dateFormat, is_verified_email, force_send_verification_email_at } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  const handleChangeTab = tab => () => {
    onChangeViewMode(tab);
    getNumberOfResponse();
    resetHistoryState();

    const id = get(match, 'params.id', '');
    if (tab === VIEW_MODES.SUMMARY && id) {
      getFormSummary(id);
    }
    if (tab === VIEW_MODES.INDIVIDUAL && id) {
      getClientsList(
        id,
        {
          clientId,
          submittedId,
          page: 1,
        },
        true,
      );
    }
  };

  const handlePrint = () => {
    const { workingAnswerDate } = workingClient;
    exportResponsesPdf && exportResponsesPdf(get(workingAnswerDate, '_id', ''));
  };

  const handleCloseError = () => {
    resetHistoryState();
    hideError();

    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  return (
    <S.Wrapper isVerifyEmail={isWhiteLabelOverdue || isShowOverdue || isVerifyEmail}>
      <S.TabsContainer isIndividual={viewMode === VIEW_MODES.INDIVIDUAL}>
        {map(VIEW_MODES, tab => (
          <div
            className={classNames('tab', { active: tab === viewMode })}
            onClick={handleChangeTab(tab)}
            key={tab}
            name={capitalize(tab)}
          >
            {capitalize(tab)}
          </div>
        ))}
      </S.TabsContainer>
      {viewMode === VIEW_MODES.INDIVIDUAL && workingClient && (
        <Individual
          cloudfrontList={cloudfrontList}
          workingClient={workingClient}
          getClientAnswerDetails={getClientAnswerDetails}
          timezone={timezone}
          dateFormat={dateFormat}
          submittedId={submittedId}
          toggleModal={toggleModal}
          match={match}
          push={push}
          getDetails={getDetails}
          isAuthorizedClient={isAuthorizedClient}
          onPrint={handlePrint}
          loadingPrint={loadingPrint}
          showError={showError}
          closeError={handleCloseError}
        />
      )}
      {viewMode === VIEW_MODES.SUMMARY && <Summary summaryData={summary} dateFormat={dateFormat} />}
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: {
      formResponses: { workingClient, summary, loadingPrint },
      pricing,
    },
    user,
  } = state;

  return {
    cloudfrontList,
    isAuthorizedClient: get(
      state,
      'rootReducer.formResponses.workingClient.workingAnswerDate.is_authorized_client',
      false,
    ),
    workingClient,
    summary,
    user,
    loadingPrint,
    paymentOverdue: pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatch = dispatch => {
  return {
    getClientAnswerDetails: bindActionCreators(getClientAnswerDetails, dispatch),
    getFormSummary: bindActionCreators(getFormSummary, dispatch),
    getNumberOfResponse: bindActionCreators(getNumberOfResponse, dispatch),
    getClientsList: bindActionCreators(getClientsList, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getDetails: bindActionCreators(getDetails, dispatch),
    push: bindActionCreators(push, dispatch),
    exportResponsesPdf: bindActionCreators(exportResponsesPdf, dispatch),
    showError: bindActionCreators(showError, dispatch),
    hideError: bindActionCreators(hideError, dispatch),
  };
};

export default connect(mapState, mapDispatch)(ResponsesMainPanel);
