import styled from 'styled-components';

export const NotGroups = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const customSelectOwnerStyles = {
  container: baseStyle => ({
    ...baseStyle,
    background: '#FAFBFC',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#D4D7D9',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#D4D7D9' : '#5C5BBD',
    },
    minHeight: '38px',
    maxHeight: '38px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FAFBFC' : state.isFocused || state.isSelected ? '#FFFFFF' : '#FAFBFC',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 12px',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
  }),
  placeholder: baseStyle => ({
    ...baseStyle,
    color: '#969797',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWight: '400',
    lineHeight: 'normal',
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '8px',
    marginBottom: '8px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: '4px',
    paddingBottom: '4px',
    overflowX: 'hidden',
    maxHeight: 274,
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#F7F7FF' : '#ffffff',
    ':hover': {
      backgroundColor: '#F7F7FF',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isFocused || state.isSelected ? '#5158CF' : '#000000',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : '400',
    lineHeight: 'normal',
    padding: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '.label': {
      backgroundColor: state.isFocused || state.isSelected ? '#F7F7FF' : '#ffffff',
      ':hover': {
        backgroundColor: '#F7F7FF',
      },
      color: state.isFocused || state.isSelected ? '#5158CF' : '#000000',
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: state.isSelected ? '600' : '400',
      lineHeight: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
};

export const ModalityOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .label {
    color: #000;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .icon {
    width: 12px;
    height: 12px;
    flex-shrink: 0;

    path {
      fill: #bcc1cf;
    }

    :hover {
      path {
        fill: #2d2e2d !important;
      }
    }
  }

  .__react_component_tooltip.modality-tooltip.show {
    display: flex;
    padding: 15px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    border-radius: 5px;
    background: #2d2e2d;
    padding: 15px;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    white-space: pre-wrap;
    text-align: center;
  }

  .__react_component_tooltip.type-dark.place-top.modality-tooltip:after {
    border-top-color: #2d2e2d !important;
    margin-bottom: 0px !important;
    border-top-width: 10px !important;
  }
`;
