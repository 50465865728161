import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { Dropdown } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down_new.svg';

import * as S from './style';

const FILTER_OWNER_OPTIONS = {
  all: 2,
  you: 1,
};

const OwnerFilter = props => {
  const { open, setOpen, filter, setFilter } = props;

  const title = filter === FILTER_OWNER_OPTIONS.all ? 'All' : 'You';

  const handleSelectFilter = ownerShip => {
    setFilter(ownerShip);
    setOpen(false);
  };

  return (
    <RootCloseWrapper
      onRootClose={() => {
        setOpen(false);
      }}
      event="click"
      disabled={!open}
    >
      <S.Wrapper>
        <div className={`filter__dropdown ${open ? 'active' : ''}`} onClick={() => setOpen(!open)}>
          <div className="filter__dropdown__title">
            <span>Owner:&nbsp;</span>
            <span className="selected">{title}</span>
          </div>
        </div>
        {open && (
          <S.MenuContainer>
            <div className="form-group">
              <label>Owner</label>
              <Dropdown
                text={
                  <>
                    <span>{title}</span>
                    <ArrowDown />
                  </>
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleSelectFilter(FILTER_OWNER_OPTIONS.you)}
                    className={filter === FILTER_OWNER_OPTIONS.you ? 'active' : ''}
                  >
                    You
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleSelectFilter(FILTER_OWNER_OPTIONS.all)}
                    className={filter === FILTER_OWNER_OPTIONS.all ? 'active' : ''}
                  >
                    All
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

OwnerFilter.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setFilter: PropTypes.func,
  user: PropTypes.object,
  isHasSelectTrainer: PropTypes.bool,
};

export default OwnerFilter;
