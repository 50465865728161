import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const HeaderStyles = styled(Modal.Header)`
  border-top-left-radius: 8px;
  display: flex !important;
  align-items: center !important;
  margin-top: 20px !important;
  padding: 15px 30px !important;

  .back-icon {
    cursor: pointer;
    display: flex;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #202353;
    margin-left: 15px;
  }
  .calculate-macros-tooltip {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
`;

export const ContentStyles = styled(Modal.Content)`
  padding: 15px 30px 30px !important;
  max-height: calc(100vh - 175px);
  overflow: auto;
  .group-inline {
    display: flex;
    & > div:not(.macros-unit) {
      flex: 1;
    }

    .macros-unit {
      width: 100px;
    }

    & > div.gender-group {
      flex: 2;
    }

    input {
      &.error {
        border-color: #ea314a;
      }
    }

    .error .single-select__control {
      border-color: #ea314a;
    }

    .error-msg {
      margin-top: 5px;
      font-size: 13px;
      line-height: 18px;
      color: #ff1b1b;
    }
  }

  .single-select__control {
    border: 1px solid #dadfea;
    box-sizing: border-box;
    border-radius: 4px;
    background: #fafbfc;
    min-height: 36px;
    padding: 0 6px;
    box-shadow: none !important;

    :hover,
    &.single-select__control--menu-is-open,
    &.single-select__control--is-focused {
      border-color: #5158cf !important;
    }

    .single-select__indicator-separator {
      display: none;
    }

    .single-select__input {
      input {
        height: auto;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #323c47;
      }
    }

    .single-select__placeholder,
    .single-select__single-value {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #323c47;
    }
  }

  .single-select__menu {
    margin: 0;
    overflow: hidden;
    z-index: 999;

    .single-select__menu-list {
      .single-select__option {
        background: #fff;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
        cursor: pointer;

        &.single-select__option--is-selected,
        &.single-select__option--is-focused {
          background: #f4f4ff !important;
        }
      }
    }
  }

  .macros-model-divider {
    width: 10px;
  }
`;

export const ActionsStyles = styled(Modal.Actions)`
  background: #ffffff !important;
  border: none !important;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  padding: 12px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: right;
`;

export default styled(Modal)`
  border-radius: 8px !important;
  & > form {
    padding: 0 !important;
  }

  .add-rest-day-section {
    .add-rest-day-btn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #5158cf;
      cursor: pointer;

      & * {
        fill: #5158cf;
      }

      span {
        margin-left: 5px;
        border-bottom: solid #5559ff 1px;
      }
    }

    .rest-day-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #202353;
      margin: 20px 0 15px;
    }

    .rest-day-option {
      display: flex;
      align-items: center;
      height: 37px;
      justify-content: space-between;

      label {
        font-size: 12px;
        line-height: 16px;
        color: #202353;
        font-weight: normal !important;
      }

      .list-rest-day {
        border: 1px solid #dadfea;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px;
        display: flex;

        input[type='checkbox'] {
          display: none;

          &:checked + .rest-day-spec {
            background: #e7e6ff;
            border-radius: 3px;
            color: #7166ea;
          }
        }

        .rest-day-spec {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 1px;
          cursor: pointer;
        }
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
