// Lib
import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

// Store
import {
  getAssignedList,
  unassignedMealPlan,
  maskAsComplete,
  getTotalAssignedClientList,
} from 'redux/meal-plans/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

// Shared
import AssignMealPlan from '../AssignMealPlan';
import Item from './Item';

// Constants
import { ASSIGNED_STATUS, MEAL_PLAN_STATUS } from 'components/MealPlanDetail/constants';
import { CDN_URL } from 'constants/commonData';

// Utils
import { pluralize } from 'utils/commonFunction';

// Components
import RemoveWeek from '../RemoveWeek';

// Assets
import { ReactComponent as AddClient } from 'assets/icons/MealPlans/add_client.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_blue.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove_icon.svg';
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import * as S from './style';

const tabs = [
  {
    key: ASSIGNED_STATUS.UPCOMING,
    label: 'Waiting to start',
  },
  {
    key: ASSIGNED_STATUS.ACTIVE,
    label: 'Active',
  },
  {
    key: ASSIGNED_STATUS.COMPLETED,
    label: 'Completed',
  },
];

const header = [
  {
    key: 'clients',
    label: 'Clients',
  },
  {
    key: 'progress',
    label: 'Progress',
  },
  {
    key: 'start_date',
    label: 'Start Date',
  },
  {
    key: 'end_date',
    label: 'End Date',
  },
];

const AssignedClientList = props => {
  const {
    toggleModal,
    toggleSecondModal,
    getAssignedList,
    cloudfrontList,
    detailMealPlan,
    toggleConfirmModal,
    unassignedMealPlan,
    maskAsComplete,
    getTotalAssignedClientList,
  } = props;

  const { status = '', _id: idDetailMealPlan = '' } = detailMealPlan || {};

  const listRef = useRef(null);
  const requestResetText = useRef(null);

  const isDraft = status === MEAL_PLAN_STATUS.DRAFT;

  const [totalClient, setTotalClient] = useState({});
  const [tab, setTab] = useState(tabs[1]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [filters, setFilter] = useState({
    page: 1,
    per_page: 20,
    text_search: '',
    meal_plan_id: idDetailMealPlan,
  });
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { page } = filters;

      try {
        if (page === 1) {
          setLoading(true);
        }
        const response = await getAssignedList({ ...filters, status: tab.key }, false);
        const dataList = get(response, 'data.data.data', []);
        const total = get(response, 'data.data.total', 0);
        setTotal(total);
        setData(statePrev => (page === 1 ? dataList : [...statePrev, ...dataList]));
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        if (page === 1) {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [filters, tab]);

  useEffect(() => {
    handleGetTotalAssignedClientList();
  }, [filters.text_search]);

  const handleGetTotalAssignedClientList = async () => {
    getTotalAssignedClientList({
      meal_plan_id: idDetailMealPlan,
      text_search: (filters && filters.text_search) || undefined,
    }).then(res => {
      const total = get(res, 'data.data', {});
      setTotalClient(total);
    });
  };

  const handleClose = () => {
    toggleSecondModal && toggleSecondModal(false);
  };

  const handleOpenAssign = () => {
    if (isDraft) return null;
    toggleModal &&
      toggleModal(true, <AssignMealPlan toggleModal={toggleModal} callbackAssignSuccess={callbackAssignSuccess} />);
  };

  const handleSearch = (e, { value }) => {
    setData([]);
    setFilter(statePrev => ({ ...statePrev, page: 1, text_search: value }));
  };

  const handleClearSearch = () => {
    setData([]);
    setFilter(statePrev => ({ ...statePrev, page: 1, text_search: '' }));
  };

  const handleChangeTab = data => {
    if (data.key === tab.key) return;
    setSelectedClient([]);
    setData([]);
    setFilter(statePrev => ({ ...statePrev, page: 1 }));
    setTab(data);
    handleGetTotalAssignedClientList();
    getAssignedList({ meal_plan_id: idDetailMealPlan, status: ASSIGNED_STATUS.ACTIVE });
  };

  const handleSelectClient = (e, idClient) => {
    const checked = e.target.checked;
    setSelectedClient(prevSelected => {
      const updatedSelected = new Set(prevSelected);
      if (checked) {
        updatedSelected.add(idClient);
      } else {
        updatedSelected.delete(idClient);
      }
      return Array.from(updatedSelected);
    });
  };

  const onScroll = () => {
    const listClient = listRef && listRef.current;
    if (listClient && get(data, 'length', 0) < total) {
      const isAtBottom = listClient.scrollTop + listClient.clientHeight >= listClient.scrollHeight - 25;
      if (isAtBottom) {
        setFilter(statePrev => ({ ...statePrev, page: statePrev.page + 1 }));
      }
    }
  };

  const handleRemove = () => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <RemoveWeek
          confirmButtonTitle="Remove"
          title="Remove client from meal plan?"
          content="Would you like to remove clients from the meal plan?"
          onConfirm={handleRemoveClient}
          noBorder
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          shouldCloseAfterConfirm={false}
        />,
      );
  };

  const handleRemoveClient = () => {
    if (selectedClient.length > 0) {
      const params = {
        meal_plan_id: idDetailMealPlan,
        meal_plan_clients: selectedClient,
      };
      toggleConfirmModal(false);
      unassignedMealPlan(params, tab.key).then(() => {
        toast(`${pluralize('client', selectedClient.length, true)} has been removed.`);
        resetDataSuccess();
        handleGetTotalAssignedClientList();
      });
    }
  };

  const handleMaskComplete = () => {
    if (loadingAction) return;
    if (selectedClient.length > 0) {
      const params = {
        meal_plan_id: idDetailMealPlan,
        assign_ids: selectedClient,
      };
      setLoadingAction(true);
      maskAsComplete(params, tab.key)
        .then(() => {
          toast(
            `${pluralize(
              'client',
              selectedClient.length,
              true,
            )} marked as “Completed". This may take a few minutes to process.`,
          );
          resetDataSuccess();
          handleGetTotalAssignedClientList();
        })
        .finally(() => {
          setLoadingAction(false);
        });
    }
  };

  const resetDataSuccess = () => {
    const clients = data.filter(item => !selectedClient.includes(item._id));
    setSelectedClient([]);
    setData(clients);
  };

  const callbackAssignSuccess = async () => {
    const params = { ...filters, page: 1, text_search: '', status: tab.key };
    setFilter(params);
    requestResetText && requestResetText.current();
    setSelectedClient([]);
    handleGetTotalAssignedClientList();
  };

  const calculateTotal = (key, totals) => {
    const { total_upcoming, total_active, total_completed } = totals || {};
    switch (key) {
      case ASSIGNED_STATUS.UPCOMING:
        return total_upcoming || 0;
      case ASSIGNED_STATUS.ACTIVE:
        return total_active || 0;
      case ASSIGNED_STATUS.COMPLETED:
        return total_completed || 0;
      default:
        return 0;
    }
  };

  return (
    <S.CustomModal open onClose={handleClose} closeOnDimmerClick={false} className="evf-assigned-client-modal">
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderTitle>Clients assigned to meal plan</S.HeaderTitle>
          <S.HeaderAssign disabled={isDraft} onClick={handleOpenAssign} data-tip data-for="assign-meal-plan-tooltip">
            <AddClient className="assign-client" />
            <span>Assign More Clients</span>
          </S.HeaderAssign>
          {isDraft && (
            <ReactTooltip
              className="assign-meal-plan-tooltip"
              id="assign-meal-plan-tooltip"
              effect="solid"
              place="top"
              delayShow={300}
            >
              Please publish the meal plan before assigning to the clients.
            </ReactTooltip>
          )}
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <S.InputSearch
            placeholder="Search clients"
            onChange={debounce(handleSearch, 300)}
            onClearSearch={handleClearSearch}
            defaultValue={filters.text_search}
            requestResetText={requestResetText}
          />
          <S.TabHeader>
            {tabs.map(item => {
              const { key, label } = item;
              const total = calculateTotal(key, totalClient || {});

              return (
                <S.TabHeaderItem onClick={() => handleChangeTab(item)} active={key === get(tab, 'key', '')} key={key}>
                  {label} ({total})
                </S.TabHeaderItem>
              );
            })}
          </S.TabHeader>
          <S.TabContent>
            <S.TableContainer hasClient={selectedClient.length > 0} ref={listRef} onScroll={debounce(onScroll, 300)}>
              <S.TableHeader>
                {header.map(item => {
                  const { key, label } = item;
                  return (
                    <S.TableCell isHeader key={key} styleHeader={key}>
                      {label}
                    </S.TableCell>
                  );
                })}
              </S.TableHeader>
              {!loading && (
                <>
                  {data.length > 0 ? (
                    <>
                      {data.map(item => {
                        const { _id } = item;
                        return (
                          <Item
                            key={_id}
                            data={item}
                            selectedClient={selectedClient}
                            cloudfrontList={cloudfrontList}
                            tab={tab}
                            handleSelectClient={handleSelectClient}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <S.TextEmpty>No Clients Found.</S.TextEmpty>
                  )}
                </>
              )}
            </S.TableContainer>
          </S.TabContent>
          {selectedClient.length > 0 && (
            <S.ActionWrapper>
              <span>{selectedClient.length} selected</span>
              <S.Action>
                {tab.key !== ASSIGNED_STATUS.COMPLETED && (
                  <S.MaskComplete onClick={handleMaskComplete}>
                    <CheckIcon />
                    Mask as complete
                  </S.MaskComplete>
                )}
                <S.Remove onClick={handleRemove}>
                  <RemoveIcon />
                  Remove
                </S.Remove>
              </S.Action>
            </S.ActionWrapper>
          )}
        </S.ContentWrapper>
      </Modal.Content>
    </S.CustomModal>
  );
};

const mapState = state => {
  const {
    rootReducer: { mealPlans },
    cloudfrontList,
  } = state;

  const listClientAssigned = get(mealPlans, 'assignedList', []);
  const detailMealPlan = get(mealPlans, 'detailMealPlan', {});

  return { listClientAssigned, detailMealPlan, cloudfrontList };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  unassignedMealPlan: bindActionCreators(unassignedMealPlan, dispatch),
  getAssignedList: bindActionCreators(getAssignedList, dispatch),
  maskAsComplete: bindActionCreators(maskAsComplete, dispatch),
  getTotalAssignedClientList: bindActionCreators(getTotalAssignedClientList, dispatch),
});

export default connect(mapState, mapDispatch)(AssignedClientList);
