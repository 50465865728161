import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import styled, { css } from 'styled-components';

const textBase = `
    font-family: 'Open Sans';
    font-weight: 400;
    line-height: 150%;
    color: #202353;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  width: 100%;
  padding: 32px 31px 32px;
  border-radius: 12px;
  border: 1px solid #dadfea;

  ${props =>
    props.isEmpty &&
    css`
      align-items: center;
    `}

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      .left-side,
      .right-side {
        user-select: none;
        pointer-events: none;
      }
    `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 400px;
`;

export const Header = styled.div`
  ${textBase}
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;

  margin-bottom: 12px;
`;

export const Description = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(32, 35, 83, 0.7);

  margin-bottom: 6px;
`;

export const LearnMore = styled.a`
  display: flex;
  margin-bottom: 2px;

  :hover {
    color: #6456c4;
  }
`;

export const LearnMoreIcon = styled.img`
  margin-right: 4px;
`;

export const LearnMoreText = styled.div`
  color: #6456c4;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  :hover {
    color: #6456c4;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonUpgradePath = styled(UpgradePathButton)`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 20px;
  border: 1px solid #f37335;
  border-radius: 5px;
  background: #fff;

  span img {
    width: 20px;
    height: 20px;
  }

  .upgrade-path-text {
    text-transform: none;
    padding-left: 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #f37335;
  }

  &:hover {
    border: 1px solid #ff9d6d;
    background: unset;
    .upgrade-path-text {
      color: #ff9d6d;
    }
  }
`;
