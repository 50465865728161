import React, { useEffect, useRef, useState } from 'react';

import TimeCounting from './TimeCounting';

import { ReactComponent as CircleIcon } from 'assets/icons/countdown-circle.svg';
import { BACKGROUND_DEFAULT, BACKGROUND_LIST } from '../../constants';

import * as S from './style';

const CountdownDetail = ({ countdown }) => {
  const { title = '', color = '', icon, date_utc = '' } = countdown;
  const [isSmallHeight, setIsSmallHeight] = useState(false);
  const wrapperRef = useRef();
  const selectedColor = BACKGROUND_LIST.find(({ background }) => color === background) || BACKGROUND_DEFAULT;

  useEffect(() => {
    if (wrapperRef.current) {
      const { offsetHeight = 0 } = wrapperRef.current;
      setIsSmallHeight(offsetHeight <= 550);
    }
  }, [wrapperRef.current]);

  return (
    <S.Wrapper ref={wrapperRef}>
      <S.BodyWrapper isSmallHeight={isSmallHeight}>
        <S.CountDownName>{title}</S.CountDownName>
        <S.CircleWrapper color={selectedColor}>
          <S.Circle>{icon}</S.Circle>
          <CircleIcon className="icon-outer" />
        </S.CircleWrapper>
        <TimeCounting datetime={date_utc} />
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default CountdownDetail;
