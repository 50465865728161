// Libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

// Actions
import { axiosInstance } from 'configs/request';
import { logoutWithoutRedirect } from 'actions/auth/login';

// Layouts
import LayoutVerifyEmail from './layout';
import { useCountdown } from 'hooks/useCountdown';

// Assets
import { ReactComponent as VerifyConfirmIcon } from 'assets/icons/VerifyEmail/verify_confirm.svg';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow_back.svg';

// Styles
import * as S from './style';

export const TIME_COUNT_DOWN = 90000;
export const CHEAT_TIME_COUNT_DOWN = 10000;

const VerifyEmail = props => {
  const { user, logoutWithoutRedirect, history } = props;
  const { email } = user || {};

  const timeCountDown = process.env.REACT_APP_CHEAT_TIME_COUNT_DOWN_VERIFY ? CHEAT_TIME_COUNT_DOWN : TIME_COUNT_DOWN;

  const { countdown, setCountdown } = useCountdown();
  const hasCooldown = localStorage.getItem('has_cooldown');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasCooldown || !countdown) {
      resendEmailVerification(!!hasCooldown);
    }
  }, []);

  const resendEmailVerification = (isClickResend = false) => {
    setLoading(true);
    axiosInstance
      .post('/api/authentication/trainer/send-email-verification', { is_click_resend: isClickResend })
      .then(response => {
        const { success, cooldown_resend: coolDownTime } = get(response, 'data.data', {});

        if (!isClickResend) {
          setCountdown(Math.ceil(coolDownTime / 1000));
          return;
        }

        if ((success || coolDownTime !== 0) && isClickResend) {
          setCountdown(Math.ceil((coolDownTime || timeCountDown) / 1000));
        }
      })
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  const handleResend = isClickResend => {
    if (loading) return;
    resendEmailVerification(isClickResend);
  };

  const onBackToLogin = () => {
    logoutWithoutRedirect();
    history.replace(`/login?email=${encodeURIComponent(email)}`);
  };

  return (
    <LayoutVerifyEmail>
      <S.ContentWrapper>
        <VerifyConfirmIcon className="verify-confirm-icon" />
        <S.VerifyConfirmInfo>
          <S.VerifyConfirmTitle>Check your email</S.VerifyConfirmTitle>
          <S.VerifyConfirmDesc>
            Thank you for signing up! A verification email has been sent to
            <S.Bold> {email}</S.Bold>. Please check your inbox and follow the instructions to verify your email address
            and complete your registration.
          </S.VerifyConfirmDesc>
          <S.VerifyConfirmActions>
            <S.ActionContent>If you didn't receive an email.</S.ActionContent>
            {!!countdown ? (
              <S.ActionCountDown>Resend ({countdown}s)</S.ActionCountDown>
            ) : (
              <S.ActionResend onClick={() => handleResend(true)} disabled={loading}>
                Click here to resend
              </S.ActionResend>
            )}
          </S.VerifyConfirmActions>
        </S.VerifyConfirmInfo>
        <S.VerifyBackLoginWrapper onClick={onBackToLogin}>
          <ArrowBackIcon className="verify-back-icon" />
          <S.VerifyBackButton>Back to login</S.VerifyBackButton>
        </S.VerifyBackLoginWrapper>
      </S.ContentWrapper>
    </LayoutVerifyEmail>
  );
};

const mapStateToProps = state => {
  const { user } = state || {};

  return {
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  logoutWithoutRedirect: bindActionCreators(logoutWithoutRedirect, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
