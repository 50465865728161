import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Container, Arrow } from './style.NewDateRange';

const DATE_FORMAT = 'MMM D';

export default function DateRangePicker(props) {
  const { startDate, weeks, onChange } = props;

  const onSelectDate = date => {
    const startDateMoment = moment(startDate);

    if (!date.isSame(startDateMoment, 'isoWeek')) {
      const newStartDate = moment(date).startOf('isoWeek');
      const newEndDate = moment(newStartDate).add(7 * weeks - 1, 'day');
      onChange(newStartDate, newEndDate);
    }
  };

  const onArrowClick = factor => {
    const newStartDate = moment(startDate).add(7 * factor, 'day');
    const newEndDate = moment(newStartDate).add(7 * weeks - 1, 'day');
    onChange(newStartDate, newEndDate);
  };

  const renderDay = (props, currentDate, selectedDate) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(startDate).add(7 * weeks, 'days');
    let className = props.className || '';

    const isStartDate = currentDate.isSame(startDateMoment, 'day');
    const isEndDate = currentDate.isSame(moment(endDateMoment).add(-1, 'days'), 'day');

    if (isStartDate || currentDate.isBetween(startDateMoment, endDateMoment)) {
      className += ' inRange';
    }

    if (isStartDate) {
      className += ' rangeStart';
    }

    if (isEndDate) {
      className += ' rangeEnd';
    }

    return (
      <td {...props} className={className}>
        <div className="content">
          <div>{currentDate.format('DD')}</div>
        </div>
      </td>
    );
  };

  const value = moment(startDate);
  const endDate = moment(startDate).add(7 * weeks - 1, 'day');

  return (
    <Container>
      <Arrow left onClick={() => onArrowClick(-1)}>
        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.66672 7.66678L6.66672 0.333443C6.66669 0.272735 6.65009 0.213186 6.6187 0.161224C6.58731 0.109261 6.54233 0.0668572 6.4886 0.0385885C6.43488 0.0103197 6.37445 -0.00274056 6.31385 0.000817098C6.25325 0.00437475 6.19477 0.0244152 6.14472 0.0587758L0.811384 3.72544C0.766833 3.75608 0.730403 3.79709 0.705234 3.84494C0.680065 3.89279 0.666914 3.94604 0.666914 4.00011C0.666914 4.05418 0.680065 4.10743 0.705234 4.15528C0.730403 4.20313 0.766833 4.24414 0.811384 4.27478L6.14472 7.94144C6.19476 7.9758 6.25325 7.99584 6.31385 7.9994C6.37445 8.00296 6.43488 7.9899 6.4886 7.96163C6.54233 7.93336 6.58731 7.89096 6.6187 7.83899C6.65009 7.78703 6.66669 7.72748 6.66672 7.66678Z"
            fill="#6A7988"
          />
        </svg>
      </Arrow>
      <Datetime
        value={value}
        renderInput={props => {
          return (
            <div {...props}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 2H10V0H8V2H4V0H2V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3L0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V3C12 2.73478 11.8946 2.48043 11.7071 2.29289C11.5196 2.10536 11.2652 2 11 2ZM10 10H2V5H10V10Z"
                  fill="#9199A3"
                />
              </svg>
              <span>
                {moment(startDate).format(DATE_FORMAT)} - {endDate.format(DATE_FORMAT)}
              </span>
            </div>
          );
        }}
        timeFormat={false}
        closeOnSelect={true}
        onChange={onSelectDate}
        renderDay={renderDay}
      />
      <Arrow right onClick={() => onArrowClick(1)}>
        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.333282 0.333224L0.333282 7.66656C0.333307 7.72727 0.34991 7.78681 0.3813 7.83878C0.412689 7.89074 0.457674 7.93314 0.511398 7.96141C0.565122 7.98968 0.625547 8.00274 0.68615 7.99918C0.746753 7.99563 0.805235 7.97558 0.855282 7.94122L6.18862 4.27456C6.23317 4.24392 6.2696 4.20291 6.29477 4.15506C6.31993 4.10721 6.33309 4.05396 6.33309 3.99989C6.33309 3.94582 6.31993 3.89257 6.29477 3.84472C6.2696 3.79687 6.23317 3.75586 6.18862 3.72522L0.855282 0.0585575C0.805234 0.0241966 0.746753 0.00415562 0.68615 0.000597938C0.625546 -0.00295926 0.565122 0.0101013 0.511398 0.0383701C0.457673 0.0666385 0.412689 0.109042 0.381299 0.161005C0.34991 0.212967 0.333307 0.272516 0.333282 0.333224Z"
            fill="#6A7988"
          />
        </svg>
      </Arrow>
    </Container>
  );
}
