import styled from 'styled-components';
import { Radio } from 'semantic-ui-react';
import * as ModalS from '../style';
import { DECLINE_REQUEST_TYPE } from '../helper';

export const Wrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: ${({ type }) => (type === DECLINE_REQUEST_TYPE.INTROCALL ? '25px 24px 24px' : '25px 24px 40px 24px')};

  ${ModalS.Title} {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #151619;
    letter-spacing: -0.02em;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  font-size: 13px;
  line-height: 19.5px;
  font-weight: 400;
  color: #202353;
  margin-bottom: 14px;
`;

export const DeclineOptionsWrapper = styled.div`
  margin-top: 6px;
  margin-bottom: 14px;

  .title {
    font-size: 11px;
    font-weight: 600;
    line-height: 16.5px;
    color: #202353;
    margin-bottom: 14px;
  }
`;

export const DeclineOption = styled.div`
  height: 52px;
  cursor: pointer;
  padding: 16px;
  border-radius: 4px;
  border: ${({ isActive }) => (isActive ? '1px solid #5158CF' : '1px solid #E0E1E4')};
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DeclineOptionRadio = styled(Radio)`
  &.ui.radio.checkbox {
    label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
      color: #202353;
      padding-left: 31px;
      letter-spacing: 0.33px;

      &::before {
        border-width: 2px;
      }
    }

    input:checked {
      & ~ label {
        font-weight: 600;
        color: #5158cf;
        letter-spacing: 0.5px;
      }

      & ~ label:after {
        background-color: #5158cf !important;
      }

      & ~ label:before {
        border-color: #5158cf !important;
      }
    }
  }
`;

export const UndoTimeInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #4a4d56;
  }
`;
