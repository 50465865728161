import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

import ArrowIconURL from 'assets/icons/arrow_corner_icon.svg';

const textBase = `
    font-family: 'Open sans';
    font-style: normal;
    line-height: normal;
`;

export const Form = styled.form``;

export const ModalShare = styled(Modal)`
  &.ui.modal {
    width: 563px;
    height: initial;
    border-radius: 8px;
    box-sizing: border-box;

    > :last-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &.ui.modal > .content {
    line-height: unset !important;
  }

  &.recipe-share-owner .header {
    color: #000 !important;
    background: none !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding: 24px 24px 0px !important;
    border-radius: 0;
  }

  &.recipe-share-owner .content {
    background: none !important;
    padding: 20px 24px 24px !important;
    border-radius: 0;

    ${Form} {
      margin-bottom: 46px;

      .client-profile-label {
        ${textBase}
        color: #6a778a;
        font-size: 10px;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding-left: 0;
      }

      .field.client_type_field.share-with-org {
        margin-top: 10px;
      }

      .field > .label {
        margin-bottom: 5px;
      }

      .ownership-dropdown {
        display: flex;
        align-items: center;
        position: relative;
        min-height: auto;
        height: 36px;
        padding: 9px 15px;
        border-radius: 4px !important;
        border: 1px solid #dcdcde !important;

        &.dropdown .dropdown.icon::before {
          background-image: url(${CDN_URL}/images/dropdown-mark-down.svg) !important;
          width: 16px;
          height: 16px;
          top: 50%;
          position: absolute;
          transform: translateY(-40%);
          right: 11px;
          color: #000000;
        }

        .text {
          color: #323c47;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
        }

        .active .text {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.95);
        }

        .dropdown {
          &.icon {
            position: absolute;
            padding: 0;
            top: calc(50% + 7px);
            padding-right: 13px;

            &::before {
              content: '';
              display: block;
              width: 6.7px;
              height: 4.6px;
              background-image: url(${ArrowIconURL}) !important;
              background-size: contain !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
            }
          }
        }
      }
    }
  }

  .formControl {
    margin-bottom: 0;

    > div {
      color: #9a9da6;
      font-weight: 700;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.01em;
      margin-bottom: 1px;

      .label__maxLength {
        color: #6a778a;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .cancel,
  .submit {
    min-width: 80px;
  }

  .submit {
    padding: 10px 27px !important;
    width: 100px;

    &:hover,
    &:active,
    &:focus {
      background: #5559ff;
    }
  }

  .cancel {
    width: 102px;
    height: 42px;
    color: #323c47;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 30px !important;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  .cancel {
    margin-right: 12px;
  }
`;
