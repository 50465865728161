import styled, { css } from 'styled-components';

export const CopyInput = styled.input`
  outline: none;
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #91939a;

  ::selection {
    color: #91939a;
    background-color: transparent;
  }
`;

export const CopyButton = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #5158cf;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

export const CopyArea = styled.div`
  padding: 11px 14px;
  background: #fafbfc;
  border: 1px solid #dcdcde;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #91939a;
  overflow: hidden;

  .copy-icon {
    flex: 0 0 14px;
    margin-left: 15px;
  }

  :hover {
    background: #ffffff;
    border: 1px solid #5158cf;
    cursor: pointer;

    ${CopyButton} {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 30px;
  text-align: center;

  .icon {
    display: flex;
    justify-content: center;
    min-height: 51px;
    margin-bottom: 30px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    margin: 0 0 15px;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    color: #000000;
    margin: 0 0 35px;
  }

  button {
    &.button--assign-first-workout {
      display: flex;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      padding: 12px;

      span {
        margin-right: 12px;
      }
    }

    &.button--open-dashboard {
      padding: 12px 55px;
    }
  }
`;
