// Libs
import React, { useMemo } from 'react';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';
import { Doughnut } from 'react-chartjs-2';

// Constants
import { listMacronutrients, MACRO_NUTRIENTS } from '../constants';

// Components
import UnitInput from '../UnitInput';

// Helper
import { calculateNutritionValues, calculateNutritionChartValues, handleEmptyValue } from '../helper';

// Assets
import { ReactComponent as IconMacroChart } from 'assets/icons/MealPlans/nutrition_chart_icon.svg';

// Style
import * as S from './style';

const chartOptions = {
  responsive: true,
  cutoutPercentage: 70,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    duration: 0,
  },
};

const roundToDecimalPlaces = (number, decimalPlaces) => {
  const factor = 10 ** decimalPlaces;
  return Math.round(number * factor) / factor;
};

const MacroNutrient = ({ MacroNutrients = [], onChange, showMacroSection = true, disabledInput = false }) => {
  const valueNutrition = useMemo(() => calculateNutritionValues(MacroNutrients), [MacroNutrients]);
  const valueNutritionChart = useMemo(() => calculateNutritionChartValues(MacroNutrients), [MacroNutrients]);
  const isEmptyCalories = valueNutrition.protein === '' && valueNutrition.carbs === '' && valueNutrition.fat === '';
  const percentProtein = get(valueNutritionChart, 'protein.percent');
  const percentCarbs = get(valueNutritionChart, 'carbs.percent');
  const percentFat = get(valueNutritionChart, 'fat.percent');

  const valueProtein = get(valueNutritionChart, 'protein.value');
  const valueCarbs = get(valueNutritionChart, 'carbs.value');
  const valueFat = get(valueNutritionChart, 'fat.value');
  const valueCalories = get(valueNutritionChart, 'calories.value');

  const blankChart = !percentProtein && !percentCarbs && !percentFat;

  const data = {
    datasets: [
      {
        data: !blankChart ? [percentProtein, percentCarbs, percentFat] : [100],
        backgroundColor: !blankChart ? ['#57B5EA', '#65CC9C', '#FFBE49'] : ['#A3A3B5'],
        hoverBackgroundColor: !blankChart ? ['#57B5EA', '#65CC9C', '#FFBE49'] : ['#A3A3B5'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      {listMacronutrients.map(item => {
        const { type, label, unit } = item;

        if (type === MACRO_NUTRIENTS.CALORIES) {
          return (
            <S.Protein key={type} data-tip data-for="tooltip-calories">
              <UnitInput
                label={label}
                unit={unit}
                value={!isEmptyCalories ? roundToDecimalPlaces(valueNutrition[MACRO_NUTRIENTS.CALORIES], 2) : ''}
                onChange={e => onChange(e, type)}
                disabled
              />
              <ReactTooltip id="tooltip-calories" effect="solid" place="top">
                Calories auto-calculated from Protein, Carbs, Fat input
              </ReactTooltip>
            </S.Protein>
          );
        }
        return (
          <S.Item className="item-macro-nutrient" key={type}>
            <UnitInput
              label={label}
              unit={unit}
              defaultValue={valueNutrition[type]}
              onChange={e => onChange(e, type)}
              disabled={disabledInput}
            />
          </S.Item>
        );
      })}
      {showMacroSection && (
        <S.MacroSection>
          <S.MacroList isEmptyValue={blankChart}>
            <S.MacroItem status="protein" isBlankData={valueProtein <= 0}>
              Protein{' '}
              <span className="percent">
                ({percentProtein}
                <span className="percentage">%</span>)
              </span>
              <S.ValueMacroItem>
                {handleEmptyValue(valueProtein)}
                <S.ValueMacroUnit>&nbsp;g</S.ValueMacroUnit>
              </S.ValueMacroItem>
            </S.MacroItem>
            <S.MacroItem status="carbs" isBlankData={valueCarbs <= 0}>
              Carbs{' '}
              <span className="percent">
                ({percentCarbs}
                <span className="percentage">%</span>)
              </span>
              <S.ValueMacroItem>
                {handleEmptyValue(valueCarbs)}
                <S.ValueMacroUnit>&nbsp;g</S.ValueMacroUnit>
              </S.ValueMacroItem>
            </S.MacroItem>
            <S.MacroItem status="fat" isBlankData={valueFat <= 0}>
              Fat{' '}
              <span className="percent">
                ({percentFat}
                <span className="percentage">%</span>)
              </span>
              <S.ValueMacroItem>
                {handleEmptyValue(valueFat)}
                <S.ValueMacroUnit>&nbsp;g</S.ValueMacroUnit>
              </S.ValueMacroItem>
            </S.MacroItem>
          </S.MacroList>
          <S.MacroMain>
            <S.MacroChart>
              <Doughnut data={data} width="100%" height="100%" options={chartOptions} />
              <IconMacroChart className={`macro-chart-icon ${blankChart ? 'blank-data' : ''}`} />
            </S.MacroChart>
            <S.CaloriesContent isEmptyValue={blankChart}>
              <S.CaloriesValue>
                {handleEmptyValue(roundToDecimalPlaces(valueCalories, 2))}
                <S.CaloriesUnit isEmptyValue={valueCalories <= 0}>&nbsp;cal</S.CaloriesUnit>
              </S.CaloriesValue>
              <S.CaloriesName>Calories</S.CaloriesName>
            </S.CaloriesContent>
          </S.MacroMain>
        </S.MacroSection>
      )}
    </>
  );
};

export default MacroNutrient;
