import React, { useMemo } from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { TRIAL_MODE, PLANS } from 'constants/commonData';
import { getSubTotalBaseOnPlan } from '../helper';
import diff from 'deep-diff';

const Button = styled.button`
  background: linear-gradient(76.71deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  padding: 12px 30px;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ffffff;

  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }

  ${props =>
    props.downgrade &&
    css`
      background: #dadfea;
      color: #5c6881;

      :hover {
        background: #c3cbdd;
      }
    `}

  :disabled {
    border: 1px solid #d0d4ed;
    background: #fff;
    cursor: not-allowed;
    color: #afb4cf;

    /* span {
      color: #AFB4CF;
    } */
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const checkChangePlan = (teamData, selectPackage) => {
  const currentPlan = _.pick(teamData, ['period', 'packageNoClients', 'addOns', 'tier', 'zapier_quantity']);
  const selectPlan = _.pick(selectPackage, ['period', 'packageNoClients', 'addOns', 'tier', 'zapier_quantity']);
  const changes = diff(currentPlan, { ...selectPlan, packageNoClients: selectPackage.packagePrice.packageNoClients });
  return !changes;
};

function UpgradeButton({ selectPackage, teamData, addOnsPricing, planPackage, ...props }) {
  const buttonProps = useMemo(() => {
    const btnProps = {
      text: 'Upgrade',
      downgrade: false,
      disabled: false,
    };
    const noWarningClients = teamData.noClients - _.get(selectPackage, 'packagePrice.packageNoClients', 0);
    btnProps.disabled = noWarningClients > 0;

    switch (teamData.trialMode) {
      case TRIAL_MODE.Active: {
        btnProps.downgrade = selectPackage.tier === 'starter';
        btnProps.text = btnProps.downgrade ? 'Downgrade' : 'Next';
        btnProps.disabled = noWarningClients > 0;
        break;
      }
      case TRIAL_MODE.Grace: {
        btnProps.downgrade = selectPackage.tier === 'starter';
        btnProps.disabled = noWarningClients > 0;
        btnProps.text = selectPackage.tier === 'starter' ? 'Stay with Starter Plan' : 'Next';
        break;
      }
      case TRIAL_MODE.Expired: {
        const isFirstTimeAddZapier = !_.has(teamData, 'zapier_quantity');
        const selectedPackage = isFirstTimeAddZapier ? _.omit(selectPackage, 'zapier_quantity') : selectPackage;
        const isNoChange = checkChangePlan(teamData, selectedPackage);

        const currentSubtotal = getSubTotalBaseOnPlan(selectPackage, planPackage, addOnsPricing);
        const oldSubtotal = getSubTotalBaseOnPlan(teamData, planPackage, addOnsPricing);
        const isDowngradeZapier = _.get(selectPackage, 'zapier_quantity', 0) <= _.get(teamData, 'zapier_quantity', 0);
        const isRemovedZapier =
          _.get(teamData, 'addOns.zapier', false) === true && _.get(selectPackage, 'addOns.zapier', false) === false;
        // Second case below is to check Zapier changed and update base plan
        btnProps.downgrade =
          (teamData.tier !== PLANS.starter.key && currentSubtotal < oldSubtotal) ||
          (currentSubtotal <= oldSubtotal && (isDowngradeZapier || isRemovedZapier));
        btnProps.text = btnProps.downgrade ? (selectPackage.tier === 'starter' ? 'Downgrade' : 'Next') : 'Next';
        btnProps.disabled = (isNoChange && !props.canDowngradeToStarter) || noWarningClients > 0;

        if (teamData.tier === PLANS.starter.key && teamData.tier === selectPackage.tier) {
          btnProps.text = 'Stay with Starter Plan';
        }

        break;
      }
      default:
        break;
    }
    return btnProps;
  }, [selectPackage, teamData]);

  return (
    <Button
      // {...buttonProps}
      text={buttonProps.text}
      downgrade={buttonProps.downgrade}
      disabled={buttonProps.disabled || buttonProps.disabled}
      onClick={() => props.onClick(buttonProps)}
    >
      <span>{buttonProps.text}</span>
    </Button>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    planPackage: rootReducer.pricing.get('planPackage').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    canDowngradeToStarter: rootReducer.pricing.get('canDowngradeToStarter'),
  };
};

const actionCreators = {
  push,
};

export default connect(mapState, actionCreators)(UpgradeButton);
