import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Shared
import S3Images from 'shared/S3Images';

// Assets
import { ReactComponent as Trailing } from 'assets/icons/MealPlans/trailing_icon.svg';

import * as S from './style';

const AccordionItem = ({ recipes, id, placeholderProps }) => {
  return (
    <Droppable droppableId={id} type="droppableRecipes">
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="droppable-context-meal-plan-day-detail">
          {recipes &&
            recipes.map((recipe, index) => {
              const _id = get(recipe, 'recipe._id', '');

              return (
                <Draggable key={_id} draggableId={_id} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <S.AccordionItemWrapper key={_id} isDragging={snapshot.isDragging}>
                        <S.AccordionItemImage>
                          <S3Images
                            src={[get(recipe, 'recipe.cover_image_thumbnail'), get(recipe, 'recipe.cover_image')]}
                          />
                        </S.AccordionItemImage>
                        <S.AccordionItemTitle>{get(recipe, 'recipe.name', '')}</S.AccordionItemTitle>
                        <S.AccordionItemIcon>
                          <Trailing className="trailing-item" />
                        </S.AccordionItemIcon>
                      </S.AccordionItemWrapper>
                    </div>
                  )}
                </Draggable>
              );
            })}
          {provided.placeholder}
          {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
            <S.Placeholder
              style={{
                top: placeholderProps.clientY,
                left: placeholderProps.clientX,
                height: placeholderProps.clientHeight,
                width: placeholderProps.clientWidth,
              }}
            />
          )}
        </div>
      )}
    </Droppable>
  );
};

export default AccordionItem;
