import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import CustomBranding from './component';
import {
  getCustomBrandingData,
  deleteWorkoutBackground,
  revokeLocalURL,
  addWorkoutBackground,
} from 'redux/custom-branding/actions';
import { toggleConfirmModal } from 'actions/modal';
import { maximizeCheckList, updateStepOnboarding } from 'redux/onboarding/actions';
import { get } from 'lodash';

const mapState = state => ({
  workoutBackgrounds: state.rootReducer.customBranding.workoutBackgrounds,
  user: state.user,
  cloudfrontList: state.cloudfrontList,
  sideBarVisible: state.sideBarVisible,
  onboarding: state.rootReducer.onboarding,
  premiumCustomBrand: state.rootReducer.permission.premiumCustomBrand,
  isEditMode: get(state, 'rootReducer.studioProgram.workingStudio.isEditMode', true),
  isPublished: get(state, 'rootReducer.studioProgram.workingStudio.isPublished', false),
  selectedWorkoutBackground: state.rootReducer.customBranding.selectedWorkoutBackground,
});

const mapDispatch = dispatch => ({
  addWorkoutBackground: bindActionCreators(addWorkoutBackground, dispatch),
  deleteWorkoutBackground: bindActionCreators(deleteWorkoutBackground, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
  revokeLocalURL: bindActionCreators(revokeLocalURL, dispatch),
  updateStepOnboarding: bindActionCreators(updateStepOnboarding, dispatch),
  maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(mapState, mapDispatch)(CustomBranding);
