import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as S from './style';
import { STUDIO_PROGRAM_STATUS } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';
import DropdownStudioItem from '../DropdownStudioItem/index';

const getTimeStatus = (studioProgram, selectedClient) => {
  let prefix = 'Added';
  let time = studioProgram.createdAt;
  switch (studioProgram.status) {
    case STUDIO_PROGRAM_STATUS.Active:
      prefix = 'Started';
      time = studioProgram.started_at;
      break;
    case STUDIO_PROGRAM_STATUS.Paused:
      prefix = 'Paused';
      time = studioProgram.paused_at;
      break;
    case STUDIO_PROGRAM_STATUS.Completed:
      prefix = 'Completed';
      time = studioProgram.completed_at
        ? moment(studioProgram.completed_at)
        : moment(studioProgram.started_at).add(studioProgram.number_of_weeks, 'week');
      break;
    default:
      break;
  }
  return (
    <>
      <b>{prefix}</b> on {momentTz.tz(time, selectedClient.timezone).format('MMM DD, YYYY')}
    </>
  );
};

function ClientStudioItem(props) {
  const { studioProgram, selectedClient } = props;

  return (
    <S.Wrapper className="clientStudio__wrapper" onClick={() => props.onGoDetail(studioProgram.studio_program)}>
      <S.CoverPhotoContainer coverImage={studioProgram.cover_image}>
        <S.CompactDataContainer>
          <S.CompactInformationItem>
            {pluralize('Week', studioProgram.number_of_weeks, true)}
            &nbsp;-&nbsp;
            {pluralize('Workout', studioProgram.number_of_workouts || 0, true)}
          </S.CompactInformationItem>
        </S.CompactDataContainer>
      </S.CoverPhotoContainer>
      <S.StudioProgramBaseInformation>
        <div className="studio-program__name">
          {studioProgram.status === STUDIO_PROGRAM_STATUS.Completed && <S.CompletedIcon />}
          {studioProgram.program_name}
        </div>
        <div className="studio-program__description">{studioProgram.description}</div>
        <div className="studio-program__timeStatus">{getTimeStatus(studioProgram, selectedClient)}</div>
      </S.StudioProgramBaseInformation>
      {studioProgram.status !== STUDIO_PROGRAM_STATUS.Completed && (
        <S.ProcessProgram
          className={`progress__${studioProgram.status}`}
          value={studioProgram.number_of_weeks_completed}
          max={studioProgram.number_of_weeks}
        />
      )}
      {studioProgram.status === STUDIO_PROGRAM_STATUS.Active && <S.ActiveCheck>Active</S.ActiveCheck>}
      <DropdownStudioItem studioProgram={studioProgram} onRemove={props.onRemove} />
    </S.Wrapper>
  );
}

export default ClientStudioItem;
