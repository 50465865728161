// Lib
import React, { useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import ReactTooltip from 'react-tooltip';

// Share
import ContentList from './ContentList';

// Constanst
import { DISPLAY_CART, DISPLAY_LIST } from 'components/MealPlanDayDetail/constants';
import { PER_PAGE } from 'components/MealPlanDetail/constants';

// Assets
import { ReactComponent as ShowCardIcon } from 'assets/icons/show-card.svg';
import { ReactComponent as ShowListIcon } from 'assets/icons/show-row.svg';

import * as S from './style';

const ContentTab = props => {
  const { total, changeQueryParams, filters, loading, filterNumber } = props;
  const { text_search = '' } = filters || {};

  const [displayType, setDisplayType] = useState(DISPLAY_CART);

  const handleLoadMore = event => {
    const scrollHeight = get(event, 'target.scrollHeight', 0);
    const scrollTop = get(event, 'target.scrollTop', 0);
    const clientHeight = get(event, 'target.clientHeight', 0);

    const bottom = scrollHeight - scrollTop <= clientHeight + 10;

    const isEnd = get(filters, 'page') >= Math.ceil(total / PER_PAGE);
    if (bottom && !loading && !isEnd) {
      const nextPage = get(filters, 'page') + 1;

      changeQueryParams && changeQueryParams({ page: nextPage });
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  return (
    <S.ContentTabWrapper onScroll={handleScroll}>
      <S.ContentTabHeader className={displayType}>
        <S.HeaderTitle>
          {text_search || filterNumber > 0 ? 'Results' : 'Recipes'} ({total || 0})
        </S.HeaderTitle>
        <S.HeaderShowList>
          <S.HeaderShowIcon
            onClick={() => setDisplayType(DISPLAY_LIST)}
            className={classNames({ selected: displayType === DISPLAY_LIST })}
          >
            <ShowListIcon data-for="list-view" data-tip className="show-list" />
            <ReactTooltip className="display-view-tooltip show-list-tooltip" id="list-view" effect="solid" place="top">
              <div>List view</div>
            </ReactTooltip>
          </S.HeaderShowIcon>
          <S.HeaderShowIcon
            onClick={() => setDisplayType(DISPLAY_CART)}
            className={classNames({ selected: displayType === DISPLAY_CART })}
          >
            <ShowCardIcon data-for="gallery-view" data-tip className="show-gallery" />
            <ReactTooltip
              className="display-view-tooltip show-gallery-tooltip"
              id="gallery-view"
              effect="solid"
              place="top"
            >
              <div>Gallery view</div>
            </ReactTooltip>
          </S.HeaderShowIcon>
        </S.HeaderShowList>
      </S.ContentTabHeader>
      <ContentList {...props} displayType={displayType} />
    </S.ContentTabWrapper>
  );
};

export default ContentTab;
