import styled from 'styled-components';

export const UpgradePathIconButton = styled.div`
  &.upgrade-path-icon-button {
    background: unset;
    border: unset;
    padding: unset;
    margin: unset;
    width: 20px;
    height: 20px;
    cursor: pointer;

    > button {
      display: inline-flex;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: unset;
      padding: unset;
      background: linear-gradient(96.75deg, #fdc830 3.96%, #f37335 91.53%);
    }

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      img {
        opacity: 0.8;
      }
    }

    &.offset-right {
      .upgrade-path-icon-tooltip.__react_component_tooltip {
        &::after {
          left: unset;
          right: 12px;
        }
      }
    }

    .upgrade-path-icon-tooltip.__react_component_tooltip {
      width: fit-content;
      padding: 15px 15px !important;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      background: #2d2e2d;
      text-align: left;
      border-radius: 5px;
      white-space: pre-line;
      word-break: break-word;
      max-width: 228px;
      text-align: center;

      &.place-top {
        margin-top: -8px;

        &:after {
          border-top-color: #2d2e2d;
          bottom: -8px !important;
          border-top-width: 8px;
        }
      }
    }
  }
`;
