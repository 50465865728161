// Libs
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Image, Radio } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import diff from 'deep-diff';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';

// Shared
import { Toggle } from 'shared/FormControl';
import UpgradePath from 'shared/UpgradePath';
import PremiumButton from 'shared/UpgradePath/components/PremiumButton';
import UpgradePathTooltipContent from 'shared/UpgradePath/components/UpgradePathTooltipContent';
import ConfirmModal from 'shared/ConfirmModal';
import { Checkbox } from 'shared/FormControl';
import { Mixpanel } from 'utils/mixplanel';

// Actions
import { toggleConfirmModal } from 'actions/modal';

// Utils
import useStateCallback from 'hooks/useStateCallback';
import WarningPopup from './WarningPopup';
import { SIDEBAR_ID } from 'components/ClientSettings/constants';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as LearnMoreIcon } from 'assets/icons/file-document.svg';

// Styles
import { SaveButton, Left, Right, Center, SettingName } from '../style';
import * as S from './style';

const MEAL_PLAN_DEPEND_ON_TYPE = {
  CLIENT_REPLACE_RECIPE: 'allow_client_to_replace_recipes',
  PUBLIC_LIB_OF_EVERFIT: 'public_library_of_everfit',
  PUBLIC_LIB_OF_COACH: 'public_library_of_the_coach',
  ALL_OF_YOUR_RECIPES: 'all_of_your_recipes',
  ALL_SYSTEM_RECIPES: 'all_system_recipes',
  MEAL_TITLE: 'meal_plan_title',
  MEAL_GUIDANCE: 'meal_guidance_title',
  DECIMAL_POINT: 'decimal_point',
  DECIMAL_COMMA: 'decimal_comma',
};

const MEAL_PLAN_CHECKBOX = [
  MEAL_PLAN_DEPEND_ON_TYPE.CLIENT_REPLACE_RECIPE,
  MEAL_PLAN_DEPEND_ON_TYPE.PUBLIC_LIB_OF_EVERFIT,
  MEAL_PLAN_DEPEND_ON_TYPE.PUBLIC_LIB_OF_COACH,
  MEAL_PLAN_DEPEND_ON_TYPE.ALL_OF_YOUR_RECIPES,
  MEAL_PLAN_DEPEND_ON_TYPE.ALL_SYSTEM_RECIPES,
];

const FEATURES = [
  { type: 'training', upgradePath: '', isCoreFeature: true },
  { type: 'workout_comment', upgradePath: '', dependOn: { type: 'training', value: true } },
  { type: 'show_rest_day', upgradePath: '', dependOn: { type: 'training', value: true } },
  { type: 'log_activities', upgradePath: '', isCoreFeature: true },
  { type: 'log_activity_comment', upgradePath: '', dependOn: { type: 'log_activities', value: true } },
  { type: 'task', upgradePath: '', isCoreFeature: true },
  { type: 'food_journal', upgradePath: 'food_journal', isCoreFeature: true },
  { type: 'macro', upgradePath: 'macro', isCoreFeature: true },
  { type: 'macro_allow_client', upgradePath: 'macro', dependOn: { type: 'macro', value: true } },
  { type: 'meal_plan', upgradePath: 'recipe', isCoreFeature: true },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.CLIENT_REPLACE_RECIPE,
    upgradePath: 'meal_plan',
    dependOn: { type: 'meal_plan', value: true },
  },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.ALL_OF_YOUR_RECIPES,
    upgradePath: 'meal_plan',
    dependOn: { type: 'meal_plan', value: true },
  },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.ALL_SYSTEM_RECIPES,
    upgradePath: 'meal_plan',
    dependOn: { type: 'meal_plan', value: true },
  },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.MEAL_TITLE,
    upgradePath: 'recipe',
    dependOn: {
      type: 'meal_plan',
      value: true,
      label: 'Show on mobile app for client',
    },
  },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.MEAL_GUIDANCE,
    upgradePath: 'recipe',
    dependOn: { type: 'meal_plan', value: true },
  },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.DECIMAL_POINT,
    upgradePath: '',
    dependOn: { type: 'meal_plan', value: true, label: 'Decimal separator' },
  },
  {
    type: MEAL_PLAN_DEPEND_ON_TYPE.DECIMAL_COMMA,
    upgradePath: '',
    dependOn: { type: 'meal_plan', value: true },
  },
  { type: 'inbox', upgradePath: '' },
  { type: 'inbox_allow_voice_messages', upgradePath: '', dependOn: { type: 'inbox', value: true } },
  { type: 'progress_photo', upgradePath: '' },
  { type: 'body_metric', upgradePath: '' },
];

function Features(props) {
  const {
    originSettings,
    showMetricSetting,
    header,
    dispatch,
    permission,
    onFuturesChanged,
    isAllChangeSetting = [],
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [features, setFeatures] = useState(props.originSettings.slice());
  const [isChanged, setIsChanged] = useStateCallback(false);
  const shouldAllChangeSetting = isAllChangeSetting.includes(true);
  const isShowSaveButton = useMemo(() => {
    return !!features.length && diff(originSettings, features);
  }, [features]);

  useEffect(() => {
    setIsChanged();
    if (!!features.length && diff(originSettings, features)) {
      setIsChanged(true);
      onFuturesChanged && onFuturesChanged(true);
    } else {
      setIsChanged(false);
      onFuturesChanged && onFuturesChanged(false);
    }
  }, [features]);

  useEffect(() => {
    setFeatures(props.originSettings.slice());
  }, [props.originSettings]);

  useEffect(() => {
    isShowSaveButton && setIsChanged(shouldAllChangeSetting);
  }, [shouldAllChangeSetting]);

  const trackingTrainingSetting = (newSettings, originSettings) => {
    const originTrainingSetting = originSettings.find(setting => setting.type === 'training');
    const newTrainingSetting = newSettings.find(setting => setting.type === 'training');
    if (!originTrainingSetting || !newTrainingSetting) return;
    if (originTrainingSetting.state && !newTrainingSetting.state) {
      Mixpanel.track('training_setting_turn_off');
    }
    if (!originTrainingSetting.state && newTrainingSetting.state) {
      Mixpanel.track('training_setting_turn_on');
    }
  };

  const onSave = () => {
    if (isSubmitting) {
      return false;
    }

    const data = { types: _.map(features, item => ({ type: item.type, state: item.state })) };
    const isNotError = data.types.find(
      type => type.state && !!FEATURES.find(feature => feature.type == type.type && feature.isCoreFeature),
    );
    if (!isNotError) return dispatch(toggleConfirmModal(true, <WarningPopup />));

    trackingTrainingSetting(data.types, originSettings);

    setIsSubmitting(true);
    props.onSave(data).finally(() => setIsSubmitting(false));
  };

  const onChangeSwitch = (obj, pathName) => {
    if (pathName && !props.permission[pathName]) {
      return;
    }

    if (obj.type === MEAL_PLAN_DEPEND_ON_TYPE.CLIENT_REPLACE_RECIPE) {
      const newEdit = features.map(mItm => ({
        ...mItm,
        state: mItm._id === obj._id ? !mItm.state : mItm.state,
      }));

      setFeatures(newEdit);
      if (!obj.state) {
        const newPubliCLibrary = newEdit.map(mItm => {
          return {
            ...mItm,
            state:
              mItm.type === MEAL_PLAN_DEPEND_ON_TYPE.PUBLIC_LIB_OF_COACH ||
              mItm.type === MEAL_PLAN_DEPEND_ON_TYPE.PUBLIC_LIB_OF_EVERFIT ||
              mItm.state,
          };
        });
        setFeatures(newPubliCLibrary);
      }
      return;
    }

    const newEdit = features.map(mItm => ({
      ...mItm,
      state: mItm._id === obj._id ? !mItm.state : mItm.state,
    }));
    setFeatures(newEdit);
  };

  const bodyMetricSetting = _.find(features, item => item.type === 'body_metric');

  const handleDiscardChange = nextLocation => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          setIsChanged(false, () => props.push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
        hasHoverState
      />,
    );
    return false;
  };

  let FEATURES_ENABLE = FEATURES;
  if (!permission.log_activity || !process.env.REACT_APP_ENABLE_LOG_ACTIVITY) {
    FEATURES_ENABLE = FEATURES.filter(it => it.type !== 'log_activities');
  }
  if (!permission.voice_message) {
    FEATURES_ENABLE = FEATURES.filter(it => it.type !== 'inbox_allow_voice_messages');
  }

  const changeRadioState = (obj, type) => {
    const mealPlanTitleEdit = features.map(item => {
      return {
        ...item,
        state: item._id === obj._id ? !item.state : item.type === type ? false : item.state,
      };
    });
    setFeatures(mealPlanTitleEdit);
  };

  const onChangeRadioSwitch = (obj, pathName) => {
    if (pathName && !props.permission[pathName]) {
      return;
    }

    switch (obj.type) {
      case MEAL_PLAN_DEPEND_ON_TYPE.MEAL_TITLE:
        changeRadioState(obj, MEAL_PLAN_DEPEND_ON_TYPE.MEAL_GUIDANCE);
        break;
      case MEAL_PLAN_DEPEND_ON_TYPE.MEAL_GUIDANCE:
        changeRadioState(obj, MEAL_PLAN_DEPEND_ON_TYPE.MEAL_TITLE);
        break;
      case MEAL_PLAN_DEPEND_ON_TYPE.DECIMAL_POINT:
        changeRadioState(obj, MEAL_PLAN_DEPEND_ON_TYPE.DECIMAL_COMMA);
        break;
      case MEAL_PLAN_DEPEND_ON_TYPE.DECIMAL_COMMA:
        changeRadioState(obj, MEAL_PLAN_DEPEND_ON_TYPE.DECIMAL_POINT);
        break;

      default:
        break;
    }
  };

  const renderMealPlanSetting = (obj, type, upgradePath, dependOn, shouldHide = false) => {
    if (MEAL_PLAN_CHECKBOX.includes(type)) {
      const isSub = obj.type !== MEAL_PLAN_DEPEND_ON_TYPE.CLIENT_REPLACE_RECIPE;
      const replaceRecipe = _.find(features, item => item.type === MEAL_PLAN_DEPEND_ON_TYPE.CLIENT_REPLACE_RECIPE);
      const filteredSubOption = _.filter(
        features,
        item =>
          (item.type === MEAL_PLAN_DEPEND_ON_TYPE.ALL_OF_YOUR_RECIPES ||
            item.type === MEAL_PLAN_DEPEND_ON_TYPE.ALL_SYSTEM_RECIPES) &&
          item.state,
      );

      const subLeastOne = filteredSubOption.length === 1;

      const activeReplaceRecipe = replaceRecipe.state;
      if (isSub && !activeReplaceRecipe) {
        return null;
      }

      const disableCheckbox = isSub && subLeastOne && obj.state;
      const lastElement = obj.type === MEAL_PLAN_DEPEND_ON_TYPE.ALL_SYSTEM_RECIPES;

      const onSelect = () => {
        !disableCheckbox && onChangeSwitch(obj, upgradePath);
      };

      return (
        <S.Feature
          shouldHide={shouldHide}
          key={type}
          className={`feature${!obj.state ? ' disabled' : ''} meal-plan-checkbox ${lastElement && 'last-element'}`}
          disabled={!obj.state || (!!upgradePath && !props.permission[upgradePath])}
          childFeature
        >
          <div style={{ width: isSub ? '70px' : '42px' }}></div>
          <div className={`checkbox-container ${disableCheckbox && 'sub-option-least-one'}`}>
            <div data-tip data-for={disableCheckbox && 'meal-plan-setting-tooltip'}>
              <Checkbox checked={obj.state} size={18} onChange={onSelect} />
            </div>
            <S.FeatureCheckboxText className="meal-plan-checkbox__label">{obj.title}</S.FeatureCheckboxText>
          </div>
          {disableCheckbox && (
            <ReactTooltip
              className="app-tooltip meal-plan-setting-tooltip"
              id="meal-plan-setting-tooltip"
              effect="solid"
              place={'top'}
            >
              <p>Choose at least 1 option</p>
            </ReactTooltip>
          )}
        </S.Feature>
      );
    }

    return (
      <S.Feature key={type} className="meal-plan-child-setting" childFeature checked={obj.state}>
        {dependOn.label && <div className="meal-plan-child-setting__label">{dependOn.label}</div>}
        <div>
          <div className="checkbox-container">
            <Radio
              checked={obj.state}
              size={18}
              onChange={() => {
                onChangeRadioSwitch(obj, upgradePath);
              }}
            />
            <S.FeatureCheckboxText className="radio-label" checked={obj.state}>
              {obj.title}
            </S.FeatureCheckboxText>
          </div>
        </div>
      </S.Feature>
    );
  };

  return (
    <S.Wrapper className="settings__features" id={SIDEBAR_ID.features}>
      <Prompt when={!!isChanged} message={handleDiscardChange} />
      <Left className="left">
        <SettingName>
          <span>Features</span>
        </SettingName>
      </Left>
      <Center className="center">
        <S.Features className="features">
          {header || null}
          {_.map(FEATURES_ENABLE, ({ type, upgradePath, dependOn, index: typeIndex }) => {
            const obj = _.find(features, item => item.type === type);
            const isChild = _.get(obj, 'parent', false);
            const isMealPlan = _.get(dependOn, 'type', '') === 'meal_plan';
            const isLearnMore = _.get(obj, 'type', '') === 'show_rest_day' ? true : false;
            let shouldHide = false;
            const isRecipeUpgradePath = !!upgradePath && upgradePath === 'recipe';

            if (isChild) {
              const dependedOnObj = _.find(features, item => item._id === isChild);
              // if cannot get the state, the comparision returns true, default to hide
              shouldHide = _.get(dependedOnObj, 'state', false) === false;
              shouldHide = shouldHide || (!!upgradePath && !props.permission[upgradePath]);
            }

            if (!obj) {
              return null;
            }

            if (isMealPlan && isChild && !shouldHide) {
              return renderMealPlanSetting(obj, type, upgradePath, dependOn, shouldHide);
            }

            if (isChild) {
              if (type === 'log_activity_comment' && !permission.log_activity) return null;
              return (
                <S.Feature
                  shouldHide={shouldHide}
                  key={type}
                  className={`feature${!obj.state ? ' disabled' : ''}`}
                  disabled={!obj.state || (!!upgradePath && !props.permission[upgradePath])}
                  childFeature
                >
                  <div style={{ width: '42px' }}></div>
                  <div className="checkbox-container">
                    <Checkbox
                      checked={obj.state}
                      size={18}
                      onChange={() => {
                        onChangeSwitch(obj, upgradePath);
                      }}
                    />
                    <S.FeatureCheckboxText>
                      {obj.title}
                      {isLearnMore && (
                        <S.FeatureLearnMore
                          href="https://help.everfit.io/en/articles/3581190-client-app-today"
                          target="_blank"
                        >
                          <LearnMoreIcon />
                          <S.FeatureLinkTitle>Learn more</S.FeatureLinkTitle>
                        </S.FeatureLearnMore>
                      )}
                    </S.FeatureCheckboxText>
                  </div>
                </S.Feature>
              );
            }

            return (
              <S.Feature
                key={type}
                className={`feature${!obj.state ? ' disabled' : ''}`}
                disabled={!obj.state || (!!upgradePath && !props.permission[upgradePath])}
              >
                <S.ImageContainer>
                  <Image src={`${CDN_URL}/images/features_new_${type}.svg`} />
                </S.ImageContainer>
                <div className="info">
                  <S.FeatureName className="name">
                    <span>{obj.title}</span>
                    <UpgradePath pathName={upgradePath} fallback={<PremiumButton />} />
                  </S.FeatureName>
                  <S.FeatureDescription className="description">
                    <span>{obj.description}</span>
                  </S.FeatureDescription>
                  {showMetricSetting && type === 'body_metric' && _.get(bodyMetricSetting, 'state') ? (
                    <div className="metrics-setting" onClick={props.onClickManageMetric}>
                      Manage Metrics
                    </div>
                  ) : null}
                </div>
                <Toggle
                  checked={upgradePath && !props.permission[upgradePath] ? false : obj.state}
                  onChange={() => {
                    onChangeSwitch(obj, upgradePath);
                  }}
                  className="features__toggle"
                  disabled={upgradePath && !props.permission[upgradePath]}
                  data-tip
                  data-for={`feat_${type}`}
                >
                  {upgradePath && !props.permission[upgradePath] ? (
                    <UpgradePathTooltipContent
                      heading={isRecipeUpgradePath ? 'Tailored Nutrition Plans' : ''}
                      description={
                        isRecipeUpgradePath
                          ? 'Design meal plans that align perfectly with your client’s macro goals and dietary preferences'
                          : ''
                      }
                      className={classNames('features__tooltipUpgradePath', {
                        'custom-upgrade-path': isRecipeUpgradePath,
                      })}
                      id={`feat_${type}`}
                    />
                  ) : null}
                </Toggle>
              </S.Feature>
            );
          })}
        </S.Features>
      </Center>
      <Right className="right">{isShowSaveButton ? <SaveButton onClick={onSave}>Save</SaveButton> : <span />}</Right>
    </S.Wrapper>
  );
}

Features.propTypes = {
  originSettings: PropTypes.array.isRequired,
  showMetricSetting: PropTypes.bool,
  onClickManageMetric: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  header: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(null, mapDispatchToProps)(Features);
