import axios from 'axios';
import axiosRetry from 'axios-retry';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'moment-timezone';
// import { datadogRum } from '@datadog/browser-rum';
// import { push } from 'connected-react-router';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import warning from 'assets/icons/warning_red_light.svg';
import { hideError, showError } from 'actions/error';
import {
  getAccessToken,
  getRefreshToken,
  saveToken,
  removeToken,
  requestLogInfo,
  mongoObjectId,
} from 'utils/commonFunction';
import { backToForms } from 'redux/form-details/actions';
import { toggleModal } from 'actions/modal';
import { X_APP_TYPE } from 'constants/commonData';

let refreshSubscribers = [];
let isRefreshingAccessToken = false;

const WHILE_URLS_RETRY = ['workout-ai/conversions', 'trainer/inviteBulk'];

const onRetry = (retryCount, error, requestConfig) => {
  if (requestConfig)
    requestConfig.headers = {
      ...requestConfig.headers,
      'x-retry': true,
      'x-retry-time': retryCount,
    };
  return;
};

const RETRY_CONFIG = {
  retries: 3,
  retryCondition: error => {
    const url = _.get(error, 'config.url', '');
    const errorStatus = _.get(error, 'response.status');
    const noneResponse = _.get(error, 'response');
    const notAllowRetry = WHILE_URLS_RETRY.some(itemUrl => url.includes(itemUrl));
    return notAllowRetry || errorStatus === 500 ? false : errorStatus > 500 || !noneResponse;
  },
  onRetry,
};

const ERROR_CODE_NETWORK = [502, 503, 504];

const RETRY_CONFIG_INSTANCE_WITHOUT_TOKEN = {
  retries: 3,
  retryCondition: error => {
    const errorStatus = _.get(error, 'response.status');
    const noneResponse = _.get(error, 'response');
    const isAllowRetry = ERROR_CODE_NETWORK.includes(errorStatus);

    return isAllowRetry || !noneResponse;
  },
  onRetry,
};

/* 
  Hacking special cases to retry refresh token
*/
const retryRefreshTokenWithAPIs = (originalRequest, status) => {
  const urls = ['api/payment']; // List of apis to retry refresh
  let counter = 0;
  urls.forEach(url => {
    if (_.get(originalRequest, 'url', '').indexOf(url) !== -1 && status === 403) {
      counter += 1;
    }
  });
  return counter;
};

function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

function onTokenRefreshed(data) {
  saveToken(data, localStorage.getItem('access-token'));
  _.forEach(refreshSubscribers, callback => {
    callback(data.token);
  });
  refreshSubscribers = [];
}

const instanceWithoutRefreshToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  headers: { agent: 'react', timezone: moment.tz.guess(), 'x-app-type': X_APP_TYPE },
});

instanceWithoutRefreshToken.interceptors.request.use(config => {
  const newConfig = config;
  const token = getAccessToken();
  newConfig.headers['x-access-token'] = token || '';
  return newConfig;
});

export const axiosInstanceWithoutRefreshToken = instanceWithoutRefreshToken;

export const axiosInstanceWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  headers: { agent: 'react', timezone: moment.tz.guess(), 'x-app-type': X_APP_TYPE },
});

axiosRetry(axiosInstanceWithoutToken, RETRY_CONFIG_INSTANCE_WITHOUT_TOKEN);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  headers: {
    'x-access-token': getAccessToken(),
    'ai-api-key': process.env.REACT_APP_AI_API_KEY,
    'x-app-type': X_APP_TYPE,
    agent: 'react',
    timezone: moment.tz.guess(),
  },
});

axiosRetry(instance, RETRY_CONFIG);

instance.interceptors.request.use(config => {
  const newConfig = config;
  newConfig.metadata = { startTime: new Date() };
  const token = getAccessToken();
  newConfig.headers['x-access-token'] = token || '';
  return newConfig;
});

function onInterceptError(error) {
  const originalRequest = error.config;
  const status = _.get(error, 'response.status');
  if (status === 401 || retryRefreshTokenWithAPIs(originalRequest, status)) {
    if (!isRefreshingAccessToken) {
      isRefreshingAccessToken = true;
      axiosInstanceWithoutToken
        .get(`/api/auth/refresh_token?token=${getRefreshToken()}`)
        .then(res => {
          isRefreshingAccessToken = false;
          const { data } = res.data;
          onTokenRefreshed(data);
        })
        .catch(() => {
          removeToken();
          window.location = '/login';
        });
    }

    const retryOrigReq = new Promise(resolve => {
      subscribeTokenRefresh(token => {
        originalRequest.headers['x-access-token'] = token;
        resolve(axios(originalRequest));
      });
    });

    return retryOrigReq;
  }
  // datadogRum.addError(error);
  return Promise.reject(error);
}

instance.interceptors.response.use(response => {
  const { monitorSettings } = window;

  if (monitorSettings && monitorSettings.enableMonitor) {
    const { limit = 0 } = monitorSettings;
    const randomNumber = _.random(0, 10);

    const now = new Date();
    const { url, baseURL } = response.config;
    const {
      method,
      metadata: { startTime },
    } = response.config;
    const { status } = response;

    const duration = now - startTime;
    const message = `${_.upperCase(method)} ${status} ${url} ${duration}`;

    requestLogInfo(message, {
      duration: duration,
      endpoint: url,
      baseURL,
      method: _.upperCase(method),
      data_dog_log_title: 'Log for Devops Checking',
      requestId: mongoObjectId(),
      statusCode: status,
    });
  }

  return response;
}, onInterceptError);

export const axiosInstance = instance;

const backToHome = () => {
  window.location.href = '/';
};

const studioPath = {
  RESOURCE: '/home/studio-collection',
  RESOURCE_ASSETS: '/home/studio-resource',
  WORKOUT: '/home/workout-collections',
  ON_DEMAND_WORKOUT: '/home/on-demand-workouts',
  STUDIO: '/home/studio-programs',
};

const goBackLast = ({
  isResourceCollection,
  isResourceAssets,
  isWorkoutCollection,
  isOnDemandWorkout,
  isStudioProgram,
}) => {
  const isAvailableGoBack = Object.values(studioPath).findIndex(it =>
    ((window.location || {}).pathname || '').startsWith(it),
  );

  if (isAvailableGoBack !== -1) {
    if (isResourceCollection) window.location.href = studioPath.RESOURCE;
    else if (isResourceAssets) window.location.href = studioPath.RESOURCE_ASSETS;
    else if (isWorkoutCollection) window.location.href = studioPath.WORKOUT;
    else if (isOnDemandWorkout) window.location.href = studioPath.ON_DEMAND_WORKOUT;
    else if (isStudioProgram) window.location.href = studioPath.STUDIO;
    else window.location.href = '/';
  }
};

const checkForbiddenStudio = ({ url = '', responseData = {} } = {}) => {
  const isForbidden = responseData.error === 'E_FORBIDDEN';
  const isBadRequest = responseData.error === 'E_BAD_REQUEST';

  const isResourceCollection = url.indexOf('/api/studio-collection') !== -1;
  const isResourceAssets = url.indexOf('/api/studio-resource') !== -1;
  const isWorkoutCollection = url.indexOf('/api/on-demand-workout-collections') !== -1;
  const isOnDemandWorkout = url.indexOf('/api/on-demand-workout-libraries') !== -1;
  const isStudioProgram = url.indexOf('api/studio-program') !== -1;

  const isStudio =
    isResourceCollection || isResourceAssets || isWorkoutCollection || isOnDemandWorkout || isStudioProgram;

  return {
    isForbidden,
    isBadRequest,
    isStudio,
    isResourceCollection,
    isResourceAssets,
    isWorkoutCollection,
    isOnDemandWorkout,
    isStudioProgram,
  };
};

const checkLostPermissionMealPlan = ({ url = '', responseData = {} } = {}) => {
  const isForbiddenMealPlan = responseData.error === 'E_FORBIDDEN';
  const isBadRequestMealPlan = responseData.error === 'E_BAD_REQUEST';

  const isMealPlan = url.indexOf('/api/meal-plans') !== -1;
  const isMealPlanClients = url.indexOf('/api/meal-plan-clients') !== -1;
  const isMealPlanPreference = url.indexOf('/api/meal-plan-preference') !== -1;
  const isMealClients = url.indexOf('/api/meal-clients') !== -1;
  const isRecipe = url.indexOf('/api/recipe-libraries') !== -1;
  const isRecipeBackgound = url.indexOf('/api/recipe-background-libraries') !== -1;
  const isRecipeIngredients = url.indexOf('/api/recipe-ingredients') !== -1;

  const isLostPermissonMealPlan =
    isMealPlan ||
    isMealPlanClients ||
    isMealPlanPreference ||
    isMealClients ||
    isRecipe ||
    isRecipeBackgound ||
    isRecipeIngredients;

  return {
    isForbiddenMealPlan,
    isBadRequestMealPlan,
    isLostPermissonMealPlan,
  };
};

const checkBadRequestOnboardingFlow = ({ url = '', responseData = {} } = {}) => {
  const isBadRequestOnboardingFlow = responseData.error === 'E_BAD_REQUEST';

  const isOnboarding = url.indexOf('/api/onboarding-flows') !== -1;

  return {
    isBadRequestOnboardingFlow,
    isOnboarding,
  };
};

// When shouldThrowError === true errors will not be thrown. shouldThrowError === true by default
const perform = (storeFunctions, headers, isLoading, successAction, failAction, shouldThrowError) => {
  const { dispatch } = storeFunctions;

  if (isLoading) {
    dispatch(showLoading());
  }

  return axiosInstance(headers)
    .then(result => {
      if (isLoading) {
        dispatch(hideLoading());
      }

      if (successAction) {
        successAction(result, storeFunctions);
      }

      return result;
    })
    .catch(error => {
      if (isLoading) {
        dispatch(hideLoading());
      }

      if (failAction) {
        failAction(error, storeFunctions);
      }

      if (!shouldThrowError) {
        return;
      }

      const responseData = _.get(error, 'response.data');
      const urlAPI = (headers || {}).url || '';
      const {
        isForbidden,
        isBadRequest,
        isStudio,
        isResourceCollection,
        isResourceAssets,
        isWorkoutCollection,
        isOnDemandWorkout,
        isStudioProgram,
      } = checkForbiddenStudio({
        url: urlAPI,
        responseData,
      });
      const { isBadRequestMealPlan, isForbiddenMealPlan, isLostPermissonMealPlan } = checkLostPermissionMealPlan({
        url: urlAPI,
        responseData,
      });
      const { isBadRequestOnboardingFlow, isOnboarding } = checkBadRequestOnboardingFlow({ url: urlAPI, responseData });

      if (responseData) {
        if (
          (_.get(headers, 'url').indexOf('api/payment/payment/get-connected-account') !== -1 ||
            _.get(headers, 'url').indexOf('/api/payment/packages') !== -1) &&
          _.get(responseData, 'statusCode') === 403
        ) {
          dispatch(
            showError(
              'You are not authorized to access this page. Please contact your admin to gain access.',
              'Error',
              'Go back',
              warning,
              'go-back',
              backToHome,
            ),
          );
          return;
        } else if (
          _.get(headers, 'url').indexOf('/api/onboarding-flows') !== -1 &&
          _.get(responseData, 'error') === 'E_FORBIDDEN'
        ) {
          dispatch(
            showError(
              'You are not authorized to access this feature.',
              'Error',
              'Go back',
              warning,
              'go-back',
              backToHome,
            ),
          );
          return;
        } else if ((isStudio && isForbidden) || (isStudio && isBadRequest)) {
          const message = _.get(responseData, 'message');
          dispatch(
            showError(message, null, null, null, null, () => {
              goBackLast({
                isResourceCollection,
                isResourceAssets,
                isWorkoutCollection,
                isOnDemandWorkout,
                isStudioProgram,
              });

              dispatch(hideError(false));
            }),
          );
          return;
        } else if (
          (isLostPermissonMealPlan && isBadRequestMealPlan) ||
          (isLostPermissonMealPlan && isForbiddenMealPlan) ||
          (isOnboarding && isBadRequestOnboardingFlow)
        ) {
          const message = _.get(responseData, 'message');
          dispatch(
            showError(message, null, null, null, null, () => {
              window.location.reload();
            }),
          );
          return;
        } else if (_.get(headers, 'url').indexOf('/retry') !== -1 && _.get(responseData, 'statusCode') >= 400) {
          return responseData;
        } else {
          if (process.env.REACT_APP_API_URL === 'https://api-internal.everfit.io') {
            toast.error(responseData.message);
          } else {
            const { getState } = storeFunctions;
            let isShowingError = false;
            if (getState) {
              const state = getState();
              isShowingError = _.get(state, 'isShowingError', false);
            }
            // Note: Handle callback to "Your Forms" for all api of Forms with error 403 (API Forms: "api/forms")
            if (
              !isShowingError &&
              headers &&
              headers.url &&
              headers.url.includes('api/forms') &&
              _.get(error, 'response.status') === 403
            ) {
              dispatch(showError(responseData.message, null, null, null, null, () => backToForms(dispatch)));
            } else {
              !isShowingError && dispatch(showError(responseData.message, responseData.title));
            }
            // Only show one error one time
            // Handle close edit schedule post popup when the scheduled post already published at current time
            if (
              _.get(headers, 'url').includes('/api/forum-post/edit-post') &&
              _.get(error, 'response.status') === 422
            ) {
              dispatch(
                showError(responseData.message, null, null, null, null, () => {
                  dispatch(hideError(false));
                  dispatch(toggleModal(false));
                }),
              );
            }
          }
        }
        throw responseData;
      } else {
        console.error('Error API Request util ', error);
        // datadogRum.addError(error);

        if (error.status) {
          // detect Network Error
          dispatch(showError('Error code 999, please contact admin'));
        }
        // datadogRum.addError(error);
        throw error;
      }
    });
};

const post = (headers, isLoading, successAction, failAction, shouldThrowError = true) => (dispatch, getState) => {
  return perform(
    { dispatch, getState },
    { ...headers, method: 'post' },
    isLoading,
    successAction,
    failAction,
    shouldThrowError,
  );
};

const get = (headers, isLoading, successAction, failAction, shouldThrowError = true) => (dispatch, getState) => {
  return perform(
    { dispatch, getState },
    { ...headers, method: 'get' },
    isLoading,
    successAction,
    failAction,
    shouldThrowError,
  );
};

const put = (headers, isLoading, successAction, failAction, shouldThrowError = true) => (dispatch, getState) => {
  return perform(
    { dispatch, getState },
    { ...headers, method: 'put' },
    isLoading,
    successAction,
    failAction,
    shouldThrowError,
  );
};

const patch = (headers, isLoading, successAction, failAction, shouldThrowError = true) => (dispatch, getState) => {
  return perform(
    { dispatch, getState },
    { ...headers, method: 'patch' },
    isLoading,
    successAction,
    failAction,
    shouldThrowError,
  );
};

const remove = (headers, isLoading, successAction, failAction, shouldThrowError = true) => (dispatch, getState) => {
  return perform(
    { dispatch, getState },
    { ...headers, method: 'delete' },
    isLoading,
    successAction,
    failAction,
    shouldThrowError,
  );
};

const postWithFormData = (headers, isLoading, successAction, failAction, shouldThrowError = true) => (
  dispatch,
  getState,
) => {
  return perform(
    { dispatch, getState },
    { ...headers, method: 'post' },
    isLoading,
    successAction,
    failAction,
    shouldThrowError,
  );
};

export function getJsonfile(url) {
  return function () {
    return axios
      .get(url)
      .then(response => {
        return response;
      })
      .catch(err => {});
  };
}

const dynamicConfigs = (requestHeader, isLoading, successAction, failAction, shouldThrowError = true) => (
  dispatch,
  getState,
) => perform({ dispatch, getState }, requestHeader, isLoading, successAction, failAction, shouldThrowError);

export default {
  post,
  get,
  put,
  patch,
  delete: remove,
  postWithFormData,
  dynamicConfigs,
};
