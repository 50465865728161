import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/delete_new.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 1;
`;

export const Total = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: #6a778a;
  margin-right: 15px;
`;

export const SearchIcon = styled.div`
  width: 14px;
  height: 14px;
  flex: 0 0 14px;
  margin-left: 10px;
  background: transparent url(${CDN_URL}/images/search.svg) no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const Header = styled.div`
  min-height: 50px;
  padding: 15px;
  color: #6a778a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  ${props =>
    props.readOnly &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
      ${SearchContainer} {
        pointer-events: none;
      }
    `}
`;

export const ClientName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #273d52;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LastMessage = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 4px;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    display: inline-block;
  }

  ${props =>
    props.isMedia &&
    css`
      color: #a6a7ba;
    `}

  ${props =>
    props.isDelete &&
    css`
      color: #000000;
    `}

  ${props =>
    props.isMultipleMedia &&
    css`
      color: #a6a7ba;
    `}
`;

export const ClientInformation = styled.div`
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
`;

export const ItemLeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  flex: 1 1;
  position: relative;
  height: 45px;

  .sb-avatar {
    flex: 0 0 38px;
    margin-right: 10px;
  }

  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
`;

export const GroupChatWrapper = styled.div`
  top: 27px;
  left: 10px;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoUnread = styled.div`
  width: 16px;
  height: 16px;
  background: #726ee4;
  border-radius: 50%;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
`;

export const Time = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #66717e;
  margin: 3px 0 7px;
`;

export const ItemRightContent = styled.div`
  text-align: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 10px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 12px;
    height: 12px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  padding: 20px 15px 12px 12px;
  position: relative;

  &.inbox-item {
    :hover,
    &--active {
      background: #f6f7ff;
      box-shadow: inset -4px 0px 0px #726ee4;
    }

    &--active {
      ${ClientName} {
        color: #6456c4;
        font-weight: bold;
      }
    }

    &--unread {
      ${ClientName} {
        font-weight: bold;
      }

      ${LastMessage} {
        font-weight: 600;
      }
    }
  }

  .inbox__mark-as-unread {
    visibility: hidden;
  }

  :hover {
    .inbox__mark-as-unread {
      visibility: visible;
    }
  }
`;

export const List = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-right: 1px solid #e8e8e8;
  overflow: hidden;
  flex: 0 0 320px;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TextNoResults = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #9a9da6;
`;
