import styled from 'styled-components';
import UpgradePathButton from '../../../../shared/UpgradePath/components/UpgradePathButton';

export const InboxUpgradeButton = styled(UpgradePathButton)`
  padding: 7px 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  width: 44px;
  height: 35px;
  .broadcast-icon {
    width: 20px;
    height: 20px;
  }
  span {
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: initial;
  }
`;

export const InboxUpgradeButtonWrapper = styled.div`
  position: relative;
  margin-right: 2px;
  .upgrade-icon {
    position: absolute;
    top: -8px;
    right: -6px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &:hover {
    ${InboxUpgradeButton} {
      background: #ffe2b8;
      .broadcast-icon path {
        fill: #f58035;
      }
    }
  }
  .__react_component_tooltip.app-tooltip.group-chat-tooltip {
    padding: 15px;
    max-width: 265px;
    background-color: #2d2e2d;
    text-align: left;
    p {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.001em;
    }
  }
`;
