import { cloneDeep, filter, findIndex, merge, concat, uniqBy } from 'lodash';
import { Types } from './actions';
import { INITIAL_WORKING_COLLECTION } from 'constants/commonData';
import { reorder, swapElement } from 'utils/commonFunction';

const PAGE_SIZE = 20;

const INITIAL_STATE = {
  list: [],
  loading: false,
  query: {
    page: 1,
    perPage: PAGE_SIZE,
    textSearch: '',
    status: '',
    total: 0,
    isEnd: false,
    excludeIds: [],
  },
  workingCollection: INITIAL_WORKING_COLLECTION,
  availableClients: [],
  originClients: [],
  assignedClients: [],
  removedClients: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.WORKOUT_COLLECTION_GET_LIST_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.WORKOUT_COLLECTION_GET_LIST_SUCCESS: {
      const isEnd = payload.list.length < PAGE_SIZE;
      return Object.assign({}, state, {
        list: payload.list,
        query: { ...state.query, total: payload.total, isEnd: isEnd },
        loading: false,
      });
    }

    case Types.WORKOUT_COLLECTION_GET_MORE_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.WORKOUT_COLLECTION_RESET_LOAD_MORE_REDUX: {
      return Object.assign({}, state, {
        ...state,
        query: {
          page: 1,
          perPage: PAGE_SIZE,
          textSearch: '',
          status: '',
          total: 0,
          isEnd: false,
        },
        loading: false,
      });
    }

    case Types.WORKOUT_COLLECTION_GET_MORE_SUCCESS: {
      const isEnd = payload.data.list.length < PAGE_SIZE;
      return Object.assign({}, state, {
        list: [...state.list, ...payload.data.list],
        query: {
          ...state.query,
          page: payload.data.page,
          isEnd: isEnd,
        },
        loading: false,
      });
    }
    case Types.WORKOUT_COLLECTION_ADD_CLIENT_SUCCESS:
      return Object.assign({}, state, { originClients: state.availableClients });

    case Types.PRODUCT_GET_LIST_FAIL: {
      return Object.assign({}, state, { loading: false });
    }

    case Types.CREATE_WORKOUT_COLLECTION_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.CREATE_WORKOUT_COLLECTION_SUCCESS: {
      return Object.assign({}, state, { list: concat(payload, state.list), loading: false });
    }

    case Types.CREATE_WORKOUT_COLLECTION_FAIL: {
      return Object.assign({}, state, { loading: false });
    }

    case Types.WORKOUT_COLLECTION_GET_DETAIL_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.WORKOUT_COLLECTION_GET_DETAIL_SUCCESS: {
      return Object.assign({}, state, { workingCollection: payload.data, loading: false });
    }

    case Types.WORKOUT_COLLECTION_ADD_CLIENT_TO_REDUX: {
      return Object.assign({}, state, {
        availableClients: uniqBy([...payload.data, ...state.availableClients], '_id'),
      });
    }

    case Types.WORKOUT_COLLECTION_REMOVE_CLIENT_TO_REDUX: {
      return Object.assign({}, state, {
        availableClients: state.availableClients.filter(item => item._id !== payload.data._id),
      });
    }

    case Types.WORKOUT_COLLECTION_GET_CLIENTS_SUCCESS: {
      return Object.assign({}, state, { availableClients: [...payload], originClients: [...payload] });
    }

    case Types.WORKOUT_COLLECTION_UPDATE_DETAIL_REQUEST: {
      return state;
    }

    case Types.WORKOUT_COLLECTION_UPDATE_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        workingCollection: { ...state.workingCollection, ...payload.data },
      });
    }

    case Types.CREATE_CATEGORIES_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.CREATE_CATEGORIES_SUCCESS: {
      let _workingCollection = state.workingCollection;
      _workingCollection.categories = concat(payload, _workingCollection.categories);

      return Object.assign({}, state, { workingCollection: _workingCollection, loading: false });
    }

    case Types.CREATE_CATEGORIES_FAIL: {
      return Object.assign({}, state, { loading: false });
    }

    case Types.DEACTIVATE_CATEGORY_SUCCESS: {
      let cloned = cloneDeep(state);
      const updateCategoryIndex = findIndex(
        cloned.workingCollection.categories,
        category => category._id === payload.data._id,
      );
      cloned.workingCollection.categories[updateCategoryIndex] = {
        ...cloned.workingCollection.categories[updateCategoryIndex],
        status: payload.data.status,
      };

      return cloned;
    }

    case Types.ACTIVATE_CATEGORY_SUCCESS: {
      let cloned = cloneDeep(state);
      const updateCategoryIndex = findIndex(
        cloned.workingCollection.categories,
        category => category._id === payload.data._id,
      );
      cloned.workingCollection.categories[updateCategoryIndex] = {
        ...cloned.workingCollection.categories[updateCategoryIndex],
        status: payload.data.status,
      };

      return cloned;
    }

    case Types.UNPUBLISH_WORKOUT_COLLECTION_SUCCESS: {
      let cloned = cloneDeep(state);
      const newState = merge(cloned, { workingCollection: { status: payload.data.status } });
      return Object.assign({}, state, { ...newState });
    }

    case Types.PUBLISH_WORKOUT_COLLECTION_SUCCESS: {
      let cloned = cloneDeep(state);
      const newState = merge(cloned, { workingCollection: { status: payload.data.status } });
      return Object.assign({}, state, { ...newState });
    }

    case Types.RESET_WORKOUT_COLLECTION_DETAIL: {
      return Object.assign({}, state, { workingCollection: INITIAL_WORKING_COLLECTION });
    }

    case Types.WORKOUT_COLLECTION_REMOVE_CATEGORY_SUCCESS: {
      let cloned = cloneDeep(state);
      let clonedCollection = cloneDeep(state.workingCollection);
      let updateList = filter(clonedCollection.categories, category => category._id !== payload.data);
      clonedCollection.categories = updateList;
      return Object.assign({}, cloned, { workingCollection: clonedCollection });
    }

    case Types.WORKOUT_COLLECTION_REMOVE_SUCCESS: {
      let cloned = cloneDeep(state);
      let updateList = filter(cloned.list, collection => collection._id !== payload.data);
      return Object.assign({}, state, { list: updateList });
    }

    case Types.WORKOUT_COLLECTION_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, { query: payload });
    }

    case Types.WORKOUT_COLLECTION_DUPLICATE_SUCCESS: {
      return Object.assign({}, state, { list: [payload.data, ...state.list] });
    }

    case Types.WORKOUT_COLLECTION_DUPLICATE_CATEGORY_SUCCESS: {
      let clonedState = cloneDeep(state);
      let clonedCategories = cloneDeep(state.workingCollection.categories);
      clonedCategories = [payload.data, ...clonedCategories];
      return merge(clonedState, { workingCollection: { categories: clonedCategories } });
    }

    case Types.WORKOUT_COLLECTION_RE_ARRANGE_CATEGORY_SUCCESS: {
      let clonedState = cloneDeep(state);
      let clonedCategories = cloneDeep(clonedState.workingCollection.categories);

      if (payload.isDragging) {
        clonedCategories = reorder(clonedCategories, payload.originalIndex, payload.destinationIndex);
      } else {
        swapElement(clonedCategories, payload.originalIndex, payload.destinationIndex);
      }

      return Object.assign({}, state, {
        workingCollection: { ...clonedState.workingCollection, categories: clonedCategories },
      });
    }

    case Types.WORKOUT_COLLECTION_CHANGE_CATEGORY_FORMAT_REDUX: {
      let clonedState = cloneDeep(state);
      let clonedCategories = cloneDeep(state.workingCollection.categories);

      const { format, categoryId } = payload;
      const newCategories = clonedCategories.map(category => {
        if (category._id === categoryId) {
          category.format = format;
        }
        return category;
      });
      return Object.assign({}, state, {
        workingCollection: { ...clonedState.workingCollection, categories: newCategories },
      });
    }
    case Types.WORKOUT_COLLECTION_CHANGE_SHARE_STATUS: {
      return Object.assign({}, state, {
        list: payload || state.list,
      });
    }
    case Types.WORKOUT_COLLECTION_GET_ASSIGNED_CLIENTS: {
      if (payload.data) {
        return Object.assign({}, state, {
          assignedClients: payload.data,
        });
      }
      return state;
    }

    default:
      return state;
  }
};
