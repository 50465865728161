import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 12px 15px;
  border: 1px solid #ecf0f8;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  margin: 6px 0px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border: 1px solid #5158cf;
  }
`;

export const TextBold = styled.strong`
  font-weight: 600;
  font-size: 13px;
  color: #202353;
  line-height: 19px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.div`
  margin-right: 15px;

  .sb-avatar--src {
    display: flex !important;
    align-items: center;
    justify-content: center;

    img[alt='refund_invoice'] {
      width: 22px !important;
      height: 20px !important;
      border-radius: 0;
    }
  }
`;

export const ActivityContent = styled.div`
  font-size: 13px;
  color: #202353;
  line-height: 150%;
  max-width: 460px;
`;

export const TimeLine = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
`;

export const RefundContent = styled.div`
  max-width: 460px;
  font-size: 12px;
  line-height: 18px;

  div {
    font-size: 13px;
    line-height: 19.5px;
  }
`;
