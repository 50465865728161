import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const DefaultUIContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-input {
    width: 265px;
    height: 36px;
  }

  button {
    padding: 8px 20px;
  }
`;

export const BannerTextContent = styled.div`
  max-width: 465px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #1c1919;
  position: relative;
  z-index: 2;

  .autoflow__banner__label {
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .autoflow__banner__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    margin-bottom: 2px;
  }

  .autoflow__banner__description {
    margin-bottom: 30px;
    span {
      opacity: 0.8;
    }
  }

  .autoflow__banner__learnMoreLink {
    margin-top: 10px;
    a {
      color: #5c5bbd;
      font-weight: bold;
      opacity: 1;
      margin-right: 30px;
    }

    img {
      margin-bottom: -3px;
    }
  }
`;

export const BannerContainer = styled.div`
  background: #f0f8ff url(${CDN_URL}/images/autoflow_banner.png) no-repeat bottom right 30px;
  background-size: auto calc(100% - 30px);
  padding: 50px 50px 10px;
  min-height: 267px;
  border-radius: 8px;

  .buttons__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  button {
    &.button--hide-banner {
      border: none;
      background-color: transparent;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #7e7e7e;
      border-radius: 5px;
      position: absolute;
      bottom: 10px;

      :hover {
        background-color: #bdbab0;
      }

      > * {
        :first-child {
          margin-right: 10px;
        }
      }
    }

    &.button--create-new {
      background-color: #50abff;
      padding: 10px 15px;
      color: #ffffff;

      svg {
        margin-right: 10px;

        path {
          fill: #fff;
        }
      }
    }

    &.button--search-box-trigger {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #50abff;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
      min-width: unset;

      svg {
        path {
          stroke: #50abff;
        }
      }

      :hover {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #50abff;

        svg {
          path {
            stroke: #50abff;
          }
        }
      }
    }
  }

  .search-input {
    width: 272px;
    height: 40px;
    border-radius: 5px;

    input {
      background-color: #ffffff;
      border-color: #fff;
      background-image: url(${CDN_URL}/images/search_black.svg);
      background-position: 11px center;
    }
  }

  .button-hidden-container {
    text-align: center;
    position: relative;
    z-index: 2;
  }
`;

export const Wrapper = styled.div`
  margin: 0px 0px 10px;
  position: relative;

  ${props =>
    props.showBanner &&
    css`
      margin: 0px 0px 20px;
      margin-bottom: 30px;
    `}
`;
