import styled from 'styled-components';

export const MenuHeader = styled.div`
  padding: 10px 10px 0;

  .search-input {
    height: 30px;
    background: #f8f8f8;

    > input {
      color: #000;
      font-size: 13px;
      line-height: 18px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;

      :hover {
        border-color: #7072dd;
      }
    }
  }
`;

export const MenuBody = styled.div`
  overflow-x: hidden;
  overflow-y: overlay;
  height: 200px;
  margin-top: 10px;
  padding-bottom: 15px;

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    li {
      padding: 5px 15px;

      .tag-checkbox {
        display: block;
      }

      .create-new-tag-option {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 11px;
        line-height: 15px;
        font-weight: 700;
        color: #7072dd;
        cursor: pointer;
        padding: 10px 15px;
        background: #f4f4ff;
        border-radius: 5px;

        > img {
          margin-right: 10px;
        }
      }

      :hover {
        background-color: #edecfc;
      }

      .tag-checkbox__title {
        display: flex;
        justify-content: flex-start;
        color: #111;
        font-weight: 400;

        > div:first-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        span {
          font-size: 13px;
          line-height: 18px;
        }

        .exercises {
          color: #99a1b1;
          margin-left: 10px;
        }
      }
    }

    .create-new-tag-item {
      padding: 0 10px 5px;
      :hover {
        background-color: #fff;
      }
    }
  }
`;

export const MenuFooter = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eceff1;

  .footer_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ui.button.general-button {
    padding: 6px 25px 9px;
    border-radius: 4px;
    margin: 0;
    background-color: #5158cf;
    border: none;
    box-shadow: none !important;
    font-size: 11px;
    line-height: 11px;

    :hover {
      background-color: #5559ff !important;
    }
  }

  .ui.button.general-button.create-button,
  .ui.button.general-button.manage-button {
    background-color: transparent;
    color: #7b82a4 !important;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    padding-right: 20px;

    > * {
      margin-right: 5px;
    }

    :hover {
      color: #5c58c0 !important;
      background-color: transparent !important;

      svg {
        circle {
          fill: #5c58c0;
        }
      }
    }
  }

  .ui.button.general-button.manage-button {
    :hover {
      svg {
        path {
          fill: #5c58c0;
        }
      }
    }
  }

  .ui.button.general-button.apply-button {
    :disabled,
    :disabled:hover {
      cursor: not-allowed;
      opacity: 1 !important;
      background-color: #c4c4c4 !important;
    }
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: -4px;
  right: 181px;
  width: 297px;
  display: block;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid #dcdcde;
  background: #fff;
  z-index: 1000;

  &.container-tags-ai {
    right: 299px;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  ${MenuHeader} {
    .search-input {
      width: 100% !important;
    }
  }
`;

export const TextBold = styled.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000;
  margin: 0;
  padding: 15px 15px 0;
`;
