import styled from 'styled-components';
import { Content, Actions } from 'shared/Styles/ModalLayout';
import { CDN_URL } from 'constants/commonData';

export const Form = styled.form`
  width: 560px;
  max-width: calc(100vw - 40px);

  button[type='submit'] {
    padding: 6px 34px;
  }

  ${Content} {
    max-height: calc(100vh - 250px);
    overflow: auto;
    padding-bottom: 0;
  }

  ${Actions} {
    padding-top: 30px;
  }
`;

export const CloseIcon = styled.div`
  width: 18px;
  height: 18px;
  background: #000 url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: -9px;
  right: -9px;
  border-radius: 50%;
  outline: none;
`;

export const Container = styled.div`
  height: auto;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 8px;
  overflow: visible;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
`;
