import { cloneDeep, get, merge, omit, pullAt } from 'lodash';
import { Types } from './actions';
import { INITIAL_WORKING_COLLECTION_CATEGORY } from 'constants/commonData';
import { reorder } from 'utils/commonFunction';

const swapElement = (array, indexA, indexB) => {
  var tmp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = tmp;
};

export default (state = INITIAL_WORKING_COLLECTION_CATEGORY, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.CATEGORY_GET_DETAIL_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.CATEGORY_GET_DETAIL_SUCCESS: {
      return Object.assign({}, state, { ...payload.data, loading: false });
    }
    case Types.CATEGORY_GET_WORKOUTS_SUCCESS: {
      let cloned = cloneDeep(state);
      const isEnd = payload.data.list.length < state.query.perPage;
      const total = get(payload, 'data.total', 0);
      return Object.assign({}, cloned, {
        workouts: payload.data,
        number_of_workouts: total,
        query: { ...state.query, total: total, isEnd: isEnd, isLoading: false },
      });
    }
    case Types.CATEGORY_ADD_WORKOUTS_SUCCESS: {
      let cloned = cloneDeep(state);
      const { workouts } = cloned;
      workouts.list = [...workouts.list, ...payload.data];
      return merge(cloned, { workouts });
    }
    case Types.CATEGORY_RESET_STATE: {
      return Object.assign({}, state, { ...INITIAL_WORKING_COLLECTION_CATEGORY, workouts: { list: [], total: 0 } });
    }

    case Types.CATEGORY_DETAIL_UPDATE_SUCCESS: {
      const category = omit(payload.data, ['workouts', 'on_demand_workout_collection']);

      return Object.assign({}, state, {
        ...category,
      });
    }

    case Types.CATEGORY_REMOVE_WORKOUT_SUCCESS: {
      let cloned = cloneDeep(state);
      const { workouts, number_of_workouts } = cloned;
      const { list = [] } = workouts;
      const newWorkouts = list.filter(it => it._id !== payload.data);
      const total = number_of_workouts > 0 ? number_of_workouts - 1 : number_of_workouts;
      return Object.assign({}, state, { workouts: { list: newWorkouts, total: total }, number_of_workouts: total });
    }

    case Types.CATEGORY_DETAIL_ACTIVE_SUCCESS: {
      let cloned = cloneDeep(state);
      return merge(cloned, { status: payload.data.status });
    }

    case Types.CATEGORY_DETAIL_DEACTIVE_SUCCESS: {
      let cloned = cloneDeep(state);
      return merge(cloned, { status: payload.data.status });
    }
    case Types.MOVE_ON_DEMAND_WORKOUT_REQUEST: {
      return Object.assign({}, state, { rearranging: true });
    }
    case Types.MOVE_ON_DEMAND_WORKOUT_SUCCESS: {
      let workoutsList = cloneDeep(state.workouts.list);
      if (payload.isDragging) {
        workoutsList = reorder(workoutsList, payload.originalIndex, payload.destinationIndex);
      } else {
        swapElement(workoutsList, payload.originalIndex, payload.destinationIndex);
      }

      return Object.assign({}, state, { workouts: { list: workoutsList }, rearranging: false });
    }
    case Types.MOVE_ON_DEMAND_WORKOUT_TO_TOP_REQUEST: {
      return Object.assign({}, state, { rearranging: true });
    }
    case Types.MOVE_ON_DEMAND_WORKOUT_TO_TOP_SUCCESS: {
      let workoutsList = cloneDeep(state.workouts.list);
      workoutsList.forEach((item, idx) => {
        if (idx === payload.originalIndex) {
          workoutsList.splice(idx, 1);
          workoutsList.unshift(item);
        }
      });

      return Object.assign({}, state, { workouts: { list: workoutsList }, rearranging: false });
    }
    case Types.MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_REQUEST: {
      return Object.assign({}, state, { rearranging: true });
    }
    case Types.MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_SUCCESS: {
      let workoutsList = cloneDeep(state.workouts.list);
      let isEnd = state.query.isEnd;
      if (isEnd) {
        // all workouts have been loaded, will not load more
        workoutsList.push(workoutsList.splice(payload.originalIndex, 1).pop());
      } else {
        // all workouts are not yet loaded, will push the workout to the end of the list, out of the current list view
        pullAt(workoutsList, [payload.originalIndex]);
      }
      return Object.assign({}, state, { workouts: { list: workoutsList }, rearranging: false });
    }
    case Types.WORKOUT_COLLECTION_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, { query: payload, loading: true });
    }
    case Types.CATEGORY_WORKOUT_LOAD_MORE_REQUEST: {
      return Object.assign({}, state, { workouts: { ...state.workouts, isLoading: true } });
    }
    case Types.CATEGORY_WORKOUT_LOAD_MORE_SUCCESS: {
      let clonedWorkouts = cloneDeep(state.workouts);
      clonedWorkouts.list = [...state.workouts.list, ...payload.data.list];
      const isEnd = payload.data.list.length < state.query.perPage;
      return Object.assign({}, state, {
        workouts: clonedWorkouts,
        query: {
          ...state.query,
          isEnd: isEnd,
          isLoading: false,
        },
      });
    }
    case Types.CATEGORY_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, { query: { ...payload, isLoading: true } });
    }
    default:
      return state;
  }
};
