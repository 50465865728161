import React from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import { toggleConfirmModal } from 'actions/modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UpdatePaymentConfirmModal } from './style';
import { getBillingOverviewData, downgradeToStarter } from 'redux/billing/actions';
import { initPricingData } from 'redux/pricing/actions';
import { CDN_URL } from 'constants/commonData';

class UpdatePaymentConfirm extends React.Component {
  state = { isDowngrading: false };
  onClose = () => {
    this.props.dispatch(toggleConfirmModal(false));
  };

  onDowngradeToStarter = (e) => {
    if (this.state.isDowngrading) {
      return;
    }
    this.setState({ isDowngrading: true });
    this.props.downgradeToStarter().then(() => {
      this.setState({ isDowngrading: false });
      this.onClose();
      this.props.getBillingOverviewData();
      this.props.initPricingData();
    }).finally(() => {
      this.setState({ isDowngrading: false });
    })
  }

  render() {
    return (
      <UpdatePaymentConfirmModal open={this.props.isConfirmModalOpen} className="confirm-popup-container new-style">
        <Modal.Content className="confirm-content">
          <Button onClick={this.onClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="confirm-content-header">
            <p className="confirm-header-label">Update Payment Later</p>
          </div>
          <div className="confirm-content-body">
            <div className="confirm-content-label">
              Without a payment, your plan will be downgraded to the Starter plan at the end of the grace period. Your workspace will lose access to any premium feature, such as:
            </div>
            <div class="confirm-content-body__feature">
              <div>• Train more than 5 clients</div>
              <div>• Program Library</div>
              <div>• Food Journal</div>
              <div>• Custom Branding</div>
            </div>
            <div className="confirm-content-label">
              If you have more than 5 active clients, all clients will be archived.
            </div>
            <div className="confirm-content-label confirm-content-label__second">
              Would you like to downgrade now or keep your current plan until the end of the grace period?
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions className="confirm-actions">
          <Button
            className="confirm-downgrade-button"
            disabled={this.state.isDowngrading}
            onClick={this.onDowngradeToStarter}
          >
            Downgrade to Starter
          </Button>
          <Button
            className="pay-later-button"
            onClick={() => {
              this.props.dispatch(toggleConfirmModal(false));
            }}
          >
            Pay later
          </Button>
        </Modal.Actions>
      </UpdatePaymentConfirmModal>
    );
  }
}

const mapStateToProps = state => {
  const { isConfirmModalOpen, confirmModal, rootReducer } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
    noClients: rootReducer.pricing.getIn(['teamData', 'noClients']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getBillingOverviewData: bindActionCreators(getBillingOverviewData, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
    downgradeToStarter: bindActionCreators(downgradeToStarter, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentConfirm);
