import React from 'react';
import _ from 'lodash';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { Image } from 'semantic-ui-react';
import { Title, SubTitle, CompleteContainer } from './SendLink.style';
import { CDN_URL } from 'constants/commonData';

const Complete = ({ complete, downloadLinkBy, phoneNumber, client, countryCode }) => {
  const format = value => formatIncompletePhoneNumber(value, countryCode.code);

  if (complete && downloadLinkBy === 'mobile') {
    return (
      <CompleteContainer>
        <Image src={`${CDN_URL}/images/register_success_2.svg`} />
        <Title>
          We sent a download link to
          <br />
          {countryCode.value} {format(phoneNumber)}
        </Title>
        <SubTitle>
          Please check your messages.
          <br />
          Cheers to training smarter!
        </SubTitle>
      </CompleteContainer>
    );
  }

  if (complete && downloadLinkBy === 'email') {
    return (
      <CompleteContainer>
        <Image src={`${CDN_URL}/images/register_success_2.svg`} />
        <Title>
          We sent a download link to
          <br />
          {_.get(client, 'email')}
        </Title>
        <SubTitle>
          Please check your messages.
          <br />
          Cheers to training smarter!
        </SubTitle>
      </CompleteContainer>
    );
  }
};
Complete.propTypes = {};

export default Complete;
