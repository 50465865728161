import { CDN_URL } from 'constants/commonData';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
const bounce = keyframes`
  0%   { transform: translateY(0); }
  8%  { transform: translateY(-10px); }
  16%  { transform: translateY(0px); }
  24%  { transform: translateY(-10px); }
  32%  { transform: translateY(0px); }
  100% { transform: translateY(0); }
`;

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 999999px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 30px;
  left: 30px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 2;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
    `}

  animation-name: ${bounce};
  animation-timing-function: ease;
  animation-duration: 15s;
  animation-iteration-count: infinite;

  :hover {
    animation-play-state: paused;
  }

  .exploreAddOn__downIcon {
    border-radius: 999999px;
    width: 36px;
    height: 36px;
    background: #000000;
    background-image: url(${CDN_URL}/images/arrow_down_white.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
  .exploreAddOn__text {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    color: #000000;
    padding: 0px 12px;
  }
`;

export default function ExploreAddOn(props) {
  return (
    <Wrapper visible={props.visible} onClick={props.onClick}>
      <div className="exploreAddOn__downIcon" />
      <div className="exploreAddOn__text">Explore the Add-ons</div>
    </Wrapper>
  );
}
