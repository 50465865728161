import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const textBase = `
    color: #202353;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
`;

export const BannerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 230px;
  background: #fffaf0;
  border-radius: 8px;
  position: relative;
`;

export const BannerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0 0;
  align-self: stretch;
  padding: 31px 0 31px 50px;
`;

export const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BannerActions = styled.div`
  display: flex;
  gap: 10px;

  .banner-search {
    width: 273px;
    input {
      ${textBase}
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      padding-left: 46px;
      background: url(${CDN_URL}/images/search_bold.svg) no-repeat;
      background-size: 17px;
      background-position: 15px center;
      background-color: #fff;
      &::placeholder {
        ${textBase}
        color: #a3a3b5;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
`;

export const BannerLabel = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const BannerHeader = styled.div`
  ${textBase}
  font-size: 22px;
  font-weight: 700;
`;

export const BannerDesc = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  max-width: 391px;
  position: relative;
`;

export const BannerLearnMore = styled.a`
  position: absolute;
  bottom: 2px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-left: 11px;
  .banner-learn-more-icon path {
    fill: #5158cf;
  }
  span {
    ${textBase}
    color: #5158cf;
    font-size: 12px;
    font-weight: 600;
  }
  &:hover {
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
`;

export const BannerButtonCreate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 172px;
  height: 36px;
  padding: 10px 20px;
  background: #5158cf;
  border-radius: 5px;

  .banner-plus-icon {
    width: 20px;
    height: 20px;
    path {
      fill: #fff;
    }
  }

  &:hover {
    cursor: pointer;
    background: rgba(81, 88, 207, 0.9);
  }
`;

export const ButtonSearch = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  padding: 10px;
  .banner-search-icon {
    width: 16px;
    height: 16px;
    path {
      fill: #7b7e91;
    }
  }
  &:hover {
    cursor: pointer;
    background: #f0f1ff;
    .banner-search-icon path {
      fill: #5158cf;
    }
  }
`;

export const BannerButtonContent = styled.div`
  ${textBase}
  color:  #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
`;

export const BannerLeft = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ImageBanner = styled.img`
  margin-top: 2px;
  width: auto;
  height: 229px;
`;

export const HideButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 30px;
  min-width: 101px;
  position: absolute;
  padding: 5px;
  bottom: 4px;
  left: calc(50% - 2px);
  transform: translateX(-50%);
  ${textBase}
  color: #7b7e91;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: #f0f1ff;
    color: #5158cf;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      background: rgba(240, 241, 255, 0.9);
    `}
`;
