import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import { getTasksLibrary, getTasksLibraryDetails, resetSelectedTask } from 'redux/tasks-library/actions';
import { Actions } from 'redux/trainer/actions';
import TasksLibrary from './component';
import { toggleModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';

const mapState = state => {
  const {
    user,
    rootReducer: {
      tasksLibrary: {
        list,
        loading,
        total,
        has_task_library,
        queryParam: { query, types, owners },
      },
    },
  } = state;

  return {
    user,
    list,
    loading,
    total,
    has_task_library,
    query,
    types,
    owners,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getTasksLibrary: bindActionCreators(getTasksLibrary, dispatch),
    resetSelectedTask: bindActionCreators(resetSelectedTask, dispatch),
    replace: bindActionCreators(replace, dispatch),
    getMetrics: bindActionCreators(Actions.getMetrics, dispatch),
    getTasksLibraryDetails: bindActionCreators(getTasksLibraryDetails, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(TasksLibrary);
