import React from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { generateVimeoAPIUrl, makeFriendlyYoutubeURL } from 'utils/commonFunction';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import './styles.scss';
import { CDN_URL } from 'constants/commonData';

export default class PlayVideoPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      thumbnail: '',
      url: '',
      loading: true,
      play: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search || '');
    const url = params.get('url');

    axios.get(generateVimeoAPIUrl(url)).then(resp => {
      const data = resp.data;
      this.setState({
        thumbnail: data.thumbnail_url || MEDIA_PLACEHOLDER,
        loading: false,
        url,
      });
    });
  }

  render() {
    const { url, loading, play, thumbnail } = this.state;

    return (
      <div className="play-video-wrapper">
        {!loading ? (
          url ? (
            <div className="play-video-container">
              <ReactPlayer
                url={makeFriendlyYoutubeURL(url)}
                playing={play}
                controls={true}
                className={play ? 'video play' : 'video pause'}
              />
              <div
                className={`preview ${play ? ' hide' : ''}`}
                onClick={() => {
                  this.setState({
                    play: true,
                  });
                }}
              >
                <img src={thumbnail} className="thumbnail" alt="" />
                <img src={`${CDN_URL}/images/play-button.svg`} className="play-button" alt="" />
              </div>
            </div>
          ) : (
            <div className="message">
              <h1>Sorry</h1>
              <p>Cannot play video</p>
            </div>
          )
        ) : null}
      </div>
    );
  }
}
