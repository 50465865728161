import React from 'react';
import { roundUp } from 'utils/commonFunction';
import { CDN_URL, TABLE_CELL_CLIENT_NAME_WIDTH } from 'constants/commonData';

export const Columns = [
  {
    key: 'name',
    label: 'Name',
    sort_key: 'full_name_sort',
    sort: true,
    icon: `${CDN_URL}/images/person.svg`,
    fieldData: 'name',
    alwaysShow: true,
    align: 'align-left',
    width: TABLE_CELL_CLIENT_NAME_WIDTH,
    fixed: true,
  },
  {
    key: 'shortcut-icons',
    label: '',
    fieldData: '',
    width: 110,
    align: 'align-right',
    alwaysShow: true,
    hideOnList: true,
  },
  {
    key: 'client-groups',
    label: 'Groups',
    fieldData: '',
    width: 240,
    columnName: 'Groups',
    icon: `${CDN_URL}/images/group_grey.svg`,
    defaultHide: true,
    headerDescription: (
      <div>
        <p className="title">Groups</p>
        <p>Group clients for easy sorting</p>
      </div>
    ),
  },
  {
    key: 'last_activity',
    label: 'Last Activity',
    sort_key: 'last_activity',
    sort: true,
    icon: `${CDN_URL}/images/last_engaged.svg`,
    fieldData: 'last_activity_text',
    width: 135,
    headerDescription: (
      <div>
        <p className="title">Last Activity</p>
        <p>Time since client last accessed the app</p>
      </div>
    ),
  },
  {
    key: 'last_engaged',
    label: 'Last engaged',
    sort_key: 'last_engaged',
    sort: true,
    icon: `${CDN_URL}/images/last_engaged.svg`,
    fieldData: 'last_engaged_text',
    width: 140,
    defaultHide: true,
    headerDescription: (
      <div>
        <p className="title">Last engaged</p>
        <p>Time since trainer last messaged the client</p>
      </div>
    ),
  },
  {
    key: 'l7dTraining',
    label: 'Last 7d Training',
    sort_key: 'percent_completed_7d',
    sort: true,
    icon: `${CDN_URL}/images/training_icon.svg`,
    fieldData: 'l7dTraining',
    width: 155,
    headerDescription: (
      <div>
        <p className="title">Last 7 days</p>
        <p>Number of workouts completed versus</p>
        <p>assigned during the last 7 days</p>
      </div>
    ),
    mutate: user =>
      user.completed_assignment_7d || user.total_assignment_7d ? (
        <div className="last-x-days-training">
          <span>{Math.round(roundUp((user.completed_assignment_7d / user.total_assignment_7d) * 100, 2))}%</span>
          <span>
            {user.completed_assignment_7d}/{user.total_assignment_7d}
          </span>
        </div>
      ) : (
        '--'
      ),
    className: 'training_completed_percent',
  },
  {
    key: 'l30dTraining',
    label: 'Last 30d Training',
    sort_key: 'percent_completed_30d',
    sort: true,
    icon: `${CDN_URL}/images/training_icon.svg`,
    fieldData: 'l30dTraining',
    width: 163,
    headerDescription: (
      <div>
        <p className="title">Last 30 days</p>
        <p>Number of workouts completed versus</p>
        <p>assigned during the last 30 days</p>
      </div>
    ),
    mutate: user =>
      user.completed_assignment_30d || user.total_assignment_30d ? (
        <div className="last-x-days-training">
          <span>{Math.round(roundUp((user.completed_assignment_30d / user.total_assignment_30d) * 100, 2))}%</span>
          <span>
            {user.completed_assignment_30d}/{user.total_assignment_30d}
          </span>
        </div>
      ) : (
        '--'
      ),
    className: 'training_completed_percent',
  },
  {
    key: 'last_assigned_workout',
    label: 'Last Assigned Workout',
    sort_key: 'last_assigned_workout',
    sort: true,
    icon: `${CDN_URL}/images/training_icon.svg`,
    fieldData: 'last_assigned_workout_text',
    width: 200,
    defaultHide: true,
    headerDescription: (
      <div>
        <p className="title">Last Assigned Workout</p>
        <p>The last assigned workout</p>
      </div>
    ),
  },
  {
    key: 'last_7_days_task',
    label: 'Last 7d Tasks',
    sort_key: 'percent_task_completed_7d',
    sort: true,
    icon: `${CDN_URL}/images/training_icon.svg`,
    fieldData: 'last_7_days_task',
    width: 145,
    headerDescription: (
      <div>
        <p className="title">Last 7 days tasks</p>
        <p>Number of tasks completed versus</p>
        <p>assigned over the last 7 days</p>
      </div>
    ),
    mutate: user =>
      user.completed_task_7d || user.total_task_7d ? (
        <div className="last-x-days-training">
          <span>{Math.round(roundUp((user.completed_task_7d / user.total_task_7d) * 100, 2))}%</span>
          <span>
            {user.completed_task_7d}/{user.total_task_7d}
          </span>
        </div>
      ) : (
        '--'
      ),
    className: 'training_completed_percent',
  },
  {
    key: 'last_30_days_task',
    label: 'Last 30d Tasks',
    sort_key: 'percent_task_completed_30d',
    sort: true,
    icon: `${CDN_URL}/images/training_icon.svg`,
    fieldData: 'last_30_days_task',
    width: 150,
    defaultHide: true,
    headerDescription: (
      <div>
        <p className="title">Last 30 days tasks</p>
        <p>Number of tasks completed versus</p>
        <p>assigned over the last 30 days</p>
      </div>
    ),
    mutate: user =>
      user.completed_task_30d || user.total_task_30d ? (
        <div className="last-x-days-training">
          <span>{Math.round(roundUp((user.completed_task_30d / user.total_task_30d) * 100, 2))}%</span>
          <span>
            {user.completed_task_30d}/{user.total_task_30d}
          </span>
        </div>
      ) : (
        '--'
      ),
    className: 'training_completed_percent',
  },
  {
    key: 'client_type',
    label: 'Category',
    sort_key: 'client_type',
    sort: true,
    icon: `${CDN_URL}/images/category.svg`,
    fieldData: 'client_type',
    width: 118,
    headerDescription: (
      <div>
        <p className="title">Category</p>
        <p>In-person or Online</p>
      </div>
    ),
  },
  {
    key: 'status',
    label: 'Status',
    sort_key: 'client_connection',
    sort: true,
    icon: `${CDN_URL}/images/status.svg`,
    fieldData: 'status',
    width: 103,
    headerDescription: (
      <div>
        <p className="title">Status</p>
        <p>Status of client’s connection on Everfit</p>
      </div>
    ),
  },
  {
    key: 'trainer_name',
    label: 'Owner',
    sort_key: 'trainer_full_name',
    sort: true,
    icon: `${CDN_URL}/images/role.svg`,
    fieldData: 'trainer_name',
    width: 150,
    headerDescription: (
      <div>
        <p className="title">Owner</p>
        <p>The trainer responsible for a client</p>
      </div>
    ),
  },
];
