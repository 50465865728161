import React from 'react';
import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const defaultImage = `${CDN_URL}/images/default_forum_banner.svg`;

export default function ForumOption({ data, cloudfrontList }) {
  return (
    <div className="asset-content forum">
      <div className="forum-cover-container">
        <img
          src={data.banner ? convertS3UrlToCloudFrontUrl(data.banner, cloudfrontList, true) : defaultImage}
          alt={data.name}
        />
      </div>
      <div className="forum-info-container">
        <div className="title">{data.name}</div>
        <div className="asset-info">
          {data.no_of_members} {pluralize('MEMBER', data.no_of_members)}
        </div>
      </div>
    </div>
  );
}
