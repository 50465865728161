import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import AdvancedCustomBranding from 'assets/images/advanced-custom-branding.png';
import { Button } from 'shared/FormControl';

const Header = styled.div`
  width: 330px;
  height: 222px;
  background: transparent url(${AdvancedCustomBranding}) no-repeat;
  background-position: top left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-size: 100%;
`;

const Content = styled.div`
  padding: 25px;
  text-align: left;
  white-space: break-spaces;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #323c47;

  .title {
    margin-bottom: 15px;
    font-weight: bold;
  }

  ul {
    margin: 0;
    list-style: none;
    padding-left: 0px;

    li {
      font-weight: normal;
      margin-bottom: 12px;
      padding-left: 13px;
      position: relative;

      :last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #c4c4c4;
      }
    }
  }
`;

const Container = styled(ReactTooltip)`
  &.__react_component_tooltip {
    &.app-tooltip-white {
      padding: 0;
    }
  }
`;

const LearnMoreButton = styled(Button).attrs({
  type: 'button',
})`
  margin-top: 30px;
  width: 276px;
  height: 44px;
  color: #f47735;
  font-weight: 600;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  border: none;
  ::before {
    content: '';
    background: linear-gradient(269.04deg, #fdc830 1.1%, #f37335 99.38%);
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
    width: 278px;
    height: 46px;
    border-radius: 6px;
  }
  :hover {
    background: linear-gradient(0deg, #fff7ee, #fff7ee), #ffffff;
  }
`;

export default props => {
  const handleClick = () => {
    props.onRedirectToTab(1); // index 1 is the premium tab
  };
  return (
    <Container {...props}>
      <Header />
      <Content>
        <div className="title">Advanced app customization features to enhance the client experience.</div>
        <ul>
          <li>Choose your custom colors</li>
          <li>Set your own app icon</li>
          <li>Unique background for each workout</li>
        </ul>
        <LearnMoreButton onClick={handleClick}>Learn more</LearnMoreButton>
      </Content>
    </Container>
  );
};
