import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { toggleModal } from 'actions/modal';
import Component from './component';
import { copyTask, pasteTask, deleteTask } from 'redux/autoflow/task/actions';
import { saveToLibrary } from 'redux/task/task.actionCreators';
import { toggleConfirmModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';

const mapStateToProps = (state, ownProps) => {
  const { autoflow } = state.rootReducer;
  const { tasks, copying, isMoving } = autoflow.task;
  const {
    settings: { metrics },
  } = autoflow;
  const day = ownProps.day.format('MM-DD-YYYY');
  const dayData = _.find(tasks, item => item.day === day);

  return {
    bodymetricTypes: metrics,
    tasks: _.get(dayData, 'tasks', []),
    habits: _.get(dayData, 'habits', []),
    workingAutoflow: autoflow.common.workingAutoflow,
    copying,
    dayId: dayData ? dayData._id : '',
    isMoving,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    copyTask: bindActionCreators(copyTask, dispatch),
    pasteTask: bindActionCreators(pasteTask, dispatch),
    deleteTask: bindActionCreators(deleteTask, dispatch),
    saveToLibrary: bindActionCreators(saveToLibrary, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
