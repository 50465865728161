import styled from 'styled-components';

export const CoverImage = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #eaecf2;
  height: 189px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PackageName = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #202353;
  word-break: break-word;
`;

export const PackageHeadline = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dbdbdb;
`;

export const Description = styled.div`
  padding-bottom: 30px;

  &.ql-snow .ql-editor {
    padding: 0;
    font-family: 'Open Sans';
    max-width: 509.41px;

    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      padding-bottom: 25px;
    }

    h2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      padding-bottom: 10px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
    }

    ul {
      list-style: disc;
      padding-left: 10px;
    }

    ol {
      list-style: auto;
      padding-left: 1.2em;
    }

    ol,
    ul {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      padding-top: 26px;
      li {
        padding-bottom: 15px;
      }

      li:not(.ql-direction-rtl) {
        padding-left: 0;
        letter-spacing: -0.3px;
        &::before {
          margin-right: 13px;
        }
      }
    }
  }
`;

export const PackageInfo = styled.div`
  padding: 8px 20px;
`;

export const Wrapper = styled.div`
  width: 606px;
  flex: 1;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 65px;
`;
