import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import InviteClientSuccess from './component';

const mapDispatch = (dispatch) => ({
  push: bindActionCreators(push, dispatch),
});

export default connect(null, mapDispatch)(InviteClientSuccess);
