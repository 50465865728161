import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { push } from 'connected-react-router';

import * as S from '../style';
import { CDN_URL, CURRENCIES, INVOICE_STATUS, TEXT_CURRENCIES } from 'constants/commonData';

import { ReactComponent as Inbox } from 'assets/icons/inbox_sm.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel_invoice_sm.svg';
import { ReactComponent as PreviewIcon } from 'assets/icons/view_sm.svg';
import { ReactComponent as Retry } from 'assets/icons/retry_charge.svg';
import { ReactComponent as RefundIcon } from 'assets/icons/refund-icon.svg';

import StatusLabel from 'components/Package/components/StatusLabel';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import PurchaseDetailPopup from '../../PurchaseDetailPopup';
import RefundPaymentPopup from '../../RefundPaymentPopup';
import { formatMoney, formatSameYear, encryptData } from 'utils/commonFunction';
import {
  cancelInvoice,
  changeQueryParams,
  countPackages,
  getPackageStatistics,
  refundInvoice,
} from 'redux/package-analytics/actions';
import { showError } from 'actions/error';

import RetryChargePopup from './RetryChargePopup';

import { INVOICE_REFUND_STATUS, REFUND_REASONS } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

const CancelMessage = () => (
  <>
    <p>
      This action will void the invoice, which means the payment will not be collected. The cancellation can not be
      reversed.
    </p>
    <p>
      Cancelling the invoice however will not affect the subscription. The subscription will stay active until its
      original end date.
    </p>
  </>
);

const TableContent = props => {
  const [retryInvoice, setRetryInvoice] = useState();
  const {
    list,
    toggleConfirmModal,
    cancelInvoice,
    toggleModal,
    toggleSecondModal,
    changeQueryParams,
    countPackages,
    getPackageStatistics,
    refundInvoice,
    account,
    permission,
    push,
    isMP,
  } = props;
  const invoices = useMemo(() => list.toJS(), [list]);

  const renderTrigger = ({ open }) => (
    <div data-tip data-for="package-detail-tooltip">
      <MenuTrigger vertical active={!!open} />
      <SharedTooltip id="package-detail-tooltip" />
    </div>
  );

  const renderRefundButton = item => {
    const isRefund = _.get(item, 'refund.status', '');

    if (isRefund === INVOICE_REFUND_STATUS.PENDING || isRefund === INVOICE_REFUND_STATUS.SUCCESSED) return;
    return (
      _.get(permission, 'is_allow_refund', false) &&
      [INVOICE_STATUS.PAID].includes(_.lowerCase(item.status)) &&
      process.env.REACT_APP_ENABLE_PAYMENT_REFUND && (
        <Option key="active" data-item={item.id} onClick={() => handleToggleRefundModal(item)}>
          <RefundIcon className="white-color" />
          <span>Refund</span>
        </Option>
      )
    );
  };

  const renderAmount = item => {
    const total = _.get(item, 'total', 0);
    const total_refund = _.get(item, 'total_refund', 0);
    const isRefund = _.get(item, 'refund.status', '');
    const currency = _.get(account, 'currency', 'usd');
    const symbol = TEXT_CURRENCIES.includes(currency)
      ? `${_.get(CURRENCIES, `${currency}.symbol`)} `
      : _.get(CURRENCIES, `${currency}.symbol`);

    let amountElement = (
      <S.PriceValue>{_.isNil(item.total) ? '--' : formatMoney(_.round(item.total / 100, 2), symbol)}</S.PriceValue>
    );

    if (isRefund === INVOICE_REFUND_STATUS.PENDING || isRefund === INVOICE_REFUND_STATUS.SUCCESSED) {
      amountElement = (
        <S.PriceValue>
          <span className="cost">{formatMoney(_.round(total_refund / 100, 2), symbol)}</span>

          <span className="cost-by-refunded">{formatMoney(_.round((total - total_refund) / 100, 2), symbol)}</span>
        </S.PriceValue>
      );
    }

    return amountElement;
  };

  const handleViewDetail = item => () => {
    toggleModal(true, <PurchaseDetailPopup id={item} isMP={isMP} />);
  };

  const handleInboxClient = item => () => {
    const clientId = item.customer._id;
    const clientName = _.get(item, 'customer.full_name', '');
    if (clientId) {
      push(`/home/inbox?profileId=${clientId}&ufn=${encryptData(clientName)}`);
    }
  };

  const handleCancel = item => () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title={`Cancel ${item.customer.full_name}'s Invoice?`}
        content={<CancelMessage />}
        onConfirm={confirmCancel(item.id)}
        confirmButtonTitle="Yes, I’m sure"
        headerIcon={`${CDN_URL}/images/cancel_subscription_confirm_icon.svg`}
        cancelButtonTitle="Nevermind"
        newStyle
        hasCloseIcon
      />,
    );
  };

  const handleRetry = item => () => {
    setRetryInvoice(item);
    toggleConfirmModal(
      true,
      <RetryChargePopup onClose={onCloseRetry} invoice={item} completedCallback={getPackageStatistics} isMP={isMP} />,
    );
  };

  const onCloseRetry = () => {
    refreshInvoices();
    setRetryInvoice(null);
    toggleConfirmModal(false);
  };

  // TODO
  const refreshInvoices = () => {
    setTimeout(() => {
      changeQueryParams(
        {
          sort: 'payment_due',
        },
        undefined,
        isMP,
      );
      countPackages({ id: _.get(retryInvoice, 'package.id') }, isMP);
    }, 2000);
  };

  const confirmCancel = id => () => {
    cancelInvoice({ id }, isMP);
  };

  if (!invoices.length) {
    return (
      <S.TableBody>
        <S.TableRow className="disabled">
          <S.TableCell colSpan="7">No invoices found</S.TableCell>
        </S.TableRow>
      </S.TableBody>
    );
  }

  const handleToggleRefundModal = invoice => {
    if (invoice) {
      toggleSecondModal(
        true,
        <RefundPaymentPopup
          toggleModal={toggleModal}
          toggleSecondModal={toggleSecondModal}
          currency={account.currency}
          refundInvoice={refundInvoice}
          invoice={invoice}
          toggleConfirmModal={toggleConfirmModal}
          showError={props.showError}
        />,
      );
    }
  };

  const handleGetReason = item => {
    let value = '';

    _.forEach(REFUND_REASONS, ele => {
      if (ele.key === _.get(item, 'refund.reason', '')) value = _.get(ele, 'label', '');
    });

    return value;
  };

  const renderRefundCost = value => {
    return formatMoney(_.round(value, 2), _.get(CURRENCIES, `${account.currency || 'usd'}.symbol`));
  };

  const renderStatus = item => {
    let status = _.get(item, 'refund.status', _.get(item, 'status', ''));
    const statusLabel = _.cloneDeep(status);

    if (status === INVOICE_REFUND_STATUS.PENDING) status = 'processing';
    if (status === INVOICE_REFUND_STATUS.SUCCESSED) status = 'refunded';

    let statusElement = <StatusLabel status={_.lowerCase(statusLabel)}>{status}</StatusLabel>;

    if (statusLabel === INVOICE_REFUND_STATUS.SUCCESSED) {
      const refundMount = _.round(_.get(item, 'refund.amount', 0) / 100, 2);
      const content = (
        <S.TooltipContent>
          <S.RefundCost>Refund: {renderRefundCost(refundMount)}</S.RefundCost>
          <S.RefundReason>
            <span className="highlight">Reason:</span> {handleGetReason(item)}
          </S.RefundReason>
          <S.RefundDetails>
            <span className="highlight">Details:</span> {_.get(item, 'refund.note', '')}
          </S.RefundDetails>
        </S.TooltipContent>
      );

      statusElement = (
        <S.Tooltip
          content={content}
          inverted
          position="center top"
          trigger={<StatusLabel status={_.lowerCase(statusLabel)}>{status}</StatusLabel>}
        />
      );
    }

    return statusElement;
  };

  return (
    <>
      <S.TableBody>
        {invoices.map(item => (
          <S.TableRow key={item.id} onClick={handleViewDetail(item.order_id)} data-item={item.id}>
            <S.TableCell>
              <S.CustomerName>{item.customer.full_name}</S.CustomerName>
              <S.CustomerEmail>{item.customer.email}</S.CustomerEmail>
            </S.TableCell>
            <S.TableCell>{renderAmount(item)}</S.TableCell>
            <S.TableCell>
              <S.TableCellContent>{item.purchase_hash_id || item.order_id}</S.TableCellContent>
            </S.TableCell>
            <S.TableCell>
              <S.TableCellContent>#{item.invoice_number}</S.TableCellContent>
            </S.TableCell>
            <S.TableCell>
              <S.TableCellContent>{renderStatus(item)}</S.TableCellContent>
            </S.TableCell>
            <S.TableCell>
              <S.InvoiceOverdue>{formatSameYear(item.payment_due)}</S.InvoiceOverdue>
              <S.OverdueAt>
                {item.status === INVOICE_STATUS.OVERDUE
                  ? `Overdue for ${moment(item.payment_due).fromNow(true)}`
                  : null}
              </S.OverdueAt>
            </S.TableCell>
            <S.TableCell className="actions">
              <S.TableCellContent>
                <DropDown triggerIcon={renderTrigger}>
                  <Option key="active" onClick={handleViewDetail(item.order_id)} data-item={item.id}>
                    <PreviewIcon />
                    <span>Purchase Detail</span>
                  </Option>
                  {renderRefundButton(item)}
                  {[INVOICE_STATUS.PAID, INVOICE_STATUS.OVERDUE, INVOICE_STATUS.FAILED].includes(
                    _.lowerCase(item.status),
                  ) &&
                    item.customer._id && (
                      <Option key="active" onClick={handleInboxClient(item)} data-item={item.id}>
                        <Inbox />
                        <span>Message Client</span>
                      </Option>
                    )}
                  {([INVOICE_STATUS.OVERDUE].includes(_.lowerCase(item.status)) ||
                    (process.env.REACT_APP_ENABLE_RECHARGE_TRIAL_INVOICE &&
                      [INVOICE_STATUS.FAILED].includes(_.lowerCase(item.status)))) && (
                    <Option key="active" onClick={handleRetry(item)} data-item={item.id}>
                      <Retry />
                      <span>Retry Credit Card</span>
                    </Option>
                  )}
                  {[INVOICE_STATUS.SCHEDULED, INVOICE_STATUS.OVERDUE].includes(_.lowerCase(item.status)) && (
                    <Option key="active" onClick={handleCancel(item)} data-item={item.id}>
                      <Cancel />
                      <span>Cancel Invoice</span>
                    </Option>
                  )}
                </DropDown>
              </S.TableCellContent>
            </S.TableCell>
          </S.TableRow>
        ))}
      </S.TableBody>
    </>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics, setupPayment, permission },
  } = state;
  return {
    list: packageAnalytics.get('invoices'),
    account: setupPayment.toJS().account,
    permission,
  };
};

export default connect(mapState, {
  toggleModal,
  toggleSecondModal,
  toggleConfirmModal,
  cancelInvoice,
  changeQueryParams,
  countPackages,
  getPackageStatistics,
  refundInvoice,
  showError,
  push,
})(TableContent);
