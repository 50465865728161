import React from 'react';

import * as S from 'shared/Giphy/styles';

function GifLoader(props) {
  return (
    <S.GifLoader>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </S.GifLoader>
  );
}

export default GifLoader;
