import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .filter__dropdown {
    cursor: pointer;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    color: #262626;
    background-color: #f5f5f5;
    :hover {
      background-color: #f4f4fc;
    }
    .filter__dropdown__title {
      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }
    }
  }
`;

export const MenuContainer = styled.div`
  width: 307px;
  border-radius: 4px;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border: solid 1px #e0e4e5;
  background-color: #fff;
  padding: 14px 23px 22px;
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 999;
  .form-group label {
    margin-bottom: 5px;
    color: #96a4b4;
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
  }
  > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .ui.dropdown {
    width: 100%;
    padding: 6px 10px;
    border-radius: 4px;
    border: solid 1px #e0e4e5;
    margin-bottom: 6px;
    > .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #343c46;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      svg {
        margin-right: 2px;
        padding-bottom: 1px;
        path {
          stroke: #6f7075;
        }
      }
    }
    .menu {
      width: calc(100% + 2px);
      top: calc(100% + 4px);
      border-radius: 4px;
      border: 1px solid #d9d9d9 !important;
      box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
      .item {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        color: #595959;
        padding: 8px 14px !important;
        :hover,
        &.active {
          font-weight: 400;
          color: #5158cf;
          background-color: #f4f4fc;
        }
      }
    }
    &:hover,
    &.visible.active {
      svg path {
        stroke: #5158cf;
      }
    }
  }
`;
