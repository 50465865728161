import styled from 'styled-components';
import { MenuTrigger } from 'shared/Icons';

export const DropdownArrow = styled.span`
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  right: 33.5px;
  top: 3.5px;
  background: #363636;
  border-top: 1px solid #363636;
  border-left: 1px solid #363636;
  transform: rotateZ(45deg);
`;

export const CardWrapper = styled.div`
  position: relative;
  cursor: pointer;
  overflow-x: hidden;
  background: #ffffff;
  box-sizing: initial;
  border: 1px solid #dadfea;
  border-radius: 5px;
  width: 350px;
  .banner-container {
    height: 216px;
    width: 100%;
    .banner {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .content-container {
    padding: 20px 0px;
    .title {
      margin-bottom: 5px;
      padding: 0px 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #202353;
    }
    .description {
      white-space: pre-line;
      padding: 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #202353;
      opacity: 0.8;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .dropdown-area {
    padding: 6px 0;
    background: #363636;
    border-radius: 5px;
  }

  .dropdown-wrapper {
    position: absolute;
    top: 5px;
    right: 9px;
    .dropdown-arrow {
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      right: 33.5px;
      top: 3.5px;
      background: #363636;
      border-top: 1px solid #363636;
      border-left: 1px solid #363636;
      transform: rotateZ(45deg);
    }
    .evf-dropdown.evf-dropdown--basic {
    }
    .evf-dropdown__trigger {
      height: 24px;
      width: 24px;
      background: #000000;
      opacity: 0.1;
      border-radius: 6px;
      :hover {
        background: #ffffff;
        opacity: 1;
      }
    }
    svg {
      width: 15px;
      margin-right: 10px;
      path,
      rect {
        stroke: white;
      }
    }
    .evf-dropdown__menu {
      width: 141px;
      transform: translateY(-0.6px) translateX(25px);
      max-height: auto !important;
      background-color: transparent !important;

      ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
      }

      &.last_item {
        .dropdown-arrow {
          display: none;
        }
      }
    }
    .evf-dropdown__option {
      padding: 7px 20px;
      background: #363636;

      &:hover {
        background-color: #696969;
      }
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  border-radius: 6px;
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  :hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .dot,
  .dot::before,
  .dot::after {
    background-color: #ffffff;
    width: 4px;
    height: 4px;
  }
  .dot {
    ::before {
      left: -6px;
    }
    ::after {
      left: 6px;
    }
  }
`;
