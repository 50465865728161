import Request from 'configs/request';

export const Types = {
  SUCCESS_GET_DEFAULT_SYSTEM_WORKOUTS: 'SUCCESS_GET_DEFAULT_SYSTEM_WORKOUTS',
  FAILED_GET_DEFAULT_SYSTEM_WORKOUTS: 'FAILED_GET_DEFAULT_SYSTEM_WORKOUTS',
};

export const getDefaultSystemWorkouts = () => (dispatch, getState) => {
  const {
    rootReducer: {
      systemWorkout: { query },
    },
  } = getState();

  return dispatch(
    Request.get(
      { url: '/api/workout/v2/list_system', params: query },
      true,
      response => {
        const { data: { data = [], total = 0 } = {} } = response;
        dispatch({ type: Types.SUCCESS_GET_DEFAULT_SYSTEM_WORKOUTS, payload: { data, total } });
      },
      error => {
        dispatch({ type: Types.FAILED_GET_DEFAULT_SYSTEM_WORKOUTS });
      },
    ),
  );
};
