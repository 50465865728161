import styled from 'styled-components';
import Button from 'shared/FormControl/Button';

export const Wrapper = styled.div`
  width: 100%;
  padding: 30px;
  background: #fafbfc;
  overflow: auto;
`;

export const FormContainer = styled.div`
  max-width: 720px;
  margin: auto;
  padding: 30px 20px;
  border: 1px solid #dadfea;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #9292d226;
  background: #fff;
`;

export const FormHeader = styled.div`
  .title {
    font-weight: bold;
    color: ##202353;
    font-size: 18px;
  }
`;

export const FormContent = styled.div`
  & > * {
    margin: 15px 0 !important;
  }
`;

export const Label = styled.label`
  font-weight: semi-bold !important;
  font-size: 10px !important;
  color: #6a778a !important;
`;

export const InputContainer = styled.div`
  position: relative;
  .character-count {
    position: absolute;
    right: 15px;
    top: 12px;
    color: rgba(0, 0, 0, 30%);
    font-size: 13px;
  }
`;

export const Input = styled.input`
  max-height: 42px;
  padding: 12px 75px 12px 15px !important;
  background-color: #fff !important;
  font-size: 13px !important;
  font-weight: normal !important;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000 !important;
    font-weight: normal;
    font-size: 13px;
    opacity: 30%;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #000 !important;
    font-weight: normal;
    font-size: 13px;
    opacity: 30%;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    ccolor: #000 !important;
    font-weight: normal;
    font-size: 13px;
    opacity: 30%;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #000 !important;
    font-weight: normal;
    font-size: 13px;
    opacity: 30%;
  }
`;

export const DescriptionInput = styled.textarea`
  max-height: 140px !important;
  padding: 12px 15px !important;
  resize: none !important;
  font-size: 13px !important;

  &:active,
  &:focus {
    border-color: #726ee4 !important;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000 !important;
    font-weight: normal;
    font-size: 13px;
    opacity: 30%;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #000;
  }
`;

export const SubmitButton = styled(Button)`
  font-size: 13px;
  padding: 7px 30px;
`;

export const FormNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  :hover {
    path {
      stroke: #5158cf;
    }
  }
`;
