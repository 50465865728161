import React from 'react';
import styled from 'styled-components';
import withResponsive from 'hoc/withResponsive';
import { CDN_URL } from 'constants/commonData';

export default withResponsive(() => (
  <Wrapper>
    <LogoContainer>
      <img src={`${CDN_URL}/images/logo.png`} className="logo" alt="" />
    </LogoContainer>
    <Heading>Thanks for signing up!</Heading>
    <Content>Start on mobile by downloading the Everfit coach app or log in on the web to start training now.</Content>
    <a href="https://apps.apple.com/us/app/everfit-for-coach/id1485827117?ls=1" className="download-link">
      <img src={`${CDN_URL}/images/appstore_available.svg`} alt="" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.everfit.coach" className="download-link">
      <img src={`${CDN_URL}/images/googleplay_available.svg`} alt="" />
    </a>
    <BackgroundContainer />
    <BackToHome href="https://everfit.io">Explore features</BackToHome>
  </Wrapper>
));

const LogoContainer = styled.div`
  min-height: 50px;
  margin: 0 auto 15px;
  img.logo {
    width: 116px;
  }
`;

const Heading = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #161616;
  text-align: center;
  margin: 0 auto 20px;
`;

const Content = styled.div`
  padding: 0 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  text-align: center;
  padding-top: 50px;
  height: 100vh;
  min-height: 500px;
  position: relative;
  overflow: auto;

  .download-link {
    display: inline-block;
    margin: 0 5px 7px;
  }

  @media (max-width: 500px) {
    ${Heading} {
      max-width: 300px;
    }
  }

  @media (max-height: 600px) {
    padding-top: 60px;
  }
`;

const BackgroundContainer = styled.div`
  background-image: url(${CDN_URL}/images/signup_mobile_banner.png);
  height: 276px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
`;

const BackToHome = styled.a`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5158cf;
  margin-bottom: 22px;
`;
