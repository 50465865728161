import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

import { CDN_URL, PLANS } from 'constants/commonData';
import { checkPermissionAccessZapier } from 'utils/commonFunction';

import ZapierProgressBar from './ZapierProgressBar';
import ZapierUsageLimit from './ZapierUsageLimit';
import ZapierChangePlanModal from './ZapierChangePlanModal';
import AdvancedSettings from './AdvancedSettings';
import OverdueZapierPopup from '../../layouts/home/Home/components/OverdueZapierPopup';

import { ReactComponent as LearnMoreIcon } from 'assets/icons/learn-about-package.svg';
import { ReactComponent as CopyKeyIcon } from 'assets/icons/copy_key.svg';
import InfoConfirmIcon from 'assets/icons/warning_violet.svg';

import * as S from './style';

const IntegrationSetting = props => {
  const {
    toggleModal,
    toggleConfirmModal,
    getCurrentUsage,
    getZapierKey,
    resetZapierKey,
    zapierBasePricings,
    period,
    teamData,
    isEnableZapier,
  } = props;

  if (!isEnableZapier) {
    return null;
  }

  const zapierKey = get(props, 'zapierKey', null);
  const zapierQuantity = get(props, 'currentZapierQuantity', null);

  const [tooltipContent, setTooltipContent] = useState('Copy API Secret');

  const planWillSelect = {
    tier: PLANS.studio.key,
    addOns: {
      zapier: true,
    },
  };

  useEffect(() => {
    getZapierKey && getZapierKey();
    zapierQuantity && getCurrentUsage && getCurrentUsage();
  }, []);

  useEffect(() => {
    return () => {
      toggleModal(false);
      toggleConfirmModal(false);
    };
  }, []);

  const handleOpenToggleModal = () => {
    toggleModal(true, <ZapierChangePlanModal currentPlan={zapierQuantity} />);
  };

  const handleResetAPI = () => {
    resetZapierKey && resetZapierKey();
  };

  function filterPricingByPlan(pricings, plan) {
    return pricings
      .map((pricing, index) => ({ ...pricing, index }))
      .filter(pricing => get(pricing, 'packageNoVolumes', 0) === plan);
  }
  const filteredPricing = filterPricingByPlan(zapierBasePricings, zapierQuantity);
  const zapierCurrentTier = (get(filteredPricing, '[0].index', 0) + 1).toLocaleString('en-US');

  const zapierCurrentPrice =
    period === 'monthly'
      ? `$${get(filteredPricing, '[0].monthlyPricing', 0).toLocaleString('en-US')}/month`
      : `$${get(filteredPricing, '[0].monthOfAnuallyPricing', 0).toLocaleString('en-US')}/month`;

  const handleResetKey = () => {
    toggleConfirmModal(
      true,
      <S.WarningConfirmModal
        title={'Reset API Secret'}
        content={`Are you sure? Resetting your API secret will affect all existing Zaps that are integrated with your account.`}
        onConfirm={handleResetAPI}
        confirmButtonTitle="Reset"
        cancelButtonTitle="Cancel"
        headerIcon={InfoConfirmIcon}
        noBorder
      />,
    );
  };

  const secretKeyRef = useRef(null);

  const handleCopyKey = () => {
    if (secretKeyRef.current) {
      const textToCopy = secretKeyRef.current.innerText;

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setTooltipContent('Copied');
        })
        .catch(error => {
          console.error('Copy failed:', error);
        });
    }
  };

  const handleTooltipMouseLeave = () => {
    setTooltipContent('Copy API Secret');
  };

  const canAccessZapier = checkPermissionAccessZapier(teamData);
  if (!canAccessZapier) {
    return <OverdueZapierPopup />;
  }

  const renderAdvancedSettings = () => {
    return (
      <AdvancedSettings>
        <ZapierUsageLimit zapierCurrentTier={zapierCurrentTier} zapierCurrentPrice={zapierCurrentPrice} />
      </AdvancedSettings>
    );
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.ContainerZapier hasKey={zapierKey}>
          <S.LeftContainer>
            <S.LogoZapierWrapper>
              <img src={`${CDN_URL}/images/logo_zapier.png`} alt="" />
            </S.LogoZapierWrapper>
            <S.DescriptionContainer hasKey={zapierKey}>
              Automate app workflows, saving time and boosting productivity with seamless cross-platform data transfer
              and integration.
              <S.FeatureLearnMore
                href="https://help.everfit.io/en/collections/6758136-zapier-integration"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreIcon />
                <S.FeatureLinkTitle>Learn more</S.FeatureLinkTitle>
              </S.FeatureLearnMore>
            </S.DescriptionContainer>
          </S.LeftContainer>
          <S.RightContainer>
            <S.GetKeyContainer hasKey={zapierKey}>
              <S.APILabel>API SECRET</S.APILabel>
              <S.SecretKeyContainer hasKey={zapierKey}>
                <S.SecretKey emptyKey={!zapierKey} ref={secretKeyRef}>
                  {zapierKey && zapierQuantity ? zapierKey : '----'}
                </S.SecretKey>
                {zapierKey && zapierQuantity > 0 && (
                  <>
                    <ReactTooltip className="tooltip-copy-zapier" id="tooltip--copy" effect="solid">
                      {tooltipContent}
                    </ReactTooltip>
                    <S.CopyKeyButton
                      data-tip="tooltip--copy"
                      data-for="tooltip--copy"
                      onMouseLeave={handleTooltipMouseLeave}
                      onClick={handleCopyKey}
                    >
                      <CopyKeyIcon />
                    </S.CopyKeyButton>
                  </>
                )}
              </S.SecretKeyContainer>
              {!zapierQuantity && (
                <S.UpgradePathAutoflowButton
                  className="upgrade-path-button"
                  text="Get Zapier"
                  planWillSelect={planWillSelect}
                />
              )}
            </S.GetKeyContainer>
            {zapierKey && zapierQuantity > 0 && (
              <S.ResetKeyButton onClick={handleResetKey}>Reset API Secret</S.ResetKeyButton>
            )}
          </S.RightContainer>
        </S.ContainerZapier>
        {zapierQuantity ? (
          <>
            <S.ProgressBarContainer>
              <ZapierProgressBar zapierCurrentTier={zapierCurrentTier} zapierCurrentPrice={zapierCurrentPrice} />
            </S.ProgressBarContainer>
            {/* TODO - V2 */}
            {/* <S.ProgressBarContainer>{renderAdvancedSettings()}</S.ProgressBarContainer> */}
            <S.ChangePlanContainer>
              <S.ChangePlanButton className="change-plan-button" onClick={handleOpenToggleModal}>
                Change Plan
              </S.ChangePlanButton>
            </S.ChangePlanContainer>
          </>
        ) : null}
      </S.Container>
    </S.Wrapper>
  );
};

export default IntegrationSetting;
