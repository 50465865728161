import styled, { css } from 'styled-components';
import { Popup, Trigger } from '../DropdownOption/style';

const baseText = `
    font-family: 'Open Sans';
    font-style: normal;
    line-height: normal;
    color: #202353;
    line-height: 150%;
    font-weight: 600;
`;

export const buttonStyle = `
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 2px;
    white-space: nowrap;

    border: 1px solid #e1e1ea;
    background: #fff;

    :hover {
      cursor: pointer;
      background:  #F0F1FF;
      color: #5158CF;
    }

    .arrow-icon{
      margin-left: 5px;
    }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MetricTitle = styled.div`
  ${baseText}
  font-size: 19px;
  line-height: 130%;
  margin-right: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: default;
  margin-top: -3px;
`;

export const ChartFilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;

  ${baseText}
  text-align: center;
  font-size: 12px;
  z-index: 3;
`;

export const SelectRangeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: -1px;
`;

export const SelectRangeButton = styled.div`
  ${Popup} {
    width: 165px;
    left: 0;
    top: 38px;
  }

  ${Trigger} {
    ${buttonStyle}
    padding-right: 7px;
    border-radius: 5px 0px 0px 5px;
  }
`;

export const SelectDateFilter = styled.div`
  ${buttonStyle}

  border-left: none;
  border-radius: 0px 5px 5px 0px;
`;

export const SelectViewType = styled.div`
  ${Trigger} {
    ${buttonStyle}
    padding-right: 7px;
    border-radius: 5px;
  }

  ${Popup} {
    width: 125px;
    right: 0;
  }

  ${({ isLoadingMetric }) =>
    isLoadingMetric &&
    css`
      cursor: not-allowed;
    `}
`;

export const SettingButton = styled.div`
  ${buttonStyle}
  position: relative;
  border-radius: 5px;

  ${({ isLoadingMetric }) =>
    isLoadingMetric &&
    css`
      cursor: not-allowed !important;
    `}

  :hover {
    .setting-icon {
      path {
        stroke: #5158cf;
      }
    }

    ::after,
    ::before {
      display: flex;
    }
  }

  .setting-icon {
    width: 12.97px;
    height: 13.33px;
  }

  ::before {
    display: none;
    content: '';
    position: absolute;
    top: 43px;
    width: 12px;
    height: 10px;
    transform: rotate(135deg);
    background: #333;
  }

  :after {
    display: none;
    content: 'Chart Settings';
    width: 111px;
    height: 34px;
    border-radius: 4px;
    background: #333;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    ${baseText}
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    word-break: keep-all;
    position: absolute;
    top: 47px;
    right: -9px;
  }
`;
