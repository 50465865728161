import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import has from 'lodash/has';

import { NewSearchInput } from 'shared/SearchInput';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_gray_out.svg';
import { ReactComponent as NoResultsIcon } from 'assets/icons/on-demand-workout-search-no-results.svg';
import LoadingIndicator from 'shared/LoadingIndicator';
import AddNewForm from './AddNewForm';
import CollectionsBanner from './CollectionsBanner';
import FirstTimeWrapper from './FirstTimeWrapper';
import { ReactComponent as FilterIcon } from 'assets/icons/new_filter.svg';
import ButtonIcon from 'shared/ButtonIcon';
import FiltersPopup, { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';
import ItemWorkoutCollection from './ItemWorkoutCollection';

import * as S from './style';

const LIMIT_LENGTH = 30;

const AddNewPopUp = props => {
  const { onSubmitAddNew, isPassedFirstTimeAdd, editCollectionOnboarding, text, user } = props;
  const [shouldShowOnBoarding, setShouldShowOnBoarding] = useState(false);

  const handleShowOnBoarding = () => {
    setShouldShowOnBoarding(!shouldShowOnBoarding);
  };

  const handleSubmit = (val, shareParams = {}, callback = null) => {
    const _val = handleCheckLimit(val);

    onSubmitAddNew && onSubmitAddNew({ ..._val, ...shareParams });
    callback && callback();
  };

  // TODO - Check Limit character
  const handleCheckLimit = val => {
    const names = ['name', 'type'];

    names.forEach(item => {
      if (has(val, item)) {
        if (val[item] <= LIMIT_LENGTH) return;
        val[item] = val[item].slice(0, LIMIT_LENGTH);
      }
    });

    return val;
  };

  return (
    <AddNewForm
      text={text || 'Create New Collection'}
      heightButton={36}
      widthButton={200}
      maxLength={30}
      icon={<PlusIcon />}
      buttonClassName="buttonClassName"
      titlePopup="Create New Collection"
      titleInput="Name"
      placeholderInput="Collection Name"
      submitName="Create"
      onSubmit={handleSubmit}
      isPassedFirstTimeAdd={false}
      user={user}
      fields={[
        {
          name: 'type',
          label: (
            <span>
              Type{' '}
              {
                <FirstTimeWrapper
                  editCollectionOnboarding={editCollectionOnboarding}
                  isPassedFirstTimeAdd={isPassedFirstTimeAdd}
                  shouldShowOnBoarding={shouldShowOnBoarding}
                  setShouldShowOnBoarding={setShouldShowOnBoarding}
                />
              }
              {isPassedFirstTimeAdd && (
                <S.InfoIconArea>
                  <InfoIcon onClick={handleShowOnBoarding} />
                </S.InfoIconArea>
              )}
            </span>
          ),
          required: true,
          isHideErrorMessage: true,
          autoFocus: false,
          placeholder: 'Add a label for the Collection Type',
          maxLength: 30,
        },
      ]}
    />
  );
};

const WorkoutCollectionsHeader = props => {
  const {
    textSearch,
    isPassedFirstTimeAdd,
    editCollectionOnboarding,
    onSubmitAddNew,
    handleSearch,
    handleClearSearch,
    handleKeyPress,
    user,
    counterFilters,
    showFilters,
    filters,
    updateFilters,
    toggleShowFilters,
  } = props;

  const resetFilter = () => {
    updateFilters({ owner: OWNER_TYPE.ALL });
  };

  return (
    <S.CollectionsHeader>
      <div className="search-filter-container">
        <NewSearchInput
          placeholder="Search by keyword or name"
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          onKeyPress={handleKeyPress}
          defaultValue={textSearch}
        />
        <S.FilterButtonWrapper>
          <ButtonIcon
            text="Filter"
            width={104}
            height={36}
            className="filter-options-header"
            icon={<FilterIcon />}
            color="rgb(119, 119, 119)"
            textHoverColor="rgb(119, 119, 119)"
            bgHoverColor="rgb(248, 248, 248)"
            onClick={toggleShowFilters}
            isShowFilter={showFilters}
          />
          {counterFilters > 0 && <S.CounterFilters>{counterFilters}</S.CounterFilters>}
          {showFilters && (
            <FiltersPopup currentFilters={filters} onUpdateFilters={updateFilters} onCloseFilters={toggleShowFilters} />
          )}
        </S.FilterButtonWrapper>

        <S.ResetFilter onClick={resetFilter} hide={counterFilters === 0}>
          Reset
        </S.ResetFilter>
      </div>

      <AddNewPopUp
        onSubmitAddNew={onSubmitAddNew}
        isPassedFirstTimeAdd={isPassedFirstTimeAdd}
        editCollectionOnboarding={editCollectionOnboarding}
        user={user}
      />
    </S.CollectionsHeader>
  );
};

const EmptyList = () => {
  return (
    <S.EmptyArea>
      <S.EmptyAreaBanner></S.EmptyAreaBanner>
      <S.EmptyAreaText>First, create your on-demand workouts Then add them to your new Collections</S.EmptyAreaText>
    </S.EmptyArea>
  );
};

const CollectionList = props => {
  const { workouts, isEnd, textSearch, total, isHideBanner, loading } = props;

  const renderShowEmpty = () => {
    if (!!textSearch) {
      return (
        <S.NotFound>
          <NoResultsIcon />
          <div className="text">No results found</div>
        </S.NotFound>
      );
    }
    return (
      <>
        {workouts.length <= 0 && loading ? (
          <LoadingIndicator size="medium" title="Loading workout collections" />
        ) : (
          <S.CollectionEmptyList isHideBanner={isHideBanner}>
            <EmptyList />
          </S.CollectionEmptyList>
        )}
      </>
    );
  };

  const renderItem = workout => {
    return <ItemWorkoutCollection key={workout._id} workout={workout} {...props} />;
  };

  return (
    <>
      {!loading && workouts.length <= 0 && renderShowEmpty()}
      {loading ? (
        <LoadingIndicator size="medium" title="Loading workout collections" />
      ) : (
        <S.CollectionsList isHideBanner={isHideBanner}>
          <S.CardListWrapper>{workouts.map(renderItem)}</S.CardListWrapper>
          {!isEnd && workouts.length < total && <LoadingIndicator className="loading" />}
        </S.CollectionsList>
      )}
    </>
  );
};

export default function WorkoutCollections({
  workouts,
  cloudfrontList,
  getWorkoutCollectionList,
  addNewWorkoutCollection,
  push,
  toggleConfirmModal,
  removeCollection,
  duplicateCollection,
  textSearch,
  changeQueryParams,
  query,
  loading,
  loadMoreWorkoutCollections,
  isPassedFirstTimeAdd,
  editCollectionOnboarding,
  resetLoadMore,
  payment,
  hideWorkoutCollectionBanner,
  isHideBanner,
  user,
  toggleModal,
  updateShareStatus,
  onboarding_flow,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ owner: 'all' });

  useEffect(() => {
    getWorkoutCollectionList && getWorkoutCollectionList({ perPage: query.perPage }, true);
    return () => {
      resetLoadMore && resetLoadMore();
      handleClearSearch();
    };
  }, []);

  const handleLoadMore = event => {
    const { total, isEnd } = query;
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && workouts.length < total && !loading && !isEnd) {
      loadMoreWorkoutCollections();
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams({ textSearch: value, page: 1 });
  };

  const handleClearSearch = () => {
    handleSearch(null, { value: '' });
  };

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 });
    }
  };

  const updateFilters = filters => {
    setFilters(filters);

    typeof changeQueryParams === 'function' &&
      changeQueryParams({
        page: 1,
        only_my_workout_collection: filters.owner === OWNER_TYPE.YOU,
      });
  };

  const toggleShowFilters = () => {
    setShowFilters(it => !it);
  };

  const counterFilters = useMemo(() => {
    let counter = 0;
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'owner' && value === 'you') {
        counter = 1;
      }
    }
    return counter;
  }, [filters]);

  return (
    <S.Wrapper onScroll={event => handleScroll(event)}>
      {isHideBanner ? (
        <WorkoutCollectionsHeader
          onSubmitAddNew={addNewWorkoutCollection}
          textSearch={textSearch}
          isPassedFirstTimeAdd={isPassedFirstTimeAdd}
          editCollectionOnboarding={editCollectionOnboarding}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
          handleKeyPress={handleKeyPress}
          user={user}
          counterFilters={counterFilters}
          showFilters={showFilters}
          filters={filters}
          updateFilters={updateFilters}
          toggleShowFilters={toggleShowFilters}
        />
      ) : (
        <CollectionsBanner
          onSetIsHideBanner={hideWorkoutCollectionBanner}
          onSubmitAddNew={addNewWorkoutCollection}
          isPassedFirstTimeAdd={isPassedFirstTimeAdd}
          editCollectionOnboarding={editCollectionOnboarding}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
          handleKeyPress={handleKeyPress}
          changeQueryParams={changeQueryParams}
        >
          <AddNewPopUp
            text="Add New Collection"
            onSubmitAddNew={addNewWorkoutCollection}
            isPassedFirstTimeAdd={isPassedFirstTimeAdd}
            editCollectionOnboarding={editCollectionOnboarding}
            user={user}
          />
        </CollectionsBanner>
      )}
      <CollectionList
        payment={payment}
        workouts={workouts || []}
        push={push}
        toggleConfirmModal={toggleConfirmModal}
        removeCollection={removeCollection}
        duplicateCollection={duplicateCollection}
        isEnd={query.isEnd}
        total={query.total}
        textSearch={textSearch}
        isHideBanner={isHideBanner}
        loading={loading}
        toggleModal={toggleModal}
        user={user}
        updateShareStatus={updateShareStatus}
        cloudfrontList={cloudfrontList}
        onboardingFlow={onboarding_flow}
      />
    </S.Wrapper>
  );
}
