import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as S from './style';
import FormModal from '../FormModal';
import { TemplateLoading } from './Loader';
import { CDN_URL } from 'constants/commonData';

const { Content } = S.ChooseTempalateModal;

function ChooseTemplate({ leaderboard, onClose, ...rest }) {
  const leaderboardData = useMemo(() => leaderboard.toJS(), [leaderboard]);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [currentCategory, setCurrentCategory] = useState('');
  const [currentTemplates, setCurrentTemplates] = useState(leaderboardData.templates);

  useEffect(() => {
    if (!currentCategory && leaderboardData.templates.length && !currentTemplates.length) {
      setCurrentTemplates(leaderboardData.templates);
    }
  }, [leaderboardData.templates, currentTemplates, currentCategory]);

  const onSelectCategory = event => {
    const { categoryCode } = event.currentTarget.dataset;

    if (categoryCode === currentCategory) {
      return;
    }

    if (!categoryCode) {
      setCurrentCategory('');
      setCurrentTemplates(leaderboardData.templates);
    } else {
      setCurrentCategory(categoryCode);
      setCurrentTemplates(leaderboardData.templates.filter(item => item.categories.includes(categoryCode)));
    }
  };

  const onSelectTemplate = event => {
    const { templateId } = event.currentTarget.dataset;

    const template = leaderboardData.templates.find(item => item._id === templateId);
    setSelectedTemplate(template);
  };

  if (selectedTemplate) {
    return <FormModal onClose={onClose} template={selectedTemplate} {...rest} />;
  }

  return (
    <S.ChooseTempalateModal
      className="modal--chooseLeaderboardTemplate"
      open={true}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Content>
        <S.ContentContainer>
          <S.LeftSide>
            <S.Header>Categories</S.Header>
            <S.ListCategory>
              <S.CategoryItem
                onClick={onSelectCategory}
                className={classnames('leaderboardTemplate__category', { active: !currentCategory })}
                key="all"
              >
                All
              </S.CategoryItem>
              {leaderboardData.categories.map(cat => (
                <S.CategoryItem
                  data-category-code={cat.code}
                  onClick={onSelectCategory}
                  className={classnames('leaderboardTemplate__category', { active: currentCategory === cat.code })}
                  key={cat.code}
                >
                  {cat.label}
                </S.CategoryItem>
              ))}
            </S.ListCategory>
          </S.LeftSide>
          <S.RightSide>
            <S.Header>Choose a Leaderboard template</S.Header>
            <S.ListTemplateContainer>
              <S.ListTemplate>
                {!leaderboardData.rankingTypes.length ? (
                  <>
                    <TemplateLoading key="1" />
                    <TemplateLoading key="2" />
                    <TemplateLoading key="3" />
                  </>
                ) : (
                  currentTemplates.map(template => (
                    <S.TemplateItem data-template-id={template._id} onClick={onSelectTemplate} key={template._id}>
                      <div className="coverImage">
                        <img src={template.banner_url} alt="" />
                      </div>
                      <div className="templateInfo">
                        <div className="templateInfo__title">{template.title}</div>
                        <div className="templateInfo__description">{template.description}</div>
                      </div>
                    </S.TemplateItem>
                  ))
                )}
              </S.ListTemplate>
            </S.ListTemplateContainer>
          </S.RightSide>
        </S.ContentContainer>
      </Content>
    </S.ChooseTempalateModal>
  );
}

const mapState = state => {
  const { leaderboard } = state.rootReducer;
  return { leaderboard };
};

export default connect(mapState)(ChooseTemplate);
