// Libs
import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

// Styles
import * as S from './styles';

const GoToIcon = props => {
  const { tooltipId = 'go-to-icon-tooltip', href, disable, tooltipContent = 'View details' } = props;

  return (
    <S.GoToIconWrapper className={classNames('go-to-icon', { disable })} data-for={tooltipId} data-tip>
      <a href={href || '#'} target="_blank" rel="noopener noreferrer" className="open-in-new-tab-icon">
        <img src="/images/open-in-new-tab-icon-normal.svg" alt="" className="normal" />
        <img src="/images/open-in-new-tab-icon-active.svg" alt="" className="active" />
      </a>
      <ReactTooltip
        id={tooltipId}
        className="go-to-icon-tooltip"
        place="top"
        effect="solid"
        offset={{ left: disable ? 93 : 0 }}
      >
        <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
      </ReactTooltip>
    </S.GoToIconWrapper>
  );
};

export default GoToIcon;
