import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const CreateNewPackageModal = styled(Modal)`
  &.ui.modal {
    width: 540px;
  }

  .header {
    border-radius: 8px;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    color: #202353 !important;
  }

  .content {
    padding: 30px !important;
  }

  .addPackage__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .addPackage {
    &__form {
    }

    &__coverImageInput {
      max-width: 215px;
      margin-bottom: 30px;
    }

    &__footer {
      display: flex;
      flex-direction: row-reverse;

      button {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
  }
  .copyPackage_form input {
    font-weight: 600;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;
