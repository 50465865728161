import styled from 'styled-components';

export default styled.div`
  .add-rest-day-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancel-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #ea314a;
      cursor: pointer;
    }
  }

  .rest-day-section {
    margin-top: 20px;
    border: 1px solid #dadfea;
    border-radius: 5px;
    padding: 15px;
  }
`;
