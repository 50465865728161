import React, { Component } from 'react';
import { Loader, Image, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// import from lodash
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { DragDropContext } from 'react-beautiful-dnd';
import ConfirmModal from 'shared/ConfirmModal';
import './styles.scss';
import ProgramCalendar from 'components/ProgramCalendar';
import { showError } from 'actions/error';
import ProgramCalendarWeek from 'shared/ProgramCalendarWeek';
import ProgramViewClients from 'components/ProgramViewClients';
import {
  CALENDAR_LIMIT_WEEKS,
  CALENDAR_TYPES,
  CDN_URL,
  CLASSNAME_TOAST,
  KEY_CODE,
  PROGRAM_COMPLETED,
} from 'constants/commonData';
import SelectCalendarType from 'shared/SelectCalendarType';
import RemoveWeekAction from 'shared/RemoveWeekAction';
import { getUserShortName, saveLastLibraryRoute, pluralize } from 'utils/commonFunction';
import AssignButton from './AssignButton';
import DropDown, { Option } from 'shared/Dropdown/Basic';

import { ReactComponent as TrashIcon } from 'assets/icons/trash-calendar.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-calendar.svg';
import { ReactComponent as CloseIconBottom } from 'assets/icons/close-copy-week.svg';
import { ReactComponent as TrashWorkoutIcon } from 'assets/icons/trash-copy-workouts.svg';
import { ReactComponent as CopyWorkoutIcon } from 'assets/icons/copy-workouts.svg';

import { toast } from 'react-toastify';
import { RemovePopup } from 'components/AutoflowInterval/Calendar/styles';
import { AddWeekButton, AddWeekGuide } from './style';
import * as S from './style';
import classnames from 'classnames';

export default class ProgramListCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarNumber: -1,
      isHovering: false,
      removingIndex: null,
    };
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleResetState = this.handleResetState.bind(this);
  }

  componentDidMount() {
    const {
      match,
      toggleSideBar,
      getClientsAssignedToProgram,
      fetchJobAssignProgram,
      user,
      getProgramLibraryDetail,
      push,
      calendarType,
      getProgramLibraryDetailByWeeks,
    } = this.props;

    const id = match.params.programId;
    getProgramLibraryDetail(id).catch(() => push('/home/program'));
    setTimeout(() => {
      getProgramLibraryDetailByWeeks({
        pid: id,
        startWeek: 0,
        endWeek: calendarType,
      });
    }, 500);

    saveLastLibraryRoute('/home/program');
    toggleSideBar(false);
    fetchJobAssignProgram(user._id, id);
    getClientsAssignedToProgram(id);
    this.setState({
      calendarNumber: -1,
    });
  }

  componentWillUnmount() {
    const { handleResetSelectWorkout, handleResetSelectedWeek } = this.props;
    document.removeEventListener('keydown', this.onPressEscapeKey);
    this.props.calendarUnmount();
    handleResetSelectWorkout && handleResetSelectWorkout();
    handleResetSelectedWeek && handleResetSelectedWeek();
  }

  onPressEscapeKey = event => {
    if (event.keyCode !== KEY_CODE.esc) return;
    this.handleResetState();
  };

  handleResetState = () => {
    const {
      handleResetSelectWorkout,
      handleResetSelectedWeek,
      copyingWorkout,
      toggleConfirmModal,
      resetCopyItem,
    } = this.props;

    const hasModal = document.querySelector('.ui.modals');
    if (hasModal) {
      toggleConfirmModal(false);
      return;
    }

    if (document.querySelector('.cancel-copy')) {
      handleResetSelectWorkout && handleResetSelectWorkout();
      handleResetSelectedWeek && handleResetSelectedWeek();
    }

    if (copyingWorkout) {
      resetCopyItem();
    }
  };

  onBeforeCapture = event => {
    const [type] = event.draggableId.split('_');
    if (type === 'workout') {
      this.setState({ isDragging: true });
    }
  };

  handleGoToWeek = week => {
    const { goToWeek, getProgramLibraryDetailByWeeks, match, calendarType } = this.props;
    const id = match.params.programId;
    getProgramLibraryDetailByWeeks &&
      getProgramLibraryDetailByWeeks({
        pid: id,
        startWeek: week,
        endWeek: week + calendarType,
      });
    goToWeek && goToWeek(week);
  };

  handleChangeType = type => {
    const { changeCalendarType, getProgramLibraryDetailByWeeks, match, currentWeek } = this.props;

    const id = match.params.programId;
    getProgramLibraryDetailByWeeks &&
      getProgramLibraryDetailByWeeks({
        pid: id,
        startWeek: currentWeek,
        endWeek: currentWeek + type,
      });
    changeCalendarType && changeCalendarType(type);
  };

  handleCopyWeek = weekIndex => {
    const { handleSelectedWeek } = this.props;
    const weekNumber = weekIndex + 1;

    handleSelectedWeek && handleSelectedWeek(weekIndex);
    toast(`Week ${weekNumber} Copied. Click on week to paste workouts.`, {
      className: CLASSNAME_TOAST.TRAINING_CALENDAR,
    });
  };

  render() {
    const { selectedProgram } = this.props;
    if (!selectedProgram) {
      return null;
    }

    return (
      <DragDropContext
        onDragEnd={r => {
          this.setState({ isDragging: false });
          this.props.onDragEnd(r, selectedProgram._id);
        }}
        onBeforeCapture={this.onBeforeCapture}
      >
        <div className="calendar-wrapper program-calendar-wrapper">
          {!!selectedProgram && (
            <Helmet>
              <title>{selectedProgram.title} - Program - Everfit</title>
            </Helmet>
          )}
          <div className="calendar-container">
            {this.renderNavigationBar()}
            {this.renderCalendar()}
          </div>
        </div>
      </DragDropContext>
    );
  }

  onRemoveAllWorkoutInWeek = weekIndex => {
    const { handleRemoveWorkoutWeek, toggleConfirmModal } = this.props;
    const weekNumber = weekIndex + 1;
    toggleConfirmModal(
      true,
      <S.RemoveAllWorkoutInWeekPopup
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove workouts from Week ${weekNumber}?`}
        content={`All workouts from Week ${weekNumber} will be deleted. Would you like to continue?`}
        onConfirm={() => handleRemoveWorkoutWeek(weekIndex)}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
        className="program-remove-week-popup"
      />,
    );
  };

  renderTrashToolTip(index, weekHasWorkout) {
    document.addEventListener('keydown', this.onPressEscapeKey);
    if (!weekHasWorkout) {
      return (
        <S.IconWrapper>
          <TrashIcon
            data-tip
            data-for={`tooltip-trash-${index}`}
            onClick={() => this.handleDeleteAction(index)}
            className="trash-icon"
          />
          <ReactTooltip className="app-tooltip" id={`tooltip-trash-${index}`} effect="solid" place="right">
            Remove entire week
          </ReactTooltip>
        </S.IconWrapper>
      );
    }
    return (
      <S.RemoveWrapper>
        <RemoveWeekAction
          onRemoveAllWorkoutInWeek={() => this.onRemoveAllWorkoutInWeek(index)}
          onDeleteWeek={() => this.handleDeleteAction(index)}
          className="program-library-remove-action"
        />
      </S.RemoveWrapper>
    );
  }

  renderCopyToolTip(index) {
    document.addEventListener('keydown', this.onPressEscapeKey);
    return (
      <S.IconWrapper>
        <CopyIcon
          data-tip
          data-for={`tooltip-copy-${index}`}
          onClick={() => this.handleCopyWeek(index)}
          className="copy-icon"
        />
        <ReactTooltip className="app-tooltip" id={`tooltip-copy-${index}`} effect="solid" place="right">
          Copy Week
        </ReactTooltip>
      </S.IconWrapper>
    );
  }

  handleOnMouseEnter = id => {
    this.setState({
      calendarNumber: id,
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      calendarNumber: -1,
    });
  };

  handleCopyWorkouts = ids => {
    const { handleCopyWorkouts } = this.props;
    const selectedLength = ids.length;
    !isEmpty(ids) &&
      toast(`${selectedLength} ${pluralize('Workout', selectedLength)} copied. Click on date to paste workouts.`, {
        className: CLASSNAME_TOAST.TRAINING_CALENDAR,
      });
    handleCopyWorkouts && handleCopyWorkouts(ids);
  };

  onRemoveWorkouts = selectedWorkouts => {
    const { toggleConfirmModal, handleRemoveWorkouts } = this.props;
    toggleConfirmModal(
      true,
      <S.RemoveMultipleWorkoutPopup
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove ${selectedWorkouts.length} ${pluralize('workout', selectedWorkouts.length)}`}
        content={`The selected workouts will be permanently deleted. Would you like to continue?`}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        onConfirm={() => handleRemoveWorkouts()}
        noBorder={true}
        hasCloseIcon={true}
        className="program-remove-multiple-popup"
      />,
    );
  };

  renderBottomSelectWorkout(selectedWorkout) {
    return (
      <S.BottomSelectWorkoutWrapper>
        <S.BottomNumberSelect>
          <S.BottomNumber>{selectedWorkout.length}</S.BottomNumber>
          <S.BottomText> {`${pluralize('Workout', selectedWorkout.length)} selected`}</S.BottomText>
        </S.BottomNumberSelect>
        <S.BottomActionsContainer>
          <>
            <S.BottomActions
              data-tip
              data-for="tooltip--remove-multiple-workout"
              onClick={() => this.onRemoveWorkouts(selectedWorkout)}
            >
              <S.IconActions>
                <TrashWorkoutIcon />
              </S.IconActions>
              <S.TextActions>Remove</S.TextActions>
              <ReactTooltip
                className="app-tooltip remove-workouts copy-remove-bottom-calendar__tooltip"
                id="tooltip--remove-multiple-workout"
                effect="solid"
                place={'top'}
                delayShow={100}
              >
                Remove Workouts
              </ReactTooltip>
            </S.BottomActions>
          </>
          <>
            <S.BottomActions
              data-tip
              data-for="tooltip--copy-multiple-workout"
              onClick={() => this.handleCopyWorkouts(selectedWorkout)}
            >
              <S.IconActions>
                <CopyWorkoutIcon />
              </S.IconActions>
              <S.TextActions>Copy</S.TextActions>
              <ReactTooltip
                className="app-tooltip copy-workouts copy-remove-bottom-calendar__tooltip"
                id="tooltip--copy-multiple-workout"
                effect="solid"
                place={'top'}
                delayShow={100}
              >
                Copy
              </ReactTooltip>
            </S.BottomActions>
          </>
        </S.BottomActionsContainer>
        <S.BottomCancel>
          <S.TextCancel>
            <S.CancelBold>Esc</S.CancelBold>&nbsp;to cancel
          </S.TextCancel>
          <CloseIconBottom onClick={() => this.handleResetState()} className="cancel-copy" />
        </S.BottomCancel>
      </S.BottomSelectWorkoutWrapper>
    );
  }

  renderBottomCopyWeek(selectedWeek) {
    const weekNumber = selectedWeek + 1;

    return (
      <S.BottomCopyWeekWrapper>
        <div>
          <strong>Week {weekNumber}</strong>
          &nbsp;copied
        </div>
        <div>
          <strong>Esc</strong>&nbsp;to cancel
          <CloseIconBottom onClick={() => this.handleResetState()} className="cancel-copy" />
        </div>
      </S.BottomCopyWeekWrapper>
    );
  }

  renderCalendar() {
    const {
      selectedProgram,
      calendarType,
      currentWeek,
      totalWeek,
      selectedWeek,
      selectedWorkout,
      workoutSets,
      isAddingWeek,
      permission,
    } = this.props;
    const { removingIndex, calendarNumber } = this.state;
    if (!selectedProgram) {
      return <Loader />;
    }

    const weeks = [];
    for (let i = 0; i < calendarType; i++) {
      const weekId = currentWeek + i;
      let weekIndex = weekId + 1;
      const isDeleting = weekId === removingIndex;

      let dayWorkouts = get(workoutSets[weekIndex - 1], 'days_workout', null);
      let weekHasWorkout = dayWorkouts
        ? dayWorkouts.some(dayWorkout => {
            return !isEmpty(get(dayWorkout, 'day_workout.workouts', []));
          })
        : false;

      weeks.push(
        <div
          className={classnames('program__week-title', {
            'hide-week-title': calendarType === 4 && weekHasWorkout,
          })}
          key={i}
        >
          {weekIndex <= totalWeek && (
            <React.Fragment>
              <S.ActionWrapper>
                <S.WeekTitle
                  className={classnames('title', {
                    'hide-week-title':
                      Number(calendarNumber + 1) === Number(weekIndex) && calendarType === 4 && weekHasWorkout,
                  })}
                >
                  Week {weekId + 1}
                </S.WeekTitle>
                {process.env.REACT_APP_COPY_WEEK_ENABLE_V2 &&
                  get(permission, 'copy_week') &&
                  isNil(selectedWeek) &&
                  isEmpty(selectedWorkout) &&
                  !isAddingWeek &&
                  !isDeleting && (
                    <S.WeekAction
                      style={{
                        opacity: Number(calendarNumber + 1) === Number(weekIndex) ? 1 : 0,
                      }}
                      onMouseLeave={this.handleOnMouseLeave}
                      onMouseEnter={this.handleOnMouseEnter}
                    >
                      <S.CalendarActionsContent>
                        {this.renderTrashToolTip(weekId, weekHasWorkout)}
                        {weekHasWorkout && this.renderCopyToolTip(weekId)}
                      </S.CalendarActionsContent>
                    </S.WeekAction>
                  )}
              </S.ActionWrapper>
            </React.Fragment>
          )}
        </div>,
      );
    }

    return (
      <div className="calendar-main-content program-calendar" onMouseLeave={this.handleOnMouseLeave}>
        <div className="program__shown_weeks">{weeks}</div>
        <ProgramCalendar isDragging={this.state.isDragging} onMouseEnter={this.handleOnMouseEnter} />
        {!isNil(selectedWeek) && this.renderBottomCopyWeek(selectedWeek)}
        {!isEmpty(selectedWorkout) && this.renderBottomSelectWorkout(selectedWorkout)}
      </div>
    );
  }

  renderClients = () => {
    const { dispatch, assigned_clients, selectedProgram } = this.props;
    const activeClients = assigned_clients.filter(o => o.program.status !== PROGRAM_COMPLETED);

    if (!selectedProgram || !assigned_clients.length) {
      return null;
    }

    const remainClients = activeClients.slice();
    const shownClients = remainClients.splice(0, 4);

    return (
      <div className="program-calendar__clients">
        {map(shownClients, item => (
          <Link
            key={item._id}
            to={`/home/client/${item._id}/calendar`}
            className="app-link avatar-container"
            data-tip
            data-for={`client-tooltip-${item._id}`}
          >
            <Avatar
              name={getUserShortName(item)}
              className="client-avatar"
              color={item.color}
              size="30"
              src={item.avatar}
            />
            <ReactTooltip
              className="app-tooltip program-calendar__tooltip"
              id={`client-tooltip-${item._id}`}
              effect="solid"
              place={'top'}
              delayShow={500}
            >
              <span>{`${item.first_name} ${item.last_name}`}</span>
            </ReactTooltip>
          </Link>
        ))}
        <div
          className="avatar-container more-clients"
          data-tip
          data-for="view-more-clients"
          onClick={() => dispatch(toggleModal(true, <ProgramViewClients />))}
        >
          {!!remainClients.length && <div>{remainClients.length}+</div>}
          <div className="dot" />
        </div>
        <ReactTooltip
          className="app-tooltip program-calendar__tooltip more-clients-tootip"
          id="view-more-clients"
          effect="solid"
          place={'top'}
          delayShow={500}
        >
          <span>View clients you have assigned this program to</span>
        </ReactTooltip>
      </div>
    );
  };

  renderLiveSync = () => {
    const { selectedProgram, programToggleLiveSync, dispatch } = this.props;

    if (!selectedProgram) {
      return null;
    }

    return (
      <div className="program-calendar__live-sync">
        <Checkbox
          toggle
          className="everfit-toggle"
          checked={selectedProgram.active_sync}
          onChange={(event, data) => {
            const checked = data.checked;

            if (checked) {
              dispatch(
                toggleConfirmModal(
                  true,
                  <ConfirmModal
                    title="Enable Live Sync"
                    content={
                      'All clients will be updated with the current version of the program, and any future change will be simultaneously synced with each client’s calendar.'
                    }
                    onConfirm={() => programToggleLiveSync(selectedProgram._id, { active_sync: true })}
                    onDeny={() => {}}
                    cancelButtonTitle={'Cancel'}
                    confirmButtonTitle={'Enable'}
                    confirmButtonClass="enable-live-sync__confirm-button"
                    headerIcon={`${CDN_URL}/images/live_sync.svg`}
                  />,
                ),
              );
            } else {
              programToggleLiveSync(selectedProgram._id, { active_sync: false });
            }
          }}
        />
        <div className="text" data-tip data-for="live-sync-tooltip">
          <div>ENABLE LIVE SYNC</div>
          <Image src={`${CDN_URL}/images/c_info.svg`} />
        </div>
        <ReactTooltip
          className="app-tooltip program-calendar__tooltip text-left"
          id="live-sync-tooltip"
          effect="solid"
          place="top"
          delayShow={500}
        >
          <span>
            Turn on Live Sync to update the calendar of any client that has been assigned the program with all changes
            that have been made to the program, and continue automated syncing.
          </span>
        </ReactTooltip>
      </div>
    );
  };

  renderNavigationBar = () => {
    const { totalWeek, currentWeek, calendarType } = this.props;

    return (
      <div className="program-calendar__navbar">
        <div className="program-calendar__navbar__left">
          <ProgramCalendarWeek
            currentWeek={currentWeek}
            totalWeek={totalWeek}
            calendarType={calendarType}
            goToWeek={this.handleGoToWeek}
          />
          {totalWeek < CALENDAR_LIMIT_WEEKS && <did>{this.renderAddWeek()}</did>}
        </div>
        <div className="program-calendar__navbar__right">
          <div className="program-advances">
            <AssignButton />
            {this.renderClients()}
            {this.renderLiveSync()}
          </div>
          <SelectCalendarType
            selected={this.props.calendarType}
            options={CALENDAR_TYPES}
            onSelect={value => this.handleChangeType(value)}
            disabled={item => (item.value === 1 ? false : totalWeek < item.value)}
            uiOptions={{ newUI: true }}
          />
        </div>
      </div>
    );
  };

  renderAddWeek() {
    const { currentWeek, totalWeek, selectedWeek, selectedWorkout } = this.props;
    const isDisableAddWeek = !isNil(selectedWeek) || !isEmpty(selectedWorkout);

    if (totalWeek >= CALENDAR_LIMIT_WEEKS) {
      return null;
    }

    return (
      <DropDown
        triggerIcon={() => (
          <AddWeekButton disabled={isDisableAddWeek} isDisableAddWeek={isDisableAddWeek}>
            + Add Week
          </AddWeekButton>
        )}
        className="dropdown--addWeek"
      >
        <Option onClick={this.handleAddBeforeAction} key="add-week-before">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="white" />
            <rect x="4" y="6" width="6" height="2" fill="white" />
            <rect x="8" y="4" width="6" height="2" transform="rotate(90 8 4)" fill="white" />
          </svg>
          <div>Add before "Week {currentWeek + 1}"</div>
        </Option>
        <Option onClick={this.handleAddAfterAction} key="add-week-after">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="white" />
            <rect x="4" y="6" width="6" height="2" fill="white" />
            <rect x="8" y="4" width="6" height="2" transform="rotate(90 8 4)" fill="white" />
          </svg>
          <div>Add after "Week {currentWeek + 1}"</div>
        </Option>
        <AddWeekGuide>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 12.25C4.1125 12.25 1.75 9.8875 1.75 7C1.75 4.1125 4.1125 1.75 7 1.75C9.8875 1.75 12.25 4.1125 12.25 7C12.25 9.8875 9.8875 12.25 7 12.25Z"
              fill="white"
            />
            <path d="M7.875 6.125H6.125V10.5H7.875V6.125Z" fill="white" />
            <path
              d="M7 5.25C7.48325 5.25 7.875 4.85825 7.875 4.375C7.875 3.89175 7.48325 3.5 7 3.5C6.51675 3.5 6.125 3.89175 6.125 4.375C6.125 4.85825 6.51675 5.25 7 5.25Z"
              fill="white"
            />
          </svg>
          <span>Or hover between 2 weeks to add a week</span>
        </AddWeekGuide>
      </DropDown>
    );
  }

  handleDeleteAction = weekIndex => {
    const { removingIndex } = this.state;
    if (removingIndex) {
      return;
    }
    this.props.dispatch(
      toggleConfirmModal(
        true,
        <RemovePopup
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          title={`Delete "Week ${weekIndex + 1}"`}
          content={'Would you like to delete this week from the program?'}
          onConfirm={() => this.onDeleteWeek(weekIndex)}
          onDeny={() => {}}
          cancelButtonTitle={'Cancel'}
          confirmButtonTitle={'Confirm'}
          hasCloseIcon={true}
          noBorder={true}
          className="remove-entire-week-popup"
        />,
      ),
    );
  };

  onDeleteWeek = weekIndex => {
    const { selectedProgram, removeWeekById } = this.props;
    const weekNumber = weekIndex + 1;
    if (selectedProgram.workout_sets.length === 1) {
      this.props.dispatch(showError('Program should have at least 1 week'));
      return;
    }
    this.setState({
      removingIndex: weekIndex,
    });
    removeWeekById({
      weekSetId: selectedProgram.workout_sets[weekIndex]._id,
      programId: selectedProgram._id,
      weekIndex: weekIndex,
    })
      .then(response => {
        toast(`Week ${weekNumber} has been removed.`, {
          className: CLASSNAME_TOAST.TRAINING_CALENDAR,
        });
        this.setState({
          removingIndex: null,
        });
      })
      .catch(error => {
        this.setState({
          removingIndex: null,
        });
      });
  };

  handleAddBeforeAction = () => {
    const { currentWeek, selectedProgram } = this.props;
    if (!isEmpty(get(selectedProgram, '_id'))) {
      this.props.addWeekAtIndex({ weekIndex: currentWeek, programId: selectedProgram._id });
    }
  };

  handleAddAfterAction = () => {
    const { currentWeek, selectedProgram } = this.props;
    if (!isEmpty(get(selectedProgram, '_id'))) {
      this.props.addWeekAtIndex({ weekIndex: currentWeek + 1, programId: selectedProgram._id });
    }
  };
}
