import React, { useMemo } from 'react';
import { get } from 'lodash';
import * as S from './style';
import { pluralize } from 'utils/commonFunction';
import DropdownWorkoutItem from '../DropdownWorkoutItem/index';

const COLLECTION_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
};

function WorkoutCollectionsItem(props) {
  const { workoutCollection } = props;

  const handleGetStatus = status => {
    let published = false;

    if (status.includes(COLLECTION_STATUS.PUBLISH)) published = true;
    return published;
  };
  
  const categoryCounter = useMemo(() => {
    const counter = get(workoutCollection, 'number_of_categories', 0);
    return `${counter} ${counter === 1 ? 'Category' : 'Categories'}`;
  }, [workoutCollection]);

  return (
    <S.Wrapper className="clientStudio__wrapper" onClick={() => props.onGoDetail(get(workoutCollection, '_id', ''))}>
      <S.CoverPhotoContainer coverImage={workoutCollection.background}>
        <S.CompactDataContainer>
          <S.CompactInformationItem>
            {categoryCounter}
            {' - '}
            {pluralize('Workout', workoutCollection.number_of_workouts || 0, true)}
          </S.CompactInformationItem>
        </S.CompactDataContainer>
      </S.CoverPhotoContainer>
      <S.WorkoutCollectionsBaseInformation>
        {/* <div className="workout-collections__type">{workoutCollection.type}</div> */}
        <div className="workout-collections__name">
          {/* {workoutCollections.status === STUDIO_PROGRAM_STATUS.Completed && <S.CompletedIcon />} */}
          {workoutCollection.name}
        </div>
        <div className="workout-collections__description">{workoutCollection.description}</div>
      </S.WorkoutCollectionsBaseInformation>
      {/* {handleGetStatus(workoutCollection.status) && <S.ActiveCheck>Published</S.ActiveCheck>} */}
      <DropdownWorkoutItem workoutCollection={workoutCollection} onRemove={props.onRemove} />
    </S.Wrapper>
  );
}

export default WorkoutCollectionsItem;
