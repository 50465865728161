import React from 'react';
import Slider from 'react-slick';

import * as S from './style';
import UpgradePathWorkoutBackground from 'assets/images/upgrade-path-workout_background.png';
import UpgradePathAppIcon from 'assets/images/upgrade-path-app-icon.png';
import UpgradePathSplashScreen from 'assets/images/upgrade-path-splash_screen.png';
import UpgradePathColorTheme from 'assets/images/upgrade-path-color_theme.png';
import { ReactComponent as ArrowSlider } from 'assets/icons/arrow-slider.svg';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import classnames from 'classnames';
import { PLANS } from 'constants/commonData';
import { NEW_APP_ICON_FLOW } from '../constants';

const autoplaySpeed = 3000;
const speed = 2000;
const data = [
  {
    imageSrc: UpgradePathAppIcon,
    title: 'Your Own App Icon ',
    description:
      'Cultivate your brand and use a custom app icon, so clients can easily recognize the training app based on your own logo.',
  },
  {
    imageSrc: UpgradePathSplashScreen,
    title: 'Premium Splash Screen',
    description:
      'Elevate the experience and welcome your clients to the app each time with a full screen background image.',
  },
  {
    imageSrc: UpgradePathWorkoutBackground,
    title: 'Custom Workout Background',
    description:
      'Different training focuses for different days? Stand out and use a unique workout background for each workout.',
  },
  {
    imageSrc: UpgradePathColorTheme,
    title: 'Unique Color Themes',
    description:
      'Your training is unique, so why settle for less. Choose your own Hex codes to set up the primary and secondary app colors.',
  },
];

function SampleNextArrow(props) {
  const { className, onClick, currentSlide } = props;
  return (
    <S.ArrowSliderRight
      className={classnames(className, { active: currentSlide !== 3, disable: currentSlide === 3 })}
      onClick={onClick}
    >
      <ArrowSlider />
    </S.ArrowSliderRight>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick, currentSlide } = props;
  return (
    <S.ArrowSliderLeft
      className={classnames(className, { active: currentSlide !== 0, disable: currentSlide === 0 })}
      onClick={onClick}
    >
      <ArrowSlider />
    </S.ArrowSliderLeft>
  );
}

class CustomBrandingNoPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        autoplay: true,
        autoplaySpeed: autoplaySpeed,
        dots: true,
        infinite: true,
        speed: speed,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        dotsClass: 'slick-dots',
        customPaging: i => <S.Dots className={'dots-item'} />,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      },
    };
  }

  handleStopAutoPlay = () => {
    document.addEventListener(
      'click',
      function (event) {
        if (event.target.getAttribute('class')) {
          if (event.target.getAttribute('class').indexOf('dots-item') > -1) {
            this.slider && this.slider.slickPause();
          }
        }
        if (event.target.closest('.slick-prev') || event.target.closest('.slick-next')) {
          this.slider && this.slider.slickPause();
        }
      }.bind(this),
    );
  };

  handleSwipeAutoPlay = () => {
    this.slider && this.slider.slickPause();
  };

  componentDidMount() {
    const { onCheckSaving } = this.props;
    onCheckSaving(false);
  }

  handleChangeUpdatePlan = () => {
    const { onCheckSaving } = this.props;
    onCheckSaving(false);
  };

  render() {
    const { settings } = this.state;
    const { appIconFlowType } = this.props;

    return (
      <S.Section>
        <S.Header>
          <S.Title>Advanced Custom Branding</S.Title>
          <S.Description>Bring your brand to the next level </S.Description>
        </S.Header>

        <S.Body>
          <S.Slider>
            <Slider
              ref={slider => (this.slider = slider)}
              {...settings}
              afterChange={this.handleStopAutoPlay}
              onSwipe={this.handleSwipeAutoPlay}
            >
              {data.map((item, key) => {
                const isChangeContent = item.title === 'Your Own App Icon ' && appIconFlowType === NEW_APP_ICON_FLOW;

                const newTitle = 'App Icon';
                const newDesc =
                  'Cultivate your brand and use an unique app icon from available list, so clients can easily recognize the training app based on your logo';

                return (
                  <S.Item key={key}>
                    <S.ItemImageArea>
                      <S.ItemImage src={item.imageSrc}></S.ItemImage>
                    </S.ItemImageArea>

                    <S.ItemContent>
                      <S.ItemTitle>{isChangeContent ? newTitle : item.title}</S.ItemTitle>
                      <S.ItemText className={classnames({ last: key === data.length - 1 })}>
                        {isChangeContent ? newDesc : item.description}
                      </S.ItemText>
                      <UpgradePathButton
                        className="upgrade-path-button"
                        text="Get Advanced Custom Branding"
                        onClick={this.handleChangeUpdatePlan}
                        planWillSelect={{
                          tier: PLANS.pro.key,
                          addOns: {
                            premiumCustomBrand: true,
                          },
                        }}
                      />
                    </S.ItemContent>
                  </S.Item>
                );
              })}
            </Slider>
          </S.Slider>
        </S.Body>
      </S.Section>
    );
  }
}

export default CustomBrandingNoPermission;
