import styled from 'styled-components';

export const Item = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  padding: 5px 20px;
  background-color: transparent;
  cursor: pointer;

  :hover, &.selected {
    background-color: #edecfc;
    color: #5c58c0;
  }

  &.week-divide {
    background-color: transparent !important;
    color: #c0c0c0 !important;
    cursor: default;

    > div {
      padding: 0 16px;
      position: relative;
      display: inline-block;
      width: auto;
      ::before, ::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 1.4px;
        background-color: #c0c0c0;
      }

      ::before {
        left: 0;
      }

      ::after {
        right: 0;
      }
    }
  }
`;

export const Menu = styled.div`
  position: absolute;
  left: 0;
  width: 220px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #d3d7d9;
  z-index: 9999;
`;

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;

  .text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #323c47;
    border-radius: 4px;
    border: solid 1px #dcdcde;
    background-color: #fafbfc;
    flex: 1 1;
    padding: 0 10px;;

    i.icon {
      line-height: 13px;
      font-size: 12px;
      margin: 0;
      font-weight: normal;
    }
  }

  ${Menu} {
    display: none;
  }

  &.open {
    ${Menu} {
      display: block;
    }
  }
`;