import React from 'react';
import { Modal, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CDN_URL, ON_DEMAND_WORKOUT_TYPE } from 'constants/commonData';
import { toggleModal } from 'actions/modal';

import * as S from './style';
import OnDemandWorkoutModal from '../OnDemandWorkoutModal';

function DialogAddNew({ isModalOpen, toggleModal, categoryLabels, onClose, addNewOnDemandWorkouts }) {
  const handleClose = () => {
    toggleModal(false);
  };

  const handleRegularClick = () => {
    toggleModal(
      true,
      <OnDemandWorkoutModal
        type={ON_DEMAND_WORKOUT_TYPE.REGULAR}
        mode={'add'}
        onClose={handleClose}
        addNewOnDemandWorkouts={addNewOnDemandWorkouts}
      />,
    );
  };
  const handleVideoClick = () => {
    toggleModal(
      true,
      <OnDemandWorkoutModal
        type={ON_DEMAND_WORKOUT_TYPE.VIDEO}
        mode={'add'}
        onClose={handleClose}
        addNewOnDemandWorkouts={addNewOnDemandWorkouts}
      />,
    );
  };

  return (
    <S.ModalWrapper
      open={true}
      onClose={onClose}
      closeOnDimmerClick={true}
      closeIcon={
        <button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </button>
      }
    >
      <Modal.Content>
        <div className="modal-title">Create an on-demand workout</div>
        <div className="buttons-container">
          <div className="button" onClick={handleRegularClick}>
            <div className="icon-container regular"></div>
            <div className="title">Regular Workout</div>
            <div className="description regular-text">Add a workout from your Library that your clients can track</div>
          </div>
          <div className="button" onClick={handleVideoClick}>
            <div className="icon-container video"></div>
            <div className="title">Video Workout</div>
            <div className="description video-text">
              Add a video for on-demand access, perfect for skills training or recorded workout routines
            </div>
          </div>
        </div>
      </Modal.Content>
    </S.ModalWrapper>
  );
}

const mapState = state => ({
  isModalOpen: state.isModalOpen,
});

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(DialogAddNew);
