import React from 'react';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';
import { push } from 'connected-react-router';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { enableEditForm } from 'redux/form-details/actions';
import { archiveForm, duplicateForm, editForm, removeForm } from 'redux/forms/actions';
import OwnerAvatar from 'shared/OwnerAvatar';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { SharingStatus } from 'shared/Icons';
import AddNewForm from '../AddNewForm';
import ConfirmModal from 'shared/ConfirmModal';
import EmptyContent from '../EmptyContent';
import Indicator from './Indicator';
import { formatSameYear, isTeamAdmin } from 'utils/commonFunction';
import { SharedTooltip } from 'shared/SharedTooltip';
import { BlockActionModal, BLOCK_ACTION_TYPES } from 'shared/TriggerForms';

import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate_form.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/form_trash.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/action_archive.svg';
import { ReactComponent as NoResultsIcon } from 'assets/icons/on-demand-workout-search-no-results.svg';

import { CDN_URL, FORM_FETCH_TYPES, FORM_STATUS, TEAM_SHARE_NOOWNER, TEAM_SHARE_SHARED } from 'constants/commonData';

import * as S from './style';

const renderTrigger = ({ open }) => (
  <div data-tip data-for="form-item-options-tooltip">
    <MenuTrigger vertical active={!!open} />
    <SharedTooltip id="form-item-options-tooltip" />
  </div>
);

const FormItem = ({
  user,
  item,
  index,
  push,
  toggleModal,
  toggleConfirmModal,
  removeForm,
  archiveForm,
  enableEditForm,
  onUpdateForm,
  onDuplicateForm,
  cloudfrontList,
}) => {
  const {
    _id,
    name,
    number_of_questions,
    number_of_responses,
    author,
    is_archived,
    is_edit_mode,
    last_updated_at,
  } = item;
  const share = item.share === TEAM_SHARE_SHARED || item.share === TEAM_SHARE_NOOWNER;
  const status = item.status === FORM_STATUS.PUBLISHED;

  const handleOpenFormPopup = (type, onSubmit) => {
    toggleModal(
      true,
      <AddNewForm user={user} type={type} workingItem={item} toggleModal={toggleModal} onSubmit={onSubmit} />,
    );
  };

  const handleEditForm = () => {
    push(`/home/forms/${_id}/questions`);
    status && !is_archived && !is_edit_mode && enableEditForm && enableEditForm(_id, { enable: true });
  };

  const handleShowArchiveSuccess = () => {
    toast(`Form ${item.name} has been archived. It will become inactive in all tasks or resource it is in.`);
  };

  const handleUpdateShare = event => {
    event.stopPropagation();
    handleOpenFormPopup('update', onUpdateForm);
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const confirmArchiveForm = () => {
    const { related_form_onboarding_flows } = item;

    if (
      Array.isArray(related_form_onboarding_flows) &&
      related_form_onboarding_flows.length > 0 &&
      typeof toggleConfirmModal === 'function'
    ) {
      return toggleConfirmModal(
        true,
        <BlockActionModal
          type={BLOCK_ACTION_TYPES.ARCHIVE_FORM}
          closeModal={closeConfirmModal}
          relatedFormOnboardingFlows={related_form_onboarding_flows}
          user={user}
        />,
      );
    }

    handleArchiveForm();
  };

  const handleArchiveForm = () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        className="archive-form-popup"
        title="Archive form"
        content={
          <>
            Once the form is archived, it will not remain active in any task or resource it is in. Would you like to
            archive <b className="name-form">{item.name}</b>?
          </>
        }
        onConfirm={() => archiveForm(_id, true, handleShowArchiveSuccess)}
        confirmButtonTitle="Yes, Archive Form"
        headerIcon={`${CDN_URL}/images/archive_light_package.svg`}
        noBorder
      />,
    );
  };

  return (
    <S.ItemWrapper key={_id} onClick={() => push(`/home/forms/${_id}/questions`)} data-id={`form-item-${index}`}>
      <S.ItemName>
        <S.Dot
          active={status}
          archived={is_archived}
          data-tip={is_archived ? 'Archived' : status ? 'Published' : 'Draft'}
          data-for={`form-status__tooltip-${_id}`}
        />
        <ReactTooltip id={`form-status__tooltip-${_id}`} effect="solid" place={'top'} className="app-tooltip" />
        <span>{name}</span>
      </S.ItemName>
      <S.ItemContent>{number_of_questions || '--'}</S.ItemContent>
      <S.ItemContent>{number_of_responses || '--'}</S.ItemContent>
      <S.ItemContent>{formatSameYear(last_updated_at)}</S.ItemContent>
      <S.ItemActions>
        {author ? (
          <OwnerAvatar
            data={author}
            cloudfrontList={cloudfrontList}
            tooltipId={`tooltip--owner-${_id}`}
            size={25}
            className="owner-avatar"
          />
        ) : (
          <div className="owner-avatar" />
        )}
        <SharingStatus shared={share} tooltipId={_id} className="app-tooltip" onClick={handleUpdateShare} />
        <DropDown triggerIcon={renderTrigger}>
          <Option key="edit-content" onClick={handleEditForm} data-option="edit">
            <EditIcon />
            <span>Edit</span>
          </Option>
          <Option
            key="duplicate-content"
            onClick={() => handleOpenFormPopup('duplicate', onDuplicateForm)}
            data-option="duplicate"
          >
            <DuplicateIcon />
            <span>Duplicate</span>
          </Option>
          <Option
            key="update-share"
            onClick={() => handleOpenFormPopup('update', onUpdateForm)}
            data-option="sharing-settings"
          >
            <ShareIcon />
            <span>Sharing settings</span>
          </Option>
          {is_archived ? (
            <Option key="unarchive-content" onClick={() => archiveForm(_id, false)} data-option="unarchive">
              <ArchiveIcon />
              <span>Unarchive</span>
            </Option>
          ) : status ? (
            <Option key="archive-content" onClick={confirmArchiveForm} data-option="archive">
              <ArchiveIcon />
              <span>Archive</span>
            </Option>
          ) : (
            <Option
              key="delete-content"
              onClick={() => {
                toggleConfirmModal(
                  true,
                  <ConfirmModal
                    className="remove-form-popup"
                    title="Remove form"
                    content={
                      <>
                        Are you sure you want to remove <b className="name-form">{name}</b>?
                      </>
                    }
                    onConfirm={() => removeForm(_id, null, true)}
                    confirmButtonTitle="Remove"
                    src={`${CDN_URL}/images/trash-circle.svg`}
                    noBorder
                  />,
                );
              }}
              data-option="delete"
            >
              <TrashIcon />
              <span>Delete</span>
            </Option>
          )}
        </DropDown>
      </S.ItemActions>
    </S.ItemWrapper>
  );
};

const TableContent = ({
  user,
  list,
  loading,
  query,
  push,
  toggleModal,
  toggleConfirmModal,
  editForm,
  duplicateForm,
  removeForm,
  isHideFormBanner,
  archiveForm,
  enableEditForm,
  isFirst,
  totalYourForms,
  cloudfrontList,
  paymentOverdue,
  zapierOverdue,
  addOnsOverdue,
  paymentWhiteLabelOverdue,
}) => {
  const { is_verified_email, force_send_verification_email_at } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  const handleUpdateForm = (data, callback) => {
    editForm && editForm(data, callback);
  };

  const handleDuplicateForm = (data, callback) => {
    duplicateForm && duplicateForm(data, callback);
  };

  if (
    (isFirst || (!list.length && totalYourForms <= 0 && query.fetch_type === FORM_FETCH_TYPES.YOUR_FORM)) &&
    !loading
  ) {
    return <EmptyContent isHideFormBanner={isHideFormBanner} />;
  }

  if (!list.length && !loading) {
    return (
      <S.NotFound isHideFormBanner={isHideFormBanner}>
        <NoResultsIcon />
        <div className="text">No results found</div>
      </S.NotFound>
    );
  }

  return (
    <S.TableContentWrapper
      isHideFormBanner={isHideFormBanner}
      onePage={query.total <= 20}
      isVerifyEmail={isWhiteLabelOverdue || isShowOverdue || isVerifyEmail}
    >
      {list.map((item, index) => (
        <FormItem
          user={user}
          item={item}
          index={index}
          push={push}
          cloudfrontList={cloudfrontList}
          toggleModal={toggleModal}
          toggleConfirmModal={toggleConfirmModal}
          removeForm={removeForm}
          archiveForm={archiveForm}
          enableEditForm={enableEditForm}
          onUpdateForm={(data, callback) => handleUpdateForm({ _id: item._id, ...data }, callback)}
          onDuplicateForm={(data, callback) => handleDuplicateForm({ _id: item._id, ...data }, callback)}
        />
      ))}
      {loading && !query.text_search && <Indicator />}
    </S.TableContentWrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: { forms, generalSettings, pricing },
  } = state;
  return {
    cloudfrontList,
    list: get(forms, 'list', []),
    totalYourForms: get(forms, 'query.archived_form', 0) + get(forms, 'query.your_form', 0),
    user,
    isHideFormBanner: get(generalSettings, 'is_hide_form_banner', false),
    query: get(forms, 'query', {}),
    loading: get(forms, 'loading', false),
    paymentOverdue: pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    editForm: bindActionCreators(editForm, dispatch),
    duplicateForm: bindActionCreators(duplicateForm, dispatch),
    removeForm: bindActionCreators(removeForm, dispatch),
    archiveForm: bindActionCreators(archiveForm, dispatch),
    enableEditForm: bindActionCreators(enableEditForm, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableContent);
