export const FILTER_STATUS = {
  all: {
    label: 'All',
    value: '',
    listName: 'Connected & archived clients',
  },
  connected: {
    label: 'Connected',
    value: 'connected',
    listName: 'Connected clients',
  },
  archived: {
    label: 'Archived',
    value: 'archived',
    listName: 'Archived clients',
  },
};

export const OWNER_FILTER = {
  you: {
    label: 'You',
    value: 1,
  },
  all: {
    label: 'All',
    value: 2,
  },
};
