import styled, { css, keyframes } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const slideLeft = keyframes`
  0%{
    left: 100%;
  }
  100% {
    left: 0;
  }
`;

const slideRight = keyframes`
  0%{
    left: -100%;
  }
  100% {
    left: 0;
  }
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #3b4859;
  padding: 20px 20px 15px;
`;

export const Type = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 7px;
  line-height: 18px;
  text-transform: uppercase;
  color: #4fdfac;
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
`;

export const Message = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 150%;
  color: #111111;
  margin-bottom: 12px;
  word-break: break-word;
  white-space: pre-line;

  a {
    color: #5158cf;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #111111;
  margin-bottom: 3px;
  word-break: break-word;
`;

export const Overview = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(179, 179, 179, 0.1);
  border-radius: 8px;
  padding: 12px 12px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .avatar {
    margin-right: 12px;
    flex: 0 0 33px;
  }

  button.read-more {
    background: #7470ef;
    border-radius: 24px;
    width: 80px;
    height: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
    outline: none;
    border: none;
  }

  ${Title}, ${Message} {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
`;

export const OverviewMainContent = styled.div`
  margin-bottom: 12px;
  height: 51px;

  ${Message} {
    margin-bottom: 0;
  }
`;

export const TodayScreen = styled.div`
  width: 100%;
  padding: 16px 10px;
  position: relative;
  animation: ${slideRight} 0.3s ease-in-out;

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.4px;
    color: #000000;
    opacity: 0.1;
    margin-bottom: 10px;
    margin-top: 20px;

    .date {
      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 11px;
      text-transform: uppercase;
    }
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #dddddd;
  cursor: pointer;
  display: inline-block;

  ${props =>
    props.active &&
    css`
      background-color: #726ee4;
      border-color: #726ee4;
    `}
`;

export const Navigations = styled.div`
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  z-index: 1;
  background: transparent;
  text-align: center;

  ${Dot} {
    :first-child {
      margin-right: 5px;
    }
  }
`;

export const PlayButton = styled.div`
  width: 42px;
  height: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: url(${CDN_URL}/images/play-button.svg) no-repeat center center;
  background-size: 17px 15px;
  background-color: #7470ef;
  z-index: 1;
`;

export const Attachment = styled.div`
  position: relative;
  width: 100%;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;

  video,
  img {
    width: 100%;
    max-height: 310px;
    min-height: 140px;
    object-fit: scale-down;
    display: block;
  }
`;

export const DetailScreen = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  animation: ${slideLeft} 0.3s ease;

  .content {
    padding: 10px 14px 30px;

    ${Type} {
      font-size: 8px;
    }

    ${Title} {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 19px;
    }

    .author {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 21px;
      display: flex;
      color: #5f5f5f;
      margin-bottom: 10px;

      .avatar {
        margin-right: 8px;
        flex: 0 0 23px;
      }
    }
  }

  ${props =>
    props.noAttachment &&
    css`
      padding-top: 16px;
    `}
`;

export const Alarm = styled.div`
  background: url(${CDN_URL}/images/alarm.svg) no-repeat center center;
  background-size: 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 26px;
  opacity: 0.1;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ::-webkit-scrollbar-track {
    margin-top: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6) !important;
  }

  ::-webkit-scrollbar {
    width: 3px !important;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 12px 16px;
  position: relative;
  overflow: hidden;
  background: url(${CDN_URL}/images/iphone_frame_transparent.svg) no-repeat top left;
  background-size: 100% auto;

  .frame {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: url(${CDN_URL}/images/iphone_frame_transparent.svg) no-repeat top left;
    background-size: 100% auto;
  }

  .phone_ear {
    position: absolute !important;
    top: 6px;
    left: 65px;
    z-index: 1;
  }

  .bottom {
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  ${props =>
    props.detail &&
    css`
      ${TodayScreen} {
        left: -100%;
      }

      ${DetailScreen} {
        left: 0;
      }
    `}
`;

export const ContentWrapper = styled.div`
  padding: 0 15px 15px;
  width: 100%;
  flex: 1 1;
  position: relative;
  overflow: hidden;

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    z-index: 1;
    cursor: pointer;

    &.left {
      left: 7px;
    }

    &.right {
      right: 12px;
    }
  }
`;

export const Container = styled.div`
  width: 303px;
  height: 100%;
  background: #fafafa;
  border-left: 1px solid #e8e8e8;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .sb-avatar__image,
  .sb-avatar__text {
    border-radius: 50%;
    object-fit: cover;
  }

  :hover {
    .arrow {
      display: block;
    }
  }
`;
