import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  background-color: #fff;
  width: 260px;
  flex-shrink: 0;

  ${({ sideBarVisible }) =>
    !sideBarVisible
      ? css`
          @media screen and (max-width: 1365px) {
            width: 300px;
          }
          @media screen and (min-width: 1366px) and (max-width: 1749px) {
            width: 338px;
          }
          @media screen and (min-width: 1750px) {
            width: 400px;
          }
        `
      : css`
          @media screen and (max-width: 1365px) {
            width: 260px;
          }
          @media screen and (min-width: 1366px) and (max-width: 1749px) {
            width: 300px;
          }
          @media screen and (min-width: 1750px) {
            width: 400px;
          }
        `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 130px);
  overflow-y: scroll;
  padding: 20px 18px 20px 30px;
  margin-right: 8px;
  ::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e1e1ea !important;
  }
  .droppable-context-meal-plan-day-detail {
    position: relative;
    .droppable-context-meal-category {
      margin-bottom: 14px;
    }
  }
`;
