import Request from 'configs/request';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { get, merge, omit } from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import { showError } from 'actions/error';
import { omitEmptyRequestParams } from 'utils/commonFunction';
import { duplicateFormQuestion } from 'redux/form-details/actions';
import { FORM_STATUS, QUESTION_TYPES } from 'constants/commonData';

export const Types = {
  HIDE_FORMS_BANNER_REQUEST: 'HIDE_FORMS_BANNER_REQUEST',
  HIDE_FORMS_BANNER_SUCCESS: 'HIDE_FORMS_BANNER_SUCCESS',
  HIDE_FORMS_BANNER_FAIL: 'HIDE_FORMS_BANNER_FAIL',
  GET_FORMS_REQUEST: 'GET_FORMS_REQUEST',
  GET_FORMS_SUCCESS: 'GET_FORMS_SUCCESS',
  GET_FORMS_FAIL: 'GET_FORMS_FAIL',
  GET_PUBLISHED_FORMS_REQUEST: 'GET_PUBLISHED_FORMS_REQUEST',
  GET_PUBLISHED_FORMS_SUCCESS: 'GET_PUBLISHED_FORMS_SUCCESS',
  GET_PUBLISHED_FORMS_FAILED: 'GET_PUBLISHED_FORMS_FAILED',
  ADD_NEW_FORM_REQUEST: 'ADD_NEW_FORM_REQUEST',
  ADD_NEW_FORM_SUCCESS: 'ADD_NEW_FORM_SUCCESS',
  ADD_NEW_FORM_FAIL: 'ADD_NEW_FORM_FAIL',
  EDIT_FORM_REQUEST: 'EDIT_FORM_REQUEST',
  EDIT_FORM_SUCCESS: 'EDIT_FORM_SUCCESS',
  EDIT_FORM_FAILED: 'EDIT_FORM_FAILED',
  DUPLICATE_FORM_REQUEST: 'DUPLICATE_FORM_REQUEST',
  DUPLICATE_FORM_SUCCESS: 'DUPLICATE_FORM_SUCCESS',
  DUPLICATE_FORM_FAILED: 'DUPLICATE_FORM_FAILED',
  DELETE_FORM_REQUEST: 'DELETE_FORM_REQUEST',
  DELETE_FORM_SUCCESS: 'DELETE_FORM_SUCCESS',
  DELETE_FORM_FAILED: 'DELETE_FORM_FAILED',
  FORM_CHANGE_QUERY_PARAMS: 'FORM_CHANGE_QUERY_PARAMS',
  ARCHIVE_FORM_REQUEST: 'ARCHIVE_FORM_REQUEST',
  ARCHIVE_FORM_SUCCESS: 'ARCHIVE_FORM_SUCCESS',
  ARCHIVE_FORM_FAILED: 'ARCHIVE_FORM_FAILED',
  FORM_RESET_QUERY_PARAMS: 'FORM_RESET_QUERY_PARAMS',
  GET_FORM_TEMPLATES_REQUEST: 'GET_FORM_TEMPLATES_REQUEST',
  GET_FORM_TEMPLATES_SUCCESS: 'GET_FORM_TEMPLATES_SUCCESS',
  GET_FORM_TEMPLATES_FAILED: 'GET_FORM_TEMPLATES_FAILED',
  RESET_FORM_TEMPLATES: 'RESET_FORM_TEMPLATES',
};

export const getAllForms = (params, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        forms: { query: stateParams },
        formDetails: { draftForms = [] },
      },
    } = getState();
    dispatch({ type: Types.GET_FORMS_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `api/forms`,
          params: omitEmptyRequestParams(
            omit(merge(stateParams, params), 'total', 'your_form', 'all_form', 'isEnd', 'status'),
          ),
        },
        true,
        response => {
          const { data } = response.data;
          const list = get(data, 'data', []);

          list.forEach((form, index) => {
            const draft = draftForms.find(x => x._id === form._id);

            if (draft) {
              const currentQuestions = get(draft, 'questions', []).filter(
                i => ![QUESTION_TYPES.WELCOME_SCREEN, QUESTION_TYPES.IMAGE_TEXT].includes(i.type),
              );
              list[index]['number_of_questions'] = currentQuestions.length || 0;
            }
          });

          data.data = list;

          if (data) {
            dispatch({ type: Types.GET_FORMS_SUCCESS, payload: data });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.GET_FORMS_FAIL });
        },
      ),
    );
  };
};

export const getPublishedForms = (params, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        forms: { query: stateParams },
      },
    } = getState();
    dispatch({ type: Types.GET_PUBLISHED_FORMS_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `api/forms`,
          params: omitEmptyRequestParams(omit(merge(stateParams, params), 'total', 'your_form', 'all_form', 'isEnd')),
        },
        true,
        response => {
          const { data } = response.data;

          if (data) {
            dispatch({ type: Types.GET_PUBLISHED_FORMS_SUCCESS, payload: data });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.GET_PUBLISHED_FORMS_FAILED });
        },
      ),
    );
  };
};

export const addNewForms = (data, openTab = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = get(state, 'user._id', '');
    const author = get(data, 'owner', '');
    const isOwner = currentUser === author || !author;

    dispatch({ type: Types.ADD_NEW_FORM_REQUEST });

    return dispatch(
      Request.post(
        { url: 'api/forms', data },
        true,
        response => {
          const { data } = response.data;

          if (data) {
            dispatch({
              type: Types.ADD_NEW_FORM_SUCCESS,
              payload: { data: data, isOwner },
            });
            Mixpanel.track('create_new_form');

            if (data._id && isOwner) {
              if (openTab) {
                window.open(`/home/forms/${data._id}/questions`, '_blank');
              } else {
                dispatch(push(`/home/forms/${data._id}/questions`));
              }
            } else {
              toast('Form has been saved');
            }
          }
        },
        () => {
          dispatch({ type: Types.ADD_NEW_FORM_FAIL });
        },
      ),
    );
  };
};

export const editForm = (data, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = get(state, 'user', null);

    dispatch({ type: Types.EDIT_FORM_REQUEST });

    return dispatch(
      Request.patch(
        { url: `api/forms/${data._id}`, data },
        true,
        response => {
          if (get(response, 'data.data.success', false)) {
            dispatch({ type: Types.EDIT_FORM_SUCCESS, payload: { data, currentUser } });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.EDIT_FORM_FAILED });
          callback && callback();
        },
      ),
    );
  };
};

export const duplicateForm = (params, callback, isOpenNewTab = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = get(state, 'user._id', '');
    const author = get(params, 'owner', '');
    const isOwner = currentUser === author || !author;
    const draftForms = get(state, 'rootReducer.formDetails.draftForms', []);
    const selectedForm = draftForms.find(item => item._id === params._id);
    const selectedFormQuestions = get(selectedForm, 'questions', []);
    const formQuestions = selectedFormQuestions.map(question =>
      omit(question, ['has_trigger_in_use', 'related_question_onboarding_flows']),
    );

    dispatch({ type: Types.DUPLICATE_FORM_REQUEST });

    return dispatch(
      Request.post(
        { url: `api/forms/${params._id}/duplicate`, data: params },
        true,
        response => {
          const { data } = response.data;
          dispatch({
            type: Types.DUPLICATE_FORM_SUCCESS,
            payload: { prevId: params._id, data: { ...data, questions: formQuestions }, isOwner },
          });
          callback && callback();
          if (isOpenNewTab && isOwner) {
            window.open(`/home/forms/${data._id}/questions`, '_blank');
            return;
          }
          if (isOwner) {
            if (selectedForm) {
              dispatch(duplicateFormQuestion({ ...data, questions: formQuestions }));
            }
            dispatch(push(`/home/forms/${data._id}/questions`));
          }
        },
        () => {
          dispatch({ type: Types.DUPLICATE_FORM_FAILED });
          callback && callback();
        },
      ),
    );
  };
};

export const removeForm = (id, callback, shouldCheckPagination = false) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        forms: { query, list },
      },
    } = getState();
    dispatch({ type: Types.DELETE_FORM_REQUEST });

    return dispatch(
      Request.delete(
        { url: `api/forms/${id}` },
        true,
        response => {
          if (get(response, 'data.data.success', false)) {
            dispatch({ type: Types.DELETE_FORM_SUCCESS, payload: id });
          }

          if (shouldCheckPagination) {
            if (query.page > 1 && list.length === 1) {
              dispatch(changeQueryParams({ page: query.page - 1 }));
            }
          }

          callback && callback();
        },
        () => {
          dispatch({ type: Types.DELETE_FORM_FAILED });
          callback && callback();
        },
      ),
    );
  };
};

export const changeQueryParams = (params, isPublished = false) => {
  return dispatch => {
    dispatch({ type: Types.FORM_CHANGE_QUERY_PARAMS, payload: params });
    if (isPublished) {
      dispatch(getPublishedForms(params));
    } else {
      dispatch(getAllForms(params));
    }
  };
};

export const resetQueryParams = (isResetFull = false) => {
  return dispatch => {
    dispatch({ type: Types.FORM_RESET_QUERY_PARAMS, payload: { isResetFull: isResetFull } });
  };
};

export const archiveForm = (id, status, callback) => {
  return dispatch => {
    dispatch({ type: Types.ARCHIVE_FORM_REQUEST });

    return dispatch(
      Request.patch(
        { url: `api/forms/${id}/archive`, data: { is_archive: status } },
        true,
        response => {
          if (get(response, 'data.data.success', false)) {
            dispatch({ type: Types.ARCHIVE_FORM_SUCCESS, payload: { id, status } });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.ARCHIVE_FORM_FAILED });
        },
      ),
    );
  };
};

export const getFormTemplates = () => {
  return dispatch => {
    dispatch({ type: Types.GET_FORM_TEMPLATES_REQUEST });

    return dispatch(
      Request.get(
        { url: '/api/onboarding-flows/form-templates' },
        true,
        (res, { dispatch }) => {
          const { data = [] } = res.data;
          dispatch({ type: Types.GET_FORM_TEMPLATES_SUCCESS, payload: { data } });
        },
        () => {
          dispatch({ type: Types.GET_FORM_TEMPLATES_FAILED });
        },
      ),
    );
  };
};

export const resetFormTemplates = () => {
  return dispatch => dispatch({ type: Types.RESET_FORM_TEMPLATES });
};
