import { connect } from 'react-redux';
import get from 'lodash/get';
import type { State, Dispatch } from 'types/redux';
import ExerciseHistoryChart from './component';
import { getExerciseHistoryEntriesByTime } from 'actions/client';
import { changeGettingChartData, updateLoadingExerciseMetric } from 'actions/bodyMetric';

const mapStateToProps = (state: State) => {
  const { user, rootReducer, bodyMetric } = state;
  const selectedClient = rootReducer.client.workingClientDetail;
  return {
    date_format: user.date_format,
    clientId: rootReducer.client.selected,
    systemUnits: selectedClient ? selectedClient.preferences : user.preferences,
    selectedClient,
    bodymetricTypes: rootReducer.client.bodymetricTypes,
    customBrandingColor: get(rootReducer, 'customBranding.originalTheme.primaryColor', ''),
    filterTime: get(bodyMetric, 'filterTime', {}),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    getExerciseHistoryEntriesByTime: data => dispatch(getExerciseHistoryEntriesByTime(data)),
    changeGettingChartData: data => dispatch(changeGettingChartData(data)),
    updateLoadingExerciseMetric: data => dispatch(updateLoadingExerciseMetric(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseHistoryChart);
