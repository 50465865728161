import React from 'react';
import Avatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';
import * as S from '../style';

export default ({ data }) => {
  return (
    <S.TableCell className="customter">
      <S.TableCellContent>
        <S.CheckboxContainer>
          <Avatar name={getUserShortName(data)} size="36" src={data.avatar} color={data.color} />
        </S.CheckboxContainer>
        <S.CustomerInfoContainer>
          <S.Name>{data.full_name}</S.Name>
          <S.Email>{data.email}</S.Email>
        </S.CustomerInfoContainer>
      </S.TableCellContent>
    </S.TableCell>
  );
};
