import React, { Component } from 'react';
import get from 'lodash/get';

// Components
import MetricChart from './components/MetricChart';
import SleepChart from './components/SpecialChart/Sleep/index';
import ChartHeader from './components/ChartHeader';
import KeysMetric from './components/KeysMetric';
import SleepKeysMetric from './components/KeysMetric/Sleep';
import BodyMetricProgressNew from 'components/BodyMetricProgressNew';
import HeartRate from './components/SpecialChart/HeartRate';
import HeartRateKeysMetric from './components/KeysMetric/HeartRate';

// Constants
import {
  BAR_CHART_METRIC_TYPES,
  DEFAULT_COLOR,
  KEYS_METRIC,
  SPECIAL_METRICS_CODE,
  UNIT_SPECIAL_CHART_NAMES,
} from './constants';

import { Wrapper } from './styles';

export default class BodyMetricChartNew extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.bodyMetric !== this.props.bodyMetric) {
      this.renderKeysMetric();
      this.renderChart();
    }
  }

  componentDidMount() {
    const container = document.querySelector('.body-metric-scroll-wrapper');
    if (container) {
      container.addEventListener('scrollend', this.handleScrollEnd);
    }
  }

  componentWillUnmount() {
    const container = document.querySelector('.body-metric-scroll-wrapper');
    if (container) {
      container.removeEventListener('scrollend', this.handleScrollEnd);
    }
  }

  handleScrollEnd = () => {
    const items = document.querySelectorAll('.my-custom-dot');
    for (const item of items) {
      item.style.display = 'unset';
    }
  };

  renderChart = () => {
    const { bodyMetric, metricTypes, timezone, customBrandingColor, hearRateMetric } = this.props;
    const { chartData, target, isGettingChartData, metricSettings, filterTime, unique_code } = bodyMetric;
    const goal = target.data ? target.data.value : 0;
    const { chartData: heartRateChartData = [], isGettingChartData: isGettingHeartRate } = hearRateMetric;

    const isLoading = this.getIsLoading();

    const chartDataSorted = chartData
      .filter(item => !!item && !isNaN(parseInt(item.value)))
      .map(item => ({
        ...item,
        goal,
        period_group: filterTime.period_group,
        value: +item.value,
        time: item.group_name || item.date,
      }));

    switch (unique_code) {
      case SPECIAL_METRICS_CODE.HEART_RATE:
        return (
          <HeartRate
            metricTypes={metricTypes}
            bodyMetric={bodyMetric}
            timezone={timezone}
            rangeTime={filterTime}
            selectedUnit={metricSettings.unit}
            target={target.data}
            isLoading={isGettingHeartRate}
            chartData={heartRateChartData}
            hearRateMetric={hearRateMetric}
            filterTime={filterTime}
          />
        );

      case SPECIAL_METRICS_CODE.SLEEP:
        return (
          <SleepChart
            metricTypes={metricTypes}
            bodyMetric={bodyMetric}
            timezone={timezone}
            rangeTime={filterTime}
            selectedUnit={metricSettings.unit}
            chartData={chartData}
            target={target.data}
            isLoading={isLoading || bodyMetric.isChangeSetting}
          />
        );

      default:
        return (
          <MetricChart
            metricTypes={metricTypes}
            bodyMetric={bodyMetric}
            timezone={timezone}
            rangeTime={filterTime}
            selectedUnit={metricSettings.unit}
            chartData={chartDataSorted}
            chartType={
              BAR_CHART_METRIC_TYPES.includes(get(bodyMetric, 'unique_code')) ? 'bar' : metricSettings.type || 'line'
            }
            color={metricSettings.color || customBrandingColor || DEFAULT_COLOR}
            target={target.data}
            isLoading={isLoading || bodyMetric.isChangeSetting}
            isDisplayDataPoints={metricSettings.display_data_points}
          />
        );
    }
  };

  getIsLoading = () => {
    const { bodyMetric } = this.props;
    const { isGettingChartData, isGettingTarget, isGettingSetting, isChangeSetting } = bodyMetric;
    return isGettingChartData || isGettingTarget || isGettingSetting || isChangeSetting;
  };

  renderKeysMetric = () => {
    const { bodyMetric, metricTypes, updateBodyMetricTarget, client, customBrandingColor, hearRateMetric } = this.props;
    const { metricSettings, unique_code } = bodyMetric;

    let Comp = KeysMetric;

    if (unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      Comp = SleepKeysMetric;
    }
    if (unique_code === SPECIAL_METRICS_CODE.HEART_RATE) {
      return <HeartRateKeysMetric bodyMetric={bodyMetric} hearRateMetric={hearRateMetric} />;
    }

    return (
      <Comp
        bodyMetric={bodyMetric}
        handleConvert={this.handleConvert}
        metricTypes={metricTypes}
        updateBodyMetricTarget={updateBodyMetricTarget}
        clientId={client._id}
        color={metricSettings.color || customBrandingColor || DEFAULT_COLOR}
        customBrandingColor={customBrandingColor}
        isLoading={this.getIsLoading() || (bodyMetric || {}).isLoadingCompareChart}
      />
    );
  };
  isHeartRateChart = unique_code => {
    return SPECIAL_METRICS_CODE.HEART_RATE === unique_code;
  };

  isSpecialChart = unique_code => {
    return UNIT_SPECIAL_CHART_NAMES.includes(unique_code);
  };

  renderProgress = () => {
    const { total, dataMetric, onLoadMore, loading } = this.props;
    const isLoadingMetric = this.getIsLoading();

    return (
      <BodyMetricProgressNew
        dataMetric={dataMetric}
        onLoadMore={onLoadMore}
        loading={loading}
        total={total}
        isLoadingMetric={isLoadingMetric}
      />
    );
  };

  isSpecialChart = unique_code => {
    return UNIT_SPECIAL_CHART_NAMES.includes(unique_code);
  };

  renderProgress = () => {
    const { total, dataMetric, onLoadMore, loading } = this.props;
    const isLoadingMetric = this.getIsLoading();
    return (
      <BodyMetricProgressNew
        dataMetric={dataMetric}
        onLoadMore={onLoadMore}
        loading={loading}
        total={total}
        isLoadingMetric={isLoadingMetric}
      />
    );
  };

  render() {
    const { bodyMetric, changeViewMode, metricTypes } = this.props;
    const { unique_code, isLoadingCompareChart } = bodyMetric;
    const keyMetrics = get(bodyMetric, 'metricSettings.key_metrics', []);
    const isLoadingMetric = this.getIsLoading();
    const isShowKeyMetric = KEYS_METRIC.some(item => keyMetrics.includes(item.key));
    const enableCompareChart = ![SPECIAL_METRICS_CODE.SLEEP, SPECIAL_METRICS_CODE.HEART_RATE].includes(unique_code);

    if (!unique_code) return null;

    return (
      <Wrapper className="metric-chart-wrapper" isHideKeyMetric={!isShowKeyMetric}>
        <div className="metric-chart-content">
          <div className="panel-header">
            <ChartHeader
              bodyMetric={bodyMetric}
              changeViewMode={changeViewMode}
              metricTypes={metricTypes}
              enableCompareChart={enableCompareChart}
              enableSetting
              isLoadingMetric={isLoadingMetric || isLoadingCompareChart}
            />
          </div>
          {this.renderKeysMetric(this.isHeartRateChart(unique_code))}
        </div>
        <div className={`body-metric-scroll-wrapper metric-${unique_code}`} id="client-metric-content-scroll">
          {this.renderChart()}
          {this.renderProgress()}
        </div>
      </Wrapper>
    );
  }
}
