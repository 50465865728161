import React from 'react';
import Request, { axiosInstance } from 'configs/request';
import { toast } from 'react-toastify';

import { Mixpanel } from 'utils/mixplanel';
import { toggleModal } from 'actions/modal';
import { MultiplePasteMessage } from 'shared/Notifications';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { CLASSNAME_TOAST, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { convertWorkoutUnits } from 'helpers/workout';

// Import from lodash
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { mongoObjectId, pluralize } from 'utils/commonFunction';
import { removeMedialDefault } from 'redux/workout_library/workout.actionCreators';

export const Types = {
  AUTOFLOW_INTERVAL_TRAINING_SUCCESS_GET_WORKOUTS: 'AUTOFLOW_INTERVAL_TRAINING_SUCCESS_GET_WORKOUTS',
  AUTOFLOW_INTERVAL_TRAINING_SUCCESS_ADD_WORKOUT: 'AUTOFLOW_INTERVAL_TRAINING_SUCCESS_ADD_WORKOUT',
  AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_SINGLE_DAY: 'AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_SINGLE_DAY',
  AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS:
    'AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS',
  AUTOFLOW_INTERVAL_TRAINING_COPY_WORKOUT: 'AUTOFLOW_INTERVAL_TRAINING_COPY_WORKOUT',
  AUTOFLOW_INTERVAL_TRAINING_RESET_COPY_ITEM: 'AUTOFLOW_INTERVAL_TRAINING_RESET_COPY_ITEM',
  AUTOFLOW_INTERVAL_TRAINING_MOVE_WORKOUT_ON_LOCAL: 'AUTOFLOW_INTERVAL_TRAINING_MOVE_WORKOUT_ON_LOCAL',
  AUTOFLOW_INTERVAL_TRAINING_ARRANGE_WORKOUT_ON_LOCAL: 'AUTOFLOW_INTERVAL_TRAINING_ARRANGE_WORKOUT_ON_LOCAL',
  AUTOFLOW_INTERVAL_TRAINING_MOVE_EXERCISE: 'AUTOFLOW_INTERVAL_TRAINING_MOVE_EXERCISE',
  AUTOFLOW_INTERVAL_TRAINING_RESET_DATA: 'AUTOFLOW_INTERVAL_TRAINING_RESET_DATA',
  AUTOFLOW_INTERVAL_TRAINING_SELECT_WORKOUT: 'AUTOFLOW_INTERVAL_TRAINING_SELECT_WORKOUT',
  AUTOFLOW_INTERVAL_TRAINING_COPY_MULTIPLE_WORKOUTS: 'AUTOFLOW_INTERVAL_TRAINING_COPY_MULTIPLE_WORKOUTS',
  AUTOFLOW_INTERVAL_TRAINING_SELECT_WEEK: 'AUTOFLOW_INTERVAL_TRAINING_SELECT_WEEK',
  AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_REQUEST: 'AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_REQUEST',
  AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_SUCCESS: 'AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_SUCCESS',
  AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_FAIL: 'AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_FAIL',
  AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_REQUEST: 'AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_REQUEST',
  AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_SUCCESS: 'AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_SUCCESS',
  AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_FAILED: 'AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_FAILED',
  AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WORKOUTS: 'AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WORKOUTS',
  AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WEEK: 'AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WEEK',
  AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WORKOUT: 'AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WORKOUT',
  AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST:
    'AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST',
  AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS:
    'AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS',
  AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED:
    'AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED',
  AUTOFLOW_INTERVAL_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST:
    'AUTOFLOW_INTERVAL_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST',
  AUTOFLOW_INTERVAL_TRAINING_MULTIPLE_WORKOUT_SUCCESS: 'AUTOFLOW_INTERVAL_TRAINING_MULTIPLE_WORKOUT_SUCCESS',
  AUTOFLOW_INTERVAL_TRAINING_MULTIPLE_WORKOUT_FAILED: 'AUTOFLOW_INTERVAL_TRAINING_MULTIPLE_WORKOUT_FAILED',
  AUTOFLOW_INTERVAL_SOCKET_WORKOUT_ADDED: 'AUTOFLOW_INTERVAL_SOCKET_WORKOUT_ADDED',
  AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_REQUEST: 'AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_REQUEST',
  AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_SUCCESS: 'AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_SUCCESS',
  AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_FAIL: 'AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_FAIL',
};

const parseWorkoutDataByDay = (weekDataList, units) => {
  const workoutsData = {};

  forEach(weekDataList, weekData => {
    const { weekIndex, days_workout } = weekData;
    forEach(days_workout, dayData => {
      const {
        day_index,
        day_workout: { workouts },
      } = dayData;
      const convertedWorkouts = map(workouts, workout => convertWorkoutUnits(workout, units));
      workoutsData[`${weekIndex}_${day_index}`] = convertedWorkouts;
    });
  });
  return workoutsData;
};

export const resetAutoflowIntervalTrainingData = () => ({ type: Types.AUTOFLOW_INTERVAL_TRAINING_RESET_DATA });

export const copyToProgramLibrary = data => {
  return Request.post({ url: '/api/autoflow-interval-training/copy-to-library', data }, false);
};

export const getAutoflowIntervalListWorkouts = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        autoflow: {
          common: { workingAutoflow },
        },
        autoflowInterval: { common },
      },
      user,
    } = getState();

    const startWeek = common.get('startWeek');
    const viewMode = common.get('viewMode');
    const endWeek = startWeek + viewMode - 1;
    const autoflowId = get(workingAutoflow, '_id', '');
    const units = user.preferences;

    if (!autoflowId) {
      return;
    }
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_REQUEST,
    });

    return dispatch(
      Request.get(
        {
          url: '/api/autoflow-interval-training/get-autoflow-calendar-by-weeks?',
          params: { autoflowId, startWeek, endWeek },
        },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          const workoutsData = parseWorkoutDataByDay(data, units);
          dispatch({
            type: Types.AUTOFLOW_INTERVAL_TRAINING_SUCCESS_GET_WORKOUTS,
            payload: { data: workoutsData },
          });
        },
      ),
    );
  };
};

const updateWorkoutOfMultipleDays = (weekDaysData, autoflowId) => {
  const workoutsByDay = {};
  forEach(weekDaysData, weekDayData => {
    const { day_index, weekIndex } = weekDayData;
    workoutsByDay[`${weekIndex}_${day_index}`] = weekDayData.workouts;
  });

  return {
    type: Types.AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS,
    payload: { data: workoutsByDay, autoflowId },
  };
};

const updateWorkoutListOfSingleDay = (data, bodyData) => {
  const dayWorkouts = { [`${bodyData.weekIndex}_${data.day_index}`]: data.workouts };
  return {
    type: Types.AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_SINGLE_DAY,
    payload: { data: dayWorkouts, autoflowId: bodyData.autoflowId },
  };
};

export const addWorkoutToAutoflowInterval = bodyData => {
  const dataRequest = {
    ...bodyData,
    sections: removeMedialDefault(bodyData.sections),
  };

  return Request.post(
    { url: '/api/autoflow-interval-training/add-new-workout', data: dataRequest },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, dataRequest));
    },
  );
};

export const assignWorkoutToAutoflowInterval = bodyData => {
  return Request.post(
    { url: '/api/autoflow-interval-training/assign-new-workout', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData));
    },
  );
};

export const copyAutoflowIntervalWorkout = workout => {
  toast(<MultiplePasteMessage />);
  Mixpanel.track('autoflow_interval_training_calendar_screen_copy_workout');
  return {
    type: Types.AUTOFLOW_INTERVAL_TRAINING_COPY_WORKOUT,
    payload: { data: workout },
  };
};

export const resetAutoflowIntervalCopyItem = () => ({ type: Types.AUTOFLOW_INTERVAL_TRAINING_RESET_COPY_ITEM });

export const updateAutoflowIntervalWorkout = bodyData => {
  return Request.put(
    { url: '/api/autoflow-interval-training/update-workout-detail', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData));
    },
  );
};

export const saveAutoflowIntervalWorkoutToLibrary = bodyData => {
  Mixpanel.track('autoflow_interval_training_calendar_screen_save_workout_to_library');
  return Request.post({ url: '/api/autoflow-interval-training/save-to-library', data: bodyData }, false, () => {
    toast.success('Workout saved to library!', { autoClose: 3000 });
  });
};

export const deleteAutoflowIntervalWorkout = bodyData => {
  Mixpanel.track('autoflow_interval_training_calendar_screen_delete_workout');
  return Request.post(
    { url: '/api/autoflow-interval-training/remove-workout', data: bodyData },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(updateWorkoutListOfSingleDay(data, bodyData));
    },
  );
};

export const viewAutoflowIntervalWorkoutDetail = (params, otherData) => {
  return Request.get(
    { url: '/api/autoflow-interval-training/get-workout-detail', params },
    false,
    (response, { dispatch, getState }) => {
      const { data } = response.data;
      const {
        rootReducer: {
          autoflow: {
            common: {
              workingAutoflow: { interval_weeks },
            },
          },
        },
      } = getState();
      const { weekIndex, dayIndex, autoflowId } = otherData;

      dispatch(
        toggleModal(
          true,
          <WorkoutDetailModal
            key="update-autoflow-interval-training-workout"
            type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
            workingWorkout={{ ...data, day: Number(weekIndex) * 7 + Number(dayIndex) }}
            fetchWorkoutsByWeek={fetchAutoflowIntervalWorkoutsByWeek}
            maxWeeks={interval_weeks.length}
            pdfType="autoflow_workout"
            onSave={newWorkout => {
              const bodyData = {
                weekIndex,
                dayIndex,
                autoflowId,
                title: newWorkout.title,
                description: newWorkout.description,
                sections: newWorkout.sections,
                workoutId: newWorkout._id,
                background: newWorkout.background,
              };

              return dispatch(updateAutoflowIntervalWorkout(bodyData)).then(response => {
                const { data } = response.data;
                return find(data.workouts, w => w._id === bodyData.workoutId);
              });
            }}
            saveToLibrary={workout =>
              dispatch(saveAutoflowIntervalWorkoutToLibrary({ workoutId: workout._id, autoflowId }))
            }
            onDelete={workout => {
              dispatch(
                deleteAutoflowIntervalWorkout({
                  autoflowId,
                  weekIndex,
                  dayIndex,
                  workoutId: workout._id,
                }),
              );
            }}
          />,
        ),
      );
    },
  );
};

export const pasteAutoflowIntervalWorkoutToDay = (bodyData, multiPaste) => {
  Mixpanel.track('autoflow_interval_training_calendar_screen_paste_workout');
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetAutoflowIntervalCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/autoflow-interval-training/copy-workout', data: bodyData }, true, response => {
        const { data } = response.data;
        dispatch(updateWorkoutListOfSingleDay(data, bodyData));
      }),
    );
  };
};

const arrangeWorkoutsInDayOnLocal = data => ({
  type: Types.AUTOFLOW_INTERVAL_TRAINING_ARRANGE_WORKOUT_ON_LOCAL,
  payload: { data },
});

export const arrangeAutoflowIntervalWorkoutInDay = bodyData => {
  return dispatch => {
    dispatch(arrangeWorkoutsInDayOnLocal(bodyData));

    return dispatch(
      Request.post(
        { url: '/api/autoflow-interval-training/arrange-workout', data: bodyData },
        false,
        (response, { dispatch }) => {
          dispatch(updateWorkoutListOfSingleDay(response.data.data, bodyData));
        },
      ),
    );
  };
};

const moveAutoflowIntervalExerciseLocal = data => ({
  type: Types.AUTOFLOW_INTERVAL_TRAINING_MOVE_EXERCISE,
  payload: { data },
});

export const arrangeSectionInsideAutoflowIntervalWorkout = bodyData => {
  return dispatch => {
    const movaData = {
      workoutId: bodyData.workoutId,
      newIndex: bodyData.newIndex,
      oldIndex: bodyData.oldIndex,
      oldWeekIndex: bodyData.weekIndex,
      oldDayIndex: bodyData.dayIndex,
      newWeekIndex: bodyData.weekIndex,
      newDayIndex: bodyData.dayIndex,
      newWorkoutId: bodyData.workoutId,
    };
    dispatch(moveAutoflowIntervalExerciseLocal(movaData));
    return dispatch(
      Request.post(
        { url: '/api/autoflow-interval-training/arrange-section', data: bodyData },
        false,
        (response, { dispatch }) => {
          dispatch(updateWorkoutListOfSingleDay(response.data.data, bodyData));
        },
        () => {
          const data = {
            workoutId: bodyData.workoutId,
            newIndex: bodyData.oldIndex,
            oldIndex: bodyData.newIndex,
            oldWeekIndex: bodyData.weekIndex,
            oldDayIndex: bodyData.dayIndex,
            newWeekIndex: bodyData.weekIndex,
            newDayIndex: bodyData.dayIndex,
            newWorkoutId: bodyData.workoutId,
          };
          dispatch(moveAutoflowIntervalExerciseLocal(data));
        },
      ),
    );
  };
};

const moveAutoflowIntervalWorkoutOnLocal = data => ({
  type: Types.AUTOFLOW_INTERVAL_TRAINING_MOVE_WORKOUT_ON_LOCAL,
  payload: { data },
});

export const moveAutoflowIntervalWorkout = (bodyData, oldWorkoutIndex) => {
  Mixpanel.track('autoflow_interval_training_calendar_screen_move_workout');
  return dispatch => {
    dispatch(moveAutoflowIntervalWorkoutOnLocal(bodyData));

    return dispatch(
      Request.post(
        { url: '/api/autoflow-interval-training/move-workout', data: bodyData },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch(updateWorkoutOfMultipleDays(data, bodyData.autoflowId));
        },
        () => {
          const data = {
            ...bodyData,
            oldWeekIndex: bodyData.newWeekIndex,
            oldDayIndex: bodyData.newDayIndex,
            newWeekIndex: bodyData.oldWeekIndex,
            newDayIndex: bodyData.oldDayIndex,
            newIndex: oldWorkoutIndex,
          };

          dispatch(moveAutoflowIntervalWorkoutOnLocal(data));
        },
      ),
    );
  };
};

export const moveAutoflowIntervalSection = bodyData => {
  Mixpanel.track('autoflow_interval_training_calendar_screen_move_section');
  return dispatch => {
    dispatch(moveAutoflowIntervalExerciseLocal(bodyData));
    return dispatch(
      Request.post(
        { url: '/api/autoflow-interval-training/move-section', data: bodyData },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch(updateWorkoutOfMultipleDays(data, bodyData.autoflowId));
        },
        () => {
          const data = {
            workoutId: bodyData.newWorkoutId,
            newIndex: bodyData.oldIndex,
            oldIndex: bodyData.newIndex,
            oldWeekIndex: bodyData.newWeekIndex,
            oldDayIndex: bodyData.newDayIndex,
            newWeekIndex: bodyData.oldWeekIndex,
            newDayIndex: bodyData.oldDayIndex,
            newWorkoutId: bodyData.workoutId,
          };
          dispatch(moveAutoflowIntervalExerciseLocal(data));
        },
      ),
    );
  };
};

export const addSectionToAutoflowIntervalWorkout = data => {
  Mixpanel.track('autoflow_interval_training_calendar_screen_add_exercise_to_wrokout');
  return Request.post(
    { url: '/api/autoflow-interval-training/add-workout-section', data },
    true,
    (response, { dispatch }) => {
      dispatch(updateWorkoutListOfSingleDay(response.data.data, data));
    },
  );
};

export const updateAutoflowIntervalSectionFromCalendar = bodyData => {
  return Request.put(
    { url: '/api/autoflow-interval-training/update-workout-detail', data: bodyData },
    false,
    (response, { dispatch }) => {
      dispatch(updateWorkoutListOfSingleDay(response.data.data, bodyData));
    },
  );
};

export const fetchAutoflowIntervalWorkoutsByWeek = data => {
  return (dispatch, getState) => {
    const state = getState();
    const { workingAutoflow } = state.rootReducer.autoflow.common;
    const params = { ...data, autoflowId: workingAutoflow._id };

    return axiosInstance
      .get('/api/autoflow-interval-training/get-autoflow-calendar-by-weeks?', { params })
      .then(response => {
        dispatch({
          type: Types.AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_SUCCESS,
          payload: response,
        });
        const workoutData = {};
        forEach(response.data.data, week => {
          forEach(week.days_workout, dayData => {
            const dayIndex = week.weekIndex * 7 + dayData.day_index;
            workoutData[dayIndex] = {
              day: dayIndex,
              workouts: get(dayData, 'day_workout.workouts', []),
            };
          });
        });
        return workoutData;
      });
  };
};

export const assignProgramToAutoflowInterval = params => {
  return Request.post({ url: '/api/v2/autoflow-interval-training/assign-program', data: params }, true);
};

// Select a workout to copy or delete
export const selectWorkout = id => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_SELECT_WORKOUT,
      payload: { data: id },
    });
  };
};

export const handleCopyWorkouts = ids => {
  const TEMP_ID = mongoObjectId();
  return dispatch =>
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_COPY_MULTIPLE_WORKOUTS,
      payload: { data: ids, copyId: TEMP_ID },
    });
};

// Paste workouts
export const handlePasteMultipleWorkout = (dayIndex, weekIndex, multiPaste = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const training = get(state, 'rootReducer.autoflowInterval.training');
    const workingAutoflow = get(state, 'rootReducer.autoflow.common.workingAutoflow');
    const trainingObj = training.toJS();
    const autoflowId = get(workingAutoflow, '_id');
    const workoutIds = get(trainingObj, 'selectedWorkouts');
    const copyId = get(trainingObj, 'copyId');
    if (isEmpty(autoflowId) || isEmpty(workoutIds)) return;

    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST,
      payload: { multiPaste },
    });

    return dispatch(
      Request.post(
        {
          url: `/api/v2/autoflow-interval-training/bulk-copy`,
          data: {
            autoflow_id: autoflowId,
            workout_ids: workoutIds,
            day_index: dayIndex,
            week_index: weekIndex,
            copy_id: copyId,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.AUTOFLOW_INTERVAL_TRAINING_MULTIPLE_WORKOUT_SUCCESS });
          }
        },
        error => {
          dispatch({ type: Types.AUTOFLOW_INTERVAL_TRAINING_MULTIPLE_WORKOUT_FAILED, error });
        },
      ),
    );
  };
};

// Delete all selected workouts
export const handleRemoveWorkouts = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      autoflow: {
        common: { workingAutoflow = {} },
      },
      autoflowInterval: { training },
    } = state.rootReducer;

    const trainingObj = training ? training.toJS() : {};
    const autoflowId = get(workingAutoflow, '_id', '');
    const workoutIds = get(trainingObj, 'selectedWorkouts', {});

    if (!autoflowId || isEmpty(workoutIds)) return;
    if (isEmpty(workoutIds)) {
      dispatch(resetSelectedWorkouts());
      toast(`0 Workouts have been removed.`, { className: CLASSNAME_TOAST.TRAINING_CALENDAR });
      return;
    }
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: `/api/v2/autoflow-interval-training/bulk-remove`,
          data: {
            autoflow_id: autoflowId,
            workout_ids: workoutIds,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          const workoutDeleted = get(response, 'data.data.workout_ids', []);
          if (success) {
            dispatch({
              type: Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS,
              payload: workoutDeleted,
            });
            const unit = pluralize('Workout', workoutDeleted.length);
            const verbs = workoutDeleted.length !== 1 ? 'have' : 'has';
            dispatch(resetSelectedWorkouts());
            toast(`${workoutDeleted.length} ${unit} ${verbs} been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => {
          dispatch({ type: Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED, error });
          dispatch(resetSelectedWorkouts());
        },
      ),
    );
  };
};

// Reset selected workouts
export const resetSelectedWorkouts = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WORKOUTS,
    });
  };
};

// Select a week to copy or delete
export const selectWeek = weekIndex => {
  const TEMP_ID = mongoObjectId();
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_SELECT_WEEK,
      payload: { weekIndex: weekIndex, weekCopyId: TEMP_ID },
    });
  };
};

// Paste a week
export const handlePasteWeek = (pasteWeekIndex, multiPaste) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      autoflow: {
        common: { workingAutoflow = {} },
      },
    } = state.rootReducer;

    const {
      autoflowInterval: { training },
    } = state.rootReducer;

    const autoflowTraining = training ? training.toJS() : {};
    const selectedWeek = get(autoflowTraining, 'selectedWeek');
    const weekCopyId = get(autoflowTraining, 'weekCopyId');

    const autoflowId = get(workingAutoflow, '_id');
    if (isEmpty(autoflowId)) return;

    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_REQUEST,
      payload: { multiPaste },
    });

    return dispatch(
      Request.post(
        {
          url: '/api/v2/autoflow-interval-training/copy-week',
          data: {
            autoflow_id: autoflowId,
            copy_week: selectedWeek,
            paste_week: pasteWeekIndex,
            copy_id: weekCopyId,
          },
        },
        false,
        response => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_SUCCESS });
          }
        },
        error => dispatch({ type: Types.AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_FAIL, error }),
      ),
    );
  };
};

// Remove workout in week
export const handleRemoveWorkoutWeek = weekIndex => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      autoflow: {
        common: { workingAutoflow = {} },
      },
    } = state.rootReducer;

    const autoflowId = get(workingAutoflow, '_id', '');

    if (!autoflowId) return;

    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_REQUEST,
    });

    return dispatch(
      Request.post(
        {
          url: '/api/v2/autoflow-interval-training/remove-week',
          data: {
            autoflow_id: autoflowId,
            week_index: weekIndex,
          },
        },
        false,
        response => {
          const removedIds = get(response, 'data.data.workout_ids', []);
          if (removedIds) {
            dispatch({
              type: Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_SUCCESS,
              payload: removedIds,
            });
            toast(`All workouts from Week ${weekIndex + 1} have been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => dispatch({ type: Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_FAILED, error }),
      ),
    );
  };
};

// Reset selected week
export const resetSelectedWeek = () => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WEEK,
    });
  };
};

// Socket update workout after copy week/workout
export const socketAutoflowIntervalWorkoutAdded = data => {
  return (dispatch, getState) => {
    const state = getState();
    const workingAutoflow = get(state, 'rootReducer.autoflow.common.workingAutoflow');
    const autoflowId = get(workingAutoflow, '_id', '');
    const weekIndex = get(data, 'week_index');
    const dayIndex = get(data, 'day_index');
    const workout = get(data, 'workout');
    const weekDayIndex = `${weekIndex}_${dayIndex}`;

    if (isEqual(autoflowId, get(data, 'autoflow_id'))) {
      return dispatch({
        type: Types.AUTOFLOW_INTERVAL_SOCKET_WORKOUT_ADDED,
        payload: {
          autoflowId,
          workout,
          weekDayIndex,
        },
      });
    }
    return;
  };
};
