import React, { useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';

import { pluralize } from 'utils/commonFunction';
import * as S from './../../TaskCreate/style';
import * as I from './style';
import { DropdownIndicator } from 'shared/Icons';
import { INTERVAL_TYPES } from '../constants';

const IntervalRepeat = ({ selectedType = 'day', selectedEvery = 1, selectedTimes = 5, onChange, errors, habit }) => {
  const [type, setType] = useState(INTERVAL_TYPES.find(item => item.value === selectedType));
  const [every, setEvery] = useState(selectedEvery);
  const [times, setTimes] = useState(selectedTimes);

  const handleSelectRepeatType = item => {
    setType(item);
  };

  const handleOnChangeEvery = event => {
    const { value } = event.target;
    const parsed = parseInt(value);
    setEvery(parsed !== 0 && !isNaN(parsed) ? parsed : '');
  };

  const handleOnChangeTimes = event => {
    const { value } = event.target;
    const parsed = parseInt(value);
    setTimes(parsed !== 0 && !isNaN(parsed) ? parsed : '');
  };

  useEffect(() => {
    onChange({
      autoflow_interval_repeat_type: type.value,
      every,
      autoflow_interval_times: times,
    });
  }, [type, every, times]);

  const limitTimes = habit ? 999 : type.value === 'day' ? 20 : 26;

  return (
    <S.Frequency>
      {!habit && <S.RepeatTitle>Every</S.RepeatTitle>}
      <I.EWrapper habit={habit}>
        <I.Input
          autoFocus={!habit}
          habit={habit}
          value={every}
          onChange={handleOnChangeEvery}
          className={(_.includes(errors, 'every') || (habit && every > 99)) && 'error'}
        />
      </I.EWrapper>
      <I.EWrapper habit={habit}>
        <Select
          options={INTERVAL_TYPES}
          components={{
            DropdownIndicator: habit ? () => <Icon name="chevron down" size="small" /> : DropdownIndicator,
            IndicatorSeparator: null,
          }}
          onChange={handleSelectRepeatType}
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          value={type}
          isSearchable={false}
          styles={{
            control: base => ({
              ...base,
              minHeight: 36,
              height: 36,
              minWidth: habit ? 102 : 91,
              marginRight: habit ? 8 : 10,
            }),
          }}
          menuPosition="fixed"
        />
      </I.EWrapper>
      <S.RepeatTitle habit={habit}>For</S.RepeatTitle>
      <I.EWrapper habit={habit}>
        <I.Input
          habit={habit}
          value={times}
          onChange={handleOnChangeTimes}
          className={(_.includes(errors, 'times') || times > limitTimes) && 'error'}
        />
      </I.EWrapper>
      <S.RepeatTitle habit={habit}>{pluralize('Time', times)}</S.RepeatTitle>
    </S.Frequency>
  );
};

export default IntervalRepeat;
