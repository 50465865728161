import React, { useState } from 'react';
import { pluralize } from 'utils/commonFunction';
import { connect } from 'react-redux';
import { Modal, Button, Image } from 'semantic-ui-react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import AsyncSelect from 'react-select/lib/Async';
import SelectClientOption from 'shared/SelectClientOption';
import { Button as ActionButton } from 'shared/FormControl';
import { selectStyles } from 'shared/SearchGroupName';
import { addClients, searchClients, searchGroupClients } from 'redux/autoflow/client/actions';
import { CDN_URL, CLIENT_STATUS } from 'constants/commonData';
import './style.scss';

function Component(props) {
  const [clients, setClients] = useState([]);
  let [assignToGroup, setAssignToGroup] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const searchClient = (inputValue, callback) => {
    const except = map(clients, '_id');
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    props
      .searchGroupClients({ q: search, selected_clients: except })
      .then(response => {
        const { data } = response.data;

        if (data) {
          if (data.length > 0) {
            callback(
              map(data, item => ({
                ...item,
                key: item._id,
                value: item._id,
                label: `${item.first_name} ${item.last_name}`,
                typeName: 'client',
              })),
            );
          } else {
            searchGroupClient(inputValue, callback);
          }
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const searchGroupClient = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    const searchAll = search.toLowerCase() === 'group' ? true : false;

    props
      .searchGroupClients({ txt_search: searchAll ? '' : search })
      .then(response => {
        const { data } = response.data;

        if (data) {
          const { data } = response.data;
          const clients = data.clients.map(o => ({
            ...o,
            typeName: 'client',
            name: `${o.first_name} ${o.last_name}`,
          }));
          const groups = get(data, 'groups', [])
            .filter(item => item && item.total_clients > 0)
            .map(g => ({
              ...g,
              typeName: 'group',
            }));
          const results = orderBy([...clients, ...groups], ['typeName'], ['asc']).filter(
            item =>
              item.typeName === 'group' ||
              ((item.client_connection === CLIENT_STATUS.connected ||
                item.client_connection === CLIENT_STATUS.pending) &&
                !item.is_archived),
          );

          callback(
            map(searchAll ? groups : results, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label:
                item.typeName === 'group'
                  ? `${item.name} (${item.total_clients} ${pluralize('client', item.total_clients || 0)})`
                  : item.name,
              total: item.total_clients,
              typeName: item.typeName,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const onClose = () => {
    setClients([]);
    setIsSubmitting(false);
    setOpen(false);
  };

  const onAdd = () => {
    if (isSubmitting || !clients.length) {
      return true;
    }

    if (assignToGroup.length > 0) {
      map(clients, item => {
        if (item.typeName !== 'group') assignToGroup.push(item);
      });
    }

    setIsSubmitting(true);
    props
      .addClients(handleFormatData())
      .then(() => {
        onClose();
        handleClearAddList();
      })
      .catch(() => setIsSubmitting(false));
  };

  const handleClearAddList = () => {
    setClients([]);
    setAssignToGroup([]);
  };

  const handleFormatData = () => {
    const clientIds = map(clients, '_id');

    return {
      autoflow: props.autoflow,
      clients: assignToGroup.length > 0 ? [...new Set(map(assignToGroup, '_id'))] : clientIds,
      first_client_name:
        get(clients, [0, 'first_name']) && get(clients, [0, 'last_name'])
          ? `${get(clients, [0, 'first_name'])} ${get(clients, [0, 'last_name'])}`
          : get(clients, [0, 'name']),
    };
  };

  const searchClientDebounce = debounce(searchClient, 200);

  function handleAssignToMember(list) {
    const assignToGroup = [];

    list
      .filter(item => item.typeName === 'group')
      .map(item => {
        props.searchGroupClients({ group: item._id }).then(response => {
          map(response.data.data.clients, item => {
            assignToGroup.push(item);
          });
        });
      });
    setClients(list);
    setAssignToGroup(assignToGroup);
  }

  return (
    <Modal
      open={open}
      className="autoflow-add-clients-modal"
      closeOnDimmerClick={false}
      closeIcon={
        <Button onClick={() => {}} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <ActionButton className="autoflow__add-clients-trigger">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M7 8.16667C8.93025 8.16667 10.5 6.59692 10.5 4.66667V3.5C10.5 1.56975 8.93025 0 7 0C5.06975 0 3.5 1.56975 3.5 3.5V4.66667C3.5 6.59692 5.06975 8.16667 7 8.16667Z"
              fill="#A1A1A5"
            />
            <path
              d="M12.25 11.6668C10.8389 11.6668 9.66233 10.6647 9.39225 9.3335H4.08333C1.83225 9.3335 0 11.1652 0 13.4168V14.0002H14V13.4168C14 12.6818 13.8022 11.9935 13.4604 11.3967C13.0912 11.5665 12.6834 11.6668 12.25 11.6668Z"
              fill="#A1A1A5"
            />
            <path
              d="M12.8333 7H11.6667V8.16667H10.5V9.33333H11.6667V10.5H12.8333V9.33333H14V8.16667H12.8333V7Z"
              fill="#A1A1A5"
            />
          </svg>
          <span>+ Clients</span>
        </ActionButton>
      }
    >
      <Modal.Header>
        <span>Add clients to Autoflow</span>
      </Modal.Header>
      <Modal.Content>
        <div>
          <AsyncSelect
            isMulti
            defaultOptions
            cacheOptions
            components={{ Option: SelectClientOption }}
            loadOptions={(inputValue, callback) => searchClientDebounce.call(this, inputValue, callback)}
            onChange={list => handleAssignToMember(list)}
            className={`multi-select-container multi-select--autoflow__clients`}
            classNamePrefix="multi-select"
            placeholder="Enter user..."
            noOptionsMessage={() => 'No clients found.'}
            styles={selectStyles}
            openMenuOnFocus
            autoFocus
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ActionButton purple onClick={onAdd} disabled={!clients.length}>
          Add Clients
        </ActionButton>
      </Modal.Actions>
    </Modal>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  return {
    autoflow: workingAutoflow ? workingAutoflow._id : '',
  };
};

export default connect(mapStateToProps, { addClients, searchClients, searchGroupClients })(Component);
