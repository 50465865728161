export const FORM_DEFAULT_UPLOAD_SETTINGS = {
  maxSize: 25,
  validateExtensions: ['png', 'jpg', 'jpeg'],
  maxSizeMessage: 'Image size limit is 25MB.',
  acceptedImage: 'image/png, image/jpeg, image/jpg',
};

export const TYPE_HIDE_ADVANCED_SETTINGS = ['signature', 'image_and_text'];

export const DIMENSIONS = {
  MAX_WIDTH: 572,
  MAX_HEIGHT: 355,
  AT_LEAST_WIDTH: 335,
};

export const DATE_FORMATS = [
  { label: 'mm/dd/yyyy', value: 'MM/DD/YYYY' },
  { label: 'dd/mm/yyyy', value: 'DD/MM/YYYY' },
];

export const DATE_FORMAT = 'MM/DD/YYYY';

export const UNIQUE_CODE_FT = 'ft';

export const UNIQUE_CODE_IN = 'in';

export const TITLE_HEIGHT_ONE_LINE = 28;
