import styled, { css } from 'styled-components';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const TabsWrapper = styled.div``;
export const TabItem = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      color: #5158cf;
      box-shadow: inset 0px -2px 0px #5158cf;
      padding: 6px 0px;
    `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  .ui.button {
    margin-left: 5px;
  }
  .ui.button:disabled {
    opacity: 1 !important;
    cursor: not-allowed !important;
    pointer-events: initial !important;
    &:hover {
      pointer-events: initial !important;
      opacity: 1 !important;
    }
  }
  .overview-close,
  .overview-save-draft,
  .overview-edit {
    button {
      &:hover,
      &:focus {
        color: #818da1;
        background: #f5f7f9 !important;
      }
    }

    &:hover,
    &:focus {
      background-color: #f5f7f9;

      span {
        color: #818da1;
      }
    }
  }

  .__react_component_tooltip {
    pointer-events: auto !important;
    max-width: 237px;
    background: #2d2e2d;
    border-radius: 5px;
    padding: 15px;
    .ui.button {
      margin-left: 0;
    }
    .message-popup {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }
    &.place-left {
      margin-left: 10px;
    }
  }
`;

export const SDropDown = styled(DropDown)`
  color: #202353 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  background-color: white;
`;

export const DropdownWrapper = styled.div`
  position: relative;

  .evf-dropdown.evf-dropdown--basic {
  }
  .evf-dropdown__trigger {
    height: 34px;
    width: 34px;
    background: #000000;
    opacity: 0.1;
    border-radius: 6px;
    :hover {
      background: #ffffff;
      opacity: 1;
    }
  }
  svg {
    width: 15px;
    margin-right: 10px;

    path,
    rect {
      stroke: white;
    }
  }
  .evf-dropdown__menu {
    width: 162px;
    color: #202353;
    background: white;
    border: 1px solid #dadfea;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    transform: translateY(2px) translateX(35px);
  }
  .evf-dropdown__option {
    padding: 7px 20px;

    rect,
    path {
      stroke: #202353;
    }

    &:hover {
      background-color: #f5f7f9;
      box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    }
  }
  .evf-dropdown__trigger-container {
    position: relative;
  }
`;

export const DropdownArrow = styled.span`
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  right: 14px;
  bottom: -9.5px;
  background: white;
  border-top: 1px solid #dadfea;
  border-left: 1px solid #dadfea;
  transform: rotateZ(45deg);
  z-index: 10000;
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: #ffffff;
  border-radius: 5px;
  width: 34px;
  height: 34px;
  border: 1px solid #d6dae4;
  margin-left: 2px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: #818da1;
  }

  &:hover {
    background-color: #f5f7f9;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;

    .dot,
    .dot::before,
    .dot::after {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
`;

export const ArrowTopIcon = styled.span`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 9px;
    width: 10px;
    height: 10px;
    display: block;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
    border-color: #e0e0e8 transparent transparent #e0e0e8 !important;
    border-style: solid !important;
    border-width: 1px !important;
    z-index: 1000;
  }
`;

export const DuplicateWrapper = styled.div`
  width: 637px;
  height: 64px;
  padding: 0 30px;
  position: fixed;
  top: 27px;
  left: 50%;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  background: #2e2525;
  color: #fff;
  border-radius: 6px;
  transform: translateX(-50%);
  align-items: center;
  display: flex;
  z-index: 2;
  span {
    margin-left: 6px;
  }
  .duplicate-indicator {
    display: inline-block;
    margin: 0 !important;
    width: 16px !important;
    height: 16px !important;
    &:before {
      width: 16px !important;
      height: 16px !important;
      border: 0.2em solid #adadad !important;
    }
    &:after {
      width: 16px !important;
      height: 16px !important;
      border-color: #fff transparent transparent !important;
    }
  }
`;
