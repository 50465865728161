import React from 'react';
import classnames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';

import Workout from './Workout';
import { Button } from 'shared/FormControl';
import { pluralize } from 'utils/commonFunction';
import AssignWorkoutModal from './AssignWorkoutModal';
import { CloseIcon } from 'shared/Icons';
import ConfirmModal from 'shared/ConfirmModal';
import { DRAGGALE_TYPE } from '../constants';
import { convertFromExerciseSetToSupersetsJson, convertFromWorkoutToAPIExercise } from 'helpers/workout';
import AssignMenu from './AssignMenu';
import SelectProgramForStudioProgramModal from 'components/SelectProgramForStudioProgramModal';

import * as S from './style';

class StudioProgramCalendarCell extends React.Component {
  constructor(props) {
    super(props);
    const [weekIndex, dayIndex] = (props.cellId || '').split('_');
    this.state = {
      isShowAllWorkouts: false,
      cellContentHeight: 0,
      maximumRenderedWorkouts: 0,
      weekIndex: Number(weekIndex),
      dayIndex: Number(dayIndex),
    };
    this.wrapperRef = null;
    this.resizeObserver = new ResizeObserver(this.handleResizeCallBack);
    this.debouncePasteMultipleWorkout = _.debounce(this.props.handlePasteMultipleWorkout, 100);
  }

  componentDidMount() {
    if (this.wrapperRef) {
      this.resizeObserver.observe(this.wrapperRef);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedWorkout !== this.props.selectedWorkout) {
      if (this.wrapperRef) {
        this.resizeObserver.disconnect();
        this.resizeObserver.observe(this.wrapperRef);
      }
    }
    if (prevProps.cellId !== this.props.cellId) {
      const [weekIndex, dayIndex] = (this.props.cellId || '').split('_');
      this.setState({
        weekIndex: Number(weekIndex),
        dayIndex: Number(dayIndex),
      });
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  handleResizeCallBack = entries => {
    if (entries[0]) {
      const {
        contentRect: { height },
        target,
      } = entries[0];

      if (height !== this.state.cellContentHeight) {
        const hasSelectedWorkout = target.classList.contains('has-overlap');
        const menuBottomBar = hasSelectedWorkout ? 52 : 0;

        const cellHeaderHeight = 34;
        const cellFooterHeight = 22;
        const containerHeight = height - cellFooterHeight - cellHeaderHeight - menuBottomBar;
        const workoutHeight = 52;
        const maximumRenderedWorkouts = parseInt(containerHeight / workoutHeight);

        this.setState({
          cellContentHeight: containerHeight,
          maximumRenderedWorkouts: maximumRenderedWorkouts,
        });
      }
    }
  };

  viewAllWorkouts = () => {
    this.setState({ isShowAllWorkouts: true });
  };

  hideViewAllMode = () => {
    this.setState({ isShowAllWorkouts: false });
  };

  onViewWorkoutDetail = workout => {
    const { weekIndex, dayIndex } = this.state;
    const { studioProgramId, disabled } = this.props;
    const otherData = { weekIndex, dayIndex, programId: studioProgramId, disabled };
    const params = { programId: studioProgramId, workoutId: workout._id };
    this.props.getStudioProgramWorkoutDetail(params, true, otherData);
  };

  pasteWorkout = event => {
    const { weekIndex, dayIndex } = this.state;
    const { copying, studioProgramId } = this.props;

    if (!copying) {
      return;
    }

    const multiPaste = event.shiftKey;
    const bodyData = { weekIndex, dayIndex, programId: studioProgramId, workoutId: copying._id };
    this.props.pasteWorkoutToDay(bodyData, multiPaste);
  };

  handlePasteWorkout = event => {
    const { dayIndex, weekIndex } = this.state;

    const multiPaste = event.shiftKey;
    this.debouncePasteMultipleWorkout({ dayIndex, weekIndex, multiPaste });
  };

  openAssignModal = () => {
    const { weekIndex, dayIndex } = this.state;
    const { studioProgramId } = this.props;
    this.props.toggleModal(
      true,
      <AssignWorkoutModal weekIndex={weekIndex} dayIndex={dayIndex} programId={studioProgramId} />,
    );
  };

  saveWorkoutToLibrary = workout => {
    const { studioProgramId } = this.props;
    this.props.saveStudioProgramWorkoutToLibrary({ workoutId: workout._id, programId: studioProgramId });
  };

  deleteWorkout = workout => {
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete workout"
        content="Are you sure that you want to delete this workout?"
        onConfirm={() => {
          const { weekIndex, dayIndex } = this.state;
          const { studioProgramId } = this.props;
          const bodyData = { programId: studioProgramId, weekIndex, dayIndex, workoutId: workout._id };
          this.props.deleteStudioProgramWorkout(bodyData);
        }}
      />,
    );
  };

  arrangeWorkoutInDay = (workout, newIndex) => {
    const { weekIndex, dayIndex } = this.state;
    const { studioProgramId } = this.props;
    const bodyData = { weekIndex, dayIndex, newIndex, programId: studioProgramId, workoutId: workout._id };
    this.props.arrangeStudioProgramWorkoutInDay(bodyData);
  };

  addSection = ({ assignment, exercise }) => {
    this.props.togglePopup(null);
    const { weekIndex, dayIndex } = this.state;
    const { studioProgramId } = this.props;
    const exerciseJson = convertFromExerciseSetToSupersetsJson(exercise);
    this.props.addSectionToStudioProgramWorkout({
      weekIndex,
      dayIndex,
      programId: studioProgramId,
      workoutId: assignment._id,
      exercise: exerciseJson,
    });
  };

  updateSection = newWorkoutData => {
    this.props.togglePopup(null);
    const { weekIndex, dayIndex } = this.state;
    const { studioProgramId, user } = this.props;
    const bodyData = {
      weekIndex,
      dayIndex,
      workoutId: newWorkoutData._id,
      programId: studioProgramId,
      title: newWorkoutData.title,
      description: newWorkoutData.description,
      sections: convertFromWorkoutToAPIExercise(newWorkoutData, user.preferences),
    };
    this.props.updateStudioProgramSectionFromCalendar(bodyData);
  };

  renderCellContent = () => {
    const { workouts, cellId, draggingWorkout, disabled, isCopySelectedWorkout } = this.props;
    const { isShowAllWorkouts, maximumRenderedWorkouts, cellContentHeight } = this.state;
    const remain = workouts.length - maximumRenderedWorkouts;
    const views = [];

    if (workouts.length) {
      const showAllWorkouts = maximumRenderedWorkouts >= workouts.length;

      for (let i = 0; i < workouts.length && (isShowAllWorkouts || i < maximumRenderedWorkouts); i++) {
        const onlyShowOverview = !showAllWorkouts || i < workouts.length - 1;
        views.push(
          <Workout
            key={`${cellId}_${workouts[i]._id}`}
            onlyShowOverview={onlyShowOverview}
            workoutIndex={i}
            currentWorkout={workouts[i]}
            cellId={cellId}
            totalWorkouts={workouts.length}
            cellContentHeight={cellContentHeight}
            isShowAllWorkouts={isShowAllWorkouts}
            draggingWorkout={draggingWorkout}
            viewWorkoutDetail={this.onViewWorkoutDetail}
            viewAllData={this.viewAllWorkouts}
            saveWorkoutToLibrary={this.saveWorkoutToLibrary}
            copyWorkout={this.props.copyStudioProgramWorkout}
            deleteWorkout={this.deleteWorkout}
            arrangeWorkoutInDay={this.arrangeWorkoutInDay}
            disabled={disabled}
            onAddSection={this.addSection}
            onUpdateSection={this.updateSection}
            onDeleteSection={this.deleteSection}
          />,
        );
      }
    }

    const content = (
      <S.CellContentContainer className="studio-program-cell__content-container">
        {views}
        {isShowAllWorkouts ? null : (
          <S.CellFooter>
            {remain > 0 ? (
              <S.ViewMoreItem onClick={this.viewAllWorkouts}>
                {pluralize(`+ ${remain} more workout`, remain)}
              </S.ViewMoreItem>
            ) : null}
            {isCopySelectedWorkout ? (
              <S.ButtonPasteMultipeWorkout
                onClick={this.handlePasteWorkout}
                className="studio-program-cell__button--paste--multiple-workout"
              >
                <S.ButtonText>Paste</S.ButtonText>
              </S.ButtonPasteMultipeWorkout>
            ) : (
              <Button purple onClick={this.pasteWorkout} className="studio-program-cell__button--paste">
                Paste
              </Button>
            )}
          </S.CellFooter>
        )}
      </S.CellContentContainer>
    );

    if (!views.length) {
      return (
        <Droppable droppableId={cellId} type={DRAGGALE_TYPE.SECTION} isDropDisabled={!!disabled}>
          {provided => {
            return (
              <S.CellContent
                ref={provided.innerRef}
                className="studio-program-cell__content studio-program-cell__content--empty"
              >
                {content}
              </S.CellContent>
            );
          }}
        </Droppable>
      );
    } else {
      return <S.CellContent className="studio-program-cell__content">{content}</S.CellContent>;
    }
  };

  openAssignProgramModal = () => {
    const { weekIndex, dayIndex } = this.state;
    const { studioProgramId, totalWeek } = this.props;
    this.props.toggleModal(
      true,
      <SelectProgramForStudioProgramModal
        dayIndex={dayIndex}
        date={dayIndex}
        weekIndex={weekIndex}
        totalDays={totalWeek * 7}
        programId={studioProgramId}
        from="studio_program_calendar"
        startingDayControlled
        showEndingOption={true}
      />,
    );
  };

  render() {
    const {
      cellId,
      copying,
      draggingWorkout,
      disabled,
      isCopySelectedWorkout,
      selectedWorkout,
      calendarViewMode,
      startWeek,
      totalWeek,
    } = this.props;

    if (!cellId) {
      return <S.StudioProgramCalendarCellWrapper />;
    }

    const { isShowAllWorkouts, weekIndex, dayIndex } = this.state;

    const endWeek = Math.min(startWeek + calendarViewMode, totalWeek);
    const needOverlapWeek = (endWeek - startWeek) % calendarViewMode === 0 && endWeek;
    const hasOverlap = !_.isEmpty(selectedWorkout) && calendarViewMode && weekIndex + 1 === needOverlapWeek;

    return (
      <Droppable droppableId={`wokout_${cellId}`} type={DRAGGALE_TYPE.WORKOUT} isDropDisabled={!!disabled}>
        {provided => (
          <S.StudioProgramCalendarCellWrapper
            data-cell-id={cellId}
            ref={ref => {
              provided.innerRef(ref);
              if (ref && ref !== this.wrapperRef) {
                this.wrapperRef = ref;
                this.resizeObserver.disconnect();
                this.resizeObserver.observe(this.wrapperRef);
              }
            }}
            className={classnames('studio-program-cell', {
              'studio-program-cell--view-all-workouts': isShowAllWorkouts,
              copying: copying && !draggingWorkout,
              'is-copy-selected-workout': isCopySelectedWorkout && !draggingWorkout,
              'has-overlap': hasOverlap,
            })}
          >
            <S.StudioProgramCalendarCellContainer className="studio-program-calendar__cell-container">
              <S.CellHeader className="studio-program-cell__header">
                <div className="day">Day {Number(weekIndex) * 7 + Number(dayIndex) + 1}</div>
                {!disabled ? (
                  <AssignMenu onAssignWorkout={this.openAssignModal} onAssignProgram={this.openAssignProgramModal} />
                ) : null}
                {isShowAllWorkouts ? <CloseIcon onClick={this.hideViewAllMode} /> : null}
              </S.CellHeader>
              {this.renderCellContent()}
            </S.StudioProgramCalendarCellContainer>
            {provided.placeholder}
          </S.StudioProgramCalendarCellWrapper>
        )}
      </Droppable>
    );
  }
}

export default StudioProgramCalendarCell;
