// Libs
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Store
import { toggleModal } from 'actions/modal';
import { changeQueryParams, setHeaderTabs, resetDetailMealPlan } from 'redux/meal-plans/actions';
import { hideMealPlanBanner } from 'redux/general-settings/actions';

// Constants
import { MEAL_PLAN_HEADER_TABS } from 'components/MealPlans/constants';
import { SORT_BY } from 'components/MealPlanDayDetail/constants';
import { MEAL_PLAN_STATUS } from 'components/MealPlanDetail/constants';

// Components
import ListSorter from 'components/Recipes/Parts/ContentList/listSorter';
import { NewSearchInput } from 'shared/SearchInput';
import MealPlanModal from '../MealPlanModal';
import MealPlanBanner from '../Banner';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close_icon_cicre.svg';

import * as S from './style';

const HeaderMealPlan = props => {
  const {
    user,
    toggleModal,
    changeQueryParams,
    hideMealPlanBanner,
    isHideBanner,
    isLoading,
    filters,
    totalAll = 0,
    totalMyPlans = 0,
    totalDraft = 0,
    tab,
    setHeaderTabs,
    loadingList,
    resetDetailMealPlan,
    isShowDraft,
    setIsShowDraft,
  } = props;
  const loadingFirstPage = get(filters, 'page') === 1 && loadingList;
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY.MOST_RECENT);

  const handleOpenAddMealPlan = () => {
    resetDetailMealPlan && resetDetailMealPlan();
    toggleModal(true, <MealPlanModal user={user} toggleModal={toggleModal} />);
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams && changeQueryParams({ text_search: value, only_my_meal_plans: false, page: 1 });
  };

  const handleClearSearch = () => {
    handleSearch(null, { value: '' });
  };

  const handleSelectSort = value => {
    if (sortBy === value) {
      setShowSortPopup(false);
      return;
    }

    const sort = SORT_BY.MOST_RECENT === value ? -1 : 1;

    setSortBy(value);
    setShowSortPopup(false);

    typeof changeQueryParams === 'function' && changeQueryParams({ sorter: value, sort: sort, page: 1 });
  };

  const handleSelectShowByShare = value => {
    if (tab === value && !isShowDraft) return;

    setIsShowDraft(false);

    typeof setHeaderTabs === 'function' && setHeaderTabs(value);

    typeof changeQueryParams === 'function' &&
      changeQueryParams({
        only_my_meal_plans: !(MEAL_PLAN_HEADER_TABS.ALL_PLAN === value),
        page: 1,
        status: JSON.stringify([MEAL_PLAN_STATUS.PUBLISH]),
      });
  };

  const handleShowDraft = status => {
    if (isShowDraft) return;

    setIsShowDraft(!status);

    typeof changeQueryParams === 'function' &&
      changeQueryParams({
        status: JSON.stringify([status ? MEAL_PLAN_STATUS.PUBLISH : MEAL_PLAN_STATUS.DRAFT]),
        only_my_meal_plans: status ? tab !== MEAL_PLAN_HEADER_TABS.ALL_PLAN : status,
        page: 1,
      });
  };

  return (
    <S.MealPlanHeaderContainer>
      <S.MealPlanHeader>
        {!isHideBanner ? (
          <MealPlanBanner
            onSearch={handleSearch}
            onClearSearch={handleClearSearch}
            onHideMealPlanBanner={hideMealPlanBanner}
            onOpenAddMealPlan={handleOpenAddMealPlan}
            isLoading={isLoading}
          />
        ) : (
          <>
            <S.MealPlanFilterWrapper>
              <NewSearchInput
                placeholder="Search by keywords or name"
                className="search-input-meal-plan"
                onChange={debounce(handleSearch, 300)}
                onClearSearch={handleClearSearch}
              />
            </S.MealPlanFilterWrapper>
            <S.NewMealPlanButton onClick={handleOpenAddMealPlan} purple>
              <PlusIcon className="plus-icon" />
              <span className="label">Create Meal Plan</span>
            </S.NewMealPlanButton>
          </>
        )}
      </S.MealPlanHeader>
      <S.HeaderList>
        <S.MealPlansShowByOwner>
          <S.AllPlans
            active={tab === MEAL_PLAN_HEADER_TABS.ALL_PLAN && !isShowDraft}
            onClick={() => handleSelectShowByShare(MEAL_PLAN_HEADER_TABS.ALL_PLAN)}
          >
            All Published Plans
          </S.AllPlans>
          <S.YourPlans
            active={tab === MEAL_PLAN_HEADER_TABS.YOUR_PLAN && !isShowDraft}
            onClick={() => handleSelectShowByShare(MEAL_PLAN_HEADER_TABS.YOUR_PLAN)}
          >
            Your Published Plans ({totalMyPlans})
          </S.YourPlans>
        </S.MealPlansShowByOwner>
        <S.ActionsRight>
          <S.ButtonShowDraft isShowDraft={isShowDraft} onClick={() => handleShowDraft(isShowDraft)}>
            <S.ButtonContent>Drafts ({totalDraft})</S.ButtonContent>
          </S.ButtonShowDraft>
          <ListSorter
            sortBy={sortBy}
            showSortPopup={showSortPopup}
            setShowSortPopup={setShowSortPopup}
            handleSelectSort={handleSelectSort}
          />
        </S.ActionsRight>
      </S.HeaderList>
    </S.MealPlanHeaderContainer>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { generalSettings, mealPlans },
  } = state;

  return {
    user,
    isHideBanner: get(generalSettings, 'is_hide_meal_plan_banner'),
    isLoading: get(generalSettings, 'loading'),
    filters: get(mealPlans, 'filters', null),
    totalAll: get(mealPlans, 'totalAll', 0),
    totalMyPlans: get(mealPlans, 'totalMyPlans', 0),
    totalDraft: get(mealPlans, 'totalDraft', 0),
    total: get(mealPlans, 'total', 0),
    tab: get(mealPlans, 'tab'),
    loadingList: get(mealPlans, 'loading'),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  hideMealPlanBanner: bindActionCreators(hideMealPlanBanner, dispatch),
  setHeaderTabs: bindActionCreators(setHeaderTabs, dispatch),
  resetDetailMealPlan: bindActionCreators(resetDetailMealPlan, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMealPlan);
