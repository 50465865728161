import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #dadfea;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const CancelButton = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #76778a;
  cursor: pointer;
  :hover {
    color: #5559ff;
  }
`;

export const TooltipTitle = styled.h6`
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  margin: 0 0 10px;
`;

export const TooltipDesc = styled.p`
  font-size: 13px;
  line-height: 150%;
`;

export const WrapperContainer = styled.div`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  max-width: 308px;
  display: grid;
  .app-tooltip-asset {
    padding: 15px;
    max-width: 234px;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  ${props =>
    !props.shouldRender &&
    css`
      display: none !important;
    `}
`;

export const IconWrapper = styled.div`
  border-radius: 5px;
  width: 52px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  ${props =>
    !props.noIconStyle &&
    css`
      svg path,
      svg rect {
        stroke: #fff;
      }
    `}
  ${props =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
`;

export const TitleItem = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #202353;
  text-align: center;
  margin-top: 8px;
`;

export const FirstAddAssetTitle = styled.h6`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  margin: 0;
`;

export const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin-top: 10px;
  .add-asset-card {
    background-color: #ffffff;
  }
`;
