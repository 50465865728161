import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import pick from 'lodash/pick';
import flattenDeep from 'lodash/flattenDeep';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import isArray from 'lodash/isArray';

import * as S from './style';
import AttachmentItem from 'components/Inbox/AttachmentItem';
import SliderMediaModal from 'shared/SliderMediaModal';
import AttachmentsUploadInBackground from 'shared/AttachmentsUploadInBackground';
import { getAttachmentUrl, getAttachmentId } from 'components/Inbox/helps';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const MultipleMediaMessage = ({
  cloudfrontList,
  message,
  showRecord,
  removeLocalMessage,
  currentRoomId,
  sendMessage,
  scrollToBottom,
  getS3presignedURLFromLocalDatabase,
  cancelUploadRequest,
  voiceMessage = false,
}) => {
  const ignoreRef = useRef(false);
  const [progress, setProgress] = useState({});

  const attachments = useMemo(() => {
    const newAttachments = get(message, 'attachments', []);
    if (isEmpty(newAttachments)) {
      return [
        {
          ...pick(message, [
            'attachment',
            'attachment_id',
            'attachment_type',
            'attachment_thumbnail',
            'attachment_width',
            'attachment_height',
            'attachment_source',
            'attachment_duration',
          ]),
        },
      ];
    }
    return newAttachments;
  }, [message]);

  const isLocalFile = useMemo(() => get(message, 'isLocalFile'), [message]);

  const uploadConfigs = useMemo(() => {
    const newUploadConfigs = get(message, 'uploadConfigs');
    if (!isArray(newUploadConfigs) && !isEmpty(newUploadConfigs)) {
      return [newUploadConfigs];
    }
    return newUploadConfigs;
  }, [message]);
  const localKey = useMemo(() => get(message, 'localKey'), [message]);
  const length = useMemo(() => size(attachments), [attachments]);
  const [isOpen, setIsOpen] = useState({});
  const [images, setImages] = useState([]);

  if (isEmpty(attachments) || (isLocalFile && isEmpty(uploadConfigs))) return <></>;

  useEffect(() => {
    let ignore = false;
    if (!isLocalFile && isEmpty(images) && !isEmpty(attachments)) {
      getPresignedURLsDebounce({
        list: flattenDeep(map(attachments, it => [get(it, 'attachment'), get(it, 'attachment_thumbnail')])),
        ignore,
        cloudfrontList,
      });
    }
    return () => {
      ignore = true;
      ignoreRef.current = true;
      if (!isLocalFile && isEmpty(images) && !isEmpty(attachments)) {
        getPresignedURLsDebounce.cancel();
      }
    };
  }, [attachments, isLocalFile, images]);

  const getPresignedURLs = useCallback(async ({ list, ignore, cloudfrontList }) => {
    if (isEmpty(list) || ignore) return;

    const cloudUrls = [];
    const presignedUrls = [];

    list.forEach(item => {
      const cloudItem = convertS3UrlToCloudFrontUrl(item, cloudfrontList);
      if (cloudItem) {
        cloudUrls.push(cloudItem);
      } else {
        presignedUrls.push(item);
      }
    });

    if (presignedUrls.length > 0) {
      const response = await getS3presignedURLFromLocalDatabase(presignedUrls);
      cloudUrls.push(...response);
    }

    setImages(cloudUrls);
  }, []);

  const getPresignedURLsDebounce = debounce(getPresignedURLs, 2000);

  const sendMessageWithAttachments = useCallback(
    ({ responses, ignore }) => {
      const hasEmptyItem = some(responses, isEmpty);
      if (isEmpty(responses) || hasEmptyItem) {
        if (!ignore) {
          removeLocalMessage(currentRoomId, localKey);
        }
        return;
      }
      const presignedUrlAttachments = map(attachments, (it, idx) => {
        const url = get(responses, `[${idx}].config.url`);
        const attachment = getAttachmentUrl(url);
        const attachment_id = getAttachmentId(url);
        return {
          ...pick(it, ['attachment_type', 'attachment_width', 'attachment_height', 'attachment_duration']),
          attachment_id,
          attachment,
        };
      });
      const bodyData = {
        ...presignedUrlAttachments[0],
        ...pick(message, ['timestamp', 'sender', 'reply_message_id', 'chat_id', 'room_id']),
        message: get(message, 'content'),
      };
      if (size(responses) > 1) {
        bodyData['attachments'] = presignedUrlAttachments;
      }
      sendMessage(bodyData);
      if (!ignore) {
        scrollToBottom(1000);
        removeLocalMessage(currentRoomId, localKey);
      }
    },
    [attachments, currentRoomId, localKey],
  );

  const afterCancelUpload = useCallback(() => {}, []);

  const updateProcess = useCallback(newProgress => !ignoreRef.current && setProgress(newProgress), [ignoreRef]);

  const forceCancel = useCallback(() => {
    typeof cancelUploadRequest === 'function' && cancelUploadRequest({ id: localKey });
  }, [localKey]);

  return (
    <S.MultipleMediaBox length={length} showRecord={showRecord}>
      {!voiceMessage &&
        map(attachments, (it, idx) => (
          <AttachmentItem
            message={it}
            thumbnail={images[idx * 2 + 1]}
            key={`attachment_${get(it, 'key')}_${idx}`}
            isLocalFile={isLocalFile}
            index={idx}
            length={length}
            progress={get(progress, idx, 0)}
            showRecord={showRecord}
            onClick={() => !showRecord && setIsOpen({ ...it, index: idx })}
            cancelable={length === 1}
            cancelUpload={forceCancel}
          />
        ))}
      {!isLocalFile && !isEmpty(attachments) && !isEmpty(images) && !isEmpty(isOpen) && (
        <SliderMediaModal isOpen={isOpen} setIsOpen={setIsOpen} attachments={attachments} images={images} />
      )}
      {isLocalFile && (
        <AttachmentsUploadInBackground
          disabled={false}
          callbackProcess={updateProcess}
          callbackSuccess={sendMessageWithAttachments}
          callbackCancel={afterCancelUpload}
          uploadConfigs={uploadConfigs}
          attachments={attachments}
          id={localKey}
        />
      )}
    </S.MultipleMediaBox>
  );
};

export default memo(MultipleMediaMessage);
