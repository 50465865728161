// Libs
import React, { useRef, useState } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import classNames from 'classnames';

// Parts
import { CONTENT_TYPING_TYPES } from 'shared/TriggerForms/constants/types';

// Styles
import * as S from './styles';

const TypingModal = props => {
  const { type, closeModal, submitAction, isCloseAfterSubmit = false } = props;

  const inputRef = useRef({ value: '' });

  const [isDisabled, setIsDisabled] = useState(true);

  if (!Object.keys(CONTENT_TYPING_TYPES).includes(type)) return null;

  const {
    _id,
    icon,
    title,
    description,
    guideDescription,
    placeholderInput,
    confirmKey,
    cancelLabelButton,
    submitLabelButton,
    submitButtonTheme,
  } = CONTENT_TYPING_TYPES[type];

  const handleCloseModal = () => {
    typeof closeModal === 'function' && closeModal();
  };

  const handleSubmitModal = () => {
    typeof submitAction === 'function' && submitAction();
    isCloseAfterSubmit && handleCloseModal();
  };

  const onInputChange = () => {
    const { value } = inputRef.current || {};

    if (value.toLocaleLowerCase().trim() === confirmKey.toLocaleLowerCase().trim()) {
      setIsDisabled(false);
    } else if (value.toLocaleLowerCase().trim() !== confirmKey.toLocaleLowerCase().trim() && !isDisabled) {
      setIsDisabled(true);
    }
  };

  return (
    <S.ConfirmTypingModalWrapper
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src="/images/close_circle.svg" />
        </Button>
      }
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {icon && <img src={icon} alt="" className="icon-header" />}
        <div className="title-modal">{title}</div>
      </Modal.Header>
      <Modal.Description>
        <div
          className="description-modal"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Modal.Description>
      <Modal.Content>
        <label htmlFor={_id} className="guide-description-modal">
          {guideDescription}
        </label>
        <input
          ref={inputRef}
          type="text"
          name={_id}
          id={_id}
          placeholder={placeholderInput}
          className="input-modal"
          onChange={onInputChange}
          autoFocus
        />
      </Modal.Content>
      <Modal.Actions>
        <button type="reset" className="cancel-button" onClick={handleCloseModal}>
          {cancelLabelButton}
        </button>
        <button
          type="submit"
          className={classNames('submit-button', {
            [submitButtonTheme]: !isDisabled,
          })}
          disabled={isDisabled}
          onClick={handleSubmitModal}
        >
          {submitLabelButton}
        </button>
      </Modal.Actions>
    </S.ConfirmTypingModalWrapper>
  );
};

export default TypingModal;
