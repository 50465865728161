import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

export default props => {
  const { data, cloudfrontList } = props;

  if (!data) {
    return null;
  }

  const otherProps = _.omit(props, ['data', 'size', 'tooltipId', 'tooltipProps']);
  const { tooltipId, size, tooltipProps } = props;

  return (
    <Container
      {...otherProps}
      data-tip
      data-for={tooltipId}
      size={size || 20}
      className={classNames('owner', props.className)}
    >
      <Avatar
        src={convertS3UrlToCloudFrontUrl(data.avatar, cloudfrontList, true)}
        color={(data || {}).color}
        name={getUserShortName(data)}
        size={size || 20}
      />
      <ReactTooltip className="app-tooltip" effect="solid" place="top" {...tooltipProps} id={tooltipId}>
        <div className="title">Owner</div>
        <div>
          {data.first_name} {data.last_name}
        </div>
      </ReactTooltip>
    </Container>
  );
};

const Container = styled.div`
  width: ${props => `${props.size || '20'}px`};
  flex: ${props => `0 0 ${props.size || '20'}px`};
  opacity: 0.4;

  :hover {
    opacity: 1;
    cursor: pointer;
  }

  .app-tooltip {
    padding: 15px !important;
    background: #231f20 !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 120% !important;
    color: #ffffff;
    text-align: left !important;

    .title {
      font-weight: normal !important;
      color: #ffffff !important;
    }
  }

  .sb-avatar__image,
  .sb-avatar__text {
    pointer-events: none;
  }
`;
