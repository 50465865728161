import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { diff } from 'deep-diff';
import { Prompt } from 'react-router';

import {
  PRODUCT_STATUS,
  PRODUCT_ACCESS_DURATION_UNIT,
  ACTIVATE_TYPES,
  LIMIT_ACCESS,
  ASSET_TYPE,
  AUTOFLOW_STATUS,
} from '../constants';
import BasicInfo from './BasicInfo';
import Asset from './Asset';
import Header from './Header';

import * as S from './style';
import Activate from './Activate';
import LimitAccess from './LimitAccess';
import InfoTooltipIcon from 'shared/InfoTooltipIcon';
import { Toggle } from 'shared/FormControl';
import { PACKAGE_STATUS, WORKOUT_COLLECTION_STATUS } from 'constants/commonData';

import WarningViolet from 'assets/icons/warning_violet.svg';
import TrashIcon from 'assets/icons/red_trash_circle.svg';
import ArchiveLight from 'assets/icons/archive_light.svg';
import Play from 'assets/icons/play_small.svg';
import Info from 'assets/icons/warning_red_light.svg';
import { hasBeenPurchased } from 'utils/commonFunction';
import { connect } from 'react-redux';

const getActivateTitle = value => {
  return ACTIVATE_TYPES.find(it => it.value === value).overviewLabel;
};

const getLimitTitle = (durationValue, durationUnit) => {
  if (durationValue && durationUnit && durationUnit !== 1) {
    const type = _.find(PRODUCT_ACCESS_DURATION_UNIT, item => item.value === durationUnit);
    if (type.key) {
      const limitLabel = LIMIT_ACCESS.find(it => it.type === type.key && it.value === `${durationValue}${type.key}`);
      return limitLabel ? limitLabel.label : 'forever';
    }
  }
  return 'forever';
};

const checkInactiveOrDeletedAsset = data => {
  const deletedAssets = data.assets.filter(asset => asset.is_deleted);
  const inactiveAssets = data.assets.filter(asset => {
    switch (asset.asset_type) {
      case ASSET_TYPE.AUTOFLOW:
        return asset.asset_data.status !== AUTOFLOW_STATUS.AUTOFLOW_STATUS_ACTIVATED;

      case ASSET_TYPE.STUDIO:
        return asset.asset_data.isPublished === false;

      case ASSET_TYPE.WORKOUT_COLLECTION:
        return asset.asset_data.status === WORKOUT_COLLECTION_STATUS.DRAFT;

      default:
        return false;
    }
  });
  const inactiveOrDeleteAssets = _.union(deletedAssets, inactiveAssets);
  const numOfInactiveOrDeleteAssets = inactiveOrDeleteAssets.length;
  return data.assets.length === numOfInactiveOrDeleteAssets;
};

const ProductDetail = props => {
  const {
    productDetail,
    saveProductDraft,
    user,
    getPreSignedUrl,
    toggleConfirmModal,
    updateProduct,
    publishProduct,
    isSubmitting,
    archiveProduct,
    deleteProduct,
    unArchiveProduct,
    duplicateProduct,
    duplicating,
    push,
    isEditingDuplicate,
    startUploadCoverImage,
    endUploadCoverImage,
    resetDuplicate,
    getListProduct,
    updateQuery,
    cloudfrontList,
  } = props;

  let [data, setData] = useState(productDetail);
  const [active, setActive] = useState(1);
  const [status, setStatus] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isDuplicatePublish, setIsDuplicatePublish] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const isChanged = useMemo(() => {
    return (isEdit && !isEditingDuplicate && diff(productDetail, data)) || data.status === PACKAGE_STATUS.NEW;
  }, [data]);

  useEffect(() => {
    return () => {
      resetDuplicate();
      updateQuery &&
        updateQuery({
          page: 1,
          q: '',
          status: '',
          isEnd: false,
        });
      getListProduct && getListProduct();
    };
  }, []);

  // Event action when props have changed
  useEffect(() => {
    setData(productDetail);
    setStatus(productDetail.status);
    setIsEdit(productDetail.is_edit_mode || isEditingDuplicate);
    setActive(productDetail.activate_product_on);
  }, [productDetail]);

  const handleClose = () => {
    if (isChanged) {
      if (data.status === PRODUCT_STATUS.PUBLISH) {
        toggleConfirmModal(
          true,
          <S.ExitConfirm
            className="exit-confirm publish-confirm"
            noBorder
            headerIcon={Info}
            hasCloseIcon
            hasHoverState
            title="Cancel Changes"
            content="Your changes will not be saved. Would you like to stop editing the sequence without saving?"
            onConfirm={handleCancelChange}
            onDeny={handleClosePopup}
            cancelButtonTitle="Continue editing"
            confirmButtonTitle="Exit without saving"
          />,
        );
      } else {
        toggleConfirmModal(
          true,
          <S.ExitConfirm
            className="exit-confirm custom"
            noBorder
            headerIcon={WarningViolet}
            hasCloseIcon
            hasHoverState
            title="Save before exiting?"
            content="You have made changes that have not been saved yet. Would you like save changes before leaving?"
            onConfirm={() => {
              data.id ? handleUpdateProduct() : handleSaveDraft();
            }}
            onDeny={handleCancelChange}
            cancelButtonTitle="Exit without saving"
            confirmButtonTitle="Save"
          />,
        );
      }
    } else {
      handleEndEdit();
    }
  };

  const handleCancelChange = () => {
    setData(productDetail);
    handleEndEdit();
  };

  const handleCancelDiscardChange = nextLocation => {
    // TODO
    if (status === PACKAGE_STATUS.NEW) {
      const param = {
        ...data,
        status: null,
      };
      setData(param);
      handleEndEdit();
    } else handleCancelChange();

    setTimeout(() => {
      if (nextLocation) push(nextLocation.pathname);
    }, 50);
  };

  // Save create new
  const handleSaveDraft = async nextLocation => {
    try {
      setIsLoadingUpdate(true);
      let params = {
        ...data,
        trainer: user._id,
      };

      let callBackFunc = null;

      // Check field null
      if (handleCheckFieldNull(data)) return;

      data.id ? (callBackFunc = updateProduct(data)) : (callBackFunc = saveProductDraft(params));

      // TODO
      params = { ...params, status: !!data.id && PACKAGE_STATUS.DRAFT };
      setData(params);
      handleEndEdit();

      params.hash_id && push(`/home/sequences/${params.hash_id}`);

      if (callBackFunc)
        await callBackFunc.finally(() => {
          if (nextLocation) push(nextLocation.pathname);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const handleClosePopup = () => {
    toggleConfirmModal(false);
  };

  // Handle Publish
  const handlePublish = () => {
    if (!!data.assets.length && !checkInactiveOrDeletedAsset(data)) {
      publishProduct && publishProduct(data);
      toggleConfirmModal(
        true,
        <S.ExitConfirm
          className="exit-confirm custom"
          noBorder
          headerIcon={Play}
          hasCloseIcon
          hasHoverState
          title="Your sequence is published!"
          content={
            <>
              <S.CustomPopupMessage>Now you can add the automated sequence to any package.</S.CustomPopupMessage>
              <S.CustomPopupMessage>
                After a sequence has been purchased, you won’t be able to make any changes to the sequence, but you can
                create a duplicate.
              </S.CustomPopupMessage>
            </>
          }
          onConfirm={handleClosePopup}
          hideCancelButton={true}
          confirmButtonTitle="Okay"
        />,
      );
    } else {
      toggleConfirmModal(
        true,
        <S.ExitConfirm
          className="exit-confirm custom"
          noBorder
          headerIcon={WarningViolet}
          hasCloseIcon
          hasHoverState
          title={`Can not publish ${data.name}`}
          content="The Sequence requires at least 1 asset before it can be published and used with a package."
          onConfirm={handleClosePopup}
          hideCancelButton={true}
          confirmButtonTitle="Okay"
        />,
      );
    }
  };

  // Start edit
  const handleStartEdit = () => {
    setIsEdit(true);
  };

  const handleEndEdit = () => {
    setIsEdit(false);
  };

  const handleDuplicate = () => {
    duplicateProduct && duplicateProduct(productDetail);
    setIsDuplicatePublish(true);
  };

  const handleActiveChange = res => {
    setData({
      ...data,
      activate_product_on: res.value,
    });
  };

  const handleUploadImageChange = res => {
    setData({
      ...data,
      cover_image: res,
    });
  };

  const handleLimitAccessChange = res => {
    const field = _.find(PRODUCT_ACCESS_DURATION_UNIT, item => item.key === res.type);

    setData({
      ...data,
      access_duration_unit: field.value,
      access_duration_value: parseInt(res.value.replace(/[^0-9]/g, '')),
    });
  };

  const handleChangeAsset = assets => {
    setData({
      ...data,
      assets,
    });
  };

  const handleChangeDesc = value => {
    setData({
      ...data,
      description: value,
    });
  };

  const handleChangeTitle = value => {
    setData({
      ...data,
      name: value,
    });
  };

  // Update a product for all status
  const handleUpdateProduct = async nextLocation => {
    if (isLoadingUpdate) return;
    try {
      setIsLoadingUpdate(true);
      if (await hasBeenPurchased(data.id)) {
        toggleConfirmModal(
          true,
          <S.ExitConfirm
            className="exit-confirm custom"
            noBorder
            headerIcon={WarningViolet}
            hasCloseIcon
            hasHoverState
            title="Sequence has been purchased"
            content="This sequence has been purchased in a package and can not be changed. We will save your sequence as a duplicate."
            onConfirm={() => {
              handleDuplicate();
            }}
            onDeny={handleCancelChange}
            hideCancelButton={true}
            confirmButtonTitle="Save as Duplicate"
          />,
        );
      } else {
        // Check field null
        if (handleCheckFieldNull(data)) return;
        if (
          (data.status === PRODUCT_STATUS.PUBLISH && !data.assets.length) ||
          (data.status === PRODUCT_STATUS.PUBLISH && checkInactiveOrDeletedAsset(data))
        ) {
          toggleConfirmModal(
            true,
            <S.ExitConfirm
              className="exit-confirm custom"
              noBorder
              headerIcon={WarningViolet}
              hasCloseIcon
              hasHoverState
              title="Sequence is empty"
              content="The Sequence requires at least 1 asset before it can be published and used with a package."
              onConfirm={handleClosePopup}
              hideCancelButton={true}
              confirmButtonTitle="Okay"
            />,
          );
        } else {
          typeof updateProduct === 'function' &&
            (await updateProduct({
              ...data,
              is_edit_mode: false,
            }).finally(() => {
              handleEndEdit();

              // TODO - Discard change move to another
              if (nextLocation.pathname) push(nextLocation.pathname);
            }));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const handleDiscardChange = nextLocation => {
    if (isChanged && !isDuplicatePublish) {
      toggleConfirmModal(
        true,
        <S.ExitConfirm
          className="exit-confirm custom"
          noBorder
          headerIcon={WarningViolet}
          hasCloseIcon
          hasHoverState
          title="Save before exiting?"
          content="You have made changes that have not been saved yet. Would you like save changes before leaving?"
          onConfirm={() => {
            data.id ? handleUpdateProduct(nextLocation) : handleSaveDraft(nextLocation);
          }}
          onDeny={() => handleCancelDiscardChange(nextLocation)}
          cancelButtonTitle="Exit without saving"
          confirmButtonTitle="Save"
        />,
      );

      return false;
    }

    return true;
  };

  if (!productDetail) {
    return null;
  }

  const handleDeleteProduct = () => {
    toggleConfirmModal(
      true,
      <S.ExitConfirm
        className="exit-confirm delete"
        noBorder
        headerIcon={TrashIcon}
        hasCloseIcon
        hasHoverState
        title="Delete Sequence"
        content="This sequence will be permanently deleted from your account. Would you like to continue?"
        onConfirm={() => {
          deleteProduct && deleteProduct(data.id);
          push('/home/sequences');
        }}
        onDeny={handleClosePopup}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete"
      />,
    );
  };

  const handleUnArchiveProduct = () => {
    unArchiveProduct && unArchiveProduct(data);
  };

  const handleArchiveProduct = () => {
    toggleConfirmModal(
      true,
      <S.ExitConfirm
        className="exit-confirm custom"
        noBorder
        headerIcon={ArchiveLight}
        hasCloseIcon
        hasHoverState
        title={`Archive ${data.name}?`}
        content={
          <>
            <S.CustomPopupMessage>
              Once the sequence is archived, it will not remain active in any package it is in. Clients purchasing the
              packages will not receive the sequence or its assets.
            </S.CustomPopupMessage>
            <S.CustomPopupMessage>
              Clients who have purchased the sequence already will not be affected.
            </S.CustomPopupMessage>
            <S.CustomPopupMessage>Would you like to archive the sequence?</S.CustomPopupMessage>
          </>
        }
        onConfirm={() => {
          archiveProduct && archiveProduct(data);
        }}
        onDeny={handleClosePopup}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Archive sequence"
      />,
    );
  };

  const handleCheckFieldNull = data => {
    // TODO
    if (data.name.length === 0) return true;
    return;
  };

  const defaultValue = useMemo(() => {
    if (data.access_duration_unit === 1) {
      return 'forever';
    } else {
      const type = _.find(PRODUCT_ACCESS_DURATION_UNIT, item => item.value === data.access_duration_unit);
      return `${data.access_duration_value}${type.key}`;
    }
  }, [data]);

  const renderArchiveClient = () => {
    const handleChangeStatus = () => {
      setData({ ...data, is_archive_client: !_.get(data, 'is_archive_client', false) });
    };

    return (
      <S.ArchiveClientArea>
        <S.ArchiveClientLabel>Archive client when access expires</S.ArchiveClientLabel>
        <Toggle
          className="unit-client-toggle"
          checked={_.get(data, 'is_archive_client', false)}
          onChange={handleChangeStatus}
        />
      </S.ArchiveClientArea>
    );
  };

  const isEnableArchiveClientSetting = process.env.REACT_APP_ENABLE_ARCHIVE_CLIENT_SETTING;

  return (
    <S.Wrapper>
      <Prompt when={isChanged} message={handleDiscardChange} />
      <Header
        data={data}
        isEdit={isEdit}
        isDisable={isDisable}
        status={duplicating ? PRODUCT_STATUS.NEW : status}
        onSetStatus={setStatus}
        onStartEditMode={handleStartEdit}
        onEnđEditMode={handleEndEdit}
        onSaveDraft={handleSaveDraft}
        onUpdate={handleUpdateProduct}
        onCancel={handleClose}
        isDuplicate={duplicating}
        isEditingDuplicate={isEditingDuplicate}
        onPublish={handlePublish}
        archiveProduct={archiveProduct}
        deleteProduct={deleteProduct}
        unArchiveProduct={unArchiveProduct}
        onDuplicate={handleDuplicate}
        onDelete={handleDeleteProduct}
        onUnarchive={handleUnArchiveProduct}
        onArchive={handleArchiveProduct}
        isLoadingUpdate={isLoadingUpdate}
        {...props}
      />

      <S.DetailContainer>
        <S.DetailWrapper>
          <BasicInfo
            isSubmitting={isSubmitting}
            isEdit={isEdit}
            data={data}
            cloudfrontList={cloudfrontList}
            getPreSignedUrl={getPreSignedUrl}
            onUploadImageChange={handleUploadImageChange}
            onChangeDesc={handleChangeDesc}
            onChangeTitle={handleChangeTitle}
            startUploadCoverImage={startUploadCoverImage}
            endUploadCoverImage={endUploadCoverImage}
          />
          <S.DropdownWrapper isEdit={isEdit}>
            {isEdit ? (
              <>
                <S.DropdownHeader>
                  <S.DropdownTitle>Initiate sequence on</S.DropdownTitle>
                  <InfoTooltipIcon
                    tooltipId="activate-product"
                    size="16px"
                    maxWidthContent="228px"
                    className="product-tooltip"
                    message="Choose if the assets should assign when a client activates their purchase, or assign on the Monday following activation."
                  />
                </S.DropdownHeader>
                <Activate onSelect={handleActiveChange} defaultValue={active} />
              </>
            ) : (
              <S.DropdownReadOnly>
                Initiate sequence on <S.TextBold>{getActivateTitle(data.activate_product_on)}</S.TextBold>
              </S.DropdownReadOnly>
            )}
          </S.DropdownWrapper>
          <S.DropdownWrapper isEdit={isEdit}>
            {isEdit ? (
              <>
                <S.DropdownHeader>
                  <S.DropdownTitle>How long to provide access</S.DropdownTitle>
                  <InfoTooltipIcon
                    tooltipId="how-long-access-product"
                    size="16px"
                    maxWidthContent="228px"
                    className="product-tooltip"
                    message="After this duration, assets will be removed or unassigned from the client’s account."
                  />
                </S.DropdownHeader>
                <LimitAccess onSelect={handleLimitAccessChange} defaultValue={defaultValue} />
                {/* Render Archive Client - Edit */}
                {isEnableArchiveClientSetting && renderArchiveClient()}
              </>
            ) : (
              <S.DropdownReadOnly>
                Provide access for{' '}
                <S.TextBold>{getLimitTitle(data.access_duration_value, data.access_duration_unit)}</S.TextBold>
              </S.DropdownReadOnly>
            )}
          </S.DropdownWrapper>

          {/* Render Archive Client - View */}
          {isEnableArchiveClientSetting && _.get(data, 'is_archive_client', false) && !isEdit && (
            <S.DropdownWrapper isEdit={isEdit}>
              <S.DropdownReadOnly>
                <S.TextBold>Archive client</S.TextBold> when access expires
              </S.DropdownReadOnly>
            </S.DropdownWrapper>
          )}
        </S.DetailWrapper>
        <S.AssetWrapper>
          <Asset
            user={user}
            isSubmitting={isSubmitting}
            toggleConfirmModal={toggleConfirmModal}
            onChangeAsset={handleChangeAsset}
            data={data}
            isEdit={isEdit}
            cloudfrontList={cloudfrontList}
          />
        </S.AssetWrapper>
      </S.DetailContainer>
    </S.Wrapper>
  );
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(ProductDetail);
