import React from 'react';
import get from 'lodash/get';
import moment_tz from 'moment-timezone';
import moment from 'moment';
import AsyncSelect from 'react-select/lib/Async';
import { escapeRegExp } from 'utils/commonFunction';

const getTimezoneDefault = (timezone) =>{
  return {
    key: timezone,
    label: getTimezoneLabel(timezone),
    value: timezone
  };
}

const searchTimezone = (q, callback) => {
  callback(
    moment_tz.tz
      .names()
      .map(itm => {
        return { 
          key: itm,
          label: getTimezoneLabel(itm),
          value: itm,
          offset: moment_tz.tz(itm)._offset
        };
      })
      .filter(itm => new RegExp(escapeRegExp(q), 'gi').test(itm.label))
      .sort((a, b) => a.offset - b.offset)
  );
}

const getTimezoneLabel = (zone) => {
  return `(GMT ${moment.tz(zone).format('Z')}) ${zone}`;
}

export default function TimezoneSelect(props) {
  return (
    <AsyncSelect
      className="setting newexercise-dropdown"
      classNamePrefix="exl"
      value={{
        key: props.timezone,
        label: getTimezoneLabel(props.timezone),
        value: props.timezone
      }}
      defaultValue={getTimezoneDefault(props.timezone)}
      onChange={props.onChange}
      blurInputOnSelect={true}
      cacheOptions
      loadOptions={searchTimezone}
      defaultOptions
      styles={{
        option: (provided, state) => {
          return {
            ...provided,
            color: state.isSelected ? '#6B6B6B' : '#A0A0A0',
            backgroundColor: state.isSelected ? '#F7F7F7' : state.isFocused ? '#F2F2F2' : '#FFFFFF',
            fontWeight: state.isSelected ? 700 : 400
          }
        }
      }}
      isDisabled={props.isDisabled}
      onMenuOpen={get(props, 'onMenuOpen')}
      menuPlacement={get(props, 'menuPlacement', 'auto')}
      components={get(props, 'components')}
    />
  )
}