// @flow

type State = {
  items: Array<any>,
};

type Action = {
  type: string,
  payload: any,
};

export const DEFAULT_STATE = {
  items: [],
};

const UserReducer = (state: State = DEFAULT_STATE, action: Action) => {
  switch (action.type) {
    case 'CMS_SUCCESS_GET_USER_LIST':
      return {
        items: action.payload.data,
      };
    default:
      return state;
  }
};

export default UserReducer;
