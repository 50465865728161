import React from 'react';

import { Button, Grid } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { getAssignmentDetail, WORKOUT_TYPE } from 'actions/workout/getWorkouts';
import { pluralize, roundUp } from 'utils/commonFunction';
import diff from 'deep-diff';
import './styles.scss';
import MetricOverview from './MetricOverview';

export default class TrainingOverview extends React.Component {
  state = { key: 0 };

  componentDidMount() {
    if (this.props.selectedClient) {
      this.getDashboardInfo();
    }
  }

  componentDidUpdate(prevProps) {
    let poverviewMetrics = prevProps.bodymetricTypes.filter(item => item.overview);
    let overviewMetrics = this.props.bodymetricTypes.filter(item => item.overview);
    if (
      this.props.selectedClient._id !== prevProps.selectedClient._id ||
      poverviewMetrics.length !== overviewMetrics.length ||
      diff(poverviewMetrics[0], overviewMetrics[0]) ||
      diff(poverviewMetrics[1], overviewMetrics[1])
    ) {
      this.getDashboardInfo();
      this.handleRefreshBodyMetric();
    }
  }

  getDashboardInfo() {
    this.props
      .getDashboardInfo({
        client: this.props.selectedClient._id,
        timezone: this.props.selectedClient.timezone,
      })
      .then(result => {
        if (result && result.data) {
          this.setState(p => ({
            info: result.data.data,
          }));
        }
      });
  }

  handleRefreshBodyMetric = () => {
    this.setState({ key: this.state.key + 1 });
  };

  render() {
    let { info } = this.state;
    const { selectedClient } = this.props;

    if (!selectedClient) {
      return false;
    }

    let dayago = null;

    if (info && info.lastTracked && info.lastTracked.end_date) {
      let currentDate = DateTime.local();
      let end_date = DateTime.fromISO(info.lastTracked.end_date);
      let duration = currentDate.diff(end_date, ['years', 'months', 'days', 'hours', 'minutes']);
      dayago = !duration ? 0 : Math.floor(duration.as('days'));
    }

    const lastWeekTracked = _.get(info, 'lastWeek.tracked', 0),
      lastWeekTotal = _.get(info, 'lastWeek.total', 0),
      lastMonthTracked = _.get(info, 'lastMonth.tracked', 0),
      lastMonthTotal = _.get(info, 'lastMonth.total', 0);
    const weekTrackedPercent = lastWeekTotal ? Math.round(roundUp((lastWeekTracked / lastWeekTotal) * 100, 2)) : '-';
    const monthTrackedPercent = lastMonthTotal
      ? Math.round(roundUp((lastMonthTracked / lastMonthTotal) * 100, 2))
      : '-';

    return (
      <div className="training-overview-container">
        <div className="eve-panel training-overview-panel">
          <div
            className="panel-header clickable"
            onClick={() => this.props.dispatch(push(`/home/client/${selectedClient._id}/calendar`))}
          >
            <div className="panel-title">Training</div>
            <a className="actions">Open Calendar</a>
          </div>
          <div className="panel-body training-overview-panel__body">
            <Grid divided columns={3}>
              {
                <Grid.Row>
                  <Grid.Column className="training-overview-period">
                    <div className="period-name">Last 7 days</div>
                    <div className="period-data">{`${lastWeekTracked}/${lastWeekTotal}`}</div>
                    <div className="period__percent">{`${weekTrackedPercent}%`}</div>
                    <div className="period-unit">Tracked</div>
                  </Grid.Column>
                  <Grid.Column className="training-overview-period">
                    <div className="period-name">Last 30 days</div>
                    <div className="period-data">{`${lastMonthTracked}/${lastMonthTotal}`}</div>
                    <div className="period__percent">{`${monthTrackedPercent}%`}</div>
                    <div className="period-unit">Tracked</div>
                  </Grid.Column>
                  <Grid.Column className="training-overview-period">
                    <div className="period-name">Next week</div>
                    <div
                      className={
                        info && info.nextWeek.total ? 'period-data no-hidden' : 'period-data no-hidden not-assigned'
                      }
                    >
                      {info ? info.nextWeek.total : 0}
                    </div>
                    <div className={info && info.nextWeek.total ? 'period-unit' : 'period-unit unit-warning'}>
                      {info && info.nextWeek.total ? 'Assigned' : 'Not assigned yet'}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid>
          </div>
        </div>
        <Grid columns="equal" className="last-workout">
          <Grid.Row>
            <Grid.Column>
              <div className="title">Last Workout:</div>
              {'  '}
              <div className="name">{info && info.lastTracked ? info.lastTracked.title : ''}</div>{' '}
              {info && info.lastTracked ? (
                <div className="time">
                  &bull; {dayago && dayago > 0 ? dayago + ` ${pluralize('day', dayago)} ago` : 'Today'}{' '}
                </div>
              ) : (
                <div>No workouts logged yet</div>
              )}
            </Grid.Column>
            <Grid.Column textAlign="right" style={{ flex: ' 0 0 auto', width: 'auto' }}>
              {info && info.lastTracked ? (
                <Button
                  onClick={() => {
                    this.props.dispatch(getAssignmentDetail(info.lastTracked._id, 'history', WORKOUT_TYPE.HISTORY));
                  }}
                >
                  Check Result
                </Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <MetricOverview />
      </div>
    );
  }
}
