import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal } from 'semantic-ui-react';
import { isNumber, get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { toggleModal } from 'actions/modal';
import { CALENDAR_LIMIT_WEEKS, CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { FormGroup, Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { addAutoflow } from 'redux/autoflow/actions';
// import icons
import { ReactComponent as AutoflowIntervalIcon } from 'assets/icons/autoflow-interval-icon.svg';
import { ReactComponent as AutoflowIntervalSelectedIcon } from 'assets/icons/autoflow-interval-selected-icon.svg';
import { ReactComponent as AutoflowExactDateIcon } from 'assets/icons/autoflow-exact-date-icon.svg';
import { ReactComponent as AutoflowExactDateSelectedIcon } from 'assets/icons/autoflow-exact-date-selected-icon.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';
// import images
import AutoflowIntervalHintImg from 'assets/images/autoflow-interval-hint.svg';
import AutoflowExactDateHintImg from 'assets/images/autoflow-exact-date-hint.svg';
import CreateNewAutoflowStyles from './styles';

const EXACT_DATE = 'exact_date';
const INTERVAL = 'interval';

const CreateNewAutoflow = ({ isOpened, user, toggleModal, addAutoflow, push }) => {
  const [autoflowType, setAutoflowType] = useState(INTERVAL);
  const [name, setName] = useState(undefined);
  const [nameError, setNameError] = useState(false);
  const [week, setWeek] = useState(1);
  const [weekError, setWeekError] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  useEffect(() => {
    setIsDisabledBtn(true);
    if (autoflowType === EXACT_DATE && name) {
      setIsDisabledBtn(false);
    }
    if (autoflowType === INTERVAL && week && name) {
      setIsDisabledBtn(false);
    }
  }, [name, week, autoflowType]);

  const onSubmit = event => {
    event.preventDefault();

    if (!name || !name.trim()) {
      return setNameError(true);
    }
    setIsDisabledBtn(true);
    setNameError(false);

    if (autoflowType === INTERVAL) {
      if (!isNumber(week)) {
        return setWeekError('Week is required');
      }
      if (week < 1 || week > CALENDAR_LIMIT_WEEKS) {
        return setWeekError(`Enter a number from 1 to ${CALENDAR_LIMIT_WEEKS}`);
      }
    }
    setWeekError('');

    const data = { name: name.trim(), type: autoflowType, author: user._id, share: TEAM_SHARE_PRIVATE };
    if (autoflowType === INTERVAL) {
      data.number_of_weeks = week;
    }
    addAutoflow(data).then(data => {
      if (get(data, 'data.data._id')) {
        toggleModal(false);
        setIsDisabledBtn(false);
        push(`/home/autoflow/${get(data, 'data.data._id')}/training/calendar`);
      }
    });
  };

  const onWeekChange = e => {
    const value = Math.abs(parseInt(e.target.value));
    if (e.target.value === '' || value === 0) setIsDisabledBtn(true);
    else setIsDisabledBtn(false);
    setWeek(value);
  };

  return (
    <Modal
      open={isOpened}
      closeOnDimmerClick={false}
      className="modal--custom-workout-background"
      onClose={() => toggleModal(false)}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <CreateNewAutoflowStyles>
        <Modal.Content>
          <form onSubmit={onSubmit}>
            <ModalLayout.Header className="autoflow-modal-header">Create an Autoflow</ModalLayout.Header>
            <ModalLayout.Content className="autoflow-modal-content">
              <div className="description">
                Choose if you want to schedule assignments based on a sequence of days after start date or directly to
                dates on a calendar.
              </div>
              <div className="autoflow-types-selection">
                <div className="autoflow-type">
                  <input
                    name="autoflow-type"
                    type="radio"
                    id="autoflow-interval-toggle"
                    className="visually-hidden"
                    checked={autoflowType === INTERVAL}
                    onClick={() => setAutoflowType(INTERVAL)}
                  />
                  <label for="autoflow-interval-toggle">
                    <div className="autoflow-section autoflow-interval" for="autoflow-interval-toggle">
                      {autoflowType === INTERVAL && <AutoflowIntervalSelectedIcon />}
                      {autoflowType !== INTERVAL && <AutoflowIntervalIcon />}
                      <div className="title">By Day Sequence</div>
                      <div className="description">Schedule assignments based on day number in the Autoflow</div>
                      <div
                        className="autoflow-tooltip"
                        data-for="autoflow-interval-tooltip"
                        data-tip="autoflow-interval-tooltip"
                      >
                        <TooltipIcon />
                      </div>
                      <ReactTooltip
                        className="app-tooltip-white"
                        id="autoflow-interval-tooltip"
                        effect="solid"
                        place={'bottom'}
                        delayShow={500}
                      >
                        <img src={AutoflowIntervalHintImg} alt="" width="405" />
                      </ReactTooltip>
                    </div>
                  </label>
                </div>
                <div className="divider" />
                <div className="autoflow-type">
                  <input
                    name="autoflow-type"
                    type="radio"
                    id="autoflow-exact-date-toggle"
                    className="visually-hidden"
                    checked={autoflowType === EXACT_DATE}
                    onClick={() => setAutoflowType(EXACT_DATE)}
                  />
                  <label for="autoflow-exact-date-toggle">
                    <div className="autoflow-section autoflow-exact-date">
                      {autoflowType === EXACT_DATE && <AutoflowExactDateSelectedIcon />}
                      {autoflowType !== EXACT_DATE && <AutoflowExactDateIcon />}
                      <div className="title">Exact Date</div>
                      <div className="description">Choose exact days on a calendar your client should train.</div>
                      <div
                        className="autoflow-tooltip"
                        data-for="autoflow-exact-date-tooltip"
                        data-tip="autoflow-exact-date-tooltip"
                      >
                        <TooltipIcon />
                      </div>
                      <ReactTooltip
                        className="app-tooltip-white"
                        id="autoflow-exact-date-tooltip"
                        effect="solid"
                        place={'bottom'}
                        delayShow={500}
                      >
                        <img src={AutoflowExactDateHintImg} alt="" width="405" />
                      </ReactTooltip>
                    </div>
                  </label>
                </div>
              </div>
              <FormGroup>
                <label>Autoflow Name</label>
                <input
                  value={name}
                  placeholder="Enter name..."
                  onChange={event => setName(event.target.value)}
                  autoFocus
                />
                {nameError ? <div className="error">Name is required</div> : null}
              </FormGroup>
              {autoflowType === INTERVAL && (
                <FormGroup>
                  <label>Duration</label>
                  <div className="autoflow-week">
                    <input
                      value={week}
                      type="number"
                      onKeyPress={event => {
                        const keyCode = event.keyCode || event.which;
                        const keyValue = String.fromCharCode(keyCode);
                        if (/\+|-|e/.test(keyValue)) event.preventDefault();
                      }}
                      placeholder="Enter week..."
                      onChange={onWeekChange}
                      min={1}
                    />
                    <span>{week < 2 ? 'Week' : 'Weeks'}</span>
                  </div>
                  {weekError ? <div className="error">{weekError}</div> : null}
                </FormGroup>
              )}
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button purple disabled={isDisabledBtn} className="autoflow-action-btn">
                Create
              </Button>
            </ModalLayout.Actions>
          </form>
        </Modal.Content>
      </CreateNewAutoflowStyles>
    </Modal>
  );
};

export default connect(
  state => ({
    isOpened: state.isModalOpen,
    user: state.user,
  }),
  dispatch => ({
    toggleModal: status => dispatch(toggleModal(status)),
    addAutoflow: data => dispatch(addAutoflow(data)),
    push: url => dispatch(push(url)),
  }),
)(CreateNewAutoflow);
