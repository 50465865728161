import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewCountryModal from '../components/NewCountryModal';

import { toggleModal } from 'actions/modal';
import * as S from '../style';
import { getPaymentCountries } from 'utils/commonFunction';
import { getRequestCountryStatus } from 'redux/product/actions';

function NewCountryFallBack(props) {
  const { toggleModal, isRequested, getRequestCountryStatus, isFetchingCountry } = props;

  useEffect(() => {
    getRequestCountryStatus && getRequestCountryStatus();
  }, []);

  const handleCloseCountryModal = () => {
    toggleModal(false);
  };

  const handleClick = () => {
    toggleModal(true, <NewCountryModal handleCloseCountryModal={handleCloseCountryModal} />);
  };

  return (
    <S.NewCountryWrapper>
      {getPaymentCountries()}
      <S.PackageFallBackGroup
        href="https://help.everfit.io/en/articles/5684968-processing-fees-for-payments"
        target="_blank"
        rel="noopener noreferrer"
      >
        and more
      </S.PackageFallBackGroup>
      .
      <S.RequestButton
        disabled={isFetchingCountry || isRequested}
        isDisabled={isFetchingCountry || isRequested}
        onClick={isFetchingCountry ? undefined : handleClick}
      >
        {isFetchingCountry || isRequested ? 'Requested' : 'Request new country'}
      </S.RequestButton>
    </S.NewCountryWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;

  return {
    isRequested: rootReducer.product.get('isRequested'),
    isFetchingCountry: rootReducer.product.get('isFetchingCountry'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getRequestCountryStatus: bindActionCreators(getRequestCountryStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCountryFallBack);
