// Lib
import React from 'react';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const DiscardChangeModal = ({ onConfirm, title, content, confirmButtonTitle }) => {
  return (
    <S.CustomConfirmModal
      title={title || 'Discard Changes?'}
      content={content || 'Are you sure you want to go? Changes have not been saved yet.'}
      onConfirm={onConfirm}
      confirmButtonTitle={confirmButtonTitle || 'Discard Changes'}
      noBorder
      hasCloseIcon
      hasHoverState
      headerIcon={`${CDN_URL}/images/discard_changes_icon.svg`}
    />
  );
};

export default DiscardChangeModal;
