import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Container } from './style';
import './style.scss';
import Trigger from './Trigger';
import ReactPlayer from 'react-player';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { isMp3File, isVideoFile } from 'utils/validations';
import { convertS3UrlToCloudFrontUrl, makeFriendlyYoutubeURL } from 'utils/commonFunction';

const closeIcon = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3125 0.6875L0.6875 21.3125"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.6875 0.6875L21.3125 21.3125"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function MediaPlayer(props) {
  const { url, type, thumbnail, showRecord, generatePreview, onPause, cloudfrontList } = props;
  const [open, setOpen] = useState(false);
  const isVideo = isVideoFile(type) || isMp3File(type);
  const mediaElement = useRef();
  const [src, setSrc] = useState('');

  const onModalOpen = async () => {
    if (showRecord) {
      return;
    }
    isVideo && onPause && onPause();
    try {
      let newURL = convertS3UrlToCloudFrontUrl(url, cloudfrontList);
      if (!newURL) {
        const response = await getS3presignedURLFromLocalDatabase([url]);
        newURL = response[0];
      }
      setSrc(newURL || url);
      setOpen(true);
    } catch {
      setSrc(convertS3UrlToCloudFrontUrl(url, cloudfrontList, true));
      setOpen(true);
    }
  };

  return (
    <Modal
      open={open}
      onOpen={onModalOpen}
      closeOnDimmerClick={true}
      closeIcon={<CloseButton className="close-button">{closeIcon}</CloseButton>}
      onClose={() => setOpen(false)}
      trigger={
        <Trigger
          isVideo={isVideo}
          url={url}
          thumbnail={thumbnail}
          generatePreview={generatePreview}
          getS3presignedURLFromLocalDatabase={props.getS3presignedURLFromLocalDatabase}
        />
      }
      className="evf-media-player-modal"
    >
      <Modal.Content>
        <Container>
          {isVideo ? (
            <ReactPlayer
              url={makeFriendlyYoutubeURL(src)}
              playing
              controls={true}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                  forceVideo: isVideoFile(type),
                  forceAudio: isMp3File(type),
                },
              }}
            />
          ) : (
            <img src={src} ref={mediaElement} alt="" />
          )}
        </Container>
      </Modal.Content>
    </Modal>
  );
}

MediaPlayer.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  thumbnail: PropTypes.string,
};

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps, { getS3presignedURLFromLocalDatabase })(MediaPlayer);
