import React, { Component } from 'react';
import _ from 'lodash';
import Avatar from 'react-avatar';
import Emoji from 'react-emoji-render';
import { Emoji as EmojiPicker } from 'emoji-picker-react';

import {
  timeSince,
  getUserShortName,
  isTeamAdmin,
  convertS3UrlToCloudFrontUrl,
  getAdditionalInfoDayString,
} from 'utils/commonFunction';
import { ACTION_TYPE } from './constants';
import { BACKGROUND_LIST, DEFAULT_EMOJI } from 'constants/habitData';
import './styles.scss';
import * as S from './styles';
import {
  EVENT_OPEN_TASK_REMINDER_POP_UP,
  TOPICS,
  NOTIFICATION_SUB_ACTIONS,
  PAYMENT_NOTIFICATIONS_TYPES,
  NOTIFICATION_ACTIONS,
} from 'constants/commonData';

export default class UpdateOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      page: 1,
      per_page: 20,
      total: 0,
    };

    this.lastScrollTop = 0;
    this.onScrollDebounce = _.debounce(this.onActivityScroll, 200);
  }

  componentDidMount() {
    if (this.props.selectedClient) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getClientActivity();
      }, 500);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedClient._id !== prevProps.selectedClient._id) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getClientActivity();
      }, 500);
    }
  }

  isShowAvatar = (action_type = '', type = '') => {
    switch (action_type) {
      case ACTION_TYPE.GOAL_COUNTDOWN:
        return true;
      case ACTION_TYPE.HABIT:
        if (type === ACTION_TYPE.COMMENT) return true;
        return false;
      case ACTION_TYPE.LOG_ACTIVITY:
        if (type === ACTION_TYPE.COMMENT) return true;
        return false;
      default:
        return false;
    }
  };

  isShowHabitEmoji = (action_type = '', type = '') => {
    if (action_type === ACTION_TYPE.HABIT && type !== ACTION_TYPE.COMMENT) {
      return true;
    }
    return false;
  };

  onActivityScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const { page, per_page, total } = this.state;

    if (page * per_page < total && scrollTop > this.lastScrollTop) {
      const bottom = scrollHeight - (scrollTop + clientHeight);

      if (bottom < 150) {
        this.getClientActivity(page + 1);
      }
    }

    this.lastScrollTop = scrollTop;
  };

  getClientActivity(nextPage = 1) {
    const { getClientActivity, selectedClient, user, subTrainers, idUser } = this.props;
    const isAdmin = isTeamAdmin(user);
    const hasPermission = _.get(selectedClient, 'trainers', []).some(item => item.trainer === user._id);

    const isSubTrainer = _.findIndex(subTrainers, function (o) {
      return o.trainer === idUser;
    });

    const params = {
      page: nextPage,
      per_page: this.state.per_page,
    };

    (isAdmin || hasPermission || isSubTrainer !== -1) &&
      getClientActivity(selectedClient._id, params).then(result => {
        const data = _.get(result, 'data.data');
        const total = _.get(result, 'data.total');
        const page = _.get(result, 'data.page');
        const per_page = _.get(result, 'data.per_page');
        const activities = [...this.state.activities, ...data].sort((a, b) => {
          return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
        });
        if (data) {
          this.setState({
            activities,
            total,
            page,
            per_page,
          });
        }
      });
  }

  handleRenderMessage = data => {
    const activityName = _.get(data, 'additional_info.name', '');
    const accountFullName = _.get(data, 'relate_user.full_name', '');
    const highlights = _.get(data, 'highlights', []);
    let message = _.get(data, 'message', '');

    highlights.forEach((highlight, index) => {
      if (activityName === highlight) return;
      if (
        _.get(data, 'action_type', '').includes('log_activity') &&
        ![accountFullName, 'commented'].includes(highlight) &&
        index > 0
      )
        return;
      if (index === 0) message = message.replace(highlight, `<span class="name">${highlight}</span>`);
      else message = message.replace(highlight, `<span class="highlights">${highlight}${' '}</span>`);
    });

    return <div className="message" dangerouslySetInnerHTML={{ __html: message }}></div>;
  };

  handleGetUserShortName = data => {
    if (this.isShowAvatar(data.action_type, data.type)) {
      return getUserShortName(data.relate_user || data.user);
    }
    let name = _.get(data, 'highlights', []);
    if (name) {
      name = name[0] || '';
      const names = (name || '').trim().replace(/\s+/g, ' ').split(' ');
      return names.length > 1 ? `${names[0]} ${names.pop()}` : names[0];
    }
  };

  handleRenderSource = data => {
    if (this.isShowAvatar(data.action_type, data.type)) {
      return _.get(data, 'relate_user.avatar', '');
    }
    if (this.isShowHabitEmoji(data.action_type, data.type)) {
      return _.get(data, 'additional_info.icon_unified', DEFAULT_EMOJI.icon_unified);
    }
    const defaultSource = _.get(data, 'relate_user.avatar', '');
    return _.get(data, 'additional_info.icon', defaultSource);
  };

  handleRenderColor = data => {
    if (this.isShowAvatar(data.action_type, data.type)) {
      return _.get(data, 'relate_user.color', '#5c58c0');
    }
    if (this.isShowHabitEmoji(data.action_type, data.type)) {
      const border = _.get(data, 'additional_info.color', DEFAULT_EMOJI.color);
      const backgroundColor = _.find(BACKGROUND_LIST, ['border', border]);
      return _.get(backgroundColor, 'background', BACKGROUND_LIST[2].background);
    }
    const defaultColor = _.get(data, 'relate_user.color', '#5c58c0');
    return _.get(data, 'additional_info.background_icon_color', defaultColor);
  };

  handleRenderAvatar = (actionType, type, name, src, color) => {
    const { cloudfrontList } = this.props;

    if (this.isShowAvatar(actionType, type)) {
      const imgCloudFrontUrl = convertS3UrlToCloudFrontUrl(src, cloudfrontList, true);
      return <Avatar name={name} color={color} className="client-avatar" size="24" src={imgCloudFrontUrl} />;
    }

    if (this.isShowHabitEmoji(actionType, type)) {
      return (
        <S.EmojiHabit color={color}>
          <EmojiPicker unified={src} size="16" />
        </S.EmojiHabit>
      );
    }

    if (!src || src.includes('https://') || src.includes('http://')) {
      const newUrl = !src ? src : convertS3UrlToCloudFrontUrl(src, cloudfrontList, true);
      return <Avatar name={name} color={color} className="client-avatar" size="24" src={newUrl} />;
    }

    return (
      <div className="activity-ava-area">
        <Emoji text={src} onlyEmojiClassName="activity-emoji" />
      </div>
    );
  };

  getCreatedAtString = data => {
    const createdAt = _.get(data, 'createdAt');
    const timezone = _.get(data, 'user.timezone');
    if (_.isEmpty(data) || _.isEmpty(createdAt) || _.isEmpty(timezone)) return '';
    const date = new Date(new Date(createdAt).toLocaleString('en-US', { timeZone: timezone }));
    // format MM-dd-yyyy
    return `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`;
  };

  handleOpenDetail = data => {
    const formId = _.get(data, 'additional_info.form');
    const clientId = _.get(data, 'relate_user._id');
    const submittedId = _.get(data, 'additional_info.formAnswer');

    if (data.type === 'form_questionnaire' && formId && clientId && submittedId) {
      window.open(`/home/forms/${formId}/responses?client=${clientId}&submitted=${submittedId}`, '_blank');
    }
  };

  onItemClick = (data, event) => {
    const {
      isHabitPermission,
      getClientsList,
      resetLoadPostsQuery,
      checkPostsIndicatorAndGotoPostDetail,
      push,
      navigateToPersonalTask,
    } = this.props;
    const openNewTab = event.shiftKey || event.metaKey || event.ctrlKey;
    const isPaymentNotification =
      _.values(PAYMENT_NOTIFICATIONS_TYPES).includes(_.get(data, 'type')) ||
      _.values(PAYMENT_NOTIFICATIONS_TYPES).includes(_.get(data, 'action_sub_type'));
    const packageId = _.get(data, 'additional_info.package.hash_id') || _.get(data, 'additional_info.package.id');
    const purchaseId = _.get(data, 'additional_info.purchase.id') || _.get(data, 'additional_info.purchase_id');

    if (isPaymentNotification && packageId && purchaseId) {
      const url = `/home/packages/${packageId}/analytics?preview=${purchaseId}`;
      openNewTab ? window.open(url, '_blank') : push(url);
      return;
    }

    let url;
    if (
      data.action_type === NOTIFICATION_ACTIONS.MEAL_PLAN_CLIENT &&
      data.action_sub_type === NOTIFICATION_SUB_ACTIONS.CLIENT_SWAPPED_RECIPE &&
      data.action_target === NOTIFICATION_ACTIONS.MEAL_PLAN_CLIENT
    ) {
      const clientId = _.get(data, 'relate_user._id');
      const trackedDate = getAdditionalInfoDayString(data);
      if (clientId) url = `/home/client/${clientId}/meal-plan?tracked=${trackedDate}`;
    } else if (
      data.action_type === NOTIFICATION_ACTIONS.MACRO &&
      data.action_sub_type === NOTIFICATION_SUB_ACTIONS.CLIENT_LOGGED_MEAL &&
      data.action_target === NOTIFICATION_ACTIONS.MACRO
    ) {
      const clientId = _.get(data, 'relate_user._id');
      const trackedDate = getAdditionalInfoDayString(data);
      if (clientId) url = `/home/client/${clientId}/macros?tracked=${trackedDate}`;
    } else if (
      data.action_type === NOTIFICATION_ACTIONS.PROGRESS_PHOTO &&
      data.action_target === NOTIFICATION_ACTIONS.PROGRESS_PHOTO
    ) {
      const clientId = _.get(data, 'relate_user._id');
      url = `/home/client/${clientId}?show=progress_photo`;
    } else if (
      data.action_type === NOTIFICATION_ACTIONS.GOAL &&
      data.action_sub_type === NOTIFICATION_SUB_ACTIONS.UPDATE &&
      !data.action_target
    ) {
      const clientId = _.get(data, 'relate_user._id');
      url = `/home/client/${clientId}/macros`;
    } else if (
      data.action_type === NOTIFICATION_ACTIONS.BODY_METRIC &&
      data.action_sub_type === NOTIFICATION_SUB_ACTIONS.UPDATE &&
      data.action_target === NOTIFICATION_ACTIONS.BODY_METRIC
    ) {
      const clientId = _.get(data, 'relate_user._id');
      url = `/home/client/${clientId}/metrics`;
    } else if (data.action_type === NOTIFICATION_ACTIONS.FORM_QUESTIONNAIRES) {
      const formId = _.get(data, 'additional_info._id');
      const clientId = _.get(data, 'relate_user._id');
      const idClientInfo = _.get(data, 'additional_info.client._id');
      const submittedId = _.get(data, 'item');
      getClientsList(formId, {
        clientId,
        submittedId,
      });
      url = `/home/forms/${formId}/responses?client=${
        idClientInfo ? idClientInfo : clientId
      }&submitted=${submittedId}&hasComment=true`;
    } else if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.NEW_WAITING_CLIENT) {
      url = '/home/client/waiting-activation';
    } else if (data.action_type === NOTIFICATION_ACTIONS.LEADER_BOARD && data.secondary_item) {
      url = `/home/autoflow/${data.secondary_item}/leaderboard`;
    } else if (data.action_type === NOTIFICATION_ACTIONS.REFERRAL) {
      url = '/home/referral-program';
    } else if (data.action_type === NOTIFICATION_ACTIONS.AUTOFLOW) {
      url = '/home/autoflow';
      if (data.item) {
        url += `/${data.item}/${data.item_topic}`;
      }
    } else if (data.action_type === NOTIFICATION_ACTIONS.FORUM) {
      if (data.sub_item_topic === 'forum_post') {
        resetLoadPostsQuery();
        // Mark Read Post Detail And Go To Detail
        checkPostsIndicatorAndGotoPostDetail({ postId: data.sub_item }, data.item, data.sub_item);
      } else {
        resetLoadPostsQuery();
        url = `/home/forums/${data.item}/discussion`;
      }
    } else if (data.action_type === NOTIFICATION_ACTIONS.LOG_ACTIVITY) {
      const idRelateUser = _.get(data, 'relate_user._id', '');
      const idAuthor = _.get(data, 'additional_info.author', '');
      if (idRelateUser || idAuthor) {
        url = `/home/client/${idAuthor || idRelateUser}/calendar/${data.item}/activity`;
      }
    } else if (data.relate_user) {
      if (data.action_type !== TOPICS.PERSONAL_TASK) {
        const idRelateUser = _.get(data, 'relate_user._id', '');
        const idClient = _.get(data, 'additional_info.client._id', '');
        url = `/home/client/${idClient ? idClient : idRelateUser}`;
      }

      if (data.item) {
        switch (data.item_topic) {
          case TOPICS.FOOD_JOURNAL:
            url += `/food-journal/detail/${data.item}`;
            break;
          case TOPICS.ASSIGNMENT:
            url += `/calendar/${data.item}/history`;
            break;
          case TOPICS.TASK:
            url += `/task/${data.item}?`;
            if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.COMMENT) {
              url += `comment=show&`;
            }
            url = url.replace(/\?$|&$/, '');
            break;
          case TOPICS.TEAM_BODY_METRIC:
            const metricCode = _.get(data, 'additional_info.metric_code');
            url += metricCode ? `/metrics?unique_code=${encodeURIComponent(metricCode)}` : `/metrics`;
            break;
          case TOPICS.PERSONAL_TASK:
            window.dispatchEvent(EVENT_OPEN_TASK_REMINDER_POP_UP);
            navigateToPersonalTask(data);
            break;
          case TOPICS.HABIT:
            if (isHabitPermission) {
              url += `/habit/${data.item}?`;
              if (
                data.action_sub_type === NOTIFICATION_SUB_ACTIONS.ON_STREAK ||
                data.action_sub_type === NOTIFICATION_SUB_ACTIONS.HABIT_COMPLETE_TRAINER
              ) {
                url += `tracked=${this.getCreatedAtString(data)}&`;
              }
              if (data.action_sub_type === NOTIFICATION_SUB_ACTIONS.COMMENT) {
                url += `comment=show&`;
              }
              url = url.replace(/\?$|&$/, '');
            } else {
              return;
            }
            break;
          default:
            break;
        }
      }
    }

    this.handleOpenDetail(data);

    if (openNewTab) {
      window.open(url, '_blank');
    } else {
      data.type !== 'form_questionnaire' && push(url);
    }
  };

  render() {
    return (
      <div className="eve-panel update-panel">
        <div className="panel-header">
          <div className="panel-title">Updates</div>
        </div>
        <div
          className="panel-body"
          onScroll={event => {
            event.persist();
            this.onScrollDebounce.call(null, event);
          }}
        >
          <ul>
            {this.state.activities &&
              this.state.activities.map(data => {
                const actionType = _.get(data, 'action_type', '');
                const type = _.get(data, 'type', '');
                const name = this.handleGetUserShortName(data);
                const src = this.handleRenderSource(data);
                const color = this.handleRenderColor(data);

                return (
                  <li key={data._id} onClick={e => this.onItemClick(data, e)} className="panel-item">
                    <div className="panel-left">
                      {this.handleRenderAvatar(actionType, type, name, src, color)}
                      {this.handleRenderMessage(data)}
                    </div>
                    <div className="time">{timeSince(new Date(data.createdAt))}</div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}
