// Libs
import React, { PureComponent } from 'react';
import { Area, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Line, ReferenceLine } from 'recharts';
import { findDOMNode } from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import round from 'lodash/round';
import isEmptyLodash from 'lodash/isEmpty';

// Actions
import { getBodyMetricTargetOverview, getMetricSettingsOverview } from 'actions/bodyMetric';
import { getChartDataLatestByDay } from 'redux/client/client.actionCreators';

// Components
import CustomLineCursor from './CustomLineCursor';
import CustomTooltipContent from './CustomTooltipContent';
import CustomGoalDot from './CustomGoal';
import CustomDot from './CustomDot';
import CustomLineBar from './CustomLineBar';
import CustomizedAxisTick from './CustomizedAxisTick';
import LoadingIndicator from 'shared/LoadingIndicator';
import CustomAvgLabel from './CustomAvgLabel';

// Constants
import {
  CHART_COLORS,
  CHART_LABEL_POSITION,
  CHART_TOOLTIP_POSITION,
  DATA_POINT_KEYS,
  HOUR_LABELS,
  KEYS_METRIC,
  KEYS_METRIC_ARRAY,
  PERIOD_GROUP,
  TIME_UNITS,
  initChartDataEmpty,
} from '../constants';
import { convertUnit } from 'utils/commonFunction';
import {
  convertDataToUnit,
  fakeChartDataWithGoal,
  fillChartDataByPeriodTime,
  fillChartDataBySettings,
  filledComparePercentage,
  formatWeeklyLabel,
  getDataMax,
  getDataMin,
  getMinMaxValue,
  getNewDataMinMax,
  getSizeOfColumn,
  getTicks,
  getXAxisInterval,
  getXLabels,
  handleUnitFormat,
  updateTooltipPosition,
} from '../chartHelper';

// Assets
import { ReactComponent as ChartBarIcon } from 'assets/icons/bar-chart.svg';
import { ReactComponent as ChartLineIcon } from 'assets/icons/line-chart-2.svg';

// Styles
import * as S from './styles';

class MetricChartCompare extends PureComponent {
  constructor(props) {
    super(props);
    this.compareAreaRef = null;
    this.compareChartRef = null;
    this.state = {
      tooltipPos: { x: undefined, y: undefined },
      filledData: [],
      isNoDataButHasGoal: false,
      goalValue: null,
      barWidth: 20,
      xInterval: 0,
      chartData: [],
      isLoading: true,
      target: {},
      settings: {},
      isHoveringCompareChart: false,
      chartBarData: {},
      chartInfor: {},
      showGoalKeyMetric: false,
      showAverageKeyMetric: false,
      average: null,
    };
    this.ID = 'metric-chart-tooltip-customized-1';
  }

  componentDidMount() {
    const { isDisplayDataPoints } = this.props;

    setTimeout(() => {
      this.getChartData();
      this.getValueGoalKeyMetric();
    }, 500);

    if (isDisplayDataPoints) {
      this.moveEleTooltip();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { chartData, target, settings } = this.state || {};
    const {
      rangeTime,
      bodyMetricCompare,
      onGetCompareChartData,
      colorMetricChart,
      customBrandingColor,
      isDisplayDataPoints,
      dataPoint,
    } = this.props || {};

    const { unit } = settings || {};
    const { data = {} } = target || {};

    if (prevState.chartData !== chartData) {
      const isEmpty = chartData.length === 0;
      const isNoDataButHasGoal = isEmpty && !!data && data.value > 0;
      const filledData = fillChartDataByPeriodTime(
        isEmpty ? initChartDataEmpty(rangeTime.to_date) : chartData,
        rangeTime,
      );
      const goalValue = this.convertGoalValueToUnit();
      const newChartData = isNoDataButHasGoal
        ? fakeChartDataWithGoal(goalValue, filledData)
        : convertDataToUnit(unit, filledData, goalValue);
      const barWidth = getSizeOfColumn(newChartData);
      const xInterval = getXAxisInterval(rangeTime);

      // Add more info to compare chart
      const chartColor =
        get(settings, 'color') || customBrandingColor || CHART_COLORS.find(color => color !== colorMetricChart);
      const chartName = get(bodyMetricCompare, 'name', '');
      const filledChartDataSettings = fillChartDataBySettings(newChartData, chartColor, chartName);
      const fileldChartDataComparePercentage = filledComparePercentage(filledChartDataSettings, dataPoint);

      typeof onGetCompareChartData === 'function' && onGetCompareChartData(fileldChartDataComparePercentage);

      this.setState({ filledData: fileldChartDataComparePercentage, goalValue, barWidth, xInterval });
    }

    if (prevProps.rangeTime !== rangeTime || prevProps.bodyMetricCompare !== bodyMetricCompare) {
      this.getChartData(rangeTime);
    }

    if (get(prevState, 'settings.key_metrics') !== get(settings, 'key_metrics')) {
      this.getValueGoalKeyMetric();
    }

    if (isDisplayDataPoints) {
      this.moveEleTooltip();
    }
  }

  getTarget = async () => {
    const { clientId, bodyMetricCompare, getBodyMetricTargetOverview } = this.props;
    try {
      const response = await getBodyMetricTargetOverview({
        client: clientId,
        unique_code: get(bodyMetricCompare, 'unique_code'),
      });

      const { data: { data } = { data: {} } } = response || {};

      return { target: data };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  getSettings = async () => {
    const { bodyMetricCompare, getMetricSettingsOverview } = this.props;
    try {
      const response = await getMetricSettingsOverview(get(bodyMetricCompare, 'unique_code'));
      const { data: { data } = { data: {} } } = response || {};
      return data || {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  getChartData = async range => {
    const { clientId, bodyMetricCompare, getChartDataLatestByDay, rangeTime, dataPoint } = this.props;
    this.setState(
      {
        isLoading: true,
        filledData: [],
        chartData: [],
        goalValue: null,
        settings: {},
        target: {},
        average: null,
      },
      () => {
        this.props.loadingChartCallback(true);
      },
    );

    try {
      const target = await this.getTarget();
      const settings = await this.getSettings();
      const { from_date, to_date, period_group } = range || rangeTime || {};
      const timezone = moment.tz.guess();

      const { data: chartDataRespond = {} } =
        (await getChartDataLatestByDay({
          client: clientId,
          metric_code: get(bodyMetricCompare, 'unique_code'),
          period_group,
          from_date,
          to_date,
          timezone,
          data_point: dataPoint || DATA_POINT_KEYS.latest_value,
        })) || {};

      const goal = get(target, 'target.data.value', 0);
      const { data, previous_day = '', previous_value = '', previous_entry = {}, average = 0 } = chartDataRespond;
      const chartData = data
        .filter(item => !!item && !isNaN(parseInt(item.value)))
        .map(item => ({
          ...item,
          goal,
          period_group,
          value: Number(item.value),
          time: moment(item.date).tz(timezone).format('MMM DD'),
          previousDay: previous_day,
          previousValue: previous_value,
          previousEntry: previous_entry,
        }));

      this.setState({
        chartData,
        ...target,
        settings,
        average,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(
        s => ({
          ...s,
          isLoading: false,
        }),
        () => {
          this.props.loadingChartCallback(false);
        },
      );
    }
  };

  updateCompareAreaRef = r => {
    this.compareAreaRef = r || this.compareAreaRef;
  };

  updateTooltipBarChart = data => {
    const { isModeCompare } = this.props;

    const tooltipPos = updateTooltipPosition(this.compareChartRef, this.ID, data, isModeCompare);
    this.setState({ tooltipPos });
  };

  convertGoalValueToUnit = () => {
    const { bodyMetricCompare } = this.props;
    const { target, settings } = this.state || {};
    const { data } = target || {};

    if (isEmptyLodash(data)) return null;

    return +convertUnit(
      (data || {}).value || 0,
      get(bodyMetricCompare, 'unit'),
      get(settings, 'unit') || get(bodyMetricCompare, 'unit'),
    ).toFixed(2);
  };

  formatXAxisLabel = label => {
    const {
      rangeTime: { period_group },
    } = this.props;
    const { xInterval, filledData } = this.state;
    const labels = period_group === PERIOD_GROUP.HOURLY ? HOUR_LABELS : getXLabels(filledData, xInterval);

    if (labels.includes(label)) {
      return formatWeeklyLabel(label, period_group);
    }
    return '';
  };

  handleTickFormat = value => {
    const { filledData, goalValue, showGoalKeyMetric } = this.state;
    const { bodyMetricCompare } = this.props;
    const { min, max } = getMinMaxValue(filledData);

    const dataMin = getDataMin(min, max, showGoalKeyMetric ? goalValue : 0);
    const dataMax = getDataMax(min, max, showGoalKeyMetric ? goalValue : 0);

    const isSecOrMinUnit = TIME_UNITS.includes(get(bodyMetricCompare, 'unit.unique_code', ''));
    const isConvertValue = isSecOrMinUnit && max >= 60;

    const [newDataMin] = getNewDataMinMax(dataMin, dataMax, isSecOrMinUnit);

    return handleUnitFormat(get(bodyMetricCompare, 'unit', {}), value, newDataMin, !isConvertValue);
  };

  tooltipActiveCallback = active => {
    const { tooltipActive } = this.state || {};
    const { isMetricChartLine } = this.props;

    if (isMetricChartLine && tooltipActive !== active) {
      this.setState({ tooltipActive: active });
    }
  };

  handleHoverChart = ({ status = false, color = '', data = {}, index = -1 }) => {
    const { isModeCompare, onHoveringCompareChart, chartRef, isMetricChartLine, areaRef } = this.props;
    const { props } = chartRef || {};

    if (!isModeCompare || !chartRef) return;

    try {
      // Get DOM of chart
      const node = findDOMNode(chartRef);
      const node1 = findDOMNode(this.compareChartRef);

      // Variables of bar chart
      let positionY = 0;
      let customHeight = 0;
      let isBar1HeightZero = false;
      let hasDataBar1 = false;

      // Variables of line chart
      let chartInfor = {};

      const noIdKey = get(props, 'data', []).every(obj => isObject(obj) && !obj.hasOwnProperty('_id'));
      const isEmptyPropsData = isEmptyLodash(get(props, 'data'));

      const getBoundingRect = (node, selector, indexProps = 0) => {
        const element = selector ? node && node.querySelectorAll(selector)[indexProps] : node;
        return element ? element.getBoundingClientRect() : undefined;
      };

      if (!isMetricChartLine && node instanceof Element && node1 instanceof Element) {
        const boundingBar1 = getBoundingRect(node, '.recharts-bar-rectangle', index);
        const boundingBar2 = getBoundingRect(node1, '.recharts-bar-rectangle', index);
        const boundingAxisBar1 = getBoundingRect(node, '.recharts-cartesian-axis-tick', index);

        const elementAxisBar1 = node && node.querySelectorAll('.recharts-cartesian-axis-tick')[index];
        const hasTextContent = elementAxisBar1 && elementAxisBar1.textContent !== '';

        if (boundingBar1 && boundingBar2 && boundingAxisBar1 && !(isEmptyPropsData || noIdKey)) {
          isBar1HeightZero = boundingBar1.height === 0;

          positionY =
            boundingBar2.y -
            (isBar1HeightZero ? (hasTextContent ? boundingAxisBar1.y + 3 : boundingAxisBar1.y + 168) : boundingBar1.y);
          customHeight =
            boundingBar2.height +
            boundingBar1.height +
            (boundingBar2.y -
              ((isBar1HeightZero
                ? hasTextContent
                  ? boundingAxisBar1.y + 3
                  : boundingAxisBar1.y + 168
                : boundingBar1.y) +
                boundingBar1.height));
        } else if (isEmptyPropsData || noIdKey) {
          hasDataBar1 = isEmptyPropsData || noIdKey;
        }
      } else if (isMetricChartLine && node instanceof Element && node1 instanceof Element) {
        const boundingChartRef = getBoundingRect(node);
        const boundingCompareChartRef = getBoundingRect(node1);
        const boundingAxisBar1 = getBoundingRect(node, '.recharts-xAxis');

        if (boundingChartRef && boundingCompareChartRef && boundingAxisBar1 && !(isEmptyPropsData || noIdKey)) {
          chartInfor = {
            areaRef,
            compareAreaRef: this.compareAreaRef,
            boundingAxisBar1,
            boundingChartRef,
            boundingCompareChartRef,
          };
        } else if (isEmptyPropsData || noIdKey) {
          chartInfor = {
            noDataBar1: isEmptyPropsData || noIdKey,
          };
        }
      }

      typeof onHoveringCompareChart === 'function' && onHoveringCompareChart(status, color);

      this.setState({
        isHoveringCompareChart: status,
        chartBarData: { ...data, positionY, customHeight, hasDataBar1 },
        chartInfor,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getValueGoalKeyMetric = () => {
    const { settings } = this.state;
    const { key_metrics } = isEmptyLodash(settings) ? { key_metrics: KEYS_METRIC_ARRAY } : settings;

    const [, showAverageKeyMetric, showGoalKeyMetric] = KEYS_METRIC.map(item => key_metrics.includes(item.key));

    this.setState({
      showGoalKeyMetric: showGoalKeyMetric,
      showAverageKeyMetric: showAverageKeyMetric,
    });
  };

  moveEleTooltip = () => {
    const { isMetricChartLine } = this.props;

    const svgEle = document.querySelector('.metric-chart-compare .recharts-surface');
    const svgTooltip = document.querySelector('.metric-chart-compare .recharts-tooltip-cursor');

    if (svgEle && svgTooltip && isMetricChartLine) {
      svgEle.insertBefore(svgTooltip, null);
    }
  };

  render() {
    const {
      filledData,
      barWidth,
      goalValue,
      tooltipPos,
      xInterval,
      tooltipActive,
      chartData,
      settings,
      isLoading,
      isHoveringCompareChart,
      chartBarData,
      chartInfor,
      target,
      showGoalKeyMetric,
      showAverageKeyMetric,
      average,
    } = this.state;
    const {
      bodyMetricCompare,
      isModeCompare,
      compareChartData,
      colorMetricChart,
      nameMetricChart,
      isMetricChartLine,
      isHoveringChart,
      rangeTime: { period_group },
      customBrandingColor,
      isDisplayDataPoints,
    } = this.props || {};

    const { unit: { title: titleUnit = '' } = {} } = settings || {};
    const { data = {} } = target || {};
    const { name = '', unit, _id } = bodyMetricCompare || {};
    const { title = '', unique_code = '' } = unit || {};

    const isEmptyChart = chartData.length === 0;
    const bgColorId = `bgColor${_id}`;
    const isNoDataButHasGoal = chartData.length === 0 && !!data && data.value !== null;

    const color =
      get(settings, 'color') ||
      customBrandingColor ||
      CHART_COLORS.find(color => {
        return color !== colorMetricChart;
      });
    const ChartIcon = isMetricChartLine ? ChartLineIcon : ChartBarIcon;

    // Add more info to compare chart
    const newCompareChartData = fillChartDataBySettings(compareChartData, colorMetricChart, nameMetricChart);

    const { min, max } = getMinMaxValue(filledData);
    const dataMin = getDataMin(min, max, showGoalKeyMetric ? goalValue : 0);
    const dataMax = getDataMax(min, max, showGoalKeyMetric ? goalValue : 0);

    const isSecOrMinUnit = TIME_UNITS.includes(unique_code);

    const [newDataMin, newDataMax, tickCount] = getNewDataMinMax(dataMin, dataMax, isSecOrMinUnit);

    const domain = !isEmptyChart ? [newDataMin, newDataMax] : undefined;

    const ticks = !isEmptyChart ? getTicks(newDataMin, newDataMax, tickCount) : undefined;

    return (
      <S.MetricChartCompareWrapper isNoDataButHasGoal={isNoDataButHasGoal}>
        <ResponsiveContainer
          width="100%"
          height={200}
          className={classNames('metric-chart-compare', { 'no-data': isEmptyChart, 'chart-line': isMetricChartLine })}
        >
          <ComposedChart
            data={filledData}
            ref={r => (this.compareChartRef = r)}
            syncId={isModeCompare ? 'sync-compare-chart' : undefined}
            onMouseOver={() => {
              if (chartData.length !== 1) return;
              if (isMetricChartLine) {
                this.handleHoverChart({ status: true });
              }
            }}
            onMouseOut={() => {
              if (chartData.length !== 1) return;
              if (isMetricChartLine) {
                this.handleHoverChart({ status: false });
              }
            }}
          >
            <XAxis
              dataKey="time"
              padding={{ left: isMetricChartLine ? 15 : 10, right: 5 }}
              tickFormatter={xInterval === 0 ? lab => formatWeeklyLabel(lab, period_group) : this.formatXAxisLabel}
              interval={0}
              dy={5}
              tickLine={false}
              className={isEmptyChart && !isLoading && `customized-xAxis-${_id}`}
              tick={
                <CustomizedAxisTick
                  filledData={filledData}
                  xInterval={xInterval}
                  period_group={period_group}
                  bodyMetricId={_id}
                />
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ top: 10 }}
              tickFormatter={this.handleTickFormat}
              label={{
                value: `${name} ${titleUnit || title ? `(${titleUnit || title})` : ''}`,
                position: CHART_LABEL_POSITION.POSITION,
                offset: CHART_LABEL_POSITION.OFFSET,
                dy: CHART_LABEL_POSITION.DY,
              }}
              domain={domain}
              ticks={isModeCompare && isEmptyChart && !goalValue ? [0] : ticks}
              tickCount={tickCount}
              allowDataOverflow
            />
            <Tooltip
              cursor={
                isMetricChartLine ? (
                  <CustomLineCursor
                    color={color}
                    areaRef={this.compareAreaRef}
                    updateTooltipBarChart={this.updateTooltipBarChart}
                    isModeCompare={isModeCompare}
                    isFillTransparent={!isHoveringCompareChart}
                    isChartCompare={isHoveringCompareChart}
                    chartInfor={chartInfor}
                    isHoveringChart={isHoveringChart || isHoveringCompareChart}
                  />
                ) : isModeCompare ? (
                  <CustomLineBar
                    color={color}
                    barWidth={barWidth}
                    chartBarData={chartBarData}
                    isChartCompare={isHoveringCompareChart}
                    isFillTransparent={!isHoveringCompareChart}
                  />
                ) : (
                  false
                )
              }
              content={
                <CustomTooltipContent
                  id={this.ID}
                  chartData={filledData}
                  compareChartData={newCompareChartData}
                  tooltipActiveCallback={this.tooltipActiveCallback}
                  isModeCompare={isModeCompare}
                  isChartLine={isMetricChartLine}
                  chartName={name}
                  chartColor={color}
                  isShowTooltip={isModeCompare ? isHoveringCompareChart && !isHoveringChart : true}
                  isMetricChartCompare
                  isHoveringChartBar={isHoveringCompareChart}
                />
              }
              animationDurationNumber={500}
              coordinate={tooltipPos}
              position={tooltipPos}
              offset={isModeCompare ? CHART_TOOLTIP_POSITION.OFFSET : undefined}
            />
            {isMetricChartLine ? (
              <>
                <defs>
                  <linearGradient id={bgColorId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0.0115331" stopColor={color} stopOpacity={1} />
                    <stop offset="0.99" stopColor="white" />
                  </linearGradient>
                </defs>
                <Area
                  shapeRendering={'optimizeQuality'}
                  strokeWidth={2}
                  dataKey="value"
                  stroke={color}
                  fill={`url(#${bgColorId})`}
                  ref={this.updateCompareAreaRef}
                  activeDot={false}
                  connectNulls
                  fillOpacity={0.1}
                  isAnimationActive={false}
                  dot={
                    (!tooltipActive && chartData.length === 1) || isDisplayDataPoints
                      ? p => <CustomDot {...p} color={color} isModeCompare={isModeCompare} bodyMetricId={_id} />
                      : undefined
                  }
                  onMouseEnter={() => {
                    if (chartData.length === 1) return;
                    this.handleHoverChart({ status: true });
                  }}
                  onMouseLeave={() => {
                    if (chartData.length === 1) return;
                    this.handleHoverChart({ status: false });
                  }}
                />
              </>
            ) : (
              <Bar
                dataKey="value"
                fill={color}
                barSize={barWidth}
                radius={[3, 3, 0, 0]}
                onMouseOver={(data, index) => {
                  this.updateTooltipBarChart(data);
                  this.handleHoverChart({ status: true, data, index });
                }}
                onMouseOut={() => this.handleHoverChart({ status: false, index: -1 })}
                activeBar={false}
              />
            )}
            {showGoalKeyMetric && goalValue !== null && (
              <>
                <ReferenceLine
                  className="custom-goal-line"
                  y={isNoDataButHasGoal ? round(goalValue) : goalValue}
                  stroke="#7b7e91"
                  label={<CustomGoalDot />}
                />
                <ReferenceLine
                  y={(isNoDataButHasGoal ? round(goalValue) : goalValue) * 2}
                  stroke="#7b7e91"
                  opacity={0}
                />
                <Line dataKey="goal" opacity={0} />
              </>
            )}
            {showAverageKeyMetric && average !== null && (
              <>
                <ReferenceLine
                  y={average}
                  stroke={color}
                  strokeDasharray="2 7"
                  label={<CustomAvgLabel color={color} />}
                  className="avg-line"
                />
                <ReferenceLine
                  y={average * chartData.length === 0 ? 2 : 1}
                  stroke={color}
                  strokeDasharray="2 7"
                  opacity={0}
                />
                <Line dataKey="average" opacity={0} />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
        {isLoading ? (
          <LoadingIndicator title="Loading chart..." className="chart-compare-loading" />
        ) : (
          <>
            {!chartData.length && !isLoading && (
              <div className="chart-no-data-compare">
                <div className="no-data-compare-wrapper">
                  <ChartIcon width={32} />
                  <p>No data available</p>
                </div>
              </div>
            )}
          </>
        )}
      </S.MetricChartCompareWrapper>
    );
  }
}

const mapStateToProps = state => {
  const {
    bodyMetric,
    rootReducer: {
      client: { workingClientDetail },
      customBranding,
    },
  } = state;

  return {
    clientId: get(workingClientDetail, '_id'),
    bodyMetricCompare: get(bodyMetric, 'bodyMetricCompare'),
    rangeTime: get(bodyMetric, 'filterTime'),
    isModeCompare: get(bodyMetric, 'isModeCompare'),
    customBrandingColor: get(customBranding, 'originalTheme.primaryColor', ''),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBodyMetricTargetOverview: bindActionCreators(getBodyMetricTargetOverview, dispatch),
    getMetricSettingsOverview: bindActionCreators(getMetricSettingsOverview, dispatch),
    getChartDataLatestByDay: bindActionCreators(getChartDataLatestByDay, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricChartCompare);
