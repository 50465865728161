import styled, { css } from 'styled-components';
import * as Layout from 'shared/LibraryLayout';
import { CDN_URL } from 'constants/commonData';

const baseText = `
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
`;

export const TableHeader = styled(Layout.TableHeader)``;

export const TableRow = styled(Layout.TableRow)``;

export const TableHeaderCell = styled(Layout.TableHeaderCell)`
  padding: 0;
  :nth-child(1) {
    width: 60px !important;
  }
  :nth-child(2) {
    width: 37% !important;
  }
  :nth-child(3) {
    width: 24% !important;
  }
  :nth-child(6) {
    width: 88px !important;
  }
`;

export const TableHeaderCellContent = styled(Layout.TableHeaderCellContent)`
  cursor: pointer;
  flex-wrap: nowrap;
  color: #a3a3b5;
  .column-symbol {
    margin-left: 11px;
    margin-bottom: 2px;
  }
  ${props =>
    props.active &&
    css`
      color: #5158cf !important;
      .column-symbol {
        fill: #5158cf !important;

        path {
          fill: #5158cf !important;
        }
      }
    `}
  svg.arrow {
    path {
      fill: none !important;
    }
  }
`;

export const TableCell = styled(Layout.TableCell)`
  padding: 16px 10px;
  color: unset;
  white-space: nowrap;
  .name-skeleton {
    width: 118px;
    height: 16px;
    border-radius: 3px;
    background: #f8f8f8;
  }
`;

export const CellTitle = styled(Layout.CellTitle)`
  ${baseText}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .checkbox__header {
    display: flex;
    justify-content: center;
    padding-left: 26px;
  }
  .checkbox-icon {
    width: 15px;
    height: 15px;
    left: calc(50% - calc(13px / 2));
    transform: translateY(-50%) translateX(-50%);
    border: 1px solid #e0e0e0;
    background-image: url(${CDN_URL}/images/checked_white.svg);
    border-radius: 2px;
  }
  .evf-checkbox {
    padding-left: 0;
  }
  ${props =>
    props.isHasSelectedPrev &&
    css`
      .checkbox-icon {
        width: 15px;
        height: 15px;
        left: calc(50% - calc(13px / 2));
        background-size: calc(15px / 2);
        background-image: none;
      }
      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    `}
`;
