/**
 * @flow
 */
export const TOGGLE_SIDE_NAV: TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';

export const toggleSideNav = (visible: boolean) => {
  return {
    type: TOGGLE_SIDE_NAV,
    visible,
  };
};
