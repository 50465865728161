import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, round } from 'lodash';
// import actions
import { getReportMacrosDaily } from 'actions/marcos';
import DailyReportStyles from './styles';

const listNutriData = [
  { title: 'Protein', param: 'protein_calories', color: '#57B5EA' },
  { title: 'Carbs', param: 'carbs_calories', color: '#65CC9C' },
  { title: 'Fat', param: 'fat_calories', color: '#FFBE49' },
  { title: 'Calories', param: 'calories', color: '#7672F0' },
];
const MFPPattern = /^Meal [0-9]{1,}/;

const DailyReport = ({ clientId, day, getReportMacrosDaily, reportData }) => {
  let mealIndex = 1;

  return (
    <DailyReportStyles>
      <div className="overview-section">
        {listNutriData.map((nutri, index) => (
          <>
            <div className="nutri-section">
              <div className="title">
                <div className="nutri-name">{nutri.title}</div>
                {get(reportData, 'mealSummary') && (
                  <div className="total-calo">
                    <span className="current">
                      {get(reportData, `mealSummary.value.${nutri.param}`)
                        ? round(get(reportData, `mealSummary.value.${nutri.param}`))
                        : 0}
                    </span>{' '}
                    /{' '}
                    <span>
                      {get(reportData, `goal.value.${nutri.param}`)
                        ? `${round(get(reportData, `goal.value.${nutri.param}`))} Cal`
                        : '0 Cal'}
                    </span>
                  </div>
                )}
              </div>
              <div className="process-bar">
                {get(reportData, `goal.value.${nutri.param}`) && (
                  <span
                    className="protein"
                    style={{
                      width: `${round(
                        (get(reportData, `mealSummary.value.${nutri.param}`) * 100) /
                          get(reportData, `goal.value.${nutri.param}`),
                      )}%`,
                      background: nutri.color,
                    }}
                  ></span>
                )}
              </div>
            </div>
            {index !== listNutriData.length - 1 && <div className="divide-div" />}
          </>
        ))}
      </div>
      <div className="daily-table-section">
        <div className="daily-header">
          <div className="col meal-col">Meals</div>
          <div className="col nutri-col">Protein (g)</div>
          <div className="col nutri-col">Carbs (g)</div>
          <div className="col nutri-col">Fat (g)</div>
          <div className="col kcal-col">Kcal</div>
        </div>
        <div className="daily-content">
          {get(reportData, 'meals[0]') &&
            reportData.meals.map(meal => {
              const isMFPMeal = MFPPattern.test(meal.title);
              return (
                <div className="row">
                  <div className="col meal-col">{isMFPMeal ? `Meal ${mealIndex++}` : meal.title}</div>
                  <div className="col nutri-col">{round(meal.value.protein_gram)}</div>
                  <div className="col nutri-col">{round(meal.value.carbs_gram)}</div>
                  <div className="col nutri-col">{round(meal.value.fat_gram)}</div>
                  <div className="col kcal-col">{round(meal.value.calories)}</div>
                </div>
              );
            })}
        </div>
      </div>
    </DailyReportStyles>
  );
};

export default connect(null, { getReportMacrosDaily })(DailyReport);
