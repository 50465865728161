import React from 'react';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import * as S from './style';
import { TimeOptions } from 'components/InAppMessage/constants';
import { CDN_URL } from 'constants/commonData';
import { DropdownIndicator } from 'shared/Icons';
import SelectDayCalendar from 'shared/SelectDayCalendar';

const offset = { left: -65 };

export default props => {
  const { showError, subject, day, time, totalWeek, onChangeScheduleDay, disabled } = props;

  return (
    <S.Wrapper className="inAppMessageForm__schedule">
      {disabled ? (
        <div className="disable-editting">
          Sending on <span style={{ fontWeight: 600 }}>Day {day + 1}</span> at{' '}
          <span style={{ fontWeight: 600 }}>{time.label}</span>
        </div>
      ) : (
        <>
          {' '}
          <S.FormGroup key="title" className={showError && !subject.trim() ? 'error' : ''}>
            <div className="label">
              <span>Title</span>
              <img
                src={`${CDN_URL}/images/new_info.svg`}
                width="14"
                data-tip
                data-for="tooltip--message-title"
                alt=""
              />
              <ReactTooltip
                id="tooltip--message-title"
                effect="solid"
                place="top"
                className="app-tooltip announcement-tooltip"
              >
                <span>
                  The title is only visible to you and meant to help remind what the scheduled messages are about.
                </span>
              </ReactTooltip>
            </div>
            <div className="controls">
              <input
                className="message__title"
                placeholder="Add a name for this message sequence"
                value={subject}
                onChange={props.onUpdateTitle}
              />
            </div>
          </S.FormGroup>
          <S.FormGroup key="time">
            <div className="label">Send on</div>
            <div className="controls">
              <SelectDayCalendar
                value={day}
                totalWeek={totalWeek}
                onChange={onChangeScheduleDay}
                placement="bottom"
                offset={offset}
                singleDay
              />
              <Select
                options={TimeOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                placeholder="-"
                menuPosition="fixed"
                onChange={props.onSelectTime}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                value={time}
                isSearchable={false}
                styles={{
                  control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 106 }),
                  menu: base => ({ ...base, marginTop: 2, minWidth: 120 }),
                }}
              />
            </div>
          </S.FormGroup>
        </>
      )}
    </S.Wrapper>
  );
};
