import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { Form } from 'shared/ModalSingleField/style';
import ArrowIconURL from 'assets/icons/arrow_corner_icon.svg';

export const AddNewFormModal = styled(Modal)`
  &.ui.modal {
    width: 560px;
    height: initial;
    border-radius: 8px;
    .close-button {
      right: -12px;
      top: -12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    > :last-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .header {
    color: #151619 !important;
    background: none !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    padding-top: 24px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 0px !important;
    border-radius: 0;
    letter-spacing: -0.02em;
  }
  .content {
    background: none !important;
    padding: 20px 24px 24px !important;
    border-radius: 0;
    ${Form} {
      margin-bottom: 46px;
      .client_type_field {
        margin-top: 5px;
      }
      .client-profile-label {
        color: #9a9da6;
        font-weight: 700;
        font-size: 9px;
        line-height: 12px;
        padding-left: 0;
        letter-spacing: 0.01em;
        text-transform: uppercase;
      }
      .ownership-dropdown {
        position: relative;
        min-height: auto;
        height: 40px;
        border-radius: 4px !important;
        .text {
          color: #4a4d56;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
        }
        .active .text {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.95);
        }
        .dropdown {
          &.icon {
            position: absolute;
            padding: 0;
            top: calc(50% + 7px);
            padding-right: 13px;
            &::before {
              content: '';
              display: block;
              width: 6.7px;
              height: 4.6px;
              background-image: url(${ArrowIconURL}) !important;
              background-size: contain !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
            }
          }
        }
      }
    }
  }
  .formControl {
    margin-bottom: 0;
    > input {
      ${props =>
        props.inputHeight &&
        css`
          height: ${props.inputHeight}px;
        `}
      &::placeholder {
        color: #bebebe;
      }
    }
    > div {
      color: #9a9da6;
      font-weight: 700;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.01em;
      margin-bottom: 1px;
      .label__maxLength {
        color: #6a778a;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  .cancel,
  .submit {
    min-width: 80px;
  }
  .submit {
    padding: 10px 27px !important;
    &:hover,
    &:active,
    &:focus {
      background: #5559ff;
    }
  }
  .cancel {
    color: #151619;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    padding: 10px 34px !important;
  }
`;
