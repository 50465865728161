import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { FoodJournalsFallBackWrapper, TeammatesFallBackWrapper } from '../style';
import { Button } from 'shared/FormControl';
import { axiosInstance } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

export default function FoodJournalsFallBack(props) {
  const [transfering, setTransfering] = useState(false);
  const [hasClientTransfer, setHasClientTransfer] = useState(false);

  useEffect(() => {
    axiosInstance
      .post('/api/team/check-client-count')
      .then(response => {
        const { data } = response.data;
        setHasClientTransfer(data.count > 0);
      })
      .catch(() => {});
  }, []);

  const handleTransfer = () => {
    setTransfering(true);
    toast('Transfer of clients has begun. It may take a few minutes to complete.');
    axiosInstance
      .post('/api/team/transfer-all-client-to-team-owner')
      .then(response => {
        setTimeout(() => {
          setHasClientTransfer(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setTransfering(false);
        }, 1000);
      });
  };

  return (
    <TeammatesFallBackWrapper>
      <FoodJournalsFallBackWrapper>
        <div className="fallback__content">
          <div className="fallback__contentTitle">Let’s grow your team</div>
          <div className="fallback__contentDescription">
            Bring your team together, and help them collaborate better with shared resources. Deliver branded training
            materials to clients, and gather insight all in one place.
          </div>
          <UpgradePathButton />
        </div>
        <div className="fallback__image">
          <img src={`${CDN_URL}/images/upgrade_path_teammates.png`} alt="upgrade_path_program.png" />
        </div>
      </FoodJournalsFallBackWrapper>
      {hasClientTransfer ? (
        <div className="teammateFallBack__transferClient">
          <div className="teammateFallBack__transferClient--text">
            We noticed you have some teammates with clients. Would you like to transfer their clients to you?
          </div>
          <div>
            <Button
              className="teammateFallBack__transferClient--button"
              onClick={handleTransfer}
              disabled={transfering}
            >
              <span>Transfer clients</span>
              {transfering ? '...' : ''}
            </Button>
          </div>
        </div>
      ) : null}
    </TeammatesFallBackWrapper>
  );
}
