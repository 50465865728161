import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';
import {
  SupersetContainer,
  SupersetHeader,
  SupersetTitle,
  TrainingSetContainer,
  SupersetExtendInfo,
  Description,
  UseORM,
  SetType,
} from '../style';
import { isUseORM } from 'helpers/exercise';
import FieldTitle from './FieldTitle';
import { roundTwoDigit } from 'helpers/number';
import { convertSecondToTimeString, convertSecondToTimeStringForRest } from 'helpers/time';
import { SET_TYPES } from 'constants/commonData';

const Superset = ({ supersetData, systemFields }) => {
  const fieldsObject = useMemo(() => {
    const result = [];
    const restField = _.find(systemFields, f => f.unique_code === 'rest');

    _.forEach(supersetData.exercise_instance.fields, fId => {
      const obj = _.find(systemFields, o => o._id === fId);

      if (obj) {
        result.push(obj);
      }
    });

    if (!_.find(result, f => f.unique_code === 'rest') && restField) {
      result.push(restField);
    }

    return result;
  }, [supersetData.exercise_instance.fields, systemFields]);

  const useORM = useMemo(() => isUseORM(_.get(supersetData, 'exercise_instance.fields', []), systemFields), [
    supersetData,
    systemFields,
  ]);

  const exerciseId = _.get(supersetData, 'exercise._id');

  const renderFieldValue = (fieldData, setData) => {
    let displayValue = '';
    const { value } = setData[fieldData.unique_code] || { value: '' };

    switch (fieldData.data_type) {
      case 'int':
        displayValue = Number(value) ? parseInt(value) : value || '-';
        break;

      case 'time':
        displayValue =
          fieldData.unique_code === 'rest'
            ? convertSecondToTimeStringForRest(value) || '00:00'
            : convertSecondToTimeString(value) || '00:00:00';
        break;
      default: {
        if (fieldData.unique_code === 'weight' && useORM) {
          const ormValue = Number(_.get(setData, 'orm.value') || 0);
          displayValue = ((Number(_.get(supersetData, 'one_rep_max.value')) || 0) * ormValue) / 100;
        } else {
          displayValue = roundTwoDigit(parseFloat(value));
        }

        break;
      }
    }

    return !displayValue && displayValue !== 0 ? '-' : displayValue;
  };

  const renderSetType = (value, index) => {
    const obj = SET_TYPES.find(item => item.value === value);

    if (obj) {
      return <SetType className={value}>{obj.label}</SetType>;
    }

    return <SetType>{index + 1}</SetType>;
  };

  return (
    <SupersetContainer>
      <SupersetHeader>
        <ExerciseMediaPreview exercise={supersetData.exercise || {}} size={{ width: 39, height: 39 }} />
        <SupersetTitle>{_.get(supersetData, 'exercise.title')}</SupersetTitle>
        {useORM && <UseORM>Use %</UseORM>}
      </SupersetHeader>
      <TrainingSetContainer>
        <table key="table-header" compact="true">
          <thead>
            <tr>
              <th key="set">Set</th>
              {fieldsObject.map(field => (
                <FieldTitle exerciseId={exerciseId} useORM={useORM} field={field} key={field._id} />
              ))}
            </tr>
          </thead>
        </table>
        <table key="table-body" compact="true" className="table--body">
          <tbody>
            {supersetData.training_sets.map((set, index) => {
              return (
                <tr key={set._id}>
                  <td>{renderSetType(set.set_type, index)}</td>
                  {_.map(fieldsObject, field => {
                    return <td key={`setField_${field._id}`}>{renderFieldValue(field, set)}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TrainingSetContainer>
      {supersetData.each_side || supersetData.tempo !== '0' ? (
        <SupersetExtendInfo>
          {supersetData.each_side && <div>Each Side</div>}
          {supersetData.tempo !== '0' && (
            <div>
              <span>Tempo</span>&nbsp;<span>{supersetData.tempo}</span>
            </div>
          )}
        </SupersetExtendInfo>
      ) : null}
      {supersetData.note && <Description>{supersetData.note}</Description>}
    </SupersetContainer>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      exercise: { fields },
    },
  } = state;

  return {
    systemFields: fields,
  };
};

export default connect(mapStateToProps)(Superset);
