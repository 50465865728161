import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  ${props =>
    props.isTrialPackage &&
    css`
      padding: 15px 10px 20px;
      line-height: 150%;

      .cancel__description {
        padding-bottom: 0px !important;
      }
    `}

  .cancel__description {
    padding-bottom: 15px;
    &.isOneTime {
      padding-bottom: 0;
    }
  }

  .evf-checkbox-circle {
    padding-bottom: 10px;
  }
`;

export const TrialText = styled.div``;
