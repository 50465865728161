import React from 'react';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';

import CustomOption from './CustomOption';
import CustomMultiValueLabel from './CustomMultiValueLabel';
import CustomControl from './CustomControl';

import * as S from './styles';

function CustomSelect(props) {
  const { onChange, name, value, options, placeholderInput, emptyMessage, emptySearchMessage, disabled } = props;
  if (disabled && isEmpty(value)) {
    return (
      <S.CardContentWrapper>
        <S.NotGroups>{emptyMessage}</S.NotGroups>
      </S.CardContentWrapper>
    );
  }
  return (
    <Select
      isMulti
      isSearchable
      onChange={onChange}
      name={name}
      value={value}
      isClearable={false}
      options={options}
      placeholder={placeholderInput}
      isDisabled={disabled}
      isOptionDisabled={disabled}
      styles={customStyles}
      classNamePrefix="group-tag"
      className="select-group-tag"
      noOptionsMessage={() => emptySearchMessage}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.name}
      menuPlacement="auto"
      components={{
        DropdownIndicator: null,
        Option: CustomOption,
        MultiValueLabel: CustomMultiValueLabel,
        Control: CustomControl,
      }}
    />
  );
}

export default CustomSelect;

export const customStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#ffffff',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '46px',
    maxHeight: '117px',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FFFFFF',
    '.scroll-container': {
      flex: 1,
      maxHeight: '115px',
      overflow: 'hidden',
    },
    '.scroll-wrapper': {
      flex: 1,
      flexWrap: 'wrap',
      overflow: 'auto',
      maxHeight: '117px',
      maxWidth: 'calc(100% - 3px * 2)',
      margin: '0 auto',
      paddingTop: state.getValue().length > 1 ? '16px' : '8px',
      paddingBottom: state.getValue().length > 1 ? '16px' : '8px',
    },
    '.scroll-wrapper::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '.scroll-wrapper::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  input: (baseStyle, state) => ({
    ...baseStyle,
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '4px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    maxHeight: '150px',
    paddingRight: '4px',
    marginRight: '4px',
    '::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: '#ffffff',
    ':hover': {
      backgroundColor: '#F2F2F2',
    },
    cursor: 'pointer',
    maxHeight: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #DCDCDE',
    gap: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '11px',
    ':last-child': {
      borderBottom: 'unset',
    },
    '.group-icon': {
      minWidth: '26px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '.total-clients': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#8D8C92',
    },
    '.group-content': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '.group-name': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#111111',
      textOverflow: 'ellipsis',
    },
    '.group-name strong': {
      fontWeight: 700,
    },
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: 'rgba(50, 60, 71, 0.3)',
    userSelect: 'none',
    marginLeft: 'unset',
    marginRight: 'unset',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '12px',
    gap: '8px',
    cursor: 'text',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8D8C92',
    userSelect: 'none',
  }),
  multiValue: (baseStyle, state) => ({
    ...baseStyle,
    background: state.isDisabled ? '#E9ECEE' : '#E7E7FD',
    borderRadius: '4px',
    margin: 'unset',
  }),
  multiValueLabel: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '12px',
    strong: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '13px',
    },
    padding: 'unset',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '7px',
    paddingRight: state.isDisabled ? '7px' : 'unset',
    color: '#444444',
    borderRadius: 'unset',
  }),
  multiValueRemove: (baseStyle, state) => ({
    ...baseStyle,
    color: '#5D5BBE',
    display: state.isDisabled ? 'none' : 'flex',
    cursor: 'pointer',
    ':hover': {
      color: '#5D5BBE',
      background: '#E7E7FD',
    },
  }),
};
