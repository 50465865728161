import get from 'lodash/get';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { Button } from 'shared/FormControl';
import { ReactComponent as Retry } from 'assets/icons/retry_charge_confirm_icon.svg';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { retryCharge } from 'redux/package-analytics/actions';
import { CDN_URL } from 'constants/commonData';

import * as S from './styles';

const RetryChargePopup = ({ onClose, retryCharge, invoice, completedCallback, isMP }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleRetryCharge = async () => {
    if (disabled) {
      return;
    }
    setLoading(true);
    try {
      const response = await retryCharge(
        {
          id: invoice.order_id,
          invoice: invoice.stripe_invoice_id,
          isTrial: get(invoice, 'status') === 'failed' && get(invoice, 'price.enable_trial'),
        },
        isMP,
      );
      if (response.statusCode >= 400) {
        toast.error(response.message);
        setLoading(false);
        setDisabled(true);
        return;
      } else {
        toast('Payment success');
        onClose();
        // TODO
        setTimeout(() => {
          completedCallback && completedCallback({ id: get(invoice, 'package.id') });
        }, 1500);
      }
    } catch {
      setLoading(false);
      setDisabled(true);
      toast.error('Payment Failed');
    }
  };

  return (
    <S.ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <ModalLayout.Header className="header">
        <Retry />
        <S.ModalTitle>Retry Credit Card</S.ModalTitle>
      </ModalLayout.Header>
      <Modal.Content>
        <ModalLayout.Wrapper>
          <S.ModalDesc>
            <p>
              We will attempt to charge the credit card on file for the full invoice amount. Please check with your
              client to make sure their card is valid.
            </p>
            <p>This action can be taken once a day.</p>
          </S.ModalDesc>
        </ModalLayout.Wrapper>
      </Modal.Content>
      <ModalLayout.Actions className="footer">
        <Button className="empty" onClick={onClose}>
          Cancel
        </Button>
        <Button
          purple
          onClick={handleRetryCharge}
          className={`confirm ${disabled && 'disabled'}`}
          data-tip
          data-for="retry-charge-tooltip"
        >
          {loading ? <Loader /> : 'Charge credit card'}
        </Button>
        {disabled && (
          <ReactTooltip id="retry-charge-tooltip" effect="solid" place={'top'} delayShow={300}>
            Charge attempted today. Please
            <br /> try again tomorrow.
          </ReactTooltip>
        )}
      </ModalLayout.Actions>
    </S.ModalWrapper>
  );
};

export default connect(null, { retryCharge })(RetryChargePopup);
