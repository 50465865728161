import styled from 'styled-components';

export const Heading = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f4f5f9;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 30px;
`;

export const ChartWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const NoData = styled.div`
  color: #000000;
  opacity: 0.3;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-align: right;
      text-transform: uppercase;
      color: #6a778a;
      padding: 15px 0px;

      :first-child {
        text-align: left;
      }
    }
  }
  tr {
    :not(:last-child) {
      border-bottom: 1px solid #e7eaf870;
    }

    td {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: right;
      color: #202353;
      padding: 15px 0px;

      :first-child {
        text-align: left;
      }

      &.actual {
        font-weight: 600;
      }
    }
  }
`;

export const MacroItem = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #202353;
  padding-left: 15px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #ffbe49;
    top: 50%;
    transform: translateY(-50%);
  }

  &.protein {
    ::before {
      background-color: #57b5ea;
    }
  }
  &.carbs {
    ::before {
      background-color: #65cc9c;
    }
  }
  &.fat {
    ::before {
      background-color: #ffbe49;
    }
  }
`;
