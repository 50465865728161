import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import SchedulePost from './component';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { editPost, getGroupDetail, hideScheduleBanner, saveScheduleTemp } from 'redux/forum/actions';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const checkAddOns = rootReducer.pricing.getIn(['teamData']).toJS();

  return {
    user,
    group: rootReducer.forum.group,
    connected_autoflow: rootReducer.forum.group.autoflow,
    members: rootReducer.forum.members,
    formDataSchedule: rootReducer.forum.formDataSchedule,
    bannerStatus: get(rootReducer, 'forum.group.is_hide_schedule_post_banner', true),
    enabledAddons: rootReducer.pricing.getIn(['teamData']).toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    saveScheduleTemp: bindActionCreators(saveScheduleTemp, dispatch),
    hideScheduleBanner: bindActionCreators(hideScheduleBanner, dispatch),
    getGroupDetail: bindActionCreators(getGroupDetail, dispatch),
    editPost: (formData, postId) => dispatch(editPost(formData, postId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchedulePost));
