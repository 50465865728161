import { get } from 'lodash';
import { Types } from './actions';

export const SORTER = {
  NAME: 'name',
  SUBMITTED_DATE: 'submitted_date',
};

export const SORT = {
  ASC: 1,
  DESC: -1,
};

export const PER_PAGE_DOCUMENTS = 20;

export const INITIAL_STATE = {
  list: [],
  loading: false,
  query: {
    page: 1,
    per_page: PER_PAGE_DOCUMENTS,
    text_search: '',
    sort: SORT.DESC,
    sorter: SORTER.SUBMITTED_DATE,
    total: 0,
  },
  onboardingFormList: [],
  isFirstLoad: true,
  loadingPrint: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_CLIENT_DOCUMENTS_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.GET_CLIENT_DOCUMENTS_SUCCESS: {
      return Object.assign({}, state, {
        list: get(payload, 'data', []),
        loading: false,
        query: {
          ...state.query,
          total: state.query.page === 1 && state.isFirstLoad ? get(payload, 'total', 0) : state.query.total,
        },
      });
    }
    case Types.GET_CLIENT_DOCUMENTS_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.CLIENT_DOCUMENTS_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...state.query, ...payload } });
    case Types.CLIENT_DOCUMENTS_RESET_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: INITIAL_STATE.query, isFirstLoad: true });
    case Types.GET_ONBOARDING_FLOW_FORM_SUCCESS: {
      return Object.assign({}, state, {
        onboardingFormList: payload,
        query: { ...state.query, total: state.query.total + payload.length },
        isFirstLoad: false,
      });
    }
    case Types.EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_REQUEST: {
      return Object.assign({}, state, { loadingPrint: true });
    }

    case Types.EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_SUCCESS:
    case Types.EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_FAILED: {
      return Object.assign({}, state, { loadingPrint: false });
    }
    default:
      return state;
  }
};
