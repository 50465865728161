import classnames from 'classnames';
import { THEMES } from 'components/CustomBranding/constants';
import OwnershipPopup from 'components/OwnershipPopup';
import SelectSection from 'components/SelectSection';
import { HIDDEN_SECTION, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { workoutDiscardFilter } from 'utils/commonFunction';
import { ENTITY_NAME } from 'database/constants';
import { diff } from 'deep-diff';
import _ from 'lodash';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import ConfirmModal from 'shared/ConfirmModal';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { ErrorMessage } from 'shared/FormControl';
import TextEditable from 'shared/TextEditable';
import { WorkoutContext } from './components/contexts';
import DragContainer from './components/DragContainer';
import LinkSet, { LINK_TYPES } from './components/LinkSet';
import Section from './components/Section';
import { OptionIcon } from './components/styles';
import * as S from './style';

const SectionWrapper = props => {
  const { sectionId, nextSectionId, sectionIndex, sectionSize, isDragMode, onIsChanging, sectionFormat } = props;

  const [, type] = sectionId.split('-');
  let linkable = false;

  if (sectionSize > 1 && sectionIndex < sectionSize - 1 && type === HIDDEN_SECTION) {
    const [, nextSectionType] = nextSectionId.split('-');
    linkable = nextSectionType === HIDDEN_SECTION;
  }

  return (
    <React.Fragment>
      <Section
        sectionId={sectionId}
        linkable={linkable}
        sectionIndex={sectionIndex}
        sectionSize={sectionSize}
        onIsChanging={onIsChanging}
        sectionFormat={sectionFormat}
      />
      {linkable && !isDragMode && (
        <LinkSet type={LINK_TYPES.LINK} sectionId={sectionId} linkIndex={sectionIndex} onIsChanging={onIsChanging} />
      )}
    </React.Fragment>
  );
};

const LIMIT_LENGTH = 90;

class MainPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingSection: false,
      scrolling: false,
      workoutBackground: {
        src: '',
      },
      originalWorkout: props.workout.toJS(),
      discard: false,
      exerciseReferences: [],
      removeAllExerciseState: false,
      selectFromLib: false,
    };
    this.headerRef = React.createRef();
    this.titleRef = React.createRef();
    this.actionFixedRef = React.createRef();
    this.contentRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.onScrollDebounce = _.debounce(this.handleScrollContent, 100);
  }

  componentDidMount() {
    const { workout, getMostRecentExercises } = this.props;
    if (!workout.get('title') && this.titleRef.current) {
      this.titleRef.current.focus();
    }
    getMostRecentExercises && getMostRecentExercises();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //Discard changes
    const { currentWorkoutData } = nextProps;
    const { originalWorkout, onIsChanging } = this.props;
    const workoutsChanged = currentWorkoutData.toJS();
    const originWorkouts = originalWorkout ? originalWorkout.toJS() : { sections: [] };

    // Original workouts to compare
    let workouts = originWorkouts;
    let unsetArr = [
      'author',
      'author_name',
      'video_only',
      'updatedAt',
      'last_interacted',
      'tags',
      'alternatives',
      'search_synonym',
      'synonyms',
      'training_sets',
      'primary_muscle_group',
      'primary_movement_pattern',
      'equipment',
      'modality',
      'movement_patterns',
      'muscle_groups',
    ];
    workoutDiscardFilter(workouts, unsetArr);

    // New workouts to compare
    let newWorkout = workoutsChanged;
    workoutDiscardFilter(newWorkout, unsetArr);

    // only run when component is first mounted
    if (this.state.workoutBackground.src === '') {
      const workoutLevelBackground = _.get(nextProps, 'workoutData.background', false);
      // If opening an existing background, will get background from workout data. Otherwise, will get default workout background set in settings
      if (workoutLevelBackground) {
        this.setState({
          workoutBackground: {
            src: workoutLevelBackground,
          },
        });
      } else {
        this.setState({
          workoutBackground: _.get(nextProps, 'defaultWorkoutBackground', THEMES[0]), // If no default background selected, choose the purple background
        });
      }
    }
    // Check changing
    if (diff(workouts, newWorkout)) {
      onIsChanging && onIsChanging(true);
      this.setState({ discard: true });
    } else {
      onIsChanging && onIsChanging(false);
      this.setState({ discard: false });
    }
  }

  descriptionObserverCallback = entities => {
    const element = entities[0];
    const { isIntersecting } = element;
    this.scrollCallbackDebounce(isIntersecting);
  };

  handleScrollCallback = descriptionIntersecting => {
    this.setState({ scrolling: !descriptionIntersecting });
  };

  handleToggleAction = isIntersecting => {
    if (this.actionFixedRef.current) {
      this.actionFixedRef.current.style.visibility = isIntersecting ? 'hidden' : 'visible';
    }
  };

  handleAddSection = () => {
    this.setState({ isAddingSection: true }, this.scrollToBottom);
    this.props.resetWorkoutError();
  };

  handleCancelAddSection = () => {
    this.setState({ isAddingSection: false });
  };

  handleAddSectionSuccess = () => {
    this.setState({ isAddingSection: false }, this.scrollToBottom);
  };

  handleChangeOwner = (event, data) => {
    const { workout } = this.props;
    const newWorkoutData = {
      author: workout.get('author') ? workout.get('author').toJS() : {},
      share: workout.get('share'),
    };

    newWorkoutData.author._id = data.value;

    if (data.value == 'no_owner') {
      newWorkoutData.author._id = null;
      newWorkoutData.share = TEAM_SHARE_NOOWNER;
    } else if (newWorkoutData.share === TEAM_SHARE_NOOWNER) {
      newWorkoutData.share = TEAM_SHARE_PRIVATE;
    }

    this.props.updateWorkoutPartialData(newWorkoutData);
  };

  handleChangeShare = (event, data) => {
    this.props.updateWorkoutPartialData({ share: data.value });
  };

  handleSaveToLibrary = () => {
    if (typeof this.props.saveToLibrary === 'function') {
      this.props.saveToLibrary(this.props.workout.toJS());
    }
  };

  handleSaveAsCopy = () => {
    if (typeof this.props.saveAsCopy === 'function') {
      this.props.saveAsCopy();
    }
  };

  handleDelete = () => {
    const { workout, replace, toggleModal, toggleConfirmModal, removeSectionLibrary } = this.props;
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Section"
        content="Are you sure that you want to delete this section?"
        onConfirm={() => {
          if (typeof removeSectionLibrary === 'function') {
            removeSectionLibrary(workout.get('_id'), () => {
              toggleModal(false);
              replace('/home/sections');
            });
          }
        }}
      />,
    );
  };

  handleLogWorkout = (isChanging, discard) => {
    const { onCheckField, logWorkout, onIsChanging } = this.props;

    if (typeof this.props.logWorkout === 'function') {
      if (discard || isChanging) {
        onCheckField(true);
        return;
      }
      logWorkout(this.props.originalWorkout.toJS());
    }
  };

  handleExportPDF = async () => {
    const { workoutData, pdfType, currentUserId } = this.props;
    this.props.getPdfRequest();
    await this.props.getPdf(workoutData, pdfType, currentUserId);
    this.props.getPdfFinish();
  };

  scrollToBottom = () => {
    if (this.contentRef.current) {
      setTimeout(() => {
        this.contentRef.current.scrollTo(0, this.contentRef.current.scrollHeight);
      }, 0);
    }
  };

  handleOnChangeBackground = data => {
    this.setState({ workoutBackground: { ...data } });
    this.props.updateBackground(data.src);
  };

  handleScrollContent = () => {
    if (this.contentRef.current && this.descriptionRef.current) {
      const { scrollTop } = this.contentRef.current;
      if (scrollTop > 0) {
        this.setState({
          scrolling: true,
        });
      } else {
        this.setState({
          scrolling: false,
        });
      }
    }
  };

  handleApplyTags = data => {
    const { updateTags } = this.props;
    updateTags(data.tags);
  };

  onUpdateAttachments = attachments => {
    this.props.updateEntity(ENTITY_NAME.Section, this.props.sectionId, { attachments });
  };

  onUpdatePrefrenceExercise = exercise => {
    let exerciseReferences = _.map(exercise, item => item);
    this.props.updateEntity(ENTITY_NAME.Section, this.props.sectionId, { exerciseReferences });
  };

  onSelectSection = data => {
    this.props.adddSectionFromLibrary(data);
  };

  handleOpenChooseFromLibrary = () => {
    this.setState({
      selectFromLib: true,
    });
  };

  handleChangeName = newValue => {
    if (newValue.trim().length <= LIMIT_LENGTH) {
      this.props.updateTitle(newValue);
    } else {
      if (newValue.trim().length === LIMIT_LENGTH + 1) {
        this.props.updateTitle(this.props.workout.get('title').trim().slice(0, LIMIT_LENGTH));
      } else {
        this.props.updateTitle(newValue.trim().slice(0, LIMIT_LENGTH));
      }
    }
  };

  render() {
    const {
      workout,
      draggingItemLeft,
      showError,
      currentUserId,
      isHideLeftPanel,
      firstLinkSupersetId,
      readOnly,
      onIsChanging,
      sectionFormat,
      isTrainer,
    } = this.props;
    const { isAddingSection, scrolling } = this.state;
    const sectionSize = workout.get('sections').size;
    const isDragMode = !!draggingItemLeft;
    const checkDelete =
      !isTrainer || workout.getIn(['author', '_id']) === currentUserId || workout.get('share') === TEAM_SHARE_NOOWNER;

    return (
      <WorkoutContext.Provider value={{ firstLinkSupersetId }}>
        <S.Wrapper className={classnames('workoutBuilder__mainPanel', { scrolling: scrolling })}>
          <S.Header className="mainPanel__header">
            <S.WrapTextEditable>
              <TextEditable
                ref={this.titleRef}
                value={workout.get('title')}
                onChange={this.handleChangeName}
                placeholder="Name your section"
                lineHeight={27}
                invalid={!workout.get('title') && showError}
                maxLength={LIMIT_LENGTH - 1}
                resetInput={(workout.get('title') || '').trim().length === 0}
              />
              <span className={`name-limit${scrolling ? ' scrolling' : ''}`}>
                {(workout.get('title') && workout.get('title').length) || 0} / {LIMIT_LENGTH}
              </span>
            </S.WrapTextEditable>
            {!workout.get('title') && showError ? (
              <ErrorMessage marginTop="8px">Please add a section name</ErrorMessage>
            ) : null}
            <S.ExtendActions className="workoutBuilder__extendActions">
              <OwnershipPopup
                isEditAble={
                  workout.get('share') === TEAM_SHARE_NOOWNER || workout.getIn(['author', '_id']) === currentUserId
                }
                owner={workout.getIn(['author', '_id']) || currentUserId}
                share={workout.get('share') || TEAM_SHARE_PRIVATE}
                handleChangeOwner={this.handleChangeOwner}
                handleChangeShare={this.handleChangeShare}
                isTrainer={isTrainer}
                hasYou
              />
              {workout.get('_id') && (
                <div data-tip data-for="section-more-options-tooltip" className="dropdown-wrapper">
                  <Dropdown className="actions">
                    <Option key="saveAsCopy" onClick={this.handleSaveAsCopy}>
                      <OptionIcon icon="floppy-disk" darkMode>
                        Save as Copy
                      </OptionIcon>
                    </Option>
                    {!readOnly
                      ? checkDelete && (
                          <Option key="delete" onClick={this.handleDelete}>
                            <OptionIcon icon="delete" darkMode>
                              Delete
                            </OptionIcon>
                          </Option>
                        )
                      : null}
                  </Dropdown>
                  <ReactTooltip
                    className="app-tooltip-white custom-arrow"
                    id="section-more-options-tooltip"
                    effect="solid"
                    place="top"
                  >
                    <div>More options </div>
                  </ReactTooltip>
                </div>
              )}
            </S.ExtendActions>
          </S.Header>
          <S.Content className="mainPanel__content" ref={this.contentRef} onScroll={this.onScrollDebounce}>
            <div ref={this.descriptionRef}></div>
            {this.state.selectFromLib && (
              <SelectSection
                onSelect={this.onSelectSection}
                open={true}
                newUI
                onClose={() => {
                  this.setState({
                    selectFromLib: false,
                  });
                }}
              />
            )}

            <S.SectionListContainer className="mainPanel__sections">
              {!!sectionSize &&
                workout
                  .get('sections')
                  .map((sectionId, index) => (
                    <SectionWrapper
                      key={sectionId}
                      sectionIndex={index}
                      sectionSize={sectionSize}
                      sectionId={sectionId}
                      nextSectionId={workout.get('sections').get(index + 1)}
                      isDragMode={isDragMode}
                      onIsChanging={onIsChanging}
                      sectionFormat={sectionFormat}
                    />
                  ))}
              {!isAddingSection && !sectionSize && (
                <DragContainer noMargin={!sectionSize && showError} isHideLeftPanel={isHideLeftPanel} />
              )}
              {!sectionSize && showError ? (
                <ErrorMessage className="workoutError--noExercise">Please add at least 1 exercise</ErrorMessage>
              ) : null}
            </S.SectionListContainer>
          </S.Content>
        </S.Wrapper>
      </WorkoutContext.Provider>
    );
  }
}

export default MainPanel;
