import React from 'react';
import { get } from 'lodash';
import Avatar from 'react-avatar';

import { autoflowTypeParse, convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import { ReactComponent as AutoflowSetting } from 'assets/icons/asset-autoflow-setting-icon.svg';

export default function AutoflowOption({ data, user, cloudfrontList }) {
  const isSettingTurnedOff = get(data, 'isSettingTurnedOff', false);
  const shouldRenderAvatar = user._id !== data.author._id;
  return (
    <div className="asset-content">
      <div className="autoflow-info-container title-container">
        <div className="title">{data.name}</div>
        {shouldRenderAvatar && (
          <Avatar
            className="autoflow-author-avatar"
            name={getUserShortName(data.author)}
            size="25"
            src={convertS3UrlToCloudFrontUrl(data.author.avatar, cloudfrontList, true)}
            color={data.author.color}
          />
        )}
      </div>
      <div className="autoflow-info-container">
        <div className={`autoflow-type ${data.type}`}>{autoflowTypeParse(data.type)}</div>
        {!isSettingTurnedOff && <AutoflowSetting />}
      </div>
    </div>
  );
}
