import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const textBase = `
  font-family: 'Open Sans';
  line-height: 150%;
  font-style: normal;
  color: #202353;
  text-align: left;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-width: 574px;
  height: 86px;
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  cursor: pointer;

  &:hover {
    border: 1px solid #5158cf;
  }

  ${props =>
    props.isChecked &&
    css`
      border: 1px solid #5158cf;
      background-color: #f8f8fe;
    `}

  ${props =>
    props.isDisabled &&
    css`
      background-color: #fff;
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        border: 1px solid transparent;

        .on-demand-checkbox {
          border: 1.6px solid #d7d7d7;
        }
      }
    `}
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Background = styled.img`
  width: 100px;
  height: 54px;
  border-radius: 4px;
  background-color: #fff;
  object-fit: cover;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 9px;
`;

export const Name = styled.div`
  ${textBase}
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  user-select: none;
`;

export const InfoPart = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const InforTitle = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  user-select: none;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Checkbox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border: 1.6px solid #d7d7d7;
  border-radius: 6px;

  &:hover {
    border: 1.6px solid #5158cf;
  }

  ${props =>
    props.isChecked &&
    css`
      background-color: #5258cf;
      border: none;
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        display: block
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(${CDN_URL}/images/checked_white.svg);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
      }
    `}
`;

export const InforAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const InforName = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  user-select: none;
`;

export const SeparateDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #dcdcdc;
`;
