import React, { useEffect, useMemo } from 'react';
import * as S from './style';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import { Mixpanel } from 'utils/mixplanel';

import MainForumScreen from './components/MainForumScreen';
import ForumTeaser from './components/ForumTeaser';
import CreateNewForum from './components/CreateNewForum';
import ForumTimeline from './components/ForumTimeline';
import MemberList from './components/MemberList';
import LeaderboardTab from './components/Leaderboard';
import PostDetail from './components/ForumTimeline/components/PostDetail';
import AdvancedSettings from './components/Settings/AdvancedSettings';
import {
  defaultSettingLeaderboard,
  resetForumData,
  markReadForumIndicator,
  showPostPublished,
} from 'redux/forum/actions';
import { toggleSideBar } from 'actions/sidebar';
import UpcomingTab from './components/UpcomingPost';
import useSocketSubscribeChannel from 'hooks/useSocketSubscribeChannel';

const CommunityForum = props => {
  const {
    permission,
    forumList,
    dispatch,
    selectedGroup,
    resetForumData,
    toggleSideBar,
    sideBarVisible,
    defaultSettingLeaderboard,
    location: { pathname },
    markReadForumIndicator,
    showPostPublished,
  } = props;

  const groupId = useMemo(() => {
    return _.get(props, 'location.pathname', '').replace('/home/forums/', '').split('/').shift();
  }, [props]);

  useEffect(() => {
    Mixpanel.track('view_community_page');
    if (!sideBarVisible) {
      toggleSideBar(true);
    }
  }, []);

  // Suggestion: SHOULD remove this code, because it's not meaningful (Test all case).
  useEffect(() => {
    const noSelectedGroup = forumList.length > 0 && _.isEmpty(selectedGroup) && pathname.indexOf('/discussion') === -1;

    if (permission) {
      defaultSettingLeaderboard && defaultSettingLeaderboard();
      if (noSelectedGroup) {
        dispatch(push(`/home/forums/${forumList[0]._id}/discussion`));
      } else if (!_.isEmpty(selectedGroup)) {
        dispatch(push(`/home/forums/${selectedGroup._id}/discussion`));
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      resetForumData && dispatch(resetForumData());
    };
  }, []);

  useEffect(() => {
    let groupID = _.get(props, 'location.pathname', '');
    const isUnread = _.get(forumList[0] || selectedGroup, 'isUnread', false);
    groupID = groupID.replace('/home/forums/', '').split('/').shift();

    if (!isUnread || groupID.length === 0) return;
    if (groupID === _.get(forumList[0], '_id', '') || groupID === _.get(selectedGroup, '_id', '')) {
      if (_.get(forumList, 'length') <= 0) return;
      markReadForumIndicator({ groupId: _.get(forumList[0], '_id', '') || _.get(selectedGroup, '_id', '') });
    }
  }, [_.get(forumList[0], '_id', ''), _.get(selectedGroup, '_id', '')]);

  useSocketSubscribeChannel({
    disable: _.isEmpty(groupId),
    event: 'join_room',
    channel: 'forum-post-published',
    room: 'forum',
    roomId: groupId,
    callback: post => {
      // TODO: handle data here
      if (!_.isEmpty(post)) {
        showPostPublished({ newPostPublished: post });
      }
    },
  });

  return (
    <S.Wrapper>
      <Switch>
        {!permission && <Route exact path="/home/forums" render={props => <ForumTeaser {...props} />} />}
        {permission && <Route exact path="/home/forums" render={props => <MainForumScreen {...props} />} />}
        {permission && <Route exact path="/home/forums/create-forum" render={props => <CreateNewForum {...props} />} />}
        {permission && <Route exact path="/home/forums/:id" render={props => <ForumTimeline {...props} />} />}
        {permission && (
          <Route exact path="/home/forums/:id/discussion" render={props => <ForumTimeline {...props} />} />
        )}
        {permission && (
          <Route exact path="/home/forums/:id/settings" render={props => <AdvancedSettings {...props} />} />
        )}
        {permission && (
          <Route path="/home/forums/:forumId/post-detail/:postId" render={props => <ForumTimeline {...props} />} />
        )}
        {permission && (
          <Route path="/home/forums/:forumId/post-item/:postId" render={props => <ForumTimeline {...props} />} />
        )}
        {permission && <Route exact path="/home/forums/:id/members" render={props => <MemberList {...props} />} />}
        {permission && (
          <Route exact path="/home/forums/:id/leaderboard" render={props => <LeaderboardTab {...props} />} />
        )}
        {permission && <Route exact path="/home/forums/:id/upcoming" render={props => <UpcomingTab {...props} />} />}
      </Switch>
    </S.Wrapper>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    resetForumData,
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    defaultSettingLeaderboard: bindActionCreators(defaultSettingLeaderboard, dispatch),
    markReadForumIndicator: bindActionCreators(markReadForumIndicator, dispatch),
    showPostPublished: bindActionCreators(showPostPublished, dispatch),
  };
};

const mapStateToProps = state => {
  return {
    cloudfrontList: state.cloudfrontList,
    sideBarVisible: state.sideBarVisible,
    permission: _.get(state.rootReducer, 'permission.forum'),
    forumList: state.rootReducer.forum.list,
    selectedGroup: state.rootReducer.forum.group,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityForum);
