/**
 * @flow
 */

import { TOGGLE_SIDE_NAV } from 'actions/sidenav';

import type { Action } from 'types/redux';

export const sideNavVisible = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case TOGGLE_SIDE_NAV:
      return action.visible;
    default:
      return state;
  }
};
