import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import * as S from './styles';
import { SelectFile } from 'shared/FormControl';
import { createObjectURL, mediaLog, readAsArrayBufferAsync, revokeObjectURL } from 'utils/commonFunction';
import { validateFiles } from 'utils/validations';
import FileUpload from 'shared/FileUpload';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { ReactComponent as UploadIcon } from 'assets/icons/upload_banner_icon.svg';
import { ReactComponent as EditBannerIcon } from 'assets/icons/edit_banner_icon.svg';
import { getPreSignedUrl } from 'redux/package-detail/actions';

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
};

class UploadCoverImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.maxSize = 25;
    this.validateExtentions = ['png', 'jpg', 'jpeg'];
    this.uploadConfigs = null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }
  }

  onSelectFile = async file => {
    const preSignedResp = await this.props.getPreSignedUrl(encodeURI(new Date().getTime() + file.name));

    if (!preSignedResp || !preSignedResp.data) {
      return;
    }

    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Package',
    });

    const fileBuffer = await readAsArrayBufferAsync(file);

    this.uploadConfigs = {
      url: preSignedResp.data.data,
      method: 'put',
      data: fileBuffer,
      headers: { 'Content-Type': file.type },
    };

    const uploadURL = new URL(preSignedResp.data.data);

    this.setState(
      { src: createObjectURL(file), isLocalUrl: true, file, uploadingURL: uploadURL.origin + uploadURL.pathname },
      () => {
        this.props.onStartUpload(file);
      },
    );
  };

  onDropFile = files => {
    if (files.length > 1) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: this.validateExtentions,
        maxSize: this.maxSize * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = 'File type not supported.';
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = 'Image size limit is 25MB.';
        }

        toast.error(message);
      } else {
        this.onSelectFile(file);
      }
    }
  };

  onUploadSuccess = () => {
    if (this._isMounted) {
      const { uploadingURL, file } = this.state;
      const { size, name, type } = file;
      mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success file via Package',
      });
      this.uploadConfigs = null;
      this.setState({ file: null, uploadingURL: null }, () => {
        this.props.onUploadSuccess({ src: uploadingURL });
      });
    }
  };

  onCancelUpload = () => {
    if (this._isMounted) {
      revokeObjectURL(this.state.src);
      this.uploadConfigs = null;
      this.setState({ ...DEFAULT_STATE }, () => {
        this.props.onEndUpload();
      });
    }
  };

  onRemoveImage = () => {
    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }

    this.setState({ ...DEFAULT_STATE }, () => {
      this.props.onRemove();
    });
  };

  render() {
    const { src, file } = this.state;

    if (!src && !this.props.src) {
      return (
        <Dropzone onDrop={this.onDropFile} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Wrapper {...getRootProps()} isDragging={isDragActive} className={this.props.className}>
              <SelectFile
                trigger={
                  <S.EditButton>
                    <EditBannerIcon /> Edit
                  </S.EditButton>
                }
                className="bannerImage__editBtn"
                onSelect={this.onSelectFile}
                accept="image/png, image/jpeg, image/jpg"
                validateExtentions={this.validateExtentions}
                maxSize={this.maxSize}
                fileSizeLimitMessage="Image size limit is 25MB."
              />
              <S.Placeholder isDragging className="upload__placeholder">
                <UploadIcon />
              </S.Placeholder>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </S.Wrapper>
          )}
        </Dropzone>
      );
    }

    return (
      <Dropzone onDrop={this.onDropFile} multiple={false}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <S.Wrapper
            {...getRootProps()}
            isDragging={isDragActive}
            src={src || this.props.src}
            className={this.props.className}
          >
            {!file && (
              <S.RemoveIcon
                onClick={e => {
                  e.stopPropagation();
                  this.onRemoveImage();
                }}
              />
            )}
            <SelectFile
              trigger={
                <S.EditButton>
                  <EditBannerIcon /> Edit
                </S.EditButton>
              }
              className="bannerImage__editBtn"
              onSelect={this.onSelectFile}
              accept="image/png, image/jpeg, image/jpg"
              validateExtentions={this.validateExtentions}
              maxSize={this.maxSize}
              fileSizeLimitMessage="Image size limit is 25MB."
            />
            {file ? (
              <FileUpload
                onSuccess={this.onUploadSuccess}
                onError={() => {}}
                onCancel={this.onCancelUpload}
                configs={this.uploadConfigs}
              />
            ) : (
              <S.Placeholder isDragging={isDragActive} className="upload__placeholder">
                <UploadIcon />
              </S.Placeholder>
            )}
            {!file && this.props.children}
          </S.Wrapper>
        )}
      </Dropzone>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
  getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadCoverImage);
