import React from 'react';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { MessageHeader, EndDate } from './style';
import { ANNOUNCEMENT_SCHEDULE_TYPES, CDN_URL } from 'constants/commonData';
import { ReactComponent as AnnouncementIcon } from 'assets/icons/announcement.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/announcement_clock.svg';

const ACTIONS = [
  { key: 'edit', label: 'Edit', icon: 'edit-white', action: 'onEdit' },
  { key: 'copy', label: 'Copy', icon: 'duplicate', action: 'onCopy' },
  { key: 'delete', label: 'Delete', icon: 'delete', action: 'onDelete' },
];

export default props => {
  const { message, mIndex, isPlaceholder, totalMessage } = props;
  const endDay =
    Number(message.weekIndex || 0) * 7 +
    Number(message.dayIndex || 0) +
    (message.schedule_on === ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE ? Number(message.dayDuration || 1) : 1);

  const onMoveUp = () => {
    props.onArrange(message, mIndex - 1, mIndex);
  };

  const onMoveDown = () => {
    props.onArrange(message, mIndex + 1, mIndex);
  };

  const onAction = event => {
    const { action } = event.currentTarget.dataset;

    if (typeof props[action] === 'function') {
      props[action](message);
    }
  };

  const renderActions = () => {
    return (
      <Dropdown className="autoMessage__actions">
        {mIndex > 0 ? (
          <Option onClick={onMoveUp} key="move-up">
            <img src={`${CDN_URL}/images/workout_up.svg`} width={14} style={{ marginRight: 12 }} alt="" />
            <span>Move Up</span>
          </Option>
        ) : null}
        {mIndex < totalMessage - 1 ? (
          <Option onClick={onMoveDown}>
            <img src={`${CDN_URL}/images/workout_down.svg`} width={14} style={{ marginRight: 12 }} alt="" />
            <span>Move Down</span>
          </Option>
        ) : null}
        {ACTIONS.map(item => (
          <Option key={item.key} onClick={onAction} data-action={item.action}>
            <img src={`${CDN_URL}/images/${item.icon}.svg`} width={14} style={{ marginRight: 12 }} alt="" />
            <span>{item.label}</span>
          </Option>
        ))}
      </Dropdown>
    );
  };

  return (
    <MessageHeader className="autoMessage__header">
      <div className="autoMessage__time">
        <AnnouncementIcon />
        <EndDate className="end_date">
          <ClockIcon />
          <div>End: Day {endDay}</div>
        </EndDate>
      </div>
      {isPlaceholder ? null : renderActions()}
    </MessageHeader>
  );
};
