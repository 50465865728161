import React, { useEffect, useMemo, useState } from 'react';
import { find, get } from 'lodash';
import * as S from '../styles';
import { pluralize } from 'utils/commonFunction';

const ProgressBar = ({ progress }) => (
  <S.ProgressBar progress={progress}>
    <div className="filled-bar" />
  </S.ProgressBar>
);

const generateOptionsArr = data => {
  const responds = get(data, 'summary.linears', []);
  const start = `${get(data, 'linear_start.value', 0)}`;
  const end = `${get(data, 'linear_end.value', 5)}`;
  const range = useMemo(() => parseInt(end) - parseInt(start) + 1, [start, end]);

  const rangeItems = Array.from({ length: range }, (_, i) => {
    const selectedOption = find(responds, respond => respond.value === i + parseInt(start));
    return selectedOption ? selectedOption : { value: i + parseInt(start) };
  });
  return rangeItems;
};

export default function LinearScaleSummary({ data }) {
  const options = generateOptionsArr(data);
  const [titleHeight, setTitleHeight] = useState(28); // default one line
  useEffect(() => {
    updateTitleHeight();
    window.addEventListener('resize', updateTitleHeight);
    return () => window.removeEventListener('resize', updateTitleHeight);
  }, []);
  const updateTitleHeight = () => {
    const headerElement = document.getElementById(`${data._id}-header`);
    if (headerElement) {
      setTitleHeight(headerElement.offsetHeight);
    }
  };
  return (
    <S.SingleChoiceSummary titleHeight={titleHeight}>
      {options.map(option => {
        const optionSummary = find(get(data, 'summary.linears', []), obj => obj._id === option._id);
        const optionResponses = get(option, 'no_responses', 0);
        const optionProgress = optionSummary ? (optionResponses / data.summary.no_responses) * 100 : 0;
        return (
          <div className="option" key={option.value}>
            <div className="info">
              <span>{option.value}</span>
              <div className="option-summary">
                <span className="percentage">{Math.round(optionProgress * 100) / 100}%</span>
                <div className="no-of-resp">
                  {optionResponses} {pluralize('response', optionResponses)}
                </div>
              </div>
            </div>
            <ProgressBar progress={optionProgress} />
          </div>
        );
      })}
    </S.SingleChoiceSummary>
  );
}
