import _ from 'lodash';
import validator from 'validator';
import { USER_ROLE, FILE_ERRORS, FILE_MIMETYPES, SPECIAL_CHARACTERS } from 'constants/commonData';
import { axiosInstanceWithoutToken } from 'configs/request';

const YOUTUBE_DOMAINS = ['www.youtube.com', 'www.youtu.be', 'youtu.be', 'youtube.com'];

const getYoutubeID = url => {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)|shorts\/|live\//);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
};

export const validateYouTubeUrl = url => {
  if (url !== undefined && url != '') {
    try {
      const urlObj = new URL(url);
      if (YOUTUBE_DOMAINS.indexOf(urlObj.host) !== -1) {
        const youtubeID = getYoutubeID(url);

        if (youtubeID && youtubeID.length === 11) {
          return youtubeID;
        }
      }
    } catch (e) {}
  }

  return '';
};

export const validateVimeoUrl = url => {
  if (url) {
    const publicReExp = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)/;
    const privateReExp = /^(http\:\/\/|https\:\/\/)?(www\.)?(player.vimeo\.com\/)/;

    return publicReExp.test(url) || privateReExp.test(url);
  }

  return false;
};

export const validateInstagramUrl = url => {
  if (url) {
    const regex = /(https?:\/\/(?:www\.)?instagram\.com\/([^/?#&]+)).*/i;

    return regex.test(url);
  }

  return false;
};

export const validateFacebookUrl = url => {
  if (url) {
    const regex = /(https?:\/\/)?([\w\.]*)(facebook|fb)\.com\/([a-zA-Z0-9_]+)/i;

    return regex.test(url);
  }

  return false;
};

export const validateTwitterUrl = url => {
  if (url) {
    const regex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/(?:[a-zA-Z0-9_]+\/?)?(\?.*)?$/i;

    return regex.test(url);
  }

  return false;
};

export const validateSpotifyUrl = url => {
  if (url) {
    const regex = /(https?:\/\/open.spotify.com\/(.*))/i;

    return regex.test(url);
  }

  return false;
};

export const isOnwer = user => !user.teams || user.teams.length === 0 || user.teams[0].role === USER_ROLE.OWNER;
export const isAdmin = user => !user.teams || user.teams.length === 0 || user.teams[0].role === USER_ROLE.ADMIN;
export const isTrainer = user => !user.teams || user.teams.length === 0 || user.teams[0].role === USER_ROLE.TRAINER;

export const validateFiles = ({ files, validateExtentions, maxSize }) => {
  let error = null;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const { size, name } = file;

    if (validateExtentions) {
      const fileExtension = name.split('.').pop().toLowerCase();

      if (!validateExtentions.includes(fileExtension)) {
        error = { type: FILE_ERRORS.FILE_TYPE_INVALID };
        break;
      }
    }

    if (size > maxSize) {
      error = { type: FILE_ERRORS.FILE_SIZE_LIMIT };
      break;
    }
  }

  return error;
};

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  return regex.test(String(phone).toLowerCase());
}

export function checkExistEmail(email) {
  return axiosInstanceWithoutToken.post('/api/v2/authentication/check-exist-email', { email: email.trim() });
}

export function validateOnlyNumber(value) {
  return /^[0-9]*$/.test(value + '');
}

export function validateStripeBillingDetail(data) {
  if (!data.name || !data.line1 || !data.postal_code || !data.city || (data.country === 'US' && !data.state)) {
    return false;
  }

  return true;
}

export const checkLinkFormat = url => {
  const linkRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*(\?.*)?$/i;
  return linkRegex.test(url);
};

export const findLastURLinText = text => {
  // const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const urlRegex = /\b(?:https?|ftp|file):\/\/\S+|\b(?:\w+\.)+\w+(?:\/\S*)?(?=[,.]?(?:\s|$))/gi;
  const links = text.match(urlRegex) || [];

  return links.pop();
};

export function validateLink(url) {
  // const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
  // const link = url.charAt(0).toLowerCase() + url.slice(1);
  // return link && regex.test(link);
  return validator.isURL(url);
}

export function getLinkType(link) {
  const regex = /^(https|http)(.*)/i;
  if (!regex.test(link)) {
    link = 'https://' + link;
  }
  if (validateYouTubeUrl(link)) {
    return 'youtube';
  }

  if (validateVimeoUrl(link)) {
    return 'vimeo';
  }

  if (validateInstagramUrl(link)) {
    return 'instagram';
  }

  if (validateFacebookUrl(link)) {
    return 'facebook';
  }

  if (validateTwitterUrl(link)) {
    return 'twitter';
  }

  if (validateSpotifyUrl(link)) {
    return 'spotify';
  }

  return 'link';
}

export const isAutoflowTrainingCalendarPage = autoflowId => {
  return window.location.pathname.includes(`${autoflowId}/training/calendar`);
};

export const isS3FileURL = url => {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname.includes('amazonaws.com');
  } catch {
    return false;
  }
};

export const isMp3File = fileMimeType => {
  try {
    return !!_.find(FILE_MIMETYPES.MP3, type => type.includes(fileMimeType));
  } catch {
    return false;
  }
};

export const isVideoFile = fileMimeType => {
  try {
    return !!_.find(FILE_MIMETYPES.VIDEO, type => fileMimeType.includes(type));
  } catch {
    return false;
  }
};

export const isImageFile = fileMimeType => {
  try {
    return !!_.find(FILE_MIMETYPES.IMAGE, type => fileMimeType.includes(type));
  } catch {
    return false;
  }
};

export const isDocumentFile = fileMimeType => {
  try {
    return !!_.find(FILE_MIMETYPES.DOCUMENT, type => fileMimeType.includes(type));
  } catch {
    return false;
  }
};

export const validateNumberOnly = event => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/\+|-|e/.test(keyValue)) event.preventDefault();
};

export const validatePositiveIntegerNumberOnly = event => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/\+|-|e|,|\./.test(keyValue)) event.preventDefault();
};

export const autoAddHttps = url => {
  const link = url.charAt(0).toLowerCase() + url.slice(1);
  const regex = /^(https|http)(.*)/i;
  if (!regex.test(link)) {
    return 'https://' + link;
  }
  return url;
};

export const validateNumberOnlyAndNoLeadingZero = event => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const currentValue = event.target.value;

  if (/\+|-|e/.test(keyValue)) {
    event.preventDefault();
  } else if (/^\d$/.test(keyValue) && currentValue.length === 0 && keyValue === '0') {
    event.preventDefault();
  }
};

export const checkPasswordValidation = val => {
  return {
    least_one_number: new RegExp('^.*\\d.*$').test(val),
    least_one_alphabet: new RegExp('^.*[a-zA-Z].*$').test(val),
    least_one_special: new RegExp(`^.*[${SPECIAL_CHARACTERS}].*$`).test(val),
    least_8_number: new RegExp('.{8,}$').test(val),
  };
};
