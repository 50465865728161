import React from 'react';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { Loader } from 'semantic-ui-react';

import { PRODUCT_STATUS } from 'components/Product/constants';
import ButtonIcon from 'shared/ButtonIcon';
import Dropdown from './dropdown';

import { ReactComponent as Close } from 'assets/icons/close_gray_out.svg';
import { ReactComponent as CloseGray } from 'assets/icons/close_gray.svg';
import { ReactComponent as Save } from 'assets/icons/save.svg';
import { ReactComponent as SaveLight } from 'assets/icons/save_light.svg';
import { ReactComponent as Ben } from 'assets/icons/pen_icon.svg';
import { ReactComponent as BenWhite } from 'assets/icons/pen_white.svg';
import { ReactComponent as Play } from 'assets/icons/play_light.svg';
import { ReactComponent as Unarchive } from 'assets/icons/unarchive_gray.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate_white.svg';

import * as S from './style';

// Header of detail page
const Header = ({
  isEdit = false,
  isDisable = false,
  status = null,
  isDuplicate = false,
  onSetStatus,
  onSetIsEdit,
  onSaveDraft,
  onStartEditMode,
  onCancel,
  onUpdate,
  data,
  onPublish,
  duplicating = false,
  onDuplicate,
  onDelete,
  onUnarchive,
  onArchive,
  isUploading,
  isEditingDuplicate,
  isLoadingUpdate,
  ...props
}) => {
  const { push } = props;

  const handleEditButton = () => {
    onStartEditMode && onStartEditMode();
  };

  // Handle Draft Event
  const handleSaveDraft = () => {
    onSaveDraft();
  };

  const handleCancelDraft = () => {
    onCancel && onCancel();
  };

  // Handle Publish Event
  const handlePublish = () => {
    onPublish && onPublish();
  };

  // Handle Close Event
  const handleClose = () => {
    push(`/home/sequences`);
  };

  // Handle Archive Event
  const handleArchive = () => {
    onArchive && onArchive();
  };

  // Handle UnArchive Event
  const handleUnArchive = () => {
    onUnarchive && onUnarchive();
  };

  // Handle Delete Event - Only for SAVED DRAFT PRODUCT
  const handleDelete = () => {
    onDelete && onDelete();
  };

  const handleDuplicate = () => {
    onDuplicate && onDuplicate();
  };

  const CreateNewActions = () => {
    return (
      <S.ActionsWrapper>
        <ButtonIcon
          text="Close"
          width={126}
          icon={<Close />}
          className="overview-close"
          height={34}
          onClick={handleClose}
        />

        <ButtonIcon
          text="Save Draft"
          width={166}
          icon={<Save />}
          className="overview-save-draft"
          height={34}
          bgHoverColor="#fff"
          textHoverColor="#818da1"
          onClick={handleSaveDraft}
        />
      </S.ActionsWrapper>
    );
  };

  const OverViewDraftActions = () => {
    const isExit = get(data, 'id', false);
    if (!isExit || isLoadingUpdate) {
      return null;
    }
    return (
      <S.ActionsWrapper>
        <ButtonIcon
          text="Edit"
          width={102}
          height={34}
          onClick={handleEditButton}
          icon={<Ben />}
          className="overview-edit"
          bgHoverColor="#fff"
          textHoverColor="#818da1"
        />
        <ButtonIcon
          text="Publish"
          width={104}
          height={34}
          onClick={handlePublish}
          bgHoverColor="#38c350"
          bgColor="#36B34B"
          color="#fff"
          textHoverColor="#fff"
          icon={<Play />}
        />
        <Dropdown status={status} onArchive={handleArchive} onDuplicate={handleDuplicate} onDelete={handleDelete} />
      </S.ActionsWrapper>
    );
  };

  const OverviewPublishActions = () => {
    const isPurchased = data.is_purchased;
    return (
      <S.ActionsWrapper>
        <div data-tip data-for="g-on-tooltip">
          <ButtonIcon
            disabled={isPurchased ? true : false}
            text="Edit"
            width={102}
            bgColor={isPurchased ? '#C4C4C4' : '#5158CF'}
            bgHoverColor={isPurchased ? '#C4C4C4' : '#5559FF'}
            color="#fff"
            textHoverColor="#fff"
            height={34}
            icon={<BenWhite />}
            onClick={isPurchased ? () => {} : handleEditButton}
          />
        </div>
        {isPurchased && (
          <ReactTooltip className="app-tooltip-dark" id="g-on-tooltip" effect="solid" place="left">
            <p className="message-popup">
              This sequence has been purchased in a package. Please create a duplicate to edit the sequence.
            </p>
            <ButtonIcon
              text="Duplicate"
              width={207}
              height={34}
              onClick={handleDuplicate}
              bgColor="transparent"
              bgHoverColor="transparent"
              color="#fff"
              textHoverColor="#fff"
              icon={<DuplicateIcon />}
            />
          </ReactTooltip>
        )}
        <Dropdown status={status} onArchive={handleArchive} onDuplicate={handleDuplicate} onDelete={handleDelete} />
      </S.ActionsWrapper>
    );
  };

  const OverviewArchiveActions = () => {
    return (
      <S.ActionsWrapper>
        <ButtonIcon
          text="Unarchive"
          width={154}
          height={34}
          onClick={handleUnArchive}
          icon={<Unarchive />}
          color="#818DA1"
          textHoverColor="#818DA1"
          bgColor="#fff"
          bgHoverColor="#f5f7f9"
        />
        <Dropdown status={status} onArchive={handleArchive} onDuplicate={handleDuplicate} onDelete={handleDelete} />
      </S.ActionsWrapper>
    );
  };

  const EditingDraftActions = () => {
    return (
      <S.ActionsWrapper>
        <ButtonIcon
          text="Cancel Changes"
          width={154}
          height={34}
          onClick={handleCancelDraft}
          icon={<CloseGray />}
          textHoverColor="#818da1"
          bgHoverColor="#f5f7f9"
          disabled={isUploading}
        />
        <ButtonIcon
          text="Save Changes"
          width={167}
          bgColor="#36B24B"
          color="#fff"
          height={34}
          bgHoverColor="#38c350"
          textHoverColor="#fff"
          onClick={onUpdate}
          icon={<SaveLight />}
          disabled={isUploading}
        />
      </S.ActionsWrapper>
    );
  };

  const EditingPublishActions = () => {
    return (
      <S.ActionsWrapper>
        <ButtonIcon
          text="Cancel Changes"
          width={154}
          height={34}
          icon={<CloseGray />}
          textHoverColor="#818da1"
          bgHoverColor="#f5f7f9"
          onClick={handleCancelDraft}
          disabled={isUploading}
        />
        <ButtonIcon
          text="Save Changes"
          width={167}
          bgColor="#36B24B"
          bgHoverColor="#38c350"
          color="#fff"
          height={34}
          icon={<SaveLight />}
          textHoverColor="#fff"
          onClick={onUpdate}
          disabled={isUploading || isLoadingUpdate}
        />
      </S.ActionsWrapper>
    );
  };

  const EditDuplicateActions = () => {
    return (
      <ButtonIcon
        text="Save Draft"
        width={166}
        icon={<Save />}
        className="overview-save-draft"
        height={34}
        bgHoverColor="#fff"
        textHoverColor="#818da1"
        onClick={handleSaveDraft}
      />
    );
  };

  const EditingMode = () => {
    if (isEditingDuplicate) {
      return <EditDuplicateActions />;
    } else if (status === PRODUCT_STATUS.NEW) {
      return <CreateNewActions />;
    } else if (status === PRODUCT_STATUS.DRAFT) {
      return <EditingDraftActions />;
    } else if (status === PRODUCT_STATUS.PUBLISH) {
      return <EditingPublishActions />;
    } else if (status === PRODUCT_STATUS.ARCHIVE) {
      return <OverviewArchiveActions />;
    }
  };

  const OverviewMode = () => {
    if (status === PRODUCT_STATUS.DRAFT || status === PRODUCT_STATUS.NEW) {
      return <OverViewDraftActions />;
    } else if (status === PRODUCT_STATUS.ARCHIVE) {
      return <OverviewArchiveActions />;
    } else if (status === PRODUCT_STATUS.PUBLISH) {
      return <OverviewPublishActions />;
    }
  };

  return (
    <S.Wrapper>
      <S.TabsWrapper>
        <S.TabItem active={true}>Overview</S.TabItem>
      </S.TabsWrapper>
      {status && (isEdit ? <EditingMode /> : !duplicating && <OverviewMode />)}
      {duplicating && (
        <S.DuplicateWrapper>
          <Loader active inline="centered" className="duplicate-indicator" />
          <span>Duplicating {get(data, 'name', '')} ...</span>
        </S.DuplicateWrapper>
      )}
    </S.Wrapper>
  );
};

export default Header;
