import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 487px;
    padding: 30px;
  }
  > .content {
    padding: 0 !important;
  }
  .confirm-content {
    padding: 0;
  }
  .confirm-footer {
    padding: 30px 0 0;
  }
`;
export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin: 0;
  padding-bottom: 15px;
  display: flex;
  svg {
    margin-right: 10px;
  }
`;

export const ModalDesc = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  white-space: pre-line;
`;

export const ModalButton = styled(Button)`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  min-width: auto;
  &.rest-btn {
    padding-left: 0;
    padding-right: 15px;
    border: 0;
    &:hover {
      background-color: transparent;
    }
  }
  &.all-btn {
    background-color: transparent;
    border: 1px solid #d6dae4;
    color: #818da1;
  }
  &.update-btn {
    background-color: #5559ff;
  }
`;
