import React, { useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

export default function (props) {
  const { options, value, autoOpen } = props;
  const [open, setOpen] = useState(!!autoOpen);
  const selected = value || {};

  return (
    <RootCloseWrapper event="click" disabled={!open} onRootClose={() => setOpen(false)}>
      <S.Wrapper className={open ? 'open' : null} ariaExpanded={!!open} tabIndex="0">
        <S.Trigger onClick={() => setOpen(!open)}>
          <div className="text">{selected.selectedLabel || selected.label}</div>
          <img src={`${CDN_URL}/images/arrow_down_light_purple.svg`} alt="" />
        </S.Trigger>
        <S.Menu>
          {_.map(options, item => (
            <S.Option
              key={item.key}
              onClick={() => {
                setOpen(false);

                if (item.key !== selected.key) {
                  props.onSelect(item);
                }
              }}
            >
              {item.label}
            </S.Option>
          ))}
        </S.Menu>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}
