import { filter } from 'lodash';
import React, { useState } from 'react';
import LabelContainer from './LabelContainer';
import { ACTION } from './constants';

import * as S from './style';

const MoreLabel = ({
  labels,
  onChangeSavedLabels,
  savedLabels: originalSavedLabels,
  handleRemoveSavedLabel,
  userId,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [savedLabels, setSavedLabels] = useState(originalSavedLabels);

  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChangeSavedLabels = (labelId, action) => {
    if (action === ACTION.ADD) {
      setSavedLabels([...savedLabels, labelId]);
    }
    if (action === ACTION.REMOVE) {
      setSavedLabels(filter(savedLabels, label => label !== labelId));
      handleRemoveSavedLabel(labelId);
    }
  };

  return (
    <S.MoreLabelWrapper>
      <S.SPopup
        on="click"
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        pinned
        trigger={<S.MoreLabelButton disabled={disabled}>+ More Labels</S.MoreLabelButton>}
        content={
          <LabelContainer
            userId={userId}
            onClose={handleClose}
            labels={labels}
            savedLabels={savedLabels}
            handleSaveLabels={onChangeSavedLabels}
            onChangeSavedLabels={handleChangeSavedLabels}
          />
        }
        position="right center"
        className="action-popup"
      />
    </S.MoreLabelWrapper>
  );
};

export default MoreLabel;
