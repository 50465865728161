/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import ChangeEmail from './component';
import { changeEmail } from 'actions/client';

const mapStateToProps = (state: State) => {
  const { user } = state;
  return {
    user
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    changeEmail: (data) => {
      return dispatch(changeEmail(data));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangeEmail)
);
