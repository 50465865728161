import classNames from 'classnames';
import { get } from 'lodash';
import React, { useMemo } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/icons/linear-scale-arrow-icon.svg';

import * as S from '../styles';

export default function LinearScaleResponse({ answer }) {
  const linear_answer = get(answer, 'answer.linear_answer');
  const start = `${get(answer, 'originalQuestion.linear_start.value', 0)}`;
  const end = `${get(answer, 'originalQuestion.linear_end.value', 5)}`;
  const startLabel = get(answer, 'originalQuestion.linear_start.label', '');
  const endLabel = get(answer, 'originalQuestion.linear_end.label', '');
  const range = useMemo(() => parseInt(end) - parseInt(start) + 1, [start, end]);

  const rangeItems = Array.from({ length: range }, (_, i) => i + parseInt(start));

  return (
    <S.LinearScaleContainer range={range}>
      <div className="labels-container">
        {startLabel && (
          <div className="label-item">
            <span>{start}: </span>
            <span>{startLabel}</span>
          </div>
        )}
        {startLabel && endLabel && (
          <div className="breaker">
            <ArrowIcon />
          </div>
        )}
        {endLabel && (
          <div className="label-item">
            <span>{end}: </span>
            <span>{endLabel}</span>
          </div>
        )}
      </div>
      <div className="option-container">
        {rangeItems.map(i => (
          <div key={i} className={classNames('option', { selected: i === linear_answer })}>
            {i}
          </div>
        ))}
      </div>
    </S.LinearScaleContainer>
  );
}
