import styled, { css } from 'styled-components';
import { Modal, Radio } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

export const RefundPaymentPopupWrapper = styled(Modal)`
  &.ui.modal {
    width: 445px;
  }

  .header {
    border-radius: 8px;
    padding-top: 24px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 15px !important;
    color: #202353 !important;
  }

  .content {
    padding: 10px 24px 20px !important;
    overflow: auto;
    max-height: 586px;
  }

  &.ui.modal > .header:not(.ui) {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    padding-bottom: 10px;
  }
  .actions {
    background: #ffffff !important;
    box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.08) !important;
    border-top: 0px !important;
    padding: 24px !important;
    .cancel-btn {
      height: 40px;
      width: 112px;
      border-radius: 4px;
    }
    .refund-btn {
      height: 40px;
      width: 112px;
      margin-left: 12px;
      border-radius: 4px;
      :hover {
        background: #5e59ff;
      }
      :disabled {
        background: #c4c4c4;
      }
    }
  }
`;

export const RefundOption = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 16px;
  border: 1px solid #e0e1e4;
  border-radius: 8px;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 24px;
  }
  .primary-field {
    align-items: center;
    display: flex;
  }
  .secondary-field {
    .refund-input-container {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
        font-weight: 600;
        line-height: 20px;
        color: #151619;
      }
      position: relative;
      .formControl {
        margin-bottom: 0px !important;
      }
      .refund-currency-text {
        position: absolute;
        top: 10px;
        right: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #151619;
      }
    }
    .refund-input-label {
      margin-top: 16px;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #9a9da6;
      margin-bottom: 4px;
    }
  }
  ${props =>
    props.selected &&
    `background: rgba(94, 89, 255, 0.08);
border: 1px solid #C0BEFA;`}
  .option-label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #151619;
  }
  .option-description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #4a4d56;
    span {
      font-weight: 700;
    }
  }
`;

export const RadioButton = styled(Radio)`
  margin-right: 16px;
  label {
    ::before {
      width: 20px !important;
      height: 20px !important;
      transition: none !important;
    }
    ::after {
      width: 12px !important;
      height: 12px !important;
      transform: none !important;
      top: 5px !important;
      left: 4px !important;
      transition: none !important;
    }
  }
`;

export const NumberInput = styled(NumberFormat)`
  width: 100%;
  padding: 10px 16px;
  outline: none;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: #151619;
  border: 1px solid #c3c5cb;
  border-radius: 4px;

  ::placeholder {
    color: #c3c5cb;
    font-weight: 400;
  }

  :hover,
  :focus {
    border-color: #5e59ff;
  }

  ${props =>
    props.error &&
    css`
      border-color: #ea314a;

      :hover,
      :focus {
        border-color: #ea314a;
      }
    `}

  ${props =>
    props.textError &&
    css`
      color: #ea314a;
    `}
`;

export const InfoContainer = styled.div`
  background: #ebf3ff;
  border-radius: 4px;
  width: 100%;
  padding: 14px 16px;
  display: flex;
  margin-bottom: 24px;
  .info-icon {
    margin-right: 16px;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }
  .info-description {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    /* or 145% */

    /* Grayscale/Gray - 90 */

    color: #4a4d56;
    span.semi-bold {
      font-weight: 600;
    }
  }
  .info-learn_more {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #3684f7;
    cursor: pointer;
  }
`;

export const DetailFieldsContainer = styled.div`
  .detail-fields-label {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #9a9da6;
    margin-bottom: 4px;
  }

  .refund-reason-dropdown {
    margin-bottom: 24px;
    .common-select-container__placeholder {
      color: #c3c5cb;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
  }
  .formControl {
    margin-bottom: 0px !important;
    textarea {
      height: 104px;
      font-size: 13px;
      padding: 10px 15px;
    }
  }
`;

export const RefundConfirmPopup = styled(Modal)`
  &.ui.modal {
    width: 482px;
  }

  .header {
    border-radius: 8px;
    font-size: 18px !important;
    line-height: 25px !important;
    font-weight: 600 !important;
    padding: 30px 30px 0 !important;
  }

  .content {
    padding: 15px 30px !important;
  }

  .actions {
    background: #fff !important;
    border-top: 0px !important;
    padding: 30px !important;
    .cancel-btn {
      padding: 5px 30px;
      border-radius: 5px;
    }
    .process-refund-btn {
      padding: 5px 30px;
      margin-left: 5px;
      border-radius: 5px;
      :hover {
        background: #5e59ff;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  padding: 10px;
  background-color: #f3f5f8;
  font-size: 12px;
  color: #151619;
  border-radius: 5px;
  font-weight: 400;

  p {
    margin-bottom: 0;
    padding-bottom: 15px;
    line-height: 18px;
    word-break: break-all;
    white-space: pre-wrap;

    :last-child {
      padding-bottom: 0;
    }
  }

  strong {
    font-weight: 700;
  }
`;

export const InfoText = styled.p`
  font-size: 13px;
  line-height: 19.5px;
  color: #323c47;
  margin-bottom: 0;
  padding: 15px 0;
  font-weight: 400;

  :last-child {
    padding-bottom: 0;
  }

  strong {
    font-weight: 700;
  }
`;
