import React, { useEffect, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import get from 'lodash/get';
import { toast } from 'react-toastify';

import { axiosInstance, axiosInstanceWithoutToken } from 'configs/request';
import { useCountdown } from 'hooks/useCountdown';

import { ReactComponent as VerifyEmailExistingIcon } from 'assets/icons/VerifyEmail/verify_email_existing.svg';

import * as S from './style';

const VerifyEmailExistingModal = ({ firstName, onClose, token, isLogged }) => {
  const config = token ? { headers: { 'x-access-token': token } } : {};
  const instance = token ? axiosInstanceWithoutToken : axiosInstance;

  const { countdown, setCountdown } = useCountdown();
  const hasCooldown = localStorage.getItem('has_cooldown');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if ((hasCooldown || !countdown) && isLogged) {
      resendEmailVerification(!!hasCooldown);
    }
  }, []);

  const resendEmailVerification = isClickResend => {
    if (loading) return;

    setLoading(true);
    instance
      .post('/api/authentication/trainer/send-email-verification', { is_click_resend: isClickResend }, config)
      .then(response => {
        const { success, cooldown_resend: coolDownTime } = get(response, 'data.data', {});

        if (!isClickResend) {
          setCountdown(Math.ceil(coolDownTime / 1000));
          return;
        }

        if ((success || coolDownTime !== 0) && isClickResend) {
          setCountdown(Math.ceil(coolDownTime / 1000));
        }

        if (get(response, 'data.data.success')) {
          toast('A verification email has been sent to you. Follow instructions to verify your account.', {
            className: 'wider',
          });
        }
      })
      .catch(error => {
        if (get(error, 'response.data.message')) {
          toast(get(error, 'response.data.message'), {
            className: 'wider',
          });
          onClose();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.CustomModal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <img src="/images/close_circle.svg" alt="" />
        </CloseButton>
      }
      onClose={onClose}
      closeOnDimmerClick={false}
      className="custom-modal-create-account"
    >
      <Modal.Content>
        <S.Container>
          <div className="header">
            <VerifyEmailExistingIcon className="verify-email-icon" />
            <span className="header-title">Verify Your Email</span>
          </div>
          <div className="content">
            Hi {firstName}, it has been 30 days since we sent you an email to verify your email address. In order to
            ensure the security of your account, please follow instructions in the verification email to verify and
            regain access to all features. Click the button below to receive a new email.
          </div>
          <div className="actions">
            <button className="resend" onClick={() => resendEmailVerification(true)} disabled={!!countdown || loading}>
              {!!countdown ? (
                <>
                  If you didn't receive an email.&nbsp;
                  <span className="resend-bold">Resend ({countdown}s)</span>
                </>
              ) : (
                'Resend Verification Email'
              )}
            </button>
          </div>
        </S.Container>
      </Modal.Content>
    </S.CustomModal>
  );
};

export default VerifyEmailExistingModal;
