import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { convertS3UrlToCloudFrontUrl, getDuration } from 'utils/commonFunction';
import VideoDuration from 'shared/VideoDuration';

class S3Video extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { src: '' };
  }

  componentDidMount() {
    this.__isMounted = true;
    this.getPresignedURLs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.videoLink !== this.props.videoLink) {
      this.getPresignedURLs();
    }
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  getPresignedURLs = async () => {
    try {
      const { getS3presignedURLFromLocalDatabase, cloudfrontList, videoLink } = this.props;
      let newUrl = convertS3UrlToCloudFrontUrl(videoLink, cloudfrontList);
      if (!newUrl) {
        const response = await getS3presignedURLFromLocalDatabase([videoLink]);
        newUrl = response[0];
      }

      if (this.__isMounted) {
        this.setState({ src: newUrl });
      }
    } catch {
      // good luck
    }
  };

  render() {
    const { src, videoType } = this.state;
    const { showDuration = false } = this.props;

    return (
      <>
        <video
          className="s3-video"
          onLoadedMetadata={event => {
            if (event.target && event.target.duration && showDuration) {
              const { duration, nextElementSibling } = event.target;
              if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                const time = getDuration(duration);
                const timeEl = nextElementSibling.querySelector('.time');
                if (timeEl) {
                  timeEl.innerText = time;
                }
              }
            }
            event.persist();
          }}
        >
          {src ? (
            <>
              <source src={src} type={videoType} />
              <source src={src} type="video/mp4" />
            </>
          ) : null}
        </video>
        {showDuration && <VideoDuration />}
      </>
    );
  }
}

S3Video.propTypes = {
  videoLink: PropTypes.string.isRequired,
  videoType: PropTypes.string,
};

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps, { getS3presignedURLFromLocalDatabase })(S3Video);
