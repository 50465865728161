import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Dropdown from 'shared/Dropdown/Basic';
import get from 'lodash/get';
import pick from 'lodash/pick';
import Datetime from 'react-datetime';
import { DateInput } from 'shared/FormControl';
import { Toggle } from 'shared/FormControl';
import EmojiPickerPopup from '../CommonComponents/EmojiPickerPopup';
import { KEY_CODE } from 'constants/commonData';
import { toggleModal } from 'actions/modal';
import SelectTime from './SelectTime';
import { capitalizeFirstChar } from 'utils/commonFunction';

import {
  BACKGROUND_DEFAULT,
  BACKGROUND_LIST,
  DEFAULT_COLOR,
  DEFAULT_COUNTDOWN_TIME,
  DEFAULT_EMOJI,
  REMINDER_SETTING,
} from '../../constants';
import TIME_OPTIONS from 'constants/time-options';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';

import * as S from './style';

const LIMIT_TITLE = 70;
const DATE_FORMAT = 'YYYY-MM-DD';

const AddAndEditCountdown = props => {
  const { isEdit = false, onChangeFormCreate = () => {}, selectedTemplate, editData = {}, userId } = props;
  const { color, icon, title = '', date = null, time = '', is_all_day = false } = editData;

  const chooseIconRef = useRef();
  const [selectedEmoji, setSelectedEmoji] = useState(isEdit ? { color, icon } : DEFAULT_EMOJI);
  const [previewEmoji, setPreviewEmoji] = useState();
  const [modalStatus, setmodalStatus] = useState(false);
  const [countdownName, setCountdownName] = useState('');
  const [isFocusNameInput, setIsFocusNameInput] = useState(false);
  const [reminderSettings, setReminderSettings] = useState(REMINDER_SETTING);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(DEFAULT_COUNTDOWN_TIME);
  const [isAllDayStatus, setIsAllDayStatus] = useState(true);

  const today = moment(new Date());
  const currentTime = today.format('HH:mm');
  const currentDate = today.format(DATE_FORMAT);

  useEffect(() => {
    if (onChangeFormCreate) {
      const settingParams = reminderSettings.reduce((accumulator, currentValue) => {
        accumulator[currentValue.key] = currentValue.status;
        return accumulator;
      }, {});

      const createParams = {
        user: userId,
        title: countdownName,
        date: selectedDate ? selectedDate.format(DATE_FORMAT) : null,
        time: selectedHour.value,
        icon: selectedEmoji.icon,
        color: selectedEmoji.color,
        icon_unified: selectedEmoji.icon_unified,
        is_all_day: isAllDayStatus,
        ...settingParams,
      };

      onChangeFormCreate(createParams);
    }
  }, [selectedEmoji, countdownName, reminderSettings, selectedDate, isAllDayStatus, selectedHour]);

  useEffect(() => {
    if (isEdit) {
      const pickReminder = ['remind_at_finish_time', 'remind_one_day_before', 'remind_one_week_before'];
      const reminderSetting = pick(editData, pickReminder);
      const oldSettings = reminderSettings.map(setting => {
        return {
          ...setting,
          status: reminderSetting[setting.key],
        };
      });
      date && setSelectedDate(moment(date));
      setSelectedEmoji({ icon, color });
      setCountdownName(title);
      setIsAllDayStatus(is_all_day);
      const editTime = TIME_OPTIONS.find(({ value }) => `${value}:00` === editData.time);
      const customTime = editData.time && moment(editData.time, 'HH:mm:ss');
      const formatTime = customTime && customTime.format('hh:mm A');
      setSelectedHour(
        editTime
          ? { ...editTime, value: `${editTime.value}:00` }
          : editData.time
          ? { label: formatTime, value: editData.time }
          : DEFAULT_COUNTDOWN_TIME,
      );
      setReminderSettings(oldSettings);
    }
  }, []);

  useEffect(() => {
    if (selectedDate && currentDate === selectedDate.format(DATE_FORMAT) && !isAllDayStatus && !isEdit) {
      const Options = TIME_OPTIONS.filter(item => item.value > currentTime);
      const selectTime = {
        ...Options[0],
        value: `${Options[0].value}:00`,
      };
      setSelectedHour(selectTime);
    } else {
      if (isEdit) {
        const editTime = TIME_OPTIONS.find(({ value }) => `${value}:00` === editData.time);
        const customTime = editData.time && moment(editData.time, 'HH:mm:ss');
        const formatTime = customTime && customTime.format('hh:mm A');
        setSelectedHour(
          editTime
            ? { ...editTime, value: `${editTime.value}:00` }
            : editData.time
            ? { label: formatTime, value: editData.time }
            : DEFAULT_COUNTDOWN_TIME,
        );
      } else {
        setSelectedHour(DEFAULT_COUNTDOWN_TIME);
      }
    }
  }, [selectedDate, isAllDayStatus]);

  const handleUpdateSelectedEmoji = data => {
    chooseIconRef.current && chooseIconRef.current();
    setSelectedEmoji(data);
  };

  const handleChangeEmojiPreview = data => {
    setPreviewEmoji(data);
  };

  const handleCloseEmojiPicker = () => {
    setmodalStatus(state => !state);
    setSelectedEmoji(selectedEmoji);
    setPreviewEmoji(null);
  };

  const handleAddMoreOption = () => {};

  const onKeyPress = event => {
    if (event.keyCode === KEY_CODE.enter) {
      handleAddMoreOption();
    }
  };

  const onChangeAddMoreOption = event => {
    const { value = '' } = event.target;
    setIsFocusNameInput(true);
    if (value.length > LIMIT_TITLE) return;
    setCountdownName(capitalizeFirstChar(value));
  };

  const handleFocusNameInput = (status = false) => () => {
    setIsFocusNameInput(status);
  };

  const onSelectReminder = keySetting => () => {
    const newSettings = reminderSettings.map(setting => {
      if (setting.key === keySetting) {
        return {
          ...setting,
          status: !setting.status,
        };
      }
      return setting;
    });
    setReminderSettings(newSettings);
  };

  const yesterday = moment(new Date()).subtract(1, 'd');
  const checkValidDate = current => {
    return current ? current.isAfter(yesterday) : false;
  };

  const renderSettingItem = (setting, index) => {
    const { key, title, status } = setting;
    const time = index > 0 && `at ${isAllDayStatus ? '9AM' : selectedHour.label}`;
    return (
      <S.SettingWrapper key={key}>
        <S.LeftSetting>
          <S.SettingTitle>{title}</S.SettingTitle>
          {time && <S.TimeLabel>{time}</S.TimeLabel>}
        </S.LeftSetting>
        <S.RightSetting>
          <Toggle checked={status} onChange={onSelectReminder(key)} width={44} height={24} />
        </S.RightSetting>
      </S.SettingWrapper>
    );
  };

  const renderDay = (props, currentDate) => {
    let className = props.className || '';
    return (
      <td {...props} className={className}>
        <div>{currentDate.date() < 10 ? '0' + currentDate.date() : currentDate.date()}</div>
      </td>
    );
  };

  const renderDateTimeInput = props => {
    return (
      <DateInput
        {...props}
        text={selectedDate ? selectedDate.format('MMM D, YYYY') : 'Choose date'}
        customIcon={<CalendarIcon className="calendar-icon" />}
      />
    );
  };

  const handleSelectDate = value => {
    setSelectedDate(value);
  };

  const handleSelectHour = (time, isDefault = false) => {
    const selectTime = {
      ...time,
      value: `${time.value}:00`,
    };
    setSelectedHour(isDefault ? time : selectTime);
  };

  const handleChangeToggle = () => {
    if (!isAllDayStatus) {
      handleSelectHour(DEFAULT_COUNTDOWN_TIME, true);
    }
    setIsAllDayStatus(!isAllDayStatus);
  };

  return (
    <S.SectionWrapper>
      <S.NameCountdownWrapper>
        <S.SelectedIconWrapper selectedColor={(previewEmoji || {}).color || selectedEmoji.color}>
          <Dropdown
            closeDropdownRef={chooseIconRef}
            onCloseDropdown={handleCloseEmojiPicker}
            triggerIcon={({ open }) => {
              return (
                <S.CountdownIconWrapper open={open}>
                  <S.CountdownIcon
                    background={get(
                      BACKGROUND_LIST.find(
                        item => item.background === ((previewEmoji || {}).color || selectedEmoji.color),
                      ),
                      'background',
                      DEFAULT_COLOR,
                    )}
                  >
                    {(previewEmoji || {}).icon || selectedEmoji.icon}
                  </S.CountdownIcon>
                </S.CountdownIconWrapper>
              );
            }}
          >
            <EmojiPickerPopup
              modalStatus={modalStatus}
              prevEmoji={selectedEmoji}
              onUpdate={handleUpdateSelectedEmoji}
              onChangeSelect={handleChangeEmojiPreview}
            />
          </Dropdown>
        </S.SelectedIconWrapper>
        <S.InputNameWrapper>
          <S.NameInput
            placeholder="Name your countdown"
            autoFocus
            onKeyDown={onKeyPress}
            onChange={onChangeAddMoreOption}
            onBlur={handleFocusNameInput(false)}
            onFocus={handleFocusNameInput(true)}
            value={countdownName}
            maxLength={LIMIT_TITLE}
          />
          <S.ValidateWrapper isFocusNameInput={isFocusNameInput}>
            <S.LimitTitle isFull={LIMIT_TITLE === countdownName.length}>
              <span>
                {countdownName.length}/{LIMIT_TITLE}
              </span>
            </S.LimitTitle>
          </S.ValidateWrapper>
        </S.InputNameWrapper>
      </S.NameCountdownWrapper>
      <S.TimeSettingWrapper>
        <S.CalendarWrapper>
          <S.DateTimeTitle>COUNTDOWN TO</S.DateTimeTitle>
          <Datetime
            value={selectedDate}
            renderDay={renderDay}
            renderInput={props => renderDateTimeInput({ ...props, name: 'endDate' })}
            timeFormat={false}
            onChange={value => handleSelectDate(value)}
            closeOnSelect={true}
            className={classNames('new-ui', { error: false })}
            isValidDate={checkValidDate}
          />
        </S.CalendarWrapper>

        <S.SelectDetailTime>
          <S.SetTimeWrapper disabled={false}>
            <SelectTime
              disabled={false}
              className="custom-reminder"
              scheduleReminder={isAllDayStatus}
              selectedDate={selectedDate ? selectedDate.format(DATE_FORMAT) : ''}
              reminderTime={selectedHour.label}
              reminderTimeValue={selectedHour}
              onChangeReminderTime={handleSelectHour}
              onToggleScheduleReminder={handleChangeToggle}
            />
          </S.SetTimeWrapper>
        </S.SelectDetailTime>
      </S.TimeSettingWrapper>
      <S.ReminderWrapper>
        <S.ReminderTitle>REMIND ME</S.ReminderTitle>
        <S.ReminderSettingWrapper>{reminderSettings.map(renderSettingItem)}</S.ReminderSettingWrapper>
      </S.ReminderWrapper>
    </S.SectionWrapper>
  );
};

const mapDispatch = {
  toggleModal,
};

const mapState = ({ rootReducer }) => {
  const { workingClientDetail } = rootReducer.client;
  return {
    userId: (workingClientDetail || {})._id,
  };
};

export default connect(mapState, mapDispatch)(AddAndEditCountdown);
