import React from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { PackageItemWrapper, CheckBoxIconWrapper, RecommendWrapper, LimitWrapper, MessageContent } from './style';
import PackageFeatures from './PackageFeatures';
import { TRIAL_MODE, PLANS } from 'constants/commonData';
import { ReactComponent as Forum } from 'assets/icons/meeting_icon.svg';

const CheckBoxIcon = ({ active, title }) => {
  return (
    <CheckBoxIconWrapper active={active}>
      <div className="checkbox__circle" data-radio={`${title}`} />
    </CheckBoxIconWrapper>
  );
};

const LimitMessage = () => {
  return (
    <LimitWrapper>
      <Forum />
      <div>
        <MessageContent>
          <strong>Community Forums (Limited offer)</strong>
        </MessageContent>
        <MessageContent>FREE 6 months access ($120 value)</MessageContent>
      </div>
    </LimitWrapper>
  );
};

const Recommend = ({ isRecommended, active, currentTier, teamData }) => {
  if (!isRecommended) {
    return null;
  }
  return (
    <RecommendWrapper active={active}>{currentTier === 'studio' ? 'Most Popular' : 'Recommended'}</RecommendWrapper>
  );
};

function getNoClientDescription(planPackage, selectPackage, active, isWarningClient) {
  let prefix = 'Up to';
  let noClient = 'Up to';
  switch (planPackage.code) {
    case PLANS.starter.key:
      prefix = 'Up to';
      noClient = planPackage.maximumNoClients;
      break;
    case PLANS.pro.key:
    case PLANS.studio.key:
      prefix = active ? 'Up to' : 'Starting at';
      noClient = active ? _.get(selectPackage, 'packagePrice.packageNoClients', 0) : planPackage.minimumNoClients;
      break;
    default:
      break;
  }
  return { prefix, noClient };
}

function PackageItem({ planPackage, selectPackage, teamData, isCompare, period, index, ...props }) {
  const isDisabled = false;
  const noClientDescription = getNoClientDescription(planPackage, selectPackage, props.active);

  const onSelect = () => {
    !isDisabled && props.onSelect(planPackage.code);
  };

  return (
    <PackageItemWrapper
      active={props.active}
      disabled={isDisabled}
      onClick={onSelect}
      data-billing-package={`package${props.active ? ' active' : ''}`}
      name={planPackage.title}
      className="Plan"
      // limitPackage={planPackage.code !== PLANS.starter.key}
    >
      <div>
        <div className="packageItem__current">
          {props.isCurrent ? (teamData.trialMode === TRIAL_MODE.Grace ? 'Trial Expired' : 'Current plan') : null}
        </div>
        <div className="packageItem__name">{planPackage.title}</div>
        <div className="packageItem__description">{planPackage.description}</div>
      </div>
      <PackageFeatures
        plan={planPackage.code}
        isCompare={isCompare}
        onToggleCompare={props.onToggleCompare}
        features={planPackage.features}
        className="packageItem__features"
      />
      <div>
        <div className="packageItem__pricing">
          <div className="packageItem__pricing--monthly">
            {!!planPackage.basePrice && <span className="packageItem__pricing--symbol">$</span>}
            {props.active ? (
              <span className="packageItem__pricing--cost">
                {_.get(selectPackage, 'packagePrice.monthlyPricing', 0) || 'Free'}
              </span>
            ) : (
              <span className="packageItem__pricing--cost">{planPackage.basePrice || 'Free'}</span>
            )}
            {!!planPackage.basePrice && <span className="packageItem__pricing--duration">/month</span>}
          </div>
          <div className="packageItem__pricing--annually">
            {selectPackage.period === 'annually' && planPackage.code !== PLANS.starter.key && 'Billed Annually'}
          </div>
        </div>
        <div className="packageItem__clients">
          <span>{noClientDescription.prefix}&nbsp;</span>
          <strong>{noClientDescription.noClient} clients</strong>
        </div>
      </div>
      {/*TODO {planPackage.code !== PLANS.starter.key && <LimitMessage />} */}
      {!isDisabled && <CheckBoxIcon title={planPackage.title} active={props.active} />}
      <Recommend
        isRecommended={planPackage.code === 'pro'}
        active={props.active}
        currentTier={props.currentTier}
        teamData={teamData}
      />
    </PackageItemWrapper>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const actionCreators = {
  push,
};

export default connect(mapState, actionCreators)(PackageItem);
