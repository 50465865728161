import React, { useRef, useEffect, useMemo } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import get from 'lodash/get';
import map from 'lodash/map';
import debounce from 'lodash/debounce';

import * as S from '../style';
import { useMyContext } from '../context/NotificationContext';
import FooterTooltip from './FooterTooltip';
import HeaderTitle from './HeaderTitle';
import HeaderFilter from './HeaderFilter';
import EmptyNotification from './EmptyNotification';
import ItemNotification from './ItemNotification';
import LoadingIndicator from './LoadingIndicator';
// import BackToTopButton from './BackToTopButton';

const NotificationList = () => {
  const {
    openPopup,
    closePopup,
    isEmptyNotification,
    isToday,
    isOlder,
    listToday,
    listOlder,
    openTooltip,
    loading,
    getMoreNotifications,
    isEnd,
    disableCloseSidebar,
    isFirstPage,
    onlyUnread,
  } = useMyContext();
  const lastScrollTopRef = useRef(0);
  const mainPanelContentRef = useRef();
  const contentScrollRef = useRef();
  const forceLoadMoreRef = useRef(false);

  const orderTitle = useMemo(() => (isToday ? 'TODAY' : !isOlder ? 'TODAY' : 'OLDER'), [isToday, isOlder]);

  useEffect(() => {
    if (!openPopup) {
      lastScrollTopRef.current = 0;
    }
  }, [openPopup]);

  useEffect(() => {
    lastScrollTopRef.current = 0;
  }, [onlyUnread]);

  useEffect(() => {
    if (
      !forceLoadMoreRef.current &&
      isFirstPage &&
      !isEnd &&
      !isEmptyNotification &&
      mainPanelContentRef.current &&
      contentScrollRef.current
    ) {
      const mainRect = mainPanelContentRef.current.getBoundingClientRect();
      const contentRect = contentScrollRef.current.getBoundingClientRect();
      if (mainRect.height > contentRect.height) {
        getMoreNotifications();
        forceLoadMoreRef.current = true;
      }
    }
  }, [
    forceLoadMoreRef.current,
    isFirstPage,
    isEnd,
    isEmptyNotification,
    mainPanelContentRef.current,
    contentScrollRef.current,
  ]);

  const onBodyScrollAndChangeTitle = () => {
    if (!isEmptyNotification && isToday && isOlder) {
      const olderTitle = document.getElementById('notification-older-title');
      const headerFilter = document.getElementById('notification-header-filter');
      const orderTitle = document.getElementById('notification-order-title');

      if (olderTitle && headerFilter && orderTitle) {
        const olderRect = olderTitle.getBoundingClientRect();
        const headerFilterRect = headerFilter.getBoundingClientRect();

        if (olderRect.top > window.innerHeight) return;

        if (Math.round(olderRect.bottom) - 2 > headerFilterRect.bottom) {
          if (orderTitle.firstChild.nodeValue.toUpperCase() === 'OLDER') {
            orderTitle.innerHTML = 'TODAY';
          }
        } else {
          if (orderTitle.firstChild.nodeValue.toUpperCase() === 'TODAY') {
            orderTitle.innerHTML = 'OLDER';
          }
        }
      }
    }
  };
  const onBodyScrollAndLoadMore = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (!isEnd && !loading && scrollTop > lastScrollTopRef.current) {
      const bottom = scrollHeight - (scrollTop + clientHeight);

      if (bottom < 150) {
        getMoreNotifications();
      }
    }

    lastScrollTopRef.current = scrollTop;
  };

  const onScrollDebounce = debounce(onBodyScrollAndLoadMore, 300);

  const renderList = items =>
    map(items, item => <ItemNotification item={item} key={`notification-${get(item, '_id')}`} />);

  return (
    <S.NotificationSidebarWrapper open={openPopup}>
      <RootCloseWrapper event="click" disabled={disableCloseSidebar} onRootClose={closePopup}>
        <S.NotificationSidebar open={openPopup}>
          <S.NotificationSidebarContent>
            <HeaderTitle />
            <HeaderFilter />
            {!isEmptyNotification && (
              <S.HeaderOrderWrapper noShadow id="notification-header-filter">
                <S.OrderTitle id="notification-order-title">{orderTitle}</S.OrderTitle>
              </S.HeaderOrderWrapper>
            )}
            <S.ContentBody
              id="notification-content-body"
              openTooltip={openTooltip}
              onScroll={event => {
                event.persist();
                onScrollDebounce.call(null, { ...event });
                onBodyScrollAndChangeTitle();
              }}
              ref={mainPanelContentRef}
            >
              <S.ContentBodyWrapper ref={contentScrollRef}>
                {isEmptyNotification && !loading && <EmptyNotification />}
                {!isEmptyNotification && isToday && renderList(listToday)}
                {!isEmptyNotification && isToday && isOlder && (
                  <S.HeaderOrderWrapper noShadow>
                    <S.OrderTitle id="notification-older-title">OLDER</S.OrderTitle>
                  </S.HeaderOrderWrapper>
                )}
                {!isEmptyNotification && isOlder && renderList(listOlder)}
                {loading && <LoadingIndicator />}
                {/* {!isEmptyNotification && isEnd && <BackToTopButton />} */}
              </S.ContentBodyWrapper>
            </S.ContentBody>
            <FooterTooltip />
          </S.NotificationSidebarContent>
        </S.NotificationSidebar>
      </RootCloseWrapper>
    </S.NotificationSidebarWrapper>
  );
};

export default NotificationList;
