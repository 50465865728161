import styled from 'styled-components';
import { NewSearchInput } from 'shared/SearchInput';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  padding: 15px;
`;

export const ResultsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  align-items: center;

  .resultsHeading__mostRecent {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: #202353;
  }

  .resultsHeading__addAll {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #5158CF;
    cursor: pointer;
  }
`;

export const ResultsContainer = styled.div`
  max-height: 392px;
  min-height: 392px;
  overflow: auto;
  margin-right: -12px;
  padding-right: 12px;
`;

export const SearchClientInput = styled(NewSearchInput)`
  width: 100%;
  margin-bottom: 20px;

  input {
    padding-top: 11px;
    padding-bottom: 11px;
    background: url(${CDN_URL}/images/search_grey_purple.svg) no-repeat;
    background-size: auto;
    background-position: 15px center;
    background-color: #F6F7F9;
    border-color: #F6F7F9;

    :focus,
    :hover {
      border-width: 1px;
    }

    ::placeholder {
      color: #A6ACC6;
      font-size: 13px;
      line-height: 18px;
      color: #A6ACC6;
    }
  }
`;

export const SearchClientItemWrapper = styled.div`
  display: flex;
  padding: 12px 2px 10px;
  box-shadow: inset 0px -1px 0px #EBEDF4;
  cursor: pointer;

  :hover {
    background: #F8F8FF;
  }

  .searchClientItem__avatar {

  }

  .searchClientItem__info {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

    &--name {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #333333
    }

    &--email {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #878787;
    }

    &--prefixGroup {
      font-weight: normal;
    }
  }
`;