/**
 * @flow
 */

import React from 'react';
import {
  Image,
} from 'semantic-ui-react';
import type { User } from 'types/model';
import type { Dispatch } from 'types/redux';
import styles from './styles';
import './styles.scss';
import _ from 'lodash';

type Props = {
  dispatch: Dispatch,
};

type State = {
};

export default class PublicProfile extends React.Component<Props> {
  state = {
  };

  render() {
    return (
      <div>
      </div>
    );
  }

}
