import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

const CustomModalitySingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <WrapperContent>
        <LabelModality>Modality:</LabelModality>
        <LabelContent>{props.children}</LabelContent>
      </WrapperContent>
    </components.SingleValue>
  );
};

export default CustomModalitySingleValue;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const LabelModality = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  color: #6a778a;
`;

const LabelContent = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  color: #323c47;
  text-transform: capitalize;
`;
