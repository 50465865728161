import styled, { css } from 'styled-components';
import ButtonIcon from 'shared/ButtonIcon';

export const HeaderArea = styled.div`
  display: flex;
  width: 837px;
  margin: auto;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 45px;
`;

export const HeaderText = styled.span`
  margin-left: 18px;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  overflow: hidden;
  line-height: 28px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  color: #151619;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  align-items: center;
`;

export const BackButton = styled.div`
  width: 12px;
  cursor: pointer;
  margin-right: 18px;
`;

export const HeaderLeft = styled.div`
  max-width: calc(100% - 200px);
`;

export const HeaderRight = styled.div`
  .buttonClassName {
    button {
      margin-right: 0;
      background: rgba(94, 89, 255, 0.08) !important;
      border-radius: 4px;

      &:hover,
      &:focus,
      &:active {
        background: rgba(94, 89, 255, 0.1) !important;
      }

      svg {
        margin-right: 3px;
      }
    }
  }
`;

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    border: none !important;
    background: rgba(94, 89, 255, 0.08) !important;

    svg {
      margin-right: 5px;
      path {
        fill: #5e59ff;
      }
    }

    span {
      color: #5e59ff !important;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      border-radius: 4px;
    }
  }
`;
