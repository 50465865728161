import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import BillingSetting from './component';
import { getBillingOverviewData } from 'redux/billing/actions';
import { toggleModal } from 'actions/modal';

const mapStateToProps = (state) => {
  const {
    user,
    rootReducer: {
      billing: { loading },
    },
  } = state;

  return { user, loading };
};

const mapDispatch = (dispatch) => ({
  push: bindActionCreators(push, dispatch),
  getBillingOverviewData: bindActionCreators(getBillingOverviewData, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(BillingSetting);
