import React from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const ModalWrapper = styled(Modal)`
  width: 494px !important;
  height: 200px !important;
  padding: 30px;
  border-radius: 8px !important;

  .content {
    padding: 0 !important;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;

const ModalContent = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #323c47;

  span {
    font-weight: 600;
    word-break: break-word;
  }

  a {
    margin-left: 8px;
    color: #5c5bbd;
    font-weight: bold;
    opacity: 1;
    letter-spacing: -0.6px;
    white-space: nowrap;

    :hover {
      color: #5c5bbd;
    }

    img {
      margin-bottom: -2px;
      margin-right: 3px;
    }
  }
`;

const ModalActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  width: 102px;
  height: 30px;

  background: #ffffff;
  border: 1px solid #d6dae4;
  border-radius: 5px;
  padding: 6px 24px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #818da1;
  cursor: pointer;

  :hover {
    background-color: #f5f7f9;
  }
`;

const AcceptButton = styled.button`
  padding: 5px 30px;
  height: 30px;
  border: 1px solid #ffb224;
  background: #ffb224;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 4px;

  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;

  :hover {
    opacity: 0.9;
  }
`;

const WarningTrialPopup = ({ isModalOpen, isSecondModalOpen, toggleModal, toggleSecondModal, onAccept }) => {
  const handleClose = () => {
    if (isSecondModalOpen) {
      toggleSecondModal(false);
    } else {
      toggleModal(false);
    }
  };
  return (
    <ModalWrapper open={isSecondModalOpen || isModalOpen} onClose={handleClose} closeOnDimmerClick={false}>
      <Modal.Content>
        <ModalHeader>Add Sequence to the Package with Trial on</ModalHeader>
        <ModalContent>
          The Trial will start on the day that the client registers for your package regardless of the start date on
          your Sequence.
          <a
            href="https://help.everfit.io/en/articles/7269312-introducing-free-trial-package"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="learn more" />
            Learn more
          </a>
        </ModalContent>

        <ModalActions>
          <CloseButton onClick={handleClose}>Cancel</CloseButton>
          <AcceptButton onClick={() => onAccept()}>Yes, continue</AcceptButton>
        </ModalActions>
      </Modal.Content>
    </ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { isModalOpen, isSecondModalOpen } = state;
  return {
    isModalOpen,
    isSecondModalOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningTrialPopup);
