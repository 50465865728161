import styled, { css } from 'styled-components';
import Button from 'shared/FormControl/Button';

export const Container = styled.div`
  background-color: #fafbfc;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 969px;
  margin: 0 auto;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 32px;
`;

export const Title = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #222222;
  margin-top: -1px;
`;

export const ButtonSave = styled(Button)`
  padding: 6px 30px;
  color: #818da1;
  width: 120px;
  height: 34px;

  svg {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px !important;

    path {
      fill: #818da1;
    }
  }

  &:disabled {
    visibility: hidden;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 34px 29px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ShowDetailWorkoutTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  margin-bottom: 8px;
`;

export const FeatureLearnMore = styled.a`
  position: relative;
  padding-left: 9px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-43%);
  }

  &:hover {
    color: #5158cf;
  }
`;

export const FeatureLinkTitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #5158cf;
  letter-spacing: -0.18px;
  padding-left: 22px;
`;

export const ShowDetailWorkoutDesc = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
`;
