import React from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import { CDN_URL } from 'constants/commonData';

import { Wrapper, Menu, Item } from './style';

class SelectProgramDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menuStyles: {},
    };

    this.menuRef = React.createRef();
    this.triggerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !prevState.open) {
      if (!this.triggerRef.current) {
        return console.error('Can not find the trigger');
      }

      if (!this.menuRef.current) {
        return console.error('Can not find the menu');
      }

      const triggerRect = this.triggerRef.current.getBoundingClientRect();
      const { height, y } = triggerRect;
      const windowHeight = window.innerHeight;
      const styles = {};

      if (y <= windowHeight - (y + height) || windowHeight - (y + height) > 300) {
        styles.top = '100%';
        styles.maxHeight = Math.min(300, windowHeight - (y + height) - 10);
      } else {
        styles.bottom = '100%';
        styles.maxHeight = Math.min(300, y - 10);
      }

      this.setState({ menuStyles: styles });
    }
  }

  renderMenu = () => {
    const { totalWeek, onSelect, value } = this.props;
    const { menuStyles } = this.state;
    let items = [];

    for (let weekIndex = 0; weekIndex < totalWeek; weekIndex++) {
      items.push(
        <Item className="select-program-day__item week-divide" key={'week-' + weekIndex}>
          <div className="week-divide">Week {weekIndex + 1}</div>
        </Item>,
      );
      const start = 7 * weekIndex;
      const end = start + 7;

      for (let dayIndex = start; dayIndex < end; dayIndex++) {
        items.push(
          <Item
            key={dayIndex}
            className={`select-program-day__item${value === dayIndex ? ' selected' : ''}`}
            onClick={() => {
              if (typeof onSelect === 'function') {
                onSelect(dayIndex);
              }

              this.setState({ open: false });
            }}
          >
            Day {dayIndex + 1}
          </Item>,
        );
      }
    }

    if (!items.length) {
      return null;
    }

    return (
      <Menu className="select-program-day__menu" ref={this.menuRef} style={menuStyles}>
        {items}
      </Menu>
    );
  };

  render() {
    const { open } = this.state;
    const { value, className, inputValue } = this.props;
    let wrapperClass = `select-program-day${open ? ' open' : ''}`;

    if (className) {
      wrapperClass += ' ' + className;
    }

    return (
      <RootCloseWrapper event="click" disabled={!open} onRootClose={() => this.setState({ open: false })}>
        <Wrapper className={wrapperClass}>
          <div className="text" onClick={() => this.setState({ open: !open })} ref={this.triggerRef}>
            {inputValue || (
              <>
                <span>Day {value + 1}</span>
                <img src={`${CDN_URL}/images/arrow_down_black.svg`} alt="" />
              </>
            )}
          </div>
          {this.renderMenu()}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

export default SelectProgramDay;
