import { Map, fromJS } from 'immutable';
import { Types } from './actions';
import { Types as WorkoutTypes } from 'redux/workout-builder/actions';
import { ENTITY_NAME } from 'database/constants';

const INITIAL_STATE = fromJS({ [ENTITY_NAME.S3PresignedURL]: {} });

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ADD_ENTITIES:
      return state.mergeDeep(state, action.payload);

    case Types.UPDATE_ENTITY: {
      const { entity, id, data } = action;
      return state.mergeIn([entity, id], fromJS(data));
    }

    case WorkoutTypes.UPDATE_EXERCISE_IN_FREESTYLE_SECTION: {
      const sections = state.get(ENTITY_NAME.Section).toJS();
      const { sectionId, oldExerciseId, exercise } = action.payload;

      for (const typeId in sections) {
        if (sections[typeId]._id === sectionId) {
          sections[typeId].exercise_references = sections[typeId].exercise_references.map(item => {
            if (item._id === oldExerciseId) {
              return exercise;
            }
            return item;
          });
        }
      }

      return state.merge({ [ENTITY_NAME.Section]: sections });
    }

    case WorkoutTypes.REMOVE_EXERCISE_IN_FREESTYLE_SECTION: {
      const sections = state.get(ENTITY_NAME.Section).toJS();
      const { sectionId, exerciseId } = action.payload;

      for (const typeId in sections) {
        if (sections[typeId]._id === sectionId) {
          sections[typeId].exercise_references = sections[typeId].exercise_references.filter(
            item => item._id !== exerciseId,
          );
        }
      }

      return state.merge({ [ENTITY_NAME.Section]: sections });
    }

    case Types.DELETE_ENTITY: {
      const { entity, id } = action;
      return state.removeIn([entity, id]);
    }

    case Types.DELETE_ENTITIES: {
      const { ids, entity } = action;
      const list = fromJS(ids);
      return list.reduce((newState, value) => {
        return newState.removeIn([entity, value]);
      }, state);
    }

    case Types.UPDATE_ENTITIES: {
      const { entities, deleteEntities } = action.payload;
      let newState;

      if (deleteEntities) {
        const immutableDeleteEntities = fromJS(deleteEntities);
        newState = immutableDeleteEntities.reduce((s1, listId, key) => {
          return listId.reduce((s2, id) => {
            return s2.removeIn([key, id]);
          }, s1);
        }, state);
      }

      const newEntities = fromJS(entities);

      return newEntities.reduce(
        (s1, entities, entityName) =>
          entities.reduce((s2, updatedData, entityId) => s2.mergeIn([entityName, entityId], updatedData), s1),
        newState || state,
      );
    }

    case Types.SET_ENTITIES: {
      const { entities } = action.payload;
      const newEntities = fromJS(entities);

      return newEntities.reduce(
        (s1, entities, entityName) =>
          entities.reduce((s2, updatedData, entityId) => s2.setIn([entityName, entityId], updatedData), s1),
        state,
      );
    }

    case WorkoutTypes.WORKOUT_BUILDER_CLEAR_DATA:
      return state.filter((obj, key) => key === ENTITY_NAME.S3PresignedURL);

    default:
      return state;
  }
};
