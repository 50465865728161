import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 99;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Trigger = styled.div`
  max-height: 30px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #202353;
`;

export const Popup = styled.div`
  display: flex;
  width: 100%;
  height: 310px;
  padding: 10px 2px 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  border-right: 3px solid transparent;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  position: absolute;
  top: 44px;
  left: 0;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);

  font-size: 13px;
  font-weight: 400;
`;

export const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${props => (props.active ? '#5158cf' : '#202353')};
  font-weight: ${props => (props.active ? '600' : '400')};
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  :hover {
    color: #5158cf;
    font-weight: ${props => (props.active ? '600' : '400')};
    border-radius: 5px;
    background: #f0f1ff;
  }

  .checked-icon {
    margin-top: 4px;
    margin-right: 4px;
    path {
      fill: #5158cf;
    }
  }
`;

export const ColorPickerWrapper = styled.div`
  display: flex;
  height: 36px;
  width: 250px;
  align-items: center;
  padding: 8px 10px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid #e1e1ea;
  overflow: hidden;

  ${({ disabled }) => {
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          :hover {
            cursor: pointer;
            background: #f0f1ff;
          }
        `;
  }}
`;

export const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
`;

export const ColorLine = styled.div`
  width: calc(100% - 20px);
  height: 2px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  margin-left: 10px;
`;

export const ColorWrapper = styled.div`
  display: flex;
  gap: 15px;

  .color-label {
    padding-top: 4px;
  }
`;
