import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const FromToPopup = styled(Popup)`
  &.ui.popup {
    width: 186px !important;
    z-index: 2100 !important;
    border-radius: 3px !important;
    border: 1px solid #eee;
    box-shadow: none !important;
  }

  &.ui.bottom.popup {
    margin: 4px 0 0 !important;
  }

  &::before {
    content: '';
    display: none;
  }
`;

export const ButtonSelect = styled.div`
  width: 176px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: 9px 15px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ffffff;
  :hover {
    border-color: #5c5bbd;
  }
  ${props =>
    props.open &&
    css`
      border-color: #5c5bbd;
    `}
`;

export const Label = styled.div`
  color: #000;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Content = styled.div`
  width: 186px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Column = styled.div`
  flex: 1 1;
  ${props =>
    props.left &&
    css`
      border-right: 1px solid #eee;
    `}
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  border-bottom: 1px solid #eee;
  background: #f8f8f8;
  user-select: none;
  ${props =>
    props.left &&
    css`
      border-top-left-radius: 3px;
    `}
  ${props =>
    props.right &&
    css`
      border-top-right-radius: 3px;
    `}

  color: #202353;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const List = styled.div`
  height: 208px;
  overflow: auto;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  height: 33px;
  cursor: pointer;

  :hover {
    color: #5158cf;
    font-weight: 600;
    background: #f6f5ff;
  }

  &.selected {
    color: #5158cf;
    font-weight: 600;
  }

  &.disabled,
  &.disabled:hover {
    color: #999 !important;
    cursor: not-allowed !important;
    user-select: none !important;
    font-weight: 400 !important;
    background: #ffffff !important;
  }
`;
