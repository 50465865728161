import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Types } from './actions';

export const INITIAL_STATE_FORM = {
  loading: false,
  clientsList: [],
  clientsQuery: {
    text_search: '',
    page: 1,
    per_page: 20,
  },
  total: 0,
  isEnd: false,
  workingClient: null,
  summary: [],
  loadingPrint: false,
};

export default (state = INITIAL_STATE_FORM, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_CLIENTS_LIST_REQUEST:
      const newClientsList = payload.page === 1 ? [] : state.clientsList;
      return Object.assign({}, state, {
        loading: true,
        clientsList: newClientsList,
      });

    case Types.GET_CLIENTS_LIST_SUCCESS: {
      let clonedState = cloneDeep(state);
      let newClientsList;
      if (state.clientsQuery.page > 1) {
        newClientsList = concat(clonedState.clientsList, payload.data);
      } else {
        newClientsList = payload.data;
      }
      return Object.assign({}, state, {
        loading: false,
        clientsList: orderBy(uniqBy(newClientsList, '_id'), ['last_answered_at'], ['desc']),
        total: payload.total,
        isEnd: newClientsList.length === payload.total,
      });
    }

    case Types.SELECT_CLIENT_REQUEST: {
      let clonedState = cloneDeep(state);
      if (!clonedState.client_avatar) {
        clonedState.client_avatar = null;
      }
      return Object.assign({}, state, { workingClient: payload.data });
    }

    case Types.GET_CLIENT_ANSWER_DATES_SUCCESS: {
      return Object.assign({}, state, {
        workingClient: {
          ...state.workingClient,
          answerDates: payload.data,
        },
      });
    }

    case Types.GET_CLIENT_ANSWER_DETAILS_REQUEST: {
      return Object.assign({}, state, {
        workingClient: {
          ...state.workingClient,
          workingAnswerDate: {
            _id: payload.data,
          },
        },
      });
    }

    case Types.GET_CLIENT_ANSWER_DETAILS_SUCCESS: {
      let clonedState = cloneDeep(state);
      clonedState = {
        ...clonedState,
        workingClient: {
          ...clonedState.workingClient,
          workingAnswerDate: {},
        },
      };
      let newClientsList = clonedState.clientsList.map(client => {
        if (
          get(client, 'client') === get(payload, 'data.client') &&
          get(payload, 'data.submitted_at') > get(client, 'last_answered_at')
        ) {
          return {
            ...client,
            last_answered_at: get(payload, 'data.submitted_at'),
          };
        }
        return client;
      });
      newClientsList = orderBy(newClientsList, 'last_answered_at', 'desc');
      return {
        ...clonedState,
        workingClient: {
          ...clonedState.workingClient,
          workingAnswerDate: payload.data,
        },
        clientsList: newClientsList,
      };
    }

    case Types.RESET_FORM_RESPONSES: {
      return INITIAL_STATE_FORM;
    }

    case Types.CHANGE_CLIENT_LIST_QUERY_PARAMS: {
      let clonedState = cloneDeep(state);
      return merge(clonedState, {
        clientsQuery: payload.params,
      });
    }

    case Types.GET_FORM_SUMMARY_SUCCESS: {
      return Object.assign({}, state, { summary: payload.data });
    }

    case Types.READ_ALL_FORM_ANSWER_COMMENT_SUCCESS: {
      let clonedState = cloneDeep(state);
      return merge(clonedState, {
        workingClient: {
          workingAnswerDate: { is_unread_comment: false },
        },
      });
    }

    case Types.NEW_FORM_ANSWER_COMMENT: {
      let clonedState = cloneDeep(state);
      return merge(clonedState, {
        workingClient: {
          workingAnswerDate: { is_unread_comment: payload },
        },
      });
    }
    case Types.EXPORT_FORM_RESPONSES_REQUEST: {
      return Object.assign({}, state, { loadingPrint: true });
    }

    case Types.EXPORT_FORM_RESPONSES_SUCCESS:
    case Types.EXPORT_FORM_RESPONSES_FAILED: {
      return Object.assign({}, state, { loadingPrint: false });
    }

    default:
      return state;
  }
};
