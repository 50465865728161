import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCustomBranding } from 'redux/custom-branding/actions';
import SettingEmailBanner from './component';

const mapState = state => {
  const {
    cloudfrontList,
    user,
    rootReducer: {
      customBranding: { loading, emailBanner },
    },
  } = state;

  return { loading, emailBanner, user, cloudfrontList };
};

const mapDispatch = dispatch => ({
  updateCustomBranding: bindActionCreators(updateCustomBranding, dispatch),
});

export default connect(mapState, mapDispatch)(SettingEmailBanner);
