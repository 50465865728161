import styled, { css } from 'styled-components';

export const NavBarRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ShowedProgramContainer = styled.div`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  padding: 0 25px;
  flex: unset;
  overflow: hidden;

  ::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 11px;
    width: 1px;
    background-color: #e8e8e8;
  }

  .program {
    margin-right: 9px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow: hidden;

    > * {
      flex: 0 0 auto;
    }

    .program__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1 1;
      overflow: hidden;

      .name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        flex: 1 1;
        text-overflow: ellipsis;
      }
    }

    .date {
      font-weight: normal;
    }

    a {
      color: inherit;

      :hover {
        color: #726ee4;
      }
    }
  }

  .view-more {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #eaeef1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 0 0 auto;

    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #728096;
      position: relative;
      ::before,
      ::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #728096;
        position: absolute;
        top: 0;
      }

      ::before {
        left: -5px;
      }

      ::after {
        right: -5px;
      }
    }
  }
`;

export const NavBarCenter = styled.div`
  overflow: hidden;
  padding-right: 25px;

  .navbarCenter__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    position: relative;

    .feature_turned_off {
      flex: 0 0 auto;
    }
  }

  ${props =>
    props.isTurnOffSetting &&
    css`
      .navbarCenter__content {
        ${ShowedProgramContainer} {
          flex: 0 0 auto;
        }
      }
    `}
`;

export const NavBarContainer = styled.div`
  &.calendar-nav {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-touch-callout: none) {
        display: flex;
      }
    }
  }
`;

export const BottomCopyWeekWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 23px 30px 22px;
  z-index: 111;
  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
  }
  .cancel-copy {
    cursor: pointer;
    margin-left: 15px;
  }
`;

export const BottomSelectWorkoutWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px 30px;
  z-index: 111;
`;

export const BottomNumberSelect = styled.div`
  width: 156px;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 5px;
  color: #333333;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const BottomNumber = styled.span`
  height: 21px;
  width: 43px;
  background-color: #303030;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;

export const BottomActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .tooltip--remove,
  .tooltip--copy {
    width: 100px;
  }
`;

export const IconActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextActions = styled.span`
  color: #728096;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const BottomActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    ${IconActions} {
      svg {
        path {
          fill: #5e59ff;
        }
      }
    }
    ${TextActions} {
      color: #5e59ff;
    }
  }
`;

export const BottomCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  svg {
    cursor: pointer;
  }
`;

export const TextCancel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CancelBold = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;
