import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientPageComponent from './component';
import { toggleSideBar } from 'actions/sidebar';
import { getClientsFullData, searchClients, activateClients, deleteClients } from 'redux/client/client.actionCreators';
import { getAllSegments, changeClientFilter, resetSelectedSegment } from 'redux/segment/actions';
import { getAllGroups } from 'redux/group/actions';
import { showError } from 'actions/error';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { CLIENT_STATUS } from 'constants/commonData';

const checkSegmentActive = reducer => {
  const {
    segment: { list, selected },
  } = reducer;

  const waitingSegment = (list || []).find(item => item.client_connection === CLIENT_STATUS.waiting);

  if (waitingSegment) {
    return selected === waitingSegment._id;
  }

  return false;
};

const mapStateToProps = state => {
  const { isModalOpen, modal, rootReducer, sideBarVisible, user, cloudfrontList } = state;
  let isTeamAdmin = !user.teams || user.teams.length === 0 || user.teams[0].role !== 1;
  const {
    isGettingClients,
    search,
    total_except_search,
    all_clients_total,
    page,
    per_page,
    total,
    total_active_clients,
    sorter,
    sort,
  } = rootReducer.client;

  return {
    cloudfrontList,
    isGettingClients,
    search,
    total_except_search,
    all_clients_total,
    total_active_clients,
    user,
    isTeamAdmin,
    sideBarVisible,
    isModalOpen,
    modal,
    isShowOwnedClient: rootReducer.client.isShowOwnedClient,
    clientList: rootReducer.client.list,
    filters: rootReducer.segment.filters,
    selectedSegment: rootReducer.segment.selected,
    isPermitDenied: rootReducer.segment.isPermitDenied,
    permission: rootReducer.permission,
    groups: rootReducer.group.list,
    page,
    per_page,
    total,
    sorter,
    sort,
    isWaitingSegmentActive: checkSegmentActive(rootReducer),
  };
};

const changeShowOwnedClient = (dispatch, isShowOwnedClient) => {
  dispatch({
    type: 'IS_SHOW_OWNED_CLIENT',
    isShowOwnedClient: isShowOwnedClient,
  });
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    changeShowOwnedClient: isShowOwnedClient => changeShowOwnedClient(dispatch, isShowOwnedClient),
    toggleSideBar: visible => dispatch(toggleSideBar(visible)),
    getClientsFullData: bindActionCreators(getClientsFullData, dispatch),
    searchClients: bindActionCreators(searchClients, dispatch),
    changeClientFilter: bindActionCreators(changeClientFilter, dispatch),
    resetSelectedSegment: bindActionCreators(resetSelectedSegment, dispatch),
    showError: bindActionCreators(showError, dispatch),
    getAllSegments: bindActionCreators(getAllSegments, dispatch),
    getAllGroups: bindActionCreators(getAllGroups, dispatch),
    activateClients: bindActionCreators(activateClients, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    deleteClients: bindActionCreators(deleteClients, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPageComponent);
