import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #000;
`;

export const AvailableSeat = styled.div`
  background: #ebedf4;
  border: 1px solid #d8dceb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 6px 9px;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #000;
  margin: 0 12px 0 20px;

  .value {
    font-weight: 600;
  }
`;

export const UpgradeButton = styled.button`
  box-shadow: none;
  outline: none;
  border: none;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ff7a00;
  background: transparent;
  

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
