import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const StartWorkout = styled.div`
  display: inline-block;
  padding: 8px 13px;
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  text-align: center;
  color: #7470ef;
  background: #ffffff;
  border-radius: 13px;
`;

export const Header = styled.div`
  margin-bottom: 10px;
  padding: 0 10px;

  .today {
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    text-transform: uppercase;
  }

  .hello {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #1e0a3c;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const WorkoutContent = styled.div`
  z-index: 2;
  position: relative;
`;

export const Workout = styled.div`
  border-radius: 5.41063px;
  padding: 27px 25px 13px;
  margin-bottom: 13px;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 124px;

  .workout__title {
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    color: #4fdfac;
    margin-bottom: 4px;
  }

  .workout__name {
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 14px;
  }
`;

export const TaskName = styled.div`
  flex: 1 1;
  overflow: hidden;
  margin: 0;
`;

export const TaskItem = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  color: #1e0a3c;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    flex: 0 0 41px;
  }

  img {
    opacity: 0.5;
    width: 5px;
  }
`;

export const TaskDivide = styled.div`
  margin: 6px 0;
  height: 1px;
  background-color: #767676;
  opacity: 0.1;
`;

export const Task = styled.div`
  background: #ffffff;
  padding: 11px 9px;
  margin-bottom: 6px;
  border-radius: 5px;
  box-shadow: 0px 5.41063px 12.4444px rgba(146, 146, 210, 0.15);

  .task__header {
    font-size: 6px;
    line-height: 8px;
    color: #1e0a3c;
    margin-bottom: 6px;
    border-bottom: 0.5px solid rgb(118 118 118 / 10%);
    padding-bottom: 5px;

    & .bold {
      font-size: 8px;
      line-height: 11px;
      font-weight: bold;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MenuFood = styled.div`
  background: #ffffff;
  padding: 11px 0 11px 9px;
  margin-bottom: 6px;
  border-radius: 5px;
  box-shadow: 0px 5.41063px 12.4444px rgba(146, 146, 210, 0.15);

  .task__header {
    width: calc(100% - 9px);
    font-size: 6px;
    line-height: 8px;
    color: #1e0a3c;
    padding-right: 9px;
    margin-bottom: 6px;
    border-bottom: 0.5px solid rgb(118 118 118 / 10%);
    padding-bottom: 5px;
    font-weight: bold;
  }

  .task__slider {
    display: flex;
    align-items: center;
    overflow: hidden;

    .item {
      position: relative;
      margin-right: 10px;

      img {
        width: 36.8px;
        height: 36.8px;
      }

      .number {
        position: absolute;
        bottom: -4px;
        right: -4px;

        .text {
          position: absolute;
          left: 4.5px;
          top: 4px;
          color: white;
          font-size: 6.5px;
          line-height: 10px;
          font-weight: 700;
        }
      }

      .days {
        font-size: 6.144px;
        line-height: 8px;
        margin-bottom: 4.6px;

        &.bold {
          font-weight: 700;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Calendar = styled.div`
  background: #ffffff;
  padding: 11px 9px;
  margin-bottom: 6px;
  border-radius: 5px;
  box-shadow: 0px 5.41063px 12.4444px rgba(146, 146, 210, 0.15);

  .task__footer {
    color: #7b7e91;
    font-size: 7px;
    line-height: 11px;
    text-align: center;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CalenarRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #1e0a3c;
  font-size: 6px;
  line-height: 8px;
  margin-bottom: 2.5px;

  .item {
    display: inherit;
    width: 18px;
    height: 18px;
    justify-content: inherit;
    align-items: inherit;
    border-radius: 50%;

    &.num {
      font-size: 8px;
      line-height: 11px;
      padding-left: 1px;
      padding-bottom: 1px;

      &.missed {
        border: 0.5px solid #f06b6b;
      }

      &.active {
        border: 0.5px solid #7470ef;
      }
    }

    &.active {
      color: ${props => {
        return '#7470EF';
      }};
      font-weight: 700;
    }
  }

  &:first-child {
    align-items: flex-end;
  }
`;

export const Content = styled.div`
  padding: 0 10px;

  .let-do-this {
    font-size: 6px;
    line-height: 8px;
    color: #1e0a3c;
    margin-bottom: 7px;

    & .bold {
      font-size: 8px;
      line-height: 11px;
      font-weight: bold;
    }
  }
`;

export const NotificationIcon = styled.div`
  width: 11px;
  height: 13px;
  position: absolute;
  background: transparent url(${CDN_URL}/images/app_notification.svg) no-repeat center;
  background-size: contain;
  top: 28px;
  right: 14px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f7f8fc;
  z-index: 1;
  position: relative;

  ${StartWorkout} {
    color: ${props => props.primaryColor};
  }

  ${Header} {
    .today {
      color: ${props => props.primaryColor};
    }
  }

  ${Workout} {
    background: transparent url(${props => props.workoutBackground}) no-repeat center;
    background-size: cover;
  }

  ${Task} {
    ${TaskItem} {
      svg.active-checkbox {
        path {
          fill: ${props => props.primaryColor};
        }
      }
    }
  }

  ${CalenarRow} {
    .active {
      border-color: ${props => props.primaryColor} !important;
      color: ${props => props.primaryColor} !important;
    }
    .missed {
      border-color: #f06b6b !important;
    }
    .checked {
      background: ${props => props.secondaryColor} !important;
      svg {
        transform: translate(-1px, 1px);
      }
    }
  }

  ${props =>
    props.useCustomTitle &&
    css`
      ${Workout} {
        .workout__title {
          color: #ffffff;
          opacity: 0.6;
        }
      }
    `}

  ${props =>
    props.customBackground &&
    css`
      ${Workout} {
        :before {
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          background-color: #131313;
          opacity: 0.2;
          position: absolute;
        }
      }
    `}

  ${props =>
    props.customTheme &&
    css`
      background-color: #f9f9f9;

      ${Task} {
        box-shadow: 0px 5px 23px rgba(192, 192, 192, 0.1);
        ${TaskItem} {
          svg.active-checkbox {
            path {
              fill: ${props => props.secondaryColor};
            }
          }
        }
      }
    `}
`;
