// libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

// store
import { toggleModal } from 'actions/modal';
import { getGroupMetricsLibrary, changeQueryParams } from 'redux/metric-group-library/action';

// components
import GroupSkeleton from '../Skeleton';
import GroupItem from './GroupItem';
import NewGroupModal from '../NewGroupModal';

// constants
import { PER_PAGE } from 'components/MetricGroupLibrary/constants';

// assets
import { ReactComponent as AddEmptyMetricIcon } from 'assets/icons/empty_add_metric_group.svg';
import { ReactComponent as PlusEmptyIcon } from 'assets/icons/plus_thin.svg';

import * as S from './style';

function Content(props) {
  const { list, getGroupMetrics, toggleModal, loading, filters, total, changeQueryParams } = props;
  const page = (filters || {}).page;

  useEffect(() => {
    getGroupMetrics();
  }, []);

  const handleOpenAddNewModal = () => {
    toggleModal(true, <NewGroupModal toggleModal={toggleModal} />);
  };

  const renderEmptyMetric = () => {
    if ((filters || {}).q)
      return (
        <S.EmptyWrapper>
          <S.NoSearchResultLabel>No results found.</S.NoSearchResultLabel>
        </S.EmptyWrapper>
      );

    return (
      <S.EmptyWrapper>
        <S.EmptyIcon>
          <AddEmptyMetricIcon />
        </S.EmptyIcon>
        <S.EmptyLabel>No metric groups yet</S.EmptyLabel>
        <S.AddMetricButton onClick={handleOpenAddNewModal}>
          <PlusEmptyIcon className="plus-icon" />
          <span>Add Your First Metric Group</span>
        </S.AddMetricButton>
      </S.EmptyWrapper>
    );
  };

  const handleScroll = event => {
    const { scrollHeight = 0, scrollTop = 0, clientHeight = 0 } = event.target;
    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    const isLastPage = page >= Math.ceil(total / PER_PAGE);

    if (bottom && !loading && !isLastPage) {
      const newPage = page + 1;
      changeQueryParams({ page: newPage });
    }
  };

  const onScrollDebounce = debounce(handleScroll, 300);

  return (
    <S.Wrapper
      onScroll={event => {
        event.persist();
        onScrollDebounce.call(null, event);
      }}
    >
      {isEmpty(list) && !loading ? (
        renderEmptyMetric()
      ) : (
        <S.ContentWrapper>
          {loading && page === 1
            ? Array(8)
                .fill(null)
                .map((_, index) => <GroupSkeleton key={index} />)
            : list.map(item => <GroupItem key={item._id} item={item} />)}
          {loading &&
            page > 1 &&
            Array(8)
              .fill(null)
              .map((_, index) => <GroupSkeleton key={index} />)}
        </S.ContentWrapper>
      )}
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { metricGroupLibrary },
  } = state;
  const { groups = [], loading = false, filters = {}, total = 0 } = metricGroupLibrary;
  return {
    list: groups,
    loading,
    filters,
    total,
  };
};

const mapDispatchToProps = dispatch => ({
  getGroupMetrics: bindActionCreators(getGroupMetricsLibrary, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
