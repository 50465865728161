import { connect } from 'react-redux';
import { get } from 'lodash';
import ClientProfileOverview from './component';
import {
  starClient,
  requestConsultationFileUploadUrl,
  deleteConsultationFile,
  getClientProfile,
} from 'redux/client/client.actionCreators';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = state => {
  const { user, rootReducer } = state;
  let isNewMessage = false;
  let rooms = rootReducer.inboxNew.rooms || [];
  let room = rooms.find(item => item.id.includes(rootReducer.client.selected));
  if (room) {
    isNewMessage = get(room[user._id], 'unread') > 0;
  }
  return {
    user: user,
    selectedClient: rootReducer.client.workingClientDetail,
    isNewMessage,
    consultationDoc: rootReducer.client.workingClientDetail.consultation_documents,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    starClient: (data, isStar) => {
      return dispatch(starClient(data, isStar));
    },
    uploadConsultationFile: (filename, clientId) => dispatch(requestConsultationFileUploadUrl(filename, clientId)),
    deleteConsultationFile: clientId => dispatch(deleteConsultationFile(clientId)),
    getClientProfile: clientId => dispatch(getClientProfile(clientId, true)),
    toggleConfirmModal: (isOpen, modal) => dispatch(toggleConfirmModal(isOpen, modal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileOverview);
