import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import { KEY_CODE } from 'constants/commonData';
import { isESCPress } from 'utils/commonFunction';
import * as S from '../style';

import { useMyContext } from '../context/ProgramTemplatesContext';
import FilterModalContent from './FilterModalContent';

const FilterModal = () => {
  const { openFullscreenModal, openFilterModal, toggleFilterModal } = useMyContext();
  const opened = openFullscreenModal && openFilterModal;
  const handleKeyDown = event => {
    if (event.keyCode === KEY_CODE.esc && isESCPress(event.key)) {
      toggleFilterModal();
    }
  };
  return (
    <S.FullscreenModalWrapper
      className={classNames('filter', {
        open: openFullscreenModal && openFilterModal,
      })}
    >
      <RootCloseWrapper event="click" disabled={!openFilterModal} onRootClose={handleKeyDown}>
        <S.FullscreenModalContainer
          className={classNames('filter', {
            open: opened,
          })}
        >
          {openFilterModal && <FilterModalContent />}
        </S.FullscreenModalContainer>
      </RootCloseWrapper>
    </S.FullscreenModalWrapper>
  );
};

export default FilterModal;
