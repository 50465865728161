import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #7B7E91;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .label {
    ${baseText}
    width: 96px;
  }
  .list {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .item {
    ${baseText}
    font-weight: 400;
    color: #202353;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 36px;
    border: 1px solid #e1e1ea;
    border-radius: 20px;
    padding: 0 14px 0 6px;
    cursor: pointer;
    .checker {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #e1e1ea;
      background-color: #fff;
    }
    &.active {
      background: #f0f1ff;
      border: 1px solid #5158cf;
      .checker {
        border: 1px solid #5158cf;
        &::before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #5158cf;
        }
      }
    }
    &:hover {
      background: #f0f1ff;
      .checker {
        border: 1px solid #5158cf;
      }
    }
  }
`;
