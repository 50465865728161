import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as S from './styles';
import { createObjectURL, mediaLog, readAsArrayBufferAsync, revokeObjectURL } from 'utils/commonFunction';
import FileUpload from 'shared/FileUpload';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { ReactComponent as EditBannerIcon } from 'assets/icons/edit_banner_icon.svg';
import { getPresignedUploadUrl } from 'utils/commonFunction';
import { FILE_LIMIT_MESSAGE } from 'components/CommunityForum/constants';
import { FORMAT_ACCEPT_UPLOAD_IMAGE, FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF } from 'constants/commonData';

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
};

class UploadCoverImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_STATE,
      src: props.src,
    };
    this.maxSize = 25;
    this.validateExtentions = props.notAllowImgGif ? FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF : FORMAT_ACCEPT_UPLOAD_IMAGE;
    this.uploadConfigs = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { src } = nextProps;

    if (!_.isEqual(src, this.state.src)) {
      this.setState({ src });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }
  }

  handleSelectFile = async file => {
    const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-forum', file);

    if (!uploadUrl || !configs) {
      return;
    }
    const fileBuffer = await readAsArrayBufferAsync(file);
    const { name, size: fileSize, type: fileType } = file;
    mediaLog({
      status: 1,
      name,
      fileSize,
      fileType,
      description: 'Send a file via forum',
    });

    this.uploadConfigs = {
      url: uploadUrl,
      method: 'put',
      data: file,
      headers: { 'Content-Type': file.type },
    };

    this.setState({
      src: createObjectURL(file),
      isLocalUrl: true,
      file,
      updateConfigs: configs.url,
    });
  };

  onUploadSuccess = response => {
    const { config } = response;
    const {
      data: { name, size: fileSize, type: fileType },
    } = config;
    mediaLog({
      status: 2,
      name,
      fileSize,
      fileType,
      description: 'Upload success file via forum banner',
    });
    if (this._isMounted) {
      const { updateConfigs } = this.state;
      this.uploadConfigs = null;
      this.setState({ file: null, uploadingURL: null }, () => {
        this.props.onUploadSuccess({ src: updateConfigs });
      });
    }
  };

  onCancelUpload = () => {
    if (this._isMounted) {
      revokeObjectURL(this.state.src);
      this.uploadConfigs = null;
      this.setState({ ...DEFAULT_STATE }, () => {
        this.props.onEndUpload();
      });
    }
  };

  render() {
    const { src, file } = this.state;

    if (!src) {
      return (
        <S.Wrapper className={this.props.className}>
          <S.SelectBanner
            trigger={
              <S.EditButton className="show-on-hover">
                <EditBannerIcon /> Edit
              </S.EditButton>
            }
            onSelect={this.handleSelectFile}
            accept={this.props.notAllowImgGif ? FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF : FORMAT_ACCEPT_UPLOAD_IMAGE}
            validateExtentions={this.validateExtentions}
            maxSize={this.maxSize}
            fileSizeLimitMessage={FILE_LIMIT_MESSAGE.IMAGE_FILE}
          />
        </S.Wrapper>
      );
    }

    return (
      <S.Wrapper src={src} className={this.props.className}>
        <S.SelectBanner
          trigger={
            <S.EditButton className="show-on-hover">
              <EditBannerIcon /> Edit
            </S.EditButton>
          }
          onSelect={this.handleSelectFile}
          accept={this.props.notAllowImgGif ? FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF : FORMAT_ACCEPT_UPLOAD_IMAGE}
          validateExtentions={this.validateExtentions}
          maxSize={this.maxSize}
          fileSizeLimitMessage={FILE_LIMIT_MESSAGE.IMAGE_FILE}
        />
        {file && (
          <FileUpload
            onSuccess={this.onUploadSuccess}
            onError={() => {}}
            onCancel={this.onCancelUpload}
            configs={this.uploadConfigs}
          />
        )}
        {!file && this.props.children}
      </S.Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadCoverImage);
