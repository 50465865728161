import { TextBase } from 'components/MetricGroupLibrary/style';
import { CDN_URL } from 'constants/commonData';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-add-group-modal {
    margin: unset !important;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    box-shadow: unset !important;
    width: ${({ isAddMetric }) => (isAddMetric ? '562px' : '525px')};
  }
  &.ui.modal.evf-add-group-modal > .header,
  &.ui.modal.evf-add-group-modal > .content,
  &.ui.modal.evf-add-group-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  padding: 20px 30px 0px 20px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  .close-button {
    position: absolute;
    display: flex;
    top: -8.5px;
    right: -8.5px;
    .close-icon {
      width: 18px;
      height: 18px;
    }
  }
`;

export const AddMetricHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .metric-search {
    height: 30px;
    width: 224px;

    input {
      padding-right: 10px;
      background: url(${CDN_URL}/images/search_2.svg) no-repeat;
      padding-left: 40px;
      background-size: auto;
      background-position: 15px center;
      background-color: #f8f8f8;

      :hover {
        border: 1px solid #5158cf;
      }
    }

    input::placeholder {
      font-size: 12px !important;
    }

    svg {
      right: 13px;
      g {
        path {
          fill: #a3a3b5 !important;
        }
      }
    }
  }
`;

export const AddMetricHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  ${TextBase}
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
  color: #202353;
  gap: 18px;
  margin-left: 15px;
  margin-bottom: 30px;

  .back-icon {
    cursor: pointer;
    path {
      fill: #202353;
    }
  }
`;

export const HeaderTitle = styled.div`
  ${TextBase}
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ShareWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 7px;
  margin-right: 7px;
  .__react_component_tooltip.app-tooltip {
    background-color: #333;
    border-radius: 4px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #333 !important;
    bottom: -10px !important;
  }

  .ownership.action-menu__content {
    right: -7px;
    top: 32px;
    width: 312px;
    border-radius: 8px;

    .image-button > div {
      padding-left: 10px;
    }

    .ui.selection.dropdown {
      padding: 11px 15px !important;
    }

    .ui.dropdown > .text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      word-wrap: nowrap;
      white-space: nowrap;
    }

    ::before {
      visibility: hidden;
    }

    .field.client_type_field.share-with-org {
      margin-top: 0;
    }

    .client_type_field {
      margin-left: -7px;
    }

    .ui.dropdown.ownership-dropdown {
      border-radius: 5px !important;
    }

    .action-menu.open .action-menu__toggle-button {
      background-color: #f0f1ff !important;
      background: url(${CDN_URL}/images/teammate_purple.svg) center center no-repeat !important;
    }

    .ui.selection.active {
      border: 1px solid #5158cf !important;

      .menu {
        padding: 10px;
        margin-top: 10px;
        box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
        border-radius: 10px;
        max-height: 232px;

        .item {
          display: flex;
          padding: 10px !important;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 5px;
          background: #fff;
          color: #202353;
          font-size: 13px;
          line-height: 150%;
          white-space: nowrap;
          border-top: none;
          span {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 200px;
          }
          :hover {
            background-color: #f0f1ff !important;
            color: #5158cf;
          }
        }
        .selected.item {
          background: url(${CDN_URL}/images/check_mark_purple.svg) no-repeat 223px center;
          background-size: 13px 13px;
          color: #5158cf !important;
        }
      }
    }

    .ui.dropdown > .dropdown.icon {
      margin-right: -3px;
      margin-top: -5px;
      background: url(${CDN_URL}/images/arrow-down-grey.svg) no-repeat center center;
      :before {
        visibility: hidden;
      }
    }
  }
  .ownership.action-menu__toggle-button .teammate-icon {
    width: 24px;
    height: 24px;
  }

  .bg-transparent.bg-ownership.image-button {
    margin-bottom: 12px;
  }
`;

export const HeaderInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LimitTitle = styled.div`
  ${TextBase}
  font-size: 12px;
  font-weight: 400;
  color: ${({ isFull }) => (isFull ? '#ea314a' : '#7b7e91')};
  margin-left: 10px;
`;

export const ValidateArea = styled.div`
  height: 26px;
`;

export const ValidateMessage = styled.div`
  ${TextBase}
  color:  #EA314A;
  font-size: 12px;
  font-weight: 400;
`;

export const HeaderInput = styled.input`
  outline: unset;
  padding: 0 10px;
  line-height: 40px !important;
  ${TextBase}
  color: #202353;
  font-size: 19px;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  min-width: 431px;
  margin-bottom: 2px;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:focus,
      &:hover {
        border: 1px solid #5158cf;
        background: #fff;
      }
    `}

  ::placeholder {
    font-size: 19px;
    color: #a3a3b5;
    font-weight: 400 !important;
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      border: 1px solid #ea314a !important;
      background: #fef5f6 !important;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isAdd }) => (isAdd ? '420px' : '410px')};
`;

export const Header = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
`;

export const Action = styled.div`
  display: flex;
  padding: 15px 29px 15px 15px !important;
  justify-content: ${({ isAddMetric }) => (isAddMetric ? 'flex-end' : 'space-between')};
  align-items: flex-end;
  align-self: stretch;
  border-top: 1px solid #f0f0f2;
  width: 100%;
  height: 60px;
  z-index: 2;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const EmptyIcon = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 77px;
`;

export const EmptyTitle = styled.div`
  ${TextBase}
  color: #202353;
  font-size: 13px;
`;

export const AddMetricButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 10px 20px;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #fff;
  ${TextBase}
  font-size: 13px;
  color: #202353;

  :hover {
    background: #f0f1ff;
    color: #5158cf;
    .plus-icon {
      path {
        fill: #5158cf;
      }
    }
  }

  .plus-icon {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
`;

export const RightAction = styled.div`
  display: flex;
  gap: 5px;
`;

export const SaveButton = styled(Button)`
  display: flex;
  height: 30px;
  padding: 10px 29px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  max-height: 30px;
  ${TextBase}
  font-size: 12px;
  cursor: ${({ notAllowed }) => (notAllowed ? ' not-allowed' : 'pointer')};

  :hover {
    opacity: 0.9;
  }

  :disabled {
    background-color: #eaeef1;
    color: #7b7e91;
  }
`;

export const AddMetric = styled(Button)`
  display: flex;
  height: 30px;
  max-height: 30px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border: transparent;
  gap: 5px;
  color: #5158cf;
  ${TextBase}
  font-size: 12px;
  border-radius: 5px;

  :hover {
    background: #f0f1ff;
  }

  .plus-icon {
    width: 16px;
    height: 16px;
    margin-right: 0;
  }
`;

export const SelectMetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 9px 0 30px;
  z-index: 2;
`;

export const SelectedMetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 9px 0 30px;
  z-index: 2;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  z-index: 1;
  position: relative;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }
`;

export const NoResult = styled.div`
  ${({ hasTextSearch }) =>
    hasTextSearch
      ? css`
          margin-top: 148px;
          font-size: 13px;
        `
      : css`
          font-size: 15px;
          margin-top: 200px;
        `}

  ${TextBase}
  margin-right: 15px;
  color: #202353;
  text-align: center;
`;

export const Placeholder = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  border: dashed 1px #5158cf;
`;

export const HeaderTable = styled.div`
  width: 502px;
  display: flex;
  padding: 6px 30px 6px 7px;
  align-items: center;
  align-self: stretch;
  ${TextBase}
  color: #7B7E91;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #f8f8f8;
`;

export const HeaderSelectedTable = styled.div`
  display: flex;
  padding: 0px 30px 7px 5px;
  align-items: center;
  align-self: stretch;
  ${TextBase}
  color: #7B7E91;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 2px;
  margin-left: 20px;
`;

export const HeaderMetricLabel = styled.div`
  display: flex;
  width: 264px;
  padding-right: 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;

  .checkbox-metric-icon {
    font-size: 13px;
    margin: 0 5px 0 2px;
    .checkbox-icon {
      border-radius: 5px;
      background-size: 69%;
    }
  }
`;

export const HeaderSelectMetricLabel = styled.div`
  display: flex;
  width: 220px;
  align-items: center;
  align-self: stretch;
`;

export const MetricName = styled.div`
  display: flex;
  width: 264px;
  padding-right: 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  font-weight: 600;

  .metric-name {
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 190px;
  }

  .checkbox-metric-icon {
    font-size: 13px;
    margin: 0 5px;
    .checkbox-icon {
      border-radius: 5px;
      background-size: 69%;
    }
  }
`;

export const SelectedMetricName = styled.div`
  display: flex;
  width: 244px;
  padding-right: 10px;
  padding-left: 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-weight: 600;

  span {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const MetricItemWrapper = styled.div`
  display: flex;
  height: 52px;
  min-height: 52px;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 5px;
  margin-right: 21px;
  border-bottom: 1px solid #e1e1ea;
  cursor: pointer;
  background-color: ${({ checked }) => checked && '#fbfbff'};
  ${TextBase}
  font-weight: 400;
  font-size: 13px;
  color: #202353;
  :hover {
    background: #fbfbff;
  }
`;

export const TrailingIconWrapper = styled.div`
  display: flex;
  align-items: center;
  visibility: hidden;
  line-height: 0;
  .trailing-item {
    width: 13px;
    height: 13px;
    path {
      fill: #a3a3b5;
    }
  }
`;

export const RemoveGroupIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  visibility: hidden;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-top: 10px;
  :hover {
    background-color: #ffd8dd;
    .remove-group-icon {
      path {
        fill: #ea314a;
      }
    }
  }

  .__react_component_tooltip.app-tooltip {
    background-color: #333;
    border-radius: 4px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #333 !important;
    bottom: -10px !important;
  }
`;

export const SelectedMetricItemWrapper = styled.div`
  display: flex;
  height: 48px;
  min-height: 48px;
  width: 466px;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  background-color: ${({ checked }) => checked && '#fbfbff'};
  ${TextBase}
  font-weight: 400;
  font-size: 13px;
  color: #202353;
  margin-right: 21px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;

  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 1px solid #5158cf;
      .trailing-item {
        visibility: visible;
      }
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        border: 1px solid #5158cf;
        ${TrailingIconWrapper}, ${RemoveGroupIcon} {
          visibility: visible;
        }
      }
    `}
`;

export const HeaderTypeLabel = styled.div`
  display: flex;
  width: 150px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const HeaderSelectedTypeLabel = styled.div`
  display: flex;
  width: 121px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const MetricType = styled.div`
  display: flex;
  width: 150px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const SelectedMetricType = styled.div`
  display: flex;
  width: 121px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const HeaderUnitLabel = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
`;

export const MetricUnit = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
  align-self: stretch;
`;

export const SelectedMetricUnit = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
  align-self: stretch;
  width: 63px;
`;
