import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  box-shadow: 0px 6px 12px rgba(30, 10, 60, 0.05);
  height: 62px;
  position: relative;
`;

export const LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 32px;
  ${'' /* padding-right: 22px; */}

  .back-button-container {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    :hover {
      span.form-library {
        text-decoration: underline;
        color: #5e59ff;
      }
      svg path {
        stroke: #5e59ff;
      }
    }
  }

  svg {
    margin-right: 4px;
  }

  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #000;
  }

  span.form-library {
    cursor: pointer;
    ${'' /* :hover {
      text-decoration: underline;
      color: #5e59ff;
    } */}
  }
  span.form-name {
    color: #5e59ff;
    max-width: 330px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
`;

export const CenterHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-61%, -25%);
`;

export const ContentType = styled.div`
  padding-bottom: 19px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  opacity: 0.6;
  margin-right: 30px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  :last-child {
    margin-right: 0;
  }

  ${props =>
    props.isActive &&
    css`
      font-weight: 700;
      color: #5158cf;
      opacity: 1;
      border-bottom: 2px solid #5158cf;
    `}
`;

export const RightHeaderWrapper = styled.div`
  display: flex;
  width: 440px;
  justify-content: flex-end;
  padding: 13px 36px 14px 0;

  button {
    padding: 7px 20px;
    line-height: 20px;
  }

  .preview-button {
    margin-right: 5px;
    svg {
      width: 14px;
      height: 11px;
      margin-right: 5px !important;
    }
  }

  .publish-button {
    :hover {
      background-color: ${props => (props.isEditMode ? '#5e59ff' : '#38c350')};
    }
    background-color: ${props => (props.isEditMode ? '#5158cf' : '#36b34b')};

    svg {
      margin-right: 5px !important;
    }
  }
  .edit-button {
    svg {
      margin-right: 5px !important;
    }
    :disabled {
      background-color: #fff;
    }
  }
  .publish-changes-button {
    background-color: rgb(81, 88, 207);
    :hover {
      background-color: rgb(81, 88, 207, 0.9);
    }
    width: 162px;
    margin-left: 5px;
    svg {
      margin-right: 5px;
      path {
        fill: #ffffff;
      }
    }
  }
`;
