import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
`;

const limitText = `
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const RecipeDesc = styled.div`
  position: relative;
  margin-top: 5px;
  .blank {
    display: none;
  }
  .desc-prime {
    display: inline-flex;
    height: 7px;
    padding-left: 10px;
    .desc-prime-icon {
      flex-shrink: 1;
    }
  }
  .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    border: 1px solid #5158cf;
    background-color: #fff !important;
  }
  .text-editable__container {
    padding: 0 21px 0 10px;
    pointer-events: none;
    .text-editable__input {
      ${baseText}
      font-size: 13px;
      line-height: 23px;
      color: #202353;
      ${limitText}
      ${props =>
        props.hasDescription &&
        css`
          display: -webkit-box;
        `}
      ${props =>
        !props.hasViewMode &&
        css`
          -webkit-line-clamp: 3;
          line-clamp: 3;
        `}
      :empty::before {
        ${baseText}
        font-size: 13px;
        line-height: 23px;
        color: #a3a3b5;
        opacity: 1;
      }
    }
  }
  ${props =>
    props.isEditMode &&
    css`
      .text-editable__container {
        padding: 7px 16px 7px 10px;
        pointer-events: auto;
        .text-editable__input {
          line-height: 20px;
          :empty::before {
            line-height: 20px;
          }
        }
      }
    `}

  ${props =>
    props.isEditMode &&
    props.hasViewMode &&
    css`
      .text-editable__container {
        pointer-events: unset;
        max-height: 81px;
        overflow-y: scroll;
        padding: 7px 0 7px 10px;
        ::-webkit-scrollbar {
          width: 16px !important;
        }
        ::-webkit-scrollbar-thumb {
          min-height: 30px !important;
          background: #e1e1ea !important;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box !important;
          border-radius: 9999px;
        }
      }
    `}
`;

export const SeeMore = styled.span`
  ${baseText}
  font-weight: 600;
  color: #5158cf;
  padding: 0 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CustomModalDesc = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-desc-modal {
    margin: unset !important;
    border-radius: 5px !important;
    border: 1px solid #f0f0f2;
    background-color: #fff !important;
    box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2) !important;
    width: 685px;
    max-height: 630px;
  }
  &.ui.modal.evf-desc-modal > .header,
  &.ui.modal.evf-desc-modal > .content {
    padding: unset !important;
    background-color: transparent !important;
  }
  &.ui.modal.evf-desc-modal > .content {
    height: calc(100% - 69px);
  }
  .close-button {
    right: -10px;
  }
`;

export const HeaderWrapper = styled.div`
  padding: 30px 30px 0;
  margin-bottom: 10px;
  height: 59px;

  .desc-prime {
    path {
      fill: #202353;
    }
  }
`;

export const ContentDesc = styled.div`
  margin-right: 11px;
  padding: 0 10px 30px 29px;
  height: 100%;
  overflow: scroll;
  white-space: break-spaces;

  ${baseText}
  line-height: 23px;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
  }
`;
