import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import classNames from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';

import { toggleModal } from 'actions/modal';
import { updateMultipleClientMetric, updateMultipleClientMetricRequest } from 'redux/client/client.actionCreators';
import BodyMetricUpdateAll from 'components/BodyMetricUpdateAll';
import DropdownOption from 'components/BodyMetricChartNew/components/DropdownOption';
import { TIME_OPTIONS_METRIC_OVERVIEW } from 'components/BodyMetricChartNew/constants';
import MetricOverviewDraggable from './MetricOverviewDraggable';

function MetricOverview({
  metricTypes,
  client,
  push,
  toggleModal,
  preferences,
  units,
  updateMultipleClientMetric,
  updateMultipleClientMetricRequest,
  isRequesting,
}) {
  const [newOverviewMetrics, setNewOverViewMetrics] = useState([]);
  const [selectedRange, setSelectedRange] = useState(TIME_OPTIONS_METRIC_OVERVIEW.last_4_week.value);

  const timeOptions = useMemo(() => Object.values(TIME_OPTIONS_METRIC_OVERVIEW), []);

  const overviewMetrics = useMemo(() => {
    const items = metricTypes.filter(item => item.overview).slice(0, 4);
    return items.sort((a, b) => moment(a.last_pin_at).unix() - moment(b.last_pin_at).unix());
  }, [metricTypes]);

  const isMetricEnable = useMemo(() => {
    return get(
      preferences.find(item => item.type === 'body_metric'),
      'state',
      false,
    );
  }, [preferences]);

  if (!isMetricEnable || !overviewMetrics.length) {
    return null;
  }

  useEffect(() => {
    if (newOverviewMetrics.length === 0) {
      setNewOverViewMetrics(overviewMetrics);
    }
  }, [overviewMetrics]);

  const redirectToMetric = () => {
    push(`/home/client/${client}/metrics`);
  };

  const updateResultsSuccessCallback = () => {
    // trick to refetch data for chart
    const currentRange = selectedRange;
    setSelectedRange(() => '');
    setTimeout(() => {
      setSelectedRange(() => currentRange);
    }, 10);
  };

  const handleUpdateAll = () => {
    toggleModal(true, <BodyMetricUpdateAll updateSuccessCallback={updateResultsSuccessCallback} />);
  };

  const handleClickOnChart = metricCode => () => {
    push(`/home/client/${client}/metrics?unique_code=${encodeURIComponent(metricCode)}&time_range=${selectedRange}`);
  };

  const checkIsDoubleChart = (index, length) => {
    if (length === 4 || (length === 3 && index > 0)) return true;
    return false;
  };

  const handleDrop = ({ source, target }) => {
    if (source._id === target._id) return;
    const sourceIndex = newOverviewMetrics.findIndex(item => item._id === source._id);
    const targetIndex = newOverviewMetrics.findIndex(item => item._id === target._id);
    newOverviewMetrics[sourceIndex] = target;
    newOverviewMetrics[targetIndex] = source;

    setNewOverViewMetrics([...newOverviewMetrics]);

    // save list re-ordered
    const items = newOverviewMetrics.map((item, index) => ({
      pinned: true,
      selected: true,
      unique_code: item.unique_code,
      unit: item.unit._id,
      last_pin_at: moment().add(index, 's').toISOString(),
    }));
    updateMultipleClientMetricRequest();
    updateMultipleClientMetric({ items: items, clientId: client }, false);
  };

  const renderChart = (item, index, list) => {
    return (
      <MetricOverviewDraggable
        key={item._id}
        item={item}
        isRequesting={isRequesting}
        units={units}
        isDoubleChart={checkIsDoubleChart(index, list.length)}
        onClick={handleClickOnChart(item.unique_code)}
        onDrop={handleDrop}
        timeRange={selectedRange}
      />
    );
  };

  useEffect(() => {
    const container = document.querySelector('.client-overview-container');
    if (!container) return;
    const handleScrollEnd = () => {
      const items = document.querySelectorAll('.my-custom-dot');
      for (const item of items) {
        item.style.display = 'unset';
      }
    };
    container.addEventListener('scrollend', handleScrollEnd);
    return () => {
      container.removeEventListener('scrollend', handleScrollEnd);
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="eve-panel training-overview-panel overview-chart">
        <div className="panel-header clickable" onClick={redirectToMetric}>
          <div className="panel-title">Body Metrics Overview</div>
          <a className="actions" onClick={handleUpdateAll}>
            Update All
          </a>
        </div>
        <div className="panel-body metric-grid-overview">
          <div className="select-wrapper">
            <DropdownOption
              selected={selectedRange}
              onSelectFilter={setSelectedRange}
              options={timeOptions}
              className="time-filter"
            />
          </div>
          <div className={classNames('droppable-context-metrics', `metric-${newOverviewMetrics.length}`)}>
            {newOverviewMetrics.map(renderChart)}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      client: { bodymetricTypes, workingClientDetail, isRequesting },
    },
  } = state;

  return {
    isRequesting,
    metricTypes: bodymetricTypes || [],
    client: get(workingClientDetail, '_id'),
    preferences: get(workingClientDetail, 'feature_preferences'),
    units: get(workingClientDetail || user, 'preferences', []),
  };
};

export default connect(mapStateToProps, {
  push,
  toggleModal,
  updateMultipleClientMetric,
  updateMultipleClientMetricRequest,
})(MetricOverview);
