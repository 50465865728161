import React from 'react';

import { GoalTypeLabels, GoalTypes } from '../../constants.js';
import { GoalItemOverviewStyled } from './styles.js';

const GoalItemOverview = ({ currentGoal, onClick, type = GoalTypes.general }) => {
  return (
    <GoalItemOverviewStyled onClick={onClick}>
      <div className="goal-type">
        <div>{GoalTypeLabels[type]}</div>
      </div>
      <div className="goal-text">{currentGoal}</div>
    </GoalItemOverviewStyled>
  );
};

export default GoalItemOverview;
