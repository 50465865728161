import { axiosInstance } from 'configs/request';
import { debounce, filter, find, get, includes, isEqual, map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { ReactComponent as RemoveIcon } from 'assets/icons/tag-value-remove.svg';
import AsyncSelect from 'react-select/lib/Async';
import { TAGS_TYPE, MAX_TAG_NAME_LENGTH } from 'constants/commonData';

import { Wrapper, TagsMenuListTitle, TagItem, SearchResultItem, CreateNewTagOptionButton } from './styles';

export const MODE = {
  DEFAULT: 'default',
  SEARCH: 'search',
};

const DEFAULT_CREATE_NEW_STATE = { enabled: false, name: '' };

const filterSelectedOptions = (options, selectedTags) => {
  const selectedIds = map(selectedTags, tag => tag._id);
  return filter(options, option => !includes(selectedIds, option._id));
};

/**
 * TagsFilter: Tags Filter
 * @param {*} menuPortalTarget: appearance position of dropdown
 * @param {*} placeholder: placeholder
 * @returns
 */
export default function TagsFilter({
  tags,
  selectedTags: originalSelectedTags = [],
  onChange,
  sorter,
  type = TAGS_TYPE.EXERCISE,
  menuPortalTarget = false,
  placeholder,
  mode = null,
  allowCreateNew = false,
  onCreateNew,
  onMenuOpen,
  onMenuClose,
  menuPlacement,
  height,
  menuPortalZIndex,
  menuListStyles = {},
  menuStyles = {},
  tagsStyles = {},
}) {
  const [selectedTags, setSelectedTags] = useState(originalSelectedTags);
  const [currentMode, setCurrentMode] = useState(mode ? mode : MODE.DEFAULT);
  const [createNew, setCreateNew] = useState(DEFAULT_CREATE_NEW_STATE);
  const [inputValue, setInputValue] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const selectRef = useRef(null);
  useEffect(() => {
    if (!isEqual(originalSelectedTags, selectedTags)) {
      setSelectedTags(originalSelectedTags);
    }
  }, [originalSelectedTags]);
  const handleTagClick = () => {};
  const handleChange = (value, actionMeta) => {
    setSelectedTags(value);
    onChange && onChange(value);
  };
  const DefaultModeOption = ({ innerProps, innerRef, data }) => {
    const isSelected = find(selectedTags, selectedTag => selectedTag._id === data._id) || false;
    return (
      <TagItem
        key={data._id}
        isSelected={isSelected}
        onClick={() => handleTagClick(data)}
        {...innerProps}
        ref={innerRef}
      >
        {data.name}
      </TagItem>
    );
  };
  const SearchModeOption = ({ innerProps, innerRef, data }) => {
    //TODO: implement search mode option
    const isSelected = find(selectedTags, selectedTag => selectedTag._id === data._id) || false;
    return (
      <SearchResultItem
        key={data._id}
        isSelected={isSelected}
        onClick={() => handleTagClick(data)}
        {...innerProps}
        ref={innerRef}
      >
        {data.name}
      </SearchResultItem>
    );
  };

  const MultiValueRemove = props => {
    return (
      <components.MultiValueRemove {...props}>
        <RemoveIcon />
      </components.MultiValueRemove>
    );
  };

  const handleCreateNew = () => {
    if (!isCreatingNew) {
      // prevent double click
      setIsCreatingNew(true);
      onCreateNew && onCreateNew(createNew.name, handleCreateNewSuccess);
    }
  };

  const handleCreateNewSuccess = newTagData => {
    if (selectRef.current) {
      // clears the input
      selectRef.current.select.select.onInputChange(null, '', '');
      setInputValue('');
      setCreateNew(DEFAULT_CREATE_NEW_STATE);
      // select the newTagData
      selectRef.current.select.select.onChange([...selectedTags, newTagData], 'create-option');
      setIsCreatingNew(false);
      if (mode !== MODE.SEARCH) {
        setCurrentMode(MODE.DEFAULT);
      }
    }
  };

  const handleInputChange = inputValue => {
    if (!inputValue && inputValue !== '') {
      return;
    }
    const newInputValue =
      inputValue.length <= MAX_TAG_NAME_LENGTH ? inputValue : inputValue.substr(0, MAX_TAG_NAME_LENGTH);
    setInputValue(newInputValue);
    if (mode === null) {
      // only auto switch mode when the props mode is not provided
      if (inputValue) {
        setCurrentMode(MODE.SEARCH);
      } else {
        setCurrentMode(MODE.DEFAULT);
      }
    }
    if (allowCreateNew) {
      setCreateNew({ enabled: inputValue.trim() !== '', name: newInputValue });
    }
  };
  const handleMenuOpen = () => {
    onMenuOpen && onMenuOpen();
  };
  const handleMenuClose = () => {
    onMenuClose && onMenuClose();
  };
  return (
    <Wrapper>
      <AsyncSelect
        isMulti
        menuPortalTarget={menuPortalTarget}
        closeMenuOnSelect={false}
        name="tags"
        ref={selectRef}
        value={selectedTags}
        defaultOptions={tags.mostRecentList}
        loadOptions={(inputValue, callback) =>
          searchTagDebounce.call(this, inputValue, callback, selectedTags, sorter, type)
        }
        getOptionLabel={option => option.name}
        getOptionValue={option => option._id}
        isLoading={false}
        onChange={handleChange}
        menuPlacement={menuPlacement ? menuPlacement : 'auto'}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        classNamePrefix="tags-select"
        placeholder={placeholder ? placeholder : 'Search or choose a tag'}
        hideSelectedOptions={mode === MODE.SEARCH}
        components={{
          DropdownIndicator: null,
          LoadingIndicator: null,
          Option: currentMode === MODE.DEFAULT ? DefaultModeOption : SearchModeOption,
          MenuList: CustomMenuList,
          MultiValueRemove,
          ClearIndicator: null,
        }}
        styles={{
          control: provided => ({
            ...provided,
            minHeight: height ? height : '42px',
            cursor: 'pointer',
          }),
          multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: '#E7E7FD',
          }),
          multiValueLabel: provided => ({
            ...provided,
            color: '#444444',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '100%',
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: menuPortalZIndex,
          }),
          menu: provided => ({
            ...provided,
            ...menuStyles,
            ...get(tagsStyles, 'menuStyles', {}),
          }),
          menuList: provided => ({
            ...provided,
            maxHeight: '164px',
            ...menuListStyles,
            ...get(tagsStyles, 'menuListStyles', {}),
          }),
        }}
        // custom props
        handleCreateNew={handleCreateNew}
        createNew={createNew}
        currentMode={currentMode}
      />
    </Wrapper>
  );
}

const CustomMenuList = props => {
  const { options, isLoading, selectProps } = props;
  const { value: selectedTags, inputValue, handleCreateNew, createNew, currentMode } = selectProps;
  const tagExist = find(options, option => option.name === inputValue) ? true : false;
  const tagSelected = find(selectedTags, option => option.name === inputValue.trim()) ? true : false;
  return (
    <components.MenuList {...props}>
      {createNew.enabled && !tagExist && !tagSelected && !isLoading && (
        <CreateNewTagOptionButton onClick={handleCreateNew}>Add " {createNew.name} "</CreateNewTagOptionButton>
      )}
      <TagsMenuListTitle mode={currentMode}>
        {currentMode === MODE.DEFAULT || inputValue === ''
          ? `Most Recent`
          : `Results (${filterSelectedOptions(props.options, selectedTags).length})`}
      </TagsMenuListTitle>
      {props.children}
    </components.MenuList>
  );
};

const searchTag = (inputValue, callback, selectedTags, sorter, type) => {
  let text_search = typeof inputValue === 'string' ? inputValue.trim() : '';
  const selectedIds = map(selectedTags, tag => tag._id);
  axiosInstance
    .get('/api/tag/get-list-tag-by-team/', { params: { text_search, sorter, type, sort: -1, page: 1, per_page: 50 } })
    .then(response => {
      const { data } = response.data;
      if (data) {
        const results = filter(data.data, it => !includes(selectedIds, it._id));
        callback(results);
      } else {
        callback([]);
      }
    })
    .catch(() => {
      callback([]);
    });
};
const searchTagDebounce = debounce(searchTag, 300);
