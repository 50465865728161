import { mongoObjectId } from 'utils/commonFunction';

export const SHOW_OPTION_CHARACTER = 240;
export const LIMIT_OPTION_CHARACTER = 255;
export const LIMIT_QUESTION_CHARACTER = 255;
export const DEFAULT_ITEM_OPTION = { value: '', isDuplicate: false };
export const DEFAULT_OPTIONS = Array(3)
  .fill(DEFAULT_ITEM_OPTION)
  .map(it => ({ ...it, _id: mongoObjectId() }));

export const KEY_ADVANCED_SETTINGS = {
  ALLOW_MULTIPLE_CHOICES: 'allow_multiple_choices',
  ALLOW_MEMBER_TO_ADD_OPTION: 'allow_member_to_add_option',
};

export const DEFAULT_ADVANCED_SETTINGS = {
  [KEY_ADVANCED_SETTINGS.ALLOW_MULTIPLE_CHOICES]: false,
  [KEY_ADVANCED_SETTINGS.ALLOW_MEMBER_TO_ADD_OPTION]: false,
};
