import React from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import classNames from 'classnames';

import SearchInput from 'shared/SearchInput';
import ManuallySet1RM from './ManuallySet1RM';

import { ReactComponent as CheckedIcon } from 'assets/icons/check-mark-freestyle-section.svg';
import { ReactComponent as ChartDarkerIcon } from 'assets/icons/chart_darker.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search_icon_grey.svg';

export default class ExerciseSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedExerciseId: '',
      exerciseHistoryList: [],
      exercise_page: 1,
      exercise_per_page: 50,
      exercise_id: '',
      primary_focus: '',
      exercise_q: '',
      exercise_sorter: 'most_recent',
      exercise_sorter_name: 'Most recent',
      exercise_sort: -1,
      typingTimeout: 0,
      loading: true,
      selectedFilter: 'modalities',
    };

    this.getExerciseHistoryDebounce = _.debounce(this.getExerciseHistory, 300);
  }

  UNSAFE_componentWillMount() {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    const selectedExerciseId = parsed.exerciseId || null;

    this.setState({ selectedExerciseId }, () => {
      this.getExerciseHistoryDebounce(true);
    });
  }

  getExerciseHistory = (isFirstLoad = false) => {
    const {
      exercise_page,
      exercise_per_page,
      exercise_id,
      exercise_q,
      exercise_sorter,
      exercise_sort,
      selectedExerciseId,
      selectedFilter,
    } = this.state;
    const { setSelectedExercise, match, getExerciseHistoryList } = this.props;

    this.setState({ loading: true });

    const preparedSelectedFilter = exercise_id || '';

    const requestData = {
      page: exercise_page,
      per_page: exercise_per_page,
      client: match.params.clientId,
      q: exercise_q,
      sorter: exercise_sorter,
      sort: exercise_sort,
    };

    if (preparedSelectedFilter) {
      requestData[`${[selectedFilter]}[]`] = preparedSelectedFilter;
    }

    getExerciseHistoryList(requestData)
      .then(result => {
        if (result && result.data && result.data.data) {
          const responseData = result.data.data.filter(it => _.get(it, 'exercise._id', ''));
          const selectedExercise =
            responseData.find(item => selectedExerciseId === _.get(item, 'exercise._id', '')) || responseData[0] || {};
          const exerciseId = _.get(selectedExercise, 'exercise._id', '');
          if (isFirstLoad) {
            setSelectedExercise(exerciseId ? selectedExercise : null);
          }

          this.setState(s => ({
            ...s,
            exerciseHistoryList: responseData,
            selectedExerciseId: isFirstLoad ? exerciseId : s.selectedExerciseId,
            loading: false,
          }));
        }
      })
      .catch(() => {
        this.setState(s => ({ ...s, loading: false }));
      });
  };

  searchExerciseHistory = (event, data) => {
    this.isQDataChanged = true;
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      exercise_q: data.value,
      typingTimeout: setTimeout(function () {
        self.getExerciseHistoryDebounce();
      }, 1000),
    });
  };

  onAddOnIconClick = () => {
    this.setState(
      {
        isSearchingExercise: false,
        exercise_q: '',
      },
      () => {
        if (this.isQDataChanged) {
          this.getExerciseHistoryDebounce();
        }
        this.isQDataChanged = false;
      },
    );
  };

  renderDropItem = (key, label) => {
    const { exercise_sorter_name, exercise_id } = this.state;
    const active = (exercise_id !== '' ? exercise_id : exercise_sorter_name) === label;

    return (
      <Dropdown.Item
        onClick={() => {
          this.handleExerciseHistorySortBy(key, label);
        }}
        className={classNames({ active: active })}
      >
        {label}
        {active && <CheckedIcon className="sort-checked-icon" />}
      </Dropdown.Item>
    );
  };

  renderFilterContent() {
    const { selectedFilter, exercise_id } = this.state;
    const { modalities, muscleGroup, movementPattern } = this.props;
    switch (selectedFilter) {
      case 'modalities':
        return (modalities || []).map(item => (
          <Dropdown.Item
            key={item._id}
            className={exercise_id === item._id ? 'active' : ''}
            onClick={() => this.handleExerciseHistorySortBy('most_recent', 'Most recent', item._id, item.title)}
          >
            {item.title}
          </Dropdown.Item>
        ));
      case 'muscle_groups':
        return (muscleGroup || []).map(item => (
          <Dropdown.Item
            key={item._id}
            className={exercise_id === item._id ? 'active' : ''}
            onClick={() => this.handleExerciseHistorySortBy('most_recent', 'Most recent', item._id, item.title)}
          >
            {item.title}
          </Dropdown.Item>
        ));
      case 'movement_patterns':
        return (movementPattern || []).map(item => (
          <Dropdown.Item
            key={item._id}
            className={exercise_id === item._id ? 'active' : ''}
            onClick={() => this.handleExerciseHistorySortBy('most_recent', 'Most recent', item._id, item.title)}
          >
            {item.title}
          </Dropdown.Item>
        ));
      default:
        return null;
    }
  }

  renderCustomDropdown = () => {
    const { selectedFilter } = this.state;

    return (
      <Dropdown.Item>
        <Dropdown text="Primary Focus" icon="chevron right" onClose={() => this.onPopupToggle(false)}>
          <Dropdown.Menu>
            <div className="metric-primary-filter">
              <div className="filter-header">
                <Dropdown.Item
                  className={selectedFilter === 'modalities' ? 'active' : ''}
                  onClick={e => this.handleSelectFilter(e, 'modalities')}
                >
                  Modality
                </Dropdown.Item>
                <Dropdown.Item
                  className={selectedFilter === 'muscle_groups' ? 'active' : ''}
                  onClick={e => this.handleSelectFilter(e, 'muscle_groups')}
                >
                  Muscle group
                </Dropdown.Item>
                <Dropdown.Item
                  className={selectedFilter === 'movement_patterns' ? 'active' : ''}
                  onClick={e => this.handleSelectFilter(e, 'movement_patterns')}
                >
                  Movement pattern
                </Dropdown.Item>
              </div>
              <div className="filter-content">{this.renderFilterContent()}</div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </Dropdown.Item>
    );
  };

  handleSearchBlur = (event = {}) => {
    const value = (event.target || {}).value || '';
    if (!value.trim()) {
      this.setState({ isSearchingExercise: false });
    }
  };

  handleSelectFilter = (e, filter) => {
    e.stopPropagation();
    this.setState({ selectedFilter: filter, openingPopup: true });
  };

  render() {
    if (!this.props.selectedClient) {
      return null;
    }

    const { selectedClient, isLoadingExerciseMetric = false } = this.props;
    const {
      exerciseHistoryList,
      isSearchingExercise,
      exercise_sort,
      exercise_sorter_name,
      selectedExerciseId,
      exercise_q,
      openingPopup,
      primary_focus,
    } = this.state;

    return (
      <React.Fragment>
        <div className="toolbar-exercise-metrics">
          {isSearchingExercise ? (
            <div className={classNames('exercise-history-search-bar', { 'has-text': exercise_q })}>
              <SearchInput
                value={exercise_q}
                autoFocus={true}
                alwayShowDelete={true}
                onChange={this.searchExerciseHistory.bind(this)}
                onAddOnIconClick={this.onAddOnIconClick}
                placeholder="Search by keywords or name"
                onBlur={this.handleSearchBlur}
              />
            </div>
          ) : (
            <>
              <span className="label">Exercise Metrics</span>
              <div
                onClick={() => {
                  this.setState({ isSearchingExercise: true });
                }}
                className="client-metric-search-icon"
              >
                <SearchIcon className="icon" />
              </div>
            </>
          )}
        </div>
        <div className="metric-type-header metric-type-header--exercise">
          <div>
            <span className="label-total">Total ({exerciseHistoryList.length}) </span>
          </div>
          <div className="sort">
            <React.Fragment>
              <Popup
                icon={null}
                basic
                on={'click'}
                position="bottom left"
                open={openingPopup}
                className="metric-history-sort-popup"
                onOpen={() => this.onPopupToggle(true)}
                onClose={() => this.onPopupToggle(false)}
                trigger={
                  <span className={classNames({ 'is-show': openingPopup })}>
                    {primary_focus !== '' ? primary_focus : exercise_sorter_name}
                  </span>
                }
              >
                <Dropdown.Menu>
                  {this.renderDropItem('most_used', 'Most used')}
                  {this.renderDropItem('most_recent', 'Most recent')}
                  {this.renderDropItem('title', 'Name')}
                  {this.renderCustomDropdown()}
                </Dropdown.Menu>
              </Popup>
              <Icon
                name={'chevron ' + (exercise_sort === -1 ? 'down' : 'up')}
                onClick={this.handleExerciseHistorySort.bind(this)}
              />
            </React.Fragment>
          </div>
        </div>
        <div className="metric-type-list exercise__list">
          {_.map(exerciseHistoryList, item => {
            const isActive = _.get(item, 'exercise._id', '') === selectedExerciseId;

            let date = item.recent_tracked && DateTime.fromISO(item.recent_tracked);
            let isSameYear = DateTime.now().year === date.year;
            let isToday = DateTime.now().hasSame(date, 'day');
            let dateRecentTracked =
              item.recent_tracked && isToday
                ? 'Today'
                : DateTime.fromISO(item.recent_tracked)
                    .toLocal()
                    .toFormat(isSameYear ? 'MMM d' : 'MMM d, yyyy');

            return (
              <div
                className={classNames('item', {
                  active: _.get(item, 'exercise._id', '') === selectedExerciseId,
                  disabled: isLoadingExerciseMetric,
                })}
                key={item._id}
                onClick={() => this.handleExerciseClick(item)}
              >
                {isActive && (
                  <Helmet>
                    <title>
                      {selectedClient.first_name} {selectedClient.last_name} - {item.exercise_name} - Everfit
                    </title>
                  </Helmet>
                )}
                <span className="name">{item.exercise_name}</span>
                <span className={item.recent_tracked ? 'time exercise__time' : 'time-empty'}>
                  {item.recent_tracked ? dateRecentTracked : '-'}
                </span>
              </div>
            );
          })}
          {exerciseHistoryList.length === 0 && (
            <div className={classNames('no-exercises', { 'has-text': exercise_q })}>
              {exercise_q ? (
                <span>No results found.</span>
              ) : (
                <>
                  {' '}
                  <ChartDarkerIcon />
                  <span>{this.state.loading ? 'Loading...' : 'No exercises have been tracked yet.'}</span>
                </>
              )}
            </div>
          )}
        </div>
        <ManuallySet1RM />
      </React.Fragment>
    );
  }

  handleExerciseClick = exerciseItem => {
    const { setSelectedExercise, dispatch, isLoadingExerciseMetric = false } = this.props;
    if (isLoadingExerciseMetric) return;
    const { selectedExerciseId, exerciseHistoryList } = this.state;
    const newId = _.get(exerciseItem, 'exercise._id', '');

    if (newId !== selectedExerciseId) {
      const newExercise = exerciseHistoryList.find(item => item._id === exerciseItem._id);
      setSelectedExercise(newExercise);

      this.setState({ selectedExerciseId: newId }, () => {
        dispatch(push(`/home/client/${this.props.match.params.clientId}/metrics?tab=exercise&exerciseId=${newId}`));
      });
    }
  };

  onPopupToggle = openingPopup => {
    this.setState(s => ({
      ...s,
      openingPopup,
    }));
  };

  handleExerciseHistorySortBy(exercise_sorter, exercise_sorter_name, exercise_id, primary_focus) {
    this.setState(
      {
        exercise_sorter: exercise_sorter,
        exercise_sorter_name: exercise_sorter_name,
        exercise_id: exercise_id || '',
        primary_focus: primary_focus || '',
        openingPopup: false,
      },
      () => {
        this.getExerciseHistoryDebounce();
      },
    );
  }

  handleExerciseHistorySort() {
    this.setState(
      {
        exercise_sort: this.state.exercise_sort === 1 ? -1 : 1,
        openingPopup: false,
      },
      () => {
        this.getExerciseHistoryDebounce();
      },
    );
  }
}
