// Libs
import _ from 'lodash';
import diff from 'deep-diff';
import { DateTime } from 'luxon';
import moment from 'moment';

// Constants
import { PLANS } from 'constants/commonData';
import { ADD_ONS_PRICING_CODE } from './constants';

export const getPriceByPeriod = (period, packagePrice) => {
  return period === 'monthly' ? _.get(packagePrice, 'monthlyPricing', 0) : _.get(packagePrice, 'anuallyPricing', 0);
};

export const getSubTotalBaseOnPlan = (selectPackage, planPackage, addOnsPricing) => {
  let { packagePrice } = selectPackage;
  if (!packagePrice) {
    packagePrice = planPackage[selectPackage.tier].pricings.find(
      item => item.packageNoClients === selectPackage.number_of_clients,
    );
  }
  const planCost = packagePrice ? getPriceByPeriod(selectPackage.period, packagePrice) : 0;
  const addOnsCost = _.reduce(
    addOnsPricing,
    (sum, addOn) => {
      return sum + (selectPackage.addOns[addOn.code] ? getPriceByPeriod(selectPackage.period, addOn.basePrice) : 0);
    },
    0,
  );
  return _.sum([planCost, addOnsCost]);
};

export function getSubTotal(selectPackage, addOnsPricing, teamData) {
  const planCost = getPriceByPeriod(selectPackage.period, selectPackage.packagePrice);
  const addOns = getAddOnsCoupon(selectPackage, addOnsPricing);
  const addOnsCost = _.reduce(
    addOnsPricing,
    (sum, addOn) => {
      if (teamData.oneTimeAddOns[addOn.code]) {
        return sum;
      }
      return sum + (addOns[addOn.code] ? getPriceByPeriod(selectPackage.period, addOn.basePrice) : 0);
    },
    0,
  );
  return _.sum([planCost, addOnsCost]);
}

export function getPrevSubTotal(teamData, addOnsPricing, pricing) {
  const { number_of_clients, billing_cycle, tier, addOns } = teamData;
  const selectedPlan = pricing.find(item => item.code === tier);
  const packagePrice = selectedPlan.pricings.find(item => item.packageNoClients === number_of_clients);

  const selectPackage = {
    addOns,
    packagePrice,
    period: billing_cycle === 1 ? 'monthly' : 'annually',
    tier,
  };
  const subTotal = getSubTotal(selectPackage, addOnsPricing, teamData);
  return subTotal;
}

export function getOneTimeCost(selectPackage, addOnsPricing, teamData) {
  return _.reduce(
    addOnsPricing,
    (sum, addOn) => {
      if (teamData.oneTimeAddOns[addOn.code] || !addOn.isOneTime) {
        return sum;
      }

      const selected = selectPackage.addOns[addOn.code];
      return sum + (selected ? getPriceByPeriod(selectPackage.period, addOn.basePrice) : 0);
    },
    0,
  );
}

export const checkChangePlan = (teamData, selectPackage) => {
  const currentPlan = _.pick(teamData, ['period', 'packageNoClients', 'addOns', 'tier']);
  const selectPlan = _.pick(selectPackage, ['period', 'packageNoClients', 'addOns', 'tier']);
  const changes = diff(currentPlan, { ...selectPlan, packageNoClients: selectPackage.packagePrice.packageNoClients });
  return !changes;
};

export const checkOnlyChangeOneTime = (teamData, selectPackage) => {
  const currentAddOns = _.omit(teamData.addOns, ['premiumCustomBrand']);
  const selectAddOns = _.omit(selectPackage.addOns, ['premiumCustomBrand']);
  const currentPlan = _.pick(teamData, ['period', 'packageNoClients', 'addOns', 'tier']);
  const selectPlan = _.pick(selectPackage, ['period', 'packageNoClients', 'addOns', 'tier']);
  const changes = diff(
    { ...currentPlan, addOns: currentAddOns },
    { ...selectPlan, addOns: selectAddOns, packageNoClients: selectPackage.packagePrice.packageNoClients },
  );
  const currentOneTimeAddOns = _.pick(teamData.addOns, ['premiumCustomBrand']);
  const selectOneTimeAddOns = _.pick(selectPackage.addOns, ['premiumCustomBrand']);
  const isChangeOneTimeAddOns = diff(currentOneTimeAddOns, selectOneTimeAddOns);
  return !changes && isChangeOneTimeAddOns;
};

export const getLowestPackagePrice = (planPricing, selectedTier, noClients, packageNoClients) => {
  const planPackage = _.chain(planPricing)
    .groupBy(o => o.code)
    .mapValues(o => o[0])
    .value();

  const pricings = planPackage[selectedTier].pricings;
  const pricingsCanChoose = _.chain(pricings)
    .filter(o => o.packageNoClients > noClients)
    .orderBy(['packageNoClients'], ['asc'])
    .value();

  const currentPackage = _.find(pricings, pricing => pricing.packageNoClients === packageNoClients);

  const lowestPackage = _.get(pricingsCanChoose, 0, _.last(pricings));
  return currentPackage && currentPackage.packageNoClients > lowestPackage.packageNoClients
    ? currentPackage
    : lowestPackage;
};

export const isPromoCodeOneTime = (addOnsPricing, promoCode) => {
  const promoCodeAddOns = _.get(promoCode, 'metadata.add_ons');
  const foundAddOn = addOnsPricing.find(addOn => addOn.code === promoCodeAddOns);
  return foundAddOn && foundAddOn.isOneTime;
};

export const getPromoValue = (promoCode, subTotal, selectPackage, addOnsPricing) => {
  if (!promoCode || !promoCode.valid) {
    return 0;
  }

  const promoCodeAddOns = _.get(promoCode, 'metadata.add_ons');

  if (promoCodeAddOns && !selectPackage.addOns[promoCodeAddOns]) {
    return 0;
  }
  if (promoCodeAddOns && selectPackage.addOns[promoCodeAddOns]) {
    const foundAddOn = addOnsPricing.find(addOn => addOn.code === promoCodeAddOns);
    const addOnsCost = getPriceByPeriod(selectPackage.period, foundAddOn.basePrice);
    const proCodeAmountOff = promoCode.amount_off / 100;
    if (!foundAddOn) {
      return 0;
    }

    if (promoCode.percent_off) {
      return addOnsCost * (promoCode.percent_off / 100);
    }

    return proCodeAmountOff > addOnsCost ? addOnsCost : proCodeAmountOff;
  }

  if (promoCode.amount_off) {
    return promoCode.amount_off / 100;
  } else if (promoCode.amount_off === 0) {
    return 0;
  } else if (promoCode.percent_off) {
    return subTotal * (promoCode.percent_off / 100);
  }
};

export const calculateRemainCost = (subTotal, teamData) => {
  if (teamData) {
    const { current_period_end, current_period_start } = teamData;
    const currentTime = DateTime.local().toSeconds();
    return ((current_period_end - currentTime) * subTotal) / (current_period_end - current_period_start);
  }
  return 0;
};

export function calculatePaymentObject(
  selectPackage,
  addOnsPricing,
  paymentInfo,
  teamData,
  downgrade,
  isScheduled = false,
) {
  let proratedSubtotal = (selectPackage.remainingCost || 0) / 100;
  let unusedCost = ((selectPackage.unusedCost || 0) * -1) / 100;

  const isOnlyChangeOnTimeAddOns = checkOnlyChangeOneTime(teamData, selectPackage);

  const subTotal = getSubTotal(selectPackage, addOnsPricing, teamData);
  const oneTimeCost = getOneTimeCost(selectPackage, addOnsPricing, teamData);

  if (teamData.period !== selectPackage.period) {
    proratedSubtotal = 0;
  }

  let realSubTotal = proratedSubtotal || subTotal;
  if (isOnlyChangeOnTimeAddOns && teamData.tier !== PLANS.starter.key && teamData.mode === 3) {
    realSubTotal = oneTimeCost;
  } else if (teamData.tier !== PLANS.starter.key && teamData.mode === 3) {
    realSubTotal += oneTimeCost;
  }

  let promoAmount = getPromoValue(selectPackage.promoCode, realSubTotal, selectPackage, addOnsPricing);
  // Code temporarily excluded to apply new logic of calculating promos based on the original prorated price.

  // TODO refactor - should separate this case
  // only handle case monthly upgrade have promotion code addOns
  const promoCodeAddOns = _.get(selectPackage.promoCode, 'metadata.add_ons');
  const should_apply_credit = _.get(selectPackage.promoCode, 'metadata.should_apply_credit');
  const isUpgradeMonthly =
    teamData.period === 'monthly' && selectPackage.period === 'annually' && selectPackage.promoCode;

  if (
    teamData.mode === 3 &&
    !isPromoCodeOneTime(addOnsPricing, selectPackage.promoCode) &&
    promoCodeAddOns &&
    selectPackage.promoCode &&
    (teamData.period === 'monthly' || (selectPackage.promoCode && selectPackage.promoCode.percent_off)) &&
    (!should_apply_credit || !selectPackage.promoCode.amount_off) &&
    !isUpgradeMonthly
  ) {
    promoAmount = calculateRemainCost(promoAmount, teamData);
  }

  const isSameZapierQuantity = teamData.zapier_quantity === selectPackage.zapier_quantity;

  // for downgrade
  const isNoChangeNoClient =
    _.get(selectPackage, 'packagePrice.packageNoClients', 0) === _.get(teamData, 'packageNoClients', 0);

  // Base plan case
  const isMonthlyDowngrade =
    teamData.tier === selectPackage.tier &&
    teamData.period === selectPackage.period &&
    isSameZapierQuantity &&
    teamData.period === 'monthly' &&
    isNoChangeNoClient &&
    downgrade;

  if (isMonthlyDowngrade) {
    proratedSubtotal = calculateRemainCost(subTotal, teamData);
    realSubTotal = calculateRemainCost(subTotal, teamData);
    unusedCost = proratedSubtotal + unusedCost;
    promoAmount = 0;
  }

  const prorationPlanCost = _.get(selectPackage, 'prorationPlanCost', 0) || 0;

  const proratedCredit = subTotal - (prorationPlanCost || 0) / 100;
  let creditBalance = _.get(paymentInfo, 'balance', 0) / 100;

  const totalDueWithoutBalance = realSubTotal - promoAmount - unusedCost;
  let totalDue = realSubTotal - promoAmount - unusedCost - creditBalance;

  // check if keep the old promotion code
  // Code temporarily excluded to apply new logic of calculating promos based on the original prorated price.
  // if (selectPackage.promoCode && selectPackage.promoCode.id === teamData.promoCode) {
  //   proratedSubtotal = proratedSubtotal + promoAmount;
  //   totalDue = totalDue + promoAmount;
  // }

  let creditToAccount = 0;
  let payFromBalance = 0;
  if (totalDueWithoutBalance < 0) {
    creditToAccount = totalDueWithoutBalance * -1;
  }
  if (totalDueWithoutBalance > 0) {
    payFromBalance = totalDueWithoutBalance > creditBalance ? creditBalance : totalDueWithoutBalance;
    // creditToAccount = creditBalance - payFromBalance;
  }

  // Zapier removed case will show total due = 0
  const isRemovedZapier =
    _.get(selectPackage, 'addOns.zapier') === false &&
    _.get(teamData, 'addOns.zapier') === true &&
    teamData.period === 'monthly' &&
    isNoChangeNoClient;

  const isPromoCodeValid = selectPackage.promoCode && !isPromoCodeOneTime(addOnsPricing, selectPackage.promoCode);
  const percentOff = _.get(selectPackage.promoCode, 'percent_off');
  const isZapierChanged =
    teamData.zapier_quantity !== selectPackage.zapier_quantity && teamData.period === selectPackage.period;

  // Determine if the promo will apply to Total Due or Prorated price
  if (isPromoCodeValid && isSameZapierQuantity && percentOff && !promoCodeAddOns) {
    if (isScheduled && proratedSubtotal <= 0 && totalDue > 0 && !isZapierChanged) {
      // Apply promo percent off on Total Due
      promoAmount = totalDue * (percentOff / 100);
    } else if (!isScheduled && proratedSubtotal > 0 && !isZapierChanged) {
      // Apply promo percent off on Prorated
      promoAmount = proratedSubtotal * (percentOff / 100);
    } else if (isZapierChanged && totalDue > 0) {
      // Apply only for up/downgrade Zapier
      promoAmount = realSubTotal * (percentOff / 100);
      totalDue = realSubTotal - promoAmount;
    }
  }

  if (isRemovedZapier) {
    // Hide Prorated price & Promo applied if Zapier plug-in is removed
    totalDue = 0;
    promoAmount = 0;
  }
  const isMatchingPromoCode = _.get(selectPackage.promoCode, '_id') === teamData.promoCode;
  const remainingSubtotal = proratedSubtotal - (unusedCost + promoAmount);

  // Re-check the remaining balance after deducting the promo code
  if (remainingSubtotal > 0 && isMatchingPromoCode) {
    // Deduct from account balance when the remaining balance isn't enough
    payFromBalance = remainingSubtotal;
    creditToAccount = 0;
  } else if (remainingSubtotal < 0 && isMatchingPromoCode) {
    // Add remaining balance to the account's credit
    creditToAccount = unusedCost + promoAmount - proratedSubtotal;
    payFromBalance = 0;
  }

  if (teamData.promoCode === null && remainingSubtotal > 0) {
    // Hide 'credit to account' when have promo code
    creditToAccount = 0;
  }

  return {
    subTotal,
    proratedSubtotal,
    unusedCost,
    proratedCredit,
    totalDue: totalDue > 0 ? totalDue : 0,
    creditToAccount,
    payFromBalance,
    creditBalance,
    prorationPlanCost,
    promoAmount,
    oneTimeCost,
  };
}

export const getAddOnsCoupon = (selectPackage, addOnsPricing) => {
  const tier = _.get(selectPackage, 'tier', {});
  let addOns = _.get(selectPackage, 'addOns', {});
  _.forEach(addOnsPricing, item => {
    if (!item.planAvailable.includes(tier)) {
      addOns = {
        ...addOns,
        [item.code]: false,
      };
    }
  });

  return addOns;
};

export const getPromoOnlyZapier = (promoCode, subTotal) => {
  if (!promoCode || !promoCode.valid) {
    return 0;
  }

  if (promoCode.amount_off || promoCode.amount_off === 0) {
    // Not apply promo code fix amount
    return 0;

    // return promoCode.amount_off / 100;
  }
  if (promoCode.percent_off) {
    return subTotal * (promoCode.percent_off / 100);
  }
};

export const checkPastDateForBeta = (date, timezone) => {
  if (!date) {
    return false;
  }

  const currentDate = moment().tz(timezone);
  const pastDate = moment.tz(date, timezone);
  const isToday = pastDate.isSame(currentDate, 'day');
  const isPastDate = !isToday && pastDate.isBefore(currentDate, 'day');

  return isPastDate;
};

export const getBetaLabel = code => {
  switch (code) {
    case ADD_ONS_PRICING_CODE.MEAL_PLAN:
      return 'Meal Plans is activated on your workspace for beta testing until';

    default:
      return '';
  }
};

export const checkPastDateForTrial = (date, timezone) => {
  if (!date) {
    return false;
  }
  const currentDate = moment().tz(timezone);
  const newDate = moment.tz(date, timezone);
  const isPastDate = newDate.unix() < currentDate.unix();
  return isPastDate;
};

export const getTimeRemain = expiredDate => {
  const expired = moment(expiredDate);
  const current = moment();
  const dayRemain = expired.diff(current, 'days');
  return dayRemain > 1 ? dayRemain + ' days' : '1 day';
};
