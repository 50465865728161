import React, { useMemo } from 'react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';

import * as S from './style';
import { DELETED_CLIENT_NAME, INBOX_SYSTEM_MESSAGE_TYPES } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';
import { ReactComponent as StartIcon } from 'assets/icons/group-chat-start-icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/group-chat-remove-icon.svg';
import { ReactComponent as JoinIcon } from 'assets/icons/group-chat-join-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/group-chat-edit-icon.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/group-chat-leave-icon.svg';

function SystemMessage(props) {
  if (isNil(get(props, 'message.system_message_type'))) return <></>;

  const customContent = useMemo(() => getContent(props), [props]);
  const customIcon = useMemo(() => getIcon(props.message), [props]);

  return (
    <S.Container>
      <S.Content>
        {customIcon}
        {customContent}
      </S.Content>
    </S.Container>
  );
}

export default SystemMessage;

export function getContent({ message, isOwner, userId, usersInfo = [], viewTeammate, textOnly = false }) {
  // Creator info
  const creator =
    usersInfo.find(item => item._id === get(message, 'metadata.user._id')) || get(message, 'metadata.user');
  const firstNameCreator = get(creator, 'is_removed', '') ? DELETED_CLIENT_NAME : get(creator, 'first_name', '');
  // Target users in group
  let targetUsers = orderBy(
    get(message, 'metadata.target_users', []),
    [user => user.first_name.toLowerCase()],
    ['asc'],
  );
  // First participant info
  const first_participant = usersInfo.find(item => item._id === get(targetUsers[0], '_id')) || targetUsers[0];
  const fullNameP1 = get(first_participant, 'is_removed', '')
    ? DELETED_CLIENT_NAME
    : get(first_participant, 'full_name', '');
  const firstNameP1 = get(first_participant, 'is_removed', '')
    ? DELETED_CLIENT_NAME
    : get(first_participant, 'first_name', '');
  const lastNameP1 = get(first_participant, 'is_removed', '') ? '' : get(first_participant, 'last_name', '');
  // Second participant info
  const second_participant = usersInfo.find(item => item._id === get(targetUsers[1], '_id')) || targetUsers[1];
  const firstNameP2 = get(second_participant, 'is_removed', '')
    ? DELETED_CLIENT_NAME
    : get(second_participant, 'first_name', '');
  const numberOther = targetUsers.length;
  const isInTargetUser = findIndex(targetUsers, { _id: userId });
  const teammateCreator =
    viewTeammate &&
    get(
      targetUsers.find(item => item._id === userId),
      'first_name',
      '',
    );
  switch (get(message, 'system_message_type')) {
    case INBOX_SYSTEM_MESSAGE_TYPES.CREATED_GROUP: {
      if (isOwner) {
        if (viewTeammate) return `${firstNameCreator} started a group with ${numberOther} others`;
        return `You started a group with ${numberOther} others`;
      }
      if (isInTargetUser !== -1 && !viewTeammate) {
        return `${firstNameCreator} started a group with you and ${pluralize('other', numberOther - 1, true)}`;
      }
      return `${firstNameCreator} started a group with ${teammateCreator || firstNameP1} and ${pluralize(
        'other',
        numberOther - 1,
        true,
      )}`;
    }
    case INBOX_SYSTEM_MESSAGE_TYPES.UPDATE_GROUP_INFO: {
      const roomName = get(message, 'metadata.room_name');
      const roomIcon = get(message, 'metadata.room_icon');
      if (isOwner && !viewTeammate) {
        if (roomName === '') {
          return 'You removed the group name';
        }
        if (!isEmpty(roomName)) {
          if (textOnly) return `You just changed the group name to ${roomName}`;
          return (
            <>
              You just changed the group name to <S.RoomName>{roomName}</S.RoomName>
            </>
          );
        }
        if (!isEmpty(roomIcon)) {
          return 'You just changed the group image';
        }
      } else {
        if (!isEmpty(roomName)) {
          if (textOnly) return `${firstNameCreator} just changed the group name to ${roomName}`;
          return (
            <>
              {firstNameCreator} just changed the group name to <S.RoomName>{roomName}</S.RoomName>
            </>
          );
        }
        if (roomName === '') {
          return `${firstNameCreator} removed the group name`;
        }
        if (!isEmpty(roomIcon)) {
          return `${firstNameCreator} just changed the group image`;
        }
      }
      return '';
    }
    case INBOX_SYSTEM_MESSAGE_TYPES.ADD_MEMBER: {
      if (isOwner) {
        if (!isEmpty(firstNameP1)) {
          if (numberOther === 1) {
            return `${firstNameP1} just joined the group`;
          }
          if (numberOther === 2 && !isEmpty(firstNameP2)) {
            return `${firstNameP1} and ${firstNameP2} just joined the group`;
          }
          if (numberOther > 2) {
            return `${firstNameP1} and ${numberOther - 1} others just joined the group`;
          }
        }
      } else {
        if (isInTargetUser === -1) {
          if (!isEmpty(firstNameP1)) {
            if (numberOther === 1) {
              return `${firstNameCreator} added ${firstNameP1} to the group`;
            }
            if (numberOther === 2 && !isEmpty(firstNameP2)) {
              return `${firstNameCreator} added ${firstNameP1} and ${firstNameP2} to the group`;
            }
            if (numberOther > 2) {
              return `${firstNameCreator} added ${firstNameP1} and ${numberOther - 1} others to the group`;
            }
          }
        } else {
          if (numberOther === 1) {
            return `${firstNameCreator} added ${teammateCreator || 'you'} to the group`;
          }
          return `${firstNameCreator} added ${teammateCreator || 'you'} and ${pluralize(
            'other',
            numberOther - 1,
            true,
          )} to the group`;
        }
      }
      return '';
    }
    case INBOX_SYSTEM_MESSAGE_TYPES.REMOVE_MEMBER:
      if (isOwner && !isEmpty(fullNameP1) && !viewTeammate) {
        return `${fullNameP1} has been removed from the group`;
      }
      if (isOwner && !isEmpty(firstNameP1) && !isEmpty(lastNameP1) && !viewTeammate) {
        return `${firstNameP1} ${lastNameP1} has been removed from the group`;
      }
      if (!isEmpty(firstNameCreator) && !isEmpty(firstNameP1)) {
        return `${firstNameCreator} removed ${firstNameP1} from the group`;
      }
      return '';
    case INBOX_SYSTEM_MESSAGE_TYPES.MEMBER_LEAVE_GROUP: {
      if (isOwner && !viewTeammate) {
        return 'You left the group';
      }
      if (!isEmpty(firstNameCreator)) {
        return `${firstNameCreator} left the group`;
      }
      return '';
    }

    default:
      return '';
  }
}

function getIcon(message) {
  switch (message.system_message_type) {
    case INBOX_SYSTEM_MESSAGE_TYPES.CREATED_GROUP:
      return <StartIcon />;
    case INBOX_SYSTEM_MESSAGE_TYPES.UPDATE_GROUP_INFO:
      return <EditIcon />;
    case INBOX_SYSTEM_MESSAGE_TYPES.ADD_MEMBER:
      return <JoinIcon />;
    case INBOX_SYSTEM_MESSAGE_TYPES.REMOVE_MEMBER:
      return <RemoveIcon />;
    case INBOX_SYSTEM_MESSAGE_TYPES.MEMBER_LEAVE_GROUP:
      return <LeaveIcon />;
    default:
      return <></>;
  }
}
