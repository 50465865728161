import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { ASSET_TYPES } from 'components/OnboardingFlowDetail/constants';
import { formatDatePaymentSummary } from 'utils/commonFunction';

import { SharedTooltip } from 'shared/SharedTooltip';
import AssetSelect from 'components/OnboardingFlowDetail/components/AssetSelect';
import { CDN_URL } from 'constants/commonData';

import { ReactComponent as ArrowConnectTopIcon } from 'assets/icons/arrow_connect_top.svg';
import { ReactComponent as WarningRenameIcon } from 'assets/icons/warning-rename-icon.svg';

import * as S from './style';

const AssignToItem = props => {
  const { answer, type, onChange, timezone, handleChangeAsset, options = [], stateTrigger, userId } = props;
  const { _id, label, is_deleted, is_resolved, last_updated_date, last_updated_by, is_rename_resolved } = answer || {};

  const handleHideWaringOptionsChanged = () => {
    const newOptions = options.map(it => {
      if (it._id === _id) return { ...it, is_rename_resolved: true };
      return it;
    });

    handleChangeAsset && handleChangeAsset({ ...stateTrigger, trigger_options: newOptions }, type);
  };

  const renderIconStatus = () => {
    if (!is_resolved && last_updated_date && last_updated_by && is_rename_resolved === null) {
      const { full_name, first_name, last_name, _id: ownerId } = last_updated_by || {};
      let fullName = (full_name || `${first_name || ''} ${last_name || ''}`).trim();
      if (ownerId === userId) {
        fullName = 'You';
      }

      return (
        <div className="icon-wrapper" data-for={`warning-rename-icon-tooltip-${_id}`} data-tip>
          <WarningRenameIcon className="warning-icon" />
          <SharedTooltip
            content={
              <div className="tooltip-wrapper">
                <div>
                  This option was changed on <br />
                  <strong>{formatDatePaymentSummary(last_updated_date, timezone)}</strong> by{' '}
                  <strong>{fullName}</strong>.
                </div>
                <div className="button-wrapper">
                  <button className="confirm-warning-button" onClick={handleHideWaringOptionsChanged}>
                    OK
                  </button>
                </div>
              </div>
            }
            id={`warning-rename-icon-tooltip-${_id}`}
            width={228}
            className="warning-rename-icon-tooltip"
            clickable={true}
          />
        </div>
      );
    }

    if (is_deleted) {
      return (
        <div className="icon-wrapper">
          <img src={`${CDN_URL}/images/warning-delete-icon.svg`} alt="" />
        </div>
      );
    }

    return null;
  };

  const renderLabel = () => {
    if (is_deleted) return 'Option Deleted';
    return label;
  };

  return (
    <S.AnswerItemAssignToWrapper>
      <div className={classNames('answer-label', { 'is-deleted': is_deleted })}>
        {renderIconStatus()}
        {renderLabel()}
      </div>
      <div className="answer-assign-to-wrapper">
        <div className="left-content">
          <ArrowConnectTopIcon />
          <div className="assign-to-label">Assign to</div>
        </div>
        <div className="main-content" id={`${_id}-trigger-select`}>
          {type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS && (
            <AssetSelect
              url="/api/program-library"
              params={{
                sorter: 'updated_at',
                sort: -1,
              }}
              value={answer.program_library_data}
              type={ASSET_TYPES.PROGRAM_TRIGGER_FORMS}
              disabled={false}
              placeholder="Select Program"
              classNamePrefix="ofp"
              onSelect={onChange(_id)}
              idSelect={`${_id}-trigger-select`}
            />
          )}
          {type === ASSET_TYPES.FORUM_TRIGGER_FORMS && (
            <AssetSelect
              url="/api/v2/forum/get-list-group"
              params={{
                sorter: 'last_activity_at',
                sort: -1,
              }}
              value={answer.forum_data}
              type={ASSET_TYPES.FORUM_TRIGGER_FORMS}
              disabled={false}
              placeholder="Select Forum"
              classNamePrefix="off"
              onSelect={onChange(_id)}
              idSelect={`${_id}-trigger-select`}
            />
          )}
        </div>
      </div>
    </S.AnswerItemAssignToWrapper>
  );
};

const mapStateToProps = state => {
  const {
    user: { _id: userId, timezone },
  } = state;

  return {
    userId,
    timezone,
  };
};

export default connect(mapStateToProps, null)(AssignToItem);
