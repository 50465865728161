import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { markClientsAsComplete } from 'redux/autoflow/client/actions';
import { markClientsAsComplete as markClientsAsCompleteInterval } from 'redux/autoflowInterval/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { AUTOFLOW_TYPES, AUTOFLOW_CLIENT_STATUS, CDN_URL } from 'constants/commonData';
import * as S from './style';

function MaskAsComplete(props) {
  const { autoflow, clients, multiple } = props;
  const activeAndPauseClients = _.filter(
    clients,
    item => item.status === AUTOFLOW_CLIENT_STATUS.ACTIVE || item.status === AUTOFLOW_CLIENT_STATUS.PAUSE,
  );
  const client = activeAndPauseClients[0];

  const onConfirm = () => {
    if (props.type === AUTOFLOW_TYPES.INTERVAL) {
      props.markClientsAsComplete({ autoflow, clients: _.map(activeAndPauseClients, '_id') }, multiple);
      toast.success(
        `${activeAndPauseClients.length} ${
          activeAndPauseClients.length !== 1 ? 'clients' : 'client'
        } marked as ‘Completed’. This may take a few minutes to process.`,
        { className: 'margin-right--small' },
      );
    } else {
      props.markClientsAsComplete({ autoflow, clients: _.map(clients, '_id') }, multiple);
      toast.success(
        `${clients.length} ${
          clients.length !== 1 ? 'clients' : 'client'
        } marked as ‘Completed’. This may take a few minutes to process.`,
        { className: 'margin-right--small' },
      );
    }
    props.toggleModal(false);
  };

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/mark_complete_bg_purple.svg`} />
            <span>
              {props.type === AUTOFLOW_TYPES.INTERVAL
                ? `Mark ${activeAndPauseClients.length} ${
                    activeAndPauseClients.length !== 1 ? 'clients' : 'client'
                  } as completed`
                : `Mark ${clients.length} ${clients.length !== 1 ? 'clients' : 'client'} as completed`}
            </span>
          </S.Header>
          <S.Body>
            {props.type === AUTOFLOW_TYPES.INTERVAL && (
              <>
                {multiple && (
                  <div>
                    <div>
                      Mark {activeAndPauseClients.length !== 1 ? 'these' : 'this'}{' '}
                      <b>
                        {activeAndPauseClients.length} client{activeAndPauseClients.length !== 1 ? 's' : ''}
                      </b>{' '}
                      as completed for this Autoflow to keep the clients in insights but free them up for another
                      Autoflow. This Autoflow will stop syncing with the clients.
                    </div>
                    <br />
                    <div>
                      The change can not be reversed. You may instead want to pause the clients. Would you like to
                      continue?
                    </div>
                  </div>
                )}
                {!multiple && (
                  <div>
                    <div>
                      When <b>{client.full_name}</b> is marked as Completed, this Autoflow will stop syncing with the
                      client, but their compliance data will remain in insights. This also allows the client to join
                      another Autoflow, but note -- the change can not be reversed.
                    </div>
                    <br />
                    <div>Would you like to continue? You may instead want to pause the client.</div>
                  </div>
                )}
              </>
            )}
            {props.type !== AUTOFLOW_TYPES.INTERVAL && (
              <div>
                {multiple ? (
                  `Need to add ${clients.length !== 1 ? 'these clients' : 'this client'} to another Autoflow? Mark ${
                    clients.length !== 1 ? 'these clients' : 'this client'
                  } as completed for this Autoflow to save ${
                    clients.length !== 1 ? 'them' : 'him/her'
                  } in Insights but free ${clients.length !== 1 ? 'them' : 'him/her'} up for another Autoflow.`
                ) : (
                  <>
                    Mark <b>{clients[0].full_name}</b> as completed for this Autoflow to save client data in Insights
                    but allow client to join another Autoflow.
                  </>
                )}
              </div>
            )}
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button purple onClick={onConfirm}>
              Confirm
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    autoflow: workingAutoflow && workingAutoflow._id,
  };
};

const mapDispatch = (dispatch, props) => {
  if (props.type === AUTOFLOW_TYPES.INTERVAL)
    return {
      toggleModal: bindActionCreators(toggleModal, dispatch),
      markClientsAsComplete: bindActionCreators(markClientsAsCompleteInterval, dispatch),
    };

  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    markClientsAsComplete: bindActionCreators(markClientsAsComplete, dispatch),
  };
};

export default connect(mapState, mapDispatch)(MaskAsComplete);
