import React from 'react';
import _ from 'lodash';

import { checkSameMonth } from 'utils/commonFunction';
import { ReactComponent as CloseIconCopyWeek } from 'assets/icons/close-copy-week.svg';

import '../styles.scss';
import * as S from '../styles';

function AlterWeekSelected(props) {
  const { handleResetSelectedWeek, selectedWeek, calendarMode, permission } = props;
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V1 || !_.get(permission, 'copy_week')) return <></>;
  if (_.isEmpty(selectedWeek) || _.isEqual(calendarMode, 'history')) return <></>;
  const isSameMonth = checkSameMonth(_.get(selectedWeek, 'from', {}), _.get(selectedWeek, 'to', {}));
  const fromDate = _.get(selectedWeek, 'from', {}).toFormat('LLL d');
  const toDate = isSameMonth
    ? _.get(selectedWeek, 'to', {}).toFormat('d')
    : _.get(selectedWeek, 'to', {}).toFormat('LLL d');
  return (
    <S.BottomCopyWeekWrapper>
      <div>
        Week of&nbsp;
        <strong>
          {fromDate} - {toDate}
        </strong>
        &nbsp;copied
      </div>
      <div>
        <strong>Esc</strong>&nbsp;to cancel
        <CloseIconCopyWeek onClick={handleResetSelectedWeek} className="cancel-copy" />
      </div>
    </S.BottomCopyWeekWrapper>
  );
}

export default AlterWeekSelected;
