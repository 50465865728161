// Libs
import React, { useMemo } from 'react';
import get from 'lodash/get';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_purple.svg';

// Components
import SynonymsItem from '../SynonymsItem';

import * as S from './styles';

const Synonyms = props => {
  const { synonyms, handleUpdateSynonyms = () => {} } = props;

  const initialSynonyms = useMemo(() => {
    if (synonyms.length === 0) {
      return [''];
    }
    return synonyms;
  }, [synonyms]);

  const handleAddSynonym = () => {
    const newSynonyms = [...initialSynonyms, ''];
    handleUpdateSynonyms(newSynonyms);
  };

  const handleChangeContent = (value, index) => {
    const updatedSynonyms = initialSynonyms.map((content, idx) => (idx === index ? value : content));

    handleUpdateSynonyms(updatedSynonyms);
  };

  const handleRemoveSynonym = index => {
    const newSynonyms = synonyms.slice();
    newSynonyms.splice(index, 1);
    handleUpdateSynonyms(newSynonyms);
  };

  const renderItem = (content, index) => {
    return (
      <SynonymsItem
        lengthSynonym={initialSynonyms.length}
        index={index}
        key={`synonym_item_${index}`}
        content={content}
        handleChangeContent={handleChangeContent}
        handleRemoveSynonym={handleRemoveSynonym}
        placeholder="Add exercise synonyms"
      />
    );
  };

  return (
    <S.SynonymWrapper>
      <S.List>{initialSynonyms.map(renderItem)}</S.List>
      {initialSynonyms.length <= 4 && (
        <S.ButtonAdd onClick={handleAddSynonym}>
          <PlusIcon />
          Add more
        </S.ButtonAdd>
      )}
    </S.SynonymWrapper>
  );
};

export default Synonyms;
