import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const PlanPackageWrapper = styled.div`
  display: flex;
`;

export const PackageItemWrapper = styled.div`
  padding: 10px 15px 30px 20px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  position: relative;
  margin-top: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 calc(100% / 3);

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
  ${props =>
    props.limitPackage &&
    css`
      padding-bottom: 80px;
    `}

  .packageItem__current {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    min-height: 12px;
    margin-bottom: 10px;

    color: #202353;
    opacity: 0.6;
  }

  .packageItem__name {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 7px;
  }

  .packageItem__description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    color: #1a1f34;
    margin-bottom: 10px;
  }

  .packageItem__pricing {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    .packageItem__pricing--symbol {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 100%;
      color: #202353;
      vertical-align: top;
    }

    &--cost {
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 100%;
      color: #202353;
      margin-right: 5px;
    }

    &--duration {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;
      color: #202353;
    }

    &--annually {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #202353;
      opacity: 0.5;
    }
  }

  .packageItem__clients {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;

    color: #202353;
  }

  :first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: 1px solid transparent;
  }

  :last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 1px solid transparent;
  }

  ${props =>
    props.active &&
    css`
      background: #ffffff;
      border: 1px solid #6261ff;
      box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
      box-shadow: 0 0 0 1px #6261ff;
      z-index: 1;

      :first-child {
        border-right: 1px solid #6261ff;
      }

      :last-child {
        border-left: 1px solid #6261ff;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        right: 50%;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        background-color: #fff;
        border: 2px solid #6261ff;
        border-radius: 2px;
        border-left-color: #fff;
        border-top-color: #fff;
      }
    `}
`;

export const CheckBoxIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 14px;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  align-items: center;

  .checkbox__circle {
    opacity: 0;
    height: 12px;
    width: 12px;
    background: #6261ff;
    border-radius: 99999px;
  }

  ${props =>
    props.active &&
    css`
      border: 2px solid #6261ff;
      box-sizing: border-box;
      .checkbox__circle {
        opacity: 1;
      }
    `}
`;

export const RecommendWrapper = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 2px solid #6261ff;

  padding: 6px;
  background: #6261ff;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  ${props =>
    props.active
      ? css`
          width: calc(100% + 4px);
          left: -2px;
        `
      : css`
          width: calc(100% + 2px);
          left: -1px;
        `}
`;

export const PackageFeaturesWrapper = styled.div`
  margin-bottom: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .packageFeature__list {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease, padding-top 0.3s ease, margin-top 0.3s ease;

    &--compare {
      margin-top: 20px;
      padding-top: 30px;
      padding-bottom: 25px;
      border-top: 1px dashed #dadfea;
      max-height: 100%;
    }
  }

  .packageGroupFeature__list {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease, padding-top 0.3s ease, margin-top 0.3s ease;

    &--compare {
      padding-top: 25px;
      max-height: 150px;
      border-top: 1px dashed #dadfea;
    }
  }

  .packageItem__compare {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #5e59ff;
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
      transition: transform 0.3s ease;
      width: 10px;
      height: 9px;
    }

    &--compare {
      margin-bottom: 10px;
      margin-top: 38px;
      width: fit-content;
      border-bottom: 1px solid #5e59ff;

      img {
        transform: rotate(180deg);
      }
    }
  }
`;

export const GroupName = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 14px;
`;

export const FeatureItemWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;

  :last-child {
    margin-bottom: 0;
  }

  .featureItem__icon {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    background-color: #7470ef;
    background-image: url(${CDN_URL}/images/checked_white.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
    margin-top: 4px;

    &--studio {
      background-color: #62cd61;
    }
  }

  .featureItem__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 145%;
    color: #1a1f34;

    &--highlight {
      font-weight: bold;
    }
  }

  .__react_component_tooltip.app-tooltip-white {
    font-weight: normal;
    border: 1px solid #dfe4e5;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-width: 260px;
    font-size: 14px;
  }
`;

export const LimitWrapper = styled.div`
  display: flex;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 249, 228, 0.38), #fff6e4 52.36%);
  border-radius: 5px;
  padding: 15px 13px;
  position: absolute;
  left: 0;
  bottom: 0;
  svg {
    margin-right: 12px;
  }
`;

export const MessageContent = styled.p`
  font-size: 12px;
  margin-bottom: 0;
`;
