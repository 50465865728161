import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button as CloseButton, Image } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

// import actions
import { toggleConfirmModal } from 'actions/modal';
// import assets
import TitleImage from 'assets/images/training-setting-warning.png';
import WarningPopupStyles, { ContentStyles } from './styles';

const WarningPopup = ({ dispatch }) => {
  const onClose = () => {
    dispatch(toggleConfirmModal(false));
  };

  return (
    <WarningPopupStyles
      size={'tiny'}
      open={true}
      onClose={onClose}
      className="client-macro-note-discard-change"
      closeOnDimmerClick
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
    >
      <ContentStyles>
        <div className="title">
          <img src={TitleImage} alt="" />
          <div className="text">Where are my assignments?</div>
        </div>
        <div className="description">
          Please keep at least one of these features turned on for your clients:&nbsp;
          <b>Training, Log Activities, Tasks, Macros, or Food Journal</b>.
        </div>
        <div className="actions">
          <Button purple onClick={onClose}>
            Turn on a feature
          </Button>
        </div>
      </ContentStyles>
    </WarningPopupStyles>
  );
};

export default connect()(WarningPopup);
