import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as S from './styles';
import { SORTER, SORT } from './ChangeExerciseModal';
import { get } from 'lodash';
import classNames from 'classnames';

const TableHeader = ({ counter = 0, query, changeQueryParams }) => {
  const HeaderItems = [
    {
      name: 'exercise',
      title: `Exercises (${counter})`,
      value: SORTER.EXERCISE,
      showTotal: true,
      sort: true,
    },
    {
      name: 'tags',
      title: 'Tags',
      value: SORTER.TAGS,
      showTotal: false,
      sort: true,
    },
    {
      name: 'modality',
      title: 'Modality',
      value: SORTER.MODALITY,
      showTotal: false,
      sort: true,
    },
    {
      name: 'muscle_group',
      title: 'Muscle group',
      value: SORTER.MUSCLE_GROUP,
      showTotal: false,
      sort: true,
    },
    {
      name: 'movement_pattern',
      title: 'Movement pattern',
      value: SORTER.MOVEMENT_PATTERN,
      showTotal: false,
      sort: true,
    },
    {
      name: 'category',
      title: 'Category',
      value: SORTER.CATEGORY,
      showTotal: false,
      sort: true,
    },
  ];
  const handleSort = value => () => {
    let newSort = SORT.ASC;
    if (query.sorter === value) {
      if (query.sort === SORT.ASC) {
        newSort = SORT.DESC;
      }
    }
    const element = document.getElementById('content-header');
    if (element) {
      element.scrollTo({ top: 0 });
    }
    changeQueryParams({ sorter: value, sort: newSort, page: 1 });
  };
  return (
    <S.TableHeader>
      <S.TableRow isHeader>
        {HeaderItems.map(item => {
          const querySorter = get(query, 'sorter', '');
          const isActive = querySorter === item.value;
          const isDesc = query.sort !== SORT.DESC;
          const disableSort = item.name === SORTER.TAGS;
          {
            /* TODO: should check the active style */
          }
          return (
            <S.HeaderItem active={isActive}>
              <S.HeaderItemContainer active={isActive}>
                <div
                  className={classNames('click-container', { hover: !disableSort })}
                  onClick={!disableSort && handleSort(item.value)}
                >
                  {item.title}
                  {!disableSort && (
                    <>
                      {isActive ? (
                        <Icon className={`chevron ${isDesc ? 'down' : 'up'}`} />
                      ) : (
                        <Icon className={`chevron down`} />
                      )}
                    </>
                  )}
                </div>
              </S.HeaderItemContainer>
            </S.HeaderItem>
          );
        })}
      </S.TableRow>
    </S.TableHeader>
  );
};

export default TableHeader;
