import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import * as S from './style';
import { MENU } from './routes';

// import get from 'lodash/get';
import { getLastAutomationRoute, handleEnableProduct } from 'utils/commonFunction';
import { SIDEBAR_BACK_TO_PARENT_EVENT } from 'constants/commonData';
import { ReactComponent as BackIcon } from 'assets/icons/back_current_color.svg';
import ImageGotoBilling from '../ImageGotoBilling';

//NOTE:
// BECAUSE I DONT HAVE TIME TO REFACTOR THE SIDBAR
// SO FOR USER SETTING PAGE, I am using UI of desktop
// JUST use css to show/hide this part
// we should move the UI for setting sidebar here. (the parent is team - last item in the mobile routes (./routes))
// So we dont need to use custom event to trigger back to parent function

const TEAM_ROUTE = 'teammates_settings';

const handlePermissionPayment = permission => {
  if (!permission.explore_payment) {
    if (permission.payment) return true;
    return false;
  }
  return true;
};

const handleRenderNavItem = (status, data, otherProps) => {
  const { total, totalProduct, permission, totalMP, isTeamAdmin } = otherProps;

  switch (status) {
    case 'package':
      return (
        <S.ItemCountList>
          <span>{data.title}</span>
          {handlePermissionPayment(permission) && <S.NumberTotal>{total > 0 && total}</S.NumberTotal>}
        </S.ItemCountList>
      );
    case 'sequences': {
      return (
        <S.ItemCountList>
          <span>{data.title}</span>
          {permission.payment && <S.NumberTotal>{totalProduct > 0 && totalProduct}</S.NumberTotal>}
        </S.ItemCountList>
      );
    }
    case 'marketplace':
      return (
        <S.ItemCountList>
          <S.LabelWrapper>
            <span>{data.title}</span>
            <S.BetaLabel>beta</S.BetaLabel>
          </S.LabelWrapper>
          {handlePermissionPayment(permission) && <S.NumberTotal>{totalMP > 0 && totalMP}</S.NumberTotal>}
        </S.ItemCountList>
      );
    case 'automation': {
      return isTeamAdmin ? data.title : 'Autoflow';
    }
    default:
      return data.title;
  }
};

const handleRenderMenuItem = (data, otherProps) => {
  return (
    <S.ParentItem className={classnames({ subItem: data.subItem })}>
      {handleRenderNavItem(data.name, data, otherProps)}
    </S.ParentItem>
  );
};

const MenuItem = ({ data, activeItem, onClick, totalProduct, ...props }) => {
  const { permission, isTeamAdmin } = props;
  const hasMP = permission.marketplace_payment;

  const handleClick = () => {
    onClick(data.name);
  };

  if (!hasMP && data.name === 'marketplace') return null;

  if (data.name === 'package' && !isTeamAdmin && !permission.payment) {
    return null;
  }
  // TODO - Handle Render Upgrade Path
  if (!permission.payment && data.name === 'sequences') {
    return (
      <S.ItemRow
        isSub={props.isSub}
        className={classnames({
          active: typeof data.isActive === 'function' ? data.isActive(activeItem) : activeItem === data.name,
        })}
      >
        <S.Item
          isSub={props.isSub}
          className={classnames({
            active: typeof data.isActive === 'function' ? data.isActive(activeItem) : activeItem === data.name,
          })}
          to={data.route}
          onClick={handleClick}
        >
          {data.icon}
          {handleRenderMenuItem(data, props)}
        </S.Item>

        <ImageGotoBilling
          maxWidthContent={234}
          notSendPrev={false}
          isNotUsePathName={false}
          pathname={'/home/sequences'}
        />
      </S.ItemRow>
    );
  }

  if (data.name === 'automation' && (!isTeamAdmin || !permission.multiple_onboarding_flow)) {
    return (
      <S.Item
        isSub={props.isSub}
        className={classnames({
          active: typeof data.isActive === 'function' ? data.isActive(activeItem) : activeItem === data.name,
        })}
        to={getLastAutomationRoute() || '/home/onboarding-flow'}
        onClick={handleClick}
      >
        {data.icon}
        {handleRenderMenuItem(data, props)}
      </S.Item>
    );
  }

  return (
    <S.Item
      isSub={props.isSub}
      className={classnames({
        active: typeof data.isActive === 'function' ? data.isActive(activeItem) : activeItem === data.name,
      })}
      to={data.route}
      onClick={handleClick}
    >
      {data.icon}
      {handleRenderMenuItem(data, props)}
    </S.Item>
  );
};

function MobileSidebar(props) {
  const { activeItem, permission, isTeamAdmin, user, isPaymentAddOn } = props;
  const [data, setData] = useState({ menus: [], isSub: false, isSettingPage: false });

  const activeMenus = useMemo(() => {
    const list = [];
    MENU.forEach(item => {
      if (!item.canAccess || item.canAccess({ permission, isTeamAdmin, user })) {
        const menu = { ...item };

        if (menu.renderSubItems) {
          menu.subItems = menu.renderSubItems({ permission, isTeamAdmin, user });
        }
        list.push(menu);
      }
    });

    return list;
  }, [permission, isTeamAdmin]);

  const handleBackToParentEventFromSettingPage = () => {
    setData(prev => ({ ...prev, menus: activeMenus, isSub: false, isSettingPage: true, parent: TEAM_ROUTE }));
  };

  useEffect(() => {
    const handle = () => {
      handleBackToParentEventFromSettingPage();
    };

    window.addEventListener(SIDEBAR_BACK_TO_PARENT_EVENT, handle);

    return () => {
      window.removeEventListener(SIDEBAR_BACK_TO_PARENT_EVENT, handle);
    };
  }, []);

  useEffect(() => {
    const active = activeMenus.find(item =>
      typeof item.isActive === 'function' ? item.isActive(activeItem) : activeItem === item.name,
    );

    if (active && active.subItems) {
      setData({ menus: active.subItems, title: active.title, isSub: true, parent: active.name, isSettingPage: false });
    } else {
      setData({ menus: activeMenus, isSub: false, isSettingPage: false });
    }
  }, [activeItem]);

  const handleItemClick = name => {
    if (!data.isSub && name === data.parent) {
      const active = activeMenus.find(item => item.name === name);

      if (active && active.subItems) {
        setData({
          menus: active.subItems,
          title: active.title,
          isSub: true,
          parent: active.name,
          isSettingPage: false,
        });
      }
    }
  };

  const handleBackToParent = () => {
    setData(prev => ({ ...prev, menus: activeMenus, isSub: false, isSettingPage: false }));
  };

  /* TODO -  REACT_APP_ENABLE_PRODUCT */
  const isPublic = handleEnableProduct(user);

  return (
    <S.Wrapper overlapAll={data.isSettingPage} isSub={data.isSub}>
      {data.isSub && (
        <S.SubMenuHeader>
          <BackIcon className="back" onClick={handleBackToParent} />
          <span>{data.title}</span>
        </S.SubMenuHeader>
      )}
      {data.menus
        .filter(item => !!isPublic || (!isPublic && item.name !== 'sequences'))
        .map(item => {
          return (
            <MenuItem
              key={item.name}
              activeItem={activeItem}
              data={item}
              isSub={data.isSub}
              subItem={item.subItem}
              onClick={handleItemClick}
              isPublic={isPublic}
              {...props}
            />
          );
        })}
    </S.Wrapper>
  );
}

export default MobileSidebar;
