import React, { useState } from 'react';
import _ from 'lodash';
import { Radio, Form, Label, Image } from 'semantic-ui-react';
import { Toggle } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

import { SaveButton, UnitWrapper, ToggleWrapper } from './style';

const UnitSetting = ({ weight, distance, length, editUnit, onChange, isChanged, onSave, onChangeSync }) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleChangeToggle = () => {
    setIsChecked(!isChecked);
    onChangeSync();
  };

  const handleOnChange = (name, value) => {
    let updateUnit = _.cloneDeep(editUnit);
    updateUnit[name] = value;
    onChange(updateUnit);
  };

  return (
    <div className="basic_info unit">
      <div className="one">
        <Image width={17} height={17} src={`${CDN_URL}/images/unit.svg`} />
        <span>Units</span>
      </div>
      <div className="three default">
        <UnitWrapper>
          <Form.Group>
            <Form.Field>
              <Label className="setting-label">WEIGHT UNIT</Label>
            </Form.Field>
            <Form.Field
              control={Radio}
              label="Metric (kg)"
              className={'general ' + (editUnit && weight.kg._id === editUnit.weight ? 'active' : 'isactive')}
              value={weight && weight.kg._id}
              checked={editUnit && weight.kg._id === editUnit.weight}
              onChange={(evt, { value }) => handleOnChange('weight', value)}
            />
            <Form.Field
              control={Radio}
              className={'general ' + (editUnit && weight.lb._id === editUnit.weight ? 'active' : 'isactive')}
              label="US/Imperial (lb)"
              value={weight && weight.lb._id}
              checked={editUnit && weight.lb._id === editUnit.weight}
              onChange={(evt, { value }) => handleOnChange('weight', value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <Label className="setting-label">DISTANCE UNIT</Label>
            </Form.Field>
            <Form.Field
              control={Radio}
              label="Metric (km)"
              className={'general ' + (editUnit && distance.km._id === editUnit.distance ? 'active' : 'isactive')}
              value={distance && distance.km._id}
              checked={editUnit && distance.km._id === editUnit.distance}
              onChange={(evt, { value }) => handleOnChange('distance', value)}
            />
            <Form.Field
              control={Radio}
              className={'general ' + (editUnit && distance.mi._id === editUnit.distance ? 'active' : 'isactive')}
              label="US/Imperial (miles)"
              value={distance && distance.mi._id}
              checked={editUnit && distance.mi._id === editUnit.distance}
              onChange={(evt, { value }) => handleOnChange('distance', value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <Label className="setting-label">LENGTH UNIT</Label>
            </Form.Field>
            <Form.Field
              control={Radio}
              label="Metric (cm)"
              className={'general ' + (editUnit && length.cm._id === editUnit.length ? 'active' : 'isactive')}
              value={length && length.cm._id}
              checked={editUnit && length.cm._id === editUnit.length}
              onChange={(evt, { value }) => handleOnChange('length', value)}
            />
            <Form.Field
              control={Radio}
              className={'general ' + (editUnit && length.in._id === editUnit.length ? 'active' : 'isactive')}
              label="US/Imperial (inch)"
              value={length && length.in._id}
              checked={editUnit && length.in._id === editUnit.length}
              onChange={(evt, { value }) => handleOnChange('length', value)}
            />
          </Form.Group>
        </UnitWrapper>
        {isChanged && (
          <ToggleWrapper>
            <Toggle
              className="unit-client-toggle"
              checked={isChecked}
              onChange={handleChangeToggle}
              label="Also update units for Metrics"
            />
          </ToggleWrapper>
        )}
      </div>
      <div className="save_area one">
        {isChanged && (
          <div>
            <SaveButton purple onClick={onSave}>
              Save
            </SaveButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default UnitSetting;
