import React from 'react';
import { Rectangle } from 'recharts';
import { COLOR_HEART_RATE_CHART } from 'components/BodyMetricChartNew/constants';
import isEmpty from 'lodash/isEmpty';

const HeartRateBarShape = props => {
  const { x, y, width, height, payload, isOverview } = props;
  const { min = 0, max = 0, value = [] } = payload;
  const defaultHeightPoint = isOverview ? 4 : 6;

  if (isEmpty(value) || value.every(it => !it)) {
    return null;
  }

  return (
    <svg fill="none">
      <Rectangle
        x={x}
        y={y - defaultHeightPoint / 2}
        width={width}
        height={max === min ? defaultHeightPoint : height + defaultHeightPoint}
        radius={[3, 3, 3, 3]}
        fill={COLOR_HEART_RATE_CHART}
        className="bar-shape-custom"
      />
    </svg>
  );
};

export default HeartRateBarShape;
