import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import { Wrapper, Trigger, Item, Menu } from './style';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.triggerClientReact = null;
  }

  renderMenu = () => {
    if (!this.triggerClientReact || !this.state.open) {
      return null;
    }

    const { max, onSelect } = this.props;
    const options = [];

    for (let i = 2; i <= max; i++) {
      options.push({ key: `set${i}`, text: `Set ${i}`, value: i });
    }

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const { width, height, x, y } = this.triggerClientReact;
    const styles = {
      left: x,
    };

    const total = max - 1;
    const bottomSpacing = viewportHeight - (y + height + 2);
    const topSpacing = y - 2;

    if (bottomSpacing >= total * 35 || bottomSpacing >= topSpacing) {
      styles.top = y + height + 2;
      styles.maxHeight = bottomSpacing - 5;
    } else {
      styles.bottom = viewportHeight - topSpacing;
      styles.maxHeight = topSpacing - 5;
    }

    return (
      <Menu style={styles}>
        {_.map(options, item => (
          <Item
            key={item.key}
            onClick={() => {
              this.setState({ open: false });
              onSelect(item.value);
            }}
          >
            <span>+ {item.value} Sets</span>
          </Item>
        ))}
      </Menu>
    );
  };

  onTriggerClick = event => {
    const { open } = this.state;
    const { className, disabled } = this.props;

    if (disabled) {
      return;
    }

    const { target } = event;

    if (target) {
      const rectObj = target.getBoundingClientRect();

      if (!open && typeof this.props.onOpen === 'function') {
        this.props.onOpen(rectObj);
      }

      this.triggerClientReact = rectObj;
    }

    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const { className, disabled } = this.props;

    return (
      <RootCloseWrapper disabled={!open} event="click" onRootClose={() => this.setState({ open: false })}>
        <Wrapper className={classnames('addSetsWrapper', className, { open: open })}>
          <Trigger
            className={classnames('addSetsWrapper__trigger', { disabled: disabled })}
            onClick={this.onTriggerClick}
          />
          {this.renderMenu()}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}
