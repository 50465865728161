import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  padding: 2px 5px;

  background: #F4AA18;
  border-radius: 3px;
  cursor: default;
  outline: none;
  margin: 0 10px;
  border: none;
  
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #FFFFFF;
  opacity: 1 !important;
`;

export default function PremiumButton(props) {
  return (
    <Button>
      {props.text || 'Premium'}
    </Button>
  )
};
