import styled from 'styled-components';

export const TableContainer = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  table {
    thead {
      th {
        .title {
          font-size: 15px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        .description {
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: center;
          color: #777;
        }

        > div {
          position: absolute;
          top: 2px;
          white-space: normal;
          text-align: center;
        }

        :nth-child(2),
        :nth-child(2) > div,
        :nth-child(3),
        :nth-child(3) > div {
          width: 230px;
        }
      }
    }
  }
`;

export const TableWrapper = styled.div`
  flex: 1 1;
  overflow: hidden;
  position: relative;
  padding-top: 62px;
  height: 100%;
`;
