// Libs
import React, { PureComponent } from 'react';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, ReferenceLine, Line } from 'recharts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { get } from 'lodash';

// Components
import CustomizedAxisTick from '../../CustomizedAxisTick';
import CustomVerticalBarShape from './CustomVerticalBarShape';
import CustomTooltipContentVertical from './CustomTooltipContentVertical';
import CustomGoalDot from '../../CustomGoal';

// Actions
import { filterChartViewMode } from 'actions/bodyMetric';

// Constants
import { HOUR_LABELS, BOUNDARY_VALUES, KEYS_METRIC, PERIOD_GROUP } from 'components/BodyMetricChartNew/constants';
import { SLEEP_VIEW, TIME_RANGE_DATE } from './constants';

import {
  formatWeeklyLabel,
  getMinMaxValue,
  getSizeOfColumn,
  getXAxisInterval,
  getXLabels,
  handleShowCursor,
} from 'components/BodyMetricChartNew/chartHelper';
import CustomAvgLabel from '../../CustomAvgLabel';

// Helpers
import {
  convertDataSleepChart,
  convertSleepCharData,
  fillChartDataByPeriodTime,
  filterDataSleepChart,
  findMinMaxValueForDataSleep,
  findValueInRange,
  getValueDurationTimeSleep,
} from './helpers';

// Styles
import './style.scss';

class RangeChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tooltipPos: { x: undefined, y: undefined },
      filledData: [],
      barWidth: 20,
      xInterval: 0,
      isHoveringChart: false,
      chartBarData: {},
      tooltipPosition: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { isGroupOverview, isOverview } = this.props;
    if (!isGroupOverview && !isOverview) {
      handleShowCursor();
    }
  }

  componentDidMount() {
    const { isGroupOverview, isOverview } = this.props;

    if (!isGroupOverview && !isOverview) {
      handleShowCursor();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chartData, rangeTime, isOverview, isDoubleChart, isGroupOverview, sleepSettings } = nextProps;
    const chartDataSleepSorted = convertDataSleepChart(chartData, rangeTime);
    const chartDataSleepFilter = filterDataSleepChart(chartDataSleepSorted, sleepSettings);
    const filledData = fillChartDataByPeriodTime(chartDataSleepFilter, rangeTime);

    const barWidth = getSizeOfColumn(filledData, isOverview || isGroupOverview, isDoubleChart);
    const xInterval = getXAxisInterval(rangeTime, isGroupOverview, isOverview, isDoubleChart);
    this.setState({ filledData: filledData, barWidth, xInterval, chartBarData: {} });
  }

  handleTickFormat = (value, domain = [], isLessThan1Hour = false) => {
    const newValue = Math.floor(value);
    const { viewBy = '' } = this.props.sleepSettings;
    const durationType = viewBy === SLEEP_VIEW.DURATION;
    const timeType = viewBy === SLEEP_VIEW.TIME;
    if (timeType) {
      if (newValue === domain[0]) {
        return '';
      }
      const itemRange = TIME_RANGE_DATE.find(item => item.value === newValue) || {};
      return itemRange.label || '';
    }
    if (durationType) {
      if (newValue === 0) return '';
      return `${newValue}${isLessThan1Hour ? 'm' : 'h'}`;
    }
    return newValue;
  };

  formatXAxisLabel = label => {
    const {
      rangeTime: { period_group },
    } = this.props;
    const { xInterval, filledData } = this.state;
    const labels = period_group === PERIOD_GROUP.HOURLY ? HOUR_LABELS : getXLabels(filledData, xInterval);

    if (labels.includes(label)) {
      return formatWeeklyLabel(label, period_group);
    }
    return '';
  };

  setTooltipPosition = data => {
    this.setState({
      tooltipPosition: data,
    });
  };

  renderChart = () => {
    const {
      chartData,
      width = 500,
      height = 400,
      isOverview = false,
      isGroupOverview,
      isLoading,
      rangeTime: { period_group },
      sleepSettings = {},
      metricSettings,
      bodyMetric,
    } = this.props;

    const { _id: bId, mid } = bodyMetric || {};
    const _id = bId || mid;

    const { value: valueDurationGoal, sleep_time_goal: valueTimeGoal } = get(bodyMetric, 'target.data', {}) || {};
    const { duration: valueDurationAverage, sleep_time: valueTimeAverage } = get(bodyMetric, 'keyMetricData', {}) || {};

    const { isShowStages = false, viewBy = '', connectedApp = false } = sleepSettings;
    const durationType = viewBy === SLEEP_VIEW.DURATION;
    const timeType = viewBy === SLEEP_VIEW.TIME;
    const { filledData = [], barWidth, tooltipPos, xInterval, tooltipPosition } = this.state;

    let convertedFilledData = convertSleepCharData(filledData, sleepSettings, isOverview);

    const isEmpty = chartData.length === 0;
    const throttleDelay = period_group === PERIOD_GROUP.MONTHLY ? 300 : 100;

    const { min, max } = getMinMaxValue(convertedFilledData);
    const isNoData = BOUNDARY_VALUES.includes(min) && BOUNDARY_VALUES.includes(max);

    let tickCountValue = 5;
    let ticks = undefined;
    let domain = isNoData || isEmpty ? undefined : null;
    let isLessThan1Hour = false;

    const showGoalKeyMetric = get(metricSettings, 'key_metrics', []).includes('goal');
    const goalValue = getValueDurationTimeSleep(valueDurationGoal, valueTimeGoal, viewBy);
    const averageValue = getValueDurationTimeSleep(valueDurationAverage, valueTimeAverage, viewBy);

    if (durationType) {
      const { min: minValue, max: maxValue } = findMinMaxValueForDataSleep(convertedFilledData, goalValue, true);

      if (maxValue < 1) {
        tickCountValue = 3;
        ticks = [0, 30, 60];
        domain = [0, 60];
        isLessThan1Hour = true;
        convertedFilledData = convertedFilledData.map(item => {
          const { rangeValues, dataType } = item;
          return {
            ...item,
            rangeValues: rangeValues.map(item => item * 60),
            dataType: (dataType || []).map(item => ({ ...item, value: item.value * 60 })),
          };
        });
      } else {
        let maxValueConvert = maxValue % 1 === 0 ? maxValue + 1 : Math.ceil(maxValue);

        if (maxValue >= 24) {
          maxValueConvert = 24;
        }

        const { gap, max, ticks: ticksValue } = findValueInRange(minValue, maxValueConvert);
        tickCountValue = gap;
        ticks = ticksValue;
        domain = [minValue, max];
      }
    }

    if (timeType) {
      const { min: minValue, max: maxValue } = findMinMaxValueForDataSleep(convertedFilledData, goalValue, true);
      const maxValueConvert = maxValue >= 24 ? 24 : maxValue % 1 === 0 ? maxValue + 1 : Math.ceil(maxValue);
      const minValueConvert = minValue <= 0 ? 0 : minValue % 1 === 0 ? minValue - 1 : Math.floor(minValue) - 1;
      const { gap, max, ticks: ticksValue } = findValueInRange(0, maxValueConvert - minValueConvert);
      tickCountValue = gap;
      ticks = ticksValue.map(item => item + minValueConvert);
      domain = [minValueConvert, minValueConvert + max];
    }

    if (isEmpty) {
      const value = isLessThan1Hour ? goalValue * 60 : goalValue;
      tickCountValue = 3;
      ticks = [0, value, value * 2];
      domain = [0, value * 2];
    }

    const convertedGoalFilledData = convertedFilledData.map(item => ({ ...item, goal: goalValue ? goalValue * 2 : 0 }));

    return (
      <ResponsiveContainer
        width="100%"
        height="100%"
        className={classNames('metric-chart', { 'no-data': isEmpty, 'time-type': timeType })}
      >
        {!isLoading && (
          <ComposedChart
            width={width}
            height={height}
            data={convertedGoalFilledData}
            throttleDelay={throttleDelay}
            className="recharts-surface-default"
          >
            <XAxis
              dataKey="time"
              padding={{
                left: isGroupOverview || isOverview ? 5 : 10,
                right: 5,
              }}
              tickFormatter={xInterval === 0 ? lab => formatWeeklyLabel(lab, period_group) : this.formatXAxisLabel}
              interval={0}
              dy={5}
              tickLine={false}
              width={10}
              className={isEmpty && !isLoading && `customized-xAxis-${_id}`}
              tick={
                <CustomizedAxisTick
                  filledData={convertedFilledData}
                  xInterval={xInterval}
                  period_group={period_group}
                  bodyMetricId={_id}
                />
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ top: 10 }}
              tick={isGroupOverview || isOverview ? { dx: -2 } : {}}
              tickFormatter={value => this.handleTickFormat(value, domain, isLessThan1Hour)}
              domain={domain}
              tickCount={tickCountValue}
              allowDataOverflow
              ticks={ticks}
            />
            <Tooltip
              cursor={false}
              content={
                <CustomTooltipContentVertical
                  sleepSettings={sleepSettings}
                  rangeTime
                  isOverview={isOverview}
                  tooltipPosition={tooltipPosition}
                  isLessThan1Hour={isLessThan1Hour}
                />
              }
              isAnimationActive={false}
              animationEasing="ease-out"
              active={!!tooltipPosition}
              coordinate={tooltipPos}
              wrapperStyle={{ ...tooltipPosition, position: 'fixed', transform: 'none', zIndex: 1000 }}
              dataPayload={get(tooltipPosition, 'payload', {})}
            />

            <Bar
              dataKey="rangeValues"
              animationDuration={500}
              barSize={barWidth}
              activeBar={false}
              shape={
                <CustomVerticalBarShape
                  setTooltipPosition={this.setTooltipPosition}
                  viewBy={viewBy}
                  isShowStages={isShowStages}
                  connectedApp={connectedApp}
                  sleepSettings={sleepSettings}
                  isOverview={isOverview}
                />
              }
            />
            {showGoalKeyMetric && goalValue !== null && !isOverview && (
              <>
                <ReferenceLine
                  className="custom-goal-line"
                  y={goalValue * (isLessThan1Hour ? 60 : 1)}
                  stroke="#a3a3b5"
                  label={<CustomGoalDot />}
                />
                <ReferenceLine
                  y={goalValue * (isLessThan1Hour ? 60 : 1) * chartData.length === 0 ? 2 : 1}
                  stroke="#a3a3b5"
                  opacity={0}
                />
                <Line dataKey="goal" opacity={0} />
              </>
            )}
            {!isOverview && averageValue !== null && (
              <>
                <ReferenceLine
                  y={averageValue * (isLessThan1Hour ? 60 : 1)}
                  stroke="#4CD9CD"
                  strokeDasharray="2 7"
                  label={<CustomAvgLabel color="#4CD9CD" />}
                  className="avg-line"
                />
                <ReferenceLine
                  y={averageValue * (isLessThan1Hour ? 60 : 1) * chartData.length === 0 ? 2 : 1}
                  stroke="#4CD9CD"
                  strokeDasharray="2 7"
                  opacity={0}
                />
                <Line dataKey="averageValue" opacity={0} />
              </>
            )}
          </ComposedChart>
        )}
      </ResponsiveContainer>
    );
  };

  render() {
    return <>{this.renderChart()}</>;
  }
}

const mapDispatchToProps = dispatch => ({
  filterChartViewMode: bindActionCreators(filterChartViewMode, dispatch),
});

const mapState = ({ bodyMetric }) => ({
  filterTime: bodyMetric.filterTime,
  sleepSettings: bodyMetric.sleepSettings || {},
  metricSettings: bodyMetric.metricSettings || {},
});

export default connect(mapState, mapDispatchToProps)(RangeChart);
