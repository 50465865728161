import map from 'lodash/map';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useEffect, useState } from 'react';
import SearchParticipant from 'components/Inbox/SearchParticipant';
import { ReactComponent as NoContactsIcon } from 'assets/icons/no-contacts-icon.svg';
import { isESCPress } from 'utils/commonFunction';
import { CDN_URL, KEY_CODE } from 'constants/commonData';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';

const AddParticipantsModal = ({
  currentRoomId,
  onClose,
  getContactsList,
  addGroupMembers,
  resetStatus,
  getLatestMessages,
  startWaitingSystemMessage,
}) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const timerRef = useRef();

  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, []);

  const onEscPressed = event => {
    if (event.keyCode === KEY_CODE.esc || isESCPress(event.key)) {
      onClose && onClose();
    }
  };

  const handleChange = options => {
    setContacts(options);
  };

  const handleAddMembers = () => {
    if (loading) return;
    setLoading(true);

    addGroupMembers &&
      addGroupMembers(currentRoomId, contacts, onClose, resetStatus)
        .then(afterUpdateGroupInfo)
        .finally(() => {
          setLoading(false);
        });
  };

  const afterUpdateGroupInfo = () => {
    if (currentRoomId) {
      getLatestMessages && getLatestMessages();
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        const messagesComponent = document.getElementById(`detail-content-${currentRoomId}`);
        if (!isEmpty(messagesComponent)) {
          messagesComponent.scrollTop = messagesComponent.scrollHeight;
        }
      }, 1000);
      startWaitingSystemMessage && startWaitingSystemMessage();
    }
  };

  const searchGroupClient = (inputValue, callback) => {
    const except = map(
      filter(contacts, item => item.typeName === 'group'),
      '_id',
    );
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    getContactsList({ search, except })
      .then(response => {
        if (response.length) {
          const data = response.map(item => item.data.data);

          const clients = data[1].data.map(client => ({
            ...client,
            typeName: 'client',
            name: `${client.first_name} ${client.last_name}`,
          }));
          const groups = data[0].map(group => ({
            ...group,
            typeName: 'group',
          }));
          const results = [...groups, ...clients];

          callback(
            map(results, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label: item.name,
              total: item.total_clients,
              typeName: item.typeName,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  return (
    <S.ModalWrapper open={true} closeOnDimmerClick={false}>
      <button className="close-button" onClick={onClose}>
        <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
      </button>
      <S.ModalTitle>Add Participants</S.ModalTitle>
      <S.ModalDesc>Select from your connected clients and fellow coaches</S.ModalDesc>
      <S.ParticipantsList>
        <S.ParticipantsListLabel>Participants</S.ParticipantsListLabel>
        <SearchParticipant
          onChange={handleChange}
          name="select-contacts"
          defaultOptions={true}
          loadOptions={debounce(searchGroupClient, 300)}
          value={contacts}
          autoOpen
          autoFocus
          placeholder=""
          className="select-participants"
          classNamePrefix="isc"
          emptyIcon={<NoContactsIcon />}
        />
      </S.ParticipantsList>
      <ModalLayout.Actions className="confirm-footer">
        <S.ModalButton purple disabled={!contacts.length || loading} onClick={handleAddMembers}>
          Add
        </S.ModalButton>
      </ModalLayout.Actions>
    </S.ModalWrapper>
  );
};

export default AddParticipantsModal;
