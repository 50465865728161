import React from 'react';
import { CDN_URL } from 'constants/commonData';

import { Container } from './style';

export default ({ show = false }) => (
  <Container show={show}>
    <img src={`${CDN_URL}/images/drop_image.svg`} className="icon" alt="" />
    <div className="text">Drop image or video here</div>
  </Container>
);
