import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { toggleModal } from 'actions/modal';
import { getClientCountdownList, resetClientGoalCountdown } from 'redux/client-countdown/action';

// components
import GoalCountdownPopup from 'components/Note/Countdown/components/GoalCountdownPopup';
import CountdownOverviewItem from 'components/Note/Countdown/components/CountdownOverviewItem';
import GoalItem from 'components/Note/Countdown/components/GoalItemOverview';
import { NoteTypes } from 'components/Note/component';

// assets
import { ReactComponent as AddIcon } from 'assets/icons/add_custom_habit.svg';
import LoadingIndicator from 'shared/LoadingIndicator';
import { GoalCountdownContentOverviewStyled } from './styles';

const GoalCountdownContentOverview = props => {
  const {
    isRequestCountdown,
    countdownList,
    toggleModal,
    currentGoal,
    loadingCountdowns,
    pageCountdowns,
    totalCountdown,
    getClientCountdownList,
    selectedClientId,
    resetClientGoalCountdown,
  } = props;
  const isEmptyList = isEmpty(countdownList) && !isRequestCountdown && !currentGoal;

  const handleClickAdd = (type, isCreateNew) => {
    toggleGoalCountdownPopup(null, type, isCreateNew);
  };

  const toggleGoalCountdownPopup = (evt, type = NoteTypes.goal, isCreateNew) => {
    toggleModal(true, <GoalCountdownPopup activeTab={type} isCreateNew={isCreateNew} />);
  };

  const handleViewDetails = countdown => {
    toggleModal(true, <GoalCountdownPopup activeTab={NoteTypes.goal_countdown} countdownDetail={countdown} />);
  };

  const renderGoalBtn = (type, label) => (
    <button onClick={() => handleClickAdd(type, true)} className="add-btn">
      <AddIcon />
      {label}
    </button>
  );

  const renderCountdownList = () => {
    return (
      <>
        {countdownList.map(item => (
          <CountdownOverviewItem countdown={item} key={item._id} onSelect={handleViewDetails} />
        ))}
      </>
    );
  };

  useEffect(() => {
    const headPanel = document.querySelector('.goals-overview.goal-countdown .panel-header');
    if (headPanel) {
      headPanel.addEventListener('click', toggleGoalCountdownPopup);

      return () => {
        headPanel.removeEventListener('click', toggleGoalCountdownPopup);
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      resetClientGoalCountdown();
    };
  }, []);

  const handleLoadMoreCountDown = event => {
    const { clientHeight = 0, scrollHeight = 0, scrollTop = 0 } = event.target;

    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    const isEnd = Math.ceil(totalCountdown / 20) === pageCountdowns;
    if (!isEmptyList && bottom && !loadingCountdowns && !isEnd) {
      const currentPage = pageCountdowns + 1;
      const params = {
        page: currentPage,
        client: selectedClientId,
      };
      getClientCountdownList(params);
    }
  };

  const onScrollDebounce = debounce(handleLoadMoreCountDown, 300);

  return (
    <GoalCountdownContentOverviewStyled
      className={classNames('note-content goal-countdown-content', { empty: isEmptyList })}
      onScroll={event => {
        event.persist();
        onScrollDebounce.call(null, event);
      }}
    >
      {isEmptyList ? (
        <>
          {renderGoalBtn(NoteTypes.goal, 'Goal')}
          {renderGoalBtn(NoteTypes.goal_countdown, 'Countdown')}
        </>
      ) : (
        <>
          {!!currentGoal && (
            <GoalItem
              currentGoal={currentGoal}
              onClick={() => toggleGoalCountdownPopup(undefined, NoteTypes.goal, false)}
            />
          )}
          {renderCountdownList()}
          {loadingCountdowns && <LoadingIndicator className="loading-countdowns" />}
        </>
      )}
    </GoalCountdownContentOverviewStyled>
  );
};

const mapState = ({ rootReducer }) => {
  const { selected: selectedClientId } = rootReducer.client || {};
  const { isRequestCountdown, countdownList, currentGoal, loadingCountdowns = false, pageCountdowns, totalCountdown } =
    rootReducer.clientCountdown || {};
  return {
    isRequestCountdown,
    countdownList,
    currentGoal,
    loadingCountdowns,
    pageCountdowns,
    totalCountdown,
    selectedClientId,
  };
};

const mapDispatch = {
  toggleModal,
  getClientCountdownList,
  resetClientGoalCountdown,
};

export default connect(mapState, mapDispatch)(GoalCountdownContentOverview);
