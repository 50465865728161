import React, { useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import Select from 'react-select';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { Dropdown } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';

import SelectClientOption from 'shared/SelectClientOption';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down_new.svg';

import * as S from './style';

const OwnerFilter = props => {
  const { filterOwner, setFilterOwner, user, isHasSelectTrainer = false, afterSelect } = props;
  const [open, setOpen] = useState(false);

  const title = isArray(filterOwner) ? 'Certain trainers' : filterOwner === null ? 'All' : 'You';

  const handleSelectYou = () => {
    if (filterOwner !== get(user, '_id', '')) {
      setFilterOwner(get(user, '_id', ''));
      afterSelect && afterSelect();
    }
    setOpen(false);
  };

  const handleSelectAll = () => {
    if (filterOwner !== null) {
      setFilterOwner(null);
      afterSelect && afterSelect();
    }
    setOpen(false);
  };

  const handleSelectTrainer = () => {
    if (!isArray(filterOwner)) {
      setFilterOwner([get(user, '_id')]);
      afterSelect && afterSelect();
    }
  };

  const handleClose = () => {
    if (isArray(filterOwner) && !filterOwner.length) {
      setFilterOwner(get(user, '_id'));
    }
    setOpen(false);
  };

  const renderSelectOwner = () => {
    let defaultValue = [];

    if (!isArray(filterOwner)) {
      return null;
    }

    const teamMembers = user.team_member || [];
    const options = map(teamMembers, item => {
      return Object.assign({}, item, {
        key: item._id,
        value: item._id,
        label: `${item.first_name} ${item.last_name}`,
      });
    });

    if (isArray(filterOwner)) {
      forEach(filterOwner, id => {
        const obj = find(options, op => op.value === id);

        if (obj) {
          defaultValue.push(obj);
        }
      });
    }

    if (!defaultValue.length) {
      const currentUser = find(options, item => item._id === get(user, '_id'));

      if (currentUser) {
        defaultValue.push(currentUser);
      }
    }

    return (
      <Select
        isMulti
        options={options}
        components={{ Option: SelectClientOption }}
        classNamePrefix="multi-select"
        placeholder="Enter user..."
        onChange={owners => setFilterOwner(map(owners, '_id'))}
        defaultValue={defaultValue}
        className="multi-select-container"
        noOptionsMessage={() => 'No results'}
      />
    );
  };

  return (
    <RootCloseWrapper onRootClose={handleClose} event="click" disabled={!open}>
      <S.Wrapper>
        <div className={`filter__dropdown ${open ? 'active' : ''}`} onClick={() => setOpen(!open)}>
          <div className="filter__dropdown__title">
            <span className="filter-name">Owner</span>
            <span className="colons">:</span>
            <span className="selected">{title}</span>
          </div>
        </div>
        {open && (
          <S.MenuContainer>
            <div className="form-group">
              <label>Owner</label>
              <Dropdown text={title} icon={<ArrowDown />}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSelectAll} className={filterOwner === null ? 'active' : ''}>
                    All
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleSelectYou} className={typeof filterOwner === 'string' ? 'active' : ''}>
                    You
                  </Dropdown.Item>
                  {isHasSelectTrainer && (
                    <Dropdown.Item
                      onClick={handleSelectTrainer}
                      className={isArray(filterOwner) && filterOwner.length ? 'active' : ''}
                    >
                      Certain trainers...
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {renderSelectOwner()}
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

OwnerFilter.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setFilter: PropTypes.func,
  user: PropTypes.object,
  isHasSelectTrainer: PropTypes.bool,
};

export default OwnerFilter;
