import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';

import {
  getLabelDetail,
  addNewPropertyLabels,
  resetSelectedWorkoutLabel,
  removePropertyFromList,
  updateLabelOptionName,
} from 'redux/workout-labels/actions';
import { toggleConfirmModal } from 'actions/modal';
import { TYPE } from './constants';
import { CDN_URL } from 'constants/commonData';

import LoadingIndicator from 'shared/LoadingIndicator';
import WorkoutLabelsHeader from './WorkoutLabelsHeader';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as EmptyOption } from 'assets/images/empty-label-option.svg';
import AddNewForm from './AddNewForm';

import * as S from './style';
import { SharedTooltip } from 'shared/SharedTooltip';

function WorkoutLabelsDetail({
  labelID,
  getLabelDetail,
  selectedWorkoutLabel,
  labelList,
  addNewPropertyLabels,
  push,
  loading,
  resetSelectedWorkoutLabel,
  toggleConfirmModal,
  removePropertyFromList,
  updateLabelOptionName,
}) {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [optionEditing, setOptionEditing] = useState();
  const labelName = get(selectedWorkoutLabel, 'name', '');
  const labelType = get(selectedWorkoutLabel, 'type', 'default');

  useEffect(() => {
    getLabelDetail && getLabelDetail(labelID);
  }, []);

  useEffect(() => {
    setList(labelList);
  }, [labelList]);

  const handleRemoveProperty = id => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        newStyle
        largeSpacing
        hasHoverState
        confirmButtonTitle="Remove"
        title="Remove Option"
        content="Are you sure you want to remove this label option? It will be removed from all the workouts in the on-demand workout library."
        onConfirm={() => removePropertyFromList(id)}
      />,
    );
  };

  const handleEditLabelOption = item => {
    setOpen(true);
    setOptionEditing(item);
  };

  const handleUpdateOptionName = (params, callback) => {
    optionEditing &&
      updateLabelOptionName &&
      updateLabelOptionName({ ...params, labelItemId: get(optionEditing, '_id') });
    callback && callback();
  };

  const renderLabelItem = (item, key) => {
    const index = (key || 0) + 1;
    const labelOptionId = get(item, '_id', '');
    const labelOptionName =
      optionEditing && optionEditing.labelItemId === labelOptionId ? optionEditing.name : get(item, 'name', '');
    const labelType = get(selectedWorkoutLabel, 'type', 'default');
    const renderTrashButton = () => {
      return (
        TYPE.CUSTOM === labelType && (
          <S.IconsWrapper>
            <>
              <S.CustomEditIcon
                className="labelInfo__icon"
                onClick={() => handleEditLabelOption(item)}
                data-tip
                data-for="edit-workout-label-tooltip"
              />
              <SharedTooltip id="edit-workout-label-tooltip" content="Edit" />
            </>
            <>
              <S.CustomDeleteIcon
                className="labelInfo__icon"
                onClick={() => handleRemoveProperty(labelOptionId)}
                data-tip
                data-for="remove-workout-label-tooltip"
              />
              <SharedTooltip id="remove-workout-label-tooltip" content="Remove" />
            </>
          </S.IconsWrapper>
        )
      );
    };

    return (
      <S.ItemWrapper key={item._id} highlight={labelType !== TYPE.DEFAULT}>
        <div>
          <S.ItemName>
            <S.ItemInfo>{index}</S.ItemInfo>
            {labelOptionName}
          </S.ItemName>
        </div>
        {renderTrashButton()}
      </S.ItemWrapper>
    );
  };

  return (
    <>
      <Helmet>
        <title>{labelName} - Workout Label - Everfit</title>
      </Helmet>
      <S.Container>
        {labelName ? (
          <WorkoutLabelsHeader
            name={labelName}
            addNewPropertyLabels={addNewPropertyLabels}
            labelID={labelID}
            push={push}
            resetSelectedWorkoutLabel={resetSelectedWorkoutLabel}
            type={get(selectedWorkoutLabel, 'type', 'default')}
          />
        ) : null}
        {loading ? (
          <LoadingIndicator />
        ) : list.length ? (
          <S.ListWrapper labelType={labelType === TYPE.DEFAULT}>
            {list.map((item, key) => renderLabelItem(item, key))}
          </S.ListWrapper>
        ) : (
          <S.EmptyOptionArea>
            <EmptyOption />
            <S.EmptyText>Add your first label option</S.EmptyText>
          </S.EmptyOptionArea>
        )}
        <AddNewForm
          open={open}
          itemEditing={optionEditing}
          titlePopup="Edit Label Option"
          submitName="Save"
          titleInput="Option name"
          placeholderInput="Enter Option Name"
          maxLength={30}
          onSetOpen={setOpen}
          onSubmit={handleUpdateOptionName}
        />
      </S.Container>
    </>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { workoutLabels },
    router: { location },
  } = state;

  return {
    loading: workoutLabels.loading,
    labelID: get(location, 'pathname', '').replace('/home/workout-labels/', ''),
    selectedWorkoutLabel: get(workoutLabels, 'selectedWorkoutLabel', null),
    labelList: get(workoutLabels, 'selectedWorkoutLabel.items', []),
  };
};

const mapDispatchToProps = dispatch => ({
  getLabelDetail: bindActionCreators(getLabelDetail, dispatch),
  addNewPropertyLabels: bindActionCreators(addNewPropertyLabels, dispatch),
  push: bindActionCreators(push, dispatch),
  resetSelectedWorkoutLabel: bindActionCreators(resetSelectedWorkoutLabel, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removePropertyFromList: bindActionCreators(removePropertyFromList, dispatch),
  updateLabelOptionName: bindActionCreators(updateLabelOptionName, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutLabelsDetail);
