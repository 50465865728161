import { debounce, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { changeQueryParams, getCoachBios } from 'redux/coach-bios/actions';
import LoadingIndicator from 'shared/LoadingIndicator';
import { NewSearchInput } from 'shared/SearchInput';
import CoachBioItem from './CoachBioItems';
import Header from './Header';
import * as Layout from 'shared/LibraryLayout';
import { MESSAGE_NOT_FOUND } from './constants';

import * as S from './style';

const CoachBioList = props => {
  const {
    coachBios,
    coachBiosTotal,
    changeQueryParams,
    loading,
    push,
    page,
    pageSize,
    cloudfrontList,
    fromTeammate = false,
  } = props;

  const [isLoadmore, setIsLoadmore] = useState(false);

  const length = coachBios ? coachBios.length : 0;

  useEffect(() => {
    changeQueryParams({ q: '', page: 1 });
  }, []);

  const onKeyPress = event => {
    event.persist();
  };

  const onSearch = (event, { value }) => {
    if (!value) {
      changeQueryParams({ q: '', page: 1 });
      return;
    }

    const trimmed = value.toLowerCase().trim();
    changeQueryParams({ q: trimmed, page: 1 });
  };

  const searchDebounce = debounce(onSearch, 300);

  const handleOpenDetail = coach => {
    const id = get(coach, '_id', '');
    fromTeammate ? push(`/home/teammates/coach-bios/${id}`) : push(`/home/coach-bios/${id}`);
  };

  const handleScrollEnd = event => {
    const isLastPage = page >= Math.ceil(coachBiosTotal / pageSize);
    setIsLoadmore(false);
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && !loading && !isLastPage) {
      setIsLoadmore(true);
      changeQueryParams({ page: page + 1 }, true);
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const renderLoadingIndicator = (isLoading, isLoadmore) => {
    if (isLoading && isLoadmore) {
      return (
        <Layout.TableBody>
          <S.CoachBiosTableRow>
            <Layout.TableCell colSpan="5">
              <S.LoadingWrapper>
                <LoadingIndicator title="Loading coach bios" className="loading-coach-bios" />
              </S.LoadingWrapper>
            </Layout.TableCell>
          </S.CoachBiosTableRow>
        </Layout.TableBody>
      );
    }

    return <S.EndLoadmore />;
  };

  return (
    <S.CoachBiosWrapper>
      <S.CoachSearch>
        <NewSearchInput
          placeholder="Search by name or email"
          onChange={searchDebounce}
          onClearSearch={() => onSearch(null, { key: '' })}
          onKeyPress={onKeyPress}
        />
      </S.CoachSearch>
      <S.TableWrapper
        onScroll={event => {
          event.persist();
          onScrollDebounce.call(null, event);
        }}
      >
        <Layout.Table>
          <Header />
          {!!coachBios &&
            coachBios.map(coach => {
              return (
                <CoachBioItem
                  key={coach._id}
                  coach={coach}
                  handleOpenDetail={handleOpenDetail}
                  cloudfrontList={cloudfrontList}
                />
              );
            })}
          {renderLoadingIndicator(loading, isLoadmore)}
        </Layout.Table>
        {!loading && !length ? (
          <Layout.NoResult>
            <Layout.NoResultText>{MESSAGE_NOT_FOUND}</Layout.NoResultText>
          </Layout.NoResult>
        ) : null}
      </S.TableWrapper>
    </S.CoachBiosWrapper>
  );
};

const mapStateToProps = state => {
  const {
    cloudfrontList,
    rootReducer: {
      coachBios: {
        list = [],
        total = 0,
        loading = false,
        query: { page, pageSize },
      },
    },
  } = state;

  return {
    cloudfrontList,
    coachBios: list,
    coachBiosTotal: total,
    loading: loading,
    page: page,
    pageSize: pageSize,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    getCoachBios: bindActionCreators(getCoachBios, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachBioList);
