import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { onSubmitted } from 'actions/submitStatus';
import {
  addWorkoutToProgramLibrary,
  fetchWorkoutsByWeek,
  getProgramWorkoutDetail,
  addNewWorkoutToProgramLibrary,
  updateProgramLibraryWorkout,
  saveWorkoutToWorkoutLibrary,
  removeWorkoutFromDate,
} from 'redux/program_library/program_library.actionCreators';
import { toggleModal } from 'actions/modal';
import ChooseWorkoutModal from 'components/ChooseWorkoutModal';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import CreateWorkoutTemplateAIDemo from 'components/CreateWorkoutTemplateAIDemo';
import { TAGS_TYPE, WORKOUT_AI_BUILDER_PAGES, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { axiosInstance } from 'configs/request';
import { getMostRecentTagsList } from 'redux/tags/actions';
import { omitEmptyRequestParams } from 'utils/commonFunction';

const DEFAULT_PARAMS = { page: 1, per_page: 10 };

class AssignWorkoutModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 0,
      workouts: [],
      isCreateNew: false,
      isSubmitting: false,
    };
    this.search = '';
    this.tags = [];
    this.searchDebounce = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.searchWorkout();
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.WORKOUT });
  }

  searchWorkout = searchParams => {
    const q = _.get(searchParams, 'q');
    const tags = _.get(searchParams, 'tags', []);
    const params = omitEmptyRequestParams({
      ...DEFAULT_PARAMS,
      q,
      search_tags: _.join(
        tags.map(tag => tag._id),
        ',',
      ),
    });
    this.setState({ loading: true, searching: true });
    axiosInstance
      .get('/api/workout/v2/list', { params })
      .then(response => {
        const { data, total } = response.data;
        this.setState({
          workouts: data,
          total,
          page: params.page,
          hasMore: data.length < response.data.total,
          loading: false,
          searching: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false, searching: false });
      });
  };

  onSearch = params => {
    this.search = params.q;
    this.tags = params.tags;
    this.searchWorkout(params);
  };

  loadMore = () => {
    const { hasMore, page, isLoading } = this.state;
    const params = omitEmptyRequestParams({
      page: page + 1,
      per_page: DEFAULT_PARAMS.per_page,
      q: this.search,
      search_tags: _.join(
        this.tags.map(tag => tag._id),
        ',',
      ),
    });

    if (hasMore && !isLoading) {
      this.setState({ loading: true });
      axiosInstance
        .get('/api/workout/v2/list', { params })
        .then(response => {
          const { data } = response.data;
          const newWorkouts = _.unionBy(this.state.workouts, data, '_id');
          this.setState({
            workouts: newWorkouts,
            total: response.data.total,
            page: params.page,
            hasMore: newWorkouts.length < response.data.total,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onSelect = workout => {
    const { program, weekIndex, dayIndex } = this.props;
    const params = { weekIndex, dayIndex, programId: program._id, workoutId: workout._id };
    this.setState({ isSubmitting: true });
    this.props
      .addWorkoutToProgramLibrary(params)
      .then(() => {
        this.props.toggleModal(false);
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  onClose = () => {
    this.props.onSubmitted('WORKOUT_DETAIL', true);
    this.props.toggleModal(false);
  };

  onCreateNew = () => {
    this.setState({ isCreateNew: true });
  };

  onSaveTheNewWorkout = async workout => {
    const { weekIndex, dayIndex, program } = this.props;
    const params = {
      weekIndex,
      dayIndex,
      programId: program._id,
      title: workout.title,
      background: workout.background,
      description: workout.description,
      sections: workout.sections,
      is_generated_by_ai: workout.is_generated_by_ai || false,
      conversion_id: workout.is_generated_by_ai ? workout.conversion_id || null : null,
      is_edited: workout.is_generated_by_ai ? workout.is_edited || false : undefined,
    };

    try {
      const response = await this.props.addNewWorkoutToProgramLibrary(params);
      const { workout } = response.data;

      return workout;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  handleUpdateAssignment = async workout => {
    const { weekIndex, dayIndex, program, updateProgramLibraryWorkout } = this.props;
    const { _id, title, description, sections, background } = workout || {};

    const bodyData = {
      workoutId: _id,
      title: title,
      description: description,
      sections: sections,
      background: background,
      programId: (program || {})._id,
      weekIndex,
      dayIndex,
    };

    try {
      const response = await updateProgramLibraryWorkout(bodyData);
      return response.data.data;
    } catch (error) {
      return Promise.resolve(null);
    }
  };

  handleSaveToLibrary = workout => {
    const { saveWorkoutToWorkoutLibrary } = this.props;
    saveWorkoutToWorkoutLibrary({ workoutId: (workout || {})._id });
  };

  handleDeleteAssignment = workoutId => {
    const { weekIndex, dayIndex, program, removeWorkoutFromDate } = this.props;
    removeWorkoutFromDate({ weekIndex, dayIndex, workoutId, programId: (program || {})._id });
  };

  handleEverfitAIClick = () => {
    const { toggleModal } = this.props;
    if (toggleModal) {
      toggleModal(
        true,
        <CreateWorkoutTemplateAIDemo
          onAssignment={this.onSaveTheNewWorkout}
          onUpdateAssignment={this.handleUpdateAssignment}
          onSaveToLibrary={this.handleSaveToLibrary}
          onAssignmentDelete={this.handleDeleteAssignment}
          pdfType="program_workout"
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          onSavedWithoutClose={this.onSavedWithoutClose}
          workoutAIPage={WORKOUT_AI_BUILDER_PAGES.program_calendar}
        />,
      );
    }
  };

  onSavedWithoutClose = workout => {
    const { weekIndex, dayIndex, program } = this.props;
    this.props.getProgramWorkoutDetail(workout._id, weekIndex, dayIndex, program._id);
  };

  render() {
    const { weekIndex, dayIndex, totalWeek, workoutTags, enableAIworkout } = this.props;
    const { isCreateNew, hasMore, loading, searching, total, isSubmitting } = this.state;

    if (isCreateNew) {
      return (
        <WorkoutDetailModal
          key="create-new-program-workout"
          maxWeeks={totalWeek}
          onSave={this.onSaveTheNewWorkout}
          onSavedWithoutClose={this.onSavedWithoutClose}
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          fetchWorkoutsByWeek={fetchWorkoutsByWeek}
          date={Number(weekIndex) * 7 + Number(dayIndex)}
          pdfType="program_workout"
        />
      );
    }

    return (
      <ChooseWorkoutModal
        workouts={this.state.workouts}
        workoutTags={workoutTags}
        onClose={this.onClose}
        onSearch={params => {
          this.search = params.q;
          this.tags = params.tags;
          this.searchDebounce(params);
        }}
        loadMore={this.loadMore}
        isLoading={loading}
        searching={searching}
        total={total}
        hasMore={hasMore}
        onSelect={this.onSelect}
        onCreateNew={this.onCreateNew}
        title="Find a Workout"
        displayOwner
        displayLastUsed
        disableSelectButton={isSubmitting}
        onEverfitAIClick={enableAIworkout ? this.handleEverfitAIClick : undefined}
      />
    );
  }
}

const mapState = state => {
  const {
    rootReducer: { program_library, workoutTags, permission },
  } = state;
  const enableAIAllWorkoutBuilder = process.env.REACT_APP_AI_ENABLE_ALL_WORKOUT_BUILDER || false;
  const enableAIworkout = enableAIAllWorkoutBuilder && _.get(permission, 'workout_builder_ai', false);

  return {
    program: program_library.selected,
    totalWeek: program_library.selected_total_week,
    workoutTags,
    enableAIworkout,
  };
};

const mapDispatch = dispatch => {
  return {
    addWorkoutToProgramLibrary: bindActionCreators(addWorkoutToProgramLibrary, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    onSubmitted: bindActionCreators(onSubmitted, dispatch),
    addNewWorkoutToProgramLibrary: bindActionCreators(addNewWorkoutToProgramLibrary, dispatch),
    updateProgramLibraryWorkout: bindActionCreators(updateProgramLibraryWorkout, dispatch),
    saveWorkoutToWorkoutLibrary: bindActionCreators(saveWorkoutToWorkoutLibrary, dispatch),
    removeWorkoutFromDate: bindActionCreators(removeWorkoutFromDate, dispatch),
    getProgramWorkoutDetail: bindActionCreators(getProgramWorkoutDetail, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(AssignWorkoutModal);
