/**
 * @flow
 */

import React from 'react';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';
import { connect } from 'react-redux';
import * as S from './style';

class ShareDropdown extends React.PureComponent {
  state = {
  };

  render() {
    const {
      owner,
      share,
      handleChangeShare,
      user, 
      isTeamAdmin,
      disableSharing
    } = this.props;

    if (!user) {
      return null;
    }
    let ownerValue = owner;
    if (!owner || share === TEAM_SHARE_NOOWNER) {
      ownerValue = 'no_owner';
    }
    let shareOptions = [
      {
        text: TEAM_SHARE[TEAM_SHARE_PRIVATE],
        value: TEAM_SHARE_PRIVATE
      },
      {
        text: TEAM_SHARE[TEAM_SHARE_SHARED],
        value: TEAM_SHARE_SHARED
      }
    ]
    if (share === TEAM_SHARE_NOOWNER) {
      return null;
    }
    return (
      <S.FormControl>
        <S.Label>Share with ORG?</S.Label>
        <S.StudioDropdown
          value={share}
          disabled={!!disableSharing || (!isTeamAdmin && owner !== user._id && ownerValue !== 'no_owner')}
          selection
          className=""
          options={shareOptions}
          onChange={handleChangeShare}
        />
      </S.FormControl>
    );
  }
}


const mapStateToProps = (state) => {
  const { user } = state;
  let isTeamAdmin = false;
  if (!user.teams || user.teams.length === 0 || user.teams[0].role === 0 || user.teams[0].role === 2) {
    isTeamAdmin = true;
  }
  return {
    user,
    isTeamAdmin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareDropdown)