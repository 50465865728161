// Libs
import React, { useMemo } from 'react';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { Doughnut } from 'react-chartjs-2';

// Assets
import { ReactComponent as IconMacroChart } from 'assets/icons/MealPlans/nutrition_chart_icon.svg';

// Helper
import {
  calculateNutritionChartValues,
  conditionalRoundNutrition,
  getValueCaloriesFromMacro,
  handleEmptyValue,
  sortCaloriesToTop,
} from '../helper';

// Constants
import { listMacronutrients } from '../constants';

// Style
import * as S from './style';

const chartOptions = {
  responsive: true,
  cutoutPercentage: 70,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    duration: 0,
  },
};

const NutritionInfoDetail = props => {
  const { MacroNutrients, OtherNutrients, listOtherNutrients, autoCalculated = false } = props;

  const valueNutrition = useMemo(() => calculateNutritionChartValues(MacroNutrients), [MacroNutrients]);

  const valueProtein = get(valueNutrition, 'protein.value');
  const valueCarbs = get(valueNutrition, 'carbs.value');
  const valueFat = get(valueNutrition, 'fat.value');
  const valueCalories = get(valueNutrition, 'calories.value');

  const percentProtein = get(valueNutrition, 'protein.percent');
  const percentCarbs = get(valueNutrition, 'carbs.percent');
  const percentFat = get(valueNutrition, 'fat.percent');

  const blankChart = !percentProtein && !percentCarbs && !percentFat;

  const data = {
    datasets: [
      {
        data: !blankChart ? [percentProtein, percentCarbs, percentFat] : [100],
        backgroundColor: !blankChart ? ['#57B5EA', '#65CC9C', '#FFBE49'] : ['#A3A3B5'],
        hoverBackgroundColor: !blankChart ? ['#57B5EA', '#65CC9C', '#FFBE49'] : ['#A3A3B5'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <S.NutritionInfoDetailWrapper isAutoCalculated={autoCalculated}>
      <S.MacroSection isEmptyValue={blankChart} isAutoCalculated={autoCalculated}>
        <S.MacroList>
          <S.MacroItem status="protein" isBlankData={valueProtein <= 0}>
            Protein{' '}
            <span className="percent">
              ({percentProtein}
              <span className="percentage">%</span>)
            </span>
            <S.Value>
              {handleEmptyValue(autoCalculated ? round(valueProtein, 2) : conditionalRoundNutrition(valueProtein))}
              <S.ValueUnit>&nbsp;g</S.ValueUnit>
            </S.Value>
          </S.MacroItem>
          <S.MacroItem status="carbs" isBlankData={valueCarbs <= 0}>
            Carbs{' '}
            <span className="percent">
              ({percentCarbs}
              <span className="percentage">%</span>)
            </span>
            <S.Value>
              {handleEmptyValue(autoCalculated ? round(valueCarbs, 2) : conditionalRoundNutrition(valueCarbs))}
              <S.ValueUnit>&nbsp;g</S.ValueUnit>
            </S.Value>
          </S.MacroItem>
          <S.MacroItem status="fat" isBlankData={valueFat <= 0}>
            Fat{' '}
            <span className="percent">
              ({percentFat}
              <span className="percentage">%</span>)
            </span>
            <S.Value>
              {handleEmptyValue(autoCalculated ? round(valueFat, 2) : conditionalRoundNutrition(valueFat))}
              <S.ValueUnit>&nbsp;g</S.ValueUnit>
            </S.Value>
          </S.MacroItem>
        </S.MacroList>
        <S.MacroMain>
          <S.MacroChart>
            <Doughnut data={data} width="100%" height="100%" options={chartOptions} />
            <S.IconMacroChart isBlankData={blankChart}>
              <IconMacroChart />
            </S.IconMacroChart>
          </S.MacroChart>
          <S.MacroValue>
            {handleEmptyValue(autoCalculated ? round(valueCalories, 2) : conditionalRoundNutrition(valueCalories))}
            <span className="unit">&nbsp;cal</span>
          </S.MacroValue>
          <S.MacroName>Calories</S.MacroName>
        </S.MacroMain>
      </S.MacroSection>
      <S.NutritionInfo>
        <S.TitleNutritionInfo isAutoCalculated={autoCalculated}>Amount per serving</S.TitleNutritionInfo>
        {sortCaloriesToTop([...listMacronutrients]).map(item => {
          const { label, unit, type } = item;

          return (
            <S.NutritionInfoItem key={type}>
              <S.Name>{label}</S.Name>
              <S.Unit>
                {handleEmptyValue(
                  autoCalculated
                    ? round(valueNutrition[type].value, 2)
                    : conditionalRoundNutrition(valueNutrition[type].value),
                )}{' '}
                <span>{unit}</span>
              </S.Unit>
            </S.NutritionInfoItem>
          );
        })}

        {(OtherNutrients || []).filter(({ value }) => isNumber(value) && value > 0).length > 0 && autoCalculated && (
          <S.LabelOtherNutrients>Other nutrients</S.LabelOtherNutrients>
        )}
        {listOtherNutrients.map(item => {
          const { name, unit, key } = item;
          const resultNutrient = OtherNutrients.find(item => get(item, 'type', '') === key) || {};
          const { value } = resultNutrient;

          if (!isNumber(value) || value <= 0) return null;

          return (
            <S.NutritionInfoItem key={key}>
              <S.Name>{name}</S.Name>
              <S.Unit>
                {value} <span>{unit}</span>
              </S.Unit>
            </S.NutritionInfoItem>
          );
        })}
      </S.NutritionInfo>
    </S.NutritionInfoDetailWrapper>
  );
};

export default NutritionInfoDetail;
