import React from 'react';
import { Input } from './style';

export function isNumber(value) {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
}

export function validateHour(newHour, defaultHour, cursorPosition) {
  let hour = newHour;
  let position = cursorPosition;

  if (Number(hour[0]) > 1) {
    hour = defaultHour;
    position -= 1;
  } else if (Number(hour[0]) === 1) {
    if (Number(defaultHour[0]) === 1 && Number(hour[1]) > 2) {
      hour = `1${defaultHour[1]}`;
      position -= 1;
    } else if (Number(hour[1]) > 2) {
      hour = '12';
    }
  }

  return { value: hour, position };
}

export function validateMinute(newMinute, defaultMinute, cursorPosition) {
  let minute = newMinute;
  let position = cursorPosition;

  if (Number(minute[0]) > 5) {
    minute = defaultMinute;
    position -= 1;
  } else if (Number(minute[0]) === 5) {
    if (Number(defaultMinute[0]) === 5 && Number(minute[1]) > 9) {
      minute = `5${defaultMinute[1]}`;
      position -= 1;
    } else if (Number(minute[1]) > 9) {
      minute = '59';
    }
  }

  return { value: minute, position };
}

function onInputChange(event, oldValue) {
  const { value, selectionEnd } = event.target;
  const isTyped = value.length > oldValue.length;
  const cursorCharacter = value[selectionEnd - 1];
  const addedCharacter = isTyped ? cursorCharacter : null;
  const removedCharacter = isTyped ? null : oldValue[selectionEnd];
  const replacedSingleCharacter = value.length === oldValue.length ? oldValue[selectionEnd - 1] : null;

  let newValue = oldValue;
  let newPosition = selectionEnd;

  if (addedCharacter !== null) {
    if (selectionEnd > 2) {
      newPosition = 2;
    } else if (isNumber(addedCharacter)) {
      // user typed a number
      if (selectionEnd === 2) {
        newValue = value.substr(0, 2);
      } else {
        newValue = addedCharacter + value.substr(selectionEnd + 1);
      }
    } else {
      // if user typed NOT a number, then keep old value & selectionEnd
      newPosition = selectionEnd - 1;
    }
  } else if (replacedSingleCharacter !== null) {
    // user replaced only a single character
    if (isNumber(cursorCharacter)) {
      newValue = value.substr(0, 2);
    } else {
      // user replaced a number on some non-number character
      newValue = oldValue;
      newPosition = selectionEnd - 1;
    }
  } else if (removedCharacter !== null) {
    newValue = `${value.substr(0, selectionEnd)}0${value.substr(selectionEnd)}`;
  }

  return { newValue: `${newValue || ''}00`.substr(0, 2), newPosition };
}

export const Hour = React.forwardRef((props, ref) => (
  <Input
    {...props}
    ref={ref}
    onChange={event => {
      const { newValue, newPosition } = onInputChange(event, props.value);
      const { value, position } = validateHour(newValue, props.value, newPosition);
      typeof props.onChange === 'function' && props.onChange({ value });
      setTimeout(() => {
        event.target.selectionStart = position;
        event.target.selectionEnd = position;
      }, 0);
      event.persist();
    }}
  />
));

export const Minute = React.forwardRef((props, ref) => (
  <Input
    {...props}
    ref={ref}
    onChange={event => {
      const { newValue, newPosition } = onInputChange(event, props.value);
      const { value, position } = validateMinute(newValue, props.value, newPosition);
      typeof props.onChange === 'function' && props.onChange({ value });
      setTimeout(() => {
        event.target.selectionStart = position;
        event.target.selectionEnd = position;
      }, 0);
      event.persist();
    }}
  />
));
