import React, { useEffect, useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { getUserShortName, limitText } from 'utils/commonFunction';
import { getAllClientsToSelect, getClientsByParams } from 'redux/broadcast-messages/actions';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import Filter from './Filter';
import { FILTER_STATUS } from '../constants';
import { Checkbox } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import LoadingIndicator from './LoadingIndicator';

import { ReactComponent as BroadcastIcon } from 'assets/icons/broadcast_icon.svg';
import { ReactComponent as AddMore } from 'assets/icons/add_black.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_gray_out.svg';
import { ReactComponent as NoClientsResultIcon } from 'assets/icons/not_found.svg';

import * as S from './style';

const PER_PAGE = 20;
const SHORT_SCREEN_HEIGHT = 768;

const BroadcastMessageLeftSide = props => {
  const {
    push,
    total,
    clients,
    onClose,
    clientsLoading,
    onSearchClient,
    getClientsByParams,
    isAllClientsLoading,
    selectedClientsProp,
    getAllClientsToSelect,
    onChangeSelectedFilter,
    onChangeSelectedClients,
  } = props;
  const initStateFilter = {
    status: FILTER_STATUS.connected.value,
    owner_ship: 1,
    groups: [],
  };

  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [allClient, setAllClient] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(initStateFilter);
  const [textSearch, setTextSearch] = useState('');
  const [page, setPage] = useState(1);
  const [windowHeight, setWindowHeight] = useState(getWindowSize());

  const [clientByStatus, setClientByStatus] = useState([]);

  const getCurrentState = () => {
    return {
      ...selectedFilter,
      groups: selectedFilter.groups.map(item => get(item, '_id', '')),
      page,
      per_page: PER_PAGE,
      text_search: textSearch,
    };
  };

  useEffect(() => {
    const params = getCurrentState();

    getClientsByParams(params);
  }, []);

  useEffect(() => {
    getClient();
  }, [textSearch]);

  useEffect(() => {
    const isSelectedAll =
      !isEmpty(selectedClients) &&
      !isEmpty(clients) &&
      clients.length === total &&
      clients.every(item => {
        return selectedClients.some(client => client._id === item._id);
      });
    isSelectedAll ? setSelectedAll(true) : setSelectedAll(false);

    if (!isEmpty(allClient) && !isSelectedAll) {
      if (!isAllClientsLoading) {
        const checkedAll = clientByStatus.every(item => {
          return selectedClients.some(client => client._id === item._id);
        });
        checkedAll && setSelectedAll(true);
      }
    }
  }, [selectedClients, clients, textSearch, allClient, clientByStatus]);

  const onSelectedClient = client => {
    if (selectedClients.some(item => item._id === client._id)) {
      setSelectedAll(false);
      setSelectedClients(selectedClients.filter(item => item._id !== client._id));
      removeEnableScroll();
    } else {
      addEnableScroll();
      setSelectedClients([...selectedClients, client]);
    }
  };

  const addEnableScroll = () => {
    const element = document.querySelector('.bottom-selected-clients');
    if (element) {
      const { classList } = element;
      if (!classList.contains('enable-scroll')) classList.add('enable-scroll');
    }
  };

  const removeEnableScroll = () => {
    const element = document.querySelector('.enable-scroll');
    if (element) {
      element.classList.remove('enable-scroll');
    }
  };

  const onSelectedAllClient = () => {
    const params = getCurrentState();
    addEnableScroll();

    if (!selectedAll) {
      getAllClientsToSelect({ ...params, per_page: 0 }, allList => {
        const newSelectedClients = selectedClients.filter(item => {
          return !allList.some(client => client._id === item._id);
        });
        setSelectedClients([...newSelectedClients, ...allList]);
        setAllClient(allList);
        setSelectedAll(true);
      });
    } else {
      getAllClientsToSelect({ ...params, per_page: 0 }, allList => {
        const uncheckedClients = selectedClients.filter(item => {
          return !allList.some(client => client._id === item._id);
        });
        setSelectedClients(uncheckedClients);
        setAllClient(allList);
        setSelectedAll(false);
      });
    }
  };

  useEffect(() => {
    onChangeSelectedClients && onChangeSelectedClients(selectedClients);
  }, [selectedClients]);

  useEffect(() => {
    setSelectedClients(selectedClientsProp);
  }, [selectedClientsProp]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerHeight = 0 } = window;
    return innerHeight;
  }

  const debounceSearch = debounce(text => {
    const trimmedText = text.trim();
    setPage(1);
    setTextSearch(trimmedText);
    onSearchClient(trimmedText);

    const params = {
      ...selectedFilter,
      groups: selectedFilter.groups.map(item => get(item, '_id', '')),
      page: 1,
      per_page: PER_PAGE,
      text_search: trimmedText,
    };
    getClientsByParams(params);
    handleScrollClientList();
  }, 500);

  const handleScrollEnd = event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    const isLastPage = page >= Math.ceil(total / PER_PAGE);
    if (bottom && !clientsLoading && !isLastPage) {
      const newPage = page + 1;

      setPage(newPage);
      const params = {
        page: newPage,
        per_page: PER_PAGE,
        text_search: textSearch,
        ...selectedFilter,
        groups: selectedFilter.groups.map(item => get(item, '_id', '')),
      };
      getClientsByParams(params);
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const isShowAddNewClient = useMemo(() => {
    return (
      !total &&
      isEmpty(selectedFilter.groups) &&
      selectedFilter.owner_ship === 1 &&
      !textSearch &&
      selectedFilter.status === FILTER_STATUS.connected.value
    );
  }, [total, selectedFilter, textSearch]);

  const handleClickAddNewClient = () => {
    onClose && onClose();
    push && push('/home/client');
  };

  const handleScrollClientList = () => {
    const scrollTop = document.querySelector('.client-list-scroll-enabled');
    if (scrollTop) {
      scrollTop.scroll({ top: 0 });
    }
  };

  const handleClearSearch = () => {
    setTextSearch('');
    onSearchClient('');
    setPage(1);

    const params = {
      page: 1,
      per_page: PER_PAGE,
      text_search: '',
      ...selectedFilter,
      groups: selectedFilter.groups.map(item => get(item, '_id', '')),
    };
    getClientsByParams(params);
    handleScrollClientList();
  };

  const handleChangeSelectFilter = filter => {
    const groupIds = get(filter, 'groups', []).map(group => get(group, '_id', ''));

    const filterOptions = {
      status: get(filter, 'status', 'connected'),
      owner_ship: get(filter, 'owner_ship', 1),
      groups: get(filter, 'groups', []),
    };

    onChangeSelectedFilter(filterOptions);
    setPage(1);
    setSelectedFilter(filterOptions);
    const params = {
      page: 1,
      per_page: PER_PAGE,
      status: get(filter, 'status', 'connected'),
      owner_ship: get(filter, 'owner_ship', 1),
      groups: groupIds,
      text_search: textSearch,
    };
    getClientsByParams(params);
    handleScrollClientList();
  };

  const getClient = () => {
    const params = getCurrentState();
    getAllClientsToSelect &&
      getAllClientsToSelect({ ...params, per_page: 0 }).then(res => {
        setClientByStatus(get(res, 'data.data.list', []));
      });
  };

  return (
    <S.Wrapper>
      <S.HeaderLeftBar>
        <S.HeaderTitle>
          <BroadcastIcon className="broadcast-icon" />
          <S.BroadcastTitle>Broadcast Message</S.BroadcastTitle>
          <InfoIcon data-tip data-for="info-tooltip" className="info-icon" />
          <ReactTooltip
            id="info-tooltip"
            effect="solid"
            place={windowHeight < SHORT_SCREEN_HEIGHT ? 'bottom' : 'top'}
            className="info-tooltip"
          >
            You can send up to 3 messages to different clients individually each time you broadcast.
          </ReactTooltip>
        </S.HeaderTitle>
      </S.HeaderLeftBar>
      <S.FilterWrapper>
        <S.SearchContainer>
          <NewSearchInput
            onChange={(event, { value }) => {
              debounceSearch(value);
            }}
            onClearSearch={() => handleClearSearch()}
            placeholder="Search clients"
          />
          <Filter
            currentSelectedFilter={selectedFilter}
            onSelectedFilterChange={filter => handleChangeSelectFilter(filter)}
          />
        </S.SearchContainer>
      </S.FilterWrapper>
      <S.ClientListWrapper>
        <S.ContentListWrapper>
          <S.HeaderList>
            <S.ClientListTitle>
              {textSearch
                ? 'results'
                : selectedFilter.status
                ? FILTER_STATUS[selectedFilter.status].listName
                : FILTER_STATUS['all'].listName}{' '}
              ({total})
            </S.ClientListTitle>
            {!isEmpty(clients) && (
              <S.SelectedAllAction>
                <div className="all-text">All</div>
                <Checkbox
                  checked={selectedAll}
                  onChange={() => onSelectedAllClient(isAllClientsLoading)}
                  size={20}
                  className="checkbox-icon"
                />
              </S.SelectedAllAction>
            )}
          </S.HeaderList>
          <S.ContentList
            className="client-list-scroll-enabled"
            onScroll={event => {
              event.persist();
              onScrollDebounce.call(null, event);
            }}
          >
            {clientsLoading && page === 1 && <LoadingIndicator />}
            {isEmpty(clients) && !clientsLoading ? (
              <S.NotFoundWrapper>
                <S.NotFoundIcon>
                  <NoClientsResultIcon className="no-clients-result-icon" />
                </S.NotFoundIcon>
                {isShowAddNewClient ? (
                  <S.CreateNewClientBtn onClick={handleClickAddNewClient}>
                    <AddMore className="add-new-client-icon" />
                    Add Your First Client
                  </S.CreateNewClientBtn>
                ) : (
                  <S.NotFoundLabel>{textSearch ? 'No results found' : 'No clients found'}</S.NotFoundLabel>
                )}
              </S.NotFoundWrapper>
            ) : (
              clients.map(client => {
                const isChecked = selectedClients.some(item => item._id === client._id);
                return (
                  <S.ClientItemWrapper
                    key={client._id}
                    checked={isChecked}
                    onClick={event => {
                      event.preventDefault();
                      onSelectedClient(client);
                    }}
                  >
                    <S.ClientInfo>
                      <Avatar
                        name={getUserShortName(client)}
                        className="client-detail-avatar"
                        size="34"
                        src={client.avatar ? client.avatar : ''}
                        color={client.color}
                      />
                      <S.ClientName>{limitText(client.full_name, 29)}</S.ClientName>
                    </S.ClientInfo>
                    <Checkbox
                      checked={isChecked}
                      onChange={event => {
                        event.preventDefault();
                        onSelectedClient(client);
                      }}
                      size={20}
                      className="checkbox-client-icon"
                    />
                  </S.ClientItemWrapper>
                );
              })
            )}
            {clientsLoading && page > 1 && <LoadingIndicator />}
          </S.ContentList>
        </S.ContentListWrapper>
      </S.ClientListWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { broadcastMessages } = state.rootReducer;
  const { clients = [], total = 0, clientsLoading = false, isAllClientsLoading = false } = broadcastMessages;

  return {
    clients: clients,
    total: total,
    clientsLoading: clientsLoading,
    isAllClientsLoading: isAllClientsLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getClientsByParams: bindActionCreators(getClientsByParams, dispatch),
  getAllClientsToSelect: bindActionCreators(getAllClientsToSelect, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastMessageLeftSide);
