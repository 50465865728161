import { connect } from 'react-redux';
import TrainingOverview from './component';
import { getDashboardInfo } from 'actions/client';

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    selectedClient: rootReducer.client.workingClientDetail,
    bodymetricTypes: rootReducer.client.bodymetricTypes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getDashboardInfo: data => {
      return dispatch(getDashboardInfo(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingOverview);
