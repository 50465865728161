import React from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import { push } from 'connected-react-router';

import { hideError } from 'actions/error';
import { selectSegment } from 'redux/segment/actions';
import { DEFAULT_CLIENT_FILTERS } from 'constants/commonData';
import './styles.scss';

export default class ErrorPopup extends React.Component {
  handleClose = () => {
    const { dispatch, callback, content, user } = this.props;
    if (typeof callback === 'function') {
      callback();
    } else if (content === 'Studio collection not found') {
      dispatch(push(`/home/studio-collection`));
      window.location.reload();
    } else if ((content || '').includes('Client with id')) {
      dispatch(selectSegment(null, Object.assign({}, DEFAULT_CLIENT_FILTERS, { ownerShip: user._id })));
      dispatch(hideError());
      dispatch(push(`/home/client`));
    } else {
      dispatch(hideError());
    }
  };

  render() {
    const { isShowingError, headerIcon, title, callback, content, buttonClass, close_button } = this.props;
    return (
      <Modal open={isShowingError} className="error-popup-container">
        <Modal.Content className="error-content">
          <div className="error-content-header">
            {headerIcon && <Image src={headerIcon} className="error-header-image" />}
            <p className="error-header-label">{title || 'Error'}</p>
          </div>
          <div className="error-content-body">
            <p className={`error-content-label ${callback && 'custom-label'}`}>{content}</p>
          </div>
        </Modal.Content>
        <Modal.Actions className={`error-actions ${callback && 'custom-action'}`}>
          <Button
            className={buttonClass ? 'error-close-button ' + buttonClass : 'error-close-button'}
            onClick={this.handleClose}
          >
            {close_button || 'Close'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
