import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-right: 30px;
  .hightlight {
    display: inline-block;
    border: 1px solid #7f7aff;
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    color: #7f7aff;
    padding: 0 3px;
  }
`;

export const MultiplePasteMessage = ({ title }) => (
  <Container>
    <span>
      Hold <span className="hightlight">Shift</span> to paste multiple {title || 'workouts'}
    </span>
  </Container>
);

const LiveSyncMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;

  .title {
    font-weight: 600;
  }
`;

export const LiveSyncEnableMessage = () => (
  <LiveSyncMessage>
    <span className="title">Live Sync Enabled.</span> <span>This may take a few minutes.</span>
  </LiveSyncMessage>
);

const FileSizeContainer = styled.div`
  margin-right: 30px;
`;

export const FileSizeMessage = ({ size }) => (
  <FileSizeContainer>{`The file must be less than ${size}`}</FileSizeContainer>
);
