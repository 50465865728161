import React, { useState } from 'react';
import { TaskContent, TaskTitle, TaskDate, TaskDetail } from '../styles';
import { Checkbox } from 'shared/FormControl';
import { COLOR_OPTIONS } from '../constants';
import { CDN_URL } from 'constants/commonData';
import { Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import moment from 'moment';
import Avatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';

import ReactTooltip from 'react-tooltip';

export const TaskItem = ({
  currentList,
  doUnmarkAsCompleted,
  doMarkAsCompleted,
  setTaskId,
  toTaskDetail,
  task,
  index,
}) => {
  const [isCompleted, setComplete] = useState(task.is_completed);
  const [fade, setFade] = useState(false);
  const correctColor = color => {
    if (color === '#fff') return color;
    return COLOR_OPTIONS.filter(e => e.color === color)[0].colorId;
  };
  const handleToTaskDetails = e => {
    setTaskId(task._id);
    toTaskDetail(task._id);
  };
  const onMarkasCompleted = e => {
    e.stopPropagation();
    setComplete(e.target.checked);
    setFade(!fade);
    if (isCompleted) {
      setTimeout(doUnmarkAsCompleted(task._id), 300);
    } else {
      setTimeout(doMarkAsCompleted(task._id), 300);
    }
  };
  const color = task.color || '#fff';

  const DateBlock = ({ date }) => {
    const selectedDate = moment(date, 'MM DD YYYY');
    const selectedYear = selectedDate.year();
    const currentYear = moment().year();
    if (selectedYear == currentYear) {
      return selectedDate.format('MMM DD');
    } else {
      return selectedDate.format('MMM DD YYYY');
    }
  };
  return (
    <Draggable draggableId={task._id} index={index}>
      {(provided, snapshot) => (
        <TaskContent
          isDragging={snapshot.isDragging}
          className={fade ? 'active' : ''}
          theme={{ color: correctColor(color) }}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="left_content">
            <div
              className="check_box"
              data-tip={`tooltip--mark-as-finish-${task._id}`}
              data-for={`tooltip--mark-as-finish-${task._id}`}
            >
              <Checkbox size={'22'} checked={isCompleted} onChange={onMarkasCompleted} circle />
            </div>
            <ReactTooltip
              delayShow={300}
              className="app-tooltip-mark"
              id={`tooltip--mark-as-finish-${task._id}`}
              effect="solid"
              place="top"
            >
              <span> {isCompleted ? 'Uncheck task' : 'Mark as complete'} </span>
            </ReactTooltip>
          </div>
          <div className={isCompleted ? 'completed right_content' : 'right_content'}>
            <TaskTitle className={isCompleted ? 'completed' : ''} onClick={handleToTaskDetails}>
              {' '}
              {task.title}{' '}
            </TaskTitle>
            {task.details && <TaskDetail>{task.details}</TaskDetail>}
            {task.date && task.date.date && (
              <TaskDate>
                <i className="clock outline icon"></i>
                <div className="date_time">
                  <DateBlock date={task.date.date} />
                  {task.date.time && ` - ${task.date.time}`}
                </div>
                {task.is_due && <div className="badge"> Due </div>}
              </TaskDate>
            )}
            {!_.isEmpty(task.assignees) && (
              <div className="program-calendar_clients">
                {_.map(task.assignees, item => (
                  <div className="avatar-container">
                    <Avatar
                      name={getUserShortName(item)}
                      className="client-avatar"
                      color={item.color}
                      size="21"
                      src={item.avatar}
                    />
                    <ReactTooltip
                      className="app-tooltip-white program-calendar__tooltip"
                      id={`client-tooltip-${item._id}`}
                      effect="solid"
                      place={'top'}
                      delayShow={500}
                    >
                      <span>{`${item.first_name} ${item.last_name}`}</span>
                    </ReactTooltip>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={'drag_drop_icon'}>
            <div>
              <img {...provided.dragHandleProps} src={`${CDN_URL}/images/humburger.svg`} alt="humburger" />
            </div>
          </div>
        </TaskContent>
      )}
    </Draggable>
  );
};
