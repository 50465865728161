import React from 'react';

import { ReactComponent as CountdownEmptyBanner } from 'assets/icons/goal-empty-banner.svg';
import { CountdownEmptyStyled } from './styles.js';

const EmptyList = ({ onClickAddNew }) => {
  return (
    <CountdownEmptyStyled>
      <CountdownEmptyBanner className="banner" />
      <div className="description">This client has not set any countdowns yet.</div>
      <button className="create-btn" onClick={onClickAddNew}>
        Add countdown
      </button>
    </CountdownEmptyStyled>
  );
};

export default EmptyList;
