import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';

import * as S from './style';

const CardThumbnail = ({ resource, showType, children, cloudfrontList }) => {
  return (
    <S.CardPhoto coverImage={convertS3UrlToCloudFrontUrl(resource.background, cloudfrontList, true)}>
      <div>{children}</div>
      <div className="overlay"></div>
    </S.CardPhoto>
  );
};
const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState, mapDispatchToProps)(CardThumbnail);
