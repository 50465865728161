import React, { useState, useRef, useEffect, useMemo } from 'react';
import find from 'lodash/find';
import moment from 'moment';
import Avatar from 'react-avatar';

import { Header, Body, Container, Gallery, Like, Caption, GalleryItem, ShowMoreText, AvatarContainer } from './style';
import { clockIcon } from 'constants/images';
import { convertS3UrlToCloudFrontUrl, getUserShortName, likeSummary } from 'utils/commonFunction';
import Interactions from './Interations';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { HEIC_IMAGE_TYPES } from 'constants/commonData';

export default function (props) {
  const { data, userId, likePost, root, onMediaClick, onCommentClick, cloudfrontList } = props;

  if (!data) {
    return null;
  }

  const { date, caption, _id, total_comment, pictures = [], pictures_thumbnail = [], liker = [] } = data || {};

  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowButtonMore, setIsShowButtonMore] = useState(false);
  const captionRef = useRef(null);

  const { renderedPictures, more, likeSum, liked, galleryNumber } = useMemo(() => {
    const renderedPictures = (pictures_thumbnail.length ? pictures_thumbnail : pictures || [])
      .slice(0, 4)
      .map(image => {
        if (HEIC_IMAGE_TYPES.some(type => image.includes(type))) {
          return image.replace('thumbnails-converted', 'images');
        }
        return image;
      });

    const more = pictures.length - renderedPictures.length;
    const likeSum = likeSummary(userId, liker);
    const liked = find(liker, item => item._id === userId);
    const galleryNumber = Math.min(4, renderedPictures.length);

    return { renderedPictures, more, likeSum, liked, galleryNumber };
  }, [pictures, pictures_thumbnail, liker, userId]);

  useEffect(() => {
    const textLength = caption.length;
    const currentHeight = captionRef.current ? captionRef.current.clientHeight : 0;
    const checkShowButtonMore = textLength > 333 || currentHeight > 80;
    if (checkShowButtonMore) {
      setIsShowButtonMore(true);
      setIsShowMore(true);
    }
  }, [caption.length]);

  const toggleReadMore = () => setIsShowMore(show => !show);

  const handleClickGalleryItem = ({ index, image }) => event => {
    if (typeof onMediaClick === 'function' && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      onMediaClick({ mediaIndex: index, data: image }, 'picture');
    }
  };

  const renderAvatars = users => {
    return (
      <AvatarContainer twoAvatars={users.length >= 2}>
        {users.slice(0, 2).map(user => (
          <Avatar
            name={getUserShortName(user)}
            className="avatar"
            size="20"
            src={user ? convertS3UrlToCloudFrontUrl(user.avatar, cloudfrontList, true) : ''}
          />
        ))}
      </AvatarContainer>
    );
  };

  const renderGalleryItem = (image, index) => {
    return (
      <GalleryItem
        key={index}
        more={index === 3 ? more : 0}
        className="gallery__item"
        href={`${root}?startAt=${index}`}
        onClick={handleClickGalleryItem({ image, index })}
      >
        <S3ImageWithFallback src={[image]} />
      </GalleryItem>
    );
  };

  return (
    <Container className="post">
      <Header>
        <div className="created-at">
          {clockIcon}
          <span>{moment.utc(date).format('h:mm A')}</span>
        </div>
      </Header>
      <Body>
        {!!caption && (
          <Caption showMore={isShowMore} ref={captionRef} className="caption">
            {caption}
          </Caption>
        )}
        {!!caption && isShowButtonMore && (
          <ShowMoreText onClick={toggleReadMore}>{isShowMore ? '... See more' : 'See less'}</ShowMoreText>
        )}
        {!!renderedPictures.length && <Gallery num={galleryNumber}>{renderedPictures.map(renderGalleryItem)}</Gallery>}
        {!!likeSum && (
          <Like>
            {renderAvatars(liker)}
            Liked by&nbsp; <span className="name">{likeSum}</span>
          </Like>
        )}
      </Body>
      <Interactions
        like={!!liked}
        onLikeClick={() => likePost(_id)}
        onCommentClick={() => (typeof onCommentClick === 'function' ? onCommentClick() : null)}
        comments={total_comment}
      />
    </Container>
  );
}
