import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { push } from 'connected-react-router';
import {
  changeQueryParams,
  hideExercise,
  removeExercise,
  updateExerciseSharingStatus,
  updateSelectedExercise,
} from 'redux/exercise/exercise.actionCreators';
import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import * as Layout from 'shared/LibraryLayout';
import {
  TEAM_SHARE_SHARED,
  TEAM_SHARE_NOOWNER,
  TEAM_SHARE_PRIVATE,
  MEDIA_PLACEHOLDER,
  CDN_URL,
} from 'constants/commonData';
import { timeSince, isTeamAdmin } from 'utils/commonFunction';
import { isS3FileURL } from 'utils/validations';
import SharingStatus from 'shared/SharingStatus';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import OwnerAvatar from 'shared/OwnerAvatar';
import { CheckBoxContainer, ExerciseNameContainer, TagContainer, Thumbnail, ThumbnailContainer } from '../style';
import { Checkbox } from 'shared/FormControl';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

function Component(props) {
  const { list, user, selectedExercise } = props;
  const isAdmin = isTeamAdmin(user);
  const hasTeam = !!user.team_member && user.team_member.length > 1;

  if (!list.length) {
    return null;
  }

  const editExercise = id => {
    props.push(`/home/exercise/${id}`);
  };

  const renderCustom = exercise => {
    const { custom, _id } = exercise;
    const tooltipId = `tooltip--exercise-system__${_id}`;
    let text = 'Custom Exercise',
      icon = <img src={`${CDN_URL}/images/check_icon.png`} width="19" data-tip data-for={tooltipId} alt="" />;

    if (!custom) {
      text = 'System Exercise';
      icon = <img src={`${CDN_URL}/images/lib_icon_everfit.svg`} data-tip data-for={tooltipId} alt="" />;
    }

    return (
      <>
        {icon}
        <ReactTooltip className="app-tooltip" place="top" id={tooltipId} effect="solid">
          <span>{text}</span>
        </ReactTooltip>
      </>
    );
  };

  const toggleSharingStatus = exercise => {
    const { author, share, custom } = exercise;

    const isOwner = author === user._id;
    const canSwitch = custom && author && share !== TEAM_SHARE_NOOWNER && (isOwner || isAdmin);

    if (canSwitch) {
      const newSharingStatus = share === TEAM_SHARE_PRIVATE ? TEAM_SHARE_SHARED : TEAM_SHARE_PRIVATE;
      props.updateExerciseSharingStatus({ _id: exercise._id, share: newSharingStatus }, isOwner);
    } else {
      editExercise(exercise._id);
    }
  };

  const renderActions = exercise => {
    const { author, custom } = exercise;
    const canDelete = custom && author === user._id;

    return (
      <DropDown
        triggerIcon={({ open }) => <MenuTrigger vertical active={!!open} itemId={_.get(exercise, '_id', '')} />}
      >
        <Option onClick={() => editExercise(exercise._id)} key="edit">
          <div className="icon">
            <img src={`${CDN_URL}/images/edit-white.svg`} alt="" />
          </div>
          <span>Edit</span>
        </Option>
        <Option key="duplicate" onClick={() => props.push(`/home/exercise/${exercise._id}/clone`)}>
          <div className="icon">
            <img src={`${CDN_URL}/images/clone.svg`} alt="" />
          </div>
          <span>Duplicate</span>
        </Option>
        {canDelete ? (
          <Option
            key="delete"
            onClick={() => {
              props.toggleConfirmModal(
                true,
                <ConfirmModal
                  title="Remove Exercise"
                  content="Are you sure that you want to delete this exercise?"
                  onConfirm={() => props.removeExercise(exercise._id)}
                />,
              );
            }}
          >
            <div className="icon">
              <img src={`${CDN_URL}/images/delete.svg`} alt="" />
            </div>
            <span>Delete</span>
          </Option>
        ) : (
          <Option
            key="hide"
            onClick={() => {
              props.toggleConfirmModal(
                true,
                <ConfirmModal
                  title="Hide Exercise"
                  content="Are you sure that you want to hide this exercise?"
                  onConfirm={() => props.hideExercise(exercise._id)}
                />,
              );
            }}
          >
            <div className="icon">
              <img src={`${CDN_URL}/images/delete.svg`} alt="" />
            </div>
            <span>Hide</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const selectExercise = (exercise, checked) => {
    let selectedList = [];

    if (checked) {
      props.updateSelectedExercise([...props.selectedExercise, exercise._id]);
      selectedList = [...props.selectedExercise, exercise._id];
    } else {
      props.updateSelectedExercise(_.filter(props.selectedExercise, id => id !== exercise._id));
      selectedList = _.filter(props.selectedExercise, id => id !== exercise._id);
    }

    props.onCountTagBySelected(_.filter(list, item => selectedList.includes(item._id)));
  };

  const handleSelectTag = (e, tag) => {
    e.stopPropagation();
    props.changeQueryParams({ tags: [tag] });
  };

  const renderTagsColumn = exercise => {
    const tags = _.get(exercise, 'tags', []);
    if (!tags.length) return <span>--</span>;

    return _.map(tags, tag => {
      return tag ? (
        <React.Fragment key={tag._id}>
          <span className="tag-name" onClick={e => handleSelectTag(e, tag)}>
            {tag.name}
          </span>
        </React.Fragment>
      ) : null;
    });
  };

  const renderExerciseModality = modality => {
    const { title } = modality || {};

    return <span>{title || '--'}</span>;
  };

  const renderExerciseMuscleGroups = (muscle_groups = []) => {
    const primaryMuscleGroup = muscle_groups.find(item => item.is_primary);
    const titleMuscle = _.get(primaryMuscleGroup, 'muscle_group.title', '--');

    return <span>{titleMuscle}</span>;
  };

  const renderExerciseMovementPatterns = (movement_patterns = []) => {
    const primaryMovementPattern = movement_patterns.find(item => item.is_primary);
    const titleMovement = _.get(primaryMovementPattern, 'movement_pattern.title', '--');

    return <span>{titleMovement}</span>;
  };

  return (
    <Layout.TableBody>
      {_.map(list, exercise => {
        const {
          title,
          category_type_name,
          last_interacted,
          custom,
          modality,
          muscle_groups,
          movement_patterns,
        } = exercise;
        const thumb = exercise.preview_300 || (exercise.picture || [])[0];
        const isVideo = !!exercise.video || !!exercise.videoLink;
        const author = hasTeam ? _.find(user.team_member, item => item._id === exercise.author) : null;
        const share = !custom || exercise.share === TEAM_SHARE_SHARED || exercise.share === TEAM_SHARE_NOOWNER;
        const isChecked = selectedExercise.includes(exercise._id);
        const isOwner = exercise.share !== TEAM_SHARE_NOOWNER;

        return (
          <Layout.TableRow key={exercise._id} onClick={() => editExercise(exercise._id)}>
            <Layout.TableCell key="title" className="title body-fixed">
              <ExerciseNameContainer>
                <CheckBoxContainer onClick={e => e.stopPropagation()} className={isChecked ? 'checked' : null}>
                  <div className="checkbox__thumbnail">
                    <Checkbox
                      checked={isChecked}
                      className="exerciseCheckbox"
                      onChange={event => {
                        event.stopPropagation();
                        selectExercise(exercise, event.target.checked);
                      }}
                    />
                  </div>
                  <ThumbnailContainer isVideo={isVideo} className="thumbnail-container">
                    {isS3FileURL(thumb) ? (
                      <S3ImageWithFallback
                        src={[thumb]}
                        defaultImage={`${CDN_URL}/images/exercise_grey.svg`}
                        cover
                        fluid
                        showLoader
                      />
                    ) : (
                      <Thumbnail thumbnail={isVideo ? thumb || MEDIA_PLACEHOLDER : thumb} />
                    )}
                  </ThumbnailContainer>
                </CheckBoxContainer>
                <div className="text">{title}</div>
              </ExerciseNameContainer>
            </Layout.TableCell>
            <Layout.TableCell key="tags">
              <TagContainer>{renderTagsColumn(exercise)}</TagContainer>
            </Layout.TableCell>
            <Layout.TableCell key="modality">{renderExerciseModality(modality)}</Layout.TableCell>
            <Layout.TableCell key="muscle-groups">{renderExerciseMuscleGroups(muscle_groups)}</Layout.TableCell>
            <Layout.TableCell key="movement-patterns">
              {renderExerciseMovementPatterns(movement_patterns)}
            </Layout.TableCell>
            <Layout.TableCell key="category">{category_type_name}</Layout.TableCell>
            <Layout.TableCell key="most-recent" alignCenter>
              {last_interacted ? timeSince(new Date(last_interacted)) : '--'}
            </Layout.TableCell>
            <Layout.TableCell key="custom" alignCenter>
              {renderCustom(exercise)}
            </Layout.TableCell>
            <Layout.TableCell key="actions">
              <Layout.Actions>
                {hasTeam ? (
                  <>
                    {author && custom && isOwner ? (
                      <OwnerAvatar
                        data={author}
                        tooltipId={`tooltip--owner-${exercise._id}`}
                        size={25}
                        className="owner-avatar"
                      />
                    ) : null}
                    <SharingStatus
                      place="top"
                      share={share}
                      itemId={exercise._id}
                      onClick={event => {
                        event.stopPropagation();
                        toggleSharingStatus(exercise);
                      }}
                    />
                  </>
                ) : null}
                {renderActions(exercise)}
              </Layout.Actions>
            </Layout.TableCell>
          </Layout.TableRow>
        );
      })}
    </Layout.TableBody>
  );
}

const TableBody = React.memo(Component);

const mapState = state => {
  const {
    user,
    rootReducer: {
      exercise: { listExercise, selectedExercise },
    },
  } = state;

  return { list: listExercise, selectedExercise, user };
};

const mapDispatch = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    updateExerciseSharingStatus: bindActionCreators(updateExerciseSharingStatus, dispatch),
    removeExercise: bindActionCreators(removeExercise, dispatch),
    hideExercise: bindActionCreators(hideExercise, dispatch),
    updateSelectedExercise: bindActionCreators(updateSelectedExercise, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableBody);
