import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const CalendarIcon = styled.div`
  width: 16px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url(${CDN_URL}/images/calendar_grey.svg);
`;

export const Arrow = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 7px 12px;
  background-position: center;
  cursor: pointer;

  ${props =>
    props.left &&
    css`
      background-image: url(${CDN_URL}/images/left_arrow_grey.svg);
      :hover {
        background-image: url(${CDN_URL}/images/left_arrow_purple.svg);
      }
    `}

  ${props =>
    props.right &&
    css`
      background-image: url(${CDN_URL}/images/right_arrow_grey.svg);
      :hover {
        background-image: url(${CDN_URL}/images/right_arrow_purple.svg);
      }
    `}
`;

export const Container = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-weight: 600;

  > button {
    background: none !important;
  }

  .rdt div.form-control[type='text'] {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #273d52;
    padding: 0 2px;
    cursor: pointer;
    :hover {
      color: #745ee6;
      ${CalendarIcon} {
        background-image: url(${CDN_URL}/images/calendar_purple.svg);
      }
    }
  }

  .rdtPicker {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    padding: 12px 12px 8px;
    width: auto;
    min-width: 236px;
    font-family: 'Open Sans';
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
    border: solid 1px #e5eced;
    background-color: #fff;

    table {
      border-collapse: collapse;

      thead {
        tr:first-child {
          th {
            font-size: 13px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #273d52;
            border: none;
            height: auto;
            padding-bottom: 6px;

            :hover {
              background: transparent;
              color: #745ee6;
            }

            &.rdtPrev,
            &.rdtNext {
              width: 30px;
              span {
                text-indent: -9999px;
                display: block;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
              }
            }
            &.rdtPrev {
              span {
                background-image: url(${CDN_URL}/images/left_arrow_grey.svg);
              }
              :hover {
                span {
                  background-image: url(${CDN_URL}/images/left_arrow_purple.svg);
                }
              }
            }

            &.rdtNext {
              span {
                background-image: url(${CDN_URL}/images/right_arrow_grey.svg);
              }

              :hover {
                span {
                  background-image: url(${CDN_URL}/images/right_arrow_purple.svg);
                }
              }
            }
          }
        }
      }

      /* tbody {
        tr {
          height: 28px;
        }
      } */
    }

    .dow {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      color: #99a1b1;
    }

    .rdtDay {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5a636c;
      width: auto;
      height: auto;
      padding: 2px 0;
      background-color: #fff;
      &:hover {
        background-color: #fff;
        .content {
          > div {
            background-color: #eee;
          }
        }
      }

      &.rdtOld {
        color: #afb5bd;
      }

      .content {
        padding: 0 4px;
        > div {
          width: 26px;
          height: 26px;
          text-align: center;
          line-height: 26px;
          border-radius: 50%;
        }
      }

      &.inRange {
        .content {
          background-color: rgba(114, 110, 228, 0.15);
        }
        :hover {
          .content {
            > div {
              background-color: #d8dadc;
            }
          }
        }
      }

      &.rdtToday {
        ::before {
          display: none;
        }
        .content {
          > div {
            background-color: rgba(114, 110, 228);
            color: #fff;
          }
        }
        :hover {
          .content {
            > div {
              color: #5a636c;
              background-color: #eee;
            }
          }
        }
        &.inRange {
          :hover {
            .content {
              > div {
                background-color: #d8dadc;
              }
            }
          }
        }
      }

      &.rangeEnd {
        .content {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      &.rangeStart {
        .content {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }
    }
  }
`;
