import styled, { css } from 'styled-components';

export const TabBarItem = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #202353;
  padding: 5px 12px;
  font-weight: normal;
  white-space: nowrap;

  ${props =>
    props.active
      ? css`
          background: #5158cf;
          box-shadow: 0px 2px 4px rgba(38, 19, 152, 0.2);
          border-radius: 3px;
          font-weight: 600;
          color: #ffffff;

          :hover {
            color: #ffffff;
            cursor: pointer;
          }
        `
      : css`
          :hover {
            color: #5158cf;
            cursor: pointer;
          }
        `}

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      &:hover {
        color: #202353;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const Group = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const PurchaseGroup = styled(Group)`
  position: relative;

  ${TabBarItem} {
    margin-right: 15px;
  }

  :after {
    content: '';
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: 1px;
    height: 24px;
    background: #dadfea;
  }
`;

export const InvoiceGroup = styled(Group)`
  ${TabBarItem} {
    margin-right: 5px;
  }
`;

export const LeftContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const RightContent = styled.div`
  flex: 0 0 236px;
  margin-left: 8px;

  .search-input {
    font-size: 12px;
    line-height: 16px;
    width: 100%;

    input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

export const Anchor = styled.div`
  position: absolute;
  height: 40px;
  width: 60px;
  z-index: 1;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${props =>
    props.left &&
    css`
      background: linear-gradient(90deg, #ffffff 35.83%, rgba(255, 255, 255, 0) 100%);
      left: 0;
      right: unset;
      justify-content: flex-start;
    `}

  ${props =>
    props.right &&
    css`
      background: linear-gradient(270deg, #ffffff 35.83%, rgba(255, 255, 255, 0) 100%);
      left: unset;
      right: 0;
      justify-content: flex-end;
    `}

    ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}

  svg {
    cursor: pointer;
    pointer-events: all;

    &:hover {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const WrapperHorizontalScroll = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
