import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as S from './style';
import { onlyInsertPlainText } from 'utils/event-api';
import { setCaretToEnd } from 'utils/commonFunction';

class TextEditable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.inputRef = React.createRef();
    this.containerRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.resetInput) {
      return (
        (nextProps.value || '').trim() !== this.inputRef.current.innerText.trim() ||
        (this.props.notRequired ? nextProps.value === '' : false) ||
        nextState.active !== this.state.active ||
        nextProps.invalid !== this.props.invalid ||
        nextProps.resetInput !== this.props.resetInput ||
        nextProps.readOnly !== this.props.readOnly
      );
    }
    return (
      (nextProps.value || '').trim() !== this.inputRef.current.innerText.trim() ||
      (this.props.notRequired ? nextProps.value === '' : false) ||
      nextState.active !== this.state.active ||
      nextProps.invalid !== this.props.invalid ||
      nextProps.readOnly !== this.props.readOnly
    );
  }

  componentDidMount() {
    if (this.props.autoFocus && !this.state.active) {
      this.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.value && this.props.value) {
      const nodes = this.inputRef.current.childNodes;
      const lastNode = nodes[nodes.length - 1];

      if (lastNode) {
        const sel = window.getSelection();
        const range = document.createRange();
        range.setStart(lastNode, lastNode.textContent.length);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }

  onContainerClick = () => {
    if (!this.state.active) {
      this.focus();
    }
  };

  onInput = event => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(event.target.innerText.trim());
    }

    this.handleMaxLength(event);
  };

  handleMaxLength(event) {
    const innerText = event.target.innerText.trim();
    const { maxLength } = this.props;
    if (_.isNumber(maxLength) && innerText.length > maxLength) {
      this.inputRef.current.innerText = this.props.value;
      var range = document.createRange();
      range.selectNodeContents(event.target);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
      setCaretToEnd(this.inputRef.current);
    }
  };

  onBlur = e => {
    this.setState({ active: false });

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  };

  onFocus = e => {
    this.setState({ active: true });

    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  };

  onKeyPress = event => {
    const { breakLine, onKeyPress, isNotBlurAfterEnter } = this.props;

    onKeyPress && onKeyPress(event);
    if (!breakLine && event.key === 'Enter') {
      event.preventDefault();
      if (!isNotBlurAfterEnter) {
        event.target.blur();
      }
    } else {
      if (event.key === 'Enter') {
        document.execCommand('insertLineBreak');
        event.preventDefault();
      }
    }
  };

  onDrop = event => {
    event.preventDefault();
  };

  handleKeyDown = event => {
    const { onKeyDown } = this.props;

    onKeyDown && onKeyDown(event);
  };

  render() {
    const { active } = this.state;
    const { placeholder, value, lineHeight, className, invalid } = this.props;

    return (
      <S.Wrapper className={classnames('text-editable__wrapper', className)}>
        <S.Container
          onClick={this.onContainerClick}
          className={classnames('text-editable__container', {
            'text-editable__container--active': this.props.readOnly ? true : active,
            'text-editable__container--invalid': invalid,
            'text-editable__container--empty': !value,
          })}
          readOnly={this.props.readOnly}
        >
          <S.ContentEditable
            className="text-editable__input"
            contentEditable={this.props.readOnly ? false : true}
            onInput={this.onInput}
            ref={this.inputRef}
            placeholder={placeholder}
            dangerouslySetInnerHTML={{ __html: value }}
            onPaste={onlyInsertPlainText}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onKeyPress={this.onKeyPress}
            lineHeight={lineHeight}
            onDrop={this.onDrop}
            onMouseOut={this.props.onMouseOut}
            onKeyDown={this.handleKeyDown}
          />
        </S.Container>
      </S.Wrapper>
    );
  }
}

TextEditable.propTypes = {
  value: PropTypes.string,
  classnames: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  breakLine: PropTypes.bool,
  lineHeight: PropTypes.number,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  isNotBlurAfterEnter: PropTypes.bool,
  onMouseOut: PropTypes.func,
};

export default TextEditable;
