/**
 * @flow
 */

export default {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Open Sans'
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },


  workoutListContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    marginTop: 5,
    borderRadius: '6px',
    boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.2)'
  },

  previewContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    marginTop: '5px',
    border: '1px solid #5a57cb',
    boxShadow: 'none'
  },


  hoverColor: '#90ee9080',
};
